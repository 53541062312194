import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SubjectAttributeDataBoxDto} from '|api/commons';

@Component({
  selector: 'icz-databox-details',
  templateUrl: './data-box-details.component.html',
  styleUrls: ['./data-box-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataBoxDetailsComponent {

  @Input({required: true}) dataBoxes: Nullable<SubjectAttributeDataBoxDto[]>;

  get dataBoxesFormatted(): string {
    return this.dataBoxes?.map(d => d.value!.id)?.join(', ') ?? '';
  }

}
