import {Component, inject, OnInit} from '@angular/core';
import {FileState} from '|api/commons';
import {ApiFileService, DocumentDto, FileDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {
  DocumentToastService,
  DocumentToastType
} from '../../../../../core/services/notifications/document-toast.service';
import {DialogService} from '../../../../../core/services/dialog.service';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';


@Component({
  selector: 'icz-add-to-file-dialog',
  templateUrl: './add-to-file-dialog.component.html',
  styleUrls: ['./add-to-file-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class AddToFileDialogComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected loadingService = inject(LoadingIndicatorService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private apiFileService = inject(ApiFileService);
  private documentToastService = inject(DocumentToastService);
  private dialogService = inject(DialogService);
  protected documents = injectModalData<DocumentDto[]>();

  formGroupsToCheck!: string[];

  form = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, [IczValidators.required()])
  });

  selectedFile: Nullable<FileDto>;

  isBulkOperation = false;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.isBulkOperation = this.documents.length > 1;
  }

  submit() {
    const documentIds = this.documents.map(doc => doc.id);
    const fileId = this.form.get('id')!.value;

    if (this.selectedFile && this.selectedFile.fileState === FileState.SETTLED) {
      this.dialogService.openQuestionDialog(
        {
          title: 'Zrušení vyřízení spisu',
          question: this.isBulkOperation ?
            'Dokumenty jsou vkládány do vyřízeného spisu. Vložením dokumentů bude vyřízení spisu zrušeno. Chcete pokračovat ve vložení dokumentů do spisu?' :
            'Dokument je vkládán do vyřízeného spisu. Vložením dokumentu bude vyřízení spisu zrušeno. Chcete pokračovat ve vložení dokumentu do spisu?',
          description: '',
          leftButtonTitle: 'Vložit do spisu',
        }
      ).subscribe( _ => {
        this.insertFileToDocument(documentIds, fileId!);
      });
    } else {
      this.insertFileToDocument(documentIds, fileId!);
    }
  }

  insertFileToDocument(documentIds: number[], fileId: number) {
    if (documentIds && fileId && this.selectedFile) {
      if (documentIds.length > 1) {
        this.loadingService.doLoading(
          this.apiFileService.fileBulkInsertDocumentsIntoFile({
            body: {
              documentIds,
              fileId
            }
          }),
          this
        ).subscribe({
          next: _ => {
            this.modalRef.close(true);
            this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_ADD_TO_FILE_STARTED,{
              [InternalNotificationKey.COUNT]: documentIds.length
            });
          },
          error: _ => {
            this.modalRef.close(false);
            this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.DOCUMENT_BULK_ACTION_ERROR);
          }
        });
      } else {
        this.loadingService.doLoading(
          this.apiFileService.fileInsertDocumentIntoFile({
            body: {documentId: documentIds[0], fileId}
          }),
          this
        ).subscribe(
          _ => {
            this.modalRef.close(true);
            this.documentToastService.dispatchDocumentInsertedToFile({
              [InternalNotificationKey.DOCUMENT_ID]: this.documents[0].id,
              [InternalNotificationKey.DOCUMENT_SUBJECT]: this.documents[0].subject,
              [InternalNotificationKey.FILE_ID]: fileId,
              [InternalNotificationKey.FILE_SUBJECT]: this.selectedFile!.subject,
            });
          },
        );
      }
    }
  }

  onFileSelected(file: FileDto) {
    this.selectedFile = file;
  }

  close() {
    this.modalRef.close(false);
  }

}
