/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagePropertyValueDto } from '../models/page-property-value-dto';
import { PropertyValueDto } from '../models/property-value-dto';
import { propertyValueOauthCreate } from '../fn/property-value-oauth/property-value-oauth-create';
import { PropertyValueOauthCreate$Params } from '../fn/property-value-oauth/property-value-oauth-create';
import { propertyValueOauthGetOrganizationProperties } from '../fn/property-value-oauth/property-value-oauth-get-organization-properties';
import { PropertyValueOauthGetOrganizationProperties$Params } from '../fn/property-value-oauth/property-value-oauth-get-organization-properties';
import { propertyValueOauthSearch } from '../fn/property-value-oauth/property-value-oauth-search';
import { PropertyValueOauthSearch$Params } from '../fn/property-value-oauth/property-value-oauth-search';
import { propertyValueOauthUpdate } from '../fn/property-value-oauth/property-value-oauth-update';
import { PropertyValueOauthUpdate$Params } from '../fn/property-value-oauth/property-value-oauth-update';
import { propertyValueOauthUpdateListForEnvironment } from '../fn/property-value-oauth/property-value-oauth-update-list-for-environment';
import { PropertyValueOauthUpdateListForEnvironment$Params } from '../fn/property-value-oauth/property-value-oauth-update-list-for-environment';
import { propertyValueOauthUpdateOrCreateOrganizationalPropertyValues } from '../fn/property-value-oauth/property-value-oauth-update-or-create-organizational-property-values';
import { PropertyValueOauthUpdateOrCreateOrganizationalPropertyValues$Params } from '../fn/property-value-oauth/property-value-oauth-update-or-create-organizational-property-values';


/**
 * API for OAuth2 protected REST endpoints to handle property values
 */
@Injectable({ providedIn: 'root' })
export class ApiPropertyValueOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `propertyValueOauthUpdate()` */
  static readonly PropertyValueOauthUpdatePath = '/oauth/property-value/{id}';

  /**
   * Updates existing property value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthUpdate$Response(params: PropertyValueOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyValueDto>> {
    return propertyValueOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates existing property value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthUpdate(params: PropertyValueOauthUpdate$Params, context?: HttpContext): Observable<PropertyValueDto> {
    return this.propertyValueOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PropertyValueDto>): PropertyValueDto => r.body)
    );
  }

  /** Path part for operation `propertyValueOauthUpdateListForEnvironment()` */
  static readonly PropertyValueOauthUpdateListForEnvironmentPath = '/oauth/property-value/{applicationName}/{organizationId}/update-environment';

  /**
   * Updates list of existing property values for given environment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueOauthUpdateListForEnvironment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthUpdateListForEnvironment$Response(params: PropertyValueOauthUpdateListForEnvironment$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PropertyValueDto>>> {
    return propertyValueOauthUpdateListForEnvironment(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates list of existing property values for given environment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueOauthUpdateListForEnvironment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthUpdateListForEnvironment(params: PropertyValueOauthUpdateListForEnvironment$Params, context?: HttpContext): Observable<Array<PropertyValueDto>> {
    return this.propertyValueOauthUpdateListForEnvironment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PropertyValueDto>>): Array<PropertyValueDto> => r.body)
    );
  }

  /** Path part for operation `propertyValueOauthUpdateOrCreateOrganizationalPropertyValues()` */
  static readonly PropertyValueOauthUpdateOrCreateOrganizationalPropertyValuesPath = '/oauth/property-value/update-or-create-organizational-property-values';

  /**
   * Updates existing organization property values and/or creates them
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueOauthUpdateOrCreateOrganizationalPropertyValues()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthUpdateOrCreateOrganizationalPropertyValues$Response(params: PropertyValueOauthUpdateOrCreateOrganizationalPropertyValues$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PropertyValueDto>>> {
    return propertyValueOauthUpdateOrCreateOrganizationalPropertyValues(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates existing organization property values and/or creates them
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueOauthUpdateOrCreateOrganizationalPropertyValues$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthUpdateOrCreateOrganizationalPropertyValues(params: PropertyValueOauthUpdateOrCreateOrganizationalPropertyValues$Params, context?: HttpContext): Observable<Array<PropertyValueDto>> {
    return this.propertyValueOauthUpdateOrCreateOrganizationalPropertyValues$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PropertyValueDto>>): Array<PropertyValueDto> => r.body)
    );
  }

  /** Path part for operation `propertyValueOauthGetOrganizationProperties()` */
  static readonly PropertyValueOauthGetOrganizationPropertiesPath = '/oauth/property-value/find-by-keys';

  /**
   * Finds organizational property values or global property values in case the former does not exist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueOauthGetOrganizationProperties()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthGetOrganizationProperties$Response(params: PropertyValueOauthGetOrganizationProperties$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PropertyValueDto>>> {
    return propertyValueOauthGetOrganizationProperties(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds organizational property values or global property values in case the former does not exist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueOauthGetOrganizationProperties$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthGetOrganizationProperties(params: PropertyValueOauthGetOrganizationProperties$Params, context?: HttpContext): Observable<Array<PropertyValueDto>> {
    return this.propertyValueOauthGetOrganizationProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PropertyValueDto>>): Array<PropertyValueDto> => r.body)
    );
  }

  /** Path part for operation `propertyValueOauthCreate()` */
  static readonly PropertyValueOauthCreatePath = '/oauth/property-value/';

  /**
   * Creates new property value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthCreate$Response(params: PropertyValueOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyValueDto>> {
    return propertyValueOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new property value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueOauthCreate(params: PropertyValueOauthCreate$Params, context?: HttpContext): Observable<PropertyValueDto> {
    return this.propertyValueOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PropertyValueDto>): PropertyValueDto => r.body)
    );
  }

  /** Path part for operation `propertyValueOauthSearch()` */
  static readonly PropertyValueOauthSearchPath = '/oauth/property-value/search-api';

  /**
   * Find property values based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueOauthSearch$Response(params?: PropertyValueOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePropertyValueDto>> {
    return propertyValueOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find property values based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueOauthSearch(params?: PropertyValueOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PagePropertyValueDto> {
    return this.propertyValueOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePropertyValueDto>): PagePropertyValueDto => r.body)
    );
  }

}
