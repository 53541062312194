import {Component, OnInit} from '@angular/core';
import {of, pipe} from 'rxjs';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {IczTableFilterWithList} from '../../../custom-filters/table-filter.component';
import {EnumFilterItem, isListFilterItem} from '../../../filter.types';
import {
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory
} from '../../../../form-elements/form-autocomplete/form-autocomplete.model';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {Option} from '../../../../../model';


@Component({
  selector: 'icz-enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss'],
})
export class EnumFilterComponent extends IczTableFilterWithList implements OnInit {

  override set item(newItem: EnumFilterItem) {
    this._item = newItem;
  }
  override get item(): EnumFilterItem {
    return this._item as EnumFilterItem;
  }

  get useCustomChipsFilter() {
    return isListFilterItem(this.item) && this.item.useCustomChips && this.item.chipNamespace && !this.item.findListCb;
  }

  get simpleListFilter() {
    return isListFilterItem(this.item) && !(this.item.useCustomChips && this.item.chipNamespace) && !this.item.findListCb;
  }

  get backendListFilter() {
    return isListFilterItem(this.item) && this.item.findListCb;
  }

  ngOnInit() {
    this.initForm();
    this.initOperatorAndValueChangeHandlers();
  }

  makeSearchTermOptionsDefinition: OptionsDefinitionFactory = (options$, strForSearch) => {
    const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

    defaultDefinition.searchtermToOptionsOperator = pipe(
      debounceTime(300),
      switchMap(searchTerm => {
        if (isListFilterItem(this.item) && searchTerm.length >= (this.item.minSearchTermLength ?? 3)) {
          return this.item.findListCb!(searchTerm);
        } else {
          return of([]);
        }
      }),
      map(items => items.map(namedDtoToOption(true)) as Option[]),
      tap(options => {
        this.item.list = options;
      })
    );

    return defaultDefinition;
  };

  applyFilter(): void {
    this.applyFilterOperator();

    if (!this.hasNoValueFilterOperator) {
      this.emitFilterValue();
    }

    this.closeFilterPopup();
  }

}
