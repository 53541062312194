import {ElasticIdentifiable} from '../../../model';
import {
  DocumentDtoWithAuthorization,
  FileDtoWithAuthorization,
  StorageUnitDtoWithAuthorization
} from '../model/dto-with-permissions.interface';
import {
  CirculationView,
  DocumentView,
  isActivityView,
  isTaskView,
  RegistryOfficeTransferView,
  StorageUnitView
} from '../document-toolbar/services/toolbar-common.utils';
import {EntityWithRefNumber, RegistryOfficeTransferIncidentView} from '../ref-number-link/ref-number-link.component';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';
import {
  COMPONENT_ENTITY_TYPES,
  DOCUMENT_ENTITY_TYPES,
  getDetailUri,
  getDetailUriByObject
} from '../shared-document.utils';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {DocumentDto, FileDto} from '|api/document';
import {Params} from '@angular/router';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {EntityType} from '|api/commons';
import {
  OwnConsignmentOfficeDeskTableView,
  OwnConsignmentTableView
} from '../own-consignment-table/model/own-consignment-model';

export type DocumentsTableDataType = Partial<ElasticIdentifiable> & {unresolvedIncidentsCount?: Nullable<number>} & (
    DocumentDtoWithAuthorization |
    FileDtoWithAuthorization |
    StorageUnitDtoWithAuthorization
);

export type TableEntityView = (
  DocumentView |
  CirculationView |
  StorageUnitView |
  RegistryOfficeTransferView |
  RegistryOfficeTransferIncidentView |
  OwnConsignmentTableView |
  OwnConsignmentOfficeDeskTableView
);

export function getDetailUrlByEntity(view: Nullable<TableEntityView>, entity: EntityWithRefNumber, ...additionalPathFragments: string[]): Nullable<string> {
  if (isTaskView(view as CirculationView)) {
    return getReferredCirculationEntityDetailUrl(entity, {taskId: entity.id}, ...additionalPathFragments);
  }
  else if (isActivityView(view as CirculationView)) {
    return getReferredCirculationEntityDetailUrl(entity, {activityId: entity.id}, ...additionalPathFragments);
  }
  else if (
    view === RegistryOfficeTransferView.HANDED_OVER_AND_STORED ||
    view === RegistryOfficeTransferIncidentView.OBJECT_INCIDENTS_LISTING
  ) {
    const typedEntity = entity as SearchRecordSourceRegistryOfficeTransferActivityDto;
    if (!isNil(typedEntity.documentId)) {
      return createAbsoluteRoute(getDetailUri(DocumentsRoute.DOCUMENT, typedEntity.documentId), ...additionalPathFragments);
    }
    else if (!isNil(typedEntity.fileId)) {
      return createAbsoluteRoute(getDetailUri(DocumentsRoute.FILE, typedEntity.fileId), ...additionalPathFragments);
    }
    else if (!isNil(typedEntity.storageUnitId)) {
      return createAbsoluteRoute(getDetailUri(DocumentsRoute.STORAGE_UNIT, typedEntity.storageUnitId), ...additionalPathFragments);
    }
    else {
      return null;
    }
  }
  else if (
    view === RegistryOfficeTransferView.REGISTRY_OFFICE_TO_TAKE_OVER ||
    view === RegistryOfficeTransferView.REGISTRY_OFFICE_TO_RESOLVE_ISSUES ||
    view === RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES
  ) {
    const typedEntity = entity as SearchRecordSourceRegistryOfficeTransferActivityDto;
    const transferViewParams = {registryOfficeTransferView: true};

    if (!isNil(typedEntity.documentId)) {
      return createAbsoluteRoute(
        getDetailUri(DocumentsRoute.DOCUMENT, typedEntity.documentId),
        ...additionalPathFragments,
        transferViewParams
      );
    }
    else if (!isNil(typedEntity.fileId)) {
      return createAbsoluteRoute(
        getDetailUri(DocumentsRoute.FILE, typedEntity.fileId),
        ...additionalPathFragments,
        transferViewParams
      );
    }
    else if (!isNil(typedEntity.storageUnitId)) {
      return createAbsoluteRoute(
        getDetailUri(DocumentsRoute.STORAGE_UNIT, typedEntity.storageUnitId),
        ...additionalPathFragments,
        transferViewParams
      );
    }
    else {
      return null;
    }
  }
  else {
    return createAbsoluteRoute(getDetailUriByObject(entity as DocumentDto | FileDto), ...additionalPathFragments);
  }
}

function getReferredCirculationEntityDetailUrl(row: EntityWithRefNumber, queryParams: Params, ...additionalPathFragments: string[]) {
  if ((row as unknown as CirculationActivityDto | CirculationTaskDto).circulationEntityType === EntityType.FILE) {
    return createAbsoluteRoute(
      getDetailUri(DocumentsRoute.FILE, (row as unknown as CirculationActivityDto | CirculationTaskDto).fileId),
      ...additionalPathFragments,
      queryParams,
    );
  }
  else if (DOCUMENT_ENTITY_TYPES.includes((row as unknown as CirculationActivityDto | CirculationTaskDto).circulationEntityType)) {
    return createAbsoluteRoute(
      getDetailUri(DocumentsRoute.DOCUMENT, (row as unknown as CirculationActivityDto | CirculationTaskDto).documentId),
      ...additionalPathFragments,
      queryParams,
    );
  }
  else if (COMPONENT_ENTITY_TYPES.includes((row as unknown as CirculationActivityDto | CirculationTaskDto).circulationEntityType)) {
    return createAbsoluteRoute(
      getDetailUri(DocumentsRoute.DOCUMENT, (row as unknown as CirculationActivityDto | CirculationTaskDto).documentId),
      ...additionalPathFragments,
    );
  }
  else {
    return null;
  }
}
