import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {DigitalComponentCompleteDto, DocumentSignRequest} from '|api/component';
import {DocumentSignResponse} from '|api/document';
import {IczModalService} from '../../../../services/icz-modal.service';
import {DialogService} from '../../../../core/services/dialog.service';
import {
  BulkComponentSignResponse,
  PreSignatureValidationDialogData,
  SignatureConfigurationDialogData
} from '../models/signature-dialog.model';
import {
  DigitalComponentPreSignatureValidationDialogComponent
} from '../../digital-component-signature/digital-component-pre-signature-validation-dialog/digital-component-pre-signature-validation-dialog.component';
import {
  DigitalComponentSignatureConfigurationDialogComponent
} from '../../digital-component-signature/digital-component-signature-configuration-dialog/digital-component-signature-configuration-dialog.component';
import {DialogSeverity} from '../../../../core/services/dialog.models';


const signModalHeight = '85vh';

@Injectable({
  providedIn: 'root',
})
export class DigitalComponentSignatureDialogService {

  private modalService = inject(IczModalService);
  private dialogService = inject(DialogService);

  openSignatureDialogFlow<TResponse>(
    digitalComponentData: DigitalComponentCompleteDto[],
    signRequestFn: (documentId: number, signRequest: DocumentSignRequest) => Observable<TResponse>,
    signResponseSelector: (res: TResponse) => DocumentSignResponse | BulkComponentSignResponse,
  ): Observable<Nullable<TResponse>> {
    return this.loadModalWithPreSignatureValidation(digitalComponentData).pipe(
      switchMap(data => {
        if (data) {
          return this.loadModalWithSignatureConfiguration(
            digitalComponentData,
            {
              ...data,
              signRequestFn,
              signResponseSelector,
            }
          );
        }
        else {
          return of(null);
        }
      })
    );
  }

  openSignatureInvalidationDialog(invalidatedComponentsLabels: string[]) {
    return this.dialogService.openSimpleDialog({
      title: 'Editací komponent zneplatníte podpisy těchto komponent',
      content: invalidatedComponentsLabels.map(label => ({text: label})),
      severity: DialogSeverity.ERROR,
      leftButtonTitle: 'Pokračovat v editaci',
    });
  }

  private loadModalWithPreSignatureValidation(digitalComponentData: DigitalComponentCompleteDto[]) {
    const multipleDigitalComponentsSelected = digitalComponentData.length > 1;
    const singleDigitalComponentDialogTitle = 'Validace podepsání komponenty {{fileName}}';
    const multipleDigitalComponentsDialogTitle = 'Validace podepsání komponent';

    const titleTemplateContext = {
      fileName: digitalComponentData[0].label!,
      length: (digitalComponentData?.length ?? 0).toString(),
    };

    const {documentId} = digitalComponentData[0];

    return this.modalService.openComponentInModal<Nullable<PreSignatureValidationDialogData>, PreSignatureValidationDialogData>({
      component: DigitalComponentPreSignatureValidationDialogComponent,
      modalOptions: {
        titleTemplate: multipleDigitalComponentsSelected ? multipleDigitalComponentsDialogTitle : singleDigitalComponentDialogTitle,
        titleTemplateContext,
        width: 650,
        height: '80vh',
      },
      data: {
        digitalComponents: digitalComponentData,
        isBulkOperation: multipleDigitalComponentsSelected,
        documentId: documentId!,
      },
    });
  }

  private loadModalWithSignatureConfiguration<TResponse>(
    digitalComponentData: DigitalComponentCompleteDto[],
    data: SignatureConfigurationDialogData<TResponse>
  ): Observable<TResponse> {
    const getTitleTemplateContext = () => {
      const withVisibleCount = data.signableWithVisualSignatureOption?.length ?? 0;
      const withoutVisibleCount = data.signableWithoutVisualSignatureOption?.length ?? 0;
      const totalSignable = withVisibleCount + withoutVisibleCount;
      const withVisible = data.signableWithVisualSignatureOption;
      const withoutVisible = data.signableWithVisualSignatureOption;

      if (digitalComponentData.length > 1 && totalSignable === 1) {
        return {
          fileName: withVisible?.length ? withVisible[0].label! : withoutVisible![0].label!,
          length: digitalComponentsLength.toString(),
        };
      } else {
        return {
          fileName: digitalComponentData[0].label!,
          length: digitalComponentsLength.toString(),
        };
      }
    };

    const {signableWithVisualSignatureOption, signableWithoutVisualSignatureOption} = data;
    const singleDigitalComponentDialogTitle = 'Podepsání dokumentu';
    const multipleDigitalComponentsDialogTitle = 'Hromadné podepsání dokumentů';

    const digitalComponentsLength = (signableWithVisualSignatureOption?.length ?? 0) + (signableWithoutVisualSignatureOption?.length ?? 0);

    const isBulkOperation = digitalComponentsLength > 1;

    const titleTemplateContext = getTitleTemplateContext();

    return this.modalService.openComponentInModal<TResponse, SignatureConfigurationDialogData<TResponse>>({
      component: DigitalComponentSignatureConfigurationDialogComponent,
      modalOptions: {
        titleTemplate: isBulkOperation ? multipleDigitalComponentsDialogTitle : singleDigitalComponentDialogTitle,
        titleTemplateContext,
        width: 1200,
        height: signModalHeight,
        disableAutoMargin: true,
      },
      data: {...data, isBulkOperation},
    });
  }

}
