/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EmailUploadNodeDto } from '../models/email-upload-node-dto';
import { emailUploadNodesOauthCreate } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-create';
import { EmailUploadNodesOauthCreate$Params } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-create';
import { emailUploadNodesOauthGetAll } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-get-all';
import { EmailUploadNodesOauthGetAll$Params } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-get-all';
import { emailUploadNodesOauthGetById } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-get-by-id';
import { EmailUploadNodesOauthGetById$Params } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-get-by-id';
import { emailUploadNodesOauthGetDefault } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-get-default';
import { EmailUploadNodesOauthGetDefault$Params } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-get-default';
import { emailUploadNodesOauthSearch } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-search';
import { EmailUploadNodesOauthSearch$Params } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-search';
import { emailUploadNodesOauthUpdate } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-update';
import { EmailUploadNodesOauthUpdate$Params } from '../fn/email-upload-nodes-oauth/email-upload-nodes-oauth-update';
import { PageEmailUploadNodeDto } from '../models/page-email-upload-node-dto';


/**
 * API for management of email-upload nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiEmailUploadNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `emailUploadNodesOauthUpdate()` */
  static readonly EmailUploadNodesOauthUpdatePath = '/oauth/email-upload-nodes/update-node/{id}';

  /**
   * Updates email-upload node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailUploadNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailUploadNodesOauthUpdate$Response(params: EmailUploadNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailUploadNodeDto>> {
    return emailUploadNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates email-upload node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailUploadNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailUploadNodesOauthUpdate(params: EmailUploadNodesOauthUpdate$Params, context?: HttpContext): Observable<EmailUploadNodeDto> {
    return this.emailUploadNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailUploadNodeDto>): EmailUploadNodeDto => r.body)
    );
  }

  /** Path part for operation `emailUploadNodesOauthCreate()` */
  static readonly EmailUploadNodesOauthCreatePath = '/oauth/email-upload-nodes/create-node';

  /**
   * Creates new email-upload node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailUploadNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailUploadNodesOauthCreate$Response(params: EmailUploadNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailUploadNodeDto>> {
    return emailUploadNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new email-upload node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailUploadNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailUploadNodesOauthCreate(params: EmailUploadNodesOauthCreate$Params, context?: HttpContext): Observable<EmailUploadNodeDto> {
    return this.emailUploadNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailUploadNodeDto>): EmailUploadNodeDto => r.body)
    );
  }

  /** Path part for operation `emailUploadNodesOauthGetById()` */
  static readonly EmailUploadNodesOauthGetByIdPath = '/oauth/email-upload-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailUploadNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthGetById$Response(params: EmailUploadNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailUploadNodeDto>> {
    return emailUploadNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailUploadNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthGetById(params: EmailUploadNodesOauthGetById$Params, context?: HttpContext): Observable<EmailUploadNodeDto> {
    return this.emailUploadNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailUploadNodeDto>): EmailUploadNodeDto => r.body)
    );
  }

  /** Path part for operation `emailUploadNodesOauthSearch()` */
  static readonly EmailUploadNodesOauthSearchPath = '/oauth/email-upload-nodes/search-api';

  /**
   * Find all values in email-upload nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailUploadNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthSearch$Response(params?: EmailUploadNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEmailUploadNodeDto>> {
    return emailUploadNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in email-upload nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailUploadNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthSearch(params?: EmailUploadNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageEmailUploadNodeDto> {
    return this.emailUploadNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEmailUploadNodeDto>): PageEmailUploadNodeDto => r.body)
    );
  }

  /** Path part for operation `emailUploadNodesOauthGetAll()` */
  static readonly EmailUploadNodesOauthGetAllPath = '/oauth/email-upload-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailUploadNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthGetAll$Response(params?: EmailUploadNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EmailUploadNodeDto>>> {
    return emailUploadNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailUploadNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthGetAll(params?: EmailUploadNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<EmailUploadNodeDto>> {
    return this.emailUploadNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailUploadNodeDto>>): Array<EmailUploadNodeDto> => r.body)
    );
  }

  /** Path part for operation `emailUploadNodesOauthGetDefault()` */
  static readonly EmailUploadNodesOauthGetDefaultPath = '/oauth/email-upload-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailUploadNodesOauthGetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthGetDefault$Response(params?: EmailUploadNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<EmailUploadNodeDto>> {
    return emailUploadNodesOauthGetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailUploadNodesOauthGetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailUploadNodesOauthGetDefault(params?: EmailUploadNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<EmailUploadNodeDto> {
    return this.emailUploadNodesOauthGetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailUploadNodeDto>): EmailUploadNodeDto => r.body)
    );
  }

}
