import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {CodebookService} from '../../../core/services/codebook.service';
import {namedDtoToOption} from '../../../core/services/data-mapping.utils';
import {Option} from '../../../model';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {HistoryService} from '../../../services/history.service';
import {combineLatest} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {UNIT_VIEW_QUERY_PARAM_NAME} from '../abstract-unit-view-component/abstract-unit-view-component';

const initialUnitOptions: Option[] = [
  {value: null, label: 'Všechny útvary'},
];

@Component({
  selector: 'icz-unit-selector',
  templateUrl: './unit-selector.component.html',
  styleUrls: ['./unit-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnitSelectorComponent implements OnInit {

  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);
  private historyService = inject(HistoryService);
  private activatedRoute = inject(ActivatedRoute);

  @Output() unitSelected = new EventEmitter<Nullable<number>>();

  unitOptions: Option[] = [...initialUnitOptions];
  form = new IczFormGroup({
    unitId: new IczFormControl<Nullable<number>>(null),
  });

  ngOnInit(): void {
    this.form.get('unitId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(unitId => {
      if (isNil(unitId)) {
        this.historyService.patchHistoryBitWithCustomParams(UNIT_VIEW_QUERY_PARAM_NAME, null);
      } else {
        this.historyService.patchHistoryBitWithCustomParams(UNIT_VIEW_QUERY_PARAM_NAME, String(unitId));
      }
      this.unitSelected.emit(unitId);
    });

    combineLatest([
      this.codebookService.organizationalUnits(),
      this.activatedRoute.queryParamMap,
      this.codebookService.orgUnitsToLoggedInFp(),
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([units, paramMap, accessibleOrgUnitIds]) => {
      const accessibleUnits = units.filter(u => accessibleOrgUnitIds.includes(u.id));
      this.unitOptions = [...initialUnitOptions].concat(accessibleUnits.map(namedDtoToOption()));

      if (paramMap.has(UNIT_VIEW_QUERY_PARAM_NAME)) {
        const unitParamValue = Number(paramMap.get(UNIT_VIEW_QUERY_PARAM_NAME)!);
        if (this.unitOptions.some(op => op.value === unitParamValue)) {
          this.form.get('unitId')!.setValue(unitParamValue);
        } else {
          this.form.get('unitId')!.setValue(null);
        }
      } else {
        this.form.get('unitId')!.setValue(null);
      }
    });
  }

}
