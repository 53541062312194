/* eslint-disable @typescript-eslint/ban-types */
import {Component, Input} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {InterpolationContext} from '../../../lib/model';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'icz-modal-dialog',
  templateUrl: './modal-dialog.component.html',
  styleUrls: ['./modal-dialog.component.scss'],
})
export class ModalDialogComponent implements IczOnChanges {
  @Input({required: true}) dialog!: MatDialogRef<any>;
  @Input({required: true}) title: Nullable<string>;
  @Input({required: true}) titleContext!: InterpolationContext;
  @Input({required: true}) showHeader: boolean = true;
  @Input({required: true}) showFooter: boolean = true;
  @Input({required: true})
  set canClose(value: boolean | '') { this._canClose = value === '' ? true : value; }
  get canClose() { return this._canClose; }
  _canClose = false;

  ngOnChanges(data: IczSimpleChanges<this>) {
    if (data.dialog) {
      this.dialog.addPanelClass('icz-modal-dialog');
    }
  }
}
