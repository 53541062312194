import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PrimitiveValueFormField} from '../form-field';
import {locateOptionByValue, Option} from '../../../model';
import {arrayMove} from '../../../lib/utils';

@Component({
  selector: 'icz-form-item-sorter',
  templateUrl: './item-sorter.component.html',
  styleUrls: ['./item-sorter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSorterComponent extends PrimitiveValueFormField {
  @Input({required: true})
  set options(newValue: Option[]) {
    this._options = newValue;
    this._value = newValue.map(o => o.value!);
  }
  get options(): Option[] {
    return this._options;
  }

  // Override
  override set value(newValue: Array<string|number>) {
    // null = unsorted = keep input option array order
    if (!newValue) {
      this._value = this._options.map(o => o.value!);
    }
    else {
      this._value = newValue;
    }
  }
  override get value(): Array<string|number> {
    return this._value;
  }

  _options: Option[] = [];

  override _value!: Array<string | number>;

  get optionsSortedByValue(): Option[] {
    return (this._value ?? []).map(v => locateOptionByValue(this._options, v)!);
  }

  override _valueChanged() {
    this.blur.emit();
    this.valueChange.emit(this._value);
  }

  moveUp(index: number) {
    if (index > 0) {
      this.moveItem(index, index - 1);
    }
  }

  moveDown(index: number) {
    if (index < this._value?.length - 1) {
      this.moveItem(index, index + 1);
    }
  }

  // CdkDragDrop<never> = item does not have any special cdkDrag metadata
  itemDropped($event: CdkDragDrop<any, any>) {
    const {previousIndex, currentIndex} = $event;
    this.moveItem(previousIndex, currentIndex);
  }

  private moveItem(fromIndex: number, toIndex: number) {
    this._value = arrayMove(this._value, fromIndex, toIndex);
    this._valueChanged();
  }
}
