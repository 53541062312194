/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ClassificationCodeSeparator } from '|api/commons';
import { ClassificationSchemeDto } from '../../models/classification-scheme-dto';

export interface ClassificationSchemeImportXml_1$Params {
  code?: string|null|undefined;
  name?: string|null|undefined;
  description?: string|null|undefined;
  separator?: ClassificationCodeSeparator|null|undefined;
      body?: {
'xml': Blob;
}|null|undefined
}

export function classificationSchemeImportXml_1(http: HttpClient, rootUrl: string, params?: ClassificationSchemeImportXml_1$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
  const rb = new RequestBuilder(rootUrl, classificationSchemeImportXml_1.PATH, 'post');
  if (params) {
    rb.query('code', params.code, {});
    rb.query('name', params.name, {});
    rb.query('description', params.description, {});
    rb.query('separator', params.separator, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ClassificationSchemeDto>;
    })
  );
}

classificationSchemeImportXml_1.PATH = '/codebook/classifier/classification-scheme/import/meta';