import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {NavigationEnd, Params, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {ResponsivityService} from '../responsivity.service';
import {TabDirective} from './tab.directive';
import {TabItem, TabItemWithPriority, TabPriority, TabsSize} from './tabs.component.model';
import {filterByClass} from '../../../lib/rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {NgScrollbar} from 'ngx-scrollbar';
import {DebugLoggingService} from '../../../core/services/debug-logging.service';

type _TabItem<I = string | number> = TabItem<I> | TabItemWithPriority<I>;

const TABLET_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST];
const SMALL_DESKTOP_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST, TabPriority.HIGH];
const MID_DESKTOP_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST, TabPriority.HIGH, TabPriority.MEDIUM];
const LARGE_DESKTOP_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST, TabPriority.HIGH, TabPriority.MEDIUM, TabPriority.LOW];


@Component({
  selector: 'icz-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent<T extends string | number = string | number> implements AfterContentInit {

  private router = inject(Router);
  private responsivityService = inject(ResponsivityService);
  private destroyRef = inject(DestroyRef);
  private el = inject(ElementRef);

  @ViewChild(NgScrollbar)
  customScrollbar!: NgScrollbar;

  customScrollbarLogicInitialized = false;

  @ContentChildren(TabDirective)
  staticTabs!: QueryList<TabDirective>;

  @Input()
  baseUrl: Nullable<string> = '';

  @Input()
  queryParams: Nullable<Params>;

  @Input()
  set activeTab(tab: Nullable<_TabItem<T>>) {
    this._activeTab$.next(tab);
  }
  get activeTab() {
    return this._activeTab$.value;
  }

  @Input()
  size: TabsSize = 'default';

  @Input()
  shouldNavigate = true;

  @Input()
  hideTabset = false;

  @Input()
  @HostBinding('class.sticky-tabs')
  withStickyTabs = false;

  @Output()
  tabClick = new EventEmitter<_TabItem<T>>();

  @Input({required: true})
  set tabs(tabs: _TabItem<T>[]) {
    if (this.tabs !== tabs) {
      this._tabs$.next(tabs);
    }
    const currentTabs = this.tabs;

    if (currentTabs?.length) {
      const segments = this.router.url.split('?')[0].split('/');
      const location = segments[segments.length - 1];

      const tabCorrespondsToRouterSegment = currentTabs.map(t => t.id).some(u => u === location);

      if (!this.activeTab) {
        if (tabCorrespondsToRouterSegment) {
          this.activeTab = currentTabs.find(t => t.id === location)!;
        } else {
          this.activeTab = currentTabs.find(t => t.id === this.activeTab?.id) ?? currentTabs[0];
        }
      }
    }
  }
  get tabs() {
    return this._tabs$.value;
  }

  get usePriorityFilter() {
    return this.tabs?.every(t => (t as TabItemWithPriority).priority);
  }

  private debugLogging = inject(DebugLoggingService);

  private _activeTab$ = new BehaviorSubject<Nullable<_TabItem<T>>>(null);

  activeTab$ = this._activeTab$.asObservable();

  private _moreTabsOpened$ = new BehaviorSubject(false);

  moreTabsOpened$ = this._moreTabsOpened$.asObservable();

  private _tabs$ = new BehaviorSubject<_TabItem<T>[]>([]);
  private nonHiddenTabs$ = this._tabs$.pipe(
    filter(Boolean),
    map(tabs => tabs.filter(t => !t.isInExtendedTabs && !t.isHidden)),
  );

  visibleTabPriorities: Observable<TabPriority[]> = combineLatest([
    this.responsivityService.isTablet$,
    this.responsivityService.isSmallDesktop$,
    this.responsivityService.isMidDesktop$,
  ]).pipe(
    map(([isTablet, isSmallDesktop, isMidDesktop]) => {
      if (isTablet) {
        return TABLET_TAB_PRIORITIES;
      } else if (isSmallDesktop) {
        return SMALL_DESKTOP_TAB_PRIORITIES;
      } else if (isMidDesktop) {
        return MID_DESKTOP_TAB_PRIORITIES;
      } else {
        return LARGE_DESKTOP_TAB_PRIORITIES;
      }
    }),
    distinctUntilChanged(),
    tap(_ => {
      this.closeMoreTabs();
    })
  );

  currentlyVisibleTabs$ = combineLatest([
    this.nonHiddenTabs$,
    this.visibleTabPriorities,
    this.activeTab$,
  ]).pipe(
    map(([tabs, activePriorities, _]) => {
      if (this.usePriorityFilter) {
        return tabs.filter(t => activePriorities.includes((t as TabItemWithPriority).priority!));
      }
      else {
        return tabs;
      }
    }),
    filter(Boolean),
    map(tabs => {
      if (!tabs?.find(t => t.id === this.activeTab?.id) && this.usePriorityFilter) {
        const tabsWithoutLastItem = tabs.slice(0, tabs.length - 1);
        return [...tabsWithoutLastItem, this.activeTab!];
      } else {
        return tabs;
      }
    })
  );

  moreTab: _TabItem = {label: 'Více', id: 'more', icon: 'expand_more'};

  hiddenTabsInMoreSelect$ = this.currentlyVisibleTabs$.pipe(
    map(tabs => {
      return this.tabs?.filter(t1 => !tabs?.find(t2 => t1.id === t2?.id)).filter(t => !t.isHidden).reverse() ?? [];
    })
  );

  @HostBinding('class.small')
  get isSmall(): boolean {
    return this.size === 'small';
  }

  get hasStaticTabs() {
    return this.staticTabs.length > 0;
  }

  get activeTabTemplate() {
    return this.hasStaticTabs && this.activeTab ? this.staticTabs.toArray().find(tab => tab.id === this.activeTab?.id)?.content : null;
  }

  get hasRoutableTabs() {
    // this.hasStaticTabs is known after content init
    return !this.hasStaticTabs && this.shouldNavigate;
  }

  openMoreTabs() {
    this._moreTabsOpened$.next(true);
  }

  closeMoreTabs() {
    this._moreTabsOpened$.next(false);
  }

  ngAfterContentInit() {
    if (this.hasRoutableTabs) {
      this.router.events.pipe(
        takeUntilDestroyed(this.destroyRef),
        filterByClass(NavigationEnd),
        map(_ => this.router.url),
      ).subscribe((targetUrl: string) => {
        if (this.tabs) {
          const targetTabId = targetUrl.split('?')[0].split('/').at(-1); // gets last routing segment

          const routingTargetTab = this.tabs.find(t => t.id === targetTabId);

          if (routingTargetTab) {
            this.activeTab = routingTargetTab;
          }
        }
      });
    }
  }

  selectAndScrollToFirstInvalid() {
    const firstInvalid = this.tabs.find(t => t.showTabValidity === true && t.valid === false);
    if (firstInvalid) {
      const firstInvalidTabItem: HTMLElement = this.el.nativeElement.querySelector(`[data-icztabid="${firstInvalid.id}"]`);
      if (!firstInvalidTabItem) return;
      const leftScroll = firstInvalidTabItem.offsetLeft;

      this.customScrollbar.scrollTo({
        left: leftScroll,
        duration: 500,
      });

      this.activeTab = firstInvalid;
    }
  }

  navigate(tab: _TabItem<T>, closeMoreTabs = false) {
    if (closeMoreTabs) {
      this.closeMoreTabs();
    }
    if (!tab.disabled) {
      if (this.hasRoutableTabs) {
        const route = `${this.baseUrl}/${tab.id}`;
        if (this.queryParams) {
          this.router.navigate([route], {queryParams: this.queryParams});
        } else {
          this.router.navigate([route]);
        }
      }
      else {
        this.activeTab = tab;
      }
      this.debugLogging.logUserInteraction({description: `Použita karta '${tab.label}'`});
      this.tabClick.emit(tab);
    }
  }

  customScrollbarUpdated() {
    if (!this.customScrollbarLogicInitialized) {
      this.customScrollbarLogicInitialized = true;

      // will cause that mousewheel scrolling will scroll the tabset horizonzally as the user would expect
      setTimeout(() => {
        this.customScrollbar.nativeElement.addEventListener('wheel', evt => {
          evt.preventDefault();

          // In case of mac touchpads which can scroll also horizontally select the "intended" scroll direction
          const finalDelta = Math.abs(evt.deltaX) > Math.abs(evt.deltaY) ? evt.deltaX : evt.deltaY;

          this.customScrollbar.scrollTo({
            left: this.customScrollbar.viewport.nativeElement.scrollLeft + finalDelta,
            duration: 0,
          });
        });
      }, 0);
    }
  }

}
