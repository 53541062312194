<div class="row gap-0 items-center icz-body-1">
  <icz-button
    class="mr-8"
    [hidden]="!((restBreadcrumbs$ | async)?.length)"
    svgIcon="more" size="default" cdkOverlayOrigin
    #breadcrumbsOverlayOrigin="cdkOverlayOrigin"
    (onAction)="isOpen = true" background
  ></icz-button>
  @for (breadcrumb of breadcrumbLinks$ | async; track breadcrumb) {
    @if (breadcrumb.uri) {
      <span class="breadcumb-item">
        @if (breadcrumbName(breadcrumb); as breadcrumbName) {
          <a
            class="truncate inline-block"
            [style.maxWidth.px]="maxBreadcrumbWidth"
            iczLinkWithoutHref
            [iczTooltip]="breadcrumbName"
            (click)="navigateToBreadcrumb(breadcrumb)"
            >
            @if (breadcrumb.objectClass) {
              <icz-icon
                [svgIcon]="getObjectIcon(breadcrumb.objectClass)"
                size="small"
                class="mr-4"
              ></icz-icon>
            }
            {{ breadcrumbName }}
          </a>
        }
      </span>
    }
    @else {
      @if (breadcrumbName(breadcrumb); as breadcrumbName) {
        <span class="breadcumb-item icz-text-muted truncate"
          [style.maxWidth.px]="maxBreadcrumbWidth" [iczTooltip]="breadcrumbName">
          @if (breadcrumb.objectClass) {
            <icz-icon
              [svgIcon]="getObjectIcon(breadcrumb.objectClass)"
              size="small"
            ></icz-icon>
          }
          {{ breadcrumbName }}
        </span>
      }
    }
    <icz-icon class="mt-4 ml-4" svgIcon="after"></icz-icon>
  }
  @if (currentBreadcrumb$ | async; as currentBreadcrumb) {
    @if ((currentBreadcrumb.isAwaitingAlias ? '' : breadcrumbName(currentBreadcrumb)); as breadcrumbName) {
      <span
        class="breadcumb-item icz-text-muted truncate" [style.maxWidth.px]="maxBreadcrumbWidth" [iczTooltip]="breadcrumbName">
        @if (currentBreadcrumb.objectClass) {
          <icz-icon
            [svgIcon]="getObjectIcon(currentBreadcrumb.objectClass)"
            size="small"
          ></icz-icon>
        }
        {{ breadcrumbName }}
      </span>
    }
    @if (currentBreadcrumb.isAwaitingAlias) {
      <icz-spinner class="mt-4 ml-4" [diameter]="21"></icz-spinner>
    }
  }

  <icz-favourite-page-selector class="ml-8" [page]="currentPage"></icz-favourite-page-selector>
</div>

<icz-popover
  [isOpen]="isOpen"
  [overlayOrigin]="breadcrumbsOverlayOrigin"
  (onClose)="isOpen = false">
  @if (isOpen) {
    <div class="p-16">
      <icz-options-list
        [options]="(restBreadcrumbs$ | async)!"
        (selectionChanged)="breadcrumbOptionSelected($event)"
      ></icz-options-list>
    </div>
  }
</icz-popover>
