/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApplicationDto } from '../models/application-dto';
import { applicationFind } from '../fn/application/application-find';
import { ApplicationFind$Params } from '../fn/application/application-find';
import { applicationFindAll } from '../fn/application/application-find-all';
import { ApplicationFindAll$Params } from '../fn/application/application-find-all';
import { PageApplicationDto } from '../models/page-application-dto';


/**
 * API for management of applications
 */
@Injectable({ providedIn: 'root' })
export class ApiApplicationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `applicationFindAll()` */
  static readonly ApplicationFindAllPath = '/applications';

  /**
   * Find all existing applications
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationFindAll$Response(params?: ApplicationFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<ApplicationDto>>> {
    return applicationFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all existing applications
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationFindAll(params?: ApplicationFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<ApplicationDto>> {
    return this.applicationFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationDto>>): Array<ApplicationDto> => r.body)
    );
  }

  /** Path part for operation `applicationFind()` */
  static readonly ApplicationFindPath = '/applications/search-api';

  /**
   * Finds all application instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationFind$Response(params?: ApplicationFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageApplicationDto>> {
    return applicationFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all application instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationFind(params?: ApplicationFind$Params|null|undefined, context?: HttpContext): Observable<PageApplicationDto> {
    return this.applicationFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageApplicationDto>): PageApplicationDto => r.body)
    );
  }

}
