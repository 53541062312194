<div>
  @if (message) {
    <icz-form-field
      label="Způsob doručení"
      [fieldDisabled]="true"
      [value]="message?.deliveryTypeId | findInList:deliveryTypes"
    ></icz-form-field>
  }
  <icz-form-field
    label="Od koho"
    [fieldDisabled]="true"
    [value]="message?.senderLabel ?? consignment?.emailAddressDto?.senderAddress"
  ></icz-form-field>
  <icz-form-field
    [label]="consignment ? 'Označení příchozí zásilky' : 'Identifikátor datové zprávy'"
    [fieldDisabled]="true"
    [value]="message?.externalId ?? consignment?.externalId"
    >
    @if (consignment) {
      <icz-icon suffix size="small" svgIcon="info" iczTooltip="Např. ID zprávy"></icz-icon>
    }
  </icz-form-field>
  @if (message) {
    <div class="row">
      <icz-form-field
        class="grow"
        label="Doručeno"
        [fieldDisabled]="true"
        [value]="message.receivedDate | localizedDatetime: false : true"
      ></icz-form-field>
      <icz-form-field
        class="grow"
        label="Odesláno"
        [fieldDisabled]="true"
        [value]="message.sentDate | localizedDatetime: false : true"
      ></icz-form-field>
    </div>
  }
  <icz-form-field
    label="Adresáti"
    [fieldDisabled]="true"
    [value]="message?.consigneeAddressees ?? message?.consigneeAddress ?? consignment?.consigneeAddressees"
  ></icz-form-field>
  <icz-form-field
    label="Kopie"
    [fieldDisabled]="true"
    [value]="message?.consigneeCopyAddressees ?? consignment?.consigneeCopyAddressees"
  ></icz-form-field>
</div>
