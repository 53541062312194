<div class="row gap-48" [formGroup]="specificFieldsForm">
  <div class="grow">
    <icz-section label="Poznámka">
      <icz-form-field
        formControlName="note"
        label="Poznámka k zásilce"
        [autoSizeMax]="5"
      ></icz-form-field>
    </icz-section>
  </div>
  <div class="grow"></div>
</div>
