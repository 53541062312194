/* tslint:disable */
/* eslint-disable */

/**
 * Business rule permission type.
 */
export enum BusinessRulePermissionType {
  DATA_FILE_FORMAT_PUID = 'DATA_FILE_FORMAT_PUID',
  DATA_FILE_FORMAT_GROUP = 'DATA_FILE_FORMAT_GROUP',
  FILE_EXTENSION = 'FILE_EXTENSION',
  FILE_NAME = 'FILE_NAME'
}
