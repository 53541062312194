/* tslint:disable */
/* eslint-disable */

/**
 * Nomenclature type for electronic registry office
 */
export enum NomenclatureType {
  REGISTRY_OFFICE = 'REGISTRY_OFFICE',
  CLASSIFICATION_SCHEME = 'CLASSIFICATION_SCHEME',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  ORG_UNIT = 'ORG_UNIT',
  USER_DETAILS = 'USER_DETAILS'
}
