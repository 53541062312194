<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-form-field
    formControlName="rejectionReasonText"
    label="Důvod odmítnutí"
    [autoSizeMin]="5"
    [autoSizeMax]="10"
  ></icz-form-field>
  <icz-form-field
    formControlName="rejectionReasonAlternativeText"
    label="Důvod odmítnutí v alternativním jazyce"
    [autoSizeMin]="5"
    [autoSizeMax]="10"
  ></icz-form-field>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Odmítnout"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
