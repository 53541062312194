<div class="row" [formGroup]="form" (keydown.enter)="enterKeyPressed()">

  <div class="col grow">
    <div class="filter-specification-area">

      <icz-form-inline-select
        formControlName="filterOperator"
        [options]="filterOperators"
        (openStatusChanged)="isOperatorSelectorOpen = $event">
      </icz-form-inline-select>

      <div class="filter-body"
        [ngClass]="{'p-0': item.filterType === FilterType.DATE || item.filterType === FilterType.DATETIME}"
        [style.height.px]="canDisplayFilterBody ? null : 1"
        [style.opacity]="canDisplayFilterBody ? null : 0"> <!-- will make smoother UX without text jumping when re-selecting filter operator -->

        @if (item.filterType === FilterType.TEXT) {
          <icz-form-field
            class="block mt-12"
            data-cy="table-toolbar-text"
            formControlName="value" iczAutoFocus="deffered"
          ></icz-form-field>
        }
        @else if (item.filterType === FilterType.NUMBER) {
          <icz-number-filter
            [selectedValue]="selectedNumber"
            [currentOperator]="currentOperator"
            (selectedValueChanged)="selectedNumberChanged($event)"
          ></icz-number-filter>
        }
        @else if (item.filterType === FilterType.DATE || item.filterType === FilterType.DATETIME) {
          <icz-date-filter
            [selected]="selectedDate"
            [currentOperator]="currentOperator"
            (selectedDateChanged)="selectedDateChanged($event)"
            (daySelected)="closeFilterPopup()"
          ></icz-date-filter>
        }
        @else if (item.filterType === FilterType.DATE_STATISTICS) {
          <icz-date-statistics-filter
            [selected]="selectedDate"
            [currentOperator]="currentOperator"
            (selectedDateChanged)="selectedDateChanged($event)"
            (daySelected)="closeFilterPopup()"
          ></icz-date-statistics-filter>
        }
        @else if (item.filterType === FilterType.FILE_SIZE) {
          <icz-file-size-filter
            (fileSizeChanged)="selectedFileSizeChanged($event)"
            class="block pt-8"
          ></icz-file-size-filter>
        }
      </div>

    </div>
  </div>

</div>
