/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ComponentsBySetOfDocumentsIdsDto } from '../../models/components-by-set-of-documents-ids-dto';
import { EsslComponentBaseDto } from '../../models/essl-component-base-dto';

export interface ComponentFindComponentsBySetOfDocumentsIds$Params {
      body: ComponentsBySetOfDocumentsIdsDto
}

export function componentFindComponentsBySetOfDocumentsIds(http: HttpClient, rootUrl: string, params: ComponentFindComponentsBySetOfDocumentsIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EsslComponentBaseDto>>> {
  const rb = new RequestBuilder(rootUrl, componentFindComponentsBySetOfDocumentsIds.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<EsslComponentBaseDto>>;
    })
  );
}

componentFindComponentsBySetOfDocumentsIds.PATH = '/component/components-by-set-of-documents-ids';