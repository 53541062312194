import {Component, Input} from '@angular/core';
import {DocumentForm} from '|api/commons';
import {DocumentDto} from '|api/document';
import {AbstractHandoverFormComponent} from '../../abstract-handover-form.component';
import {IczFormGroup} from '../../../../../form-elements/icz-form-controls';

@Component({
  selector: 'icz-hand-for-acknowledgement-specification',
  templateUrl: './hand-for-acknowledgement-specification.component.html',
  styleUrls: ['./hand-for-acknowledgement-specification.component.scss']
})
export class HandForAcknowledgementSpecificationComponent extends AbstractHandoverFormComponent {
  @Input()
  override form!: IczFormGroup;
  @Input()
  override circulationEntities!: DocumentDto[];

  protected override selectedFlowType = null;

  readonly DocumentForm = DocumentForm;
}
