/* tslint:disable */
/* eslint-disable */
export enum StorageUnitAuthorizedOperation {
  STORAGE_UNIT_SHOW_PROFILE = 'STORAGE_UNIT_SHOW_PROFILE',
  STORAGE_UNIT_MODIFY_PROFILE = 'STORAGE_UNIT_MODIFY_PROFILE',
  STORAGE_UNIT_DELETE = 'STORAGE_UNIT_DELETE',
  STORAGE_UNIT_SHOW_CONTENT = 'STORAGE_UNIT_SHOW_CONTENT',
  STORAGE_UNIT_ENTITY_ADD = 'STORAGE_UNIT_ENTITY_ADD',
  STORAGE_UNIT_ENTITY_WITHDRAW = 'STORAGE_UNIT_ENTITY_WITHDRAW',
  STORAGE_UNIT_ENTITY_MOVE = 'STORAGE_UNIT_ENTITY_MOVE',
  STORAGE_UNIT_SHOW_SHARING = 'STORAGE_UNIT_SHOW_SHARING',
  STORAGE_UNIT_MANAGE_SHARING = 'STORAGE_UNIT_MANAGE_SHARING',
  STORAGE_UNIT_SHOW_HISTORY = 'STORAGE_UNIT_SHOW_HISTORY',
  STORAGE_UNIT_SHOW_ACTIVITIES = 'STORAGE_UNIT_SHOW_ACTIVITIES',
  STORAGE_UNIT_BLOCK_CONTENT = 'STORAGE_UNIT_BLOCK_CONTENT',
  STORAGE_UNIT_UNBLOCK_CONTENT = 'STORAGE_UNIT_UNBLOCK_CONTENT'
}
