import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {ApiOwnDocumentService, DocumentDto, OwnDocumentCreateDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {IczFormGroup} from '../components/form-elements/icz-form-controls';
import {LoadingIndicatorService} from '../components/essentials/loading-indicator.service';
import {DocumentToastService, DocumentToastType} from '../core/services/notifications/document-toast.service';
import {CounterTypeGroup, MainMenuCountsService} from '../core/services/main-menu-counts.service';
import {
  transformDocumentCreateDtoWithComponents
} from '../components/shared-business-components/analog-component-create-form/analog-component-create-form.component';
import {SKIP_ERROR_DIALOG} from '../core/error-handling/http-errors';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {ApplicationRoute} from '../enums/shared-routes.enum';
import {DocumentDetailRoute, DocumentsRoute} from '../enums/documents-routes.enum';
import {interpolateBackendErrorMessage} from '../components/essentials/interpolate-backend-error.pipe';

@Injectable()
export class OwnDocumentService {

  private loadingService = inject(LoadingIndicatorService);
  private apiOwnDocumentService = inject(ApiOwnDocumentService);
  private router = inject(Router);
  private documentToastService = inject(DocumentToastService);
  private translateService = inject(TranslateService);
  private mainMenuCountsService = inject(MainMenuCountsService);

  private documentSource$ = new BehaviorSubject<Nullable<DocumentDto>>(null);

  waiting = false;
  document$ = this.documentSource$.asObservable();

  private _formGroup!: IczFormGroup;

  set form(value: IczFormGroup) { this._formGroup = value; }

  get valid() { return this._formGroup ? this._formGroup.valid : false; }

  submit() {
    if (!this._formGroup) {
      throw new Error('Form group is empty');
    }

    const formValue = this._formGroup.getRawValue();
    delete formValue.esslComponentCount;

    const ownDocument = transformDocumentCreateDtoWithComponents(formValue as OwnDocumentCreateDto, false);

    this.disable();

    this.loadingService.doLoading(
      this.apiOwnDocumentService.ownDocumentCreate_1(
        {
          body: {
            ...ownDocument,
          },
        },
        SKIP_ERROR_DIALOG
      ),
      this
    ).subscribe({
      next: result => {
        this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_CREATED, {
          [InternalNotificationKey.DOCUMENT_ID]: result.id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: result.subject,
        });
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.DOCUMENT, result.id, DocumentDetailRoute.PREVIEW));
      },
      error: error => {
        this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.DOCUMENT_CREATION_ERROR, {
          [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, error.error),
        });
        this.enable();
      }
    });
  }

  private enable() {
    this._formGroup.enable({ emitEvent: false });
  }

  private disable() {
    this._formGroup.disable({ emitEvent: false });
  }

}
