import {OwnConsignmentStatus} from '|api/commons';
import {IczTableDataSource} from '../../../table/table.datasource';
import {GenericOwnConsignment} from '../model/own-consignment-model';
import {OwnConsignmentsSearchService} from '../../../../services/own-consignments-search.service';

export class OwnConsignmentsOfficeDeskOwnerDatasource extends IczTableDataSource<GenericOwnConsignment> {
  constructor(
    searchService: OwnConsignmentsSearchService,
    states: Array<OwnConsignmentStatus>,
  ) {
    super(searchParams => searchService.findOfficeDeskConsignmentsForForOwner(searchParams, states));
  }
}
