/* tslint:disable */
/* eslint-disable */

/**
 * What to do when the validation of a digital component fails.
 */
export enum OwnDigitalConsignmentValidationFailureAction {
  WARN = 'WARN',
  DISALLOW = 'DISALLOW'
}
