import {ChangeDetectorRef, Component, HostBinding, inject, OnInit} from '@angular/core';
import {DetachingService} from '../../essentials/detaching.service';
import {EnvironmentType} from '../../../core/services/environment.models';
import {ActiveModuleService, ApplicationModule} from '../../../core/services/active-module.service';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {ENVIRONMENT} from '../../../core/services/environment.service';

const SIDE_MENU_ANIMATION_DURATION = 500;


@Component({
  selector: 'icz-menu-and-bar',
  templateUrl: './menu-and-bar.component.html',
  styleUrls: ['./menu-and-bar.component.scss'],
})
export class MenuAndBarComponent implements OnInit {

  private detachingService = inject(DetachingService);
  private cd = inject(ChangeDetectorRef);
  private settingsService = inject(UserSettingsService);
  private activeModuleService = inject(ActiveModuleService);
  private environment = inject(ENVIRONMENT);

  isMenuOpen = true;

  @HostBinding('class.env-test')
  get isMenuTesting() {
    return this.environment.environmentType === EnvironmentType.TESTING;
  }

  @HostBinding('class.env-edu')
  get isMenuEdu() {
    return this.environment.environmentType === EnvironmentType.TRAINING;
  }

  get sidenavClass() {
    const appEnvironment = this.environment.environmentType;

    if (
      this.activeModuleService.activeModule === ApplicationModule.ADMIN && (
        appEnvironment === EnvironmentType.PRODUCTION ||
        appEnvironment === EnvironmentType.DEVELOPMENT
      )
    ) {
      return '';
    }
    else {
      return `env-${appEnvironment}`;
    }
  }

  ngOnInit() {
    this.restoreSideMenuState();

    this.detachingService.registerDetach('columnResize', this.cd);
    this.detachingService.registerDetach('menuClosed', this.cd);
  }

  openSideMenu() {
    this.isMenuOpen = true;
    this.detachingService.reattach();
    this.persistSideMenuState();
  }

  closeSideMenu() {
    this.isMenuOpen = false;
    setTimeout(() => {
        this.detachingService.detachApplicantsByEvent('menuClosed');
        this.detachingService.reattach();
      },
      SIDE_MENU_ANIMATION_DURATION);
    this.persistSideMenuState();
  }

  persistSideMenuState() {
    this.settingsService.setRawValue(LocalStorageKey.MENU_OPEN_STATE, JSON.stringify(this.isMenuOpen));
  }

  restoreSideMenuState() {
    const persistedState = this.settingsService.getRawValue(LocalStorageKey.MENU_OPEN_STATE);

    if (persistedState === null) {
      this.settingsService.setRawValue(LocalStorageKey.MENU_OPEN_STATE, JSON.stringify(true));
    }

    this.isMenuOpen = JSON.parse(this.settingsService.getRawValue(LocalStorageKey.MENU_OPEN_STATE)!);
  }

}
