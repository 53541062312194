import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {cloneDeep, isEmpty} from 'lodash';
import {
  CzechAddressDto,
  DocumentForm,
  SubjectAttributeAddressDto,
  SubjectAttributesDto,
  SubjectAttributeSource,
  SubjectAttributeState,
  SubjectAttributeStringDto,
  SubjectObjectRelationType,
  SubjectRecordClassification,
  SubjectRecordCreateOrUpdateDto, SubjectRecordFindMode
} from '|api/commons';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {IczModalService} from '../../../services/icz-modal.service';
import {ScrollingService} from '../../../services/scrolling.service';
import {formatAddressForDto, SubjectLoaderIds, SubjectsService} from '../../../services/subjects.service';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {AddressCompleteDto, SUBJECT_ADDRESS_ATTRIBUTE_TYPES} from '../model/addresses.model';
import {
  AddSubjectWizardData,
  StepCreateFormAction,
  StepCreateFormResult,
  SUBJECT_ADDRESS_AS_SENDER_ATTRIBUTE_TYPES,
  SubjectAsSender,
  SubjectRecordSource,
  SubjectRecordWithSourceDto,
} from '../model/subjects.model';
import {
  SelectSubjectDialogComponent,
  SelectSubjectDialogResult,
  SelectSubjectDialogResultAction
} from '../subjects/select-subject-dialog/select-subject-dialog.component';
import {SubjectAddressSelection} from '../subjects/subject-detail-card/subject-detail-card.component';
import {getSearchSubjectFormGroup,} from '../subjects/subject-search-form/subject-search-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  SubjectCreateComponentMode,
  SubjectCreateFormComponent
} from '../subjects/subject-create-form/subject-create-form.component';
import {SubjectIszrIdentificationService} from '../subjects/subject-iszr-identification.service';
import {CodebookService} from '../../../core';
import {IszrRppUserRelationFilteredDto} from '|api/codebook';
import {SubjectsToolbarDisablers} from '../subjects/subjects-toolbar/subjects-toolbar.disablers';
import {SubjectAttributeType} from '../model/subject-attribute-type';
import {of, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';


@Component({
  selector: 'icz-receive-consignment-set-sender',
  templateUrl: './receive-consignment-set-sender.component.html',
  styleUrls: ['./receive-consignment-set-sender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiveConsignmentSetSenderComponent implements OnInit, IczOnChanges {

  private subjectsService = inject(SubjectsService);
  private modalService = inject(IczModalService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private scrollingService = inject(ScrollingService);
  private codebookService = inject(CodebookService);
  private subjectIszrIdentificationService = inject(SubjectIszrIdentificationService);
  private destroyRef = inject(DestroyRef);
  private cd = inject(ChangeDetectorRef);
  private injector = inject(Injector);
  private elementRef = inject(ElementRef);

  @Input() sectionLabel = '';
  @Input() withAddressSelection = true;
  @Input() subjectFieldsToPrefill: Nullable<Partial<Record<SubjectAttributeType, unknown>>>;
  @Input() enforceAddressValidator = true;
  @Output() senderSet = new EventEmitter<SubjectAsSender>();
  @Output() senderAddressSet = new EventEmitter<Nullable<{value: AddressCompleteDto, valid: boolean}>>();
  @Output() anonymousSenderSet = new EventEmitter();
  @Output() blockingAction = new EventEmitter<boolean>();

  _searchForm!: IczFormGroup;
  initialSubjectFormValue!: Record<string, any>;
  iszrAgendasForCurrentFunctionalPosition: IszrRppUserRelationFilteredDto[] = [];

  get searchForm(): IczFormGroup {
    return this._searchForm;
  }
  set searchForm(form: IczFormGroup) {
    this._searchForm = form;
    this.initialSubjectFormValue = this._searchForm.value;
  }
  anonymousSenderFormGroup = new IczFormGroup({
    anonymousSender: new IczFormControl<Nullable<boolean>>(false, [])
  });
  get isAnonymousSender(): Nullable<boolean> {
    return this.anonymousSenderFormGroup?.get('anonymousSender')?.value;
  }

  get isUserNotPermittedForIszrOperations(): boolean {
    return Boolean(SubjectsToolbarDisablers.isUserNotPermittedForIszrOperations(this.iszrAgendasForCurrentFunctionalPosition)());
  }

  createForm = SubjectCreateFormComponent.getCreateSubjectFormGroup();

  searched = false;

  lastFindMode: Nullable<SubjectRecordFindMode> = null;

  searchedSubjects: SubjectRecordWithSourceDto[] = [];

  readonly documentFormOptions = enumToOptions('documentForm', DocumentForm);
  readonly SubjectLoaderIds = SubjectLoaderIds;
  readonly SubjectCreateComponentMode = SubjectCreateComponentMode;
  readonly SubjectRecordFindMode = SubjectRecordFindMode;

  get showSearchForm() {
    return !this.searched || this.manySubjectsFound;
  }

  get showCreateForm() {
    return this.noSubjectFound;
  }

  get noSubjectFound() {
    return this.searched && this.searchedSubjects!.length === 0;
  }

  get oneInternalSubjectFound() {
    return this.searched && this.searchedSubjects!.length === 1 && this.searchedSubjects[0].id;
  }

  get oneExternalSubjectFound() {
    return this.searched && this.searchedSubjects!.length === 1 && !this.searchedSubjects[0].id;
  }

  get oneSubjectFound() {
    return this.oneInternalSubjectFound || this.oneExternalSubjectFound;
  }

  get manySubjectsFound() {
    return this.searched && this.searchedSubjects!.length > 1;
  }

  get classificationControl() {
    return this.searchForm.get('classification');
  }

  get addressForm(): IczFormGroup {
    return this.searchForm.get('address') as IczFormGroup;
  }

  get isSearchFormEmpty(): boolean {
    return this.subjectsService.isSearchFormEmpty(this.searchForm);
  }

  get minimumSetForNewSubjectCreateFilled(): boolean {
    const firstNameControl = this.searchForm.get(SubjectAttributeType.FIRST_NAME);
    const surnameControl = this.searchForm.get(SubjectAttributeType.SURNAME);
    const businessNameControl = this.searchForm.get(SubjectAttributeType.BUSINESS_NAME);

    if (SubjectTemplateUtils.classification(this.searchForm) === SubjectRecordClassification.FO) {
      return (firstNameControl!.value?.length > 1 && surnameControl!.value?.length > 1);
    }
    else return businessNameControl!.value?.length > 2;
  }

  get subjectStepCreateData(): AddSubjectWizardData {
    return {
      relationType: SubjectObjectRelationType.SENDER,
      searchForm: this.searchForm.getRawValue()
    };
  }

  private mapCreateAttributesToSearchForm(attrs: SubjectAttributesDto): Record<string, string | AddressCompleteDto> {
    const result: Record<string, string | AddressCompleteDto> = {};
    Object.entries(attrs).forEach(([k, v]) => {
      if (SUBJECT_ADDRESS_ATTRIBUTE_TYPES.includes(k as SubjectAttributeType)) {
        if (v?.value) {
          result.address = (v as SubjectAttributeAddressDto).value as CzechAddressDto;
        }
      } else {
        if (v && k !== SubjectAttributeType.DATA_BOX) {
          if (v?.value) {
            result[k] = (v as SubjectAttributeStringDto).value as string;
          }
        }
      }
    });

    return result;
  }

  createFormResult(result: StepCreateFormResult) {
    if (result.action === StepCreateFormAction.SEARCH) {
	    this.removeAsSender();
      if (result.subject?.attributes) {
        const patchSearch = this.mapCreateAttributesToSearchForm(result.subject.attributes);
        this.searchForm.patchValue(patchSearch);
        if (patchSearch?.address) {
          this.searchForm.get('address')!.get('value')!.get(patchSearch?.address._Class)!.patchValue(patchSearch.address);
        }
      }
    } else if (result.action === StepCreateFormAction.CREATE) {
      if (!isEmpty(result.subject?.attributes)) {
        this.senderSet.emit({...result.subject!} as SubjectRecordCreateOrUpdateDto);

        const addressKeys: string[] = Object.keys(result.subject!.attributes).filter(a => SUBJECT_ADDRESS_AS_SENDER_ATTRIBUTE_TYPES.includes(a as SubjectAttributeType));

        addressKeys.forEach(addressKey => {
          const addressValue: SubjectAttributeAddressDto | SubjectAttributeAddressDto[] = result.subject!.attributes[addressKey as SubjectAttributeType]!;

          if (addressKey === SubjectAttributeType.ADDITIONAL_ADDRESS) {
            if ((addressValue as AddressCompleteDto[]).length) {
              this.senderAddressSet.emit({value: (addressValue as SubjectAttributeAddressDto[])[0]!.value, valid: true});
            }
          } else {
            if (addressValue) {
                this.senderAddressSet.emit({value: (addressValue as SubjectAttributeAddressDto).value, valid: true});
            }
          }
        });

      }
      else {
        this.emitEmptySender();
      }
    }
  }


  emitSenderAsCreateDtoFromSearchForm() {
    this.senderSet.emit(this.subjectsService.createNewSubjectDtoFromSearchForm(this.searchForm));
    this.senderAddressSet.emit({
      value: formatAddressForDto(SubjectTemplateUtils.addressSearchForm(this.searchForm)),
      valid: this.addressForm.valid
    });
  }

  emitSenderAsFoundFromInternal() {
    this.senderSet.emit(this.searchedSubjects[0]!.id!);
  }

  emitSenderAsCreateNewFromExternal() {
    const sender: SubjectRecordCreateOrUpdateDto = {
      attributes: this.searchedSubjects[0].attributes,
      classification: this.searchedSubjects[0].classification,
      forceMode: false,
      enrichMode: true,
      iszrMetadata: this.searchedSubjects[0].iszrMetadata,
      iszrIdentifier: this.searchedSubjects[0].iszrIdentifier,
      objectRelations: null,
    };
    this.senderSet.emit(sender);
  }

  emitEmptySender() {
    this.senderSet.emit(null);
    this.senderAddressSet.emit(null);
  }

  overrideToCreateSubject() {
    this.searchedSubjects = [];
    this.emitSenderAsCreateDtoFromSearchForm();
  }

  searchSubjects(findMode: SubjectRecordFindMode): void {
    this.loadingIndicatorService.doLoading(this.subjectsService.searchSubjects(this.searchForm, true, findMode)
        .pipe(switchMap((subjects: Array<SubjectRecordWithSourceDto>) => {
            if (subjects.length === 1 && !subjects[0].id && subjects[0].subjectSource === SubjectRecordSource.ISDS_SEARCH) {
              return this.subjectsService.findUsingIsdsFind(subjects[0]).pipe(
                takeUntilDestroyed(this.destroyRef),
                map(subjectFromIsdsFind => {
                  return subjectFromIsdsFind ? [subjectFromIsdsFind] : [];
                }));
            }
            else {
              return of(subjects);
            }
          }
        )),
      this,
      SubjectLoaderIds.SEARCHING)
      .subscribe({
        next: (subjects: Array<SubjectRecordWithSourceDto>) => {
          this.searched = true;
          this.searchedSubjects = subjects.map(s => cloneDeep(s));

          // if no results found, new subject will be created as part of the ReceivedPaperConsignmentCreateDto
          // user will still have to provide a new address, which will be both attribute of this new subject and will be set as senderAddress
          if (this.noSubjectFound) {
            this.emitSenderAsCreateDtoFromSearchForm();
          }
          else if (this.oneInternalSubjectFound) {
            this.emitSenderAsFoundFromInternal();
          }
          else if (this.oneExternalSubjectFound) {
            this.emitSenderAsCreateNewFromExternal();
          }
          else if (this.manySubjectsFound) {
            this.openSubjectSelectionModal();
          }
          this.scrollToTop();
        },
        complete: () => {
          this.searched = true;
          this.lastFindMode = findMode;
        },
      });
  }

  private scrollToTop() {
    setTimeout(() => {
      this.scrollingService.scrollTo(this.elementRef.nativeElement);
    }, 0);
  }

  private openSubjectSelectionModal() {
    this.modalService.openComponentInModal<SelectSubjectDialogResult, SubjectRecordWithSourceDto[]>({
      component: SelectSubjectDialogComponent,
      modalOptions: {
        titleTemplate: 'Nalezeno více subjektů',
        width: 1200,
        height: '90vh',
      },
      data: this.searchedSubjects
    }).subscribe(result => {
      if (result?.result === SelectSubjectDialogResultAction.SELECTED_EXISTING && result?.subject) {
        this.searchedSubjects = [result.subject as SubjectRecordWithSourceDto];
        this.cd.detectChanges();
      } else if (result?.result === SelectSubjectDialogResultAction.CREATE_NEW) {
        this.searchedSubjects = [];
      }
      this.loadingIndicatorService.endLoading(this, SubjectLoaderIds.SEARCHING);
    });
  }

  /**
   * Sets searched subjects to empty array which effectively destroys the subject-detail-card component
   */
  removeAsSender() {
    this.searched = false;
    this.searchedSubjects = [];
    this.senderSet.emit(null); // it's important to reset the sender in paper consignment, else old sender might be set
    this.senderAddressSet.emit(null); // it's important to reset the sender in paper consignment, else old sender might be set
  }

  resetSearchForm() {
    this.searchForm.reset(this.initialSubjectFormValue);
    this.prefillSubjectFields();
  }

  resetAnonymousSenderForm() {
    this.anonymousSenderFormGroup.reset();
  }

  subjectAddressSelected(addressSelection: SubjectAddressSelection) {
    this.senderAddressSet.emit({value: addressSelection.address, valid: true});

    if (addressSelection.existingAddressSelected) {
      if (this.oneInternalSubjectFound) {
        this.emitSenderAsFoundFromInternal();
      } else if (this.oneExternalSubjectFound) {
        this.emitSenderAsCreateNewFromExternal();
      }
    } else {
      const newAddressAsSubjectAttribute: SubjectAttributeAddressDto = {
        source: SubjectAttributeSource.INTERNAL,
        state: SubjectAttributeState.CORRECT,
        validFrom: new Date().toISOString(),
        value: addressSelection.address
      };

      const subjectDtoUpdatedWithNewAddress: SubjectRecordCreateOrUpdateDto = {
        ...this.searchedSubjects[0],
        attributes: {
          ...this.searchedSubjects[0]!.attributes,
          additionalAddresses: [newAddressAsSubjectAttribute!]}
      };

      this.senderSet.emit(subjectDtoUpdatedWithNewAddress);
    }
  }

  ngOnInit() {
    this.codebookService.iszrAgendasWithActivityRolesForCurrentFunctionalPosition().subscribe(iszrAgendas => {
      this.iszrAgendasForCurrentFunctionalPosition = iszrAgendas;
    });

    this.searchForm = getSearchSubjectFormGroup();
    this.anonymousSenderFormGroup.get('anonymousSender')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(anonymousSender => this.anonymousSenderSet.emit(anonymousSender));

    const enableFieldsIfDisabled = (fields: string[]) => {
      fields.forEach(field => {
        if (this.searchForm.get(field)!.disabled) this.searchForm.get(field)!.enable();
      });
    };
    const attrType = SubjectAttributeType;

    this.searchForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      if (this.showSearchForm) {
        if (this.minimumSetForNewSubjectCreateFilled) {
          this.emitSenderAsCreateDtoFromSearchForm();
        }
        else {
          this.emitEmptySender();
        }
      }
    });
    this.classificationControl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(classification => {
      if (SubjectTemplateUtils.isNaturalPerson(classification)) {
        enableFieldsIfDisabled([attrType.FIRST_NAME, attrType.SURNAME, attrType.BIRTH_DATE]);
        this.searchForm.get(attrType.BUSINESS_NAME)!.setValue(null);
        this.searchForm.get(attrType.BUSINESS_NAME)!.disable({emitEvent: false});
        this.searchForm.get(attrType.CID)!.setValue(null);
        this.searchForm.get(attrType.CID)!.disable({emitEvent: false});
      }
      else if (SubjectTemplateUtils.isLegalPerson(classification)) {
        enableFieldsIfDisabled([attrType.BUSINESS_NAME, attrType.CID]);
        this.searchForm.get(attrType.FIRST_NAME)!.setValue(null);
        this.searchForm.get(attrType.FIRST_NAME)!.disable({emitEvent: false});
        this.searchForm.get(attrType.SURNAME)!.setValue(null);
        this.searchForm.get(attrType.SURNAME)!.disable({emitEvent: false});
        this.searchForm.get(attrType.BIRTH_DATE)!.setValue(null);
        this.searchForm.get(attrType.BIRTH_DATE)!.disable({emitEvent: false});
      }
      else if (SubjectTemplateUtils.isBusinessIndividual(classification)) {
        enableFieldsIfDisabled([
          attrType.FIRST_NAME,
          attrType.SURNAME,
          attrType.BIRTH_DATE,
          attrType.BUSINESS_NAME,
          attrType.CID,
        ]);
      }
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.subjectFieldsToPrefill && this.subjectFieldsToPrefill) {
      this.prefillSubjectFields();
    }
  }

  identifySubjectFromSearchForm() {
    const searchValue = this.searchForm.getRawValue();
    this.subjectIszrIdentificationService.tryIdentifySubject(searchValue, true, this.injector).subscribe(identifiedSubject => {
      if (identifiedSubject?.subject) {
        this.searched = true;
        const subjectWithSource = {...identifiedSubject.subject, subjectSource: SubjectRecordSource.ISZR};
        this.searchedSubjects = [subjectWithSource];

        this.emitSenderAsCreateNewFromExternal();
      }
    });
  }

  private prefillSubjectFields() {
    if (this.subjectFieldsToPrefill) {
      const prefillFirstName = this.subjectFieldsToPrefill[SubjectAttributeType.FIRST_NAME];
      const prefillLastName = this.subjectFieldsToPrefill[SubjectAttributeType.SURNAME];
      const prefillBusinessName = this.subjectFieldsToPrefill[SubjectAttributeType.BUSINESS_NAME];

      if (prefillFirstName) {
        this.searchForm.get('firstName')!.setValue(prefillFirstName);
      }
      if (prefillLastName) {
        this.searchForm.get('surname')!.setValue(prefillLastName);
      }
      if (prefillBusinessName) {
        this.searchForm.get('classification')!.setValue(SubjectRecordClassification.PO);
        this.searchForm.get('businessName')!.setValue(prefillBusinessName);
      }
    }
  }

}
