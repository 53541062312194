@if ((label | translate); as translatedLabel) {
  <div
    class="input-label"
    [iczTooltip]="translatedLabel"
  >
    {{ translatedLabel }}
  </div>
}

<div
  class="input-wrapper flexible-height"
  [class.disabled]="!withWhiteBackground"
  >
  <div class="infix">
    @if (!value && placeholder) {
      <span
        class="content-text icz-select-placeholder cursor-default grow"
        [iczTooltip]="placeholder | translate"
      >
        {{ placeholder | translate }}
      </span>
    }
    @if (value) {
      @if (!options?.length && !isValueArray) {
        <span class="content-text grow" [iczTooltip]="'' + value">{{ value }}</span>
      }
      @if (options?.length && !isValueArray) {
        @if (value | findInList:(options ?? []) | translate; as translatedValue) {
          <span class="content-text grow" [iczTooltip]="translatedValue">{{ translatedValue }}</span>
        }
      }
      @if (isValueArray) {
        <div class="chip-list">
          @for (item of $any(value); track item) {
            @if ((item ?? '') | findInList:(options ?? []); as optionLabel) {
              <icz-form-autocomplete-chip
                [removable]="false"
                [iczTooltip]="optionLabel"
              >
                {{ optionLabel | translate }}
              </icz-form-autocomplete-chip>
            }
          }
        </div>
      }
    }
    <ng-content></ng-content>
  </div>
  <div class="suffix">
    <ng-content select="[suffix]"></ng-content>
  </div>
</div>
