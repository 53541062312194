/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { iszrIntegrationAdministrationCreate } from '../fn/iszr-integration-administration/iszr-integration-administration-create';
import { IszrIntegrationAdministrationCreate$Params } from '../fn/iszr-integration-administration/iszr-integration-administration-create';
import { iszrIntegrationAdministrationDelete } from '../fn/iszr-integration-administration/iszr-integration-administration-delete';
import { IszrIntegrationAdministrationDelete$Params } from '../fn/iszr-integration-administration/iszr-integration-administration-delete';
import { iszrIntegrationAdministrationGet } from '../fn/iszr-integration-administration/iszr-integration-administration-get';
import { IszrIntegrationAdministrationGet$Params } from '../fn/iszr-integration-administration/iszr-integration-administration-get';
import { iszrIntegrationAdministrationGetAll } from '../fn/iszr-integration-administration/iszr-integration-administration-get-all';
import { IszrIntegrationAdministrationGetAll$Params } from '../fn/iszr-integration-administration/iszr-integration-administration-get-all';
import { iszrIntegrationAdministrationGetAllFiltered } from '../fn/iszr-integration-administration/iszr-integration-administration-get-all-filtered';
import { IszrIntegrationAdministrationGetAllFiltered$Params } from '../fn/iszr-integration-administration/iszr-integration-administration-get-all-filtered';
import { iszrIntegrationAdministrationUpdate } from '../fn/iszr-integration-administration/iszr-integration-administration-update';
import { IszrIntegrationAdministrationUpdate$Params } from '../fn/iszr-integration-administration/iszr-integration-administration-update';
import { IszrRppUserRelationDto } from '../models/iszr-rpp-user-relation-dto';
import { IszrRppUserRelationFilteredDto } from '../models/iszr-rpp-user-relation-filtered-dto';


/**
 * Contains endpoints related to ISZR RPP relation management.
 */
@Injectable({ providedIn: 'root' })
export class ApiIszrIntegrationAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `iszrIntegrationAdministrationGet()` */
  static readonly IszrIntegrationAdministrationGetPath = '/codebook/iszr-rpp-user-relation/{id}';

  /**
   * Gets ISZR Rpp user relation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrIntegrationAdministrationGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationGet$Response(params: IszrIntegrationAdministrationGet$Params, context?: HttpContext): Observable<StrictHttpResponse<IszrRppUserRelationDto>> {
    return iszrIntegrationAdministrationGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets ISZR Rpp user relation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrIntegrationAdministrationGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationGet(params: IszrIntegrationAdministrationGet$Params, context?: HttpContext): Observable<IszrRppUserRelationDto> {
    return this.iszrIntegrationAdministrationGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrRppUserRelationDto>): IszrRppUserRelationDto => r.body)
    );
  }

  /** Path part for operation `iszrIntegrationAdministrationUpdate()` */
  static readonly IszrIntegrationAdministrationUpdatePath = '/codebook/iszr-rpp-user-relation/{id}';

  /**
   * Updates ISZR rpp user relation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrIntegrationAdministrationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  iszrIntegrationAdministrationUpdate$Response(params: IszrIntegrationAdministrationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<IszrRppUserRelationDto>> {
    return iszrIntegrationAdministrationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates ISZR rpp user relation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrIntegrationAdministrationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  iszrIntegrationAdministrationUpdate(params: IszrIntegrationAdministrationUpdate$Params, context?: HttpContext): Observable<IszrRppUserRelationDto> {
    return this.iszrIntegrationAdministrationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrRppUserRelationDto>): IszrRppUserRelationDto => r.body)
    );
  }

  /** Path part for operation `iszrIntegrationAdministrationDelete()` */
  static readonly IszrIntegrationAdministrationDeletePath = '/codebook/iszr-rpp-user-relation/{id}';

  /**
   * Deletes ISZR rpp user relation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrIntegrationAdministrationDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationDelete$Response(params: IszrIntegrationAdministrationDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return iszrIntegrationAdministrationDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes ISZR rpp user relation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrIntegrationAdministrationDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationDelete(params: IszrIntegrationAdministrationDelete$Params, context?: HttpContext): Observable<void> {
    return this.iszrIntegrationAdministrationDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `iszrIntegrationAdministrationCreate()` */
  static readonly IszrIntegrationAdministrationCreatePath = '/codebook/iszr-rpp-user-relation/';

  /**
   * Creates ISZR rpp user relation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrIntegrationAdministrationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  iszrIntegrationAdministrationCreate$Response(params: IszrIntegrationAdministrationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<IszrRppUserRelationDto>> {
    return iszrIntegrationAdministrationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates ISZR rpp user relation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrIntegrationAdministrationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  iszrIntegrationAdministrationCreate(params: IszrIntegrationAdministrationCreate$Params, context?: HttpContext): Observable<IszrRppUserRelationDto> {
    return this.iszrIntegrationAdministrationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrRppUserRelationDto>): IszrRppUserRelationDto => r.body)
    );
  }

  /** Path part for operation `iszrIntegrationAdministrationGetAll()` */
  static readonly IszrIntegrationAdministrationGetAllPath = '/codebook/iszr-rpp-user-relation';

  /**
   * Returns all ISZR rpp user relation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrIntegrationAdministrationGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationGetAll$Response(params?: IszrIntegrationAdministrationGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IszrRppUserRelationDto>>> {
    return iszrIntegrationAdministrationGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all ISZR rpp user relation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrIntegrationAdministrationGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationGetAll(params?: IszrIntegrationAdministrationGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<IszrRppUserRelationDto>> {
    return this.iszrIntegrationAdministrationGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IszrRppUserRelationDto>>): Array<IszrRppUserRelationDto> => r.body)
    );
  }

  /** Path part for operation `iszrIntegrationAdministrationGetAllFiltered()` */
  static readonly IszrIntegrationAdministrationGetAllFilteredPath = '/codebook/iszr-rpp-user-relation/filtered';

  /**
   * Returns ISZR rpp user relation for current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrIntegrationAdministrationGetAllFiltered()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationGetAllFiltered$Response(params?: IszrIntegrationAdministrationGetAllFiltered$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IszrRppUserRelationFilteredDto>>> {
    return iszrIntegrationAdministrationGetAllFiltered(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns ISZR rpp user relation for current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrIntegrationAdministrationGetAllFiltered$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrIntegrationAdministrationGetAllFiltered(params?: IszrIntegrationAdministrationGetAllFiltered$Params|null|undefined, context?: HttpContext): Observable<Array<IszrRppUserRelationFilteredDto>> {
    return this.iszrIntegrationAdministrationGetAllFiltered$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IszrRppUserRelationFilteredDto>>): Array<IszrRppUserRelationFilteredDto> => r.body)
    );
  }

}
