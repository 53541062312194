/* tslint:disable */
/* eslint-disable */

/**
 * Type of a job that will transfer (or transferred) ownership (and/or holdership) of all or selected objects of one or more source functional positions to respective target functional position(s).
 */
export enum FpOwnershipTransferJobType {
  SINGLE_TRANSFER_IMMEDIATE_EXECUTION = 'SINGLE_TRANSFER_IMMEDIATE_EXECUTION',
  SINGLE_TRANSFER_SCHEDULED_EXECUTION = 'SINGLE_TRANSFER_SCHEDULED_EXECUTION',
  BULK_TRANSFER_IMMEDIATE_EXECUTION = 'BULK_TRANSFER_IMMEDIATE_EXECUTION',
  BULK_TRANSFER_SCHEDULED_EXECUTION = 'BULK_TRANSFER_SCHEDULED_EXECUTION'
}
