import {DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {CirculationActivityFlowType, FileForm} from '|api/commons';
import {DocumentDto, FileDto} from '|api/document';
import {CirculationTaskDto} from '|api/flow';
import {IczDateValidators} from '../../../form-elements/validators/icz-validators/icz-date-validators';
import {locateOptionByValue, Option} from '../../../../model';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {
  OrganizationalStructureOption,
  OrganizationalStructureService
} from '../../../../core/services/organizational-structure.service';
import {FunctionalPositionGroup, FunctionalPositionGroupItems} from '../../saved-functional-positions.service';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

interface DerivedOrgStructureElementOptionData {
  originalValue?: Nullable<string | number>;
}

export type CirculationEntity = DocumentDto | FileDto | CirculationTaskDto | EsslComponentDto;

export type DerivedOrgStructureElementOption = Option<Nullable<string | number>, DerivedOrgStructureElementOptionData>;

/**
 * This abstract class contains common behaviors of all handover forms.
 * protected members are meant to be overridden or used in specializations, private members not.
 */

@Directive()
export abstract class AbstractHandoverFormComponent implements OnInit {

  protected orgStructureService = inject(OrganizationalStructureService);
  protected destroyRef = inject(DestroyRef);

  readonly deadlineValidator = IczDateValidators.presentOrFutureWithouWeekendValidator;
  readonly FileForm = FileForm;

  protected circulationEntities!: CirculationEntity[];

  // It MUST contain a control of name "orgStructureElementIds"
  // otherwise you have to override orgStructureElementIdsFormControlName.
  // It also MIGHT contain a control determining participants behavior
  // which is then used for computing participant order in activity process.
  form!: IczFormGroup;

  orgStructureOptions: OrganizationalStructureOption[] = [];

  selectedParticipants: Option[] = [];

  // Useful for overriding in case of creating singleselect controls for participants / wanting to save a group with specific order.
  get groupForSaving(): FunctionalPositionGroupItems {
    return this.orgStructureElementsControl?.value;
  }

  // This property should be defined in each subclass either as a getter or
  // as a plain property and should indicate the set of available flow types
  // for given activity type.
  protected abstract selectedFlowType: Nullable<CirculationActivityFlowType>;

  // Useful for overriding in case of creating singleselect controls for participants.
  protected orgStructureElementIdsControlName = 'orgStructureElementIds';

  // Useful when some endpoint does not accept functional position
  // object of shape {functionalPositionId, taskAssignmentOrder}
  protected functionalPositionMapper = (o: DerivedOrgStructureElementOption, index: number): any => ({
    functionalPositionId: o.value,
    taskAssignmentOrder: this.generateTaskOrder(o, index),
  });

  // Useful when some endpoint does not accept organizational unit
  // object of shape {organizationalUnitId, taskAssignmentOrder}
  protected organizationalUnitMapper = (o: DerivedOrgStructureElementOption, index: number): any => ({
    organizationalUnitId: o.value,
    taskAssignmentOrder: this.generateTaskOrder(o, index),
  });

  // Useful for overriding in case of creating singleselect controls for participants.
  protected get selectedOrgStructureOptions(): DerivedOrgStructureElementOption[] {
    return this.orgStructureElementsControl!.value.map((orgStructureOptionValue: number) => {
      const foundOption = locateOptionByValue(this.orgStructureOptions, orgStructureOptionValue);
      // todo fix in the whole app: orgStructureService provides options where OU
      //  options have numerical IDs and FP options have string code IDs though
      //  APIs accept only numerical IDs. => in progress of fixing using common postgres sequence
      return foundOption ?
        {...foundOption, value: foundOption.id, data: {originalValue: foundOption.value}} :
        null;
    });
  }

  // Specifies if a given form contains a sorter component for
  // setting participant order. Found in each dialog with allowed
  // SERIAL/SELECTIVE flow type.
  protected participantsOrderControlName: Nullable<string> = null;

  // Not meant to be overridden => override orgStructureElementIdsControlName.
  protected get orgStructureElementsControl(): IczFormControl {
    return this.form?.get(this.orgStructureElementIdsControlName)! as IczFormControl;
  }

  // Not meant to be overridden => override participantsOrderControlName.
  protected get participantsOrderControl(): Nullable<IczFormControl> {
    return this.form.get(this.participantsOrderControlName!) as Nullable<IczFormControl>;
  }

  // Not meant to be overridden => override functionalPositionMapper.
  protected get selectedFunctionalPositions() {
    return this.selectedOrgStructureOptions
      .filter(o => o.originId === 'fp')
      .map(this.functionalPositionMapper);
  }

  // Not meant to be overridden => override organizationalUnitMapper.
  protected get selectedOrganizationalUnits() {
    return this.selectedOrgStructureOptions
      .filter(o => o.originId === 'ou')
      .map(this.organizationalUnitMapper);
  }

  ngOnInit() {
    this.orgStructureService.orgStructureOptions().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(options => this.orgStructureOptions = options);

    if (this.participantsOrderControlName && this.participantsOrderControl) {
      this.orgStructureElementsControl.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe((orgStructureElementIds: number[]) => {
        this.selectedParticipants = (orgStructureElementIds ?? []).map(
          value => locateOptionByValue(this.orgStructureOptions, value)!
        );
        this.participantsOrderControl!.setValue(orgStructureElementIds);
      });
    }
  }

  applySavedGroup(functionalPositionGroup: Nullable<FunctionalPositionGroup>) {
    this.orgStructureElementsControl!.setValue(functionalPositionGroup?.items ?? []);
  }

  private generateTaskOrder(option: DerivedOrgStructureElementOption, index: number): Nullable<number> {
    if (
      this.selectedFlowType === CirculationActivityFlowType.SERIAL ||
      this.selectedFlowType === CirculationActivityFlowType.SELECTIVE
    ) {
      // NOTE: taskAssignmentOrder is one-based number series!
      if (this.participantsOrderControlName && this.participantsOrderControl) {
        const taskParticipantsOrder: Array<string|number> = this.participantsOrderControl.value;
        return taskParticipantsOrder.indexOf(option.data!.originalValue!) + 1;
      }
      else {
        if (option.originId === 'fp') {
          return index + 1;
        }
        else if (option.originId === 'ou') {
          return this.selectedFunctionalPositions.length + index + 1;
        }
        else {
          return -1;
        }
      }
    }
    else {
      return null;
    }
  }

}
