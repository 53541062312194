/* tslint:disable */
/* eslint-disable */

/**
 * Epdz Error action Type
 */
export enum EpdzErrorActionType {
  REDELIVER = 'REDELIVER',
  REJECT = 'REJECT',
  CANCEL = 'CANCEL'
}
