/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { NumberingConfigurationDto } from '../../models/numbering-configuration-dto';

export interface NumberingConfigurationFindById$Params {

/**
 * Id of numbering configuration to be found
 */
  id: number;
}

export function numberingConfigurationFindById(http: HttpClient, rootUrl: string, params: NumberingConfigurationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<NumberingConfigurationDto>> {
  const rb = new RequestBuilder(rootUrl, numberingConfigurationFindById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<NumberingConfigurationDto>;
    })
  );
}

numberingConfigurationFindById.PATH = '/codebook/numbering-configuration/{id}';