/* tslint:disable */
/* eslint-disable */

/**
 * Analog component protection
 */
export enum AnalogProtectiveElement {
  PLASTIC_TEXT = 'PLASTIC_TEXT',
  WATERMARK = 'WATERMARK',
  RELIEF_PRINTING = 'RELIEF_PRINTING',
  EMBOSSING = 'EMBOSSING',
  DRY_SEAL = 'DRY_SEAL',
  RELIEF_EMBOSSING = 'RELIEF_EMBOSSING',
  OPTICAL_VARIABLE_ELEMENT = 'OPTICAL_VARIABLE_ELEMENT'
}
