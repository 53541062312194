import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ENVIRONMENT} from '../services/environment.service';
import {isIgnoredRequestUrl} from '../../services/services-utils';

@Injectable()
export class UrlBuildingInterceptor implements HttpInterceptor {

  private environment = inject(ENVIRONMENT);

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isIgnoredRequestUrl(req, this.environment)) {
      return next.handle(req);
    }
    else {
      return next.handle(req.clone({
        url: this.environment.apiServicesUrl + req.url,
      }));
    }
  }

}
