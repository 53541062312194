<p class="icz-body-strong">{{ 'Výraz nalezen' | translate }}:</p>

@for (highlight of highlights; track highlight) {
  <p class="icz-body-1">
    <span class="icz-body-strong">{{ (columnNames[highlight.field] ?? '') | translate }}</span><br>
    @for (fragment of highlight.fragments; track fragment) {
      <span
        [iczHighlightText]="fragment.content"
        [iczHighlightText.offsets]="fragment.parts"
        [iczHighlightText.highlightAll]="shouldHighlightEverything(fragment.parts)">
      </span>
    }
  </p>
}
