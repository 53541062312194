/* tslint:disable */
/* eslint-disable */
export enum StorageUnitPermission {
  SHOW_IN_LIST = 'SHOW_IN_LIST',
  PROFILE_READ = 'PROFILE_READ',
  PROFILE_WRITE = 'PROFILE_WRITE',
  DELETE = 'DELETE',
  CONTENT_SHOW_LIST = 'CONTENT_SHOW_LIST',
  CONTENT_ADD = 'CONTENT_ADD',
  CONTENT_WITHDRAW = 'CONTENT_WITHDRAW',
  CONTENT_MOVE = 'CONTENT_MOVE',
  BULK_CONTENT_PROFILE_ONLY_LEVEL = 'BULK_CONTENT_PROFILE_ONLY_LEVEL',
  BULK_CONTENT_READ_ONLY_LEVEL = 'BULK_CONTENT_READ_ONLY_LEVEL',
  BULK_CONTENT_READ_AND_WRITE_LEVEL = 'BULK_CONTENT_READ_AND_WRITE_LEVEL',
  BULK_CONTENT_FULL_ACCESS_LEVEL = 'BULK_CONTENT_FULL_ACCESS_LEVEL',
  PERMISSION_SET_READ = 'PERMISSION_SET_READ',
  PERMISSION_SET_WRITE = 'PERMISSION_SET_WRITE',
  HISTORY_SHOW_LIST = 'HISTORY_SHOW_LIST',
  ACTIVITY_SHOW_LIST = 'ACTIVITY_SHOW_LIST',
  ACTIVITY_MANAGE = 'ACTIVITY_MANAGE',
  TASK_SETTLE_OR_REJECT = 'TASK_SETTLE_OR_REJECT'
}
