<div class="row" [formGroup]="form" (keydown.enter)="applyFilter()">

  <div class="col grow">
    <icz-form-inline-select
      formControlName="filterOperator"
      [options]="filterOperators"
      (openStatusChanged)="isOperatorSelectorOpen = $event">
    </icz-form-inline-select>

    <div class="filter-body"
         [style.height.px]="canDisplayFilterBody ? null : 1"
         [style.opacity]="canDisplayFilterBody ? null : 0">
      <icz-address
        [form]="addressForm"
        groupName="."
        [disableAllValidators]="true"
        [isSearchMode]="true"
        (addressChanged)="addressFilterChanged($event)"
      >
      </icz-address>
    </div>
  </div>
</div>
