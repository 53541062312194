/* tslint:disable */
/* eslint-disable */

/**
 * Import metadata state
 */
export enum ImportMetadataState {
  ADDED = 'ADDED',
  DELETED = 'DELETED',
  MODIFIED = 'MODIFIED',
  UNMODIFIED = 'UNMODIFIED',
  ERROR = 'ERROR'
}
