/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiRegistryOfficeService } from './services/api-registry-office.service';
import { ApiOfficeDeskRegionService } from './services/api-office-desk-region.service';
import { ApiOfficeDeskCategoryService } from './services/api-office-desk-category.service';
import { ApiNumberingConfigurationService } from './services/api-numbering-configuration.service';
import { ApiIszrIntegrationAdministrationService } from './services/api-iszr-integration-administration.service';
import { ApiFilingOfficeService } from './services/api-filing-office.service';
import { ApiExternalRetentionTriggerService } from './services/api-external-retention-trigger.service';
import { ApiDistributorService } from './services/api-distributor.service';
import { ApiDisposalScheduleService } from './services/api-disposal-schedule.service';
import { ApiDispatchOfficeService } from './services/api-dispatch-office.service';
import { ApiDeliveryServiceCombinationService } from './services/api-delivery-service-combination.service';
import { ApiDeliveryServiceBasicService } from './services/api-delivery-service-basic.service';
import { ApiDeliveryServiceAdditionalService } from './services/api-delivery-service-additional.service';
import { ApiDeliveryResultService } from './services/api-delivery-result.service';
import { ApiCrossReferenceTemplateService } from './services/api-cross-reference-template.service';
import { ApiSecurityCategoryService } from './services/api-security-category.service';
import { ApiKeywordService } from './services/api-keyword.service';
import { ApiFileTypeService } from './services/api-file-type.service';
import { ApiEntityClassService } from './services/api-entity-class.service';
import { ApiDocumentTypeService } from './services/api-document-type.service';
import { ApiDeliveryTypeService } from './services/api-delivery-type.service';
import { ApiClassificationService } from './services/api-classification.service';
import { ApiClassificationSchemeService } from './services/api-classification-scheme.service';
import { ApiAnalogComponentTypeService } from './services/api-analog-component-type.service';
import { ApiFormatRegisterService } from './services/api-format-register.service';
import { ApiTestingPurposeOnlyService } from './services/api-testing-purpose-only.service';
import { ApiBusinessRulesService } from './services/api-business-rules.service';
import { ApiTemplateParameterService } from './services/api-template-parameter.service';
import { ApiIszrService } from './services/api-iszr.service';
import { ApiEsslEventTypeService } from './services/api-essl-event-type.service';
import { ApiDeliveryServiceGroupService } from './services/api-delivery-service-group.service';
import { ApiCsuLegalformService } from './services/api-csu-legalform.service';
import { ApiCsuCzemService } from './services/api-csu-czem.service';
import { ApiCsuCmfService } from './services/api-csu-cmf.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiRegistryOfficeService,
    ApiOfficeDeskRegionService,
    ApiOfficeDeskCategoryService,
    ApiNumberingConfigurationService,
    ApiIszrIntegrationAdministrationService,
    ApiFilingOfficeService,
    ApiExternalRetentionTriggerService,
    ApiDistributorService,
    ApiDisposalScheduleService,
    ApiDispatchOfficeService,
    ApiDeliveryServiceCombinationService,
    ApiDeliveryServiceBasicService,
    ApiDeliveryServiceAdditionalService,
    ApiDeliveryResultService,
    ApiCrossReferenceTemplateService,
    ApiSecurityCategoryService,
    ApiKeywordService,
    ApiFileTypeService,
    ApiEntityClassService,
    ApiDocumentTypeService,
    ApiDeliveryTypeService,
    ApiClassificationService,
    ApiClassificationSchemeService,
    ApiAnalogComponentTypeService,
    ApiFormatRegisterService,
    ApiTestingPurposeOnlyService,
    ApiBusinessRulesService,
    ApiTemplateParameterService,
    ApiIszrService,
    ApiEsslEventTypeService,
    ApiDeliveryServiceGroupService,
    ApiCsuLegalformService,
    ApiCsuCzemService,
    ApiCsuCmfService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
