import {Observable, of} from 'rxjs';
import {Page} from '../api';
import {ApiKeywordService, KeywordDto} from '|api/codebook';
import {CodebookSearchService} from './codebook-search.service';
import {FilterOperator, SearchParams} from './search-api.service';

export function findKeywordsUsingFulltext(searchTerm: string, apiKeywordService: ApiKeywordService) {
  return apiKeywordService.keywordFindAllBySearchedTerm({
    body: {
      searchedTerm: searchTerm,
    }
  });
}

export function findKeywordsByIDs(ids: number[], searchService: CodebookSearchService): Observable<Page<KeywordDto>> {
  if (!ids || !ids.length) return of({content: [], totalElements: 0});

  const searchParams: SearchParams = {
    filter: [
      {
        fieldName: 'id',
        operator: FilterOperator.inSet,
        value: ids.toString(),
      },
    ],
    sort: [{fieldName: 'code'}],
    page: 0,
    size: ids.length,
  };

  return searchService.findKeywords(searchParams);
}
