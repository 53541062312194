@if (consignment) {
  @if (isOwnPaperConsignment(consignment) && consignment.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS) {
    <icz-address-details
      [addressWithType]="addressToAddressWithType(consignment.consigneeAddress)"
    ></icz-address-details>
  }
  @if (isOwnDigitalConsignment(consignment) && consignment.consignmentType === ConsignmentType.OWN_DIGITAL_EMAIL) {
    <div>
      {{ consignment.consigneeEmail }}
    </div>
  }
  @if (isOwnDigitalConsignment(consignment) && consignment.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
    <div>
      <!-- todo(rb) implement invalidated/suspended databox warning after BE ready -->
      {{ consignment.consigneeDataBox?.id }}
    </div>
  }
  @if (isOwnInternalConsignment(consignment)) {
    <icz-address-details
      [addressWithType]="filingOfficeAddress(consignment.filingOfficeSheetNodeId!)"
    ></icz-address-details>
  }
  @if (isOwnFaxConsignment(consignment)) {
    <div>
      {{ consignment.consigneePhoneNumber }}
    </div>
  }
}
