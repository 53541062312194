import {DestroyRef, Directive, ElementRef, EventEmitter, inject} from '@angular/core';
import {FilterType, isFilterWithOptions, NonemptyFilterItem} from '../filter.types';
import {FilterValue} from '../table.models';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {FilterOperator, isNoValueOperator} from '../../../services/search-api.service';
import {Option} from '../../../model';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

/**
 * Abstract class used for creation of custom filters.
 */
export abstract class IczTableFilter {

  set item(newValue: NonemptyFilterItem) { this._item = newValue; }
  get item(): NonemptyFilterItem { return this._item; }
  protected _item!: NonemptyFilterItem;

  closePopup = new EventEmitter<void>();
  setFilterValue = new EventEmitter<FilterValue>();
  isOperatorSelectorOpen = false;
  isLoading = false;
  form!: IczFormGroup;

  get canDisplayFilterBody(): boolean {
    return !this.isOperatorSelectorOpen && !isNoValueOperator(this.currentOperator);
  }

  closeFilterPopup() {
    this.closePopup.emit();
  }

  get currentOperator(): FilterOperator {
    return this.form.get('filterOperator')!.value;
  }

  initForm() {
    this.form = new IczFormGroup({
      filterOperator: new IczFormControl<Nullable<FilterOperator>>(this.initialFilterOperator),
      value: new IczFormControl<Nullable<string | string[]>>(this.initialFilterValue),
    });
  }

  filterOperators: Array<Option<FilterOperator>> = [];

  abstract applyFilter(): void;
  protected abstract emitFilterValue(): void;
  protected abstract applyFilterOperator(): void;

  protected get initialFilterOperator(): FilterOperator {
    return this.item.filterOption?.value
      ? this.item.filterOption.value
      : this.filterOperators[0]?.value;
  }

  protected get initialFilterValue(): Nullable<string | string[]> {
    if (!this.item?.value) return null;

    if (this.isFilterWithOptions) {
      if (this.item.filterType === FilterType.ENUM || this.item.filterType === FilterType.CODEBOOK) {
        return this.item.value;
      }
      else if (Array.isArray(this.item.value)) {
        return this.item.value[0];
      }
      else {
        return this.item.value;
      }
    }
    else {
      return this.item.value;
    }
  }

  protected get isFilterWithOptions(): boolean {
    return isFilterWithOptions(this.item.filterType);
  }

  protected emitNoValueFilterValue(filterOperator: FilterOperator) {
    this.setFilterValue.emit({
      value: undefined,
      viewValue: undefined,
      subValues: undefined,
      label: null,
      closeAfter: true,
      filterOperator,
    });
  }
}

@Directive()
export abstract class IczTableFilterWithInternalForm extends IczTableFilter {

  protected destroyRef = inject(DestroyRef);
  protected el = inject(ElementRef);

  protected initOperatorAndValueChangeHandlers() {
    this.form.get('value')!.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.emitFilterValue();
    });

    this.form.get('filterOperator')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.applyFilterOperator();
    });
  }

  applyFilterOperator(): void {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();

      this.setFilterValue.emit({
        value: undefined,
        viewValue: undefined,
        subValues: undefined,
        label: null,
        closeAfter: true,
        filterOperator,
      });

      this.closeFilterPopup();
    }
    else if (valueControl!.value) {
      this.emitFilterValue();
    }
    else {
      valueControl!.enable();
      setTimeout(() => this.el.nativeElement.querySelector('.filter-body icz-form-field input')?.focus());
    }
  }

}

@Directive()
export abstract class IczTableFilterWithList extends IczTableFilterWithInternalForm {

  get hasNoValueFilterOperator(): boolean {
    return isNoValueOperator(this.currentOperator);
  }

  protected emitFilterValue(): void {
    const value = this.form.get('value')!.value;

    if (!this.isFilterWithOptions && value === null) return;

    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (this.hasNoValueFilterOperator) {
      this.setFilterValue.emit({
        value: undefined,
        label: null,
        filterOperator,
        closeAfter: true,
      });
    }
    else {
      this.setFilterValue.emit({
        value,
        list: this.item.list,
        label: null,
        filterOperator,
      });
    }
  }

  override applyFilterOperator() {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();

      this.setFilterValue.emit({
        value: undefined,
        viewValue: undefined,
        subValues: undefined,
        label: null,
        closeAfter: true,
        filterOperator,
      });

      this.closeFilterPopup();
    }
    else if (valueControl!.value) {
      this.emitFilterValue();
    }
    else {
      valueControl!.enable();
      setTimeout(() => this.el.nativeElement.querySelector('.filter-body icz-form-field input')?.focus());
    }
  }

}
