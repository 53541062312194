import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {delay, filter, take, takeUntil, tap} from 'rxjs/operators';
import {CLOSE_THIS_TOAST, MessageType, TemplateDefinition, ToastMessage} from '../../toast.service';
import {Button} from '../../../essentials/button-collection/button-collection.component';
import {animationDuration, appearAndShrinkAnimation} from '../../../../animations/animations';


@Component({
  selector: 'icz-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [
    appearAndShrinkAnimation,
  ],
})
export class ToastMessageComponent implements OnInit {
  @Input({required: true}) message!: ToastMessage;
  @Input({required: true}) canHide!: boolean;

  @Output() dismiss = new EventEmitter<any>();

  haltClosing$ = new Subject<void>();
  showHaltIcon = false;
  shouldHide = false;
  animationDisable$ = new BehaviorSubject<boolean>(true);

  readonly MessageType = MessageType;

  onClose() {
    this.shouldHide = true;
    this.dismiss.emit();
  }

  ngOnInit(): void {
    // TODO(pk) hack to make expanded panel closed while animation. Issue: https://github.com/angular/components/issues/11765
    setTimeout(() => this.animationDisable$.next(false), animationDuration);

    of(null).pipe(
      filter(() => this.message?.type !== MessageType.ERROR && !this.message.isPermanent),
      delay(this.message?.duration ?? 0),
      takeUntil(this.haltClosing$),
      take(1),
    ).subscribe(() => this.onClose());
  }

  mouseEnter() {
    if (this.message.type === MessageType.ERROR) return;

    this.haltClosing$.next();

    if (this.message?.isBackendNotification) {
      this.showHaltIcon = true;
    }
  }

  mouseLeft() {
    if (this.message.type === MessageType.ERROR) return;

    of(null).pipe(
      delay(1_000),
      tap(() => this.showHaltIcon = false),
      delay(this.message.duration),
      takeUntil(this.haltClosing$),
      take(1),
    ).subscribe(() => this.onClose());
  }

  processButtonAction(button: Button) {
    const result = button.action?.(button);

    if (result === CLOSE_THIS_TOAST) {
      this.onClose();
    }
  }

  isMessageTemplateArray(
    template: TemplateDefinition<unknown> | Array<TemplateDefinition<unknown>>,
  ): template is Array<TemplateDefinition<unknown>> {
    return Array.isArray(template);
  }
}
