/* eslint-disable no-console */
import {Observable, OperatorFunction, pipe} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {Page} from '../api';

type Constructor<C> = new (...args: any[]) => C;

export function castStream<R>(): OperatorFunction<any, R> {
  return pipe(
    map(e => e as unknown as R),
  );
}

export function filterByClass<T, R>(ctorFunction: Constructor<R>): OperatorFunction<T, R> {
  return pipe(
    castStream<R>(),
    filter<R>(e => e instanceof ctorFunction),
  );
}

/**
 * Use as the tap operator to log values which go through the pipe.
 * For development purpose only.
 * Usage:
 * ```
 * observable.pipe(
 *   log('value'),
 * )
 * ```
 */
export function log<T>(message?: string, loglevel: 'log'|'info'|'warn'|'error' = 'log'): OperatorFunction<T, T> {
  return tap({
    next: value => console[loglevel](message, 'next', value),
    error: error => console[loglevel](message, 'finished with error', error),
    complete: () => console[loglevel](message, 'finished via complete'),
  });
}

/**
 * This custom RxJS operator transforms unpaged data in array to paged data
 * for the needs of IczTableDataSource descendants
 */
export function dataToPage<T>(page: Nullable<number> = null, size: Nullable<number> = null) {
  return (input$: Observable<T[]>): Observable<Page<T>> => {
    return input$.pipe(
      map(data => {
        let slicedData = data;

        if (page !== null && size !== null) {
          slicedData = data.slice(size * page, size * (page + 1));
        }

        return {
          content: slicedData,
          totalElements: data.length
        };
      })
    );
  };
}
