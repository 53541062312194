<div
  cdkOverlayOrigin
  #proofOfDeliveryPopupOrigin="cdkOverlayOrigin"
  >
  @if (loadingService.isLoading$(this) | async) {
    <icz-spinner [diameter]="30"></icz-spinner>
  }
  @if (consignment) {
    <div
      class="icz-body-1"
      >
      @if (consignment.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
        @if ($any(consignment).deliveryEvents?.length && deliveredAt) {
          @if (consignment.proofOfDelivery) {
            {{ consignment.proofOfDelivery.deliveryResultId | findInList:deliveryResultOptions }}<br>
          }
          <a
            class="icz-body-1"
            iczLinkWithoutHref
            (click.stop)="isProofOfDeliveryPopupOpen = true"
            >
            {{ 'Doručenka' | translate }}
          </a>
        }
      }
      @else {
        @if (consignment.proofOfDelivery) {
          {{ consignment.proofOfDelivery.deliveryResultId | findInList:deliveryResultOptions }}
        }
      }
    </div>
  }
</div>

<icz-popover
  #proofOfDeliveryPopup
  [overlayOrigin]="proofOfDeliveryPopupOrigin"
  [allowHorizontalPlacement]="true"
  [isOpen]="isProofOfDeliveryPopupOpen"
  (onClose)="isProofOfDeliveryPopupOpen = false"
  >
  <div class="px-24 py-16">
    @if (proofOfDeliveryPopup.isOpen) {
      <icz-databox-proof-of-delivery
        [consignment]="$any(consignment)"
        [deliveredAt]="deliveredAt"
      ></icz-databox-proof-of-delivery>
    }
  </div>
</icz-popover>
