@if (esslComponent) {
  <div class="row gap-32">
    <div class="col grow gap-32">
      @if (isDigitalComponent) {
        <icz-section boxed label="Velikost souboru (spočtená)">
          <div class="row">
            <icz-readonly-field class="grow" label="Počet stran" [value]="digitalComponent.numberOfPages ?? ('Nedostupné' | translate)"></icz-readonly-field>
            @if (isDigitalComponent) {
              <icz-readonly-field class="grow" label="Velikost" [value]="digitalComponentVersion?.fileSize! | fileSize"></icz-readonly-field>
            }
          </div>
        </icz-section>
      }
      <icz-section boxed label="Základní informace">
        <icz-readonly-field label="Založil" [value]="esslComponentOrVersionDto.auditInfo!.createdByFunctionalPositionId | findFunctionalPositionById | async"></icz-readonly-field>
        <icz-readonly-field label="Upravil" [value]="esslComponentOrVersionDto.auditInfo!.modifiedByFunctionalPositionId | findFunctionalPositionById | async"></icz-readonly-field>
        <icz-readonly-field label="UID (unikátní identifikátor)" [value]="esslComponent.uid!.uid"></icz-readonly-field>
        <div class="row">
          <icz-readonly-field class="grow" label="Datum vytvoření" [value]="esslComponentOrVersionDto.auditInfo!.createdAt | localizedDate"></icz-readonly-field>
          <icz-readonly-field class="grow" label="Datum úpravy" [value]="esslComponentOrVersionDto.auditInfo!.modifiedAt | localizedDate"></icz-readonly-field>
        </div>
        @if (isDigitalComponent) {
          <icz-readonly-field label="Název nahraného souboru" [value]="digitalComponentVersion?.fileName"></icz-readonly-field>
        }
      </icz-section>
    </div>
    <div class="col grow gap-32">
      @if (isPaperComponent) {
        <form [formGroup]="form">
          <icz-section boxed label="Listy a ochranné prvky analogové komponenty">
            <div class="row grow">
              @if (!isComponentMainComponent) {
                <icz-form-field
                  formControlName="count" class="grow"
                  [label]="isAnalogCompositionSheets ? 'Počet příloh' : 'Počet svazků'" type="integer"
                ></icz-form-field>
              }
              @if (isAnalogCompositionSheets) {
                <icz-form-field label="Počet listů" type="integer"
                formControlName="numberOfSheets" class="grow"></icz-form-field>
              }
              @if (!isComponentMainComponent) {
                <icz-form-autocomplete label="Skladba"  class="grow"  formControlName="compositionType" [options]="analogCompositionTypeOptions"></icz-form-autocomplete>
              }
            </div>
            <icz-form-autocomplete label="Ochranný prvek" formControlName="protectiveElementSet" [options]="protectiveElementOptions" [isMultiselect]="true"></icz-form-autocomplete>
          </icz-section>
        </form>
      }
      @if (isDigitalComponent || isPaperComponent) {
        @if (conversionRegistersExists) {
          @for (conversionRegister of conversionRegisters; track conversionRegister) {
            <!--        todo 12160 show correct heading, info text and possibly icz-essl-component-link if there is other component to link from -->
              <!--          todo 12160 filter correctly by CONVERSION TYPE-->
              <!--          <icz-section boxed label="Transformace" svgIcon="conversion_menu">-->
              <!--            <span> {{ conversionInfoText | translate }} </span>-->
              <!--                        <icz-essl-component-link [documentEsslComponent]="originEsslComponent"></icz-essl-component-link>-->
              <!--          </icz-section>-->
              <!--        todo 12160 show required info from conversioRegister to show as Doložka o autorizované konverzi -->
              <!--          <icz-section boxed label="Doložka o autorizované konverzi" svgIcon="conversion">-->
              <!--            <icz-readonly-field label="Datum vyhotovení doložky"-->
              <!--                                [value]="digitalComponent.authConversionClause?.conversionDate | localizedDatetime"></icz-readonly-field>-->
              <!--          </icz-section>-->
          }
        }
        <icz-section boxed label="Technické parametry">
          <div class="row">
            <icz-readonly-field class="grow" label="MIME Type" [value]="digitalComponentVersion?.mimeType"></icz-readonly-field>
            <icz-readonly-field class="grow" label="PUID" [value]="digitalComponentVersion?.puid"></icz-readonly-field>
          </div>
          <icz-readonly-field testingFeature class="grow" label="Ukazatel do DMS" value=""></icz-readonly-field>
        </icz-section>
      }
      @if (this.digitalComponent?.parentContainerDigitalComponentId) {
        <icz-section boxed label="Vytěženo z kontejneru">
          @if (!sourceContainerComponent) {
            <icz-spinner [diameter]="30"></icz-spinner>
          }
          @else {
            <icz-essl-component-link
              [documentEsslComponent]="sourceContainerComponent"
              [versionId]="this.digitalComponent!.parentContainerDigitalComponentVersionId!"
            ></icz-essl-component-link>
          }
        </icz-section>
      }
      @if (isComponentRenditionByAnonymization) {
        <icz-section boxed label="Anonymizace">
          @if (!sourceAnonymizationComponent) {
            <icz-spinner [diameter]="30"></icz-spinner>
          }
          @else {
            <icz-essl-component-link
              [documentEsslComponent]="sourceAnonymizationComponent"
              [versionId]="this.digitalComponent!.parentAnonymizationDigitalComponentVersionId!"
            ></icz-essl-component-link>
          }
        </icz-section>
      }
    </div>
  </div>
}
