/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DigitalComponentReferenceCreateDto } from '../../models/digital-component-reference-create-dto';
import { DigitalComponentVersionValidationResult } from '../../models/digital-component-version-validation-result';

export interface DigitalComponentLinkDigitalComponentToDocument$Params {
  documentId: number;
      body: Array<DigitalComponentReferenceCreateDto>
}

export function digitalComponentLinkDigitalComponentToDocument(http: HttpClient, rootUrl: string, params: DigitalComponentLinkDigitalComponentToDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentVersionValidationResult>>> {
  const rb = new RequestBuilder(rootUrl, digitalComponentLinkDigitalComponentToDocument.PATH, 'post');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DigitalComponentVersionValidationResult>>;
    })
  );
}

digitalComponentLinkDigitalComponentToDocument.PATH = '/component/digital-component/{documentId}/link';