/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { circulationActivityCheckRevokePreconditions } from '../fn/circulation-activity/circulation-activity-check-revoke-preconditions';
import { CirculationActivityCheckRevokePreconditions$Params } from '../fn/circulation-activity/circulation-activity-check-revoke-preconditions';
import { circulationActivityCreateAcknowledgement } from '../fn/circulation-activity/circulation-activity-create-acknowledgement';
import { CirculationActivityCreateAcknowledgement$Params } from '../fn/circulation-activity/circulation-activity-create-acknowledgement';
import { circulationActivityCreateApproval } from '../fn/circulation-activity/circulation-activity-create-approval';
import { CirculationActivityCreateApproval$Params } from '../fn/circulation-activity/circulation-activity-create-approval';
import { circulationActivityCreateComponentSigning } from '../fn/circulation-activity/circulation-activity-create-component-signing';
import { CirculationActivityCreateComponentSigning$Params } from '../fn/circulation-activity/circulation-activity-create-component-signing';
import { circulationActivityCreateHandover } from '../fn/circulation-activity/circulation-activity-create-handover';
import { CirculationActivityCreateHandover$Params } from '../fn/circulation-activity/circulation-activity-create-handover';
import { circulationActivityCreateHandoverToIssd } from '../fn/circulation-activity/circulation-activity-create-handover-to-issd';
import { CirculationActivityCreateHandoverToIssd$Params } from '../fn/circulation-activity/circulation-activity-create-handover-to-issd';
import { circulationActivityCreateStatement } from '../fn/circulation-activity/circulation-activity-create-statement';
import { CirculationActivityCreateStatement$Params } from '../fn/circulation-activity/circulation-activity-create-statement';
import { circulationActivityDocumentIssd } from '../fn/circulation-activity/circulation-activity-document-issd';
import { CirculationActivityDocumentIssd$Params } from '../fn/circulation-activity/circulation-activity-document-issd';
import { CirculationActivityDto } from '../models/circulation-activity-dto';
import { circulationActivityExecuteComponentSigningPreconditionsCheck } from '../fn/circulation-activity/circulation-activity-execute-component-signing-preconditions-check';
import { circulationActivityExecuteComponentSigningPreconditionsCheck_1 } from '../fn/circulation-activity/circulation-activity-execute-component-signing-preconditions-check-1';
import { CirculationActivityExecuteComponentSigningPreconditionsCheck_1$Params } from '../fn/circulation-activity/circulation-activity-execute-component-signing-preconditions-check-1';
import { CirculationActivityExecuteComponentSigningPreconditionsCheck$Params } from '../fn/circulation-activity/circulation-activity-execute-component-signing-preconditions-check';
import { circulationActivityExecuteFileHandoverPreconditionsCheck } from '../fn/circulation-activity/circulation-activity-execute-file-handover-preconditions-check';
import { circulationActivityExecuteFileHandoverPreconditionsCheck_1 } from '../fn/circulation-activity/circulation-activity-execute-file-handover-preconditions-check-1';
import { CirculationActivityExecuteFileHandoverPreconditionsCheck_1$Params } from '../fn/circulation-activity/circulation-activity-execute-file-handover-preconditions-check-1';
import { CirculationActivityExecuteFileHandoverPreconditionsCheck$Params } from '../fn/circulation-activity/circulation-activity-execute-file-handover-preconditions-check';
import { circulationActivityFileIssd } from '../fn/circulation-activity/circulation-activity-file-issd';
import { CirculationActivityFileIssd$Params } from '../fn/circulation-activity/circulation-activity-file-issd';
import { circulationActivityFilterByRevocableActivities } from '../fn/circulation-activity/circulation-activity-filter-by-revocable-activities';
import { CirculationActivityFilterByRevocableActivities$Params } from '../fn/circulation-activity/circulation-activity-filter-by-revocable-activities';
import { circulationActivityFindAllByIdNoPermissionCheck } from '../fn/circulation-activity/circulation-activity-find-all-by-id-no-permission-check';
import { CirculationActivityFindAllByIdNoPermissionCheck$Params } from '../fn/circulation-activity/circulation-activity-find-all-by-id-no-permission-check';
import { circulationActivityFindById } from '../fn/circulation-activity/circulation-activity-find-by-id';
import { CirculationActivityFindById$Params } from '../fn/circulation-activity/circulation-activity-find-by-id';
import { circulationActivityFinishIssdActivity } from '../fn/circulation-activity/circulation-activity-finish-issd-activity';
import { CirculationActivityFinishIssdActivity$Params } from '../fn/circulation-activity/circulation-activity-finish-issd-activity';
import { circulationActivityGetTargetParticipants } from '../fn/circulation-activity/circulation-activity-get-target-participants';
import { CirculationActivityGetTargetParticipants$Params } from '../fn/circulation-activity/circulation-activity-get-target-participants';
import { CirculationActivityPreconditionsCheckResultDto } from '../models/circulation-activity-preconditions-check-result-dto';
import { circulationActivityRemoveTargetParticipants } from '../fn/circulation-activity/circulation-activity-remove-target-participants';
import { CirculationActivityRemoveTargetParticipants$Params } from '../fn/circulation-activity/circulation-activity-remove-target-participants';
import { circulationActivitySearch } from '../fn/circulation-activity/circulation-activity-search';
import { CirculationActivitySearch$Params } from '../fn/circulation-activity/circulation-activity-search';
import { CirculationActivityTargetParticipantCompleteDto } from '../models/circulation-activity-target-participant-complete-dto';
import { PageCirculationActivityDto } from '../models/page-circulation-activity-dto';
import { RevokePreconditionsDto } from '../models/revoke-preconditions-dto';


/**
 * Controller for Document Circulation Activities
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationActivityService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationActivityRemoveTargetParticipants()` */
  static readonly CirculationActivityRemoveTargetParticipantsPath = '/flow/activity/{id}/remove-target-participants';

  /**
   * Remove activity target by given activity id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityRemoveTargetParticipants()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityRemoveTargetParticipants$Response(params: CirculationActivityRemoveTargetParticipants$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityRemoveTargetParticipants(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove activity target by given activity id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityRemoveTargetParticipants$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityRemoveTargetParticipants(params: CirculationActivityRemoveTargetParticipants$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityRemoveTargetParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityCreateStatement()` */
  static readonly CirculationActivityCreateStatementPath = '/flow/activity/statement';

  /**
   * Create statement activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCreateStatement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateStatement$Response(params: CirculationActivityCreateStatement$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityCreateStatement(this.http, this.rootUrl, params, context);
  }

  /**
   * Create statement activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCreateStatement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateStatement(params: CirculationActivityCreateStatement$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityCreateStatement$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityCreateComponentSigning()` */
  static readonly CirculationActivityCreateComponentSigningPath = '/flow/activity/sign';

  /**
   * Create approval activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCreateComponentSigning()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateComponentSigning$Response(params: CirculationActivityCreateComponentSigning$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityCreateComponentSigning(this.http, this.rootUrl, params, context);
  }

  /**
   * Create approval activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCreateComponentSigning$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateComponentSigning(params: CirculationActivityCreateComponentSigning$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityCreateComponentSigning$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityCreateHandover()` */
  static readonly CirculationActivityCreateHandoverPath = '/flow/activity/handover';

  /**
   * Create handover activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCreateHandover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateHandover$Response(params: CirculationActivityCreateHandover$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityCreateHandover(this.http, this.rootUrl, params, context);
  }

  /**
   * Create handover activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCreateHandover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateHandover(params: CirculationActivityCreateHandover$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityCreateHandover$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityCreateHandoverToIssd()` */
  static readonly CirculationActivityCreateHandoverToIssdPath = '/flow/activity/handover-to-issd';

  /**
   * Create handover activity for ISSD
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCreateHandoverToIssd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateHandoverToIssd$Response(params: CirculationActivityCreateHandoverToIssd$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationActivityDto>>> {
    return circulationActivityCreateHandoverToIssd(this.http, this.rootUrl, params, context);
  }

  /**
   * Create handover activity for ISSD
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCreateHandoverToIssd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateHandoverToIssd(params: CirculationActivityCreateHandoverToIssd$Params, context?: HttpContext): Observable<Array<CirculationActivityDto>> {
    return this.circulationActivityCreateHandoverToIssd$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationActivityDto>>): Array<CirculationActivityDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivityFindAllByIdNoPermissionCheck()` */
  static readonly CirculationActivityFindAllByIdNoPermissionCheckPath = '/flow/activity/find-all-by-id';

  /**
   * Find all circulation activities based on their IDs
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityFindAllByIdNoPermissionCheck()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFindAllByIdNoPermissionCheck$Response(params: CirculationActivityFindAllByIdNoPermissionCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationActivityDto>>> {
    return circulationActivityFindAllByIdNoPermissionCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all circulation activities based on their IDs
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityFindAllByIdNoPermissionCheck$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFindAllByIdNoPermissionCheck(params: CirculationActivityFindAllByIdNoPermissionCheck$Params, context?: HttpContext): Observable<Array<CirculationActivityDto>> {
    return this.circulationActivityFindAllByIdNoPermissionCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationActivityDto>>): Array<CirculationActivityDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivityFilterByRevocableActivities()` */
  static readonly CirculationActivityFilterByRevocableActivitiesPath = '/flow/activity/filter-by-revocable-activities';

  /**
   * Filters by revocable activities.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityFilterByRevocableActivities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFilterByRevocableActivities$Response(params: CirculationActivityFilterByRevocableActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationActivityDto>>> {
    return circulationActivityFilterByRevocableActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * Filters by revocable activities.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityFilterByRevocableActivities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFilterByRevocableActivities(params: CirculationActivityFilterByRevocableActivities$Params, context?: HttpContext): Observable<Array<CirculationActivityDto>> {
    return this.circulationActivityFilterByRevocableActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationActivityDto>>): Array<CirculationActivityDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivityFileIssd()` */
  static readonly CirculationActivityFileIssdPath = '/flow/activity/file-issd/{taskState}';

  /**
   * Temporaly emulation of task change state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityFileIssd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFileIssd$Response(params: CirculationActivityFileIssd$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityFileIssd(this.http, this.rootUrl, params, context);
  }

  /**
   * Temporaly emulation of task change state
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityFileIssd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFileIssd(params: CirculationActivityFileIssd$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityFileIssd$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityExecuteFileHandoverPreconditionsCheck()` */
  static readonly CirculationActivityExecuteFileHandoverPreconditionsCheckPath = '/flow/activity/file-handover-preconditions-check';

  /**
   * Executes the Files Handover Preconditions Check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityExecuteFileHandoverPreconditionsCheck()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityExecuteFileHandoverPreconditionsCheck$Response(params: CirculationActivityExecuteFileHandoverPreconditionsCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationActivityPreconditionsCheckResultDto>>> {
    return circulationActivityExecuteFileHandoverPreconditionsCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * Executes the Files Handover Preconditions Check
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityExecuteFileHandoverPreconditionsCheck$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityExecuteFileHandoverPreconditionsCheck(params: CirculationActivityExecuteFileHandoverPreconditionsCheck$Params, context?: HttpContext): Observable<Array<CirculationActivityPreconditionsCheckResultDto>> {
    return this.circulationActivityExecuteFileHandoverPreconditionsCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationActivityPreconditionsCheckResultDto>>): Array<CirculationActivityPreconditionsCheckResultDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivityFinishIssdActivity()` */
  static readonly CirculationActivityFinishIssdActivityPath = '/flow/activity/done-issd';

  /**
   * Finish issd activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityFinishIssdActivity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFinishIssdActivity$Response(params: CirculationActivityFinishIssdActivity$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return circulationActivityFinishIssdActivity(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish issd activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityFinishIssdActivity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityFinishIssdActivity(params: CirculationActivityFinishIssdActivity$Params, context?: HttpContext): Observable<Array<number>> {
    return this.circulationActivityFinishIssdActivity$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `circulationActivityDocumentIssd()` */
  static readonly CirculationActivityDocumentIssdPath = '/flow/activity/document-issd/{taskState}';

  /**
   * Temporaly emulation of task change state
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityDocumentIssd()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityDocumentIssd$Response(params: CirculationActivityDocumentIssd$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityDocumentIssd(this.http, this.rootUrl, params, context);
  }

  /**
   * Temporaly emulation of task change state
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityDocumentIssd$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityDocumentIssd(params: CirculationActivityDocumentIssd$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityDocumentIssd$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityExecuteComponentSigningPreconditionsCheck()` */
  static readonly CirculationActivityExecuteComponentSigningPreconditionsCheckPath = '/flow/activity/component-signing-preconditions-check';

  /**
   * Executes the Components Signing Preconditions Check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityExecuteComponentSigningPreconditionsCheck()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityExecuteComponentSigningPreconditionsCheck$Response(params: CirculationActivityExecuteComponentSigningPreconditionsCheck$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationActivityPreconditionsCheckResultDto>>> {
    return circulationActivityExecuteComponentSigningPreconditionsCheck(this.http, this.rootUrl, params, context);
  }

  /**
   * Executes the Components Signing Preconditions Check
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityExecuteComponentSigningPreconditionsCheck$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityExecuteComponentSigningPreconditionsCheck(params: CirculationActivityExecuteComponentSigningPreconditionsCheck$Params, context?: HttpContext): Observable<Array<CirculationActivityPreconditionsCheckResultDto>> {
    return this.circulationActivityExecuteComponentSigningPreconditionsCheck$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationActivityPreconditionsCheckResultDto>>): Array<CirculationActivityPreconditionsCheckResultDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivityCheckRevokePreconditions()` */
  static readonly CirculationActivityCheckRevokePreconditionsPath = '/flow/activity/check-revoke-preconditions';

  /**
   * Check if given activities can be revoked
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCheckRevokePreconditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCheckRevokePreconditions$Response(params: CirculationActivityCheckRevokePreconditions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RevokePreconditionsDto>>> {
    return circulationActivityCheckRevokePreconditions(this.http, this.rootUrl, params, context);
  }

  /**
   * Check if given activities can be revoked
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCheckRevokePreconditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCheckRevokePreconditions(params: CirculationActivityCheckRevokePreconditions$Params, context?: HttpContext): Observable<Array<RevokePreconditionsDto>> {
    return this.circulationActivityCheckRevokePreconditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RevokePreconditionsDto>>): Array<RevokePreconditionsDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivityCreateApproval()` */
  static readonly CirculationActivityCreateApprovalPath = '/flow/activity/approval';

  /**
   * Create approval activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCreateApproval()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateApproval$Response(params: CirculationActivityCreateApproval$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityCreateApproval(this.http, this.rootUrl, params, context);
  }

  /**
   * Create approval activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCreateApproval$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateApproval(params: CirculationActivityCreateApproval$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityCreateApproval$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityCreateAcknowledgement()` */
  static readonly CirculationActivityCreateAcknowledgementPath = '/flow/activity/acknowledgement';

  /**
   * Create acknowledgement activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityCreateAcknowledgement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateAcknowledgement$Response(params: CirculationActivityCreateAcknowledgement$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityCreateAcknowledgement(this.http, this.rootUrl, params, context);
  }

  /**
   * Create acknowledgement activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityCreateAcknowledgement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityCreateAcknowledgement(params: CirculationActivityCreateAcknowledgement$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityCreateAcknowledgement$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityFindById()` */
  static readonly CirculationActivityFindByIdPath = '/flow/activity/{id}';

  /**
   * Find activity with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityFindById$Response(params: CirculationActivityFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
    return circulationActivityFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find activity with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityFindById(params: CirculationActivityFindById$Params, context?: HttpContext): Observable<CirculationActivityDto> {
    return this.circulationActivityFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityDto>): CirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityGetTargetParticipants()` */
  static readonly CirculationActivityGetTargetParticipantsPath = '/flow/activity/{id}/get-target-participants';

  /**
   * Find all document circulation activity participant instances by activity ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityGetTargetParticipants()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityGetTargetParticipants$Response(params: CirculationActivityGetTargetParticipants$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationActivityTargetParticipantCompleteDto>>> {
    return circulationActivityGetTargetParticipants(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all document circulation activity participant instances by activity ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityGetTargetParticipants$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityGetTargetParticipants(params: CirculationActivityGetTargetParticipants$Params, context?: HttpContext): Observable<Array<CirculationActivityTargetParticipantCompleteDto>> {
    return this.circulationActivityGetTargetParticipants$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationActivityTargetParticipantCompleteDto>>): Array<CirculationActivityTargetParticipantCompleteDto> => r.body)
    );
  }

  /** Path part for operation `circulationActivitySearch()` */
  static readonly CirculationActivitySearchPath = '/flow/activity/search-api';

  /**
   * Find all document circulation activity instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivitySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivitySearch$Response(params?: CirculationActivitySearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageCirculationActivityDto>> {
    return circulationActivitySearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all document circulation activity instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivitySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivitySearch(params?: CirculationActivitySearch$Params|null|undefined, context?: HttpContext): Observable<PageCirculationActivityDto> {
    return this.circulationActivitySearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageCirculationActivityDto>): PageCirculationActivityDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityExecuteFileHandoverPreconditionsCheck_1()` */
  static readonly CirculationActivityExecuteFileHandoverPreconditionsCheck_1Path = '/flow/activity/file-handover-preconditions-check/{fileId}';

  /**
   * Executes the File Handover Preconditions Check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityExecuteFileHandoverPreconditionsCheck_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityExecuteFileHandoverPreconditionsCheck_1$Response(params: CirculationActivityExecuteFileHandoverPreconditionsCheck_1$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityPreconditionsCheckResultDto>> {
    return circulationActivityExecuteFileHandoverPreconditionsCheck_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Executes the File Handover Preconditions Check
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityExecuteFileHandoverPreconditionsCheck_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityExecuteFileHandoverPreconditionsCheck_1(params: CirculationActivityExecuteFileHandoverPreconditionsCheck_1$Params, context?: HttpContext): Observable<CirculationActivityPreconditionsCheckResultDto> {
    return this.circulationActivityExecuteFileHandoverPreconditionsCheck_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityPreconditionsCheckResultDto>): CirculationActivityPreconditionsCheckResultDto => r.body)
    );
  }

  /** Path part for operation `circulationActivityExecuteComponentSigningPreconditionsCheck_1()` */
  static readonly CirculationActivityExecuteComponentSigningPreconditionsCheck_1Path = '/flow/activity/component-signing-preconditions-check/{componentId}';

  /**
   * Executes the Component Signing Preconditions Check
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityExecuteComponentSigningPreconditionsCheck_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityExecuteComponentSigningPreconditionsCheck_1$Response(params: CirculationActivityExecuteComponentSigningPreconditionsCheck_1$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityPreconditionsCheckResultDto>> {
    return circulationActivityExecuteComponentSigningPreconditionsCheck_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Executes the Component Signing Preconditions Check
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityExecuteComponentSigningPreconditionsCheck_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationActivityExecuteComponentSigningPreconditionsCheck_1(params: CirculationActivityExecuteComponentSigningPreconditionsCheck_1$Params, context?: HttpContext): Observable<CirculationActivityPreconditionsCheckResultDto> {
    return this.circulationActivityExecuteComponentSigningPreconditionsCheck_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationActivityPreconditionsCheckResultDto>): CirculationActivityPreconditionsCheckResultDto => r.body)
    );
  }

}
