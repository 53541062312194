<ng-container [formGroup]="valueForm">
  <div class="row">
    <div class="col grow">
      <icz-form-field class="grow" label="ID datové schránky" formControlName="id">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
      @if (isLoading) {
        <div class="row items-center">
          <icz-spinner [diameter]="16"></icz-spinner><span class="info-mini-text">{{'Probíhá ověřování datové schránky.' | translate}}</span>
        </div>
      }
    </div>
    @if (showDataboxType) {
      <div class="col grow">
        <icz-readonly-field
          [options]="dataBoxTypeOptions"
          [waiting]="isLoading"
          [value]="dataBoxTypeControl(valueForm)!.value"
          [blockingOverlayOpts]="{spinnerDiameter: 30, cursorWaiting: true,}"
          class="grow databox-type"
          label="Typ datové schránky"
          >
          @if (isVerified) {
            <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
          }
        </icz-readonly-field>
      </div>
    }
  </div>
</ng-container>
