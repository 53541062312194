import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {CirculationTaskResolution, CirculationTaskType} from '|api/commons';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {IczTagColor} from '../../essentials/tag/tag.component';
import {ACTIVITY_STATE_COLORS} from '../activity-state-tag/activity-state-tag.component';
import {TableToolbarPopupComponent} from '../../table/table-toolbar/table-toolbar-popup/table-toolbar-popup.component';

@Component({
  selector: 'icz-task-activity-state-icon',
  templateUrl: './task-activity-state-icon.component.html',
  styleUrls: ['./task-activity-state-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskActivityStateIconComponent {
  // task and activity are mutually exclusive
  @Input()
  task: Nullable<CirculationTaskDto>;
  @Input()
  activity: Nullable<CirculationActivityDto>;
  @Output()
  circulationActionCompleted = new EventEmitter<void>();

  get stateColor(): string {
    let iconColor: Nullable<IczTagColor>;

    if (this.activity) {
      iconColor = this.activityStateColors[this.activity.activityState];
    }
    else if (this.task) {
      iconColor = this.taskResolutionColors[this.task.resolution];
    }

    return `task_${iconColor ?? 'grey'}`;
  }

  get tooltip(): string {
    if (this.task) {
      return `en.circulationTaskResolution.${this.task.resolution}`;
    }
    else if (this.activity) {
      return `en.circulationActivityState.${this.activity.activityState}`;
    }
    else {
      return '-';
    }
  }

  private readonly activityStateColors = ACTIVITY_STATE_COLORS;

  private taskResolutionColors: Record<CirculationTaskResolution, IczTagColor> = {
    [CirculationTaskResolution.IN_PROGRESS]: 'orange',
    [CirculationTaskResolution.ASSIGNED]: 'orange',
    [CirculationTaskResolution.DONE_SUCCESS]: 'green',
    [CirculationTaskResolution.DONE_FAILURE]: 'red',
    [CirculationTaskResolution.DONE_NEUTRAL]: 'grey',
  };

  taskIconClicked($event: MouseEvent, tasksPopup: TableToolbarPopupComponent) {
    if (!(this.task && this.task.taskType === CirculationTaskType.TAKEOVER_ISSD)) {
      tasksPopup.toggle();
      $event.stopPropagation();
    }
  }
}
