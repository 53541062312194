import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

export enum IconCounterBadgeColor {
  RED,
  BLUE,
}

@Component({
  selector: 'icz-dashboard-icon-counter',
  templateUrl: './dashboard-icon-counter.component.html',
  styleUrls: ['./dashboard-icon-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardIconCounterComponent {
  @Input({required: true})
  svgIcon = 'empty';
  @Input()
  iconBadgeColor = IconCounterBadgeColor.BLUE;
  @Input({required: true})
  name = '';
  @Input()
  description = '';
  @Input({required: true})
  count: Nullable<number>;

  get iconBackgroundColorCode(): string {
    switch (this.iconBadgeColor) {
      case IconCounterBadgeColor.RED:
        return '#e82c17';
      case IconCounterBadgeColor.BLUE:
        return '#004ef5';
    }
  }
}
