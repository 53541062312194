import {ContentChild, Directive, ElementRef, forwardRef, inject, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[iczAutoFocus]',
  exportAs: 'iczAutoFocus',
})
export class AutoFocusDirective implements OnInit {

  private el = inject(ElementRef);

  // use 'deffered' to apply Material effects
  @Input({required: true}) iczAutoFocus!: true | false | 'deffered'| '';

  @ContentChild(forwardRef(() => AutoFocusDirective))
  slave!: AutoFocusDirective;

  ngOnInit() {
    if (this.iczAutoFocus === false) return;
    setTimeout(() => {
      this.focus();
    });
  }

  focus() {
    const isFocusable = ((el: {focus: () => void}) => (el && typeof el.focus === 'function') ? el : null);

    // inputs and textareas have priority over buttons
    const focusableDescendants: HTMLElement[] = [
      this.el.nativeElement.querySelector('input'),
      this.el.nativeElement.querySelector('textarea'),
      this.el.nativeElement.querySelector('button')
    ].filter(Boolean);

    const focusable = isFocusable(this.slave) || // slave AutoFocusDirective exist
      // this.slave(this.el.nativeElement) // focus is implemented
      isFocusable(focusableDescendants[0]); // first focusable descendant

    if (focusable) {
      if (this.iczAutoFocus === 'deffered' || this.iczAutoFocus === '') {
        // delay 150ms works almost surely compared do 0ms
        setTimeout(() => { focusable.focus(); }, 150);
      } else {
        focusable.focus();
      }
    }
  }

}
