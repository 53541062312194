import {ExtendedCirculationActivityDto} from '../../model/elastic-extended-entities.interface';
import {CirculationActivityState, CirculationActivityType} from '|api/commons';
import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {RevokePreconditionsDto} from '|api/flow';

export class ActivityOperationValidators {

  static isActivityNotInProgress(): OperationValidator<ExtendedCirculationActivityDto> {
    return (activity: ExtendedCirculationActivityDto) => {
      return activity.activityState !== CirculationActivityState.IN_PROGRESS  ? {validationMessage: 'Úkon není ve stavu Běžící' } : null;
    };
  }

  static isRejectedHandoverActivity(): OperationValidator<ExtendedCirculationActivityDto> {
    return (activity: ExtendedCirculationActivityDto) => {
      return ((activity.activityType === CirculationActivityType.DOCUMENT_HANDOVER || activity.activityType === CirculationActivityType.FILE_HANDOVER) && activity.rejectedTaskIds?.length) ? {validationMessage: 'Odmítnutý úkol předání nelze odvolat. Převezměte odmítnutý dokument pro dokončení úkonu.'} : null;
    };
  }

  static isUnrevokableActivity(preconditionResults: RevokePreconditionsDto[]): OperationValidator<ExtendedCirculationActivityDto> {
    return (activity: ExtendedCirculationActivityDto) => {
      const failedPrecondition = preconditionResults.find(r => r.activityId === activity.id && !r.revokeAllowed);

      if (failedPrecondition) {
        return {validationMessage: `fe.ui.revokeDisallowReason.${failedPrecondition.revokeDisallowReason}`};
      }
      else {
        return null;
      }
    };
  }
}
