/* tslint:disable */
/* eslint-disable */

/**
 * Envelope variable key
 */
export enum EnvelopeVariableKey {
  REF_NUMBER = 'REF_NUMBER',
  FILE_REF_NUMBER = 'FILE_REF_NUMBER'
}
