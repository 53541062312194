import {HttpHeaders} from '@angular/common/http';
import {DestroyRef, Injectable, NgZone, inject} from '@angular/core';
import html2canvas from 'html2canvas';
import {combineLatest, EMPTY, filter, fromEvent, NEVER, Observable, of} from 'rxjs';
import {catchError, switchMap, take, timeout} from 'rxjs/operators';
import {iczFormatDatetime} from '../../components/essentials/datetime.pipe';
import {GlobalLoadingIndicatorService} from '../../components/essentials/global-loading-indicator.service';
import {
  DebugLoggingService,
  getFriendlyLoglevelName,
  HttpMessage,
  IdtInboundMessage,
  IdtOutboundMessage,
  LogLevel,
  LogMessage,
  StompMessage,
  UserInteractionLogMessage
} from './debug-logging.service';
import {EnvironmentService} from './environment.service';
import {HistoryService} from '../../services/history.service';
import {LocalBinaryFileDownloadService} from '../../services/local-binary-file-download.service';
import {castStream} from '../../lib/rxjs';
import {formatAsLocalIsoDateTime} from '../../lib/utils';
import {HistoryBit} from '../../services/history.model';
import {ApplicationLanguage} from './environment.models';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IdtService} from './idt/idt.service';
import {VersionInfoService} from '../../components/essentials/version-info.service';


@Injectable({providedIn: 'root'})
export class BugReportService {

  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private debugLoggingService = inject(DebugLoggingService);
  private environmentService = inject(EnvironmentService);
  private historyService = inject(HistoryService);
  private ngZone = inject(NgZone);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private versionInfoService = inject(VersionInfoService);
  private destroyRef = inject(DestroyRef);
  private idtService = inject(IdtService);

  constructor() {
    this.ngZone.runOutsideAngular(() => {
      fromEvent(document.body, 'keydown').pipe(
        castStream<KeyboardEvent>(),
        // Ctrl+Alt+= OR Ctrl+Alt+\
        filter(e => (
          e.ctrlKey && e.altKey && (
            e.code === 'Equal' ||
            e.code === 'Backslash' ||
            e.code === 'IntlBackslash'
          )
        )),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        this.globalLoadingService.doLoading(
          this.generateBugReport(),
          'Tvorba chybového hlášení může chvíli trvat'
        ).subscribe();
      });
    });
  }

  generateBugReport(): Observable<void> {
    let idtVersionRequest$ = this.idtService.getIdtVersion();

    if (idtVersionRequest$ === EMPTY) {
      idtVersionRequest$ = NEVER;
    }

    return combineLatest(
      this.versionInfoService.getVersionInfo(),
      idtVersionRequest$.pipe(
        timeout(5000),
        catchError(_ => of({version: 'IDT nebylo dostupné'}))
      ),
    ).pipe(
      take(1),
      switchMap(([appVersion, idtVersionResponse]) => {
        return new Observable<void>(observer => {
          setTimeout(() => {
            html2canvas(document.body!).then(canvas => {
              const currentSessionHistory = this.historyService.historyBits.filter(
                hb => hb.sessionId === this.historyService.currentSessionId
              );
              const environment = this.environmentService.environment;
              const bugReportDate = new Date();
              const idtVersion = idtVersionResponse.version;

              const html = `
            <html>
              <head>
                <meta charset="utf-8">
                <style>
                  html {font-family: sans-serif;}
                  table {width: 100%;}
                  th {text-align: left;}
                  td {vertical-align: top;}
                  .row-multiline th, .row-multiline td {border-bottom: dotted 1px #666;}
                  .header-separated th {border-bottom: solid 2px #000;}
                  .row-separated td {border-bottom: solid 1px #000;}
                  .warning-log {color: #e65100;}
                  .error-log {color: #e81d1d;}
                </style>
              </head>
              <body>
                <h1>Chybové hlášení e-Spis G2</h1>
                <p>
                  Pořízeno: ${this.formatDatetime(bugReportDate)}<br>
                  Prostředí: ${environment.environmentName} (${environment.hostUrl})<br>
                  Verze: ${appVersion}<br>
                  Verze IDT: ${idtVersion}<br>
                </p>
                <img src="${canvas.toDataURL('image/jpeg')}" style="width: 100%" alt="Screenshot">
                ${this.generateLogTables()}
                ${this.generateUserInteractionTable(currentSessionHistory, this.debugLoggingService.userInteractionHistory)}
                ${this.generateHttpTable(this.debugLoggingService.httpRequests)}
                ${this.generateStompTable(this.debugLoggingService.stompMessages)}
                ${this.generateIdtTables(this.debugLoggingService.idtInboundMessages, this.debugLoggingService.idtOutboundMessages)}
              </body>
            </html>
            `;

              const blob = new Blob([html], {type: 'text/html'});
              const commitHashRe = /^.+commit (.+),.+$/g;

              const reportDate = formatAsLocalIsoDateTime(bugReportDate).replace(/[ :]/g, '-');
              const commitHash = (commitHashRe.exec(appVersion) ?? [])[1] ?? 'UnknownCommit';
              const appEnvironment = environment.environmentName;
              const filename = `espisG2_${reportDate}_${appEnvironment}_${commitHash}.html`;

              this.localBinaryFileDownloadService.downloadBinaryFileFromUrl(
                window.URL.createObjectURL(blob),
                filename
              );

              observer.complete();
            });
          }, 2000);
        });
      })
    );
  }

  private generateUserInteractionTable(currentSessionHistory: Array<HistoryBit>, userInteractionHistory: Array<UserInteractionLogMessage>) {
    return this.generateTable(
      currentSessionHistory,
      currentSessionHistory => {
        const tmpUserInteraction: UserInteractionLogMessage[] =
          currentSessionHistory.map(hb => ({description: `Navigace na ${hb.url}`, timestamp: hb.date}))
            .concat(userInteractionHistory).sort((a, b) => a.timestamp < b.timestamp ? -1 : a.timestamp > b.timestamp ? 1 : 0);

        let out = '';
        out += `<tr class="header-separated">
                <th>Čas</th>
                <th>Interakce</th>
              </tr>`;
        for (const interaction of tmpUserInteraction) {
          out += `<tr class="row-separated">
                  <td>${this.formatDatetime(interaction.timestamp)}</td>
                  <td>${interaction.description}</td>
                </tr>`;
        }

        return out;
      },
      'Uživatelská interakce',
    );
  }

  private generateStompTable(stompMessages: Array<StompMessage>) {
    return this.generateTable(
      stompMessages,
      stompMessages => {
        let out = '';
        out += `<tr class="header-separated">
                <th>Čas</th>
                <th>Topic</th>
                <th>Data</th>
              </tr>`;
        for (const stompMessage of stompMessages) {
          out += `<tr class="row-separated">
                  <td>${this.formatDatetime(stompMessage.timestamp)}</td>
                  <td>${stompMessage.topic}</td>
                  <td><pre>${this.serializePayload(stompMessage.payload)}</pre></td>
                </tr>`;
        }
        return out;
      },
      'Notifikační zprávy',
    );
  }

  private generateIdtTables(inboundMessages: Array<IdtInboundMessage>, outboundMessages: Array<IdtOutboundMessage>) {
    let out = '';

    if (inboundMessages.length || outboundMessages.length) {
      out += '<h2>IDT zprávy</h2>';

      out += this.generateIdtOutboundTable(outboundMessages);
      out += this.generateIdtInboundTable(inboundMessages);
    }

    return out;
  }

  private generateIdtInboundTable(idtMessages: Array<IdtInboundMessage>) {
    return this.generateTable(
      idtMessages,
      idtMessages => {
        let out = '';
        out += `<tr class="header-separated">
                <th>Čas</th>
                <th>Data</th>
              </tr>`;
        for (const idtMessage of idtMessages) {
          out += `<tr class="row-separated ${!idtMessage.success ? 'error-log' : ''}">
                  <td>${this.formatDatetime(idtMessage.timestamp)}</td>
                  <td><pre>${this.serializePayload(idtMessage.data)}</pre></td>
                </tr>`;
        }
        return out;
      },
      'Příchozí',
      true,
    );
  }

  private generateIdtOutboundTable(idtMessages: Array<IdtOutboundMessage>) {
    return this.generateTable(
      idtMessages,
      idtMessages => {
        let out = '';
        out += `<tr class="header-separated">
                <th>Čas</th>
                <th>Metoda</th>
                <th>Data</th>
              </tr>`;
        for (const idtMessage of idtMessages) {
          out += `<tr class="row-separated">
                  <td>${this.formatDatetime(idtMessage.timestamp)}</td>
                  <td>${idtMessage.method}</td>
                  <td><pre>${this.serializePayload(idtMessage.data)}</pre></td>
                </tr>`;
        }
        return out;
      },
      'Odchozí',
      true,
    );
  }

  private generateHttpTable(httpRequests: Array<HttpMessage>) {
    return this.generateTable(
      httpRequests,
      httpRequests => {
        let out = '';
        out += `<tr class="row-multiline">
                <th>Čas</th>
                <th>Kód</th>
                <th>Metoda a URL</th>
              </tr>
              <tr class="row-multiline">
                <th></th>
                <th>Hlavičky požadavku</th>
                <th>Tělo požadavku</th>
              </tr>
              <tr class="header-separated">
                <th></th>
                <th>Hlavičky odpovědi</th>
                <th>Tělo odpovědi</th>
              </tr>`;
        for (const httpRequest of httpRequests) {
          out += `<tr class="row-multiline ${httpRequest.statusCode >= 400 ? 'error-log' : ''}">
                  <td>${this.formatDatetime(httpRequest.timestamp)}</td>
                  <td>${httpRequest.statusCode}</td>
                  <td>${httpRequest.httpMethod} ${httpRequest.requestUrl}</td>
                </tr>
                <tr class="row-multiline ${httpRequest.statusCode >= 400 ? 'error-log' : ''}">
                  <td></td>
                  <td><code>${this.serializaHeaders(httpRequest.requestHeaders)}</code></td>
                  <td><pre>${this.serializePayload(httpRequest.requestPayload)}</pre></td>
                </tr>
                <tr class="row-separated ${httpRequest.statusCode >= 400 ? 'error-log' : ''}">
                  <td></td>
                  <td><code>${this.serializaHeaders(httpRequest.responseHeaders)}</code></td>
                  <td><pre>${this.serializePayload(httpRequest.responsePayload)}</pre></td>
                </tr>`;
        }
        return out;
      },
      'HTTP volání',
    );
  }

  private generateLogTables() {
    let out = '';

    out += '<h2>Log</h2>';

    out += this.generateLogTableByLevel(LogLevel.ERROR, this.debugLoggingService.consoleLogsError);
    out += this.generateLogTableByLevel(LogLevel.VERBOSE, this.debugLoggingService.consoleLogsVerbose);
    out += this.generateLogTableByLevel(LogLevel.WARNING, this.debugLoggingService.consoleLogsWarning);
    out += this.generateLogTableByLevel(LogLevel.INFO, this.debugLoggingService.consoleLogsInfo);

    return out;
  }

  private generateLogTableByLevel(logLevel: LogLevel, consoleLogs: Array<LogMessage>) {
    return this.generateTable(
      consoleLogs,
      consoleLogs => {
        let out = '';
          out += `<tr class="header-separated">
                <th>Čas</th>
                <th>Zpráva</th>
              </tr>`;
        for (const consoleLog of consoleLogs) {
          out += `<tr class="row-separated ${consoleLog.logLevel === LogLevel.WARNING ? 'warning-log' : ''} ${consoleLog.logLevel === LogLevel.ERROR ? 'error-log' : ''}">
                  <td>${this.formatDatetime(consoleLog.timestamp)}</td>
                  <td><pre>${this.parseLogContentParts(consoleLog.contentParts)}</pre></td>
                </tr>`;
        }
        return out;
      },
      getFriendlyLoglevelName(logLevel),
      true,
    );
  }

  private generateTable<T>(
    data: T[],
    tableContentGenerator: (data: T[]) => string,
    heading: string,
    isSubsection = false,
  ) {
    let out = '';

    if (data.length) {
      out += isSubsection ? `<h3>${heading}</h3>` : `<h2>${heading}</h2>`;

      out += '<table>';
      out += tableContentGenerator(data);
      out += '</table>';
    }

    return out;
  }

  private serializaHeaders(headers: HttpHeaders): string {
    let out = '';

    for (const headerKey of headers.keys()) {
      out += `<em>${headerKey}:</em> ${headers.get(headerKey)}<br><br>`;
    }

    return out;
  }

  private serializePayload(payload: any) {
    const json = JSON.stringify(payload, undefined, 2);
    return json;
  }

  private formatDatetime(date: Date | string) {
    return iczFormatDatetime(ApplicationLanguage.CZECH, date, false, true);
  }

  private parseLogContentParts(contentParts: any[]) {
    return contentParts.map(part => {
      if (part instanceof Error) {
        return `${part.name}: ${part.message}\n${part.stack}`;
      }
      else {
        return this.serializePayload(part);
      }
    }).join(', ');
  }

}
