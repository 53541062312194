<icz-section label="Stav zásilky">
  @if (!consignmentValidationResult || !consignmentValidationResult.alreadyRan) {
    <div>
      <icz-label secondary label="Pro plné zvalidování zásilky vyplňte prosím všechna povinná pole v Popisu vypravení a vyberte alespoň jednu komponentu."></icz-label>
    </div>
  }
  @else {
    @if (consignmentValidationResult!.valid) {
      <div>
        <icz-label secondary label="Validní zásilka."></icz-label>
        @if (consignmentValidationResult!.errors!.length) {
          <div>
            <icz-label secondary label="Varování:"></icz-label>
            @for (reason of consignmentValidationResult!.errors; track reason.code) {
              <div>
                <icz-label [label]="reason.code | interpolateBackendError:reason.parameters" bold></icz-label>
              </div>
            }
          </div>
        }
      </div>
    }
    @else {
      <icz-label secondary label="Zásilka není validní z důvodů:"></icz-label>
      @for (reason of consignmentValidationResult!.errors; track reason.code) {
        <div>
          <icz-label [label]="reason.code | interpolateBackendError:reason.parameters" bold></icz-label>
        </div>
      }
    }
  }
</icz-section>
