@if (key) {
  <span>{{key | translate}}</span>:
  @if (type === 'string') {
    <span class="font-bold">{{value}}</span>
  }
  @if (type === 'functionalPosition') {
    <span class="font-bold">{{$any(value) | findFunctionalPositionById | async}}</span>
  }
  @if (type === 'refNumber' && entity) {
    <icz-ref-number-link    [entity]="entity">{{value}}</icz-ref-number-link>
  }
}
