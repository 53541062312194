<div [formGroup]="form">
  <div class="row grow">
    <icz-label label="ČDZ, Číslo doporučené zásilky" class="mat-expansion-panel-content" light></icz-label>
  </div>
  <form class="row grow" formGroupName="consignmentPostingNumber">
    <div class="row-group field-prefix">
      <icz-form-field class="grow" formControlName="prefix" placeholder="Prefix" [showValidationStatus]="false"></icz-form-field>
      <icz-form-field class="grow" formControlName="code" placeholder="Pořadové číslo" [showValidationStatus]="false"></icz-form-field>
      <icz-form-field class="grow" formControlName="suffix" placeholder="Postfix doplněn z konfigurace" [showValidationStatus]="false"></icz-form-field>
    </div>
  </form>
</div>
