/* tslint:disable */
/* eslint-disable */

/**
 * Rendition Period. NULL => manually-triggered renditions only.
 */
export enum FilingRegisterPeriodicRenditionPeriod {
  MONTH = 'MONTH',
  QUARTER = 'QUARTER',
  YEAR = 'YEAR'
}
