/* tslint:disable */
/* eslint-disable */

/**
 * Type of document circulation activity
 */
export enum CirculationActivityType {
  FILE_HANDOVER = 'FILE_HANDOVER',
  FILE_ISSD_HANDOVER = 'FILE_ISSD_HANDOVER',
  FILE_ISSD_RETURN_OR_REJECT = 'FILE_ISSD_RETURN_OR_REJECT',
  FILE_OWNERSHIP_TRANSFER = 'FILE_OWNERSHIP_TRANSFER',
  DOCUMENT_HANDOVER = 'DOCUMENT_HANDOVER',
  DOCUMENT_HANDOVER_TO_OWN_HANDS = 'DOCUMENT_HANDOVER_TO_OWN_HANDS',
  DOCUMENT_ISSD_HANDOVER = 'DOCUMENT_ISSD_HANDOVER',
  DOCUMENT_ISSD_RETURN_OR_REJECT = 'DOCUMENT_ISSD_RETURN_OR_REJECT',
  DOCUMENT_OWNERSHIP_TRANSFER = 'DOCUMENT_OWNERSHIP_TRANSFER',
  DOCUMENT_APPROVAL = 'DOCUMENT_APPROVAL',
  DOCUMENT_STATEMENT = 'DOCUMENT_STATEMENT',
  DOCUMENT_ACKNOWLEDGEMENT = 'DOCUMENT_ACKNOWLEDGEMENT',
  COMPONENT_SIGNING = 'COMPONENT_SIGNING',
  COMPONENT_APPROVAL = 'COMPONENT_APPROVAL'
}
