/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DictionaryDto } from '../models/dictionary-dto';
import { dictionaryFindAllAsMap } from '../fn/dictionary/dictionary-find-all-as-map';
import { DictionaryFindAllAsMap$Params } from '../fn/dictionary/dictionary-find-all-as-map';
import { dictionaryFindByLanguage } from '../fn/dictionary/dictionary-find-by-language';
import { DictionaryFindByLanguage$Params } from '../fn/dictionary/dictionary-find-by-language';
import { dictionaryFindByLanguageAsMap } from '../fn/dictionary/dictionary-find-by-language-as-map';
import { DictionaryFindByLanguageAsMap$Params } from '../fn/dictionary/dictionary-find-by-language-as-map';
import { dictionaryFindByPrefix } from '../fn/dictionary/dictionary-find-by-prefix';
import { DictionaryFindByPrefix$Params } from '../fn/dictionary/dictionary-find-by-prefix';
import { dictionarySearch } from '../fn/dictionary/dictionary-search';
import { DictionarySearch$Params } from '../fn/dictionary/dictionary-search';
import { dictionaryUpdate } from '../fn/dictionary/dictionary-update';
import { DictionaryUpdate$Params } from '../fn/dictionary/dictionary-update';
import { PageDictionaryDto } from '../models/page-dictionary-dto';


/**
 * API for management of codebook Dictionary
 */
@Injectable({ providedIn: 'root' })
export class ApiDictionaryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dictionaryUpdate()` */
  static readonly DictionaryUpdatePath = '/dictionary/{id}';

  /**
   * Updates existing dictionary
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dictionaryUpdate$Response(params: DictionaryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DictionaryDto>> {
    return dictionaryUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates existing dictionary
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dictionaryUpdate(params: DictionaryUpdate$Params, context?: HttpContext): Observable<DictionaryDto> {
    return this.dictionaryUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DictionaryDto>): DictionaryDto => r.body)
    );
  }

  /** Path part for operation `dictionaryFindByPrefix()` */
  static readonly DictionaryFindByPrefixPath = '/dictionary/by-prefix';

  /**
   * Returns translation pairs whose keys are prefixed with given prefixes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryFindByPrefix()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dictionaryFindByPrefix$Response(params: DictionaryFindByPrefix$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
[key: string]: string;
};
}>> {
    return dictionaryFindByPrefix(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns translation pairs whose keys are prefixed with given prefixes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryFindByPrefix$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dictionaryFindByPrefix(params: DictionaryFindByPrefix$Params, context?: HttpContext): Observable<{
[key: string]: {
[key: string]: string;
};
}> {
    return this.dictionaryFindByPrefix$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
[key: string]: string;
};
}>): {
[key: string]: {
[key: string]: string;
};
} => r.body)
    );
  }

  /** Path part for operation `dictionaryFindByLanguage()` */
  static readonly DictionaryFindByLanguagePath = '/dictionary/{lang}';

  /**
   * Find all values in codebook 'Dictionary' in selected language
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryFindByLanguage()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryFindByLanguage$Response(params: DictionaryFindByLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DictionaryDto>>> {
    return dictionaryFindByLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in codebook 'Dictionary' in selected language
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryFindByLanguage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryFindByLanguage(params: DictionaryFindByLanguage$Params, context?: HttpContext): Observable<Array<DictionaryDto>> {
    return this.dictionaryFindByLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DictionaryDto>>): Array<DictionaryDto> => r.body)
    );
  }

  /** Path part for operation `dictionaryFindByLanguageAsMap()` */
  static readonly DictionaryFindByLanguageAsMapPath = '/dictionary/{lang}/as-map';

  /**
   * Returns all the translation pairs for a language.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryFindByLanguageAsMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryFindByLanguageAsMap$Response(params: DictionaryFindByLanguageAsMap$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: string;
}>> {
    return dictionaryFindByLanguageAsMap(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the translation pairs for a language.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryFindByLanguageAsMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryFindByLanguageAsMap(params: DictionaryFindByLanguageAsMap$Params, context?: HttpContext): Observable<{
[key: string]: string;
}> {
    return this.dictionaryFindByLanguageAsMap$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: string;
}>): {
[key: string]: string;
} => r.body)
    );
  }

  /** Path part for operation `dictionarySearch()` */
  static readonly DictionarySearchPath = '/dictionary/search-api';

  /**
   * Find all values in dictionary based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionarySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionarySearch$Response(params?: DictionarySearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDictionaryDto>> {
    return dictionarySearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in dictionary based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionarySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionarySearch(params?: DictionarySearch$Params|null|undefined, context?: HttpContext): Observable<PageDictionaryDto> {
    return this.dictionarySearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDictionaryDto>): PageDictionaryDto => r.body)
    );
  }

  /** Path part for operation `dictionaryFindAllAsMap()` */
  static readonly DictionaryFindAllAsMapPath = '/dictionary/as-map';

  /**
   * Returns all the translation pairs grouped by language.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryFindAllAsMap()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryFindAllAsMap$Response(params?: DictionaryFindAllAsMap$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
[key: string]: string;
};
}>> {
    return dictionaryFindAllAsMap(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the translation pairs grouped by language.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryFindAllAsMap$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryFindAllAsMap(params?: DictionaryFindAllAsMap$Params|null|undefined, context?: HttpContext): Observable<{
[key: string]: {
[key: string]: string;
};
}> {
    return this.dictionaryFindAllAsMap$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
[key: string]: string;
};
}>): {
[key: string]: {
[key: string]: string;
};
} => r.body)
    );
  }

}
