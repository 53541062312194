import {HttpErrorResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, forkJoin, Observable, of, throwError} from 'rxjs';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {VisibleSignatureDto, VisibleSignaturePage, VisibleSignaturePosition} from '|api/commons';
import {
  ApiSignatureService as DigitalComponentSignatureService,
  DigitalComponentCompleteDto,
  DigitalComponentVersionValidateResponse
} from '|api/component';
import {
  ApiSignatureService,
  DigitalComponentVersionDigestResult,
  DigitalComponentVersionSignRequest,
  DocumentDigestRequest,
  DocumentSignRequest,
  VisibleSignatureRequest,
} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  digitalComponentPreSignatureCodes
} from '../components/shared-business-components/digital-component-signature/digital-component-pre-signature-validation-dialog/digital-component-pre-signature-validation-dialog.component';
import {IdtService, IdtSignReqPayload, IdtSignResData} from '../core/services/idt/idt.service';
import {DialogService} from '../core/services/dialog.service';
import {
  DigitalComponentTemplateData,
  EsslComponentToastService,
  EsslComponentToastType
} from '../core/services/notifications/essl-component-toast.service';
import {getLastDigitalComponentVersionId} from '../components/shared-business-components/shared-document.utils';
import {SKIP_ERROR_DIALOG} from '../core/error-handling/http-errors';
import {Idt2Certificate, Idt2HashAlgorithm} from '../core/services/idt/idt.typedefs';
import {IdtCommunicationError} from '../core/services/idt/idt-link.service';
import {ErrorType} from '../core/services/idt/idt.enums';
import {
  DigitalComponentWithResult
} from '../components/shared-business-components/essl-components/models/signature-dialog.model';

// Used for calculating millimeters of pixels (1px = 0.2645833333mm)
export function transformPixelsToMilimeters(pixelNumber: number): number {
  return Math.round(pixelNumber * 0.2645833333 * window.devicePixelRatio); // If it's way off, just multiply by 2.9 bro
}


function digitalComponentInProgressToVisibleSignatureDto(digitalComponent: InProgressSignature): Nullable<VisibleSignatureDto> {
  if (!digitalComponent) return null;
  return {
    positionX: transformPixelsToMilimeters(digitalComponent.translateX + digitalComponent.scrolledX),

    positionY: Math.round(
      transformPixelsToMilimeters(digitalComponent.translateY + digitalComponent.scrolledY - (digitalComponent.pageNumber - 1) * (digitalComponent.pageHeight + 16))
    ), // 16 is bottom margin of a page, change only together with CSS of page
    page: VisibleSignaturePage.NUMBER,
    pageNumber: digitalComponent.pageNumber,
    position: VisibleSignaturePosition.XY,
  };
}

export interface ElementTranslate {
  translateX: number;
  translateY: number;
}

export interface ManualSignatureInformation {
  pageNumber: number;
  positionX: number; // in displayed px
  positionY: number; // in displayed px
}

export interface CardInformation extends ManualSignatureInformation {
  hasValidPosition: boolean;
  pageHeight: number; // in displayed px
  scrolledX: number; // in displayed px
  scrolledY: number; // in displayed px
  displayedPositionX?: number;
  displayedPositionY?: number;
}

export interface VisibleSignatureSettings extends VisibleSignatureDto {
  isVisualSignature: boolean;
  isPositionSelectedFromOptions: boolean;
  reason?: string;
  place?: string;
}

type SignRequestFromDigest = IdtSignReqPayload & { signDate: string, digitalComponentVersionId: number, seSessionId: string };

type SignRequestsForIdt = {[key: number]: Observable<Nullable<IdtSignResData>>};

export interface InProgressSignature extends CardInformation, ElementTranslate {
  digitalComponent: DigitalComponentCompleteDto;
  reason: string;
  place: string;
  showValidity: boolean;
  isReadyForSign: boolean;
}

/*
  SignMarkTimestampService
  ---------------------------
  1. This service is used for all operations with signature, mark and timestamp
*/

@Injectable({
  providedIn: 'root',
})
export class SignMarkTimestampService {

  private dialogService = inject(DialogService);
  private apiSignatureService = inject(ApiSignatureService);
  private idtService = inject(IdtService);
  private toastService = inject(EsslComponentToastService);
  private digitalComponentSignatureService = inject(DigitalComponentSignatureService);
  private translateService = inject(TranslateService);

  // DigitalComponents with Manual Signature in Progress
  private _digitalComponentsWithManualSignatureInProgress$ = new BehaviorSubject<InProgressSignature[]>([]);
  digitalComponentsWithManualSignatureInProgress$ = this._digitalComponentsWithManualSignatureInProgress$.asObservable();

  // DigitalComponents with Adding Timestamp in Progress
  private _digitalComponentsWithAddingTimestampInProgress$ = new BehaviorSubject<DigitalComponentCompleteDto[]>([]);
  digitalComponentsWithAddingTimestampInProgress$ = this._digitalComponentsWithAddingTimestampInProgress$.asObservable();
  addingTimestampToDigitalComponents$ = this.digitalComponentsWithAddingTimestampInProgress$.pipe(map(digitalComponents => digitalComponents.length > 0));

  // DigitalComponents with Mark in Progress
  private _digitalComponentsWithMarkInProgress$ = new BehaviorSubject<DigitalComponentCompleteDto[]>([]);
  digitalComponentsWithMarkInProgress$ = this._digitalComponentsWithMarkInProgress$.asObservable();

  // DigitalComponents with Manual validation in Progress
  private _digitalComponentsWithManualValidationProgress$ = new BehaviorSubject<DigitalComponentCompleteDto[]>([]);
  digitalComponentsWithManualValidationProgress$ = this._digitalComponentsWithManualValidationProgress$.asObservable();

  addDigitalComponentsToManualValidationInProgressList(digitalComponents: DigitalComponentCompleteDto[]) {
    this._digitalComponentsWithManualValidationProgress$.next(digitalComponents);
  }

  deleteDigitalComponentsFromManualValidationInProgressList() {
    this._digitalComponentsWithManualValidationProgress$.next([]);
  }

  addDigitalComponentsToTimestampInProgressList(digitalComponents: DigitalComponentCompleteDto[]) {
    this._digitalComponentsWithAddingTimestampInProgress$.next(digitalComponents);
  }

  deleteDigitalComponentsFromTimestampInProgressList() {
    this._digitalComponentsWithAddingTimestampInProgress$.next([]);
  }

  addDigitalComponentsToMarkInProgressList(digitalComponents: DigitalComponentCompleteDto[]) {
    this._digitalComponentsWithMarkInProgress$.next(digitalComponents);
  }

  deleteDigitalComponentsFromMarkInProgressList() {
    this._digitalComponentsWithMarkInProgress$.next([]);
  }

  addDigitalComponentToManualSignatureInProgressList(newDigitalComponent: InProgressSignature) {
    const list = [...this._digitalComponentsWithManualSignatureInProgress$.value];
    const index = list.findIndex(inProgressSignature => inProgressSignature.digitalComponent.id === newDigitalComponent.digitalComponent.id);
    const isInList = index !== -1;
    if (isInList) {
      list[index] = newDigitalComponent;
      this._digitalComponentsWithManualSignatureInProgress$.next(list);
    } else {
      this._digitalComponentsWithManualSignatureInProgress$.next([...list, newDigitalComponent]);
    }
  }

  clearListWithAttachmensInManualSignatureProgress() {
    this._digitalComponentsWithManualSignatureInProgress$.next([]);
  }

  // Is used when user want to mannualy check digitalComponent validity with toolbar button
  triggerManualDigitalComponentValidation(digitalComponents: DigitalComponentCompleteDto[]) {
    const body = digitalComponents.map(digitalComponent => getLastDigitalComponentVersionId(digitalComponent)!);
    this.addDigitalComponentsToManualValidationInProgressList(digitalComponents);
    return this.digitalComponentSignatureService
      .signatureValidate({body})
      .pipe(tap(response => this.dispatchManualDigitalComponentValidationToasters(digitalComponents, response)));
  }

  addTimestamp(digitalComponents: DigitalComponentCompleteDto[], documentId: number, skipErrorContext: boolean = false) {
    const digitalComponentVersionIds = this.extractDigitalComponentVersionIds(digitalComponents);
    this.addDigitalComponentsToTimestampInProgressList(digitalComponents);
    return this.apiSignatureService.signatureExtendDocument(
      {
        body: {
          digitalComponentVersionIds
        },
        documentId,
      },
      skipErrorContext ? SKIP_ERROR_DIALOG : undefined
    ).pipe(
      tap(response => this.toastService.dispatchAddTimestampToasters(digitalComponents, response.extendResult))
    );
  }

  markDigitalComponent(digitalComponents: DigitalComponentCompleteDto[], documentId: number, assignTimestamp: boolean, skipErrorContext: boolean = false) {
    const digitalComponentVersionIds = this.extractDigitalComponentVersionIds(digitalComponents);
    this.addDigitalComponentsToMarkInProgressList(digitalComponents);
    const visibleSignatureRequests: VisibleSignatureRequest[] = digitalComponentVersionIds.map(digitalComponentVersionId => ({
      digitalComponentVersionId: digitalComponentVersionId!,
    }));
    return this.apiSignatureService.signatureMarkDocument(
      {
        body: {
          visibleSignatureRequests,
          assignTimestamp
        },
        documentId,
      },
      skipErrorContext ? SKIP_ERROR_DIALOG : undefined
    ).pipe(tap(response => this.toastService.dispatchMarkDigitalComponentToasters(digitalComponents, documentId, assignTimestamp, response.markResult)));
  }

  private getVisibleSignatureDto(data: VisibleSignatureSettings): VisibleSignatureDto {
    const {position, positionX, positionY, isPositionSelectedFromOptions, page, pageNumber} = data;
    return {
      page,
      pageNumber: page === VisibleSignaturePage.NUMBER ? pageNumber : undefined,
      position: isPositionSelectedFromOptions ? position : VisibleSignaturePosition.XY,
      positionX,
      positionY,
    };
  }

  private getVisibleSignatureRequestsForDigest(
    digitalComponentsWithoutManualSignature: DigitalComponentWithResult[],
    digitalComponentsWithManualSignature: Nullable<InProgressSignature[]>,
    isVisualSignature: boolean,
    visibleSignatureDto: VisibleSignatureDto,
    reason: Nullable<string>,
    place: Nullable<string>
  ): VisibleSignatureRequest[] {

    let signatureRequestsForManualSignatures: VisibleSignatureRequest[] = [];

    if (digitalComponentsWithManualSignature?.length) {
      signatureRequestsForManualSignatures = digitalComponentsWithManualSignature.map(digitalComponentWithManualSig => {
        const digitalComponentVersionId = getLastDigitalComponentVersionId(digitalComponentWithManualSig.digitalComponent)!;
        const digitalComponentReason = digitalComponentWithManualSig.reason ?? reason;
        const digitalComponentPlace = digitalComponentWithManualSig.place ?? place;
        return {
          digitalComponentVersionId,
          reason: digitalComponentReason!,
          place: digitalComponentPlace!,
          visibleSignatureDto: digitalComponentInProgressToVisibleSignatureDto(digitalComponentWithManualSig)!,
        };
      });
    }
    const signatureRequestsForOtherSignatures = digitalComponentsWithoutManualSignature.map(digitalComponent => {
        const digitalComponentVersionId = getLastDigitalComponentVersionId(digitalComponent)!;
        if (isVisualSignature && digitalComponent.infoCode !== digitalComponentPreSignatureCodes.infoCodes.notVisibleSignable) {
          return {
            digitalComponentVersionId,
            reason: reason!,
            place: place!,
            visibleSignatureDto,
          };
        }
        return {digitalComponentVersionId};
      });

    return signatureRequestsForManualSignatures.concat(signatureRequestsForOtherSignatures);
  }

  private getVisibleSignatureRequestsForSign(
    digitalComponentsWithoutManualSignature: DigitalComponentWithResult[],
    digitalComponentsWithManualSignature: Nullable<InProgressSignature[]>,
    signRequestsFromDigest: SignRequestFromDigest[],
    signedDigitalComponentsFromIdt: {[key: number]: Nullable<IdtSignResData>},
    isVisualSignature: boolean,
    certificate: string,
    certificateChain: string,
    assignTimestamp: boolean,
  ): DigitalComponentVersionSignRequest[] {
    const failedIdtSignDigitalComponentVersionId = Object.keys(signedDigitalComponentsFromIdt).find(digitalComponentId => !signedDigitalComponentsFromIdt[Number(digitalComponentId)]);

    if (failedIdtSignDigitalComponentVersionId) {
      const affectedDigitalComponent = [
        ...digitalComponentsWithoutManualSignature,
        ...(digitalComponentsWithManualSignature ?? []).map(inProgressSignature => inProgressSignature.digitalComponent),
      ].find(
        digitalComponent => digitalComponent.digitalComponentVersions!.find(version => version.id === Number(failedIdtSignDigitalComponentVersionId))
      )!;
      const affectedDigitalComponentVersion = affectedDigitalComponent.digitalComponentVersions!.find(
        version => version.id === Number(failedIdtSignDigitalComponentVersionId)
      )!;

      this.toastService.dispatchComponentErrorToast(
        EsslComponentToastType.SIGN_FILE_ERROR,
        {
          [InternalNotificationKey.DOCUMENT_ID]: affectedDigitalComponent.documentId!,
          [InternalNotificationKey.DIGITAL_COMPONENT_ID]: affectedDigitalComponent.id!,
          [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: affectedDigitalComponentVersion.id!,
          [InternalNotificationKey.ESSL_COMPONENT_LABEL]: affectedDigitalComponent.label!,
          [InternalNotificationKey.ERROR_DESCRIPTION]: this.translateService.instant('Interní chyba při komunikaci se Službou pro ověřování podpisů'),
        }
      );

      return [];
    }

    let signatureRequestsForManualSignatures: DigitalComponentVersionSignRequest[] = [];

    if (digitalComponentsWithManualSignature?.length) {
      const digitalComponentIds = digitalComponentsWithManualSignature.map(a => getLastDigitalComponentVersionId(a.digitalComponent));
      const filteredSignRequestsFromDigest = signRequestsFromDigest.filter(s => digitalComponentIds.includes(s.digitalComponentVersionId));

      signatureRequestsForManualSignatures = filteredSignRequestsFromDigest.map(signRequest => {
        /*const digitalComponentWithManualSig = digitalComponentsWithManualSignature.find(
          att => getLastDigitalComponentVersionId(att.digitalComponent) === signRequest.digitalComponentVersionId
        )!;*/

        const signature = signedDigitalComponentsFromIdt[signRequest.digitalComponentVersionId]!.signature;

        return {
          signature,
          signDate: signRequest.signDate,
          seSessionId: signRequest.seSessionId,
          certificateChain,
          certificate,
          assignTimestamp,
          digitalComponentVersionId: signRequest.digitalComponentVersionId,
        };
      });
    }
    const digitalComponentIds = digitalComponentsWithoutManualSignature.map(a => getLastDigitalComponentVersionId(a));
    const filteredSignRequestsFromDigest = signRequestsFromDigest.filter(s => digitalComponentIds.includes(s.digitalComponentVersionId));

    const signatureRequestsForOtherSignatures = filteredSignRequestsFromDigest.map(signRequest => {
      const digitalComponentWithoutManualSig = digitalComponentsWithoutManualSignature.find(
        att => getLastDigitalComponentVersionId(att) === signRequest.digitalComponentVersionId
      )!;

      const signature = signedDigitalComponentsFromIdt[signRequest.digitalComponentVersionId]!.signature;

      if (isVisualSignature && digitalComponentWithoutManualSig.infoCode !== digitalComponentPreSignatureCodes.infoCodes.notVisibleSignable) {
        return {
          signature,
          signDate: signRequest.signDate,
          seSessionId: signRequest.seSessionId,
          certificateChain,
          certificate,
          assignTimestamp,
          digitalComponentVersionId: signRequest.digitalComponentVersionId,
        };
      }
      else {
        return {
          signature,
          signDate: signRequest.signDate,
          seSessionId: signRequest.seSessionId,
          certificateChain,
          certificate,
          assignTimestamp,
          digitalComponentVersionId: signRequest.digitalComponentVersionId,
        };
      }
    });
    return signatureRequestsForManualSignatures!.concat(signatureRequestsForOtherSignatures!);
  }

  private getSignRequestsForIdt(
    selectedCertificate: Idt2Certificate,
    password: string,
    digestResults: DigitalComponentVersionDigestResult[] = []
  ): {signRequestsForIdt: SignRequestsForIdt; signRequestsFromDigest: SignRequestFromDigest[]} {
    const provider = selectedCertificate.location.provider;
    const alias = selectedCertificate.location.alias;

    const signRequestsFromDigest: SignRequestFromDigest[] = digestResults.map(signRequest => ({
      provider,
      alias,
      password,
      sha1: selectedCertificate.sha1,
      hashAlg: Idt2HashAlgorithm.SHA256,
      message: signRequest.hash!,
      signDate: signRequest.signDate!,
      digitalComponentVersionId: signRequest.digitalComponentVersionId!,
      seSessionId: signRequest.seSessionId!
    }));

    const signRequestsForIdt: {[key: number]: Observable<IdtSignResData>} = {};
    for (const request of signRequestsFromDigest) {
      const {hashAlg, message, digitalComponentVersionId} = request;

      const doSignRequest: IdtSignReqPayload = {
        sha1: request.sha1,
        hashAlg,
        message,
        password: password ?? '',
      };

      signRequestsForIdt[digitalComponentVersionId] = this.idtService.sign(doSignRequest);
    }

    return {signRequestsForIdt, signRequestsFromDigest};
  }

  private getDigestRequestBody(
    digitalComponentsWithoutManualSignature: DigitalComponentWithResult[],
    digitalComponentsWithManualSignature: Nullable<InProgressSignature[]>,
    isVisualSignature: boolean,
    visibleSignatureDto: VisibleSignatureDto,
    assignTimestamp: boolean,
    pem: string,
    reason: Nullable<string>,
    place: Nullable<string>
  ): DocumentDigestRequest {
    const visibleSignatureRequests: VisibleSignatureRequest[] = this.getVisibleSignatureRequestsForDigest(
      digitalComponentsWithoutManualSignature,
      digitalComponentsWithManualSignature,
      isVisualSignature,
      visibleSignatureDto,
      reason,
      place
    );
    return {
      visibleSignatureRequests,
      assignTimestamp,
      certificate: pem,
      certificateChain: pem,
    };
  }

  getSignatureRequest(
    digitalComponentsWithoutManualSignature: DigitalComponentWithResult[],
    digitalComponentsWithManualSignature: InProgressSignature[],
    documentId: number,
    assignTimestamp: boolean,
    idtCertData: Idt2Certificate,
    visibleSignatureSettings: VisibleSignatureSettings,
    password: string,
  ): Observable<Nullable<DocumentSignRequest>> {
    const {isVisualSignature} = visibleSignatureSettings;
    const visibleSignatureDto: VisibleSignatureDto = this.getVisibleSignatureDto(visibleSignatureSettings);
    return of(idtCertData).pipe(
      take(1),
      catchError(err => {
        return of(err);
      }),
      switchMap((response: Idt2Certificate) => {
        const purifiedPem = this.purifyPem(response.pem);

        const body = this.getDigestRequestBody(
          digitalComponentsWithoutManualSignature,
          digitalComponentsWithManualSignature,
          isVisualSignature,
          visibleSignatureDto,
          assignTimestamp,
          purifiedPem,
          visibleSignatureSettings.reason,
          visibleSignatureSettings.place
        );
        return this.apiSignatureService.signatureDigestDocument({documentId, body}).pipe(
          map(response => ({
            digestResult: response.digestResult,
            purifiedPem,
            certificateChain: purifiedPem,
          }))
        );
      }),
      switchMap(response => {
        if (!response.digestResult || response.digestResult.some(dr => dr.error)) {
          return throwError(() => new Error('Došlo k chybě při získávání otisků obsahů komponent.'));
        }
        else {
          const pairedSignRequests = this.getSignRequestsForIdt(
            idtCertData,
            password,
            response.digestResult
          );

          // not deprecated
          return forkJoin(pairedSignRequests.signRequestsForIdt).pipe(
            map(signedDigitalComponentsFromIdt => {
              const purifiedPem = this.purifyPem(idtCertData.pem);

              return {
                signedDigitalComponentsFromIdt,
                certificate: purifiedPem,
                certificateChain: response.certificateChain,
                signRequestsFromDigest: pairedSignRequests.signRequestsFromDigest,
              };
            })
          );
        }
      }),
      map(signDataWithResult => {
        const digitalComponentVersionSignRequests: DigitalComponentVersionSignRequest[] = this.getVisibleSignatureRequestsForSign(
          digitalComponentsWithoutManualSignature,
          digitalComponentsWithManualSignature,
          signDataWithResult.signRequestsFromDigest,
          signDataWithResult.signedDigitalComponentsFromIdt,
          isVisualSignature,
          signDataWithResult.certificate,
          signDataWithResult.certificateChain,
          assignTimestamp
        );
        const body: DocumentSignRequest = {
          digitalComponentVersionSignRequests
        };
        return body;
      }),
      catchError(e => {
        if (!(e instanceof HttpErrorResponse)) {
          if (
            e instanceof IdtCommunicationError &&
            'code' in e.errorData &&
            e.errorData.code === ErrorType.methodInternalError
          ) {
            this.dialogService.showError('Bylo zadáno špatné heslo k certifikátu. Opravte heslo a zkuste spustit podepsání znovu.');
          }
          else {
            this.dialogService.showError(e.message);
          }
        }

        return of(null);
      }),
    );
  }

  checkDigitalComponentsBeforeSign(digitalComponents: DigitalComponentCompleteDto[], documentId: number) {
    const payload = {body: {digitalComponentVersionIds: this.extractDigitalComponentVersionIds(digitalComponents)}, documentId};
    return this.apiSignatureService.signatureCheckBeforeSign(payload);
  }

  private extractDigitalComponentVersionIds(digitalComponents: DigitalComponentCompleteDto[]) {
    return digitalComponents.map(digitalComponent => getLastDigitalComponentVersionId(digitalComponent)!);
  }

  private dispatchManualDigitalComponentValidationToasters(digitalComponents: DigitalComponentCompleteDto[], validationResults: DigitalComponentVersionValidateResponse[]) {
    for (const validationResult of validationResults) {
      const digitalComponent = digitalComponents.find(row => getLastDigitalComponentVersionId(row) === validationResult.digitalComponentVersionId)!;

      let toastTemplateData: DigitalComponentTemplateData = {
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: digitalComponents[0].label!,
        [InternalNotificationKey.DOCUMENT_ID]: digitalComponents[0].documentId!,
        [InternalNotificationKey.DIGITAL_COMPONENT_ID]: digitalComponent!.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: validationResult.digitalComponentVersionId,
      };

      if (validationResult.success) {
        this.toastService.dispatchComponentInfoToast(
          EsslComponentToastType.MANUAL_DIGITAL_COMPONENT_VALIDATION_SUCCESS,
          toastTemplateData
        );
      }
      else {
        toastTemplateData = {
            ...toastTemplateData,
          [InternalNotificationKey.ERROR_DESCRIPTION]: this.translateService.instant(validationResult.error?.code ?? 'Neznámá chyba')};

        this.toastService.dispatchComponentErrorToast(
          EsslComponentToastType.MANUAL_DIGITAL_COMPONENT_VALIDATION_FAIL,
          toastTemplateData
        );
      }
    }
  }

  private purifyPem(pem: string): string {
    return pem
    .replace('-----BEGIN CERTIFICATE-----\r\n', '')
    .replace('\r\n-----END CERTIFICATE-----\r\n', '')
    .replaceAll('\r\n', '');
  }

}
