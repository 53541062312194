@if (showComponentDetailedInputToggle) {
  <icz-button-toggle-group class="grow"
    [value]="analogComponentsConfig.isDetailedInput"
    (valueChange)="emitComponentDetailedInputChange($event)"
    [options]="componentDetailedInputOptions">
  </icz-button-toggle-group>
}

<icz-section label="Listinné komponenty" [expanded]="componentsExpanded.paperComponents" [showExpansionHint]="true" [storeClosedState]="false">
  <icz-analog-component-create-form
    #paperComponentsCreateFormComponent
    [analogComponentType]="EsslAnalogComponentType.PAPER_COMPONENT"
    [config]="analogComponentsConfig"
    [resetForm]="resetForm"
    [form]="analogComponentsControlForPaper">
  </icz-analog-component-create-form>
  <div class="row mt-8 mb-8">
    <icz-button label="Přidat listinnou komponentu" (onAction)="addPaperComponent()" class="add-component-button"></icz-button>
  </div>
</icz-section>

<icz-section label="Nosiče" [expanded]="componentsExpanded.mediumComponents" [showExpansionHint]="true" [storeClosedState]="false">
  <icz-analog-component-create-form
    #mediumComponentsCreateFormComponent
    [analogComponentType]="EsslAnalogComponentType.MEDIUM_COMPONENT"
    [config]="analogComponentsConfig"
    [resetForm]="resetForm"
    [form]="analogComponentsControlForMedium">
  </icz-analog-component-create-form>
  <div class="row mt-8 mb-8">
    <icz-button label="Přidat komponentu nosič" (onAction)="addMediumComponent()" class="add-component-button"></icz-button>
  </div>
</icz-section>

<icz-section label="Předměty" [expanded]="componentsExpanded.physicalItemComponents" [showExpansionHint]="true" [storeClosedState]="false">
  <icz-analog-component-create-form
    #physicalItemsComponentsCreateFormComponent
    [analogComponentType]="EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT"
    [config]="analogComponentsConfig"
    [resetForm]="resetForm"
    [form]="analogComponentsControlForPhysicalItem">
  </icz-analog-component-create-form>
  <div class="row mt-8 mb-8">
    <icz-button label="Přidat komponentu předmět" (onAction)="addPhysicalItemComponent()" class="add-component-button"></icz-button>
  </div>
</icz-section>
