import {Component, Input} from '@angular/core';
import {CirculationActivityType, CirculationTaskState} from '|api/commons';
import {CirculationActivityDto} from '|api/flow';
import {TaskInfo, TaskInfoType} from './task-info.model';


@Component({
  selector: 'icz-task-info-bit',
  templateUrl: './task-info-bit.component.html',
  styleUrls: ['./task-info-bit.component.scss']
})
export class TaskInfoBitComponent {
  @Input({required: true})
  taskInfo: Nullable<TaskInfo>;
  @Input({required: true})
  parentActivity!: CirculationActivityDto;

  readonly TaskInfoType = TaskInfoType;

  get activityTransitionName(): string {
    if (this.taskInfo?.taskState === CirculationTaskState.PASSED_BY_TARGET) {
      return 'Předáno dál';
    }
    else {
      switch (this.taskInfo?.parentActivityType) {
        case CirculationActivityType.DOCUMENT_HANDOVER:
        case CirculationActivityType.FILE_HANDOVER:
          return 'Dokončeno';
        case CirculationActivityType.DOCUMENT_APPROVAL:
          if (this.taskInfo?.taskState === CirculationTaskState.DISAPPROVED_BY_TARGET) {
            return 'Neschváleno';
          }
          else {
            return 'Schváleno';
          }
        case CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT:
          return 'Vzato na vědomí';
        case CirculationActivityType.DOCUMENT_STATEMENT:
          return 'Dokončeno';
        default:
          return 'Dokončeno';
      }
    }
  }

  get activityDescriptionName(): string {
    const activityType = this.taskInfo?.parentActivityType;

    if (
      activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
      activityType === CirculationActivityType.FILE_HANDOVER
    ) {
      if (this.taskInfo?.hasChildActivities) {
        return 'Důvod předání dál';
      }
      else {
        return 'Poznámka k převzetí';
      }
    }
    else if (activityType === CirculationActivityType.DOCUMENT_APPROVAL) {
      if (this.taskInfo?.taskState === CirculationTaskState.DISAPPROVED_BY_TARGET) {
        return 'Důvod neschválení dokumentu';
      }
      else {
        return 'Poznámka ke schválení';
      }
    }
    else if (activityType === CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT) {
      return 'Poznámka';
    }
    else if (activityType === CirculationActivityType.DOCUMENT_STATEMENT) {
      return 'Vyjádření';
    }
    else if (activityType === CirculationActivityType.FILE_ISSD_RETURN_OR_REJECT || activityType === CirculationActivityType.DOCUMENT_ISSD_RETURN_OR_REJECT) {
      if (this.taskInfo?.taskState === CirculationTaskState.REJECTED_BY_ISSD) {
        return 'Důvod odmítnutí';
      }
      else if (this.taskInfo?.taskState === CirculationTaskState.RETURNED_BY_ISSD) {
        return 'Důvod vrácení';
      } else {
        return 'Poznámka';
      }
    }
    else {
      return 'Poznámka';
    }
  }
}
