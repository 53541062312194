/* tslint:disable */
/* eslint-disable */

/**
 * Status received internal message can be in from processing point of view.
 */
export enum ReceivedInternalMessageProcessingStatus {
  UNREAD = 'UNREAD',
  PROCESSED = 'PROCESSED'
}
