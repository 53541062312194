<div class="h-full w-full">
  @if (dataSource && columnsData) {
    <icz-table
      [id]="tableId"
      #tableComponent
      [columnsData]="columnsData"
      [dataSource]="dataSource"
      [config]="tableConfig"
      (clicked)="rowClicked($event)"
      (selectedRowsChanged)="selectedRowsChanged($event)"
      (pageLoad)="onPageLoad($event)"
      [isNonBlockingLoading]="loadingIndicatorService.isLoading(consignmentToolbarButtonsService)"
      >
      <ng-container tableTitle>
        <icz-label [label]="tableTitle"></icz-label>
        <!--    todo(lp) Q: If office desk table, should this be filtered to dispatch offices used for Office desk only ??-->
        @if (showDispatchOfficeSelector) {
          <icz-dispatch-office-selector
            class="row items-center ml-16"
            (dispatchOfficeSelected)="dispatchOfficeSelectorChanged($event)"
            >
          </icz-dispatch-office-selector>
        }
        @if (isUnitView) {
          <icz-unit-selector class="ml-16" (unitSelected)="unitSelected($event)"></icz-unit-selector>
        }
        <div class="gap-32 row"></div>
      </ng-container>
      @if (showTabs) {
        <icz-tabs
          tableTabs
          class="grow"
          [tabs]="tableTabs"
          [shouldNavigate]="false"
          [activeTab]="activatedTab"
          (tabClick)="tableTabViewChanged($event)">
        </icz-tabs>
      }
      <icz-label notFoundLabel noDataLabel [label]="(isOfficeDeskView || isOfficerCreateOfficeDeskView) ? 'Žádné vyvěšení' : 'Žádné vypravení'"></icz-label>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.CONSIGNMENT_TYPE">
        <icz-own-consignment-type-icon [consignmentType]="row.consignmentType" [iconSize]="iconSize"></icz-own-consignment-type-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.IDENTIFICATION_STATUS">
        <!-- todo(rb) needs to be completed on subjects side -->
        <!-- todo(lp) check if identification status as separate col is still planned (might be bcs of filtering) -->
        <icz-icon testingFeature svgIcon="verified_subject" testingFeatureLabel="NYI" [size]="iconSize"></icz-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.STATUS">
        <icz-tag [label]="'en.ownConsignmentStatus.' + row.status" color="blue"></icz-tag>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.IS_VALID">
        <icz-icon [svgIcon]="row.valid ? 'checked' : 'crossed'" size="small"></icz-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.EXCLUDE_FROM_BPF">
        <icz-checkbox [value]="getExcludeValue(row)"
          (valueChange)="excludedConsignmentIdsChanged($event, row)"
          [fieldDisabled]="getExcludeDisabled(row)"
          [iczTooltip]="getExcludeDisabled(row) ? 'Zásilka není listinná, nebo se již nachází na podacím archu.' : ''"
        label=""></icz-checkbox>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.CONSIGNEE; withEllipsis: true">
        <icz-consignment-table-consignee
          [consignment]="row"
        ></icz-consignment-table-consignee>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.CONSIGNEE_ADDRESS">
        <icz-consignment-table-address
          [consignment]="row"
        ></icz-consignment-table-address>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.DELIVERY_TYPE_ID">
        <icz-delivery-type-widget
          [deliveryTypeId]="row.deliveryTypeId"
        ></icz-delivery-type-widget>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.DELIVERY_RESULT">
        <icz-consignment-delivery-result
          [consignment]="row"
        ></icz-consignment-delivery-result>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.DOCUMENT_OBJECT_CLASS; let column = column">
        @if (isOwnOfficeDeskConsignment(row)) {
          <icz-table-entity-icon [consignment]="$any(row)" [view]="tableView"></icz-table-entity-icon>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.CONSIGNMENT_POSTING_NUMBER; withEllipsis: true; let column = column">
        @if ($any(row).consignmentPostingNumber) {
          <span [iczTooltip]="getConsignmentPostingNumber($any(row))">{{ getConsignmentPostingNumber($any(row)) }}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.PAYOUT_AMOUNT; withEllipsis: true; let column = column">
        @if ($any(row).payoutAmount | money; as payoutAmount) {
          <span [iczTooltip]="payoutAmount">{{ payoutAmount }}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.WEIGHT; withEllipsis: true; let column = column">
        @if ($any(row).weight; as weight) {
          <span [iczTooltip]="weight + 'g'">{{ weight + 'g' }}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER; withEllipsis: true; let column = column">
        @if (getOutboundConsignmentIdentifier(row); as outboundIdentifier) {
          <span [iczTooltip]="outboundIdentifier">{{ outboundIdentifier }}</span>
        }
      </ng-container>
      <icz-consignment-toolbar-buttons
        tools
        [selectedRows]="selectedRows"
        [view]="tableView"
        [disableAllButtons]="isOwnDocumentDeactivated"
        [isUnitView]="isUnitView"
        [dispatchOfficeId]="selectedDispatchOfficeId"
        [excludedConsignmentIdsForBpf]="excludedConsignmentIdsForBpf"
        [canCreateBulkPostingForm]="canCreateBulkPostingForm"
        [dispatchOfficeDistributionNodeIds]="dispatchOfficeDistributionNodeIds"
        (operationCompleted)="dataSource.reload(true)"
      ></icz-consignment-toolbar-buttons>
    </icz-table>
  }
</div>
