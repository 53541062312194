import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationLanguage} from '../../core/services/environment.models';
import {stripTimezoneFromDate} from '../../lib/utils';


export function iczFormatDate(
  currentLocale: ApplicationLanguage,
  value: Nullable<string | number | Date>,
  ignoreTimezoneOffset = false
) {
  const convertedValue = convertValueToDateObject(value, ignoreTimezoneOffset);

  if (currentLocale === ApplicationLanguage.CZECH || currentLocale === ApplicationLanguage.SLOVAK) {
    return convertedValue?.toLocaleDateString('cs-CZ') ?? '';
  }
  else {
    return convertedValue?.toLocaleDateString('en-US') ?? '';
  }
}

export function convertValueToDateObject(value: Nullable<string | number | Date>, ignoreTimezoneOffset = false): Nullable<Date> {
  if (value && (typeof (value) === 'number' || typeof (value) === 'string')) {
    if (typeof (value) === 'string' && !Number.isNaN(Number(value))) {
      value = Number(value);
    }
    value = new Date(value);
  }

  let convertedValue = value as Nullable<Date>;

  if (ignoreTimezoneOffset) {
    convertedValue = stripTimezoneFromDate(convertedValue);
  }

  return convertedValue;
}

@Pipe({
  name: 'localizedDate',
  pure: false, // false because it also depends on app locale
})
export class LocalizedDatePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * @param value - ISO date OR unix timestamp in milliseconds since Epoch
   * @param ignoreTimezoneOffset - use for formatting wall datetime strings without timezone specifiers
   */
  transform(value: Nullable<string | number | Date>, ignoreTimezoneOffset?: boolean): string {
    if (!value) { // Branch used merely for performance optimization
      return '';
    }

    return iczFormatDate(this.translateService.currentLang as ApplicationLanguage, value, ignoreTimezoneOffset);
  }

}
