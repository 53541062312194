/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { componentBulkDelete } from '../fn/component/component-bulk-delete';
import { ComponentBulkDelete$Params } from '../fn/component/component-bulk-delete';
import { componentFindComponentsBySetOfDocumentsIds } from '../fn/component/component-find-components-by-set-of-documents-ids';
import { ComponentFindComponentsBySetOfDocumentsIds$Params } from '../fn/component/component-find-components-by-set-of-documents-ids';
import { componentGetById } from '../fn/component/component-get-by-id';
import { ComponentGetById$Params } from '../fn/component/component-get-by-id';
import { componentSearch } from '../fn/component/component-search';
import { ComponentSearch$Params } from '../fn/component/component-search';
import { componentSearchAndCount } from '../fn/component/component-search-and-count';
import { ComponentSearchAndCount$Params } from '../fn/component/component-search-and-count';
import { EsslComponentBaseDto } from '../models/essl-component-base-dto';
import { EsslComponentExtendedDto } from '../models/essl-component-extended-dto';
import { PageEsslComponentBaseDto } from '../models/page-essl-component-base-dto';


/**
 * Controller for components
 */
@Injectable({ providedIn: 'root' })
export class ApiComponentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `componentFindComponentsBySetOfDocumentsIds()` */
  static readonly ComponentFindComponentsBySetOfDocumentsIdsPath = '/component/components-by-set-of-documents-ids';

  /**
   * Find all components for given set of document ids. If some id does not correspond any component, error is returned with information, which ids do not have corresponding component.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentFindComponentsBySetOfDocumentsIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  componentFindComponentsBySetOfDocumentsIds$Response(params: ComponentFindComponentsBySetOfDocumentsIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EsslComponentBaseDto>>> {
    return componentFindComponentsBySetOfDocumentsIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all components for given set of document ids. If some id does not correspond any component, error is returned with information, which ids do not have corresponding component.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentFindComponentsBySetOfDocumentsIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  componentFindComponentsBySetOfDocumentsIds(params: ComponentFindComponentsBySetOfDocumentsIds$Params, context?: HttpContext): Observable<Array<EsslComponentBaseDto>> {
    return this.componentFindComponentsBySetOfDocumentsIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EsslComponentBaseDto>>): Array<EsslComponentBaseDto> => r.body)
    );
  }

  /** Path part for operation `componentGetById()` */
  static readonly ComponentGetByIdPath = '/component/{id}';

  /**
   * Returns a component with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentGetById$Response(params: ComponentGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslComponentExtendedDto>> {
    return componentGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a component with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentGetById(params: ComponentGetById$Params, context?: HttpContext): Observable<EsslComponentExtendedDto> {
    return this.componentGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslComponentExtendedDto>): EsslComponentExtendedDto => r.body)
    );
  }

  /** Path part for operation `componentSearch()` */
  static readonly ComponentSearchPath = '/component/search-api';

  /**
   * Finds all components matching a given search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentSearch$Response(params?: ComponentSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEsslComponentBaseDto>> {
    return componentSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all components matching a given search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentSearch(params?: ComponentSearch$Params|null|undefined, context?: HttpContext): Observable<PageEsslComponentBaseDto> {
    return this.componentSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEsslComponentBaseDto>): PageEsslComponentBaseDto => r.body)
    );
  }

  /** Path part for operation `componentSearchAndCount()` */
  static readonly ComponentSearchAndCountPath = '/component/search-api/count';

  /**
   * Counts all components matching a given search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentSearchAndCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentSearchAndCount$Response(params?: ComponentSearchAndCount$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return componentSearchAndCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Counts all components matching a given search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentSearchAndCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  componentSearchAndCount(params?: ComponentSearchAndCount$Params|null|undefined, context?: HttpContext): Observable<number> {
    return this.componentSearchAndCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `componentBulkDelete()` */
  static readonly ComponentBulkDeletePath = '/component/bulk-delete';

  /**
   * Deletes all types of components according to theirs ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `componentBulkDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  componentBulkDelete$Response(params: ComponentBulkDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return componentBulkDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes all types of components according to theirs ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `componentBulkDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  componentBulkDelete(params: ComponentBulkDelete$Params, context?: HttpContext): Observable<void> {
    return this.componentBulkDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
