import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';

const LOG_HTTP_REQUESTS_LONGER_THAN = 1000; // ms

@Injectable()
export class ProfilerInterceptor implements HttpInterceptor {

  private pendingRequests = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const pendingAtStart = this.pendingRequests;
    this.pendingRequests++;

    const started = Date.now();
    let result: string;

    return !LOG_HTTP_REQUESTS_LONGER_THAN ? next.handle(req) : next.handle(req).pipe(
      tap({
        next: (event: HttpEvent<any>) => result = event instanceof HttpResponse ? 'succeeded' : '',
        error: _ => result = 'failed',
      }),
      finalize(() => {
        this.pendingRequests--;
        const elapsed = Date.now() - started;
        if (elapsed > LOG_HTTP_REQUESTS_LONGER_THAN) {
          const pendingMsg = (pendingAtStart || this.pendingRequests) ? `(while other ${pendingAtStart}~${this.pendingRequests} pending)` : '';
          console.warn(`${elapsed} ms for ${req.method} "${req.urlWithParams}" and ${result}.`, pendingMsg);
        }
      })
    );
  }

}
