import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {
  GenderType,
  SubjectAttributeAddressDto,
  SubjectAttributeDataBoxDto,
  SubjectAttributeStringDto,
  SubjectRecordClassification,
  SubjectRecordDto
} from '|api/commons';
import {
  AttributeComponentType,
  ATTRIBUTES_WITH_MULTI_VALUES,
  mapLegalForms,
  SubjectAttributeWithMultipleValues,
  SubjectRecordWithSourceDto
} from '../../../model/subjects.model';
import {IczOnChanges, IczSimpleChanges} from '../../../../../utils/icz-on-changes';
import {IczFormArray, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {SubjectTemplateUtils} from '../../../../../utils/subject-template-utils';
import {SubjectCreateFormComponent} from '../../subject-create-form/subject-create-form.component';
import {AddressForm} from '../../../model/addresses.model';
import {getNewStringAttribute} from '../../../model/subject-attribute.model';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {Option} from '../../../../../model';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {SubjectAttributeType} from '../../../model/subject-attribute-type';

@Component({
  selector: 'icz-subject-table-sidebar',
  templateUrl: './subject-table-sidebar.component.html',
  styleUrls: ['./subject-table-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectTableSidebarComponent implements IczOnChanges {

  @Input({required: true}) subject: Nullable<SubjectRecordWithSourceDto>;
  @Input() opened = false;
  @Output() closed = new EventEmitter<void>();

  private codebookService = inject(CodebookService);

  protected readonly SubjectAttributeType = SubjectAttributeType;
  legalFormOptions$ = this.codebookService.allLegalForms().pipe(mapLegalForms());
  genderOptions: Option[] = enumToOptions('subjectGender', GenderType);

  form: Nullable<IczFormGroup>;

  orderedAttributes: SubjectAttributeType[] = [
    SubjectAttributeType.FIRST_NAME,
    SubjectAttributeType.SURNAME,
    SubjectAttributeType.DEGREE_BEFORE,
    SubjectAttributeType.DEGREE_AFTER,
    SubjectAttributeType.BUSINESS_NAME,
    SubjectAttributeType.CID,
    SubjectAttributeType.CLIENT_ID,
    SubjectAttributeType.LEGAL_FORM,
    SubjectAttributeType.GENDER,
    SubjectAttributeType.IDENTITY_CARD_ID,
    SubjectAttributeType.DRIVING_LICENCE_ID,
    SubjectAttributeType.PASSPORT_ID,
    SubjectAttributeType.BIRTH_DATE,
    SubjectAttributeType.BIRTH_NAME,
    SubjectAttributeType.BIRTH_SURNAME,
    SubjectAttributeType.BIRTH_PLACE,
    SubjectAttributeType.TAX_ID,
    SubjectAttributeType.VAT_ID,
    SubjectAttributeType.EXCISE_TAX_ID,
    SubjectAttributeType.LE_ID,
    SubjectAttributeType.ART_1_P_3_ID,
    SubjectAttributeType.EORI_CODE,
    SubjectAttributeType.COMPANY_ID,
    SubjectAttributeType.RESIDENTIAL_ADDRESS,
    SubjectAttributeType.MAILING_ADDRESS,
    SubjectAttributeType.NOTE,
  ];

  orderedAttributesWithMultiplicityMany: SubjectAttributeWithMultipleValues[] = [
    SubjectAttributeType.DATA_BOX,
    SubjectAttributeType.ADDITIONAL_ADDRESS,
    SubjectAttributeType.POSTAL_BOX,
    SubjectAttributeType.EMAIL,
    SubjectAttributeType.PHONE_FAX,
  ];

  getMultipleValuesControl(attribute: SubjectAttributeType): IczFormArray {
    return this.form?.get(attribute) as IczFormArray;
  }

  get classification(): SubjectRecordClassification {
    return SubjectTemplateUtils.classification(this.form!);
  }

  private readonlyPatchAddressesWithMultiplicitOne(form: IczFormGroup, subject: SubjectRecordDto): IczFormGroup {
    const addressAttrs = [SubjectAttributeType.MAILING_ADDRESS, SubjectAttributeType.RESIDENTIAL_ADDRESS];
    addressAttrs.forEach(attr => {
      if ((subject.attributes[attr] as SubjectAttributeAddressDto)?.value) {
        form.get(attr)!.patchValue(subject.attributes[attr]);
        form.get(attr)!.get('value')!
          .setValue(SubjectTemplateUtils.getAddressWithoutTypeString((subject.attributes[attr] as SubjectAttributeAddressDto).value));
      }
    });
    return form;
  }

  private readonlyPatchAttributesWithMultipleValues(form: IczFormGroup, subject: SubjectRecordDto): IczFormGroup {
    const emails = subject.attributes[SubjectAttributeType.EMAIL];
    const phoneFaxes = subject.attributes[SubjectAttributeType.PHONE_FAX];
    const additionalAddresses = subject.attributes.additionalAddresses?.map(attr => {return {...attr, value: SubjectTemplateUtils.getAddressWithoutTypeString(attr.value)};});
    const postalBoxes = subject.attributes.postalBoxes?.map(attr => {return {...attr, value: SubjectTemplateUtils.getPostalBoxString(attr.value)};});
    const dataBoxes = subject.attributes.dataBoxes?.map(attr => {return {...attr, value: attr.value!.id};});

    ATTRIBUTES_WITH_MULTI_VALUES.forEach(attr => {
      const attrControl = form.get(attr) as IczFormArray;
      let attrObjects: Nullable<SubjectAttributeStringDto | SubjectAttributeDataBoxDto | AddressForm>[] = [];
      if (attr === SubjectAttributeType.ADDITIONAL_ADDRESS) {
        attrObjects = additionalAddresses!;
      } else if (attr === SubjectAttributeType.DATA_BOX) {
        attrObjects = dataBoxes!;
      } else if (attr === SubjectAttributeType.EMAIL) {
        attrObjects = emails!;
      } else if (attr === SubjectAttributeType.PHONE_FAX) {
        attrObjects = phoneFaxes!;
      } else if (attr === SubjectAttributeType.POSTAL_BOX) {
        attrObjects = postalBoxes!;
      }
      attrControl.setSize(attrObjects?.length ?? 0);
      attrObjects?.forEach((obj: any, i: number) => {
        if (obj) {
          attrControl.controls[i]!.patchValue(obj);
        }
      });
    });

    return form;
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.subject?.currentValue) {
      this.form = SubjectCreateFormComponent.getCreateSubjectFormGroup(this.subject);
      // transforming all attribute data types to string for readonly purpose
      this.form.controls[SubjectAttributeType.MAILING_ADDRESS] = getNewStringAttribute(null, []);
      this.form.controls[SubjectAttributeType.RESIDENTIAL_ADDRESS] = getNewStringAttribute(null, []);
      this.form.controls[SubjectAttributeType.ADDITIONAL_ADDRESS] = new IczFormArray(() => getNewStringAttribute(null, []), []);
      this.form.controls[SubjectAttributeType.POSTAL_BOX] = new IczFormArray(() => getNewStringAttribute(null, []), []);
      this.form.controls[SubjectAttributeType.DATA_BOX] = new IczFormArray(() => getNewStringAttribute(null, []), []);
      this.readonlyPatchAddressesWithMultiplicitOne(this.form, this.subject!);
      this.readonlyPatchAttributesWithMultipleValues(this.form, this.subject!);
      this.form.disable();
    }
  }

  protected readonly SubjectAttributeComponentType = AttributeComponentType;
}
