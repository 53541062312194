<div class="col gap-16 p-24">
  @for (timelineItem of timelineItems; track timelineItem) {
    <div class="icz-body-1">
      <div>
        <div>{{ 'Původní číslo jednací' | translate }}:</div>
        @if (timelineItem ?? ('bez čísla jednacího' | translate); as refNumber) {
          <div
            [iczTooltip]="refNumber" iczTooltipClass="ref-number-multiline"
            class="ref-number"
          >{{ refNumber }}</div>
        }
      </div>
    </div>
  }
</div>
