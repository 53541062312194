import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {TableToolbarPopupComponent} from '../table-toolbar-popup/table-toolbar-popup.component';
import {DISABLED_ELEMENT_TOOLTIP_DELAY} from '../../../essentials/tooltip.directive';
import {SavedFiltersService} from '../saved-filters.service';
import {ResponsivityService} from '../../../essentials/responsivity.service';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {TableSavedFilters} from '../../../../services/user-settings.service';
import {Option} from '../../../../model';
import {arrayToOptions} from '../../../../core/services/data-mapping.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Observable, take} from 'rxjs';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {FilterItemValueTree, isFilterTreeEmpty} from '../../filter-trees.utils';
import {DialogService} from '../../../../core/services/dialog.service';


const DEFAULT_SAVED_FILTER_SCOPE = '_DEFAULT';

export function openFilterDeleteQuestion(
  savedFilterName: string,
  dialogService: DialogService,
  translateService: TranslateService,
) {
  return dialogService.openQuestionDialog(
    {
      title: 'Smazat uložený filtr',
      question: `${translateService.instant('Opravdu chcete smazat uložený filtr')} "${savedFilterName}"?`,
      description: 'Tuto operaci nelze vrátit.',
    }
  );
}


@Component({
  selector: 'icz-saved-filters',
  templateUrl: './saved-filters.component.html',
  styleUrls: ['./saved-filters.component.scss'],
})
export class SavedFiltersComponent implements OnInit {

  protected savedFiltersService = inject(SavedFiltersService);
  protected responsivityService = inject(ResponsivityService);
  protected loadingService = inject(LoadingIndicatorService);
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @ViewChild('saveFilterPopup')
  saveFilterPopup!: TableToolbarPopupComponent;
  @ViewChild('savedFiltersPopup')
  savedFiltersPopup!: TableToolbarPopupComponent;

  @Input()
  searchTerm: Nullable<string> = '';
  @Input({required: true})
  activeFilters: Nullable<FilterItemValueTree>;
  @Input({required: true})
  savedFilterScope: string = DEFAULT_SAVED_FILTER_SCOPE;
  @Input({required: true})
  defaultFilterName$!: Observable<Nullable<string>>;
  @Output()
  savedFilterSelected = new EventEmitter<FilterItemValueTree>();

  form = new IczFormGroup({
    filterName: new IczFormControl<Nullable<string>>(null),
    setNewFilterAsDefault: new IczFormControl<Nullable<boolean>>(null),
  });

  currentTableSavedFilters: TableSavedFilters = {};

  savedFilterOptions: Option[] = [];

  get areFiltersEmpty(): boolean {
    return (!this.activeFilters || isFilterTreeEmpty(this.activeFilters)) && !this.searchTerm;
  }

  get filterName(): string {
    return this.form.get('filterName')!.value!;
  }

  get setNewFilterAsDefault() {
    return this.form.get('setNewFilterAsDefault')!.value;
  }

  get isFilterSaveDialogValid(): boolean {
    return Boolean(this.filterName && !this.isFilterNameDuplicate);
  }

  get saveButtonTooltip(): Nullable<string> {
    if (!this.isFilterSaveDialogValid) {
      if (!this.filterName) {
        return 'Není vyplněn název filtru';
      }
      else if (this.isFilterNameDuplicate) {
        return 'Stejný název filtru pro tuto tabulku již existuje. Zvolte prosím jiný.';
      }
    }

    return null;
  }

  private get isFilterNameDuplicate(): boolean {
    return this.currentTableSavedFilters.hasOwnProperty(this.filterName);
  }

  DISABLED_ELEMENT_TOOLTIP_DELAY = DISABLED_ELEMENT_TOOLTIP_DELAY;

  ngOnInit() {
    this.savedFiltersService.savedFilters$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(savedFilters => {
      this.currentTableSavedFilters = savedFilters[this.savedFilterScope] ?? {};
      this.retrieveSavedFilterOptions();
      this.cd.markForCheck();
    });
  }

  useDefaultFilterName(): void {
    this.loadingService.doLoading(
      this.defaultFilterName$.pipe(
        take(1),
      ),
      this
    ).subscribe(defaultFilterName => {
      this.form.get('filterName')!.setValue(defaultFilterName);
    });
  }

  restoreSavedFilter(selectedFilterOptions: Option[]): void {
    const filterKey = selectedFilterOptions[0].value;
    const filterValues = this.currentTableSavedFilters[filterKey as string];

    this.savedFilterSelected.emit(filterValues);

    this.closeSavedFiltersDialog();
  }

  saveFilter(): void {
    if (this.isFilterSaveDialogValid) {
      const filterName = this.filterName;

      this.savedFiltersService.saveFilter(
        this.savedFilterScope,
        filterName,
        this.activeFilters,
        this.searchTerm,
      );
      if (this.setNewFilterAsDefault) {
        this.savedFiltersService.updateFilterDefaultState(
          this.savedFilterScope,
          true,
          filterName
        );
      }

      this.closeSaveFilterDialog();
      this.cd.detectChanges();
    }
  }

  closeSaveFilterDialog(): void {
    this.saveFilterPopup.isOpen = false;
    this.form.reset();
  }

  savedFilterDeleted(savedFilterName: string): void {
    openFilterDeleteQuestion(
      savedFilterName,
      this.dialogService,
      this.translateService,
    ).subscribe(_ => {
      this.savedFiltersService.deleteFilter(this.savedFilterScope, savedFilterName);
      this.savedFiltersPopup.isOpen = false;
      this.retrieveSavedFilterOptions();
      this.cd.detectChanges();
    });
  }

  defaultFilterChanged(value: any, option: string) {
    this.savedFiltersService.updateFilterDefaultState(this.savedFilterScope, value, option);
  }

  getDefaultValue(option: string) {
    const filterValue = this.currentTableSavedFilters[option as string];
    if (filterValue) {
      return filterValue.isDefault ?? false;
    } else {
      return false;
    }
  }

  closeSavedFiltersDialog() {
    this.savedFiltersPopup.toggle();
  }

  private retrieveSavedFilterOptions() {
    this.savedFilterOptions = arrayToOptions(Object.keys(this.currentTableSavedFilters));
  }

}
