import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
  ApiDigitalComponentService,
  DigitalComponentCompleteDto,
  DigitalComponentDto,
  DigitalComponentReferenceCreateDto,
  DigitalComponentVersionValidationResult
} from '|api/component';

@Injectable({
  providedIn: 'root',
})
export class DigitalComponentUpdateService {

  private apiDigitalComponentService = inject(ApiDigitalComponentService);

  digitalComponentUpdateMetadata(
    uploadModalResult: DigitalComponentCompleteDto[],
    digitalComponent: DigitalComponentCompleteDto,
  ): Observable<Nullable<DigitalComponentDto>> {
    if (!uploadModalResult || !uploadModalResult.length) return of(null);

    const {description, isFinal, originType, relationType, label} = uploadModalResult[0];
    const body = {
      label,
      description,
      isFinal,
      originType,
      relationType,
    };

    return this.apiDigitalComponentService.digitalComponentUpdateMetadata({
      id: digitalComponent.id!,
      body,
    });
  }

  linkNewDigitalComponentsToDocument(
    uploadModalResult: DigitalComponentReferenceCreateDto[],
    documentId: Nullable<number>,
  ): Observable<DigitalComponentVersionValidationResult[]> {
    if (!uploadModalResult || !uploadModalResult.length) return of();
    const body = uploadModalResult as DigitalComponentReferenceCreateDto[];

    return this.apiDigitalComponentService.digitalComponentLinkDigitalComponentToDocument({
      documentId: documentId!,
      body,
    });
  }

}
