/* tslint:disable */
/* eslint-disable */

/**
 * Volume handling type
 */
export enum VolumeHandlingType {
  MANUAL = 'MANUAL',
  TIME = 'TIME',
  AMOUNT = 'AMOUNT',
  VOLUME = 'VOLUME'
}
