/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { organizationCreate } from '../fn/organization/organization-create';
import { OrganizationCreate$Params } from '../fn/organization/organization-create';
import { organizationCreateFromFile } from '../fn/organization/organization-create-from-file';
import { OrganizationCreateFromFile$Params } from '../fn/organization/organization-create-from-file';
import { OrganizationDto } from '|api/commons';
import { organizationFind } from '../fn/organization/organization-find';
import { OrganizationFind$Params } from '../fn/organization/organization-find';
import { organizationFindActiveOrDeactivated } from '../fn/organization/organization-find-active-or-deactivated';
import { OrganizationFindActiveOrDeactivated$Params } from '../fn/organization/organization-find-active-or-deactivated';
import { OrganizationFromFileCreateDto } from '../models/organization-from-file-create-dto';
import { organizationGetAll } from '../fn/organization/organization-get-all';
import { OrganizationGetAll$Params } from '../fn/organization/organization-get-all';
import { organizationGetAllActive } from '../fn/organization/organization-get-all-active';
import { OrganizationGetAllActive$Params } from '../fn/organization/organization-get-all-active';
import { organizationGetById } from '../fn/organization/organization-get-by-id';
import { organizationGetById_1 } from '../fn/organization/organization-get-by-id-1';
import { OrganizationGetById_1$Params } from '../fn/organization/organization-get-by-id-1';
import { OrganizationGetById$Params } from '../fn/organization/organization-get-by-id';
import { organizationParseFromFile } from '../fn/organization/organization-parse-from-file';
import { OrganizationParseFromFile$Params } from '../fn/organization/organization-parse-from-file';
import { organizationUpdate } from '../fn/organization/organization-update';
import { OrganizationUpdate$Params } from '../fn/organization/organization-update';
import { PageOrganizationDto } from '../models/page-organization-dto';


/**
 * API for management of organizations
 */
@Injectable({ providedIn: 'root' })
export class ApiOrganizationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `organizationUpdate()` */
  static readonly OrganizationUpdatePath = '/organization/{organizationCode}';

  /**
   * Update organization.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationUpdate$Response(params: OrganizationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update organization.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationUpdate(params: OrganizationUpdate$Params, context?: HttpContext): Observable<void> {
    return this.organizationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationParseFromFile()` */
  static readonly OrganizationParseFromFilePath = '/organization/parse-from-file';

  /**
   * Returns organization parsed from registration file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationParseFromFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  organizationParseFromFile$Response(params?: OrganizationParseFromFile$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OrganizationFromFileCreateDto>> {
    return organizationParseFromFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns organization parsed from registration file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationParseFromFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  organizationParseFromFile(params?: OrganizationParseFromFile$Params|null|undefined, context?: HttpContext): Observable<OrganizationFromFileCreateDto> {
    return this.organizationParseFromFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationFromFileCreateDto>): OrganizationFromFileCreateDto => r.body)
    );
  }

  /** Path part for operation `organizationFindActiveOrDeactivated()` */
  static readonly OrganizationFindActiveOrDeactivatedPath = '/organization';

  /**
   * Get Active And Deactivated Organizations.
   *
   * Returns info about all organizations which are either active or has been deactivated. In other words, this operation will always filter out soft-deleted or being-set-up organizations. Returned data is ordered by organization state and name (active are returned first).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationFindActiveOrDeactivated()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindActiveOrDeactivated$Response(params?: OrganizationFindActiveOrDeactivated$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationDto>>> {
    return organizationFindActiveOrDeactivated(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Active And Deactivated Organizations.
   *
   * Returns info about all organizations which are either active or has been deactivated. In other words, this operation will always filter out soft-deleted or being-set-up organizations. Returned data is ordered by organization state and name (active are returned first).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationFindActiveOrDeactivated$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFindActiveOrDeactivated(params?: OrganizationFindActiveOrDeactivated$Params|null|undefined, context?: HttpContext): Observable<Array<OrganizationDto>> {
    return this.organizationFindActiveOrDeactivated$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationDto>>): Array<OrganizationDto> => r.body)
    );
  }

  /** Path part for operation `organizationCreate()` */
  static readonly OrganizationCreatePath = '/organization';

  /**
   * Create new organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationCreate$Response(params: OrganizationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationDto>> {
    return organizationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationCreate(params: OrganizationCreate$Params, context?: HttpContext): Observable<OrganizationDto> {
    return this.organizationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `organizationCreateFromFile()` */
  static readonly OrganizationCreateFromFilePath = '/organization/create-from-file';

  /**
   * Creates new organization parsed from registration form file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationCreateFromFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationCreateFromFile$Response(params: OrganizationCreateFromFile$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationDto>> {
    return organizationCreateFromFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new organization parsed from registration form file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationCreateFromFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  organizationCreateFromFile(params: OrganizationCreateFromFile$Params, context?: HttpContext): Observable<OrganizationDto> {
    return this.organizationCreateFromFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `organizationGetById()` */
  static readonly OrganizationGetByIdPath = '/organization/{id}';

  /**
   * Get Organization By ID.
   *
   * Returns info about the organization with the given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetById$Response(params: OrganizationGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationDto>> {
    return organizationGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Organization By ID.
   *
   * Returns info about the organization with the given ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetById(params: OrganizationGetById$Params, context?: HttpContext): Observable<OrganizationDto> {
    return this.organizationGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `organizationFind()` */
  static readonly OrganizationFindPath = '/organization/search-api';

  /**
   * Find Organizations.
   *
   * Returns info about all organizations which match given criteria
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFind$Response(params?: OrganizationFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOrganizationDto>> {
    return organizationFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find Organizations.
   *
   * Returns info about all organizations which match given criteria
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationFind(params?: OrganizationFind$Params|null|undefined, context?: HttpContext): Observable<PageOrganizationDto> {
    return this.organizationFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOrganizationDto>): PageOrganizationDto => r.body)
    );
  }

  /** Path part for operation `organizationGetAll()` */
  static readonly OrganizationGetAllPath = '/organization/get-all';

  /**
   * Get All Organizations.
   *
   * Returns info about all organizations which either exist or existed in the system.In other words, this operation will return any organization regardless its state, including such an organization which has been soft-deleted. Returned data is ordered by organization name.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetAll$Response(params?: OrganizationGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationDto>>> {
    return organizationGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Get All Organizations.
   *
   * Returns info about all organizations which either exist or existed in the system.In other words, this operation will return any organization regardless its state, including such an organization which has been soft-deleted. Returned data is ordered by organization name.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetAll(params?: OrganizationGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<OrganizationDto>> {
    return this.organizationGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationDto>>): Array<OrganizationDto> => r.body)
    );
  }

  /** Path part for operation `organizationGetAllActive()` */
  static readonly OrganizationGetAllActivePath = '/organization/get-all-active';

  /**
   * Get all active Organizations.
   *
   * Returns info about all active organizations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationGetAllActive()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetAllActive$Response(params?: OrganizationGetAllActive$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationDto>>> {
    return organizationGetAllActive(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all active Organizations.
   *
   * Returns info about all active organizations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationGetAllActive$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetAllActive(params?: OrganizationGetAllActive$Params|null|undefined, context?: HttpContext): Observable<Array<OrganizationDto>> {
    return this.organizationGetAllActive$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationDto>>): Array<OrganizationDto> => r.body)
    );
  }

  /** Path part for operation `organizationGetById_1()` */
  static readonly OrganizationGetById_1Path = '/organization/by-code/{code}';

  /**
   * Get Organization By Code.
   *
   * Returns info about the organization with a given code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationGetById_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetById_1$Response(params: OrganizationGetById_1$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationDto>> {
    return organizationGetById_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Organization By Code.
   *
   * Returns info about the organization with a given code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationGetById_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationGetById_1(params: OrganizationGetById_1$Params, context?: HttpContext): Observable<OrganizationDto> {
    return this.organizationGetById_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

}
