import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {IczTableFilter} from '../table-filter.component';
import {
  SubjectCustomFilterDialogComponent,
  SubjectCustomFilterDialogResult
} from './subject-custom-filter-dialog/subject-custom-filter-dialog.component';
import {IczModalService} from '../../../../services/icz-modal.service';
import {isNoValueOperator} from '../../../../services/search-api.service';


@Component({
  selector: 'icz-subject-custom-filter',
  templateUrl: './subject-custom-filter.component.html',
  styleUrls: ['./subject-custom-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectCustomFilterComponent extends IczTableFilter implements OnInit {

  private iczModalService = inject(IczModalService);

  selectedSubjects: SubjectRecordDto[] = [];

  ngOnInit(): void {
    this.initForm();

    this.iczModalService.openComponentInModal({
      component: SubjectCustomFilterDialogComponent,
      modalOptions: {
        width: 1000,
        height: 800,
        disableAutoMargin: true,
        useCustomHeader: true,
      },
      data: {
        filterOperators: this.filterOperators,
        form: this.form,
      }
    }).subscribe(result => {
      if (!result) return;

      const res = result as SubjectCustomFilterDialogResult;
      this.selectedSubjects = res.selectedSubjects;
      if (res.selectedSubjects.length) {
        this.applyFilter();
      }
    });
  }

  applyFilter(): void {
    this.emitFilterValue();
    this.closeFilterPopup();
  }

  emitFilterValue(): void {
    let value = this.form.get('value')!.value;
    if (!value?.length || !this.selectedSubjects.length) return;

    value = value.sort();
    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    this.selectedSubjects = this.selectedSubjects.sort((a, b) => {
      if (a.id! > b.id!) {
        return 1;
      }
      if (a.id! < b.id!) {
        return -1;
      }
      return 0;
    });

    this.setFilterValue.emit({
      value,
      label: null,
      filterOperator,
      closeAfter: true,
    });
  }

  applyFilterOperator(): void {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();
      this.emitNoValueFilterValue(filterOperator);
      this.closeFilterPopup();
    } else if (valueControl!.value) {
      this.emitFilterValue();
    } else {
      valueControl!.enable();
    }
  }

}
