<div class="col grow">
  @if (!isAtleastOneDigitalComponentUploaded) {
    <icz-alert heading="Vyberte soubor k nahrání"></icz-alert>
  }
  <icz-digital-components-upload-form
    class="grow"
    [digitalComponent]="digitalComponent"
    [config]="digitalComponentsConfig"
    [type]="data.type"
    [form]="form"
    [selectFiles]="selectFiles"
    (fileSelected)="fileSelected()"
    [uploadFiles]="digitalComponent ? uploadFiles : null"
    [isUploading]="isUploading"
    (fileUploaded)="fileUploaded($event)"
  ></icz-digital-components-upload-form>
</div>

<icz-form-buttons>
  @if (isCheckInDialog) {
    <icz-button iczFormSubmit [disabled]="disabled" leftButtons (onAction)="submitAndUnlock()" primary label="Uložit a odemknout"></icz-button>
  }
  <icz-button iczFormSubmit [disabled]="disabled || primarySubmitDisabled" leftButtons (onAction)="submit({unlock: false})" [primary]="!isCheckInDialog" label="Uložit"></icz-button>
  @if (canUpload) {
    <icz-button leftButtons (onAction)="selectFiles.emit()" [label]="uploadNextLabel"></icz-button>
  }
  <icz-button rightButtons [disabled]="isUploading" (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
