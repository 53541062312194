<icz-section label="Technické údaje" [showExpansionHint]="false" [boxed]="true">
  <div class="row">
    <div class="col grow">
      <icz-readonly-field class="grow" [value]="subject?.id" label="ID subjektu"></icz-readonly-field>
    </div>
  </div>
  <div class="row">
    <div class="col grow">
      <icz-readonly-field class="grow" [value]="subject?.auditInfo?.createdAt | localizedDate" label="Založeno"></icz-readonly-field>
    </div>
    <div class="col grow">
      <icz-readonly-field class="grow" [value]="subject?.auditInfo?.modifiedAt | localizedDate" label="Poslední úprava"></icz-readonly-field>
    </div>
  </div>
  <div class="row">
    <div class="col grow">
      <icz-readonly-field class="grow" [value]="subject?.isdsVerified | localizedDate" label="Datum posledního ověření"></icz-readonly-field>
    </div>
    <div class="col grow">
      <icz-readonly-field class="grow" [value]="subject?.disposalDate | localizedDate" label="Předpokládané vyřazení"></icz-readonly-field>
    </div>
  </div>
  <div class="row">
    <icz-readonly-field class="grow" [value]="subject?.auditInfo?.createdBy" label="Založil"></icz-readonly-field>
  </div>
</icz-section>

<icz-section label="Počet vazeb na objekty" [showExpansionHint]="false" [boxed]="true" class="mb-64">
  <div class="row">
    <icz-readonly-field class="grow" [value]="subjectRelationsCount" label="Počet vazeb na objekty"></icz-readonly-field>
  </div>
</icz-section>
