<div class="gap-16 col">
  <icz-section [label]="object?.refNumber ?? WITHOUT_REF_NUMBER" boxed>
    @if (object?.refNumber) {
      <icz-copy-to-clipboard-button
        sectionHeader
        size="small"
        [text]="object?.refNumber!"
      ></icz-copy-to-clipboard-button>
    }

    @if (isDocumentObject(object)) {
      <icz-document-registration-timeline-link
        sectionHeaderAppendix
        [document]="object"
      ></icz-document-registration-timeline-link>
    }

    <div class="row">
      <icz-readonly-field class="grow" [label]="isFileObject(object) ? 'Název' : 'Věc'" [value]="object?.subject"></icz-readonly-field>
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="Popis" [value]="object?.description"></icz-readonly-field>
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="Poznámka" [value]="object?.note"></icz-readonly-field>
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="UID - Unikátní identifikátor" [value]="object?.uid | formatIdentifier"></icz-readonly-field>
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="Značka" [value]="object?.label"></icz-readonly-field>
      @if (isDocumentObject(object)) {
        <icz-readonly-field class="grow" label="Forma" [value]="object?.documentForm" [options]="documentFormOptions"></icz-readonly-field>
      }
      @if (isFileObject(object)) {
        <icz-readonly-field class="grow" label="Forma" [value]="object?.fileForm" [options]="fileFormOptions"></icz-readonly-field>
      }
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="Termín vyřízení" [value]="object?.resolutionDate ? (object?.resolutionDate | localizedDate) : null"></icz-readonly-field>
      @if (isDocumentObject(object)) {
        <icz-readonly-field class="grow" label="Stav" [value]="object?.documentState" [options]="documentStateOptions"></icz-readonly-field>
      }
      @if (isFileObject(object)) {
        <icz-readonly-field class="grow" label="Stav" [value]="object?.fileState" [options]="fileStateOptions"></icz-readonly-field>
      }
    </div>
    <div class="row">
      <icz-form-autocomplete
        [fieldDisabled]="true" [isMultiselect]="true"
        class="grow" label="Držitelé"
        [value]="object?.holderFunctionalPositionIds"
        [options]="functionalPositionsOptions$ | async"
      ></icz-form-autocomplete>
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="Zpracovatel"
        [value]="object?.ownerFunctionalPositionId"
        [options]="functionalPositionsOptions$ | async"
      ></icz-readonly-field>
    </div>
  </icz-section>

  <icz-section label="Základní informace" boxed [expanded]="false">
    <div class="row">
      <icz-readonly-field class="grow" label="Související evidence" [value]="object?.relatedEvidence"></icz-readonly-field>
    </div>
    <div class="row">
      <icz-readonly-field class="grow" label="Založeno" [value]="object?.auditInfo?.createdAt | localizedDate"></icz-readonly-field>
      <icz-readonly-field class="grow" label="Poslední úprava" [value]="object?.auditInfo?.modifiedAt | localizedDate"></icz-readonly-field>
    </div>
    @if (isDocumentObject(object)) {
      <icz-readonly-field label="Evidenční pomůcka" class="grow" [value]="object?.filingRegisterId" [options]="basicRegisterOptions"></icz-readonly-field>
      <icz-readonly-field label="Poslední datum evidence" class="grow" [value]="object?.filingRegisterRegDate | localizedDate"></icz-readonly-field>
    }
    @if (isFileObject(object)) {
      <icz-readonly-field label="Způsob vedení spisu" class="grow" [value]="object?.fileHandlingType" [options]="fileHandlingTypeOptions"></icz-readonly-field>
    }
  </icz-section>

  @if (isDocumentObject(object)) {
    <icz-section label="Komponenty" boxed [expanded]="false">
      @for (component of digitalComponents$ | async; track component.id) {
        <icz-essl-component-link [documentEsslComponent]="component" [isReadonly]="isObjectDeactivated"></icz-essl-component-link>
      }
      @for (component of paperComponents$ | async; track component.id) {
        <icz-essl-component-link [documentEsslComponent]="component" [isReadonly]="isObjectDeactivated"></icz-essl-component-link>
      }
      @for (component of mediumComponents$ | async; track component.id) {
        <icz-essl-component-link [documentEsslComponent]="component" [isReadonly]="isObjectDeactivated"></icz-essl-component-link>
      }
      @for (component of physicalItemComponents$ | async; track component.id) {
        <icz-essl-component-link [documentEsslComponent]="component" [isReadonly]="isObjectDeactivated"></icz-essl-component-link>
      }
    </icz-section>
  }

  <icz-section label="Reprezentující subjekt" boxed [expanded]="false">
    @if (representingSubject) {
      <icz-portrait [subject]="representingSubject"></icz-portrait>
    }
    @else {
      @if (isDocumentObject(object)) {
        <icz-label class="mt-32 mb-16 text-center" label="Dokument nemá reprezentující subjekt."></icz-label>
      }
      @if (isFileObject(object)) {
        <icz-label class="mt-32 mb-16 text-center" label="Spis nemá reprezentující subjekt."></icz-label>
      }
    }
  </icz-section>

  <icz-section label="Specifické" boxed>
    <icz-readonly-field class="grow" label="Bezpečnostní kategorie"
      [value]="object?.securityCategoryId"
      [options]="securityCategoryOptions$ | async"
    ></icz-readonly-field>
    <icz-readonly-field
      class="grow" label="Klíčová slova"
      [value]="object?.keywordIds"
      [options]="keywords$ | async"
    ></icz-readonly-field>
  </icz-section>

  <icz-section label="Třídění" boxed>
    <div class="row">
      @if (isDocumentObject(object)) {
        <icz-readonly-field class="grow" label="Typ dokumentu" [value]="object?.documentTypeId" [options]="documentTypeOptions"></icz-readonly-field>
      }
      @if (isFileObject(object)) {
        <icz-readonly-field class="grow" label="Typ spisu" [value]="object?.fileTypeId" [options]="fileTypeOptions"></icz-readonly-field>
      }
    </div>
    <icz-readonly-field [options]="entityClassOptions" [value]="object?.entityClassId" class="grow"
    label="Věcná skupina"></icz-readonly-field>
    <icz-disposal-tip [detailLevel]="DisposalTipDetailLevel.COMPLEX" [documentTypeId]="isDocumentObject(object) ? (object?.documentTypeId!) : null"
      [entityClassId]="object?.entityClassId!" [showHelpField]="false"
      class="grow">
    </icz-disposal-tip>
  </icz-section>

  @if (isDocumentObject(object)) {
    <icz-section label="Právní moc a zmocnění" boxed>
      <icz-date-picker [value]="object?.executionDate" label="Právní moc" class="grow" [fieldDisabled]="true"></icz-date-picker>
      <icz-empowerment [value]="object?.empowerment" [fieldDisabled]="true"></icz-empowerment>
    </icz-section>
  }
  @else {
    <icz-section label="Zmocnění" boxed>
      <icz-empowerment [value]="object?.empowerment" [fieldDisabled]="true"></icz-empowerment>
    </icz-section>
  }


  @if (isDocumentObject(object) && ($any(object).documentForm === DocumentForm.ANALOG || $any(object).documentForm === DocumentForm.HYBRID)) {
    <icz-section label="Fyzické uložení" boxed>
      @if (isDocumentObject(object)) {
        <icz-readonly-field [value]="object?.componentPhysicalLocation" label="Uložení" class="grow"></icz-readonly-field>
      }
    </icz-section>
  }
</div>
