/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EnvironmentPropertyUpdateDto } from '../../models/environment-property-update-dto';
import { PropertyValueDto } from '../../models/property-value-dto';

export interface PropertyValueOauthUpdateListForEnvironment$Params {

/**
 * Application name
 */
  applicationName: string;

/**
 * Organization id
 */
  organizationId: number;
      body: Array<EnvironmentPropertyUpdateDto>
}

export function propertyValueOauthUpdateListForEnvironment(http: HttpClient, rootUrl: string, params: PropertyValueOauthUpdateListForEnvironment$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PropertyValueDto>>> {
  const rb = new RequestBuilder(rootUrl, propertyValueOauthUpdateListForEnvironment.PATH, 'put');
  if (params) {
    rb.path('applicationName', params.applicationName, {});
    rb.path('organizationId', params.organizationId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<PropertyValueDto>>;
    })
  );
}

propertyValueOauthUpdateListForEnvironment.PATH = '/oauth/property-value/{applicationName}/{organizationId}/update-environment';