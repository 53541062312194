import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject, Subject} from 'rxjs';
import {map, share} from 'rxjs/operators';
import {FunctionalPositionDto, OrganizationalUnitDto, UserDto} from '|api/auth-server';
import {getUserFullName, Option} from '../../model';
import {CodebookService} from './codebook.service';
import {removeDuplicates} from '../../lib/utils';
import {Cacheable} from 'ts-cacheable';

export interface OrganizationalUnitOptionData {
  defaultFunctionalPositionId: number;
  isEmptyOrgEntity?: boolean;
}

export interface FunctionalPositionOptionData {
  isEmptyOrgEntity?: boolean;
}

type OrganizationalStructureOptionData = (
  OrganizationalUnitOptionData |
  FunctionalPositionOptionData
);

export type OrganizationalStructureOption = Option<Nullable<string | number>, OrganizationalStructureOptionData>;

export function formatFunctionalPositionLabelByHolder(fp: FunctionalPositionDto): string {
  return fp.code + ' - ' + fp.name + ' - ' + fp.holderName;
}

export function isEmptyFunctionalPosition(functionalPosition: FunctionalPositionDto, users: UserDto[], orgUnitsOptions: Option[]) {
  const hasUserSubstitution = users.find(u => u.substituteFunctionalPositions!.find(sfp => sfp.id === functionalPosition.id));
  const isEmptyFp = !functionalPosition.holderId && !Boolean(hasUserSubstitution);
  if (isEmptyFp && functionalPosition.representedOrganizationalUnit) {
    const parentOuId = functionalPosition.parentOrganizationalUnitId;
    const parentOuOption = orgUnitsOptions.find(ou => ou.value === parentOuId);
    if (parentOuOption?.data) (parentOuOption.data as OrganizationalUnitOptionData).isEmptyOrgEntity = true;
  }
  return isEmptyFp;
}

export function isEmptyOrganizationalUnit(ouId: number, functionalPositions: FunctionalPositionDto[]): boolean {
  const hasRepresenting = functionalPositions.find(fp => fp.parentOrganizationalUnitId === ouId && fp.representedOrganizationalUnit);
  return !Boolean(hasRepresenting);
}

export function getOrganizationalStructureRoot(orgStructure: OrganizationalStructure) {
  return orgStructure.organizationalUnits.find(ou => isNil(ou.parentId));
}

class OrganizationalStructure {

  constructor(
    public functionalPositions: FunctionalPositionDto[],
    public organizationalUnits: OrganizationalUnitDto[],
    public users: UserDto[],
  ) {}

  getFP = (id: number) => this.functionalPositions.find(fp => fp.id === id);
  getOU = (id: number) => this.organizationalUnits.find(ou => ou.id === id);
  getRepresentingUserByFp = (fp: FunctionalPositionDto) => this.users.find(u => u.username !== 'system' && u.id === fp.holderId);

  getNearestOU(fp: FunctionalPositionDto, processedFPids: number[] = []): Nullable<OrganizationalUnitDto> {
    if (!fp) return null;
    if (fp.parentOrganizationalUnitId) return this.getOU(fp.parentOrganizationalUnitId)!;
    if (processedFPids.includes(fp.id)) throw new Error('Cyclic dependency of Functional Positions');
    return this.getNearestOU(this.getFP(fp.parentFuncPosId!)!, [...processedFPids, fp.id]);
  }

  getParentsOU(ou: OrganizationalUnitDto): number[] {
    if (!ou) return [];
    if (!ou.parentId) return [ou.id];
    const parentsOfParent = this.getParentsOU(this.getOU(ou.parentId)!);
    if (parentsOfParent.includes(ou.id)) throw new Error('Cyclic dependency of Organizational Units');
    return [...parentsOfParent, ou.id];
  }

}

const clearOrgStructureCache$ = new Subject<void>();

const ORG_STRUCTURE_CACHING_POLICY = {
  maxAge: 60 * 60 * 1000, /* ms = 60min */
  cacheBusterObserver: clearOrgStructureCache$,
};

@Injectable({
  providedIn: 'root',
})
export class OrganizationalStructureService {

  private codebookService = inject(CodebookService);

  constructor() {
    this.organizationalStructure().pipe(
      map(os => os.functionalPositions),
    ).subscribe(fps => {
      this._functionalPositions = fps;
    });
  }

  private _functionalPositions: FunctionalPositionDto[] = [];

  // Attributes encapsulated in methods with caching decorators

  @Cacheable(ORG_STRUCTURE_CACHING_POLICY)
  organizationalStructure() {
    return combineLatest([
      this.codebookService.functionalPositions(),
      this.codebookService.organizationalUnits(),
      this.codebookService.users(),
    ]).pipe(
      map(([functionalPositions, organizationalUnits, users]) => {
        return new OrganizationalStructure(functionalPositions, organizationalUnits, users);
      }),
      share({
        connector: () => new ReplaySubject(1),
        resetOnError: false,
        resetOnComplete: false,
        resetOnRefCountZero: false,
      }),
    );
  }

  @Cacheable(ORG_STRUCTURE_CACHING_POLICY)
  functionalPositions() {
    return this.organizationalStructure().pipe(
      map(os => os.functionalPositions),
    );
  }

  @Cacheable(ORG_STRUCTURE_CACHING_POLICY)
  functionalPositionsOptions(): Observable<Option<number, FunctionalPositionDto>[]> {
    return this.organizationalStructure().pipe(
      map(os => os.functionalPositions.map(fp => ({
        value: fp.id,
        label: this.formatFunctionalPositionLabel(fp, os),
        data: fp,
      }))),
    );
  }

  @Cacheable(ORG_STRUCTURE_CACHING_POLICY)
  organizationalUnits() {
    return this.organizationalStructure().pipe(
      map(os => os.organizationalUnits),
    );
  }

  @Cacheable(ORG_STRUCTURE_CACHING_POLICY)
  organizationalUnitsOptions(): Observable<Option<number>[]> {
    return this.organizationalStructure().pipe(
      map(os => os.organizationalUnits.map(ou => ({
        value: ou.id,
        label: this.formatOrgUnitLabel(ou, os),
      }))),
    );
  }

  @Cacheable(ORG_STRUCTURE_CACHING_POLICY)
  orgStructureOptions(): Observable<OrganizationalStructureOption[]> {
    return this.organizationalStructure().pipe(
      map(organizationalStructure => {
        const ouOptions = organizationalStructure.organizationalUnits
          .map<Option<number, OrganizationalUnitOptionData>>(ou => ({
            value: ou.id,
            label: this.formatOrgUnitLabel(ou, organizationalStructure),
            parent: ou.parentId!,
            id: ou.id,
            originId: 'ou',
            data: {
              isEmptyOrgEntity: isEmptyOrganizationalUnit(ou.id, organizationalStructure.functionalPositions),
              defaultFunctionalPositionId: ou.defaultFunctionalPositionId,
            },
          }));

        const fpOptions = organizationalStructure.functionalPositions
          .map<Option<string, FunctionalPositionOptionData>>(fp => {
            return {
              value: fp.code,
              label: this.formatFunctionalPositionLabel(fp, organizationalStructure),
              parent: fp.parentOrganizationalUnitId as unknown as string,
              id: fp.id,
              originId: 'fp',
              data: {
                isEmptyOrgEntity: isEmptyFunctionalPosition(fp, organizationalStructure.users, ouOptions),
              },
            };
          });

        return ([...ouOptions, ...fpOptions]).map(o => {
          if (isNil(o.parent)) {
            o.autoExpand = true;
          }

          return o;
        });
      }),
    );
  }

  // Operations

  getParentsFPs(fp: FunctionalPositionDto | number): number[] {
    if (!fp || !this.functionalPositions.length) return [];
    let fpAsFunctionalPositionDto: FunctionalPositionDto;
    if (typeof fp === 'number') {
      fpAsFunctionalPositionDto = this._functionalPositions.find(ff => ff.id === fp)!;
    } else {
      fpAsFunctionalPositionDto = fp;
    }

    let parentIds: number[] = [];
    if (fpAsFunctionalPositionDto.parentFuncPosId) {
      parentIds.push(fpAsFunctionalPositionDto.parentFuncPosId);
    }
    let parent = this._functionalPositions.find(fPosition => fPosition.id === fpAsFunctionalPositionDto.parentFuncPosId)!;

    while (parent?.parentFuncPosId) {
      parentIds.push(parent.parentFuncPosId);
      parent = this._functionalPositions.find(fPosition => fPosition.id === parent.parentFuncPosId)!;
    }
    parentIds = removeDuplicates(parentIds);
    return parentIds;
  }

  getFunctionalPositionById(functionalPositionId: number): Observable<Nullable<FunctionalPositionDto>> {
    return this.organizationalStructure().pipe(
      map(os => os.getFP(functionalPositionId)),
    );
  }

  getOrganizationalUnitById(organizationalUnitId: number) {
    return this.organizationalStructure().pipe(
      map(os => os.getOU(organizationalUnitId)),
    );
  }

  getFunctionalPositionForOrganizationalUnit(
    organizationalUnitId: number,
    currentFunctionalPositionId: number,
  ) {
    if (isNil(organizationalUnitId)) return of(null);

    return this.organizationalStructure().pipe(
      map(os => {
        const ou = os.getOU(organizationalUnitId);
        if (!ou) return of(null);
        const fp = os.getFP(currentFunctionalPositionId)!;
        if (os.getParentsOU(os.getNearestOU(fp)!).includes(organizationalUnitId)) {
          return fp;
        }
        return os.getFP(ou.defaultFunctionalPositionId);
      }),
    );
  }

  getOrganizationalUnitForFunctionalPosition(
    functionalPositionId: Nullable<number>,
    currentOrganizationalUnitId?: number,
  ) {
    if (isNil(functionalPositionId)) return of(null);

    return this.organizationalStructure().pipe(
      map(os => {
        const nou = os.getNearestOU(os.getFP(functionalPositionId)!);
        const parents = os.getParentsOU(nou!);
        if (parents.includes(currentOrganizationalUnitId!)) {
          return os.getOU(currentOrganizationalUnitId!);
        }
        return nou;
      }),
    );
  }

  resetOrgStructureCaches() {
    clearOrgStructureCache$.next();
  }

  private formatFunctionalPositionLabel(fp: FunctionalPositionDto, os: OrganizationalStructure): string {
    const fpUser = os.getRepresentingUserByFp(fp);
    return fp.code + ' - ' + fp.name + (fpUser ? ` (${getUserFullName(fpUser)})` : '');
  }

  private formatOrgUnitLabel(ou: OrganizationalUnitDto, os: OrganizationalStructure): string {
    const ouFunctionalPosition = os.getFP(ou.defaultFunctionalPositionId);
    return ou.code + ' - ' + ou.name + (ouFunctionalPosition ? ` (${ouFunctionalPosition.name})` : '');
  }

}
