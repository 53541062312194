import {ChangeDetectionStrategy, Component, EventEmitter, inject, Output} from '@angular/core';
import {ChangelogService} from '../../essentials/changelog.service';


const GIT_REPOSITORY_URL = 'https://bitbucket.i.cz/projects/DMSESPISG2/repos/esslg2';

@Component({
  selector: 'icz-app-info-popup',
  templateUrl: './app-info-popup.component.html',
  styleUrls: ['./app-info-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppInfoPopupComponent {

  private changelogService = inject(ChangelogService);

  @Output() onClose = new EventEmitter<void>();

  repoURL = GIT_REPOSITORY_URL;
  changelog$ = this.changelogService.getChangelogItems();

}
