/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OwnDataBoxConsignmentConfigurationDto } from '../../models/own-data-box-consignment-configuration-dto';

export interface OwnConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Params {
      body: OwnDataBoxConsignmentConfigurationDto
}

export function ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration(http: HttpClient, rootUrl: string, params: OwnConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>> {
  const rb = new RequestBuilder(rootUrl, ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>;
    })
  );
}

ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration.PATH = '/sad/configuration/own-consignment/digital/databox';