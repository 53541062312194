/* tslint:disable */
/* eslint-disable */

/**
 * Task type
 */
export enum CirculationTaskType {
  HANDOVER_ACTIVITY_MANAGEMENT_BY_INITIATOR = 'HANDOVER_ACTIVITY_MANAGEMENT_BY_INITIATOR',
  TAKEOVER_REJECTED_BY_INITIATOR = 'TAKEOVER_REJECTED_BY_INITIATOR',
  TAKEOVER_BY_TARGET = 'TAKEOVER_BY_TARGET',
  TAKEOVER_DUPLICATE_BY_TARGET = 'TAKEOVER_DUPLICATE_BY_TARGET',
  TAKEOVER_ISSD = 'TAKEOVER_ISSD',
  HANDOVER_TO_OWN_HANDS_ACTIVITY_MANAGEMENT_BY_INITIATOR = 'HANDOVER_TO_OWN_HANDS_ACTIVITY_MANAGEMENT_BY_INITIATOR',
  HANDOVER_TO_OWN_HANDS_TAKEOVER_REJECTED_BY_INITIATOR = 'HANDOVER_TO_OWN_HANDS_TAKEOVER_REJECTED_BY_INITIATOR',
  HANDOVER_TO_OWN_HANDS_TAKEOVER_RETURNED_BY_INITIATOR = 'HANDOVER_TO_OWN_HANDS_TAKEOVER_RETURNED_BY_INITIATOR',
  HANDOVER_TO_OWN_HANDS_TAKEOVER_BY_TARGET = 'HANDOVER_TO_OWN_HANDS_TAKEOVER_BY_TARGET',
  DOCUMENT_APPROVAL_ACTIVITY_MANAGEMENT_BY_INITIATOR = 'DOCUMENT_APPROVAL_ACTIVITY_MANAGEMENT_BY_INITIATOR',
  DOCUMENT_APPROVAL_BY_TARGET = 'DOCUMENT_APPROVAL_BY_TARGET',
  COMPONENT_APPROVAL_ACTIVITY_MANAGEMENT_BY_INITIATOR = 'COMPONENT_APPROVAL_ACTIVITY_MANAGEMENT_BY_INITIATOR',
  COMPONENT_APPROVAL_BY_TARGET = 'COMPONENT_APPROVAL_BY_TARGET',
  STATEMENT_ACTIVITY_MANAGEMENT_BY_INITIATOR = 'STATEMENT_ACTIVITY_MANAGEMENT_BY_INITIATOR',
  STATEMENT_FROM_TARGET = 'STATEMENT_FROM_TARGET',
  ACKNOWLEDGEMENT_ACTIVITY_MANAGEMENT_BY_INITIATOR = 'ACKNOWLEDGEMENT_ACTIVITY_MANAGEMENT_BY_INITIATOR',
  ACKNOWLEDGEMENT_BY_TARGET = 'ACKNOWLEDGEMENT_BY_TARGET',
  COMPONENT_SIGNING_MANAGEMENT_BY_INITIATOR = 'COMPONENT_SIGNING_MANAGEMENT_BY_INITIATOR',
  SIGNING_BY_TARGET = 'SIGNING_BY_TARGET'
}
