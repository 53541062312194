import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import {
  CzechAddressDto,
  GenericLineAddressDto,
  HouseNumberType,
  PostalBoxAddressDto,
  SlovakAddressDto,
  SubjectRecordClassification
} from '|api/commons';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


export const TEST_CZECH_ADDRESS = {
  city: 'Praha 9',
  houseNumber: '4211',
  houseNumberType: HouseNumberType.STREET_NUMBER,
  postalCode: '19016',
  street: 'Stavbařů',
} as CzechAddressDto;

export const TEST_SLOVAK_ADDRESS = {
  city: 'Bratislava',
  houseNumber: '774',
  houseNumberType: HouseNumberType.STREET_NUMBER,
  postalCode: '81101',
  street: 'Štúrova',
} as SlovakAddressDto;

export const TEST_POBOX_ADDRESS = {
  box: 'P.O. BOX 33',
  postOffice: 'Praha 174',
} as PostalBoxAddressDto;

export const TEST_GENERIC_LINE_ADDRESS = {
  _Class: 'GenericLineAddressDto',
  country: 'USA',
  addressLines: [
    {
      line: '495 Grove Pkwy',
    },
    {
      line: 'Apt No. 20',
    },
    {
      line: 'Newark, NJ 10014',
    }
  ]
} as unknown as GenericLineAddressDto;

export const TEST_SALUTATION = 'Paní';
export const TEST_FIRST_NAME = 'Božena';
export const TEST_SURNAME = 'Novotná';
export const TEST_BUSINESS_NAME = 'OKNOPLAST, a. s.';


@Component({
  selector: 'icz-envelope-or-label-test-consignee-form',
  templateUrl: './envelope-or-label-test-consignee-form.component.html',
  styleUrls: ['./envelope-or-label-test-consignee-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvelopeOrLabelTestConsigneeFormComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  form!: IczFormGroup;

  consigneeSubjectClassificationOptions = enumToOptions('subjectRecordClassification', SubjectRecordClassification);

  get addressForm(): IczFormGroup {
    return this.form.get('address') as IczFormGroup;
  }

  get isTestConsigneeBusiness() {
    const consigneeClassification = this.form.get('consigneeSubjectClassification')!.value as unknown as SubjectRecordClassification;
    return (
      SubjectTemplateUtils.isLegalPerson(consigneeClassification) ||
      SubjectTemplateUtils.isBusinessIndividual(consigneeClassification)
    );
  }

  ngOnInit() {
    if (this.form) {
      this.form.get('consigneeSubjectClassification')!.valueChanges.pipe(
        startWith(null),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        if (this.isTestConsigneeBusiness) {
          this.form.get('consigneeSubjectName')!.addValidators([IczValidators.required()]);
        }
        else {
          this.form.get('consigneeFirstName')!.addValidators([IczValidators.required()]);
          this.form.get('consigneeSurname')!.addValidators([IczValidators.required()]);
        }

        this.form.recursivelyUpdateValueAndValidity();
      });
    }
  }

}
