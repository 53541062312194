import {inject, Injectable} from '@angular/core';
import {CodebookService} from '../codebook.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {CurrentSessionService} from '../../../services/current-session.service';

@Injectable({
  providedIn: 'root'
})
export class OrgStructureNotificationsService {

  private codebookService = inject(CodebookService);
  private currentSessionService = inject(CurrentSessionService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.IMPORT_SUCCESS,
      (_: WebSocketNotification) => {
        this.codebookService.resetTransientCodebookCaches();
        this.currentSessionService.resetOrgStructureCache();
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.IMPORT_ERROR,
      (_: WebSocketNotification) => {
        // no-op on purpose
      }
    );
  }

}
