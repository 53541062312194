import {CommonModule} from '@angular/common';
import {inject, NgModule} from '@angular/core';
import {LibraryImportModule} from './.module-library-import/library-import.module';
import {AutoFocusDirective} from './auto-focus.directive';
import {IczMatCalendarDirective} from './calendar/icz-mat-calendar.directive';
import {DebouncePipe} from './debounce.pipe';
import {FileSizePipe} from './file-size.pipe';
import {FindInArrayPipe} from './find-in-array.pipe';
import {FindInListPipe} from './find-in-list.pipe';
import {FormButtonsComponent} from './form-buttons/form-buttons.component';
import {FormatIdentifierPipe} from './format-identifier.pipe';
import {InterpolatePipe} from './interpolate.pipe';
import {IconComponent} from './icon/icon.component';
import {LabelComponent} from './label/label.component';
import {ThemeOutletComponent} from './theme-outlet/theme-outlet.component';
import {PopoverComponent} from './popover/popover.component';
import {StaticProgressIndicatorComponent} from './static-progress-indicator/static-progress-indicator.component';
import {TagComponent} from './tag/tag.component';
import {TabDirective} from './tabs/tab.directive';
import {AlertComponent} from './alert/alert.component';
import {AnnotatedLabelComponent} from './annotated-label/annotated-label.component';
import {CalendarRangeComponent} from './calendar-range/calendar-range.component';
import {ChipComponent} from './chip/chip.component';
import {EmpowermentPipe} from './empowerment.pipe';
import {CardComponent} from './card/card.component';
import {HeadingComponent} from './heading/heading.component';
import {StatusCardComponent} from './status-card/status-card.component';
import {LinksToDirective} from './links-to.directive';
import {UserInitialsComponent} from './user-initials/user-initials.component';
import {TranslateEnumValuesListPipe} from './translate-enum-values-list.pipe';
import {TabItemComponent} from './tabs/tab-item/tab-item.component';
import {RtfOutletComponent} from './rtf-outlet/rtf-outlet.component';
import {HtmlOutletComponent} from './html-outlet/html-outlet.component';
import {GetNodeInjectorDirective} from './get-node-injector.directive';
import {MoreItemsCounterComponent} from './more-items-circle/more-items-counter.component';
import {PassedDeadlineWarningDirective} from './passed-deadline-warning.directive';
import {LinkWithoutHrefDirective} from './link-without-href.directive';
import {ExpandableParagraphComponent} from './expandable-paragraph/expandable-paragraph.component';
import {LocalizedBooleanPipe} from './localized-boolean.pipe';
import {SectionExpanderComponent} from './section-expander/section-expander.component';
import {InterpolateBackendErrorPipe} from './interpolate-backend-error.pipe';
import {SpinnerComponent} from './spinner/spinner.component';
import {SectionComponent} from './section/section.component';
import {ResizableSidebarComponent} from './resizable-sidebar/resizable-sidebar.component';
import {TemplatePoolService} from './template-pool/template-pool.service';
import {TooltipDirective} from './tooltip.directive';
import {
  PlainComponentVersionViewerDialogComponent
} from './plain-component-version-viewer-dialog/plain-component-version-viewer-dialog.component';
import {ButtonCollectionComponent} from './button-collection/button-collection.component';
import {ButtonComponent} from './button/button.component';
import {LocalizedDatePipe} from './date.pipe';
import {InaccessibleDirective} from './inaccessible.directive';
import {TabsComponent} from './tabs/tabs.component';
import {
  SharedTemplateCollectionComponent
} from './template-pool/shared-template-collection/shared-template-collection.component';
import {ToggleButtonComponent} from './toggle-button/toggle-button.component';
import {CalendarComponent} from './calendar/calendar.component';
import {LocalizedDatetimePipe} from './datetime.pipe';
import {FileContentsViewerComponent} from './file-contents-viewer/file-contents-viewer.component';
import {LocalizedTimePipe} from './time.pipe';
import {NewspaperLoaderComponent} from './newspaper-loader/newspaper-loader.component';
import {MoneyPipe} from './money.pipe';
import {DisposalSchedulePipe} from './disposal-schedule.pipe';
import {MatRippleModule} from '@angular/material/core';
import {CopyToClipboardButtonComponent} from './copy-to-clipboard-button/copy-to-clipboard-button.component';
import {AttributeValuesPipe} from './attribute-values.pipe';

@NgModule({
  declarations: [
    ButtonComponent,
    IconComponent,
    LabelComponent,
    ThemeOutletComponent,
    PopoverComponent,
    SpinnerComponent,
    StaticProgressIndicatorComponent,
    TagComponent,
    ToggleButtonComponent,
    AutoFocusDirective,
    DebouncePipe,
    FindInArrayPipe,
    InaccessibleDirective,
    InterpolatePipe,
    TooltipDirective,
    TabDirective,
    TabsComponent,
    LocalizedDatePipe,
    LocalizedDatetimePipe,
    FormButtonsComponent,
    AlertComponent,
    AttributeValuesPipe,
    FindInListPipe,
    FileSizePipe,
    // layouting
    SectionComponent,
    ButtonCollectionComponent,
    PassedDeadlineWarningDirective,
    ChipComponent,
    CardComponent,
    LinksToDirective,
    UserInitialsComponent,
    HeadingComponent,
    StatusCardComponent,
    GetNodeInjectorDirective,
    ResizableSidebarComponent,
    MoreItemsCounterComponent,
    ExpandableParagraphComponent,
    TabItemComponent,
    LocalizedTimePipe,
    AnnotatedLabelComponent,
    CalendarRangeComponent,
    CalendarComponent,
    IczMatCalendarDirective,
    TranslateEnumValuesListPipe,
    InterpolateBackendErrorPipe,
    FormatIdentifierPipe,
    LocalizedBooleanPipe,
    MoneyPipe,
    DisposalSchedulePipe,
    HtmlOutletComponent,
    FileContentsViewerComponent,
    RtfOutletComponent,
    EmpowermentPipe,
    SectionExpanderComponent,
    LinkWithoutHrefDirective,
    PlainComponentVersionViewerDialogComponent,
    NewspaperLoaderComponent,
    SharedTemplateCollectionComponent,
    CopyToClipboardButtonComponent,
  ],
  imports: [CommonModule, LibraryImportModule, MatRippleModule],
  exports: [
    LibraryImportModule,
    ButtonComponent,
    IconComponent,
    LabelComponent,
    ThemeOutletComponent,
    PopoverComponent,
    SpinnerComponent,
    StaticProgressIndicatorComponent,
    TagComponent,
    ToggleButtonComponent,
    AutoFocusDirective,
    DebouncePipe,
    FindInArrayPipe,
    InaccessibleDirective,
    InterpolatePipe,
    TooltipDirective,
    TabDirective,
    TabsComponent,
    LocalizedDatePipe,
    AttributeValuesPipe,
    MoneyPipe,
    DisposalSchedulePipe,
    LocalizedDatetimePipe,
    FormButtonsComponent,
    AlertComponent,
    FindInListPipe,
    SectionComponent,
    ButtonCollectionComponent,
    PassedDeadlineWarningDirective,
    ChipComponent,
    CardComponent,
    LinksToDirective,
    UserInitialsComponent,
    HeadingComponent,
    StatusCardComponent,
    GetNodeInjectorDirective,
    ResizableSidebarComponent,
    FileSizePipe,
    MoreItemsCounterComponent,
    ExpandableParagraphComponent,
    LocalizedTimePipe,
    AnnotatedLabelComponent,
    CalendarRangeComponent,
    CalendarComponent,
    TranslateEnumValuesListPipe,
    InterpolateBackendErrorPipe,
    FormatIdentifierPipe,
    LocalizedBooleanPipe,
    HtmlOutletComponent,
    FileContentsViewerComponent,
    RtfOutletComponent,
    EmpowermentPipe,
    SectionExpanderComponent,
    LinkWithoutHrefDirective,
    PlainComponentVersionViewerDialogComponent,
    NewspaperLoaderComponent,
    CopyToClipboardButtonComponent,
  ],
  providers: [TemplatePoolService],
})
export class EssentialsModule {

  constructor() {
    inject(TemplatePoolService).initialize(SharedTemplateCollectionComponent);
  }

}
