import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';

@Component({
  selector: 'icz-table-expansion-panel',
  templateUrl: './table-expansion-panel.component.html',
  styleUrls: ['./table-expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableExpansionPanelComponent {
  @ViewChild('expansionPanel')
  expansionPanel!: MatExpansionPanel;

  @Input({required: true})
  menuLabel = '';
  @Input()
  menuIcon = '';
  @Input({required: true})
  isEmpty = true;
  @Input({required: true})
  isEmptyText = 'žádné položky';
  @Input({required: true})
  isMenuExpanded = false;
  @Output()
  menuExpanded = new EventEmitter<void>();
  @Output()
  menuCollapsed = new EventEmitter<void>();

  get isExpanded() {
    return this.expansionPanel?._getExpandedState() === 'expanded';
  }

  menuPanelClicked() {
    const expansionState = this.expansionPanel._getExpandedState();

    if (expansionState === 'expanded') {
      this.menuExpanded.emit();
    }
    else if (expansionState === 'collapsed') {
      this.menuCollapsed.emit();
    }
    // else nothing
  }
}
