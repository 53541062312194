/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { emailNotificationByUserOauthBulkActivate } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-bulk-activate';
import { EmailNotificationByUserOauthBulkActivate$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-bulk-activate';
import { emailNotificationByUserOauthBulkDeactivate } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-bulk-deactivate';
import { EmailNotificationByUserOauthBulkDeactivate$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-bulk-deactivate';
import { emailNotificationByUserOauthCreate } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-create';
import { EmailNotificationByUserOauthCreate$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-create';
import { emailNotificationByUserOauthDelete } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-delete';
import { EmailNotificationByUserOauthDelete$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-delete';
import { emailNotificationByUserOauthGetById } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-get-by-id';
import { EmailNotificationByUserOauthGetById$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-get-by-id';
import { emailNotificationByUserOauthSearch } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-search';
import { EmailNotificationByUserOauthSearch$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-search';
import { emailNotificationByUserOauthUpdate } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-update';
import { EmailNotificationByUserOauthUpdate$Params } from '../fn/email-notification-by-user-oauth/email-notification-by-user-oauth-update';
import { EmailNotificationForUserDto } from '../models/email-notification-for-user-dto';
import { PageEmailNotificationForUserDto } from '../models/page-email-notification-for-user-dto';


/**
 * Controller with operations over user notifications.
 */
@Injectable({ providedIn: 'root' })
export class ApiEmailNotificationByUserOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `emailNotificationByUserOauthGetById()` */
  static readonly EmailNotificationByUserOauthGetByIdPath = '/oauth/email-notification-by-user/{id}';

  /**
   * Returns email notification rule by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthGetById$Response(params: EmailNotificationByUserOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationForUserDto>> {
    return emailNotificationByUserOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns email notification rule by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthGetById(params: EmailNotificationByUserOauthGetById$Params, context?: HttpContext): Observable<EmailNotificationForUserDto> {
    return this.emailNotificationByUserOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNotificationForUserDto>): EmailNotificationForUserDto => r.body)
    );
  }

  /** Path part for operation `emailNotificationByUserOauthUpdate()` */
  static readonly EmailNotificationByUserOauthUpdatePath = '/oauth/email-notification-by-user/{id}';

  /**
   * Updates email notification rule settings for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthUpdate$Response(params: EmailNotificationByUserOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationForUserDto>> {
    return emailNotificationByUserOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates email notification rule settings for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthUpdate(params: EmailNotificationByUserOauthUpdate$Params, context?: HttpContext): Observable<EmailNotificationForUserDto> {
    return this.emailNotificationByUserOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNotificationForUserDto>): EmailNotificationForUserDto => r.body)
    );
  }

  /** Path part for operation `emailNotificationByUserOauthDelete()` */
  static readonly EmailNotificationByUserOauthDeletePath = '/oauth/email-notification-by-user/{id}';

  /**
   * Deletes email notification rule for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthDelete$Response(params: EmailNotificationByUserOauthDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return emailNotificationByUserOauthDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes email notification rule for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthDelete(params: EmailNotificationByUserOauthDelete$Params, context?: HttpContext): Observable<void> {
    return this.emailNotificationByUserOauthDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `emailNotificationByUserOauthCreate()` */
  static readonly EmailNotificationByUserOauthCreatePath = '/oauth/email-notification-by-user/create-for-user';

  /**
   * Creates email notification rule settings for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthCreate$Response(params: EmailNotificationByUserOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationForUserDto>> {
    return emailNotificationByUserOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates email notification rule settings for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthCreate(params: EmailNotificationByUserOauthCreate$Params, context?: HttpContext): Observable<EmailNotificationForUserDto> {
    return this.emailNotificationByUserOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNotificationForUserDto>): EmailNotificationForUserDto => r.body)
    );
  }

  /** Path part for operation `emailNotificationByUserOauthBulkDeactivate()` */
  static readonly EmailNotificationByUserOauthBulkDeactivatePath = '/oauth/email-notification-by-user/bulk-deactivate';

  /**
   * Bulk deactivates email notifications for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthBulkDeactivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthBulkDeactivate$Response(params: EmailNotificationByUserOauthBulkDeactivate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return emailNotificationByUserOauthBulkDeactivate(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk deactivates email notifications for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthBulkDeactivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthBulkDeactivate(params: EmailNotificationByUserOauthBulkDeactivate$Params, context?: HttpContext): Observable<void> {
    return this.emailNotificationByUserOauthBulkDeactivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `emailNotificationByUserOauthBulkActivate()` */
  static readonly EmailNotificationByUserOauthBulkActivatePath = '/oauth/email-notification-by-user/bulk-activate';

  /**
   * Bulk activates email notifications for user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthBulkActivate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthBulkActivate$Response(params: EmailNotificationByUserOauthBulkActivate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return emailNotificationByUserOauthBulkActivate(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk activates email notifications for user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthBulkActivate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationByUserOauthBulkActivate(params: EmailNotificationByUserOauthBulkActivate$Params, context?: HttpContext): Observable<void> {
    return this.emailNotificationByUserOauthBulkActivate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `emailNotificationByUserOauthSearch()` */
  static readonly EmailNotificationByUserOauthSearchPath = '/oauth/email-notification-by-user/search-api';

  /**
   * Finds email notification rules based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationByUserOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthSearch$Response(params?: EmailNotificationByUserOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEmailNotificationForUserDto>> {
    return emailNotificationByUserOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds email notification rules based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationByUserOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationByUserOauthSearch(params?: EmailNotificationByUserOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageEmailNotificationForUserDto> {
    return this.emailNotificationByUserOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEmailNotificationForUserDto>): PageEmailNotificationForUserDto => r.body)
    );
  }

}
