/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageAdministrationSubjectRecordDto } from '../models/page-administration-subject-record-dto';
import { PageSubjectRecordDto } from '../models/page-subject-record-dto';
import { subjectAdministrationComputeAllDuplicateGroups } from '../fn/subject-administration/subject-administration-compute-all-duplicate-groups';
import { SubjectAdministrationComputeAllDuplicateGroups$Params } from '../fn/subject-administration/subject-administration-compute-all-duplicate-groups';
import { subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod } from '../fn/subject-administration/subject-administration-dispose-all-subject-records-after-disposal-period';
import { SubjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod$Params } from '../fn/subject-administration/subject-administration-dispose-all-subject-records-after-disposal-period';
import { subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted } from '../fn/subject-administration/subject-administration-dispose-all-subject-records-with-disposal-period-started';
import { SubjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted$Params } from '../fn/subject-administration/subject-administration-dispose-all-subject-records-with-disposal-period-started';
import { subjectAdministrationDisposeSubject } from '../fn/subject-administration/subject-administration-dispose-subject';
import { SubjectAdministrationDisposeSubject$Params } from '../fn/subject-administration/subject-administration-dispose-subject';
import { subjectAdministrationGetByDuplicateGroupId } from '../fn/subject-administration/subject-administration-get-by-duplicate-group-id';
import { SubjectAdministrationGetByDuplicateGroupId$Params } from '../fn/subject-administration/subject-administration-get-by-duplicate-group-id';
import { subjectAdministrationMergeDuplicateSubjects } from '../fn/subject-administration/subject-administration-merge-duplicate-subjects';
import { SubjectAdministrationMergeDuplicateSubjects$Params } from '../fn/subject-administration/subject-administration-merge-duplicate-subjects';
import { subjectAdministrationRemoveFromDuplicateGroup } from '../fn/subject-administration/subject-administration-remove-from-duplicate-group';
import { SubjectAdministrationRemoveFromDuplicateGroup$Params } from '../fn/subject-administration/subject-administration-remove-from-duplicate-group';
import { subjectAdministrationSearchSubjectDuplicateGroups } from '../fn/subject-administration/subject-administration-search-subject-duplicate-groups';
import { SubjectAdministrationSearchSubjectDuplicateGroups$Params } from '../fn/subject-administration/subject-administration-search-subject-duplicate-groups';
import { subjectAdministrationSearchSubjectsToBeDisposed } from '../fn/subject-administration/subject-administration-search-subjects-to-be-disposed';
import { SubjectAdministrationSearchSubjectsToBeDisposed$Params } from '../fn/subject-administration/subject-administration-search-subjects-to-be-disposed';
import { SubjectRecordDto } from '|api/commons';


/**
 * Controller for administration of subjects.
 */
@Injectable({ providedIn: 'root' })
export class ApiSubjectAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `subjectAdministrationRemoveFromDuplicateGroup()` */
  static readonly SubjectAdministrationRemoveFromDuplicateGroupPath = '/subject-administration/set-as-unique-to-selected';

  /**
   * Sets subjects as unique to selected subjects.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationRemoveFromDuplicateGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectAdministrationRemoveFromDuplicateGroup$Response(params: SubjectAdministrationRemoveFromDuplicateGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectAdministrationRemoveFromDuplicateGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Sets subjects as unique to selected subjects.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationRemoveFromDuplicateGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectAdministrationRemoveFromDuplicateGroup(params: SubjectAdministrationRemoveFromDuplicateGroup$Params, context?: HttpContext): Observable<void> {
    return this.subjectAdministrationRemoveFromDuplicateGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationMergeDuplicateSubjects()` */
  static readonly SubjectAdministrationMergeDuplicateSubjectsPath = '/subject-administration/merge-duplicate-subjects';

  /**
   * Merge duplicate subjects to one subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationMergeDuplicateSubjects()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectAdministrationMergeDuplicateSubjects$Response(params: SubjectAdministrationMergeDuplicateSubjects$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectAdministrationMergeDuplicateSubjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Merge duplicate subjects to one subject
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationMergeDuplicateSubjects$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectAdministrationMergeDuplicateSubjects(params: SubjectAdministrationMergeDuplicateSubjects$Params, context?: HttpContext): Observable<void> {
    return this.subjectAdministrationMergeDuplicateSubjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationSearchSubjectsToBeDisposed()` */
  static readonly SubjectAdministrationSearchSubjectsToBeDisposedPath = '/subject-administration/elastic/search-to-be-disposed';

  /**
   * Find subjects to be disposed (without relations and with disposal period start) based on findDto.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationSearchSubjectsToBeDisposed()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectAdministrationSearchSubjectsToBeDisposed$Response(params: SubjectAdministrationSearchSubjectsToBeDisposed$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSubjectRecordDto>> {
    return subjectAdministrationSearchSubjectsToBeDisposed(this.http, this.rootUrl, params, context);
  }

  /**
   * Find subjects to be disposed (without relations and with disposal period start) based on findDto.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationSearchSubjectsToBeDisposed$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectAdministrationSearchSubjectsToBeDisposed(params: SubjectAdministrationSearchSubjectsToBeDisposed$Params, context?: HttpContext): Observable<PageSubjectRecordDto> {
    return this.subjectAdministrationSearchSubjectsToBeDisposed$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSubjectRecordDto>): PageSubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationComputeAllDuplicateGroups()` */
  static readonly SubjectAdministrationComputeAllDuplicateGroupsPath = '/subject-administration/compute-all-duplicate-groups';

  /**
   * Compute all duplicate subject groups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationComputeAllDuplicateGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationComputeAllDuplicateGroups$Response(params?: SubjectAdministrationComputeAllDuplicateGroups$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectAdministrationComputeAllDuplicateGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Compute all duplicate subject groups
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationComputeAllDuplicateGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationComputeAllDuplicateGroups(params?: SubjectAdministrationComputeAllDuplicateGroups$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.subjectAdministrationComputeAllDuplicateGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationSearchSubjectDuplicateGroups()` */
  static readonly SubjectAdministrationSearchSubjectDuplicateGroupsPath = '/subject-administration/elastic/search-duplicate-groups';

  /**
   * Find all subject duplicate groups based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationSearchSubjectDuplicateGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationSearchSubjectDuplicateGroups$Response(params?: SubjectAdministrationSearchSubjectDuplicateGroups$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageAdministrationSubjectRecordDto>> {
    return subjectAdministrationSearchSubjectDuplicateGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all subject duplicate groups based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationSearchSubjectDuplicateGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationSearchSubjectDuplicateGroups(params?: SubjectAdministrationSearchSubjectDuplicateGroups$Params|null|undefined, context?: HttpContext): Observable<PageAdministrationSubjectRecordDto> {
    return this.subjectAdministrationSearchSubjectDuplicateGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageAdministrationSubjectRecordDto>): PageAdministrationSubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationGetByDuplicateGroupId()` */
  static readonly SubjectAdministrationGetByDuplicateGroupIdPath = '/subject-administration/elastic/get-by-duplicate-group/{duplicateGroupId}';

  /**
   * Find all duplicate subjects in given group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationGetByDuplicateGroupId()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationGetByDuplicateGroupId$Response(params: SubjectAdministrationGetByDuplicateGroupId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectRecordDto>>> {
    return subjectAdministrationGetByDuplicateGroupId(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all duplicate subjects in given group
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationGetByDuplicateGroupId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationGetByDuplicateGroupId(params: SubjectAdministrationGetByDuplicateGroupId$Params, context?: HttpContext): Observable<Array<SubjectRecordDto>> {
    return this.subjectAdministrationGetByDuplicateGroupId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubjectRecordDto>>): Array<SubjectRecordDto> => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationDisposeSubject()` */
  static readonly SubjectAdministrationDisposeSubjectPath = '/subject-administration/{subjectId}';

  /**
   * Dispose (delete) subject without relations and with deletePeriod.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationDisposeSubject()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationDisposeSubject$Response(params: SubjectAdministrationDisposeSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectAdministrationDisposeSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Dispose (delete) subject without relations and with deletePeriod.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationDisposeSubject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationDisposeSubject(params: SubjectAdministrationDisposeSubject$Params, context?: HttpContext): Observable<void> {
    return this.subjectAdministrationDisposeSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted()` */
  static readonly SubjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStartedPath = '/subject-administration/dispose-all-with-period-started';

  /**
   * Dispose (delete) all subjects with disposedAt is null and disposePeriodStart is not null.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted$Response(params?: SubjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted(this.http, this.rootUrl, params, context);
  }

  /**
   * Dispose (delete) all subjects with disposedAt is null and disposePeriodStart is not null.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted(params?: SubjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.subjectAdministrationDisposeAllSubjectRecordsWithDisposalPeriodStarted$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod()` */
  static readonly SubjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriodPath = '/subject-administration/dispose-all-subject-records-after-disposal-period';

  /**
   * Dispose (delete) all subject records after disposal period.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod$Response(params?: SubjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod(this.http, this.rootUrl, params, context);
  }

  /**
   * Dispose (delete) all subject records after disposal period.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod(params?: SubjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.subjectAdministrationDisposeAllSubjectRecordsAfterDisposalPeriod$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
