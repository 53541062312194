import {inject, Injectable} from '@angular/core';
import {Observable, of, switchMap, tap} from 'rxjs';
import {ConsignmentType} from '|api/commons';
import {
  ApiOwnConsignmentService,
  OwnConsignmentHandoverDto,
  OwnConsignmentTakeoverDto,
  OwnDigitalConsignmentDto,
  OwnOfficeDeskConsignmentDto,
  OwnPaperConsignmentDto,
} from '|api/sad';
import {
  GenericOwnConsignment,
  GenericUpdateOwnConsignment,
  OwnConsignmentWorkflowActionOpts,
} from './model/own-consignment-model';
import {
  RejectDialogService,
  RejectDialogType,
} from '../dispatch-dialogs/components/reject-dialog/reject-dialog.service';
import {
  WithdrawForCorrectionDialogService,
} from '../dispatch-dialogs/components/withdraw-for-correction-dialog/withdraw-for-correction-dialog.service';
import {CounterTypeGroup, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {AbstractManualPostDialogService} from '../../../services/abstract-manual-post-dialog.service';
import {
  PrepareOrDispatchDialogService,
} from '../dispatch-dialogs/components/prepare-or-dispatch-dialog/prepare-or-dispatch-dialog.service';
import {PrepareOrDispatchDialogType} from '../dispatch-dialogs/dispatch-dialogs.model';
import {GlobalLoadingIndicatorService} from '../../essentials/global-loading-indicator.service';
import {OwnConsignmentWorkflowAction} from '../consignment-dialog/consignment-dialog/consignment.model';
import {DialogService} from '../../../core/services/dialog.service';

export function isOwnEmailConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnDigitalConsignmentDto>)?.consignmentType === ConsignmentType.OWN_DIGITAL_EMAIL;
}

function isOwnDataboxConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnDigitalConsignmentDto>)?.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX;
}

export function isOwnPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnPaperConsignmentDto>)?.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS;
}

export function isOwnPersonalConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnPaperConsignmentDto>)?.consignmentType === ConsignmentType.OWN_PAPER_ON_PLACE;
}

export function isOwnInternalPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnPaperConsignmentDto>)?.consignmentType === ConsignmentType.OWN_PAPER_INTERNAL;
}

export function isOwnInternalDigitalConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnPaperConsignmentDto>)?.consignmentType === ConsignmentType.OWN_DIGITAL_INTERNAL;
}

export function isOwnInternalConsignment(c: Nullable<GenericOwnConsignment>) {
  return isOwnInternalPaperConsignment(c) || isOwnInternalDigitalConsignment(c);
}

export function isOwnDigitalConsignment(c: Nullable<GenericOwnConsignment>) {
  return isOwnDataboxConsignment(c) || isOwnEmailConsignment(c);
}

export function isOwnOfficeDeskConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnOfficeDeskConsignmentDto>)?.consignmentType === ConsignmentType.OFFICE_DESK;
}

export function getDocumentIdFromConsignment(c: Nullable<GenericOwnConsignment>): number {
  if (isOwnOfficeDeskConsignment(c)) {
    return (c as OwnOfficeDeskConsignmentDto).documentId!;
  }
  else {
    return (c as OwnPaperConsignmentDto).ownDocumentId;
  }
}

export function openValidWithWarningHandoverConfirmationDialog(iczDialogService: DialogService) {
  return iczDialogService.openQuestionDialog(
    {
      title: 'Předat zásilku výpravně',
      question: `Opravdu chcete zásilku předat výpravně?`,
      description: 'Zásilku je možné vypravit ale obsahuje chyby.',
      leftButtonTitle: 'Předat',
    }
  );
}

@Injectable()
export class OwnConsignmentWorkflowService {

  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private prepareOrDispatchDialogService = inject(PrepareOrDispatchDialogService);
  private rejectDialogService = inject(RejectDialogService);
  private manualPostDialogService = inject(AbstractManualPostDialogService);
  private withdrawForCorrectionDialogService = inject(WithdrawForCorrectionDialogService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);

  private refreshMenuCountsOnWorkflowSuccess = () => tap(result => {
    if (result) {
      this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
    }
  });

  private getSaveObs$(selection: GenericOwnConsignment[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) {
    let saveObs$: Nullable<Observable<GenericOwnConsignment | GenericUpdateOwnConsignment>>;
    if (opts.saveFirst && updateReq$) {
      saveObs$ = updateReq$;
    } else {
      saveObs$ = of(selection[0]);
    }
    return saveObs$;
  }

  workflowAction: Record<OwnConsignmentWorkflowAction, (selection: any, opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => Observable<any>> = {

    [OwnConsignmentWorkflowAction.TAKEOVER]: (selection: (GenericOwnConsignment)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      const isBulk = selection.length > 1;
      if (!isBulk) {
        return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((updatedConsignment: GenericOwnConsignment | GenericUpdateOwnConsignment) => {
          return this.consignmentTakeover(updatedConsignment as OwnPaperConsignmentDto).pipe(this.refreshMenuCountsOnWorkflowSuccess());
        }));
      } else {
        const bulkDto: OwnConsignmentTakeoverDto[] = selection.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        });
        return this.apiOwnConsignmentService.ownConsignmentBulkTakeover({body: bulkDto});
      }
    },

    [OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION]: (selection: (OwnPaperConsignmentDto | OwnDigitalConsignmentDto)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((updatedConsignment: GenericOwnConsignment | GenericUpdateOwnConsignment) => {
        const isBulk = selection.length > 1;
        const updatedSelection: (GenericOwnConsignment)[] = isBulk ? selection : [updatedConsignment as GenericOwnConsignment];

        return this.prepareOrDispatchDialogService.openPrepareOrDispatchDialog(
            (updatedSelection[0] as OwnPaperConsignmentDto).ownDocumentId,
            updatedSelection as OwnPaperConsignmentDto[],
            PrepareOrDispatchDialogType.PREPARE_FOR_DISTRIBUTION,)
            .pipe(this.refreshMenuCountsOnWorkflowSuccess());
      }));
    },

    [OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION]: (selection: (OwnPaperConsignmentDto | OwnDigitalConsignmentDto)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      const isBulk = selection.length > 1;

      return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((updatedConsignment: GenericOwnConsignment | GenericUpdateOwnConsignment) => {
        if (isOwnPaperConsignment(selection[0]) || isOwnInternalPaperConsignment(selection[0])) {
          const updatedSelection: (GenericOwnConsignment)[] = isBulk ? selection : [updatedConsignment as GenericOwnConsignment];

          return this.prepareOrDispatchDialogService.openPrepareOrDispatchDialog(
            (updatedSelection[0] as OwnPaperConsignmentDto).ownDocumentId,
            updatedSelection as OwnPaperConsignmentDto[],
            PrepareOrDispatchDialogType.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION)
            .pipe(this.refreshMenuCountsOnWorkflowSuccess());
        } else {
          // digital "takeover" is actually takeover and distribution in 1 step - physically is just sending of email or databox message
          this.globalLoadingService.startLoading(); // start global loading, it will end on observable finalize

          if (isBulk) {
            return this.apiOwnConsignmentService.ownConsignmentBulkTakeover({
              body: selection.map(s => {
                return {consignmentType: s.consignmentType, id: s.id};
              }),
            });
          } else {
            return this.consignmentTakeover(selection[0]).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          }
        }
      }));
    },

    [OwnConsignmentWorkflowAction.DISPATCH]: (selection: (OwnPaperConsignmentDto | OwnDigitalConsignmentDto)[]) => {
      return this.prepareOrDispatchDialogService.openPrepareOrDispatchDialog(selection[0].ownDocumentId, selection as OwnPaperConsignmentDto[], PrepareOrDispatchDialogType.MARK_AS_DISPATCH).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE]: (selection: (OwnPaperConsignmentDto | OwnDigitalConsignmentDto)[]) => {
      return this.apiOwnConsignmentService.ownConsignmentMarkNonEpoAsDispatched({consignmentId: selection[0].id}).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.POST]: (selection: (OwnOfficeDeskConsignmentDto)[]) => {
      return this.manualPostDialogService.openManualPostDialog(selection[0].documentId!, selection[0]).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.UNPOST]: (selection: (OwnOfficeDeskConsignmentDto)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection as OwnOfficeDeskConsignmentDto[], RejectDialogType.UNPOST).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.REJECT]: (selection: (OwnPaperConsignmentDto | OwnOfficeDeskConsignmentDto)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection as OwnPaperConsignmentDto[], RejectDialogType.REJECT,).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.WITHDRAW_FROM_DISTRIBUTION]: (selection: (OwnPaperConsignmentDto | OwnDigitalConsignmentDto)[]) => {
      return this.rejectDialogService.openRejectDialog(selection[0].ownDocumentId, selection as OwnPaperConsignmentDto[], RejectDialogType.WITHDRAW_FROM_DISTRIBUTION).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.WITHDRAW_FOR_CORRECTION]: (selection: (OwnPaperConsignmentDto | OwnOfficeDeskConsignmentDto)[]) => {
      return this.withdrawForCorrectionDialogService.openWithdrawForCorrectionDialog(getDocumentIdFromConsignment(selection[0]), selection as OwnPaperConsignmentDto[]).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.CANCEL]: (selection: (GenericOwnConsignment)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection as OwnPaperConsignmentDto[], RejectDialogType.CANCEL).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.DELETE]: (selection: (GenericOwnConsignment)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection as OwnPaperConsignmentDto[], RejectDialogType.DELETE).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.HANDOVER]: (selection: (GenericOwnConsignment)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      const isBulk = selection.length > 1;

      if (!isBulk) {
        const id = selection[0].id;

        return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((consignment: GenericOwnConsignment | GenericUpdateOwnConsignment) => {
          if (isOwnPaperConsignment(consignment as GenericOwnConsignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverPaper({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else if (isOwnDigitalConsignment(consignment as GenericOwnConsignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverDigital({id, body: {}})
              .pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else if (isOwnInternalPaperConsignment(consignment as GenericOwnConsignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverInternalPaper({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else if (isOwnInternalDigitalConsignment(consignment as GenericOwnConsignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverInternalDigital({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else {
            return this.apiOwnConsignmentService.ownConsignmentHandoverOfficeDesk({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          }
        }));
      } else {
        const bulkDto: OwnConsignmentHandoverDto[] = selection.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        });
        return this.apiOwnConsignmentService.ownConsignmentBulkHandover({body: bulkDto});
      }
    },
  };

  consignmentTakeover(consignment: GenericOwnConsignment): Observable<any> {
    if (isOwnPaperConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverPaper({id: consignment.id});
    }
    else if (isOwnDigitalConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverAndDispatchDigital({id: consignment.id});
    }
    else if (isOwnOfficeDeskConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverOfficeDesk({id: consignment.id});
    }
    else if (isOwnInternalDigitalConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverAndDispatchInternalDigital({id: consignment.id});
    }
    else if (isOwnInternalPaperConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverInternalPaper({id: consignment.id});
    }
    else {
      throw new Error('Wrong type of own consignment for takeover!');
    }
  }

}
