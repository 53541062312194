import {ChangeDetectorRef, Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {cloneDeep} from 'lodash';
import {ApiKeywordService} from '|api/codebook';
import {StorageUnitDto} from '|api/document';
import {DocumentsTableDataType} from './documents-table.models';
import {TableConfig} from '../../table/table.models';
import {TableColumnsData} from '../../table/table-columns-data';
import {RowComparatorFn, RowSelectionDisableFn} from '../../table/selected-rows.service';
import {isAuthorizedOperationGranted} from '../permissions/permissions.utils';
import {TemplatePoolService} from '../../essentials/template-pool/template-pool.service';
import {DocumentFiltersDataService} from './components/document-filters/document-filters-data.service';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {IczTableDataSource} from '../../table/table.datasource';
import {
  DocumentView,
  REGISTRY_OFFICE_TRANSFER_VIEWS,
  RegistryOfficeTransferView,
  StorageUnitView
} from '../document-toolbar/services/toolbar-common.utils';
import {extendDefaultTableConfig, TableComponent} from '../../table/table.component';
import {FilterOperator, SearchParams, SortParam} from '../../../services/search-api.service';
import {
  documentColumnNames,
  DocumentsTableColumn,
  DocumentsTableView,
  getDocumentsColumnsData,
  getDocumentsColumnsForView,
} from './documents-table.columnsets';
import {getObjectTagColor, isDocumentObject, isFileObject} from '../shared-document.utils';
import {Option} from '../../../model';
import {SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {DisposalTipDetailLevel} from '../shared-business-components.model';
import {CirculationActivityDto} from '|api/flow';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {Router} from '@angular/router';
import {enumToArray, enumValuesToArray} from '../../../core/services/data-mapping.utils';
import {CodebookService} from '../../../core/services/codebook.service';


@Component({
  /* tableId is required attribute because it is bound to icz-table required attribut id */
  selector: 'icz-documents-table',
  templateUrl: './documents-table.component.html',
  styleUrls: ['./documents-table.component.scss'],
})
export class DocumentsTableComponent<T extends DocumentsTableDataType = DocumentsTableDataType> implements OnInit, IczOnChanges {

  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private templatePool = inject(TemplatePoolService);
  private apiKeywordService = inject(ApiKeywordService);
  private router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private codebookService = inject(CodebookService);

  @Input({required: true}) tableId!: string;

  @Input({required: true}) dataSource!: IczTableDataSource<T>;

  @Input({required: true})
  set viewType(viewType: DocumentsTableView) {
    if (viewType && this._viewType !== viewType) {
      const previousViewType = this._viewType;
      this._viewType = viewType;

      /**
       * Should not run on first change because it will init TableToolbarService
       * twice which results in glitches in global search when using search link.
       */
      if (previousViewType) {
        this.selectedRows = [];
        this.initializeTableConfig();
        this.initializeColumnMetadata();
      }
    }
  }
  get viewType(): DocumentsTableView {
    return this._viewType;
  }

  @Input() set searchTerm(newSearchTerm: string) {
    if (newSearchTerm && newSearchTerm !== this._searchTerm) {
      this._searchTerm = newSearchTerm;
      this.searchTermWords = newSearchTerm.split(' '); // performance optimization
    }
  }

  @Input() openQuickPreview = true;

  @Input() enabledFilters: Nullable<string[]>;
  @Input() preselectedObjects: Nullable<any[]>;

  @Input() isUnitView!: boolean;
  @Input() hideTableToolbar = false;
  @Input() hideTableToolbarButtons = false;

  @Output() searchTermChange = new EventEmitter<string>();
  @Output() initialized = new EventEmitter<TableComponent<DocumentsTableColumn>>();
  @Output() pageLoad = new EventEmitter<SearchParams>();
  @Output() selectedRowsChanged = new EventEmitter<T[]>();
  @Output() activeRowChanged = new EventEmitter<Nullable<T>>();
  @Output() searchParamsSelected = new EventEmitter<SearchParams>();

  @ViewChild('iczTable') iczTable!: TableComponent<DocumentsTableColumn>;

  config!: TableConfig<DocumentsTableColumn>;

  columnsData!: TableColumnsData<DocumentsTableColumn>;
  selectedRows: Array<T> = [];
  activeRow: Nullable<T> = null;
  isQuickPreviewOpen = false;
  isQuickConstrainedPreviewOpen = false;
  searchTermWords: string[] = [];

  gracefulElasticRowComparator: RowComparatorFn<DocumentsTableDataType> = (a, b) => {
    if (a.elasticId && b.elasticId) {
      return a.elasticId === b.elasticId;
    }
    else {
      // When not working with Elastic data sources, IDs are guaranteed to be
      // unique because they originate from database auto-incremented sequence.
      return a.id === b.id;
    }
  };

  currentEntityForRelevanceDetailView: Nullable<DocumentsTableDataType> = null;

  _searchTerm: string = '';

  _viewType!: DocumentsTableView;

  columnNames = documentColumnNames;

  readonly isDocumentObject = isDocumentObject;
  readonly isFileObject = isFileObject;

  readonly getObjectTagColor = getObjectTagColor;
  readonly isAuthorizedOperationGranted = isAuthorizedOperationGranted;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  readonly DocumentView = DocumentView;
  readonly StorageUnitView = StorageUnitView;
  readonly DocumentsTableColumn = DocumentsTableColumn;

  issdAppsOptions: Option[] = [];
  objectClassOptions: Option[] = this.documentFiltersDataService.objectClassOptions;

  get isRegistryOfficeTransferView() {
    return REGISTRY_OFFICE_TRANSFER_VIEWS.includes(this._viewType);
  }

  ngOnInit() {
    this.initializeTableConfig();
    this.initializeColumnMetadata();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.dataSource?.currentValue && changes.dataSource?.previousValue) {
      this.dataSource.reload();
    }
  }

  private initializeTableConfig() {
    let defaultSort: Nullable<SortParam<DocumentsTableColumn>>;
    let defaultFilterColumns: DocumentsTableColumn[] = [];
    let autoOpenFilter = false;
    let showReload = true;
    let showColumnSelector = true;
    let showFilter = true;
    let showTools = true;

    if (this._viewType === DocumentView.GLOBAL_SEARCH) {
      defaultSort = {
        fieldName: DocumentsTableColumn.RELEVANCE,
        descending: true,
      };
      autoOpenFilter = true;
      showFilter = false;
    }
    else if (this._viewType === DocumentView.EXPIRING_DOCUMENTS) {
      defaultSort = {
        fieldName: DocumentsTableColumn.RESOLUTION_DATE,
        descending: false,
      };
    }
    else if (this._viewType === DocumentView.OWNERSHIP_TRANSFER) {
      autoOpenFilter = true;
      showReload = false;
      showFilter = false;
      showColumnSelector = false;
    }
    else if (this._viewType === StorageUnitView.STORAGE_UNIT_INSERT_DETAIL) {
      autoOpenFilter = true;
      showReload = false;
      showFilter = false;
      showTools = false;
      showColumnSelector = false;
      defaultFilterColumns = [
        DocumentsTableColumn.IDENTIFIER,
        DocumentsTableColumn.STORAGE_UNIT_NUMBER,
        DocumentsTableColumn.STORAGE_UNIT_NAME,
      ];
    }
    else if (this._viewType === DocumentView.STORAGE_UNIT_INSERT) {
      autoOpenFilter = true;
      showReload = false;
      showFilter = false;
      showTools = false;
      showColumnSelector = false;
    }
    else if (this._viewType === StorageUnitView.STORAGE_UNITS_TABLE) {
      defaultFilterColumns = [
        DocumentsTableColumn.OBJECT_CLASS,
        DocumentsTableColumn.STORAGE_UNIT_CONTENT_BLOCKED,
        DocumentsTableColumn.STORAGE_UNIT_NAME,
        DocumentsTableColumn.CREATED_AT,
        DocumentsTableColumn.CONTENT_COUNT,
        DocumentsTableColumn.RETENTION_TRIGGER_TYPE_ID,
        DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR,
        DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
      ];
    }
    else if (this._viewType === DocumentView.FILING_OFFICE_RECEIVED) {
      autoOpenFilter = true;
      defaultFilterColumns = [
        DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
        DocumentsTableColumn.OBJECT_CLASS,
        DocumentsTableColumn.REF_NUMBER_ALL,
        DocumentsTableColumn.IDENTIFIER,
        DocumentsTableColumn.SUBJECT,
        DocumentsTableColumn.CREATED_AT,
        DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
        DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
      ];
    }
    else if (this._viewType === DocumentView.FILING_OFFICE_REJECTED) {
      autoOpenFilter = true;
      defaultFilterColumns = [DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
        DocumentsTableColumn.OBJECT_CLASS,
        DocumentsTableColumn.REF_NUMBER_ALL,
        DocumentsTableColumn.SUBJECT,
        DocumentsTableColumn.CREATED_AT,
        DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
        DocumentsTableColumn.HANDOVER_ACTIVITY_TARGET_FP_ID,
      ];
    }
    else if (this._viewType === DocumentView.FILING_OFFICE_HANDED_OVER) {
      autoOpenFilter = true;
      defaultFilterColumns = [DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
        DocumentsTableColumn.OBJECT_CLASS,
        DocumentsTableColumn.REF_NUMBER_ALL,
        DocumentsTableColumn.SUBJECT,
        DocumentsTableColumn.CREATED_AT,
        DocumentsTableColumn.CREATED_BY,
        DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
        DocumentsTableColumn.HANDOVER_ACTIVITY_TARGET_FP_ID,
      ];
    }
    else {
      defaultSort = {
        fieldName: DocumentsTableColumn.CREATED_AT,
        descending: true,
      };
      defaultFilterColumns = [
        DocumentsTableColumn.LABEL,
        DocumentsTableColumn.OBJECT_CLASS,
        DocumentsTableColumn.REPRESENTING_SUBJECT,
        DocumentsTableColumn.ENTITY_STATE,
      ];
    }

    this.config = extendDefaultTableConfig<DocumentsTableColumn>({
      hasActiveRow: this.openQuickPreview,
      defaultFilterColumns,
      toolbarConfig: {
        showFilter,
        showSavedFilters: true,
        autoOpenFilter,
        showReload,
        showColumnSelector,
        showFulltextSearch: (
          this._viewType !== DocumentView.OWNERSHIP_TRANSFER &&
          !enumValuesToArray(RegistryOfficeTransferView).includes(this._viewType)
        ),
        showTools,
      },
      defaultSort,
    });
  }

  private initializeColumnMetadata() {
    const registryOfficeViews = enumToArray(RegistryOfficeTransferView);
    if (registryOfficeViews.includes(this._viewType! as any)) {
      this.codebookService.registryOffices().subscribe(offices => {
        this.columnsData = getDocumentsColumnsData(
          this._viewType!,
          this.documentFiltersDataService,
          this.templatePool,
          this.isUnitView,
          getDocumentsColumnsForView(this._viewType!),
          offices.length,
        );
        this.cd.detectChanges();
      });
    } else {
      this.columnsData = getDocumentsColumnsData(
        this._viewType!,
        this.documentFiltersDataService,
        this.templatePool,
        this.isUnitView,
        getDocumentsColumnsForView(this._viewType!),
      );
      this.cd.detectChanges();
    }
  }

  loadPage(searchParams: SearchParams) {
    const searchParamsForRequest = cloneDeep(searchParams);
    const insertedDocumentsFilter = searchParams.filter.find(f => f.fieldName === DocumentsTableColumn.FILE_INSERTED_DOCUMENT);
    if (this.viewType === DocumentView.FILE_CONTENTS && insertedDocumentsFilter) {
      const filterItemIndex = searchParamsForRequest.filter.findIndex(f => f.fieldName === DocumentsTableColumn.FILE_INSERTED_DOCUMENT);
      if (insertedDocumentsFilter.value === 'true') {
        searchParamsForRequest.filter[filterItemIndex].value = null;
        searchParamsForRequest.filter[filterItemIndex].operator = FilterOperator.notEmpty;
      } else {
        searchParamsForRequest.filter.splice(filterItemIndex, 1);
      }
    }

    this.dataSource.loadPage(searchParamsForRequest);
    this.isQuickPreviewOpen = false;
    this.isQuickConstrainedPreviewOpen = false;
    this.pageLoad.emit(searchParams);
  }

  relevanceDetailsClick($event: Event, row: DocumentsTableDataType) {
    this.currentEntityForRelevanceDetailView = row;
    $event.stopPropagation();
  }

  setActiveRow(row: T) {
    this.hidePreviewSidebar();
    this.activeRowChanged.emit(row);

    if (row && this.openQuickPreview) {
      this.activeRow = row;
      this.isQuickPreviewOpen = Boolean(row);
    }
    else {
      this.hidePreviewSidebar();
    }
  }

  setSelectedRows(rows: Array<T>) {
    this.selectedRows = rows;
    this.selectedRowsChanged.emit(rows);
  }

  isTriggerEventCheckYearInvalid(year: Nullable<number>) {
    if (year) {
      return year <= (new Date()).getFullYear();
    } else {
      return false;
    }
  }

  rowDisabler: RowSelectionDisableFn<T> = document => {
    return (this.viewType === DocumentView.FILE_CONTENTS && !isNil((document as SearchRecordSourceDocumentFileDto).documentRemovedFromGivenFileAt));
  };

  unselectAndReloadRow() {
    this.dataSource.reload(true);
  }

  afterTableInitialized(tableInstance: TableComponent<DocumentsTableColumn>) {
    // need to wait for @ViewChild iczTable initialization
    setTimeout(() => this.initialized.emit(tableInstance), 0);
  }

  searchTermChanged(newSearchTerm: string) {
    this.searchTerm = newSearchTerm;
    this.searchTermChange.emit(newSearchTerm);
  }

  isOnlyConstrainedPreviewAvailable(row: T) {
    // todo(rb) profile feature is turned off for now because in the second iteration of permissions
    //  it got decoupled from permission system and instead will be in its own epic
    return false;
  }

  getActivityTargetParticipantIds(activity: CirculationActivityDto) {
    return activity.targetParticipants.map(tp => tp.targetParticipantId);
  }

  private hidePreviewSidebar() {
    this.isQuickConstrainedPreviewOpen = false;
    this.isQuickPreviewOpen = false;
  }

  getStorageUnitBlockedIcon(row: StorageUnitDto) {
    if (row.contentBlocked) {
      return 'icon_lock';
    } else {
      return 'icon_unlocked';
    }
  }

  openStorageUnitDetail(row: StorageUnitDto) {
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.STORAGE_UNIT,
      row.id,
    ));
  }

}
