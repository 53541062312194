/* tslint:disable */
/* eslint-disable */

/**
 * Rendition result.
 */
export enum TransactionLogRenditionResult {
  OK = 'OK',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  NOT_DONE = 'NOT_DONE'
}
