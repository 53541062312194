/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FpOwnershipTransferBulkTransferCsvValidationResponse } from '../models/fp-ownership-transfer-bulk-transfer-csv-validation-response';
import { fpOwnershipTransferFindApplicableFunctionalPositions } from '../fn/fp-ownership-transfer/fp-ownership-transfer-find-applicable-functional-positions';
import { FpOwnershipTransferFindApplicableFunctionalPositions$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-find-applicable-functional-positions';
import { fpOwnershipTransferFindJobs } from '../fn/fp-ownership-transfer/fp-ownership-transfer-find-jobs';
import { FpOwnershipTransferFindJobs$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-find-jobs';
import { fpOwnershipTransferGetJob } from '../fn/fp-ownership-transfer/fp-ownership-transfer-get-job';
import { FpOwnershipTransferGetJob$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-get-job';
import { FpOwnershipTransferJobDto } from '../models/fp-ownership-transfer-job-dto';
import { fpOwnershipTransferParseAndValidateBulkTransferCsv } from '../fn/fp-ownership-transfer/fp-ownership-transfer-parse-and-validate-bulk-transfer-csv';
import { FpOwnershipTransferParseAndValidateBulkTransferCsv$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-parse-and-validate-bulk-transfer-csv';
import { fpOwnershipTransferRescheduleTransferOwnership } from '../fn/fp-ownership-transfer/fp-ownership-transfer-reschedule-transfer-ownership';
import { FpOwnershipTransferRescheduleTransferOwnership$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-reschedule-transfer-ownership';
import { fpOwnershipTransferScheduleTransferOwnership } from '../fn/fp-ownership-transfer/fp-ownership-transfer-schedule-transfer-ownership';
import { FpOwnershipTransferScheduleTransferOwnership$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-schedule-transfer-ownership';
import { fpOwnershipTransferUnscheduleTransferOwnership } from '../fn/fp-ownership-transfer/fp-ownership-transfer-unschedule-transfer-ownership';
import { FpOwnershipTransferUnscheduleTransferOwnership$Params } from '../fn/fp-ownership-transfer/fp-ownership-transfer-unschedule-transfer-ownership';
import { PageFpOwnershipTransferApplicableFpInfo } from '../models/page-fp-ownership-transfer-applicable-fp-info';
import { PageFpOwnershipTransferJobDto } from '../models/page-fp-ownership-transfer-job-dto';


/**
 * Contains endpoints related to functional position ownership transfer.
 */
@Injectable({ providedIn: 'root' })
export class ApiFpOwnershipTransferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fpOwnershipTransferGetJob()` */
  static readonly FpOwnershipTransferGetJobPath = '/fp-ownership-transfer/job/{jobId}';

  /**
   * Job Info.
   *
   * Returns info about the job with the provided ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferGetJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferGetJob$Response(params: FpOwnershipTransferGetJob$Params, context?: HttpContext): Observable<StrictHttpResponse<FpOwnershipTransferJobDto>> {
    return fpOwnershipTransferGetJob(this.http, this.rootUrl, params, context);
  }

  /**
   * Job Info.
   *
   * Returns info about the job with the provided ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferGetJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferGetJob(params: FpOwnershipTransferGetJob$Params, context?: HttpContext): Observable<FpOwnershipTransferJobDto> {
    return this.fpOwnershipTransferGetJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<FpOwnershipTransferJobDto>): FpOwnershipTransferJobDto => r.body)
    );
  }

  /** Path part for operation `fpOwnershipTransferRescheduleTransferOwnership()` */
  static readonly FpOwnershipTransferRescheduleTransferOwnershipPath = '/fp-ownership-transfer/job/{jobId}';

  /**
   * Job Update.
   *
   * Re-schedules previously scheduled ownership transfer job for immediate or future execution.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferRescheduleTransferOwnership()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fpOwnershipTransferRescheduleTransferOwnership$Response(params: FpOwnershipTransferRescheduleTransferOwnership$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fpOwnershipTransferRescheduleTransferOwnership(this.http, this.rootUrl, params, context);
  }

  /**
   * Job Update.
   *
   * Re-schedules previously scheduled ownership transfer job for immediate or future execution.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferRescheduleTransferOwnership$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fpOwnershipTransferRescheduleTransferOwnership(params: FpOwnershipTransferRescheduleTransferOwnership$Params, context?: HttpContext): Observable<void> {
    return this.fpOwnershipTransferRescheduleTransferOwnership$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fpOwnershipTransferUnscheduleTransferOwnership()` */
  static readonly FpOwnershipTransferUnscheduleTransferOwnershipPath = '/fp-ownership-transfer/job/{jobId}';

  /**
   * Job Delete.
   *
   * Deletes a job with a given ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferUnscheduleTransferOwnership()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferUnscheduleTransferOwnership$Response(params: FpOwnershipTransferUnscheduleTransferOwnership$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fpOwnershipTransferUnscheduleTransferOwnership(this.http, this.rootUrl, params, context);
  }

  /**
   * Job Delete.
   *
   * Deletes a job with a given ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferUnscheduleTransferOwnership$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferUnscheduleTransferOwnership(params: FpOwnershipTransferUnscheduleTransferOwnership$Params, context?: HttpContext): Observable<void> {
    return this.fpOwnershipTransferUnscheduleTransferOwnership$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fpOwnershipTransferScheduleTransferOwnership()` */
  static readonly FpOwnershipTransferScheduleTransferOwnershipPath = '/fp-ownership-transfer/schedule-job';

  /**
   * Ownership Transfer Scheduling.
   *
   * Schedules requested ownership transfer job for immediate or future execution.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferScheduleTransferOwnership()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fpOwnershipTransferScheduleTransferOwnership$Response(params: FpOwnershipTransferScheduleTransferOwnership$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fpOwnershipTransferScheduleTransferOwnership(this.http, this.rootUrl, params, context);
  }

  /**
   * Ownership Transfer Scheduling.
   *
   * Schedules requested ownership transfer job for immediate or future execution.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferScheduleTransferOwnership$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fpOwnershipTransferScheduleTransferOwnership(params: FpOwnershipTransferScheduleTransferOwnership$Params, context?: HttpContext): Observable<void> {
    return this.fpOwnershipTransferScheduleTransferOwnership$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fpOwnershipTransferParseAndValidateBulkTransferCsv()` */
  static readonly FpOwnershipTransferParseAndValidateBulkTransferCsvPath = '/fp-ownership-transfer/parse-and-validate-bulk-transfer-csv';

  /**
   * Bulk Ownership Transfer CSV Validation.
   *
   * Parses and validates CSV data with definitions for bulk ownership transfer. Note that the validation process may also report a transient validation error meaning that if a bulk transfer request created based on this CSV data would get filed at this moment, the server would deny scheduling and return 404 error.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferParseAndValidateBulkTransferCsv()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  fpOwnershipTransferParseAndValidateBulkTransferCsv$Response(params?: FpOwnershipTransferParseAndValidateBulkTransferCsv$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<FpOwnershipTransferBulkTransferCsvValidationResponse>> {
    return fpOwnershipTransferParseAndValidateBulkTransferCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk Ownership Transfer CSV Validation.
   *
   * Parses and validates CSV data with definitions for bulk ownership transfer. Note that the validation process may also report a transient validation error meaning that if a bulk transfer request created based on this CSV data would get filed at this moment, the server would deny scheduling and return 404 error.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferParseAndValidateBulkTransferCsv$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  fpOwnershipTransferParseAndValidateBulkTransferCsv(params?: FpOwnershipTransferParseAndValidateBulkTransferCsv$Params|null|undefined, context?: HttpContext): Observable<FpOwnershipTransferBulkTransferCsvValidationResponse> {
    return this.fpOwnershipTransferParseAndValidateBulkTransferCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<FpOwnershipTransferBulkTransferCsvValidationResponse>): FpOwnershipTransferBulkTransferCsvValidationResponse => r.body)
    );
  }

  /** Path part for operation `fpOwnershipTransferFindJobs()` */
  static readonly FpOwnershipTransferFindJobsPath = '/fp-ownership-transfer/job-search-api';

  /**
   * Job Search.
   *
   * Returns info about all matching jobs that were previously created. Search request conforming to 'FPOwnershipTransferJobDto' expected.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferFindJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferFindJobs$Response(params?: FpOwnershipTransferFindJobs$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFpOwnershipTransferJobDto>> {
    return fpOwnershipTransferFindJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * Job Search.
   *
   * Returns info about all matching jobs that were previously created. Search request conforming to 'FPOwnershipTransferJobDto' expected.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferFindJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferFindJobs(params?: FpOwnershipTransferFindJobs$Params|null|undefined, context?: HttpContext): Observable<PageFpOwnershipTransferJobDto> {
    return this.fpOwnershipTransferFindJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFpOwnershipTransferJobDto>): PageFpOwnershipTransferJobDto => r.body)
    );
  }

  /** Path part for operation `fpOwnershipTransferFindApplicableFunctionalPositions()` */
  static readonly FpOwnershipTransferFindApplicableFunctionalPositionsPath = '/fp-ownership-transfer/applicable-fp-search-api';

  /**
   * Applicable Functional Position Search.
   *
   * Returns info about all matching functional positions that are applicable for ownership transfer. Search request conforming to 'FPOwnershipTransferFPSearchRequestAttributes' expected.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fpOwnershipTransferFindApplicableFunctionalPositions()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferFindApplicableFunctionalPositions$Response(params?: FpOwnershipTransferFindApplicableFunctionalPositions$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFpOwnershipTransferApplicableFpInfo>> {
    return fpOwnershipTransferFindApplicableFunctionalPositions(this.http, this.rootUrl, params, context);
  }

  /**
   * Applicable Functional Position Search.
   *
   * Returns info about all matching functional positions that are applicable for ownership transfer. Search request conforming to 'FPOwnershipTransferFPSearchRequestAttributes' expected.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fpOwnershipTransferFindApplicableFunctionalPositions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fpOwnershipTransferFindApplicableFunctionalPositions(params?: FpOwnershipTransferFindApplicableFunctionalPositions$Params|null|undefined, context?: HttpContext): Observable<PageFpOwnershipTransferApplicableFpInfo> {
    return this.fpOwnershipTransferFindApplicableFunctionalPositions$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFpOwnershipTransferApplicableFpInfo>): PageFpOwnershipTransferApplicableFpInfo => r.body)
    );
  }

}
