import {DestroyRef, inject, Pipe, PipeTransform} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CodebookService} from '../../core/services/codebook.service';
import {CmfItemDto} from '|api/codebook';
import {EsslMoneyDto} from '|api/commons';

/**
 * This pipe assumes that its input array is not mutated, thus is
 * suitable for example in tables where a value is looked up in list.
 */
@Pipe({
  name: 'money',
  pure: false,
})
export class MoneyPipe implements PipeTransform {

  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  private currencies: CmfItemDto[] = [];

  constructor() {
    this.codebookService.currencies().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(currencies => {
      this.currencies = currencies;
    });
  }

  transform(value: Nullable<EsslMoneyDto>): string {
   let result = '';
   if (!value || !value.number) result = '';
   else {
     result += value.number;
     if (this.currencies.length) {
       result += ` ${this.currencies.find(c => c.alphabeticCode === value.currency)!.symbol}`;
     }
   }

   return result;
  }

}
