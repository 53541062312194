import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {MessageType, SImplicitlyStringifiable, ToastService} from '../../../components/notifications/toast.service';
import {InternalNotificationKey} from '|api/notification';

const SFilingRegisterRenditionTemplateData = z.object({
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
});

export const SFilingRegisterRenditionScheduleScheduledTemplateData = SFilingRegisterRenditionTemplateData;
export const SFilingRegisterRenditionFinishedTemplateData = SFilingRegisterRenditionTemplateData;
export const SFilingRegisterRenditionScheduledTemplateData = SFilingRegisterRenditionTemplateData;
export const SFilingRegisterRenditionFinishedSuccessTemplateData = SFilingRegisterRenditionTemplateData;


export enum RenditionToastType {
  FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED = 'FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED',
  FILING_REGISTER_RENDITION_CREATED = 'FILING_REGISTER_RENDITION_CREATED',
  FILING_REGISTER_RENDITION_FINISHED = 'FILING_REGISTER_RENDITION_FINISHED',
  FILING_REGISTER_RENDITION_SCHEDULED = 'FILING_REGISTER_RENDITION_SCHEDULED',
  FILING_REGISTER_RENDITION_FINISHED_SUCCESS = 'FILING_REGISTER_RENDITION_FINISHED_SUCCESS'
}

@Injectable({
  providedIn: 'root'
})
export class RenditionToastService {

  private toastService = inject(ToastService);

  dispatchSimpleInfoToast(renditionToastType: RenditionToastType) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, renditionToastType);
  }

}
