/* tslint:disable */
/* eslint-disable */

/**
 * Type of payment
 */
export enum PaymentType {
  CASH = 'CASH',
  TRANSFER = 'TRANSFER',
  POST_BOX_CREDIT = 'POST_BOX_CREDIT',
  PAYMENT_MACHINE = 'PAYMENT_MACHINE'
}
