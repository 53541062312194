import {ConsignmentType, OwnConsignmentStatus} from '|api/commons';
import {GenericOwnConsignment} from '../model/own-consignment-model';
import {IczTableDataSource} from '../../../table/table.datasource';
import {UnitViewSearchConfig} from '../../../../services/search-api-with-elastic.service';
import {OwnConsignmentsSearchService} from '../../../../services/own-consignments-search.service';


export class OwnConsignmentsForOwnerOrUnitDatasource extends IczTableDataSource<GenericOwnConsignment> {
  constructor(
    searchService: OwnConsignmentsSearchService,
    unitView: UnitViewSearchConfig,
    states: Array<OwnConsignmentStatus>,
    consignmentTypes: Nullable<ConsignmentType[]>,
  ) {
    super(searchParams => searchService.findOwnConsignmentsForOwnerOrUnit(searchParams, unitView, states, consignmentTypes));
  }
}
