/* tslint:disable */
/* eslint-disable */

/**
 * Notification type
 */
export enum NotificationType {
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR'
}
