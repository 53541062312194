/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { labelTemplateBatchUpdate } from '../fn/label-template/label-template-batch-update';
import { LabelTemplateBatchUpdate$Params } from '../fn/label-template/label-template-batch-update';
import { labelTemplateCreate } from '../fn/label-template/label-template-create';
import { LabelTemplateCreate$Params } from '../fn/label-template/label-template-create';
import { labelTemplateDeletePermissionSet } from '../fn/label-template/label-template-delete-permission-set';
import { LabelTemplateDeletePermissionSet$Params } from '../fn/label-template/label-template-delete-permission-set';
import { LabelTemplateDto } from '../models/label-template-dto';
import { labelTemplateFindAll } from '../fn/label-template/label-template-find-all';
import { LabelTemplateFindAll$Params } from '../fn/label-template/label-template-find-all';
import { labelTemplateFindByType } from '../fn/label-template/label-template-find-by-type';
import { LabelTemplateFindByType$Params } from '../fn/label-template/label-template-find-by-type';


/**
 * API for management of print label templates.
 */
@Injectable({ providedIn: 'root' })
export class ApiLabelTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `labelTemplateFindAll()` */
  static readonly LabelTemplateFindAllPath = '/oauth/label-template';

  /**
   * Finds all label templates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelTemplateFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelTemplateFindAll$Response(params?: LabelTemplateFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<LabelTemplateDto>>> {
    return labelTemplateFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all label templates
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelTemplateFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelTemplateFindAll(params?: LabelTemplateFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<LabelTemplateDto>> {
    return this.labelTemplateFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LabelTemplateDto>>): Array<LabelTemplateDto> => r.body)
    );
  }

  /** Path part for operation `labelTemplateBatchUpdate()` */
  static readonly LabelTemplateBatchUpdatePath = '/oauth/label-template';

  /**
   * Batch update of label templates
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelTemplateBatchUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  labelTemplateBatchUpdate$Response(params: LabelTemplateBatchUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LabelTemplateDto>>> {
    return labelTemplateBatchUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Batch update of label templates
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelTemplateBatchUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  labelTemplateBatchUpdate(params: LabelTemplateBatchUpdate$Params, context?: HttpContext): Observable<Array<LabelTemplateDto>> {
    return this.labelTemplateBatchUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LabelTemplateDto>>): Array<LabelTemplateDto> => r.body)
    );
  }

  /** Path part for operation `labelTemplateCreate()` */
  static readonly LabelTemplateCreatePath = '/oauth/label-template';

  /**
   * Creates new label template with uploaded file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelTemplateCreate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  labelTemplateCreate$Response(params?: LabelTemplateCreate$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<LabelTemplateDto>> {
    return labelTemplateCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new label template with uploaded file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelTemplateCreate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  labelTemplateCreate(params?: LabelTemplateCreate$Params|null|undefined, context?: HttpContext): Observable<LabelTemplateDto> {
    return this.labelTemplateCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<LabelTemplateDto>): LabelTemplateDto => r.body)
    );
  }

  /** Path part for operation `labelTemplateFindByType()` */
  static readonly LabelTemplateFindByTypePath = '/oauth/label-template/find-by-type/{type}';

  /**
   * Finds default label template content by type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelTemplateFindByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelTemplateFindByType$Response(params: LabelTemplateFindByType$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return labelTemplateFindByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds default label template content by type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelTemplateFindByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelTemplateFindByType(params: LabelTemplateFindByType$Params, context?: HttpContext): Observable<string> {
    return this.labelTemplateFindByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `labelTemplateDeletePermissionSet()` */
  static readonly LabelTemplateDeletePermissionSetPath = '/oauth/label-template/{id}';

  /**
   * Delete label template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `labelTemplateDeletePermissionSet()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelTemplateDeletePermissionSet$Response(params: LabelTemplateDeletePermissionSet$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return labelTemplateDeletePermissionSet(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete label template
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `labelTemplateDeletePermissionSet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  labelTemplateDeletePermissionSet(params: LabelTemplateDeletePermissionSet$Params, context?: HttpContext): Observable<void> {
    return this.labelTemplateDeletePermissionSet$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
