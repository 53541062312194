import {Observable} from 'rxjs';
import {OwnOfficeDeskConsignmentDto} from '|api/sad';

export enum ManualPostDialogResultType {
  POSTED = 'POSTED',
}
export interface ManualPostDialogResult {
  resultType: ManualPostDialogResultType;
  consignment: OwnOfficeDeskConsignmentDto;
}

export abstract class AbstractManualPostDialogService {
  abstract openManualPostDialog(documentId: number, consignment: OwnOfficeDeskConsignmentDto): Observable<Nullable<ManualPostDialogResult>>;
}
