/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DisposalScheduleComputationRequestDto } from '../../models/disposal-schedule-computation-request-dto';
import { DisposalScheduleComputationResponseDto } from '../../models/disposal-schedule-computation-response-dto';

export interface DocumentGetValidDisposalSchedules$Params {
      body: DisposalScheduleComputationRequestDto
}

export function documentGetValidDisposalSchedules(http: HttpClient, rootUrl: string, params: DocumentGetValidDisposalSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<DisposalScheduleComputationResponseDto>> {
  const rb = new RequestBuilder(rootUrl, documentGetValidDisposalSchedules.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DisposalScheduleComputationResponseDto>;
    })
  );
}

documentGetValidDisposalSchedules.PATH = '/document/document/get-valid-disposal-schedules';