/* tslint:disable */
/* eslint-disable */

/**
 * Attributes to use to sort by the OwnPaperConsignments on the output exported/printed BulkPostingForm. Unsure about COD_AMOUNT - should be *Vyplatne* ?
 */
export enum BulkPostingFormSortAttribute {
  CONSIGNOR = 'CONSIGNOR',
  REF_NUMBER = 'REF_NUMBER',
  ORGANIZATIONAL_UNIT = 'ORGANIZATIONAL_UNIT',
  RECOMMENDED_CONSIGNMENT_NUMBER = 'RECOMMENDED_CONSIGNMENT_NUMBER',
  DISPATCH_OFFICE_RECEIVE_DATE = 'DISPATCH_OFFICE_RECEIVE_DATE',
  BASIC_DELIVERY_SERVICE = 'BASIC_DELIVERY_SERVICE',
  COD_AMOUNT = 'COD_AMOUNT',
  DELIVERY_SERVICE = 'DELIVERY_SERVICE'
}
