import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EssentialsModule} from '../essentials/essentials.module';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {TableModule} from '../table/table.module';
import {BarChartComponent} from './bar-chart/bar-chart.component';
import {DonutChartComponent} from './donut-chart/donut-chart.component';
import {LineChartComponent} from './line-chart/line-chart.component';
import {
  StatisticDashboardToggleComponent
} from './statistics-dashboard/statistic-dashboard-toggle/statistic-dashboard-toggle.component';
import {StatisticsDashboardComponent} from './statistics-dashboard/statistics-dashboard.component';

import {StatisticsTableComponent} from './statistics-table/statistics-table.component';
import {TrendCounterComponent} from './trend-counter/trend-counter.component';
import {
  StatisticTrendCounterComponent
} from './statistics-dashboard/statistic-trend-counter/statistic-trend-counter.component';

@NgModule({
  declarations: [
    StatisticsTableComponent,
    StatisticDashboardToggleComponent,
    TrendCounterComponent,
    StatisticsDashboardComponent,
    LineChartComponent,
    DonutChartComponent,
    BarChartComponent,
    StatisticTrendCounterComponent,
  ],
  exports: [
    StatisticsTableComponent,
    TrendCounterComponent,
    StatisticsDashboardComponent,
    StatisticDashboardToggleComponent,
    LineChartComponent,
    DonutChartComponent,
    BarChartComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    FormElementsModule,
    TableModule,
  ]
})
export class StatisticsModule { }
