import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {EntityType} from '|api/commons';
import {CrossReferenceWithDetailDto} from '|api/document';
import {ElasticDocumentOrFile} from '../../model/document-file-sidebar.model';
import {isDocumentObject, isFileObject} from '../../shared-document.utils';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {getDefaultSearchParams} from '../../../table/table.datasource';
import {addFilter, FilterOperator} from '../../../../services/search-api.service';
import {CrossReferenceSearchService} from '../../../../services/cross-reference-search.service';

@Component({
  selector: 'icz-document-file-sidebar-tab-related',
  templateUrl: './document-file-sidebar-tab-related.component.html',
  styleUrls: ['./document-file-sidebar-tab-related.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentFileSidebarTabRelatedComponent {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private cd = inject(ChangeDetectorRef);
  private apiCrossReferenceService = inject(CrossReferenceSearchService);

  @Input({required: true}) object!: ElasticDocumentOrFile;

  relatedEntities!: CrossReferenceWithDetailDto[];

  readonly isDocumentObject = isDocumentObject;
  readonly isFileObject = isFileObject;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.object?.currentValue) {
      const currentObject = changes.object.currentValue;

      this.loadingIndicatorService.startLoading(this);
      if (!currentObject?.id) {
        this.loadingIndicatorService.endLoading(this);
        this.cd.detectChanges();
        return;
      } else {
        const searchParams = getDefaultSearchParams();
        addFilter(searchParams, {
          fieldName: 'valid',
          operator: FilterOperator.equals,
          value: 'true',
        });
        const entityType = isDocumentObject(this.object) ? EntityType.DOCUMENT : EntityType.FILE;
        this.apiCrossReferenceService.findCrossReferenceEntitiesForRelatedView(currentObject?.id, entityType, searchParams).subscribe(references => {
          this.relatedEntities = references.content;
          this.loadingIndicatorService.endLoading(this);
          this.cd.detectChanges();
        });
      }
    }
  }

}
