import {ChangeDetectorRef, Component, DestroyRef, inject, Input} from '@angular/core';
import {TranslateParser} from '@ngx-translate/core';
import {PrimitiveControlValueType, PrimitiveValueFormField} from '../form-field';
import {TableColumnsData} from '../../table/table-columns-data';
import {isRequired} from '../validators/icz-validators/icz-validators';
import {
  PopupSelectorTableDialogComponent,
  PopupSelectorTableDialogData
} from '../../table/popup-selector-table-dialog/popup-selector-table-dialog.component';
import {InMemorySearchDatasource} from './in-memory-search.datasource';
import {defaultPageSize, IczTableDataSource} from '../../table/table.datasource';
import {Option} from '../../../model';
import {IczModalService} from '../../../services/icz-modal.service';
import {FilterOperator} from '../../../services/search-api.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


type TableSelectorOption = Option|Record<string, any>;

interface SelectedValue {
  modelValue: PrimitiveControlValueType;
  viewValue: string;
  completeValue: any;
}


@Component({
  /* tableId is required attribute because it is bound to icz-table required attribut id */
  selector: 'icz-form-popup-table-selector[tableId]',
  templateUrl: './form-popup-table-selector.component.html',
  styleUrls: ['./form-popup-table-selector.component.scss']
})
export class FormPopupTableSelectorComponent<TColumnId extends string> extends PrimitiveValueFormField {

  private modalService = inject(IczModalService);
  private translateParser = inject(TranslateParser);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @Input()
  readonly = false;
  @Input()
  clearable = true;
  @Input({required: true})
  popupTitle = '';
  @Input({required: true})
  tableTitle = '';
  @Input({required: true})
  tableId = '';
  @Input()
  tableTag = '';
  @Input()
  showFulltextSearch = false;
  @Input()
  defaultFilterColumns: TColumnId[] = [];
  @Input()
  set options(newValue: TableSelectorOption[]) {
    this._options = newValue;
    this.renderSelectedValueFromOptions();
  }
  get options(): TableSelectorOption[] {
    return this._options;
  }
  // If dataSource gets passed to the component along with options,
  // dataSource gets precedence as the source of the data for the table.
  @Input()
  set dataSource(newDataSource: IczTableDataSource<any>) {
    this.allValuesDataSource = newDataSource;
    this.renderSelectedValueFromDataSource();
  }
  // In datasource mode, there's a possilibity to pass a separate datasource used
  //  for inner table, effectively used as an analogy for Option#isHidden.
  @Input()
  selectableValuesDataSource: Nullable<IczTableDataSource<any>>;

  @Input({required: true})
  tableColumnsData = new TableColumnsData<TColumnId>([]);
  // These two properties control what gets selected from table rows
  // as view value (in the control box) and as model value (value of FormControl)
  // by default, it's preconfigured for Option[] passed to options.
  // Usually needs a reconfiguration in case we use a dataSourceFactory.
  // ViewValueTemplate can use simple interpolation using { ... } block
  // (single curly braces, not Mustache cause it collides with Angular interpolation).
  @Input()
  viewValueTemplate: string = '{label}';
  @Input()
  modelValueKey: string = 'value';

  // @Override
  @Input()
  override set value(newValue: PrimitiveControlValueType) {
    super.value = newValue;

    if (this.allValuesDataSource) {
      this.renderSelectedValueFromDataSource();
    }
    else if (this._options?.length) {
      this.renderSelectedValueFromOptions();
    }
  }

  // @Override must be overridden along with setter even tho
  // there is no reason to change getter body
  override get value(): PrimitiveControlValueType {
    return this._value as PrimitiveControlValueType;
  }

  get viewValue(): string {
    return isNil(this.selectedValue) ? '' : this.selectedValue!.viewValue;
  }

  get required(): boolean {
    return isRequired(this.control);
  }

  get isClearButtonVisible() {
    return (this.clearable && !this.required && !this.fieldDisabled && !this.readonly && this.value);
  }

  get dataSourceForInnerTable() {
    return this.selectableValuesDataSource ?? this.allValuesDataSource;
  }

  selectedValue: Nullable<SelectedValue> = null;

  private _options: TableSelectorOption[] = [];
  private allValuesDataSource!: IczTableDataSource<any>;

  openTable() {
    if (!this.readonly && !this.fieldDisabled) {
      if (!this.tableColumnsData?.columnDefinitions?.length) {
        console.warn('Table schema for icz-form-popup-table-selector is missing.');
      }

      this.modalService.openComponentInModal({
        component: PopupSelectorTableDialogComponent,
        modalOptions: {
          width: 1000,
          height: 600,
          titleTemplate: this.popupTitle || this.label,
          disableAutoMargin: true,
        },
        data: {
          tableId: this.tableId,
          tableTitle: this.tableTitle,
          tableTitleTag: this.tableTag,
          schema: this.tableColumnsData,
          tableConfig: {
            defaultFilterColumns: this.defaultFilterColumns as string[],
            toolbarConfig: {
              showFulltextSearch: this.showFulltextSearch,
            }
          },
          dataSource: (
            this.dataSourceForInnerTable ??
            new InMemorySearchDatasource(() => this._options.filter(o => !o.isHidden))
          ),
        } as PopupSelectorTableDialogData<TColumnId>,
      }).subscribe((resultValue: any) => {
        if (resultValue) {
          if (resultValue[this.modelValueKey] === undefined) {
            console.warn(`FormPopupTableSelectorComponent#modelValueKey
            might be set incorrectly because it produced value: ${resultValue[this.modelValueKey]}.`);
          }

          this.selectedValue = {
            viewValue: this.renderViewValueTemplateData(resultValue),
            modelValue: resultValue[this.modelValueKey],
            completeValue: resultValue,
          };
          this._valueChanged(resultValue[this.modelValueKey]);
        }

        this.blur.emit();
      });
    }
  }

  clearClicked($event: Event) {
    $event.stopPropagation();

    this.selectedValue = null;
    this._valueChanged(null);
  }

  _valueChanged(newValue: Nullable<PrimitiveControlValueType>) {
    this.blur.emit();

    // super.value and not this.value because it will bypass
    // SearchApi value lookup which is redundant at this point
    super.value = newValue;
    this.valueChange.emit(super.value);
  }

  private renderSelectedValueFromOptions() {
    if (this._value) {
      const selectedOption = this._options.find(o => o[this.modelValueKey as keyof TableSelectorOption] === this._value);

      if (selectedOption) {
        this.selectedValue = {
          modelValue: this._value,
          viewValue: this.renderViewValueTemplateData(selectedOption),
          completeValue: selectedOption,
        };
      }
      else {
        console.warn(`Value "${this._value}" does not correspond to any option of popup table selector.
            Please ensure that FormPopupTableSelectorComponent#modelValueKey is set
            properly to a unique option property.`);
      }
    }
  }

  private renderSelectedValueFromDataSource() {
    const value = String(this._value);

    if (this._value) {
      this.allValuesDataSource.items$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(valueItems => {
        if (valueItems.length === 1) {
          this.selectedValue = {
            modelValue: value,
            viewValue: this.renderViewValueTemplateData(valueItems[0]),
            completeValue: valueItems[0],
          };
          this.cd.detectChanges();
        }
        else {
          console.warn(`[formControlName=${this.formControlName}] Current value corresponds to ${valueItems.length} options from API. Please ensure that FormPopupTableSelectorComponent#modelValueKey is set properly to a valid option property.`);
        }
      });

      this.allValuesDataSource.loadPage({
        page: 0,
        size: defaultPageSize,
        filter: [{
          fieldName: this.modelValueKey,
          value,
          operator: FilterOperator.equals,
        }],
        sort: [],
      });
    }
    else {
      this.selectedValue = null;
    }
  }

  private renderViewValueTemplateData(data: any) {
    return this.translateParser.interpolate(
      this.viewValueTemplate
        .replaceAll('{', '{{')
        .replaceAll('}', '}}'),
      data
    ) ?? '';
  }

}
