@if (columnsData) {
  <icz-table
    [id]="tableId"
    [columnsData]="columnsData"
    [config]="tableConfig"
    [dataSource]="dataSource"
    (clicked)="rowClicked($event)"
    (pageLoad)="onPageLoad($event)"
    (selectedRowsChanged)="selectedRowsChanged.emit($event)"
    >
    <icz-label tableTitle [label]="label"></icz-label>
    <icz-label notFoundLabel noDataLabel [label]="noDataLabel"></icz-label>
    <ng-content tools select="[tools]"></ng-content>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: RegistryOfficeTransferIncidentsTableColumn.OBJECT_CLASS; withEllipsis: true">
      <icz-object-class-icon [objectClass]="row.objectClass"></icz-object-class-icon>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: RegistryOfficeTransferIncidentsTableColumn.REF_NUMBER; withEllipsis: true">
      <icz-ref-number-link
        [entity]="row"
        [view]="RegistryOfficeTransferIncidentView.OBJECT_INCIDENTS_LISTING"
      ></icz-ref-number-link>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: RegistryOfficeTransferIncidentsTableColumn.STATE">
      <div [iczTooltip]="transferIncidentStateEnumPrefix + row.state">
        @if (row.state === RegistryOfficeTransferIncidentState.IN_PROGRESS) {
          <icz-icon class="header-icon" size="small" svgIcon="crossed"></icz-icon>
        }
        @else {
          <icz-icon class="header-icon" size="small" svgIcon="checked"></icz-icon>
        }
      </div>
    </ng-container>
  </icz-table>
}
