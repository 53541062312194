import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PrimitiveValueFormField} from '../form-field';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {isNilOrEmptyString} from '../../../lib/utils';
import {TranslateService} from '@ngx-translate/core';
import {ApplicationLanguage} from '../../../core/services/environment.models';

/**
 * Implement number inputs using [type]="text" and validator IczValidators.isInteger/IczValidators.isDecimal!
 */
export type FormFieldType = 'text'|'integer'|'decimal'|'password';

@Component({
  selector: 'icz-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'icz-form-field'
  },
})
export class FormFieldComponent extends PrimitiveValueFormField<string | number> implements OnInit, IczOnChanges {

  private translateService = inject(TranslateService);

  @ViewChild('inputElement', {read: ElementRef, static: false})
  inputElement!: ElementRef;

  @ViewChild('autosize', {read: CdkTextareaAutosize, static: false})
  autosize!: CdkTextareaAutosize;

  @Input()
  override set value(newValue: Nullable<string | number>) {
    if (!isNilOrEmptyString(newValue) && this.type === 'integer') {
      this._value = String(newValue);
    }
    else if (!isNilOrEmptyString(newValue) && this.type === 'decimal') {
      const currentLanguage = this.translateService.currentLang as ApplicationLanguage;
      const sanitizedNewValue = (typeof newValue) === 'string' ? parseFloat(newValue as string) : newValue as number;
      const stringifiedNumber = (
        this.decimalPlaceCount && Number.isInteger(sanitizedNewValue * (10 ** this.decimalPlaceCount)) ?
          sanitizedNewValue.toFixed(this.decimalPlaceCount) :
          String(newValue)
      );

      if (currentLanguage === ApplicationLanguage.CZECH || currentLanguage === ApplicationLanguage.SLOVAK) {
        this._value = stringifiedNumber.replace('.', ',');
      }
      else {
        this._value = stringifiedNumber;
      }
    }
    else {
      this._value = newValue;
    }
  }
  override get value(): Nullable<string | number> {
    return this.convertInternalValueToOutputValue(this._value as string);
  }

  @Input() type: FormFieldType = 'text';
  @Input() decimalPlaceCount: Nullable<number>;
  @Input() autoSizeMin = 1;
  @Input() autoSizeMax = 1;
  @Input() iczInputMask = '';
  @Input() clearable: boolean = false;
  @Input() tabIndex!: number;
  @Input() showValidationStatus: boolean = true;
  @Input() switchPrefixAndPlaceholder: boolean = false;
  @Input() passwordSuggestions = false;
  @Input() isUsername = false;
  @Input() htmlName: Nullable<string> = null;
  @Input() hideInputElement = false;
  @Input() hasClickableInput = false;

  @Output()
  focus = new EventEmitter<void>();
  @Output()
  inputClick = new EventEmitter<Event>();

  originalType: Nullable<FormFieldType>;

  get shouldShowPrefix() {
    if (this.switchPrefixAndPlaceholder) {
      return (this.isFieldFocused || !isNilOrEmptyString(this.value));
    } else {
      return true;
    }
  }

  get shouldHidePlaceholder() {
    return this.switchPrefixAndPlaceholder && this.shouldShowPrefix;
  }

  get autocompleteValue() {
    if (this.type === 'password') {
      if (this.passwordSuggestions) {
        return 'on';
      } else {
        return 'new-password';
      }
    } else if (this.isUsername) {
      return 'username';
    } else {
      return 'off';
    }
  }

  get passwordRevealButtonIcon() {
    return this.type === 'password' ? 'eye' : 'eye_hidden';
  }

  get htmlType() {
    if (this.type === 'integer' || this.type === 'decimal') {
      return 'text';
    }
    else {
      return this.type;
    }
  }

  get isDecimalTypeWithFixedDecimals() {
    return this.type === 'decimal' && !isNil(this.decimalPlaceCount);
  }

  toggleRevealPassword() {
    this.type = (
      this.type === 'password' ?
        'text' :
        'password'
    );
  }

  isFieldFocused = false;
  showRightLabelPopup = false;

  ngOnInit(): void {
    setTimeout(() => {
      if (this.autosize) {
        this.autosize.resizeToFitContent(true);
      }
    }, 0);
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes?.type?.currentValue && !changes?.type.previousValue) {
      this.originalType = changes.type.currentValue;
      this._isPasswordField = this.originalType === 'password';
    }

    if (changes.type || changes.autoSizeMax) {
      if (this.autoSizeMax > 1 && this.type !== 'text') {
        throw new Error(`Only form fields of type=text support AutoSize larger than 1.`);
      }
    }
  }

  focusField() {
    this.inputElement.nativeElement.focus();
  }

  onFocus() {
    this.isFieldFocused = true;
    this.focus.emit();
  }

  onBlur($event: Event) {
    if (this.isDecimalTypeWithFixedDecimals) {
      this.emitOutputValue($event);
    }

    this.isFieldFocused = false;
    this.blur.emit();
  }

  onInputClick($event: Event) {
    if (this.hasClickableInput) {
      this.inputClick.emit($event);
    }
  }

  _valueChanged($event: Event) {
    if (!this.isDecimalTypeWithFixedDecimals) {
      this.emitOutputValue($event);
    }
  }

  clearClicked() {
    this._value = null;
    this.valueChange.emit('');
  }

  checkInputCharacter($event: KeyboardEvent) {
    if (
      $event.metaKey ||
      $event.ctrlKey ||
      $event.key === 'Backspace' ||
      $event.key === 'Delete' ||
      $event.key === 'Tab' ||
      $event.key === 'ArrowLeft' ||
      $event.key === 'ArrowRight' ||
      $event.key === 'Enter'
    ) return;

    if (this.type === 'integer') {
      const allowedCharacters = /[-0-9]/;

      if (!allowedCharacters.test($event.key)) {
        $event.preventDefault();
      }
    }
    else if (this.type === 'decimal') {
      const allowedCharacters = /[-0-9,.]/;

      if (!allowedCharacters.test($event.key)) {
        $event.preventDefault();
      }
    }
  }

  private convertInternalValueToOutputValue(internalValue: Nullable<string>): Nullable<string | number> {
    if (isNilOrEmptyString(internalValue)) {
      return null;
    }
    else {
      if (this.type === 'integer') {
        // eslint-disable-next-line eqeqeq -- coercive behavior is on point here - `string|number == string` where string|number might contain nonconversible values
        if (internalValue != `${parseInt(internalValue, 10)}`) {
          return null;
        }
        else {
          return parseInt(internalValue, 10);
        }
      }
      else if (this.type === 'decimal') {
        if (/^[+-]?(?=[,.]?\d)\d*([,.]\d{0,9})?$/.test(internalValue)) {
          return parseFloat(internalValue.replace(',', '.'));
        }
        else {
          return null;
        }
      }
      else {
        return internalValue;
      }
    }
  }

  private emitOutputValue($event: Event) {
    const fieldValue = ($event.target as HTMLInputElement).value;
    const outputValue = this.convertInternalValueToOutputValue(fieldValue);

    this.value = outputValue;
    this.valueChange.emit(outputValue);
  }

}
