import {inject, Injectable} from '@angular/core';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  RegistryOfficeToastType,
  SRegistryOfficeTransferErrorTemplateData,
  SRegistryOfficeTransferIncidentErrorTemplateData,
  SRegistryOfficeTransferIncidentTemplateData,
  SRegistryOfficeTransferTemplateData
} from './registry-office-toast.service';


@Injectable({
  providedIn: 'root'
})
export class RegistryOfficeNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_INCIDENT_RESOLVE_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferIncidentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_INCIDENT_RESOLVE_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferIncidentErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_CANCEL_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_TRANSFER_CANCEL_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_RETRANSFER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_TRANSFER_RETRANSFER_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_CANCEL_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_TRANSFER_CANCEL_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_RETRANSFER_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_TRANSFER_RETRANSFER_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_TRANSFER_RETURN_TO_OFFICE_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_CREATED_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_TRANSFER_CREATED_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_CREATED_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_TRANSFER_CREATED_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_TAKEN_OVER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_TRANSFER_TAKEN_OVER_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_TAKEN_OVER_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_TRANSFER_TAKEN_OVER_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_REJECT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          RegistryOfficeToastType.RO_TRANSFER_REJECTED_SUCCESS,
          parsedBody.parameters,
          SRegistryOfficeTransferTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_REJECT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          RegistryOfficeToastType.RO_TRANSFER_REJECTED_ERROR,
          parsedBody.parameters,
          SRegistryOfficeTransferErrorTemplateData,
        );
      }
    );
  }

}
