@if (isBulkPrinting) {
  <icz-wizard
    (stepChanged)="wizardStepChanged()"
    (canceled)="cancel()"
    #wizard
    >
    <ng-template
      iczWizardStep="printSettings"
      label="Nastavení tisku"
      submitLabel="Tisknout"
      [customSubmitHandler]="printSubmitHandler"
      >
      <ng-container *ngTemplateOutlet="mainPrintSettings"></ng-container>
    </ng-template>
    @for (consignment of data.consignments; track consignment.id) {
      <ng-template
        [iczWizardStep]="'printAdjustments_' + $index"
        [label]="getPrintTaskTabHeading($index)"
        [customSubmitHandler]="printSubmitHandler"
        submitLabel="Tisknout"
        >
        <div class="p-16" [waiting]="loadingService.isLoading$(this, SheetLabelPrintDialogLoader.OTHER) | async">
          <div class="row gap-32">
            <div class="col grow">
              @if (customFieldDefs[$index]?.length) {
                <icz-envelope-or-label-custom-fields-form
                  [form]="$any(customTextForms.get('' + $index))"
                  [customFields]="customFieldDefs[$index]"
                ></icz-envelope-or-label-custom-fields-form>
              }
            </div>
            <div class="col grow">
              <icz-section label="Náhled etikety">
                @if (selectedSheetLabelTemplate) {
                  <icz-envelope-or-label-preview-canvas
                    class="block mt-16"
                    [template]="selectedSheetLabelTemplate"
                    [previewImageDataUrl]="previewDataUrls[$index]"
                    [isPreviewLoading]="loadingService.isLoading$(this, SheetLabelPrintDialogLoader.PREVIEW) | async"
                  ></icz-envelope-or-label-preview-canvas>
                }
              </icz-section>
            </div>
          </div>
        </div>
      </ng-template>
    }
    <ng-container extraLeftButtons>
      @if (wizard.stepIndex < (wizard.steps?.length ?? 0) - 1) {
        <icz-button
          [disabled]="!form.valid"
          [label]="wizard.stepIndex === 0 ? 'Kontrola jednotlivých etiket' : 'Zkontrolovat další etiketu'"
          (onAction)="wizard.basicNextStep()"
        ></icz-button>
      }
    </ng-container>
  </icz-wizard>
}
@else {
  <form
    [formGroup]="form" [class.p-16]="isBulkPrinting" class="h-full overflow-hidden"
    [waiting]="loadingService.isLoading$(this, SheetLabelPrintDialogLoader.OTHER) | async"
    >
    <div class="row gap-32 h-full">
      <div class="col print-specification-col h-full overflow-y-auto">
        @if (!isTestingPrintMode) {
          <icz-section label="Vyberte podobu štítku">
            <icz-form-autocomplete
              formControlName="sheetLabelTemplateId"
              label="Šablona archu etiket"
              [options]="sheetLabelTemplateOptions"
            ></icz-form-autocomplete>
          </icz-section>
        }
        @if (isTestingPrintMode) {
          <icz-envelope-or-label-test-consignee-form
            [form]="form"
          ></icz-envelope-or-label-test-consignee-form>
        }
        @if (masterCustomFieldDefs?.length) {
          <icz-envelope-or-label-custom-fields-form
            [form]="masterCustomTextForm"
            [customFields]="masterCustomFieldDefs"
          ></icz-envelope-or-label-custom-fields-form>
        }
        <icz-section label="Tiskové nastavení">
          <div class="row grow">
            <icz-form-field
              class="grow" type="integer"
              formControlName="firstPrintPositionRow"
              label="Tisk od pozice - řádek"
            ></icz-form-field>
            <icz-form-field
              class="grow" type="integer"
              formControlName="firstPrintPositionColumn"
              label="Tisk od pozice - sloupec"
            ></icz-form-field>
          </div>
          <icz-form-field
            type="integer"
            formControlName="xPrintOffsetMm"
            label="Odsadit vodorovně"
            >
            <div suffix class="icz-body-1">mm</div>
          </icz-form-field>
          <icz-form-field
            type="integer"
            formControlName="yPrintOffsetMm"
            label="Odsadit svisle"
            >
            <div suffix class="icz-body-1">mm</div>
          </icz-form-field>
        </icz-section>
      </div>
      <div class="col grow h-full overflow-y-auto">
        <icz-section [label]="isBulkPrinting ? 'Rozměr archu etiket' : 'Rozměr archu a náhled etikety'">
          @if (selectedSheetLabelTemplate; as sheetLabelTemplate) {
            <div>
              <div class="icz-body-strong mt-16">
                {{ 'Rozměr archu' | translate }}: {{ sheetLabelTemplate.labelSheetWidthMm }}x{{ sheetLabelTemplate.labelSheetHeightMm }} mm@if (sheetPaperFormat) {
                ({{ sheetPaperFormat }})
                },
                {{ sheetLabelTemplate.rowCount }} {{ 'řádky' | translate }}, {{ sheetLabelTemplate.labelCountPerRow }} {{ 'etiket na řádku' | translate }}
              </div>
              <div class="icz-body-strong mt-16">
                {{ 'Rozměr etikety' | translate }}: {{ sheetLabelTemplate.labelWidthMm }}x{{ sheetLabelTemplate.labelHeightMm }} mm
              </div>
              <div class="icz-body-1 text-green">
                {{ 'Odsazení textu shora' | translate }}: {{ sheetLabelTemplate.textYOffsetMm }} mm
              </div>
              <div class="icz-body-1 text-green">
                {{ 'Odsazení textu zleva' | translate }}: {{ sheetLabelTemplate.textXOffsetMm }} mm
              </div>
            </div>
          }
          @if (!isBulkPrinting && selectedSheetLabelTemplate) {
            <icz-envelope-or-label-preview-canvas
              class="block mt-16"
              [template]="selectedSheetLabelTemplate"
              [previewImageDataUrl]="previewDataUrls[0]"
              [isPreviewLoading]="loadingService.isLoading$(this, SheetLabelPrintDialogLoader.PREVIEW) | async"
            ></icz-envelope-or-label-preview-canvas>
          }
        </icz-section>
      </div>
    </div>
  </form>
}

<ng-template #mainPrintSettings>
  <form
    [formGroup]="form" [class.p-16]="isBulkPrinting" class="h-full overflow-hidden"
    [waiting]="loadingService.isLoading$(this, SheetLabelPrintDialogLoader.OTHER) | async"
    >
    <div class="row gap-32 h-full">
      <div class="col print-specification-col h-full overflow-y-auto">
        @if (!isTestingPrintMode) {
          <icz-section label="Vyberte podobu štítku">
            <icz-form-autocomplete
              formControlName="sheetLabelTemplateId"
              label="Šablona archu etiket"
              [options]="sheetLabelTemplateOptions"
            ></icz-form-autocomplete>
          </icz-section>
        }
        @if (isTestingPrintMode) {
          <icz-envelope-or-label-test-consignee-form
            [form]="form"
          ></icz-envelope-or-label-test-consignee-form>
        }
        @if (masterCustomFieldDefs?.length) {
          <icz-envelope-or-label-custom-fields-form
            [form]="masterCustomTextForm"
            [customFields]="masterCustomFieldDefs"
          ></icz-envelope-or-label-custom-fields-form>
        }
        <icz-section label="Tiskové nastavení">
          <div class="row grow">
            <icz-form-field
              class="grow" type="integer"
              formControlName="firstPrintPositionRow"
              label="Tisk od pozice - řádek"
            ></icz-form-field>
            <icz-form-field
              class="grow" type="integer"
              formControlName="firstPrintPositionColumn"
              label="Tisk od pozice - sloupec"
            ></icz-form-field>
          </div>
          <icz-form-field
            type="integer"
            formControlName="xPrintOffsetMm"
            label="Odsadit vodorovně"
            >
            <div suffix class="icz-body-1">mm</div>
          </icz-form-field>
          <icz-form-field
            type="integer"
            formControlName="yPrintOffsetMm"
            label="Odsadit svisle"
            >
            <div suffix class="icz-body-1">mm</div>
          </icz-form-field>
        </icz-section>
      </div>
      <div class="col grow h-full overflow-y-auto">
        <icz-section [label]="isBulkPrinting ? 'Rozměr archu etiket' : 'Rozměr archu a náhled etikety'">
          @if (selectedSheetLabelTemplate; as sheetLabelTemplate) {
            <div>
              <div class="icz-body-strong mt-16">
                {{ 'Rozměr archu' | translate }}: {{ sheetLabelTemplate.labelSheetWidthMm }}x{{ sheetLabelTemplate.labelSheetHeightMm }} mm@if (sheetPaperFormat) {
                ({{ sheetPaperFormat }})
                },
                {{ sheetLabelTemplate.rowCount }} {{ 'řádky' | translate }}, {{ sheetLabelTemplate.labelCountPerRow }} {{ 'etiket na řádku' | translate }}
              </div>
              <div class="icz-body-strong mt-16">
                {{ 'Rozměr etikety' | translate }}: {{ sheetLabelTemplate.labelWidthMm }}x{{ sheetLabelTemplate.labelHeightMm }} mm
              </div>
              <div class="icz-body-1 text-green">
                {{ 'Odsazení textu shora' | translate }}: {{ sheetLabelTemplate.textYOffsetMm }} mm
              </div>
              <div class="icz-body-1 text-green">
                {{ 'Odsazení textu zleva' | translate }}: {{ sheetLabelTemplate.textXOffsetMm }} mm
              </div>
            </div>
          }
          @if (!isBulkPrinting && selectedSheetLabelTemplate) {
            <icz-envelope-or-label-preview-canvas
              class="block mt-16"
              [template]="selectedSheetLabelTemplate"
              [previewImageDataUrl]="previewDataUrls[0]"
              [isPreviewLoading]="loadingService.isLoading$(this, SheetLabelPrintDialogLoader.PREVIEW) | async"
            ></icz-envelope-or-label-preview-canvas>
          }
        </icz-section>
      </div>
    </div>
  </form>
</ng-template>

@if (!isBulkPrinting) {
  <icz-form-buttons>
    <icz-button leftButtons iczFormSubmit
      [disabled]="loadingService.isLoading(this)"
      (onAction)="submit()"
      primary label="Tisknout"
    ></icz-button>
    <icz-button rightButtons
      [disabled]="loadingService.isLoading(this)"
      (onAction)="cancel()" label="Storno"
    ></icz-button>
  </icz-form-buttons>
}
