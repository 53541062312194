/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { iszrFindAll } from '../fn/iszr/iszr-find-all';
import { IszrFindAll$Params } from '../fn/iszr/iszr-find-all';
import { iszrGetAgenda } from '../fn/iszr/iszr-get-agenda';
import { IszrGetAgenda$Params } from '../fn/iszr/iszr-get-agenda';
import { iszrGetAgendaRole } from '../fn/iszr/iszr-get-agenda-role';
import { IszrGetAgendaRole$Params } from '../fn/iszr/iszr-get-agenda-role';
import { IszrRppDto } from '|api/commons';


/**
 * Contains codebook endpoints related to ISZR agenda-role information.
 */
@Injectable({ providedIn: 'root' })
export class ApiIszrService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `iszrGetAgenda()` */
  static readonly IszrGetAgendaPath = '/codebook/iszr-rpp/{agendaCode}';

  /**
   * Finds ISZR RPP items by agenda code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrGetAgenda()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrGetAgenda$Response(params: IszrGetAgenda$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<IszrRppDto>>> {
    return iszrGetAgenda(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds ISZR RPP items by agenda code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrGetAgenda$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrGetAgenda(params: IszrGetAgenda$Params, context?: HttpContext): Observable<Array<IszrRppDto>> {
    return this.iszrGetAgenda$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IszrRppDto>>): Array<IszrRppDto> => r.body)
    );
  }

  /** Path part for operation `iszrGetAgendaRole()` */
  static readonly IszrGetAgendaRolePath = '/codebook/iszr-rpp/{agendaCode}/{roleCode}';

  /**
   * Finds ISZR RPP item by agenda/role code.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrGetAgendaRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrGetAgendaRole$Response(params: IszrGetAgendaRole$Params, context?: HttpContext): Observable<StrictHttpResponse<IszrRppDto>> {
    return iszrGetAgendaRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds ISZR RPP item by agenda/role code.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrGetAgendaRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrGetAgendaRole(params: IszrGetAgendaRole$Params, context?: HttpContext): Observable<IszrRppDto> {
    return this.iszrGetAgendaRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrRppDto>): IszrRppDto => r.body)
    );
  }

  /** Path part for operation `iszrFindAll()` */
  static readonly IszrFindAllPath = '/codebook/iszr-rpp/';

  /**
   * Finds all ISZR RPP items.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrFindAll$Response(params?: IszrFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IszrRppDto>>> {
    return iszrFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all ISZR RPP items.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrFindAll(params?: IszrFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<IszrRppDto>> {
    return this.iszrFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IszrRppDto>>): Array<IszrRppDto> => r.body)
    );
  }

}
