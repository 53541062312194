import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IdtLinkService} from './idt-link.service';
import {
  getCertListReq,
  getCertListRes,
  getOpenCommandReq,
  getPrintersRes,
  getVersionRes,
  Idt2MethodName,
  Idt2OpenCommandDefinition,
  Idt2OpenCommandInfo, openAnonymizationReq,
  openFileReq,
  printReq,
  signReqDataMessage,
  signRes
} from './idt.typedefs';

// Request data types
export type IdtGetCertListReqPayload = getCertListReq['data'];
export type IdtSignReqPayload = signReqDataMessage;
export type IdtPrintReqPayload = printReq['data'];
export type IdtOpenFileReqPayload = openFileReq['data'];
export type IdtOpenAnonymizationReqPayload = openAnonymizationReq['data'];
export type IdtGetOpenCommandReqPayload = getOpenCommandReq['data'];
export type IdtSetCustomOpenCommandReqPayload = Idt2OpenCommandDefinition;

// Response data types
export type IdtGetCertListResData = getCertListRes['data'];
export type IdtSignResData = signRes['data'];
export type IdtGetPrintersResData = getPrintersRes['data'];
export type IdtGetVersionResData = getVersionRes['data'];
export type IdtGetOpenCommandResData = Idt2OpenCommandInfo;
export type IdtSetCustomOpenCommandResData = Idt2OpenCommandInfo;

/**
 * IDT2 = ICZ Desktop Tools version 2.
 * Not only for signing docs.
 */
@Injectable({ providedIn: 'root' })
export class IdtService {

  private idtLinkService = inject(IdtLinkService);

  getCertList(payload: IdtGetCertListReqPayload): Observable<IdtGetCertListResData> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.getCertList,
      data: payload,
    }) as unknown as Observable<IdtGetCertListResData>;
  }

  sign(payload: IdtSignReqPayload): Observable<IdtSignResData> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.sign,
      data: payload,
    }) as unknown as Observable<IdtSignResData>;
  }

  getPrinters(): Observable<IdtGetPrintersResData> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.getPrinters,
    }) as unknown as Observable<IdtGetPrintersResData>;
  }

  print(payload: IdtPrintReqPayload): Observable<void> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.print,
      data: payload,
    }) as unknown as Observable<void>;
  }

  openFile(payload: IdtOpenFileReqPayload): Observable<void> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.openFile,
      data: payload,
    }) as unknown as Observable<void>;
  }

  openAnonymization(payload: IdtOpenAnonymizationReqPayload): Observable<void> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.openAnonymization,
      data: payload,
    }) as unknown as Observable<void>;
  }

  getOpenCommand(payload: IdtGetOpenCommandReqPayload): Observable<IdtGetOpenCommandResData> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.getOpenCommand,
      data: payload,
    }) as unknown as Observable<IdtGetOpenCommandResData>;
  }

  setCustomOpenCommand(payload: IdtSetCustomOpenCommandReqPayload): Observable<IdtSetCustomOpenCommandResData> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.setCustomOpenCommand,
      data: payload,
    }) as unknown as Observable<IdtSetCustomOpenCommandResData>;
  }

  getIdtVersion(): Observable<IdtGetVersionResData> {
    return this.idtLinkService.request({
      methodName: Idt2MethodName.getVersion,
    }) as unknown as Observable<IdtGetVersionResData>;
  }

}
