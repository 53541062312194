/* tslint:disable */
/* eslint-disable */

/**
 * Registry office transfer state
 */
export enum Essl9972RegistryOfficeTransferState {
  HANDED_OVER = 'HANDED_OVER',
  HANDED_OVER_TO_DESA = 'HANDED_OVER_TO_DESA',
  ACCEPTED = 'ACCEPTED'
}
