import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, forkJoin, Observable, of, startWith, Subscription, switchMap} from 'rxjs';
import {
  ApiEnvelopeTemplatesService,
  CustomTextEnvelopeSectionDto,
  EnvelopePrintRequestDto,
  EnvelopeSectionType,
  EnvelopeTemplateDto,
  EnvelopeTemplatePreviewRequestDto,
  OwnInternalPaperConsignmentDto,
  OwnPaperConsignmentDto,
} from '|api/sad';
import {SubjectRecordClassification, SubjectRecordDto} from '|api/commons';
import {AbstractTemplatePrintDialog, AbstractTemplatePrintDialogData} from '../abstract-template-print-dialog';
import {createAddressFormGroup} from '../subjects/address/address-utils';
import {WizardComponent} from '../../dialogs/wizard/wizard.component';
import {
  clearCustomTextForm,
  filterEmptyCustomTextValues,
  initializeCustomFieldsForm
} from '../envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {Option} from '../../../model';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {DialogService} from '../../../core/services/dialog.service';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {fileToBase64} from '../../../lib/utils';
import {formatAddressForDto} from '../../../services/subjects.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {castStream} from '../../../lib/rxjs';
import {ApiSubjectRecordService} from '|api/subject-register';
import {injectModalData} from '../../../lib/modals';
import {SubjectAttributeType} from '../model/subject-attribute-type';

export interface EnvelopePrintDialogData extends AbstractTemplatePrintDialogData {
  draftEnvelopeTemplate?: EnvelopeTemplateDto;
}

type EnvelopeTemplateOption = Option<number, EnvelopeTemplateDto>;

enum EnvelopePrintDialogLoader {
  PREVIEW = 'PREVIEW',
  OTHER = 'OTHER',
}

function getEnvelopePrintForm() {
  return new IczFormGroup({
    envelopeTemplateId: new IczFormControl<Nullable<number>>(null),
    consigneeSubjectClassification: new IczFormControl<Nullable<SubjectRecordClassification>>(SubjectRecordClassification.FO),
    salutation: new IczFormControl<Nullable<string>>(null),
    consigneeFirstName: new IczFormControl<Nullable<string>>(null),
    consigneeSurname: new IczFormControl<Nullable<string>>(null),
    consigneeDegreeBeforeName: new IczFormControl<Nullable<string>>(null),
    consigneeDegreeAfterName: new IczFormControl<Nullable<string>>(null),
    consigneeBirthDate: new IczFormControl<Nullable<string>>(null),
    consigneeSubjectName: new IczFormControl<Nullable<string>>(null),
    consigneeAdditionalName: new IczFormControl<Nullable<string>>(null),
    contactPerson: new IczFormControl<Nullable<string>>(null),
    addressType: new IczFormControl<Nullable<SubjectAttributeType>>(SubjectAttributeType.MAILING_ADDRESS),
    address: createAddressFormGroup(undefined, 3),
    customText: new IczFormGroup({}),
    xPrintOffsetMm: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(1)]),
    yPrintOffsetMm: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(1)]),
  });
}


@Component({
  selector: 'icz-envelope-print-dialog',
  templateUrl: './envelope-print-dialog.component.html',
  styleUrls: ['./envelope-print-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvelopePrintDialogComponent extends AbstractTemplatePrintDialog<EnvelopeTemplatePreviewRequestDto | EnvelopePrintRequestDto> implements OnInit {

  @ViewChild('wizard')
  wizard!: WizardComponent;

  form!: IczFormGroup;

  envelopeTemplateOptions: EnvelopeTemplateOption[] = [];

  previewSubscription: Nullable<Subscription>;

  printSubmitHandler = () => this.submit();

  readonly EnvelopePrintDialogLoader = EnvelopePrintDialogLoader;
  readonly PRINT_ADJUSTMENTS_STEP_PREFIX = 'printAdjustments_';

  private envelopeTemplateService = inject(ApiEnvelopeTemplatesService);
  private apiSubjectRecordNgService = inject(ApiSubjectRecordService);
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  protected override data = injectModalData<EnvelopePrintDialogData>();

  ngOnInit() {
    this.isTestingPrintMode = isNil(this.data.consignments);

    if (!this.isTestingPrintMode) {
      this.codebookService.envelopeTemplates().subscribe(envelopeTemplates => {
        this.envelopeTemplateOptions = envelopeTemplates.map(et => ({
          value: et.id,
          label: et.name,
          data: et,
        }));
      });

      if (this.isBulkPrinting) {
        const consignmentsCount = this.data.consignments!.length;

        this.form = new IczFormGroup({
          masterPrintSettings: getEnvelopePrintForm(),
          printTasks: new IczFormArray(getEnvelopePrintForm, []),
        });

        const masterPrintForm = this.form.get('masterPrintSettings') as IczFormGroup;
        const printTaskForms = this.form.get('printTasks') as IczFormArray;

        this.initSyncMasterToSlaveTaskValues(masterPrintForm, printTaskForms, 'salutation');
        this.initSyncMasterToSlaveTaskValues(masterPrintForm, printTaskForms, 'consigneeAdditionalName');
        this.initSyncMasterToSlaveTaskValues(masterPrintForm, printTaskForms, 'contactPerson');
        masterPrintForm.get('envelopeTemplateId')!.valueChanges.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(envelopeTemplateId => {
          setTimeout(() => {
            if (envelopeTemplateId) {
              for (let i = 0; i < this.data.consignments!.length; ++i) {
                printTaskForms.get(String(i))!.get('envelopeTemplateId')!.setValue(envelopeTemplateId, {emitEvent: false});
              }

              this.setUpCustomFieldDefs();
            }
            else {
              clearCustomTextForm(masterPrintForm.get('customText') as IczFormGroup);
              this.masterCustomFieldDefs = [];
            }

            this.cd.detectChanges();
          }, 0);
        });
        masterPrintForm.get('customText')!.valueChanges.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(masterCustomTextValues => {
          for (let i = 0; i < this.data.consignments!.length; ++i) {
            const printTaskCustomTextForm = printTaskForms.get(String(i))!.get('customText') as IczFormGroup;

            initializeCustomFieldsForm(this.masterCustomFieldDefs, printTaskCustomTextForm);
            printTaskCustomTextForm.patchValue(masterCustomTextValues);
          }
        });

        printTaskForms.setSize(consignmentsCount);

        for (let i = 0; i < consignmentsCount; ++i) {
          this.initializePrintTaskForm(printTaskForms.get(String(i)) as IczFormGroup, this.data.consignments!, i);
        }
      }
      else {
        this.form = getEnvelopePrintForm();
        this.initializePrintTaskForm(this.form, this.data.consignments!, 0);
      }
    }
    else {
      this.form = getEnvelopePrintForm();

      this.patchFormWithTestData();

      this.form.get('consigneeSubjectClassification')!.setValidators(IczValidators.required());
      this.form.get('addressType')!.setValidators(IczValidators.required());

      this.setUpCustomFieldDefs();

      this.form.valueChanges.pipe(
        startWith(null),
        debounceTime(1000),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        if (this.form.valid) {
          this.requestVisualPreview(0);
        }
      });
    }

    this.form.recursivelyUpdateValueAndValidity();

    this.form.valueChanges.pipe(
      startWith(null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      if (this.wizard?.tabs) {
        this.wizard.tabs = this.wizard.tabs.map((t, i) => {
          if ((t.id as string).startsWith(this.PRINT_ADJUSTMENTS_STEP_PREFIX)) {
            return {
              ...t,
              valid: this.form.get('printTasks')!.get(String(i - 1))!.valid, // i-1 because the first tab is master print settings
              showTabValidity: true,
            };
          }
          else {
            return t;
          }
        });
      }
    });
  }

  submit() {
    let request$: Observable<void>;

    if (this.isTestingPrintMode) {
      request$ = this.remoteBinaryFileDownloadService.fetchEnvelopeTestPrintPreview(
        'PDF',
        this.getPreviewRequestDto(false, -1) as EnvelopeTemplatePreviewRequestDto,
      ).pipe(
        switchMap(pdfBuffer => {
          const file = new File([pdfBuffer], `${this.getSelectedEnvelopeTemplate(-1)!.name}.pdf`);

          this.localBinaryFileDownloadService.downloadFile(file);

          return of(null);
        }),
        castStream<void>(),
      );
    }
    else {
      if (!this.form.valid) {
        this.dialogService.showError('Některé obálky nemají správně vyplněné nastavení tisku. Opravte nastavení tisku pro obálky, které jsou označené symbolem vykřičníku.');
        return;
      }

      const printRequests$: Observable<ArrayBuffer>[] = [];

      for (let i = 0; i < this.data.consignments!.length; ++i) {
        printRequests$.push(
          this.remoteBinaryFileDownloadService.fetchEnvelopePrintPreview(
            'PDF',
            this.getPreviewRequestDto(false, i) as EnvelopePrintRequestDto,
          )
        );
      }

      request$ = forkJoin(printRequests$).pipe(
        switchMap(pdfBuffers => {
          const pdfFiles = pdfBuffers.map((b, i) => new File([b], `${this.getSelectedEnvelopeTemplate(i)!.name}.pdf`));

          for (const file of pdfFiles) {
            this.localBinaryFileDownloadService.downloadFile(file);
          }

          return of(null);
        }),
        castStream<void>(),
      );
    }

    this.loadingService.doLoading(
      request$,
      this,
      EnvelopePrintDialogLoader.OTHER
    ).subscribe();
  }

  cancel() {
    this.modalRef.close(null);
  }

  wizardStepChanged() {
    const currentStepIndex = this.wizard.stepIndex;

    if (currentStepIndex > 0) {
      this.requestVisualPreview(currentStepIndex - 1);
    }
  }

  isConsigneeBusiness(printTaskIndex: number) {
    let printTaskForm: IczFormGroup;

    if (this.isBulkPrinting) {
      if (printTaskIndex === -1) {
        printTaskForm = this.form.get('masterPrintSettings') as IczFormGroup;
      }
      else {
        printTaskForm = this.form.get('printTasks')!.get(String(printTaskIndex)) as IczFormGroup;
      }
    }
    else {
      printTaskForm = this.form;
    }

    const consigneeClassification = printTaskForm.get('consigneeSubjectClassification')!.value as unknown as SubjectRecordClassification;

    return SubjectTemplateUtils.isLegalPerson(consigneeClassification) || SubjectTemplateUtils.isBusinessIndividual(consigneeClassification);
  }

  getSelectedEnvelopeTemplate(printTaskIndex: number): Nullable<EnvelopeTemplateDto> {
    if (this.isTestingPrintMode) {
      return this.data.draftEnvelopeTemplate;
    }
    else {
      let envelopeTemplateId: Nullable<number>;

      if (this.isBulkPrinting) {
        if (printTaskIndex === -1) {
          envelopeTemplateId = Number(this.form.value.masterPrintSettings.envelopeTemplateId);
        }
        else {
          envelopeTemplateId = Number(this.form.value.printTasks[printTaskIndex]?.envelopeTemplateId ?? 0);
        }
      }
      else {
        envelopeTemplateId = Number(this.form.value.envelopeTemplateId);
      }

      return this.envelopeTemplateOptions.find(o => o.value === envelopeTemplateId)?.data;
    }
  }

  getPrintTaskTabHeading(printTaskIndex: number) {
    return `${this.translateService.instant('Obálka')} ${printTaskIndex + 1}`;
  }

  protected getTestConsigneeSubjectDto(): SubjectRecordDto {
    if (this.isConsigneeBusiness(0)) {
      return this.getBusinessEntityTestConsignee();
    }
    else {
      return this.getNaturalPersonTestConsignee();
    }
  }

  protected requestVisualPreview(printTaskIndex: number) {
    this.previewDataUrls[printTaskIndex] = null;
    this.cd.detectChanges();

    let preview$: Observable<ArrayBuffer>;

    if (this.isTestingPrintMode) {
      preview$ = this.remoteBinaryFileDownloadService.fetchEnvelopeTestPrintPreview(
        'SVG',
        this.getPreviewRequestDto(true, printTaskIndex) as EnvelopeTemplatePreviewRequestDto,
      );
    }
    else {
      if (!this.getSelectedEnvelopeTemplate(printTaskIndex)) {
        return;
      }

      preview$ = this.remoteBinaryFileDownloadService.fetchEnvelopePrintPreview(
        'SVG',
        this.getPreviewRequestDto(true, printTaskIndex) as EnvelopePrintRequestDto,
      );
    }

    this.previewSubscription?.unsubscribe();

    this.previewSubscription = this.loadingService.doLoading(
      preview$.pipe(
        switchMap(pdfBuffer => {
          return fileToBase64(new File([pdfBuffer], 'unknown.svg', {type: 'image/svg+xml'}));
        }),
      ),
      this,
      EnvelopePrintDialogLoader.PREVIEW
    ).subscribe(svgDataUrl => {
      this.previewDataUrls[printTaskIndex] = svgDataUrl;
      this.cd.detectChanges();
    });
  }

  protected setUpCustomFieldDefs() {
    const selectedMasterEnvelopeTemplate = this.getSelectedEnvelopeTemplate(-1);

    if (selectedMasterEnvelopeTemplate) {
      const masterTextSections = selectedMasterEnvelopeTemplate.sections.filter(
        s => s.sectionType === EnvelopeSectionType.TEXT
      ) as CustomTextEnvelopeSectionDto[];

      const customFieldDefs = masterTextSections.map(s => ({
        key: s.sectionKey,
        label: s.name,
      }));

      this.masterCustomFieldDefs = customFieldDefs;
    }

    this.customFieldDefs = [];

    if (!this.isTestingPrintMode) {
      for (let i = 0; i < this.data.consignments!.length; ++i) {
        this.setUpCustomFieldsForPrintTask(i);
      }
    }
    else {
      this.setUpCustomFieldsForPrintTask(0);
    }
  }

  protected getPreviewRequestDto(isForPreviewCanvas: boolean, printTaskIndex?: number): EnvelopeTemplatePreviewRequestDto | EnvelopePrintRequestDto {
    if (this.isTestingPrintMode) {
      const formValue = this.form.getRawValue();
      const isConsigneeBusiness = this.isConsigneeBusiness(0);

      return {
        template: this.getSelectedEnvelopeTemplate(0)!,
        testData: {
          salutation: isConsigneeBusiness ? null : formValue.salutation,
          customText: filterEmptyCustomTextValues(formValue.customText),
          contactPerson: isConsigneeBusiness ? formValue.contactPerson : null,
          consigneeAdditionalName: isConsigneeBusiness ? formValue.consigneeAdditionalName : null,
          consignee: this.getTestConsigneeSubjectDto(),
          consigneeAddress: formatAddressForDto(this.form.get('address') as IczFormGroup),
        },
        printXOffsetMm: isForPreviewCanvas ? null : formValue.xPrintOffsetMm,
        printYOffsetMm: isForPreviewCanvas ? null : formValue.yPrintOffsetMm,
      } as EnvelopeTemplatePreviewRequestDto;
    }
    else {
      const masterFormValue = this.form.getRawValue().masterPrintSettings;
      const formValue = (
        this.isBulkPrinting ?
          (
            printTaskIndex === -1 ?
              this.form.getRawValue().masterPrintSettings :
              this.form.getRawValue().printTasks[printTaskIndex!]
          ) :
          this.form.getRawValue()
      );
      const isConsigneeBusiness = this.isConsigneeBusiness(printTaskIndex!);

      return {
        consigneeAdditionalName: isConsigneeBusiness ? formValue.consigneeAdditionalName : null,
        contactPerson: isConsigneeBusiness ? formValue.contactPerson : null,
        customText: filterEmptyCustomTextValues(formValue.customText),
        envelopeTemplateId: formValue.envelopeTemplateId,
        ownConsignmentId: this.data.consignments![printTaskIndex!].id,
        salutation: isConsigneeBusiness ? null : formValue.salutation,
        printXOffsetMm: isForPreviewCanvas ? null : formValue.xPrintOffsetMm ?? masterFormValue?.xPrintOffsetMm,
        printYOffsetMm: isForPreviewCanvas ? null : formValue.yPrintOffsetMm ?? masterFormValue?.xPrintOffsetMm,
      } as EnvelopePrintRequestDto;
    }
  }

  private initializePrintTaskForm(
    printTaskForm: IczFormGroup,
    consignments: Array<OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto>,
    consignmentIndex: number,
  ) {
    const consignment = consignments[consignmentIndex];
    const envelopeTemplateControl = printTaskForm.get('envelopeTemplateId')!;

    envelopeTemplateControl.setValidators(IczValidators.required());
    envelopeTemplateControl.setValue(consignment.envelopeTemplateId);
    printTaskForm.get('salutation')!.setValue(consignment.salutation);
    printTaskForm.get('consigneeAdditionalName')!.setValue(consignment.consigneeAdditionalName);
    printTaskForm.get('contactPerson')!.setValue(consignment.contactPerson);

    printTaskForm.valueChanges.pipe(
      debounceTime(1000),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      if (printTaskForm.valid) {
        this.requestVisualPreview(consignmentIndex);
      }
    });

    envelopeTemplateControl!.valueChanges.pipe(
      startWith(null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      // this timeout is needed because the value of this.selectedEnvelopeTemplate
      //  is consistent with form model after one change detection tick
      setTimeout(() => {
        if (this.isBulkPrinting) {
          this.form.get('masterPrintSettings')!.get('envelopeTemplateId')!.setValue(null);
        }

        this.setUpCustomFieldDefs();

        if (!this.isBulkPrinting) {
          this.requestVisualPreview(consignmentIndex);
        }
      }, 0);
    });
  }

  private setUpCustomFieldsForPrintTask(printTaskIndex: number) {
    const selectedEnvelopeTemplate = this.getSelectedEnvelopeTemplate(printTaskIndex);

    if (selectedEnvelopeTemplate) {
      const textSections = selectedEnvelopeTemplate.sections.filter(
        s => s.sectionType === EnvelopeSectionType.TEXT
      ) as CustomTextEnvelopeSectionDto[];

      let customTextValues: Record<string, string> = {};

      if (!this.isTestingPrintMode) {
        const consignment = this.data.consignments![printTaskIndex];

        if (consignment.customText) {
          customTextValues = consignment.customText;
        }
      }

      const customFieldDefs = textSections.map(s => ({
        key: s.sectionKey,
        label: s.name,
      }));

      let customTextFormModel: IczFormGroup;

      if (this.isBulkPrinting) {
        customTextFormModel = this.form.get('printTasks')!.get(String(printTaskIndex))!.get('customText') as IczFormGroup;
      }
      else {
        customTextFormModel = this.form.get('customText') as IczFormGroup;
      }

      initializeCustomFieldsForm(customFieldDefs, customTextFormModel);
      customTextFormModel.patchValue(customTextValues, {emitEvent: false});

      this.customFieldDefs.push(customFieldDefs);
    }
    else {
      this.customFieldDefs.push([]);
    }
  }

  private initSyncMasterToSlaveTaskValues(
    masterPrintForm: IczFormGroup,
    printTaskForms: IczFormArray,
    controlKey: keyof (OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto),
  ) {
    masterPrintForm.get(controlKey)!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(fieldValue => {
      for (let i = 0; i < this.data.consignments!.length; ++i) {
        if (!this.data.consignments![i][controlKey]) {
          printTaskForms.get(String(i))!.get(controlKey)!.setValue(fieldValue);
        }
      }
    });
  }

}
