import {Directive, ElementRef, HostBinding, inject, Input} from '@angular/core';
import {FormGroupDirective} from '@angular/forms';
import {Subject} from 'rxjs';
import {IczFormGroup} from './icz-form-controls';
import {ScrollingService} from '../../services/scrolling.service';
import {IczOnChanges, IczSimpleChanges} from '../../utils/icz-on-changes';

/**
 * In case of weird scroll positioning after pressing Submit,
 * flip iczFormDebug to true and see the development console.
 */
@Directive({
  selector: '[iczForm]'
})
export class IczFormDirective {

  private elementRef = inject(ElementRef);
  private scrollingService = inject(ScrollingService);
  private parentIczFormDirective = inject(IczFormDirective, {optional: true, skipSelf: true});

  form: Nullable<IczFormGroup<any>>;

  readonly nonGroupFocusableInvalidFields = '.ng-invalid:not(.icz-form-group) input, .ng-invalid:not(.icz-form-group) textarea, div.icz-validation-focusable-area';

  @Input()
  iczFormDebug = false;

  get isFormValid() {
    if (this.form) {
      // We must ensure that dynamic validators that might have changed between last form
      //  field change and form submission time have been run before returning validity value.
      // It's important to emitEvent=false because otherwise it will
      //  unnecessarily trigger valueChanges listeners in controllers.
      this.form.recursivelyUpdateValueAndValidity({emitEvent: false});
      return this.form.valid;
    }
    else {
      return true;
    }
  }

  get isFormDisabled() {
    if (this.form) {
      return this.form.disabled;
    }
    else {
      return false;
    }
  }

  private el: Nullable<HTMLElement>;

  ngOnInit() {
    // The host of this directive is an ng-container:
    // In such case, the directive will behave as if placed on the nearest real parent DOM element.
    if (this.elementRef.nativeElement.nodeType === Node.COMMENT_NODE) {
      if (this.elementRef.nativeElement.parentElement) {
        this.el = this.elementRef.nativeElement.parentElement;
      }
      else {
        console.warn(
          `[iczForm] no suitable element for iczForm container found. The directive will not perform any effects.`
        );
      }
    }
    else {
      this.el = this.elementRef.nativeElement;
    }

    if (this.el && this.el.tagName !== 'FORM' && !this.el.querySelector('form')) {
      console.warn(
        `[iczForm] directive should be placed either on a <form [form]="..." ...> element or on an ancestor of it.`
      );
    }

    if (this.parentIczFormDirective) {
      console.warn(
        `There already exists a parent [iczForm] placed on some of the ancestor elements. On-the-fly form validation with invalid field scroll might not work properly.`
      );
    }
  }

  openInvalidSections() {
    if (!this.el) {
      return;
    }

    setTimeout(() => {
      const openedSections: any[] = [];
      const invalidElements: NodeListOf<HTMLElement> = this.el!.querySelectorAll(this.nonGroupFocusableInvalidFields);

      if (invalidElements.length > 0) {
        invalidElements.forEach(el => {
          this.openAncestorSections(el, openedSections);
        });
      }
    });
  }

  focusFirstInvalid() {
    if (!this.el) {
      return;
    }

    setTimeout(() => {
      const invalidElements: NodeListOf<HTMLElement> = this.el!.querySelectorAll(this.nonGroupFocusableInvalidFields);

      if (invalidElements.length > 0) {
        const firstInvalidElement = invalidElements[0];
        const offsetTolerance = 100; // = 60px (toolbar) + 40px (safe area)

        const scrollableContainer = this.scrollingService.getScrollableAncestor(firstInvalidElement);
        if (scrollableContainer) {
          const offsetTopInAncestor = this.scrollingService.getOffsetTopInAncestor(firstInvalidElement, scrollableContainer);
          const topOffset = offsetTopInAncestor - offsetTolerance;
          scrollableContainer.scrollTo({top: topOffset, behavior: 'smooth'});
        }
        else {
          const elementWindowOffset = firstInvalidElement.getBoundingClientRect().top + window.scrollY - offsetTolerance;
          window.scrollTo({top: elementWindowOffset, behavior: 'smooth'});
        }

        setTimeout(() => {
          firstInvalidElement.focus();
        }, 700);
      }
    }, 100);
  }

  private openAncestorSections(el: Nullable<HTMLElement>, openedSections: any[]) {
    if (!isNil(el) && (el instanceof HTMLElement)) {
      if (el.tagName === 'MAT-EXPANSION-PANEL' && !el.classList.contains('mat-expanded')) {
        const wasOpened = openedSections.some(os => os === el);
        if (!wasOpened) {
          (el.firstElementChild as HTMLElement).click();
          openedSections.push(el);
        }
      }
      if (el !== this.el) {
        this.openAncestorSections(el.parentNode as Nullable<HTMLElement>, openedSections);
      }
    }
  }

}

// merely a glue directive to pass our FormGroup to iczForm
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[formGroup]',
})
export class IczFormContainerDirective implements IczOnChanges {

  private iczForm = inject(IczFormDirective, {optional: true});
  private parentFormGroup = inject(FormGroupDirective, {optional: true, skipSelf: true});

  @HostBinding('class')
  elementClass = 'icz-form-group';

  @Input()
  formGroup!: IczFormGroup<any>;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('formGroup.ignoreIczFormSubmitValidations')
  ignoreSetForm = false;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.formGroup.currentValue) {
      if ((this.parentFormGroup === null) && this.iczForm && changes.formGroup.currentValue && !this.ignoreSetForm) {
        this.iczForm.form = changes.formGroup.currentValue;
      }
      this.formGroupChanged$.next();
    }
  }

  // Observable for event of binding new instance of IczFormGroup to IczFormContainerDirective
  formGroupChanged$: Subject<void> = new Subject<void>();

}
