import {ChangeDetectorRef, Component, DestroyRef, HostBinding, inject, OnInit} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {MessageType, ToastMessage, ToastService} from '../toast.service';
import {appearWithDelay} from '../../../animations/animations';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {UserSettingsService} from '../../../services/user-settings.service';
import {combineLatest, map} from 'rxjs';


@Component({
  selector: 'icz-toast-center',
  templateUrl: './toast-center.component.html',
  styleUrls: ['./toast-center.component.scss'],
  animations: [
    appearWithDelay,
  ],
})
export class ToastCenterComponent implements OnInit {

  private userSettingsService = inject(UserSettingsService);
  private toastService = inject(ToastService);
  private translate = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @HostBinding('class.nonempty')
  get isNonEmpty() {
    return this.messages.length > 0;
  }

  messages: ToastMessage[] = [];

  messagesToShow = 3;
  nextNotification!: string;

  get messagesQueue() {
    const notifications = this.messages?.length - this.messagesToShow;
    return this.translateParser.interpolate(this.nextNotification, {notifications});
  }

  ngOnInit() {
    this.translate.onLangChange.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.translations();
    });

    this.translations();

    // Synthetic resize event emission will cause the popover with toast center
    // to correctly reposition when there were no notifications in the past.
    this.toastService.messages$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      window.dispatchEvent(new Event('resize'));
      this.cd.detectChanges();
    });

    combineLatest([
      this.userSettingsService.disableInfoToasts$,
      this.toastService.messages$,
    ]).pipe(
      map(([disableInfoToasts, messages]) => {
        if (disableInfoToasts) {
          return messages.filter(m => m.type !== MessageType.INFO);
        }
        else {
          return messages;
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(messages => {
      this.messages = messages;
    });
  }

  translations() {
    this.translate.get([
      'Další upozornění: x',
    ]).subscribe(translation => {
      this.nextNotification = translation['Další upozornění: x'];
    });
  }

  dismiss(index: number) {
    this.toastService.dismissToast(index);
  }

  closeAll() {
    this.toastService.reinitialize();
  }

}
