/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { AnalogComponentToDocumentReferenceCreateDto } from '../../models/analog-component-to-document-reference-create-dto';

export interface MediumComponentLinkMediumComponentToDocument$Params {
  documentId: number;
      body: Array<AnalogComponentToDocumentReferenceCreateDto>
}

export function mediumComponentLinkMediumComponentToDocument(http: HttpClient, rootUrl: string, params: MediumComponentLinkMediumComponentToDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, mediumComponentLinkMediumComponentToDocument.PATH, 'post');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

mediumComponentLinkMediumComponentToDocument.PATH = '/component/medium-component/{documentId}/link';