import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DocumentState} from '|api/commons';
import {FilterOperator, FilterParam, SearchParams} from '../../../services/search-api.service';
import {AbstractObjectSelectorComponent} from '../file-selector/abstract-object-selector.component';
import {WITHOUT_REF_NUMBER} from '../document-toolbar/services/toolbar-common.utils';
import {DocumentDto} from '|api/document';
import {OWN_DOCUMENT_OBJECT_CLASSES} from '../shared-document.utils';

@Component({
  selector: 'icz-own-document-selector',
  templateUrl: './own-document-selector.component.html',
  styleUrls: ['./own-document-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwnDocumentSelectorComponent extends AbstractObjectSelectorComponent<DocumentDto> {

  readonly objectClasses = [...OWN_DOCUMENT_OBJECT_CLASSES];
  readonly recentlyVisitedObjectsHeading = 'Naposledy navštívené vlastní dokumenty:';
  readonly additionalFilteringCriteria: FilterParam[] = [
    {
      fieldName: 'documentState',
      operator: FilterOperator.equals,
      value: String([DocumentState.ON_TABLE]),
    },
    {
      fieldName: 'objectClass',
      operator: FilterOperator.inSet,
      value: String(OWN_DOCUMENT_OBJECT_CLASSES),
    },
  ];
  readonly withoutRefNumberText = WITHOUT_REF_NUMBER;

  protected findObjects(searchParams: SearchParams) {
    return this.searchService.findDocumentsAll(searchParams);
  }

}
