import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {ApiRenditionService, FileDto} from '|api/document';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {LocalBinaryFileDownloadService} from '../../../../../services/local-binary-file-download.service';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {httpResponseToFile} from '../../../../../services/remote-binary-file-download.service';
import {OWN_DOCUMENT_OBJECT_CLASSES} from '../../../shared-document.utils';

export enum FileRenditionOutputType {
  APPEND_TO_DOCUMENT = 'APPEND_TO_DOCUMENT',
  DOWNLOAD = 'DOWNLOAD',
}

@Component({
  selector: 'icz-file-rendition-dialog',
  templateUrl: './file-rendition-dialog.component.html',
  styleUrls: ['./file-rendition-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class FileRenditionDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<FileRenditionOutputType>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private apiRenditionService = inject(ApiRenditionService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private destroyRef = inject(DestroyRef);
  private files = injectModalData<FileDto[]>();

  formGroupsToCheck!: string[];

  form = new IczFormGroup({
    outputType: new IczFormControl<Nullable<FileRenditionOutputType>>(null, [IczValidators.required()]),
    documentId: new IczFormControl<Nullable<number>>(null),
    includeOriginalComponents: new IczFormControl<Nullable<boolean>>(false),
  });

  get isDocumentAppendMode() {
    return this.form.value.outputType === FileRenditionOutputType.APPEND_TO_DOCUMENT;
  }

  readonly FileRenditionOutputType = FileRenditionOutputType;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.form.get('outputType')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(outputType => {
      const documentControl = this.form.get('documentId')!;

      documentControl.clearValidators();

      if (outputType === FileRenditionOutputType.APPEND_TO_DOCUMENT) {
        documentControl.setValidators([IczValidators.required()]);
      }

      documentControl.updateValueAndValidity();
    });
  }

  submit() {
    const formValue = this.form.getRawValue();

    this.loadingService.doLoading(
      this.apiRenditionService.renditionCreateBulkFileRendition$Response({
        body: {
          fileIds: this.files.map(f => f.id),
          addComponentOrigins: formValue.includeOriginalComponents,
          linkToDocumentId: formValue.documentId,
        }
      }),
      this
    ).pipe(
      httpResponseToFile(),
    ).subscribe(response => {
      if (!this.isDocumentAppendMode) {
        this.localBinaryFileDownloadService.downloadFile(response);
      }
      this.modalRef.close(this.form.value.outputType);
    });
  }

  close() {
    this.modalRef.close(null);
  }

  protected readonly OWN_DOCUMENT_OBJECT_CLASSES = OWN_DOCUMENT_OBJECT_CLASSES;
}
