import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentsTableDataType} from '../../documents-table.models';


@Component({
  selector: 'icz-quick-incidents-list-icon',
  templateUrl: './quick-incidents-list-icon.component.html',
  styleUrls: ['./quick-incidents-list-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickIncidentsListIconComponent {

  @Input({ required: true })
  entity!: DocumentsTableDataType;

  isIncidentsListOpen = false;

  readonly isNil = isNil;

}
