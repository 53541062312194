/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ermsAuditFindBatchByUid } from '../fn/erms-audit/erms-audit-find-batch-by-uid';
import { ErmsAuditFindBatchByUid$Params } from '../fn/erms-audit/erms-audit-find-batch-by-uid';
import { ermsAuditFindOutgoingBatchById } from '../fn/erms-audit/erms-audit-find-outgoing-batch-by-id';
import { ErmsAuditFindOutgoingBatchById$Params } from '../fn/erms-audit/erms-audit-find-outgoing-batch-by-id';
import { ermsAuditFindOutgoingBatches } from '../fn/erms-audit/erms-audit-find-outgoing-batches';
import { ErmsAuditFindOutgoingBatches$Params } from '../fn/erms-audit/erms-audit-find-outgoing-batches';
import { ermsAuditFindOutgoingBatchVersions } from '../fn/erms-audit/erms-audit-find-outgoing-batch-versions';
import { ErmsAuditFindOutgoingBatchVersions$Params } from '../fn/erms-audit/erms-audit-find-outgoing-batch-versions';
import { ermsAuditFindOutgoingEventById } from '../fn/erms-audit/erms-audit-find-outgoing-event-by-id';
import { ErmsAuditFindOutgoingEventById$Params } from '../fn/erms-audit/erms-audit-find-outgoing-event-by-id';
import { ermsAuditFindOutgoingEvents } from '../fn/erms-audit/erms-audit-find-outgoing-events';
import { ErmsAuditFindOutgoingEvents$Params } from '../fn/erms-audit/erms-audit-find-outgoing-events';
import { ermsAuditFindOutgoingEventsByBatchId } from '../fn/erms-audit/erms-audit-find-outgoing-events-by-batch-id';
import { ErmsAuditFindOutgoingEventsByBatchId$Params } from '../fn/erms-audit/erms-audit-find-outgoing-events-by-batch-id';
import { ermsAuditFindOutgoingMessageById } from '../fn/erms-audit/erms-audit-find-outgoing-message-by-id';
import { ErmsAuditFindOutgoingMessageById$Params } from '../fn/erms-audit/erms-audit-find-outgoing-message-by-id';
import { ermsAuditFindOutgoingMessages } from '../fn/erms-audit/erms-audit-find-outgoing-messages';
import { ErmsAuditFindOutgoingMessages$Params } from '../fn/erms-audit/erms-audit-find-outgoing-messages';
import { ermsAuditFindOutgoingMessagesByBatchId } from '../fn/erms-audit/erms-audit-find-outgoing-messages-by-batch-id';
import { ErmsAuditFindOutgoingMessagesByBatchId$Params } from '../fn/erms-audit/erms-audit-find-outgoing-messages-by-batch-id';
import { ermsAuditFindReceivedBatchById } from '../fn/erms-audit/erms-audit-find-received-batch-by-id';
import { ErmsAuditFindReceivedBatchById$Params } from '../fn/erms-audit/erms-audit-find-received-batch-by-id';
import { ermsAuditFindReceivedBatches } from '../fn/erms-audit/erms-audit-find-received-batches';
import { ErmsAuditFindReceivedBatches$Params } from '../fn/erms-audit/erms-audit-find-received-batches';
import { ermsAuditFindReceivedBatchVersions } from '../fn/erms-audit/erms-audit-find-received-batch-versions';
import { ErmsAuditFindReceivedBatchVersions$Params } from '../fn/erms-audit/erms-audit-find-received-batch-versions';
import { ermsAuditFindReceivedEventById } from '../fn/erms-audit/erms-audit-find-received-event-by-id';
import { ErmsAuditFindReceivedEventById$Params } from '../fn/erms-audit/erms-audit-find-received-event-by-id';
import { ermsAuditFindReceivedEvents } from '../fn/erms-audit/erms-audit-find-received-events';
import { ErmsAuditFindReceivedEvents$Params } from '../fn/erms-audit/erms-audit-find-received-events';
import { ermsAuditFindReceivedEventsByBatchId } from '../fn/erms-audit/erms-audit-find-received-events-by-batch-id';
import { ErmsAuditFindReceivedEventsByBatchId$Params } from '../fn/erms-audit/erms-audit-find-received-events-by-batch-id';
import { ermsAuditFindReceivedMessageById } from '../fn/erms-audit/erms-audit-find-received-message-by-id';
import { ErmsAuditFindReceivedMessageById$Params } from '../fn/erms-audit/erms-audit-find-received-message-by-id';
import { ermsAuditFindReceivedMessages } from '../fn/erms-audit/erms-audit-find-received-messages';
import { ErmsAuditFindReceivedMessages$Params } from '../fn/erms-audit/erms-audit-find-received-messages';
import { ermsAuditFindReceivedMessagesByBatchId } from '../fn/erms-audit/erms-audit-find-received-messages-by-batch-id';
import { ErmsAuditFindReceivedMessagesByBatchId$Params } from '../fn/erms-audit/erms-audit-find-received-messages-by-batch-id';
import { ermsAuditGetConfiguredIssdSources } from '../fn/erms-audit/erms-audit-get-configured-issd-sources';
import { ErmsAuditGetConfiguredIssdSources$Params } from '../fn/erms-audit/erms-audit-get-configured-issd-sources';
import { ermsAuditGetOutgoingEventDetail } from '../fn/erms-audit/erms-audit-get-outgoing-event-detail';
import { ErmsAuditGetOutgoingEventDetail$Params } from '../fn/erms-audit/erms-audit-get-outgoing-event-detail';
import { ermsAuditGetReceivedEventDetail } from '../fn/erms-audit/erms-audit-get-received-event-detail';
import { ErmsAuditGetReceivedEventDetail$Params } from '../fn/erms-audit/erms-audit-get-received-event-detail';
import { ermsAuditMarkOutgoingEventAsFixed } from '../fn/erms-audit/erms-audit-mark-outgoing-event-as-fixed';
import { ErmsAuditMarkOutgoingEventAsFixed$Params } from '../fn/erms-audit/erms-audit-mark-outgoing-event-as-fixed';
import { ErmsAuditOutgoingBatchDto } from '../models/erms-audit-outgoing-batch-dto';
import { ErmsAuditOutgoingEventDetailDto } from '../models/erms-audit-outgoing-event-detail-dto';
import { ErmsAuditOutgoingEventDto } from '../models/erms-audit-outgoing-event-dto';
import { ErmsAuditOutgoingMessageDto } from '../models/erms-audit-outgoing-message-dto';
import { ermsAuditReadOutgoingBatchContent } from '../fn/erms-audit/erms-audit-read-outgoing-batch-content';
import { ErmsAuditReadOutgoingBatchContent$Params } from '../fn/erms-audit/erms-audit-read-outgoing-batch-content';
import { ermsAuditReadReceivedBatchContent } from '../fn/erms-audit/erms-audit-read-received-batch-content';
import { ErmsAuditReadReceivedBatchContent$Params } from '../fn/erms-audit/erms-audit-read-received-batch-content';
import { ErmsAuditReceivedBatchDto } from '../models/erms-audit-received-batch-dto';
import { ErmsAuditReceivedEventDetailDto } from '../models/erms-audit-received-event-detail-dto';
import { ErmsAuditReceivedEventDto } from '../models/erms-audit-received-event-dto';
import { ErmsAuditReceivedMessageDto } from '../models/erms-audit-received-message-dto';
import { ermsAuditResendOutgoingBatch } from '../fn/erms-audit/erms-audit-resend-outgoing-batch';
import { ErmsAuditResendOutgoingBatch$Params } from '../fn/erms-audit/erms-audit-resend-outgoing-batch';
import { ermsAuditRetryOutgoingEvent } from '../fn/erms-audit/erms-audit-retry-outgoing-event';
import { ErmsAuditRetryOutgoingEvent$Params } from '../fn/erms-audit/erms-audit-retry-outgoing-event';
import { PageErmsAuditOutgoingBatchDto } from '../models/page-erms-audit-outgoing-batch-dto';
import { PageErmsAuditOutgoingEventDto } from '../models/page-erms-audit-outgoing-event-dto';
import { PageErmsAuditOutgoingMessageDto } from '../models/page-erms-audit-outgoing-message-dto';
import { PageErmsAuditReceivedBatchDto } from '../models/page-erms-audit-received-batch-dto';
import { PageErmsAuditReceivedEventDto } from '../models/page-erms-audit-received-event-dto';
import { PageErmsAuditReceivedMessageDto } from '../models/page-erms-audit-received-message-dto';


/**
 * The ERMS batch processing audit
 */
@Injectable({ providedIn: 'root' })
export class ApiErmsAuditService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ermsAuditRetryOutgoingEvent()` */
  static readonly ErmsAuditRetryOutgoingEventPath = '/erms-audit/outgoing/event/{id}/retry';

  /**
   * Retry outgoing event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditRetryOutgoingEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditRetryOutgoingEvent$Response(params: ErmsAuditRetryOutgoingEvent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsAuditRetryOutgoingEvent(this.http, this.rootUrl, params, context);
  }

  /**
   * Retry outgoing event
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditRetryOutgoingEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditRetryOutgoingEvent(params: ErmsAuditRetryOutgoingEvent$Params, context?: HttpContext): Observable<void> {
    return this.ermsAuditRetryOutgoingEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsAuditMarkOutgoingEventAsFixed()` */
  static readonly ErmsAuditMarkOutgoingEventAsFixedPath = '/erms-audit/outgoing/event/{id}/mark-as-fixed';

  /**
   * Mark outgoing event as fixed
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditMarkOutgoingEventAsFixed()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditMarkOutgoingEventAsFixed$Response(params: ErmsAuditMarkOutgoingEventAsFixed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsAuditMarkOutgoingEventAsFixed(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark outgoing event as fixed
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditMarkOutgoingEventAsFixed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditMarkOutgoingEventAsFixed(params: ErmsAuditMarkOutgoingEventAsFixed$Params, context?: HttpContext): Observable<void> {
    return this.ermsAuditMarkOutgoingEventAsFixed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsAuditResendOutgoingBatch()` */
  static readonly ErmsAuditResendOutgoingBatchPath = '/erms-audit/outgoing/batch/{id}/resend';

  /**
   * Resend outgoing batch
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditResendOutgoingBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditResendOutgoingBatch$Response(params: ErmsAuditResendOutgoingBatch$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsAuditResendOutgoingBatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Resend outgoing batch
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditResendOutgoingBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditResendOutgoingBatch(params: ErmsAuditResendOutgoingBatch$Params, context?: HttpContext): Observable<void> {
    return this.ermsAuditResendOutgoingBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedMessageById()` */
  static readonly ErmsAuditFindReceivedMessageByIdPath = '/erms-audit/received/message/{id}';

  /**
   * By id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedMessageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedMessageById$Response(params: ErmsAuditFindReceivedMessageById$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditReceivedMessageDto>> {
    return ermsAuditFindReceivedMessageById(this.http, this.rootUrl, params, context);
  }

  /**
   * By id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedMessageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedMessageById(params: ErmsAuditFindReceivedMessageById$Params, context?: HttpContext): Observable<ErmsAuditReceivedMessageDto> {
    return this.ermsAuditFindReceivedMessageById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditReceivedMessageDto>): ErmsAuditReceivedMessageDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedMessages()` */
  static readonly ErmsAuditFindReceivedMessagesPath = '/erms-audit/received/message/search-api';

  /**
   * By predicate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedMessages$Response(params?: ErmsAuditFindReceivedMessages$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageErmsAuditReceivedMessageDto>> {
    return ermsAuditFindReceivedMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * By predicate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedMessages(params?: ErmsAuditFindReceivedMessages$Params|null|undefined, context?: HttpContext): Observable<PageErmsAuditReceivedMessageDto> {
    return this.ermsAuditFindReceivedMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageErmsAuditReceivedMessageDto>): PageErmsAuditReceivedMessageDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedMessagesByBatchId()` */
  static readonly ErmsAuditFindReceivedMessagesByBatchIdPath = '/erms-audit/received/message/by-batch-id/{batchId}';

  /**
   * By batch id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedMessagesByBatchId()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedMessagesByBatchId$Response(params: ErmsAuditFindReceivedMessagesByBatchId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErmsAuditReceivedMessageDto>>> {
    return ermsAuditFindReceivedMessagesByBatchId(this.http, this.rootUrl, params, context);
  }

  /**
   * By batch id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedMessagesByBatchId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedMessagesByBatchId(params: ErmsAuditFindReceivedMessagesByBatchId$Params, context?: HttpContext): Observable<Array<ErmsAuditReceivedMessageDto>> {
    return this.ermsAuditFindReceivedMessagesByBatchId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErmsAuditReceivedMessageDto>>): Array<ErmsAuditReceivedMessageDto> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedEventById()` */
  static readonly ErmsAuditFindReceivedEventByIdPath = '/erms-audit/received/event/{id}';

  /**
   * By id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedEventById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedEventById$Response(params: ErmsAuditFindReceivedEventById$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditReceivedEventDto>> {
    return ermsAuditFindReceivedEventById(this.http, this.rootUrl, params, context);
  }

  /**
   * By id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedEventById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedEventById(params: ErmsAuditFindReceivedEventById$Params, context?: HttpContext): Observable<ErmsAuditReceivedEventDto> {
    return this.ermsAuditFindReceivedEventById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditReceivedEventDto>): ErmsAuditReceivedEventDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditGetReceivedEventDetail()` */
  static readonly ErmsAuditGetReceivedEventDetailPath = '/erms-audit/received/event/{id}/detail';

  /**
   * Returns detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditGetReceivedEventDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditGetReceivedEventDetail$Response(params: ErmsAuditGetReceivedEventDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditReceivedEventDetailDto>> {
    return ermsAuditGetReceivedEventDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns detail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditGetReceivedEventDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditGetReceivedEventDetail(params: ErmsAuditGetReceivedEventDetail$Params, context?: HttpContext): Observable<ErmsAuditReceivedEventDetailDto> {
    return this.ermsAuditGetReceivedEventDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditReceivedEventDetailDto>): ErmsAuditReceivedEventDetailDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedEvents()` */
  static readonly ErmsAuditFindReceivedEventsPath = '/erms-audit/received/event/search-api';

  /**
   * By predicate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedEvents$Response(params?: ErmsAuditFindReceivedEvents$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageErmsAuditReceivedEventDto>> {
    return ermsAuditFindReceivedEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * By predicate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedEvents(params?: ErmsAuditFindReceivedEvents$Params|null|undefined, context?: HttpContext): Observable<PageErmsAuditReceivedEventDto> {
    return this.ermsAuditFindReceivedEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageErmsAuditReceivedEventDto>): PageErmsAuditReceivedEventDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedEventsByBatchId()` */
  static readonly ErmsAuditFindReceivedEventsByBatchIdPath = '/erms-audit/received/event/by-batch-id/{batchId}';

  /**
   * By batch id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedEventsByBatchId()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedEventsByBatchId$Response(params: ErmsAuditFindReceivedEventsByBatchId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErmsAuditReceivedEventDto>>> {
    return ermsAuditFindReceivedEventsByBatchId(this.http, this.rootUrl, params, context);
  }

  /**
   * By batch id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedEventsByBatchId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedEventsByBatchId(params: ErmsAuditFindReceivedEventsByBatchId$Params, context?: HttpContext): Observable<Array<ErmsAuditReceivedEventDto>> {
    return this.ermsAuditFindReceivedEventsByBatchId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErmsAuditReceivedEventDto>>): Array<ErmsAuditReceivedEventDto> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedBatchById()` */
  static readonly ErmsAuditFindReceivedBatchByIdPath = '/erms-audit/received/batch/{id}';

  /**
   * By id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedBatchById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedBatchById$Response(params: ErmsAuditFindReceivedBatchById$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditReceivedBatchDto>> {
    return ermsAuditFindReceivedBatchById(this.http, this.rootUrl, params, context);
  }

  /**
   * By id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedBatchById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedBatchById(params: ErmsAuditFindReceivedBatchById$Params, context?: HttpContext): Observable<ErmsAuditReceivedBatchDto> {
    return this.ermsAuditFindReceivedBatchById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditReceivedBatchDto>): ErmsAuditReceivedBatchDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditReadReceivedBatchContent()` */
  static readonly ErmsAuditReadReceivedBatchContentPath = '/erms-audit/received/batch/{id}/xml';

  /**
   * Read batch xml
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditReadReceivedBatchContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditReadReceivedBatchContent$Response(params: ErmsAuditReadReceivedBatchContent$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return ermsAuditReadReceivedBatchContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Read batch xml
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditReadReceivedBatchContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditReadReceivedBatchContent(params: ErmsAuditReadReceivedBatchContent$Params, context?: HttpContext): Observable<any> {
    return this.ermsAuditReadReceivedBatchContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedBatchVersions()` */
  static readonly ErmsAuditFindReceivedBatchVersionsPath = '/erms-audit/received/batch/{id}/versions';

  /**
   * Get versions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedBatchVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedBatchVersions$Response(params: ErmsAuditFindReceivedBatchVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErmsAuditReceivedBatchDto>>> {
    return ermsAuditFindReceivedBatchVersions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get versions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedBatchVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedBatchVersions(params: ErmsAuditFindReceivedBatchVersions$Params, context?: HttpContext): Observable<Array<ErmsAuditReceivedBatchDto>> {
    return this.ermsAuditFindReceivedBatchVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErmsAuditReceivedBatchDto>>): Array<ErmsAuditReceivedBatchDto> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindReceivedBatches()` */
  static readonly ErmsAuditFindReceivedBatchesPath = '/erms-audit/received/batch/search-api';

  /**
   * By predicate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindReceivedBatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedBatches$Response(params?: ErmsAuditFindReceivedBatches$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageErmsAuditReceivedBatchDto>> {
    return ermsAuditFindReceivedBatches(this.http, this.rootUrl, params, context);
  }

  /**
   * By predicate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindReceivedBatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindReceivedBatches(params?: ErmsAuditFindReceivedBatches$Params|null|undefined, context?: HttpContext): Observable<PageErmsAuditReceivedBatchDto> {
    return this.ermsAuditFindReceivedBatches$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageErmsAuditReceivedBatchDto>): PageErmsAuditReceivedBatchDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingMessageById()` */
  static readonly ErmsAuditFindOutgoingMessageByIdPath = '/erms-audit/outgoing/message/{id}';

  /**
   * By id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingMessageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingMessageById$Response(params: ErmsAuditFindOutgoingMessageById$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditOutgoingMessageDto>> {
    return ermsAuditFindOutgoingMessageById(this.http, this.rootUrl, params, context);
  }

  /**
   * By id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingMessageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingMessageById(params: ErmsAuditFindOutgoingMessageById$Params, context?: HttpContext): Observable<ErmsAuditOutgoingMessageDto> {
    return this.ermsAuditFindOutgoingMessageById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditOutgoingMessageDto>): ErmsAuditOutgoingMessageDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingMessages()` */
  static readonly ErmsAuditFindOutgoingMessagesPath = '/erms-audit/outgoing/message/search-api';

  /**
   * By predicate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingMessages()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingMessages$Response(params?: ErmsAuditFindOutgoingMessages$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageErmsAuditOutgoingMessageDto>> {
    return ermsAuditFindOutgoingMessages(this.http, this.rootUrl, params, context);
  }

  /**
   * By predicate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingMessages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingMessages(params?: ErmsAuditFindOutgoingMessages$Params|null|undefined, context?: HttpContext): Observable<PageErmsAuditOutgoingMessageDto> {
    return this.ermsAuditFindOutgoingMessages$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageErmsAuditOutgoingMessageDto>): PageErmsAuditOutgoingMessageDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingMessagesByBatchId()` */
  static readonly ErmsAuditFindOutgoingMessagesByBatchIdPath = '/erms-audit/outgoing/message/by-batch-id/{batchId}';

  /**
   * By batch id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingMessagesByBatchId()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingMessagesByBatchId$Response(params: ErmsAuditFindOutgoingMessagesByBatchId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErmsAuditOutgoingMessageDto>>> {
    return ermsAuditFindOutgoingMessagesByBatchId(this.http, this.rootUrl, params, context);
  }

  /**
   * By batch id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingMessagesByBatchId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingMessagesByBatchId(params: ErmsAuditFindOutgoingMessagesByBatchId$Params, context?: HttpContext): Observable<Array<ErmsAuditOutgoingMessageDto>> {
    return this.ermsAuditFindOutgoingMessagesByBatchId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErmsAuditOutgoingMessageDto>>): Array<ErmsAuditOutgoingMessageDto> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingEventById()` */
  static readonly ErmsAuditFindOutgoingEventByIdPath = '/erms-audit/outgoing/event/{id}';

  /**
   * By id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingEventById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingEventById$Response(params: ErmsAuditFindOutgoingEventById$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditOutgoingEventDto>> {
    return ermsAuditFindOutgoingEventById(this.http, this.rootUrl, params, context);
  }

  /**
   * By id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingEventById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingEventById(params: ErmsAuditFindOutgoingEventById$Params, context?: HttpContext): Observable<ErmsAuditOutgoingEventDto> {
    return this.ermsAuditFindOutgoingEventById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditOutgoingEventDto>): ErmsAuditOutgoingEventDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditGetOutgoingEventDetail()` */
  static readonly ErmsAuditGetOutgoingEventDetailPath = '/erms-audit/outgoing/event/{id}/detail';

  /**
   * Returns detail
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditGetOutgoingEventDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditGetOutgoingEventDetail$Response(params: ErmsAuditGetOutgoingEventDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditOutgoingEventDetailDto>> {
    return ermsAuditGetOutgoingEventDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns detail
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditGetOutgoingEventDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditGetOutgoingEventDetail(params: ErmsAuditGetOutgoingEventDetail$Params, context?: HttpContext): Observable<ErmsAuditOutgoingEventDetailDto> {
    return this.ermsAuditGetOutgoingEventDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditOutgoingEventDetailDto>): ErmsAuditOutgoingEventDetailDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingEvents()` */
  static readonly ErmsAuditFindOutgoingEventsPath = '/erms-audit/outgoing/event/search-api';

  /**
   * By predicate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingEvents$Response(params?: ErmsAuditFindOutgoingEvents$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageErmsAuditOutgoingEventDto>> {
    return ermsAuditFindOutgoingEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * By predicate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingEvents(params?: ErmsAuditFindOutgoingEvents$Params|null|undefined, context?: HttpContext): Observable<PageErmsAuditOutgoingEventDto> {
    return this.ermsAuditFindOutgoingEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageErmsAuditOutgoingEventDto>): PageErmsAuditOutgoingEventDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingEventsByBatchId()` */
  static readonly ErmsAuditFindOutgoingEventsByBatchIdPath = '/erms-audit/outgoing/event/by-batch-id/{batchId}';

  /**
   * By batch id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingEventsByBatchId()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingEventsByBatchId$Response(params: ErmsAuditFindOutgoingEventsByBatchId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErmsAuditOutgoingEventDto>>> {
    return ermsAuditFindOutgoingEventsByBatchId(this.http, this.rootUrl, params, context);
  }

  /**
   * By batch id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingEventsByBatchId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingEventsByBatchId(params: ErmsAuditFindOutgoingEventsByBatchId$Params, context?: HttpContext): Observable<Array<ErmsAuditOutgoingEventDto>> {
    return this.ermsAuditFindOutgoingEventsByBatchId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErmsAuditOutgoingEventDto>>): Array<ErmsAuditOutgoingEventDto> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingBatchById()` */
  static readonly ErmsAuditFindOutgoingBatchByIdPath = '/erms-audit/outgoing/batch/{id}';

  /**
   * By id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingBatchById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingBatchById$Response(params: ErmsAuditFindOutgoingBatchById$Params, context?: HttpContext): Observable<StrictHttpResponse<ErmsAuditOutgoingBatchDto>> {
    return ermsAuditFindOutgoingBatchById(this.http, this.rootUrl, params, context);
  }

  /**
   * By id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingBatchById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingBatchById(params: ErmsAuditFindOutgoingBatchById$Params, context?: HttpContext): Observable<ErmsAuditOutgoingBatchDto> {
    return this.ermsAuditFindOutgoingBatchById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ErmsAuditOutgoingBatchDto>): ErmsAuditOutgoingBatchDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditReadOutgoingBatchContent()` */
  static readonly ErmsAuditReadOutgoingBatchContentPath = '/erms-audit/outgoing/batch/{id}/xml';

  /**
   * Read batch xml
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditReadOutgoingBatchContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditReadOutgoingBatchContent$Response(params: ErmsAuditReadOutgoingBatchContent$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return ermsAuditReadOutgoingBatchContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Read batch xml
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditReadOutgoingBatchContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditReadOutgoingBatchContent(params: ErmsAuditReadOutgoingBatchContent$Params, context?: HttpContext): Observable<any> {
    return this.ermsAuditReadOutgoingBatchContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingBatchVersions()` */
  static readonly ErmsAuditFindOutgoingBatchVersionsPath = '/erms-audit/outgoing/batch/{id}/versions';

  /**
   * Get versions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingBatchVersions()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingBatchVersions$Response(params: ErmsAuditFindOutgoingBatchVersions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ErmsAuditOutgoingBatchDto>>> {
    return ermsAuditFindOutgoingBatchVersions(this.http, this.rootUrl, params, context);
  }

  /**
   * Get versions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingBatchVersions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingBatchVersions(params: ErmsAuditFindOutgoingBatchVersions$Params, context?: HttpContext): Observable<Array<ErmsAuditOutgoingBatchDto>> {
    return this.ermsAuditFindOutgoingBatchVersions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ErmsAuditOutgoingBatchDto>>): Array<ErmsAuditOutgoingBatchDto> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindOutgoingBatches()` */
  static readonly ErmsAuditFindOutgoingBatchesPath = '/erms-audit/outgoing/batch/search-api';

  /**
   * By predicate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindOutgoingBatches()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingBatches$Response(params?: ErmsAuditFindOutgoingBatches$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageErmsAuditOutgoingBatchDto>> {
    return ermsAuditFindOutgoingBatches(this.http, this.rootUrl, params, context);
  }

  /**
   * By predicate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindOutgoingBatches$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindOutgoingBatches(params?: ErmsAuditFindOutgoingBatches$Params|null|undefined, context?: HttpContext): Observable<PageErmsAuditOutgoingBatchDto> {
    return this.ermsAuditFindOutgoingBatches$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageErmsAuditOutgoingBatchDto>): PageErmsAuditOutgoingBatchDto => r.body)
    );
  }

  /** Path part for operation `ermsAuditGetConfiguredIssdSources()` */
  static readonly ErmsAuditGetConfiguredIssdSourcesPath = '/erms-audit/issd-source';

  /**
   * Returns configured Issd sources
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditGetConfiguredIssdSources()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditGetConfiguredIssdSources$Response(params?: ErmsAuditGetConfiguredIssdSources$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ermsAuditGetConfiguredIssdSources(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns configured Issd sources
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditGetConfiguredIssdSources$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditGetConfiguredIssdSources(params?: ErmsAuditGetConfiguredIssdSources$Params|null|undefined, context?: HttpContext): Observable<Array<string>> {
    return this.ermsAuditGetConfiguredIssdSources$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ermsAuditFindBatchByUid()` */
  static readonly ErmsAuditFindBatchByUidPath = '/erms-audit/batch/{uid}';

  /**
   * By uid
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsAuditFindBatchByUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindBatchByUid$Response(params: ErmsAuditFindBatchByUid$Params, context?: HttpContext): Observable<StrictHttpResponse<(ErmsAuditReceivedBatchDto | ErmsAuditOutgoingBatchDto)>> {
    return ermsAuditFindBatchByUid(this.http, this.rootUrl, params, context);
  }

  /**
   * By uid
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsAuditFindBatchByUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsAuditFindBatchByUid(params: ErmsAuditFindBatchByUid$Params, context?: HttpContext): Observable<(ErmsAuditReceivedBatchDto | ErmsAuditOutgoingBatchDto)> {
    return this.ermsAuditFindBatchByUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<(ErmsAuditReceivedBatchDto | ErmsAuditOutgoingBatchDto)>): (ErmsAuditReceivedBatchDto | ErmsAuditOutgoingBatchDto) => r.body)
    );
  }

}
