export function daysInBetween(minDate: Date, maxDate: Date) {
  const difference = (minDate.getTime() - maxDate.getTime());
  return Math.ceil(difference / (1000 * 3600 * 24));
}

export function labels(config: any): string[] {
  const cfg = config || {};
  const min = cfg.min || 0;
  const max = cfg.max || 100;
  const count = cfg.count || 8;
  const step = (max - min) / count;
  const decimals = cfg.decimals || 8;
  const dfactor = Math.pow(10, decimals) || 0;
  const prefix = cfg.prefix || '';
  const values: string[] = [];
  let i;

  for (i = min; i < max; i += step) {
    values.push(prefix + Math.round(dfactor * i) / dfactor);
  }

  return values;
}

export enum ChartElementColor {
  RED = 'RED',
  ORANGE = 'ORANGE',
  YELLOW = 'YELLOW',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
  PURPLE = 'PURPLE',
  GREY = 'GREY',
}

export const CHART_COLORS_MAP = {
  [ChartElementColor.BLUE]: 'rgba(54, 162, 235, 0.5)',
  [ChartElementColor.RED]: 'rgba(246, 16, 103, 0.5)',
  [ChartElementColor.ORANGE]: 'rgba(249, 183, 81, 0.5)',
  [ChartElementColor.YELLOW]: 'rgba(255, 205, 86, 0.5)',
  [ChartElementColor.GREEN]: 'rgba(75, 212, 129, 0.5)',
  [ChartElementColor.PURPLE]: 'rgba(153, 102, 255, 0.5)',
  [ChartElementColor.GREY]: 'rgba(201, 203, 207, 0.5)'
};

export const CHART_COLOR_PALETTE = [
  CHART_COLORS_MAP[ChartElementColor.BLUE],
  CHART_COLORS_MAP[ChartElementColor.RED],
  CHART_COLORS_MAP[ChartElementColor.GREEN],
  CHART_COLORS_MAP[ChartElementColor.ORANGE],
  CHART_COLORS_MAP[ChartElementColor.PURPLE],
  CHART_COLORS_MAP[ChartElementColor.GREY],
  CHART_COLORS_MAP[ChartElementColor.YELLOW],
];
