/* tslint:disable */
/* eslint-disable */

/**
 * Document form
 */
export enum DocumentForm {
  DIGITAL = 'DIGITAL',
  ANALOG = 'ANALOG',
  HYBRID = 'HYBRID'
}
