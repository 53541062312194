@if (!hideSelector) {
  <div [formGroup]="form">
    <icz-form-autocomplete formControlName="dispatchOfficeId"
      [clearable]="false"
      [options]="dispatchOfficeOptions"
      [showValidationStatus]="false"
      class="dispatch-office-selector grow-1"
    ></icz-form-autocomplete>
  </div>
}
