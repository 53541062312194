/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CheckConversionValidityResponseDto } from '../models/check-conversion-validity-response-dto';
import { ComponentContentControlMetadataDto } from '../models/component-content-control-metadata-dto';
import { digitalComponentBulkLock } from '../fn/digital-component/digital-component-bulk-lock';
import { DigitalComponentBulkLock$Params } from '../fn/digital-component/digital-component-bulk-lock';
import { digitalComponentBulkUnlock } from '../fn/digital-component/digital-component-bulk-unlock';
import { DigitalComponentBulkUnlock$Params } from '../fn/digital-component/digital-component-bulk-unlock';
import { digitalComponentCheckAuthConversionClause } from '../fn/digital-component/digital-component-check-auth-conversion-clause';
import { DigitalComponentCheckAuthConversionClause$Params } from '../fn/digital-component/digital-component-check-auth-conversion-clause';
import { digitalComponentCheckin } from '../fn/digital-component/digital-component-checkin';
import { DigitalComponentCheckin$Params } from '../fn/digital-component/digital-component-checkin';
import { digitalComponentCheckout } from '../fn/digital-component/digital-component-checkout';
import { DigitalComponentCheckout$Params } from '../fn/digital-component/digital-component-checkout';
import { DigitalComponentCompleteDto } from '../models/digital-component-complete-dto';
import { digitalComponentCopyDigitalComponents } from '../fn/digital-component/digital-component-copy-digital-components';
import { DigitalComponentCopyDigitalComponents$Params } from '../fn/digital-component/digital-component-copy-digital-components';
import { digitalComponentCreateFromTemplate } from '../fn/digital-component/digital-component-create-from-template';
import { DigitalComponentCreateFromTemplate$Params } from '../fn/digital-component/digital-component-create-from-template';
import { digitalComponentDelete } from '../fn/digital-component/digital-component-delete';
import { DigitalComponentDelete$Params } from '../fn/digital-component/digital-component-delete';
import { digitalComponentDownloadAuthConversionClause } from '../fn/digital-component/digital-component-download-auth-conversion-clause';
import { DigitalComponentDownloadAuthConversionClause$Params } from '../fn/digital-component/digital-component-download-auth-conversion-clause';
import { DigitalComponentDto } from '../models/digital-component-dto';
import { digitalComponentFindById } from '../fn/digital-component/digital-component-find-by-id';
import { DigitalComponentFindById$Params } from '../fn/digital-component/digital-component-find-by-id';
import { digitalComponentFindByIds } from '../fn/digital-component/digital-component-find-by-ids';
import { DigitalComponentFindByIds$Params } from '../fn/digital-component/digital-component-find-by-ids';
import { digitalComponentFindDigitalComponents } from '../fn/digital-component/digital-component-find-digital-components';
import { DigitalComponentFindDigitalComponents$Params } from '../fn/digital-component/digital-component-find-digital-components';
import { digitalComponentGetComponentContentControlMetadata } from '../fn/digital-component/digital-component-get-component-content-control-metadata';
import { DigitalComponentGetComponentContentControlMetadata$Params } from '../fn/digital-component/digital-component-get-component-content-control-metadata';
import { digitalComponentGetDocumentDigitalComponentIds } from '../fn/digital-component/digital-component-get-document-digital-component-ids';
import { DigitalComponentGetDocumentDigitalComponentIds$Params } from '../fn/digital-component/digital-component-get-document-digital-component-ids';
import { digitalComponentGetDocumentDigitalComponents } from '../fn/digital-component/digital-component-get-document-digital-components';
import { DigitalComponentGetDocumentDigitalComponents$Params } from '../fn/digital-component/digital-component-get-document-digital-components';
import { digitalComponentLinkDigitalComponentToDocument } from '../fn/digital-component/digital-component-link-digital-component-to-document';
import { DigitalComponentLinkDigitalComponentToDocument$Params } from '../fn/digital-component/digital-component-link-digital-component-to-document';
import { digitalComponentLock } from '../fn/digital-component/digital-component-lock';
import { DigitalComponentLock$Params } from '../fn/digital-component/digital-component-lock';
import { digitalComponentUnlock } from '../fn/digital-component/digital-component-unlock';
import { DigitalComponentUnlock$Params } from '../fn/digital-component/digital-component-unlock';
import { digitalComponentUpdateComponentContentControlMetadata } from '../fn/digital-component/digital-component-update-component-content-control-metadata';
import { DigitalComponentUpdateComponentContentControlMetadata$Params } from '../fn/digital-component/digital-component-update-component-content-control-metadata';
import { digitalComponentUpdateMetadata } from '../fn/digital-component/digital-component-update-metadata';
import { DigitalComponentUpdateMetadata$Params } from '../fn/digital-component/digital-component-update-metadata';
import { digitalComponentUploadNewFileVersion } from '../fn/digital-component/digital-component-upload-new-file-version';
import { DigitalComponentUploadNewFileVersion$Params } from '../fn/digital-component/digital-component-upload-new-file-version';
import { DigitalComponentVersionDto } from '../models/digital-component-version-dto';
import { DigitalComponentVersionValidationResult } from '../models/digital-component-version-validation-result';
import { PageDigitalComponentCompleteDto } from '../models/page-digital-component-complete-dto';


/**
 * Controller for Digital components
 */
@Injectable({ providedIn: 'root' })
export class ApiDigitalComponentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `digitalComponentFindById()` */
  static readonly DigitalComponentFindByIdPath = '/component/digital-component/{id}';

  /**
   * Finds existing digital component by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentFindById$Response(params: DigitalComponentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentCompleteDto>> {
    return digitalComponentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds existing digital component by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentFindById(params: DigitalComponentFindById$Params, context?: HttpContext): Observable<DigitalComponentCompleteDto> {
    return this.digitalComponentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentCompleteDto>): DigitalComponentCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentUpdateMetadata()` */
  static readonly DigitalComponentUpdateMetadataPath = '/component/digital-component/{id}';

  /**
   * Update metadata for existing digital component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentUpdateMetadata()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentUpdateMetadata$Response(params: DigitalComponentUpdateMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentDto>> {
    return digitalComponentUpdateMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Update metadata for existing digital component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentUpdateMetadata$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentUpdateMetadata(params: DigitalComponentUpdateMetadata$Params, context?: HttpContext): Observable<DigitalComponentDto> {
    return this.digitalComponentUpdateMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentDto>): DigitalComponentDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentUpdateComponentContentControlMetadata()` */
  static readonly DigitalComponentUpdateComponentContentControlMetadataPath = '/component/digital-component/{id}/update-component-content-control-metadata';

  /**
   * Update component content control metadata for document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentUpdateComponentContentControlMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentUpdateComponentContentControlMetadata$Response(params: DigitalComponentUpdateComponentContentControlMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionDto>> {
    return digitalComponentUpdateComponentContentControlMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Update component content control metadata for document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentUpdateComponentContentControlMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentUpdateComponentContentControlMetadata(params: DigitalComponentUpdateComponentContentControlMetadata$Params, context?: HttpContext): Observable<DigitalComponentVersionDto> {
    return this.digitalComponentUpdateComponentContentControlMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentVersionDto>): DigitalComponentVersionDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentUnlock()` */
  static readonly DigitalComponentUnlockPath = '/component/digital-component/{id}/unlock';

  /**
   * Unlocks digital component for content editing. Only available for FM who did the lock (lockedByFunctionalPositionId), OR document holders, OR document holders supervisors.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentUnlock()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentUnlock$Response(params: DigitalComponentUnlock$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentCompleteDto>> {
    return digitalComponentUnlock(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlocks digital component for content editing. Only available for FM who did the lock (lockedByFunctionalPositionId), OR document holders, OR document holders supervisors.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentUnlock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentUnlock(params: DigitalComponentUnlock$Params, context?: HttpContext): Observable<DigitalComponentCompleteDto> {
    return this.digitalComponentUnlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentCompleteDto>): DigitalComponentCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentLock()` */
  static readonly DigitalComponentLockPath = '/component/digital-component/{id}/lock';

  /**
   * ANCHOR ESSL-5528 Locks digital component for content editing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentLock()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentLock$Response(params: DigitalComponentLock$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentCompleteDto>> {
    return digitalComponentLock(this.http, this.rootUrl, params, context);
  }

  /**
   * ANCHOR ESSL-5528 Locks digital component for content editing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentLock$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentLock(params: DigitalComponentLock$Params, context?: HttpContext): Observable<DigitalComponentCompleteDto> {
    return this.digitalComponentLock$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentCompleteDto>): DigitalComponentCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentGetComponentContentControlMetadata()` */
  static readonly DigitalComponentGetComponentContentControlMetadataPath = '/component/digital-component/{id}/get-component-content-control-metadata';

  /**
   * Gets component content control metadata for document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentGetComponentContentControlMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentGetComponentContentControlMetadata$Response(params: DigitalComponentGetComponentContentControlMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ComponentContentControlMetadataDto>>> {
    return digitalComponentGetComponentContentControlMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets component content control metadata for document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentGetComponentContentControlMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentGetComponentContentControlMetadata(params: DigitalComponentGetComponentContentControlMetadata$Params, context?: HttpContext): Observable<Array<ComponentContentControlMetadataDto>> {
    return this.digitalComponentGetComponentContentControlMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ComponentContentControlMetadataDto>>): Array<ComponentContentControlMetadataDto> => r.body)
    );
  }

  /** Path part for operation `digitalComponentCheckout()` */
  static readonly DigitalComponentCheckoutPath = '/component/digital-component/{id}/checkout';

  /**
   * Checks out and locks digital component for content editing.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentCheckout()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentCheckout$Response(params: DigitalComponentCheckout$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return digitalComponentCheckout(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks out and locks digital component for content editing.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentCheckout$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentCheckout(params: DigitalComponentCheckout$Params, context?: HttpContext): Observable<Array<string>> {
    return this.digitalComponentCheckout$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `digitalComponentCheckin()` */
  static readonly DigitalComponentCheckinPath = '/component/digital-component/{id}/checkin';

  /**
   * Checks in and possibly unlocks digital component for content editing. Only available for FM who did the lock (lockedByFunctionalPositionId), OR document holders, OR document holders supervisors.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentCheckin()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentCheckin$Response(params: DigitalComponentCheckin$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentCompleteDto>> {
    return digitalComponentCheckin(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks in and possibly unlocks digital component for content editing. Only available for FM who did the lock (lockedByFunctionalPositionId), OR document holders, OR document holders supervisors.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentCheckin$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentCheckin(params: DigitalComponentCheckin$Params, context?: HttpContext): Observable<DigitalComponentCompleteDto> {
    return this.digitalComponentCheckin$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentCompleteDto>): DigitalComponentCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentLinkDigitalComponentToDocument()` */
  static readonly DigitalComponentLinkDigitalComponentToDocumentPath = '/component/digital-component/{documentId}/link';

  /**
   * Link digital component for document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentLinkDigitalComponentToDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentLinkDigitalComponentToDocument$Response(params: DigitalComponentLinkDigitalComponentToDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentVersionValidationResult>>> {
    return digitalComponentLinkDigitalComponentToDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Link digital component for document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentLinkDigitalComponentToDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentLinkDigitalComponentToDocument(params: DigitalComponentLinkDigitalComponentToDocument$Params, context?: HttpContext): Observable<Array<DigitalComponentVersionValidationResult>> {
    return this.digitalComponentLinkDigitalComponentToDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DigitalComponentVersionValidationResult>>): Array<DigitalComponentVersionValidationResult> => r.body)
    );
  }

  /** Path part for operation `digitalComponentUploadNewFileVersion()` */
  static readonly DigitalComponentUploadNewFileVersionPath = '/component/digital-component/{digitalComponentId}';

  /**
   * Upload new file to already linked digital component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentUploadNewFileVersion()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentUploadNewFileVersion$Response(params: DigitalComponentUploadNewFileVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionDto>> {
    return digitalComponentUploadNewFileVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload new file to already linked digital component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentUploadNewFileVersion$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentUploadNewFileVersion(params: DigitalComponentUploadNewFileVersion$Params, context?: HttpContext): Observable<DigitalComponentVersionDto> {
    return this.digitalComponentUploadNewFileVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentVersionDto>): DigitalComponentVersionDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentFindByIds()` */
  static readonly DigitalComponentFindByIdsPath = '/component/digital-component/findByIds';

  /**
   * Find existing digital components by its ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentFindByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentFindByIds$Response(params: DigitalComponentFindByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentCompleteDto>>> {
    return digitalComponentFindByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Find existing digital components by its ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentFindByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentFindByIds(params: DigitalComponentFindByIds$Params, context?: HttpContext): Observable<Array<DigitalComponentCompleteDto>> {
    return this.digitalComponentFindByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DigitalComponentCompleteDto>>): Array<DigitalComponentCompleteDto> => r.body)
    );
  }

  /** Path part for operation `digitalComponentCreateFromTemplate()` */
  static readonly DigitalComponentCreateFromTemplatePath = '/component/digital-component/create-from-template';

  /**
   * Create component from template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentCreateFromTemplate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentCreateFromTemplate$Response(params: DigitalComponentCreateFromTemplate$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentCompleteDto>> {
    return digitalComponentCreateFromTemplate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create component from template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentCreateFromTemplate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentCreateFromTemplate(params: DigitalComponentCreateFromTemplate$Params, context?: HttpContext): Observable<DigitalComponentCompleteDto> {
    return this.digitalComponentCreateFromTemplate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentCompleteDto>): DigitalComponentCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentCopyDigitalComponents()` */
  static readonly DigitalComponentCopyDigitalComponentsPath = '/component/digital-component/copy';

  /**
   * Copy the list of digital components with their last digital component versions and link it with a document based on the provided document id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentCopyDigitalComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentCopyDigitalComponents$Response(params: DigitalComponentCopyDigitalComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentCopyDigitalComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Copy the list of digital components with their last digital component versions and link it with a document based on the provided document id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentCopyDigitalComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentCopyDigitalComponents(params: DigitalComponentCopyDigitalComponents$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentCopyDigitalComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentBulkUnlock()` */
  static readonly DigitalComponentBulkUnlockPath = '/component/digital-component/bulk-unlock';

  /**
   * Unlocks digital components for content editing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentBulkUnlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentBulkUnlock$Response(params: DigitalComponentBulkUnlock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentBulkUnlock(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlocks digital components for content editing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentBulkUnlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentBulkUnlock(params: DigitalComponentBulkUnlock$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentBulkUnlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentBulkLock()` */
  static readonly DigitalComponentBulkLockPath = '/component/digital-component/bulk-lock';

  /**
   * Locks digital components for content editing
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentBulkLock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentBulkLock$Response(params: DigitalComponentBulkLock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentBulkLock(this.http, this.rootUrl, params, context);
  }

  /**
   * Locks digital components for content editing
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentBulkLock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentBulkLock(params: DigitalComponentBulkLock$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentBulkLock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentGetDocumentDigitalComponents()` */
  static readonly DigitalComponentGetDocumentDigitalComponentsPath = '/component/digital-component/{documentId}/digital-components';

  /**
   * Returns digital components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentGetDocumentDigitalComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentGetDocumentDigitalComponents$Response(params: DigitalComponentGetDocumentDigitalComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentCompleteDto>>> {
    return digitalComponentGetDocumentDigitalComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns digital components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentGetDocumentDigitalComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentGetDocumentDigitalComponents(params: DigitalComponentGetDocumentDigitalComponents$Params, context?: HttpContext): Observable<Array<DigitalComponentCompleteDto>> {
    return this.digitalComponentGetDocumentDigitalComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DigitalComponentCompleteDto>>): Array<DigitalComponentCompleteDto> => r.body)
    );
  }

  /** Path part for operation `digitalComponentFindDigitalComponents()` */
  static readonly DigitalComponentFindDigitalComponentsPath = '/component/digital-component/{documentId}/digital-components/search-api';

  /**
   * Finds digital components based on documentId and search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentFindDigitalComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentFindDigitalComponents$Response(params: DigitalComponentFindDigitalComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDigitalComponentCompleteDto>> {
    return digitalComponentFindDigitalComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds digital components based on documentId and search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentFindDigitalComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentFindDigitalComponents(params: DigitalComponentFindDigitalComponents$Params, context?: HttpContext): Observable<PageDigitalComponentCompleteDto> {
    return this.digitalComponentFindDigitalComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDigitalComponentCompleteDto>): PageDigitalComponentCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentGetDocumentDigitalComponentIds()` */
  static readonly DigitalComponentGetDocumentDigitalComponentIdsPath = '/component/digital-component/{documentId}/digital-component-ids';

  /**
   * Returns digital components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentGetDocumentDigitalComponentIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentGetDocumentDigitalComponentIds$Response(params: DigitalComponentGetDocumentDigitalComponentIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return digitalComponentGetDocumentDigitalComponentIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns digital components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentGetDocumentDigitalComponentIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentGetDocumentDigitalComponentIds(params: DigitalComponentGetDocumentDigitalComponentIds$Params, context?: HttpContext): Observable<Array<number>> {
    return this.digitalComponentGetDocumentDigitalComponentIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `digitalComponentDownloadAuthConversionClause()` */
  static readonly DigitalComponentDownloadAuthConversionClausePath = '/component/digital-component/{digitalComponentId}/download-authorized-conversion-clause';

  /**
   * Downloads the authorised conversion clause for digital component instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentDownloadAuthConversionClause()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentDownloadAuthConversionClause$Response(params: DigitalComponentDownloadAuthConversionClause$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentDownloadAuthConversionClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Downloads the authorised conversion clause for digital component instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentDownloadAuthConversionClause$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentDownloadAuthConversionClause(params: DigitalComponentDownloadAuthConversionClause$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentDownloadAuthConversionClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentCheckAuthConversionClause()` */
  static readonly DigitalComponentCheckAuthConversionClausePath = '/component/digital-component/{digitalComponentId}/check-authorized-conversion-clause';

  /**
   * Checks the authorised conversion clause for digital component instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentCheckAuthConversionClause()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentCheckAuthConversionClause$Response(params: DigitalComponentCheckAuthConversionClause$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckConversionValidityResponseDto>> {
    return digitalComponentCheckAuthConversionClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks the authorised conversion clause for digital component instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentCheckAuthConversionClause$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentCheckAuthConversionClause(params: DigitalComponentCheckAuthConversionClause$Params, context?: HttpContext): Observable<CheckConversionValidityResponseDto> {
    return this.digitalComponentCheckAuthConversionClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckConversionValidityResponseDto>): CheckConversionValidityResponseDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentDelete()` */
  static readonly DigitalComponentDeletePath = '/component/digital-component/per-entity/document/{documentId}/component/{componentId}';

  /**
   * Deletes digital component in a given document according to theirs ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentDelete$Response(params: DigitalComponentDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes digital component in a given document according to theirs ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentDelete(params: DigitalComponentDelete$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
