import {inject, Injectable} from '@angular/core';
import {getToastHeaderTemplateTranslateKey, MessageType, ToastService} from '../../../notifications/toast.service';

export enum PermissionToastType {
  PERMISSION_ADDED = 'PERMISSION_ADDED',
  PERMISSION_DELETED = 'PERMISSION_DELETED',
  PERMISSION_UPDATED = 'PERMISSION_UPDATED',
}

@Injectable({
  providedIn: 'root',
})
export class PermissionToastService {

  private toastService = inject(ToastService);

  dispatchPermissionAdded() {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(PermissionToastType.PERMISSION_ADDED),
        },
      },
    });
  }

  dispatchPermissionDeleted() {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(PermissionToastType.PERMISSION_DELETED),
        },
      },
    });
  }

  dispatchPermissionUpdated() {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(PermissionToastType.PERMISSION_UPDATED),
        },
      },
    });
  }

}
