<div [ngClass]="{'acknowledged-notification-text': isRead}" class="font-bold">
  @if (code) {
    <span>
      @if (getMessageHeaderTemplate(code); as headerTemplate) {
        <icz-notification-template-outlet
          [template]="headerTemplate.template"
          [templateContext]="paramsMap"
          [templateContextSchema]="headerTemplate.schema"
        ></icz-notification-template-outlet>
      }
    </span>
  }
  @if (!code) {
    <span>
      {{ 'Bez předmětu' | translate }}
    </span>
  }
</div>
