import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
  RelatedObjectType,
  SubjectObjectRelationCreateDto,
  SubjectObjectRelationType,
  SubjectRecordClassification,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto
} from '|api/commons';
import {SubjectCreateComponentMode} from '../../subject-create-form/subject-create-form.component';
import {IczOnChanges, IczSimpleChanges} from '../../../../../utils/icz-on-changes';
import {
  addressAttributeTypeToCreateFromSearchedAddress,
  SearchOnlyAttribute,
  SubjectsService
} from '../../../../../services/subjects.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczFormArray, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczModalRef} from '../../../../../services/icz-modal-ref.injectable';
import {SubjectTemplateUtils} from '../../../../../utils/subject-template-utils';
import {stripUnwantedProps} from '../../../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  AddSubjectWizardData,
  constructSubjectName,
  SingleAddressCreateResult,
  StepCreateFormAction,
  StepCreateFormResult
} from '../../../model/subjects.model';
import {DocumentDetailService} from '../../../../../services/document-detail.service';
import {SubjectToastService, SubjectToastType} from '../../../../../core/services/notifications/subject-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {interpolateBackendErrorMessage} from '../../../../essentials/interpolate-backend-error.pipe';
import {TranslateService} from '@ngx-translate/core';
import {ObjectDetailPart} from '../../../../../services/abstract-object-detail.service';
import {load} from '../../../../../lib/object-counts';
import {createAddressFormGroup} from '../../address/address-utils';
import {isOwnDocumentObject} from '../../../shared-document.utils';
import {
  CreateOrUpdateSubjectAction,
  SubjectCreateOrUpdateWithDuplicateResolveService
} from '../../subject-create-or-update-with-duplicate-resolve.service';
import {SubjectAttributeType} from '../../../model/subject-attribute-type';


@Component({
  selector: 'icz-add-subject-step-create',
  templateUrl: './add-subject-step-create.component.html',
  styleUrls: ['./add-subject-step-create.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddSubjectStepCreateComponent implements OnInit, IczOnChanges {

  private subjectsService = inject(SubjectsService);
  private subjectToastService = inject(SubjectToastService);
  private translateService = inject(TranslateService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);
  private subjectCreateOrUpdateWithDuplicateResolveService = inject(SubjectCreateOrUpdateWithDuplicateResolveService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});

  @Input({required: true})
  set dataForCreate(newValue: Nullable<AddSubjectWizardData>) {
    if (newValue && !this.dataPassed) {
      this._dataForCreate = newValue;

      const searchForm = newValue.searchForm!;
      this.relationType = newValue.relationType;

      // if UPPERCASE SubjectAttributeTypes are used both for search and create APIs, we won't need to map search to create keys manually
      // and just use form.patchValue()
      if (this.subjectsService && SubjectAttributeType) {
        this.mapSearchFormValuesToCreateForm(searchForm);
      }
      this.dataPassed = true;
    }
  }
  private dataPassed = false;
  private _dataForCreate: Nullable<AddSubjectWizardData>;

  @Input({required: true}) form!: IczFormGroup;
  @Input() modalRef!: IczModalRef<Nullable<SubjectRecordCreateOrUpdateDto>>;
  @Input() componentMode = SubjectCreateComponentMode.CREATE_FULL;
  @Input() enforceAddressValidator = true;
  @Input() documentId: Nullable<number>;
  @Input() useCustomFormButtons = true;
  @Input() allowEmitCreateResult = false;
  @Output() result = new EventEmitter<StepCreateFormResult>();
  @Output() blockingAction = new EventEmitter<boolean>();

  relationType: Nullable<SubjectObjectRelationType>;
  submitBlocked = false;
  singleAddressForm = createAddressFormGroup();
  singleAddress: Nullable<SingleAddressCreateResult>;

  get classification(): SubjectRecordClassification {
    return SubjectTemplateUtils.classification(this.form);
  }

  get isNaturalPerson(): boolean {
    return SubjectTemplateUtils.isNaturalPerson(this.classification);
  }

  private getSubjectToCreate(): SubjectRecordCreateOrUpdateDto {
	  const classification = SubjectTemplateUtils.classification(this.form);
	  const ovmType = SubjectTemplateUtils.ovmType(this.form);

	  const formValue = stripUnwantedProps<any, any>(this.form.getRawValue(), [], {removeNull: true, removeEmptyString: true});
    if (this.singleAddress?.addressForm && this.singleAddress.type) {
      const singleAddressValue = this.singleAddress.addressForm.getRawValue();
      if (this.singleAddress.type === SubjectAttributeType.ADDITIONAL_ADDRESS) {
        formValue[this.singleAddress.type] = [singleAddressValue];
      } else {
        formValue[this.singleAddress.type] = singleAddressValue;
      }
    }

    return {
      attributes: this.subjectsService.formValueToAttributesDto(formValue),
      classification,
      ovmType,
      enrichMode: true,
      forceMode: false,
    };
  }

  ngOnInit(): void {
   this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
			if (this.allowEmitCreateResult) {
				this.emitCreate();
			}
    });
  }

  singleAddressChange(result: Nullable<SingleAddressCreateResult>) {
    if (result) {
      this.singleAddress = result;
      this.emitCreate();
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.dataForCreate?.currentValue) {
      this.form.patchValue(this._dataForCreate!);
    }
		if (changes?.allowEmitCreateResult?.currentValue) {
			this.emitCreate();
		}
  }

  setBlockingAction(event: boolean) {
    this.submitBlocked = event;
    this.blockingAction.emit(event);
  }

  private mapSearchFormValuesToCreateForm(searchForm: Record<string, any>) {
    for (const key of Object.keys(searchForm)) {
      const valueToMap = searchForm[key] ?? null;

      const skippedKeys = [SearchOnlyAttribute.DATA_BOX, 'classification', 'identityType'];

      if (!skippedKeys.includes(key)) {
        if (key === SearchOnlyAttribute.EMAIL) {
          const emailControl = this.form?.get(SubjectAttributeType.EMAIL) as IczFormArray;
          if (emailControl?.length === 0) {
            emailControl.setSize(1);
            emailControl?.controls[0].get('value')?.setValue(valueToMap);
          }
        } else if (key === SearchOnlyAttribute.ADDRESS) {
          const address = searchForm.address ?? null;
          if (address?.value) {
            if (this.componentMode === SubjectCreateComponentMode.CREATE_FULL) {
              if (addressAttributeTypeToCreateFromSearchedAddress) {
                this.form.get(addressAttributeTypeToCreateFromSearchedAddress)!.get('value')!.patchValue(address.value);
              }
            } else if (this.componentMode === SubjectCreateComponentMode.CREATE_ESSENTIAL) {
              this.singleAddressForm!.get('value')!.patchValue(address.value);
            }
          }
        } else {
          this.form.get(key)!.get('value')?.setValue(valueToMap);
        }
      }
    }

    this.form.get('classification')?.setValue(searchForm.classification);
  }

  emitSearch() {
	  const subjectToCreate = this.getSubjectToCreate();
	  this.result.emit({action: StepCreateFormAction.SEARCH, subject: subjectToCreate});
  }

	emitCreate() {
    if (this.componentMode === SubjectCreateComponentMode.CREATE_FULL) {
      if (this.form.valid) {
        const subjectToCreate = this.getSubjectToCreate();
        this.result.emit({action: StepCreateFormAction.CREATE, subject: subjectToCreate});
      } else {
        this.result.emit({action: StepCreateFormAction.CREATE, subject: null});
      }
    } else if (this.componentMode === SubjectCreateComponentMode.CREATE_ESSENTIAL) {
      if (this.form.valid && this.singleAddress?.valid) {
        const subjectToCreate = this.getSubjectToCreate();
        this.result.emit({action: StepCreateFormAction.CREATE, subject: subjectToCreate});
      } else {
        this.result.emit({action: StepCreateFormAction.CREATE, subject: null});
      }
    }
	}

  private onCreateSuccess(subject: SubjectRecordDto | SubjectRecordCreateOrUpdateDto) {
    const name = constructSubjectName(subject);

    this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_LINK_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
    this.documentDetailService?.reloadObject({
      [ObjectDetailPart.OBJECT_DATA]: load(),
    });
    this.modalRef.close(subject);
  }

  private onCreateTechnicalError(subject: SubjectRecordCreateOrUpdateDto, error: any) {
    const name = constructSubjectName(subject);

    this.subjectToastService.dispatchSubjectErrorToast(
      SubjectToastType.SUBJECT_LINK_ERROR, {
        [InternalNotificationKey.SUBJECT_NAME]: name,
        [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, error.error),
      });
  }

  createAndLinkNewSubject() {
    if (!this.documentId) return;
    const subjectToCreate = this.getSubjectToCreate();

    const representing = isOwnDocumentObject(this.documentDetailService?.object);

    const relation: SubjectObjectRelationCreateDto = {
      relatedObjectId: this.documentId,
      representing,
      relationType: this.relationType,
      relatedObjectType: RelatedObjectType.DOCUMENT,
    };
    subjectToCreate.objectRelations = [relation];

    this.loadingIndicatorService.doLoading(
      this.subjectCreateOrUpdateWithDuplicateResolveService.createOrUpdateSubjectWithDuplicateResolve(
        CreateOrUpdateSubjectAction.CREATE_OR_UPDATE_SUBJECT,
        {subject: subjectToCreate, createRelation: relation},
        this.documentDetailService!.objectId),
      this)
      .subscribe(
        {
          next: createAttemptResult => {
            if (!createAttemptResult) {
              return; // no result means closing the duplicate dialog without any action
            }
            else {
              this.onCreateSuccess(createAttemptResult);
            }
          },
          error: err => {
            this.onCreateTechnicalError(subjectToCreate, err);
          }
        }
      );
  }
}
