/* tslint:disable */
/* eslint-disable */

/**
 * Message status after delivery
 */
export enum MessageStatusAfterDelivery {
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED'
}
