<!-- todo check visual regression -->
@if (timeSelectorUsage === TimeSelectorUsage.FORM) {
  <label>{{(label | translate)}}</label>
}

<div class="col">
  <div class="gap-4 row">
    @if (timeSelectorUsage === TimeSelectorUsage.FILTER) {
      <icz-icon class="mr-8" size="small" svgIcon="time"></icz-icon>
    }
    <icz-number-counter-field
      [minValue]="0"
      [maxValue]="23"
      [value]="hours!"
      (valueChange)="hoursChanged($event)"
      [timeSelectorUsage]="timeSelectorUsage"
      [fieldDisabled]="fieldDisabled"
    ></icz-number-counter-field>
    <div class="col hh-mm-separator" [ngClass]="{'hh-mm-separator-pad': timeSelectorUsage === TimeSelectorUsage.FILTER}">:</div>
    <icz-number-counter-field
      [minValue]="0"
      [maxValue]="59"
      [value]="minutes!"
      (valueChange)="minutesChanged($event)"
      [timeSelectorUsage]="timeSelectorUsage"
      [fieldDisabled]="fieldDisabled"
    ></icz-number-counter-field>
  </div>
</div>
