import {
  OwnConsignmentSpecificFieldsForm,
  OwnDataboxConsignmentForm,
  OwnEmailConsignmentForm
} from './consignment-dialog.form';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {OwnDigitalConsignmentDto, OwnPaperConsignmentDto} from '|api/sad';
import {DeliveryTypeDto} from '|api/codebook';
import {ConsignmentType, DistributionClass, EmpowermentDto, SubjectRecordDto} from '|api/commons';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {DigitalComponentCompleteDto, PaperComponentDto} from '|api/component';
import {
  CreateNewConsignmentDialogType,
  GenericOwnConsignment
} from '../../own-consignment-table/model/own-consignment-model';
import {
  isOwnInternalDigitalConsignment,
  isOwnInternalPaperConsignment,
} from '../../own-consignment-table/own-consignment-workflow.service';
import {ConsignmentDeliveryFilingType, OwnConsignmentWorkflowAction} from './consignment.model';
import {
  DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE,
  sumDigitalComponentsSize,
  sumPaperComponentsRenditionSize
} from '../../shared-document.utils';
import {injectModalData} from '../../../../lib/modals';
import {Directive} from '@angular/core';

export function isEmailConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnDigitalConsignmentDto>)?.consigneeEmail && (c as Nullable<OwnDigitalConsignmentDto>)?.body;
}

export function isDataboxConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c as Nullable<OwnDigitalConsignmentDto>)?.consigneeDataBox;
}

export function isInternalConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c?.consignmentType === ConsignmentType.OWN_PAPER_INTERNAL || c?.consignmentType === ConsignmentType.OWN_DIGITAL_INTERNAL);
}

export function isFaxConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c?.consignmentType === ConsignmentType.TELEFAX);
}

export function isPortalConsignment(c: Nullable<GenericOwnConsignment>) {
  return (c?.consignmentType === ConsignmentType.PORTAL);
}

export function isPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return (
    c?.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS &&
    (c as Nullable<OwnPaperConsignmentDto>)?.consigneeAddress
  );
}

export function isOnPlaceConsignment(c: Nullable<GenericOwnConsignment>) {
  return (
    c?.consignmentType === ConsignmentType.OWN_PAPER_ON_PLACE &&
    (c as Nullable<OwnPaperConsignmentDto>)?.consigneeAddress
  );
}

export function isPaperConsignmentByDistClass(distributionClassForDispatch: DistributionClass): boolean {
  return (
    distributionClassForDispatch === DistributionClass.ADDRESS_PLACE ||
    distributionClassForDispatch === DistributionClass.ON_PLACE
  );
}

export function isEmailConsignmentByDistClass(distributionClassForDispatch: DistributionClass): boolean {
  return distributionClassForDispatch === DistributionClass.EMAIL_BOX;
}

export function isDataboxConsignmentByDistClass(distributionClassForDispatch: DistributionClass): boolean {
  return distributionClassForDispatch === DistributionClass.ISDS_BOX;
}

export function isInternalConsignmentByDistClass(distributionClassForDispatch: DistributionClass): boolean {
  return distributionClassForDispatch === DistributionClass.INTERNAL;
}

export function isInternalPaperDispatch(deliveryTypeForDispatch: DeliveryTypeDto): boolean {
  return deliveryTypeForDispatch ? !deliveryTypeForDispatch.forDigital : false;
}

export function isOfficeDeskConsignmentByModalData(type: ConsignmentDialogType,
                                                   distributionClassForDispatch: DistributionClass,
                                                   selectedDistributionClass: DistributionClass
): boolean {
  return type === ConsignmentDialogType.CREATE_OFFICE_DESK_CONSIGNMENT ||
    distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD ||
    selectedDistributionClass === DistributionClass.OFFICIAL_BOARD;
}

export enum ConsignmentDialogResultType {
  SAVED = 'SAVED',
  SAVED_WITH_PROOF_OF_DELIVERY = 'SAVED_WITH_PROOF_OF_DELIVERY',
  HANDED_OVER = 'HANDED_OVER',
  MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE = 'MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE',
  DELETED = 'DELETED',
}

export enum ConsignmentDialogType {
  CREATE_CONSIGNMENT = 'CREATE_CONSIGNMENT',
  CREATE_OFFICE_DESK_CONSIGNMENT = 'CREATE_OFFICE_DESK_CONSIGNMENT',
  EDIT_CONSIGNMENT = 'EDIT_CONSIGNMENT',
  CREATE_INTERNAL_CONSIGNMENT = 'CREATE_INTERNAL_CONSIGNMENT',
}

export interface ConsignmentDocumentData {
  empowerment?: Nullable<EmpowermentDto>;
  refNumber?: Nullable<string>;
  subject?: Nullable<string>;
  id: number;
  parentFileRefNumber?: Nullable<string>;
}

/**
 * preselectedConsignees - used only for create mode
 */
export interface ConsignmentDialogData {
  documentData: ConsignmentDocumentData;
  type: ConsignmentDialogType;
  consignment: Nullable<GenericOwnConsignment>;
  preselectedConsignees?: SubjectRecordDto[],
  requestTableReloadCb?: () => void;
}

export interface ConsignmentDialogResult {
  resultType: ConsignmentDialogResultType | OwnConsignmentWorkflowAction;
  consignment: OwnPaperConsignmentDto;
}

export interface OfficeDeskSpecifics {
  deliveryType: Nullable<DeliveryTypeDto>;
  dispatchOfficeDistributionNodeId: Nullable<number>;
  isPublicPosting: boolean;
}

export interface ConsignmentDispatchDelivery {
  distributionClassForDispatch: Nullable<DistributionClass>;
  selectedDistributionClass: Nullable<DistributionClass>;
  deliveryTypeForDispatch: Nullable<DeliveryTypeDto>;
  deliveryTypeIdForDispatch: Nullable<number>;
}

@Directive()
export abstract class AbstractConsignmentDialogData {

  protected modalData = injectModalData<ConsignmentDialogData>();

  genericFieldsForm = new IczFormGroup({
    deliveryTypeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  _specificFieldsForm!: OwnConsignmentSpecificFieldsForm;

  get specificFieldsForm() {
    return this._specificFieldsForm;
  }

  set specificFieldsForm(form) {
    this._specificFieldsForm = form;
  }

  isNew = false;
  isOwner = false;

  disableEdit = false; // used to disable consignment form for come consignment types
  disableRecordDeliveryResult = false;
  disableCirculation = false;

  formGroupsToCheck!: string[];

  createNewConsignmentDialogType = CreateNewConsignmentDialogType.ALLOW_ALL_DISPATCH_METHODS;

  officeDeskSpecifics: OfficeDeskSpecifics = {deliveryType: null, isPublicPosting: false, dispatchOfficeDistributionNodeId: null};

  forDispatch: ConsignmentDispatchDelivery = {
    distributionClassForDispatch: null,
    deliveryTypeIdForDispatch: null,
    deliveryTypeForDispatch: null,
    selectedDistributionClass: null,
  };

  deliveryFilingType: Nullable<ConsignmentDeliveryFilingType>;

  selectedComponents: EsslComponentDto[] = [];

  get isPaperConsignmentByDistClass(): boolean {
    return isPaperConsignmentByDistClass(this.forDispatch.distributionClassForDispatch!);
  }

  get isEmailConsignmentByDistClass(): boolean {
    return isEmailConsignmentByDistClass(this.forDispatch.distributionClassForDispatch!);
  }

  get isEmailOutsideFilingOffice() {
    return this.forDispatch.deliveryTypeForDispatch?.code === DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE;
  }

  get isDataboxConsignmentByDistClass(): boolean {
    return isDataboxConsignmentByDistClass(this.forDispatch.distributionClassForDispatch!);
  }

  get isInternalConsignmentByDistClass(): boolean {
    return isInternalConsignmentByDistClass(this.forDispatch.distributionClassForDispatch!);
  }

  get isInternalPaperDispatch(): boolean {
    return isInternalPaperDispatch(this.forDispatch.deliveryTypeForDispatch!);
  }

  get isDigitalConsignmentByDistClass(): boolean {
    return this.isEmailConsignmentByDistClass || this.isDataboxConsignmentByDistClass;
  }

  get isOfficeDeskConsignment(): boolean {
    return isOfficeDeskConsignmentByModalData(
      this.modalData.type,
      this.forDispatch.distributionClassForDispatch!,
      this.forDispatch.selectedDistributionClass!);
  }

  get isInternalPaperConsignment(): boolean {
    return isOwnInternalPaperConsignment(this.modalData.consignment);
  }

  get isInternalDigitalConsignment(): boolean {
    return isOwnInternalDigitalConsignment(this.modalData.consignment);
  }

  get isFaxConsignment(): boolean {
    return isFaxConsignment(this.modalData.consignment);
  }

  get isPortalConsignment(): boolean {
    return isPortalConsignment(this.modalData.consignment);
  }

  get ownPaperConsignment(): OwnPaperConsignmentDto {
    return this.modalData.consignment as OwnPaperConsignmentDto;
  }

  get isCreateRegularConsignmentDialogType(): boolean {
    return this.modalData.type === ConsignmentDialogType.CREATE_CONSIGNMENT;
  }

  get isCreateOfficeDeskConsignmentDialogType(): boolean {
    return this.modalData.type === ConsignmentDialogType.CREATE_OFFICE_DESK_CONSIGNMENT;
  }

  get isCreateInternalConsignmentDialogType(): boolean {
    return this.modalData.type === ConsignmentDialogType.CREATE_INTERNAL_CONSIGNMENT;
  }

  get isCreateAnyConsignmentDialogType(): boolean {
    return this.isCreateRegularConsignmentDialogType ||
      this.isCreateOfficeDeskConsignmentDialogType ||
      this.isCreateInternalConsignmentDialogType;
  }

  get isEditConsignmentDialogType(): boolean {
    return this.modalData.type === ConsignmentDialogType.EDIT_CONSIGNMENT;
  }

  get usePaperConsignmentSpecification() {
    return this.forDispatch.distributionClassForDispatch === DistributionClass.ADDRESS_PLACE ||
      this.forDispatch.distributionClassForDispatch === DistributionClass.ON_PLACE ||
      this.forDispatch.distributionClassForDispatch === DistributionClass.INTERNAL;
  }

  get formValue() {
    return {
      ...this.genericFieldsForm.getRawValue(),
      ...this.specificFieldsForm.getRawValue(),
    };
  }

  get selectedComponentIds() {
    return this.selectedComponents.map(c => c.id!);
  }

  get mainComponentId() {
    // todo(rb) ask UX how to select a component as MAIN
    return this.selectedComponentIds[0];
  }

  get dispatchOfficeDistributionNodeId(): Nullable<number> {
    if (this.forDispatch.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD) {
      return this.officeDeskSpecifics.dispatchOfficeDistributionNodeId;
    }
    else if (this.forDispatch.distributionClassForDispatch === DistributionClass.EMAIL_BOX) {
      return (this.specificFieldsForm as OwnEmailConsignmentForm).getRawValue().dispatchOfficeDistributionNodeId;
    }
    else if (this.forDispatch.distributionClassForDispatch === DistributionClass.ISDS_BOX) {
      return (this.specificFieldsForm as OwnDataboxConsignmentForm).getRawValue().dispatchOfficeDistributionNodeId;
    }
    else {
      return null;
    }
  }

  get deliveryTypeId(): Nullable<number> {
    return this.genericFieldsForm.get('deliveryTypeId')!.value;
  }

  get selectedComponentsSize(): number {
    if (!this.selectedComponents?.length) {
      return 0;
    }
    else {
      return (
        sumDigitalComponentsSize(this.selectedComponents as DigitalComponentCompleteDto[]) +
        sumPaperComponentsRenditionSize(this.selectedComponents as PaperComponentDto[])
      );
    }
  }

}
