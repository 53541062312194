import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[iczPassedDeadlineWarning]',
})
export class PassedDeadlineWarningDirective {
  @Input({alias: 'iczPassedDeadlineWarning', required: true})
  deadlineDate!: string; // iso 8601-formatted

  @HostBinding('class.text-danger')
  get isDeadlinePassed(): boolean {
    return Number(new Date()) > Number(new Date(this.deadlineDate));
  }
}
