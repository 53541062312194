/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { IdentifierDto } from '|api/commons';
import { identifierGeneratorCheckFileReferenceNumberIsUnique } from '../fn/identifier-generator/identifier-generator-check-file-reference-number-is-unique';
import { IdentifierGeneratorCheckFileReferenceNumberIsUnique$Params } from '../fn/identifier-generator/identifier-generator-check-file-reference-number-is-unique';
import { identifierGeneratorGenerateStorageUnitNumber } from '../fn/identifier-generator/identifier-generator-generate-storage-unit-number';
import { IdentifierGeneratorGenerateStorageUnitNumber$Params } from '../fn/identifier-generator/identifier-generator-generate-storage-unit-number';
import { identifierGeneratorGetFileReferenceNumber } from '../fn/identifier-generator/identifier-generator-get-file-reference-number';
import { IdentifierGeneratorGetFileReferenceNumber$Params } from '../fn/identifier-generator/identifier-generator-get-file-reference-number';
import { identifierGeneratorGetId } from '../fn/identifier-generator/identifier-generator-get-id';
import { IdentifierGeneratorGetId$Params } from '../fn/identifier-generator/identifier-generator-get-id';
import { identifierGeneratorGetUid } from '../fn/identifier-generator/identifier-generator-get-uid';
import { IdentifierGeneratorGetUid$Params } from '../fn/identifier-generator/identifier-generator-get-uid';
import { UniqueIdentifierDto } from '|api/commons';


/**
 * Contains endpoints related to Identifier Generators
 */
@Injectable({ providedIn: 'root' })
export class ApiIdentifierGeneratorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `identifierGeneratorGenerateStorageUnitNumber()` */
  static readonly IdentifierGeneratorGenerateStorageUnitNumberPath = '/records/identifier-generator/storage-unit-number/generate';

  /**
   * Generates Storage Unit Number for a file.
   *
   * Generates and sets file storage unit for a given file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorGenerateStorageUnitNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGenerateStorageUnitNumber$Response(params?: IdentifierGeneratorGenerateStorageUnitNumber$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IdentifierDto>> {
    return identifierGeneratorGenerateStorageUnitNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates Storage Unit Number for a file.
   *
   * Generates and sets file storage unit for a given file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorGenerateStorageUnitNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGenerateStorageUnitNumber(params?: IdentifierGeneratorGenerateStorageUnitNumber$Params|null|undefined, context?: HttpContext): Observable<IdentifierDto> {
    return this.identifierGeneratorGenerateStorageUnitNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdentifierDto>): IdentifierDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorCheckFileReferenceNumberIsUnique()` */
  static readonly IdentifierGeneratorCheckFileReferenceNumberIsUniquePath = '/records/identifier-generator/file-ref-number/check-is-unique';

  /**
   * Check File Reference Number for uniqueness.
   *
   * Check File Reference Number for uniqueness.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorCheckFileReferenceNumberIsUnique()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorCheckFileReferenceNumberIsUnique$Response(params: IdentifierGeneratorCheckFileReferenceNumberIsUnique$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return identifierGeneratorCheckFileReferenceNumberIsUnique(this.http, this.rootUrl, params, context);
  }

  /**
   * Check File Reference Number for uniqueness.
   *
   * Check File Reference Number for uniqueness.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorCheckFileReferenceNumberIsUnique$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorCheckFileReferenceNumberIsUnique(params: IdentifierGeneratorCheckFileReferenceNumberIsUnique$Params, context?: HttpContext): Observable<void> {
    return this.identifierGeneratorCheckFileReferenceNumberIsUnique$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorGetUid()` */
  static readonly IdentifierGeneratorGetUidPath = '/records/identifier-generator/uid/identifier/{entityType}/{entityId}';

  /**
   * Previously generated (or set) UID for an entity.
   *
   * Returns the previously generated (or set) UID for a given entity. Or empty 400 response if no UID has not yet been set for the entity, orUID identifiers are inapplicable to the type of entities.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorGetUid()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGetUid$Response(params: IdentifierGeneratorGetUid$Params, context?: HttpContext): Observable<StrictHttpResponse<UniqueIdentifierDto>> {
    return identifierGeneratorGetUid(this.http, this.rootUrl, params, context);
  }

  /**
   * Previously generated (or set) UID for an entity.
   *
   * Returns the previously generated (or set) UID for a given entity. Or empty 400 response if no UID has not yet been set for the entity, orUID identifiers are inapplicable to the type of entities.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorGetUid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGetUid(params: IdentifierGeneratorGetUid$Params, context?: HttpContext): Observable<UniqueIdentifierDto> {
    return this.identifierGeneratorGetUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<UniqueIdentifierDto>): UniqueIdentifierDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorGetId()` */
  static readonly IdentifierGeneratorGetIdPath = '/records/identifier-generator/id/identifier/{entityType}/{entityId}';

  /**
   * Previously generated (or set) ID for an entity.
   *
   * Returns the previously generated (or set) ID for a given entity. Or empty 400 response if no ID has not yet been set for the entity, orID identifiers are inapplicable to the type of entities.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorGetId()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGetId$Response(params: IdentifierGeneratorGetId$Params, context?: HttpContext): Observable<StrictHttpResponse<IdentifierDto>> {
    return identifierGeneratorGetId(this.http, this.rootUrl, params, context);
  }

  /**
   * Previously generated (or set) ID for an entity.
   *
   * Returns the previously generated (or set) ID for a given entity. Or empty 400 response if no ID has not yet been set for the entity, orID identifiers are inapplicable to the type of entities.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorGetId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGetId(params: IdentifierGeneratorGetId$Params, context?: HttpContext): Observable<IdentifierDto> {
    return this.identifierGeneratorGetId$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdentifierDto>): IdentifierDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorGetFileReferenceNumber()` */
  static readonly IdentifierGeneratorGetFileReferenceNumberPath = '/records/identifier-generator/file-ref-number/identifier/{fileId}';

  /**
   * Previously generated (or set) File Reference Number for a file.
   *
   * Returns the previously generated (or set) file reference number for a given file. Or empty 400 response if no file reference number has not yet been set for the file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorGetFileReferenceNumber()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGetFileReferenceNumber$Response(params: IdentifierGeneratorGetFileReferenceNumber$Params, context?: HttpContext): Observable<StrictHttpResponse<IdentifierDto>> {
    return identifierGeneratorGetFileReferenceNumber(this.http, this.rootUrl, params, context);
  }

  /**
   * Previously generated (or set) File Reference Number for a file.
   *
   * Returns the previously generated (or set) file reference number for a given file. Or empty 400 response if no file reference number has not yet been set for the file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorGetFileReferenceNumber$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorGetFileReferenceNumber(params: IdentifierGeneratorGetFileReferenceNumber$Params, context?: HttpContext): Observable<IdentifierDto> {
    return this.identifierGeneratorGetFileReferenceNumber$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdentifierDto>): IdentifierDto => r.body)
    );
  }

}
