/* tslint:disable */
/* eslint-disable */

/**
 * Origin type
 */
export enum DigitalComponentOrigin {
  DIGITAL_BORN = 'DIGITAL_BORN',
  DIGITAL_CONVERSION = 'DIGITAL_CONVERSION',
  DIGITAL_CONVERSION_ORIGINAL = 'DIGITAL_CONVERSION_ORIGINAL',
  DIGITAL_AUTH_CONVERSION = 'DIGITAL_AUTH_CONVERSION',
  DIGITAL_DIGITIZATION = 'DIGITAL_DIGITIZATION',
  MIGRATION = 'MIGRATION',
  MIGRATION_CLAUSE = 'MIGRATION_CLAUSE',
  ANONYMIZATION = 'ANONYMIZATION'
}
