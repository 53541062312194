/* tslint:disable */
/* eslint-disable */

/**
 * Status of an own consignment.
 */
export enum OwnConsignmentStatus {
  CANCELLED = 'CANCELLED',
  REJECTED = 'REJECTED',
  TO_HANDOVER = 'TO_HANDOVER',
  TO_TAKEOVER = 'TO_TAKEOVER',
  TAKEN_OVER = 'TAKEN_OVER',
  IN_DISTRIBUTION = 'IN_DISTRIBUTION',
  DISPATCHED = 'DISPATCHED',
  DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION = 'DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION',
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED',
  TO_BE_POSTED = 'TO_BE_POSTED',
  POSTED = 'POSTED',
  UNPOSTED = 'UNPOSTED'
}
