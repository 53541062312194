<form [formGroup]="form">
  <icz-form-field
    formControlName="email"
    label="E-mail"
  ></icz-form-field>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              (onAction)="submit()"
              primary label="Uložit"
  ></icz-button>
  <icz-button rightButtons
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
