/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { IssdDto } from '../models/issd-dto';
import { issdOauthCreate } from '../fn/issd-oauth/issd-oauth-create';
import { IssdOauthCreate$Params } from '../fn/issd-oauth/issd-oauth-create';
import { issdOauthDeleteById } from '../fn/issd-oauth/issd-oauth-delete-by-id';
import { IssdOauthDeleteById$Params } from '../fn/issd-oauth/issd-oauth-delete-by-id';
import { issdOauthFind } from '../fn/issd-oauth/issd-oauth-find';
import { IssdOauthFind$Params } from '../fn/issd-oauth/issd-oauth-find';
import { issdOauthFindAll } from '../fn/issd-oauth/issd-oauth-find-all';
import { IssdOauthFindAll$Params } from '../fn/issd-oauth/issd-oauth-find-all';
import { issdOauthFindAllTypes } from '../fn/issd-oauth/issd-oauth-find-all-types';
import { IssdOauthFindAllTypes$Params } from '../fn/issd-oauth/issd-oauth-find-all-types';
import { issdOauthGetById } from '../fn/issd-oauth/issd-oauth-get-by-id';
import { IssdOauthGetById$Params } from '../fn/issd-oauth/issd-oauth-get-by-id';
import { issdOauthUpdate } from '../fn/issd-oauth/issd-oauth-update';
import { IssdOauthUpdate$Params } from '../fn/issd-oauth/issd-oauth-update';
import { IssdTypeDto } from '../models/issd-type-dto';
import { PageIssdDto } from '../models/page-issd-dto';


/**
 * API for management of issds (external applications).
 */
@Injectable({ providedIn: 'root' })
export class ApiIssdOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `issdOauthGetById()` */
  static readonly IssdOauthGetByIdPath = '/oauth/issd/{id}';

  /**
   * Returns issd by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthGetById$Response(params: IssdOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdDto>> {
    return issdOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns issd by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthGetById(params: IssdOauthGetById$Params, context?: HttpContext): Observable<IssdDto> {
    return this.issdOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdDto>): IssdDto => r.body)
    );
  }

  /** Path part for operation `issdOauthUpdate()` */
  static readonly IssdOauthUpdatePath = '/oauth/issd/{id}';

  /**
   * Update issd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdOauthUpdate$Response(params: IssdOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdDto>> {
    return issdOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update issd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdOauthUpdate(params: IssdOauthUpdate$Params, context?: HttpContext): Observable<IssdDto> {
    return this.issdOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdDto>): IssdDto => r.body)
    );
  }

  /** Path part for operation `issdOauthDeleteById()` */
  static readonly IssdOauthDeleteByIdPath = '/oauth/issd/{id}';

  /**
   * Delete Issd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthDeleteById$Response(params: IssdOauthDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return issdOauthDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Issd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthDeleteById(params: IssdOauthDeleteById$Params, context?: HttpContext): Observable<void> {
    return this.issdOauthDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `issdOauthCreate()` */
  static readonly IssdOauthCreatePath = '/oauth/issd';

  /**
   * Creates new issd
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdOauthCreate$Response(params: IssdOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdDto>> {
    return issdOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new issd
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdOauthCreate(params: IssdOauthCreate$Params, context?: HttpContext): Observable<IssdDto> {
    return this.issdOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdDto>): IssdDto => r.body)
    );
  }

  /** Path part for operation `issdOauthFindAllTypes()` */
  static readonly IssdOauthFindAllTypesPath = '/oauth/issd/types';

  /**
   * Find all Issd types that are allowed and not used for a given organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthFindAllTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthFindAllTypes$Response(params?: IssdOauthFindAllTypes$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdTypeDto>>> {
    return issdOauthFindAllTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Issd types that are allowed and not used for a given organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthFindAllTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthFindAllTypes(params?: IssdOauthFindAllTypes$Params|null|undefined, context?: HttpContext): Observable<Array<IssdTypeDto>> {
    return this.issdOauthFindAllTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdTypeDto>>): Array<IssdTypeDto> => r.body)
    );
  }

  /** Path part for operation `issdOauthFind()` */
  static readonly IssdOauthFindPath = '/oauth/issd/search-api';

  /**
   * Finds all issd instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthFind$Response(params?: IssdOauthFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageIssdDto>> {
    return issdOauthFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all issd instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthFind(params?: IssdOauthFind$Params|null|undefined, context?: HttpContext): Observable<PageIssdDto> {
    return this.issdOauthFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageIssdDto>): PageIssdDto => r.body)
    );
  }

  /** Path part for operation `issdOauthFindAll()` */
  static readonly IssdOauthFindAllPath = '/oauth/issd/findAll';

  /**
   * Finds all issd instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdOauthFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthFindAll$Response(params?: IssdOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdDto>>> {
    return issdOauthFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all issd instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdOauthFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdOauthFindAll(params?: IssdOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<IssdDto>> {
    return this.issdOauthFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdDto>>): Array<IssdDto> => r.body)
    );
  }

}
