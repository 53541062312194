import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CrossReferenceWithDetailDto, DocumentDto, FileDto} from '|api/document';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {getBigObjectIcon, isDocumentObject, isFileObject} from '../shared-document.utils';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {EsslObject} from '../model/entity.model';

@Component({
  selector: 'icz-entity-item',
  templateUrl: './entity-item.component.html',
  styleUrls: ['./entity-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityItemComponent {
  @Input({required: true})
  entity: Nullable<DocumentDto | FileDto | CrossReferenceWithDetailDto>;
  @Input()
  showFileHandlingType = false;

  get entityLink() {
    let entityUriDiscriminator: Nullable<DocumentsRoute>;

    if (isDocumentObject(this.entity as EsslObject)) {
      entityUriDiscriminator = DocumentsRoute.DOCUMENT;
    }
    else if (isFileObject(this.entity as EsslObject)) {
      entityUriDiscriminator = DocumentsRoute.FILE;
    }

    return createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      entityUriDiscriminator ?? 'UNKNOWN_ENTITY',
      this.entity?.id ?? ''
    );
  }

  get entityIcon() {
    return getBigObjectIcon(this.entity as EsslObject);
  }

  get entityIdentifier() {
    if (this.entity) {
      if (Object.keys(this.entity).includes('entityReferringFromId')) {
        return {uid: ''};
      } else {
        return (this.entity as DocumentDto).uid;
      }
    } else {
      return {uid: ''};
    }
  }

  isFile(entity: Nullable<DocumentDto | FileDto | CrossReferenceWithDetailDto>): entity is FileDto {
    return isFileObject(entity as EsslObject);
  }
}
