/* eslint-disable @typescript-eslint/ban-types */
import {Injectable} from '@angular/core';
import {DialogPosition, MatDialogRef, MatDialogState} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ComponentModalComponent} from '../components/dialogs/component-modal/component-modal.component';

export type ModalTitleOrContext = (
  {title: string, titleContext?: Record<string, string>} |
  {title?: string, titleContext: Record<string, string>}
);

function setModalTitle(this: IczModalRef<unknown>, title: ModalTitleOrContext): void {
  if (title.title) {
    this.componentInstance.dialogData.modalOptions.titleTemplate = title.title;
  }
  if (title.titleContext) {
    this.componentInstance.dialogData.modalOptions.titleTemplateContext = title.titleContext;
  }
}

function toggleMaximize(this: IczModalRef<unknown>): void {
  if (!this.isMaximized) {
    this.updateSize('100vw', '100vh');
    this.isMaximized = true;
  }
  else {
    this.updateSize(this.originalWidth, this.originalHeight);
    this.isMaximized = false;
  }
}

/**
 * Basically just a decorated MatDialogRef:
 * - it is an abstract class because I didn't want to inject raw modal ref token using @Inject decorator.
 * - abstract method `forceClose` is populated inside `iczModalRefFactory`.
 * - the rest of abstract methods is in fact implemented in `MatDialogRef`, however I need them here otherwise Angular compiler starts complaining.
 */
@Injectable()
export abstract class IczModalRef<R> {

  abstract originalWidth: string;
  abstract originalHeight: string;

  abstract isMaximized: boolean;

  /*
   * CheckUnsavedFormDialogService will override IczModalRef#close with its own
   * decorated version which will ask for saving changes. That is undesirable in
   * case we need to force-close our modals on various critical events in the application.
   */
  abstract forceClose(modalResult?: R | undefined): void;

  abstract setModalTitle(title: ModalTitleOrContext): void;

  abstract addPanelClass(classes: string | string[]): this;

  abstract afterClosed(): Observable<R | undefined>;

  abstract afterOpened(): Observable<void>;

  abstract backdropClick(): Observable<MouseEvent>;

  abstract beforeClosed(): Observable<R | undefined>;

  abstract close(dialogResult?: R | undefined): void;

  abstract componentInstance: ComponentModalComponent<unknown>;

  abstract disableClose: boolean | undefined;

  abstract getState(): MatDialogState;

  abstract id: string;

  abstract keydownEvents(): Observable<KeyboardEvent>;

  abstract removePanelClass(classes: string | string[]): this;

  abstract updatePosition(position: DialogPosition | undefined): this;

  abstract updateSize(width: string | undefined, height: string | undefined): this;

  abstract toggleMaximize(): void;
}

// eslint-disable-next-line @typescript-eslint/ban-types -- here we need to work with the original
export function iczModalRefFactory(modalRef: MatDialogRef<unknown, unknown>) {
  const castedModalRef = modalRef as unknown as IczModalRef<unknown>;

  castedModalRef.forceClose = modalRef.close;

  castedModalRef.setModalTitle = setModalTitle.bind(castedModalRef);

  castedModalRef.isMaximized = false;
  castedModalRef.toggleMaximize = toggleMaximize.bind(castedModalRef);

  return modalRef;
}
