<div class="row">
  @if (isToolbarLoading) {
    <icz-spinner
      class="mt-4 mr-8"
      [diameter]="30"
    ></icz-spinner>
  }
  <icz-button-collection
    size="large"
    [groupButtons]="true"
    [hideBorders]="true"
    [disableAll]="disableAllButtons || isToolbarLoading"
    [buttons]="toolbarButtons"
    [areCollapsed]="isToolbarCollapsed$ | async"
    (buttonClicked)="buttonClicked($event)"
  ></icz-button-collection>
</div>
