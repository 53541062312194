import {ConsignmentType, OwnConsignmentStatus} from '|api/commons';
import {IczTableDataSource} from '../../../table/table.datasource';
import {GenericOwnConsignment} from '../model/own-consignment-model';
import {OwnConsignmentsSearchService} from '../../../../services/own-consignments-search.service';


export class OwnConsignmentsForDispatchOfficerDatasource extends IczTableDataSource<GenericOwnConsignment> {
  constructor(
    searchService: OwnConsignmentsSearchService,
    states: Array<OwnConsignmentStatus>,
    dispatchOfficeDistributionNodeIds: number[],
    consignmentTypes: Nullable<ConsignmentType[]>,
  ) {
    super(searchParams => searchService.findOwnConsignmentsForDispatchOfficer(searchParams, states, dispatchOfficeDistributionNodeIds, consignmentTypes));
  }
}
