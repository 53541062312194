<icz-validation-errors-list [control]="control">
  <div class="wrapper" [formGroup]="_formGroup" cdkOverlayOrigin #timePopoverOrigin="cdkOverlayOrigin">
    <icz-form-field
      class="grow"
      formControlName="valueInput"
      [label]="label"
      (click)="openPopover()"
      (keydown)="onInputFieldKeydown($event)"
      (blur)="valueInputBlurred()"
      [fieldDisabled]="fieldDisabled"
      [placeholder]="placeholder"
      iczInputMask="99:99"
      >
      <div class="flex" suffix>
        <ng-content select="[suffix]"></ng-content>
        @if (!fieldDisabled) {
          <icz-icon class="ml-8 cursor-pointer" size="small" svgIcon="time"></icz-icon>
        }
      </div>
    </icz-form-field>

    <icz-popover [isOpen]="_isOpen" [overlayOrigin]="timePopoverOrigin" (onClose)="closePopover()">
      @if (_isOpen) {
        <icz-time-selector-grid
          data-cy="timeSelector"
          (selectionChange)="onTimePick($event)"
        ></icz-time-selector-grid>
      }
    </icz-popover>
  </div>
</icz-validation-errors-list>
