import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {AnyComponent} from '../../model';

type LoaderId = string|number; // expected enum values
const DEFAULT_LOADER_ID: LoaderId = '_DEFAULT';

/**
 * Used for centrally setting/unsetting loading flags for components or services.
 * Should be connected to global HTTP interceptor which will unset
 * the loading flag in case an error happens.
 *
 * FAQ:
 * Q: How to change loading flag for a parent component?
 * A: Inject the parent component as a dependency to your constructor and
 *    call loadingService.setLoading(parentComponent, STATE)
 * .
 * Q: How to change loading flag for a child component?
 * A: Use a @ViewChild with component selector and pass the
 *    reference of the child to setLoading method
 *
 * Q: I need more loading indicators in my components. How to?
 * A: Assign a LoaderId to each indicator and then use them
 *    with calls to methods of this service.
 */
@Injectable(
  /*
    Provide it in your SharedModule, do not use
    'root', otherwise isLoading$ will not work
    when placed into instance property initializer!
  */
)
export class LoadingIndicatorService { // also known as RotatingCabbageService
  // WeakMap will remove components from itself upon
  // their garbage collection by JS runtime
  private loadingStates = new WeakMap<AnyComponent, Map<LoaderId, boolean>>();
  // Both loading state maps are kept in-sync such that
  // they can be used by both sync and async methods.
  private loadingStates$ = new WeakMap<AnyComponent, Map<LoaderId, BehaviorSubject<boolean>>>();

  // observable creation operator
  // loaderId can be used when there are multiple loading indicators associated to a single context
  doLoading<V>(source$: Observable<V>, context: AnyComponent, loaderId?: LoaderId): Observable<V> {
    this.startLoading(context, loaderId);

    return source$.pipe(
      finalize(() => this.endLoading(context, loaderId)),
    );
  }

  isLoading(context: AnyComponent, loaderId?: LoaderId): boolean {
    const loaderStates = this.loadingStates.get(context);

    if (!loaderStates) return false;
    else return loaderStates.get(this.getLoaderId(loaderId)) ?? false;
  }

  isLoading$(context: AnyComponent, loaderId?: LoaderId): Nullable<Observable<boolean>> {
    const coalescedLoaderId = this.getLoaderId(loaderId);

    if (!this.hasLoadingStates(context, coalescedLoaderId)) {
      this.setLoadingState(context, false, coalescedLoaderId);
    }

    return this.loadingStates$.get(context)!.get(coalescedLoaderId);
  }

  startLoading(context: AnyComponent, loaderId?: LoaderId): void {
    this.setLoadingState(context, true, this.getLoaderId(loaderId));
  }

  endLoading(context: AnyComponent, loaderId?: LoaderId): void {
    this.setLoadingState(context, false, this.getLoaderId(loaderId));
  }

  clearLoadings(): void {
    this.loadingStates = new WeakMap<AnyComponent, Map<LoaderId, boolean>>();
    this.loadingStates$ = new WeakMap<AnyComponent, Map<LoaderId, BehaviorSubject<boolean>>>();
  }

  private setLoadingState(context: AnyComponent, state: boolean, loaderId: LoaderId): void {
    if (!this.hasLoadingStates(context, loaderId)) {
      if (this.hasContextLoadingState(context)) {
        this.loadingStates.get(context)!.set(loaderId, state);
        this.loadingStates$.get(context)!.set(loaderId, new BehaviorSubject<boolean>(state));
      }
      else {
        this.loadingStates.set(context, new Map<LoaderId, boolean>([
          [loaderId, state]
        ]));
        this.loadingStates$.set(context, new Map<LoaderId, BehaviorSubject<boolean>>([
          [loaderId, new BehaviorSubject<boolean>(state)]
        ]));
      }
    }
    else {
      this.loadingStates.get(context)!.set(loaderId, state);
      this.loadingStates$.get(context)!.get(loaderId)!.next(state);
    }
  }

  private hasLoadingStates(context: AnyComponent, loaderId: LoaderId) {
    return this.hasContextLoadingState(context) && this.hasLoaderLoadingState(context, loaderId);
  }

  private hasContextLoadingState(context: AnyComponent) {
    return this.loadingStates.has(context) && this.loadingStates$.has(context);
  }

  private hasLoaderLoadingState(context: AnyComponent, loaderId: LoaderId) {
    return this.loadingStates.get(context)?.has(loaderId) && this.loadingStates$.get(context)?.has(loaderId);
  }

  private getLoaderId(loaderId: Nullable<LoaderId>) {
    return loaderId ?? DEFAULT_LOADER_ID;
  }
}
