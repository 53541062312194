/* tslint:disable */
/* eslint-disable */

/**
 * Language type
 */
export enum Language {
  CS = 'CS',
  SK = 'SK',
  EN = 'EN'
}
