import {Component, inject, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ErrorDialogDataService, ErrorDialogItem} from './error-dialog-data.service';
import {interpolateBackendErrorMessage} from '../../essentials/interpolate-backend-error.pipe';
import {DialogSeverity, SimpleDialogData} from '../../../core/services/dialog.models';
import {injectModalData, injectModalRef} from '../../../lib/modals';

@Component({
  selector: 'icz-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent implements OnDestroy {

  private translate = inject(TranslateService);
  protected modalRef = injectModalRef<boolean>();
  private errorDataService = inject(ErrorDialogDataService);
  protected data = injectModalData<SimpleDialogData>();

  result!: boolean;

  errorData$ = this.errorDataService.errorData$;

  ngOnDestroy(): void {
    this.errorDataService.clearData();
  }

  interpolateErrorMessages(item: ErrorDialogItem) {
    return item.validationErrors.map(err => interpolateBackendErrorMessage(this.translate, {errorCode: err.errorCode, parameters: err.parameters}));
  }

  getIconName() {
    switch (this.data?.severity) {
      case DialogSeverity.ERROR:
        return 'modal_error';
      case DialogSeverity.WARNING:
        return 'warning';
      case DialogSeverity.INFO:
      default:
        return 'modal_info';
    }
  }

}
