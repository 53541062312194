/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagePropertyDefinitionDto } from '../models/page-property-definition-dto';
import { propertyDefinitionCreate } from '../fn/property-definition/property-definition-create';
import { PropertyDefinitionCreate$Params } from '../fn/property-definition/property-definition-create';
import { PropertyDefinitionDto } from '../models/property-definition-dto';
import { propertyDefinitionFind } from '../fn/property-definition/property-definition-find';
import { PropertyDefinitionFind$Params } from '../fn/property-definition/property-definition-find';
import { propertyDefinitionUpdate } from '../fn/property-definition/property-definition-update';
import { PropertyDefinitionUpdate$Params } from '../fn/property-definition/property-definition-update';


/**
 * API for management of property definitions
 */
@Injectable({ providedIn: 'root' })
export class ApiPropertyDefinitionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `propertyDefinitionUpdate()` */
  static readonly PropertyDefinitionUpdatePath = '/management/definition/{key}';

  /**
   * Updates property definition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyDefinitionUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyDefinitionUpdate$Response(params: PropertyDefinitionUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyDefinitionDto>> {
    return propertyDefinitionUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates property definition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyDefinitionUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyDefinitionUpdate(params: PropertyDefinitionUpdate$Params, context?: HttpContext): Observable<PropertyDefinitionDto> {
    return this.propertyDefinitionUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PropertyDefinitionDto>): PropertyDefinitionDto => r.body)
    );
  }

  /** Path part for operation `propertyDefinitionCreate()` */
  static readonly PropertyDefinitionCreatePath = '/management/definition';

  /**
   * Creates new property definition
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyDefinitionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyDefinitionCreate$Response(params: PropertyDefinitionCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyDefinitionDto>> {
    return propertyDefinitionCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new property definition
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyDefinitionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyDefinitionCreate(params: PropertyDefinitionCreate$Params, context?: HttpContext): Observable<PropertyDefinitionDto> {
    return this.propertyDefinitionCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PropertyDefinitionDto>): PropertyDefinitionDto => r.body)
    );
  }

  /** Path part for operation `propertyDefinitionFind()` */
  static readonly PropertyDefinitionFindPath = '/management/definition/search-api';

  /**
   * Finds property definitions based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyDefinitionFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyDefinitionFind$Response(params?: PropertyDefinitionFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePropertyDefinitionDto>> {
    return propertyDefinitionFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds property definitions based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyDefinitionFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyDefinitionFind(params?: PropertyDefinitionFind$Params|null|undefined, context?: HttpContext): Observable<PagePropertyDefinitionDto> {
    return this.propertyDefinitionFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePropertyDefinitionDto>): PagePropertyDefinitionDto => r.body)
    );
  }

}
