<icz-popover
  [isOpen]="isOpen"
  [overlayOrigin]="popoverOrigin"
  (onClose)="isOpen = false; onClose.emit()">
  <div class="popover">
    <div class="popover--header px-16">
      <ng-content select="[popoverHeader]"></ng-content>
    </div>

    <div class="popover--body">
      <ng-content></ng-content>
    </div>

    <div class="popover--footer">
      <ng-content select="[popoverFooter]"></ng-content>
    </div>
  </div>
</icz-popover>
