import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {ConsignmentType, DeliveryResultEventType} from '|api/commons';
import {DeliveryEventDto, OwnDigitalConsignmentDto} from '|api/sad';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';

@Component({
  selector: 'icz-databox-proof-of-delivery',
  templateUrl: './databox-proof-of-delivery.component.html',
  styleUrls: ['./databox-proof-of-delivery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataboxProofOfDeliveryComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);

  @Input({required: true})
  consignment!: OwnDigitalConsignmentDto;
  @Input()
  deliveredAt: Nullable<string>;

  nullText = 'Nezadáno';

  deliveryEvents: DeliveryEventDto[] = [];

  get dispatchedAt() {
    if (this.consignment?.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
      return (this.consignment as OwnDigitalConsignmentDto).deliveryEvents?.find(de => de.eventCode === DeliveryResultEventType.EV_5)?.eventDate;
    }
    else {
      return null;
    }
  }

  ngOnInit() {
    if (this.consignment) {
      const sortedDeliveryEvents = [...this.consignment.deliveryEvents ?? []];
      sortedDeliveryEvents.sort((de1, de2) => de1.eventDate < de2.eventDate ? 1 : -1);

      this.deliveryEvents = sortedDeliveryEvents;
    }
  }

}
