import {inject, Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {
  FilterItemTree,
  FilterTreeOperator,
  isFilterTree,
  isSimpleQueryFilterTree,
  StringifiedTree
} from './filter-trees.utils';
import {TranslateService} from '@ngx-translate/core';
import {FilterItem} from './filter.types';
import {SubjectNamePipe} from '../shared-business-components/subjects/subject-name.pipe';
import {LocalizedDatePipe} from '../essentials/date.pipe';
import {LocalizedDatetimePipe} from '../essentials/datetime.pipe';
import {EmpowermentPipe} from '../essentials/empowerment.pipe';
import {AddressPipe} from '../form-elements/address.pipe';
import {getFilterItemValue} from './table-toolbar/filter-item/filter-item.component';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

export function stringifiedTreeToHumanReadableExpression(
  stringifiedTree: StringifiedTree,
  withTextFormatting: boolean,
  translateService: TranslateService,
) {
  if (isSimpleQueryFilterTree(stringifiedTree)) {
    return stringifiedTree.values.join(', ');
  }
  else {
    const translatedOperator = translateService.instant(
      stringifiedTree.operator === FilterTreeOperator.AND ?
        'A ZÁROVEŇ' :
        'NEBO'
    );

    const formattedOperator = withTextFormatting ?
      ` <span class="icz-body-strong">${translatedOperator}</span> ` :
      ` ${translatedOperator} `;

    const stringifiedParts: string[] = stringifiedTree.values.map(treeValue => {
      if (isFilterTree(treeValue)) {
        return stringifiedTreeToHumanReadableExpression(treeValue, withTextFormatting, translateService);
      }
      else {
        return treeValue;
      }
    });

    return `( ${stringifiedParts.join(formattedOperator)} )`;
  }
}

function filterItemValueToString(
  item: FilterItem,
  withTextFormatting: boolean,
  apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,
  subjectNamePipe: SubjectNamePipe,
  translateService: TranslateService,
  localizedDatePipe: LocalizedDatePipe,
  localizedDatetimePipe: LocalizedDatetimePipe,
  empowermentPipe: EmpowermentPipe,
  addressPipe: AddressPipe,
) {
  return getFilterItemValue(
    item,
    translateService,
    localizedDatePipe,
    localizedDatetimePipe,
    empowermentPipe,
    addressPipe,
    apiSubjectRecordNgElasticService,
    subjectNamePipe,
  ).pipe(map(
    filterValue => {
      const columnName = translateService.instant(item.columnLabel ?? item.label);
      const operatorName = item.value ? `${translateService.instant(item.filterOption!.label).toLowerCase()} ` : '';

      if (Array.isArray(item.value) && isNil(item.list)) {
        if (withTextFormatting) {
          filterValue = '<span class="list-loading-dark inline-block w-64 u-align-sub"></span>';
        }
        else {
          filterValue = '...';
        }
      }

      if (withTextFormatting) {
        return `${columnName} <i>${operatorName}</i>${filterValue}`;
      }
      else {
        return `${columnName} ${operatorName}${filterValue}`;
      }
    }
  ));
}

function filterItemTreeToStringifiedTree(
  filterItemTree: FilterItemTree,
  withTextFormatting: boolean,
  apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,
  subjectNamePipe: SubjectNamePipe,
  translateService: TranslateService,
  localizedDatePipe: LocalizedDatePipe,
  localizedDatetimePipe: LocalizedDatetimePipe,
  empowermentPipe: EmpowermentPipe,
  addressPipe: AddressPipe,
): Observable<StringifiedTree> {
  if (!filterItemTree.values.length) {
    return of({
      operator: filterItemTree.operator,
      values: [],
    });
  }
  else {
    if (isSimpleQueryFilterTree(filterItemTree)) {
      return forkJoin(
        (filterItemTree.values as FilterItem[]).map(f => filterItemValueToString(
          f,
          withTextFormatting,
          apiSubjectRecordNgElasticService,
          subjectNamePipe,
          translateService,
          localizedDatePipe,
          localizedDatetimePipe,
          empowermentPipe,
          addressPipe,
        ))
      ).pipe(
        map(resolvedTreeValues => ({
          operator: filterItemTree.operator,
          values: resolvedTreeValues,
        })),
      );
    }
    else {
      return forkJoin(
        filterItemTree.values.map(v => {
          if (isFilterTree(v)) {
            return filterItemTreeToStringifiedTree(
              v,
              withTextFormatting,
              apiSubjectRecordNgElasticService,
              subjectNamePipe,
              translateService,
              localizedDatePipe,
              localizedDatetimePipe,
              empowermentPipe,
              addressPipe,
            );
          }
          else {
            return filterItemValueToString(
              v,
              withTextFormatting,
              apiSubjectRecordNgElasticService,
              subjectNamePipe,
              translateService,
              localizedDatePipe,
              localizedDatetimePipe,
              empowermentPipe,
              addressPipe,
            );
          }
        })
      ).pipe(
        map(resolvedTreeValues => ({
          operator: filterItemTree.operator,
          values: resolvedTreeValues,
        }))
      );
    }
  }
}

@Injectable()
export class FilterNameService {

  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private subjectNamePipe = inject(SubjectNamePipe);
  private translateService = inject(TranslateService);
  private localizedDatePipe = inject(LocalizedDatePipe);
  private localizedDatetimePipe = inject(LocalizedDatetimePipe);
  private empowermentPipe = inject(EmpowermentPipe);
  private addressPipe = inject(AddressPipe);

  getFilterNameObs(
    activeFilters$: Observable<FilterItemTree>,
    searchTerm$: Observable<Nullable<string>>,
    withFormatting: boolean,
  ) {
    return combineLatest([
      activeFilters$,
      searchTerm$,
    ]).pipe(
      switchMap(([activeFilters, searchTerm]) => {
        return combineLatest([
          of(searchTerm),
          filterItemTreeToStringifiedTree(
            activeFilters,
            withFormatting,
            this.apiSubjectRecordNgElasticService,
            this.subjectNamePipe,
            this.translateService,
            this.localizedDatePipe,
            this.localizedDatetimePipe,
            this.empowermentPipe,
            this.addressPipe,
          )
        ]);
      }),
      map(([searchTerm, stringifiedTree]) => {
        return [
          searchTerm,
          stringifiedTreeToHumanReadableExpression(stringifiedTree, withFormatting, this.translateService),
        ];
      }),
      map(([searchTerm, humanReadableExpression]) => {
        if (searchTerm) {
          const formattedSearchTermDescription = `${this.translateService.instant('Hledat')}: ${searchTerm}`;

          if (humanReadableExpression) {
            return `${formattedSearchTermDescription}, ${humanReadableExpression}`;
          }
          else {
            return formattedSearchTermDescription;
          }
        }
        else {
          return humanReadableExpression;
        }
      }),
    );
  }

}
