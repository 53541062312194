<form [formGroup]="form">
  <div (click)="openPermissionSelector()" #permissionAutocomplete="cdkOverlayOrigin" cdkOverlayOrigin>
    <icz-readonly-field class="pb-0" [options]="optionForField" [value]="valueForField" placeholder="Zvolte oprávnění" [withWhiteBackground]="!fieldDisabled">
      @if (showInvertedStatusIcon) {
        <icz-icon
          suffix
          [iczTooltip]="invertedTooltip"
          size="small"
          class="mr-16"
          [svgIcon]="invertedStatusIcon"
        ></icz-icon>
      }
      @if (!fieldDisabled) {
        <icz-icon class="cursor-pointer" suffix size="small" svgIcon="expand_more"></icz-icon>
      }
    </icz-readonly-field>
  </div>
  <icz-permission-selector-popup
    [popoverOrigin]="permissionAutocomplete"
    [isOpened]="(permissionSelectorOpened$ | async)!"
    (onClose)="closePermissionSelector($event)"
    (permissionsChanged)="handlePermissionChange($event)"
    [permissionOptions]="$any(form.value)"
    [entityType]="entityType"
  ></icz-permission-selector-popup>
</form>
