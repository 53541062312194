<icz-filter-item
  [useAsFormElement]="true"
  [autoOpen]="false"
  [isStatic]="isStatic"
  [isLoading]="isListLoading"
  [item]="item"
  [isDisabled]="fieldDisabled ?? false"
  [deferredFilterList]="getDeferredFilterList()"
  (cancelClicked)="cancelClicked.next($event)"
  (valueChanged)="valueChanged($event)">
</icz-filter-item>
