import {locateOptionByValue, Option} from '../../../model';
import {DocumentDto, FileDto} from '|api/document';
import {ChangeDetectorRef, Directive, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormAutocompleteComponent} from '../../form-elements/form-autocomplete/form-autocomplete.component';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory
} from '../../form-elements/form-autocomplete/form-autocomplete.model';
import {Observable, of, pipe} from 'rxjs';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {ObjectClass} from '|api/commons';
import {FilterOperator, FilterParam, GlobalOperator, SearchParams} from '../../../services/search-api.service';
import {HistoryService} from '../../../services/history.service';
import {DocumentSearchService} from '../../../services/document-search.service';
import {TranslateService} from '@ngx-translate/core';
import {getObjectIcon} from '../shared-document.utils';
import {Page} from '../../../api';
import {FilterTreeOperator} from '../../table/filter-trees.utils';

const RECENTLY_VISITED_OPTION_VALUE = '__recentlyVisitedFiles' as const;
type RecentlyVisitedObjectOption<T extends DocumentDto|FileDto> = Option<typeof RECENTLY_VISITED_OPTION_VALUE | number, T>;
type ObjectOption<T extends DocumentDto|FileDto> = Option<number, T>;

@Directive()
export abstract class AbstractObjectSelectorComponent<T extends DocumentDto|FileDto> implements OnInit {

  protected historyService = inject(HistoryService);
  protected searchService = inject(DocumentSearchService);
  protected translateService = inject(TranslateService);
  protected cd = inject(ChangeDetectorRef);

  @ViewChild('objectSelector')
  private objectSelector!: FormAutocompleteComponent;

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;
  @Input()
  excludeObjectWithId: Nullable<number>;
  @Output()
  objectSelected = new EventEmitter<T>();

  recentlyVisitedObjectsOptions: RecentlyVisitedObjectOption<T>[] = [];

  makeObjectSearchOptionsDefinition: OptionsDefinitionFactory = (options$, strForSearch) => {
    const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

    defaultDefinition.searchtermToOptionsOperator = pipe(
      debounceTime(300),
      switchMap(searchTerm => {
        if (searchTerm.length >= this.minSearchTermLength) {
          return this.findObjectsUsingFulltext(searchTerm).pipe(
            map(page => page.content ?? []),
          );
        }
        else {
          return of([]);
        }
      }),
      map(objects => objects.map(f => this.objectToObjectSelectorOption(f)) as ObjectOption<T>[]),
      tap(options => this.searchedOptions = options),
    );

    return defaultDefinition;
  };

  minSearchTermLength = 3;
  searchedOptions: ObjectOption<T>[] = [];
  selectedObjectSubject: string = '';

  abstract readonly objectClasses: Array<Nullable<T['objectClass']>>;
  abstract readonly recentlyVisitedObjectsHeading: string;
  abstract readonly additionalFilteringCriteria: FilterParam[];
  abstract readonly withoutRefNumberText: string;

  ngOnInit(): void {
    const recentlyVisitedObjects = this.historyService.getRecentlyVisitedObjects(
      this.objectClasses as unknown as ObjectClass[]
    ).map(rvo => ({
      value: rvo.objectId,
      label: rvo.objectName,
      icon: getObjectIcon(rvo.objectClass)!,
    })).filter(o => !this.excludeObjectWithId || o.value !== this.excludeObjectWithId)
      .reverse(); // most recently visited object should be at the top

    if (recentlyVisitedObjects.length) {
      const searchParams: SearchParams = {
        filter: [
          {
            fieldName: 'id',
            operator: FilterOperator.inSet,
            value: String(recentlyVisitedObjects.map(recentlyVisitedObjectOption => recentlyVisitedObjectOption.value)),
          },
          {
            fieldName: 'objectClass',
            operator: FilterOperator.inSet,
            value: String(this.objectClasses),
          },
          ...this.additionalFilteringCriteria,
        ],
        sort: [{fieldName: 'refNumber'}],
        page: 0,
        size: recentlyVisitedObjects.length,
      };

      this.findObjects(searchParams).subscribe(foundObjects => {
        const relevantRecentlyVisitedObjects = recentlyVisitedObjects
          .map(recentlyVisitedObjectOption => foundObjects.content.find(object => object.id === recentlyVisitedObjectOption.value))
          .filter(Boolean)
          .slice(0, 5) as T[]; // Six most recent objects

        this.recentlyVisitedObjectsOptions = [
          {
            value: RECENTLY_VISITED_OPTION_VALUE,
            label: this.recentlyVisitedObjectsHeading,
            isSeparator: true,
          },
          ...(relevantRecentlyVisitedObjects.map(object => this.objectToObjectSelectorOption(object))),
        ];
        this.cd.detectChanges();
      });
    }
  }

  recentlyVisitedObjectSelected(selection: RecentlyVisitedObjectOption<T>[]) {
    if (selection[0]) {
      this.form.get(this.controlName)?.setValue(selection[0].value as number);
      this.selectedObjectSubject = selection[0].label;
      this.objectSelected.emit(selection[0].data);
      this.objectSelector.closeOptions();
    }
  }

  onObjectSelected(selectedId: number) {
    const selection = locateOptionByValue(this.searchedOptions, selectedId);
    if (selection) {
      this.selectedObjectSubject = selection.label;
      this.objectSelected.emit(selection.data);
    }
  }

  private objectToObjectSelectorOption(object: T): Option<typeof RECENTLY_VISITED_OPTION_VALUE|number, T> {
    return {
      value: object.id,
      label: `${object.refNumber ?? this.translateService.instant(this.withoutRefNumberText)} - ${object.subject}`,
      icon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      data: object
    };
  }

  private findObjectsUsingFulltext(searchTerm: string): Observable<Page<T>> {
    const searchParams: SearchParams = {
      complexFilter: {
        operator: FilterTreeOperator.AND,
        values: [
          {
            fieldName: 'objectClass',
            operator: FilterOperator.inSet,
            value: String(this.objectClasses),
          },
          {
            operator: FilterTreeOperator.OR,
            values: [
              {
                fieldName: 'subject',
                operator: FilterOperator.contains,
                value: searchTerm,
                isCaseInsensitive: true,
              },
              {
                fieldName: 'refNumber',
                operator: FilterOperator.contains,
                value: searchTerm,
                isCaseInsensitive: true,
              }
            ],
          }
        ],
      },
      filter: this.additionalFilteringCriteria,
      sort: [{fieldName: 'refNumber'}],
      page: 0,
      size: 20,
      globalOperator: GlobalOperator.and,
    };

    return this.findObjects(searchParams);
  }

  protected abstract findObjects(searchParams: SearchParams): Observable<Page<T>>;

}
