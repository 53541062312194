/* tslint:disable */
/* eslint-disable */

/**
 * Version of ISSD interface.
 */
export enum IssdInterfaceVersion {
  V_1 = 'V1',
  V_2 = 'V2'
}
