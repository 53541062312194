/* tslint:disable */
/* eslint-disable */

/**
 * State of the organization
 */
export enum OrganizationState {
  IN_PROGRESS = 'IN_PROGRESS',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  DEACTIVATED = 'DEACTIVATED'
}
