/* tslint:disable */
/* eslint-disable */

/**
 * Flag representing the method of pagination of PDF Bulk Posting form export
 */
export enum BulkPostingFormPaging {
  NO_PAGINATION = 'NO_PAGINATION',
  DELIVERY_SERVICE_BASIC_PAGINATION = 'DELIVERY_SERVICE_BASIC_PAGINATION',
  DELIVERY_SERVICE_COMBINATION_PAGINATION = 'DELIVERY_SERVICE_COMBINATION_PAGINATION'
}
