import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'icz-form-autocomplete-chip',
  templateUrl: './form-autocomplete-chip.component.html',
  styleUrls: ['./form-autocomplete-chip.component.scss']
})
export class FormAutocompleteChipComponent {
  @Input()
  removable = true;
  @Input()
  isMultiline = false;
  @Output()
  removeClicked = new EventEmitter<void>();

  removeButtonClicked($event: Event) {
    $event.stopPropagation();

    this.removeClicked.emit();
  }
}
