import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ResizableModule} from 'angular-resizable-element';
import {
  EmpowermentCustomFilterComponent
} from './custom-filters/empowerment-custom-filter/empowerment-custom-filter.component';
import {
  EmpowermentFilterComponent
} from './custom-filters/empowerment-custom-filter/empowerment-filter/empowerment-filter.component';
import {TableContextMenuComponent} from './table-context-menu/table-context-menu.component';
import {TableDataCellComponent} from './table-data-cell/table-data-cell.component';
import {TableExpansionPanelComponent} from './table-expansion-panel/table-expansion-panel.component';
import {TableHeaderCellComponent} from './table-header-cell/table-header-cell.component';
import {DateFilterComponent} from './table-toolbar/filter-item/basic-filters/date-filter/date-filter.component';
import {
  FileSizeFilterComponent
} from './table-toolbar/filter-item/basic-filters/file-size-filter/file-size-filter.component';
import {NumberFilterComponent} from './table-toolbar/filter-item/basic-filters/number-filter/number-filter.component';
import {SavedFiltersComponent} from './table-toolbar/saved-filters/saved-filters.component';
import {TableSavedFiltersComponent} from './table-toolbar/table-saved-filters/table-saved-filters.component';
import {TableTreeMenuComponent} from './table-tree-menu/table-tree-menu.component';
import {TablePaginatorComponent} from './table-paginator/table-paginator.component';
import {TablePreviewComponent} from './table-preview/table-preview.component';
import {TableToolbarPopupComponent} from './table-toolbar/table-toolbar-popup/table-toolbar-popup.component';
import {FilterItemComponent} from './table-toolbar/filter-item/filter-item.component';
import {DimensionsToolbarComponent} from './table-toolbar/dimensions-toolbar/dimensions-toolbar.component';
import {TableToolbarComponent} from './table-toolbar/table-toolbar.component';
import {TableTreeviewComponent} from './table-treeview/table-treeview.component';
import {ColumnTemplateDirective} from './column-template.directive';
import {TableComponent} from './table.component';
import {BasicFiltersComponent} from './table-toolbar/filter-item/basic-filters/basic-filters.component';
import {EnumFilterComponent} from './table-toolbar/filter-item/enum-filter/enum-filter.component';
import {CodebookFilterComponent} from './table-toolbar/filter-item/codebook-filter/codebook-filter.component';
import {PopupSelectorTableDialogComponent} from './popup-selector-table-dialog/popup-selector-table-dialog.component';
import {FilterTagComponent} from './table-toolbar/filter-breadcrumb/filter-tag.component';
import {FormFilterComponent} from './table-toolbar/form-filter/form-filter.component';
import {FormFilterValueAccessorDirective} from './table-toolbar/form-filter/form-filter.value-accessor';
import {
  DateStatisticsFilterComponent
} from './table-toolbar/filter-item/basic-filters/date-statistics-filter/date-statistics-filter.component';
import {
  DatePickerShortcutsComponent
} from './table-toolbar/filter-item/basic-filters/date-picker-shortcuts/date-picker-shortcuts.component';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {EssentialsModule} from '../essentials/essentials.module';
import {DialogsModule} from '../dialogs/dialogs.module';


@NgModule({
  declarations: [
    TablePaginatorComponent,
    TablePreviewComponent,
    TableToolbarPopupComponent,
    FilterItemComponent,
    DimensionsToolbarComponent,
    TableToolbarComponent,
    TableTreeviewComponent,
    ColumnTemplateDirective,
    TableComponent,
    BasicFiltersComponent,
    EnumFilterComponent,
    CodebookFilterComponent,
    PopupSelectorTableDialogComponent,
    FilterTagComponent,
    FormFilterComponent,
    FormFilterValueAccessorDirective,
    TableSavedFiltersComponent,
    TableTreeMenuComponent,
    TableExpansionPanelComponent,
    TableContextMenuComponent,
    SavedFiltersComponent,
    TableDataCellComponent,
    TableHeaderCellComponent,
    FileSizeFilterComponent,
    DateFilterComponent,
    DateStatisticsFilterComponent,
    DatePickerShortcutsComponent,
    EmpowermentFilterComponent,
    EmpowermentCustomFilterComponent,
    NumberFilterComponent,
  ],
  exports: [
    TablePaginatorComponent,
    TablePreviewComponent,
    TableToolbarPopupComponent,
    FilterItemComponent,
    TableToolbarComponent,
    TableTreeviewComponent,
    ColumnTemplateDirective,
    TableComponent,
    BasicFiltersComponent,
    EnumFilterComponent,
    CodebookFilterComponent,
    PopupSelectorTableDialogComponent,
    FilterTagComponent,
    FormFilterComponent,
    FormFilterValueAccessorDirective,
    TableTreeMenuComponent,
    TableExpansionPanelComponent,
    TableSavedFiltersComponent,
    SavedFiltersComponent,
  ],
  imports: [CommonModule, FormElementsModule, EssentialsModule, DialogsModule, ResizableModule],
})
export class TableModule {}
