/* tslint:disable */
/* eslint-disable */

/**
 * Rejection Reason Type (see http://dev-eamodel.ecm.i.cz/g2/index.htm?guid={3ACBF2DA-420B-43cf-B238-D152D641E4AC})
 */
export enum ExternalNotificationRejectionReasonType {
  DECRYPTION_ERROR = 'DECRYPTION_ERROR',
  DUPLICATE_MESSAGE = 'DUPLICATE_MESSAGE',
  NOT_SIGNED = 'NOT_SIGNED',
  SIGNATURE_NOT_VERIFIABLE = 'SIGNATURE_NOT_VERIFIABLE',
  MESSAGE_SIZE_LIMIT_EXCEEDED = 'MESSAGE_SIZE_LIMIT_EXCEEDED',
  DISALLOWED_MESSAGE_FORMAT = 'DISALLOWED_MESSAGE_FORMAT',
  NOT_VALID_SIGNATURE = 'NOT_VALID_SIGNATURE',
  SPAM = 'SPAM',
  VIR = 'VIR',
  AUTOMAIL = 'AUTOMAIL',
  CONTAINER_FORMAT = 'CONTAINER_FORMAT',
  CONTAINER_BROKEN = 'CONTAINER_BROKEN',
  NO_CONTAINER = 'NO_CONTAINER'
}
