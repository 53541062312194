@if ((dataSource?.loading$ | async) === false) {
  <div class="gap-8 col pt-16" [class.pb-16]="totalIncidentsCount <= MAX_INCIDENTS_COUNT">
    <icz-heading level="3" label="Chyby při předání do spisovny" class="mt-0 mb-8 ml-16"></icz-heading>
    @if (unresolvedIncidents.length) {
      @for (incident of unresolvedIncidents; track incident.id) {
        <div class="icz-body-1 px-16">
          <icz-ref-number-link [entity]="$any(incident)" [view]="RegistryOfficeTransferIncidentView.OBJECT_INCIDENTS_LISTING"></icz-ref-number-link> {{ incident.entityName }}: {{ incident.name }}
        </div>
      }
      @if (totalIncidentsCount > MAX_INCIDENTS_COUNT) {
        <div class="list-footer">
          <a [routerLink]="entityIncidentsLink">{{ 'Přejít na přehled všech chyb' | translate }} ({{ totalIncidentsCount }})</a>
        </div>
      }
    }
    @else {
      <div class="text-center">
        <icz-icon class="mt-16 mb-4" svgIcon="alldone"></icz-icon>
        <icz-label class="font-bold mb-16" label="Objekt nemá žádné chyby při předání."></icz-label>
      </div>
    }
  </div>
}
@else {
  <icz-spinner class="block text-center my-32" [diameter]="50"></icz-spinner>
}
