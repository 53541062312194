<form class="p-16" [formGroup]="form">
  <div class="row gap-32">
    <icz-section label="Výsledek doručení" class="grow">
      <icz-form-autocomplete
        formControlName="deliveryResultId"
        label="Výsledek doručení"
        [options]="deliveryResultOptions"
      ></icz-form-autocomplete>
      <icz-form-field
        label="Nový stav zásilky"
        [fieldDisabled]="true"
        [value]="getStatusAfterDeliveryTranslationKey(selectedDeliveryResult?.statusAfterDelivery) | translate"
      ></icz-form-field>
      <icz-form-field
        formControlName="note"
        label="Poznámka"
        [autoSizeMin]="3"
        [autoSizeMax]="6"
      ></icz-form-field>
      <icz-date-picker
        formControlName="acknowledgementDate"
        label="Datum doručení"
        outputFormat="dateTime"
        [selectableDates]="isValidAcknowledgementDate"
      ></icz-date-picker>
    </icz-section>
  </div>
</form>
