import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
  BasicFilterItem,
  CodebookFilterItem,
  EnumFilterItem,
  FilterItemValue,
  FilterWithList,
  NonemptyFilterItem
} from '../../filter.types';
import {Option} from '../../../../model';
import {FilterOperator, getOperatorTranslationKey} from '../../../../services/search-api.service';
import {IczOnChanges, IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DeferredColumnList, isListColumnDefinition} from '../../table.models';

export type FormFilterItem = BasicFilterItem | ((EnumFilterItem | CodebookFilterItem) & FilterWithList);

@Component({
  selector: 'icz-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormFilterComponent implements IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @Input()
  set value(newValue: Nullable<FilterItemValue>) {
    if (newValue !== this._value) {
      this._value = newValue;
      this.item = this.getFilterItem();
    }
  }
  get value(): Nullable<FilterItemValue> {
    return this._value;
  }

  @Input({required: true})
  filterItem: Partial<FormFilterItem> = {};
  @Input({required: true})
  formControlName = '';
  @Input()
  set fieldDisabled(newValue: Nullable<boolean>) { // not just [disabled] cause it clashes with another directive from ReactiveFormsModule
    this._fieldDisabledFromTemplate = newValue;
  }
  get fieldDisabled(): Nullable<boolean> {
    return this._fieldDisabledFromTemplate ?? this._fieldDisabledFromReactiveModel;
  }
  @Input()
  isStatic = true;
  @Output()
  valueChange = new EventEmitter<FilterItemValue>();
  @Output()
  blur = new EventEmitter<void>();
  @Output()
  cancelClicked = new EventEmitter<boolean>();

  _fieldDisabledFromTemplate: Nullable<boolean>;
  _fieldDisabledFromReactiveModel: Nullable<boolean>;

  isListLoading = false;
  resolvedList: Nullable<Option<any, any>[]>;

  item!: FormFilterItem;
  _value: Nullable<FilterItemValue>;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.formControlName || changes.filterItem) {
      this.item = this.getFilterItem();
    }
  }

  ngOnInit() {
    if ((this.filterItem as FilterWithList).list$) {
      this.isListLoading = true;
      (this.filterItem as FilterWithList).list$!.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe({
        next: resolvedList => {
          this.resolvedList = resolvedList;
          this.isListLoading = false;
          this.cd.detectChanges();
        },
        error: _ => this.isListLoading = false,
      });
    }
  }

  valueChanged($event: FormFilterItem) {
    this.value = FilterItemValue.fromFilterItem($event);
    this.item = this.getFilterItem();
    this.valueChange.emit(this._value!);
  }

  getDeferredFilterList(): DeferredColumnList {
    if (isListColumnDefinition(this.filterItem)) {
      return {list: (this.resolvedList ?? this.filterItem.list ?? []), isListLoading: this.isListLoading};
    }
    else {
      return {list: [], isListLoading: false};
    }
  }

  private getFilterOptionFromValue(): Nullable<Option<FilterOperator>> {
    if (this._value?.operator) {
      return {
        value: this._value?.operator,
        label: getOperatorTranslationKey(this._value?.operator),
      };
    }
    else {
      return undefined;
    }
  }

  private getFilterItem(): NonemptyFilterItem {
    return {
      id: this.formControlName,
      filterType: this.filterItem?.filterType!,
      label: String(this._value?.value ?? ''),
      filterOption: this.getFilterOptionFromValue()!,
      value: this._value?.value ?? '',
      subValues: this._value?.subValues,
      columnLabel: this.filterItem?.label ?? '',
      list: this.filterItem?.list ?? this._value?.list ?? [],
      ...(this.filterItem ?? {}),
    };
  }

}
