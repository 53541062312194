/* tslint:disable */
/* eslint-disable */

/**
 * Availability
 */
export enum EsslComponentAvailability {
  AVAILABLE = 'AVAILABLE',
  LOCKED = 'LOCKED'
}
