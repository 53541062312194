import {inject, Injectable, Injector} from '@angular/core';
import {tap} from 'rxjs/operators';
import {InternalNotificationKey} from '|api/notification';
import {OwnDigitalConsignmentDto, OwnPaperConsignmentDto} from '|api/sad';
import {ConsignmentType, SubjectRecordDto} from '|api/commons';

import {
  ConsignmentDialogData,
  ConsignmentDialogResult,
  ConsignmentDialogResultType,
  ConsignmentDialogType,
  ConsignmentDocumentData
} from './consignment-dialog/abstract-consignment-dialog-data';
import {ConsignmentDialogComponent,} from './consignment-dialog/consignment-dialog.component';
import {ConsignmentsToastService, ConsignmentToastData} from './consignments-toast.service';
import {GenericOwnConsignment} from '../own-consignment-table/model/own-consignment-model';
import {IczModalService} from '../../../services/icz-modal.service';

@Injectable()
export class ConsignmentDialogService {

  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);
  private injector = inject(Injector);

  openConsignmentDialog(
    documentData: ConsignmentDocumentData,
    type: ConsignmentDialogType,
    consignment?: GenericOwnConsignment,
    preselectedConsignees?: SubjectRecordDto[],
    requestTableReloadCb?: () => void,
  ) {
    let titleTemplate = 'Vypravení zásilky';
    if (type === ConsignmentDialogType.CREATE_OFFICE_DESK_CONSIGNMENT) titleTemplate = 'Vyvěšení dokumentu';
    else if (type === ConsignmentDialogType.EDIT_CONSIGNMENT && consignment?.consignmentType === ConsignmentType.OFFICE_DESK) titleTemplate = 'Vyvěšení dokumentu';
    else if (type === ConsignmentDialogType.CREATE_CONSIGNMENT) titleTemplate = 'Vypravení dokumentu';
    else if (type === ConsignmentDialogType.CREATE_INTERNAL_CONSIGNMENT) titleTemplate = 'Interní vypravení dokumentu';

    return this.modalService.openComponentInModal<Nullable<ConsignmentDialogResult>, ConsignmentDialogData>({
      component: ConsignmentDialogComponent,
      modalOptions: {
        width: 1030,
        height: '90vh',
        titleTemplate,
        disableAutoMargin: true,
        injector: this.injector,
      },
      data: {
        documentData,
        type,
        consignment: consignment as Nullable<OwnPaperConsignmentDto | OwnDigitalConsignmentDto>,
        preselectedConsignees,
        requestTableReloadCb,
      },
    }).pipe(
      tap(result => {
        if (result) {
          const toastData: ConsignmentToastData = {
            [InternalNotificationKey.CONSIGNMENT_ID]: result.consignment.id,
            [InternalNotificationKey.CONSIGNMENT_UID]: result.consignment.uid!.uid!,
          };

          if (result.resultType === ConsignmentDialogResultType.HANDED_OVER) {
            this.consignmentsToastService.dispatchConsignmentHandedOver(toastData);
          }
          else if (
            result.resultType === ConsignmentDialogResultType.SAVED ||
            result.resultType === ConsignmentDialogResultType.SAVED_WITH_PROOF_OF_DELIVERY
          ) {
            if (consignment) {
              this.consignmentsToastService.dispatchConsignmentUpdated(toastData);
            } else {
              toastData[InternalNotificationKey.DOCUMENT_ID] = documentData.id;
              toastData[InternalNotificationKey.DOCUMENT_SUBJECT] = documentData.subject!;
              this.consignmentsToastService.dispatchConsignmentSaved(toastData);
            }

            if (result.resultType === ConsignmentDialogResultType.SAVED_WITH_PROOF_OF_DELIVERY) {
              this.consignmentsToastService.dispatchProofOfDeliveryRecorded(toastData);
            }
          }
        }
      }),
    );
  }

}
