<div class="col grow px-24"
     [waiting]="loadingIndicatorService.isLoading(this, SubjectLoaderIds.LINKING) || loadingIndicatorService.isLoading(this, SubjectLoaderIds.SEARCHING)">
  <icz-subject-search-form [form]="form" (searchByKeys)="searchSubjectsAndDecideFindMode(true)" [expanded]="expanded"
                           [showSearchAppendix]="showSearchAppendix"></icz-subject-search-form>

  <div class="row flex-row-reverse">
    @if (showInlineSearchButton) {
      <icz-button svgIcon="search" [label]="allowInternalSearchOnly ? 'Vyhledat v rejstříku' : 'Vyhledat subjekt'"
                  primary [disabled]="!form.valid || isFormEmpty"
                  (onAction)="searchSubjectsAndDecideFindMode(false)" iczFormSubmit>
      </icz-button>
    }
  </div>

  <icz-section label="Nalezené subjekty" [expanded]="true" [storeClosedState]="false">
    @if (!loadingIndicatorService.isLoading(this)) {
      <div>
        @if (showLinkToStepCreate) {
          <div class="row">
            <icz-label label="Nenašli jste co jste hledali?"></icz-label>
            <span class="underline cursor-pointer" (click)="goToCreateSubjectStep()">{{ 'Založit subjekt' | translate }}</span>
          </div>
        }
        @if (searched) {
          @if (foundSubjects.length) {
            <icz-subjects-table
              class="w-full"
              [tableId]="tableViewId"
              [dataSource]="dataSource"
              [columnSet]="resultTableColumnSet"
              (selectedRowsChanged)="onRowSelect($event)"
              [createNewConsignmentDialogType]="createNewConsignmentDialogType"
              [tableTitle]="showResultTableToolbar ? 'Nalezené subjekty' : null"
              (actionExecuted)="actionExecuted($event)"
              (reloadDataSource)="searchSubjectsByKeys(true)"
              (subjectAddressChanged)="subjectAddressChanged.emit($event)"
            >
              @if (showResultTableToolbar) {
                <icz-subjects-toolbar
                  tools [selectedRows]="selectedRows"
                  [context]="subjectToolbarContext"
                ></icz-subjects-toolbar>
              }
            </icz-subjects-table>
          } @else {
            @if (allowCreateOnNotFound) {
              <icz-alert severity="info" alertMode="full" heading="Subjekt s těmito údaji nebyl nalezen.">
                <icz-button extraContent label="Založit subjekt" primary (onAction)="goToCreateSubjectStep()"></icz-button>
              </icz-alert>
            }
            @if (!allowCreateOnNotFound) {
              <icz-alert heading="Subjekt s těmito údaji nebyl nalezen." severity="warning"></icz-alert>
            }
          }
        }
      </div>
    }
  </icz-section>
</div>


<!--    Footer Search button-->
@if (showFooter) {
  <icz-form-buttons>
    @if (!allowInternalSearchOnly) {
      <icz-button label="Vyhledat subjekt"
                  primary
                  [disabled]="!form.valid || isFormEmpty || loadingIndicatorService.isLoading(this, SubjectLoaderIds.SEARCHING)"
                  (onAction)="searchSubjectsByKeys()"
                  iczFormSubmit
                  leftButtons>
      </icz-button>
    }
    <icz-button
      leftButtons
      [primary]="allowInternalSearchOnly"
      label="Vyhledat v rejstříku"
      [disabled]="!form.valid || isFormEmpty || loadingIndicatorService.isLoading(this, SubjectLoaderIds.SEARCHING)"
      (onAction)="searchSubjectsInternalOnly()">
    </icz-button>
  </icz-form-buttons>
}
