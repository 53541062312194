import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ReceivedEpdzAttachmentDto} from '|api/sad';
import {getComponentIconByFilenameSuffix} from '../../../model';

@Component({
  selector: 'icz-epdz-attachment-link',
  templateUrl: './epdz-attachment-link.component.html',
  styleUrls: ['./epdz-attachment-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EpdzAttachmentLinkComponent implements OnInit {
  @Input({required: true})
  epdzAttachment!: ReceivedEpdzAttachmentDto;

  attachmentIcon!: string;

  ngOnInit(): void {
    if (this.epdzAttachment) {
      this.attachmentIcon = getComponentIconByFilenameSuffix(this.epdzAttachment.name!);
    }
  }
}
