/* tslint:disable */
/* eslint-disable */

/**
 * Role of functional position that should receive an email notification.
 */
export enum FunctionalPositionRole {
  HOLDER = 'HOLDER',
  HOLDER_SUPERVISOR = 'HOLDER_SUPERVISOR',
  HOLDER_SECRETARIATE = 'HOLDER_SECRETARIATE',
  HOLDER_OU = 'HOLDER_OU',
  OWNER = 'OWNER',
  OWNER_SUPERVISOR = 'OWNER_SUPERVISOR',
  OWNER_SECRETARIATE = 'OWNER_SECRETARIATE',
  OWNER_OU = 'OWNER_OU',
  TASK_INITIATOR = 'TASK_INITIATOR',
  TASK_TARGET = 'TASK_TARGET'
}
