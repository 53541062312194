/* tslint:disable */
/* eslint-disable */

/**
 * Data box type
 */
export enum DataBoxType {
  OVM = 'OVM',
  OVM_NOTAR = 'OVM_NOTAR',
  OVM_EXEKUT = 'OVM_EXEKUT',
  OVM_REQ = 'OVM_REQ',
  OVM_FO = 'OVM_FO',
  OVM_PFO = 'OVM_PFO',
  OVM_PO = 'OVM_PO',
  PO = 'PO',
  PO_ZAK = 'PO_ZAK',
  PO_REQ = 'PO_REQ',
  PFO = 'PFO',
  PFO_REQ = 'PFO_REQ',
  PFO_ADVOK = 'PFO_ADVOK',
  PFO_DANPOR = 'PFO_DANPOR',
  PFO_INSSPR = 'PFO_INSSPR',
  PFO_AUDITOR = 'PFO_AUDITOR',
  PFO_ZNALEC = 'PFO_ZNALEC',
  PFO_TLUMOCNIK = 'PFO_TLUMOCNIK',
  PFO_ARCH = 'PFO_ARCH',
  PFO_AIAT = 'PFO_AIAT',
  PFO_AZI = 'PFO_AZI',
  FO = 'FO',
  SYS = 'SYS'
}
