/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CollectionFileRefNumberIdentifierGeneratorDto } from '../models/collection-file-ref-number-identifier-generator-dto';
import { FileRefNumberIdentifierGeneratorDto } from '../models/file-ref-number-identifier-generator-dto';
import { identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-create-collection-file-ref-number-generator';
import { IdentifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-create-collection-file-ref-number-generator';
import { identifierGeneratorAdministrationCreateFileRefNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-create-file-ref-number-generator';
import { IdentifierGeneratorAdministrationCreateFileRefNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-create-file-ref-number-generator';
import { identifierGeneratorAdministrationCreatePriorOrderNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-create-prior-order-number-generator';
import { IdentifierGeneratorAdministrationCreatePriorOrderNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-create-prior-order-number-generator';
import { identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-create-specialized-file-ref-number-generator';
import { IdentifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-create-specialized-file-ref-number-generator';
import { identifierGeneratorAdministrationCreateSpecializedUidGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-create-specialized-uid-generator';
import { IdentifierGeneratorAdministrationCreateSpecializedUidGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-create-specialized-uid-generator';
import { identifierGeneratorAdministrationCreateStorageUnitNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-create-storage-unit-number-generator';
import { IdentifierGeneratorAdministrationCreateStorageUnitNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-create-storage-unit-number-generator';
import { identifierGeneratorAdministrationGetAllIdentifierGenerators } from '../fn/identifier-generator-administration/identifier-generator-administration-get-all-identifier-generators';
import { IdentifierGeneratorAdministrationGetAllIdentifierGenerators$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-get-all-identifier-generators';
import { identifierGeneratorAdministrationResetUidGenerators } from '../fn/identifier-generator-administration/identifier-generator-administration-reset-uid-generators';
import { IdentifierGeneratorAdministrationResetUidGenerators$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-reset-uid-generators';
import { identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-collection-file-ref-number-generator';
import { IdentifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-collection-file-ref-number-generator';
import { identifierGeneratorAdministrationUpdateFileRefNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-file-ref-number-generator';
import { IdentifierGeneratorAdministrationUpdateFileRefNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-file-ref-number-generator';
import { identifierGeneratorAdministrationUpdateIdGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-id-generator';
import { IdentifierGeneratorAdministrationUpdateIdGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-id-generator';
import { identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-prior-order-number-generator';
import { IdentifierGeneratorAdministrationUpdatePriorOrderNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-prior-order-number-generator';
import { identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-specialized-file-ref-number-generator';
import { IdentifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-specialized-file-ref-number-generator';
import { identifierGeneratorAdministrationUpdateSpecializedUidGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-specialized-uid-generator';
import { IdentifierGeneratorAdministrationUpdateSpecializedUidGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-specialized-uid-generator';
import { identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-storage-unit-number-generator';
import { IdentifierGeneratorAdministrationUpdateStorageUnitNumberGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-storage-unit-number-generator';
import { identifierGeneratorAdministrationUpdateUidGenerator } from '../fn/identifier-generator-administration/identifier-generator-administration-update-uid-generator';
import { IdentifierGeneratorAdministrationUpdateUidGenerator$Params } from '../fn/identifier-generator-administration/identifier-generator-administration-update-uid-generator';
import { IdentifierGeneratorDto } from '../models/identifier-generator-dto';
import { IdIdentifierGeneratorDto } from '../models/id-identifier-generator-dto';
import { PriorOrderNumberIdentifierGeneratorDto } from '../models/prior-order-number-identifier-generator-dto';
import { SpecializedFileRefNumberIdentifierGeneratorDto } from '../models/specialized-file-ref-number-identifier-generator-dto';
import { SpecializedUidIdentifierGeneratorDto } from '../models/specialized-uid-identifier-generator-dto';
import { StorageUnitNumberIdentifierGeneratorDto } from '../models/storage-unit-number-identifier-generator-dto';
import { UidIdentifierGeneratorDto } from '../models/uid-identifier-generator-dto';


/**
 * Contains endpoints for Identifier Generators administration
 */
@Injectable({ providedIn: 'root' })
export class ApiIdentifierGeneratorAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateUidGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateUidGeneratorPath = '/records/identifier-generator-administration/uid-generator';

  /**
   * UID identifier generator configuration.
   *
   * Configures UID identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateUidGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateUidGenerator$Response(params: IdentifierGeneratorAdministrationUpdateUidGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<UidIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateUidGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * UID identifier generator configuration.
   *
   * Configures UID identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateUidGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateUidGenerator(params: IdentifierGeneratorAdministrationUpdateUidGenerator$Params, context?: HttpContext): Observable<UidIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateUidGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<UidIdentifierGeneratorDto>): UidIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateStorageUnitNumberGeneratorPath = '/records/identifier-generator-administration/storage-unit-number-generator';

  /**
   * Storage unit number identifier generator creation.
   *
   * Creates the Storage unit number identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator$Response(params: IdentifierGeneratorAdministrationUpdateStorageUnitNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Storage unit number identifier generator creation.
   *
   * Creates the Storage unit number identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator(params: IdentifierGeneratorAdministrationUpdateStorageUnitNumberGenerator$Params, context?: HttpContext): Observable<StorageUnitNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateStorageUnitNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitNumberIdentifierGeneratorDto>): StorageUnitNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationCreateStorageUnitNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationCreateStorageUnitNumberGeneratorPath = '/records/identifier-generator-administration/storage-unit-number-generator';

  /**
   * Storage unit number identifier generator creation.
   *
   * Creates the Storage unit number identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationCreateStorageUnitNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateStorageUnitNumberGenerator$Response(params: IdentifierGeneratorAdministrationCreateStorageUnitNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationCreateStorageUnitNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Storage unit number identifier generator creation.
   *
   * Creates the Storage unit number identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationCreateStorageUnitNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateStorageUnitNumberGenerator(params: IdentifierGeneratorAdministrationCreateStorageUnitNumberGenerator$Params, context?: HttpContext): Observable<StorageUnitNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationCreateStorageUnitNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitNumberIdentifierGeneratorDto>): StorageUnitNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateSpecializedUidGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateSpecializedUidGeneratorPath = '/records/identifier-generator-administration/specialized-uid-generators/{id}';

  /**
   * Specialized UID identifier generator configuration.
   *
   * Configures a given Specialized UID identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateSpecializedUidGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateSpecializedUidGenerator$Response(params: IdentifierGeneratorAdministrationUpdateSpecializedUidGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<SpecializedUidIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateSpecializedUidGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Specialized UID identifier generator configuration.
   *
   * Configures a given Specialized UID identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateSpecializedUidGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateSpecializedUidGenerator(params: IdentifierGeneratorAdministrationUpdateSpecializedUidGenerator$Params, context?: HttpContext): Observable<SpecializedUidIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateSpecializedUidGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecializedUidIdentifierGeneratorDto>): SpecializedUidIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateSpecializedFileRefNumberGeneratorPath = '/records/identifier-generator-administration/specialized-file-ref-number-generator/{id}';

  /**
   * Specialized file reference number identifier generator configuration.
   *
   * Configures a given Specialized file reference number identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator$Response(params: IdentifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<SpecializedFileRefNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Specialized file reference number identifier generator configuration.
   *
   * Configures a given Specialized file reference number identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator(params: IdentifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator$Params, context?: HttpContext): Observable<SpecializedFileRefNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateSpecializedFileRefNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecializedFileRefNumberIdentifierGeneratorDto>): SpecializedFileRefNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdatePriorOrderNumberGeneratorPath = '/records/identifier-generator-administration/prior-order-number-generator';

  /**
   * Prior order number identifier generator configuration.
   *
   * Configures Prior order number identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator$Response(params: IdentifierGeneratorAdministrationUpdatePriorOrderNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<PriorOrderNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Prior order number identifier generator configuration.
   *
   * Configures Prior order number identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator(params: IdentifierGeneratorAdministrationUpdatePriorOrderNumberGenerator$Params, context?: HttpContext): Observable<PriorOrderNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdatePriorOrderNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriorOrderNumberIdentifierGeneratorDto>): PriorOrderNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationCreatePriorOrderNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationCreatePriorOrderNumberGeneratorPath = '/records/identifier-generator-administration/prior-order-number-generator';

  /**
   * Prior order number identifier generator creation.
   *
   * Creates the Prior order number identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationCreatePriorOrderNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreatePriorOrderNumberGenerator$Response(params: IdentifierGeneratorAdministrationCreatePriorOrderNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<PriorOrderNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationCreatePriorOrderNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Prior order number identifier generator creation.
   *
   * Creates the Prior order number identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationCreatePriorOrderNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreatePriorOrderNumberGenerator(params: IdentifierGeneratorAdministrationCreatePriorOrderNumberGenerator$Params, context?: HttpContext): Observable<PriorOrderNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationCreatePriorOrderNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<PriorOrderNumberIdentifierGeneratorDto>): PriorOrderNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateIdGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateIdGeneratorPath = '/records/identifier-generator-administration/id-generator';

  /**
   * ID identifier generator configuration.
   *
   * Configures ID identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateIdGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateIdGenerator$Response(params: IdentifierGeneratorAdministrationUpdateIdGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<IdIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateIdGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * ID identifier generator configuration.
   *
   * Configures ID identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateIdGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateIdGenerator(params: IdentifierGeneratorAdministrationUpdateIdGenerator$Params, context?: HttpContext): Observable<IdIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateIdGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdIdentifierGeneratorDto>): IdIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateFileRefNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateFileRefNumberGeneratorPath = '/records/identifier-generator-administration/file-ref-number-generator';

  /**
   * File reference number identifier generator configuration.
   *
   * Configures File reference number identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateFileRefNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateFileRefNumberGenerator$Response(params: IdentifierGeneratorAdministrationUpdateFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<FileRefNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateFileRefNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * File reference number identifier generator configuration.
   *
   * Configures File reference number identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateFileRefNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateFileRefNumberGenerator(params: IdentifierGeneratorAdministrationUpdateFileRefNumberGenerator$Params, context?: HttpContext): Observable<FileRefNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateFileRefNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileRefNumberIdentifierGeneratorDto>): FileRefNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationCreateFileRefNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationCreateFileRefNumberGeneratorPath = '/records/identifier-generator-administration/file-ref-number-generator';

  /**
   * File reference number identifier generator creation.
   *
   * Creates the File reference number identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationCreateFileRefNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateFileRefNumberGenerator$Response(params: IdentifierGeneratorAdministrationCreateFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<FileRefNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationCreateFileRefNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * File reference number identifier generator creation.
   *
   * Creates the File reference number identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationCreateFileRefNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateFileRefNumberGenerator(params: IdentifierGeneratorAdministrationCreateFileRefNumberGenerator$Params, context?: HttpContext): Observable<FileRefNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationCreateFileRefNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileRefNumberIdentifierGeneratorDto>): FileRefNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationUpdateCollectionFileRefNumberGeneratorPath = '/records/identifier-generator-administration/collection-file-ref-number-generator';

  /**
   * Collection file reference number identifier generator configuration.
   *
   * Configures Collection file reference number identifier generator's parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator$Response(params: IdentifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<CollectionFileRefNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Collection file reference number identifier generator configuration.
   *
   * Configures Collection file reference number identifier generator's parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator(params: IdentifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator$Params, context?: HttpContext): Observable<CollectionFileRefNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationUpdateCollectionFileRefNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<CollectionFileRefNumberIdentifierGeneratorDto>): CollectionFileRefNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationCreateCollectionFileRefNumberGeneratorPath = '/records/identifier-generator-administration/collection-file-ref-number-generator';

  /**
   * Collection file reference number identifier generator creation.
   *
   * Creates the Collection file reference number identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Response(params: IdentifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<CollectionFileRefNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Collection file reference number identifier generator creation.
   *
   * Creates the Collection file reference number identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator(params: IdentifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Params, context?: HttpContext): Observable<CollectionFileRefNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<CollectionFileRefNumberIdentifierGeneratorDto>): CollectionFileRefNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationCreateSpecializedUidGenerator()` */
  static readonly IdentifierGeneratorAdministrationCreateSpecializedUidGeneratorPath = '/records/identifier-generator-administration/specialized-uid-generators';

  /**
   * Specialized UID identifier generator creation.
   *
   * Creates a new Specialized UID identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationCreateSpecializedUidGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateSpecializedUidGenerator$Response(params: IdentifierGeneratorAdministrationCreateSpecializedUidGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<SpecializedUidIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationCreateSpecializedUidGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Specialized UID identifier generator creation.
   *
   * Creates a new Specialized UID identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationCreateSpecializedUidGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateSpecializedUidGenerator(params: IdentifierGeneratorAdministrationCreateSpecializedUidGenerator$Params, context?: HttpContext): Observable<SpecializedUidIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationCreateSpecializedUidGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecializedUidIdentifierGeneratorDto>): SpecializedUidIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator()` */
  static readonly IdentifierGeneratorAdministrationCreateSpecializedFileRefNumberGeneratorPath = '/records/identifier-generator-administration/specialized-file-ref-number-generator';

  /**
   * Specialized file reference number identifier generator creation.
   *
   * Creates a new Specialized file reference number identifier generator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator$Response(params: IdentifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<SpecializedFileRefNumberIdentifierGeneratorDto>> {
    return identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator(this.http, this.rootUrl, params, context);
  }

  /**
   * Specialized file reference number identifier generator creation.
   *
   * Creates a new Specialized file reference number identifier generator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator(params: IdentifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator$Params, context?: HttpContext): Observable<SpecializedFileRefNumberIdentifierGeneratorDto> {
    return this.identifierGeneratorAdministrationCreateSpecializedFileRefNumberGenerator$Response(params, context).pipe(
      map((r: StrictHttpResponse<SpecializedFileRefNumberIdentifierGeneratorDto>): SpecializedFileRefNumberIdentifierGeneratorDto => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationResetUidGenerators()` */
  static readonly IdentifierGeneratorAdministrationResetUidGeneratorsPath = '/records/identifier-generator-administration/reset-uid-generators';

  /**
   * (Specialized) UID identifier generator reset.
   *
   * Resets all or specified (specialized) generators to one. In other words, this operation ensures that the next issued order number of reset generators will be 1.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationResetUidGenerators()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationResetUidGenerators$Response(params: IdentifierGeneratorAdministrationResetUidGenerators$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return identifierGeneratorAdministrationResetUidGenerators(this.http, this.rootUrl, params, context);
  }

  /**
   * (Specialized) UID identifier generator reset.
   *
   * Resets all or specified (specialized) generators to one. In other words, this operation ensures that the next issued order number of reset generators will be 1.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationResetUidGenerators$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  identifierGeneratorAdministrationResetUidGenerators(params: IdentifierGeneratorAdministrationResetUidGenerators$Params, context?: HttpContext): Observable<void> {
    return this.identifierGeneratorAdministrationResetUidGenerators$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `identifierGeneratorAdministrationGetAllIdentifierGenerators()` */
  static readonly IdentifierGeneratorAdministrationGetAllIdentifierGeneratorsPath = '/records/identifier-generator-administration/get-all-generators';

  /**
   * All available identifier generators.
   *
   * Returns all available identifier generators.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `identifierGeneratorAdministrationGetAllIdentifierGenerators()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorAdministrationGetAllIdentifierGenerators$Response(params?: IdentifierGeneratorAdministrationGetAllIdentifierGenerators$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IdentifierGeneratorDto>>> {
    return identifierGeneratorAdministrationGetAllIdentifierGenerators(this.http, this.rootUrl, params, context);
  }

  /**
   * All available identifier generators.
   *
   * Returns all available identifier generators.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `identifierGeneratorAdministrationGetAllIdentifierGenerators$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  identifierGeneratorAdministrationGetAllIdentifierGenerators(params?: IdentifierGeneratorAdministrationGetAllIdentifierGenerators$Params|null|undefined, context?: HttpContext): Observable<Array<IdentifierGeneratorDto>> {
    return this.identifierGeneratorAdministrationGetAllIdentifierGenerators$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IdentifierGeneratorDto>>): Array<IdentifierGeneratorDto> => r.body)
    );
  }

}
