@if (!addressCurrentlyValid) {
  <icz-alert
    severity="error" class="mb-16"
    heading="Zvolená adresa zásilky byla u subjektu změněna a není již aktuální."
  >
    <ng-content content select="[unknownAddressTypeHelp]"></ng-content>
  </icz-alert>
}

@if (wrapInCard) {
  <icz-card class="in-card">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </icz-card>
}
@else {
  <div class="row items-center" [ngClass]="{'p-16': wrapInCard}">
    <div class="grow">
      @if (showVerificationStatus) {
        <div class="icz-body-1">
          <span [class.icz-body-strong]="isAddressLabelBold">{{ addressTypeLabel }}</span>
          @if (isAddressVerified) {
            <span>
              @if (addressTypeLabel) {
                - {{ 'ověřeno' | translate }}
              }
              @else {
                {{ 'Ověřená adresa' | translate }}
              }
              @if (isAddressFromIszr) {
                <icz-icon class="inline" size="tiny" svgIcon="checked"></icz-icon>
              }
            </span>
          }
        </div>
      }
      @if (addressWithType?.address | address: false | async; as addressText) {
        <span [iczTooltip]="withTooltip ? addressText : null" [class.truncate]="withSingleLineAddress">
          {{ addressText }}
        </span>
      }
    </div>
    <!--    todo 10217 temporarily hidden - a bit more complex with new api -->
    <!--    <icz-button *ngIf="allowAddressEdit && addressCurrentlyValid" svgIcon="edit" iczTooltip="Upravit adresu" (onAction)="editAddress()"></icz-button>-->
  </div>
}

<ng-template #content>
  <div class="row items-center" [ngClass]="{'p-16': wrapInCard}">
    <div class="grow">
      <div class="icz-body-1">
        <span [class.icz-body-strong]="isAddressLabelBold">{{ addressTypeLabel }}</span>
        @if (isAddressVerified) {
          <span>
            @if (addressTypeLabel) {
              - {{ 'ověřeno' | translate }}
            }
            @else {
            {{ 'Ověřená adresa' | translate }}
            }
            @if (isAddressFromIszr) {
              <icz-icon class="inline" size="tiny" svgIcon="checked"></icz-icon>
            }
          </span>
        }
      </div>
    @if (addressWithType?.address | address: false | async; as addressText) {
      <span
        [iczTooltip]="withTooltip ? addressText : null" [class.truncate]="withSingleLineAddress"
        >
        {{ addressText }}
      </span>
    }
  </div>
  <!--    todo 10217 temporarily hidden - a bit more complex with new api -->
  <!--    <icz-button *ngIf="allowAddressEdit && addressCurrentlyValid" svgIcon="edit" iczTooltip="Upravit adresu" (onAction)="editAddress()"></icz-button>-->
</div>
</ng-template>
