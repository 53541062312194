<div class="col grow items-center"
  [class.main]="skin === 'MAIN'"
  [class.config]="skin === 'CONFIG'">
  <div class="logo-container">
    @if (skin === 'MAIN') {
      <icz-icon svgIcon="logo_big_bglight"></icz-icon>
    }
    @if (skin === 'CONFIG') {
      <icz-icon svgIcon="logo_big_bgpurple"></icz-icon>
      <icz-heading class="grow" label="Konfigurační aplikace" level="1"></icz-heading>
    }
  </div>

  @if (skin === LoginPageSkin.MAIN) {
    <icz-login-language-selector
      [languageCode]="loginScreenLanguageCode"
      (languageChanged)="changeLanguage($event)">
    </icz-login-language-selector>
  }

  <icz-environment-info [hidden]="!organizationOptions"></icz-environment-info>

  <div [hidden]="!organizationOptions" class="login-form-container" (keydown.enter)="login()">
    <div class="col gap-8">
      @if (skin === 'MAIN') {
        <icz-heading level="2" label="Přihlášení do e-spis"></icz-heading>
      }
      @if (skin === 'CONFIG') {
        <icz-heading level="2" label="Zadejte přihlašovací údaje"></icz-heading>
      }

      @if (loggedOutFromAnotherSession) {
        <icz-alert severity="info" class="mb-8" heading="Odhlásili jsme vás."></icz-alert>
      }
      @if (loginFailed) {
        <icz-alert severity="error" class="mb-8" heading="Přihlášení selhalo."></icz-alert>
      }
      @if (wrongCredentials) {
        <icz-alert
          severity="error" class="mb-8"
          [heading]="skin === 'CONFIG' ? 'Chybné uživatelské jméno nebo heslo.' : 'Chybné uživatelské jméno, heslo, nebo organizace.'"
        ></icz-alert>
      }

      <form class="grow" [formGroup]="form">
        @if (skin === 'MAIN') {
          <icz-form-autocomplete
            label="Organizace"
            formControlName="organization_code"
            [iczAutoFocus]="!isOrganizationSelected"
            [options]="organizationOptions"
            [clearable]="false"
          ></icz-form-autocomplete>
        }

        <icz-form-field class="grow" type="text" label="{{ 'Uživatelské jméno' | translate }}" formControlName="username" [isUsername]="true"
          htmlName="username" data-cy="login-username" (keydown.enter)="passwordInput.focusField()" [iczAutoFocus]="isOrganizationSelected">
        </icz-form-field>

        <icz-form-field class="grow login-password" #passwordInput type="password" label="{{ 'Heslo' | translate }}"
          htmlName="password" formControlName="password" data-cy="login-password" [passwordSuggestions]="true">
        </icz-form-field>

        <icz-button class="mt-32 grow"
          primary (onAction)="login()"
          [disabled]="form.invalid || (loadingService.isLoading$(this) | async)"
          data-cy="login-btn"
          label="Přihlásit">
        </icz-button>
      </form>

      @if (loadingService.isLoading$(this) | async) {
        <div class="row grow items-center justify-center waiting">
          <icz-spinner></icz-spinner>
        </div>
      }

    </div>
  </div>

  @if (!organizationOptions) {
    <icz-spinner class="initial-loading-spinner"></icz-spinner>
  }

  <icz-version-info></icz-version-info>
</div>
