import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable, of} from 'rxjs';
import {delay, filter, map, switchMap, take} from 'rxjs/operators';
import {
  EntityType,
  RelatedObjectDto,
  RelatedObjectType,
  SubjectObjectRelationDeleteDto,
  SubjectRecordDto,
  SubjectRecordWithRelationsDto
} from '|api/commons';
import {AuthorizedEntityType, DocumentAuthorizedOperation, FileAuthorizedOperation} from '|api/core';
import {DocumentDto} from '|api/document';
import {AuthorizedButton, AuthorizedButtonService} from '../../../essentials/authorized-button.service';
import {Button, ButtonCollectionComponent} from '../../../essentials/button-collection/button-collection.component';
import {AddSubjectDialogComponent} from '../add-subject/add-subject-dialog.component';
import {SubjectsToolbarDisablers} from './subjects-toolbar.disablers';
import {CommonToolbarDisablers} from '../../document-toolbar/services/toolbar-common.disablers';
import {IczOnChanges, IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {IczModalService} from '../../../../services/icz-modal.service';
import {UserSettingsService} from '../../../../services/user-settings.service';
import {DocumentDetailCountType, DocumentDetailService} from '../../../../services/document-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {increment, load} from '../../../../lib/object-counts';
import {ToolbarDataService} from '../../../table/table-toolbar/toolbar-data.service';
import {GlobalLoadingIndicatorService} from '../../../essentials/global-loading-indicator.service';
import {SubjectToastService, SubjectToastType} from '../../../../core/services/notifications/subject-toast.service';
import {ObjectDetailPart} from '../../../../services';
import {SubjectUsageReportConfigurationComponent} from '../subject-usage-report-configuration/subject-usage-report-configuration.component';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from '../../../../core/services/notifications/internal-notification.enum';
import {ELASTIC_RELOAD_DELAY} from '../../document-toolbar/services/toolbar-common.utils';
import {ApiRelatedObjectService, ApiSubjectRecordElasticService} from '|api/subject-register';
import {InternalNotificationKey} from '|api/notification';
import {SubjectIszrIdentificationService} from '../subject-iszr-identification.service';
import {IszrRppUserRelationFilteredDto} from '|api/codebook';
import {CodebookService} from 'libs/shared/src/lib/core';
import {constructSubjectName} from '../../model/subjects.model';


export enum SubjectToolbarContext {
  DOCUMENT_DETAIL = 'DOCUMENT_DETAIL',
  OVERVIEW = 'OVERVIEW',
  ADMIN = 'ADMIN',
  SUBJECT_DETAIL = 'SUBJECT_DETAIL',
  SUBJECT_DETAIL_ISDS = 'SUBJECT_DETAIL_ISDS',
  SUBJECT_DETAIL_ISZR = 'SUBJECT_DETAIL_ISZR',
}

export enum SubjectOperation {
  IDENTIFY = 'IDENTIFY'
}

export interface SubjectOperatonComplete {
  operation: SubjectOperation,
  inputSubject: SubjectRecordDto | SubjectRecordWithRelationsDto,
  outputSubject: SubjectRecordDto,
  additionalOperationResult?: {valueCorrectionMode?: boolean}
}

@Component({
  selector: 'icz-subjects-toolbar',
  templateUrl: './subjects-toolbar.component.html',
  styleUrls: ['./subjects-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectsToolbarComponent implements OnInit, IczOnChanges {

  currentDocumentRepresentingSubjectId: Nullable<number>;

  private userSettingsService = inject(UserSettingsService);
  private iczModalService = inject(IczModalService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private toolbarDataService = inject(ToolbarDataService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private codebookService = inject(CodebookService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private apiRelatedObjectNgService = inject(ApiRelatedObjectService);
  private subjectToastService = inject(SubjectToastService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private subjectIszrIdentificationService = inject(SubjectIszrIdentificationService);
  private injector = inject(Injector);
  private documentDetailService = inject(DocumentDetailService, {optional: true});

  @Input({required: true}) selectedRows: SubjectRecordDto[] = [];
  @Input() entityId$!: Observable<Nullable<number>>;
  @Input() entityType = EntityType.DOCUMENT;
  @Input() context = SubjectToolbarContext.OVERVIEW;
  @Input() nonpersistentSubjectMode = false;
  @Output() reloadDataSource = new EventEmitter<void>();
  @Output() operationCompleted = new EventEmitter<SubjectOperatonComplete>();
  @ViewChild('buttonCollectionComponent', {read: ButtonCollectionComponent, static: false})
  buttonCollectionComponent!: ButtonCollectionComponent;

  currentDocument: Nullable<DocumentDto>;

  subjectToolbarButtons: Button[] = [];

  iszrAgendasForCurrentFunctionalPosition: IszrRppUserRelationFilteredDto[] = [];

  areButtonsCollapsed$ = this.userSettingsService.showTableLabels$.pipe(map(showTableLabels => !showTableLabels));

  ngOnInit() {
    this.codebookService.iszrAgendasWithActivityRolesForCurrentFunctionalPosition().subscribe(iszrAgendas => {
      this.iszrAgendasForCurrentFunctionalPosition = iszrAgendas;
    });

    if (this.documentDetailService) {
      this.documentDetailService.object$.pipe(
        filter(Boolean),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(document => {
        this.currentDocument = document as DocumentDto;
        const related: RelatedObjectDto = {
          relatedObjectId: document.id,
          relatedObjectType: RelatedObjectType.DOCUMENT,
        };
        this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRepresentingSubject({body: related}).subscribe(subject => {
          this.currentDocumentRepresentingSubjectId = subject?.id;
        });

        this.getToolbarButtons();
      });
      this.documentDetailService.objectRepresentingSubject$?.pipe(
        delay(ELASTIC_RELOAD_DELAY),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        this.getToolbarButtons();
      });
    }
    else {
      this.getToolbarButtons();
    }

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.getToolbarButtons();
      }
    );
  }

  /**
   * Sets required params (incl. dependencies) for the modal actions in subjectToolbarButtons
   */
  buttonClicked(button: Button) {
    button.action?.(button);
  }

  addNewSubject() {
    this.entityId$.pipe(
      take(1),
      switchMap(entityId =>
        this.iczModalService.openComponentInModal({
          component: AddSubjectDialogComponent,
          modalOptions: {
            width: 1200,
            height: '90vh',
            titleTemplate: 'Přidání subjektu',
            disableAutoMargin: true,
            injector: this.injector,
          },
          data: entityId,
        })
      )
    ).pipe(delay(ELASTIC_RELOAD_DELAY)).subscribe(result => {
      if (result) {
        this.reloadDataSource.emit();
        this.documentDetailService?.reloadObject({
          [DocumentDetailCountType.SUBJECTS]: increment(),
        });
      }
    });
  }

  markSubjectAsRepresenting(selectedSubjects: SubjectRecordDto[]) {
    const selectedSubject = selectedSubjects[0]!;
    const related: RelatedObjectDto = {relatedObjectId: this.currentDocument!.id, relatedObjectType: RelatedObjectType.DOCUMENT};

    this.globalLoadingService.doLoading(
      this.apiRelatedObjectNgService.relatedObjectSetRepresentingSubject({
        body: related,
        subjectId: selectedSubject.id!
      })
    ).subscribe(() => {
      const name = constructSubjectName(selectedSubject);

      this.documentDetailService!.reloadObject({
        [ObjectDetailPart.OBJECT_DATA]: load(),
      });

      this.subjectToastService.dispatchSubjectInfoToast(
        SubjectToastType.SUBJECT_MARK_AS_REPRESENTING_SUCCESS,
        {
          [InternalNotificationKey.SUBJECT_NAME]: name,
        }
      );

      this.reloadDataSource.emit();
    });
  };

  unlinkSubject(selectedSubjects: SubjectRecordDto[]) {
    const relationDelete: SubjectObjectRelationDeleteDto[] = [{relatedObjectId: this.currentDocument!.id, relatedObjectType: RelatedObjectType.DOCUMENT}];

    this.globalLoadingService.doLoading(
    this.apiRelatedObjectNgService.relatedObjectUnlinkObjectsFromSubject({subjectId: selectedSubjects[0].id!, body: relationDelete})).subscribe(() => {
      const name = constructSubjectName(selectedSubjects[0]);

      this.subjectToastService.dispatchSubjectInfoToast(
        SubjectToastType.SUBJECT_UNLINK_SUCCESS,
        {
          [InternalNotificationKey.SUBJECT_NAME]: name,
        }
      );

      setTimeout(() => {
        this.reloadDataSource.emit();
      }, ELASTIC_RELOAD_DELAY);
    });
  }

  identifySubject(selectedSubjects: SubjectRecordDto[]) {
    const inputSubject = selectedSubjects[0];

    this.subjectIszrIdentificationService.tryIdentifySubject(inputSubject, false, this.injector).subscribe(identifiedSubject => {
      if (identifiedSubject?.subject) {
        this.operationCompleted.emit({
          operation: SubjectOperation.IDENTIFY,
          inputSubject,
          outputSubject: identifiedSubject.subject,
          additionalOperationResult: {valueCorrectionMode: identifiedSubject.valueCorrectionMode},
        });
      }
    });
  }

  // NYI
  verifyARES() {
  }

  // NYI
  printSubjectDetail() {
  }

  openUsageOverviewConfig() {
    this.iczModalService.openComponentInModal({
      component: SubjectUsageReportConfigurationComponent,
      modalOptions: {
        titleTemplate: 'Uložení přehledu',
        width: 850,
        height: 700,
      },
    }).subscribe();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.selectedRows || changes.entityId$ || changes.entityType || changes.nonpersistentSubjectMode || changes.context) {
      this.getToolbarButtons();
    }
  }

  private getToolbarButtons() {
    if (this.entityId$) {
      const isParentEntityDocument = this.entityType === EntityType.DOCUMENT;
      const isParentEntityFile = this.entityType === EntityType.FILE;

      this.entityId$.pipe(
        take(1),
        switchMap(entityId => this.authorizedButtonService.fetchAuthorizedButtonPermissions(
          {
            [AuthorizedEntityType.DOCUMENT]: isParentEntityDocument ? entityId : null,
            [AuthorizedEntityType.FILE]: isParentEntityFile ? entityId : null,
          },
          this.toolbarDataService.mergeToolbars(
            of([
              {
                label: 'Přidat subjekt',
                icon: 'add_user',
                show: this.context === SubjectToolbarContext.DOCUMENT_DETAIL,
                authorizedOperations: [
                  DocumentAuthorizedOperation.DOCUMENT_ADD_SUBJECT,
                  FileAuthorizedOperation.FILE_ADD_SUBJECT,
                ],
                buttonDisablers: [
                  SubjectsToolbarDisablers.isDocumentDeactivated(this.currentDocument),
                ],
                action: () => this.addNewSubject(),
              },
              {
                label: 'Nastavit jako reprezentující',
                icon: 'flag_outlined',
                show: (this.context === SubjectToolbarContext.DOCUMENT_DETAIL || this.context === SubjectToolbarContext.SUBJECT_DETAIL) && !isNil(this.documentDetailService) && !this.nonpersistentSubjectMode,
                buttonDisablers: [
                  CommonToolbarDisablers.isNoOrMultipleItemsSelected(this.selectedRows),
                  SubjectsToolbarDisablers.subjectIsAlreadyRepresenting(this.selectedRows, this.documentDetailService),
                ],
                action: () => this.markSubjectAsRepresenting(this.selectedRows),
              },
              {
                label: 'Ztotožnit',
                icon: 'verified_subject',
                show: this.context !== SubjectToolbarContext.SUBJECT_DETAIL_ISDS && this.context !== SubjectToolbarContext.SUBJECT_DETAIL_ISZR,
                authorizedOperations: [
                  DocumentAuthorizedOperation.DOCUMENT_IDENTIFY_SUBJECT,
                  FileAuthorizedOperation.FILE_IDENTIFY_SUBJECT,
                ],
                buttonDisablers: [
                  CommonToolbarDisablers.isNoOrMultipleItemsSelected(this.selectedRows),
                  SubjectsToolbarDisablers.isDocumentDeactivated(this.currentDocument),
                  SubjectsToolbarDisablers.isUserNotPermittedForIszrOperations(this.iszrAgendasForCurrentFunctionalPosition),
                ],
                action: () => this.identifySubject(this.selectedRows)
              },
              // todo(rb) uncomment after feature ready
              /*{
                label: 'Ověřit v ARES',
                icon: 'verified_subject',
                show: this.context === SubjectToolbarContext.ADMIN,
                buttonDisablers: [
                  CommonToolbarDisablers.isNoOrMultipleItemsSelected(this.selectedRows),
                  SubjectsToolbarDisablers.isDocumentDeactivated(this.currentDocument),
                ],
                isTestingFeature: true,
              },*/
              {
                label: 'Vypravení',
                icon: 'vypravna',
                show: this.context === SubjectToolbarContext.OVERVIEW,
                isTestingFeature: true,
              },
              {
                label: 'Více',
                icon: 'more',
                submenuItems: [
                  {
                    label: 'Tisk',
                    icon: 'printer',
                    show: this.context === SubjectToolbarContext.SUBJECT_DETAIL,
                    isTestingFeature: true,
                    action: () => this.printSubjectDetail(),
                  },
                  {
                    label: 'Přehled použití',
                    icon: 'prehled',
                    show: this.context === SubjectToolbarContext.SUBJECT_DETAIL && !this.nonpersistentSubjectMode,
                    isTestingFeature: true,
                    action: () => this.openUsageOverviewConfig(),
                  },
                  {
                    label: 'Odebrat subjekt',
                    show: this.context === SubjectToolbarContext.DOCUMENT_DETAIL,
                    authorizedOperations: [
                      DocumentAuthorizedOperation.DOCUMENT_REMOVE_SUBJECT,
                      FileAuthorizedOperation.FILE_REMOVE_SUBJECT,
                    ],
                    buttonDisablers: [
                      CommonToolbarDisablers.isNoOrMultipleItemsSelected(this.selectedRows),
                      SubjectsToolbarDisablers.isDocumentDeactivated(this.currentDocument),
                    ],
                    action: () => this.unlinkSubject(this.selectedRows),
                  },
                ]
              },
            ] satisfies AuthorizedButton[]),
          ),
        )),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(buttons => {
        this.subjectToolbarButtons = buttons;
        this.cd.detectChanges();
      });
    }
  }

}
