import {Component, inject} from '@angular/core';
import {switchMap} from 'rxjs';
import {ApiFileService, DocumentToFileDto, FileDto, OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczModalService} from '../../../../../services/icz-modal.service';
import {DocumentSearchService} from '../../../../../services/document-search.service';
import {
  DocumentToastService,
  DocumentToastType
} from '../../../../../core/services/notifications/document-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';

export interface MoveToAnotherFileDialogData {
  documents: (OwnDocumentDto | ReceivedDocumentDto)[];
  currentFile: FileDto;
  isFileDelete: boolean;
}

export interface MoveToAnotherFileDialogResult {
  isDocumentInitial: boolean;
  sourceFile: FileDto;
  targetFile: FileDto;
}

@Component({
  selector: 'icz-move-to-another-file-dialog',
  templateUrl: './move-to-another-file-dialog.component.html',
  styleUrls: ['./move-to-another-file-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class MoveToAnotherFileDialogComponent implements IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<MoveToAnotherFileDialogResult | boolean>>();
  private searchService = inject(DocumentSearchService);
  private apiFileService = inject(ApiFileService);
  private modalService = inject(IczModalService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private documentToastService = inject(DocumentToastService);
  private modalData = injectModalData<MoveToAnotherFileDialogData>();

  form = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  document!: OwnDocumentDto | ReceivedDocumentDto;
  currentFile!: FileDto;

  get isCurrentDocumentInitial() {
    return this.document?.id === this.currentFile?.initialDocumentId;
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    if (this.modalData.documents) {
      this.document = this.modalData.documents[0];
    }

    this.currentFile = this.modalData.currentFile;
  }

  submit() {
    const targetFileId = this.form.value.id!;
    let targetFile: FileDto;

    if (this.modalData.documents.length > 1) {
      this.loadingService.doLoading(
        this.apiFileService.fileBulkMoveDocumentsToAnotherFile({
          body: {
            documentIds: this.modalData.documents.map(d => d.id),
            fileId: targetFileId
          }
        }),
        this
      ).subscribe({
        next: _ => {
          this.modalRef.close(true);
          this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_MOVE_FROM_FILE_STARTED, {
            [InternalNotificationKey.COUNT]: this.modalData.documents.length
          });
        },
        error: _ => {
          this.modalRef.close(false);
          this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.DOCUMENT_BULK_ACTION_ERROR);
        }
      });
    } else {
      const requestDto: DocumentToFileDto = {
        documentId: this.document!.id,
        fileId: targetFileId,
      };

      this.loadingService.doLoading(
        this.apiFileService.fileFindById({id: targetFileId}).pipe(
          switchMap(file => {
            targetFile = file;
            return this.apiFileService.fileMoveDocumentToAnotherFile({
              body: requestDto,
            });
          })
        ),
        this,
      ).subscribe(() => {
        this.modalRef.close({
          isDocumentInitial: this.isCurrentDocumentInitial,
          targetFile,
          sourceFile: this.currentFile,
        } as MoveToAnotherFileDialogResult);
      });
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

}
