import {InterpolationContext} from '../../lib/model';

export enum DialogSeverity {
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  INFO = 'INFO',
}

export enum DialogMode {
  SIMPLE = 'SIMPLE',
  ACCENTED = 'ACCENTED'
}

export interface DialogItem {
  text: string;
  context?: InterpolationContext;
}

export interface DialogWithTitleData extends SimpleDialogData {
  title: string;
  titleContext?: InterpolationContext;
}

export interface SimpleDialogData {
  severity?: DialogSeverity;
  content: DialogItem[];
  mode?: DialogMode;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  showLeftButton?: boolean;
  showRightButton?: boolean;
}

export interface QuestionDialogData {
  title: string;
  titleContext?: InterpolationContext;
  severity?: DialogSeverity;
  question: string;
  questionContext?: InterpolationContext;
  description: string;
  descriptionContext?: InterpolationContext;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
}

