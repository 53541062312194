/* tslint:disable */
/* eslint-disable */

/**
 * Processing state on digital component - used for example during running conversion
 */
export enum ComponentProcessingState {
  CONVERSION_IN_PROGRESS = 'CONVERSION_IN_PROGRESS',
  CONTAINER_EXTRACTION_IN_PROGRESS = 'CONTAINER_EXTRACTION_IN_PROGRESS',
  COMPONENT_ANONYMIZATION_IN_PROGRESS = 'COMPONENT_ANONYMIZATION_IN_PROGRESS',
  COMPONENT_NATIVE_EDIT_IN_PROGRESS = 'COMPONENT_NATIVE_EDIT_IN_PROGRESS'
}
