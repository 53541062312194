import {Directive, HostListener, inject, Input, OnInit} from '@angular/core';
import {
  PopupSelectorTableDialogComponent
} from '../table/popup-selector-table-dialog/popup-selector-table-dialog.component';
import {IczFormDirective} from './icz-form.directive';
import {ButtonComponent} from '../essentials/button/button.component';
import {IczModalService} from '../../services/icz-modal.service';
import {ComponentModalComponent} from '../dialogs/component-modal/component-modal.component';
import {BehaviorSubject} from 'rxjs';
import {InvalidFormControlInfo, recursivelyListInvalidControls} from './icz-form-controls';
import {SimpleDialogComponent} from '../dialogs/simple-dialog/simple-dialog.component';

@Directive({
  selector: '[iczFormSubmit]'
})
export class IczFormSubmitDirective implements OnInit {

  private modalService = inject(IczModalService);
  private parentModal = inject(ComponentModalComponent, {optional: true});
  private iczForm = inject(IczFormDirective, {optional: true});
  private iczButton = inject(ButtonComponent, {optional: true});

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('iczFormSubmit.disableChecks')
  disableChecks = false;

  @Input()
  set disabled(value: Nullable<boolean | ''>) {
    if (this._disabled$.value && value === false) {
      // Prevents firing button event handler in very short period after enabling the button back
      setTimeout(() => {
        this._disabled$.next(false);
      }, 1000);
    }
    else {
      this._disabled$.next(value === '' ? true : Boolean(value));
    }
  }

  private shouldBlockButtonAction = () => {
    if (this.disableChecks) {
      return false;
    }
    else {
      return this.iczButton! && this.iczForm! && !this.iczForm.isFormValid;
    }
  };

  @HostListener('click')
  private hostClicked() {
    if (this.iczForm && !this.iczForm.isFormValid && !this.iczForm.isFormDisabled) {
      const list: InvalidFormControlInfo[] = [];
      recursivelyListInvalidControls(this.iczForm.form!, list);
      console.warn('Invalidní hodnoty při potvrzení', list);
    }

    if (this.shouldBlockButtonAction()) {
      this.iczForm?.form?.markFormGroupTouched();
      this.iczForm!.openInvalidSections();
      this.iczForm!.focusFirstInvalid();
    }
  }

  @HostListener('window:keydown.enter', ['$event'])
  private enterPressed($event: Event) {
    if (
      this.iczButton!.primary &&
      (
        !this.parentModal ||
        (this.parentModal && this.modalService.isModalComponentCurrentlyActive(this.parentModal))
      ) &&
      !this.modalService.hasOpenModalOfType(SimpleDialogComponent) &&
      !this.modalService.hasOpenModalOfType(PopupSelectorTableDialogComponent)
    ) {
      this.simulateHostButtonClick($event);
    }
  }

  simulateHostButtonClick($event: Event) {
    if (!this._disabled$.value) {
      this.hostClicked();
      this.iczButton!.buttonClicked($event);
    }
  }

  private _disabled$ = new BehaviorSubject<boolean>(false);

  ngOnInit() {
    if (!this.iczForm) {
      console.warn(`[iczFormSubmit] directive is not placed on a descendant of an element with [iczForm] directive. It will not perform any effects.`);
    }
    if (!this.iczButton) {
      console.warn(`[iczFormSubmit] directive is not placed on <icz-button> element. It will not perform any effects.`);
    }
    else {
      this.iczButton.cancelActionIf = this.shouldBlockButtonAction;
    }
  }

}
