import {Injectable, InjectionToken} from '@angular/core';
import axios from 'axios';
import * as JSON5 from 'json5';
import {from, Observable, ReplaySubject} from 'rxjs';
import {Environment, EnvironmentConfiguration, environmentConfigurationToEnvironment} from './environment.models';
import {deepFreeze} from '../../lib/utils';

export const ENVIRONMENT = new InjectionToken<Environment>(
  'Current environment information'
);

export function loadEnvironment(environmentService: EnvironmentService): Observable<void> {
  return from(environmentService.loadEnvironment());
}

export function getEnvironmentValue(environmentService: EnvironmentService): Environment {
  return environmentService.environment!;
}

@Injectable({
  providedIn: 'platform'
})
export class EnvironmentService {

  // Environment configuration observables are used when waiting for important data after application startup
  private _environment$ = new ReplaySubject<Environment>(1);
  environment$ = this._environment$.asObservable();

  // Environment value is used for @Inject(ENVIRONMENT) in consumer components/services/directives
  environment: Environment = {} as Environment;

  async loadEnvironment(): Promise<void> {
    const timestamp = Number(new Date());
    const environmentData: string | EnvironmentConfiguration = (await axios.get(`/assets/environment/environment.json5?ts=${timestamp}`)).data;
    // todo(rb) JSON5.parse does not work after migrating to Vite+ESBuild builder. Try resolving in the next version of builder or JSON5.
    const environmentDef = typeof(environmentData) === 'string' ? (JSON5 as any).default.parse(environmentData) as EnvironmentConfiguration : environmentData;

    environmentConfigurationToEnvironment(environmentDef, this.environment);

    // Prevents environment modification at runtime from client code
    deepFreeze(this.environment);

    this._environment$.next(this.environment);
  }

}
