/* tslint:disable */
/* eslint-disable */

/**
 * Status of the BulkPostingForm. Worth mentioning this can differ from the status of all OwnPaperConsignments in this BulkPostingForm.
 */
export enum BulkPostingFormRecordStatus {
  DISTRIBUTED = 'DISTRIBUTED',
  DISPATCHED = 'DISPATCHED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED'
}
