import {Observable, Subject} from 'rxjs';

/**
 * Usable for stubbing services with zero properties and all methods returning an Observable.
 * - For each such method returns an observable which never emits.
 */
export const UNIVERSAL_API_SERVICE_STUB = new Proxy({}, {
  get(target: any, prop: string | symbol, receiver: any): (...anything: any) => Observable<any> {
    return () => new Subject<any>();
  }
});
