/* tslint:disable */
/* eslint-disable */

/**
 * Possible values for subject-object relations.
 */
export enum SubjectObjectRelationType {
  RELATED = 'RELATED',
  SENDER = 'SENDER',
  ADDRESSEE = 'ADDRESSEE'
}
