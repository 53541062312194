@if (group?.length && !groupSaved && parentForm.dirty) {
  <div class="row items-center cursor-pointer mb-8" (click)="toggleExpansion()">
    <a iczLinkWithoutHref>{{ 'Uložit skupinu funkčních míst pro příště' | translate }}</a>
    <icz-icon size="small" [svgIcon]="isExpanded ? 'collapse' : 'expand_more'"></icz-icon>
  </div>
  @if (isExpanded) {
    @if (isGroupOrdered) {
      <icz-form-help-field
        class="pb-0"
        helpText="Skupina bude uložena včetně Vámi nadefinovaného pořadí při postupném předání"
      ></icz-form-help-field>
    }
    <form iczForm class="row pt-8" [formGroup]="form">
      <icz-form-field class="grow" formControlName="groupName" label="Pojmenujte skupinu">
        <icz-icon suffix size="small" svgIcon="info" [iczTooltip]="tooltipText"></icz-icon>
      </icz-form-field>
      <div>
        @if (!groupJustSaved) {
          <icz-button iczFormSubmit class="selection-save-button" label="Uložit" (onAction)="saveClicked()"></icz-button>
        }
        @if (groupJustSaved) {
          <icz-button class="selection-save-button" label="Uloženo" svgIcon="done"></icz-button>
        }
      </div>
    </form>
  }
}
