import {
  DistributionClass,
  SubjectAttributesDto,
  SubjectAttributeSource,
  SubjectAttributeState,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto
} from '|api/commons';
import {FileDto} from '|api/document';
import {AddressCompleteDto, AddressWithTypeAndValidityDto} from '../../model/addresses.model';
import {AddSubjectWizardData, SubjectAndAddress} from '../../model/subjects.model';
import {AbstractConsignmentDialogData} from './abstract-consignment-dialog-data';
import {ConsigneeWithDestination} from './consignment-consignees-overview/consignment-consignees-overview.component';
import {OrganizationConsignee} from './consignment-org-unit-selection/consignment-org-unit-selection.component';
import {isEmpty} from 'lodash';
import {Directive} from '@angular/core';
import {SubjectAttributeType} from '../../model/subject-attribute-type';

export interface ConsigneeResult {
  consignee: Nullable<SubjectRecordCreateOrUpdateDto>;
  consigneeId: Nullable<number>;
}

@Directive()
export abstract class AbstractConsignmentDialogConsignee extends AbstractConsignmentDialogData {

  selectedConsigneesWithDestination: ConsigneeWithDestination[] = [];
  subjectToCreate: Nullable<SubjectRecordCreateOrUpdateDto>;
  additionalAddresses: AddressWithTypeAndValidityDto[] = [];
  additionalEmailAddresses: string[] = [];
  consigneeAddressOverrides: SubjectAndAddress[] = [];
  consigneeCreationData: Nullable<AddSubjectWizardData>;
  file: Nullable<FileDto>;

  get isConsigneeRequiredForCreate() {
    return this.forDispatch.selectedDistributionClass === DistributionClass.ADDRESS_PLACE ||
      this.forDispatch.selectedDistributionClass === DistributionClass.ON_PLACE ||
      this.forDispatch.selectedDistributionClass === DistributionClass.EMAIL_BOX ||
      this.forDispatch.selectedDistributionClass === DistributionClass.ISDS_BOX ||
      this.forDispatch.selectedDistributionClass === DistributionClass.INTERNAL ||
      (this.forDispatch.selectedDistributionClass !== DistributionClass.OFFICIAL_BOARD && this.selectedConsigneesWithDestination.length);
  }

  consigneeAddressChanged(subjectAndAddress: SubjectAndAddress) {
    const overrideForSubject = this.consigneeAddressOverrides.find(o => o.subject === subjectAndAddress.subject);

    if (overrideForSubject) {
      const originalOverrideIndex = this.consigneeAddressOverrides.indexOf(overrideForSubject);
      this.consigneeAddressOverrides.splice(originalOverrideIndex, 1);
    }

    this.consigneeAddressOverrides.push(subjectAndAddress);
  }

  onOrgConsigneeChanges(consignee: OrganizationConsignee) {
    this.selectedConsigneesWithDestination = [{
      consignee: consignee.subject,
      destinationAddress: consignee.sheetNode?.address as AddressCompleteDto,
    }];
  }

  consigneeAddressAdded(additionalAddresses: AddressWithTypeAndValidityDto[]) {
    this.additionalAddresses = additionalAddresses;
  }

  consigneeEdited(updatedConsignee: SubjectRecordDto) {
    const editedConsigneeIndex = this.selectedConsigneesWithDestination.findIndex(c => c.consignee.id === updatedConsignee.id);

    if (editedConsigneeIndex !== -1) {
      this.selectedConsigneesWithDestination[editedConsigneeIndex].consignee = updatedConsignee;
      this.selectedConsigneesWithDestination = [...this.selectedConsigneesWithDestination];
    } else {
      // this assumes there is only 1 consignee maximum
      this.selectedConsigneesWithDestination = [{consignee: updatedConsignee}];
    }

    if (this.subjectToCreate) {
      this.subjectToCreate = updatedConsignee;
    }
  }

  protected getConsignee(): ConsigneeResult {
    const additionalSubjectAttributes: SubjectAttributesDto = {};

    const commonSubjectAttributeFields = {
      source: SubjectAttributeSource.INTERNAL,
      state: SubjectAttributeState.CORRECT,
      validFrom: new Date().toISOString(),
    };

    if (this.isPaperConsignmentByDistClass && this.additionalAddresses?.length) {
      additionalSubjectAttributes.additionalAddresses = this.additionalAddresses
        .filter(a => a.isCurrentlyValid)
        .map(a => {
          return {
            ...commonSubjectAttributeFields,
            type: a.type!,
            value: a.address,
          };
        });
    }
    else if (this.forDispatch.distributionClassForDispatch === DistributionClass.EMAIL_BOX && this.additionalEmailAddresses?.length) {
      additionalSubjectAttributes.emails = this.additionalEmailAddresses.map(a => {
        return {
          type: SubjectAttributeType.EMAIL,
          value: a,
          ...commonSubjectAttributeFields,
        };
      });
    }

    let consignee: SubjectRecordCreateOrUpdateDto | number;

    if (this.subjectToCreate) {
      consignee = {
        ...this.subjectToCreate,
      };

      (consignee as SubjectRecordCreateOrUpdateDto).enrichMode = true;

      // @ts-ignore -- the endpoint does not accept SubjectRecordCreateOrUpdateDtos with manually created object references
      delete (consignee as SubjectRecordCreateOrUpdateDto).relatedObjectReference;

      if (!isEmpty(additionalSubjectAttributes)) {
        consignee.attributes = {
          ...consignee.attributes,
          ...additionalSubjectAttributes,
        };
      }
    }
    else {
      // if ID is present, subject already exists within our subject register
      if (this.selectedConsigneesWithDestination[0].consignee.id) {
          if (!isEmpty(additionalSubjectAttributes)) {
          consignee = {
            ...this.selectedConsigneesWithDestination[0].consignee,
          };

          consignee.attributes = {
            ...consignee.attributes,
            ...additionalSubjectAttributes,
          };
        }
        else {
          return {consigneeId: this.selectedConsigneesWithDestination[0].consignee.id!, consignee: null};
        }
      }
      else {
        // If ID is not present on the subject, subject was only found in ISDS registry and will be created in our subject register
        // together with the consignment
        consignee = {
          ...this.selectedConsigneesWithDestination[0].consignee,
        };

        if (!isEmpty(additionalSubjectAttributes)) {
          consignee.attributes = {
            ...consignee.attributes,
            ...additionalSubjectAttributes,
          };
        }
      }

    }

    return {consignee, consigneeId: null};
  }

}
