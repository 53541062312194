/* tslint:disable */
/* eslint-disable */

/**
 * Registry office transfer error source
 */
export enum RegistryOfficeTransferErrorSource {
  DESA_VALIDATOR = 'DESA_VALIDATOR',
  INTERNAL_VALIDATOR = 'INTERNAL_VALIDATOR',
  MANUAL = 'MANUAL',
  CORRECTIVE_PROCEDURE = 'CORRECTIVE_PROCEDURE'
}
