import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {serializeParamsToQueryString} from '../../lib/utils';
import {Params} from '@angular/router';

export function createAbsoluteRouteWithUnit(...args: (string | number)[]) {
  return createAbsoluteRoute(ApplicationRoute.UNIT, ...args);
}

export function createAbsoluteRoute(...args: (string | number | Params)[]): string {
  if (args.length < 1) {
    console.warn('Args length should be equal or greater than 1');
  }

  const queryParamObjects = args.filter(a => a && typeof a === 'object');

  if (queryParamObjects.length > 1) {
    throw new Error(`createAbsoluteRoute call must specify 0..1 query params definitions. Specified ${queryParamObjects.length} definitions.`);
  }
  else {
    const pathParts = args.filter(a => typeof a === 'string' || typeof a === 'number');

    if (queryParamObjects.length && Object.keys(queryParamObjects[0]).length) {
      return ApplicationRoute.ROOT + pathParts.join('/') + serializeParamsToQueryString(queryParamObjects[0] as Params);
    }
    else {
      return ApplicationRoute.ROOT + pathParts.join('/');
    }
  }
}
