/* tslint:disable */
/* eslint-disable */

/**
 * Source of attribute
 */
export enum SubjectAttributeSource {
  INTERNAL = 'INTERNAL',
  ISDS = 'ISDS',
  ROB = 'ROB',
  ROS = 'ROS'
}
