import {CirculationActivityType, ObjectClass} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {DocumentDto, FileDto, RegistryOfficeTransferDto, StorageUnitDto} from '|api/document';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {RefNumberableEntity} from '../ref-number-link/ref-number-link.component';
import {DocumentDtoWithAuthorization} from './dto-with-permissions.interface';

export interface EntityReferringDocument {
  id: number; // own ID
  documentId?: Nullable<number>; // referred document ID
}

export type DocumentFragment = Pick<DocumentDto, 'subject'|'refNumber'|'objectClass'| 'documentState'>;
export type DocumentTransferFragment = Pick<DocumentDto, 'subject'| 'documentState'>;

export interface ExtendedCirculationActivityDto extends DocumentFragment, EntityReferringDocument, CirculationActivityDto {
  hasSettlementNotes: boolean;
  rejectedTaskIds: Nullable<Array<number>>;
}

export interface ExtendedCirculationTaskDto extends DocumentFragment, EntityReferringDocument, CirculationTaskDto {
  activityType: CirculationActivityType;
  fullComponent: Nullable<DigitalComponentCompleteDto>;
  componentLabel?: string;
}

export interface ElasticObjectTransferFragment {
  objectClass: ObjectClass;
  entityName: Nullable<string>;
}

export function isTransferedElasticObjectFragment(value: any): value is ElasticObjectTransferFragment {
  return value && 'entityName' in value;
}

export type ExtendedRegistryOfficeTransferActivityDto = (
  DocumentDto | FileDto |
  StorageUnitDto | ElasticObjectTransferFragment
) & RegistryOfficeTransferDto;

export interface ExtendedCirculationTaskDtoWithFullBaseObject extends ExtendedCirculationTaskDto {
  fullDocument: Nullable<RefNumberableEntity & DocumentDtoWithAuthorization>;
  fullComponent: Nullable<DigitalComponentCompleteDto>;
}

export type ExtendedDigitalComponentCompleteDto = DigitalComponentCompleteDto & {
  digitalComponentLastFileName: string;
  digitalComponentLastFileSize: number;
  digitalComponentLastVersion: number; // ID of last digitalComponent version
  digitalComponentLastTypeFlag: string;
};

export function isExtendedDocumentTaskDto(value: any): value is ExtendedCirculationTaskDto {
  return (
    value &&
    'subject' in value &&
    'refNumber' in value &&
    'documentId' in value &&
    'taskState' in value &&
    'taskType' in value &&
    'activityId' in value
  );
}

export function isExtendedDocumentActivityDto(value: any): value is ExtendedCirculationActivityDto {
  return (
    value &&
    'subject' in value &&
    'refNumber' in value &&
    'documentId' in value &&
    'activityState' in value &&
    'activityType' in value &&
    'targetParticipants' in value
  );
}
