import {ChangeDetectorRef, Component, EventEmitter, HostBinding, inject, Input, OnInit, Output} from '@angular/core';
import {DetachingService} from '../../essentials/detaching.service';


@Component({
  selector: 'icz-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.scss'],
})
export class TablePreviewComponent implements OnInit {

  private detachingService = inject(DetachingService);
  private cd = inject(ChangeDetectorRef);

  @HostBinding('class.opened')
  @Input({required: true})
  opened: Nullable<boolean> = false;

  @HostBinding('class.is-doc-preview')
  @Input()
  isTableDocPreview = false;

  @Output()
  closed = new EventEmitter<void>();

  ngOnInit() {
    this.detachingService.registerDetach('columnResize', this.cd);
  }

}
