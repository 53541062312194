import {Component, Input} from '@angular/core';
import {
  getUiKeyForSubjectSearchAttribute,
  isCzSkAddressPartiallyFilled,
  SearchOnlyAttribute,
  unifiedSearchFormKeys,
  unifiedSearchFormKeysWithIdentifiers
} from '../../../../services/subjects.service';
import {AddressCompleteDto, AddressFormat, AddressWithTypeDto} from '../../model/addresses.model';
import {SubjectSearchFormData} from '../subject-search-form/subject-search-form.component';
import {SubjectAttributeType} from '../../model/subject-attribute-type';

const SUBJECT_ATTRIBUTE_RENDERING_ORDER: Partial<Record<keyof SubjectSearchFormData, number>> = {
  [SubjectAttributeType.BUSINESS_NAME]: 0,
  [SubjectAttributeType.CID]: 1,
  [SearchOnlyAttribute.DATA_BOX]: 2,
  [SubjectAttributeType.FIRST_NAME]: 3,
  [SubjectAttributeType.SURNAME]: 4,
  [SubjectAttributeType.BIRTH_DATE]: 5,
  [SubjectAttributeType.IDENTITY_CARD_ID]: 6,
  [SubjectAttributeType.DRIVING_LICENCE_ID]: 7,
  [SubjectAttributeType.PASSPORT_ID]: 8,
  [SubjectAttributeType.CLIENT_ID]: 0,
  [SearchOnlyAttribute.ADDRESS]: 10,
};

type SearchCriteriaEntries = Array<[string, string | AddressCompleteDto]>;

@Component({
  selector: 'icz-subject-search-appendix',
  templateUrl: './subject-search-appendix.component.html',
  styleUrls: ['./subject-search-appendix.component.scss'],
})
export class SubjectSearchAppendixComponent {

  @Input({required: true}) searchCriteria!: SubjectSearchFormData;
  @Input() showIdentifiers = false;

  readonly SearchOnlyAttribute = SearchOnlyAttribute;
  readonly SubjectAttributeType = SubjectAttributeType;

  getUiKeyForSubjectSearchAttribute = getUiKeyForSubjectSearchAttribute;

  get orderedSearchCriteriaToDisplay(): SearchCriteriaEntries {
    if (!this.searchCriteria) return [];

    const searchCriteriaEntries: SearchCriteriaEntries = Object.entries({...this.searchCriteria}).filter(([key, value]) => {
      if (!value) return false;
      else {
        if (this.showIdentifiers) {
          return unifiedSearchFormKeysWithIdentifiers.includes(key as SubjectAttributeType);
        }
        else {
          return unifiedSearchFormKeys.includes(key as SubjectAttributeType);
        }
      }
    });

    searchCriteriaEntries.sort(
      // @ts-ignore
      ([key1, _], [key2, __]) => SUBJECT_ATTRIBUTE_RENDERING_ORDER[key1] - SUBJECT_ATTRIBUTE_RENDERING_ORDER[key2]
    );

    return searchCriteriaEntries;
  }

  get searchCriteriaLength(): number {
    return this.orderedSearchCriteriaToDisplay ? Object.keys(this.orderedSearchCriteriaToDisplay).length : 0;
  }

  get isAddressPartiallyFilled(): boolean {
    return isCzSkAddressPartiallyFilled(this.addressWithType.address);
  }

  get addressWithType(): AddressWithTypeDto {
    const address = this.searchCriteria.address;
    return {
      address: {...address[address['_Class']] as AddressCompleteDto, _Class: address['_Class']},
      format: address['_Class'] as AddressFormat
    };
  }

}
