<div class="row gap-24 pb-4">
  <div class="col grow gap-24">
    @if (showCardsForLeader) {
      <icz-card >
        <div class="row grow px-24"><icz-heading label="Vyžadující pozornost - Útvar" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter [linksTo]="unitDocumentTasksRoute" name="Úkolovník"
        [count]="unitTaskCount$ | async" [showWarnColor]="(hasTasksAfterDeadline$ | async)!"></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter [linksTo]="unitDocumentsExpiringRoute" name="Blížící se termín"
        [count]="unitExpiringDocumentsCount$ | async"></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter name="Po termínu" [count]="unitExpiredDocumentsCount$ | async" [showWarnColor]="true"></icz-dashboard-gauge-counter>
      </icz-card>
    }

    @if (showCardsForSecretariat && !showCardsForLeader) {
      <icz-card>
        <div class="row grow px-24"><icz-heading label="Vyžadující pozornost - Útvar" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter [linksTo]="unitDocumentTasksRoute" name="Úkolovník"
        [count]="unitTaskCount$ | async"></icz-dashboard-gauge-counter>
      </icz-card>
    }
  </div>
  <div class="col grow gap-24">
    @if (showCardsForLeader) {
      <icz-card >
        <div class="row grow px-24"><icz-heading label="Podpisová kniha" [level]="'3'"></icz-heading></div>
        <div class="card-divider">
          <icz-dashboard-gauge-counter
            name="Čeká na můj podpis"
            [linksTo]="toSignTaskRoute"
            [count]="toSignTaskCount$ | async"
            [showWarnColor]="(hasToSignTasksAfterDeadline$ | async)!"
          ></icz-dashboard-gauge-counter>
          <icz-dashboard-gauge-counter
            name="Čeká na mé schválení"
            [linksTo]="toApproveTaskRoute"
            [count]="toApproveTaskCount$ | async"
            [showWarnColor]="(hasToApproveTasksAfterDeadline$ | async)!"
          ></icz-dashboard-gauge-counter>
        </div>
        <icz-button size="xlarge" (onAction)="goToPage('')" label="Kompletní přehled v podpisové knize" background></icz-button>
      </icz-card>
    }

    @if (showCardsForSecretariat) {
      <icz-card>
        <div class="row grow px-24"><icz-heading label="Povinný zápis doručenky" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter name="Zapsání doručenky"
        [count]="forUnitDeliveryConfirmationConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
      </icz-card>
    }
  </div>
  <div class="col grow gap-24">
    @if (showCardsForLeader) {
      <icz-card >
        <div class="row grow px-24"><icz-heading label="Aktivity" [level]="'3'"></icz-heading></div>
        <div class="col grow px-24 pb-16 gap-12">
          <icz-button
            (onAction)="goToPage(ownershipTransferTaskRoute)"
            [noCentered]="true"
            svgIcon="handover_to_distribution"
            size="large"
            label="Převody objektů"
          ></icz-button>
        </div>
      </icz-card>
    }
  </div>
</div>
