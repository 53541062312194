/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ermsDataFindByErmsIdentifier } from '../fn/erms-data/erms-data-find-by-erms-identifier';
import { ErmsDataFindByErmsIdentifier$Params } from '../fn/erms-data/erms-data-find-by-erms-identifier';


/**
 * API for managing binary data
 */
@Injectable({ providedIn: 'root' })
export class ApiErmsDataService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ermsDataFindByErmsIdentifier()` */
  static readonly ErmsDataFindByErmsIdentifierPath = '/ermsData/{identifier}/{idSource}/{username}/{sourceId}/{orgCode}';

  /**
   * Find attachment binary data by ERMS identifier.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsDataFindByErmsIdentifier()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsDataFindByErmsIdentifier$Response(params: ErmsDataFindByErmsIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ermsDataFindByErmsIdentifier(this.http, this.rootUrl, params, context);
  }

  /**
   * Find attachment binary data by ERMS identifier.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsDataFindByErmsIdentifier$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsDataFindByErmsIdentifier(params: ErmsDataFindByErmsIdentifier$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ermsDataFindByErmsIdentifier$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
