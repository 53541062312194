/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DisposalScheduleUsageDto } from '../../models/disposal-schedule-usage-dto';

export interface DisposalScheduleFindDisposalScheduleUsages$Params {

/**
 * Id of given disposal schedule
 */
  id: number;
}

export function disposalScheduleFindDisposalScheduleUsages(http: HttpClient, rootUrl: string, params: DisposalScheduleFindDisposalScheduleUsages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DisposalScheduleUsageDto>>> {
  const rb = new RequestBuilder(rootUrl, disposalScheduleFindDisposalScheduleUsages.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<DisposalScheduleUsageDto>>;
    })
  );
}

disposalScheduleFindDisposalScheduleUsages.PATH = '/codebook/disposal-schedule/{id}/find-usages';