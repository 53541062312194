/* tslint:disable */
/* eslint-disable */
export enum DocumentPermission {
  SHOW_IN_LIST = 'SHOW_IN_LIST',
  PROFILE_READ = 'PROFILE_READ',
  PROFILE_WRITE = 'PROFILE_WRITE',
  BULK_RECEIVED_CONSIGNMENT_READ_PROFILE = 'BULK_RECEIVED_CONSIGNMENT_READ_PROFILE',
  BULK_RECEIVED_CONSIGNMENT_WRITE_PROFILE = 'BULK_RECEIVED_CONSIGNMENT_WRITE_PROFILE',
  BULK_RECEIVED_CONSIGNMENT_READ_COMPONENT_CONTENT = 'BULK_RECEIVED_CONSIGNMENT_READ_COMPONENT_CONTENT',
  OWN_CONSIGNMENT_SHOW_LIST = 'OWN_CONSIGNMENT_SHOW_LIST',
  OWN_CONSIGNMENT_ADD = 'OWN_CONSIGNMENT_ADD',
  BULK_OWN_CONSIGNMENT_READ_PROFILE = 'BULK_OWN_CONSIGNMENT_READ_PROFILE',
  BULK_OWN_CONSIGNMENT_WRITE_PROFILE = 'BULK_OWN_CONSIGNMENT_WRITE_PROFILE',
  BULK_OWN_CONSIGNMENT_SHOW_CONTENT_LIST_OR_METADATA = 'BULK_OWN_CONSIGNMENT_SHOW_CONTENT_LIST_OR_METADATA',
  BULK_OWN_CONSIGNMENT_READ_CONTENT = 'BULK_OWN_CONSIGNMENT_READ_CONTENT',
  BULK_OWN_CONSIGNMENT_WRITE_CONTENT = 'BULK_OWN_CONSIGNMENT_WRITE_CONTENT',
  BULK_OWN_CONSIGNMENT_HANDOVER = 'BULK_OWN_CONSIGNMENT_HANDOVER',
  BULK_OWN_CONSIGNMENT_CONFIRM_DELIVERY_RESULT = 'BULK_OWN_CONSIGNMENT_CONFIRM_DELIVERY_RESULT',
  BULK_OWN_CONSIGNMENT_DELETE = 'BULK_OWN_CONSIGNMENT_DELETE',
  COMPONENT_SHOW_LIST = 'COMPONENT_SHOW_LIST',
  COMPONENT_ADD = 'COMPONENT_ADD',
  BULK_COMPONENT_WRITE_PROFILE = 'BULK_COMPONENT_WRITE_PROFILE',
  BULK_COMPONENT_READ_CONTENT = 'BULK_COMPONENT_READ_CONTENT',
  BULK_COMPONENT_WRITE_CONTENT = 'BULK_COMPONENT_WRITE_CONTENT',
  BULK_COMPONENT_CONVERT = 'BULK_COMPONENT_CONVERT',
  BULK_COMPONENT_DELETE = 'BULK_COMPONENT_DELETE',
  RELATED_OBJECT_SHOW_LIST = 'RELATED_OBJECT_SHOW_LIST',
  RELATED_OBJECT_ADD = 'RELATED_OBJECT_ADD',
  RELATED_OBJECT_REMOVE = 'RELATED_OBJECT_REMOVE',
  SUBJECT_SHOW_LIST = 'SUBJECT_SHOW_LIST',
  SUBJECT_ADD = 'SUBJECT_ADD',
  SUBJECT_READ = 'SUBJECT_READ',
  SUBJECT_WRITE = 'SUBJECT_WRITE',
  SUBJECT_REMOVE = 'SUBJECT_REMOVE',
  PERMISSION_SET_READ = 'PERMISSION_SET_READ',
  PERMISSION_SET_WRITE = 'PERMISSION_SET_WRITE',
  HISTORY_SHOW_LIST = 'HISTORY_SHOW_LIST',
  ACTIVITY_SHOW_LIST = 'ACTIVITY_SHOW_LIST',
  ACTIVITY_MANAGE = 'ACTIVITY_MANAGE',
  TASK_SETTLE_OR_REJECT = 'TASK_SETTLE_OR_REJECT',
  REGISTER = 'REGISTER',
  SETTLE_OR_UNSETTLE = 'SETTLE_OR_UNSETTLE',
  PASS_ON_TO_EXTERNAL_ORGANIZATION = 'PASS_ON_TO_EXTERNAL_ORGANIZATION',
  CANCEL_OR_RESTORE = 'CANCEL_OR_RESTORE',
  CREATE_FILE = 'CREATE_FILE',
  DUPLICATE = 'DUPLICATE'
}
