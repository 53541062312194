<div [formGroup]="form" [waiting]="loadingService.isLoading(this)" class="row grow">
  @switch (detailLevel) {
    @case (DisposalTipDetailLevel.VALUE_WITH_LABEL) {
      <icz-form-field [fieldDisabled]="true" [value]="disposalSchedule" class="grow"
      label="Skartační režim"></icz-form-field>
    }
    @case (DisposalTipDetailLevel.SIMPLE_EDIT) {
      <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
      formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
      <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
      <icz-form-autocomplete
        [options]="retentionTriggerTypesOptions"
        class="grow-2"
        formControlName="retentionTriggerTypeId"
        label="Spouštěcí událost"
      ></icz-form-autocomplete>
    }
  }
</div>
