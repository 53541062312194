import {inject, Injectable} from '@angular/core';
import {Cacheable} from 'ts-cacheable';
import {from, Observable, Subject} from 'rxjs';
import {OrganizationDto} from '|api/commons';
import {map, switchMap, take} from 'rxjs/operators';
import axios from 'axios';
import {EnvironmentService} from '../core/services/environment.service';

const cacheReset$ = new Subject<void>();

const cacheConfig = {
  maxAge: 10 * 60 * 1000, /* ms = 10 min */
  cacheBusterObserver: cacheReset$,
};

@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {

  private environmentService = inject(EnvironmentService);

  @Cacheable(cacheConfig)
  getOrganizations(): Observable<OrganizationDto[]> {
    return this.environmentService.environment$.pipe(
      take(1),
      switchMap(environment => {
        return from(axios.get(
          // todo(rb) interpolate the path!
          `${environment.hostUrl}/api/config-server/api/rest/organization`
        ));

      }),
      map(organizationsResponse => organizationsResponse.data as OrganizationDto[]),
    );
  };

  resetCache() {
    cacheReset$.next();
  }

}
