import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrganizationalStructureService} from '../../core/services/organizational-structure.service';

@Pipe({
  name: 'findOrganizationalUnitById',
  pure: true,
})
export class FindOrganizationalUnitByIdPipe implements PipeTransform {

  private orgStructureService = inject(OrganizationalStructureService);

  transform(orgUnitIds: Nullable<number[] | number>): Observable<string> {
    if (orgUnitIds && !Array.isArray(orgUnitIds)) {
      orgUnitIds = [orgUnitIds];
    }

    if (isNil(orgUnitIds)) {
      return of('');
    }
    else {
      return this.orgStructureService.organizationalUnitsOptions().pipe(
        map(organizationalUnits => {
            return organizationalUnits
              .filter(ouOption => ouOption && (orgUnitIds as number[]).includes(ouOption.value))
              .map(ou => ou.label)
              .join(', ');
          }
        ),
      );
    }
  }

}
