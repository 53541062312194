import {ChangeDetectionStrategy, Component, inject, Input, TemplateRef, ViewChild} from '@angular/core';
import {SelectLikeField} from '../select-like-field';
import {
  ComposableFormAutocompleteComponent
} from './composable-form-autocomplete/composable-form-autocomplete.component';
import {IAutocompleteNoResultsContext} from './form-autocomplete-list/form-autocomplete-list.component';
import {makeDefaultOptionsDefinition, OptionsDefinitionFactory} from './form-autocomplete.model';
import {Option} from '../../../model';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';

/**
 * An autocomplete with a vertical list of available values
 * built using ComposableFormAutocomplete component
 *
 * Responsibilities:
 * - Defining desired @Inputs and @Outputs
 * - Composing the autocomplete itself from other components (see component template)
 * - Passing @Inputs to inner components
 *
 * More details about creating your own autocomplete
 * components are in ComposableFormAutocomplete JSDoc
 */
@Component({
  selector: 'icz-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAutocompleteComponent<T extends string | number = string | number> extends SelectLikeField<T> {

  protected loadingService = inject(LoadingIndicatorService);

  @ViewChild('composableFormAutocomplete')
  private composableFormAutocomplete!: ComposableFormAutocompleteComponent;

  @Input()
  searchIndexer: Nullable<(o: Option<T>) => string>;
  @Input()
  noResultsTemplate: Nullable<TemplateRef<IAutocompleteNoResultsContext>>;
  @Input()
  optionsDefinitionFactory: OptionsDefinitionFactory = makeDefaultOptionsDefinition;
  @Input()
  minSearchTermLength = 0;
  @Input()
  showValidationStatus = true;
  @Input()
  hideDefaultTooltips = false;
  @Input()
  autoSizeMax = 1;
  @Input()
  allowLoadingIndicator: Nullable<boolean>;
  @Input()
  hideSearchField = false;

  // @publicApi
  closeOptions() {
    this.composableFormAutocomplete.optionsClosed(true);
  }

}
