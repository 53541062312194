import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../../form-elements/validators/icz-validators/icz-validators';
import {addressDtoToForm, createAddressFormGroup} from '../../../../subjects/address/address-utils';
import {Option} from '../../../../../../model';
import {
  getAddressTypeLabelForLegalLike,
  getAddressTypeLabelForNaturalPerson
} from '../../../../subjects/address-type-label.pipe';
import {LoadingIndicatorService} from '../../../../../essentials/loading-indicator.service';
import {AddressFormat, AddressWithTypeDto} from '../../../../model/addresses.model';
import {ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP} from '../../../../subjects/address/address.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SubjectRecordClassification} from '|api/commons';
import {injectModalData, injectModalRef} from '../../../../../../lib/modals';
import {SubjectAttributeType} from '../../../../model/subject-attribute-type';

export interface ConsignmentAddressDialogData {
  addressToEdit?: Nullable<AddressWithTypeDto>;
  nonpersistentSubjectMode?: boolean;
  createModeSubjectClassification?: Nullable<SubjectRecordClassification>;
}

export interface ConsignmentAddressDialogResult {
  address: AddressWithTypeDto;
  valueCorrectionMode?: Nullable<boolean>;
}

@Component({
  selector: 'icz-consignment-address-dialog',
  templateUrl: './consignment-address-dialog.component.html',
  styleUrls: ['./consignment-address-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ConsignmentAddressDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<ConsignmentAddressDialogResult>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private destroyRef = inject(DestroyRef);
  protected dialogData = injectModalData<ConsignmentAddressDialogData>();

  form = new IczFormGroup({
    addressType: new IczFormControl<Nullable<SubjectAttributeType>>(SubjectAttributeType.MAILING_ADDRESS, [IczValidators.required()]),
    address: createAddressFormGroup(),
  });

  formGroupsToCheck!: string[];
  addressAttributeType = SubjectAttributeType.ADDITIONAL_ADDRESS;
  isAddressValidForIdentification = false;
  valueCorrectionMode = true;

  get addressFormGroup(): IczFormGroup {
    return this.form.get('address') as IczFormGroup;
  }

  get isCreateMode() {
    return !this.dialogData.addressToEdit;
  }

  get isCzechAddress() {
    return this.addressFormGroup!.get('value')!.get('_Class')!.value === AddressFormat.CzechAddressDto;
  }

  naturalPersonAddressTypeOptions: Option<SubjectAttributeType>[] = [
    SubjectAttributeType.ADDITIONAL_ADDRESS,
    SubjectAttributeType.MAILING_ADDRESS,
    SubjectAttributeType.RESIDENTIAL_ADDRESS,
    SubjectAttributeType.POSTAL_BOX,
  ].map(attributeType => ({
    value: attributeType,
    label: getAddressTypeLabelForNaturalPerson(attributeType),
  }));

  legalLikeAddressTypeOptions: Option<SubjectAttributeType>[] = [
    SubjectAttributeType.MAILING_ADDRESS,
    SubjectAttributeType.RESIDENTIAL_ADDRESS,
    SubjectAttributeType.POSTAL_BOX,
  ].map(attributeType => ({
    value: attributeType,
    label: getAddressTypeLabelForLegalLike(attributeType),
  }));

  readonly SubjectAttributeType = SubjectAttributeType;
  readonly SubjectRecordClassification = SubjectRecordClassification;
  readonly ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP = ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.form.get('addressType')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) this.addressAttributeType = value;
    });

    if (!this.isCreateMode) {
      const addressClass = this.dialogData.addressToEdit!.address._Class as AddressFormat;

      this.form.patchValue({
        addressType: this.dialogData.addressToEdit!.type,
        address: {
          ...addressDtoToForm(this.dialogData.addressToEdit!.address),
          country: addressClass === AddressFormat.CzechAddressDto ? 'CZE' : (
            addressClass === AddressFormat.SlovakAddressDto ? 'SVK' :
              this.dialogData.addressToEdit!.address.country
          ),
        },
      });
    }
  }

  submit() {
    const formValue = this.form.value;
    const addressFormValue = this.form.value.address.value;

    this.modalRef.close({
      address: {
        address: {
          _Class: addressFormValue._Class,
          ...addressFormValue[addressFormValue._Class],
          country: addressFormValue.country,
        },
        format: addressFormValue._Class,
        type: formValue.addressType,
      } as AddressWithTypeDto,
      valueCorrectionMode: this.isCreateMode ? null : this.valueCorrectionMode,
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
