/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiCirculationTaskService } from './services/api-circulation-task.service';
import { ApiCirculationTaskBulkActionsService } from './services/api-circulation-task-bulk-actions.service';
import { ApiCirculationTaskSystemService } from './services/api-circulation-task-system.service';
import { ApiCirculationTaskStatementService } from './services/api-circulation-task-statement.service';
import { ApiCirculationTaskComponentSigningService } from './services/api-circulation-task-component-signing.service';
import { ApiCirculationTaskHandoverService } from './services/api-circulation-task-handover.service';
import { ApiCirculationTaskHandoverToOwnHandsService } from './services/api-circulation-task-handover-to-own-hands.service';
import { ApiCirculationTaskApprovalService } from './services/api-circulation-task-approval.service';
import { ApiCirculationActivityService } from './services/api-circulation-activity.service';
import { ApiCirculationActivityBulkActionsService } from './services/api-circulation-activity-bulk-actions.service';
import { ApiCirculationTaskAcknowledgementService } from './services/api-circulation-task-acknowledgement.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiCirculationTaskService,
    ApiCirculationTaskBulkActionsService,
    ApiCirculationTaskSystemService,
    ApiCirculationTaskStatementService,
    ApiCirculationTaskComponentSigningService,
    ApiCirculationTaskHandoverService,
    ApiCirculationTaskHandoverToOwnHandsService,
    ApiCirculationTaskApprovalService,
    ApiCirculationActivityService,
    ApiCirculationActivityBulkActionsService,
    ApiCirculationTaskAcknowledgementService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
