/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { officeDeskRegionCreate } from '../fn/office-desk-region/office-desk-region-create';
import { OfficeDeskRegionCreate$Params } from '../fn/office-desk-region/office-desk-region-create';
import { officeDeskRegionDelete } from '../fn/office-desk-region/office-desk-region-delete';
import { OfficeDeskRegionDelete$Params } from '../fn/office-desk-region/office-desk-region-delete';
import { OfficeDeskRegionDto } from '../models/office-desk-region-dto';
import { officeDeskRegionFindAll } from '../fn/office-desk-region/office-desk-region-find-all';
import { OfficeDeskRegionFindAll$Params } from '../fn/office-desk-region/office-desk-region-find-all';
import { officeDeskRegionFindById } from '../fn/office-desk-region/office-desk-region-find-by-id';
import { OfficeDeskRegionFindById$Params } from '../fn/office-desk-region/office-desk-region-find-by-id';
import { officeDeskRegionSearch } from '../fn/office-desk-region/office-desk-region-search';
import { OfficeDeskRegionSearch$Params } from '../fn/office-desk-region/office-desk-region-search';
import { officeDeskRegionUpdate } from '../fn/office-desk-region/office-desk-region-update';
import { OfficeDeskRegionUpdate$Params } from '../fn/office-desk-region/office-desk-region-update';
import { PageOfficeDeskRegionDto } from '../models/page-office-desk-region-dto';


/**
 * Controller for office desk region
 */
@Injectable({ providedIn: 'root' })
export class ApiOfficeDeskRegionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `officeDeskRegionFindById()` */
  static readonly OfficeDeskRegionFindByIdPath = '/codebook/office-desk-region/{id}';

  /**
   * Find office desk region object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskRegionFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionFindById$Response(params: OfficeDeskRegionFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskRegionDto>> {
    return officeDeskRegionFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find office desk region object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskRegionFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionFindById(params: OfficeDeskRegionFindById$Params, context?: HttpContext): Observable<OfficeDeskRegionDto> {
    return this.officeDeskRegionFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskRegionDto>): OfficeDeskRegionDto => r.body)
    );
  }

  /** Path part for operation `officeDeskRegionUpdate()` */
  static readonly OfficeDeskRegionUpdatePath = '/codebook/office-desk-region/{id}';

  /**
   * Update existing office desk region object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskRegionUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskRegionUpdate$Response(params: OfficeDeskRegionUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskRegionDto>> {
    return officeDeskRegionUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing office desk region object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskRegionUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskRegionUpdate(params: OfficeDeskRegionUpdate$Params, context?: HttpContext): Observable<OfficeDeskRegionDto> {
    return this.officeDeskRegionUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskRegionDto>): OfficeDeskRegionDto => r.body)
    );
  }

  /** Path part for operation `officeDeskRegionDelete()` */
  static readonly OfficeDeskRegionDeletePath = '/codebook/office-desk-region/{id}';

  /**
   * Delete existing office desk region object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskRegionDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionDelete$Response(params: OfficeDeskRegionDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return officeDeskRegionDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing office desk region object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskRegionDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionDelete(params: OfficeDeskRegionDelete$Params, context?: HttpContext): Observable<void> {
    return this.officeDeskRegionDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `officeDeskRegionFindAll()` */
  static readonly OfficeDeskRegionFindAllPath = '/codebook/office-desk-region';

  /**
   * Finds all office desk region object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskRegionFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionFindAll$Response(params?: OfficeDeskRegionFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OfficeDeskRegionDto>>> {
    return officeDeskRegionFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all office desk region object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskRegionFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionFindAll(params?: OfficeDeskRegionFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<OfficeDeskRegionDto>> {
    return this.officeDeskRegionFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OfficeDeskRegionDto>>): Array<OfficeDeskRegionDto> => r.body)
    );
  }

  /** Path part for operation `officeDeskRegionCreate()` */
  static readonly OfficeDeskRegionCreatePath = '/codebook/office-desk-region';

  /**
   * Create new office desk region object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskRegionCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskRegionCreate$Response(params: OfficeDeskRegionCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskRegionDto>> {
    return officeDeskRegionCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new office desk region object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskRegionCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskRegionCreate(params: OfficeDeskRegionCreate$Params, context?: HttpContext): Observable<OfficeDeskRegionDto> {
    return this.officeDeskRegionCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskRegionDto>): OfficeDeskRegionDto => r.body)
    );
  }

  /** Path part for operation `officeDeskRegionSearch()` */
  static readonly OfficeDeskRegionSearchPath = '/codebook/office-desk-region/search-api';

  /**
   * Finds foffice desk region objects based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskRegionSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionSearch$Response(params?: OfficeDeskRegionSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOfficeDeskRegionDto>> {
    return officeDeskRegionSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds foffice desk region objects based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskRegionSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskRegionSearch(params?: OfficeDeskRegionSearch$Params|null|undefined, context?: HttpContext): Observable<PageOfficeDeskRegionDto> {
    return this.officeDeskRegionSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfficeDeskRegionDto>): PageOfficeDeskRegionDto => r.body)
    );
  }

}
