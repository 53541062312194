import {ButtonDisablerFn} from '../../../essentials/button-collection/button-collection.component';
import {OwnDocumentDto} from '|api/document';
import {OwnConsignmentStatus} from '|api/commons';
import {
  DeliveryResultDto,
  DeliveryServiceAdditionalDto,
  DeliveryServiceCombinationDto,
  DeliveryTypeDto
} from '|api/codebook';
import {GenericOwnConsignment} from '../../own-consignment-table/model/own-consignment-model';
import {isNilOrEmptyString} from '../../../../lib/utils';
import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {CommonToolbarDisablers} from '../../document-toolbar/services/toolbar-common.disablers';
import {ADDITIONAL_SERVICE_ISDS_FICTIONAL_DELIVERY_CODE, DELIVERY_TYPE_DATABOX_CODE} from '../../shared-document.utils';

export class ConsignmentToolbarButtonsDisablers {

  static generateDisablers(consignments: GenericOwnConsignment[], validators: OperationValidator<GenericOwnConsignment>[]): ButtonDisablerFn[] {
    const res: ButtonDisablerFn[] = [];
    validators.forEach(validator => {
      res.push(
          () => {
            return CommonToolbarDisablers.evalSelectionWithValidator(consignments, validator);
          }
      );
    });
    return res;
  }

  static isDocumentWithoutRefNumber(document: OwnDocumentDto): ButtonDisablerFn {
    return () => {
      return (document && isNilOrEmptyString(document.refNumber)) ? {tooltip: 'Nelze založit vypravení s nezaevidovaným dokumentem'} : null;
    };
  }

  static isDeliveryResultRecordUnsupported(
    consignment: GenericOwnConsignment,
    deliveryTypes: DeliveryTypeDto[],
    deliveryResults: DeliveryResultDto[],
    additionalServices: DeliveryServiceAdditionalDto[],
    deliveryServiceCombinations: DeliveryServiceCombinationDto[],
  ): ButtonDisablerFn {
    const fictionalDeliveryAdditionalServiceId = additionalServices.find(as => as.code === ADDITIONAL_SERVICE_ISDS_FICTIONAL_DELIVERY_CODE)!.id;
    const databoxDeliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_DATABOX_CODE)!.id;

    const databoxWithoutFictionDeliveryServiceCombinations = deliveryServiceCombinations
      .filter(dsc => (
        dsc.basicService.deliveryTypeId === databoxDeliveryTypeId &&
        !dsc.additionalServices.find(as => as.id === fictionalDeliveryAdditionalServiceId)
      ))
      .map(dsc => dsc.id);

    return () => {
      let isDeliveryResultRecordSupported = false;

      const deliveryTypeId = consignment.deliveryTypeId;
      const isProcessedByFilingOffice = deliveryTypes.find(dt => dt.id === deliveryTypeId)?.processedByFilingOffice;

      const manualDeliveryResultDeliveryTypeIds = deliveryResults
        .filter(dr => !dr.isInitiatedAutomatically)
        .map(dr => dr.deliveryTypeId)
        .filter(Boolean);

      const isManualDeliveryResultRecordSupported = manualDeliveryResultDeliveryTypeIds.includes(deliveryTypeId);

      if (!isProcessedByFilingOffice) {
        // todo(rb) add condition after implemented: "Dokument zásilky ještě není ve spisovně"
        isDeliveryResultRecordSupported = isManualDeliveryResultRecordSupported;
      }
      else if (consignment.deliveryTypeId === databoxDeliveryTypeId) {
        isDeliveryResultRecordSupported = databoxWithoutFictionDeliveryServiceCombinations.includes(consignment.deliveryServiceCombinationId!);
      }
      else {
        const filingOfficeConsignmentStatesForManualDeliveryResult = [
          OwnConsignmentStatus.DISPATCHED,
          OwnConsignmentStatus.DELIVERED,
          OwnConsignmentStatus.DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION,
          OwnConsignmentStatus.NOT_DELIVERED,
        ];

        isDeliveryResultRecordSupported = (
          isManualDeliveryResultRecordSupported &&
          filingOfficeConsignmentStatesForManualDeliveryResult.includes(consignment.status!)
          // todo(rb) add condition after implemented: "Dokument zásilky ještě není ve spisovně"
        );
      }
      return (!isDeliveryResultRecordSupported) ? {tooltip: 'Zásilka není ve stavu umožňující manuálne zapsat doručení.'} : null;
    };
  }

}
