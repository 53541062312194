import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY
} from '@angular/material/datepicker';

@Component({
  selector: 'icz-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
})
export class CalendarRangeComponent {

  @Input()
  selectedDateRange: Nullable<DateRange<Date>>;
  @Output()
  selectedDateRangeChange = new EventEmitter<DateRange<Date>>();

  _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
    }
    else {
      this.selectedDateRange = new DateRange(date, null);
    }

    this.selectedDateRangeChange.emit(this.selectedDateRange);
  }

}
