import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CirculationActivityState, CirculationTaskState} from '|api/commons';
import {CirculationActivityDto} from '|api/flow';
import {IczTagColor} from '../../../essentials/tag/tag.component';

@Component({
  selector: 'icz-task-state-tag',
  templateUrl: './task-state-tag.component.html',
  styleUrls: ['./task-state-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskStateTagComponent {
  @Input({required: true})
  taskState: Nullable<CirculationTaskState>;
  @Input()
  parentActivity!: Nullable<CirculationActivityDto>;

  get tagText() {
    if (isNil(this.taskState)) {
      if (this.parentActivity?.activityState === CirculationActivityState.REVOKED) {
        return 'Odvolaný';
      }
      else {
        return 'Čeká v pořadí';
      }
    }
    else {
      return `en.circulationTaskState.${this.taskState}`;
    }
  }

  get taskStateColor(): IczTagColor {
    return !this.taskState ? 'grey' : this.taskStateColors[this.taskState]!;
  }

  taskStateColors: Record<CirculationTaskState, IczTagColor> = {
    [CirculationTaskState.APPROVED_BY_TARGET]: 'green',
    [CirculationTaskState.STATEMENT_DONE_BY_TARGET]: 'green',
    [CirculationTaskState.TAKEN_OVER_BY_TARGET]: 'green',
    [CirculationTaskState.TAKEN_OVER_BY_INITIATOR]: 'green',
    [CirculationTaskState.ACKNOWLEDGED_BY_TARGET]: 'green',
    [CirculationTaskState.SIGNED_BY_TARGET]: 'green',
    [CirculationTaskState.IN_PROGRESS_BY_SYSTEM]: 'orange',
    [CirculationTaskState.ASSIGNED_TO_TARGET]: 'orange',
    [CirculationTaskState.ASSIGNED_TO_INITIATOR]: 'orange',
    [CirculationTaskState.DISAPPROVED_BY_TARGET]: 'red',
    [CirculationTaskState.PASSED_BY_TARGET]: 'green',
    [CirculationTaskState.REJECTED_BY_TARGET]: 'grey',
    [CirculationTaskState.REVOKED_BY_INITIATOR]: 'grey',
    [CirculationTaskState.MANAGEMENT_DONE_BY_SYSTEM]: 'green',
    [CirculationTaskState.REVOKED_BY_SYSTEM]: 'grey',
    [CirculationTaskState.WAIT_FOR_ISSD_TAKEOVER]:'orange',
    [CirculationTaskState.TAKEN_OVER_BY_ISSD]:'green',
    [CirculationTaskState.RETURNED_BY_ISSD]: 'green',
    [CirculationTaskState.REJECTED_BY_ISSD]: 'red',
    [CirculationTaskState.RETURNED_BY_TARGET]: 'red',
  };
}
