<form [waiting]="loadingService.isLoading(this)" [formGroup]="form">
  <icz-section label="Komu předat spis">
    <icz-org-structure-selector
      [form]="form"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      controlName="orgStructureElementId"
      label="Funkční místo nebo organizační jednotka - předání originálu"
    ></icz-org-structure-selector>
  </icz-section>
  <icz-section label="Instrukce a termín">
    <div class="row">
      <icz-form-field class="grow-5"
                      formControlName="targetTaskInstruction"
                      label="Instrukce"
                      [autoSizeMax]="3">
      </icz-form-field>
      <icz-date-picker class="grow-3"
                       formControlName="deadline"
                       label="Termín úkolu"
                       [selectableDates]="deadlineValidator"
      ></icz-date-picker>
    </div>
  </icz-section>
  <!--<icz-section *ngIf="isFileObject(initEntity) && initEntity?.fileForm === FileForm.ANALOG" label="Tisk">
    <icz-checkbox testingFeature
                  formControlName="printHandoverProtocol"
                  label="Tisknout předávací protokol">
    </icz-checkbox>
  </icz-section>-->
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="submit()" primary label="Předat"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
