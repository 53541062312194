/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkPostingFormTemplateCreate } from '../fn/bulk-posting-form-template/bulk-posting-form-template-create';
import { BulkPostingFormTemplateCreate$Params } from '../fn/bulk-posting-form-template/bulk-posting-form-template-create';
import { bulkPostingFormTemplateDelete } from '../fn/bulk-posting-form-template/bulk-posting-form-template-delete';
import { BulkPostingFormTemplateDelete$Params } from '../fn/bulk-posting-form-template/bulk-posting-form-template-delete';
import { BulkPostingFormTemplateDto } from '../models/bulk-posting-form-template-dto';
import { bulkPostingFormTemplateFindAll } from '../fn/bulk-posting-form-template/bulk-posting-form-template-find-all';
import { BulkPostingFormTemplateFindAll$Params } from '../fn/bulk-posting-form-template/bulk-posting-form-template-find-all';
import { bulkPostingFormTemplateFindById } from '../fn/bulk-posting-form-template/bulk-posting-form-template-find-by-id';
import { BulkPostingFormTemplateFindById$Params } from '../fn/bulk-posting-form-template/bulk-posting-form-template-find-by-id';


/**
 * Contains endpoints related to Bulk Posting Form Template
 */
@Injectable({ providedIn: 'root' })
export class ApiBulkPostingFormTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bulkPostingFormTemplateFindAll()` */
  static readonly BulkPostingFormTemplateFindAllPath = '/sad/bulk-posting-form-template/';

  /**
   * Finds all BulkPostingFormTemplateDto.
   *
   *
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormTemplateFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormTemplateFindAll$Response(params?: BulkPostingFormTemplateFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<BulkPostingFormTemplateDto>>> {
    return bulkPostingFormTemplateFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all BulkPostingFormTemplateDto.
   *
   *
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormTemplateFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormTemplateFindAll(params?: BulkPostingFormTemplateFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<BulkPostingFormTemplateDto>> {
    return this.bulkPostingFormTemplateFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulkPostingFormTemplateDto>>): Array<BulkPostingFormTemplateDto> => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormTemplateCreate()` */
  static readonly BulkPostingFormTemplateCreatePath = '/sad/bulk-posting-form-template/';

  /**
   * Creates BulkPostingFormTemplate.
   *
   * Creates BulkPostingFormTemplate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormTemplateCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPostingFormTemplateCreate$Response(params: BulkPostingFormTemplateCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkPostingFormTemplateDto>> {
    return bulkPostingFormTemplateCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates BulkPostingFormTemplate.
   *
   * Creates BulkPostingFormTemplate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormTemplateCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPostingFormTemplateCreate(params: BulkPostingFormTemplateCreate$Params, context?: HttpContext): Observable<BulkPostingFormTemplateDto> {
    return this.bulkPostingFormTemplateCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkPostingFormTemplateDto>): BulkPostingFormTemplateDto => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormTemplateFindById()` */
  static readonly BulkPostingFormTemplateFindByIdPath = '/sad/bulk-posting-form-template/{id}';

  /**
   * Find bulk posting form template by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormTemplateFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormTemplateFindById$Response(params: BulkPostingFormTemplateFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkPostingFormTemplateDto>> {
    return bulkPostingFormTemplateFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find bulk posting form template by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormTemplateFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormTemplateFindById(params: BulkPostingFormTemplateFindById$Params, context?: HttpContext): Observable<BulkPostingFormTemplateDto> {
    return this.bulkPostingFormTemplateFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkPostingFormTemplateDto>): BulkPostingFormTemplateDto => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormTemplateDelete()` */
  static readonly BulkPostingFormTemplateDeletePath = '/sad/bulk-posting-form-template/{id}';

  /**
   * Deletes existing posting form template by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormTemplateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormTemplateDelete$Response(params: BulkPostingFormTemplateDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkPostingFormTemplateDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes existing posting form template by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormTemplateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormTemplateDelete(params: BulkPostingFormTemplateDelete$Params, context?: HttpContext): Observable<void> {
    return this.bulkPostingFormTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
