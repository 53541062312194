import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {DigitalComponentVersionSignatureFlagType} from '|api/commons';
import {DigitalComponentVersionSignatureFlagDto} from '|api/component';
import {SignatureValidationDataDto} from '|api/sad';
import {IczModalService} from '../../../services/icz-modal.service';
import {
  DigitalComponentSignatureDetailData,
  DigitalComponentSignatureDetailDialogComponent
} from '../essl-components/components/digital-component-signature-detail-dialog/digital-component-signature-detail-dialog.component';
import {AuthConversionClauseDto} from '|api/document';

// FlagDigitalComponentFlagDtoEnum split into parts:
// {type, state} = {DigitalComponentFlagType, DigitalComponentFlagState}

enum DigitalComponentFlagType {
  SIGNATURE = 'SIGNATURE',
  SIGNATURE_WITH_TIMESTAMP = 'SIGNATURE_WITH_TIMESTAMP',
  MARK = 'MARK',
  MARK_WITH_TIMESTAMP = 'MARK_WITH_TIMESTAMP',
}

enum DigitalComponentFlagState {
  VALID = 'VALID',
  INVALID = 'INVALID',
  INDETERMINATE = 'INDETERMINATE',
}

export enum FlagTypeIcon {
  SIGNATURE = 'signature',
  TIMESTAMP = 'stamp',
  MARK = 'seal',
}

export enum FlagStateIcon {
  VALID = 'checked',
  INVALID = 'cross',
  INDETERMINATE = 'question_mark_orange',
}

@Component({
  selector: 'icz-digital-component-flags',
  templateUrl: './digital-component-flags.component.html',
  styleUrls: ['./digital-component-flags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalComponentFlagsComponent {

  private modalService = inject(IczModalService);

  /**
   * digitalComponentVersionId and signatureValidationDataDto are mutually exclusive:
   * - digitalComponentVersionId is used for ESSL components,
   * - signatureValidationDataDto is used for EPDZ components.
   */
  @Input()
  digitalComponentVersionId: Nullable<number>;
  @Input()
  signatureValidationData: Nullable<SignatureValidationDataDto>;

  @Input({required: true})
  flags: Nullable<DigitalComponentVersionSignatureFlagDto[]> = null;

  @Input()
  authConversionClause: Nullable<AuthConversionClauseDto>;

  private get _flags() {
    return this.flags ?? [];
  }

  get restFlagsCount(): number {
    return this._flags.length - 1;
  }

  get firstFlag(): DigitalComponentVersionSignatureFlagDto {
    return this._flags.filter(f => f.flag !== DigitalComponentVersionSignatureFlagType.NONE)[0];
  }

  openDigitalComponentSignatureDetail() {
    this.modalService.openComponentInModal<void, DigitalComponentSignatureDetailData>({
      component: DigitalComponentSignatureDetailDialogComponent,
      modalOptions: {
        titleTemplate: 'Detail podpisu',
        width: 1000,
        height: 700,
      },
      data: {
        digitalComponentVersionId: this.digitalComponentVersionId,
        signatureValidationData: this.signatureValidationData,
        lastClauseDate: this.authConversionClause?.conversionDate,
      },
    });
  }

  getFlagStateIcon(flag: DigitalComponentVersionSignatureFlagDto): Nullable<FlagStateIcon> {
    const flagState = this.getFlagState(flag);

    switch (flagState) {
      case DigitalComponentFlagState.VALID:
        return FlagStateIcon.VALID;
      case DigitalComponentFlagState.INVALID:
        return FlagStateIcon.INVALID;
      case DigitalComponentFlagState.INDETERMINATE:
        return FlagStateIcon.INDETERMINATE;
      default:
        return null;
    }
  }

  getFlagTypeIcons(flag: DigitalComponentVersionSignatureFlagDto): FlagTypeIcon[] {
    const viewFlagType = this.getFlagType(flag);

    switch (viewFlagType) {
      case DigitalComponentFlagType.MARK:
        return [FlagTypeIcon.MARK];
      case DigitalComponentFlagType.MARK_WITH_TIMESTAMP:
        return [FlagTypeIcon.MARK, FlagTypeIcon.TIMESTAMP];
      case DigitalComponentFlagType.SIGNATURE:
        return [FlagTypeIcon.SIGNATURE];
      case DigitalComponentFlagType.SIGNATURE_WITH_TIMESTAMP:
        return [FlagTypeIcon.SIGNATURE, FlagTypeIcon.TIMESTAMP];
      default:
        return [];
    }
  }

  private getFlagType(flag: DigitalComponentVersionSignatureFlagDto): DigitalComponentFlagType {
    const flagParts = flag.flag!.split('_');
    flagParts.pop();

    return flagParts.join('_') as DigitalComponentFlagType;
  }

  private getFlagState(flag: DigitalComponentVersionSignatureFlagDto): Nullable<DigitalComponentFlagState> {
    const flagParts = flag.flag!.split('_');

    if (flagParts.length >= 1) {
      return flagParts[flagParts.length - 1] as DigitalComponentFlagState;
    }

    return null;
  }

}
