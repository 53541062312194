<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-address
    #addressComponent
    [form]="addressFormGroup"
    groupName="."
    [addressAttributeType]="addressAttributeType"
    [enforceValidators]="true"
    [showSectionExpansionHint]="false"
    (addressValidityForIdentificationChanged)="isAddressValidForIdentification = $event"
  ></icz-address>
  @if (isCreateMode) {
    @if (dialogData.createModeSubjectClassification === SubjectRecordClassification.FO) {
      <icz-form-autocomplete
        formControlName="addressType"
        label="Typ adresy"
        [options]="naturalPersonAddressTypeOptions"
      ></icz-form-autocomplete>
    }
    @else {
      <icz-form-autocomplete
        formControlName="addressType"
        label="Typ adresy"
        [options]="legalLikeAddressTypeOptions"
      ></icz-form-autocomplete>
    }
  }
  @if (!isCreateMode && !dialogData.nonpersistentSubjectMode) {
    <icz-checkbox
      [value]="$any(!valueCorrectionMode)" (valueChange)="valueCorrectionMode = !valueCorrectionMode"
      label="Uchovat původní hodnotu pro pozdější vyhledávání" class="ml-16"
    ></icz-checkbox>
  }
</form>

<icz-form-buttons>
  <ng-container leftButtons>
    <icz-button iczFormSubmit
      [disabled]="loadingService.isLoading(this)"
      (onAction)="submit()"
      primary [label]="isCreateMode ? 'Přidat adresu' : 'Upravit adresu'"
    ></icz-button>
    @if (isCzechAddress) {
      <icz-button
        [disabled]="!isAddressValidForIdentification"
        [iczTooltip]="!isAddressValidForIdentification ? ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP : null"
        (onAction)="addressComponent.identifyAddress()"
        label="Ověřit adresu"
      ></icz-button>
    }
  </ng-container>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
