import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PrimitiveValueFormField} from '../form-field';
import {Option} from '../../../model';

@Component({
  selector: 'icz-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonToggleGroupComponent<T extends string | number = string | number> extends PrimitiveValueFormField<T> {
  @Input({required: true}) options!: Option[];

  _valueChanged(value: T) {
    this.value = value;
    this.valueChange.emit(value);
    this.blur.emit();
  }

  isButtonToggled(value: T) {
    return this.value === value;
  }
}
