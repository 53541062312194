/* tslint:disable */
/* eslint-disable */

/**
 * Identifier Generator Type
 */
export enum IdentifierGeneratorType {
  ID_GENERATOR = 'ID_GENERATOR',
  UID_GENERATOR = 'UID_GENERATOR',
  SPECIALIZED_UID_GENERATOR = 'SPECIALIZED_UID_GENERATOR',
  FILING_REGISTER_REF_NUMBER_GENERATOR = 'FILING_REGISTER_REF_NUMBER_GENERATOR',
  SEPARATE_FILING_REGISTER_REF_NUMBER_GENERATOR = 'SEPARATE_FILING_REGISTER_REF_NUMBER_GENERATOR',
  PRIOR_ORDER_NUMBER_GENERATOR = 'PRIOR_ORDER_NUMBER_GENERATOR',
  COLLECTION_FILE_REF_NUMBER_GENERATOR = 'COLLECTION_FILE_REF_NUMBER_GENERATOR',
  FILE_REF_NUMBER_GENERATOR = 'FILE_REF_NUMBER_GENERATOR',
  SPECIALIZED_FILE_REF_NUMBER_GENERATOR = 'SPECIALIZED_FILE_REF_NUMBER_GENERATOR',
  STORAGE_UNIT_NUMBER_GENERATOR = 'STORAGE_UNIT_NUMBER_GENERATOR'
}
