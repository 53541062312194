<icz-wizard (finished)="submit.emit()" (canceled)="close(false)"
  [alwaysEnableAllSteps]="true"
  [showSubmitBar]="showWizardSubmitBar"
  >
  <ng-template
    [iczWizardStep]="ResolutionDialogSteps.RESOLUTION"
    label="Vyřízení"
    submitLabel="Vyřídit"
    [isValid]="resolutionValid && componentsStepValid"
    [customSubmitHandler]="quickResolutionHandler"
    >
    @if (esslComponents && !digitalDocumentHasComponents) {
      <icz-alert
        class="mx-24 my-16"
        severity="error"
        heading="Pro vyřízení digitálního dokumentu musí dokument obsahovat alespoň jeden elektronický soubor"
      ></icz-alert>
    }
    @if (form) {
      <icz-document-settle-form
        [document]="document"
        [esslComponents]="esslComponents"
        [settlementTabState]="settlementTabState"
        [showDocumentInfo]="showDocumentInfo"
        [disposalSchedulePrepare]="disposalSchedulePrepare"
        (disposalScheduleSourceChange)="onDisposalScheduleSourceChange()"
        [form]="form"
        [waiting]="loadingIndicatorService.isLoading(this)"
        class="grow"
        (onErrorClose)="onErrorDialogClose()"
        (settlementTabPrepareChange)="settlementTabStateChange.emit($event)"
      ></icz-document-settle-form>
    }
  </ng-template>
  <ng-template
    [stepHidden]="!isResolutionByDocument"
    [iczWizardStep]="ResolutionDialogSteps.RESOLUTION_BY_DOCUMENT"
    label="Vybrat vyřizovaný dokument"
    submitLabel="Vyřídit"
    >
    <icz-document-settle-by-document-form
      [form]="form"
      [document]="document"
      [preselectedDocument]="relatedDocuments[document.id]"
      (resolutionByDocumentChanged)="onRelatedDocumentChange(document.id, $event)"
    ></icz-document-settle-by-document-form>
  </ng-template>
  <ng-template
    [iczWizardStep]="ResolutionDialogSteps.COMPONENTS"
    [label]="digitalComponentSelectionTabHeading"
    submitLabel="Vyřídit"
    [isValid]="resolutionValid && componentsStepValid"
    [customSubmitHandler]="quickResolutionHandler"
    >
    <icz-digital-components-selector
      id="document-resolution-component-selector"
      tableTitle="Výběr komponent pro uložení do spisovny"
      [view]="EsslComponentsTableColumnSet.ESSL_COMPONENTS_SETTLEMENT_SELECTOR"
      [documentId]="document.id"
      [acceptableDataFileFormatRules]="acceptableDataFileFormats"
      [disableFinalComponents]="true"
      [selection]="preselectedComponents"
      (tableDestroyed)="onComponentSelectorDestroyed()"
      (esslComponentsSelected)="setEsslComponents($event)"
      (esslComponentIdsExcluded)="setExcludedComponentIds($event)"
    ></icz-digital-components-selector>
  </ng-template>
  <ng-template
    [stepHidden]="!(isResolutionByDocument && isDocumentForResolutionSelected)"
    [iczWizardStep]="ResolutionDialogSteps.COMPONENTS_BY_DOCUMENT"
    submitLabel="Vyřídit"
    [label]="byDocumentComponentSelectionTabHeading"
    [customSubmitHandler]="quickResolutionHandler"
    [isValid]="resolutionValid && componentsStepValid"
    >
    <icz-digital-components-selector
      id="document-resolution-component-selector-related"
      tableTitle="Výběr komponent pro uložení do spisovny"
      [view]="EsslComponentsTableColumnSet.ESSL_COMPONENTS_SETTLEMENT_SELECTOR"
      [documentId]="relatedDocumentId"
      [acceptableDataFileFormatRules]="acceptableDataFileFormats"
      [disableFinalComponents]="true"
      [selection]="preselectedRelatedDocumentComponents"
      (tableDestroyed)="onRelatedComponentSelectorDestroyed()"
      (esslComponentsSelected)="setRelatedDocumentComponents($event)"
      (esslComponentIdsExcluded)="setexcludedRelatedDocumentComponentIds($event)"
    ></icz-digital-components-selector>
  </ng-template>

  @if (showWizardSubmitBar) {
    <div extraLeftButtons>
      @if (isOnResolutionFormStep && isResolutionByDocument) {
        <icz-button svgIcon="after"
          label="K výběru vyřizovaného dokumentu" [disabled]="!resolutionValid"
        (onAction)="goToStep(ResolutionDialogSteps.RESOLUTION_BY_DOCUMENT)"></icz-button>
      }
      @if ((isOnResolutionFormStep && !isResolutionByDocument) || isOnByDocumentResolutionStep) {
        <icz-button svgIcon="after"
          label="K výběru příloh vyřizujícího dokumentu" [disabled]="!resolutionValid"
        (onAction)="goToStep(ResolutionDialogSteps.COMPONENTS)"></icz-button>
      }
      @if (isOnComponentsStep && isDocumentForResolutionSelected) {
        <icz-button svgIcon="after"
          label="K výběru příloh vyřizovaného dokumentu" [disabled]="!resolutionValid"
        (onAction)="goToStep(ResolutionDialogSteps.COMPONENTS_BY_DOCUMENT)"></icz-button>
      }
    </div>
  }

</icz-wizard>
