import {DestroyRef, inject, Injectable} from '@angular/core';
import {EMPTY, forkJoin, Observable, of, switchMap} from 'rxjs';
import {DialogService} from '../../core/services/dialog.service';
import {LocalBinaryFileDownloadService} from '../../services/local-binary-file-download.service';
import {fileToBase64} from '../../lib/utils';
import {PrinterSelectionDialogComponent} from './printer-selection-dialog/printer-selection-dialog.component';
import {IczModalService} from '../../services/icz-modal.service';
import {IdtService} from '../../core/services/idt/idt.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PrintingOperationType} from '../../services/user-settings.service';
import {MessageType, ToastService} from '../notifications/toast.service';

@Injectable({
  providedIn: 'root'
})
export class PrintService {

  private destroyRef = inject(DestroyRef);
  private idtService = inject(IdtService);
  private dialogService = inject(DialogService);
  private modalService = inject(IczModalService);
  private toastService = inject(ToastService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);

  printBinary(printingOperationType: PrintingOperationType, files: File[]): Observable<void> {
    if (!files.length) {
      return EMPTY;
    }
    else {
      return this.openSelectPrinterDialog(printingOperationType).pipe(
        switchMap(selectedPrinter => {
          if (selectedPrinter) {
            const base64encode$ = files.map(f => fileToBase64(f));

            return forkJoin(base64encode$).pipe(
              takeUntilDestroyed(this.destroyRef),
              switchMap(filesBase64 => {
                for (const fileBase64 of filesBase64) {
                  this.idtService.print({
                    barcode: fileBase64.split(',')[1],
                    printerName: selectedPrinter,
                  });
                }

                this.dispatchPrintingStartedToast();

                return of();
              }),
            );
          }
          else {
            return of();
          }
        }),
      );
    }
  }

  printText(printingOperationType: PrintingOperationType, texts: string[]): Observable<void> {
    if (!texts.length) {
      return EMPTY;
    }
    else {
      return this.openSelectPrinterDialog(printingOperationType).pipe(
        switchMap(selectedPrinter => {
          if (selectedPrinter) {
            const textsBase64 = texts.map(t => btoa(t));

            for (const textBase64 of textsBase64) {
              this.idtService.print({
                barcode: textBase64,
                printerName: selectedPrinter,
              });
            }

            this.dispatchPrintingStartedToast();
          }

          return of();
        }),
      );
    }
  }

  private openSelectPrinterDialog(printingOperationType: PrintingOperationType): Observable<Nullable<string>> {
    return this.modalService.openComponentInModal<Nullable<string>, PrintingOperationType>({
      component: PrinterSelectionDialogComponent,
      modalOptions: {
        width: 500,
        height: 250,
        titleTemplate: 'Vyberte tiskárnu pro tisk',
      },
      data: printingOperationType,
    });
  }

  private dispatchPrintingStartedToast() {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: 'Tisk spuštěn.',
        }
      }
    });
  }

  private handleIncompatibleBrowser(pdfFile: File) {
    this.localBinaryFileDownloadService.downloadFile(pdfFile);
  }

}
