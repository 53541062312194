/* tslint:disable */
/* eslint-disable */

/**
 * Flag specifing purpose of webdav component share
 */
export enum WebDavSharePurpose {
  NATIVE_EDIT = 'NATIVE_EDIT',
  ANONYMIZATION = 'ANONYMIZATION'
}
