import {inject} from '@angular/core';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {HealthcheckResult, HealthcheckService} from './healthcheck.service';
import {createAbsoluteRoute} from '../routing/routing.helpers';
import {ApplicationRoute} from '../../enums/shared-routes.enum';

export function guardBackendStatus() {
  return () => {
    const healthcheckService = inject(HealthcheckService);
    const router = inject(Router);

    return healthcheckService.getBackendHealthcheckResult().pipe(
      map((backendStatus: HealthcheckResult) => {
        if (backendStatus.isUp) {
          return true;
        } else {
          if (backendStatus.redirectTo) {
            return router.createUrlTree([
              createAbsoluteRoute(backendStatus.redirectTo)
            ]);
          }
          else {
            return router.createUrlTree([
              createAbsoluteRoute(ApplicationRoute.ROOT)
            ]);
          }
        }
      }),
    );
  };
}
