/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OwnConsignmentTakeoverAndDistributionDto } from '../../models/own-consignment-takeover-and-distribution-dto';
import { OwnInternalPaperConsignmentDto } from '../../models/own-internal-paper-consignment-dto';

export interface OwnConsignmentTakeoverAndPrepareForDistributionInternalPaper$Params {
  id: number;
      body: OwnConsignmentTakeoverAndDistributionDto
}

export function ownConsignmentTakeoverAndPrepareForDistributionInternalPaper(http: HttpClient, rootUrl: string, params: OwnConsignmentTakeoverAndPrepareForDistributionInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
  const rb = new RequestBuilder(rootUrl, ownConsignmentTakeoverAndPrepareForDistributionInternalPaper.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OwnInternalPaperConsignmentDto>;
    })
  );
}

ownConsignmentTakeoverAndPrepareForDistributionInternalPaper.PATH = '/sad/own-consignment/paper/internal/{id}/takeover-and-prepare-for-distribution';