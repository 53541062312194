<div [waiting]="loadingService.isLoading(this)" style="min-height: 450px">
  <icz-file-selector
    [form]="form"
    [excludeObjectWithId]="currentFile.id"
    controlName="id"
  ></icz-file-selector>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()" primary label="Přesunout do jiného spisu"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
