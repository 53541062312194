<div class="h-full col grow" [formGroup]="form">
  <div class="top-toolbar row">
    <div class="row items-center">
      <ng-content></ng-content>
      {{ tableTitle }}
    </div>
    <div class="grow"></div>
    <div class="row items-center">
      <icz-form-filter formControlName="firstPeriod"
                       [filterItem]="firstPeriodFilter"></icz-form-filter>
      <icz-form-filter formControlName="secondPeriod"
                       [filterItem]="secondPeriodFilter"></icz-form-filter>
      <icz-toggle-button
        class="my-8"
        label="Graf"
        [toggled]="showLineChart"
        (onAction)="showLineChart = !showLineChart"
      ></icz-toggle-button>
    </div>
  </div>
  <div class="graph-area w-full">
    @if (statisticConfig?.allowLineChart && showTableAndGraph) {
      <div>
        @if (showLineChart) {
          <icz-line-chart [config]="lineChartConfig" [waiting]="loadingIndicatorService.isLoading$(this, 'lineChart') | async" class="w-full"></icz-line-chart>
        }
      </div>
    }
    @if (statisticConfig?.allowBarChart) {
      <div>
        <div class="mb-12">
          <icz-checkbox formControlName="showBarChart" label="Zobrazit sloupcový graf"></icz-checkbox>
        </div>
        @if (showBarChart) {
          <icz-bar-chart [config]="statisticConfig.barConfig!"></icz-bar-chart>
        }
      </div>
    }
    @if (statisticConfig?.allowDonutChart) {
      <div>
        <div class="mb-12">
          <icz-checkbox formControlName="showDonutChart" label="Zobrazit koláčový graf"></icz-checkbox>
        </div>
        @if (showDonutChart) {
          <icz-donut-chart [config]="statisticConfig.donutConfig!"></icz-donut-chart>
        }
      </div>
    }
  </div>

  @if (columnsData && tableConfig && statisticConfig && showTableAndGraph) {
    <div class="col grow">
      <icz-table
        class="col grow"
        [waiting]="loadingIndicatorService.isLoading$(this, 'statistic-table') | async"
        [id]="'statistic-table-' + statisticConfig.id"
        [dataSource]="dataSource"
        [columnsData]="columnsData"
        [config]="tableConfig"
        (pageLoad)="loadPage($event)"
        [enableContextMenu]="false"
        [initialDimensions]="selectedDimensions"
        [allowedDimensions]="allowedDimensions"
        [disableUrlParameters]="true"
        (dimensionsChange)="dimensionsChanged($event, true)"
        (initialized)="afterTableInitialized($event)"
      >
        <icz-label noDataLabel label="Žádná data"></icz-label>
        <icz-label notFoundLabel label="Žádná data"></icz-label>
        <!-- todo(mh) will be added in next iteration -->
        <!--<div class="row-group" tools>
          <icz-button background="white" (onAction)="exportToCSV()" label="Export do CSV"></icz-button>
        </div>-->
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'count'; let column = column">
          {{ row.count }}
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.ENTITY_CLASS; let column = column">
          @if (row.dimensions[StatisticsDimension.ENTITY_CLASS]) {
            {{ row.dimensions[StatisticsDimension.ENTITY_CLASS] | findInList:(column.list$! | async)! | translate }}
          } @else {
            {{ getNullDimensionValue(column.id) | translate}}
          }
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.SECURITY_CATEGORY; let column = column">
          @if (row.dimensions[StatisticsDimension.SECURITY_CATEGORY]) {
            {{ row.dimensions[StatisticsDimension.SECURITY_CATEGORY] | findInList:(securityCategories$ | async)! | translate }}
          } @else {
            {{ getNullDimensionValue(column.id) | translate}}
          }
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.ENTITY_STATE; let column = column">
          {{ row.dimensions[StatisticsDimension.ENTITY_STATE] | findInList:(entityStateOptions)! | translate }}
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'previousCount'; let column = column">
          {{ row.previousCount }}
        </ng-container>
      </icz-table>
    </div>
  }
</div>
