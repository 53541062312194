/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deliveryServiceCombinationCreate } from '../fn/delivery-service-combination/delivery-service-combination-create';
import { DeliveryServiceCombinationCreate$Params } from '../fn/delivery-service-combination/delivery-service-combination-create';
import { DeliveryServiceCombinationDto } from '../models/delivery-service-combination-dto';
import { deliveryServiceCombinationFindAll } from '../fn/delivery-service-combination/delivery-service-combination-find-all';
import { DeliveryServiceCombinationFindAll$Params } from '../fn/delivery-service-combination/delivery-service-combination-find-all';
import { deliveryServiceCombinationFindByDeliveryTypeGroup } from '../fn/delivery-service-combination/delivery-service-combination-find-by-delivery-type-group';
import { DeliveryServiceCombinationFindByDeliveryTypeGroup$Params } from '../fn/delivery-service-combination/delivery-service-combination-find-by-delivery-type-group';
import { deliveryServiceCombinationFindById } from '../fn/delivery-service-combination/delivery-service-combination-find-by-id';
import { DeliveryServiceCombinationFindById$Params } from '../fn/delivery-service-combination/delivery-service-combination-find-by-id';
import { deliveryServiceCombinationSearch } from '../fn/delivery-service-combination/delivery-service-combination-search';
import { DeliveryServiceCombinationSearch$Params } from '../fn/delivery-service-combination/delivery-service-combination-search';
import { deliveryServiceCombinationUpdate } from '../fn/delivery-service-combination/delivery-service-combination-update';
import { DeliveryServiceCombinationUpdate$Params } from '../fn/delivery-service-combination/delivery-service-combination-update';
import { PageDeliveryServiceCombinationDto } from '../models/page-delivery-service-combination-dto';


/**
 * Delivery services controller
 */
@Injectable({ providedIn: 'root' })
export class ApiDeliveryServiceCombinationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliveryServiceCombinationFindById()` */
  static readonly DeliveryServiceCombinationFindByIdPath = '/codebook/delivery-service-combination/{id}';

  /**
   * Finds the delivery service by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceCombinationFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationFindById$Response(params: DeliveryServiceCombinationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceCombinationDto>> {
    return deliveryServiceCombinationFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the delivery service by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceCombinationFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationFindById(params: DeliveryServiceCombinationFindById$Params, context?: HttpContext): Observable<DeliveryServiceCombinationDto> {
    return this.deliveryServiceCombinationFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceCombinationDto>): DeliveryServiceCombinationDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceCombinationUpdate()` */
  static readonly DeliveryServiceCombinationUpdatePath = '/codebook/delivery-service-combination/{id}';

  /**
   * Updates an existing delivery service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceCombinationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceCombinationUpdate$Response(params: DeliveryServiceCombinationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceCombinationDto>> {
    return deliveryServiceCombinationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing delivery service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceCombinationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceCombinationUpdate(params: DeliveryServiceCombinationUpdate$Params, context?: HttpContext): Observable<DeliveryServiceCombinationDto> {
    return this.deliveryServiceCombinationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceCombinationDto>): DeliveryServiceCombinationDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceCombinationFindAll()` */
  static readonly DeliveryServiceCombinationFindAllPath = '/codebook/delivery-service-combination';

  /**
   * Returns all the delivery services
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceCombinationFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationFindAll$Response(params?: DeliveryServiceCombinationFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryServiceCombinationDto>>> {
    return deliveryServiceCombinationFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the delivery services
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceCombinationFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationFindAll(params?: DeliveryServiceCombinationFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DeliveryServiceCombinationDto>> {
    return this.deliveryServiceCombinationFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryServiceCombinationDto>>): Array<DeliveryServiceCombinationDto> => r.body)
    );
  }

  /** Path part for operation `deliveryServiceCombinationCreate()` */
  static readonly DeliveryServiceCombinationCreatePath = '/codebook/delivery-service-combination';

  /**
   * Creates a new delivery service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceCombinationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceCombinationCreate$Response(params: DeliveryServiceCombinationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceCombinationDto>> {
    return deliveryServiceCombinationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new delivery service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceCombinationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceCombinationCreate(params: DeliveryServiceCombinationCreate$Params, context?: HttpContext): Observable<DeliveryServiceCombinationDto> {
    return this.deliveryServiceCombinationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceCombinationDto>): DeliveryServiceCombinationDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceCombinationFindByDeliveryTypeGroup()` */
  static readonly DeliveryServiceCombinationFindByDeliveryTypeGroupPath = '/codebook/delivery-service-combination/{deliveryTypeGroup}/findByDeliveryTypeGroup/search-api';

  /**
   * Returns services based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceCombinationFindByDeliveryTypeGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationFindByDeliveryTypeGroup$Response(params: DeliveryServiceCombinationFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceCombinationDto>> {
    return deliveryServiceCombinationFindByDeliveryTypeGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns services based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceCombinationFindByDeliveryTypeGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationFindByDeliveryTypeGroup(params: DeliveryServiceCombinationFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<PageDeliveryServiceCombinationDto> {
    return this.deliveryServiceCombinationFindByDeliveryTypeGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceCombinationDto>): PageDeliveryServiceCombinationDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceCombinationSearch()` */
  static readonly DeliveryServiceCombinationSearchPath = '/codebook/delivery-service-combination/search-api';

  /**
   * Returns delivery services based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceCombinationSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationSearch$Response(params?: DeliveryServiceCombinationSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceCombinationDto>> {
    return deliveryServiceCombinationSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns delivery services based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceCombinationSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceCombinationSearch(params?: DeliveryServiceCombinationSearch$Params|null|undefined, context?: HttpContext): Observable<PageDeliveryServiceCombinationDto> {
    return this.deliveryServiceCombinationSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceCombinationDto>): PageDeliveryServiceCombinationDto => r.body)
    );
  }

}
