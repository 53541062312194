<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <icz-form-autocomplete
    formControlName="curentRegisterId"
    label="Stávající evidence"
    [options]="basicRegisterOptions"
  ></icz-form-autocomplete>
  <icz-form-autocomplete
    formControlName="newRegisterId"
    label="Zvolte novou evidenci"
    [options]="applicableBasicRegisterOptions"
  ></icz-form-autocomplete>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Přeevidovat dokument"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
