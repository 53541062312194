import {ChangeDetectionStrategy, Component, OnInit,} from '@angular/core';
import {DateFilterComponent} from '../date-filter/date-filter.component';
import {DateFilterSelectionMode} from '../date-filter/date-filter.models';
import {IczOnChanges, IczSimpleChanges} from '../../../../../../utils/icz-on-changes';

@Component({
  selector: 'icz-date-statistics-filter',
  templateUrl: './date-statistics-filter.component.html',
  styleUrls: ['./date-statistics-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateStatisticsFilterComponent extends DateFilterComponent implements IczOnChanges, OnInit {

  isSingleDateSwitchDisabled = true;

  ngOnInit() {
    this.selectionMode = DateFilterSelectionMode.INTERVAL;
    this.isIntervalSwitchDisabled = false;
  }

  // overriding DateFilterComponent's ngOnChanges behavior, which might be undesired here
  override ngOnChanges(changes: IczSimpleChanges<this>) {
  }

}
