<div class="col gap-24 icz-body-1 h-full">
  <icz-alert severity="warning" [heading]="modalText | interpolate: {unavailableCount: modalData.unavailableTasks.length, availableCount: modalData.availableTasks.length}"></icz-alert>

  <ng-container>
    <div class="row">
      <icz-label class="font-bold" label="Seznam úkolů, které nepodporují vybranou operaci:"></icz-label>
    </div>

    <div class="overflow-y-auto error-list">
      @for (task of modalData.unavailableTasks; track task) {
        <div>
          <div class="bold mb-8">{{ task }}</div>
        </div>
      }
    </div>
  </ng-container>
</div>

<icz-form-buttons>
  <icz-button primary leftButtons [label]="getContinueLabel()" (onAction)="confirm()"></icz-button>
  <icz-button rightButtons (onAction)="cancel()" [label]="'Storno'"></icz-button>
</icz-form-buttons>
