import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {TabItem} from '../../../essentials/tabs/tabs.component.model';

enum AttachSeal {
  NO_SEAL = 'NO_SEAL',
  MANUAL = 'MANUAL', // manual mode will most likely not be implemented, unless G2 consumers can provide dimensions of the seal
  AUTOMATIC = 'AUTOMATIC',
}

enum LocationForSave {
  HARD_DRIVE = 'HARD_DRIVE',
  ATTACH_TO_DOCUMENT = 'ATTACH_TO_DOCUMENT',
  CREATE_NEW_DOCUMENT = 'CREATE_NEW_DOCUMENT',
}

@Component({
  selector: 'icz-subject-usage-report-configuration',
  templateUrl: './subject-usage-report-configuration.component.html',
  styleUrls: ['./subject-usage-report-configuration.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectUsageReportConfigurationComponent {

  readonly AttachSeal = AttachSeal;
  readonly LocationForSave = LocationForSave;

  form = new IczFormGroup({
    attachSeal: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    reason: new IczFormControl<Nullable<string>>(null, []),
    place: new IczFormControl<Nullable<string>>(null, []),
    locationForSave: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  tabs: TabItem[] = [
    {
      id: 'configuration',
      label: 'Nastavení podoby přehledu',
      showTabValidity: false,
    },
    {
      id: 'preview',
      label: 'Ukázka přehledu',
      showTabValidity: false,
    },
  ];

  get isAttachingSeal() {
    return this.form?.get('attachSeal')?.value === AttachSeal.AUTOMATIC;
  }

}
