import {TransactionEventDto} from '|api/transactionlog';
import {IczTableDataSource} from '../../table/table.datasource';
import {TransactionLogSearchService} from '../../../services/transaction-log-search.service';


export class TransactionsDataSource extends IczTableDataSource<TransactionEventDto> {
  constructor(searchService: TransactionLogSearchService) {
    super(searchParams => {
      searchParams.sort ??= [];

      if (!searchParams.sort.find(s => s.fieldName === 'id')) {
        searchParams.sort.push({
          fieldName: 'id',
          descending: true,
        });
      }

      return searchService.findTransactions(searchParams);
    });
  }
}
