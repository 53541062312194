import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HolidayDatesService {
  // after having implemented a list of bank holidays to
  // G2 config, connect this service to it
  isHoliday(d: Date) {
    return d.getDay() === 0; // every sunday
  }
}
