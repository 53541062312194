<div class="p-16" [waiting]="loadingService.isLoading(this)">
  <h2 class="mt-0 mb-24">{{ activityHeading | translate }}</h2>
  @if (isComponentCirculation) {
    <a
      class="row inline-flex icz-body-1 mb-16" iczLinkWithoutHref (click)="openCirculationComponent()"
      >
      @if (activity?.activityType === CirculationActivityType.COMPONENT_SIGNING) {
        <icz-icon svgIcon="signature" size="small"></icz-icon>
      }
      {{ $any(activity).componentLabel }}
    </a>
  }
  <div [class.row]="isMultiColumn">
    <div class="col grow">
      <div class="activity-steps">
        @if (activity) {
          <icz-activity-steps-preview
            #rootActivity
            [activity]="activity"
          ></icz-activity-steps-preview>
        }
      </div>
    </div>
    @if (activity) {
      <div class="col grow">
        <div class="activity-info">
          @if (rootActivity?.activityTasksInfo?.length && instruction && !isIssdActivity) {
            <div class="icz-body-1 mb-8">
              <!-- task instructions are the same for each task
              thus getting instruction of the first task -->
              <span class="font-bold">{{ 'Instrukce' | translate }}:</span>
              <icz-expandable-paragraph [contents]="instruction"></icz-expandable-paragraph>
            </div>
          }
          <div class="icz-body-1 mb-8">
            <span class="font-bold">{{ 'Zadavatel úkolu' | translate }}:</span>
            {{ activity.initUserId | findUserById | async }} ({{ activity.initFunctionalPositionId | findFunctionalPositionById:true | async }})
          </div>
          <div class="icz-body-1 mb-8">
            <span class="font-bold">{{ 'Útvar zadavatele' | translate }}:</span>
            {{ activity.initOrgUnitId | findOrganizationalUnitById | async }}
          </div>
          <div class="icz-body-1 mb-8">
            <span class="font-bold">{{ 'Úkol vytvořen' | translate }}:</span>
            {{ activity.initDate | localizedDatetime }}
          </div>
          @if (activity.deadline) {
            <div class="icz-body-1 mb-8">
              <span class="font-bold">{{ 'Termín úkolu' | translate }}:</span>
              {{ deadlineDescription | translate }} {{ activity.deadline | localizedDate }}
            </div>
          }
          @if (activity.numberOfAnalogComponentCopiesToSign) {
            <div class="icz-body-1 mb-8">
              <span class="font-bold">{{ 'Počet listin k podpisu' | translate }}:</span>
              {{ activity.numberOfAnalogComponentCopiesToSign }}
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>
