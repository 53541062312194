/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { circulationTaskAcknowledgementAcknowledge } from '../fn/circulation-task-acknowledgement/circulation-task-acknowledgement-acknowledge';
import { CirculationTaskAcknowledgementAcknowledge$Params } from '../fn/circulation-task-acknowledgement/circulation-task-acknowledgement-acknowledge';
import { circulationTaskAcknowledgementPass } from '../fn/circulation-task-acknowledgement/circulation-task-acknowledgement-pass';
import { CirculationTaskAcknowledgementPass$Params } from '../fn/circulation-task-acknowledgement/circulation-task-acknowledgement-pass';
import { circulationTaskAcknowledgementRevoke } from '../fn/circulation-task-acknowledgement/circulation-task-acknowledgement-revoke';
import { CirculationTaskAcknowledgementRevoke$Params } from '../fn/circulation-task-acknowledgement/circulation-task-acknowledgement-revoke';
import { CirculationTaskDto } from '../models/circulation-task-dto';


/**
 * Controller for executing actions on acknowledgement document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskAcknowledgementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskAcknowledgementRevoke()` */
  static readonly CirculationTaskAcknowledgementRevokePath = '/flow/acknowledgement/{id}/revoke';

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskAcknowledgementRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskAcknowledgementRevoke$Response(params: CirculationTaskAcknowledgementRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskAcknowledgementRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskAcknowledgementRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskAcknowledgementRevoke(params: CirculationTaskAcknowledgementRevoke$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskAcknowledgementRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskAcknowledgementPass()` */
  static readonly CirculationTaskAcknowledgementPassPath = '/flow/acknowledgement/{id}/pass';

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskAcknowledgementPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskAcknowledgementPass$Response(params: CirculationTaskAcknowledgementPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskAcknowledgementPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskAcknowledgementPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskAcknowledgementPass(params: CirculationTaskAcknowledgementPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskAcknowledgementPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskAcknowledgementAcknowledge()` */
  static readonly CirculationTaskAcknowledgementAcknowledgePath = '/flow/acknowledgement/{id}/acknowledge';

  /**
   * Finish task by acknowledgement by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskAcknowledgementAcknowledge()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskAcknowledgementAcknowledge$Response(params: CirculationTaskAcknowledgementAcknowledge$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskAcknowledgementAcknowledge(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by acknowledgement by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskAcknowledgementAcknowledge$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskAcknowledgementAcknowledge(params: CirculationTaskAcknowledgementAcknowledge$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskAcknowledgementAcknowledge$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

}
