/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { storageUnitBulkBlock } from '../fn/storage-unit/storage-unit-bulk-block';
import { StorageUnitBulkBlock$Params } from '../fn/storage-unit/storage-unit-bulk-block';
import { storageUnitBulkDelete } from '../fn/storage-unit/storage-unit-bulk-delete';
import { StorageUnitBulkDelete$Params } from '../fn/storage-unit/storage-unit-bulk-delete';
import { storageUnitBulkInsertEntities } from '../fn/storage-unit/storage-unit-bulk-insert-entities';
import { StorageUnitBulkInsertEntities$Params } from '../fn/storage-unit/storage-unit-bulk-insert-entities';
import { storageUnitBulkMoveEntities } from '../fn/storage-unit/storage-unit-bulk-move-entities';
import { StorageUnitBulkMoveEntities$Params } from '../fn/storage-unit/storage-unit-bulk-move-entities';
import { storageUnitBulkUnblock } from '../fn/storage-unit/storage-unit-bulk-unblock';
import { StorageUnitBulkUnblock$Params } from '../fn/storage-unit/storage-unit-bulk-unblock';
import { storageUnitBulkWithdrawEntities } from '../fn/storage-unit/storage-unit-bulk-withdraw-entities';
import { StorageUnitBulkWithdrawEntities$Params } from '../fn/storage-unit/storage-unit-bulk-withdraw-entities';
import { storageUnitCreate } from '../fn/storage-unit/storage-unit-create';
import { StorageUnitCreate$Params } from '../fn/storage-unit/storage-unit-create';
import { StorageUnitDto } from '../models/storage-unit-dto';
import { storageUnitFindById } from '../fn/storage-unit/storage-unit-find-by-id';
import { StorageUnitFindById$Params } from '../fn/storage-unit/storage-unit-find-by-id';
import { storageUnitFindValidStorageUnitsForEntities } from '../fn/storage-unit/storage-unit-find-valid-storage-units-for-entities';
import { StorageUnitFindValidStorageUnitsForEntities$Params } from '../fn/storage-unit/storage-unit-find-valid-storage-units-for-entities';
import { storageUnitUpdate } from '../fn/storage-unit/storage-unit-update';
import { StorageUnitUpdate$Params } from '../fn/storage-unit/storage-unit-update';


/**
 * Controller for storage unit objects
 */
@Injectable({ providedIn: 'root' })
export class ApiStorageUnitService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `storageUnitFindById()` */
  static readonly StorageUnitFindByIdPath = '/document/storage-unit/{id}';

  /**
   * Find storage unit object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFindById$Response(params: StorageUnitFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitDto>> {
    return storageUnitFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find storage unit object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFindById(params: StorageUnitFindById$Params, context?: HttpContext): Observable<StorageUnitDto> {
    return this.storageUnitFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitDto>): StorageUnitDto => r.body)
    );
  }

  /** Path part for operation `storageUnitUpdate()` */
  static readonly StorageUnitUpdatePath = '/document/storage-unit/{id}';

  /**
   * Update storage unit object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitUpdate$Response(params: StorageUnitUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitDto>> {
    return storageUnitUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update storage unit object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitUpdate(params: StorageUnitUpdate$Params, context?: HttpContext): Observable<StorageUnitDto> {
    return this.storageUnitUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitDto>): StorageUnitDto => r.body)
    );
  }

  /** Path part for operation `storageUnitCreate()` */
  static readonly StorageUnitCreatePath = '/document/storage-unit';

  /**
   * Create new storage unit object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitCreate$Response(params: StorageUnitCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitDto>> {
    return storageUnitCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new storage unit object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitCreate(params: StorageUnitCreate$Params, context?: HttpContext): Observable<StorageUnitDto> {
    return this.storageUnitCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitDto>): StorageUnitDto => r.body)
    );
  }

  /** Path part for operation `storageUnitBulkWithdrawEntities()` */
  static readonly StorageUnitBulkWithdrawEntitiesPath = '/document/storage-unit/{id}/withdraw-entities';

  /**
   * Withdraw entities from current storage unit identified by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitBulkWithdrawEntities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkWithdrawEntities$Response(params: StorageUnitBulkWithdrawEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitBulkWithdrawEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraw entities from current storage unit identified by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitBulkWithdrawEntities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkWithdrawEntities(params: StorageUnitBulkWithdrawEntities$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitBulkWithdrawEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitBulkMoveEntities()` */
  static readonly StorageUnitBulkMoveEntitiesPath = '/document/storage-unit/{id}/move-entities';

  /**
   * Move entities from current storage unit identified by id to another storage unit identified by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitBulkMoveEntities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkMoveEntities$Response(params: StorageUnitBulkMoveEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitBulkMoveEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Move entities from current storage unit identified by id to another storage unit identified by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitBulkMoveEntities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkMoveEntities(params: StorageUnitBulkMoveEntities$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitBulkMoveEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitBulkInsertEntities()` */
  static readonly StorageUnitBulkInsertEntitiesPath = '/document/storage-unit/{id}/insert-entities';

  /**
   * Insert entities in target storage unit identified by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitBulkInsertEntities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkInsertEntities$Response(params: StorageUnitBulkInsertEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitBulkInsertEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Insert entities in target storage unit identified by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitBulkInsertEntities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkInsertEntities(params: StorageUnitBulkInsertEntities$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitBulkInsertEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitFindValidStorageUnitsForEntities()` */
  static readonly StorageUnitFindValidStorageUnitsForEntitiesPath = '/document/storage-unit/find-valid-storage-units';

  /**
   * Find storage units valid for inserting or moving given entities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFindValidStorageUnitsForEntities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFindValidStorageUnitsForEntities$Response(params: StorageUnitFindValidStorageUnitsForEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StorageUnitDto>>> {
    return storageUnitFindValidStorageUnitsForEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Find storage units valid for inserting or moving given entities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFindValidStorageUnitsForEntities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFindValidStorageUnitsForEntities(params: StorageUnitFindValidStorageUnitsForEntities$Params, context?: HttpContext): Observable<Array<StorageUnitDto>> {
    return this.storageUnitFindValidStorageUnitsForEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StorageUnitDto>>): Array<StorageUnitDto> => r.body)
    );
  }

  /** Path part for operation `storageUnitBulkUnblock()` */
  static readonly StorageUnitBulkUnblockPath = '/document/storage-unit/bulk-unblock';

  /**
   * Unblocks storage units.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitBulkUnblock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkUnblock$Response(params: StorageUnitBulkUnblock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitBulkUnblock(this.http, this.rootUrl, params, context);
  }

  /**
   * Unblocks storage units.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitBulkUnblock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkUnblock(params: StorageUnitBulkUnblock$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitBulkUnblock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitBulkBlock()` */
  static readonly StorageUnitBulkBlockPath = '/document/storage-unit/bulk-block';

  /**
   * Blocks storage units.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitBulkBlock()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkBlock$Response(params: StorageUnitBulkBlock$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitBulkBlock(this.http, this.rootUrl, params, context);
  }

  /**
   * Blocks storage units.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitBulkBlock$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkBlock(params: StorageUnitBulkBlock$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitBulkBlock$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitBulkDelete()` */
  static readonly StorageUnitBulkDeletePath = '/document/storage-unit/bulk-delete';

  /**
   * Deletes storage units.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitBulkDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkDelete$Response(params: StorageUnitBulkDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitBulkDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes storage units.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitBulkDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitBulkDelete(params: StorageUnitBulkDelete$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitBulkDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
