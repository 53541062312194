<form [formGroup]="form">
  <icz-section label="Popis dokumentu a předání" [showExpansionHint]="true">
    <div class="row grow">
      <icz-org-structure-selector [form]="form"
                                  (valueChangeWithOriginIds)="ownerChangedWithOriginIds($event)"
                                  [useSelfAssignmentConfiguration]="!isInternalConsignment"
                                  [disableOptionsWithEmptyOrgEntity]="true"
                                  controlName="formOwnerOrganizationEntityId"
                                  class="grow"
                                  label="Předat na Organizační jednotku nebo Funkční místo"
                                  [isMultiselect]="false">
      </icz-org-structure-selector>
    </div>

    <icz-received-document-form [receivedDocumentUsage]="receivedDocumentUsage"
                                [showComponentDetailedInputToggle]="true"
                                [canAddAnalogComponentsByOpenedState]="canAddAnalogComponentsByOpenedState"
                                [distributionClass]="distributionClass"
                                [disableAssignRegNr]="disableAssignRegNr"
                                [deliveryType]="deliveryType"
                                [resetForm]="resetForm"
                                (canAddDigitalComponentsByDocumentForm)="canAddDigitalComponentsByDocumentForm.emit($event)"
                                [form]="receivedDocumentFormGroup">
    </icz-received-document-form>
  </icz-section>
</form>
