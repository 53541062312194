import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {InterpolationContext} from '../../../lib/model';

export interface ValidationError {
  objectType: string;
  field: string;
  message: string;
  errorCode?: string;
  parameters?: string[];
}

export interface ErrorDialogItem {
  text: string;
  context: InterpolationContext;
  validationErrors: ValidationError[];
}

@Injectable({
  providedIn: 'root'
})
export class ErrorDialogDataService {
  private _errorData$ = new BehaviorSubject<Nullable<ErrorDialogItem[]>>(null);
  errorData$ = this._errorData$.asObservable();

  setData(d: Nullable<ErrorDialogItem[]>) {
    this._errorData$.next(d);
  }

  addDialogItem(item: ErrorDialogItem) {
    const actualData = this.getActualData();

    if (actualData) {
      actualData.push(item);
      this.setData({...actualData});
    }
  }

  getActualData() {
    return this._errorData$.value;
  }

  clearData() {
    this.setData(null);
  }
}
