/* tslint:disable */
/* eslint-disable */

/**
 * Component transfer mode
 */
export enum ComponentTransferMode {
  DIRECT = 'DIRECT',
  LINK_BY_SIZE = 'LINK_BY_SIZE',
  LINK = 'LINK'
}
