import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';


export const SSharedFolderTemplateData = z.object({
  [InternalNotificationKey.NAME]: z.string(),
});

export type SharedFolderTemplateData = z.infer<typeof SSharedFolderTemplateData>;

export const SSharedFolderErrorTemplateData = z.object({
  [InternalNotificationKey.ERROR_DESCRIPTION]: z.string(),
});

export type SharedFolderErrorTemplateData = z.infer<typeof SSharedFolderErrorTemplateData>;

export const SSharedFolderSharingTargetParticipantInfoTemplateData = z.object({
  [InternalNotificationKey.USERNAME]: z.string(),
  [InternalNotificationKey.FP_CODE]: z.string(),
  [InternalNotificationKey.NAME]: z.string(),
});

export type SharedFolderSharingTargetParticipantInfoTemplateData = z.infer<typeof SSharedFolderSharingTargetParticipantInfoTemplateData>;

export const SSharedFolderEntityOperationTemplateData = z.object({
  [InternalNotificationKey.ENTITY_TYPE]: z.string(),
  [InternalNotificationKey.REF_NUMBER]: z.string(),
  [InternalNotificationKey.SUBJECT]: z.string(),
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: z.string(),
  [InternalNotificationKey.ID]: z.string(),
  [InternalNotificationKey.NAME]: z.string(),
});

export type SharedFolderEntityOperationTemplateData = z.infer<typeof SSharedFolderEntityOperationTemplateData>;


export enum SharedFolderToastType {
  SHARED_FOLDER_CREATE_SUCCESS = 'SHARED_FOLDER_CREATE_SUCCESS',
  SHARED_FOLDER_CREATE_TARGET_PARTICIPANT = 'SHARED_FOLDER_CREATE_TARGET_PARTICIPANT',
  SHARED_FOLDER_CREATE_ERROR = 'SHARED_FOLDER_CREATE_ERROR',
  SHARED_FOLDER_UPDATE_SUCCESS = 'SHARED_FOLDER_UPDATE_SUCCESS',
  SHARED_FOLDER_UPDATE_PERMISSIONS_ADDED_TARGET_PARTICIPANT = 'SHARED_FOLDER_UPDATE_PERMISSIONS_ADDED_TARGET_PARTICIPANT',
  SHARED_FOLDER_UPDATE_PERMISSIONS_REMOVED_TARGET_PARTICIPANT = 'SHARED_FOLDER_UPDATE_PERMISSIONS_REMOVED_TARGET_PARTICIPANT',
  SHARED_FOLDER_UPDATE_ERROR = 'SHARED_FOLDER_UPDATE_ERROR',
  SHARED_FOLDER_DELETE_TARGET_PARTICIPANT = 'SHARED_FOLDER_DELETE_TARGET_PARTICIPANT',
  SHARED_FOLDER_DELETE_SUCCESS = 'SHARED_FOLDER_DELETE_SUCCESS',
  SHARED_FOLDER_DELETE_ERROR = 'SHARED_FOLDER_DELETE_ERROR',
  SHARED_FOLDER_OBJECT_INSERT_SUCCESS = 'SHARED_FOLDER_OBJECT_INSERT_SUCCESS',
  SHARED_FOLDER_OBJECT_INSERT_ERROR = 'SHARED_FOLDER_OBJECT_INSERT_ERROR',
  SHARED_FOLDER_OBJECT_REMOVE_SUCCESS = 'SHARED_FOLDER_OBJECT_REMOVE_SUCCESS',
  SHARED_FOLDER_OBJECT_REMOVE_ERROR = 'SHARED_FOLDER_OBJECT_REMOVE_ERROR',
  SHARED_FOLDER_OBJECT_MOVE_SUCCESS = 'SHARED_FOLDER_OBJECT_MOVE_SUCCESS',
  SHARED_FOLDER_OBJECT_MOVE_ERROR = 'SHARED_FOLDER_OBJECT_MOVE_ERROR',
  INSERT_INTO_SHARED_FOLDER_SUCCESS = 'INSERT_INTO_SHARED_FOLDER_SUCCESS',
  REMOVE_FROM_SHARED_FOLDER_SUCCESS = 'REMOVE_FROM_SHARED_FOLDER_SUCCESS',
  MOVE_BETWEEN_SHARED_FOLDERS_SUCCESS = 'MOVE_BETWEEN_SHARED_FOLDERS_SUCCESS'
}

@Injectable({
  providedIn: 'root',
})
export class SharedFolderToastService {

  private toastService = inject(ToastService);

  dispatchSimpleInfoToast(toastType: string) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, toastType);
  }

  dispatchSimpleWarningToast(toastType: string) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, toastType);
  }

  dispatchSimpleErrorToast(toastType: string) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, toastType);
  }

  dispatchSharedFolderCreateSuccess(templateData: SharedFolderTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, SharedFolderToastType.SHARED_FOLDER_CREATE_SUCCESS, templateData, SSharedFolderTemplateData);
  }

  dispatchSharedFolderUpdateSuccess(templateData: SharedFolderTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, SharedFolderToastType.SHARED_FOLDER_UPDATE_SUCCESS, templateData, SSharedFolderTemplateData);
  }

  dispatchSharedFolderDeleteSuccess(templateData: SharedFolderTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, SharedFolderToastType.SHARED_FOLDER_DELETE_SUCCESS, templateData, SSharedFolderTemplateData);
  }

  dispatchInsertIntoSharedFolderSuccess(templateData: SharedFolderTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, SharedFolderToastType.INSERT_INTO_SHARED_FOLDER_SUCCESS, templateData, SSharedFolderTemplateData);
  }

  dispatchRemoveFromSharedFolderSuccess(templateData: SharedFolderTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, SharedFolderToastType.REMOVE_FROM_SHARED_FOLDER_SUCCESS, templateData, SSharedFolderTemplateData);
  }

  dispatchMoveBetweenSharedFoldersSuccess(templateData: SharedFolderTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, SharedFolderToastType.MOVE_BETWEEN_SHARED_FOLDERS_SUCCESS, templateData, SSharedFolderTemplateData);
  }

}
