import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {forkJoin, Observable, of, switchMap} from 'rxjs';
import {
  DeliveryResultDto,
  DeliveryServiceBasicDto,
  DeliveryServiceCombinationDto,
  DeliveryTypeDto
} from '|api/codebook';
import {
  AnalogComponentOrigin,
  AnalogCompositionType,
  DeliveryResultConfirmationForm,
  EsslComponentRelation,
  MessageStatusAfterDelivery
} from '|api/commons';
import {ApiDigitalComponentVersionService} from '|api/component';
import {DigitalComponentVersionDto, DocumentDto} from '|api/document';
import {
  ApiOwnConsignmentService,
  OwnConsignmentDto,
  OwnDigitalConsignmentCreateDto,
  OwnDigitalConsignmentDto,
  OwnPaperConsignmentCreateDto,
  OwnPaperConsignmentDto,
  ProofOfDeliveryCreateDto,
  ProofOfDeliveryDto
} from '|api/sad';
import {ManualDeliveryResultRecordAction, ManualDeliveryResultRecordMode} from '../../model/own-consignment-model';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {WizardComponent} from '../../../../dialogs/wizard/wizard.component';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {Option} from '../../../../../model';
import {ManualDeliveryResultDocumentSelectorDatasource} from './manual-delivery-result-document-selector.datasource';
import {enumToOptions, namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {DocumentView} from '../../../document-toolbar/services/toolbar-common.utils';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {DocumentSearchService} from '../../../../../services/document-search.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DELIVERY_TYPE_DATABOX_CODE} from '../../../shared-document.utils';
import {getMidnightOfTheDay, getTodayMidnight} from '../../../../../lib/utils';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';
import {
  getDeliveryResultSpecificationFormGroup
} from '../manual-delivery-result-form/manual-delivery-result-form.component';

enum ManualDeliveryArtefactType {
  PHYSICAL_ARTEFACT = 'PHYSICAL_ARTEFACT',
  DIGITAL_ARTEFACT = 'DIGITAL_ARTEFACT',
}

enum ManualDeliveryResultDialogStep {
  DELIVERY_RESULT_SPECIFICATION = 'DELIVERY_RESULT_SPECIFICATION',
  DELIVERY_CONFIRMATION_SELECTION = 'DELIVERY_CONFIRMATION_SELECTION',
}

type ManualDeliveryResultDialogBeforeCreateData = {
  action: ManualDeliveryResultRecordAction.RECORD_DELIVERY_RESULT,
  mode: ManualDeliveryResultRecordMode.BEFORE_CREATE,
  consignment: OwnPaperConsignmentCreateDto | OwnDigitalConsignmentCreateDto,
};

type ManualDeliveryResultDialogAfterCreateData = {
  action: ManualDeliveryResultRecordAction,
  mode: ManualDeliveryResultRecordMode.AFTER_CREATE,
  consignment: OwnConsignmentDto,
};

export type ManualDeliveryResultDialogData = (
  ManualDeliveryResultDialogBeforeCreateData |
  ManualDeliveryResultDialogAfterCreateData
);

export interface ManualDeliveryResultDialogResult {
  proofOfDelivery: ProofOfDeliveryCreateDto | ProofOfDeliveryDto;
}


@Component({
  selector: 'icz-manual-delivery-result-dialog',
  templateUrl: './manual-delivery-result-dialog.component.html',
  styleUrls: ['./manual-delivery-result-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ManualDeliveryResultDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<ManualDeliveryResultDialogResult>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private searchService = inject(DocumentSearchService);
  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private destroyRef = inject(DestroyRef);
  protected dialogData = injectModalData<ManualDeliveryResultDialogData>();

  @ViewChild(WizardComponent)
  wizard!: WizardComponent;

  deliveryResultSpecificationFormGroup = getDeliveryResultSpecificationFormGroup();

  confirmationFileFormGroup = new IczFormGroup({
    deliveryConfirmationFile: new IczFormArray(
      () => new IczFormGroup({file: new IczFormControl<Nullable<string>>()}),
      [], // initial length = 0
    ),
    artefactType: new IczFormControl<Nullable<string>>(null),
    physicalArtefactName: new IczFormControl<Nullable<string>>(null),
  });

  isValidAcknowledgementDate = {
    validationFn: (d: Date) => {
      const deliveryDate = this.dialogData.consignment.dispatchDate;

      if (deliveryDate) {
        return d <= getTodayMidnight() && d >= getMidnightOfTheDay(new Date(deliveryDate));
      }
      else {
        return d <= getTodayMidnight();
      }
    },
    invalidDateMessage: {
      errorMessageTemplate: 'Datum nesmí být v budoucnosti a zároveň nesmí být před datem vypravení zásilky, je-li známo'
    }
  };

  deliveryResults: DeliveryResultDto[] = [];

  deliveryResultOptions: Option[] = [];

  selectedDeliveryResult: Nullable<DeliveryResultDto>;

  fileDeletionHandler = (index: number) => of(null);

  dataSource = new ManualDeliveryResultDocumentSelectorDatasource(this.searchService);

  deliveryConfirmationDocument: Nullable<DocumentDto>;

  formGroupsToCheck!: string[];

  artefactTypeOptions: Option[] = enumToOptions('manualDeliveryArtefactType', ManualDeliveryArtefactType);

  get isDigitalArtefactTypeSelected() {
    return this.confirmationFileFormGroup.get('artefactType')!.value === ManualDeliveryArtefactType.DIGITAL_ARTEFACT;
  }

  get deliveryConfirmationFormControl() {
    return this.deliveryResultSpecificationFormGroup.get('deliveryConfirmationForm')!;
  }

  get deliveryConfirmationForm() {
    return this.deliveryConfirmationFormControl.value;
  }

  get shouldAppendDeliveryConfirmation() {
    return (
      this.selectedDeliveryResult?.deliveryConfirmationForm === DeliveryResultConfirmationForm.ARTIFACT ||
      this.selectedDeliveryResult?.deliveryConfirmationForm === DeliveryResultConfirmationForm.DOCUMENT ||
      (
        this.selectedDeliveryResult?.deliveryConfirmationForm === DeliveryResultConfirmationForm.ARTIFACT_OR_DOCUMENT &&
        this.deliveryConfirmationForm
      )
    );
  }

  get hasDeliveryConfirmationFileSelected() {
    return this.deliveryConfirmationFileControl.length > 0;
  }

  get deliveryConfirmationFileControl() {
    return this.confirmationFileFormGroup.get('deliveryConfirmationFile') as IczFormArray;
  }

  get effectiveDeliveryConfirmationForm(): Nullable<DeliveryResultConfirmationForm> {
    if (this.isConfirmationFormArtifactOrDocument) {
      return this.deliveryConfirmationFormControl.value as DeliveryResultConfirmationForm;
    }
    else {
      return this.selectedDeliveryResult?.deliveryConfirmationForm;
    }
  }

  get isConfirmationFormArtifactOrDocument() {
    return this.selectedDeliveryResult?.deliveryConfirmationForm === DeliveryResultConfirmationForm.ARTIFACT_OR_DOCUMENT;
  }

  get isAddProofOfDeliveryAction() {
    return this.dialogData.action === ManualDeliveryResultRecordAction.ADD_PROOF_OF_DELIVERY;
  }

  get currentWizardStep() {
    return (this.wizard?.currentStep?.id as Nullable<ManualDeliveryResultDialogStep>) ?? ManualDeliveryResultDialogStep.DELIVERY_RESULT_SPECIFICATION;
  }

  readonly DocumentView = DocumentView;
  readonly ManualDeliveryResultDialogStep = ManualDeliveryResultDialogStep;
  readonly DeliveryResultConfirmationForm = DeliveryResultConfirmationForm;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['deliveryResultSpecificationFormGroup', 'confirmationFileFormGroup']);

    this.confirmationFileFormGroup.get('artefactType')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value === ManualDeliveryArtefactType.PHYSICAL_ARTEFACT) {
        this.confirmationFileFormGroup.get('physicalArtefactName')!.setValidators([IczValidators.required()]);
        this.confirmationFileFormGroup.get('deliveryConfirmationFile')!.clearValidators();
      } else if (value === ManualDeliveryArtefactType.DIGITAL_ARTEFACT) {
        this.confirmationFileFormGroup.get('physicalArtefactName')!.clearValidators();
        this.confirmationFileFormGroup.get('deliveryConfirmationFile')!.setValidators([IczValidators.oneFileSelected()]);
      } else {
        this.confirmationFileFormGroup.get('deliveryConfirmationFile')!.clearValidators();
        this.confirmationFileFormGroup.get('physicalArtefactName')!.clearValidators();
      }
      this.confirmationFileFormGroup.get('physicalArtefactName')!.updateValueAndValidity();
      this.confirmationFileFormGroup.get('deliveryConfirmationFile')!.updateValueAndValidity();
    });

    const deliveryResultControl = this.deliveryResultSpecificationFormGroup.get('deliveryResultId')!;

    if (this.isAddProofOfDeliveryAction) {
      deliveryResultControl.disable();
      this.deliveryResultSpecificationFormGroup.get('acknowledgementDate')!.disable();
    }

    this.loadingService.doLoading(
      forkJoin([
        this.codebookService.deliveryTypes(),
        this.codebookService.deliveryResults(),
        this.codebookService.deliveryServiceBasics(),
        this.codebookService.deliveryServiceCombinations(),
      ]).pipe(
        switchMap(([deliveryTypes, deliveryResults, deliveryServiceBasics, deliveryServiceCombinations]) => {
          if (this.isAddProofOfDeliveryAction) {
            return forkJoin([
              of(deliveryTypes),
              of(deliveryResults),
              of(deliveryServiceBasics),
              of(deliveryServiceCombinations),
              this.apiOwnConsignmentService.ownConsignmentGetProofOfDelivery({
                proofOfDeliveryId: (this.dialogData.consignment as OwnPaperConsignmentDto | OwnDigitalConsignmentDto).proofOfDeliveryId!,
              })
            ]);
          }
          else {
            return of([
              deliveryTypes,
              deliveryResults,
              deliveryServiceBasics,
              deliveryServiceCombinations,
              null
            ] as [DeliveryTypeDto[], DeliveryResultDto[], DeliveryServiceBasicDto[], DeliveryServiceCombinationDto[], Nullable<ProofOfDeliveryDto>]);
          }
        })
      ),
      this
    ).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(([deliveryTypes, deliveryResults, deliveryServiceBasics, deliveryServiceCombinations, proofOfDelivery]) => {
      const effectiveBasicServiceId = (
        (this.dialogData.consignment! as OwnConsignmentDto).deliveryServiceBasicId ??
        deliveryServiceCombinations.find(dsc => dsc.id === this.dialogData.consignment.deliveryServiceCombinationId)?.basicService?.id
      );
      const basicService = deliveryServiceBasics.find(dsb => dsb.id === effectiveBasicServiceId);
      const isDeliveryConfirmationRequired =  basicService ? basicService.onlyDeliversAgainstConfirmation : false;
      this.deliveryResults = deliveryResults;
      const deliveryResultsForDeliveryType = deliveryResults.filter(dr => !dr.deliveryTypeId || dr.deliveryTypeId === this.dialogData.consignment!.deliveryTypeId);

      if (isDeliveryConfirmationRequired) {
        let relevantDeliveryResults = deliveryResultsForDeliveryType.filter(dr => dr.deliveryConfirmationForm);

        const databoxDeliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_DATABOX_CODE)!.id;

        if (this.dialogData.consignment.deliveryTypeId === databoxDeliveryTypeId) {
          relevantDeliveryResults = relevantDeliveryResults.filter(dr => dr.statusAfterDelivery === MessageStatusAfterDelivery.NOT_DELIVERED);
        }

        this.deliveryResultOptions = relevantDeliveryResults.map(namedDtoToOption());
      } else {
        this.deliveryResultOptions = deliveryResultsForDeliveryType.map(namedDtoToOption());
      }

      if (proofOfDelivery) {
        deliveryResultControl.setValue(proofOfDelivery.deliveryResultId);
        this.deliveryResultSpecificationFormGroup.get('note')!.setValue(proofOfDelivery.note);
      }
    });
  }

  onSelectedDeliveryResultChange(deliveryResult: Nullable<DeliveryResultDto>) {
    this.selectedDeliveryResult = deliveryResult;
    const artefactTypeControl = this.confirmationFileFormGroup.get('artefactType')!;
    const confirmationFileControl = this.confirmationFileFormGroup.get('deliveryConfirmationFile')!;

    if (this.shouldAppendDeliveryConfirmation) {
      artefactTypeControl.setValidators([IczValidators.required()]);
      artefactTypeControl.setValue(ManualDeliveryArtefactType.DIGITAL_ARTEFACT);
      confirmationFileControl.setValidators([IczValidators.oneFileSelected()]);
    }
    else {
      artefactTypeControl.clearValidators();
      artefactTypeControl.setValue(null);
      confirmationFileControl.clearValidators();
    }

    artefactTypeControl.updateValueAndValidity();
    confirmationFileControl.updateValueAndValidity();
  }

  confirmationDocumentChanged(documents: Array<DocumentDto>) {
    this.deliveryConfirmationDocument = documents[0];
  }

  goToDeliveryConfirmationStep() {
    this.wizard.basicNextStep();
  }

  submit(withDeliveryConfirmationAppend: boolean) {
    const formValue = this.deliveryResultSpecificationFormGroup.getRawValue();
    const confirmationFileFormValue = this.confirmationFileFormGroup.value;
    const isDeliveryConfirmationArtefact = !formValue.deliveryConfirmationForm || formValue.deliveryConfirmationForm === DeliveryResultConfirmationForm.ARTIFACT;
    const isDeliveryConfirmationDocument = !formValue.deliveryConfirmationForm || formValue.deliveryConfirmationForm === DeliveryResultConfirmationForm.DOCUMENT;
    const uploadBlob = confirmationFileFormValue.deliveryConfirmationFile?.[0]?.file as Nullable<File>;
    const isDigitalArtefact = !confirmationFileFormValue.artefactType || confirmationFileFormValue.artefactType === ManualDeliveryArtefactType.DIGITAL_ARTEFACT;
    let requestDto: Nullable<ProofOfDeliveryCreateDto>;

    let uploadDeliveryConfirmation$: Observable<Nullable<DigitalComponentVersionDto>> = of(null);
    let proofOfDeliveryRequest$: Observable<Nullable<ProofOfDeliveryDto>>;

    if (withDeliveryConfirmationAppend) {
      if (isDeliveryConfirmationArtefact) {
        if (uploadBlob && isDigitalArtefact) {
          uploadDeliveryConfirmation$ = this.apiDigitalComponentVersionService.digitalComponentVersionUploadNewFile({
            body: {
              file: uploadBlob,
            }
          });

          requestDto = {
            deliveryResultId: formValue.deliveryResultId!,
            note: formValue.note!,
            acknowledgementDate: formValue.acknowledgementDate!,
            deliveryConfirmationPaperComponentDto: {
              label: this.translateService.instant('Doručenka - digitální záznam'),
              count: 1,
              isFinal: false,
              numberOfSheets: 1,
              originType: AnalogComponentOrigin.ANALOG_BORN,
              relationType: EsslComponentRelation.ENCLOSURE,
              compositionType: AnalogCompositionType.SHEETS,
            },
          };

          proofOfDeliveryRequest$ = uploadDeliveryConfirmation$.pipe(
            switchMap(uploadedComponentVersion => {
              requestDto!.deliveryConfirmationComponentVersionId = uploadedComponentVersion?.id;

              return this.apiOwnConsignmentService.ownConsignmentCreateProofOfDelivery({
                consignmentId: (this.dialogData as ManualDeliveryResultDialogAfterCreateData).consignment.id,
                body: requestDto!,
              });
            }),
          );
        }
        else {
          requestDto = {
            deliveryResultId: formValue.deliveryResultId!,
            note: formValue.note!,
            acknowledgementDate: formValue.acknowledgementDate!,
            deliveryConfirmationPaperComponentDto: {
              label: confirmationFileFormValue.physicalArtefactName,
              count: 1,
              isFinal: false,
              numberOfSheets: 1,
              originType: AnalogComponentOrigin.ANALOG_BORN,
              relationType: EsslComponentRelation.ENCLOSURE,
              compositionType: AnalogCompositionType.SHEETS,
            },
          };

          proofOfDeliveryRequest$ = this.apiOwnConsignmentService.ownConsignmentCreateProofOfDelivery({
            consignmentId: (this.dialogData as ManualDeliveryResultDialogAfterCreateData).consignment.id,
            body: requestDto,
          });
        }
      }
      else if (isDeliveryConfirmationDocument) {
        requestDto = {
          deliveryResultId: formValue.deliveryResultId!,
          note: formValue.note!,
          acknowledgementDate: formValue.acknowledgementDate!,
          deliveryConfirmationDocumentId: this.deliveryConfirmationDocument?.id,
        };

        proofOfDeliveryRequest$ = this.apiOwnConsignmentService.ownConsignmentCreateProofOfDelivery({
          consignmentId: (this.dialogData as ManualDeliveryResultDialogAfterCreateData).consignment.id,
          body: requestDto,
        });
      }
      else {
        proofOfDeliveryRequest$ = of(null);
      }
    }
    else {
      requestDto = {
        deliveryResultId: formValue.deliveryResultId!,
        note: formValue.note!,
        acknowledgementDate: formValue.acknowledgementDate!,
      };

      proofOfDeliveryRequest$ = this.apiOwnConsignmentService.ownConsignmentCreateProofOfDelivery({
        consignmentId: (this.dialogData as ManualDeliveryResultDialogAfterCreateData).consignment.id,
        body: requestDto,
      });
    }

    if (this.dialogData.mode === ManualDeliveryResultRecordMode.AFTER_CREATE) {
      this.loadingService.doLoading(
        proofOfDeliveryRequest$,
        this,
      ).subscribe(proofOfDelivery => {
        this.modalRef.close({
          proofOfDelivery,
        } as ManualDeliveryResultDialogResult);
      });
    }
    else {
      if (isDigitalArtefact) {
        this.loadingService.doLoading(
          uploadDeliveryConfirmation$,
          this
        ).subscribe(uploadedComponentVersion => {
          if (uploadedComponentVersion) {
            requestDto!.deliveryConfirmationComponentVersionId = uploadedComponentVersion.id;
          }

          this.modalRef.close({
            proofOfDelivery: requestDto!,
          } as ManualDeliveryResultDialogResult);
        });
      }
      else {
        this.modalRef.close({
          proofOfDelivery: requestDto!,
        } as ManualDeliveryResultDialogResult);
      }
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

  isDeliveryResultSpecificationStepValid() {
    return true;
    // this.shouldAppendDeliveryConfirmation && this.isConfirmationFormArtifactOrDocument
  }
}
