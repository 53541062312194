import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {DocumentForm, FileForm, RegistryOfficeTransferIncidentState} from '|api/commons';
import {isDocumentObject, isFileObject, isStorageUnitObject} from '../../shared-document.utils';
import {TransferableObject} from './transferable-object-toolbar-buttons.service';
import {DocumentOperationValidators} from './document-operation-validators';
import {FileOperationValidators} from './file-operation-validators';
import {EsslObject} from '../../model/entity.model';
import {StorageUnitToolbarValidators} from './storage-unit-toolbar.validators';
import {RegistryOfficeTransferIncidentDto} from '|api/document';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';

export class RegistryOfficeTransferValidators {
  static isEntityAnalogOrHybrid(): OperationValidator<TransferableObject> {
    return (entity: TransferableObject) => {
      if (isDocumentObject(entity)) {
        return (entity.documentForm === DocumentForm.ANALOG || entity.documentForm === DocumentForm.HYBRID) ? {validationMessage: 'Dokument je analogový nebo hybridní a nelze jej předat samostatně.' } : null;
      } else if (isFileObject(entity)) {
        return (entity.fileForm === FileForm.ANALOG || entity.fileForm === FileForm.HYBRID) ? {validationMessage: 'Spis je analogový nebo hybridní a nelze jej předat samostatně.' } : null;
      } else {
        return null;
      }
    };
  }

  static isEntityInRegistryOffice(): OperationValidator<TransferableObject> {
    return (entity: TransferableObject) => {
      return entity.inRegistryOffice ? {validationMessage: 'Entita je ve spisovně.' } : null;
    };
  }

  static isEntityInIncorrectState(): OperationValidator<TransferableObject> {
    return (entity: TransferableObject) => {
      if (isDocumentObject(entity)) {
        return DocumentOperationValidators.isDocumentNotSettled()(entity);
      } else if (isFileObject(entity)) {
        return FileOperationValidators.isFileNotClosed()(entity);
      } else {
        return null;
      }
    };
  }

  static isEntityIncomplete(): OperationValidator<TransferableObject> {
    return (entity: TransferableObject) => {
      if (isStorageUnitObject(entity)) {
        return entity.contentCount === 0 ? {validationMessage: 'Ukládací jednotka je prázdna.' } : null;
      } else {
        return null;
      }
    };
  }

  static isEntityIncorrectType(): OperationValidator<TransferableObject> {
    return (entity: TransferableObject) => {
      if (isDocumentObject(entity)) {
        return (entity.fileId !== null || entity.storageUnitId !== null) ? {validationMessage: 'Dokument je zařazený do spisu nebo ukládací jednotky.' } : null;
      } else if (isFileObject(entity)) {
        return (entity.storageUnitId !== null) ? {validationMessage: 'Spis je zařazený do ukládací jednotky.' } : null;
      } else {
        return null;
      }
    };
  }

  static isSeparateDocumentTransferringUnavailable(storeDocumentsSeparatelyUntil: Date): OperationValidator<Nullable<EsslObject>> {
    return (storableObject: Nullable<EsslObject>) => {
      return StorageUnitToolbarValidators.isSeparateDocumentStoringUnavailable(storeDocumentsSeparatelyUntil)(storableObject) ? {validationMessage: 'Samostatné předávaní dokumentů do spisovny není povoleno.'} : null;
    };
  }

  static isIncidentAlreadyResolved(): OperationValidator<RegistryOfficeTransferIncidentDto> {
    return (entity: RegistryOfficeTransferIncidentDto) => {
      return (entity.state === RegistryOfficeTransferIncidentState.SOLVED) ? {validationMessage: 'Chyba předání do spisvony již byla označena za vyřešenou.'} : null;
    };
  }

  static isTransferActivityReturnedFromDesa(): OperationValidator<SearchRecordSourceRegistryOfficeTransferActivityDto> {
    return (entity: SearchRecordSourceRegistryOfficeTransferActivityDto) => {
      return !isNil(entity.takenOverAt) ? {validationMessage: 'Entita nebyla vrácena manuálně, ale elektronickou spisovnou.'} : null;
    };
  }
}
