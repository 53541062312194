/* tslint:disable */
/* eslint-disable */

/**
 * Envelope section type
 */
export enum EnvelopeSectionType {
  BARCODE = 'BARCODE',
  CHECKBOX = 'CHECKBOX',
  CONSIGNEE = 'CONSIGNEE',
  SENDER = 'SENDER',
  TEXT = 'TEXT',
  SPECIFIED_TEXT = 'SPECIFIED_TEXT',
  VARIABLE = 'VARIABLE'
}
