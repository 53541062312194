<icz-filter-tag
  [waiting]="isLoading"
  [blockingOverlayOpts]="{spinnerDiameter: 24}"
  [filterName]="filterLabel"
  [filterValue]="filterValue | async"
  [selectedOptions]="shouldRenderChips ? selectedOptions : null"
  [viewValue]="viewValue"
  [operator]="item?.filterOption?.value"
  [isDisabled]="isDisabled"
  [class.active]="isOpen"
  [tabIndex]="isDisabled ? -1 : 0"
  [customTemplate]="isBooleanFilter ? booleanFilterValue : null"
  cdkOverlayOrigin #popoverOrigin="cdkOverlayOrigin"
  (clicked)="openPopup()">
  @if (shouldDisplayExpansionHint || shouldDisplayCalendarHint(item) || shouldDisplayCancelHint(item)) {
    <div class="row" suffix>
      @if (shouldDisplayExpansionHint) {
        <icz-button disableFocus size="default"
        svgIcon="expand_more" (onAction)="openPopup($event)" background disableRipple></icz-button>
      }
      @if (shouldDisplayCalendarHint(item)) {
        <icz-button disableFocus size="default"
        svgIcon="calendar" (onAction)="openPopup($event)" background disableRipple></icz-button>
      }
      @if (shouldDisplayCancelHint(item)) {
        <icz-button (onAction)="cancel($event)"
          svgIcon="close" background disableRipple size="small">
        </icz-button>
      }
    </div>
  }
</icz-filter-tag>

<icz-popover
  [isOpen]="isOpen"
  [allowHorizontalPlacement]="useAsFormElement"
  [overlayOrigin]="popoverOrigin"
  (onClose)="closePopup()">
  @if (isOpen) {
    <div [ngClass]="{'filter-popover': !isModalFilterItem, 'filter-modal': isModalFilterItem}" [waiting]="!component && !isModalFilterItem">
      <ng-container #customFilter></ng-container>
    </div>
  }
</icz-popover>

<ng-template #booleanFilterValue>
  <div class="items-center row content-space-around">
    <div class="icz-body-1 grow">{{item?.columnLabel}}</div>
    <icz-checkbox class="filter-checkbox" [value]="null" (valueChange)="booleanFilterChanged($event)"></icz-checkbox>
  </div>
</ng-template>
