import {inject, Injectable} from '@angular/core';
import {WebSocketNotificationsService} from '../../../components/notifications/web-socket-notifications.service';
import {NotificationCountService} from '../../../components/notifications/notification-count.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationCountHandlerService {

  private notificationCountService = inject(NotificationCountService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerCountHandler(count => {
      this.notificationCountService.updateNotificationCount(count);
    });
  }

}
