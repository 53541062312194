/* tslint:disable */
/* eslint-disable */

/**
 * Form of the non-paper analog component
 */
export enum AnalogComponentForm {
  ITEM = 'ITEM',
  MEDIUM = 'MEDIUM'
}
