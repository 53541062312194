<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  @if (activity) {
    @if (activity.circulationEntityType === EntityType.FILE) {
      <div class="icz-body-1 mb-12">{{ 'Úkol bude odebrán následujícímu řešiteli' | translate }}:</div>
      @for (task of tasksInfo; track task.orderNumber) {
        <icz-task-info-bit
          [taskInfo]="task"
          [parentActivity]="activity"
        ></icz-task-info-bit>
      }
    }
    @else {
      <icz-section label="Komu chcete úkol odebrat?">
        <icz-radio-group>
          <icz-radio-button label="Odebrat úkol všem řešitelům, kteří zatím na úkolu nezačali pracovat"
            formControlName="taskRemovalMode"
            [value]="TaskRemovalMode.ALL"
          ></icz-radio-button>
          <icz-radio-button label="Vybrat řešitele, kterým má být úkol odebrán"
            formControlName="taskRemovalMode"
            [value]="TaskRemovalMode.SOME"
          ></icz-radio-button>
        </icz-radio-group>
      </icz-section>
      @if (form.get('taskRemovalMode')!.value === TaskRemovalMode.SOME) {
        <div>
          @for (task of tasksInfo; track task.orderNumber) {
            <icz-checkbox
              (changed)="toggleTaskRemoval(task, $event)">
              <icz-task-info-bit
                [taskInfo]="task"
                [parentActivity]="activity"
              ></icz-task-info-bit>
            </icz-checkbox>
          }
        </div>
      }
    }
    <icz-form-field
      formControlName="reason"
      label="Důvod odvolání"
      class="mt-32"
      [autoSizeMax]="10"
    ></icz-form-field>
  }
  @if (isBulkRevoke) {
    <icz-section label="Komu chcete úkol odebrat?">
      <icz-radio-group>
        <icz-radio-button label="Odebrat úkol všem řešitelům, kteří zatím na úkolu nezačali pracovat"
          formControlName="taskRemovalMode"
          [value]="TaskRemovalMode.ALL"
        ></icz-radio-button>
        <icz-radio-button label="Vybrat řešitele, kterým má být úkol odebrán"
          iczTooltip="Hromadné odebrání úkolů neumožňuje zvolit řešitele, kterým má být úkol odebrán"
          [fieldDisabled]="true"
          [value]="TaskRemovalMode.SOME"
        ></icz-radio-button>
      </icz-radio-group>
    </icz-section>
    <icz-form-field
      formControlName="reason"
      label="Důvod odvolání"
      class="mt-32"
      [autoSizeMax]="10"
    ></icz-form-field>
  }
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="!isFormValid || loadingService.isLoading(this)" leftButtons (onAction)="revokeTask()" primary label="Odvolat úkol"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
