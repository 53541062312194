/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { numberingConfigurationCreate } from '../fn/numbering-configuration/numbering-configuration-create';
import { NumberingConfigurationCreate$Params } from '../fn/numbering-configuration/numbering-configuration-create';
import { numberingConfigurationDelete } from '../fn/numbering-configuration/numbering-configuration-delete';
import { NumberingConfigurationDelete$Params } from '../fn/numbering-configuration/numbering-configuration-delete';
import { NumberingConfigurationDto } from '../models/numbering-configuration-dto';
import { numberingConfigurationFindAll } from '../fn/numbering-configuration/numbering-configuration-find-all';
import { NumberingConfigurationFindAll$Params } from '../fn/numbering-configuration/numbering-configuration-find-all';
import { numberingConfigurationFindById } from '../fn/numbering-configuration/numbering-configuration-find-by-id';
import { NumberingConfigurationFindById$Params } from '../fn/numbering-configuration/numbering-configuration-find-by-id';
import { numberingConfigurationSearch } from '../fn/numbering-configuration/numbering-configuration-search';
import { NumberingConfigurationSearch$Params } from '../fn/numbering-configuration/numbering-configuration-search';
import { numberingConfigurationUpdate } from '../fn/numbering-configuration/numbering-configuration-update';
import { NumberingConfigurationUpdate$Params } from '../fn/numbering-configuration/numbering-configuration-update';
import { PageNumberingConfigurationDto } from '../models/page-numbering-configuration-dto';


/**
 * Controller for Numbering Configurations of entity classes
 */
@Injectable({ providedIn: 'root' })
export class ApiNumberingConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `numberingConfigurationFindById()` */
  static readonly NumberingConfigurationFindByIdPath = '/codebook/numbering-configuration/{id}';

  /**
   * Find numbering configuration by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `numberingConfigurationFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationFindById$Response(params: NumberingConfigurationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<NumberingConfigurationDto>> {
    return numberingConfigurationFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find numbering configuration by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `numberingConfigurationFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationFindById(params: NumberingConfigurationFindById$Params, context?: HttpContext): Observable<NumberingConfigurationDto> {
    return this.numberingConfigurationFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<NumberingConfigurationDto>): NumberingConfigurationDto => r.body)
    );
  }

  /** Path part for operation `numberingConfigurationUpdate()` */
  static readonly NumberingConfigurationUpdatePath = '/codebook/numbering-configuration/{id}';

  /**
   * Update existing numbering configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `numberingConfigurationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  numberingConfigurationUpdate$Response(params: NumberingConfigurationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<NumberingConfigurationDto>> {
    return numberingConfigurationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing numbering configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `numberingConfigurationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  numberingConfigurationUpdate(params: NumberingConfigurationUpdate$Params, context?: HttpContext): Observable<NumberingConfigurationDto> {
    return this.numberingConfigurationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<NumberingConfigurationDto>): NumberingConfigurationDto => r.body)
    );
  }

  /** Path part for operation `numberingConfigurationDelete()` */
  static readonly NumberingConfigurationDeletePath = '/codebook/numbering-configuration/{id}';

  /**
   * Delete existing numbering configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `numberingConfigurationDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationDelete$Response(params: NumberingConfigurationDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return numberingConfigurationDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing numbering configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `numberingConfigurationDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationDelete(params: NumberingConfigurationDelete$Params, context?: HttpContext): Observable<void> {
    return this.numberingConfigurationDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `numberingConfigurationFindAll()` */
  static readonly NumberingConfigurationFindAllPath = '/codebook/numbering-configuration';

  /**
   * Finds all numbering configurations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `numberingConfigurationFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationFindAll$Response(params?: NumberingConfigurationFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<NumberingConfigurationDto>>> {
    return numberingConfigurationFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all numbering configurations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `numberingConfigurationFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationFindAll(params?: NumberingConfigurationFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<NumberingConfigurationDto>> {
    return this.numberingConfigurationFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<NumberingConfigurationDto>>): Array<NumberingConfigurationDto> => r.body)
    );
  }

  /** Path part for operation `numberingConfigurationCreate()` */
  static readonly NumberingConfigurationCreatePath = '/codebook/numbering-configuration';

  /**
   * Create new numbering configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `numberingConfigurationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  numberingConfigurationCreate$Response(params: NumberingConfigurationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<NumberingConfigurationDto>> {
    return numberingConfigurationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new numbering configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `numberingConfigurationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  numberingConfigurationCreate(params: NumberingConfigurationCreate$Params, context?: HttpContext): Observable<NumberingConfigurationDto> {
    return this.numberingConfigurationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<NumberingConfigurationDto>): NumberingConfigurationDto => r.body)
    );
  }

  /** Path part for operation `numberingConfigurationSearch()` */
  static readonly NumberingConfigurationSearchPath = '/codebook/numbering-configuration/search-api';

  /**
   * Find all values of numbering configuration by selected criteria
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `numberingConfigurationSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationSearch$Response(params?: NumberingConfigurationSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageNumberingConfigurationDto>> {
    return numberingConfigurationSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values of numbering configuration by selected criteria
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `numberingConfigurationSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  numberingConfigurationSearch(params?: NumberingConfigurationSearch$Params|null|undefined, context?: HttpContext): Observable<PageNumberingConfigurationDto> {
    return this.numberingConfigurationSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageNumberingConfigurationDto>): PageNumberingConfigurationDto => r.body)
    );
  }

}
