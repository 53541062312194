/* tslint:disable */
/* eslint-disable */

/**
 * Direction of the electronic communitation.
 */
export enum ElectronicChannelDirection {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
  SEND_RECEIVE = 'SEND_RECEIVE'
}
