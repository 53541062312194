import {DestroyRef, inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Subject, Subscription} from 'rxjs';
import {
  FilterItemValue,
  FilterType,
  isListFilterDefinition,
  isListFilterItem,
  isNonemptyFilterDefinition,
  isNonemptyFilterItem,
  NonemptyFilterItem
} from '../filter.types';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {DialogService} from '../../../core/services/dialog.service';
import {getOperatorTranslationKey} from '../../../services/search-api.service';
import {
  addValueToFilterItem,
  EMPTY_FILTER_TREE,
  FilterItemValueTree,
  filterItemValueTreeToFilterItemTree
} from '../filter-trees.utils';
import {TableColumnsData} from '../table-columns-data';
import {startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Injectable()
export class TableToolbarService implements OnDestroy {

  private destroyRef = inject(DestroyRef);
  private dialogService = inject(DialogService);

  autoOpen = false;
  defaultToolbarItems: string[] = [];
  reloadFilters$ = new Subject<void>();

  allColumnFilters$ = new BehaviorSubject<NonemptyFilterItem[]>([]);

  enabledFilters$ = new BehaviorSubject<Nullable<string[]>>(null);

  filters$ = new BehaviorSubject<NonemptyFilterItem[]>([]);

  allColumnFilterOptions$ = this.allColumnFilters$.pipe(
    map(allFilters => {
      return allFilters
        .filter(u => isNonemptyFilterItem(u))
        .filter(u => !u.isDistinctive)
        .map(u => ({value: (u.customFilterId ? u.customFilterId : u.id)!, label: u.columnLabel}));
    }),
  );

  activeFilterValues$ = new BehaviorSubject<FilterItemValueTree>(EMPTY_FILTER_TREE);

  visibleFilters$ = this.filters$.pipe(
    map(filters => filters.filter(f => !f.isDistinctive)),
    map(filters => {
      if (this.enabledFilters$.value) {
        return filters.filter(f => this.enabledFilters$.value!.includes(f.id));
      }
      else {
        return filters;
      }
    }),
  );

  activeFilters$ = combineLatest([
    this.activeFilterValues$,
    this.allColumnFilters$,
  ]).pipe(
    map(([activeFilterValues, allColumnFilters]) => {
      return filterItemValueTreeToFilterItemTree(activeFilterValues, allColumnFilters);
    })
  );

  distinctiveFilters$ = this.filters$.pipe(
    map(allFilters => allFilters.filter(f => f.isDistinctive))
  );

  toolbarForm = new IczFormGroup({
    searchTerm: new IczFormControl<Nullable<string>>(null),
  });

  columnDefinitionsChangeSubscription: Nullable<Subscription>;

  ngOnDestroy() {
    this.columnDefinitionsChangeSubscription?.unsubscribe();
  }

  init(defaultToolbarItems: string[], columnsData: TableColumnsData<string>) {
    this.defaultToolbarItems = defaultToolbarItems;

    this.toolbarForm.reset(
      {
        searchTerm: null,
      },
      {
        emitEvent: false,
      }
    );

    this.columnDefinitionsChangeSubscription?.unsubscribe();

    this.columnDefinitionsChangeSubscription = columnsData.columnDefinitionsChange$.pipe(
      startWith(undefined),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      const columnDefinitions = columnsData.columnDefinitions;

      this.allColumnFilters$.next(columnDefinitions.filter(c => c.filterType !== FilterType.NONE).map(c => {
        return ({
          id: c.id,
          filterType: c.filterType,
          value: '',
          label: undefined,
          columnLabel: !c.label!.replace(/ /g, '') ? c.filterLabel! : c.label!,
          list: isListFilterDefinition(c) ? c.list : undefined,
          ...(isNonemptyFilterDefinition(c) ? c.filterConfig : {}),
        } as NonemptyFilterItem);
      }));
    });

    const distinctiveFilterIds = this.allColumnFilters$.value.filter(c => c.isDistinctive).map(c => c.id);

    const itemsInToolbar = [
      ...distinctiveFilterIds,
      ...this.defaultToolbarItems,
    ];

    this.filters$.next(this.allColumnFilters$.value.filter(c => itemsInToolbar.includes(c.customFilterId ? c.customFilterId : c.id)));

    this.activeFilterValues$.next(EMPTY_FILTER_TREE);
  }

  addTreeItems(deserializedQuery: FilterItemValueTree) {
    this.activeFilterValues$.next(deserializedQuery);
  }

  addItemValue(itemValue: FilterItemValue, reload = false) {
    const filterItem = this.allColumnFilters$.value.find(u => u.customFilterId ? u.customFilterId === itemValue.id : u.id === itemValue.id);

    if (filterItem) {
      const presetFilter = this.filters$.value.find(f => f.id === itemValue.id);

      if (presetFilter) {
        presetFilter.value = itemValue.value;
        presetFilter.subValues = itemValue.subValues;
        presetFilter.filterOption = {value: itemValue.operator!, label: getOperatorTranslationKey(itemValue.operator)};

        if (isListFilterItem(presetFilter)) {
          presetFilter.originId = itemValue.originId;
        }
      }
      else {
        this.addFilterItem(addValueToFilterItem(filterItem, itemValue));
      }

      if (reload) {
        this.reloadFilters();
      }
    }
    else {
      // Neznámé vyhledávací kritérium "{{criterionName}}". Vyhledávání podle tohoto kritéria nebude provedeno.
      this.dialogService.showError(
        'fe.ui.unknownFilteringCriterion',
          undefined,
        {criterionName: itemValue.id}
      );
    }
  }

  addFilterItem(item: NonemptyFilterItem, reload = false) {
    this.filters$.next([...this.filters$.value, item]);

    if (reload) this.reloadFilters();
  }

  removeFilterItem(item: NonemptyFilterItem, reload = false) {
    this.filters$.next(this.filters$.value.filter(f => f !== item));
    if (reload) this.reloadFilters();
  }

  clearAllFilters(reload = false) {
    this.filters$.next([]);
    this.activeFilterValues$.next(EMPTY_FILTER_TREE);
    if (reload) this.reloadFilters();
  }

  reloadFilters() {
    this.filters$.next(this.filters$.value);
    this.reloadFilters$.next();
  }

}
