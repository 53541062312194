import {ChartElementColor} from './chart-utils';
import {Statistic, StatisticsDimension} from '|api/elastic';
import {Option} from '../../model';


export interface GenericChartDataset {
  label: string,
  data: Array<number>,
  borderWidth?: number,
  barPercentage?: number,
  backgroundColor?: Array<ChartElementColor>,
  borderColor?: Array<ChartElementColor>,
  hoverOffset?: number,
}

export interface GenericChartConfig {
  labels: Array<string>,
  datasets: Array<GenericChartDataset>
}

export interface LineChartPoint {
  x: string;
  y: number;
}

export interface LineChartDataset {
  label: string,
  data: Array<number>,
  fullDataSet: Array<LineChartPoint>,
  fill?: boolean,
  borderColor?: ChartElementColor,
  backgroundColor?: ChartElementColor,
  tension?: number
}

export interface LineChartConfig {
  xAxisRange: [string, string], // [ISODate, ISODate]
  datasets: Array<LineChartDataset>,
}

// todo need to specify all periods by analysis
export enum TrendPeriod {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
}

export enum IczStatistic {
  NEW_DOCUMENT_COUNT = 'NEW_DOCUMENT_COUNT',
  DOCUMENT_COUNT = 'DOCUMENT_COUNT',
  NEW_FILE_COUNT = 'NEW_FILE_COUNT',
  FILE_COUNT = 'FILE_COUNT',
  FILE_IN_STORING_UNIT_COUNT = 'FILE_IN_STORING_UNIT_COUNT',
  NEW_COMPONENT_COUNT = 'NEW_COMPONENT_COUNT',
  DOCUMENT_SIZE = 'DOCUMENT_SIZE',
  FILE_SIZE = 'FILE_SIZE',
  FILE_BY_PERMISSION_COUNT = 'FILE_BY_PERMISSION_COUNT',
  /*NEW_ENTITY_CLASSES_CURRENT_CS = 'NEW_ENTITY_CLASSES_CURRENT_CS',
  SIP_PACKAGE_COUNT = 'SIP_PACKAGE_COUNT',
  NEW_CASE_FILE_COUNT = 'NEW_CASE_FILE_COUNT',
  FILES_BY_COMPONENT_FILESIZE = 'FILES_BY_COMPONENT_FILESIZE',
  COMPONENTS = 'COMPONENTS',*/
}

export enum StatisticFormatter {
  FILESIZE = 'FILESIZE',
}

export enum StatisticTrendCounterMode {
  LINK = 'LINK',
  COUNT = 'COUNT',
  GRAPH = 'GRAPH',
  TABLE = 'TABLE'
}

export interface AggregatedCounterData {
  label: string;
  value: number;
}

export interface TrendWidgetData {
  total: number;
  trend: number;
  aggregatedData?: AggregatedCounterData[];
}

export interface TrendCounterDefinition {
  id: IczStatistic;
  label: string;
  isTimeSpecific: boolean;
  tooltip: string;
  statisticKey: Statistic;
  mode?: StatisticTrendCounterMode;
  isDetailTestingFeature?: boolean;
  order: number;
  columnIndex: number;
}

export interface TrendCounterStoredSettings {
  id: IczStatistic;
  period: TrendPeriod;
  displayed: boolean;
  order: number;
  columnIndex: number;
}

export interface StatisticConfig {
  id: IczStatistic,
  period: TrendPeriod;
  statisticKey: Statistic;
  label: string;
  displayed: boolean;
  order: number;
  dimensions: Array<StatisticsDimension>,
  isTimeSpecific: boolean,
  tooltip?: string;
  allowBarChart: boolean,
  allowLineChart: boolean,
  allowDonutChart: boolean,
  barConfig?: GenericChartConfig,
  donutConfig?: GenericChartConfig,
  lineConfig?: LineChartConfig,
  formatter?: StatisticFormatter,
  mode?: StatisticTrendCounterMode;
  isDetailTestingFeature?: boolean;
}

export const periodOptions: Option[]  = [
  {value: TrendPeriod.LAST_7_DAYS, label: 'Za posledních 7 dní'},
  {value: TrendPeriod.LAST_30_DAYS, label: 'Za posledních 30 dní'},
];


