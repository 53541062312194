import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  AcknowledgementStatus,
  ApiInternalNotificationService,
  InternalNotificationDto,
  NotificationType
} from '|api/notification';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {injectModalData, injectModalRef} from '../../../lib/modals';
import {ResponsivityService} from '../../essentials/responsivity.service';
import {UserSettingsService} from '../../../services';
import {UserInitialsColor} from '../../essentials/user-initials/user-initials.model';
import {Button} from '../../essentials/button-collection/button-collection.component';
import {getMessageHeaderParams} from '../notification-message-header/notification-message-header.component';
import {getUserInitials} from '../../../model';
import {iczFormatDatetime} from '../../essentials/datetime.pipe';
import {ApplicationLanguage} from '../../../core/services/environment.models';


@Component({
  selector: 'icz-notification-message-dialog',
  templateUrl: './notification-message-dialog.component.html',
  styleUrls: ['./notification-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageDialogComponent {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private responsivityService = inject(ResponsivityService);
  private userSettingsService = inject(UserSettingsService);
  private translateService = inject(TranslateService);
  private apiInternalNotificationService = inject(ApiInternalNotificationService);
  protected message = injectModalData<InternalNotificationDto>();

  close() {
    this.modalRef.close(null);
  }

  protected readonly UserInitialsColor = UserInitialsColor;

  isToolbarCollapsed$ = combineLatest([
    this.responsivityService.isSmallDesktop$,
    this.userSettingsService.showTableLabels$,
  ]).pipe(
    map(([isSmallDesktop, showTableLabels]) => isSmallDesktop || !showTableLabels)
  );

  notificationActionButtons: Button[] = [
    {
      label: 'Označit jako přečtené',
      icon: 'doruceny_dokument',
      show: !this.isRead(this.message),
      action: _ => this.markAsRead(),
    },
    {
      label: 'Označit jako nepřečtené',
      icon: 'email',
      show: this.isRead(this.message),
      action: _ => this.markAsUnread(),
    },
    {
      label: 'Smazat',
      icon: 'delete',
      action: _ => this.deleteMessage(),
    },
  ];

  getUserAcronym(username: string): string {
    return getUserInitials([username]);
  }

  getMessageHeaderParams = getMessageHeaderParams;

  isRead(row: InternalNotificationDto): boolean {
    if (!row) return false;
    return row.acknowledgementStatus === AcknowledgementStatus.READ;
  }

  buttonClicked(btn: Button) {
    btn.action!(btn);
  }

  markAsRead() {
    this.apiInternalNotificationService.internalNotificationAccept({body: [this.message.id]}).subscribe(
      _ => {
        this.modalRef.close(true);
      }
    );
  }

  markAsUnread() {
    this.apiInternalNotificationService.internalNotificationUndoAccept({body: [this.message.id]}).subscribe(
      _ => {
        this.modalRef.close(true);
      });
  }

  deleteMessage() {
    this.apiInternalNotificationService.internalNotificationSoftDelete({body: [this.message.id]}).subscribe(_ => {
      this.modalRef.close(true);
    });
  }

  getNotificationDateLabel() {
    return `${this.translateService.instant('Vytvořeno')}: ${iczFormatDatetime(this.translateService.currentLang as ApplicationLanguage, this.message.auditInfo?.createdAt!)}`;
  }

  getNotificationStatusLabel() {
    if (this.message.notificationType === NotificationType.INFO) {
      return 'Informace';
    } else if (this.message.notificationType === NotificationType.WARNING) {
      return 'Varování';
    } else {
      return 'Chyba';
    }
  }

}
