<div>
  @if (!useValuesFromForm) {
    <icz-disposal-schedule-comparator
      [entityClassId]="entityClassId"
      [documentTypeId]="documentTypeId"
      [disposalScheduleSource]="disposalScheduleSource"
      [disposalScheduleComparison]="disposalScheduleComparison"
    ></icz-disposal-schedule-comparator>
  }
  <icz-disposal-tip
    [disposalScheduleId]="formDisposalScheduleId"
    [entityClassId]="entityClassId"
    [documentTypeId]="documentTypeId"
    [detailLevel]="DisposalTipDetailLevel.VALUE_WITH_LABEL"
    [showHelpField]="false"
    (disposalScheduleSource)="onDisposalScheduleSource($event)"
    (disposalScheduleSourceEntity)="onDisposalScheduleSourceEntitySelected($event)"
    (disposalScheduleComparison)="onDisposalScheduleComparison($event)"
  ></icz-disposal-tip>
  @if (disposalScheduleSourceEntitySelectedId) {
    <icz-retention-trigger-type-widget
      [form]="form"
      [externalRetentionTriggerIds]="externalRetentionTriggerSelectedIds"
      [disposalScheduleId]="disposalScheduleSourceEntitySelectedId"
      [retentionTriggerWidgetMode]="retentionTriggerWidgetMode"
      [showExternalRetentionYear]="true"
    ></icz-retention-trigger-type-widget>
  }
</div>
