<div>
  @if (label) {
    <label class="input-label">{{ label | translate }}</label>
  }
  @if (!_options.length) {
    <div class="text-center p-16">
      <p>{{ 'Žádné položky' | translate }}</p>
    </div>
  }
  @if (_options.length) {
    <div
      cdkDropList
      [class.item-list-disabled]="fieldDisabled"
      (cdkDropListDropped)="itemDropped($event)"
      >
      @for (option of optionsSortedByValue; track option?.value) {
        <icz-item-sorter-item
          cdkDrag
          [option]="option"
          [index]="$index"
          [itemCount]="_options.length"
          (upPressed)="moveUp($index)"
          (downPressed)="moveDown($index)">
        </icz-item-sorter-item>
      }
    </div>
  }
</div>
