import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {ConsignmentType, OwnConsignmentStatus} from '|api/commons';
import {ApiOwnConsignmentService} from '|api/sad';
import {FilterOperator, SearchApiService, SearchParams} from './search-api.service';
import {
  GenericOwnConsignment
} from '../components/shared-business-components/own-consignment-table/model/own-consignment-model';
import {UnitViewSearchConfig} from './search-api-with-elastic.service';

export const CONSIGNMENT_TYPE_FILTER_OVERRIDE = 'consignmentTypeOverride';
export const CONSIGNMENT_STATUS_FILTER_OVERRIDE = 'consignmentStatusOverride';

@Injectable({
  providedIn: 'root'
})
export class OwnConsignmentsSearchService extends SearchApiService {

  private processConsignmentTypeFilterOverride(searchParams: Partial<SearchParams>, consignmentTypes?: Nullable<ConsignmentType[]>){
    const hasSpecificConsignmentTypeIndex = searchParams.filter!.findIndex(f => f.fieldName === CONSIGNMENT_TYPE_FILTER_OVERRIDE);
    if (hasSpecificConsignmentTypeIndex > -1) {
      searchParams.filter!.splice(hasSpecificConsignmentTypeIndex, 1);
    } else {
      if (consignmentTypes?.length) searchParams.filter!.push({fieldName: 'consignmentType', value: consignmentTypes.toString(), operator: FilterOperator.inSet});
    }
  }

  findOwnDocumentOwnConsignments(documentId: number, searchParams: Partial<SearchParams>): Observable<Page<GenericOwnConsignment>> {
    return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiOwnConsignmentService.OwnConsignmentFindOwnConsignmentsForOwnDocumentPath, {id: documentId}));
  }

  findReceivedDocumentOwnConsignments(documentId: number, searchParams: Partial<SearchParams>): Observable<Page<GenericOwnConsignment>> {
    return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiOwnConsignmentService.OwnConsignmentFindOwnConsignmentsForDocumentPath, {id: documentId}));
  }

  findOwnConsignmentsForDispatchOfficer(searchParams: Partial<SearchParams>,
                                        states: Array<OwnConsignmentStatus>,
                                        dispatchOfficeDistributionNodeIds?: number[],
                                        consignmentTypes?: Nullable<ConsignmentType[]>): Observable<Page<GenericOwnConsignment>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    if (dispatchOfficeDistributionNodeIds?.length) searchParams.filter!.push({fieldName: 'dispatchOfficeDistributionNodeId', value: dispatchOfficeDistributionNodeIds.toString(), operator: FilterOperator.inSet});

    this.processConsignmentTypeFilterOverride(searchParams, consignmentTypes);
    return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForDispatchOfficerPath);
  }

  countOfficeDeskConsignmentsForDispatchOfficer(searchParams: Partial<SearchParams>, states: Array<OwnConsignmentStatus>): Observable<number> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    return this.searchApi<number>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentCountOfficeDeskConsignmentsForDispatchOfficerPath);
  }

  findOfficeDeskConsignmentsForDispatchOfficer(searchParams: Partial<SearchParams>, states: Array<OwnConsignmentStatus>): Observable<Page<GenericOwnConsignment>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindOfficeDeskConsignmentsForDispatchOfficerPath);
  }

  findOfficeDeskConsignmentsForForOwner(searchParams: Partial<SearchParams>, states: Array<OwnConsignmentStatus>): Observable<Page<GenericOwnConsignment>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindOfficeDeskConsignmentsForOwnerPath);
  }

  findOwnConsignmentsForOwnerOrUnit(searchParams: Partial<SearchParams>,
                                    unitView: UnitViewSearchConfig,
                                    states: Array<OwnConsignmentStatus>,
                                    consignmentTypes?: Nullable<ConsignmentType[]>): Observable<Page<GenericOwnConsignment>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    this.processConsignmentTypeFilterOverride(searchParams, consignmentTypes);

    if (unitView.isUnitView) {
      if (!isNil(unitView.orgUnitId)) {
        return this.searchApi<Page<GenericOwnConsignment>>(searchParams,
          applyPathParams(CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForSecretariat_1Path, {orgUnitId: unitView.orgUnitId}));
      } else {
        return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForSecretariatPath);
      }
    } else {
      return this.searchApi<Page<GenericOwnConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForOwnerPath);
    }
  }

  private addConsignmentStatesToSearchParams(states: Array<OwnConsignmentStatus>, searchParams: Partial<SearchParams>) {
    const hasSpecificConsignmentStatusIndex = searchParams.filter!.findIndex(f => f.fieldName === CONSIGNMENT_STATUS_FILTER_OVERRIDE);
    if (hasSpecificConsignmentStatusIndex > -1) {
      searchParams.filter!.splice(hasSpecificConsignmentStatusIndex, 1);
    } else {
      if (states) searchParams.filter!.push({fieldName: 'status', value: String(states), operator: FilterOperator.inSet});
    }
  }

}
