/* eslint-disable @typescript-eslint/quotes */
import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {concatMap, filter, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {
  ApiAuthorizationService,
  ApiEntitySharingService,
  AuthorizedEntityType,
  StorageUnitAuthorizedOperation
} from '|api/core';
import {ApiStorageUnitService} from '|api/document';
import {AbstractObjectDetailService, ObjectDetailLoadType} from './abstract-object-detail.service';
import {HistoryService} from './history.service';
import {ObjectCounts} from '../lib/object-counts';
import {
  GeneralAuthorizationResult,
  getInsufficientPermissionsTooltip,
  isAuthorizedOperationGranted
} from '../components/shared-business-components/permissions/permissions.utils';
import {TabItemWithPriority} from '../components/essentials/tabs/tabs.component.model';
import {DocumentDetailRoute, StorageUnitDetailRoute} from '../enums/documents-routes.enum';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {ApplicationRoute} from '../enums/shared-routes.enum';
import {
  StorageUnitDtoWithAuthorization
} from '../components/shared-business-components/model/dto-with-permissions.interface';
import {TranslateService} from '@ngx-translate/core';
import {ApiElasticsearchService} from '|api/elastic';
import {EntityType, RegistryOfficeTransferIncidentState} from '|api/commons';
import {IncidentDto} from '|api/incident-log';

export enum StorageUnitDetailCountType {
  CONTENT = 'content',
  SHARE = 'share',
  INCIDENTS = 'incidents',
}

@Injectable()
export class StorageUnitDetailService extends AbstractObjectDetailService<StorageUnitDtoWithAuthorization, StorageUnitDetailCountType> {

  private apiEntitySharingService = inject(ApiEntitySharingService);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private apiElasticsearchService = inject(ApiElasticsearchService);
  private historyService = inject(HistoryService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private translateService = inject(TranslateService);

  constructor() {
    super();

    super.initialize();
  }

  override counts = new ObjectCounts<StorageUnitDetailCountType>({
    [StorageUnitDetailCountType.CONTENT]: storageUnitId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiElasticsearchService.elasticsearchCountDocumentsAndFilesInStorageUnit({storageUnitId})),
    ),
    [StorageUnitDetailCountType.SHARE]: storageUnitId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiEntitySharingService.entitySharingCountSharingStatusForStorageUnit({id: storageUnitId})),
    ),
    [StorageUnitDetailCountType.INCIDENTS]: storageUnitId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiElasticsearchService.elasticsearchCountRegistryOfficeTransferIncidentsForEntity({
        entityType: EntityType.STORAGE_UNIT,
        entityId: storageUnitId,
        urlPredicates: {'state': RegistryOfficeTransferIncidentState.IN_PROGRESS} as Partial<Record<keyof IncidentDto, string>>,
      })),
    ),
  });

  override objectAuthorization$: Observable<Nullable<GeneralAuthorizationResult>> = combineLatest([
    this.objectId$,
    this.loadRequested$,
  ]).pipe(
    filter(([_, loadType]) => loadType === ObjectDetailLoadType.INITIAL_LOAD || loadType === ObjectDetailLoadType.RELOAD_PERMISSIONS),
    switchMap(([id, _]) => this.loadingService.doLoading(
      this.apiAuthorizationService.authorizationAuthorizeStorageUnitOperations({
        body: {
          authorizedEntityId: id,
          authorizedEntityType: AuthorizedEntityType.STORAGE_UNIT,
          operationsToAuthorize: [
            StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_PROFILE,
            StorageUnitAuthorizedOperation.STORAGE_UNIT_MODIFY_PROFILE,
            StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_CONTENT,
            StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_HISTORY,
            StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_SHARING,
          ],
        }
      }),
      this
    )),
    shareReplay(1)
  );

  override object$: Observable<Nullable<StorageUnitDtoWithAuthorization>> = combineLatest([
    this.objectId$,
    this.objectAuthorization$,
    this.loadRequested$,
  ]).pipe(
    concatMap(([id, authorization, loadType]) => {
      let storageUnit$: Nullable<Observable<StorageUnitDtoWithAuthorization>>;

      if (loadType === ObjectDetailLoadType.INITIAL_LOAD || loadType === ObjectDetailLoadType.RELOAD_OBJECT) {
        storageUnit$ = this.loadingService.doLoading(
          this.apiStorageUnitService.storageUnitFindById({
            id,
          }).pipe(
            map(storageUnit => ({...storageUnit, authorization})),
          ).pipe(
            tap({
              error: _ => this.historyService.routeBack(createAbsoluteRoute(ApplicationRoute.DOCUMENTS)),
            }),
          ),
          this
        );
      }
      else {
        storageUnit$ = of(this.object);
      }

      return storageUnit$!;
    }),
    shareReplay(1)
  );

  override tabs$: Observable<TabItemWithPriority[]> = combineLatest([
    this.object$,
    this.counts.countsChanged$,
  ]).pipe(
    map(([object, counts]) => {
      const cauUserShowContent = isAuthorizedOperationGranted(object!.authorization, StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_CONTENT);
      const canUserShowHistory = isAuthorizedOperationGranted(object!.authorization, StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_HISTORY);
      const canUserShowSharing = isAuthorizedOperationGranted(object!.authorization, StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_SHARING);

      return [
        {
          id: StorageUnitDetailRoute.PREVIEW,
          label: 'Přehled',
          showCount: false,
        },
        {
          id: StorageUnitDetailRoute.CONTENT,
          showCount: cauUserShowContent,
          label: 'Obsah jednotky',
          count: counts[StorageUnitDetailRoute.CONTENT],
          disabled: !cauUserShowContent,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_CONTENT, this.translateService)?.tooltip
        },
        {
          id: StorageUnitDetailRoute.HISTORY,
          label: 'Historie',
          disabled: !canUserShowHistory,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_HISTORY, this.translateService)?.tooltip
        },
        {
          id: StorageUnitDetailRoute.SHARE,
          showCount: canUserShowSharing,
          label: 'Sdíleno',
          count: counts[StorageUnitDetailRoute.SHARE],
          disabled: !canUserShowSharing,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, StorageUnitAuthorizedOperation.STORAGE_UNIT_SHOW_SHARING, this.translateService)?.tooltip
        },
        {
          id: StorageUnitDetailRoute.INCIDENTS,
          label: 'Chyby při předání',
          isHidden: !Boolean(object!.registryOfficeTransfer?.registryOfficeTransferState),
          icon: counts[DocumentDetailRoute.INCIDENTS] ? 'error' : null,
          showCount: true,
          count: counts[StorageUnitDetailRoute.INCIDENTS],
        },
      ] as TabItemWithPriority[];
    })
  );

}
