import {Directive, forwardRef, inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {GenericValueAccessor} from './generic.value-accessor';
import {FormPopupTableSelectorComponent} from './form-popup-table-selector/form-popup-table-selector.component';


@Directive({
  // the + ',' + are intentional because the commas are hard
  // to read and thus the selector is prone to hard-to-spot mistakes

  selector: 'icz-form-popup-table-selector[formControlName]' + ',',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormPopupTableSelectorValueAccessorDirective),
    multi: true
  }],
})
export class FormPopupTableSelectorValueAccessorDirective extends GenericValueAccessor {

  protected override component = inject(FormPopupTableSelectorComponent, {optional: true})!;

  @Input()
  override formControlName!: string;

}
