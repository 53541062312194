import {inject, Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {BulkPostingFormDto} from '|api/sad';
import {
  BulkPostingFormToastData,
  ConsignmentsToastService,
} from '../../../consignment-dialog/consignments-toast.service';
import {BulkPostingFormDialogComponent} from './bulk-posting-form-dialog.component';
import {IczModalService} from '../../../../../services/icz-modal.service';


export enum BulkPostingFormDialogResultType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  EXPORTED = 'EXPORTED',
}

export interface BulkPostingFormDialogResult {
  resultType: BulkPostingFormDialogResultType;
  name: string;
}

export interface BulkPostingFormDialogData {
  isCreateMode: boolean;
  dispatchOfficeId: Nullable<number>;
  excludedConsignmentIds?: number[];
  bulkPostingForm?: BulkPostingFormDto;
}

@Injectable({
  providedIn: 'root'
})
export class BulkPostingFormDialogService {

  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);

  openBulkPostingFormDialog(data: BulkPostingFormDialogData) {
    return this.modalService.openComponentInModal<Nullable<BulkPostingFormDialogResult>, BulkPostingFormDialogData>({
      component: BulkPostingFormDialogComponent,
      modalOptions: {
        width: 1000,
        height: 800,
        titleTemplate: data.isCreateMode ? 'Průvodce generování PPA' : 'Poštovní podací arch',
        disableAutoMargin: true
      },
      data,
    }).pipe(
      tap(result => {
        if (!result) return;
        const toastData: BulkPostingFormToastData = {
          [InternalNotificationKey.NAME]: result.name,
        };
        if (result.resultType === BulkPostingFormDialogResultType.CREATED) {
          this.consignmentsToastService.dispatchBulkPostingFormCreated(toastData);
        } else if (result.resultType === BulkPostingFormDialogResultType.UPDATED) {
          this.consignmentsToastService.dispatchBulkPostingFormUpdated(toastData);
        } else if (result.resultType === BulkPostingFormDialogResultType.EXPORTED) {
          this.consignmentsToastService.dispatchBulkPostingFormExported(toastData);
        }
      }),
    );
  }

}
