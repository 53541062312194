/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PermissionSetLevel } from '../../models/permission-set-level';
import { StorageUnitPermission } from '../../models/storage-unit-permission';

export interface PermissionGetStorageUnitPermissionsByLevel$Params {
  level: PermissionSetLevel;
}

export function permissionGetStorageUnitPermissionsByLevel(http: HttpClient, rootUrl: string, params: PermissionGetStorageUnitPermissionsByLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StorageUnitPermission>>> {
  const rb = new RequestBuilder(rootUrl, permissionGetStorageUnitPermissionsByLevel.PATH, 'get');
  if (params) {
    rb.path('level', params.level, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<StorageUnitPermission>>;
    })
  );
}

permissionGetStorageUnitPermissionsByLevel.PATH = '/permission/storage-unit/by-level/{level}';