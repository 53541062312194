/* tslint:disable */
/* eslint-disable */

/**
 * Role of a functional position that is defined and used within the ESSL application.
 */
export enum EsslApplicationRole {
  REFERENT = 'REFERENT',
  VEDOUCI = 'VEDOUCI',
  SEKRETARIAT = 'SEKRETARIAT',
  PODATELNA = 'PODATELNA',
  VYPRAVNA = 'VYPRAVNA',
  SPISOVNA = 'SPISOVNA',
  BEZPECNOSTNI_MANAZER = 'BEZPECNOSTNI_MANAZER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  SPRAVCE_SPISOVEHO_PLANU = 'SPRAVCE_SPISOVEHO_PLANU',
  SPRAVCE_JM_REJSTRIKU = 'SPRAVCE_JM_REJSTRIKU',
  POSUZOVATEL_SKARTACE = 'POSUZOVATEL_SKARTACE',
  ADM_TECH_SKARTACE = 'ADM_TECH_SKARTACE'
}
