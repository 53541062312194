<ng-container [formGroup]="singleAttributeForm">
  @if (isFormField) {
    <icz-form-field [label]="label" class="grow" formControlName="value"
      [rightLabel]="previousValue ? 'Původně' : ''"
      [rightLabelTooltip]="rightLabelTooltip"
    >
      @if (isVerified) {
        <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
      }
    </icz-form-field>
  }
  @else if (isAutocomplete) {
    <icz-form-autocomplete [label]="label" [options]="options" class="grow" formControlName="value"
      [rightLabel]="previousValue ? 'Původně' : ''"
      [rightLabelTooltip]="rightLabelTooltip"
    >
      @if (isVerified) {
        <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
      }
    </icz-form-autocomplete>
  }
  @else if (isDatepicker && subjectAttributeType === SubjectAttributeType.BIRTH_DATE) {
    <icz-date-picker [label]="label"
      class="grow" formControlName="value" [selectableDates]="birthDateValidator"
      [rightLabel]="previousValue ? 'Původně' : ''"
      [rightLabelTooltip]="rightLabelTooltip"
    >
      @if (isVerified) {
        <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
      }
    </icz-date-picker>
  }
</ng-container>
