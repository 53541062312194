/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CirculationTaskState } from '|api/commons';
import { UniqueIdentifierDto } from '|api/commons';

export interface CirculationActivityDocumentIssd$Params {

/**
 * Task state
 */
  taskState: CirculationTaskState;
      body: UniqueIdentifierDto
}

export function circulationActivityDocumentIssd(http: HttpClient, rootUrl: string, params: CirculationActivityDocumentIssd$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, circulationActivityDocumentIssd.PATH, 'post');
  if (params) {
    rb.path('taskState', params.taskState, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

circulationActivityDocumentIssd.PATH = '/flow/activity/document-issd/{taskState}';