/* tslint:disable */
/* eslint-disable */

/**
 * Operator for sorting the OwnPaperConsignments inside the BulkPostingForm
 */
export enum BulkPostingFormFormat {
  CSV = 'CSV',
  PDF = 'PDF'
}
