/* tslint:disable */
/* eslint-disable */
export enum FileAuthorizedOperation {
  FILE_SHOW_PROFILE = 'FILE_SHOW_PROFILE',
  FILE_MODIFY_PROFILE = 'FILE_MODIFY_PROFILE',
  FILE_SHOW_DOCUMENTS = 'FILE_SHOW_DOCUMENTS',
  FILE_ADD_DOCUMENT = 'FILE_ADD_DOCUMENT',
  FILE_ADD_ADDITIONAL_DOCUMENT = 'FILE_ADD_ADDITIONAL_DOCUMENT',
  FILE_SHOW_RELATED_OBJECTS = 'FILE_SHOW_RELATED_OBJECTS',
  FILE_ADD_RELATED_OBJECT = 'FILE_ADD_RELATED_OBJECT',
  FILE_REMOVE_RELATED_OBJECT = 'FILE_REMOVE_RELATED_OBJECT',
  FILE_SHOW_SUBJECTS = 'FILE_SHOW_SUBJECTS',
  FILE_ADD_SUBJECT = 'FILE_ADD_SUBJECT',
  FILE_SHOW_SUBJECT = 'FILE_SHOW_SUBJECT',
  FILE_MODIFY_SUBJECT = 'FILE_MODIFY_SUBJECT',
  FILE_REMOVE_SUBJECT = 'FILE_REMOVE_SUBJECT',
  FILE_IDENTIFY_SUBJECT = 'FILE_IDENTIFY_SUBJECT',
  FILE_SHOW_SHARING = 'FILE_SHOW_SHARING',
  FILE_MANAGE_SHARING = 'FILE_MANAGE_SHARING',
  FILE_SHOW_HISTORY = 'FILE_SHOW_HISTORY',
  FILE_SHOW_ACTIVITIES = 'FILE_SHOW_ACTIVITIES',
  FILE_CREATE_HANDOVER_ACTIVITY = 'FILE_CREATE_HANDOVER_ACTIVITY',
  FILE_REVOKE_TASK = 'FILE_REVOKE_TASK',
  FILE_BEGIN_TASK = 'FILE_BEGIN_TASK',
  FILE_REJECT_TASK = 'FILE_REJECT_TASK',
  FILE_DELEGATE_TASK = 'FILE_DELEGATE_TASK',
  FILE_TAKEOVER = 'FILE_TAKEOVER',
  FILE_TAKEOVER_AND_DELEGATE = 'FILE_TAKEOVER_AND_DELEGATE',
  FILE_SETTLE = 'FILE_SETTLE',
  FILE_REVOKE_SETTLEMENT = 'FILE_REVOKE_SETTLEMENT',
  FILE_CLOSE = 'FILE_CLOSE',
  FILE_OPEN_CLOSED = 'FILE_OPEN_CLOSED',
  FILE_DEACTIVATE = 'FILE_DEACTIVATE',
  FILE_REACTIVATE = 'FILE_REACTIVATE',
  FILE_PASS_ON_TO_EXTERNAL_ORGANIZATION = 'FILE_PASS_ON_TO_EXTERNAL_ORGANIZATION'
}
