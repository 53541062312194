/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { documentTypeCheckBeforeUpdate } from '../fn/document-type/document-type-check-before-update';
import { DocumentTypeCheckBeforeUpdate$Params } from '../fn/document-type/document-type-check-before-update';
import { documentTypeCreate } from '../fn/document-type/document-type-create';
import { DocumentTypeCreate$Params } from '../fn/document-type/document-type-create';
import { documentTypeDeleteById } from '../fn/document-type/document-type-delete-by-id';
import { DocumentTypeDeleteById$Params } from '../fn/document-type/document-type-delete-by-id';
import { DocumentTypeDto } from '../models/document-type-dto';
import { documentTypeExportXml } from '../fn/document-type/document-type-export-xml';
import { DocumentTypeExportXml$Params } from '../fn/document-type/document-type-export-xml';
import { documentTypeFindAll } from '../fn/document-type/document-type-find-all';
import { DocumentTypeFindAll$Params } from '../fn/document-type/document-type-find-all';
import { documentTypeFindAllValid } from '../fn/document-type/document-type-find-all-valid';
import { DocumentTypeFindAllValid$Params } from '../fn/document-type/document-type-find-all-valid';
import { documentTypeFindAllValidInTimeByType } from '../fn/document-type/document-type-find-all-valid-in-time-by-type';
import { DocumentTypeFindAllValidInTimeByType$Params } from '../fn/document-type/document-type-find-all-valid-in-time-by-type';
import { documentTypeFindAllValidOrToBeValid } from '../fn/document-type/document-type-find-all-valid-or-to-be-valid';
import { DocumentTypeFindAllValidOrToBeValid$Params } from '../fn/document-type/document-type-find-all-valid-or-to-be-valid';
import { documentTypeFindById } from '../fn/document-type/document-type-find-by-id';
import { DocumentTypeFindById$Params } from '../fn/document-type/document-type-find-by-id';
import { documentTypeImportXml } from '../fn/document-type/document-type-import-xml';
import { DocumentTypeImportXml$Params } from '../fn/document-type/document-type-import-xml';
import { documentTypeSearch } from '../fn/document-type/document-type-search';
import { DocumentTypeSearch$Params } from '../fn/document-type/document-type-search';
import { documentTypeTestCreate } from '../fn/testing-purpose-only/document-type-test-create';
import { DocumentTypeTestCreate$Params } from '../fn/testing-purpose-only/document-type-test-create';
import { documentTypeUpdate } from '../fn/document-type/document-type-update';
import { DocumentTypeUpdate$Params } from '../fn/document-type/document-type-update';
import { EsslErrorCodeExceptionDto } from '|api/commons';
import { PageDocumentTypeDto } from '../models/page-document-type-dto';


/**
 * Controller for Document Types
 */
@Injectable({ providedIn: 'root' })
export class ApiDocumentTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentTypeFindById()` */
  static readonly DocumentTypeFindByIdPath = '/codebook/classifier/document-type/{id}';

  /**
   * Find document type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindById$Response(params: DocumentTypeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentTypeDto>> {
    return documentTypeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find document type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindById(params: DocumentTypeFindById$Params, context?: HttpContext): Observable<DocumentTypeDto> {
    return this.documentTypeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentTypeDto>): DocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `documentTypeUpdate()` */
  static readonly DocumentTypeUpdatePath = '/codebook/classifier/document-type/{id}';

  /**
   * Update existing document type and its children if necessary
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeUpdate$Response(params: DocumentTypeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return documentTypeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing document type and its children if necessary
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeUpdate(params: DocumentTypeUpdate$Params, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.documentTypeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `documentTypeDeleteById()` */
  static readonly DocumentTypeDeleteByIdPath = '/codebook/classifier/document-type/{id}';

  /**
   * Delete document type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeDeleteById$Response(params: DocumentTypeDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentTypeDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete document type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeDeleteById(params: DocumentTypeDeleteById$Params, context?: HttpContext): Observable<void> {
    return this.documentTypeDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentTypeFindAll()` */
  static readonly DocumentTypeFindAllPath = '/codebook/classifier/document-type';

  /**
   * Finds all document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAll$Response(params?: DocumentTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return documentTypeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAll(params?: DocumentTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.documentTypeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `documentTypeCreate()` */
  static readonly DocumentTypeCreatePath = '/codebook/classifier/document-type';

  /**
   * Create new document type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeCreate$Response(params: DocumentTypeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentTypeDto>> {
    return documentTypeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new document type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeCreate(params: DocumentTypeCreate$Params, context?: HttpContext): Observable<DocumentTypeDto> {
    return this.documentTypeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentTypeDto>): DocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `documentTypeTestCreate()` */
  static readonly DocumentTypeTestCreatePath = '/codebook/classifier/document-type/testCreate';

  /**
   * Create new document type, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeTestCreate$Response(params: DocumentTypeTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentTypeDto>> {
    return documentTypeTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new document type, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeTestCreate(params: DocumentTypeTestCreate$Params, context?: HttpContext): Observable<DocumentTypeDto> {
    return this.documentTypeTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentTypeDto>): DocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `documentTypeImportXml()` */
  static readonly DocumentTypeImportXmlPath = '/codebook/classifier/document-type/import';

  /**
   * Create new document types from xml
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeImportXml()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentTypeImportXml$Response(params?: DocumentTypeImportXml$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentTypeImportXml(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new document types from xml
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeImportXml$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  documentTypeImportXml(params?: DocumentTypeImportXml$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.documentTypeImportXml$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentTypeFindAllValidInTimeByType()` */
  static readonly DocumentTypeFindAllValidInTimeByTypePath = '/codebook/classifier/document-type/{nodeType}/valid-on-date/{date}';

  /**
   * Finds all valid Document types at specific date for specific type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeFindAllValidInTimeByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAllValidInTimeByType$Response(params: DocumentTypeFindAllValidInTimeByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return documentTypeFindAllValidInTimeByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all valid Document types at specific date for specific type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeFindAllValidInTimeByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAllValidInTimeByType(params: DocumentTypeFindAllValidInTimeByType$Params, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.documentTypeFindAllValidInTimeByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `documentTypeFindAllValid()` */
  static readonly DocumentTypeFindAllValidPath = '/codebook/classifier/document-type/valid';

  /**
   * Finds all VALID document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeFindAllValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAllValid$Response(params?: DocumentTypeFindAllValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return documentTypeFindAllValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all VALID document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeFindAllValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAllValid(params?: DocumentTypeFindAllValid$Params|null|undefined, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.documentTypeFindAllValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `documentTypeFindAllValidOrToBeValid()` */
  static readonly DocumentTypeFindAllValidOrToBeValidPath = '/codebook/classifier/document-type/valid-or-to-be-valid';

  /**
   * Finds all valid or to-be-valid document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeFindAllValidOrToBeValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAllValidOrToBeValid$Response(params?: DocumentTypeFindAllValidOrToBeValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentTypeDto>>> {
    return documentTypeFindAllValidOrToBeValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all valid or to-be-valid document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeFindAllValidOrToBeValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeFindAllValidOrToBeValid(params?: DocumentTypeFindAllValidOrToBeValid$Params|null|undefined, context?: HttpContext): Observable<Array<DocumentTypeDto>> {
    return this.documentTypeFindAllValidOrToBeValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentTypeDto>>): Array<DocumentTypeDto> => r.body)
    );
  }

  /** Path part for operation `documentTypeSearch()` */
  static readonly DocumentTypeSearchPath = '/codebook/classifier/document-type/search-api';

  /**
   * Finds document types based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeSearch$Response(params?: DocumentTypeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDocumentTypeDto>> {
    return documentTypeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds document types based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeSearch(params?: DocumentTypeSearch$Params|null|undefined, context?: HttpContext): Observable<PageDocumentTypeDto> {
    return this.documentTypeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDocumentTypeDto>): PageDocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `documentTypeExportXml()` */
  static readonly DocumentTypeExportXmlPath = '/codebook/classifier/document-type/export';

  /**
   * Export document types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeExportXml()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeExportXml$Response(params?: DocumentTypeExportXml$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return documentTypeExportXml(this.http, this.rootUrl, params, context);
  }

  /**
   * Export document types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeExportXml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeExportXml(params?: DocumentTypeExportXml$Params|null|undefined, context?: HttpContext): Observable<any> {
    return this.documentTypeExportXml$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `documentTypeCheckBeforeUpdate()` */
  static readonly DocumentTypeCheckBeforeUpdatePath = '/codebook/classifier/document-type/check-before-update/{id}';

  /**
   * Check document type before update if any of the descandens if out of range for new validTo date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeCheckBeforeUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeCheckBeforeUpdate$Response(params: DocumentTypeCheckBeforeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslErrorCodeExceptionDto>> {
    return documentTypeCheckBeforeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Check document type before update if any of the descandens if out of range for new validTo date.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeCheckBeforeUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentTypeCheckBeforeUpdate(params: DocumentTypeCheckBeforeUpdate$Params, context?: HttpContext): Observable<EsslErrorCodeExceptionDto> {
    return this.documentTypeCheckBeforeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslErrorCodeExceptionDto>): EsslErrorCodeExceptionDto => r.body)
    );
  }

}
