/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EnvelopeValidateResponseDto } from '../models/envelope-validate-response-dto';
import { PageSheetLabelTemplateDto } from '../models/page-sheet-label-template-dto';
import { SheetLabelTemplateDto } from '../models/sheet-label-template-dto';
import { sheetLabelTemplatesCheckUpdatePermisssion } from '../fn/sheet-label-templates/sheet-label-templates-check-update-permisssion';
import { SheetLabelTemplatesCheckUpdatePermisssion$Params } from '../fn/sheet-label-templates/sheet-label-templates-check-update-permisssion';
import { sheetLabelTemplatesCreate } from '../fn/sheet-label-templates/sheet-label-templates-create';
import { SheetLabelTemplatesCreate$Params } from '../fn/sheet-label-templates/sheet-label-templates-create';
import { sheetLabelTemplatesDelete } from '../fn/sheet-label-templates/sheet-label-templates-delete';
import { SheetLabelTemplatesDelete$Params } from '../fn/sheet-label-templates/sheet-label-templates-delete';
import { sheetLabelTemplatesFindAll } from '../fn/sheet-label-templates/sheet-label-templates-find-all';
import { SheetLabelTemplatesFindAll$Params } from '../fn/sheet-label-templates/sheet-label-templates-find-all';
import { sheetLabelTemplatesFindById } from '../fn/sheet-label-templates/sheet-label-templates-find-by-id';
import { SheetLabelTemplatesFindById$Params } from '../fn/sheet-label-templates/sheet-label-templates-find-by-id';
import { sheetLabelTemplatesFindSheetLabelTemplates } from '../fn/sheet-label-templates/sheet-label-templates-find-sheet-label-templates';
import { SheetLabelTemplatesFindSheetLabelTemplates$Params } from '../fn/sheet-label-templates/sheet-label-templates-find-sheet-label-templates';
import { sheetLabelTemplatesGetTemplatePreview } from '../fn/sheet-label-templates/sheet-label-templates-get-template-preview';
import { SheetLabelTemplatesGetTemplatePreview$Params } from '../fn/sheet-label-templates/sheet-label-templates-get-template-preview';
import { sheetLabelTemplatesUpdate } from '../fn/sheet-label-templates/sheet-label-templates-update';
import { SheetLabelTemplatesUpdate$Params } from '../fn/sheet-label-templates/sheet-label-templates-update';


/**
 * Contains endpoints related to Sheet Label Templates
 */
@Injectable({ providedIn: 'root' })
export class ApiSheetLabelTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sheetLabelTemplatesFindById()` */
  static readonly SheetLabelTemplatesFindByIdPath = '/sad/sheet-label-templates/{id}';

  /**
   * sheet Label Template Get.
   *
   * Gets sheet label template by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesFindById$Response(params: SheetLabelTemplatesFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SheetLabelTemplateDto>> {
    return sheetLabelTemplatesFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * sheet Label Template Get.
   *
   * Gets sheet label template by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesFindById(params: SheetLabelTemplatesFindById$Params, context?: HttpContext): Observable<SheetLabelTemplateDto> {
    return this.sheetLabelTemplatesFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetLabelTemplateDto>): SheetLabelTemplateDto => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesUpdate()` */
  static readonly SheetLabelTemplatesUpdatePath = '/sad/sheet-label-templates/{id}';

  /**
   * Sheet Label Template Update.
   *
   * Updates Sheet Label Template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesUpdate$Response(params: SheetLabelTemplatesUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SheetLabelTemplateDto>> {
    return sheetLabelTemplatesUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Sheet Label Template Update.
   *
   * Updates Sheet Label Template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesUpdate(params: SheetLabelTemplatesUpdate$Params, context?: HttpContext): Observable<SheetLabelTemplateDto> {
    return this.sheetLabelTemplatesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetLabelTemplateDto>): SheetLabelTemplateDto => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesDelete()` */
  static readonly SheetLabelTemplatesDeletePath = '/sad/sheet-label-templates/{id}';

  /**
   * Sheet Label Template Deletion.
   *
   * Deletes of eheet label template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesDelete$Response(params: SheetLabelTemplatesDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sheetLabelTemplatesDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Sheet Label Template Deletion.
   *
   * Deletes of eheet label template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesDelete(params: SheetLabelTemplatesDelete$Params, context?: HttpContext): Observable<void> {
    return this.sheetLabelTemplatesDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesGetTemplatePreview()` */
  static readonly SheetLabelTemplatesGetTemplatePreviewPath = '/sad/sheet-label-templates/template-preview/{format}';

  /**
   * Template Preview.
   *
   * Gets sheet label preview based on template definition.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesGetTemplatePreview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesGetTemplatePreview$Response(params: SheetLabelTemplatesGetTemplatePreview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return sheetLabelTemplatesGetTemplatePreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Template Preview.
   *
   * Gets sheet label preview based on template definition.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesGetTemplatePreview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesGetTemplatePreview(params: SheetLabelTemplatesGetTemplatePreview$Params, context?: HttpContext): Observable<Array<string>> {
    return this.sheetLabelTemplatesGetTemplatePreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesCheckUpdatePermisssion()` */
  static readonly SheetLabelTemplatesCheckUpdatePermisssionPath = '/sad/sheet-label-templates/check-update-permisssion';

  /**
   * Sheet label Template Validate Update Permission.
   *
   * Checks if logged fp i permitted to update action.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesCheckUpdatePermisssion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesCheckUpdatePermisssion$Response(params: SheetLabelTemplatesCheckUpdatePermisssion$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnvelopeValidateResponseDto>>> {
    return sheetLabelTemplatesCheckUpdatePermisssion(this.http, this.rootUrl, params, context);
  }

  /**
   * Sheet label Template Validate Update Permission.
   *
   * Checks if logged fp i permitted to update action.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesCheckUpdatePermisssion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesCheckUpdatePermisssion(params: SheetLabelTemplatesCheckUpdatePermisssion$Params, context?: HttpContext): Observable<Array<EnvelopeValidateResponseDto>> {
    return this.sheetLabelTemplatesCheckUpdatePermisssion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnvelopeValidateResponseDto>>): Array<EnvelopeValidateResponseDto> => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesFindAll()` */
  static readonly SheetLabelTemplatesFindAllPath = '/sad/sheet-label-templates/';

  /**
   * Finds all sheet label templates.
   *
   * Returns all sheet label templates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesFindAll$Response(params?: SheetLabelTemplatesFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SheetLabelTemplateDto>>> {
    return sheetLabelTemplatesFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all sheet label templates.
   *
   * Returns all sheet label templates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesFindAll(params?: SheetLabelTemplatesFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<SheetLabelTemplateDto>> {
    return this.sheetLabelTemplatesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SheetLabelTemplateDto>>): Array<SheetLabelTemplateDto> => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesCreate()` */
  static readonly SheetLabelTemplatesCreatePath = '/sad/sheet-label-templates/';

  /**
   * Sheet Label Template Create.
   *
   * Creates a new shet label template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesCreate$Response(params: SheetLabelTemplatesCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SheetLabelTemplateDto>> {
    return sheetLabelTemplatesCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Sheet Label Template Create.
   *
   * Creates a new shet label template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetLabelTemplatesCreate(params: SheetLabelTemplatesCreate$Params, context?: HttpContext): Observable<SheetLabelTemplateDto> {
    return this.sheetLabelTemplatesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetLabelTemplateDto>): SheetLabelTemplateDto => r.body)
    );
  }

  /** Path part for operation `sheetLabelTemplatesFindSheetLabelTemplates()` */
  static readonly SheetLabelTemplatesFindSheetLabelTemplatesPath = '/sad/sheet-label-templates/search-api';

  /**
   * Finds sheet label templates.
   *
   * Returns sheet label templates for the passed search predicate.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetLabelTemplatesFindSheetLabelTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesFindSheetLabelTemplates$Response(params?: SheetLabelTemplatesFindSheetLabelTemplates$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSheetLabelTemplateDto>> {
    return sheetLabelTemplatesFindSheetLabelTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds sheet label templates.
   *
   * Returns sheet label templates for the passed search predicate.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetLabelTemplatesFindSheetLabelTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetLabelTemplatesFindSheetLabelTemplates(params?: SheetLabelTemplatesFindSheetLabelTemplates$Params|null|undefined, context?: HttpContext): Observable<PageSheetLabelTemplateDto> {
    return this.sheetLabelTemplatesFindSheetLabelTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSheetLabelTemplateDto>): PageSheetLabelTemplateDto => r.body)
    );
  }

}
