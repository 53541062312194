import {inject, Pipe, PipeTransform} from '@angular/core';
import {
  AddressCompleteDto,
  AddressFormat, GenericLineAddressFormDto,
  isCzechAddress,
  isGenericLineAddress,
  isPostalBoxAddress,
  isSlovakAddress,
} from '../shared-business-components/model/addresses.model';
import {CodebookService} from '../../core/services/codebook.service';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CzechAddressDto, GenericLineAddressDto, HouseNumberType, PostalBoxAddressDto} from '|api/commons';

export function formatCzechAddress(czechAddress: CzechAddressDto, includeCountry?: boolean) {
  const street = czechAddress.street ?? '';
  const houseNumber = czechAddress.houseNumber ? (czechAddress.houseNumberType === HouseNumberType.STREET_NUMBER ? czechAddress.houseNumber : `ev. č. ${czechAddress.houseNumber}`) : '';
  const orientationNumber = czechAddress.orientationNumber ? `/${czechAddress.orientationNumber}` : '';
  const orientationNumberLastCharacter = czechAddress.orientationNumber && czechAddress.orientationNumberLastCharacter ? czechAddress.orientationNumberLastCharacter : '';
  const city = czechAddress.city ? czechAddress.city + (czechAddress.district || czechAddress.pragueDistrict ? ` - ${czechAddress.district ?? czechAddress.pragueDistrict}` : '') : '';
  const postalCode = czechAddress.city && czechAddress.postalCode ? `, ${czechAddress.postalCode} ` : '';
  const country = includeCountry ? `, ${czechAddress.country}` : '';

  if (street) {
    return `${street} ${houseNumber}${orientationNumber}${orientationNumberLastCharacter}${postalCode}${city}${country}`;
  }
  else {
    return `${city} ${houseNumber}${orientationNumber}${orientationNumberLastCharacter}${postalCode}${city}${country}`;
  }
}

@Pipe({
  name: 'address',
  pure: true,
})
export class AddressPipe implements PipeTransform {

  private codebookService = inject(CodebookService);

  transform(address: Nullable<AddressCompleteDto>,
            includeCountry = false
  ): Observable<string> {
    if (!address || !address._Class) return of('');

    let out = '';
    if (isCzechAddress(address._Class as AddressFormat) || isSlovakAddress(address._Class as AddressFormat)) {
      return of(formatCzechAddress(address as CzechAddressDto));
    }
    else if (isPostalBoxAddress(address._Class as AddressFormat)) {
      const poBox = address as PostalBoxAddressDto;

      const box = poBox.box ?? '';
      const postOffice = poBox.postOffice ? `(${poBox.postOffice})` : '';
      const postalCode = poBox.postalCode ? `(${poBox.postalCode})` : '';
      const country = includeCountry ? `, ${poBox.country}` : '';

      out = `${box} ${postalCode} ${postOffice} ${country}`;
      return of(out);
    }
    else if (isGenericLineAddress(address._Class as AddressFormat)) {
      const genericAddress = address as GenericLineAddressDto;
      if (genericAddress.addressLines) {
        if (genericAddress.addressLines?.[0].line) {
          out = (genericAddress as GenericLineAddressFormDto)!.addressLines!.map(l => l.line).join(', ');
        } else {
          out = genericAddress.addressLines.join(', ');
        }
      }
      return this.codebookService.countries().pipe(map(
        countries => {
          const country =  countries.find(c => c.alpha3Code === genericAddress.country);
          out += country ? `, ${country.englishShortName}` : '';
          return out;
        }
      ));
    } else {
      return of('');
    }
  }
}
