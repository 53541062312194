<form class="grow" [formGroup]="form">
  @if (subject) {
    <div class="h-full">
      <div class="row justify-between p-12 edit-subject-header"
        cdkDrag cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
        cdkDragBoundary=".cdk-overlay-container">
        <div class="row items-center p-4">
          <icz-subject-header-info [subject]="subject" [showIdentifiedAsTag]="true"></icz-subject-header-info>
          @if (isSubjectRepresenting) {
            <icz-tag color="green" label="Reprezentující"></icz-tag>
          }
        </div>
        <div class="row items-center edit-subject-toolbar">
          <icz-subjects-toolbar
            class="cursor-pointer"
            [entityId$]="documentDetailService ? documentDetailService.objectId$ : null$"
            [selectedRows]="[subject]"
            [context]="subjectToolbarContext"
            (operationCompleted)="toolbarOperationCompleted($event)"
            [nonpersistentSubjectMode]="isIsdsViewOnly || isEditNonpersistentMode"
          ></icz-subjects-toolbar>
          <icz-button svgIcon="close" (onAction)="cancel()" background></icz-button>
        </div>
      </div>

      @if (showTabs) {
        <icz-tabs [tabs]="tabs" (tabClick)="tabClicked($event)" [shouldNavigate]="false" class="tabs">
          <div extraContent>
            <div class="col edit-subject-tabs-extra-content">
              <icz-subject-identification-status-icon [subject]="subject" [showExtraVerificationInfo]="true"></icz-subject-identification-status-icon>
            </div>
          </div>
        </icz-tabs>
      }
      @if (activeTab === EditSubjectDialogTab.OVERVIEW) {
        <icz-subject-create-form
          [mode]="ComponentMode.UPDATE"
          [subject]="subject"
          [isReadonly]="isReadOnly || isIsdsViewOnly"
          [nonpersistentSubjectMode]="isNonpersistentSubjectMode"
          [form]="form"
          #subjectCreateFormComponent
          [waiting]="loadingIndicatorService.isLoading$(this) | async"
          (blockingAction)="submitBlocked = $event"
          (disposeSubject)="disposeSubject()"
          (technicalClassificationChange)="classificationChangeWithReferenceUpdate = $event"
        ></icz-subject-create-form>
      }
      @if (activeTab === EditSubjectDialogTab.USAGE_REPORT) {
        @if (activeTab === EditSubjectDialogTab.USAGE_REPORT) {
          <icz-subject-usage-report
            [subjectId]="subject.id!"
          ></icz-subject-usage-report>
        }
      }
      @if (activeTab === EditSubjectDialogTab.HISTORY) {
        <icz-transactions type="entity"
          [esslObjectType]="EsslObjectType.SUBJECT"
          [entityId]="subject.id!"
        ></icz-transactions>
      }
    </div>
  }
</form>

@if (isIsdsViewOnly || isReadOnly) {
  <icz-form-buttons>
    <icz-button rightButtons primary label="Zavřít" (onAction)="cancel()"></icz-button>
  </icz-form-buttons>
} @else {
  <icz-form-buttons>
    @if (isIszrView) {
      <icz-button iczFormSubmit leftButtons primary label="Přidat subjekt" (onAction)="closeWithIszrSubject()" [disabled]=submitBlocked></icz-button>
    } @else {
      <div class="row items-center" leftButtons>
        <icz-button iczFormSubmit primary label="Uložit" (onAction)="update()" [disabled]=submitBlocked></icz-button>
        @if (!isNonpersistentSubjectMode) {
          <icz-checkbox
            [value]="$any(!valueCorrectionMode)" (valueChange)="valueCorrectionMode = !valueCorrectionMode"
            label="Uchovat původní hodnotu pro pozdější vyhledávání" class="ml-16"
          ></icz-checkbox>
        }
      </div>
    }
    <icz-button rightButtons (onAction)="cancel()" label="Storno"></icz-button>

  </icz-form-buttons>
}
