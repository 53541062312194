import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {DeliveryTypeDto, DistributionClassDto} from '|api/codebook';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {ReceivedDocumentUsage} from '../../../services/received-consignments.model';
import {Option} from '../../../model';

@Component({
  selector: 'icz-received-document-with-handover-form',
  templateUrl: './received-document-with-handover-form.component.html',
  styleUrls: ['./received-document-with-handover-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedDocumentWithHandoverFormComponent implements IczOnChanges {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected changeDetectorRef = inject(ChangeDetectorRef);

  get receivedDocumentFormGroup() {
    return this.form?.get('receivedDocumentWithComponentsCreateBaseDto') as IczFormGroup;
  }
  get ownerFunctionalPositionIdControl() {
    return this.form?.get('ownerFunctionalPositionId');
  }
  get ownerOrganizationalUnitIdControl() {
    return this.form?.get('ownerOrganizationalUnitId');
  }

  get isInternalConsignment() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_INTERNAL_CONSIGNMENT;
  }

  @Input({required: true}) form!: IczFormGroup;
  @Input({required: true}) distributionClass: Nullable<DistributionClassDto>;
  @Input() resetForm!: Nullable<EventEmitter<void>>;
  @Input() deliveryType: Nullable<DeliveryTypeDto>;
  @Input() receivedDocumentUsage = ReceivedDocumentUsage.CREATE_FILING_OFFICE_PAPER_CONSIGNMENT;
  @Input() canAddAnalogComponentsByOpenedState: Nullable<boolean>;
  @Input() disableAssignRegNr = false;
  @Output() canAddDigitalComponentsByDocumentForm = new EventEmitter<boolean>();

  ownerChangedWithOriginIds(v: Nullable<Option[] | Option>) {
    if (!v || Array.isArray(v)) return; //change condition if owner becomes multiselect
    if (v!.originId) {
      if (v.originId === 'fp') {
        this.ownerFunctionalPositionIdControl!.setValue(v.value);
        this.ownerOrganizationalUnitIdControl!.setValue(null);
      } else if (v.originId === 'ou') {
        this.ownerFunctionalPositionIdControl!.setValue(null);
        this.ownerOrganizationalUnitIdControl!.setValue(v.value);
      }
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.distributionClass && changes.distributionClass.currentValue) {
      this.changeDetectorRef.detectChanges();
    }
  }

}
