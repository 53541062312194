import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {OwnConsignmentStatus, SubjectRecordDto} from '|api/commons';
import {OwnDigitalConsignmentDto, OwnInternalDigitalConsignmentDto} from '|api/sad';
import {ConsignmentDeliveryFilingType} from '../consignment.model';
import {AddSubjectEmailDialogComponent} from './add-subject-email-dialog/add-subject-email-dialog.component';
import {AbstractConsignmentSpecificationComponent} from '../abstract-consignment-specification.component';
import {OwnEmailConsignmentForm} from '../consignment-dialog.form';
import {Option} from '../../../../../model';
import {IczModalService} from '../../../../../services/icz-modal.service';

import {SubjectAttributeType} from '../../../model/subject-attribute-type';

@Component({
  selector: 'icz-email-consignment-specification',
  templateUrl: './email-consignment-specification.component.html',
  styleUrls: ['./email-consignment-specification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailConsignmentSpecificationComponent extends AbstractConsignmentSpecificationComponent implements OnInit {

  private modalService = inject(IczModalService);

  @Input({required: true})
  override specificFieldsForm!: OwnEmailConsignmentForm;
  @Input({required: true})
  consignment!: Nullable<OwnDigitalConsignmentDto | OwnInternalDigitalConsignmentDto>;
  @Input({required: true})
  deliveryFilingType: Nullable<ConsignmentDeliveryFilingType>;
  @Input()
  additionalEmailAddresses: string[] = [];
  @Output()
  additionalEmailAddressesChange = new EventEmitter<string[]>();

  emailAddressOptions: Option<string>[] = [];

  distributionNodesLoading = false;

  get emailAddressControl() {
    return this.specificFieldsForm.get('consigneeEmail')!;
  }

  readonly OwnConsignmentStatus = OwnConsignmentStatus;
  readonly ConsignmentDeliveryFillingType = ConsignmentDeliveryFilingType;

  ngOnInit(): void {
    this.getEmailAddressOptions();

    if (this.emailAddressOptions.length === 1) {
      this.emailAddressControl.setValue(this.emailAddressOptions[0].value);
    }
  }

  addSubjectEmailAddress() {
    this.modalService.openComponentInModal<string, SubjectRecordDto>({
      component: AddSubjectEmailDialogComponent,
      modalOptions: {
        width: 400,
        height: 450,
        titleTemplate: 'Přidat e-mailovou adresu',
      },
      data: this.consignee,
    }).subscribe(result => {
      if (result) {
        this.additionalEmailAddresses.push(result);
        this.getEmailAddressOptions();
        this.emailAddressControl.setValue(result);
        this.additionalEmailAddressesChange.emit(this.additionalEmailAddresses);
      }
    });
  }

  private getEmailAddressOptions() {
    const attributes = this.consignee?.attributes ?? {};
    const emailsFromSubject = attributes[SubjectAttributeType.EMAIL] ? attributes[SubjectAttributeType.EMAIL]!
      .map(a => ({
        value: a.value,
        label: a.value,
      })).filter(o => Boolean(o.value)) : [];
    const additionalEmails = this.additionalEmailAddresses.map(a => ({
      value: a,
      label: a,
    }));

    this.emailAddressOptions = [
      ...emailsFromSubject as Option<string>[],
      ...additionalEmails as Option<string>[],
    ];
  }

}
