/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CzechAddressDto } from '|api/commons';
import { ruianAddressesGetRuianLoadingProcessStatus } from '../fn/ruian-addresses/ruian-addresses-get-ruian-loading-process-status';
import { RuianAddressesGetRuianLoadingProcessStatus$Params } from '../fn/ruian-addresses/ruian-addresses-get-ruian-loading-process-status';
import { ruianAddressesIdentifyAddress } from '../fn/ruian-addresses/ruian-addresses-identify-address';
import { RuianAddressesIdentifyAddress$Params } from '../fn/ruian-addresses/ruian-addresses-identify-address';
import { ruianAddressesPerformLoad } from '../fn/ruian-addresses/ruian-addresses-perform-load';
import { RuianAddressesPerformLoad$Params } from '../fn/ruian-addresses/ruian-addresses-perform-load';
import { ruianAddressesRuianFullLoad } from '../fn/ruian-addresses/ruian-addresses-ruian-full-load';
import { RuianAddressesRuianFullLoad$Params } from '../fn/ruian-addresses/ruian-addresses-ruian-full-load';
import { ruianAddressesSearchAddresses } from '../fn/ruian-addresses/ruian-addresses-search-addresses';
import { RuianAddressesSearchAddresses$Params } from '../fn/ruian-addresses/ruian-addresses-search-addresses';
import { RuianLoadingStatusDto } from '../models/ruian-loading-status-dto';


/**
 * API for identification requests related to Rex2 component
 */
@Injectable({ providedIn: 'root' })
export class ApiRuianAddressesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ruianAddressesSearchAddresses()` */
  static readonly RuianAddressesSearchAddressesPath = '/elastic/ruian/search';

  /**
   * Fulltext search RUIAN addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruianAddressesSearchAddresses()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ruianAddressesSearchAddresses$Response(params: RuianAddressesSearchAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CzechAddressDto>>> {
    return ruianAddressesSearchAddresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Fulltext search RUIAN addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ruianAddressesSearchAddresses$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ruianAddressesSearchAddresses(params: RuianAddressesSearchAddresses$Params, context?: HttpContext): Observable<Array<CzechAddressDto>> {
    return this.ruianAddressesSearchAddresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CzechAddressDto>>): Array<CzechAddressDto> => r.body)
    );
  }

  /** Path part for operation `ruianAddressesPerformLoad()` */
  static readonly RuianAddressesPerformLoadPath = '/elastic/ruian/perform-load';

  /**
   * Execute RUIAN address loading process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruianAddressesPerformLoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruianAddressesPerformLoad$Response(params?: RuianAddressesPerformLoad$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ruianAddressesPerformLoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Execute RUIAN address loading process
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ruianAddressesPerformLoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruianAddressesPerformLoad(params?: RuianAddressesPerformLoad$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.ruianAddressesPerformLoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ruianAddressesIdentifyAddress()` */
  static readonly RuianAddressesIdentifyAddressPath = '/elastic/ruian/address';

  /**
   * Search RUIAN address by provided structured parameters
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruianAddressesIdentifyAddress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ruianAddressesIdentifyAddress$Response(params: RuianAddressesIdentifyAddress$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CzechAddressDto>>> {
    return ruianAddressesIdentifyAddress(this.http, this.rootUrl, params, context);
  }

  /**
   * Search RUIAN address by provided structured parameters
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ruianAddressesIdentifyAddress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ruianAddressesIdentifyAddress(params: RuianAddressesIdentifyAddress$Params, context?: HttpContext): Observable<Array<CzechAddressDto>> {
    return this.ruianAddressesIdentifyAddress$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CzechAddressDto>>): Array<CzechAddressDto> => r.body)
    );
  }

  /** Path part for operation `ruianAddressesGetRuianLoadingProcessStatus()` */
  static readonly RuianAddressesGetRuianLoadingProcessStatusPath = '/elastic/ruian/status';

  /**
   * Get the status of the RUIAN loading process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruianAddressesGetRuianLoadingProcessStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruianAddressesGetRuianLoadingProcessStatus$Response(params?: RuianAddressesGetRuianLoadingProcessStatus$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<RuianLoadingStatusDto>> {
    return ruianAddressesGetRuianLoadingProcessStatus(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the status of the RUIAN loading process
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ruianAddressesGetRuianLoadingProcessStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruianAddressesGetRuianLoadingProcessStatus(params?: RuianAddressesGetRuianLoadingProcessStatus$Params|null|undefined, context?: HttpContext): Observable<RuianLoadingStatusDto> {
    return this.ruianAddressesGetRuianLoadingProcessStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<RuianLoadingStatusDto>): RuianLoadingStatusDto => r.body)
    );
  }

  /** Path part for operation `ruianAddressesRuianFullLoad()` */
  static readonly RuianAddressesRuianFullLoadPath = '/elastic/ruian/full-load/{dropIndex}';

  /**
   * Execute RUIAN address initial loading process
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ruianAddressesRuianFullLoad()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruianAddressesRuianFullLoad$Response(params: RuianAddressesRuianFullLoad$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ruianAddressesRuianFullLoad(this.http, this.rootUrl, params, context);
  }

  /**
   * Execute RUIAN address initial loading process
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ruianAddressesRuianFullLoad$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ruianAddressesRuianFullLoad(params: RuianAddressesRuianFullLoad$Params, context?: HttpContext): Observable<void> {
    return this.ruianAddressesRuianFullLoad$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
