<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-section label="Řešení chyby">
    <icz-form-field
      formControlName="solutionDescription"
      label="Provedená náprava"
      [autoSizeMax]="5"
    ></icz-form-field>
  </icz-section>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Označit jako vyřešené"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
