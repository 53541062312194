import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {delay, switchMap} from 'rxjs/operators';
import {EsslComponentAvailability} from '|api/commons';
import {DigitalComponentDto} from '|api/component';
import {DocumentDto} from '|api/document';
import {ApiCirculationTaskService} from '|api/flow';
import {
  DigitalComponentOperationResult,
  DigitalComponentOperationType,
  DigitalComponentView,
  EsslComponentToolbarButtonsService
} from '../../services/essl-component-toolbar-buttons.service';
import {TaskToolbarButtonsService} from '../../../document-toolbar/services/task-toolbar-buttons.service';
import {IczSimpleChanges} from '../../../../../utils/icz-on-changes';
import {EsslComponentDto} from '../../../../../services/essl-component-search.service';
import {Button} from '../../../../essentials/button-collection/button-collection.component';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {ToolbarDataService} from '../../../../table/table-toolbar/toolbar-data.service';
import {LocalBinaryFileDownloadService} from '../../../../../services/local-binary-file-download.service';
import {getLatestDigitalComponentVersion} from '../../../shared-document.utils';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';
import {ELASTIC_RELOAD_DELAY} from '../../../document-toolbar/services/toolbar-common.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {InternalNotificationMessageCode} from '../../../../../core/services/notifications/internal-notification.enum';
import {WebSocketNotificationsService} from '../../../../notifications/web-socket-notifications.service';
import {AbstractToolbarButtonsComponent} from '../../../../essentials/abstract-toolbar-buttons.component';
import {DeliveryTypeDto} from '|api/codebook';
import {CodebookService} from '../../../../../core/services/codebook.service';


@Component({
  selector: 'icz-essl-components-toolbar-buttons',
  templateUrl: './essl-components-toolbar-buttons.component.html',
  styleUrls: ['./essl-components-toolbar-buttons.component.scss'],
  providers: [
    EsslComponentToolbarButtonsService,
    TaskToolbarButtonsService,
  ],
})
export class EsslComponentsToolbarButtonsComponent extends AbstractToolbarButtonsComponent<EsslComponentDto, DigitalComponentView, DigitalComponentOperationResult> implements OnInit {

  private loadingService = inject(LoadingIndicatorService);
  private toolbarDataService = inject(ToolbarDataService);
  private digitalComponentToolbarButtonsService = inject(EsslComponentToolbarButtonsService);
  private taskToolbarButtonsService = inject(TaskToolbarButtonsService);
  private apiCirculationTaskService = inject(ApiCirculationTaskService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private destroyRef = inject(DestroyRef);
  private codebookService = inject(CodebookService);

  @Input()
  digitalComponentVersionId!: number;
  @Input()
  digitalComponentVersionDownloadUrl!: string;
  @Input()
  digitalComponentVersionDownloadFilename: Nullable<string>;
  @Input()
  disableAllButtons = false;
  @Input({required: true})
  parentDocument!: DocumentDto;
  @Input()
  deliveryType: Nullable<DeliveryTypeDto>;

  componentCirculationData: Nullable<ExtendedCirculationTaskDto>;

  get isToolbarLoading() {
    return this.loadingService.isLoading(this) || this.loadingService.isLoading(this.taskToolbarButtonsService);
  }

  ngOnInit() {
    this.digitalComponentToolbarButtonsService.actionCompleted$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(result => {
      if (result.operationType === DigitalComponentOperationType.DOWNLOAD) {
        this.downloadDigitalComponentVersion();
      }

      if (this.view === DigitalComponentView.DIGITAL_COMPONENT_VIEWER) {
        if (result.operationType === DigitalComponentOperationType.COMPONENT_LOCKED) {
          this.selectedRows![0]!.availability = EsslComponentAvailability.LOCKED;
          this.generateToolbarContents();
        }
        else if (result.operationType === DigitalComponentOperationType.COMPONENT_UNLOCKED) {
          this.selectedRows![0]!.availability = EsslComponentAvailability.AVAILABLE;
          this.generateToolbarContents();
        }
        else if (result.operationType === DigitalComponentOperationType.COMPONENT_CHECKED_IN) {
          this.selectedRows![0]!.availability = EsslComponentAvailability.AVAILABLE;
          this.generateToolbarContents();
        }
      }

      this.operationCompleted.emit(result);
    });

    this.taskToolbarButtonsService.actionCompleted$.pipe(
      delay(ELASTIC_RELOAD_DELAY),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.operationCompleted.emit({
        operationType: DigitalComponentOperationType.CIRCULATION_ACTION_COMPLETED,
        selectedDigitalComponents: null,
        addedComponentCount: null,
        removedComponentCount: null,
      });
    });

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.generateToolbarContents();
      }
    );
  }

  override ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.view || changes.digitalComponentVersionId || changes.selectedRows || changes.parentDocument || changes.deliveryType) {
      this.generateToolbarContents();
    }
  }

  protected generateToolbarContents() {
    let buttons$: Observable<Button[]>;

    if (this.selectedRows!.length === 0 || this.selectedRows!.length === 1) {
      const selectedComponent = this.selectedRows![0] as unknown as Nullable<DigitalComponentDto>;
      const currentUserCirculationTaskId = selectedComponent?.currentUserCirculationTaskId;

      if (this.selectedRows!.length === 1 && currentUserCirculationTaskId) {
        buttons$ = this.loadingService.doLoading(
          this.apiCirculationTaskService.circulationTaskResolveAvailableActions({
            body: [currentUserCirculationTaskId],
          }),
          this
        ).pipe(
          switchMap(availableActions => {
            if (availableActions[0] && availableActions[0].taskActions!.length) {
              return forkJoin([
                this.apiCirculationTaskService.circulationTaskFindById({
                  id: currentUserCirculationTaskId,
                }),
                of(availableActions[0].taskActions),
              ]);
            }
            else {
              return of([null, []]);
            }
          }),
          switchMap(([task, availableActions]) => {
            if (task && availableActions?.length) {
              this.componentCirculationData = (task as unknown as ExtendedCirculationTaskDto);

              return this.taskToolbarButtonsService.getToolbarButtons(
                [
                  {
                    ...(task as unknown as ExtendedCirculationTaskDto),
                    componentLabel: getLatestDigitalComponentVersion(selectedComponent as Nullable<DigitalComponentDto>)?.fileName ?? selectedComponent?.label ?? '',
                  }
                ],
                {availableTaskActions: [{
                    taskId: currentUserCirculationTaskId,
                    availableActions,
                    circulationTask: this.componentCirculationData
                  }]}
              );
            }
            else {
              this.componentCirculationData = null;

              return of([]);
            }
          }),
        );
      }
      else {
        this.componentCirculationData = null;
        buttons$ = this.codebookService.businessRulesGetAllowedFormatsAnonymiztion().pipe(switchMap(
          allowedFormats => {
            return this.digitalComponentToolbarButtonsService.getToolbarButtons(
              this.selectedRows!,
              {
                view: this.view,
                digitalComponentVersionId: this.digitalComponentVersionId!,
                parentDocument: this.parentDocument,
                deliveryType: this.deliveryType!,
                anonymizationAllowedFormats: allowedFormats
              }
            );
          }
        ));
      }
    }
    else {
      this.componentCirculationData = null;

      buttons$ = this.codebookService.businessRulesGetAllowedFormatsAnonymiztion().pipe(switchMap(
        allowedFormats => {
          return this.digitalComponentToolbarButtonsService.getToolbarButtons(
            this.selectedRows!,
            {
              view: this.view,
              digitalComponentVersionId: this.digitalComponentVersionId!,
              parentDocument: this.parentDocument,
              deliveryType: this.deliveryType!,
              anonymizationAllowedFormats: allowedFormats
            }
          );
        }
      ));
    }


    this.loadingService.doLoading(buttons$, this).subscribe(buttons => {
        this.toolbarButtons = buttons;
      }
    );
  }

  private downloadDigitalComponentVersion() {
    if (this.digitalComponentVersionDownloadUrl && this.digitalComponentVersionDownloadFilename) {
      this.localBinaryFileDownloadService.downloadBinaryFileFromUrl(
        this.digitalComponentVersionDownloadUrl,
        this.digitalComponentVersionDownloadFilename,
      );
    }
  }

}
