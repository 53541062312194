<icz-barcode-scanned-consignments-form
  [form]="form"
  [useCode2D]="(dialogMode === OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION ||
          dialogMode === OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION)"
  [notFoundMessage]="(
            dialogMode === OwnConsignmentWorkflowAction.TAKEOVER ?
              'Listinná zásilka s tímto kódem se nenachází v seznamu zásilek K převzetí' :
              'Listinná zásilka s tímto kódem se nenachází v seznamu zásilek K vypravení'
          )"
  (consignmentDeliveryServiceEditRequested)="editConsignmentDeliveryServiceCombination($event)"
  (consignmentCommandsChanged)="onConsignmentCommandsChanged($event)"
></icz-barcode-scanned-consignments-form>

<icz-form-buttons>
  <div class="row items-center" leftButtons>
    @if (dialogMode === OwnConsignmentWorkflowAction.TAKEOVER) {
      <icz-button
        [disabled]="!consignmentsToProcess.length || loadingService.isLoading(this)"
        (onAction)="bulkExecuteWorkflowAction(OwnConsignmentWorkflowAction.TAKEOVER)"
        primary label="Převzít"
      ></icz-button>
    }
    @if (dialogMode === OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION) {
      <icz-button
        [disabled]="!consignmentsToProcess.length || loadingService.isLoading(this)"
        (onAction)="bulkExecuteWorkflowAction(OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION)"
        primary label="Převzít a předat do distribuce"
      ></icz-button>
    }
    @if (dialogMode === OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION) {
      <icz-button
        [disabled]="!consignmentsToProcess.length || loadingService.isLoading(this)"
        (onAction)="bulkExecuteWorkflowAction(OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION)"
        primary label="Předat do distribuce"
      ></icz-button>
    }
    @if (consignmentsToProcess.length) {
      <div class="icz-body-1 ml-16">
        {{ 'Celkem zásilek' | translate }}: <span class="icz-body-strong">{{ consignmentsToProcess.length }}</span>
      </div>
    }
  </div>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
