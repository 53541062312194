/* tslint:disable */
/* eslint-disable */

/**
 * ESSL Object type
 */
export enum EsslObjectType {
  DOCUMENT = 'DOCUMENT',
  FILE = 'FILE',
  COMPONENT = 'COMPONENT',
  CONSIGNMENT = 'CONSIGNMENT',
  CASE_FILE = 'CASE_FILE',
  SUB_FILE = 'SUB_FILE',
  VOLUME = 'VOLUME',
  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',
  FUNCTIONAL_POSITION = 'FUNCTIONAL_POSITION',
  PERSON = 'PERSON',
  GROUP_OF_USERS = 'GROUP_OF_USERS',
  CLASSIFICATION_SCHEME = 'CLASSIFICATION_SCHEME',
  ENTITY_CLASS = 'ENTITY_CLASS',
  DISPOSAL_SCHEDULE = 'DISPOSAL_SCHEDULE',
  DISPOSAL_PROPOSAL = 'DISPOSAL_PROPOSAL',
  DELIMITATION_PROPOSAL = 'DELIMITATION_PROPOSAL',
  SUBJECT = 'SUBJECT',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  FILE_TYPE = 'FILE_TYPE',
  STORAGE_UNIT = 'STORAGE_UNIT',
  CIRCULATION_TASK = 'CIRCULATION_TASK',
  ID_GENERATOR = 'ID_GENERATOR',
  UID_GENERATOR = 'UID_GENERATOR',
  SPECIALIZED_UID_GENERATOR = 'SPECIALIZED_UID_GENERATOR',
  FILE_REF_NUMBER_GENERATOR = 'FILE_REF_NUMBER_GENERATOR',
  SPECIALIZED_FILE_REF_NUMBER_GENERATOR = 'SPECIALIZED_FILE_REF_NUMBER_GENERATOR',
  FILING_REGISTER = 'FILING_REGISTER',
  SEPARATE_FILING_REGISTER = 'SEPARATE_FILING_REGISTER',
  SHARED_FOLDER = 'SHARED_FOLDER',
  REGISTRY_OFFICE = 'REGISTRY_OFFICE',
  USER_DETAILS = 'USER_DETAILS',
  CLASSIFICATION = 'CLASSIFICATION',
  CLASSIFIER = 'CLASSIFIER',
  SECURITY_CATEGORY = 'SECURITY_CATEGORY',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DISTRIBUTOR = 'DISTRIBUTOR',
  DELIVERY_SERVICE_BASIC = 'DELIVERY_SERVICE_BASIC',
  DELIVERY_SERVICE_ADDITIONAL = 'DELIVERY_SERVICE_ADDITIONAL',
  DELIVERY_SERVICE_COMBINATION = 'DELIVERY_SERVICE_COMBINATION',
  KEYWORD = 'KEYWORD',
  BULK_POSTING_FORM = 'BULK_POSTING_FORM',
  OFFICE_DESK = 'OFFICE_DESK',
  ISZR_AGENDA = 'ISZR_AGENDA',
  SYSTEM = 'SYSTEM',
  SYSTEM_CONFIGURATION_PROPERTY_DEFINITION = 'SYSTEM_CONFIGURATION_PROPERTY_DEFINITION',
  SYSTEM_CONFIGURATION_PROPERTY_VALUE = 'SYSTEM_CONFIGURATION_PROPERTY_VALUE',
  SYSTEM_CONFIGURATION_ORGANIZATION = 'SYSTEM_CONFIGURATION_ORGANIZATION',
  TRANSACTION_PROTOCOL = 'TRANSACTION_PROTOCOL',
  SYSTEM_CONFIGURATION_EMAIL_NOTIFICATION_RULE = 'SYSTEM_CONFIGURATION_EMAIL_NOTIFICATION_RULE'
}
