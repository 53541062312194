import {Directive, inject, Injector} from '@angular/core';

@Directive({
  selector: '[iczGetNodeInjector]',
  exportAs: 'iczGetNodeInjector',
})
export class GetNodeInjectorDirective {

  injector = inject(Injector);

}
