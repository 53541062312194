import {CommonModule} from '@angular/common';
import {inject, NgModule} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {NG_EVENT_PLUGINS} from '@tinkoff/ng-event-plugins';
import {DialogsModule} from './components/dialogs/dialogs.module';
import {initDatepickerLocale} from './components/essentials/calendar/calendar-locale';
import {EssentialsModule} from './components/essentials/essentials.module';
import {LoadingIndicatorService} from './components/essentials/loading-indicator.service';
import {FormElementsModule} from './components/form-elements/form-elements.module';
import {GeneralScreenAreaModule} from './components/general-screen-area/general-screen-area.module';
import {HighlightsModule} from './components/highlights/highlights.module';
import {LoginScreensModule} from './components/login-screens/login-screens.module';
import {NotificationsModule} from './components/notifications/notifications.module';
import {SharedScreensModule} from './components/shared-screens/shared-screens.module';
import {TableModule} from './components/table/table.module';
import {initIconRegistry} from './components/essentials/icon/icon.registry';
import {
  SharedBusinessComponentsModule
} from './components/shared-business-components/shared-business-components.module';

// isNil is inserted here into global scope because otherwise
//  unit tests of libs can't resolve it in runtime.
window.isNil = (value: any): value is Nil => {
  return value === null || value === undefined;
};

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    EssentialsModule,
    FormElementsModule,
    DialogsModule,
    TableModule,
    SharedScreensModule,
    NotificationsModule,
    LoginScreensModule,
    HighlightsModule,
    GeneralScreenAreaModule,
    SharedBusinessComponentsModule,
  ],
  providers: [
    LoadingIndicatorService,
    NG_EVENT_PLUGINS,
  ],
})
export class SharedModule {

  constructor() {
    initIconRegistry(inject(MatIconRegistry), inject(DomSanitizer));
    initDatepickerLocale(inject(TranslateService), inject(DateAdapter));
  }

}
