import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {DateRange, MatCalendarCellCssClasses, MatCalendarView} from '@angular/material/datepicker';
import {IczValidatorResult} from '../../form-elements/validators/icz-validators/validator-decorators';
import {HolidayDatesService} from '../../../services/holiday-dates.service';
import {isDateRange} from '../../../model';

export type ValidDateFn = (date: Date) => boolean;

export interface ValidDateFnWithMessage {
  validationFn: ValidDateFn,
  invalidDateMessage?: IczValidatorResult
}

@Component({
  selector: 'icz-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent {

  private holidayDatesService = inject(HolidayDatesService);

  @Input()
  startAt: Nullable<Date | DateRange<Date> | string>;
  @Input()
  selected: Nullable<Date | DateRange<Date> | string>;
  @Input()
  selectableDates: ValidDateFn = _ => true;
  @Input()
  areHolidaysSelectable = true;
  @Output()
  daySelected = new EventEmitter<void>();
  @Output()
  selectedChange = new EventEmitter<Date | DateRange<Date>>();

  /**
   * a predicate which makes the dates which do
   * not satisfy it red plus makes them unselectable
   */
  nonHolidayDates: ValidDateFn = d => !this.holidayDatesService.isHoliday(d);

  isSelectableDate(date: Date, view: MatCalendarView = 'month') {
    if (view === 'month') {
      if (this.areHolidaysSelectable) {
        return this.selectableDates(date);
      }
      else {
        return this.selectableDates(date) && this.nonHolidayDates(date);
      }
    }
    else {
      return true;
    }
  }

  dateClass = (d: Date, view: MatCalendarView): MatCalendarCellCssClasses => {
    const classes: string[] = [];

    if (!this.isSelectableDate(d, view)) {
      classes.push('calendar-date-disabled');
    }

    if (!this.nonHolidayDates(d) && view === 'month') {
      classes.push('calendar-date-holiday');
    }

    return classes;
  };

  onDatePick(newValue: Nullable<Date | DateRange<Date>>): void {
    if (isDateRange(newValue)) {
      this.selectedChange.emit(newValue);
    }
    else {
      if (newValue) {
        const date = new Date(newValue);

        if (this.isSelectableDate(date)) {
          this.selectedChange.emit(date);
        }
      }
    }
  }

}
