import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, ViewChild} from '@angular/core';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormOptionsDefinition, makeDefaultOptionsDefinition} from '../form-autocomplete/form-autocomplete.model';
import {SelectLikeField} from '../select-like-field';
import {
  ComposableFormAutocompleteComponent
} from '../form-autocomplete/composable-form-autocomplete/composable-form-autocomplete.component';
import {
  IAutocompleteNoResultsContext
} from '../form-autocomplete/form-autocomplete-list/form-autocomplete-list.component';
import {locateOptionByValue, Option} from '../../../model';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {ChipUsageRates, UserSettingsService} from '../../../services/user-settings.service';


interface ChipInputOptionData {
  _usageRate: number;
}

type ChipInputOption = Option<Nullable<string | number>, ChipInputOptionData>;
type ChipInputValue = Array<string | number> | string | number;

const usageRateComparator = (o1: ChipInputOption, o2: ChipInputOption) => o2.data!._usageRate - o1.data!._usageRate;

// Decorates default options definition such that the options
// will get sorted by usage rate from local storage
export function makeUsageSortedOptionsDefinition(
  options$: Observable<Option[]>,
  strForSearch: (s: Option) => string,
): FormOptionsDefinition {
  const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

  defaultDefinition.searchtermToOptionsOperator = pipe(
    defaultDefinition.searchtermToOptionsOperator,
    map(options => [...(options as ChipInputOption[]).sort(usageRateComparator)]),
  );

  return defaultDefinition;
}

@Component({
  selector: 'icz-form-chip-input',
  templateUrl: './form-chip-input.component.html',
  styleUrls: ['./form-chip-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormChipInputComponent extends SelectLikeField<string | number> implements OnInit, IczOnChanges {

  private userSettingsService = inject(UserSettingsService);
  private cd = inject(ChangeDetectorRef);

  @ViewChild('basisComponent')
  basisComponent!: ComposableFormAutocompleteComponent;

  override _visibleOptions!: ChipInputOption[];

  @Input()
  override set value(newValues: Nullable<ChipInputValue>) {
    super.value = newValues;

    this.initOptions((this._rawOptions ?? []) as ChipInputOption[]);
  }
  override get value(): Nullable<ChipInputValue> {
    return super.value;
  }

  @Input({required: true})
  chipNamespace: Nullable<string>;
  @Input()
  override isMultiselect = true;
  @Input()
  manualValueCommit = true;
  @Input()
  searchIndexer: Nullable<(o: Option) => string>;

  usageSortedOptionsDefinitionFactory = makeUsageSortedOptionsDefinition;

  createNewChipText = '"{{searchTerm}}" (vytvořit nové)';

  private _rawOptions: Nullable<Option[]>;
  private _lastRealValue: Nullable<ChipInputValue> = null;

  ngOnInit(): void {
    if (isNil(this.chipNamespace)) {
      console.warn('Each instance of icz-form-chip-input must have property "chipNamespace" set.');
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.options || changes.value || changes.chipNamespace) {
      if (this.options !== this._rawOptions) {
        this._rawOptions = this.options;
        this.initOptions(this._visibleOptions);
      }
    }
  }

  addChip(context: IAutocompleteNoResultsContext) {
    if (this.chipNamespace) {
      const chipName = context.searchTerm;

      this._visibleOptions.push({
        label: chipName,
        value: chipName,
        data: {
          _usageRate: 0,
        }
      });
      this.userSettingsService.addCustomChip(this.chipNamespace, chipName);

      context.searchTermChanged.emit(context.searchTerm);
    }
  }

  applyChanges() {
    super.emitFieldValue();
    this.recomputeUsageRates();
    this._lastRealValue = this._value as Nullable<Array<string | number>>;
    this.basisComponent.optionsClosed(true);
  }

  valueCleared() {
    super.emitFieldValue();
    this._lastRealValue = null;
  }

  // Will return selected chip state to previous effective value
  // after closing the selector without applying any changes.
  optionsListClosed(isSynthetic: boolean) {
    if (!isSynthetic && this.isMultiselect && this.manualValueCommit) {
      this._value = this._lastRealValue;
      this.__value = this.searchForMultiselectOptions(this._lastRealValue as Nullable<Array<string|number>>);
    }
  }

  protected override emitFieldValue() {
    if (!(this.manualValueCommit && this.isMultiselect)) {
      super.emitFieldValue();
    }
  }

  private recomputeUsageRates() {
    let oldSelectedOptions: Set<string|number>;
    let newSelectedOptions: Set<string|number>;

    if (this.isMultiselect) {
      oldSelectedOptions = new Set(this._lastRealValue as Array<string|number> ?? []);
      newSelectedOptions = new Set(this._value as Nullable<Array<string | number>> ?? []);
    }
    else {
      oldSelectedOptions = new Set(this._lastRealValue ? [this._lastRealValue as string|number] : []);
      newSelectedOptions = new Set(this._value ? [this._value as string|number] : []);
    }

    const additions = new Set([...newSelectedOptions].filter(x => !oldSelectedOptions.has(x!)));

    for (const additionValue of additions) {
      const additionOption = this._visibleOptions.find(o => o.value === additionValue)!;

      ++additionOption.data!._usageRate;

      this.userSettingsService.setChipUsageRate(
        this.chipNamespace!,
        String(additionOption.value),
        additionOption.data!._usageRate,
      );
    }
  }

  private initOptions(options: ChipInputOption[]) {
    this.options = FormChipInputComponent.getOptionsWithCustomChips(
      options,
      this._value as Nullable<string>,
      this.chipNamespace!,
      this.userSettingsService,
    );
    this.cd.detectChanges();
  }

  static getOptionsWithCustomChips(
    originalOptions: Option<Nullable<string | number>>[],
    currentValue: Nullable<string | string[]>,
    chipNamespace: string,
    userSettingsService: UserSettingsService,
  ) {
    const customOptions = (chipNamespace ? userSettingsService.getCustomChips(chipNamespace) : []).map(
      chipName => ({label: chipName, value: chipName} as Option)
    );

    const availableOptions: Option[] = [
      ...originalOptions,
      ...customOptions,
    ];
    const optionsFromArbitraryValues: Option[] = [];

    if (Array.isArray(currentValue)) {
      for (const singleValue of currentValue) {
        if (!locateOptionByValue(availableOptions, singleValue)) {
          optionsFromArbitraryValues.push({
            label: singleValue,
            value: singleValue,
          });
        }
      }
    }
    else if (!isNil(currentValue) && !locateOptionByValue(availableOptions, currentValue)) {
      optionsFromArbitraryValues.push({
        label: currentValue,
        value: currentValue,
      });
    }

    const chipUsageRates: ChipUsageRates = chipNamespace ? userSettingsService.getChipUsageRates(chipNamespace) : {};

    return ([
      ...optionsFromArbitraryValues,
      ...availableOptions,
    ] as ChipInputOption[]).map(o => {
      if (!o.hasOwnProperty('data')) {
        o.data = {
          _usageRate: 0,
        };
      }
      o.data!._usageRate = chipUsageRates[String(o.value)] ?? 0;
      return o;
    });
  }
}
