@if (!subject?.attributes) {
  <div>
    @if (isDocumentObject) {
      <icz-label label="Dokument nemá reprezentující subjekt."></icz-label>
    }
    @if (isFileObject) {
      <icz-label label="Spis nemá reprezentující subjekt."></icz-label>
    }
  </div>
}
@if (subject?.attributes) {
  <div>
    <div class="row">
      <div class="col justify-center w-64">
        <icz-user-initials [initials]="subject?.classification!"></icz-user-initials>
      </div>
      <div class="col">
        <span>{{subject | subjectName}}</span>
        <icz-address-details [addressWithType]="getBusinessSortedAddress"></icz-address-details>
        @if (showDatabox && dataBoxes?.length) {
          <icz-databox-details [dataBoxes]="dataBoxes"></icz-databox-details>
        }
      </div>
    </div>
  </div>
}
