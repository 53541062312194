import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {BusinessRuleName, DataFileFormatAcceptability, DocumentForm, SettlementType} from '|api/commons';
import {EsslComponentDto} from '../../../../../../services/essl-component-search.service';
import {
  ApiDocumentService,
  DisposalScheduleComputationDto,
  DisposalScheduleComputationInnerRequestDto,
  DocumentDto
} from '|api/document';
import {IczFormGroup} from '../../../../../form-elements/icz-form-controls';
import {ApiComponentService} from '|api/component';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {WizardComponent} from '../../../../../dialogs/wizard/wizard.component';
import {EsslComponentsTableColumnSet} from '../../../../essl-components-table/essl-components-table.component';
import {LoadingIndicatorService} from '../../../../../essentials/loading-indicator.service';
import {SearchedDocumentOptionData, SettlementTabState} from '../document-settle-model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime} from 'rxjs/operators';

enum ResolutionDialogStep {
  RESOLUTION = 'RESOLUTION',
  RESOLUTION_BY_DOCUMENT = 'RESOLUTION_BY_DOCUMENT',
  COMPONENTS_BY_DOCUMENT = 'COMPONENTS_BY_DOCUMENT',
  COMPONENTS = 'COMPONENTS'
}

@Component({
  selector: 'icz-document-settle',
  templateUrl: './document-settle.component.html',
  styleUrls: ['./document-settle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSettleComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private cd = inject(ChangeDetectorRef);
  private apiComponentService = inject(ApiComponentService);
  private destroyRef = inject(DestroyRef);
  private apiDocumentService = inject(ApiDocumentService);

  @Input({required: true}) document!: DocumentDto;
  @Input({required: true}) form!: IczFormGroup;
  @Input({required: true}) settlementTabState!: SettlementTabState;
  @Input() disposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  @Input() showWizardSubmitBar = true;
  @Input() showDocumentInfo = false;
  @Output() settlementValid = new EventEmitter<boolean>();
  @Output() closed = new EventEmitter<boolean>();
  @Output() submit = new EventEmitter<void>();
  @Output() settlementTabStateChange = new EventEmitter<SettlementTabState>();
  @Output() esslComponentIdsExcluded = new EventEmitter<number[]>();
  @ViewChild(WizardComponent)
  wizardComponent!: WizardComponent;

  readonly ResolutionDialogSteps = ResolutionDialogStep;
  readonly BusinessRuleName = BusinessRuleName;
  relatedDocuments: Record<number, Nullable<DocumentDto>> = {};

  quickResolutionHandler = () => this.submit.emit();

  esslComponents: Nullable<EsslComponentDto[]>;
  preselectedComponents: Nullable<EsslComponentDto[]>;
  preselectedRelatedDocumentComponents: Nullable<EsslComponentDto[]>;
  acceptableDataFileFormats = [DataFileFormatAcceptability.ACCEPTABLE, DataFileFormatAcceptability.RECOMMENDED, DataFileFormatAcceptability.COMPLEMENTARY];

  onRelatedDocumentChange(documentId: number, relatedDocument: Nullable<SearchedDocumentOptionData>) {
    this.relatedDocuments[documentId] = relatedDocument ? relatedDocument.document : null;
  }
  get componentsStepValid() {
    const documentForm = this.form.get('documentForm')!.value as DocumentForm;
    return documentForm !== DocumentForm.DIGITAL || this.selectedEsslComponentsCount > 0;
  }

  get isOnResolutionFormStep() {
    return this.wizardComponent?.stepIndex === 0;
  }

  get isOnByDocumentResolutionStep() {
    return this.wizardComponent?.currentStep.id === ResolutionDialogStep.RESOLUTION_BY_DOCUMENT;
  }

  get isOnComponentsStep() {
    return this.wizardComponent?.currentStep.id === ResolutionDialogStep.COMPONENTS;
  }

  get isResolutionByDocument() {
    return this.form.get('documentSettlementType')?.value === SettlementType.BY_DOCUMENT;
  }

  get isDocumentForResolutionSelected() {
    return !isNil(this.form.get('relatedDocumentId')?.value);
  }

  get digitalDocumentHasComponents() {
    const documentForm: DocumentForm = this.form.get('documentForm')!.value;

    if (documentForm === DocumentForm.DIGITAL) {
      return Boolean(
        this.form && this.esslComponents &&
        documentForm === DocumentForm.DIGITAL &&
        this.selectedEsslComponentsCount > 0
      );
    } else {
      return true;
    }
  }

  get digitalComponentSelectionTabHeading(): string {
    return this.translateParser.interpolate(
      this.translateService.instant('Výběr příloh - vyřizující dokument ({{count}})'),
      {count: this.selectedEsslComponentsCount},
    ) ?? '';
  }

  get byDocumentComponentSelectionTabHeading(): string {
    return this.translateParser.interpolate(
      this.translateService.instant('Výběr příloh - vyřizovaný dokument ({{count}})'),
      {count: this.byDocumentComponentsCount},
    ) ?? '';
  }

  get relatedDocumentId(): number {
    return this.form.get('relatedDocumentId')?.value;
  }

  get resolutionValid() {
    return this.form.valid;
  }

  get selectedEsslComponentsCount(): number {
    return this.form.get('esslComponents')!.value?.length ?? 0;
  }

  get byDocumentComponentsCount(): number {
    return this.form.get('relatedDocumentComponents')!.value?.length ?? 0;
  }

  readonly EsslComponentsTableColumnSet = EsslComponentsTableColumnSet;

  ngOnInit(): void {
    this.form.valueChanges.pipe(debounceTime(250), takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.settlementValid.emit(this.form.valid);
    });

    if (this.document) {
      this.apiComponentService.componentFindComponentsBySetOfDocumentsIds({
        body: {
          documentIds: [this.document.id]
        }
      }).subscribe(documentComponents => {
        this.form.get('esslComponents')!.setValue(documentComponents);
        this.preselectedComponents = documentComponents as unknown as EsslComponentDto[];
        this.cd.detectChanges();
      });
    }

    this.form.get('relatedDocumentId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(relatedDocumentId => {
      const settlementType = this.form.get('documentSettlementType')!.value;
      if (settlementType === SettlementType.BY_DOCUMENT && !isNil(relatedDocumentId)) {
        this.loadingIndicatorService.doLoading(
          this.apiComponentService.componentFindComponentsBySetOfDocumentsIds({
            body: {
              documentIds: [relatedDocumentId]
            }
          }),
          this
        ).subscribe(documentComponents => {
          this.form.get('relatedDocumentComponents')!.setValue(documentComponents);
          this.preselectedRelatedDocumentComponents = documentComponents as unknown as EsslComponentDto[];
          this.cd.detectChanges();
        });
      }
    });

    this.form.updateValueAndValidity();
  }

  onErrorDialogClose() {
    this.closed.emit(false);
  }

  close(isDone?: boolean) {
    this.closed.emit(isDone);
  }

  setEsslComponents(esslComponents: EsslComponentDto[]) {
    this.form.get('esslComponents')!.setValue(esslComponents);
  }

  setExcludedComponentIds(ids: number[]) {
    this.form.get('excludedComponentIds')!.setValue(ids);
  }

  setexcludedRelatedDocumentComponentIds(ids: number[]) {
    this.form.get('excludedRelatedDocumentComponentIds')!.setValue(ids);
  }

  onComponentSelectorDestroyed() {
    this.preselectedComponents = this.form.get('esslComponents')!.value;
  }

  onRelatedComponentSelectorDestroyed() {
    this.preselectedRelatedDocumentComponents = this.form.get('relatedDocumentComponents')!.value;
  }

  setRelatedDocumentComponents(esslComponents: EsslComponentDto[]) {
    this.form.get('relatedDocumentComponents')!.setValue(esslComponents);
  }

  goToStep(stepId: string) {
    this.wizardComponent.setStepById(stepId);
  }

  onDisposalScheduleSourceChange() {
    const formValue = this.form.getRawValue();
    const computationInnerRequest: DisposalScheduleComputationInnerRequestDto[] = [{
      entityId: this.document.id,
      documentTypeId: formValue.documentTypeId,
      entityClassId: formValue.entityClassId,
    }];

    this.apiDocumentService.documentGetValidDisposalSchedules(
      {
        body: {
          inner: computationInnerRequest,
          referenceDate: this.form.get('settlementDate'!)!.value!
        }
      }
    ).subscribe(result => {
      if (result.resultsPerEntity) {
        this.disposalSchedulePrepare = result.resultsPerEntity[0];
        this.cd.detectChanges();
      }
    });
  }

}
