/* tslint:disable */
/* eslint-disable */

/**
 * Represents the business type of Transaction Log attribute.
 */
export enum TransactionLogAttributeType {
  BOOLEAN = 'BOOLEAN',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  LOCALTIME = 'LOCALTIME',
  LOCALDATE = 'LOCALDATE',
  DATETIME = 'DATETIME',
  STRING = 'STRING',
  L_10_N_STRING = 'L10N_STRING',
  ENUM = 'ENUM',
  FUNCTIONAL_POSITION = 'FUNCTIONAL_POSITION',
  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',
  CLASSIFIER = 'CLASSIFIER'
}
