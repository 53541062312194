<div [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async" class="h-full">
  @if (idtStatus === IdtConnectionState.RUNNING) {
    <icz-form-autocomplete
      formControlName="printerName"
      label="Vyberte tiskárnu"
      [options]="printerOptions"
    ></icz-form-autocomplete>
  }
  @else {
    <icz-idt-malfunction-alert
      [idtStatus]="idtStatus"
      functionUnavailableText="Operace tisku tudíž nejsou k dispozici."
    ></icz-idt-malfunction-alert>
    <div class="mt-8">
      @if (idtStatus !== IdtConnectionState.INITIATING) {
        <a iczLinkWithoutHref (click)="reconnect()">
          Zkusit znovu
        </a>
      }
    </div>
  }

</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    (onAction)="submit()"
    primary label="Tisk"
    [disabled]="idtStatus !== IdtConnectionState.RUNNING"
  ></icz-button>
  <icz-button rightButtons
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
