import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollingService {

  isDebugMode = false;

  getScrollableAncestor(el: Nullable<HTMLElement>): Nullable<HTMLElement> {
    if (isNil(el) || !(el instanceof HTMLElement)) {
      return null;
    }

    // @ts-ignore
    const isElementCssScrollable = ['auto', 'scroll'].includes(window.getComputedStyle(el)['overflow-y']);

    if (isElementCssScrollable && el.scrollHeight > el.clientHeight) {
      return el;
    }
    else {
      return this.getScrollableAncestor(el.parentNode as Nullable<HTMLElement>);
    }
  }

  getOffsetTopInAncestor(el: Nullable<HTMLElement>, ancestor: HTMLElement): number {
    if (
      isNil(el) ||
      el === ancestor ||
      el.contains(ancestor) ||
      el === document.body
    ) {
      return 0;
    }
    else {
      // If el.offsetParent !== el.parentNode, el-to-root path traversal might omit
      // elements with significant offsets and cause the computation to be incorrect.
      if (this.isDebugMode && el.offsetParent !== el.parentNode) {
        console.warn(
          `[iczForm]: this element on the path from el to ancestor might be non-offset element. Please make it ` +
          `an offset element by setting its position to relative (preferred) or absolute if problems with invalid form scroll arise.`,
          el.parentNode
        );
      }

      return el.offsetTop + this.getOffsetTopInAncestor(el.offsetParent as HTMLElement, ancestor);
    }
  }


  scrollTo(el: HTMLElement) {
    if (!el) {
      return;
    }

    const offsetTolerance = 100; // = 60px (toolbar) + 40px (safe area)

    setTimeout(() => {
      const scrollableAncestor = this.getScrollableAncestor(el);
      if (scrollableAncestor) {
        const offset = this.getOffsetTopInAncestor(el, scrollableAncestor);
        scrollableAncestor.scrollTo({top: offset - offsetTolerance, behavior: 'smooth'});
      }
    }, 100);
  }
}
