import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {Router} from '@angular/router';
import {OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';

@Component({
  selector: 'icz-parent-document-info',
  templateUrl: './parent-document-info.component.html',
  styleUrls: ['./parent-document-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParentDocumentInfoComponent {

  private router = inject(Router);

  @Input({required: true})
  parentDocument!: OwnDocumentDto | ReceivedDocumentDto;
  @Output()
  documentLinkClicked = new EventEmitter<void>();

  linkClicked() {
    this.documentLinkClicked.emit();
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.DOCUMENT,
      this.parentDocument.id,
    ));
  }

}
