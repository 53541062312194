/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OfficeDeskNodeDto } from '../models/office-desk-node-dto';
import { officeDeskNodesOauthCreate } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-create';
import { OfficeDeskNodesOauthCreate$Params } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-create';
import { officeDeskNodesOauthGetAll } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-get-all';
import { OfficeDeskNodesOauthGetAll$Params } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-get-all';
import { officeDeskNodesOauthGetById } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-get-by-id';
import { OfficeDeskNodesOauthGetById$Params } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-get-by-id';
import { officeDeskNodesOauthGetDefault } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-get-default';
import { OfficeDeskNodesOauthGetDefault$Params } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-get-default';
import { officeDeskNodesOauthSearch } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-search';
import { OfficeDeskNodesOauthSearch$Params } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-search';
import { officeDeskNodesOauthUpdate } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-update';
import { OfficeDeskNodesOauthUpdate$Params } from '../fn/office-desk-nodes-oauth/office-desk-nodes-oauth-update';
import { PageOfficeDeskNodeDto } from '../models/page-office-desk-node-dto';


/**
 * API for management of office desk nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiOfficeDeskNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `officeDeskNodesOauthUpdate()` */
  static readonly OfficeDeskNodesOauthUpdatePath = '/oauth/office-desk-nodes/update-node/{id}';

  /**
   * Updates office desk node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskNodesOauthUpdate$Response(params: OfficeDeskNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskNodeDto>> {
    return officeDeskNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates office desk node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskNodesOauthUpdate(params: OfficeDeskNodesOauthUpdate$Params, context?: HttpContext): Observable<OfficeDeskNodeDto> {
    return this.officeDeskNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskNodeDto>): OfficeDeskNodeDto => r.body)
    );
  }

  /** Path part for operation `officeDeskNodesOauthCreate()` */
  static readonly OfficeDeskNodesOauthCreatePath = '/oauth/office-desk-nodes/create-node';

  /**
   * Creates new office desk node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskNodesOauthCreate$Response(params: OfficeDeskNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskNodeDto>> {
    return officeDeskNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new office desk node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskNodesOauthCreate(params: OfficeDeskNodesOauthCreate$Params, context?: HttpContext): Observable<OfficeDeskNodeDto> {
    return this.officeDeskNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskNodeDto>): OfficeDeskNodeDto => r.body)
    );
  }

  /** Path part for operation `officeDeskNodesOauthGetById()` */
  static readonly OfficeDeskNodesOauthGetByIdPath = '/oauth/office-desk-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthGetById$Response(params: OfficeDeskNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskNodeDto>> {
    return officeDeskNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthGetById(params: OfficeDeskNodesOauthGetById$Params, context?: HttpContext): Observable<OfficeDeskNodeDto> {
    return this.officeDeskNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskNodeDto>): OfficeDeskNodeDto => r.body)
    );
  }

  /** Path part for operation `officeDeskNodesOauthSearch()` */
  static readonly OfficeDeskNodesOauthSearchPath = '/oauth/office-desk-nodes/search-api';

  /**
   * Find all values in office desk nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthSearch$Response(params?: OfficeDeskNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOfficeDeskNodeDto>> {
    return officeDeskNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in office desk nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthSearch(params?: OfficeDeskNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageOfficeDeskNodeDto> {
    return this.officeDeskNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfficeDeskNodeDto>): PageOfficeDeskNodeDto => r.body)
    );
  }

  /** Path part for operation `officeDeskNodesOauthGetAll()` */
  static readonly OfficeDeskNodesOauthGetAllPath = '/oauth/office-desk-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthGetAll$Response(params?: OfficeDeskNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OfficeDeskNodeDto>>> {
    return officeDeskNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthGetAll(params?: OfficeDeskNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<OfficeDeskNodeDto>> {
    return this.officeDeskNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OfficeDeskNodeDto>>): Array<OfficeDeskNodeDto> => r.body)
    );
  }

  /** Path part for operation `officeDeskNodesOauthGetDefault()` */
  static readonly OfficeDeskNodesOauthGetDefaultPath = '/oauth/office-desk-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskNodesOauthGetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthGetDefault$Response(params?: OfficeDeskNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskNodeDto>> {
    return officeDeskNodesOauthGetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskNodesOauthGetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskNodesOauthGetDefault(params?: OfficeDeskNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<OfficeDeskNodeDto> {
    return this.officeDeskNodesOauthGetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskNodeDto>): OfficeDeskNodeDto => r.body)
    );
  }

}
