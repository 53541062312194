/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagePhysicalItemComponentDto } from '../models/page-physical-item-component-dto';
import { physicalItemComponentCreate } from '../fn/physical-item-component/physical-item-component-create';
import { PhysicalItemComponentCreate$Params } from '../fn/physical-item-component/physical-item-component-create';
import { physicalItemComponentDelete } from '../fn/physical-item-component/physical-item-component-delete';
import { PhysicalItemComponentDelete$Params } from '../fn/physical-item-component/physical-item-component-delete';
import { PhysicalItemComponentDto } from '../models/physical-item-component-dto';
import { physicalItemComponentFindById } from '../fn/physical-item-component/physical-item-component-find-by-id';
import { PhysicalItemComponentFindById$Params } from '../fn/physical-item-component/physical-item-component-find-by-id';
import { physicalItemComponentFindPhysicalItemComponents } from '../fn/physical-item-component/physical-item-component-find-physical-item-components';
import { PhysicalItemComponentFindPhysicalItemComponents$Params } from '../fn/physical-item-component/physical-item-component-find-physical-item-components';
import { physicalItemComponentGetDocumentPhysicalItemComponentIds } from '../fn/physical-item-component/physical-item-component-get-document-physical-item-component-ids';
import { PhysicalItemComponentGetDocumentPhysicalItemComponentIds$Params } from '../fn/physical-item-component/physical-item-component-get-document-physical-item-component-ids';
import { physicalItemComponentGetDocumentPhysicalItemComponents } from '../fn/physical-item-component/physical-item-component-get-document-physical-item-components';
import { PhysicalItemComponentGetDocumentPhysicalItemComponents$Params } from '../fn/physical-item-component/physical-item-component-get-document-physical-item-components';
import { physicalItemComponentLinkPhysicalItemComponentToDocument } from '../fn/physical-item-component/physical-item-component-link-physical-item-component-to-document';
import { PhysicalItemComponentLinkPhysicalItemComponentToDocument$Params } from '../fn/physical-item-component/physical-item-component-link-physical-item-component-to-document';
import { physicalItemComponentUpdate } from '../fn/physical-item-component/physical-item-component-update';
import { PhysicalItemComponentUpdate$Params } from '../fn/physical-item-component/physical-item-component-update';


/**
 * Controller for physical item component
 */
@Injectable({ providedIn: 'root' })
export class ApiPhysicalItemComponentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `physicalItemComponentFindById()` */
  static readonly PhysicalItemComponentFindByIdPath = '/component/physical-item-component/{id}';

  /**
   * Finds existing physical item component by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentFindById$Response(params: PhysicalItemComponentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<PhysicalItemComponentDto>> {
    return physicalItemComponentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds existing physical item component by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentFindById(params: PhysicalItemComponentFindById$Params, context?: HttpContext): Observable<PhysicalItemComponentDto> {
    return this.physicalItemComponentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhysicalItemComponentDto>): PhysicalItemComponentDto => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentUpdate()` */
  static readonly PhysicalItemComponentUpdatePath = '/component/physical-item-component/{id}';

  /**
   * Update metadata for existing physical item component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  physicalItemComponentUpdate$Response(params: PhysicalItemComponentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PhysicalItemComponentDto>> {
    return physicalItemComponentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update metadata for existing physical item component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  physicalItemComponentUpdate(params: PhysicalItemComponentUpdate$Params, context?: HttpContext): Observable<PhysicalItemComponentDto> {
    return this.physicalItemComponentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhysicalItemComponentDto>): PhysicalItemComponentDto => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentCreate()` */
  static readonly PhysicalItemComponentCreatePath = '/component/physical-item-component';

  /**
   * Create new physical item component instance
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  physicalItemComponentCreate$Response(params: PhysicalItemComponentCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PhysicalItemComponentDto>> {
    return physicalItemComponentCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new physical item component instance
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  physicalItemComponentCreate(params: PhysicalItemComponentCreate$Params, context?: HttpContext): Observable<PhysicalItemComponentDto> {
    return this.physicalItemComponentCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PhysicalItemComponentDto>): PhysicalItemComponentDto => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentLinkPhysicalItemComponentToDocument()` */
  static readonly PhysicalItemComponentLinkPhysicalItemComponentToDocumentPath = '/component/physical-item-component/{documentId}/link';

  /**
   * Link physical item component for document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentLinkPhysicalItemComponentToDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  physicalItemComponentLinkPhysicalItemComponentToDocument$Response(params: PhysicalItemComponentLinkPhysicalItemComponentToDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return physicalItemComponentLinkPhysicalItemComponentToDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Link physical item component for document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentLinkPhysicalItemComponentToDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  physicalItemComponentLinkPhysicalItemComponentToDocument(params: PhysicalItemComponentLinkPhysicalItemComponentToDocument$Params, context?: HttpContext): Observable<void> {
    return this.physicalItemComponentLinkPhysicalItemComponentToDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentGetDocumentPhysicalItemComponents()` */
  static readonly PhysicalItemComponentGetDocumentPhysicalItemComponentsPath = '/component/physical-item-component/{documentId}/physical-item-components';

  /**
   * Returns physical item components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentGetDocumentPhysicalItemComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentGetDocumentPhysicalItemComponents$Response(params: PhysicalItemComponentGetDocumentPhysicalItemComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PhysicalItemComponentDto>>> {
    return physicalItemComponentGetDocumentPhysicalItemComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns physical item components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentGetDocumentPhysicalItemComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentGetDocumentPhysicalItemComponents(params: PhysicalItemComponentGetDocumentPhysicalItemComponents$Params, context?: HttpContext): Observable<Array<PhysicalItemComponentDto>> {
    return this.physicalItemComponentGetDocumentPhysicalItemComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PhysicalItemComponentDto>>): Array<PhysicalItemComponentDto> => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentFindPhysicalItemComponents()` */
  static readonly PhysicalItemComponentFindPhysicalItemComponentsPath = '/component/physical-item-component/{documentId}/physical-item-components/search-api';

  /**
   * Finds physical item components based on documentId and search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentFindPhysicalItemComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentFindPhysicalItemComponents$Response(params: PhysicalItemComponentFindPhysicalItemComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<PagePhysicalItemComponentDto>> {
    return physicalItemComponentFindPhysicalItemComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds physical item components based on documentId and search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentFindPhysicalItemComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentFindPhysicalItemComponents(params: PhysicalItemComponentFindPhysicalItemComponents$Params, context?: HttpContext): Observable<PagePhysicalItemComponentDto> {
    return this.physicalItemComponentFindPhysicalItemComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePhysicalItemComponentDto>): PagePhysicalItemComponentDto => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentGetDocumentPhysicalItemComponentIds()` */
  static readonly PhysicalItemComponentGetDocumentPhysicalItemComponentIdsPath = '/component/physical-item-component/{documentId}/physical-item-component-ids';

  /**
   * Returns physical item components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentGetDocumentPhysicalItemComponentIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentGetDocumentPhysicalItemComponentIds$Response(params: PhysicalItemComponentGetDocumentPhysicalItemComponentIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return physicalItemComponentGetDocumentPhysicalItemComponentIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns physical item components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentGetDocumentPhysicalItemComponentIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentGetDocumentPhysicalItemComponentIds(params: PhysicalItemComponentGetDocumentPhysicalItemComponentIds$Params, context?: HttpContext): Observable<Array<number>> {
    return this.physicalItemComponentGetDocumentPhysicalItemComponentIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `physicalItemComponentDelete()` */
  static readonly PhysicalItemComponentDeletePath = '/component/physical-item-component/per-entity/document/{documentId}/component/{componentId}';

  /**
   * Deletes physical item component in a given document according to theirs ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `physicalItemComponentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentDelete$Response(params: PhysicalItemComponentDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return physicalItemComponentDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes physical item component in a given document according to theirs ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `physicalItemComponentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  physicalItemComponentDelete(params: PhysicalItemComponentDelete$Params, context?: HttpContext): Observable<void> {
    return this.physicalItemComponentDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
