import {DocumentDto} from '|api/document';
import {
  AnalogComponentOrigin,
  ContainerExtractionState,
  DigitalComponentOrigin,
  DigitalComponentVersionTypeFlag,
  EntityType,
  EsslComponentAvailability
} from '|api/commons';
import {getLatestDigitalComponentVersion, isDigitalComponent,} from '../../shared-document.utils';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {DigitalComponentCompleteDto, DigitalComponentDto, PaperComponentDto} from '|api/component';
import {FunctionalPositionDto} from '|api/auth-server';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {
  CirculationActivityPreconditionFailureDto,
  CirculationActivityPreconditionFailureType,
  CirculationActivityPreconditionsCheckResultDto
} from '|api/flow';
import {OperationValidator, ValidatorGuard} from '../../../../services/bulk-operation-validation.service';


export class EsslComponentOperationValidators {
  @ValidatorGuard()
  static componentIsNotDigital(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return selection.entityType !== EntityType.DIGITAL_COMPONENT ? {validationMessage: 'Komponenta není digitální'} : null;
    };
  }

  @ValidatorGuard()
  static componentIsNotPaper(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return selection.entityType !== EntityType.PAPER_COMPONENT ? {validationMessage: 'Komponenta není listinná'} : null;
    };
  }

  @ValidatorGuard()
  static componentDoesntHaveDigitalRendition(): OperationValidator<PaperComponentDto> {
    return (selection: PaperComponentDto) => {
      return !Boolean(selection.digitalRenditionId) ? {validationMessage: 'Komponenta nemá digitalizovaný obsah'} : null;
    };
  }

  static someComponentsDontHaveAuthConversion(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      const component = selection as DigitalComponentDto | PaperComponentDto;
      return component.originType !== AnalogComponentOrigin.ANALOG_AUTH_CONVERSION && component.originType !== DigitalComponentOrigin.DIGITAL_AUTH_CONVERSION ?
        {validationMessage: 'Komponenta nemá provedenou KZMÚ.'} :
        null;
    };
  }

  static componentsIsNotInAllowedFormatForAuthConversionLE(): OperationValidator<PaperComponentDto> {
    return (selection: PaperComponentDto) => {
      const allowedDigitalComponentLastTypeFlagsForAuthorizedConversion = [
        DigitalComponentVersionTypeFlag.PDF,
        DigitalComponentVersionTypeFlag.PDFA,
        DigitalComponentVersionTypeFlag.IMAGE
      ];
      return !allowedDigitalComponentLastTypeFlagsForAuthorizedConversion.includes(selection.digitalComponentLastTypeFlag!) ?
        {validationMessage: 'Naskenovaný obsah listinné komponenty není v povoleném formátu pro autorizovanou konverzi.'} : null;
    };
  }

  static componentIsLocked(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return selection.availability === EsslComponentAvailability.LOCKED ? {validationMessage: 'Komponenta již byla uzamčena'} : null;
    };
  }

  static componentIsNotFromTemplate(): OperationValidator<DigitalComponentCompleteDto> {
    return (selection: DigitalComponentCompleteDto) => {
      return isNil(selection.digitalComponentTemplateId) ? {validationMessage: 'Komponenta nebyla vytvořena ze šablony'} : null;
    };
  }

  @ValidatorGuard()
  static componentIsNotLocked(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return selection.availability === EsslComponentAvailability.AVAILABLE ? {validationMessage: 'Komponenta zatím nebyla uzamčena'} : null;
    };
  }

  static componentIsFinal(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return selection.isFinal ? {validationMessage: 'Komponenta je finální'} : null;
    };
  }

  static componentIsNotFinal(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return !selection.isFinal ? {validationMessage: 'Komponenta není finální'} : null;
    };
  }

  static componentIsNotPdf(): OperationValidator<DigitalComponentCompleteDto> {
    return (selection: DigitalComponentCompleteDto) => {
      return getLatestDigitalComponentVersion(selection)!.mimeType !== 'application/pdf' ? {validationMessage: 'Komponenta není ve formátu pdf'} : null;
    };
  }

  static anotherAsyncOperationIsRunning(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      return isDigitalComponent(selection) && selection.processingState ? {validationMessage: 'Nad komponentou již běží konverze nebo vytěžování'} : null;
    };
  }

  static isUnlockPreconditionNotMet(
    currentUserFP: FunctionalPositionDto,
    organizationalStructureService: OrganizationalStructureService,
    document: DocumentDto,
  ): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      const predicate = (component: EsslComponentDto) => {
        const precondition = (
          (component as DigitalComponentCompleteDto)?.lockedByFP === currentUserFP.id ||
          document.ownerFunctionalPositionId === currentUserFP.id ||
          organizationalStructureService.getParentsFPs(document.ownerFunctionalPositionId!).includes(currentUserFP.id)
        );
        return !precondition;
      };

      return predicate(selection) ? {validationMessage: 'Komponenta nebyla zamknuta Vámi ani nejste zpracovatel dokumentu ani jeho nadřízený'} : null;
    };
  }

  static handForSignPreconditionNotMet(validationResult: Array<CirculationActivityPreconditionsCheckResultDto>) {
    return (selection: EsslComponentDto) => {
      const failedValidation = validationResult.find(result => result.entityId === selection.id! && !result?.passed);
      if (failedValidation) {
        let text = '';
        const failedFilePrecondtion = failedValidation!.failures.find(f => f.entityType === EntityType.FILE);
        const failedDocumentPrecondtions = failedValidation!.failures.filter(f => f.entityType === EntityType.DOCUMENT);
        const failedComponentPrecondtions = failedValidation!.failures.filter(f => (
          f.entityType === EntityType.DIGITAL_COMPONENT ||
          f.entityType === EntityType.PAPER_COMPONENT ||
          f.entityType === EntityType.PHYSICAL_ITEM_COMPONENT ||
          f.entityType === EntityType.MEDIUM_COMPONENT ||
          f.entityType === EntityType.ANALOG_COMPONENT ||
          f.entityType === EntityType.COMPONENT
        ));

        if (failedFilePrecondtion) {
          if (failedFilePrecondtion.failedPrecondition === CirculationActivityPreconditionFailureType.INCOMPATIBLE_FLOW_CURRENTLY_RUNNING) {
            text = 'Nad spisem běží oběh, který brání předání komponenty.';
          }
        }
        else if (failedDocumentPrecondtions.length) {
          const errorDocuments: Record<string, CirculationActivityPreconditionFailureDto[]> = {};
          errorDocuments.notHolderOrOwner = failedDocumentPrecondtions.filter(
            doc => doc.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NOT_OWNER
          );
          errorDocuments.inpompatibleFlow = failedDocumentPrecondtions.filter(
            doc => doc.failedPrecondition === CirculationActivityPreconditionFailureType.INCOMPATIBLE_FLOW_CURRENTLY_RUNNING
          );

          if (errorDocuments.notHolderOrOwner && errorDocuments.notHolderOrOwner.length > 0 && errorDocuments.notHolderOrOwner[0]) {
            text = 'Nejste zpracovatelem dokumentu, který obsahuje vybranou komponentu.';
          }
          else if (errorDocuments.inpompatibleFlow && errorDocuments.inpompatibleFlow.length > 0 && errorDocuments.inpompatibleFlow[0]) {
            text = 'Nad dokumentem běží oběh, který brání předání komponenty.';
          }
        }
        else if (failedComponentPrecondtions.length) {
          const errorComponents: Record<string, CirculationActivityPreconditionFailureDto[]> = {};
          errorComponents.componentAlreadyFinal = failedComponentPrecondtions.filter(
            cmp => cmp.failedPrecondition === CirculationActivityPreconditionFailureType.COMPONENT_ALREADY_FINAL
          );
          errorComponents.inpompatibleFlow = failedComponentPrecondtions.filter(
            cmp => cmp.failedPrecondition === CirculationActivityPreconditionFailureType.INCOMPATIBLE_FLOW_CURRENTLY_RUNNING
          );

          if (errorComponents.componentAlreadyFinal && errorComponents.componentAlreadyFinal.length > 0 && errorComponents.componentAlreadyFinal[0]) {
            text = 'Komponenta je finální, nelze ji upravovat ani podepisovat, což brání předání komponenty.';
          }
          else if (errorComponents.inpompatibleFlow && errorComponents.inpompatibleFlow.length > 0 && errorComponents.inpompatibleFlow[0]) {
            text = 'Nad komponentou již běží jiný oběh.';
          }
        }
        return {validationMessage: text};
      } else {
        return null;
      }
    };
  }

  static componentIsAlreadyExtracted(): OperationValidator<DigitalComponentCompleteDto> {
    return (selection: DigitalComponentCompleteDto) => {
      const extractionState = getLatestDigitalComponentVersion(selection)!.extractionState;

      return (
        extractionState === ContainerExtractionState.EXTRACTED || extractionState === ContainerExtractionState.EXTRACTED_WITHOUT_CONTENT ?
          {validationMessage: 'Kontejner již byl vytěžen.'} :
          null
      );
    };
  }

  static componentIsNotContainer(): OperationValidator<DigitalComponentCompleteDto> {
    return (selection: DigitalComponentCompleteDto) => {
      return (
        getLatestDigitalComponentVersion(selection)!.extractionState ?
          null :
          {validationMessage: 'Komponenta není kontejner.'}
      );
    };
  }

  static componentIsInConversion():OperationValidator<DigitalComponentDto> {
    return (selection: DigitalComponentDto) => {
      return (
        isNil(selection.processingState) ?
          null :
          {validationMessage: 'Komponenta je v procesu konverze.'}
      );
    };
  }

  static componentIsNotInAllowedFormat(allowedFormats: string[]):OperationValidator<DigitalComponentCompleteDto> {
    return (selection: DigitalComponentCompleteDto) => {
      const latestComponentVersionPuid = getLatestDigitalComponentVersion(selection)?.puid!;
      const hasAllowedPuid = allowedFormats.includes(latestComponentVersionPuid);
      return (
        hasAllowedPuid ?
          null :
          {validationMessage: 'Komponenta není v povoleném formátu.'}
      );
    };
  }

  static componentHasAuthorizedConversion(): OperationValidator<EsslComponentDto> {
    return (selection: EsslComponentDto) => {
      const originType = (selection as DigitalComponentDto | PaperComponentDto).originType;
      return originType === DigitalComponentOrigin.DIGITAL_AUTH_CONVERSION || originType === AnalogComponentOrigin.ANALOG_AUTH_CONVERSION  ? {validationMessage: 'Komponenta je výstupem autorizované konverze.'} : null;
    };
  }
}
