import {
  ChangeDetectorRef,
  createComponent,
  Directive,
  ElementRef,
  EnvironmentInjector,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import {SpinnerComponent} from './spinner/spinner.component';
import {TestingFeature} from '../../core/services/environment.models';
import {IczOnChanges, IczSimpleChanges} from '../../utils/icz-on-changes';
import {ENVIRONMENT} from '../../core/services/environment.service';

export type Inaccessibility = TestingFeature | 'spinner';

interface BlockingOverlayOpts {
  noOpacity?: boolean;
  cursorWaiting?: boolean;
  cursorNotAllowed?: boolean;
  spinnerDiameter?: number;
  transparentBackdrop?: boolean;
  spinnerCenteringTopOffset?: number;
  invertSpinnerColor?: boolean;
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[testingFeature],[waiting],[blockingOverlay]',
})
export class InaccessibleDirective implements OnInit, IczOnChanges {

  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private environmentInjector = inject(EnvironmentInjector);
  private environment = inject(ENVIRONMENT);

  @Input() forceRelativePosition = true;
  @Input() waiting: Nullable<boolean> = false;

  @Input()
  set testingFeature(value: Nullable<boolean | ''>) { this.setTestingFeatureFlag(value); }
  get testingFeature() { return this._testingFeature; }
  _testingFeature = false;

  @Input()
  set blockingOverlay(value: boolean | '') { this.setTestingFeatureFlag(value); }
  get blockingOverlay() { return this._testingFeature; }

  @Input() testingFeatureLabel: Nullable<string> = 'Not implemented yet';
  @Input() blockingOverlayOpts: BlockingOverlayOpts = {noOpacity: false, cursorWaiting: false, cursorNotAllowed: false};

  @Input()
  set testingFeatureInaccessibility(value: TestingFeature) { this._testingFeatureInaccessibility = value; }
  get testingFeatureInaccessibility() {
    return this._testingFeatureInaccessibility || this.environment.testingFeatureBehavior;
  }
  private _testingFeatureInaccessibility!: TestingFeature;

  get inaccessibility(): Inaccessibility {
    if (this.testingFeature && this.environment.testingFeatureBehavior !== 'ACCESSIBLE') return this.testingFeatureInaccessibility;
    if (this.waiting) return 'spinner';
    return 'ACCESSIBLE';
  }

  private get overlayElement() {
    if (!this._overlayElement) {
      this._overlayElement = this.renderer.createElement('div');
      this.renderer.addClass(this._overlayElement, 'inaccessible-overlay');
      if (this.blockingOverlayOpts?.noOpacity) {
        this.renderer.addClass(this._overlayElement, 'inaccessible-overlay-none');
      }
      if (this.blockingOverlayOpts?.transparentBackdrop) {
        this.renderer.addClass(this._overlayElement, 'inaccessible-overlay-transparent');
      }
      if (this.blockingOverlayOpts?.cursorWaiting) {
        this.renderer.addClass(this._overlayElement, 'cursor-waiting');
      }
      if (this.blockingOverlayOpts?.cursorNotAllowed) {
        this.renderer.addClass(this._overlayElement, 'cursor-not-allowed');
      }
      if (this.blockingOverlayOpts?.spinnerCenteringTopOffset) {
        this.renderer.setStyle(this._overlayElement, 'padding-top', `${this.blockingOverlayOpts?.spinnerCenteringTopOffset}px`);
      }
    }
    return this._overlayElement;
  }
  private _overlayElement: Nullable<HTMLDivElement>;

  private get spinnerElement() {
    if (!this._spinnerElement) {
      const spinnerComponent = createComponent(SpinnerComponent, {
        environmentInjector: this.environmentInjector,
      });
      spinnerComponent.instance.diameter = this.blockingOverlayOpts?.spinnerDiameter ? this.blockingOverlayOpts?.spinnerDiameter : 100;
      spinnerComponent.instance.invertSpinnerColor = this.blockingOverlayOpts?.invertSpinnerColor ? this.blockingOverlayOpts?.invertSpinnerColor : false;
      spinnerComponent.changeDetectorRef.detectChanges();
      this._spinnerElement = spinnerComponent.instance.elementRef.nativeElement;
    }
    return this._spinnerElement;
  }
  private _spinnerElement!: HTMLElement;

  private get labelElement() {
    if (!this._labelElement) {
      this._labelElement = this.renderer.createText(this.testingFeatureLabel ?? '');
    }
    return this._labelElement;
  }
  private _labelElement?: HTMLDivElement;

  private previousInaccessibility: Inaccessibility = 'ACCESSIBLE';
  private previousDisplayStyle!: string;
  private previousOverflowStyle!: string;

  ngOnInit() {
    if (this.forceRelativePosition) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    this.updateInaccessibility(Boolean(changes.testingFeatureLabel));
    this.changeDetectorRef.markForCheck();
  }

  private updateInaccessibility(updateLabel: boolean) {
    if (!updateLabel && this.previousInaccessibility === this.inaccessibility) return;

    switch (this.previousInaccessibility) {
      case 'HIDDEN':
        this.elementRef.nativeElement.style.display = this.previousDisplayStyle;
        break;
      case 'spinner':
        this.elementRef.nativeElement.style.overflow = this.previousOverflowStyle;
        this.renderer.removeChild(this.overlayElement, this.spinnerElement);
        this.renderer.removeChild(this.elementRef.nativeElement, this.overlayElement);
        break;
      case 'LABELED':
        this.renderer.removeChild(this.overlayElement, this.labelElement);
        this.renderer.removeChild(this.elementRef.nativeElement, this.overlayElement);
        break;
    }

    if (updateLabel) this._labelElement = undefined;

    switch (this.inaccessibility) {
      case 'HIDDEN':
        this.previousDisplayStyle = this.elementRef.nativeElement.style.display;
        this.elementRef.nativeElement.style.display = 'none';
        break;
      case 'spinner':
        this.previousOverflowStyle = this.elementRef.nativeElement.style.overflow;
        this.elementRef.nativeElement.style.overflow = 'hidden';
        this.renderer.appendChild(this.elementRef.nativeElement, this.overlayElement);
        this._overlayElement!.style.top = `${this.elementRef.nativeElement.scrollTop}px`;
        this.renderer.appendChild(this.overlayElement, this.spinnerElement);
        break;
      case 'LABELED':
        this.renderer.appendChild(this.elementRef.nativeElement, this.overlayElement);
        this.renderer.appendChild(this.overlayElement, this.labelElement);
        break;
    }

    this.previousInaccessibility = this.inaccessibility;
  }

  private setTestingFeatureFlag(newValue: Nullable<boolean|''>) {
    this._testingFeature = newValue === '' || Boolean(newValue);
  }

}
