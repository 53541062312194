import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {PrimitiveControlValueType, PrimitiveValueFormField} from '../form-field';
import {OptionsListComponent} from '../form-autocomplete/options-list/options-list.component';
import {Option} from '../../../model';

/**
 * A simple select-like component. Used as a selector of filter operators.
 *
 */
@Component({
  selector: 'icz-form-inline-select',
  templateUrl: './form-inline-select.component.html',
  styleUrls: ['./form-inline-select.component.scss']
})
export class FormInlineSelectComponent extends PrimitiveValueFormField {
  @Input({required: true})
  set options(newOptions: Option<any>[]) {
    this._visibleOptions = newOptions.filter(o => !o.isHidden);
  }
  get options(): Option<any>[] {
    return this._visibleOptions;
  }
  @Input()
  override placeholder: string = 'Vyberte...';
  @Output()
  openStatusChanged = new EventEmitter<boolean>();

  @Input()
  override set value(newValue: Nullable<PrimitiveControlValueType>) {
    this.selectedOption = this.options.find(o => o.value === newValue) ?? null;
  }
  override get value(): Nullable<PrimitiveControlValueType> {
    return this.selectedOption?.value ?? null;
  }

  _visibleOptions: Option[] = [];

  selectedOption: Nullable<Option> = null;
  isOpen = false;

  @ViewChild(OptionsListComponent, {static: false})
  optionsList: Nullable<OptionsListComponent<any>>;

  override _valueChanged() {
    this.valueChange.emit(this.value);
  }

  selectionChanged($event: Option[]) {
    if ($event.length > 0) {
      this.selectedOption = $event[0];
    }

    this._valueChanged();
    this.close();
  }

  toggleOpen() {
    if (!this.fieldDisabled) {
      if (this.isOpen) {
        this.close();
      }
      else {
        this.open();
      }
    }
  }

  open() {
    this.isOpen = true;
    this.openStatusChanged.emit(true);
  }

  close() {
    this.isOpen = false;
    this.blur.emit();
    this.openStatusChanged.emit(false);
  }
}
