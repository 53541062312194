/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DataFileFormatDto } from '../models/data-file-format-dto';
import { DataFileFormatGroupDto } from '../models/data-file-format-group-dto';
import { formatRegisterFindAllDataFileFormats } from '../fn/format-register/format-register-find-all-data-file-formats';
import { FormatRegisterFindAllDataFileFormats$Params } from '../fn/format-register/format-register-find-all-data-file-formats';
import { formatRegisterFindAllGroups } from '../fn/format-register/format-register-find-all-groups';
import { FormatRegisterFindAllGroups$Params } from '../fn/format-register/format-register-find-all-groups';
import { formatRegisterFindFormatByMimeType } from '../fn/format-register/format-register-find-format-by-mime-type';
import { FormatRegisterFindFormatByMimeType$Params } from '../fn/format-register/format-register-find-format-by-mime-type';
import { formatRegisterFindFormatByPuid } from '../fn/format-register/format-register-find-format-by-puid';
import { FormatRegisterFindFormatByPuid$Params } from '../fn/format-register/format-register-find-format-by-puid';
import { formatRegisterFindFormatsByAcceptability } from '../fn/format-register/format-register-find-formats-by-acceptability';
import { FormatRegisterFindFormatsByAcceptability$Params } from '../fn/format-register/format-register-find-formats-by-acceptability';
import { formatRegisterFindGroupById } from '../fn/format-register/format-register-find-group-by-id';
import { FormatRegisterFindGroupById$Params } from '../fn/format-register/format-register-find-group-by-id';
import { formatRegisterGetPuidList } from '../fn/format-register/format-register-get-puid-list';
import { FormatRegisterGetPuidList$Params } from '../fn/format-register/format-register-get-puid-list';
import { formatRegisterSearch } from '../fn/format-register/format-register-search';
import { FormatRegisterSearch$Params } from '../fn/format-register/format-register-search';
import { PageDataFileFormatDto } from '../models/page-data-file-format-dto';


/**
 * Controller for Format Register
 */
@Injectable({ providedIn: 'root' })
export class ApiFormatRegisterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `formatRegisterFindFormatsByAcceptability()` */
  static readonly FormatRegisterFindFormatsByAcceptabilityPath = '/codebook/format-register/data-file-format-by-acceptability';

  /**
   * Find data file formats for given data file format acceptability
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterFindFormatsByAcceptability()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formatRegisterFindFormatsByAcceptability$Response(params: FormatRegisterFindFormatsByAcceptability$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DataFileFormatDto>>> {
    return formatRegisterFindFormatsByAcceptability(this.http, this.rootUrl, params, context);
  }

  /**
   * Find data file formats for given data file format acceptability
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterFindFormatsByAcceptability$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  formatRegisterFindFormatsByAcceptability(params: FormatRegisterFindFormatsByAcceptability$Params, context?: HttpContext): Observable<Array<DataFileFormatDto>> {
    return this.formatRegisterFindFormatsByAcceptability$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DataFileFormatDto>>): Array<DataFileFormatDto> => r.body)
    );
  }

  /** Path part for operation `formatRegisterSearch()` */
  static readonly FormatRegisterSearchPath = '/codebook/format-register/search-api';

  /**
   * Search api for file format.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterSearch$Response(params?: FormatRegisterSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDataFileFormatDto>> {
    return formatRegisterSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Search api for file format.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterSearch(params?: FormatRegisterSearch$Params|null|undefined, context?: HttpContext): Observable<PageDataFileFormatDto> {
    return this.formatRegisterSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataFileFormatDto>): PageDataFileFormatDto => r.body)
    );
  }

  /** Path part for operation `formatRegisterGetPuidList()` */
  static readonly FormatRegisterGetPuidListPath = '/codebook/format-register/puid-string-list';

  /**
   * Returns puids as a list of strings.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterGetPuidList()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterGetPuidList$Response(params?: FormatRegisterGetPuidList$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return formatRegisterGetPuidList(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns puids as a list of strings.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterGetPuidList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterGetPuidList(params?: FormatRegisterGetPuidList$Params|null|undefined, context?: HttpContext): Observable<Array<string>> {
    return this.formatRegisterGetPuidList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `formatRegisterFindFormatByMimeType()` */
  static readonly FormatRegisterFindFormatByMimeTypePath = '/codebook/format-register/mimetype/';

  /**
   * Find data file format for given mime type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterFindFormatByMimeType()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindFormatByMimeType$Response(params: FormatRegisterFindFormatByMimeType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DataFileFormatDto>>> {
    return formatRegisterFindFormatByMimeType(this.http, this.rootUrl, params, context);
  }

  /**
   * Find data file format for given mime type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterFindFormatByMimeType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindFormatByMimeType(params: FormatRegisterFindFormatByMimeType$Params, context?: HttpContext): Observable<Array<DataFileFormatDto>> {
    return this.formatRegisterFindFormatByMimeType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DataFileFormatDto>>): Array<DataFileFormatDto> => r.body)
    );
  }

  /** Path part for operation `formatRegisterFindAllGroups()` */
  static readonly FormatRegisterFindAllGroupsPath = '/codebook/format-register/groups';

  /**
   * Returns content of the data_file_format_group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterFindAllGroups()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindAllGroups$Response(params?: FormatRegisterFindAllGroups$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DataFileFormatGroupDto>>> {
    return formatRegisterFindAllGroups(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns content of the data_file_format_group
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterFindAllGroups$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindAllGroups(params?: FormatRegisterFindAllGroups$Params|null|undefined, context?: HttpContext): Observable<Array<DataFileFormatGroupDto>> {
    return this.formatRegisterFindAllGroups$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DataFileFormatGroupDto>>): Array<DataFileFormatGroupDto> => r.body)
    );
  }

  /** Path part for operation `formatRegisterFindGroupById()` */
  static readonly FormatRegisterFindGroupByIdPath = '/codebook/format-register/groups/{id}';

  /**
   * Returns group by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterFindGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindGroupById$Response(params: FormatRegisterFindGroupById$Params, context?: HttpContext): Observable<StrictHttpResponse<DataFileFormatGroupDto>> {
    return formatRegisterFindGroupById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns group by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterFindGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindGroupById(params: FormatRegisterFindGroupById$Params, context?: HttpContext): Observable<DataFileFormatGroupDto> {
    return this.formatRegisterFindGroupById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataFileFormatGroupDto>): DataFileFormatGroupDto => r.body)
    );
  }

  /** Path part for operation `formatRegisterFindAllDataFileFormats()` */
  static readonly FormatRegisterFindAllDataFileFormatsPath = '/codebook/format-register/data-file-formats';

  /**
   * Returns all data file formats
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterFindAllDataFileFormats()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindAllDataFileFormats$Response(params?: FormatRegisterFindAllDataFileFormats$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DataFileFormatDto>>> {
    return formatRegisterFindAllDataFileFormats(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all data file formats
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterFindAllDataFileFormats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindAllDataFileFormats(params?: FormatRegisterFindAllDataFileFormats$Params|null|undefined, context?: HttpContext): Observable<Array<DataFileFormatDto>> {
    return this.formatRegisterFindAllDataFileFormats$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DataFileFormatDto>>): Array<DataFileFormatDto> => r.body)
    );
  }

  /** Path part for operation `formatRegisterFindFormatByPuid()` */
  static readonly FormatRegisterFindFormatByPuidPath = '/codebook/format-register/';

  /**
   * Find data file format
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `formatRegisterFindFormatByPuid()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindFormatByPuid$Response(params: FormatRegisterFindFormatByPuid$Params, context?: HttpContext): Observable<StrictHttpResponse<DataFileFormatDto>> {
    return formatRegisterFindFormatByPuid(this.http, this.rootUrl, params, context);
  }

  /**
   * Find data file format
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `formatRegisterFindFormatByPuid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  formatRegisterFindFormatByPuid(params: FormatRegisterFindFormatByPuid$Params, context?: HttpContext): Observable<DataFileFormatDto> {
    return this.formatRegisterFindFormatByPuid$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataFileFormatDto>): DataFileFormatDto => r.body)
    );
  }

}
