import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';

export interface ChangelogItem {
  commitHash: string;
  authDate: string;
  author: string;
  pullReqNr: string;
  srcBranch: string;
  tgtBranch: string;
  commitMsg: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChangelogService {

  private httpClient = inject(HttpClient);

  getChangelogItems() {
    const requestTimestamp = Number(new Date());
    return this.httpClient.get<ChangelogItem[]>(`/assets/changelog.json?ts=${requestTimestamp}`);
  }

}
