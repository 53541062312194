/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CirculationActivityDto } from '../../models/circulation-activity-dto';
import { CirculationHandoverActivityCreateDto } from '../../models/circulation-handover-activity-create-dto';

export interface CirculationActivityCreateHandover$Params {
      body: CirculationHandoverActivityCreateDto
}

export function circulationActivityCreateHandover(http: HttpClient, rootUrl: string, params: CirculationActivityCreateHandover$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityDto>> {
  const rb = new RequestBuilder(rootUrl, circulationActivityCreateHandover.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CirculationActivityDto>;
    })
  );
}

circulationActivityCreateHandover.PATH = '/flow/activity/handover';