<ng-container [formGroup]="form">
  <icz-form-autocomplete
    [clearable]="false"
    [fieldDisabled]="true"
    class="grow min-w-0"
    [isMultiselect]="true"
    formControlName="externalRetentionTriggerIds"
    label="Externí spouštěcí událost"
    [options]="externalRetentionTriggerOptions"
  ></icz-form-autocomplete>
</ng-container>

