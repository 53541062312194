import {EventEmitter} from '@angular/core';
import {isEqual} from 'lodash';
import {combineLatest, Observable, pipe} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {Option} from '../../../model';

export type ValueToOptionOperator<O> = ((value$: Observable<any>) => Observable<O>);
export type SearchtermToOptionsOperator<O> = ((searchTerm$: Observable<string>) => Observable<O[]>);
export type DefaultOptionValue$ = () => Observable<any>;

export interface FormOptionsDefinitionGen<O extends Option> {
  // Will find an Option object corresponding to a scalar value (usually a string)
  valueToOptionOperator: ValueToOptionOperator<O>;
  // Will find appropriate Options based on a search term which is a string
  searchtermToOptionsOperator: SearchtermToOptionsOperator<O>;
  // Will find a default value in case the term is empty
  defaultOptionValue$?: DefaultOptionValue$;
}

export interface FormOptionsDefinition extends FormOptionsDefinitionGen<Option> {}
export type FormOptions = Option[] | Observable<Option[]>;

export function getDefaultSelectItemValue(options: Option[]): any {
  const selectItem = options.find(x => x.default && !x.disabled);
  return selectItem ? selectItem.value : null;
}

/**
 * Contexts required to be implemented for ng-template outlets
 */
interface ISelectedOptionInputs {
  selectedOptions: Option[];
}

interface IPopoverInputs {
  options: Option[];
  selectedOptions: Option[];
}

interface IPopoverOutputs {
  selectionChanged: (selectedOptions: Option[]) => void;
  searchTermChanged: (searchTerm: string) => void;
}

export interface ISelectedOptionContext extends ISelectedOptionInputs {}
export interface IPopoverContext extends IPopoverInputs, IPopoverOutputs {}

export interface IAutocompleteListItemContext {
  option: Option;
}

export interface IImplicitTemplateContext<T> {
  $implicit: T;
}

// this helper type allows to strongly type the components that
// are gonna be passed the ISelectedOptionContext and IPopoverContext
// and IAutocompleteListItemContext typed objects...
export type ComponentWithContext<C> = {
  [K in keyof C]: (
    C[K] extends (oneArgument: any) => void ? // is it a handler?
      EventEmitter<Parameters<C[K]>[0]> : // then the component should have corresponding event emitter
      C[K]
    )
};

/**
 * A factory function that prepares simple operator definitions.
 *
 * @param options$ observable of options list - because the source of the options may be
 *                                              either synchronous or asynchronous value
 * @param strForSearch a function for generating string for full-text search from option properties
 * @returns {FormOptionsDefinition}
 */
export type OptionsDefinitionFactory = (options$: Observable<Option[]>, strForSearch: (s: Option) => string) => FormOptionsDefinition;

// Options definition factory used for finite lists
export function makeDefaultOptionsDefinition(
  options$: Observable<Option[]>,
  strForSearch: (s: Option) => string,
): FormOptionsDefinition {
  const sharedOptions$ = options$.pipe(
    map(options => options || []),
    shareReplay(1),
  );

  const valueToOptionOperator: ValueToOptionOperator<Option> = value$ =>
    combineLatest([value$, sharedOptions$]).pipe(
      // find option for given value by isEqual deep comparison
      map(([value, options]) => options.find(option => isEqual(option.value, value))!),
    );

  const searchtermToOptionsOperator: SearchtermToOptionsOperator<Option> = pipe(
    map(searchterm => searchterm.toLowerCase()),
    searchterm$ => combineLatest([searchterm$, sharedOptions$]),
    // select the options with label containing the search string
    map(([searchTerm, options]) => options.filter((option: Option) => {
        const str = strForSearch(option);
        if (!str) return !searchTerm;
        return str.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    ),
  );

  const defaultOptionValue$: DefaultOptionValue$ = () => {
    return options$.pipe(
      map(selectItemArray => getDefaultSelectItemValue(selectItemArray))
    );
  };

  return {valueToOptionOperator, searchtermToOptionsOperator, defaultOptionValue$};
}
