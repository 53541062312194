import {animate, style, transition, trigger} from '@angular/animations';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  DestroyRef,
  DoCheck,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  NgZone,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {MatSort, MatSortable, Sort, SortDirection} from '@angular/material/sort';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ResizeEvent} from 'angular-resizable-element';
import {isEqual} from 'lodash';
import {BehaviorSubject, combineLatest, merge, Observable, skip, Subscription} from 'rxjs';
import {distinctUntilChanged, filter, finalize, map, take, withLatestFrom} from 'rxjs/operators';
import {
  AutoPageSizeByManualHeight,
  AutoPageSizeByOffsets,
  AutoPageSizeConfig,
  ColumnDefinition,
  MIN_SEARCH_TERM_LENGTH,
  ResizeLineBounds,
  TableConfig,
  TableConfigExtensions,
  TableTemplates
} from './table.models';
import {getDefaultTableToolbarConfig, TableToolbarComponent} from './table-toolbar/table-toolbar.component';
import {
  RowComparatorFn,
  RowDisableFn,
  RowSelectionDisableFn,
  SelectedRowsService,
  TableReservedColumns,
  TableRow
} from './selected-rows.service';
import {CustomPaginator, TablePaginatorComponent} from './table-paginator/table-paginator.component';
import {TableToolbarService} from './table-toolbar/table-toolbar.service';
import {TableColumnsData} from './table-columns-data';
import {ColumnTemplateDirective} from './column-template.directive';
import {DetachingService} from '../essentials/detaching.service';
import {PrimitiveControlValueType} from '../form-elements/form-field';
import {
  PersistedColumnProperties,
  PersistedUserTableProperties,
  UserSettingsService
} from '../../services/user-settings.service';
import {IczOnChanges, IczSimpleChanges} from '../../utils/icz-on-changes';
import {FilterOperator, FilterParam, SearchParams, SortParam} from '../../services/search-api.service';
import {IczTableDataSource} from './table.datasource';
import {CodebookFilterDefinition, EnumFilterDefinition, FilterItemValue, FilterListOption} from './filter.types';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {hashed, serializeParamsToQueryString} from '../../lib/utils';
import {
  FILTER_PARAM_NAME,
  FULLTEXT_SEARCH_TERM_PARAM_NAME,
  SearchParamsSerializationService,
  SORT_TERM_PARAM_NAME
} from '../../services/search-params-serialization.service';
import {HistoryService} from '../../services/history.service';
import {ModalDialogComponent} from '../dialogs/modal-dialog/modal-dialog.component';
import {MessageType, ToastService} from '../notifications/toast.service';
import {MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {DialogService} from '../../core/services/dialog.service';
import {
  EMPTY_FILTER_TREE,
  FilterItemTree,
  filterItemTreeToFilterItemValueTree,
  FilterParamTree,
  FilterTreeOperator,
  isFilterTreeEmpty,
  isSimpleQueryFilterTree,
  viewFilterTreeToDataFilterTree
} from './filter-trees.utils';
import {StatisticsDimension} from '|api/elastic';

export function getDefaultTableConfig(): TableConfig<never> {
  return {
    hasActiveRow: false,
    hidePageSize: true,
    defaultFilterColumns: [],
    defaultSort: null,
    loadDataOnInitOrDatasourceChange: true,
    hoverableRows: true,
    rowHeight: 36,
    autoPageSizeConfig: true,
    allowMultiPageSelection: true,
    disableLocalStorageSortPersistence: false,
    toolbarConfig: getDefaultTableToolbarConfig(),
  };
}

export function extendDefaultTableConfig<TColumnKey extends string>(configExtensions: TableConfigExtensions<TColumnKey>): TableConfig<TColumnKey> {
  const defaultConfig = getDefaultTableConfig();

  return {
    ...defaultConfig,
    ...configExtensions,
    toolbarConfig: {
      ...defaultConfig.toolbarConfig,
      ...(configExtensions.toolbarConfig ?? {})
    }
  };
}

export function createTabTableId(prefix: string) {
  return prefix + '-table';
}

// upper limit for page size set
// due to performance reasons
const PAGE_SIZE_LIMIT = 200;
// page size determined at runtime should
// be a multiple of this number
const ROW_COUNT_MULTIPLE = 1;
// a single constant for the height of
// paginator, table toolbar
const TABLE_TOOLBAR_HEIGHT = 52;
// height of <th> element in table copied from $table-th-height
const TABLE_HEADER_HEIGHT = 42;
const NO_COLUMN = '';

const ROW_ID_COMPARATOR = (a: TableRow, b: TableRow) => a.id === b.id;


@Component({
  selector: 'icz-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  providers: [
    {
      provide: MatPaginatorIntl,
      useClass: CustomPaginator,
    },
    SelectedRowsService,
    TableToolbarService,
  ],
  animations: [
    trigger('rowExpandAnimation', [
      transition(':enter', [
        style({height: 0, opacity: 0}),
        animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({height: '*', opacity: 1})),
      ]),
      transition(':leave', [
        style({height: '*', opacity: 1}),
        animate('150ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({height: 0, opacity: 0})),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent<TColumnKey extends string> implements OnInit, AfterViewInit, AfterContentInit, IczOnChanges, DoCheck {

  tableToolbarService = inject(TableToolbarService);
  selectedRowsService = inject(SelectedRowsService);
  private translate = inject(TranslateService);
  protected userSettingsService = inject(UserSettingsService);
  protected router = inject(Router);
  private detachingService = inject(DetachingService);
  protected cd = inject(ChangeDetectorRef);
  private ngZone = inject(NgZone);
  private destroyRef = inject(DestroyRef);
  private searchParamsSerializationService = inject(SearchParamsSerializationService);
  private activatedRoute = inject(ActivatedRoute);
  private historyService = inject(HistoryService);
  private toastService = inject(ToastService);
  private dialogService = inject(DialogService);
  private modalComponent = inject(ModalDialogComponent, {optional: true});

  readonly TableReservedColumns = TableReservedColumns;
  readonly MAT_HEADER_ID = 'matheader';
  readonly SELECTION_COLUMN_WIDTH = 56; // For desired 20px padding left and right + 16px checkbox itself
  readonly COLUMN_WIDTH_FALLBACK = 100;

  @Input({required: true}) id!: string;
  @Input({required: true}) columnsData: Nullable<TableColumnsData<TColumnKey|TableReservedColumns>>;
  @Input() preselectedRows: Nullable<any[]>;
  @Input() disabledRows: any[] = [];
  @Input() expandedRow!: any;
  @Input() config: TableConfig<TColumnKey> = getDefaultTableConfig();
  // array of column IDs which will be filterable. null means "enable all filters".
  @Input() enabledFilters: Nullable<string[]>;
  @Input() rowComparator: RowComparatorFn<any> = ROW_ID_COMPARATOR;
  @Input() rowSelectionDisabler: Nullable<RowSelectionDisableFn<any>>;
  @Input() rowDisabler: Nullable<RowDisableFn<any>>;
  @Input() searchTerm: string = '';
  @Input() isNonBlockingLoading = false;
  @Input() disabledRowTooltip: Nullable<string>;
  @Input() enableContextMenu = true;
  @Input() enableGlobalSelectAll = false;
  @Input() allowedDimensions: Nullable<Array<StatisticsDimension>>;
  @Input() initialDimensions: Nullable<Array<StatisticsDimension>>;
  @Input() disableUrlParameters = false;
  @Input() allowSavingFiltersWithEmptyValues = true;

  @Output() searchTermChange = new EventEmitter<string>();
  @Output() clicked = new EventEmitter<any>();
  @Output() sorted = new EventEmitter<Nullable<Sort>>();
  @Output() loaded = new EventEmitter<void>(); // called multiple times
  @Output() initialized = new EventEmitter<this>(); // called once
  @Output() pageChanged = new EventEmitter<PageEvent>();
  @Output() autoFitClicked = new EventEmitter<void>();
  @Output() reload = new EventEmitter<void>();
  @Output() resized = new EventEmitter<void>();
  @Output() dragged = new EventEmitter<void>();
  @Output() activeRowChanged = new EventEmitter<any>();
  @Output() selectedRowsChanged = new EventEmitter<any>();
  @Output() searchParamsSelected = new EventEmitter<SearchParams>();
  @Output() dimensionsChange = new EventEmitter<Array<StatisticsDimension>>();

  @Output() pageLoad = new EventEmitter<SearchParams>(true);
  @Input() rowClick!: (row: any) => any;
  @Input({required: true}) dataSource: Nullable<IczTableDataSource<any>>;
  @Input() manualRowCount: Nullable<number>; // manual override for user defined row count
  @Input() hideTableToolbar = false;

  sortFields: SortParam<TColumnKey>[] = [];
  filterParams: FilterParamTree = EMPTY_FILTER_TREE;
  activeFilters: FilterItemTree = EMPTY_FILTER_TREE;
  defaultColumnDefinitions: ColumnDefinition<TColumnKey>[] = [];

  hoveredHeader: Nullable<string>;
  customTableCells: TableTemplates = {};
  isResizing = false;
  resizedColumn = NO_COLUMN;
  tableWidthIsAutomatic$ = new BehaviorSubject(true);
  resizeLineBounds: ResizeLineBounds = {x: 0, y: 0, height: 0};

  selectedRowsText!: string;
  allSelectedText!: string;
  selectAllText!: string;
  selectedRows: Array<{ id: number }> = [];
  isTableInitialized = false;
  isGlobalSelected = false;

  // Column ID -> CSS Width Value
  columnWidths: Record<string, string> = {};
  tableWidth: string = 'auto';

  showContextMenu$ = new BehaviorSubject(false);
  toolbarOpened = false;

  @ViewChild(TablePaginatorComponent, {static: true})
  tablePaginator!: TablePaginatorComponent;

  @ViewChild(MatSort, {static: true})
  matSort!: MatSort;

  @ViewChild('tableContainerEl', {read: ElementRef, static: false})
  tableContainerEl!: ElementRef;

  @ViewChild('tableEl', {read: ElementRef, static: false})
  tableEl!: ElementRef;

  @ViewChild(TableToolbarComponent)
  tableToolbar!: TableToolbarComponent;

  @ContentChildren(ColumnTemplateDirective)
  private columnsTemplates!: QueryList<ColumnTemplateDirective<any, any>>;

  @ContentChild('iczExpandedRowTemplate')
  expandedRowTemplate!: TemplateRef<any>;

  currentSort: Nullable<Sort>;

  hasNoData$!: Observable<boolean>;
  emptyDataSubsetFound$!: Observable<boolean>;

  tablePageRowCount!: number;
  rowSelectionDisablingFnChanges$: BehaviorSubject<Nullable<RowSelectionDisableFn>> = new BehaviorSubject<Nullable<RowSelectionDisableFn>>(null);

  globalSelectionTextInfo = 'Jsou vybrané všechny záznamy (celkem {{pageCount}}) na této stránce.';
  globalSelectionTextLink = 'Vybrat všechny nalezené záznamy (celkem {{totalCount}})';
  globalSelectionSelectedtext = 'Vybrané všechny nalezené záznamy (celkem {{totalCount}})';

  rowSelectionSub = new Subscription();
  activeRowSub = new Subscription();
  filterOrSortChangeSub = new Subscription();
  itemsSub = new Subscription();

  // used for manual list$ changes handling
  asyncListObservables: Record<string, Observable<FilterListOption[]>> = {};
  listLoadingStatesSubscription: Nullable<Subscription>;

  dataSourceConnectSubscription: Nullable<Subscription>;

  queryParamsSubscription: Nullable<Subscription>;

  filterChanged$ = this.pageLoad.pipe(
    map(searchParams => searchParams.filter),
    distinctUntilChanged(isEqual),
  );

  get listLoadingStates() {
    return this.columnsData!.listLoadingStates;
  }

  get maxContextMenuHeight(): number {
    return TABLE_TOOLBAR_HEIGHT + this.getContentSpaceHeight(this.config.autoPageSizeConfig);
  }

  get showGlobalSelection() {
    return this.enableGlobalSelectAll &&
      this.selectedRowsService.isAllOnPageSelected() &&
      (this.dataSource!.paginator!.pageSize < this.dataSource!.paginator!.length);
  }

  get tableSchemaVersionHash(): string {
    if (this.columnsData) {
      const columnPropertiesForHashing = this.columnsData.columnDefinitions.map(
        cd => `${cd.id}${cd.filterConfig?.customFilterId}${cd.filterType}${cd.disableSort}`
      );
      columnPropertiesForHashing.sort();
      return hashed({hash: columnPropertiesForHashing.join(',')});
    }
    else {
      return 'unknown';
    }
  }

  onColumnSettingsClicked() {
    this.openContextMenu(null);
  }

  private getSortId(sort: Sort): string {
    const customId = this.columnsData?.columnDefinitions?.find(def => def.id === sort.active && def.filterConfig?.customFilterId)?.filterConfig?.customFilterId;
    return Boolean(customId) ? customId! : sort.active;
  }

  private loadDisplayedColumnsFromLocalStorage(propertiesFromLocalStorage: Nullable<PersistedUserTableProperties>) {
    const columnsFromLocalStorage = propertiesFromLocalStorage?.columns;
    if (!columnsFromLocalStorage?.length) {
      this.tableWidthIsAutomatic$.next(true);
      return;
    }
    const originalColumnDefinitions = this.columnsData!.columnDefinitions;

    try {
      if (columnsFromLocalStorage.length > 0) {
        const firstColumn = columnsFromLocalStorage[0];
        if (!('id' in firstColumn) || !('isVisible' in firstColumn)) {
          throw new Error('Incompatible saved column local storage data found.');
        }
      }
      const filteredLocalStorageColumns =
        columnsFromLocalStorage.filter(lsc => this.columnsData!.columnDefinitions.some(cd => cd.id === lsc.id));

      this.columnsData!.displayedColumns = filteredLocalStorageColumns.filter(p => p.isVisible).map(p => p.id);
      this.columnsData!.columnDefinitions = this.columnsData!.columnDefinitions.map(c => {return {...c, displayed: false};});
      this.columnsData!.displayedColumns.forEach((columnId, index) => {
        const oldIndex = this.columnsData!.columnDefinitions.findIndex(cDef => cDef.id === columnId);
        const columnDef = this.columnsData!.columnDefinitions[oldIndex];
        columnDef.displayed = true;
        moveItemInArray(this.columnsData!.columnDefinitions, oldIndex, index);
      });

      const someColumnsHaveDefinedWidth = filteredLocalStorageColumns.some(c => !isNil(c.columnWidth));
      this.tableWidthIsAutomatic$.next(!someColumnsHaveDefinedWidth);
    }
    catch {
      this.columnsData!.columnDefinitions = originalColumnDefinitions;

      if (propertiesFromLocalStorage) {
        delete propertiesFromLocalStorage.columns;
        this.userSettingsService.saveTableProperties(this.id, propertiesFromLocalStorage);
      }
    }
  }

  private loadSortFromLocalStorage(propertiesFromLocalStorage: Nullable<PersistedUserTableProperties>) {
    if (!this.config.disableLocalStorageSortPersistence) {
      const sortedColumn = propertiesFromLocalStorage?.sort;

      if (sortedColumn) {
        this.currentSort = {
          active: sortedColumn.sortColumnId,
          direction: sortedColumn.sortDirection!,
        };
      }
    }
  }

  private loadPropertiesFromLocalStorage() {
    let propertiesFromLocalStorage = this.userSettingsService.getTableProperties(this.id);

    if (propertiesFromLocalStorage) {
      if (propertiesFromLocalStorage.tableSchemaVersionHash !== this.tableSchemaVersionHash) {
        this.userSettingsService.deleteCustomPropertiesForTable(this.id);
        propertiesFromLocalStorage = null;
      }
    }

    this.loadDisplayedColumnsFromLocalStorage(propertiesFromLocalStorage);
    this.loadSortFromLocalStorage(propertiesFromLocalStorage);
  }

  private saveTableColumnsToLocalStorage() {
    this.ngZone.onMicrotaskEmpty.pipe(
      take(1),
    ).subscribe(() => {
      const previousProperties = this.userSettingsService.getTableProperties(this.id);
      const previousColumns = previousProperties?.columns ?? [];
      const currentColumns: PersistedColumnProperties[] = this.columnsData!.columnDefinitions.map(cd => ({
        id: cd.id,
        isVisible: this.columnsData!.displayedColumns.includes(cd.id),
        columnWidth: (
          this.getColumnElementsByColumnId(cd.id)?.[0]?.offsetWidth ??
          previousColumns.find(p => p.id === cd.id)?.columnWidth
        ),
        sortDirection: this.currentSort?.active === cd.id ? this.currentSort?.direction : undefined,
      }));

      let valueToSave: PersistedUserTableProperties;

      if (previousProperties) {
        valueToSave = {
          ...previousProperties,
          columns: currentColumns,
        };
      }
      else {
        valueToSave = {
          tableSchemaVersionHash: this.tableSchemaVersionHash,
          sort: null,
          columns: currentColumns,
        };
      }

      this.userSettingsService.saveTableProperties(this.id, valueToSave);
    });
  }

  clearSettingsInLocalStorage() {
    this.userSettingsService.saveTableProperties(
      this.id,
      {
        tableSchemaVersionHash: this.tableSchemaVersionHash,
        sort: null,
        columns: null,
      }
    );
  }

  onHeaderMouseover(columnId: string) {
    this.hoveredHeader = columnId;
  }

  onHeaderMouseleave() {
    this.hoveredHeader = null;
  }

  onResizeStart(event: ResizeEvent, columnId: string): void {
    const col = this.columnsData!.columnDefinitions.find(c => c.id === columnId);
    if (!col || col.fixedWidth) return;
    // resizeStart is triggered on all columns that will change size, not just the user origin. Hence storing resizedColumn name only
    // if resizing isn't in progress yet
    if (!this.isResizing) this.resizedColumn = columnId;
    this.isResizing = true;
  }

  onResizing(event: ResizeEvent, columnId: string) {
    const resizedColHeadEl = this.getColumnElementsByColumnId(columnId);
    const tableContainerEl = this.tableEl.nativeElement.parentNode;
    if (!event?.rectangle || !resizedColHeadEl?.length || !tableContainerEl) return;

    const headerRect = resizedColHeadEl[0].getBoundingClientRect();
    const tableContainerRect = tableContainerEl.getBoundingClientRect();

    this.resizeLineBounds.x = headerRect?.x! + event.rectangle.width!;
    this.resizeLineBounds.y = headerRect?.y!;
    this.resizeLineBounds.height = tableContainerRect?.height!;
  }

  selectAllItemsByTotalCount() {
    this.isGlobalSelected = true;
    this.searchParamsSelected.emit(this.getCurrentSearchParams());
  }

  clearSelection() {
    this.isGlobalSelected = false;
    this.selectedRowsService.deselectAll();
  }

  firstTimeSetTableToManual() {
    this.columnsData!.columnDefinitions.forEach(cd => {
      if (cd.displayed) this.setTableColumnWidth(cd, this.getColumnElementsByColumnId(cd.id)?.[0]?.offsetWidth);
    });
    this.tableWidthIsAutomatic$.next(false);
    this.saveTableColumnsToLocalStorage();
  }

  onResizeEnd(event: ResizeEvent, columnId: string): void {
    this.detachingService.reattach();

    // let's not do anything if resize handle was clicked without moving the mouse
    if (event.edges.right === 0 || event.edges.left === 0) {
      this.isResizing = false;
      this.resizedColumn = NO_COLUMN;
      return;
    }

    if (this.tableWidthIsAutomatic$.getValue()) {
     this.firstTimeSetTableToManual();
    }

    this.ngZone.onMicrotaskEmpty.pipe(
      take(1)
    ).subscribe(() => {
      // otherwise, do the resizing
      if (event.edges.right) {
        const col = this.columnsData!.columnDefinitions.find(c => c.id === columnId);
        if (!col || col.fixedWidth) return; // do not attempt to resize if fixedWidth
        this.setTableColumnWidth(col, event.rectangle.width!);
        this.setTableWidth({columnId, newWidth: event.rectangle.width!});
      }
      this.isResizing = false;
      this.resizedColumn = NO_COLUMN;
      this.tableWidthIsAutomatic$.next(false);
      this.resized.emit();
      this.resizeLineBounds = {x: 0, y:0, height: 0};
      this.saveTableColumnsToLocalStorage();
    });
  }

  private setTableWidth(newlyResizedCol?: {columnId: string, newWidth: number}) {
    const columnsFromLocalStorage = this.userSettingsService.getTableProperties(this.id)?.columns;
    if (!columnsFromLocalStorage?.length) {
      return;
    }

    let tableWidth = 0;
    columnsFromLocalStorage.forEach(storedColumn => {
      if (this.columnsData!.displayedColumns.includes(storedColumn.id)) {
        tableWidth += storedColumn.columnWidth ?? 0;
      }
    });

    // If setting new table width is done *because of* user resize, the affected column saved width needs to be discarded and new width added
    if (newlyResizedCol) {
      const storedCol = columnsFromLocalStorage.find(c => c.id === newlyResizedCol.columnId);
      tableWidth -= storedCol!.columnWidth!;
      tableWidth += newlyResizedCol.newWidth;
    }

    this.tableWidth = `${tableWidth}px`;
    this.cd.detectChanges();
  }

  private setTableColumnWidth(col: ColumnDefinition<string>, newWidth: Nullable<number>) {
    if (newWidth) {
      newWidth = newWidth ?? this.COLUMN_WIDTH_FALLBACK;
      let cssValue = `${newWidth}px`;

      if (col.maxWidth && newWidth > col.maxWidth) {
        cssValue = `${col.maxWidth}px`;
      }
      if (col.minWidth && newWidth < col.minWidth) {
        cssValue = `${col.minWidth}px`;
      }

      this.columnWidths[col.id] = cssValue;
    } else {
      if (col.fixedWidth) {
        this.columnWidths[col.id] = `${col.fixedWidth}px`;
      } else if (this.tableWidthIsAutomatic$.value) {
        this.columnWidths[col.id] = 'auto';
      } else {
        this.columnWidths[col.id] = `${this.COLUMN_WIDTH_FALLBACK}px`;
      }
    }

    this.cd.detectChanges();
  }

  private getColumnElementsByColumnId(columnId: string): HTMLElement[] {
    return Array.from(this.tableEl.nativeElement.querySelectorAll(
      `.mat-column-${columnId.replace('.', '-')}`)) as HTMLElement[];
  }

  toggleColumn(columnId: string) {
    if (this.tableWidthIsAutomatic$.value) {
      this.firstTimeSetTableToManual();
    }

    this.ngZone.onMicrotaskEmpty.pipe(
      take(1)
    ).subscribe(() => {
      const col = this.columnsData!.columnDefinitions.find(c => c.id === columnId);
      if (!col) return;
      col.displayed = !col.displayed;
      this.setDisplayedColumns();
      this.dragged.emit();

      this.ngZone.onMicrotaskEmpty.pipe(
        take(1)
      ).subscribe(() => {
        this.recoverColumnWidthsFromLocalStorage();
        this.saveTableColumnsToLocalStorage();
      });
    });
  }

  resetUserAdjustmentsToDefault() {
    this.columnsData!.columnDefinitions = this.cloneColumnDefinitions(this.defaultColumnDefinitions)
      .map(columnDefinition => ({
        ...columnDefinition,
        list: (this.columnsData!.columnDefinitions.find(
          cd => cd.id === columnDefinition.id
        ) as EnumFilterDefinition | CodebookFilterDefinition).list,
      }));
    this.setDisplayedColumns();

    this.tableWidthIsAutomatic$.next(true);

    this.ngZone.onMicrotaskEmpty.pipe(
      take(1)
    ).subscribe(() => {
      for (const displayedColumnId of this.columnsData!.displayedColumns) {
        const displayedColumnDef = this.columnsData!.columnDefinitions.find(cd => cd.id === displayedColumnId)!;
        this.setTableColumnWidth(displayedColumnDef, null);
      }

      this.sortChanged(
        this.config.defaultSort ?
          {
            active: this.config.defaultSort.fieldName,
            direction: this.config.defaultSort.descending ? 'desc' : 'asc'
          } :
          null
      );

      this.clearSettingsInLocalStorage();
    });
  }

  setDisplayedColumns() {
    this.columnsData!.displayedColumns = this.columnsData!.columnDefinitions.filter(c => c.displayed).map(c => c.id);
  }

  dropListDropped(event: CdkDragDrop<HTMLElement, HTMLElement>) {
    const hasSelectionColumn = this.columnsData!.columnDefinitions.find(c => c.id === TableReservedColumns.SELECTION);
    const shiftIndex = hasSelectionColumn ? 1 : 0;
    const getActualPreviousIndex = () => {
      const columnId = this.columnsData!.displayedColumns[event.previousIndex + shiftIndex];
      return this.columnsData!.columnDefinitions.findIndex(c => c.id === columnId);
    };

    const getActualIndexOfDroppedInto = () => {
      const columnId = this.columnsData!.displayedColumns[event.currentIndex + shiftIndex];
      return this.columnsData!.columnDefinitions.findIndex(c => c.id === columnId);
    };

    if (event) {
      moveItemInArray(this.columnsData!.columnDefinitions, getActualPreviousIndex(), getActualIndexOfDroppedInto());
      this.setDisplayedColumns();
      this.recoverColumnWidthsFromLocalStorage();
      this.saveTableColumnsToLocalStorage();
    }
    this.dragged.emit();
  }

  private recoverColumnWidthsFromLocalStorage() {
    const columnsFromLocalStorage = this.userSettingsService.getTableProperties(this.id)?.columns ?? [];

    this.columnsData!.displayedColumns.forEach(columnId => {
      const columnDef = this.columnsData!.columnDefinitions.find(cDef => cDef.id === columnId);
      const savedColumnWidth = columnsFromLocalStorage.find(p => p.id === columnId)?.columnWidth ?? null;

      if (columnDef) {
        this.setTableColumnWidth(columnDef, savedColumnWidth);
      }
    });
    this.setTableWidth();
  }

  private reinitDatasource() {
    this.dataSource!.paginator = this.tablePaginator.paginator;
    this.initSelectedRowsService();
    this.applyPreselection();
    this.initDataStatusObservables();
    this.selectedRowsService.deselectAll();
    this.autoloadDataIfPossible();
  }

  ngOnInit() {
    this.translate.onLangChange.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.getTranslations();
    });

    if (this.manualRowCount) {
      this.tablePageRowCount = this.manualRowCount;
    } else {
      this.tablePageRowCount = this.userSettingsService.getTablePageRowCount();
    }

    this.getTranslations();

    if (this.columnsData && this.dataSource) {
      this.defaultColumnDefinitions = this.cloneColumnDefinitions(this.columnsData.initialDefinitions);

      if (this.columnsData.columnDefinitions.length === 0) {
        console.warn('There are 0 definitions of table columns');
      }

      this.loadAsyncColumnLists();
      this.loadPropertiesFromLocalStorage();
      this.connectDatasourceStateToPaginator();

      this.listLoadingStatesSubscription = this.columnsData!.listLoadingStatesChange$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.cd.detectChanges());

      this.initializeTableToolbar();

      this.loadFiltersAndSort();
      this.setUpSort();
      this.pageChanged.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_page => {
        this.loadPage();
        this.tableContainerEl.nativeElement.scrollTop = 0;
        this.selectedRowsService.unsetActiveRow();
      });

      this.sorted.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(sort => {
        if (!sort) {
          this.sortFields = [];
        }
        else {
          if (sort.direction !== '') {
            // If columnDefinition has customId for filter, this ID should be used also for sorting

            this.sortFields = [
              {
                fieldName: this.getSortId(sort) as TColumnKey,
                descending: sort.direction === 'desc',
              },
            ];
          }
          // default sort should be overridable using sort indicator arrow
          else if (sort.active !== this.config.defaultSort?.fieldName) {
            this.setUpSort();
          }
        }

        this.loadPage();
        this.selectedRowsService.deselectAll();
      });

      this.tableToolbarService.reloadFilters$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => {
        this.reloadTable();
        this.cd.detectChanges();
      });

      this.initDataStatusObservables();
    } else {
      throw new Error('@Input columnsData or @Input dataSource were not supplied to icz-table.');
    }
  }

  ngDoCheck() {
    if (this.columnsData) {
      const columnIdsWithListsToReload: string[] = [];

      for (const column of this.columnsData.columnDefinitions) {
        if ((column as CodebookFilterDefinition | EnumFilterDefinition).list$ !== this.asyncListObservables[column.id]) {
          columnIdsWithListsToReload.push(column.id);
        }
      }

      if (columnIdsWithListsToReload.length) {
        this.loadAsyncColumnLists(columnIdsWithListsToReload);
      }
    }
  }

  private loadFiltersAndSort() {
    if (
      this.modalComponent ||
      this.activatedRoute.snapshot.data?.fallbackBreadcrumbUri ||
      this.activatedRoute.snapshot.data?.skipHistoryBit ||
      this.disableUrlParameters
    ) return; // do not interact with url parameters when table is in modal or at a route without dedicated history bit or has a configuration input flipped to true

    this.tableToolbarService.filters$.pipe(take(1)).subscribe(filters => {
      this.queryParamsSubscription?.unsubscribe();
      this.queryParamsSubscription = this.activatedRoute.queryParams.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(queryParams => {
        let hasFilters = false;

        if (FILTER_PARAM_NAME in queryParams || FULLTEXT_SEARCH_TERM_PARAM_NAME in queryParams) {
          if (FILTER_PARAM_NAME in queryParams) {
            try {
              const deserializedQuery = this.searchParamsSerializationService.deserializeFilterValuesFromString(queryParams[FILTER_PARAM_NAME]);

              if (deserializedQuery) {
                if (!isFilterTreeEmpty(deserializedQuery)) {
                  this.tableToolbarService.clearAllFilters();

                  if (isSimpleQueryFilterTree(deserializedQuery)) {
                    for (const paramValue of (deserializedQuery.values as FilterItemValue[])) {
                      this.tableToolbarService!.addItemValue(paramValue);
                    }
                  }
                  else {
                    this.tableToolbarService.addTreeItems(deserializedQuery);
                  }

                  hasFilters = true;
                }
              }
            }
            catch {
              this.dialogService.showError('Došlo k chybě při čtení vyhledávacího výrazu z adresy v prohlížeči. Vyhledávání nebude provedeno.');
            }
          }

          if (FULLTEXT_SEARCH_TERM_PARAM_NAME in queryParams) {
            const searchTermFromUrl = queryParams[FULLTEXT_SEARCH_TERM_PARAM_NAME] as string;
            if (searchTermFromUrl.length < MIN_SEARCH_TERM_LENGTH) {
              this.toastService.dispatchToast({
                type: MessageType.ERROR,
                data: {
                  header: {
                    template: 'Pro vyhledávání musíte zadat alespoň dva znaky.',
                  }
                }
              });
            } else {
              this.searchTerm = searchTermFromUrl;
              hasFilters = true;
            }
          }
        } else {
          const savedFilterScope = this.id;
          const allSavedFilters = this.userSettingsService.getSavedFilters();

          if (allSavedFilters && allSavedFilters.hasOwnProperty(savedFilterScope)) {
            try {
              Object.keys(allSavedFilters[savedFilterScope]).forEach(k => {
                if (allSavedFilters[savedFilterScope][k].isDefault) {

                  this.tableToolbarService.clearAllFilters();

                  if (isSimpleQueryFilterTree(allSavedFilters[savedFilterScope][k])) {
                    for (const paramValue of (allSavedFilters[savedFilterScope][k].values as FilterItemValue[])) {
                      this.tableToolbarService!.addItemValue(paramValue);
                    }
                  }
                  else {
                    this.tableToolbarService.addTreeItems(allSavedFilters[savedFilterScope][k]);
                  }
                  hasFilters = true;
                }
              });
            } catch {
              throw new Error(`Saved filters loading failed.`);
            }
          }
        }

        if (SORT_TERM_PARAM_NAME in queryParams) {
          const sortTermFromUrl = queryParams[SORT_TERM_PARAM_NAME] as string;
          const sortTermFromUrlSplit = sortTermFromUrl.split(',');
          if (sortTermFromUrlSplit.length !== 2) {
            throw new Error('Parameter for sort loaded from url is malformed.');
          } else {
            if (sortTermFromUrlSplit[1] === 'asc' || sortTermFromUrlSplit[1] === 'desc') {
              const parsedSort = {active: sortTermFromUrlSplit[0], direction: sortTermFromUrlSplit[1] as SortDirection};
              if (this.validateSortWithColumnData(parsedSort)) {
                this.sortChanged(parsedSort, true);
                this.setUpSort();
              }
            }
          }
        }

        if (hasFilters) {
          this.tableToolbarService.activeFilters$.pipe(
            take(1),
          ).subscribe(activeFilters => {
            this.filterParams = viewFilterTreeToDataFilterTree(activeFilters);
            this.tableToolbarService!.reloadFilters();
            this.toolbarOpened = true;
          });
        }
      });
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.columnsData && changes.columnsData.previousValue && changes.columnsData.currentValue) {
      this.listLoadingStatesSubscription?.unsubscribe();
      this.asyncListObservables = {};

      this.columnsData!.columnDefinitions.forEach(column => {
        if (column.id === TableReservedColumns.SELECTION) column.fixedWidth = this.SELECTION_COLUMN_WIDTH;
      });
      if (this.columnsData!.columnDefinitions.some(cd => cd.allowSettingInContextMenu === false && cd.displayed === true)) {
        throw new Error('Table column configuration error. Column that is displayable must be configurable in context menu as well.');
      }

      this.defaultColumnDefinitions = this.cloneColumnDefinitions(this.columnsData!.initialDefinitions);

      this.loadAsyncColumnLists();

      this.listLoadingStatesSubscription = this.columnsData!.listLoadingStatesChange$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.cd.detectChanges());

      this.initializeTableToolbar();
    }

    if (changes.dataSource && changes.dataSource.previousValue && changes.dataSource.currentValue) {
      this.connectDatasourceStateToPaginator();
      this.clearSearchParams();
      this.toolbarOpened = false;
      this.initializeTableToolbar();
      this.loadPropertiesFromLocalStorage();
      this.loadFiltersAndSort();
      this.setUpSort();
      this.reinitDatasource();
    }

    if ((changes.id && changes.id.previousValue && changes.id.currentValue) || (changes.columnsData && changes.columnsData.previousValue && changes.columnsData.currentValue)) {
      this.columnWidths = {};
      this.tableWidth = 'auto';
      this.toolbarOpened = false;
      this.clearSearchParams();
      if (this.isTableInitialized) this.recoverColumnWidthsFromLocalStorage();
      this.initializeTableToolbar();
      this.loadPropertiesFromLocalStorage();
      this.loadFiltersAndSort();
    }

    if (changes.preselectedRows && !isEqual(changes.preselectedRows.currentValue, changes.preselectedRows.previousValue)) {
      this.applyPreselection();
    }

    if (changes.rowSelectionDisabler) {
      this.rowSelectionDisablingFnChanges$.next(changes.rowSelectionDisabler.currentValue);
      this.initSelectedRowsService();
      this.applyPreselection();
    }
  }

  private initDataStatusObservables() {
    this.hasNoData$ = combineLatest([
      this.tableToolbarService.activeFilters$,
      this.dataSource!.items$,
    ]).pipe(
      map(([activeFilters, items]) => !items.length && !this.dataSource!.paginator?.length && isFilterTreeEmpty(activeFilters)),
    );

    this.emptyDataSubsetFound$ = combineLatest([
      this.tableToolbarService.activeFilters$,
      this.dataSource!.items$,
    ]).pipe(
      map(([activeFilters, items]) => !items.length && !this.dataSource!.paginator?.length && !isFilterTreeEmpty(activeFilters)),
    );
  }

  private validateSortWithColumnData(sort: Sort) {
    if (this.columnsData && this.columnsData.columnDefinitions) {
      const columnForSort = this.columnsData.columnDefinitions.find(column => column.id === sort.active);
      return Boolean(columnForSort && !columnForSort.disableSort);
    } else {
      return false;
    }
  }

  private setUpSort() {
    if (this.matSort && (this.config?.defaultSort || this.currentSort)) {
      this.matSort.active = 'false';

      if (this.currentSort) {
        this.matSort.sort({
          id: this.currentSort.active,
          start: this.currentSort.direction,
        } as MatSortable);

        this.sortFields = [
          {
            fieldName: this.getSortId(this.currentSort) as TColumnKey,
            descending: this.currentSort.direction === 'desc',
          }
        ];
      }
      else if (this.config.defaultSort) {
        const sortColumnDefinition = this.columnsData!.columnDefinitions.find(cd => cd.id === this.config.defaultSort!.fieldName);
        if (sortColumnDefinition && !sortColumnDefinition.disableSort) {
          this.matSort.sort({
            id: this.config.defaultSort.fieldName,
            start: this.config.defaultSort.descending ? 'desc' : 'asc',
          } as unknown as MatSortable);

          this.sortFields = [
            {
              ...this.config.defaultSort,
            }
          ];
        }
      }
    }
  }

  private applyPreselection() {
    if (this.selectedRowsService.isInitialized) {

      if (this.preselectedRows?.length) {
        this.selectedRowsService.selectMany(this.preselectedRows, true);
      }
    }
  }

  getContentSpaceHeight(specifier: AutoPageSizeConfig): number {
    let componentTopOffset = 0;
    let componentBottomOffset = 0;

    if (this.isAutoPageSizeByOffsets(specifier)) {
      componentTopOffset = specifier.screenTopOffset;
      componentBottomOffset = specifier.screenBottomOffset;
    }

    return window.innerHeight
      - componentBottomOffset
      - componentTopOffset
      - this.getNonContentTableSpaceHeight(specifier);
  }

  ngAfterViewInit(): void {
    if (this.dataSource) {
      this.dataSource.paginator!.pageSize = this.getTablePageSize(this.config.autoPageSizeConfig);
    }

    this.autoloadDataIfPossible();

    this.recoverColumnWidthsFromLocalStorage();
    this.loaded.next();
    this.initialized.emit(this);
    this.isTableInitialized = true;
  }

  ngAfterContentInit() {
    this.columnsTemplates.toArray().reduce((dic, template) => {
      dic[template.id] = {
        template: template.content,
        withEllipsis: template.withEllipsis as boolean,
      };
      return dic;
    }, this.customTableCells);

    if (this.dataSource) {
      this.dataSource.paginator = this.tablePaginator.paginator;
    }

    this.initSelectedRowsService();
  }

  private autoloadDataIfPossible() {
    if (this.config.loadDataOnInitOrDatasourceChange) {
      if (this.tableToolbarService.filters$.value.length > 0) {
        this.reloadTable();
      } else this.loadPage();
    }
  }

  private getTablePageSize(autoPageSizeSpecifier: true | AutoPageSizeByOffsets | AutoPageSizeByManualHeight) {
    let pageSize: number;

    if (this.tablePageRowCount) {
      return this.tablePageRowCount;
    }
    else {
      if (this.isAutoPageSizeByManualHeight(autoPageSizeSpecifier)) {
        pageSize = this.getPageSizeByContentHeight(
          autoPageSizeSpecifier.tableHeight -
          this.getNonContentTableSpaceHeight(autoPageSizeSpecifier)
        );
      }
      else {
        pageSize = this.getPageSizeByScreenHeight(autoPageSizeSpecifier);
      }

      return Math.min(
        Math.max(pageSize, ROW_COUNT_MULTIPLE - 1),
        PAGE_SIZE_LIMIT // row count is limited due to p4m reasons
      );
    }
  }

  private getPageSizeByScreenHeight(specifier: AutoPageSizeConfig) {
    const pageSize = this.getPageSizeByContentHeight(this.getContentSpaceHeight(specifier));

    return pageSize;
  }

  private getNonContentTableSpaceHeight(specifier: AutoPageSizeConfig): number {
    // 3 * TABLE_TOOLBAR_HEIGHT because we have to count in:
    // - table toolbar (action buttons, table name, etc.),
    // - table data header - the first <tr> in <table>,
    // plus 1 px for material 16 paginator height correction
    let out = TABLE_TOOLBAR_HEIGHT + TABLE_HEADER_HEIGHT + 1;
    if (!this.dataSource?.disablePagination) {
      // - paginator at the bottom of the component
      out +=  TABLE_TOOLBAR_HEIGHT;
    }

    if (!this.isAutoPageSizeByOffsets(specifier)) {
      out += TABLE_TOOLBAR_HEIGHT; // for manual height/full-auto height inference
    }

    // If the filter toolbar is open on table initialization,
    // subtract its height from available space, too.
    if (this.config.toolbarConfig.autoOpenFilter) {
      out += TABLE_TOOLBAR_HEIGHT;
    }

    if (this.tableToolbar?.hasTabsContent) {
      out += TABLE_TOOLBAR_HEIGHT;
    }

    return out;
  }

  private getPageSizeByContentHeight(contentSpaceHeight: number) {
    const availableRowCount = contentSpaceHeight / this.config.rowHeight;

    // We want to have our page size as a multiple of a meaningful
    // number (5, 10, ... whatever). this code does exactly that.
    return (
      availableRowCount % ROW_COUNT_MULTIPLE === 0 ?
        availableRowCount : // It matches to row count multiple
        (Math.floor(availableRowCount / ROW_COUNT_MULTIPLE)
          * ROW_COUNT_MULTIPLE) + ROW_COUNT_MULTIPLE - 1 // it takes the nearest higher multiple of rows
    );
  }

  onRowClick(event: MouseEvent, row: TableRow, checkboxClick: boolean) {
    const allowSelection = this.columnsData!.columnDefinitions.find(c => c.id === TableReservedColumns.SELECTION);

    if (checkboxClick) event.stopPropagation();
    if (this.isDisabledRow(row) || this.isRowSelectionDisabled(row) || this.isRowDisabledByDisabler(row)) return; // do nothing if this row is disabled

    // todo(lp) note: multiple de/selection with SHIFT key only works if clicked exactly in checkbox, not via generousAreaClick()
    // single selection of row works fine both from checkbox and from generous click area
    if (checkboxClick && allowSelection && event.shiftKey) { // select multiple rows at once with SHIFT + click
      if (row.id == null) return; // better do anything if row data object has no id prop

      let rowsToSelect: any[] = [];
      const dataThisPage = this.dataSource!.data; // yes, contains data in actual displayed order after sorting, paging etc.
      // it's important to filter invalid indices and have the array sorted for further processing
      combineLatest([
        this.selectedRowsService.selectedRows$,
      ]).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(([selected]) => {
        this.isGlobalSelected = false;
        this.selectedRows = [...selected];
      });
      const selectedIndices = this.selectedRows
        .map(s => dataThisPage.findIndex(d => this.rowComparator(s, d)))
        .filter(f => f !== -1).sort();
      if (selectedIndices.length === 0) return; // selectedIndices can be empty if there are no selected rows, also if there are selected rows on different pages, we don't care

      const clickedIndex = dataThisPage.findIndex(d => this.rowComparator(row, d));
      const minSelected = Math.min(...selectedIndices);
      const maxSelected = Math.max(...selectedIndices);

      // if also CTRL key, unselect multiple rows with CTRL + SHIFT + click
      if (event.ctrlKey) {
        if (clickedIndex <= maxSelected) {
          rowsToSelect = dataThisPage.slice(minSelected, checkboxClick ? clickedIndex : clickedIndex + 1);
          if (rowsToSelect.length) this.selectedRowsService.deselectMany(rowsToSelect);
        }
        // else select more
      } else {
        // select rows above first selected
        if (clickedIndex < minSelected) {
          rowsToSelect = dataThisPage.slice(checkboxClick ? clickedIndex + 1 : clickedIndex, minSelected + 1);
        }
        // select rows below last selected
        else if (clickedIndex > maxSelected) {
          rowsToSelect = dataThisPage.slice(maxSelected, checkboxClick ? clickedIndex : clickedIndex + 1);
        }
        // select rows between first and last selected
        else if (clickedIndex > minSelected && clickedIndex < maxSelected) {
          const missingInRange = Array.from(Array(maxSelected - minSelected), (v, i) => i + minSelected).filter(i => !selectedIndices.includes(i));
          const relevant = missingInRange.filter(missing => missing <= clickedIndex);
          rowsToSelect = dataThisPage.filter((row, index) => relevant.indexOf(index) !== -1);
        }
        if (rowsToSelect.length) this.selectedRowsService.selectMany(rowsToSelect);
      }
    }
    else if (!checkboxClick) {
      this.selectedRowsService.toggleActiveRow(row);
      this.clicked.emit(row);
    }
  }

  private getTranslations() {
    this.translate.get([
      'Jsou vybrány všechny dokumenty (celkem x) na této stránce.',
      'Jsou vybrány všechny dokumenty (celkem x).',
      'Vybrat všechny dokumenty (celkem x)',
    ]).subscribe(translation => {
      this.selectedRowsText = translation['Jsou vybrány všechny dokumenty (celkem x) na této stránce.'];
      this.allSelectedText = translation['Jsou vybrány všechny dokumenty (celkem x).'];
      this.selectAllText = translation['Vybrat všechny dokumenty (celkem x)'];
    });
  }

  private loadPage() {
    this.dataSource!.items$.pipe(skip(1), take(1)).subscribe(_ => {
      // when page is loaded deselect all and apply preselection
      this.applyPreselection();
    });

    if (this.dataSource!.paginator) {
      this.pageLoad.emit(this.getCurrentSearchParams());
    }
  }

  searchTermChanged(searchTerm: string) {
    this.searchTerm = searchTerm || ' '; // this how elastic API accepts empty searchTerm query

    this.selectedRowsService.deselectAll();
    this.dataSource!.paginator!.firstPage();
    this.loadPage();

    this.searchTermChange.emit(this.searchTerm);
    this.patchHistoryBit();
  }

  openContextMenu(event: Nullable<MouseEvent>) {
    if (!this.enableContextMenu) return;
    if (event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
    this.showContextMenu$.next(true);
  }

  closeContextMenu() {
    this.showContextMenu$.next(false);
  }

  reloadTable() {
    const filtersFromToolbar = this.tableToolbarService.filters$.value.filter(
      f => (
        (f.filterOption?.value && f.value) ||
        f.filterOption?.value === FilterOperator.empty ||
        f.filterOption?.value === FilterOperator.notEmpty
      )
    );

    if (isSimpleQueryFilterTree(this.tableToolbarService.activeFilterValues$.value)) {
      this.tableToolbarService.activeFilterValues$.next(filterItemTreeToFilterItemValueTree({
        operator: FilterTreeOperator.NONE,
        values: filtersFromToolbar,
      }));
    }

    this.tableToolbarService.activeFilters$.pipe(
      take(1),
    ).subscribe(activeFilters => {
      this.filterParams = viewFilterTreeToDataFilterTree(activeFilters);
      this.activeFilters = activeFilters;

      this.patchHistoryBit();

      this.tablePaginator.setPage(0);
      this.loadPage();
    });
  }

  private patchHistoryBit() {
    if (
      this.modalComponent ||
      this.activatedRoute.snapshot.data?.fallbackBreadcrumbUri ||
      this.activatedRoute.snapshot.data?.skipHistoryBit ||
      this.disableUrlParameters
    ) return; // do not interact with url parameters when table is in modal or at a route without dedicated history bit or has a configuration input flipped to true

    let queryString = '';

    if (this.searchTerm && this.searchTerm !== ' ') {
      queryString = `${FULLTEXT_SEARCH_TERM_PARAM_NAME}=${encodeURIComponent(this.searchTerm)}`;
    }

    const filterValues = filterItemTreeToFilterItemValueTree(this.activeFilters);

    const queryParams: Params = {
      ...this.searchParamsSerializationService.createParamsFromSort(this.currentSort),
    };

    if (!isFilterTreeEmpty(filterValues)) {
      queryParams[FILTER_PARAM_NAME] = this.searchParamsSerializationService.serializeFilterValuesToString(filterValues);
    }

    const filterQueryString = serializeParamsToQueryString(queryParams, true);

    if (queryString === '') {
      queryString = filterQueryString;
    } else {
      if (filterQueryString) {
        queryString = `${queryString}&${filterQueryString}`;
      }
    }
    setTimeout(() => {
      this.historyService.patchHistoryBitWithSearchParams(queryString);
    },100);
  }

  clearSearchParams() {
    this.currentSort = null;
    this.sortFields = [];
    this.searchTerm = ' ';
    this.filterParams = EMPTY_FILTER_TREE;
    this.activeFilters = EMPTY_FILTER_TREE;
    this.tablePaginator.setPage(0);
  }

  isDisabledRow(row: any) {
    return this.disabledRows?.find(disabledRow => disabledRow.id === row.id);
  }

  isRowSelectionDisabled(row: any) {
    return this.rowSelectionDisabler ? this.rowSelectionDisabler(row) : false;
  }

  isRowDisabledByDisabler(row: any) {
    return this.rowDisabler ? this.rowDisabler(row) : false;
  }

  tableCheckboxClicked(checked: PrimitiveControlValueType, row: TableRow) {
    this.selectedRowsService.rowSelectionChanged(checked!, row);
  }

  rowCountSettingsChanged(newRowCount: number) {
    this.userSettingsService.setTablePageRowCount(newRowCount);
    this.tablePageRowCount = newRowCount;

    const paginator = this.dataSource!.paginator!;

    paginator.pageSize = this.getTablePageSize(this.config.autoPageSizeConfig);
    paginator.pageIndex = 0;

    this.reloadTable();
  }

  getCheckboxTooltip(row: any) {
    if (this.selectedRowsService.isSelectionDisabled(row)) {
      return this.disabledRowTooltip ?? 'Nelze vybrat';
    }
    else {
      return '';
    }
  }

  isEllipsisApplied(column: ColumnDefinition<string>) {
    return !this.customTableCells[column.id] || (this.customTableCells[column.id] && this.customTableCells[column.id].withEllipsis);
  }

  sortChanged(sort: Nullable<Sort>, ignoreLocalStorage = false) {
    this.currentSort = sort;
    if (!ignoreLocalStorage) this.saveTableSortToLocalStorage();
    this.sorted.emit(sort);

    if (
      this.modalComponent ||
      this.activatedRoute.snapshot.data?.fallbackBreadcrumbUri ||
      this.activatedRoute.snapshot.data?.skipHistoryBit ||
      this.disableUrlParameters
    ) return; // do not interact with url parameters when table is in modal or at a route without dedicated history bit or has a configuration input flipped to true

    const sortParamToStore = !sort?.direction ? null : `${sort.active},${sort.direction}`;
    this.historyService.patchHistoryBitWithCustomParams(SORT_TERM_PARAM_NAME, sortParamToStore);
  }

  private saveTableSortToLocalStorage() {
    if (this.currentSort && !this.config.disableLocalStorageSortPersistence) {
      const propertiesFromLocalStorage = this.userSettingsService.getTableProperties(this.id);
      const sortPropertiesToSave = {
        sortColumnId: this.currentSort.active,
        sortDirection: this.currentSort.direction,
      };

      let valueToSave: PersistedUserTableProperties;

      if (propertiesFromLocalStorage) {
        valueToSave = {
          ...propertiesFromLocalStorage,
          sort: sortPropertiesToSave,
        };
      }
      else {
        valueToSave = {
          tableSchemaVersionHash: this.tableSchemaVersionHash,
          sort: sortPropertiesToSave,
          columns: null,
        };
      }

      this.userSettingsService.saveTableProperties(this.id, valueToSave);
    }
  }

  private initSelectedRowsService() {
    this.selectedRowsService.init(this.dataSource!, this.tablePaginator, this.rowComparator, this.rowSelectionDisablingFnChanges$);

    this.itemsSub.unsubscribe();
    this.filterOrSortChangeSub.unsubscribe();
    this.rowSelectionSub.unsubscribe();
    this.activeRowSub.unsubscribe();

    this.itemsSub = this.dataSource!.items$.pipe(
      withLatestFrom(this.selectedRowsService.selectedRows$),
      takeUntilDestroyed(this.destroyRef),
      skip(1),
    ).subscribe(([items, selected]) => {
      if (items && !this.config.allowMultiPageSelection) {
        this.selectedRowsService.deselectMany(selected);
        this.selectedRowsService.selectMany(items.filter(d => selected.some(s => this.rowComparator(s, d))), true);
      }
    });

    this.filterOrSortChangeSub = merge(
      this.filterChanged$,
      this.sorted.pipe(distinctUntilChanged(isEqual)),
    ).pipe(
      withLatestFrom(this.selectedRowsService.selectedRows$),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([_, selected]) => {
      if (this.config.allowMultiPageSelection) {
        this.selectedRowsService.deselectMany(selected);
      }
    });

    this.rowSelectionSub = this.selectedRowsService.selectedRows$.pipe(
      distinctUntilChanged(isEqual),
      takeUntilDestroyed(this.destroyRef),
      skip(1),
    ).subscribe(selected => {
      this.isGlobalSelected = false;
      this.selectedRowsChanged.emit([...selected]);
    });

    this.activeRowSub = this.selectedRowsService.activeRow$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(this.activeRowChanged);
  }

  private isAutoPageSizeByOffsets(specifier: AutoPageSizeConfig): specifier is AutoPageSizeByOffsets {
    const typedSpecifier = specifier as AutoPageSizeByOffsets;

    return (typedSpecifier.screenTopOffset !== undefined &&
      typedSpecifier.screenBottomOffset !== undefined);
  }

  private isAutoPageSizeByManualHeight(specifier: AutoPageSizeConfig): specifier is AutoPageSizeByManualHeight {
    return (specifier as AutoPageSizeByManualHeight).tableHeight !== undefined;
  }

  private loadAsyncColumnLists(columnIdsToLoad?: Nullable<string[]>) {
    this.columnsData!.columnDefinitions
      .filter(column => columnIdsToLoad ? columnIdsToLoad.includes(column.id) : true)
      .forEach(column => {
        if (column.id === TableReservedColumns.SELECTION) column.fixedWidth = this.SELECTION_COLUMN_WIDTH;

        if ((column as CodebookFilterDefinition | EnumFilterDefinition).list$) {
          this.columnsData!.setColumnLoading(column.id, true);
          this.asyncListObservables[column.id] = (column as CodebookFilterDefinition | EnumFilterDefinition).list$!;
          (column as CodebookFilterDefinition).list$!.pipe(
            takeUntilDestroyed(this.destroyRef),
            finalize(() => {
              this.columnsData!.setColumnLoading(column.id, false);
            }),
          ).subscribe(list => {
            this.columnsData!.setColumnLoading(column.id, false);
            this.columnsData!.setColumnList(column.id, list);
          });
        }
      });
  }

  private initializeTableToolbar() {
    this.tableToolbarService.init(this.config.defaultFilterColumns, this.columnsData!);
  }

  private connectDatasourceStateToPaginator() {
    this.dataSourceConnectSubscription?.unsubscribe();

    this.dataSourceConnectSubscription = this.dataSource!.connect().pipe(
      filter(data => Boolean(data.length)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_data => {
      setTimeout(() => {
        this.tablePaginator.setAvailablePagesOptions();
        this.loaded.next();
      });
    });

    this.dataSource!.connect().pipe(
      filter(data => Boolean(data.length)),
      take(1)
    ).subscribe(_ => {});
  }

  private getCurrentSearchParams(): SearchParams {
    if (isSimpleQueryFilterTree(this.filterParams)) {
      return {
        page: this.dataSource!.paginator!.pageIndex,
        size: this.dataSource!.paginator!.pageSize,
        sort: this.sortFields,
        filter: [...this.filterParams.values] as FilterParam[],
        fulltextSearchTerm: this.searchTerm,
      };
    }
    else {
      return {
        page: this.dataSource!.paginator!.pageIndex,
        size: this.dataSource!.paginator!.pageSize,
        sort: this.sortFields,
        filter: [],
        fulltextSearchTerm: this.searchTerm,
        complexFilter: this.filterParams,
      };
    }
  }

  // Semi-deep clone - shallow clones column def array and column def items
  //  but not ColumnDefinition#list which are usually very big and thus
  //  significantly delay affected operations which use object copying.
  private cloneColumnDefinitions(columnDefinitions: ColumnDefinition<TColumnKey>[]): ColumnDefinition<TColumnKey>[] {
    return columnDefinitions.map(cd => ({...cd}));
  }

}
