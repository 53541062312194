/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageTransactionLogRenditionDto } from '../models/page-transaction-log-rendition-dto';
import { TransactionLogRenditionDto } from '../models/transaction-log-rendition-dto';
import { transactionLogRenditionFind } from '../fn/transaction-log-rendition/transaction-log-rendition-find';
import { TransactionLogRenditionFind$Params } from '../fn/transaction-log-rendition/transaction-log-rendition-find';
import { transactionLogRenditionRenderManually } from '../fn/transaction-log-rendition/transaction-log-rendition-render-manually';
import { TransactionLogRenditionRenderManually$Params } from '../fn/transaction-log-rendition/transaction-log-rendition-render-manually';


/**
 * Controller for Transaction Log Renditions
 */
@Injectable({ providedIn: 'root' })
export class ApiTransactionLogRenditionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transactionLogRenditionRenderManually()` */
  static readonly TransactionLogRenditionRenderManuallyPath = '/transaction-log/rendition/manual';

  /**
   * Creates manual rendition by given params
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionLogRenditionRenderManually()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionLogRenditionRenderManually$Response(params: TransactionLogRenditionRenderManually$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionLogRenditionDto>> {
    return transactionLogRenditionRenderManually(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates manual rendition by given params
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionLogRenditionRenderManually$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  transactionLogRenditionRenderManually(params: TransactionLogRenditionRenderManually$Params, context?: HttpContext): Observable<TransactionLogRenditionDto> {
    return this.transactionLogRenditionRenderManually$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionLogRenditionDto>): TransactionLogRenditionDto => r.body)
    );
  }

  /** Path part for operation `transactionLogRenditionFind()` */
  static readonly TransactionLogRenditionFindPath = '/transaction-log/rendition/search';

  /**
   * Find all Transaction Log Rendition instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionLogRenditionFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionLogRenditionFind$Response(params?: TransactionLogRenditionFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageTransactionLogRenditionDto>> {
    return transactionLogRenditionFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Transaction Log Rendition instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionLogRenditionFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionLogRenditionFind(params?: TransactionLogRenditionFind$Params|null|undefined, context?: HttpContext): Observable<PageTransactionLogRenditionDto> {
    return this.transactionLogRenditionFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageTransactionLogRenditionDto>): PageTransactionLogRenditionDto => r.body)
    );
  }

}
