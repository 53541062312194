import {AnalogCompositionType, DocumentForm} from '|api/commons';

export enum ComponentUploadStatus {
  IDLE = 'IDLE',
  UPLOADING = 'UPLOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INVALID_EXTENSION = 'INVALID_EXTENSION'
}
export interface CreateDigitalFormConfig {
  showFieldOrigin: boolean,
  showFieldIsFinal: boolean,
  setFieldIsFinalAsTrue: boolean,
}

export const defaultDigitalFormConfig: CreateDigitalFormConfig = {
  showFieldIsFinal: true,
  showFieldOrigin: true,
  setFieldIsFinalAsTrue: false,
};

export function isAnalogOrHybrid(documentForm: DocumentForm) {
  return documentForm === DocumentForm.ANALOG || documentForm === DocumentForm.HYBRID;
}

export function isDigitalOrHybrid(documentForm: DocumentForm) {
  return documentForm === DocumentForm.DIGITAL || documentForm === DocumentForm.HYBRID;
}

export function isAnalogCompositionSheets(analogCompositionType: Nullable<AnalogCompositionType>) {
  return analogCompositionType === AnalogCompositionType.SHEETS;
}

export function isAnalogCompositionVolumes(analogCompositionType: Nullable<AnalogCompositionType>) {
  return analogCompositionType === AnalogCompositionType.VOLUMES;
}
