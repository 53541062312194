@if (highlights | highlightFragmentForField:fieldId; as highlightFragment) {
  <span
    [iczTooltip]="originalValue"
    [iczHighlightText]="highlightFragment.content"
    [iczHighlightText.offsets]="highlightFragment.parts"
    [iczHighlightText.highlightAll]="highlightFragment.parts | shouldHighlightEverything">
  </span>
}
@else {
  @if (!highlights && searchTermWords.length) {
    <span
      [iczTooltip]="originalValue"
      [iczHighlightText.if]="searchTermWords.length > 0"
      [iczHighlightText]="originalValue"
      [iczHighlightText.terms]="searchTermWords">
    </span>
  }
  @else {
    <span [iczTooltip]="originalValue">{{ originalValue }}</span>
  }
}
