export type TestingFeature = 'ACCESSIBLE' | 'HIDDEN' | 'LABELED';

// ApplicationLanguage values should comply to ISO language codes in lowercase
export enum ApplicationLanguage {
  CZECH = 'cs',
  SLOVAK = 'sk',
  ENGLISH = 'en',
}

interface AvailableEnvironment {
  host: string;
  type: EnvironmentType;
  name: string;
}

export interface BackendUrls {
  eurekaApiUrl: string;
  apiServicesUrl: string;
  getTokenUrl: string;
  orgListUrl: string;
  eurekaWsUrl: string;
  wsBaseUrl: string;
}

interface ActiveEnvironment extends BackendUrls {
  hostUrl: string;
  environmentType: EnvironmentType;
  environmentName: string;
}

interface EnvironmentSpecifics {
  testingFeatureBehavior: TestingFeature;
  defaultLanguage: ApplicationLanguage;
  disableBackendHealthchecks: boolean;
  clientCredentials: string;
}

export interface EnvironmentConfiguration extends EnvironmentSpecifics {
  availableEnvironments: AvailableEnvironment[];
  activeEnvironmentIndex: number;
}

export interface Environment extends ActiveEnvironment, EnvironmentSpecifics {
  availableEnvironments: AvailableEnvironment[];
}

export enum EnvironmentType {
  PRODUCTION = 'PRODUCTION',
  TESTING = 'TESTING',
  TRAINING = 'TRAINING',
  DEVELOPMENT = 'DEVELOPMENT',
}

function stripSchemaFromUrl(url: string): string {
  return url.replace(/^.+:\/\//g, '');
}

export function getWebsocketUrlSchema(url: string) {
  return url.includes('https://') ? 'wss://' : 'ws://';
}

// mutates environment object value
export function environmentConfigurationToEnvironment(
  environmentConf: EnvironmentConfiguration,
  environment: Environment,
) {
  const activeEnvironment = environmentConf.availableEnvironments[
    environmentConf.activeEnvironmentIndex
  ];
  const host = activeEnvironment.host;

  environment.hostUrl = activeEnvironment.host;
  environment.environmentName = activeEnvironment.name;
  environment.environmentType = activeEnvironment.type;
  environment.availableEnvironments = environmentConf.availableEnvironments;
  environment.disableBackendHealthchecks = environmentConf.disableBackendHealthchecks;
  environment.defaultLanguage = environmentConf.defaultLanguage;
  environment.testingFeatureBehavior = environmentConf.testingFeatureBehavior;
  environment.clientCredentials = environmentConf.clientCredentials;

  const backendUrls = urlsFromHost(host);

  for (const backendUrlKey in backendUrls) {
    if (backendUrls.hasOwnProperty(backendUrlKey)) {
      environment[backendUrlKey as keyof BackendUrls] = backendUrls[backendUrlKey as keyof BackendUrls];
    }
  }
}

export function urlsFromHost(host: string): BackendUrls {
  return {
    eurekaApiUrl: `${host}/eureka`,
    apiServicesUrl: `${host}/api`,
    getTokenUrl: `${host}/api/auth-server/api/rest/oauth/token`,
    orgListUrl: `${host}/api/auth-server/api/rest/organization`,
    eurekaWsUrl: `${getWebsocketUrlSchema(host)}${stripSchemaFromUrl(host)}/eureka-ws`,
    wsBaseUrl: `${getWebsocketUrlSchema(host)}${stripSchemaFromUrl(host)}/notification-ws`,
  };
}
