/* tslint:disable */
/* eslint-disable */

/**
 * Disposal type
 */
export enum DisposalType {
  TRIGGER_EVENT_WITH_PERIOD = 'TRIGGER_EVENT_WITH_PERIOD',
  FIXED_DISPOSAL_YEAR = 'FIXED_DISPOSAL_YEAR'
}
