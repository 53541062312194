import {inject, Injectable} from '@angular/core';
import {ToolbarProvider} from '../../../table/table-toolbar/table-toolbar-buttons.utils';
import {map, Observable, of} from 'rxjs';
import {Button} from '../../../essentials/button-collection/button-collection.component';
import {AuthorizedButton, AuthorizedButtonService} from '../../../essentials/authorized-button.service';
import {DocumentOrFileDto} from '../../shared-document.utils';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {
  AbstractSharedFolderDialogsManagerService
} from '../../../../services/abstract-shared-folder-dialogs-manager.service';
import {createAbsoluteRoute, DialogService, SharedFolderToastService} from '../../../../core';
import {InternalNotificationKey} from '|api/notification';
import {WITHOUT_REF_NUMBER} from './toolbar-common.utils';
import {TranslateService} from '@ngx-translate/core';
import {
  SharedFolderSelectionDialogComponent,
  SharedFolderSelectionDialogData
} from '../components/shared-folder-selection-dialog/shared-folder-selection-dialog.component';
import {IczModalService} from '../../../../services/icz-modal.service';
import {
  SharedFolderInsertDialogComponent
} from '../components/shared-folder-insert-dialog/shared-folder-insert-dialog.component';
import {ApiSharedFolderService, SharedFolderDto} from '|api/document';
import {AuthorizedEntityType, SharedFolderAuthorizedOperation} from '|api/core';
import {GlobalLoadingIndicatorService} from '../../../essentials/global-loading-indicator.service';
import {ApplicationRoute} from '../../../../enums/shared-routes.enum';
import {Router} from '@angular/router';
import {SharedFolderViewService} from 'libs/shared/src/lib/services';
import {ToastService} from '../../../notifications/toast.service';

export enum SharedFolderToolbarAction {
  SHARED_FOLDER_CREATED = 'SHARED_FOLDER_CREATED',
  SHARED_FOLDER_UPDATED = 'SHARED_FOLDER_UPDATED',
  SHARED_FOLDER_DELETED = 'SHARED_FOLDER_DELETED',
  ENTITY_INSERTED_TO_SHARED_FOLDER = 'ENTITY_INSERTED_TO_SHARED_FOLDER',
  ENTITY_REMOVED_FROM_SHARED_FOLDER = 'ENTITY_REMOVED_FROM_SHARED_FOLDER',
  ENTITY_MOVED_BETWEEN_SHARED_FOLDERS = 'ENTITY_MOVED_BETWEEN_SHARED_FOLDERS',
}

@Injectable()
export class SharedFolderToolbarButtonsService extends ToolbarProvider<DocumentOrFileDto, SharedFolderToolbarAction, void> {

  private router = inject(Router);
  private toastService = inject(ToastService);
  private modalService = inject(IczModalService);
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private sharedFolderToastService = inject(SharedFolderToastService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private apiSharedFolderService = inject(ApiSharedFolderService);
  private sharedFolderViewService = inject(SharedFolderViewService, {optional: true});
  private globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService);
  private sharedFolderDialogsManagerService = inject(AbstractSharedFolderDialogsManagerService);

  override getToolbarButtons(selection: Nullable<DocumentOrFileDto[]>): Observable<Button[]> {
    const buttons$: Observable<AuthorizedButton[]> = of([
      {
        label: 'Založit',
        icon: 'add_new',
        authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_CREATE_SUBFOLDERS],
        submenuItems: [
          {
            label: 'Založit složku',
            authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_CREATE_SUBFOLDERS],
            action: () => this.onNewSharedFolderClick(this.sharedFolderViewService!.currentFolder!),
          },
        ],
      },
      {
        label: 'Úpravy',
        icon: 'edit',
        submenuItems: [
          {
            label: 'Upravit složku',
            authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_EDIT_METADATA],
            action: () => this.onEditSharedFolderClick(this.sharedFolderViewService!.currentFolder!),
          },
          {
            label: 'Vložit do složky',
            authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_EDIT_METADATA],
            action: () => this.onInsertToSharedFolderClick(this.sharedFolderViewService!.currentFolder!),
          },
          {
            label: 'Vyjmout ze složky',
            authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_REMOVE_OBJECT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
            ],
            action: () => this.onRemoveFromSharedFolderClick(selection!, this.sharedFolderViewService!.currentFolder!),
          },
          {
            label: 'Přesunout do jiné složky',
            authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_MOVE_OBJECT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
            ],
            action: () => this.onMoveToAnotherSharedFolderClick(selection!, this.sharedFolderViewService!.currentFolder!),
          },
        ]
      },
      {
        label: 'Smazat složku',
        icon: 'delete',
        authorizedOperations: [SharedFolderAuthorizedOperation.SHARED_FOLDER_DELETE_FOLDER_AND_SUBFOLDERS],
        action: () => this.onDeleteSharedFolderClick(this.sharedFolderViewService!.currentFolder!),
      },
    ]);

    if (selection?.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.SHARED_FOLDER]: selection[0]?.id,
        },
        buttons$
      );
    }
    else {
      return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  private onNewSharedFolderClick(sharedFolder: SharedFolderDto) {
    this.sharedFolderDialogsManagerService.openSharedFolderCreateUpdateDialog({
      sharedFolder,
      isCreateMode: true,
    }).subscribe(result => {
      if (result) {
        this.sharedFolderToastService.dispatchSharedFolderCreateSuccess({
          [InternalNotificationKey.NAME]: result.name,
        });
        this.announceActionCompleted(SharedFolderToolbarAction.SHARED_FOLDER_CREATED);
      }
    });
  }

  private onEditSharedFolderClick(sharedFolder: SharedFolderDto) {
    this.sharedFolderDialogsManagerService.openSharedFolderCreateUpdateDialog({
      sharedFolder,
      isCreateMode: false,
    }).subscribe(result => {
      if (result) {
        this.sharedFolderToastService.dispatchSharedFolderUpdateSuccess({
          [InternalNotificationKey.NAME]: result.name,
        });
        this.announceActionCompleted(SharedFolderToolbarAction.SHARED_FOLDER_UPDATED);
      }
    });
  }

  private onMoveToAnotherSharedFolderClick(entities: DocumentOrFileDto[], currentFolder: SharedFolderDto) {
    const isBulkAction = entities.length > 1;

    this.modalService.openComponentInModal<Nullable<SharedFolderDto>, SharedFolderSelectionDialogData>({
      component: SharedFolderSelectionDialogComponent,
      modalOptions: {
        width: 500,
        height: 580,
        titleTemplate: isBulkAction ? 'Přesun objektů ({{count}}) do jiné složky' : 'Přesunout objekt do jiné složky',
        titleTemplateContext: {
          count: String(entities.length),
        }
      },
      data: {
        submitButtonText: 'Přesunout do složky',
        disabledFolderIds: [currentFolder.id],
      }
    }).subscribe(targetFolder => {
      if (targetFolder) {
        this.globalLoadingIndicatorService.doLoading(
          this.apiSharedFolderService.sharedFolderMoveObjectToAnotherFolder({
            id: currentFolder.id,
            body: {
              entities: entities.map(e => ({
                id: e.id,
                entityType: e.entityType!,
              })),
              targetFolderId: targetFolder.id,
            }
          })
        ).subscribe(() => {
          this.sharedFolderToastService.dispatchMoveBetweenSharedFoldersSuccess({
            [InternalNotificationKey.NAME]: targetFolder.name,
          });
          this.announceActionCompleted(SharedFolderToolbarAction.ENTITY_MOVED_BETWEEN_SHARED_FOLDERS);
        });
      }
    });
  }

  private onRemoveFromSharedFolderClick(entities: DocumentOrFileDto[], sharedFolder: SharedFolderDto) {
    const isBulkAction = entities.length > 1;

    this.dialogService.openQuestionDialog({
      title: isBulkAction ? 'Vyjmutí objektů ({{count}}) ze složky' : 'Vyjmout objekt ze složky',
      titleContext: {
        count: entities.length,
      },
      question: (
        isBulkAction ?
          'Opravdu chcete vyjmout objekty ({{count}}) ze sdílené složky {{name}}?' :
          'Opravdu chcete vyjmout objekt {{refNumber}}: {{subject}} ze sdílené složky {{name}}?'
      ),
      leftButtonTitle: 'Vyjmout ze složky',
      description: 'Operaci nelze vrátit.',
      questionContext: {
        name: sharedFolder.name,
        count: entities.length,
        refNumber: entities[0].refNumber ?? this.translateService.instant(WITHOUT_REF_NUMBER),
        subject: entities[0].subject,
      },
    }).subscribe(() => {
      this.globalLoadingIndicatorService.doLoading(
        this.apiSharedFolderService.sharedFolderRemoveObjectsFromSharedFolder({
          id: sharedFolder.id,
          body: entities.map(e => ({
            id: e.id,
            entityType: e.entityType!,
          })),
        }),
      ).subscribe(result => {
        this.sharedFolderToastService.dispatchRemoveFromSharedFolderSuccess({
          [InternalNotificationKey.NAME]: sharedFolder.name,
        });
        this.announceActionCompleted(SharedFolderToolbarAction.ENTITY_REMOVED_FROM_SHARED_FOLDER);
      });
    });
  }

  private onInsertToSharedFolderClick(sharedFolder: SharedFolderDto) {
    this.modalService.openComponentInModal<Nullable<boolean>, SharedFolderDto>({
      component: SharedFolderInsertDialogComponent,
      modalOptions: {
        width: 500,
        height: 580,
        titleTemplate: 'Vložit objekty do složky {{name}}',
        titleTemplateContext: {
          name: sharedFolder.name,
        }
      },
      data: sharedFolder
    }).subscribe(result => {
      if (result) {
        this.sharedFolderToastService.dispatchInsertIntoSharedFolderSuccess({
          [InternalNotificationKey.NAME]: sharedFolder.name,
        });
        this.announceActionCompleted(SharedFolderToolbarAction.ENTITY_INSERTED_TO_SHARED_FOLDER);
      }
    });
  }

  private onDeleteSharedFolderClick(sharedFolder: SharedFolderDto) {
    this.dialogService.openQuestionDialog({
      title: 'Smazat sdílenou složku',
      question: 'Opravdu chcete smazat sdílenou složku {{name}}?',
      description: 'Operaci nelze vrátit.',
      questionContext: {
        name: sharedFolder.name,
      },
    }).subscribe(() => {
      this.globalLoadingIndicatorService.doLoading(
        this.apiSharedFolderService.sharedFolderDeleteFolder({
          id: sharedFolder.id,
        })
      ).subscribe(() => {
        this.sharedFolderToastService.dispatchSharedFolderDeleteSuccess({
          [InternalNotificationKey.NAME]: sharedFolder.name,
        });
        this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.SHARED_FOLDERS));
        this.announceActionCompleted(SharedFolderToolbarAction.SHARED_FOLDER_DELETED);
      });
    });
  }

}
