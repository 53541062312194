import {IczTableDataSource} from '../../../../table/table.datasource';
import {FilterParam} from '../../../../../services/search-api.service';
import {EsslComponentDto, EsslComponentSearchService} from '../../../../../services/essl-component-search.service';

export class ConsignmentComponentsDatasource extends IczTableDataSource<EsslComponentDto> {
  constructor(
    searchService: EsslComponentSearchService,
    consignmentId: number,
    documentId: number,
    additionalFilters?: FilterParam[]
  ) {
    super(searchParams => searchService.findEsslComponentsForConsignment(searchParams, consignmentId, documentId, additionalFilters));
  }
}
