import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'icz-more-items-counter',
  templateUrl: './more-items-counter.component.html',
  styleUrls: ['./more-items-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoreItemsCounterComponent {
  @Input({required: true})
  moreItemsCount: number = 0;
}
