/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ErmsAuditOutgoingBatchDto } from '../../models/erms-audit-outgoing-batch-dto';
import { ErmsAuditReceivedBatchDto } from '../../models/erms-audit-received-batch-dto';

export interface ErmsAuditFindBatchByUid$Params {

/**
 * uid
 */
  uid: string;
}

export function ermsAuditFindBatchByUid(http: HttpClient, rootUrl: string, params: ErmsAuditFindBatchByUid$Params, context?: HttpContext): Observable<StrictHttpResponse<(ErmsAuditReceivedBatchDto | ErmsAuditOutgoingBatchDto)>> {
  const rb = new RequestBuilder(rootUrl, ermsAuditFindBatchByUid.PATH, 'get');
  if (params) {
    rb.path('uid', params.uid, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<(ErmsAuditReceivedBatchDto | ErmsAuditOutgoingBatchDto)>;
    })
  );
}

ermsAuditFindBatchByUid.PATH = '/erms-audit/batch/{uid}';