<div class="row" [formGroup]="form" (keydown.enter)="applyFilter()">

  <div class="col grow">
    <icz-form-inline-select
      formControlName="filterOperator"
      [options]="filterOperators"
      (openStatusChanged)="isOperatorSelectorOpen = $event">
    </icz-form-inline-select>

    <div class="filter-body"
         [style.height.px]="canDisplayFilterBody ? null : 1"
         [style.opacity]="canDisplayFilterBody ? null : 0">

      <icz-empowerment-filter
        [selected]="$any(selectedEmpowerment)"
        (empowermentChanged)="empowermentFilterChanged($event)"
        class="block pt-8"
      >
      </icz-empowerment-filter>
    </div>
  </div>
</div>
