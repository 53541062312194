<cdk-virtual-scroll-viewport class="block h-full overflow-x-hidden" itemSize="40" minBufferPx="125" maxBufferPx="500" appendOnly #virtualScrollViewport>
  <ng-container *cdkVirtualFor="let node of dataSource">
    <div [style.padding-left.px]="node.level * 30" class="row gap-0 node-main-row">
      @if (hasChild(node)) {
        <div class="col hide-borders">
          <icz-button [svgIcon]="treeControl.isExpanded(node) ? 'expand_more' : 'after'" (onAction)="toggleExpansionState(node)" background disableRipple></icz-button>
        </div>
      }
      @else {
        <div class="col no-child-margin"></div>
      }
      <div class="overflow-hidden col grow node-value">
        @if (_searchTerm && !node.parent && childrenPath[node.value]) {
          <div class="tree-breadcrumbs">
            @if (formatChildPath(childrenPath[node.value]); as childPath) {
              <span
                [iczTooltip]="childPath"
                [innerHtml]="childPath"
              ></span>
            }
          </div>
        }
        <div class="row relative">
          @if (!node.isGroup && isMultiChoice && !hasChild(node)) {
            <icz-checkbox
              class="grow checklist-leaf-node"
              [fieldDisabled]="node.disabled"
              [label]="listItemTemplate ? null : node.label"
              [checked]="checklistSelection.isSelected(node)"
              (changed)="toggleLeafItemSelection(node)">
              <ng-container [ngTemplateOutlet]="treeItemTemplate"
                [ngTemplateOutletContext]="{$implicit: node}"
              ></ng-container>
            </icz-checkbox>
          }

          @if (!node.isGroup && isMultiChoice && hasChild(node)) {
            <icz-checkbox
              [fieldDisabled]="node.disabled" class="grow overflow-hidden min-w-0"
              [value]="isInnerNodeSelected(node)"
              [label]="listItemTemplate ? null : node.label"
              [indeterminate]="isInnerNodeIndeterminate(node)"
              (changed)="toggleItemSelection(node)">
              <ng-container [ngTemplateOutlet]="treeItemTemplate"
                [ngTemplateOutletContext]="{$implicit: node}"
              ></ng-container>
            </icz-checkbox>
          }

          @if (node.isGroup || !isMultiChoice) {
            <div class="grow" (click)="nodeClicked(node)">
              @if (!listItemTemplate) {
                <icz-label class="node-just-label"
                [label]="node.label"></icz-label>
              }
              @else {
                <ng-container [ngTemplateOutlet]="treeItemTemplate"
                  [ngTemplateOutletContext]="{$implicit: node}"
                ></ng-container>
              }
            </div>
          }

          @if (isSelectAllDescendantsVisible(node)) {
            <div class="icz-body-1 select-subtree">
              <a
                iczLinkWithoutHref
                (click)="selectSubtreeByNode($event, node)">
                {{ 'vybrat vše' | translate }}
              </a>
            </div>
          }
        </div>
      </div>
    </div>
  </ng-container>
</cdk-virtual-scroll-viewport>

<ng-template #treeItemTemplate let-node>
  <div class="row grow tree-item">
    <div class="grow">
      <!-- [isMultiselect]="false" + [isSelected]="false" => this component handles option checkbox on its own -->
      <icz-option-item
        [option]="node"
        [listItemTemplate]="listItemTemplate"
        [isMultiselect]="false"
        [isSelected]="false"
      ></icz-option-item>
    </div>
  </div>
</ng-template>
