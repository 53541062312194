import {DestroyRef, inject, Injectable} from '@angular/core';
import {CheckUnsavedFormsCollection, FormGroupPropertiesOf} from './check-unsaved-forms-collection.interface';
import {IczModalRef} from '../../services/icz-modal-ref.injectable';
import {IFormGroupCheckable, openModalOnChanges} from '../../lib/form-group-checks';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DialogService} from '../../core/services/dialog.service';

/**
 * This service should be component-provided
 */

@Injectable()
export class CheckUnsavedFormDialogService implements CheckUnsavedFormsCollection {

  private modalRef = inject(IczModalRef);
  private destroyRef = inject(DestroyRef);
  private dialogService = inject(DialogService);

  addUnsavedFormCheck<T extends IFormGroupCheckable>(component: T, formGroupsToCheck: Array<keyof FormGroupPropertiesOf<T>>) {
    component.formGroupsToCheck = formGroupsToCheck as string[];

    const originalCloseFunction = this.modalRef.close.bind(this.modalRef);

    this.modalRef.close = (isDone = false) => {
      if (isDone) {
        originalCloseFunction(isDone);

        return;
      }

      openModalOnChanges<void>(component, this.dialogService,
        {
          changesDetected: afterClosed$ => {
            afterClosed$.pipe(
              takeUntilDestroyed(this.destroyRef)
            ).subscribe(shouldStay => {
              if (!shouldStay) {
                originalCloseFunction(isDone);
              }
            });
          },
          changesNotDetected: () => {
            originalCloseFunction(isDone);
          }
        }
      );
    };
  }

}
