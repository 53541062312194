/* tslint:disable */
/* eslint-disable */

/**
 * Storage unit form
 */
export enum StorageUnitForm {
  DIGITAL = 'DIGITAL',
  ANALOG = 'ANALOG',
  HYBRID = 'HYBRID'
}
