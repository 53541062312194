import {Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {DeliveryTypeDto, DisposalScheduleDto, DistributionClassDto, DocumentTypeDto} from '|api/codebook';
import {DistributionClass, DocumentForm} from '|api/commons';
import {
  CreateAnalogFormConfig,
  CreateAnalogsUsage,
  defaultCreateAnalogFormConfig,
  EsslAnalogComponentTypeAsFields,
} from '../analog-component-create-form/analog-component-create-form.component';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {IczDateValidators} from '../../form-elements/validators/icz-validators/icz-date-validators';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {addDays} from '../../../lib/utils';
import {getStartOfTheDay} from '../../../model';
import {calcDocumentFormFromComponents, getObjectClassByDocumentForm} from '../shared-document.utils';
import {
  initEntityClassPreselectionByDocumentType,
  initSecurityCategoryPreselectionByEntityClass,
} from '../../../utils/document-forms.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ReceivedDocumentUsage} from '../../../services/received-consignments.model';
import {initTotalComponentsCountFormListeners} from '../essl-components/models/digital-components.model';
import {DisposalTipDetailLevel} from '../shared-business-components.model';
import {DocumentEsslComponentsValidator} from '../document-essl-components-validator';

@Component({
  selector: 'icz-received-document-form',
  templateUrl: './received-document-form.component.html',
  styleUrls: ['./received-document-form.component.scss'],
})
export class ReceivedDocumentFormComponent implements OnInit, IczOnChanges {

  applicationConfigService = inject(ApplicationConfigService);
  codebookService = inject(CodebookService);
  destroyRef = inject(DestroyRef);

  @ViewChild('fileUpload', {static: false}) fileUpload!: ElementRef;

  @Input({required: true}) form!: IczFormGroup;
  @Input({required: true}) distributionClass: Nullable<DistributionClassDto>;
  @Input() receivedDocumentUsage = ReceivedDocumentUsage.CREATE_OFFICER_FULL_DOCUMENT;
  @Input() showComponentDetailedInputToggle = false;
  @Input() resetForm!: Nullable<EventEmitter<void>>;
  @Input() deliveryType: Nullable<DeliveryTypeDto>;
  @Input() canAddAnalogComponentsByOpenedState: Nullable<boolean>;
  @Input() disableAssignRegNr = false;
  @Output() canAddDigitalComponentsByDocumentForm = new EventEmitter<boolean>();

  readonly presentOrFutureDateValidator = IczDateValidators.presentOrFutureDateValidator;

  analogComponentsConfig: CreateAnalogFormConfig = {...defaultCreateAnalogFormConfig, setFieldIsFinalAsTrue: true};

  get isFullDocument() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_OFFICER_FULL_DOCUMENT;
  }

  get isFilingOfficeInternal() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_INTERNAL_CONSIGNMENT;
  }

  get isFilingOfficePaper() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PAPER_CONSIGNMENT;
  }

  get entityClassId() {
    return this.form.get('entityClassId')!.value;
  }

  get documentTypeId() {
    return this.form.get('documentTypeId')!.value;
  }

  get esslComponentCountFormCtrl() {
    return this.form.get('esslComponentCount') as IczFormControl;
  }

  get assignRefNr() {
    return this.form.get('assignRefNr') as IczFormControl;
  }

  canAddAnalogComponents$ = new BehaviorSubject(false);

  documentTypes: Array<DocumentTypeDto> = [];

  documentFormOptions = enumToOptions('documentForm', DocumentForm);

  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  ngOnInit(): void {
    this.codebookService.documentTypes().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(
      documentTypes => this.documentTypes = documentTypes
    );

    this.resetForm?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.setAllowedDocumentFormAndComponents();
    });

    if (this.isFullDocument) {
      this.form.addControl('empowerment', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('entityClassId', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('securityCategoryId', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('keywordIds', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('documentTypeId', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('resolutionDate', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('disposalScheduleId', new IczFormControl<Nullable<string>>(null, []));
      this.form.addControl('retentionTriggerTypeId', new IczFormControl<Nullable<string>>({value: null, disabled: true}, []));
      this.form.addControl('yearOfRetentionPeriodStart', new IczFormControl<Nullable<number>>(null, []));
      this.form.addControl('triggerEventCheckYear', new IczFormControl<Nullable<number>>(null, []));

      this.initSecurityCategoryPreselection();
      this.initEntityClassPreselection();
    }

    if (this.isFilingOfficeInternal) {
      this.form.addControl('empowerment', new IczFormControl<Nullable<string>>(null, []));
    }

    this.form.get('documentTypeId')?.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(documentTypeId => {
      const selectedDocumentType = this.documentTypes.find(o => o.id === documentTypeId);

      if (selectedDocumentType?.entityClassId) {
        this.form.get('entityClassId')?.setValue(selectedDocumentType.entityClassId);
      }
      if (selectedDocumentType?.statutoryPeriod) {
        const resolutionDate = addDays(getStartOfTheDay(), selectedDocumentType.statutoryPeriod);
        this.form.get('resolutionDate')!.setValue(resolutionDate.toISOString());
      } else {
        this.form.get('resolutionDate')!.setValue(null);
      }
    });

    this.analogComponentsConfig = {
      analogCreateUsage: this.isFullDocument ? CreateAnalogsUsage.PART_OF_DOCUMENT_CREATE : CreateAnalogsUsage.PART_OF_DOCUMENT_CREATE_FILING_OFFICE,
      isDetailedInput: this.isFilingOfficePaper ? 'false' : 'true',
      allowDeleteFirst: true,
      showFieldOrigin: false,
      showFieldIsFinal: false,
      setFieldIsFinalAsTrue: true,
    };

    this.canAddAnalogComponents$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(canAddAnalog => {
      const parentNodeType = this.distributionClass?.parent!.distributionClassValue;
      if (canAddAnalog && parentNodeType && parentNodeType === DistributionClass.PHYSICAL_DISTRIBUTION_NODE) {
        this.analogComponentsConfig = {...this.analogComponentsConfig, setFirstComponentAsMain: true};
      } else {
        this.analogComponentsConfig = {...this.analogComponentsConfig, setFirstComponentAsMain: false};
      }
    });

    this.form.get('documentForm')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(documentForm => {
      const objectClass = getObjectClassByDocumentForm(documentForm, true);

      if (objectClass) {
        this.form.get('objectClass')!.setValue(objectClass);
      }
    });

    initTotalComponentsCountFormListeners(this.destroyRef, this.form, this.esslComponentCountFormCtrl);
  }

  get documentForm() {
    return this.form.get('documentForm')?.value;
  }

  private calcDocumentFormFromComponents() {
   calcDocumentFormFromComponents(this.form, this.destroyRef);
  }

  // Allowed document form and analog/digital components is a combination of distributionClass and opened state of consignment.
  setAllowedDocumentFormAndComponents() {
    let canAddAnalogComponentsByForm = false;

    if (!this.distributionClass) return;

    const parentNodeType = this.distributionClass.parent!.distributionClassValue;
    if (parentNodeType === DistributionClass.PHYSICAL_DISTRIBUTION_NODE) {
      canAddAnalogComponentsByForm = true;
      this.canAddDigitalComponentsByDocumentForm.next(false);
      this.form.get('documentForm')!.setValue(DocumentForm.ANALOG);
    } else if (parentNodeType === DistributionClass.DIGITAL_DISTRIBUTION_NODE) {
      canAddAnalogComponentsByForm = false;
      this.canAddDigitalComponentsByDocumentForm.next(true);
      this.form.get('documentForm')!.setValue(DocumentForm.DIGITAL);
    } else if (parentNodeType === DistributionClass.HYBRID_DISTRIBUTION_NODE) {
      canAddAnalogComponentsByForm = true;
      this.canAddDigitalComponentsByDocumentForm.next(true);
      // for HYBRID node, documentForm is not always be hybrid, will be calculated from components
      this.calcDocumentFormFromComponents();
    } else if (parentNodeType === DistributionClass.INTERNAL_NODE && this.deliveryType) {
      if (this.deliveryType.forDigital) {
        canAddAnalogComponentsByForm = false;
        this.canAddDigitalComponentsByDocumentForm.next(false);
        this.form.get('documentForm')!.setValue(DocumentForm.DIGITAL);
      } else {
        canAddAnalogComponentsByForm = true;
        this.canAddDigitalComponentsByDocumentForm.next(false);
        this.form.get('documentForm')!.setValue(DocumentForm.ANALOG);
      }
    }

    this.canAddAnalogComponents$.next(Boolean(this.canAddAnalogComponentsByOpenedState) && canAddAnalogComponentsByForm);
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.distributionClass?.currentValue) {
      this.setAllowedDocumentFormAndComponents();
    }
    if (changes?.deliveryType?.currentValue) {
      this.setAllowedDocumentFormAndComponents();
    }
    if (changes?.canAddAnalogComponentsByOpenedState) {
      this.setAllowedDocumentFormAndComponents();
      this.esslComponentCountFormCtrl!.setValidators([DocumentEsslComponentsValidator.isEsslComponentAdded()]);
      this.esslComponentCountFormCtrl!.updateValueAndValidity();

      if (changes.canAddAnalogComponentsByOpenedState.currentValue === true) {
        (this.form.get(EsslAnalogComponentTypeAsFields.PAPER)!.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray).controls.forEach(formGroup => {
          (formGroup as IczFormGroup).resetValidatorsToInitial();
          formGroup.updateValueAndValidity();
        });
        (this.form.get(EsslAnalogComponentTypeAsFields.MEDIUM)!.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray).controls.forEach(formGroup => {
          (formGroup as IczFormGroup).resetValidatorsToInitial();
          formGroup.updateValueAndValidity();
        });
        (this.form.get(EsslAnalogComponentTypeAsFields.PHYSICAL)!.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray).controls.forEach(formGroup => {
          (formGroup as IczFormGroup).resetValidatorsToInitial();
          formGroup.updateValueAndValidity();
        });
      }
      else {
        this.esslComponentCountFormCtrl!.clearValidators();
        this.esslComponentCountFormCtrl!.updateValueAndValidity();
        (this.form.get(EsslAnalogComponentTypeAsFields.PAPER)!.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray).controls.forEach(formGroup => {
          Object.values((formGroup as IczFormGroup).controls).forEach(control => {
            (control as IczFormControl).clearValidators();
            control.updateValueAndValidity();
          });
        });
        (this.form.get(EsslAnalogComponentTypeAsFields.MEDIUM)!.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray).controls.forEach(formGroup => {
          Object.values((formGroup as IczFormGroup).controls).forEach(control => {
            (control as IczFormControl).clearValidators();
            control.updateValueAndValidity();
          });
        });
        (this.form.get(EsslAnalogComponentTypeAsFields.PHYSICAL)!.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray).controls.forEach(formGroup => {
          Object.values((formGroup as IczFormGroup).controls).forEach(control => {
            (control as IczFormControl).clearValidators();
            control.updateValueAndValidity();
          });
        });
      }
    }
    if (changes?.disableAssignRegNr) {
      if (changes.disableAssignRegNr.currentValue === true) {
        this.assignRefNr.disable();
        this.assignRefNr.setValue(false);
      } else {
        this.assignRefNr.enable();
      }
    }
  }

  onDisposalScheduleSelected(schedule: Nullable<DisposalScheduleDto>) {
    if (schedule) this.form.get('disposalScheduleId')?.setValue(schedule.id);
  }

  private initSecurityCategoryPreselection() {
    initSecurityCategoryPreselectionByEntityClass.bind(this)();
  }

  private initEntityClassPreselection() {
    initEntityClassPreselectionByDocumentType.bind(this)();
  }
}
