<icz-section [label]="sectionLabel" boxed class="block mb-32">
  <icz-form-field
    label="Předmět"
    value="předmět datové zprávy"
    [value]="consignment?.subject ?? message?.subject"
    [fieldDisabled]="true"
  ></icz-form-field>
  <div class="row">
    <icz-form-field
      class="grow"
      label="Dodáno do DS"
      [value]="message?.sentDate ?? consignment?.receivedDateTime | localizedDatetime: false : true"
      [fieldDisabled]="true"
    ></icz-form-field>
    <icz-form-field
      class="grow"
      label="Doručeno do DS"
      [value]="message?.receivedDate ?? consignment?.deliveryDate | localizedDatetime: false : true"
      [fieldDisabled]="true"
    ></icz-form-field>
  </div>
  <div class="row">
    <icz-form-field
      class="grow"
      label="Identifikátor datové zprávy"
      [value]="message?.externalId ?? consignment?.externalId"
      [fieldDisabled]="true"
    ></icz-form-field>
    <icz-form-field
      class="grow"
      label="Zmocnění"
      [value]="(message?.empowerment! | empowerment) ?? consignment?.empowerment"
      [fieldDisabled]="true"
    ></icz-form-field>
  </div>
  <div class="row">
    <icz-form-field
      class="grow"
      label="Do vlastních rukou"
      [value]="message?.personalDelivery ?? consignment?.personalDelivery | localizedBoolean"
      [fieldDisabled]="true"
    ></icz-form-field>
    <icz-form-field
      class="grow"
      label="K rukám"
      [value]="message?.toHands ?? consignment?.toHands"
      [fieldDisabled]="true"
    ></icz-form-field>
  </div>
  <icz-form-field
    class="grow"
    label="Číslo jednací odesílatele"
    [value]="message?.senderRefNumber ?? consignment?.senderRefNumber"
    [fieldDisabled]="true"
  ></icz-form-field>
  <icz-form-field
    class="grow"
    label="Organizační jednotka příjemce"
    [value]="consignment?.consigneeOrganisationUnit"
    [fieldDisabled]="true"
  ></icz-form-field>
  @if (showDeliveryType) {
    <icz-form-field
      label="Způsob doručení"
      value="Datová schránka"
      [fieldDisabled]="true"
    ></icz-form-field>
  }
  @if (showSignatureStatus) {
    <icz-label class="signature-status icz-body-1" label="Podpis/pečeť"></icz-label>
    <icz-digital-message-signature-status
      [message]="message"
    ></icz-digital-message-signature-status>
  }
</icz-section>
