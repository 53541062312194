import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {EmpowermentDto} from '|api/commons';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'icz-empowerment-filter',
  templateUrl: './empowerment-filter.component.html',
  styleUrls: ['./empowerment-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmpowermentFilterComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Input() selected: Nullable<EmpowermentDto>;
  @Output() empowermentChanged = new EventEmitter<Nullable<EmpowermentDto>>();

  form = new IczFormGroup({
    empowerment: new IczFormControl<Nullable<EmpowermentDto>>(null),
  });

  ngOnInit(): void {
    this.form.get('empowerment')!.setValue(this.selected);
    this.form.get('empowerment')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.empowermentChanged.emit(value);
    });
  }

}
