/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { filingOfficeCreate } from '../fn/filing-office/filing-office-create';
import { FilingOfficeCreate$Params } from '../fn/filing-office/filing-office-create';
import { filingOfficeDelete } from '../fn/filing-office/filing-office-delete';
import { FilingOfficeDelete$Params } from '../fn/filing-office/filing-office-delete';
import { FilingOfficeDto } from '../models/filing-office-dto';
import { filingOfficeFindAll } from '../fn/filing-office/filing-office-find-all';
import { FilingOfficeFindAll$Params } from '../fn/filing-office/filing-office-find-all';
import { filingOfficeFindById } from '../fn/filing-office/filing-office-find-by-id';
import { FilingOfficeFindById$Params } from '../fn/filing-office/filing-office-find-by-id';
import { filingOfficeSearch } from '../fn/filing-office/filing-office-search';
import { FilingOfficeSearch$Params } from '../fn/filing-office/filing-office-search';
import { filingOfficeUpdate } from '../fn/filing-office/filing-office-update';
import { FilingOfficeUpdate$Params } from '../fn/filing-office/filing-office-update';
import { PageFilingOfficeDto } from '../models/page-filing-office-dto';


/**
 * Controller for filing office objects
 */
@Injectable({ providedIn: 'root' })
export class ApiFilingOfficeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `filingOfficeFindById()` */
  static readonly FilingOfficeFindByIdPath = '/codebook/filing-office/{id}';

  /**
   * Find filing office object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingOfficeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeFindById$Response(params: FilingOfficeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<FilingOfficeDto>> {
    return filingOfficeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find filing office object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingOfficeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeFindById(params: FilingOfficeFindById$Params, context?: HttpContext): Observable<FilingOfficeDto> {
    return this.filingOfficeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilingOfficeDto>): FilingOfficeDto => r.body)
    );
  }

  /** Path part for operation `filingOfficeUpdate()` */
  static readonly FilingOfficeUpdatePath = '/codebook/filing-office/{id}';

  /**
   * Update existing filing office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingOfficeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingOfficeUpdate$Response(params: FilingOfficeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FilingOfficeDto>> {
    return filingOfficeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing filing office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingOfficeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingOfficeUpdate(params: FilingOfficeUpdate$Params, context?: HttpContext): Observable<FilingOfficeDto> {
    return this.filingOfficeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilingOfficeDto>): FilingOfficeDto => r.body)
    );
  }

  /** Path part for operation `filingOfficeDelete()` */
  static readonly FilingOfficeDeletePath = '/codebook/filing-office/{id}';

  /**
   * Delete existing filing office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingOfficeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeDelete$Response(params: FilingOfficeDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return filingOfficeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing filing office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingOfficeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeDelete(params: FilingOfficeDelete$Params, context?: HttpContext): Observable<void> {
    return this.filingOfficeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `filingOfficeFindAll()` */
  static readonly FilingOfficeFindAllPath = '/codebook/filing-office';

  /**
   * Finds all filing office objects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingOfficeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeFindAll$Response(params?: FilingOfficeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FilingOfficeDto>>> {
    return filingOfficeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all filing office objects
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingOfficeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeFindAll(params?: FilingOfficeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<FilingOfficeDto>> {
    return this.filingOfficeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FilingOfficeDto>>): Array<FilingOfficeDto> => r.body)
    );
  }

  /** Path part for operation `filingOfficeCreate()` */
  static readonly FilingOfficeCreatePath = '/codebook/filing-office';

  /**
   * Create new filing office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingOfficeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingOfficeCreate$Response(params: FilingOfficeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FilingOfficeDto>> {
    return filingOfficeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new filing office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingOfficeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingOfficeCreate(params: FilingOfficeCreate$Params, context?: HttpContext): Observable<FilingOfficeDto> {
    return this.filingOfficeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilingOfficeDto>): FilingOfficeDto => r.body)
    );
  }

  /** Path part for operation `filingOfficeSearch()` */
  static readonly FilingOfficeSearchPath = '/codebook/filing-office/search-api';

  /**
   * Finds filing office objects based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingOfficeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeSearch$Response(params?: FilingOfficeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFilingOfficeDto>> {
    return filingOfficeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds filing office objects based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingOfficeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingOfficeSearch(params?: FilingOfficeSearch$Params|null|undefined, context?: HttpContext): Observable<PageFilingOfficeDto> {
    return this.filingOfficeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFilingOfficeDto>): PageFilingOfficeDto => r.body)
    );
  }

}
