import {FunctionalPositionDto} from '|api/auth-server';
import {
  DeliveryResultDto,
  DeliveryServiceAdditionalDto,
  DeliveryServiceBasicDto,
  DeliveryServiceCombinationDto,
  DeliveryTypeDto,
  OfficeDeskCategoryDto,
  OfficeDeskRegionDto
} from '|api/codebook';
import {ConsignmentType, OwnConsignmentStatus} from '|api/commons';
import {OfficeDeskNodeDto} from '|api/sad';
import {
  isOfficeDeskView,
  OwnConsignmentOfficeDeskTableView,
  OwnConsignmentTableView
} from './model/own-consignment-model';
import {TableColumnsData} from '../../table/table-columns-data';
import {ColumnDefinition} from '../../table/table.models';
import {FilterType} from '../../table/filter.types';
import {getDeliveryTypesForDispatchOptions} from '../utils/delivery-types.utils';
import {
  arrayToOptions,
  enumToOptions,
  namedDtosToOptions,
  namedDtoToOption
} from '../../../core/services/data-mapping.utils';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {OwnConsignmentTableConsignmentTypeFilter} from '../consignment-dialog/consignment-dialog/consignment.model';
import {DistributionNodeDto} from '|api/config-server';
import {getConsignmentIcon} from '../shared-document.utils';

export enum OwnConsignmentColumnKey {
  CONSIGNEE = 'consignee',
  CONSIGNEE_ADDRESS = 'consigneeAddress',
  CONSIGNMENT_POSTING_NUMBER = 'consignmentPostingNumber',
  CONSIGNMENT_TYPE = 'consignmentType', // Druh vypravení
  CREATED_AT = 'auditInfo.createdAt',
  CREATED_BY = 'auditInfo.createdBy',
  CREATED_BY_FUNCTIONAL_POSITION_ID = 'auditInfo.createdByFunctionalPositionId',
  OUTBOUND_CONSIGNMENT_IDENTIFIER = 'outboundConsignmentIdentifier',
  DELIVERY_DATE = 'proofOfDelivery.acknowledgementDate',
  DELIVERY_RESULT = 'proofOfDelivery.deliveryResultId', //  "Výsledek doručení"
  DELIVERY_SERVICE_ADDITIONAL_IDS = 'deliveryServiceAdditionalIds',
  DELIVERY_SERVICE_BASIC_ID = 'deliveryServiceBasicId',
  DELIVERY_SERVICE_COMBINATION_ID = 'deliveryServiceCombinationId',
  DELIVERY_TYPE_ID = 'deliveryTypeId',
  DISPATCH_DATE = 'dispatchDate',
  DISTRIBUTION_NODE_ID = 'dispatchOfficeDistributionNodeId',
  DOCUMENT_OBJECT_CLASS = 'objectClass', // Druh dokumentu
  DOCUMENT_REF_NUMBER = 'refNumber',
  REF_NUMBER_HISTORY = 'refNumberHistory',
  DOCUMENT_SUBJECT = 'documentSubject',
  EXTERNAL_ID = 'externalId',
  HANDOVER_DATE = 'handoverDate',
  DISTRIBUTION_DATE = 'distributionDate',
  IDENTIFICATION_STATUS = 'identificationStatus',
  IS_VALID = 'isValid', // represented by check mark icon
  MANUAL_UNPOST_REASON = 'manualUnpostReason',
  OFFICE_DESK_CATEGORY_ID = 'officeDeskCategoryId',
  OFFICE_DESK_DISTRIBUTION_NODE_ID = 'dispatchOfficeDistributionNodeId',
  OFFICE_DESK_REGION_ID = 'officeDeskRegionId',
  ORIGINATOR = 'originator',
  OWNER_FUNCTIONAL_POSITION_ID = 'ownerFunctionalPositionId',
  OWNER_ORGANIZATIONAL_UNIT_ID = 'ownerOrganizationalUnitId',
  PAYOUT_AMOUNT = 'payoutAmount.number',
  POSTED = 'posted', // Datum skutečného vyvěšení
  POSTING_DURATION = 'postingDuration',
  REJECTION_DATE = 'rejectionDate',
  REJECTION_REASON = 'rejectionReason',
  SELECTION = 'selection',
  STATUS = 'status',
  TAKEOVER_DATE = 'takeoverDate',
  TO_POST_FROM = 'toPostFrom',
  TO_POST_TO = 'toPostTo',
  UNPOSTED = 'unposted', // Datum skutečného svěšení
  UUID = 'uid.uid',
  WEIGHT = 'weight',
  BULK_POSTING_FORM_NAME = 'bulkPostingFormName',
  ORIGINAL_CONSIGNEE = 'originalConsignee',
  EXCLUDE_FROM_BPF = 'excludeFromBpf',
  CANCELLATION_REASON = 'cancellationReason',
  CANCELLATION_DATE = 'cancellationDate',
}

export const officerAllColumnSet = [
  OwnConsignmentColumnKey.SELECTION,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.DOCUMENT_SUBJECT,
  OwnConsignmentColumnKey.CONSIGNMENT_TYPE,
  OwnConsignmentColumnKey.CONSIGNEE,
  OwnConsignmentColumnKey.CONSIGNEE_ADDRESS,
  OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER,
  OwnConsignmentColumnKey.REF_NUMBER_HISTORY,
  OwnConsignmentColumnKey.UUID,
  OwnConsignmentColumnKey.DELIVERY_TYPE_ID,
  OwnConsignmentColumnKey.CREATED_AT,
  OwnConsignmentColumnKey.CREATED_BY_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_BASIC_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_ADDITIONAL_IDS,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_COMBINATION_ID,
  OwnConsignmentColumnKey.CONSIGNMENT_POSTING_NUMBER,
  OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER,
  OwnConsignmentColumnKey.DELIVERY_RESULT,
  OwnConsignmentColumnKey.OWNER_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.DISPATCH_DATE,
  OwnConsignmentColumnKey.DELIVERY_DATE,
  OwnConsignmentColumnKey.REJECTION_DATE,
  OwnConsignmentColumnKey.REJECTION_REASON,
  OwnConsignmentColumnKey.CANCELLATION_DATE,
  OwnConsignmentColumnKey.CANCELLATION_REASON,
  OwnConsignmentColumnKey.DISTRIBUTION_NODE_ID,
  OwnConsignmentColumnKey.OWNER_ORGANIZATIONAL_UNIT_ID,
  OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
];

export const officerInProgressColumnSet = [
  OwnConsignmentColumnKey.SELECTION,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.IS_VALID,
  OwnConsignmentColumnKey.DOCUMENT_SUBJECT,
  OwnConsignmentColumnKey.CONSIGNMENT_TYPE,
  OwnConsignmentColumnKey.CONSIGNEE,
  OwnConsignmentColumnKey.CONSIGNEE_ADDRESS,
  OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER,
  OwnConsignmentColumnKey.REF_NUMBER_HISTORY,
  OwnConsignmentColumnKey.UUID,
  OwnConsignmentColumnKey.DELIVERY_TYPE_ID,
  OwnConsignmentColumnKey.CREATED_AT,
  OwnConsignmentColumnKey.CREATED_BY_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_BASIC_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_ADDITIONAL_IDS,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_COMBINATION_ID,
  OwnConsignmentColumnKey.CONSIGNMENT_POSTING_NUMBER,
  OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER,
  OwnConsignmentColumnKey.OWNER_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.DISTRIBUTION_NODE_ID,
  OwnConsignmentColumnKey.OWNER_ORGANIZATIONAL_UNIT_ID,
  OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
];

export const officerRejectedColumnSet = [
  OwnConsignmentColumnKey.SELECTION,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.DOCUMENT_SUBJECT,
  OwnConsignmentColumnKey.IS_VALID,
  OwnConsignmentColumnKey.CONSIGNMENT_TYPE,
  OwnConsignmentColumnKey.CONSIGNEE,
  OwnConsignmentColumnKey.CONSIGNEE_ADDRESS,
  OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER,
  OwnConsignmentColumnKey.REF_NUMBER_HISTORY,
  OwnConsignmentColumnKey.UUID,
  OwnConsignmentColumnKey.DELIVERY_TYPE_ID,
  OwnConsignmentColumnKey.CREATED_AT,
  OwnConsignmentColumnKey.CREATED_BY_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_BASIC_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_ADDITIONAL_IDS,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_COMBINATION_ID,
  OwnConsignmentColumnKey.CONSIGNMENT_POSTING_NUMBER,
  OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER,
  OwnConsignmentColumnKey.OWNER_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.REJECTION_DATE,
  OwnConsignmentColumnKey.REJECTION_REASON,
  OwnConsignmentColumnKey.DISTRIBUTION_NODE_ID,
  OwnConsignmentColumnKey.OWNER_ORGANIZATIONAL_UNIT_ID,
  OwnConsignmentColumnKey.WEIGHT,
  OwnConsignmentColumnKey.PAYOUT_AMOUNT,
  OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
];

export const officerConsignmentsOnDocumentColumnSet = [
  OwnConsignmentColumnKey.SELECTION,
  OwnConsignmentColumnKey.DOCUMENT_SUBJECT,
  OwnConsignmentColumnKey.CONSIGNMENT_TYPE,
  OwnConsignmentColumnKey.CONSIGNEE,
  OwnConsignmentColumnKey.IS_VALID,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.DELIVERY_TYPE_ID,
  OwnConsignmentColumnKey.CONSIGNEE_ADDRESS,
  OwnConsignmentColumnKey.UUID,
  OwnConsignmentColumnKey.DELIVERY_RESULT,
  OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER,
  OwnConsignmentColumnKey.CREATED_AT,
  OwnConsignmentColumnKey.DISPATCH_DATE,
  OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
];

export const dispatchOfficerSpecificStatusColumnSet = [
  OwnConsignmentColumnKey.SELECTION,
  OwnConsignmentColumnKey.CONSIGNMENT_TYPE,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER,
  OwnConsignmentColumnKey.REF_NUMBER_HISTORY,
  OwnConsignmentColumnKey.OWNER_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.CONSIGNEE,
  OwnConsignmentColumnKey.CONSIGNEE_ADDRESS,
  OwnConsignmentColumnKey.DELIVERY_TYPE_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_COMBINATION_ID,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.UUID,
  OwnConsignmentColumnKey.CREATED_AT,
  OwnConsignmentColumnKey.CONSIGNMENT_POSTING_NUMBER,
  OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER,
  OwnConsignmentColumnKey.CREATED_BY_FUNCTIONAL_POSITION_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_BASIC_ID,
  OwnConsignmentColumnKey.DELIVERY_SERVICE_ADDITIONAL_IDS,
  OwnConsignmentColumnKey.WEIGHT,
  OwnConsignmentColumnKey.PAYOUT_AMOUNT,
  OwnConsignmentColumnKey.DISTRIBUTION_NODE_ID,
  OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
];

export const dispatchOfficerInDistributionColumnSet = [
  ...dispatchOfficerSpecificStatusColumnSet,
  OwnConsignmentColumnKey.EXCLUDE_FROM_BPF,
  OwnConsignmentColumnKey.DISPATCH_DATE,
  OwnConsignmentColumnKey.DISTRIBUTION_DATE,
  OwnConsignmentColumnKey.BULK_POSTING_FORM_NAME,
];

export const dispatchOfficerDispatchedColumnSet = [
  ...dispatchOfficerSpecificStatusColumnSet,
  OwnConsignmentColumnKey.DISPATCH_DATE,
  OwnConsignmentColumnKey.DELIVERY_DATE,
  OwnConsignmentColumnKey.DELIVERY_RESULT,
  OwnConsignmentColumnKey.DISTRIBUTION_DATE,
  OwnConsignmentColumnKey.BULK_POSTING_FORM_NAME,
];

// Delivery Result views offer the same columns as Officer ALL columns, but differ in what columns are displayed as default
export const recordDeliveryResultColumnSet = [
  ...officerAllColumnSet
];

// Office desk only columns below
export const officeDeskCommonColumnSet = [
  OwnConsignmentColumnKey.SELECTION,
  OwnConsignmentColumnKey.UUID,
  OwnConsignmentColumnKey.DOCUMENT_OBJECT_CLASS,
  OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER,
  OwnConsignmentColumnKey.DOCUMENT_SUBJECT,
  OwnConsignmentColumnKey.CONSIGNEE,
  OwnConsignmentColumnKey.ORIGINATOR,
  OwnConsignmentColumnKey.OFFICE_DESK_DISTRIBUTION_NODE_ID,
  OwnConsignmentColumnKey.OFFICE_DESK_CATEGORY_ID,
  OwnConsignmentColumnKey.OFFICE_DESK_REGION_ID,
  OwnConsignmentColumnKey.TO_POST_FROM,
  OwnConsignmentColumnKey.TO_POST_TO,
  OwnConsignmentColumnKey.CREATED_AT,
  OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
];

export const officeDeskOfficerViewAllColumnSet = [ // both OFFICER_VIEW_ALL and OFFICER_VIEW_ALL_POSTED routes
  ...officeDeskCommonColumnSet,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.CREATED_BY,
  OwnConsignmentColumnKey.POSTED,
  OwnConsignmentColumnKey.UNPOSTED,
  OwnConsignmentColumnKey.MANUAL_UNPOST_REASON,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.TAKEOVER_DATE,
  OwnConsignmentColumnKey.REJECTION_DATE,
  OwnConsignmentColumnKey.REJECTION_REASON,
  OwnConsignmentColumnKey.CANCELLATION_REASON,
  OwnConsignmentColumnKey.CANCELLATION_DATE,
];

export const officeDeskOfficerViewInProgressColumnSet = [
  ...officeDeskCommonColumnSet,
];

export const officeDeskOfficerViewRejectedColumnSet = [
  ...officeDeskCommonColumnSet,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.TAKEOVER_DATE,
  OwnConsignmentColumnKey.REJECTION_DATE,
  OwnConsignmentColumnKey.REJECTION_REASON,
];

export const officeDeskViewToTakeoverColumnSet = [
  ...officeDeskCommonColumnSet,
  OwnConsignmentColumnKey.CREATED_BY,
  OwnConsignmentColumnKey.HANDOVER_DATE,
];

export const officeDeskViewToPostColumnSet = [
  ...officeDeskCommonColumnSet,
  OwnConsignmentColumnKey.CREATED_BY,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.TAKEOVER_DATE,
];

export const officeDeskViewPostedColumnSet = [
  ...officeDeskCommonColumnSet,
  OwnConsignmentColumnKey.CREATED_BY,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.TAKEOVER_DATE,
  OwnConsignmentColumnKey.POSTED,
];

export const officeDeskViewUnpostedColumnSet = [
  ...officeDeskCommonColumnSet,
  OwnConsignmentColumnKey.STATUS,
  OwnConsignmentColumnKey.CREATED_BY,
  OwnConsignmentColumnKey.HANDOVER_DATE,
  OwnConsignmentColumnKey.TAKEOVER_DATE,
  OwnConsignmentColumnKey.POSTED,
  OwnConsignmentColumnKey.UNPOSTED,
  OwnConsignmentColumnKey.MANUAL_UNPOST_REASON,
];

function shouldDisplayPostingRange(tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView) {
  return tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL ||
    tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL_POSTED ||
    tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS ||
    tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER ||
    tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST;
}

export function getColumnsData(
  deliveryTypes$: Observable<DeliveryTypeDto[]>,
  deliveryResults$: Observable<DeliveryResultDto[]>,
  functionalPositions$: Observable<FunctionalPositionDto[]>,
  basicDeliveryServices$: Observable<DeliveryServiceBasicDto[]>,
  additionalDeliveryServices$: Observable<DeliveryServiceAdditionalDto[]>,
  deliveryServiceCombinations$: Observable<DeliveryServiceCombinationDto[]>,
  categories$: Observable<OfficeDeskCategoryDto[]>,
  regions$: Observable<OfficeDeskRegionDto[]>,
  officeDeskNodes$: Observable<OfficeDeskNodeDto[]>,
  distributionNodesAll$: Observable<DistributionNodeDto[]>,
  columns: OwnConsignmentColumnKey[],
  tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView,
  tab: Nullable<OwnConsignmentTableConsignmentTypeFilter>,
): TableColumnsData<OwnConsignmentColumnKey> {
  const columnDefinitions: ColumnDefinition<OwnConsignmentColumnKey>[] = [];
  columns.forEach(column => {
    let columnDefinition: ColumnDefinition<OwnConsignmentColumnKey>;
    switch (column) {
      case OwnConsignmentColumnKey.SELECTION:
        columnDefinition = {
          id: column,
          label: 'Výběr',
          hideLabelInHeader: true,
          filterType: FilterType.NONE,
        };
        break;

      case OwnConsignmentColumnKey.CONSIGNMENT_TYPE:
        columnDefinition = {
          id: column,
          label: 'Druh vypravení',
          fixedWidth: 32,
          // filtering by type only makes sense in the tab with various types
          filterType: tab === OwnConsignmentTableConsignmentTypeFilter.ALL ? FilterType.ENUM : FilterType.NONE,
          displayed: tableView !== OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT,
          list: enumToOptions('consignmentType', ConsignmentType)
            .filter(o => String(o.value).includes('OWN'))
            .map(o => ({
              ...o,
              icon: getConsignmentIcon(o.value as ConsignmentType),
            })),
          hideLabelInHeader: true,
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.IDENTIFICATION_STATUS:
        columnDefinition = {
          id: column,
          label: 'Stav identifikace',
          fixedWidth: 32,
          filterType: FilterType.NONE, // todo(rb) make it 'list after BE implemented
          hideLabelInHeader: true,
          icon: 'complete_subject',
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.CONSIGNEE:
        columnDefinition = {
          id: column,
          displayed: tableView !== OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER &&
            tableView !== OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST &&
            tableView !== OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_POSTED &&
            tableView !== OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_UNPOSTED,
          label: 'Komu posílám',
          filterType: FilterType.NONE, // todo(rb) determine filterType
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.IS_VALID:
        columnDefinition = {
          id: column,
          icon: 'checked',
          fixedWidth: 32,
          label: 'Validní',
          displayed: true,
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.STATUS:
        columnDefinition = {
          id: column,
          label: 'Stav',
          filterType: ([
            OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
            OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION,
            OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH,
            OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT
          ] as string[]).includes(tableView as string) ? FilterType.NONE : FilterType.ENUM,
          displayed: tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL || tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED || tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS,
          list: tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED ? arrayToOptions([
            OwnConsignmentStatus.DISPATCHED,
            OwnConsignmentStatus.DELIVERED,
            OwnConsignmentStatus.NOT_DELIVERED
          ], 'ownConsignmentStatus') : enumToOptions('ownConsignmentStatus', OwnConsignmentStatus),
        };
        break;

      case OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER:
        columnDefinition = {
          id: column,
          label: 'Číslo jednací',
          filterType: FilterType.TEXT,
        };
        break;

      case OwnConsignmentColumnKey.REF_NUMBER_HISTORY:
        columnDefinition = {
          id: column,
          label: 'Původní číslo jednací',
          filterType: FilterType.TEXT,
          displayed: false,
          filterConfig: {customFilterId: 'ownDocument.refNumberHistory'}
        };
        break;

      case OwnConsignmentColumnKey.DELIVERY_TYPE_ID:
        columnDefinition = {
          id: column,
          label: 'Způsob doručení',
          filterType: FilterType.ENUM,
          list$: deliveryTypes$.pipe(map(getDeliveryTypesForDispatchOptions)),
        };
        break;

      case OwnConsignmentColumnKey.CONSIGNEE_ADDRESS:
        columnDefinition = {
          id: column,
          label: 'Kam posílám',
          displayed: tableView !== OwnConsignmentTableView.OFFICER_VIEW_ALL,
          filterType: FilterType.NONE, // todo(rb) make it 'text after BE ready
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.HANDOVER_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum předání výpravně',
          filterType: FilterType.DATETIME,
          displayed: tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH ||
            tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER
        };
        break;

      case OwnConsignmentColumnKey.DISTRIBUTION_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum předání do distribuce',
          filterType: FilterType.DATETIME,
          displayed: tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION,
        };
        break;

      case OwnConsignmentColumnKey.TAKEOVER_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum převzetí',
          filterType: FilterType.DATETIME,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.UUID:
        columnDefinition = {
          id: column,
          label: 'UID zásilky',
          filterType: FilterType.TEXT,
          displayed: isOfficeDeskView(tableView) ||
            tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS ||
            tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL ||
            tableView === OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT ||
            tableView === OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT
        };
        break;

      case OwnConsignmentColumnKey.OUTBOUND_CONSIGNMENT_IDENTIFIER:
        columnDefinition = {
          id: column,
          label: 'Označení odchozí zásilky',
          filterType: FilterType.TEXT,
          displayed: false,
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.DELIVERY_RESULT:
        columnDefinition = {
          id: column,
          label: 'Výsledek doručení',
          filterType: FilterType.CODEBOOK,
          list$: deliveryResults$.pipe(
            namedDtosToOptions,
          ),
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.CONSIGNMENT_POSTING_NUMBER:
        columnDefinition = {
          id: column,
          label: 'ČDZ',
          filterType: FilterType.TEXT,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.OFFICE_DESK_REGION_ID:
        columnDefinition = {
          id: column,
          label: 'Oblast',
          filterType: FilterType.ENUM,
          list$: regions$.pipe(map(list => list.map(namedDtoToOption()))),
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.OFFICE_DESK_CATEGORY_ID:
        columnDefinition = {
          id: column,
          label: 'Kategorie',
          filterType: FilterType.ENUM,
          list$: categories$.pipe(map(list => list.map(namedDtoToOption()))),
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.CREATED_AT:
        columnDefinition = {
          id: column,
          label: 'Datum založení',
          filterType: FilterType.DATE,
          displayed: !isOfficeDeskView(tableView) &&
            tableView !== OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH &&
              tableView !== OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION &&
              tableView !== OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED,
        };
        break;

      case OwnConsignmentColumnKey.CREATED_BY_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column,
          label: 'Založil',
          filterType: FilterType.ENUM,
          list$: functionalPositions$.pipe(map(list => list.map(namedDtoToOption(true)))),
          disableSort: true,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.TO_POST_FROM:
        columnDefinition = {
          id: column,
          label: 'Vyvěsit od',
          filterType: FilterType.DATE,
          displayed: shouldDisplayPostingRange(tableView),
        };
        break;

      case OwnConsignmentColumnKey.TO_POST_TO:
        columnDefinition = {
          id: column,
          label: 'Vyvěsit do',
          filterType: FilterType.DATE,
          displayed: shouldDisplayPostingRange(tableView),
        };
        break;

      case OwnConsignmentColumnKey.POSTING_DURATION:
        columnDefinition = {
          id: column,
          label: 'Vyvěsit dní',
          filterType: FilterType.NUMBER,
          displayed: true,
        };
        break;

      case OwnConsignmentColumnKey.DISTRIBUTION_NODE_ID:
        columnDefinition = {
          id: column,
          label: 'Distribuční uzel',
          list$: distributionNodesAll$.pipe(map(list => list.map((entry: DistributionNodeDto) => ({
            value: entry.id!,
            label: entry.nodeName!, // dist nodes are nonstandard, missing both 'label' and 'name'
          })))),
          filterType: FilterType.CODEBOOK,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.DISPATCH_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum vypravení',
          filterType: FilterType.DATE,
        };
        break;

      case OwnConsignmentColumnKey.DELIVERY_SERVICE_BASIC_ID:
        columnDefinition = {
          id: column,
          label: 'Základní služba',
          filterType: FilterType.ENUM,
          list$: basicDeliveryServices$.pipe(map(list => list.map(namedDtoToOption()))),
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.DELIVERY_SERVICE_ADDITIONAL_IDS:
        columnDefinition = {
          id: column,
          label: 'Doplňkové služby',
          filterType: FilterType.ENUM,
          list$: additionalDeliveryServices$.pipe(map(list => list.map(namedDtoToOption()))),
          disableSort: true,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.DELIVERY_SERVICE_COMBINATION_ID:
        columnDefinition = {
          id: column,
          label: 'Doručovací služba',
          filterType: FilterType.ENUM,
          list$: deliveryServiceCombinations$.pipe(map(list => list.map(namedDtoToOption()))),
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.EXTERNAL_ID:
        columnDefinition = {
          id: column,
          label: 'Číslo zásilky',
          filterType: FilterType.TEXT,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.OWNER_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column,
          label: 'Zpracovatel dokumentu',
          filterType: FilterType.ENUM,
          list$: functionalPositions$.pipe(map(list => list.map(namedDtoToOption(true)))),
          disableSort: true,
          displayed:  tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
          filterConfig: {
            customFilterId: 'ownDocument.ownerFunctionalPositionId',
          }
        };
        break;

      case OwnConsignmentColumnKey.DOCUMENT_OBJECT_CLASS:
        columnDefinition = {
          id: column,
          displayed: false,
          label: 'Druh dokumentu',
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.DELIVERY_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum doručení',
          filterType: FilterType.DATE,
        };
        break;

      case OwnConsignmentColumnKey.EXCLUDE_FROM_BPF:
        columnDefinition = {
          id: column,
          label: 'Nepřidávat na arch',
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.DOCUMENT_SUBJECT:
        columnDefinition = {
          id: column,
          label: 'Věc',
          filterType: FilterType.NONE,
          disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE,
        };
        break;

      case OwnConsignmentColumnKey.OFFICE_DESK_DISTRIBUTION_NODE_ID:
        columnDefinition = {
          id: column,
          label: 'Úřední deska',
          displayed: tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL ||
            tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL_POSTED,
          list$: officeDeskNodes$.pipe(map(list => list.map((entry: OfficeDeskNodeDto) => ({
            value: entry.id!,
            label: entry.nodeName!, // dist nodes are nonstandard, missing both 'label' and 'name'
          })))),
          filterType: FilterType.ENUM,
        };
        break;

      case OwnConsignmentColumnKey.ORIGINATOR:
        columnDefinition = {
          id: column,
          label: 'Původce',
          filterType: FilterType.TEXT,
          displayed: tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
          disableSort: true,
        };
        break;

      case OwnConsignmentColumnKey.POSTED:
        columnDefinition = {
          id: column,
          label: 'Vyvěšeno',
          filterType: FilterType.DATE,
          displayed: true,
        };
        break;

      case OwnConsignmentColumnKey.UNPOSTED:
        columnDefinition = {
          id: column,
          label: 'Svěšeno',
          filterType: FilterType.DATE,
          displayed: true,
        };
        break;

      case OwnConsignmentColumnKey.REJECTION_REASON:
        columnDefinition = {
          id: column,
          label: 'Důvod vrácení',
          filterType: FilterType.TEXT,
          displayed: tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED ||
            tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED ||
            tableView === OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT ||
            tableView === OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT,
        };
        break;

      case OwnConsignmentColumnKey.REJECTION_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum vrácení',
          filterType: FilterType.DATE,
          displayed: tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED ||
            tableView === OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT,
        };
        break;

      case OwnConsignmentColumnKey.CANCELLATION_REASON:
        columnDefinition = {
          id: column,
          label: 'Důvod storna',
          filterType: FilterType.TEXT,
          displayed: tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED ||
            tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED,
        };
        break;

      case OwnConsignmentColumnKey.CANCELLATION_DATE:
        columnDefinition = {
          id: column,
          label: 'Datum storna',
          filterType: FilterType.DATE,
          displayed: tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED,
        };
        break;

      case OwnConsignmentColumnKey.MANUAL_UNPOST_REASON:
        columnDefinition = {
          id: column,
          label: 'Důvod předčasného svěšení',
          filterType: FilterType.TEXT,
        };
        break;

      case OwnConsignmentColumnKey.WEIGHT:
        columnDefinition = {
          id: column,
          label: 'Váha',
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.PAYOUT_AMOUNT:
        columnDefinition = {
          id: column,
          label: 'Výplatné',
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.BULK_POSTING_FORM_NAME:
        columnDefinition = {
          id: column,
          label: 'Podací arch',
          // todo(mh) ESSL-8737 allow filter and sort when implemented on BE
          filterType: FilterType.NONE,
          disableSort: true,
          displayed: false,
        };
        break;

      case OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE:
        columnDefinition = {
          id: column,
          label: 'Původní adresát',
          filterType: FilterType.TEXT,
          disableSort: true,
          displayed: false,
        };
        break;

      default:
        return;
    }
    columnDefinitions.push(columnDefinition);
  });

  return new TableColumnsData<OwnConsignmentColumnKey>(columnDefinitions);
}
