import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'icz-document-file-insufficient-permissions-alert',
  templateUrl: './document-file-insufficient-permissions-alert.component.html',
  styleUrls: ['./document-file-insufficient-permissions-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentFileInsufficientPermissionsAlertComponent {
  @Input()
  isShortAlert = false;

  get alertText(): string {
    const alertSentence = 'Nemáte dostatečné oprávnění pro zobrazení obsahu dokumentu/spisu.';
    const repairHint = 'Pokud potřebujete, požádejte držitele o nasdílení dokumentu/spisu.';

    if (this.isShortAlert) {
      return alertSentence;
    }
    else {
      return `${alertSentence} ${repairHint}`;
    }
  }
}
