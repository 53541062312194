import {
  Component,
  EventEmitter,
  HostBinding,
  inject,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AutoFocusDirective} from '../../essentials/auto-focus.directive';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {FavouritePageService} from '../../../services/favourite-page.service';
import {ActiveModuleService, ApplicationModule} from '../../../core/services/active-module.service';
import {ENVIRONMENT} from '../../../core/services/environment.service';
import {EnvironmentType} from '../../../core/services/environment.models';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {EsslApplicationRole} from '|api/commons';
import {TabItem} from '../../essentials/tabs/tabs.component.model';
import {animate, style, transition, trigger} from '@angular/animations';
import {MenuItemCountViewIds} from '../../../core/routing/menu-item-count-view-ids';

export interface MenuItemActionButton {
  icon: string;
  name: string;
  handler: () => void;
  isTestingFeature?: boolean;
}

export interface MenuItem {
  name: string;
  id: string;
  countViewId?: MenuItemCountViewIds;
  icon?: string;
  count?: Nullable<number>;
  isTestingFeature?: boolean;
  children?: MenuItem[];
  actionButtons?: MenuItemActionButton[];
  isAllowedFor?: EsslApplicationRole[];
}

enum SideMenuTab {
  MENU = 'MENU',
  FAVORITES = 'FAVORITES',
}

export function filterMenuItems<M extends MenuItem>(menuItems: M[], predicate: (menuItem: M) => boolean): M[] {
  const filteredMenuItems = menuItems.filter(menuItem => predicate(menuItem));

  for (const menuItem of filteredMenuItems) {
    if (menuItem.children) {
      // @ts-ignore
      menuItem.children = filterMenuItems(menuItem.children, predicate);
    }
  }

  return filteredMenuItems;
}

const TAB_ANIMATION_SPEED = 500; // ms
const SIDE_MENU_WIDTH = 300; // px

const TabAnimation = trigger(
  'tabAnimation',
  [
    transition('void => left', [
      style({transform: `translateX(${SIDE_MENU_WIDTH}px)`}),
      animate(`${TAB_ANIMATION_SPEED}ms`, style({transform: 'translateX(0)'})),
    ]),
    transition('left => void', [
      animate(`${TAB_ANIMATION_SPEED}ms`, style({transform: `translateX(-${SIDE_MENU_WIDTH}px)`})),
    ]),
    transition('void => right', [
      style({transform: `translateX(-${SIDE_MENU_WIDTH}px)`}),
      animate(`${TAB_ANIMATION_SPEED}ms`, style({transform: 'translateX(0)'})),
    ]),
    transition('right => void', [
      animate(`${TAB_ANIMATION_SPEED}ms`, style({transform: `translateX(${SIDE_MENU_WIDTH}px)`})),
    ]),
  ]
);

@Component({
  selector: 'icz-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    TabAnimation,
  ],
})
export class SideMenuComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private favouritePageService = inject(FavouritePageService);
  private activeModuleService = inject(ActiveModuleService);
  private environment = inject(ENVIRONMENT);

  @Output() closeClicked = new EventEmitter();
  @ViewChild(AutoFocusDirective, {static: true}) autoFocusDirective!: AutoFocusDirective;

  activeMenuTab = SideMenuTab.MENU;
  private activeMenuTabForAnimation = SideMenuTab.MENU;

  sideMenuTabs: TabItem<SideMenuTab>[] = [
    {
      id: SideMenuTab.MENU,
      label: 'Menu',
    },
    {
      id: SideMenuTab.FAVORITES,
      label: 'Oblíbené',
    }
  ];

  isNotificationDotVisible$ = this.favouritePageService.newFavouritePageHasBeenAdded$.pipe(
    map(newFavouritePageHasBeenAdded => {
      if (this.activeMenuTab === SideMenuTab.FAVORITES && newFavouritePageHasBeenAdded) {
        this.favouritePageService.resetNewFavouritePageHasBeenAdded();
      }
      return this.activeMenuTab === SideMenuTab.MENU && newFavouritePageHasBeenAdded;
    }),
  );

  @HostBinding('class.env-production')
  get isProductionEnv() {
    return (
      this.environment.environmentType === EnvironmentType.PRODUCTION ||
      this.environment.environmentType === EnvironmentType.DEVELOPMENT
    );
  }

  @HostBinding('class.env-training')
  get isTrainingEnv() {
    return this.environment.environmentType === EnvironmentType.TRAINING;
  }

  @HostBinding('class.env-testing')
  get isTestingEnv() {
    return this.environment.environmentType === EnvironmentType.TESTING;
  }

  get isMenuAdmin() {
    return this.activeModuleService.activeModule === ApplicationModule.ADMIN;
  }

  get isMenuConfig() {
    return this.activeModuleService.activeModule === ApplicationModule.CONFIG;
  }

  get isMenuMain() {
    return this.activeModuleService.activeModule === ApplicationModule.MAIN;
  }

  @HostBinding('class.nav-testing')
  get isMenuTesting() {
    return this.isTestingEnv;
  }

  @HostBinding('class.nav-edu')
  get isMenuEdu() {
    return this.isTrainingEnv;
  }

  @HostBinding('class.nav-main')
  get isProdMain() {
    return this.isProductionEnv && this.isMenuMain;
  }

  @HostBinding('class.nav-admin')
  get isProdAdmin() {
    return this.isProductionEnv && this.isMenuAdmin;
  }

  @HostBinding('class.nav-config')
  get isProdConfig() {
    return this.isProductionEnv && this.isMenuConfig;
  }

  get tabAnimationState() {
    return this.activeMenuTabForAnimation === SideMenuTab.MENU ? 'left' : 'right';
  }

  private favoritesMenu: MenuItem[] = [];

  favouriteMenu$: Observable<MenuItem[]> = combineLatest([
    this.favouritePageService.favouritePageList$,
    this.activeModuleService.activeModule$,
  ]).pipe(
    map(([pages, activeModule]) => pages.filter(p => p.module === activeModule)),
    map(pages => pages.map(({label, url}) => ({name: label, id: url, icon: 'favorite_on'}))),
    map(pages => [...this.favoritesMenu, ...pages]),
  );

  readonly SideMenuTab = SideMenuTab;

  ngOnInit() {
    if (location.host.includes('localhost')) {
      this.favoritesMenu = [
        {
          name: 'Demo',
          icon: 'empty',
          id: createAbsoluteRoute(ApplicationRoute.DEMO),
        },
      ];
    }
  }

  focus() {
    this.autoFocusDirective.focus();
  }

  activeTabChanged(tabItem: TabItem<SideMenuTab>) {
    this.activeMenuTab = tabItem.id;

    if (this.activeMenuTab === SideMenuTab.FAVORITES) {
      this.favouritePageService.resetNewFavouritePageHasBeenAdded();
    }

    setTimeout(() => {
      this.activeMenuTabForAnimation = tabItem.id;
    }, TAB_ANIMATION_SPEED);
  }

}
