<icz-button background svgIcon="{{ currentLanguage?.icon }}"
  [matMenuTriggerFor]="languageMenu" originalIconColor>
  {{ currentLanguage?.label! | translate | lowercase }}
  <icz-icon svgIcon="expand_more"></icz-icon>
</icz-button>

<mat-menu #languageMenu="matMenu">
  @for (language of availableLanguages; track language) {
    <icz-button
      class="language-selector-item"
      background svgIcon="{{ language.icon }}" originalIconColor
    (onAction)="changeLanguage(language.value)" [label]="language.label"></icz-button>
  }
</mat-menu>
