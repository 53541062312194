/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ConsignmentValidationResult } from '../models/consignment-validation-result';
import { CountDto } from '|api/commons';
import { DataBoxNodeDto } from '../models/data-box-node-dto';
import { DeliveryEventDto } from '../models/delivery-event-dto';
import { DistributionNodeValidationResult } from '../models/distribution-node-validation-result';
import { EmailNodeDto } from '../models/email-node-dto';
import { OfficeDeskNodeDto } from '../models/office-desk-node-dto';
import { ownConsignmentAutoPostUnpostToEdeska } from '../fn/own-consignment/own-consignment-auto-post-unpost-to-edeska';
import { OwnConsignmentAutoPostUnpostToEdeska$Params } from '../fn/own-consignment/own-consignment-auto-post-unpost-to-edeska';
import { ownConsignmentBulkCancel } from '../fn/own-consignment/own-consignment-bulk-cancel';
import { OwnConsignmentBulkCancel$Params } from '../fn/own-consignment/own-consignment-bulk-cancel';
import { ownConsignmentBulkCreateProofOfDelivery } from '../fn/own-consignment/own-consignment-bulk-create-proof-of-delivery';
import { OwnConsignmentBulkCreateProofOfDelivery$Params } from '../fn/own-consignment/own-consignment-bulk-create-proof-of-delivery';
import { ownConsignmentBulkDelete } from '../fn/own-consignment/own-consignment-bulk-delete';
import { OwnConsignmentBulkDelete$Params } from '../fn/own-consignment/own-consignment-bulk-delete';
import { ownConsignmentBulkHandover } from '../fn/own-consignment/own-consignment-bulk-handover';
import { OwnConsignmentBulkHandover$Params } from '../fn/own-consignment/own-consignment-bulk-handover';
import { ownConsignmentBulkMarkAsDispatchedPaper } from '../fn/own-consignment/own-consignment-bulk-mark-as-dispatched-paper';
import { OwnConsignmentBulkMarkAsDispatchedPaper$Params } from '../fn/own-consignment/own-consignment-bulk-mark-as-dispatched-paper';
import { ownConsignmentBulkPrepareForDistributionPaper } from '../fn/own-consignment/own-consignment-bulk-prepare-for-distribution-paper';
import { OwnConsignmentBulkPrepareForDistributionPaper$Params } from '../fn/own-consignment/own-consignment-bulk-prepare-for-distribution-paper';
import { ownConsignmentBulkReject } from '../fn/own-consignment/own-consignment-bulk-reject';
import { OwnConsignmentBulkReject$Params } from '../fn/own-consignment/own-consignment-bulk-reject';
import { ownConsignmentBulkTakeover } from '../fn/own-consignment/own-consignment-bulk-takeover';
import { OwnConsignmentBulkTakeover$Params } from '../fn/own-consignment/own-consignment-bulk-takeover';
import { ownConsignmentBulkTakeoverAndPrepareForDistributionPaper } from '../fn/own-consignment/own-consignment-bulk-takeover-and-prepare-for-distribution-paper';
import { OwnConsignmentBulkTakeoverAndPrepareForDistributionPaper$Params } from '../fn/own-consignment/own-consignment-bulk-takeover-and-prepare-for-distribution-paper';
import { ownConsignmentBulkWithdraw } from '../fn/own-consignment/own-consignment-bulk-withdraw';
import { OwnConsignmentBulkWithdraw$Params } from '../fn/own-consignment/own-consignment-bulk-withdraw';
import { ownConsignmentBulkWithdrawFromDistribution } from '../fn/own-consignment/own-consignment-bulk-withdraw-from-distribution';
import { OwnConsignmentBulkWithdrawFromDistribution$Params } from '../fn/own-consignment/own-consignment-bulk-withdraw-from-distribution';
import { ownConsignmentCancelDigital } from '../fn/own-consignment/own-consignment-cancel-digital';
import { OwnConsignmentCancelDigital$Params } from '../fn/own-consignment/own-consignment-cancel-digital';
import { ownConsignmentCancelInternalDigital } from '../fn/own-consignment/own-consignment-cancel-internal-digital';
import { OwnConsignmentCancelInternalDigital$Params } from '../fn/own-consignment/own-consignment-cancel-internal-digital';
import { ownConsignmentCancelInternalPaper } from '../fn/own-consignment/own-consignment-cancel-internal-paper';
import { OwnConsignmentCancelInternalPaper$Params } from '../fn/own-consignment/own-consignment-cancel-internal-paper';
import { ownConsignmentCancelOfficeDesk } from '../fn/own-consignment/own-consignment-cancel-office-desk';
import { OwnConsignmentCancelOfficeDesk$Params } from '../fn/own-consignment/own-consignment-cancel-office-desk';
import { ownConsignmentCancelPaper } from '../fn/own-consignment/own-consignment-cancel-paper';
import { OwnConsignmentCancelPaper$Params } from '../fn/own-consignment/own-consignment-cancel-paper';
import { ownConsignmentCheckLinkedConsignmentsByDistributionNodeId } from '../fn/own-consignment/own-consignment-check-linked-consignments-by-distribution-node-id';
import { OwnConsignmentCheckLinkedConsignmentsByDistributionNodeId$Params } from '../fn/own-consignment/own-consignment-check-linked-consignments-by-distribution-node-id';
import { ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer } from '../fn/own-consignment/own-consignment-count-office-desk-consignments-for-dispatch-officer';
import { OwnConsignmentCountOfficeDeskConsignmentsForDispatchOfficer$Params } from '../fn/own-consignment/own-consignment-count-office-desk-consignments-for-dispatch-officer';
import { ownConsignmentCountOwnConsignmentsForDocument } from '../fn/own-consignment/own-consignment-count-own-consignments-for-document';
import { OwnConsignmentCountOwnConsignmentsForDocument$Params } from '../fn/own-consignment/own-consignment-count-own-consignments-for-document';
import { ownConsignmentCreateOwnDigitalConsignment } from '../fn/own-consignment/own-consignment-create-own-digital-consignment';
import { OwnConsignmentCreateOwnDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-create-own-digital-consignment';
import { ownConsignmentCreateOwnInternalDigitalConsignment } from '../fn/own-consignment/own-consignment-create-own-internal-digital-consignment';
import { OwnConsignmentCreateOwnInternalDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-create-own-internal-digital-consignment';
import { ownConsignmentCreateOwnInternalPaperConsignment } from '../fn/own-consignment/own-consignment-create-own-internal-paper-consignment';
import { OwnConsignmentCreateOwnInternalPaperConsignment$Params } from '../fn/own-consignment/own-consignment-create-own-internal-paper-consignment';
import { ownConsignmentCreateOwnOfficeDesk } from '../fn/own-consignment/own-consignment-create-own-office-desk';
import { OwnConsignmentCreateOwnOfficeDesk$Params } from '../fn/own-consignment/own-consignment-create-own-office-desk';
import { ownConsignmentCreateOwnPaperConsignment } from '../fn/own-consignment/own-consignment-create-own-paper-consignment';
import { OwnConsignmentCreateOwnPaperConsignment$Params } from '../fn/own-consignment/own-consignment-create-own-paper-consignment';
import { ownConsignmentCreateOwnPortalConsignment } from '../fn/own-consignment/own-consignment-create-own-portal-consignment';
import { OwnConsignmentCreateOwnPortalConsignment$Params } from '../fn/own-consignment/own-consignment-create-own-portal-consignment';
import { ownConsignmentCreateOwnTelefaxConsignment } from '../fn/own-consignment/own-consignment-create-own-telefax-consignment';
import { OwnConsignmentCreateOwnTelefaxConsignment$Params } from '../fn/own-consignment/own-consignment-create-own-telefax-consignment';
import { ownConsignmentCreateProofOfDelivery } from '../fn/own-consignment/own-consignment-create-proof-of-delivery';
import { OwnConsignmentCreateProofOfDelivery$Params } from '../fn/own-consignment/own-consignment-create-proof-of-delivery';
import { ownConsignmentDeleteDigital } from '../fn/own-consignment/own-consignment-delete-digital';
import { OwnConsignmentDeleteDigital$Params } from '../fn/own-consignment/own-consignment-delete-digital';
import { ownConsignmentDeleteInternalDigital } from '../fn/own-consignment/own-consignment-delete-internal-digital';
import { OwnConsignmentDeleteInternalDigital$Params } from '../fn/own-consignment/own-consignment-delete-internal-digital';
import { ownConsignmentDeleteInternalPaper } from '../fn/own-consignment/own-consignment-delete-internal-paper';
import { OwnConsignmentDeleteInternalPaper$Params } from '../fn/own-consignment/own-consignment-delete-internal-paper';
import { ownConsignmentDeleteOfficeDesk } from '../fn/own-consignment/own-consignment-delete-office-desk';
import { OwnConsignmentDeleteOfficeDesk$Params } from '../fn/own-consignment/own-consignment-delete-office-desk';
import { ownConsignmentDeletePaper } from '../fn/own-consignment/own-consignment-delete-paper';
import { OwnConsignmentDeletePaper$Params } from '../fn/own-consignment/own-consignment-delete-paper';
import { ownConsignmentFindByIdOfficeDesk } from '../fn/own-consignment/own-consignment-find-by-id-office-desk';
import { OwnConsignmentFindByIdOfficeDesk$Params } from '../fn/own-consignment/own-consignment-find-by-id-office-desk';
import { ownConsignmentFindConsignmentsCountForDispatchOfficer } from '../fn/own-consignment/own-consignment-find-consignments-count-for-dispatch-officer';
import { OwnConsignmentFindConsignmentsCountForDispatchOfficer$Params } from '../fn/own-consignment/own-consignment-find-consignments-count-for-dispatch-officer';
import { ownConsignmentFindConsignmentsCountForOwner } from '../fn/own-consignment/own-consignment-find-consignments-count-for-owner';
import { OwnConsignmentFindConsignmentsCountForOwner$Params } from '../fn/own-consignment/own-consignment-find-consignments-count-for-owner';
import { ownConsignmentFindConsignmentsCountForSecretariat } from '../fn/own-consignment/own-consignment-find-consignments-count-for-secretariat';
import { ownConsignmentFindConsignmentsCountForSecretariat_1 } from '../fn/own-consignment/own-consignment-find-consignments-count-for-secretariat-1';
import { OwnConsignmentFindConsignmentsCountForSecretariat_1$Params } from '../fn/own-consignment/own-consignment-find-consignments-count-for-secretariat-1';
import { OwnConsignmentFindConsignmentsCountForSecretariat$Params } from '../fn/own-consignment/own-consignment-find-consignments-count-for-secretariat';
import { ownConsignmentFindConsignmentsForDispatchOfficer } from '../fn/own-consignment/own-consignment-find-consignments-for-dispatch-officer';
import { OwnConsignmentFindConsignmentsForDispatchOfficer$Params } from '../fn/own-consignment/own-consignment-find-consignments-for-dispatch-officer';
import { ownConsignmentFindConsignmentsForOwner } from '../fn/own-consignment/own-consignment-find-consignments-for-owner';
import { OwnConsignmentFindConsignmentsForOwner$Params } from '../fn/own-consignment/own-consignment-find-consignments-for-owner';
import { ownConsignmentFindConsignmentsForSecretariat } from '../fn/own-consignment/own-consignment-find-consignments-for-secretariat';
import { ownConsignmentFindConsignmentsForSecretariat_1 } from '../fn/own-consignment/own-consignment-find-consignments-for-secretariat-1';
import { OwnConsignmentFindConsignmentsForSecretariat_1$Params } from '../fn/own-consignment/own-consignment-find-consignments-for-secretariat-1';
import { OwnConsignmentFindConsignmentsForSecretariat$Params } from '../fn/own-consignment/own-consignment-find-consignments-for-secretariat';
import { ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer } from '../fn/own-consignment/own-consignment-find-office-desk-consignments-for-dispatch-officer';
import { OwnConsignmentFindOfficeDeskConsignmentsForDispatchOfficer$Params } from '../fn/own-consignment/own-consignment-find-office-desk-consignments-for-dispatch-officer';
import { ownConsignmentFindOfficeDeskConsignmentsForOwner } from '../fn/own-consignment/own-consignment-find-office-desk-consignments-for-owner';
import { OwnConsignmentFindOfficeDeskConsignmentsForOwner$Params } from '../fn/own-consignment/own-consignment-find-office-desk-consignments-for-owner';
import { ownConsignmentFindOfficeDeskNodesForFunctionalPosition } from '../fn/own-consignment/own-consignment-find-office-desk-nodes-for-functional-position';
import { OwnConsignmentFindOfficeDeskNodesForFunctionalPosition$Params } from '../fn/own-consignment/own-consignment-find-office-desk-nodes-for-functional-position';
import { ownConsignmentFindOwnConsignmentsForDocument } from '../fn/own-consignment/own-consignment-find-own-consignments-for-document';
import { OwnConsignmentFindOwnConsignmentsForDocument$Params } from '../fn/own-consignment/own-consignment-find-own-consignments-for-document';
import { ownConsignmentFindOwnConsignmentsForOwnDocument } from '../fn/own-consignment/own-consignment-find-own-consignments-for-own-document';
import { OwnConsignmentFindOwnConsignmentsForOwnDocument$Params } from '../fn/own-consignment/own-consignment-find-own-consignments-for-own-document';
import { ownConsignmentGetDeliveryEvents } from '../fn/own-consignment/own-consignment-get-delivery-events';
import { OwnConsignmentGetDeliveryEvents$Params } from '../fn/own-consignment/own-consignment-get-delivery-events';
import { ownConsignmentGetEnvelopePrint } from '../fn/own-consignment/own-consignment-get-envelope-print';
import { OwnConsignmentGetEnvelopePrint$Params } from '../fn/own-consignment/own-consignment-get-envelope-print';
import { ownConsignmentGetInternalPaperEnvelopePreview } from '../fn/own-consignment/own-consignment-get-internal-paper-envelope-preview';
import { OwnConsignmentGetInternalPaperEnvelopePreview$Params } from '../fn/own-consignment/own-consignment-get-internal-paper-envelope-preview';
import { ownConsignmentGetInternalPaperEnvelopePreviewWithUid } from '../fn/own-consignment/own-consignment-get-internal-paper-envelope-preview-with-uid';
import { OwnConsignmentGetInternalPaperEnvelopePreviewWithUid$Params } from '../fn/own-consignment/own-consignment-get-internal-paper-envelope-preview-with-uid';
import { ownConsignmentGetOrganizationOutcomeDistributionNodes } from '../fn/own-consignment/own-consignment-get-organization-outcome-distribution-nodes';
import { OwnConsignmentGetOrganizationOutcomeDistributionNodes$Params } from '../fn/own-consignment/own-consignment-get-organization-outcome-distribution-nodes';
import { ownConsignmentGetOwnDigitalConsignment } from '../fn/own-consignment/own-consignment-get-own-digital-consignment';
import { OwnConsignmentGetOwnDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-get-own-digital-consignment';
import { ownConsignmentGetOwnInternalConsignmentConfiguration } from '../fn/own-consignment/own-consignment-get-own-internal-consignment-configuration';
import { OwnConsignmentGetOwnInternalConsignmentConfiguration$Params } from '../fn/own-consignment/own-consignment-get-own-internal-consignment-configuration';
import { ownConsignmentGetOwnInternalDigitalConsignment } from '../fn/own-consignment/own-consignment-get-own-internal-digital-consignment';
import { OwnConsignmentGetOwnInternalDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-get-own-internal-digital-consignment';
import { ownConsignmentGetOwnInternalPaperConsignment } from '../fn/own-consignment/own-consignment-get-own-internal-paper-consignment';
import { OwnConsignmentGetOwnInternalPaperConsignment$Params } from '../fn/own-consignment/own-consignment-get-own-internal-paper-consignment';
import { ownConsignmentGetOwnPaperConsignment } from '../fn/own-consignment/own-consignment-get-own-paper-consignment';
import { OwnConsignmentGetOwnPaperConsignment$Params } from '../fn/own-consignment/own-consignment-get-own-paper-consignment';
import { ownConsignmentGetPaperEnvelopePreview } from '../fn/own-consignment/own-consignment-get-paper-envelope-preview';
import { OwnConsignmentGetPaperEnvelopePreview$Params } from '../fn/own-consignment/own-consignment-get-paper-envelope-preview';
import { ownConsignmentGetPaperEnvelopePreviewWithUid } from '../fn/own-consignment/own-consignment-get-paper-envelope-preview-with-uid';
import { OwnConsignmentGetPaperEnvelopePreviewWithUid$Params } from '../fn/own-consignment/own-consignment-get-paper-envelope-preview-with-uid';
import { ownConsignmentGetProofOfDelivery } from '../fn/own-consignment/own-consignment-get-proof-of-delivery';
import { OwnConsignmentGetProofOfDelivery$Params } from '../fn/own-consignment/own-consignment-get-proof-of-delivery';
import { ownConsignmentGetProofOfDeliveryForConsignment } from '../fn/own-consignment/own-consignment-get-proof-of-delivery-for-consignment';
import { OwnConsignmentGetProofOfDeliveryForConsignment$Params } from '../fn/own-consignment/own-consignment-get-proof-of-delivery-for-consignment';
import { ownConsignmentGetSheetLabelPrint } from '../fn/own-consignment/own-consignment-get-sheet-label-print';
import { OwnConsignmentGetSheetLabelPrint$Params } from '../fn/own-consignment/own-consignment-get-sheet-label-print';
import { ownConsignmentHandoverDigital } from '../fn/own-consignment/own-consignment-handover-digital';
import { OwnConsignmentHandoverDigital$Params } from '../fn/own-consignment/own-consignment-handover-digital';
import { ownConsignmentHandoverInternalDigital } from '../fn/own-consignment/own-consignment-handover-internal-digital';
import { OwnConsignmentHandoverInternalDigital$Params } from '../fn/own-consignment/own-consignment-handover-internal-digital';
import { ownConsignmentHandoverInternalPaper } from '../fn/own-consignment/own-consignment-handover-internal-paper';
import { OwnConsignmentHandoverInternalPaper$Params } from '../fn/own-consignment/own-consignment-handover-internal-paper';
import { ownConsignmentHandoverOfficeDesk } from '../fn/own-consignment/own-consignment-handover-office-desk';
import { OwnConsignmentHandoverOfficeDesk$Params } from '../fn/own-consignment/own-consignment-handover-office-desk';
import { ownConsignmentHandoverPaper } from '../fn/own-consignment/own-consignment-handover-paper';
import { OwnConsignmentHandoverPaper$Params } from '../fn/own-consignment/own-consignment-handover-paper';
import { ownConsignmentManualPostOfficeDesk } from '../fn/own-consignment/own-consignment-manual-post-office-desk';
import { OwnConsignmentManualPostOfficeDesk$Params } from '../fn/own-consignment/own-consignment-manual-post-office-desk';
import { ownConsignmentManualUnpostOfficeDesk } from '../fn/own-consignment/own-consignment-manual-unpost-office-desk';
import { OwnConsignmentManualUnpostOfficeDesk$Params } from '../fn/own-consignment/own-consignment-manual-unpost-office-desk';
import { ownConsignmentMarkAsDispatchedInternalPaper } from '../fn/own-consignment/own-consignment-mark-as-dispatched-internal-paper';
import { OwnConsignmentMarkAsDispatchedInternalPaper$Params } from '../fn/own-consignment/own-consignment-mark-as-dispatched-internal-paper';
import { ownConsignmentMarkAsDispatchedPaper } from '../fn/own-consignment/own-consignment-mark-as-dispatched-paper';
import { OwnConsignmentMarkAsDispatchedPaper$Params } from '../fn/own-consignment/own-consignment-mark-as-dispatched-paper';
import { ownConsignmentMarkNonEpoAsDispatched } from '../fn/own-consignment/own-consignment-mark-non-epo-as-dispatched';
import { OwnConsignmentMarkNonEpoAsDispatched$Params } from '../fn/own-consignment/own-consignment-mark-non-epo-as-dispatched';
import { OwnConsignmentPerStateCountForDispatchOfficerDto } from '../models/own-consignment-per-state-count-for-dispatch-officer-dto';
import { OwnConsignmentPerStateCountForOwnerDto } from '../models/own-consignment-per-state-count-for-owner-dto';
import { ownConsignmentPrepareForDistributionInternalPaper } from '../fn/own-consignment/own-consignment-prepare-for-distribution-internal-paper';
import { OwnConsignmentPrepareForDistributionInternalPaper$Params } from '../fn/own-consignment/own-consignment-prepare-for-distribution-internal-paper';
import { ownConsignmentPrepareForDistributionPaper } from '../fn/own-consignment/own-consignment-prepare-for-distribution-paper';
import { OwnConsignmentPrepareForDistributionPaper$Params } from '../fn/own-consignment/own-consignment-prepare-for-distribution-paper';
import { ownConsignmentPrintLabelByDocumentId } from '../fn/own-consignment/own-consignment-print-label-by-document-id';
import { OwnConsignmentPrintLabelByDocumentId$Params } from '../fn/own-consignment/own-consignment-print-label-by-document-id';
import { ownConsignmentRejectDigital } from '../fn/own-consignment/own-consignment-reject-digital';
import { OwnConsignmentRejectDigital$Params } from '../fn/own-consignment/own-consignment-reject-digital';
import { ownConsignmentRejectInternalDigital } from '../fn/own-consignment/own-consignment-reject-internal-digital';
import { OwnConsignmentRejectInternalDigital$Params } from '../fn/own-consignment/own-consignment-reject-internal-digital';
import { ownConsignmentRejectInternalPaper } from '../fn/own-consignment/own-consignment-reject-internal-paper';
import { OwnConsignmentRejectInternalPaper$Params } from '../fn/own-consignment/own-consignment-reject-internal-paper';
import { ownConsignmentRejectOfficeDesk } from '../fn/own-consignment/own-consignment-reject-office-desk';
import { OwnConsignmentRejectOfficeDesk$Params } from '../fn/own-consignment/own-consignment-reject-office-desk';
import { ownConsignmentRejectPaper } from '../fn/own-consignment/own-consignment-reject-paper';
import { OwnConsignmentRejectPaper$Params } from '../fn/own-consignment/own-consignment-reject-paper';
import { ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-dispatch-officer';
import { OwnConsignmentReturnConsignmentsPerStateCountForDispatchOfficer$Params } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-dispatch-officer';
import { ownConsignmentReturnConsignmentsPerStateCountForOwner } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-owner';
import { OwnConsignmentReturnConsignmentsPerStateCountForOwner$Params } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-owner';
import { ownConsignmentReturnConsignmentsPerStateCountForSecretariat } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-secretariat';
import { ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1 } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-secretariat-1';
import { OwnConsignmentReturnConsignmentsPerStateCountForSecretariat_1$Params } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-secretariat-1';
import { OwnConsignmentReturnConsignmentsPerStateCountForSecretariat$Params } from '../fn/own-consignment/own-consignment-return-consignments-per-state-count-for-secretariat';
import { ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer } from '../fn/own-consignment/own-consignment-return-office-desk-consignments-per-state-count-for-dispatch-officer';
import { OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer$Params } from '../fn/own-consignment/own-consignment-return-office-desk-consignments-per-state-count-for-dispatch-officer';
import { ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner } from '../fn/own-consignment/own-consignment-return-office-desk-consignments-per-state-count-for-owner';
import { OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner$Params } from '../fn/own-consignment/own-consignment-return-office-desk-consignments-per-state-count-for-owner';
import { ownConsignmentTakeoverAndDispatchDigital } from '../fn/own-consignment/own-consignment-takeover-and-dispatch-digital';
import { OwnConsignmentTakeoverAndDispatchDigital$Params } from '../fn/own-consignment/own-consignment-takeover-and-dispatch-digital';
import { ownConsignmentTakeoverAndDispatchInternalDigital } from '../fn/own-consignment/own-consignment-takeover-and-dispatch-internal-digital';
import { OwnConsignmentTakeoverAndDispatchInternalDigital$Params } from '../fn/own-consignment/own-consignment-takeover-and-dispatch-internal-digital';
import { ownConsignmentTakeoverAndPrepareForDistributionInternalPaper } from '../fn/own-consignment/own-consignment-takeover-and-prepare-for-distribution-internal-paper';
import { OwnConsignmentTakeoverAndPrepareForDistributionInternalPaper$Params } from '../fn/own-consignment/own-consignment-takeover-and-prepare-for-distribution-internal-paper';
import { ownConsignmentTakeoverAndPrepareForDistributionPaper } from '../fn/own-consignment/own-consignment-takeover-and-prepare-for-distribution-paper';
import { OwnConsignmentTakeoverAndPrepareForDistributionPaper$Params } from '../fn/own-consignment/own-consignment-takeover-and-prepare-for-distribution-paper';
import { ownConsignmentTakeoverInternalPaper } from '../fn/own-consignment/own-consignment-takeover-internal-paper';
import { OwnConsignmentTakeoverInternalPaper$Params } from '../fn/own-consignment/own-consignment-takeover-internal-paper';
import { ownConsignmentTakeoverOfficeDesk } from '../fn/own-consignment/own-consignment-takeover-office-desk';
import { OwnConsignmentTakeoverOfficeDesk$Params } from '../fn/own-consignment/own-consignment-takeover-office-desk';
import { ownConsignmentTakeoverPaper } from '../fn/own-consignment/own-consignment-takeover-paper';
import { OwnConsignmentTakeoverPaper$Params } from '../fn/own-consignment/own-consignment-takeover-paper';
import { ownConsignmentUpdateOwnDigitalConsignment } from '../fn/own-consignment/own-consignment-update-own-digital-consignment';
import { OwnConsignmentUpdateOwnDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-update-own-digital-consignment';
import { ownConsignmentUpdateOwnInternalConsignmentConfiguration } from '../fn/own-consignment/own-consignment-update-own-internal-consignment-configuration';
import { OwnConsignmentUpdateOwnInternalConsignmentConfiguration$Params } from '../fn/own-consignment/own-consignment-update-own-internal-consignment-configuration';
import { ownConsignmentUpdateOwnInternalDigitalConsignment } from '../fn/own-consignment/own-consignment-update-own-internal-digital-consignment';
import { OwnConsignmentUpdateOwnInternalDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-update-own-internal-digital-consignment';
import { ownConsignmentUpdateOwnInternalPaperConsignment } from '../fn/own-consignment/own-consignment-update-own-internal-paper-consignment';
import { OwnConsignmentUpdateOwnInternalPaperConsignment$Params } from '../fn/own-consignment/own-consignment-update-own-internal-paper-consignment';
import { ownConsignmentUpdateOwnOfficeDeskConsignment } from '../fn/own-consignment/own-consignment-update-own-office-desk-consignment';
import { OwnConsignmentUpdateOwnOfficeDeskConsignment$Params } from '../fn/own-consignment/own-consignment-update-own-office-desk-consignment';
import { ownConsignmentUpdateOwnPaperConsignment } from '../fn/own-consignment/own-consignment-update-own-paper-consignment';
import { OwnConsignmentUpdateOwnPaperConsignment$Params } from '../fn/own-consignment/own-consignment-update-own-paper-consignment';
import { ownConsignmentValidateOwnDigitalConsignment } from '../fn/own-consignment/own-consignment-validate-own-digital-consignment';
import { OwnConsignmentValidateOwnDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-validate-own-digital-consignment';
import { ownConsignmentValidateOwnInternalDigitalConsignment } from '../fn/own-consignment/own-consignment-validate-own-internal-digital-consignment';
import { OwnConsignmentValidateOwnInternalDigitalConsignment$Params } from '../fn/own-consignment/own-consignment-validate-own-internal-digital-consignment';
import { ownConsignmentValidateOwnInternalPaperConsignment } from '../fn/own-consignment/own-consignment-validate-own-internal-paper-consignment';
import { OwnConsignmentValidateOwnInternalPaperConsignment$Params } from '../fn/own-consignment/own-consignment-validate-own-internal-paper-consignment';
import { ownConsignmentValidateOwnOfficeDeskConsignment } from '../fn/own-consignment/own-consignment-validate-own-office-desk-consignment';
import { OwnConsignmentValidateOwnOfficeDeskConsignment$Params } from '../fn/own-consignment/own-consignment-validate-own-office-desk-consignment';
import { ownConsignmentValidateOwnPaperConsignment } from '../fn/own-consignment/own-consignment-validate-own-paper-consignment';
import { OwnConsignmentValidateOwnPaperConsignment$Params } from '../fn/own-consignment/own-consignment-validate-own-paper-consignment';
import { ownConsignmentValidateSubjectAddresses } from '../fn/own-consignment/own-consignment-validate-subject-addresses';
import { OwnConsignmentValidateSubjectAddresses$Params } from '../fn/own-consignment/own-consignment-validate-subject-addresses';
import { ownConsignmentWithdrawDigital } from '../fn/own-consignment/own-consignment-withdraw-digital';
import { OwnConsignmentWithdrawDigital$Params } from '../fn/own-consignment/own-consignment-withdraw-digital';
import { ownConsignmentWithdrawFromDistributionInternalPaper } from '../fn/own-consignment/own-consignment-withdraw-from-distribution-internal-paper';
import { OwnConsignmentWithdrawFromDistributionInternalPaper$Params } from '../fn/own-consignment/own-consignment-withdraw-from-distribution-internal-paper';
import { ownConsignmentWithdrawFromDistributionPaper } from '../fn/own-consignment/own-consignment-withdraw-from-distribution-paper';
import { OwnConsignmentWithdrawFromDistributionPaper$Params } from '../fn/own-consignment/own-consignment-withdraw-from-distribution-paper';
import { ownConsignmentWithdrawInternalDigital } from '../fn/own-consignment/own-consignment-withdraw-internal-digital';
import { OwnConsignmentWithdrawInternalDigital$Params } from '../fn/own-consignment/own-consignment-withdraw-internal-digital';
import { ownConsignmentWithdrawInternalPaper } from '../fn/own-consignment/own-consignment-withdraw-internal-paper';
import { OwnConsignmentWithdrawInternalPaper$Params } from '../fn/own-consignment/own-consignment-withdraw-internal-paper';
import { ownConsignmentWithdrawOfficeDesk } from '../fn/own-consignment/own-consignment-withdraw-office-desk';
import { OwnConsignmentWithdrawOfficeDesk$Params } from '../fn/own-consignment/own-consignment-withdraw-office-desk';
import { ownConsignmentWithdrawPaper } from '../fn/own-consignment/own-consignment-withdraw-paper';
import { OwnConsignmentWithdrawPaper$Params } from '../fn/own-consignment/own-consignment-withdraw-paper';
import { OwnDigitalConsignmentDto } from '../models/own-digital-consignment-dto';
import { OwnInternalConsignmentConfigurationDto } from '../models/own-internal-consignment-configuration-dto';
import { OwnInternalDigitalConsignmentDto } from '../models/own-internal-digital-consignment-dto';
import { OwnInternalPaperConsignmentDto } from '../models/own-internal-paper-consignment-dto';
import { OwnOfficeDeskConsignmentDto } from '../models/own-office-desk-consignment-dto';
import { OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto } from '../models/own-office-desk-consignment-per-state-count-for-dispatch-officer-dto';
import { OwnOfficeDeskConsignmentPerStateCountForOwnerDto } from '../models/own-office-desk-consignment-per-state-count-for-owner-dto';
import { OwnPaperConsignmentDto } from '../models/own-paper-consignment-dto';
import { OwnPortalConsignmentDto } from '../models/own-portal-consignment-dto';
import { OwnTelefaxConsignmentDto } from '../models/own-telefax-consignment-dto';
import { PageOwnConsignmentDto } from '../models/page-own-consignment-dto';
import { PageOwnOfficeDeskConsignmentDto } from '../models/page-own-office-desk-consignment-dto';
import { ProofOfDeliveryDto } from '../models/proof-of-delivery-dto';
import { SheetNodeDto } from '../models/sheet-node-dto';


/**
 * Contains endpoints related to Own Consignment
 */
@Injectable({ providedIn: 'root' })
export class ApiOwnConsignmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ownConsignmentGetOwnPaperConsignment()` */
  static readonly OwnConsignmentGetOwnPaperConsignmentPath = '/sad/own-consignment/paper/{id}';

  /**
   * Own Paper Consignment Get.
   *
   * Gets own paper consignment by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetOwnPaperConsignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnPaperConsignment$Response(params: OwnConsignmentGetOwnPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentGetOwnPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Paper Consignment Get.
   *
   * Gets own paper consignment by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetOwnPaperConsignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnPaperConsignment(params: OwnConsignmentGetOwnPaperConsignment$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentGetOwnPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentUpdateOwnPaperConsignment()` */
  static readonly OwnConsignmentUpdateOwnPaperConsignmentPath = '/sad/own-consignment/paper/{id}';

  /**
   * Own Paper Consignment update.
   *
   * Updates Own Paper Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentUpdateOwnPaperConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnPaperConsignment$Response(params: OwnConsignmentUpdateOwnPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentUpdateOwnPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Paper Consignment update.
   *
   * Updates Own Paper Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentUpdateOwnPaperConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnPaperConsignment(params: OwnConsignmentUpdateOwnPaperConsignment$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentUpdateOwnPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentDeletePaper()` */
  static readonly OwnConsignmentDeletePaperPath = '/sad/own-consignment/paper/{id}';

  /**
   * Consignment Deletion.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentDeletePaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeletePaper$Response(params: OwnConsignmentDeletePaper$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentDeletePaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Deletion.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentDeletePaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeletePaper(params: OwnConsignmentDeletePaper$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentDeletePaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentValidateSubjectAddresses()` */
  static readonly OwnConsignmentValidateSubjectAddressesPath = '/sad/own-consignment/paper/validate-subject-addresses/{subjectId}';

  /**
   * Validate Subject Own Paper Consignment Addresses.
   *
   * Validate Subject Own Paper Consignment Addresses
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentValidateSubjectAddresses()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentValidateSubjectAddresses$Response(params: OwnConsignmentValidateSubjectAddresses$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentValidateSubjectAddresses(this.http, this.rootUrl, params, context);
  }

  /**
   * Validate Subject Own Paper Consignment Addresses.
   *
   * Validate Subject Own Paper Consignment Addresses
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentValidateSubjectAddresses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentValidateSubjectAddresses(params: OwnConsignmentValidateSubjectAddresses$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentValidateSubjectAddresses$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetOwnInternalPaperConsignment()` */
  static readonly OwnConsignmentGetOwnInternalPaperConsignmentPath = '/sad/own-consignment/paper/internal/{id}';

  /**
   * Own Internal Paper Consignment Get.
   *
   * Gets own internal paper consignment by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetOwnInternalPaperConsignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnInternalPaperConsignment$Response(params: OwnConsignmentGetOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentGetOwnInternalPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Paper Consignment Get.
   *
   * Gets own internal paper consignment by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetOwnInternalPaperConsignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnInternalPaperConsignment(params: OwnConsignmentGetOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentGetOwnInternalPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentUpdateOwnInternalPaperConsignment()` */
  static readonly OwnConsignmentUpdateOwnInternalPaperConsignmentPath = '/sad/own-consignment/paper/internal/{id}';

  /**
   * Own Internal Paper Consignment update.
   *
   * Updates Own Internal Paper Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentUpdateOwnInternalPaperConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnInternalPaperConsignment$Response(params: OwnConsignmentUpdateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentUpdateOwnInternalPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Paper Consignment update.
   *
   * Updates Own Internal Paper Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentUpdateOwnInternalPaperConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnInternalPaperConsignment(params: OwnConsignmentUpdateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentUpdateOwnInternalPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentDeleteInternalPaper()` */
  static readonly OwnConsignmentDeleteInternalPaperPath = '/sad/own-consignment/paper/internal/{id}';

  /**
   * Consignment Deletion.
   *
   * Hands over a internal consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentDeleteInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteInternalPaper$Response(params: OwnConsignmentDeleteInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentDeleteInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Deletion.
   *
   * Hands over a internal consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentDeleteInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteInternalPaper(params: OwnConsignmentDeleteInternalPaper$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentDeleteInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindByIdOfficeDesk()` */
  static readonly OwnConsignmentFindByIdOfficeDeskPath = '/sad/own-consignment/office-desk/{id}';

  /**
   * Office Desk Consignment Get.
   *
   * Gets office desk consignment by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindByIdOfficeDesk()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindByIdOfficeDesk$Response(params: OwnConsignmentFindByIdOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentFindByIdOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office Desk Consignment Get.
   *
   * Gets office desk consignment by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindByIdOfficeDesk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindByIdOfficeDesk(params: OwnConsignmentFindByIdOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentFindByIdOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentUpdateOwnOfficeDeskConsignment()` */
  static readonly OwnConsignmentUpdateOwnOfficeDeskConsignmentPath = '/sad/own-consignment/office-desk/{id}';

  /**
   * Own Office Desk Consignment update.
   *
   * Updates Office Desk Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentUpdateOwnOfficeDeskConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnOfficeDeskConsignment$Response(params: OwnConsignmentUpdateOwnOfficeDeskConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentUpdateOwnOfficeDeskConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Office Desk Consignment update.
   *
   * Updates Office Desk Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentUpdateOwnOfficeDeskConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnOfficeDeskConsignment(params: OwnConsignmentUpdateOwnOfficeDeskConsignment$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentUpdateOwnOfficeDeskConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentDeleteOfficeDesk()` */
  static readonly OwnConsignmentDeleteOfficeDeskPath = '/sad/own-consignment/office-desk/{id}';

  /**
   * Consignment Deletion.
   *
   * Deletes Office Desk Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentDeleteOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteOfficeDesk$Response(params: OwnConsignmentDeleteOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentDeleteOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Deletion.
   *
   * Deletes Office Desk Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentDeleteOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteOfficeDesk(params: OwnConsignmentDeleteOfficeDesk$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentDeleteOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentAutoPostUnpostToEdeska()` */
  static readonly OwnConsignmentAutoPostUnpostToEdeskaPath = '/sad/own-consignment/office-desk/autoPostUnpostToEdeska';

  /**
   * Post/unpost to edeska, when is postFrom today and autoPosting active
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentAutoPostUnpostToEdeska()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentAutoPostUnpostToEdeska$Response(params?: OwnConsignmentAutoPostUnpostToEdeska$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentAutoPostUnpostToEdeska(this.http, this.rootUrl, params, context);
  }

  /**
   * Post/unpost to edeska, when is postFrom today and autoPosting active
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentAutoPostUnpostToEdeska$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentAutoPostUnpostToEdeska(params?: OwnConsignmentAutoPostUnpostToEdeska$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.ownConsignmentAutoPostUnpostToEdeska$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetOwnInternalConsignmentConfiguration()` */
  static readonly OwnConsignmentGetOwnInternalConsignmentConfigurationPath = '/sad/own-consignment/internal/configuration';

  /**
   * Own Internal Consignment Configuration Get.
   *
   * Gets own internal consignment configuration.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetOwnInternalConsignmentConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnInternalConsignmentConfiguration$Response(params?: OwnConsignmentGetOwnInternalConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalConsignmentConfigurationDto>> {
    return ownConsignmentGetOwnInternalConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Consignment Configuration Get.
   *
   * Gets own internal consignment configuration.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetOwnInternalConsignmentConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnInternalConsignmentConfiguration(params?: OwnConsignmentGetOwnInternalConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<OwnInternalConsignmentConfigurationDto> {
    return this.ownConsignmentGetOwnInternalConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalConsignmentConfigurationDto>): OwnInternalConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentUpdateOwnInternalConsignmentConfiguration()` */
  static readonly OwnConsignmentUpdateOwnInternalConsignmentConfigurationPath = '/sad/own-consignment/internal/configuration';

  /**
   * Own Internal Consignment Configuration Update.
   *
   * Updates Own Internal Consignment Configuration.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentUpdateOwnInternalConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnInternalConsignmentConfiguration$Response(params: OwnConsignmentUpdateOwnInternalConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentUpdateOwnInternalConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Consignment Configuration Update.
   *
   * Updates Own Internal Consignment Configuration.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentUpdateOwnInternalConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnInternalConsignmentConfiguration(params: OwnConsignmentUpdateOwnInternalConsignmentConfiguration$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentUpdateOwnInternalConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetOwnDigitalConsignment()` */
  static readonly OwnConsignmentGetOwnDigitalConsignmentPath = '/sad/own-consignment/digital/{id}';

  /**
   * Own Digital Consignment Get.
   *
   * Gets own digital consignment by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetOwnDigitalConsignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnDigitalConsignment$Response(params: OwnConsignmentGetOwnDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentGetOwnDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Digital Consignment Get.
   *
   * Gets own digital consignment by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetOwnDigitalConsignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnDigitalConsignment(params: OwnConsignmentGetOwnDigitalConsignment$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentGetOwnDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentUpdateOwnDigitalConsignment()` */
  static readonly OwnConsignmentUpdateOwnDigitalConsignmentPath = '/sad/own-consignment/digital/{id}';

  /**
   * Own Digital Consignment update.
   *
   * Updates Own Digital Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentUpdateOwnDigitalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnDigitalConsignment$Response(params: OwnConsignmentUpdateOwnDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentUpdateOwnDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Digital Consignment update.
   *
   * Updates Own Digital Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentUpdateOwnDigitalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnDigitalConsignment(params: OwnConsignmentUpdateOwnDigitalConsignment$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentUpdateOwnDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentDeleteDigital()` */
  static readonly OwnConsignmentDeleteDigitalPath = '/sad/own-consignment/digital/{id}';

  /**
   * Consignment Deletion.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentDeleteDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteDigital$Response(params: OwnConsignmentDeleteDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentDeleteDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Deletion.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentDeleteDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteDigital(params: OwnConsignmentDeleteDigital$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentDeleteDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetOwnInternalDigitalConsignment()` */
  static readonly OwnConsignmentGetOwnInternalDigitalConsignmentPath = '/sad/own-consignment/digital/internal/{id}';

  /**
   * Own Internal Digital Consignment Get.
   *
   * Gets own internal digital consignment by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetOwnInternalDigitalConsignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnInternalDigitalConsignment$Response(params: OwnConsignmentGetOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentGetOwnInternalDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Digital Consignment Get.
   *
   * Gets own internal digital consignment by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetOwnInternalDigitalConsignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOwnInternalDigitalConsignment(params: OwnConsignmentGetOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentGetOwnInternalDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentUpdateOwnInternalDigitalConsignment()` */
  static readonly OwnConsignmentUpdateOwnInternalDigitalConsignmentPath = '/sad/own-consignment/digital/internal/{id}';

  /**
   * Own Internal Digital Consignment update.
   *
   * Updates Own Internal Digital Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentUpdateOwnInternalDigitalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnInternalDigitalConsignment$Response(params: OwnConsignmentUpdateOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentUpdateOwnInternalDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Digital Consignment update.
   *
   * Updates Own Internal Digital Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentUpdateOwnInternalDigitalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentUpdateOwnInternalDigitalConsignment(params: OwnConsignmentUpdateOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentUpdateOwnInternalDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentDeleteInternalDigital()` */
  static readonly OwnConsignmentDeleteInternalDigitalPath = '/sad/own-consignment/digital/internal/{id}';

  /**
   * Consignment Deletion.
   *
   * Deletes internal digital consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentDeleteInternalDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteInternalDigital$Response(params: OwnConsignmentDeleteInternalDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentDeleteInternalDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Deletion.
   *
   * Deletes internal digital consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentDeleteInternalDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentDeleteInternalDigital(params: OwnConsignmentDeleteInternalDigital$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentDeleteInternalDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetProofOfDeliveryForConsignment()` */
  static readonly OwnConsignmentGetProofOfDeliveryForConsignmentPath = '/sad/own-consignment/{consignmentId}/proof-of-delivery';

  /**
   * Get Proof of Delivery for Consignment.
   *
   * Fetches Proof of Delivery for Consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetProofOfDeliveryForConsignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetProofOfDeliveryForConsignment$Response(params: OwnConsignmentGetProofOfDeliveryForConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ProofOfDeliveryDto>> {
    return ownConsignmentGetProofOfDeliveryForConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Get Proof of Delivery for Consignment.
   *
   * Fetches Proof of Delivery for Consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetProofOfDeliveryForConsignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetProofOfDeliveryForConsignment(params: OwnConsignmentGetProofOfDeliveryForConsignment$Params, context?: HttpContext): Observable<ProofOfDeliveryDto> {
    return this.ownConsignmentGetProofOfDeliveryForConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProofOfDeliveryDto>): ProofOfDeliveryDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateProofOfDelivery()` */
  static readonly OwnConsignmentCreateProofOfDeliveryPath = '/sad/own-consignment/{consignmentId}/proof-of-delivery';

  /**
   * Proof of Delivery create.
   *
   * Creates proof of Delivery.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateProofOfDelivery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateProofOfDelivery$Response(params: OwnConsignmentCreateProofOfDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<ProofOfDeliveryDto>> {
    return ownConsignmentCreateProofOfDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * Proof of Delivery create.
   *
   * Creates proof of Delivery.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateProofOfDelivery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateProofOfDelivery(params: OwnConsignmentCreateProofOfDelivery$Params, context?: HttpContext): Observable<ProofOfDeliveryDto> {
    return this.ownConsignmentCreateProofOfDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProofOfDeliveryDto>): ProofOfDeliveryDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnTelefaxConsignment()` */
  static readonly OwnConsignmentCreateOwnTelefaxConsignmentPath = '/sad/own-consignment/telefax';

  /**
   * Own Telefax Consignment Test Create.
   *
   * Creates a new own telefax consignment for test purposes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnTelefaxConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnTelefaxConsignment$Response(params: OwnConsignmentCreateOwnTelefaxConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnTelefaxConsignmentDto>> {
    return ownConsignmentCreateOwnTelefaxConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Telefax Consignment Test Create.
   *
   * Creates a new own telefax consignment for test purposes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnTelefaxConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnTelefaxConsignment(params: OwnConsignmentCreateOwnTelefaxConsignment$Params, context?: HttpContext): Observable<OwnTelefaxConsignmentDto> {
    return this.ownConsignmentCreateOwnTelefaxConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnTelefaxConsignmentDto>): OwnTelefaxConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetSheetLabelPrint()` */
  static readonly OwnConsignmentGetSheetLabelPrintPath = '/sad/own-consignment/sheet-label-print/{format}';

  /**
   * Paper or Internal Paper Consignment Sheet Label Print.
   *
   * Generates a print with the sheet label of the given paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetSheetLabelPrint()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetSheetLabelPrint$Response(params: OwnConsignmentGetSheetLabelPrint$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentGetSheetLabelPrint(this.http, this.rootUrl, params, context);
  }

  /**
   * Paper or Internal Paper Consignment Sheet Label Print.
   *
   * Generates a print with the sheet label of the given paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetSheetLabelPrint$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetSheetLabelPrint(params: OwnConsignmentGetSheetLabelPrint$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentGetSheetLabelPrint$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentPrintLabelByDocumentId()` */
  static readonly OwnConsignmentPrintLabelByDocumentIdPath = '/sad/own-consignment/print-label-by-document-id/{id}';

  /**
   * Prints label for consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentPrintLabelByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentPrintLabelByDocumentId$Response(params: OwnConsignmentPrintLabelByDocumentId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentPrintLabelByDocumentId(this.http, this.rootUrl, params, context);
  }

  /**
   * Prints label for consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentPrintLabelByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentPrintLabelByDocumentId(params: OwnConsignmentPrintLabelByDocumentId$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentPrintLabelByDocumentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnPortalConsignment()` */
  static readonly OwnConsignmentCreateOwnPortalConsignmentPath = '/sad/own-consignment/portal';

  /**
   * Own Portal Consignment Test Create.
   *
   * Creates a new own portal consignment for test purposes.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnPortalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnPortalConsignment$Response(params: OwnConsignmentCreateOwnPortalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPortalConsignmentDto>> {
    return ownConsignmentCreateOwnPortalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Portal Consignment Test Create.
   *
   * Creates a new own portal consignment for test purposes.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnPortalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnPortalConsignment(params: OwnConsignmentCreateOwnPortalConsignment$Params, context?: HttpContext): Observable<OwnPortalConsignmentDto> {
    return this.ownConsignmentCreateOwnPortalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPortalConsignmentDto>): OwnPortalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnPaperConsignment()` */
  static readonly OwnConsignmentCreateOwnPaperConsignmentPath = '/sad/own-consignment/paper';

  /**
   * Own Paper Consignment Create.
   *
   * Creates a new own paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnPaperConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnPaperConsignment$Response(params: OwnConsignmentCreateOwnPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentCreateOwnPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Paper Consignment Create.
   *
   * Creates a new own paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnPaperConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnPaperConsignment(params: OwnConsignmentCreateOwnPaperConsignment$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentCreateOwnPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawPaper()` */
  static readonly OwnConsignmentWithdrawPaperPath = '/sad/own-consignment/paper/{id}/withdraw';

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawPaper$Response(params: OwnConsignmentWithdrawPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentWithdrawPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawPaper(params: OwnConsignmentWithdrawPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentWithdrawPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawFromDistributionPaper()` */
  static readonly OwnConsignmentWithdrawFromDistributionPaperPath = '/sad/own-consignment/paper/{id}/withdraw-from-distribution';

  /**
   * Consignment From-Distribution-Withdrawal.
   *
   * Unmarks a consignment as ready to be dispatched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawFromDistributionPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawFromDistributionPaper$Response(params: OwnConsignmentWithdrawFromDistributionPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentWithdrawFromDistributionPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment From-Distribution-Withdrawal.
   *
   * Unmarks a consignment as ready to be dispatched.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawFromDistributionPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawFromDistributionPaper(params: OwnConsignmentWithdrawFromDistributionPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentWithdrawFromDistributionPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverPaper()` */
  static readonly OwnConsignmentTakeoverPaperPath = '/sad/own-consignment/paper/{id}/takeover';

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverPaper()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverPaper$Response(params: OwnConsignmentTakeoverPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentTakeoverPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverPaper$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverPaper(params: OwnConsignmentTakeoverPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentTakeoverPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverAndPrepareForDistributionPaper()` */
  static readonly OwnConsignmentTakeoverAndPrepareForDistributionPaperPath = '/sad/own-consignment/paper/{id}/takeover-and-prepare-for-distribution';

  /**
   * Consignment Takeover and Distribution.
   *
   * Two steps in one. See takeover and prepareForDistribution.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverAndPrepareForDistributionPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentTakeoverAndPrepareForDistributionPaper$Response(params: OwnConsignmentTakeoverAndPrepareForDistributionPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentTakeoverAndPrepareForDistributionPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takeover and Distribution.
   *
   * Two steps in one. See takeover and prepareForDistribution.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverAndPrepareForDistributionPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentTakeoverAndPrepareForDistributionPaper(params: OwnConsignmentTakeoverAndPrepareForDistributionPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentTakeoverAndPrepareForDistributionPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentRejectPaper()` */
  static readonly OwnConsignmentRejectPaperPath = '/sad/own-consignment/paper/{id}/reject';

  /**
   * Consignment Rejection.
   *
   * Rejects a consignment and effectively makes it as undistributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentRejectPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectPaper$Response(params: OwnConsignmentRejectPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentRejectPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Rejection.
   *
   * Rejects a consignment and effectively makes it as undistributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentRejectPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectPaper(params: OwnConsignmentRejectPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentRejectPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentPrepareForDistributionPaper()` */
  static readonly OwnConsignmentPrepareForDistributionPaperPath = '/sad/own-consignment/paper/{id}/prepare-for-distribution';

  /**
   * Consignment Distribution.
   *
   * Marks a consignment as ready to be dispatched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentPrepareForDistributionPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentPrepareForDistributionPaper$Response(params: OwnConsignmentPrepareForDistributionPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentPrepareForDistributionPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Distribution.
   *
   * Marks a consignment as ready to be dispatched.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentPrepareForDistributionPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentPrepareForDistributionPaper(params: OwnConsignmentPrepareForDistributionPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentPrepareForDistributionPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentMarkAsDispatchedPaper()` */
  static readonly OwnConsignmentMarkAsDispatchedPaperPath = '/sad/own-consignment/paper/{id}/mark-as-dispatched';

  /**
   * Consignment Dispatch.
   *
   * Confirms that a consignment has been accepted by a distributor and that it already is or soon will be on its way to the consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentMarkAsDispatchedPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentMarkAsDispatchedPaper$Response(params: OwnConsignmentMarkAsDispatchedPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentMarkAsDispatchedPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Dispatch.
   *
   * Confirms that a consignment has been accepted by a distributor and that it already is or soon will be on its way to the consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentMarkAsDispatchedPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentMarkAsDispatchedPaper(params: OwnConsignmentMarkAsDispatchedPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentMarkAsDispatchedPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentHandoverPaper()` */
  static readonly OwnConsignmentHandoverPaperPath = '/sad/own-consignment/paper/{id}/handover';

  /**
   * Consignment Handover.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentHandoverPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverPaper$Response(params: OwnConsignmentHandoverPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentHandoverPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Handover.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentHandoverPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverPaper(params: OwnConsignmentHandoverPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentHandoverPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCancelPaper()` */
  static readonly OwnConsignmentCancelPaperPath = '/sad/own-consignment/paper/{id}/cancel';

  /**
   * Consignment Cancellation.
   *
   * Cancels a consignment, which effectively means soft-deletion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCancelPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelPaper$Response(params: OwnConsignmentCancelPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentDto>> {
    return ownConsignmentCancelPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Cancellation.
   *
   * Cancels a consignment, which effectively means soft-deletion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCancelPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelPaper(params: OwnConsignmentCancelPaper$Params, context?: HttpContext): Observable<OwnPaperConsignmentDto> {
    return this.ownConsignmentCancelPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentDto>): OwnPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentValidateOwnPaperConsignment()` */
  static readonly OwnConsignmentValidateOwnPaperConsignmentPath = '/sad/own-consignment/paper/validate';

  /**
   * Own Paper Consignment Create Validation.
   *
   * Validate own paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentValidateOwnPaperConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnPaperConsignment$Response(params: OwnConsignmentValidateOwnPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsignmentValidationResult>> {
    return ownConsignmentValidateOwnPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Paper Consignment Create Validation.
   *
   * Validate own paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentValidateOwnPaperConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnPaperConsignment(params: OwnConsignmentValidateOwnPaperConsignment$Params, context?: HttpContext): Observable<ConsignmentValidationResult> {
    return this.ownConsignmentValidateOwnPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsignmentValidationResult>): ConsignmentValidationResult => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnInternalPaperConsignment()` */
  static readonly OwnConsignmentCreateOwnInternalPaperConsignmentPath = '/sad/own-consignment/paper/internal';

  /**
   * Own Internal Paper Consignment Create.
   *
   * Creates a new own internal paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnInternalPaperConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnInternalPaperConsignment$Response(params: OwnConsignmentCreateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentCreateOwnInternalPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Paper Consignment Create.
   *
   * Creates a new own internal paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnInternalPaperConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnInternalPaperConsignment(params: OwnConsignmentCreateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentCreateOwnInternalPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawInternalPaper()` */
  static readonly OwnConsignmentWithdrawInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/withdraw';

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a internal consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawInternalPaper$Response(params: OwnConsignmentWithdrawInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentWithdrawInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a internal consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawInternalPaper(params: OwnConsignmentWithdrawInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentWithdrawInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawFromDistributionInternalPaper()` */
  static readonly OwnConsignmentWithdrawFromDistributionInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/withdraw-from-distribution';

  /**
   * Consignment From-Distribution-Withdrawal.
   *
   * Unmarks a internal consignment as ready to be dispatched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawFromDistributionInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawFromDistributionInternalPaper$Response(params: OwnConsignmentWithdrawFromDistributionInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentWithdrawFromDistributionInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment From-Distribution-Withdrawal.
   *
   * Unmarks a internal consignment as ready to be dispatched.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawFromDistributionInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawFromDistributionInternalPaper(params: OwnConsignmentWithdrawFromDistributionInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentWithdrawFromDistributionInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverInternalPaper()` */
  static readonly OwnConsignmentTakeoverInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/takeover';

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a internal consignment as distributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverInternalPaper()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverInternalPaper$Response(params: OwnConsignmentTakeoverInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentTakeoverInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a internal consignment as distributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverInternalPaper$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverInternalPaper(params: OwnConsignmentTakeoverInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentTakeoverInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverAndPrepareForDistributionInternalPaper()` */
  static readonly OwnConsignmentTakeoverAndPrepareForDistributionInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/takeover-and-prepare-for-distribution';

  /**
   * Consignment Takeover and Distribution.
   *
   * Two steps in one. See takeover and prepareForDistribution.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverAndPrepareForDistributionInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentTakeoverAndPrepareForDistributionInternalPaper$Response(params: OwnConsignmentTakeoverAndPrepareForDistributionInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentTakeoverAndPrepareForDistributionInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takeover and Distribution.
   *
   * Two steps in one. See takeover and prepareForDistribution.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverAndPrepareForDistributionInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentTakeoverAndPrepareForDistributionInternalPaper(params: OwnConsignmentTakeoverAndPrepareForDistributionInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentTakeoverAndPrepareForDistributionInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentRejectInternalPaper()` */
  static readonly OwnConsignmentRejectInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/reject';

  /**
   * Consignment Rejection.
   *
   * Rejects a internal consignment and effectively makes it as undistributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentRejectInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectInternalPaper$Response(params: OwnConsignmentRejectInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentRejectInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Rejection.
   *
   * Rejects a internal consignment and effectively makes it as undistributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentRejectInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectInternalPaper(params: OwnConsignmentRejectInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentRejectInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentPrepareForDistributionInternalPaper()` */
  static readonly OwnConsignmentPrepareForDistributionInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/prepare-for-distribution';

  /**
   * Consignment Distribution.
   *
   * Marks a internal consignment as ready to be dispatched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentPrepareForDistributionInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentPrepareForDistributionInternalPaper$Response(params: OwnConsignmentPrepareForDistributionInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentPrepareForDistributionInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Distribution.
   *
   * Marks a internal consignment as ready to be dispatched.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentPrepareForDistributionInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentPrepareForDistributionInternalPaper(params: OwnConsignmentPrepareForDistributionInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentPrepareForDistributionInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentMarkAsDispatchedInternalPaper()` */
  static readonly OwnConsignmentMarkAsDispatchedInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/mark-as-dispatched';

  /**
   * Consignment Dispatch.
   *
   * Confirms that a internal consignment has been accepted by a distributor and that it already is or soon will be on its way to the consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentMarkAsDispatchedInternalPaper()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentMarkAsDispatchedInternalPaper$Response(params: OwnConsignmentMarkAsDispatchedInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentMarkAsDispatchedInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Dispatch.
   *
   * Confirms that a internal consignment has been accepted by a distributor and that it already is or soon will be on its way to the consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentMarkAsDispatchedInternalPaper$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentMarkAsDispatchedInternalPaper(params: OwnConsignmentMarkAsDispatchedInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentMarkAsDispatchedInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentHandoverInternalPaper()` */
  static readonly OwnConsignmentHandoverInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/handover';

  /**
   * Consignment Handover.
   *
   * Hands over a internal paper consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentHandoverInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverInternalPaper$Response(params: OwnConsignmentHandoverInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentHandoverInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Handover.
   *
   * Hands over a internal paper consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentHandoverInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverInternalPaper(params: OwnConsignmentHandoverInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentHandoverInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCancelInternalPaper()` */
  static readonly OwnConsignmentCancelInternalPaperPath = '/sad/own-consignment/paper/internal/{id}/cancel';

  /**
   * Consignment Cancellation.
   *
   * Cancels a internal consignment, which effectively means soft-deletion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCancelInternalPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelInternalPaper$Response(params: OwnConsignmentCancelInternalPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalPaperConsignmentDto>> {
    return ownConsignmentCancelInternalPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Cancellation.
   *
   * Cancels a internal consignment, which effectively means soft-deletion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCancelInternalPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelInternalPaper(params: OwnConsignmentCancelInternalPaper$Params, context?: HttpContext): Observable<OwnInternalPaperConsignmentDto> {
    return this.ownConsignmentCancelInternalPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalPaperConsignmentDto>): OwnInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentValidateOwnInternalPaperConsignment()` */
  static readonly OwnConsignmentValidateOwnInternalPaperConsignmentPath = '/sad/own-consignment/paper/internal/validate';

  /**
   * Own Internal Paper Consignment Create Validation.
   *
   * Validate own internal paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentValidateOwnInternalPaperConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnInternalPaperConsignment$Response(params: OwnConsignmentValidateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsignmentValidationResult>> {
    return ownConsignmentValidateOwnInternalPaperConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Paper Consignment Create Validation.
   *
   * Validate own internal paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentValidateOwnInternalPaperConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnInternalPaperConsignment(params: OwnConsignmentValidateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<ConsignmentValidationResult> {
    return this.ownConsignmentValidateOwnInternalPaperConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsignmentValidationResult>): ConsignmentValidationResult => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetInternalPaperEnvelopePreview()` */
  static readonly OwnConsignmentGetInternalPaperEnvelopePreviewPath = '/sad/own-consignment/paper/internal/envelope-preview/{format}';

  /**
   * Internal Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for an internal paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetInternalPaperEnvelopePreview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetInternalPaperEnvelopePreview$Response(params: OwnConsignmentGetInternalPaperEnvelopePreview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentGetInternalPaperEnvelopePreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Internal Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for an internal paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetInternalPaperEnvelopePreview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetInternalPaperEnvelopePreview(params: OwnConsignmentGetInternalPaperEnvelopePreview$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentGetInternalPaperEnvelopePreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetInternalPaperEnvelopePreviewWithUid()` */
  static readonly OwnConsignmentGetInternalPaperEnvelopePreviewWithUidPath = '/sad/own-consignment/paper/internal/envelope-preview-uid/{format}/{uid}';

  /**
   * Internal Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for an internal paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetInternalPaperEnvelopePreviewWithUid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetInternalPaperEnvelopePreviewWithUid$Response(params: OwnConsignmentGetInternalPaperEnvelopePreviewWithUid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentGetInternalPaperEnvelopePreviewWithUid(this.http, this.rootUrl, params, context);
  }

  /**
   * Internal Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for an internal paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetInternalPaperEnvelopePreviewWithUid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetInternalPaperEnvelopePreviewWithUid(params: OwnConsignmentGetInternalPaperEnvelopePreviewWithUid$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentGetInternalPaperEnvelopePreviewWithUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetPaperEnvelopePreview()` */
  static readonly OwnConsignmentGetPaperEnvelopePreviewPath = '/sad/own-consignment/paper/envelope-preview/{format}';

  /**
   * Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for a paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetPaperEnvelopePreview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetPaperEnvelopePreview$Response(params: OwnConsignmentGetPaperEnvelopePreview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentGetPaperEnvelopePreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for a paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetPaperEnvelopePreview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetPaperEnvelopePreview(params: OwnConsignmentGetPaperEnvelopePreview$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentGetPaperEnvelopePreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetPaperEnvelopePreviewWithUid()` */
  static readonly OwnConsignmentGetPaperEnvelopePreviewWithUidPath = '/sad/own-consignment/paper/envelope-preview-uid/{format}/{uid}';

  /**
   * Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for a paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetPaperEnvelopePreviewWithUid()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetPaperEnvelopePreviewWithUid$Response(params: OwnConsignmentGetPaperEnvelopePreviewWithUid$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentGetPaperEnvelopePreviewWithUid(this.http, this.rootUrl, params, context);
  }

  /**
   * Paper Consignment Envelope Preview.
   *
   * Generates a preview of the envelope for a paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetPaperEnvelopePreviewWithUid$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetPaperEnvelopePreviewWithUid(params: OwnConsignmentGetPaperEnvelopePreviewWithUid$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentGetPaperEnvelopePreviewWithUid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkTakeoverAndPrepareForDistributionPaper()` */
  static readonly OwnConsignmentBulkTakeoverAndPrepareForDistributionPaperPath = '/sad/own-consignment/paper/bulk-takeover-and-prepare-for-distribution';

  /**
   * Consignment Takover and Distribution.
   *
   * Two steps in one. See takeover and prepareForDistribution.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkTakeoverAndPrepareForDistributionPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkTakeoverAndPrepareForDistributionPaper$Response(params: OwnConsignmentBulkTakeoverAndPrepareForDistributionPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkTakeoverAndPrepareForDistributionPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takover and Distribution.
   *
   * Two steps in one. See takeover and prepareForDistribution.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkTakeoverAndPrepareForDistributionPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkTakeoverAndPrepareForDistributionPaper(params: OwnConsignmentBulkTakeoverAndPrepareForDistributionPaper$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkTakeoverAndPrepareForDistributionPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkPrepareForDistributionPaper()` */
  static readonly OwnConsignmentBulkPrepareForDistributionPaperPath = '/sad/own-consignment/paper/bulk-prepare-for-distribution';

  /**
   * Consignment Distribution.
   *
   * Marks a consignments as ready to be dispatched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkPrepareForDistributionPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkPrepareForDistributionPaper$Response(params: OwnConsignmentBulkPrepareForDistributionPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkPrepareForDistributionPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Distribution.
   *
   * Marks a consignments as ready to be dispatched.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkPrepareForDistributionPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkPrepareForDistributionPaper(params: OwnConsignmentBulkPrepareForDistributionPaper$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkPrepareForDistributionPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkMarkAsDispatchedPaper()` */
  static readonly OwnConsignmentBulkMarkAsDispatchedPaperPath = '/sad/own-consignment/paper/bulk-mark-as-dispatched';

  /**
   * Consignments Dispatch.
   *
   * Confirms that a consignment has been accepted by a distributor and that it already is or soon will be on its way to the consignee.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkMarkAsDispatchedPaper()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkMarkAsDispatchedPaper$Response(params: OwnConsignmentBulkMarkAsDispatchedPaper$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkMarkAsDispatchedPaper(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignments Dispatch.
   *
   * Confirms that a consignment has been accepted by a distributor and that it already is or soon will be on its way to the consignee.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkMarkAsDispatchedPaper$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkMarkAsDispatchedPaper(params: OwnConsignmentBulkMarkAsDispatchedPaper$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkMarkAsDispatchedPaper$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnOfficeDesk()` */
  static readonly OwnConsignmentCreateOwnOfficeDeskPath = '/sad/own-consignment/office-desk';

  /**
   * Office Desk Consignment Create.
   *
   * Creates a new office desk consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnOfficeDesk$Response(params: OwnConsignmentCreateOwnOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentCreateOwnOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office Desk Consignment Create.
   *
   * Creates a new office desk consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnOfficeDesk(params: OwnConsignmentCreateOwnOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentCreateOwnOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawOfficeDesk()` */
  static readonly OwnConsignmentWithdrawOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/withdraw';

  /**
   * Office desk Withdrawal.
   *
   * Stops the dispatch process of a consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawOfficeDesk$Response(params: OwnConsignmentWithdrawOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentWithdrawOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office desk Withdrawal.
   *
   * Stops the dispatch process of a consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawOfficeDesk(params: OwnConsignmentWithdrawOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentWithdrawOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverOfficeDesk()` */
  static readonly OwnConsignmentTakeoverOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/takeover';

  /**
   * Office Desk Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverOfficeDesk()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverOfficeDesk$Response(params: OwnConsignmentTakeoverOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentTakeoverOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office Desk Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverOfficeDesk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverOfficeDesk(params: OwnConsignmentTakeoverOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentTakeoverOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentRejectOfficeDesk()` */
  static readonly OwnConsignmentRejectOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/reject';

  /**
   * Office desk Rejection.
   *
   * Rejects a office desk and effectively makes it as undistributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentRejectOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectOfficeDesk$Response(params: OwnConsignmentRejectOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentRejectOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office desk Rejection.
   *
   * Rejects a office desk and effectively makes it as undistributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentRejectOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectOfficeDesk(params: OwnConsignmentRejectOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentRejectOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentManualUnpostOfficeDesk()` */
  static readonly OwnConsignmentManualUnpostOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/manual-unpost';

  /**
   * Office desk manual unpost.
   *
   * Unposts manualy a office desk.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentManualUnpostOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentManualUnpostOfficeDesk$Response(params: OwnConsignmentManualUnpostOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentManualUnpostOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office desk manual unpost.
   *
   * Unposts manualy a office desk.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentManualUnpostOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentManualUnpostOfficeDesk(params: OwnConsignmentManualUnpostOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentManualUnpostOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentManualPostOfficeDesk()` */
  static readonly OwnConsignmentManualPostOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/manual-post';

  /**
   * Office desk manual post.
   *
   * Posts manualy a office desk.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentManualPostOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentManualPostOfficeDesk$Response(params: OwnConsignmentManualPostOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentManualPostOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office desk manual post.
   *
   * Posts manualy a office desk.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentManualPostOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentManualPostOfficeDesk(params: OwnConsignmentManualPostOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentManualPostOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentHandoverOfficeDesk()` */
  static readonly OwnConsignmentHandoverOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/handover';

  /**
   * Office Desk Handover.
   *
   * Hands over a office desk to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentHandoverOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverOfficeDesk$Response(params: OwnConsignmentHandoverOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentHandoverOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office Desk Handover.
   *
   * Hands over a office desk to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentHandoverOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverOfficeDesk(params: OwnConsignmentHandoverOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentHandoverOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCancelOfficeDesk()` */
  static readonly OwnConsignmentCancelOfficeDeskPath = '/sad/own-consignment/office-desk/{id}/cancel';

  /**
   * Office desk Cancellation.
   *
   * Cancels a office desk, which effectively means soft-deletion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCancelOfficeDesk()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelOfficeDesk$Response(params: OwnConsignmentCancelOfficeDesk$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentDto>> {
    return ownConsignmentCancelOfficeDesk(this.http, this.rootUrl, params, context);
  }

  /**
   * Office desk Cancellation.
   *
   * Cancels a office desk, which effectively means soft-deletion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCancelOfficeDesk$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelOfficeDesk(params: OwnConsignmentCancelOfficeDesk$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentCancelOfficeDesk$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentDto>): OwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentValidateOwnOfficeDeskConsignment()` */
  static readonly OwnConsignmentValidateOwnOfficeDeskConsignmentPath = '/sad/own-consignment/office-desk/validate';

  /**
   * Office Desk Consignment Create Validation.
   *
   * Validate own office desk consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentValidateOwnOfficeDeskConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnOfficeDeskConsignment$Response(params: OwnConsignmentValidateOwnOfficeDeskConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsignmentValidationResult>> {
    return ownConsignmentValidateOwnOfficeDeskConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Office Desk Consignment Create Validation.
   *
   * Validate own office desk consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentValidateOwnOfficeDeskConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnOfficeDeskConsignment(params: OwnConsignmentValidateOwnOfficeDeskConsignment$Params, context?: HttpContext): Observable<ConsignmentValidationResult> {
    return this.ownConsignmentValidateOwnOfficeDeskConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsignmentValidationResult>): ConsignmentValidationResult => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetEnvelopePrint()` */
  static readonly OwnConsignmentGetEnvelopePrintPath = '/sad/own-consignment/envelope-print/{format}';

  /**
   * Paper or Internal Paper Consignment Envelope Print.
   *
   * Generates a print with the envelope of the given paper or internal paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetEnvelopePrint()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetEnvelopePrint$Response(params: OwnConsignmentGetEnvelopePrint$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return ownConsignmentGetEnvelopePrint(this.http, this.rootUrl, params, context);
  }

  /**
   * Paper or Internal Paper Consignment Envelope Print.
   *
   * Generates a print with the envelope of the given paper or internal paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetEnvelopePrint$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentGetEnvelopePrint(params: OwnConsignmentGetEnvelopePrint$Params, context?: HttpContext): Observable<Array<string>> {
    return this.ownConsignmentGetEnvelopePrint$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnDigitalConsignment()` */
  static readonly OwnConsignmentCreateOwnDigitalConsignmentPath = '/sad/own-consignment/digital';

  /**
   * Own Digital Consignment Create.
   *
   * Creates a new own digital consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnDigitalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnDigitalConsignment$Response(params: OwnConsignmentCreateOwnDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentCreateOwnDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Digital Consignment Create.
   *
   * Creates a new own digital consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnDigitalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnDigitalConsignment(params: OwnConsignmentCreateOwnDigitalConsignment$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentCreateOwnDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawDigital()` */
  static readonly OwnConsignmentWithdrawDigitalPath = '/sad/own-consignment/digital/{id}/withdraw';

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawDigital$Response(params: OwnConsignmentWithdrawDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentWithdrawDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawDigital(params: OwnConsignmentWithdrawDigital$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentWithdrawDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverAndDispatchDigital()` */
  static readonly OwnConsignmentTakeoverAndDispatchDigitalPath = '/sad/own-consignment/digital/{id}/takeover';

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverAndDispatchDigital()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverAndDispatchDigital$Response(params: OwnConsignmentTakeoverAndDispatchDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentTakeoverAndDispatchDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverAndDispatchDigital$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverAndDispatchDigital(params: OwnConsignmentTakeoverAndDispatchDigital$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentTakeoverAndDispatchDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentRejectDigital()` */
  static readonly OwnConsignmentRejectDigitalPath = '/sad/own-consignment/digital/{id}/reject';

  /**
   * Consignment Rejection.
   *
   * Rejects a digital consignment and effectively makes it as undistributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentRejectDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectDigital$Response(params: OwnConsignmentRejectDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentRejectDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Rejection.
   *
   * Rejects a digital consignment and effectively makes it as undistributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentRejectDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectDigital(params: OwnConsignmentRejectDigital$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentRejectDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentHandoverDigital()` */
  static readonly OwnConsignmentHandoverDigitalPath = '/sad/own-consignment/digital/{id}/handover';

  /**
   * Consignment Handover.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentHandoverDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverDigital$Response(params: OwnConsignmentHandoverDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentHandoverDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Handover.
   *
   * Hands over a consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentHandoverDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverDigital(params: OwnConsignmentHandoverDigital$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentHandoverDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCancelDigital()` */
  static readonly OwnConsignmentCancelDigitalPath = '/sad/own-consignment/digital/{id}/cancel';

  /**
   * Consignment Cancellation.
   *
   * Cancels a consignment, which effectively means soft-deletion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCancelDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelDigital$Response(params: OwnConsignmentCancelDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentCancelDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Cancellation.
   *
   * Cancels a consignment, which effectively means soft-deletion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCancelDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelDigital(params: OwnConsignmentCancelDigital$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentCancelDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentMarkNonEpoAsDispatched()` */
  static readonly OwnConsignmentMarkNonEpoAsDispatchedPath = '/sad/own-consignment/digital/{consignmentId}/mark-non-epo-as-dispatched';

  /**
   * Marks non-epo own email digital consignment as dispatched.
   *
   * Marks non-epo own email digital consignment as dispatched.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentMarkNonEpoAsDispatched()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentMarkNonEpoAsDispatched$Response(params: OwnConsignmentMarkNonEpoAsDispatched$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentDto>> {
    return ownConsignmentMarkNonEpoAsDispatched(this.http, this.rootUrl, params, context);
  }

  /**
   * Marks non-epo own email digital consignment as dispatched.
   *
   * Marks non-epo own email digital consignment as dispatched.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentMarkNonEpoAsDispatched$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentMarkNonEpoAsDispatched(params: OwnConsignmentMarkNonEpoAsDispatched$Params, context?: HttpContext): Observable<OwnDigitalConsignmentDto> {
    return this.ownConsignmentMarkNonEpoAsDispatched$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentDto>): OwnDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentValidateOwnDigitalConsignment()` */
  static readonly OwnConsignmentValidateOwnDigitalConsignmentPath = '/sad/own-consignment/digital/validate';

  /**
   * Own Digital Consignment Create Validation.
   *
   * Validate own digital consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentValidateOwnDigitalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnDigitalConsignment$Response(params: OwnConsignmentValidateOwnDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsignmentValidationResult>> {
    return ownConsignmentValidateOwnDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Digital Consignment Create Validation.
   *
   * Validate own digital consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentValidateOwnDigitalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnDigitalConsignment(params: OwnConsignmentValidateOwnDigitalConsignment$Params, context?: HttpContext): Observable<ConsignmentValidationResult> {
    return this.ownConsignmentValidateOwnDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsignmentValidationResult>): ConsignmentValidationResult => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCreateOwnInternalDigitalConsignment()` */
  static readonly OwnConsignmentCreateOwnInternalDigitalConsignmentPath = '/sad/own-consignment/digital/internal';

  /**
   * Own Internal Digital Consignment Create.
   *
   * Creates a new own internal digital consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCreateOwnInternalDigitalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnInternalDigitalConsignment$Response(params: OwnConsignmentCreateOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentCreateOwnInternalDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Digital Consignment Create.
   *
   * Creates a new own internal digital consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCreateOwnInternalDigitalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCreateOwnInternalDigitalConsignment(params: OwnConsignmentCreateOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentCreateOwnInternalDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentWithdrawInternalDigital()` */
  static readonly OwnConsignmentWithdrawInternalDigitalPath = '/sad/own-consignment/digital/internal/{id}/withdraw';

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a internal consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentWithdrawInternalDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawInternalDigital$Response(params: OwnConsignmentWithdrawInternalDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentWithdrawInternalDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Withdrawal.
   *
   * Stops the dispatch process of a internal consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentWithdrawInternalDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentWithdrawInternalDigital(params: OwnConsignmentWithdrawInternalDigital$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentWithdrawInternalDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentTakeoverAndDispatchInternalDigital()` */
  static readonly OwnConsignmentTakeoverAndDispatchInternalDigitalPath = '/sad/own-consignment/digital/internal/{id}/takeover';

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentTakeoverAndDispatchInternalDigital()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverAndDispatchInternalDigital$Response(params: OwnConsignmentTakeoverAndDispatchInternalDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentTakeoverAndDispatchInternalDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Takeover.
   *
   * Acknowledges and marks a consignment as distributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentTakeoverAndDispatchInternalDigital$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentTakeoverAndDispatchInternalDigital(params: OwnConsignmentTakeoverAndDispatchInternalDigital$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentTakeoverAndDispatchInternalDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentRejectInternalDigital()` */
  static readonly OwnConsignmentRejectInternalDigitalPath = '/sad/own-consignment/digital/internal/{id}/reject';

  /**
   * Consignment Rejection.
   *
   * Rejects a internal consignment and effectively makes it as undistributable.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentRejectInternalDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectInternalDigital$Response(params: OwnConsignmentRejectInternalDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentRejectInternalDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Rejection.
   *
   * Rejects a internal consignment and effectively makes it as undistributable.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentRejectInternalDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentRejectInternalDigital(params: OwnConsignmentRejectInternalDigital$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentRejectInternalDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentHandoverInternalDigital()` */
  static readonly OwnConsignmentHandoverInternalDigitalPath = '/sad/own-consignment/digital/internal/{id}/handover';

  /**
   * Consignment Handover.
   *
   * Hands over a internal digital consignment to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentHandoverInternalDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverInternalDigital$Response(params: OwnConsignmentHandoverInternalDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentHandoverInternalDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Handover.
   *
   * Hands over a internal digital consignment to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentHandoverInternalDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentHandoverInternalDigital(params: OwnConsignmentHandoverInternalDigital$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentHandoverInternalDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCancelInternalDigital()` */
  static readonly OwnConsignmentCancelInternalDigitalPath = '/sad/own-consignment/digital/internal/{id}/cancel';

  /**
   * Consignment Cancellation.
   *
   * Cancels a internal consignment, which effectively means soft-deletion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCancelInternalDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelInternalDigital$Response(params: OwnConsignmentCancelInternalDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnInternalDigitalConsignmentDto>> {
    return ownConsignmentCancelInternalDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Cancellation.
   *
   * Cancels a internal consignment, which effectively means soft-deletion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCancelInternalDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCancelInternalDigital(params: OwnConsignmentCancelInternalDigital$Params, context?: HttpContext): Observable<OwnInternalDigitalConsignmentDto> {
    return this.ownConsignmentCancelInternalDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnInternalDigitalConsignmentDto>): OwnInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentValidateOwnInternalDigitalConsignment()` */
  static readonly OwnConsignmentValidateOwnInternalDigitalConsignmentPath = '/sad/own-consignment/digital/internal/validate';

  /**
   * Own Internal Digital Consignment Create Validation.
   *
   * Validate own internal digital consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentValidateOwnInternalDigitalConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnInternalDigitalConsignment$Response(params: OwnConsignmentValidateOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsignmentValidationResult>> {
    return ownConsignmentValidateOwnInternalDigitalConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Own Internal Digital Consignment Create Validation.
   *
   * Validate own internal digital consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentValidateOwnInternalDigitalConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentValidateOwnInternalDigitalConsignment(params: OwnConsignmentValidateOwnInternalDigitalConsignment$Params, context?: HttpContext): Observable<ConsignmentValidationResult> {
    return this.ownConsignmentValidateOwnInternalDigitalConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConsignmentValidationResult>): ConsignmentValidationResult => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCheckLinkedConsignmentsByDistributionNodeId()` */
  static readonly OwnConsignmentCheckLinkedConsignmentsByDistributionNodeIdPath = '/sad/own-consignment/check-linked-consignments';

  /**
   * Checks linked consignments to distribution node.
   *
   * Checks linked consignments to distribution node by distributionNodeId
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCheckLinkedConsignmentsByDistributionNodeId()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCheckLinkedConsignmentsByDistributionNodeId$Response(params: OwnConsignmentCheckLinkedConsignmentsByDistributionNodeId$Params, context?: HttpContext): Observable<StrictHttpResponse<DistributionNodeValidationResult>> {
    return ownConsignmentCheckLinkedConsignmentsByDistributionNodeId(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks linked consignments to distribution node.
   *
   * Checks linked consignments to distribution node by distributionNodeId
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCheckLinkedConsignmentsByDistributionNodeId$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentCheckLinkedConsignmentsByDistributionNodeId(params: OwnConsignmentCheckLinkedConsignmentsByDistributionNodeId$Params, context?: HttpContext): Observable<DistributionNodeValidationResult> {
    return this.ownConsignmentCheckLinkedConsignmentsByDistributionNodeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<DistributionNodeValidationResult>): DistributionNodeValidationResult => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkCreateProofOfDelivery()` */
  static readonly OwnConsignmentBulkCreateProofOfDeliveryPath = '/sad/own-consignment/bulk/proof-of-delivery';

  /**
   * Bulk Proof of Delivery initialization.
   *
   * Initiates bulk proof of Delivery creation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkCreateProofOfDelivery()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkCreateProofOfDelivery$Response(params: OwnConsignmentBulkCreateProofOfDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkCreateProofOfDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk Proof of Delivery initialization.
   *
   * Initiates bulk proof of Delivery creation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkCreateProofOfDelivery$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkCreateProofOfDelivery(params: OwnConsignmentBulkCreateProofOfDelivery$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkCreateProofOfDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkWithdraw()` */
  static readonly OwnConsignmentBulkWithdrawPath = '/sad/own-consignment/bulk-withdraw';

  /**
   * Consignment Withdraw Reject.
   *
   * Withdraw a consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkWithdraw()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkWithdraw$Response(params: OwnConsignmentBulkWithdraw$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkWithdraw(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Withdraw Reject.
   *
   * Withdraw a consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkWithdraw$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkWithdraw(params: OwnConsignmentBulkWithdraw$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkWithdraw$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkWithdrawFromDistribution()` */
  static readonly OwnConsignmentBulkWithdrawFromDistributionPath = '/sad/own-consignment/bulk-withdraw-from-distribution';

  /**
   * Consignment Bulk Withdraw From Distribution.
   *
   * Withdraw from distribution a consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkWithdrawFromDistribution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkWithdrawFromDistribution$Response(params: OwnConsignmentBulkWithdrawFromDistribution$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkWithdrawFromDistribution(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Bulk Withdraw From Distribution.
   *
   * Withdraw from distribution a consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkWithdrawFromDistribution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkWithdrawFromDistribution(params: OwnConsignmentBulkWithdrawFromDistribution$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkWithdrawFromDistribution$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkTakeover()` */
  static readonly OwnConsignmentBulkTakeoverPath = '/sad/own-consignment/bulk-takeover';

  /**
   * Consignment Bulk Takeover.
   *
   * Takes over a consignments to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkTakeover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkTakeover$Response(params: OwnConsignmentBulkTakeover$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkTakeover(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Bulk Takeover.
   *
   * Takes over a consignments to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkTakeover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkTakeover(params: OwnConsignmentBulkTakeover$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkTakeover$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkReject()` */
  static readonly OwnConsignmentBulkRejectPath = '/sad/own-consignment/bulk-reject';

  /**
   * Consignment Bulk Reject.
   *
   * Reject a consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkReject$Response(params: OwnConsignmentBulkReject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Bulk Reject.
   *
   * Reject a consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkReject(params: OwnConsignmentBulkReject$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkHandover()` */
  static readonly OwnConsignmentBulkHandoverPath = '/sad/own-consignment/bulk-handover';

  /**
   * Consignment Bulk Handover.
   *
   * Hands over a consignments to a dispatch office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkHandover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkHandover$Response(params: OwnConsignmentBulkHandover$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkHandover(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Bulk Handover.
   *
   * Hands over a consignments to a dispatch office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkHandover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkHandover(params: OwnConsignmentBulkHandover$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkHandover$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkCancel()` */
  static readonly OwnConsignmentBulkCancelPath = '/sad/own-consignment/bulk-cancel';

  /**
   * Consignment Bulk Cancel.
   *
   * Cancel a consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkCancel()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkCancel$Response(params: OwnConsignmentBulkCancel$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkCancel(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignment Bulk Cancel.
   *
   * Cancel a consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkCancel$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkCancel(params: OwnConsignmentBulkCancel$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkCancel$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetDeliveryEvents()` */
  static readonly OwnConsignmentGetDeliveryEventsPath = '/sad/own-consignment/{consignmentId}/delivery-events';

  /**
   * Proof of Delivery get.
   *
   * Returns Proof of Delivery.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetDeliveryEvents()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetDeliveryEvents$Response(params: OwnConsignmentGetDeliveryEvents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryEventDto>>> {
    return ownConsignmentGetDeliveryEvents(this.http, this.rootUrl, params, context);
  }

  /**
   * Proof of Delivery get.
   *
   * Returns Proof of Delivery.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetDeliveryEvents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetDeliveryEvents(params: OwnConsignmentGetDeliveryEvents$Params, context?: HttpContext): Observable<Array<DeliveryEventDto>> {
    return this.ownConsignmentGetDeliveryEvents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryEventDto>>): Array<DeliveryEventDto> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsForSecretariat()` */
  static readonly OwnConsignmentFindConsignmentsForSecretariatPath = '/sad/own-consignment/search-api/secretariat';

  /**
   * Finds consignments.
   *
   * Returns consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsForSecretariat()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForSecretariat$Response(params?: OwnConsignmentFindConsignmentsForSecretariat$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOwnConsignmentDto>> {
    return ownConsignmentFindConsignmentsForSecretariat(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds consignments.
   *
   * Returns consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsForSecretariat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForSecretariat(params?: OwnConsignmentFindConsignmentsForSecretariat$Params|null|undefined, context?: HttpContext): Observable<PageOwnConsignmentDto> {
    return this.ownConsignmentFindConsignmentsForSecretariat$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnConsignmentDto>): PageOwnConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsForSecretariat_1()` */
  static readonly OwnConsignmentFindConsignmentsForSecretariat_1Path = '/sad/own-consignment/search-api/secretariat/org-unit/{orgUnitId}';

  /**
   * Finds consignments.
   *
   * Returns consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsForSecretariat_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForSecretariat_1$Response(params: OwnConsignmentFindConsignmentsForSecretariat_1$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOwnConsignmentDto>> {
    return ownConsignmentFindConsignmentsForSecretariat_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds consignments.
   *
   * Returns consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsForSecretariat_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForSecretariat_1(params: OwnConsignmentFindConsignmentsForSecretariat_1$Params, context?: HttpContext): Observable<PageOwnConsignmentDto> {
    return this.ownConsignmentFindConsignmentsForSecretariat_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnConsignmentDto>): PageOwnConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsForOwner()` */
  static readonly OwnConsignmentFindConsignmentsForOwnerPath = '/sad/own-consignment/search-api/owner';

  /**
   * Finds consignments.
   *
   * Returns consignments of all types for the passed search predicate. Only searches among consignments the current user owns.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsForOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForOwner$Response(params?: OwnConsignmentFindConsignmentsForOwner$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOwnConsignmentDto>> {
    return ownConsignmentFindConsignmentsForOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds consignments.
   *
   * Returns consignments of all types for the passed search predicate. Only searches among consignments the current user owns.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsForOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForOwner(params?: OwnConsignmentFindConsignmentsForOwner$Params|null|undefined, context?: HttpContext): Observable<PageOwnConsignmentDto> {
    return this.ownConsignmentFindConsignmentsForOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnConsignmentDto>): PageOwnConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindOfficeDeskConsignmentsForOwner()` */
  static readonly OwnConsignmentFindOfficeDeskConsignmentsForOwnerPath = '/sad/own-consignment/search-api/office-desk/owner';

  /**
   * Finds office desk consignments.
   *
   * Returns office desk consignments of all types for the passed search predicate. Only searches among consignments the current user owns.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindOfficeDeskConsignmentsForOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOfficeDeskConsignmentsForOwner$Response(params?: OwnConsignmentFindOfficeDeskConsignmentsForOwner$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOwnOfficeDeskConsignmentDto>> {
    return ownConsignmentFindOfficeDeskConsignmentsForOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds office desk consignments.
   *
   * Returns office desk consignments of all types for the passed search predicate. Only searches among consignments the current user owns.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindOfficeDeskConsignmentsForOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOfficeDeskConsignmentsForOwner(params?: OwnConsignmentFindOfficeDeskConsignmentsForOwner$Params|null|undefined, context?: HttpContext): Observable<PageOwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentFindOfficeDeskConsignmentsForOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnOfficeDeskConsignmentDto>): PageOwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer()` */
  static readonly OwnConsignmentFindOfficeDeskConsignmentsForDispatchOfficerPath = '/sad/own-consignment/search-api/office-desk/dispatch-officer';

  /**
   * Finds office desk consignments.
   *
   * Returns office desk own consignments for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer$Response(params?: OwnConsignmentFindOfficeDeskConsignmentsForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOwnOfficeDeskConsignmentDto>> {
    return ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds office desk consignments.
   *
   * Returns office desk own consignments for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer(params?: OwnConsignmentFindOfficeDeskConsignmentsForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<PageOwnOfficeDeskConsignmentDto> {
    return this.ownConsignmentFindOfficeDeskConsignmentsForDispatchOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnOfficeDeskConsignmentDto>): PageOwnOfficeDeskConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsForDispatchOfficer()` */
  static readonly OwnConsignmentFindConsignmentsForDispatchOfficerPath = '/sad/own-consignment/search-api/dispatch-officer';

  /**
   * Finds consignments.
   *
   * Returns own consignments of all types for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsForDispatchOfficer()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForDispatchOfficer$Response(params?: OwnConsignmentFindConsignmentsForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOwnConsignmentDto>> {
    return ownConsignmentFindConsignmentsForDispatchOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds consignments.
   *
   * Returns own consignments of all types for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsForDispatchOfficer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsForDispatchOfficer(params?: OwnConsignmentFindConsignmentsForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<PageOwnConsignmentDto> {
    return this.ownConsignmentFindConsignmentsForDispatchOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnConsignmentDto>): PageOwnConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsCountForSecretariat()` */
  static readonly OwnConsignmentFindConsignmentsCountForSecretariatPath = '/sad/own-consignment/search-api/count/secretariat';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsCountForSecretariat()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForSecretariat$Response(params?: OwnConsignmentFindConsignmentsCountForSecretariat$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
    return ownConsignmentFindConsignmentsCountForSecretariat(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsCountForSecretariat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForSecretariat(params?: OwnConsignmentFindConsignmentsCountForSecretariat$Params|null|undefined, context?: HttpContext): Observable<CountDto> {
    return this.ownConsignmentFindConsignmentsCountForSecretariat$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountDto>): CountDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsCountForSecretariat_1()` */
  static readonly OwnConsignmentFindConsignmentsCountForSecretariat_1Path = '/sad/own-consignment/search-api/count/secretariat/org-unit/{orgUnitId}';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsCountForSecretariat_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForSecretariat_1$Response(params: OwnConsignmentFindConsignmentsCountForSecretariat_1$Params, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
    return ownConsignmentFindConsignmentsCountForSecretariat_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsCountForSecretariat_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForSecretariat_1(params: OwnConsignmentFindConsignmentsCountForSecretariat_1$Params, context?: HttpContext): Observable<CountDto> {
    return this.ownConsignmentFindConsignmentsCountForSecretariat_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountDto>): CountDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsCountForOwner()` */
  static readonly OwnConsignmentFindConsignmentsCountForOwnerPath = '/sad/own-consignment/search-api/count/owner';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user owns.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsCountForOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForOwner$Response(params?: OwnConsignmentFindConsignmentsCountForOwner$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
    return ownConsignmentFindConsignmentsCountForOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user owns.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsCountForOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForOwner(params?: OwnConsignmentFindConsignmentsCountForOwner$Params|null|undefined, context?: HttpContext): Observable<CountDto> {
    return this.ownConsignmentFindConsignmentsCountForOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountDto>): CountDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer()` */
  static readonly OwnConsignmentCountOfficeDeskConsignmentsForDispatchOfficerPath = '/sad/own-consignment/search-api/count/office-desk/dispatch-officer';

  /**
   * Counts office desk consignments.
   *
   * Returns office desk own consignment count for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer$Response(params?: OwnConsignmentCountOfficeDeskConsignmentsForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Counts office desk consignments.
   *
   * Returns office desk own consignment count for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer(params?: OwnConsignmentCountOfficeDeskConsignmentsForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<number> {
    return this.ownConsignmentCountOfficeDeskConsignmentsForDispatchOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindConsignmentsCountForDispatchOfficer()` */
  static readonly OwnConsignmentFindConsignmentsCountForDispatchOfficerPath = '/sad/own-consignment/search-api/count/dispatch-officer';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindConsignmentsCountForDispatchOfficer()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForDispatchOfficer$Response(params?: OwnConsignmentFindConsignmentsCountForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
    return ownConsignmentFindConsignmentsCountForDispatchOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate and the current officer's dispatch offices.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindConsignmentsCountForDispatchOfficer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindConsignmentsCountForDispatchOfficer(params?: OwnConsignmentFindConsignmentsCountForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<CountDto> {
    return this.ownConsignmentFindConsignmentsCountForDispatchOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountDto>): CountDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetProofOfDelivery()` */
  static readonly OwnConsignmentGetProofOfDeliveryPath = '/sad/own-consignment/proof-of-delivery/{proofOfDeliveryId}';

  /**
   * Proof of Delivery get.
   *
   * Returns Proof of Delivery.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetProofOfDelivery()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetProofOfDelivery$Response(params: OwnConsignmentGetProofOfDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<ProofOfDeliveryDto>> {
    return ownConsignmentGetProofOfDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * Proof of Delivery get.
   *
   * Returns Proof of Delivery.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetProofOfDelivery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetProofOfDelivery(params: OwnConsignmentGetProofOfDelivery$Params, context?: HttpContext): Observable<ProofOfDeliveryDto> {
    return this.ownConsignmentGetProofOfDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProofOfDeliveryDto>): ProofOfDeliveryDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentReturnConsignmentsPerStateCountForSecretariat()` */
  static readonly OwnConsignmentReturnConsignmentsPerStateCountForSecretariatPath = '/sad/own-consignment/per-state-count/secretariat';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for. Consignments are grouped by consignment state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentReturnConsignmentsPerStateCountForSecretariat()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForSecretariat$Response(params?: OwnConsignmentReturnConsignmentsPerStateCountForSecretariat$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>> {
    return ownConsignmentReturnConsignmentsPerStateCountForSecretariat(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for. Consignments are grouped by consignment state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentReturnConsignmentsPerStateCountForSecretariat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForSecretariat(params?: OwnConsignmentReturnConsignmentsPerStateCountForSecretariat$Params|null|undefined, context?: HttpContext): Observable<OwnConsignmentPerStateCountForOwnerDto> {
    return this.ownConsignmentReturnConsignmentsPerStateCountForSecretariat$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>): OwnConsignmentPerStateCountForOwnerDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1()` */
  static readonly OwnConsignmentReturnConsignmentsPerStateCountForSecretariat_1Path = '/sad/own-consignment/per-state-count/secretariat/org-unit/{orgUnitId}';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for. Consignments are grouped by consignment state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1$Response(params: OwnConsignmentReturnConsignmentsPerStateCountForSecretariat_1$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>> {
    return ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the passed search predicate. Only searches among consignments the current user is secretariat for. Consignments are grouped by consignment state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1(params: OwnConsignmentReturnConsignmentsPerStateCountForSecretariat_1$Params, context?: HttpContext): Observable<OwnConsignmentPerStateCountForOwnerDto> {
    return this.ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>): OwnConsignmentPerStateCountForOwnerDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentReturnConsignmentsPerStateCountForOwner()` */
  static readonly OwnConsignmentReturnConsignmentsPerStateCountForOwnerPath = '/sad/own-consignment/per-state-count/owner';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types the current user owns grouped by consignment state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentReturnConsignmentsPerStateCountForOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForOwner$Response(params?: OwnConsignmentReturnConsignmentsPerStateCountForOwner$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>> {
    return ownConsignmentReturnConsignmentsPerStateCountForOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types the current user owns grouped by consignment state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentReturnConsignmentsPerStateCountForOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForOwner(params?: OwnConsignmentReturnConsignmentsPerStateCountForOwner$Params|null|undefined, context?: HttpContext): Observable<OwnConsignmentPerStateCountForOwnerDto> {
    return this.ownConsignmentReturnConsignmentsPerStateCountForOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>): OwnConsignmentPerStateCountForOwnerDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer()` */
  static readonly OwnConsignmentReturnConsignmentsPerStateCountForDispatchOfficerPath = '/sad/own-consignment/per-state-count/dispatch-officer';

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the current officer's dispatch offices grouped by consignment state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer$Response(params?: OwnConsignmentReturnConsignmentsPerStateCountForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnConsignmentPerStateCountForDispatchOfficerDto>> {
    return ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of consignments.
   *
   * Returns the number of own consignments of all types for the current officer's dispatch offices grouped by consignment state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer(params?: OwnConsignmentReturnConsignmentsPerStateCountForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<OwnConsignmentPerStateCountForDispatchOfficerDto> {
    return this.ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnConsignmentPerStateCountForDispatchOfficerDto>): OwnConsignmentPerStateCountForDispatchOfficerDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentGetOrganizationOutcomeDistributionNodes()` */
  static readonly OwnConsignmentGetOrganizationOutcomeDistributionNodesPath = '/sad/own-consignment/outcome-distribution-node/for-delivery-type/{deliveryTypeId}';

  /**
   * Requested organization's outcome distribution nodes.
   *
   * Returns organization's outcome distribution nodes for a delivery type.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentGetOrganizationOutcomeDistributionNodes()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOrganizationOutcomeDistributionNodes$Response(params: OwnConsignmentGetOrganizationOutcomeDistributionNodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(SheetNodeDto | DataBoxNodeDto | EmailNodeDto)>>> {
    return ownConsignmentGetOrganizationOutcomeDistributionNodes(this.http, this.rootUrl, params, context);
  }

  /**
   * Requested organization's outcome distribution nodes.
   *
   * Returns organization's outcome distribution nodes for a delivery type.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentGetOrganizationOutcomeDistributionNodes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentGetOrganizationOutcomeDistributionNodes(params: OwnConsignmentGetOrganizationOutcomeDistributionNodes$Params, context?: HttpContext): Observable<Array<(SheetNodeDto | DataBoxNodeDto | EmailNodeDto)>> {
    return this.ownConsignmentGetOrganizationOutcomeDistributionNodes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(SheetNodeDto | DataBoxNodeDto | EmailNodeDto)>>): Array<(SheetNodeDto | DataBoxNodeDto | EmailNodeDto)> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner()` */
  static readonly OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwnerPath = '/sad/own-consignment/office-desk/per-state-count/owner';

  /**
   * Returns the number of office desk consignments.
   *
   * Returns the number of own consignments of office desk type the current user owns grouped by consignment state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner$Response(params?: OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentPerStateCountForOwnerDto>> {
    return ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of office desk consignments.
   *
   * Returns the number of own consignments of office desk type the current user owns grouped by consignment state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner(params?: OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner$Params|null|undefined, context?: HttpContext): Observable<OwnOfficeDeskConsignmentPerStateCountForOwnerDto> {
    return this.ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentPerStateCountForOwnerDto>): OwnOfficeDeskConsignmentPerStateCountForOwnerDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer()` */
  static readonly OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficerPath = '/sad/own-consignment/office-desk/per-state-count/dispatch-officer';

  /**
   * Returns the number of office desk consignments.
   *
   * Returns the number of own consignments of office desk for the current officer's dispatch offices grouped by consignment state.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer$Response(params?: OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto>> {
    return ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the number of office desk consignments.
   *
   * Returns the number of own consignments of office desk for the current officer's dispatch offices grouped by consignment state.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer(params?: OwnConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer$Params|null|undefined, context?: HttpContext): Observable<OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto> {
    return this.ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto>): OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindOfficeDeskNodesForFunctionalPosition()` */
  static readonly OwnConsignmentFindOfficeDeskNodesForFunctionalPositionPath = '/sad/own-consignment/office-desk-nodes';

  /**
   * Finds office desk nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindOfficeDeskNodesForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOfficeDeskNodesForFunctionalPosition$Response(params?: OwnConsignmentFindOfficeDeskNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OfficeDeskNodeDto>>> {
    return ownConsignmentFindOfficeDeskNodesForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds office desk nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindOfficeDeskNodesForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOfficeDeskNodesForFunctionalPosition(params?: OwnConsignmentFindOfficeDeskNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<Array<OfficeDeskNodeDto>> {
    return this.ownConsignmentFindOfficeDeskNodesForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OfficeDeskNodeDto>>): Array<OfficeDeskNodeDto> => r.body)
    );
  }

  /** Path part for operation `ownConsignmentCountOwnConsignmentsForDocument()` */
  static readonly OwnConsignmentCountOwnConsignmentsForDocumentPath = '/sad/own-consignment/count/by-document/{id}';

  /**
   * Counts Own Consignments For Document.
   *
   * Counts all own consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentCountOwnConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentCountOwnConsignmentsForDocument$Response(params: OwnConsignmentCountOwnConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<CountDto>> {
    return ownConsignmentCountOwnConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Counts Own Consignments For Document.
   *
   * Counts all own consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentCountOwnConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentCountOwnConsignmentsForDocument(params: OwnConsignmentCountOwnConsignmentsForDocument$Params, context?: HttpContext): Observable<CountDto> {
    return this.ownConsignmentCountOwnConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<CountDto>): CountDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindOwnConsignmentsForOwnDocument()` */
  static readonly OwnConsignmentFindOwnConsignmentsForOwnDocumentPath = '/sad/own-consignment/by-own-document/{id}';

  /**
   * Search Own Consignments For Own Document.
   *
   * Returns all own consignments, be they paper or digital, for a given own document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindOwnConsignmentsForOwnDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOwnConsignmentsForOwnDocument$Response(params: OwnConsignmentFindOwnConsignmentsForOwnDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOwnConsignmentDto>> {
    return ownConsignmentFindOwnConsignmentsForOwnDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Own Consignments For Own Document.
   *
   * Returns all own consignments, be they paper or digital, for a given own document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindOwnConsignmentsForOwnDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOwnConsignmentsForOwnDocument(params: OwnConsignmentFindOwnConsignmentsForOwnDocument$Params, context?: HttpContext): Observable<PageOwnConsignmentDto> {
    return this.ownConsignmentFindOwnConsignmentsForOwnDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnConsignmentDto>): PageOwnConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentFindOwnConsignmentsForDocument()` */
  static readonly OwnConsignmentFindOwnConsignmentsForDocumentPath = '/sad/own-consignment/by-document/{id}';

  /**
   * Search Own Consignments For Document.
   *
   * Returns all own consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentFindOwnConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOwnConsignmentsForDocument$Response(params: OwnConsignmentFindOwnConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOwnConsignmentDto>> {
    return ownConsignmentFindOwnConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Own Consignments For Document.
   *
   * Returns all own consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentFindOwnConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentFindOwnConsignmentsForDocument(params: OwnConsignmentFindOwnConsignmentsForDocument$Params, context?: HttpContext): Observable<PageOwnConsignmentDto> {
    return this.ownConsignmentFindOwnConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOwnConsignmentDto>): PageOwnConsignmentDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentBulkDelete()` */
  static readonly OwnConsignmentBulkDeletePath = '/sad/own-consignment/bulk-delete';

  /**
   * Consignments Deletion.
   *
   * Delete a consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentBulkDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkDelete$Response(params: OwnConsignmentBulkDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ownConsignmentBulkDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Consignments Deletion.
   *
   * Delete a consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentBulkDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentBulkDelete(params: OwnConsignmentBulkDelete$Params, context?: HttpContext): Observable<void> {
    return this.ownConsignmentBulkDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
