/* eslint-disable @angular-eslint/no-input-rename */
import {Directive, ElementRef, inject, Input, Renderer2} from '@angular/core';
import {AbstractHighlightDirective} from './abstract-highlight.directive';
import {IczOnChanges, IczSimpleChanges} from '../../utils/icz-on-changes';
import {replaceLast} from '../../lib/utils';

export interface OffsetHighlight {
  offset: number;
  length: number;
}

@Directive({
  selector: '[iczHighlightText]',
})
export class HighlightTextDirective extends AbstractHighlightDirective implements IczOnChanges {

  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  @Input({alias: 'iczHighlightText', required: true})
  text: Nullable<string>; // text. could be HTML. **It must be trusted cause otherwise it will open the app to XSS attacks**

  @Input('iczHighlightText.terms')
  terms: string[] = []; // terms to highlight in text. typically based on user input. are sanitized against html injection

  @Input('iczHighlightText.offsets')
  offsets: OffsetHighlight[] = [];

  @Input('iczHighlightText.highlightAll')
  highlightAll: boolean = false;

  @Input('iczHighlightText.if')
  activateHighlighting: boolean = true; // could be disabled based on some external condition

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.terms || changes.text || changes.offsets || changes.highlightAll || changes.activateHighlighting) {
      if (this.activateHighlighting) {
        if (this.highlightAll) {
          this.renderInnerContent(this.insertHighlightElement(this.text));
        }
        else if (this.terms?.length) {
          this.renderTermMode();
        }
        else if (this.offsets?.length) {
          this.renderOffsetMode();
        }
        else {
          this.renderInnerContent(this.text);
        }
      }
      else {
        this.renderInnerContent(this.text);
      }
    }
  }

  private renderTermMode() {
    const escapedNonemptyTerms = this.terms.filter(t => t);

    if (escapedNonemptyTerms.length) {
      this.renderInnerContent(this.highlightOccurrences(escapedNonemptyTerms));
    }
    else {
      this.renderInnerContent(this.text);
    }
  }

  private renderOffsetMode() {
    this.renderInnerContent(this.highlightByOffsets(this.offsets));
  }

  private renderInnerContent(html: Nullable<string>) {
    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      html,
    );
  }

  private highlightOccurrences(escapedTerms: string[]) {
    return (this.text ?? '').replace(
      this.getRegexForTerms(escapedTerms),
      this.insertHighlightElement('$1'),
    );
  }

  private highlightByOffsets(offsets: OffsetHighlight[]) {
    // Sorted by offset descending. It brings the benefit that we will not need
    // to recompute string offsets each time after inserting a mark element.
    const sortedOffsets = offsets.filter(o => o.length > 0)
      .sort((o1, o2) => o2.offset - o1.offset);

    let outText = this.text ?? '';

    for (const offset of sortedOffsets) {
      const substringByOffsets = outText.substr(offset.offset, offset.length);
      outText = replaceLast(outText, substringByOffsets, this.insertHighlightElement(substringByOffsets));
    }

    return outText;
  }

  private insertHighlightElement(toString: Nullable<string>) {
    return `<mark>${toString}</mark>`;
  }
}
