/* tslint:disable */
/* eslint-disable */

/**
 * Issd Conversion Mapping Direction
 */
export enum IssdConversionMappingDirection {
  BOTH_WAYS = 'BOTH_WAYS',
  TO_ESSL = 'TO_ESSL',
  TO_ISSD = 'TO_ISSD'
}
