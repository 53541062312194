<div class="row-group px-16 py-8">
  <icz-button
    class="grow"
    [class.active]="selectionMode === DateFilterSelectionMode.DATE"
    [disabled]="isSingleDateSwitchDisabled"
    label="Zadat datum"
    (onAction)="switchToDateMode()"
  ></icz-button>
  <icz-button
    class="grow"
    [class.active]="selectionMode === DateFilterSelectionMode.INTERVAL"
    [disabled]="isIntervalSwitchDisabled"
    label="Zadat interval"
    (onAction)="switchToIntervalMode()"
  ></icz-button>
</div>
<icz-date-picker-shortcuts
  [selectionMode]="selectionMode"
  [activeIntervalShortcut]="activeShortcutType"
  (shortcutClicked)="handleDateShortcutChange($event)"
></icz-date-picker-shortcuts>
@if (selectionMode === DateFilterSelectionMode.DATE) {
  <form class="px-8 row-group" [formGroup]="calendarDateForm">
    <icz-form-field
      class="date-input grow" formControlName="date"
      iczAutoFocus (keydown.enter)="applyDateInputValue()"
      >
      <span prefix>{{ 'Datum' | translate }}:&nbsp;</span>
    </icz-form-field>
    <icz-button
      class="apply-date-filter"
      primary label="Použít"
      (onAction)="applyDateInputValue()"
    ></icz-button>
  </form>
  <icz-calendar
    [selected]="selected"
    (selectedChange)="onDateChange($event)"
    (daySelected)="onDayClick()"
  ></icz-calendar>
}
@else {
  <icz-calendar-range
    [selectedDateRange]="$any(selected)"
    (selectedDateRangeChange)="onDateChange($event)"
  ></icz-calendar-range>
}
