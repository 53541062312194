/* tslint:disable */
/* eslint-disable */

/**
 * Object class for File
 */
export enum FileAllowableObjectClass {
  FILE = 'FILE',
  FILE_DIGITAL = 'FILE_DIGITAL'
}
