/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageTeleFaxNodeDto } from '../models/page-tele-fax-node-dto';
import { TeleFaxNodeDto } from '../models/tele-fax-node-dto';
import { teleFaxNodesOauthCreate } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-create';
import { TeleFaxNodesOauthCreate$Params } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-create';
import { teleFaxNodesOauthGetAll } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-get-all';
import { TeleFaxNodesOauthGetAll$Params } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-get-all';
import { teleFaxNodesOauthGetById } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-get-by-id';
import { TeleFaxNodesOauthGetById$Params } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-get-by-id';
import { teleFaxNodesOauthGetDefault } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-get-default';
import { TeleFaxNodesOauthGetDefault$Params } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-get-default';
import { teleFaxNodesOauthSearch } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-search';
import { TeleFaxNodesOauthSearch$Params } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-search';
import { teleFaxNodesOauthUpdate } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-update';
import { TeleFaxNodesOauthUpdate$Params } from '../fn/tele-fax-nodes-oauth/tele-fax-nodes-oauth-update';


/**
 * API for management of telefax nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiTeleFaxNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `teleFaxNodesOauthUpdate()` */
  static readonly TeleFaxNodesOauthUpdatePath = '/oauth/tele-fax-nodes/update-node/{id}';

  /**
   * Updates telefax node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teleFaxNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teleFaxNodesOauthUpdate$Response(params: TeleFaxNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<TeleFaxNodeDto>> {
    return teleFaxNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates telefax node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teleFaxNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teleFaxNodesOauthUpdate(params: TeleFaxNodesOauthUpdate$Params, context?: HttpContext): Observable<TeleFaxNodeDto> {
    return this.teleFaxNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeleFaxNodeDto>): TeleFaxNodeDto => r.body)
    );
  }

  /** Path part for operation `teleFaxNodesOauthCreate()` */
  static readonly TeleFaxNodesOauthCreatePath = '/oauth/tele-fax-nodes/create-node';

  /**
   * Creates new telefax node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teleFaxNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teleFaxNodesOauthCreate$Response(params: TeleFaxNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<TeleFaxNodeDto>> {
    return teleFaxNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new telefax node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teleFaxNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  teleFaxNodesOauthCreate(params: TeleFaxNodesOauthCreate$Params, context?: HttpContext): Observable<TeleFaxNodeDto> {
    return this.teleFaxNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeleFaxNodeDto>): TeleFaxNodeDto => r.body)
    );
  }

  /** Path part for operation `teleFaxNodesOauthGetById()` */
  static readonly TeleFaxNodesOauthGetByIdPath = '/oauth/tele-fax-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teleFaxNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthGetById$Response(params: TeleFaxNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<TeleFaxNodeDto>> {
    return teleFaxNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teleFaxNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthGetById(params: TeleFaxNodesOauthGetById$Params, context?: HttpContext): Observable<TeleFaxNodeDto> {
    return this.teleFaxNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeleFaxNodeDto>): TeleFaxNodeDto => r.body)
    );
  }

  /** Path part for operation `teleFaxNodesOauthSearch()` */
  static readonly TeleFaxNodesOauthSearchPath = '/oauth/tele-fax-nodes/search-api';

  /**
   * Find all values in telefax nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teleFaxNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthSearch$Response(params?: TeleFaxNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageTeleFaxNodeDto>> {
    return teleFaxNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in telefax nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teleFaxNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthSearch(params?: TeleFaxNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageTeleFaxNodeDto> {
    return this.teleFaxNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageTeleFaxNodeDto>): PageTeleFaxNodeDto => r.body)
    );
  }

  /** Path part for operation `teleFaxNodesOauthGetAll()` */
  static readonly TeleFaxNodesOauthGetAllPath = '/oauth/tele-fax-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teleFaxNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthGetAll$Response(params?: TeleFaxNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<TeleFaxNodeDto>>> {
    return teleFaxNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teleFaxNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthGetAll(params?: TeleFaxNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<TeleFaxNodeDto>> {
    return this.teleFaxNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TeleFaxNodeDto>>): Array<TeleFaxNodeDto> => r.body)
    );
  }

  /** Path part for operation `teleFaxNodesOauthGetDefault()` */
  static readonly TeleFaxNodesOauthGetDefaultPath = '/oauth/tele-fax-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `teleFaxNodesOauthGetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthGetDefault$Response(params?: TeleFaxNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<TeleFaxNodeDto>> {
    return teleFaxNodesOauthGetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `teleFaxNodesOauthGetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  teleFaxNodesOauthGetDefault(params?: TeleFaxNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<TeleFaxNodeDto> {
    return this.teleFaxNodesOauthGetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<TeleFaxNodeDto>): TeleFaxNodeDto => r.body)
    );
  }

}
