<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-org-structure-selection-loader
    label="Zvolit skupinu pro předání"
    [parentForm]="form"
    (groupSelected)="applySavedGroup($event)"
  ></icz-org-structure-selection-loader>
  <icz-section label="Komu dát ke schválení">
    <icz-org-structure-selector
      [form]="form"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      controlName="orgStructureElementIds"
      [isMultiselect]="true"
      label="Funkční místa nebo organizační jednotky"
    ></icz-org-structure-selector>
    <icz-org-structure-selection-saver
      [parentForm]="form"
      [group]="groupForSaving"
      [isGroupOrdered]="true"
    ></icz-org-structure-selection-saver>
  </icz-section>
  <div class="row">
    <div class="col grow">
      <icz-section label="Instrukce">
        <icz-form-field formControlName="targetTaskInstruction"
          label="Instrukce"
          [autoSizeMax]="3"
        ></icz-form-field>
      </icz-section>
      @if (selectedParticipants?.length) {
        <icz-section label="Pořadí předání">
          <icz-form-item-sorter formControlName="taskParticipantsOrder" class="grow" [options]="selectedParticipants"></icz-form-item-sorter>
        </icz-section>
      }
    </div>
    <div class="col grow">
      <icz-section label="Termín">
        <icz-date-picker formControlName="deadline"
          label="Termín, dokdy potřebujete mít schváleno"
          [selectableDates]="deadlineValidator"
        ></icz-date-picker>
      </icz-section>
      <!--<icz-section *ngIf="initEntity?.documentForm === DocumentForm.ANALOG" testingFeature label="Tisk">
      <icz-checkbox formControlName="printHandoverProtocol"
        label="Tisknout předávací protokol"
      ></icz-checkbox>
    </icz-section>-->
  </div>
</div>
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="handForApproval()" primary label="Dát ke schválení"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
