import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AuthorizedEntityType, PermissionSetLevel} from '|api/core';
import {
  areSelectorValuesSame,
  GeneralPermissionSetLevel,
  PermissionSelectorPermission,
  PermissionSelectorValue
} from '../permission-selector-popup/permission-selector-popup.component';
import {FormField} from '../../../form-elements/form-field';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {Option} from '../../../../model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {enumToOptions} from '../../../../core/services/data-mapping.utils';
import {SharedFolderPermissionLevel} from '|api/commons';

export interface PermissionSelectorPopupClosedEvent {
  permissionOptions: PermissionSelectorValue;
  permissionsUpdated: boolean;
}

const DEFAULT_PERMISSION_OPTIONS_FORM_DATA: PermissionSelectorValue = {
  revoking: false,
  detailedPermissions: false,
  permissions: [],
  permissionSetLevel: null,
};

const readonlyOptionForFull = {value: 'dummy', label: 'Nastavena podrobná oprávnění'};

/*
  Permission selector
  1. Is used to open permission selector popup and also view selected permissions from popup
  2. It can be used as form element in Angular Forms
*/


@Component({
  selector: 'icz-permission-selector',
  templateUrl: './permission-selector.component.html',
  styleUrls: ['./permission-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionSelectorComponent extends FormField<PermissionSelectorValue> implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Input() showInvertedStatus = true;
  @Input({required: true}) entityType!: AuthorizedEntityType;

  @Output()
  popupOpened = new EventEmitter();

  @Output()
  popupClosed = new EventEmitter<PermissionSelectorPopupClosedEvent>();

  override set value(newValue: Nullable<PermissionSelectorValue>) {
    if (newValue) {
      if (!areSelectorValuesSame(this.getPermissionSelectorValueFromForm(), newValue)) {
        this.form.patchValue(newValue);
      }
    }
    else {
      this.form.patchValue(DEFAULT_PERMISSION_OPTIONS_FORM_DATA);
    }
  }
  override get value(): Nullable<PermissionSelectorValue> {
    return this.getPermissionSelectorValueFromForm();
  }

  // value type = PermissionSelectorValue
  form = new IczFormGroup({
    detailedPermissions: new IczFormControl<Nullable<boolean>>(false),
    revoking: new IczFormControl<Nullable<boolean>>(false),
    permissions: new IczFormControl<Nullable<PermissionSelectorPermission[]>>([]),
    permissionSetLevel: new IczFormControl<Nullable<GeneralPermissionSetLevel>>(null),
  });

  private _permissionSelectorOpened$ = new BehaviorSubject(false);

  permissionSelectorOpened$ = this._permissionSelectorOpened$.asObservable();

  shortPermissionOptions: Option<GeneralPermissionSetLevel>[] = [];

  handlePermissionChange(event: PermissionSelectorValue) {
    this.form.patchValue(event);
  }

  get optionForField() {
    return this.isDetailedList ? [readonlyOptionForFull] : this.shortPermissionOptions;
  }

  get valueForField() {
    return this.isDetailedList ? readonlyOptionForFull.value : this.form.value.permissionSetLevel;
  }

  get invertedTooltip() {
    return this.isDisablingPermissions ? 'Zakázat oprávnění' : 'Povolit oprávnění';
  }

  get invertedStatusIcon() {
    return this.isDisablingPermissions ? 'cross' : 'checked';
  }

  get isDisablingPermissions() {
    return this.form.value.revoking;
  }

  get isDetailedList() {
    return this.form.value.detailedPermissions;
  }

  get arePermissionsDefined() {
    return this.isDetailedList ? (this.form.value.permissions?.length ?? 0) > 0 : Boolean(this.form.value.permissionSetLevel);
  }

  get showInvertedStatusIcon() {
    return this.showInvertedStatus && this.arePermissionsDefined;
  }

  get isSharedFolderEntity() {
    return this.entityType === AuthorizedEntityType.SHARED_FOLDER;
  }

  openPermissionSelector() {
    if (this.fieldDisabled) {
      return;
    }
    this._permissionSelectorOpened$.next(true);
    this.popupOpened.emit();
  }

  closePermissionSelector(permissionsUpdated: boolean) {
    this._permissionSelectorOpened$.next(false);
    this.popupClosed.emit({permissionsUpdated, permissionOptions: this.form.getRawValue() as PermissionSelectorValue});
  }

  listenForValueChanges() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this._valueChanged(this.getPermissionSelectorValueFromForm());
    });
    if(this.value) {
      this.form.patchValue(this.value);
    }
  }

  override _valueChanged($event: PermissionSelectorValue) {
    this.valueChange.emit($event);
    this.blur.emit();
  }

  ngOnInit() {
    this.listenForValueChanges();

    if (this.isSharedFolderEntity) {
      this.shortPermissionOptions = enumToOptions('sharedFolderPermissionLevel', SharedFolderPermissionLevel) as Option<GeneralPermissionSetLevel>[];
    }
    else {
      this.shortPermissionOptions = enumToOptions('permissionSetLevel', PermissionSetLevel) as Option<GeneralPermissionSetLevel>[];
    }
  }

  private getPermissionSelectorValueFromForm(): PermissionSelectorValue {
    const formValue = this.form.value;

    return {
      detailedPermissions: formValue.detailedPermissions!,
      revoking: formValue.revoking!,
      permissions: formValue.permissions,
      permissionSetLevel: formValue.permissionSetLevel,
    };
  }

}
