/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageSubstitutionDto } from '../models/page-substitution-dto';
import { substitutionCloseFunctionalPositionsSubstitutions } from '../fn/substitution/substitution-close-functional-positions-substitutions';
import { SubstitutionCloseFunctionalPositionsSubstitutions$Params } from '../fn/substitution/substitution-close-functional-positions-substitutions';
import { substitutionCreateFunctionalPositionSubstitutions } from '../fn/substitution/substitution-create-functional-position-substitutions';
import { SubstitutionCreateFunctionalPositionSubstitutions$Params } from '../fn/substitution/substitution-create-functional-position-substitutions';
import { substitutionCreateUserSubstitutions } from '../fn/substitution/substitution-create-user-substitutions';
import { SubstitutionCreateUserSubstitutions$Params } from '../fn/substitution/substitution-create-user-substitutions';
import { substitutionDeleteByIds } from '../fn/substitution/substitution-delete-by-ids';
import { SubstitutionDeleteByIds$Params } from '../fn/substitution/substitution-delete-by-ids';
import { SubstitutionDto } from '../models/substitution-dto';
import { substitutionFindById } from '../fn/substitution/substitution-find-by-id';
import { SubstitutionFindById$Params } from '../fn/substitution/substitution-find-by-id';
import { substitutionSearch } from '../fn/substitution/substitution-search';
import { SubstitutionSearch$Params } from '../fn/substitution/substitution-search';
import { substitutionSearchByRole } from '../fn/substitution/substitution-search-by-role';
import { SubstitutionSearchByRole$Params } from '../fn/substitution/substitution-search-by-role';
import { substitutionUpdateSubstitution } from '../fn/substitution/substitution-update-substitution';
import { SubstitutionUpdateSubstitution$Params } from '../fn/substitution/substitution-update-substitution';


/**
 * Controller with operations over substitutions
 */
@Injectable({ providedIn: 'root' })
export class ApiSubstitutionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `substitutionFindById()` */
  static readonly SubstitutionFindByIdPath = '/substitution/{id}';

  /**
   * Find substitution with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionFindById$Response(params: SubstitutionFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SubstitutionDto>> {
    return substitutionFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find substitution with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionFindById(params: SubstitutionFindById$Params, context?: HttpContext): Observable<SubstitutionDto> {
    return this.substitutionFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubstitutionDto>): SubstitutionDto => r.body)
    );
  }

  /** Path part for operation `substitutionUpdateSubstitution()` */
  static readonly SubstitutionUpdateSubstitutionPath = '/substitution/{id}';

  /**
   * Updates user substitution for functional positions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionUpdateSubstitution()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionUpdateSubstitution$Response(params: SubstitutionUpdateSubstitution$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return substitutionUpdateSubstitution(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates user substitution for functional positions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionUpdateSubstitution$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionUpdateSubstitution(params: SubstitutionUpdateSubstitution$Params, context?: HttpContext): Observable<void> {
    return this.substitutionUpdateSubstitution$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `substitutionCreateUserSubstitutions()` */
  static readonly SubstitutionCreateUserSubstitutionsPath = '/substitution/user';

  /**
   * User Substitutions Create.
   *
   * Creates new user substitution for functional positions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionCreateUserSubstitutions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionCreateUserSubstitutions$Response(params: SubstitutionCreateUserSubstitutions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return substitutionCreateUserSubstitutions(this.http, this.rootUrl, params, context);
  }

  /**
   * User Substitutions Create.
   *
   * Creates new user substitution for functional positions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionCreateUserSubstitutions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionCreateUserSubstitutions(params: SubstitutionCreateUserSubstitutions$Params, context?: HttpContext): Observable<void> {
    return this.substitutionCreateUserSubstitutions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `substitutionCreateFunctionalPositionSubstitutions()` */
  static readonly SubstitutionCreateFunctionalPositionSubstitutionsPath = '/substitution/functional-position';

  /**
   * Functional Position Substitutions Create.
   *
   * Creates new users substitutions for one functional position.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionCreateFunctionalPositionSubstitutions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionCreateFunctionalPositionSubstitutions$Response(params: SubstitutionCreateFunctionalPositionSubstitutions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return substitutionCreateFunctionalPositionSubstitutions(this.http, this.rootUrl, params, context);
  }

  /**
   * Functional Position Substitutions Create.
   *
   * Creates new users substitutions for one functional position.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionCreateFunctionalPositionSubstitutions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionCreateFunctionalPositionSubstitutions(params: SubstitutionCreateFunctionalPositionSubstitutions$Params, context?: HttpContext): Observable<void> {
    return this.substitutionCreateFunctionalPositionSubstitutions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `substitutionCloseFunctionalPositionsSubstitutions()` */
  static readonly SubstitutionCloseFunctionalPositionsSubstitutionsPath = '/substitution/close-functional-positions-substitutions';

  /**
   * Close Functional Positions Substitutions.
   *
   * Closes substitutions for given functional positions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionCloseFunctionalPositionsSubstitutions()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionCloseFunctionalPositionsSubstitutions$Response(params: SubstitutionCloseFunctionalPositionsSubstitutions$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return substitutionCloseFunctionalPositionsSubstitutions(this.http, this.rootUrl, params, context);
  }

  /**
   * Close Functional Positions Substitutions.
   *
   * Closes substitutions for given functional positions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionCloseFunctionalPositionsSubstitutions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionCloseFunctionalPositionsSubstitutions(params: SubstitutionCloseFunctionalPositionsSubstitutions$Params, context?: HttpContext): Observable<void> {
    return this.substitutionCloseFunctionalPositionsSubstitutions$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `substitutionSearchByRole()` */
  static readonly SubstitutionSearchByRolePath = '/substitution/substitution-by-role/search-api';

  /**
   * Find substitution records accessible to currently logged functional place by role
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionSearchByRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionSearchByRole$Response(params?: SubstitutionSearchByRole$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSubstitutionDto>> {
    return substitutionSearchByRole(this.http, this.rootUrl, params, context);
  }

  /**
   * Find substitution records accessible to currently logged functional place by role
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionSearchByRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionSearchByRole(params?: SubstitutionSearchByRole$Params|null|undefined, context?: HttpContext): Observable<PageSubstitutionDto> {
    return this.substitutionSearchByRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSubstitutionDto>): PageSubstitutionDto => r.body)
    );
  }

  /** Path part for operation `substitutionSearch()` */
  static readonly SubstitutionSearchPath = '/substitution/search-api';

  /**
   * Find all substitution records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionSearch$Response(params?: SubstitutionSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSubstitutionDto>> {
    return substitutionSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all substitution records
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  substitutionSearch(params?: SubstitutionSearch$Params|null|undefined, context?: HttpContext): Observable<PageSubstitutionDto> {
    return this.substitutionSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSubstitutionDto>): PageSubstitutionDto => r.body)
    );
  }

  /** Path part for operation `substitutionDeleteByIds()` */
  static readonly SubstitutionDeleteByIdsPath = '/substitution';

  /**
   * Deletes substitutions by id list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `substitutionDeleteByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionDeleteByIds$Response(params: SubstitutionDeleteByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return substitutionDeleteByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes substitutions by id list
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `substitutionDeleteByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  substitutionDeleteByIds(params: SubstitutionDeleteByIds$Params, context?: HttpContext): Observable<void> {
    return this.substitutionDeleteByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
