<icz-card [waiting]="loadingService.isLoading$(this) | async" class="block">
  <icz-dashboard-icon-counter
    [linksTo]="documentsNotificationsRoute"
    svgIcon="ringbell"
    [iconBadgeColor]="IconCounterBadgeColor.RED"
    name="Upozornění"
    description="Celkem nepřečtených"
    [count]="unreadNotificationCount$ | async">
  </icz-dashboard-icon-counter>
</icz-card>
