import {DestroyRef, inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalStorageKey, UserSettingsService} from './user-settings.service';
import {ActiveModuleService, ApplicationModule} from '../core/services/active-module.service';
import {AuthService} from '../core/authentication/auth.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface FavouritePage {
  module: ApplicationModule;
  label: string;
  url: string;
}

export interface FavouritePageCreateData {
  label: string;
  url: string;
}


@Injectable({
  providedIn: 'root',
})
export class FavouritePageService {

  private authService = inject(AuthService);
  private activeModuleService = inject(ActiveModuleService);
  private userSettingsService = inject(UserSettingsService);
  private destroyRef = inject(DestroyRef);

  private _favouritePages$!: BehaviorSubject<FavouritePage[]>;

  private _newFavouritePageHasBeenAdded$!: BehaviorSubject<boolean>;

  newFavouritePageHasBeenAdded$!: Observable<boolean>;

  favouritePageList$!: Observable<FavouritePage[]>;

  constructor() {
    this.initialize();

    this.authService.login$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      this.initialize();
    });
  }

  initialize() {
    this._favouritePages$ = new BehaviorSubject<FavouritePage[]>(
      (this.userSettingsService.getParsedValue(LocalStorageKey.FAVOURITE_PAGES) as FavouritePage[]) ?? []
    );
    this.favouritePageList$ = this._favouritePages$.asObservable();

    this._newFavouritePageHasBeenAdded$ = new BehaviorSubject<boolean>(false);
    this.newFavouritePageHasBeenAdded$ = this._newFavouritePageHasBeenAdded$.asObservable();
  }

  setNewFavouritePageHasBeenAdded() {
    this._newFavouritePageHasBeenAdded$.next(true);
  }

  resetNewFavouritePageHasBeenAdded() {
    this._newFavouritePageHasBeenAdded$.next(false);
  }

  isPageInFavouriteList(pageUrl: string) {
    const currentPages = this._favouritePages$.value;
    const isInFavouriteList = Boolean(currentPages.find(({url}) => url === pageUrl));
    return isInFavouriteList;
  }

  savePageToFavouriteList(page: FavouritePageCreateData) {
    const {url, label} = page;
    const currentPages = this._favouritePages$.value;
    const isAlreadyInList = this.isPageInFavouriteList(page.url);
    if (!isAlreadyInList) {
      const module = this.activeModuleService.activeModule;
      const newFavouritePages = [
        ...currentPages,
        {
          module,
          url,
          label
        }
      ];
      this._favouritePages$.next(newFavouritePages);
      this.userSettingsService.setParsedValue(LocalStorageKey.FAVOURITE_PAGES, newFavouritePages);
      this.setNewFavouritePageHasBeenAdded();
    }
  }

  removePageFromFavouriteList(pageUrl: string) {
    const currentPages = this._favouritePages$.value;
    const isAlreadyInList = this.isPageInFavouriteList(pageUrl);
    if (isAlreadyInList) {
      const filteredFavouritePages = currentPages.filter(page => page.url !== pageUrl);
      this._favouritePages$.next(filteredFavouritePages);
      this.userSettingsService.setParsedValue(LocalStorageKey.FAVOURITE_PAGES, filteredFavouritePages);
    }
  }

}
