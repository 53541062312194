import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {IczFormGroup} from '../../../form-elements/icz-form-controls';
import {ClassificationSchemeDto, DisposalScheduleDto, EntityClassDto, SecurityCategoryDto} from '|api/codebook';
import {formatDisposalSchedule} from '../../shared-document.utils';
import {forkJoin} from 'rxjs';
import {CodebookService} from '../../../../core/services/codebook.service';

enum EntityClassPopupTab {
  DESCRIPTION = 'DESCRIPTION',
  TREE_VIEW = 'TREE_VIEW'
}

@Component({
  selector: 'icz-entity-class-selector-popup',
  templateUrl: './entity-class-selector-popup.component.html',
  styleUrls: ['./entity-class-selector-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityClassSelectorPopupComponent implements OnInit {
  private codebookService = inject(CodebookService);
  private cd = inject(ChangeDetectorRef);

  @Input()
  selectedEntityClass: Nullable<EntityClassDto>;

  selectedClassificationScheme: Nullable<ClassificationSchemeDto>;
  selectedSecurityCategory: Nullable<SecurityCategoryDto>;
  disposalSchedules: DisposalScheduleDto[] = [];
  classificationSchemes: ClassificationSchemeDto[] = [];
  securityCategories: SecurityCategoryDto[] = [];

  readonly EntityClassPopupTab = EntityClassPopupTab;

  entityClassPopupForm = new IczFormGroup({});

  tabs = [
    {
      id: EntityClassPopupTab.DESCRIPTION,
      label: 'Základní popis',
    },
    {
      id: EntityClassPopupTab.TREE_VIEW,
      label: 'Zanoření ve spisovém plánu',
    }
  ];

  get selectedEntityClassId(): Nullable<number> {
    if (this.selectedEntityClass) {
      return this.selectedEntityClass.id;
    } else {
      return null;
    }
  }

  getEntityClassProperty(property: keyof EntityClassDto) {
    if (this.selectedEntityClass) {
      return (this.selectedEntityClass[property]) ? String(this.selectedEntityClass[property]) : '';
    } else {
      return '';
    }
  }

  get disposalScheduleForPopup() {
    if (this.selectedEntityClass && this.disposalSchedules.length > 0) {
      const disposalSchedule = this.disposalSchedules.find(ds => ds.id === this.selectedEntityClass!.disposalScheduleId);
      return formatDisposalSchedule(disposalSchedule);
    } else {
      return '';
    }
  }

  getClassificationSchemeProperty(property: keyof ClassificationSchemeDto): string {
    if (this.selectedEntityClass && this.classificationSchemes.length > 0) {
      if (isNil(this.selectedClassificationScheme) || (this.selectedClassificationScheme && this.selectedClassificationScheme.id !== this.selectedEntityClass.classificationSchemeId)) {
        this.selectedClassificationScheme = this.classificationSchemes.find(cs => cs.id === this.selectedEntityClass!.classificationSchemeId);
        if (this.selectedClassificationScheme) {
          return (this.selectedClassificationScheme[property]) ? String(this.selectedClassificationScheme[property]) : '';
        } else {
          return '';
        }
      } else {
        return (this.selectedClassificationScheme[property]) ? String(this.selectedClassificationScheme[property]) : '';
      }
    } else {
      return '';
    }
  }

  get securityCategoryLabel(): string {
    if (this.selectedEntityClass && this.securityCategories.length > 0) {
      if (isNil(this.selectedSecurityCategory) || (this.selectedSecurityCategory && this.selectedSecurityCategory.id !== this.selectedEntityClass.securityCategoryId)) {
        this.selectedSecurityCategory = this.securityCategories.find(sc => sc.id === this.selectedEntityClass!.securityCategoryId);
        if (this.selectedSecurityCategory) {
          return this.selectedSecurityCategory.name;
        } else {
          return '';
        }
      } else {
        return this.selectedSecurityCategory.name;
      }
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    forkJoin([
      this.codebookService.disposalSchedules(),
      this.codebookService.securityCategories(),
      this.codebookService.classificationSchemes(),
    ]).subscribe(([disposalSchedules, securityCategories, classificationSchemes]) => {
      this.disposalSchedules = disposalSchedules;
      this.classificationSchemes = classificationSchemes;
      this.securityCategories = securityCategories;
      this.cd.detectChanges();
    });
  }
}
