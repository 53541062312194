import {Component, TemplateRef, ViewChild} from '@angular/core';
import {AbstractTemplateCollection} from '../abstract-template-collection';
import {
  IAutocompleteListItemContext,
  IImplicitTemplateContext
} from '../../../form-elements/form-autocomplete/form-autocomplete.model';

@Component({
  templateUrl: './shared-template-collection.component.html',
})
export class SharedTemplateCollectionComponent extends AbstractTemplateCollection {
  // each new template should have prefix/suffix "template".
  @ViewChild('entityClassChipTemplate')
  entityClassChipTemplate!: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;

  @ViewChild('agendaActivityRoleTemplate')
  agendaActivityRoleTemplate!: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;
}
