import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {combineLatest} from 'rxjs';
import {OfficeDeskCategoryDto, OfficeDeskRegionDto} from '|api/codebook';
import {SubjectRecordDto} from '|api/commons';
import {AddressPipe} from '../../../../form-elements/address.pipe';
import {AbstractConsignmentSpecificationComponent} from '../abstract-consignment-specification.component';
import {IczOnChanges, IczSimpleChanges} from '../../../../../utils/icz-on-changes';
import {OwnOfficeDeskConsignmentForm} from '../consignment-dialog.form';
import {Option} from '../../../../../model';
import {IczDateValidators} from '../../../../form-elements/validators/icz-validators/icz-date-validators';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {ConsignmentUtilsService} from '../consignment-utils';
import {ApiBasicSettingsOauthService} from '|api/config-server';
import {deliveryTypeToOption, namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {isNilOrEmptyString} from '../../../../../lib/utils';


@Component({
  selector: 'icz-office-desk-consignment-specification',
  templateUrl: './office-desk-consignment-specification.component.html',
  styleUrls: ['./office-desk-consignment-specification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddressPipe],
})
export class OfficeDeskConsignmentSpecificationComponent extends AbstractConsignmentSpecificationComponent implements IczOnChanges, OnInit {

  private codebookService = inject(CodebookService);
  private consignmentUtilsService = inject(ConsignmentUtilsService);
  private apiBasicSettingsOauthService = inject(ApiBasicSettingsOauthService);
  private addressPipe = inject(AddressPipe);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  override specificFieldsForm!: OwnOfficeDeskConsignmentForm;
  @Input({required: true})
  consigneeForOfficeDesk!: Nullable<SubjectRecordDto>;
  @Output()
  dispatchOfficeDistributionNodeIdChanged = new EventEmitter<number>();

  @Input({required: true})
  isPublicPosting!: boolean;

  officeDeskDistributionNodeOptions: Option[] = [];
  officeDeskCategories: OfficeDeskCategoryDto[] = [];
  officeDeskCategoryOptions: Option[] = [];
  officeDeskRegionOptions: Option[] = [];
  officeDeskRegions: OfficeDeskRegionDto[] = [];
  deliveryTypeOptions: Option[] = [];

  presentOrFutureDateValidator = IczDateValidators.presentOrFutureDateValidator;

  nodeChanged(id: any) {
    this.dispatchOfficeDistributionNodeIdChanged.emit(id as number);
  }

  categoryChanged(id: number) {
    const category: Nullable<OfficeDeskCategoryDto> = this.officeDeskCategories.find(odc => odc.id === id);
    if (category) {
      this.specificFieldsForm.get('postingDuration')!.setValue(category.defaultPostingDuration);
      this.specificFieldsForm.get('officeDeskRegionId')!.setValue(null);
      this.filterSelectableRegionOptions(category.id);
    }
  }

  filterSelectableRegionOptions(categoryId: number) {
    this.officeDeskRegionOptions = this.officeDeskRegions.filter(r => r.officeDeskCategoryIds?.includes(categoryId)).map(namedDtoToOption());
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.isPublicPosting) {
      this.specificFieldsForm.get('publicPosting')!.setValue(this.isPublicPosting);
      if (this.isPublicPosting === false && this.consigneeForOfficeDesk) {
        this.specificFieldsForm.get('consignee')!.setValue(this.consigneeForOfficeDesk);
      }
    }
  }

  ngOnInit() {
    this.loadingService.doLoading(combineLatest([
      this.codebookService.officeDeskNodes(),
      this.codebookService.officeDeskCategories(),
      this.codebookService.officeDeskRegions(),
      this.codebookService.deliveryTypes(),
    ]), this)
      .subscribe(([nodes, categories, regions, deliveryTypes]) => {
        this.officeDeskDistributionNodeOptions = nodes!.filter(n => n.isActive && n.dispatchOfficeId).map(n => {
          return {label: n.nodeName, value: n.id};
        });
        this.officeDeskCategories = categories;
        this.officeDeskRegions = regions;
        this.officeDeskCategoryOptions = categories.map(namedDtoToOption());
        this.officeDeskRegionOptions = regions.map(namedDtoToOption());
        this.deliveryTypeOptions = deliveryTypes.map(deliveryTypeToOption);
      });

    const selectedCategoryId = this.specificFieldsForm.get('officeDeskCategoryId')!.value;
    if (selectedCategoryId) {
      this.filterSelectableRegionOptions(selectedCategoryId);
    }
    const labelCtrl = this.specificFieldsForm.get('label')!;
    if (this.documentData?.subject && isNilOrEmptyString(labelCtrl.value)) {
      labelCtrl.setValue(this.documentData?.subject);
    }
    if (this.documentData?.refNumber) {
      this.specificFieldsForm.get('refNumber')!.setValue(this.documentData?.refNumber);
    }

    if (!this.specificFieldsForm.get('originatorName')!.value || !this.specificFieldsForm.get('originatorAddress')!.value) {
      this.loadingService.doLoading(
        this.apiBasicSettingsOauthService.basicSettingsOauthFind(),
        this,
        'originator'
      ).subscribe(basicSettings => {
        this.specificFieldsForm.get('originatorName')!.setValue(basicSettings.organizationName);
        this.addressPipe.transform(basicSettings.address, true).subscribe(
          orgAddress => {
            this.specificFieldsForm.get('originatorAddress')!.setValue(orgAddress);
          }
        );
      });
    }

    this.consignmentUtilsService.registerCalcPostingDays(this.specificFieldsForm, this.destroyRef);
  }

}
