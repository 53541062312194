import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ComponentModalComponent} from './component-modal/component-modal.component';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';
import {WizardComponent} from './wizard/wizard.component';
import {WizardStepDirective} from './wizard/wizard-step.directive';
import {EssentialsModule} from '../essentials/essentials.module';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {SimpleDialogComponent} from './simple-dialog/simple-dialog.component';
import {ErrorDialogComponent} from './error-dialog/error-dialog.component';

@NgModule({
  declarations: [
    ModalDialogComponent,
    ErrorDialogComponent,
    WizardComponent,
    WizardStepDirective,
    ComponentModalComponent,
    SimpleDialogComponent,
  ],
  exports: [
    ModalDialogComponent,
    ErrorDialogComponent,
    WizardComponent,
    WizardStepDirective,
    SimpleDialogComponent
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    FormElementsModule,
  ]
})
export class DialogsModule {
}
