<icz-wizard
  #wizard
  [hidden]="loadingForUpdateMode"
  [waiting]="loadingService.isLoading$(this) | async"
  [alwaysEnableAllSteps]="enableAllSteps"
  [disableNextStepFormChecks]="currentWizardStep === ConsignmentWizardStep.CONSIGNEES_OVERVIEW"
  (finished)="saveConsignment()"
  (stepChanged)="wizardStepChanged($event)"
  (canceled)="cancel()"
  [readonlyWizard]="isEditConsignmentDialogType"
  [hideNextButton]="!isNew"
  >
  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.CONSIGNEES_OVERVIEW"
    [stepHidden]="isConsigneesOverviewDisabled"
    [customSubmitHandler]="goToConsignmentSpecification"
    submitLabel="Přejít k popisu"
    label="Adresát"
    >
    @if (canUserReadProfile) {
      <div>
        <ng-container *ngTemplateOutlet="writeAuthorizationAlert"></ng-container>
        @if (wasConsigneePreselected) {
          <div>
            <icz-alert class="mx-16 mt-16" heading="Přednastavili jsme pro vás adresáta. Jedná se o odesílatele původního doručeného dokumentu." severity="info"></icz-alert>
          </div>
        }
        <div class="row grow mt-8 p-16 gap-24">
          <div class="col grow">
            <icz-consignment-consignees-overview
              [isNew]="isNew"
              [additionalAddresses]="additionalAddresses"
              [selectedConsigneesWithDestination]="selectedConsigneesWithDestination"
              [officeDeskSpecifics]="officeDeskSpecifics"
              [readonly]="isEditConsignmentDialogType"
              [consignment]="modalData.consignment"
              [specificFieldsForm]="specificFieldsForm"
              [distributionClassForDispatch]="forDispatch.distributionClassForDispatch"
              (wizardStepChangeRequested)="wizard.setStepById($event)"
              (consigneeEdited)="consigneeEdited($event)"
              (addressEdited)="addressEdited($event)"
              (consigneeRemoved)="consigneeRemoved($event)"
            ></icz-consignment-consignees-overview>
            @if (modalData.consignment) {
              <div class="row mt-16">
                <icz-readonly-field class="grow" [value]="modalData.consignment.originalConsignee" label="Původní adresát"></icz-readonly-field>
              </div>
            }
            @if (isConsignmentMetadataSectionVisible) {
              <icz-consignment-delivery-result-overview
                [ownConsignment]="ownPaperConsignment"
                [modalRef]="modalRef"
              ></icz-consignment-delivery-result-overview>
            }
          </div>
          <div class="col grow">
            @if (isConsignmentMetadataSectionVisible) {
              <icz-consignment-meta-overview
                [ownConsignment]="ownPaperConsignment"
              ></icz-consignment-meta-overview>
            }
          </div>
        </div>
      </div>
    }
    @else {
      <icz-alert
        heading="Nemáte dostatečné oprávnění pro zobrazení informací o zásílce"
        class="mx-16 my-32" severity="info"
        >
      </icz-alert>
    }
  </ng-template>

  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.ORGANIZATIONAL_UNIT_SELECTION"
    [stepHidden]="!isCreateInternalConsignmentDialogType"
    [isValid]="true"
    label="Adresát"
    submitLabel="Přejít k popisu"
    [customSubmitHandler]="goToConsignmentSpecification"
    >
    <div class="row grow mt-8 p-16">
      <icz-consignment-org-unit-selection
        class="grow"
        [form]="$any(specificFieldsForm)"
        (organizationConsigneeChanged)="onOrgConsigneeChanges($event)"
      ></icz-consignment-org-unit-selection>
      <div class="col grow"></div>
    </div>
  </ng-template>

  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.CONSIGNEE_SELECTION"
    [stepHidden]="isConsigneeSelectionDisabled"
    label="Vyhledání adresáta"
    [submitLabel]="officeDeskSpecifics.isPublicPosting ? 'Přejít k popisu' : 'Vyhledat subjekt'"
    [customSubmitHandler]="consigneeSelectionSubmitHandler"
    >
    @if (modalData.documentData) {
      <icz-consignment-consignee-selection
        [documentData]="modalData.documentData"
        (initialized)="consigneeSelectionComponent = $event"
        [createNewConsignmentDialogType]="createNewConsignmentDialogType"
        (consigneeCreationRequested)="goToConsigneeCreation($event)"
        (distributionClassDispatchSelected)="distributionClassDispatchSelected($event)"
        (consigneeAddressChanged)="consigneeAddressChanged($event)"
      ></icz-consignment-consignee-selection>
    }
  </ng-template>

  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.CONSIGNEE_CREATION"
    label="Založení nového adresáta"
    submitLabel="Uložit a přejít k popisu"
    [isValid]="!submitBlockedByConsigneeCreation"
    [customSubmitHandler]="createSubjectForConsignment"
    [stepHidden]="currentWizardStep !== ConsignmentWizardStep.CONSIGNEE_CREATION"
    >
    @if (modalData.documentData && consigneeCreationData) {
      <icz-consignment-consignee-creation
        [documentData]="modalData.documentData"
        [consigneeCreationData]="consigneeCreationData"
        (subjectCreationRequested)="subjectCreationRequested($event)"
        (blockingAction)="submitBlockedByConsigneeCreation = $event"
        (initialized)="consigneeCreationComponent = $event"
      ></icz-consignment-consignee-creation>
    }
  </ng-template>

  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION"
    [label]="isOfficeDeskConsignment ? 'Popis vyvěšení' : 'Popis vypravení'"
    submitLabel="Uložit připravované"
    [customSubmitHandler]="saveConsignment"
    >
    <ng-container *ngTemplateOutlet="writeAuthorizationAlert"></ng-container>
    @if (modalData.documentData && (isConsigneeRequiredForCreate || isPortalConsignment) && !isCreateOfficeDeskConsignmentDialogType) {
      <div class="p-16">
        <icz-section label="Způsob vypravení">
          <icz-applicable-delivery-type-selector
            [form]="genericFieldsForm"
            controlName="deliveryTypeId"
            [subject]="selectedConsigneesWithDestination[0]!.consignee"
            [constrainToDistributionClass]="forDispatch.selectedDistributionClass"
            [disableFilingOfficeProcessingChange]="!isNew"
            (loadingStatusChanged)="deliveryTypeSelectorLoadingStatusChanged($event)"
            (applicableDeliveryTypeChanged)="consignmentApplicableDeliveryTypeChanged($event)"
          ></icz-applicable-delivery-type-selector>
        </icz-section>
        @if (usePaperConsignmentSpecification) {
          <icz-paper-or-personal-or-internal-consignment-specification
            [documentData]="modalData.documentData"
            [consignmentForEdit]="modalData.consignment"
            [canAddNewAddress]="canAddNewAddress"
            [specificFieldsForm]="$any(specificFieldsForm)"
            [consignee]="selectedConsigneesWithDestination[0]!.consignee"
            [selectedDistributionClass]="forDispatch.selectedDistributionClass"
            [consigneeAddressOverrides]="consigneeAddressOverrides"
            [additionalAddresses]="additionalAddresses"
            [distributionClassForDispatch]="forDispatch.distributionClassForDispatch!"
            [deliveryTypeId]="deliveryTypeId"
            [selectedDeliveryType]="forDispatch.deliveryTypeForDispatch"
            [consignmentForEnvelopePreview]="consignmentForEnvelopePreview"
            (addressAdded)="consigneeAddressAdded($event)"
          ></icz-paper-or-personal-or-internal-consignment-specification>
        }
        @if (forDispatch.distributionClassForDispatch === DistributionClass.EMAIL_BOX) {
          <icz-email-consignment-specification
            [deliveryTypeId]="deliveryTypeId"
            [consignment]="$any(modalData.consignment)"
            [consignee]="selectedConsigneesWithDestination[0]!.consignee"
            [specificFieldsForm]="$any(specificFieldsForm)"
            [selectedDistributionClass]="forDispatch.selectedDistributionClass"
            [deliveryFilingType]="deliveryFilingType"
            [(additionalEmailAddresses)]="additionalEmailAddresses"
          ></icz-email-consignment-specification>
        }
        @if (forDispatch.distributionClassForDispatch === DistributionClass.ISDS_BOX) {
          <icz-databox-consignment-specification
            [documentData]="modalData.documentData"
            [deliveryTypeId]="deliveryTypeId"
            [consignee]="selectedConsigneesWithDestination[0]!.consignee"
            [consignmentForEdit]="modalData.consignment"
            [specificFieldsForm]="$any(specificFieldsForm)"
            [selectedDistributionClass]="forDispatch.selectedDistributionClass"
          ></icz-databox-consignment-specification>
        }
        @if (isFaxConsignment) {
          <icz-telefax-consignment-specification
            [specificFieldsForm]="$any(specificFieldsForm)"
          ></icz-telefax-consignment-specification>
        }
        @if (isPortalConsignment) {
          <icz-portal-consignment-specification
            [specificFieldsForm]="$any(specificFieldsForm)"
          ></icz-portal-consignment-specification>
        }
      </div>
    }
    @if (modalData.documentData && officeDeskSpecifics.deliveryType) {
      <div class="p-16">
        @if (forDispatch.selectedDistributionClass === DistributionClass.OFFICIAL_BOARD || forDispatch.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD) {
          <icz-office-desk-consignment-specification
            [documentData]="modalData.documentData"
            [isPublicPosting]="officeDeskSpecifics.isPublicPosting"
            [consigneeForOfficeDesk]="selectedConsigneesWithDestination.length ? selectedConsigneesWithDestination[0].consignee : null"
            (dispatchOfficeDistributionNodeIdChanged)="officeDeskSpecifics.dispatchOfficeDistributionNodeId = $event"
            [deliveryTypeId]="officeDeskSpecifics.deliveryType!.id"
            [specificFieldsForm]="$any(specificFieldsForm)"
            [selectedDistributionClass]="forDispatch.selectedDistributionClass"
          ></icz-office-desk-consignment-specification>
        }
      </div>
    }
  </ng-template>

  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.COMPONENTS_SELECTION"
    [label]="componentSelectionTabHeading"
    submitLabel="Uložit připravované"
    [customSubmitHandler]="saveConsignment"
    >
    @if (selectedComponents) {
      <icz-consignment-component-selection
        class="block h-full"
        [specificFieldsForm]="specificFieldsForm"
        [documentId]="modalData.documentData.id"
        [disabled]="!componentSelectionEnabled"
        [canUserWriteContent]="canUserWriteContent"
        [canUserDisplayComponentsContent]="canUserReadComponentContent"
        [preselectedComponents]="selectedComponents"
        [consignmentId]="modalData.consignment ? modalData.consignment.id : null"
        [dispatchOfficeDistributionNodeId]="dispatchOfficeDistributionNodeId"
        [distributionClassForDispatch]="forDispatch.distributionClassForDispatch"
        [deliveryFilingType]="deliveryFilingType"
        [consignmentValidationResult]="consignmentValidationResult"
        (componentsSelected)="componentsSelected($event)"
      ></icz-consignment-component-selection>
    }
  </ng-template>

  <ng-template
    [iczWizardStep]="ConsignmentWizardStep.CONSIGNMENT_VALIDATION"
    [label]="consignmentValidationTabHeading"
    submitLabel="Uložit připravované"
    [customSubmitHandler]="saveConsignment"
    >
    <icz-consignment-validation
      [consignmentValidationResult]="consignmentValidationResult"
    ></icz-consignment-validation>
  </ng-template>

  <ng-container extraLeftButtons>
    @if (isCreateAnyConsignmentDialogType || isEditConsignmentDialogType) {
      @if (isCreateAnyConsignmentDialogType && !officeDeskSpecifics.isPublicPosting && currentWizardStep === ConsignmentWizardStep.CONSIGNEE_SELECTION) {
        <icz-button
          label="Vyhledat v rejstříku"
          (onAction)="searchSubjectsInternalOnly()"
        >
        </icz-button>
        <icz-button
          label="Ztotožnit"
          (onAction)="identifySubject()"
          [disabled]="isUserNotPermittedForIszrOperations"
        >
        </icz-button>
      }
      @if (isEditConsignmentDialogType) {
        <icz-button
          label="Uložit změny"
          [disabled]="!canUserWriteProfile || !canUserReadProfile || disableEdit"
          primary
          (onAction)="saveConsignment()"
          >
        </icz-button>
      }
      @if (isSelectAttachmentsVisible) {
        <icz-button
          label="Vybrat přílohy"
          [disabled]="!canUserReadProfile"
          (onAction)="selectComponents()"
        ></icz-button>
      }
      @if (isHandOutsideFilingOfficeVisible) {
        <div class="row">
          @if (deliveryFilingType === ConsignmentDeliveryFillingType.DELIVERY_NO_FILLING_OFFICE && !disableRecordDeliveryResult) {
            <icz-button
              iczFormSubmit
              [disabled]="(!consignmentValidationResult?.valid) || (consignmentValidationResult?.loading)"
              [label]="isEditConsignmentDialogType ? 'Zapsat doručení' : 'Uložit a zapsat doručení'"
              (onAction)="dispatchNoFilingConsignment()"
            ></icz-button>
          }
          @if (isCreateAnyConsignmentDialogType) {
            @if (isEmailOutsideFilingOffice) {
              <icz-button
                iczFormSubmit
                label="Uložit a potvrdit vypravení"
                [disabled]="(!consignmentValidationResult?.valid) || (consignmentValidationResult?.loading)"
                (onAction)="saveAndDoWorkflowAction(OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE)"
              ></icz-button>
            } @else {
              <icz-button
                iczFormSubmit
                label="Uložit a předat výpravně"
                [disabled]="(!consignmentValidationResult?.valid) || (consignmentValidationResult?.loading)"
                (onAction)="saveAndDoWorkflowAction(OwnConsignmentWorkflowAction.HANDOVER)"
              ></icz-button>
            }
          }
          <!--        Handover alone is defined in Consignment Dialog Buttons, but this button combines saving of the new consignment and handover action in 1 click. -->
        </div>
      }
    }
    @if (isEditConsignmentDialogType && !disableCirculation) {
      <icz-consignment-dialog-buttons
        class="mr-8"
        [consignment]="modalData.consignment!"
        [consignmentUpdateReq$]="saveReqAsUpdate()"
        [consignmentValidationResult]="consignmentValidationResult"
        (actionStarted)="loadingService.startLoading(this)"
        (result)="handleWorkflowAction($event)"
      ></icz-consignment-dialog-buttons>
    }
    @if ((isCreateAnyConsignmentDialogType || isEditConsignmentDialogType) && isComponentSelectionInfoVisible) {
      <div class="icz-body-1 ml-16 pt-8">
        {{ 'Vybráno příloh' | translate }}: {{ selectedComponents.length }}. {{ 'Celková velikost' | translate }}: {{ selectedComponentsSize | fileSize }}.
      </div>
    }
  </ng-container>
</icz-wizard>

<ng-template #writeAuthorizationAlert>
  @if (!canUserWriteProfile) {
    <div>
      <icz-alert
        heading="Nemáte dostatečné oprávnění pro editaci informací o zásílce"
        class="m-16" severity="info"
        >
      </icz-alert>
    </div>
  }
</ng-template>
