/* tslint:disable */
/* eslint-disable */

/**
 * Property transaction event type
 */
export enum ConfigTransactionEventType {
  CREATION = 'CREATION',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  ORGANIZATION_ACTIVATION = 'ORGANIZATION_ACTIVATION',
  ORGANIZATION_CREATION = 'ORGANIZATION_CREATION',
  ORGANIZATION_DEACTIVATION = 'ORGANIZATION_DEACTIVATION',
  ORGANIZATION_DELETE = 'ORGANIZATION_DELETE'
}
