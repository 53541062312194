<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="col grow h-full">
    <icz-alert heading="fe.ui.textHelp.emailConsignmentCreate" severity="info"></icz-alert>
    <icz-form-autocomplete class="mt-16"  label="Distribuční uzel pro předání" formControlName="distributionNodeId" [options]="distributionNodesOptions" [clearable]="false"></icz-form-autocomplete>
    @if (selectedMaximumEmailSize) {
      <div class="icz-body-1">
        <span>
          {{ maxSizeInfoText | translate | interpolate:({maximumSize: selectedMaximumEmailSize}) }}
        </span>
      </div>
    }
    <icz-validation-errors-list [array]="messageFilesControl" [wrapInAlert]="true" [compactAlert]="true"></icz-validation-errors-list>
    <icz-file-upload-list
      class="grow"
      [form]="emailMessageFilesForm"
      [showFileSize]="true"
      [isMultiUpload]="true"
      [allowedFileExtensions]="['.eml', '.msg']"
      [showFileDragAndDrop]="true"
      [showFileUploadButton]="true"
      (filesChanged)="filesChanged()"
      fileFormArrayName="messageFiles"
      >
      <ng-container additionalContent>

      </ng-container>
    </icz-file-upload-list>
  </div>
</form>
<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this) || !isAllUploaded()"
    (onAction)="submit()"
    primary label="Předat e-mailové zprávy"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
