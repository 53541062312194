<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="isOpen"
  [cdkConnectedOverlayHasBackdrop]="withBackdrop"
  [cdkConnectedOverlayPositions]="allowedPositions"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  (attach)="connectedOverlayAttach()"
  (detach)="handleClose()"
  (backdropClick)="handleClose()"
>
  <div
    [class.icz-popover-content-window]="showContentWindow"
    [attr.data-popover-id]="popoverId"
    [class.invisible]="!isInnerContentOpen"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
