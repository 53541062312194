import {DestroyRef, inject, Injectable} from '@angular/core';
import {ActivationEnd, ActivationStart, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {distinctUntilKeyChanged, filter, first, map, withLatestFrom} from 'rxjs/operators';
import * as uuid from 'uuid';
import {ObjectClass} from '|api/commons';
import {ApplicationRoute} from '../enums/shared-routes.enum';
import {areHistoryBitsSame, HistoryBit, VisitedObject} from './history.model';
import {UserSettingsService} from './user-settings.service';
import {castStream, filterByClass} from '../lib/rxjs';
import {AuthService} from '../core/authentication/auth.service';
import {removeDuplicates, stripQueryStringFromUrl} from '../lib/utils';
import {IczPageTitleService} from '../core/routing/icz-page-title.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  FILTER_PARAM_NAME,
  FULLTEXT_SEARCH_TERM_PARAM_NAME,
  SORT_TERM_PARAM_NAME
} from './search-params-serialization.service';

interface HistoryRerouteState {
  isRerouteFromHistory?: boolean;
}

interface HistoryBitAliasMetadata {
  url: string;
  aliasLabel: string;
  visitedObject?: VisitedObject;
  aliasParts: string[];
}

const HISTORY_SIZE_LIMIT = 50;

export function getHistoryBitWithTitle(translateService: TranslateService, title: string, dynamicPart: string) {
  return `${translateService.instant(title)} ${dynamicPart}`;
}


@Injectable({providedIn: 'root'})
export class HistoryService {

  private router = inject(Router);
  private authService = inject(AuthService);
  private pageTitleService = inject(IczPageTitleService);
  private userSettingsService = inject(UserSettingsService);
  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);

  readonly currentSessionId = uuid.v4();

  private routerEventListenersInitialized = false;

  private _historyBits$ = new BehaviorSubject<HistoryBit[]>([]);

  private _activationStart$ = this.router.events.pipe(
    filterByClass(ActivationStart),
  );

  private _navigationStart$ = this.router.events.pipe(
    filterByClass(NavigationStart),
  );

  // Observable store pattern public members
  historyBits$ = this._historyBits$.asObservable();

  get historyBits(): HistoryBit[] {
    return this._historyBits$.value;
  }

  latestBit$: Observable<Nullable<HistoryBit>> = this.historyBits$.pipe(
    map(list => list?.length ? list[list.length - 1] : null)
  );

  constructor() {
    this.authService.login$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.loadSavedHistory();
      this.initializeRouterEventListeners();
    });
  }

  private initializeRouterEventListeners() {
    if (!this.routerEventListenersInitialized) {
      const savedHistory = this._historyBits$.value;

      // for initial application open
      this.router.events.pipe(
        filterByClass(ActivationEnd),
        filter(initSnapshot => !initSnapshot.snapshot.data.skipHistoryBit),
        filter(initSnapshot => Boolean(initSnapshot.snapshot.title)),
        first()
      ).subscribe(initSnapshot => {
        const lastHistoryBit = savedHistory.length ? savedHistory[savedHistory.length - 1] : null;
        const basicRouteData = initSnapshot.snapshot.data;
        const fallbackBreadcrumbUri = basicRouteData.fallbackBreadcrumbUri;

        const currentHistoryBit: HistoryBit = {
          sessionId: this.currentSessionId,
          label: initSnapshot.snapshot.title ?? '',
          date: new Date(),
          url: this.applyFallbackUri((initSnapshot.snapshot as any)._routerState.url, fallbackBreadcrumbUri),
          completeUrl: window.location.href,
          canBeFavourite: Boolean(basicRouteData.canBeFavourite),
          isRootLevelRoute: Boolean(basicRouteData.isRootLevelRoute),
          isRerouteFromHistory: false,
          isAwaitingAlias: basicRouteData.usesAlias,
          visitedObject: null,
        };

        // page refresh under most circumstances
        if (areHistoryBitsSame(lastHistoryBit, currentHistoryBit)) {
          // The history bit will not get duplicated but will get marked as if visited in current session.
          lastHistoryBit!.sessionId = this.currentSessionId;
          this.setCurrentHistoryBits([...savedHistory]);
        } else {
          this.setCurrentHistoryBits([
            ...savedHistory,
            currentHistoryBit,
          ]);
        }
      });

      // for non-initial page transitions
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        withLatestFrom(this._activationStart$, this._navigationStart$),
        castStream<[NavigationEnd, ActivationStart, NavigationStart]>(),
        filter(([_navEnd, activationStart, _]: [NavigationEnd, ActivationStart, NavigationStart]) => !activationStart.snapshot.data.skipHistoryBit),
        map<[NavigationEnd, ActivationStart, NavigationStart], HistoryBit>(([navEnd, activationStart, navigationStart]: [NavigationEnd, ActivationStart, NavigationStart]) => {
          const basicRouteData = activationStart.snapshot.data;
          const fallbackBreadcrumbUri = basicRouteData.fallbackBreadcrumbUri;
          const routingState: Nullable<HistoryRerouteState> = this.router.getCurrentNavigation()?.extras?.state;
          const usedBackOrForthButton = navigationStart.navigationTrigger === 'popstate';

          return {
            sessionId: this.currentSessionId,
            label: activationStart.snapshot.title,
            url: this.applyFallbackUri(navEnd.urlAfterRedirects, fallbackBreadcrumbUri),
            date: new Date(),
            completeUrl: window.location.href,
            canBeFavourite: Boolean(basicRouteData.canBeFavourite),
            isRootLevelRoute: Boolean(basicRouteData.isRootLevelRoute),
            isRerouteFromHistory: Boolean(routingState?.isRerouteFromHistory || usedBackOrForthButton),
            isAwaitingAlias: basicRouteData.usesAlias,
          } as HistoryBit;
        }),
        distinctUntilKeyChanged('url'), // ignore navigation to same URL multiple times
        filter(newHistoryBit => Boolean(newHistoryBit.label)),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((newHistoryBit: HistoryBit) => {
        const historyBits = this._historyBits$.value;
        const lastHistoryBit = historyBits[historyBits.length - 1];

        if (areHistoryBitsSame(newHistoryBit, lastHistoryBit)) {
          // No need to mark lastHistoryBit with currentSessionId because on
          // non-initial navigations, last history bit is always from the current session.
          return;
        }

        historyBits.push(newHistoryBit);

        if (historyBits.length > HISTORY_SIZE_LIMIT) {
          historyBits.shift();
        }

        this.setCurrentHistoryBits(historyBits);
      });

      this.routerEventListenersInitialized = true;
    }
  }

  /**
   * Will route back in the current session using the history trace recorded to historyBits$.
   * Will use fallbackRoute in case there is no history in the current session.
   */
  routeBack(fallbackRoute: string = ApplicationRoute.ROOT) {
    const currentSessionHistory = this._historyBits$.value.filter(hb => hb.sessionId === this.currentSessionId);

    if (currentSessionHistory.length >= 2) { // 2 = current page and recent page to go back to
      const lastVisitedItem = currentSessionHistory[currentSessionHistory.length - 2];
      this.router.navigateByUrl(lastVisitedItem.url);
    }
    else {
      this.router.navigateByUrl(fallbackRoute);
    }
  }

  routeToHistoryBit(historyBit: HistoryBit) {
    this.router.navigateByUrl(
      historyBit.url,
      {
        state: {
          isRerouteFromHistory: true,
        }
      }
    );
  }

  /** Add alias to url historyBit and update historyBits */
  setHistoryBitAlias(aliasLabel: string, url: string, visitedObject?: VisitedObject, aliasParts?: string[]) {
    // all aliases must be set asynchronously - this timeout guarantees that even synchronous calls will do so
    setTimeout(() => {
      this.setCurrentHistoryBits(this._historyBits$.value, {aliasLabel, url, visitedObject, aliasParts: aliasParts ?? []});
    }, 0);
  }

  patchHistoryBitWithCustomParams(paramKey: string, paramValue: Nullable<string>) {
    setTimeout(() => {
      if (this._historyBits$.value.length) {
        const currentBit = this._historyBits$.value[this._historyBits$.value.length - 1];
        let updatedPath = '';
        if (currentBit.url.indexOf('?') > -1) {
          const currentUrl = currentBit.url.split('?')[0];
          const currentQueryParams = currentBit.url.split('?')[1];
          const currentQueryParamsList = currentQueryParams.split('&');
          const customParamIndex = currentQueryParamsList.findIndex(p => p.split('=')[0] === paramKey);
          if (customParamIndex > -1) {
            if (currentQueryParamsList.length > 1) {
              if(isNil(paramValue)) {
                // delete param from existing params
                currentQueryParamsList.splice(customParamIndex, 1);
              } else {
                // update param value in existing params
                currentQueryParamsList[customParamIndex] = `${paramKey}=${paramValue}`;
              }
              updatedPath = `${currentUrl}?${currentQueryParamsList.join('&')}`;
            } else {
              if(isNil(paramValue)) {
                // delete param there is only one
                updatedPath = `${currentUrl}`;
              } else {
                // update param value there is only one
                updatedPath = `${currentUrl}?${paramKey}=${paramValue}`;
              }
            }
          } else {
            if(isNil(paramValue)) {
              // param has no value and is not in params => do not change url
              updatedPath = `${currentBit.url}`;
            } else {
              // append to existing params
              updatedPath = `${currentUrl}?${currentQueryParams}&${paramKey}=${paramValue}`;
            }
          }
        } else {
          if(isNil(paramValue)) {
            // param has no value and is not in params => do not change url
            updatedPath = `${currentBit.url}`;
          } else {
            // add new param
            updatedPath = `${currentBit.url}?${paramKey}=${paramValue}`;
          }
        }

        currentBit.url = updatedPath;

        this.setCurrentHistoryBits(this._historyBits$.value);

        window.history.replaceState(
          null,
          '',
          updatedPath
        );
      }
    }, 0);
  }

  patchHistoryBitWithSearchParams(stringQueryParams: string) {
    setTimeout(() => {
      if (this._historyBits$.value.length) {
        const currentBit = this._historyBits$.value[this._historyBits$.value.length - 1];
        let updatedPath: string;
        if (currentBit.url.indexOf('?') > -1) {
          const currentUrl = currentBit.url.split('?')[0];
          const currentQueryParams = currentBit.url.split('?')[1];
          if (currentQueryParams) {
            const currentQueryParamsList = currentQueryParams
              .split('&')
              .map(paramKeyAndValue => paramKeyAndValue.split('='));
            const nonSearchParams: string[] = currentQueryParamsList
              .filter(paramKeyAndValueParts => (
                paramKeyAndValueParts[0] !== FILTER_PARAM_NAME &&
                paramKeyAndValueParts[0] !== SORT_TERM_PARAM_NAME &&
                paramKeyAndValueParts[0] !== FULLTEXT_SEARCH_TERM_PARAM_NAME
              ))
              .map(paramKeyAndValueParts => paramKeyAndValueParts.join('='));

            updatedPath = `${currentUrl}`;
            if (nonSearchParams.length === 0) {
              updatedPath += stringQueryParams ? `?${stringQueryParams}` : '';
            } else {
              updatedPath += `?${nonSearchParams.join('&')}${stringQueryParams ? '&' + stringQueryParams : ''}`;
            }
          } else {
            updatedPath = currentUrl;
          }
        } else {
          if (stringQueryParams) {
            updatedPath = `${currentBit.url}?${stringQueryParams}`;
          } else {
            updatedPath = `${currentBit.url}`;
          }
        }
        currentBit.url = updatedPath;

        let currentBitLabel = currentBit.label;
        let currentBitLabelParts: string[] = currentBit.labelParts ?? [];

        const stringSearchParamsList = stringQueryParams
          .split('&')
          .map(paramKeyAndValue => paramKeyAndValue.split('='));
        if (stringQueryParams && (stringSearchParamsList.length > 1 || !(stringSearchParamsList.length === 1 && stringSearchParamsList[0][0] === SORT_TERM_PARAM_NAME)) ) {
          if (currentBit.label.indexOf(' - ') === -1) {
            currentBitLabel = `${currentBit.label} - ${this.translateService.instant('filtr')}`;
            currentBitLabelParts = (currentBit.labelParts && currentBit.labelParts.length > 0) ? currentBit.labelParts.concat(['-' , 'filtr']) : [currentBit.label, '-' , 'filtr'];
          }
        } else {
          currentBitLabel = currentBit.label.split(' - ')[0];
          if (currentBit.labelParts) {
            const delimiterIndex = currentBit.labelParts.findIndex(part => part === '-');
            if (delimiterIndex > -1) {
              currentBit.labelParts.splice(delimiterIndex, 2);
              currentBitLabelParts = currentBit.labelParts;
            } else {
              currentBitLabelParts = [currentBitLabel];
            }
          } else {
            currentBitLabelParts = [currentBitLabel];
          }
        }

        this.setCurrentHistoryBits(
          this._historyBits$.value,
          {
            url: updatedPath,
            aliasLabel: currentBitLabel,
            aliasParts: currentBitLabelParts
          }
        );

        window.history.replaceState(
          null,
          '',
          updatedPath
        );
      }
    }, 0);
  }

  getRecentlyVisitedObjects(objectClasses: ObjectClass[]): VisitedObject[] {
    const foundObjects = this._historyBits$.value
                .filter(hb => objectClasses.includes(hb?.visitedObject?.objectClass!))
                .map(hb => hb.visitedObject!);

    return removeDuplicates(foundObjects, obj => obj.objectId);
  }

  private loadSavedHistory() {
    const savedHistory = this.userSettingsService.getSavedHistory();

    this._historyBits$.next(savedHistory);
    return savedHistory;
  }

  private setCurrentHistoryBits(historyBits: HistoryBit[], alias?: HistoryBitAliasMetadata) {
    if (alias) {
      historyBits = this.applyAliasToViewHistoryBits(historyBits, alias);

      if (alias.aliasLabel) {
        this.pageTitleService.setTitle(alias.aliasLabel);
      }
    }

    this.userSettingsService.persistSavedHistory(historyBits);

    this._historyBits$.next(historyBits);
    return historyBits;
  }

  private applyAliasToViewHistoryBits(historyBits: HistoryBit[], alias: HistoryBitAliasMetadata): HistoryBit[] {
    return historyBits.map(historyBit => {
      if (this.isAliasApplicableToHistoryBit(historyBit, alias)) {
        const enrichedHistoryBit = {
          ...historyBit,
          label: alias.aliasLabel,
          labelParts: alias.aliasParts,
          isAwaitingAlias: false,
        };

        if (alias.visitedObject) {
          enrichedHistoryBit.visitedObject = alias.visitedObject;
        }

        return enrichedHistoryBit;
      }
      else {
        return historyBit;
      }
    });
  }

  private isAliasApplicableToHistoryBit(historyBit: HistoryBit, alias: HistoryBitAliasMetadata) {
    return stripQueryStringFromUrl(historyBit.url) === stripQueryStringFromUrl(alias.url);
  }

  private applyFallbackUri(uri: string, fallbackBreadcrumbUri: string) {
    const uriSegments = uri.split('/');

    // The first item of uriSegments is always a stray empty string
    // thus we need to check for at least two elements
    // (second and later uriSegment is always non-empty).
    if (uriSegments.length > 1 && fallbackBreadcrumbUri) {
      uriSegments[uriSegments.length - 1] = fallbackBreadcrumbUri;
    }

    return uriSegments.join('/');
  }

}
