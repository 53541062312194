/* tslint:disable */
/* eslint-disable */

/**
 * Sheet label template section type
 */
export enum SheetLabelTemplateSectionType {
  DEGREE_BEFORE = 'DEGREE_BEFORE',
  DEGREE_AFTER = 'DEGREE_AFTER',
  COUNTRY = 'COUNTRY',
  BIRTH_YEAR = 'BIRTH_YEAR',
  POSTAL_CODE = 'POSTAL_CODE',
  SURNAME = 'SURNAME',
  PO_BOX = 'PO_BOX',
  CITY = 'CITY',
  STREET = 'STREET',
  PRAGUE_DISTRICT = 'PRAGUE_DISTRICT',
  COUNTRY_CODE = 'COUNTRY_CODE',
  HOLDER_ORG_UNIT_CODE = 'HOLDER_ORG_UNIT_CODE',
  FIRST_NAME = 'FIRST_NAME',
  BUSINESS_NAME = 'BUSINESS_NAME',
  POSTAL_OFFICE = 'POSTAL_OFFICE',
  ADDITIONAL_NAME = 'ADDITIONAL_NAME',
  BIRTH_DATE = 'BIRTH_DATE',
  HOUSE_NUMBER = 'HOUSE_NUMBER',
  ORIENTATION_NUMBER = 'ORIENTATION_NUMBER',
  DISTRICT = 'DISTRICT',
  SHORTCUT = 'SHORTCUT',
  FREE_TEXT = 'FREE_TEXT',
  USER_TEXT = 'USER_TEXT',
  HANDLING_TYPE = 'HANDLING_TYPE',
  MARK = 'MARK',
  FILE_REF_NUMBER = 'FILE_REF_NUMBER',
  PAID_BY_BANK_TRANSFER = 'PAID_BY_BANK_TRANSFER',
  DOCUMENT_REF_NUMBER = 'DOCUMENT_REF_NUMBER',
  AGENDA_NUMBER = 'AGENDA_NUMBER',
  PRIORITY_DELIVERY_MODE = 'PRIORITY_DELIVERY_MODE',
  POSTAL_SERVICE_NAMES = 'POSTAL_SERVICE_NAMES',
  POSTAL_SERVICE_CODES = 'POSTAL_SERVICE_CODES',
  CONSIGNEE = 'CONSIGNEE'
}
