/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DataBoxType } from '|api/commons';
import { sadDataboxGetDataBoxType } from '../fn/sad-databox/sad-databox-get-data-box-type';
import { SadDataboxGetDataBoxType$Params } from '../fn/sad-databox/sad-databox-get-data-box-type';


/**
 * Contains endpoints related to Czech Data Boxes.
 */
@Injectable({ providedIn: 'root' })
export class ApiSadDataboxService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sadDataboxGetDataBoxType()` */
  static readonly SadDataboxGetDataBoxTypePath = '/sad/databox/{id}/type';

  /**
   * Data Box Type.
   *
   * Returns type of the data box with a given identifier. The type information is retrieved via the FindDataBox2 operation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sadDataboxGetDataBoxType()` instead.
   *
   * This method doesn't expect any request body.
   */
  sadDataboxGetDataBoxType$Response(params: SadDataboxGetDataBoxType$Params, context?: HttpContext): Observable<StrictHttpResponse<DataBoxType>> {
    return sadDataboxGetDataBoxType(this.http, this.rootUrl, params, context);
  }

  /**
   * Data Box Type.
   *
   * Returns type of the data box with a given identifier. The type information is retrieved via the FindDataBox2 operation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sadDataboxGetDataBoxType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sadDataboxGetDataBoxType(params: SadDataboxGetDataBoxType$Params, context?: HttpContext): Observable<DataBoxType> {
    return this.sadDataboxGetDataBoxType$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataBoxType>): DataBoxType => r.body)
    );
  }

}
