/* tslint:disable */
/* eslint-disable */

/**
 * Entity type
 */
export enum EntityType {
  DOCUMENT = 'DOCUMENT',
  RECEIVED_DOCUMENT = 'RECEIVED_DOCUMENT',
  OWN_DOCUMENT = 'OWN_DOCUMENT',
  CIRCULATION_TASK = 'CIRCULATION_TASK',
  CIRCULATION_ACTIVITY = 'CIRCULATION_ACTIVITY',
  COMPONENT = 'COMPONENT',
  DIGITAL_COMPONENT = 'DIGITAL_COMPONENT',
  ANALOG_COMPONENT = 'ANALOG_COMPONENT',
  PAPER_COMPONENT = 'PAPER_COMPONENT',
  PHYSICAL_ITEM_COMPONENT = 'PHYSICAL_ITEM_COMPONENT',
  MEDIUM_COMPONENT = 'MEDIUM_COMPONENT',
  DIGITAL_COMPONENT_VERSION = 'DIGITAL_COMPONENT_VERSION',
  CONSIGNMENT = 'CONSIGNMENT',
  OWN_PAPER_CONSIGNMENT_CONFIGURATION = 'OWN_PAPER_CONSIGNMENT_CONFIGURATION',
  OWN_DIGITAL_CONSIGNMENT_VALIDATION_CONFIGURATION = 'OWN_DIGITAL_CONSIGNMENT_VALIDATION_CONFIGURATION',
  OWN_DATABOX_CONSIGNMENT_CONFIGURATION = 'OWN_DATABOX_CONSIGNMENT_CONFIGURATION',
  OWN_EMAIL_CONSIGNMENT_CONFIGURATION = 'OWN_EMAIL_CONSIGNMENT_CONFIGURATION',
  OWN_OFFICE_DESK_CONSIGNMENT_CONFIGURATION = 'OWN_OFFICE_DESK_CONSIGNMENT_CONFIGURATION',
  FILE = 'FILE',
  APPLICATION = 'APPLICATION',
  USER = 'USER',
  AUTHORITY = 'AUTHORITY',
  PROPERTY_DEFINITION = 'PROPERTY_DEFINITION',
  PROPERTY_VALUE = 'PROPERTY_VALUE',
  CLASSIFICATION_SCHEME = 'CLASSIFICATION_SCHEME',
  EXTERNAL_RETENTION_TRIGGER = 'EXTERNAL_RETENTION_TRIGGER',
  RETENTION_TRIGGER_TYPE = 'RETENTION_TRIGGER_TYPE',
  CROSS_REFERENCE = 'CROSS_REFERENCE',
  DISPOSAL_SCHEDULE = 'DISPOSAL_SCHEDULE',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  KEYWORD = 'KEYWORD',
  FILE_TYPE = 'FILE_TYPE',
  NUMERICAL_SERIES = 'NUMERICAL_SERIES',
  ENTITY_CLASS = 'ENTITY_CLASS',
  ENTITY_CLASS_NUMBERING_CONFIGURATION = 'ENTITY_CLASS_NUMBERING_CONFIGURATION',
  ENTITY_CLASS_NUMBERING_CONFIGURATION_LEVEL = 'ENTITY_CLASS_NUMBERING_CONFIGURATION_LEVEL',
  SUBJECT = 'SUBJECT',
  SUBJECT_IDENTIFIER = 'SUBJECT_IDENTIFIER',
  SUBJECT_RECORD = 'SUBJECT_RECORD',
  SUBJECT_OBJECT_RELATION = 'SUBJECT_OBJECT_RELATION',
  SUBJECT_RECORD_RELATED_OBJECT = 'SUBJECT_RECORD_RELATED_OBJECT',
  SUBJECT_ATTRIBUTE = 'SUBJECT_ATTRIBUTE',
  SUBJECT_DATA_ATTRIBUTE = 'SUBJECT_DATA_ATTRIBUTE',
  DICTIONARY = 'DICTIONARY',
  SECURITY_CATEGORY = 'SECURITY_CATEGORY',
  DELIVERY_RESULT = 'DELIVERY_RESULT',
  DELIVERY_TYPE = 'DELIVERY_TYPE',
  DELIVERY_TYPE_DEFINITION = 'DELIVERY_TYPE_DEFINITION',
  DELIVERY_SERVICE_BASIC = 'DELIVERY_SERVICE_BASIC',
  DELIVERY_SERVICE_GROUP = 'DELIVERY_SERVICE_GROUP',
  DELIVERY_SERVICE_BASIC_DEFINITION = 'DELIVERY_SERVICE_BASIC_DEFINITION',
  DELIVERY_SERVICE_ADDITIONAL = 'DELIVERY_SERVICE_ADDITIONAL',
  DELIVERY_SERVICE_ADDITIONAL_DEFINITION = 'DELIVERY_SERVICE_ADDITIONAL_DEFINITION',
  DELIVERY_SERVICE_COMBINATION = 'DELIVERY_SERVICE_COMBINATION',
  DISTRIBUTOR = 'DISTRIBUTOR',
  ADDRESS = 'ADDRESS',
  INCIDENT_LOG = 'INCIDENT_LOG',
  TRANSACTION_LOG_RENDITION = 'TRANSACTION_LOG_RENDITION',
  TRANSACTION_EVENT = 'TRANSACTION_EVENT',
  TRANSACTION_EVENT_ATTRIBUTE = 'TRANSACTION_EVENT_ATTRIBUTE',
  SCHEDULE = 'SCHEDULE',
  JOB_DETAILS = 'JOB_DETAILS',
  TRIGGER = 'TRIGGER',
  TRIGGER_HISTORY = 'TRIGGER_HISTORY',
  SEARCH_RESULT = 'SEARCH_RESULT',
  SEARCH_RECORD = 'SEARCH_RECORD',
  CREATE_INDEX = 'CREATE_INDEX',
  FUNCTIONAL_POSITION = 'FUNCTIONAL_POSITION',
  ORGANIZATIONAL_STRUCTURE_IMPORT_METADATA = 'ORGANIZATIONAL_STRUCTURE_IMPORT_METADATA',
  IMPORT_METADATA_AUDIT = 'IMPORT_METADATA_AUDIT',
  APPLICATION_ROLE = 'APPLICATION_ROLE',
  OAUTH_CLIENT_DETAILS = 'OAUTH_CLIENT_DETAILS',
  ORGANIZATIONAL_UNIT = 'ORGANIZATIONAL_UNIT',
  VOID = 'VOID',
  NOTIFICATION = 'NOTIFICATION',
  SIGNATURE_DATA = 'SIGNATURE_DATA',
  SIGNATURE_VALIDATION_ITEM = 'SIGNATURE_VALIDATION_ITEM',
  ID_GENERATOR = 'ID_GENERATOR',
  UID_GENERATOR = 'UID_GENERATOR',
  SPECIALIZED_UID_GENERATOR = 'SPECIALIZED_UID_GENERATOR',
  ID_IDENTIFIER = 'ID_IDENTIFIER',
  UID_IDENTIFIER = 'UID_IDENTIFIER',
  PRIOR_ORDER_NUMBER_GENERATOR = 'PRIOR_ORDER_NUMBER_GENERATOR',
  COLLECTION_FILE_REF_NUMBER_GENERATOR = 'COLLECTION_FILE_REF_NUMBER_GENERATOR',
  FILE_REF_NUMBER_IDENTIFIER = 'FILE_REF_NUMBER_IDENTIFIER',
  FILE_REF_NUMBER_GENERATOR = 'FILE_REF_NUMBER_GENERATOR',
  SPECIALIZED_FILE_REF_NUMBER_GENERATOR = 'SPECIALIZED_FILE_REF_NUMBER_GENERATOR',
  FILING_REGISTER = 'FILING_REGISTER',
  SEPARATE_FILING_REGISTER = 'SEPARATE_FILING_REGISTER',
  FILING_REGISTER_RENDITION = 'FILING_REGISTER_RENDITION',
  EPDZ_CONFIGURATION = 'EPDZ_CONFIGURATION',
  EXTERNAL_NOTIFICATION_APPENDIX_CONFIGURATION = 'EXTERNAL_NOTIFICATION_APPENDIX_CONFIGURATION',
  BILINGUAL_TEMPLATE = 'BILINGUAL_TEMPLATE',
  EXTERNAL_NOTIFICATION_CONFIGURATION = 'EXTERNAL_NOTIFICATION_CONFIGURATION',
  EXTERNAL_NOTIFICATION_REJECTION_REASON_CONFIGURATION = 'EXTERNAL_NOTIFICATION_REJECTION_REASON_CONFIGURATION',
  BULK_POSTING_FORM = 'BULK_POSTING_FORM',
  OFFICE_DESK = 'OFFICE_DESK',
  TELE_FAX = 'TELE_FAX',
  BULK_POSTING_FORM_TEMPLATE = 'BULK_POSTING_FORM_TEMPLATE',
  ENVELOPE_TEMPLATE = 'ENVELOPE_TEMPLATE',
  SHEET_LABEL_TEMPLATE = 'SHEET_LABEL_TEMPLATE',
  DIGITAL_COMPONENT_LABEL_TEMPLATE = 'DIGITAL_COMPONENT_LABEL_TEMPLATE',
  PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
  DELIVERY_EVENT = 'DELIVERY_EVENT',
  BASIC_SETTINGS = 'BASIC_SETTINGS',
  ORGANIZATION = 'ORGANIZATION',
  DISTRIBUTION_NODE = 'DISTRIBUTION_NODE',
  DATABOX_DISTRIBUTION_NODE = 'DATABOX_DISTRIBUTION_NODE',
  DIGITAL_DISTRIBUTION_NODE = 'DIGITAL_DISTRIBUTION_NODE',
  EMAIL_DISTRIBUTION_NODE = 'EMAIL_DISTRIBUTION_NODE',
  ELECTRONIC_DISTRIBUTION_NODE = 'ELECTRONIC_DISTRIBUTION_NODE',
  DATABOX_NODE = 'DATABOX_NODE',
  EMAIL_NODE = 'EMAIL_NODE',
  EMAIL_UPLOAD_NODE = 'EMAIL_UPLOAD_NODE',
  EPDZ_LINKED_NODE = 'EPDZ_LINKED_NODE',
  SHEET_NODE = 'SHEET_NODE',
  ADDRESS_PLACE_DISTRIBUTION_NODE = 'ADDRESS_PLACE_DISTRIBUTION_NODE',
  ORGANIZATION_DISTRIBUTION_NODE = 'ORGANIZATION_DISTRIBUTION_NODE',
  PHYSICAL_DISTRIBUTION_NODE = 'PHYSICAL_DISTRIBUTION_NODE',
  STORAGE_UNIT = 'STORAGE_UNIT',
  REGISTRY_OFFICE_TRANSFER = 'REGISTRY_OFFICE_TRANSFER',
  REGISTRY_OFFICE_TRANSFER_INCIDENT = 'REGISTRY_OFFICE_TRANSFER_INCIDENT',
  REGISTRY_OFFICE_TRANSFER_PROBLEM = 'REGISTRY_OFFICE_TRANSFER_PROBLEM',
  SHARED_FOLDER = 'SHARED_FOLDER',
  EMAIL_NOTIFICATION_RULE = 'EMAIL_NOTIFICATION_RULE',
  EMAIL_NOTIFICATION_OPERATION = 'EMAIL_NOTIFICATION_OPERATION',
  EMAIL_NOTIFICATION_DEADLINE = 'EMAIL_NOTIFICATION_DEADLINE',
  EMAIL_NOTIFICATION_CIRCULATION = 'EMAIL_NOTIFICATION_CIRCULATION',
  EMAIL_NOTIFICATION_CONSIGNMENT = 'EMAIL_NOTIFICATION_CONSIGNMENT',
  EMAIL_NOTIFICATION_USER = 'EMAIL_NOTIFICATION_USER',
  EMAIL_NOTIFICATION_FOR_USER = 'EMAIL_NOTIFICATION_FOR_USER',
  PORTAL_NODE = 'PORTAL_NODE'
}
