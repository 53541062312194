<div class="canvas col grow items-center justify-center w-full h-full">
  @switch (viewerType) {
    @case (ViewerType.IFRAME) {
      @if (url) {
        <iframe class="iframe-viewer" [src]="url"></iframe>
      }
    }
    @case (ViewerType.IMAGE) {
      @if (url) {
        <div class="image-viewer grow">
          <img [src]="url">
        </div>
      }
    }
    @case (ViewerType.VIDEO) {
      @if (url) {
        <video width="320" height="240" controls>
          <source [src]="url" [type]="mimeType">
        </video>
      }
    }
    @case (ViewerType.AUDIO) {
      @if (url) {
        <audio width="320" height="240" controls>
          <source [src]="url" [type]="mimeType">
        </audio>
      }
    }
    @case (ViewerType.HTML) {
      @if (html) {
        <icz-html-outlet class="w-full h-full p-16 overflow-y-auto" [html]="html"></icz-html-outlet>
      }
    }
    @case (ViewerType.TXT) {
      @if (html) {
        <pre class="text-viewer w-full h-full">{{ html }}</pre>
      }
    }
    @case (ViewerType.RTF) {
      @if (fileContents) {
        <icz-rtf-outlet class="w-full h-full p-16 overflow-y-auto" [rtfContents]="fileContents!.buffer"></icz-rtf-outlet>
      }
    }
    @default {
      <div class="col gap-64 justify-center items-center text-center no-viewer">
        <p>{{ 'Nepodařilo se nám vytvořit náhled.' | translate }}</p>
        <icz-button primary (onAction)="downloadRequested.emit()" label="Stáhnout a zobrazit náhled"></icz-button>
      </div>
    }
  }
</div>
