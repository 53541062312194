import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {EpdzAttachmentViewerComponent,} from './epdz-attachment-viewer.component';
import {ReceivedEpdzAttachmentDto, ReceivedEpdzMessageDto} from '|api/sad';
import {IczModalService} from '../../../services/icz-modal.service';

export interface EpdzAttachmentViewerParams {
  message: ReceivedEpdzMessageDto;
  attachments: ReceivedEpdzAttachmentDto[];
  initialAttachmentIndex?: Nullable<number>;
}

@Injectable({
  providedIn: 'root'
})
export class EpdzAttachmentViewerService {

  private modalService = inject(IczModalService);

  openAttachmentViewer(params: EpdzAttachmentViewerParams): Observable<void> {
    return this.modalService.openComponentInModal<void, EpdzAttachmentViewerParams>({
      component: EpdzAttachmentViewerComponent,
      modalOptions: {
        width: 1100,
        height: '90vh',
        useCustomHeader: true,
        disableAutoMargin: true,
      },
      data: params,
    });
  }

}
