import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';
import {
  getToastHeaderTemplateTranslateKey,
  MessageType,
  ToastService
} from '../../../components/notifications/toast.service';


export const SEpdzErrorOperationStartedTemplateData = z.object({
  [InternalNotificationKey.COUNT]: z.number(),
  [InternalNotificationKey.ACTION_TYPE]: z.string(),
});

export type EpdzErrorOperationStartedTemplateData = z.infer<typeof SEpdzErrorOperationStartedTemplateData>;

export const SEpdzErrorTemplateData = z.object({
  [InternalNotificationKey.ACTION_TYPE]: z.string(),
  [InternalNotificationKey.SUBJECT]: z.string(),
});

export type EpdzErrorTemplateData = z.infer<typeof SEpdzErrorTemplateData>;

export const SEpdzErrorErrorTemplateData = z.object({
  [InternalNotificationKey.ACTION_TYPE]: z.string(),
  [InternalNotificationKey.SUBJECT]: z.string(),
  [InternalNotificationKey.ERROR_DESCRIPTION]: z.string(),
});

export type EpdzErrorErrorTemplateData = z.infer<typeof SEpdzErrorErrorTemplateData>;

export const SEpdzGeneralErrorErrorTemplateData = z.object({
  [InternalNotificationKey.EPDZ_ERROR_MESSAGE]: z.string(),
  [InternalNotificationKey.EPDZ_FILE_NAME]: z.string(),
});

export type EpdzGeneralErrorErrorTemplateData = z.infer<typeof SEpdzGeneralErrorErrorTemplateData>;


export enum EpdzErrorToastType {
  EPDZ_ERROR_PROCESSING_STARTED = 'EPDZ_ERROR_PROCESSING_STARTED',
  EPDZ_ERROR_PROCESSING_SUCCESS = 'EPDZ_ERROR_PROCESSING_SUCCESS',
  EPDZ_ERROR_PROCESSING_ERROR = 'EPDZ_ERROR_PROCESSING_ERROR',
  EPDZ_MESSAGE_PROCESS_ERROR = 'EPDZ_MESSAGE_PROCESS_ERROR',
  EPDZ_ERROR_CONTAINER_CORRUPT = 'EPDZ_ERROR_CONTAINER_CORRUPT',
  EPDZ_ERROR_NO_CONTAINER = 'EPDZ_ERROR_NO_CONTAINER',
  EPDZ_ERROR_GENERAL = 'EPDZ_ERROR_GENERAL',
}

@Injectable({
  providedIn: 'root',
})
export class EpdzErrorToastService {

  private toastService = inject(ToastService);

  dispatchProcessingStarted(toastData: EpdzErrorOperationStartedTemplateData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(EpdzErrorToastType.EPDZ_ERROR_PROCESSING_STARTED),
          templateData: toastData,
          templateDataSchema: SEpdzErrorOperationStartedTemplateData,
        }
      }
    });
  }

}
