<div class="text-center w-full">
  <icz-icon [svgIcon]="getIconName()" class="xlarge mb-32"></icz-icon>
  @if (errorData$ | async; as errorData) {
    @if (errorData.length > 1) {
      <p class="font-bold">{{ 'Došlo k několika chybám' | translate }}</p>
    }
    @for (errorItem of errorData; track errorItem) {
      <p class="icz-body-1 mt-0">
        {{errorItem.text | translate | interpolate:errorItem.context}}
      </p>
      @for (error of interpolateErrorMessages(errorItem); track error) {
        <p class="icz-body-1">
          {{error}}
        </p>
      }
    }
  }
</div>

<icz-form-buttons>
  <icz-button leftButtons (onAction)="modalRef.close(true)" primary [label]="data.leftButtonTitle ?? 'Potvrdit'"></icz-button>
  @if (data.showRightButton) {
    <icz-button rightButtons (onAction)="modalRef.close(false)" [label]="data.rightButtonTitle ?? 'Storno'"></icz-button>
  }
</icz-form-buttons>
