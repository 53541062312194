<icz-tabs [tabs]="tabs">

  <!--  CONFIGURATION TAB -->
  <ng-template iczTab="configuration">
    <div [formGroup]="form" class="row grow">
      <icz-section label="Umístit na přehled pečeť" class="col grow mx-32 my-24">
        <div class="gap-8 col">
          <icz-radio-group>
            <icz-radio-button [value]="AttachSeal.NO_SEAL" label="Vygenerovat přehled bez pečetě" formControlName="attachSeal"></icz-radio-button>
            <icz-radio-button [value]="AttachSeal.AUTOMATIC" label="Automatické umístění pečetě" formControlName="attachSeal"></icz-radio-button>
          </icz-radio-group>
        </div>
        @if (isAttachingSeal) {
          <div class="my-32">
            <icz-form-field class="grow" label="Důvod" formControlName="reason"></icz-form-field>
            <icz-form-field class="grow" label="Místo" formControlName="place"></icz-form-field>
          </div>
        }
      </icz-section>

      <icz-section label="Kam chcete přehled uložit" class="col grow mx-32 my-24">
        <div class="gap-8 col">
          <icz-radio-group>
            <icz-radio-button [value]="LocationForSave.HARD_DRIVE" label="Uložit na disk do počítače" formControlName="locationForSave"></icz-radio-button>
            <icz-radio-button [value]="LocationForSave.ATTACH_TO_DOCUMENT" label="Připojit přehled k existujícímu dokumentu" formControlName="locationForSave"></icz-radio-button>
            <icz-radio-button [value]="LocationForSave.CREATE_NEW_DOCUMENT" label="Založit nový dokument, do kterého se přehled vloží" formControlName="locationForSave"></icz-radio-button>
          </icz-radio-group>
        </div>

      </icz-section>
    </div>

  </ng-template>

  <ng-template iczTab="preview">
    preview...
  </ng-template>

</icz-tabs>
