type Collator = Map<string, string>;

// cz+sk collation table
export const CS_COLLATOR = new Map<string, string>([
  // [ cz/sk character -> its preceding character (cz/sk or pure ascii) ]
  ['á', 'a'],
  ['ä', 'á'],
  ['č', 'c'],
  ['ď', 'd'],
  ['dz', 'ď'],
  ['dž', 'dz'],
  ['é', 'e'],
  ['ě', 'é'],
  ['í', 'i'],
  ['ch', 'h'],
  ['ĺ', 'l'],
  ['ľ', 'ĺ'],
  ['ň', 'n'],
  ['ó', 'o'],
  ['ö', 'ó'],
  ['ô', 'ö'],
  ['ŕ', 'r'],
  ['ř', 'ŕ'],
  ['š', 's'],
  ['ť', 't'],
  ['ú', 'u'],
  ['ů', 'ú'],
  ['ý', 'y'],
  ['ž', 'z'],
]);

interface PrecedingCharacterDescriptor {
  character: string;
  distanceFromOriginal: number;
}

function isValidDigraph(s: string, collator: Collator): boolean {
  return s.length === 2 && collator.has(s);
}

export function getFirstPrecedingAsciiCharacter(character: string, collator: Collator): PrecedingCharacterDescriptor {
  let preceding = character;
  let steps = 0;

  while (collator.has(preceding)) {
    preceding = collator.get(preceding)!;
    ++steps;
  }

  return {
    character: preceding,
    distanceFromOriginal: steps,
  };
}

// implements character comparison "a > b"
export function collateCharacters(a: string, b: string, collator: Collator): boolean {
  if (a === b) return false;

  if (collator.has(a) || collator.has(b)) {
    const precedingCharacterA = getFirstPrecedingAsciiCharacter(a, collator);
    const precedingCharacterB = getFirstPrecedingAsciiCharacter(b, collator);

    const precedingAsciiA = precedingCharacterA.character;
    const distanceA = precedingCharacterA.distanceFromOriginal;
    const precedingAsciiB = precedingCharacterB.character;
    const distanceB = precedingCharacterB.distanceFromOriginal;

    if (precedingAsciiA === precedingAsciiB) {
      return distanceA > distanceB;
    }
    else {
      // neither precedingAsciiA nor precedingAsciiB could be
      // digraphs in this branch so >(String, String) will work fine
      return precedingAsciiA > precedingAsciiB;
    }
  }
  else {
    return a > b;
  }
}

// implements string comparison "a > b"
export function collateStrings(a: string, b: string, collator: Collator = CS_COLLATOR): boolean {
  if (a.length === 0 || b.length === 0) {
    return false;
  }
  else if (a.length === 1 || b.length === 1) {
    return collateCharacters(a[0], b[0], collator);
  }
  else {
    const commonLength = Math.min(a.length, b.length);

    {
      let i;
      let j;

      // i iterates over a, j iterates over b
      for (i = j = 0; i < commonLength && j < commonLength; ) {
        const diA = a.substr(i, 2);
        const diB = b.substr(j, 2);
        // if it's a digraph, increment i/j by two. else by one
        const aGraph = isValidDigraph(diA, collator) ? (i += 2, diA) : a[i++];
        const bGraph = isValidDigraph(diB, collator) ? (j += 2, diB) : b[j++];

        if (aGraph !== bGraph) {
          return collateCharacters(aGraph, bGraph, collator);
        }
      }
    }

    return false;
  }
}
