<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-org-structure-selection-loader
    label="Zvolit skupinu pro předání"
    [parentForm]="form"
    [constrainToFunctionalPositions]="true"
    (groupSelected)="applySavedGroup($event)"
  ></icz-org-structure-selection-loader>
  <icz-section label="Komu dát k podpisu">
    <!-- todo(rb) implement leader functional position preselection -->
    <icz-org-structure-selector
      [form]="form"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      [disableOrganizationStructureType]="OrganizationStructureType.ORGANIZATION_UNIT"
      controlName="orgStructureElementIds"
      [isMultiselect]="true"
      label="Funkční místa"
    ></icz-org-structure-selector>
    <icz-org-structure-selection-saver
      [parentForm]="form"
      [group]="groupForSaving"
      [isGroupOrdered]="true"
    ></icz-org-structure-selection-saver>
  </icz-section>
  <div class="row">
    <div class="col grow">
      <icz-section label="Instrukce">
        <icz-form-field formControlName="targetTaskInstruction"
          label="Instrukce"
          [autoSizeMax]="3"
        ></icz-form-field>
      </icz-section>
      @if (selectedParticipants?.length) {
        <icz-section label="Pořadí předání">
          <icz-form-item-sorter formControlName="taskParticipantsOrder" class="grow" [options]="selectedParticipants"></icz-form-item-sorter>
        </icz-section>
      }
    </div>
    <div class="col grow">
      <icz-section label="Termín">
        <icz-date-picker formControlName="deadline"
          label="Termín, dokdy potřebujete mít podepsáno"
          [selectableDates]="deadlineValidator"
        ></icz-date-picker>
      </icz-section>
      @if (!isDigitalComponent) {
        <icz-section label="Podepsat také listinnou podobu">
          <icz-form-field type="integer" formControlName="numberOfAnalogComponentCopiesToSign"
            label="Počet listin k podpisu"
          ></icz-form-field>
        </icz-section>
      }
      <!-- todo(mh) ESSL-8643 this is commented out as it is not yet analyzed for single or bulk actions -->
      <!--<icz-section *ngIf="componentDocument?.documentForm === DocumentForm.ANALOG" testingFeature label="Tisk">
      <icz-checkbox formControlName="printHandoverProtocol"
        label="Tisknout předávací protokol"
      ></icz-checkbox>
    </icz-section>-->
  </div>
</div>
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="handForSigning()" primary label="Dát k podpisu"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
