import {Component, inject, OnInit} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {CheckUnsavedFormDialogService} from '../../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../../form-elements/validators/icz-validators/icz-validators';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';

@Component({
  selector: 'icz-add-subject-email-dialog',
  templateUrl: './add-subject-email-dialog.component.html',
  styleUrls: ['./add-subject-email-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class AddSubjectEmailDialogComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<string>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected subjectRecordDetail = injectModalData<SubjectRecordDto>();

  form = new IczFormGroup({
    email: new IczFormControl<Nullable<string>>(null, [IczValidators.required(), IczValidators.email()]),
  });

  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  submit() {
    this.modalRef.close(this.form.value.email);
  }

  cancel() {
    this.modalRef.close(null);
  }

}
