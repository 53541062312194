import {Directive, Input} from '@angular/core';
import {IczValidators, ValidatorResult} from './icz-validators';
import {AbstractControl, NG_VALIDATORS, Validator} from '@angular/forms';

@Directive({
  selector: '[iczRequiredField]', // kept with low specificity for prototyping reasons
  providers: [
    {provide: NG_VALIDATORS, useExisting: RequiredFieldDirective, multi: true},
  ],
})
export class RequiredFieldDirective implements Validator {
  @Input()
  iczRequiredField!: boolean|'';

  validate(control: AbstractControl): ValidatorResult {
    // typing iczRequiredField is equivalent to [iczRequiredField]="true"
    return (this.iczRequiredField || this.iczRequiredField === '') ?
      IczValidators.required()(control) :
      IczValidators._dummyValidator();
  }
}
