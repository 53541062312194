import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {isNil} from 'lodash';
import {DistributionClass, SubjectRecordDto} from '|api/commons';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {anonymousSenderSet} from '../model/subjects.model';
import {ApiAuthorizationService, AuthorizedEntityType, ReceivedConsignmentAuthorizedOperation} from '|api/core';
import {ReceivedDigitalConsignmentDto, ReceivedPaperConsignmentDto, ReceivedTelefaxConsignmentDto} from '|api/sad';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AddressCompleteDto} from '../model/addresses.model';
import {addressDtoToForm, createAddressFormGroup} from '../subjects/address/address-utils';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {SubjectAttributeType} from '../model/subject-attribute-type';


@Component({
  selector: 'icz-receive-consignment-display-sender',
  templateUrl: './receive-consignment-display-sender.component.html',
  styleUrls: ['./receive-consignment-display-sender.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiveConsignmentDisplaySenderComponent implements IczOnChanges {

  private apiAuthorizationService = inject(ApiAuthorizationService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected destroyRef = inject(DestroyRef);

  @Input({required: true}) consignmentDistributionClass!: DistributionClass;
  @Input({required: true}) form!: IczFormGroup;
  @Input({required: true}) isTelefaxConsignment!: boolean;
  @Input({required: true}) isDocumentDeactivated!: boolean;
  @Input({required: true}) senderSubjectId!: number;
  @Input() senderOU: Nullable<string>;
  @Input() receivedPaperConsignment: Nullable<ReceivedPaperConsignmentDto>;
  @Input() receivedDigitalConsignment: Nullable<ReceivedDigitalConsignmentDto>;
  @Input() receivedTelefaxConsignment: Nullable<ReceivedTelefaxConsignmentDto>;
  @Output() senderRemoved = new EventEmitter<void>();

  @ViewChild('noSubjectFoundMessage', {static: false})
  notFoundMessage!: ElementRef;

  wasSenderRemoved = false;

  isSenderLoaded = false;

  senderSubject: Nullable<SubjectRecordDto>;

  senderAddressForm = new IczFormGroup({
      senderAddressDto: createAddressFormGroup(),
    },
  );

  get isSenderKnown() {
    return !isNil(this.senderSubject) && Object.keys(this.senderSubject).length > 0;
  }

  get senderAddress() {
    return this.senderAddressForm.get('senderAddressDto') as IczFormGroup;
  }

  get personalDelivery() {
    return this.form?.get('personalDelivery')!.value;
  }

  get isPostDelivery() {
    return (this.consignmentDistributionClass === DistributionClass.ADDRESS_PLACE || this.consignmentDistributionClass === DistributionClass.PHYSICAL_BOX);
  }

  get isDataBoxDelivery() {
    return this.consignmentDistributionClass === DistributionClass.ISDS_BOX;
  }

  get isInternalDelivery() {
    return this.consignmentDistributionClass === DistributionClass.INTERNAL;
  }

  get isTelefaxDelivery() {
    return this.consignmentDistributionClass === DistributionClass.PHONE_CONTACT;
  }

  get isPortalDelivery() {
    return this.consignmentDistributionClass === DistributionClass.PORTAL_BOX;
  }

  get isSubjectEditAllowed() {
    return !(this.isDocumentDeactivated || this.isDataBoxDelivery || this.isInternalDelivery || this.isTelefaxDelivery || this.isPortalDelivery || !this.canModifyContent);
  }

  subjectRemoved() {
    this.wasSenderRemoved = true;
    this.senderRemoved.emit();
  }

  readonly SubjectAttributeType = SubjectAttributeType;

  searched = false;

  canModifyContent = false;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.receivedPaperConsignment?.currentValue) {
      if (this.receivedPaperConsignment?.id) {
        this.resolveAuthorizedOperations(this.receivedPaperConsignment.id);
        const address = addressDtoToForm(this.receivedPaperConsignment.senderAddress as AddressCompleteDto);
        this.senderAddress.patchValue(address!);
      }
    }

    if (!isNil(this.senderSubjectId)) {
      this.apiSubjectRecordNgElasticService.subjectRecordElasticGet({
        subjectId: this.senderSubjectId,
      }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( senderSubject => {
        if (senderSubject) {
          this.form?.get('senderDefinition')!.setValue(senderSubject.id);
          this.senderSubject = senderSubject;
          this.isSenderLoaded = true;
          this.wasSenderRemoved = false;
          this.changeDetectorRef.detectChanges();
        }
      });
    }
    else {
      this.isSenderLoaded = true;
      this.wasSenderRemoved = false;
      if (this.form) anonymousSenderSet(true, this.form);
      this.senderSubject = null;
      this.changeDetectorRef.detectChanges();
    }
  }

  private resolveAuthorizedOperations(consignmentId: number) {
    this.apiAuthorizationService.authorizationAuthorizeReceivedConsignmentOperations({
      body: {
        operationsToAuthorize: [ReceivedConsignmentAuthorizedOperation.RECEIVED_CONSIGNMENT_SHOW_PROFILE, ReceivedConsignmentAuthorizedOperation.RECEIVED_CONSIGNMENT_MODIFY_PROFILE, ReceivedConsignmentAuthorizedOperation.RECEIVED_CONSIGNMENT_SHOW_CONTENT],
        authorizedEntityId: consignmentId,
        authorizedEntityType: AuthorizedEntityType.RECEIVED_CONSIGNMENT,
      },
    }).subscribe(result => {
      if (result) {
        this.canModifyContent = result.authorizedOperations.find(op => op.operation === ReceivedConsignmentAuthorizedOperation.RECEIVED_CONSIGNMENT_MODIFY_PROFILE)!.userAuthorized;
        if (!this.canModifyContent) {
          this.form.disable();
        }
      }
    });
  }

}
