<div class="col grow h-full w-full" iczForm>
  @if (form) {
    <icz-receive-or-register-received-consignment
      (blockingAction)="blockingAction = $event"
      [distributionNodeOptions]="[]"
      [form]="form"
      [receivedDocumentUsage]="receivedDocumentUsage"
      [unopenedConsignment]="unopenedConsignment" [waiting]="loadingIndicatorService.isLoading(this)"
      class="icz-workspace"
      iczAutoFocus
    ></icz-receive-or-register-received-consignment>
  }
  <icz-submit-bar
    (cancel)="onCancel()"
    (submit)="onSubmit()"
    [iczTooltip]="blockingAction ? 'Probíhá ověřování datové schránky.' : ''"
    [submitDisabled]="submitDisabled"
  ></icz-submit-bar>
</div>
