import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isEqual} from 'lodash';
import {merge, Observable, of} from 'rxjs';
import {debounceTime, startWith, switchMap} from 'rxjs/operators';
import {DocumentDto, FileDto} from '|api/document';
import {
  SheetLabelPrintRequestDto,
  SheetLabelTemplateDto,
  SheetLabelTemplatePreviewRequestDto,
  SheetLabelTemplateSectionType,
} from '|api/sad';
import {SubjectRecordClassification, SubjectRecordDto} from '|api/commons';
import {AbstractTemplatePrintDialog, AbstractTemplatePrintDialogData} from '../abstract-template-print-dialog';
import {WizardComponent} from '../../dialogs/wizard/wizard.component';
import {createAddressFormGroup} from '../subjects/address/address-utils';
import {
  filterEmptyCustomTextValues,
  initializeCustomFieldsForm,
  initializeCustomFieldsFormByValuesMap
} from '../envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {Option} from '../../../model';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {formatAddressForDto} from '../../../services/subjects.service';
import {fileToBase64} from '../../../lib/utils';
import {PAPER_FORMAT_SIZES} from '../model/envelopes-and-sheets.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {injectModalData} from '../../../lib/modals';
import {SubjectAttributeType} from '../model/subject-attribute-type';

export interface SheetLabelPrintDialogData extends AbstractTemplatePrintDialogData {
  object?: DocumentDto | FileDto;
  draftSheetLabelTemplate?: SheetLabelTemplateDto;
}

type SheetLabelTemplateOption = Option<number, SheetLabelTemplateDto>;

enum SheetLabelPrintDialogLoader {
  PREVIEW = 'PREVIEW',
  OTHER = 'OTHER',
}


@Component({
  selector: 'icz-sheet-label-print-dialog',
  templateUrl: './sheet-label-print-dialog.component.html',
  styleUrls: ['./sheet-label-print-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SheetLabelPrintDialogComponent extends AbstractTemplatePrintDialog<SheetLabelTemplatePreviewRequestDto | SheetLabelPrintRequestDto> implements OnInit {

  protected override data = injectModalData<SheetLabelPrintDialogData>();
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);

  @ViewChild('wizard')
  wizard!: WizardComponent;

  form = new IczFormGroup({
    sheetLabelTemplateId: new IczFormControl<Nullable<number>>(null),

    consigneeSubjectClassification: new IczFormControl<Nullable<SubjectRecordClassification>>(SubjectRecordClassification.FO, [IczValidators.required()]),
    salutation: new IczFormControl<Nullable<string>>(null),
    consigneeFirstName: new IczFormControl<Nullable<string>>(null),
    consigneeSurname: new IczFormControl<Nullable<string>>(null),
    consigneeDegreeBeforeName: new IczFormControl<Nullable<string>>(null),
    consigneeDegreeAfterName: new IczFormControl<Nullable<string>>(null),
    consigneeBirthDate: new IczFormControl<Nullable<string>>(null),
    consigneeSubjectName: new IczFormControl<Nullable<string>>(null),
    consigneeAdditionalName: new IczFormControl<Nullable<string>>(null),
    contactPerson: new IczFormControl<Nullable<string>>(null),
    addressType: new IczFormControl<Nullable<SubjectAttributeType>>(SubjectAttributeType.MAILING_ADDRESS, [IczValidators.required()]),
    address: createAddressFormGroup(undefined, 3),
    customText: new IczFormGroup({}),

    firstPrintPositionRow: new IczFormControl<Nullable<number>>(1, [IczValidators.required(), IczValidators.isInteger(), IczValidators.min(1)]),
    firstPrintPositionColumn: new IczFormControl<Nullable<number>>(1, [IczValidators.required(), IczValidators.isInteger(), IczValidators.min(1)]),
    xPrintOffsetMm: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(1)]),
    yPrintOffsetMm: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(1)]),
  });

  sheetLabelTemplateOptions: SheetLabelTemplateOption[] = [];

  sheetPaperFormat: Nullable<string>;

  get selectedSheetLabelTemplate(): Nullable<SheetLabelTemplateDto> {
    if (this.isTestingPrintMode) {
      return this.data.draftSheetLabelTemplate;
    }
    else {
      const sheetLabelTemplateId = Number(this.form.value.sheetLabelTemplateId);
      return this.sheetLabelTemplateOptions.find(lt => lt.value === sheetLabelTemplateId)?.data;
    }
  }

  get isConsigneeBusiness() {
    const consigneeClassification = this.form.get('consigneeSubjectClassification')!.value as unknown as SubjectRecordClassification;
    return (
      SubjectTemplateUtils.isLegalPerson(consigneeClassification) ||
      SubjectTemplateUtils.isBusinessIndividual(consigneeClassification)
    );
  }

  printSubmitHandler = () => this.submit();

  masterCustomTextForm = new IczFormGroup({});
  customTextForms = new IczFormArray(() => new IczFormGroup({}), []);

  readonly SheetLabelPrintDialogLoader = SheetLabelPrintDialogLoader;

  ngOnInit() {
    this.isTestingPrintMode = isNil(this.data.consignments);

    if (!this.isTestingPrintMode) {
      this.loadingService.doLoading(
        this.codebookService.sheetLabelTemplates(),
        this,
        SheetLabelPrintDialogLoader.OTHER
      ).subscribe(sheetLabelTemplates => {
        this.sheetLabelTemplateOptions = sheetLabelTemplates.map(slt => ({
          value: slt.id,
          label: slt.name,
          data: slt,
        }));
      });

      this.form.get('sheetLabelTemplateId')!.setValidators(IczValidators.required());
      this.form.get('sheetLabelTemplateId')!.valueChanges.pipe(
        startWith(null),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        // this timeout is needed because the value of this.selectedEnvelopeTemplate
        //  is consistent with form model after one change detection tick
        setTimeout(() => {
          this.determineSheetPaperFormat();
          this.setUpCustomFieldDefs();
        }, 0);
      });
    }
    else {
      this.patchFormWithTestData();
      this.setUpCustomFieldDefs();
      this.determineSheetPaperFormat();
    }

    this.form.recursivelyUpdateValueAndValidity();

    if (!this.isBulkPrinting) {
      merge(
        this.form.valueChanges,
        this.masterCustomTextForm.valueChanges,
      ).pipe(
        startWith(null),
        debounceTime(1000),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        if (this.form.valid) {
          this.requestVisualPreview(0);
        }
      });
    }
  }

  submit() {
    let request$: Observable<ArrayBuffer>;

    if (this.isTestingPrintMode) {
      request$ = this.remoteBinaryFileDownloadService.fetchSheetLabelTestPrintPreview(
        'PDF',
        this.getPreviewRequestDto(false) as SheetLabelTemplatePreviewRequestDto
      );
    }
    else {
      request$ = this.remoteBinaryFileDownloadService.fetchSheetLabelPrintPreview(
        'PDF',
        this.getPreviewRequestDto(false) as SheetLabelPrintRequestDto
      );
    }

    this.loadingService.doLoading(
      request$.pipe(
        switchMap(pdfBuffer => {
          const file = new File([pdfBuffer], `${this.selectedSheetLabelTemplate!.name}.pdf`);
          this.localBinaryFileDownloadService.downloadFile(file);
          return of(null);
        }),
      ),
      this,
      SheetLabelPrintDialogLoader.OTHER
    ).subscribe();
  }

  cancel() {
    this.modalRef.close(null);
  }

  wizardStepChanged() {
    const currentStepIndex = this.wizard.stepIndex;

    if (currentStepIndex > 0) {
      this.requestVisualPreview(currentStepIndex - 1);
    }
  }

  getPrintTaskTabHeading(printTaskIndex: number) {
    return `${this.translateService.instant('Etiketa')} ${printTaskIndex + 1}`;
  }

  protected getTestConsigneeSubjectDto(): SubjectRecordDto {
    if (this.isConsigneeBusiness) {
      return this.getBusinessEntityTestConsignee();
    }
    else {
      return this.getNaturalPersonTestConsignee();
    }
  }

  protected setUpCustomFieldDefs() {
    if (this.selectedSheetLabelTemplate) {
      const textSections = this.selectedSheetLabelTemplate.sections.filter(
        s => s.labelSectionType === SheetLabelTemplateSectionType.USER_TEXT
      );

      const customFieldDefs = textSections.map(s => ({
        key: s.sectionKey!,
        label: s.customText!,
      }));

      this.customFieldDefs = [];

      this.masterCustomFieldDefs = customFieldDefs;

      if (!this.isTestingPrintMode) {
        if (this.isBulkPrinting) {
          this.customTextForms.setSize(this.data.consignments!.length);
        }

        for (let i = 0; i < this.data.consignments!.length; ++i) {
          if (this.isBulkPrinting) {
            const taskCustomTextForm = this.customTextForms.get(String(i)) as IczFormGroup;

            initializeCustomFieldsForm(customFieldDefs, taskCustomTextForm);

            taskCustomTextForm.valueChanges.pipe(
              takeUntilDestroyed(this.destroyRef),
              debounceTime(1000),
            ).subscribe(_ => {
              this.requestVisualPreview(i);
            });
          }

          this.customFieldDefs.push(customFieldDefs);
        }

        if (this.isBulkPrinting) {
          this.masterCustomTextForm.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe(masterCustomTextValues => {
            for (let i = 0; i < this.data.consignments!.length; ++i) {
              const taskCustomTextForm = this.customTextForms.get(String(i)) as IczFormGroup;

              initializeCustomFieldsFormByValuesMap(masterCustomTextValues, taskCustomTextForm);
              taskCustomTextForm.patchValue(masterCustomTextValues, {emitEvent: false});
            }
          });
        }
      }
    }
  }

  protected getPreviewRequestDto(isForPreviewCanvas: boolean, printTaskIndex?: number): SheetLabelTemplatePreviewRequestDto | SheetLabelPrintRequestDto {
    const formValue = this.form.getRawValue();

    if (this.isTestingPrintMode) {
      return {
        template: this.selectedSheetLabelTemplate!,
        testData: {
          salutation: this.isConsigneeBusiness ? null : formValue.salutation,
          userText: filterEmptyCustomTextValues(this.masterCustomTextForm.value),
          contactPerson: this.isConsigneeBusiness ? formValue.contactPerson : null,
          consigneeAdditionalName: this.isConsigneeBusiness ? formValue.consigneeAdditionalName : null,
          consignee: this.getTestConsigneeSubjectDto(),
          consigneeAddress: formatAddressForDto(this.form.get('address') as IczFormGroup),
        },
        firstPrintPositionRow: isForPreviewCanvas ? 1 : formValue.firstPrintPositionRow!,
        firstPrintPositionColumn: isForPreviewCanvas ? 1 : formValue.firstPrintPositionColumn!,
        printXOffsetMm: isForPreviewCanvas ? null : formValue.xPrintOffsetMm,
        printYOffsetMm: isForPreviewCanvas ? null : formValue.yPrintOffsetMm,
        printSingleLabel: isForPreviewCanvas,
      } as SheetLabelTemplatePreviewRequestDto;
    }
    else {
      if (isForPreviewCanvas && !isNil(printTaskIndex)) {
        return {
          sheetLabelTemplateId: this.selectedSheetLabelTemplate!.id,
          consignments: [
            {
              consigneeAdditionalName: this.isConsigneeBusiness ? formValue.consigneeAdditionalName : null,
              contactPerson: this.isConsigneeBusiness ? formValue.contactPerson : null,
              ownConsignmentId: this.data.consignments![printTaskIndex].id,
              salutation: this.isConsigneeBusiness ? null : formValue.salutation,
              userText: filterEmptyCustomTextValues(
                this.isBulkPrinting ?
                  this.customTextForms.value[printTaskIndex] :
                  this.masterCustomTextForm.value
              ),
            }
          ],
          firstPrintPositionRow: isForPreviewCanvas ? 1 : formValue.firstPrintPositionRow!,
          firstPrintPositionColumn: isForPreviewCanvas ? 1 : formValue.firstPrintPositionColumn!,
          printXOffsetMm: isForPreviewCanvas ? null : formValue.xPrintOffsetMm,
          printYOffsetMm: isForPreviewCanvas ? null : formValue.yPrintOffsetMm,
          printSingleLabel: isForPreviewCanvas,
        } as SheetLabelPrintRequestDto;
      }
      else {
        return {
          sheetLabelTemplateId: this.selectedSheetLabelTemplate!.id,
          consignments: this.data.consignments!.map((c, i) => ({
            consigneeAdditionalName: this.isConsigneeBusiness ? formValue.consigneeAdditionalName : null,
            contactPerson: this.isConsigneeBusiness ? formValue.contactPerson : null,
            ownConsignmentId: c.id,
            salutation: this.isConsigneeBusiness ? null : formValue.salutation,
            userText: filterEmptyCustomTextValues(
              this.isBulkPrinting ? this.customTextForms.value[i] : this.masterCustomTextForm.value
            ),
          })),
          firstPrintPositionRow: isForPreviewCanvas ? 1 : formValue.firstPrintPositionRow!,
          firstPrintPositionColumn: isForPreviewCanvas ? 1 : formValue.firstPrintPositionColumn!,
          printXOffsetMm: isForPreviewCanvas ? null : formValue.xPrintOffsetMm,
          printYOffsetMm: isForPreviewCanvas ? null : formValue.yPrintOffsetMm,
          printSingleLabel: isForPreviewCanvas,
        } as SheetLabelPrintRequestDto;
      }
    }
  }

  private determineSheetPaperFormat() {
    if (this.selectedSheetLabelTemplate) {
      const paperFormatSizeEntry = Object.entries(PAPER_FORMAT_SIZES).find(
        dimensionEntry => isEqual(
          dimensionEntry[1],
          [
            this.selectedSheetLabelTemplate!.labelSheetWidthMm,
            this.selectedSheetLabelTemplate!.labelSheetHeightMm
          ]
        )
      );

      if (paperFormatSizeEntry) {
        this.sheetPaperFormat = paperFormatSizeEntry[0];
      }
      else {
        this.sheetPaperFormat = null;
      }
    }
  }

  protected requestVisualPreview(printTaskIndex: number): void {
    this.previewDataUrls[printTaskIndex] = null;
    this.cd.detectChanges();

    let preview$: Observable<ArrayBuffer>;

    if (this.isTestingPrintMode) {
      preview$ = this.remoteBinaryFileDownloadService.fetchSheetLabelTestPrintPreview(
        'SVG',
        this.getPreviewRequestDto(true, printTaskIndex) as SheetLabelTemplatePreviewRequestDto
      );
    }
    else {
      preview$ = this.remoteBinaryFileDownloadService.fetchSheetLabelPrintPreview(
        'SVG',
        this.getPreviewRequestDto(true, printTaskIndex) as SheetLabelPrintRequestDto
      );
    }

    this.loadingService.doLoading(
      preview$.pipe(
        switchMap(pdfBuffer => {
          return fileToBase64(new File([pdfBuffer], 'unknown.svg', {type: 'image/svg+xml'}));
        }),
      ),
      this,
      SheetLabelPrintDialogLoader.PREVIEW
    ).subscribe(svgDataUrl => {
      this.previewDataUrls[printTaskIndex] = svgDataUrl;
      this.cd.detectChanges();
    });
  }

}
