/* tslint:disable */
/* eslint-disable */

/**
 * File handling type
 */
export enum FileHandlingType {
  PRIOR_ORDER = 'PRIOR_ORDER',
  COLLECTION_FILE = 'COLLECTION_FILE'
}
