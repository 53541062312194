/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { dispatchOfficeCreate } from '../fn/dispatch-office/dispatch-office-create';
import { DispatchOfficeCreate$Params } from '../fn/dispatch-office/dispatch-office-create';
import { dispatchOfficeDelete } from '../fn/dispatch-office/dispatch-office-delete';
import { DispatchOfficeDelete$Params } from '../fn/dispatch-office/dispatch-office-delete';
import { DispatchOfficeDto } from '../models/dispatch-office-dto';
import { dispatchOfficeFindAll } from '../fn/dispatch-office/dispatch-office-find-all';
import { DispatchOfficeFindAll$Params } from '../fn/dispatch-office/dispatch-office-find-all';
import { dispatchOfficeFindById } from '../fn/dispatch-office/dispatch-office-find-by-id';
import { DispatchOfficeFindById$Params } from '../fn/dispatch-office/dispatch-office-find-by-id';
import { dispatchOfficeSearch } from '../fn/dispatch-office/dispatch-office-search';
import { DispatchOfficeSearch$Params } from '../fn/dispatch-office/dispatch-office-search';
import { dispatchOfficeUpdate } from '../fn/dispatch-office/dispatch-office-update';
import { DispatchOfficeUpdate$Params } from '../fn/dispatch-office/dispatch-office-update';
import { PageDispatchOfficeDto } from '../models/page-dispatch-office-dto';


/**
 * Controller for dispatch office objects
 */
@Injectable({ providedIn: 'root' })
export class ApiDispatchOfficeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dispatchOfficeFindById()` */
  static readonly DispatchOfficeFindByIdPath = '/codebook/dispatch-office/{id}';

  /**
   * Find dispatch office object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dispatchOfficeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeFindById$Response(params: DispatchOfficeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DispatchOfficeDto>> {
    return dispatchOfficeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find dispatch office object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dispatchOfficeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeFindById(params: DispatchOfficeFindById$Params, context?: HttpContext): Observable<DispatchOfficeDto> {
    return this.dispatchOfficeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DispatchOfficeDto>): DispatchOfficeDto => r.body)
    );
  }

  /** Path part for operation `dispatchOfficeUpdate()` */
  static readonly DispatchOfficeUpdatePath = '/codebook/dispatch-office/{id}';

  /**
   * Update existing dispatch office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dispatchOfficeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dispatchOfficeUpdate$Response(params: DispatchOfficeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DispatchOfficeDto>> {
    return dispatchOfficeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing dispatch office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dispatchOfficeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dispatchOfficeUpdate(params: DispatchOfficeUpdate$Params, context?: HttpContext): Observable<DispatchOfficeDto> {
    return this.dispatchOfficeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DispatchOfficeDto>): DispatchOfficeDto => r.body)
    );
  }

  /** Path part for operation `dispatchOfficeDelete()` */
  static readonly DispatchOfficeDeletePath = '/codebook/dispatch-office/{id}';

  /**
   * Delete existing dispatch office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dispatchOfficeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeDelete$Response(params: DispatchOfficeDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return dispatchOfficeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing dispatch office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dispatchOfficeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeDelete(params: DispatchOfficeDelete$Params, context?: HttpContext): Observable<void> {
    return this.dispatchOfficeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `dispatchOfficeFindAll()` */
  static readonly DispatchOfficeFindAllPath = '/codebook/dispatch-office';

  /**
   * Finds all dispatch office objects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dispatchOfficeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeFindAll$Response(params?: DispatchOfficeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DispatchOfficeDto>>> {
    return dispatchOfficeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all dispatch office objects
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dispatchOfficeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeFindAll(params?: DispatchOfficeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DispatchOfficeDto>> {
    return this.dispatchOfficeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DispatchOfficeDto>>): Array<DispatchOfficeDto> => r.body)
    );
  }

  /** Path part for operation `dispatchOfficeCreate()` */
  static readonly DispatchOfficeCreatePath = '/codebook/dispatch-office';

  /**
   * Create new dispatch office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dispatchOfficeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dispatchOfficeCreate$Response(params: DispatchOfficeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DispatchOfficeDto>> {
    return dispatchOfficeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new dispatch office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dispatchOfficeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  dispatchOfficeCreate(params: DispatchOfficeCreate$Params, context?: HttpContext): Observable<DispatchOfficeDto> {
    return this.dispatchOfficeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DispatchOfficeDto>): DispatchOfficeDto => r.body)
    );
  }

  /** Path part for operation `dispatchOfficeSearch()` */
  static readonly DispatchOfficeSearchPath = '/codebook/dispatch-office/search-api';

  /**
   * Finds dispatch office objects based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dispatchOfficeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeSearch$Response(params?: DispatchOfficeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDispatchOfficeDto>> {
    return dispatchOfficeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds dispatch office objects based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dispatchOfficeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dispatchOfficeSearch(params?: DispatchOfficeSearch$Params|null|undefined, context?: HttpContext): Observable<PageDispatchOfficeDto> {
    return this.dispatchOfficeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDispatchOfficeDto>): PageDispatchOfficeDto => r.body)
    );
  }

}
