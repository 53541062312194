import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Option} from '../../../../model';
import {StatisticsDimension} from '|api/elastic';


@Component({
  selector: 'icz-dimensions-toolbar',
  templateUrl: './dimensions-toolbar.component.html',
  styleUrls: ['./dimensions-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimensionsToolbarComponent implements OnInit {
  @Input({required: true}) initialDimensions: Nullable<Array<StatisticsDimension>>;
  @Input({required: true}) allowedDimensions: Nullable<Array<StatisticsDimension>>;
  @Output() dimensionsChange = new EventEmitter<Array<StatisticsDimension>>();

  selectedDimensions: StatisticsDimension[] = [];
  allowedDimensionsOptions: Option<string, string>[] = [];

  showPopover = false;

  addDimension(dim: StatisticsDimension) {
    const index = this.selectedDimensions.findIndex(d => dim === d);
    if (index === -1) {
      this.selectedDimensions.push(dim);
      this.dimensionsChange.emit(this.selectedDimensions);
    }
    this.showPopover = false;
  }

  removeDimension(dim: StatisticsDimension) {
    const index = this.selectedDimensions.findIndex(d => dim === d);
    if (index !== -1) {
      this.selectedDimensions.splice(index, 1);
      this.dimensionsChange.emit(this.selectedDimensions);
    }
  }

  ngOnInit() {
    if (this.initialDimensions) {
      this.selectedDimensions = [...this.initialDimensions];
    }
    this.allowedDimensionsOptions = this.allowedDimensions ? this.allowedDimensions.map(dim => ({
      value: dim,
      label: `en.dimension.${dim}`,
      data: dim,
    })) : [];
  }

}
