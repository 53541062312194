/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ApplicationRoleDto } from '../models/application-role-dto';
import { applicationRoleFindAll } from '../fn/application-role/application-role-find-all';
import { ApplicationRoleFindAll$Params } from '../fn/application-role/application-role-find-all';
import { applicationRoleFindById } from '../fn/application-role/application-role-find-by-id';
import { ApplicationRoleFindById$Params } from '../fn/application-role/application-role-find-by-id';
import { applicationRoleSearch } from '../fn/application-role/application-role-search';
import { ApplicationRoleSearch$Params } from '../fn/application-role/application-role-search';
import { PageApplicationRoleDto } from '../models/page-application-role-dto';


/**
 * Controller with operations over application roles
 */
@Injectable({ providedIn: 'root' })
export class ApiApplicationRoleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `applicationRoleFindAll()` */
  static readonly ApplicationRoleFindAllPath = '/application-role';

  /**
   * Find all application roles instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationRoleFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationRoleFindAll$Response(params?: ApplicationRoleFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<ApplicationRoleDto>>> {
    return applicationRoleFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all application roles instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationRoleFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationRoleFindAll(params?: ApplicationRoleFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<ApplicationRoleDto>> {
    return this.applicationRoleFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationRoleDto>>): Array<ApplicationRoleDto> => r.body)
    );
  }

  /** Path part for operation `applicationRoleFindById()` */
  static readonly ApplicationRoleFindByIdPath = '/application-role/{id}';

  /**
   * Find application role by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationRoleFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationRoleFindById$Response(params: ApplicationRoleFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ApplicationRoleDto>> {
    return applicationRoleFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find application role by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationRoleFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationRoleFindById(params: ApplicationRoleFindById$Params, context?: HttpContext): Observable<ApplicationRoleDto> {
    return this.applicationRoleFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationRoleDto>): ApplicationRoleDto => r.body)
    );
  }

  /** Path part for operation `applicationRoleSearch()` */
  static readonly ApplicationRoleSearchPath = '/application-role/search-api';

  /**
   * Finds application roles based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `applicationRoleSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationRoleSearch$Response(params?: ApplicationRoleSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageApplicationRoleDto>> {
    return applicationRoleSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds application roles based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `applicationRoleSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  applicationRoleSearch(params?: ApplicationRoleSearch$Params|null|undefined, context?: HttpContext): Observable<PageApplicationRoleDto> {
    return this.applicationRoleSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageApplicationRoleDto>): PageApplicationRoleDto => r.body)
    );
  }

}
