/* tslint:disable */
/* eslint-disable */

/**
 * Settlement method
 */
export enum SettlementMethod {
  INDIVIDUAL = 'INDIVIDUAL',
  BY_FILE = 'BY_FILE'
}
