<div [formGroup]="form" [waiting]="loadingIndicatorService.isLoading$(this) | async">
  @if (formData?.showMetadata && consignment) {
    <div class="mb-32">
      <icz-section [boxed]="false" label="Údaje zásilky">
        <div class="row consignee">
          <span>{{'Komu se posílá' | translate}}:</span>  <span>{{consignment.consigneeDetail! | subjectName}}</span>
        </div>
        <div class="row">
          <icz-key-value-pair key="Kam se posílá" [value]="consignment.consigneeAddress ? (consignment.consigneeAddress | address | async) : 'Nedostupné'"></icz-key-value-pair>
        </div>
        <div class="row">
          <icz-key-value-pair key="Způsob doručení" [value]="consignment.deliveryTypeId | findInList: deliveryTypeOptions"></icz-key-value-pair>
        </div>
      </icz-section>
    </div>
  }

  @if (!isDefaultConsignment) {
    <div class="row grow">
      <icz-form-autocomplete class="grow" formControlName="distributorId" label="Distributor"
        [options]="distributorOptions$ | async">
      </icz-form-autocomplete>
    </div>
  }
  @if (isDistributorPost) {
    <icz-consignment-posting-number [form]="form" class="mb-16"></icz-consignment-posting-number>
  }

  <!--  todo(lp) PPA selection is greyed out in FS, but will be impl one day -->
  <!--  <div *ngIf="isDistributorPost" class="mb-16">-->
  <!--    <div class="row grow">-->
  <!--      <icz-form-field class="grow" formControlName="bulkPostingFormId" label="Poštovní podací arch" testingFeature></icz-form-field>-->
<!--    </div>-->
<!--    <div class="row grow">-->
<!--      <icz-button label="Založit Poštovní arch" class="grow" testingFeature></icz-button>-->
<!--    </div>-->
<!--  </div>-->
@if (isDistributorPost) {
  <div class="row grow">
    <icz-form-field class="grow" type="integer" formControlName="weight" label="Hmotnost">
      <span suffix>g</span>
    </icz-form-field>
    <icz-form-money-input
      class="grow"
      formControlName="payoutAmount"
      label="Výplatné"
    ></icz-form-money-input>
  </div>
}
</div>
