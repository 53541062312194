<div class="row gap-24 pb-4">
  @if (showFilingOfficerSection) {
    <div class="col grow">
      <icz-card class="mb-24">
        <div class="row grow px-24"><icz-heading label="Podatelna" [level]="'3'"></icz-heading></div>
        @if (showSheetNodesCounter) {
          <icz-dashboard-gauge-counter
            [linksTo]="receivedConsignmentsRoute" name="Přijaté dnes"
          [count]="receivedConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        }
        @if (showDataboxNodesCounter) {
          <icz-dashboard-gauge-counter
            [linksTo]="receivedDataboxConsignmentsRoute" name="Datová schránka"
          [count]="receivedDataboxConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        }
        @if (showEmailNodesCounter) {
          <icz-dashboard-gauge-counter
            [linksTo]="receivedEmailConsignmentsRoute" name="Elektronická podatelna"
          [count]="receivedEmailConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        }
        @if (showSheetNodesCounter) {
          <icz-dashboard-gauge-counter
            [linksTo]="receivedInternalConsignmentsRoute" name="Interní podání"
          [count]="receivedInternalConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        }
        @if (showSheetNodesCounter) {
          <icz-dashboard-gauge-counter
            [linksTo]="handedOverConsignmentsRoute" name="Předáno podatelnou"
          [count]="handedOverByFilingOfficeConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        }
      </icz-card>
      <icz-card>
        <div class="row grow px-24"><icz-heading label="Vyžadující pozornost" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter name="Vráceno do podatelny"
          [linksTo]="rejectedConsignmentsRoute"
        [count]="rejectedConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
      </icz-card>
    </div>
  }
  @if (showDispatchOfficeSection) {
    <div class="col grow">
      <icz-card class="mb-24">
        <div class="row grow px-24"><icz-heading label="Výpravna" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter [linksTo]="toTakeoverConsignmentsRoute" name="K převzetí"
        [count]="toTakeoverConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter [linksTo]="takenOverConsignmentsRoute" name="K vypravení"
        [count]="takenOverConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter [linksTo]="inDistributionConsignmentsRoute" name="V distribuci"
        [count]="inDistributionConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter [linksTo]="toPostConsignmentsRoute" name="Úřední deska - dnes vyvěsit"
        [count]="toBePostedConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
      </icz-card>
      <icz-card>
        <div class="row grow px-24"><icz-heading label="Povinný zápis doručenky" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter [linksTo]="forDeliveryConfirmationConsignmentsRoute" name="Zapsání doručenky"
        [count]="forDeliveryConfirmationConsignmentsCount$ | async"></icz-dashboard-gauge-counter>
      </icz-card>
    </div>
  }
  <div class="col grow">
    @if (showFilingOfficerSection) {
      <icz-card class="mb-24">
        <div class="row grow px-24"><icz-heading label="Aktivity" [level]="'3'"></icz-heading></div>
        <div class="col grow px-24 pb-16 gap-12">
          <icz-button
            (onAction)="goToPage(receivePaperConsignmentRoute)"
            [noCentered]="true"
            svgIcon="add_new"
            size="large"
            label="Přijmout zásilku"
          ></icz-button>
        </div>
      </icz-card>
    }
    <icz-dashboard-flow-activities-preview></icz-dashboard-flow-activities-preview>
  </div>
  @if (!showDispatchOfficeSection || !showFilingOfficerSection) {
    <div class="col grow"></div>
  }
</div>
