import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'icz-form-help-field',
  templateUrl: './form-help-field.component.html',
  styleUrls: ['./form-help-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHelpFieldComponent {

  @Input({required: true})
  helpText: string = '';
  @Input()
  isInline? = true;

}
