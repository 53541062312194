/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExternalNotificationConfigurationDto } from '../models/external-notification-configuration-dto';
import { externalNotificationConfigurationFind } from '../fn/external-notification-configuration/external-notification-configuration-find';
import { ExternalNotificationConfigurationFind$Params } from '../fn/external-notification-configuration/external-notification-configuration-find';
import { externalNotificationConfigurationFindById } from '../fn/external-notification-configuration/external-notification-configuration-find-by-id';
import { ExternalNotificationConfigurationFindById$Params } from '../fn/external-notification-configuration/external-notification-configuration-find-by-id';
import { externalNotificationConfigurationGetReceivedPreview } from '../fn/external-notification-configuration/external-notification-configuration-get-received-preview';
import { ExternalNotificationConfigurationGetReceivedPreview$Params } from '../fn/external-notification-configuration/external-notification-configuration-get-received-preview';
import { externalNotificationConfigurationUpdate } from '../fn/external-notification-configuration/external-notification-configuration-update';
import { ExternalNotificationConfigurationUpdate$Params } from '../fn/external-notification-configuration/external-notification-configuration-update';
import { ExternalNotificationPreviewDto } from '../models/external-notification-preview-dto';
import { PageExternalNotificationConfigurationDto } from '../models/page-external-notification-configuration-dto';


/**
 * Controller for External notification configurations
 */
@Injectable({ providedIn: 'root' })
export class ApiExternalNotificationConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalNotificationConfigurationFindById()` */
  static readonly ExternalNotificationConfigurationFindByIdPath = '/notification/external-notification-configuration/{id}';

  /**
   * Find external notification configuration by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationConfigurationFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationConfigurationFindById$Response(params: ExternalNotificationConfigurationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationConfigurationDto>> {
    return externalNotificationConfigurationFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find external notification configuration by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationConfigurationFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationConfigurationFindById(params: ExternalNotificationConfigurationFindById$Params, context?: HttpContext): Observable<ExternalNotificationConfigurationDto> {
    return this.externalNotificationConfigurationFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationConfigurationDto>): ExternalNotificationConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationConfigurationUpdate()` */
  static readonly ExternalNotificationConfigurationUpdatePath = '/notification/external-notification-configuration/{id}';

  /**
   * Update existing external notification configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationConfigurationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationConfigurationUpdate$Response(params: ExternalNotificationConfigurationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationConfigurationDto>> {
    return externalNotificationConfigurationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing external notification configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationConfigurationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationConfigurationUpdate(params: ExternalNotificationConfigurationUpdate$Params, context?: HttpContext): Observable<ExternalNotificationConfigurationDto> {
    return this.externalNotificationConfigurationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationConfigurationDto>): ExternalNotificationConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationConfigurationGetReceivedPreview()` */
  static readonly ExternalNotificationConfigurationGetReceivedPreviewPath = '/notification/external-notification-messages/received-preview/{receivedEpdzMessageId}';

  /**
   * Get preview of external notification for epdz message of type MESSAGE_RECEIVED
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationConfigurationGetReceivedPreview()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationConfigurationGetReceivedPreview$Response(params: ExternalNotificationConfigurationGetReceivedPreview$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationPreviewDto>> {
    return externalNotificationConfigurationGetReceivedPreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Get preview of external notification for epdz message of type MESSAGE_RECEIVED
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationConfigurationGetReceivedPreview$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationConfigurationGetReceivedPreview(params: ExternalNotificationConfigurationGetReceivedPreview$Params, context?: HttpContext): Observable<ExternalNotificationPreviewDto> {
    return this.externalNotificationConfigurationGetReceivedPreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationPreviewDto>): ExternalNotificationPreviewDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationConfigurationFind()` */
  static readonly ExternalNotificationConfigurationFindPath = '/notification/external-notification-configuration/search-api';

  /**
   * A standard SearchApi endpoint returning external notification configurations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationConfigurationFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationConfigurationFind$Response(params?: ExternalNotificationConfigurationFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageExternalNotificationConfigurationDto>> {
    return externalNotificationConfigurationFind(this.http, this.rootUrl, params, context);
  }

  /**
   * A standard SearchApi endpoint returning external notification configurations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationConfigurationFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationConfigurationFind(params?: ExternalNotificationConfigurationFind$Params|null|undefined, context?: HttpContext): Observable<PageExternalNotificationConfigurationDto> {
    return this.externalNotificationConfigurationFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageExternalNotificationConfigurationDto>): PageExternalNotificationConfigurationDto => r.body)
    );
  }

}
