<div class="text-center w-full">
  <icz-icon [svgIcon]="icon" class="xlarge mb-12"></icz-icon>

  @if (mode === DialogMode.SIMPLE) {
    @for (dialogContentItem of data.content; track dialogContentItem) {
      <p class="icz-body-1 mt-0">
        {{dialogContentItem.text | translate | interpolate:(dialogContentItem.context ?? {})}}
      </p>
    }
  }

  @if (mode === DialogMode.ACCENTED) {
    @for (dialogContentItem of data.content; track dialogContentItem) {
      <p [ngClass]="{'icz-body-strong': $first, 'icz-body-1': !$first, 'icz-text-muted': !$first}">
        {{dialogContentItem.text | translate | interpolate:(dialogContentItem.context ?? {})}}
      </p>
    }
  }
</div>

<icz-form-buttons>
  @if (data.showLeftButton) {
    <icz-button leftButtons (onAction)="close(true)" primary [label]="leftButtonTitle"></icz-button>
  }
  @if (data.showRightButton) {
    <icz-button rightButtons (onAction)="close(false)" [label]="rightButtonTitle"></icz-button>
  }
</icz-form-buttons>
