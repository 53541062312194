<div class="single-notification">
  <div class="flex items-center">
    <!-- todo refactoring idea: user-initials should itself implement
    algo for user initials and accept full user name instead -->
    @if (originatorInitials) {
      <icz-user-initials
        class="mr-8"
        [initials]="originatorInitials"
        [isSystem]="isSystemMessage"
        [color]="UserInitialsColor.GREEN">
      </icz-user-initials>
    }
    <span class="font-bold grow">
      <ng-content select="[originatorName]"></ng-content>
    </span>
    <ng-content select="[discriminator]"></ng-content>
  </div>
  <div>
    <ng-content select="[header]"></ng-content>
  </div>
  <div>
    <ng-content select="[body]"></ng-content>
  </div>
  <ng-content select="[appendix]"></ng-content>
</div>
