/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CheckConversionValidityResponseDto } from '../models/check-conversion-validity-response-dto';
import { conversionBulkChangeDataFormatWithClause } from '../fn/conversion/conversion-bulk-change-data-format-with-clause';
import { ConversionBulkChangeDataFormatWithClause$Params } from '../fn/conversion/conversion-bulk-change-data-format-with-clause';
import { conversionBulkConvertToOutputFormat } from '../fn/conversion/conversion-bulk-convert-to-output-format';
import { ConversionBulkConvertToOutputFormat$Params } from '../fn/conversion/conversion-bulk-convert-to-output-format';
import { conversionChangeDataFormatWithClause } from '../fn/conversion/conversion-change-data-format-with-clause';
import { ConversionChangeDataFormatWithClause$Params } from '../fn/conversion/conversion-change-data-format-with-clause';
import { conversionCheckAuthConversionClause } from '../fn/conversion/conversion-check-auth-conversion-clause';
import { ConversionCheckAuthConversionClause$Params } from '../fn/conversion/conversion-check-auth-conversion-clause';
import { conversionConvertAnalogToDigital } from '../fn/conversion/conversion-convert-analog-to-digital';
import { ConversionConvertAnalogToDigital$Params } from '../fn/conversion/conversion-convert-analog-to-digital';
import { conversionConvertAnalogToDigitalAuth } from '../fn/conversion/conversion-convert-analog-to-digital-auth';
import { ConversionConvertAnalogToDigitalAuth$Params } from '../fn/conversion/conversion-convert-analog-to-digital-auth';
import { conversionConvertDigitalToAnalog } from '../fn/conversion/conversion-convert-digital-to-analog';
import { ConversionConvertDigitalToAnalog$Params } from '../fn/conversion/conversion-convert-digital-to-analog';
import { conversionConvertToOutputFormat } from '../fn/conversion/conversion-convert-to-output-format';
import { ConversionConvertToOutputFormat$Params } from '../fn/conversion/conversion-convert-to-output-format';
import { conversionConvertToPaperForm } from '../fn/conversion/conversion-convert-to-paper-form';
import { ConversionConvertToPaperForm$Params } from '../fn/conversion/conversion-convert-to-paper-form';
import { conversionCreateExecutionDateClause } from '../fn/conversion/conversion-create-execution-date-clause';
import { ConversionCreateExecutionDateClause$Params } from '../fn/conversion/conversion-create-execution-date-clause';
import { conversionDownloadAuthConversionClause } from '../fn/conversion/conversion-download-auth-conversion-clause';
import { ConversionDownloadAuthConversionClause$Params } from '../fn/conversion/conversion-download-auth-conversion-clause';


/**
 * Conversion controller
 */
@Injectable({ providedIn: 'root' })
export class ApiConversionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `conversionConvertToOutputFormat()` */
  static readonly ConversionConvertToOutputFormatPath = '/component/conversion/{digitalComponentVersionId}/output-format';

  /**
   * Convert existing digital component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionConvertToOutputFormat()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionConvertToOutputFormat$Response(params: ConversionConvertToOutputFormat$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionConvertToOutputFormat(this.http, this.rootUrl, params, context);
  }

  /**
   * Convert existing digital component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionConvertToOutputFormat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionConvertToOutputFormat(params: ConversionConvertToOutputFormat$Params, context?: HttpContext): Observable<void> {
    return this.conversionConvertToOutputFormat$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionConvertToPaperForm()` */
  static readonly ConversionConvertToPaperFormPath = '/component/conversion/{digitalComponentVersionId}/convert-to-paper-form';

  /**
   * Converts an existing Acrobat PDF/A digital component version to a paper form
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionConvertToPaperForm()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionConvertToPaperForm$Response(params: ConversionConvertToPaperForm$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionConvertToPaperForm(this.http, this.rootUrl, params, context);
  }

  /**
   * Converts an existing Acrobat PDF/A digital component version to a paper form
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionConvertToPaperForm$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionConvertToPaperForm(params: ConversionConvertToPaperForm$Params, context?: HttpContext): Observable<void> {
    return this.conversionConvertToPaperForm$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionChangeDataFormatWithClause()` */
  static readonly ConversionChangeDataFormatWithClausePath = '/component/conversion/{digitalComponentVersionId}/change-data-format-with-clause';

  /**
   * Change format of digital component version with clause
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionChangeDataFormatWithClause()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionChangeDataFormatWithClause$Response(params: ConversionChangeDataFormatWithClause$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionChangeDataFormatWithClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Change format of digital component version with clause
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionChangeDataFormatWithClause$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionChangeDataFormatWithClause(params: ConversionChangeDataFormatWithClause$Params, context?: HttpContext): Observable<void> {
    return this.conversionChangeDataFormatWithClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionConvertDigitalToAnalog()` */
  static readonly ConversionConvertDigitalToAnalogPath = '/component/conversion/{digitalComponentId}/conversion-digital-to-analog';

  /**
   * Converts the digital component into the analogue component.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionConvertDigitalToAnalog()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionConvertDigitalToAnalog$Response(params: ConversionConvertDigitalToAnalog$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionConvertDigitalToAnalog(this.http, this.rootUrl, params, context);
  }

  /**
   * Converts the digital component into the analogue component.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionConvertDigitalToAnalog$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionConvertDigitalToAnalog(params: ConversionConvertDigitalToAnalog$Params, context?: HttpContext): Observable<void> {
    return this.conversionConvertDigitalToAnalog$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionCreateExecutionDateClause()` */
  static readonly ConversionCreateExecutionDateClausePath = '/component/conversion/execution-date-clause';

  /**
   * Create Execution Date Clause
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionCreateExecutionDateClause()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionCreateExecutionDateClause$Response(params: ConversionCreateExecutionDateClause$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionCreateExecutionDateClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Execution Date Clause
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionCreateExecutionDateClause$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionCreateExecutionDateClause(params: ConversionCreateExecutionDateClause$Params, context?: HttpContext): Observable<void> {
    return this.conversionCreateExecutionDateClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionConvertAnalogToDigital()` */
  static readonly ConversionConvertAnalogToDigitalPath = '/component/conversion/conversion-analog-to-digital';

  /**
   * Converts the digital scan (rendition) of the analogue component into a digital (officially verified) conversion by official authority.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionConvertAnalogToDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionConvertAnalogToDigital$Response(params: ConversionConvertAnalogToDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionConvertAnalogToDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Converts the digital scan (rendition) of the analogue component into a digital (officially verified) conversion by official authority.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionConvertAnalogToDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionConvertAnalogToDigital(params: ConversionConvertAnalogToDigital$Params, context?: HttpContext): Observable<void> {
    return this.conversionConvertAnalogToDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionBulkConvertToOutputFormat()` */
  static readonly ConversionBulkConvertToOutputFormatPath = '/component/conversion/bulk-output-format';

  /**
   * Convert existing digital components
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionBulkConvertToOutputFormat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionBulkConvertToOutputFormat$Response(params: ConversionBulkConvertToOutputFormat$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionBulkConvertToOutputFormat(this.http, this.rootUrl, params, context);
  }

  /**
   * Convert existing digital components
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionBulkConvertToOutputFormat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionBulkConvertToOutputFormat(params: ConversionBulkConvertToOutputFormat$Params, context?: HttpContext): Observable<void> {
    return this.conversionBulkConvertToOutputFormat$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionBulkChangeDataFormatWithClause()` */
  static readonly ConversionBulkChangeDataFormatWithClausePath = '/component/conversion/bulk-change-data-format-with-clause';

  /**
   * Change format of digital components version with clause
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionBulkChangeDataFormatWithClause()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionBulkChangeDataFormatWithClause$Response(params: ConversionBulkChangeDataFormatWithClause$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionBulkChangeDataFormatWithClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Change format of digital components version with clause
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionBulkChangeDataFormatWithClause$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionBulkChangeDataFormatWithClause(params: ConversionBulkChangeDataFormatWithClause$Params, context?: HttpContext): Observable<void> {
    return this.conversionBulkChangeDataFormatWithClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionConvertAnalogToDigitalAuth()` */
  static readonly ConversionConvertAnalogToDigitalAuthPath = '/component/conversion/authorized-conversion-analog-to-digital';

  /**
   * Converts the digital scan (rendition) of the analogue component into a digital authorized conversion by official authority.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionConvertAnalogToDigitalAuth()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionConvertAnalogToDigitalAuth$Response(params: ConversionConvertAnalogToDigitalAuth$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionConvertAnalogToDigitalAuth(this.http, this.rootUrl, params, context);
  }

  /**
   * Converts the digital scan (rendition) of the analogue component into a digital authorized conversion by official authority.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionConvertAnalogToDigitalAuth$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  conversionConvertAnalogToDigitalAuth(params: ConversionConvertAnalogToDigitalAuth$Params, context?: HttpContext): Observable<void> {
    return this.conversionConvertAnalogToDigitalAuth$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionDownloadAuthConversionClause()` */
  static readonly ConversionDownloadAuthConversionClausePath = '/component/conversion/{digitalComponentId}/download-authorized-conversion-clause';

  /**
   * Downloads the authorised conversion clause for digital component instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionDownloadAuthConversionClause()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionDownloadAuthConversionClause$Response(params: ConversionDownloadAuthConversionClause$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return conversionDownloadAuthConversionClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Downloads the authorised conversion clause for digital component instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionDownloadAuthConversionClause$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionDownloadAuthConversionClause(params: ConversionDownloadAuthConversionClause$Params, context?: HttpContext): Observable<void> {
    return this.conversionDownloadAuthConversionClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `conversionCheckAuthConversionClause()` */
  static readonly ConversionCheckAuthConversionClausePath = '/component/conversion/{digitalComponentId}/check-authorized-conversion-clause';

  /**
   * Checks the authorised conversion clause for paper component instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionCheckAuthConversionClause()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionCheckAuthConversionClause$Response(params: ConversionCheckAuthConversionClause$Params, context?: HttpContext): Observable<StrictHttpResponse<CheckConversionValidityResponseDto>> {
    return conversionCheckAuthConversionClause(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks the authorised conversion clause for paper component instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionCheckAuthConversionClause$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionCheckAuthConversionClause(params: ConversionCheckAuthConversionClause$Params, context?: HttpContext): Observable<CheckConversionValidityResponseDto> {
    return this.conversionCheckAuthConversionClause$Response(params, context).pipe(
      map((r: StrictHttpResponse<CheckConversionValidityResponseDto>): CheckConversionValidityResponseDto => r.body)
    );
  }

}
