/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DictionaryDto } from '../models/dictionary-dto';
import { dictionaryOauthFindByLanguage } from '../fn/dictionary-oauth/dictionary-oauth-find-by-language';
import { DictionaryOauthFindByLanguage$Params } from '../fn/dictionary-oauth/dictionary-oauth-find-by-language';
import { dictionaryOauthSearch } from '../fn/dictionary-oauth/dictionary-oauth-search';
import { DictionaryOauthSearch$Params } from '../fn/dictionary-oauth/dictionary-oauth-search';
import { PageDictionaryDto } from '../models/page-dictionary-dto';


/**
 * API for OAuth2 secured REST endpoints for management of dictionaries
 */
@Injectable({ providedIn: 'root' })
export class ApiDictionaryOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `dictionaryOauthFindByLanguage()` */
  static readonly DictionaryOauthFindByLanguagePath = '/oauth/dictionary/{lang}';

  /**
   * Find all dictionary values in selected language
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryOauthFindByLanguage()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryOauthFindByLanguage$Response(params: DictionaryOauthFindByLanguage$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DictionaryDto>>> {
    return dictionaryOauthFindByLanguage(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all dictionary values in selected language
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryOauthFindByLanguage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryOauthFindByLanguage(params: DictionaryOauthFindByLanguage$Params, context?: HttpContext): Observable<Array<DictionaryDto>> {
    return this.dictionaryOauthFindByLanguage$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DictionaryDto>>): Array<DictionaryDto> => r.body)
    );
  }

  /** Path part for operation `dictionaryOauthSearch()` */
  static readonly DictionaryOauthSearchPath = '/oauth/dictionary/search-api';

  /**
   * Find dictionary values based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `dictionaryOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryOauthSearch$Response(params?: DictionaryOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDictionaryDto>> {
    return dictionaryOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find dictionary values based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `dictionaryOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  dictionaryOauthSearch(params?: DictionaryOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageDictionaryDto> {
    return this.dictionaryOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDictionaryDto>): PageDictionaryDto => r.body)
    );
  }

}
