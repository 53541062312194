import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {
  ComponentWithContext,
  IAutocompleteListItemContext,
  IPopoverContext,
} from '../../form-autocomplete/form-autocomplete.model';
import {TreeItemSelectionStrategy, TreeViewSelection} from '../../tree-view/tree-view.component';
import {Option} from '../../../../model';

@Component({
  selector: 'icz-form-autocomplete-tree-list',
  templateUrl: './form-autocomplete-tree-list.component.html',
  styleUrls: ['./form-autocomplete-tree-list.component.scss']
})
export class FormAutocompleteTreeListComponent implements ComponentWithContext<IPopoverContext> {
  readonly TreeItemSelectionStrategy = TreeItemSelectionStrategy;

  @Input({required: true})
  options: Option[] = [];
  @Input({required: true})
  selectedOptions: Option[] = [];
  @Input({required: true})
  isMultiselect: Nullable<boolean> = false;
  @Input({required: true})
  searchTermInputPrefix = '';
  @Input({required: true})
  searchTermInputPlaceholder = '';
  @Input({required: true})
  selectionStrategy = TreeItemSelectionStrategy.SIMPLE;
  @Input({required: true})
  listItemTemplate!: TemplateRef<IAutocompleteListItemContext>;
  @Input()
  containerHeight: Nullable<number>;
  @Output()
  selectionChanged = new EventEmitter<Option[]>();
  @Output()
  searchTermChanged = new EventEmitter<string>();

  _searchTerm: string = '';

  searchItems($event: any) {
    // this method won't emit to searchTermChanged because TreeViewComponent already
    // includes a sophisticated mechanism for full-text search in n-ary tree.
    this._searchTerm = $event;
  }

  treeItemSelected(selection: TreeViewSelection) {
    this.selectionChanged.emit(
      this.options.filter(o => selection.selectedNodes.findIndex(s => s.value === o.value) !== -1)
    );
  }
}
