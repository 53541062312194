import {IczFormControl, IczFormControlState, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczValidatorFn} from '../../form-elements/validators/icz-validators/validator-decorators';
import {GenderType, SubjectAttributeSource, SubjectAttributeState} from '|api/commons';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';

export function getNewStringAttribute (formState: Nullable<string> | IczFormControlState<Nullable<string>>, validators: IczValidatorFn[]) {
  return new IczFormGroup({
    id: new IczFormControl<Nullable<string>>(null, []),  // ID of existing subject attribute
    source: new IczFormControl<Nullable<SubjectAttributeSource>>(SubjectAttributeSource.INTERNAL, []),
    state: new IczFormControl<Nullable<SubjectAttributeState>>(SubjectAttributeState.CORRECT, []),
    validFrom: new IczFormControl<Nullable<string>>(new Date().toISOString(), []),
    value: new IczFormControl<Nullable<string>>(formState, validators),
    previousValue: new IczFormControl<Nullable<string>>(null, []),
  });
}

export function getNewGenderTypeAttribute (formState: Nullable<GenderType> | IczFormControlState<Nullable<GenderType>>, validators: IczValidatorFn[]) {
  return new IczFormGroup({
    id: new IczFormControl<Nullable<string>>(null, []),  // ID of existing subject attribute
    source: new IczFormControl<Nullable<SubjectAttributeSource>>(SubjectAttributeSource.INTERNAL, []),
    state: new IczFormControl<Nullable<SubjectAttributeState>>(SubjectAttributeState.CORRECT, []),
    validFrom: new IczFormControl<Nullable<string>>(new Date().toISOString(), []),
    value: new IczFormControl<Nullable<GenderType>>(formState, validators),
    previousValue: new IczFormControl<Nullable<string>>(null, []),
  });
}

export function getNewDataboxAttribute () {
  return new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, []),  // ID of existing subject attribute
    source: new IczFormControl<Nullable<SubjectAttributeSource>>(SubjectAttributeSource.INTERNAL, []),
    state: new IczFormControl<Nullable<SubjectAttributeState>>(SubjectAttributeState.CORRECT, []),
    validFrom: new IczFormControl<Nullable<string>>(new Date().toISOString(), []),
    value:  new IczFormGroup({
      id: new IczFormControl<Nullable<string>>(null, [IczValidators.required(), IczValidators.isValidDataboxIdentifier()]),
      type: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    }),
    previousValue: new IczFormControl<Nullable<string>>(null, []),
  });
}
