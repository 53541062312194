import {CollectionViewer, DataSource, SelectionChange} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

export interface TreeEntry<D = any> {
  id?: Nullable<number>;
  parentId?: Nullable<number>;
  name: string;
  code?: Nullable<string>;
  nodeType: 'NODE' | 'LEAF';
  testingFeature?: boolean;
  data?: D;
}

export interface TreeNode<D = any> {
  id: number;
  label: string;
  level: number;
  expandable: boolean;
  opened?: boolean;
  leaf?: boolean;
  testingFeature?: boolean;
  data?: D;
}

export function getTreeNodeLevel(node: TreeNode) {
  return node.level;
}

export function isTreeNodeExpandable(node: TreeNode) {
  return node.expandable;
}

export function hasChildren(id: number, data: any[]): boolean {
  return Boolean(data) && data.some(entry => entry.parentId === id);
}

export function getChildrenParentsIds(id: number, data: any[], ids: Set<number> = new Set()): Set<number> {
  if (hasChildren(id, data)) {
    data
      .filter(item => item.parentId === id)
      .forEach(item => {
        ids.add(item.parentId);
        getChildrenParentsIds(item.id, data, ids);
      });
  } else {
    ids.add(id);
  }

  return ids;
}

export class TableTreeviewDataSource<T extends TreeEntry> implements DataSource<TreeNode> {
  private data: T[] = [];
  private _treeData$ = new BehaviorSubject<TreeNode[]>([]);
  private _selected$ = new BehaviorSubject<Nullable<TreeNode>>(null);
  private _expand$ = new BehaviorSubject<Nullable<TreeNode>>(null);
  selected$ = this._selected$.asObservable();
  expand$ = this._expand$.asObservable();
  treeData$ = this._treeData$.asObservable();

  get nodes(): TreeNode[] {
    return this._treeData$.value;
  }

  set nodes(value: TreeNode[]) {
    this.treeControl.dataNodes = value;
    this._treeData$.next(value);
  }

  getData() {
    return this.data;
  }

  private mapTreeNode(entry: T, parentLevel: number): TreeNode {
    return {
      id: entry.id!,
      label: entry.name,
      level: parentLevel + 1,
      expandable: hasChildren(entry.id!, this.data),
      leaf: (entry.nodeType === 'LEAF'),
      opened: false,
      testingFeature: entry.testingFeature,
      data: entry.data,
    };
  }

  constructor(
    private treeControl: FlatTreeControl<TreeNode>,
  ) {}

  connect(collectionViewer: CollectionViewer): Observable<TreeNode[]> {
    this.treeControl.expansionModel.changed.subscribe(
      (change: SelectionChange<TreeNode>) => {
        if (change.added || change.removed) {
          this.handleTreeControl(change);
        }
      }
    );
    return merge(collectionViewer.viewChange, this._treeData$).pipe(map(() => this.nodes));
  }

  disconnect(): void {
    this._treeData$.complete();
  }

  toggleNode(node: TreeNode, expand: boolean) {
    if (!node) return;
    const index = this.nodes.indexOf(node);
    if (expand) {
      if (node.opened) return;

      node.opened = true;

      const nodes = this.data.filter(entry => (
        node.level && entry.parentId === node.id
      )).map(entry => this.mapTreeNode(entry, node.level));

      this.nodes.splice(index + 1, 0, ...nodes);
    } else {
      node.opened = false;
      let count = 0;
      for (let i = index + 1; i < this.nodes.length && this.nodes[i].level > node.level; i++) count++;
      this.nodes.splice(index + 1, count);
    }

    this._treeData$.next(this.nodes);
  }

  private getBranchIds(id: number) {
    let result = [id];
    const dataEntry = this.data.find(entry => (entry.id === id));
    if (dataEntry && (dataEntry.parentId || dataEntry.parentId === 0)) {
      result = this.getBranchIds(dataEntry.parentId).concat(result);
    }
    return result;
  }

  private openBranch(nodeToOpen: number) {
    const branch = this.getBranchIds(nodeToOpen);
    for (const id of branch) {
      const node = this.nodes.find(n => (n.id === id));
      if (node) {
        this.toggleNode(node, true);
        this._expand$.next(node);
        if (node.id === nodeToOpen) {
          this._selected$.next(node);
        }
      }
    }
  }

  setData(source$: Observable<T[]>, nodeToOpen: number) {
    if (!this.data.length) {
      source$.subscribe(data => {
        this.data = data;
        this.buildTree(nodeToOpen);
      });
    } else {
      this.buildTree(nodeToOpen);
    }
  }

  reset() {
    this.data = [];
  }

  private buildTree(nodeToOpen: number) {
    this.nodes = this.data.filter(
      entry => (!(entry.parentId || entry.parentId === 0))
    ).map(
      entry => this.mapTreeNode(entry, 0)
    );
    this.openBranch(nodeToOpen);
  }

  getNodeById(nodeId: number) {
    const [node] = this.nodes.filter(entry => (entry.id === nodeId));
    return node;
  }

  handleTreeControl(change: SelectionChange<TreeNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  asOptions() {
    return this.data.map(entry => ({value: entry.id, label: entry.name}));
  }
}
