/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DisposalScheduleComputationResponseDto } from '../models/disposal-schedule-computation-response-dto';
import { documentBulkRegister } from '../fn/document/document-bulk-register';
import { DocumentBulkRegister$Params } from '../fn/document/document-bulk-register';
import { documentBulkSettle } from '../fn/document/document-bulk-settle';
import { DocumentBulkSettle$Params } from '../fn/document/document-bulk-settle';
import { documentBulkWithdrawSettle } from '../fn/document/document-bulk-withdraw-settle';
import { DocumentBulkWithdrawSettle$Params } from '../fn/document/document-bulk-withdraw-settle';
import { documentDeactivateDocument } from '../fn/document/document-deactivate-document';
import { DocumentDeactivateDocument$Params } from '../fn/document/document-deactivate-document';
import { DocumentDto } from '../models/document-dto';
import { documentFindByFileId } from '../fn/document/document-find-by-file-id';
import { DocumentFindByFileId$Params } from '../fn/document/document-find-by-file-id';
import { documentFindById } from '../fn/document/document-find-by-id';
import { DocumentFindById$Params } from '../fn/document/document-find-by-id';
import { documentFindSuitableDocumentsForSettlement } from '../fn/document/document-find-suitable-documents-for-settlement';
import { DocumentFindSuitableDocumentsForSettlement$Params } from '../fn/document/document-find-suitable-documents-for-settlement';
import { documentGetByIds } from '../fn/document/document-get-by-ids';
import { DocumentGetByIds$Params } from '../fn/document/document-get-by-ids';
import { documentGetValidDisposalSchedules } from '../fn/document/document-get-valid-disposal-schedules';
import { DocumentGetValidDisposalSchedules$Params } from '../fn/document/document-get-valid-disposal-schedules';
import { documentPrepareBulkSettlement } from '../fn/document/document-prepare-bulk-settlement';
import { DocumentPrepareBulkSettlement$Params } from '../fn/document/document-prepare-bulk-settlement';
import { documentPrepareDeactivation } from '../fn/document/document-prepare-deactivation';
import { DocumentPrepareDeactivation$Params } from '../fn/document/document-prepare-deactivation';
import { documentPrepareSettlement } from '../fn/document/document-prepare-settlement';
import { DocumentPrepareSettlement$Params } from '../fn/document/document-prepare-settlement';
import { DocumentPrepareSettlementDto } from '../models/document-prepare-settlement-dto';
import { documentPrintLabelBatch } from '../fn/document/document-print-label-batch';
import { DocumentPrintLabelBatch$Params } from '../fn/document/document-print-label-batch';
import { documentProcessDocumentsForSettlementNotification } from '../fn/document/document-process-documents-for-settlement-notification';
import { DocumentProcessDocumentsForSettlementNotification$Params } from '../fn/document/document-process-documents-for-settlement-notification';
import { DocumentProfileDto } from '../models/document-profile-dto';
import { documentReactivateDocument } from '../fn/document/document-reactivate-document';
import { DocumentReactivateDocument$Params } from '../fn/document/document-reactivate-document';
import { documentReadDocumentProfile } from '../fn/document/document-read-document-profile';
import { DocumentReadDocumentProfile$Params } from '../fn/document/document-read-document-profile';
import { documentRegister } from '../fn/document/document-register';
import { DocumentRegister$Params } from '../fn/document/document-register';
import { documentReindexDocuments } from '../fn/document/document-reindex-documents';
import { DocumentReindexDocuments$Params } from '../fn/document/document-reindex-documents';
import { documentSearch } from '../fn/document/document-search';
import { DocumentSearch$Params } from '../fn/document/document-search';
import { documentSettleDocumentByAcknowledgement } from '../fn/document/document-settle-document-by-acknowledgement';
import { DocumentSettleDocumentByAcknowledgement$Params } from '../fn/document/document-settle-document-by-acknowledgement';
import { documentSettleDocumentByDocument } from '../fn/document/document-settle-document-by-document';
import { DocumentSettleDocumentByDocument$Params } from '../fn/document/document-settle-document-by-document';
import { documentSettleDocumentByRecord } from '../fn/document/document-settle-document-by-record';
import { DocumentSettleDocumentByRecord$Params } from '../fn/document/document-settle-document-by-record';
import { documentUpdateDocumentProfile } from '../fn/document/document-update-document-profile';
import { DocumentUpdateDocumentProfile$Params } from '../fn/document/document-update-document-profile';
import { documentWithdrawSettledDocument } from '../fn/document/document-withdraw-settled-document';
import { DocumentWithdrawSettledDocument$Params } from '../fn/document/document-withdraw-settled-document';
import { PageDocumentDto } from '../models/page-document-dto';
import { PrepareDeactivationDto } from '../models/prepare-deactivation-dto';
import { RegisterDocumentInFilingRegisterResponseDto } from '../models/register-document-in-filing-register-response-dto';


/**
 * API for management of documents
 */
@Injectable({ providedIn: 'root' })
export class ApiDocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentReadDocumentProfile()` */
  static readonly DocumentReadDocumentProfilePath = '/document/document/profile/{documentId}';

  /**
   * Returns the document's profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReadDocumentProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentReadDocumentProfile$Response(params: DocumentReadDocumentProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentProfileDto>> {
    return documentReadDocumentProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the document's profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReadDocumentProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentReadDocumentProfile(params: DocumentReadDocumentProfile$Params, context?: HttpContext): Observable<DocumentProfileDto> {
    return this.documentReadDocumentProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentProfileDto>): DocumentProfileDto => r.body)
    );
  }

  /** Path part for operation `documentUpdateDocumentProfile()` */
  static readonly DocumentUpdateDocumentProfilePath = '/document/document/profile/{documentId}';

  /**
   * Updates a document's profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentUpdateDocumentProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentUpdateDocumentProfile$Response(params: DocumentUpdateDocumentProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentProfileDto>> {
    return documentUpdateDocumentProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a document's profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentUpdateDocumentProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentUpdateDocumentProfile(params: DocumentUpdateDocumentProfile$Params, context?: HttpContext): Observable<DocumentProfileDto> {
    return this.documentUpdateDocumentProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentProfileDto>): DocumentProfileDto => r.body)
    );
  }

  /** Path part for operation `documentWithdrawSettledDocument()` */
  static readonly DocumentWithdrawSettledDocumentPath = '/document/document/{id}/settle/withdraw';

  /**
   * Withdraw of settlement of document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentWithdrawSettledDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentWithdrawSettledDocument$Response(params: DocumentWithdrawSettledDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentWithdrawSettledDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraw of settlement of document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentWithdrawSettledDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentWithdrawSettledDocument(params: DocumentWithdrawSettledDocument$Params, context?: HttpContext): Observable<void> {
    return this.documentWithdrawSettledDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentSettleDocumentByRecord()` */
  static readonly DocumentSettleDocumentByRecordPath = '/document/document/{id}/settle-by-record';

  /**
   * Settle document by record on document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentSettleDocumentByRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentSettleDocumentByRecord$Response(params: DocumentSettleDocumentByRecord$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentSettleDocumentByRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle document by record on document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentSettleDocumentByRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentSettleDocumentByRecord(params: DocumentSettleDocumentByRecord$Params, context?: HttpContext): Observable<void> {
    return this.documentSettleDocumentByRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentSettleDocumentByDocument()` */
  static readonly DocumentSettleDocumentByDocumentPath = '/document/document/{id}/settle-by-document';

  /**
   * Settle document by document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentSettleDocumentByDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentSettleDocumentByDocument$Response(params: DocumentSettleDocumentByDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentSettleDocumentByDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle document by document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentSettleDocumentByDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentSettleDocumentByDocument(params: DocumentSettleDocumentByDocument$Params, context?: HttpContext): Observable<void> {
    return this.documentSettleDocumentByDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentSettleDocumentByAcknowledgement()` */
  static readonly DocumentSettleDocumentByAcknowledgementPath = '/document/document/{id}/settle-by-acknowledgement';

  /**
   * Settle document by record on document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentSettleDocumentByAcknowledgement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentSettleDocumentByAcknowledgement$Response(params: DocumentSettleDocumentByAcknowledgement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentSettleDocumentByAcknowledgement(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle document by record on document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentSettleDocumentByAcknowledgement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentSettleDocumentByAcknowledgement(params: DocumentSettleDocumentByAcknowledgement$Params, context?: HttpContext): Observable<void> {
    return this.documentSettleDocumentByAcknowledgement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentRegister()` */
  static readonly DocumentRegisterPath = '/document/document/{id}/register';

  /**
   * (Re-)Register document.
   *
   * (Re-)registers document by placing it in either the filing register or a given separate filing register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentRegister$Response(params: DocumentRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<RegisterDocumentInFilingRegisterResponseDto>> {
    return documentRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * (Re-)Register document.
   *
   * (Re-)registers document by placing it in either the filing register or a given separate filing register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentRegister(params: DocumentRegister$Params, context?: HttpContext): Observable<RegisterDocumentInFilingRegisterResponseDto> {
    return this.documentRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegisterDocumentInFilingRegisterResponseDto>): RegisterDocumentInFilingRegisterResponseDto => r.body)
    );
  }

  /** Path part for operation `documentReactivateDocument()` */
  static readonly DocumentReactivateDocumentPath = '/document/document/{id}/reactivate';

  /**
   * Reactivation of document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReactivateDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentReactivateDocument$Response(params: DocumentReactivateDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentReactivateDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Reactivation of document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReactivateDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentReactivateDocument(params: DocumentReactivateDocument$Params, context?: HttpContext): Observable<void> {
    return this.documentReactivateDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentFindSuitableDocumentsForSettlement()` */
  static readonly DocumentFindSuitableDocumentsForSettlementPath = '/document/document/{id}/find-suitable-documents-for-settlement';

  /**
   * Find suitable settling documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFindSuitableDocumentsForSettlement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentFindSuitableDocumentsForSettlement$Response(params: DocumentFindSuitableDocumentsForSettlement$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    return documentFindSuitableDocumentsForSettlement(this.http, this.rootUrl, params, context);
  }

  /**
   * Find suitable settling documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFindSuitableDocumentsForSettlement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentFindSuitableDocumentsForSettlement(params: DocumentFindSuitableDocumentsForSettlement$Params, context?: HttpContext): Observable<Array<DocumentDto>> {
    return this.documentFindSuitableDocumentsForSettlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

  /** Path part for operation `documentDeactivateDocument()` */
  static readonly DocumentDeactivateDocumentPath = '/document/document/{id}/deactivate';

  /**
   * Deactivation of document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDeactivateDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDeactivateDocument$Response(params: DocumentDeactivateDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentDeactivateDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Deactivation of document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDeactivateDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDeactivateDocument(params: DocumentDeactivateDocument$Params, context?: HttpContext): Observable<void> {
    return this.documentDeactivateDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentReindexDocuments()` */
  static readonly DocumentReindexDocumentsPath = '/document/document/reindex';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentReindexDocuments()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReindexDocuments$Response(params: DocumentReindexDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return documentReindexDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentReindexDocuments$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentReindexDocuments(params: DocumentReindexDocuments$Params, context?: HttpContext): Observable<Array<number>> {
    return this.documentReindexDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `documentProcessDocumentsForSettlementNotification()` */
  static readonly DocumentProcessDocumentsForSettlementNotificationPath = '/document/document/process-documents-for-settlement-notification';

  /**
   * Checks documents settlement date and creates notification event for them.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentProcessDocumentsForSettlementNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentProcessDocumentsForSettlementNotification$Response(params: DocumentProcessDocumentsForSettlementNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentProcessDocumentsForSettlementNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks documents settlement date and creates notification event for them.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentProcessDocumentsForSettlementNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentProcessDocumentsForSettlementNotification(params: DocumentProcessDocumentsForSettlementNotification$Params, context?: HttpContext): Observable<void> {
    return this.documentProcessDocumentsForSettlementNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentPrintLabelBatch()` */
  static readonly DocumentPrintLabelBatchPath = '/document/document/print-label-batch';

  /**
   * Prints label for multiple documents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrintLabelBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrintLabelBatch$Response(params: DocumentPrintLabelBatch$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return documentPrintLabelBatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Prints label for multiple documents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrintLabelBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrintLabelBatch(params: DocumentPrintLabelBatch$Params, context?: HttpContext): Observable<Array<string>> {
    return this.documentPrintLabelBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `documentGetValidDisposalSchedules()` */
  static readonly DocumentGetValidDisposalSchedulesPath = '/document/document/get-valid-disposal-schedules';

  /**
   * Returns valid disposal schedules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentGetValidDisposalSchedules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentGetValidDisposalSchedules$Response(params: DocumentGetValidDisposalSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<DisposalScheduleComputationResponseDto>> {
    return documentGetValidDisposalSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns valid disposal schedules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentGetValidDisposalSchedules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentGetValidDisposalSchedules(params: DocumentGetValidDisposalSchedules$Params, context?: HttpContext): Observable<DisposalScheduleComputationResponseDto> {
    return this.documentGetValidDisposalSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<DisposalScheduleComputationResponseDto>): DisposalScheduleComputationResponseDto => r.body)
    );
  }

  /** Path part for operation `documentGetByIds()` */
  static readonly DocumentGetByIdsPath = '/document/document/get-by-ids';

  /**
   * Returns documents by their ids.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentGetByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentGetByIds$Response(params: DocumentGetByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    return documentGetByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns documents by their ids.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentGetByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentGetByIds(params: DocumentGetByIds$Params, context?: HttpContext): Observable<Array<DocumentDto>> {
    return this.documentGetByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

  /** Path part for operation `documentBulkSettle()` */
  static readonly DocumentBulkSettlePath = '/document/document/bulk-settle';

  /**
   * Bulk settle documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentBulkSettle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentBulkSettle$Response(params: DocumentBulkSettle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentBulkSettle(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk settle documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentBulkSettle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentBulkSettle(params: DocumentBulkSettle$Params, context?: HttpContext): Observable<void> {
    return this.documentBulkSettle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentBulkWithdrawSettle()` */
  static readonly DocumentBulkWithdrawSettlePath = '/document/document/bulk-settle/withdraw';

  /**
   * Withdraw of settlement of documents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentBulkWithdrawSettle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentBulkWithdrawSettle$Response(params: DocumentBulkWithdrawSettle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentBulkWithdrawSettle(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraw of settlement of documents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentBulkWithdrawSettle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentBulkWithdrawSettle(params: DocumentBulkWithdrawSettle$Params, context?: HttpContext): Observable<void> {
    return this.documentBulkWithdrawSettle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentPrepareBulkSettlement()` */
  static readonly DocumentPrepareBulkSettlementPath = '/document/document/bulk-settle/prepare/{dateTime}';

  /**
   * Provides complete information for preparation of document settlements.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrepareBulkSettlement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrepareBulkSettlement$Response(params: DocumentPrepareBulkSettlement$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentPrepareSettlementDto>>> {
    return documentPrepareBulkSettlement(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides complete information for preparation of document settlements.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrepareBulkSettlement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentPrepareBulkSettlement(params: DocumentPrepareBulkSettlement$Params, context?: HttpContext): Observable<Array<DocumentPrepareSettlementDto>> {
    return this.documentPrepareBulkSettlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentPrepareSettlementDto>>): Array<DocumentPrepareSettlementDto> => r.body)
    );
  }

  /** Path part for operation `documentBulkRegister()` */
  static readonly DocumentBulkRegisterPath = '/document/document/bulk-register';

  /**
   * (Re-)Register documents.
   *
   * (Re-)registers documents by placing it in either the filing register or a given separate filing register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentBulkRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentBulkRegister$Response(params: DocumentBulkRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return documentBulkRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * (Re-)Register documents.
   *
   * (Re-)registers documents by placing it in either the filing register or a given separate filing register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentBulkRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentBulkRegister(params: DocumentBulkRegister$Params, context?: HttpContext): Observable<void> {
    return this.documentBulkRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `documentFindById()` */
  static readonly DocumentFindByIdPath = '/document/document/{id}';

  /**
   * Find document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById$Response(params: DocumentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDto>> {
    return documentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindById(params: DocumentFindById$Params, context?: HttpContext): Observable<DocumentDto> {
    return this.documentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDto>): DocumentDto => r.body)
    );
  }

  /** Path part for operation `documentPrepareSettlement()` */
  static readonly DocumentPrepareSettlementPath = '/document/document/{id}/settle/prepare/{dateTime}';

  /**
   * Provides complete information for preparation of document settlement.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrepareSettlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrepareSettlement$Response(params: DocumentPrepareSettlement$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentPrepareSettlementDto>> {
    return documentPrepareSettlement(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides complete information for preparation of document settlement.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrepareSettlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrepareSettlement(params: DocumentPrepareSettlement$Params, context?: HttpContext): Observable<DocumentPrepareSettlementDto> {
    return this.documentPrepareSettlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentPrepareSettlementDto>): DocumentPrepareSettlementDto => r.body)
    );
  }

  /** Path part for operation `documentPrepareDeactivation()` */
  static readonly DocumentPrepareDeactivationPath = '/document/document/{id}/deactivate/prepare';

  /**
   * Provides complete validations for document deactivation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentPrepareDeactivation()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrepareDeactivation$Response(params: DocumentPrepareDeactivation$Params, context?: HttpContext): Observable<StrictHttpResponse<PrepareDeactivationDto>> {
    return documentPrepareDeactivation(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides complete validations for document deactivation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentPrepareDeactivation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentPrepareDeactivation(params: DocumentPrepareDeactivation$Params, context?: HttpContext): Observable<PrepareDeactivationDto> {
    return this.documentPrepareDeactivation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrepareDeactivationDto>): PrepareDeactivationDto => r.body)
    );
  }

  /** Path part for operation `documentSearch()` */
  static readonly DocumentSearchPath = '/document/document/search-api';

  /**
   * Finds documents based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentSearch$Response(params?: DocumentSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDocumentDto>> {
    return documentSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds documents based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentSearch(params?: DocumentSearch$Params|null|undefined, context?: HttpContext): Observable<PageDocumentDto> {
    return this.documentSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDocumentDto>): PageDocumentDto => r.body)
    );
  }

  /** Path part for operation `documentFindByFileId()` */
  static readonly DocumentFindByFileIdPath = '/document/document/file/{fileId}';

  /**
   * Find documents in a file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFindByFileId()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindByFileId$Response(params: DocumentFindByFileId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    return documentFindByFileId(this.http, this.rootUrl, params, context);
  }

  /**
   * Find documents in a file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFindByFileId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentFindByFileId(params: DocumentFindByFileId$Params, context?: HttpContext): Observable<Array<DocumentDto>> {
    return this.documentFindByFileId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

}
