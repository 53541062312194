<div class="col gap-8 list">
  @for (app of apiStatus; track app.name) {
    <div class="row">
      @if (app.isStarting) {
        <icz-icon class="icon-starting" svgIcon="in_progress" withCustomIconColor></icz-icon>
      }
      @else {
        @if (app.isRunning) {
          <icz-icon svgIcon="success"></icz-icon>
        }
        @if (!app.isRunning) {
          <icz-icon svgIcon="error"></icz-icon>
        }
      }
      <icz-label [label]="app.description || app.name"></icz-label>
    </div>
  }
</div>
