/* tslint:disable */
/* eslint-disable */

/**
 * Delivery type nodes with their structure
 */
export enum DistributionClass {
  DISTRIBUTION_NODE = 'DISTRIBUTION_NODE',
  DIGITAL_DISTRIBUTION_NODE = 'DIGITAL_DISTRIBUTION_NODE',
  HYBRID_DISTRIBUTION_NODE = 'HYBRID_DISTRIBUTION_NODE',
  PHYSICAL_DISTRIBUTION_NODE = 'PHYSICAL_DISTRIBUTION_NODE',
  INTERNAL_NODE = 'INTERNAL_NODE',
  FILESYSTEM_FILE = 'FILESYSTEM_FILE',
  INFORMATION_SYSTEM = 'INFORMATION_SYSTEM',
  OFFICIAL_BOARD = 'OFFICIAL_BOARD',
  PORTAL = 'PORTAL',
  DIGITAL_BOX = 'DIGITAL_BOX',
  PHYSICAL_BOX = 'PHYSICAL_BOX',
  ADDRESS_PLACE = 'ADDRESS_PLACE',
  EMAIL_BOX = 'EMAIL_BOX',
  ISDS_BOX = 'ISDS_BOX',
  PORTAL_BOX = 'PORTAL_BOX',
  INTERNAL = 'INTERNAL',
  PHONE_CONTACT = 'PHONE_CONTACT',
  ON_PLACE = 'ON_PLACE'
}
