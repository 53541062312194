/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FunctionalPositionDto } from '../models/functional-position-dto';
import { FunctionalPositionWithSubstitutionDto } from '../models/functional-position-with-substitution-dto';
import { OrganizationalUnitDto } from '../models/organizational-unit-dto';
import { orgStructCacheFindAllByUser } from '../fn/org-struct-cache/org-struct-cache-find-all-by-user';
import { OrgStructCacheFindAllByUser$Params } from '../fn/org-struct-cache/org-struct-cache-find-all-by-user';
import { orgStructCacheFindAllFunctionalPositions } from '../fn/org-struct-cache/org-struct-cache-find-all-functional-positions';
import { OrgStructCacheFindAllFunctionalPositions$Params } from '../fn/org-struct-cache/org-struct-cache-find-all-functional-positions';
import { orgStructCacheFindAllOrganizationalUnits } from '../fn/org-struct-cache/org-struct-cache-find-all-organizational-units';
import { OrgStructCacheFindAllOrganizationalUnits$Params } from '../fn/org-struct-cache/org-struct-cache-find-all-organizational-units';
import { orgStructCacheFindAllUsers } from '../fn/org-struct-cache/org-struct-cache-find-all-users';
import { OrgStructCacheFindAllUsers$Params } from '../fn/org-struct-cache/org-struct-cache-find-all-users';
import { orgStructCacheGetFunctionalPositionsWithSubstitutions } from '../fn/org-struct-cache/org-struct-cache-get-functional-positions-with-substitutions';
import { OrgStructCacheGetFunctionalPositionsWithSubstitutions$Params } from '../fn/org-struct-cache/org-struct-cache-get-functional-positions-with-substitutions';
import { PageUserDto } from '../models/page-user-dto';


/**
 * OrgStructCache API
 */
@Injectable({ providedIn: 'root' })
export class ApiOrgStructCacheService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `orgStructCacheFindAllUsers()` */
  static readonly OrgStructCacheFindAllUsersPath = '/org-struct-cache/user/find-all';

  /**
   * Find all cached users.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orgStructCacheFindAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllUsers$Response(params?: OrgStructCacheFindAllUsers$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageUserDto>> {
    return orgStructCacheFindAllUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all cached users.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orgStructCacheFindAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllUsers(params?: OrgStructCacheFindAllUsers$Params|null|undefined, context?: HttpContext): Observable<PageUserDto> {
    return this.orgStructCacheFindAllUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageUserDto>): PageUserDto => r.body)
    );
  }

  /** Path part for operation `orgStructCacheFindAllOrganizationalUnits()` */
  static readonly OrgStructCacheFindAllOrganizationalUnitsPath = '/org-struct-cache/organizational-unit';

  /**
   * Find all cached organizational unit instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orgStructCacheFindAllOrganizationalUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllOrganizationalUnits$Response(params?: OrgStructCacheFindAllOrganizationalUnits$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationalUnitDto>>> {
    return orgStructCacheFindAllOrganizationalUnits(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all cached organizational unit instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orgStructCacheFindAllOrganizationalUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllOrganizationalUnits(params?: OrgStructCacheFindAllOrganizationalUnits$Params|null|undefined, context?: HttpContext): Observable<Array<OrganizationalUnitDto>> {
    return this.orgStructCacheFindAllOrganizationalUnits$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationalUnitDto>>): Array<OrganizationalUnitDto> => r.body)
    );
  }

  /** Path part for operation `orgStructCacheFindAllFunctionalPositions()` */
  static readonly OrgStructCacheFindAllFunctionalPositionsPath = '/org-struct-cache/functional-position';

  /**
   * Find all cached functional position instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orgStructCacheFindAllFunctionalPositions()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllFunctionalPositions$Response(params?: OrgStructCacheFindAllFunctionalPositions$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FunctionalPositionDto>>> {
    return orgStructCacheFindAllFunctionalPositions(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all cached functional position instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orgStructCacheFindAllFunctionalPositions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllFunctionalPositions(params?: OrgStructCacheFindAllFunctionalPositions$Params|null|undefined, context?: HttpContext): Observable<Array<FunctionalPositionDto>> {
    return this.orgStructCacheFindAllFunctionalPositions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalPositionDto>>): Array<FunctionalPositionDto> => r.body)
    );
  }

  /** Path part for operation `orgStructCacheFindAllByUser()` */
  static readonly OrgStructCacheFindAllByUserPath = '/org-struct-cache/functional-position/user';

  /**
   * Find all cached functional position instances of user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orgStructCacheFindAllByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllByUser$Response(params?: OrgStructCacheFindAllByUser$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FunctionalPositionDto>>> {
    return orgStructCacheFindAllByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all cached functional position instances of user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orgStructCacheFindAllByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheFindAllByUser(params?: OrgStructCacheFindAllByUser$Params|null|undefined, context?: HttpContext): Observable<Array<FunctionalPositionDto>> {
    return this.orgStructCacheFindAllByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalPositionDto>>): Array<FunctionalPositionDto> => r.body)
    );
  }

  /** Path part for operation `orgStructCacheGetFunctionalPositionsWithSubstitutions()` */
  static readonly OrgStructCacheGetFunctionalPositionsWithSubstitutionsPath = '/org-struct-cache/functional-position/functional-position-with-substitution/user';

  /**
   * Find all functional position instances that have direct or indirect parent functional position with id {parentId}
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `orgStructCacheGetFunctionalPositionsWithSubstitutions()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheGetFunctionalPositionsWithSubstitutions$Response(params?: OrgStructCacheGetFunctionalPositionsWithSubstitutions$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FunctionalPositionWithSubstitutionDto>>> {
    return orgStructCacheGetFunctionalPositionsWithSubstitutions(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances that have direct or indirect parent functional position with id {parentId}
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `orgStructCacheGetFunctionalPositionsWithSubstitutions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  orgStructCacheGetFunctionalPositionsWithSubstitutions(params?: OrgStructCacheGetFunctionalPositionsWithSubstitutions$Params|null|undefined, context?: HttpContext): Observable<Array<FunctionalPositionWithSubstitutionDto>> {
    return this.orgStructCacheGetFunctionalPositionsWithSubstitutions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalPositionWithSubstitutionDto>>): Array<FunctionalPositionWithSubstitutionDto> => r.body)
    );
  }

}
