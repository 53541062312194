@if (consignment) {
  @if (isOwnOfficeDeskConsignment(consignment) && $any(consignment).publicPosting) {
    <div>
      <span>{{'Veřejné vyvěšení' | translate}}</span>
    </div>
  }
  @else {
    @if (isOwnInternalConsignment(consignment)) {
      <span> {{ consignment.consigneeOrganizationalUnitId! | findOrganizationalUnitById | async }}</span>
    }
    @if (isOwnPaperConsignment(consignment) || isOwnOfficeDeskConsignment(consignment) || isOwnDigitalConsignment(consignment) || isOwnFaxConsignment(consignment) || isOwnPortalConsignment(consignment)) {
      <div class="row items-center">
        <icz-subject-identification-status-icon
          [subject]="consignment.consigneeDetail"
          size="small"
        ></icz-subject-identification-status-icon>
        <span class="inline truncate">{{consignment.consigneeDetail | subjectName:true:$any(consignment).consigneeAdditionalName}}</span>
      </div>
    }
  }
}
