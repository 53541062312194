import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {ReceivedDigitalConsignmentDto, ReceivedEpdzMessageDetailDto} from '|api/sad';
import {Option} from '../../../../model';
import {CodebookService} from '../../../../core/services/codebook.service';
import {namedDtosToOptions} from '../../../../core/services/data-mapping.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'icz-email-metadata-preview',
  templateUrl: './email-metadata-preview.component.html',
  styleUrls: ['./email-metadata-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailMetadataPreviewComponent implements OnInit {

  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  /*
   * Either metadata OR consignment should be supplied
   */
  @Input()
  message: Nullable<ReceivedEpdzMessageDetailDto>;
  @Input()
  consignment: Nullable<ReceivedDigitalConsignmentDto>;

  deliveryTypes!: Option[];

  ngOnInit() {
    this.codebookService.deliveryTypes().pipe(
      namedDtosToOptions,
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(deliveryTypes => {
      this.deliveryTypes = deliveryTypes;
    });
  }

}
