import {inject, Injectable} from '@angular/core';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  RenditionToastType,
  SFilingRegisterRenditionFinishedSuccessTemplateData,
  SFilingRegisterRenditionScheduleScheduledTemplateData
} from './rendition-toast.service';

@Injectable({
  providedIn: 'root'
})
export class RenditionNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchSimpleToast(MessageType.INFO, RenditionToastType.FILING_REGISTER_RENDITION_SCHEDULED, parsedBody.parameters[0], SFilingRegisterRenditionScheduleScheduledTemplateData);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FILING_REGISTER_RENDITION_FINISHED,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchSimpleToast(MessageType.INFO, RenditionToastType.FILING_REGISTER_RENDITION_FINISHED_SUCCESS, parsedBody.parameters[0], SFilingRegisterRenditionFinishedSuccessTemplateData);
      }
    );
  }

}
