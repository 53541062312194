/* tslint:disable */
/* eslint-disable */

/**
 * Possible values for retention trigger type.
 */
export enum RetentionTriggerTypeCode {
  AFTER_EXTERNAL_EVENT = 'AFTER_EXTERNAL_EVENT',
  AFTER_ENTITY_CREATE = 'AFTER_ENTITY_CREATE',
  AFTER_ENTITY_SETTLE = 'AFTER_ENTITY_SETTLE',
  AFTER_ENTITY_CLOSE = 'AFTER_ENTITY_CLOSE',
  AFTER_SUBJECT_CREATE = 'AFTER_SUBJECT_CREATE'
}
