/* tslint:disable */
/* eslint-disable */

/**
 * Takeover type of registry office object
 */
export enum RegistryOfficeTakeoverType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}
