import {inject, Injectable} from '@angular/core';
import {ToolbarProvider} from '../../../table/table-toolbar/table-toolbar-buttons.utils';
import {DocumentView, FileView} from './toolbar-common.utils';
import {BulkOperationValidationService} from '../../../../services/bulk-operation-validation.service';
import {GlobalLoadingIndicatorService} from '../../../essentials/global-loading-indicator.service';
import {AbstractDocumentDialogsManagerService} from '../../../../services/abstract-document-dialogs-manager.service';
import {AuthorizedButton, AuthorizedButtonService} from '../../../essentials/authorized-button.service';
import {TranslateService} from '@ngx-translate/core';
import {MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {DocumentOrFileDto, getObjectIcon, getObjectLabel, isDocumentEntity} from '../../shared-document.utils';
import {map, Observable, of} from 'rxjs';
import {Button} from '../../../essentials/button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {AuthorizedEntityType, DocumentAuthorizedOperation, FileAuthorizedOperation} from '|api/core';
import {ObjectClass} from '|api/commons';
import {CommonToolbarValidators} from './toolbar-common.validators';

export enum DocumentAndFileToolbarAction {
  SUSPEND_SETTLEMENT = 'SUSPEND_SETTLEMENT',
  RESUME_SETTLEMENT = 'RESUME_SETTLEMENT'
}

@Injectable()
export class DocumentAndFileToolbarButtonsService extends ToolbarProvider<DocumentOrFileDto, DocumentAndFileToolbarAction, DocumentView | FileView> {

  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private documentDialogService = inject(AbstractDocumentDialogsManagerService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private translateService = inject(TranslateService);
  private mainMenuCountsService = inject(MainMenuCountsService);

  getToolbarButtons(selection: Nullable<DocumentOrFileDto[]>, viewType: DocumentView | FileView): Observable<Button[]> {
    let buttons$: Observable<AuthorizedButton[]>;
    if (viewType === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR) {
      buttons$ = of([
        {
          label: 'Editace lhůty kontroly událostí',
          icon: 'edit',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          action: () => this.onRetentionYearEdit(selection!, true),
        },
        {
          label: 'Editace roku spouštěcí událostí',
          icon: 'edit',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          action: () => this.onRetentionYearEdit(selection!, false),
        }
      ] as AuthorizedButton[]);
    } else {
      buttons$ = of([
        {
          label: 'Úpravy',
          icon: 'edit',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          submenuItems: [
            this.getSuspendSettlementButton(selection),
            this.getResumeSettlementButton(selection),
          ]
        }
      ] as AuthorizedButton[]);
    }
    return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
  }

  private esslObjectToValidationObject(object: DocumentOrFileDto) {
    return {
      entityId: object.id,
      authorizedEntityType: isDocumentEntity(object.entityType) ? AuthorizedEntityType.DOCUMENT : AuthorizedEntityType.FILE,
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

  private getSuspendSettlementButton(selection: Nullable<Array<DocumentOrFileDto>>): AuthorizedButton {
    return {
      label: 'Přerušit řízení',
      icon: '',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
        CommonToolbarDisablers.isFileOrDocumentSuspended(selection),
        CommonToolbarDisablers.isFileOrDocumentInExternalApp(selection),
      ],
      action: () => this.onSuspendSettlementClick(selection!),
    };
  }

  private getResumeSettlementButton(selection: Nullable<Array<DocumentOrFileDto>>): AuthorizedButton {
    return {
      label: 'Obnovit řízení',
      icon: '',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
        CommonToolbarDisablers.isFileOrDocumentNotSuspended(selection),
      ],
      action: () => this.onResumeSettlementClick(selection!),
    };
  }

  private onSuspendSettlementClick(selectedObjects: DocumentOrFileDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést přerušení řízení. Hromadné přerušení řízení bude provedeno jen s vyhovujícími objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked(),
          CommonToolbarValidators.isFileOrDocumentSuspended(),
          CommonToolbarValidators.isFileOrDocumentInExternalApp()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_SETTLE, FileAuthorizedOperation.FILE_SETTLE],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openSuspendSettlementDialog(validatedObjects).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.SUSPEND_SETTLEMENT);
          }
        });
      }
    });
  }

  private onResumeSettlementClick(selectedObjects: DocumentOrFileDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést obnovení řízení. Hromadné obnovení řízení bude provedeno jen s vyhovujícími objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked(),
          CommonToolbarValidators.isFileOrDocumentNotSuspended()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_SETTLE, FileAuthorizedOperation.FILE_SETTLE],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openResumeSettlementDialog(validatedObjects).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.RESUME_SETTLEMENT);
          }
        });
      }
    });
  }

  private onRetentionYearEdit(selectedObjects: DocumentOrFileDto[], editCheckYear: boolean) {
    const validationMessage = editCheckYear ? 'Pro některé objekty ({{errorCount}}) není možno provést editaci lhůty kontroly události. Hromadné akce bude provedena jen s vyhovujícími objekty ({{successCount}}).' :
      'Pro některé objekty ({{errorCount}}) není možno provést editaci roku spouštěcí události. Hromadné akce bude provedena jen s vyhovujícími objekty ({{successCount}}).';
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: validationMessage,
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openRetentionYearEditDialog(validatedObjects, editCheckYear).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.RESUME_SETTLEMENT);
          }
        });
      }
    });
  }
}
