/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ErmsDataFindByErmsIdentifier$Params {

/**
 * Digital component identifier.
 */
  identifier: string;

/**
 * Digital component source.
 */
  idSource: string;

/**
 * Username.
 */
  username: string;

/**
 * Source of issd application.
 */
  sourceId: string;

/**
 * Code of organization.
 */
  orgCode: string;
}

export function ermsDataFindByErmsIdentifier(http: HttpClient, rootUrl: string, params: ErmsDataFindByErmsIdentifier$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(rootUrl, ermsDataFindByErmsIdentifier.PATH, 'get');
  if (params) {
    rb.path('identifier', params.identifier, {});
    rb.path('idSource', params.idSource, {});
    rb.path('username', params.username, {});
    rb.path('sourceId', params.sourceId, {});
    rb.path('orgCode', params.orgCode, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<string>>;
    })
  );
}

ermsDataFindByErmsIdentifier.PATH = '/ermsData/{identifier}/{idSource}/{username}/{sourceId}/{orgCode}';