import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {catchError, distinctUntilChanged, filter, map, shareReplay, switchMap, withLatestFrom} from 'rxjs/operators';
import {ActivatedRoute} from '@angular/router';
import {CirculationActivityState, EntityType, LockedState, SubjectRecordDto} from '|api/commons';
import {ApiCirculationActivityService, ApiCirculationTaskService} from '|api/flow';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DestroyRef, inject} from '@angular/core';
import {
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation,
  isAuthorizedOperationGranted
} from '../components/shared-business-components/permissions/permissions.utils';
import {CountChangeCommand, ObjectCounts} from '../lib/object-counts';
import {CurrentSessionService} from './current-session.service';
import {CirculationSearchService} from './circulation-search.service';
import {
  ExtendedCirculationActivityDto,
  ExtendedCirculationTaskDto
} from '../components/shared-business-components/model/elastic-extended-entities.interface';
import {IczFormGroup} from '../components/form-elements/icz-form-controls';
import {FilterOperator, GenericSearchService} from './search-api.service';
import {EsslObject} from '../components/shared-business-components/model/entity.model';
import {
  ApiRegistryOfficeTransferService,
  RegistryOfficeTransferDto,
  RegistryOfficeTransferWithMainEntityInfoDto,
  StorageUnitDto
} from '|api/document';
import {isDocumentObject} from '../components/shared-business-components/shared-document.utils';
import {SKIP_ERROR_DIALOG} from '../core/error-handling/http-errors';
import {LoadingIndicatorService} from '../components/essentials/loading-indicator.service';
import {
  EntityWithAuthorization
} from '../components/shared-business-components/permissions/model/entity-with-permissions.model';
import {TabItemWithPriority} from '../components/essentials/tabs/tabs.component.model';

export enum ObjectDetailSubmitActionType {
  SAVE = 'SAVE',
  SAVE_AND_CLOSE = 'SAVE_AND_CLOSE',
}

export enum ObjectDetailPart {
  OBJECT_DATA = 'objectData',
  OBJECT_PERMISSIONS = 'objectPermissions',
}

export enum ObjectDetailLoadType {
  INITIAL_LOAD = 'INITIAL_LOAD',
  RELOAD_OBJECT = 'RELOAD_OBJECT',
  RELOAD_PERMISSIONS = 'RELOAD_PERMISSIONS',
}

export interface EsslObjectDetailEntity extends EsslObject, EntityWithAuthorization {
  registryOfficeTransfer?: Nullable<RegistryOfficeTransferDto>;
}

export abstract class AbstractObjectDetailService<TObject extends EsslObjectDetailEntity, TCount extends string> {

  protected destroyRef = inject(DestroyRef);
  protected route = inject(ActivatedRoute);
  protected loadingService = inject(LoadingIndicatorService);
  protected apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);

  protected loadRequested$ = new Subject<ObjectDetailLoadType>();

  objectId$ = this.route.params.pipe(map(params => params.uuid)).pipe(
    filter(Boolean),
    map(objectId => Number(objectId)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  objectId!: number;
  object!: TObject;
  lockedState: Nullable<LockedState>;

  abstract objectAuthorization$: Observable<Nullable<GeneralAuthorizationResult>>;
  abstract object$: Observable<Nullable<TObject>>;
  abstract tabs$: Observable<TabItemWithPriority[]>;

  abstract counts: ObjectCounts<TCount>;

  isRegistryOfficeTransferActivityView$ = this.route.queryParams.pipe(
    map(params => Boolean(params.registryOfficeTransferView)),
    distinctUntilChanged(),
    shareReplay(1),
  );
  registryOfficeTransfer: Nullable<RegistryOfficeTransferWithMainEntityInfoDto>;
  registryOfficeTransfer$ = new Subject<Nullable<RegistryOfficeTransferWithMainEntityInfoDto>>();

  form$ = new BehaviorSubject<Nullable<IczFormGroup>>(null);
  lockedState$!: Observable<Nullable<LockedState>>;
  alreadySaved = false;

  showSubmitBar = false;

  submit$ = new Subject<ObjectDetailSubmitActionType>();
  submitFinished$ = new Subject<void>();
  cancel$ = new Subject<void>();

  protected genericSearchService = inject(GenericSearchService);

  private getRegistryOfficeTransfer(object: TObject): Observable<Nullable<RegistryOfficeTransferWithMainEntityInfoDto>> {
    if (object.registryOfficeTransfer) {
      return this.apiRegistryOfficeTransferService.registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos(
        {
          entityType: isDocumentObject(object) ? EntityType.DOCUMENT : object.entityType!,
          entityId: object.id!,
        },
        SKIP_ERROR_DIALOG
      ).pipe(
        catchError(_ => of(null))
      );
    }
    else {
      return of(null);
    }
  }

  initialize() {
    this.loadRequested$.pipe(
      withLatestFrom(this.objectId$),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([loadType, objectId]) => {
      if (loadType === ObjectDetailLoadType.INITIAL_LOAD) {
        this.counts.loadAllCounts(objectId).subscribe();
      }
    });

    this.object$.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(object => {
      this.lockedState = object.lockedState;
      this.object = object;
      this.getRegistryOfficeTransfer(object).subscribe(registryOfficeTransfer => {
        this.registryOfficeTransfer = registryOfficeTransfer;
        this.registryOfficeTransfer$.next(this.registryOfficeTransfer);
      });
    });

    this.objectId$.pipe(
      filter(Boolean),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(objectId => {
      this.objectId = objectId;
      this.loadRequested$.next(ObjectDetailLoadType.INITIAL_LOAD);
    });

    this.lockedState$ = this.object$.pipe(filter(Boolean),map(obj => obj.lockedState));
  }

  reloadObject(changeCommands?: Partial<Record<TCount | ObjectDetailPart, CountChangeCommand>>) {
    if (!changeCommands || changeCommands[ObjectDetailPart.OBJECT_DATA]) {
      this.loadRequested$.next(ObjectDetailLoadType.RELOAD_OBJECT);
    }
    if (!changeCommands || changeCommands[ObjectDetailPart.OBJECT_PERMISSIONS]) {
      this.loadRequested$.next(ObjectDetailLoadType.RELOAD_PERMISSIONS);
    }

    if (!changeCommands) {
      this.counts.loadAllCounts(this.objectId).subscribe();
    }
    else {
      this.counts.requestChangeCounts(this.objectId, changeCommands as Partial<Record<TCount, CountChangeCommand>>).subscribe();
    }
  }

  resetActiveForm() {
    this.form$.value!.reset(this.object);
  }
}

export abstract class DocumentFileDetailService<TObject extends EsslObjectDetailEntity, TCount extends string> extends AbstractObjectDetailService<TObject, TCount> {

  protected circulationSearchService = inject(CirculationSearchService);
  protected currentSessionService = inject(CurrentSessionService);
  protected apiCirculationActivityService = inject(ApiCirculationActivityService);
  protected apiCirculationTaskService = inject(ApiCirculationTaskService);

  protected abstract activitiesAuthorizedOperation: GeneralAuthorizedOperation;
  protected abstract objectReferenceIdFieldName: 'documentId' | 'fileId';

  taskId$ = this.route.queryParams.pipe(
    filter(params => !(params.taskId && params.activityId)),
    map(params => params.taskId),
    map(taskId => {
      if (taskId) {
        return Number(taskId);
      } else {
        return null;
      }
    }),
    shareReplay(1),
  );

  activityId$ = this.route.queryParams.pipe(
    filter(params => !(params.taskId && params.activityId)),
    map(params => params.activityId),
    map(activityId => {
      if (activityId) {
        return Number(activityId);
      } else {
        return null;
      }
    }),
    shareReplay(1),
  );
  objectRepresentingSubject: Nullable<SubjectRecordDto>;
  objectRepresentingSubject$!: Observable<Nullable<SubjectRecordDto>>;

  override initialize() {
    super.initialize();

    this.runningActivity$ = this.object$.pipe(
      filter(object => isAuthorizedOperationGranted(object?.authorization, this.activitiesAuthorizedOperation)),
      switchMap(object => {
        return this.circulationSearchService.findActivitiesGlobally({
          filter: [
            {
              fieldName: this.objectReferenceIdFieldName,
              operator: FilterOperator.equals,
              value: String(object!.id),
            },
            {
              fieldName: 'activityState',
              operator: FilterOperator.equals,
              value: CirculationActivityState.IN_PROGRESS,
            }
          ],
          sort: [],
          page: 0,
          size: 1,
        });
      }),
      map(page => page.content),
      map(activities => activities[0]),
      shareReplay(),
    );

    this.objectRepresentingSubject$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(objectRepresentingSubject => {
      this.objectRepresentingSubject = objectRepresentingSubject;
    });
  }

  abstract getStorageUnit(object: TObject): Nullable<Observable<StorageUnitDto>>;

  runningActivity$!: Observable<Nullable<ExtendedCirculationActivityDto>>;

  refActivity$ = combineLatest([
    this.objectId$,
    this.activityId$,
  ]).pipe(
    switchMap(([objectId, activityId]) => {
      if (activityId) {
        return this.loadingService.doLoading(
          this.apiCirculationActivityService.circulationActivityFindById({id: activityId}).pipe(
            map(activity => {
              const isCurrentUsersActivity = activity.initFunctionalPositionId === this.currentSessionService.currentUserFunctionalPosition!.id;
              const isCurrentObjectsActivity = activity[this.objectReferenceIdFieldName] === objectId;

              if (isCurrentUsersActivity && isCurrentObjectsActivity) {
                return activity as ExtendedCirculationActivityDto;
              }
              else {
                return null;
              }
            })
          ),
          this
        );
      } else {
        return of(null);
      }
    }),
  );

  refTask$ = combineLatest([
    this.objectId$,
    this.taskId$,
  ]).pipe(
    switchMap(([objectId, taskId]) => {
      if (taskId) {
        return this.loadingService.doLoading(
          this.apiCirculationTaskService.circulationTaskFindById({id: taskId}).pipe(
            map(task => {
              const isCurrentUsersTask = task.ownerFunctionalPositionId === this.currentSessionService.currentUserFunctionalPosition!.id;
              const isCurrentObjectsTask = task[this.objectReferenceIdFieldName] === objectId;

              if (isCurrentUsersTask && isCurrentObjectsTask) {
                task[this.objectReferenceIdFieldName] = objectId;
                return task as ExtendedCirculationTaskDto;
              }
              else {
                return null;
              }
            }),
          ),
          this
        );
      } else {
        return of(null);
      }
    }),
  );

}
