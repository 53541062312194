/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FunctionalPositionDto } from '../models/functional-position-dto';
import { functionalPositionFindAll } from '../fn/functional-position/functional-position-find-all';
import { FunctionalPositionFindAll$Params } from '../fn/functional-position/functional-position-find-all';
import { functionalPositionFindAllByUser } from '../fn/functional-position/functional-position-find-all-by-user';
import { FunctionalPositionFindAllByUser$Params } from '../fn/functional-position/functional-position-find-all-by-user';
import { functionalPositionFindByCode } from '../fn/functional-position/functional-position-find-by-code';
import { FunctionalPositionFindByCode$Params } from '../fn/functional-position/functional-position-find-by-code';
import { functionalPositionFindById } from '../fn/functional-position/functional-position-find-by-id';
import { FunctionalPositionFindById$Params } from '../fn/functional-position/functional-position-find-by-id';
import { functionalPositionFindFirstUserByRelationType } from '../fn/functional-position/functional-position-find-first-user-by-relation-type';
import { FunctionalPositionFindFirstUserByRelationType$Params } from '../fn/functional-position/functional-position-find-first-user-by-relation-type';
import { functionalPositionGetFunctionalPositionsWithSubstitutions } from '../fn/functional-position/functional-position-get-functional-positions-with-substitutions';
import { FunctionalPositionGetFunctionalPositionsWithSubstitutions$Params } from '../fn/functional-position/functional-position-get-functional-positions-with-substitutions';
import { functionalPositionSearch } from '../fn/functional-position/functional-position-search';
import { FunctionalPositionSearch$Params } from '../fn/functional-position/functional-position-search';
import { functionalPositionSearchForUser } from '../fn/functional-position/functional-position-search-for-user';
import { FunctionalPositionSearchForUser$Params } from '../fn/functional-position/functional-position-search-for-user';
import { functionalPositionSearchParentChildrenTree } from '../fn/functional-position/functional-position-search-parent-children-tree';
import { FunctionalPositionSearchParentChildrenTree$Params } from '../fn/functional-position/functional-position-search-parent-children-tree';
import { FunctionalPositionWithSubstitutionDto } from '../models/functional-position-with-substitution-dto';
import { PageFunctionalPositionDto } from '../models/page-functional-position-dto';
import { UserDto } from '../models/user-dto';


/**
 * Controller with operations over functional operations
 */
@Injectable({ providedIn: 'root' })
export class ApiFunctionalPositionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `functionalPositionFindAll()` */
  static readonly FunctionalPositionFindAllPath = '/functional-position';

  /**
   * Find all functional position instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindAll$Response(params?: FunctionalPositionFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FunctionalPositionDto>>> {
    return functionalPositionFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindAll(params?: FunctionalPositionFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<FunctionalPositionDto>> {
    return this.functionalPositionFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalPositionDto>>): Array<FunctionalPositionDto> => r.body)
    );
  }

  /** Path part for operation `functionalPositionFindById()` */
  static readonly FunctionalPositionFindByIdPath = '/functional-position/{id}';

  /**
   * Find functional position by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindById$Response(params: FunctionalPositionFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<FunctionalPositionDto>> {
    return functionalPositionFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find functional position by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindById(params: FunctionalPositionFindById$Params, context?: HttpContext): Observable<FunctionalPositionDto> {
    return this.functionalPositionFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FunctionalPositionDto>): FunctionalPositionDto => r.body)
    );
  }

  /** Path part for operation `functionalPositionFindFirstUserByRelationType()` */
  static readonly FunctionalPositionFindFirstUserByRelationTypePath = '/functional-position/{id}/first-user-by-relation-type/{userFuncPosRelationType}';

  /**
   * Find first user for functional position by relation type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionFindFirstUserByRelationType()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindFirstUserByRelationType$Response(params: FunctionalPositionFindFirstUserByRelationType$Params, context?: HttpContext): Observable<StrictHttpResponse<UserDto>> {
    return functionalPositionFindFirstUserByRelationType(this.http, this.rootUrl, params, context);
  }

  /**
   * Find first user for functional position by relation type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionFindFirstUserByRelationType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindFirstUserByRelationType(params: FunctionalPositionFindFirstUserByRelationType$Params, context?: HttpContext): Observable<UserDto> {
    return this.functionalPositionFindFirstUserByRelationType$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserDto>): UserDto => r.body)
    );
  }

  /** Path part for operation `functionalPositionFindAllByUser()` */
  static readonly FunctionalPositionFindAllByUserPath = '/functional-position/user';

  /**
   * Find all functional position instances of user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionFindAllByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindAllByUser$Response(params?: FunctionalPositionFindAllByUser$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FunctionalPositionDto>>> {
    return functionalPositionFindAllByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances of user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionFindAllByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindAllByUser(params?: FunctionalPositionFindAllByUser$Params|null|undefined, context?: HttpContext): Observable<Array<FunctionalPositionDto>> {
    return this.functionalPositionFindAllByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalPositionDto>>): Array<FunctionalPositionDto> => r.body)
    );
  }

  /** Path part for operation `functionalPositionSearchForUser()` */
  static readonly FunctionalPositionSearchForUserPath = '/functional-position/user/search-api';

  /**
   * Find all functional position instances of user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionSearchForUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionSearchForUser$Response(params?: FunctionalPositionSearchForUser$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFunctionalPositionDto>> {
    return functionalPositionSearchForUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances of user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionSearchForUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionSearchForUser(params?: FunctionalPositionSearchForUser$Params|null|undefined, context?: HttpContext): Observable<PageFunctionalPositionDto> {
    return this.functionalPositionSearchForUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFunctionalPositionDto>): PageFunctionalPositionDto => r.body)
    );
  }

  /** Path part for operation `functionalPositionSearch()` */
  static readonly FunctionalPositionSearchPath = '/functional-position/search-api';

  /**
   * Finds functional positions based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionSearch$Response(params?: FunctionalPositionSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFunctionalPositionDto>> {
    return functionalPositionSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds functional positions based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionSearch(params?: FunctionalPositionSearch$Params|null|undefined, context?: HttpContext): Observable<PageFunctionalPositionDto> {
    return this.functionalPositionSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFunctionalPositionDto>): PageFunctionalPositionDto => r.body)
    );
  }

  /** Path part for operation `functionalPositionSearchParentChildrenTree()` */
  static readonly FunctionalPositionSearchParentChildrenTreePath = '/functional-position/parent/{parentId}/search-api';

  /**
   * Find all functional position instances that have direct or indirect parent functional position with id {parentId}
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionSearchParentChildrenTree()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionSearchParentChildrenTree$Response(params: FunctionalPositionSearchParentChildrenTree$Params, context?: HttpContext): Observable<StrictHttpResponse<PageFunctionalPositionDto>> {
    return functionalPositionSearchParentChildrenTree(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances that have direct or indirect parent functional position with id {parentId}
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionSearchParentChildrenTree$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionSearchParentChildrenTree(params: FunctionalPositionSearchParentChildrenTree$Params, context?: HttpContext): Observable<PageFunctionalPositionDto> {
    return this.functionalPositionSearchParentChildrenTree$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFunctionalPositionDto>): PageFunctionalPositionDto => r.body)
    );
  }

  /** Path part for operation `functionalPositionGetFunctionalPositionsWithSubstitutions()` */
  static readonly FunctionalPositionGetFunctionalPositionsWithSubstitutionsPath = '/functional-position/functional-position-with-substitution/user';

  /**
   * Find all functional position instances that have direct or indirect parent functional position with id {parentId}
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionGetFunctionalPositionsWithSubstitutions()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionGetFunctionalPositionsWithSubstitutions$Response(params?: FunctionalPositionGetFunctionalPositionsWithSubstitutions$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FunctionalPositionWithSubstitutionDto>>> {
    return functionalPositionGetFunctionalPositionsWithSubstitutions(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances that have direct or indirect parent functional position with id {parentId}
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionGetFunctionalPositionsWithSubstitutions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionGetFunctionalPositionsWithSubstitutions(params?: FunctionalPositionGetFunctionalPositionsWithSubstitutions$Params|null|undefined, context?: HttpContext): Observable<Array<FunctionalPositionWithSubstitutionDto>> {
    return this.functionalPositionGetFunctionalPositionsWithSubstitutions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FunctionalPositionWithSubstitutionDto>>): Array<FunctionalPositionWithSubstitutionDto> => r.body)
    );
  }

  /** Path part for operation `functionalPositionFindByCode()` */
  static readonly FunctionalPositionFindByCodePath = '/functional-position/code/{code}';

  /**
   * Find functional position instances by code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `functionalPositionFindByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindByCode$Response(params: FunctionalPositionFindByCode$Params, context?: HttpContext): Observable<StrictHttpResponse<FunctionalPositionDto>> {
    return functionalPositionFindByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Find functional position instances by code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `functionalPositionFindByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  functionalPositionFindByCode(params: FunctionalPositionFindByCode$Params, context?: HttpContext): Observable<FunctionalPositionDto> {
    return this.functionalPositionFindByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<FunctionalPositionDto>): FunctionalPositionDto => r.body)
    );
  }

}
