import {ObjectClass} from '|api/commons';

export interface VisitedObject {
  objectClass: ObjectClass;
  objectId: number;
  objectName: string;
}

export interface HistoryBit {
  sessionId: string; // UUID containing the ID of current session. Each session begins by application bootstrap.
  label: string;
  labelParts?: string[];
  date: Date;
  url: string;
  completeUrl: string;
  visitedObject: Nullable<VisitedObject>;
  canBeFavourite: boolean;
  isRootLevelRoute: boolean;
  isRerouteFromHistory: boolean;
  isAwaitingAlias: boolean; // transient property - used for indicating that alias label should appear in a moment
}

export function areHistoryBitsSame(hb1: Nullable<HistoryBit>, hb2: Nullable<HistoryBit>) {
  return hb1?.url === hb2?.url;
}
