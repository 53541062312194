import {Pipe, PipeTransform} from '@angular/core';

// Either has both idSource and identifier non-null or both null.
//  uid is null if both idSource AND identifier are null.
export interface UniquelyIdentifiable {
  idSource?: Nullable<string>;
  identifier?: Nullable<string>;
  uid?: Nullable<string>; // computed concatenation "idSource+identifier", exists only in some UniquelyIdentifiables
}

@Pipe({
  name: 'formatIdentifier',
  pure: true, // expects that input value is a DTO from BE and not mutated during consumer component lifecycle
})
export class FormatIdentifierPipe implements PipeTransform {

  transform(uniquelyIdentifiable: Nullable<UniquelyIdentifiable>): string {
    if (uniquelyIdentifiable?.uid && typeof(uniquelyIdentifiable?.uid) === 'string') {
      return uniquelyIdentifiable.uid;
    }
    else {
      return (uniquelyIdentifiable?.idSource ?? '') + (uniquelyIdentifiable?.identifier ?? '');
    }
  }

}
