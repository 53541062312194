import {EsslComponentDto, EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {IczTableDataSource} from '../../table/table.datasource';
import {FilterParam} from '../../../services/search-api.service';

export class EsslComponentsTableDatasource extends IczTableDataSource<EsslComponentDto> {
  constructor(
    searchService: EsslComponentSearchService,
    documentId: number,
    additionalFilters?: FilterParam[]
  ) {
    super(searchParams => searchService.findEsslComponents(searchParams, documentId, additionalFilters));
  }
}
