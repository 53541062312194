import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {of, OperatorFunction, switchMap} from 'rxjs';
import {Page} from '../api';
import {TypedSearchRecordDto} from './search-api.service';
import {EntityType, RelatedObjectDto, SubjectRecordWithRelationsDto} from '|api/commons';
import {
  entityTypeToRelatedObjectType,
  relatedObjectClassToRelatedObjectType
} from '../components/shared-business-components/shared-document.utils';
import {map} from 'rxjs/operators';
import {constructSubjectName} from '../components/shared-business-components/model/subjects.model';
import {EntityWithRepresentingSubject} from './document-search.service';

enum SearchRepresentingSubjectForEntity {
  ENTITY_ITSELF = 'ENTITY_ITSELF',
  RELATED_OBJECT_BY_OBJECT_CLASS = 'RELATED_OBJECT_BY_OBJECT_CLASS',
}

export function extendEsslObjectsWithRepresenting(apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,):
  OperatorFunction<Page<TypedSearchRecordDto<any & EntityWithRepresentingSubject>>, Page<TypedSearchRecordDto<any & EntityWithRepresentingSubject>>> {
  return switchMap(res => {
    if (!res.content) return of(res);
    let findRelations: Array<RelatedObjectDto>;
    let searchMode: SearchRepresentingSubjectForEntity;

    if (res.content.every(d => d.source.entityType === EntityType.CIRCULATION_ACTIVITY || d.source.entityType === EntityType.CIRCULATION_TASK)) {
      searchMode = SearchRepresentingSubjectForEntity.RELATED_OBJECT_BY_OBJECT_CLASS;
      findRelations = res.content!.map(d => {
        return {
          relatedObjectId: d.source.documentId ?? d.source.fileId,
          relatedObjectType: relatedObjectClassToRelatedObjectType(d.source.objectClass!)!
        };
      });
    } else {
      searchMode = SearchRepresentingSubjectForEntity.ENTITY_ITSELF;
      findRelations = res.content!.map(d => {
        return {
          relatedObjectId: d.source.id,
          relatedObjectType: entityTypeToRelatedObjectType(d.source.entityType!)!
        };
      });
    }

    return apiSubjectRecordNgElasticService.subjectRecordElasticGetRepresentingSubjects({body: findRelations}).pipe(map((subjectsWithRelations: Array<SubjectRecordWithRelationsDto>) => {
      res.content.forEach(c => {
        let relatedSubject: Nullable<SubjectRecordWithRelationsDto> = null;

        if (searchMode === SearchRepresentingSubjectForEntity.RELATED_OBJECT_BY_OBJECT_CLASS) {
          relatedSubject = subjectsWithRelations.find(s => s.objectRelations!.find(r => {
            return (r.relatedObjectId === c.source.documentId || r.relatedObjectId === c.source.fileId) &&
              r.relatedObjectType === relatedObjectClassToRelatedObjectType(c.source.objectClass!)!; }
          ));
        } else if (searchMode === SearchRepresentingSubjectForEntity.ENTITY_ITSELF) {
          relatedSubject = subjectsWithRelations.find(s => s.objectRelations!.find(r => {
            return r.relatedObjectId === c.source.id && r.relatedObjectType === entityTypeToRelatedObjectType(c.source.entityType!)!; }
          ));
        }

        if (relatedSubject) {
          const representingRelation = relatedSubject?.objectRelations!.find(r => Boolean(r.representing));
          if (representingRelation) {
            c.source.representingSubject = constructSubjectName(relatedSubject, true);
          }
        }
      });

      return res;
    }));
  });
}
