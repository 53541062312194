import {AfterViewInit, ChangeDetectorRef, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {differenceBy} from 'lodash';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {filter, finalize, map, switchMap} from 'rxjs/operators';
import {VisibleSignatureDto, VisibleSignaturePage, VisibleSignaturePosition} from '|api/commons';
import {DigitalComponentCompleteDto, DocumentSignResponse} from '|api/component';
import {ApiSignatureService} from '|api/document';
import {TabsComponent} from '../../../essentials/tabs/tabs.component';
import {IczModalService} from '../../../../services/icz-modal.service';
import {
  ApplicationConfigService,
  SignatureCertificateLocation,
  SignaturePlaceTimestamp
} from '../../../../core/services/config/application-config.service';
import {IdtGetCertListResData, IdtService} from '../../../../core/services/idt/idt.service';
import {IdtConnectionState, IdtLinkService} from '../../../../core/services/idt/idt-link.service';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {ConfigPropValueService} from '../../../../services/config-prop-value.service';
import {
  InProgressSignature,
  SignMarkTimestampService,
  VisibleSignatureSettings
} from '../../../../services/sign-mark-timestamp.service';
import {
  EsslComponentToastService,
  EsslComponentToastType
} from '../../../../core/services/notifications/essl-component-toast.service';
import {TabItem} from '../../../essentials/tabs/tabs.component.model';
import {Option} from '../../../../model';
import {isWindowsOs} from '../../../../lib/utils';
import {Idt2Certificate, Idt2CertificateProvider} from '../../../../core/services/idt/idt.typedefs';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {getLatestDigitalComponentVersion} from '../../shared-document.utils';
import {iczFormatDate} from '../../../essentials/date.pipe';
import {ApplicationLanguage} from '../../../../core/services/environment.models';
import {
  DigitalComponentWithResult,
  isBulkComponentSignResponse,
  SignatureConfigurationDialogData
} from '../../essl-components/models/signature-dialog.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {InternalNotificationKey} from '|api/notification';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';


enum CertificateSourceEnum {
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
}

enum SignatureMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

enum LoadingId {
  SIGN_DIGITAL_COMPONENTS = 'SIGN_DIGITAL_COMPONENTS',
  IDT_INSTALLED = 'IDT_INSTALLED',
  IDT_RETRYING = 'IDT_RETRYING',
}

enum ReasonPlaceFillMode {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
}

/*
  Signature flow step by step
  ------------------------------
  1. Getting certificate chain from certificate using IDT
  2. Digest digitalComponents - digitalComponents are send to BACKEND to digest
  3. Sign digitalComponents IDT - digitalComponents will be signed using IDT
  4. Signature to BE - signature for each digitalComponents is send to BACKEND
*/

/*
  IDT
  ------------------------------
  1. IDT is a tool, which is installed on the computer and communicates with website applications using WebSocket.
  2. It is used for signing and verifying digital signatures.
  3. It is based on Java, so Java need to be installed on the computer.
  4. IDT doesnt work well with MacOS and Linux
  5. IDT works good with Windows
  6. Demo for IDT can be found on /demo/idt page in application
*/

/*
  Signature type
  ------------------------------
  1. Not visual - digitalComponent is signed, but signature is not visible on digitalComponent
  2. Visual - digitalComponent is signed, and signature is visible on digitalComponent
*/

/*
  Visual signature position
  ------------------------------
  1. Automatic - position is calculated automatically by BACKEND and will be placed on digitalComponent
  2. Manual - user will drag and drop signature on digitalComponent visually with mouse
*/


@Component({
  selector: 'icz-digital-component-signature-configuration-dialog',
  templateUrl: './digital-component-signature-configuration-dialog.component.html',
  styleUrls: ['./digital-component-signature-configuration-dialog.component.scss'],
})
export class DigitalComponentSignatureConfigurationDialogComponent implements OnInit, AfterViewInit {

  protected modalRef = injectModalRef<unknown>();
  protected modalService = inject(IczModalService);
  protected applicationConfigService = inject(ApplicationConfigService);
  private idtService = inject(IdtService);
  private idtLinkService = inject(IdtLinkService);
  protected loadingService = inject(LoadingIndicatorService);
  private configPropValueService = inject(ConfigPropValueService);
  private signMarkTimestampService = inject(SignMarkTimestampService);
  private apiSignatureService = inject(ApiSignatureService);
  private translateService = inject(TranslateService);
  private esslComponentToastService = inject(EsslComponentToastService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  protected data = injectModalData<SignatureConfigurationDialogData<unknown>>();

  @ViewChild(TabsComponent) tabsComponent!: TabsComponent;

  /*
    Basic tab for signature configuration
    -------------------------------------
    1. If user can sign digitalComponent(s) visually, application will show tab for each digitalComponent
    to configure and drop visual signature
    2. If position of dropped signature is not valid, tab will show error icon, if signature is dropped to valid position, success icon is shown
   */
  tabs: TabItem[] = [
    {
      id: 'definition',
      label: 'Definice podpisu',
      showTabValidity: false,
    },
  ];

  tabsWithDigitalComponents: TabItem[] = [...this.tabs];

  readonly CertificateSourceEnum = CertificateSourceEnum;

  readonly SignatureMode = SignatureMode;

  readonly LoadingId = LoadingId;

  readonly SignaturePlaceTimestamp = SignaturePlaceTimestamp;

  localSourceTypeOptions: Option[] = (
    isWindowsOs() ?
      [{value: Idt2CertificateProvider.WINDOWS, label: `fe.ui.CertLocationProvider.${Idt2CertificateProvider.WINDOWS}`}] :
      [{value: Idt2CertificateProvider.FILE, label: `fe.ui.CertLocationProvider.${Idt2CertificateProvider.FILE}`}]
  );

  signatureForm: IczFormGroup = new IczFormGroup({
    isVisualSignature: new IczFormControl<Nullable<boolean>>(false),
    isPositionSelectedFromOptions: new IczFormControl<Nullable<boolean>>(true),
    certificateSource: new IczFormControl<Nullable<CertificateSourceEnum>>(null, [IczValidators.required()]),
    // Default validator is required because default value for provider is WINDOWS
    validCertificate: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    provider: new IczFormControl<Nullable<Idt2CertificateProvider>>(isWindowsOs() ? Idt2CertificateProvider.WINDOWS : Idt2CertificateProvider.FILE, [IczValidators.required()]),
    password: new IczFormControl<Nullable<string>>(null),
    signatureMode: new IczFormControl<Nullable<SignatureMode>>(SignatureMode.AUTOMATIC, [IczValidators.required()]),
    page: new IczFormControl<Nullable<VisibleSignaturePage>>(VisibleSignaturePage.FIRST, [IczValidators.required]),
    position: new IczFormControl<Nullable<VisibleSignaturePosition>>(VisibleSignaturePosition.BOTTOM_RIGHT, [IczValidators.required]),
    positionX: new IczFormControl<Nullable<number>>(0, [IczValidators.required]),
    positionY: new IczFormControl<Nullable<number>>(0, [IczValidators.required]),
    reason: new IczFormControl<Nullable<string>>(null),
    place: new IczFormControl<Nullable<string>>(null),
    reasonPlaceFillMode: new IczFormControl<Nullable<ReasonPlaceFillMode>>(ReasonPlaceFillMode.AUTOMATIC, [IczValidators.required()]),
  });

  private digitalComponentsWithManualSignatureInProgress!: InProgressSignature[];
  digitalComponentsWithManualSignatureInProgress$ = this.signMarkTimestampService.digitalComponentsWithManualSignatureInProgress$;
  idtStatus = IdtConnectionState.INITIATING;

  certificateList$ = new BehaviorSubject<Nullable<IdtGetCertListResData>>(null);

  certificateListOptions$: Observable<Option<Idt2Certificate>[]> = this.certificateList$.pipe(
    filter(Boolean),
    map((x: IdtGetCertListResData) => {
      const selectedProvider = this.providerTypeFormControl.value as Idt2CertificateProvider;
      // limit to time valid certificates: notBefore<=today<=notAfter
      const today = new Date();
      const options = x.locations
        .filter(locationItem => locationItem.location.provider === selectedProvider)
        .filter(locationItem => (new Date(locationItem.notBefore) <= today && today <= new Date(locationItem.notAfter)))
        .map(locationItem => ({value: locationItem, label: locationItem.subject!, data: locationItem}));
      return options;
    })
  );

  private providerValueChanged$ = this.providerTypeFormControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef));

  get globalReason() {
    return this.signatureForm.get('reason')!.value;
  }

  get globalPlace() {
    return this.signatureForm.get('place')!.value;
  }

  get isSignButtonWithoutVisualSignatureVisible() {
    return this.idtStatus && !this.isManualSignatureActive && this.isCertificateSourceSelected;
  }

  get isPositionSelectedFromOptions(): boolean {
    return this.signatureForm.get('isPositionSelectedFromOptions')!.value;
  }

  get visualSignatureControl() {
    return this.signatureForm.get('isVisualSignature')!;
  }

  get isVisualSignature(): boolean {
    return this.visualSignatureControl.value;
  }

  get documentId(): number {
    return this.data.documentId;
  }

  get reasonPlaceFillModeControl() {
    return this.signatureForm.get('reasonPlaceFillMode')!;
  }

  get isVisualSignatureAvailable() {
    return this.numberOfWithoutVisualSignatureOption !== this.allDigitalComponents.length;
  }

  get providerTypeFormControl() {
    return this.signatureForm.get('provider')!;
  }

  get areAllTabsVisible() {
    return this.isManualSignatureActive && this.isCertificateSelected && this.isVisualSignature;
  }

  get allTabs() {
    return this.areAllTabsVisible ? this.tabsWithDigitalComponents : this.tabs;
  }

  get signableWithVisualSignatureOption() {
    return this.data.signableWithVisualSignatureOption ?? [];
  }

  get signableWithoutVisualSignatureOption() {
    return this.data.signableWithoutVisualSignatureOption ?? [];
  }

  get numberOfWithoutVisualSignatureOption() {
    return this.signableWithoutVisualSignatureOption.length;
  }

  get isMultipleOfWithVisualSignatureOption() {
    return this.signableWithVisualSignatureOption.length > 1;
  }

  get allDigitalComponents(): DigitalComponentWithResult[] {
    return [...this.signableWithVisualSignatureOption, ...this.signableWithoutVisualSignatureOption];
  }

  get certificateSourceControl() {
    return this.signatureForm.get('certificateSource')!;
  }

  get isLocalCertificateSourceSelected() {
    return this.certificateSourceControl.value === CertificateSourceEnum.LOCAL;
  }

  get isCertificateSourceSelected() {
    return Boolean(this.certificateSourceControl.value);
  }

  get isManualSignatureActive() {
    return this.signatureForm.get('signatureMode')!.value === SignatureMode.MANUAL;
  }

  get isBulkOperation() {
    return this.data.isBulkOperation;
  }

  // Signature can be placed with or without timestamp
  get manualSignatureButtonText() {
    return this.isBulkOperation ? 'Umístit podpisy ručně s časovým razítkem' : 'Umístit podpis ručně s časovým razítkem';
  }

  get manualSignatureButtonTextWithoutTimestamp() {
    return this.isBulkOperation ? 'Umístit podpisy ručně bez časového razítka' : 'Umístit podpis ručně bez časového razítka';
  }

  get currentCertificate() {
    return this.signatureForm.value.validCertificate;
  }

  get isCertificateSelected() {
    return !isNil(this.signatureForm.value.validCertificate);
  }

  get isFileCertificateSelected() {
    return (
      this.providerTypeFormControl.value === Idt2CertificateProvider.FILE
    );
  }

  readonly IdtConnectionState = IdtConnectionState;

  handleTabClick(tab: TabItem) {
    this.signatureForm.updateValueAndValidity();
    this.cd.detectChanges();
  }

  closeDialog(signResponse?: unknown) {
    this.modalRef.close(signResponse);
  }

  handleSignWithManualSignaturePosition(assignTimestamp: boolean) {
    if(!this.areAllManualSignaturesValid()) return;
    this.handleSign(assignTimestamp, this.digitalComponentsWithManualSignatureInProgress);
  }

  getCommonVisualSignatureSettings() : VisibleSignatureSettings {
    return {
      ...this.getVisibleSignatureDto(),
      reason: this.globalReason,
      place: this.globalPlace,
      isVisualSignature: this.isVisualSignature,
      isPositionSelectedFromOptions: this.isPositionSelectedFromOptions,
    };
  }

  updateTabsOnSign() {
    if (this.tabsWithDigitalComponents.filter(tab => tab.id !== 'definition').find(tab => !tab.showTabValidity || !tab.valid)) {
      this.tabsWithDigitalComponents = this.tabsWithDigitalComponents.map(tab => {
        if (tab.id === 'definition') return tab;
        else return {...tab, showTabValidity: true};
      });
    }
  }

  /**
   * Handles sign action. Does some data preparation before sending to SignMarkTimeStamp service in the case of manual signature
   * @param assignTimestamp
   * @param digitalComponentsWithManualSignature
   */
  handleSign(assignTimestamp: boolean, digitalComponentsWithManualSignature?: InProgressSignature[]) {
    this.signatureForm.updateValueAndValidity();

    let digitalComponentsWithoutManualSignature: DigitalComponentWithResult[] = [];

    if (digitalComponentsWithManualSignature) {
      this.updateTabsOnSign();

      const withManualSignatureAsCompleteDtos: DigitalComponentCompleteDto[] = digitalComponentsWithManualSignature!.map(a => a.digitalComponent);

      digitalComponentsWithoutManualSignature = differenceBy(this.allDigitalComponents, withManualSignatureAsCompleteDtos, 'id');
    } else {
      digitalComponentsWithoutManualSignature = this.allDigitalComponents;
    }

    const visibleSignatureSettings = this.getCommonVisualSignatureSettings();
    const password = this.signatureForm.get('password')!.value;

    this.loadingService.doLoading(
      this.signMarkTimestampService.getSignatureRequest(
        digitalComponentsWithoutManualSignature,
        digitalComponentsWithManualSignature ?? [],
        this.documentId,
        assignTimestamp,
        this.currentCertificate,
        visibleSignatureSettings,
        password,
      ).pipe(
        switchMap(signRequest => {
          if (signRequest) {
            return this.data.signRequestFn(this.documentId, signRequest);
          }
          else {
            return of(null);
          }
        })
      ),
      this,
      LoadingId.SIGN_DIGITAL_COMPONENTS
    ).subscribe({
      next: response => {
        if (response) {
          const signResponse = this.data.signResponseSelector(response);
          if (!isBulkComponentSignResponse(signResponse)) {
            this.esslComponentToastService.dispatchSignDigitalComponentsToasts(
              digitalComponentsWithoutManualSignature,
              (digitalComponentsWithManualSignature ?? []),
              this.documentId,
              (signResponse as DocumentSignResponse).signResult
            );
          } else {
            let count = 0;
            if (digitalComponentsWithoutManualSignature && digitalComponentsWithoutManualSignature.length > 0) {
              count = digitalComponentsWithoutManualSignature.length;
            } else if (digitalComponentsWithManualSignature && digitalComponentsWithManualSignature.length > 0) {
              count = digitalComponentsWithManualSignature.length;
            }
            this.esslComponentToastService.dispatchBulkComponentSignTask({[InternalNotificationKey.COUNT]: String(count)});
          }
          this.closeDialog(response);
        }
      },
      error: _ => {
        this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.SIGN_FILE_ERROR);
      }
    });
  }

  private getVisibleSignatureDto(): VisibleSignatureDto {
    const {page, position, positionX, positionY} = this.signatureForm.value;
    return {page, position, positionX, positionY};
  }

  private setDigitalComponentsTabs() {
    const digitalComponentTabs: TabItem[] = this.signableWithVisualSignatureOption.map(digitalComponent => {
      const {id} = digitalComponent;
      const fileName = getLatestDigitalComponentVersion(digitalComponent)!.fileName;
      return {label: fileName!, id: id!, icon: 'attachment_pdf', description: digitalComponent.description, showTabValidity: false, valid: false};
    });
    this.tabsWithDigitalComponents.push(...digitalComponentTabs);
  }

  private updateDigitalComponentTabs(list: InProgressSignature[]) {
    this.tabsWithDigitalComponents.forEach(tab => {
      list.forEach(item => {
        if (tab.id === item.digitalComponent.id) {
          tab.showTabValidity = item.showValidity;
          tab.valid = item.isReadyForSign;
        }
      });
    });
  }

  /*
    When user opens dialog, application will check if his IDT is running or is installed
    If doesn't, it will show button to download IDT
  */
  private checkIfIdtIsInstalled() {
    this.idtLinkService.idtConnectionStatus$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        finalize(() => this.loadingService.endLoading(this, LoadingId.IDT_RETRYING))
      )
      .subscribe(status => {
        this.idtStatus = status;
      });
  }

  /*
    IDT will return list of certificates, which are available for user to choose
  */
  private getCertificateListFromIDT() {
    this.idtService
      .getCertList({eidas: true})
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(certList => this.certificateList$.next(certList));
  }

  ngAfterViewInit() {
    this.setDigitalComponentsTabs();
  }

  ngOnInit() {
    const certificateSourceControl = this.signatureForm.get('certificateSource')!;

    switch (this.applicationConfigService.defaultSignatureCertificateLocation) {
      case SignatureCertificateLocation.LOCAL:
        certificateSourceControl.setValue(CertificateSourceEnum.LOCAL);
        break;
      case SignatureCertificateLocation.REMOTE:
        certificateSourceControl.setValue(CertificateSourceEnum.REMOTE);
        break;
    }

    this.checkIfIdtIsInstalled();
    this.getCertificateListFromIDT();
    this.signMarkTimestampService.clearListWithAttachmensInManualSignatureProgress();
    this.digitalComponentsWithManualSignatureInProgress$.subscribe(list => {
      this.digitalComponentsWithManualSignatureInProgress = list;
      this.updateDigitalComponentTabs(list);
    });

    this.providerValueChanged$.subscribe(_ => this.getCertificateListFromIDT());
  }

  getCertificateAdditionalInfo(cert: Idt2Certificate) {
    let additionalInfo = this.translateService.instant('Typ') + ': ';

    if (cert.signature) {
      additionalInfo += this.translateService.instant('Podpis');
    } else if (cert.seal) {
      additionalInfo += this.translateService.instant('Pečeť');
    } else {
      additionalInfo += this.translateService.instant('neznámý');
    }

    additionalInfo += ', ' + this.translateService.instant('Platnost do') + ': ' + iczFormatDate(this.translateService.currentLang as ApplicationLanguage, new Date(cert.notAfter));
    additionalInfo += ', SSCD: ';
    if (cert.sscd) {
      additionalInfo += this.translateService.instant('Ano');
    } else {
      additionalInfo += this.translateService.instant('Ne');
    }

    return additionalInfo;
  }

  parseNaming(subject: string) {
    let name = '';
    let org = '';
    let country = '';
    let ou = '';

    const csv = subject.split(',');
    let result = '';
    csv.forEach(value => {
      if (value.includes('CN=')) {
        name = value.split('=')[1];
      }

      if (value.includes('O=')) {
        org = value.split('=')[1];
      }

      if (value.includes('C=')) {
        country = value.split('=')[1];
      }

      if (value.includes('OU=')) {
        ou = value.split('=')[1];
      }
    });

    result = name;
    result += ou !== '' ? ', ' + ou : '';
    result += org !== '' ? ', ' + org : '';
    result += country !== '' ? ', ' + country : '';

    return result;
  }

  private areAllManualSignaturesValid() {
    return this.digitalComponentsWithManualSignatureInProgress.every(dc => dc.isReadyForSign);
  }
}
