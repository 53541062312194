<div class="wrapper" cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">

  <div class="row grow" (click.stop)="addressClick()">
    <icz-address-details class="grow" [addressWithType]="getBusinessSortedAddresses(subject)?.[0]"></icz-address-details>
    @if (hasMultipleAddresses) {
      <icz-icon svgIcon="expand_more" class="mr-4"></icz-icon>
    }
  </div>

  <icz-popover [isOpen]="showPopover"
    [overlayOrigin]="overlayOrigin"
    (onClose)="showPopover = false">
    @for (opt of options; track opt.value) {
      <icz-option-item
        [option]="opt"
        [listItemTemplate]="optionTmpl"
      ></icz-option-item>
    }

    <ng-template let-context #optionTmpl>
      <icz-address-details [addressWithType]="context.option?.data?.addressWithType" (click)="showPopover = !showPopover"></icz-address-details>
    </ng-template>
  </icz-popover>
</div>
