import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {UserSettingsService} from '../../../services/user-settings.service';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {hashed} from '../../../lib/utils';
import {IconSize} from '../icon/icon.component';

@Component({
  selector: 'icz-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SectionComponent implements IczOnChanges {

  private userSettings = inject(UserSettingsService);
  private elRef = inject(ElementRef);

  @Input({required: true}) label!: string;
  @Input() svgIcon: Nullable<string>;
  @Input() svgIconSize: Nullable<IconSize> = 'default';
  @Input() showExpansionHint = false;
  @Input() expanded = true;
  @Input() maxContentHeight? = 'auto'; // Possible values are CSS units (eg. '250px') or 'auto'. If 'auto', section content won't display y scrollbar, else it will
  @Input() headerHeight = 40; // px, default ~ $form-vertical-modulus from SCSS
  _sectionId: Nullable<string>;

  @Input()
  storeClosedState = true;

  @HostBinding('class.boxed')
  @Input()
  set boxed(value: boolean | '') { this._boxed = value === '' ? true : value; }
  get boxed() { return this._boxed; }
  _boxed = false;

  @HostBinding('class.no-padding')
  @Input()
  set noPadding(value: boolean | '') { this._noPadding = value === '' ? true : value; }
  get noPadding() { return this._noPadding; }
  _noPadding = false;

  @Input()
  set alwaysOpened(value: boolean | '') { this._alwaysOpened = value === '' ? true : value; }
  get alwaysOpened() { return this._alwaysOpened; }
  _alwaysOpened = false;

  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  private _openCalled = false;
  private _closeCalled = false;

  onOpened() {
    this._openCalled = true;
    this.expanded = true;
    this.opened.emit();

    setTimeout(() => {
      this._openCalled = false;
    });
  }

  onClosed() {
    this._closeCalled = true;
    this.expanded = false;
    this.closed.emit();

    setTimeout(() => {
      this._closeCalled = false;
    });
  }

  click() {
    if (this._closeCalled) {
      this._closeCalled = false;
      if (this._sectionId) {
        this.userSettings.setSectionClosedState(this._sectionId, true);
      }
    }

    if (this._openCalled) {
      this._openCalled = false;
      if (this._sectionId) {
        this.userSettings.setSectionClosedState(this._sectionId, false);
      }
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.label && changes.label.currentValue && this.storeClosedState) {
      const path: string[] = [changes.label.currentValue];
      this.createPathId(this.elRef.nativeElement, path);
      this._sectionId = hashed({sectionId: path.join('_')});
      const closedState = this.userSettings.getSectionClosedState(this._sectionId);
      if (!isNil(closedState)) {
        this.expanded = !closedState;
      }
    }
  }

  createPathId(el: any, path: string[]) {
    if ( (el.nodeName as string).startsWith('ICZ') ) {
      path.push(el.nodeName);
    }

    if (el.parentElement !== null && el.parentElement.nodeName !== 'BODY') {
      this.createPathId(el.parentElement, path);
    }
  }

}
