import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'icz-not-implemented',
  template: `{{'Zde bude v další iteraci' | translate}}.`,
  styleUrls: ['./not-implemented.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotImplementedComponent {
}
