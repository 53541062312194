/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DigitalComponentVersionCompleteDto } from '../models/digital-component-version-complete-dto';
import { digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds } from '../fn/digital-component-version/digital-component-version-delete-drafts-by-digital-component-version-ids';
import { DigitalComponentVersionDeleteDraftsByDigitalComponentVersionIds$Params } from '../fn/digital-component-version/digital-component-version-delete-drafts-by-digital-component-version-ids';
import { digitalComponentVersionDownloadSpecificVersionOfFile } from '../fn/digital-component-version/digital-component-version-download-specific-version-of-file';
import { DigitalComponentVersionDownloadSpecificVersionOfFile$Params } from '../fn/digital-component-version/digital-component-version-download-specific-version-of-file';
import { digitalComponentVersionExtractComponentsFromContainers } from '../fn/digital-component-version/digital-component-version-extract-components-from-containers';
import { DigitalComponentVersionExtractComponentsFromContainers$Params } from '../fn/digital-component-version/digital-component-version-extract-components-from-containers';
import { digitalComponentVersionGetById } from '../fn/digital-component-version/digital-component-version-get-by-id';
import { DigitalComponentVersionGetById$Params } from '../fn/digital-component-version/digital-component-version-get-by-id';
import { digitalComponentVersionUploadNewEmailFile } from '../fn/digital-component-version/digital-component-version-upload-new-email-file';
import { DigitalComponentVersionUploadNewEmailFile$Params } from '../fn/digital-component-version/digital-component-version-upload-new-email-file';
import { digitalComponentVersionUploadNewFile } from '../fn/digital-component-version/digital-component-version-upload-new-file';
import { DigitalComponentVersionUploadNewFile$Params } from '../fn/digital-component-version/digital-component-version-upload-new-file';
import { DigitalComponentVersionValidatedDto } from '../models/digital-component-version-validated-dto';


/**
 * Controller for Digital components Version
 */
@Injectable({ providedIn: 'root' })
export class ApiDigitalComponentVersionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `digitalComponentVersionUploadNewFile()` */
  static readonly DigitalComponentVersionUploadNewFilePath = '/component/digital-component-version';

  /**
   * Upload new file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentVersionUploadNewFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentVersionUploadNewFile$Response(params?: DigitalComponentVersionUploadNewFile$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionCompleteDto>> {
    return digitalComponentVersionUploadNewFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload new file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentVersionUploadNewFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentVersionUploadNewFile(params?: DigitalComponentVersionUploadNewFile$Params|null|undefined, context?: HttpContext): Observable<DigitalComponentVersionCompleteDto> {
    return this.digitalComponentVersionUploadNewFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentVersionCompleteDto>): DigitalComponentVersionCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentVersionUploadNewEmailFile()` */
  static readonly DigitalComponentVersionUploadNewEmailFilePath = '/component/digital-component-version/upload-email-file';

  /**
   * Upload new email file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentVersionUploadNewEmailFile()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentVersionUploadNewEmailFile$Response(params: DigitalComponentVersionUploadNewEmailFile$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionValidatedDto>> {
    return digitalComponentVersionUploadNewEmailFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Upload new email file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentVersionUploadNewEmailFile$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  digitalComponentVersionUploadNewEmailFile(params: DigitalComponentVersionUploadNewEmailFile$Params, context?: HttpContext): Observable<DigitalComponentVersionValidatedDto> {
    return this.digitalComponentVersionUploadNewEmailFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentVersionValidatedDto>): DigitalComponentVersionValidatedDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentVersionExtractComponentsFromContainers()` */
  static readonly DigitalComponentVersionExtractComponentsFromContainersPath = '/component/digital-component-version/extract-container';

  /**
   * Extract components from existing containers
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentVersionExtractComponentsFromContainers()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentVersionExtractComponentsFromContainers$Response(params: DigitalComponentVersionExtractComponentsFromContainers$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentVersionExtractComponentsFromContainers(this.http, this.rootUrl, params, context);
  }

  /**
   * Extract components from existing containers
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentVersionExtractComponentsFromContainers$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentVersionExtractComponentsFromContainers(params: DigitalComponentVersionExtractComponentsFromContainers$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentVersionExtractComponentsFromContainers$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentVersionGetById()` */
  static readonly DigitalComponentVersionGetByIdPath = '/component/digital-component-version/{digitalComponentVersionId}';

  /**
   * Retrieves digital component version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentVersionGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentVersionGetById$Response(params: DigitalComponentVersionGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionCompleteDto>> {
    return digitalComponentVersionGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves digital component version
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentVersionGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentVersionGetById(params: DigitalComponentVersionGetById$Params, context?: HttpContext): Observable<DigitalComponentVersionCompleteDto> {
    return this.digitalComponentVersionGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentVersionCompleteDto>): DigitalComponentVersionCompleteDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentVersionDownloadSpecificVersionOfFile()` */
  static readonly DigitalComponentVersionDownloadSpecificVersionOfFilePath = '/component/digital-component-version/{digitalComponentVersionId}/download';

  /**
   * Download specific file by digital component version id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentVersionDownloadSpecificVersionOfFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentVersionDownloadSpecificVersionOfFile$Response(params: DigitalComponentVersionDownloadSpecificVersionOfFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentVersionDownloadSpecificVersionOfFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Download specific file by digital component version id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentVersionDownloadSpecificVersionOfFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentVersionDownloadSpecificVersionOfFile(params: DigitalComponentVersionDownloadSpecificVersionOfFile$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentVersionDownloadSpecificVersionOfFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds()` */
  static readonly DigitalComponentVersionDeleteDraftsByDigitalComponentVersionIdsPath = '/component/digital-component-version/delete-drafts';

  /**
   * Delete digital component drafts with related digital component versions and TDMS data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds$Response(params: DigitalComponentVersionDeleteDraftsByDigitalComponentVersionIds$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete digital component drafts with related digital component versions and TDMS data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds(params: DigitalComponentVersionDeleteDraftsByDigitalComponentVersionIds$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
