/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BulkFileClosePrepareDto } from '../models/bulk-file-close-prepare-dto';
import { BulkFileSettlementPrepareDto } from '../models/bulk-file-settlement-prepare-dto';
import { DisposalScheduleComputationResponseDto } from '../models/disposal-schedule-computation-response-dto';
import { fileBulkCloseFiles } from '../fn/file/file-bulk-close-files';
import { FileBulkCloseFiles$Params } from '../fn/file/file-bulk-close-files';
import { fileBulkInsertDocumentsIntoFile } from '../fn/file/file-bulk-insert-documents-into-file';
import { FileBulkInsertDocumentsIntoFile$Params } from '../fn/file/file-bulk-insert-documents-into-file';
import { fileBulkMoveDocumentsToAnotherFile } from '../fn/file/file-bulk-move-documents-to-another-file';
import { FileBulkMoveDocumentsToAnotherFile$Params } from '../fn/file/file-bulk-move-documents-to-another-file';
import { fileBulkremoveDocumentsFromFile } from '../fn/file/file-bulkremove-documents-from-file';
import { FileBulkremoveDocumentsFromFile$Params } from '../fn/file/file-bulkremove-documents-from-file';
import { fileBulkReopen } from '../fn/file/file-bulk-reopen';
import { FileBulkReopen$Params } from '../fn/file/file-bulk-reopen';
import { fileBulkSettle } from '../fn/file/file-bulk-settle';
import { FileBulkSettle$Params } from '../fn/file/file-bulk-settle';
import { fileBulkWithdrawSettle } from '../fn/file/file-bulk-withdraw-settle';
import { FileBulkWithdrawSettle$Params } from '../fn/file/file-bulk-withdraw-settle';
import { fileCloseFile } from '../fn/file/file-close-file';
import { FileCloseFile$Params } from '../fn/file/file-close-file';
import { fileCreate } from '../fn/file/file-create';
import { FileCreate$Params } from '../fn/file/file-create';
import { fileDeactivateFile } from '../fn/file/file-deactivate-file';
import { FileDeactivateFile$Params } from '../fn/file/file-deactivate-file';
import { FileDto } from '../models/file-dto';
import { fileFindByDocumentId } from '../fn/file/file-find-by-document-id';
import { FileFindByDocumentId$Params } from '../fn/file/file-find-by-document-id';
import { fileFindById } from '../fn/file/file-find-by-id';
import { FileFindById$Params } from '../fn/file/file-find-by-id';
import { fileFindUidByDocumentId } from '../fn/file/file-find-uid-by-document-id';
import { FileFindUidByDocumentId$Params } from '../fn/file/file-find-uid-by-document-id';
import { fileGetValidDisposalSchedules } from '../fn/file/file-get-valid-disposal-schedules';
import { FileGetValidDisposalSchedules$Params } from '../fn/file/file-get-valid-disposal-schedules';
import { fileInsertDocumentIntoFile } from '../fn/file/file-insert-document-into-file';
import { FileInsertDocumentIntoFile$Params } from '../fn/file/file-insert-document-into-file';
import { fileMoveDocumentToAnotherFile } from '../fn/file/file-move-document-to-another-file';
import { FileMoveDocumentToAnotherFile$Params } from '../fn/file/file-move-document-to-another-file';
import { filePrepareBulkSettlement } from '../fn/file/file-prepare-bulk-settlement';
import { FilePrepareBulkSettlement$Params } from '../fn/file/file-prepare-bulk-settlement';
import { filePrepareClose } from '../fn/file/file-prepare-close';
import { filePrepareClose_1 } from '../fn/file/file-prepare-close-1';
import { FilePrepareClose_1$Params } from '../fn/file/file-prepare-close-1';
import { FilePrepareClose$Params } from '../fn/file/file-prepare-close';
import { FilePrepareCompletionDto } from '../models/file-prepare-completion-dto';
import { filePrepareDeactivation } from '../fn/file/file-prepare-deactivation';
import { FilePrepareDeactivation$Params } from '../fn/file/file-prepare-deactivation';
import { filePrepareSettlement } from '../fn/file/file-prepare-settlement';
import { FilePrepareSettlement$Params } from '../fn/file/file-prepare-settlement';
import { fileProcessFilesForSettlementNotification } from '../fn/file/file-process-files-for-settlement-notification';
import { FileProcessFilesForSettlementNotification$Params } from '../fn/file/file-process-files-for-settlement-notification';
import { fileProcessFilesForSettlementResumeNotification } from '../fn/file/file-process-files-for-settlement-resume-notification';
import { FileProcessFilesForSettlementResumeNotification$Params } from '../fn/file/file-process-files-for-settlement-resume-notification';
import { FileProfileDto } from '../models/file-profile-dto';
import { fileReactivateFile } from '../fn/file/file-reactivate-file';
import { FileReactivateFile$Params } from '../fn/file/file-reactivate-file';
import { fileReadFileProfile } from '../fn/file/file-read-file-profile';
import { FileReadFileProfile$Params } from '../fn/file/file-read-file-profile';
import { fileRemoveDocumentFromFile } from '../fn/file/file-remove-document-from-file';
import { FileRemoveDocumentFromFile$Params } from '../fn/file/file-remove-document-from-file';
import { fileReopen } from '../fn/file/file-reopen';
import { FileReopen$Params } from '../fn/file/file-reopen';
import { fileSearch } from '../fn/file/file-search';
import { FileSearch$Params } from '../fn/file/file-search';
import { fileSettleFileByAcknowledgement } from '../fn/file/file-settle-file-by-acknowledgement';
import { FileSettleFileByAcknowledgement$Params } from '../fn/file/file-settle-file-by-acknowledgement';
import { fileSettleFileByAssignment } from '../fn/file/file-settle-file-by-assignment';
import { FileSettleFileByAssignment$Params } from '../fn/file/file-settle-file-by-assignment';
import { fileSettleFileByDocument } from '../fn/file/file-settle-file-by-document';
import { FileSettleFileByDocument$Params } from '../fn/file/file-settle-file-by-document';
import { fileSettleFileByRecord } from '../fn/file/file-settle-file-by-record';
import { FileSettleFileByRecord$Params } from '../fn/file/file-settle-file-by-record';
import { fileUpdate } from '../fn/file/file-update';
import { FileUpdate$Params } from '../fn/file/file-update';
import { fileUpdateFileProfile } from '../fn/file/file-update-file-profile';
import { FileUpdateFileProfile$Params } from '../fn/file/file-update-file-profile';
import { fileWithdrawSettledFile } from '../fn/file/file-withdraw-settled-file';
import { FileWithdrawSettledFile$Params } from '../fn/file/file-withdraw-settled-file';
import { PageFileDto } from '../models/page-file-dto';
import { PrepareDeactivationDto } from '../models/prepare-deactivation-dto';
import { UniqueIdentifierDto } from '|api/commons';


/**
 * API for management of files
 */
@Injectable({ providedIn: 'root' })
export class ApiFileService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fileFindById()` */
  static readonly FileFindByIdPath = '/document/file/{id}';

  /**
   * Find file with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileFindById$Response(params: FileFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDto>> {
    return fileFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find file with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileFindById(params: FileFindById$Params, context?: HttpContext): Observable<FileDto> {
    return this.fileFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileDto>): FileDto => r.body)
    );
  }

  /** Path part for operation `fileUpdate()` */
  static readonly FileUpdatePath = '/document/file/{id}';

  /**
   * File Update.
   *
   * Updates file's metadata.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileUpdate$Response(params: FileUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDto>> {
    return fileUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * File Update.
   *
   * Updates file's metadata.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileUpdate(params: FileUpdate$Params, context?: HttpContext): Observable<FileDto> {
    return this.fileUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileDto>): FileDto => r.body)
    );
  }

  /** Path part for operation `fileReadFileProfile()` */
  static readonly FileReadFileProfilePath = '/document/file/profile/{fileId}';

  /**
   * Returns the file's profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileReadFileProfile()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileReadFileProfile$Response(params: FileReadFileProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<FileProfileDto>> {
    return fileReadFileProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the file's profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileReadFileProfile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileReadFileProfile(params: FileReadFileProfile$Params, context?: HttpContext): Observable<FileProfileDto> {
    return this.fileReadFileProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileProfileDto>): FileProfileDto => r.body)
    );
  }

  /** Path part for operation `fileUpdateFileProfile()` */
  static readonly FileUpdateFileProfilePath = '/document/file/profile/{fileId}';

  /**
   * Updates a file's profile
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileUpdateFileProfile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileUpdateFileProfile$Response(params: FileUpdateFileProfile$Params, context?: HttpContext): Observable<StrictHttpResponse<FileProfileDto>> {
    return fileUpdateFileProfile(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a file's profile
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileUpdateFileProfile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileUpdateFileProfile(params: FileUpdateFileProfile$Params, context?: HttpContext): Observable<FileProfileDto> {
    return this.fileUpdateFileProfile$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileProfileDto>): FileProfileDto => r.body)
    );
  }

  /** Path part for operation `fileCreate()` */
  static readonly FileCreatePath = '/document/file';

  /**
   * Creates new file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileCreate$Response(params: FileCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDto>> {
    return fileCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileCreate(params: FileCreate$Params, context?: HttpContext): Observable<FileDto> {
    return this.fileCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileDto>): FileDto => r.body)
    );
  }

  /** Path part for operation `fileWithdrawSettledFile()` */
  static readonly FileWithdrawSettledFilePath = '/document/file/{id}/settle/withdraw';

  /**
   * Withdraw of settlement of file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileWithdrawSettledFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileWithdrawSettledFile$Response(params: FileWithdrawSettledFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileWithdrawSettledFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraw of settlement of file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileWithdrawSettledFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileWithdrawSettledFile(params: FileWithdrawSettledFile$Params, context?: HttpContext): Observable<void> {
    return this.fileWithdrawSettledFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileSettleFileByRecord()` */
  static readonly FileSettleFileByRecordPath = '/document/file/{id}/settle-by-record';

  /**
   * Settle file by record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileSettleFileByRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByRecord$Response(params: FileSettleFileByRecord$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileSettleFileByRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle file by record
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileSettleFileByRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByRecord(params: FileSettleFileByRecord$Params, context?: HttpContext): Observable<void> {
    return this.fileSettleFileByRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileSettleFileByDocument()` */
  static readonly FileSettleFileByDocumentPath = '/document/file/{id}/settle-by-document';

  /**
   * Settle file by document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileSettleFileByDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByDocument$Response(params: FileSettleFileByDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileSettleFileByDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle file by document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileSettleFileByDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByDocument(params: FileSettleFileByDocument$Params, context?: HttpContext): Observable<void> {
    return this.fileSettleFileByDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileSettleFileByAssignment()` */
  static readonly FileSettleFileByAssignmentPath = '/document/file/{id}/settle-by-assignment';

  /**
   * Settle file by assignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileSettleFileByAssignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByAssignment$Response(params: FileSettleFileByAssignment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileSettleFileByAssignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle file by assignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileSettleFileByAssignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByAssignment(params: FileSettleFileByAssignment$Params, context?: HttpContext): Observable<void> {
    return this.fileSettleFileByAssignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileSettleFileByAcknowledgement()` */
  static readonly FileSettleFileByAcknowledgementPath = '/document/file/{id}/settle-by-acknowledgement';

  /**
   * Settle file by acknowledgement
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileSettleFileByAcknowledgement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByAcknowledgement$Response(params: FileSettleFileByAcknowledgement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileSettleFileByAcknowledgement(this.http, this.rootUrl, params, context);
  }

  /**
   * Settle file by acknowledgement
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileSettleFileByAcknowledgement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileSettleFileByAcknowledgement(params: FileSettleFileByAcknowledgement$Params, context?: HttpContext): Observable<void> {
    return this.fileSettleFileByAcknowledgement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileReactivateFile()` */
  static readonly FileReactivateFilePath = '/document/file/{id}/reactivate';

  /**
   * Reactivation of file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileReactivateFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileReactivateFile$Response(params: FileReactivateFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileReactivateFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Reactivation of file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileReactivateFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileReactivateFile(params: FileReactivateFile$Params, context?: HttpContext): Observable<void> {
    return this.fileReactivateFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileDeactivateFile()` */
  static readonly FileDeactivateFilePath = '/document/file/{id}/deactivate';

  /**
   * Deactivation of file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileDeactivateFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileDeactivateFile$Response(params: FileDeactivateFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileDeactivateFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Deactivation of file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileDeactivateFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileDeactivateFile(params: FileDeactivateFile$Params, context?: HttpContext): Observable<void> {
    return this.fileDeactivateFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileCloseFile()` */
  static readonly FileCloseFilePath = '/document/file/{id}/close';

  /**
   * Close a file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileCloseFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileCloseFile$Response(params: FileCloseFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileCloseFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Close a file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileCloseFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileCloseFile(params: FileCloseFile$Params, context?: HttpContext): Observable<void> {
    return this.fileCloseFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileProcessFilesForSettlementResumeNotification()` */
  static readonly FileProcessFilesForSettlementResumeNotificationPath = '/document/file/process-files-for-settlement-resume-notification';

  /**
   * Checks files settlement resume date and creates notification event for them.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileProcessFilesForSettlementResumeNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileProcessFilesForSettlementResumeNotification$Response(params: FileProcessFilesForSettlementResumeNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileProcessFilesForSettlementResumeNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks files settlement resume date and creates notification event for them.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileProcessFilesForSettlementResumeNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileProcessFilesForSettlementResumeNotification(params: FileProcessFilesForSettlementResumeNotification$Params, context?: HttpContext): Observable<void> {
    return this.fileProcessFilesForSettlementResumeNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileProcessFilesForSettlementNotification()` */
  static readonly FileProcessFilesForSettlementNotificationPath = '/document/file/process-files-for-settlement-notification';

  /**
   * Checks files settlement date and creates notification event for them.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileProcessFilesForSettlementNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileProcessFilesForSettlementNotification$Response(params: FileProcessFilesForSettlementNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileProcessFilesForSettlementNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks files settlement date and creates notification event for them.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileProcessFilesForSettlementNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileProcessFilesForSettlementNotification(params: FileProcessFilesForSettlementNotification$Params, context?: HttpContext): Observable<void> {
    return this.fileProcessFilesForSettlementNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileMoveDocumentToAnotherFile()` */
  static readonly FileMoveDocumentToAnotherFilePath = '/document/file/move-document';

  /**
   * Move document to another file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileMoveDocumentToAnotherFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileMoveDocumentToAnotherFile$Response(params: FileMoveDocumentToAnotherFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileMoveDocumentToAnotherFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Move document to another file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileMoveDocumentToAnotherFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileMoveDocumentToAnotherFile(params: FileMoveDocumentToAnotherFile$Params, context?: HttpContext): Observable<void> {
    return this.fileMoveDocumentToAnotherFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileInsertDocumentIntoFile()` */
  static readonly FileInsertDocumentIntoFilePath = '/document/file/insert-document';

  /**
   * Insert document into existing file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileInsertDocumentIntoFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileInsertDocumentIntoFile$Response(params: FileInsertDocumentIntoFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileInsertDocumentIntoFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Insert document into existing file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileInsertDocumentIntoFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileInsertDocumentIntoFile(params: FileInsertDocumentIntoFile$Params, context?: HttpContext): Observable<void> {
    return this.fileInsertDocumentIntoFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileGetValidDisposalSchedules()` */
  static readonly FileGetValidDisposalSchedulesPath = '/document/file/get-valid-disposal-schedules';

  /**
   * Returns valid disposal schedules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileGetValidDisposalSchedules()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileGetValidDisposalSchedules$Response(params: FileGetValidDisposalSchedules$Params, context?: HttpContext): Observable<StrictHttpResponse<DisposalScheduleComputationResponseDto>> {
    return fileGetValidDisposalSchedules(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns valid disposal schedules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileGetValidDisposalSchedules$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileGetValidDisposalSchedules(params: FileGetValidDisposalSchedules$Params, context?: HttpContext): Observable<DisposalScheduleComputationResponseDto> {
    return this.fileGetValidDisposalSchedules$Response(params, context).pipe(
      map((r: StrictHttpResponse<DisposalScheduleComputationResponseDto>): DisposalScheduleComputationResponseDto => r.body)
    );
  }

  /** Path part for operation `fileBulkSettle()` */
  static readonly FileBulkSettlePath = '/document/file/bulk-settle';

  /**
   * Bulk settle files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkSettle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkSettle$Response(params: FileBulkSettle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileBulkSettle(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk settle files
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkSettle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkSettle(params: FileBulkSettle$Params, context?: HttpContext): Observable<void> {
    return this.fileBulkSettle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileBulkWithdrawSettle()` */
  static readonly FileBulkWithdrawSettlePath = '/document/file/bulk-settle/withdraw';

  /**
   * Withdraw of settlement of files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkWithdrawSettle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkWithdrawSettle$Response(params: FileBulkWithdrawSettle$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileBulkWithdrawSettle(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraw of settlement of files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkWithdrawSettle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkWithdrawSettle(params: FileBulkWithdrawSettle$Params, context?: HttpContext): Observable<void> {
    return this.fileBulkWithdrawSettle$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `filePrepareBulkSettlement()` */
  static readonly FilePrepareBulkSettlementPath = '/document/file/bulk-settle/prepare/{dateTime}';

  /**
   * Provides complete information for preparation of bulk file settlement.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filePrepareBulkSettlement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filePrepareBulkSettlement$Response(params: FilePrepareBulkSettlement$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkFileSettlementPrepareDto>> {
    return filePrepareBulkSettlement(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides complete information for preparation of bulk file settlement.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filePrepareBulkSettlement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filePrepareBulkSettlement(params: FilePrepareBulkSettlement$Params, context?: HttpContext): Observable<BulkFileSettlementPrepareDto> {
    return this.filePrepareBulkSettlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkFileSettlementPrepareDto>): BulkFileSettlementPrepareDto => r.body)
    );
  }

  /** Path part for operation `fileBulkReopen()` */
  static readonly FileBulkReopenPath = '/document/file/bulk-reopen';

  /**
   * Reopen closed files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkReopen()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkReopen$Response(params: FileBulkReopen$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {
    return fileBulkReopen(this.http, this.rootUrl, params, context);
  }

  /**
   * Reopen closed files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkReopen$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkReopen(params: FileBulkReopen$Params, context?: HttpContext): Observable<{
[key: string]: {
};
}> {
    return this.fileBulkReopen$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>): {
[key: string]: {
};
} => r.body)
    );
  }

  /** Path part for operation `fileBulkremoveDocumentsFromFile()` */
  static readonly FileBulkremoveDocumentsFromFilePath = '/document/file/bulk-remove-document';

  /**
   * Remove documents from existing file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkremoveDocumentsFromFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkremoveDocumentsFromFile$Response(params: FileBulkremoveDocumentsFromFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileBulkremoveDocumentsFromFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove documents from existing file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkremoveDocumentsFromFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkremoveDocumentsFromFile(params: FileBulkremoveDocumentsFromFile$Params, context?: HttpContext): Observable<void> {
    return this.fileBulkremoveDocumentsFromFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileBulkMoveDocumentsToAnotherFile()` */
  static readonly FileBulkMoveDocumentsToAnotherFilePath = '/document/file/bulk-move-document';

  /**
   * Move documents to another file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkMoveDocumentsToAnotherFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkMoveDocumentsToAnotherFile$Response(params: FileBulkMoveDocumentsToAnotherFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileBulkMoveDocumentsToAnotherFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Move documents to another file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkMoveDocumentsToAnotherFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkMoveDocumentsToAnotherFile(params: FileBulkMoveDocumentsToAnotherFile$Params, context?: HttpContext): Observable<void> {
    return this.fileBulkMoveDocumentsToAnotherFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileBulkInsertDocumentsIntoFile()` */
  static readonly FileBulkInsertDocumentsIntoFilePath = '/document/file/bulk-insert-document';

  /**
   * Insert documents into existing file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkInsertDocumentsIntoFile()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkInsertDocumentsIntoFile$Response(params: FileBulkInsertDocumentsIntoFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileBulkInsertDocumentsIntoFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Insert documents into existing file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkInsertDocumentsIntoFile$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkInsertDocumentsIntoFile(params: FileBulkInsertDocumentsIntoFile$Params, context?: HttpContext): Observable<void> {
    return this.fileBulkInsertDocumentsIntoFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileBulkCloseFiles()` */
  static readonly FileBulkCloseFilesPath = '/document/file/bulk-close';

  /**
   * Close a files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileBulkCloseFiles()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkCloseFiles$Response(params: FileBulkCloseFiles$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileBulkCloseFiles(this.http, this.rootUrl, params, context);
  }

  /**
   * Close a files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileBulkCloseFiles$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileBulkCloseFiles(params: FileBulkCloseFiles$Params, context?: HttpContext): Observable<void> {
    return this.fileBulkCloseFiles$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `filePrepareClose()` */
  static readonly FilePrepareClosePath = '/document/file/bulk-close-prepare';

  /**
   * Prepare information to close a files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filePrepareClose()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filePrepareClose$Response(params: FilePrepareClose$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkFileClosePrepareDto>> {
    return filePrepareClose(this.http, this.rootUrl, params, context);
  }

  /**
   * Prepare information to close a files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filePrepareClose$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filePrepareClose(params: FilePrepareClose$Params, context?: HttpContext): Observable<BulkFileClosePrepareDto> {
    return this.filePrepareClose$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkFileClosePrepareDto>): BulkFileClosePrepareDto => r.body)
    );
  }

  /** Path part for operation `filePrepareSettlement()` */
  static readonly FilePrepareSettlementPath = '/document/file/{id}/settle/prepare/{dateTime}';

  /**
   * Provides complete information for preparation of file settlement.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filePrepareSettlement()` instead.
   *
   * This method doesn't expect any request body.
   */
  filePrepareSettlement$Response(params: FilePrepareSettlement$Params, context?: HttpContext): Observable<StrictHttpResponse<FilePrepareCompletionDto>> {
    return filePrepareSettlement(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides complete information for preparation of file settlement.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filePrepareSettlement$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filePrepareSettlement(params: FilePrepareSettlement$Params, context?: HttpContext): Observable<FilePrepareCompletionDto> {
    return this.filePrepareSettlement$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilePrepareCompletionDto>): FilePrepareCompletionDto => r.body)
    );
  }

  /** Path part for operation `fileReopen()` */
  static readonly FileReopenPath = '/document/file/{id}/reopen';

  /**
   * Reopen closed file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileReopen()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileReopen$Response(params: FileReopen$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: {
};
}>> {
    return fileReopen(this.http, this.rootUrl, params, context);
  }

  /**
   * Reopen closed file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileReopen$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileReopen(params: FileReopen$Params, context?: HttpContext): Observable<{
[key: string]: {
};
}> {
    return this.fileReopen$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: {
};
}>): {
[key: string]: {
};
} => r.body)
    );
  }

  /** Path part for operation `filePrepareDeactivation()` */
  static readonly FilePrepareDeactivationPath = '/document/file/{id}/deactivate/prepare';

  /**
   * Provides complete validations for file deactivation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filePrepareDeactivation()` instead.
   *
   * This method doesn't expect any request body.
   */
  filePrepareDeactivation$Response(params: FilePrepareDeactivation$Params, context?: HttpContext): Observable<StrictHttpResponse<PrepareDeactivationDto>> {
    return filePrepareDeactivation(this.http, this.rootUrl, params, context);
  }

  /**
   * Provides complete validations for file deactivation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filePrepareDeactivation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filePrepareDeactivation(params: FilePrepareDeactivation$Params, context?: HttpContext): Observable<PrepareDeactivationDto> {
    return this.filePrepareDeactivation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PrepareDeactivationDto>): PrepareDeactivationDto => r.body)
    );
  }

  /** Path part for operation `filePrepareClose_1()` */
  static readonly FilePrepareClose_1Path = '/document/file/{id}/close/prepare';

  /**
   * Prepare information to close a file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filePrepareClose_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  filePrepareClose_1$Response(params: FilePrepareClose_1$Params, context?: HttpContext): Observable<StrictHttpResponse<FilePrepareCompletionDto>> {
    return filePrepareClose_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Prepare information to close a file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filePrepareClose_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filePrepareClose_1(params: FilePrepareClose_1$Params, context?: HttpContext): Observable<FilePrepareCompletionDto> {
    return this.filePrepareClose_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilePrepareCompletionDto>): FilePrepareCompletionDto => r.body)
    );
  }

  /** Path part for operation `fileFindUidByDocumentId()` */
  static readonly FileFindUidByDocumentIdPath = '/document/file/uid/by-document-id/{documentId}';

  /**
   * If a document is in a file, then it returns the file's uid.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileFindUidByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileFindUidByDocumentId$Response(params: FileFindUidByDocumentId$Params, context?: HttpContext): Observable<StrictHttpResponse<UniqueIdentifierDto>> {
    return fileFindUidByDocumentId(this.http, this.rootUrl, params, context);
  }

  /**
   * If a document is in a file, then it returns the file's uid.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileFindUidByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileFindUidByDocumentId(params: FileFindUidByDocumentId$Params, context?: HttpContext): Observable<UniqueIdentifierDto> {
    return this.fileFindUidByDocumentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<UniqueIdentifierDto>): UniqueIdentifierDto => r.body)
    );
  }

  /** Path part for operation `fileSearch()` */
  static readonly FileSearchPath = '/document/file/search-api';

  /**
   * Finds files based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileSearch$Response(params?: FileSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFileDto>> {
    return fileSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds files based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileSearch(params?: FileSearch$Params|null|undefined, context?: HttpContext): Observable<PageFileDto> {
    return this.fileSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFileDto>): PageFileDto => r.body)
    );
  }

  /** Path part for operation `fileRemoveDocumentFromFile()` */
  static readonly FileRemoveDocumentFromFilePath = '/document/file/remove-document/{documentId}';

  /**
   * Remove document from existing file.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileRemoveDocumentFromFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileRemoveDocumentFromFile$Response(params: FileRemoveDocumentFromFile$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileRemoveDocumentFromFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove document from existing file.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileRemoveDocumentFromFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileRemoveDocumentFromFile(params: FileRemoveDocumentFromFile$Params, context?: HttpContext): Observable<void> {
    return this.fileRemoveDocumentFromFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileFindByDocumentId()` */
  static readonly FileFindByDocumentIdPath = '/document/file/by-document-id/{documentId}';

  /**
   * Returns the file a document is in.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileFindByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileFindByDocumentId$Response(params: FileFindByDocumentId$Params, context?: HttpContext): Observable<StrictHttpResponse<FileDto>> {
    return fileFindByDocumentId(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns the file a document is in.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileFindByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileFindByDocumentId(params: FileFindByDocumentId$Params, context?: HttpContext): Observable<FileDto> {
    return this.fileFindByDocumentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileDto>): FileDto => r.body)
    );
  }

}
