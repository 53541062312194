<div [ngClass]="{'acknowledged-notification-text': isRead}">
  @if (isKnownMessageCode(code)) {
    @if (hasBody(code)) {
      @if (getMessageBodyTemplate(code); as bodyTemplate) {
        <icz-notification-template-outlet
          [template]="bodyTemplate.template"
          [templateContext]="paramsMap"
          [templateContextSchema]="bodyTemplate.schema"
        ></icz-notification-template-outlet>
      }
    }
  }
  @if (!isKnownMessageCode(code)) {
    {{'Neznámé upozornění (kód zprávy: ' + code + ')' | translate}}
  }
</div>
