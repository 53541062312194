/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentPrepareSettlementDto } from '../../models/document-prepare-settlement-dto';

export interface DocumentPrepareSettlement$Params {

/**
 * Id of document to be settled
 */
  id: number;
  dateTime: string;
}

export function documentPrepareSettlement(http: HttpClient, rootUrl: string, params: DocumentPrepareSettlement$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentPrepareSettlementDto>> {
  const rb = new RequestBuilder(rootUrl, documentPrepareSettlement.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.path('dateTime', params.dateTime, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentPrepareSettlementDto>;
    })
  );
}

documentPrepareSettlement.PATH = '/document/document/{id}/settle/prepare/{dateTime}';