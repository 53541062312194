import {ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {ApplicationLanguage} from '../../../core/services/environment.models';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {ActiveModuleService, ApplicationModule} from '../../../core/services/active-module.service';
import {Option} from '../../../model';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {AUTH_SERVICE, IAuthService} from '../../../services/services-utils';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {OrganizationsService} from '../../../services/organizations.service';
import {HttpErrorResponse} from '@angular/common/http';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {LogoutType} from '../../../core/authentication/auth.service';


export enum LoginPageSkin {
  MAIN = 'MAIN',
  CONFIG = 'CONFIG',
}


@Component({
  selector: 'icz-login-page',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPageComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private cd = inject(ChangeDetectorRef);
  private router = inject(Router);
  private translateService = inject(TranslateService);
  private userSettingsService = inject(UserSettingsService);
  private activeModuleService = inject(ActiveModuleService);
  private organizationsService = inject(OrganizationsService);
  private destroyRef = inject(DestroyRef);
  private authService = inject<IAuthService>(AUTH_SERVICE);

  readonly LoginPageSkin = LoginPageSkin;

  loginFailed = false;
  wrongCredentials = false;
  loggedOutFromAnotherSession = false;

  loginScreenLanguageCode!: ApplicationLanguage;

  form = new IczFormGroup({
    organization_code: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    username: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    password: new IczFormControl<Nullable<string>>(null, [IczValidators.required(), IczValidators.minLength(5)]),
  });

  get skin(): LoginPageSkin {
    return this.activeModuleService.activeModule === ApplicationModule.CONFIG ? LoginPageSkin.CONFIG : LoginPageSkin.MAIN;
  }

  get isInConfigApp() {
    return this.activeModuleService.activeModule === ApplicationModule.CONFIG;
  }

  organizationOptions!: Option<string>[];

  isOrganizationSelected = false;

  ngOnInit() {
    if (this.authService.isAuthenticatedWithFunctionalPosition) {
      this.router.navigateByUrl(this.isInConfigApp ? ApplicationRoute.CONFIG : ApplicationRoute.ROOT);
    }
    else if (this.authService.isAuthenticatedWithoutFunctionalPosition) {
      this.selectPosition();
    }
    else {
      this.loginScreenLanguageCode = this.userSettingsService.getRawValue(LocalStorageKey.USER_LANGUAGE) as ApplicationLanguage;
      this.loggedOutFromAnotherSession = this.router.getCurrentNavigation()?.extras?.state?.logoutType === LogoutType.PROGRAMMATIC;

      const organization$ = this.organizationsService.getOrganizations();

      this.loadingService.doLoading(
        organization$,
        this
      ).subscribe(organizations => {
        this.organizationOptions = organizations.map(o => ({
          value: o.code,
          label: o.name,
        }));

        if (!this.isInConfigApp) {
          const organizationControl = this.form.get('organization_code')!;

          const hostnameParts = (window.location.hostname.split('.'));
          const domain = hostnameParts[0];
          const orgByDomain = organizations.find(o => o.domain === domain);

          if (orgByDomain) {
            const orgCode = orgByDomain.code;
            organizationControl.setValue(orgCode);
            organizationControl.disable();
            this.isOrganizationSelected = true;
          }
          else {
            const orgIdByLastUsed = this.userSettingsService.getRawValue(LocalStorageKey.LAST_USED_ORG_ID);
            let orgByLastUsed;
            if (orgIdByLastUsed) {
              orgByLastUsed = organizations.find(o => o.id === Number(orgIdByLastUsed));
            }

            if (orgByLastUsed) {
              organizationControl.setValue(String(orgByLastUsed.code!));
            } else {
              if (this.organizationOptions.length) {
                organizationControl.setValue(this.organizationOptions[0].value);
              }
            }

          }
        }

        setTimeout(() => {
          this.cd.detectChanges();
        }, 0);
      });
    }

    if (this.isInConfigApp) {
      this.form.get('organization_code')!.clearValidators();
    }
  }

  login() {
    if (this.form.invalid) {
      Object.values(this.form.controls).forEach(control => control.markAsTouched());
      return;
    }

    this.loginFailed = false;
    this.wrongCredentials = false;

    this.form.disable();

    this.loadingService.startLoading(this);

    const formValue = this.form.value;

    this.authService.logIn(
      formValue.organization_code!,
      formValue.username!,
      formValue.password!,
    ).pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: _ => {
        setTimeout(() => {
          this.selectPosition();
          this.loadingService.endLoading(this);
        }, 500);
      },
      error: (err: HttpErrorResponse | Error) => {
        if (err instanceof HttpErrorResponse && (err.status === 400 || err.status === 401)) {
          this.wrongCredentials = true;
        }
        else {
          this.loginFailed = true;
        }

        this.form.enable();
        this.loadingService.endLoading(this);
      },
    });
  }

  changeLanguage(languageCode: ApplicationLanguage) {
    this.userSettingsService.setRawValue(LocalStorageKey.USER_LANGUAGE, languageCode);
    this.loginScreenLanguageCode = languageCode;

    this.translateService.use(languageCode);
  }

  private selectPosition() {
    if (!this.isInConfigApp) {
      this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.PLACE_SELECT));
    }
  }

}
