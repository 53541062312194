/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PageCrossReferenceTemplateDto } from '../../models/page-cross-reference-template-dto';

export interface CrossReferenceTemplateSearch$Params {

/**
 * Zero-based page index (0..N)
 */
  page?: any|null|undefined;

/**
 * The size of the page to be returned
 */
  size?: any|null|undefined;

/**
 * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported. Example: 'id,desc'.
 */
  sort?: Array<string>|null|undefined;

/**
 * Parameter object able to store predicate parameters. Key for each predicate is field of entity and value is operation along with searchable value in json format. For examples please see <a href="https://gt-tech.bitbucket.io/spring-data-querydsl-value-operators/EXAMPLE_APPLICATION.html">this link</a>
 */
  urlPredicates?: {
}|null|undefined;
}

export function crossReferenceTemplateSearch(http: HttpClient, rootUrl: string, params?: CrossReferenceTemplateSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageCrossReferenceTemplateDto>> {
  const rb = new RequestBuilder(rootUrl, crossReferenceTemplateSearch.PATH, 'get');
  if (params) {
    rb.query('page', params.page, {});
    rb.query('size', params.size, {});
    rb.query('sort', params.sort, {});
    rb.query('urlPredicates', params.urlPredicates, {"style":"form","explode":true});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PageCrossReferenceTemplateDto>;
    })
  );
}

crossReferenceTemplateSearch.PATH = '/codebook/cross-reference-template/search-api';