import {Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, ViewChild} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {ApiDocumentTypeService} from '|api/codebook';
import {DocumentForm, EmpowermentDto, NodeType} from '|api/commons';
import {DocumentAllowableObjectClass} from '|api/document';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {
  getDigitalComponentUploadForm
} from '../digital-components-upload-form/digital-components-upload-form.component';
import {
  AnalogComponentCreateFormComponent,
  createAnalogComponentsFormGroup,
  CreateAnalogFormConfig,
  CreateAnalogsUsage,
  EsslAnalogComponentType,
  EsslAnalogComponentTypeAsFields
} from '../analog-component-create-form/analog-component-create-form.component';
import {DocumentEsslComponentsValidator} from '../document-essl-components-validator';
import {ComponentUploadStatus, defaultDigitalFormConfig} from '../essl-components/essl-components-utils';
import {DocumentTypeOption} from '../../../services/document-detail.service';
import {getStartOfTheDay, locateOptionByValue, Option} from '../../../model';
import {IczDateValidators} from '../../form-elements/validators/icz-validators/icz-date-validators';
import {CodebookService} from '../../../core/services/codebook.service';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {
  initEntityClassPreselectionByDocumentType,
  initSecurityCategoryPreselectionByEntityClass
} from '../../../utils/document-forms.utils';
import {addDays, formatAsLocalIsoDate} from '../../../lib/utils';
import {calcDocumentFormFromComponents, getObjectClassByDocumentForm} from '../shared-document.utils';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {OwnDocumentService} from '../../../services/own-document.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  DigitalComponentUploadDialogType,
  EsslDigitalComponentTypeAsFields,
  initTotalComponentsCountFormListeners
} from '../essl-components/models/digital-components.model';
import {DisposalTipDetailLevel} from '../shared-business-components.model';
import {IczModalService} from '../../../services/icz-modal.service';
import {
  DigitalComponentFromTemplateDialogComponent,
  DigitalComponentFromTemplateDialogResult
} from '../essl-components/components/digital-component-from-template-dialog/digital-component-from-template-dialog.component';


/** is OwnDocumentCreateDto */
export function getOwnDocumentForm(defaultAssignRefNr: boolean) {
  return new IczFormGroup({
    subject: new IczFormControl<Nullable<string>>(null, [IczValidators.required(), IczValidators.maxLength(100)]),
    assignRefNr: new IczFormControl<Nullable<boolean>>(defaultAssignRefNr, []),
    description: new IczFormControl<Nullable<string>>('', []),
    note: new IczFormControl<Nullable<string>>('', []),
    relatedEvidence: new IczFormControl<Nullable<string>>('', []),
    label: new IczFormControl<Nullable<string>>('', []),
    documentTypeId: new IczFormControl<Nullable<number>>(),
    externalId: new IczFormControl<Nullable<string>>('', []),
    documentForm: new IczFormControl<Nullable<DocumentForm>>({value: null, disabled: true}),
    entityClassId: new IczFormControl<Nullable<number>>(),
    triggerEvent: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<number>>(null),
    triggerEventCheckYear: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    securityCategoryId: new IczFormControl<Nullable<number>>(null, []),
    keywordIds: new IczFormControl<Nullable<number[]>>(null, []),
    componentPhysicalLocation: new IczFormControl<Nullable<string>>('', []),
    resolutionDate: new IczFormControl<Nullable<string>>(null, []),
    executionDate: new IczFormControl<Nullable<string>>(null, []),
    objectClass: new IczFormControl<Nullable<DocumentAllowableObjectClass>>(DocumentAllowableObjectClass.OWN_DOCUMENT, []),
    empowerment: new IczFormControl<Nullable<EmpowermentDto>>(null, []),
    [EsslDigitalComponentTypeAsFields.DIGITAL]: new IczFormArray(() => getDigitalComponentUploadForm(), []),
    [EsslAnalogComponentTypeAsFields.PAPER]: createAnalogComponentsFormGroup(
      {type: EsslAnalogComponentType.PAPER_COMPONENT, initialSize: 0}),
    [EsslAnalogComponentTypeAsFields.MEDIUM]: createAnalogComponentsFormGroup(
      {type: EsslAnalogComponentType.MEDIUM_COMPONENT, initialSize: 0}),
    [EsslAnalogComponentTypeAsFields.PHYSICAL]: createAnalogComponentsFormGroup(
      {type: EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT, initialSize: 0}),
    esslComponentCount: new IczFormControl<number>(0, [DocumentEsslComponentsValidator.isEsslComponentAdded()])
  });
}


@Component({
  selector: 'icz-own-document-form',
  templateUrl: './own-document-form.component.html',
  styleUrls: ['./own-document-form.component.scss'],
})
export class OwnDocumentFormComponent implements OnInit {

  codebookService = inject(CodebookService);
  protected applicationConfigService = inject(ApplicationConfigService);
  private ownDocumentService = inject(OwnDocumentService);
  private apiDocumentTypeService = inject(ApiDocumentTypeService);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  destroyRef = inject(DestroyRef);
  private modalService = inject(IczModalService);

  @Input({required: true})
  form!: IczFormGroup;
  @Input()
  digitalComponentsCountLimit = Infinity;
  @Input()
  withEntityClassSelection = true;

  @ViewChild('fileUpload', {static: false}) fileUpload!: ElementRef;

  analogComponentCreateFormComponent!: AnalogComponentCreateFormComponent;
  @ViewChild('analogComponentCreateFormComponent') set analogComponentCreateForm(component: AnalogComponentCreateFormComponent) {
    if(component) {
      this.analogComponentCreateFormComponent = component;
    }
  }

  readonly DigitalComponentUploadDialogType = DigitalComponentUploadDialogType;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;
  selectFiles = new EventEmitter();
  digitalComponentsConfig = defaultDigitalFormConfig;

  get documentForm() {
    return this.form.get('documentForm')?.value;
  }

  get documentFormControl() {
    return this.form.get('documentForm');
  }

  get entityClassId() {
    return this.form.get('entityClassId')!.value;
  }

  get documentTypeId() {
    return this.form.get('documentTypeId')!.value;
  }

  get digitalComponentsControl() {
    return this.form.get(EsslDigitalComponentTypeAsFields.DIGITAL) as IczFormArray;
  }

  get digitalComponentsCount() {
    return this.digitalComponentsControl.length;
  }

  get digitalComponentsCountExceedsLimit() {
    return this.digitalComponentsCount >= this.digitalComponentsCountLimit;
  }

  get esslComponentCountFormCtrl() {
    return this.form.get('esslComponentCount') as IczFormControl;
  }

  get digitalComponentsCountExceedsLimitTooltip() {
    if (this.digitalComponentsCountExceedsLimit) {
      return this.translateParser.interpolate(
        this.translateService.instant('Lze nahrát maximálně {{limitCount}} komponent'),
        {
          limitCount: this.digitalComponentsCountLimit,
        }
      );
    }
    else {
      return null;
    }
  }

  documentTypeOptions: DocumentTypeOption[] = [];
  documentFormOptions: Option[] = [];

  analogComponentsConfig: CreateAnalogFormConfig = {
    analogCreateUsage: CreateAnalogsUsage.PART_OF_DOCUMENT_CREATE,
    isDetailedInput: 'true',
    showFieldIsFinal: true,
    showFieldOrigin: true,
    allowDeleteFirst: true,
    setFieldIsFinalAsTrue: false,
  };

  readonly presentOrFutureDateValidator = IczDateValidators.presentOrFutureDateValidator;

  private initSecurityCategoryPreselection() {
    initSecurityCategoryPreselectionByEntityClass.bind(this)();
  }

  private initEntityClassPreselection() {
    initEntityClassPreselectionByDocumentType.bind(this)();
  }

  private getAutocompleteOptions() {
    this.apiDocumentTypeService.documentTypeFindAllValidInTimeByType({
      nodeType: NodeType.LEAF,
      date: formatAsLocalIsoDate(new Date().toISOString()),
    }).pipe(
      map(entries => entries.map(option => ({
        value: option.id!,
        parent: option.parentId,
        label: option.name,
        data: {
          disposalScheduleId: option.disposalScheduleId,
          entityClassId: option.entityClassId,
          statutoryPeriod: option.statutoryPeriod,
        },
      }))),
    ).subscribe(options => this.documentTypeOptions = options);
  }

  private cleanIncompatibleEsslComponents(documentForm: DocumentForm) {
    if (documentForm === DocumentForm.ANALOG) {
      (this.form.get(EsslDigitalComponentTypeAsFields.DIGITAL) as IczFormArray)?.setSize(0);
    } else if (documentForm === DocumentForm.DIGITAL) {
      (this.form.get(EsslAnalogComponentTypeAsFields.PAPER)?.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray)?.setSize(0);
      (this.form.get(EsslAnalogComponentTypeAsFields.MEDIUM)?.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray)?.setSize(0);
      (this.form.get(EsslAnalogComponentTypeAsFields.PHYSICAL)?.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray)?.setSize(0);
    }
  }

  private calcDocumentFormFromComponents() {
    calcDocumentFormFromComponents(this.form, this.destroyRef);
  }

  ngOnInit() {
    this.ownDocumentService.form = this.form;
    this.documentFormOptions = enumToOptions('documentForm', DocumentForm);

    this.getAutocompleteOptions();
    this.calcDocumentFormFromComponents();
    this.initSecurityCategoryPreselection();
    this.initEntityClassPreselection();

    this.documentFormControl!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(documentForm => {
      this.cleanIncompatibleEsslComponents(documentForm);
      const objectClass = getObjectClassByDocumentForm(documentForm);

      if (objectClass) {
        this.form.get('objectClass')!.setValue(objectClass);
      }
    });

    this.form.get('documentTypeId')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(documentTypeId => {
      const documentType = locateOptionByValue(this.documentTypeOptions, documentTypeId);

      if (documentType?.data?.statutoryPeriod) {
        const resolutionDate = addDays(getStartOfTheDay(), documentType.data.statutoryPeriod);

        this.form.get('resolutionDate')!.setValue(resolutionDate.toISOString());
      } else {
        this.form.get('resolutionDate')!.setValue(null);
      }
    });

    initTotalComponentsCountFormListeners(this.destroyRef, this.form, this.esslComponentCountFormCtrl);
  }

  addAnotherDigitalComponent() {
    if (!this.digitalComponentsCountExceedsLimit) {
      this.selectFiles.emit();
    }
  }

  addAnotherDigitalComponentFromTemplate() {
    this.modalService.openComponentInModal<DigitalComponentFromTemplateDialogResult, void>({
        component: DigitalComponentFromTemplateDialogComponent,
        modalOptions: {
          titleTemplate: 'Založení komponenty ze šablony',
          width: 1000,
          height: null,
        },
      }).subscribe(result => {
        if (result) {
          this.digitalComponentsControl.setValue([...this.digitalComponentsControl.getRawValue(), {
            relationType: result.relationType,
            description: result.description,
            label: result.label,
            isFinal: result.isFinal,
            originType: result.originType,
            digitalComponentVersionId: null,
            file: {
              name: result.fileName,
              size: result.fileSize,
            },
            status: ComponentUploadStatus.SUCCESS,
            isValidOutputFormat: true,
            digitalComponentTemplateId: result.templateId
          }]);
        }
    });
  }

}
