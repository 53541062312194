/* tslint:disable */
/* eslint-disable */

/**
 * Type of authentication used on node.
 */
export enum AuthenticationType {
  CERTIFICATE = 'CERTIFICATE',
  PASSWORD = 'PASSWORD'
}
