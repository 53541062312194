/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { renditionCreateBulkDocumentPrintRendition } from '../fn/rendition/rendition-create-bulk-document-print-rendition';
import { RenditionCreateBulkDocumentPrintRendition$Params } from '../fn/rendition/rendition-create-bulk-document-print-rendition';
import { renditionCreateBulkDocumentXmlPrintRendition } from '../fn/rendition/rendition-create-bulk-document-xml-print-rendition';
import { RenditionCreateBulkDocumentXmlPrintRendition$Params } from '../fn/rendition/rendition-create-bulk-document-xml-print-rendition';
import { renditionCreateBulkFilePrintRendition } from '../fn/rendition/rendition-create-bulk-file-print-rendition';
import { RenditionCreateBulkFilePrintRendition$Params } from '../fn/rendition/rendition-create-bulk-file-print-rendition';
import { renditionCreateBulkFileRendition } from '../fn/rendition/rendition-create-bulk-file-rendition';
import { RenditionCreateBulkFileRendition$Params } from '../fn/rendition/rendition-create-bulk-file-rendition';
import { renditionCreateBulkFileXmlPrintRendition } from '../fn/rendition/rendition-create-bulk-file-xml-print-rendition';
import { RenditionCreateBulkFileXmlPrintRendition$Params } from '../fn/rendition/rendition-create-bulk-file-xml-print-rendition';


/**
 * API for rendition of documents and files
 */
@Injectable({ providedIn: 'root' })
export class ApiRenditionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `renditionCreateBulkFilePrintRendition()` */
  static readonly RenditionCreateBulkFilePrintRenditionPath = '/document/rendition/print/file';

  /**
   * Creates a print rendition for given files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renditionCreateBulkFilePrintRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkFilePrintRendition$Response(params: RenditionCreateBulkFilePrintRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return renditionCreateBulkFilePrintRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a print rendition for given files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renditionCreateBulkFilePrintRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkFilePrintRendition(params: RenditionCreateBulkFilePrintRendition$Params, context?: HttpContext): Observable<any> {
    return this.renditionCreateBulkFilePrintRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `renditionCreateBulkFileXmlPrintRendition()` */
  static readonly RenditionCreateBulkFileXmlPrintRenditionPath = '/document/rendition/print/file-xml';

  /**
   * Creates a xml print rendition for given files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renditionCreateBulkFileXmlPrintRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkFileXmlPrintRendition$Response(params: RenditionCreateBulkFileXmlPrintRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return renditionCreateBulkFileXmlPrintRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a xml print rendition for given files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renditionCreateBulkFileXmlPrintRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkFileXmlPrintRendition(params: RenditionCreateBulkFileXmlPrintRendition$Params, context?: HttpContext): Observable<any> {
    return this.renditionCreateBulkFileXmlPrintRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `renditionCreateBulkDocumentPrintRendition()` */
  static readonly RenditionCreateBulkDocumentPrintRenditionPath = '/document/rendition/print/document';

  /**
   * Creates a print rendition for given documents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renditionCreateBulkDocumentPrintRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkDocumentPrintRendition$Response(params: RenditionCreateBulkDocumentPrintRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return renditionCreateBulkDocumentPrintRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a print rendition for given documents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renditionCreateBulkDocumentPrintRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkDocumentPrintRendition(params: RenditionCreateBulkDocumentPrintRendition$Params, context?: HttpContext): Observable<any> {
    return this.renditionCreateBulkDocumentPrintRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `renditionCreateBulkDocumentXmlPrintRendition()` */
  static readonly RenditionCreateBulkDocumentXmlPrintRenditionPath = '/document/rendition/print/document-xml';

  /**
   * Creates a xml print rendition for given documents.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renditionCreateBulkDocumentXmlPrintRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkDocumentXmlPrintRendition$Response(params: RenditionCreateBulkDocumentXmlPrintRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return renditionCreateBulkDocumentXmlPrintRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a xml print rendition for given documents.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renditionCreateBulkDocumentXmlPrintRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkDocumentXmlPrintRendition(params: RenditionCreateBulkDocumentXmlPrintRendition$Params, context?: HttpContext): Observable<any> {
    return this.renditionCreateBulkDocumentXmlPrintRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `renditionCreateBulkFileRendition()` */
  static readonly RenditionCreateBulkFileRenditionPath = '/document/rendition/file';

  /**
   * Creates a rendition for given files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `renditionCreateBulkFileRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkFileRendition$Response(params: RenditionCreateBulkFileRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return renditionCreateBulkFileRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a rendition for given files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `renditionCreateBulkFileRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  renditionCreateBulkFileRendition(params: RenditionCreateBulkFileRendition$Params, context?: HttpContext): Observable<any> {
    return this.renditionCreateBulkFileRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
