/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReceivedPaperConsignmentDto } from '../../models/received-paper-consignment-dto';
import { ReceivedPaperConsignmentRegisterContentDto } from '../../models/received-paper-consignment-register-content-dto';

export interface ReceivedPaperConsignmentRegisterContentByFilingOffice$Params {

/**
 * Consignment id
 */
  id: number;
      body: ReceivedPaperConsignmentRegisterContentDto
}

export function receivedPaperConsignmentRegisterContentByFilingOffice(http: HttpClient, rootUrl: string, params: ReceivedPaperConsignmentRegisterContentByFilingOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
  const rb = new RequestBuilder(rootUrl, receivedPaperConsignmentRegisterContentByFilingOffice.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReceivedPaperConsignmentDto>;
    })
  );
}

receivedPaperConsignmentRegisterContentByFilingOffice.PATH = '/sad/received-paper-consignment/{id}/register-content-filing-office';