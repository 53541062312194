/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PortalNodeDto } from '../../models/portal-node-dto';
import { PortalNodeUpdateDto } from '../../models/portal-node-update-dto';

export interface PortalNodesOauthUpdate$Params {

/**
 * ID of entity to update
 */
  id: number;
      body: PortalNodeUpdateDto
}

export function portalNodesOauthUpdate(http: HttpClient, rootUrl: string, params: PortalNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalNodeDto>> {
  const rb = new RequestBuilder(rootUrl, portalNodesOauthUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PortalNodeDto>;
    })
  );
}

portalNodesOauthUpdate.PATH = '/oauth/portal-nodes/update-node/{id}';