import {Component, Input} from '@angular/core';


@Component({
  selector: 'icz-document-registration-timeline',
  templateUrl: './document-registration-timeline.component.html',
  styleUrls: ['./document-registration-timeline.component.scss'],
})
export class DocumentRegistrationTimelineComponent {
  @Input()
  timelineItems: string[] = [];
}
