/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EsslComponentExtendedDto } from '../../models/essl-component-extended-dto';

export interface ComponentGetById$Params {

/**
 * The component id
 */
  id: number;
}

export function componentGetById(http: HttpClient, rootUrl: string, params: ComponentGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslComponentExtendedDto>> {
  const rb = new RequestBuilder(rootUrl, componentGetById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EsslComponentExtendedDto>;
    })
  );
}

componentGetById.PATH = '/component/{id}';