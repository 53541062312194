import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MarkdownService} from 'ngx-markdown';
import {InterpolationContext} from '../../../lib/model';
import {ZodType} from 'zod';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';

@Component({
  selector: 'icz-notification-template-outlet',
  templateUrl: './notification-template-outlet.component.html',
  styleUrls: ['./notification-template-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class NotificationTemplateOutletComponent implements OnInit, OnDestroy {

  private el = inject(ElementRef);
  private router = inject(Router);
  private renderer = inject(Renderer2);
  private markdownService = inject(MarkdownService);
  private translateService = inject(TranslateService);
  private applicationConfigService = inject(ApplicationConfigService);

  @Input({required: true})
  template = '';
  @Input({required: true})
  templateContext: Nullable<InterpolationContext>;
  @Input({required: true})
  templateContextSchema: Nullable<ZodType>;
  @Output()
  linkClicked = new EventEmitter<string>();

  isDataInvalid = false;
  isErrorReportingEnabled = this.applicationConfigService.isErrorReportingEnabled;

  private listenerDestructors: Array<() => void> = [];

  ngOnInit() {
    // an adjustment of Markdown Renderer that adjusts an expression of type "**text**"
    this.markdownService.renderer.strong = (text: string) => {
      return `<span class="font-bold">${text}</span>`;
    };

    // an adjustment of Markdown Renderer that translates expressions in text tokens
    //  enclosed in double @@s using our translation service.
    this.markdownService.renderer.text = (text: string) => {
      const translationBlockRe = /@@(..*?)@@/m; // need lazy quantifier so ..*? = <<ungreedy>> .+
      let matchResults;

      while ((matchResults = translationBlockRe.exec(text)) !== null) {
        text = text.replace(matchResults[0], this.translateService.instant(matchResults[1]));
      }

      return text;
    };

    if (this.templateContext && this.templateContextSchema) {
      const validationResult = this.templateContextSchema.safeParse(this.templateContext);

      if (!validationResult.success) {
        this.isDataInvalid = true;
        console.warn(`Notification template ${this.template} schema validation failed: ${JSON.stringify(validationResult.error.format())}`);
      }
    }
  }

  ngOnDestroy() {
    for (const destructor of this.listenerDestructors) {
      destructor();
    }
  }

  registerRoutingLinkListeners() {
    const markdownLinkElements = Array.from(this.el.nativeElement.querySelectorAll('markdown a'));

    for (const linkEl of markdownLinkElements) {
      this.listenerDestructors.push(
        this.renderer.listen(
          linkEl, 'click', (e: Event) => {
            e.stopPropagation();
            const el = e.target as HTMLElement;
            const linkUrl = this.getLinkUrl(el);

            if (!this.isLinkWithSchema(el)) {
              e.preventDefault();
              this.router.navigateByUrl(linkUrl);
            }

            this.linkClicked.emit(linkUrl);
          }
        )
      );
    }
  }

  private isLinkWithSchema(linkEl: HTMLElement) {
    const absoluteUrlRe = /(?:^[a-z][a-z0-9+.-]*:|\/\/)/i;
    return absoluteUrlRe.test(this.getLinkUrl(linkEl));
  }

  private getLinkUrl(linkEl: HTMLElement): string {
    return linkEl.getAttribute?.('href') ?? '';
  }

}
