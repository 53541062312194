import {Pipe, PipeTransform} from '@angular/core';
import {SubjectAttributeStringDto} from '|api/commons';

@Pipe({
  name: 'attributesValue',
  pure: true, // for each pair (immutable array, value) it will produce the same result
})
export class AttributeValuesPipe implements PipeTransform {

  transform(attributes: SubjectAttributeStringDto[]): string {
    if (isNil(attributes) || !Array.isArray(attributes)) {
      return '';
    }
    else {
     return attributes.map(a => a.value)!.join(', ');
    }
  }

}
