import {
  PlainComponentVersionViewerDialogComponent,
  PlainComponentViewerData
} from './plain-component-version-viewer-dialog.component';
import {IczModalService} from '../../../services/icz-modal.service';
import {DigitalComponentVersionTypeFlag} from '|api/commons';
import {ApiDigitalComponentVersionService} from '|api/component';
import {tap} from 'rxjs';
import {LoadingIndicatorService} from '../loading-indicator.service';
import {inject, Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})
export class PlainComponentVersionViewerDialogService {

  private iczModalService = inject(IczModalService);
  private loadingService = inject(LoadingIndicatorService);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);

  openPlainComponentVersionViewerWithVersionFetch(digitalComponentVersionId: number) {
    return this.loadingService.doLoading(
      this.apiDigitalComponentVersionService.digitalComponentVersionGetById({
        digitalComponentVersionId: digitalComponentVersionId!,
      }),
      this
    ).pipe(
      tap(digitalComponentVersion => {
        this.openPlainComponentVersionViewer(
          digitalComponentVersion.id!,
          digitalComponentVersion.fileName,
          digitalComponentVersion.mimeType,
          digitalComponentVersion.digitalComponentVersionTypeFlag,
        );
      }),
    );
  }

  openPlainComponentVersionViewer(
    digitalComponentVersionId: number,
    digitalComponentVersionFileName?: Nullable<string>,
    digitalComponentVersionMimeType?: Nullable<string>,
    digitalComponentVersionTypeFlag?: Nullable<DigitalComponentVersionTypeFlag>,
    modalTitleSuffix?: Nullable<string>,
  ) {
    this.iczModalService.openComponentInModal<void, PlainComponentViewerData>({
      component: PlainComponentVersionViewerDialogComponent,
      modalOptions: {
        width: 1100,
        height: '90vh',
        useCustomHeader: true,
        disableAutoMargin: true,
      },
      data: {
        digitalComponentVersionId,
        digitalComponentVersionFileName,
        digitalComponentVersionMimeType,
        digitalComponentVersionTypeFlag,
        modalTitleSuffix,
      }
    }).subscribe(_ => {
    });
  }

}
