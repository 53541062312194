/* tslint:disable */
/* eslint-disable */

/**
 * Data file format acceptability
 */
export enum DataFileFormatAcceptability {
  RECOMMENDED = 'RECOMMENDED',
  ACCEPTABLE = 'ACCEPTABLE',
  COMPLEMENTARY = 'COMPLEMENTARY',
  UNACCEPTABLE = 'UNACCEPTABLE',
  UNPACK = 'UNPACK'
}
