import {ChangeDetectionStrategy, Component, inject, OnInit, ViewChild} from '@angular/core';
import {TakeoverByBarcodeDialogService, TakeoverByBarcodeWorkflowAction} from './takeover-by-barcode-dialog.service';
import {OwnConsignmentWorkflowService} from '../own-consignment-table/own-consignment-workflow.service';
import {OwnInternalPaperConsignmentDto, OwnPaperConsignmentDto} from '|api/sad';
import {
  ConsignmentChangeDeliveryServiceDialogComponent
} from './consignment-change-delivery-service-dialog/consignment-change-delivery-service-dialog.component';
import {ConsignmentsToastService} from '../consignment-dialog/consignments-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {OwnConsignmentStatus} from '|api/commons';
import {OwnConsignmentWorkflowAction} from '../consignment-dialog/consignment-dialog/consignment.model';
import {ConsignmentCommand} from '../barcode-scanned-consignments-table/barcode-scanned-consignments-table.component';
import {
  ConsignmentBarcodeScanAndFindService
} from '../barcode-scanned-consignments-form/consignment-barcode-scan-and-find.service';
import {
  BarcodeScannedConsignmentsFormComponent, getBarcodeScannedConsignmentsForm
} from '../barcode-scanned-consignments-form/barcode-scanned-consignments-form.component';
import {CheckUnsavedFormDialogService} from '../../dialogs/check-unsaved-form-dialog.service';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {injectModalData, injectModalRef} from '../../../lib/modals';
import {IczModalService} from '../../../services/icz-modal.service';

export interface TakeoverByBarcodeDialogData {
  dispatchOfficeDistributionNodeIds: number[];
  dialogMode: TakeoverByBarcodeWorkflowAction;
}

@Component({
  selector: 'icz-takeover-by-barcode-dialog',
  templateUrl: './takeover-by-barcode-dialog.component.html',
  styleUrls: ['./takeover-by-barcode-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService,
    OwnConsignmentWorkflowService,
    TakeoverByBarcodeDialogService,
    ConsignmentBarcodeScanAndFindService,
  ],
})
export class TakeoverByBarcodeDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<number>>();
  private takeoverByBarcodeDialogService = inject(TakeoverByBarcodeDialogService);
  private consignmentBarcodeScanAndFindService = inject(ConsignmentBarcodeScanAndFindService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);
  private modalData = injectModalData<TakeoverByBarcodeDialogData>();

  @ViewChild(BarcodeScannedConsignmentsFormComponent)
  barcodeScannedConsignments!: BarcodeScannedConsignmentsFormComponent;

  form = getBarcodeScannedConsignmentsForm();

  consignmentsToProcess: ConsignmentCommand[] = [];

  formGroupsToCheck!: string[];

  get dialogMode() {
    return this.modalData.dialogMode;
  }

  readonly OwnConsignmentWorkflowAction = OwnConsignmentWorkflowAction;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.consignmentBarcodeScanAndFindService.initialize(
      this.modalData.dispatchOfficeDistributionNodeIds,
      [
        this.dialogMode === OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION ?
          OwnConsignmentStatus.TAKEN_OVER :
          OwnConsignmentStatus.TO_TAKEOVER
      ]
    );
  }

  onConsignmentCommandsChanged(consignments: ConsignmentCommand[]) {
    this.consignmentsToProcess = consignments;
  }

  editConsignmentDeliveryServiceCombination(consignment: ConsignmentCommand) {
    this.modalService.openComponentInModal<number, OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto>({
      component: ConsignmentChangeDeliveryServiceDialogComponent,
      modalOptions: {
        width: 500,
        height: 250,
        titleTemplate: 'Upravit doručovací službu zásilky',
      },
      data: consignment.consignment,
    }).subscribe(resultDeliveryServiceId => {
      if (resultDeliveryServiceId) {
        this.loadingService.doLoading(
          this.takeoverByBarcodeDialogService.changeConsignmentAttributes(
            consignment.consignment,
            {deliveryServiceCombinationId: resultDeliveryServiceId}
          ),
          this
        ).subscribe(resultConsignment => {
          consignment.consignment.deliveryServiceCombinationId = resultDeliveryServiceId;

          this.consignmentsToastService.dispatchConsignmentDeliveryServiceUpdated({
            [InternalNotificationKey.CONSIGNMENT_UID]: resultConsignment.uid!.uid!,
          });
        });
      }
    });
  }

  bulkExecuteWorkflowAction(workflowAction: TakeoverByBarcodeWorkflowAction) {
    this.barcodeScannedConsignments.resetErrorFlags();
    this.form.reset();

    this.loadingService.doLoading(
      this.takeoverByBarcodeDialogService.requestAction(
        workflowAction,
        this.consignmentsToProcess,
      ),
      this,
    ).subscribe(_ => {
      this.modalRef.close(this.consignmentsToProcess.length);
    });
  }

  cancel() {
    this.modalRef.close(null);
  }
}
