<icz-label class="icz-body-1" label="Přidat oprávnění"></icz-label>
<form [formGroup]="form" class="gap-0 row-group">
  <icz-org-structure-selector
    [waiting]="this.isLoading$ | async"
    [form]="form"
    controlName="orgStructureElementIds"
    [isMultiselect]="true"
    [placeholder]="disableOrgUnitPermissions ? 'Vyberte funkční místo' : 'Vyberte funkční místo nebo org. jednotku'"
    [disableCurrentFunctionalPosition]="true"
    [disableOrganizationStructureType]="disableOrgUnitPermissions ? OrganizationStructureType.ORGANIZATION_UNIT : null"
    [autoSizeMax]="3"
    [clearable]="true"
    [fieldDisabled]="!arePermissionsControlsUsable"
    class="grow"
  ></icz-org-structure-selector>
  <icz-permission-selector class="grow" formControlName="permissionOptions" [entityType]="entityType" [fieldDisabled]="!arePermissionsControlsUsable"></icz-permission-selector>
  <icz-button
    [iczTooltip]="disabledSubmitTooltip"
    [disabled]="(isLoading$ | async) || isSubmitDisabled"
    (onAction)="addPermission()"
    [primary]="true"
    size="tiny"
    label="Přidat"
  ></icz-button>
</form>
