/* tslint:disable */
/* eslint-disable */

/**
 * Type of rendition output
 */
export enum RenditionOutputType {
  XML = 'XML',
  PDF = 'PDF'
}
