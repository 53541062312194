import {Directive, inject, Input} from '@angular/core';
import {DistributionClass, SubjectRecordDto} from '|api/commons';
import {IczFormGroup} from '../../../form-elements/icz-form-controls';
import {ConsignmentDocumentData} from './abstract-consignment-dialog-data';
import {GenericOwnConsignment} from '../../own-consignment-table/model/own-consignment-model';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';

@Directive()
export class AbstractConsignmentSpecificationComponent {

  protected loadingService = inject(LoadingIndicatorService);

  @Input()
  specificFieldsForm!: IczFormGroup;
  @Input()
  documentData: Nullable<ConsignmentDocumentData>;
  @Input()
  consignmentForEdit: Nullable<GenericOwnConsignment>;
  @Input()
  consignee!: SubjectRecordDto;
  @Input()
  selectedDistributionClass: Nullable<DistributionClass>;
  @Input()
  deliveryTypeId: Nullable<number>;

  get documentRefNumber() {
    if (this.documentData && this.documentData.refNumber) {
      return this.documentData.refNumber;
    } else if (this.consignmentForEdit) {
      return this.consignmentForEdit.refNumber;
    } else {
      return '';
    }
  }

  get fileRefNumber() {
    if (this.documentData && this.documentData.parentFileRefNumber) {
      return this.documentData.parentFileRefNumber;
    } else if (this.consignmentForEdit) {
      return this.consignmentForEdit.fileRefNumber;
    } else {
      return '';
    }
  }

}
