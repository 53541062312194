import {FlatTreeControl} from '@angular/cdk/tree';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {filter} from 'rxjs/operators';
import {TableTreeviewDataSource, TreeEntry, TreeNode} from './table-treeview.datasource';
import {castStream} from '../../../lib/rxjs';

export type TreeAction = 'select' | 'edit' | 'group' | 'new';
export interface TreeEvent {
  action: TreeAction;
  node: TreeNode;
}

@Component({
  selector: 'icz-table-treeview',
  templateUrl: './table-treeview.component.html',
  styleUrls: ['./table-treeview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTreeviewComponent {

  private _dataSource!: TableTreeviewDataSource<TreeEntry>;
  selected!: TreeNode;

  @Input() treeviewTitle = 'Přehled';
  @Input({required: true}) treeControl!: FlatTreeControl<TreeNode>;
  @Input() showItemMenu = true;
  @Input({required: true}) set dataSource(value: TableTreeviewDataSource<TreeEntry>) {
    this._dataSource = value;
    this._dataSource.selected$.pipe(
      filter(Boolean),
      castStream<TreeNode>(),
    ).subscribe(
      node => this.selected = node,
    );
  }
  get dataSource() { return this._dataSource; }

  @Output() onAction = new EventEmitter<TreeEvent>();

  sideWidth = 340;

  onTreeNodeClick(node: TreeNode) {
    this.selected = node;
    this.onAction.emit({action: 'select', node});
  }
}
