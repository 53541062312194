import {Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CirculationActivityFlowType, CirculationActivityType, CirculationTaskType, DocumentForm} from '|api/commons';
import {DocumentDto} from '|api/document';
import {
  ApiCirculationActivityBulkActionsService,
  ApiCirculationActivityService,
  ApiCirculationTaskBulkActionsService,
  ApiCirculationTaskHandoverService, ApiCirculationTaskHandoverToOwnHandsService,
  CirculationActivityDto,
  CirculationHandoverActivityCreateDto,
  CirculationHandoverTaskTakeoverPassDto,
  CirculationTaskDto
} from '|api/flow';
import {AbstractHandoverFormModalComponent} from '../abstract-handover-form-modal.component';
import {FunctionalPositionGroup, FunctionalPositionGroupItems} from '../../../saved-functional-positions.service';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup, TextLength} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {IczModalService} from '../../../../../services/icz-modal.service';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {isDocumentEntity, isTaskEntity} from '../../../shared-document.utils';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';
import {
  getRelatedOrgStructureOptionIds,
  handleCirculationErrorAsToast
} from '../../../../../utils/document-file-circulation.utils';
import {
  PrimaryFunctionalPositionPickerComponent,
  PrimaryFunctionalPositionPickerDialogData,
  PrimaryFunctionalPositionPickerResult
} from '../../../primary-functional-position-picker/primary-functional-position-picker.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {injectModalData} from '../../../../../lib/modals';

@Component({
  selector: 'icz-handover-dialog',
  templateUrl: './handover-dialog.component.html',
  styleUrls: ['./handover-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class HandoverDialogComponent extends AbstractHandoverFormModalComponent {

  private modalService = inject(IczModalService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);
  private apiCirculationTaskHandoverService = inject(ApiCirculationTaskHandoverService);
  private apiCirculationTaskHandoverToOwnHandsService = inject(ApiCirculationTaskHandoverToOwnHandsService);
  private apiCirculationTaskBulkActionsService = inject(ApiCirculationTaskBulkActionsService);
  protected override circulationEntities = injectModalData<Array<DocumentDto | ExtendedCirculationTaskDto>>();

  override form = new IczFormGroup({
    originalOrgStructureElementId: new IczFormControl<Nullable<number>>(undefined, [IczValidators.requiredButAllowNull()]),
    copyOrgStructureElementIds: new IczFormControl<Nullable<Array<number>>>(null),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    deadline: new IczFormControl<Nullable<string>>(null),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });

  disabledOriginalOptionValues$!: Observable<Array<string|number>>;
  disabledCopyOptionValues$!: Observable<Array<string|number>>;

  readonly isDocumentEntity = isDocumentEntity;
  readonly DocumentForm = DocumentForm;

  isTakeoverAndPassMode = false;
  isTakeoverByInitiatorAndPassMode = false;
  isHandoverToOwnHandsTakeoverByInitiatorAndPassMode = false;

  override get groupForSaving(): FunctionalPositionGroupItems {
    const primaryFp = this.form?.get('originalOrgStructureElementId')?.value;
    const nonPrimaryFps = this.form?.get('copyOrgStructureElementIds')?.value;

    return [
      ...(primaryFp ? [primaryFp] : []),
      ...(nonPrimaryFps ?? [])
    ].filter(val => val !== null); // null is reserved for keeping original document for yourself
  }

  protected override selectedFlowType = CirculationActivityFlowType.PARALLEL;

  protected override orgStructureElementIdsControlName = 'copyOrgStructureElementIds';

  private get originalOrgStructureElementId(): number {
    return this.form.get('originalOrgStructureElementId')!.value!;
  }

  private get selectedPrimaryFunctionalPositionId() {
    const orgStructureElementId = this.originalOrgStructureElementId;

    if (orgStructureElementId) {
      return this.orgStructureOptions.find(
        o => o.value === orgStructureElementId && o.originId === 'fp'
      )?.id ?? null;
    }
    else {
      return null;
    }
  }

  private get selectedPrimaryOrganizationalUnitId() {
    const orgStructureElementId = this.originalOrgStructureElementId;

    if (orgStructureElementId) {
      return this.orgStructureOptions.find(
        o => o.value === orgStructureElementId && o.originId === 'ou'
      )?.id ?? null;
    }
    else {
      return null;
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.initializeForm();

    this.isTakeoverAndPassMode = this.circulationEntities.every(
      entity => isTaskEntity(entity) &&
        entity.taskType === CirculationTaskType.TAKEOVER_BY_TARGET
    );
    this.isTakeoverByInitiatorAndPassMode = this.circulationEntities.every(
      entity => isTaskEntity(entity) &&
        entity.taskType === CirculationTaskType.TAKEOVER_REJECTED_BY_INITIATOR
    );
    this.isHandoverToOwnHandsTakeoverByInitiatorAndPassMode = this.circulationEntities.every(
      entity => isTaskEntity(entity) &&
        entity.taskType === CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_REJECTED_BY_INITIATOR
    );

    if (this.isTakeoverAndPassMode || this.isTakeoverByInitiatorAndPassMode || this.isHandoverToOwnHandsTakeoverByInitiatorAndPassMode) {
      const deadlineControl = this.form.get('deadline')!;

      deadlineControl.setValue((this.circulationEntities[0] as ExtendedCirculationTaskDto).deadline);
      deadlineControl.disable();
    }
  }

  submit() {
    const copyTargets = this.orgStructureElementsControl.value;
    const formValue = this.form.value;

    let submit$: Observable<unknown>;

    if (this.isTakeoverAndPassMode || this.isTakeoverByInitiatorAndPassMode || this.isHandoverToOwnHandsTakeoverByInitiatorAndPassMode) {
      const takeoverActionDto: CirculationHandoverTaskTakeoverPassDto = {
        functionalPositions: copyTargets ? this.selectedFunctionalPositions : [],
        organizationalUnits: copyTargets ? this.selectedOrganizationalUnits : [],
        primaryFunctionalPositionId: this.selectedPrimaryFunctionalPositionId!,
        primaryOrganizationalUnitId: this.selectedPrimaryOrganizationalUnitId!,
        targetTaskInstruction: formValue.targetTaskInstruction!,
      };

      if (this.isTakeoverAndPassMode) {
        if (this.isSingleEntityFlow) {
          submit$ = this.apiCirculationTaskHandoverService.circulationTaskHandoverTakeoverAndPass(
            {
              id: this.circulationEntities[0].id,
              body: takeoverActionDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else {
          submit$ = this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsTakeoverAndPass(
            {
              body: this.circulationEntities.map(entity => ({
                id: entity.id,
                ...takeoverActionDto
              })),
            },
            SKIP_ERROR_DIALOG
          );
        }
      }
      else if (this.isTakeoverByInitiatorAndPassMode) {
        submit$ = this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsTakeoverAndPass(
          {
            body: this.circulationEntities.map(entity => ({
              id: entity.id,
              ...takeoverActionDto
            })),
          },
          SKIP_ERROR_DIALOG
        );
      } else {
        submit$ = this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsTakeoverRejectedAndPass(
          {
            id: this.circulationEntities[0].id,
            body: takeoverActionDto,
          },
            SKIP_ERROR_DIALOG
        );
      }
    }
    else {
      const activityCreateDtos: CirculationHandoverActivityCreateDto[] = this.circulationEntities.map(entity => ({
        circulationEntityType: (entity as DocumentDto).entityType!,
        deadline: formValue.deadline!,
        flowType: this.selectedFlowType,
        targetTaskInstruction: formValue.targetTaskInstruction!,
        documentId: entity.id,
        functionalPositions: copyTargets ? this.selectedFunctionalPositions : [],
        organizationalUnits: copyTargets ? this.selectedOrganizationalUnits : [],
        primaryFunctionalPositionId: this.selectedPrimaryFunctionalPositionId!,
        primaryOrganizationalUnitId: this.selectedPrimaryOrganizationalUnitId!,
      }));

      if (this.isSingleEntityFlow) {
        submit$ = this.apiCirculationActivityService.circulationActivityCreateHandover(
          {
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        );
      } else {
        submit$ = this.apiCirculationActivityBulkActionsService.circulationActivityBulkActionsHandover(
          {
            body: activityCreateDtos,
          },
          SKIP_ERROR_DIALOG
        );
      }
    }

    this.loadingService.doLoading(submit$, this).subscribe({
      next: result => {
        this.form.markAsPristine();

        if (this.isSingleEntityFlow) {
          if (this.isTakeoverAndPassMode || this.isTakeoverByInitiatorAndPassMode) { // takeover and pass = result is a task
            this.modalRef.close((result as CirculationTaskDto).activityId);
          }
          else { // handover create = result is an activity
            this.modalRef.close((result as CirculationActivityDto).id);
          }
        } else {
          this.modalRef.close(true);
        }

      },
      error: errorResponse => handleCirculationErrorAsToast(
        this.circulationEntities[0],
        errorResponse,
        this.toastService,
        this.translateService,
        this.modalRef,
        CirculationActivityType.DOCUMENT_HANDOVER,
      ),
    });
  }

  override applySavedGroup(functionalPositionGroup: Nullable<FunctionalPositionGroup>) {
    const functionalPositionGroupItems = functionalPositionGroup?.items;

    if (functionalPositionGroupItems) {
      if (functionalPositionGroupItems.length === 0) {
        return;
      }

      this.modalService.openComponentInModal<PrimaryFunctionalPositionPickerResult, PrimaryFunctionalPositionPickerDialogData>({
        component: PrimaryFunctionalPositionPickerComponent,
        modalOptions: {
          titleTemplate: 'Kterému funkčnímu místu má být předán originál?',
          width: 600,
          height: 500,
          isClosable: false,
        },
        data: {
          selectedGroup: functionalPositionGroupItems,
          showHandCopyToAll: true,
        },
      }).subscribe(result => {
        if (result) {
          const primaryFp = result.originalReceiver;
          const nonPrimaryFps = result.copyReceivers;

          this.form.get('originalOrgStructureElementId')!.setValue(primaryFp as any);

          if (nonPrimaryFps?.length) {
            this.form.get('copyOrgStructureElementIds')!.setValue(nonPrimaryFps as any);
          }
        }
      });
    }
    else {
      this.form.get('originalOrgStructureElementId')!.setValue(undefined); // value `null` is reserved here
      this.form.get('copyOrgStructureElementIds')!.setValue(null);
    }
  }

  private initializeForm() {
    const originalControl = this.form.get('originalOrgStructureElementId')!;
    const copyControl = this.form.get('copyOrgStructureElementIds')!;

    originalControl.setValue(undefined);

    originalControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(value => {
      if (value === null) { // "keep the original" requires the user to pass a copy to someone else
        copyControl.setValidators([IczValidators.required()]);
      } else {
        copyControl.setValidators([]);
      }

      copyControl.updateValueAndValidity();
    });

    this.disabledCopyOptionValues$ = originalControl.valueChanges.pipe(
      map(value => value ? [value] : []),
      map(getRelatedOrgStructureOptionIds.bind(this)),
    );

    this.disabledOriginalOptionValues$ = copyControl.valueChanges.pipe(
      map(value => value ?? []),
      map(getRelatedOrgStructureOptionIds.bind(this)),
    );
  }

}
