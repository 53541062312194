import {Injectable} from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';
import {map} from 'rxjs/operators';
import {CORE_MICROSERVICE_ROOT, Page, Sort} from '../../api';
import {ApiInternalNotificationService, InternalNotificationDto} from '|api/notification';
import {SearchApiService, SearchParams} from '../../services/search-api.service';

// NOTE: this is the same as SearchRecordDtoPage, except content directly contains array of dtos, instead of source prop.
interface NotificationSearchRecordDtoPage {
  content: InternalNotificationDto[];
  empty?: boolean;
  first?: boolean;
  last?: boolean;
  /** format: int32 */
  number?: number;
  /** format: int32 */
  numberOfElements?: number;
  /** format: int32 */
  size?: number;
  sort?: Sort;
  /** format: int64 */
  totalElements: number;
  /** format: int32 */
  totalPages?: number;
}

function unwrapDocuments(): OperatorFunction<NotificationSearchRecordDtoPage, Page<InternalNotificationDto>> {
  return map(page => ({
    ...page,
    content: page.content,
  }));
}

@Injectable({
  providedIn: 'root',
})
export class NotificationSearchService extends SearchApiService {

  findNotifications(searchParams: Partial<SearchParams>): Observable<Page<InternalNotificationDto>> {
    return this.searchApi<NotificationSearchRecordDtoPage>(searchParams, CORE_MICROSERVICE_ROOT + ApiInternalNotificationService.InternalNotificationSearchPath).pipe(
      unwrapDocuments()
    );
  }

}
