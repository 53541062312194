/* eslint-disable */
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormControlOptions,
  FormControlState,
  FormGroup,
  ValidatorFn
} from '@angular/forms';
import {Subject} from 'rxjs';
import {IczValidators} from '../validators/icz-validators/icz-validators';
import {IczValidatorFn} from '../validators/icz-validators/validator-decorators';

type EmitEventOpts = {emitEvent?: boolean, onlySelf?: boolean};

export declare type ɵRawValue<T extends AbstractControl | undefined> = T extends AbstractControl<any, any> ? (T['setValue'] extends ((v: infer R) => void) ? R : never) : never;

declare type ɵIsAny<T, Y, N> = 0 extends (1 & T) ? Y : N;

export declare type ɵTypedOrUntyped<T, Typed, Untyped> = ɵIsAny<T, Untyped, Typed>;

type ɵFormGroupRawValue<T extends {
  [K in keyof T]?: AbstractControl<any>;
}> = ɵTypedOrUntyped<T, {
  [K in keyof T]: ɵRawValue<T[K]>;
}, {
  [key: string]: any;
}>;

export declare type ɵFormArrayRawValue<T extends AbstractControl<any>> = ɵTypedOrUntyped<T, Array<ɵRawValue<T>>, any[]>;

export interface IczFormControlState<T> extends FormControlState<T> {
  important?: boolean;
}

export interface InvalidFormControlInfo {
  controlName: string;
  value: any;
  errors: string[];
}

export enum TextLength {
  TINY = 50,
  SHORT = 255,
  LONG = 4000,
  UNLIMITED = -1, // Might come in handy when some other validator already validates text length
}

export function enableFormFields<TControl extends {[K in keyof TControl]: AbstractControl<any>} = any>(
  form: IczFormGroup<TControl>,
  attributesToEnable: Array<keyof TControl>
) {
  for (const attribute of attributesToEnable) {
    // @ts-ignore
    form.get(attribute)!.enable();
  }
}

/**
 * Method for recursively updating validity status of FormGroup/FormArray. By default, this method prevents parent of
 * `this` FormGroup from beeing notified about changes in validity status. To prevent this behavior use onlySelf = false
 * parameter in opts object
 * @param opts Options for enabling/disabling status changes events
 */
function recursivelyUpdateValueAndValidity(this: IczFormGroup|IczFormArray, opts?: EmitEventOpts) {
  // depth-first traversal
  for (const key in this.controls) {
    if (this.controls.hasOwnProperty(key)) {
      const formElement = this.get(key);
      const recursiveCallOpts = {onlySelf: true, ...(opts ?? {})};

      if (formElement instanceof IczFormControl) {
        formElement.updateValueAndValidity(recursiveCallOpts);
      }
      else if (formElement instanceof IczFormGroup || formElement instanceof IczFormArray) {
        formElement.recursivelyUpdateValueAndValidity(recursiveCallOpts);
      }
    }
  }

  this.updateValueAndValidity({onlySelf: true, ...(opts ?? {})});
}

export function recursivelyListInvalidControls(form: IczFormGroup|IczFormArray, list: InvalidFormControlInfo[]) {
  // depth-first traversal
  for (const key in form.controls) {
    if (form.controls.hasOwnProperty(key)) {
      const formElement = form.get(key);

      if (formElement instanceof IczFormControl && formElement.invalid) {
        list.push({controlName: key, value: formElement.value, errors: Object.keys(formElement.errors ?? {})});
      }
      else if (formElement instanceof IczFormGroup || formElement instanceof IczFormArray) {
        recursivelyListInvalidControls(formElement, list);
      }
    }
  }
}

function recursivelyMarkAsTouched(this: IczFormGroup|IczFormArray) {
  // depth-first traversal
  Object.values(this.controls).forEach(control => {
    control.markAsTouched();

    if (control instanceof IczFormGroup && control.controls) {
      control.markFormGroupTouched();
    }
    if (control instanceof IczFormArray && control.controls) {
      control.markFormArrayTouched();
    }
  });
}

function getMaxLengthValidator(maxLength: number) {
  return maxLength !== TextLength.UNLIMITED ? IczValidators.maxLength(maxLength) : null;
}

export class IczFormGroup<TControl extends { [K in keyof TControl]: AbstractControl<any> } = any> extends FormGroup<TControl> {
  private initialValidators: Record<string, ValidatorFn> = {};

  constructor(
    formState?: TControl,
    validatorOrOpts?: Nullable<ValidatorFn | ValidatorFn[] | FormControlOptions>,
    asyncValidator?: Nullable<AsyncValidatorFn | AsyncValidatorFn[]>,
  ) {
    super(formState!, validatorOrOpts, asyncValidator);

    this.setInitialValidators();
  }

  /**
   * Marks all controls in this form group as touched
   */
  markFormGroupTouched() {
    recursivelyMarkAsTouched.bind(this)();
  }

  recursivelyUpdateValueAndValidity(opts?: EmitEventOpts) {
    recursivelyUpdateValueAndValidity.bind(this)(opts);
  }

  setInitialValidators() {
    Object.entries(this.controls).forEach(([key, value]) => {
      this.initialValidators[key] = value.validator!;
    });
  }

  resetValidatorsToInitial() {
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const formElement = this.get(key);
        if (formElement && this.initialValidators[key]) {
          formElement.validator = this.initialValidators[key];
        }
      }
    }
  }

  areFormControlsValid(formControlNames: Array<keyof TControl>) {
    let out = true;

    // todo(rb) fix those ts-ignores
    for (const formControlName of formControlNames) {
      // Disabled form controls are marked as invalid when calling FormControl.valid.
      // Assuming that disabled form controls are always valid bcs they could not accept user input.
      out &&= (
        // @ts-ignore
        this.controls[formControlName].status === 'VALID' ||
        // @ts-ignore
        this.controls[formControlName].status === 'DISABLED'
      );
    }

    return out;
  }

  // todo(mh) fix the component that made trouble, this is a bit dirty
  /**
   * Overrides setValue method of Angular Form Group to prevent null error in setValue method.
   * When setValue is called on FormGroup angular it takes all controls names in form group and tries to find them on `value` (value[formControlName]).
   * This check fails in error when `value` is null.
   * @param value
   * @param options
   */
  override setValue(value: ɵFormGroupRawValue<TControl>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    if (!isNil(value)) {
      super.setValue(value, options);
    }
  }

  // IczControlFlagChangeListenable - duplicated across IczFormControl, IczFormGroup and IczFormArray.
  //  Keep them in sync pls.

  private _dirtinessChanged = new Subject<boolean>();
  private _touchChanged: Subject<boolean> = new Subject<boolean>();
  dirtinessChanged = this._dirtinessChanged.asObservable();
  touchChanged = this._touchChanged.asObservable();

  override markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this._touchChanged.next(this.touched);
  }
  override markAsUntouched(opts?: { onlySelf?: boolean }) {
    super.markAsUntouched(opts);
    this._touchChanged.next(this.touched);
  }
  override markAsDirty(opts?: { onlySelf?: boolean }) {
    super.markAsDirty(opts);
    this._dirtinessChanged.next(this.dirty);
  }
  override markAsPristine(opts?: { onlySelf?: boolean }) {
    super.markAsPristine(opts);
    this._dirtinessChanged.next(this.dirty);
  }

}

/**
 * Imposes implicit length limit on FormControls with text value type which
 * defaults to 255 characters (originates from default VARCHAR limits in database)
 */
// @ts-ignore
export class IczFormControl<TValue = any> extends FormControl<TValue> { // todo(rb) remove that ts-ignore
  private maxLength: number;
  private _important: Nullable<boolean>;

  constructor(
    formState?: IczFormControlState<TValue> | TValue,
    validatorOrOpts?: Nullable<ValidatorFn | ValidatorFn[] | FormControlOptions>,
    asyncValidator?: Nullable<AsyncValidatorFn | AsyncValidatorFn[]>,
    maxValueLength?: TextLength,
  ) {
    const maxLength = (maxValueLength ?? TextLength.SHORT) as number;
    const maxLengthValidator: Nullable<IczValidatorFn> = getMaxLengthValidator(maxLength);

    if (Array.isArray(validatorOrOpts)) {
      if (maxLengthValidator) {
        validatorOrOpts.push(maxLengthValidator);
      }
    }
    else if (validatorOrOpts instanceof Function) {
      if (maxLengthValidator) {
        validatorOrOpts = [validatorOrOpts, maxLengthValidator];
      }
    }
    else if (validatorOrOpts?.validators) {
      if (!Array.isArray(validatorOrOpts.validators)) {
        if (maxLengthValidator) {
          validatorOrOpts.validators = [validatorOrOpts.validators];
        }
      }
      else if (maxLengthValidator) {
        validatorOrOpts.validators.push(maxLengthValidator);
      }
    }
    else if (maxLengthValidator) { // validatorOrOpts is falsy
      validatorOrOpts = maxLengthValidator;
    }

    super(formState!, validatorOrOpts, asyncValidator);

    // Extending ng forms _isBoxedValue() method:
    // If important is specified in formState, we don't want to use the config object as a value, we want to apply formState.value as value
    if (this._isBoxedValueWithImportant(formState)) {
      this._important = Boolean(formState?.important);
      super.setValue((formState as IczFormControlState<TValue>).value);
    }

    this.maxLength = maxLength;
  }

  get important() {
    return this._important;
  }

  // ngForms _isBoxedValue() internal method is already checking for value + disabled props of formState to be treated as config object
  private _isBoxedValueWithImportant(formState: Nullable<IczFormControlState<TValue> | TValue>): formState is IczFormControlState<TValue> {
    if (formState === null || formState === undefined || typeof formState !== 'object') return false;
    else {
      return (Object.keys(formState).length === 2 && 'value' in formState && 'important' in formState) ||
        (Object.keys(formState).length === 3 && 'value' in formState && 'important' in formState && 'disabled' in formState);
    }
  }

  override reset(formState: IczFormControlState<TValue> | TValue, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    if (this._isBoxedValueWithImportant(formState)) {
      this._important = Boolean((formState as any)?.important);
    }
    super.reset(formState as FormControlState<TValue>, options);
  }

  override setValidators(newValidator: Nullable<ValidatorFn | ValidatorFn[]>): void {
    const maxLengthValidator: Nullable<IczValidatorFn> = getMaxLengthValidator(this.maxLength);

    if (newValidator) {
      if (!Array.isArray(newValidator)) {
        newValidator = [newValidator];
      }

      if (maxLengthValidator) {
        newValidator.push(maxLengthValidator);
      }

      super.setValidators(newValidator);
    }
    else if (maxLengthValidator) {
      super.setValidators(maxLengthValidator);
    }
  }

  // IczControlFlagChangeListenable - duplicated across IczFormControl, IczFormGroup and IczFormArray.
  //  Keep them in sync pls.

  private _dirtinessChanged = new Subject<boolean>();
  private _touchChanged: Subject<boolean> = new Subject<boolean>();
  dirtinessChanged = this._dirtinessChanged.asObservable();
  touchChanged = this._touchChanged.asObservable();

  override markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this._touchChanged.next(this.touched);
  }
  override markAsUntouched(opts?: { onlySelf?: boolean }) {
    super.markAsUntouched(opts);
    this._touchChanged.next(this.touched);
  }
  override markAsDirty(opts?: { onlySelf?: boolean }) {
    super.markAsDirty(opts);
    this._dirtinessChanged.next(this.dirty);
  }
  override markAsPristine(opts?: { onlySelf?: boolean }) {
    super.markAsPristine(opts);
    this._dirtinessChanged.next(this.dirty);
  }

}

export type ControlFactory<TControl> = (newControlIndex: number) => TControl;

export class IczFormArray<TControl extends AbstractControl<any> = any> extends FormArray<TControl> {
  constructor(public createControl: ControlFactory<TControl>, controls: Array<TControl>, ...rest: any[]) {
    super(controls, ...rest);
  }

  override setValue(value: ɵFormArrayRawValue<TControl>, options: {onlySelf?: boolean, emitEvent?: boolean} = {}): void {
    this.setSize(value.length);
    super.setValue(value, options);
  }

  /**
   * Sets specified number of controls in the array
   * @param size of the array
   * @param startFromLast whether to remove beginning from the end of array
   */
  setSize(size: number, startFromLast = false) {
    while (size < this.controls.length) this.removeAt(startFromLast ? this.controls.length - 1 : 0);
    while (size > this.controls.length) this.push(this.createControl(this.controls.length));
  }

  /**
   * Increments IczFormArray controls by calling supplied form builder method.
   * @returns {AbstractControl} control created by calling form builder method
   */
  incrementSize(): TControl {
    const control = this.createControl(this.controls.length);
    this.push(control);
    return control;
  }

  getLastControl(): Nullable<TControl> {
    return this.controls.length > 0 ? this.controls[this.controls.length - 1] as TControl : null;
  }

  removeLastControl() {
    this.setSize(this.controls.length - 1, true);
  }

  recursivelyUpdateValueAndValidity(opts?: EmitEventOpts) {
    recursivelyUpdateValueAndValidity.bind(this)(opts);
  }

  markFormArrayTouched() {
    recursivelyMarkAsTouched.bind(this)();
  }

  // IczControlFlagChangeListenable - duplicated across IczFormControl, IczFormGroup and IczFormArray.
  //  Keep them in sync pls.

  private _dirtinessChanged = new Subject<boolean>();
  private _touchChanged: Subject<boolean> = new Subject<boolean>();
  dirtinessChanged = this._dirtinessChanged.asObservable();
  touchChanged = this._touchChanged.asObservable();

  override markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this._touchChanged.next(this.touched);
  }
  override markAsUntouched(opts?: { onlySelf?: boolean }) {
    super.markAsUntouched(opts);
    this._touchChanged.next(this.touched);
  }
  override markAsDirty(opts?: { onlySelf?: boolean }) {
    super.markAsDirty(opts);
    this._dirtinessChanged.next(this.dirty);
  }
  override markAsPristine(opts?: { onlySelf?: boolean }) {
    super.markAsPristine(opts);
    this._dirtinessChanged.next(this.dirty);
  }

}
