/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EmailNotificationRuleDto } from '../../models/email-notification-rule-dto';
import { EmailNotificationRuleUpdateDto } from '../../models/email-notification-rule-update-dto';

export interface EmailNotificationRulesOauthUpdate$Params {

/**
 * ID of email notification rule
 */
  id: number;
      body: EmailNotificationRuleUpdateDto
}

export function emailNotificationRulesOauthUpdate(http: HttpClient, rootUrl: string, params: EmailNotificationRulesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationRuleDto>> {
  const rb = new RequestBuilder(rootUrl, emailNotificationRulesOauthUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EmailNotificationRuleDto>;
    })
  );
}

emailNotificationRulesOauthUpdate.PATH = '/oauth/email-notification-rules/{id}';