import {Pipe, PipeTransform} from '@angular/core';
import {
  HighlightFragmentPart,
  shouldHighlightEverything
} from '../document-table/components/document-relevance-details/document-relevance-details.component';

@Pipe({
  name: 'shouldHighlightEverything',
  pure: true,
})
export class ShouldHighlightEverythingPipe implements PipeTransform {

  transform(fragmentParts: HighlightFragmentPart[]): boolean {
    return shouldHighlightEverything(fragmentParts);
  }

}
