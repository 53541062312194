import {inject, Injectable} from '@angular/core';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {ApplicationRoute} from '../enums/shared-routes.enum';
import {CurrentSessionService} from './current-session.service';

const BLACKLISTED_ROUTES_FOR_RESTORE = [
  createAbsoluteRoute(ApplicationRoute.LOGIN),
  createAbsoluteRoute(ApplicationRoute.PLACE_SELECT),
];

@Injectable({
  providedIn: 'root'
})
export class RouteRestorationService {

  private currentSessionService = inject(CurrentSessionService);

  // A key-value container from username+FP to route, URL restoration must be user+FP-scoped
  private urlsBeforeLogin: Record<string, Nullable<string>> = {};

  get urlsBeforeLoginStoreKey(): Nullable<string> {
    if (this.currentSessionService.currentUser && this.currentSessionService.currentUserFunctionalPosition) {
      return this.currentSessionService.currentUser.username + this.currentSessionService.currentUserFunctionalPosition.id;
    }
    else {
      return null;
    }
  }

  storeUrlBeforeLogin(url: string) {
    if (!BLACKLISTED_ROUTES_FOR_RESTORE.includes(url) && this.urlsBeforeLoginStoreKey) {
      this.urlsBeforeLogin[this.urlsBeforeLoginStoreKey] = url;
    }
  }

  restoreUrlBeforeLogin(): Nullable<string> {
    if (this.urlsBeforeLoginStoreKey) {
      const url = this.urlsBeforeLogin[this.urlsBeforeLoginStoreKey];
      this.urlsBeforeLogin[this.urlsBeforeLoginStoreKey] = null;

      return url;
    }
    else {
      return null;
    }
  }

}
