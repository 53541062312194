import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ConsignmentValidationResult} from '|api/sad';

export interface ConsignmentValidationDialogResult extends ConsignmentValidationResult {
  alreadyRan: boolean;
  loading: boolean;
}


@Component({
  selector: 'icz-consignment-validation',
  templateUrl: './consignment-validation.component.html',
  styleUrls: ['./consignment-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentValidationComponent {

  @Input({required: true}) consignmentValidationResult: Nullable<ConsignmentValidationDialogResult>;

}
