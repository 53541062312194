/* tslint:disable */
/* eslint-disable */

/**
 * ISSD purpose.
 */
export enum IssdPurpose {
  IMPORT = 'IMPORT',
  INTEGRATION = 'INTEGRATION'
}
