/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageReceivedInternalMessageBaseDto } from '../models/page-received-internal-message-base-dto';
import { ReceivedInternalDigitalMessageDto } from '../models/received-internal-digital-message-dto';
import { receivedInternalMessageFindDigitalInternalMessage } from '../fn/received-internal-message/received-internal-message-find-digital-internal-message';
import { ReceivedInternalMessageFindDigitalInternalMessage$Params } from '../fn/received-internal-message/received-internal-message-find-digital-internal-message';
import { receivedInternalMessageFindPaperInternalMessage } from '../fn/received-internal-message/received-internal-message-find-paper-internal-message';
import { ReceivedInternalMessageFindPaperInternalMessage$Params } from '../fn/received-internal-message/received-internal-message-find-paper-internal-message';
import { receivedInternalMessageLockInternalMessage } from '../fn/received-internal-message/received-internal-message-lock-internal-message';
import { ReceivedInternalMessageLockInternalMessage$Params } from '../fn/received-internal-message/received-internal-message-lock-internal-message';
import { receivedInternalMessageMarkMessageAsRead } from '../fn/received-internal-message/received-internal-message-mark-message-as-read';
import { ReceivedInternalMessageMarkMessageAsRead$Params } from '../fn/received-internal-message/received-internal-message-mark-message-as-read';
import { receivedInternalMessageSearch } from '../fn/received-internal-message/received-internal-message-search';
import { ReceivedInternalMessageSearch$Params } from '../fn/received-internal-message/received-internal-message-search';
import { receivedInternalMessageUnlockInternalMessage } from '../fn/received-internal-message/received-internal-message-unlock-internal-message';
import { ReceivedInternalMessageUnlockInternalMessage$Params } from '../fn/received-internal-message/received-internal-message-unlock-internal-message';
import { ReceivedInternalPaperMessageDto } from '../models/received-internal-paper-message-dto';


/**
 * API for management of received internal messages
 */
@Injectable({ providedIn: 'root' })
export class ApiReceivedInternalMessageService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `receivedInternalMessageMarkMessageAsRead()` */
  static readonly ReceivedInternalMessageMarkMessageAsReadPath = '/sad/received-internal-message/{messageId}/mark';

  /**
   * Marks the internal message as read.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedInternalMessageMarkMessageAsRead()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageMarkMessageAsRead$Response(params: ReceivedInternalMessageMarkMessageAsRead$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedInternalMessageMarkMessageAsRead(this.http, this.rootUrl, params, context);
  }

  /**
   * Marks the internal message as read.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedInternalMessageMarkMessageAsRead$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageMarkMessageAsRead(params: ReceivedInternalMessageMarkMessageAsRead$Params, context?: HttpContext): Observable<void> {
    return this.receivedInternalMessageMarkMessageAsRead$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedInternalMessageFindPaperInternalMessage()` */
  static readonly ReceivedInternalMessageFindPaperInternalMessagePath = '/sad/received-internal-message/{messageId}/paper';

  /**
   * Finds internal paper message for a given id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedInternalMessageFindPaperInternalMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageFindPaperInternalMessage$Response(params: ReceivedInternalMessageFindPaperInternalMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedInternalPaperMessageDto>> {
    return receivedInternalMessageFindPaperInternalMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds internal paper message for a given id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedInternalMessageFindPaperInternalMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageFindPaperInternalMessage(params: ReceivedInternalMessageFindPaperInternalMessage$Params, context?: HttpContext): Observable<ReceivedInternalPaperMessageDto> {
    return this.receivedInternalMessageFindPaperInternalMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedInternalPaperMessageDto>): ReceivedInternalPaperMessageDto => r.body)
    );
  }

  /** Path part for operation `receivedInternalMessageFindDigitalInternalMessage()` */
  static readonly ReceivedInternalMessageFindDigitalInternalMessagePath = '/sad/received-internal-message/{messageId}/digital';

  /**
   * Finds internal digital message for a given id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedInternalMessageFindDigitalInternalMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageFindDigitalInternalMessage$Response(params: ReceivedInternalMessageFindDigitalInternalMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedInternalDigitalMessageDto>> {
    return receivedInternalMessageFindDigitalInternalMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds internal digital message for a given id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedInternalMessageFindDigitalInternalMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageFindDigitalInternalMessage(params: ReceivedInternalMessageFindDigitalInternalMessage$Params, context?: HttpContext): Observable<ReceivedInternalDigitalMessageDto> {
    return this.receivedInternalMessageFindDigitalInternalMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedInternalDigitalMessageDto>): ReceivedInternalDigitalMessageDto => r.body)
    );
  }

  /** Path part for operation `receivedInternalMessageUnlockInternalMessage()` */
  static readonly ReceivedInternalMessageUnlockInternalMessagePath = '/sad/received-internal-message/unlock-internal-message/{messageId}';

  /**
   * Unlock Internal Message.
   *
   * Unlocks Internal message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedInternalMessageUnlockInternalMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageUnlockInternalMessage$Response(params: ReceivedInternalMessageUnlockInternalMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedInternalMessageUnlockInternalMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlock Internal Message.
   *
   * Unlocks Internal message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedInternalMessageUnlockInternalMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageUnlockInternalMessage(params: ReceivedInternalMessageUnlockInternalMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedInternalMessageUnlockInternalMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedInternalMessageSearch()` */
  static readonly ReceivedInternalMessageSearchPath = '/sad/received-internal-message/search-api';

  /**
   * Find all received internal messages based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedInternalMessageSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageSearch$Response(params?: ReceivedInternalMessageSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedInternalMessageBaseDto>> {
    return receivedInternalMessageSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received internal messages based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedInternalMessageSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageSearch(params?: ReceivedInternalMessageSearch$Params|null|undefined, context?: HttpContext): Observable<PageReceivedInternalMessageBaseDto> {
    return this.receivedInternalMessageSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedInternalMessageBaseDto>): PageReceivedInternalMessageBaseDto => r.body)
    );
  }

  /** Path part for operation `receivedInternalMessageLockInternalMessage()` */
  static readonly ReceivedInternalMessageLockInternalMessagePath = '/sad/received-internal-message/lock-internal-message/{messageId}';

  /**
   * Lock Internal Message.
   *
   * Locks Internal message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedInternalMessageLockInternalMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageLockInternalMessage$Response(params: ReceivedInternalMessageLockInternalMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedInternalMessageLockInternalMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Lock Internal Message.
   *
   * Locks Internal message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedInternalMessageLockInternalMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedInternalMessageLockInternalMessage(params: ReceivedInternalMessageLockInternalMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedInternalMessageLockInternalMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
