<icz-validation-errors-list [control]="control" [showValidationStatus]="asPopover">
  <icz-composable-form-autocomplete
    #basisComponent
    class="form-chip-width"
    [value]="__value"
    (valueChange)="_valueChanged($event)"
    (blur)="blur.emit()"
    (cleared)="valueCleared()"
    (optionsListClosed)="optionsListClosed($event)"
    [label]="label"
    [placeholder]="placeholder"
    [options]="_visibleOptions"
    [disabled]="fieldDisabled"
    [clearable]="clearable"
    [required]="required"
    [isMultiselect]="isMultiselect"
    [asPopover]="asPopover"
    [searchIndexer]="searchIndexer"
    [popoverContentTemplate]="popoverContentTemplate"
    [selectedOptionTemplate]="selectedOptionTemplate"
    [optionsDefinitionFactory]="usageSortedOptionsDefinitionFactory">

    <ng-template #selectedOptionTemplate let-context>
      @if (customSelectedOptionTemplate) {
        <!--
        In Angular 16, context.selectedOptions has wrong inferred type thus using $any.
        todo(rb) try to remove $any after upgrading to another version of Angular.
        -->
        @for (so of context.selectedOptions; track so.value) {
          <ng-container
            [ngTemplateOutlet]="$any(customSelectedOptionTemplate)"
            [ngTemplateOutletContext]="{$implicit: {option: so}}">
          </ng-container>
        }
      }
      @if (!customSelectedOptionTemplate) {
        @if (!isMultiselect) {
          @for (so of context.selectedOptions; track so.value) {
            <icz-form-autocomplete-list-text-item
              [option]="so" [iczTooltip]="so.label"
            ></icz-form-autocomplete-list-text-item>
          }
        }
        @if (isMultiselect) {
          <div class="chip-list">
            @for (so of context.selectedOptions; track so.value) {
              <icz-form-autocomplete-chip
                [removable]="!fieldDisabled"
                [iczTooltip]="so.label"
                (removeClicked)="deselectOption(so)"
              >
                {{ so.label | translate }}
              </icz-form-autocomplete-chip>
            }
          </div>
        }
      }
    </ng-template>

    <ng-template #popoverContentTemplate let-context>
      <icz-form-autocomplete-list
        [isLoading]="false"
        [hideDefaultTooltips]="false"
        [searchTermInputPrefix]="searchTermInputPrefix"
        [isMultiselect]="isMultiselect"
        [options]="context.options"
        [selectedOptions]="context.selectedOptions"
        [listItemTemplate]="customOptionTemplate || listItemTemplate"
        [noResultsTemplate]="noResultsTemplate"
        (selectionChanged)="context.selectionChanged($event)"
        (searchTermChanged)="context.searchTermChanged($event)">

        <ng-template #listItemTemplate let-context>
          <icz-form-autocomplete-list-text-item
            [option]="context.option">
          </icz-form-autocomplete-list-text-item>
        </ng-template>

        <ng-template #noResultsTemplate let-context>
          @if (!context.searchTerm) {
            <div class="no-options-found">
              {{ 'Žádný výsledek' | translate }}
            </div>
          }
          @if (context.searchTerm) {
            <div>
              <a class="icz-body-1" iczLinkWithoutHref
                (click)="addChip(context);">
                {{ createNewChipText | translate | interpolate:context }}
              </a>
            </div>
          }
        </ng-template>

        @if (manualValueCommit && isMultiselect) {
          <icz-button
            footer primary
            class="mt-4"
            label="Použít"
            (onAction)="applyChanges()"
          ></icz-button>
        }

      </icz-form-autocomplete-list>
    </ng-template>

  </icz-composable-form-autocomplete>
</icz-validation-errors-list>
