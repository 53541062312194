/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CirculationTaskDto } from '../models/circulation-task-dto';
import { circulationTaskHandoverPass } from '../fn/circulation-task-handover/circulation-task-handover-pass';
import { CirculationTaskHandoverPass$Params } from '../fn/circulation-task-handover/circulation-task-handover-pass';
import { circulationTaskHandoverReject } from '../fn/circulation-task-handover/circulation-task-handover-reject';
import { CirculationTaskHandoverReject$Params } from '../fn/circulation-task-handover/circulation-task-handover-reject';
import { circulationTaskHandoverRevoke } from '../fn/circulation-task-handover/circulation-task-handover-revoke';
import { CirculationTaskHandoverRevoke$Params } from '../fn/circulation-task-handover/circulation-task-handover-revoke';
import { circulationTaskHandoverTakeover } from '../fn/circulation-task-handover/circulation-task-handover-takeover';
import { CirculationTaskHandoverTakeover$Params } from '../fn/circulation-task-handover/circulation-task-handover-takeover';
import { circulationTaskHandoverTakeoverAndPass } from '../fn/circulation-task-handover/circulation-task-handover-takeover-and-pass';
import { CirculationTaskHandoverTakeoverAndPass$Params } from '../fn/circulation-task-handover/circulation-task-handover-takeover-and-pass';
import { circulationTaskHandoverTakeoverRejected } from '../fn/circulation-task-handover/circulation-task-handover-takeover-rejected';
import { CirculationTaskHandoverTakeoverRejected$Params } from '../fn/circulation-task-handover/circulation-task-handover-takeover-rejected';
import { circulationTaskHandoverTakeoverRejectedAndPass } from '../fn/circulation-task-handover/circulation-task-handover-takeover-rejected-and-pass';
import { CirculationTaskHandoverTakeoverRejectedAndPass$Params } from '../fn/circulation-task-handover/circulation-task-handover-takeover-rejected-and-pass';


/**
 * Controller for executing actions on handover document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskHandoverService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskHandoverTakeover()` */
  static readonly CirculationTaskHandoverTakeoverPath = '/flow/handover/{id}/takeover';

  /**
   * Finish task by document takeover by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverTakeover()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverTakeover$Response(params: CirculationTaskHandoverTakeover$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverTakeover(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by document takeover by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverTakeover$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverTakeover(params: CirculationTaskHandoverTakeover$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverTakeover$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverTakeoverRejected()` */
  static readonly CirculationTaskHandoverTakeoverRejectedPath = '/flow/handover/{id}/takeover-rejected';

  /**
   * Finish task by takeover rejected task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverTakeoverRejected()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverTakeoverRejected$Response(params: CirculationTaskHandoverTakeoverRejected$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverTakeoverRejected(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by takeover rejected task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverTakeoverRejected$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverTakeoverRejected(params: CirculationTaskHandoverTakeoverRejected$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverTakeoverRejected$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverTakeoverRejectedAndPass()` */
  static readonly CirculationTaskHandoverTakeoverRejectedAndPassPath = '/flow/handover/{id}/takeover-rejected-and-pass';

  /**
   * Finish task by takeover rejected task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverTakeoverRejectedAndPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverTakeoverRejectedAndPass$Response(params: CirculationTaskHandoverTakeoverRejectedAndPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverTakeoverRejectedAndPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by takeover rejected task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverTakeoverRejectedAndPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverTakeoverRejectedAndPass(params: CirculationTaskHandoverTakeoverRejectedAndPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverTakeoverRejectedAndPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverTakeoverAndPass()` */
  static readonly CirculationTaskHandoverTakeoverAndPassPath = '/flow/handover/{id}/takeover-and-pass';

  /**
   * Finish task by takeover and pass to next targets by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverTakeoverAndPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverTakeoverAndPass$Response(params: CirculationTaskHandoverTakeoverAndPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverTakeoverAndPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by takeover and pass to next targets by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverTakeoverAndPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverTakeoverAndPass(params: CirculationTaskHandoverTakeoverAndPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverTakeoverAndPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverRevoke()` */
  static readonly CirculationTaskHandoverRevokePath = '/flow/handover/{id}/revoke';

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverRevoke$Response(params: CirculationTaskHandoverRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverRevoke(params: CirculationTaskHandoverRevoke$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverReject()` */
  static readonly CirculationTaskHandoverRejectPath = '/flow/handover/{id}/reject';

  /**
   * Finish task by reject task by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverReject$Response(params: CirculationTaskHandoverReject$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by reject task by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverReject(params: CirculationTaskHandoverReject$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverPass()` */
  static readonly CirculationTaskHandoverPassPath = '/flow/handover/{id}/pass';

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverPass$Response(params: CirculationTaskHandoverPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverPass(params: CirculationTaskHandoverPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

}
