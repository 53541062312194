/* tslint:disable */
/* eslint-disable */

/**
 * Rendition type
 */
export enum TransactionLogRenditionType {
  MANUAL = 'MANUAL',
  AUTOMATIC = 'AUTOMATIC'
}
