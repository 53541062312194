import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EssentialsModule} from '../essentials/essentials.module';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {NotificationMessageBodyComponent} from './notification-message-body/notification-message-body.component';
import {NotificationMessageHeaderComponent} from './notification-message-header/notification-message-header.component';
import {
  NotificationTemplateOutletComponent
} from './notification-template-outlet/notification-template-outlet.component';
import {NotificationTypeIconComponent} from './notification-type-icon/notification-type-icon.component';
import {NotificationsSidebarComponent} from './notifications-sidebar/notifications-sidebar.component';
import {ToastCenterComponent} from './toast-center/toast-center.component';
import {ProcessTemplateStructuresPipe} from './toast-center/toast-message/process-template-structures.pipe';
import {ToastMessageComponent} from './toast-center/toast-message/toast-message.component';
import {SharedBusinessComponentsModule} from '../shared-business-components/shared-business-components.module';
import {NotificationMessageDialogComponent} from './notification-message-dialog/notification-message-dialog.component';

@NgModule({
  declarations: [
    NotificationMessageBodyComponent,
    NotificationsSidebarComponent,
    ToastMessageComponent,
    ToastCenterComponent,
    ProcessTemplateStructuresPipe,
    NotificationTemplateOutletComponent,
    NotificationMessageHeaderComponent,
    NotificationTypeIconComponent,
    NotificationMessageDialogComponent,
  ],
  exports: [
    NotificationMessageBodyComponent,
    NotificationsSidebarComponent,
    ToastMessageComponent,
    ToastCenterComponent,
    NotificationMessageHeaderComponent,
    NotificationTypeIconComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    FormElementsModule,
    SharedBusinessComponentsModule,
  ]
})
export class NotificationsModule {
}
