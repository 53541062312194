@if (subject) {
  <div class="row items-center p-4">
    <icz-subject-initials [subject]="subject" class="mr-4"></icz-subject-initials>
    @if (withSplitBorder) {
      <hr class="mx-8">
    }
    <icz-subject-identification-status-icon [subject]="subject" [showIdentifiedAsTag]="showIdentifiedAsTag"></icz-subject-identification-status-icon>
    @if (subject | subjectName; as subjectName) {
      <span class="subject-name" [iczTooltip]="subjectName">{{subjectName}}</span>
    }
    <ng-content select="[afterSubjectName]"></ng-content>
  </div>
}
