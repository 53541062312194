/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AmbivalentSubjectDto } from '|api/commons';
import { subjectRecordCreateOrUpdateRecord } from '../fn/subject-record/subject-record-create-or-update-record';
import { SubjectRecordCreateOrUpdateRecord$Params } from '../fn/subject-record/subject-record-create-or-update-record';
import { SubjectRecordDto } from '|api/commons';
import { subjectRecordGet } from '../fn/subject-record/subject-record-get';
import { SubjectRecordGet$Params } from '../fn/subject-record/subject-record-get';
import { subjectRecordGetSubjectDisplayName } from '../fn/subject-record/subject-record-get-subject-display-name';
import { SubjectRecordGetSubjectDisplayName$Params } from '../fn/subject-record/subject-record-get-subject-display-name';
import { subjectRecordIdentifySubject } from '../fn/subject-record/subject-record-identify-subject';
import { SubjectRecordIdentifySubject$Params } from '../fn/subject-record/subject-record-identify-subject';
import { subjectRecordSearch } from '../fn/subject-record/subject-record-search';
import { SubjectRecordSearch$Params } from '../fn/subject-record/subject-record-search';
import { subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects } from '../fn/subject-record/subject-record-unlink-object-from-all-subjects-and-link-to-new-subjects';
import { SubjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects$Params } from '../fn/subject-record/subject-record-unlink-object-from-all-subjects-and-link-to-new-subjects';
import { subjectRecordUpdateRelationWithSubject } from '../fn/subject-record/subject-record-update-relation-with-subject';
import { SubjectRecordUpdateRelationWithSubject$Params } from '../fn/subject-record/subject-record-update-relation-with-subject';


/**
 * Controller for Subject Record
 */
@Injectable({ providedIn: 'root' })
export class ApiSubjectRecordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `subjectRecordUpdateRelationWithSubject()` */
  static readonly SubjectRecordUpdateRelationWithSubjectPath = '/subject-record/update-relation-with-subject';

  /**
   * Updates the relation between a subject and a related object. Relation subject can be changed or created.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordUpdateRelationWithSubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordUpdateRelationWithSubject$Response(params: SubjectRecordUpdateRelationWithSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return subjectRecordUpdateRelationWithSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates the relation between a subject and a related object. Relation subject can be changed or created.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordUpdateRelationWithSubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordUpdateRelationWithSubject(params: SubjectRecordUpdateRelationWithSubject$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.subjectRecordUpdateRelationWithSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects()` */
  static readonly SubjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjectsPath = '/subject-record/unlink-object-from-all-subjects-and-link-to-new-subjects';

  /**
   * Unlink object from all subjects and link to new if possible.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects$Response(params: SubjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlink object from all subjects and link to new if possible.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects(params: SubjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects$Params, context?: HttpContext): Observable<void> {
    return this.subjectRecordUnlinkObjectFromAllSubjectsAndLinkToNewSubjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectRecordSearch()` */
  static readonly SubjectRecordSearchPath = '/subject-record/search-api';

  /**
   * Finds subjects based on input dto in internal esslg and ISDS.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordSearch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordSearch$Response(params: SubjectRecordSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<AmbivalentSubjectDto>> {
    return subjectRecordSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds subjects based on input dto in internal esslg and ISDS.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordSearch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordSearch(params: SubjectRecordSearch$Params, context?: HttpContext): Observable<AmbivalentSubjectDto> {
    return this.subjectRecordSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<AmbivalentSubjectDto>): AmbivalentSubjectDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordIdentifySubject()` */
  static readonly SubjectRecordIdentifySubjectPath = '/subject-record/identify-subject';

  /**
   * Identifies subject in the ISZR register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordIdentifySubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordIdentifySubject$Response(params: SubjectRecordIdentifySubject$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return subjectRecordIdentifySubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Identifies subject in the ISZR register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordIdentifySubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordIdentifySubject(params: SubjectRecordIdentifySubject$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.subjectRecordIdentifySubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordCreateOrUpdateRecord()` */
  static readonly SubjectRecordCreateOrUpdateRecordPath = '/subject-record/create-or-update';

  /**
   * Creates or updates a subject record based on the provided DTO.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordCreateOrUpdateRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordCreateOrUpdateRecord$Response(params: SubjectRecordCreateOrUpdateRecord$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return subjectRecordCreateOrUpdateRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates or updates a subject record based on the provided DTO.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordCreateOrUpdateRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordCreateOrUpdateRecord(params: SubjectRecordCreateOrUpdateRecord$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.subjectRecordCreateOrUpdateRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordGet()` */
  static readonly SubjectRecordGetPath = '/subject-record/{subjectId}';

  /**
   * Returns subject by its id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordGet$Response(params: SubjectRecordGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return subjectRecordGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns subject by its id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordGet(params: SubjectRecordGet$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.subjectRecordGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordGetSubjectDisplayName()` */
  static readonly SubjectRecordGetSubjectDisplayNamePath = '/subject-record/get-subject-name/{subjectId}';

  /**
   * Gets display name for subject Id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordGetSubjectDisplayName()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordGetSubjectDisplayName$Response(params: SubjectRecordGetSubjectDisplayName$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return subjectRecordGetSubjectDisplayName(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets display name for subject Id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordGetSubjectDisplayName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordGetSubjectDisplayName(params: SubjectRecordGetSubjectDisplayName$Params, context?: HttpContext): Observable<string> {
    return this.subjectRecordGetSubjectDisplayName$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
