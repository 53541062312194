/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { circulationTaskBulkActionsAcknowledge } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-acknowledge';
import { CirculationTaskBulkActionsAcknowledge$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-acknowledge';
import { circulationTaskBulkActionsApprove } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-approve';
import { CirculationTaskBulkActionsApprove$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-approve';
import { circulationTaskBulkActionsDisapprove } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-disapprove';
import { CirculationTaskBulkActionsDisapprove$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-disapprove';
import { circulationTaskBulkActionsGiveStatement } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-give-statement';
import { CirculationTaskBulkActionsGiveStatement$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-give-statement';
import { circulationTaskBulkActionsPass } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-pass';
import { CirculationTaskBulkActionsPass$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-pass';
import { circulationTaskBulkActionsReject } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-reject';
import { CirculationTaskBulkActionsReject$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-reject';
import { circulationTaskBulkActionsReturnEntity } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-return-entity';
import { CirculationTaskBulkActionsReturnEntity$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-return-entity';
import { circulationTaskBulkActionsRevoke } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-revoke';
import { CirculationTaskBulkActionsRevoke$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-revoke';
import { circulationTaskBulkActionsSignAnalog } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-sign-analog';
import { CirculationTaskBulkActionsSignAnalog$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-sign-analog';
import { circulationTaskBulkActionsSignDigital } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-sign-digital';
import { CirculationTaskBulkActionsSignDigital$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-sign-digital';
import { circulationTaskBulkActionsTakeover } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-takeover';
import { CirculationTaskBulkActionsTakeover$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-takeover';
import { circulationTaskBulkActionsTakeoverAndPass } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-takeover-and-pass';
import { CirculationTaskBulkActionsTakeoverAndPass$Params } from '../fn/circulation-task-bulk-actions/circulation-task-bulk-actions-takeover-and-pass';


/**
 * Bulk action endpoints for tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskBulkActionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskBulkActionsTakeover()` */
  static readonly CirculationTaskBulkActionsTakeoverPath = '/flow/task/bulk/takeover';

  /**
   * Takes over documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsTakeover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsTakeover$Response(params: CirculationTaskBulkActionsTakeover$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsTakeover(this.http, this.rootUrl, params, context);
  }

  /**
   * Takes over documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsTakeover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsTakeover(params: CirculationTaskBulkActionsTakeover$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsTakeover$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsTakeoverAndPass()` */
  static readonly CirculationTaskBulkActionsTakeoverAndPassPath = '/flow/task/bulk/takeover-and-pass';

  /**
   * Takes over and then hands over documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsTakeoverAndPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsTakeoverAndPass$Response(params: CirculationTaskBulkActionsTakeoverAndPass$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsTakeoverAndPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Takes over and then hands over documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsTakeoverAndPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsTakeoverAndPass(params: CirculationTaskBulkActionsTakeoverAndPass$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsTakeoverAndPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsSignDigital()` */
  static readonly CirculationTaskBulkActionsSignDigitalPath = '/flow/task/bulk/sign-digital';

  /**
   * Digitally signs components
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsSignDigital()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsSignDigital$Response(params: CirculationTaskBulkActionsSignDigital$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsSignDigital(this.http, this.rootUrl, params, context);
  }

  /**
   * Digitally signs components
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsSignDigital$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsSignDigital(params: CirculationTaskBulkActionsSignDigital$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsSignDigital$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsSignAnalog()` */
  static readonly CirculationTaskBulkActionsSignAnalogPath = '/flow/task/bulk/sign-analog';

  /**
   * Signs analog components
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsSignAnalog()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsSignAnalog$Response(params: CirculationTaskBulkActionsSignAnalog$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsSignAnalog(this.http, this.rootUrl, params, context);
  }

  /**
   * Signs analog components
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsSignAnalog$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsSignAnalog(params: CirculationTaskBulkActionsSignAnalog$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsSignAnalog$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsRevoke()` */
  static readonly CirculationTaskBulkActionsRevokePath = '/flow/task/bulk/revoke';

  /**
   * Revokes activities or removes target participants
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsRevoke$Response(params: CirculationTaskBulkActionsRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revokes activities or removes target participants
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsRevoke(params: CirculationTaskBulkActionsRevoke$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsReturnEntity()` */
  static readonly CirculationTaskBulkActionsReturnEntityPath = '/flow/task/bulk/return';

  /**
   * Returns received documents (consignments) back to registries.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsReturnEntity()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsReturnEntity$Response(params: CirculationTaskBulkActionsReturnEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsReturnEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns received documents (consignments) back to registries.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsReturnEntity$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsReturnEntity(params: CirculationTaskBulkActionsReturnEntity$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsReturnEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsReject()` */
  static readonly CirculationTaskBulkActionsRejectPath = '/flow/task/bulk/reject';

  /**
   * Rejects tasks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsReject$Response(params: CirculationTaskBulkActionsReject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Rejects tasks
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsReject(params: CirculationTaskBulkActionsReject$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsPass()` */
  static readonly CirculationTaskBulkActionsPassPath = '/flow/task/bulk/pass';

  /**
   * Delegates tasks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsPass$Response(params: CirculationTaskBulkActionsPass$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Delegates tasks
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsPass(params: CirculationTaskBulkActionsPass$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsGiveStatement()` */
  static readonly CirculationTaskBulkActionsGiveStatementPath = '/flow/task/bulk/give-statement';

  /**
   * Gives statements for documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsGiveStatement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsGiveStatement$Response(params: CirculationTaskBulkActionsGiveStatement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsGiveStatement(this.http, this.rootUrl, params, context);
  }

  /**
   * Gives statements for documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsGiveStatement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsGiveStatement(params: CirculationTaskBulkActionsGiveStatement$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsGiveStatement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsDisapprove()` */
  static readonly CirculationTaskBulkActionsDisapprovePath = '/flow/task/bulk/disapprove';

  /**
   * Disapproves documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsDisapprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsDisapprove$Response(params: CirculationTaskBulkActionsDisapprove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsDisapprove(this.http, this.rootUrl, params, context);
  }

  /**
   * Disapproves documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsDisapprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsDisapprove(params: CirculationTaskBulkActionsDisapprove$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsDisapprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsApprove()` */
  static readonly CirculationTaskBulkActionsApprovePath = '/flow/task/bulk/approve';

  /**
   * Approves documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsApprove$Response(params: CirculationTaskBulkActionsApprove$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsApprove(this.http, this.rootUrl, params, context);
  }

  /**
   * Approves documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsApprove(params: CirculationTaskBulkActionsApprove$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsApprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskBulkActionsAcknowledge()` */
  static readonly CirculationTaskBulkActionsAcknowledgePath = '/flow/task/bulk/acknowledge';

  /**
   * Acknowledges documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskBulkActionsAcknowledge()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsAcknowledge$Response(params: CirculationTaskBulkActionsAcknowledge$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskBulkActionsAcknowledge(this.http, this.rootUrl, params, context);
  }

  /**
   * Acknowledges documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskBulkActionsAcknowledge$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskBulkActionsAcknowledge(params: CirculationTaskBulkActionsAcknowledge$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskBulkActionsAcknowledge$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
