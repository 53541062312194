@if (column) {
  @if (!customTableCells[column.id!]) {
    @if (formattedCellData; as cellData) {
      <span
        [iczTooltip]="formattedCellData"
        iczTooltipPosition="left"
        >
        {{ cellData }}
      </span>
    }
  }
  @else {
    <ng-container *ngTemplateOutlet="customTableCells[column.id!].template; context: {$implicit: row, column: column}"></ng-container>
  }
}
