import {DistributionClass} from '|api/commons';

import {SubjectAttributeType} from '../model/subject-attribute-type';

export const DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES: Partial<Record<DistributionClass, SubjectAttributeType[]>> = {
  [DistributionClass.ADDRESS_PLACE]: [],
  [DistributionClass.ISDS_BOX]: [
    SubjectAttributeType.DATA_BOX,
  ],
  [DistributionClass.EMAIL_BOX]: [
    SubjectAttributeType.EMAIL,
  ],
  [DistributionClass.ON_PLACE]: []
};

export const DISTRIBUTION_CLASS_EXTENDED_REQUIRED_ATTRIBUTES: Partial<Record<DistributionClass, SubjectAttributeType[]>> = {
  [DistributionClass.ADDRESS_PLACE]: [],
  [DistributionClass.ISDS_BOX]: [
    SubjectAttributeType.DATA_BOX,
  ],
  [DistributionClass.EMAIL_BOX]: [],
  [DistributionClass.ON_PLACE]: []
};
