<div class="tree-height">
  <icz-tree-view
    class="block h-full"
    [searchTerm]="_searchTerm"
    [isMultiChoice]="false"
    [selectionStrategy]="TreeItemSelectionStrategy.SIMPLE"
    [checkedItems]="[]"
    [listItemTemplate]="listItemTemplate"
    [data]="$any(selectedEntityClassPath)">
  </icz-tree-view>

  <ng-template #listItemTemplate let-context>
    <icz-form-autocomplete-list-text-item
      [option]="context.option"
    ></icz-form-autocomplete-list-text-item>
  </ng-template>
</div>
