<div class="gutter">
  <div
    class="preview" [style.aspectRatio]="previewAspectRatio"
    [waiting]="(loadingService.isLoading$(this) | async) || isPreviewLoading"
    [style.backgroundColor]="isPreviewLoading ? 'transparent' : null"
    >
    <img [hidden]="!isBackgroundLoaded || isPreviewLoading" class="background" [src]="backgroundImageDataUrl">
    <img [hidden]="!isBackgroundLoaded || isPreviewLoading || !previewImageDataUrl" class="foreground" [src]="previewImageDataUrl">
    @if (infoText) {
      <div class="info-text">
        <div class="icz-body-strong">{{ infoText | translate }}</div>
      </div>
    }
  </div>
</div>
