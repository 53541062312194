/* tslint:disable */
/* eslint-disable */

/**
 * Operator to describe whether to include or exclude given basicDeliveryServices for the filter for OwnPaperConsignments inside the BulkPostingForm.
 */
export enum BulkPostingFormFilterOperator {
  EXCLUDE = 'EXCLUDE',
  INCLUDE = 'INCLUDE'
}
