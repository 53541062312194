import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {PrimitiveControlValueType} from '../../../form-field';
import {TimeSelectorUsage} from '../time-selector.component';

@Component({
  selector: 'icz-number-counter-field',
  templateUrl: './number-counter-field.component.html',
  styleUrls: ['./number-counter-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberCounterFieldComponent {
  readonly TimepickerUsage = TimeSelectorUsage;

  @Input({required: true})
  minValue = 0;
  @Input({required: true})
  maxValue = Infinity;
  @Input()
  numeralCount = 2;
  @Input({required: true})
  value = 0;
  @Output()
  valueChange = new EventEmitter<number>();
  @Input({required: true})
  timeSelectorUsage = TimeSelectorUsage.FILTER;
  @Input()
  fieldDisabled = false;

  hasNonNumericValue = false;

  @HostBinding('class.icz-invalid')
  get isInvalid(): boolean {
    return (this.value < this.minValue || this.value > this.maxValue) || this.hasNonNumericValue;
  }

  get viewValue(): string {
    return String(this.value).padStart(this.numeralCount, '0');
  }
  set viewValue(newValue: PrimitiveControlValueType) {
    const parsedValue = parseInt(newValue as string, 10);

    if (isNaN(parsedValue)) {
      this.hasNonNumericValue = true;
    }
    else {
      this.hasNonNumericValue = false;

      this.value = parsedValue;

      if (!this.isInvalid) {
        this.valueChange.emit(this.value);
      }
    }
  }

  increment() {
    if (this.value >= this.maxValue || this.value < this.minValue) {
      this.value = this.minValue;
    }
    else {
      ++this.value;
    }

    this.valueChange.emit(this.value);
  }

  decrement() {
    if (this.value <= this.minValue || this.value > this.maxValue) {
      this.value = this.maxValue;
    }
    else {
      --this.value;
    }

    this.valueChange.emit(this.value);
  }

}
