<div class="grid gap-16">
  @for (consignee of selectedConsigneesWithDestination; track consignee.consignee.id) {
    <div>
      <icz-section label="Komu se posílá" [expanded]="false" [alwaysOpened]="true"></icz-section>
      <icz-subject-detail-card
        [subject]="consignee.consignee"
        [areAddressesReadonly]="true"
        [allowSubjectEdit]="areSubjectAndAddressEditable"
        [allowSubjectUnlink]="false"
        [subjectReplacementConfig]="subjectReplacementConfig"
        (subjectRemoved)="consigneeRemoved.emit(consignee.consignee)"
        (subjectEdited)="consigneeEdited.emit($event)"
      ></icz-subject-detail-card>
      @if (isInternalConsignment) {
        <form [formGroup]="specificFieldsForm!" class="mt-16">
          <icz-form-autocomplete label="Zvolte organizační jednotku" formControlName="consigneeOrganizationalUnitId" [options]="organizationalUnits$ | async"></icz-form-autocomplete>
        </form>
      }
      @if (!isOfficeDeskConsignment) {
        <icz-section label="Kam se posílá" [expanded]="false" [alwaysOpened]="true"></icz-section>
      }
      @if (isPaperConsignment) {
        @if (getDestinationAddress(consignee); as destinationAddress) {
          <icz-address-details
            [wrapInCard]="true"
            [requireAddressType]="true"
            [isAddressLabelBold]="true"
            [addressWithType]="addressToAddressWithType(destinationAddress, consignee.consignee, additionalAddresses)"
            [allowAddressEdit]="areSubjectAndAddressEditable"
            [nonpersistentSubjectMode]="isNil(consignee.consignee.id)"
            (addressEdited)="addressEdited.emit($event)"
            >
            <a
              unknownAddressTypeHelp class="icz-body-1" iczLinkWithoutHref
              (click)="wizardStepChangeRequested.emit(ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION)"
              >
              {{ 'Upravit adresu v záložce Popis vypravení' | translate }}
            </a>
          </icz-address-details>
        }
      }
      @if (isEmailConsignment) {
        <icz-card>
          <div class="p-16">
            <span> {{ digitalConsignment?.consigneeEmail }}</span>
          </div>
        </icz-card>
      }
      @if (isDataboxConsignment) {
        <icz-card>
          <div class="p-16 row items-center">
            <icz-icon svgIcon="datova_zprava"></icz-icon>
            <span> {{ digitalConsignment?.consigneeDataBox?.id }}</span>
          </div>
        </icz-card>
      }
      @if (isInternalConsignment) {
        <icz-address-details
          [wrapInCard]="true"
          [isAddressLabelBold]="true"
          [addressWithType]="filingOfficeAddress()"
        ></icz-address-details>
      }
      @if (isTelefaxConsignment) {
        <icz-card>
          <div class="p-16">
            <span> {{ telefaxConsignment?.consigneePhoneNumber }}</span>
          </div>
        </icz-card>
      }
    </div>
  }
  @if (isOfficeDeskConsignment && isPublicPosting) {
    <icz-alert
      heading="Dokument bude vyvěšen na úřední desku bez přiřazení konkrétního adresáta.">
    </icz-alert>
  }
</div>
