import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ApplicationConfigService} from './application-config.service';
import {castStream} from '../../../lib/rxjs';

export function loadApplicationConfig(applicationConfigService: ApplicationConfigService): Observable<void> {
  return applicationConfigService.loadConfig().pipe(
    // Application bootstrap factory will swallow application config load
    // errors caused by missing access tokens at application startup time.
    catchError(_ => {
      return of(null);
    }),
    castStream<void>(),
  );
}
