import {BehaviorSubject, forkJoin, Observable, of, tap} from 'rxjs';
import {castStream} from './rxjs';
import {clone} from 'lodash';

enum CountLoadingStrategy {
  INCREMENT = 'INCREMENT',
  DECREMENT = 'DECREMENT',
  LOAD = 'LOAD',
}

export interface CountChangeCommand {
  strategy: CountLoadingStrategy;
  changeCountBy?: number;
}

export function load(): CountChangeCommand {
  return {
    strategy: CountLoadingStrategy.LOAD,
  };
}

export function increment(incrementBy = 1): CountChangeCommand {
  return {
    strategy: CountLoadingStrategy.INCREMENT,
    changeCountBy: incrementBy,
  };
}

export function decrement(decrementBy = 1): CountChangeCommand {
  return {
    strategy: CountLoadingStrategy.DECREMENT,
    changeCountBy: decrementBy,
  };
}

type CountLoaderFn = (objectId: number) => Observable<number>;

export class ObjectCounts<TCountType extends string> {

  private counts$ = new BehaviorSubject<Partial<Record<TCountType, number>>>({});
  countsChanged$ = this.counts$.asObservable();

  constructor(private countLoaders: Record<TCountType, CountLoaderFn>) {}

  loadAllCounts(objectId: number): Observable<void> {
    const load$ = {} as Record<TCountType, Observable<number>>;

    for (const countType in this.countLoaders) {
      if (this.countLoaders.hasOwnProperty(countType)) {
        load$[countType] = this.countLoaders[countType](objectId);
      }
    }

    return forkJoin(load$).pipe(
      tap(loadResults => this.counts$.next(loadResults)),
      castStream<void>(),
    );
  }

  requestChangeCounts(objectId: number, counterChangeCommands: Partial<Record<TCountType, CountChangeCommand>>): Observable<void> {
    const load$ = {} as Record<TCountType, Observable<number>>;

    for (const countType in counterChangeCommands) {
      if (counterChangeCommands.hasOwnProperty(countType) && this.countLoaders.hasOwnProperty(countType)) {
        const changeCommand = counterChangeCommands[countType]!;

        if (changeCommand.strategy === CountLoadingStrategy.LOAD) {
          load$[countType] = this.countLoaders[countType](objectId).pipe(
            tap(newCount => {
              const counts = clone(this.counts$.value);

              counts[countType] = newCount;

              this.counts$.next(counts);
            }),
          );
        }
        else if (changeCommand.strategy === CountLoadingStrategy.INCREMENT) {
          const counts = clone(this.counts$.value);

          counts[countType] ??= 0;
          counts[countType]! += changeCommand.changeCountBy!;

          load$[countType] = of(counts[countType]!);
        }
        else if (changeCommand.strategy === CountLoadingStrategy.DECREMENT) {
          const counts = clone(this.counts$.value);

          counts[countType] ??= 0;
          counts[countType]! -= changeCommand.changeCountBy!;

          load$[countType] = of(counts[countType]!);
        }
        else {
          load$[countType] = of(-1);
        }
      }
    }

    return forkJoin(load$).pipe(
      tap(newCounts => {
        const oldCounts = clone(this.counts$.value);

        this.counts$.next({
          ...oldCounts,
          ...newCounts,
        });
      }),
      castStream<void>(),
    );
  }

  getCount(countType: TCountType): number {
    return this.counts$.value[countType] ?? 0;
  }

  resetCounts() {
    this.counts$.next({});
  }

}
