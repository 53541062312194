@if (subject) {
  <div class="row items-center p-4">
    <icz-subject-header-info [subject]="subject"></icz-subject-header-info>
    <icz-databox-details [dataBoxes]="dataBoxes" class="my-8"></icz-databox-details>
  </div>
  <div class="flex">
    @for (attribute of orderedAttributes;track attribute) {
      @if ($any(subject.attributes[attribute.key])?.value;as attributeValue) {
        <div class="mr-4 flex subject-row-attribute">
          <span class="font-bold mr-2">{{ attribute.label | translate }}:</span>
          @if (attribute.key === SubjectAttributeType.BIRTH_DATE) {
            <span> {{ ($any(attributeValue) | localizedDate) }}</span>
          } @else if (attribute.key === SubjectAttributeType.EMAIL && emails.length) {
            <span> {{ emails }}</span>
          } @else {
            <span> {{ attributeValue }}</span>
          }
          @if (!$last) {
            <span class="mr-2">, </span>
          }
        </div>
      }
    }
  </div>
}
