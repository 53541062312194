/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentCheckBeforeExtendRequest } from '../../models/document-check-before-extend-request';
import { DocumentCheckBeforeExtendResponse } from '../../models/document-check-before-extend-response';

export interface SignatureCheckBeforeExtend$Params {

/**
 * ID of document
 */
  documentId: number;
      body: DocumentCheckBeforeExtendRequest
}

export function signatureCheckBeforeExtend(http: HttpClient, rootUrl: string, params: SignatureCheckBeforeExtend$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentCheckBeforeExtendResponse>> {
  const rb = new RequestBuilder(rootUrl, signatureCheckBeforeExtend.PATH, 'post');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentCheckBeforeExtendResponse>;
    })
  );
}

signatureCheckBeforeExtend.PATH = '/document/signature/{documentId}/check-before-extend';