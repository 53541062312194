/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagePropertyDefinitionDto } from '../models/page-property-definition-dto';
import { propertyDefinitonOauthSearch } from '../fn/property-definiton-oauth/property-definiton-oauth-search';
import { PropertyDefinitonOauthSearch$Params } from '../fn/property-definiton-oauth/property-definiton-oauth-search';


/**
 * API for OAuth2 protected REST endpoints to handle property definitions
 */
@Injectable({ providedIn: 'root' })
export class ApiPropertyDefinitonOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `propertyDefinitonOauthSearch()` */
  static readonly PropertyDefinitonOauthSearchPath = '/oauth/property-definition/search-api';

  /**
   * Find property definitions based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyDefinitonOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyDefinitonOauthSearch$Response(params?: PropertyDefinitonOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePropertyDefinitionDto>> {
    return propertyDefinitonOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find property definitions based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyDefinitonOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyDefinitonOauthSearch(params?: PropertyDefinitonOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PagePropertyDefinitionDto> {
    return this.propertyDefinitonOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePropertyDefinitionDto>): PagePropertyDefinitionDto => r.body)
    );
  }

}
