import {Component} from '@angular/core';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {AbstractBackendStatusComponent} from '../abstract-backend-status.component';

@Component({
  selector: 'icz-network-unavailable-page',
  templateUrl: './network-unavailable-page.component.html',
  styleUrls: ['./network-unavailable-page.component.scss'],
})
export class NetworkUnavailablePageComponent extends AbstractBackendStatusComponent {
  readonly ApplicationRoute = ApplicationRoute;
}
