/* tslint:disable */
/* eslint-disable */

/**
 * Object class
 */
export enum ObjectClass {
  DOCUMENT = 'DOCUMENT',
  RECEIVED_DOCUMENT = 'RECEIVED_DOCUMENT',
  RECEIVED_DOCUMENT_DIGITAL = 'RECEIVED_DOCUMENT_DIGITAL',
  OWN_DOCUMENT = 'OWN_DOCUMENT',
  OWN_DOCUMENT_DIGITAL = 'OWN_DOCUMENT_DIGITAL',
  CONSIGNMENT = 'CONSIGNMENT',
  FILE = 'FILE',
  FILE_DIGITAL = 'FILE_DIGITAL',
  CASE_FILE = 'CASE_FILE',
  CASE_FILE_DIGITAL = 'CASE_FILE_DIGITAL',
  SUB_FILE = 'SUB_FILE',
  VOLUME = 'VOLUME',
  CASE_VOLUME = 'CASE_VOLUME',
  DOCUMENT_ENTITY_CLASS = 'DOCUMENT_ENTITY_CLASS',
  SUB_FILE_ENTITY_CLASS = 'SUB_FILE_ENTITY_CLASS',
  HIERARCHICAL_ENTITY_CLASS = 'HIERARCHICAL_ENTITY_CLASS',
  ENTITY_CLASS_CASE_FILE = 'ENTITY_CLASS_CASE_FILE',
  CLASSIFICATION_SCHEME = 'CLASSIFICATION_SCHEME',
  DISPOSAL_SCHEDULE = 'DISPOSAL_SCHEDULE',
  DIGITAL_COMPONENT = 'DIGITAL_COMPONENT',
  ANALOG_COMPONENT = 'ANALOG_COMPONENT',
  PAPER_COMPONENT = 'PAPER_COMPONENT',
  PHYSICAL_ITEM_COMPONENT = 'PHYSICAL_ITEM_COMPONENT',
  MEDIUM_COMPONENT = 'MEDIUM_COMPONENT',
  PROOF_OF_DELIVERY = 'PROOF_OF_DELIVERY',
  STORAGE_UNIT = 'STORAGE_UNIT',
  STORAGE_UNIT_DIGITAL = 'STORAGE_UNIT_DIGITAL',
  SHARED_FOLDER = 'SHARED_FOLDER',
  REGISTRY_OFFICE_TRANSFER_INCIDENT = 'REGISTRY_OFFICE_TRANSFER_INCIDENT',
  CIRCULATION_TASK = 'CIRCULATION_TASK'
}
