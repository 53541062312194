import {DestroyRef, Injectable, inject} from '@angular/core';
import {
  ApiReceivedDigitalConsignmentService,
  EpdzErrorActionType,
  EpdzErrorDto,
  ReceivedEpdzMessageRejectionDataDto
} from '|api/sad';
import {EpdzErrorsToolbarDisablers} from './epdz-errors-toolbar.disablers';
import {ToolbarProvider} from '../../table/table-toolbar/table-toolbar-buttons.utils';
import {
  BulkOperationValidationService,
  EsslObjectForValidation
} from '../../../services/bulk-operation-validation.service';
import {GlobalLoadingIndicatorService} from '../../essentials/global-loading-indicator.service';
import {AuthorizedButton} from '../../essentials/authorized-button.service';
import {CommonToolbarDisablers} from '../document-toolbar/services/toolbar-common.disablers';
import {IczModalService} from '../../../services/icz-modal.service';
import {EpdzErrorsOperationValidators} from './epdz-errors.validators';
import {RejectEmailMessageDialogComponent} from '../reject-email-message-dialog/reject-email-message-dialog.component';
import {WebSocketNotificationsService} from '../../notifications/web-socket-notifications.service';
import {EpdzErrorToastService} from '../../../core/services/notifications/epdz-error-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {InternalNotificationMessageCode} from '../../../core/services/notifications/internal-notification.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {getEpdzMessageIcon} from '../shared-document.utils';

export enum EpdzErrorsToolbarView {
  EMAIL_MESSAGE_ERRORS = 'EMAIL_MESSAGE_ERRORS',
  DATABOX_MESSAGE_ERRORS = 'DATABOX_MESSAGE_ERRORS',
  ALL_MESSAGE_ERRORS_ADMIN_VIEW = 'ALL_MESSAGE_ERRORS_ADMIN_VIEW',
}

export enum EpdzToolbarAction {
  ERROR_RESOLVED = 'ERROR_RESOLVED',
}

@Injectable({
  providedIn: 'root'
})
export class EpdzErrorsToolbarButtonsService extends ToolbarProvider<EpdzErrorDto, EpdzToolbarAction, EpdzErrorsToolbarView> {

  private destroyRef = inject(DestroyRef);
  private modalService = inject(IczModalService);
  private epdzErrorToastService = inject(EpdzErrorToastService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  constructor() {
    super();

    this.wsNotificationsService.getMessageListener$(InternalNotificationMessageCode.BULK_EPDZ_ERROR_PROCESSING_SUCCESS).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.announceActionCompleted(EpdzToolbarAction.ERROR_RESOLVED);
    });
  }

  getToolbarButtons(selection: Nullable<EpdzErrorDto[]>, view: EpdzErrorsToolbarView): AuthorizedButton[] {
    return [
      {
        label: 'Znovuzpracovat',
        icon: 'activate',
        action: () => this.processMessageAgain(selection!),
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
        ]
      },
      {
        label: 'Odmítnout',
        icon: 'send_back',
        show: view === EpdzErrorsToolbarView.EMAIL_MESSAGE_ERRORS || view === EpdzErrorsToolbarView.ALL_MESSAGE_ERRORS_ADMIN_VIEW,
        action: () => this.rejectMessage(selection!),
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          EpdzErrorsToolbarDisablers.isMessageNotEmailType(selection),
          EpdzErrorsToolbarDisablers.isMessageWithoutSender(selection),
        ]
      },
      {
        label: 'Vyřadit',
        icon: 'closed',
        show: view === EpdzErrorsToolbarView.ALL_MESSAGE_ERRORS_ADMIN_VIEW,
        action: () => this.disposeMessage(selection!),
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          EpdzErrorsToolbarDisablers.isMessageNotEmailType(selection),
        ]
      },
    ];
  }

  private processMessageAgain(selectedErrors: EpdzErrorDto[]) {
    this.globalLoadingService.doLoading(
      this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentBulkPerformActionOverEpdzError({
        body: {
          bulkActionItems: selectedErrors.map(epdzError => ({
            epdzErrorId: epdzError.id,
            actionType: EpdzErrorActionType.REDELIVER,
          })),
        }
      })
    ).subscribe(() => {
      this.epdzErrorToastService.dispatchProcessingStarted({
        [InternalNotificationKey.COUNT]: selectedErrors.length,
        [InternalNotificationKey.ACTION_TYPE]: EpdzErrorActionType.REDELIVER,
      });
    });
  }

  private rejectMessage(selectedErrors: EpdzErrorDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<EpdzErrorDto>(
      {
        dialogWarningLabel: 'Některé ze zvolených zpráv ({{errorCount}}) nelze odmítnout ze zpracování. Odmítnutí bude provedeno jen s vyhovujícími zprávami ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          EpdzErrorsOperationValidators.isMessageWithoutSender(),
          EpdzErrorsOperationValidators.isMessageNotEmailType(),
        ],
        esslObjects: selectedErrors.map(epdzError => this.epdzErrorToValidationObject(epdzError)),
        authorizedOperations: [],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.modalService.openComponentInModal<ReceivedEpdzMessageRejectionDataDto, void>({
          component: RejectEmailMessageDialogComponent,
          modalOptions: {
            width: 600,
            height: 500,
            titleTemplate: validatedObjects.length === 1 ? 'Odmítnout zprávu' : 'Odmítnout zprávy ({{count}})',
          },
        }).subscribe(result => {
          if (result) {
            this.globalLoadingService.doLoading(
              this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentBulkPerformActionOverEpdzError({
                body: {
                  bulkActionItems: validatedObjects.map(epdzError => ({
                    epdzErrorId: epdzError.id,
                    actionType: EpdzErrorActionType.REJECT,
                    rejectionReason: {
                      rejectionReasonText: result.rejectionReasonText,
                      rejectionReasonAlternativeText: result.rejectionReasonAlternativeText,
                    },
                  })),
                }
              })
            ).subscribe(() => {
              this.epdzErrorToastService.dispatchProcessingStarted({
                [InternalNotificationKey.COUNT]: selectedErrors.length,
                [InternalNotificationKey.ACTION_TYPE]: EpdzErrorActionType.REJECT,
              });
            });
          }
        });
      }
    });
  }

  private disposeMessage(selectedErrors: EpdzErrorDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<EpdzErrorDto>(
      {
        dialogWarningLabel: 'Některé ze zvolených zpráv ({{errorCount}}) nelze vyřadit ze zpracování. Vyřazení bude provedeno jen s vyhovujícími zprávami ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          EpdzErrorsOperationValidators.isMessageNotEmailType(),
        ],
        esslObjects: selectedErrors.map(epdzError => this.epdzErrorToValidationObject(epdzError)),
        authorizedOperations: [],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.globalLoadingService.doLoading(
          this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentBulkPerformActionOverEpdzError({
            body: {
              bulkActionItems: selectedErrors.map(epdzError => ({
                epdzErrorId: epdzError.id,
                actionType: EpdzErrorActionType.CANCEL,
              })),
            }
          })
        ).subscribe(() => {
          this.epdzErrorToastService.dispatchProcessingStarted({
            [InternalNotificationKey.COUNT]: selectedErrors.length,
            [InternalNotificationKey.ACTION_TYPE]: EpdzErrorActionType.CANCEL,
          });
        });
      }
    });
  }

  private epdzErrorToValidationObject(epdzError: EpdzErrorDto): EsslObjectForValidation<EpdzErrorDto> {
    return {
      entityId: epdzError.id!,
      entityIcon: getEpdzMessageIcon(epdzError.messageType!),
      entityName: epdzError.subject!,
      authorizedEntityType: null,
      entityData: epdzError,
    };
  }

}
