import {CirculationActivityState, CirculationActivityType, CirculationTaskState} from '|api/commons';
import {ColumnDefinition} from '../../table/table.models';
import {FilterType} from '../../table/filter.types';
import {TableColumnsData} from '../../table/table-columns-data';
import {CirculationView} from '../document-toolbar/services/toolbar-common.utils';
import {DocumentFiltersDataService} from '../document-table/components/document-filters/document-filters-data.service';
import {Option} from '../../../model';
import {enumToOptions} from '../../../core/services/data-mapping.utils';


export enum CirculationTableColumn {
  ACTIVITY_TYPE = 'activityType',
  ACTIVITY_STATE = 'activityState',
  LEAF_TARGET_PARTICIPANTS = 'leafTargetParticipants',
  LEAF_TARGET_PARTICIPANT_ORG_UNITS = 'leafTargetParticipantOrgunits',
  INIT_DATE = 'initDate',
  INIT_FUNCTIONAL_POSITION_ID = 'initFunctionalPositionId',
  ACTIVITY_NOTES = 'notes',
  SELECTION = 'selection',
  OBJECT_CLASS = 'objectClass',
  REF_NUMBER = 'refNumber',
  SUBJECT = 'subject',
  ASSIGNMENT_DATE = 'assignmentDate',
  ORDER_BY_DOCUMENT = 'orderByDocument',
  HAS_TASKS = 'hasTasks',
  DEADLINE = 'deadline',
  OWNER_FUNCTIONAL_POSITION_ID = 'ownerFunctionalPositionId',
  OWNER_ORG_UNIT_ID = 'ownerOrgUnitId',
  TASK_TYPE = 'taskType',
  TASK_STATE = 'taskState',
  TASK_INSTRUCTION = 'instruction',
  COMPONENT_NAME = 'componentName',
  SIGNATURES = 'signatures',
  COMPONENT_ID = 'componentId',
  ISSD_ID = 'issdId',
  REJECTION_NOTE = 'rejectionNote',
  REJECTION_REASON = 'rejectionReason',
  IDENTIFIER = 'uid',
  REPRESENTING_SUBJECT = 'representingSubject',
  TARGET_TASK_INSTRUCTION = 'targetTaskInstruction',
  TASK_AUTHOR_FUNCTIONAL_POSITION_ID = 'taskAuthorFunctionalPositionId',
  TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID = 'taskAuthorOrganizationalUnitId',
  INIT_ORG_UNIT_ID = 'initOrgUnitId',
  NUMBER_OF_ANALOG_COMPONENT_COPIES_TO_SIGN = 'numberOfAnalogComponentCopiesToSign',
}

interface CirculationTableColumnConfiguration {
  columnId: CirculationTableColumn;
  displayed: boolean;
}

const tasksInProgressColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REPRESENTING_SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_INSTRUCTION,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REJECTION_REASON,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.OWNER_ORG_UNIT_ID,
    displayed: false,
  }
];

const unitTasksInProgressColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_TYPE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REPRESENTING_SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_INSTRUCTION,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REJECTION_REASON,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.OWNER_ORG_UNIT_ID,
    displayed: false,
  },
];

const tasksDoneColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_TYPE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REPRESENTING_SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REJECTION_REASON,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.OWNER_ORG_UNIT_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_INSTRUCTION,
    displayed: false,
  },
];

const unitTasksDoneColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_TYPE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REPRESENTING_SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REJECTION_REASON,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_INSTRUCTION,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.OWNER_ORG_UNIT_ID,
    displayed: false,
  }
];

const componentTasksColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_NAME,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TASK_INSTRUCTION,
    displayed: false,
  },
];

const issdTasksColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ISSD_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: true,
  },
];

const issdRejectedTasksColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ISSD_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REJECTION_NOTE,
    displayed: true,
  },
];

const componentActivitiesDoneColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.TASK_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ASSIGNMENT_DATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_NOTES,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_NAME,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: true,
  },
];

const documentActivitiesColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ORDER_BY_DOCUMENT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_TYPE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.LEAF_TARGET_PARTICIPANTS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_DATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.NUMBER_OF_ANALOG_COMPONENT_COPIES_TO_SIGN,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_NOTES,
    displayed: true,
  },
];

const activitiesColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.LEAF_TARGET_PARTICIPANTS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_TYPE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REPRESENTING_SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_DATE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TARGET_TASK_INSTRUCTION,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_NOTES,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_ORG_UNIT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.LEAF_TARGET_PARTICIPANT_ORG_UNITS,
    displayed: false,
  }
];

const unitActivitiesColumnSet: CirculationTableColumnConfiguration[] = [
  {
    columnId: CirculationTableColumn.SELECTION,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.OBJECT_CLASS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.HAS_TASKS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.IDENTIFIER,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REF_NUMBER,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.REPRESENTING_SUBJECT,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.SIGNATURES,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.LEAF_TARGET_PARTICIPANTS,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_TYPE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.COMPONENT_ID,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_STATE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_DATE,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.TARGET_TASK_INSTRUCTION,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.DEADLINE,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.ACTIVITY_NOTES,
    displayed: true,
  },
  {
    columnId: CirculationTableColumn.INIT_ORG_UNIT_ID,
    displayed: false,
  },
  {
    columnId: CirculationTableColumn.LEAF_TARGET_PARTICIPANT_ORG_UNITS,
    displayed: false,
  },
];

export const columnNames: Record<CirculationTableColumn, string> = {
  [CirculationTableColumn.SELECTION]: 'Výběr',
  [CirculationTableColumn.OBJECT_CLASS]: 'Druh',
  [CirculationTableColumn.REF_NUMBER]: 'ČJ/SZ',
  [CirculationTableColumn.SUBJECT]: 'Věc',
  [CirculationTableColumn.ASSIGNMENT_DATE]: 'Založen',
  [CirculationTableColumn.HAS_TASKS]: 'Má úkoly',
  [CirculationTableColumn.DEADLINE]: 'Termín úkolu',
  [CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID]: 'Řešitel',
  [CirculationTableColumn.OWNER_ORG_UNIT_ID]: 'Útvar řešitele',
  [CirculationTableColumn.TASK_TYPE]: 'Úkol',
  [CirculationTableColumn.TASK_STATE]: 'Stav úkolu',
  [CirculationTableColumn.TASK_INSTRUCTION]: 'Instrukce úkolu',
  [CirculationTableColumn.ACTIVITY_NOTES]: 'Důvod odmítnutí/vrácení',
  [CirculationTableColumn.ACTIVITY_TYPE]: 'Úkon',
  [CirculationTableColumn.ACTIVITY_STATE]: 'Stav úkolu',
  [CirculationTableColumn.LEAF_TARGET_PARTICIPANTS]: 'Řešitelé',
  [CirculationTableColumn.LEAF_TARGET_PARTICIPANT_ORG_UNITS]: 'Útvary řešitelů',
  [CirculationTableColumn.INIT_DATE]: 'Datum předání',
  [CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID]: 'Zadavatel úkolu',
  [CirculationTableColumn.INIT_ORG_UNIT_ID]: 'Útvar zadavatele',
  [CirculationTableColumn.ORDER_BY_DOCUMENT]: 'Pořadové číslo',
  [CirculationTableColumn.COMPONENT_NAME]: 'Název komponenty',
  [CirculationTableColumn.SIGNATURES]: 'Podpis komponenty',
  [CirculationTableColumn.COMPONENT_ID]: 'Komponenta úkolu',
  [CirculationTableColumn.ISSD_ID]: 'Externí aplikace',
  [CirculationTableColumn.REJECTION_NOTE]: 'Důvod odmítnutí/vrácení',
  [CirculationTableColumn.REJECTION_REASON]: 'Důvod odmítnutí/vrácení',
  [CirculationTableColumn.TARGET_TASK_INSTRUCTION]: 'Instrukce',
  [CirculationTableColumn.IDENTIFIER]: 'UID - Unikátní identifikátor',
  [CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID]: 'Zadavatel',
  [CirculationTableColumn.TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID]: 'Útvar zadavatele',
  [CirculationTableColumn.REPRESENTING_SUBJECT]: 'Reprezentující subjekt',
  [CirculationTableColumn.NUMBER_OF_ANALOG_COMPONENT_COPIES_TO_SIGN]: 'Počet listin k podpisu',
};

export function getColumnsForView(viewType: CirculationView): CirculationTableColumnConfiguration[] {
  switch (viewType) {
    case CirculationView.TASKS_IN_PROGRESS:
      return tasksInProgressColumnSet;
    case CirculationView.UNIT_TASKS_IN_PROGRESS:
      return unitTasksInProgressColumnSet;
    case CirculationView.TASKS_DONE:
      return tasksDoneColumnSet;
    case CirculationView.UNIT_TASKS_DONE:
      return unitTasksDoneColumnSet;
    case CirculationView.ISSD_TASKS_REJECTED:
      return issdRejectedTasksColumnSet;
    case CirculationView.ISSD_TASKS:
      return issdTasksColumnSet;
    case CirculationView.ACTIVITIES_IN_PROGRESS:
      return activitiesColumnSet;
    case CirculationView.UNIT_ACTIVITIES_IN_PROGRESS:
      return unitActivitiesColumnSet;
    case CirculationView.DOCUMENT_ACTIVITIES:
      return documentActivitiesColumnSet;
    case CirculationView.COMPONENT_TASKS_TO_SIGN:
    case CirculationView.COMPONENT_TASKS_TO_APPROVE:
      return componentTasksColumnSet;
    case CirculationView.COMPONENT_TASKS_DONE:
      return componentActivitiesDoneColumnSet;
    default:
      return [];
  }
}

export function getColumnsData(
  documentFiltersDataService: DocumentFiltersDataService,
  functionalPositionOptions: Option[],
  organizationalUnitOptions: Option[],
  issdApps: Option[],
  columns: CirculationTableColumnConfiguration[],
  tableView: CirculationView,
) {
  const columnDefinitions: ColumnDefinition<CirculationTableColumn>[] = [];
  columns.forEach(column => {
    let columnDefinition: ColumnDefinition<CirculationTableColumn>;
    switch (column.columnId) {
      case CirculationTableColumn.SELECTION:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NONE
        };
        break;

      case CirculationTableColumn.OBJECT_CLASS:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          fixedWidth: 40,
          disableSort: true,
          filterType: FilterType.ENUM,
          filterLabel: 'Druh objektu',
          list: documentFiltersDataService.objectClassOptions,
        };
        break;

      case CirculationTableColumn.REF_NUMBER:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.TEXT,
          toggleable: false,
        };
        break;

      case CirculationTableColumn.SUBJECT:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.TEXT,
        };
        break;

      case CirculationTableColumn.ASSIGNMENT_DATE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.DATETIME,
        };
        break;

      case CirculationTableColumn.HAS_TASKS:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          fixedWidth: 32,
          icon: 'task_faded',
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.DEADLINE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.DATE,
        };
        break;

      case CirculationTableColumn.OWNER_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          disableSort: true,
          filterType: FilterType.CODEBOOK,
          list: functionalPositionOptions,
          filterConfig: {
            originId: 'fp',
            isTree: true,
          }
        };
        break;

      case CirculationTableColumn.OWNER_ORG_UNIT_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          disableSort: true,
          filterType: FilterType.CODEBOOK,
          list: organizationalUnitOptions,
          filterConfig: {
            originId: 'ou',
            isTree: true,
          }
        };
        break;

      case CirculationTableColumn.TASK_TYPE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.ENUM,
          list: documentFiltersDataService.taskTypeOptions,
          disableSort: true,
          allowTranslation: true,
          translateWithPrefix: 'en.circulationTaskType.',
        };
        break;

      case CirculationTableColumn.TASK_STATE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.ENUM,
          list: enumToOptions('circulationTaskState', CirculationTaskState),
          disableSort: true,
          allowTranslation: true,
          translateWithPrefix: 'en.circulationTaskState.',
        };
        break;

      case CirculationTableColumn.COMPONENT_NAME:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.SIGNATURES:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.TASK_INSTRUCTION:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.TEXT,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.ORDER_BY_DOCUMENT:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NUMBER,
          fixedWidth: 32,
          hideLabelInHeader: true,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.ACTIVITY_TYPE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.ENUM,
          list: enumToOptions('circulationActivityType', CirculationActivityType),
          disableSort: true,
        };
        break;

      case CirculationTableColumn.ACTIVITY_STATE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.ENUM,
          list: enumToOptions('circulationActivityState', CirculationActivityState),
          disableSort: true,
        };
        break;

      case CirculationTableColumn.LEAF_TARGET_PARTICIPANTS:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          disableSort: true,
          list: functionalPositionOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
          },
        };
        break;

      case CirculationTableColumn.LEAF_TARGET_PARTICIPANT_ORG_UNITS:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          disableSort: true,
          list: organizationalUnitOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'ou',
            isTree: true,
          },
        };
        break;

      case CirculationTableColumn.ISSD_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.ENUM,
          filterLabel: 'Externí aplikace',
          list: issdApps,
          disableSort: true,
          allowTranslation: false,
        };
        break;

      case CirculationTableColumn.INIT_DATE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.DATETIME,
        };
        break;

      case CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.CODEBOOK,
          list: functionalPositionOptions,
          displayed: tableView !== CirculationView.ACTIVITIES_IN_PROGRESS,
          disableSort: true,
          filterConfig: {
            originId: 'fp',
            isTree: true,
          },
        };
        break;

      case CirculationTableColumn.ACTIVITY_NOTES:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.COMPONENT_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.REJECTION_NOTE:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case CirculationTableColumn.REJECTION_REASON:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.TEXT,
        };
        break;

      case CirculationTableColumn.IDENTIFIER:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.TEXT,
        };
        break;

      case CirculationTableColumn.TASK_AUTHOR_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.CODEBOOK,
          list: functionalPositionOptions,
          disableSort: true,
          filterConfig: {
            originId: 'fp',
            isTree: true,
          },
        };
        break;

      case CirculationTableColumn.TASK_AUTHOR_ORGANIZATIONAL_UNIT_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.CODEBOOK,
          list: organizationalUnitOptions,
          disableSort: true,
          filterConfig: {
            originId: 'ou',
            isTree: true,
          },
        };
        break;

      case CirculationTableColumn.INIT_ORG_UNIT_ID:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.CODEBOOK,
          list: organizationalUnitOptions,
          disableSort: true,
          filterConfig: {
            originId: 'ou',
            isTree: true,
          },
        };
        break;

      case CirculationTableColumn.REPRESENTING_SUBJECT:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          disableSort: true,
          filterType: FilterType.SUBJECT_RECORD
        };
        break;

      case CirculationTableColumn.TARGET_TASK_INSTRUCTION:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.TEXT
        };
        break;

      case CirculationTableColumn.NUMBER_OF_ANALOG_COMPONENT_COPIES_TO_SIGN:
        columnDefinition = {
          id: column.columnId,
          label: columnNames[column.columnId],
          filterType: FilterType.NUMBER,
          disableSort: true,
        };
        break;
    }

    columnDefinition.displayed = column.displayed;

    columnDefinitions.push(columnDefinition);
  });
  return new TableColumnsData<CirculationTableColumn>(columnDefinitions);
}
