/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { IssdImportConfigurationDto } from '../models/issd-import-configuration-dto';
import { issdImportConfigurationOauthCreate } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-create';
import { IssdImportConfigurationOauthCreate$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-create';
import { issdImportConfigurationOauthDeleteById } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-delete-by-id';
import { IssdImportConfigurationOauthDeleteById$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-delete-by-id';
import { issdImportConfigurationOauthFind } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find';
import { IssdImportConfigurationOauthFind$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find';
import { issdImportConfigurationOauthFindAll } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find-all';
import { IssdImportConfigurationOauthFindAll$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find-all';
import { issdImportConfigurationOauthFindAllIssdTypes } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find-all-issd-types';
import { IssdImportConfigurationOauthFindAllIssdTypes$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find-all-issd-types';
import { issdImportConfigurationOauthFindAllNotUsedIssdTypes } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find-all-not-used-issd-types';
import { IssdImportConfigurationOauthFindAllNotUsedIssdTypes$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-find-all-not-used-issd-types';
import { issdImportConfigurationOauthUpdate } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-update';
import { IssdImportConfigurationOauthUpdate$Params } from '../fn/issd-import-configuration-oauth/issd-import-configuration-oauth-update';
import { IssdTypeDto } from '../models/issd-type-dto';
import { PageIssdImportConfigurationDto } from '../models/page-issd-import-configuration-dto';


/**
 * API for management of issd import configurations.
 */
@Injectable({ providedIn: 'root' })
export class ApiIssdImportConfigurationOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `issdImportConfigurationOauthUpdate()` */
  static readonly IssdImportConfigurationOauthUpdatePath = '/oauth/issd-import-configuration/{id}';

  /**
   * Update issd import configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdImportConfigurationOauthUpdate$Response(params: IssdImportConfigurationOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdImportConfigurationDto>> {
    return issdImportConfigurationOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update issd import configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdImportConfigurationOauthUpdate(params: IssdImportConfigurationOauthUpdate$Params, context?: HttpContext): Observable<IssdImportConfigurationDto> {
    return this.issdImportConfigurationOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdImportConfigurationDto>): IssdImportConfigurationDto => r.body)
    );
  }

  /** Path part for operation `issdImportConfigurationOauthDeleteById()` */
  static readonly IssdImportConfigurationOauthDeleteByIdPath = '/oauth/issd-import-configuration/{id}';

  /**
   * Delete IssdImportConfiguration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthDeleteById$Response(params: IssdImportConfigurationOauthDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return issdImportConfigurationOauthDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete IssdImportConfiguration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthDeleteById(params: IssdImportConfigurationOauthDeleteById$Params, context?: HttpContext): Observable<void> {
    return this.issdImportConfigurationOauthDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `issdImportConfigurationOauthCreate()` */
  static readonly IssdImportConfigurationOauthCreatePath = '/oauth/issd-import-configuration';

  /**
   * Creates new issd import configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdImportConfigurationOauthCreate$Response(params: IssdImportConfigurationOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdImportConfigurationDto>> {
    return issdImportConfigurationOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new issd import configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdImportConfigurationOauthCreate(params: IssdImportConfigurationOauthCreate$Params, context?: HttpContext): Observable<IssdImportConfigurationDto> {
    return this.issdImportConfigurationOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdImportConfigurationDto>): IssdImportConfigurationDto => r.body)
    );
  }

  /** Path part for operation `issdImportConfigurationOauthFindAllNotUsedIssdTypes()` */
  static readonly IssdImportConfigurationOauthFindAllNotUsedIssdTypesPath = '/oauth/issd-import-configuration/types';

  /**
   * Find all Issd types that are allowed and not used for a given organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthFindAllNotUsedIssdTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFindAllNotUsedIssdTypes$Response(params?: IssdImportConfigurationOauthFindAllNotUsedIssdTypes$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdTypeDto>>> {
    return issdImportConfigurationOauthFindAllNotUsedIssdTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Issd types that are allowed and not used for a given organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthFindAllNotUsedIssdTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFindAllNotUsedIssdTypes(params?: IssdImportConfigurationOauthFindAllNotUsedIssdTypes$Params|null|undefined, context?: HttpContext): Observable<Array<IssdTypeDto>> {
    return this.issdImportConfigurationOauthFindAllNotUsedIssdTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdTypeDto>>): Array<IssdTypeDto> => r.body)
    );
  }

  /** Path part for operation `issdImportConfigurationOauthFind()` */
  static readonly IssdImportConfigurationOauthFindPath = '/oauth/issd-import-configuration/search-api';

  /**
   * Finds all issd import configuration instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFind$Response(params?: IssdImportConfigurationOauthFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageIssdImportConfigurationDto>> {
    return issdImportConfigurationOauthFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all issd import configuration instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFind(params?: IssdImportConfigurationOauthFind$Params|null|undefined, context?: HttpContext): Observable<PageIssdImportConfigurationDto> {
    return this.issdImportConfigurationOauthFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageIssdImportConfigurationDto>): PageIssdImportConfigurationDto => r.body)
    );
  }

  /** Path part for operation `issdImportConfigurationOauthFindAll()` */
  static readonly IssdImportConfigurationOauthFindAllPath = '/oauth/issd-import-configuration/findAll';

  /**
   * Finds all issd import configuration instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFindAll$Response(params?: IssdImportConfigurationOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdImportConfigurationDto>>> {
    return issdImportConfigurationOauthFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all issd import configuration instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFindAll(params?: IssdImportConfigurationOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<IssdImportConfigurationDto>> {
    return this.issdImportConfigurationOauthFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdImportConfigurationDto>>): Array<IssdImportConfigurationDto> => r.body)
    );
  }

  /** Path part for operation `issdImportConfigurationOauthFindAllIssdTypes()` */
  static readonly IssdImportConfigurationOauthFindAllIssdTypesPath = '/oauth/issd-import-configuration/findAllTypes';

  /**
   * Find all Issd types that are allowed for a given organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdImportConfigurationOauthFindAllIssdTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFindAllIssdTypes$Response(params?: IssdImportConfigurationOauthFindAllIssdTypes$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdTypeDto>>> {
    return issdImportConfigurationOauthFindAllIssdTypes(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Issd types that are allowed for a given organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdImportConfigurationOauthFindAllIssdTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdImportConfigurationOauthFindAllIssdTypes(params?: IssdImportConfigurationOauthFindAllIssdTypes$Params|null|undefined, context?: HttpContext): Observable<Array<IssdTypeDto>> {
    return this.issdImportConfigurationOauthFindAllIssdTypes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdTypeDto>>): Array<IssdTypeDto> => r.body)
    );
  }

}
