/* tslint:disable */
/* eslint-disable */

/**
 * State of circulation activity
 */
export enum CirculationActivityState {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  REVOKED = 'REVOKED'
}
