/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { nomenclatureSynchronizationFindNomenclaturesSynchronization } from '../fn/nomenclature-synchronization/nomenclature-synchronization-find-nomenclatures-synchronization';
import { NomenclatureSynchronizationFindNomenclaturesSynchronization$Params } from '../fn/nomenclature-synchronization/nomenclature-synchronization-find-nomenclatures-synchronization';
import { nomenclatureSynchronizationNomenclatureSynchronize } from '../fn/nomenclature-synchronization/nomenclature-synchronization-nomenclature-synchronize';
import { NomenclatureSynchronizationNomenclatureSynchronize$Params } from '../fn/nomenclature-synchronization/nomenclature-synchronization-nomenclature-synchronize';
import { PageNomenclatureSynchronizationDto } from '../models/page-nomenclature-synchronization-dto';


/**
 * Contains endpoints related to nomenclature synchronization with electronic registry office, e.g. DESA
 */
@Injectable({ providedIn: 'root' })
export class ApiNomenclatureSynchronizationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `nomenclatureSynchronizationNomenclatureSynchronize()` */
  static readonly NomenclatureSynchronizationNomenclatureSynchronizePath = '/nomenclature-synchronization/{nomenclatureType}/synchronize';

  /**
   * Triggers manual nomenclature synchronization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nomenclatureSynchronizationNomenclatureSynchronize()` instead.
   *
   * This method doesn't expect any request body.
   */
  nomenclatureSynchronizationNomenclatureSynchronize$Response(params: NomenclatureSynchronizationNomenclatureSynchronize$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return nomenclatureSynchronizationNomenclatureSynchronize(this.http, this.rootUrl, params, context);
  }

  /**
   * Triggers manual nomenclature synchronization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `nomenclatureSynchronizationNomenclatureSynchronize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  nomenclatureSynchronizationNomenclatureSynchronize(params: NomenclatureSynchronizationNomenclatureSynchronize$Params, context?: HttpContext): Observable<void> {
    return this.nomenclatureSynchronizationNomenclatureSynchronize$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `nomenclatureSynchronizationFindNomenclaturesSynchronization()` */
  static readonly NomenclatureSynchronizationFindNomenclaturesSynchronizationPath = '/nomenclature-synchronization/search-api';

  /**
   * Find nomenclatures with status of synchronization with DESA
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `nomenclatureSynchronizationFindNomenclaturesSynchronization()` instead.
   *
   * This method doesn't expect any request body.
   */
  nomenclatureSynchronizationFindNomenclaturesSynchronization$Response(params?: NomenclatureSynchronizationFindNomenclaturesSynchronization$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageNomenclatureSynchronizationDto>> {
    return nomenclatureSynchronizationFindNomenclaturesSynchronization(this.http, this.rootUrl, params, context);
  }

  /**
   * Find nomenclatures with status of synchronization with DESA
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `nomenclatureSynchronizationFindNomenclaturesSynchronization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  nomenclatureSynchronizationFindNomenclaturesSynchronization(params?: NomenclatureSynchronizationFindNomenclaturesSynchronization$Params|null|undefined, context?: HttpContext): Observable<PageNomenclatureSynchronizationDto> {
    return this.nomenclatureSynchronizationFindNomenclaturesSynchronization$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageNomenclatureSynchronizationDto>): PageNomenclatureSynchronizationDto => r.body)
    );
  }

}
