import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {InternalNotificationDto, InternalNotificationKey, InternalNotificationParameterDto} from '|api/notification';
import {
  getBulkToastHeaderTemplateTranslateKey,
  getToastHeaderTemplateTranslateKey,
  SImplicitlyStringifiable
} from '../toast.service';
import {
  AbstractNotificationMessageContent,
  NotificationGenericTemplateWithSchema,
} from '../notification-message-body/abstract-notification-message-content';
import {
  ConsignmentToastType,
  SBulkConsignmentActionToastData,
} from '../../shared-business-components/consignment-dialog/consignments-toast.service';
import {
  InternalNotificationConsignmentSubjectCode,
  InternalNotificationSubjectCode,
} from '../../../core/services/notifications/internal-notification.enum';
import {MESSAGE_HEADER_MAPPERS} from './notification-message-header-mappers.model';
import {z} from 'zod';

function registerConsignmentHeaderMappers() {
  for (const [key, value] of Object.entries(InternalNotificationConsignmentSubjectCode)) {
    const templateKey = key.replace('_BULK', '');
    MESSAGE_HEADER_MAPPERS[value as InternalNotificationConsignmentSubjectCode] = {
      template: templateKey as ConsignmentToastType,
      schema: SBulkConsignmentActionToastData,
    };
  }
}

export function getMessageHeaderParams(notification: InternalNotificationDto): InternalNotificationParameterDto[] {
  if (notification.bulkNotification) {
    return [{key: InternalNotificationKey.COUNT, value: String(notification.parameters.length), bulkNotificationGroupId: 0}];
  } else {
    return notification.parameters[0];
  }
}

@Component({
  selector: 'icz-notification-message-header',
  templateUrl: './notification-message-header.component.html',
  styleUrls: ['./notification-message-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageHeaderComponent extends AbstractNotificationMessageContent<InternalNotificationSubjectCode> implements OnInit {

  protected mappers = MESSAGE_HEADER_MAPPERS;

  ngOnInit() {
    registerConsignmentHeaderMappers();
  }

  getMessageHeaderTemplate(subjectCode: InternalNotificationSubjectCode): NotificationGenericTemplateWithSchema<string> {
    const headerTemplate = this.getTemplate(subjectCode)!;

    let headerTemplateExtended = '';
    if (typeof headerTemplate.template === 'function') {
      if (this.isBulkNotification) {
        headerTemplateExtended = headerTemplate.template(this.paramsMap, true);
      } else {
        headerTemplateExtended = headerTemplate.template(this.paramsMap);
      }
    } else {
      headerTemplateExtended = headerTemplate.template;
    }

    if (this.isBulkNotification) {
      return {
        template: getBulkToastHeaderTemplateTranslateKey(headerTemplateExtended),
        schema: z.object({[InternalNotificationKey.COUNT]: SImplicitlyStringifiable})
      };
    } else {
      return {
        ...headerTemplate,
        template: getToastHeaderTemplateTranslateKey(headerTemplateExtended),
      };
    }
  }

}
