import {ChangeDetectionStrategy, Component, inject, Input, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {EsslObjectType, TransactionLogEventType} from '|api/commons';
import {ApiTransactionEventAttributeService, TransactionEventDto} from '|api/transactionlog';
import {TransactionsDataSource} from './transactions.datasource';
import {ColumnDefinition} from '../../table/table.models';
import {FilterType} from '../../table/filter.types';
import {TableColumnsData} from '../../table/table-columns-data';
import {TransactionSidebarEvent} from '../transaction-sidebar/transaction-sidebar.component';
import {TransactionLogSearchService} from '../../../services/transaction-log-search.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {OrganizationalStructureService} from '../../../core/services/organizational-structure.service';
import {extendDefaultTableConfig, TableComponent} from '../../table/table.component';
import {enumToOptions, namedDtoToOption} from '../../../core/services/data-mapping.utils';
import {getUserFullName, Option} from '../../../model';
import {FilterOperator, FilterParam, SearchParams} from '../../../services/search-api.service';
import {FilterParamTree, FilterTreeOperator} from '../../table/filter-trees.utils';
import {Button} from '../../essentials/button-collection/button-collection.component';

type TransactionHistoryType = 'entity' | 'transaction-protocol';

type TransactionsTableColumnKey = keyof TransactionEventDto|'entityIdentifier.uid'|'parentIdentifier.uid';

const SYSTEM_OPTION: Option = {
  value: -1,
  label: 'Systém',
};

@Component({
  selector: 'icz-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsComponent {

  private searchService = inject(TransactionLogSearchService);
  private codebookService = inject(CodebookService);
  private orgStructureService = inject(OrganizationalStructureService);
  private apiTransactionEventAttributeService = inject(ApiTransactionEventAttributeService);

  @ViewChild('iczTable') iczTable!: TableComponent<TransactionsTableColumnKey>;

  @Input({required: true}) type!: TransactionHistoryType;
  @Input() identifier: Nullable<string> = null;
  @Input() idSource: Nullable<string>;
  @Input() entityId: Nullable<number>;
  @Input() esslObjectType: Nullable<EsslObjectType>;
  @Input() set global(value: boolean | '') {
    this._global = value === '' ? true : value;
  }
  private _global = false;

  dataSource = new TransactionsDataSource(this.searchService);

  config = extendDefaultTableConfig({
    hasActiveRow: true,
    disableLocalStorageSortPersistence: true,
  });

  functionalPositionsOptions$: Observable<Option[]> = this.orgStructureService.functionalPositions().pipe(
    map(entries => ([
      SYSTEM_OPTION,
      ...entries.map(namedDtoToOption()),
    ]))
  );

  userOptions$: Observable<Option[]> = this.codebookService.users().pipe(
    map(users => ([
      SYSTEM_OPTION,
      ...users.map(u => ({
        label: getUserFullName(u),
        value: u.id,
      })),
    ]))
  );

  get isTransactionProtocolTable() {
    return this.type === 'transaction-protocol';
  }

  private getTableColumns(): ColumnDefinition<TransactionsTableColumnKey>[] {
    const columns: ColumnDefinition<TransactionsTableColumnKey>[] = [
      {id: 'creationDate', label: 'Datum operace', filterType: FilterType.DATETIME},
      {id: 'userId', label: 'Uživatel', list$: this.userOptions$, filterType: FilterType.ENUM},
      {id: 'functionalPositionId', label: 'Funkční místo', filterType: FilterType.ENUM, list$: this.functionalPositionsOptions$},
      {
        id: 'esslObjectType',
        label: 'Druh objektu',
        list: enumToOptions('esslObjectType', EsslObjectType),
        filterType: FilterType.ENUM,
        translateWithPrefix: 'en.esslObjectType.',
        allowTranslation: true,
      },
      {
        id: 'transactionLogEventType',
        label: 'Typ operace',
        list: enumToOptions('transactionLogEventType', TransactionLogEventType),
        filterType: FilterType.ENUM,
        allowTranslation: true,
        translateWithPrefix: 'en.transactionLogEventType.'
      },
      {
        id: 'entityIdentifier.uid',
        label: 'Identifikátor',
        filterType: FilterType.TEXT,
      },
      {
        id: 'origin',
        label: 'Zdroj',
        filterType: FilterType.TEXT,
        allowTranslation: true,
      },
      // todo(rb) add Ztvárnění when BE ready
      // todo(rb) backend does not fill those data, however it might do so in the future
      // {id: 'description', label: 'Popis akce', filterType: FilterType.text},
    ];

    if (this.isTransactionProtocolTable) {
      return [...columns, {id: 'parentIdentifier.uid', label: 'Kontext', filterType: FilterType.TEXT}];
    }
    return columns;
  }

  columnsData = new TableColumnsData<TransactionsTableColumnKey>(this.getTableColumns());

  private _currentTransactionEvent$ = new BehaviorSubject<Nullable<TransactionEventDto>>(null);

  currentTransactionEvent$: Observable<Nullable<TransactionSidebarEvent>> = this._currentTransactionEvent$.pipe(
    filter(Boolean),
    switchMap(basicTransactionEvent =>
      this.apiTransactionEventAttributeService.transactionEventAttributeFindAllChangedByTransactionEventId({
        id: basicTransactionEvent!.id!,
      }).pipe(
        map(attributes => ({
          eventData: basicTransactionEvent,
          attributes,
        })),
      )
    )
  );

  isAttributePreviewOpened$ = this._currentTransactionEvent$.pipe(
    map(Boolean),
  );

  setCurrentTransactionEvent(transactionEvent: Nullable<TransactionEventDto>) {
    this._currentTransactionEvent$.next(transactionEvent);
  }

  loadPage(searchParams: SearchParams) {
    if (!this._global) {
      const isUidFilterUsed = searchParams.filter.find(
        filterParam => filterParam.fieldName === 'entityIdentifier.uid'
      ) !== undefined;

      const entityIdAndObjectTypeFilterValues: FilterParam[] = [
        {
          fieldName: 'entityId',
          operator: FilterOperator.equals,
          value: String(this.entityId),
        },
        {
          fieldName: 'esslObjectType',
          operator: FilterOperator.equals,
          value: String(this.esslObjectType),
        },
      ];

      const complexUidFilter: FilterParamTree = {
        operator: FilterTreeOperator.OR,
        values: [
          {
            operator: FilterTreeOperator.AND,
            values: [
              {
                fieldName: 'entityIdentifier.identifier',
                operator: FilterOperator.equals,
                value: String(this.identifier),
              },
              {
                fieldName: 'entityIdentifier.idSource',
                operator: FilterOperator.equals,
                value: String(this.idSource),
              }
            ]
          },
          {
            operator: FilterTreeOperator.AND,
            values: [
              {
                fieldName: 'parentIdentifier.identifier',
                operator: FilterOperator.equals,
                value: String(this.identifier),
              },
              {
                fieldName: 'parentIdentifier.idSource',
                operator: FilterOperator.equals,
                value: String(this.idSource),
              }
            ]
          },
        ]
      };

      if (this.idSource && this.identifier && !isUidFilterUsed) {
        if (!searchParams.complexFilter) {
          // if all of idSource, uid, entityId and esslObjectType are provided, construct OR between entityUID, parentUID and entityID filter groups
          if (this.esslObjectType && this.entityId) {
            searchParams.complexFilter = {
              operator: FilterTreeOperator.OR,
              values: [
                {
                  operator: FilterTreeOperator.AND,
                  values: entityIdAndObjectTypeFilterValues
                },
                complexUidFilter
              ]
            };
          }
          // otherwise if only idSource and uid, construct just UID filter
          else {
            searchParams.complexFilter = complexUidFilter;
          }
        }
      }
      // if only entityId and esslObjectType, use simple filter
      else if (this.esslObjectType && this.entityId) {
        searchParams.filter = [...searchParams.filter, ...entityIdAndObjectTypeFilterValues];
      }
    }
    this.dataSource.loadPage(searchParams);
    this._currentTransactionEvent$.next(null);
  }

  onRowClick(transactionEvent: TransactionEventDto) {
    this.setCurrentTransactionEvent(transactionEvent);
  }

  // NYI
  onClickPrint(event: Event) {}

  onPreviewClose() {
    this.setCurrentTransactionEvent(null);
    this.iczTable.selectedRowsService.unsetActiveRow();
  }

  toolbarButtonClicked(button: Button) {
    button.action?.(button);
  }

}
