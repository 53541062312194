/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { issdTypeCreate } from '../fn/issd-type/issd-type-create';
import { IssdTypeCreate$Params } from '../fn/issd-type/issd-type-create';
import { issdTypeDeleteById } from '../fn/issd-type/issd-type-delete-by-id';
import { IssdTypeDeleteById$Params } from '../fn/issd-type/issd-type-delete-by-id';
import { IssdTypeDto } from '../models/issd-type-dto';
import { issdTypeFind } from '../fn/issd-type/issd-type-find';
import { IssdTypeFind$Params } from '../fn/issd-type/issd-type-find';
import { issdTypeFindAll } from '../fn/issd-type/issd-type-find-all';
import { IssdTypeFindAll$Params } from '../fn/issd-type/issd-type-find-all';
import { issdTypeFindIssdBySourceAndOrganization } from '../fn/issd-type/issd-type-find-issd-by-source-and-organization';
import { IssdTypeFindIssdBySourceAndOrganization$Params } from '../fn/issd-type/issd-type-find-issd-by-source-and-organization';
import { issdTypeFindIssdByUsernameAndOrganization } from '../fn/issd-type/issd-type-find-issd-by-username-and-organization';
import { IssdTypeFindIssdByUsernameAndOrganization$Params } from '../fn/issd-type/issd-type-find-issd-by-username-and-organization';
import { issdTypeUpdate } from '../fn/issd-type/issd-type-update';
import { IssdTypeUpdate$Params } from '../fn/issd-type/issd-type-update';
import { PageIssdTypeDto } from '../models/page-issd-type-dto';


/**
 * API for management of ISSD (external applications)
 */
@Injectable({ providedIn: 'root' })
export class ApiIssdTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `issdTypeUpdate()` */
  static readonly IssdTypeUpdatePath = '/issd-type/{id}';

  /**
   * Update issd type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdTypeUpdate$Response(params: IssdTypeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdTypeDto>> {
    return issdTypeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update issd type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdTypeUpdate(params: IssdTypeUpdate$Params, context?: HttpContext): Observable<IssdTypeDto> {
    return this.issdTypeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdTypeDto>): IssdTypeDto => r.body)
    );
  }

  /** Path part for operation `issdTypeDeleteById()` */
  static readonly IssdTypeDeleteByIdPath = '/issd-type/{id}';

  /**
   * Delete Issd type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeDeleteById$Response(params: IssdTypeDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return issdTypeDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete Issd type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeDeleteById(params: IssdTypeDeleteById$Params, context?: HttpContext): Observable<void> {
    return this.issdTypeDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `issdTypeFindAll()` */
  static readonly IssdTypeFindAllPath = '/issd-type';

  /**
   * Find all existing issd types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFindAll$Response(params?: IssdTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdTypeDto>>> {
    return issdTypeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all existing issd types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFindAll(params?: IssdTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<IssdTypeDto>> {
    return this.issdTypeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdTypeDto>>): Array<IssdTypeDto> => r.body)
    );
  }

  /** Path part for operation `issdTypeCreate()` */
  static readonly IssdTypeCreatePath = '/issd-type';

  /**
   * Creates new issd type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdTypeCreate$Response(params: IssdTypeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdTypeDto>> {
    return issdTypeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new issd type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  issdTypeCreate(params: IssdTypeCreate$Params, context?: HttpContext): Observable<IssdTypeDto> {
    return this.issdTypeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdTypeDto>): IssdTypeDto => r.body)
    );
  }

  /** Path part for operation `issdTypeFind()` */
  static readonly IssdTypeFindPath = '/issd-type/search-api';

  /**
   * Finds all issd type instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFind$Response(params?: IssdTypeFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageIssdTypeDto>> {
    return issdTypeFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all issd type instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFind(params?: IssdTypeFind$Params|null|undefined, context?: HttpContext): Observable<PageIssdTypeDto> {
    return this.issdTypeFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageIssdTypeDto>): PageIssdTypeDto => r.body)
    );
  }

  /** Path part for operation `issdTypeFindIssdByUsernameAndOrganization()` */
  static readonly IssdTypeFindIssdByUsernameAndOrganizationPath = '/issd-type/by-username/{username}';

  /**
   * Returns issd by username
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeFindIssdByUsernameAndOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFindIssdByUsernameAndOrganization$Response(params: IssdTypeFindIssdByUsernameAndOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdTypeDto>> {
    return issdTypeFindIssdByUsernameAndOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns issd by username
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeFindIssdByUsernameAndOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFindIssdByUsernameAndOrganization(params: IssdTypeFindIssdByUsernameAndOrganization$Params, context?: HttpContext): Observable<IssdTypeDto> {
    return this.issdTypeFindIssdByUsernameAndOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdTypeDto>): IssdTypeDto => r.body)
    );
  }

  /** Path part for operation `issdTypeFindIssdBySourceAndOrganization()` */
  static readonly IssdTypeFindIssdBySourceAndOrganizationPath = '/issd-type/by-source/{source}';

  /**
   * Returns issd by source
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdTypeFindIssdBySourceAndOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFindIssdBySourceAndOrganization$Response(params: IssdTypeFindIssdBySourceAndOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdTypeDto>> {
    return issdTypeFindIssdBySourceAndOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns issd by source
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdTypeFindIssdBySourceAndOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdTypeFindIssdBySourceAndOrganization(params: IssdTypeFindIssdBySourceAndOrganization$Params, context?: HttpContext): Observable<IssdTypeDto> {
    return this.issdTypeFindIssdBySourceAndOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdTypeDto>): IssdTypeDto => r.body)
    );
  }

}
