/* tslint:disable */
/* eslint-disable */

/**
 * Task current state
 */
export enum CirculationTaskState {
  ASSIGNED_TO_INITIATOR = 'ASSIGNED_TO_INITIATOR',
  REVOKED_BY_INITIATOR = 'REVOKED_BY_INITIATOR',
  TAKEN_OVER_BY_INITIATOR = 'TAKEN_OVER_BY_INITIATOR',
  ASSIGNED_TO_TARGET = 'ASSIGNED_TO_TARGET',
  PASSED_BY_TARGET = 'PASSED_BY_TARGET',
  REJECTED_BY_TARGET = 'REJECTED_BY_TARGET',
  RETURNED_BY_TARGET = 'RETURNED_BY_TARGET',
  TAKEN_OVER_BY_TARGET = 'TAKEN_OVER_BY_TARGET',
  APPROVED_BY_TARGET = 'APPROVED_BY_TARGET',
  DISAPPROVED_BY_TARGET = 'DISAPPROVED_BY_TARGET',
  ACKNOWLEDGED_BY_TARGET = 'ACKNOWLEDGED_BY_TARGET',
  STATEMENT_DONE_BY_TARGET = 'STATEMENT_DONE_BY_TARGET',
  SIGNED_BY_TARGET = 'SIGNED_BY_TARGET',
  IN_PROGRESS_BY_SYSTEM = 'IN_PROGRESS_BY_SYSTEM',
  REVOKED_BY_SYSTEM = 'REVOKED_BY_SYSTEM',
  MANAGEMENT_DONE_BY_SYSTEM = 'MANAGEMENT_DONE_BY_SYSTEM',
  WAIT_FOR_ISSD_TAKEOVER = 'WAIT_FOR_ISSD_TAKEOVER',
  TAKEN_OVER_BY_ISSD = 'TAKEN_OVER_BY_ISSD',
  REJECTED_BY_ISSD = 'REJECTED_BY_ISSD',
  RETURNED_BY_ISSD = 'RETURNED_BY_ISSD'
}
