<div class="popover-content p-4">
  <icz-form-field
    (valueChange)="searchItems($event)"
    [placeholder]="searchTermInputPlaceholder"
    [showValidationStatus]="false"
    iczAutoFocus="deffered">
    @if (searchTermInputPrefix) {
      <span prefix>
        {{ searchTermInputPrefix | translate }}
      </span>
    }
  </icz-form-field>

  <ng-content select="[additionalContentBeforeOptions]"></ng-content>

  <div class="tree-view-wrapper">
    <icz-tree-view
      class="block h-full"
      [searchTerm]="_searchTerm"
      [isMultiChoice]="isMultiselect ?? false"
      (selectionChanged)="treeItemSelected($event)"
      [selectionStrategy]="isMultiselect ? selectionStrategy : TreeItemSelectionStrategy.SIMPLE"
      [checkedItems]="selectedOptions"
      [listItemTemplate]="listItemTemplate"
      [containerTreeHeight]="containerHeight"
      [data]="options">
    </icz-tree-view>
  </div>
</div>
