import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {SubjectTableColumnSet} from '../subjects-table/subjects-table/subjects-table.component';
import {injectModalData, injectModalRef} from '../../../../lib/modals';
import {DialogService} from '../../../../core/services/dialog.service';
import {InMemorySearchDatasource} from '../../../form-elements/form-popup-table-selector/in-memory-search.datasource';

export interface SubjectSelectionDialogData {
  tableTitle: string;
  primaryButtonTitle: string;
  minSelectedSubjectsCount: number;
  subjects: SubjectRecordDto[];
}

@Component({
  selector: 'icz-subject-selection-dialog',
  templateUrl: './subject-selection-dialog.component.html',
  styleUrls: ['./subject-selection-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubjectSelectionDialogComponent {

  protected modalData = injectModalData<SubjectSelectionDialogData>();
  private modalRef = injectModalRef<Nullable<SubjectRecordDto[]>>();
  private dialogService = inject(DialogService);

  selectedSubjects: SubjectRecordDto[] = [];

  subjectsDataSource = new InMemorySearchDatasource(() => this.modalData.subjects);

  selectedRowsChanged(selectedRows: SubjectRecordDto[]) {
    this.selectedSubjects = selectedRows;
  }

  submit() {
    if (this.selectedSubjects.length >= this.modalData.minSelectedSubjectsCount) {
      this.modalRef.close(this.selectedSubjects);
    }
    else {
      this.dialogService.showError(
        'Je nutné vybrat alespoň {{minSelectedSubjectsCount}} subjekty.',
        undefined,
        this.modalData
      );
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

  readonly SubjectTableColumnSet = SubjectTableColumnSet;

}
