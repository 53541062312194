import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {EmpowermentDto, OriginalEmpowermentDto} from '|api/commons';

@Pipe({
  name: 'empowerment',
  pure: false, // might change based on language changes
})
export class EmpowermentPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  private readonly lawString = this.translateService.instant('zák. č.');
  private readonly paragraphString = this.translateService.instant('ods.');
  private readonly pointString = this.translateService.instant('pís.');
  private readonly yearString = this.translateService.instant('rok');

  transform(empowerment: Nullable<EmpowermentDto | OriginalEmpowermentDto>): string {
    if (!empowerment) {
      return '';
    }
    else {
      let out = '';

      if (empowerment.law && empowerment.year) {
        out += `${this.lawString} ${empowerment.law}/${empowerment.year}`;
      } else {
        if (empowerment.law) {
          out += `${this.lawString} ${empowerment.law} `;
        }
        if (empowerment.year) {
          out += `${this.yearString} ${empowerment.year} `;
        }
      }

      if (empowerment.section) {
        out += ` § ${empowerment.section}`;
      }
      if (empowerment.paragraph) {
        out += ` ${this.paragraphString} ${empowerment.paragraph}`;
      }
      if (empowerment.point) {
        out += ` ${this.pointString} ${empowerment.point}`;
      }

      return out;
    }
  }

}
