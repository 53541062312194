<icz-hand-for-acknowledgement-specification
  [waiting]="loadingService.isLoading(this)"
  [form]="form"
  [circulationEntities]="circulationEntities"
></icz-hand-for-acknowledgement-specification>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="handForAcknowledgement()" primary label="Dát na vědomí"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
