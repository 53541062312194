import {Pipe, PipeTransform} from '@angular/core';
import {DocumentAllowableObjectClass, FileAllowableObjectClass} from '|api/document';
import {ObjectClass} from './model/entity.model';

@Pipe({
  name: 'objectClassName',
  pure: true,
})
export class ObjectClassNamePipe implements PipeTransform {

  transform(objectClass: Nullable<ObjectClass>): string {
    switch (objectClass) {
      case DocumentAllowableObjectClass.RECEIVED_DOCUMENT_DIGITAL:
        return 'Doručený dokument v elektronické podobě';
      case DocumentAllowableObjectClass.RECEIVED_DOCUMENT:
        return 'Doručený dokument';
      case DocumentAllowableObjectClass.OWN_DOCUMENT_DIGITAL:
        return 'Vlastní dokument v elektronické podobě';
      case DocumentAllowableObjectClass.OWN_DOCUMENT:
        return 'Vlastní dokument';
      case FileAllowableObjectClass.FILE_DIGITAL:
        return 'Spis v elektronické podobě';
      case FileAllowableObjectClass.FILE:
        return 'Spis';
      // todo(rb) uncomment those after BE will be ready
      /*case ObjectClass.CASE_FILE_DIGITAL:
        return 'Typový spis v elektronické podobě';
      case ObjectClass.CASE_FILE:
        return 'Typový spis';
      case ObjectClass.CASE_VOLUME:
        return 'Díl typového spisu';
      case ObjectClass.PACKAGE:
        return 'Zásilka';
      case ObjectClass.DISPOSAL_SCHEDULE:
        return 'Skartační návrh';*/
      default:
        return '';
    }
  }

}
