<div class="gap-16 col">
  @if (loadingIndicatorService.isLoading(this)) {
    <icz-spinner class="text-center" [diameter]="40"></icz-spinner>
  }
  @if (!loadingIndicatorService.isLoading(this)) {
    @if (!relatedEntities?.length) {
      <div>
        @if (isDocumentObject(object)) {
          <icz-label class="my-16 text-center" label="Dokument nemá související objekty."></icz-label>
        }
        @if (isFileObject(object)) {
          <icz-label class="my-16 text-center" label="Spis nemá související objekty."></icz-label>
        }
      </div>
    }
    @else {
      <icz-section label="Související dokumenty/spisy" boxed>
        <div class="col mt-16 gap-16">
          @for (entity of relatedEntities; track entity.id) {
            <icz-entity-item
              [entity]="entity"
            ></icz-entity-item>
          }
        </div>
      </icz-section>
    }
  }
</div>
