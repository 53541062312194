@if (dataSource) {
  <icz-essl-components-table
    [id]="id"
    [waiting]="loadingService.isLoading$(this) | async"
    [dataSource]="dataSource"
    [columnSet]="view"
    [preselectedRows]="selection"
    [rowCount]="rowCount"
    [canUserDisplayComponentsContent]="canUserDisplayComponentsContent"
    [acceptableDigitalDataFormats]="acceptableDataFileFormats"
    [rowSelectionDisabler]="rowSelectionDisabler"
    [disabledRowTooltip]="disabledRowTooltip"
    (rowsSelected)="rowsSelected($event)"
    [consignmentId]="consignmentId"
    [hideTableToolbar]="hideTableToolbar"
    >
    <ng-container tableTitle>
      @if (tableTitle) {
        <icz-label [label]="tableTitle"></icz-label>
      }
      <ng-content select="[afterTableTitle]"></ng-content>
    </ng-container>
  </icz-essl-components-table>
}
