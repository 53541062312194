import {inject, Injectable} from '@angular/core';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {
  SStorageUnitEntityTemplateData,
  SStorageUnitTemplateData,
  StorageUnitToastType
} from './storage-unit-toast.service';

@Injectable({
  providedIn: 'root'
})
export class StorageUnitNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_LOCK_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          StorageUnitToastType.STORAGE_UNIT_BLOCK_SUCCESS,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_LOCK_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_BLOCK_FAILED,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_UNLOCK_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          StorageUnitToastType.STORAGE_UNIT_UNBLOCK_SUCCESS,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_UNLOCK_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_UNBLOCK_FAILED,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_DELETE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          StorageUnitToastType.STORAGE_UNIT_DELETE_SUCCESS,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_DELETE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_DELETE_FAILED,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_INSERT_TO_STORAGE_UNIT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          StorageUnitToastType.STORAGE_UNIT_INSERT_SUCCESS,
          parsedBody.parameters,
          SStorageUnitEntityTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_INSERT_TO_STORAGE_UNIT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_INSERT_ERROR,
          parsedBody.parameters,
          SStorageUnitEntityTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_STORAGE_UNIT_DELETE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_INSERT_ERROR,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          StorageUnitToastType.STORAGE_UNIT_WITHDRAW_SUCCESS,
          parsedBody.parameters,
          SStorageUnitEntityTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_WITHDRAW_ERROR,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          StorageUnitToastType.STORAGE_UNIT_MOVE_SUCCESS,
          parsedBody.parameters,
          SStorageUnitEntityTemplateData,
        );
      }
    );
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          StorageUnitToastType.STORAGE_UNIT_MOVE_ERROR,
          parsedBody.parameters,
          SStorageUnitTemplateData,
        );
      }
    );
  }

}
