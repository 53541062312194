@if (!isNil(entity.unresolvedIncidentsCount)) {
  <div cdkOverlayOrigin #incidentsPopupOrigin="cdkOverlayOrigin" (click.stop)="isIncidentsListOpen = true" class="cursor-pointer">
    <div class="text-left">
      @if (entity.unresolvedIncidentsCount > 0) {
        <icz-icon svgIcon="config_error" size="small"></icz-icon>
      }
      @if (entity.unresolvedIncidentsCount === 0) {
        <icz-icon svgIcon="config_success" size="small"></icz-icon>
      }
    </div>
    <icz-popover
      [overlayOrigin]="incidentsPopupOrigin"
      [isOpen]="isIncidentsListOpen"
      (onClose)="isIncidentsListOpen = false;"
      >
      @if (isIncidentsListOpen) {
        <icz-quick-incidents-list [entity]="entity"></icz-quick-incidents-list>
      }
    </icz-popover>
  </div>
}
