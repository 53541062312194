<ng-container [formGroup]="form">
  <ng-container formGroupName="digitalComponents">
    @for (item of digitalComponentsControl.controls; track item) {
      <div class="col digital-component"
        [formGroupName]="$index" [waiting]="loadingIndicatorService.isLoading(this, $index)">
        <div class="gap-16 row mb-16">
          @if (showSpinner($any(item.value))) {
            <icz-spinner [diameter]="24"></icz-spinner>
          }
          @if (getIcon($any(item.value)); as icon) {
            <icz-icon [svgIcon]="icon"></icz-icon>
          }
          <icz-label class="font-bold overflow-hidden" dontTranslate [label]="item.get('file')!.value.name"></icz-label>
          <icz-label class="filesize" dontTranslate [label]="item.get('file')!.value.size | fileSize"></icz-label>
          @if (showDeleteBtn($any(item.value))) {
            <icz-button background size="small" svgIcon="delete" (onAction)="deleteDigitalComponentDraft($index, item.value)"></icz-button>
          }
          <!--todo currently not sure if this warning message for isFinal is needed-->
          <!--<span *ngIf="item.get('isFinal')!.value && !item.get('isValidOutputFormat')!.value" class="icz-warning">-->
          <!--{{ 'Nahranou komponentu není možné označit jako finální, protože není ve výstupním formátu.' }}-->
        <!--</span>-->
      </div>
      @if (!isAddDigitalRenditionDialog) {
        <div class="row">
          <icz-form-field [iczAutoFocus]="!!digitalComponent" formControlName="label" class="grow-3" label="Název komponenty"></icz-form-field>
          <icz-form-autocomplete formControlName="relationType" class="grow" label="Vztah k dokumentu" [options]="esslComponentRelationOptions"></icz-form-autocomplete>
          @if (config?.showFieldOrigin) {
            <icz-form-autocomplete formControlName="originType" class="grow" label="Původ" [options]="originTypeOptions" [clearable]="false"></icz-form-autocomplete>
          }
        </div>
        <div class="row items-center">
          <icz-form-field formControlName="description" class="grow" label="Popis komponenty" [autoSizeMax]="6"></icz-form-field>
          @if (config?.showFieldIsFinal) {
            <icz-checkbox formControlName="isFinal" label="Komponenta je finální"></icz-checkbox>
          }
          @if (showSignatureFlags) {
            <div>
              @if (getCompleteVersion(item.value.digitalComponentVersionId)?.completeVersion; as completeVersion) {
                @if (completeVersion?.digitalComponentVersionSignatureFlags) {
                  <icz-digital-component-flags
                    [digitalComponentVersionId]="completeVersion.id!"
                    [flags]="completeVersion?.digitalComponentVersionSignatureFlags!"
                  ></icz-digital-component-flags>
                }
                @else {
                  <icz-tag
                    class="inline-flex"
                    color="red"
                    label="Nebylo ověřeno"
                  ></icz-tag>
                }
              }
            </div>
          }
        </div>
        @if (businessRule && !item.get('isValidOutputFormat')!.value) {
          <div class="row items-center">
            <icz-alert label="Nahraná komponenta není v povoleném výstupním formátu." alertMode="compact" severity="warning"></icz-alert>
          </div>
        }
      }
    </div>
  }
</ng-container>
</ng-container>

<input type="file" #fileUpload (change)="setFiles()" [multiple]="digitalComponent ? false : 'multiple'" data-cy="doc-digital-component-file-input" [hidden]="true">
