/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReceivedEpdzMessageRejectionDataDto } from '../../models/received-epdz-message-rejection-data-dto';

export interface ReceivedDigitalConsignmentRejectDefectiveEpdzMessage$Params {

/**
 * ID of defective EPDZ message
 */
  id: number;
      body: ReceivedEpdzMessageRejectionDataDto
}

export function receivedDigitalConsignmentRejectDefectiveEpdzMessage(http: HttpClient, rootUrl: string, params: ReceivedDigitalConsignmentRejectDefectiveEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, receivedDigitalConsignmentRejectDefectiveEpdzMessage.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

receivedDigitalConsignmentRejectDefectiveEpdzMessage.PATH = '/sad/epdz/{id}/reject';