<form class="col grow" [waiting]="loadingService.isLoading$(this) | async" [formGroup]="form">
  <div class="col grow">
    <div class="row grow gap-24">
      <icz-section class="grow" label="Zobrazení informací na nástěnce">
        <icz-label label="Které informace chcete na nástěnce zobrazovat?" [hasNormalWhitespace]="true" class="mb-8"></icz-label>
        <icz-checkbox formControlName="attentionRequired" label="Sekce Vyžadující pozornost - povinná sekce, nelze odebrat" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="notifications" label="Sekce Upozornění" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="activities" label="Sekce Aktivity" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="fpSubstitutions" label="Sekce Vaše funkční místo je zastupované" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="userSubstitutions" label="Sekce Funkční místa, která zastupujete" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="myTasks" label="Sekce Mnou zadané úkoly" class="mb-8"></icz-checkbox>
        <icz-radio-group>
          <div class="space-y-8  mt-8 ml-16">
            <icz-radio-button formControlName="tasksPeriod" label="Za posledních 24 hodin" [value]="TaskFilterPeriod.ONE_DAY"></icz-radio-button>
            <icz-radio-button formControlName="tasksPeriod" label="Za posledních 48 hodin" [value]="TaskFilterPeriod.TWO_DAYS"></icz-radio-button>
          </div>
        </icz-radio-group>
      </icz-section>
      <icz-section class="grow" label="Jakou stránku chcete zobrazovat jako výchozí?">
        <icz-label label="Nastavte si stránku, kterou chcete po přihlášení do aplikace vidět jako první:" [hasNormalWhitespace]="true"></icz-label>
        <icz-radio-group>
          <div class="space-y-8 mt-8">
            <icz-radio-button formControlName="landingPage" label="Nástěnka" [value]="LandingPage.DASHBOARD"></icz-radio-button>
            <icz-radio-button formControlName="landingPage" label="Jako úvodní stránku zobrazit tu, na které jste skončili při posledním odhlášení z aplikace" [value]="LandingPage.LAST_USED_PAGE" ></icz-radio-button>
          </div>
        </icz-radio-group>
      </icz-section>
    </div>
  </div>
</form>
<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()" primary label="Uložit"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
