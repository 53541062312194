import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable, of, OperatorFunction} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {IczDataSourceDef} from '../components/table/table.datasource';
import {
  filterOperatorToComplexOperator,
  FilterParamTree,
  FilterTreeOperator,
  isFilterTree
} from '../components/table/filter-trees.utils';
import {SearchRecordDto} from '|api/elastic';
import {map, switchMap} from 'rxjs/operators';
import {groupBy} from 'lodash';

export function getOperatorTranslationKey(op: Nullable<FilterOperator>): string {
  if (!op) return '';

  return `fe.ui.filter.operator.${op}`;
}

export enum FilterOperator {
  equals = 'eq',
  notEquals = 'ne',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  contains = 'contains',
  doesNotContains = 'doesNotContains',
  less = 'lt',
  lessOrEqual = 'lte',
  greater = 'gt',
  greaterOrEqual = 'gte',
  inSet = 'in',
  empty = 'empty',
  notEmpty = 'notEmpty',
}

export function isNoValueOperator(filterOperator: Nullable<FilterOperator>) {
  return filterOperator === FilterOperator.empty || filterOperator === FilterOperator.notEmpty;
}

export enum ComplexOperator {
  AND = '_AND',
  OR = '_OR',
  NOT = '_NOT',
  NONE = '_NONE'
}

export enum GlobalOperator {
  and = 'AND',
  or = 'OR',
}

interface FieldParam<TFieldKey> {
  fieldName: TFieldKey;
}

export interface FilterDefinition {
  operator?: FilterOperator;
  and?: boolean;
  or?: boolean;
  not?: boolean;
  value: Nullable<string>;
  isCaseInsensitive?: boolean;
}

export interface SortParam<TFieldKey extends string|number|symbol> extends FieldParam<TFieldKey> {
  descending?: boolean;
}

export interface FilterParam extends FieldParam<string>, FilterDefinition {}

export interface SearchParams {
  filter: FilterParam[];
  complexFilter?: FilterParamTree;
  sort: SortParam<string>[];
  size: number;
  page: number;
  globalOperator?: GlobalOperator;
  fulltextSearchTerm?: string;
}

export const BLANK_SEARCH_PARAMS: SearchParams = Object.freeze({
  filter: [],
  sort: [],
  page: 0,
  size: 0,
});

const UNKNOWN_FIELD_NAME = 'unknown-field-name';

export type TypedSearchRecordDto<T> = Omit<SearchRecordDto, 'source'> & {source: T};

export function removeFilterByFieldName(params: SearchParams, fieldNames: string[]) {
  const {filter} = params;
  if (filter) {
    fieldNames.forEach(fieldName => {
      while (filter.some(f => f.fieldName === fieldName)) {
        const index = filter.findIndex(f => f.fieldName === fieldName);
        filter.splice(index, 1);
      }
    });
  }
}

export function addFilter(params: SearchParams, newFilter: FilterParam | FilterParam[]) {
  const {filter} = params;
  if (Array.isArray(newFilter) && filter) {
    filter.push(...newFilter);
  }
  else {
    const {fieldName} = newFilter as FilterParam;
    if (filter.some(i => i.fieldName === fieldName)) {
      const filterField = filter.find(i => i.fieldName === fieldName);
      if (filterField) {
        filterField.value = (newFilter as FilterParam).value;
      }
    }
    else {
      filter.push(newFilter as FilterParam);
    }
  }
}

export function filterDefinitionToString(filterDef: FilterDefinition): string {
  let filterDefStr = `${filterDef.value ?? ''}`;
  if (filterDef.operator) filterDefStr = `${filterDef.operator}(${filterDefStr})`;
  if (filterDef.isCaseInsensitive) filterDefStr = `ci(${filterDefStr})`;
  if (filterDef.not) filterDefStr = `not(${filterDefStr})`;
  if (filterDef.or) filterDefStr = `or(${filterDefStr})`;
  if (filterDef.and) filterDefStr = `and(${filterDefStr})`;
  return filterDefStr;
}

type ComplexPredicateLeaf = Record<string, string>; // {fieldId -> serialized field value, e.g. "contains(Busarade)"}
type ComplexPredicate = Partial<Record<ComplexOperator, ComplexPredicate[] | ComplexPredicateLeaf>>;

export function filterParamTreeToComplexPredicate(filterParamTree: FilterParamTree): ComplexPredicate {
  const treeValuesCount = filterParamTree.values.length;
  const subtreesCount = filterParamTree.values.filter(treeValue => isFilterTree(treeValue)).length;

  if (subtreesCount === treeValuesCount) {
    return {
      [filterOperatorToComplexOperator(filterParamTree.operator)]: filterParamTree.values.map(
        treeValue => filterParamTreeToComplexPredicate(treeValue as FilterParamTree)
      ),
    };
  }
  else if (subtreesCount === 0) { // all tree values are FilterValues
    const itemsWithAdditionalOperator = (filterParamTree.values as FilterParam[]).filter(fp => fp.and || fp.or);

    if (itemsWithAdditionalOperator.length) {
      const groupsByOperatorAndField = groupBy(itemsWithAdditionalOperator, fp => `${fp.and ? FilterTreeOperator.AND : FilterTreeOperator.OR},${fp.fieldName}`);
      let alteredSubexpressionTree = [...filterParamTree.values];

      for (const operatorAndFieldSubexpressionKey in groupsByOperatorAndField) {
        if (groupsByOperatorAndField.hasOwnProperty(operatorAndFieldSubexpressionKey)) {
          const operator = operatorAndFieldSubexpressionKey.split(',')[0] as FilterTreeOperator;

          alteredSubexpressionTree = alteredSubexpressionTree.filter(
            treeItem => !groupsByOperatorAndField[operatorAndFieldSubexpressionKey].includes(treeItem as FilterParam)
          );

          alteredSubexpressionTree.push({
            operator,
            values: groupsByOperatorAndField[operatorAndFieldSubexpressionKey].map(fv => ({...fv, and: undefined, or: undefined})),
          });
        }
      }

      return {
        [filterOperatorToComplexOperator(filterParamTree.operator)]: alteredSubexpressionTree.reduce((acc, treeValue) => {
          if (isFilterTree(treeValue) && treeValue.values.length) {
            const subtreeFieldName = (treeValue.values[0] as FilterParam).fieldName;
            return {
              ...acc,
              [subtreeFieldName]: `${treeValue.operator}(${(treeValue.values as FilterParam[]).map(fp => filterDefinitionToString(fp)).join(',')})`,
            };
          }
          else {
            return {
              ...acc,
              [(treeValue as FilterParam).fieldName]: filterDefinitionToString(treeValue as FilterParam),
            };
          }
        }, {} as ComplexPredicate),
      };
    }
    else {
      const complexPredicateLeaf: ComplexPredicateLeaf = {};

      for (const filterParam of (filterParamTree.values as FilterParam[])) {
        complexPredicateLeaf[filterParam.fieldName] = filterDefinitionToString(filterParam);
      }

      return {
        [filterOperatorToComplexOperator(filterParamTree.operator)]: complexPredicateLeaf,
      };
    }
  }
  else {
    return {
      [filterOperatorToComplexOperator(filterParamTree.operator)]: filterParamTree.values.map(treeValue => {
        if (isFilterTree(treeValue)) {
          return filterParamTreeToComplexPredicate(treeValue);
        }
        else {
          return filterParamTreeToComplexPredicate({
            operator: FilterTreeOperator.AND,
            values: [treeValue],
          });
        }
      }),
    };
  }
}

export type SearchApiQueryParams = Array<[string, string | string[]]>;

const PAGE_QUERY_PARAM_NAME = 'page';
const SIZE_QUERY_PARAM_NAME = 'size';
const GLOBAL_OPERATOR_QUERY_PARAM_NAME = 'globalOperator';
const COMPLEX_PREDICATE_QUERY_PARAM_NAME = '_complexPredicate';
const SORT_QUERY_PARAM_NAME = 'sort';

const RESERVED_QUERY_PARAM_NAMES = [
  PAGE_QUERY_PARAM_NAME,
  SIZE_QUERY_PARAM_NAME,
  GLOBAL_OPERATOR_QUERY_PARAM_NAME,
  COMPLEX_PREDICATE_QUERY_PARAM_NAME,
  SORT_QUERY_PARAM_NAME,
];

/**
 * A common abstract class used for providing SearchApi
 * functionality to its derived classes
 */
export abstract class SearchApiService {

  private http = inject(HttpClient);

  static getPageAndSizeQueryParams(searchParams: Partial<SearchParams>): SearchApiQueryParams {
    return [
      [PAGE_QUERY_PARAM_NAME, String(searchParams.page)],
      [SIZE_QUERY_PARAM_NAME, String(searchParams.size)]
    ];
  }

  static getFilterQueryParams(searchParams: Partial<SearchParams>): SearchApiQueryParams {
    const out: SearchApiQueryParams = [];

    if (searchParams.globalOperator) {
      out.push([GLOBAL_OPERATOR_QUERY_PARAM_NAME, searchParams.globalOperator]);
    }

    const filter: Array<[string, any]> = (searchParams.filter ?? []).reduce((groupedFilters: Array<[string, any]>, filterParam: FilterParam) => {
      const field = filterParam.fieldName || UNKNOWN_FIELD_NAME;

      if (RESERVED_QUERY_PARAM_NAMES.includes(field)) {
        throw new Error(`Reserved query param name '${field}' used as filter field name!`);
      }

      const filterDefinition = filterDefinitionToString(filterParam);
      const fieldFilter = groupedFilters.find(res => res[0] === field);

      if (fieldFilter) {
        fieldFilter[1] = Array.isArray(fieldFilter[1]) ?
          fieldFilter[1].concat(filterDefinition) :
          [fieldFilter[1], filterDefinition];
      }
      else {
        groupedFilters.push([field, filterDefinition]);
      }

      return groupedFilters;
    }, []);

    out.push(...filter);

    if (searchParams.complexFilter) {
      out.push([COMPLEX_PREDICATE_QUERY_PARAM_NAME, JSON.stringify(filterParamTreeToComplexPredicate(searchParams.complexFilter))]);
    }

    return out;
  }

  static getSortQueryParams(searchParams: Partial<SearchParams>): SearchApiQueryParams {
    const sort = (searchParams.sort ?? []).map(
      sortParam => [sortParam.fieldName || UNKNOWN_FIELD_NAME, sortParam.descending ? 'desc' : 'asc'].join(',')
    );

    return [
      [SORT_QUERY_PARAM_NAME, sort],
    ];
  }

  static searchApiParamsToHttpParams(searchApiQueryParams: SearchApiQueryParams): HttpParams {
    let queryParams = new HttpParams();

    searchApiQueryParams
      .filter(([_, value]) => value !== undefined)
      .forEach(([key, value]) => {
        if (typeof value === 'string') queryParams = queryParams.set(key, value);
        else if (Array.isArray(value)) value.forEach(v => queryParams = queryParams.append(key, v));
        else queryParams = queryParams.set(key, JSON.stringify(value));
      });

    return queryParams;
  }

  private setupQueryParams(searchParams: Partial<SearchParams>) {
    return SearchApiService.searchApiParamsToHttpParams([
      ...SearchApiService.getPageAndSizeQueryParams(searchParams),
      ...SearchApiService.getSortQueryParams(searchParams),
      ...SearchApiService.getFilterQueryParams(searchParams),
    ]);
  }

  protected searchApi<T>(searchParams: Partial<SearchParams>, url: string): Observable<T> {
    return this.http.get<T>(url, {params: this.setupQueryParams(searchParams)});
  }

  // note: roughly corresponds with `statisticsSearch`. Param 'filters' is not really needed, we can just use
  // urlPredicates the same ways as in classic searchApi.
  protected searchApiStatistics<T>(searchParams: Partial<SearchParams>, // sort + filter just like normal searchApi
                                url: string,
                                dimensions: Array<string>,

  ): Observable<T> {
    let queryParams: HttpParams = this.setupQueryParams(searchParams);

    queryParams = queryParams.set('dimensions', String(dimensions));

    return this.http.get<T>(url, {params: queryParams});
  }

}

export function unwrapSearchContent<T>(): OperatorFunction<Page<TypedSearchRecordDto<T>>, Page<T>> {
  return map(page => ({
    ...page,
    content: page.content!.map(record => record.source),
  }));
}

@Injectable({
  providedIn: 'root',
})
export class GenericSearchService extends SearchApiService {

  genericSearchApi<T>(searchParams: Partial<SearchParams>, dataSourceDef: IczDataSourceDef<T>): Observable<Page<T>> {
    if (!dataSourceDef.microServiceRoot) dataSourceDef.microServiceRoot = CORE_MICROSERVICE_ROOT;
    return this.searchApi<(Page<T> | Page<TypedSearchRecordDto<T>>)>(
      searchParams,
      applyPathParams(dataSourceDef.microServiceRoot + dataSourceDef.url, dataSourceDef.staticPathParams)
    ).pipe(
      switchMap(result => {
        if (dataSourceDef.customMapper) {
          return of(result as Page<TypedSearchRecordDto<T>>).pipe(dataSourceDef.customMapper);
        } else {
          return of(result as Page<T>);
        }
      })
    );
  }

}
