import {Type} from '@angular/core';
import {EntityType} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {DocumentDto, FileDto} from '|api/document';
import {CirculationTaskDto} from '|api/flow';
import {CirculationEntity} from '../components/abstract-handover-form.component';
import {IczModalService} from '../../../../services/icz-modal.service';
import {DocumentToastService} from '../../../../core/services/notifications/document-toast.service';
import {AbstractHandoverFormModalComponent} from '../components/abstract-handover-form-modal.component';
import {COMPONENT_ENTITY_TYPES, isDocumentObject, isEsslComponent, isFileObject} from '../../shared-document.utils';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {ExtendedCirculationTaskDto} from '../../model/elastic-extended-entities.interface';
import {isTaskEntity} from '../../../../utils/document-file-circulation.utils';
import {enumValuesToArray} from '../../../../core/services/data-mapping.utils';

export enum DocumentView {
  DOCUMENT_DETAIL = 'DOCUMENT_DETAIL',
  GLOBAL_SEARCH = 'GLOBAL_SEARCH',
  EXPIRING_DOCUMENTS = 'EXPIRING_DOCUMENTS',
  FILE_CONTENTS = 'FILE_CONTENTS',
  STORAGE_UNIT_CONTENTS = 'STORAGE_UNIT_CONTENTS',
  SETTLED_DOCUMENTS = 'SETTLED_DOCUMENTS',
  SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR = 'SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR',
  DOCUMENT_LISTING = 'DOCUMENT_LISTING', // generic table view of documents - default view
  SHARED_FOLDER = 'SHARED_FOLDER',
  DOCUMENT_SELECTOR = 'DOCUMENT_SELECTOR',
  FILING_OFFICE_RECEIVED = 'FILING_OFFICE_RECEIVED',
  FILING_OFFICE_HANDED_OVER = 'FILING_OFFICE_HANDED_OVER',
  FILING_OFFICE_REJECTED = 'FILING_OFFICE_REJECTED',
  OWNERSHIP_TRANSFER = 'OWNERSHIP_TRANSFER',
  MANUAL_SHARED = 'MANUAL_SHARED',
  ISSD_DOCUMENTS = 'ISSD_DOCUMENTS',
  SETTLED_DOCUMENTS_STORED = 'SETTLED_DOCUMENTS_STORED',
  STORAGE_UNIT_INSERT = 'STORAGE_UNIT_INSERT',
}

export enum FileView {
  FILE_DETAIL = 'FILE_DETAIL'
}


export enum CirculationView {
  TASKS_IN_PROGRESS = 'TASKS_IN_PROGRESS',
  COMPONENT_TASKS_TO_SIGN = 'COMPONENT_TASKS_TO_SIGN',
  COMPONENT_TASKS_TO_APPROVE = 'COMPONENT_TASKS_TO_APPROVE',
  COMPONENT_TASKS_DONE = 'COMPONENT_TASKS_DONE',
  TASKS_DONE = 'TASKS_DONE',
  ACTIVITIES_IN_PROGRESS = 'ACTIVITIES_IN_PROGRESS',
  DOCUMENT_ACTIVITIES = 'DOCUMENT_ACTIVITIES',
  ISSD_TASKS = 'ISSD_TASKS',
  ISSD_TASKS_REJECTED = 'ISSD_TASKS_REJECTED',
  UNIT_TASKS_IN_PROGRESS = 'UNIT_TASKS_IN_PROGRESS',
  UNIT_ACTIVITIES_IN_PROGRESS = 'UNIT_ACTIVITIES_IN_PROGRESS',
  UNIT_TASKS_DONE = 'UNIT_TASKS_DONE',
}

export enum StorageUnitView {
  STORAGE_UNITS_TABLE = 'STORAGE_UNITS_TABLE',
  STORAGE_UNIT_DETAIL = 'STORAGE_UNIT_DETAIL',
  STORAGE_UNIT_INSERT_DETAIL = 'STORAGE_UNIT_INSERT_DETAIL',
}

export enum RegistryOfficeTransferView {
  REGISTRY_OFFICE_TO_RESOLVE_ISSUES = 'REGISTRY_OFFICE_TO_RESOLVE_ISSUES',
  REGISTRY_OFFICE_TO_TAKE_OVER = 'REGISTRY_OFFICE_TO_TAKE_OVER',
  OFFICER_TO_RESOLVE_ISSUES = 'OFFICER_TO_RESOLVE_ISSUES',
  HANDED_OVER_AND_STORED = 'HANDED_OVER_AND_STORED', // předané, uložené
  ENTITY_TRANSFER_IN_PROGRESS = 'ENTITY_TRANSFER_IN_PROGRESS',
}

export const REGISTRY_OFFICE_TRANSFER_VIEWS = enumValuesToArray(RegistryOfficeTransferView);

export function isTaskView(view: CirculationView) {
  return [
    CirculationView.TASKS_IN_PROGRESS,
    CirculationView.TASKS_DONE,
    CirculationView.COMPONENT_TASKS_TO_SIGN,
    CirculationView.COMPONENT_TASKS_TO_APPROVE,
    CirculationView.COMPONENT_TASKS_DONE,
    CirculationView.ISSD_TASKS,
    CirculationView.ISSD_TASKS_REJECTED,
    CirculationView.UNIT_TASKS_IN_PROGRESS,
    CirculationView.UNIT_TASKS_DONE,
  ].includes(view);
}

export function isActivityView(view: CirculationView) {
  return [
    CirculationView.ACTIVITIES_IN_PROGRESS,
    CirculationView.DOCUMENT_ACTIVITIES,
    CirculationView.UNIT_ACTIVITIES_IN_PROGRESS
  ].includes(view);
}

export function isComponentTaskOrActivityView(view: CirculationView) {
  return [
    CirculationView.COMPONENT_TASKS_TO_SIGN,
    CirculationView.COMPONENT_TASKS_TO_APPROVE,
    CirculationView.COMPONENT_TASKS_DONE,
  ].includes(view);
}

export const WITHOUT_REF_NUMBER = 'bez čísla jednacího';
export const WITHOUT_FILE_REF_NUMBER = 'bez spisové značky';

// ms. Workaround. Hopefully enough for Elastic to catch consistency. And hopefully not that much for users to notice.
export const ELASTIC_RELOAD_DELAY = 1000;

export const IN_PROGRESS_ACTIVITY_DOCUMENT_VIEWS = [
  CirculationView.DOCUMENT_ACTIVITIES,
  CirculationView.ACTIVITIES_IN_PROGRESS,
  CirculationView.UNIT_ACTIVITIES_IN_PROGRESS,
];

export function openGeneralCirculationModal(
  component: {modalService: IczModalService; documentToastService: DocumentToastService; announceActionCompleted: (action: string) => void},
  titleTemplate: string,
  titleTemplateBulk: string,
  modalComponent: Type<AbstractHandoverFormModalComponent>,
  circulationEntities: Nullable<DocumentDto | FileDto | ExtendedCirculationTaskDto | EsslComponentDto>[],
  successAction?: string,
) {
  let titleTemplateContext: Nullable<Record<string, string>>;
  let dialogTitle: string;

  if (circulationEntities.length === 1) {
    const initEntity = circulationEntities[0];
    if (isDocumentObject(initEntity as any) || isFileObject(initEntity as any) || isTaskEntity(initEntity as any)) {
      if (isTaskEntity(initEntity as any) && COMPONENT_ENTITY_TYPES.includes((initEntity as CirculationTaskDto).circulationEntityType)) {
        titleTemplateContext = {
          componentName: (initEntity as CirculationTaskDto).componentLabel!,
        };
      }
      else {
        titleTemplateContext = {
          refNumber: (initEntity as DocumentDto | FileDto).refNumber || WITHOUT_REF_NUMBER,
        };
      }
    }
    else if (isEsslComponent(initEntity)) {
      if (initEntity.entityType === EntityType.DIGITAL_COMPONENT) {
        titleTemplateContext = {
          componentName: (initEntity as DigitalComponentCompleteDto).label!,
        };
      }
      else {
        titleTemplateContext = {
          componentName: initEntity.label!,
        };
      }
    }
    dialogTitle = titleTemplate;
  } else {
    dialogTitle = titleTemplateBulk;
    titleTemplateContext = {
      count: String(circulationEntities.length)
    };
  }

  const afterClosed$ = component.modalService.openComponentInModal<Nullable<number>, CirculationEntity[]>({
    component: modalComponent,
    modalOptions: {
      width: 1000,
      height: 650,
      titleTemplate: dialogTitle,
      titleTemplateContext: titleTemplateContext!,
    },
    data: circulationEntities as CirculationEntity[],
  });

  afterClosed$.subscribe(modalResult => {
    if (modalResult && successAction) {
      component.announceActionCompleted(successAction);
    }
  });

  return afterClosed$;
}
