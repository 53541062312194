/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentSharingDto } from '../models/document-sharing-dto';
import { entitySharingManagementRedefineDocumentSharing } from '../fn/entity-sharing-management/entity-sharing-management-redefine-document-sharing';
import { EntitySharingManagementRedefineDocumentSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-redefine-document-sharing';
import { entitySharingManagementRedefineFileSharing } from '../fn/entity-sharing-management/entity-sharing-management-redefine-file-sharing';
import { EntitySharingManagementRedefineFileSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-redefine-file-sharing';
import { entitySharingManagementRedefineOrgUnitSharing } from '../fn/entity-sharing-management/entity-sharing-management-redefine-org-unit-sharing';
import { EntitySharingManagementRedefineOrgUnitSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-redefine-org-unit-sharing';
import { entitySharingManagementRedefineSharedFolderSharing } from '../fn/entity-sharing-management/entity-sharing-management-redefine-shared-folder-sharing';
import { EntitySharingManagementRedefineSharedFolderSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-redefine-shared-folder-sharing';
import { entitySharingManagementRedefineStorageUnitSharing } from '../fn/entity-sharing-management/entity-sharing-management-redefine-storage-unit-sharing';
import { EntitySharingManagementRedefineStorageUnitSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-redefine-storage-unit-sharing';
import { entitySharingManagementRevokeDocumentSharing } from '../fn/entity-sharing-management/entity-sharing-management-revoke-document-sharing';
import { EntitySharingManagementRevokeDocumentSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-revoke-document-sharing';
import { entitySharingManagementRevokeFileSharing } from '../fn/entity-sharing-management/entity-sharing-management-revoke-file-sharing';
import { EntitySharingManagementRevokeFileSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-revoke-file-sharing';
import { entitySharingManagementRevokeOrgUnitSharing } from '../fn/entity-sharing-management/entity-sharing-management-revoke-org-unit-sharing';
import { EntitySharingManagementRevokeOrgUnitSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-revoke-org-unit-sharing';
import { entitySharingManagementRevokeSharedFolderSharing } from '../fn/entity-sharing-management/entity-sharing-management-revoke-shared-folder-sharing';
import { EntitySharingManagementRevokeSharedFolderSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-revoke-shared-folder-sharing';
import { entitySharingManagementRevokeStorageUnitSharing } from '../fn/entity-sharing-management/entity-sharing-management-revoke-storage-unit-sharing';
import { EntitySharingManagementRevokeStorageUnitSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-revoke-storage-unit-sharing';
import { entitySharingManagementSearchOrgUnitSharings } from '../fn/entity-sharing-management/entity-sharing-management-search-org-unit-sharings';
import { EntitySharingManagementSearchOrgUnitSharings$Params } from '../fn/entity-sharing-management/entity-sharing-management-search-org-unit-sharings';
import { entitySharingManagementSetupDocumentSharing } from '../fn/entity-sharing-management/entity-sharing-management-setup-document-sharing';
import { EntitySharingManagementSetupDocumentSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-setup-document-sharing';
import { entitySharingManagementSetupFileSharing } from '../fn/entity-sharing-management/entity-sharing-management-setup-file-sharing';
import { EntitySharingManagementSetupFileSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-setup-file-sharing';
import { entitySharingManagementSetupOrgUnitSharing } from '../fn/entity-sharing-management/entity-sharing-management-setup-org-unit-sharing';
import { EntitySharingManagementSetupOrgUnitSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-setup-org-unit-sharing';
import { entitySharingManagementSetupSharedFolderSharing } from '../fn/entity-sharing-management/entity-sharing-management-setup-shared-folder-sharing';
import { EntitySharingManagementSetupSharedFolderSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-setup-shared-folder-sharing';
import { entitySharingManagementSetupStorageUnitSharing } from '../fn/entity-sharing-management/entity-sharing-management-setup-storage-unit-sharing';
import { EntitySharingManagementSetupStorageUnitSharing$Params } from '../fn/entity-sharing-management/entity-sharing-management-setup-storage-unit-sharing';
import { FileSharingDto } from '../models/file-sharing-dto';
import { OrgUnitEntitySharingDto } from '../models/org-unit-entity-sharing-dto';
import { PageOrgUnitEntitySharingDto } from '../models/page-org-unit-entity-sharing-dto';
import { SharedFolderSharingDto } from '../models/shared-folder-sharing-dto';
import { StorageUnitSharingDto } from '../models/storage-unit-sharing-dto';

@Injectable({ providedIn: 'root' })
export class ApiEntitySharingManagementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `entitySharingManagementRedefineOrgUnitSharing()` */
  static readonly EntitySharingManagementRedefineOrgUnitSharingPath = '/entity-sharing/management/per-org-unit/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRedefineOrgUnitSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineOrgUnitSharing$Response(params: EntitySharingManagementRedefineOrgUnitSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<OrgUnitEntitySharingDto>> {
    return entitySharingManagementRedefineOrgUnitSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRedefineOrgUnitSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineOrgUnitSharing(params: EntitySharingManagementRedefineOrgUnitSharing$Params, context?: HttpContext): Observable<OrgUnitEntitySharingDto> {
    return this.entitySharingManagementRedefineOrgUnitSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrgUnitEntitySharingDto>): OrgUnitEntitySharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRevokeOrgUnitSharing()` */
  static readonly EntitySharingManagementRevokeOrgUnitSharingPath = '/entity-sharing/management/per-org-unit/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRevokeOrgUnitSharing()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeOrgUnitSharing$Response(params: EntitySharingManagementRevokeOrgUnitSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return entitySharingManagementRevokeOrgUnitSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRevokeOrgUnitSharing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeOrgUnitSharing(params: EntitySharingManagementRevokeOrgUnitSharing$Params, context?: HttpContext): Observable<void> {
    return this.entitySharingManagementRevokeOrgUnitSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRedefineStorageUnitSharing()` */
  static readonly EntitySharingManagementRedefineStorageUnitSharingPath = '/entity-sharing/management/per-entity/storage-unit/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRedefineStorageUnitSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineStorageUnitSharing$Response(params: EntitySharingManagementRedefineStorageUnitSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitSharingDto>> {
    return entitySharingManagementRedefineStorageUnitSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRedefineStorageUnitSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineStorageUnitSharing(params: EntitySharingManagementRedefineStorageUnitSharing$Params, context?: HttpContext): Observable<StorageUnitSharingDto> {
    return this.entitySharingManagementRedefineStorageUnitSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitSharingDto>): StorageUnitSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRevokeStorageUnitSharing()` */
  static readonly EntitySharingManagementRevokeStorageUnitSharingPath = '/entity-sharing/management/per-entity/storage-unit/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRevokeStorageUnitSharing()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeStorageUnitSharing$Response(params: EntitySharingManagementRevokeStorageUnitSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return entitySharingManagementRevokeStorageUnitSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRevokeStorageUnitSharing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeStorageUnitSharing(params: EntitySharingManagementRevokeStorageUnitSharing$Params, context?: HttpContext): Observable<void> {
    return this.entitySharingManagementRevokeStorageUnitSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRedefineSharedFolderSharing()` */
  static readonly EntitySharingManagementRedefineSharedFolderSharingPath = '/entity-sharing/management/per-entity/shared-folder/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRedefineSharedFolderSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineSharedFolderSharing$Response(params: EntitySharingManagementRedefineSharedFolderSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderSharingDto>> {
    return entitySharingManagementRedefineSharedFolderSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRedefineSharedFolderSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineSharedFolderSharing(params: EntitySharingManagementRedefineSharedFolderSharing$Params, context?: HttpContext): Observable<SharedFolderSharingDto> {
    return this.entitySharingManagementRedefineSharedFolderSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderSharingDto>): SharedFolderSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRevokeSharedFolderSharing()` */
  static readonly EntitySharingManagementRevokeSharedFolderSharingPath = '/entity-sharing/management/per-entity/shared-folder/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRevokeSharedFolderSharing()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeSharedFolderSharing$Response(params: EntitySharingManagementRevokeSharedFolderSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return entitySharingManagementRevokeSharedFolderSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRevokeSharedFolderSharing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeSharedFolderSharing(params: EntitySharingManagementRevokeSharedFolderSharing$Params, context?: HttpContext): Observable<void> {
    return this.entitySharingManagementRevokeSharedFolderSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRedefineFileSharing()` */
  static readonly EntitySharingManagementRedefineFileSharingPath = '/entity-sharing/management/per-entity/file/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRedefineFileSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineFileSharing$Response(params: EntitySharingManagementRedefineFileSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<FileSharingDto>> {
    return entitySharingManagementRedefineFileSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRedefineFileSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineFileSharing(params: EntitySharingManagementRedefineFileSharing$Params, context?: HttpContext): Observable<FileSharingDto> {
    return this.entitySharingManagementRedefineFileSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileSharingDto>): FileSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRevokeFileSharing()` */
  static readonly EntitySharingManagementRevokeFileSharingPath = '/entity-sharing/management/per-entity/file/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRevokeFileSharing()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeFileSharing$Response(params: EntitySharingManagementRevokeFileSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return entitySharingManagementRevokeFileSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRevokeFileSharing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeFileSharing(params: EntitySharingManagementRevokeFileSharing$Params, context?: HttpContext): Observable<void> {
    return this.entitySharingManagementRevokeFileSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRedefineDocumentSharing()` */
  static readonly EntitySharingManagementRedefineDocumentSharingPath = '/entity-sharing/management/per-entity/document/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRedefineDocumentSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineDocumentSharing$Response(params: EntitySharingManagementRedefineDocumentSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSharingDto>> {
    return entitySharingManagementRedefineDocumentSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRedefineDocumentSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementRedefineDocumentSharing(params: EntitySharingManagementRedefineDocumentSharing$Params, context?: HttpContext): Observable<DocumentSharingDto> {
    return this.entitySharingManagementRedefineDocumentSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSharingDto>): DocumentSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementRevokeDocumentSharing()` */
  static readonly EntitySharingManagementRevokeDocumentSharingPath = '/entity-sharing/management/per-entity/document/{sharingId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementRevokeDocumentSharing()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeDocumentSharing$Response(params: EntitySharingManagementRevokeDocumentSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return entitySharingManagementRevokeDocumentSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementRevokeDocumentSharing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementRevokeDocumentSharing(params: EntitySharingManagementRevokeDocumentSharing$Params, context?: HttpContext): Observable<void> {
    return this.entitySharingManagementRevokeDocumentSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementSetupOrgUnitSharing()` */
  static readonly EntitySharingManagementSetupOrgUnitSharingPath = '/entity-sharing/management/per-org-unit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementSetupOrgUnitSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupOrgUnitSharing$Response(params: EntitySharingManagementSetupOrgUnitSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<OrgUnitEntitySharingDto>> {
    return entitySharingManagementSetupOrgUnitSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementSetupOrgUnitSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupOrgUnitSharing(params: EntitySharingManagementSetupOrgUnitSharing$Params, context?: HttpContext): Observable<OrgUnitEntitySharingDto> {
    return this.entitySharingManagementSetupOrgUnitSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrgUnitEntitySharingDto>): OrgUnitEntitySharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementSetupStorageUnitSharing()` */
  static readonly EntitySharingManagementSetupStorageUnitSharingPath = '/entity-sharing/management/per-entity/storage-unit';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementSetupStorageUnitSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupStorageUnitSharing$Response(params: EntitySharingManagementSetupStorageUnitSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitSharingDto>> {
    return entitySharingManagementSetupStorageUnitSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementSetupStorageUnitSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupStorageUnitSharing(params: EntitySharingManagementSetupStorageUnitSharing$Params, context?: HttpContext): Observable<StorageUnitSharingDto> {
    return this.entitySharingManagementSetupStorageUnitSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitSharingDto>): StorageUnitSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementSetupSharedFolderSharing()` */
  static readonly EntitySharingManagementSetupSharedFolderSharingPath = '/entity-sharing/management/per-entity/shared-folder';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementSetupSharedFolderSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupSharedFolderSharing$Response(params: EntitySharingManagementSetupSharedFolderSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderSharingDto>> {
    return entitySharingManagementSetupSharedFolderSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementSetupSharedFolderSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupSharedFolderSharing(params: EntitySharingManagementSetupSharedFolderSharing$Params, context?: HttpContext): Observable<SharedFolderSharingDto> {
    return this.entitySharingManagementSetupSharedFolderSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderSharingDto>): SharedFolderSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementSetupFileSharing()` */
  static readonly EntitySharingManagementSetupFileSharingPath = '/entity-sharing/management/per-entity/file';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementSetupFileSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupFileSharing$Response(params: EntitySharingManagementSetupFileSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<FileSharingDto>> {
    return entitySharingManagementSetupFileSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementSetupFileSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupFileSharing(params: EntitySharingManagementSetupFileSharing$Params, context?: HttpContext): Observable<FileSharingDto> {
    return this.entitySharingManagementSetupFileSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileSharingDto>): FileSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementSetupDocumentSharing()` */
  static readonly EntitySharingManagementSetupDocumentSharingPath = '/entity-sharing/management/per-entity/document';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementSetupDocumentSharing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupDocumentSharing$Response(params: EntitySharingManagementSetupDocumentSharing$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSharingDto>> {
    return entitySharingManagementSetupDocumentSharing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementSetupDocumentSharing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entitySharingManagementSetupDocumentSharing(params: EntitySharingManagementSetupDocumentSharing$Params, context?: HttpContext): Observable<DocumentSharingDto> {
    return this.entitySharingManagementSetupDocumentSharing$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSharingDto>): DocumentSharingDto => r.body)
    );
  }

  /** Path part for operation `entitySharingManagementSearchOrgUnitSharings()` */
  static readonly EntitySharingManagementSearchOrgUnitSharingsPath = '/entity-sharing/management/per-org-unit/search-api';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingManagementSearchOrgUnitSharings()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementSearchOrgUnitSharings$Response(params?: EntitySharingManagementSearchOrgUnitSharings$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOrgUnitEntitySharingDto>> {
    return entitySharingManagementSearchOrgUnitSharings(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingManagementSearchOrgUnitSharings$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingManagementSearchOrgUnitSharings(params?: EntitySharingManagementSearchOrgUnitSharings$Params|null|undefined, context?: HttpContext): Observable<PageOrgUnitEntitySharingDto> {
    return this.entitySharingManagementSearchOrgUnitSharings$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOrgUnitEntitySharingDto>): PageOrgUnitEntitySharingDto => r.body)
    );
  }

}
