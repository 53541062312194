/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { bulkPostingFormCancel } from '../fn/bulk-posting-form/bulk-posting-form-cancel';
import { BulkPostingFormCancel$Params } from '../fn/bulk-posting-form/bulk-posting-form-cancel';
import { bulkPostingFormCreate } from '../fn/bulk-posting-form/bulk-posting-form-create';
import { BulkPostingFormCreate$Params } from '../fn/bulk-posting-form/bulk-posting-form-create';
import { BulkPostingFormDetailDto } from '../models/bulk-posting-form-detail-dto';
import { BulkPostingFormDto } from '../models/bulk-posting-form-dto';
import { bulkPostingFormExport } from '../fn/bulk-posting-form/bulk-posting-form-export';
import { BulkPostingFormExport$Params } from '../fn/bulk-posting-form/bulk-posting-form-export';
import { bulkPostingFormFindById } from '../fn/bulk-posting-form/bulk-posting-form-find-by-id';
import { BulkPostingFormFindById$Params } from '../fn/bulk-posting-form/bulk-posting-form-find-by-id';
import { bulkPostingFormFindDetailById } from '../fn/bulk-posting-form/bulk-posting-form-find-detail-by-id';
import { BulkPostingFormFindDetailById$Params } from '../fn/bulk-posting-form/bulk-posting-form-find-detail-by-id';
import { bulkPostingFormGenerateName } from '../fn/bulk-posting-form/bulk-posting-form-generate-name';
import { BulkPostingFormGenerateName$Params } from '../fn/bulk-posting-form/bulk-posting-form-generate-name';
import { bulkPostingFormSearch } from '../fn/bulk-posting-form/bulk-posting-form-search';
import { BulkPostingFormSearch$Params } from '../fn/bulk-posting-form/bulk-posting-form-search';
import { bulkPostingFormSearchAndCount } from '../fn/bulk-posting-form/bulk-posting-form-search-and-count';
import { BulkPostingFormSearchAndCount$Params } from '../fn/bulk-posting-form/bulk-posting-form-search-and-count';
import { bulkPostingFormUpdate } from '../fn/bulk-posting-form/bulk-posting-form-update';
import { BulkPostingFormUpdate$Params } from '../fn/bulk-posting-form/bulk-posting-form-update';
import { bulkPostingFormWithdrawConsignment } from '../fn/bulk-posting-form/bulk-posting-form-withdraw-consignment';
import { BulkPostingFormWithdrawConsignment$Params } from '../fn/bulk-posting-form/bulk-posting-form-withdraw-consignment';
import { PageBulkPostingFormDto } from '../models/page-bulk-posting-form-dto';


/**
 * Contains endpoints related to Bulk Posting Form
 */
@Injectable({ providedIn: 'root' })
export class ApiBulkPostingFormService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `bulkPostingFormFindById()` */
  static readonly BulkPostingFormFindByIdPath = '/sad/bulk-posting-form/{id}';

  /**
   * Find bulk posting form by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormFindById$Response(params: BulkPostingFormFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkPostingFormDto>> {
    return bulkPostingFormFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find bulk posting form by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormFindById(params: BulkPostingFormFindById$Params, context?: HttpContext): Observable<BulkPostingFormDto> {
    return this.bulkPostingFormFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkPostingFormDto>): BulkPostingFormDto => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormUpdate()` */
  static readonly BulkPostingFormUpdatePath = '/sad/bulk-posting-form/{id}';

  /**
   * Updates BulkPostingForm.
   *
   * Updates BulkPostingForm
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPostingFormUpdate$Response(params: BulkPostingFormUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkPostingFormDto>> {
    return bulkPostingFormUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates BulkPostingForm.
   *
   * Updates BulkPostingForm
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPostingFormUpdate(params: BulkPostingFormUpdate$Params, context?: HttpContext): Observable<BulkPostingFormDto> {
    return this.bulkPostingFormUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkPostingFormDto>): BulkPostingFormDto => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormExport()` */
  static readonly BulkPostingFormExportPath = '/sad/bulk-posting-form/{id}/export';

  /**
   * Exports the BulkPostingForm. TODO unsure if export params should be specified here, from FS it seems like this is set on the BulkPostingForm object itself and cannot be changed once BulkPostingForm is locked.
   *
   * Exports BulkPostingForm.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormExport()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormExport$Response(params: BulkPostingFormExport$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkPostingFormExport(this.http, this.rootUrl, params, context);
  }

  /**
   * Exports the BulkPostingForm. TODO unsure if export params should be specified here, from FS it seems like this is set on the BulkPostingForm object itself and cannot be changed once BulkPostingForm is locked.
   *
   * Exports BulkPostingForm.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormExport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormExport(params: BulkPostingFormExport$Params, context?: HttpContext): Observable<void> {
    return this.bulkPostingFormExport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormCancel()` */
  static readonly BulkPostingFormCancelPath = '/sad/bulk-posting-form/{id}/cancel';

  /**
   * Cancels BulkPostingForm. Releases all the linked OwnPaperConsignments and thus allows them to be assigned to another BulkPostingForm.
   *
   * Cancels BulkPostingForm.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormCancel()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormCancel$Response(params: BulkPostingFormCancel$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkPostingFormCancel(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancels BulkPostingForm. Releases all the linked OwnPaperConsignments and thus allows them to be assigned to another BulkPostingForm.
   *
   * Cancels BulkPostingForm.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormCancel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormCancel(params: BulkPostingFormCancel$Params, context?: HttpContext): Observable<void> {
    return this.bulkPostingFormCancel$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormWithdrawConsignment()` */
  static readonly BulkPostingFormWithdrawConsignmentPath = '/sad/bulk-posting-form/{consignmentId}/withdraw-consignment';

  /**
   * Withdraws consignment from bulk posting form.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormWithdrawConsignment()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormWithdrawConsignment$Response(params: BulkPostingFormWithdrawConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return bulkPostingFormWithdrawConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraws consignment from bulk posting form.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormWithdrawConsignment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormWithdrawConsignment(params: BulkPostingFormWithdrawConsignment$Params, context?: HttpContext): Observable<void> {
    return this.bulkPostingFormWithdrawConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormCreate()` */
  static readonly BulkPostingFormCreatePath = '/sad/bulk-posting-form/';

  /**
   * Creates BulkPostingForm.
   *
   * Creates BulkPostingForm
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPostingFormCreate$Response(params: BulkPostingFormCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<BulkPostingFormDto>> {
    return bulkPostingFormCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates BulkPostingForm.
   *
   * Creates BulkPostingForm
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  bulkPostingFormCreate(params: BulkPostingFormCreate$Params, context?: HttpContext): Observable<BulkPostingFormDto> {
    return this.bulkPostingFormCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BulkPostingFormDto>): BulkPostingFormDto => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormSearch()` */
  static readonly BulkPostingFormSearchPath = '/sad/bulk-posting-form/search-api';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormSearch$Response(params?: BulkPostingFormSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageBulkPostingFormDto>> {
    return bulkPostingFormSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormSearch(params?: BulkPostingFormSearch$Params|null|undefined, context?: HttpContext): Observable<PageBulkPostingFormDto> {
    return this.bulkPostingFormSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageBulkPostingFormDto>): PageBulkPostingFormDto => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormSearchAndCount()` */
  static readonly BulkPostingFormSearchAndCountPath = '/sad/bulk-posting-form/search-api/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormSearchAndCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormSearchAndCount$Response(params?: BulkPostingFormSearchAndCount$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return bulkPostingFormSearchAndCount(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormSearchAndCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormSearchAndCount(params?: BulkPostingFormSearchAndCount$Params|null|undefined, context?: HttpContext): Observable<number> {
    return this.bulkPostingFormSearchAndCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormGenerateName()` */
  static readonly BulkPostingFormGenerateNamePath = '/sad/bulk-posting-form/generateName';

  /**
   * Generates unique name for next BulkPostingForm.
   *
   * Generates unique name for next BulkPostingForm using name generator. Note: name generator doesn't have to be set at all, name of the BulkPostingForm can be any and the only purpose of the generator is to ensure uniqueness.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormGenerateName()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormGenerateName$Response(params?: BulkPostingFormGenerateName$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return bulkPostingFormGenerateName(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates unique name for next BulkPostingForm.
   *
   * Generates unique name for next BulkPostingForm using name generator. Note: name generator doesn't have to be set at all, name of the BulkPostingForm can be any and the only purpose of the generator is to ensure uniqueness.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormGenerateName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormGenerateName(params?: BulkPostingFormGenerateName$Params|null|undefined, context?: HttpContext): Observable<string> {
    return this.bulkPostingFormGenerateName$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `bulkPostingFormFindDetailById()` */
  static readonly BulkPostingFormFindDetailByIdPath = '/sad/bulk-posting-form/detail/{id}';

  /**
   * Find bulk posting form detail by BPF id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `bulkPostingFormFindDetailById()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormFindDetailById$Response(params: BulkPostingFormFindDetailById$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BulkPostingFormDetailDto>>> {
    return bulkPostingFormFindDetailById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find bulk posting form detail by BPF id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `bulkPostingFormFindDetailById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  bulkPostingFormFindDetailById(params: BulkPostingFormFindDetailById$Params, context?: HttpContext): Observable<Array<BulkPostingFormDetailDto>> {
    return this.bulkPostingFormFindDetailById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BulkPostingFormDetailDto>>): Array<BulkPostingFormDetailDto> => r.body)
    );
  }

}
