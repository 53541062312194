import {Type} from '@angular/core';
import {CodebookFilterComponent} from './table-toolbar/filter-item/codebook-filter/codebook-filter.component';
import {EnumFilterComponent} from './table-toolbar/filter-item/enum-filter/enum-filter.component';
import {FilterType} from './filter.types';
import {IczTableFilter} from './custom-filters/table-filter.component';
import {BasicFiltersComponent} from './table-toolbar/filter-item/basic-filters/basic-filters.component';
import {
  EmpowermentCustomFilterComponent
} from './custom-filters/empowerment-custom-filter/empowerment-custom-filter.component';
import {SubjectCustomFilterComponent} from './custom-filters/subject-custom-filter/subject-custom-filter.component';
import {AddressCustomFilterComponent} from './custom-filters/address-custom-filter/address-custom-filter.component';


export const FILTER_WIDGETS: Record<FilterType, Nullable<Type<IczTableFilter>>> = {
  [FilterType.TEXT]: BasicFiltersComponent,
  [FilterType.DATE]: BasicFiltersComponent,
  [FilterType.DATE_STATISTICS]: BasicFiltersComponent,
  [FilterType.DATETIME]: BasicFiltersComponent,
  [FilterType.NUMBER]: BasicFiltersComponent,
  [FilterType.ENUM]: EnumFilterComponent,
  [FilterType.CODEBOOK]: CodebookFilterComponent,
  [FilterType.BOOLEAN]: BasicFiltersComponent,
  [FilterType.FILE_SIZE]: BasicFiltersComponent,
  [FilterType.EMPOWERMENT]: EmpowermentCustomFilterComponent,
  [FilterType.SUBJECT_RECORD]: SubjectCustomFilterComponent,
  [FilterType.ADDRESS]: AddressCustomFilterComponent,
  [FilterType.NONE]: null,
};
