/* tslint:disable */
/* eslint-disable */

/**
 * Object class for Storage unit
 */
export enum StorageUnitAllowableObjectClass {
  STORAGE_UNIT = 'STORAGE_UNIT',
  STORAGE_UNIT_DIGITAL = 'STORAGE_UNIT_DIGITAL'
}
