<icz-add-subject-step-create
  #subjectCreateComponent
  [form]="form"
  [documentId]="documentData.id"
  [dataForCreate]="consigneeCreationData"
  [useCustomFormButtons]="false"
  [allowEmitCreateResult]="createRequested"
  (result)="createResult($event)"
  (blockingAction)="blockingAction.emit($event)"
></icz-add-subject-step-create>
