<form [waiting]="loadingService.isLoading(this)" [formGroup]="form">
  <icz-section label="Kam budete chtít ztvárnění uložit?">
    <icz-radio-group>
      <icz-radio-button formControlName="outputType" [value]="FileRenditionOutputType.APPEND_TO_DOCUMENT" label="Připojit k existujícímu dokumentu"></icz-radio-button>
      @if (isDocumentAppendMode) {
        <icz-document-selector
          class="block mt-4"
          [form]="form"
          controlName="documentId"
          [objectClasses]="OWN_DOCUMENT_OBJECT_CLASSES"
        ></icz-document-selector>
      }
      <icz-radio-button formControlName="outputType" [value]="FileRenditionOutputType.DOWNLOAD" label="Uložit na disk v mém počítači"></icz-radio-button>
    </icz-radio-group>
  </icz-section>
  <icz-section label="Mají být součástí ztvárnění i originály?">
    <icz-checkbox formControlName="includeOriginalComponents" label="Připojit ke ztvárnění i originály komponent"></icz-checkbox>
  </icz-section>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
  (onAction)="submit()" primary label="Provést ztvárnění"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
  (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
