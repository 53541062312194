/* tslint:disable */
/* eslint-disable */

/**
 * Type of entity class
 */
export enum EntityClassType {
  DOCUMENT_FILE = 'DOCUMENT_FILE',
  ENTITY_CLASS_SUB_FILE = 'ENTITY_CLASS_SUB_FILE',
  ENTITY_CLASS_CASE_FILE = 'ENTITY_CLASS_CASE_FILE',
  ENTITY_CLASS = 'ENTITY_CLASS'
}
