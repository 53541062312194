import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {EpdzErrorDetailDto} from '|api/sad';

@Component({
  selector: 'icz-digital-message-processing-errors',
  templateUrl: './digital-message-processing-errors.component.html',
  styleUrls: ['./digital-message-processing-errors.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalMessageProcessingErrorsComponent {

  @Input({required: true})
  errorDetails: Nullable<EpdzErrorDetailDto[]>;

  isErrorsPopupOpen = false;

  openErrorsPopup() {
    this.isErrorsPopupOpen = true;
  }

  closeErrorsPopup() {
    this.isErrorsPopupOpen = false;
  }

}
