<icz-validation-errors-list [control]="control" [showValidationStatus]="asPopover">
  <icz-composable-form-autocomplete
    [value]="__value"
    (valueChange)="_valueChanged($event)"
    (blur)="blur.emit()"
    [label]="label"
    [placeholder]="placeholder"
    [options]="_visibleOptions"
    [disabled]="fieldDisabled"
    [clearable]="clearable"
    [required]="required"
    [asPopover]="asPopover"
    [isMultiselect]="isMultiselect"
    [autoSizeMax]="autoSizeMax"
    [popoverContentTemplate]="popoverContentTemplate"
    [selectedOptionTemplate]="selectedOptionTemplate">

    <ng-template #selectedOptionTemplate let-context>
      @if (!isMultiselect) {
        @for (so of context.selectedOptions; track so.value) {
          <!--
          In Angular 16, context.selectedOptions has wrong inferred type thus using $any.
          todo(rb) try to remove $any after upgrading to another version of Angular.
          -->
          @if (customSelectedOptionTemplate) {
            <ng-container
              [ngTemplateOutlet]="$any(customSelectedOptionTemplate)"
              [ngTemplateOutletContext]="{$implicit: {option: so}}">
            </ng-container>
          }
        @else {
            <icz-form-autocomplete-list-text-item
              [option]="so" [iczTooltip]="so.label"
            ></icz-form-autocomplete-list-text-item>
          }
        }
      }
      @if (isMultiselect) {
        <div class="chip-list">
          @for (so of context.selectedOptions; track so.value) {
            <icz-form-autocomplete-chip
              [removable]="!fieldDisabled"
              [iczTooltip]="so.label"
              (removeClicked)="deselectOption(so)"
            >
              <!--
              In Angular 16, context.selectedOptions has wrong inferred type thus using $any.
              todo(rb) try to remove $any after upgrading to another version of Angular.
              -->
              @if (customSelectedOptionTemplate) {
                <ng-container
                  [ngTemplateOutlet]="$any(customSelectedOptionTemplate)"
                  [ngTemplateOutletContext]="{$implicit: {option: so}}"
                ></ng-container>
              }
              @else {
                {{ so.label | translate }}
              }
            </icz-form-autocomplete-chip>
          }
        </div>
      }
    </ng-template>

    <ng-template #popoverContentTemplate let-context>
      <icz-form-autocomplete-tree-list
        [selectionStrategy]="selectionStrategy"
        [searchTermInputPrefix]="searchTermInputPrefix"
        [searchTermInputPlaceholder]="asPopover ? '' : placeholder"
        [listItemTemplate]="customOptionTemplate || listItemTemplate"
        [isMultiselect]="isMultiselect"
        [options]="context.options"
        [containerHeight]="containerHeight"
        [selectedOptions]="context.selectedOptions"
        (selectionChanged)="context.selectionChanged($event)"
        (searchTermChanged)="context.searchTermChanged($event)"
        >

        <ng-content additionalContentBeforeOptions select="[additionalContentBeforeOptions]"></ng-content>

        <ng-template #listItemTemplate let-context>
          <icz-form-autocomplete-list-text-item
            [option]="context.option"
          ></icz-form-autocomplete-list-text-item>
        </ng-template>

      </icz-form-autocomplete-tree-list>
    </ng-template>

  </icz-composable-form-autocomplete>
</icz-validation-errors-list>
