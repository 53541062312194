/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { externalRetentionTriggerCreate } from '../fn/external-retention-trigger/external-retention-trigger-create';
import { ExternalRetentionTriggerCreate$Params } from '../fn/external-retention-trigger/external-retention-trigger-create';
import { externalRetentionTriggerDelete } from '../fn/external-retention-trigger/external-retention-trigger-delete';
import { ExternalRetentionTriggerDelete$Params } from '../fn/external-retention-trigger/external-retention-trigger-delete';
import { ExternalRetentionTriggerDto } from '../models/external-retention-trigger-dto';
import { externalRetentionTriggerFindAll } from '../fn/external-retention-trigger/external-retention-trigger-find-all';
import { ExternalRetentionTriggerFindAll$Params } from '../fn/external-retention-trigger/external-retention-trigger-find-all';
import { externalRetentionTriggerGet } from '../fn/external-retention-trigger/external-retention-trigger-get';
import { ExternalRetentionTriggerGet$Params } from '../fn/external-retention-trigger/external-retention-trigger-get';
import { externalRetentionTriggerSearch } from '../fn/external-retention-trigger/external-retention-trigger-search';
import { ExternalRetentionTriggerSearch$Params } from '../fn/external-retention-trigger/external-retention-trigger-search';
import { externalRetentionTriggerUpdate } from '../fn/external-retention-trigger/external-retention-trigger-update';
import { ExternalRetentionTriggerUpdate$Params } from '../fn/external-retention-trigger/external-retention-trigger-update';
import { PageExternalRetentionTriggerDto } from '../models/page-external-retention-trigger-dto';


/**
 * Controller for External Retention Trigger
 */
@Injectable({ providedIn: 'root' })
export class ApiExternalRetentionTriggerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalRetentionTriggerGet()` */
  static readonly ExternalRetentionTriggerGetPath = '/codebook/external-retention-trigger/{id}';

  /**
   * Gets external retention trigger.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalRetentionTriggerGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerGet$Response(params: ExternalRetentionTriggerGet$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalRetentionTriggerDto>> {
    return externalRetentionTriggerGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets external retention trigger.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalRetentionTriggerGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerGet(params: ExternalRetentionTriggerGet$Params, context?: HttpContext): Observable<ExternalRetentionTriggerDto> {
    return this.externalRetentionTriggerGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalRetentionTriggerDto>): ExternalRetentionTriggerDto => r.body)
    );
  }

  /** Path part for operation `externalRetentionTriggerUpdate()` */
  static readonly ExternalRetentionTriggerUpdatePath = '/codebook/external-retention-trigger/{id}';

  /**
   * Update external retention trigger.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalRetentionTriggerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalRetentionTriggerUpdate$Response(params: ExternalRetentionTriggerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalRetentionTriggerDto>> {
    return externalRetentionTriggerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update external retention trigger.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalRetentionTriggerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalRetentionTriggerUpdate(params: ExternalRetentionTriggerUpdate$Params, context?: HttpContext): Observable<ExternalRetentionTriggerDto> {
    return this.externalRetentionTriggerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalRetentionTriggerDto>): ExternalRetentionTriggerDto => r.body)
    );
  }

  /** Path part for operation `externalRetentionTriggerDelete()` */
  static readonly ExternalRetentionTriggerDeletePath = '/codebook/external-retention-trigger/{id}';

  /**
   * Delete external retention trigger.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalRetentionTriggerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerDelete$Response(params: ExternalRetentionTriggerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return externalRetentionTriggerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete external retention trigger.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalRetentionTriggerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerDelete(params: ExternalRetentionTriggerDelete$Params, context?: HttpContext): Observable<void> {
    return this.externalRetentionTriggerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `externalRetentionTriggerFindAll()` */
  static readonly ExternalRetentionTriggerFindAllPath = '/codebook/external-retention-trigger';

  /**
   * Returns all existing external retention triggers in the system.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalRetentionTriggerFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerFindAll$Response(params?: ExternalRetentionTriggerFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<ExternalRetentionTriggerDto>>> {
    return externalRetentionTriggerFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all existing external retention triggers in the system.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalRetentionTriggerFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerFindAll(params?: ExternalRetentionTriggerFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<ExternalRetentionTriggerDto>> {
    return this.externalRetentionTriggerFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExternalRetentionTriggerDto>>): Array<ExternalRetentionTriggerDto> => r.body)
    );
  }

  /** Path part for operation `externalRetentionTriggerCreate()` */
  static readonly ExternalRetentionTriggerCreatePath = '/codebook/external-retention-trigger';

  /**
   * Creates external retention trigger
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalRetentionTriggerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalRetentionTriggerCreate$Response(params: ExternalRetentionTriggerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalRetentionTriggerDto>> {
    return externalRetentionTriggerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates external retention trigger
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalRetentionTriggerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalRetentionTriggerCreate(params: ExternalRetentionTriggerCreate$Params, context?: HttpContext): Observable<ExternalRetentionTriggerDto> {
    return this.externalRetentionTriggerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalRetentionTriggerDto>): ExternalRetentionTriggerDto => r.body)
    );
  }

  /** Path part for operation `externalRetentionTriggerSearch()` */
  static readonly ExternalRetentionTriggerSearchPath = '/codebook/external-retention-trigger/search-api';

  /**
   * Finds external retention trigger
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalRetentionTriggerSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerSearch$Response(params?: ExternalRetentionTriggerSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageExternalRetentionTriggerDto>> {
    return externalRetentionTriggerSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds external retention trigger
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalRetentionTriggerSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalRetentionTriggerSearch(params?: ExternalRetentionTriggerSearch$Params|null|undefined, context?: HttpContext): Observable<PageExternalRetentionTriggerDto> {
    return this.externalRetentionTriggerSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageExternalRetentionTriggerDto>): PageExternalRetentionTriggerDto => r.body)
    );
  }

}
