/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagePortalNodeDto } from '../models/page-portal-node-dto';
import { PortalNodeDto } from '../models/portal-node-dto';
import { portalNodesOauthCreate } from '../fn/portal-nodes-oauth/portal-nodes-oauth-create';
import { PortalNodesOauthCreate$Params } from '../fn/portal-nodes-oauth/portal-nodes-oauth-create';
import { portalNodesOauthGetAll } from '../fn/portal-nodes-oauth/portal-nodes-oauth-get-all';
import { PortalNodesOauthGetAll$Params } from '../fn/portal-nodes-oauth/portal-nodes-oauth-get-all';
import { portalNodesOauthGetById } from '../fn/portal-nodes-oauth/portal-nodes-oauth-get-by-id';
import { PortalNodesOauthGetById$Params } from '../fn/portal-nodes-oauth/portal-nodes-oauth-get-by-id';
import { portalNodesOauthGetDefault } from '../fn/portal-nodes-oauth/portal-nodes-oauth-get-default';
import { PortalNodesOauthGetDefault$Params } from '../fn/portal-nodes-oauth/portal-nodes-oauth-get-default';
import { portalNodesOauthSearch } from '../fn/portal-nodes-oauth/portal-nodes-oauth-search';
import { PortalNodesOauthSearch$Params } from '../fn/portal-nodes-oauth/portal-nodes-oauth-search';
import { portalNodesOauthUpdate } from '../fn/portal-nodes-oauth/portal-nodes-oauth-update';
import { PortalNodesOauthUpdate$Params } from '../fn/portal-nodes-oauth/portal-nodes-oauth-update';


/**
 * API for management of portal nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiPortalNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `portalNodesOauthUpdate()` */
  static readonly PortalNodesOauthUpdatePath = '/oauth/portal-nodes/update-node/{id}';

  /**
   * Updates portal node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `portalNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  portalNodesOauthUpdate$Response(params: PortalNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalNodeDto>> {
    return portalNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates portal node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `portalNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  portalNodesOauthUpdate(params: PortalNodesOauthUpdate$Params, context?: HttpContext): Observable<PortalNodeDto> {
    return this.portalNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PortalNodeDto>): PortalNodeDto => r.body)
    );
  }

  /** Path part for operation `portalNodesOauthCreate()` */
  static readonly PortalNodesOauthCreatePath = '/oauth/portal-nodes/create-node';

  /**
   * Creates new portal node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `portalNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  portalNodesOauthCreate$Response(params: PortalNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalNodeDto>> {
    return portalNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new portal node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `portalNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  portalNodesOauthCreate(params: PortalNodesOauthCreate$Params, context?: HttpContext): Observable<PortalNodeDto> {
    return this.portalNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PortalNodeDto>): PortalNodeDto => r.body)
    );
  }

  /** Path part for operation `portalNodesOauthGetById()` */
  static readonly PortalNodesOauthGetByIdPath = '/oauth/portal-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `portalNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthGetById$Response(params: PortalNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalNodeDto>> {
    return portalNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `portalNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthGetById(params: PortalNodesOauthGetById$Params, context?: HttpContext): Observable<PortalNodeDto> {
    return this.portalNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PortalNodeDto>): PortalNodeDto => r.body)
    );
  }

  /** Path part for operation `portalNodesOauthSearch()` */
  static readonly PortalNodesOauthSearchPath = '/oauth/portal-nodes/search-api';

  /**
   * Find all values in portal nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `portalNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthSearch$Response(params?: PortalNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePortalNodeDto>> {
    return portalNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in portal nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `portalNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthSearch(params?: PortalNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PagePortalNodeDto> {
    return this.portalNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePortalNodeDto>): PagePortalNodeDto => r.body)
    );
  }

  /** Path part for operation `portalNodesOauthGetAll()` */
  static readonly PortalNodesOauthGetAllPath = '/oauth/portal-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `portalNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthGetAll$Response(params?: PortalNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<PortalNodeDto>>> {
    return portalNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `portalNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthGetAll(params?: PortalNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<PortalNodeDto>> {
    return this.portalNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PortalNodeDto>>): Array<PortalNodeDto> => r.body)
    );
  }

  /** Path part for operation `portalNodesOauthGetDefault()` */
  static readonly PortalNodesOauthGetDefaultPath = '/oauth/portal-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `portalNodesOauthGetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthGetDefault$Response(params?: PortalNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PortalNodeDto>> {
    return portalNodesOauthGetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `portalNodesOauthGetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  portalNodesOauthGetDefault(params?: PortalNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<PortalNodeDto> {
    return this.portalNodesOauthGetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<PortalNodeDto>): PortalNodeDto => r.body)
    );
  }

}
