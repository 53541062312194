import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Option} from '../../../../model';

@Component({
  selector: 'icz-item-sorter-item',
  templateUrl: './item-sorter-item.component.html',
  styleUrls: ['./item-sorter-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemSorterItemComponent {
  @Input({required: true})
  index = 0;
  @Input({required: true})
  itemCount = 0;
  @Input({required: true})
  option!: Option;
  @Output()
  upPressed = new EventEmitter<void>();
  @Output()
  downPressed = new EventEmitter<void>();
}
