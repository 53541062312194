/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageDigitalComponentTemplateParameterDto } from '../models/page-digital-component-template-parameter-dto';
import { templateParameterSearch } from '../fn/template-parameter/template-parameter-search';
import { TemplateParameterSearch$Params } from '../fn/template-parameter/template-parameter-search';


/**
 * Controller for Digital component template parameters
 */
@Injectable({ providedIn: 'root' })
export class ApiTemplateParameterService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `templateParameterSearch()` */
  static readonly TemplateParameterSearchPath = '/codebook/template-parameter/search-api';

  /**
   * Search api for template parameter.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `templateParameterSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateParameterSearch$Response(params?: TemplateParameterSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDigitalComponentTemplateParameterDto>> {
    return templateParameterSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Search api for template parameter.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `templateParameterSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  templateParameterSearch(params?: TemplateParameterSearch$Params|null|undefined, context?: HttpContext): Observable<PageDigitalComponentTemplateParameterDto> {
    return this.templateParameterSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDigitalComponentTemplateParameterDto>): PageDigitalComponentTemplateParameterDto => r.body)
    );
  }

}
