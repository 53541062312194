import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {SharedFolderSelectorComponent, SharedFolderSelectorView} from '../../../shared-folder-selector/shared-folder-selector.component';
import {injectModalData, injectModalRef} from '../../../../../lib/modals';
import {SharedFolderDto} from '|api/document';

export interface SharedFolderSelectionDialogData {
  submitButtonText: string;
  disabledFolderIds: number[];
}

@Component({
  selector: 'icz-shared-folder-selection-dialog',
  templateUrl: './shared-folder-selection-dialog.component.html',
  styleUrls: ['./shared-folder-selection-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class SharedFolderSelectionDialogComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<SharedFolderDto>>();
  protected loadingService = inject(LoadingIndicatorService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected dialogData = injectModalData<SharedFolderSelectionDialogData>();

  @ViewChild(SharedFolderSelectorComponent)
  sharedFolderSelectorComponent!: SharedFolderSelectorComponent;

  formGroupsToCheck!: string[];

  form = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, [IczValidators.required()])
  });

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  submit() {
    this.modalRef.close(this.sharedFolderSelectorComponent.options.find(o => o.value === this.form.getRawValue().id)!.data);
  }

  close() {
    this.modalRef.close(null);
  }

  protected readonly SharedFolderSelectorView = SharedFolderSelectorView;
}
