import {Observable} from 'rxjs/internal/Observable';
import {DigitalComponentCompleteDto, DocumentSignRequest} from '|api/component';
import {DocumentSignResponse} from '|api/document';

export interface SignatureConfigurationDialogData<TResponse> {
  documentId: number;
  isBulkOperation: boolean;
  signRequestFn: (documentId: number, signRequest: DocumentSignRequest) => Observable<TResponse>;
  signResponseSelector: (res: TResponse) => DocumentSignResponse | BulkComponentSignResponse;
  signableWithVisualSignatureOption?: DigitalComponentWithResult[];
  signableWithoutVisualSignatureOption?: DigitalComponentWithResult[];
}

export interface BulkComponentSignResponse {
  bulkComponentSignStarted: boolean;
}

export function isBulkComponentSignResponse(response: any): response is BulkComponentSignResponse {
  return (response as BulkComponentSignResponse).bulkComponentSignStarted !== undefined;
}

export interface PreSignatureValidationDialogData {
  documentId: number;
  isBulkOperation: boolean;
  digitalComponents: DigitalComponentCompleteDto[];
}

export interface PreSignatureValidationResult {
  documentCriticalError?: Nullable<DocumentPreSignatureValidationResult>;
  digitalComponentResults: DigitalComponentWithResult[];
}

export interface DocumentPreSignatureValidationResult {
  errorCode?: Nullable<string>;
  errorParameters?: Nullable<string[]>;
}

export interface DigitalComponentWithResult extends DigitalComponentCompleteDto {
  errorCode?: Nullable<string>;
  errorParameters?: Nullable<string[]>;
  infoCode?: Nullable<string>;
  infoParameters?: Nullable<string[]>;
  fileName: Nullable<string>;
}
