<div>
  @if (dialogData.showHandCopyToAll) {
    <icz-button
      class="inline-block"
      label="Všem ve skupině předat duplikát dokumentu"
      (onAction)="handCopyToAll()"
    ></icz-button>
  }

  <div class="org-structure-element-chips pt-4 px-4 mt-16">
    @for (element of orgStructureElements; track element.value) {
      <icz-chip
        (clicked)="orgStructureElementSelected(element)" class="mr-4">
        <div content class="row">
          @if (element.originId === 'ou') {
            <icz-icon size="small" svgIcon="hierarchy" class="h-16"></icz-icon>
          }
          <div class="grow">
            {{ element.label | translate }}
          </div>
        </div>
      </icz-chip>
    }
  </div>
</div>
