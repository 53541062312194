import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {OwnInternalPaperConsignmentDto, OwnPaperConsignmentDto} from '|api/sad';
import {CodebookService} from '../../../core/services/codebook.service';
import {namedDtosToOptions} from '../../../core/services/data-mapping.utils';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {Option} from '../../../model';

export interface Code2DMetadata {
  country: string;
  dpmVersion: number;
  customerNumber: string;
  weight: number;
  submissionDate: string; // Datum podání
  payoutAmount: number;
  bulkConsignmentRegistrationNo: string; // Kumulativní reg. zásilek
  postalServiceCode: string;
  postalFeeSum: number; // Vz. Registr – suma poštovného
  signatureKey: string;
}

export interface ConsignmentCommand {
  consignment: OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto;
  code2DMetadata: Nullable<Code2DMetadata>;
  cdz: Nullable<string>;
}

@Component({
  selector: 'icz-barcode-scanned-consignments-table',
  templateUrl: './barcode-scanned-consignments-table.component.html',
  styleUrls: ['./barcode-scanned-consignments-table.component.scss'],
})
export class BarcodeScannedConsignmentsTableComponent implements OnInit {

  private codebookService = inject(CodebookService);
  private loadingService = inject(LoadingIndicatorService);

  @Input({required: true})
  consignmentCommands!: ConsignmentCommand[];
  @Input()
  showDeliveryServiceEdit = true;
  @Output()
  consignmentDeliveryServiceEditRequested = new EventEmitter<ConsignmentCommand>();
  @Output()
  consignmentRemovalRequested = new EventEmitter<ConsignmentCommand>();

  deliveryServiceCombinationOptions: Option[] = [];

  ngOnInit() {
    this.loadingService.doLoading(
      this.codebookService.deliveryServiceCombinations().pipe(
        namedDtosToOptions,
      ),
      this
    ).subscribe(
      deliveryServiceCombinationOptions => {
        this.deliveryServiceCombinationOptions = deliveryServiceCombinationOptions;
      }
    );
  }
}
