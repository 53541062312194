/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReceivedPaperConsignmentDto } from '../../models/received-paper-consignment-dto';
import { ReceivedPaperConsignmentUpdateDto } from '../../models/received-paper-consignment-update-dto';

export interface ReceivedPaperConsignmentUpdate$Params {

/**
 * Id of own consignment to update
 */
  id: number;
      body: ReceivedPaperConsignmentUpdateDto
}

export function receivedPaperConsignmentUpdate(http: HttpClient, rootUrl: string, params: ReceivedPaperConsignmentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
  const rb = new RequestBuilder(rootUrl, receivedPaperConsignmentUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReceivedPaperConsignmentDto>;
    })
  );
}

receivedPaperConsignmentUpdate.PATH = '/sad/received-paper-consignment/{id}';