@if (czechOrSlovakAddressForm) {
  <div [formGroup]="czechOrSlovakAddressForm">
    @if (isVerified || isReadonly) {
      <div class="row">
        <icz-readonly-field
          class="grow"
          label="Země"
          [value]="readonlyAddressFormatType | translate"
        >
          @if (isVerified) {
            <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
          }
        </icz-readonly-field>
      </div>
    }
    <div class="row">
      <icz-form-field class="grow" formControlName="street" label="Název ulice">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
    </div>
    <div class="row">
      <icz-form-field formControlName="houseNumber" label="Číslo popisné" class="grow-1">
        <icz-icon suffix size="small" iczTooltip="ui.document.address.tooltip" svgIcon="info"></icz-icon>
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked" class="ml-4"></icz-icon>
        }
      </icz-form-field>
      <icz-form-field formControlName="orientationNumber" label="Číslo orientační" class="grow-1">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
      <icz-form-field formControlName="registrationNumber" label="Číslo evidenční" class="grow-1">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
    </div>
    <div class="row">
      <icz-form-field class="grow" formControlName="city" label="Obec">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
      <icz-form-field class="grow" formControlName="district" label="Část obce">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
    </div>
    <div class="row">
      <icz-form-field class="grow" formControlName="postalCode" label="PSČ">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
      <icz-form-field class="grow" formControlName="postalOffice" label="Pošta">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
      <icz-form-field class="grow" formControlName="pragueDistrict" label="Městská část">
        @if (isVerified) {
          <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
        }
      </icz-form-field>
    </div>
  </div>
}
