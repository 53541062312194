import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {IczModalService} from '../../services/icz-modal.service';
import {InterpolationContext} from '../../lib/model';
import {DialogMode, DialogSeverity, DialogWithTitleData, QuestionDialogData, SimpleDialogData} from './dialog.models';
import {SimpleDialogComponent} from '../../components/dialogs/simple-dialog/simple-dialog.component';
import {filter} from 'rxjs/operators';
import {castStream} from '../../lib/rxjs';
import {ErrorDialogComponent} from '../../components/dialogs/error-dialog/error-dialog.component';


@Injectable({
  providedIn: 'root',
})
export class DialogService {

  private modalService = inject(IczModalService);

  showError(
    text: string,
    title?: string,
    context: InterpolationContext = {},
  ): Observable<Nullable<boolean>> {
    if (!title) {
      if (text) {
        title = 'ui.error.title';
      }
      else {
        title = 'ui.error.title.unknown';
      }
    }

    const data: DialogWithTitleData = {
      severity: DialogSeverity.ERROR,
      title,
      titleContext: context,
      content: [
        {
          text,
          context,
        },
      ],
    };
    return this.openSimpleDialog(data);
  }

  openCommunicationErrorDialog(data: DialogWithTitleData) {
    return this.modalService.openComponentInModalWithInstance<boolean, SimpleDialogData>({
      component: ErrorDialogComponent,
      modalOptions: {
        width: 450,
        height: 350,
        titleTemplate: data.title ?? (data.severity === DialogSeverity.ERROR ? 'Nastala chyba' : 'Upozornění'),
      },
      data
    });
  }

  isAnyDialogOpened() {
    return this.modalService.hasOpenModalOfType(SimpleDialogComponent);
  }

  openSimpleDialog(data: DialogWithTitleData): Observable<Nullable<boolean>> {
    return this.openSimpleDialogWithInstance(data).afterClosed();
  }

  openSimpleDialogWithInstance(data: DialogWithTitleData) {
    return this.modalService.openComponentInModalWithInstance<boolean, SimpleDialogData>({
      component: SimpleDialogComponent,
      modalOptions: {
        width: 450,
        height: 350,
        titleTemplate: data.title ?? (data.severity === DialogSeverity.ERROR ? 'Nastala chyba' : 'Upozornění'),
        titleTemplateContext: data.titleContext,
        isClosable: false,
      },
      data: {
        ...data,
        severity: data.severity ?? DialogSeverity.INFO,
        mode: data.mode ?? DialogMode.SIMPLE,
        leftButtonTitle: data.leftButtonTitle ?? 'Potvrdit',
        rightButtonTitle: data.rightButtonTitle ?? 'Storno',
        showLeftButton: data.showLeftButton ?? true,
        showRightButton: data.showRightButton ?? true,
      }
    });
  }

  /**
   * Opens simple question dialog.
   * @param data - default params severity = DialogSeverity.INFO, rightButtonTitle = 'Storno', leftButtonTitle = 'Smazat'
   */
  openQuestionDialog(data: QuestionDialogData): Observable<void> {
    return this.openQuestionDialogWithAnswer(data).pipe(filter(result => result), castStream<void>());
  }

  openQuestionDialogWithAnswer(data: QuestionDialogData): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, SimpleDialogData>({
      component: SimpleDialogComponent,
      modalOptions: {
        width: 450,
        height: 350,
        titleTemplate: data.title,
        titleTemplateContext: data.titleContext,
        isClosable: false,
      },
      data: {
        severity: data.severity ?? DialogSeverity.INFO,
        mode: DialogMode.ACCENTED,
        content: [
          {
            text: data.question,
            context: data.questionContext
          },
          {
            text: data.description,
            context: data.descriptionContext
          }
        ],
        leftButtonTitle: data.leftButtonTitle ?? 'Smazat',
        rightButtonTitle: data.rightButtonTitle ?? 'Storno',
        showLeftButton: true,
        showRightButton: true,
      }
    });
  }

  openUnsavedChangesDialog(): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, SimpleDialogData>({
      component: SimpleDialogComponent,
      modalOptions: {
        width: 450,
        height: 350,
        titleTemplate: 'Ztratíte neuložená data',
        isClosable: false,
      },
      data: {
        severity: DialogSeverity.INFO,
        mode: DialogMode.ACCENTED,
        content: [
          {
            text: 'Ztratíte neuložená data'
          },
          {
            text:  'Opouštíte rozpracovaný formulář. Neuložená data budou ztracena.'
          }
        ],
        leftButtonTitle: 'Zůstat',
        rightButtonTitle: 'Opustit',
        showLeftButton: true,
        showRightButton: true,
      }
    });
  }

}
