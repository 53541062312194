import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {
  GenericOwnConsignment,
  isOfficeDeskConsignment,
  isOwnPortalConsignment
} from '../../own-consignment-table/model/own-consignment-model';
import {OwnConsignmentStatus} from '|api/commons';
import {TranslateService} from '@ngx-translate/core';
import {
  isOwnDigitalConsignment,
  isOwnInternalConsignment,
  isOwnInternalPaperConsignment,
  isOwnPaperConsignment,
  isOwnPersonalConsignment
} from '../../own-consignment-table/own-consignment-workflow.service';
import {DeliveryTypeDto} from '|api/codebook';
import {DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE} from '../../shared-document.utils';

export class ConsignmentOperationValidators {

  static notInAllowedStatus(allowedStatus: OwnConsignmentStatus, translateService: TranslateService): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      const isIncorrectStatus = c.status !== allowedStatus;

      if (isIncorrectStatus) {
        return {
          validationMessage: 'Zásilka není ve stavu {{state}}.',
          validationMessageContext: {state: translateService.instant(`en.ownConsignmentStatus.${allowedStatus}`)}
        };
      }
      else {
        return null;
      }
    };
  }

  static isNotPaperOrInternalPaperConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return (!isOwnPaperConsignment(c) && !isOwnInternalPaperConsignment(c)) ?
        {validationMessage: 'Zásilka není listinná ani interní listinná.'} :
        null;
    };
  }

  static notInOneOfAllowedStatus(allowedStatuses: OwnConsignmentStatus[], translateService: TranslateService): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      const isIncorrectStatus = allowedStatuses.every(s => c.status !== s);

      if (isIncorrectStatus) {
        const joinedStatuses = allowedStatuses.map(s => translateService.instant(`en.ownConsignmentStatus.${s}`)).toString().replaceAll(',', ', ');

        return {
          validationMessage: 'Zásilka není v nekterém ze stavů: {{statuses}}.',
          validationMessageContext: {statuses: joinedStatuses}
        };
      }
      else {
        return null;
      }
    };
  }

  static isPaperConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return isOwnPaperConsignment(c) ? {validationMessage: 'Zásilka je listinná.'} : null;
    };
  }

  static isNotPaperConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return !ConsignmentOperationValidators.isPaperConsignment()(c) ? {validationMessage: 'Zásilka není listinná.'} : null;
    };
  }

  static isNotPaperOrInternalAnalogConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return !isOwnPaperConsignment(c) && !isOwnInternalPaperConsignment(c)  ? {validationMessage: 'Zásilka není listinná nebo interní analogová.'} : null;
    };
  }

  static isPersonalConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return isOwnPersonalConsignment(c) ? {validationMessage: 'Zásilka je osobní.'} : null;
    };
  }

  static isPortalConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return isOwnPortalConsignment(c) ? {validationMessage: 'Zásilka je portálová.'} : null;
    };
  }

  static isDigitalConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return isOwnDigitalConsignment(c) ? {validationMessage: 'Zásilka je do datové schránky nebo přes e-mail.'} : null;
    };
  }

  static isNotDigitalConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return !isOwnDigitalConsignment(c) ? {validationMessage: 'Zásilka není do datové schránky nebo přes e-mail.'} : null;
    };
  }

  static isInternalConsignment(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return isOwnInternalConsignment(c) ? {validationMessage: 'Zásilka je interní analogová.'} : null;
    };
  }

  static isInternalConsignmentInDistribution(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return (isOwnInternalConsignment(c) && c.status === OwnConsignmentStatus.IN_DISTRIBUTION) ? {validationMessage: 'Interní zásilka je v distribuci.'} : null;
    };
  }

  static isEmailOutsideFilingOffice(deliveryTypes: DeliveryTypeDto[]): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      const emailOutsideFilingOfficeDeliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE)!.id;
      return (c.deliveryTypeId === emailOutsideFilingOfficeDeliveryTypeId) ? {validationMessage: 'Zásilka je mimo EPO.'} : null;
    };
  }

  static isNotEmailOutsideFilingOffice(deliveryTypes: DeliveryTypeDto[]): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      const emailOutsideFilingOfficeDeliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE)!.id;
      return (c.deliveryTypeId !== emailOutsideFilingOfficeDeliveryTypeId) ? {validationMessage: 'Zásilka není mimo EPO.'} : null;
    };
  }

  static consignmentHasNoProofOfDelivery(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return !c.proofOfDeliveryId ?
        {validationMessage: 'Doručenku lze připojit pouze k zásilce, které již bylo zapsáno doručení v minulosti.'} :
        null;
    };
  }

  static isNotSupportedForLabelPrinting(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return (!isOwnPaperConsignment(c) &&
        !isOwnPersonalConsignment(c) &&
        !isOwnInternalPaperConsignment(c) &&
        !isOfficeDeskConsignment(c)) ?
        {validationMessage: 'Tato akce podporuje pouze listinná, osobní, interní listinná vypravení a vyvěšení na úřední desku.'} :
        null;
    };
  }

  static isNotSupportedForEnvelopePrinting(): OperationValidator<GenericOwnConsignment> {
    return (c: GenericOwnConsignment) => {
      return (!isOwnPaperConsignment(c) && !isOwnPersonalConsignment(c) && !isOwnInternalPaperConsignment(c)) ?
        {validationMessage: 'Tato akce podporuje pouze listinná, osobní a interní listinná vypravení.'} :
        null;
    };
  }

}
