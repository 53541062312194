<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="col grow dialog-size">
    <div class="row grow">
      <div class="grow">
        <icz-form-autocomplete
          formControlName="templateId"
          label="Pro založení použít šablonu"
          [options]="digitalComponentTemplates"
        ></icz-form-autocomplete>
      </div>
      <div class="grow"></div>
    </div>
    @if (componentTemplateControl.value) {
      <div class="col grow dialog-separator">
        <div class="row">
          <icz-form-field formControlName="label" class="grow-3" label="Název komponenty"></icz-form-field>
          <icz-form-autocomplete formControlName="relationType" class="grow" label="Vztah k dokumentu" [options]="relationTypeOptions" [clearable]="false"></icz-form-autocomplete>
          <icz-form-autocomplete formControlName="originType" class="grow" label="Původ" [options]="originTypeOptions" [clearable]="false"></icz-form-autocomplete>
        </div>
        <div class="row grow items-center">
          <icz-form-field class="grow-3" formControlName="description" label="Popis"></icz-form-field>
          <icz-checkbox formControlName="isFinal" class="grow-1" label="Komponenta je finální"></icz-checkbox>
        </div>
      </div>
    }
    @if (hasFileContent) {
      <icz-file-contents-viewer
        class="grow"
        [fileName]="templateVersionCompleteDto!.fileName!"
        [fileContents]="digitalComponentVersionFileContents"
        [mimeType]="templateVersionCompleteDto!.mimeType!"
        (downloadRequested)="download()"
      ></icz-file-contents-viewer>
    }
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this)"
    (onAction)="submit()"
    primary label="Založit komponentu"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Zrušit"
  ></icz-button>
</icz-form-buttons>
