import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';
import {
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';
import {CirculationBulkTaskData, SCirculationBulkTaskData} from './circulation-toast.service';

export enum FileCirculationToastType {
  FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR = 'FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR',
  FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR = 'FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR',
  FILE_BULK_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR = 'FILE_BULK_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR',
  FILE_TASK_CIRCULATION_BY_TARGET_ERROR = 'FILE_TASK_CIRCULATION_BY_TARGET_ERROR',
}

export const SFileCirculationHandoverAssignedInitiatorData = z.object({
  [InternalNotificationKey.FILE_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.FILE_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ACTIVITY_ID]: SImplicitlyStringifiable,
});
export type FileCirculationHandoverAssignedInitiatorData = z.infer<typeof SFileCirculationHandoverAssignedInitiatorData>;

export const SFileCirculationBulkTaskSuccessByTargetData = z.object({
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
});
export type FileCirculationBulkTaskSuccessByTargetData = z.infer<typeof SFileCirculationBulkTaskSuccessByTargetData>;

export const SFileCirculationTaskErrorData = z.object({
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
});
export type FileCirculationTaskErrorData = z.infer<typeof SFileCirculationTaskErrorData>;

@Injectable({
  providedIn: 'root'
})
export class FileCirculationToastService {

  private toastService = inject(ToastService);

  dispatchFileHandoverAssignedInitiator(templateData: FileCirculationHandoverAssignedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR),
          templateData,
          templateDataSchema: SFileCirculationHandoverAssignedInitiatorData,
        },
      },
    });
  }

  dispatchBulkFileHandoverAssignedInitiator(templateData: CirculationBulkTaskData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(FileCirculationToastType.FILE_BULK_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }

}
