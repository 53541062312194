import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

export type CardStatus = 'success' | 'error' | 'warning' | 'info';

@Component({
  selector: 'icz-status-card',
  templateUrl: './status-card.component.html',
  styleUrls: ['./status-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatusCardComponent {
  @Input({required: true}) status!: CardStatus;

  @Input({required: true}) label = '';

  @Input() icon!: string;

  @HostBinding('class.custom-content')
  @Input()
  customContent = false;

  @HostBinding('class.shadow')
  @Input()
  hasShadow = true;

  @HostBinding('class.success')
  get isSuccess() {
    return this.status === 'success';
  }

  @HostBinding('class.warning')
  get isWarning() {
    return this.status === 'warning';
  }

  @HostBinding('class.error')
  get isError() {
    return this.status === 'error';
  }

  @HostBinding('class.info')
  get isInfo() {
    return this.status === 'info';
  }

  get statusIcon() {
    return this.icon ? this.icon : this.status ? `config_${this.status}` : null;
  }
}
