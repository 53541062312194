import {Directive, EventEmitter, inject, Input, Output} from '@angular/core';
import {map, switchMap} from 'rxjs/operators';
import {Button} from './button-collection/button-collection.component';
import {IczOnChanges, IczSimpleChanges} from '../../utils/icz-on-changes';
import {ResponsivityService} from './responsivity.service';
import {UserSettingsService} from '../../services';

@Directive()
export abstract class AbstractToolbarButtonsComponent<TRow, TView = void, TOperationResult = void> implements IczOnChanges {

  protected responsivityService = inject(ResponsivityService);
  protected userSettingsService = inject(UserSettingsService);

  @Input()
  view!: TView;
  @Input({required: true})
  selectedRows: Nullable<Array<TRow>> = null;

  @Output()
  operationCompleted = new EventEmitter<TOperationResult>();

  isToolbarCollapsed$ = this.responsivityService.isSmallDesktop$.pipe(
    switchMap(isSmallDesktop => this.userSettingsService.showTableLabels$.pipe(
      map(showTableLabels => !showTableLabels || isSmallDesktop),
    )),
  );

  toolbarButtons!: Button[];

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.selectedRows) {
      this.generateToolbarContents(this.selectedRows);
    }
  }

  buttonClicked(button: Button) {
    button.action?.(button);
  }

  protected abstract generateToolbarContents(rows: Nullable<TRow[]>): void;

}
