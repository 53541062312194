<div class="row" [formGroup]="form">
  <icz-form-autocomplete
    [clearable]="false"
    [options]="subjectIdentifierTypeOptions"
    class="grow"
    formControlName="identityType"
    label="Typ identifikátoru"
  ></icz-form-autocomplete>
  <div (keydown)="onInputFieldKeydown($event)" class="grow">
    @if (isIdSelected(SubjectIdentifierType.DATA_BOX)) {
      <icz-form-field [formControlName]="SearchOnlyAttribute.DATA_BOX" label="ID datové schránky"></icz-form-field>
    }
    @if (isIdSelected(SubjectIdentifierType.IDENTITY_CARD_ID)) {
      <icz-form-field [formControlName]="SubjectAttributeType.IDENTITY_CARD_ID" label="Občanský průkaz"></icz-form-field>
    }
    @if (isIdSelected(SubjectIdentifierType.DRIVING_LICENCE_ID)) {
      <icz-form-field [formControlName]="SubjectAttributeType.DRIVING_LICENCE_ID" label="Řidičský průkaz"></icz-form-field>
    }
    @if (isIdSelected(SubjectIdentifierType.PASSPORT_ID)) {
      <icz-form-field [formControlName]="SubjectAttributeType.PASSPORT_ID" label="Cestovní pas"></icz-form-field>
    }
    @if (isIdSelected(SubjectIdentifierType.CLIENT_ID)) {
      <icz-form-field [formControlName]="SubjectAttributeType.CLIENT_ID" label="Klientské číslo"></icz-form-field>
    }
  </div>
</div>
