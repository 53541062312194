import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject} from '@angular/core';
import {IdtConnectionState, IdtLinkService} from '../../../core/services/idt/idt-link.service';
import {IdtService} from '../../../core/services/idt/idt.service';
import {switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';

@Component({
  selector: 'icz-idt-version-info',
  templateUrl: './idt-version-info.component.html',
  styleUrls: ['./idt-version-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class IdtVersionInfoComponent {

  private idtLinkService = inject(IdtLinkService);
  private idtService = inject(IdtService);
  private cd = inject(ChangeDetectorRef);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private applicationConfig = inject(ApplicationConfigService);

  idtVersion = '';
  idtStatus = IdtConnectionState.INITIATING;
  readonly IdtConnectionState = IdtConnectionState;
  readonly warningText = 'Máte verzi IDT {{currentVersion}}, ale vyžadována je minimálně verze {{requiredVersion}}.';
  versionWarningText = '';

  ngOnInit() {
    this.idtLinkService.idtConnectionStatus$.pipe(
      take(1),
      tap(status => this.idtStatus = status),
      switchMap(status => {
        if (status === IdtConnectionState.RUNNING || status === IdtConnectionState.INCOMPATIBLE_VERSION) {
          return this.idtService.getIdtVersion();
        }
        else {
          return of({version: this.translateService.instant('není k dispozici')});
        }
      }),
    ).subscribe(versionData => {
      this.idtVersion = versionData.version;
      this.versionWarningText = this.translateParser.interpolate(this.translateService.instant(this.warningText), {
        currentVersion: this.idtVersion,
        requiredVersion: this.applicationConfig.defaultIdtVersion,
      })!;

      this.cd.detectChanges();
    });
  }

}
