/* eslint-disable no-console */
import axios, {AxiosRequestConfig} from 'axios';
import * as NodeFormData from 'form-data';
import {BackendUrls, Environment, urlsFromHost} from '../core/services/environment.models';
import {ServiceConfiguration, servicesConfiguration} from './api.conf';

export enum EurekaAppInstanceStatus {
  UP = 'UP',
  DOWN = 'DOWN',
  STARTING = 'STARTING',
}

export interface EurekaAppInstance {
  app: string;
  vipAddress: string;
  homePageUrl: string;
  status: EurekaAppInstanceStatus;
}

export interface EurekaApp {
  name: string;
  instance: EurekaAppInstance[];
}

export interface OpenApiSpec {
  name: string;
  url: string;
}

export interface EurekaAppStatus extends EurekaApp, ServiceConfiguration {
  isRunning: boolean;
  isStarting: boolean;
}

export interface AccessTokenMetadata {
  expires_in: number;
  functional_position_id: number;
  user_id: number;
  refresh_expires_in: number;
  surname: string;
  functional_position: string;
  first_name: string;
  email: string;
  organization_id: number;
  organization_code: string;
}

export interface AccessToken {
  token: string;
  tokenMetadata: AccessTokenMetadata;
}

export interface ApiControllerRequestOptions {
  requestAsOrganizationCode?: string;
  requestAsUser: string;
  requestAsFunctionalPositionCode: string;
}

export function areRequestOptionsSame(o1: Nullable<ApiControllerRequestOptions>, o2: Nullable<ApiControllerRequestOptions>) {
  return (
    o1?.requestAsFunctionalPositionCode === o2?.requestAsFunctionalPositionCode &&
    o1?.requestAsUser === o2?.requestAsUser &&
    o1?.requestAsOrganizationCode === o2?.requestAsOrganizationCode
  );
}

// mutates environment object value
export function overrideUrls(ENV_NAME: Nullable<string>, environment: Environment) {
  if (ENV_NAME) {
    const overridenEnvironment = environment.availableEnvironments.find(e => e.name === ENV_NAME);

    if (overridenEnvironment) {
      let environmentHost = overridenEnvironment.host;

      // eslint-disable-next-line no-console
      console.log(`API URLs will be overwritten by: ENV_NAME=${ENV_NAME} with host=${environmentHost}\n`);

      if (!environmentHost.startsWith('http://') && !environmentHost.startsWith('https://')) {
        environmentHost = `http://${environmentHost}`;
      }

      environment.environmentName = overridenEnvironment.name;
      environment.environmentType = overridenEnvironment.type;

      const modifiedURLs = urlsFromHost(environmentHost);
      for (const url in modifiedURLs) {
        if (modifiedURLs.hasOwnProperty(url)) {
          environment[url as keyof BackendUrls] = modifiedURLs[url as keyof BackendUrls];
        }
      }
    }
    else {
      console.log(
        `Warning: no environment declared in environment.json5 matches ENV_NAME "${ENV_NAME}". API URLs will NOT be overwritten.`
      );
    }
  } else {
    console.warn(`API URLs will NOT be overwritten. ENV_NAME is not defined.\n`);
  }
}

export enum BackendStatus {
  UP = 'UP', // all services are UP => BE is UP
  DOWN = 'DOWN', // no services are UP => BE is DOWN
  NETWORK_UNAVAILABLE = 'NETWORK_UNAVAILABLE', // Eureka does not respond
}

export type ServicesConfiguration = Record<string, ServiceConfiguration>;

export function getBackendAppsStatus(runningApps: EurekaApp[]): EurekaAppStatus[] {
  const apps: Record<string, EurekaAppStatus> = {};

  Object.entries(servicesConfiguration).forEach(([name, serviceConfiguration]: [string, ServiceConfiguration]) => {
    apps[name] = {
      ...serviceConfiguration,
      name,
      instance: [],
      isRunning: false,
      isStarting: false,
    };
  });

  for (const runningApp of runningApps) {
    const name = runningApp.name.toLowerCase();
    const app = apps[name];

    // backend is running more microservices than FE declares in api.conf.ts
    if (!app) {
      continue;
    }

    const appInstances = runningApp.instance;

    const appIsUp = (i: EurekaAppInstance) => i.status === EurekaAppInstanceStatus.UP;
    const appIsStarting = (i: EurekaAppInstance) => i.status === EurekaAppInstanceStatus.STARTING;

    app.isRunning = appInstances.length > 0 && appInstances.some(appIsUp);
    app.isStarting = (
      appInstances.length > 0 &&
      (
        appInstances.every(appIsStarting) ||
        (appInstances.some(appIsStarting) && !appInstances.some(appIsUp))
      )
    );
  }

  return Object.values(apps);
}

export function checkApiIsReady(backendStatus: EurekaAppStatus[]): BackendStatus {
  return backendStatus.every(appStatus => appStatus.isRunning || appStatus.excludeFromApi) ? BackendStatus.UP : BackendStatus.DOWN;
}

export const ENVIRONMENT_ORGANIZATIONS: Record<string, string> = {
  DEV: 'icz',
  CI: 'icz-ci',
  E2E: 'icz-e2e',
  QA1: 'icz-qa1',
  QA2: 'icz-qa2',
  QA3: 'icz-qa3',
  INT: 'int',
  MIG: 'mig',
};

export class ApiController {
  // Map FROM user+functional position TO access token Promises
  private _tokens = new Map<string, Promise<AccessToken>>();

  private config: AxiosRequestConfig = {
    headers: {
      Authorization: this.environment.clientCredentials,
    },
  };

  constructor(private environment: Environment) {}

  login(organization = 'icz', username = 'user', password = 'g2sps@pWd') {
    const params = new URLSearchParams();
    params.append('grant_type', 'password');
    params.append('organization_code', organization);
    params.append('username', username);
    params.append('password', password);
    return axios.post(this.environment.getTokenUrl, params, this.config);
  }

  setFunctionalPosition(access_token: string, functionalPositionCode: string) {
    const params = new URLSearchParams();
    params.append('grant_type', 'functional_position');
    params.append('functional_position', functionalPositionCode);
    params.append('access_token', access_token);
    return axios.post(this.environment.getTokenUrl, params, this.config);
  }

  async getAccessToken(verbose = false, requestOptions?: Nullable<ApiControllerRequestOptions>): Promise<string> {
    return (await this.getAccessTokenObject(verbose, requestOptions)).token;
  }

  async getAccessTokenMetadata(verbose = false, requestOptions?: Nullable<ApiControllerRequestOptions>): Promise<AccessTokenMetadata> {
    return (await this.getAccessTokenObject(verbose, requestOptions)).tokenMetadata;
  }

  private async getAccessTokenObject(verbose = false, requestOptions?: Nullable<ApiControllerRequestOptions>): Promise<AccessToken> {
    if (!requestOptions) {
      requestOptions = {
        requestAsUser: 'user',
        requestAsFunctionalPositionCode: 'Administrator',
      };
    }

    if (!requestOptions!.requestAsOrganizationCode) {
      requestOptions!.requestAsOrganizationCode = ENVIRONMENT_ORGANIZATIONS[this.environment.environmentName];
    }

    const tokenKey =
      requestOptions!.requestAsOrganizationCode + requestOptions!.requestAsUser + requestOptions!.requestAsFunctionalPositionCode;

    if (!this._tokens.has(tokenKey)) {
      this._tokens.set(tokenKey, this._fetchAccessToken(verbose, requestOptions!));
    }

    return this._tokens.get(tokenKey)!;
  }

  private async _fetchAccessToken(verbose = false, requestOptions: ApiControllerRequestOptions): Promise<AccessToken> {
    const loginResp = await this.login(requestOptions.requestAsOrganizationCode, requestOptions.requestAsUser);
    const sfpResp = await this.setFunctionalPosition(loginResp.data.access_token, requestOptions.requestAsFunctionalPositionCode);

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const {first_name, surname, functional_position, functional_position_id, token_type, access_token, organization_id, organization_code} = sfpResp.data;
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Authorization = `${token_type} ${access_token}`;
    if (verbose) {
      console.log(`Using auth service at ${this.environment.getTokenUrl}`);
      console.log(`User ${first_name} ${surname} (${functional_position} ID=${functional_position_id}) under organization ${organization_code} ID=${organization_id} logged in with Authorization:`);
      console.log(`\n${Authorization}\n`);
    }

    return {
      token: Authorization,
      tokenMetadata: sfpResp.data,
    };
  }

  async getApps(exclusionsByConfiguration = false, verbose = false): Promise<EurekaApp[]> {
    const url = this.environment.eurekaApiUrl + '/apps';
    if (verbose) console.log(`Using Eureka at ${url}`);

    const response = await axios.get(url);
    if (!response.data.applications) throw new Error(`Eureka is not responding.`);
    const list: EurekaApp[] = response.data.applications.application;

    if (!exclusionsByConfiguration) return list;

    const includeInList = (serviceName: string) => {
      const conf = servicesConfiguration[serviceName];
      if (!conf) return true; // service without configuration (new one?)
      return !conf.excludeFromApi;
    };

    return list.filter(app => includeInList(app.name.toLowerCase()));
  }

  timeout(ms = 1000) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async waitForApi(timeout = 0, verbose = true) {
    const started = +new Date();
    do {
      try {
        if (verbose) console.log('Checking backend status.');

        const apps = await this.getApps(false, verbose);
        const status = getBackendAppsStatus(apps);
        if (checkApiIsReady(status) === 'UP') return;

        if (timeout) {
          const runtime = +new Date() - started;
          if (runtime > timeout * 1_000) throw new Error(`Timeout ${timeout} s runs out.`);
        }

        if (verbose) {
          const startingServices = status
            .filter(appStatus => !appStatus.isRunning && !appStatus.excludeFromApi)
            .map(appStatus => appStatus.name)
            .join(', ');
          console.log(`Waiting for: ${startingServices}.\n`);
        }
      } catch (e: any) {
        console.error(e.message);
      } finally {
        await this.timeout(5000);
      }
    } while (true);
  }

  async listOpenApi(direct = false, urlPostfix = '/api/rest/swagger-ui.html') {
    const list: OpenApiSpec[] = [];

    const apps = await this.getApps(true);
    apps.forEach(app => {
      app.instance.forEach(i =>
        list.push({
          name: app.name,
          url: (direct ? i.homePageUrl : this.environment.apiServicesUrl + '/' + i.vipAddress) + urlPostfix,
        })
      );
    });

    return list;
  }

  async load(uri: string, authorizationOverride = '') {
    try {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const Authorization = authorizationOverride || (await this.getAccessToken());
      const response = await axios.get(uri, {headers: {Authorization}});
      return response.data;
    } catch (e) {
      console.error(`Cannot load ${uri} because of ${e}`);
      return undefined;
    }
  }

  async get(uri: string, requestOptions?: Nullable<ApiControllerRequestOptions>) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Authorization = await this.getAccessToken(undefined, requestOptions);
    const response = await axios.get(uri, {headers: {Authorization}});
    return response.data;
  }

  // eslint-disable-next-line @typescript-eslint/ban-types
  async post(uri: string, data?: object | NodeFormData, requestOptions?: Nullable<ApiControllerRequestOptions>) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Authorization = await this.getAccessToken(undefined, requestOptions);

    const requestConfig: AxiosRequestConfig = {headers: {Authorization}};

    if (data && data instanceof NodeFormData) {
      requestConfig.headers = {
        ...data.getHeaders(),
        ...requestConfig.headers,
      };
    }

    const response = await axios.post(uri, data, requestConfig);

    return response.data;
  }
  // eslint-disable-next-line @typescript-eslint/ban-types
  async put(uri: string, data: object, requestOptions?: Nullable<ApiControllerRequestOptions>) {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const Authorization = await this.getAccessToken(undefined, requestOptions);
    const response = await axios.put(uri, data, {headers: {Authorization}});
    return response.data;
  }

  async loadApps(direct = false, urlPostfix = '/api/rest/actuator/info') {
    const list = await this.listOpenApi(direct, urlPostfix);

    const download = async (app: OpenApiSpec) => {
      const authorizationOverride = servicesConfiguration[app.name.toLowerCase()]?.Authorization;
      return {
        ...app,
        response: await this.load(app.url, authorizationOverride),
      };
    };

    const requests = list.map(download);
    const responses = await Promise.all(requests);

    return responses;
  }
}
