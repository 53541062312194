/* eslint-disable @typescript-eslint/ban-types -- when overriding we need to use the "undefined" type from superclass signature */
import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DefaultTitleStrategy, PRIMARY_OUTLET, RouterStateSnapshot} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {IczPageTitleService} from './icz-page-title.service';

const EXPECT_ALIAS = '__EXPECT_ALIAS__';

@Injectable({providedIn: 'root'})
export class IczPageTitleStrategy extends DefaultTitleStrategy {

  private pageTitleService = inject(IczPageTitleService);

  private translateService: Nullable<TranslateService>;
  private langChangeSubscription: Nullable<Subscription>;
  private currentTitle: Nullable<string>;

  get isTranslateServiceReadyForCurrentLang(): boolean {
    return Boolean(
      this.translateService &&
      Object.keys(this.translateService.store.translations[this.translateService.currentLang] ?? {}).length
    );
  }

  setTranslateService(translateService: TranslateService) {
    this.translateService = translateService;

    this.langChangeSubscription?.unsubscribe();

    this.langChangeSubscription = this.translateService.onLangChange.subscribe(_ => {
      this.setTranslatedPageTitle(this.currentTitle ?? '');
    });
  }

  override buildTitle(snapshot: RouterStateSnapshot): string | undefined {
    let pageTitle: Nullable<string>;
    let usesAlias = false;
    let route: Nullable<ActivatedRouteSnapshot> = snapshot.root;

    while (route !== undefined) {
      usesAlias = route?.data?.usesAlias ?? usesAlias;
      pageTitle = this.getResolvedTitleForRoute(route) ?? pageTitle;
      route = route.children.find(child => child.outlet === PRIMARY_OUTLET);
    }

    return usesAlias ? EXPECT_ALIAS : pageTitle!;
  }

  override updateTitle(snapshot: RouterStateSnapshot): void {
    const titleFromRouteMetadata = this.buildTitle(snapshot);

    this.currentTitle = titleFromRouteMetadata;
    this.setTranslatedPageTitle(this.currentTitle);
  }

  private setTranslatedPageTitle(titleFromRouteMetadata: string | undefined) {
    if (titleFromRouteMetadata !== EXPECT_ALIAS) {
      if (titleFromRouteMetadata && this.isTranslateServiceReadyForCurrentLang) {
        this.pageTitleService.setTitle(this.translateService!.instant(titleFromRouteMetadata));
      }
      else {
        this.pageTitleService.setTitle('');
      }
    }
  }

}
