import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {IczFormArray, IczFormGroup} from '../../form-elements/icz-form-controls';
import {Option} from '../../../model';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {OrganizationalStructureService} from '../../../core/services/organizational-structure.service';
import {FunctionalPositionDto} from '|api/core';
import {CurrentSessionService} from '../../../services';

type MemberFunctionalPositionOption = Option<number, FunctionalPositionDto>;

@Component({
  selector: 'icz-members-with-permissions-table',
  templateUrl: './members-with-permissions-table.component.html',
  styleUrls: ['./members-with-permissions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersWithPermissionsTableComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private currentSessionService = inject(CurrentSessionService);

  // a form with controls: permissions, functionalPlacesToAssign, functionalPositions
  @Input({required: true})
  form!: IczFormGroup;

  @Input({required: true})
  permissionOptions: Option[] = [];

  @Input()
  showPermissionTooltip = true;

  @Input()
  disableSelfPermissionAssignment = false;

  @Input({required: true})
  permissionTooltipTranslationPrefix!: string;

  allFunctionalPlacesOptions: MemberFunctionalPositionOption[] = [];
  functionalPlacesOptions: MemberFunctionalPositionOption[] = this.allFunctionalPlacesOptions;
  alreadySelectedPlaces: number[] = [];

  get functionalPositionsForm() {
    return this.form.get('functionalPositions') as IczFormArray;
  }

  get isAddPositionButtonDisabled() {
    return isNil(this.form.get('functionalPlacesToAssign')?.value) || isNil(this.form.get('permissions')?.value);
  }

  ngOnInit() {
    this.loadingService.doLoading(
      this.organizationalStructureService.functionalPositionsOptions(),
      this
    ).subscribe(options => {
      this.allFunctionalPlacesOptions = options.map(o => {
        if (this.disableSelfPermissionAssignment) {
          const isUnassignable = this.currentSessionService.currentUserFunctionalPosition!.id === o.value;

          return {
            ...o,
            disabled: isUnassignable,
            disableReason: isUnassignable ? 'Nemůžete přiřadit oprávnění sami sobě.' : undefined,
          };
        }
        else {
          return o;
        }
      });
      this.updatePlaceOptions();
    });
  }

  getFunctionalPositionNameValue(form: IczFormGroup) {
    return form.get('name')!.value;
  }

  addWorkerWithPermissions() {
    const selectedPositions = this.form.get('functionalPlacesToAssign')!.value;
    const selectedPermission = this.form.get('permissions')!.value;
    selectedPositions?.forEach((posId: number) => {
      this.functionalPositionsForm.incrementSize();
      const group = this.functionalPositionsForm.controls[this.functionalPositionsForm.length - 1];
      group.patchValue({
        name: this.functionalPlacesOptions.find(p => p.value === posId)!.label,
        id: posId,
        permission: selectedPermission,
        code: this.functionalPlacesOptions.find(p => p.value === posId)!.data!.code
      });
      this.alreadySelectedPlaces.push(posId);
    });
    this.updatePlaceOptions();
    this.form.get('functionalPlacesToAssign')!.setValue(null);
    this.form.get('permissions')!.setValue(null);
  }

  deleteWorker(index: number) {
    const deletedWorkerId = this.functionalPositionsForm.controls[index].get('id')?.value;
    const toBeDeletedIndex = this.alreadySelectedPlaces.findIndex(placeId => placeId === deletedWorkerId);
    if (toBeDeletedIndex > -1) this.alreadySelectedPlaces.splice(toBeDeletedIndex, 1);
    this.updatePlaceOptions();
    this.functionalPositionsForm.removeAt(index);
  }

  updatePlaceOptions() {
    this.functionalPlacesOptions = this.allFunctionalPlacesOptions.filter(pos => !this.alreadySelectedPlaces.includes(Number(pos.value!)));
  }

}
