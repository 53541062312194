import {Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, TemplateRef} from '@angular/core';
import {IAutocompleteListItemContext} from '../form-autocomplete.model';
import {Option} from '../../../../model';

@Component({
  selector: 'icz-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss']
})
export class OptionsListComponent<T> {

  private elementRef = inject(ElementRef);

  @Input({required: true})
  options: Option<T, any>[] = [];
  @Input()
  selectedOptions: Option<T, any>[] = [];
  @Input()
  isMultiselect: Nullable<boolean> = false;
  @Input()
  readonlyMode = false;
  @Input()
  listItemTemplate!: TemplateRef<IAutocompleteListItemContext>;
  @Input()
  maxListHeight = 280;
  @Input()
  hideDefaultTooltips = false;
  @Output()
  selectionChanged = new EventEmitter<Option<T, any>[]>();

  activeOption: Nullable<Option<T, any>> = null;

  @HostListener('document:click', ['$event'])
  clickout(event: MouseEvent) {
    if(!this.elementRef.nativeElement.contains(event.target)) {
      this.resetActiveOption();
    }
  }

  optionSelected(option: Option<T, any>) {
    if (this.readonlyMode || option.disabled || option.isSeparator) {
      return;
    }

    if (this.isMultiselect) {
      if (!this.isSelected(option)) {
        this.selectedOptions!.push(option);
      }
      else {
        const index = this.selectedOptions.findIndex(op => op.value === option.value);
        this.selectedOptions!.splice(index,1);
      }
    }
    else {
      this.selectedOptions = [option];
    }

    this.selectionChanged.emit(this.selectedOptions!);
  }

  selectPrevOption() {
    if (this.activeOption) {
      const optionIndex = this.options.indexOf(this.activeOption);

      if (optionIndex > 0) {
        this.activeOption = this.options[optionIndex - 1];
      }
    }
  }

  selectNextOption() {
    if (this.activeOption) {
      const optionIndex = this.options.indexOf(this.activeOption);

      if (optionIndex < this.options.length - 1) {
        this.activeOption = this.options[optionIndex + 1];
      }
    } else {
      this.selectFirstOption();
    }
  }

  selectFirstOption() {
    if (this.activeOption === null && this.options.length) {
      this.activeOption = this.options[0];
    }
  }

  selectCurrentOption() {
    if (this.activeOption) {
      this.optionSelected(this.activeOption);
    }
  }

  resetActiveOption() {
    this.activeOption = null;
  }

  mouseEnteredOption(option: Option<T>) {
    this.activeOption = option;
  }

  isSelected(option: Option<T, any>) {
    return !this.readonlyMode && this.selectedOptions?.some(op => op.value === option.value);
  }

  isActive(option: Option<T, any>) {
    return !this.readonlyMode && this.activeOption === option && !this.activeOption.isSeparator;
  }

}
