<div [waiting]="loadingService.isLoading(this)">
  @for (taskInfo of activityTasksInfoBeforeChild; track taskInfo.orderNumber) {
    <icz-task-info-bit
      [taskInfo]="taskInfo"
      [parentActivity]="activity"
    ></icz-task-info-bit>
  }
  @if (activity?.childActivities?.length) {
    <icz-subprocess-designator>
      @for (childActivity of activity.childActivities; track childActivity.id) {
        <icz-activity-steps-preview
          [activity]="childActivity"
        ></icz-activity-steps-preview>
      }
    </icz-subprocess-designator>
  }
  @for (taskInfo of activityTasksInfoAfterChild; track taskInfo.orderNumber) {
    <icz-task-info-bit
      [taskInfo]="taskInfo"
      [parentActivity]="activity"
    ></icz-task-info-bit>
  }
</div>
