import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {SubstitutionDto} from '|api/auth-server';
import {LocalizedDatePipe} from '../../essentials/date.pipe';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {PersonalSettingsRoute} from '../../../enums/personal-settings-routes.enum';
import {FilterOperator} from '../../../services/search-api.service';
import {map, take} from 'rxjs/operators';
import {Observable, of, switchMap} from 'rxjs';
import {CurrentSessionService} from '../../../services/current-session.service';
import {ConfigSearchService} from '../../../services/config-search.service';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';

export enum SubstitutionsCounterMode {
  USER_SUBSTITUTIONS = 'USER_SUBSTITUTIONS',
  FP_SUBSTITUTIONS = 'FP_SUBSTITUTIONS'
}

@Component({
  selector: 'icz-dashboard-substitutions-counter',
  templateUrl: './dashboard-substitutions-counter.component.html',
  styleUrls: ['./dashboard-substitutions-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardSubstitutionsCounterComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private currentSessionService = inject(CurrentSessionService);
  private configSearchService = inject(ConfigSearchService);
  private translateService = inject(TranslateService);
  private router = inject(Router);

  @Input({required: true})
  substitutionsCounterMode: SubstitutionsCounterMode = SubstitutionsCounterMode.USER_SUBSTITUTIONS;

  substitutions: SubstitutionDto[] = [];
  localizedDatePipe = new LocalizedDatePipe();

  readonly SUBSTITUTION_FOR_USER_ICON_COLOR = '#cde9ce';
  readonly SUBSTITUTION_FOR_FP_ICON_COLOR = '#ffe7c4';

  get isSubstitutionsForUser() {
    return this.substitutionsCounterMode === SubstitutionsCounterMode.USER_SUBSTITUTIONS;
  }

  ngOnInit() {
    let substitutions$: Observable<SubstitutionDto[]>;

    if (this.isSubstitutionsForUser) {
      substitutions$ = this.configSearchService.findSubstitutions({
        size: 1000,
        page: 0,
        sort: [],
        filter: [
          {
            fieldName: 'userId',
            value: String(this.currentSessionService.currentUser!.id),
            operator: FilterOperator.equals,
          }
        ],
        fulltextSearchTerm: ''
      }).pipe(
        map(page => page.content ?? []),
      );
    }
    else {
      substitutions$ = this.currentSessionService.currentUserFunctionalPosition$.pipe(
        switchMap(position => {
          if (position?.substituted) {
            return of([]);
          }
          else {
            return this.configSearchService.findSubstitutions({
              size: 1000,
              page: 0,
              sort: [],
              filter: [
                {
                  fieldName: 'functionalPositionId',
                  value: String(position?.id),
                  operator: FilterOperator.equals,
                }
              ],
              fulltextSearchTerm: ''
            }).pipe(map(page => page.content ?? []));
          }
        }),
        take(1),
      );
    }

    this.loadingService.doLoading(
      substitutions$,
      this
    ).subscribe(substitutions => this.substitutions = substitutions);
  }

  goToSubstitutionsSettings() {
    if (this.isSubstitutionsForUser) {
      this.router.navigateByUrl(createAbsoluteRoute(
        ApplicationRoute.ADMIN,
        PersonalSettingsRoute.PERSONAL_SETTINGS,
        PersonalSettingsRoute.PERSONAL_SUBSTITUTIONS,
      ));
    } else {
      this.router.navigateByUrl(createAbsoluteRoute(
        ApplicationRoute.ADMIN,
        PersonalSettingsRoute.PERSONAL_SETTINGS,
        PersonalSettingsRoute.FUNCTIONAL_POSITION_SUBSTITUTIONS,
      ));
    }
  }

  formatSubstitutionDate(substitution: any) {
    const dateTo = substitution.to ? this.localizedDatePipe.transform(substitution.to) : this.translateService.instant('neurčito');
    return '(' + this.translateService.instant(this.isSubstitutionsForUser ? 'zástup do' : 'zastupuje do') + ' ' + dateTo + ')';
  }
}
