import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {TimeSelectorValue} from './time-selector/time-selector.component';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {IczValidators} from '../validators/icz-validators/icz-validators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {isValidTime} from '../../../lib/utils';
import {parseTimeFromSimpleTimeString} from '../../../model';
import {AbstractPickerComponent} from '../abstract-picker.component';

@Component({
  selector: 'icz-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimePickerComponent extends AbstractPickerComponent implements OnInit {

  protected valueValidator = IczValidators.isSimpleTime();

  @Input()
  override set value(newValue: Nullable<string>) {
    if (this._formGroup) {
      this.valueInput.setValue(newValue, {emitEvent: false});
      this._realValue = newValue;
    }
  }
  override get value(): Nullable<string> {
    return this._realValue;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.valueInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const isValidAccordingToValidator = value && IczValidators.isSimpleTime()(new IczFormControl<Nullable<string>>(value)) === null;

      if (isValidAccordingToValidator) {
        const isLogicallyValid = value ? isValidTime(parseTimeFromSimpleTimeString(value)) : null;

        if (isLogicallyValid) {
          this._valueChanged(value);
        }
      } else {
        this._realValue = null;
        this.valueChange.emit(this._realValue);
      }
    });
  }

  _formGroup = new IczFormGroup({
    valueInput: new IczFormControl<Nullable<string>>(null, [this.valueValidator]),
  });

  onTimePick(timeSelectorValue: TimeSelectorValue): void {
    this.valueInput.reset(undefined);

    const newValue = `${String(timeSelectorValue.hours).padStart(2, '0')}:${String(timeSelectorValue.minutes).padStart(2, '0')}`;

    this._valueChanged(newValue);
    this.closePopover();
  }

  _valueChanged(newValue: Nullable<string>): void {
    if (!newValue) {
      return;
    }

    if (this.parseLocalTimeFormat(newValue)) {
      this.value = newValue;
      this.valueChange.emit(newValue);
      this.blur.emit();
    }
  }

  private parseLocalTimeFormat(timeSource: string): Nullable<string> {
    const time = parseTimeFromSimpleTimeString(timeSource);

    if (isValidTime(time)) {
      this.unsetControlErrors();
      return timeSource;
    }
    else {
      this.setControlErrors({
        invalidDateInput: {
          errorMessageTemplate: 'Zadaný čas je neplatný'
        },
      });
      return null;
    }
  }

}
