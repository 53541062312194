import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {ButtonSize} from '../button/button.component';

@Component({
  selector: 'icz-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CopyToClipboardButtonComponent {

  private clipboard = inject(Clipboard);
  private cd = inject(ChangeDetectorRef);

  @Input({ required: true })
  text!: string;
  @Input()
  size: ButtonSize = 'default';

  copied = false;

  copyUrlToClipboard($event: Event) {
    $event.stopPropagation();
    this.clipboard.copy(this.text);
    this.copied = true;

    setTimeout(() => {
      this.copied = false;
      this.cd.detectChanges();
    }, 5000);
  }

}
