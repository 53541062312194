<div class="col h-full">
  @if (componentSizeMayBeLimited) {
    @if (componentSizeLimitExceeded) {
      <icz-alert class="m-16" severity="error" [heading]="sizeLimitExceededAlert" [textContext]="{componentsSizeLimit: componentsSizeLimit}"></icz-alert>
    }
    @if (mainComponentConstraintUnmet) {
      <icz-alert class="m-16" severity="error" [heading]="mainComponentConstraintAlert"></icz-alert>
    }
  }
  @if (!canUserWriteContent) {
    <icz-alert
      heading="Nemáte dostatečné oprávnění pro úpravu obsahu zásilky"
      class="m-16" severity="info"
      >
    </icz-alert>
  }
  <icz-digital-components-selector
    class="grow"
    [tableTitle]="tableTitle"
    id="consignment-component-selector"
    [documentId]="documentId"
    [customDataSource]="consignmentComponentDatasource"
    [disabled]="disabled || !canUserWriteContent"
    [canUserDisplayComponentsContent]="canUserDisplayComponentsContent"
    [selection]="preselectedComponents"
    [additionalFilters]="additionalComponentSelectorFilters"
    [view]="consignmentId ? EsslComponentsTableColumnSet.OWN_CONSIGNMENT_COMPONENT_SELECTOR: EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_CONSIGNMENTS"
    [consignmentId]="consignmentId"
    (esslComponentsSelected)="handleComponentsSelected($event)"
    >
    @if (distributionClassForDispatch === DistributionClass.ADDRESS_PLACE) {
      <div
        afterTableTitle
        class="row ml-16" [formGroup]="specificFieldsForm"
        >
        <!-- todo(rb) consult - semantics of this field are a bit unclear -->
        <icz-form-field
          [fieldDisabled]="true"
          [switchPrefixAndPlaceholder]="true"
          [showValidationStatus]="false"
          placeholder="Počet listinných komponent"
          >
          <span prefix>{{ 'Počet listinných komponent' | translate }}:&nbsp;</span>
        </icz-form-field>
        <icz-form-field
          formControlName="nonPaperComponentsType"
          [switchPrefixAndPlaceholder]="true"
          [showValidationStatus]="false"
          placeholder="Druh nelistinných komponent"
          >
          <span prefix>{{ 'Druh nelistinných komponent' | translate }}:&nbsp;</span>
        </icz-form-field>
      </div>
    }
    @if (componentSizeMayBeLimited) {
      <div
        afterTableTitle
        class="row ml-16"
        >
        <div class="grow">
          @if (componentsSizeLimit) {
            <div class="icz-body-1 max-component-size-info">
              <span>
                {{ 'Maximální velikost odesílaných příloh může být' | translate }}
                {{ componentsSizeLimit }}.
              </span>
            </div>
          }
          @if (componentsSizeLimitLoaded && !componentsSizeLimit) {
            <div class="row icz-body-1">
              <icz-icon svgIcon="info"></icz-icon>
              <span>{{ 'Kontrolu, zda velikost příloh nepřekračuje limit, je možné provést až po výběru distribučního uzlu, na který budete vypravovat.' | translate }}</span>
            </div>
          }
        </div>
      </div>
    }
  </icz-digital-components-selector>
</div>
