import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  GenericOwnConsignment,
  isOwnDigitalConsignment,
  isOwnFaxConsignment,
  isOwnInternalConsignment,
  isOwnPaperConsignment,
  isOwnPortalConsignment
} from '../../model/own-consignment-model';
import {isOwnOfficeDeskConsignment} from '../../own-consignment-workflow.service';

@Component({
  selector: 'icz-consignment-table-consignee',
  templateUrl: './consignment-table-consignee.component.html',
  styleUrls: ['./consignment-table-consignee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentTableConsigneeComponent {

  @Input({required: true})
  consignment!: GenericOwnConsignment;

  readonly isOwnPaperConsignment = isOwnPaperConsignment;
  readonly isOwnDigitalConsignment = isOwnDigitalConsignment;
  readonly isOwnInternalConsignment = isOwnInternalConsignment;
  readonly isOwnOfficeDeskConsignment = isOwnOfficeDeskConsignment;
  readonly isOwnFaxConsignment = isOwnFaxConsignment;
  readonly isOwnPortalConsignment = isOwnPortalConsignment;

}
