import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {TRUNCATED_TEXT_TOOLTIP_DELAY} from '../../../essentials/tooltip.directive';
import {FilterOperator, getOperatorTranslationKey, isNoValueOperator} from '../../../../services/search-api.service';
import {Option} from '../../../../model';

@Component({
  selector: 'icz-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterTagComponent {
  @Input({required: true})
  filterName: string = '';
  @Input()
  filterValue: Nullable<string> = '';
  @Input()
  operator: Nullable<FilterOperator>;
  @Input()
  customTemplate: Nullable<TemplateRef<any>>;
  @Input()
  viewValue: Nullable<string | number | string[]>;
  @Input()
  selectedOptions: Nullable<Option[]>;
  @HostBinding('class.is-disabled')
  @Input()
  isDisabled = false;

  @Output()
  clicked = new EventEmitter<void>();

  TRUNCATED_TEXT_TOOLTIP_DELAY = TRUNCATED_TEXT_TOOLTIP_DELAY;

  @HostBinding('class.is-active')
  get isActive() {
    return this.operator && (this.filterValue || isNoValueOperator(this.operator));
  }

  @HostBinding('tabindex')
  private tabIndex = 0;

  @HostListener('keydown', ['$event'])
  private handleFocus($event: KeyboardEvent): void {
    if ($event.key === 'Enter' || $event.key === ' ') {
      this.clicked.emit();
    }
  }

  getTranslationKey(operator: Nullable<FilterOperator>) {
    return getOperatorTranslationKey(operator);
  }

}
