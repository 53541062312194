<div class="col grow user-popup">

  @if (functionalPositionSubstituted$ | async) {
    <div>
      @if (substitutedFPHolder$ | async; as user) {
        <div class="row grow gap-16 p-24">
          <icz-user-initials [initials]="getUserInitials(user)"></icz-user-initials>
          <div class="col grow gap-8">
            <div class="row grow font-bold">{{ getUserFullName(user) }}</div>
            <div class="row grow user-info">{{ functionalPositionName$ | async }}</div>
            <div class="row grow user-info">{{ organizationName$ | async }}</div>
          </div>
        </div>
      }
      @else {
        <div class="row grow gap-16 p-24">
          <icz-user-initials [initials]="''"></icz-user-initials>
          <div class="col grow gap-8">
            <div class="row grow font-bold">{{ 'Bez vykonávajícího' }}</div>
            <div class="row grow user-info">{{ functionalPositionName$ | async }}</div>
            <div class="row grow user-info">{{ organizationName$ | async }}</div>
          </div>
        </div>
      }
    </div>
  }
  @else {
    <div class="row grow gap-16 p-24">
      <icz-user-initials [initials]="userInitials$ | async"></icz-user-initials>
      <div class="col grow gap-8">
        <div class="row grow font-bold">{{ userFullName$ | async }}</div>
        <div class="row grow user-info">{{ functionalPositionName$ | async }}</div>
        <div class="row grow user-info">{{ organizationName$ | async }}</div>
      </div>
    </div>
  }


  <hr>
    @if (showSelectPlace) {
      <icz-button background class="row-button grow" noCentered svgIcon="substitute" (onAction)="selectPlace()"
      label="Zastupující místo"></icz-button>
      <hr>
      }
      <icz-button background class="row-button grow" noCentered svgIcon="info" (onAction)="aboutAppClick()"
      label="O aplikaci"></icz-button>
      <hr>
        <icz-button background class="row-button grow" noCentered svgIcon="log_out" (onAction)="logout()"
        label="Odhlásit se"></icz-button>
        <hr>

          @if (hasSponsors) {
            <div class="col grow gap-8 sponsor-row">
              <icz-label label="Za podpory" class="support-label"></icz-label>
            </div>
          }
        </div>
