<div class="row justify-end">
  @if (isDispatchAvailable(SubjectsTableAction.DISPATCH_USING_ISDS, DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES)) {
    <icz-button
      primary
      svgIcon="datova_zprava"
      iczTooltip="Vypravit datovou zprávou"
      (onAction)="actionButtonClicked(SubjectsTableAction.DISPATCH_USING_ISDS, subject)"
    ></icz-button>
  }
  @if (isDispatchAvailable(SubjectsTableAction.DISPATCH_USING_PAPER, DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES)) {
    <icz-button
      primary
      svgIcon="zasilka"
      iczTooltip="Vypravit listinnou zásilkou"
      (onAction)="actionButtonClicked(SubjectsTableAction.DISPATCH_USING_PAPER, subject)"
    ></icz-button>
  }
  @if (isDispatchAvailable(SubjectsTableAction.DISPATCH_USING_IN_PERSON, DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES)) {
    <icz-button
      primary
      svgIcon="take_over"
      iczTooltip="Vypravit osobně"
      (onAction)="actionButtonClicked(SubjectsTableAction.DISPATCH_USING_IN_PERSON, subject)"
    ></icz-button>
  }
  @if (isDispatchAvailable(SubjectsTableAction.DISPATCH_USING_EMAIL, DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES)) {
    <icz-button
      primary
      svgIcon="email"
      iczTooltip="Vypravit e-mailem"
      (onAction)="actionButtonClicked(SubjectsTableAction.DISPATCH_USING_EMAIL, subject)"
    ></icz-button>
  }
  <icz-button
    primary
    svgIcon="uredni_deska"
    iczTooltip="Vyvěsit na úřední desku"
    (onAction)="actionButtonClicked(SubjectsTableAction.POST_ON_OFFICE_DESK, subject)"
  ></icz-button>
  <icz-button
    [hidden]="!moreDeliveryActionOptions.length"
    cdkOverlayOrigin
    #moreDeliveryTypesOrigin="cdkOverlayOrigin"
    [svgIcon]="areMoreOptionsOpen ? 'collapse' : 'expand_more'"
    iczTooltip="Další možnosti"
    (onAction)="areMoreOptionsOpen = true"
  ></icz-button>

  <icz-popover
    [isOpen]="areMoreOptionsOpen"
    [overlayOrigin]="moreDeliveryTypesOrigin"
    (onClose)="areMoreOptionsOpen = false"
    >
    <icz-options-list
      [options]="moreDeliveryActionOptions"
      (selectionChanged)="extendedDeliveryActionSelected($event)"
    ></icz-options-list>
  </icz-popover>
</div>
