export enum MenuItemCountViewIds {
  DOCUMENTS_TASKS = 'DOCUMENTS_TASKS',
  DOCUMENTS_IN_PROGRESS = 'DOCUMENTS_IN_PROGRESS',
  DOCUMENTS_SETTLED = 'DOCUMENTS_SETTLED',
  DOCUMENTS_EXPIRING = 'DOCUMENTS_EXPIRING',
  DOCUMENTS_SIGNATURE_BOOK = 'DOCUMENTS_SIGNATURE_BOOK',
  DOCUMENTS_EXTERNAL_APPLICATION_DOCUMENTS = 'DOCUMENTS_EXTERNAL_APPLICATION_DOCUMENTS',
  DOCUMENTS_REGISTRY_OFFICE_TRANSFERS = 'DOCUMENTS_REGISTRY_OFFICE_TRANSFERS',
  DOCUMENTS_MANUALLY_SHARED = 'DOCUMENTS_MANUALLY_SHARED',
  CONSIGNMENTS_DISPATCH_OFFICER_TO_TAKE_OVER = 'CONSIGNMENTS_DISPATCH_OFFICER_TO_TAKE_OVER',
  CONSIGNMENTS_DISPATCH_OFFICER_TO_DISPATCH = 'CONSIGNMENTS_DISPATCH_OFFICER_TO_DISPATCH',
  CONSIGNMENTS_DISPATCH_OFFICER_IN_DISTRIBUTION = 'CONSIGNMENTS_DISPATCH_OFFICER_IN_DISTRIBUTION',
  CONSIGNMENTS_DISPATCH_OFFICER_DISPATCHED = 'CONSIGNMENTS_DISPATCH_OFFICER_DISPATCHED',
  CONSIGNMENTS_DISPATCH_OFFICER_DISPATCH_OFFICE_RECORD_DELIVERY_RESULT = 'CONSIGNMENTS_DISPATCH_OFFICER_DISPATCH_OFFICE_RECORD_DELIVERY_RESULT',
  CONSIGNMENTS_DISPATCH_OFFICER_BULK_POSTING_FORMS = 'CONSIGNMENTS_DISPATCH_OFFICER_BULK_POSTING_FORMS',
  CONSIGNMENTS_OFFICER_VIEW_IN_PROGRESS = 'CONSIGNMENTS_OFFICER_VIEW_IN_PROGRESS',
  CONSIGNMENTS_OFFICER_VIEW_ALL = 'CONSIGNMENTS_OFFICER_VIEW_ALL',
  CONSIGNMENTS_OFFICER_VIEW_REJECTED = 'CONSIGNMENTS_OFFICER_VIEW_REJECTED',
  CONSIGNMENTS_OFFICER_VIEW_RECORD_DELIVERY_RESULT = 'CONSIGNMENTS_OFFICER_VIEW_RECORD_DELIVERY_RESULT',
  OFFICE_DESK_OFFICER_VIEW_IN_PROGRESS = 'OFFICE_DESK_OFFICER_VIEW_IN_PROGRESS',
  OFFICE_DESK_OFFICER_VIEW_ALL_POSTED = 'OFFICE_DESK_OFFICER_VIEW_ALL_POSTED',
  OFFICE_DESK_OFFICER_VIEW_REJECTED = 'OFFICE_DESK_OFFICER_VIEW_REJECTED',
  OFFICE_DESK_OFFICER_VIEW_ALL = 'OFFICE_DESK_OFFICER_VIEW_ALL',
  OFFICE_DESK_TO_TAKE_OVER = 'OFFICE_DESK_TO_TAKE_OVER',
  OFFICE_DESK_TO_POST = 'OFFICE_DESK_TO_POST',
  OFFICE_DESK_POSTED = 'OFFICE_DESK_POSTED',
  OFFICE_DESK_UNPOSTED = 'OFFICE_DESK_UNPOSTED',
  FILING_OFFICE_RECEIVED_ALL_CONSIGNMENTS = 'FILING_OFFICE_RECEIVED_ALL_CONSIGNMENTS',
  FILING_OFFICE_RECEIVED_EMAIL_MESSAGES = 'FILING_OFFICE_RECEIVED_EMAIL_MESSAGES',
  FILING_OFFICE_RECEIVED_DATABOX_MESSAGES = 'FILING_OFFICE_RECEIVED_DATABOX_MESSAGES',
  FILING_OFFICE_RECEIVED_INTERNAL_CONSIGNMENTS = 'FILING_OFFICE_RECEIVED_INTERNAL_CONSIGNMENTS',
  FILING_OFFICE_HANDED_OVER = 'FILING_OFFICE_HANDED_OVER',
  FILING_OFFICE_REJECTED = 'FILING_OFFICE_REJECTED',
  UNIT_DISPATCH_OFFICER_VIEW_IN_PROGRESS = 'UNIT_DISPATCH_OFFICER_VIEW_IN_PROGRESS',
  UNIT_DISPATCH_OFFICER_VIEW_ALL = 'UNIT_DISPATCH_OFFICER_VIEW_ALL',
  UNIT_DISPATCH_OFFICER_VIEW_REJECTED = 'UNIT_DISPATCH_OFFICER_VIEW_REJECTED',
  UNIT_DISPATCH_OFFICER_VIEW_RECORD_DELIVERY_RESULT = 'UNIT_DISPATCH_OFFICER_VIEW_RECORD_DELIVERY_RESULT',
  UNIT_IN_PROGRESS = 'UNIT_IN_PROGRESS',
  UNIT_EXPIRING = 'UNIT_EXPIRING',
  UNIT_SETTLED = 'UNIT_SETTLED',
  UNIT_REGISTRY_OFFICE_TRANSFERS = 'UNIT_REGISTRY_OFFICE_TRANSFERS',
  UNIT_TASKS = 'UNIT_TASKS',
  UNIT_SIGNATURE_BOOK = 'UNIT_SIGNATURE_BOOK',
  REGISTRY_OFFICE_TO_TAKE_OVER = 'REGISTRY_OFFICE_TO_TAKE_OVER',
  REGISTRY_OFFICE_TO_RESOLVE_ISSUES = 'REGISTRY_OFFICE_TO_RESOLVE_ISSUES',
  REGISTRY_OFFICE_PROBLEMS = 'REGISTRY_OFFICE_PROBLEMS',
}
