import {isDocumentEntity, isFileEntity} from '../../shared-document.utils';
import {ExtendedCirculationTaskDto} from '../../model/elastic-extended-entities.interface';
import {EntityType} from '|api/commons';
import {TranslateService} from '@ngx-translate/core';
import {CirculationActivityPreconditionsCheckResultDto, RevokePreconditionsDto} from '|api/flow';
import {OperationValidator, ValidatorGuard} from '../../../../services/bulk-operation-validation.service';

export class TaskOperationValidators {
  @ValidatorGuard()
  static selectedItemIsNotDocumentCirculation(): OperationValidator<ExtendedCirculationTaskDto> {
    return (task: ExtendedCirculationTaskDto) => {
      return !isDocumentEntity(task.circulationEntityType)  ? {validationMessage: 'Akci lze provést pouze s dokumenty' } : null;
    };
  }

  @ValidatorGuard()
  static selectedItemIsNotFileCirculation(): OperationValidator<ExtendedCirculationTaskDto> {
    return (task: ExtendedCirculationTaskDto) => {
      return !isFileEntity(task.circulationEntityType)  ? {validationMessage: 'Akci lze provést pouze se spisy' } : null;
    };
  }

  static selectedSignItemIsNotDigitalComponent(): OperationValidator<ExtendedCirculationTaskDto> {
    return (task: ExtendedCirculationTaskDto) => {
      return task.circulationEntityType === EntityType.ANALOG_COMPONENT ? {validationMessage: 'Komponenta je analogová a nelze ji podepsat spolu s vybranou digitální komponentou' } : null;
    };
  }

  static selectedSignItemIsNotAnalogComponent(): OperationValidator<ExtendedCirculationTaskDto> {
    return (task: ExtendedCirculationTaskDto) => {
      return task.circulationEntityType === EntityType.DIGITAL_COMPONENT ? {validationMessage: 'Komponenta je digitální a nelze ji podepsat spolu s vybranou analogovou komponentou' } : null;
    };
  }

  static selectedFileHandoverPreconditionCheckFailed(preconditionCheckResult: CirculationActivityPreconditionsCheckResultDto[], translateService: TranslateService): OperationValidator<ExtendedCirculationTaskDto> {
    return (task: ExtendedCirculationTaskDto) => {
      const failedPreconditionCheck = preconditionCheckResult.find(result => result.entityId === task.fileId && !result?.passed);
      if (failedPreconditionCheck) {
        return {validationMessage: translateService.instant(failedPreconditionCheck?.failures[0].failedPrecondition!) };
      } else {
        return null;
      }
    };
  }

  static isUnrevokableActivityByTask(preconditionResults: RevokePreconditionsDto[]) {
    return (task: ExtendedCirculationTaskDto) => {
      const failedPrecondition = preconditionResults.find(r => r.activityId === task.activityId && !r.revokeAllowed);

      if (failedPrecondition) {
        return {validationMessage: `fe.ui.revokeDisallowReason.${failedPrecondition.revokeDisallowReason}`};
      }
      else {
        return null;
      }
    };
  }
}
