/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageTransactionEventAttributeDto } from '../models/page-transaction-event-attribute-dto';
import { TransactionEventAttributeDto } from '|api/commons';
import { transactionEventAttributeFind } from '../fn/transaction-event-attribute/transaction-event-attribute-find';
import { TransactionEventAttributeFind$Params } from '../fn/transaction-event-attribute/transaction-event-attribute-find';
import { transactionEventAttributeFindAllByTransactionEventId } from '../fn/transaction-event-attribute/transaction-event-attribute-find-all-by-transaction-event-id';
import { TransactionEventAttributeFindAllByTransactionEventId$Params } from '../fn/transaction-event-attribute/transaction-event-attribute-find-all-by-transaction-event-id';
import { transactionEventAttributeFindAllChangedByTransactionEventId } from '../fn/transaction-event-attribute/transaction-event-attribute-find-all-changed-by-transaction-event-id';
import { TransactionEventAttributeFindAllChangedByTransactionEventId$Params } from '../fn/transaction-event-attribute/transaction-event-attribute-find-all-changed-by-transaction-event-id';
import { transactionEventAttributeFindById } from '../fn/transaction-event-attribute/transaction-event-attribute-find-by-id';
import { TransactionEventAttributeFindById$Params } from '../fn/transaction-event-attribute/transaction-event-attribute-find-by-id';


/**
 * Controller for Transaction Event Attribute
 */
@Injectable({ providedIn: 'root' })
export class ApiTransactionEventAttributeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transactionEventAttributeFindById()` */
  static readonly TransactionEventAttributeFindByIdPath = '/transaction-log/transaction-event-attribute/{id}';

  /**
   * Find transaction event attribute by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionEventAttributeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFindById$Response(params: TransactionEventAttributeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionEventAttributeDto>> {
    return transactionEventAttributeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find transaction event attribute by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionEventAttributeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFindById(params: TransactionEventAttributeFindById$Params, context?: HttpContext): Observable<TransactionEventAttributeDto> {
    return this.transactionEventAttributeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionEventAttributeDto>): TransactionEventAttributeDto => r.body)
    );
  }

  /** Path part for operation `transactionEventAttributeFindAllByTransactionEventId()` */
  static readonly TransactionEventAttributeFindAllByTransactionEventIdPath = '/transaction-log/transaction-event-attribute/transaction-event/{id}';

  /**
   * Find all Transaction Event Attributes with selected Transaction event.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionEventAttributeFindAllByTransactionEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFindAllByTransactionEventId$Response(params: TransactionEventAttributeFindAllByTransactionEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransactionEventAttributeDto>>> {
    return transactionEventAttributeFindAllByTransactionEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Transaction Event Attributes with selected Transaction event.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionEventAttributeFindAllByTransactionEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFindAllByTransactionEventId(params: TransactionEventAttributeFindAllByTransactionEventId$Params, context?: HttpContext): Observable<Array<TransactionEventAttributeDto>> {
    return this.transactionEventAttributeFindAllByTransactionEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransactionEventAttributeDto>>): Array<TransactionEventAttributeDto> => r.body)
    );
  }

  /** Path part for operation `transactionEventAttributeFindAllChangedByTransactionEventId()` */
  static readonly TransactionEventAttributeFindAllChangedByTransactionEventIdPath = '/transaction-log/transaction-event-attribute/transaction-event/{id}/changed';

  /**
   * Find Transaction Event Attributes with selected Transaction event, attribute name is label or attribute is changed.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionEventAttributeFindAllChangedByTransactionEventId()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFindAllChangedByTransactionEventId$Response(params: TransactionEventAttributeFindAllChangedByTransactionEventId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TransactionEventAttributeDto>>> {
    return transactionEventAttributeFindAllChangedByTransactionEventId(this.http, this.rootUrl, params, context);
  }

  /**
   * Find Transaction Event Attributes with selected Transaction event, attribute name is label or attribute is changed.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionEventAttributeFindAllChangedByTransactionEventId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFindAllChangedByTransactionEventId(params: TransactionEventAttributeFindAllChangedByTransactionEventId$Params, context?: HttpContext): Observable<Array<TransactionEventAttributeDto>> {
    return this.transactionEventAttributeFindAllChangedByTransactionEventId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TransactionEventAttributeDto>>): Array<TransactionEventAttributeDto> => r.body)
    );
  }

  /** Path part for operation `transactionEventAttributeFind()` */
  static readonly TransactionEventAttributeFindPath = '/transaction-log/transaction-event-attribute/search';

  /**
   * Find all Transaction Event Attribute instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionEventAttributeFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFind$Response(params?: TransactionEventAttributeFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageTransactionEventAttributeDto>> {
    return transactionEventAttributeFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Transaction Event Attribute instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionEventAttributeFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventAttributeFind(params?: TransactionEventAttributeFind$Params|null|undefined, context?: HttpContext): Observable<PageTransactionEventAttributeDto> {
    return this.transactionEventAttributeFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageTransactionEventAttributeDto>): PageTransactionEventAttributeDto => r.body)
    );
  }

}
