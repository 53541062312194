import {DestroyRef, inject, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MainMenuService} from './main-menu.service';
import {LoadingIndicatorService} from '../../components/essentials/loading-indicator.service';
import {BehaviorSubject, interval, Observable, of, throttle} from 'rxjs';
import {ApiElasticsearchService} from '|api/elastic';
import {
  ApiBulkPostingFormService,
  ApiOwnConsignmentService,
  OwnConsignmentPerStateCountForDispatchOfficerDto,
  OwnConsignmentPerStateCountForOwnerDto,
  OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto,
  OwnOfficeDeskConsignmentPerStateCountForOwnerDto
} from '|api/sad';
import {MenuItem} from '../../components/general-screen-area/side-menu/side-menu.component';
import {isDispatchOfficer, isRegistryOfficer, isSecretariat} from '../../services/user-roles.model';
import {CurrentSessionService} from '../../services/current-session.service';
import {flatten} from 'lodash';
import {
  ELASTIC_RELOAD_DELAY
} from '../../components/shared-business-components/document-toolbar/services/toolbar-common.utils';
import {delay, finalize, switchMap} from 'rxjs/operators';
import {MenuItemCountViewIds} from '../routing/menu-item-count-view-ids';
import {AuthService} from '../authentication/auth.service';
import {ApiRegistryOfficeTransferService} from '|api/document';

export type BackendCounts = Record<string, Nullable<number>>;

const DELAY_REPEATED_COUNTER_REQUEST = 1000;

interface ElasticRecordCounts {
  'flow-activities': number,
  'settled-by-org-unit': number,
  'flow-activities-by-org-unit': number,
  'transfers-handed-over': number,
  'settled': number,
  'handed-over-by-filing-office': number,
  'storage-unit': number,
  'transfers-to-resolve-issues-registry-office': number,
  'on-table': number,
  'issd': number,
  'flow-tasks': number,
  'transfers-handed-over-registry-office': number,
  'transfers-saved-to-desa-by-org-unit': number,
  'transfers-saved-to-desa': number,
  'transfers-to-resolve-issues-by-org-unit': number,
  'transfers-to-resolve-issues': number,
  'on-table-by-org-unit': number,
  'transfers-handed-over-by-org-unit': number,
  'shared-manually': number,
  'flow-tasks-by-org-unit': number,
  'returned-to-filing-office': number,
  'received-by-filing-office': number,
  'signature-book': number,
  'registry-office-problems': number,
  'signature-book-by-org-unit': number,
}

enum CounterEndpoint {
  ELASTIC = 'ELASTIC',
  OWN_CONSIGNMENTS_DISPATCH_OFFICER = 'OWN_CONSIGNMENTS_DISPATCH_OFFICER',
  OWN_CONSIGNMENTS_OWNER = 'OWN_CONSIGNMENTS_OWNER',
  OWN_CONSIGNMENTS_SECRETARIAT = 'OWN_CONSIGNMENTS_SECRETARIAT',
  OFFICE_DESK_DISPATCH_OFFICER = 'OFFICE_DESK_DISPATCH_OFFICER',
  OFFICE_DESK_OWNER = 'OFFICE_DESK_OWNER',
  BULK_POSTING_FORMS = 'BULK_POSTING_FORMS',
  REGISTRY_OFFICE_PROBLEMS = 'REGISTRY_OFFICE_PROBLEMS',
}

type CountFieldNameToViewId = Record<string, MenuItemCountViewIds>;
type ElasticCountFieldNameToViewId = Record<keyof ElasticRecordCounts, Nullable<MenuItemCountViewIds>>;

const elasticCountsViewIdMap: ElasticCountFieldNameToViewId = {
  'flow-tasks': MenuItemCountViewIds.DOCUMENTS_TASKS,
  'on-table': MenuItemCountViewIds.DOCUMENTS_IN_PROGRESS,
  'received-by-filing-office': MenuItemCountViewIds.FILING_OFFICE_RECEIVED_ALL_CONSIGNMENTS,
  'returned-to-filing-office': MenuItemCountViewIds.FILING_OFFICE_REJECTED,
  'shared-manually': MenuItemCountViewIds.DOCUMENTS_MANUALLY_SHARED,
  'on-table-by-org-unit': MenuItemCountViewIds.UNIT_IN_PROGRESS,
  'settled-by-org-unit': MenuItemCountViewIds.UNIT_SETTLED,
  'settled': MenuItemCountViewIds.DOCUMENTS_SETTLED,
  'issd': MenuItemCountViewIds.DOCUMENTS_EXTERNAL_APPLICATION_DOCUMENTS,
  'signature-book': MenuItemCountViewIds.DOCUMENTS_SIGNATURE_BOOK,
  'flow-tasks-by-org-unit': MenuItemCountViewIds.UNIT_TASKS,
  'transfers-handed-over-registry-office': MenuItemCountViewIds.REGISTRY_OFFICE_TO_TAKE_OVER,
  'transfers-to-resolve-issues-registry-office': MenuItemCountViewIds.REGISTRY_OFFICE_TO_RESOLVE_ISSUES,
  'handed-over-by-filing-office': MenuItemCountViewIds.FILING_OFFICE_HANDED_OVER,
  'registry-office-problems': MenuItemCountViewIds.REGISTRY_OFFICE_PROBLEMS,
  'signature-book-by-org-unit': MenuItemCountViewIds.UNIT_SIGNATURE_BOOK,
  'transfers-handed-over': null,
  'flow-activities': null,
  'flow-activities-by-org-unit': null,
  'storage-unit': null,
  'transfers-saved-to-desa': null,
  'transfers-saved-to-desa-by-org-unit': null,
  'transfers-to-resolve-issues': null,
  'transfers-to-resolve-issues-by-org-unit': null,
  'transfers-handed-over-by-org-unit': null,
};

const consignmentsDispatchOfficerCountsViewIdMap: CountFieldNameToViewId = {
  toTakeoverCount: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_TAKE_OVER,
  takenOverCount: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_DISPATCH,
  dispatchedCount: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCHED,
  inDistributionCount: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_IN_DISTRIBUTION,
  dispatchedAndWaitingForDeliveryConfirmationCount: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCH_OFFICE_RECORD_DELIVERY_RESULT,
};

const consignmentsOwnerCountsViewIdMap: CountFieldNameToViewId = {
  allCount: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_ALL,
  dispatchedAndWaitingForDeliveryConfirmationCount: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_RECORD_DELIVERY_RESULT,
  rejectedCount: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_REJECTED,
  toHandoverCount: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_IN_PROGRESS,
};

const bulkPostingFormsCountsViewIdMap: CountFieldNameToViewId = {
  bulkPostingFormsCount: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_BULK_POSTING_FORMS,
};

const registryOfficeProblemsCountsViewIdMap: CountFieldNameToViewId = {
  registryOfficeProblemsCount: MenuItemCountViewIds.REGISTRY_OFFICE_PROBLEMS,
};

const officeDeskDispatchOfficerCountsViewIdMap: CountFieldNameToViewId = {
  postedCount: MenuItemCountViewIds.OFFICE_DESK_POSTED,
  toBePostedCount: MenuItemCountViewIds.OFFICE_DESK_TO_POST,
  toTakeoverCount: MenuItemCountViewIds.OFFICE_DESK_TO_TAKE_OVER,
  unpostedCount: MenuItemCountViewIds.OFFICE_DESK_UNPOSTED,
};

const officeDeskOwnerCountsViewIdMap: CountFieldNameToViewId = {
  allCount: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_ALL,
  postedCount: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_ALL_POSTED,
  rejectedCount: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_REJECTED,
  toHandoverCount: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_IN_PROGRESS,
};

const unitCountsViewIdMap: CountFieldNameToViewId = {
  allCount: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_ALL,
  rejectedCount: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_REJECTED,
  toHandoverCount: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_IN_PROGRESS,
  dispatchedAndWaitingForDeliveryConfirmationCount: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_RECORD_DELIVERY_RESULT,
};


export enum CounterTypeGroup {
  DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS = 'DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS',
  OWN_CONSIGNMENT_COUNTS = 'OWN_CONSIGNMENT_COUNTS',
  BULK_POSTING_FORMS_COUNTS = 'BULK_POSTING_FORMS_COUNTS',
  REGISTRY_OFFICE_PROBLEMS = 'REGISTRY_OFFICE_PROBLEMS',
}

interface CountsConfig {
  countFieldNameToViewIdMap: CountFieldNameToViewId,
  counts: Nullable<BackendCounts>,
}

type MenuCountsDto =
  ElasticRecordCounts |
  Nullable<OwnConsignmentPerStateCountForOwnerDto> |
  Nullable<OwnConsignmentPerStateCountForDispatchOfficerDto> |
  Nullable<OwnOfficeDeskConsignmentPerStateCountForOwnerDto> |
  Nullable<OwnOfficeDeskConsignmentPerStateCountForDispatchOfficerDto> |
  number;

const UPDATE_ALL_COUNTS = 'ALL' as const;

export function findMenuItemByViewId(menuItems: MenuItem[], viewId: MenuItemCountViewIds): Nullable<MenuItem> {
  for (const item of menuItems) {
    if (item.countViewId === viewId) return item;
    else if (item.children) {
      const foundItem = findMenuItemByViewId(item.children, viewId);

      if (foundItem !== null) return foundItem;
    }
  }

  return null;
}

@Injectable({
  providedIn: 'root'
})
export class MainMenuCountsService {

  private mainMenuService = inject(MainMenuService);
  private loadingService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);
  private apiElasticsearchService = inject(ApiElasticsearchService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private apiBulkPostingFormService = inject(ApiBulkPostingFormService);
  private apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  private currentSessionService = inject(CurrentSessionService);
  private authService = inject(AuthService);

  private get menuItems () {
    return this.mainMenuService.menuItems;
  }
  menuItemsWithCounts$ = new BehaviorSubject<Nullable<MenuItem[]>>(null);

  private counterEndpointsPerCounterType: Record<CounterTypeGroup, CounterEndpoint[]> = {
    [CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]: [
      CounterEndpoint.ELASTIC
    ],
    [CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]: [
      CounterEndpoint.OWN_CONSIGNMENTS_DISPATCH_OFFICER,
      CounterEndpoint.OWN_CONSIGNMENTS_OWNER,
      CounterEndpoint.OWN_CONSIGNMENTS_SECRETARIAT,
      CounterEndpoint.OFFICE_DESK_DISPATCH_OFFICER,
      CounterEndpoint.OFFICE_DESK_OWNER,
    ],
    [CounterTypeGroup.BULK_POSTING_FORMS_COUNTS]: [
      CounterEndpoint.BULK_POSTING_FORMS,
    ],
    [CounterTypeGroup.REGISTRY_OFFICE_PROBLEMS]: [
      CounterEndpoint.REGISTRY_OFFICE_PROBLEMS,
    ]
  };

  setMenuItemCount(menuItem: MenuItem, count: number) {
    menuItem.count = count;
  }

  allowedCountersForCurrentUser: CounterEndpoint[] = [];
  countersRecentlyRequested: CounterEndpoint[] = [];

  private setAllowedCountersForCurrentUser() {
    this.allowedCountersForCurrentUser = [];
    const isCurrentUserOfficer = true; // everybody is officer
    const isCurrentUserDispatchOfficer = isDispatchOfficer(this.currentSessionService.currentUserFunctionalPosition!);
    const isCurrentUserSecretariat = isSecretariat(this.currentSessionService.currentUserFunctionalPosition!);
    const isCurrentUserRegistryOfficer = isRegistryOfficer(this.currentSessionService.currentUserFunctionalPosition!);

    if (isCurrentUserOfficer) {
      this.allowedCountersForCurrentUser.push(
        CounterEndpoint.ELASTIC,
        CounterEndpoint.OWN_CONSIGNMENTS_OWNER,
        CounterEndpoint.OFFICE_DESK_OWNER,
      );
    }
    if (isCurrentUserDispatchOfficer) {
      this.allowedCountersForCurrentUser.push(
        CounterEndpoint.OWN_CONSIGNMENTS_DISPATCH_OFFICER,
        CounterEndpoint.OFFICE_DESK_DISPATCH_OFFICER,
        CounterEndpoint.BULK_POSTING_FORMS,
      );
    }
    if (isCurrentUserSecretariat) {
      this.allowedCountersForCurrentUser.push(
        CounterEndpoint.OWN_CONSIGNMENTS_SECRETARIAT
      );
    }
    if (isCurrentUserRegistryOfficer) {
      this.allowedCountersForCurrentUser.push(
        CounterEndpoint.REGISTRY_OFFICE_PROBLEMS
      );
    }
  }

  private init() {
    this.setAllowedCountersForCurrentUser();
    // mainMenuService.menuItems$ fire on login, hence counters are refreshed correctly on relogin
    this.mainMenuService.menuItems$.pipe(
      throttle(menuItems => {
        if (menuItems.length) {
        return interval(DELAY_REPEATED_COUNTER_REQUEST);
      } else {
          return interval(0);
        }
      }),
      takeUntilDestroyed(this.destroyRef)).subscribe(menuItems => {
      this.menuItemsWithCounts$.next(menuItems);
      this.updateMainMenuCounters(UPDATE_ALL_COUNTS, true);
    });
  }

  constructor() {
    this.init();

    this.authService.logout$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.resetAllCounts(this.menuItems);
    });
  }

  private resetAllCounts(menuItems: MenuItem[]) {
    menuItems.forEach(item => {
      item.count = null;
      if (item.children?.length) {
        this.resetAllCounts(item.children);
      }
    });
    this.menuItemsWithCounts$.next(this.menuItems);
  }

  private addCountsToMenu(countsConfig: CountsConfig) {
    if (!this.menuItems ?.length) return;

    Object.entries(countsConfig.countFieldNameToViewIdMap).forEach(([countFieldName, routeViewId]) => {
      const menuItem = findMenuItemByViewId(this.menuItems , routeViewId);
      if (menuItem && !isNil(countsConfig.counts?.[countFieldName as keyof BackendCounts])) {
        this.setMenuItemCount(menuItem, countsConfig.counts![countFieldName as keyof BackendCounts]!);
      }
    });
  }

  private addCountsElastic = (counts: MenuCountsDto) => {
    this.addCountsToMenu( {
      countFieldNameToViewIdMap: elasticCountsViewIdMap as CountFieldNameToViewId,
      counts: counts as unknown as Nullable<BackendCounts>,
    });
  };

  private addCountsConsignmentsForOwner = (dispatchCounts: Nullable<MenuCountsDto>,) => {
    this.addCountsToMenu({
        countFieldNameToViewIdMap: consignmentsOwnerCountsViewIdMap,
        counts: dispatchCounts as unknown as Nullable<BackendCounts>,
    });
  };

  private addCountsConsignmentsForDispatchOfficer = (dispatchCounts: Nullable<MenuCountsDto>) => {
    dispatchCounts = dispatchCounts as OwnConsignmentPerStateCountForDispatchOfficerDto;
    this.addCountsToMenu({
      countFieldNameToViewIdMap: consignmentsDispatchOfficerCountsViewIdMap,
      counts: dispatchCounts ?
        {
          ...(dispatchCounts as unknown as BackendCounts),
          // "Dispatched" view displays consignments in multiple states
          dispatchedCount: dispatchCounts.dispatchedCount + dispatchCounts.deliveredCount + dispatchCounts.notDeliveredCount,
        } :
        null
    });
  };

  private addCountsOfficeDeskForOfficer = (officeDeskCounts: Nullable<MenuCountsDto>,) => {
    this.addCountsToMenu({
      countFieldNameToViewIdMap: officeDeskOwnerCountsViewIdMap,
      counts: officeDeskCounts as unknown as Nullable<BackendCounts>,
    });
  };

  private addCountsOfficeDeskForDispatchOfficer = (officeDeskCounts: Nullable<MenuCountsDto>,) => {
    this.addCountsToMenu({
      countFieldNameToViewIdMap: officeDeskDispatchOfficerCountsViewIdMap,
      counts: officeDeskCounts as unknown as Nullable<BackendCounts>,
    });
  };

  private addCountsBulkPostingFormForDispatchOfficer = (bulkPostingFormsCount: Nullable<MenuCountsDto>) => {
    this.addCountsToMenu({
      countFieldNameToViewIdMap: bulkPostingFormsCountsViewIdMap,
      counts: {bulkPostingFormsCount} as unknown as Nullable<BackendCounts>,
    });
  };

  private addCountsRegistryOfficeProblemsForRegistryOfficer = (registryOfficeProblemsCount: Nullable<MenuCountsDto>) => {
    this.addCountsToMenu({
      countFieldNameToViewIdMap: registryOfficeProblemsCountsViewIdMap,
      counts: {registryOfficeProblemsCount} as unknown as Nullable<BackendCounts>,
    });
  };

  private addCountsUnit = (unitCounts: Nullable<MenuCountsDto>) => {
    this.addCountsToMenu({
      countFieldNameToViewIdMap: unitCountsViewIdMap,
      counts: unitCounts as unknown as Nullable<BackendCounts>,
    });
  };

  private countReqs$: Record<CounterEndpoint, {req$: Observable<any>, applyHandler: (param: MenuCountsDto) => void} > = {
    [CounterEndpoint.ELASTIC]: {
      req$: this.apiElasticsearchService.elasticsearchGetCountForViews(),
      applyHandler: this.addCountsElastic
    },
    [CounterEndpoint.OWN_CONSIGNMENTS_DISPATCH_OFFICER]: {
      req$: this.apiOwnConsignmentService.ownConsignmentReturnConsignmentsPerStateCountForDispatchOfficer(),
      applyHandler: this.addCountsConsignmentsForDispatchOfficer
    },
    [CounterEndpoint.OWN_CONSIGNMENTS_OWNER]: {
      req$: this.apiOwnConsignmentService.ownConsignmentReturnConsignmentsPerStateCountForOwner(),
      applyHandler: this.addCountsConsignmentsForOwner
    },
    [CounterEndpoint.OWN_CONSIGNMENTS_SECRETARIAT]: {
      req$: this.apiOwnConsignmentService.ownConsignmentReturnConsignmentsPerStateCountForSecretariat(),
      applyHandler: this.addCountsUnit
    },
    [CounterEndpoint.OFFICE_DESK_DISPATCH_OFFICER]: {
      req$: this.apiOwnConsignmentService.ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForDispatchOfficer(),
      applyHandler: this.addCountsOfficeDeskForDispatchOfficer
    },
    [CounterEndpoint.OFFICE_DESK_OWNER]: {
      req$: this.apiOwnConsignmentService.ownConsignmentReturnOfficeDeskConsignmentsPerStateCountForOwner(),
      applyHandler: this.addCountsOfficeDeskForOfficer
    },
    [CounterEndpoint.BULK_POSTING_FORMS]: {
      req$: this.apiBulkPostingFormService.bulkPostingFormSearchAndCount(),
      applyHandler: this.addCountsBulkPostingFormForDispatchOfficer
    },
    [CounterEndpoint.REGISTRY_OFFICE_PROBLEMS]: {
      req$: this.apiRegistryOfficeTransferService.registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount(),
      applyHandler: this.addCountsRegistryOfficeProblemsForRegistryOfficer
    },
  };

  updateMainMenuCounters(counterTypes: CounterTypeGroup[] | typeof UPDATE_ALL_COUNTS, skipElasticDelay = false) {
    if (!this.menuItems ?.length || !counterTypes.length) return;

    let endpoints: CounterEndpoint[] = [];
    if (counterTypes === UPDATE_ALL_COUNTS) {
      endpoints = flatten(Object.values(this.counterEndpointsPerCounterType));
    } else {
      endpoints = flatten(counterTypes.map(r => this.counterEndpointsPerCounterType[r as CounterTypeGroup]));
    }
    endpoints = endpoints.filter(e => !this.countersRecentlyRequested.includes(e));

    endpoints = endpoints.filter(r => this.allowedCountersForCurrentUser.includes(r));

    endpoints.forEach(endpoint => {
      if (!this.countersRecentlyRequested.indexOf(endpoint)) this.countersRecentlyRequested.push(endpoint);

      const counterDefinition = this.countReqs$[endpoint];
      if (counterDefinition?.applyHandler) {
        // counters may be refreshed upon specific UI user action, and since there's a delay between db and aggregated counts from elastic,
        // adding a delay increases likelihood of correct counts
        const initialDelayInMs = skipElasticDelay ? 0 : ELASTIC_RELOAD_DELAY;
        of(null).pipe(
          delay(initialDelayInMs),
          finalize(() => {
            setTimeout(() => {
              this.countersRecentlyRequested = this.countersRecentlyRequested.slice(this.countersRecentlyRequested.indexOf(endpoint));
            }, DELAY_REPEATED_COUNTER_REQUEST);
          }),
          switchMap(_ => counterDefinition.req$)
        ).subscribe({
          next: counters => {
            counterDefinition.applyHandler(counters);
            this.menuItemsWithCounts$.next([...this.menuItems]);
          },
          error: err => {
          },
        });
      }
    });
  }
}
