import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {catchError, filter, of, Subscription, switchMap} from 'rxjs';
import {EnvelopeTemplateDto, OwnInternalPaperConsignmentCreateDto, OwnPaperConsignmentCreateDto} from '|api/sad';
import {IczOnChanges, IczSimpleChanges} from '../../../../../../utils/icz-on-changes';
import {
  ConsignmentTypeForPreview,
  RemoteBinaryFileDownloadService
} from '../../../../../../services/remote-binary-file-download.service';
import {LoadingIndicatorService} from '../../../../../essentials/loading-indicator.service';
import {fileToBase64} from '../../../../../../lib/utils';

@Component({
  selector: 'icz-envelope-preview',
  templateUrl: './envelope-preview.component.html',
  styleUrls: ['./envelope-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvelopePreviewComponent implements IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  protected loadingService = inject(LoadingIndicatorService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);

  @Input({required: true})
  consignment: Nullable<OwnPaperConsignmentCreateDto | OwnInternalPaperConsignmentCreateDto>;
  @Input({required: true})
  uid: Nullable<string>;
  @Input({required: true})
  consignmentType!: ConsignmentTypeForPreview;
  @Input({required: true})
  currentEnvelopeTemplate: Nullable<EnvelopeTemplateDto>;

  currentPreviewDataUrl: Nullable<string>;

  previewErrorOccurred = false;

  previewSubscription: Nullable<Subscription>;

  get infoText(): Nullable<string> {
    if (!this.consignment) {
      return 'Pro zobrazení náhledu obálky vyplňte šablonu obálky a všechna povinná pole.';
    }
    else if (this.previewErrorOccurred) {
      return 'Při utváření náhledu obálky došlo k chybě.';
    }
    else {
      return null;
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.consignment) {
      this.previewErrorOccurred = false;

      this.previewSubscription?.unsubscribe();

      if (this.consignment?.envelopeTemplateId) {
        this.previewSubscription = this.loadingService.doLoading(
          this.remoteBinaryFileDownloadService.fetchEnvelopePreview(
            this.consignmentType,
            this.consignment!,
            this.uid
          ).pipe(
            catchError(_ => {
              this.previewErrorOccurred = true;
              this.cd.detectChanges();
              return of(null);
            }),
            filter(Boolean),
            switchMap(pdfBuffer => {
              return fileToBase64(new File([pdfBuffer], 'unknown.svg', {type: 'image/svg+xml'}));
            }),
          ),
          this
        ).subscribe(svgDataUrl => {
          this.currentPreviewDataUrl = svgDataUrl;
          this.cd.detectChanges();
        });
      }
      else {
        this.currentPreviewDataUrl = null;
        this.cd.detectChanges();
      }
    }
  }

}
