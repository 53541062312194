import {inject, Injectable} from '@angular/core';
import {LocalStorageKey, UserSettingsService} from '../../services/user-settings.service';

export type FunctionalPositionGroupId = string; // human-readable group name
export type FunctionalPositionGroupItem = string|number;
export type FunctionalPositionGroupItems = Array<FunctionalPositionGroupItem>;
export interface FunctionalPositionGroup {
  items: FunctionalPositionGroupItems;
  isOrdered: boolean;
}
type SavedFunctionalPositions = Record<FunctionalPositionGroupId, FunctionalPositionGroup>;

@Injectable({
  providedIn: 'root'
})
export class SavedFunctionalPositionsService {

  private userSettingsService = inject(UserSettingsService);

  savedFunctionalPositions: SavedFunctionalPositions = {};

  constructor() {
    if (!this.userSettingsService.getParsedValue(LocalStorageKey.SAVED_FUNCTIONAL_POSITIONS)) {
      this.saveValue({});
    }

    this.loadGroups();
  }

  addFunctionalPositionGroup(groupId: FunctionalPositionGroupId, items: FunctionalPositionGroupItems, isOrdered: boolean) {
    this.loadGroups(); // concurrency-safe additional load

    if (this.savedFunctionalPositions.hasOwnProperty(groupId)) {
      throw new Error(`Group with ID ${groupId} already exists in saved group store.`);
    }
    else {
      this.savedFunctionalPositions[groupId] = {
        items,
        isOrdered,
      };
      this.saveGroups();
    }
  }

  removeFunctionalPositionGroup(groupId: FunctionalPositionGroupId) {
    delete this.savedFunctionalPositions[groupId];
    this.saveGroups();
  }

  private saveGroups() {
    this.saveValue(this.savedFunctionalPositions);
  }

  private saveValue(val: SavedFunctionalPositions) {
    return this.userSettingsService.setParsedValue(LocalStorageKey.SAVED_FUNCTIONAL_POSITIONS, val);
  }

  private loadGroups() {
    this.savedFunctionalPositions = this.userSettingsService.getParsedValue(LocalStorageKey.SAVED_FUNCTIONAL_POSITIONS)!;
  }

}
