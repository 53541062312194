/* tslint:disable */
/* eslint-disable */

/**
 * Event state
 */
export enum ErmsReceivedEventAuditState {
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  REJECTED_FOR_SERVER_ERROR = 'REJECTED_FOR_SERVER_ERROR',
  SKIPPED = 'SKIPPED',
  TO_PROCESS = 'TO_PROCESS',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED'
}
