/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { classificationCreate } from '../fn/classification/classification-create';
import { ClassificationCreate$Params } from '../fn/classification/classification-create';
import { classificationDelete } from '../fn/classification/classification-delete';
import { ClassificationDelete$Params } from '../fn/classification/classification-delete';
import { ClassificationDto } from '../models/classification-dto';
import { classificationFindAll } from '../fn/classification/classification-find-all';
import { ClassificationFindAll$Params } from '../fn/classification/classification-find-all';
import { classificationFindById } from '../fn/classification/classification-find-by-id';
import { ClassificationFindById$Params } from '../fn/classification/classification-find-by-id';
import { classificationSearch } from '../fn/classification/classification-search';
import { ClassificationSearch$Params } from '../fn/classification/classification-search';
import { classificationTestCreate } from '../fn/testing-purpose-only/classification-test-create';
import { ClassificationTestCreate$Params } from '../fn/testing-purpose-only/classification-test-create';
import { classificationUpdate } from '../fn/classification/classification-update';
import { ClassificationUpdate$Params } from '../fn/classification/classification-update';
import { PageClassificationDto } from '../models/page-classification-dto';


/**
 * Controller for Classifications
 */
@Injectable({ providedIn: 'root' })
export class ApiClassificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `classificationFindById()` */
  static readonly ClassificationFindByIdPath = '/codebook/classifier/classification/{id}';

  /**
   * Find classification by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationFindById$Response(params: ClassificationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationDto>> {
    return classificationFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find classification by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationFindById(params: ClassificationFindById$Params, context?: HttpContext): Observable<ClassificationDto> {
    return this.classificationFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationDto>): ClassificationDto => r.body)
    );
  }

  /** Path part for operation `classificationUpdate()` */
  static readonly ClassificationUpdatePath = '/codebook/classifier/classification/{id}';

  /**
   * Update existing classification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationUpdate$Response(params: ClassificationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationDto>> {
    return classificationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing classification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationUpdate(params: ClassificationUpdate$Params, context?: HttpContext): Observable<ClassificationDto> {
    return this.classificationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationDto>): ClassificationDto => r.body)
    );
  }

  /** Path part for operation `classificationDelete()` */
  static readonly ClassificationDeletePath = '/codebook/classifier/classification/{id}';

  /**
   * Delete existing classification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationDelete$Response(params: ClassificationDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return classificationDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing classification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationDelete(params: ClassificationDelete$Params, context?: HttpContext): Observable<void> {
    return this.classificationDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `classificationFindAll()` */
  static readonly ClassificationFindAllPath = '/codebook/classifier/classification';

  /**
   * Finds all classifications
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationFindAll$Response(params?: ClassificationFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<ClassificationDto>>> {
    return classificationFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all classifications
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationFindAll(params?: ClassificationFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<ClassificationDto>> {
    return this.classificationFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ClassificationDto>>): Array<ClassificationDto> => r.body)
    );
  }

  /** Path part for operation `classificationCreate()` */
  static readonly ClassificationCreatePath = '/codebook/classifier/classification';

  /**
   * Create new classification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationCreate$Response(params: ClassificationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationDto>> {
    return classificationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new classification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationCreate(params: ClassificationCreate$Params, context?: HttpContext): Observable<ClassificationDto> {
    return this.classificationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationDto>): ClassificationDto => r.body)
    );
  }

  /** Path part for operation `classificationTestCreate()` */
  static readonly ClassificationTestCreatePath = '/codebook/classifier/classification/testCreate';

  /**
   * Create new classification, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationTestCreate$Response(params: ClassificationTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationDto>> {
    return classificationTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new classification, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationTestCreate(params: ClassificationTestCreate$Params, context?: HttpContext): Observable<ClassificationDto> {
    return this.classificationTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationDto>): ClassificationDto => r.body)
    );
  }

  /** Path part for operation `classificationSearch()` */
  static readonly ClassificationSearchPath = '/codebook/classifier/classification/search-api';

  /**
   * Finds classifications based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSearch$Response(params?: ClassificationSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageClassificationDto>> {
    return classificationSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds classifications based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSearch(params?: ClassificationSearch$Params|null|undefined, context?: HttpContext): Observable<PageClassificationDto> {
    return this.classificationSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageClassificationDto>): PageClassificationDto => r.body)
    );
  }

}
