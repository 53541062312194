import {map, switchMap} from 'rxjs/operators';
import {removeDuplicates} from '../../../lib/utils';
import {Observable, of, pipe} from 'rxjs';
import {EsslComponentDto, EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {FilterOperator} from '../../../services/search-api.service';
import {Page} from '../../../api';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';

export function getFullComponentsObs(tasksOrActivities: Page<CirculationTaskDto | CirculationActivityDto>, esslComponentSearchService: EsslComponentSearchService) {
  const componentIds = removeDuplicates(
    tasksOrActivities.content.map(taskOrActivity => taskOrActivity.componentId!).filter(Boolean)
  );

  let fullComponents$: Observable<EsslComponentDto[]> = of([]);

  if (componentIds.length) {
    fullComponents$ = esslComponentSearchService.findEsslComponents({
      page: 0,
      size: componentIds.length,
      filter: [
        {
          fieldName: 'id',
          operator: FilterOperator.inSet,
          value: String(componentIds),
        }
      ],
      sort: []
    }).pipe(map(page => page.content ?? []));
  }

  return fullComponents$;
}

export function fetchInFullComponents(esslComponentSearchService: EsslComponentSearchService) {
  return pipe(
    switchMap((tasksOrActivities: Page<CirculationTaskDto | CirculationActivityDto>) => {
      return getFullComponentsObs(tasksOrActivities, esslComponentSearchService).pipe(map(components => {
        tasksOrActivities.content = tasksOrActivities.content.map(t => {
          return {
            ...t,
            fullComponent: components.find(d => d.id === t.componentId) ?? null,
          };
        });
        return tasksOrActivities;
      }));
    })
  );
}
