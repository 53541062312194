import {MatDialogConfig} from '@angular/material/dialog';
import {IczModalRef} from '../services/icz-modal-ref.injectable';
import {inject} from '@angular/core';
import {ICZ_MODAL_DATA} from '../components/dialogs/component-modal/component-modal.component';

export const MODAL_BACKDROP_CLASS = 'icz-modal-backdrop';

const DEFAULT_MODAL_CONFIG: MatDialogConfig = {
  disableClose: true, // disables closing a modal using backdrop clicks and Escape keypresses
  closeOnNavigation: true, // disables closing a modal using the back button of the browser
  autoFocus: false,
  backdropClass: ['cdk-overlay-dark-backdrop', MODAL_BACKDROP_CLASS],
  maxWidth: '100vw',
  maxHeight: '100vh',
};

export function extendDefaultModalConfig<D>(modalConfigExtensions: MatDialogConfig<D>): MatDialogConfig<D> {
  return {
    ...DEFAULT_MODAL_CONFIG,
    ...modalConfigExtensions,
  };
}

export function injectModalRef<T>() {
  return inject<IczModalRef<T>>(IczModalRef);
}

export function injectModalData<T>() {
  return inject<T>(ICZ_MODAL_DATA);
}
