/* tslint:disable */
/* eslint-disable */

/**
 * File state
 */
export enum FileState {
  OPEN = 'OPEN',
  SETTLED = 'SETTLED',
  CLOSED = 'CLOSED',
  DEACTIVATED = 'DEACTIVATED',
  DELETED = 'DELETED',
  SUSPENDED = 'SUSPENDED'
}
