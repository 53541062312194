/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-create-data-box-consignment-configuration';
import { OwnConsignmentConfigurationCreateDataBoxConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-create-data-box-consignment-configuration';
import { ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-create-digital-consignment-validation-configuration';
import { OwnConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-create-digital-consignment-validation-configuration';
import { ownConsignmentConfigurationCreateEmailConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-create-email-consignment-configuration';
import { OwnConsignmentConfigurationCreateEmailConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-create-email-consignment-configuration';
import { ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-create-office-desk-consignment-configuration';
import { OwnConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-create-office-desk-consignment-configuration';
import { ownConsignmentConfigurationCreatePaperConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-create-paper-consignment-configuration';
import { OwnConsignmentConfigurationCreatePaperConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-create-paper-consignment-configuration';
import { ownConsignmentConfigurationGetDataBoxConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-get-data-box-consignment-configuration';
import { OwnConsignmentConfigurationGetDataBoxConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-get-data-box-consignment-configuration';
import { ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-get-digital-consignment-validation-configuration';
import { OwnConsignmentConfigurationGetDigitalConsignmentValidationConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-get-digital-consignment-validation-configuration';
import { ownConsignmentConfigurationGetEmailConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-get-email-consignment-configuration';
import { OwnConsignmentConfigurationGetEmailConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-get-email-consignment-configuration';
import { ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-get-office-desk-consignment-configuration';
import { OwnConsignmentConfigurationGetOfficeDeskConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-get-office-desk-consignment-configuration';
import { ownConsignmentConfigurationGetPaperConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-get-paper-consignment-configuration';
import { OwnConsignmentConfigurationGetPaperConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-get-paper-consignment-configuration';
import { ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-update-data-box-consignment-configuration';
import { OwnConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-update-data-box-consignment-configuration';
import { ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-update-digital-consignment-validation-configuration';
import { OwnConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-update-digital-consignment-validation-configuration';
import { ownConsignmentConfigurationUpdateEmailConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-update-email-consignment-configuration';
import { OwnConsignmentConfigurationUpdateEmailConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-update-email-consignment-configuration';
import { ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-update-office-desk-consignment-configuration';
import { OwnConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-update-office-desk-consignment-configuration';
import { ownConsignmentConfigurationUpdatePaperConsignmentConfiguration } from '../fn/own-consignment-configuration/own-consignment-configuration-update-paper-consignment-configuration';
import { OwnConsignmentConfigurationUpdatePaperConsignmentConfiguration$Params } from '../fn/own-consignment-configuration/own-consignment-configuration-update-paper-consignment-configuration';
import { OwnDataBoxConsignmentConfigurationDto } from '../models/own-data-box-consignment-configuration-dto';
import { OwnDigitalConsignmentValidationConfigurationDto } from '../models/own-digital-consignment-validation-configuration-dto';
import { OwnEmailConsignmentConfigurationDto } from '../models/own-email-consignment-configuration-dto';
import { OwnOfficeDeskConsignmentConfigurationDto } from '../models/own-office-desk-consignment-configuration-dto';
import { OwnPaperConsignmentConfigurationDto } from '../models/own-paper-consignment-configuration-dto';


/**
 * REST API for the Own Consignment global configuration.
 */
@Injectable({ providedIn: 'root' })
export class ApiOwnConsignmentConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ownConsignmentConfigurationGetPaperConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationGetPaperConsignmentConfigurationPath = '/sad/configuration/own-consignment/paper';

  /**
   * Own paper consignment gloconf.
   *
   * Returns the global configuration for own paper consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationGetPaperConsignmentConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetPaperConsignmentConfiguration$Response(params?: OwnConsignmentConfigurationGetPaperConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationGetPaperConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own paper consignment gloconf.
   *
   * Returns the global configuration for own paper consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationGetPaperConsignmentConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetPaperConsignmentConfiguration(params?: OwnConsignmentConfigurationGetPaperConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<OwnPaperConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationGetPaperConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentConfigurationDto>): OwnPaperConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationUpdatePaperConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationUpdatePaperConsignmentConfigurationPath = '/sad/configuration/own-consignment/paper';

  /**
   * Own paper consignment gloconf.
   *
   * Updates the global configuration for own paper consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationUpdatePaperConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdatePaperConsignmentConfiguration$Response(params: OwnConsignmentConfigurationUpdatePaperConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationUpdatePaperConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own paper consignment gloconf.
   *
   * Updates the global configuration for own paper consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationUpdatePaperConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdatePaperConsignmentConfiguration(params: OwnConsignmentConfigurationUpdatePaperConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnPaperConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationUpdatePaperConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentConfigurationDto>): OwnPaperConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationCreatePaperConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationCreatePaperConsignmentConfigurationPath = '/sad/configuration/own-consignment/paper';

  /**
   * Own paper consignment gloconf.
   *
   * Creates the global configuration for own paper consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationCreatePaperConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreatePaperConsignmentConfiguration$Response(params: OwnConsignmentConfigurationCreatePaperConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnPaperConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationCreatePaperConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own paper consignment gloconf.
   *
   * Creates the global configuration for own paper consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationCreatePaperConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreatePaperConsignmentConfiguration(params: OwnConsignmentConfigurationCreatePaperConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnPaperConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationCreatePaperConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnPaperConsignmentConfigurationDto>): OwnPaperConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationGetOfficeDeskConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/office-desk';

  /**
   * Own office desk consignment gloconf.
   *
   * Returns the global configuration for own office desk consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration$Response(params?: OwnConsignmentConfigurationGetOfficeDeskConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own office desk consignment gloconf.
   *
   * Returns the global configuration for own office desk consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration(params?: OwnConsignmentConfigurationGetOfficeDeskConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<OwnOfficeDeskConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationGetOfficeDeskConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentConfigurationDto>): OwnOfficeDeskConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationUpdateOfficeDeskConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/office-desk';

  /**
   * Own office desk consignment gloconf.
   *
   * Updates the global configuration for own office desk consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration$Response(params: OwnConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own office desk consignment gloconf.
   *
   * Updates the global configuration for own office desk consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration(params: OwnConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationUpdateOfficeDeskConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentConfigurationDto>): OwnOfficeDeskConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationCreateOfficeDeskConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/office-desk';

  /**
   * Own office desk consignment gloconf.
   *
   * Creates the global configuration for own office desk consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration$Response(params: OwnConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnOfficeDeskConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own office desk consignment gloconf.
   *
   * Creates the global configuration for own office desk consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration(params: OwnConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnOfficeDeskConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationCreateOfficeDeskConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnOfficeDeskConsignmentConfigurationDto>): OwnOfficeDeskConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationGetEmailConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationGetEmailConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/email';

  /**
   * Own email consignment gloconf.
   *
   * Returns the global configuration for own email consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationGetEmailConsignmentConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetEmailConsignmentConfiguration$Response(params?: OwnConsignmentConfigurationGetEmailConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnEmailConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationGetEmailConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own email consignment gloconf.
   *
   * Returns the global configuration for own email consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationGetEmailConsignmentConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetEmailConsignmentConfiguration(params?: OwnConsignmentConfigurationGetEmailConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<OwnEmailConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationGetEmailConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnEmailConsignmentConfigurationDto>): OwnEmailConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationUpdateEmailConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationUpdateEmailConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/email';

  /**
   * Own email consignment gloconf.
   *
   * Updates the global configuration for own email consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationUpdateEmailConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateEmailConsignmentConfiguration$Response(params: OwnConsignmentConfigurationUpdateEmailConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnEmailConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationUpdateEmailConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own email consignment gloconf.
   *
   * Updates the global configuration for own email consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationUpdateEmailConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateEmailConsignmentConfiguration(params: OwnConsignmentConfigurationUpdateEmailConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnEmailConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationUpdateEmailConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnEmailConsignmentConfigurationDto>): OwnEmailConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationCreateEmailConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationCreateEmailConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/email';

  /**
   * Own email consignment gloconf.
   *
   * Creates the global configuration for own email consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationCreateEmailConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateEmailConsignmentConfiguration$Response(params: OwnConsignmentConfigurationCreateEmailConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnEmailConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationCreateEmailConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own email consignment gloconf.
   *
   * Creates the global configuration for own email consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationCreateEmailConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateEmailConsignmentConfiguration(params: OwnConsignmentConfigurationCreateEmailConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnEmailConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationCreateEmailConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnEmailConsignmentConfigurationDto>): OwnEmailConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationGetDataBoxConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationGetDataBoxConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/databox';

  /**
   * Own DataBox consignment gloconf.
   *
   * Returns the global configuration for own DataBox consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationGetDataBoxConsignmentConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetDataBoxConsignmentConfiguration$Response(params?: OwnConsignmentConfigurationGetDataBoxConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationGetDataBoxConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own DataBox consignment gloconf.
   *
   * Returns the global configuration for own DataBox consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationGetDataBoxConsignmentConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetDataBoxConsignmentConfiguration(params?: OwnConsignmentConfigurationGetDataBoxConsignmentConfiguration$Params|null|undefined, context?: HttpContext): Observable<OwnDataBoxConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationGetDataBoxConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>): OwnDataBoxConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationUpdateDataBoxConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/databox';

  /**
   * Own DataBox consignment gloconf.
   *
   * Updates the global configuration for own DataBox consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Response(params: OwnConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own DataBox consignment gloconf.
   *
   * Updates the global configuration for own DataBox consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration(params: OwnConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnDataBoxConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationUpdateDataBoxConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>): OwnDataBoxConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration()` */
  static readonly OwnConsignmentConfigurationCreateDataBoxConsignmentConfigurationPath = '/sad/configuration/own-consignment/digital/databox';

  /**
   * Own DataBox consignment gloconf.
   *
   * Creates the global configuration for own DataBox consignments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration$Response(params: OwnConsignmentConfigurationCreateDataBoxConsignmentConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>> {
    return ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Own DataBox consignment gloconf.
   *
   * Creates the global configuration for own DataBox consignments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration(params: OwnConsignmentConfigurationCreateDataBoxConsignmentConfiguration$Params, context?: HttpContext): Observable<OwnDataBoxConsignmentConfigurationDto> {
    return this.ownConsignmentConfigurationCreateDataBoxConsignmentConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDataBoxConsignmentConfigurationDto>): OwnDataBoxConsignmentConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration()` */
  static readonly OwnConsignmentConfigurationGetDigitalConsignmentValidationConfigurationPath = '/sad/configuration/own-consignment/digital/component-validation';

  /**
   * Digital component validation gloconf.
   *
   * Returns the global configuration for own digital consignments' components validation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration$Response(params?: OwnConsignmentConfigurationGetDigitalConsignmentValidationConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentValidationConfigurationDto>> {
    return ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital component validation gloconf.
   *
   * Returns the global configuration for own digital consignments' components validation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration(params?: OwnConsignmentConfigurationGetDigitalConsignmentValidationConfiguration$Params|null|undefined, context?: HttpContext): Observable<OwnDigitalConsignmentValidationConfigurationDto> {
    return this.ownConsignmentConfigurationGetDigitalConsignmentValidationConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentValidationConfigurationDto>): OwnDigitalConsignmentValidationConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration()` */
  static readonly OwnConsignmentConfigurationUpdateDigitalConsignmentValidationConfigurationPath = '/sad/configuration/own-consignment/digital/component-validation';

  /**
   * Digital component validation gloconf.
   *
   * Updates the global configuration for own digital consignments' components validation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration$Response(params: OwnConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentValidationConfigurationDto>> {
    return ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital component validation gloconf.
   *
   * Updates the global configuration for own digital consignments' components validation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration(params: OwnConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration$Params, context?: HttpContext): Observable<OwnDigitalConsignmentValidationConfigurationDto> {
    return this.ownConsignmentConfigurationUpdateDigitalConsignmentValidationConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentValidationConfigurationDto>): OwnDigitalConsignmentValidationConfigurationDto => r.body)
    );
  }

  /** Path part for operation `ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration()` */
  static readonly OwnConsignmentConfigurationCreateDigitalConsignmentValidationConfigurationPath = '/sad/configuration/own-consignment/digital/component-validation';

  /**
   * Digital component validation gloconf.
   *
   * Creates the global configuration for own digital consignments' components validation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration$Response(params: OwnConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDigitalConsignmentValidationConfigurationDto>> {
    return ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital component validation gloconf.
   *
   * Creates the global configuration for own digital consignments' components validation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration(params: OwnConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration$Params, context?: HttpContext): Observable<OwnDigitalConsignmentValidationConfigurationDto> {
    return this.ownConsignmentConfigurationCreateDigitalConsignmentValidationConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDigitalConsignmentValidationConfigurationDto>): OwnDigitalConsignmentValidationConfigurationDto => r.body)
    );
  }

}
