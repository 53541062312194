import {inject, Injectable} from '@angular/core';
import {OwnConsignmentsSearchService} from '../../../services/own-consignments-search.service';
import {
  OwnConsignmentsForDispatchOfficerDatasource
} from '../own-consignment-table/datasources/own-consignments-for-dispatch-officer.datasource';
import {ConsignmentType, OwnConsignmentStatus} from '|api/commons';
import {of, skip, take} from 'rxjs';
import {castStream} from '../../../lib/rxjs';
import {OwnPaperConsignmentDto} from '|api/sad';
import {Code2DMetadata} from '../barcode-scanned-consignments-table/barcode-scanned-consignments-table.component';
import {FilterOperator, GlobalOperator} from '../../../services/search-api.service';
import {FilterTreeOperator} from '../../table/filter-trees.utils';

@Injectable()
export class ConsignmentBarcodeScanAndFindService {
  private ownConsignmentsSearchService = inject(OwnConsignmentsSearchService);

  dataSource!: OwnConsignmentsForDispatchOfficerDatasource;

  initialize(dispatchOfficeDistributionNodeIds: number[], consignmentsToGetStates: OwnConsignmentStatus[]) {
    this.dataSource = new OwnConsignmentsForDispatchOfficerDatasource(
      this.ownConsignmentsSearchService,
      consignmentsToGetStates,
      dispatchOfficeDistributionNodeIds,
      [ConsignmentType.OWN_PAPER_ADDRESS, ConsignmentType.OWN_PAPER_INTERNAL]
    );
  }

  parseCodeString2D(codeString: Nullable<string>): Nullable<Code2DMetadata> {
    if (!codeString) {
      return null;
    }
    else if (codeString.length !== 60) {
      throw new Error('Incorrect 2D code string length.');
    }
    else {
      const paddingLength = 4;

      const country = codeString.substr(0, 2);
      const dpmVersion = Number(codeString.substr(2, 2));
      const customerNumber = codeString.substr(4, 9);
      const weight = Number(codeString.substr(13, 5));
      const submissionDate = codeString.substr(18, 6);
      const payoutAmountWholePart = Number(codeString.substr(24, 3));
      const payoutAmountDecimalPart = Number(codeString.substr(27, 2));
      const payoutAmount = payoutAmountWholePart + (payoutAmountDecimalPart * 0.01);
      const bulkConsignmentRegistrationNo = codeString.substr(29, 8);
      const postalServiceCode = codeString.substr(37, 3);
      const postalFeeSumWholePart = Number(codeString.substr(40, 10));
      const postalFeeSumDecimalPart = Number(codeString.substr(50, 2));
      const postalFeeSum = postalFeeSumWholePart + (postalFeeSumDecimalPart * 0.01);
      const signatureKey = codeString.substr(52 + paddingLength, 4);

      if (isNaN(dpmVersion) || isNaN(weight) || isNaN(payoutAmount) || isNaN(postalFeeSum)) {
        throw new Error('2D code contains unparseable numeric values.');
      }

      return {
        country,
        dpmVersion,
        customerNumber,
        weight,
        submissionDate,
        payoutAmount,
        bulkConsignmentRegistrationNo,
        postalServiceCode,
        postalFeeSum,
        signatureKey,
      };
    }
  }

  tryGetConsignmentByBcr(bcrCode: Nullable<string>) {
    if (!bcrCode) {
      return of([]);
    }
    else {
      this.dataSource.loadPage({
        globalOperator: GlobalOperator.and,
        complexFilter: {
          operator: FilterTreeOperator.OR,
          values: [
            {
              fieldName: 'outboundConsignmentIdentifier',
              operator: FilterOperator.equals,
              value: bcrCode,
            },
            {
              fieldName: 'uid.uid',
              operator: FilterOperator.equals,
              value: bcrCode,
            }
          ],
        },
        filter: [],
        sort: [],
        page: 0,
        size: 1,
      });

      return this.dataSource.items$.pipe(
        skip(1), // the first emit is always undefined
        take(1), // the next emit already contains the loaded data
        castStream<Array<OwnPaperConsignmentDto>>(),
      );
    }
  }

  getFiveConsignmentsUsingCheatCode() {
    this.dataSource.loadPage({
      filter: [],
      sort: [],
      page: 0,
      size: 5,
    });

    return this.dataSource.items$.pipe(
      skip(1), // the first emit is always undefined
      take(1), // the next emit already contains the loaded data
      castStream<Array<OwnPaperConsignmentDto>>(),
    );
  }
}
