import {AfterViewInit, Directive, ElementRef, EventEmitter, inject, Output} from '@angular/core';

@Directive({
  selector: '[iczMatCalendar]'
})
export class IczMatCalendarDirective implements AfterViewInit {

  private el = inject(ElementRef);

  // eslint-disable-next-line @angular-eslint/no-output-rename
  @Output('iczMatCalendar.daySelected')
  daySelected = new EventEmitter<void>();

  private selectableDateElements!: HTMLElement[];

  ngAfterViewInit() {
    this.selectableDateElements = Array.from(
      this.el.nativeElement.querySelectorAll('.mat-calendar-body-cell:not(.calendar-date-disabled)')
    );

    for (const selectableDateEl of this.selectableDateElements) {
      selectableDateEl.addEventListener(
        'click',
        () => this.daySelected.emit(),
      );
    }
  }

  // no need to OnDestroy as when the elements are destroyed
  // in DOM tree, their listeners are unregistered

}
