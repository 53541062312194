<form [formGroup]="form" [waiting]="loadingService.isLoading(this)" class="h-full">
  @if (data.duplicate) {
    <div>
      <icz-radio-group>
        <icz-radio-button
          class="mb-16"
          label="Připojit duplikát komponenty ke stávajícímu dokumentu"
          formControlName="dupilcateToCurrentDocument"
          [value]="true"
        ></icz-radio-button>
        <icz-radio-button
          label="Připojit duplikát komponenty k jinému vlastnímu dokumentu"
          formControlName="dupilcateToCurrentDocument"
          [value]="false"
        ></icz-radio-button>
        @if (!dupilcateToCurrentDocumentControl.value) {
          <icz-own-document-selector
            class="block pt-8 pl-32"
            [excludeObjectWithId]="data.documentId"
            [form]="form"
            controlName="documentId"
          ></icz-own-document-selector>
        }
      </icz-radio-group>
    </div>
  }
  @else {
    <div class="col grow h-full">
      <icz-document-selector
        [form]="form"
        controlName="documentId"
      ></icz-document-selector>
      @if (selectedDocumentControl.value) {
        <div class="grow">
          <icz-digital-components-selector
            id="document-resolution-component-selector"
            tableTitle="Výběr komponent pro převzetí"
            class="h-full"
            [view]="EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_SIMPLE"
            [disableAnalogComponents]="true"
            [documentId]="selectedDocumentControl.value"
            (esslComponentsSelected)="setEsslComponents($event)"
          ></icz-digital-components-selector>
        </div>
      }
    </div>
  }
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this) || this.selectedComponentIds.length === 0"
    (onAction)="submit()"
    primary [label]="data.duplicate ? 'Duplikovat' : 'Převzít'"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Zrušit"
  ></icz-button>
</icz-form-buttons>
