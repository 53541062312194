import {inject, Injectable} from '@angular/core';
import {finalize, Observable} from 'rxjs';
import {LoadingIndicatorService} from './loading-indicator.service';
import {AnyComponent} from '../../model';

@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingIndicatorService {

  private loadingService = inject(LoadingIndicatorService);

  globalLoadingDescription: Nullable<string>;
  isInitialized = false;

  private appComponent!: AnyComponent;

  initialize(appComponent: AnyComponent) {
    this.isInitialized = true;
    this.appComponent = appComponent;
  }

  doLoading<T>(obs$: Observable<T>, globalLoadingDescription?: Nullable<string>): Observable<T> {
    this.globalLoadingDescription = globalLoadingDescription;

    return this.loadingService.doLoading(obs$, this.appComponent).pipe(
      finalize(() => this.globalLoadingDescription = null),
    );
  }

  startLoading(): void {
    this.loadingService.startLoading(this.appComponent);
  }

  endLoading(): void {
    this.loadingService.endLoading(this.appComponent);
  }

}
