import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DistributionClass, SubjectAttributeStringDto} from '|api/commons';
import {AbstractSubjectsTableActionsComponent, SubjectsTableAction} from '../subjects-table-actions';
import {CreateNewConsignmentDialogType} from '../../../own-consignment-table/model/own-consignment-model';
import {
  DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES,
  DISTRIBUTION_CLASS_EXTENDED_REQUIRED_ATTRIBUTES
} from '../../subject-delivery-groups';
import {Option} from '../../../../../model';
import {SubjectRecordDto} from '|api/commons';

import {SubjectAttributeType} from '../../../model/subject-attribute-type';

export const TABLE_ACTION_DELIVERY_TYPE_CLASSES: Partial<Record<SubjectsTableAction, DistributionClass>> = {
  [SubjectsTableAction.DISPATCH_USING_PAPER]: DistributionClass.ADDRESS_PLACE,
  [SubjectsTableAction.DISPATCH_USING_ISDS]: DistributionClass.ISDS_BOX,
  [SubjectsTableAction.DISPATCH_USING_EMAIL]: DistributionClass.EMAIL_BOX,
  [SubjectsTableAction.DISPATCH_USING_IN_PERSON]: DistributionClass.ON_PLACE,
  [SubjectsTableAction.POST_ON_OFFICE_DESK]: DistributionClass.OFFICIAL_BOARD,
};

function subjectHasDefinedAttributeWithMultiplicity(subject: SubjectRecordDto, attr: SubjectAttributeType): boolean {
  return subject && (attr in (subject.attributes) && Boolean((subject.attributes[attr] as Array<SubjectAttributeStringDto>)!.length));
}

@Component({
  selector: 'icz-subjects-table-consignment-actions',
  templateUrl: './subjects-table-consignment-actions.component.html',
  styleUrls: ['./subjects-table-consignment-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectsTableConsignmentActionsComponent extends AbstractSubjectsTableActionsComponent implements OnInit {

  @Input({required: true}) createNewConsignmentDialogType!: CreateNewConsignmentDialogType;

  areMoreOptionsOpen = false;

  moreDeliveryActionOptions: Option<SubjectsTableAction>[] = [];

  readonly DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES = DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES;

  ngOnInit(): void {
    this.moreDeliveryActionOptions = ([
      {
        value: SubjectsTableAction.DISPATCH_USING_ISDS,
        icon: 'datova_zprava',
        label: 'Vypravit datovou zprávou',
      },
      {
        value: SubjectsTableAction.DISPATCH_USING_EMAIL,
        icon: 'email',
        label: 'Vypravit e-mailem',
      },
    ]).filter(o => {
      return (
        (this.createNewConsignmentDialogType === CreateNewConsignmentDialogType.ALLOW_ALL_DISPATCH_METHODS &&
        o.value === SubjectsTableAction.POST_ON_OFFICE_DESK) ||
        (!this.isDispatchAvailable(o.value, DISTRIBUTION_CLASS_BASIC_REQUIRED_ATTRIBUTES) &&
        this.isDispatchAvailable(o.value, DISTRIBUTION_CLASS_EXTENDED_REQUIRED_ATTRIBUTES))
      );
    });
  }

  isDispatchAvailable(action: SubjectsTableAction, requiredAttributesMap: Partial<Record<DistributionClass, SubjectAttributeType[]>>) {
    if (this.createNewConsignmentDialogType === CreateNewConsignmentDialogType.OFFICE_DESK_ONLY) {
      return action === SubjectsTableAction.POST_ON_OFFICE_DESK;
    }
    else {
      const requiredAttributes = requiredAttributesMap[
        TABLE_ACTION_DELIVERY_TYPE_CLASSES[action]!
        ];

      if (!requiredAttributes?.length) {
        return true;
      }
      else {
        return requiredAttributes
          .map(attr => subjectHasDefinedAttributeWithMultiplicity(this.subject, attr))
          .some(attributeDefined => attributeDefined);
      }
    }
  }

  extendedDeliveryActionSelected(selection: Option<SubjectsTableAction>[]) {
    if (selection.length) {
      this.actionExecuted.emit({
        action: selection[0].value,
        subject: this.subject,
      });
    }
  }

}
