<icz-alert
  heading="Zdá se, že chystáte založit duplicitní subjekt. Prosím zkontrolujte, zda nelze použít existující subjekt z rejstříku nebo systému Datových schránek."></icz-alert>

@if (modalData?.subjectToBeCreateOrUpdated) {
  <icz-card class="my-8">
    <icz-label [label]="headingText" class="mb-4 px-16 py-8"></icz-label>
    <div class="row items-center justify-between p-16">
      <div class="row items-center">
       <icz-subject-detail-row-info
         [subject]="modalData.subjectToBeCreateOrUpdated!"
         [showAllAttributes]="true"
       ></icz-subject-detail-row-info>
      </div>

      <icz-button (onAction)="forceCreateOrUpdate()" [label]="forceLabel"></icz-button>
    </div>
  </icz-card>
}
<hr>

<icz-subjects-table
  [dataSource]="dataSource"
  [columnSet]="SubjectTableColumnSet.RESULT_SET_FOR_LINKING"
  [linkButtonDisabled]="modalData.subjectReplacementConfig?.allowSubjectReplacement === false"
  [linkButtonDisabledReason]="modalData.subjectReplacementConfig?.disallowedReplacementReason!"
  (actionExecuted)="subjectSelected($event)"
  class="w-full"
  [tableTitle]="tableTitle"
  linkButtonLabel="Nahradit"
  tableId="subjects-duplicate-decision-dialog"
>
</icz-subjects-table>

<icz-form-buttons>
  <icz-button rightButtons primary label="Storno" (onAction)="cancel()"></icz-button>
</icz-form-buttons>
