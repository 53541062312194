<div class="row grow gap-8 overflow-hidden items-center">
  @if (!hideIcon) {
    <icz-icon [svgIcon]="svgIcon" [iczTooltip]="fileName"></icz-icon>
  }
  @if (!hideIcon && hasExecutionDateClause) {
    <icz-icon svgIcon="conversion"></icz-icon>
  }
  @if (showFileName) {
    <div>{{fileName}}</div>
  }
  @else {
    <div class="col grow overflow-hidden justify-center">
      <ng-content select="[fileStatus]"></ng-content>
      <div class="file-name" [iczTooltip]="digitalComponentLabel">{{ digitalComponentLabel }}</div>
      @if (!hideDescription && description) {
        <div class="file-description" [iczTooltip]="description">{{ description }}</div>
      }
    </div>
  }
  @if (locked) {
    <icz-button class="lock-icon" svgIcon="eye_hidden" background></icz-button>
  }
</div>
