import {Component, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {ApiSharedFolderService, DocumentDto, FileDto, SharedFolderDto} from '|api/document';
import {EntityType} from '|api/commons';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';


@Component({
  selector: 'icz-shared-folder-insert-dialog',
  templateUrl: './shared-folder-insert-dialog.component.html',
  styleUrls: ['./shared-folder-insert-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class SharedFolderInsertDialogComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected loadingService = inject(LoadingIndicatorService);
  protected apiSharedFolderService = inject(ApiSharedFolderService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected sharedFolder = injectModalData<SharedFolderDto>();

  formGroupsToCheck!: string[];

  form = new IczFormGroup({
    objectId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()])
  });

  selectedObjectEntityType: Nullable<EntityType>;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  objectSelected(object: DocumentDto | FileDto) {
    this.selectedObjectEntityType = object.entityType;
  }

  submit() {
    this.apiSharedFolderService.sharedFolderAddObjectsToSharedFolder({
      id: this.sharedFolder.id,
      body: [
        {
          id: this.form.getRawValue().objectId!,
          entityType: this.selectedObjectEntityType!,
        }
      ],
    }).subscribe(() => {
      this.modalRef.close(true);
    });
  }

  close() {
    this.modalRef.close(null);
  }

}
