import {HttpClient, HttpContext, HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {Observable, OperatorFunction, pipe} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {ApiClassificationSchemeService, ExportType} from '|api/codebook';
import {ConsignmentType} from '|api/commons';
import {ApiDigitalComponentService, ApiDigitalComponentVersionService, ApiPaperComponentService} from '|api/component';
import {
  ApiBulkPostingFormService,
  ApiConsignmentComponentService,
  ApiEnvelopeTemplatesService,
  ApiOwnConsignmentService,
  ApiReceivedDigitalConsignmentService,
  ApiReceivedPaperConsignmentService,
  ApiSheetLabelTemplatesService,
  EnvelopePrintRequestDto,
  EnvelopeTemplatePreviewRequestDto,
  OwnInternalPaperConsignmentCreateDto,
  OwnPaperConsignmentCreateDto,
  SheetLabelPrintRequestDto,
  SheetLabelTemplatePreviewRequestDto,
} from '|api/sad';
import {castStream} from '../lib/rxjs';
import {SKIP_ERROR_DIALOG} from '../core/error-handling/http-errors';
import {
  ApiErmsAuditService,
  ErmsAuditDirection,
  ErmsAuditOutgoingBatchDto,
  ErmsAuditReceivedBatchDto
} from '|api/nsess-api';
import {
  ErmsBatchDto
} from '../../../../../apps/espis/src/app/modules/admin/modules/automatic-processes/erms-batch-monitoring/erms-batch-model';

interface EpdzAttachmentIdentifier {
  messageId: number;
  attachmentId: number;
}

export interface BinaryWithCharset {
  buffer: ArrayBuffer;
  charset: string;
}

export type ConsignmentTypeForPreview = (
  ConsignmentType.OWN_PAPER_ADDRESS |
  ConsignmentType.OWN_PAPER_INTERNAL
  );

function pluckBuffer(): OperatorFunction<BinaryWithCharset, ArrayBuffer> {
  return source$ => source$.pipe(
    map(binaryWithCharset => binaryWithCharset.buffer),
  );
}

export function httpResponseToFile(): OperatorFunction<HttpResponse<ArrayBuffer>, File> {
  return pipe(
    map(httpResponse => {
      const contentTypeHeader = httpResponse.headers.get('Content-Type');
      const contentDispositionHeader = httpResponse.headers.get('Content-Disposition');

      let filename = 'unknown';

      if (contentDispositionHeader?.includes('filename*=UTF-8\'\'')) {
        const contentDispositionParts = contentDispositionHeader.split('filename*=UTF-8\'\'');
        filename = decodeURIComponent(contentDispositionParts[1]);
      }

      return new File(
        [httpResponse.body!],
        filename,
        {
          type: contentTypeHeader ?? 'application/octet-stream',
        }
      );
    }),
  );
}

@Injectable({
  providedIn: 'root'
})
export class RemoteBinaryFileDownloadService {

  private httpClient = inject(HttpClient);

  private fetchBinaryFile<TBody>(
    urlWithoutCoreRoot: string,
    paramMap: Record<string, string | number>,
    method: 'get' | 'post' = 'get',
    context?: HttpContext,
    requestBody?: Nullable<TBody>,
  ): Observable<BinaryWithCharset> {
    const pathParams = applyPathParams(
      CORE_MICROSERVICE_ROOT + urlWithoutCoreRoot,
      paramMap
    );
    const descriptionParams = {
      responseType: 'arraybuffer',
      observe: 'events',
      context,
    };

    let request$: Observable<HttpEvent<any>>;

    if (method === 'get') { // @ts-ignore
      request$ = this.httpClient.get<any>(pathParams, descriptionParams);
    }
    else { // @ts-ignore
      request$ = this.httpClient.post<any>(pathParams, requestBody ?? null, descriptionParams);
    }

    return request$.pipe(
      filter(httpEvent => httpEvent.type === HttpEventType.Response),
      castStream<HttpResponse<ArrayBuffer>>(),
      map(httpResponse => {
        const contentType = httpResponse.headers.get('Content-Type');

        let charset = 'utf-8'; // default

        if (contentType?.includes('charset=')) {
          charset = contentType.split('charset=')[1];
        }

        return {
          buffer: httpResponse.body!,
          charset,
        };
      }),
    );
  }

  fetchSheetLabelTestPrintPreview(format: 'PDF' | 'SVG' | 'PS', previewRequest: SheetLabelTemplatePreviewRequestDto): Observable<ArrayBuffer> {
    return this.fetchBinaryFile(
      ApiSheetLabelTemplatesService.SheetLabelTemplatesGetTemplatePreviewPath,
      {
        format
      },
      'post',
      SKIP_ERROR_DIALOG,
      previewRequest,
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchEnvelopeTestPrintPreview(format: 'PDF' | 'SVG' | 'PS', previewRequest: EnvelopeTemplatePreviewRequestDto): Observable<ArrayBuffer> {
    return this.fetchBinaryFile(
      ApiEnvelopeTemplatesService.EnvelopeTemplatesGetTemplatePreviewPath,
      {
        format
      },
      'post',
      SKIP_ERROR_DIALOG,
      previewRequest,
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchEnvelopePreview(
    consignmentType: ConsignmentTypeForPreview,
    previewRequest: OwnPaperConsignmentCreateDto | OwnInternalPaperConsignmentCreateDto,
    consignmentUid: Nullable<string>,
  ): Observable<ArrayBuffer> {
    const internalConsignmentEnvelopePreviewPath = (
      consignmentUid ?
        applyPathParams(ApiOwnConsignmentService.OwnConsignmentGetInternalPaperEnvelopePreviewWithUidPath, {uid: consignmentUid}) :
        ApiOwnConsignmentService.OwnConsignmentGetInternalPaperEnvelopePreviewPath
    );
    const paperConsignmentEnvelopePreviewPath = (
      consignmentUid ?
        applyPathParams(ApiOwnConsignmentService.OwnConsignmentGetPaperEnvelopePreviewWithUidPath, {uid: consignmentUid}) :
        ApiOwnConsignmentService.OwnConsignmentGetPaperEnvelopePreviewPath
    );

    return this.fetchBinaryFile(
      consignmentType === ConsignmentType.OWN_PAPER_INTERNAL ?
        internalConsignmentEnvelopePreviewPath :
        paperConsignmentEnvelopePreviewPath,
      {
        format: 'SVG'
      },
      'post',
      SKIP_ERROR_DIALOG,
      previewRequest,
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchSheetLabelPrintPreview(
    format: 'PDF' | 'SVG' | 'PS',
    printRequest: SheetLabelPrintRequestDto
  ): Observable<ArrayBuffer> {
    return this.fetchBinaryFile(
      ApiOwnConsignmentService.OwnConsignmentGetSheetLabelPrintPath,
      {
        format
      },
      'post',
      SKIP_ERROR_DIALOG,
      printRequest,
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchEnvelopePrintPreview(
    format: 'PDF' | 'SVG' | 'PS',
    printRequest: EnvelopePrintRequestDto
  ): Observable<ArrayBuffer> {
    return this.fetchBinaryFile(
      ApiOwnConsignmentService.OwnConsignmentGetEnvelopePrintPath,
      {
        format
      },
      'post',
      SKIP_ERROR_DIALOG,
      printRequest,
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchClassificationSchemeExport(id: number, type: ExportType): Observable<ArrayBuffer> {
    return this.fetchBinaryFile(
      ApiClassificationSchemeService.ClassificationSchemeExportXmlPath,
      {
        id: id.toString(),
        type
      },
      'get',
      SKIP_ERROR_DIALOG,
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchBulkPostingFormExport(id: number): Observable<ArrayBuffer> {
    return this.fetchBinaryFile(
      ApiBulkPostingFormService.BulkPostingFormExportPath,
      {
        id: id.toString(),
      },
      'post'
    ).pipe(
      pluckBuffer(),
    );
  }

  fetchDigitalComponentVersion(id: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiDigitalComponentVersionService.DigitalComponentVersionDownloadSpecificVersionOfFilePath,
      {
        digitalComponentVersionId: id.toString(),
      },
    );
  }

  fetchDigitalComponentVersionWithinConsignment(consignmentId: number, componentId: number, digitalComponentVersionId: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiConsignmentComponentService.ConsignmentComponentDownloadOwnConsignmentComponentDigitalVersionPath,
      {
        id: consignmentId.toString(),
        componentId: componentId.toString(),
        digitalComponentVersionId: digitalComponentVersionId.toString(),
      },
    );
  }

  fetchDigitalComponentCheckout(id: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiDigitalComponentService.DigitalComponentCheckoutPath,
      {
        id: id.toString(),
      },
      'post'
    );
  }

  fetchEpdzAttachment(attachmentIdentifier: EpdzAttachmentIdentifier): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiReceivedDigitalConsignmentService.ReceivedDigitalConsignmentDownloadEpdzAttachmentContentPath,
      attachmentIdentifier as unknown as Record<string, number>
    );
  }

  fetchPaperComponentDigitalRendition(id: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiPaperComponentService.PaperComponentDownloadDigitalRenditionPath,
      {
        paperComponentId: id
      },
    );
  }

  fetchReceivedConsignmentPdfProofOfDelivery(id: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiReceivedPaperConsignmentService.ReceivedPaperConsignmentGetPdfProofOfDeliveryPath,
      {
        id
      },
    );
  }

  fetchPaperAuthConversionClause(digitalComponentId: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiDigitalComponentService.DigitalComponentDownloadAuthConversionClausePath,
      {
        digitalComponentId
      },
    );
  }

  fetchDigitalAuthConversionClause(digitalComponentId: number): Observable<BinaryWithCharset> {
    return this.fetchBinaryFile(
      ApiDigitalComponentService.DigitalComponentDownloadAuthConversionClausePath,
      {
        digitalComponentId
      },
    );
  }

  fetchErmsBatchContentXml(batch: ErmsBatchDto): Observable<BinaryWithCharset> {
    let path = '';
    let direction = ErmsAuditDirection.INCOMING;
    if ((batch as ErmsAuditReceivedBatchDto)?.direction === ErmsAuditDirection.INCOMING) {
      direction = ErmsAuditDirection.INCOMING;
    }
    else if ((batch as ErmsAuditOutgoingBatchDto)?.direction === ErmsAuditDirection.OUTGOING) {
      direction = ErmsAuditDirection.OUTGOING;
    }
    if (direction === ErmsAuditDirection.OUTGOING) {
      path = ApiErmsAuditService.ErmsAuditReadOutgoingBatchContentPath;
    }
    else {
      path = ApiErmsAuditService.ErmsAuditReadReceivedBatchContentPath;
    }

    return this.fetchBinaryFile(
      path,
      {
        id: batch.id
      },
    );
  }

}
