import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {ApiRelatedObjectService} from '|api/subject-register';

@Component({
  selector: 'icz-subject-technical-info',
  templateUrl: './subject-technical-info.component.html',
  styleUrls: ['./subject-technical-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectTechnicalInfoComponent implements OnInit {

  protected apiRelatedObjectService = inject(ApiRelatedObjectService);

  @Input({required: true}) subject!: SubjectRecordDto;

  subjectRelationsCount = 0;

  ngOnInit() {
    if (this.subject?.id) {
      this.apiRelatedObjectService.relatedObjectGetRelationsCount({subjectId: this.subject.id!}).subscribe(count =>
        this.subjectRelationsCount = count);
    }
  }

}
