@if (deliveryServiceCombination) {
  <div class="icz-body-1 col gap-12">
    <div>
      <div class="icz-body-strong">{{ deliveryServiceCombination.name }}</div>
    </div>
    @if (deliveryServiceCombination.basicService) {
      <div>
        <div class="icz-body-strong">{{ 'Základní služby' | translate }}</div>
        <div>{{ deliveryServiceCombination.basicService.name }}</div>
      </div>
    }
    @if (deliveryServiceCombination.additionalServices?.length) {
      <div>
        <div class="icz-body-strong">{{ 'Doplňkové služby' | translate }}</div>
        @for (additionalService of deliveryServiceCombination.additionalServices; track additionalService.id) {
          <div>
            {{ additionalServiceEpa(additionalService) | translate }} - {{additionalService.name }}
          </div>
        }
      </div>
    }
    @if (epaPpaService.calculateEpaPa(deliveryServiceCombination, 'epa'); as epa) {
      <div>
        <div class="icz-body-strong">{{ 'Zkratka ePA' | translate }}</div>
        <div>{{ epa | translate }}</div>
      </div>
    }
    @if (epaPpaService.calculateEpaPa(deliveryServiceCombination, 'pa'); as pa) {
      <div>
        <div class="icz-body-strong">{{ 'Zkratka PA' | translate }}</div>
        <div>{{ pa | translate }}</div>
      </div>
    }
  </div>
}
