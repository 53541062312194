<div class="row-group">
  <icz-button
    class="mode-switch grow"
    [class.active]="selectionMode === NumberFilterSelectionMode.SINGLE_VALUE"
    label="Zadat hodnotu"
    (onAction)="switchToSingleValueMode()"
  ></icz-button>
  <icz-button
    class="mode-switch grow"
    [class.active]="selectionMode === NumberFilterSelectionMode.INTERVAL"
    [disabled]="isIntervalSwitchDisabled"
    label="Zadat interval"
    (onAction)="switchToIntervalMode()"
  ></icz-button>
</div>
<div class="row" [formGroup]="form">
  @if (selectionMode === NumberFilterSelectionMode.INTERVAL) {
    <div class="row mt-16">
      <icz-form-field
        class="grow"
        type="decimal"
        formControlName="from"
        iczAutoFocus="deffered"
        >
        <span prefix>{{'Od:' | translate}}&nbsp;</span>
      </icz-form-field>
      <icz-form-field
        class="grow"
        type="decimal"
        formControlName="to"
        >
        <span prefix>{{'Do:' | translate}}&nbsp;</span>
      </icz-form-field>
      <icz-button
        primary
        class="apply-interval"
        (onAction)="useIntervalValues()"
        label="Použít"
      ></icz-button>
    </div>
  }
  @else {
    <icz-form-field
      class="grow mt-16"
      type="decimal"
      formControlName="value"
      iczAutoFocus="deffered"
    ></icz-form-field>
  }
</div>
