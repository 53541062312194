<div class="icz-workspace p-0 w-full">
  <div class="row mx-auto gap-64">
    <div class="col">
      <icz-heading label="Aplikace není dostupná"></icz-heading>
      <p class="m-0">{{ 'fe.ui.applicationUnavailableText' | translate }}</p>
      <icz-button
        class="inline-block mt-32"
        primary
        (onAction)="refreshApplication()"
        label="Aktualizovat ručně">
      </icz-button>
    </div>
  </div>
</div>
