import {ButtonDisablerFn, Guard} from '../../../essentials/button-collection/button-collection.component';
import {FileDto} from '|api/document';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {FileOrProfileDtoWithAuthorization} from '../../model/dto-with-permissions.interface';
import {FileOperationValidators} from './file-operation-validators';
import {CommonToolbarDisablers} from './toolbar-common.disablers';

export class FileToolbarDisablers {

  @Guard()
  static selectedItemIsNotFile(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.selectedItemIsNotFile());
    };
  }

  static isSomeFileNotSettled(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileNotSettled());
    };
  }

  static isSomeFileDeactivated(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileDeactivated());
    };
  }

  static isSomeFileClosed(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileClosed());
    };
  }

  static isSomeFileNotClosed(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileNotClosed());
    };
  }

  static isSomeFileNotOpen(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileNotOpen());
    };
  }

  static isFileInStorageUnit(selection: FileOrProfileDtoWithAuthorization[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileInStorageUnit());
    };
  }

  static isSomeFileDeactivetePreconditionNotMet(
    currentSessionService: CurrentSessionService,
    organizationalStructureService: OrganizationalStructureService,
    selection: FileOrProfileDtoWithAuthorization[]
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.isFileDeactivetePreconditionNotMet(currentSessionService, organizationalStructureService));
    };
  }

  static fileHandoverToExternalApplicationPreconditionNotMet(
    selection: FileDto[],
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, FileOperationValidators.fileHandoverToExternalApplicationPreconditionNotMet());
    };
  }
}
