import {Component, Input} from '@angular/core';
import {SelectLikeField} from '../select-like-field';
import {TreeItemSelectionStrategy} from '../tree-view/tree-view.component';
import {getOptionsPathToRoot, getOptionsSubtree, Option} from '../../../model';

@Component({
  selector: 'icz-form-tree-selector',
  templateUrl: './form-tree-selector.component.html',
  styleUrls: ['./form-tree-selector.component.scss']
})
export class FormTreeSelectorComponent<T extends string|number = string|number> extends SelectLikeField<T> {
  @Input()
  selectionStrategy = TreeItemSelectionStrategy.SIMPLE;
  @Input()
  autoSizeMax = 1;
  @Input()
  containerHeight: Nullable<number>;

  // Override
  protected override optionDeselector(elementToRemove: Option<T>) {
    this.__value = FormTreeSelectorComponent.deselectOption(this.__value, elementToRemove, this.selectionStrategy);
  }

  static override deselectOption<T>(options: Array<Option<Nullable<T>>>, optionToDeselect: Option<Nullable<T>>, selectionStrategy: TreeItemSelectionStrategy): Array<Option<Nullable<T>>> {
    if (selectionStrategy === TreeItemSelectionStrategy.BULK) {
      let result: Array<Option<Nullable<T>>> = options;

      const subtree = getOptionsSubtree(options, optionToDeselect.value);
      // If there were selected inner tree nodes and we unselected some of its descendants, deselect that
      // inner tree node as well because its checked state signifies that there are ALL descendants selected.
      const pathToRoot = getOptionsPathToRoot(options, optionToDeselect.value);

      // factor out only selected options
      const uniqueOptionsToRemove = [...new Set([...subtree, ...pathToRoot])]
        .filter(o => options.find(v => o && v.value === o.value));

      for (const optionToRemove of uniqueOptionsToRemove) {
        result = SelectLikeField.deselectOption(result, optionToRemove!);
      }

      return result;
    }
    else { // SIMPLE, HYBRID
      return SelectLikeField.deselectOption(options, optionToDeselect);
    }
  }

}
