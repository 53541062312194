<div class="col grow items-center">
  <div class="logo-container">
    <icz-icon svgIcon="logo_big_bglight"></icz-icon>
  </div>

  <icz-environment-info></icz-environment-info>
  @if (placeOptions.length) {
    <div class="place-form-container">
      <div class="col gap-8">
        <icz-heading level="2" label="Vyberte zastupující místo"></icz-heading>
        <div>
          <ng-template #optionTemplate let-context>
            <div class="option row grow">
              <div class="place-option" data-cy="functional-place">
                <div class="row gap-0 grow">
                  <icz-user-initials
                    class="ml-8"
                    [initials]="getUserInitials(context.option.data.userInitials)"
                    >
                  </icz-user-initials>
                  <div class="place-description">
                    <div class="w-full">
                      @if (context.option.data.holderName) {
                        <div class="truncate">
                          {{context.option.data.holderName}}
                        </div>
                      }
                      <div class="truncate">
                        @if (context.option.data.code) {
                          {{context.option.data.code}} -
                          }{{context.option.data.name}}
                        </div>
                      </div>
                    </div>
                  </div>
                  @if (context.option.data.isSubstituted) {
                    <div class="substitution-date">
                      <span>{{substitutionDateToText(context.option.data.substitutionTo)}}</span>
                    </div>
                  }
                </div>
              </div>
            </ng-template>
            <icz-form-autocomplete
              iczAutoFocus="deffered"
              [customOptionTemplate]="optionTemplate"
              [options]="placeOptions"
              [asPopover]="false"
              (valueChange)="select($event)">
            </icz-form-autocomplete>
          </div>
        </div>
      </div>
    }

    <icz-version-info></icz-version-info>
  </div>
