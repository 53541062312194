import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {OvmType, SubjectRecordDto} from '|api/commons';
import {UserInitialsColor} from '../../../essentials/user-initials/user-initials.model';


@Component({
  selector: 'icz-subject-initials',
  templateUrl: './subject-initials.component.html',
  styleUrls: ['./subject-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectInitialsComponent {

  @Input({required: true})
  subject!: SubjectRecordDto;

  protected readonly OvmType = OvmType;
  protected readonly UserInitialsColor = UserInitialsColor;
}
