export interface TabItem<I = string | number> {
  id: I;
  label: string;
  tooltip?: Nullable<string>;
  showCount?: Nullable<boolean>;
  count?: Nullable<number>;
  disabled?: Nullable<boolean>;
  icon?: Nullable<string>;
  showTabValidity?: Nullable<boolean>;
  valid?: Nullable<boolean>;
  isInExtendedTabs?: Nullable<boolean>;
  isHidden?: Nullable<boolean>;
  loading?: Nullable<boolean>;
}

export interface TabItemWithPriority<I = string | number> extends TabItem<I> {
  priority: TabPriority;
}

export type TabsSize = 'small' | 'default';

export enum TabPriority {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  HIGHEST = 'HIGHEST',
}
