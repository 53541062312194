import {AfterViewInit, Component, DestroyRef, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import {MatPaginator, PageEvent} from '@angular/material/paginator';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface PaginatorRange {
  indexStart: number;
  indexEnd: number;
  length: number;
}

const PAGINATOR_PAGE_BUTTONS_COUNT = 4;

@Component({
  selector: 'icz-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements AfterViewInit, IczOnChanges {

  private destroyRef = inject(DestroyRef);

  @ViewChild(MatPaginator, {static: true})
  paginator!: MatPaginator;

  @Input({required: true})
  length: Nullable<number> = 1;
  @Input({required: true})
  pageSize = 1;
  @Input({required: true})
  pageIndex = 0;

  @Output()
  pageChanged = new EventEmitter<PageEvent>();
  @Output()
  rangeChanged = new EventEmitter<PaginatorRange>();

  availablePages: number[] = [];

  get numberOfPages() {
    return Math.ceil((this.length ?? 0) / this.pageSize);
  }

  get isCollectionPaginable() {
    return this.numberOfPages > 1;
  }

  get currentPageNumber() {
    return this.pageIndex + 1;
  }

  get pageRange(): PaginatorRange {
    return this.getPageRange(this.pageIndex, this.pageSize, this.length!);
  }

  private getPageRange(page: number, pageSize: number, length: number): PaginatorRange {
    if (length === 0 || pageSize === 0) {
      return {indexStart: 0, indexEnd: length, length};
    }

    length = Math.max(length, 0);

    const indexStart: number = page * pageSize;
    const indexEnd: number = indexStart < length ? Math.min(indexStart + pageSize, length) : indexStart + pageSize;

    return {indexStart: indexStart + 1, indexEnd, length};
  }

  setPage(pageNumber: number) {
    const maxPages = Math.ceil(this.paginator.length / this.paginator.pageSize);

    if (pageNumber <= 0) pageNumber = 1;

    if (pageNumber > 1 && pageNumber > maxPages) pageNumber = maxPages;

    if (this.paginator.pageIndex !== pageNumber - 1) {
      this.paginator.pageIndex = pageNumber - 1;
      this.paginator.page.next({
        pageIndex: pageNumber - 1,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (!changes.length?.firstChange) {
      this.rangeChanged.emit(this.pageRange);
      this.availablePages = this.getAvailablePagesOptions();
    }
  }

  ngAfterViewInit() {
    this.rangeChanged.emit(this.pageRange);
    this.paginator.page.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(page => {
      this.availablePages = this.getAvailablePagesOptions();
      this.pageChanged.emit(page);
    });

    this.availablePages = this.getAvailablePagesOptions();
  }

  private getAvailablePagesOptions() {
    const effectivePageButtonsCount = Math.min(PAGINATOR_PAGE_BUTTONS_COUNT, this.numberOfPages);
    let lowerPagesCount: number;

    if (this.currentPageNumber === 1) {
      lowerPagesCount = 0;
    }
    else if (this.currentPageNumber >= this.numberOfPages) {
      lowerPagesCount = effectivePageButtonsCount - 1;
    }
    else if (this.currentPageNumber === this.numberOfPages - 1) {
      lowerPagesCount = effectivePageButtonsCount - 2;
    }
    else {
      lowerPagesCount = Math.min(this.paginator.pageIndex, 2);
    }

    const out: number[] = [];

    let i = this.currentPageNumber - lowerPagesCount;

    while (out.length < effectivePageButtonsCount) {
      out.push(i);
      ++i;
    }

    return out;
  }

}
