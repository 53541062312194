<icz-popover [isOpen]="isOpened" [overlayOrigin]="popoverOrigin" [allowHorizontalPlacement]="true" (onClose)="closePopup()">
  @if (isOpened) {
    <form [formGroup]="form">
      <div class="popover" [ngClass]="{'popover--wide': isFullPermissionListVisible}">
        <div class="popover__header">
          <icz-form-inline-select class="w-full" formControlName="revoking" [options]="revokeOptions" (valueChange)="revokingChange()"></icz-form-inline-select>
        </div>
        <div class="popover__body">
          @if (isFullPermissionListVisible) {
            <form [formGroup]="permissionsFormGroup">
              @for (permissionListSettings of fullPermissionListSettings; track permissionListSettings.label) {
                <span class="px-16 mt-16 label">{{ permissionListSettings.label | translate }}</span>
                <div class="permission-list permission-list--double-col">
                  @for (option of permissionListSettings.options; track option.value) {
                    <div class="permission-list__item">
                      <icz-checkbox class="icz-body-1 grow" [formControlName]="option.value" [label]="option.label"></icz-checkbox>
                      @if (isDocumentEntity ) {
                        <icz-icon [iczTooltip]="'fe.ui.tooltip.documentPermission.' + option.value" size="small" svgIcon="info"></icz-icon>
                      }
                      @if (isFileEntity) {
                        <icz-icon [iczTooltip]="'fe.ui.tooltip.filePermission.' + option.value" size="small" svgIcon="info"></icz-icon>
                      }
                    </div>
                  }
                </div>
              }
            </form>
          }
          @else {
            <icz-radio-group class="permission-list">
              @for (option of shortPermissionOptions; track option.value) {
                <div class="permission-list__item">
                  <icz-radio-button
                    class="icz-body-1 grow"
                    formControlName="permissionSetLevel"
                    [value]="option.value"
                    [label]="option.label"
                  ></icz-radio-button>
                  <icz-icon [iczTooltip]="'fe.ui.tooltip.permission.' + entityType + '.' + option.value" size="small" svgIcon="info"></icz-icon>
                </div>
              }
            </icz-radio-group>
          }
        </div>
        <div class="popover__footer">
          <icz-button [disabled]="isButtonDisabled" (onAction)="applyPermissions()" [primary]="true" label="Použít"></icz-button>
          @if (isFullPermissionListVisible) {
            <span
              class="permission-list-type-switch"
              (click)="hideFullPermissionList()"
              >
              {{ 'Nastavit oprávnění zjednodušeně' | translate }}
            </span>
          }
          @else {
            @if (isAuthorizedEntityTypeWithFineGranedPermissions) {
              <span class="permission-list-type-switch" (click)="showFullPermissionList()">
                {{ 'Nastavit oprávnění podrobně' | translate }}
              </span>
            }
          }
        </div>
      </div>
    </form>
  }
</icz-popover>
