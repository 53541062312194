import {Component, EventEmitter, HostListener, inject, Input, OnInit, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Observable, Subscription} from 'rxjs';
import {CodebookService} from '../../../../core/services/codebook.service';
import {enumToOptions} from '../../../../core/services/data-mapping.utils';
import {Option} from '../../../../model';
import {
  AddressAttributeType,
  isAddressForIdentificationFilled,
  isCzSkAddressPartiallyFilled
} from '../../../../services/subjects.service';
import {IczOnChanges, IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {AddressCompleteDto, AddressFormat,} from '../../model/addresses.model';
import {IczFormArray, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {CzechAddressDto, HouseNumberType} from '|api/commons';
import {mapCountries} from '../../model/subjects.model';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {AddressIdentificationService, AddressIdentificationStatus} from './address-identification.service';
import {debounceTime, startWith} from 'rxjs/operators';
import {cloneDeep, isEqual} from 'lodash';
import {
  AbstractSubjectVerifiableAttributeComponent
} from '../subject-single-attribute/abstract-subject-verifiable-attribute.component';
import {SubjectAttributeType} from '../../model/subject-attribute-type';

export enum AddressComponentFormatType {
  CZ = 'CZ',
  SK = 'SK',
  INT = 'INT',
  PO_BOX = 'PO_BOX',
}

export const ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP = 'Pro ověření adresy vyplňte alespoň ulici, obec, nebo PSČ';

@Component({
  selector: 'icz-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent extends AbstractSubjectVerifiableAttributeComponent implements OnInit, IczOnChanges {

  loadingService = inject(LoadingIndicatorService);
  addressIdentificationService = inject(AddressIdentificationService);
  private codebookService = inject(CodebookService);

  @HostListener('keydown', ['$event'])
  addressChangedByUser(event: KeyboardEvent) {
    // either the user changed the value by adding a letter/number or deleted some parts of the value
    if (event.key.length === 1 || event.key === 'Backspace' || event.key === 'Delete') {
      this.addressIdentificationStatus = AddressIdentificationStatus.UNKNOWN;

      if (this.singleAttributeForm.value.value._Class === AddressFormat.CzechAddressDto) {
        this.valueForm.get(AddressFormat.CzechAddressDto)!.get('id')!.setValue(null, {emitEvent: false});
      }
    }
  }

  countriesOptions$: Nullable<Observable<Option<string>[]>> = this.codebookService.countries().pipe(mapCountries());

  @Input() addressAttributeType: Nullable<AddressAttributeType>; // Residential / mailing / additional
  @Input() wrapInSection = true;
  @Input() boxed = false;
  @Input() label!: string;
  @Input() showSectionExpansionHint = true;
  @Input() isSearchMode = false;
  @Input() initExpanded = true;
  @Input() isReadonly = false;
  @Input() disableAllValidators = false;
  @Input() enforceValidators = false;
  @Input() showSearchBox = true;
  @Input() withAddressValidationButton = false;
  @Input() withAddressTypeSelection = true;
  @Output() addressValidityForIdentificationChanged = new EventEmitter<boolean>();
  @Output() deleteAddress = new EventEmitter<void>();
  @Output() addressChanged = new EventEmitter<AddressCompleteDto>();

  oldRawValue: Record<string, any> = {};

  get czechAddressControl(): IczFormGroup {
    return this.valueForm.get(AddressFormat.CzechAddressDto) as IczFormGroup;
  }

  get slovakAddressControl(): IczFormGroup {
    return this.valueForm.get(AddressFormat.SlovakAddressDto) as IczFormGroup;
  }

  get genericLineAddressControl(): IczFormGroup {
    return this.valueForm.get(AddressFormat.GenericLineAddressDto) as IczFormGroup;
  }

  get postalBoxAddressControl(): IczFormGroup {
    return this.valueForm.get(AddressFormat.PostalBoxAddressDto) as IczFormGroup;
  }

  get isCzechAddress() {
    return this.valueForm.get('_Class')!.value === AddressFormat.CzechAddressDto;
  }

  get isSlovakAddress() {
    return this.valueForm.get('_Class')!.value === AddressFormat.SlovakAddressDto;
  }

  get isGenericLineAddress() {
    return this.valueForm.get('_Class')!.value === AddressFormat.GenericLineAddressDto;
  }

  get isPostalBoxAddress() {
    return this.valueForm.get('_Class')!.value === AddressFormat.PostalBoxAddressDto;
  }

  get addressLinesControl() {
    if (this.isGenericLineAddress) {
      return this.genericLineAddressControl!.get('addressLines') as IczFormArray;
    } else return null;
  }

  get isFormDisabled() {
    if (this.isCzechAddress) {
      return this.valueForm.get(AddressFormat.CzechAddressDto)!.get('street')!.disabled;
    }
    else if (this.isSlovakAddress) {
      return this.valueForm.get(AddressFormat.SlovakAddressDto)!.get('street')!.disabled;
    }
    else if (this.isPostalBoxAddress) {
      return this.valueForm.get(AddressFormat.PostalBoxAddressDto)!.get('box')!.disabled;
    }
    else {
      return this.valueForm.get(AddressFormat.GenericLineAddressDto)!.get('addressLines')!.disabled;
    }
  }

  showAddressTypeOptions = true;
  isAddressValidForIdentification = false;

  readonly AddressFormat = AddressFormat;
  readonly AddressIdentificationStatus = AddressIdentificationStatus;
  readonly ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP = ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP;

  addressFormatTypeOptions = enumToOptions('addressComponentFormatType', AddressComponentFormatType).filter(op => op.value !== AddressComponentFormatType.PO_BOX);
  addressFormatType: Nullable<AddressComponentFormatType>;
  oldAddressFormatType: Nullable<AddressComponentFormatType>;
  addressIdentificationStatus = AddressIdentificationStatus.UNKNOWN;

  addressValidityForIdentificationSubscription: Nullable<Subscription>;

  addressIdentifiedText = 'Kód adresního místa: {{addressPlaceCode}}';

  get addressIdentifiedTextContext() {
    return {
      addressPlaceCode: this.valueForm.value[AddressFormat.CzechAddressDto]?.id,
    };
  }

  get isReadonlyOrVerified() {
    return this.isReadonly || this.isVerified;
  }

  addAddressLine() {
    this.addressLinesControl?.incrementSize();
  }

  removeAddressLine($event: Event, index: number) {
    $event.stopPropagation();
    this.addressLinesControl?.removeAt(index);
  }

  private disableForm() {
    this.singleAttributeForm.disable();
    this.showAddressTypeOptions = false;
  }

  private enableForm() {
    this.singleAttributeForm.enable();
    this.showAddressTypeOptions = true;
  }

  override ngOnChanges(changes: IczSimpleChanges<this>) {
    super.ngOnChanges(changes);

    if (changes) {
      if (this.isReadonlyOrVerified) {
        this.disableForm();
      } else {
        this.enableForm();
      }
    }

    if (changes.addressAttributeType?.currentValue) {
      if (!changes.addressAttributeType.firstChange) {
        if (changes.addressAttributeType.currentValue === SubjectAttributeType.POSTAL_BOX) {
          this.addressFormatType = AddressComponentFormatType.PO_BOX;
          this.showAddressTypeOptions = false;
          this.onAddressComponentFormatType(this.addressFormatType, false);
        }
        else {
          this.addressFormatType = this.oldAddressFormatType;
          this.onAddressComponentFormatType(AddressComponentFormatType.CZ);
        }
      }

      this.resolveValidators();
    }
    if (changes.singleAttributeForm) {
      this.initAddressValidityForIdentificationChecks();
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    // this prevents "Expression has changed after it was checked error" caused by form updating its validity status
    setTimeout(() => {
      const classFormValue = this.valueForm.get('_Class')!.value;
      if (this.addressAttributeType === SubjectAttributeType.POSTAL_BOX) {
        this.onAddressComponentFormatType(AddressComponentFormatType.PO_BOX);
        this.addressFormatType = AddressComponentFormatType.PO_BOX;
      } else if (classFormValue === AddressFormat.CzechAddressDto) {
        this.onAddressComponentFormatType(AddressComponentFormatType.CZ);
        this.addressFormatType = AddressComponentFormatType.CZ;
      } else if (classFormValue === AddressFormat.SlovakAddressDto) {
        this.onAddressComponentFormatType(AddressComponentFormatType.SK);
        this.addressFormatType = AddressComponentFormatType.SK;
      } else {
        this.onAddressComponentFormatType(AddressComponentFormatType.INT);
        this.addressFormatType = AddressComponentFormatType.INT;
      }
      this.resolveValidators();

      const formValue = this.valueForm.getRawValue();

      if (formValue._Class === AddressFormat.CzechAddressDto && formValue[AddressFormat.CzechAddressDto].id) {
        this.addressIdentificationStatus = AddressIdentificationStatus.IDENTIFIED;
      }

      this.oldRawValue = cloneDeep(formValue);
      this.valueForm.valueChanges.pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef)).subscribe(formValue => {
        if (!isEqual(this.oldRawValue, formValue)) {
          this.resolveValidators();
          this.addressChanged.emit(formValue);
          this.oldRawValue = cloneDeep(formValue);
        }
      });
      this.initAddressValidityForIdentificationChecks();
    }, 0);
  }

  resolveValidators() {
    const houseNumberValidators = [IczValidators.required(), IczValidators.isStringifiedInteger(), IczValidators.min(1)];
    const orientationNumberValidators = [IczValidators.orientationNumber()];
    const registrationNumberValidators = [IczValidators.isStringifiedInteger(), IczValidators.min(1)];

    const addCzSkValidators = (czOrSkControl: IczFormGroup) => {
      czOrSkControl.get('city')?.addValidators([IczValidators.required()]);
      czOrSkControl.get('postalCode')?.addValidators([IczValidators.required(), IczValidators.postalCode(false)]);
      czOrSkControl.get('houseNumber')?.addValidators(houseNumberValidators);
      czOrSkControl.get('orientationNumber')?.addValidators(orientationNumberValidators);
      czOrSkControl.get('registrationNumber')?.addValidators(registrationNumberValidators);
    };
    const removeCzSkValidators = (czOrSkControl: IczFormGroup) => {
      czOrSkControl.get('city')?.clearValidators();
      czOrSkControl.get('postalCode')?.clearValidators();
      czOrSkControl.get('houseNumber')?.clearValidators();
      czOrSkControl.get('orientationNumber')?.clearValidators();
      czOrSkControl.get('registrationNumber')?.clearValidators();
    };
    const addPostalBoxValidators = () => {
      this.postalBoxAddressControl.get('box')?.addValidators([IczValidators.required()]);
      this.postalBoxAddressControl.get('postOffice')?.addValidators([IczValidators.required()]);
      this.postalBoxAddressControl.get('postalCode')?.addValidators([IczValidators.required()]);
      this.postalBoxAddressControl.get('postalCode')?.addValidators([IczValidators.postalCode(false)]);
    };
    const removePostalBoxValidators = () => {
      this.postalBoxAddressControl.get('box')?.clearValidators();
      this.postalBoxAddressControl.get('postOffice')?.clearValidators();
      this.postalBoxAddressControl.get('postalCode')?.clearValidators();
    };
    const addGenericLineValidators = (addressLinesControl: Nullable<IczFormArray>) => {
      addressLinesControl?.controls?.[0].get('line')?.addValidators([IczValidators.required()]);
    };
    const removeGenericLineValidators = (addressLinesControl: Nullable<IczFormArray>) => {
      addressLinesControl?.controls?.[0].get('line')?.clearValidators();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const addRegNumAndHouseNumValidators = (czOrSkControl: IczFormGroup) => {
      const registrationNumber = czOrSkControl.get('registrationNumber')!;
      const houseNumber = czOrSkControl.get('houseNumber')!;
      const orientationNumber = czOrSkControl.get('orientationNumber')!;

      if (registrationNumber.value && !houseNumber.value) {
        registrationNumber.setValidators(registrationNumberValidators);
        houseNumber.disable();
        orientationNumber.disable();
        orientationNumber.setValue(null);
        houseNumber.clearValidators();
      } else if (!registrationNumber.value && houseNumber.value) {
        houseNumber.setValidators(houseNumberValidators);
        if (!this.isReadonlyOrVerified) {
          orientationNumber.enable();
        }
        registrationNumber.disable();
        registrationNumber.clearValidators();
      } else {
        if (!this.isReadonlyOrVerified) {
          houseNumber.enable();
          registrationNumber.enable();
          orientationNumber.enable();
        }
        houseNumber.setValidators(houseNumberValidators);
        registrationNumber.setValidators(registrationNumberValidators);
      }
    };

    if (this.disableAllValidators) {
      removeCzSkValidators(this.czechAddressControl);
      removeCzSkValidators(this.slovakAddressControl);
      removePostalBoxValidators();
      return;
    }

    if ((this.isCzechAddress) && (isCzSkAddressPartiallyFilled(this.czechAddressControl) || this.enforceValidators)) {
      addCzSkValidators(this.czechAddressControl);
      addRegNumAndHouseNumValidators(this.czechAddressControl);
      removeCzSkValidators(this.slovakAddressControl);
    } else if ((this.isSlovakAddress) && (isCzSkAddressPartiallyFilled(this.slovakAddressControl) || this.enforceValidators)) {
      addCzSkValidators(this.slovakAddressControl);
      addRegNumAndHouseNumValidators(this.slovakAddressControl);
      removeCzSkValidators(this.czechAddressControl);
    } else {
      removeCzSkValidators(this.czechAddressControl);
      removeCzSkValidators(this.slovakAddressControl);
    }

    if (this.isPostalBoxAddress && (isCzSkAddressPartiallyFilled(this.postalBoxAddressControl) || this.enforceValidators)) {
      addPostalBoxValidators();
    } else {
      removePostalBoxValidators();
    }

    if (this.isGenericLineAddress || this.enforceValidators) {
      addGenericLineValidators(this.addressLinesControl);
    } else {
      removeGenericLineValidators(this.addressLinesControl);
    }

    this.czechAddressControl.recursivelyUpdateValueAndValidity({onlySelf: false});
    this.slovakAddressControl.recursivelyUpdateValueAndValidity({onlySelf: false});
    this.postalBoxAddressControl.recursivelyUpdateValueAndValidity({onlySelf: false});
    this.genericLineAddressControl.recursivelyUpdateValueAndValidity({onlySelf: false});
  }

  onAddressComponentFormatType(formatType: Nullable<AddressComponentFormatType>, markDirty = false) {
    if (formatType === AddressComponentFormatType.CZ) {
      this.valueForm.get('_Class')!.setValue(AddressFormat.CzechAddressDto);
      this.valueForm.get('country')!.setValue('CZE');
    } else if (formatType === AddressComponentFormatType.SK) {
      this.valueForm.get('_Class')!.setValue(AddressFormat.SlovakAddressDto);
      this.valueForm.get('country')!.setValue('SVK');
    } else if (formatType === AddressComponentFormatType.PO_BOX) {
      this.valueForm.get('_Class')!.setValue(AddressFormat.PostalBoxAddressDto);
      if (!this.isReadonlyOrVerified) {
        this.valueForm.get('country')!.enable();
      }
    } else {
      this.valueForm.get('_Class')!.setValue(AddressFormat.GenericLineAddressDto);
      if (!this.isReadonlyOrVerified) {
        this.valueForm.get('country')!.enable();
        this.valueForm.get('_Class')!.enable();
      }
    }
    if (markDirty) this.valueForm.get('country')!.markAsDirty();
    this.singleAttributeForm.recursivelyUpdateValueAndValidity();
  }

  identifyAddress() {
    this.addressIdentificationService.identifyAddress(this.valueForm).subscribe(identificationResult => {
      this.addressIdentificationStatus = identificationResult.status;

      if (identificationResult.address) {
        this.patchFormWithIdentifiedAddress(identificationResult.address);
      }
    });
  }

  addressSelected(address: CzechAddressDto) {
    this.patchFormWithIdentifiedAddress(address);
    this.addressIdentificationStatus = AddressIdentificationStatus.IDENTIFIED;
    this.singleAttributeForm.updateValueAndValidity();
  }

  private patchFormWithIdentifiedAddress(address: CzechAddressDto) {
    this.valueForm.get(AddressFormat.CzechAddressDto)!.setValue(
      {
        city: address.city || null,
        district: address.district || null,
        pragueDistrict: address.pragueDistrict || null,
        houseNumber: address.houseNumberType === HouseNumberType.STREET_NUMBER ? (address.houseNumber || null) : null,
        orientationNumber: address.orientationNumber || null,
        orientationNumberLastCharacter: address.orientationNumberLastCharacter || null,
        registrationNumber: address.houseNumberType === HouseNumberType.REGISTRATION_NUMBER ? (address.houseNumber || null) : null,
        id: address.id || null,
        postalCode: address.postalCode || null,
        postalOffice: address.postalOffice || null,
        street: address.street || null,
        houseNumberType: address.houseNumberType || null,
      },
      {
        emitEvent: false,
      }
    );
  }

  private initAddressValidityForIdentificationChecks() {
    this.addressValidityForIdentificationSubscription?.unsubscribe();
    this.addressValidityForIdentificationSubscription = this.valueForm.valueChanges.pipe(
      startWith(this.valueForm.value),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(formValue => {
      const addressClass = formValue._Class;

      this.isAddressValidForIdentification = isAddressForIdentificationFilled(
        {
          _Class: addressClass,
          ...formValue[addressClass],
        },
      );
      this.addressValidityForIdentificationChanged.emit(this.isAddressValidForIdentification);
    });
  }
}
