/* tslint:disable */
/* eslint-disable */

/**
 * Delivery result event type
 */
export enum DeliveryResultEventType {
  EV_0 = 'EV0',
  EV_1 = 'EV1',
  EV_2 = 'EV2',
  EV_3 = 'EV3',
  EV_4 = 'EV4',
  EV_5 = 'EV5',
  EV_6 = 'EV6',
  EV_7 = 'EV7',
  EV_8 = 'EV8',
  EV_9 = 'EV9',
  EV_10 = 'EV10',
  EV_11 = 'EV11',
  EV_12 = 'EV12',
  EV_13 = 'EV13',
  ONTIME = 'ONTIME',
  EARLY = 'EARLY'
}
