/* tslint:disable */
/* eslint-disable */

/**
 * Delivery result confirmation form
 */
export enum DeliveryResultConfirmationForm {
  ARTIFACT = 'ARTIFACT',
  DOCUMENT = 'DOCUMENT',
  ARTIFACT_OR_DOCUMENT = 'ARTIFACT_OR_DOCUMENT'
}
