import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {convertValueToDateObject} from './date.pipe';
import {ApplicationLanguage} from '../../core/services/environment.models';

export function iczFormatTime(
  currentLocale: ApplicationLanguage,
  value: Nullable<number>,
  ignoreTimezoneOffset?: boolean,
): string {
  if (!Number.isNaN(Number(value))) {
    const parsedValue = Number(value);

    if (parsedValue < 0 || parsedValue >= 86400000) {
      throw new Error(`LocalizedTimePipe value "${parsedValue}" out of bounds <0, 86400000).`);
    }
  }

  const convertedValue = convertValueToDateObject(value, ignoreTimezoneOffset);

  if (currentLocale === ApplicationLanguage.CZECH || currentLocale === ApplicationLanguage.SLOVAK) {
    return stripSecondsFromCzechDateTime(convertedValue?.toLocaleTimeString('cs-CZ') ?? '');
  }
  else {
    return stripSecondsFromEnglishDateTime(convertedValue?.toLocaleTimeString('en-US') ?? '');
  }
}

export function stripSecondsFromCzechDateTime(stringWithTime: string): string {
  return stringWithTime.replace(/:\d\d$/g, '');
}

export function stripSecondsFromEnglishDateTime(stringWithTime: string): string {
  return stringWithTime.replace(',', '').replace(/:\d\d (AM|PM)$/g, ' $1');
}

/**
 * Strips time part of date.
 * @param date date object
 * @param currentLocale locale to apply
 * @param ignoreTimezoneOffset ignore timezone offset
 * @return time part of date formated in hh:mm
 */
export function getTimePartFromDate(date: Date, currentLocale: ApplicationLanguage, ignoreTimezoneOffset?: boolean,) {
  const convertedValue = convertValueToDateObject(date.getTime(), ignoreTimezoneOffset);
  if (currentLocale === ApplicationLanguage.CZECH || currentLocale === ApplicationLanguage.SLOVAK) {
    return (convertedValue?.toLocaleTimeString('cs-CZ', {hour: '2-digit', minute: '2-digit'}) ?? '');
  }
  else {
    return (convertedValue?.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'}) ?? '');
  }
}

@Pipe({
  name: 'localizedTime',
  pure: true,
})
export class LocalizedTimePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * @param value - unix timestamp in milliseconds since Epoch
   *              - must be in <1970-01-01T00:00:00.000; 1970-01-01T23:59:59.999) = <0, 86400000)
   * @param ignoreTimezoneOffset - use for formatting wall time strings without timezone specifiers
   */
  transform(value: Nullable<number>, ignoreTimezoneOffset?: boolean): string {
    if (!value) { // Branch used merely for performance optimization
      return '';
    }

    return iczFormatTime(this.translateService.currentLang as ApplicationLanguage, value, ignoreTimezoneOffset);
  }

}
