<icz-validation-errors-list
  [showValidationStatus]="showValidationStatus"
  [control]="control"
>
  <icz-form-autocomplete
    [label]="label"
    [options]="$any(options)"
    [value]="__value"
    [clearable]="false"
    [fieldDisabled]="fieldDisabled"
    (click.stop)="0"
    [customOptionTemplate]="addressOptionTemplate"
    [showValidationStatus]="false"
    [customSelectedOptionTemplate]="addressOptionTemplate"
    (valueChange)="_valueChanged($any($event))"
    (blur)="blur.emit()"
  ></icz-form-autocomplete>
</icz-validation-errors-list>

<ng-template #addressOptionTemplate let-context>
  <icz-address-details
    [withTooltip]="false"
    [withSingleLineAddress]="true"
    [subjectClassification]="subject.classification"
    [addressWithType]="context.option.data.addressWithType"
    [isAddressValid]="context.option.data.addressWithType.isCurrentlyValid"
  ></icz-address-details>
</ng-template>
