/* tslint:disable */
/* eslint-disable */

/**
 * Export type
 */
export enum ExportType {
  ICZ = 'ICZ',
  NSESSS = 'NSESSS',
  NSESSS_TREE = 'NSESSS_TREE'
}
