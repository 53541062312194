import {Component, ElementRef, EventEmitter, HostBinding, inject, Input, Output} from '@angular/core';
import {TableReservedColumns} from '../selected-rows.service';
import {ColumnDefinition} from '../table.models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'icz-table-context-menu',
  templateUrl: './table-context-menu.component.html',
  styleUrls: ['./table-context-menu.component.scss']
})
export class TableContextMenuComponent {

  private el = inject(ElementRef);
  private translateService = inject(TranslateService);

  @Input()
  @HostBinding('style.maxHeight.px')
  heightLimit: Nullable<number>;

  @Input({required: true}) columnDefinitions: ColumnDefinition<string>[] = [];
  @Output() onClose = new EventEmitter<void>();
  @Output() defaultClicked = new EventEmitter<void>();
  @Output() columnToggled = new EventEmitter<ColumnDefinition<string>>();

  get contextMenuContentHeight(): Nullable<number> {
    if (!this.heightLimit) {
      return null;
    }
    else {
      return this.currentHeight >= this.heightLimit ? this.heightLimit : null;
    }
  }

  private get currentHeight(): number {
    return this.el.nativeElement.offsetHeight;
  }

  get columnDefinitionsForContextMenu() {
    return this.columnDefinitions
      .filter(cd => isNil(cd.allowSettingInContextMenu) || cd.allowSettingInContextMenu === true)
      .sort((a, b) => {
        return this.translateService.instant(a.label!).localeCompare(this.translateService.instant(b.label!), this.translateService.currentLang, { sensitivity: 'accent' });
      });
  }

  isToggleableColumn(column: ColumnDefinition<string>) {
    return column.id !== TableReservedColumns.SELECTION && column.toggleable !== false;
  }

}
