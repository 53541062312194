/* eslint-disable @angular-eslint/directive-class-suffix */
import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {PrimitiveValueFormField} from './form-field';
import {IAutocompleteListItemContext, IImplicitTemplateContext} from './form-autocomplete/form-autocomplete.model';
import {isRequired} from './validators/icz-validators/icz-validators';
import {getOptionsByValuesList, locateOptionByValue, Option} from '../../model';
import {hasDuplicates} from '../../lib/utils';

/**
 * An abstract class used as a base for implementing <select>-like form elements.
 * Supports singleselect and multiselect and is resistant to various race
 * conditions such as when options load later than value and vice versa.
 *
 * Responsibilities:
 * - Declaring a basic set of @Inputs and @Outputs for particular subclasses
 * - Converting the internal representation to FormGroup data
 * - Implementing the interface needed by FormFieldValue accessor by defining _valueChange
 */
@Directive()
export abstract class SelectLikeField<T extends string|number> extends PrimitiveValueFormField<T[]|T> {
  @Input({required: true})
  set options(newOptions: Nullable<Option<Nullable<T>, any>[]>) {
    newOptions ??= [];

    if (hasDuplicates(newOptions, o => o.value)) {
      console.warn(
        `[formControlName=${this.formControlName}] Options array contains duplicate option values. ` +
        `Please pass in an array without such duplicates otherwise Autocomplete might not work reliably.`,
        newOptions
      );
    }

    this._options = newOptions;
    this._visibleOptions = newOptions.filter(o => !o.isHidden);

    if (this.isMultiselect) {
      this.__value = this.searchForMultiselectOptions(this._value as T[]);
    }
    else {
      this.__value = this.searchForSingleselectOptions(this._value as T);
    }
    if (this.allowSingleOptionPreselection) {
      this._valueChanged(this.__value);
    }
  }
  get options(): Option<Nullable<T>, any>[] {
    return this._options;
  }
  @Input()
  clearable = true;
  @Input()
  asPopover = true;
  @Input()
  isMultiselect: Nullable<boolean> = false;
  @Input()
  allowSingleOptionPreselection = false;
  @Input()
  searchTermInputPrefix = '';
  @Input()
  originId: Nullable<string>;
  @Input()
  customOptionTemplate: Nullable<TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>>;
  @Input()
  customSelectedOptionTemplate: Nullable<TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>>;
  @Output()
  valueChangeWithOriginIds = new EventEmitter<Nullable<Option<Nullable<T>, any>[] | Option<Nullable<T>, any>>>();

  @Input()
  override set value(newValues: Nullable<T[]|T>) {
    this._value = newValues;

    if (this.options) {
      if (Array.isArray(newValues) && this.isMultiselect) {
        this.__value = this.searchForMultiselectOptions(newValues);
      }
      else if (!Array.isArray(newValues) && newValues === null && this.isMultiselect) {
        this.__value = [];
      }
      else if (!Array.isArray(newValues) && !this.isMultiselect) {
        this.__value = this.searchForSingleselectOptions(newValues);
      }
      else {
        throw new Error(`ComposableFormAutocomplete received an unexpected` +
          `value: ${newValues} for multiselect=${this.isMultiselect}.`);
      }
    }
  }
  override get value(): Nullable<T[]|T> {
    return this._value;
  }

  get required(): boolean {
    return isRequired(this.control);
  }

  _options!: Option<Nullable<T>, any>[];
  // user is able to select/search thru visible options only
  _visibleOptions!: Option<Nullable<T>, any>[];
  // this value is intrinsic array of option objects that
  // get passed to inner components of the autocomplete
  __value: Option<Nullable<T>, any>[] = [];
  // this value is synchronized with the
  // value of associated FormControl object
  override _value: Nullable<T[]|T> = null;
  private _valueWithOriginIds: Nullable<Option<Nullable<T>, any>[] | Option<Nullable<T>, any>> = null;

  override _valueChanged(selectedOptions: Option<Nullable<T>, any>[]) {
    let selectedOptionIds;

    // if originId is present, every option should have its 'id' property.
    if (this.originId) {
      selectedOptionIds = selectedOptions
        .filter(o => {
          if (!o.originId) {
            console.warn(
              `[formControlName=${this.formControlName}] You have passed originId to the component but originIds of your Options are undefined. ` +
              `This is an inconsistent state, if passing @Input originId, every Option should have id AND originId.`
            );

            return false;
          }

          return o.originId === this.originId;
        })
        .map(o => {
          if (!o.id) {
            console.warn(
              `[formControlName=${this.formControlName}] You have passed originId to the component but ids of your Options are undefined. ` +
              `This is an inconsistent state, if passing @Input originId, every Option should have id AND originId.`
            );
          }

          return o.id!;
        }
      );
    }
    else {
      selectedOptionIds = selectedOptions.map(o => o.value!);
    }

    if (!selectedOptionIds.length) {
      this.__value = [];
      this._value = null;
      this._valueWithOriginIds = null;
    }
    else if (selectedOptionIds.length && !this.isMultiselect) {
      this.__value = [selectedOptions[0]];
      this._value = selectedOptionIds[0] as Nullable<T>;
      this._valueWithOriginIds = selectedOptions[0];
    }
    else {
      this.__value = selectedOptions;
      this._value = selectedOptionIds as T[];
      this._valueWithOriginIds = selectedOptions;
    }

    this.emitFieldValue();
  }

  protected emitFieldValue() {
    this.valueChange.emit(this._value);
    this.valueChangeWithOriginIds.emit(this._valueWithOriginIds);
  }

  protected searchForMultiselectOptions(newValues: Nullable<T[]>): Option<Nullable<T>, any>[] {
    if (this.options && newValues) {
      return getOptionsByValuesList(this.options, newValues, this.originId);
    }
    else {
      return [];
    }
  }

  protected searchForSingleselectOptions(newValue: Nullable<T>): Option<Nullable<T>, any>[] {
    if (this.options) {
      if (this.options.length === 1 && isNil(newValue) && isRequired(this.control) && this.allowSingleOptionPreselection) {
        return this.options;
      } else {
        const option = locateOptionByValue(this.options, newValue, this.originId);

        if (!option) {
          if (!isNil(newValue)) {
            // tslint:disable-next-line:no-console
            console.warn(`[formControlName=${this.formControlName}] Model value "${newValue}" is not present in options of the autocomplete.`);
          }

          return [];
        }
        else {
          return [option];
        }
      }
    }
    else {
      return [];
    }
  }

  deselectOption(so: Option<Nullable<T>>) {
    const elementToRemove = this.__value.find(option => option.value === so.value);

    if (elementToRemove) {
      this.optionDeselector(elementToRemove);
      this.__value = [...this.__value];

      this._valueChanged(this.__value);
    }
  }

  /**
   * Template method used for customizing element deselection process.
   * To override when removing a multiselect badge of inner tree node for example.
   */
  protected optionDeselector(optionToDeselect: Option<Nullable<T>, any>) {
    this.__value = SelectLikeField.deselectOption(this.__value, optionToDeselect);
  }

  static deselectOption<T>(options: Option<Nullable<T>, any>[], optionToDeselect: Option<Nullable<T>, any>, ...extraOptions: any[]): Option<Nullable<T>, any>[] {
    return options.filter(o => o.value !== optionToDeselect.value);
  }
}
