import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {Router} from '@angular/router';
import {DocumentDto, FileDto, ReceivedDocumentDto} from '|api/document';
import {ColumnDefinition} from '../../table/table.models';
import {EsslEntity} from '../model/entity.model';
import {ExtendedDigitalComponentCompleteDto} from '../model/elastic-extended-entities.interface';
import {getDetailUrlByEntity, TableEntityView} from '../document-table/documents-table.models';

export interface RefNumberableEntity extends EsslEntity {
  refNumber: Nullable<string>;
}

export type EntityWithRefNumber = RefNumberableEntity & (
  Omit<ReceivedDocumentDto, 'refNumber'> |
  DocumentDto |
  FileDto |
  ExtendedDigitalComponentCompleteDto
);

export enum RegistryOfficeTransferIncidentView {
  OBJECT_INCIDENTS_LISTING = 'OBJECT_INCIDENTS_LISTING',
}

@Component({
  selector: 'icz-ref-number-link',
  templateUrl: './ref-number-link.component.html',
  styleUrls: ['./ref-number-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RefNumberLinkComponent {

  private router = inject(Router);

  @Input({required: true})
  entity!: EntityWithRefNumber;
  @Input()
  columnDefinition: Nullable<ColumnDefinition<string>>;
  @Input()
  searchTermWords: string[] = [];
  @Input()
  view!: Nullable<TableEntityView>;

  openDetail() {
    const detailUri = getDetailUrlByEntity(this.view, this.entity);

    if (detailUri) {
      this.router.navigateByUrl(detailUri);
    }
  }

}
