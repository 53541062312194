@if (disposalScheduleSelectedSig()) {
  <icz-alert class="mb-8" severity="info" [heading]="retentionTriggerAndDisposalScheduleInfoText()">
    <ng-container content>
      @if (disposalScheduleSelectedDual) {
        <a content class="p-4 icz-body-1 visible-clickable-area"
           iczLinkWithoutHref
           (click.stop)="comparatorOpen = true"
           #comparator="cdkOverlayOrigin"
           cdkOverlayOrigin
        >
          <span>{{ 'Zobrazit srovnání skartačních režimů' | translate }}</span>
        </a>
        <icz-popover [overlayOrigin]="comparator" [isOpen]="comparatorOpen" (onClose)="comparatorOpen = false">
          @if (comparatorOpen) {
            <div class="col">
              <div class="row header-separator">
                <icz-label label="Srovnání skartačních režimů" class="grow ml-16"></icz-label>
                <icz-button svgIcon="close" size="small" background (onAction)="comparatorOpen = false" class="mr-16"></icz-button>
              </div>
              <div class="row grow mx-16 mb-16 comparator-size">
                <div class="col grow" [formGroup]="disposalScheduleByEntityClassForm">
                  <icz-section label="Skartační režim věcné skupiny">
                    <icz-form-field [fieldDisabled]="true" formControlName="disposalSchedule" label="Skartační režim"></icz-form-field>
                    @if (selectedEntityClass) {
                      <icz-retention-trigger-type-widget [form]="disposalScheduleByEntityClassForm" [disposalScheduleId]="selectedEntityClass.disposalScheduleId!" [externalRetentionTriggerIds]="[selectedEntityClass.externalRetentionTriggerId!]"></icz-retention-trigger-type-widget>
                    }
                  </icz-section>
                </div>
                <div class="col grow" [formGroup]="disposalScheduleByDocumentTypeForm">
                  <icz-section label="Skartační režim typu dokumentu">
                    <icz-form-field [fieldDisabled]="true" formControlName="disposalSchedule" label="Skartační režim"></icz-form-field>
                    @if (selectedDocumentType) {
                      <icz-retention-trigger-type-widget [form]="disposalScheduleByDocumentTypeForm" [disposalScheduleId]="selectedDocumentType.disposalScheduleId!" [externalRetentionTriggerIds]="[selectedDocumentType.externalRetentionTriggerId!]"></icz-retention-trigger-type-widget>
                    }
                  </icz-section>
                </div>
              </div>
            </div>
          }
        </icz-popover>
      }
    </ng-container>
  </icz-alert>
}
