import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {ApiBulkPostingFormService, BulkPostingFormDto} from '|api/sad';
import {SearchApiService, SearchParams} from './search-api.service';

@Injectable({
  providedIn: 'root'
})
export class BulkPostingFormsSearchService extends SearchApiService {

  findAllBulkPostingForms(searchParams: Partial<SearchParams>): Observable<Page<BulkPostingFormDto>> {
    return this.searchApi<Page<BulkPostingFormDto>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiBulkPostingFormService.BulkPostingFormSearchPath, {}));
  }

}
