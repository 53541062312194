import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ColumnDefinition} from '../table.models';


@Component({
  selector: 'icz-table-header-cell',
  templateUrl: './table-header-cell.component.html',
  styleUrls: ['./table-header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderCellComponent {
  @Input({required: true})
  column: Nullable<Partial<ColumnDefinition<string>>> = {};
  @Input({required: true})
  columnIndex!: number;
  @Input()
  columnWidth: Nullable<string>;

  get textContentWidth(): string {
    if (this.columnWidth) {
      if (this.column?.disableSort) {
        return `calc(${this.columnWidth} - 8px)`;
      } else {
        return `calc(${this.columnWidth} - 20px)`;
      }
    } else {
      return 'auto';
    }
  }
}
