/* tslint:disable */
/* eslint-disable */

/**
 * Flag type
 */
export enum DigitalComponentVersionSignatureFlagType {
  NONE = 'NONE',
  SIGNATURE_VALID = 'SIGNATURE_VALID',
  SIGNATURE_INDETERMINATE = 'SIGNATURE_INDETERMINATE',
  SIGNATURE_INVALID = 'SIGNATURE_INVALID',
  SIGNATURE_WITH_TIMESTAMP_VALID = 'SIGNATURE_WITH_TIMESTAMP_VALID',
  SIGNATURE_WITH_TIMESTAMP_INDETERMINATE = 'SIGNATURE_WITH_TIMESTAMP_INDETERMINATE',
  SIGNATURE_WITH_TIMESTAMP_INVALID = 'SIGNATURE_WITH_TIMESTAMP_INVALID',
  MARK_VALID = 'MARK_VALID',
  MARK_INDETERMINATE = 'MARK_INDETERMINATE',
  MARK_INVALID = 'MARK_INVALID',
  MARK_WITH_TIMESTAMP_VALID = 'MARK_WITH_TIMESTAMP_VALID',
  MARK_WITH_TIMESTAMP_INDETERMINATE = 'MARK_WITH_TIMESTAMP_INDETERMINATE',
  MARK_WITH_TIMESTAMP_INVALID = 'MARK_WITH_TIMESTAMP_INVALID',
  VALIDATION_FAILED = 'VALIDATION_FAILED'
}
