import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {LandingPage} from '../../../enums/shared-routes.enum';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczModalRef} from '../../../services/icz-modal-ref.injectable';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum TaskFilterPeriod {
  ONE_DAY = 'ONE_DAY',
  TWO_DAYS = 'TWO_DAYS'
}


@Component({
  selector: 'icz-dashboard-settings-dialog',
  templateUrl: './dashboard-settings-dialog.component.html',
  styleUrls: ['./dashboard-settings-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardSettingsDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = inject(IczModalRef);
  private userSettingsService = inject(UserSettingsService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private destroyRef = inject(DestroyRef);

  readonly TaskFilterPeriod = TaskFilterPeriod;
  readonly LandingPage = LandingPage;

  form = new IczFormGroup({
    attentionRequired: new IczFormControl<boolean>({value: true, disabled: true}, []),
    notifications: new IczFormControl<boolean>(true, []),
    activities: new IczFormControl<boolean>(true, []),
    myTasks: new IczFormControl<boolean>(true, []),
    userSubstitutions: new IczFormControl<boolean>(true, []),
    fpSubstitutions: new IczFormControl<boolean>(true, []),
    tasksPeriod: new IczFormControl<TaskFilterPeriod>(TaskFilterPeriod.ONE_DAY, []),
    landingPage: new IczFormControl<LandingPage>(LandingPage.DASHBOARD, []),
  });
  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.form.get('myTasks')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        this.form.get('tasksPeriod')!.enable();
      } else {
        this.form.get('tasksPeriod')!.disable();
      }
    });

    const timePeriodSetting = this.userSettingsService.getParsedValue(LocalStorageKey.DASHBOARD_TASK_TIME_PERIOD);
    const landingPageSetting = this.userSettingsService.getParsedValue(LocalStorageKey.USER_LANDING_PAGE);
    const visibleSectionsSetting = this.userSettingsService.getParsedValue(LocalStorageKey.USER_DASHBOARD_SECTIONS);
    this.form.get('tasksPeriod')!.setValue(timePeriodSetting ? timePeriodSetting.timePeriod : TaskFilterPeriod.ONE_DAY);
    this.form.get('landingPage')!.setValue(landingPageSetting ? landingPageSetting.landingPage : LandingPage.DASHBOARD);
    if (visibleSectionsSetting) {
      this.form.get('notifications')!.setValue(visibleSectionsSetting.notifications);
      this.form.get('activities')!.setValue(visibleSectionsSetting.activities);
      this.form.get('myTasks')!.setValue(visibleSectionsSetting.myTasks);
    }

  }

  submit() {
    const formValue = this.form.getRawValue();
    this.userSettingsService.setParsedValue(LocalStorageKey.DASHBOARD_TASK_TIME_PERIOD, {
      timePeriod: formValue.tasksPeriod
    });

    this.userSettingsService.setParsedValue(LocalStorageKey.USER_LANDING_PAGE, {
      landingPage: formValue.landingPage
    });

    this.userSettingsService.setParsedValue(LocalStorageKey.USER_DASHBOARD_SECTIONS, {
      myTasks: formValue.myTasks,
      activities: formValue.activities,
      notifications: formValue.notifications,
      userSubstitutions: formValue.userSubstitutions,
      fpSubstitutions: formValue.fpSubstitutions
    });

    this.form.reset();
    this.close();
  }

  close() {
    this.modalRef.close();
  }
}
