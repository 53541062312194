/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ConfigUserDto } from '../models/config-user-dto';
import { PageConfigUserDto } from '../models/page-config-user-dto';
import { userAddUserToOrgStruct } from '../fn/user/user-add-user-to-org-struct';
import { UserAddUserToOrgStruct$Params } from '../fn/user/user-add-user-to-org-struct';
import { userChangePassword } from '../fn/user/user-change-password';
import { UserChangePassword$Params } from '../fn/user/user-change-password';
import { userCreate } from '../fn/user/user-create';
import { UserCreate$Params } from '../fn/user/user-create';
import { userFind } from '../fn/user/user-find';
import { UserFind$Params } from '../fn/user/user-find';
import { userFindByUsername } from '../fn/user/user-find-by-username';
import { UserFindByUsername$Params } from '../fn/user/user-find-by-username';
import { userGetUserInfo } from '../fn/user/user-get-user-info';
import { UserGetUserInfo$Params } from '../fn/user/user-get-user-info';
import { userSetAddedToOrgStruct } from '../fn/user/user-set-added-to-org-struct';
import { UserSetAddedToOrgStruct$Params } from '../fn/user/user-set-added-to-org-struct';
import { userUpdate } from '../fn/user/user-update';
import { UserUpdate$Params } from '../fn/user/user-update';


/**
 * API for managing authentication users
 */
@Injectable({ providedIn: 'root' })
export class ApiUserService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `userFindByUsername()` */
  static readonly UserFindByUsernamePath = '/user/{username}';

  /**
   * Finds user based on its username
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFindByUsername()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindByUsername$Response(params: UserFindByUsername$Params, context?: HttpContext): Observable<StrictHttpResponse<ConfigUserDto>> {
    return userFindByUsername(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds user based on its username
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFindByUsername$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFindByUsername(params: UserFindByUsername$Params, context?: HttpContext): Observable<ConfigUserDto> {
    return this.userFindByUsername$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigUserDto>): ConfigUserDto => r.body)
    );
  }

  /** Path part for operation `userUpdate()` */
  static readonly UserUpdatePath = '/user/{username}';

  /**
   * Updates existing user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdate$Response(params: UserUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ConfigUserDto>> {
    return userUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates existing user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userUpdate(params: UserUpdate$Params, context?: HttpContext): Observable<ConfigUserDto> {
    return this.userUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigUserDto>): ConfigUserDto => r.body)
    );
  }

  /** Path part for operation `userCreate()` */
  static readonly UserCreatePath = '/user';

  /**
   * Creates new user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreate$Response(params: UserCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ConfigUserDto>> {
    return userCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userCreate(params: UserCreate$Params, context?: HttpContext): Observable<ConfigUserDto> {
    return this.userCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigUserDto>): ConfigUserDto => r.body)
    );
  }

  /** Path part for operation `userSetAddedToOrgStruct()` */
  static readonly UserSetAddedToOrgStructPath = '/user/set-added-to-org-struct';

  /**
   * Adds a timestamp to user whose was added to orgstruct.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userSetAddedToOrgStruct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSetAddedToOrgStruct$Response(params: UserSetAddedToOrgStruct$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userSetAddedToOrgStruct(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds a timestamp to user whose was added to orgstruct.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userSetAddedToOrgStruct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userSetAddedToOrgStruct(params: UserSetAddedToOrgStruct$Params, context?: HttpContext): Observable<void> {
    return this.userSetAddedToOrgStruct$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userChangePassword()` */
  static readonly UserChangePasswordPath = '/user/change-password';

  /**
   * Allows user to change his password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userChangePassword()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChangePassword$Response(params: UserChangePassword$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userChangePassword(this.http, this.rootUrl, params, context);
  }

  /**
   * Allows user to change his password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userChangePassword$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userChangePassword(params: UserChangePassword$Params, context?: HttpContext): Observable<void> {
    return this.userChangePassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userAddUserToOrgStruct()` */
  static readonly UserAddUserToOrgStructPath = '/user/add-config-user-to-org-struct';

  /**
   * Adds a user to the organizational structure.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userAddUserToOrgStruct()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userAddUserToOrgStruct$Response(params: UserAddUserToOrgStruct$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return userAddUserToOrgStruct(this.http, this.rootUrl, params, context);
  }

  /**
   * Adds a user to the organizational structure.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userAddUserToOrgStruct$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  userAddUserToOrgStruct(params: UserAddUserToOrgStruct$Params, context?: HttpContext): Observable<void> {
    return this.userAddUserToOrgStruct$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `userFind()` */
  static readonly UserFindPath = '/user/search-api';

  /**
   * Finds users based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFind$Response(params?: UserFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageConfigUserDto>> {
    return userFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds users based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userFind(params?: UserFind$Params|null|undefined, context?: HttpContext): Observable<PageConfigUserDto> {
    return this.userFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageConfigUserDto>): PageConfigUserDto => r.body)
    );
  }

  /** Path part for operation `userGetUserInfo()` */
  static readonly UserGetUserInfoPath = '/user/info';

  /**
   * Retrieves information about currently logged in user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `userGetUserInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserInfo$Response(params: UserGetUserInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ConfigUserDto>> {
    return userGetUserInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves information about currently logged in user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `userGetUserInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  userGetUserInfo(params: UserGetUserInfo$Params, context?: HttpContext): Observable<ConfigUserDto> {
    return this.userGetUserInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ConfigUserDto>): ConfigUserDto => r.body)
    );
  }

}
