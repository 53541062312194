@if (entity) {
  <div
    class="row grow"
    [class.visible-clickable-area]="isLink"
    [class.cursor-pointer]="isLink"
    [class.p-4]="isLink"
    (click)="navigateToEntityDetail()"
    >
    <div>
      <icz-object-class-icon [objectClass]="objectClass" iconSize="default" class="mt-4 block"></icz-object-class-icon>
    </div>
    <div class="grow">
      @if (isDocumentEntity) {
        <div>{{ ('ČJ' | translate) + ': ' + $any(entity).refNumber }}</div>
      }
      @if (isFileEntity) {
        <div>{{ ('SZ' | translate) + ': ' + $any(entity).refNumber }}</div>
      }
      @if (isStorageUnitEntity) {
        <div>{{ ('Č.UJ' | translate) + ': ' + $any(entity).storageUnitNumber }}</div>
      }
      @if (isDocumentEntity) {
        <div>{{ ('Věc' | translate)  + ': ' + $any(entity).subject}}</div>
      }
      @if (isFileEntity) {
        <div>{{ ('Název' | translate)  + ': ' + $any(entity).subject}}</div>
      }
      @if (isStorageUnitEntity) {
        <div>{{ ('Název' | translate)  + ': ' + $any(entity).name}}</div>
      }
      @if ($any(entity).description) {
        <div>{{ ('Popis' | translate)  + ': ' + $any(entity).description}}</div>
      }
      @if (isFileEntity && $any(entity).label) {
        <div>{{ ('Značka' | translate)  + ': ' + $any(entity).label}}</div>
      }
    </div>
  </div>
}
