/* tslint:disable */
/* eslint-disable */

/**
 * How digital component was rendered - for example extracted from container
 */
export enum DigitalComponentRenditionType {
  CONTAINER_EXTRACTION = 'CONTAINER_EXTRACTION',
  ANONYMIZATION = 'ANONYMIZATION'
}
