<div class="popover-content"
  (keydown.arrowUp)="optionsList.selectPrevOption()"
  (keydown.arrowDown)="optionsList.selectNextOption()"
  (keydown.enter.stop)="optionsList.selectCurrentOption()">

  @if (!hideSearchField) {
    <icz-form-field
      (valueChange)="searchItems($event!)"
      [showValidationStatus]="false"
      [placeholder]="searchTermInputPlaceholder"
      iczAutoFocus="deffered">
      @if (searchTermInputPrefix) {
        <span prefix>
          {{ searchTermInputPrefix | translate }}
        </span>
      }
    </icz-form-field>
  }

  <ng-content additionalContentBeforeOptions select="[additionalContentBeforeOptions]"></ng-content>

  <icz-options-list
    #optionsList
    [hidden]="isLoading"
    [options]="options"
    [selectedOptions]="selectedOptions"
    [isMultiselect]="isMultiselect"
    [listItemTemplate]="listItemTemplate"
    [hideDefaultTooltips]="hideDefaultTooltips"
    (selectionChanged)="selectionChanged.emit($event)">
  </icz-options-list>

  @if (!isLoading && !options.length) {
    <div>
      <ng-container [ngTemplateOutlet]="noResultsTemplate ?? defaultNoResultsTemplate"
        [ngTemplateOutletContext]="{$implicit: {searchTerm: searchTerm, searchTermChanged: searchTermChanged}}">
      </ng-container>
    </div>
  }

  @if (!isLoading && options.length) {
    <ng-content select="[footer]"></ng-content>
  }

  @if (isLoading) {
    <!-- 29 is just right such that nothing jumps like crazy from the initial "no options" state -->
    <icz-spinner class="block text-center" [diameter]="29"></icz-spinner>
  }

</div>

<ng-template #defaultNoResultsTemplate let-context>
  <div class="no-options-found">
    {{ ((context.searchTerm?.length ?? 0) > 0 ? 'Žádné možnosti nenalezeny' : 'Žádné možnosti') | translate }}
  </div>
</ng-template>
