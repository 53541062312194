<form [formGroup]="form">
  <icz-essl-component-link [documentEsslComponent]="paperComponent" class="row"></icz-essl-component-link>
  <icz-form-field
    label="Počet stran"
    class="grow mt-8"
    formControlName="numberOfPages">
  </icz-form-field>
  <icz-form-autocomplete label="Ochranný prvek"
                         formControlName="protectiveElementSet"
                         [isMultiselect]="true"
                         class="grow mt-8"
                         [options]="protectiveElementOptions">
  </icz-form-autocomplete>
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit leftButtons (onAction)="submit()" primary label="Provést konverzi"></icz-button>
  <icz-button rightButtons (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
