/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DataBoxNodeDto } from '../../models/data-box-node-dto';
import { EmailNodeDto } from '../../models/email-node-dto';
import { SheetNodeDto } from '../../models/sheet-node-dto';

export interface OwnConsignmentGetOrganizationOutcomeDistributionNodes$Params {
  deliveryTypeId: number;
}

export function ownConsignmentGetOrganizationOutcomeDistributionNodes(http: HttpClient, rootUrl: string, params: OwnConsignmentGetOrganizationOutcomeDistributionNodes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(SheetNodeDto | DataBoxNodeDto | EmailNodeDto)>>> {
  const rb = new RequestBuilder(rootUrl, ownConsignmentGetOrganizationOutcomeDistributionNodes.PATH, 'get');
  if (params) {
    rb.path('deliveryTypeId', params.deliveryTypeId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(SheetNodeDto | DataBoxNodeDto | EmailNodeDto)>>;
    })
  );
}

ownConsignmentGetOrganizationOutcomeDistributionNodes.PATH = '/sad/own-consignment/outcome-distribution-node/for-delivery-type/{deliveryTypeId}';