/* tslint:disable */
/* eslint-disable */

/**
 * Which components to validate
 */
export enum OwnDigitalConsignmentValidationScope {
  NO_COMPONENTS = 'NO_COMPONENTS',
  AT_LEAST_ONE_COMPONENT = 'AT_LEAST_ONE_COMPONENT',
  ALL_COMPONENTS = 'ALL_COMPONENTS'
}
