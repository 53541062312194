<div
  class="flex"
  cdkOverlayOrigin
  #tasksPopupOrigin="cdkOverlayOrigin"
  (click)="taskIconClicked($event, tasksPopup)">

  @if (task || activity) {
    <icz-icon
      [svgIcon]="stateColor"
      [iczTooltip]="tooltip"
      size="small"
    ></icz-icon>
  }

  <icz-table-toolbar-popup
    #tasksPopup
    [popoverOrigin]="tasksPopupOrigin">
    <div class="p-16">
      @if (tasksPopup.isOpen) {
        <icz-quick-activity-preview
          class="block"
          [isMultiColumn]="true"
          [activity]="activity"
          [activityId]="task?.rootActivityId"
          (circulationActionCompleted)="circulationActionCompleted.emit()"
        ></icz-quick-activity-preview>
      }
    </div>
  </icz-table-toolbar-popup>
</div>
