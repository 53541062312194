/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deliveryServiceBasicCreate } from '../fn/delivery-service-basic/delivery-service-basic-create';
import { DeliveryServiceBasicCreate$Params } from '../fn/delivery-service-basic/delivery-service-basic-create';
import { DeliveryServiceBasicDto } from '../models/delivery-service-basic-dto';
import { deliveryServiceBasicFindAll } from '../fn/delivery-service-basic/delivery-service-basic-find-all';
import { DeliveryServiceBasicFindAll$Params } from '../fn/delivery-service-basic/delivery-service-basic-find-all';
import { deliveryServiceBasicFindByDeliveryTypeGroup } from '../fn/delivery-service-basic/delivery-service-basic-find-by-delivery-type-group';
import { deliveryServiceBasicFindByDeliveryTypeGroup_1 } from '../fn/delivery-service-basic/delivery-service-basic-find-by-delivery-type-group-1';
import { DeliveryServiceBasicFindByDeliveryTypeGroup_1$Params } from '../fn/delivery-service-basic/delivery-service-basic-find-by-delivery-type-group-1';
import { DeliveryServiceBasicFindByDeliveryTypeGroup$Params } from '../fn/delivery-service-basic/delivery-service-basic-find-by-delivery-type-group';
import { deliveryServiceBasicFindById } from '../fn/delivery-service-basic/delivery-service-basic-find-by-id';
import { DeliveryServiceBasicFindById$Params } from '../fn/delivery-service-basic/delivery-service-basic-find-by-id';
import { deliveryServiceBasicSearch } from '../fn/delivery-service-basic/delivery-service-basic-search';
import { DeliveryServiceBasicSearch$Params } from '../fn/delivery-service-basic/delivery-service-basic-search';
import { deliveryServiceBasicUpdate } from '../fn/delivery-service-basic/delivery-service-basic-update';
import { DeliveryServiceBasicUpdate$Params } from '../fn/delivery-service-basic/delivery-service-basic-update';
import { PageDeliveryServiceBasicDto } from '../models/page-delivery-service-basic-dto';


/**
 * Basic delivery services controller
 */
@Injectable({ providedIn: 'root' })
export class ApiDeliveryServiceBasicService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliveryServiceBasicFindById()` */
  static readonly DeliveryServiceBasicFindByIdPath = '/codebook/delivery-service-basic/{id}';

  /**
   * Finds the service by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindById$Response(params: DeliveryServiceBasicFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceBasicDto>> {
    return deliveryServiceBasicFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the service by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindById(params: DeliveryServiceBasicFindById$Params, context?: HttpContext): Observable<DeliveryServiceBasicDto> {
    return this.deliveryServiceBasicFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceBasicDto>): DeliveryServiceBasicDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceBasicUpdate()` */
  static readonly DeliveryServiceBasicUpdatePath = '/codebook/delivery-service-basic/{id}';

  /**
   * Updates an existing service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceBasicUpdate$Response(params: DeliveryServiceBasicUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceBasicDto>> {
    return deliveryServiceBasicUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceBasicUpdate(params: DeliveryServiceBasicUpdate$Params, context?: HttpContext): Observable<DeliveryServiceBasicDto> {
    return this.deliveryServiceBasicUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceBasicDto>): DeliveryServiceBasicDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceBasicFindAll()` */
  static readonly DeliveryServiceBasicFindAllPath = '/codebook/delivery-service-basic';

  /**
   * Returns all the services
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindAll$Response(params?: DeliveryServiceBasicFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryServiceBasicDto>>> {
    return deliveryServiceBasicFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the services
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindAll(params?: DeliveryServiceBasicFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DeliveryServiceBasicDto>> {
    return this.deliveryServiceBasicFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryServiceBasicDto>>): Array<DeliveryServiceBasicDto> => r.body)
    );
  }

  /** Path part for operation `deliveryServiceBasicCreate()` */
  static readonly DeliveryServiceBasicCreatePath = '/codebook/delivery-service-basic';

  /**
   * Creates a new service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceBasicCreate$Response(params: DeliveryServiceBasicCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceBasicDto>> {
    return deliveryServiceBasicCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceBasicCreate(params: DeliveryServiceBasicCreate$Params, context?: HttpContext): Observable<DeliveryServiceBasicDto> {
    return this.deliveryServiceBasicCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceBasicDto>): DeliveryServiceBasicDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceBasicFindByDeliveryTypeGroup()` */
  static readonly DeliveryServiceBasicFindByDeliveryTypeGroupPath = '/codebook/delivery-service-basic/{deliveryTypeGroup}/findByDeliveryTypeGroup';

  /**
   * Returns all services for given delivery type group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicFindByDeliveryTypeGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindByDeliveryTypeGroup$Response(params: DeliveryServiceBasicFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryServiceBasicDto>>> {
    return deliveryServiceBasicFindByDeliveryTypeGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all services for given delivery type group
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicFindByDeliveryTypeGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindByDeliveryTypeGroup(params: DeliveryServiceBasicFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<Array<DeliveryServiceBasicDto>> {
    return this.deliveryServiceBasicFindByDeliveryTypeGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryServiceBasicDto>>): Array<DeliveryServiceBasicDto> => r.body)
    );
  }

  /** Path part for operation `deliveryServiceBasicFindByDeliveryTypeGroup_1()` */
  static readonly DeliveryServiceBasicFindByDeliveryTypeGroup_1Path = '/codebook/delivery-service-basic/{deliveryTypeGroup}/findByDeliveryTypeGroup/search-api';

  /**
   * Returns services for given delivery type group based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicFindByDeliveryTypeGroup_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindByDeliveryTypeGroup_1$Response(params: DeliveryServiceBasicFindByDeliveryTypeGroup_1$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceBasicDto>> {
    return deliveryServiceBasicFindByDeliveryTypeGroup_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns services for given delivery type group based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicFindByDeliveryTypeGroup_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicFindByDeliveryTypeGroup_1(params: DeliveryServiceBasicFindByDeliveryTypeGroup_1$Params, context?: HttpContext): Observable<PageDeliveryServiceBasicDto> {
    return this.deliveryServiceBasicFindByDeliveryTypeGroup_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceBasicDto>): PageDeliveryServiceBasicDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceBasicSearch()` */
  static readonly DeliveryServiceBasicSearchPath = '/codebook/delivery-service-basic/search-api';

  /**
   * Returns services based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceBasicSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicSearch$Response(params?: DeliveryServiceBasicSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceBasicDto>> {
    return deliveryServiceBasicSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns services based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceBasicSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceBasicSearch(params?: DeliveryServiceBasicSearch$Params|null|undefined, context?: HttpContext): Observable<PageDeliveryServiceBasicDto> {
    return this.deliveryServiceBasicSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceBasicDto>): PageDeliveryServiceBasicDto => r.body)
    );
  }

}
