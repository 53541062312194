<ng-container [formGroup]="form">
  @if (wrapInSection) {
    <icz-section [label]="sectionLabel">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </icz-section>
  }
  @else {
    <div class="row gap-16">
      <icz-date-picker class="grow" [formControlName]="validFromControlName" label="Platnost Od" [selectableDates]="selectableValidFromDates"></icz-date-picker>
      <icz-date-picker class="grow" [formControlName]="validToControlName" label="Platnost Do" [selectableDates]="selectableValidToDates"></icz-date-picker>
    </div>
  }

  <ng-template #content>
    <div class="row gap-16">
      <icz-date-picker class="grow" [formControlName]="validFromControlName" label="Platnost Od" [selectableDates]="selectableValidFromDates"></icz-date-picker>
      <icz-date-picker class="grow" [formControlName]="validToControlName" label="Platnost Do" [selectableDates]="selectableValidToDates"></icz-date-picker>
    </div>
  </ng-template>

  <ng-content select="[moreContent]"></ng-content>
</ng-container>
