import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {ElasticDocumentOrFile} from '../../model/document-file-sidebar.model';
import {isDocumentObject, isFileObject} from '../../shared-document.utils';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {RelatedObjectType, SubjectRecordDto} from '|api/commons';

@Component({
  selector: 'icz-document-file-sidebar-tab-subjects',
  templateUrl: './document-file-sidebar-tab-subjects.component.html',
  styleUrls: ['./document-file-sidebar-tab-subjects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentFileSidebarTabSubjectsComponent implements IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);

  @Input({required: true}) object!: ElasticDocumentOrFile;

  readonly isDocumentObject = isDocumentObject;
  readonly isFileObject = isFileObject;

  subjects: Nullable<Array<SubjectRecordDto>>;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.object?.currentValue) {
      const currentObject = changes.object.currentValue;

      this.loadingIndicatorService.startLoading(this);
      if (!currentObject?.id) {
        this.loadingIndicatorService.endLoading(this);
        this.cd.detectChanges();
        return;
      } else {
        this.apiSubjectRecordNgElasticService.subjectRecordElasticElasticFindSubjectsByRelations({
          body: {
            relatedObjectIds: [currentObject?.id],
            relatedObjectType: RelatedObjectType.DOCUMENT,
          },
        })
          .subscribe(subjects => {
            this.subjects = subjects.length ? subjects : [];
            this.loadingIndicatorService.endLoading(this);
            this.cd.detectChanges();
          });
      }

    }
  }

}
