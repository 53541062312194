/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { officeDeskCategoryCreate } from '../fn/office-desk-category/office-desk-category-create';
import { OfficeDeskCategoryCreate$Params } from '../fn/office-desk-category/office-desk-category-create';
import { officeDeskCategoryDelete } from '../fn/office-desk-category/office-desk-category-delete';
import { OfficeDeskCategoryDelete$Params } from '../fn/office-desk-category/office-desk-category-delete';
import { OfficeDeskCategoryDto } from '../models/office-desk-category-dto';
import { officeDeskCategoryFindAll } from '../fn/office-desk-category/office-desk-category-find-all';
import { OfficeDeskCategoryFindAll$Params } from '../fn/office-desk-category/office-desk-category-find-all';
import { officeDeskCategoryFindById } from '../fn/office-desk-category/office-desk-category-find-by-id';
import { OfficeDeskCategoryFindById$Params } from '../fn/office-desk-category/office-desk-category-find-by-id';
import { officeDeskCategorySearch } from '../fn/office-desk-category/office-desk-category-search';
import { OfficeDeskCategorySearch$Params } from '../fn/office-desk-category/office-desk-category-search';
import { officeDeskCategoryUpdate } from '../fn/office-desk-category/office-desk-category-update';
import { OfficeDeskCategoryUpdate$Params } from '../fn/office-desk-category/office-desk-category-update';
import { PageOfficeDeskCategoryDto } from '../models/page-office-desk-category-dto';


/**
 * Controller for office desk category
 */
@Injectable({ providedIn: 'root' })
export class ApiOfficeDeskCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `officeDeskCategoryFindById()` */
  static readonly OfficeDeskCategoryFindByIdPath = '/codebook/office-desk-category/{id}';

  /**
   * Find office desk category object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskCategoryFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategoryFindById$Response(params: OfficeDeskCategoryFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskCategoryDto>> {
    return officeDeskCategoryFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find office desk category object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskCategoryFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategoryFindById(params: OfficeDeskCategoryFindById$Params, context?: HttpContext): Observable<OfficeDeskCategoryDto> {
    return this.officeDeskCategoryFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskCategoryDto>): OfficeDeskCategoryDto => r.body)
    );
  }

  /** Path part for operation `officeDeskCategoryUpdate()` */
  static readonly OfficeDeskCategoryUpdatePath = '/codebook/office-desk-category/{id}';

  /**
   * Update existing office desk category object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskCategoryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskCategoryUpdate$Response(params: OfficeDeskCategoryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskCategoryDto>> {
    return officeDeskCategoryUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing office desk category object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskCategoryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskCategoryUpdate(params: OfficeDeskCategoryUpdate$Params, context?: HttpContext): Observable<OfficeDeskCategoryDto> {
    return this.officeDeskCategoryUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskCategoryDto>): OfficeDeskCategoryDto => r.body)
    );
  }

  /** Path part for operation `officeDeskCategoryDelete()` */
  static readonly OfficeDeskCategoryDeletePath = '/codebook/office-desk-category/{id}';

  /**
   * Delete existing office desk category object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskCategoryDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategoryDelete$Response(params: OfficeDeskCategoryDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return officeDeskCategoryDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing office desk category object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskCategoryDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategoryDelete(params: OfficeDeskCategoryDelete$Params, context?: HttpContext): Observable<void> {
    return this.officeDeskCategoryDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `officeDeskCategoryFindAll()` */
  static readonly OfficeDeskCategoryFindAllPath = '/codebook/office-desk-category';

  /**
   * Finds all office desk category object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskCategoryFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategoryFindAll$Response(params?: OfficeDeskCategoryFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OfficeDeskCategoryDto>>> {
    return officeDeskCategoryFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all office desk category object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskCategoryFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategoryFindAll(params?: OfficeDeskCategoryFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<OfficeDeskCategoryDto>> {
    return this.officeDeskCategoryFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OfficeDeskCategoryDto>>): Array<OfficeDeskCategoryDto> => r.body)
    );
  }

  /** Path part for operation `officeDeskCategoryCreate()` */
  static readonly OfficeDeskCategoryCreatePath = '/codebook/office-desk-category';

  /**
   * Create new office desk category object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskCategoryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskCategoryCreate$Response(params: OfficeDeskCategoryCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<OfficeDeskCategoryDto>> {
    return officeDeskCategoryCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new office desk category object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskCategoryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  officeDeskCategoryCreate(params: OfficeDeskCategoryCreate$Params, context?: HttpContext): Observable<OfficeDeskCategoryDto> {
    return this.officeDeskCategoryCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OfficeDeskCategoryDto>): OfficeDeskCategoryDto => r.body)
    );
  }

  /** Path part for operation `officeDeskCategorySearch()` */
  static readonly OfficeDeskCategorySearchPath = '/codebook/office-desk-category/search-api';

  /**
   * Finds foffice desk category objects based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `officeDeskCategorySearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategorySearch$Response(params?: OfficeDeskCategorySearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOfficeDeskCategoryDto>> {
    return officeDeskCategorySearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds foffice desk category objects based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `officeDeskCategorySearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  officeDeskCategorySearch(params?: OfficeDeskCategorySearch$Params|null|undefined, context?: HttpContext): Observable<PageOfficeDeskCategoryDto> {
    return this.officeDeskCategorySearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfficeDeskCategoryDto>): PageOfficeDeskCategoryDto => r.body)
    );
  }

}
