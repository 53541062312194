import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Observable, of, switchMap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {
  ApiBulkPostingFormService,
  ApiBulkPostingFormTemplateService,
  BulkPostingFormCreateDto,
  BulkPostingFormDto,
  BulkPostingFormFilterDeliveryServicesDto,
  BulkPostingFormFilterDto,
  BulkPostingFormFilterOperator,
  BulkPostingFormFormat,
  BulkPostingFormPaging,
  BulkPostingFormSortAttribute,
  BulkPostingFormSortOperator,
  BulkPostingFormTemplateDto,
  BulkPostingFormUpdateDto,
  PaymentType,
} from '|api/sad';
import {
  BulkPostingFormDialogData,
  BulkPostingFormDialogResult,
  BulkPostingFormDialogResultType,
} from './bulk-posting-form-dialog.service';
import {ConsignmentsToastService} from '../../../consignment-dialog/consignments-toast.service';
import {
  BulkPostingFormTemplateNameDialogComponent,
} from './bulk-posting-form-template-name-dialog/bulk-posting-form-template-name-dialog.component';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczModalService} from '../../../../../services/icz-modal.service';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {LocalBinaryFileDownloadService} from '../../../../../services/local-binary-file-download.service';
import {RemoteBinaryFileDownloadService} from '../../../../../services/remote-binary-file-download.service';
import {enumToOptions, namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {Option} from '../../../../../model';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {
  ApiDispatchOfficeService,
  ApiDistributorService,
  DeliveryServiceCombinationDto,
  DeliveryTypeGroup,
} from '|api/codebook';
import {startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TabItem} from '../../../../essentials/tabs/tabs.component.model';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';


@Component({
  selector: 'icz-bulk-posting-form-dialog',
  templateUrl: './bulk-posting-form-dialog.component.html',
  styleUrls: ['./bulk-posting-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkPostingFormDialogComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected iczModalService = inject(IczModalService);
  private modalRef = injectModalRef<Nullable<BulkPostingFormDialogResult>>();
  private codebookService = inject(CodebookService);
  private apiBulkPostingFormService = inject(ApiBulkPostingFormService);
  private apiBulkPostingFormTemplateService = inject(ApiBulkPostingFormTemplateService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private consignmentsToastService = inject(ConsignmentsToastService);
  private destroyRef = inject(DestroyRef);
  private apiDistributorService = inject(ApiDistributorService);
  private apiDispatchOfficeService = inject(ApiDispatchOfficeService);
  protected modalData = injectModalData<BulkPostingFormDialogData>();

  form = new IczFormGroup({
    dispatchOfficeId: new IczFormControl<Nullable<number>>({value: null, disabled: true}, [IczValidators.required()]),
    distributorId: new IczFormControl<Nullable<number>>(1, [IczValidators.required()]),
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    paymentType: new IczFormControl<Nullable<PaymentType>>(null, []),
    customerCardNumber: new IczFormControl<Nullable<string>>(null, []),
    ownConsignmentFiltering: new IczFormGroup({
      combinationDeliveryService: new IczFormGroup({
        combinationDeliveryServiceIds: new IczFormControl<Nullable<number[]>>(null, []),
        filterOperator: new IczFormControl<Nullable<BulkPostingFormFilterOperator>>(null, []),
      }),
      bulkPostingFormRequired: new IczFormControl<Nullable<boolean>>(false, []),
      dispatchOfficeTakeoverDateFrom: new IczFormControl<Nullable<string>>(null, []),
      dispatchOfficeTakeoverDateTo: new IczFormControl<Nullable<string>>(null, []),
      organizationalUnitIds: new IczFormControl<Nullable<number[]>>(null, []),
    }),
    ownConsignmentSorting: new IczFormArray(() => {
      return new IczFormGroup({
        bulkPostingFormSortAttribute: new IczFormControl<Nullable<BulkPostingFormSortAttribute>>(BulkPostingFormSortAttribute.DELIVERY_SERVICE, []),
        bulkPostingFormSortOperator: new IczFormControl<Nullable<BulkPostingFormSortOperator>>(BulkPostingFormSortOperator.DESCENDING, []),
        bulkPostingFormSortOrder: new IczFormControl<Nullable<number>>(0, []),
      });
    }, []),
    printConfiguration: new IczFormGroup({
      pagingMethod: new IczFormControl<Nullable<BulkPostingFormPaging>>(BulkPostingFormPaging.NO_PAGINATION, [IczValidators.required()]),
      exportFormat: new IczFormControl<Nullable<BulkPostingFormFormat>>(BulkPostingFormFormat.PDF, [IczValidators.required()]),
    }),
  });

  templateForm = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    distributorId: new IczFormControl<Nullable<number>>(1, [IczValidators.required()]),
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    ownConsignmentFiltering: new IczFormGroup({
      combinationDeliveryService: new IczFormGroup({
        combinationDeliveryServiceIds: new IczFormControl<Nullable<number[]>>(null, []),
        filterOperator: new IczFormControl<Nullable<BulkPostingFormFilterOperator>>(null, []),
      }),
      bulkPostingFormRequired: new IczFormControl<Nullable<boolean>>(false, []),
      organizationalUnitIds: new IczFormControl<Nullable<number[]>>(null, []),
    }),
    ownConsignmentSorting: new IczFormArray(() => {
      return new IczFormGroup({
        bulkPostingFormSortAttribute: new IczFormControl<Nullable<BulkPostingFormSortAttribute>>(BulkPostingFormSortAttribute.COD_AMOUNT, []),
        bulkPostingFormSortOperator: new IczFormControl<Nullable<BulkPostingFormSortOperator>>(BulkPostingFormSortOperator.DESCENDING, []),
        bulkPostingFormSortOrder: new IczFormControl<Nullable<number>>(0, []),
      });
    }, []),
    printConfiguration: new IczFormGroup({
      pagingMethod: new IczFormControl<Nullable<BulkPostingFormPaging>>(BulkPostingFormPaging.NO_PAGINATION, [IczValidators.required()]),
      exportFormat: new IczFormControl<Nullable<string>>(BulkPostingFormFormat.PDF, [IczValidators.required()]),
    }),
  });

  tabs: TabItem[] = [
    {
      id: 'definition',
      label: 'Definice archu',
    },
    {
      id: 'content',
      label: 'Seznam položek',
    }
  ];

  readonly BulkPostingFormFilterOperator = BulkPostingFormFilterOperator;
  readonly BulkPostingFormFormat = BulkPostingFormFormat;

  bulkPostingFormTemplates: BulkPostingFormTemplateDto[] = [];
  bulkPostingFormTemplateOptions: Option[] = [];
  dispatchOfficeOptions: Option[] = [];
  distributorOptions: Option[] = [];
  deliveryServiceCombinationOptionsFull: Option[] = [];
  deliveryServiceCombinationOptions: Option[] = [];
  bulkPostingFormSortAttributeOptions: Option[] = enumToOptions('bulkPostingFormSortAttribute', BulkPostingFormSortAttribute);
  bulkPostingFormSortOperatorOptions: Option[] = enumToOptions('bulkPostingFormSortOperator', BulkPostingFormSortOperator);
  bulkPostingFormPagingOptions: Option[] = enumToOptions('bulkPostingFormPaging', BulkPostingFormPaging);
  paymentTypeOptions: Option[] = enumToOptions('paymentType', PaymentType);
  bulkPostingFormTemplateForm = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, []),
  });
  private orgUnitsAsIds: number[] = [];
  get ownConsignmentFilteringForm(): IczFormGroup {
    return this.form?.get('ownConsignmentFiltering') as IczFormGroup;
  }

  get ownConsignmentSortingForm(): IczFormArray {
    return this.form?.get('ownConsignmentSorting') as IczFormArray;
  }

  get pagingMethodControl(): IczFormControl {
    return this.form.get('printConfiguration')!.get('pagingMethod') as IczFormControl;
  }

  get exportFormatControl(): IczFormControl {
    return this.form.get('printConfiguration')!.get('exportFormat') as IczFormControl;
  }

  get isCreateMode() {
    return this.modalData.isCreateMode;
  }

  private initFormsInCreateMode() {
    this.bulkPostingFormTemplateForm.get('id')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(templateId => {
      if (templateId) this.patchValuesFromTemplate(templateId);
    });
    this.setTemplateOptions();

    (this.form.get('ownConsignmentSorting') as IczFormArray).setSize(1);

    this.handleSortingValueConflict(this.ownConsignmentSortingForm.getLastControl());
    this.handlePagingValueConflict();

  }

  private patchForm(toPatch: IczFormGroup, value: BulkPostingFormDto | BulkPostingFormTemplateDto) {
    const currentRawValue = toPatch.getRawValue();
    toPatch.patchValue({
      dispatchOfficeId: (value as BulkPostingFormDto).dispatchOfficeId ?? currentRawValue.dispatchOfficeId,
      distributorId: value.distributorId,
      name: value.name,
      paymentType: (value as BulkPostingFormDto).paymentType,
      customerCardNumber: (value as BulkPostingFormDto).customerCardNumber ?? currentRawValue.customerCardNumber,
    });
    toPatch.get('ownConsignmentFiltering')!.get('combinationDeliveryService')!.patchValue({
      combinationDeliveryServiceIds: value.ownConsignmentFiltering.combinationDeliveryService?.combinationDeliveryServiceIds,
      filterOperator: value.ownConsignmentFiltering.combinationDeliveryService?.filterOperator,
    });
    toPatch.get('ownConsignmentFiltering')?.patchValue({
      combinationDeliveryService: value.ownConsignmentFiltering.combinationDeliveryService as BulkPostingFormFilterDeliveryServicesDto,
      bulkPostingvalueRequired: value.ownConsignmentFiltering?.bulkPostingFormRequired,
      dispatchOfficeTakeoverDateFrom: (value.ownConsignmentFiltering as BulkPostingFormFilterDto).dispatchOfficeTakeoverDateFrom,
      dispatchOfficeTakeoverDateTo: (value.ownConsignmentFiltering as BulkPostingFormFilterDto).dispatchOfficeTakeoverDateTo,
      organizationalUnitIds: (value.ownConsignmentFiltering as BulkPostingFormFilterDto).organizationalUnitIds,
    });
    (toPatch.get('ownConsignmentSorting')! as IczFormArray)!.setSize(value.ownConsignmentSorting.length);
    (toPatch.get('ownConsignmentSorting')! as IczFormArray)!.controls.forEach((control, index) => {
      control.patchValue(value.ownConsignmentSorting[index]);
    });
    toPatch.get('printConfiguration')!.patchValue({
      pagingMethod: value.printConfiguration?.pagingMethod ?? currentRawValue.printConfiguration.pagingMethod,
      exportFormat: value.printConfiguration?.exportFormat ?? currentRawValue.printConfiguration.exportFormat,
    });
  }

  private handleSortingValueConflict(newSortControl: IczFormGroup) {
    const pagingOptionBasic = this.bulkPostingFormPagingOptions.find(o => o.value === BulkPostingFormPaging.DELIVERY_SERVICE_BASIC_PAGINATION)!;
    const pagingOptionCombination = this.bulkPostingFormPagingOptions.find(o => o.value === BulkPostingFormPaging.DELIVERY_SERVICE_COMBINATION_PAGINATION)!;

    newSortControl.get('bulkPostingFormSortAttribute')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(sortValue => {
      if (sortValue === BulkPostingFormSortAttribute.BASIC_DELIVERY_SERVICE) {
        pagingOptionBasic.disabled = true;
        this.ownConsignmentSortingForm.controls.forEach(control => {
          if (control.get('bulkPostingFormSortAttribute')!.value === BulkPostingFormSortAttribute.DELIVERY_SERVICE) {
            pagingOptionCombination.disabled = true;
          }
        });
      } else if (sortValue === BulkPostingFormSortAttribute.DELIVERY_SERVICE) {
        pagingOptionCombination.disabled = true;
        this.ownConsignmentSortingForm.controls.forEach(control => {
          if (control.get('bulkPostingFormSortAttribute')!.value === BulkPostingFormSortAttribute.BASIC_DELIVERY_SERVICE) {
            pagingOptionBasic.disabled = true;
          }
        });
      } else {
        this.ownConsignmentSortingForm.controls.forEach(control => {
          if (control.value === BulkPostingFormSortAttribute.BASIC_DELIVERY_SERVICE) {
            pagingOptionBasic.disabled = true;
          }
          if (control.value === BulkPostingFormSortAttribute.DELIVERY_SERVICE) {
            pagingOptionCombination.disabled = true;
          }
        });
      }
      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    });
  }

  private handlePagingValueConflict() {
    const sortOptionBasic = this.bulkPostingFormSortAttributeOptions.find(o => o.value === BulkPostingFormSortAttribute.BASIC_DELIVERY_SERVICE)!;
    const sortOptionCombination = this.bulkPostingFormSortAttributeOptions.find(o => o.value === BulkPostingFormSortAttribute.DELIVERY_SERVICE)!;
    const pagingOptionBasic = this.bulkPostingFormPagingOptions.find(o => o.value === BulkPostingFormPaging.DELIVERY_SERVICE_BASIC_PAGINATION)!;
    const pagingOptionCombination = this.bulkPostingFormPagingOptions.find(o => o.value === BulkPostingFormPaging.DELIVERY_SERVICE_COMBINATION_PAGINATION)!;

    this.exportFormatControl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(exportFormat => {
      if (exportFormat === BulkPostingFormFormat.PDF) {
        pagingOptionCombination.disabled = false;
        pagingOptionBasic.disabled = false;
      } else if (exportFormat === BulkPostingFormFormat.CSV) {
        this.pagingMethodControl.setValue(BulkPostingFormPaging.NO_PAGINATION);
        pagingOptionCombination.disabled = true;
        pagingOptionBasic.disabled = true;
      }
    });

    this.pagingMethodControl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(pagingMethod => {
      if (pagingMethod === BulkPostingFormPaging.DELIVERY_SERVICE_BASIC_PAGINATION) {
        sortOptionBasic.disabled = true;
        sortOptionCombination.disabled = false;
        this.ownConsignmentSortingForm.controls.forEach(control => {
          if (control.get('bulkPostingFormSortAttribute')!.value === BulkPostingFormSortAttribute.BASIC_DELIVERY_SERVICE) {
            control.get('bulkPostingFormSortAttribute')!.setValue(BulkPostingFormSortAttribute.COD_AMOUNT, {emitEvent: false});
          }
        });
      } else if (pagingMethod === BulkPostingFormPaging.DELIVERY_SERVICE_COMBINATION_PAGINATION) {
        sortOptionBasic.disabled = false;
        sortOptionCombination.disabled = true;
        this.ownConsignmentSortingForm.controls.forEach(control => {
          if (control.get('bulkPostingFormSortAttribute')!.value === BulkPostingFormSortAttribute.DELIVERY_SERVICE) {
            control.get('bulkPostingFormSortAttribute')!.setValue(BulkPostingFormSortAttribute.COD_AMOUNT, {emitEvent: false});
          }
        });
      } else if (pagingMethod === BulkPostingFormPaging.NO_PAGINATION) {
        sortOptionBasic.disabled = false;
        sortOptionCombination.disabled = false;
      }

      this.form.updateValueAndValidity({onlySelf: true, emitEvent: true});
    });
  }

  setTemplateOptions() {
    this.apiBulkPostingFormTemplateService.bulkPostingFormTemplateFindAll().subscribe(bulkPostingFormTemplates => {
      this.bulkPostingFormTemplates = bulkPostingFormTemplates;
      this.bulkPostingFormTemplateOptions = bulkPostingFormTemplates.map(namedDtoToOption());
    });
  }

  private patchValuesFromTemplate(templateId: number) {
    const template = this.bulkPostingFormTemplates.find(b => b.id === templateId);
    if (template) {
      this.patchForm(this.form, template);
    }
  }

  private setDispatchOfficeId() {
    if (this.modalData.dispatchOfficeId) {
      this.form.get('dispatchOfficeId')?.setValue(this.modalData.dispatchOfficeId);
    } else {
      this.apiDispatchOfficeService.dispatchOfficeFindAll().subscribe(dispatchOffices => {
        if (dispatchOffices.length === 1) {
          this.form.get('dispatchOfficeId')?.setValue(dispatchOffices[0].id);
        }
        else {
          throw new Error('Could not determine dispatch office for bulk posting form!');
        }
      });
    }
  }

  addSortCriterion() {
    this.ownConsignmentSortingForm.incrementSize();
    const length = this.ownConsignmentSortingForm.length;
    this.ownConsignmentSortingForm?.getLastControl().get('bulkPostingFormSortOrder')!.setValue(length - 1);
    this.handleSortingValueConflict(this.ownConsignmentSortingForm?.getLastControl());
  }

  removeSortCriterion(i: number) {
    this.ownConsignmentSortingForm.removeAt(i);
  }

  deleteTemplate(context: { option: Option }) {
    const idToDelete = context?.option.value;
    if (idToDelete || idToDelete === 0) {
      this.apiBulkPostingFormTemplateService.bulkPostingFormTemplateDelete({id: parseInt(idToDelete as string)}).subscribe(_ => {
        this.setTemplateOptions();
      });
    }
  }

  private doSave(): Observable<BulkPostingFormDto> {
    const formValue = this.form.getRawValue() as unknown as BulkPostingFormCreateDto;
    formValue.excludedConsignments = this.modalData.excludedConsignmentIds;
    const serviceFilters = formValue.ownConsignmentFiltering.combinationDeliveryService;
    if (serviceFilters?.combinationDeliveryServiceIds == null || serviceFilters.filterOperator == null) {
      delete formValue.ownConsignmentFiltering.combinationDeliveryService;
    }
    if (this.orgUnitsAsIds.length) {
      formValue.ownConsignmentFiltering.organizationalUnitIds = this.orgUnitsAsIds;
    }

    return this.loadingIndicatorService.doLoading(
      this.apiBulkPostingFormService.bulkPostingFormCreate({body: formValue}),
      this);
  }

  private filterServiceCombinationOptions() {
    this.deliveryServiceCombinationOptions = this.deliveryServiceCombinationOptionsFull.filter(dsc =>
      (dsc.data! as DeliveryServiceCombinationDto).basicService.distributorId === this.form.get('distributorId')!.value);
  }

  save() {
    this.doSave()
      .subscribe(bulk => {
        this.modalRef.close({
          resultType: BulkPostingFormDialogResultType.CREATED,
          name: bulk.name
        } as BulkPostingFormDialogResult);
      });
  }

  update() {
    const formValue = this.form.value;
    const body: BulkPostingFormUpdateDto = {
      name: formValue.name!,
      customerCardNumber: formValue.customerCardNumber,
      paymentType: formValue.paymentType,
    };
    this.loadingIndicatorService.doLoading(
      this.apiBulkPostingFormService.bulkPostingFormUpdate({id: this.modalData.bulkPostingForm!.id!, body}),
      this
    ).subscribe(bulk => {
      this.modalRef.close({
        resultType: BulkPostingFormDialogResultType.UPDATED,
        name: bulk.name
      } as BulkPostingFormDialogResult);
    });
  }

  /**
   * Exports the Form. If it's a new one, it needs to be created in DB first, if it's a readonly view, it calls the export directly.
   */
  export() {
    let bulkPostingForm$: Observable<BulkPostingFormDto>;
    if (this.isCreateMode) {
      bulkPostingForm$ = this.doSave();
    } else {
      bulkPostingForm$ = of(this.modalData.bulkPostingForm!);
    }
    bulkPostingForm$.pipe(switchMap((bulk: BulkPostingFormDto) => {
      return this.loadingIndicatorService.doLoading(
        this.remoteBinaryFileDownloadService.fetchBulkPostingFormExport(bulk.id),
        this);
    }))
      .subscribe(blob => {
        const fileName = `${this.form.get('name')!.value!}.${this.form.get('printConfiguration')!.get('exportFormat')!.value!.toLowerCase()}`;
        this.localBinaryFileDownloadService.downloadBlob(blob, fileName, 'application/octet-stream');

        this.modalRef.close({
          resultType: BulkPostingFormDialogResultType.EXPORTED,
          name: this.form.get('name')!.value
        } as BulkPostingFormDialogResult);
      });
  }

  organizationalUnitIdsChanged(units: Nullable<Option[] | Option>) {
    this.orgUnitsAsIds = units ? (units as Option[]).map(u => u!.id!) : [];
  }

  saveAsTemplate() {
    this.iczModalService
      .openComponentInModal<string, void>({
        component: BulkPostingFormTemplateNameDialogComponent,
        modalOptions: {
          titleTemplate: 'Uložit pro příští použití',
          width: 400,
          height: 200,
        },
      })
      .subscribe((templateName: string) => {
        if (templateName) {
          this.patchForm(this.templateForm, this.form.value as BulkPostingFormDto);
          const templateValue = this.templateForm.getRawValue() as unknown as BulkPostingFormTemplateDto;

          const serviceFilters = templateValue.ownConsignmentFiltering.combinationDeliveryService;
          if (serviceFilters?.combinationDeliveryServiceIds == null || serviceFilters.filterOperator == null) {
            delete templateValue.ownConsignmentFiltering.combinationDeliveryService;
          }
          if (this.orgUnitsAsIds.length) {
            templateValue.ownConsignmentFiltering.organizationalUnitIds = this.orgUnitsAsIds;
          }
          templateValue.name = templateName;

          this.loadingIndicatorService.doLoading(
            this.apiBulkPostingFormTemplateService.bulkPostingFormTemplateCreate({body: templateValue}),
            this)
            .subscribe(template => {
              const toastData = {[InternalNotificationKey.NAME]: template.name};
              this.consignmentsToastService.dispatchBulkPostingFormTemplateCreated(toastData);
            });
        }
      });
  }

  close() {
    this.modalRef.close(null);
  }

  ngOnInit() {
    this.codebookService.dispatchOffices().subscribe(d => this.dispatchOfficeOptions = d.map(namedDtoToOption()));
    this.apiDistributorService.distributorFindByDeliveryTypeGroup({deliveryTypeGroup: DeliveryTypeGroup.POSTA})
      .subscribe(d => this.distributorOptions = d.map(namedDtoToOption()));
    this.codebookService.deliveryServiceCombinationsForCzechPost().subscribe(dsc => {
      this.deliveryServiceCombinationOptionsFull = dsc.content!.map(namedDtoToOption()).map(o => {
        return {...o, data: dsc.content!.find(d => d.id === o.value)};
      });
      if (this.form.get('distributorId')!.value) {
        this.filterServiceCombinationOptions();
      }
    });

    this.initFormsInCreateMode();

    if (this.isCreateMode) {
      this.setDispatchOfficeId();
    } else {
      if (this.modalData?.bulkPostingForm) this.patchForm(this.form, this.modalData.bulkPostingForm);
      this.form.disable();
      this.form.get('name')!.enable();
      this.form.get('customerCardNumber')!.enable();
      this.form.get('paymentType')!.enable();
      this.bulkPostingFormTemplateForm.disable();
    }

    if (this.isCreateMode) {
      this.form.get('distributorId')!.valueChanges.pipe(
        startWith(this.form.get('distributorId')!.value),
        takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.form.get('ownConsignmentFiltering')!.get('combinationDeliveryService')!.get('combinationDeliveryServiceIds')!.setValue(null);
        this.filterServiceCombinationOptions();
      });
    }
  }

}
