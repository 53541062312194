import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {DeliveryResultDto} from '|api/codebook';
import {EntityType} from '|api/commons';
import {ProofOfDeliveryDto} from '|api/sad';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {
  GenericOwnConsignment,
  isOfficeDeskConsignment
} from '../../../own-consignment-table/model/own-consignment-model';
import {getComponentIcon} from '../../../../../model';
import {
  PlainComponentVersionViewerDialogService
} from '../../../../essentials/plain-component-version-viewer-dialog/plain-component-version-viewer-dialog.service';
import {Router} from '@angular/router';
import {IczModalRef} from '../../../../../services/icz-modal-ref.injectable';
import {createAbsoluteRoute} from '../../../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../../../enums/documents-routes.enum';
import {ApiDocumentService, DocumentDto} from '|api/document';
import {of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {CommunicationErrorDialogService} from '../../../../../core/services/communication-error-dialog.service';


@Component({
  selector: 'icz-consignment-delivery-result-overview',
  templateUrl: './consignment-delivery-result-overview.component.html',
  styleUrls: ['./consignment-delivery-result-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentDeliveryResultOverviewComponent implements OnInit {

  private router = inject(Router);
  private apiDocumentService = inject(ApiDocumentService);
  private codebookService = inject(CodebookService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private communicationErrorDialogService = inject(CommunicationErrorDialogService);
  private plainComponentVersionViewerDialogService = inject(PlainComponentVersionViewerDialogService);

  hasProofOfDeliveryDocumentInsufficientPermissions = false;

  @Input({required: true}) ownConsignment!: Nullable<GenericOwnConsignment>;
  @Input({required: true}) modalRef!: IczModalRef<unknown>;

  proofOfDeliveryDocument: Nullable<DocumentDto> = null;

  deliveryResults: DeliveryResultDto[] = [];

  get deliveryResultName(): Nullable<string> {
    return this.deliveryResults?.find(dr => dr.id === this.ownConsignment?.proofOfDelivery?.deliveryResultId)?.name;
  }

  get digitalComponentVersionFileIcon() {
    if (!this.ownConsignment?.proofOfDelivery) return null;
    return getComponentIcon(EntityType.DIGITAL_COMPONENT!, this.ownConsignment?.proofOfDelivery?.digitalComponentVersionTypeFlag!);
  }

  get isOfficeDeskConsignment() {
    return isOfficeDeskConsignment(this.ownConsignment);
  }

  ngOnInit() {
    if (!this.ownConsignment) return;
    const proofOfDeliveryDocumentId = this.ownConsignment.proofOfDelivery?.documentId;

    if (proofOfDeliveryDocumentId) {
      this.apiDocumentService.documentFindById({id: proofOfDeliveryDocumentId}, SKIP_ERROR_DIALOG).pipe(
        catchError(e => {
          if (e instanceof HttpErrorResponse) {
            if (e.status === 401) {
              this.hasProofOfDeliveryDocumentInsufficientPermissions = true;
            }
            else {
              this.communicationErrorDialogService.showCommunicationError(e);
            }
          }

          return of(null);
        }),
      ).subscribe(proofOfDeliveryDocument => {
        this.proofOfDeliveryDocument = proofOfDeliveryDocument;
        this.changeDetectorRef.detectChanges();
      });
    }

    this.codebookService.deliveryResults().subscribe(deliveryResults => {
      this.deliveryResults = deliveryResults;
      this.changeDetectorRef.detectChanges();
    });
  }

  openProofOfDeliveryDocument(proofOfDelivery: ProofOfDeliveryDto) {
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.DOCUMENT,
      proofOfDelivery.documentId!,
    ));
    this.modalRef.close(null);
  }

  openProofOfDeliveryComponentPreview(proofOfDelivery: ProofOfDeliveryDto) {
    this.plainComponentVersionViewerDialogService.openPlainComponentVersionViewer(
      proofOfDelivery.digitalComponentVersionId!,
      proofOfDelivery.digitalComponentVersionFileName,
      proofOfDelivery.digitalComponentVersionMimeType,
      proofOfDelivery.digitalComponentVersionTypeFlag,
      'Doklad doručení'
    );
  }
}
