import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {ApplicationLanguage} from '../../../core/services/environment.models';


export function initDatepickerLocale(translateService: TranslateService, adapter: DateAdapter<Date>) {
  function changeFirstDayOfWeekBasedOnLanguage(lang: ApplicationLanguage) {
    adapter.getFirstDayOfWeek = () => (lang === ApplicationLanguage.ENGLISH ? 0 : 1);
  }

  const currentLanguage = translateService.currentLang as ApplicationLanguage;
  adapter.setLocale(currentLanguage);
  changeFirstDayOfWeekBasedOnLanguage(currentLanguage);

  translateService.onLangChange.pipe(
    map(event => event?.lang),
  ).subscribe((newLanguage: string) => {
    adapter.setLocale(newLanguage);
    changeFirstDayOfWeekBasedOnLanguage(newLanguage as ApplicationLanguage);
  });
}
