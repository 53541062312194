/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OrganizationalUnitDto } from '../models/organizational-unit-dto';
import { organizationalUnitFind } from '../fn/organizational-unit/organizational-unit-find';
import { OrganizationalUnitFind$Params } from '../fn/organizational-unit/organizational-unit-find';
import { organizationalUnitFindAll } from '../fn/organizational-unit/organizational-unit-find-all';
import { OrganizationalUnitFindAll$Params } from '../fn/organizational-unit/organizational-unit-find-all';
import { organizationalUnitFindByCode } from '../fn/organizational-unit/organizational-unit-find-by-code';
import { OrganizationalUnitFindByCode$Params } from '../fn/organizational-unit/organizational-unit-find-by-code';
import { organizationalUnitFindByFunctionalPositionId } from '../fn/organizational-unit/organizational-unit-find-by-functional-position-id';
import { OrganizationalUnitFindByFunctionalPositionId$Params } from '../fn/organizational-unit/organizational-unit-find-by-functional-position-id';
import { organizationalUnitFindById } from '../fn/organizational-unit/organizational-unit-find-by-id';
import { OrganizationalUnitFindById$Params } from '../fn/organizational-unit/organizational-unit-find-by-id';
import { organizationalUnitFindWithParent } from '../fn/organizational-unit/organizational-unit-find-with-parent';
import { OrganizationalUnitFindWithParent$Params } from '../fn/organizational-unit/organizational-unit-find-with-parent';
import { organizationalUnitGetAllManagedByUser } from '../fn/organizational-unit/organizational-unit-get-all-managed-by-user';
import { OrganizationalUnitGetAllManagedByUser$Params } from '../fn/organizational-unit/organizational-unit-get-all-managed-by-user';
import { organizationalUnitGetOrganizationalUnitsValidOnDate } from '../fn/organizational-unit/organizational-unit-get-organizational-units-valid-on-date';
import { OrganizationalUnitGetOrganizationalUnitsValidOnDate$Params } from '../fn/organizational-unit/organizational-unit-get-organizational-units-valid-on-date';
import { PageOrganizationalUnitDto } from '../models/page-organizational-unit-dto';


/**
 * Controller with operations over client details
 */
@Injectable({ providedIn: 'root' })
export class ApiOrganizationalUnitService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `organizationalUnitFindAll()` */
  static readonly OrganizationalUnitFindAllPath = '/organizational-unit';

  /**
   * Find all functional position instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindAll$Response(params?: OrganizationalUnitFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationalUnitDto>>> {
    return organizationalUnitFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all functional position instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindAll(params?: OrganizationalUnitFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<OrganizationalUnitDto>> {
    return this.organizationalUnitFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationalUnitDto>>): Array<OrganizationalUnitDto> => r.body)
    );
  }

  /** Path part for operation `organizationalUnitFindById()` */
  static readonly OrganizationalUnitFindByIdPath = '/organizational-unit/{id}';

  /**
   * Find organizational unit by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindById$Response(params: OrganizationalUnitFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationalUnitDto>> {
    return organizationalUnitFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find organizational unit by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindById(params: OrganizationalUnitFindById$Params, context?: HttpContext): Observable<OrganizationalUnitDto> {
    return this.organizationalUnitFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationalUnitDto>): OrganizationalUnitDto => r.body)
    );
  }

  /** Path part for operation `organizationalUnitGetOrganizationalUnitsValidOnDate()` */
  static readonly OrganizationalUnitGetOrganizationalUnitsValidOnDatePath = '/organizational-unit/valid-on-date';

  /**
   * Finds organizational units based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitGetOrganizationalUnitsValidOnDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitGetOrganizationalUnitsValidOnDate$Response(params: OrganizationalUnitGetOrganizationalUnitsValidOnDate$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOrganizationalUnitDto>> {
    return organizationalUnitGetOrganizationalUnitsValidOnDate(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds organizational units based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitGetOrganizationalUnitsValidOnDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitGetOrganizationalUnitsValidOnDate(params: OrganizationalUnitGetOrganizationalUnitsValidOnDate$Params, context?: HttpContext): Observable<PageOrganizationalUnitDto> {
    return this.organizationalUnitGetOrganizationalUnitsValidOnDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOrganizationalUnitDto>): PageOrganizationalUnitDto => r.body)
    );
  }

  /** Path part for operation `organizationalUnitFind()` */
  static readonly OrganizationalUnitFindPath = '/organizational-unit/search-api';

  /**
   * Finds organizational units based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFind$Response(params?: OrganizationalUnitFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOrganizationalUnitDto>> {
    return organizationalUnitFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds organizational units based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFind(params?: OrganizationalUnitFind$Params|null|undefined, context?: HttpContext): Observable<PageOrganizationalUnitDto> {
    return this.organizationalUnitFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOrganizationalUnitDto>): PageOrganizationalUnitDto => r.body)
    );
  }

  /** Path part for operation `organizationalUnitFindWithParent()` */
  static readonly OrganizationalUnitFindWithParentPath = '/organizational-unit/parent/{parentId}/search-api';

  /**
   * Finds all organization units based on passed search criteria and having the {parentId} in their ancestor chain.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitFindWithParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindWithParent$Response(params: OrganizationalUnitFindWithParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OrganizationalUnitDto>>> {
    return organizationalUnitFindWithParent(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all organization units based on passed search criteria and having the {parentId} in their ancestor chain.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitFindWithParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindWithParent(params: OrganizationalUnitFindWithParent$Params, context?: HttpContext): Observable<Array<OrganizationalUnitDto>> {
    return this.organizationalUnitFindWithParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationalUnitDto>>): Array<OrganizationalUnitDto> => r.body)
    );
  }

  /** Path part for operation `organizationalUnitGetAllManagedByUser()` */
  static readonly OrganizationalUnitGetAllManagedByUserPath = '/organizational-unit/managed-by-user';

  /**
   * Returns all the organization units the logged-in user leads.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitGetAllManagedByUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitGetAllManagedByUser$Response(params?: OrganizationalUnitGetAllManagedByUser$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return organizationalUnitGetAllManagedByUser(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the organization units the logged-in user leads.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitGetAllManagedByUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitGetAllManagedByUser(params?: OrganizationalUnitGetAllManagedByUser$Params|null|undefined, context?: HttpContext): Observable<Array<number>> {
    return this.organizationalUnitGetAllManagedByUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `organizationalUnitFindByCode()` */
  static readonly OrganizationalUnitFindByCodePath = '/organizational-unit/code/{code}';

  /**
   * Find organizational unit by code
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitFindByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindByCode$Response(params: OrganizationalUnitFindByCode$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationalUnitDto>> {
    return organizationalUnitFindByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Find organizational unit by code
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitFindByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindByCode(params: OrganizationalUnitFindByCode$Params, context?: HttpContext): Observable<OrganizationalUnitDto> {
    return this.organizationalUnitFindByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationalUnitDto>): OrganizationalUnitDto => r.body)
    );
  }

  /** Path part for operation `organizationalUnitFindByFunctionalPositionId()` */
  static readonly OrganizationalUnitFindByFunctionalPositionIdPath = '/organizational-unit/by-functional-position/{functionalPositionId}';

  /**
   * Finds organizational units based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalUnitFindByFunctionalPositionId()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindByFunctionalPositionId$Response(params: OrganizationalUnitFindByFunctionalPositionId$Params, context?: HttpContext): Observable<StrictHttpResponse<OrganizationalUnitDto>> {
    return organizationalUnitFindByFunctionalPositionId(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds organizational units based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalUnitFindByFunctionalPositionId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalUnitFindByFunctionalPositionId(params: OrganizationalUnitFindByFunctionalPositionId$Params, context?: HttpContext): Observable<OrganizationalUnitDto> {
    return this.organizationalUnitFindByFunctionalPositionId$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationalUnitDto>): OrganizationalUnitDto => r.body)
    );
  }

}
