<form [waiting]="loadingService.isLoading(this)" [formGroup]="form">
  <icz-section label="Co budete chtít vytisknout?">
    <icz-checkbox formControlName="envelope" label="Obálka spisu"></icz-checkbox>
    <icz-checkbox formControlName="metadata" label="Metadata spisu"></icz-checkbox>
    <icz-checkbox formControlName="content" label="Soupis dokumentů ve spisu"></icz-checkbox>
    <icz-checkbox formControlName="transactionLog" label="Transakční protokol spisu"></icz-checkbox>
  </icz-section>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()" primary label="Tisknout"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
