/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ErmsIssdSendHeartbeatForIssdEssl$Params {
  issdSource: string;
  orgCode: string;
}

export function ermsIssdSendHeartbeatForIssdEssl(http: HttpClient, rootUrl: string, params: ErmsIssdSendHeartbeatForIssdEssl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, ermsIssdSendHeartbeatForIssdEssl.PATH, 'get');
  if (params) {
    rb.path('issdSource', params.issdSource, {});
    rb.path('orgCode', params.orgCode, {});
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

ermsIssdSendHeartbeatForIssdEssl.PATH = '/erms-issd/heartbeat/issd/{issdSource}/essl/{orgCode}';