<div [waiting]="areDeliveryServicesLoading">
  <icz-delivery-service-combination-selector
    [form]="form"
    controlName="deliveryServiceCombinationId"
    [deliveryTypeId]="consignment.deliveryTypeId"
    (loadingStatusChanged)="areDeliveryServicesLoading = $event"
  ></icz-delivery-service-combination-selector>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              (onAction)="submit()"
              primary label="Uložit"
  ></icz-button>
  <icz-button rightButtons
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
