import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {flatten, isEqual} from 'lodash';
import {forkJoin, Observable, of} from 'rxjs';
import {debounceTime, delay, map, switchMap} from 'rxjs/operators';
import {FunctionalPositionDto} from '|api/auth-server';
import {
  DistributionClass,
  OwnConsignmentStatus,
  SubjectObjectRelationType,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto,
  SubjectRecordFindMode
} from '|api/commons';

import {ApiAuthorizationService, AuthorizedEntityType, OwnConsignmentAuthorizedOperation} from '|api/core';
import {
  ExplicitOwnConsignmentStatus,
  OwnConsignmentDto,
  OwnDigitalConsignmentCreateDto,
  OwnDigitalConsignmentDto,
  OwnDigitalConsignmentUpdateDto,
  OwnInternalDigitalConsignmentDto,
  OwnInternalPaperConsignmentCreateDto,
  OwnInternalPaperConsignmentDto,
  OwnOfficeDeskConsignmentDto,
  OwnPaperConsignmentCreateDto,
  OwnPaperConsignmentDto,
  ProofOfDeliveryCreateDto
} from '|api/sad';
import {
  isOwnInternalPaperConsignment,
  openValidWithWarningHandoverConfirmationDialog,
  OwnConsignmentWorkflowService
} from '../../own-consignment-table/own-consignment-workflow.service';
import {
  ConsignmentConsigneeCreationComponent
} from './consignment-consignee-creation/consignment-consignee-creation.component';
import {ConsignmentDeliveryFilingType, OwnConsignmentWorkflowAction} from './consignment.model';
import {CheckUnsavedFormDialogService} from '../../../dialogs/check-unsaved-form-dialog.service';
import {AbstractConsignmentDialogValidation, ConsignmentWizardStep} from './abstract-consignment-dialog-validation';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';
import {WizardComponent, WizardStepChange} from '../../../dialogs/wizard/wizard.component';
import {
  ConsignmentConsigneeSelectionComponent,
  DistributionClassDispatchSpecifier
} from './consignment-consignee-selection/consignment-consignee-selection.component';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {
  ConsignmentDialogResult,
  ConsignmentDialogResultType,
  ConsignmentDialogType,
  isDataboxConsignment,
  isEmailConsignment,
  isFaxConsignment,
  isInternalConsignment,
  isOnPlaceConsignment,
  isPaperConsignment,
  isPortalConsignment
} from './abstract-consignment-dialog-data';
import {DialogService} from '../../../../core/services/dialog.service';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {CodebookService} from '../../../../core/services/codebook.service';
import {
  getOwnDataboxConsignmentDialogForm,
  getOwnEmailConsignmentDialogForm,
  getOwnFaxConsignmentDialogForm,
  getOwnInternalConsignmentDialogForm,
  getOwnOfficeDeskConsignmentDialogForm,
  getOwnPaperOrPersonalConsignmentDialogForm,
  getOwnPortalConsignmentDialogForm,
  OwnDataboxConsignmentForm,
  OwnEmailConsignmentForm,
  OwnInternalConsignmentForm,
  OwnOfficeDeskConsignmentForm,
  OwnPaperOrPersonalConsignmentForm
} from './consignment-dialog.form';
import {
  CreateNewConsignmentDialogType,
  filterDeliveryTypesByDistributionClass,
  GenericOwnConsignment,
  GenericUpdateOwnConsignment,
  isOfficeDeskConsignment,
  ManualDeliveryResultRecordAction,
  ManualDeliveryResultRecordMode,
  OwnConsignmentWorkflowActionResult
} from '../../own-consignment-table/model/own-consignment-model';
import {
  initializeCustomFieldsFormByValuesMap
} from '../../envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {enableFormFields, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {ApplicableDeliveryType} from './applicable-delivery-type-selector/applicable-delivery-type-selector.component';
import {WITHOUT_REF_NUMBER} from '../../document-toolbar/services/toolbar-common.utils';
import {
  TABLE_ACTION_DELIVERY_TYPE_CLASSES
} from '../../subjects/subjects-table/subjects-table-consignment-actions/subjects-table-consignment-actions.component';
import {EsslComponentDto, EsslComponentSearchService} from '../../../../services/essl-component-search.service';
import {isDispatchOfficer} from '../../../../services/user-roles.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {getDefaultSearchParams} from '../../../table/table.datasource';
import {FilterOperator} from '../../../../services/search-api.service';
import {ManualDeliveryResultDialogService} from './manual-delivery-result-dialog.service';
import {AddressFormat} from '../../model/addresses.model';
import {hashed} from '../../../../lib/utils';
import {SubjectRecordSource, SubjectRecordWithSourceDto} from '../../model/subjects.model';
import {SubjectsService} from '../../../../services/subjects.service';
import {AddressEditInfo} from '../../../form-elements/address-details/address-details.component';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {
  ConsignmentWithDuplicateResolveService
} from '../../received-document-form/consignment-with-duplicate-resolve.service';
import {CommunicationErrorDialogService} from '../../../../core/services/communication-error-dialog.service';
import {SubjectIszrIdentificationService} from '../../subjects/subject-iszr-identification.service';
import {IczModalService} from '../../../../services/icz-modal.service';
import {SubjectsToolbarDisablers} from '../../subjects/subjects-toolbar/subjects-toolbar.disablers';
import {IszrRppUserRelationFilteredDto} from '|api/codebook';
import {injectModalRef} from '../../../../lib/modals';
import {SubjectAttributeType} from '../../model/subject-attribute-type';


@Component({
  selector: 'icz-consignment-dialog',
  templateUrl: './consignment-dialog.component.html',
  styleUrls: ['./consignment-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService,
    OwnConsignmentWorkflowService,
  ]
})
export class ConsignmentDialogComponent extends AbstractConsignmentDialogValidation implements OnInit, AfterViewInit, IFormGroupCheckable  {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<ConsignmentDialogResult>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private dialogService = inject(DialogService);
  private communicationErrorDialogService = inject(CommunicationErrorDialogService);
  private consignmentWithDuplicateResolveService = inject(ConsignmentWithDuplicateResolveService);
  private ownConsignmentWorkflowService = inject(OwnConsignmentWorkflowService);
  private cd = inject(ChangeDetectorRef);
  private currentSessionService = inject(CurrentSessionService);
  private manualDeliveryResultDialogService = inject(ManualDeliveryResultDialogService);
  private codebookService = inject(CodebookService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private esslComponentSearchService = inject(EsslComponentSearchService);
  private destroyRef = inject(DestroyRef);
  private subjectsService = inject(SubjectsService);
  private iczModalService = inject(IczModalService);
  private subjectIszrIdentificationService = inject(SubjectIszrIdentificationService);

  @ViewChild('wizard')
  override wizard!: WizardComponent;

  private injector = inject(Injector);

  consigneeSelectionComponent!: ConsignmentConsigneeSelectionComponent;
  consigneeCreationComponent!: ConsignmentConsigneeCreationComponent;

  canUserReadProfile = false;
  canUserWriteProfile = false;
  canUserReadComponentContent = false;
  canUserWriteContent = false;

  submitBlockedByConsigneeCreation = false;

  consignmentForEnvelopePreview: Nullable<OwnPaperConsignmentCreateDto | OwnInternalPaperConsignmentCreateDto>;

  iszrAgendasForCurrentFunctionalPosition: IszrRppUserRelationFilteredDto[] = [];

  get isUserNotPermittedForIszrOperations(): boolean {
    return Boolean(SubjectsToolbarDisablers.isUserNotPermittedForIszrOperations(this.iszrAgendasForCurrentFunctionalPosition)());
  }

  get allFormsValid() {
    // officeDeskConsignment doesn't need genericFieldsForm to be valid, because deliveryTypeId is specified in _specificFieldsForm
    return (!this.genericFieldsForm?.invalid || this.isOfficeDeskConsignment) && this._specificFieldsForm?.valid;
  }

  override get specificFieldsForm() {
    return this._specificFieldsForm;
  }

  override set specificFieldsForm(form) {
    this._specificFieldsForm = form;

    const valueChanges$ = (this._specificFieldsForm as OwnPaperOrPersonalConsignmentForm).valueChanges;

    valueChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      const formValueChangeTs = Date.now();
      if (this.enoughTimeSinceStepChangePassed(formValueChangeTs)) {
        if (this.isEditConsignmentDialogType || this.selectedComponents.length > 0) {
          this.runBackendValidation$.next();
        }
      }
    });

    valueChanges$.pipe(
      debounceTime(1000),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      const envelopeTemplateId = (
        (this._specificFieldsForm as OwnPaperOrPersonalConsignmentForm | OwnInternalConsignmentForm).value.envelopeTemplateId ??
        (this.modalData.consignment as Nullable<OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto>)?.envelopeTemplateId
      );

      if (envelopeTemplateId && (this.genericFieldsForm.disabled || this.genericFieldsForm.valid) && (this._specificFieldsForm.disabled || this._specificFieldsForm.valid)) {
        this.consignmentForEnvelopePreview = (
          this.isInternalPaperConsignment || (this.isInternalConsignmentByDistClass && this.isInternalPaperDispatch)
        ) ? this.getInternalPaperConsignmentCreateDto() : this.getPaperConsignmentCreateDto();
      }
      else {
        this.consignmentForEnvelopePreview = null;
      }
    });
  }

  get enableAllSteps() {
    return this.selectedConsigneesWithDestination.length > 0 || this.officeDeskSpecifics.isPublicPosting || this.isPortalConsignment;
  }

  get wasConsigneePreselected() {
    return (this.modalData.preselectedConsignees?.length ?? 0) > 0;
  }

  get isSelectAttachmentsVisible() {
    return (
      !this.disableEdit &&
      this.currentWizardStep !== ConsignmentWizardStep.COMPONENTS_SELECTION &&
      this.currentWizardStep !== ConsignmentWizardStep.CONSIGNEE_SELECTION &&
      this.currentWizardStep !== ConsignmentWizardStep.CONSIGNEE_CREATION
    );
  }

  get isHandOutsideFilingOfficeVisible() {
    return (
      (this.isOfficeDeskConsignment || this.selectedConsigneesWithDestination.length) &&
      this.currentWizardStep !== ConsignmentWizardStep.CONSIGNEE_SELECTION &&
      this.currentWizardStep !== ConsignmentWizardStep.CONSIGNEE_CREATION
    );
  }

  get isComponentSelectionInfoVisible() {
    return (
      this.currentWizardStep === ConsignmentWizardStep.COMPONENTS_SELECTION && (
        this.forDispatch.distributionClassForDispatch === DistributionClass.EMAIL_BOX ||
        this.forDispatch.distributionClassForDispatch === DistributionClass.ISDS_BOX ||
        this.forDispatch.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD
      )
    );
  }

  get isFreeEditingAllowed() {
    return this.isNew || this.modalData.consignment!.status === OwnConsignmentStatus.TO_HANDOVER;
  }

  get componentSelectionTabHeading(): string {
    const heading = this.isOfficeDeskConsignment ? 'Výběr příloh k vyvěšení (vybráno {{count}})' : 'Výběr příloh k vypravení (vybráno {{count}})';

    return this.translateParser.interpolate(
      this.translateService.instant(heading),
      {count: this.selectedComponents.length},
    ) ?? '';
  }

  get isConsignmentMetadataSectionVisible() {
    return this.isEditConsignmentDialogType;
  }

  get isConsigneesOverviewDisabled() {
    return !this.isConsigneeSelectionDisabled || this.modalData.type === ConsignmentDialogType.CREATE_INTERNAL_CONSIGNMENT;
  }

  get isConsigneeSelectionDisabled() {
    return (
      this.isEditConsignmentDialogType ||
      this.selectedConsigneesWithDestination.length > 0 ||
      (this.currentWizardStep !== ConsignmentWizardStep.CONSIGNEE_SELECTION && this.officeDeskSpecifics.isPublicPosting) ||
      this.modalData.type === ConsignmentDialogType.CREATE_INTERNAL_CONSIGNMENT
    );
  }

  loadingForUpdateMode = false;
  currentUserInfo: Nullable<FunctionalPositionDto> = null;
  stepChangedTs: Nullable<number>;
  componentSelectionEnabled = true;
  canAddNewAddress = true;
  consignmentCreateDate = new Date();

  readonly ExplicitOwnConsignmentStatus = ExplicitOwnConsignmentStatus;
  readonly ConsignmentDeliveryFillingType = ConsignmentDeliveryFilingType;
  readonly ConsignmentWizardStep = ConsignmentWizardStep;
  readonly DistributionClass = DistributionClass;

  consigneeSelectionSubmitHandler = () => {
    if (this.officeDeskSpecifics.isPublicPosting) {
      this.wizard.setStepById(ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION);
    } else {
      this.consigneeSelectionComponent.searchSubjects(SubjectRecordFindMode.INTERNAL_ISDS);
    }
  };

  searchSubjectsInternalOnly() {
    this.consigneeSelectionComponent.searchSubjects(SubjectRecordFindMode.INTERNAL);
  }

  goToConsignmentSpecification = () => {
    this.wizard.setStepById(ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION);
  };

  createSubjectForConsignment = () => {
    this.consigneeCreationComponent.createSubject();
  };

  private handleDistributionNodeControl(applicableDeliveryType: ApplicableDeliveryType) {
    if (
      applicableDeliveryType.distributionClass === DistributionClass.ISDS_BOX ||
      applicableDeliveryType.distributionClass === DistributionClass.EMAIL_BOX
    ) {
      if (this.modalData.documentData.refNumber) {
        const withoutRefNumber = this.translateService.instant(WITHOUT_REF_NUMBER);
        const messageSubject = `${this.modalData.documentData.refNumber ?? withoutRefNumber} - ${this.modalData.documentData.subject}`;

        (this.specificFieldsForm as IczFormGroup).get('subject')!.setValue(messageSubject);
      }
    }

    this.deliveryFilingType = applicableDeliveryType.deliveryType.processedByFilingOffice ?
      ConsignmentDeliveryFilingType.DELIVERY_FILLING_OFFICE :
      ConsignmentDeliveryFilingType.DELIVERY_NO_FILLING_OFFICE;

    const distributionNodeControl = (this.specificFieldsForm as IczFormGroup).get('dispatchOfficeDistributionNodeId')!;

    if (
      this.deliveryFilingType === ConsignmentDeliveryFilingType.DELIVERY_NO_FILLING_OFFICE &&
      this.forDispatch.distributionClassForDispatch !== DistributionClass.ON_PLACE
    ) {
      distributionNodeControl.reset();
      distributionNodeControl.disable();
      distributionNodeControl.clearValidators();
    }
    else {
      distributionNodeControl.enable();
    }
  }

  consignmentApplicableDeliveryTypeChanged(applicableDeliveryType: Nullable<ApplicableDeliveryType>) {
    if (applicableDeliveryType) {
      if (this.forDispatch.deliveryTypeIdForDispatch !== applicableDeliveryType.deliveryType.id) {
        this.forDispatch.distributionClassForDispatch = applicableDeliveryType.distributionClass;
        this.forDispatch.deliveryTypeIdForDispatch = applicableDeliveryType.deliveryType.id;
        this.forDispatch.deliveryTypeForDispatch = applicableDeliveryType.deliveryType;

        if (applicableDeliveryType.distributionClass === DistributionClass.ISDS_BOX) {
          this.specificFieldsForm = getOwnDataboxConsignmentDialogForm();
          if (this.isCreateAnyConsignmentDialogType) {
            (this.specificFieldsForm as IczFormGroup).get('empowerment')!.setValue(this.modalData.documentData.empowerment);
          }
        }
        else if (applicableDeliveryType.distributionClass === DistributionClass.EMAIL_BOX) {
          this.specificFieldsForm = getOwnEmailConsignmentDialogForm();
        }
        else if (this.isPaperConsignmentByDistClass) {
          if (this.isCreateAnyConsignmentDialogType) {
            this.specificFieldsForm = getOwnPaperOrPersonalConsignmentDialogForm();
          } else {
            (this.specificFieldsForm as IczFormGroup).get('deliveryServiceCombinationId')!.setValue(null);
            (this.specificFieldsForm as IczFormGroup).get('dispatchOfficeDistributionNodeId')!.setValue(null);
          }
        }
        else if (applicableDeliveryType.distributionClass === DistributionClass.OFFICIAL_BOARD) {
          this.specificFieldsForm = getOwnOfficeDeskConsignmentDialogForm();
          if (this.officeDeskSpecifics.deliveryType?.id) {
            this.genericFieldsForm.get('deliveryTypeId')!.setValue(this.officeDeskSpecifics.deliveryType.id!);
          }
        }

        if (applicableDeliveryType.distributionClass !== DistributionClass.OFFICIAL_BOARD) {
          this.handleDistributionNodeControl(applicableDeliveryType!);
        }

        if (!this.isFreeEditingAllowed) {
          this.determineDisabledFieldsInEditMode();
        }

        this.specificFieldsForm.recursivelyUpdateValueAndValidity();
        this.cd.detectChanges();

        this.initializeAdditionalAddresses();
      }
    }
    else {
      this.deliveryFilingType = null;
    }
  }


  enoughTimeSinceStepChangePassed(changeTs: number) {
    return this.allFormsValid && changeTs && this.stepChangedTs && (changeTs - this.stepChangedTs > 250);
  }

  determineDisabledFieldsInEditMode() {
    // Safer to disable everything at first
    this.genericFieldsForm.disable();
    this.specificFieldsForm.disable();
    this.componentSelectionEnabled = false;
    this.canAddNewAddress = false;

    if (!this.modalData.consignment) return;

    const c = this.modalData.consignment;

    const toHandover = c.status === OwnConsignmentStatus.TO_HANDOVER;
    const toTakeover = c.status === OwnConsignmentStatus.TO_TAKEOVER;
    const takenOver = c.status === OwnConsignmentStatus.TAKEN_OVER;
    const inDistribution = c.status === OwnConsignmentStatus.IN_DISTRIBUTION;
    const cancelled = c.status === OwnConsignmentStatus.CANCELLED;
    const dispatchOfficer = isDispatchOfficer(this.currentUserInfo!);

    const paperForm = this.specificFieldsForm as OwnPaperOrPersonalConsignmentForm;
    const emailForm = this.specificFieldsForm as OwnEmailConsignmentForm;
    const databoxForm = this.specificFieldsForm as OwnDataboxConsignmentForm;
    const officeDeskForm = this.specificFieldsForm as OwnOfficeDeskConsignmentForm;
    const internalForm = this.specificFieldsForm as OwnInternalConsignmentForm;

    const paperAdditionalInfoFields: (keyof OwnPaperOrPersonalConsignmentForm['value'])[] = ['consignorFileRefNumber', 'consignorRefNumber', 'codAmount', 'declaredValue'];
    const paperEnvelopeRelatedFields: (keyof OwnPaperOrPersonalConsignmentForm['value'])[] = ['deliveryServiceCombinationId', 'envelopeTemplateId', 'salutation', 'contactPerson', 'consigneeAdditionalName', 'customText'];
    const paperPhysicalAttrFields: (keyof OwnPaperOrPersonalConsignmentForm['value'])[] = ['consignmentPostingNumber', 'weight', 'payoutAmount'];
    const databoxAdditionalInfoFields: (keyof OwnDataboxConsignmentForm['value'])[] = ['deliveryServiceCombinationId', 'consignorFileRefNumber', 'consignorRefNumber', 'empowerment'];
    const databoxConsigneeFields: (keyof OwnDataboxConsignmentForm['value'])[] = ['consigneeDataBox', 'toBeDeliveredTo', 'subject', 'empowerment'];
    const emailConsigneeFields: (keyof OwnEmailConsignmentForm['value'])[] = ['consigneeEmail', 'subject', 'body', 'dispatchOfficeDistributionNodeId'];
    const officeDeskTimeRangeFields: (keyof OwnOfficeDeskConsignmentForm['value'])[] = ['toPostFrom', 'postingDuration', 'toPostTo'];
    const officeDeskFields: (keyof OwnOfficeDeskConsignmentForm['value'])[] = ['deliveryServiceCombinationId', 'dispatchOfficeDistributionNodeId', 'officeDeskCategoryId', 'officeDeskRegionId', 'label', ...officeDeskTimeRangeFields];

    if (this.canUserWriteProfile && !this.disableEdit) {
      if (toHandover) {
        if (this.isOwner) {
          this.genericFieldsForm.get('deliveryTypeId')!.enable();
          (this._specificFieldsForm as IczFormGroup).get('note')!.enable();
          this.componentSelectionEnabled = true;

          if (this.isPaperConsignmentByDistClass) {
            enableFormFields(paperForm, paperAdditionalInfoFields);
            enableFormFields(paperForm, paperEnvelopeRelatedFields);
            paperForm.get('dispatchOfficeDistributionNodeId')!.enable();
            paperForm.get('consigneeAddress')!.enable();
            paperForm.get('weight')!.enable();
            paperForm.get('payoutAmount')!.enable();
            this.canAddNewAddress = true;
          }
          else if (this.isEmailConsignmentByDistClass) {
            enableFormFields(emailForm, emailConsigneeFields);
          }
          else if (this.isDataboxConsignmentByDistClass) {
            enableFormFields(databoxForm, databoxAdditionalInfoFields);
            enableFormFields(databoxForm, databoxConsigneeFields);
            databoxForm.get('dispatchOfficeDistributionNodeId')!.enable();
          }
          else if (this.isOfficeDeskConsignment) {
            enableFormFields(officeDeskForm, officeDeskFields);
          }
          else if (this.isInternalPaperConsignment || this.isInternalDigitalConsignment) {
            enableFormFields(paperForm, paperEnvelopeRelatedFields);
            internalForm.get('consigneeOrganizationalUnitId')!.enable();
            internalForm.get('dispatchOfficeDistributionNodeId')!.enable();
            internalForm.get('consignorRefNumber')!.enable();
            internalForm.get('consignorFileRefNumber')!.enable();
            // this must be disabled in consignment edit mode, because changing deliveryTypeId results in changing internal consignment from paper to digital
            this.genericFieldsForm.get('deliveryTypeId')!.disable();
          }
        }
      }
      if (toTakeover && this.isOwner) {
        if (this.isPaperConsignmentByDistClass) {
          enableFormFields(paperForm, paperAdditionalInfoFields);
          enableFormFields(paperForm, paperEnvelopeRelatedFields);
        }
        else if (this.isDataboxConsignmentByDistClass) {
          enableFormFields(databoxForm, databoxAdditionalInfoFields);
        }
        else if (this.isInternalPaperConsignment) {
          enableFormFields(paperForm, paperEnvelopeRelatedFields);
          internalForm.get('consignorRefNumber')!.enable();
          internalForm.get('consignorFileRefNumber')!.enable();
        }
        else if (this.isInternalDigitalConsignment) {
          internalForm.get('deliveryServiceCombinationId')!.enable();
          internalForm.get('consignorRefNumber')!.enable();
          internalForm.get('consignorFileRefNumber')!.enable();
        }
      }
      if ((toTakeover || takenOver) && dispatchOfficer) {
        this.genericFieldsForm.get('deliveryTypeId')!.enable();
        (this._specificFieldsForm as IczFormGroup).get('note')!.enable();

        if (this.isPaperConsignmentByDistClass) {
          enableFormFields(paperForm, paperPhysicalAttrFields);
          enableFormFields(paperForm, paperEnvelopeRelatedFields);
        }
        else if (this.isDataboxConsignmentByDistClass) {
          databoxForm.get('deliveryServiceCombinationId')!.enable();
        }
        else if (this.isOfficeDeskConsignment) {
          enableFormFields(officeDeskForm, officeDeskTimeRangeFields);
          officeDeskForm.get('deliveryServiceCombinationId')!.enable();
        }
        else if (this.isInternalPaperConsignment) {
          enableFormFields(paperForm, paperEnvelopeRelatedFields);
        }
        else if (this.isInternalDigitalConsignment) {
          internalForm.get('deliveryServiceCombinationId')!.enable();
        }
      }
      if (takenOver && dispatchOfficer) {
        if (this.isOfficeDeskConsignment) {
          enableFormFields(officeDeskForm, officeDeskTimeRangeFields);
        }
      }
      if (inDistribution && dispatchOfficer) {
        (this._specificFieldsForm as IczFormGroup).get('note')!.enable();

        if (this.isPaperConsignmentByDistClass) {
          enableFormFields(paperForm, paperPhysicalAttrFields);
        }
      }
      if (cancelled && this.isOwner) {
        (this._specificFieldsForm as IczFormGroup).get('note')!.enable();
      }
    } else {
      this.genericFieldsForm.disable();
      this.specificFieldsForm.disable();
    }


    this.genericFieldsForm.recursivelyUpdateValueAndValidity();
    this.specificFieldsForm.recursivelyUpdateValueAndValidity();
  }

  private setWizardStepIfNeeded() {
    if (this.modalData.type === ConsignmentDialogType.EDIT_CONSIGNMENT) {
      this.currentWizardStep = ConsignmentWizardStep.CONSIGNEES_OVERVIEW;
    }
  }

  override ngOnInit() {
    super.ngOnInit();

    this.checkUnsavedService.addUnsavedFormCheck(
      this,
      [
        'genericFieldsForm',
        'specificFieldsForm'
      ]
    );

    this.isNew = !this.modalData.consignment;

    this.genericFieldsForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      const formValueChangeTs = Date.now();
      if (this.enoughTimeSinceStepChangePassed(formValueChangeTs)) {
        if (this.isEditConsignmentDialogType || this.selectedComponents.length > 0) {
          this.runBackendValidation$.next();
        }
      }
    });
    if (this.isNew) {
      if (this.modalData.type === ConsignmentDialogType.CREATE_INTERNAL_CONSIGNMENT) {
        this.currentWizardStep = ConsignmentWizardStep.ORGANIZATIONAL_UNIT_SELECTION;
        this.canAddNewAddress = false;
        this.forDispatch.selectedDistributionClass = DistributionClass.INTERNAL;
        this.specificFieldsForm = getOwnInternalConsignmentDialogForm();
      } else if (this.modalData.preselectedConsignees?.length) {
        this.selectedConsigneesWithDestination = [{
          consignee: this.modalData.preselectedConsignees[0],
          destinationAddress: undefined,
        }];
        this.currentWizardStep = ConsignmentWizardStep.CONSIGNEES_OVERVIEW;
      }
      else {
        this.currentWizardStep = ConsignmentWizardStep.CONSIGNEE_SELECTION;
      }
      this.canUserReadProfile = this.canUserWriteProfile = this.canUserWriteContent = this.canUserReadComponentContent = true;
    }
    else {
      this.loadingForUpdateMode = true;

      if (isPaperConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnPaperOrPersonalConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.ADDRESS_PLACE;
      }
      else if (isOnPlaceConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnPaperOrPersonalConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.ON_PLACE;
      }
      else if (isEmailConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnEmailConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.EMAIL_BOX;
      }
      else if (isDataboxConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnDataboxConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.ISDS_BOX;
      }
      else if (isOfficeDeskConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnOfficeDeskConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.OFFICIAL_BOARD;
        this.officeDeskSpecifics.isPublicPosting = this.modalData.consignment.publicPosting!;
        this.officeDeskSpecifics.dispatchOfficeDistributionNodeId = this.modalData.consignment.dispatchOfficeDistributionNodeId!;
      }
      else if (isInternalConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnInternalConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.INTERNAL;
      }
      else if (isFaxConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnFaxConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.PHONE_CONTACT;
        this.disableEdit = true;
      }
      else if (isPortalConsignment(this.modalData.consignment)) {
        this.specificFieldsForm = getOwnPortalConsignmentDialogForm();
        this.forDispatch.distributionClassForDispatch = DistributionClass.PORTAL_BOX;
        this.disableEdit = true;
        this.disableRecordDeliveryResult = true;
        this.disableCirculation = true;
      }

      this.forDispatch.selectedDistributionClass = this.forDispatch.distributionClassForDispatch;

      let getSubject$: Observable<Nullable<SubjectRecordDto>> = of(null);

      if (!this.officeDeskSpecifics.isPublicPosting && !(this.isPortalConsignment && !this.modalData.consignment?.consigneeId)) {
        getSubject$ = this.apiSubjectRecordNgElasticService.subjectRecordElasticGet({
          subjectId: this.modalData.consignment!.consigneeId!,
        });
      }

      const esslComponentsSearchParams = getDefaultSearchParams();
      esslComponentsSearchParams.filter = [
        {
          fieldName: 'id',
          value: String(this.modalData.consignment!.componentIds),
          operator: FilterOperator.inSet
        }
      ];

      this.loadingService.doLoading(
        forkJoin(
          getSubject$,
          this.codebookService.deliveryTypes(),
          this.apiAuthorizationService.authorizationAuthorizeOwnConsignmentOperations({
            body: {
              operationsToAuthorize: [
                OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_SHOW_PROFILE,
                OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MODIFY_PROFILE,
                OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MODIFY_CONTENT,
                OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_SHOW_COMPONENT_CONTENT,
              ],
              authorizedEntityId: this.modalData.consignment!.id,
              authorizedEntityType: AuthorizedEntityType.OWN_CONSIGNMENT
            }
          }),
          (
            this.modalData.consignment!.componentIds?.length ?
              this.esslComponentSearchService.findEsslComponentsForConsignment(esslComponentsSearchParams, this.modalData.consignment!.id, this.modalData.documentData.id).pipe(map(response => response.content)) :
              of([])
          )
        ),
        this
      ).subscribe(([subject, deliveryTypes, authorizationResult, selectedComponents]) => {
        if (authorizationResult) {
          this.canUserReadProfile = authorizationResult.authorizedOperations.find(op => op.operation === OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_SHOW_PROFILE)!.userAuthorized;
          this.canUserWriteProfile = authorizationResult.authorizedOperations.find(op => op.operation === OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MODIFY_PROFILE)!.userAuthorized;
          this.canUserWriteContent = authorizationResult.authorizedOperations.find(op => op.operation === OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MODIFY_CONTENT)!.userAuthorized;
          this.canUserReadComponentContent = authorizationResult.authorizedOperations.find(op => op.operation === OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_SHOW_COMPONENT_CONTENT)!.userAuthorized;
        }

        if (subject) {
          this.selectedConsigneesWithDestination = [{
            consignee: subject,
            destinationAddress: (this.modalData.consignment as OwnPaperConsignmentDto).consigneeAddress
          }];
        }

        const applicableDeliveryType = deliveryTypes.find(dt => dt.id === this.modalData.consignment!.deliveryTypeId)!;

        this.selectedComponents = selectedComponents;

        if (isInternalConsignment(this.modalData.consignment)) {
          this.consignmentApplicableDeliveryTypeChanged({
            deliveryType: applicableDeliveryType,
            distributionClass: DistributionClass.INTERNAL
          });
        }
        if (isEmailConsignment(this.modalData.consignment)) {
          this.consignmentApplicableDeliveryTypeChanged({
            deliveryType: applicableDeliveryType,
            distributionClass: DistributionClass.EMAIL_BOX
          });
        }
        this.genericFieldsForm.patchValue(this.modalData.consignment!, {emitEvent: false});

        if ((this.modalData.consignment as OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto).customText) {
          initializeCustomFieldsFormByValuesMap(
            (this.modalData.consignment as OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto).customText ?? {},
            (this.specificFieldsForm as IczFormGroup).get('customText') as IczFormGroup,
          );
        }

        (this.specificFieldsForm as IczFormGroup).patchValue(this.modalData.consignment!);

        this.consignmentCreateDate = new Date(this.modalData.consignment!.auditInfo!.createdAt!);

        this.forDispatch.deliveryTypeIdForDispatch = this.modalData.consignment!.deliveryTypeId;

        this.deliveryFilingType = applicableDeliveryType.processedByFilingOffice ?
          ConsignmentDeliveryFilingType.DELIVERY_FILLING_OFFICE :
          ConsignmentDeliveryFilingType.DELIVERY_NO_FILLING_OFFICE;

        this.wizard.setStepById(ConsignmentWizardStep.CONSIGNEES_OVERVIEW);
        this.loadingForUpdateMode = false;

        this.currentSessionService.currentUserFunctionalPosition$.pipe(
          takeUntilDestroyed(this.destroyRef)).subscribe(c => {
          this.currentUserInfo = c;
          this.isOwner = this.modalData!.consignment!.ownerFunctionalPositionId === this.currentUserInfo!.id;
          if (this.isEditConsignmentDialogType) {
            this.determineDisabledFieldsInEditMode();
          }
        });

        this.initializeAdditionalAddresses();
        this.initValidationTab();

        this.cd.detectChanges();
      });
    }

    this.setWizardStepIfNeeded();

    this.codebookService.deliveryTypes().subscribe(dts => {
      const deliveryTypesForOfficeDesk = filterDeliveryTypesByDistributionClass(dts, DistributionClass.OFFICIAL_BOARD);
      if (deliveryTypesForOfficeDesk.length === 1) {
        this.officeDeskSpecifics.deliveryType = deliveryTypesForOfficeDesk[0];
      }
      else {
        throw new Error('Incorrect delivery types for Office Desk configured!');
      }
    });
    this.codebookService.iszrAgendasWithActivityRolesForCurrentFunctionalPosition().subscribe(iszrAgendas => {
      this.iszrAgendasForCurrentFunctionalPosition = iszrAgendas;
    });
  }

  private initValidationTab() {
    if (this.isCreateAnyConsignmentDialogType) {
      this.setValidationTab({alreadyRan: false, loading: false});
    } else if (this.isEditConsignmentDialogType) {
      const statusesToRunInitialVali = [OwnConsignmentStatus.TO_HANDOVER, OwnConsignmentStatus.TO_TAKEOVER, OwnConsignmentStatus.TAKEN_OVER];
      if (statusesToRunInitialVali.includes(this.modalData!.consignment!.status)) {
        this.runBackendValidation$.next();
      } else {
        this.setValidationTab({alreadyRan: true, loading: false, valid: true, errors: []});
      }
    }
  }

  ngAfterViewInit() {
    of(null).pipe(takeUntilDestroyed(this.destroyRef), delay(150)).subscribe(_ => {
      if (this.isCreateOfficeDeskConsignmentDialogType) {
        this.createNewConsignmentDialogType = CreateNewConsignmentDialogType.OFFICE_DESK_ONLY;
        this.distributionClassDispatchSelected(
          {
            distributionClass: TABLE_ACTION_DELIVERY_TYPE_CLASSES.POST_ON_OFFICE_DESK!,
            publicPosting: true,
          }
        );
      }
    });
    this.cd.detectChanges();
  }

  consigneeRemoved(consignee: SubjectRecordDto) {
    const consigneeIndex = this.selectedConsigneesWithDestination.findIndex(c => c.consignee === consignee);

    if (consigneeIndex !== -1) {
      this.selectedConsigneesWithDestination.splice(consigneeIndex, 1);
      this.selectedConsigneesWithDestination = [...this.selectedConsigneesWithDestination];
    }

    this.specificFieldsForm.reset();
    this.consigneeCreationData = null;

    if (!this.selectedConsigneesWithDestination.length) {
      this.wizard.setStepById(ConsignmentWizardStep.CONSIGNEE_SELECTION);
    }

    this.forDispatch.selectedDistributionClass = null;
    this.forDispatch.distributionClassForDispatch = null;
  }

  dispatchNoFilingConsignment() {
    if (!this.consignmentValidationResult?.valid) {
      this.dialogService.showError('Není možné změnit stav zasilky, protože nebyla zvolena žádná komponenta.', 'Zásilka obsahuje chyby');
      return;
    }

    if (this.isEditConsignmentDialogType) {
      this.manualDeliveryResultDialogService.openManualDeliveryResultDialog({
        action: ManualDeliveryResultRecordAction.RECORD_DELIVERY_RESULT,
        consignment: this.modalData.consignment as OwnConsignmentDto,
        mode: ManualDeliveryResultRecordMode.AFTER_CREATE,
      }).subscribe(result => {
        if (result) {
          this.modalRef.close({
            resultType: ConsignmentDialogResultType.SAVED_WITH_PROOF_OF_DELIVERY,
            consignment: this.modalData.consignment,
          } as ConsignmentDialogResult);
        }
      });
    }
    else {
      this.manualDeliveryResultDialogService.openManualDeliveryResultDialog({
        action: ManualDeliveryResultRecordAction.RECORD_DELIVERY_RESULT,
        consignment: this.getDraftConsignmentDto(),
        mode: ManualDeliveryResultRecordMode.BEFORE_CREATE,
      }).subscribe(proofOfDelivery => {
        if (proofOfDelivery) {
          this.saveConsignment(proofOfDelivery.proofOfDelivery);
        }
      });
    }
  }

  saveAndDoWorkflowAction(action: OwnConsignmentWorkflowAction) {
    if (this.consignmentValidationResult?.valid) {
      const saveAndHandoverConsignment$ =
        this.loadingService.doLoading(
        this.doSaveConsignmentReq()!.pipe(
          switchMap(ownConsignment => {
            return forkJoin([
              this.ownConsignmentWorkflowService.workflowAction[action]([ownConsignment], {validate: false, saveFirst: false}),
              of(ownConsignment),
            ]);
          }),
          map(([_, ownConsignment]) => ownConsignment),
        ),
        this
      );
      let resultType = ConsignmentDialogResultType.SAVED;
      if (action === OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE) {
        resultType = ConsignmentDialogResultType.MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE;
      }
      else if (action === OwnConsignmentWorkflowAction.HANDOVER) {
        resultType = ConsignmentDialogResultType.HANDED_OVER;
      }
      const consignmentSavedObserver = (ownConsignment: GenericOwnConsignment | GenericUpdateOwnConsignment) => this.modalRef.close({
        resultType,
        consignment: ownConsignment,
      } as ConsignmentDialogResult);

      // If the consignment is valid and yet contains errors, first let the user confirm
      if (this.consignmentValidationResult.errors!.length) {
        openValidWithWarningHandoverConfirmationDialog(this.dialogService).subscribe(_ => {
          saveAndHandoverConsignment$?.subscribe(consignmentSavedObserver);
        });
      } else {
        saveAndHandoverConsignment$?.subscribe(consignmentSavedObserver);
      }
    }
  }

  saveReqAsUpdate() {
    return this.doSaveConsignmentReq() as Nullable<Observable<GenericUpdateOwnConsignment>>;
  }

  doSaveConsignmentReq(additionalData?: Nullable<ProofOfDeliveryCreateDto>):
    Nullable<Observable<GenericOwnConsignment | GenericUpdateOwnConsignment>> {

    let request$: Observable<OwnPaperConsignmentDto | OwnDigitalConsignmentDto | OwnOfficeDeskConsignmentDto | OwnInternalPaperConsignmentDto | OwnInternalDigitalConsignmentDto>;
    const dispatchOfficer = isDispatchOfficer(this.currentUserInfo!);
    const id = this.modalData?.consignment?.id;
    if ((this.isEditConsignmentDialogType && (this.loadingForUpdateMode || (!dispatchOfficer && !this.isOwner))) || this.isFaxConsignment || this.isPortalConsignment) {
      return;
    }

    if (this.isPaperConsignmentByDistClass) {
      // create
      if (this.isNew) {
        request$ = this.consignmentWithDuplicateResolveService.ownConsignmentCreateOwnPaperConsignment(this.getPaperConsignmentCreateDto(additionalData));
      }
      // update
      else {
        request$ = this.consignmentWithDuplicateResolveService.ownConsignmentUpdateOwnPaperConsignment(id!, this.getOwnPaperConsignmentUpdateDto());
      }
    }
    else if (this.isDigitalConsignmentByDistClass) {
      // create
      if (this.isNew) {
        let requestDto: OwnDigitalConsignmentCreateDto;

        if (this.isDataboxConsignmentByDistClass) {
          requestDto = this.getDataboxConsignmentCreateDto();
        }
        else if (this.isEmailConsignmentByDistClass) {
          requestDto = this.getEmailConsignmentCreateDto(additionalData);
        }
        request$ = this.consignmentWithDuplicateResolveService.ownConsignmentCreateOwnDigitalConsignment(requestDto!);
      }
      // update
      else {
        if (this.isOwner) {
          let requestDto: OwnDigitalConsignmentUpdateDto;

          if (this.isDataboxConsignmentByDistClass) {
            requestDto = this.getOwnDataboxConsignmentUpdateDto();
          }
          else if (this.isEmailConsignmentByDistClass) {
            requestDto = this.getOwnEmailConsignmentUpdateDto();
          }

          request$ = this.consignmentWithDuplicateResolveService.ownConsignmentUpdateOwnDigitalConsignment(id!, requestDto!);
        }
        else if (dispatchOfficer) {
          request$ = this.consignmentWithDuplicateResolveService.ownConsignmentUpdateOwnDigitalConsignment(id!, this.getOwnDigitalConsignmentUpdateDto());
        }
      }
    }
    else if (this.isOfficeDeskConsignment) {
      // create
      if (this.isNew) {
        request$ = this.consignmentWithDuplicateResolveService.ownConsignmentCreateOwnOfficeDesk(this.getOwnOfficeDeskConsignmentCreateDto());
      }
      // update
      else {
        request$ = this.consignmentWithDuplicateResolveService.ownConsignmentUpdateOwnOfficeDeskConsignment(id!, this.getOwnOfficeDeskConsignmentUpdateDto());
      }
    }
    else if (this.isInternalConsignmentByDistClass) {
      // create
      if (this.isNew) {
        if (this.isInternalPaperDispatch) {
          request$ = this.consignmentWithDuplicateResolveService.ownConsignmentCreateOwnInternalPaperConsignment(this.getInternalPaperConsignmentCreateDto());
        } else {
          request$ = this.consignmentWithDuplicateResolveService.ownConsignmentCreateOwnInternalDigitalConsignment(this.getInternalDigitalConsignmentCreateDto());
        }
      }
      // update
      else {
        if (isOwnInternalPaperConsignment(this.modalData.consignment)) {
          request$ = this.consignmentWithDuplicateResolveService.ownConsignmentUpdateOwnInternalPaperConsignment(id!, this.getOwnInternalPaperConsignmentUpdateDto());
        } else {
          request$ = this.consignmentWithDuplicateResolveService.ownConsignmentUpdateOwnInternalDigitalConsignment(id!, this.getOwnInternalDigitalConsignmentUpdateDto());
        }
      }
    }
    else {
      this.dialogService.showError('K uložení zásilky je nutné vybrat způsob doručení.', 'Nelze uložit zásilku');
      return;
    }

    if (!request$!) throw new Error('Could not determine update path');

    return request$;
  }

  saveConsignment = (proofOfDelivery?: Nullable<ProofOfDeliveryCreateDto>) => {
    this.genericFieldsForm.recursivelyUpdateValueAndValidity();
    if (!this.genericFieldsForm.invalid) {
      this.loadingService.doLoading(
        this.doSaveConsignmentReq(proofOfDelivery)!,
        this
      ).subscribe({
        next: ownConsignment => {
          if (!ownConsignment) return;
          this.modalRef.close({
            resultType: (
              proofOfDelivery ?
                ConsignmentDialogResultType.SAVED_WITH_PROOF_OF_DELIVERY :
                ConsignmentDialogResultType.SAVED
            ),
            consignment: ownConsignment,
          } as ConsignmentDialogResult);
        },
        error: error => {
          this.communicationErrorDialogService.showCommunicationError(error);
        }
      });
    }
  };

  identifySubject() {
    const searchForm = this.consigneeSelectionComponent?.subjectSearchWithResultsComponent.form;

    if (!searchForm) return;
    const searchValue = searchForm.getRawValue();
    this.subjectIszrIdentificationService.tryIdentifySubject(searchValue, true, this.injector).subscribe(identifiedSubject => {
      if (identifiedSubject?.subject) {
        const subjectWithSource: SubjectRecordWithSourceDto = {...identifiedSubject.subject, subjectSource: SubjectRecordSource.ISZR};
        this.consigneeSelectionComponent.subjectSearchWithResultsComponent.setSubjectsForTable([subjectWithSource]);
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  selectComponents() {
    this.wizard.setStepById(ConsignmentWizardStep.COMPONENTS_SELECTION);
  }

  componentsSelected($event: EsslComponentDto[]) {
    const tableSelectionIds = $event.map(c => c.id).sort();
    const dialogSelectionIds = this.selectedComponents.map(c => c.id).sort();

    this.selectedComponents = $event;

    const lastComponentsSelectedTs = Date.now();

    if (!isEqual(tableSelectionIds, dialogSelectionIds) && this.enoughTimeSinceStepChangePassed(lastComponentsSelectedTs)) {
      this.runBackendValidation$.next();
    }
  }

  goToConsigneeCreation($event: Record<string, any>) {
    this.consigneeCreationData = {
      relationType: SubjectObjectRelationType.ADDRESSEE,
      searchForm: $event,
    };

    this.wizard.setStepById(ConsignmentWizardStep.CONSIGNEE_CREATION);
  }

  wizardStepChanged(stepChange: WizardStepChange) {
    this.currentWizardStep = stepChange.currentStep.id as ConsignmentWizardStep;
    this.stepChangedTs = Date.now();
  }

  distributionClassDispatchSelected(specifier: DistributionClassDispatchSpecifier) {
    this.forDispatch.selectedDistributionClass = specifier.distributionClass;

    if (specifier.distributionClass === null) {
      if (this.isCreateOfficeDeskConsignmentDialogType) {
        // If specifier is null, but we're in dialog restricted to CREATE OFFICE DESK, it's needed to force DistributionClass.OFFICIAL_BOARD because
        //   deliveryType selector is hidden
        this.forDispatch.distributionClassForDispatch = DistributionClass.OFFICIAL_BOARD;
      } else {
        // Otherwise, do reset distributionClassForDispatch
        this.forDispatch.distributionClassForDispatch = null;
      }
      this.officeDeskSpecifics.isPublicPosting = false;
      this.wizard.setStepById(ConsignmentWizardStep.CONSIGNEE_SELECTION);
      return;
    }
    else if (specifier.distributionClass !== DistributionClass.OFFICIAL_BOARD) {
      this.selectedConsigneesWithDestination = [{consignee: specifier.subject!}];
      this.officeDeskSpecifics.isPublicPosting = false;
      this.wizard.setStepById(ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION);
    }
    else if (specifier.distributionClass === DistributionClass.OFFICIAL_BOARD) {
      if (specifier.publicPosting) {
        this.officeDeskSpecifics.isPublicPosting = true;
        this.cd.detectChanges();
      } else {
        this.selectedConsigneesWithDestination = [{consignee: specifier.subject!}];
        this.officeDeskSpecifics.isPublicPosting = false;
        this.wizard.setStepById(ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION);
      }

      this.consignmentApplicableDeliveryTypeChanged({
        deliveryType: this.officeDeskSpecifics.deliveryType!,
        distributionClass: DistributionClass.OFFICIAL_BOARD
      });
    }
  }

  subjectCreationRequested(subject: SubjectRecordCreateOrUpdateDto) {
    this.subjectToCreate = subject;
    this.selectedConsigneesWithDestination = [{consignee: {...subject, identifiable: false, classification: subject.classification!}}];
    this.wizard.setStepById(ConsignmentWizardStep.CONSIGNMENT_SPECIFICATION);
  }

  deliveryTypeSelectorLoadingStatusChanged(isComponentLoading: boolean) {
    if (isComponentLoading) {
      this.loadingService.startLoading(this);
    }
    else {
      this.loadingService.endLoading(this);
    }
  }

  handleWorkflowAction(result: OwnConsignmentWorkflowActionResult) {
    this.loadingService.endLoading(this);
    if (result.success) {
      this.modalRef.close({
        resultType: result.action,
        consignment: this.modalData.consignment,
      } as ConsignmentDialogResult);
    }
  }

  addressEdited(addressEditInfo: AddressEditInfo) {
    //   const consignee = this.selectedConsigneesWithDestination[0]?.consignee ?? this.subjectToCreate;
    //   if (!consignee.attributes) {
    //   }
    //
    //   const consigneeAttributesHashed: Record<keyof SubjectAttributesDto, string | string[]> = cloneDeep(consignee.attributes) as Record<keyof SubjectAttributesDto, string | string[]>;
    //   Object.keys(consigneeAttributesHashed).forEach(k => {
    //     if (Array.isArray(consigneeAttributesHashed[k as keyof SubjectAttributesDto])) {
    //       consigneeAttributesHashed[k as keyof SubjectAttributesDto] = (consigneeAttributesHashed[k as keyof SubjectAttributesDto] as string[]).map(item => hashed(item)) as string[];
    //     } else {
    //       consigneeAttributesHashed[k as keyof SubjectAttributesDto] = hashed(consigneeAttributesHashed[k as keyof SubjectAttributesDto] as string);
    //     }
    //   });
    //
    //   const originalAddressHash = {type: addressEditInfo.originalAddress.type, hash: hashed(addressEditInfo.originalAddress.address)};
    //   const editedAddress = addressEditInfo.editedAddress.address;
    //   const editedAddressClass = editedAddress._Class as AddressFormat;
    //
    // editedAddress.country = editedAddressClass === AddressFormat.CzechAddressDto ?
    //   'CZE' : (editedAddressClass === AddressFormat.SlovakAddressDto ? 'SVK' : editedAddress.country);
    //
    // const addressIndexToEdit = consigneeAttrHashes.indexOf(originalAddressHash);
    //
    // if (addressIndexToEdit !== -1) {
    //   (this.specificFieldsForm as OwnPaperOrPersonalConsignmentForm).get('consigneeAddress')!.setValue(editedAddress);
    //   this.selectedConsigneesWithDestination[0].destinationAddress = editedAddress;
    //   consignee.attributes[addressIndexToEdit].value = editedAddress;
    //
    //   if (this.modalData.consignment) {
    //     (this.modalData.consignment as OwnPaperConsignmentDto).consigneeAddress = editedAddress;
    //   }
    //
    //   if (!this.subjectToCreate && consignee.id) {
    //     this.selectedConsigneesWithDestination[0]!.consignee = mapSubjectAttributesForDisplay([this.selectedConsigneesWithDestination[0]!.consignee])[0];
    //     this.loadingService.doLoading(
    //       this.apiSubjectRegisterAdministrationService.subjectRegisterAdministrationReplaceSubjectRecordAttributes({
    //         uuid: consignee.id!,
    //         body: {
    //           attributes: consignee.attributes,
    //           valueCorrectionMode: addressEditInfo.valueCorrectionMode === ValueCorrectionMode.CORRECTION,
    //         }
    //       }).pipe(
    //         switchMap(_ => {
    //           if (!this.isNew) {
    //             return this.doSaveConsignmentReq()!;
    //           }
    //           else {
    //             return of();
    //           }
    //         }),
    //       ),
    //       this
    //     ).subscribe();
    //   }
    // }
    // else {
    //   if (this.additionalAddresses.length) {
    //     const additionalAddressHashes = this.additionalAddresses.map(aa => hashed(aa.address));
    //     const additionalAddressIndexToEdit = additionalAddressHashes.indexOf(originalAddressHash);
    //
    //     if (additionalAddressIndexToEdit !== -1) {
    //       this.additionalAddresses[additionalAddressIndexToEdit].address = editedAddress;
    //       (this.specificFieldsForm as OwnPaperOrPersonalConsignmentForm).get('consigneeAddress')!.setValue(editedAddress);
    //       this.selectedConsigneesWithDestination[0].destinationAddress = editedAddress;
    //     }
    //     else {
    //       throw new Error('Address to edit not found in additional addresses.');
    //     }
    //   }
    //   else {
    //     throw new Error('Address to edit not found in subject attributes neither additional addresses.');
    //   }
    // }
    //
    // this.initializeAdditionalAddresses();
    //
    // if (this.subjectToCreate) {
    //   this.subjectToCreate = consignee;
    // }
    // else {
    //   this.selectedConsigneesWithDestination[0]!.consignee = consignee;
    // }
  }

  private initializeAdditionalAddresses() {
    if (this.modalData.type === ConsignmentDialogType.EDIT_CONSIGNMENT && this.usePaperConsignmentSpecification) {
      const consigneeValidAddresses = this.subjectsService.getSubjectPhysicalAddresses(this.selectedConsigneesWithDestination[0]!.consignee.attributes)!;
      const consignmentAddress = (this.modalData.consignment as OwnPaperConsignmentDto).consigneeAddress;
      const consignmentAddressHash = hashed(consignmentAddress);

      const consigneeValidAddressHashes = Object.values(consigneeValidAddresses).map(v => hashed(v));
      const validAdditionalAddressHashes = this.additionalAddresses.map(aa => hashed(aa.address!));

      if (
        consignmentAddress &&
        !flatten(Object.values(consigneeValidAddressHashes)).includes(consignmentAddressHash) &&
        !validAdditionalAddressHashes.includes(consignmentAddressHash)
      ) {
        this.additionalAddresses = [
          ...(this.additionalAddresses ?? []),
          {
            type: SubjectAttributeType.ADDITIONAL_ADDRESS,
            address: consignmentAddress,
            format: consignmentAddress._Class as AddressFormat,
            isCurrentlyValid: false,
          }
        ];
      }
    }

    this.additionalEmailAddresses = [];
  }

  private getDraftConsignmentDto(): OwnPaperConsignmentCreateDto | OwnDigitalConsignmentCreateDto {
    if (this.isPaperConsignmentByDistClass) {
      return this.getPaperConsignmentCreateDto();
    }
    else if (this.isDigitalConsignmentByDistClass) {
      if (this.forDispatch.distributionClassForDispatch === DistributionClass.ISDS_BOX) {
        return this.getDataboxConsignmentCreateDto();
      }
      else if (this.forDispatch.distributionClassForDispatch === DistributionClass.EMAIL_BOX) {
        return this.getEmailConsignmentCreateDto();
      }
      else {
        throw new Error('Draft digital consignment type unknown.');
      }
    }
    else {
      throw new Error('Draft consignment type unknown.');
    }
  }

  protected readonly OwnConsignmentWorkflowAction = OwnConsignmentWorkflowAction;
}
