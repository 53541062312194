<div [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async" class="h-full">
  @if (showPreselectionFailed) {
    <icz-alert heading="Není možné jednoznačně určit, které externí aplikaci se mají vybrané objekty předat. Objekty  buď nemají nastaven typ nebo mají nastaveny rozdílné typy, které směrují k různým externím aplikacím."></icz-alert>
  }
  <icz-section label="Předání externí aplikaci">
    <icz-form-autocomplete label="Zvolte externí aplikaci, které má být předáno" formControlName="issdId" [options]="issdOptions"></icz-form-autocomplete>
  </icz-section>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
  (onAction)="submit()" primary label="Postoupit"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
  (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
