<ng-container [formGroup]="form">
  <icz-form-autocomplete
    #searchField
    class="w-full"
    [noResultsTemplate]="noResultsTemplate"
    formControlName="searchField" [options]="[]"
    [optionsDefinitionFactory]="makeFileSearchOptionsDefinition"
    placeholder="Hledej adresu"
    [minSearchTermLength]="minSearchTermLength"
    (valueChange)="onAddressSelected($event)"
  >
    <icz-icon prefix svgIcon="search" size="small"></icz-icon>
  </icz-form-autocomplete>
</ng-container>

<ng-template #noResultsTemplate let-context>
  <div class="no-options-found">
    {{ ((context.searchTerm?.length ?? 0) >= minSearchTermLength ? 'Žádné adresy nenalezeny' : 'Pro vyhledání zadejte minimálně tři znaky') | translate }}
  </div>
</ng-template>
