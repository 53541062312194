/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ExternalNotificationRejectionReasonConfigurationDto } from '../models/external-notification-rejection-reason-configuration-dto';
import { externalNotificationRejectionReasonConfigurationFind } from '../fn/external-notification-rejection-reason-configuration/external-notification-rejection-reason-configuration-find';
import { ExternalNotificationRejectionReasonConfigurationFind$Params } from '../fn/external-notification-rejection-reason-configuration/external-notification-rejection-reason-configuration-find';
import { externalNotificationRejectionReasonConfigurationFindById } from '../fn/external-notification-rejection-reason-configuration/external-notification-rejection-reason-configuration-find-by-id';
import { ExternalNotificationRejectionReasonConfigurationFindById$Params } from '../fn/external-notification-rejection-reason-configuration/external-notification-rejection-reason-configuration-find-by-id';
import { externalNotificationRejectionReasonConfigurationUpdate } from '../fn/external-notification-rejection-reason-configuration/external-notification-rejection-reason-configuration-update';
import { ExternalNotificationRejectionReasonConfigurationUpdate$Params } from '../fn/external-notification-rejection-reason-configuration/external-notification-rejection-reason-configuration-update';
import { PageExternalNotificationRejectionReasonConfigurationDto } from '../models/page-external-notification-rejection-reason-configuration-dto';


/**
 * Controller for external notification rejection reason configurations.
 */
@Injectable({ providedIn: 'root' })
export class ApiExternalNotificationRejectionReasonConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalNotificationRejectionReasonConfigurationFindById()` */
  static readonly ExternalNotificationRejectionReasonConfigurationFindByIdPath = '/notification/external-notification-rejection-reason-configuration/{id}';

  /**
   * Find external notification rejection reason configuration by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationRejectionReasonConfigurationFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationRejectionReasonConfigurationFindById$Response(params: ExternalNotificationRejectionReasonConfigurationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationRejectionReasonConfigurationDto>> {
    return externalNotificationRejectionReasonConfigurationFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find external notification rejection reason configuration by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationRejectionReasonConfigurationFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationRejectionReasonConfigurationFindById(params: ExternalNotificationRejectionReasonConfigurationFindById$Params, context?: HttpContext): Observable<ExternalNotificationRejectionReasonConfigurationDto> {
    return this.externalNotificationRejectionReasonConfigurationFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationRejectionReasonConfigurationDto>): ExternalNotificationRejectionReasonConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationRejectionReasonConfigurationUpdate()` */
  static readonly ExternalNotificationRejectionReasonConfigurationUpdatePath = '/notification/external-notification-rejection-reason-configuration/{id}';

  /**
   * Update existing external notification rejection reason configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationRejectionReasonConfigurationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationRejectionReasonConfigurationUpdate$Response(params: ExternalNotificationRejectionReasonConfigurationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationRejectionReasonConfigurationDto>> {
    return externalNotificationRejectionReasonConfigurationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing external notification rejection reason configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationRejectionReasonConfigurationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationRejectionReasonConfigurationUpdate(params: ExternalNotificationRejectionReasonConfigurationUpdate$Params, context?: HttpContext): Observable<ExternalNotificationRejectionReasonConfigurationDto> {
    return this.externalNotificationRejectionReasonConfigurationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationRejectionReasonConfigurationDto>): ExternalNotificationRejectionReasonConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationRejectionReasonConfigurationFind()` */
  static readonly ExternalNotificationRejectionReasonConfigurationFindPath = '/notification/external-notification-rejection-reason-configuration/search-api';

  /**
   * A standard SearchApi endpoint returning external notification rejection reason configurations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationRejectionReasonConfigurationFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationRejectionReasonConfigurationFind$Response(params?: ExternalNotificationRejectionReasonConfigurationFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageExternalNotificationRejectionReasonConfigurationDto>> {
    return externalNotificationRejectionReasonConfigurationFind(this.http, this.rootUrl, params, context);
  }

  /**
   * A standard SearchApi endpoint returning external notification rejection reason configurations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationRejectionReasonConfigurationFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationRejectionReasonConfigurationFind(params?: ExternalNotificationRejectionReasonConfigurationFind$Params|null|undefined, context?: HttpContext): Observable<PageExternalNotificationRejectionReasonConfigurationDto> {
    return this.externalNotificationRejectionReasonConfigurationFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageExternalNotificationRejectionReasonConfigurationDto>): PageExternalNotificationRejectionReasonConfigurationDto => r.body)
    );
  }

}
