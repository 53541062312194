import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DigitalComponentVersionSignatureFlagType} from '|api/commons';
import {ReceivedEpdzAttachmentDto, ReceivedEpdzMessageDetailDto} from '|api/sad';
import {epdzToEsslSignatureFlags} from '../epdz-attachment-subset-selector/epdz-attachment.utils';

@Component({
  selector: 'icz-digital-message-signature-status',
  templateUrl: './digital-message-signature-status.component.html',
  styleUrls: ['./digital-message-signature-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalMessageSignatureStatusComponent {
  @Input({required: true})
  message: Nullable<ReceivedEpdzMessageDetailDto>;

  get originalAttachment(): Nullable<ReceivedEpdzAttachmentDto> {
    return this.message?.receivedEpdzAttachmentDtoList?.find(a => a.type === 'ORIGINAL');
  }

  get isSigned(): boolean {
    return Boolean(this.originalAttachment?.signatureFlags?.length && this.originalAttachment.signatureFlags[0] !== DigitalComponentVersionSignatureFlagType.NONE);
  }

  readonly epdzToEsslSignatureFlags = epdzToEsslSignatureFlags;
}
