import {CirculationActivityFlowType, CirculationActivityType, CirculationTaskState} from '|api/commons';
import {CirculationActivityDto, CirculationActivityTargetParticipantDto, CirculationTaskCompleteDto} from '|api/flow';

export enum TaskInfoType {
  ASSIGNED = 'ASSIGNED',
  UNASSIGNED = 'UNASSIGNED',
}

interface CommonTaskInfo {
  taskInfoType: TaskInfoType;
  orderNumber: Nullable<number>;
  hasChildActivities: boolean;
  ownerFunctionalPositionId: number;
  parentActivityType: CirculationActivityType;
}

export interface AssignedTaskInfo extends CommonTaskInfo {
  taskInfoType: TaskInfoType.ASSIGNED;
  taskId: number;
  taskState: CirculationTaskState;
  ownerUserId: number;
  assignmentDate: string;
  settlementDate?: Nullable<string>;
  instruction?: Nullable<string>;
  settlementNote?: Nullable<string>;
  rejectionReason?: Nullable<string>;
  revokeReason?: Nullable<string>;
}

export interface UnassignedTaskInfo extends CommonTaskInfo {
  taskInfoType: TaskInfoType.UNASSIGNED;
  // eslint-disable-next-line @typescript-eslint/ban-types -- always null for this specialisation
  taskState: null;
}

export type TaskInfo = AssignedTaskInfo | UnassignedTaskInfo;

export function getTasksInfo(
  activity: CirculationActivityDto,
  activityTasks: CirculationTaskCompleteDto[],
  activityParticipants: CirculationActivityTargetParticipantDto[],
  previousTaskInfo: TaskInfo[] = [],
): TaskInfo[] {
  const activityTasksInfo: TaskInfo[] = [];
  const nonManagementTasks = activityTasks.filter(t => !t.isManagementTask).sort((t1, t2) => t1.id - t2.id);

  activityParticipants = [...activityParticipants].sort((p1, p2) => p1.taskAssignmentOrder! - p2.taskAssignmentOrder!);

  if (activity.flowType === CirculationActivityFlowType.SERIAL) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- I need numeric index of task as it will be used in task order numeral.
    for (let i = 0; i < activityParticipants.length; ++i) {
      const activityParticipant = activityParticipants[i];
      const isParticipantAlreadyUsed = previousTaskInfo.some(
        ti => ti.ownerFunctionalPositionId === activityParticipant.targetParticipantId
      );

      if (!isParticipantAlreadyUsed) {
        const taskOfCurrentParticipant = nonManagementTasks.find(
          t => t.participant && t.participant.targetParticipantId === activityParticipant.targetParticipantId
        );

        let activityTaskInfo: TaskInfo;

        if (taskOfCurrentParticipant) {
          activityTaskInfo = {
            taskInfoType: TaskInfoType.ASSIGNED,
            orderNumber: i + 1,
            hasChildActivities: Boolean(activity.childActivities?.length),
            ownerFunctionalPositionId: activityParticipant.targetParticipantId,
            parentActivityType: activity.activityType,
            taskId: taskOfCurrentParticipant.id,
            taskState: taskOfCurrentParticipant.taskState,
            ownerUserId: taskOfCurrentParticipant.ownerUserId!,
            assignmentDate: taskOfCurrentParticipant.assignmentDate,
            settlementDate: taskOfCurrentParticipant.settlementDate,
            instruction: taskOfCurrentParticipant.instruction,
            settlementNote: taskOfCurrentParticipant.settlementNote,
            rejectionReason: taskOfCurrentParticipant.rejectionReason,
            revokeReason: taskOfCurrentParticipant.revokeReason,
          };
        }
        else {
          activityTaskInfo = {
            taskInfoType: TaskInfoType.UNASSIGNED,
            taskState: null,
            orderNumber: i + 1,
            hasChildActivities: Boolean(activity.childActivities?.length),
            ownerFunctionalPositionId: activityParticipant.targetParticipantId,
            parentActivityType: activity.activityType,
          };
        }

        activityTasksInfo.push(activityTaskInfo);
      }
    }
  }
  else {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of -- I need numeric index of task as it will be used in task order numeral.
    for (let i = 0; i < nonManagementTasks.length; ++i) {
      const currentTask = nonManagementTasks[i];
      const currentTaskParticipant = activityParticipants.find(p => p.targetParticipantId === currentTask.participant?.targetParticipantId);

      const activityTaskInfo: TaskInfo = {
        taskInfoType: TaskInfoType.ASSIGNED,
        orderNumber: null,
        hasChildActivities: Boolean(activity.childActivities?.length),
        ownerFunctionalPositionId: currentTaskParticipant?.targetParticipantId ?? currentTask.ownerFunctionalPositionId,
        parentActivityType: activity.activityType,
        taskId: currentTask.id,
        taskState: currentTask.taskState,
        ownerUserId: currentTask.ownerUserId!,
        assignmentDate: currentTask.assignmentDate,
        settlementDate: currentTask.settlementDate,
        instruction: currentTask.instruction,
        settlementNote: currentTask.settlementNote,
        rejectionReason: currentTask.rejectionReason,
        revokeReason: currentTask.revokeReason,
      };

      activityTasksInfo.push(activityTaskInfo);
    }
  }

  return activityTasksInfo;
}
