/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { entityClassClose } from '../fn/entity-class/entity-class-close';
import { EntityClassClose$Params } from '../fn/entity-class/entity-class-close';
import { entityClassCreate } from '../fn/entity-class/entity-class-create';
import { EntityClassCreate$Params } from '../fn/entity-class/entity-class-create';
import { entityClassCreateMany } from '../fn/entity-class/entity-class-create-many';
import { EntityClassCreateMany$Params } from '../fn/entity-class/entity-class-create-many';
import { entityClassDelete } from '../fn/entity-class/entity-class-delete';
import { EntityClassDelete$Params } from '../fn/entity-class/entity-class-delete';
import { EntityClassDto } from '../models/entity-class-dto';
import { entityClassFindAll } from '../fn/entity-class/entity-class-find-all';
import { EntityClassFindAll$Params } from '../fn/entity-class/entity-class-find-all';
import { entityClassFindAllValidNowByType } from '../fn/entity-class/entity-class-find-all-valid-now-by-type';
import { EntityClassFindAllValidNowByType$Params } from '../fn/entity-class/entity-class-find-all-valid-now-by-type';
import { entityClassFindByClassificationSchemeId } from '../fn/entity-class/entity-class-find-by-classification-scheme-id';
import { EntityClassFindByClassificationSchemeId$Params } from '../fn/entity-class/entity-class-find-by-classification-scheme-id';
import { entityClassFindById } from '../fn/entity-class/entity-class-find-by-id';
import { EntityClassFindById$Params } from '../fn/entity-class/entity-class-find-by-id';
import { entityClassGenerateClassificationCode } from '../fn/entity-class/entity-class-generate-classification-code';
import { EntityClassGenerateClassificationCode$Params } from '../fn/entity-class/entity-class-generate-classification-code';
import { entityClassSearch } from '../fn/entity-class/entity-class-search';
import { EntityClassSearch$Params } from '../fn/entity-class/entity-class-search';
import { entityClassUpdate } from '../fn/entity-class/entity-class-update';
import { EntityClassUpdate$Params } from '../fn/entity-class/entity-class-update';
import { IdentifierDto } from '|api/commons';
import { PageEntityClassDto } from '../models/page-entity-class-dto';


/**
 * Controller for Entity Classes
 */
@Injectable({ providedIn: 'root' })
export class ApiEntityClassService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `entityClassFindById()` */
  static readonly EntityClassFindByIdPath = '/codebook/classifier/entity-class/{id}';

  /**
   * Find entity class by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindById$Response(params: EntityClassFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<EntityClassDto>> {
    return entityClassFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find entity class by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindById(params: EntityClassFindById$Params, context?: HttpContext): Observable<EntityClassDto> {
    return this.entityClassFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EntityClassDto>): EntityClassDto => r.body)
    );
  }

  /** Path part for operation `entityClassUpdate()` */
  static readonly EntityClassUpdatePath = '/codebook/classifier/entity-class/{id}';

  /**
   * Update existing entity class
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassUpdate$Response(params: EntityClassUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EntityClassDto>> {
    return entityClassUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing entity class
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassUpdate(params: EntityClassUpdate$Params, context?: HttpContext): Observable<EntityClassDto> {
    return this.entityClassUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EntityClassDto>): EntityClassDto => r.body)
    );
  }

  /** Path part for operation `entityClassDelete()` */
  static readonly EntityClassDeletePath = '/codebook/classifier/entity-class/{id}';

  /**
   * Deletes entity class.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassDelete$Response(params: EntityClassDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return entityClassDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes entity class.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassDelete(params: EntityClassDelete$Params, context?: HttpContext): Observable<void> {
    return this.entityClassDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `entityClassClose()` */
  static readonly EntityClassClosePath = '/codebook/classifier/entity-class/close/{id}';

  /**
   * Closes entity class.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassClose()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassClose$Response(params: EntityClassClose$Params, context?: HttpContext): Observable<StrictHttpResponse<EntityClassDto>> {
    return entityClassClose(this.http, this.rootUrl, params, context);
  }

  /**
   * Closes entity class.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassClose$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassClose(params: EntityClassClose$Params, context?: HttpContext): Observable<EntityClassDto> {
    return this.entityClassClose$Response(params, context).pipe(
      map((r: StrictHttpResponse<EntityClassDto>): EntityClassDto => r.body)
    );
  }

  /** Path part for operation `entityClassFindAll()` */
  static readonly EntityClassFindAllPath = '/codebook/classifier/entity-class';

  /**
   * Finds all entity classes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindAll$Response(params?: EntityClassFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EntityClassDto>>> {
    return entityClassFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all entity classes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindAll(params?: EntityClassFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<EntityClassDto>> {
    return this.entityClassFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EntityClassDto>>): Array<EntityClassDto> => r.body)
    );
  }

  /** Path part for operation `entityClassCreate()` */
  static readonly EntityClassCreatePath = '/codebook/classifier/entity-class';

  /**
   * Create new entity class
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassCreate$Response(params: EntityClassCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EntityClassDto>> {
    return entityClassCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new entity class
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassCreate(params: EntityClassCreate$Params, context?: HttpContext): Observable<EntityClassDto> {
    return this.entityClassCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EntityClassDto>): EntityClassDto => r.body)
    );
  }

  /** Path part for operation `entityClassGenerateClassificationCode()` */
  static readonly EntityClassGenerateClassificationCodePath = '/codebook/classifier/entity-class/generate-classification-code';

  /**
   * Generate Classification Code.
   *
   * Generates classification code based on input parameters. Please note that the code may be null in the returned object. This means that classification codes of entity classes at the corresponding nesting level must be set manually.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassGenerateClassificationCode()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassGenerateClassificationCode$Response(params: EntityClassGenerateClassificationCode$Params, context?: HttpContext): Observable<StrictHttpResponse<IdentifierDto>> {
    return entityClassGenerateClassificationCode(this.http, this.rootUrl, params, context);
  }

  /**
   * Generate Classification Code.
   *
   * Generates classification code based on input parameters. Please note that the code may be null in the returned object. This means that classification codes of entity classes at the corresponding nesting level must be set manually.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassGenerateClassificationCode$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassGenerateClassificationCode(params: EntityClassGenerateClassificationCode$Params, context?: HttpContext): Observable<IdentifierDto> {
    return this.entityClassGenerateClassificationCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<IdentifierDto>): IdentifierDto => r.body)
    );
  }

  /** Path part for operation `entityClassCreateMany()` */
  static readonly EntityClassCreateManyPath = '/codebook/classifier/entity-class/batch-create';

  /**
   * Create new entity classes all at once.
   *
   * Creates multiple new entity classes all at once. This implies that should creation of any entity class fail, none of the passed in entity classes will be created! Returned DTOs are in the same order as input DTOs. There are no requirements on the order of input DTOs. Hierarchies must be specified by means of transient IDs.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassCreateMany()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassCreateMany$Response(params: EntityClassCreateMany$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EntityClassDto>>> {
    return entityClassCreateMany(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new entity classes all at once.
   *
   * Creates multiple new entity classes all at once. This implies that should creation of any entity class fail, none of the passed in entity classes will be created! Returned DTOs are in the same order as input DTOs. There are no requirements on the order of input DTOs. Hierarchies must be specified by means of transient IDs.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassCreateMany$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  entityClassCreateMany(params: EntityClassCreateMany$Params, context?: HttpContext): Observable<Array<EntityClassDto>> {
    return this.entityClassCreateMany$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EntityClassDto>>): Array<EntityClassDto> => r.body)
    );
  }

  /** Path part for operation `entityClassFindAllValidNowByType()` */
  static readonly EntityClassFindAllValidNowByTypePath = '/codebook/classifier/entity-class/{entityClassType}/valid';

  /**
   * Find currently valid entity classes by their type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassFindAllValidNowByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindAllValidNowByType$Response(params: EntityClassFindAllValidNowByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EntityClassDto>>> {
    return entityClassFindAllValidNowByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Find currently valid entity classes by their type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassFindAllValidNowByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindAllValidNowByType(params: EntityClassFindAllValidNowByType$Params, context?: HttpContext): Observable<Array<EntityClassDto>> {
    return this.entityClassFindAllValidNowByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EntityClassDto>>): Array<EntityClassDto> => r.body)
    );
  }

  /** Path part for operation `entityClassSearch()` */
  static readonly EntityClassSearchPath = '/codebook/classifier/entity-class/search-api';

  /**
   * Find all values in entity class based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassSearch$Response(params?: EntityClassSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEntityClassDto>> {
    return entityClassSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in entity class based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassSearch(params?: EntityClassSearch$Params|null|undefined, context?: HttpContext): Observable<PageEntityClassDto> {
    return this.entityClassSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEntityClassDto>): PageEntityClassDto => r.body)
    );
  }

  /** Path part for operation `entityClassFindByClassificationSchemeId()` */
  static readonly EntityClassFindByClassificationSchemeIdPath = '/codebook/classifier/entity-class/by-scheme/{classificationSchemeId}';

  /**
   * Find entity classes by its classification scheme id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entityClassFindByClassificationSchemeId()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindByClassificationSchemeId$Response(params: EntityClassFindByClassificationSchemeId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EntityClassDto>>> {
    return entityClassFindByClassificationSchemeId(this.http, this.rootUrl, params, context);
  }

  /**
   * Find entity classes by its classification scheme id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entityClassFindByClassificationSchemeId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entityClassFindByClassificationSchemeId(params: EntityClassFindByClassificationSchemeId$Params, context?: HttpContext): Observable<Array<EntityClassDto>> {
    return this.entityClassFindByClassificationSchemeId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EntityClassDto>>): Array<EntityClassDto> => r.body)
    );
  }

}
