import {inject, Injectable} from '@angular/core';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {
  AdminToastType, SAdminErrorTemplateData, SAdminTemplateData,
  SDocumentTypeImportErrorTemplateData,
  SDocumentTypeImportSuccessTemplateData
} from './admin-toast.service';


@Injectable({
  providedIn: 'root',
})
export class AdminNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.DOCUMENT_TYPE_IMPORT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          AdminToastType.DOCUMENT_TYPES_IMPORT_SUCCESS,
          parsedBody.parameters,
          SDocumentTypeImportSuccessTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.DOCUMENT_TYPE_IMPORT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          AdminToastType.DOCUMENT_TYPES_IMPORT_ERROR,
          parsedBody.parameters,
          SDocumentTypeImportErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPLOAD_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FINISHED,
          parsedBody.parameters,
          SAdminTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPLOAD_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FAILED,
          parsedBody.parameters,
          SAdminErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPDATE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FINISHED,
          parsedBody.parameters,
          SAdminTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.NOMENCLATURE_SYNCHRONIZATION_UPDATE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FAILED,
          parsedBody.parameters,
          SAdminErrorTemplateData,
        );
      }
    );
  }
}
