/* tslint:disable */
/* eslint-disable */

/**
 * Type of related object
 */
export enum RelatedObjectType {
  DOCUMENT = 'DOCUMENT',
  FILE = 'FILE',
  CONSIGNMENT = 'CONSIGNMENT',
  ORGANIZATION = 'ORGANIZATION'
}
