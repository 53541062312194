import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {
  BackendStatus,
  checkApiIsReady,
  EurekaAppStatus,
  getBackendAppsStatus,
  MicroserviceApplication
} from '../../api';
import {ActiveModuleService, ApplicationModule} from './active-module.service';
import {enumValuesToArray} from './data-mapping.utils';
import {ENVIRONMENT} from './environment.service';


const ALL_MICROSERVICES = enumValuesToArray(MicroserviceApplication) as MicroserviceApplication[];

const MODULE_CHECKED_SERVICES: Record<ApplicationModule, MicroserviceApplication[]> = {
  [ApplicationModule.MAIN]: ALL_MICROSERVICES,
  [ApplicationModule.ADMIN]: ALL_MICROSERVICES,
  [ApplicationModule.CONFIG]: [MicroserviceApplication.CONFIG_SERVER],
};

export function checkCurrentModuleServices(currentModule: ApplicationModule) {
  return (backendStatus: EurekaAppStatus[]): EurekaAppStatus[] => {
    return backendStatus.filter(bs => MODULE_CHECKED_SERVICES[currentModule].includes(bs.name as MicroserviceApplication));
  };
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private http = inject(HttpClient);
  private activeModuleService = inject(ActiveModuleService);
  private environment = inject(ENVIRONMENT);

  getApps() {
    return this.http.get<any>(this.environment.eurekaApiUrl + '/apps').pipe(map(data => data.applications.application));
  }

  getBackendAppsStatus() {
    return this.getApps().pipe(map(getBackendAppsStatus), map(checkCurrentModuleServices(this.activeModuleService.activeModule)));
  }

  backendIsReady() {
    return this.getBackendAppsStatus().pipe(
      map(checkApiIsReady),
      catchError(_ => of(BackendStatus.NETWORK_UNAVAILABLE))
    );
  }

}
