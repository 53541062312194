import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {EntityType} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {EsslComponentDto} from '../../../services/essl-component-search.service';
import {EsslComponentModalService} from '../../../services/essl-component-modal.service';

@Component({
  selector: 'icz-essl-component-link',
  templateUrl: './essl-component-link.component.html',
  styleUrls: ['./essl-component-link.component.scss'],
})
export class EsslComponentLinkComponent implements OnInit {

  private digitalComponentModalService = inject(EsslComponentModalService);
  private router = inject(Router);

  @Input({required: true}) documentEsslComponent!: EsslComponentDto;
  @Input() versionId!: number;
  @Input() locked = false;
  @Input() hideDescription: '' | boolean = false;
  @Input() hideIcon: '' | boolean = false;
  @Output() metadataChanged = new EventEmitter<void>();
  @Input() showFileName = false;
  @Input() isReadonly = false;
  @Input() hideAllComponentsContent = true;
  @Input() consignmentId: Nullable<number>;

  get isAnalogComponent(): boolean {
    if (!this.documentEsslComponent) return false;
    const entityType = this.documentEsslComponent.entityType;
    return entityType === EntityType.PAPER_COMPONENT || entityType === EntityType.MEDIUM_COMPONENT || entityType === EntityType.PHYSICAL_ITEM_COMPONENT;
  }

  get digitalComponent(): DigitalComponentCompleteDto {
    return this.documentEsslComponent as DigitalComponentCompleteDto;
  }

  ngOnInit(): void {
    if (!this.versionId && !this.isAnalogComponent) {
      this.versionId = this.digitalComponent?.digitalComponentVersions![0].id!;
    } else {
      return; // Else show nothing - input is an analog component
    }
  }

  fileClick() {
    if (!this.documentEsslComponent) return;

    const documentId = this.documentEsslComponent.documentId ?? parseInt((this.router.url.match(/\d+/)![0]) as string, 10);
    if (!documentId) throw new Error('Unknown document ID to get permissions for component display');

    this.digitalComponentModalService.openEsslComponentDetailWithPermissionFetch(
      this.digitalComponent,
      this.versionId,
      documentId,
      this.isReadonly,
      undefined,
      this.hideAllComponentsContent,
      this.consignmentId!
    ).subscribe((metadataChanged: boolean) => {
      if (metadataChanged) {
        this.metadataChanged.emit();
      }
    });
  }

}
