/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageSheetNodeDto } from '../models/page-sheet-node-dto';
import { SheetNodeDto } from '../models/sheet-node-dto';
import { sheetNodesOauthCreate } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-create';
import { SheetNodesOauthCreate$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-create';
import { sheetNodesOauthGetAll } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-all';
import { SheetNodesOauthGetAll$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-all';
import { sheetNodesOauthGetById } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-by-id';
import { SheetNodesOauthGetById$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-by-id';
import { sheetNodesOauthGetDefault } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-default';
import { SheetNodesOauthGetDefault$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-default';
import { sheetNodesOauthGetDefaultSendSheetNode } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-default-send-sheet-node';
import { SheetNodesOauthGetDefaultSendSheetNode$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-get-default-send-sheet-node';
import { sheetNodesOauthSearch } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-search';
import { SheetNodesOauthSearch$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-search';
import { sheetNodesOauthUpdate } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-update';
import { SheetNodesOauthUpdate$Params } from '../fn/sheet-nodes-oauth/sheet-nodes-oauth-update';


/**
 * API for management of esheet nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiSheetNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sheetNodesOauthUpdate()` */
  static readonly SheetNodesOauthUpdatePath = '/oauth/sheet-nodes/update-node/{id}';

  /**
   * Updates sheet node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetNodesOauthUpdate$Response(params: SheetNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SheetNodeDto>> {
    return sheetNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates sheet node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetNodesOauthUpdate(params: SheetNodesOauthUpdate$Params, context?: HttpContext): Observable<SheetNodeDto> {
    return this.sheetNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetNodeDto>): SheetNodeDto => r.body)
    );
  }

  /** Path part for operation `sheetNodesOauthCreate()` */
  static readonly SheetNodesOauthCreatePath = '/oauth/sheet-nodes/create-node';

  /**
   * Creates new sheet node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetNodesOauthCreate$Response(params: SheetNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SheetNodeDto>> {
    return sheetNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new sheet node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sheetNodesOauthCreate(params: SheetNodesOauthCreate$Params, context?: HttpContext): Observable<SheetNodeDto> {
    return this.sheetNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetNodeDto>): SheetNodeDto => r.body)
    );
  }

  /** Path part for operation `sheetNodesOauthGetById()` */
  static readonly SheetNodesOauthGetByIdPath = '/oauth/sheet-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetById$Response(params: SheetNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<SheetNodeDto>> {
    return sheetNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetById(params: SheetNodesOauthGetById$Params, context?: HttpContext): Observable<SheetNodeDto> {
    return this.sheetNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetNodeDto>): SheetNodeDto => r.body)
    );
  }

  /** Path part for operation `sheetNodesOauthSearch()` */
  static readonly SheetNodesOauthSearchPath = '/oauth/sheet-nodes/search-api';

  /**
   * Find all values in sheet nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthSearch$Response(params?: SheetNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSheetNodeDto>> {
    return sheetNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in sheet nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthSearch(params?: SheetNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageSheetNodeDto> {
    return this.sheetNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSheetNodeDto>): PageSheetNodeDto => r.body)
    );
  }

  /** Path part for operation `sheetNodesOauthGetAll()` */
  static readonly SheetNodesOauthGetAllPath = '/oauth/sheet-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetAll$Response(params?: SheetNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SheetNodeDto>>> {
    return sheetNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetAll(params?: SheetNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<SheetNodeDto>> {
    return this.sheetNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SheetNodeDto>>): Array<SheetNodeDto> => r.body)
    );
  }

  /** Path part for operation `sheetNodesOauthGetDefaultSendSheetNode()` */
  static readonly SheetNodesOauthGetDefaultSendSheetNodePath = '/oauth/sheet-nodes/default-send-node';

  /**
   * Returns default node for send
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthGetDefaultSendSheetNode()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetDefaultSendSheetNode$Response(params?: SheetNodesOauthGetDefaultSendSheetNode$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<SheetNodeDto>> {
    return sheetNodesOauthGetDefaultSendSheetNode(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node for send
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthGetDefaultSendSheetNode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetDefaultSendSheetNode(params?: SheetNodesOauthGetDefaultSendSheetNode$Params|null|undefined, context?: HttpContext): Observable<SheetNodeDto> {
    return this.sheetNodesOauthGetDefaultSendSheetNode$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetNodeDto>): SheetNodeDto => r.body)
    );
  }

  /** Path part for operation `sheetNodesOauthGetDefault()` */
  static readonly SheetNodesOauthGetDefaultPath = '/oauth/sheet-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sheetNodesOauthGetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetDefault$Response(params?: SheetNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<SheetNodeDto>> {
    return sheetNodesOauthGetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sheetNodesOauthGetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sheetNodesOauthGetDefault(params?: SheetNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<SheetNodeDto> {
    return this.sheetNodesOauthGetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<SheetNodeDto>): SheetNodeDto => r.body)
    );
  }

}
