import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IdtConnectionState} from '../../../core/services/idt/idt-link.service';

@Component({
  selector: 'icz-idt-malfunction-alert',
  templateUrl: './idt-malfunction-alert.component.html',
  styleUrls: ['./idt-malfunction-alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdtMalfunctionAlertComponent {

  @Input({required: true})
  idtStatus!: IdtConnectionState;
  @Input()
  functionUnavailableText: Nullable<string>;

  protected readonly IdtConnectionState = IdtConnectionState;

}
