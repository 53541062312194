/* tslint:disable */
/* eslint-disable */

/**
 * Digital component template permission type
 */
export enum DigitalComponentTemplatePermissionType {
  READ = 'READ',
  EDIT = 'EDIT',
  NO_PERMISSION = 'NO_PERMISSION'
}
