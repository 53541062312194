/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { filingRegisterAdministrationCreateFilingRegister } from '../fn/filing-register-administration/filing-register-administration-create-filing-register';
import { FilingRegisterAdministrationCreateFilingRegister$Params } from '../fn/filing-register-administration/filing-register-administration-create-filing-register';
import { filingRegisterAdministrationCreateSeparateFilingRegister } from '../fn/filing-register-administration/filing-register-administration-create-separate-filing-register';
import { FilingRegisterAdministrationCreateSeparateFilingRegister$Params } from '../fn/filing-register-administration/filing-register-administration-create-separate-filing-register';
import { filingRegisterAdministrationGetAllSeparateFilingRegisters } from '../fn/filing-register-administration/filing-register-administration-get-all-separate-filing-registers';
import { FilingRegisterAdministrationGetAllSeparateFilingRegisters$Params } from '../fn/filing-register-administration/filing-register-administration-get-all-separate-filing-registers';
import { filingRegisterAdministrationGetFilingRegister } from '../fn/filing-register-administration/filing-register-administration-get-filing-register';
import { FilingRegisterAdministrationGetFilingRegister$Params } from '../fn/filing-register-administration/filing-register-administration-get-filing-register';
import { filingRegisterAdministrationGetSeparateFilingRegister } from '../fn/filing-register-administration/filing-register-administration-get-separate-filing-register';
import { FilingRegisterAdministrationGetSeparateFilingRegister$Params } from '../fn/filing-register-administration/filing-register-administration-get-separate-filing-register';
import { filingRegisterAdministrationUpdateFilingRegister } from '../fn/filing-register-administration/filing-register-administration-update-filing-register';
import { FilingRegisterAdministrationUpdateFilingRegister$Params } from '../fn/filing-register-administration/filing-register-administration-update-filing-register';
import { filingRegisterAdministrationUpdateSeparateFilingRegister } from '../fn/filing-register-administration/filing-register-administration-update-separate-filing-register';
import { FilingRegisterAdministrationUpdateSeparateFilingRegister$Params } from '../fn/filing-register-administration/filing-register-administration-update-separate-filing-register';
import { FilingRegisterDto } from '../models/filing-register-dto';
import { SeparateFilingRegisterDto } from '../models/separate-filing-register-dto';


/**
 * Contains endpoints for Filing Register administration
 */
@Injectable({ providedIn: 'root' })
export class ApiFilingRegisterAdministrationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `filingRegisterAdministrationGetSeparateFilingRegister()` */
  static readonly FilingRegisterAdministrationGetSeparateFilingRegisterPath = '/records/filing-register-administration/separate-filing_registers/{id}';

  /**
   * Return a separate filing register.
   *
   * Returns a given separate filing register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationGetSeparateFilingRegister()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterAdministrationGetSeparateFilingRegister$Response(params: FilingRegisterAdministrationGetSeparateFilingRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<SeparateFilingRegisterDto>> {
    return filingRegisterAdministrationGetSeparateFilingRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Return a separate filing register.
   *
   * Returns a given separate filing register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationGetSeparateFilingRegister$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterAdministrationGetSeparateFilingRegister(params: FilingRegisterAdministrationGetSeparateFilingRegister$Params, context?: HttpContext): Observable<SeparateFilingRegisterDto> {
    return this.filingRegisterAdministrationGetSeparateFilingRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeparateFilingRegisterDto>): SeparateFilingRegisterDto => r.body)
    );
  }

  /** Path part for operation `filingRegisterAdministrationUpdateSeparateFilingRegister()` */
  static readonly FilingRegisterAdministrationUpdateSeparateFilingRegisterPath = '/records/filing-register-administration/separate-filing_registers/{id}';

  /**
   * Update a separate filing register.
   *
   * Updates the configuration of a separate filing register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationUpdateSeparateFilingRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationUpdateSeparateFilingRegister$Response(params: FilingRegisterAdministrationUpdateSeparateFilingRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<SeparateFilingRegisterDto>> {
    return filingRegisterAdministrationUpdateSeparateFilingRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a separate filing register.
   *
   * Updates the configuration of a separate filing register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationUpdateSeparateFilingRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationUpdateSeparateFilingRegister(params: FilingRegisterAdministrationUpdateSeparateFilingRegister$Params, context?: HttpContext): Observable<SeparateFilingRegisterDto> {
    return this.filingRegisterAdministrationUpdateSeparateFilingRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeparateFilingRegisterDto>): SeparateFilingRegisterDto => r.body)
    );
  }

  /** Path part for operation `filingRegisterAdministrationGetFilingRegister()` */
  static readonly FilingRegisterAdministrationGetFilingRegisterPath = '/records/filing-register-administration/filing_register';

  /**
   * Return the filing register.
   *
   * Returns the current configuration of the filing register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationGetFilingRegister()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterAdministrationGetFilingRegister$Response(params?: FilingRegisterAdministrationGetFilingRegister$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<FilingRegisterDto>> {
    return filingRegisterAdministrationGetFilingRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Return the filing register.
   *
   * Returns the current configuration of the filing register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationGetFilingRegister$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterAdministrationGetFilingRegister(params?: FilingRegisterAdministrationGetFilingRegister$Params|null|undefined, context?: HttpContext): Observable<FilingRegisterDto> {
    return this.filingRegisterAdministrationGetFilingRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilingRegisterDto>): FilingRegisterDto => r.body)
    );
  }

  /** Path part for operation `filingRegisterAdministrationUpdateFilingRegister()` */
  static readonly FilingRegisterAdministrationUpdateFilingRegisterPath = '/records/filing-register-administration/filing_register';

  /**
   * Update the filing register.
   *
   * Updates the configuration of the filing register.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationUpdateFilingRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationUpdateFilingRegister$Response(params: FilingRegisterAdministrationUpdateFilingRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<FilingRegisterDto>> {
    return filingRegisterAdministrationUpdateFilingRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Update the filing register.
   *
   * Updates the configuration of the filing register.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationUpdateFilingRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationUpdateFilingRegister(params: FilingRegisterAdministrationUpdateFilingRegister$Params, context?: HttpContext): Observable<FilingRegisterDto> {
    return this.filingRegisterAdministrationUpdateFilingRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilingRegisterDto>): FilingRegisterDto => r.body)
    );
  }

  /** Path part for operation `filingRegisterAdministrationCreateFilingRegister()` */
  static readonly FilingRegisterAdministrationCreateFilingRegisterPath = '/records/filing-register-administration/filing_register';

  /**
   * Create the filing register.
   *
   * Sets up the filing register by providing configuration parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationCreateFilingRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationCreateFilingRegister$Response(params: FilingRegisterAdministrationCreateFilingRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<FilingRegisterDto>> {
    return filingRegisterAdministrationCreateFilingRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Create the filing register.
   *
   * Sets up the filing register by providing configuration parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationCreateFilingRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationCreateFilingRegister(params: FilingRegisterAdministrationCreateFilingRegister$Params, context?: HttpContext): Observable<FilingRegisterDto> {
    return this.filingRegisterAdministrationCreateFilingRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<FilingRegisterDto>): FilingRegisterDto => r.body)
    );
  }

  /** Path part for operation `filingRegisterAdministrationGetAllSeparateFilingRegisters()` */
  static readonly FilingRegisterAdministrationGetAllSeparateFilingRegistersPath = '/records/filing-register-administration/separate-filing_registers';

  /**
   * Return all defined separate filing registers.
   *
   * Returns all defined separate filing registers.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationGetAllSeparateFilingRegisters()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterAdministrationGetAllSeparateFilingRegisters$Response(params?: FilingRegisterAdministrationGetAllSeparateFilingRegisters$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SeparateFilingRegisterDto>>> {
    return filingRegisterAdministrationGetAllSeparateFilingRegisters(this.http, this.rootUrl, params, context);
  }

  /**
   * Return all defined separate filing registers.
   *
   * Returns all defined separate filing registers.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationGetAllSeparateFilingRegisters$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterAdministrationGetAllSeparateFilingRegisters(params?: FilingRegisterAdministrationGetAllSeparateFilingRegisters$Params|null|undefined, context?: HttpContext): Observable<Array<SeparateFilingRegisterDto>> {
    return this.filingRegisterAdministrationGetAllSeparateFilingRegisters$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeparateFilingRegisterDto>>): Array<SeparateFilingRegisterDto> => r.body)
    );
  }

  /** Path part for operation `filingRegisterAdministrationCreateSeparateFilingRegister()` */
  static readonly FilingRegisterAdministrationCreateSeparateFilingRegisterPath = '/records/filing-register-administration/separate-filing_registers';

  /**
   * Create a new separate filing register.
   *
   * Sets up a new separate filing register by providing configuration parameters.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterAdministrationCreateSeparateFilingRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationCreateSeparateFilingRegister$Response(params: FilingRegisterAdministrationCreateSeparateFilingRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<SeparateFilingRegisterDto>> {
    return filingRegisterAdministrationCreateSeparateFilingRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new separate filing register.
   *
   * Sets up a new separate filing register by providing configuration parameters.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterAdministrationCreateSeparateFilingRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterAdministrationCreateSeparateFilingRegister(params: FilingRegisterAdministrationCreateSeparateFilingRegister$Params, context?: HttpContext): Observable<SeparateFilingRegisterDto> {
    return this.filingRegisterAdministrationCreateSeparateFilingRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeparateFilingRegisterDto>): SeparateFilingRegisterDto => r.body)
    );
  }

}
