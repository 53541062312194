/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CirculationTaskAction } from '|api/commons';
import { CirculationTaskActionsDto } from '../models/circulation-task-actions-dto';
import { CirculationTaskCompleteDto } from '../models/circulation-task-complete-dto';
import { circulationTaskFilterByRevocableTasks } from '../fn/circulation-task/circulation-task-filter-by-revocable-tasks';
import { CirculationTaskFilterByRevocableTasks$Params } from '../fn/circulation-task/circulation-task-filter-by-revocable-tasks';
import { circulationTaskFindByActivityId } from '../fn/circulation-task/circulation-task-find-by-activity-id';
import { CirculationTaskFindByActivityId$Params } from '../fn/circulation-task/circulation-task-find-by-activity-id';
import { circulationTaskFindById } from '../fn/circulation-task/circulation-task-find-by-id';
import { CirculationTaskFindById$Params } from '../fn/circulation-task/circulation-task-find-by-id';
import { circulationTaskProcessCirculationTasksForNotification } from '../fn/circulation-task/circulation-task-process-circulation-tasks-for-notification';
import { CirculationTaskProcessCirculationTasksForNotification$Params } from '../fn/circulation-task/circulation-task-process-circulation-tasks-for-notification';
import { circulationTaskResolveAvailableActions } from '../fn/circulation-task/circulation-task-resolve-available-actions';
import { circulationTaskResolveAvailableActions_1 } from '../fn/circulation-task/circulation-task-resolve-available-actions-1';
import { CirculationTaskResolveAvailableActions_1$Params } from '../fn/circulation-task/circulation-task-resolve-available-actions-1';
import { CirculationTaskResolveAvailableActions$Params } from '../fn/circulation-task/circulation-task-resolve-available-actions';


/**
 * Controller for document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskProcessCirculationTasksForNotification()` */
  static readonly CirculationTaskProcessCirculationTasksForNotificationPath = '/flow/task/process-circulation-tasks-for-notification';

  /**
   * Checks circulation tasks deadline dates and creates notification event for them.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskProcessCirculationTasksForNotification()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskProcessCirculationTasksForNotification$Response(params: CirculationTaskProcessCirculationTasksForNotification$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationTaskProcessCirculationTasksForNotification(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks circulation tasks deadline dates and creates notification event for them.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskProcessCirculationTasksForNotification$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskProcessCirculationTasksForNotification(params: CirculationTaskProcessCirculationTasksForNotification$Params, context?: HttpContext): Observable<void> {
    return this.circulationTaskProcessCirculationTasksForNotification$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationTaskFilterByRevocableTasks()` */
  static readonly CirculationTaskFilterByRevocableTasksPath = '/flow/task/filter-by-revocable';

  /**
   * Filter task ids by revocable business logic
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskFilterByRevocableTasks()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskFilterByRevocableTasks$Response(params: CirculationTaskFilterByRevocableTasks$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return circulationTaskFilterByRevocableTasks(this.http, this.rootUrl, params, context);
  }

  /**
   * Filter task ids by revocable business logic
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskFilterByRevocableTasks$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskFilterByRevocableTasks(params: CirculationTaskFilterByRevocableTasks$Params, context?: HttpContext): Observable<Array<number>> {
    return this.circulationTaskFilterByRevocableTasks$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `circulationTaskResolveAvailableActions()` */
  static readonly CirculationTaskResolveAvailableActionsPath = '/flow/task/available-actions';

  /**
   * Resolve available actions for tasks
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskResolveAvailableActions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskResolveAvailableActions$Response(params: CirculationTaskResolveAvailableActions$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationTaskActionsDto>>> {
    return circulationTaskResolveAvailableActions(this.http, this.rootUrl, params, context);
  }

  /**
   * Resolve available actions for tasks
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskResolveAvailableActions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskResolveAvailableActions(params: CirculationTaskResolveAvailableActions$Params, context?: HttpContext): Observable<Array<CirculationTaskActionsDto>> {
    return this.circulationTaskResolveAvailableActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationTaskActionsDto>>): Array<CirculationTaskActionsDto> => r.body)
    );
  }

  /** Path part for operation `circulationTaskResolveAvailableActions_1()` */
  static readonly CirculationTaskResolveAvailableActions_1Path = '/flow/task/{taskId}/available-actions';

  /**
   * Resolve available actions for a task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskResolveAvailableActions_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskResolveAvailableActions_1$Response(params: CirculationTaskResolveAvailableActions_1$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationTaskAction>>> {
    return circulationTaskResolveAvailableActions_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Resolve available actions for a task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskResolveAvailableActions_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskResolveAvailableActions_1(params: CirculationTaskResolveAvailableActions_1$Params, context?: HttpContext): Observable<Array<CirculationTaskAction>> {
    return this.circulationTaskResolveAvailableActions_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationTaskAction>>): Array<CirculationTaskAction> => r.body)
    );
  }

  /** Path part for operation `circulationTaskFindById()` */
  static readonly CirculationTaskFindByIdPath = '/flow/task/{id}';

  /**
   * Find task with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskFindById$Response(params: CirculationTaskFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskCompleteDto>> {
    return circulationTaskFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find task with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskFindById(params: CirculationTaskFindById$Params, context?: HttpContext): Observable<CirculationTaskCompleteDto> {
    return this.circulationTaskFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskCompleteDto>): CirculationTaskCompleteDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskFindByActivityId()` */
  static readonly CirculationTaskFindByActivityIdPath = '/flow/task/find-by-activity/{activityId}';

  /**
   * Find tasks linked to activity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskFindByActivityId()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskFindByActivityId$Response(params: CirculationTaskFindByActivityId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationTaskCompleteDto>>> {
    return circulationTaskFindByActivityId(this.http, this.rootUrl, params, context);
  }

  /**
   * Find tasks linked to activity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskFindByActivityId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskFindByActivityId(params: CirculationTaskFindByActivityId$Params, context?: HttpContext): Observable<Array<CirculationTaskCompleteDto>> {
    return this.circulationTaskFindByActivityId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationTaskCompleteDto>>): Array<CirculationTaskCompleteDto> => r.body)
    );
  }

}
