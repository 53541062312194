import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {ENVIRONMENT} from '../../../core/services/environment.service';
import {EnvironmentType} from '../../../core/services/environment.models';


@Component({
  selector: 'icz-environment-info',
  templateUrl: './environment-info.component.html',
  styleUrls: ['./environment-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnvironmentInfoComponent {

  protected environment = inject(ENVIRONMENT);

  readonly EnvironmentType = EnvironmentType;

}
