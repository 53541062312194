import {HttpRequest} from '@angular/common/http';
import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {DataBoxNodeDto, EmailNodeDto, SheetNodeDto} from '|api/sad';
import {Environment} from '../core/services/environment.models';

export interface IAuthService {
  isAuthenticatedWithFunctionalPosition: boolean;
  isAuthenticatedWithoutFunctionalPosition: boolean;

  logIn(orgCode: string, username: string, password: string): Observable<void>;
}


export interface ICodebookService {
  sheetNodesForCurrentFunctionalPosition(): Observable<SheetNodeDto[]>;
  emailNodesForCurrentFunctionalPosition(): Observable<EmailNodeDto[]>;
  databoxNodesForCurrentFunctionalPosition(): Observable<DataBoxNodeDto[]>;
}

export const AUTH_SERVICE = new InjectionToken('AUTH_SERVICE');

export function isIgnoredRequestUrl(request: HttpRequest<any>, environment: Environment) {
  return (
    request.url.match(`^/assets/`) ||
    request.url.match(`^${environment.eurekaApiUrl}/`) ||
    request.url.match(`^${environment.getTokenUrl}`) ||
    request.url.match(`^${environment.orgListUrl}`)
  );
}

export function isRequestToConfigServerBasicResource(request: HttpRequest<any>) {
  return (
    request.url.match(`^/config-server/`) &&
    !request.url.match(`^/config-server/api/rest/oauth/.+`)
  );
}
