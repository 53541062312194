import {ChangeDetectionStrategy, Component, HostListener} from '@angular/core';
import {DialogMode, DialogSeverity, SimpleDialogData} from '../../../core/services/dialog.models';
import {injectModalData, injectModalRef} from '../../../lib/modals';

@Component({
  selector: 'icz-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleDialogComponent {

  protected modalRef = injectModalRef<boolean>();
  protected data = injectModalData<SimpleDialogData>();

  @HostListener('window:keydown.enter', ['$event'])
  private enterPressed(event: Event) {
    event.stopPropagation();
    this.close(true);
  }

  @HostListener('window:keydown.escape', ['$event'])
  private escapePressed(event: Event) {
    event.stopPropagation();
    this.close(false);
  }

  readonly DialogMode = DialogMode;

  get icon(): string {
    switch (this.data?.severity) {
      case DialogSeverity.ERROR:
        return 'modal_error';
      case DialogSeverity.WARNING:
        return 'warning_color';
      case DialogSeverity.INFO:
      default:
        return 'modal_info';
    }
  }

  get mode(): DialogMode {
    return this.data.mode ?? DialogMode.SIMPLE;
  }

  get leftButtonTitle() {
    return this.data.leftButtonTitle ?? 'Potvrdit';
  }

  get rightButtonTitle() {
    return this.data.rightButtonTitle ?? 'Storno';
  }

  close(result: boolean) {
    this.modalRef.close(result);
  }

}
