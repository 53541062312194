import {Component, inject} from '@angular/core';
import {MainMenuCountsService} from '../../../core/services/main-menu-counts.service';

@Component({
  selector: 'icz-lazy-module-outlet',
  templateUrl: './lazy-module-outlet.component.html',
  styleUrls: ['./lazy-module-outlet.component.scss']
})
export class LazyModuleOutletComponent {

  protected mainMenuWithCount = inject(MainMenuCountsService);

  showNotificationsSidebar = false;

}
