import {HttpErrorResponse} from '@angular/common/http';
import {ErrorHandler, inject, Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MessageType, ToastService} from '../../components/notifications/toast.service';
import {BugReportService} from '../services/bug-report.service';
import {GlobalLoadingIndicatorService} from '../../components/essentials/global-loading-indicator.service';
import {ApplicationConfigService} from '../services/config/application-config.service';
import {createAbsoluteRoute} from '../routing/routing.helpers';
import {IczModalService} from '../../services/icz-modal.service';
import {ApplicationRoute} from '../../enums/shared-routes.enum';


@Injectable()
export class EsslErrorHandler {

  private ngZone = inject(NgZone);
  private router = inject(Router);
  private toastService = inject(ToastService);
  private modalService = inject(IczModalService);
  private bugReportService = inject(BugReportService);
  private translateService = inject(TranslateService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private applicationConfigService = inject(ApplicationConfigService);

  private originalErrorHandler = new ErrorHandler();

  handleError(e: Error) {
    // Can't check for error prototype using instanceof since that exception originates from Webpack.
    if (e instanceof Error && (e.message.includes('error loading dynamically imported module') || e.message.includes('Failed to fetch dynamically imported module'))) {
      this.modalService.closeAllModals();
      this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.APPLICATION_UNAVAILABLE));
    }
    else {
      if (this.applicationConfigService.isErrorReportingEnabled && !(e instanceof HttpErrorResponse)) {
        const errorMessage = `${e.name}: ${e.message.substr(0, 300)}`;

        this.ngZone.run(() => {
          this.toastService.dispatchToast({
            type: MessageType.ERROR,
            data: {
              header: {
                template: this.translateService.instant('Došlo k neočekávané chybě: "{{errorMessage}}"'),
                templateData: {
                  errorMessage,
                }
              },
              body: {
                template: '```' + (e.stack ?? `(${this.translateService.instant('bez podrobností')})`).split('\n').slice(0, 5) + '```',
              },
              buttons: [
                {
                  label: this.translateService.instant('Chybové hlášení'),
                  action: () => {
                    this.globalLoadingService.doLoading(
                      this.bugReportService.generateBugReport(),
                      'Tvorba chybového hlášení může chvíli trvat'
                    ).subscribe();
                  },
                }
              ]
            }
          });
        });
      }

      this.originalErrorHandler.handleError(e);
    }
  }

}
