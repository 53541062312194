import {DestroyRef, inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  filter,
  forkJoin,
  merge,
  of,
  OperatorFunction,
  pipe,
  take
} from 'rxjs';
import {ApiFunctionalPositionService, FunctionalPositionDto} from '|api/auth-server';
import {MenuItem} from '../../components/general-screen-area/side-menu/side-menu.component';
import {
  FULLTEXT_SEARCH_TERM_FILTER_ID,
  SavedFiltersService
} from '../../components/table/table-toolbar/saved-filters.service';
import {openFilterDeleteQuestion} from '../../components/table/table-toolbar/saved-filters/saved-filters.component';
import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {createAbsoluteRoute, createAbsoluteRouteWithUnit} from '../routing/routing.helpers';
import {
  DispatchRoute,
  DocumentsDashboardRoute,
  DocumentsRoute,
  FilingOfficeRoute,
  OfficeDeskRoute,
  RegistryOfficeRoute
} from '../../enums/documents-routes.enum';
import {AuthService} from '../authentication/auth.service';
import {CodebookService} from './codebook.service';
import {removeDuplicates, serializeParamsToQueryString} from '../../lib/utils';
import {
  FILTER_PARAM_NAME,
  FULLTEXT_SEARCH_TERM_PARAM_NAME,
  SearchParamsSerializationService
} from '../../services/search-params-serialization.service';
import {LoadingIndicatorService} from '../../components/essentials/loading-indicator.service';
import {AnyComponent} from '../../model';
import {
  isDispatchOfficer,
  isFilingOfficer,
  isLeader,
  isOfficer,
  isRegistryOfficer,
  isSecretariat
} from '../../services/user-roles.model';
import {CurrentSessionService} from '../../services/current-session.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {findInFilterTree} from '../../components/table/filter-trees.utils';
import {Params} from '@angular/router';
import {MenuItemCountViewIds} from '../routing/menu-item-count-view-ids';
import {DialogService} from './dialog.service';


export const GLOBAL_SEARCH_FILTER_SCOPE = 'documents_search';

const GLOBAL_SEARCH_URI = createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.SEARCH);

export const dispatchMenuDispatchOfficer: MenuItem[] = [
  {
    name: 'K převzetí',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.TO_TAKE_OVER),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_TAKE_OVER,
  },
  {
    name: 'K vypravení',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.TO_DISPATCH),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_DISPATCH,
  },
  {
    name: 'V distribuci',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.IN_DISTRIBUTION),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_IN_DISTRIBUTION,
  },
  {
    name: 'Vypravené',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.DISPATCHED),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCHED,
  },
  {
    name: 'Zápis doručení - výpravna',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.DISPATCH_OFFICE_RECORD_DELIVERY_RESULT),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCH_OFFICE_RECORD_DELIVERY_RESULT,
  },
  {
    name: 'Poštovní podací archy',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.BULK_POSTING_FORMS),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_BULK_POSTING_FORMS,
  },
];

export const dispatchMenuOfficer: MenuItem[] = [
  {
    name: 'Vypravení - připravované',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.OFFICER_VIEW_IN_PROGRESS),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_IN_PROGRESS,
  },
  {
    name: 'Vypravení - vše',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.OFFICER_VIEW_ALL),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_ALL,
  },
  {
    name: 'Vrácené zásilky',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.OFFICER_VIEW_REJECTED),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_REJECTED,
  },
  {
    name: 'Zápis doručení',
    id: createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.OFFICER_VIEW_RECORD_DELIVERY_RESULT),
    countViewId: MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_RECORD_DELIVERY_RESULT,
  },
];

export const officeDeskMenuOfficer: MenuItem[] = [
  {
    name: 'Vyvěšení - připravované',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.OFFICER_VIEW_IN_PROGRESS),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_IN_PROGRESS,
  },
  {
    name: 'Úřední deska',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.OFFICER_VIEW_ALL_POSTED),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_ALL_POSTED,
  },
  {
    name: 'Vrácená vyvěšení',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.OFFICER_VIEW_REJECTED),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_REJECTED,
  },
  {
    name: 'Vyvěšení - vše',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.OFFICER_VIEW_ALL),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_OFFICER_VIEW_ALL,
  },
];

export const officeDeskMenuDispatchOfficer: MenuItem[] = [
  {
    name: 'Převzít vyvěšení',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.TO_TAKE_OVER),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_TO_TAKE_OVER,
  },
  {
    name: 'K vyvěšení',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.TO_POST),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_TO_POST,
  },
  {
    name: 'Vyvěšené',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.POSTED),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_POSTED,
  },
  {
    name: 'Svěšené',
    id: createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.UNPOSTED),
    countViewId: MenuItemCountViewIds.OFFICE_DESK_UNPOSTED,
  },
];

export const filingMenu: MenuItem[] = [
  {
    name: 'Přijaté',
    id: createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_ALL_CONSIGNMENTS),
    countViewId: MenuItemCountViewIds.FILING_OFFICE_RECEIVED_ALL_CONSIGNMENTS,
  },
  {
    name: 'E-mailová podání',
    id: createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_EMAIL_MESSAGES),
    countViewId: MenuItemCountViewIds.FILING_OFFICE_RECEIVED_EMAIL_MESSAGES,
  },
  {
    name: 'Datová schránka',
    id: createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_DATABOX_MESSAGES),
    countViewId: MenuItemCountViewIds.FILING_OFFICE_RECEIVED_DATABOX_MESSAGES,
  },
  {
    name: 'Interní podání',
    id: createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_INTERNAL_CONSIGNMENTS),
    countViewId: MenuItemCountViewIds.FILING_OFFICE_RECEIVED_INTERNAL_CONSIGNMENTS,
  },
  {
    name: 'Předané podatelnou',
    id: createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.HANDED_OVER),
    countViewId: MenuItemCountViewIds.FILING_OFFICE_HANDED_OVER,
  },
  {
    name: 'Vráceno do podatelny',
    id: createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.REJECTED),
    countViewId: MenuItemCountViewIds.FILING_OFFICE_REJECTED,
  },
];

const dispatchUnitMenuItems: MenuItem[] = [
  {
    name: 'Vypravení - připravované',
    id: createAbsoluteRouteWithUnit(DispatchRoute.OFFICER_VIEW_IN_PROGRESS),
    countViewId: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_IN_PROGRESS,
  },
  {
    name: 'Vypravení - vše',
    id: createAbsoluteRouteWithUnit(DispatchRoute.OFFICER_VIEW_ALL),
    countViewId: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_ALL,
  },
  {
    name: 'Vypravení - vrácené',
    id: createAbsoluteRouteWithUnit(DispatchRoute.OFFICER_VIEW_REJECTED),
    countViewId: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_REJECTED,
  },
  {
    name: 'Zápis doručení',
    id: createAbsoluteRouteWithUnit(DispatchRoute.OFFICER_VIEW_RECORD_DELIVERY_RESULT),
    countViewId: MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_RECORD_DELIVERY_RESULT,
  },
];

export const officerUnitMenu: MenuItem[] = [
  {
    name: 'Vyřizované',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.IN_PROGRESS),
    countViewId: MenuItemCountViewIds.UNIT_IN_PROGRESS,
  },
  {
    name: 'Blízký termín',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.EXPIRING),
    countViewId: MenuItemCountViewIds.UNIT_EXPIRING,
  },
  {
    name: 'Ukončené',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.SETTLED),
    countViewId: MenuItemCountViewIds.UNIT_SETTLED,
  },
];

const transfersUnitMenu: MenuItem[] = [
  {
    name: 'Spisovna',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.REGISTRY_OFFICE_TRANSFERS),
    countViewId: MenuItemCountViewIds.UNIT_REGISTRY_OFFICE_TRANSFERS,
  },
];

const tasksUnitMenu: MenuItem[] = [
  {
    name: 'Úkolovník',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.TASKS),
    countViewId: MenuItemCountViewIds.UNIT_TASKS,
  },
  {
    name: 'Podpisová kniha',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.SIGNATURE_BOOK),
    countViewId: MenuItemCountViewIds.UNIT_SIGNATURE_BOOK,
  },
];

export const leaderUnitMenu: MenuItem[] = [
  ...officerUnitMenu,
  ...tasksUnitMenu,
  ...transfersUnitMenu,
];

export const secretariatUnitMenu: MenuItem[] = [
  ...officerUnitMenu,
  {
    name: 'Úkolovník',
    id: createAbsoluteRouteWithUnit(DocumentsRoute.TASKS),
    countViewId: MenuItemCountViewIds.UNIT_TASKS,
  },
  ...dispatchUnitMenuItems,
  ...transfersUnitMenu,
];

export const leaderAndSecretariatUnitMenu: MenuItem[] = [
  ...officerUnitMenu,
  ...tasksUnitMenu,
  ...dispatchUnitMenuItems,
  ...transfersUnitMenu,
];

// todo(rb) uncomment individual sections after their respective modules get implemented.
const MAIN_MENU_STATIC: MenuItem[] = [
  {
    name: 'Nástěnka',
    id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsDashboardRoute.DASHBOARD),
    icon: 'dashboard',
  },
  {
    name: 'Moje dokumenty',
    id: ApplicationRoute.DOCUMENTS,
    icon: 'my_documents',
    children: [
      {
        name: 'Úkolovník',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.TASKS),
        countViewId: MenuItemCountViewIds.DOCUMENTS_TASKS,
      },
      {
        name: 'Vyřizované',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS,DocumentsRoute.IN_PROGRESS),
        countViewId: MenuItemCountViewIds.DOCUMENTS_IN_PROGRESS,
      },
      {
        name: 'Ukončené',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.SETTLED),
        countViewId: MenuItemCountViewIds.DOCUMENTS_SETTLED,
      },
      {
        name: 'Blízký termín',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.EXPIRING),
        countViewId: MenuItemCountViewIds.DOCUMENTS_EXPIRING,
      },
      {
        name: 'Podpisová kniha',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.SIGNATURE_BOOK),
        countViewId: MenuItemCountViewIds.DOCUMENTS_SIGNATURE_BOOK,
      },
      {
        name: 'Externí aplikace',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.EXTERNAL_APPLICATION_DOCUMENTS),
        countViewId: MenuItemCountViewIds.DOCUMENTS_EXTERNAL_APPLICATION_DOCUMENTS,
      },
      {
        name: 'Spisovna',
        id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.REGISTRY_OFFICE_TRANSFERS),
        countViewId: MenuItemCountViewIds.DOCUMENTS_REGISTRY_OFFICE_TRANSFERS,
      },
    ],
  },
  {
    name: 'Úřední deska',
    id: ApplicationRoute.OFFICE_DESK,
    icon: 'uredni_deska',
    children: [], // dynamic children based on user role
  },
  {
    name: 'Nasdíleno',
    id: createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.MANUALLY_SHARED),
    icon: 'add_user',
    countViewId: MenuItemCountViewIds.DOCUMENTS_MANUALLY_SHARED,
  },
  /*
  {
    name: 'Dynamické složky',
    id: DynamicFoldersRoute.DYNAMIC_FOLDERS,
    icon: 'dynamicka_slozka',
    isTestingFeature: true,
  },*/
  {
    name: 'Podatelna',
    id: ApplicationRoute.FILING_OFFICE,
    icon: 'podatelna',
    children: [], // dynamic children based on user role
  },
  {
    name: 'Vypravení',
    id: ApplicationRoute.DISPATCH,
    icon: 'vypravna',
    children: [], // dynamic children based on user role
  },
  {
    name: 'Spisovna',
    id: ApplicationRoute.REGISTRY_OFFICE,
    icon: 'spisovna',
    children: [
      {
        name: 'K převzetí',
        id: createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.TO_TAKE_OVER),
        countViewId: MenuItemCountViewIds.REGISTRY_OFFICE_TO_TAKE_OVER,
      },
      {
        name: 'K nápravě',
        id: createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.TO_RESOLVE_ISSUES),
        countViewId: MenuItemCountViewIds.REGISTRY_OFFICE_TO_RESOLVE_ISSUES,
      },
      // todo(rb) uncomment after ANA ready
      /*{
        name: 'Spisovna - Vše',
        id: createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.ALL),
        viewId: 'transfers-all-registry-office',
      },*/
      {
        name: 'Přehled problémů',
        id: createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.PROBLEMS),
        countViewId: MenuItemCountViewIds.REGISTRY_OFFICE_PROBLEMS,
      },
    ],
  },
  {
    name: 'Útvar',
    id: ApplicationRoute.UNIT,
    icon: 'sekretariat',
    children: [], // dynamic children based on user role
  },
  {
    name: 'Sdílené složky',
    id: createAbsoluteRoute(ApplicationRoute.SHARED_FOLDERS),
    icon: 'registratury',
  },
  /*
  {
    name: 'Jednání',
    id: MeetingsRoute.MEETINGS,
    icon: 'jednani',
    isTestingFeature: true,
  },
  {
    name: 'Smlouvy',
    id: ContractsRoute.CONTRACTS,
    icon: 'smlouvy',
    isTestingFeature: true,
  },
  {
    name: 'Správa a nastavení',
    id: 'administration-and-settings',
    icon: 'user_settings',
    isTestingFeature: true,
    children: [
      {
        name: 'Statistiky',
        id: 'statistics',
        isTestingFeature: true,
        children: [
          {
            name: 'Základní statistiky ERMS',
            id: createAbsoluteRoute(AdministrationAndSettingsRoute.STATISTICS, AdministrationAndSettingsStatisticsRoute.BASIC),
            isTestingFeature: true,
          },
          {
            name: 'Pokročilé statistiky',
            id: createAbsoluteRoute(AdministrationAndSettingsRoute.STATISTICS, AdministrationAndSettingsStatisticsRoute.ADVANCED),
            isTestingFeature: true,
          },
          {
            name: 'Správa statistik',
            id: createAbsoluteRoute(AdministrationAndSettingsRoute.STATISTICS, AdministrationAndSettingsStatisticsRoute.SETTINGS),
            isTestingFeature: true,
          },
        ],
      },
      {
        name: 'Tiskové sestavy',
        id: AdministrationAndSettingsRoute.PRINT_COLLECTIONS,
        isTestingFeature: true,
      },
      {
        name: 'Nastavení aplikace',
        id: AdministrationAndSettingsRoute.MAIN_MENU_SETTINGS,
        isTestingFeature: true,
        children: [
          {
            name: 'Základní nastavení',
            id: createAbsoluteRoute(AdministrationAndSettingsRoute.MAIN_MENU_SETTINGS, AdministrationAndSettingsMainMenuRoute.GENERAL),
            isTestingFeature: true,
          },
          {
            name: 'Uzpůsobení vzhledu',
            id: createAbsoluteRoute(AdministrationAndSettingsRoute.MAIN_MENU_SETTINGS, AdministrationAndSettingsMainMenuRoute.APPEARANCE),
            isTestingFeature: true,
          },
          {
            name: 'Změna hesla',
            id: createAbsoluteRoute(
              AdministrationAndSettingsRoute.MAIN_MENU_SETTINGS,
              AdministrationAndSettingsMainMenuRoute.PASSWORD_CHANGE
            ),
            isTestingFeature: true,
          },
          {
            name: 'Barevné kategorie',
            id: createAbsoluteRoute(
              AdministrationAndSettingsRoute.MAIN_MENU_SETTINGS,
              AdministrationAndSettingsMainMenuRoute.COLOR_CATEGORIES
            ),
            isTestingFeature: true,
          },
          {
            name: 'Avíza',
            id: createAbsoluteRoute(
              AdministrationAndSettingsRoute.MAIN_MENU_SETTINGS,
              AdministrationAndSettingsMainMenuRoute.NOTIFICATIONS
            ),
            isTestingFeature: true,
          },
        ],
      },
    ],
  },*/
];

function handleErrorAsNoNodes<T>(): OperatorFunction<T[], T[]> {
  return pipe(
    catchError(_ => of([] as T[])),
  );
}

@Injectable({
  providedIn: 'root'
})
export class MainMenuService {

  private currentSessionService = inject(CurrentSessionService);
  private authService = inject(AuthService);
  private apiFunctionalPositionService = inject(ApiFunctionalPositionService);
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private savedFiltersService = inject(SavedFiltersService);
  private searchParamsSerializationService = inject(SearchParamsSerializationService);
  private codebook = inject(CodebookService);
  private loadingService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);

  constructor() {
    merge(
      this.authService.login$,
      this.savedFiltersService.savedFilters$,
    ).pipe(
      debounceTime(100),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.setMainMenu();
    });
  }

  private _menuItems$ = new BehaviorSubject<MenuItem[]>([]);
  menuItems$ = this._menuItems$.asObservable();
  loadingContext: Nullable<AnyComponent>;

  get menuItems() {
    return this._menuItems$.value;
  }

  private addGlobalFiltersToMenu() {
    const savedFilters = this.savedFiltersService.savedFilters;
    const savedGlobalFilters = savedFilters[GLOBAL_SEARCH_FILTER_SCOPE] ?? {};
    const savedGlobalFiltersCount = Object.keys(savedGlobalFilters).length;

    if (savedGlobalFiltersCount) {
      const savedFiltersMenuSection: MenuItem = {
        name: 'Uložené filtry',
        id: 'saved-filters',
        icon: 'filter',
        count: savedGlobalFiltersCount,
        children: []
      };

      for (const savedFilterName in savedGlobalFilters) {
        if (savedGlobalFilters.hasOwnProperty(savedFilterName)) {
          const fulltextSearchFilter = findInFilterTree(
            savedGlobalFilters[savedFilterName],
            f => f.id === FULLTEXT_SEARCH_TERM_FILTER_ID,
          );

          const params: Params = {
            [FILTER_PARAM_NAME]: this.searchParamsSerializationService.serializeFilterValuesToString(savedGlobalFilters[savedFilterName]),
          };

          if (fulltextSearchFilter) {
            params[FULLTEXT_SEARCH_TERM_PARAM_NAME] = fulltextSearchFilter.value;
          }

          savedFiltersMenuSection.children!.push({
            name: savedFilterName,
            id: GLOBAL_SEARCH_URI + serializeParamsToQueryString(params),
            actionButtons: [
              {
                name: 'Smazat',
                icon: 'delete',
                handler: () => this.deleteSavedFilter(savedFilterName),
              }
            ]
          });
        }
      }

      this._menuItems$.next([
        ...this.menuItems,
        savedFiltersMenuSection,
      ]);
    }
  }

  setLoadingContext(component: AnyComponent) {
    this.loadingContext = component;
  }

  private setRoleSubmenu(
    menu: MenuItem[],
    currentUserInfo: Nullable<FunctionalPositionDto>,
    emailNodesCount: number,
    databoxNodeCount: number,
    sheetNodesCount: number,
    registryOfficesCount: number,
  ): MenuItem[] {
    const filingOfficeMenu = menu.find(m => m.id === ApplicationRoute.FILING_OFFICE);
    const secretariatMenu = menu.find(m => m.id === ApplicationRoute.UNIT);
    const dispatchMenu = menu.find(m => m.id === ApplicationRoute.DISPATCH);
    const officeDeskMenu = menu.find(m => m.id === ApplicationRoute.OFFICE_DESK);

    if (currentUserInfo) {
      if (filingOfficeMenu && isFilingOfficer(currentUserInfo!)) {
        let filteredFilingOfficeMenu = [...filingMenu];
        if (emailNodesCount === 0) {
          filteredFilingOfficeMenu = filteredFilingOfficeMenu.filter(f => f.countViewId !== MenuItemCountViewIds.FILING_OFFICE_RECEIVED_EMAIL_MESSAGES);
        }

        if (databoxNodeCount === 0) {
          filteredFilingOfficeMenu = filteredFilingOfficeMenu.filter(f => f.countViewId !== MenuItemCountViewIds.FILING_OFFICE_RECEIVED_DATABOX_MESSAGES);
        }

        if (sheetNodesCount === 0) {
          filteredFilingOfficeMenu = filteredFilingOfficeMenu.filter(f => f.countViewId !== MenuItemCountViewIds.FILING_OFFICE_RECEIVED_INTERNAL_CONSIGNMENTS);
        }

        filingOfficeMenu.children = filteredFilingOfficeMenu;
      } else {
        menu = menu.filter(m => m.id !== ApplicationRoute.FILING_OFFICE);
      }

      if (!isRegistryOfficer(currentUserInfo) || !registryOfficesCount) {
        menu = menu.filter(m => m.id !== ApplicationRoute.REGISTRY_OFFICE);
      }

      if (dispatchMenu && officeDeskMenu) {
        if (isDispatchOfficer(currentUserInfo) && !isOfficer()) {
          dispatchMenu!.children = dispatchMenuDispatchOfficer;
          officeDeskMenu!.children = officeDeskMenuDispatchOfficer;
        }
        else if (isOfficer() && !isDispatchOfficer(currentUserInfo)) {
          dispatchMenu!.children = dispatchMenuOfficer;
          officeDeskMenu!.children = officeDeskMenuOfficer;
        }
        if (isDispatchOfficer(currentUserInfo) && isOfficer()) {
          dispatchMenu!.children = removeDuplicates([
            ...dispatchMenuDispatchOfficer,
            ...dispatchMenuOfficer,
          ], o => o.id);
          officeDeskMenu!.children = removeDuplicates([
            ...officeDeskMenuDispatchOfficer,
            ...officeDeskMenuOfficer,
          ], o => o.id);
        }
        else if (!isDispatchOfficer(currentUserInfo) && !isOfficer()) {
          menu = menu.filter(m => m.id !== ApplicationRoute.DISPATCH);
          menu = menu.filter(m => m.id !== ApplicationRoute.OFFICE_DESK);
        }
      }

      if (secretariatMenu?.children) {
        if (isLeader(currentUserInfo) && isSecretariat(currentUserInfo)) {
          secretariatMenu!.children = leaderAndSecretariatUnitMenu;
        }
        else if (isSecretariat(currentUserInfo)) {
          secretariatMenu!.children = secretariatUnitMenu;
        }
        else if (isLeader(currentUserInfo)) {
          secretariatMenu!.children = leaderUnitMenu;
        }
        else {
          secretariatMenu!.children = officerUnitMenu;
        }
      }
    }

    return menu;
  }

  private setMainMenu() {
    if (this.loadingContext) {
      this.loadingService.startLoading(this.loadingContext);
    }
    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      filter(_ => this.authService.isAuthenticatedWithFunctionalPosition),
      take(1),
    ).subscribe(currentUserInfo => {
      forkJoin([
        this.codebook.emailNodesForCurrentFunctionalPosition().pipe(handleErrorAsNoNodes()),
        this.codebook.databoxNodesForCurrentFunctionalPosition().pipe(handleErrorAsNoNodes()),
        this.codebook.sheetNodesForCurrentFunctionalPosition().pipe(handleErrorAsNoNodes()),
        this.codebook.registryOfficesForCurrentFunctionalPosition().pipe(handleErrorAsNoNodes()),
      ]).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(([emailNodes, databoxNodes, sheetNodes, registryOffices]) => {
        const menu = this.setRoleSubmenu([...MAIN_MENU_STATIC], currentUserInfo, emailNodes.length, databoxNodes.length, sheetNodes.length, registryOffices.length);
        this._menuItems$.next(menu);
        this.addGlobalFiltersToMenu();
      });
    });
  }

  private deleteSavedFilter(savedFilterName: string) {
    openFilterDeleteQuestion(
      savedFilterName,
      this.dialogService,
      this.translateService,
    ).subscribe(_ => {
      this.savedFiltersService.deleteFilter(GLOBAL_SEARCH_FILTER_SCOPE, savedFilterName);
    });
  }

}
