import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';

@Component({
  selector: 'icz-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotFoundPageComponent {
  readonly ApplicationRoute = ApplicationRoute;
}
