/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiSheetLabelTemplatesService } from './services/api-sheet-label-templates.service';
import { ApiReceivedPaperConsignmentService } from './services/api-received-paper-consignment.service';
import { ApiReceivedDigitalConsignmentService } from './services/api-received-digital-consignment.service';
import { ApiOwnConsignmentService } from './services/api-own-consignment.service';
import { ApiEnvelopeTemplatesService } from './services/api-envelope-templates.service';
import { ApiOwnConsignmentConfigurationService } from './services/api-own-consignment-configuration.service';
import { ApiBulkPostingFormService } from './services/api-bulk-posting-form.service';
import { ApiReceivedInternalMessageService } from './services/api-received-internal-message.service';
import { ApiBulkPostingFormTemplateService } from './services/api-bulk-posting-form-template.service';
import { ApiConsignmentComponentService } from './services/api-consignment-component.service';
import { ApiSadDataboxService } from './services/api-sad-databox.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiSheetLabelTemplatesService,
    ApiReceivedPaperConsignmentService,
    ApiReceivedDigitalConsignmentService,
    ApiOwnConsignmentService,
    ApiEnvelopeTemplatesService,
    ApiOwnConsignmentConfigurationService,
    ApiBulkPostingFormService,
    ApiReceivedInternalMessageService,
    ApiBulkPostingFormTemplateService,
    ApiConsignmentComponentService,
    ApiSadDataboxService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
