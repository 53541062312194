/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CirculationTaskDto } from '../models/circulation-task-dto';
import { circulationTaskSystemStartProgress } from '../fn/circulation-task-system/circulation-task-system-start-progress';
import { CirculationTaskSystemStartProgress$Params } from '../fn/circulation-task-system/circulation-task-system-start-progress';


/**
 * Controller for executing actions on document circulation tasks by system
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskSystemService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskSystemStartProgress()` */
  static readonly CirculationTaskSystemStartProgressPath = '/flow/system/start-progress';

  /**
   * Get available actions for task
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskSystemStartProgress()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskSystemStartProgress$Response(params: CirculationTaskSystemStartProgress$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CirculationTaskDto>>> {
    return circulationTaskSystemStartProgress(this.http, this.rootUrl, params, context);
  }

  /**
   * Get available actions for task
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskSystemStartProgress$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskSystemStartProgress(params: CirculationTaskSystemStartProgress$Params, context?: HttpContext): Observable<Array<CirculationTaskDto>> {
    return this.circulationTaskSystemStartProgress$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CirculationTaskDto>>): Array<CirculationTaskDto> => r.body)
    );
  }

}
