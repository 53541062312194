/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { csuLegalformGetAll } from '../fn/csu-legalform/csu-legalform-get-all';
import { CsuLegalformGetAll$Params } from '../fn/csu-legalform/csu-legalform-get-all';
import { csuLegalformGetAllValid } from '../fn/csu-legalform/csu-legalform-get-all-valid';
import { CsuLegalformGetAllValid$Params } from '../fn/csu-legalform/csu-legalform-get-all-valid';
import { csuLegalformGetLegalFormsForSubjectClassification } from '../fn/csu-legalform/csu-legalform-get-legal-forms-for-subject-classification';
import { CsuLegalformGetLegalFormsForSubjectClassification$Params } from '../fn/csu-legalform/csu-legalform-get-legal-forms-for-subject-classification';
import { csuLegalformGetValidLegalFormsForSubjectClassification } from '../fn/csu-legalform/csu-legalform-get-valid-legal-forms-for-subject-classification';
import { CsuLegalformGetValidLegalFormsForSubjectClassification$Params } from '../fn/csu-legalform/csu-legalform-get-valid-legal-forms-for-subject-classification';
import { LegalFormDto } from '../models/legal-form-dto';


/**
 * Contains endpoints related to LegalForms lookup table
 */
@Injectable({ providedIn: 'root' })
export class ApiCsuLegalformService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `csuLegalformGetAll()` */
  static readonly CsuLegalformGetAllPath = '/codebook/csu/legalform';

  /**
   * Returns all defined LegalForms.
   *
   * Returns all defined LegalForms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csuLegalformGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetAll$Response(params?: CsuLegalformGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<LegalFormDto>>> {
    return csuLegalformGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all defined LegalForms.
   *
   * Returns all defined LegalForms
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csuLegalformGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetAll(params?: CsuLegalformGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<LegalFormDto>> {
    return this.csuLegalformGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LegalFormDto>>): Array<LegalFormDto> => r.body)
    );
  }

  /** Path part for operation `csuLegalformGetLegalFormsForSubjectClassification()` */
  static readonly CsuLegalformGetLegalFormsForSubjectClassificationPath = '/codebook/csu/legalform/{subjectClassification}';

  /**
   * Returns LegalForms for given subject classification.
   *
   * Returns LegalForms for given subject classification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csuLegalformGetLegalFormsForSubjectClassification()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetLegalFormsForSubjectClassification$Response(params: CsuLegalformGetLegalFormsForSubjectClassification$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LegalFormDto>>> {
    return csuLegalformGetLegalFormsForSubjectClassification(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns LegalForms for given subject classification.
   *
   * Returns LegalForms for given subject classification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csuLegalformGetLegalFormsForSubjectClassification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetLegalFormsForSubjectClassification(params: CsuLegalformGetLegalFormsForSubjectClassification$Params, context?: HttpContext): Observable<Array<LegalFormDto>> {
    return this.csuLegalformGetLegalFormsForSubjectClassification$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LegalFormDto>>): Array<LegalFormDto> => r.body)
    );
  }

  /** Path part for operation `csuLegalformGetValidLegalFormsForSubjectClassification()` */
  static readonly CsuLegalformGetValidLegalFormsForSubjectClassificationPath = '/codebook/csu/legalform/{subjectClassification}/valid';

  /**
   * Returns valid LegalForms for given subject type.
   *
   * Returns valid LegalForms for given subject classification
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csuLegalformGetValidLegalFormsForSubjectClassification()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetValidLegalFormsForSubjectClassification$Response(params: CsuLegalformGetValidLegalFormsForSubjectClassification$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LegalFormDto>>> {
    return csuLegalformGetValidLegalFormsForSubjectClassification(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns valid LegalForms for given subject type.
   *
   * Returns valid LegalForms for given subject classification
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csuLegalformGetValidLegalFormsForSubjectClassification$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetValidLegalFormsForSubjectClassification(params: CsuLegalformGetValidLegalFormsForSubjectClassification$Params, context?: HttpContext): Observable<Array<LegalFormDto>> {
    return this.csuLegalformGetValidLegalFormsForSubjectClassification$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LegalFormDto>>): Array<LegalFormDto> => r.body)
    );
  }

  /** Path part for operation `csuLegalformGetAllValid()` */
  static readonly CsuLegalformGetAllValidPath = '/codebook/csu/legalform/valid';

  /**
   * Returns all valid defined LegalForms.
   *
   * Returns all valid defined LegalForms
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csuLegalformGetAllValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetAllValid$Response(params?: CsuLegalformGetAllValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<LegalFormDto>>> {
    return csuLegalformGetAllValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all valid defined LegalForms.
   *
   * Returns all valid defined LegalForms
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csuLegalformGetAllValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  csuLegalformGetAllValid(params?: CsuLegalformGetAllValid$Params|null|undefined, context?: HttpContext): Observable<Array<LegalFormDto>> {
    return this.csuLegalformGetAllValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LegalFormDto>>): Array<LegalFormDto> => r.body)
    );
  }

}
