@if (!isCreateMode) {
  <icz-tabs [tabs]="tabs" class="grow" [waiting]="loadingIndicatorService.isLoading(this)">
    <ng-template iczTab="definition">
      <ng-container *ngTemplateOutlet="definition"></ng-container>
    </ng-template>
    <ng-template iczTab="content">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-template>
  </icz-tabs>
}

@if (isCreateMode) {
  <div [waiting]="loadingIndicatorService.isLoading(this)">
    <ng-container *ngTemplateOutlet="definition"></ng-container>
  </div>
}

<ng-template #definition>
  <div class="definition-tab">
    <div [formGroup]="bulkPostingFormTemplateForm">
      @if (isCreateMode) {
        <div class="row grow row grow gap-48">
          <div class="col grow">
            <icz-form-autocomplete [customOptionTemplate]="templateOptionTemplate"
              [options]="bulkPostingFormTemplateOptions"
              class="grow"
              formControlName="id"
              label="Přednačíst data z uloženého nastavení">
            </icz-form-autocomplete>
          </div>
          <div class="col grow"></div>
        </div>
      }
    </div>

    <ng-template #templateOptionTemplate let-context>
      <icz-form-autocomplete-list-text-item
        [option]="context.option"
        class="w-full"
        >
        <icz-button (onAction)="deleteTemplate(context)" afterItemLabel size="small" svgIcon="delete"></icz-button>
      </icz-form-autocomplete-list-text-item>
    </ng-template>

    <div [formGroup]="form" class="row grow gap-48">
      <div class="col grow">
        <icz-section label="Základní údaje">
          <div class="row grow">
            <icz-form-autocomplete [options]="dispatchOfficeOptions"
              class="grow"
              formControlName="dispatchOfficeId"
              label="Výpravna">
            </icz-form-autocomplete>
            <icz-form-autocomplete [options]="distributorOptions"
              class="grow"
              formControlName="distributorId"
              label="Distributor">
            </icz-form-autocomplete>
          </div>
          <div class="row grow">
            <icz-form-field class="grow" formControlName="name"
            label="Název poštovního podacího archu"></icz-form-field>
          </div>
          <div class="row grow">
            <icz-form-field class="grow" formControlName="customerCardNumber"
            label="Číslo zákaznické karty"></icz-form-field>
            <icz-form-autocomplete [options]="paymentTypeOptions" class="grow" formControlName="paymentType"
            label="Typ úhrady"></icz-form-autocomplete>
          </div>

        </icz-section>
        <icz-section label="Podoba výstupu">
          <div formGroupName="printConfiguration">
            <icz-form-autocomplete [options]="bulkPostingFormPagingOptions"
              class="grow-3"
              formControlName="pagingMethod"
              label="Oddělovat stránky">
            </icz-form-autocomplete>

            <div class="mb-8">{{ 'Typ výstupu' | translate }}</div>
            <div class="row grow">
              <icz-radio-group>
                <div class="row grow">
                  <icz-radio-button [value]="BulkPostingFormFormat.CSV" formControlName="exportFormat"
                  label="CSV"></icz-radio-button>
                  <icz-radio-button [value]="BulkPostingFormFormat.PDF" formControlName="exportFormat"
                  label="PDF"></icz-radio-button>
                </div>
              </icz-radio-group>
            </div>
          </div>
        </icz-section>

        <icz-section label="Řazení výstupu">
          @for (nodeGroup of ownConsignmentSortingForm.controls; track nodeGroup) {
            <div>
              <form [formGroup]="$any(nodeGroup)">
                <div class="row grow items-end">
                  <icz-form-autocomplete [clearable]="false"
                    [options]="bulkPostingFormSortAttributeOptions"
                    class="grow-3"
                    formControlName="bulkPostingFormSortAttribute"
                    label="Řadit podle">
                  </icz-form-autocomplete>
                  <icz-form-autocomplete [clearable]="false"
                    [options]="bulkPostingFormSortOperatorOptions"
                    class="grow-1"
                    formControlName="bulkPostingFormSortOperator">
                  </icz-form-autocomplete>
                  <div class="col">
                    @if ($index === 0) {
                      <icz-button class="invisible mt-16" svgIcon="delete"></icz-button>
                    }
                    @if ($index > 0) {
                      <icz-button (onAction)="removeSortCriterion($index)"
                        [disabled]="!isCreateMode"
                        class="mb-12"
                      svgIcon="delete"></icz-button>
                    }
                  </div>
                </div>
              </form>
            </div>
          }
          @if (isCreateMode) {
            <div class="row grow">
              <icz-button (onAction)="addSortCriterion()" [disabled]="!isCreateMode" class="grow"
              label="Přidat další kritérium pro řazení"></icz-button>
            </div>
          }

        </icz-section>
      </div>

      <div class="col grow">
        <div formGroupName="ownConsignmentFiltering">
          <icz-section label="Pro jaké zásilky vytváříte arch">
            @if (isCreateMode && modalData?.excludedConsignmentIds?.length) {
              <icz-form-help-field
                class="ml-4"
                helpText="({{modalData.excludedConsignmentIds?.length}}) {{'Zásilky jste vyloučili z archu.' | translate}}">
              </icz-form-help-field>
            }
            <icz-radio-group>
              <icz-radio-button [value]="true" formControlName="bulkPostingFormRequired"
              label="Jen zásilky s povinným záznamem doručení"></icz-radio-button>
              <icz-radio-button [value]="false" class="mt-16" formControlName="bulkPostingFormRequired"
              label="Všechny zásilky"></icz-radio-button>
            </icz-radio-group>
            <div class="row grow">
              <icz-label label="Datum převzetí do distribuce"></icz-label>
              <div class="col grow">
                <icz-date-picker formControlName="dispatchOfficeTakeoverDateFrom" outputFormat="dateTime"
                placeholder="Od"></icz-date-picker>
              </div>
              <div class="col grow">
                <icz-date-picker formControlName="dispatchOfficeTakeoverDateTo" outputFormat="dateTime"
                placeholder="Do"></icz-date-picker>
              </div>
            </div>
            <div class="row grow">
              <icz-org-structure-selector
                (valueChangeWithOriginIds)="organizationalUnitIdsChanged($event)"
                [disableCurrentFunctionalPosition]="false"
                [form]="ownConsignmentFilteringForm"
                [isMultiselect]="true"
                class="grow"
                controlName="organizationalUnitIds"
                label="Zásilky patří organizační jednotce"
              ></icz-org-structure-selector>
            </div>
          </icz-section>
          <icz-section label="Jaké doručovací služby na arch zahrnout">
            <div formGroupName="combinationDeliveryService">
              <icz-radio-group>
                <icz-radio-button [value]="null" formControlName="filterOperator"
                label="Zahrnout všechny služby"></icz-radio-button>
                <icz-radio-button [value]="BulkPostingFormFilterOperator.INCLUDE" class="mt-16"
                  formControlName="filterOperator"
                label="Zahrnout jen některé služby"></icz-radio-button>
                <icz-radio-button [value]="BulkPostingFormFilterOperator.EXCLUDE" class="mt-16"
                formControlName="filterOperator" label="Vyloučit některé služby"></icz-radio-button>
              </icz-radio-group>
              <div class="row grow mt-16">
                <icz-form-autocomplete [isMultiselect]="true"
                  [options]="deliveryServiceCombinationOptions"
                  class="grow"
                  formControlName="combinationDeliveryServiceIds"
                  label="Které doručovací služby?">
                </icz-form-autocomplete>
              </div>
            </div>
          </icz-section>
          @if (isCreateMode) {
            <icz-section label="Uložení pro příští použití">
              <div class="row grow">
                <icz-button (onAction)="saveAsTemplate()" class="grow" label="Uložit pro příští použití"></icz-button>
              </div>
            </icz-section>
          }
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #content>
  <icz-bulk-posting-form-dialog-consignments [id]="modalData.bulkPostingForm!.id"></icz-bulk-posting-form-dialog-consignments>
</ng-template>

<icz-form-buttons>
  @if (isCreateMode) {
    <icz-button (onAction)="export()" [disabled]="loadingIndicatorService.isLoading(this)"
      iczFormSubmit
    label="Uložit a tisknout" leftButtons primary></icz-button>
  }
  @if (!isCreateMode) {
    <icz-button (onAction)="export()" [disabled]="loadingIndicatorService.isLoading(this)"
    label="Tisknout" leftButtons primary></icz-button>
  }
  @if (isCreateMode) {
    <icz-button (onAction)="save()" [disabled]="loadingIndicatorService.isLoading(this)"
    iczFormSubmit label="Uložit" leftButtons></icz-button>
  }
  @if (!isCreateMode) {
    <icz-button (onAction)="update()" [disabled]="loadingIndicatorService.isLoading(this)"
    iczFormSubmit label="Uložit změny" leftButtons></icz-button>
  }
  <icz-button (onAction)="close()" [disabled]="loadingIndicatorService.isLoading(this)"
  label="Storno" rightButtons></icz-button>
</icz-form-buttons>
