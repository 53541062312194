import {ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {combineLatest, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {ClassificationSchemeDto, DisposalScheduleDto, EntityClassDto, SecurityCategoryDto} from '|api/codebook';
import {EntityClassType, VolumeHandlingType} from '|api/commons';
import {disposalScheduleToOption, enumToOptions} from '../../../core/services/data-mapping.utils';
import {TableColumnsData} from '../../table/table-columns-data';
import {FilterType} from '../../table/filter.types';
import {getDisposalScheduleFilterConfig} from '../document-table/components/document-filters/document-filter-selectors';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczTableDataSource} from '../../table/table.datasource';
import {InMemorySearchDatasource} from '../../form-elements/form-popup-table-selector/in-memory-search.datasource';
import {CodebookService} from '../../../core/services/codebook.service';
import {ClassificationService} from '../../../core/services/classification.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  FormPopupTableSelectorComponent
} from '../../form-elements/form-popup-table-selector/form-popup-table-selector.component';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';


export function getEntityClassSelectorSchema(disposalSchedules: DisposalScheduleDto[], securityCategories: SecurityCategoryDto[]) {
  const disposalScheduleOptions = disposalSchedules.map(disposalScheduleToOption);
  const securityCategoryOptions = securityCategories.map(sc => ({value: sc.id, label: `${sc.code} ${sc.name}`}));

  return new TableColumnsData<keyof EntityClassDto>([
    {
      id: 'fqcCode',
      label: 'Kód',
      filterType: FilterType.TEXT,
      disableSort: true,
    },
    {
      id: 'name',
      label: 'Název',
      filterType: FilterType.TEXT,
    },
    {
      id: 'description',
      label: 'Popis',
      filterType: FilterType.TEXT,
      displayed: false
    },
    {
      id: 'disposalScheduleId',
      label: 'Sk. režim',
      filterType: FilterType.CODEBOOK,
      list: disposalScheduleOptions,
      disableSort: true,
      filterConfig: getDisposalScheduleFilterConfig(),
    },
    {
      id: 'type',
      label: 'Určena pro',
      filterType: FilterType.NONE, // entity class selectors have this filter always predefined and static
      allowTranslation: true,
      translateWithPrefix: 'en.entityClassType.',
      disableSort: true, // is translated on FE = sorting it (by Codebook ID) would yield weird results
    },
    {
      id: 'securityCategoryId',
      label: 'Bezpečnostní kategorie',
      displayed: false,
      filterType: FilterType.CODEBOOK,
      list: securityCategoryOptions,
      filterConfig: {
        isTree: true,
      },
    },
    {
      id: 'volumeHandlingType',
      label: 'Způsob vedení dílu',
      filterType: FilterType.ENUM,
      list: enumToOptions('volumeHandlingType', VolumeHandlingType),
      allowTranslation: true,
      translateWithPrefix: 'en.volumeHandlingType.',
      displayed: false,
      disableSort: true,
    }
  ]);
}

const SELECTOR_ENTITY_CLASS_TYPE = EntityClassType.DOCUMENT_FILE;

@Component({
  selector: 'icz-entity-class-selector',
  templateUrl: './entity-class-selector.component.html',
  styleUrls: ['./entity-class-selector.component.scss']
})
export class EntityClassSelectorComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);
  private classificationService = inject(ClassificationService);
  private destroyRef = inject(DestroyRef);
  private cd = inject(ChangeDetectorRef);

  @ViewChild('tableSelector')
  tableSelector!: FormPopupTableSelectorComponent<keyof EntityClassDto>;

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;
  @Input()
  label = 'Věcná skupina';
  @Input()
  fieldDisabled: Nullable<boolean>;
  @Input()
  showEntityClassesValidAt = new Date();
  @Input()
  allowedEntityClassIds: Nullable<number[]>;
  @Input()
  showPopupDetails = false;

  validEntityClassesDataSource: IczTableDataSource<EntityClassDto> = new InMemorySearchDatasource(() => []);
  allEntityClassesDataSource: IczTableDataSource<EntityClassDto> = this.classificationService.getAllEntityClassesDataSource(SELECTOR_ENTITY_CLASS_TYPE);

  entityClassSelectorSchema: TableColumnsData<keyof EntityClassDto> = new TableColumnsData<never>([]);
  currentClassificationSchemeName!: string;
  currentClassificationSchemeId!: number;
  disposalSchedules: DisposalScheduleDto[] = [];
  classificationSchemes: ClassificationSchemeDto[] = [];
  securityCategories: SecurityCategoryDto[] = [];

  get selectedEntityClass() {
    return this.tableSelector?.selectedValue?.completeValue as Nullable<EntityClassDto>;
  }

  get isSelectedEntityClass(): boolean {
    return !isNil(this.selectedEntityClass);
  }

  get isSelectedEntityClassExpired() {
    const selectedEntityClass = this.tableSelector?.selectedValue?.completeValue as Nullable<EntityClassDto>;

    if (selectedEntityClass && this.currentClassificationSchemeId) {
      return selectedEntityClass.classificationSchemeId !== this.currentClassificationSchemeId;
    }
    else {
      return false;
    }
  }

  ngOnInit() {
    this.getTableSelectorDataSource();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.showEntityClassesValidAt && changes.showEntityClassesValidAt.currentValue) {
      this.getTableSelectorDataSource();
    }
  }

  private getTableSelectorDataSource() {

    this.showEntityClassesValidAt ??= new Date();

    return combineLatest([
      this.codebookService.disposalSchedules(),
      this.codebookService.securityCategories(),
    ]).pipe(
      switchMap(([disposalSchedules, securityCategories]) => {
        return combineLatest([
          of(disposalSchedules),
          of(securityCategories),
          this.classificationService.getValidEntityClassesDataSource(this.showEntityClassesValidAt, SELECTOR_ENTITY_CLASS_TYPE, this.allowedEntityClassIds),
        ]);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([disposalSchedules, securityCategories, dataSource]) => {
      this.entityClassSelectorSchema = getEntityClassSelectorSchema(disposalSchedules, securityCategories);
      this.validEntityClassesDataSource = dataSource.schemeDataSource;
      this.currentClassificationSchemeId = dataSource.schemeId;
      this.currentClassificationSchemeName = dataSource.schemeName;

      this.cd.detectChanges();
    });
  }

}
