import {Component, Input} from '@angular/core';
import {OwnFaxConsignmentForm} from '../consignment-dialog.form';

@Component({
  selector: 'icz-telefax-consignment-specification',
  templateUrl: './telefax-consignment-specification.component.html',
  styleUrls: ['./telefax-consignment-specification.component.scss']
})
export class TelefaxConsignmentSpecificationComponent {

  @Input({required: true})
  specificFieldsForm!: OwnFaxConsignmentForm;

}
