/* eslint-disable @angular-eslint/directive-class-suffix */
import {Directive, Input} from '@angular/core';
import {InternalNotificationKey, InternalNotificationParameterDto} from '|api/notification';
import {
  ConsignmentToastBulkType,
  ConsignmentToastType,
} from '../../shared-business-components/consignment-dialog/consignments-toast.service';
import {EsslComponentToastType} from '../../../core/services/notifications/essl-component-toast.service';
import {CirculationToastType} from '../../../core/services/notifications/circulation-toast.service';
import {
  EsslComponentCirculationToastType
} from '../../../core/services/notifications/essl-component-circulation-toast.service';
import {DocumentToastType} from '../../../core/services/notifications/document-toast.service';
import {RenditionToastType} from '../../../core/services/notifications/rendition-toast.service';
import {ZodType} from 'zod';
import {FileCirculationToastType} from '../../../core/services/notifications/file-circulation-toast.service';
import {StorageUnitToastType} from '../../../core/services/notifications/storage-unit-toast.service';
import {FileToastType} from '../../../core/services/notifications/file-toast.service';
import {EpdzErrorToastType} from '../../../core/services/notifications/epdz-error-toast.service';
import {RegistryOfficeToastType} from '../../../core/services/notifications/registry-office-toast.service';
import {AdminToastType} from '../../../core/services/notifications/admin-toast.service';
import {enumValuesToArray} from '../../../core/services/data-mapping.utils';


export interface NotificationTemplateWithSchema<T> {
  template: T | ((params: Partial<Record<InternalNotificationKey, string>>, ignoreComponentTypeCheck?: boolean) => string);
  schema: Nullable<ZodType>;
  preserveBodyMode?: boolean;
}

export interface NotificationGenericTemplateWithSchema<T> {
  template: T;
  schema: Nullable<ZodType>;
  preserveBodyMode?: boolean;
}

// E ... enum type
export type NotificationModelMappers<E extends string> = Partial<Record<
  E,
  Nullable<NotificationTemplateWithSchema<GlobalToastType|EsslComponentToastType|CirculationToastType|EsslComponentCirculationToastType|DocumentToastType|RenditionToastType|ConsignmentToastType|ConsignmentToastBulkType|FileCirculationToastType|StorageUnitToastType|EpdzErrorToastType|FileToastType|RegistryOfficeToastType|AdminToastType>>
>>;

export enum GlobalToastType {
  UNKNOWN = 'UNKNOWN', // reserved for nonexistent toasts and templates
}

export function getEsslComponentTemplate(template: string) {
  return (paramsMap: Partial<Record<InternalNotificationKey, string>>, ignoreComponentTypeCheck = false) => {
    const componentNotificationTemplates = enumValuesToArray(EsslComponentCirculationToastType) as unknown as string[];

    if (!ignoreComponentTypeCheck && componentNotificationTemplates.includes(template)) {
      if (paramsMap[InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]) {
        return `${template}_DIGITAL`;
      } else {
        return `${template}_NONDIGITAL`;
      }
    } else {
      return template;
    }
  };
}


@Directive()
export abstract class AbstractNotificationMessageContent<E extends string> {

  protected abstract mappers: NotificationModelMappers<E>;

  @Input()
  isRead = false;
  @Input()
  code!: E;
  @Input()
  set params(newParams: Nullable<InternalNotificationParameterDto[]>) {
    if (newParams) {
      this.paramsMap = {};

      for (const param of newParams) {
        this.paramsMap[param.key] = param.value;
      }
    }
  }
  @Input()
  isBulkNotification = false;

  paramsMap: Partial<Record<InternalNotificationKey, string>> = {};

  protected getTemplate(code: E): Nullable<NotificationTemplateWithSchema<string>> {
    const templateKey = this.mappers[code];

    if (templateKey === undefined) {
      return {
        template: GlobalToastType.UNKNOWN,
        schema: null,
      };
    }
    else {
      return templateKey;
    }
  }

}
