/* tslint:disable */
/* eslint-disable */

/**
 * Validation result
 */
export enum ValidationResult {
  VALID = 'VALID',
  INVALID = 'INVALID',
  INDETERMINATE = 'INDETERMINATE'
}
