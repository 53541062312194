/* tslint:disable */
/* eslint-disable */

/**
 * Operator for sorting the OwnPaperConsignments inside the BulkPostingForm
 */
export enum BulkPostingFormSortOperator {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}
