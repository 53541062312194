import {Directive, inject, Input, TemplateRef} from '@angular/core';

@Directive({
  selector: '[iczTab]',
})
export class TabDirective {

  content = inject(TemplateRef);

  @Input({alias: 'iczTab', required: true}) id!: string;

}
