/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { mediumComponentCreate } from '../fn/medium-component/medium-component-create';
import { MediumComponentCreate$Params } from '../fn/medium-component/medium-component-create';
import { mediumComponentDelete } from '../fn/medium-component/medium-component-delete';
import { MediumComponentDelete$Params } from '../fn/medium-component/medium-component-delete';
import { MediumComponentDto } from '../models/medium-component-dto';
import { mediumComponentFindById } from '../fn/medium-component/medium-component-find-by-id';
import { MediumComponentFindById$Params } from '../fn/medium-component/medium-component-find-by-id';
import { mediumComponentFindMediumComponents } from '../fn/medium-component/medium-component-find-medium-components';
import { MediumComponentFindMediumComponents$Params } from '../fn/medium-component/medium-component-find-medium-components';
import { mediumComponentGetDocumentMediumComponentIds } from '../fn/medium-component/medium-component-get-document-medium-component-ids';
import { MediumComponentGetDocumentMediumComponentIds$Params } from '../fn/medium-component/medium-component-get-document-medium-component-ids';
import { mediumComponentGetDocumentMediumComponents } from '../fn/medium-component/medium-component-get-document-medium-components';
import { MediumComponentGetDocumentMediumComponents$Params } from '../fn/medium-component/medium-component-get-document-medium-components';
import { mediumComponentLinkMediumComponentToDocument } from '../fn/medium-component/medium-component-link-medium-component-to-document';
import { MediumComponentLinkMediumComponentToDocument$Params } from '../fn/medium-component/medium-component-link-medium-component-to-document';
import { mediumComponentUpdate } from '../fn/medium-component/medium-component-update';
import { MediumComponentUpdate$Params } from '../fn/medium-component/medium-component-update';
import { PageMediumComponentDto } from '../models/page-medium-component-dto';


/**
 * Controller for medium component
 */
@Injectable({ providedIn: 'root' })
export class ApiMediumComponentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `mediumComponentFindById()` */
  static readonly MediumComponentFindByIdPath = '/component/medium-component/{id}';

  /**
   * Finds existing medium component by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentFindById$Response(params: MediumComponentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<MediumComponentDto>> {
    return mediumComponentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds existing medium component by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentFindById(params: MediumComponentFindById$Params, context?: HttpContext): Observable<MediumComponentDto> {
    return this.mediumComponentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediumComponentDto>): MediumComponentDto => r.body)
    );
  }

  /** Path part for operation `mediumComponentUpdate()` */
  static readonly MediumComponentUpdatePath = '/component/medium-component/{id}';

  /**
   * Update metadata for existing medium component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mediumComponentUpdate$Response(params: MediumComponentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<MediumComponentDto>> {
    return mediumComponentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update metadata for existing medium component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mediumComponentUpdate(params: MediumComponentUpdate$Params, context?: HttpContext): Observable<MediumComponentDto> {
    return this.mediumComponentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediumComponentDto>): MediumComponentDto => r.body)
    );
  }

  /** Path part for operation `mediumComponentCreate()` */
  static readonly MediumComponentCreatePath = '/component/medium-component';

  /**
   * Create new medium component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mediumComponentCreate$Response(params: MediumComponentCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<MediumComponentDto>> {
    return mediumComponentCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new medium component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mediumComponentCreate(params: MediumComponentCreate$Params, context?: HttpContext): Observable<MediumComponentDto> {
    return this.mediumComponentCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<MediumComponentDto>): MediumComponentDto => r.body)
    );
  }

  /** Path part for operation `mediumComponentLinkMediumComponentToDocument()` */
  static readonly MediumComponentLinkMediumComponentToDocumentPath = '/component/medium-component/{documentId}/link';

  /**
   * Link medium component for document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentLinkMediumComponentToDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mediumComponentLinkMediumComponentToDocument$Response(params: MediumComponentLinkMediumComponentToDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mediumComponentLinkMediumComponentToDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Link medium component for document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentLinkMediumComponentToDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  mediumComponentLinkMediumComponentToDocument(params: MediumComponentLinkMediumComponentToDocument$Params, context?: HttpContext): Observable<void> {
    return this.mediumComponentLinkMediumComponentToDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `mediumComponentGetDocumentMediumComponents()` */
  static readonly MediumComponentGetDocumentMediumComponentsPath = '/component/medium-component/{documentId}/medium-components';

  /**
   * Returns medium components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentGetDocumentMediumComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentGetDocumentMediumComponents$Response(params: MediumComponentGetDocumentMediumComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MediumComponentDto>>> {
    return mediumComponentGetDocumentMediumComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns medium components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentGetDocumentMediumComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentGetDocumentMediumComponents(params: MediumComponentGetDocumentMediumComponents$Params, context?: HttpContext): Observable<Array<MediumComponentDto>> {
    return this.mediumComponentGetDocumentMediumComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MediumComponentDto>>): Array<MediumComponentDto> => r.body)
    );
  }

  /** Path part for operation `mediumComponentFindMediumComponents()` */
  static readonly MediumComponentFindMediumComponentsPath = '/component/medium-component/{documentId}/medium-components/search-api';

  /**
   * Finds medium components based on documentId and search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentFindMediumComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentFindMediumComponents$Response(params: MediumComponentFindMediumComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<PageMediumComponentDto>> {
    return mediumComponentFindMediumComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds medium components based on documentId and search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentFindMediumComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentFindMediumComponents(params: MediumComponentFindMediumComponents$Params, context?: HttpContext): Observable<PageMediumComponentDto> {
    return this.mediumComponentFindMediumComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageMediumComponentDto>): PageMediumComponentDto => r.body)
    );
  }

  /** Path part for operation `mediumComponentGetDocumentMediumComponentIds()` */
  static readonly MediumComponentGetDocumentMediumComponentIdsPath = '/component/medium-component/{documentId}/medium-component-ids';

  /**
   * Returns medium components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentGetDocumentMediumComponentIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentGetDocumentMediumComponentIds$Response(params: MediumComponentGetDocumentMediumComponentIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return mediumComponentGetDocumentMediumComponentIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns medium components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentGetDocumentMediumComponentIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentGetDocumentMediumComponentIds(params: MediumComponentGetDocumentMediumComponentIds$Params, context?: HttpContext): Observable<Array<number>> {
    return this.mediumComponentGetDocumentMediumComponentIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `mediumComponentDelete()` */
  static readonly MediumComponentDeletePath = '/component/medium-component/per-entity/document/{documentId}/component/{componentId}';

  /**
   * Deletes medium component in a given document according to theirs ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `mediumComponentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentDelete$Response(params: MediumComponentDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return mediumComponentDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes medium component in a given document according to theirs ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `mediumComponentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  mediumComponentDelete(params: MediumComponentDelete$Params, context?: HttpContext): Observable<void> {
    return this.mediumComponentDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
