/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiPhysicalItemComponentService } from './services/api-physical-item-component.service';
import { ApiPaperComponentService } from './services/api-paper-component.service';
import { ApiMediumComponentService } from './services/api-medium-component.service';
import { ApiDigitalComponentService } from './services/api-digital-component.service';
import { ApiDigitalComponentTemplateService } from './services/api-digital-component-template.service';
import { ApiSignatureService } from './services/api-signature.service';
import { ApiDigitalComponentVersionService } from './services/api-digital-component-version.service';
import { ApiConversionService } from './services/api-conversion.service';
import { ApiConversionRecordService } from './services/api-conversion-record.service';
import { ApiComponentService } from './services/api-component.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiPhysicalItemComponentService,
    ApiPaperComponentService,
    ApiMediumComponentService,
    ApiDigitalComponentService,
    ApiDigitalComponentTemplateService,
    ApiSignatureService,
    ApiDigitalComponentVersionService,
    ApiConversionService,
    ApiConversionRecordService,
    ApiComponentService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
