/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { digitalComponentTemplateCheckUpdatePermisssion } from '../fn/digital-component-template/digital-component-template-check-update-permisssion';
import { DigitalComponentTemplateCheckUpdatePermisssion$Params } from '../fn/digital-component-template/digital-component-template-check-update-permisssion';
import { digitalComponentTemplateCreate } from '../fn/digital-component-template/digital-component-template-create';
import { DigitalComponentTemplateCreate$Params } from '../fn/digital-component-template/digital-component-template-create';
import { digitalComponentTemplateDelete } from '../fn/digital-component-template/digital-component-template-delete';
import { DigitalComponentTemplateDelete$Params } from '../fn/digital-component-template/digital-component-template-delete';
import { DigitalComponentTemplateDto } from '../models/digital-component-template-dto';
import { digitalComponentTemplateFindAll } from '../fn/digital-component-template/digital-component-template-find-all';
import { DigitalComponentTemplateFindAll$Params } from '../fn/digital-component-template/digital-component-template-find-all';
import { digitalComponentTemplateFindById } from '../fn/digital-component-template/digital-component-template-find-by-id';
import { DigitalComponentTemplateFindById$Params } from '../fn/digital-component-template/digital-component-template-find-by-id';
import { digitalComponentTemplateFindDigitalComponentTemplates } from '../fn/digital-component-template/digital-component-template-find-digital-component-templates';
import { DigitalComponentTemplateFindDigitalComponentTemplates$Params } from '../fn/digital-component-template/digital-component-template-find-digital-component-templates';
import { digitalComponentTemplateUpdate } from '../fn/digital-component-template/digital-component-template-update';
import { DigitalComponentTemplateUpdate$Params } from '../fn/digital-component-template/digital-component-template-update';
import { DigitalComponentTemplateValidateResponseDto } from '../models/digital-component-template-validate-response-dto';
import { PageDigitalComponentTemplateDto } from '../models/page-digital-component-template-dto';


/**
 * Contains endpoints related to Digital component Templates
 */
@Injectable({ providedIn: 'root' })
export class ApiDigitalComponentTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `digitalComponentTemplateFindById()` */
  static readonly DigitalComponentTemplateFindByIdPath = '/component/digital-component-template/{id}';

  /**
   * Digital Component Template Get.
   *
   * Gets digital component template by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateFindById$Response(params: DigitalComponentTemplateFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentTemplateDto>> {
    return digitalComponentTemplateFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital Component Template Get.
   *
   * Gets digital component template by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateFindById(params: DigitalComponentTemplateFindById$Params, context?: HttpContext): Observable<DigitalComponentTemplateDto> {
    return this.digitalComponentTemplateFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentTemplateDto>): DigitalComponentTemplateDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentTemplateUpdate()` */
  static readonly DigitalComponentTemplateUpdatePath = '/component/digital-component-template/{id}';

  /**
   * Digital Component Template Update.
   *
   * Updates Digital Component Template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentTemplateUpdate$Response(params: DigitalComponentTemplateUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentTemplateDto>> {
    return digitalComponentTemplateUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital Component Template Update.
   *
   * Updates Digital Component Template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentTemplateUpdate(params: DigitalComponentTemplateUpdate$Params, context?: HttpContext): Observable<DigitalComponentTemplateDto> {
    return this.digitalComponentTemplateUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentTemplateDto>): DigitalComponentTemplateDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentTemplateDelete()` */
  static readonly DigitalComponentTemplateDeletePath = '/component/digital-component-template/{id}';

  /**
   * Digital Component Template Deletion.
   *
   * Deletes of digital component template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateDelete$Response(params: DigitalComponentTemplateDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return digitalComponentTemplateDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital Component Template Deletion.
   *
   * Deletes of digital component template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateDelete(params: DigitalComponentTemplateDelete$Params, context?: HttpContext): Observable<void> {
    return this.digitalComponentTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `digitalComponentTemplateCheckUpdatePermisssion()` */
  static readonly DigitalComponentTemplateCheckUpdatePermisssionPath = '/component/digital-component-template/check-update-permisssion';

  /**
   * Digital component template Validate Update Permission.
   *
   * Checks if logged fp i permitted to update action.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateCheckUpdatePermisssion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentTemplateCheckUpdatePermisssion$Response(params: DigitalComponentTemplateCheckUpdatePermisssion$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentTemplateValidateResponseDto>>> {
    return digitalComponentTemplateCheckUpdatePermisssion(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital component template Validate Update Permission.
   *
   * Checks if logged fp i permitted to update action.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateCheckUpdatePermisssion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentTemplateCheckUpdatePermisssion(params: DigitalComponentTemplateCheckUpdatePermisssion$Params, context?: HttpContext): Observable<Array<DigitalComponentTemplateValidateResponseDto>> {
    return this.digitalComponentTemplateCheckUpdatePermisssion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DigitalComponentTemplateValidateResponseDto>>): Array<DigitalComponentTemplateValidateResponseDto> => r.body)
    );
  }

  /** Path part for operation `digitalComponentTemplateFindAll()` */
  static readonly DigitalComponentTemplateFindAllPath = '/component/digital-component-template/';

  /**
   * Finds all digital component templates.
   *
   * Returns all digital component templates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateFindAll$Response(params?: DigitalComponentTemplateFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentTemplateDto>>> {
    return digitalComponentTemplateFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all digital component templates.
   *
   * Returns all digital component templates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateFindAll(params?: DigitalComponentTemplateFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DigitalComponentTemplateDto>> {
    return this.digitalComponentTemplateFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DigitalComponentTemplateDto>>): Array<DigitalComponentTemplateDto> => r.body)
    );
  }

  /** Path part for operation `digitalComponentTemplateCreate()` */
  static readonly DigitalComponentTemplateCreatePath = '/component/digital-component-template/';

  /**
   * Digital Component Template Create.
   *
   * Creates a new digital component template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentTemplateCreate$Response(params: DigitalComponentTemplateCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentTemplateDto>> {
    return digitalComponentTemplateCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Digital Component Template Create.
   *
   * Creates a new digital component template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  digitalComponentTemplateCreate(params: DigitalComponentTemplateCreate$Params, context?: HttpContext): Observable<DigitalComponentTemplateDto> {
    return this.digitalComponentTemplateCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentTemplateDto>): DigitalComponentTemplateDto => r.body)
    );
  }

  /** Path part for operation `digitalComponentTemplateFindDigitalComponentTemplates()` */
  static readonly DigitalComponentTemplateFindDigitalComponentTemplatesPath = '/component/digital-component-template/search-api';

  /**
   * Finds digital component templates.
   *
   * Returns digital component templates for the passed search predicate.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `digitalComponentTemplateFindDigitalComponentTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateFindDigitalComponentTemplates$Response(params?: DigitalComponentTemplateFindDigitalComponentTemplates$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDigitalComponentTemplateDto>> {
    return digitalComponentTemplateFindDigitalComponentTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds digital component templates.
   *
   * Returns digital component templates for the passed search predicate.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `digitalComponentTemplateFindDigitalComponentTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  digitalComponentTemplateFindDigitalComponentTemplates(params?: DigitalComponentTemplateFindDigitalComponentTemplates$Params|null|undefined, context?: HttpContext): Observable<PageDigitalComponentTemplateDto> {
    return this.digitalComponentTemplateFindDigitalComponentTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDigitalComponentTemplateDto>): PageDigitalComponentTemplateDto => r.body)
    );
  }

}
