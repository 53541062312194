/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PortalNodeCreateDto } from '../../models/portal-node-create-dto';
import { PortalNodeDto } from '../../models/portal-node-dto';

export interface PortalNodesOauthCreate$Params {
      body: PortalNodeCreateDto
}

export function portalNodesOauthCreate(http: HttpClient, rootUrl: string, params: PortalNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PortalNodeDto>> {
  const rb = new RequestBuilder(rootUrl, portalNodesOauthCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PortalNodeDto>;
    })
  );
}

portalNodesOauthCreate.PATH = '/oauth/portal-nodes/create-node';