@if (signatureOrSeal) {
  <icz-section label="{{ signatureOrSealLabel | translate }} {{ indexText }}">
    <ng-container sectionHeaderBeforeLabel>
      <icz-icon class="signature-icon" size="small" [svgIcon]="headerIcon"></icz-icon>
    </ng-container>
    @if (signatureOrSeal.qcType === 'INDETERMINATE') {
      <icz-alert severity="info" heading="Nepodařilo se určit, zda se jedná o pečeť nebo podpis"></icz-alert>
    }
    <div class="col gap-8 mb-8 pt-8">
      @if (isValidSignatureOrSeal) {
        <div class="row gap-8">
          <icz-tag svgIcon="checked" [inline]="true" color="green" [label]="validSignatureOrSealLabel"></icz-tag>
          <icz-tag [inline]="true" [color]="qualifiedTagColor" [label]="qualifiedLabel" [labelContext]="qcType"></icz-tag>
        </div>
      }
      @if (isInvalidSignatureOrSeal) {
        <icz-alert severity="error" [heading]="notValidSignatureOrSealHeadingLabel" [label]="invalidOrIndeterminateLabelLong"></icz-alert>
      }
      @if (isIndeterminateSignatureOrSeal) {
        <icz-alert severity="warning" [heading]="indeterminateSignatureOrSealHeadingLabel" [label]="invalidOrIndeterminateLabelLong"></icz-alert>
      }
      @if (isSignatoryDefined) {
        <icz-alert severity="neutral" customSvgIcon="signature" [heading]="signatoryLabel" [textContext]="signatory"></icz-alert>
      }
      <icz-alert
        severity="neutral" [customSvgIcon]="hasTimestamps ? 'stamp' : 'time'"
        [heading]="signedOrSealedDateLabel" [label]="hasTimestamps ? 'S časovým razítkem' : ''"
        [textContext]="{date: (signatureOrSeal.signatureTime | localizedDatetime)}"
      ></icz-alert>
    </div>
    <icz-section [label]="sectionLabel" [expanded]="false">
      <icz-label [hasNormalWhitespace]="true" class="font-bold" [label]="signatureOrSealLabel"></icz-label>
      <div class="gap-4 mb-16 info-list col">
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" [label]="formAndLevelLabel"></icz-label>
          <span class="font-bold">{{ signatureOrSeal.form }} - {{ signatureOrSeal.level }}</span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Hash"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.digestDto!.hash! }}
          </span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Algoritmus"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.digestDto!.hashAlgorithm! }}
          </span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Vytvořen"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.signatureTime | localizedDatetime }}
          </span>
        </div>
      </div>
      <icz-label [hasNormalWhitespace]="true" class="font-bold" label="Certifikát"></icz-label>
      <div class="gap-4 mb-16 info-list col">
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Sériové číslo certifikátu"></icz-label>
          <span class="font-bold">{{ signatureOrSeal.certificateInfoDto!.serialNumber! }}</span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Subjekt"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.certificateInfoDto!.subject! }}
          </span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Vydavatel"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.certificateInfoDto!.issuer! }}
          </span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Platnost certifikátu od"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.certificateInfoDto!.validFrom! | localizedDatetime }}
          </span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Platnost certifikátu do"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.certificateInfoDto!.validTo! | localizedDatetime }}
          </span>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Revokován"></icz-label>
          <icz-label [hasNormalWhitespace]="true" class="font-bold" [label]="revokedLabel"></icz-label>
        </div>
        <div class="row">
          <icz-label [hasNormalWhitespace]="true" label="Ověřeno"></icz-label>
          <span class="font-bold">
            {{ signatureOrSeal.certificateInfoDto!.crlIssuingTime! | localizedDatetime }}
          </span>
        </div>
      </div>
      @for (timestamp of signatureOrSeal.timestamps; track timestamp.id) {
        <icz-label [hasNormalWhitespace]="true" class="font-bold" label="Časové razítko"></icz-label>
        <div class="gap-4 info-list col" [class.mb-16]="signatureOrSeal.timestamps!.length > 1 && !$last">
          <div class="row">
            <icz-label [hasNormalWhitespace]="true" label="Sériové číslo"></icz-label>
            <span class="font-bold">{{ timestamp.certificateInfoDto!.serialNumber! }}</span>
          </div>
          <div class="row">
            <icz-label [hasNormalWhitespace]="true" label="Subjekt"></icz-label>
            <span class="font-bold">
              {{ timestamp.certificateInfoDto?.signatory?.commonName ?? '' }}
            </span>
          </div>
          <div class="row">
            <icz-label [hasNormalWhitespace]="true" label="Vydavatel"></icz-label>
            <span class="font-bold">
              {{ timestamp.certificateInfoDto!.issuer! }}
            </span>
          </div>
          <div class="row">
            <icz-label [hasNormalWhitespace]="true" label="Ověřený datum a čas"></icz-label>
            <span class="font-bold">
              {{ timestamp.signatureTime | localizedDatetime }}
            </span>
          </div>
          <div class="row">
            <icz-label [hasNormalWhitespace]="true" label="Platnost"></icz-label>
            <icz-label [hasNormalWhitespace]="true" class="font-bold" [label]="getTimestampResultLabel(timestamp)"></icz-label>
          </div>
        </div>
      }
    </icz-section>
  </icz-section>
}
