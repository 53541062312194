/* tslint:disable */
/* eslint-disable */

/**
 * Method of ISSD import.
 */
export enum IssdImportType {
  API = 'API',
  DIRECTORY = 'DIRECTORY'
}
