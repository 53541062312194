import {ChangeDetectorRef, DestroyRef, Directive, forwardRef, inject, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FormFilterComponent} from './form-filter.component';
import {FilterItemValue} from '../../filter.types';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

;


@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'icz-form-filter[formControlName]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFilterValueAccessorDirective),
    multi: true,
  }],
})
export class FormFilterValueAccessorDirective implements ControlValueAccessor, OnInit {

  private cd = inject(ChangeDetectorRef);
  private formFilterComponent = inject(FormFilterComponent);
  private destroyRef = inject(DestroyRef);

  @Input()
  formControlName!: string;

  ngOnInit(): void {
    this.formFilterComponent.valueChange.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(newValue => this.onChange(newValue));

    this.formFilterComponent.blur.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(_ => this.onTouched());
  }

  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.formFilterComponent.value);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.formFilterComponent._fieldDisabledFromReactiveModel = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(newValue: FilterItemValue): void {
    this.formFilterComponent.value = newValue;
    this.cd.detectChanges();
  }

  private onChange = (_: FilterItemValue) => {};
  private onTouched = () => {};

}
