/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { externalNotificationAppendixConfigurationCreate } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-create';
import { ExternalNotificationAppendixConfigurationCreate$Params } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-create';
import { externalNotificationAppendixConfigurationDelete } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-delete';
import { ExternalNotificationAppendixConfigurationDelete$Params } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-delete';
import { ExternalNotificationAppendixConfigurationDto } from '../models/external-notification-appendix-configuration-dto';
import { externalNotificationAppendixConfigurationFind } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-find';
import { ExternalNotificationAppendixConfigurationFind$Params } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-find';
import { externalNotificationAppendixConfigurationFindById } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-find-by-id';
import { ExternalNotificationAppendixConfigurationFindById$Params } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-find-by-id';
import { externalNotificationAppendixConfigurationUpdate } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-update';
import { ExternalNotificationAppendixConfigurationUpdate$Params } from '../fn/external-notification-appendix-configuration/external-notification-appendix-configuration-update';
import { PageExternalNotificationAppendixConfigurationDto } from '../models/page-external-notification-appendix-configuration-dto';


/**
 * Controller for External notification appendix configuration
 */
@Injectable({ providedIn: 'root' })
export class ApiExternalNotificationAppendixConfigurationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `externalNotificationAppendixConfigurationFindById()` */
  static readonly ExternalNotificationAppendixConfigurationFindByIdPath = '/notification/external-notification-appendix-configuration/{id}';

  /**
   * Find external notification appendix configuration by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationAppendixConfigurationFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationAppendixConfigurationFindById$Response(params: ExternalNotificationAppendixConfigurationFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>> {
    return externalNotificationAppendixConfigurationFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find external notification appendix configuration by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationAppendixConfigurationFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationAppendixConfigurationFindById(params: ExternalNotificationAppendixConfigurationFindById$Params, context?: HttpContext): Observable<ExternalNotificationAppendixConfigurationDto> {
    return this.externalNotificationAppendixConfigurationFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>): ExternalNotificationAppendixConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationAppendixConfigurationUpdate()` */
  static readonly ExternalNotificationAppendixConfigurationUpdatePath = '/notification/external-notification-appendix-configuration/{id}';

  /**
   * Update existing external notification appendix configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationAppendixConfigurationUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationAppendixConfigurationUpdate$Response(params: ExternalNotificationAppendixConfigurationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>> {
    return externalNotificationAppendixConfigurationUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing external notification appendix configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationAppendixConfigurationUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationAppendixConfigurationUpdate(params: ExternalNotificationAppendixConfigurationUpdate$Params, context?: HttpContext): Observable<ExternalNotificationAppendixConfigurationDto> {
    return this.externalNotificationAppendixConfigurationUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>): ExternalNotificationAppendixConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationAppendixConfigurationDelete()` */
  static readonly ExternalNotificationAppendixConfigurationDeletePath = '/notification/external-notification-appendix-configuration/{id}';

  /**
   * Delete external notification appendix configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationAppendixConfigurationDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationAppendixConfigurationDelete$Response(params: ExternalNotificationAppendixConfigurationDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return externalNotificationAppendixConfigurationDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete external notification appendix configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationAppendixConfigurationDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationAppendixConfigurationDelete(params: ExternalNotificationAppendixConfigurationDelete$Params, context?: HttpContext): Observable<void> {
    return this.externalNotificationAppendixConfigurationDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `externalNotificationAppendixConfigurationCreate()` */
  static readonly ExternalNotificationAppendixConfigurationCreatePath = '/notification/external-notification-appendix-configuration';

  /**
   * Create new external notification appendix configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationAppendixConfigurationCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationAppendixConfigurationCreate$Response(params: ExternalNotificationAppendixConfigurationCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>> {
    return externalNotificationAppendixConfigurationCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new external notification appendix configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationAppendixConfigurationCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  externalNotificationAppendixConfigurationCreate(params: ExternalNotificationAppendixConfigurationCreate$Params, context?: HttpContext): Observable<ExternalNotificationAppendixConfigurationDto> {
    return this.externalNotificationAppendixConfigurationCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>): ExternalNotificationAppendixConfigurationDto => r.body)
    );
  }

  /** Path part for operation `externalNotificationAppendixConfigurationFind()` */
  static readonly ExternalNotificationAppendixConfigurationFindPath = '/notification/external-notification-appendix-configuration/search-api';

  /**
   * A standard SearchApi endpoint returning external notification appendix configurations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `externalNotificationAppendixConfigurationFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationAppendixConfigurationFind$Response(params?: ExternalNotificationAppendixConfigurationFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageExternalNotificationAppendixConfigurationDto>> {
    return externalNotificationAppendixConfigurationFind(this.http, this.rootUrl, params, context);
  }

  /**
   * A standard SearchApi endpoint returning external notification appendix configurations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `externalNotificationAppendixConfigurationFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  externalNotificationAppendixConfigurationFind(params?: ExternalNotificationAppendixConfigurationFind$Params|null|undefined, context?: HttpContext): Observable<PageExternalNotificationAppendixConfigurationDto> {
    return this.externalNotificationAppendixConfigurationFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageExternalNotificationAppendixConfigurationDto>): PageExternalNotificationAppendixConfigurationDto => r.body)
    );
  }

}
