/* tslint:disable */
/* eslint-disable */

/**
 * Barcode format
 */
export enum BarcodeFormat {
  HEIGHT_15_MM = 'HEIGHT_15MM',
  HEIGHT_12_MM = 'HEIGHT_12MM',
  HEIGHT_10_MM = 'HEIGHT_10MM',
  HEIGHT_8_MM = 'HEIGHT_8MM',
  HEIGHT_6_MM = 'HEIGHT_6MM'
}
