/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CirculationSignResultDto } from '../models/circulation-sign-result-dto';
import { circulationTaskComponentSigningReject } from '../fn/circulation-task-component-signing/circulation-task-component-signing-reject';
import { CirculationTaskComponentSigningReject$Params } from '../fn/circulation-task-component-signing/circulation-task-component-signing-reject';
import { circulationTaskComponentSigningRevoke } from '../fn/circulation-task-component-signing/circulation-task-component-signing-revoke';
import { CirculationTaskComponentSigningRevoke$Params } from '../fn/circulation-task-component-signing/circulation-task-component-signing-revoke';
import { circulationTaskComponentSigningSignAnalogComponent } from '../fn/circulation-task-component-signing/circulation-task-component-signing-sign-analog-component';
import { CirculationTaskComponentSigningSignAnalogComponent$Params } from '../fn/circulation-task-component-signing/circulation-task-component-signing-sign-analog-component';
import { circulationTaskComponentSigningSignDigitalComponent } from '../fn/circulation-task-component-signing/circulation-task-component-signing-sign-digital-component';
import { CirculationTaskComponentSigningSignDigitalComponent$Params } from '../fn/circulation-task-component-signing/circulation-task-component-signing-sign-digital-component';
import { CirculationTaskDto } from '../models/circulation-task-dto';


/**
 * Controller for executing actions on statement document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskComponentSigningService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskComponentSigningSignDigitalComponent()` */
  static readonly CirculationTaskComponentSigningSignDigitalComponentPath = '/flow/signing/{id}/sign-digital';

  /**
   * todo
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskComponentSigningSignDigitalComponent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskComponentSigningSignDigitalComponent$Response(params: CirculationTaskComponentSigningSignDigitalComponent$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationSignResultDto>> {
    return circulationTaskComponentSigningSignDigitalComponent(this.http, this.rootUrl, params, context);
  }

  /**
   * todo
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskComponentSigningSignDigitalComponent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskComponentSigningSignDigitalComponent(params: CirculationTaskComponentSigningSignDigitalComponent$Params, context?: HttpContext): Observable<CirculationSignResultDto> {
    return this.circulationTaskComponentSigningSignDigitalComponent$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationSignResultDto>): CirculationSignResultDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskComponentSigningSignAnalogComponent()` */
  static readonly CirculationTaskComponentSigningSignAnalogComponentPath = '/flow/signing/{id}/sign-analog';

  /**
   * todo
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskComponentSigningSignAnalogComponent()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskComponentSigningSignAnalogComponent$Response(params: CirculationTaskComponentSigningSignAnalogComponent$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskComponentSigningSignAnalogComponent(this.http, this.rootUrl, params, context);
  }

  /**
   * todo
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskComponentSigningSignAnalogComponent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskComponentSigningSignAnalogComponent(params: CirculationTaskComponentSigningSignAnalogComponent$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskComponentSigningSignAnalogComponent$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskComponentSigningRevoke()` */
  static readonly CirculationTaskComponentSigningRevokePath = '/flow/signing/{id}/revoke';

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskComponentSigningRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskComponentSigningRevoke$Response(params: CirculationTaskComponentSigningRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskComponentSigningRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskComponentSigningRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskComponentSigningRevoke(params: CirculationTaskComponentSigningRevoke$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskComponentSigningRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskComponentSigningReject()` */
  static readonly CirculationTaskComponentSigningRejectPath = '/flow/signing/{id}/reject';

  /**
   * Finish task by reject task by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskComponentSigningReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskComponentSigningReject$Response(params: CirculationTaskComponentSigningReject$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskComponentSigningReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by reject task by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskComponentSigningReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskComponentSigningReject(params: CirculationTaskComponentSigningReject$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskComponentSigningReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

}
