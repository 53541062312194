import {inject, Injectable} from '@angular/core';
import {IczModalService} from '../../../../services/icz-modal.service';
import {
  ManualDeliveryResultDialogComponent,
  ManualDeliveryResultDialogData,
  ManualDeliveryResultDialogResult
} from '../../own-consignment-table/components/manual-delivery-result-dialog/manual-delivery-result-dialog.component';
import {ManualDeliveryResultRecordAction} from '../../own-consignment-table/model/own-consignment-model';


@Injectable({providedIn: 'root'})
export class ManualDeliveryResultDialogService {

  private modalService = inject(IczModalService);

  openManualDeliveryResultDialog(dialogData: ManualDeliveryResultDialogData) {
    return this.modalService.openComponentInModal<ManualDeliveryResultDialogResult, ManualDeliveryResultDialogData>({
      component: ManualDeliveryResultDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: dialogData.action === ManualDeliveryResultRecordAction.RECORD_DELIVERY_RESULT ? 'Zapsat doručení' : 'Připojit doručenku',
        disableAutoMargin: true,
      },
      data: dialogData,
    });
  }

}
