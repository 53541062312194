<ng-container [formGroup]="form">
  <!--
      Options for this selector are generated by makeFileSearchOptionsDefinition,
      [options]="recentlyVisitedFilesOptions" is a hack because the option selected
      from icz-option-list below would otherwise not render correctly.
      Extension of Autocomplete for asynchronous values would cost a lost more mandays.
  -->
  <icz-form-autocomplete
    #objectSelector
    iczAutoFocus
    [noResultsTemplate]="noResultsTemplate"
    [formControlName]="controlName"
    [options]="recentlyVisitedObjectsOptions"
    [optionsDefinitionFactory]="makeObjectSearchOptionsDefinition"
    label="Vyberte spis"
    placeholder="Hledejte v názvu, spisové značce nebo UID spisu"
    [minSearchTermLength]="minSearchTermLength"
    (valueChange)="onObjectSelected($event)"
  ></icz-form-autocomplete>
</ng-container>

<ng-template #noResultsTemplate let-context>
  <div class="no-options-found">
    {{ ((context.searchTerm?.length ?? 0) >= minSearchTermLength ? 'Žádné spisy nenalezeny' : 'Pro vyhledání zadejte minimálně tři znaky') | translate }}
  </div>
  <!-- Hack to show the recently visited list when searchTermLength is < 3. -->
  <icz-options-list
    [options]="recentlyVisitedObjectsOptions"
    (selectionChanged)="recentlyVisitedObjectSelected($event)"
  ></icz-options-list>
</ng-template>
