<div class="row grow gap-24" [waiting]="loadingIndicatorService.isLoading(this)">
  @if (signatures$ | async; as signatures) {
    @if (signatures.length) {
      <div
        class="col grow gap-x-24"
        [class.grid]="showTwoColGrid$ | async"
        [class.grid-cols-2]="showTwoColGrid$ | async"
        >
        @for (signature of signatures; track signature.id) {
          <icz-digital-component-signature-info
            [signatureIndex]="$index + 1"
            [totalNumberOfSignatures]="signatures.length"
            [signatureOrSeal]="signature"
            >
          </icz-digital-component-signature-info>
        }
      </div>
    }
  }
  @if (seals$ | async; as seals) {
    @if (seals.length) {
      <div
        class="col grow gap-x-24"
        [class.grid]="showTwoColGrid$ | async"
        [class.grid-cols-2]="showTwoColGrid$ | async"
        >
        @for (seal of seals; track seal.id) {
          <icz-digital-component-signature-info
            [sealIndex]="$index + 1"
            [totalNumberOfSeals]="seals.length"
            [signatureOrSeal]="seal"
            >
          </icz-digital-component-signature-info>
        }
      </div>
    }
  }
</div>
@if (modalData?.lastClauseDate) {
  <div class="row grow">
<!--    // todo 12160 this should display the same detail about clause as component detail dialog-->
    <icz-section label="Doložka o autorizované konverzi" svgIcon="conversion" svgIconSize="small">
      <icz-readonly-field label="Datum vyhotovení doložky" [value]="modalData.lastClauseDate | localizedDatetime"></icz-readonly-field>
    </icz-section>
  </div>
}

<icz-form-buttons>
  <icz-button (onAction)="closeDialog()" rightButtons label="Storno"></icz-button>
</icz-form-buttons>
