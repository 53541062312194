import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {PageEvent} from '@angular/material/paginator';
import {Router} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {filter, map, shareReplay, startWith, switchMap, tap} from 'rxjs/operators';
import {AcknowledgementStatus, ApiInternalNotificationService, InternalNotificationDto} from '|api/notification';
import {NotificationSearchService} from '../notification-search.service';
import {NotificationCountService} from '../notification-count.service';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {Option} from '../../../model';
import {FilterOperator, SearchParams} from '../../../services/search-api.service';
import {namedDtoToOption} from '../../../core/services/data-mapping.utils';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {CodebookService} from '../../../core/services/codebook.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {getMessageHeaderParams} from '../notification-message-header/notification-message-header.component';
import {NotificationMessageDialogComponent} from '../notification-message-dialog/notification-message-dialog.component';
import {IczModalService} from '../../../services/icz-modal.service';
import {PaginatorRange} from '../../form-elements/paginator/paginator.component';


@Component({
  selector: 'icz-notifications-sidebar',
  templateUrl: './notifications-sidebar.component.html',
  styleUrls: ['./notifications-sidebar.component.scss'],
  providers: [ApiInternalNotificationService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsSidebarComponent implements OnInit, IczOnChanges {

  private notificationSearchService = inject(NotificationSearchService);
  private loadingIndicatorService = inject(LoadingIndicatorService);
  private apiInternalNotificationService = inject(ApiInternalNotificationService);
  private notificationCountService = inject(NotificationCountService);
  private codebookService = inject(CodebookService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private modalService = inject(IczModalService);

  @HostBinding('class.opened')
  @Input({required: true})
  opened = false;

  @Output()
  closed = new EventEmitter<void>();

  get isLoading$() {
    return this.loadingIndicatorService.isLoading$(this);
  }

  form: IczFormGroup = new IczFormGroup({
    showOnlyUnread: new IczFormControl<Nullable<boolean>>(true),
  });

  codebooksLoaded = false;
  functionalPositions: Option[] = [];

  unreadNotificationsCount = 0;

  notificationPageSize = 20;
  notificationPageIndex = 0;
  notificationPageRange: PaginatorRange = {indexStart: 0, indexEnd: 0, length: 0};

  readonly AcknowledgementStatus = AcknowledgementStatus;

  private _initNotifications$ = new BehaviorSubject(null);

  private _showOnlyUnread$ = this.showOnlyUnreadControl.valueChanges;

  private _notifications$ = this._initNotifications$.pipe(
    switchMap(() => this._showOnlyUnread$),
    tap(() => {
      this.loadingIndicatorService.startLoading(this);
    }),
    startWith(this.showOnlyUnread),
    switchMap(showOnlyUnread => {
      const initSearch: Partial<SearchParams> = {page: this.notificationPageIndex, size: this.notificationPageSize};
      if (showOnlyUnread) {
        initSearch.page = this.notificationPageIndex;
        initSearch.filter = [{operator: FilterOperator.equals, value: AcknowledgementStatus.UNREAD, fieldName: 'acknowledgementStatus'}];
      }
      initSearch.sort = [{fieldName: 'auditInfo.createdAt', descending: true}];
      return this.notificationSearchService.findNotifications(initSearch);
    }),
    tap(() => {
      this.loadingIndicatorService.endLoading(this);
    }),
    shareReplay(1)
  );

  notifications$ = this._notifications$.pipe(
    map(page => page?.content),
    filter(Boolean),
  );

  notificationsTotalCount$ = this._notifications$.pipe(
    map(page => page?.totalElements),
  );

  get showOnlyUnreadControl() {
    return this.form.get('showOnlyUnread')!;
  }

  get showOnlyUnread() {
    return this.showOnlyUnreadControl.value;
  }

  getHeaderParams = getMessageHeaderParams;
  private reloadNotifications() {
    this._initNotifications$.next(null);
    this.showOnlyUnreadControl.updateValueAndValidity({onlySelf: true});
  }

  markAllAsRead() {
    this.loadingIndicatorService
      .doLoading(
        this.apiInternalNotificationService.internalNotificationAcceptAll(),
        this
      )
      .subscribe(() => {
        this.reloadNotifications();
      });
  }

  markAsRead(event: Event, notification: InternalNotificationDto) {
    event.stopPropagation();

    const {id} = notification;
    this.loadingIndicatorService.doLoading(
      this.apiInternalNotificationService.internalNotificationAccept({body: [id]}),
      this
    ).subscribe(() => {
      this.reloadNotifications();
    });
  }

  deleteNotification(event: Event, notification: InternalNotificationDto) {
    event.stopPropagation();

    const {id} = notification;
    this.loadingIndicatorService.doLoading(this.apiInternalNotificationService.internalNotificationSoftDelete({body: [id]}), this).subscribe(() => {
      this.reloadNotifications();
    });
  }

  isRead(row: InternalNotificationDto): boolean {
    if (!row) return false;
    return row.acknowledgementStatus === AcknowledgementStatus.READ;
  }

  getUserAcronym(username: string): string {
    if (!username) return '';
    else return username.split(/\s/).reduce((response, word) => `${response}${word.slice(0, 1)}`, '');
  }

  handleNotificationPageRangeChange(event: PaginatorRange) {
    this.notificationPageRange = event;
  }

  handleNotificationPageChange(event: PageEvent) {
    const {pageIndex} = event;
    this.notificationPageIndex = pageIndex;
    this.reloadNotifications();
  }

  private registerUnreadNotificationCount() {
    this.notificationCountService.notificationCount$.pipe(
      filter(val => val !== null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(count => {
      this.unreadNotificationsCount = count ?? 0;
    });
  }

  getFPs(): void {
    this.codebookService
      .functionalPositions()
      .subscribe(fps => {
        this.functionalPositions = fps.map(namedDtoToOption());
      });
  }

  ngOnInit() {
    this.registerUnreadNotificationCount();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.opened?.currentValue === true) {
      this.reloadNotifications();
      if (!this.codebooksLoaded) {
        this.getFPs();
        this.codebooksLoaded = true;
      }
    }
  }

  showNotificationsOverview() {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.NOTIFICATIONS));
    this.closeSidebar();
  }

  closeSidebar() {
    this.closed.emit();
  }

  onRowClick(row: InternalNotificationDto) {
    this.modalService.openComponentInModal<boolean, InternalNotificationDto>({
      component: NotificationMessageDialogComponent,
      modalOptions: {
        width: 800,
        height: '60vh',
        useCustomHeader: true,
        disableAutoMargin: true
      },
      data: row,
    }).subscribe(result => {
      if (result) {
        this.reloadNotifications();
      }
    });
  }

}
