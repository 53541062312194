/* eslint-disable max-classes-per-file */
import {BehaviorSubject, Observable, take} from 'rxjs';
import {TabItem} from '../essentials/tabs/tabs.component.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {IczTableDataSource} from '../table/table.datasource';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {enumToArray} from '../../core';
import {Option} from '../../model';

export const TAB_VIEW_QUERY_PARAM_NAME = 'view';

export interface TableViewMetadata {
  dataSource: IczTableDataSource<any>;
}

export interface ViewWithTabsViewMetadata<TView extends string> {
  tabView: TView;
}

export interface TableWithTabsViewMetadata<TView extends string> extends ViewWithTabsViewMetadata<TView>, TableViewMetadata {
  tableId: string;
}

@Directive()
export abstract class AbstractTableWithTabsComponent<TView extends string, TViewMetadata extends TableWithTabsViewMetadata<TView>> implements OnInit {

  protected router = inject(Router);
  protected destroyRef = inject(DestroyRef);
  protected activatedRoute = inject(ActivatedRoute);

  abstract tabs$: BehaviorSubject<TabItem<TView>[]>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  protected abstract viewsEnum: object;
  protected abstract defaultView: TView;

  activeViewMetadata: Nullable<TViewMetadata>;

  get tabView(): Nullable<TView> {
    return this.activeViewMetadata?.tabView;
  }

  get activeTab(): Nullable<TabItem<TView>> {
    return this.tabs$.value.find(t => t.id === this.tabView);
  }

  get dataSource(): Nullable<IczTableDataSource<any>> {
    return this.activeViewMetadata?.dataSource;
  }

  get tableId(): Nullable<string> {
    return this.activeViewMetadata?.tableId;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(queryParams => {
      let selectedTabView = queryParams[TAB_VIEW_QUERY_PARAM_NAME] ?? this.defaultView;

      if (!enumToArray(this.viewsEnum).includes(selectedTabView)) {
        selectedTabView = this.defaultView;
      }

      this.activeViewMetadata = this.getViewMetadata(queryParams[TAB_VIEW_QUERY_PARAM_NAME] ?? this.defaultView);
    });
  }

  protected abstract getViewMetadata(tabView: TView): TViewMetadata;

  viewTabChanged(activatedTab: TabItem<TView>) {
    this.changeQueryParam(activatedTab.id, TAB_VIEW_QUERY_PARAM_NAME);
  }

  private changeQueryParam(newValue: Nullable<string>, paramName: string) {
    const queryParams: Params = {
      [paramName]: newValue,
    };

    this.router.navigate(
      [],
      {
        queryParams,
        relativeTo: this.activatedRoute
      }
    );
  }

}

@Directive()
export abstract class AbstractTableWithSelectorComponent<TViewMetadata extends TableViewMetadata = TableViewMetadata> implements OnInit {

  protected router = inject(Router);
  protected destroyRef = inject(DestroyRef);
  protected activatedRoute = inject(ActivatedRoute);

  protected abstract selectorQueryParamName: string;
  protected abstract selectorOptions$: Observable<Option<Nullable<number>>[]>;

  selectorOptions: Option<Nullable<number>>[] = [];

  activeViewMetadata: Nullable<TViewMetadata>;
  activeSelectorValue: Nullable<number>;

  get dataSource(): Nullable<IczTableDataSource<any>> {
    return this.activeViewMetadata?.dataSource;
  }

  ngOnInit() {
    this.selectorOptions$.pipe(
      take(1),
    ).subscribe(selectorOptions => {
      if (this.selectorOptions.length > 0) {
        this.selectorOptions = [
          ...this.selectorOptions,
          ...selectorOptions,
        ];
      }
      else {
        this.selectorOptions = selectorOptions;
      }

      this.activatedRoute.queryParams.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(queryParams => {
        const parsedSelectorValue = Number(queryParams[this.selectorQueryParamName]);

        let sanitizedSelectorValue = isNaN(parsedSelectorValue) ? queryParams[this.selectorQueryParamName] : parsedSelectorValue;

        if (!this.selectorOptions.find(o => o.value === sanitizedSelectorValue)) {
          sanitizedSelectorValue = null;
        }

        this.activeSelectorValue = sanitizedSelectorValue;

        const viewMetadata = this.getViewMetadata(sanitizedSelectorValue);

        if (viewMetadata) {
          this.activeViewMetadata = viewMetadata;
        }
      });
    });
  }

  // if the method returns null, activeViewMetadata will not change thus table inputs will not get changed
  protected abstract getViewMetadata(selectorValue: Nullable<number>): Nullable<TViewMetadata>;

  selectorValueChanged(value: Nullable<number | number[]>) {
    this.changeQueryParam(isNil(value) ? null : String(value), this.selectorQueryParamName);
  }

  private changeQueryParam(newValue: Nullable<string>, paramName: string) {
    const queryParams: Params = {
      [paramName]: newValue,
    };

    this.router.navigate(
      [],
      {
        queryParams,
        relativeTo: this.activatedRoute
      }
    );
  }

}

@Directive()
export abstract class AbstractViewWithTabsComponent<TView extends string, TViewMetadata extends ViewWithTabsViewMetadata<TView>> implements OnInit {

  protected router = inject(Router);
  protected destroyRef = inject(DestroyRef);
  protected activatedRoute = inject(ActivatedRoute);

  abstract tabs$: BehaviorSubject<TabItem<TView>[]>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  protected abstract viewsEnum: object;
  protected abstract defaultView: TView;

  activeViewMetadata: Nullable<TViewMetadata>;

  get tabView(): Nullable<TView> {
    return this.activeViewMetadata?.tabView;
  }

  get activeTab(): Nullable<TabItem<TView>> {
    return this.tabs$.value.find(t => t.id === this.tabView);
  }

  ngOnInit() {
    this.activatedRoute.queryParams.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(queryParams => {
      let selectedTabView = queryParams[TAB_VIEW_QUERY_PARAM_NAME] ?? this.defaultView;

      if (!enumToArray(this.viewsEnum).includes(selectedTabView)) {
        selectedTabView = this.defaultView;
      }

      const viewMetadata = this.getViewMetadata(
        queryParams[TAB_VIEW_QUERY_PARAM_NAME] ?? this.defaultView,
      );

      if (viewMetadata) {
        this.activeViewMetadata = viewMetadata;
      }
    });
  }

  protected abstract getViewMetadata(tabView: TView): Nullable<TViewMetadata>;

  viewTabChanged(activatedTab: TabItem<TView>) {
    this.changeQueryParam(activatedTab.id, TAB_VIEW_QUERY_PARAM_NAME);
  }

  private changeQueryParam(newValue: Nullable<string>, paramName: string) {
    this.router.navigate(
      [],
      {
        queryParams: {
          [paramName]: newValue,
        },
        relativeTo: this.activatedRoute
      }
    );
  }

}

@Directive()
export abstract class AbstractViewWithTabsAndSelectorComponent<TView extends string, TViewMetadata extends ViewWithTabsViewMetadata<TView>> implements OnInit {

  protected router = inject(Router);
  protected destroyRef = inject(DestroyRef);
  protected activatedRoute = inject(ActivatedRoute);

  abstract tabs$: BehaviorSubject<TabItem<TView>[]>;
  protected abstract selectorQueryParamName: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  protected abstract viewsEnum: object;
  protected abstract defaultView: TView;
  protected abstract selectorOptions$: Observable<Option<Nullable<number>>[]>;

  selectorOptions: Option<Nullable<number>>[] = [];

  activeViewMetadata: Nullable<TViewMetadata>;
  activeSelectorValue: Nullable<number>;

  get tabView(): Nullable<TView> {
    return this.activeViewMetadata?.tabView;
  }

  get activeTab(): Nullable<TabItem<TView>> {
    return this.tabs$.value.find(t => t.id === this.tabView);
  }

  ngOnInit() {
    this.selectorOptions$.pipe(
      take(1),
    ).subscribe(selectorOptions => {
      if (this.selectorOptions.length > 0) {
        this.selectorOptions = [
          ...this.selectorOptions,
          ...selectorOptions,
        ];
      }
      else {
        this.selectorOptions = selectorOptions;
      }

      this.activatedRoute.queryParams.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(queryParams => {
        const parsedSelectorValue = Number(queryParams[this.selectorQueryParamName]);

        let sanitizedSelectorValue = isNaN(parsedSelectorValue) ? queryParams[this.selectorQueryParamName] : parsedSelectorValue;
        let selectedTabView = queryParams[TAB_VIEW_QUERY_PARAM_NAME] ?? this.defaultView;

        if (!enumToArray(this.viewsEnum).includes(selectedTabView)) {
          selectedTabView = this.defaultView;
        }
        if (!this.selectorOptions.find(o => o.value === sanitizedSelectorValue)) {
          sanitizedSelectorValue = null;
        }

        this.activeSelectorValue = sanitizedSelectorValue;

        const viewMetadata = this.getViewMetadata(
          queryParams[TAB_VIEW_QUERY_PARAM_NAME] ?? this.defaultView,
          sanitizedSelectorValue
        );

        if (viewMetadata) {
          this.activeViewMetadata = viewMetadata;
        }
      });
    });
  }

  protected abstract getViewMetadata(tabView: TView, selectorValue: Nullable<number>): Nullable<TViewMetadata>;

  viewTabChanged(activatedTab: TabItem<TView>) {
    this.changeQueryParam(activatedTab.id, TAB_VIEW_QUERY_PARAM_NAME, this.selectorQueryParamName);
  }

  selectorValueChanged(value: Nullable<number | number[]>) {
    this.changeQueryParam(isNil(value) ? null : String(value), this.selectorQueryParamName, TAB_VIEW_QUERY_PARAM_NAME);
  }

  private changeQueryParam(newValue: Nullable<string>, paramName: string, paramToKeep: string) {
    const queryParams: Params = {
      [paramName]: newValue,
    };

    const urlParams = new URLSearchParams(window.location.search);
    const selectorParamValue = urlParams.get(paramToKeep);
    if (selectorParamValue) {
      queryParams[paramToKeep] = selectorParamValue;
    }

    this.router.navigate(
      [],
      {
        queryParams,
        relativeTo: this.activatedRoute
      }
    );
  }

}

@Directive()
export abstract class AbstractTableWithTabsAndSelectorComponent<TView extends string, TViewMetadata extends TableWithTabsViewMetadata<TView>> extends AbstractViewWithTabsAndSelectorComponent<TView, TViewMetadata> {

  get dataSource(): Nullable<IczTableDataSource<any>> {
    return this.activeViewMetadata?.dataSource;
  }

  get tableId(): Nullable<string> {
    return this.activeViewMetadata?.tableId;
  }

}
