<div [waiting]="loadingService.isLoading$(this) | async" class="h-full">
  @if (conversionValidityResponse) {
    <div class="col gap-8 h-full">
      <icz-alert [severity]="clauseStatus.severity" [heading]="clauseStatus.heading" [label]="clauseStatus.label"></icz-alert>
      <icz-alert severity="info" label="Informace z ověření doložky pouze potvrzuje platnost doložky na základě pořadového čísla konverze. Platnost dokumentu a všech zajišťovacích prvků (elektronická pečeť a časové razítko) je nutno ověřit manuálně."></icz-alert>
      @if (conversionClauseWithBinary) {
        <icz-file-contents-viewer
          class="grow"
          [fileContents]="conversionClauseWithBinary.fileContents"
          [fileName]="conversionClauseWithBinary.fileName"
          [mimeType]="conversionClauseWithBinary.mimeType"
          (downloadRequested)="download()"
        ></icz-file-contents-viewer>
      }
    </div>
  }
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="displayClause()"
              primary label="Zobrazit doložku"
  ></icz-button>
  <icz-button leftButtons iczFormSubmit
              testingFeature
              [disabled]="loadingService.isLoading(this)"
              (onAction)="print()"
              label="Tisknout"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
