/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EsslErrorCodeExceptionDto } from '|api/commons';
import { fileTypeCheckBeforeUpdate } from '../fn/file-type/file-type-check-before-update';
import { FileTypeCheckBeforeUpdate$Params } from '../fn/file-type/file-type-check-before-update';
import { fileTypeCreate } from '../fn/file-type/file-type-create';
import { FileTypeCreate$Params } from '../fn/file-type/file-type-create';
import { fileTypeDeleteById } from '../fn/file-type/file-type-delete-by-id';
import { FileTypeDeleteById$Params } from '../fn/file-type/file-type-delete-by-id';
import { FileTypeDto } from '../models/file-type-dto';
import { fileTypeFindAll } from '../fn/file-type/file-type-find-all';
import { FileTypeFindAll$Params } from '../fn/file-type/file-type-find-all';
import { fileTypeFindAllValidInTimeByType } from '../fn/file-type/file-type-find-all-valid-in-time-by-type';
import { FileTypeFindAllValidInTimeByType$Params } from '../fn/file-type/file-type-find-all-valid-in-time-by-type';
import { fileTypeFindById } from '../fn/file-type/file-type-find-by-id';
import { FileTypeFindById$Params } from '../fn/file-type/file-type-find-by-id';
import { fileTypeSearch } from '../fn/file-type/file-type-search';
import { FileTypeSearch$Params } from '../fn/file-type/file-type-search';
import { fileTypeTestCreate } from '../fn/testing-purpose-only/file-type-test-create';
import { FileTypeTestCreate$Params } from '../fn/testing-purpose-only/file-type-test-create';
import { fileTypeUpdate } from '../fn/file-type/file-type-update';
import { FileTypeUpdate$Params } from '../fn/file-type/file-type-update';
import { PageFileTypeDto } from '../models/page-file-type-dto';


/**
 * Controller for File Types
 */
@Injectable({ providedIn: 'root' })
export class ApiFileTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fileTypeFindById()` */
  static readonly FileTypeFindByIdPath = '/codebook/classifier/file-type/{id}';

  /**
   * Find file type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeFindById$Response(params: FileTypeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<FileTypeDto>> {
    return fileTypeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find file type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeFindById(params: FileTypeFindById$Params, context?: HttpContext): Observable<FileTypeDto> {
    return this.fileTypeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileTypeDto>): FileTypeDto => r.body)
    );
  }

  /** Path part for operation `fileTypeUpdate()` */
  static readonly FileTypeUpdatePath = '/codebook/classifier/file-type/{id}';

  /**
   * Update existing file type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeUpdate$Response(params: FileTypeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileTypeDto>>> {
    return fileTypeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing file type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeUpdate(params: FileTypeUpdate$Params, context?: HttpContext): Observable<Array<FileTypeDto>> {
    return this.fileTypeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileTypeDto>>): Array<FileTypeDto> => r.body)
    );
  }

  /** Path part for operation `fileTypeDeleteById()` */
  static readonly FileTypeDeleteByIdPath = '/codebook/classifier/file-type/{id}';

  /**
   * Delete document type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeDeleteById$Response(params: FileTypeDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return fileTypeDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete document type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeDeleteById(params: FileTypeDeleteById$Params, context?: HttpContext): Observable<void> {
    return this.fileTypeDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `fileTypeFindAll()` */
  static readonly FileTypeFindAllPath = '/codebook/classifier/file-type';

  /**
   * Finds all file types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeFindAll$Response(params?: FileTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<FileTypeDto>>> {
    return fileTypeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all file types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeFindAll(params?: FileTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<FileTypeDto>> {
    return this.fileTypeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileTypeDto>>): Array<FileTypeDto> => r.body)
    );
  }

  /** Path part for operation `fileTypeCreate()` */
  static readonly FileTypeCreatePath = '/codebook/classifier/file-type';

  /**
   * Create new file type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeCreate$Response(params: FileTypeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileTypeDto>> {
    return fileTypeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new file type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeCreate(params: FileTypeCreate$Params, context?: HttpContext): Observable<FileTypeDto> {
    return this.fileTypeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileTypeDto>): FileTypeDto => r.body)
    );
  }

  /** Path part for operation `fileTypeTestCreate()` */
  static readonly FileTypeTestCreatePath = '/codebook/classifier/file-type/testCreate';

  /**
   * Create new file type, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeTestCreate$Response(params: FileTypeTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileTypeDto>> {
    return fileTypeTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new file type, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeTestCreate(params: FileTypeTestCreate$Params, context?: HttpContext): Observable<FileTypeDto> {
    return this.fileTypeTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileTypeDto>): FileTypeDto => r.body)
    );
  }

  /** Path part for operation `fileTypeFindAllValidInTimeByType()` */
  static readonly FileTypeFindAllValidInTimeByTypePath = '/codebook/classifier/file-type/{nodeType}/valid-on-date/{date}';

  /**
   * Finds all valid File types at specific dale for specific type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeFindAllValidInTimeByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeFindAllValidInTimeByType$Response(params: FileTypeFindAllValidInTimeByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileTypeDto>>> {
    return fileTypeFindAllValidInTimeByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all valid File types at specific dale for specific type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeFindAllValidInTimeByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeFindAllValidInTimeByType(params: FileTypeFindAllValidInTimeByType$Params, context?: HttpContext): Observable<Array<FileTypeDto>> {
    return this.fileTypeFindAllValidInTimeByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileTypeDto>>): Array<FileTypeDto> => r.body)
    );
  }

  /** Path part for operation `fileTypeSearch()` */
  static readonly FileTypeSearchPath = '/codebook/classifier/file-type/search-api';

  /**
   * Find all values in file type based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeSearch$Response(params?: FileTypeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFileTypeDto>> {
    return fileTypeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in file type based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeSearch(params?: FileTypeSearch$Params|null|undefined, context?: HttpContext): Observable<PageFileTypeDto> {
    return this.fileTypeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFileTypeDto>): PageFileTypeDto => r.body)
    );
  }

  /** Path part for operation `fileTypeCheckBeforeUpdate()` */
  static readonly FileTypeCheckBeforeUpdatePath = '/codebook/classifier/file-type/check-before-update/{id}';

  /**
   * Check file type before update if any of the descandens if out of range for new validTo date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeCheckBeforeUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeCheckBeforeUpdate$Response(params: FileTypeCheckBeforeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslErrorCodeExceptionDto>> {
    return fileTypeCheckBeforeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Check file type before update if any of the descandens if out of range for new validTo date.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeCheckBeforeUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  fileTypeCheckBeforeUpdate(params: FileTypeCheckBeforeUpdate$Params, context?: HttpContext): Observable<EsslErrorCodeExceptionDto> {
    return this.fileTypeCheckBeforeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslErrorCodeExceptionDto>): EsslErrorCodeExceptionDto => r.body)
    );
  }

}
