<div class="row gap-24 pb-4">
  <div class="col grow gap-24">
    <icz-card>
      <div class="row grow px-24"><icz-heading label="Vyžadující pozornost" [level]="'3'"></icz-heading></div>
      <icz-dashboard-gauge-counter [linksTo]="documentTasksRoute" name="Úkolovník"
      [count]="taskCount$ | async" [showWarnColor]="(hasTasksAfterDeadline$ | async)!"></icz-dashboard-gauge-counter>
      <icz-dashboard-gauge-counter [linksTo]="documentsExpiringRoute" name="Blížící se termín"
      [count]="expiringDocumentsCount$ | async"></icz-dashboard-gauge-counter>
      <icz-dashboard-gauge-counter name="Po termínu" [count]="expiredDocumentsCount$ | async" [showWarnColor]="true"></icz-dashboard-gauge-counter>

    </icz-card>
    @if (showFPSubstitutions) {
      <icz-dashboard-substitutions-counter
        class="mb-24"
        [substitutionsCounterMode]="SubstitutionsCounterMode.FP_SUBSTITUTIONS"
      ></icz-dashboard-substitutions-counter>
    }
  </div>
  <div class="col grow gap-24">
    @if (showTasksSection) {
      <icz-dashboard-flow-activities-preview
      ></icz-dashboard-flow-activities-preview>
    }
    @if (showUserSubstitutions) {
      <icz-dashboard-substitutions-counter
        [substitutionsCounterMode]="SubstitutionsCounterMode.USER_SUBSTITUTIONS"
      ></icz-dashboard-substitutions-counter>
    }
  </div>
  <div class="col grow gap-24">
    @if (showActivitiesSection) {
      <icz-card>
        <div class="row grow px-24"><icz-heading label="Aktivity" [level]="'3'"></icz-heading></div>
        <div class="col grow px-24 pb-16 gap-12">
          <icz-button
            (onAction)="goToPage(documentsNewOwnDocument)"
            [noCentered]="true"
            svgIcon="add_new"
            size="large"
            label="Založit vlastní dokument"
          ></icz-button>
          <icz-button
            (onAction)="goToPage(documentsNewReceivedDocument)"
            [noCentered]="true"
            svgIcon="add_new"
            size="large"
            label="Založit doručený dokument"
          ></icz-button>
          <icz-button
            (onAction)="onCreateEmailConsignmentClick()"
            [noCentered]="true"
            svgIcon="add_new"
            size="large"
            label="E-mail mimo podatelnu"
          ></icz-button>
        </div>
      </icz-card>
    }
    @if (showNotificationsSection) {
      <icz-dashboard-unread-notifications-counter
      ></icz-dashboard-unread-notifications-counter>
    }
  </div>
</div>
