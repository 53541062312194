import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {DeliveryTypeDto} from '|api/codebook';
import {OwnConsignmentStatus} from '|api/commons';
import {OwnOfficeDeskConsignmentDto} from '|api/sad';
import {OrganizationalStructureService} from '../../../../../core/services/organizational-structure.service';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {
  GenericOwnConsignment,
  getOutboundConsignmentIdentifier,
  isOfficeDeskConsignment
} from '../../../own-consignment-table/model/own-consignment-model';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';


@Component({
  selector: 'icz-consignment-meta-overview',
  templateUrl: './consignment-meta-overview.component.html',
  styleUrls: ['./consignment-meta-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentMetaOverviewComponent implements OnInit {

  private organizationalStructureService = inject(OrganizationalStructureService);
  private codebookService = inject(CodebookService);

  @Input({required: true}) ownConsignment!: Nullable<GenericOwnConsignment>;

  requiresDeliveryConfirmation = false;

  deliveryTypes: DeliveryTypeDto[] = [];
  functionalPositionOptions$ = this.organizationalStructureService.functionalPositionsOptions();
  statusOptions = enumToOptions('ownConsignmentStatus', OwnConsignmentStatus);

  get ownConsignmentAsOfficeDesk() {
    return this.ownConsignment as OwnOfficeDeskConsignmentDto;
  }

  get isOfficeDeskConsignment() {
    return isOfficeDeskConsignment(this.ownConsignment);
  }

  get outboundConsignmentIdentifier(): Nullable<string> {
    return getOutboundConsignmentIdentifier(this.ownConsignment!);
  }

  ngOnInit() {
    this.codebookService.deliveryTypes().subscribe(delTypes => {
      this.deliveryTypes = delTypes;
      if (!this.ownConsignment) return;
      const delType = this.deliveryTypes.find(dt => dt.id === this.ownConsignment!.deliveryTypeId);
      this.requiresDeliveryConfirmation = delType?.requiresDeliveryConfirmation ?? false;
    });
  }

}
