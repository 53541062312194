<div class="col layout">
  <div>
    <div class="row header">
      <div class="caption grow">
        <ng-content select="[previewHeader]"></ng-content>
      </div>
      @if (!isTableDocPreview) {
        <icz-button class="flex-grow-0" size="small" svgIcon="close" background (onAction)="closed.emit()" background disableRipple></icz-button>
      }
    </div>
  </div>
  <div class="scroll-area">
    <ng-content></ng-content>
  </div>
</div>
