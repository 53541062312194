<icz-card [waiting]="loadingIndicatorService.isLoading$(this) | async" [blockingOverlayOpts]="{spinnerDiameter: 50}">
  <div class="p-16 pb-4">
    @if (label) {
      <div class="card-label">{{ label }}</div>
    }
    @if (subject && subject.attributes) {
      <div>
        <div class="row justify-between">
          <div class="row items-center">
           <icz-subject-header-info [subject]="subject"></icz-subject-header-info>
            @if (subjectOU) {
              <span>{{ subjectOU }}</span>
            }
          </div>
          <div class="row">
            @if (allowSubjectIdentify) {
              <icz-button
                svgIcon="verified_subject"
                size="large"
                [disabled]="!subject.id || isUserNotPermittedForIszrOperations"
                (onAction)="identifySubject()"
              ></icz-button>
            }
            @if (allowSubjectEdit) {
              <icz-button
                svgIcon="edit"
                size="large"
                (onAction)="editSubject()"
              ></icz-button>
            }
            @if (allowSubjectUnlink) {
              <icz-button
                size="large"
                label="Zpět k hledání"
                (onAction)="subjectRemoved.emit()"
              ></icz-button>
            }
          </div>

        </div>
        @if (showAllAttributes) {
          <icz-databox-details [dataBoxes]="dataBoxes" class="my-8"></icz-databox-details>
        }
        @for (attribute of orderedAttributes; track attribute) {
          <div class="my-16">
            @if ($any(subject.attributes[attribute.key])?.value; as attributeValue) {
              <div>
                <div><span class="font-bold">{{attribute.label | translate}}</span></div>
                <div><span>{{ attribute.key === SubjectAttributeType.BIRTH_DATE ? ($any(attributeValue) | localizedDate) : attributeValue }}</span></div>
              </div>
            }
          </div>
        }
        @if (areAddressesReadonly && showAllAttributes) {
          <div class="icz-body-1">
            @for (addressWithType of addresses; track addressWithType) {
              <div class="mb-16">
                <icz-address-details
                  [addressWithType]="addressWithType"
                  [isAddressLabelBold]="true"
                  [subjectClassification]="subject.classification"
                ></icz-address-details>
              </div>
            }
          </div>
        }
        @else {
          @if (!areAddressesReadonly) {
            <div [formGroup]="selectAddressGroup" class="my-24">
              <span>{{'Vyberte, z které adresy byla zásilka odeslána' | translate}}</span>
              <icz-radio-group>
                @for (addressWithType of addressIndexes(); track addressWithType; let index = $index) {
                  <div class="mt-16">
                    @if (index < newAddressIndex) {
                      <icz-radio-button [value]="index" formControlName="sendingAddressIndex">
                        <icz-address-details [addressWithType]="addresses[addressWithType]" [subjectClassification]="subject.classification"></icz-address-details>
                      </icz-radio-button>
                    }
                    @if (index === newAddressIndex) {
                      <icz-radio-button [value]="index" formControlName="sendingAddressIndex"
                        label="Zásilka byla odeslána z jiné adresy">
                        <icz-address-details [addressWithType]="addresses[addressWithType]" [subjectClassification]="subject.classification"></icz-address-details>
                      </icz-radio-button>
                    }
                  </div>
                }
              </icz-radio-group>
            </div>
          }
        }
      </div>
    }
  </div>
</icz-card>

<icz-address [hidden]="areAddressesReadonly || !showNewAdditionalAddressForm" [withAddressValidationButton]="true"
  groupName="."
  [form]="newAddressFormGroup" [addressAttributeType]="SubjectAttributeType.ADDITIONAL_ADDRESS">
</icz-address>
