/* tslint:disable */
/* eslint-disable */

/**
 * Inserted document response mode
 */
export enum InsertedDocumentResponseMode {
  ID = 'ID',
  FULL = 'FULL'
}
