import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {CodebookService} from '../../../core/services/codebook.service';
import {AbstractClassifierSelectorComponent} from '../abstract-classifier-selector.component';
import {FileTypeDto} from '|api/codebook';
import {NodeType} from '|api/commons';
import {namedDtoToOption} from '../../../core/services/data-mapping.utils';

@Component({
  selector: 'icz-file-type-selector',
  templateUrl: './file-type-selector.component.html',
  styleUrls: ['./file-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileTypeSelectorComponent extends AbstractClassifierSelectorComponent<FileTypeDto> {

  private codebookService = inject(CodebookService);

  @Input()
  label = 'Typ spisu';
  @Input()
  fieldDisabled: Nullable<boolean>;

  classifierSource$ = this.codebookService.fileTypes();
  optionMapper = namedDtoToOption(true);
  override prefilteringPredicate = (e: FileTypeDto) => e.nodeType === NodeType.LEAF;

}
