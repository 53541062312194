/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentDto } from '../models/document-dto';
import { documentDuplicateCopyDocument } from '../fn/document-duplicate/document-duplicate-copy-document';
import { DocumentDuplicateCopyDocument$Params } from '../fn/document-duplicate/document-duplicate-copy-document';
import { documentDuplicatePrepareDuplicatedDocument } from '../fn/document-duplicate/document-duplicate-prepare-duplicated-document';
import { DocumentDuplicatePrepareDuplicatedDocument$Params } from '../fn/document-duplicate/document-duplicate-prepare-duplicated-document';


/**
 * API for management of documents duplicates.
 */
@Injectable({ providedIn: 'root' })
export class ApiDocumentDuplicateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentDuplicateCopyDocument()` */
  static readonly DocumentDuplicateCopyDocumentPath = '/document/document-duplicate/create-document-copy';

  /**
   * Creates a new copy of the document and its components.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDuplicateCopyDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDuplicateCopyDocument$Response(params: DocumentDuplicateCopyDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentDto>>> {
    return documentDuplicateCopyDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new copy of the document and its components.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDuplicateCopyDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentDuplicateCopyDocument(params: DocumentDuplicateCopyDocument$Params, context?: HttpContext): Observable<Array<DocumentDto>> {
    return this.documentDuplicateCopyDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentDto>>): Array<DocumentDto> => r.body)
    );
  }

  /** Path part for operation `documentDuplicatePrepareDuplicatedDocument()` */
  static readonly DocumentDuplicatePrepareDuplicatedDocumentPath = '/document/document-duplicate/{id}/prepare-duplicated-document';

  /**
   * Prepare duplication of document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentDuplicatePrepareDuplicatedDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDuplicatePrepareDuplicatedDocument$Response(params: DocumentDuplicatePrepareDuplicatedDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDto>> {
    return documentDuplicatePrepareDuplicatedDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Prepare duplication of document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentDuplicatePrepareDuplicatedDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  documentDuplicatePrepareDuplicatedDocument(params: DocumentDuplicatePrepareDuplicatedDocument$Params, context?: HttpContext): Observable<DocumentDto> {
    return this.documentDuplicatePrepareDuplicatedDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDto>): DocumentDto => r.body)
    );
  }

}
