import {inject, Injectable} from '@angular/core';
import {
  ConsignmentToastType,
  SConsignmentErrorToastData,
  SConsignmentToastData
} from '../../../components/shared-business-components/consignment-dialog/consignments-toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';


@Injectable({
  providedIn: 'root'
})
export class OfficeDeskNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.OFFICE_DESK_POST_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          ConsignmentToastType.OFFICE_DESK_POST_SUCCESS,
          parsedBody.parameters,
          SConsignmentToastData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.OFFICE_DESK_POST_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          ConsignmentToastType.OFFICE_DESK_POST_ERROR,
          parsedBody.parameters,
          SConsignmentErrorToastData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.OFFICE_DESK_UNPOST_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          ConsignmentToastType.OFFICE_DESK_UNPOST_SUCCESS,
          parsedBody.parameters,
          SConsignmentToastData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.OFFICE_DESK_UNPOST_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          ConsignmentToastType.OFFICE_DESK_UNPOST_ERROR,
          parsedBody.parameters,
          SConsignmentErrorToastData,
        );
      }
    );
  }

}
