import {Observable} from 'rxjs';
import {SharedFolderDto} from '|api/document';

export interface SharedFolderCreateUpdateDialogData {
  sharedFolder?: Nullable<SharedFolderDto>;
  isCreateMode: boolean;
}

export abstract class AbstractSharedFolderDialogsManagerService {
  abstract openSharedFolderCreateUpdateDialog(dialogData: SharedFolderCreateUpdateDialogData): Observable<Nullable<SharedFolderDto>>;
}
