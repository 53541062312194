/* tslint:disable */
/* eslint-disable */

/**
 * Type of connection security protocol used on node.
 */
export enum EmailConnectionSecurityType {
  NONE = 'NONE',
  SSL_TLS = 'SSL_TLS',
  STARTTLS = 'STARTTLS'
}
