import {Directive, EventEmitter, Input, Output} from '@angular/core';
import {SubjectRecordWithSourceDto} from '../../model/subjects.model';

export enum SubjectsTableAction {
  LINK_SUBJECT = 'LINK_SUBJECT',
  DISPATCH_USING_ISDS = 'DISPATCH_USING_ISDS',
  DISPATCH_USING_PAPER = 'DISPATCH_USING_PAPER',
  DISPATCH_USING_EMAIL = 'DISPATCH_USING_EMAIL',
  DISPATCH_USING_IN_PERSON = 'DISPATCH_USING_IN_PERSON',
  POST_ON_OFFICE_DESK = 'POST_ON_OFFICE_DESK',
  MERGE_SUBJECTS = 'MERGE_SUBJECTS',
}

export interface SubjectsTableActionCommand {
  action: SubjectsTableAction;
  subject: SubjectRecordWithSourceDto;
}

@Directive()
export abstract class AbstractSubjectsTableActionsComponent {
  @Input({required: true})
  subject!: SubjectRecordWithSourceDto;
  @Output()
  actionExecuted = new EventEmitter<SubjectsTableActionCommand>();

  readonly SubjectsTableAction = SubjectsTableAction;

  actionButtonClicked(action: SubjectsTableAction, subject: SubjectRecordWithSourceDto) {
    this.actionExecuted.emit({action, subject});
  }
}
