<icz-subject-multiple-values-attribute class="my-24" [boxed]="boxed" [isReadonly]="readonly"
                                       [subjectAttributeType]="SubjectAttributeType.EMAIL"
                                       [form]="form"
></icz-subject-multiple-values-attribute>

<icz-subject-multiple-values-attribute class="my-24" [boxed]="boxed" [isReadonly]="readonly"
                                       [subjectAttributeType]="SubjectAttributeType.PHONE_FAX"
                                       [form]="form"
></icz-subject-multiple-values-attribute>

<icz-section label="Další kontakty" [showExpansionHint]="!boxed" [boxed]="boxed">
  <div [formGroup]="form">
    <div class="row">
      <icz-subject-single-attribute [form]="form!"
                                    [subjectAttributeType]="SubjectAttributeType.NOTE"
                                    class="grow">
      </icz-subject-single-attribute>
    </div>
  </div>
</icz-section>
