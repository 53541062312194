import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { UserInitialsColor } from './user-initials.model';

@Component({
  selector: 'icz-user-initials',
  templateUrl: './user-initials.component.html',
  styleUrls: ['./user-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInitialsComponent {
  @Input({required: true})
  initials: Nullable<string> = '';
  @Input()
  isSystem = false;
  @Input()
  color = UserInitialsColor.BLUE;

  readonly UserInitialsColor = UserInitialsColor;

  get initialsIcon(): Nullable<string> {
    if (this.initials) return null;
    else {
      return this.isSystem ? 'system_user' : 'user_blue';
    }
  }

  get backgroundColor(): string {
    if (this.isSystem) {
      return '#e2e7eb';
    }
    else {
      switch (this.color) {
        case UserInitialsColor.BLUE:
          return '#d4e0fc';
        case UserInitialsColor.GREEN:
          return '#cde9ce';
        case UserInitialsColor.PURPLE:
          return '#d9cef8';
      }
    }
  }
}
