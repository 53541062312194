/* tslint:disable */
/* eslint-disable */

/**
 * Value type defined in property definition
 */
export enum PropertyValueType {
  STRING = 'STRING',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  TIME = 'TIME',
  DATETIME = 'DATETIME',
  INTEGER = 'INTEGER',
  DECIMAL = 'DECIMAL',
  LIST = 'LIST',
  STRUCTURE = 'STRUCTURE',
  MEMORY_SIZE = 'MEMORY_SIZE'
}
