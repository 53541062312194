import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {StorageUnitDto} from '|api/document';
import {EsslObject} from '../../model/entity.model';
import {isDocumentObject} from '../../shared-document.utils';

export class StorageUnitToolbarValidators {
  static isStorageUnitBlocked(): OperationValidator<Nullable<StorageUnitDto>> {
    return (storageUnit: Nullable<StorageUnitDto>) => {
      return (storageUnit && storageUnit.contentBlocked) ? {validationMessage: 'Ukládací jednotka je zablokována.' } : null;
    };
  }

  static isStorageUnitNotBlocked(): OperationValidator<Nullable<StorageUnitDto>> {
    return (storageUnit: Nullable<StorageUnitDto>) => {
      return (storageUnit && !storageUnit.contentBlocked) ? {validationMessage: 'Ukládací jednotka není zablokována.' } : null;
    };
  }

  static isSeparateDocumentStoringUnavailable(storeDocumentsSeparatelyUntil: Date): OperationValidator<Nullable<EsslObject>> {
    return (storableObject: Nullable<EsslObject>) => {
      return isDocumentObject(storableObject) && new Date() > storeDocumentsSeparatelyUntil ? {validationMessage: 'Samostatné ukládání dokumentů není povoleno.'} : null;
    };
  }
}
