import {inject, Injectable} from '@angular/core';
import {DocumentToastService, DocumentToastType, SDocumentTemplateData} from './document-toast.service';
import {FileToastService, FileToastType, SFileTemplateData} from './file-toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {
  InternalNotificationBulkDocumentActionMessageCode,
  InternalNotificationBulkFileActionMessageCode,
  InternalNotificationMessageCode
} from './internal-notification.enum';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';

@Injectable({
  providedIn: 'root'
})
export class SettlementNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          DocumentToastType.DOCUMENT_SETTLED,
          parsedBody.parameters,
          SDocumentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.DOCUMENT_SETTLEMENT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          DocumentToastType.DOCUMENT_SETTLE_ERROR,
          parsedBody.parameters,
          SDocumentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_WITHDRAW_SETTLE_DOCUMENT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_SUCCESS,
          parsedBody.parameters,
          SDocumentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_WITHDRAW_SETTLE_DOCUMENT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_ERROR,
          parsedBody.parameters,
          SDocumentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FILE_SETTLEMENT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          FileToastType.FILE_SETTLED,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FILE_SETTLEMENT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          FileToastType.FILE_SETTLE_ERROR,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkFileActionMessageCode.BULK_WITHDRAW_SETTLE_FILE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          FileToastType.FILE_WITHDRAW_SETTLEMENT,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkFileActionMessageCode.BULK_WITHDRAW_SETTLE_FILE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkFileActionMessageCode.BULK_CLOSE_FILE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          FileToastType.FILE_CLOSED,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkFileActionMessageCode.BULK_CLOSE_FILE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          FileToastType.FILE_CLOSE_ERROR,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkFileActionMessageCode.BULK_REOPEN_FILE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          FileToastType.FILE_REOPENED_SUCCESS,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkFileActionMessageCode.BULK_REOPEN_FILE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          FileToastType.FILE_REOPENED_ERROR,
          parsedBody.parameters,
          SFileTemplateData,
        );
      }
    );

  }
}
