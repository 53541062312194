import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {NavigationEnd, Params, Router} from '@angular/router';
import {merge} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ApiInternalNotificationService} from '|api/notification';
import {GlobalLoadingIndicatorService} from '../../essentials/global-loading-indicator.service';
import {NotificationCountService} from '../../notifications/notification-count.service';
import {MessageType, ToastService} from '../../notifications/toast.service';
import {MIN_SEARCH_TERM_LENGTH} from '../../table/table.models';
import {animationDuration, AnimationState, shakeit} from '../../../animations/animations';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {ActiveModuleService, ApplicationModule} from '../../../core/services/active-module.service';
import {EnvironmentType} from '../../../core/services/environment.models';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {HistoryService} from '../../../services/history.service';
import {BugReportService} from '../../../core/services/bug-report.service';
import {ENVIRONMENT} from '../../../core/services/environment.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {getUserFullName, getUserInitials} from '../../../model';
import {OrganizationsService} from '../../../services/organizations.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FULLTEXT_SEARCH_TERM_PARAM_NAME} from '../../../services/search-params-serialization.service';
import {OrganizationalStructureService} from '../../../core/services/organizational-structure.service';
import {getSubstitutedFpHolderObs} from '../user-settings-popup/user-settings-popup.component';
import {filterByClass} from '../../../lib/rxjs';


@Component({
  selector: 'icz-main-application-bar',
  templateUrl: './main-application-bar.component.html',
  styleUrls: ['./main-application-bar.component.scss'],
  providers: [ApiInternalNotificationService],
  animations: [shakeit],
})
export class MainApplicationBarComponent implements OnInit {

  protected applicationConfigService = inject(ApplicationConfigService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  protected currentSessionService = inject(CurrentSessionService);
  private organizationService = inject(OrganizationsService);
  private apiInternalNotificationService = inject(ApiInternalNotificationService);
  private notificationCountService = inject(NotificationCountService);
  private router = inject(Router);
  private historyService = inject(HistoryService);
  private bugReportService = inject(BugReportService);
  private toastService = inject(ToastService);
  private destroyRef = inject(DestroyRef);
  protected activeModuleService = inject(ActiveModuleService);
  private orgStructureService = inject(OrganizationalStructureService);
  protected environment = inject(ENVIRONMENT);

  @Input()
  notificationsSidebarOpen = false;
  @Output()
  notificationsSidebarOpenChange = new EventEmitter<boolean>();

  private unreadNotificationsCount = 0;
  infoOpen = false;
  settingsOpen = false;
  userSettingsOpen = false;
  state = AnimationState.SHAKESTART;
  currentUser$ = this.currentSessionService.currentUser$.pipe(
    filter(Boolean),
  );

  readonly getUserInitials = getUserInitials;
  readonly getUserFullName = getUserFullName;

  userInitials$ = this.currentUser$.pipe(
    map(user => getUserInitials(user!)),
  );
  userFullName$ = this.currentUser$.pipe(
    map(user => getUserFullName(user!)),
  );
  organizationName$ = this.currentSessionService.currentOrganization$.pipe(
    filter(Boolean),
    map(organization => organization!.name),
  );
  functionalPositionName$ = this.currentSessionService.currentUserFunctionalPosition$.pipe(
    filter(Boolean),
    map(fp => fp!.name),
  );

  functionalPositionSubstituted$ = this.currentSessionService.currentUserFunctionalPosition$.pipe(
    filter(Boolean),
    map(fp => fp.substituted),
  );

  substitutedFPHolder$ = getSubstitutedFpHolderObs(this.currentSessionService, this.orgStructureService);

  form = new IczFormGroup({
    searchTerm: new IczFormControl<Nullable<string>>(null),
  });
  searchInputExpanded = false;

  currentPage$ = this.historyService.latestBit$.pipe(filter(Boolean));

  get hasUnreadNotifications() {
    return this.unreadNotificationsCount > 0;
  }

  get searchTerm(): Nullable<string> {
    return this.form.get('searchTerm')!.value;
  }

  get ringbellCount(): string {
    return this.hasUnreadNotifications ? this.unreadNotificationsCount.toString(10) : ' ';
  }

  readonly ApplicationModule = ApplicationModule;
  readonly EnvironmentType = EnvironmentType;

  searchInputFocusChanged(focused: boolean) {
    this.searchInputExpanded = focused;
  }

  shakeMe() {
    this.state = AnimationState.SHAKEEND;
    setTimeout(() => (this.state = AnimationState.SHAKESTART), animationDuration);
  }

  doSearch() {
    const searchTerm = this.searchTerm;

    if (searchTerm) {
      if (searchTerm.length >= MIN_SEARCH_TERM_LENGTH) {
        const queryParams: Params = {};
        queryParams[FULLTEXT_SEARCH_TERM_PARAM_NAME] = searchTerm;

        this.router.navigate(
          [
            ApplicationRoute.DOCUMENTS,
            DocumentsRoute.SEARCH
          ],
          {
            queryParams
          }
        );
      }
      else {
        this.toastService.dispatchToast({
          type: MessageType.ERROR,
          data: {
            header: {
              template: 'Pro vyhledávání musíte zadat alespoň dva znaky.',
            }
          }
        });
      }
    }
  }

  ngOnInit() {
    merge(
      this.apiInternalNotificationService.internalNotificationCount().pipe(
        map(countDto => countDto.count),
      ),
      this.notificationCountService.notificationCount$,
    ).pipe(
      filter(val => val !== null),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(count => {
      this.unreadNotificationsCount = count ?? 0;
      if (this.unreadNotificationsCount > 0) this.shakeMe();
    });

    this.router.events.pipe(
      filterByClass(NavigationEnd),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      if (this.router.url.startsWith(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.SEARCH))) {
        this.clearSearchTerm();
      }
    });
  }

  openAdvancedSearch() {
    let navigationExtras;

    if (this.searchTerm) {
      navigationExtras = {
        state: {
          searchTerm: this.searchTerm,
        }
      };
      this.clearSearchTerm();
    }

    this.router.navigateByUrl(createAbsoluteRoute(
        ApplicationRoute.DOCUMENTS,
        DocumentsRoute.ADVANCED_SEARCH
      ),
      navigationExtras
    );
  }

  toggleNotificationsSidebar() {
    this.notificationsSidebarOpen = !this.notificationsSidebarOpen;
    this.notificationsSidebarOpenChange.emit(this.notificationsSidebarOpen);
  }

  generateBugReport() {
    this.globalLoadingService.doLoading(
      this.bugReportService.generateBugReport(),
      'Tvorba chybového hlášení může chvíli trvat'
    ).subscribe();
  }

  private clearSearchTerm() {
    this.form.get('searchTerm')!.reset();
  }

}
