/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { distributorCreate } from '../fn/distributor/distributor-create';
import { DistributorCreate$Params } from '../fn/distributor/distributor-create';
import { DistributorDto } from '../models/distributor-dto';
import { distributorFindAll } from '../fn/distributor/distributor-find-all';
import { DistributorFindAll$Params } from '../fn/distributor/distributor-find-all';
import { distributorFindByDeliveryTypeGroup } from '../fn/distributor/distributor-find-by-delivery-type-group';
import { DistributorFindByDeliveryTypeGroup$Params } from '../fn/distributor/distributor-find-by-delivery-type-group';
import { distributorFindById } from '../fn/distributor/distributor-find-by-id';
import { DistributorFindById$Params } from '../fn/distributor/distributor-find-by-id';
import { distributorSearch } from '../fn/distributor/distributor-search';
import { DistributorSearch$Params } from '../fn/distributor/distributor-search';
import { distributorUpdate } from '../fn/distributor/distributor-update';
import { DistributorUpdate$Params } from '../fn/distributor/distributor-update';
import { PageDistributorDto } from '../models/page-distributor-dto';


/**
 * Distributors controller
 */
@Injectable({ providedIn: 'root' })
export class ApiDistributorService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `distributorFindById()` */
  static readonly DistributorFindByIdPath = '/codebook/distributor/{id}';

  /**
   * Finds the distributor by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributorFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorFindById$Response(params: DistributorFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DistributorDto>> {
    return distributorFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the distributor by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributorFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorFindById(params: DistributorFindById$Params, context?: HttpContext): Observable<DistributorDto> {
    return this.distributorFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DistributorDto>): DistributorDto => r.body)
    );
  }

  /** Path part for operation `distributorUpdate()` */
  static readonly DistributorUpdatePath = '/codebook/distributor/{id}';

  /**
   * Updates an existing distributor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributorUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  distributorUpdate$Response(params: DistributorUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DistributorDto>> {
    return distributorUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing distributor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributorUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  distributorUpdate(params: DistributorUpdate$Params, context?: HttpContext): Observable<DistributorDto> {
    return this.distributorUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DistributorDto>): DistributorDto => r.body)
    );
  }

  /** Path part for operation `distributorFindAll()` */
  static readonly DistributorFindAllPath = '/codebook/distributor';

  /**
   * Returns all the distributors
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributorFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorFindAll$Response(params?: DistributorFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DistributorDto>>> {
    return distributorFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the distributors
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributorFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorFindAll(params?: DistributorFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DistributorDto>> {
    return this.distributorFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DistributorDto>>): Array<DistributorDto> => r.body)
    );
  }

  /** Path part for operation `distributorCreate()` */
  static readonly DistributorCreatePath = '/codebook/distributor';

  /**
   * Creates a new distributor
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributorCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  distributorCreate$Response(params: DistributorCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DistributorDto>> {
    return distributorCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new distributor
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributorCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  distributorCreate(params: DistributorCreate$Params, context?: HttpContext): Observable<DistributorDto> {
    return this.distributorCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DistributorDto>): DistributorDto => r.body)
    );
  }

  /** Path part for operation `distributorSearch()` */
  static readonly DistributorSearchPath = '/codebook/distributor/search-api';

  /**
   * Returns distributors based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributorSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorSearch$Response(params?: DistributorSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDistributorDto>> {
    return distributorSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns distributors based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributorSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorSearch(params?: DistributorSearch$Params|null|undefined, context?: HttpContext): Observable<PageDistributorDto> {
    return this.distributorSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDistributorDto>): PageDistributorDto => r.body)
    );
  }

  /** Path part for operation `distributorFindByDeliveryTypeGroup()` */
  static readonly DistributorFindByDeliveryTypeGroupPath = '/codebook/distributor/findByDeliveryTypeGroup/{deliveryTypeGroup}';

  /**
   * Returns distributors based on delivery type parent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributorFindByDeliveryTypeGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorFindByDeliveryTypeGroup$Response(params: DistributorFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DistributorDto>>> {
    return distributorFindByDeliveryTypeGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns distributors based on delivery type parent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributorFindByDeliveryTypeGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributorFindByDeliveryTypeGroup(params: DistributorFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<Array<DistributorDto>> {
    return this.distributorFindByDeliveryTypeGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DistributorDto>>): Array<DistributorDto> => r.body)
    );
  }

}
