/* tslint:disable */
/* eslint-disable */
export enum OwnConsignmentAuthorizedOperation {
  OWN_CONSIGNMENT_SHOW_PROFILE = 'OWN_CONSIGNMENT_SHOW_PROFILE',
  OWN_CONSIGNMENT_MODIFY_PROFILE = 'OWN_CONSIGNMENT_MODIFY_PROFILE',
  OWN_CONSIGNMENT_SHOW_CONTENT = 'OWN_CONSIGNMENT_SHOW_CONTENT',
  OWN_CONSIGNMENT_MODIFY_CONTENT = 'OWN_CONSIGNMENT_MODIFY_CONTENT',
  OWN_CONSIGNMENT_SHOW_COMPONENT_CONTENT = 'OWN_CONSIGNMENT_SHOW_COMPONENT_CONTENT',
  OWN_CONSIGNMENT_HANDOVER = 'OWN_CONSIGNMENT_HANDOVER',
  OWN_CONSIGNMENT_CANCEL = 'OWN_CONSIGNMENT_CANCEL',
  OWN_CONSIGNMENT_WITHDRAW = 'OWN_CONSIGNMENT_WITHDRAW',
  OWN_CONSIGNMENT_REJECT = 'OWN_CONSIGNMENT_REJECT',
  OWN_CONSIGNMENT_TAKEOVER = 'OWN_CONSIGNMENT_TAKEOVER',
  OWN_CONSIGNMENT_DISTRIBUTE = 'OWN_CONSIGNMENT_DISTRIBUTE',
  OWN_CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION = 'OWN_CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION',
  OWN_CONSIGNMENT_DISPATCH = 'OWN_CONSIGNMENT_DISPATCH',
  OWN_CONSIGNMENT_MARK_AS_DISPATCHED = 'OWN_CONSIGNMENT_MARK_AS_DISPATCHED',
  OWN_CONSIGNMENT_CONFIRM_DELIVERY = 'OWN_CONSIGNMENT_CONFIRM_DELIVERY',
  OWN_CONSIGNMENT_DELETE = 'OWN_CONSIGNMENT_DELETE',
  OWN_CONSIGNMENT_PRINT_ENVELOPE = 'OWN_CONSIGNMENT_PRINT_ENVELOPE',
  OWN_CONSIGNMENT_PRINT_LABEL = 'OWN_CONSIGNMENT_PRINT_LABEL',
  OWN_OFFICE_DESK_MANUAL_POST = 'OWN_OFFICE_DESK_MANUAL_POST'
}
