@if (isCollectionPaginable) {
  <div class="button-panel">
    <icz-button svgIcon="first_page" class="mr-4" [disabled]="!paginator.hasPreviousPage()" (onAction)="paginator.firstPage()"></icz-button>
    <icz-button svgIcon="chevron_left" class="mr-4" [disabled]="!paginator.hasPreviousPage()" (onAction)="paginator.previousPage()"></icz-button>
    @for (availablePage of availablePages; track availablePage) {
      <icz-button class="mr-4" [class.active-page]="currentPageNumber === availablePage" [label]="'' + availablePage" (onAction)="setPage(availablePage)"></icz-button>
    }
    <icz-button svgIcon="chevron_right" [disabled]="!paginator.hasNextPage()" (onAction)="paginator.nextPage()"></icz-button>
  </div>
}
<mat-paginator class="paginator" [hidePageSize]="hidePageSize"></mat-paginator>

@if (selectedRowsCount > 0) {
  <icz-label
    primary class="selection-label"
    [label]="selectedItemsCountLabel | translate | interpolate:{count: this.selectedRowsCount}"
  ></icz-label>
}

<div class="grow"></div>

<icz-popover
  [isOpen]="isRowCountSelectorOpen"
  (onClose)="isRowCountSelectorOpen = false"
  [overlayOrigin]="rowCountSelectorOverlayOrigin"
  >
  @if (isRowCountSelectorOpen) {
    <icz-options-list
      [options]="rowCountOptions"
      [selectedOptions]="selectedRowCount"
      (selectionChanged)="changeRowCount($event)"
    ></icz-options-list>
  }
</icz-popover>

<icz-button
  [label]="('Počet řádků' | translate) + ': ' + (this.pageRowCount || 'Auto')"
  (onAction)="toggleRowCountSelector()"
  cdkOverlayOrigin
  #rowCountSelectorOverlayOrigin="cdkOverlayOrigin"
></icz-button>
