import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {AuthorizedEntityType} from '|api/core';
import {InterpolationContext} from '../../../lib/model';
import {ValidationResultWithTooltip} from '../../../services/bulk-operation-validation.service';
import {injectModalData, injectModalRef} from '../../../lib/modals';

export interface ValidationInfo {
  esslObjectIcon: string;
  esslObjectLabel: string;
  validationErrors: ValidationResultWithTooltip[];
  entityId: number;
  authorizedEntityType: Nullable<AuthorizedEntityType>,
}

export interface BulkOperationValidationDialogData {
  dialogWarningLabel: string;
  dialogWarningLabelContext: Nullable<InterpolationContext>;
  validationResultToDisplay: ValidationInfo[];
  correctObjectsList: any[];
}

@Component({
  selector: 'icz-bulk-operation-validation-dialog',
  templateUrl: './bulk-operation-validation-dialog.component.html',
  styleUrls: ['./bulk-operation-validation-dialog.component.scss'],
})
export class BulkOperationValidationDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<any[]>>();
  private cd = inject(ChangeDetectorRef);
  protected modalData = injectModalData<BulkOperationValidationDialogData>();

  validationResultToDisplay: ValidationInfo[] = [];
  correctObjectsList: any[] = [];

  isLoaded = false;

  ngOnInit(): void {
    this.correctObjectsList = this.modalData.correctObjectsList ?? [];
    this.validationResultToDisplay = this.modalData.validationResultToDisplay ?? [];
    this.isLoaded = true;
    this.cd.detectChanges();
  }

  close() {
    this.modalRef.close([]);
  }

  submit() {
    this.modalRef.close(this.correctObjectsList);
  }

}
