<div iczForm
  class="grow"
  [formGroup]="form">
  <ng-content select="[noSubjectInfoBox]"></ng-content>

  <!--  Identification by unequivocal keys -->
  @if (wrapInSections) {
    <icz-section label="Rychlá identifikace subjektu" [showExpansionHint]="true" [ngClass]="{'col': !singleColumnDisplay}" [expanded]="expanded"
      >
      <ng-container *ngTemplateOutlet="identifyByIdentifiers"></ng-container>
    </icz-section>
  }
  @else {
  <div class="row gap-48">
    <div [ngClass]="{'col': !singleColumnDisplay}" class="grow">
      <icz-subject-identifier-input-form-fragment
        [form]="form"
        (enterPressed)="emitSearchIfFormNonEmpty()"
      ></icz-subject-identifier-input-form-fragment>
    </div>
    @if (!singleColumnDisplay) {
      <div class="col grow"></div>
    }
  </div>
}

<ng-template #identifyByIdentifiers>
  <div class="row gap-48">
    <div [ngClass]="{'col': !singleColumnDisplay}" class="grow">
      <icz-subject-identifier-input-form-fragment
        [form]="form"
        (enterPressed)="emitSearchIfFormNonEmpty()"
      ></icz-subject-identifier-input-form-fragment>
    </div>
    @if (!singleColumnDisplay) {
      <div class="col grow"></div>
    }
  </div>
</ng-template>

<!-- Identification by complex keys -->
@if (wrapInSections) {
  <icz-section label="Podrobná identifikace subjektu" [showExpansionHint]="true" [ngClass]="{'col': !singleColumnDisplay}"
    [expanded]="expanded">
    <ng-container *ngTemplateOutlet="identifyByComplexKeys"></ng-container>
  </icz-section>
}
@else {
@if (showSearchAppendix) {
  <ng-container sectionHeaderAppendix>
    <icz-subject-search-appendix [searchCriteria]="searchCriteriaValue" class="mb-8"></icz-subject-search-appendix>
  </ng-container>
}
<div [ngClass]="{'row': !singleColumnDisplay}" class="grow gap-48">
  <div [ngClass]="{'col': !singleColumnDisplay}" class="grow">
    <div class="row">
      <icz-form-field class="grow" [formControlName]="SubjectAttributeType.FIRST_NAME" label="Jméno" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
      <icz-form-field class="grow" [formControlName]="SubjectAttributeType.SURNAME" label="Příjmení" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
    </div>
    <div class="row">
      <icz-date-picker [formControlName]="SubjectAttributeType.BIRTH_DATE" [selectableDates]="birthDateValidator"
        (keydown)="onInputFieldKeydown($event)" class="grow"  label="Datum narození"
      ></icz-date-picker>
    </div>
    <div class="row">
      <icz-form-field class="grow" [formControlName]="SubjectAttributeType.BUSINESS_NAME" label="Obchodní název" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
    </div>
    <div class="row">
      <icz-form-field class="grow" [formControlName]="SubjectAttributeType.CID" label="IČO" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
    </div>
    <div class="row">
      <icz-form-field class="grow" [formControlName]="SearchOnlyAttribute.EMAIL" label="Email" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
    </div>
  </div>
  <div [ngClass]="{'col': !singleColumnDisplay}" class="grow">
    <icz-address [form]="addressForm" [isSearchMode]="true" groupName="." [disableAllValidators]="true" label="Adresa"></icz-address>
  </div>
</div>
}

<ng-template #identifyByComplexKeys>
  @if (showSearchAppendix) {
    <ng-container sectionHeaderAppendix>
      <icz-subject-search-appendix [searchCriteria]="searchCriteriaValue" class="mb-8"></icz-subject-search-appendix>
    </ng-container>
  }

  <div [ngClass]="{'row': !singleColumnDisplay}" class="grow gap-48">
    <div [ngClass]="{'col': !singleColumnDisplay}" class="grow">
      <div class="row">
        <icz-form-field class="grow" [formControlName]="SubjectAttributeType.FIRST_NAME" label="Jméno" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
        <icz-form-field class="grow" [formControlName]="SubjectAttributeType.SURNAME" label="Příjmení" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
      </div>
      <div class="row">
        <icz-date-picker [formControlName]="SubjectAttributeType.BIRTH_DATE" [selectableDates]="birthDateValidator"
          (keydown)="onInputFieldKeydown($event)"
        class="grow"  label="Datum narození"></icz-date-picker>
      </div>
      <div class="row">
        <icz-form-field class="grow" [formControlName]="SubjectAttributeType.BUSINESS_NAME" label="Obchodní název" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
      </div>
      <div class="row">
        <icz-form-field class="grow" [formControlName]="SubjectAttributeType.CID" label="IČO" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
      </div>
      <div class="row">
        <icz-form-field class="grow" [formControlName]="SearchOnlyAttribute.EMAIL" label="Email" (keydown)="onInputFieldKeydown($event)"></icz-form-field>
      </div>
    </div>

    <div [ngClass]="{'col': !singleColumnDisplay}" class="grow">
      <icz-address [form]="addressForm" [isSearchMode]="true" groupName="." [disableAllValidators]="true" label="Adresa"></icz-address>
    </div>
  </div>
</ng-template>

</div>
