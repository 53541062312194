<div class="icz-workspace p-0 w-full">
  <div class="row mx-auto gap-64">
    <div class="col">
      <img src="assets/images/ufo.svg" [alt]="'Stránka nenalezena' | translate">
    </div>
    <div class="col">
      <icz-heading label="Stránka nenalezena"></icz-heading>
      <p class="m-0">{{ 'fe.ui.pageNotFoundText' | translate }}</p>
      <icz-button
        class="inline-block mt-32"
        primary
        [routerLink]="ApplicationRoute.ROOT"
        label="Přejít na úvodní stránku">
      </icz-button>
    </div>
  </div>
</div>
