<div class="h-full gap-24 col">
  <!-- Visible, when there are no digitalComponents to sign -->
  @if ((isThereDigitalComponentToSign$ | async) === false) {
    <icz-alert severity="error" [heading]="noDigitalComponentToSignAlertText"></icz-alert>
  }

  <!-- Visible when the parent document has some kind of problem (those problems are always hard-errors) -->
  @if (parentDocumentError$ | async; as parentDocumentError) {
    <icz-alert
      severity="error"
      [heading]="parentDocumentError.errorCode | interpolateBackendError:parentDocumentError.errorParameters"
    ></icz-alert>
  }

  <!-- Visible, when BULK operation, atleast one ERROR and other digitalComponents can be signed -->
  @if ((isThereDigitalComponentToSign$ | async) && isBulkOperation) {
    @if (hasInfos$ | async) {
      <icz-alert
        [textContext]="alertTranslateContextForWarning$ | async"
        severity="warning"
        heading="fe.ui.alert.heading.SOME_DIGITAL_COMPONENTS_NOT_SIGNABLE_VISUAL"
      ></icz-alert>
    }
    @if (hasErrors$ | async) {
      <icz-alert
        [textContext]="alertTranslateContextForError$ | async"
        severity="warning"
        heading="fe.ui.alert.heading.SOME_DIGITAL_COMPONENTS_NOT_VALID"
      ></icz-alert>
    }
  }

  <!-- Visible, when there is atleast one info -->
  @if (hasInfos$ | async) {
    <div class="row">
      <icz-label class="font-bold" label="Komponenty, které nelze podepsat vizuálně"></icz-label>
    </div>
    <div class="overflow-y-auto digital-component-list">
      @for (digitalComponent of signableWithoutVisualSignatureOption$ | async; track digitalComponent.id) {
        <div class="items-start row">
          <icz-icon svgIcon="warning_color"> </icz-icon>
          <div class="col">
            <icz-label class="mb-4 font-bold underline" [label]="digitalComponent.fileName"></icz-label>
            <icz-label [label]="digitalComponent.infoCode | interpolateBackendError:digitalComponent.infoParameters"></icz-label>
          </div>
        </div>
      }
    </div>
  }

  <!-- Visible, when there is atleast one error -->
  @if (hasErrors$ | async) {
    <div class="row">
      <icz-label class="font-bold" label="Komponenty, které nelze podepsat vůbec"></icz-label>
    </div>
    <div class="overflow-y-auto digital-component-list">
      @for (digitalComponent of notSignableDigitalComponents$ | async; track digitalComponent.id) {
        <div class="items-start mb-16 row">
          <icz-alert severity="error" [heading]="digitalComponent.fileName!" [label]="digitalComponent.errorCode!" [textContext]="digitalComponent.errorParameters" alertMode="compact"></icz-alert>
        </div>
      }
    </div>
  }

  <!-- Visible, when FE sending request to BE if digitalComponents are VALID -->
  @if (loadingService.isLoading(this)) {
    <div class="items-center justify-center h-full col">
      <icz-spinner [diameter]="50"></icz-spinner>
      <div class="row">
        <icz-label class="font-bold" label="Kontroluje se správnost komponent"></icz-label>
      </div>
    </div>
  }
</div>

<icz-form-buttons>
  <!-- Visible only if there is atleast one digitalComponent to SIGN -->
  @if (canContinueToSign$ | async) {
    <icz-button
      leftButtons
      (onAction)="continueToSigning()"
      primary
      label="Pokračovat k podepsání"
    ></icz-button>
  }
  <icz-button rightButtons (onAction)="closeDialog()" label="Storno"></icz-button>
</icz-form-buttons>
