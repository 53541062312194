import {DigitalComponentOrigin, EsslComponentRelation} from '|api/commons';
import {DigitalComponentCompleteDto, DigitalComponentReferenceCreateDto} from '|api/component';
import {merge} from 'rxjs';
import {EsslAnalogComponentTypeAsFields} from '../../analog-component-create-form/analog-component-create-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {DestroyRef} from '@angular/core';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {PaperComponentDto} from '|api/sad';

export enum ConversionClauseVerificationStatus {
  VALID = 'VALID',
  INVALID_AUTHORIZATION = 'INVALID_AUTHORIZATION',
  INVALID_DATA = 'INVALID_DATA',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
}

export interface ConversionClauseVerification {
  esslComponent: EsslComponentDto,
}

export interface UploadedDigitalComponent {
  digitalComponentVersionId: number;
  description: string;
  isFinal: boolean;
  originType: DigitalComponentOrigin,
  relationType: EsslComponentRelation,
}

export enum EsslDigitalComponentTypeAsFields {
  DIGITAL = 'digitalComponents',
}

export enum DigitalComponentUploadDialogType {
  NEW_DIGITAL_COMPONENT = 'NEW_DIGITAL_COMPONENT',
  NEW_DIGITAL_COMPONENT_WITH_SIGNATURES_CHECK = 'NEW_DIGITAL_COMPONENT_WITH_SIGNATURES_CHECK',
  NEW_DIGITAL_VERSION= 'NEW_DIGITAL_VERSION',
  ADD_DIGITAL_RENDITION_TO_PAPER = 'ADD_DIGITAL_RENDITION_TO_PAPER',
  CHECK_IN = 'CHECK_IN,'
}

export interface DigitalComponentUploadDialogData {
  type: DigitalComponentUploadDialogType,
  data: Nullable<DigitalComponentCompleteDto>
}

export interface DigitalComponentUploadDialogResult {
  metadata: DigitalComponentCompleteDto[] | DigitalComponentReferenceCreateDto[],
}

export enum ConversionAnalogToDigitalType {
  AUTHORIZED_CONVERSION = 'AUTHORIZED_CONVERSION',
  VERIFIED_COPY = 'VERIFIED_COPY',
}

export interface ConversionAnalogToDigitalModalData {
  conversionType: ConversionAnalogToDigitalType,
  paperComponent: PaperComponentDto,
}

export function initTotalComponentsCountFormListeners(destroyRef: DestroyRef, form: IczFormGroup, esslComponentCountFormCtrl: IczFormControl) {
  merge(
    form.get(EsslAnalogComponentTypeAsFields.PHYSICAL)!.valueChanges,
    form.get(EsslAnalogComponentTypeAsFields.MEDIUM)!.valueChanges,
    form.get(EsslAnalogComponentTypeAsFields.PAPER)!.valueChanges,
    form.get(EsslDigitalComponentTypeAsFields.DIGITAL)!.valueChanges,
  ).pipe(takeUntilDestroyed(destroyRef)).subscribe( _ => {
    const esslComponentsCount =
      (form.get(EsslDigitalComponentTypeAsFields.DIGITAL) as IczFormArray).length +
      ((form.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormGroup).get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray).length +
      ((form.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormGroup).get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray).length +
      ((form.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormGroup).get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray).length;
    esslComponentCountFormCtrl.setValue(esslComponentsCount);
  });
}
