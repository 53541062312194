import {of} from 'rxjs';
import {filterAndSortArray} from '../../table/filter.utils';
import {IczTableDataSource} from '../../table/table.datasource';
import {dataToPage} from '../../../lib/rxjs';

type TableDataFactory = () => any[];

export class InMemorySearchDatasource extends IczTableDataSource<any> {
  constructor(protected tableDataFactory: TableDataFactory) {
    super(searchParams => {
      const {page, size, filter, sort, fulltextSearchTerm} = searchParams;

      // the sorting process should not sort the original
      // array so imma create shallow copy
      return of(filterAndSortArray([...this.tableDataFactory()], filter, fulltextSearchTerm, sort)).pipe(
        dataToPage<any>(page, size)
      );
    });
  }

  setDataFactory(factory: TableDataFactory) {
    this.tableDataFactory = factory;
    this.reload(true);
  }
}
