/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ReceivedSingleTelefaxConsignmentCreateDto } from '../../models/received-single-telefax-consignment-create-dto';
import { ReceivedTelefaxConsignmentDto } from '../../models/received-telefax-consignment-dto';

export interface ReceivedPaperConsignmentProcessTelefaxReceivedOfficer$Params {
      body: ReceivedSingleTelefaxConsignmentCreateDto
}

export function receivedPaperConsignmentProcessTelefaxReceivedOfficer(http: HttpClient, rootUrl: string, params: ReceivedPaperConsignmentProcessTelefaxReceivedOfficer$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedTelefaxConsignmentDto>> {
  const rb = new RequestBuilder(rootUrl, receivedPaperConsignmentProcessTelefaxReceivedOfficer.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ReceivedTelefaxConsignmentDto>;
    })
  );
}

receivedPaperConsignmentProcessTelefaxReceivedOfficer.PATH = '/sad/received-paper-consignment/telefax/receive-officer';