/* tslint:disable */
/* eslint-disable */

/**
 * Visible signature position
 */
export enum VisibleSignaturePosition {
  XY = 'XY',
  TOP_LEFT = 'TOP_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}
