import {ButtonDisablerFn} from '../../../essentials/button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {RegistryOfficeTransferValidators} from './registry-office-transfer.validators';
import {TransferableObject} from './transferable-object-toolbar-buttons.service';
import {EsslObject} from '../../model/entity.model';
import {RegistryOfficeTransferIncidentDto} from '|api/document';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';

export class RegistryOfficeTransferDisablers {
  static isEntityAnalogOrHybrid(selection: Nullable<TransferableObject[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isEntityAnalogOrHybrid());
    };
  }

  static isEntityInRegistryOffice(selection: Nullable<TransferableObject[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isEntityInRegistryOffice());
    };
  }

  static isEntityInIncorrectState(selection: Nullable<TransferableObject[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isEntityInIncorrectState());
    };
  }

  static isEntityIncomplete(selection: Nullable<TransferableObject[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isEntityIncomplete());
    };
  }

  static isEntityIncorrectType(selection: Nullable<TransferableObject[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isEntityIncorrectType());
    };
  }

  static isSeparateDocumentTransferringUnavailable(selection: Nullable<EsslObject[]>, storeDocumentsSeparatelyUntil: Date): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isSeparateDocumentTransferringUnavailable(storeDocumentsSeparatelyUntil));
    };
  }

  static isIncidentAlreadyResolved(selection: Nullable<RegistryOfficeTransferIncidentDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isIncidentAlreadyResolved());
    };
  }

  static isTransferActivityReturnedFromDesa(selection: Nullable<SearchRecordSourceRegistryOfficeTransferActivityDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, RegistryOfficeTransferValidators.isTransferActivityReturnedFromDesa());
    };
  }
}
