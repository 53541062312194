import {DataBoxDto, EmpowermentDto, EsslMoneyDto} from '|api/commons';
import {IczFormControl, IczFormGroup, TextLength} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {AddressCompleteDto} from '../../model/addresses.model';
import {formatAsLocalIsoDate, getTodayMidnight} from '../../../../lib/utils';

export function getOwnPaperOrPersonalConsignmentDialogForm() {
  return new IczFormGroup({
    deliveryServiceCombinationId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    codAmount: new IczFormControl<Nullable<EsslMoneyDto>>(null),
    consigneeAdditionalName: new IczFormControl<Nullable<string>>(null),
    consigneeAddress: new IczFormControl<Nullable<AddressCompleteDto>>(null, [IczValidators.required()]),
    contactPerson: new IczFormControl<Nullable<string>>(null),
    declaredValue: new IczFormControl<Nullable<EsslMoneyDto>>(null),
    salutation: new IczFormControl<Nullable<string>>(null),
    nonPaperComponentsType: new IczFormControl<Nullable<string>>(null),
    consignorFileRefNumber: new IczFormControl<Nullable<string>>(null),
    consignorRefNumber: new IczFormControl<Nullable<string>>(null),
    note: new IczFormControl<Nullable<string>>(null),
    envelopeTemplateId: new IczFormControl<Nullable<number>>(null),
    consignmentPostingNumber: new IczFormGroup({ // ConsignmentPostingNumberDto
      code: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
      prefix: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
      suffix: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    }),
    weight: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(0)]),
    payoutAmount: new IczFormControl<Nullable<EsslMoneyDto>>(null, []),
    customText: new IczFormGroup({}),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export function getOwnEmailConsignmentDialogForm() {
  return new IczFormGroup({
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    consigneeEmail: new IczFormControl<Nullable<string>>(null, [IczValidators.required(), IczValidators.email()]),
    subject: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    body: new IczFormControl<Nullable<string>>(null, [IczValidators.required()], undefined, TextLength.LONG),
    note: new IczFormControl<Nullable<string>>(null),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export function getOwnDataboxConsignmentDialogForm() {
  return new IczFormGroup({
    deliveryServiceCombinationId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    subject: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    consigneeDataBox: new IczFormControl<Nullable<DataBoxDto>>(null, [IczValidators.required()]),
    consigneeOrganizationalUnit: new IczFormControl<Nullable<string>>(null),
    consigneeOrganizationalUnitCode: new IczFormControl<Nullable<string>>(null, [IczValidators.isInteger()]),
    consignorFileRefNumber: new IczFormControl<Nullable<string>>(null),
    consignorRefNumber: new IczFormControl<Nullable<string>>(null),
    toBeDeliveredTo: new IczFormControl<Nullable<string>>(null),
    empowerment: new IczFormControl<Nullable<EmpowermentDto>>(null),
    note: new IczFormControl<Nullable<string>>(null),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export function getOwnOfficeDeskConsignmentDialogForm() {
  return new IczFormGroup({
    deliveryServiceCombinationId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    consignee: new IczFormControl<Nullable<any>>(null),
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    originatorName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    originatorAddress: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    publicPosting: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    label: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    toPostFrom: new IczFormControl<Nullable<string>>(formatAsLocalIsoDate(getTodayMidnight()), [IczValidators.required()]),
    postingDuration: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(0)]),
    toPostTo: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    refNumber: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    officeDeskRegionId: new IczFormControl<Nullable<number>>(null),
    officeDeskCategoryId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    note: new IczFormControl<Nullable<string>>(null),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export function getOwnInternalConsignmentDialogForm() {
  return new IczFormGroup({
    deliveryServiceCombinationId: new IczFormControl<Nullable<number>>(null, []),
    consigneeOrganizationalUnitId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, []),
    filingOfficeSheetNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    consigneeAdditionalName: new IczFormControl<Nullable<string>>(null, []),
    consigneeAddress: new IczFormControl<Nullable<AddressCompleteDto>>({value: null, disabled: true}, []),
    contactPerson: new IczFormControl<Nullable<string>>(null),
    salutation: new IczFormControl<Nullable<string>>(null),
    nonPaperComponentsType: new IczFormControl<Nullable<string>>(null),
    consignorFileRefNumber: new IczFormControl<Nullable<string>>(null),
    consignorRefNumber: new IczFormControl<Nullable<string>>(null),
    envelopeTemplateId: new IczFormControl<Nullable<number>>(null),
    empowerment: new IczFormControl<Nullable<EmpowermentDto>>({value: null, disabled: true}, []),
    note: new IczFormControl<Nullable<string>>(null),
    customText: new IczFormGroup({}),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export function getOwnFaxConsignmentDialogForm() {
  return new IczFormGroup({
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    consigneeAdditionalName: new IczFormControl<Nullable<string>>(null),
    consigneeAddress: new IczFormControl<Nullable<AddressCompleteDto>>(null, [IczValidators.required()]),
    nonPaperComponentsType: new IczFormControl<Nullable<string>>(null),
    customText: new IczFormGroup({}),
    note: new IczFormControl<Nullable<string>>(null),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export function getOwnPortalConsignmentDialogForm() {
  return new IczFormGroup({
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    consigneeAdditionalName: new IczFormControl<Nullable<string>>(null),
    consigneeAddress: new IczFormControl<Nullable<AddressCompleteDto>>(null, [IczValidators.required()]),
    nonPaperComponentsType: new IczFormControl<Nullable<string>>(null),
    customText: new IczFormGroup({}),
    note: new IczFormControl<Nullable<string>>(null),
    uid: new IczFormGroup({
      uid: new IczFormControl<Nullable<string>>(null, []),
    }),
  });
}

export type OwnPaperOrPersonalConsignmentForm = ReturnType<typeof getOwnPaperOrPersonalConsignmentDialogForm>;
export type OwnEmailConsignmentForm = ReturnType<typeof getOwnEmailConsignmentDialogForm>;
export type OwnDataboxConsignmentForm = ReturnType<typeof getOwnDataboxConsignmentDialogForm>;
export type OwnOfficeDeskConsignmentForm = ReturnType<typeof getOwnOfficeDeskConsignmentDialogForm>;
export type OwnInternalConsignmentForm = ReturnType<typeof getOwnInternalConsignmentDialogForm>;
export type OwnFaxConsignmentForm = ReturnType<typeof getOwnFaxConsignmentDialogForm>;
export type OwnPortalConsignmentForm = ReturnType<typeof getOwnPortalConsignmentDialogForm>;

export type OwnConsignmentSpecificFieldsForm = OwnPaperOrPersonalConsignmentForm | OwnEmailConsignmentForm | OwnDataboxConsignmentForm | OwnOfficeDeskConsignmentForm | OwnInternalConsignmentForm | OwnFaxConsignmentForm | OwnPortalConsignmentForm;
