/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OrganizationFromFileCreateDto } from '../../models/organization-from-file-create-dto';

export interface OrganizationParseFromFile$Params {
      body?: {
'file': Blob;
}|null|undefined
}

export function organizationParseFromFile(http: HttpClient, rootUrl: string, params?: OrganizationParseFromFile$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OrganizationFromFileCreateDto>> {
  const rb = new RequestBuilder(rootUrl, organizationParseFromFile.PATH, 'put');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OrganizationFromFileCreateDto>;
    })
  );
}

organizationParseFromFile.PATH = '/organization/parse-from-file';