@if (!hideTabset) {
  <ng-scrollbar track="horizontal" position="invertX" visibility="hover" (updated)="customScrollbarUpdated()">
    <div class="tabs-container">
      <ng-content select="[beforeContent]"></ng-content>
      @for (tab of currentlyVisibleTabs$ | async; track tab) {
        <icz-tab-item
          [tab]="tab"
          [isActive]="(activeTab$ | async)?.id === tab?.id"
          [attr.data-icztabid]="tab?.id"
          (onClick)="navigate(tab)"
        ></icz-tab-item>
      }
      @if ((hiddenTabsInMoreSelect$ | async)?.length) {
        <icz-tab-item
          [tab]="moreTab"
          (click)="openMoreTabs()"
          cdkOverlayOrigin
          #moreTabs="cdkOverlayOrigin"
          >
          <icz-label label="Více"></icz-label>
          <icz-popover (onClose)="closeMoreTabs()" [isOpen]="(moreTabsOpened$ | async)!" [overlayOrigin]="moreTabs">
            @for (tab of hiddenTabsInMoreSelect$ | async; track tab) {
              <div>
                <icz-tab-item
                  [tab]="tab" class="tab-with-hover"
                  [isActive]="(activeTab$ | async)?.id === tab?.id"
                  (onClick)="navigate(tab, true)"
                ></icz-tab-item>
              </div>
            }
          </icz-popover>
        </icz-tab-item>
      }
      <ng-content select="[extraContent]"></ng-content>
    </div>
  </ng-scrollbar>
}
@if (hasStaticTabs && (activeTab$ | async)) {
  <div class="h-full overflow-x-hidden overflow-y-auto">
    <ng-template [ngTemplateOutlet]="activeTabTemplate!" [ngTemplateOutletContext]="{tab: activeTab$ | async}"></ng-template>
  </div>
}
