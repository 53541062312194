import {FlatTreeControl} from '@angular/cdk/tree';
import {DestroyRef, inject, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Observable, Subject} from 'rxjs';
import {map, shareReplay, take} from 'rxjs/operators';
import {ApiSharedFolderService, SharedFolderDto} from '|api/document';
import {
  getTreeNodeLevel,
  isTreeNodeExpandable,
  TableTreeviewDataSource,
  TreeNode
} from '../components/table/table-treeview/table-treeview.datasource';


@Injectable()
export class SharedFolderViewService {

  private destroyRef = inject(DestroyRef);
  private apiSharedFolderService = inject(ApiSharedFolderService);

  accessibleFolders$!: Observable<SharedFolderDto[]>;
  viewReloaded$ = new Subject<void>();

  treeControl = new FlatTreeControl<TreeNode>(getTreeNodeLevel, isTreeNodeExpandable);
  treeDataSource = new TableTreeviewDataSource<SharedFolderDto>(this.treeControl);

  currentFolder: Nullable<SharedFolderDto>;

  constructor() {
    this.treeDataSource.expand$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(node => this.treeControl.expand(node!));
  }

  load() {
    this.setAccessibleFoldersDataSource();

    return this.accessibleFolders$.pipe(
      take(1),
    ).pipe(
      map(accessibleFolders => {
        const rootFolders = accessibleFolders.filter(f => isNil(f.parentId));

        if (rootFolders.length) {
          const firstRootFolder = rootFolders[0];

          this.treeDataSource.setData(
            this.accessibleFolders$,
            firstRootFolder.id,
          );

          return firstRootFolder;
        }
        else {
          this.treeDataSource.setData(
            this.accessibleFolders$,
            0
          );

          return null;
        }
      }),
    );
  }

  reload(rootId: number) {
    this.setAccessibleFoldersDataSource();

    this.treeDataSource.reset();
    this.treeDataSource.setData(
      this.accessibleFolders$,
      rootId
    );
    this.viewReloaded$.next();
  }

  reloadAndExpandRoot() {
    this.reload(0);
  }

  reloadAndExpandCurrentlyExpandedNode() {
    const expandedNode = this.treeControl.dataNodes.find(n => this.treeControl.isExpanded(n));

    if (expandedNode) {
      this.reload(expandedNode.id);
    }
    else {
      this.reloadAndExpandRoot();
    }
  }

  reset() {
    this.treeDataSource.reset();
  }

  private setAccessibleFoldersDataSource() {
    this.accessibleFolders$ = this.apiSharedFolderService.sharedFolderGetAllAccessibleFolders().pipe(
      shareReplay(),
    );
  }

}
