import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {TabItem} from '../tabs.component.model';

@Component({
  selector: 'icz-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
})
export class TabItemComponent<T extends string | number = string | number> {
  @Input({required: true}) tab: Nullable<TabItem<T>>;

  @HostBinding('class.active')
  @Input()
  isActive = false;

  @Output() onClick = new EventEmitter();

  get isLoading() {
    return this.tab?.showTabValidity && this.tab?.loading;
  }

  get isValid() {
    return this.tab?.showTabValidity && !this.isLoading && this.tab?.valid;
  }

  get isInvalid() {
    return this.tab?.showTabValidity && !this.isLoading && !this.tab?.valid;
  }

  @HostBinding('class.disabled')
  get isDisabled() {
    return Boolean(this.tab?.disabled);
  }

  @HostListener('click', ['$event'])
  handleTabClick() {
    this.onClick.emit();
  }
}
