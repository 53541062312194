/* tslint:disable */
/* eslint-disable */

/**
 * Issd Import File Handover Type.
 */
export enum IssdImportFileHandoverType {
  DIRECT = 'DIRECT',
  STORAGE = 'STORAGE',
  LINK = 'LINK'
}
