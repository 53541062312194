/* tslint:disable */
/* eslint-disable */
export enum FilePermission {
  SHOW_IN_LIST = 'SHOW_IN_LIST',
  PROFILE_READ = 'PROFILE_READ',
  PROFILE_WRITE = 'PROFILE_WRITE',
  DOCUMENT_SHOW_LIST = 'DOCUMENT_SHOW_LIST',
  DOCUMENT_ADD = 'DOCUMENT_ADD',
  DOCUMENT_ADDITIONAL_ADD = 'DOCUMENT_ADDITIONAL_ADD',
  DOCUMENT_REMOVE = 'DOCUMENT_REMOVE',
  BULK_DOCUMENT_PROFILE_ONLY_LEVEL = 'BULK_DOCUMENT_PROFILE_ONLY_LEVEL',
  BULK_DOCUMENT_READ_ONLY_LEVEL = 'BULK_DOCUMENT_READ_ONLY_LEVEL',
  BULK_DOCUMENT_READ_AND_WRITE_LEVEL = 'BULK_DOCUMENT_READ_AND_WRITE_LEVEL',
  BULK_DOCUMENT_FULL_ACCESS_LEVEL = 'BULK_DOCUMENT_FULL_ACCESS_LEVEL',
  RELATED_OBJECT_SHOW_LIST = 'RELATED_OBJECT_SHOW_LIST',
  RELATED_OBJECT_ADD = 'RELATED_OBJECT_ADD',
  RELATED_OBJECT_REMOVE = 'RELATED_OBJECT_REMOVE',
  SUBJECT_SHOW_LIST = 'SUBJECT_SHOW_LIST',
  SUBJECT_ADD = 'SUBJECT_ADD',
  SUBJECT_READ = 'SUBJECT_READ',
  SUBJECT_WRITE = 'SUBJECT_WRITE',
  SUBJECT_REMOVE = 'SUBJECT_REMOVE',
  PERMISSION_SET_READ = 'PERMISSION_SET_READ',
  PERMISSION_SET_WRITE = 'PERMISSION_SET_WRITE',
  HISTORY_SHOW_LIST = 'HISTORY_SHOW_LIST',
  ACTIVITY_SHOW_LIST = 'ACTIVITY_SHOW_LIST',
  ACTIVITY_MANAGE = 'ACTIVITY_MANAGE',
  TASK_SETTLE_OR_REJECT = 'TASK_SETTLE_OR_REJECT',
  SETTLE_OR_UNSETTLE = 'SETTLE_OR_UNSETTLE',
  OPEN_CLOSED = 'OPEN_CLOSED',
  PASS_ON_TO_EXTERNAL_ORGANIZATION = 'PASS_ON_TO_EXTERNAL_ORGANIZATION',
  CANCEL_OR_RESTORE = 'CANCEL_OR_RESTORE'
}
