/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConversionRegisterDto } from '../../models/conversion-register-dto';
import { ConversionRegisterSearchRequest } from '../../models/conversion-register-search-request';

export interface ConversionRecordSearch$Params {
      body: ConversionRegisterSearchRequest
}

export function conversionRecordSearch(http: HttpClient, rootUrl: string, params: ConversionRecordSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversionRegisterDto>>> {
  const rb = new RequestBuilder(rootUrl, conversionRecordSearch.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<ConversionRegisterDto>>;
    })
  );
}

conversionRecordSearch.PATH = '/component/conversion-register/search';