/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExportType } from '../../models/export-type';

export interface ClassificationSchemeExportXml$Params {

/**
 * Id of classification scheme to be found
 */
  id: number;

/**
 * Export type
 */
  type: ExportType;
}

export function classificationSchemeExportXml(http: HttpClient, rootUrl: string, params: ClassificationSchemeExportXml$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
  const rb = new RequestBuilder(rootUrl, classificationSchemeExportXml.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
    rb.path('type', params.type, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<string>>;
    })
  );
}

classificationSchemeExportXml.PATH = '/codebook/classifier/classification-scheme/export/{type}/{id}';