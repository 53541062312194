/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DigitalComponentVersionDto } from '../models/digital-component-version-dto';
import { DigitalComponentVersionValidateResponse } from '../models/digital-component-version-validate-response';
import { DocumentCheckBeforeExtendResponse } from '../models/document-check-before-extend-response';
import { DocumentCheckBeforeMarkResponse } from '../models/document-check-before-mark-response';
import { DocumentCheckBeforeSignResponse } from '../models/document-check-before-sign-response';
import { DocumentDigestResponse } from '../models/document-digest-response';
import { DocumentExtendResponse } from '../models/document-extend-response';
import { DocumentMarkResponse } from '../models/document-mark-response';
import { DocumentSignResponse } from '../models/document-sign-response';
import { EsslErrorCodeExceptionDto } from '|api/commons';
import { signatureCheckBeforeExtend } from '../fn/signature/signature-check-before-extend';
import { signatureCheckBeforeExtend_1 } from '../fn/signature/signature-check-before-extend-1';
import { SignatureCheckBeforeExtend_1$Params } from '../fn/signature/signature-check-before-extend-1';
import { SignatureCheckBeforeExtend$Params } from '../fn/signature/signature-check-before-extend';
import { signatureCheckBeforeMark } from '../fn/signature/signature-check-before-mark';
import { signatureCheckBeforeMark_1 } from '../fn/signature/signature-check-before-mark-1';
import { SignatureCheckBeforeMark_1$Params } from '../fn/signature/signature-check-before-mark-1';
import { SignatureCheckBeforeMark$Params } from '../fn/signature/signature-check-before-mark';
import { signatureCheckBeforeSign } from '../fn/signature/signature-check-before-sign';
import { signatureCheckBeforeSign_1 } from '../fn/signature/signature-check-before-sign-1';
import { SignatureCheckBeforeSign_1$Params } from '../fn/signature/signature-check-before-sign-1';
import { SignatureCheckBeforeSign$Params } from '../fn/signature/signature-check-before-sign';
import { signatureDigestDocument } from '../fn/signature/signature-digest-document';
import { SignatureDigestDocument$Params } from '../fn/signature/signature-digest-document';
import { signatureExtend } from '../fn/signature/signature-extend';
import { SignatureExtend$Params } from '../fn/signature/signature-extend';
import { signatureExtendDocument } from '../fn/signature/signature-extend-document';
import { SignatureExtendDocument$Params } from '../fn/signature/signature-extend-document';
import { signatureMarkDocument } from '../fn/signature/signature-mark-document';
import { SignatureMarkDocument$Params } from '../fn/signature/signature-mark-document';
import { signatureSignaturesDetail } from '../fn/signature/signature-signatures-detail';
import { SignatureSignaturesDetail$Params } from '../fn/signature/signature-signatures-detail';
import { signatureSignDocument } from '../fn/signature/signature-sign-document';
import { SignatureSignDocument$Params } from '../fn/signature/signature-sign-document';
import { signatureValidate } from '../fn/signature/signature-validate';
import { SignatureValidate$Params } from '../fn/signature/signature-validate';
import { SignatureValidationDataDto } from '../models/signature-validation-data-dto';


/**
 * API for management of signatures
 */
@Injectable({ providedIn: 'root' })
export class ApiSignatureService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `signatureSignDocument()` */
  static readonly SignatureSignDocumentPath = '/document/signature/{documentId}/sign';

  /**
   * Sign of document digital components versions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureSignDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureSignDocument$Response(params: SignatureSignDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSignResponse>> {
    return signatureSignDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Sign of document digital components versions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureSignDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureSignDocument(params: SignatureSignDocument$Params, context?: HttpContext): Observable<DocumentSignResponse> {
    return this.signatureSignDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSignResponse>): DocumentSignResponse => r.body)
    );
  }

  /** Path part for operation `signatureMarkDocument()` */
  static readonly SignatureMarkDocumentPath = '/document/signature/{documentId}/mark';

  /**
   * Mark of documents digital components versions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureMarkDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureMarkDocument$Response(params: SignatureMarkDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMarkResponse>> {
    return signatureMarkDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Mark of documents digital components versions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureMarkDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureMarkDocument(params: SignatureMarkDocument$Params, context?: HttpContext): Observable<DocumentMarkResponse> {
    return this.signatureMarkDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentMarkResponse>): DocumentMarkResponse => r.body)
    );
  }

  /** Path part for operation `signatureExtendDocument()` */
  static readonly SignatureExtendDocumentPath = '/document/signature/{documentId}/extend';

  /**
   * Extend digital components versions of timestamp.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureExtendDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureExtendDocument$Response(params: SignatureExtendDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentExtendResponse>> {
    return signatureExtendDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Extend digital components versions of timestamp.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureExtendDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureExtendDocument(params: SignatureExtendDocument$Params, context?: HttpContext): Observable<DocumentExtendResponse> {
    return this.signatureExtendDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentExtendResponse>): DocumentExtendResponse => r.body)
    );
  }

  /** Path part for operation `signatureDigestDocument()` */
  static readonly SignatureDigestDocumentPath = '/document/signature/{documentId}/digest';

  /**
   * Digest of documents digital components versions
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureDigestDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureDigestDocument$Response(params: SignatureDigestDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDigestResponse>> {
    return signatureDigestDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Digest of documents digital components versions
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureDigestDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureDigestDocument(params: SignatureDigestDocument$Params, context?: HttpContext): Observable<DocumentDigestResponse> {
    return this.signatureDigestDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentDigestResponse>): DocumentDigestResponse => r.body)
    );
  }

  /** Path part for operation `signatureCheckBeforeSign()` */
  static readonly SignatureCheckBeforeSignPath = '/document/signature/{documentId}/check-before-sign';

  /**
   * Check document digital component versions before sign
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureCheckBeforeSign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureCheckBeforeSign$Response(params: SignatureCheckBeforeSign$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentCheckBeforeSignResponse>> {
    return signatureCheckBeforeSign(this.http, this.rootUrl, params, context);
  }

  /**
   * Check document digital component versions before sign
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureCheckBeforeSign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureCheckBeforeSign(params: SignatureCheckBeforeSign$Params, context?: HttpContext): Observable<DocumentCheckBeforeSignResponse> {
    return this.signatureCheckBeforeSign$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentCheckBeforeSignResponse>): DocumentCheckBeforeSignResponse => r.body)
    );
  }

  /** Path part for operation `signatureCheckBeforeMark()` */
  static readonly SignatureCheckBeforeMarkPath = '/document/signature/{documentId}/check-before-mark';

  /**
   * Check that mark operation is possible for a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureCheckBeforeMark()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureCheckBeforeMark$Response(params: SignatureCheckBeforeMark$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentCheckBeforeMarkResponse>> {
    return signatureCheckBeforeMark(this.http, this.rootUrl, params, context);
  }

  /**
   * Check that mark operation is possible for a document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureCheckBeforeMark$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureCheckBeforeMark(params: SignatureCheckBeforeMark$Params, context?: HttpContext): Observable<DocumentCheckBeforeMarkResponse> {
    return this.signatureCheckBeforeMark$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentCheckBeforeMarkResponse>): DocumentCheckBeforeMarkResponse => r.body)
    );
  }

  /** Path part for operation `signatureCheckBeforeExtend()` */
  static readonly SignatureCheckBeforeExtendPath = '/document/signature/{documentId}/check-before-extend';

  /**
   * Check that extend operation is possible for a document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureCheckBeforeExtend()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureCheckBeforeExtend$Response(params: SignatureCheckBeforeExtend$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentCheckBeforeExtendResponse>> {
    return signatureCheckBeforeExtend(this.http, this.rootUrl, params, context);
  }

  /**
   * Check that extend operation is possible for a document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureCheckBeforeExtend$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureCheckBeforeExtend(params: SignatureCheckBeforeExtend$Params, context?: HttpContext): Observable<DocumentCheckBeforeExtendResponse> {
    return this.signatureCheckBeforeExtend$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentCheckBeforeExtendResponse>): DocumentCheckBeforeExtendResponse => r.body)
    );
  }

  /** Path part for operation `signatureExtend()` */
  static readonly SignatureExtendPath = '/component/signature/{digitalComponentVersionId}/extend';

  /**
   * Extend signature of additional information
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureExtend()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureExtend$Response(params: SignatureExtend$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionDto>> {
    return signatureExtend(this.http, this.rootUrl, params, context);
  }

  /**
   * Extend signature of additional information
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureExtend$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureExtend(params: SignatureExtend$Params, context?: HttpContext): Observable<DigitalComponentVersionDto> {
    return this.signatureExtend$Response(params, context).pipe(
      map((r: StrictHttpResponse<DigitalComponentVersionDto>): DigitalComponentVersionDto => r.body)
    );
  }

  /** Path part for operation `signatureValidate()` */
  static readonly SignatureValidatePath = '/component/signature/validate';

  /**
   * Validation of digital component version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureValidate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureValidate$Response(params: SignatureValidate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DigitalComponentVersionValidateResponse>>> {
    return signatureValidate(this.http, this.rootUrl, params, context);
  }

  /**
   * Validation of digital component version
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureValidate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  signatureValidate(params: SignatureValidate$Params, context?: HttpContext): Observable<Array<DigitalComponentVersionValidateResponse>> {
    return this.signatureValidate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DigitalComponentVersionValidateResponse>>): Array<DigitalComponentVersionValidateResponse> => r.body)
    );
  }

  /** Path part for operation `signatureSignaturesDetail()` */
  static readonly SignatureSignaturesDetailPath = '/component/signature/{digitalComponentVersionId}/signatures-detail';

  /**
   * Retrieve signatures detail of digital component version
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureSignaturesDetail()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureSignaturesDetail$Response(params: SignatureSignaturesDetail$Params, context?: HttpContext): Observable<StrictHttpResponse<SignatureValidationDataDto>> {
    return signatureSignaturesDetail(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve signatures detail of digital component version
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureSignaturesDetail$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureSignaturesDetail(params: SignatureSignaturesDetail$Params, context?: HttpContext): Observable<SignatureValidationDataDto> {
    return this.signatureSignaturesDetail$Response(params, context).pipe(
      map((r: StrictHttpResponse<SignatureValidationDataDto>): SignatureValidationDataDto => r.body)
    );
  }

  /** Path part for operation `signatureCheckBeforeSign_1()` */
  static readonly SignatureCheckBeforeSign_1Path = '/component/signature/{digitalComponentVersionId}/check-before-sign';

  /**
   * Check sign operation for digital component version is possible
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureCheckBeforeSign_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureCheckBeforeSign_1$Response(params: SignatureCheckBeforeSign_1$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslErrorCodeExceptionDto>> {
    return signatureCheckBeforeSign_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Check sign operation for digital component version is possible
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureCheckBeforeSign_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureCheckBeforeSign_1(params: SignatureCheckBeforeSign_1$Params, context?: HttpContext): Observable<EsslErrorCodeExceptionDto> {
    return this.signatureCheckBeforeSign_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslErrorCodeExceptionDto>): EsslErrorCodeExceptionDto => r.body)
    );
  }

  /** Path part for operation `signatureCheckBeforeMark_1()` */
  static readonly SignatureCheckBeforeMark_1Path = '/component/signature/{digitalComponentVersionId}/check-before-mark';

  /**
   * Check mark operation for digital component version is possible
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureCheckBeforeMark_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureCheckBeforeMark_1$Response(params: SignatureCheckBeforeMark_1$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslErrorCodeExceptionDto>> {
    return signatureCheckBeforeMark_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Check mark operation for digital component version is possible
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureCheckBeforeMark_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureCheckBeforeMark_1(params: SignatureCheckBeforeMark_1$Params, context?: HttpContext): Observable<EsslErrorCodeExceptionDto> {
    return this.signatureCheckBeforeMark_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslErrorCodeExceptionDto>): EsslErrorCodeExceptionDto => r.body)
    );
  }

  /** Path part for operation `signatureCheckBeforeExtend_1()` */
  static readonly SignatureCheckBeforeExtend_1Path = '/component/signature/{digitalComponentVersionId}/check-before-extend';

  /**
   * Check extend operation for digital component version is possible
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `signatureCheckBeforeExtend_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureCheckBeforeExtend_1$Response(params: SignatureCheckBeforeExtend_1$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslErrorCodeExceptionDto>> {
    return signatureCheckBeforeExtend_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Check extend operation for digital component version is possible
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `signatureCheckBeforeExtend_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  signatureCheckBeforeExtend_1(params: SignatureCheckBeforeExtend_1$Params, context?: HttpContext): Observable<EsslErrorCodeExceptionDto> {
    return this.signatureCheckBeforeExtend_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslErrorCodeExceptionDto>): EsslErrorCodeExceptionDto => r.body)
    );
  }

}
