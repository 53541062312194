/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EsslEventTypeDto } from '../models/essl-event-type-dto';
import { esslEventTypeFindAll } from '../fn/essl-event-type/essl-event-type-find-all';
import { EsslEventTypeFindAll$Params } from '../fn/essl-event-type/essl-event-type-find-all';


/**
 * Controller for dispatch office objects
 */
@Injectable({ providedIn: 'root' })
export class ApiEsslEventTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `esslEventTypeFindAll()` */
  static readonly EsslEventTypeFindAllPath = '/codebook/essl-event-type/find-all';

  /**
   * Finds all essl event types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `esslEventTypeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  esslEventTypeFindAll$Response(params?: EsslEventTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EsslEventTypeDto>>> {
    return esslEventTypeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all essl event types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `esslEventTypeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  esslEventTypeFindAll(params?: EsslEventTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<EsslEventTypeDto>> {
    return this.esslEventTypeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EsslEventTypeDto>>): Array<EsslEventTypeDto> => r.body)
    );
  }

}
