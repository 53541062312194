import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentsTableColumn} from '../../documents-table.columnsets';


export interface Highlight {
  field: DocumentsTableColumn;
  fragments: HighlightFragment[];
}

export interface HighlightFragment {
  content: string;
  parts: HighlightFragmentPart[];
}

export interface HighlightFragmentPart {
  offset: number;
  length: number;
  hasMaxLength: boolean;
}

export function shouldHighlightEverything(parts: HighlightFragmentPart[]) {
  return parts.some(p => p.hasMaxLength);
}

@Component({
  selector: 'icz-document-relevance-details',
  templateUrl: './document-relevance-details.component.html',
  styleUrls: ['./document-relevance-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentRelevanceDetailsComponent {
  @Input()
  highlights: Highlight[] = [];
  @Input()
  columnNames: Partial<Record<DocumentsTableColumn, string>> = {};

  shouldHighlightEverything = shouldHighlightEverything;
}
