/* tslint:disable */
/* eslint-disable */

/**
 * File reference creation type
 */
export enum FileReferenceCreationType {
  INITIAL_DOCUMENT = 'INITIAL_DOCUMENT',
  GENERATOR = 'GENERATOR',
  CUSTOM = 'CUSTOM'
}
