import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {isNumber} from 'lodash';
import {CmfItemDto} from '|api/codebook';
import {EsslMoneyDto} from '|api/commons';
import {FormField} from '../form-field';
import {IczValidatorFn, ValidationErrorMessage} from '../validators/icz-validators/validator-decorators';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {isNilOrEmptyString} from '../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export class IczMoneyValidators {
  @ValidationErrorMessage('Musí být platný zápis peněžní hodnoty s maximálně dvěma desetinnými místy, Např.: "2,50", "2"')
  static isValidCurrencyObject(): IczValidatorFn {
    return (control: AbstractControl) => {
      const controlValue: EsslMoneyDto = control.value;

      if (isNil(controlValue)) {
        return null;
      }
      else if (typeof(controlValue) !== 'object') {
        return {isValidCurrencyObject: false};
      }

      if (controlValue.currency) {
        if (isNumber(controlValue.number)) {
          const moneyValue = controlValue.number;

          return (
            moneyValue >= 0 &&
            moneyValue < 10000000000 &&
            // moneyValue can have at most two decimals - using *10*10 instead of *100 because
            // *100 sometimes produces tiny floating number errors (8.6 * 100 -> 859.99999... , 8.6 * 10 * 10 -> 860)
            Number.isInteger(moneyValue * 10 * 10)
          ) ? null : {isValidCurrencyObject: false};
        }
        else {
          return {isValidCurrencyObject: false};
        }
      }
      else {
        return {isValidCurrencyObject: false};
      }
    };
  }
}


@Component({
  selector: 'icz-form-money-input',
  templateUrl: './form-money-input.component.html',
  styleUrls: ['./form-money-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormMoneyInputComponent extends FormField<EsslMoneyDto> implements OnInit {

  private applicationConfigService = inject(ApplicationConfigService);
  private codebookService = inject(CodebookService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @Input()
  override set value(newValue: Nullable<EsslMoneyDto>) {
    this._value = newValue;

    if (newValue) {
      this.moneyValue = newValue.number;
      this.setCurrency(newValue.currency);
    }
    else {
      this.setCurrencyToDefault();
    }
  }
  override get value(): Nullable<EsslMoneyDto> {
    return this._value;
  }
  @Input() readonly = false;

  moneyValue: Nullable<number> = null;

  availableCurrencies!: CmfItemDto[];
  currency: Nullable<CmfItemDto> = null;

  ngOnInit(): void {
    this.codebookService.currencies().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(currencies => {
      this.availableCurrencies = currencies;
      this.setCurrencyToDefault();
    });

    setTimeout(() => {
      this.control?.addValidators([IczMoneyValidators.isValidCurrencyObject()]);
    }, 0);
  }

  _valueChanged(moneyValue: Nullable<string | number>) {
    this.moneyValue = isNilOrEmptyString(moneyValue) || Number.isNaN(moneyValue as number) ? null : moneyValue as number;

    const finalValue = isNil(this.moneyValue) ? null : {number: this.moneyValue, currency: this.currency!.alphabeticCode};

    this._value = finalValue;
    this.valueChange.emit(finalValue);
  }

  private setCurrencyToDefault() {
    this.setCurrency(this.applicationConfigService.defaultCurrency);
  }

  private setCurrency(currencyAlphabeticCode: string) {
    if (this.availableCurrencies) {
      this.currency = this.availableCurrencies.find(c => c.alphabeticCode === currencyAlphabeticCode);
      this.cd.detectChanges();

      if (!this.currency) {
        throw new Error(
          `Currency ${currencyAlphabeticCode} does not correspond to any currency in CSU-CMF currency codebook. ` +
          `icz-form-money-input will not work properly.`
        );
      }
    }
  }

}
