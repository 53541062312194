<ng-container [formGroup]="form">
  <icz-radio-group>
    <div class="row gap-64">
      <div class="col gap-8">
        <icz-radio-button [formControlName]="controlName" [value]="PageCorner.TOP_LEFT" label="Levý horní roh"></icz-radio-button>
        <icz-radio-button [formControlName]="controlName" [value]="PageCorner.BOTTOM_LEFT" label="Levý dolní roh"></icz-radio-button>
      </div>
      <div class="col gap-8">
        <icz-radio-button [formControlName]="controlName" [value]="PageCorner.TOP_RIGHT" label="Pravý horní roh"></icz-radio-button>
        <icz-radio-button [formControlName]="controlName" [value]="PageCorner.BOTTOM_RIGHT" label="Pravý dolní roh"></icz-radio-button>
      </div>
    </div>
  </icz-radio-group>
</ng-container>
