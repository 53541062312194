/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ermsIssdGetByPermissions } from '../fn/erms-issd/erms-issd-get-by-permissions';
import { ErmsIssdGetByPermissions$Params } from '../fn/erms-issd/erms-issd-get-by-permissions';
import { ermsIssdHeartbeatEsslIssd } from '../fn/erms-issd/erms-issd-heartbeat-essl-issd';
import { ErmsIssdHeartbeatEsslIssd$Params } from '../fn/erms-issd/erms-issd-heartbeat-essl-issd';
import { ermsIssdHeartbeatIncoming } from '../fn/erms-issd/erms-issd-heartbeat-incoming';
import { ErmsIssdHeartbeatIncoming$Params } from '../fn/erms-issd/erms-issd-heartbeat-incoming';
import { ermsIssdHeartbeatIssdEssl } from '../fn/erms-issd/erms-issd-heartbeat-issd-essl';
import { ErmsIssdHeartbeatIssdEssl$Params } from '../fn/erms-issd/erms-issd-heartbeat-issd-essl';
import { ermsIssdHeartbeatOutgoing } from '../fn/erms-issd/erms-issd-heartbeat-outgoing';
import { ErmsIssdHeartbeatOutgoing$Params } from '../fn/erms-issd/erms-issd-heartbeat-outgoing';
import { ermsIssdPauseIncomingPipeline } from '../fn/erms-issd/erms-issd-pause-incoming-pipeline';
import { ErmsIssdPauseIncomingPipeline$Params } from '../fn/erms-issd/erms-issd-pause-incoming-pipeline';
import { ermsIssdPauseOutgoingPipeline } from '../fn/erms-issd/erms-issd-pause-outgoing-pipeline';
import { ErmsIssdPauseOutgoingPipeline$Params } from '../fn/erms-issd/erms-issd-pause-outgoing-pipeline';
import { ermsIssdResumeIncomingPipeline } from '../fn/erms-issd/erms-issd-resume-incoming-pipeline';
import { ErmsIssdResumeIncomingPipeline$Params } from '../fn/erms-issd/erms-issd-resume-incoming-pipeline';
import { ermsIssdResumeOutgoingPipeline } from '../fn/erms-issd/erms-issd-resume-outgoing-pipeline';
import { ErmsIssdResumeOutgoingPipeline$Params } from '../fn/erms-issd/erms-issd-resume-outgoing-pipeline';
import { ermsIssdSendHeartbeatForEsslIssd } from '../fn/erms-issd/erms-issd-send-heartbeat-for-essl-issd';
import { ErmsIssdSendHeartbeatForEsslIssd$Params } from '../fn/erms-issd/erms-issd-send-heartbeat-for-essl-issd';
import { ermsIssdSendHeartbeatForIssdEssl } from '../fn/erms-issd/erms-issd-send-heartbeat-for-issd-essl';
import { ErmsIssdSendHeartbeatForIssdEssl$Params } from '../fn/erms-issd/erms-issd-send-heartbeat-for-issd-essl';
import { IssdDto } from '../models/issd-dto';


/**
 * API for management of ISSD (Document Management Information System)
 */
@Injectable({ providedIn: 'root' })
export class ApiErmsIssdService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ermsIssdResumeOutgoingPipeline()` */
  static readonly ErmsIssdResumeOutgoingPipelinePath = '/erms-issd/pipeline/outgoing/{pipelineId}/resume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdResumeOutgoingPipeline()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdResumeOutgoingPipeline$Response(params: ErmsIssdResumeOutgoingPipeline$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdResumeOutgoingPipeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdResumeOutgoingPipeline$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdResumeOutgoingPipeline(params: ErmsIssdResumeOutgoingPipeline$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdResumeOutgoingPipeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdPauseOutgoingPipeline()` */
  static readonly ErmsIssdPauseOutgoingPipelinePath = '/erms-issd/pipeline/outgoing/{pipelineId}/pause';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdPauseOutgoingPipeline()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdPauseOutgoingPipeline$Response(params: ErmsIssdPauseOutgoingPipeline$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdPauseOutgoingPipeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdPauseOutgoingPipeline$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdPauseOutgoingPipeline(params: ErmsIssdPauseOutgoingPipeline$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdPauseOutgoingPipeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdHeartbeatOutgoing()` */
  static readonly ErmsIssdHeartbeatOutgoingPath = '/erms-issd/pipeline/outgoing/{pipelineId}/heartbeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdHeartbeatOutgoing()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatOutgoing$Response(params: ErmsIssdHeartbeatOutgoing$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdHeartbeatOutgoing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdHeartbeatOutgoing$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatOutgoing(params: ErmsIssdHeartbeatOutgoing$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdHeartbeatOutgoing$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdHeartbeatIssdEssl()` */
  static readonly ErmsIssdHeartbeatIssdEsslPath = '/erms-issd/pipeline/issd/{issdSource}/essl/{orgCode}/heartbeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdHeartbeatIssdEssl()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatIssdEssl$Response(params: ErmsIssdHeartbeatIssdEssl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdHeartbeatIssdEssl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdHeartbeatIssdEssl$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatIssdEssl(params: ErmsIssdHeartbeatIssdEssl$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdHeartbeatIssdEssl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdResumeIncomingPipeline()` */
  static readonly ErmsIssdResumeIncomingPipelinePath = '/erms-issd/pipeline/incoming/{pipelineId}/resume';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdResumeIncomingPipeline()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdResumeIncomingPipeline$Response(params: ErmsIssdResumeIncomingPipeline$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdResumeIncomingPipeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdResumeIncomingPipeline$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdResumeIncomingPipeline(params: ErmsIssdResumeIncomingPipeline$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdResumeIncomingPipeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdPauseIncomingPipeline()` */
  static readonly ErmsIssdPauseIncomingPipelinePath = '/erms-issd/pipeline/incoming/{pipelineId}/pause';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdPauseIncomingPipeline()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdPauseIncomingPipeline$Response(params: ErmsIssdPauseIncomingPipeline$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdPauseIncomingPipeline(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdPauseIncomingPipeline$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdPauseIncomingPipeline(params: ErmsIssdPauseIncomingPipeline$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdPauseIncomingPipeline$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdHeartbeatIncoming()` */
  static readonly ErmsIssdHeartbeatIncomingPath = '/erms-issd/pipeline/incoming/{pipelineId}/heartbeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdHeartbeatIncoming()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatIncoming$Response(params: ErmsIssdHeartbeatIncoming$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdHeartbeatIncoming(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdHeartbeatIncoming$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatIncoming(params: ErmsIssdHeartbeatIncoming$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdHeartbeatIncoming$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdHeartbeatEsslIssd()` */
  static readonly ErmsIssdHeartbeatEsslIssdPath = '/erms-issd/pipeline/essl/{orgCode}/issd/{issdSource}/heartbeat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdHeartbeatEsslIssd()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatEsslIssd$Response(params: ErmsIssdHeartbeatEsslIssd$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdHeartbeatEsslIssd(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdHeartbeatEsslIssd$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdHeartbeatEsslIssd(params: ErmsIssdHeartbeatEsslIssd$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdHeartbeatEsslIssd$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdSendHeartbeatForIssdEssl()` */
  static readonly ErmsIssdSendHeartbeatForIssdEsslPath = '/erms-issd/heartbeat/issd/{issdSource}/essl/{orgCode}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdSendHeartbeatForIssdEssl()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  ermsIssdSendHeartbeatForIssdEssl$Response(params: ErmsIssdSendHeartbeatForIssdEssl$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdSendHeartbeatForIssdEssl(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdSendHeartbeatForIssdEssl$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  ermsIssdSendHeartbeatForIssdEssl(params: ErmsIssdSendHeartbeatForIssdEssl$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdSendHeartbeatForIssdEssl$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdSendHeartbeatForEsslIssd()` */
  static readonly ErmsIssdSendHeartbeatForEsslIssdPath = '/erms-issd/heartbeat/essl/{orgCode}/issd/{issdSource}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdSendHeartbeatForEsslIssd()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  ermsIssdSendHeartbeatForEsslIssd$Response(params: ErmsIssdSendHeartbeatForEsslIssd$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return ermsIssdSendHeartbeatForEsslIssd(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdSendHeartbeatForEsslIssd$Response()` instead.
   *
   * This method doesn't expect any request body.
   *
   * @deprecated
   */
  ermsIssdSendHeartbeatForEsslIssd(params: ErmsIssdSendHeartbeatForEsslIssd$Params, context?: HttpContext): Observable<void> {
    return this.ermsIssdSendHeartbeatForEsslIssd$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `ermsIssdGetByPermissions()` */
  static readonly ErmsIssdGetByPermissionsPath = '/erms-issd/by-permissions';

  /**
   * Find all Issds enabled for current user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ermsIssdGetByPermissions()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdGetByPermissions$Response(params?: ErmsIssdGetByPermissions$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdDto>>> {
    return ermsIssdGetByPermissions(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Issds enabled for current user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ermsIssdGetByPermissions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ermsIssdGetByPermissions(params?: ErmsIssdGetByPermissions$Params|null|undefined, context?: HttpContext): Observable<Array<IssdDto>> {
    return this.ermsIssdGetByPermissions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdDto>>): Array<IssdDto> => r.body)
    );
  }

}
