/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EmailNotificationRuleDto } from '../models/email-notification-rule-dto';
import { emailNotificationRulesOauthCreate } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-create';
import { EmailNotificationRulesOauthCreate$Params } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-create';
import { emailNotificationRulesOauthDelete } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-delete';
import { EmailNotificationRulesOauthDelete$Params } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-delete';
import { emailNotificationRulesOauthGetById } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-get-by-id';
import { EmailNotificationRulesOauthGetById$Params } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-get-by-id';
import { emailNotificationRulesOauthSearch } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-search';
import { EmailNotificationRulesOauthSearch$Params } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-search';
import { emailNotificationRulesOauthUpdate } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-update';
import { EmailNotificationRulesOauthUpdate$Params } from '../fn/email-notification-rules-oauth/email-notification-rules-oauth-update';
import { PageEmailNotificationRuleDto } from '../models/page-email-notification-rule-dto';


/**
 * API for management of email notification rules.
 */
@Injectable({ providedIn: 'root' })
export class ApiEmailNotificationRulesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `emailNotificationRulesOauthGetById()` */
  static readonly EmailNotificationRulesOauthGetByIdPath = '/oauth/email-notification-rules/{id}';

  /**
   * Returns email notification rule by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthGetById$Response(params: EmailNotificationRulesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationRuleDto>> {
    return emailNotificationRulesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns email notification rule by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthGetById(params: EmailNotificationRulesOauthGetById$Params, context?: HttpContext): Observable<EmailNotificationRuleDto> {
    return this.emailNotificationRulesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNotificationRuleDto>): EmailNotificationRuleDto => r.body)
    );
  }

  /** Path part for operation `emailNotificationRulesOauthUpdate()` */
  static readonly EmailNotificationRulesOauthUpdatePath = '/oauth/email-notification-rules/{id}';

  /**
   * Updates email notification rule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthUpdate$Response(params: EmailNotificationRulesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationRuleDto>> {
    return emailNotificationRulesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates email notification rule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthUpdate(params: EmailNotificationRulesOauthUpdate$Params, context?: HttpContext): Observable<EmailNotificationRuleDto> {
    return this.emailNotificationRulesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNotificationRuleDto>): EmailNotificationRuleDto => r.body)
    );
  }

  /** Path part for operation `emailNotificationRulesOauthDelete()` */
  static readonly EmailNotificationRulesOauthDeletePath = '/oauth/email-notification-rules/{id}';

  /**
   * Deletes email notification rule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthDelete$Response(params: EmailNotificationRulesOauthDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return emailNotificationRulesOauthDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes email notification rule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthDelete(params: EmailNotificationRulesOauthDelete$Params, context?: HttpContext): Observable<void> {
    return this.emailNotificationRulesOauthDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `emailNotificationRulesOauthCreate()` */
  static readonly EmailNotificationRulesOauthCreatePath = '/oauth/email-notification-rules/create-rule';

  /**
   * Creates new email notification rule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthCreate$Response(params: EmailNotificationRulesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNotificationRuleDto>> {
    return emailNotificationRulesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new email notification rule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNotificationRulesOauthCreate(params: EmailNotificationRulesOauthCreate$Params, context?: HttpContext): Observable<EmailNotificationRuleDto> {
    return this.emailNotificationRulesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNotificationRuleDto>): EmailNotificationRuleDto => r.body)
    );
  }

  /** Path part for operation `emailNotificationRulesOauthSearch()` */
  static readonly EmailNotificationRulesOauthSearchPath = '/oauth/email-notification-rules/search-api';

  /**
   * Finds email notification rules based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNotificationRulesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthSearch$Response(params?: EmailNotificationRulesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEmailNotificationRuleDto>> {
    return emailNotificationRulesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds email notification rules based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNotificationRulesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNotificationRulesOauthSearch(params?: EmailNotificationRulesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageEmailNotificationRuleDto> {
    return this.emailNotificationRulesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEmailNotificationRuleDto>): PageEmailNotificationRuleDto => r.body)
    );
  }

}
