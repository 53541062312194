import {Injectable} from '@angular/core';
import {Title} from '@angular/platform-browser';

const TITLE_APP_NAME = 'e-Spis G2';

@Injectable({providedIn: 'root'})
export class IczPageTitleService extends Title {

  override setTitle(newTitle: string) {
    if (newTitle) {
      super.setTitle(`${TITLE_APP_NAME} :: ${newTitle}`);
    }
    else {
      super.setTitle(TITLE_APP_NAME);
    }
  }

}
