@for (item of orderedSearchCriteriaToDisplay; track item[0]) {
  <div class="appendix-item">
    @if (item[0] !== SearchOnlyAttribute.ADDRESS || isAddressPartiallyFilled) {
      <span class="icz-body-strong">{{getUiKeyForSubjectSearchAttribute(item[0]) | translate}}</span>:
      <span class="appendix-item-value">
        @if (item[0] !== SearchOnlyAttribute.ADDRESS) {
          <span>
            {{ item[0] === SubjectAttributeType.BIRTH_DATE ? (item[1] | localizedDate) : item[1]}}
          </span>
        }
        @else {
          <icz-address-details [addressWithType]="addressWithType" [showVerificationStatus]="false"></icz-address-details>
        }
      </span>
      @if ($index < searchCriteriaLength - 1) {
        <span class="mr-4">,</span>
      }
      @else {
        <span class="mr-24"></span>
      }
    }
  </div>
}
