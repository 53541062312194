/* tslint:disable */
/* eslint-disable */

/**
 * Batch direction
 */
export enum ErmsAuditDirection {
  INCOMING = 'INCOMING',
  OUTGOING = 'OUTGOING'
}
