@if (form) {
  <icz-section label="Ukázková data pro zkušební tisk" [formGroup]="form">
    <icz-form-autocomplete
      class="grow"
      label="Typ subjektu"
      formControlName="consigneeSubjectClassification"
      [options]="consigneeSubjectClassificationOptions"
    ></icz-form-autocomplete>
    @if (isTestConsigneeBusiness) {
      <icz-form-field
        formControlName="consigneeSubjectName"
        label="Název subjektu"
      ></icz-form-field>
      <icz-form-field
        formControlName="consigneeAdditionalName"
        label="Doplňující název"
      ></icz-form-field>
      <icz-form-field
        formControlName="contactPerson"
        label="Kontaktní osoby"
      ></icz-form-field>
    }
    @else {
      <div class="row">
        <icz-form-field
          class="grow"
          formControlName="salutation"
          label="Oslovení"
        ></icz-form-field>
        <icz-date-picker
          class="grow"
          formControlName="consigneeBirthDate"
          label="Datum narození"
        ></icz-date-picker>
      </div>
      <div class="row">
        <icz-form-field
          class="grow"
          formControlName="consigneeFirstName"
          label="Jméno"
        ></icz-form-field>
        <icz-form-field
          class="grow"
          formControlName="consigneeSurname"
          label="Příjmení"
        ></icz-form-field>
      </div>
      <div class="row">
        <icz-form-field
          class="grow"
          formControlName="consigneeDegreeBeforeName"
          label="Titul(y) před jménem"
        ></icz-form-field>
        <icz-form-field
          class="grow"
          formControlName="consigneeDegreeAfterName"
          label="Titul(y) za jménem"
        ></icz-form-field>
      </div>
    }
  </icz-section>
  <icz-address
    label="Ukázková adresa"
    [form]="addressForm"
    groupName="."
    [enforceValidators]="true"
    [showSearchBox]="false"
    [showSectionExpansionHint]="false"
  ></icz-address>
}
