<div class="w-full">
  <icz-subject-create-form [mode]="componentMode"
                           [form]="form"
                           [singleAddressForm]="singleAddressForm"
                           (backToSearch)="emitSearch()"
                           (singleAddressResult)="singleAddressChange($event)"
                           (blockingAction)="setBlockingAction($event)"
                           [enforceAddressValidator]="enforceAddressValidator"
                           [waiting]="loadingIndicatorService.isLoading(this)"
  ></icz-subject-create-form>
  @if (useCustomFormButtons) {
    <icz-form-buttons>
      <icz-button iczFormSubmit primary (onAction)="createAndLinkNewSubject()" leftButtons
        [disabled]="!documentId || !form!.valid || submitBlocked"
        label="Založit a přidat subjekt">
      </icz-button>
    </icz-form-buttons>
  }
</div>
