@if (permissionsToAssignForm) {
  <div class="col grow" [formGroup]="permissionsToAssignForm">
    <div class="permission-selection">
      <div class="row-group grow">
        <icz-org-structure-selector
          [form]="permissionsToAssignForm"
          [disableCurrentFunctionalPosition]="disableCurrentFunctionalPosition"
          [disabledOptionValues]="disabledOptionValues"
          (valueChangeWithOriginIds)="valueChangeWithOriginIds($event)"
          controlName="organizationalStructureEntityId"
          class="grow-3"
          [isMultiselect]="false"
          label="Funkční místa nebo organizační jednotky"
        ></icz-org-structure-selector>
        <icz-form-autocomplete
          class="grow-1 align-with-field-label"
          formControlName="permissionType"
          [options]="permissionOptions"
          [clearable]="false"
          placeholder="Zvolte oprávnění"
          [customOptionTemplate]="permissionOptionTemplate"
          [hideDefaultTooltips]="true"
        ></icz-form-autocomplete>
        <icz-button
          class="h-32 align-with-field-label"
          label="Přidat"
          [primary]="true"
          [disabled]="isAddPermissionButtonDisabled"
          (onAction)="addToPermissionsList()"
        ></icz-button>
      </div>
      <icz-validation-errors-list [array]="permissionsListForm" [fieldsValidationErrors]="[]">
      </icz-validation-errors-list>
    </div>
    <div class="row grow header-row mat-header-cell">
      <span class="grow-2 header-row-cell pl-16">{{'Funkční místo nebo organizační jednotka' | translate}}</span>
      <span class="grow-3 header-row-cell">{{'Oprávnění' | translate}}</span>
    </div>
  </div>
}

@for (permissionValue of permissionsListForm?.controls; track permissionValue) {
  <div class="row grow px-16 py-8 permission-definition-row">
    <form [formGroup]="$any(permissionValue)" class="row grow items-center">
      <div class="row grow-2">
        @if (isFM($any(permissionValue))) {
          <icz-icon size="small" svgIcon="funkcni_misto"></icz-icon>
        }
        @if (!isFM($any(permissionValue))) {
          <icz-icon size="small" svgIcon="hierarchy"></icz-icon>
        }
        <span class="icz-body-1">{{getOrgEntityNameValue($any(permissionValue))}}</span>
      </div>
      <div class="row grow-3">
        <icz-form-autocomplete
          formControlName="permissionType"
          [options]="permissionOptions"
          class="pl-12 mt-16" [clearable]="false"
          [customOptionTemplate]="permissionOptionTemplate"
          [hideDefaultTooltips]="true"
        ></icz-form-autocomplete>
        <div class="row ml-auto items-center">
          <icz-button svgIcon="delete" (onAction)="deletePermission($index)" class="h-24"></icz-button>
        </div>
      </div>
    </form>
  </div>
}

<ng-template #permissionOptionTemplate let-context>
  <icz-form-autocomplete-list-text-item
    class="w-full"
    [option]="context.option">
    <icz-icon
      afterItemLabel svgIcon="info" size="small"
      [iczTooltip]="context.option.label"
    ></icz-icon>
  </icz-form-autocomplete-list-text-item>
</ng-template>
