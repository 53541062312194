<icz-button svgIcon="history" size="default" cdkOverlayOrigin #historyBitsBox="cdkOverlayOrigin"
  (onAction)="isOpen = true" background
  data-cy="history-overlay-button"
></icz-button>

<icz-popover
  [isOpen]="isOpen"
  [overlayOrigin]="historyBitsBox"
  (onClose)="isOpen = false">
  @if (isOpen) {
    <div class="col grow history-bits-box">
      <div class="row px-16 py-8">
        <icz-label label="Historie procházení" class="grow"></icz-label>
        <icz-button svgIcon="close" background (onAction)="isOpen = false" size="small" disableRipple></icz-button>
      </div>
      <hr/>
      <cdk-virtual-scroll-viewport class="history-bits-list" itemSize="36">
        @if (!historyBits.length) {
          <icz-label class="no-history-label" label="Žádná historie procházení"></icz-label>
        }
        <div *cdkVirtualFor="let historyBit of historyBits; let i = index" (click)="historyBitClick(historyBit)"
          class="history-bit-item" [class.active-bit]="i === 0" [class.bit-without-icon]="!historyBit.visitedObject">
          @if (historyBit.visitedObject) {
            <icz-icon
              [svgIcon]="getObjectIcon(historyBit.visitedObject.objectClass)"
              size="small"
            ></icz-icon>
          }
          @if (getHistoryBitName(historyBit); as translatedLabel) {
            <icz-label
              class="bit-label"
              [label]="translatedLabel"
              [iczTooltip]="translatedLabel"
            ></icz-label>
          }
          <div class="row bit-actions items-end">
            <icz-favourite-page-selector [smallIcon]="true" [page]="historyBit"></icz-favourite-page-selector>
            <icz-copy-to-clipboard-button
              [text]="historyBit.completeUrl"
              size="small"
              class="copy"
            ></icz-copy-to-clipboard-button>
            <icz-button
              class="reference"
              size="small"
              [iczTooltip]="'Otevřít na nové záložce' | translate"
              background
              svgIcon="new_tab"
              (onAction)="openInNewTab($event, historyBit.completeUrl)"
            ></icz-button>
            <icz-label class="bit-date" [label]="historyBit.date | localizedDatetime:true"></icz-label>
          </div>
        </div>
      </cdk-virtual-scroll-viewport>
    </div>
  }
</icz-popover>
