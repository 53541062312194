import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {SubjectAttributeDataBoxDto, SubjectRecordDto} from '|api/commons';
import {transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto} from '../../model/subjects.model';
import {SubjectAttributeType} from '../../model/subject-attribute-type';


@Component({
  selector: 'icz-subject-detail-row-info',
  templateUrl: './subject-detail-row-info.component.html',
  styleUrls: ['./subject-detail-row-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectDetailRowInfoComponent implements OnInit {

  @Input({
    transform: transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto,
    required: true
  })
  subject!: SubjectRecordDto;
  @Input()
  showAllAttributes = true;

  protected readonly SubjectAttributeType = SubjectAttributeType;

  get dataBoxes(): SubjectAttributeDataBoxDto[] {
    return (this.subject?.attributes[SubjectAttributeType.DATA_BOX]) ?? [];
  }

  get emails(): string {
    return this.subject.attributes.emails?.map(d => d.value)?.join(', ') ?? '';
  }

  orderedAttributes: { key: SubjectAttributeType, label: string }[] = [
    // Natural person attributes
    {key: SubjectAttributeType.BIRTH_DATE, label: 'Datum narození'},
    // Legal person like attributes
    {key: SubjectAttributeType.TAX_ID, label: 'DIČ'},
    {key: SubjectAttributeType.VAT_ID, label: '  Identifikační číslo pro účely DPH'},
    {key: SubjectAttributeType.EXCISE_TAX_ID, label: 'ID Spotřební daně'},
    {key: SubjectAttributeType.LE_ID, label: 'Identifikační údaj právnické osoby'},
    {key: SubjectAttributeType.ART_1_P_3_ID, label: 'Identifikační údaj uvedený v čl.3 odst. 1'},
    {key: SubjectAttributeType.EORI_CODE, label: 'EORI kód'},
  ];

  private addAllAttributes() {
    this.orderedAttributes.splice(1, 0,
      {key: SubjectAttributeType.IDENTITY_CARD_ID, label: 'Občanský průkaz'},
      {key: SubjectAttributeType.DRIVING_LICENCE_ID, label: 'Řidičský průkaz'},
      {key: SubjectAttributeType.PASSPORT_ID, label: 'Cestovní pas'},
      {key: SubjectAttributeType.PHONE_FAX, label: 'Telefon/fax'},
      {key: SubjectAttributeType.EMAIL, label: 'E-mail'},
      {key: SubjectAttributeType.BIRTH_NAME, label: 'Rodné jméno'},
      {key: SubjectAttributeType.BIRTH_SURNAME, label: 'Rodné příjmení'},
      {key: SubjectAttributeType.BIRTH_PLACE, label: 'Místo narození'},
      {key: SubjectAttributeType.DEGREE_BEFORE, label: 'Titul před'},
      {key: SubjectAttributeType.DEGREE_AFTER, label: 'Titul za'},
    );
  }

  ngOnInit() {
    if (this.showAllAttributes) this.addAllAttributes();
  }
}
