/* tslint:disable */
/* eslint-disable */

/**
 * Type of the house number.
 */
export enum HouseNumberType {
  STREET_NUMBER = 'STREET_NUMBER',
  REGISTRATION_NUMBER = 'REGISTRATION_NUMBER'
}
