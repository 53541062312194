import {ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit} from '@angular/core';

@Component({
  selector: 'icz-spinner',
  templateUrl: './spinner.component.html',
  // Styles of this component are defined in global.scss
  //  because Angular since v17 loads component styles only when included in a
  //  template and that would not work in conjunction with InaccessibleDirective.
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent implements OnInit {

  // elementRef is used by InaccessibleDirective
  elementRef = inject(ElementRef);

  border!: number;

  @Input()
  diameter: number = 100; // in px
  @Input()
  invertSpinnerColor = false;

  ngOnInit() {
    this.border = this.diameter / 7;
  }

}
