import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DispatchOfficeDto} from '|api/codebook';
import {CodebookService, namedDtoToOption} from '../../../core';
import {Option} from '../../../model';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {HistoryService} from '../../../services/history.service';
import {ActivatedRoute} from '@angular/router';
import {combineLatest} from 'rxjs';

export const DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME = 'viewDispatchOfficeId';

@Component({
  selector: 'icz-dispatch-office-selector',
  templateUrl: './dispatch-office-selector.component.html',
  styleUrls: ['./dispatch-office-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DispatchOfficeSelectorComponent implements OnInit {

  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);
  private historyService = inject(HistoryService);
  private activatedRoute = inject(ActivatedRoute);

  hideSelector = false;

  @Output() dispatchOfficeSelected = new EventEmitter<Nullable<DispatchOfficeDto>>();

  dispatchOfficeOptions: Option[] = [
    {value: null, label: 'Všechny výpravny'},
  ];
  dispatchOffices: DispatchOfficeDto[] = [];
  form = new IczFormGroup({
    dispatchOfficeId: new IczFormControl<Nullable<number>>(),
  });

  ngOnInit(): void {
    this.form.get('dispatchOfficeId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(dispatchOfficeId => {
      const dispatchOffice = this.dispatchOffices!.find(d => d.id === dispatchOfficeId);
      if (dispatchOffice) {
        this.historyService.patchHistoryBitWithCustomParams(DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME, String(dispatchOffice.id));
        this.dispatchOfficeSelected.emit(dispatchOffice);
      } else {
        this.historyService.patchHistoryBitWithCustomParams(DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME, null);
        this.dispatchOfficeSelected.emit(null);
      }
    });

    combineLatest([
      this.codebookService.dispatchOffices(),
      this.activatedRoute.queryParamMap
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([dispatchOffices, paramMap]) => {
      this.dispatchOffices = dispatchOffices;
      this.hideSelector = dispatchOffices.length === 1;
      if (this.dispatchOfficeOptions.length === 1) {
        this.dispatchOfficeOptions = this.dispatchOfficeOptions.concat(dispatchOffices.map(namedDtoToOption()));
      }

      setTimeout(() => {
        if (paramMap.has(DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME)) {
          const dispatchNodeParamValue = Number(paramMap.get(DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME)!);
          if (this.dispatchOfficeOptions.some(op => op.value === dispatchNodeParamValue)) {
            this.form.get('dispatchOfficeId')!.setValue(dispatchNodeParamValue);
          } else {
            this.form.get('dispatchOfficeId')!.setValue(null);
          }
        } else {
          this.form.get('dispatchOfficeId')!.setValue(null);
        }
      });
    });
  }

}
