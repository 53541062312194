import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {cloneDeep, isEqual} from 'lodash';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {FunctionalPositionDto} from '|api/auth-server';
import {TreeItemSelectionStrategy} from '../../form-elements/tree-view/tree-view.component';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {Option} from '../../../model';
import {
  OrganizationalStructureOption,
  OrganizationalStructureService,
  OrganizationalUnitOptionData,
} from '../../../core/services/organizational-structure.service';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {CurrentSessionService} from '../../../services/current-session.service';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';

export enum OrganizationStructureType {
  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',
  FUNCTIONAL_POSITION = 'FUNCTIONAL_POSITION'
}

@Component({
  selector: 'icz-org-structure-selector',
  templateUrl: './org-structure-selector.component.html',
  styleUrls: ['./org-structure-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgStructureSelectorComponent implements IczOnChanges, OnInit {

  private currentSessionService = inject(CurrentSessionService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private applicationConfig = inject(ApplicationConfigService);

  readonly TreeSelectorSelectionStrategy = TreeItemSelectionStrategy;

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName = '';
  @Input()
  label = '';
  @Input()
  reservedValueLabel = '';
  @Input()
  isMultiselect = false;
  @Input()
  disableCurrentFunctionalPosition = false;
  @Input()
  useSelfAssignmentConfiguration = false;
  @Input()
  disableOrganizationStructureType: Nullable<OrganizationStructureType>;
  @Input()
  disabledOptionValues: Nullable<Array<Nullable<string | number>>> = null;
  @Input()
  disabledOptionValuesReason = '';
  @Input()
  disableOptionsWithEmptyOrgEntity = false;
  @Input()
  fieldDisabled = false;
  @Input()
  placeholder = '';
  @Input()
  clearable = true;
  @Input()
  autoSizeMax = 1;
  @Output()
  valueChangeWithOriginIds = new EventEmitter<Nullable<Option[] | Option>>();

  orgStructureOptions$: Observable<OrganizationalStructureOption[]> = of([]);
  functionalPositions: Nullable<FunctionalPositionDto[]>;

  ngOnInit(): void {
    this.organizationalStructureService.functionalPositions().subscribe(fms => {
      this.functionalPositions = fms;
      this.initializeOrgStructureOptions();
    });
  }

  emitValueChangeWithOriginIds(v: Nullable<Option[] | Option>) {
    let valueCopy: Nullable<Option[] | Option>;
    if (!v) valueCopy = v;
    else if (Array.isArray(v)) {
      valueCopy = cloneDeep(v);
      valueCopy.filter(vc => vc.originId === 'fp').forEach(vc => vc.value = this.functionalPositions!.find(fp => fp.code === vc.value)!.id);
    } else {
      valueCopy = {...v};
      if (valueCopy.originId === 'fp') {
        valueCopy.value = this.functionalPositions!.find(fp => fp.code === v.value)!.id;
      }
    }
    this.valueChangeWithOriginIds.emit(valueCopy);
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (
      changes.disableCurrentFunctionalPosition?.currentValue !== changes.disableCurrentFunctionalPosition?.previousValue ||
      !isEqual(changes.disabledOptionValues?.currentValue, changes.disabledOptionValues?.previousValue) ||
      changes.disabledOptionValuesReason?.currentValue !== changes.disabledOptionValuesReason?.previousValue
    ) {
      this.initializeOrgStructureOptions();
    }
  }

  private initializeOrgStructureOptions() {
    this.orgStructureOptions$ = this.organizationalStructureService.orgStructureOptions().pipe(
      map(options => {
        // options is passed as reference and this whole piping wiil make changes to options structure, thus creating deep clone of organizationalStructureService.orgStructureOptions
        const optionsDeepCopy: OrganizationalStructureOption[] = JSON.parse(JSON.stringify(options));
        if (this.reservedValueLabel) {
          return [
            {
              value: null,
              label: this.reservedValueLabel,
              isReserved: true,
            },
            ...optionsDeepCopy,
          ] as OrganizationalStructureOption[];
        }
        else {
          return optionsDeepCopy;
        }
      }),
      map(options => {
        const currentFpId = this.currentSessionService.currentUserFunctionalPosition!.id;
        const currentFpOption = options.find(o => {
          return (
            o.id === currentFpId &&
            o.originId === 'fp'
          );
        });
        if (this.disableCurrentFunctionalPosition) {
          if (currentFpOption) {
            currentFpOption.disabled = true;
            currentFpOption.disableReason = 'Nelze vybrat sebe sama';
          }

          const parentOuWithRepresentingFp = options.find(o => {
            return (
              o.originId === 'ou' &&
              (o.data as OrganizationalUnitOptionData)?.defaultFunctionalPositionId === currentFpId
            );
          });

          if (parentOuWithRepresentingFp) {
            parentOuWithRepresentingFp.disabled = true;
            parentOuWithRepresentingFp.disableReason = 'Nelze vybrat organizační jednotku, jejíž reprezentant je Vaše funkční místo';
          }
        }

        if (this.useSelfAssignmentConfiguration &&
          this.applicationConfig.receivedDocumentDeliveryWithoutForwarding &&
          currentFpOption &&
          isNil(this.form.get(this.controlName)!.value)
        ) {
          this.form.get(this.controlName)!.setValue(currentFpOption.value);
          this.emitValueChangeWithOriginIds(currentFpOption);
        }

        return options;
      }),
      map(options => {
        if (this.disableOptionsWithEmptyOrgEntity) {
          for (const option of options) {
            if ((option.data as OrganizationalUnitOptionData)?.isEmptyOrgEntity) {
              option.disabled = true;
              if (option.originId === 'fp') {
                option.disableReason = 'Nelze vybrat funkční místo, které nikdo nevykonává.';
              } else if (option.originId === 'ou') {
                option.disableReason = 'Nelze vybrat organizační jednotku, která nemá určeného reprezentanta.';
              }
            }
          }
          this.form.get(this.controlName)?.addValidators([IczValidators.isNonEmptyOrgEntity(options)]);
        } else {
          // disableOptionsWithEmptyOrgEntity is only set once so no need to handle
        }

        if (this.disabledOptionValues?.length) {
          const optionsToDisable = options.filter(o => this.disabledOptionValues!.includes(o.value));

          for (const option of optionsToDisable) {
            option.disabled = true;
            option.disableReason = this.disabledOptionValuesReason;
          }

        } else if (this.disableOrganizationStructureType) {
          const optionsToDisable = options.filter(o =>
            (o.originId === 'fp' && this.disableOrganizationStructureType === OrganizationStructureType.FUNCTIONAL_POSITION) ||
            (o.originId === 'ou' && this.disableOrganizationStructureType === OrganizationStructureType.ORGANIZATION_UNIT));

          for (const option of optionsToDisable) {
            option.disabled = true;
            option.disableReason = this.disabledOptionValuesReason;
          }
        }

        return options;
      }),
    );
  }

}
