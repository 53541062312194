import {inject, Injectable, InjectionToken} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';

export const WINDOW = new InjectionToken('Browser Window Object', {
  factory: () => window,
  providedIn: 'root',
});

export enum ResponsivityBreakpoint {
  TABLET = 1270, // <0x0, 1280x800>
  DESKTOP_SM = 1430, // <1280x800, 1440x900>
  DESKTOP_MD = 1670, // <1440x900, 1680x1050>
  DESKTOP_LG = Infinity, // <1680x1050, +Inf>
}

@Injectable({
  providedIn: 'root'
})
export class ResponsivityService {

  private window = inject(WINDOW);

  // keep it in sync with ResponsivityBreakpoint enum and respect ascending breakpoints order pls
  private breakpointsOrder = [
    0,
    ResponsivityBreakpoint.TABLET,
    ResponsivityBreakpoint.DESKTOP_SM,
    ResponsivityBreakpoint.DESKTOP_MD,
    ResponsivityBreakpoint.DESKTOP_LG,
  ];

  private windowResized$ = fromEvent(this.window, 'resize').pipe(
    startWith(null),
    map(_ => this.window.innerWidth),
    debounceTime(10),
  );

  isTablet$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.TABLET);
  isSmallDesktop$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.DESKTOP_SM);
  isMidDesktop$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.DESKTOP_MD);
  // not providing an observable for LargeDesktop as there should be no optimizations needed for it

  activeBreakpoint$ = this.windowResized$.pipe(
    map(viewportWidth => {
      for (const breakpointSize of this.breakpointsOrder) {
        if (viewportWidth < breakpointSize) {
          return breakpointSize;
        }
      }

      return ResponsivityBreakpoint.DESKTOP_LG;
    }),
    distinctUntilChanged(),
  );

  invisibleBreakpointsList$ = this.activeBreakpoint$.pipe(
    map(activeBreakpoint => this.breakpointsOrder.filter(b => b >= activeBreakpoint)),
    distinctUntilChanged(),
  );

  private createObsFromBreakpoint(breakpoint: number) {
    return this.windowResized$.pipe(
      map(_ => this.window.innerWidth < breakpoint),
      distinctUntilChanged(),
    );
  }

}
