@if (entity) {
  <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openDetail()">
    @if (entity.refNumber) {
      <icz-highlightable-text-content
        class="ref-number"
        [fieldId]="columnDefinition?.id"
        [originalValue]="entity.refNumber"
        [searchTermWords]="searchTermWords"
        [highlights]="$any(entity).highlights">
      </icz-highlightable-text-content>
    }
    @if (!entity.refNumber) {
      <span class="icz-text-muted" [iczTooltip]="'bez čísla jednacího' | translate">
        {{ 'bez čísla jednacího' | translate }}
      </span>
    }
  </a>
}
