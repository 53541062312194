<div class="submit-bar-wrapper"
     [blockingOverlay]="savingProgress ?? false"
     [testingFeatureLabel]="''">
  <icz-button data-cy="submit-bar-btn"
              primary iczFormSubmit
              [disabled]="submitDisabled || savingProgress"
              (onAction)="submit.emit()"
              [label]="customCaption ? customCaption : caption"></icz-button>
  <ng-content></ng-content>
  <div class="grow"></div>
  <ng-content select="[beforeCancel]"></ng-content>
  <icz-button iczFormCancel (onAction)="cancel.emit()"
              label="Storno" [disabled]="savingProgress"></icz-button>
</div>
