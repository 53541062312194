import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {
  ApiComponentService,
  ApiDigitalComponentService,
  ApiPaperComponentService,
  DigitalComponentCompleteDto,
  PaperComponentDto
} from '|api/component';
import {FilterOperator, SearchApiService, SearchParams} from './search-api.service';

@Injectable({
  providedIn: 'root',
})
export class ComponentSearchService extends SearchApiService {

  countDocumentComponents(documentId: number): Observable<number> {
    const searchParams: Partial<SearchParams> = {
      filter: [
        {
          fieldName: 'documentId',
          operator: FilterOperator.equals,
          value: String(documentId),
        }
      ]
    };

    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiComponentService.ComponentSearchAndCountPath);
  }

  findDocumentDigitalComponents(documentId: number, searchParams: Partial<SearchParams>): Observable<Page<DigitalComponentCompleteDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiDigitalComponentService.DigitalComponentFindDigitalComponentsPath, {documentId}));
  }

  findDocumentPaperComponents(documentId: number, searchParams: Partial<SearchParams>): Observable<Page<PaperComponentDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiPaperComponentService.PaperComponentFindPaperComponentsPath, {documentId}));
  }

}
