import {Component, inject} from '@angular/core';
import {CirculationActivityFlowType, CirculationActivityType, DocumentForm, EntityType} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {
  ApiCirculationActivityBulkActionsService,
  ApiCirculationActivityService,
  CirculationActivityCreateDto
} from '|api/flow';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {
  AbstractHandoverFormModalComponent
} from '../../../document-toolbar/components/abstract-handover-form-modal.component';
import {IczFormControl, IczFormGroup, TextLength} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {FunctionalPositionGroupItems} from '../../../saved-functional-positions.service';
import {OrganizationStructureType} from '../../../functional-position-selector/org-structure-selector.component';
import {MessageType} from '../../../../notifications/toast.service';
import {getLatestDigitalComponentVersion} from '../../../shared-document.utils';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {handleCirculationErrorAsToast} from '../../../../../utils/document-file-circulation.utils';
import {CirculationToastType} from '../../../../../core/services/notifications/circulation-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {
  SEsslActivityErrorData
} from '../../../../../core/services/notifications/essl-component-circulation-toast.service';
import {injectModalData} from 'libs/shared/src/lib/lib/modals';

@Component({
  selector: 'icz-hand-for-signing-dialog',
  templateUrl: './hand-for-signing-dialog.component.html',
  styleUrls: ['./hand-for-signing-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class HandForSigningDialogComponent extends AbstractHandoverFormModalComponent {

  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);
  protected override circulationEntities = injectModalData<DigitalComponentCompleteDto[]>();

  override form = new IczFormGroup({
    orgStructureElementIds: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    taskParticipantsOrder: new IczFormControl<Nullable<string>>(null),
    deadline: new IczFormControl<Nullable<string>>(null),
    numberOfAnalogComponentCopiesToSign: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger()]),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });

  protected override participantsOrderControlName = 'taskParticipantsOrder';

  protected override selectedFlowType = CirculationActivityFlowType.SERIAL;

  override get groupForSaving(): FunctionalPositionGroupItems {
    if (this.selectedFlowType === CirculationActivityFlowType.PARALLEL) {
      return super.groupForSaving;
    }
    else {
      return this.participantsOrderControl?.value;
    }
  }

  get isDigitalComponent() {
    return this.circulationEntities[0].entityType === EntityType.DIGITAL_COMPONENT;
  }

  readonly DocumentForm = DocumentForm;

  readonly OrganizationStructureType = OrganizationStructureType;
  readonly CirculationActivityFlowType = CirculationActivityFlowType;

  handForSigning() {
    const formValue = this.form.value;
    const activityCreateDtos: CirculationActivityCreateDto[] = this.circulationEntities.map(entity => ({
      circulationEntityType: entity.entityType!,
      componentId: entity.id,
      componentLabel: entity.label,
      digitalComponentVersionId: getLatestDigitalComponentVersion(entity)?.id,
      deadline: formValue.deadline!,
      flowType: this.selectedFlowType,
      targetTaskInstruction: formValue.targetTaskInstruction!,
      functionalPositions: this.selectedFunctionalPositions,
      organizationalUnits: this.selectedOrganizationalUnits,
      numberOfAnalogComponentCopiesToSign: formValue.numberOfAnalogComponentCopiesToSign,
    }));

    if (this.isSingleEntityFlow) {
      this.loadingService.doLoading(
        this.apiCirculationActivityService.circulationActivityCreateComponentSigning(
          {
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        ),
        this,
      ).subscribe({
        next: createdActivity => this.modalRef.close(createdActivity.id),
        error: errorResponse => handleCirculationErrorAsToast(
          this.circulationEntities[0],
          errorResponse,
          this.toastService,
          this.translateService,
          this.modalRef,
          CirculationActivityType.COMPONENT_SIGNING
        ),
      });
    } else {
      this.loadingService.doLoading(
        this.apiCirculationActivityBulkActionsService.circulationActivityBulkActionsSign(
          {
            body: activityCreateDtos,
          },
          SKIP_ERROR_DIALOG
        ),
        this,
      ).subscribe({
        next: _ => this.modalRef.close(true),
        error: _ => this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_BULK_COMPONENT_HAND_FOR_SIGN_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationActivityType.COMPONENT_SIGNING}], SEsslActivityErrorData)
      });
    }
  }

}
