/* tslint:disable */
/* eslint-disable */

/**
 * Disposal operation code of a subject record related object
 */
export enum DisposalOperationCode {
  S = 'S',
  V = 'V',
  A = 'A'
}
