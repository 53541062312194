/* tslint:disable */
/* eslint-disable */

/**
 * Delivery type group
 */
export enum DeliveryTypeGroup {
  POSTA = 'POSTA',
  HYBRIDNI = 'HYBRIDNI',
  OSOBNI = 'OSOBNI',
  VYVESKA = 'VYVESKA',
  TELEFAX = 'TELEFAX',
  EMAIL = 'EMAIL',
  PORTAL = 'PORTAL',
  ISDS = 'ISDS',
  INTERNI = 'INTERNI',
  INTERNI_SCHRANKA = 'INTERNI_SCHRANKA'
}
