<div class="col gap-24 icz-body-1 h-full">
  @if (modalData.warningMessage) {
    <icz-alert severity="warning" [heading]="modalData.warningMessage" [textContext]="modalData.warningMessageContext"></icz-alert>
  }

  <ng-container>
    <div class="row">
      <icz-label class="font-bold" label="Seznam chyb:"></icz-label>
    </div>

    <div class="overflow-y-auto error-list">
      @for (error of modalData.errors; track error) {
        <div class="row items-start mb-16">
          <icz-icon svgIcon="warning" withCustomIconColor></icz-icon>
          <!-- todo this should be configurable using ng-templates in the future -->
          <div>
            <strong>{{ error.entity.name }}</strong>: {{ error.errorMessage }}
          </div>
        </div>
      }
    </div>
  </ng-container>
</div>

<icz-form-buttons>
  @if (modalData.showConfirmButton) {
    <icz-button primary leftButtons label="Pokračovat" (onAction)="confirm()"></icz-button>
  }
  <icz-button rightButtons (onAction)="cancel()" [label]="modalData.showConfirmButton ? 'Storno' : 'Zavřít'"></icz-button>
</icz-form-buttons>
