import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {parseBoolean} from '../../lib/utils';

export function formatBoolean(translateService: TranslateService, value: Nullable<boolean>) {
  if (isNil(value)) {
    return '';
  }
  else {
    return translateService.instant(value ? 'Ano' : 'Ne');
  }
}

@Pipe({
  name: 'localizedBoolean',
  pure: false, // false because it also depends on app locale
})
export class LocalizedBooleanPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(value: Nullable<boolean | string>): string {
    return formatBoolean(
      this.translateService,
      typeof(value) === 'string' ? parseBoolean(value) : value,
    );
  }

}
