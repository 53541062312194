import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {AbstractSubjectsTableActionsComponent} from '../subjects-table-actions';


@Component({
  selector: 'icz-subjects-table-generic-actions',
  templateUrl: './subjects-table-generic-actions.component.html',
  styleUrls: ['./subjects-table-generic-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectsTableGenericActionsComponent extends AbstractSubjectsTableActionsComponent {
  @Input() linkButtonLabel: Nullable<string> = 'Přidat';
  @Input() linkButtonDisabled = false;
  @Input() linkButtonDisabledTooltip : Nullable<string>;
}
