<div class="label-box" [class.highlighted]="_isHighlighted">
  @if (!_isInline) {
    <div class="title">{{ annotation | translate }}</div>
    <div class="label">{{ $any(label) | translate }}</div>
  }
  @else {
    <span class="title">{{ annotation | translate }}</span>:
    <span class="label">{{ isNil(label) ? '-' : $any(label) | translate }}</span>
  }
</div>
