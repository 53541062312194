import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {isEqual} from 'lodash';
import {EmpowermentDto} from '|api/commons';
import {FormField} from '../form-field';
import {IczFieldValidationResults, IczValidatorFn} from '../validators/icz-validators/validator-decorators';
import {IczValidators, ValidatorResult} from '../validators/icz-validators/icz-validators';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {isNilOrEmptyString} from '../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';


@Component({
  selector: 'icz-empowerment',
  templateUrl: './empowerment.component.html',
  styleUrls: ['./empowerment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmpowermentComponent extends FormField<EmpowermentDto> implements OnInit, IczOnChanges {

  private destroyRef = inject(DestroyRef);

  @Input()
  override set value(newValue: Nullable<EmpowermentDto>) {
    this.__value = newValue ?? null;
    if (!isNil(newValue)) {
      this.yearControl.setValue(newValue.year ?? null, {emitEvent: false});
      this.lawControl.setValue(newValue.law ?? null, {emitEvent: false});
      this.sectionControl.setValue(newValue.section ?? null, {emitEvent: false});
      this.pointControl.setValue(newValue.point ?? null, {emitEvent: false});
      this.paragraphControl.setValue(newValue.paragraph ?? null, {emitEvent: false});

      setTimeout(() => {
        this.isChangeOnSetValue = true;
        this.resolveValidators();
      });
    }
  }
  override get value(): Nullable<EmpowermentDto> {
    return this.__value;
  }

  @Input() disableRequiredValidation = false;

  _valueChanged(empowermentValue: EmpowermentDto) {
    if (this.isChangeOnSetValue) {
      this.isChangeOnSetValue = false;
    } else {
      if (this.isEmpowermentEmpty(this._form)) {
        this.__value = null;
      } else {
        this.__value = empowermentValue;
      }
      this.valueChange.emit(this.__value);
    }
  }

  readonly empowermentFieldNames: Record<string, string> = {
    year: 'Rok vydání',
    law: 'Číslo zákona',
    paragraph: 'Odstavec',
    section: 'Paragraf',
    point: 'Písmeno',
  };

  private __value: Nullable<EmpowermentDto>;

  _form: IczFormGroup = this.getEmpowermentForm();
  private oldValue = {};
  empowermentErrors: IczFieldValidationResults[] = [];
  isChangeOnSetValue = false;

  override onControlAssigned = () => {
    this.control.addValidators([this.empowermentValidator()]);
  };

  get lawControl(): IczFormControl {
    return this._form.get('law')! as IczFormControl;
  }

  get yearControl(): IczFormControl {
    return this._form.get('year')! as IczFormControl;
  }

  get sectionControl(): IczFormControl {
    return this._form.get('section')! as IczFormControl;
  }

  get pointControl(): IczFormControl {
    return this._form.get('point')! as IczFormControl;
  }

  get paragraphControl(): IczFormControl {
    return this._form.get('paragraph')! as IczFormControl;
  }

  getEmpowermentForm(): IczFormGroup {
    return new IczFormGroup({
      year: new IczFormControl<Nullable<number>>(null, [IczValidators.min(1900), IczValidators.max(new Date().getFullYear())]),
      law: new IczFormControl<Nullable<number>>(null, []),
      paragraph: new IczFormControl<Nullable<string>>(null, []),
      section: new IczFormControl<Nullable<string>>(null, []),
      point: new IczFormControl<Nullable<string>>(null, []),
    });
  }

  isEmpowermentEmpty(form: IczFormGroup) {
    return Object.entries(form.getRawValue()).every(([_, value]) => isNilOrEmptyString(value as Nullable<any>));
  }

  empowermentValidator(): IczValidatorFn {
    return (_: AbstractControl): ValidatorResult => {
      if (this.empowermentErrors.length > 0) {
        return this.empowermentErrors[0].errors;
      }
      return null;
    };
  }

  ngOnInit(): void {
    this._form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      const newValue = this._form.getRawValue();

      if (!isEqual(this.oldValue, newValue)) {
        this.oldValue = {...newValue};
        this.resolveValidators();
        this._valueChanged(newValue);
      }
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.fieldDisabled) {
      if (this.fieldDisabled) {
        this._form.disable();
      }
      else {
        this._form.enable();
      }
    }
  }

  resolveValidators(emitEvent = true) {
    this.empowermentErrors = [];
    const isEmpowermentValueEmpty = this.isEmpowermentEmpty(this._form);

    if(isEmpowermentValueEmpty) {
      this.lawControl.clearValidators();
      this.yearControl.clearValidators();
      this.sectionControl.clearValidators();
      this.paragraphControl.clearValidators();
      this.pointControl.clearValidators();
    } else {
      this.lawControl.addValidators([
        IczValidators.min(1),
        IczValidators.max(9999),
        IczValidators.isInteger(),
      ]);
      this.yearControl.addValidators([
        IczValidators.min(1900)]);

      if (!this.disableRequiredValidation) {
        this.lawControl.addValidators([IczValidators.required()]);
        this.yearControl.addValidators([IczValidators.required()]);
      }

      this.paragraphControl.addValidators([
        IczValidators.min(1),
        IczValidators.max(999),
        IczValidators.isStringifiedInteger(),
      ]);
      this.sectionControl.addValidators([
        IczValidators.isValidEmpowermentSection()
      ]);
      this.pointControl.addValidators([
        IczValidators.isValidEmpowermentPoint()
      ]);
    }

    this._form.recursivelyUpdateValueAndValidity({emitEvent});
    this.empowermentErrors = [];
    Object.keys(this._form.controls).forEach( controlName => {
      const formControl: IczFormControl = this._form.get(controlName) as IczFormControl;
      if (formControl.invalid) {
        this.empowermentErrors.push({
          fieldName: this.empowermentFieldNames[controlName],
          errors: {...formControl.errors}
        });
      }
    });
  }

}
