import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {ApplicationRoute} from '../../enums/shared-routes.enum';

export enum ApplicationModule {
  'MAIN' = 'main',
  'ADMIN' = 'admin',
  'CONFIG' = 'config'
}

export function getApplicationModuleByUrl(url: string): ApplicationModule {
  if (url.startsWith(`/${ApplicationRoute.ADMIN}`)) {
    return ApplicationModule.ADMIN;
  } else if (url.startsWith(`/${ApplicationRoute.CONFIG}`)) {
    return ApplicationModule.CONFIG;
  } else {
    return ApplicationModule.MAIN;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActiveModuleService {

  private _activeModule$ = new BehaviorSubject<ApplicationModule>(ApplicationModule.MAIN);
  activeModule$ = this._activeModule$.asObservable();


  get activeModule() {
    return this._activeModule$.value;
  }

  set activeModule(am: ApplicationModule) {
    this._activeModule$.next(am);
  }

}
