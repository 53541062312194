/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CirculationActivityPreconditionsCheckResultDto } from '../../models/circulation-activity-preconditions-check-result-dto';

export interface CirculationActivityExecuteComponentSigningPreconditionsCheck_1$Params {

/**
 * Component ID
 */
  componentId: number;
}

export function circulationActivityExecuteComponentSigningPreconditionsCheck_1(http: HttpClient, rootUrl: string, params: CirculationActivityExecuteComponentSigningPreconditionsCheck_1$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationActivityPreconditionsCheckResultDto>> {
  const rb = new RequestBuilder(rootUrl, circulationActivityExecuteComponentSigningPreconditionsCheck_1.PATH, 'get');
  if (params) {
    rb.path('componentId', params.componentId, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CirculationActivityPreconditionsCheckResultDto>;
    })
  );
}

circulationActivityExecuteComponentSigningPreconditionsCheck_1.PATH = '/flow/activity/component-signing-preconditions-check/{componentId}';