import {sub} from 'date-fns';
import {getTodayMidnight, getTomorrowMidnight} from '../../../../lib/utils';

export class IczDateValidators {
  static presentOrFutureDateValidator = {
    validationFn: (d: Date) => {
      return d >= getTodayMidnight();
    },
    invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být v minulosti'}
  };

  static futureDateValidator = {
    validationFn: (d: Date) => {
      return d > getTodayMidnight();
    },
    invalidDateMessage : { errorMessageTemplate: 'Datum musí být den následující po aktuálním datu.'}
  };

  static pastOrPresentDateValidator = {
    validationFn: (d: Date) => {
      return d <= getTodayMidnight();
    },
    invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být v budoucnosti'}
  };

  static yesterdayOrFutureDateValidator = {
    validationFn: (d: Date) => d.getTime() >= Date.now() - (24 * 60 * 60 * 1000),
    invalidDateMessage : { errorMessageTemplate: 'Můžete zadat včerejší nebo pozdější datum'}
  };

  static presentOrFutureWithouWeekendValidator = {
    validationFn: (d: Date) => {
      return d >= getTodayMidnight() && d.getDay() !== 6; // 6 = saturday
    },
    invalidDateMessage : { errorMessageTemplate: 'Zadejte budoucí datum, které není o víkendu'}
  };

  static birthDateValidator = {
    validationFn: (d: Date) => {
      const lowerBound = sub(new Date(), {years: 200, days: 1});
      return d >= lowerBound && d < getTomorrowMidnight();
    },
    invalidDateMessage: { errorMessageTemplate: 'Neplatné datum narození'}
  };
}
