/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { conversionRecordSearch } from '../fn/conversion-record/conversion-record-search';
import { ConversionRecordSearch$Params } from '../fn/conversion-record/conversion-record-search';
import { conversionRecordSearchBulk } from '../fn/conversion-record/conversion-record-search-bulk';
import { ConversionRecordSearchBulk$Params } from '../fn/conversion-record/conversion-record-search-bulk';
import { ConversionRegisterDto } from '../models/conversion-register-dto';


/**
 * Conversion Record controller
 */
@Injectable({ providedIn: 'root' })
export class ApiConversionRecordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `conversionRecordSearchBulk()` */
  static readonly ConversionRecordSearchBulkPath = '/component/conversion-register/bulk';

  /**
   * Finds the conversion register record for a given target artefact ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionRecordSearchBulk()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionRecordSearchBulk$Response(params: ConversionRecordSearchBulk$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversionRegisterDto>>> {
    return conversionRecordSearchBulk(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the conversion register record for a given target artefact ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionRecordSearchBulk$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionRecordSearchBulk(params: ConversionRecordSearchBulk$Params, context?: HttpContext): Observable<Array<ConversionRegisterDto>> {
    return this.conversionRecordSearchBulk$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversionRegisterDto>>): Array<ConversionRegisterDto> => r.body)
    );
  }

  /** Path part for operation `conversionRecordSearch()` */
  static readonly ConversionRecordSearchPath = '/component/conversion-register/';

  /**
   * Finds the conversion register record for a given target artefact ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `conversionRecordSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionRecordSearch$Response(params: ConversionRecordSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ConversionRegisterDto>>> {
    return conversionRecordSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the conversion register record for a given target artefact ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `conversionRecordSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  conversionRecordSearch(params: ConversionRecordSearch$Params, context?: HttpContext): Observable<Array<ConversionRegisterDto>> {
    return this.conversionRecordSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ConversionRegisterDto>>): Array<ConversionRegisterDto> => r.body)
    );
  }

}
