@if (columnsData && config) {
  <icz-table
    class="grow h-full"
    [id]="id"
    [dataSource]="dataSource"
    [columnsData]="columnsData"
    [config]="config"
    [preselectedRows]="preselectedRows"
    [manualRowCount]="rowCount"
    (pageLoad)="loadPage($event)"
    (selectedRowsChanged)="rowsSelected.emit($event)"
    [expandedRow]="expandedRow"
    [rowSelectionDisabler]="rowSelectionDisabler!"
    [disabledRowTooltip]="disabledRowTooltip"
    (resized)="setColumnsDisplayedForExpanded(false)"
    (dragged)="setColumnsDisplayedForExpanded(true)"
    [isNonBlockingLoading]="isNonBlockingLoading || (addingTimestampToDigitalComponents$ | async)!"
    [hideTableToolbar]="hideTableToolbar"
    >
    <ng-content tableTitle select="[tableTitle]"></ng-content>
    <icz-label noDataLabel label="Žádné komponenty"></icz-label>
    <icz-label notFoundLabel label="Žádné komponenty nebyly nalezeny"></icz-label>

    <ng-content tools select="[tools]"></ng-content>

    <ng-template #iczExpandedRowTemplate let-row>
      @for (version of row.digitalComponentVersions; track version.id) {
        <div class="version-expanded-row">
          @if (!$first) {
            @for (column of columnsDisplayed; track column.id) {
              <div [style.width.px]="column.width" class="version-expanded-col" [id]="'expandedCol' + column.id">
                @switch (column.id) {
                  @case (EsslComponentsTableColumn.VERSION) {
                    <icz-label label="Verze" class="mr-4"></icz-label>
                    {{ version.version }}
                  }
                  @case (EsslComponentsTableColumn.LABEL_AND_DESCRIPTION) {
                    <icz-essl-component-link
                      class="visible-clickable-area p-8"
                      [versionId]="version.id!"
                      [documentEsslComponent]="row"
                      [hideAllComponentsContent]="canUserDisplayComponentsContent"
                      [showFileName]="true"
                      (click)="$event.stopPropagation()"
                    ></icz-essl-component-link>
                  }
                  @case (EsslComponentsTableColumn.SIGNATURES) {
                    @if (version.digitalComponentVersionSignatureFlags?.length) {
                      <icz-digital-component-flags
                        [digitalComponentVersionId]="version.id"
                        [flags]="version.digitalComponentVersionSignatureFlags"
                      ></icz-digital-component-flags>
                    }
                  }
                  @case (EsslComponentsTableColumn.CREATED_AT) {
                    {{ version.auditInfo!.createdAt! | localizedDate }}
                  }
                  @case (EsslComponentsTableColumn.FILE_SIZE) {
                    {{ version?.fileSize! | fileSize }}
                  }
                }
              </div>
            }
          }
        </div>
      }
    </ng-template>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.IS_FINAL">
      @if (row.isFinal) {
        <icz-icon class="header-icon" size="small" svgIcon="checked"></icz-icon>
      }
    </ng-container>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.AVAILABILITY">
      @if (row.availability === EsslComponentAvailability.LOCKED) {
        <icz-icon class="checked" size="small" svgIcon="icon_lock"></icz-icon>
      }
      @else if (row.availability === EsslComponentAvailability.AVAILABLE) {
      <icz-icon class="checked" size="small" svgIcon="icon_unlocked"></icz-icon>
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.COMPONENT_STATE; let column = column">
    @if ($any(row).currentUserCirculationTaskState; as taskState) {
      @if (taskState === CirculationTaskState.ASSIGNED_TO_INITIATOR) {
        <icz-tag color="orange" [inline]="true" label="V oběhu"></icz-tag>
      }
      @else {
        <icz-tag
          color="orange" [inline]="true"
          [label]="'en.circulationTaskState.' + taskState"
        ></icz-tag>
      }
    }
    @else {
      @if (getLatestDigitalComponentVersion($any(row))?.extractionState; as extractionState) {
        <icz-tag
          [color]="getExtractionStateTagColor(extractionState)" [inline]="true"
          [label]="extractionState | findInList: extractionStateOptions"
        ></icz-tag>
      }
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.SIGNATURES">
    @if (isDigitalComponent(row) && row?.digitalComponentVersions) {
      @if (hasValidatedFlags(row!.digitalComponentVersions![0]!.digitalComponentVersionSignatureFlags)) {
        @if (row?.digitalComponentVersions![0]!.digitalComponentVersionSignatureFlags!.length) {
          <icz-digital-component-flags
            [digitalComponentVersionId]="row!.digitalComponentVersions![0]!.id!"
            [flags]="row?.digitalComponentVersions![0]!.digitalComponentVersionSignatureFlags!"
          ></icz-digital-component-flags>
        }
      }
      @else {
        <icz-tag
          class="inline-flex"
          color="red"
          label="Nebylo ověřeno"
        ></icz-tag>
      }
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.LABEL_AND_DESCRIPTION">
    <icz-essl-component-link
      class="visible-clickable-area p-8"
      [documentEsslComponent]="row"
      (metadataChanged)="dataSource.reload(true)"
      (click)="$event.stopPropagation()"
      [isReadonly]="isDocumentDeactivated"
      [hideAllComponentsContent]="canUserDisplayComponentsContent"
      [consignmentId]="columnSet === EsslComponentsTableColumnSet.OWN_CONSIGNMENT_COMPONENT_SELECTOR ? consignmentId : null"
      >
      <ng-container fileStatus>
        @if ((isDigitalComponent(row) || isPaperComponent(row)) && row.processingState) {
          @switch (row.processingState) {
            @case (ComponentProcessingState.CONVERSION_IN_PROGRESS) {
              <icz-static-progress-indicator progressLabel="Probíhá konverze"></icz-static-progress-indicator>
            }
            @case (ComponentProcessingState.CONTAINER_EXTRACTION_IN_PROGRESS) {
              <icz-static-progress-indicator progressLabel="Probíhá vytěžování"></icz-static-progress-indicator>
            }
            @case (ComponentProcessingState.COMPONENT_ANONYMIZATION_IN_PROGRESS) {
              <icz-static-progress-indicator progressLabel="Probíhá anonymizace"></icz-static-progress-indicator>
            }
            @case (ComponentProcessingState.COMPONENT_NATIVE_EDIT_IN_PROGRESS) {
              <icz-static-progress-indicator progressLabel="Probíhá editace"></icz-static-progress-indicator>
            }
          }
        }
        @else if (isMarking(row.id!) | async) {
          <icz-static-progress-indicator progressLabel="Probíhá pečetění"></icz-static-progress-indicator>
        }
        @else if (isAddingTimestamp(row.id!) | async) {
          <icz-static-progress-indicator progressLabel="Probíhá přidávání časového razítka">
          </icz-static-progress-indicator>
        }
        @else if (isInManualValidation(row.id!) | async) {
          <icz-static-progress-indicator progressLabel="Probíhá manuální validace">
          </icz-static-progress-indicator>
        }
      </ng-container>
    </icz-essl-component-link>
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.LABEL_SMALL">
    <icz-essl-component-link
      class="visible-clickable-area px-4 py-2"
      [documentEsslComponent]="row"
      (metadataChanged)="dataSource.reload()"
      (click)="$event.stopPropagation()"
      [isReadonly]="isDocumentDeactivated"
      [hideDescription]="true"
      [hideIcon]="true"
      [hideAllComponentsContent]="canUserDisplayComponentsContent"
      [consignmentId]="columnSet === EsslComponentsTableColumnSet.OWN_CONSIGNMENT_COMPONENT_SELECTOR ? consignmentId : null"
    ></icz-essl-component-link>
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.VERSION">
    @if (isDigitalComponent(row) && row.digitalComponentVersions) {
      <div class="row">
        @if (row.digitalComponentVersions.length > 1) {
          <icz-button
            [stopClickPropagation]="true"
            (onAction)="toggleVersions(row)"
            [svgIcon]="expandedRow === row ? 'expand_more' : 'after'"
            size="small"
            noBorder
            class="versions-expander"
            [label]="$any(row.digitalComponentVersions.length)"
          ></icz-button>
        }
        @else {
          {{ row.digitalComponentVersions.length }}
        }
        <div class="grow"></div>
      </div>
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.RENDITION_TYPE; let column = column">
    @if ($any(row).renditionType === DigitalComponentRenditionType.CONTAINER_EXTRACTION) {
      <div iczTooltip="Vytěženo z kontejneru">
        <icz-button svgIcon="extract" class="rendition-type-badge"></icz-button>
      </div>
    }
    @if ($any(row).renditionType === DigitalComponentRenditionType.ANONYMIZATION) {
      <div iczTooltip="Anonymizace">
        <icz-button svgIcon="extract" class="rendition-type-badge"></icz-button>
      </div>
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.RELATION_TYPE; let column = column">
    {{ row.relationType | findInList: column.list! | translate }}
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.ORIGIN_TYPE; let column = column">
    {{ $any(row).originType | findInList: column.list! | translate }}
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.COUNT">
    <span>{{row.relationType === EsslComponentRelation.MAIN ? '' : $any(row).count}}</span>
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.NUMBER_OF_SHEETS">
    <span>{{$any(row).compositionType === AnalogCompositionType.VOLUMES ? '' : $any(row).numberOfSheets}}</span>
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.ORDER_IN_DOCUMENT">
    <span>{{row.orderInDocument}}</span>
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.FILE_SIZE">
    @if (isDigitalComponent(row) && row.digitalComponentVersions) {
      {{ getLatestDigitalComponentVersion(row)?.fileSize! | fileSize }}
    }
    @else {
      {{ $any(row).digitalComponentLastFileSize! | fileSize }}
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.CREATED_BY; withEllipsis: true">
    @if ((row.auditInfo && row.auditInfo.createdBy | findUserByUsername | async)! | translate; as createdBy) {
      <span [iczTooltip]="createdBy">{{ createdBy }}</span>
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.MODIFIED_BY; withEllipsis: true">
    @if ((row.auditInfo && row.auditInfo.modifiedBy | findUserByUsername | async)! | translate; as modifiedBy) {
      <span [iczTooltip]="modifiedBy">{{ modifiedBy }}</span>
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.IDENTIFIER; withEllipsis: true">
    @if (row.uid | formatIdentifier; as fullIdentifier) {
      <span [iczTooltip]="fullIdentifier">{{ fullIdentifier }}</span>
    }
  </ng-container>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: EsslComponentsTableColumn.UNACCEPTABLE_OUTPUT_FORMAT">
    @if (isUnacceptableOutputFormat(row)) {
      <icz-icon [iczTooltip]="'Verze digitální komponenty není ve výstupním či tolerovaném datovém formátu.'" class="header-icon" size="small" svgIcon="inline_error"></icz-icon>
    }
  </ng-container>
</icz-table>
}
