<div class="row gap-48" [formGroup]="specificFieldsForm" [waiting]="loadingService.isLoading$(this) | async">
  <div class="grow">
    <icz-section label="Adresa portálu">
      <icz-form-autocomplete
        formControlName="dispatchOfficeDistributionNodeId"
        label="Adresa portálu"
        [options]="portalDistributionNodeOptions"
      ></icz-form-autocomplete>
    </icz-section>
  </div>
  <div class="grow"></div>
</div>
