import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, merge, Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {AnalogComponentTypeDto} from '|api/codebook';
import {
  AnalogComponentForm,
  AnalogComponentOrigin,
  AnalogCompositionType,
  AnalogProtectiveElement,
  ContainerExtractionState,
  DigitalComponentOrigin,
  EntityType,
  EsslComponentAvailability,
  EsslComponentRelation,
} from '|api/commons';
import {
  ApiConversionRecordService,
  ApiDigitalComponentService,
  ApiDigitalComponentVersionService,
  ApiMediumComponentService,
  ApiPaperComponentService,
  ApiPhysicalItemComponentService,
  ComponentContentControlMetadataDto,
  ConversionRegisterDto,
  ConversionRegisterSearchRequest,
  DigitalComponentCompleteDto,
  DigitalComponentDto,
  DigitalComponentMetadataUpdateDto,
  DigitalComponentVersionCompleteDto,
  MediumComponentUpdateDto,
  PaperComponentDto,
  PaperComponentUpdateDto,
  PhysicalItemComponentUpdateDto,
} from '|api/component';
import {ComponentAuthorizedOperation} from '|api/core';
import {ApiDocumentService, OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {CheckUnsavedFormDialogService} from '../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../lib/form-group-checks';
import {ToggleButtonComponent} from '../../essentials/toggle-button/toggle-button.component';
import {EsslAnalogComponentDto, EsslComponentDto} from '../../../services/essl-component-search.service';
import {getComponentIcon, Option} from '../../../model';
import {isAuthorizedOperationGranted} from '../permissions/permissions.utils';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {BinaryWithCharset, RemoteBinaryFileDownloadService,} from '../../../services/remote-binary-file-download.service';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {EsslComponentViewerModalData} from '../../../services/essl-component-modal.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {LocalBinaryFileDownloadService} from '../../../services/local-binary-file-download.service';
import {WebSocketNotificationsService} from '../../notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from '../../../core/services/notifications/internal-notification.enum';
import {analogComponentTypesToOptions, enumToOptions} from '../../../core/services/data-mapping.utils';
import {
  DigitalComponentOperationResult,
  DigitalComponentOperationType,
  DigitalComponentView,
} from '../essl-components/services/essl-component-toolbar-buttons.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PrintingOperationType} from '../../../services/user-settings.service';
import {PrintService} from '../print.service';
import {ALLOWED_DIGITAL_COMPONENT_TEMPLATE_TYPES, getExtractionStateTagColor, isReceivedDocumentObject} from '../shared-document.utils';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {
  UpdateComponentTemplateDialogComponent
} from '../essl-components/components/update-component-template-dialog/update-component-template-dialog.component';
import {IczModalService} from '../../../services/icz-modal.service';
import {injectModalData, injectModalRef} from '../../../lib/modals';


@Component({
  selector: 'icz-essl-component-viewer-dialog',
  templateUrl: './essl-component-viewer-dialog.component.html',
  styleUrls: ['./essl-component-viewer-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class EsslComponentViewerDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private apiPaperComponentService = inject(ApiPaperComponentService);
  private apiMediumComponentService = inject(ApiMediumComponentService);
  private apiPhysicalItemComponentService = inject(ApiPhysicalItemComponentService);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private apiConversionRecordService = inject(ApiConversionRecordService);
  private codebookService = inject(CodebookService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private apiDocumentService = inject(ApiDocumentService);
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  private printService = inject(PrintService);
  private modalService = inject(IczModalService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected data = injectModalData<EsslComponentViewerModalData>();

  @ViewChild('moreToggleButton', {static: false})
  moreToggleButton!: ToggleButtonComponent;

  @ViewChild(CdkDrag, {static: false})
  cdkDrag!: CdkDrag;

  formGroupsToCheck!: string[];
  esslComponent!: EsslComponentDto;
  digitalComponentVersion?: DigitalComponentVersionCompleteDto;
  parentDocument: Nullable<OwnDocumentDto | ReceivedDocumentDto>;
  analogComponentTypesOptions!: Option[];

  componentMetadata: ComponentContentControlMetadataDto[] = [];
  isCreatedByTemplate = false;
  emptyTemplateParameters: string[] = [];

  extractionStateOptions = enumToOptions('containerExtractionState', ContainerExtractionState);

  conversionRegisters: Array<ConversionRegisterDto> = [];

  get digitalComponent() {
    return this.esslComponent as DigitalComponentCompleteDto;
  }

  get analogComponent() {
    return this.esslComponent as EsslAnalogComponentDto;
  }

  get isComponentFinal() {
    return this.esslComponent.isFinal;
  }

  get isDigitalComponent() {
    return (this.esslComponent.entityType === EntityType.DIGITAL_COMPONENT);
  }

  get isAnalogComponent() {
    return this.isPaperComponent || this.isMediumOrPhysicalItemComponent;
  }

  get isPaperComponent() {
    return (this.esslComponent.entityType === EntityType.PAPER_COMPONENT);
  }

  get isMediumComponent() {
    return (this.esslComponent.entityType === EntityType.MEDIUM_COMPONENT);
  }

  get isPhysicalItemComponent() {
    return (this.esslComponent.entityType === EntityType.PHYSICAL_ITEM_COMPONENT);
  }

  get isMediumOrPhysicalItemComponent() {
    return (this.isMediumComponent || this.isPhysicalItemComponent);
  }

  get digitalComponentVersionId(): Nullable<number> {
    if (this.isDigitalComponent) {
      return this.digitalComponentVersion?.id;
    }
    else if (this.isPaperComponent) {
      return (this.analogComponent as PaperComponentDto).digitalRenditionId;
    }
    else {
      return null;
    }
  }

  get isLocked() {
    return this.esslComponent.availability === EsslComponentAvailability.LOCKED;
  }

  get hasDigitalComponentVersionId() {
    return Boolean(this.digitalComponentVersionId);
  }

  get digitalComponentVersionNumber() {
    return this.digitalComponentVersion?.version;
  }

  get digitalComponentVersionFileName() {
    return this.digitalComponentVersion?.fileName!;
  }

  get digitalComponentMimeType() {
    return this.digitalComponentVersion?.mimeType;
  }

  get digitalComponentVersionFileIcon() {
    return getComponentIcon(this.esslComponent.entityType!, this.digitalComponentVersion?.digitalComponentVersionTypeFlag!, !isNil(this.digitalComponentVersion?.extractionState));
  }

  get canUserShowComponentContent() {
    if (isNil(this.data.hideComponentContent)) {
      return isAuthorizedOperationGranted(this.data.authorizationResult, ComponentAuthorizedOperation.COMPONENT_SHOW_CONTENT);
    } else {
      return this.data.hideComponentContent;
    }
  }

  get canUserModifyComponentProfile() {
    return isAuthorizedOperationGranted(this.data.authorizationResult, ComponentAuthorizedOperation.COMPONENT_MODIFY_PROFILE);
  }

  get descriptionLabel() {
    if (this.isMediumComponent) {
      return 'Popis nosiče';
    } else if (this.isPhysicalItemComponent) {
      return 'Popis předmětu';
    } else {
      return 'Popis komponenty';
    }
  }

  get physicalTypeLabel() {
    if (this.isMediumComponent) {
      return 'Druh technického nosiče';
    } else if (this.isPhysicalItemComponent) {
      return 'Druh fyzického předmětu';
    } else {
      return 'Druh komponenty';
    }
  }

  get isReadonly() {
    return (this.data.isReadonly && !this.esslComponent.currentUserCirculationTaskId) ?? false;
  }

  get isReceivedDocument() {
    return isReceivedDocumentObject(this.parentDocument);
  }

  get hasExecutionDateClause() {
    return Boolean(this.digitalComponent.executionDateClause);
  }

  get conversionRegistersExists() {
    return Boolean(this.conversionRegisters.length);
  }

  get conversionTooltip() {
    if (this.isPaperComponent || this.isDigitalComponent) {
      const componentOrigin = (this.esslComponent as PaperComponentDto | DigitalComponentDto).originType;

      if (componentOrigin === DigitalComponentOrigin.DIGITAL_AUTH_CONVERSION || componentOrigin === AnalogComponentOrigin.ANALOG_AUTH_CONVERSION) {
        return 'Výstup autorizované konverze';
      } else if (componentOrigin === DigitalComponentOrigin.ANONYMIZATION) {
        return 'Výstup anonymizace';
      } else {
        return 'Výstup konverze';
      }
    }
    else {
      return '';
    }
  }

  get allParametersFilled() {
    return this.emptyTemplateParameters.length === 0;
  }

  get emptyParametersError() {
    if (this.isCreatedByTemplate) {
      if (!this.allParametersFilled) {
        return `${this.translateService.instant('K nasledujícím parametrům šablony nejsou přiřazené hodnoty')}: ${this.emptyTemplateParameters.join(', ')}`;
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  digitalComponentChanged = false;

  form = new IczFormGroup({
    // all kinds of components
    label: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    description: new IczFormControl<Nullable<string>>(null),
    originType: new IczFormControl<Nullable<AnalogComponentOrigin | DigitalComponentOrigin>>(null),
    relationType: new IczFormControl<Nullable<EsslComponentRelation>>(null, [IczValidators.required()]),
    isFinal: new IczFormControl<Nullable<boolean>>(null),
    digitalComponentVersionId: new IczFormControl<Nullable<string>>(null),
    // all analogs
    count: new IczFormControl<Nullable<number>>(null),
    // paper component only
    protectiveElementSet: new IczFormControl<Nullable<AnalogProtectiveElement[]>>(null),
    compositionType: new IczFormControl<Nullable<AnalogCompositionType>>(null),
    numberOfSheets: new IczFormControl<Nullable<number>>(null),
    // medium or physicalItem component only
    type: new IczFormControl<Nullable<string>>(null),
  });

  downloadUrl!: string;

  originTypeOptions: Option[] = [];
  esslComponentRelationOptions: Option[] = [];
  protectiveElementOptions: Option[] = [];

  showAdvancedMetadata = false;
  digitalComponentVersionFileContents: Nullable<BinaryWithCharset>;
  consignmentId: Nullable<number>;

  get isMaximized() {
    return this.modalRef.isMaximized;
  }

  get isContainer() {
    return !isNil(this.digitalComponentVersion?.extractionState);
  }

  get isContainerExtracted() {
    return this.digitalComponentVersion?.extractionState === ContainerExtractionState.EXTRACTED ||
      this.digitalComponentVersion?.extractionState === ContainerExtractionState.EXTRACTED_WITHOUT_CONTENT;
  }

  readonly DigitalComponentView = DigitalComponentView;
  readonly getExtractionStateTagColor = getExtractionStateTagColor;

  private downloadDigitalContent() {
    if (!this.digitalComponentVersionId) return;

    if (this.isPaperComponent) {
      this.remoteBinaryFileDownloadService.fetchPaperComponentDigitalRendition(this.esslComponent.id!
      ).subscribe(binaryWithCharset => {
        this.digitalComponentVersionFileContents = binaryWithCharset;
        this.downloadUrl = this.localBinaryFileDownloadService.constructDownloadUrl(binaryWithCharset.buffer, this.digitalComponentVersionFileName!, 'application/octet-stream');
      });
    } else {
      if (!isNil(this.consignmentId)) {
        this.remoteBinaryFileDownloadService.fetchDigitalComponentVersionWithinConsignment(
            this.consignmentId,
            this.esslComponent.id!,
            this.digitalComponentVersionId
        ).subscribe(binaryWithCharset => {
          this.digitalComponentVersionFileContents = binaryWithCharset;
          this.downloadUrl = this.localBinaryFileDownloadService.constructDownloadUrl(binaryWithCharset.buffer, this.digitalComponentVersionFileName!, 'application/octet-stream');
        });
      } else {
        this.remoteBinaryFileDownloadService.fetchDigitalComponentVersion(
          this.digitalComponentVersionId
        ).subscribe(binaryWithCharset => {
          this.digitalComponentVersionFileContents = binaryWithCharset;
          this.downloadUrl = this.localBinaryFileDownloadService.constructDownloadUrl(binaryWithCharset.buffer, this.digitalComponentVersionFileName!, 'application/octet-stream');
        });

        this.isCreatedByTemplate = !isNil(this.digitalComponent.digitalComponentTemplateId);
        const filenameParts = this.digitalComponentVersionFileName.split('.');
        const fileExtension = `.${filenameParts[filenameParts.length - 1]}`;
        if (this.isCreatedByTemplate && ALLOWED_DIGITAL_COMPONENT_TEMPLATE_TYPES.includes(fileExtension)) {
          this.apiDigitalComponentService.digitalComponentGetComponentContentControlMetadata({id: this.digitalComponentVersionId}).subscribe(componentMetadata => {
            this.componentMetadata = componentMetadata;
            for (const param of this.componentMetadata) {
              if (param.empty) {
                this.emptyTemplateParameters.push(String(param.placeHolderName));
              }
            }
          });
        }
      }
    }
  }

  private setAutocompleteOptions() {
    if (this.isDigitalComponent) {
      this.originTypeOptions = enumToOptions('digitalComponentOrigin', DigitalComponentOrigin);
    } else if (this.isAnalogComponent) {
      this.originTypeOptions = enumToOptions('analogComponentOrigin', AnalogComponentOrigin);
    }
    this.esslComponentRelationOptions = enumToOptions('esslComponentRelation', EsslComponentRelation);
    if (this.isAnalogComponent) {
      this.esslComponentRelationOptions = this.esslComponentRelationOptions.filter(o => o.value === EsslComponentRelation.MAIN || o.value === EsslComponentRelation.ENCLOSURE);
    }

    this.protectiveElementOptions = enumToOptions('analogProtectiveElement', AnalogProtectiveElement);
  }

  private getDigitalComponentVersionMetadataForPaperComponent() {
    if (this.isAnalogComponent) {
      if (this.digitalComponentVersionId) {
        this.apiDigitalComponentVersionService.digitalComponentVersionGetById({digitalComponentVersionId: this.digitalComponentVersionId})
          .subscribe(digitalComponentVersion => {
            this.digitalComponentVersion = digitalComponentVersion;
          });
      } else {
        this.showAdvancedMetadata = true; // if a paper component has no digital rendition, let's show what else we can show
        setTimeout(() => {
          this.moreToggleButton.toggle();
        });
      }
    }
  }

  setHardValuesForMainComponent(paperControl: IczFormGroup): void {
    paperControl.get('relationType')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(relationType => {
      if (relationType === EsslComponentRelation.MAIN) {
        paperControl.get('compositionType')!.setValue(AnalogCompositionType.SHEETS);
        paperControl.get('count')!.setValue(1);
      }
    });
  }

  findAllConversionRegistersForThisComponent() {
    let conversionRegistersAsSource: ConversionRegisterSearchRequest;
    if (this.isDigitalComponent) {
      conversionRegistersAsSource = {sourceComponentVersionId: this.digitalComponentVersionId};
    }
    else {
      conversionRegistersAsSource = {sourceComponentId: this.esslComponent.id};
    }

    let conversionRegistersAsTarget: ConversionRegisterSearchRequest;
    if (this.isDigitalComponent) {
      conversionRegistersAsTarget = {targetComponentVersionId: this.digitalComponentVersionId};
    }
    else {
      conversionRegistersAsTarget = {targetComponentId: this.esslComponent.id};
    }

    combineLatest([
      this.apiConversionRecordService.conversionRecordSearch({body: conversionRegistersAsSource}),
      this.apiConversionRecordService.conversionRecordSearch({body: conversionRegistersAsTarget}),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([asSource, asTarget]) => {
      this.conversionRegisters = [...asSource, ...asTarget];

      this.resolveFormDisableState(this.conversionRegisters);
    });
  }

  ngOnInit() {
    this.esslComponent = this.data.esslComponent;
    this.consignmentId = this.data.consignmentId;

    if (this.isDigitalComponent) {
      if (this.data.versionId) {
        this.digitalComponentVersion = this.digitalComponent.digitalComponentVersions!.find(v => v.id === this.data.versionId)!;
      }
      else if (this.data.versionNumber) {
        this.digitalComponentVersion = this.digitalComponent.digitalComponentVersions!.find(v => v.version === this.data.versionNumber)!;
      }
      else {
        throw new Error('Please set either versionId or versionNumber, otherwise digitalComponent viewer will not work reliably.');
      }
    }
    else if (this.isPaperComponent) {
      const contentDigitalComponentVersionId = (this.esslComponent as PaperComponentDto).digitalRenditionId;

      if (contentDigitalComponentVersionId) {
        this.apiDigitalComponentVersionService.digitalComponentVersionGetById({
          digitalComponentVersionId: contentDigitalComponentVersionId,
        }).subscribe(digitalRendition => {
          this.digitalComponentVersion = digitalRendition;
        });
      }
    }

    // todo 12160 fetch conversionRegister by COMPONENT ID if PAPER, or by COMPONENTVERSION ID if DIGITAL
    /*this.apiConversionRecordService.conversionRecordFindTargetArtefactId({artefactId: this.esslComponent.id!}).subscribe(conversionRegisters => {
      if (conversionRegisters.length) {
        this.conversionRegisters = conversionRegisters;
      }
    });*/
    this.findAllConversionRegistersForThisComponent();

    if (this.esslComponent.documentId) {
      this.loadingIndicatorService.doLoading(
        this.apiDocumentService.documentFindById({
          id: this.esslComponent.documentId,
        }),
        this
      ).subscribe(parentDocument => {
        this.parentDocument = parentDocument as OwnDocumentDto | ReceivedDocumentDto;
      });
    }

    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.form.patchValue(this.esslComponent);
    this.modalRef.addPanelClass('icz-modal-dialog');


    merge(
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_SUCCESS),
    ).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(message => {
      const digitalComponentIds = message.parameters.map(parameterSet => Number(parameterSet[InternalNotificationKey.DIGITAL_COMPONENT_ID]));

      if (this.isDigitalComponent && digitalComponentIds.includes(this.esslComponent.id!)) {
        this.modalRef.forceClose(false);
      }
    });

    this.setAutocompleteOptions();
    this.getDigitalComponentVersionMetadataForPaperComponent();
    this.downloadDigitalContent();
    this.setHardValuesForMainComponent(this.form);
    if (this.isMediumOrPhysicalItemComponent) {
      this.form.get('type')!.addValidators([IczValidators.required()]);
    }

    this.codebookService.analogComponentTypes().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((types:AnalogComponentTypeDto[]) => {
      const form = this.isPhysicalItemComponent ? AnalogComponentForm.ITEM : AnalogComponentForm.MEDIUM;
      this.analogComponentTypesOptions = analogComponentTypesToOptions(types, form, this.translateService);
    });
  }

  resolveFormDisableState(conversionRegisters: ConversionRegisterDto[]) {
    const originType = (this.esslComponent as DigitalComponentDto | PaperComponentDto).originType;

    const isTargetComponentInAuthConversion = originType === DigitalComponentOrigin.DIGITAL_AUTH_CONVERSION || originType === AnalogComponentOrigin.ANALOG_AUTH_CONVERSION;
    const isSourceComponentInAuthConversion = Boolean(conversionRegisters.find(c => {
      return ((c.sourceComponentId === this.esslComponent.id || c.sourceComponentVersionId === this.digitalComponentVersionId) &&
        (c.type === 'AUTH_PAPER_TO_ELECTRONIC_FORM' || c.type === 'AUTH_ELECTRONIC_TO_PAPER_FORM'));
    }));

    if (!this.canUserModifyComponentProfile || this.data.isReadonly) {
      this.form.disable();
    }
    else {
      if (this.isDigitalComponent || this.isPaperComponent) {
        if (this.isReceivedDocument || isTargetComponentInAuthConversion || isSourceComponentInAuthConversion) {
          this.form.get('isFinal')!.disable();
          this.form.get('originType')!.disable();
        }
        if (originType === DigitalComponentOrigin.ANONYMIZATION) {
          this.form.get('originType')!.disable();
        }
        if (this.isMediumOrPhysicalItemComponent || this.isReceivedDocument) {
          this.form.get('relationType')!.disable();
        }
      }
    }
  }

  toggleMaximize() {
    this.cdkDrag.reset();
    this.modalRef.toggleMaximize();
  }

  close() {
    this.modalRef.close(this.digitalComponentChanged);
  }

  saveChanges() {
    let updateRequest$ = new Observable();
    const formValue = this.form.value;

    if (this.isDigitalComponent) {
      const requestDto: DigitalComponentMetadataUpdateDto = {
        description: formValue.description,
        isFinal: formValue.isFinal!,
        label: formValue.label,
        originType: formValue.originType as DigitalComponentOrigin,
        relationType: formValue.relationType!,
      };

      updateRequest$ = this.apiDigitalComponentService.digitalComponentUpdateMetadata({
        body: requestDto,
        id: this.esslComponent.id!,
      });
    } else if (this.isPaperComponent) {
      const requestDto: PaperComponentUpdateDto = {
        ...this.esslComponent,
        compositionType: formValue.compositionType!,
        count: formValue.count,
        description: formValue.description,
        isFinal: formValue.isFinal!,
        label: formValue.label,
        numberOfSheets: formValue.numberOfSheets,
        originType: formValue.originType as AnalogComponentOrigin,
        protectiveElementSet: formValue.protectiveElementSet,
        relationType: formValue.relationType!,
      };

      updateRequest$ = this.apiPaperComponentService.paperComponentUpdate({
        body: requestDto,
        id: this.esslComponent.id!,
      });
    } else if (this.isMediumComponent) {
      const requestDto: MediumComponentUpdateDto = {
        ...this.esslComponent,
        count: formValue.count,
        description: formValue.description,
        isFinal: formValue.isFinal!,
        label: formValue.label,
        relationType: formValue.relationType!,
        type: formValue.type!,
      };

      updateRequest$ = this.apiMediumComponentService.mediumComponentUpdate({
        body: requestDto,
        id: this.esslComponent.id!,
      });
    } else if (this.isPhysicalItemComponent) {
      const requestDto: PhysicalItemComponentUpdateDto = {
        ...this.esslComponent,
        count: formValue.count,
        description: formValue.description,
        isFinal: formValue.isFinal!,
        label: formValue.label,
        relationType: formValue.relationType!,
        type: formValue.type!,
      };

      updateRequest$ = this.apiPhysicalItemComponentService.physicalItemComponentUpdate({
        body: requestDto,
        id: this.esslComponent.id!,
      });
    }

    this.loadingIndicatorService.doLoading(updateRequest$, this).subscribe(_ => {
      this.digitalComponentChanged = true;
      this.form.markAsPristine();
      this.modalRef.close(true);
    });
  }

  cancelChanges() {
    this.close();
  }

  download() {
    if (this.downloadUrl && this.digitalComponentVersionFileName) {
      this.localBinaryFileDownloadService.downloadBinaryFileFromUrl(
        this.downloadUrl,
        this.digitalComponentVersionFileName
      );
    }
  }

  digitalComponentOperationCompleted($event: DigitalComponentOperationResult) {
    switch ($event.operationType) {
      case DigitalComponentOperationType.COMPONENT_LOCKED:
      case DigitalComponentOperationType.COMPONENT_UNLOCKED:
        this.digitalComponentChanged = true;
        break;
      case DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION:
      case DigitalComponentOperationType.COMPONENT_VERSION_ADDED:
      case DigitalComponentOperationType.COMPONENT_CHECKED_IN:
      case DigitalComponentOperationType.COMPONENT_CHECKED_OUT:
      case DigitalComponentOperationType.DIGITAL_RENDITION_ADDED:
      case DigitalComponentOperationType.CIRCULATION_ACTION_COMPLETED:
      case DigitalComponentOperationType.SIGN_DIGITAL_COMPONENT:
      case DigitalComponentOperationType.MARK_DIGITAL_COMPONENT:
      case DigitalComponentOperationType.ADD_TIMESTAMP:
      case DigitalComponentOperationType.MANUAL_DIGITAL_COMPONENT_VALIDATION:
      case DigitalComponentOperationType.COMPONENTS_DELEDED:
      case DigitalComponentOperationType.COMPONENT_DELETED:
      case DigitalComponentOperationType.EXECUTION_CLAUSE_CREATED:
      case DigitalComponentOperationType.COMPONENT_TEMPLATE_UPDATED:
        this.digitalComponentChanged = true;
        this.close();
        break;
      case DigitalComponentOperationType.EXTRACTION:
        this.close();
        break;
    }
  }

  toggleAdvancedMetadata() {
    this.showAdvancedMetadata = !this.showAdvancedMetadata;
  }

  parentDocumentLinkClicked() {
    this.modalRef?.forceClose(null);
  }

  printComponentLabel() {
    this.apiPaperComponentService.paperComponentPrintLabel({id: this.esslComponent.id!}).pipe(take(1)).subscribe(labels => {
      this.printService.printText(PrintingOperationType.LABEL, labels).subscribe();
    });
  }

  openUpdateParamsDialog() {
    this.modalService.openComponentInModal<boolean, DigitalComponentCompleteDto>({
      component: UpdateComponentTemplateDialogComponent,
      modalOptions: {
        titleTemplate: 'Aktualizace parametrů v obsahu komponenty',
        width: 1000,
        height: 800,
      },
      data: this.digitalComponent,
    }).subscribe(result => {
      if (result) {
        this.modalRef.close(result);
      }
    });
  }

}
