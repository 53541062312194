/* tslint:disable */
/* eslint-disable */

/**
 * Registry office transfer task state
 */
export enum Essl9972RegistryOfficeTransferActivityState {
  TO_TAKE_OVER = 'TO_TAKE_OVER',
  TO_RESOLVE_ISSUES = 'TO_RESOLVE_ISSUES',
  REJECTED_BY_REGISTRY_OFFICE = 'REJECTED_BY_REGISTRY_OFFICE',
  TAKEN_OVER = 'TAKEN_OVER'
}
