import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {injectModalData, injectModalRef} from '../../../../../lib/modals';
import {ConversionClauseVerification, ConversionClauseVerificationStatus} from '../../models/digital-components.model';
import {
  BinaryWithCharset,
  RemoteBinaryFileDownloadService
} from '../../../../../services/remote-binary-file-download.service';
import {AlertStatus} from '../../../../essentials/alert/alert.component';
import {
  ApiConversionService,
  ApiDigitalComponentService,
  ApiPaperComponentService,
  CheckConversionValidityResponseDto
} from '|api/component';
import {EntityType} from '|api/commons';
import {Observable} from 'rxjs';
import {LocalBinaryFileDownloadService} from '../../../../../services/local-binary-file-download.service';

interface VerifyClauseStatusAlert {
  severity: AlertStatus,
  heading: string,
  label: string,
}

interface FileContents {
  fileContents: BinaryWithCharset,
  fileName: string,
  mimeType: string,
}

@Component({
  selector: 'icz-verify-conversion-clause-dialog',
  templateUrl: './verify-conversion-clause-dialog.component.html',
  styleUrls: ['./verify-conversion-clause-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyConversionClauseDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  protected apiPaperComponentService = inject(ApiPaperComponentService);
  protected apiConversionService = inject(ApiConversionService);
  protected apiDigitalComponentService = inject(ApiDigitalComponentService);
  protected modalRef = injectModalRef<Nullable<void>>();
  protected data = injectModalData<ConversionClauseVerification>();
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);

  conversionValidityResponse: Nullable<CheckConversionValidityResponseDto>;
  conversionClauseWithBinary: Nullable<FileContents>;

  get clauseStatus(): VerifyClauseStatusAlert {
    switch (this.conversionValidityResponse?.status) {
      case ConversionClauseVerificationStatus.VALID:
        return {
          severity: 'success',
          heading: 'Validní',
          label: 'Dokument vznikl provedením konverze dokumentů podle zákona č. 300/2008 Sb., o elektronických úkonech a autorizované konverzi dokumentů, v platném znění.\nPožadavek byl zpracován korektně.'
        };
      case ConversionClauseVerificationStatus.INVALID_AUTHORIZATION:
        return {severity: 'error', heading: 'Nevalidní přístup', label: 'Doložka neexistuje, nebo k ní nemáte přístup.'};
      case ConversionClauseVerificationStatus.INVALID_DATA:
        return {severity: 'error', heading: 'Nevalidní data', label: 'Vstupní data nejsou v požadovaném formátu.'};
      case ConversionClauseVerificationStatus.SYSTEM_ERROR:
        return {
          severity: 'error',
          heading: 'Systémová chyba',
          label: 'Systémová chyba. Je potřeba počkat, než bude chyba vyřešena, případně požadavek zkusit zaslat znovu.'
        };
      default:
        return {severity: 'info', heading: '', label: ''};
    }
  }

  get isDigitalComponent() {
    return this.data?.esslComponent?.entityType === EntityType.DIGITAL_COMPONENT;
  }

  get isPaperComponent() {
    return this.data?.esslComponent?.entityType === EntityType.PAPER_COMPONENT;
  }

  get esslComponentId() {
    return this.data?.esslComponent?.id!;
  }

  readonly ConversionClauseVerificationStatus = ConversionClauseVerificationStatus;

  ngOnInit() {
    const req$ = this.apiConversionService.conversionCheckAuthConversionClause({digitalComponentId: this.esslComponentId});
    this.loadingService.doLoading(req$, this).subscribe(conversionValidityResponse => {
      this.conversionValidityResponse = conversionValidityResponse;
    });

  }

  displayClause() {
    let req$!: Observable<BinaryWithCharset>;
    if (this.isPaperComponent) {
      req$ = this.remoteBinaryFileDownloadService.fetchPaperAuthConversionClause(this.esslComponentId);
    }
    else {
      req$ = this.remoteBinaryFileDownloadService.fetchDigitalAuthConversionClause(this.esslComponentId);
    }

    this.loadingService.doLoading(
      req$,
      this,
    ).subscribe(conversionClause => {
      this.conversionClauseWithBinary = {
        fileContents: conversionClause,
        fileName: `Doložka k autorizované konverzi z moci úřední ke komponentě ${this.data.esslComponent.label}.pdf`,
        mimeType: 'application/pdf'
      };
      this.changeDetectorRef.detectChanges();
    });
  }

  download() {
    const downloadUrl = this.localBinaryFileDownloadService.constructDownloadUrl(this.conversionClauseWithBinary!.fileContents.buffer, this.conversionClauseWithBinary!.fileName!, 'application/octet-stream');
    this.localBinaryFileDownloadService.downloadBinaryFileFromUrl(
      downloadUrl,
      `Doložka k autorizované konverzi z moci úřední ke komponentě ${this.data.esslComponent.label}.`
    );
  }

  print() {
    // todo 11018 print
  }

  cancel() {
    this.modalRef.close(null);
  }

}
