<div (keydown.arrowUp)="optionsList?.selectPrevOption()"
  (keydown.arrowDown)="optionsList?.selectNextOption()"
  (keydown.enter)="optionsList?.selectCurrentOption()">

  <div class="row selector-header" (click)="toggleOpen()">
    <div class="icz-body-1">
      {{ (selectedOption?.label || placeholder) | translate }}
    </div>
    @if (!fieldDisabled) {
      <icz-icon class="ml-8" size="small" svgIcon="expand_more"></icz-icon>
    }
  </div>
  @if (isOpen) {
    <div>
      <icz-options-list [options]="_visibleOptions"
        [isMultiselect]="false"
        [selectedOptions]="selectedOption ? [selectedOption] : []"
        [listItemTemplate]="listItemTemplate"
        [maxListHeight]="500"
        (selectionChanged)="selectionChanged($event)">
      </icz-options-list>
    </div>
  }

  <ng-template #listItemTemplate let-context>
    <icz-form-autocomplete-list-text-item
      [option]="context.option">
    </icz-form-autocomplete-list-text-item>
  </ng-template>
</div>
