import {ChangeDetectorRef, DestroyRef, Directive, ElementRef, inject, Input, OnInit} from '@angular/core';

import {ControlContainer, ControlValueAccessor} from '@angular/forms';
import {FormField} from './form-field';
import {IczFormContainerDirective} from './icz-form.directive';
import {IczFormControl} from './icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Directive()
export class GenericValueAccessor implements ControlValueAccessor, OnInit {

  protected element = inject(ElementRef);
  protected controlContainer = inject(ControlContainer);
  protected cd = inject(ChangeDetectorRef);
  protected iczFormContainer = inject(IczFormContainerDirective);
  protected destroyRef = inject(DestroyRef);

  @Input()
  formControlName!: string;

  protected component!: FormField<any>;

  ngOnInit(): void {
    if (!this.component || !this.controlContainer || !this.formControlName) {
      throw new Error(`FormFieldValueAccessorDirective can't find FormField and FormGroup for ${this.element.nativeElement.outerHTML}`);
    }
    else {
      this.initFormControl();

      this.iczFormContainer.formGroupChanged$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(_ => this.initFormControl());

      this.component.valueChange.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(newValue => this.onChange(newValue as string));

      this.component.blur.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(_ => this.onTouched());
    }
  }

  initFormControl() {
    // such a typecast is fine because formControlName is
    // always placed on a field associated to a FormControl
    this.component.control = this.controlContainer.control!.get(this.formControlName) as IczFormControl;
    if (this.component.onControlAssigned) this.component.onControlAssigned();
  }

  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.component.value);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.component._fieldDisabledFromReactiveModel = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(newValue: string): void {
    this.component.value = newValue;
    this.cd.detectChanges();
  }

  private onChange = (_: string) => {};
  private onTouched = () => {};

}
