<form [formGroup]="form">
  <icz-org-structure-selection-loader
    label="Zvolit skupinu pro předání"
    [parentForm]="form"
    (groupSelected)="applySavedGroup($event)"
  ></icz-org-structure-selection-loader>
  <icz-section label="Komu dát na vědomí">
    <icz-org-structure-selector
      [form]="form"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      controlName="orgStructureElementIds"
      [isMultiselect]="true"
      label="Funkční místa nebo organizační jednotky"
    ></icz-org-structure-selector>
    <icz-org-structure-selection-saver
      [parentForm]="form"
      [group]="groupForSaving"
    ></icz-org-structure-selection-saver>
  </icz-section>

  <div class="row">
    <div class="col grow">
      <icz-section label="Instrukce">
        <icz-form-field formControlName="targetTaskInstruction"
                        label="Instrukce"
                        [autoSizeMax]="3"
        ></icz-form-field>
      </icz-section>
      <!--todo(mh) uncomment when printing of handover protocol is defined-->
      <!--<icz-section *ngIf="initEntity?.documentForm === DocumentForm.ANALOG" testingFeature label="Tisk">
        <icz-checkbox formControlName="printHandoverProtocol"
                      label="Tisknout předávací protokol"
        ></icz-checkbox>
      </icz-section>-->
    </div>
    <div class="col grow">
      <icz-section label="Termín">
        <icz-date-picker formControlName="deadline"
                         label="Termín, dokdy potřebujete mít schváleno"
                         [selectableDates]="deadlineValidator"
        ></icz-date-picker>
      </icz-section>
    </div>
  </div>
</form>
