/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DateSeriesCreatedEntitiesDto } from '../models/date-series-created-entities-dto';
import { PageOfStatisticSummaryRowDto } from '../models/page-of-statistic-summary-row-dto';
import { statisticsGetCreatedForDimensionsAggregation } from '../fn/statistics/statistics-get-created-for-dimensions-aggregation';
import { StatisticsGetCreatedForDimensionsAggregation$Params } from '../fn/statistics/statistics-get-created-for-dimensions-aggregation';
import { statisticsGetDateSeriesCreatedAggregation } from '../fn/statistics/statistics-get-date-series-created-aggregation';
import { StatisticsGetDateSeriesCreatedAggregation$Params } from '../fn/statistics/statistics-get-date-series-created-aggregation';
import { statisticsGetStatisticTilesAsync } from '../fn/statistics/statistics-get-statistic-tiles-async';
import { StatisticsGetStatisticTilesAsync$Params } from '../fn/statistics/statistics-get-statistic-tiles-async';
import { StatisticTileDto } from '../models/statistic-tile-dto';


/**
 * Statistics api providing elasticsearch aggregations
 */
@Injectable({ providedIn: 'root' })
export class ApiStatisticsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `statisticsGetStatisticTilesAsync()` */
  static readonly StatisticsGetStatisticTilesAsyncPath = '/elastic/statistics/get-statistic-tiles-async';

  /**
   * Asynchronously retrieve required statistic tiles and return them to user by websocket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsGetStatisticTilesAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetStatisticTilesAsync$Response(params: StatisticsGetStatisticTilesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<StatisticTileDto>> {
    return statisticsGetStatisticTilesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Asynchronously retrieve required statistic tiles and return them to user by websocket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsGetStatisticTilesAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetStatisticTilesAsync(params: StatisticsGetStatisticTilesAsync$Params, context?: HttpContext): Observable<StatisticTileDto> {
    return this.statisticsGetStatisticTilesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatisticTileDto>): StatisticTileDto => r.body)
    );
  }

  /** Path part for operation `statisticsGetCreatedForDimensionsAggregation()` */
  static readonly StatisticsGetCreatedForDimensionsAggregationPath = '/elastic/statistics/dimensions-created';

  /**
   * Get creation statistic for dimensions of given entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsGetCreatedForDimensionsAggregation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetCreatedForDimensionsAggregation$Response(params: StatisticsGetCreatedForDimensionsAggregation$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOfStatisticSummaryRowDto>> {
    return statisticsGetCreatedForDimensionsAggregation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get creation statistic for dimensions of given entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsGetCreatedForDimensionsAggregation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetCreatedForDimensionsAggregation(params: StatisticsGetCreatedForDimensionsAggregation$Params, context?: HttpContext): Observable<PageOfStatisticSummaryRowDto> {
    return this.statisticsGetCreatedForDimensionsAggregation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfStatisticSummaryRowDto>): PageOfStatisticSummaryRowDto => r.body)
    );
  }

  /** Path part for operation `statisticsGetDateSeriesCreatedAggregation()` */
  static readonly StatisticsGetDateSeriesCreatedAggregationPath = '/elastic/statistics/date-series-created';

  /**
   * Get creation date series statistic for given entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsGetDateSeriesCreatedAggregation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetDateSeriesCreatedAggregation$Response(params: StatisticsGetDateSeriesCreatedAggregation$Params, context?: HttpContext): Observable<StrictHttpResponse<DateSeriesCreatedEntitiesDto>> {
    return statisticsGetDateSeriesCreatedAggregation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get creation date series statistic for given entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsGetDateSeriesCreatedAggregation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetDateSeriesCreatedAggregation(params: StatisticsGetDateSeriesCreatedAggregation$Params, context?: HttpContext): Observable<DateSeriesCreatedEntitiesDto> {
    return this.statisticsGetDateSeriesCreatedAggregation$Response(params, context).pipe(
      map((r: StrictHttpResponse<DateSeriesCreatedEntitiesDto>): DateSeriesCreatedEntitiesDto => r.body)
    );
  }

}
