import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {get} from 'lodash';
import {FileSizePipe} from '../../essentials/file-size.pipe';
import {LocalizedDatetimePipe} from '../../essentials/datetime.pipe';
import {LocalizedBooleanPipe} from '../../essentials/localized-boolean.pipe';
import {FindInListPipe} from '../../essentials/find-in-list.pipe';
import {TableTemplates} from '../table.models';
import {CodebookFilterDefinition, FilterType} from '../filter.types';
import {LocalizedDatePipe} from '../../essentials/date.pipe';
import {TableColumnsData} from '../table-columns-data';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {distinctUntilChanged} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subscription} from 'rxjs';

@Component({
  selector: 'icz-table-data-cell',
  templateUrl: './table-data-cell.component.html',
  styleUrls: ['./table-data-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FileSizePipe,
    LocalizedDatePipe,
    LocalizedDatetimePipe,
    LocalizedBooleanPipe,
    FindInListPipe,
  ]
})
export class TableDataCellComponent implements IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private fileSizePipe = inject(FileSizePipe);
  private localizedDatePipe = inject(LocalizedDatePipe);
  private localizedDatetimePipe = inject(LocalizedDatetimePipe);
  private localizedBooleanPipe = inject(LocalizedBooleanPipe);
  private findInListPipe = inject(FindInListPipe);
  private translateService = inject(TranslateService);

  @Input({required: true})
  customTableCells: TableTemplates = {};
  @Input({required: true})
  columnId!: string;
  @Input({required: true})
  columnsData!: TableColumnsData<string>;
  @Input({required: true})
  row: Record<string, any> = {};

  private listLoadingStateChangeSubscription: Nullable<Subscription>;

  get column() {
    return this.columnsData?.getColumnById(this.columnId);
  }

  get formattedCellData(): string {
    const value = get(this.row, this.column!.id!);
    const translatePrefix = this.column!.translateWithPrefix ?? '';

    let unformattedValue: string;

    if (!isNil(value)) {
      unformattedValue = `${translatePrefix}${value}`;
    }
    else {
      unformattedValue = this.column!.nullValueText ?? '';
    }

    if (this.column!.filterType === FilterType.FILE_SIZE) {
      return this.fileSizePipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.DATE) {
      return this.localizedDatePipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.DATETIME) {
      return this.localizedDatetimePipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.BOOLEAN) {
      return this.localizedBooleanPipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.CODEBOOK || this.column!.filterType === FilterType.ENUM) {
      let foundOptionLabel = '';

      if (!isNil((this.column as CodebookFilterDefinition).list)) {
        foundOptionLabel = this.findInListPipe.transform(
          value,
          (this.column as CodebookFilterDefinition).list!,
          (this.column as CodebookFilterDefinition).filterConfig?.originId,
        );
      }
      else {
        foundOptionLabel = value;
      }

      if (this.column!.allowTranslation) {
        if (foundOptionLabel === '') {
          return '';
        }
        else if (isNil(foundOptionLabel)) {
          return this.column!.nullValueText ?? '';
        }
        else {
          return this.translateService.instant(`${translatePrefix}${value}`);
        }
      }
      else {
        return foundOptionLabel;
      }
    }
    else {
      if (this.column!.allowTranslation) {
        if (unformattedValue === '') {
          return '';
        }
        else {
          return this.translateService.instant(unformattedValue);
        }
      }
      else {
        return unformattedValue;
      }
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if ((changes.columnsData || changes.columnId) && this.columnsData && this.columnId) {
      this.listLoadingStateChangeSubscription?.unsubscribe();
      this.listLoadingStateChangeSubscription = this.columnsData.listLoadingStatesChange$.pipe(
        distinctUntilChanged(
          (prev, curr) => prev[this.columnId] === false && curr[this.columnId] === true
        ),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        this.cd.markForCheck();
      });
    }
  }

}
