/* eslint-disable @typescript-eslint/quotes */
import {ServicesConfiguration} from './api.controller';

export interface ServiceConfiguration {
  description?: string;
  excludeFromApi?: boolean;
  Authorization?: string;
}

export enum MicroserviceApplication {
  API_GATEWAY = 'api-gateway',
  CONFIG_SERVER = 'config-server',
  SERVICE1 = 'service1',
  AUTH_SERVER = 'auth-server',
  CORE = 'core',
  SCHEDULER = 'scheduler',
  SUBJECT_REGISTER = 'subject-register',
  ORCHESTRATOR = 'orchestrator',

}

export const servicesConfiguration: ServicesConfiguration =
{
  "api-gateway": {
    "excludeFromApi": true
  },
  "config-server": {
    "Authorization": "Basic c3VwZXJhZG1pbjpwYXNzd29yZA==",
    "description": "Config Application"
  },
  "service1": {
    "excludeFromApi": true
  },
  "auth-server": {
    "description": "Auth Server Application"
  },
  "core": {
    "description": "Core Application"
  },
  "scheduler": {
    "description": "Scheduler Application"
  },
  "subject-register": {
    "description": "Subject Register Application"
  },
  "orchestrator": {
    "description": "Orchestrator Application"
  }
};
