import {ChangeDetectionStrategy, Component} from '@angular/core';
import {FileState} from '|api/commons';
import {FilterOperator, FilterParam, SearchParams} from '../../../services/search-api.service';
import {AbstractObjectSelectorComponent} from './abstract-object-selector.component';
import {WITHOUT_FILE_REF_NUMBER} from '../document-toolbar/services/toolbar-common.utils';
import {FileDto} from '|api/document';
import {FILE_OBJECT_CLASSES} from '../shared-document.utils';

@Component({
  selector: 'icz-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSelectorComponent extends AbstractObjectSelectorComponent<FileDto> {

  readonly objectClasses = FILE_OBJECT_CLASSES;
  readonly recentlyVisitedObjectsHeading = 'Naposledy navštívené spisy:';
  readonly additionalFilteringCriteria: FilterParam[] = [
    {
      fieldName: 'fileState',
      operator: FilterOperator.equals,
      value: String([FileState.OPEN]),
    },
  ];
  readonly withoutRefNumberText = WITHOUT_FILE_REF_NUMBER;

  protected findObjects(searchParams: SearchParams) {
    return this.searchService.findFiles(searchParams);
  }

}
