/* tslint:disable */
/* eslint-disable */
export enum SharedFolderAuthorizedOperation {
  SHARED_FOLDER_VIEW_CONTENT = 'SHARED_FOLDER_VIEW_CONTENT',
  SHARED_FOLDER_EDIT_METADATA = 'SHARED_FOLDER_EDIT_METADATA',
  SHARED_FOLDER_INSERT_OBJECT = 'SHARED_FOLDER_INSERT_OBJECT',
  SHARED_FOLDER_REMOVE_OBJECT = 'SHARED_FOLDER_REMOVE_OBJECT',
  SHARED_FOLDER_MOVE_OBJECT = 'SHARED_FOLDER_MOVE_OBJECT',
  SHARED_FOLDER_CREATE_SUBFOLDERS = 'SHARED_FOLDER_CREATE_SUBFOLDERS',
  SHARED_FOLDER_DELETE_FOLDER_AND_SUBFOLDERS = 'SHARED_FOLDER_DELETE_FOLDER_AND_SUBFOLDERS',
  SHARED_FOLDER_MANAGE_SHARING = 'SHARED_FOLDER_MANAGE_SHARING'
}
