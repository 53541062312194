/* tslint:disable */
/* eslint-disable */

/**
 * Property type defined in property definition
 */
export enum PropertyType {
  RUNTIME = 'RUNTIME',
  STARTUP = 'STARTUP'
}
