import {DestroyRef, inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs';
import {IMessage} from '@stomp/stompjs/esm6';
import {RxStomp} from '@stomp/rx-stomp';
import {ENVIRONMENT} from '../environment.service';
import {DebugLoggingService} from '../debug-logging.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum SystemNotificationType {
  SERVICE_REGISTERED = 'SERVICE_REGISTERED',
  SERVICE_CANCELED = 'SERVICE_CANCELED',
}

export interface SystemNotification {
  type: SystemNotificationType;
  serviceName: string; // a name of our service in uppercase
}


@Injectable({
  providedIn: 'root'
})
export class SystemNotificationsService implements OnDestroy {

  private debugLoggingService = inject(DebugLoggingService);
  private destroyRef = inject(DestroyRef);
  private environment = inject(ENVIRONMENT);

  notification$ = new Subject<SystemNotification>();

  private rxStompClient: Nullable<RxStomp> = null;

  private systemMessageHandler = (msg: IMessage) => {
    if (msg.body) {
      const parsedBody: {key: string, serviceName: string} = JSON.parse(msg.body);

      this.debugLoggingService.logStompMessage({
        topic: '/topic/system',
        payload: parsedBody,
      });

      this.notification$.next({
        type: parsedBody.key as SystemNotificationType,
        serviceName: parsedBody.serviceName,
      });
    }
  };

  initialize() {
    this.openConnection();
  }

  ngOnDestroy(): void {
    this.closeConnection();
  }

  private openConnection() {
    if (!this.rxStompClient) {
      this.rxStompClient = new RxStomp();
      this.rxStompClient.configure({
        brokerURL: `${this.environment.eurekaWsUrl}`,
      });

      this.rxStompClient.connected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.debugLoggingService.logStompMessage({
          topic: 'systémové notifikace',
          payload: 'spojení otevřeno',
        });
      });
      this.rxStompClient.activate();

      this.rxStompClient.watch({
        destination: '/topic/system'
      }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(message => {
        this.systemMessageHandler(message);
      });
    }
  }

  private closeConnection() {
    if (!this.rxStompClient) return;

    this.rxStompClient.deactivate().then(() => {
      this.debugLoggingService.logStompMessage({
        topic: 'systémové notifikace',
        payload: 'spojení uzavřeno',
      });
    });
  }

}
