/* tslint:disable */
/* eslint-disable */

/**
 * External Notification Type (see http://dev-eamodel.ecm.i.cz/g2/index.htm?guid={3ACBF2DA-420B-43cf-B238-D152D641E4AC})
 */
export enum ExternalNotificationType {
  MESSAGE_DELIVERED = 'MESSAGE_DELIVERED',
  MESSAGE_RECEIVED = 'MESSAGE_RECEIVED',
  MESSAGE_REJECTED_BY_OPERATOR = 'MESSAGE_REJECTED_BY_OPERATOR',
  MESSAGE_REJECTED = 'MESSAGE_REJECTED',
  MESSAGE_FORWARDED = 'MESSAGE_FORWARDED'
}
