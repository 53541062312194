/* tslint:disable */
/* eslint-disable */

/**
 * Delivery confirmation contents
 */
export enum DeliveryConfirmationContents {
  DELIVERY_CONFIRMATION = 'DELIVERY_CONFIRMATION',
  PROOF_OF_LOST = 'PROOF_OF_LOST',
  EMAIL = 'EMAIL',
  DATABOX_CONFIRMATION = 'DATABOX_CONFIRMATION',
  PUBLICATION_CONFIRMATION = 'PUBLICATION_CONFIRMATION',
  INTERNAL_CONFIRMATION = 'INTERNAL_CONFIRMATION',
  PERSONAL_CONFIRMATION = 'PERSONAL_CONFIRMATION'
}
