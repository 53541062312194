<div>
  <icz-alert
    severity="error"
    heading="Dokument není možné vyřídit"
  ></icz-alert>
  <icz-label class="font-bold mt-24 mb-16" label="Aby bylo možné dokument vyřídit, je potřeba upravit nasledující:"></icz-label>
  @if (hasUnresolvedConsignments) {
    <div>
      <div class="row">
        <icz-icon svgIcon="info_blue"></icz-icon>
        <span></span>
      </div>
    </div>
  }
  @if (hasRunningCirculation) {
    <div>
      <div class="row">
        <icz-icon svgIcon="info_blue"></icz-icon>
        <span></span>
      </div>
    </div>
  }
  @if (isNotStateForResolution) {
    <div>
      <div class="row">
        <icz-icon svgIcon="info_blue"></icz-icon>
        <div>
          <span>{{modalData.error.error.errorCode | translate}}</span>
        </div>
      </div>
    </div>
  }
</div>
<icz-form-buttons>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
