import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {ApiOwnConsignmentService, OwnConsignmentBulkHandleOperationsDto} from '|api/sad';
import {GenericOwnConsignment} from '../../../own-consignment-table/model/own-consignment-model';
import {RejectDialogData, RejectDialogResult, RejectDialogType} from './reject-dialog.service';
import {
  isOwnDigitalConsignment,
  isOwnInternalDigitalConsignment,
  isOwnInternalPaperConsignment,
  isOwnOfficeDeskConsignment,
  isOwnPaperConsignment,
} from '../../../own-consignment-table/own-consignment-workflow.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';


interface RejectDialogConfig {
  confirmLabel: string;
  reasonLabel: string;
  confirmReq$: {
    // API returns void observable for DELETE methods, hence Observable<GenericOwnConsignment> | void>
    paper$?: Nullable<Observable<GenericOwnConsignment | void>>,
    digital$?: Nullable<Observable<GenericOwnConsignment | void>>,
    officeDesk$?: Nullable<Observable<GenericOwnConsignment | void>>,
    internalPaper$?: Nullable<Observable<GenericOwnConsignment | void>>,
    internalDigital$?: Nullable<Observable<GenericOwnConsignment | void>>,
    bulkConsignments$?: Nullable<Observable<void>>,
  };
}

@Component({
  selector: 'icz-reject-dialog',
  templateUrl: './reject-dialog.component.html',
  styleUrls: ['./reject-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RejectDialogComponent {

  private modalRef = injectModalRef<Nullable<RejectDialogResult>>();
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected modalData = injectModalData<RejectDialogData>();

  isBulk = this.modalData.consignments?.length > 1;

  form = new IczFormGroup({
    reason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  get consignmentTypeRequest$(): keyof RejectDialogConfig['confirmReq$'] {
    if (this.isBulk) return 'bulkConsignments$';
    else {
      if (isOwnPaperConsignment(this.modalData.consignments[0])) return 'paper$';
      if (isOwnDigitalConsignment(this.modalData.consignments[0])) return 'digital$';
      if (isOwnOfficeDeskConsignment(this.modalData.consignments[0])) return 'officeDesk$';
      if (isOwnInternalPaperConsignment(this.modalData.consignments[0])) return 'internalPaper$';
      if (isOwnInternalDigitalConsignment(this.modalData.consignments[0])) return 'internalDigital$';
      else return 'paper$';
    }
  }

  get rejectDialogConfig(): Nullable<Record<RejectDialogType, RejectDialogConfig>> {
    if (!this.modalData?.consignments?.[0]) return null;

    const reason = this.form.get('reason')!.value;
    const id = this.modalData.consignments[0].id!;
    const singleConsignmentReqData = {id, body: {reason: reason!}};
    const bulkReqData: {
      body: OwnConsignmentBulkHandleOperationsDto
    } = {
      body: {
        bulkDataDto: this.modalData.consignments.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        }),
        reason
      },
    };

    return {
      [RejectDialogType.REJECT]: {
        confirmLabel: 'Vrátit',
        reasonLabel: 'Důvod vrácení',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentRejectPaper(singleConsignmentReqData),
          digital$: this.apiOwnConsignmentService.ownConsignmentRejectDigital(singleConsignmentReqData),
          officeDesk$: this.apiOwnConsignmentService.ownConsignmentRejectOfficeDesk(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentRejectInternalPaper(singleConsignmentReqData),
          internalDigital$: this.apiOwnConsignmentService.ownConsignmentRejectInternalDigital(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkReject(bulkReqData),
        }
      },
      [RejectDialogType.CANCEL]: {
        confirmLabel: 'Stornovat vypravení',
        reasonLabel: 'Důvod stornování',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentCancelPaper(singleConsignmentReqData),
          digital$: this.apiOwnConsignmentService.ownConsignmentCancelDigital(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentCancelInternalPaper(singleConsignmentReqData),
          internalDigital$: this.apiOwnConsignmentService.ownConsignmentCancelInternalDigital(singleConsignmentReqData),
          officeDesk$: this.apiOwnConsignmentService.ownConsignmentCancelOfficeDesk(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkCancel(bulkReqData),
        }
      },
      [RejectDialogType.WITHDRAW_FROM_DISTRIBUTION]: {
        confirmLabel: 'Stáhnout z distribuce',
        reasonLabel: 'Důvod stažení z distribuce',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentWithdrawFromDistributionPaper(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentWithdrawFromDistributionInternalPaper(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkWithdrawFromDistribution(bulkReqData),
        }
      },
      [RejectDialogType.DELETE]: {
        confirmLabel: 'Smazat',
        reasonLabel: 'Důvod smazání',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentDeletePaper(singleConsignmentReqData),
          digital$: this.apiOwnConsignmentService.ownConsignmentDeleteDigital(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentDeleteInternalPaper(singleConsignmentReqData),
          internalDigital$: this.apiOwnConsignmentService.ownConsignmentDeleteInternalDigital(singleConsignmentReqData),
          officeDesk$: this.apiOwnConsignmentService.ownConsignmentDeleteOfficeDesk(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkDelete(bulkReqData),
        }
      },
      [RejectDialogType.UNPOST]: {
        confirmLabel: 'Svěsit',
        reasonLabel: 'Důvod svěšení',
        confirmReq$: {
          officeDesk$: this.apiOwnConsignmentService.ownConsignmentManualUnpostOfficeDesk(singleConsignmentReqData)
        }
      }
    };
  }

  get isDeleteDialogType() {
    return this.modalData.type === RejectDialogType.DELETE;
  }

  submit() {
    this.loadingIndicatorService.doLoading(
      this.rejectDialogConfig![this.modalData.type]!.confirmReq$[this.consignmentTypeRequest$ as keyof RejectDialogConfig['confirmReq$']]! as Observable<GenericOwnConsignment | void>,
      this,
    ).subscribe(ownConsignment => {
      this.modalRef.close({
        resultType: this.modalData.type,
        consignment: ownConsignment,
        reason: this.form.get('reason')!.value,
      } as RejectDialogResult);
    });
  }

  close() {
    this.modalRef.close(null);
  }

}
