import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {BehaviorSubject, filter, forkJoin, of, take} from 'rxjs';
import {Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {ReceivedEpdzMessageProcessingStatus} from '|api/sad';
import {CirculationActivityType, CirculationTaskType, OwnConsignmentStatus} from '|api/commons';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DispatchRoute, FilingOfficeRoute, OfficeDeskRoute} from '../../../enums/documents-routes.enum';
import {getDefaultSearchParams} from '../../table/table.datasource';
import {formatAsLocalIsoDate} from '../../../lib/utils';
import {FilterOperator} from '../../../services/search-api.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {AuthService} from '../../../core/authentication/auth.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {CirculationSearchService} from '../../../services/circulation-search.service';
import {DocumentSearchService} from '../../../services/document-search.service';
import {OwnConsignmentsSearchService} from '../../../services/own-consignments-search.service';
import {FilingOfficeSearchService} from '../../../services/filing-office-search.service';
import {isDispatchOfficer, isFilingOfficer} from '../../../services/user-roles.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {findMenuItemByViewId, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {MenuItemCountViewIds} from '../../../core/routing/menu-item-count-view-ids';


@Component({
  selector: 'icz-dashboard-filing-dispatch',
  templateUrl: './dashboard-filing-dispatch.component.html',
  styleUrls: ['./dashboard-filing-dispatch.component.scss']
})
export class DashboardFilingDispatchComponent implements OnInit {

  private router = inject(Router);
  private documentSearchService = inject(DocumentSearchService);
  private filingOfficeSearchService = inject(FilingOfficeSearchService);
  private ownConsignmentsSearchService = inject(OwnConsignmentsSearchService);
  private currentSessionService = inject(CurrentSessionService);
  private authService = inject(AuthService);
  private codebook = inject(CodebookService);
  private circulationSearchService = inject(CirculationSearchService);
  private destroyRef = inject(DestroyRef);
  private mainMenuCountsService = inject(MainMenuCountsService);

  readonly receivedConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_ALL_CONSIGNMENTS);
  readonly receivePaperConsignmentRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVE_PAPER_CONSIGNMENT);
  readonly receivedDataboxConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_DATABOX_MESSAGES);
  readonly receivedEmailConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_EMAIL_MESSAGES);
  readonly receivedInternalConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.RECEIVED_INTERNAL_CONSIGNMENTS);
  readonly rejectedConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.REJECTED);
  readonly handedOverConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.FILING_OFFICE, FilingOfficeRoute.HANDED_OVER);

  readonly toTakeoverConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.TO_TAKE_OVER);
  readonly takenOverConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.TO_DISPATCH);
  readonly inDistributionConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.IN_DISTRIBUTION);
  readonly toPostConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.OFFICE_DESK, OfficeDeskRoute.TO_POST);

  readonly forDeliveryConfirmationConsignmentsRoute = createAbsoluteRoute(ApplicationRoute.DISPATCH, DispatchRoute.DISPATCH_OFFICE_RECORD_DELIVERY_RESULT);

  receivedConsignmentsCount$ = of(0);
  receivedDataboxConsignmentsCount$ = of(0);
  receivedEmailConsignmentsCount$ = of(0);
  receivedInternalConsignmentsCount$ = of(0);
  rejectedConsignmentsCount$ = of(0);
  handedOverByFilingOfficeConsignmentsCount$ = of(0);

  toTakeoverConsignmentsCount$ = new BehaviorSubject<number>(0);
  takenOverConsignmentsCount$ = new BehaviorSubject<number>(0);
  inDistributionConsignmentsCount$ = new BehaviorSubject<number>(0);
  forDeliveryConfirmationConsignmentsCount$ = new BehaviorSubject<number>(0);

  toBePostedConsignmentsCount$ = this.ownConsignmentsSearchService.countOfficeDeskConsignmentsForDispatchOfficer(
    {...getDefaultSearchParams(), size: 0, filter: [
        {fieldName: 'toPostFrom', value: formatAsLocalIsoDate(new Date()), operator: FilterOperator.equals}
      ]
    },
    [OwnConsignmentStatus.TO_BE_POSTED]
  );

  showFilingOfficerSection = false;
  showDispatchOfficeSection = false;
  showEmailNodesCounter = false;
  showDataboxNodesCounter = false;
  showSheetNodesCounter = false;

  goToPage(route: string) {
    this.router.navigateByUrl(route);
  }

  ngOnInit() {
    this.setDashboard();
  }

  setDashboard() {
    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      filter(_ => this.authService.isAuthenticatedWithFunctionalPosition),
      take(1),
    ).subscribe(currentUserInfo => {
      if (currentUserInfo) {
        if (isFilingOfficer(currentUserInfo)) {
          this.showFilingOfficerSection = true;

          this.loadRejectedReceivedDocumentsCounter();

          forkJoin([
            this.codebook.emailNodesForCurrentFunctionalPosition(),
            this.codebook.databoxNodesForCurrentFunctionalPosition(),
            this.codebook.sheetNodesForCurrentFunctionalPosition(),

          ]).pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe(([emailNodes, databoxNodes, sheetNodes]) => {
            if (emailNodes.length > 0) {
              this.showEmailNodesCounter = true;
              this.receivedEmailConsignmentsCount$ = this.filingOfficeSearchService.findEmailMessages(
                {...getDefaultSearchParams(), size: 0, filter: []},
                ReceivedEpdzMessageProcessingStatus.UNREAD
              ).pipe(
                map(result => result.totalElements)
              );
            }

            if (sheetNodes.length > 0) {
              this.showSheetNodesCounter = true;
              this.receivedConsignmentsCount$ = this.documentSearchService.documentsFilingOfficeForTodayCount();

              this.receivedInternalConsignmentsCount$ = this.filingOfficeSearchService.findInternalMessages(
                {...getDefaultSearchParams(), size: 0, filter: []}
              ).pipe(
                map(result => result.totalElements)
              );
            }

            if (databoxNodes.length > 0) {
              this.showDataboxNodesCounter = true;
              this.receivedDataboxConsignmentsCount$ = this.filingOfficeSearchService.findDataboxMessages(
                {...getDefaultSearchParams(), size: 0, filter: []}
              ).pipe(
                map(result => result.totalElements)
              );
            }

          });

          this.handedOverByFilingOfficeConsignmentsCount$ = this.mainMenuCountsService.menuItemsWithCounts$.pipe(
            takeUntilDestroyed(this.destroyRef),
            map(menuItems => ((menuItems && findMenuItemByViewId(menuItems, MenuItemCountViewIds.FILING_OFFICE_HANDED_OVER)?.count) ?? 0)
          ));
        }

        if (isDispatchOfficer(currentUserInfo)) {
          this.showDispatchOfficeSection = true;
          this.mainMenuCountsService.menuItemsWithCounts$.pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe(menuItems => {
              let toTakeOverCount = 0;
              let takenOverCount = 0;
              let inDistributionCount = 0;
              let forDeliveryConfirmationCount = 0;
              if (menuItems) {
                toTakeOverCount = findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_TAKE_OVER)?.count ?? 0;
                takenOverCount = findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_DISPATCH)?.count ?? 0;
                inDistributionCount = findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_IN_DISTRIBUTION)?.count ?? 0;
                forDeliveryConfirmationCount = findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCH_OFFICE_RECORD_DELIVERY_RESULT)?.count ?? 0;
              }

              this.toTakeoverConsignmentsCount$.next(toTakeOverCount);
              this.takenOverConsignmentsCount$.next(takenOverCount);
              this.inDistributionConsignmentsCount$.next(inDistributionCount);
              this.forDeliveryConfirmationConsignmentsCount$.next(forDeliveryConfirmationCount);
            }
          );
        }
      }
    });
  }

  loadRejectedReceivedDocumentsCounter() {
    const rejectedReceivedDocumentsSearchParams = {...getDefaultSearchParams(), size: 0};
    rejectedReceivedDocumentsSearchParams.filter = [
      {
        fieldName: 'activityType',
        operator: FilterOperator.equals,
        value: CirculationActivityType.DOCUMENT_HANDOVER
      },
      {
        fieldName: 'taskType',
        operator: FilterOperator.equals,
        value: CirculationTaskType.TAKEOVER_REJECTED_BY_INITIATOR
      },
      {
        fieldName: 'isManagementTask',
        operator: FilterOperator.equals,
        value: 'false'
      },
    ];
    this.rejectedConsignmentsCount$ = this.circulationSearchService.findTasksGlobally(rejectedReceivedDocumentsSearchParams).pipe(
      map(result => result.totalElements)
    );
  }
}
