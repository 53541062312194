/* tslint:disable */
/* eslint-disable */

/**
 * The rendition (trigger) type.
 */
export enum FilingRegisterRenditionType {
  MANUAL = 'MANUAL',
  PERIODIC = 'PERIODIC'
}
