import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {ConsignmentType, DataBoxType, DeliveryResultEventType} from '|api/commons';
import {OwnDigitalConsignmentDto} from '|api/sad';
import {Option} from '../../../../../model';
import {GenericOwnConsignment} from '../../model/own-consignment-model';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {namedDtosToOptions} from '../../../../../core/services/data-mapping.utils';


@Component({
  selector: 'icz-consignment-delivery-result',
  templateUrl: './consignment-delivery-result.component.html',
  styleUrls: ['./consignment-delivery-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentDeliveryResultComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);

  @Input({required: true})
  consignment!: GenericOwnConsignment;

  isProofOfDeliveryPopupOpen = false;
  deliveryResultOptions: Option[] = [];

  consigneeDataboxType: Nullable<DataBoxType>;

  get deliveredAt() {
    if (this.isPublicAuthorityDataBoxType(this.consigneeDataboxType)) {
      return this.getEventDate(DeliveryResultEventType.EV_5);
    }
    else {
      return this.getEventDate(DeliveryResultEventType.EV_11) ?? this.getEventDate(DeliveryResultEventType.EV_12) ?? this.getEventDate(DeliveryResultEventType.EV_2);
    }
  }

  readonly ConsignmentType = ConsignmentType;

  ngOnInit(): void {
    const consignmentId = this.consignment?.id;

    if (this.consignment?.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
      this.consigneeDataboxType = (this.consignment as OwnDigitalConsignmentDto).consigneeDataBox?.type;
    }

    if (consignmentId) {
      this.loadingService.doLoading(
        this.codebookService.deliveryResults().pipe(namedDtosToOptions),
        this
      ).subscribe(deliveryResultOptions => {
        this.deliveryResultOptions = deliveryResultOptions;
      });
    }
  }

  private isPublicAuthorityDataBoxType(dataBoxType: Nullable<DataBoxType>) {
    return dataBoxType?.includes('OVM') ?? false;
  }

  private getEventDate(eventType: DeliveryResultEventType) {
    if (this.consignment?.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
      return (this.consignment as OwnDigitalConsignmentDto).deliveryEvents?.find(de => de.eventCode === eventType)?.eventDate;
    }
    else {
      return null;
    }
  }

}
