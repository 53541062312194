/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DigitalComponentVersionValidatedDto } from '../../models/digital-component-version-validated-dto';

export interface DigitalComponentVersionUploadNewEmailFile$Params {

/**
 * Email upload distribution node ID.
 */
  distributionNodeId: number;
      body?: {
'file': Blob;
}|null|undefined
}

export function digitalComponentVersionUploadNewEmailFile(http: HttpClient, rootUrl: string, params: DigitalComponentVersionUploadNewEmailFile$Params, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionValidatedDto>> {
  const rb = new RequestBuilder(rootUrl, digitalComponentVersionUploadNewEmailFile.PATH, 'post');
  if (params) {
    rb.query('distributionNodeId', params.distributionNodeId, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DigitalComponentVersionValidatedDto>;
    })
  );
}

digitalComponentVersionUploadNewEmailFile.PATH = '/component/digital-component-version/upload-email-file';