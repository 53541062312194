/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ClassificationDto } from '../models/classification-dto';
import { classificationTestCreate } from '../fn/testing-purpose-only/classification-test-create';
import { ClassificationTestCreate$Params } from '../fn/testing-purpose-only/classification-test-create';
import { DocumentTypeDto } from '../models/document-type-dto';
import { documentTypeTestCreate } from '../fn/testing-purpose-only/document-type-test-create';
import { DocumentTypeTestCreate$Params } from '../fn/testing-purpose-only/document-type-test-create';
import { FileTypeDto } from '../models/file-type-dto';
import { fileTypeTestCreate } from '../fn/testing-purpose-only/file-type-test-create';
import { FileTypeTestCreate$Params } from '../fn/testing-purpose-only/file-type-test-create';

@Injectable({ providedIn: 'root' })
export class ApiTestingPurposeOnlyService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fileTypeTestCreate()` */
  static readonly FileTypeTestCreatePath = '/codebook/classifier/file-type/testCreate';

  /**
   * Create new file type, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `fileTypeTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeTestCreate$Response(params: FileTypeTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileTypeDto>> {
    return fileTypeTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new file type, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `fileTypeTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  fileTypeTestCreate(params: FileTypeTestCreate$Params, context?: HttpContext): Observable<FileTypeDto> {
    return this.fileTypeTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileTypeDto>): FileTypeDto => r.body)
    );
  }

  /** Path part for operation `documentTypeTestCreate()` */
  static readonly DocumentTypeTestCreatePath = '/codebook/classifier/document-type/testCreate';

  /**
   * Create new document type, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentTypeTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeTestCreate$Response(params: DocumentTypeTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentTypeDto>> {
    return documentTypeTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new document type, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentTypeTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentTypeTestCreate(params: DocumentTypeTestCreate$Params, context?: HttpContext): Observable<DocumentTypeDto> {
    return this.documentTypeTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentTypeDto>): DocumentTypeDto => r.body)
    );
  }

  /** Path part for operation `classificationTestCreate()` */
  static readonly ClassificationTestCreatePath = '/codebook/classifier/classification/testCreate';

  /**
   * Create new classification, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationTestCreate$Response(params: ClassificationTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationDto>> {
    return classificationTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new classification, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationTestCreate(params: ClassificationTestCreate$Params, context?: HttpContext): Observable<ClassificationDto> {
    return this.classificationTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationDto>): ClassificationDto => r.body)
    );
  }

}
