<icz-analog-component-create-form
  #AnalogComponentCreateFormComponent
  [form]="form"
  [config]="componentsConfig"
  [analogComponentType]="data.analogComponentType"
  (submitted)="close($event)"
  >
</icz-analog-component-create-form>

<icz-form-buttons>
  <icz-button leftButtons [disabled]="disabled" (onAction)="submit()" primary label="Uložit"></icz-button>
  @if (isPaperComponentsDialog) {
    <icz-button leftButtons label="Přidat další komponentu" (onAction)="addAnotherComponent()"></icz-button>
  }
  <icz-button rightButtons [disabled]="isUploading" (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
