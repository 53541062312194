import {IczFormArray, IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {DocumentAllowableObjectClass} from '|api/document';
import {getDigitalComponentUploadForm} from '../digital-components-upload-form/digital-components-upload-form.component';
import {
  createAnalogComponentsFormGroup,
  EsslAnalogComponentType,
  EsslAnalogComponentTypeAsFields,
  transformDocumentCreateDtoWithComponents,
} from '../analog-component-create-form/analog-component-create-form.component';
import {DocumentEsslComponentsValidator} from '../document-essl-components-validator';
import {
  ReceivedDocumentCreateBaseDto,
  ReceivedDocumentWithComponentsCreateBaseDto,
  ReceivedPaperConsignmentCreateDto,
  ReceivedPaperConsignmentRegisterContentDto,
  SadReceivedDocumentCreateDto
} from '|api/sad';
import {ReceivedDocumentUsage} from '../../../services/received-consignments.model';
import {SubjectRecordCreateOrUpdateDto} from '|api/commons';
import {AddressCompleteDto} from '../model/addresses.model';
import {SubjectAsSender} from '../model/subjects.model';

interface ReceivedPaperConsignmentRegisterContentForm extends ReceivedPaperConsignmentRegisterContentDto {
  receivedDocumentCreateDtoListWithHandover?: SadReceivedDocumentCreateDto[],
  receivedDocumentCreateDtoListNoHandover?: ReceivedDocumentCreateBaseDto[],
  senderAddressValid?: boolean,
}

/** is ReceivedDocumentCreateDto */
export function getReceivedDocumentFormGroup(defaultAssignRefNr: boolean) {
  return new IczFormGroup({
    subject: new IczFormControl<Nullable<string>>(null, [IczValidators.required(), IczValidators.maxLength(100)]),
    assignRefNr: new IczFormControl<Nullable<boolean>>(defaultAssignRefNr, []),
    description: new IczFormControl<Nullable<string>>('', []),
    note: new IczFormControl<Nullable<string>>('', []),
    relatedEvidence: new IczFormControl<Nullable<string>>('', []),
    label: new IczFormControl<Nullable<string>>('', []),
    documentForm: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    externalId: new IczFormControl<Nullable<string>>(null),
    senderRefNr: new IczFormControl<Nullable<string>>({value: null, important: true, disabled: false}),
    componentPhysicalLocation: new IczFormControl<Nullable<string>>('', []),
    objectClass: new IczFormControl<Nullable<DocumentAllowableObjectClass>>(DocumentAllowableObjectClass.RECEIVED_DOCUMENT, []),
    digitalComponents: new IczFormArray(() => getDigitalComponentUploadForm(), []),
    [EsslAnalogComponentTypeAsFields.PAPER]: createAnalogComponentsFormGroup(
      {type: EsslAnalogComponentType.PAPER_COMPONENT, initialSize: 0}),
    [EsslAnalogComponentTypeAsFields.MEDIUM]: createAnalogComponentsFormGroup(
      {type: EsslAnalogComponentType.MEDIUM_COMPONENT, initialSize: 0}),
    [EsslAnalogComponentTypeAsFields.PHYSICAL]: createAnalogComponentsFormGroup(
      {type: EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT, initialSize: 0}),
    esslComponentCount: new IczFormControl<number>(0, [DocumentEsslComponentsValidator.isEsslComponentAdded()]),
  });
}

/** is ReceivedPaperConsignmentCreateDto, except receivedDocuments have to be mapped either from withHandover or noHandover */
export function getReceivePaperConsignmentFormGroup(documentsWithHandover: boolean,
                                                    defaultAssignRefNr: boolean,
                                                    targetDistributionNode: boolean,
) {
  const form = new IczFormGroup({
    contactPerson: new IczFormControl<Nullable<string>>(null, []),
    deliveryDate: new IczFormControl<Nullable<string>>(new Date().toISOString(), [IczValidators.required()]),
    deliveryTime: new IczFormControl<Nullable<string>>(null, [IczValidators.isSimpleTime()]),
    targetDistributionNodeId: new IczFormControl<Nullable<number>>(null, []),

    deliveryTypeId: new IczFormControl<Nullable<number>>(null, []),
    basicServiceId: new IczFormControl<Nullable<number>>(null, []),
    description: new IczFormControl<Nullable<string>>(null, []),
    dispatchDate: new IczFormControl<Nullable<string>>(null, []),
    exclusivePersonalDelivery: new IczFormControl<Nullable<boolean>>(false, []),
    externalId: new IczFormControl(null, []),
    keepEnvelope: new IczFormControl<Nullable<boolean>>(false, []),
    label: new IczFormControl<Nullable<string>>(null, []),
    noOfficialContent: new IczFormControl<Nullable<boolean>>(true, []),
    note: new IczFormControl<Nullable<string>>(null, []),
    originalSender: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    personalDelivery: new IczFormControl<Nullable<boolean>>(false, []),
    postingNumber: new IczFormControl<Nullable<string>>(null, []),
    receivedDocumentCreateDtoList: new IczFormArray(() => getReceivedDocumentWithHandoverFormGroup(defaultAssignRefNr), []),
    /**
     * receivedDocumentCreateDtoListWithHandover is mapped to receivedDocumentCreateDtoList
     */
    receivedDocumentCreateDtoListWithHandover: new IczFormArray(() => getReceivedDocumentWithHandoverFormGroup(defaultAssignRefNr), []),
    /**
     * receivedDocumentCreateDtoListNoHandover is mapped to receivedDocumentCreateDtoList
     */
    receivedDocumentCreateDtoListNoHandover: new IczFormArray(() => getReceivedDocumentFormGroup(defaultAssignRefNr), []),

    recommended: new IczFormControl<Nullable<boolean>>(false, []),
    recordedDelivery: new IczFormControl<Nullable<boolean>>(true, []),
    salutation: new IczFormControl<Nullable<string>>(null, []),
    toOwnHands: new IczFormControl<Nullable<boolean>>(false, []),
    trackingNumber: new IczFormControl<Nullable<string>>(null, []),
    unopened: new IczFormControl<Nullable<boolean>>(false, []),
    senderPostOffice: new IczFormControl<Nullable<string>>(null, []),
    senderAddress: new IczFormControl<Nullable<AddressCompleteDto>>(null, []),
    senderAddressValid: new IczFormControl<Nullable<boolean>>(true, [IczValidators.required()]),
    senderDefinition: new IczFormControl<Nullable<SubjectAsSender>>(null, [IczValidators.isValidSenderSubject()]),
  });

  if (targetDistributionNode) {
    form.get('targetDistributionNodeId')!.setValidators([IczValidators.required()]);
  }
  if (documentsWithHandover) {
    (form.get('receivedDocumentCreateDtoListWithHandover') as IczFormArray).setSize(1);
    (form.get('receivedDocumentCreateDtoListNoHandover') as IczFormArray).setSize(0);
  }
  else {
    (form.get('receivedDocumentCreateDtoListNoHandover') as IczFormArray).setSize(1);
    (form.get('receivedDocumentCreateDtoListWithHandover') as IczFormArray).setSize(0);
  }
  return form;
}

export type ReceivePaperConsignmentForm = ReturnType<typeof getReceivePaperConsignmentFormGroup>;


/** is SadReceivedDocumentCreateDto: ownerOrganizationEntityId is transformed into ownerFunctionalPositionId or ownerOrganizationalUnitId*/
export function getReceivedDocumentWithHandoverFormGroup(defaultAssignRefNr: boolean): IczFormGroup {
  return new IczFormGroup({
    formOwnerOrganizationEntityId: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    ownerFunctionalPositionId: new IczFormControl<Nullable<string>>(null, []),
    ownerOrganizationalUnitId: new IczFormControl<Nullable<string>>(null, []),
    receivedDocumentWithComponentsCreateBaseDto: getReceivedDocumentFormGroup(defaultAssignRefNr)
  });
}

interface SadReceivedDocumentCreateDtoWithFormOwner extends SadReceivedDocumentCreateDto {
  formOwnerOrganizationEntityId?: number;
}

export interface SenderResult {
  senderDefinition: Nullable<SubjectRecordCreateOrUpdateDto>;
  senderId: Nullable<number>;
}

export function getSender(senderDefinitionValue: Nullable<SubjectRecordCreateOrUpdateDto | number>): SenderResult {

  const senderDefinition: Nullable<SubjectRecordCreateOrUpdateDto> = typeof senderDefinitionValue !== 'number' ? senderDefinitionValue : null;
  const senderId: Nullable<number> = typeof senderDefinitionValue === 'number' ? senderDefinitionValue : null;

  return {senderDefinition, senderId};
}

export function consignmentFormToReceivedConsignmentCreateDto(form: ReceivePaperConsignmentForm):
  ReceivedPaperConsignmentCreateDto {
  const formValue = form.getRawValue();

  const sender = getSender(formValue.senderDefinition);

  if (!formValue.recommended) {
    formValue.senderPostOffice = null;
    formValue.postingNumber = null;
  }

  const documents: ReceivedDocumentWithComponentsCreateBaseDto[] = (formValue.receivedDocumentCreateDtoListWithHandover as SadReceivedDocumentCreateDto[])
    .map(d => d.receivedDocumentWithComponentsCreateBaseDto!);

  documents.forEach(document => transformDocumentCreateDtoWithComponents(document, Boolean(formValue.unopened)));
  const sadDocuments: SadReceivedDocumentCreateDto[] | ReceivedDocumentWithComponentsCreateBaseDto[] = formValue.receivedDocumentCreateDtoListWithHandover!;
  sadDocuments.forEach((document: SadReceivedDocumentCreateDtoWithFormOwner) => {
    delete document.formOwnerOrganizationEntityId;
  });

  const consignment: ReceivedPaperConsignmentCreateDto = {
    basicServiceId: formValue.basicServiceId,
    deliveryDate: formValue.deliveryDate,
    deliveryTime: formValue.deliveryTime,
    deliveryTypeId: formValue.deliveryTypeId,
    description: formValue.description,
    dispatchDate: formValue.dispatchDate,
    externalId: formValue.externalId,
    keepEnvelope: formValue.keepEnvelope,
    label: formValue.label,
    noOfficialContent: formValue.noOfficialContent,
    note: formValue.note,
    originalSender: formValue.originalSender,
    personalDelivery: formValue.personalDelivery,
    postingNumber: formValue.postingNumber,
    receivedDocumentCreateDtoList: [...sadDocuments],
    recommended: formValue.recommended,
    recordedDelivery: formValue.recordedDelivery,
    salutation: formValue.salutation,
    targetDistributionNodeId: formValue.targetDistributionNodeId,
    toOwnHands: formValue.toOwnHands,
    trackingNumber: formValue.trackingNumber,
    unopened: formValue.unopened,
    senderAddress: formValue.senderAddress,
    senderDefinition: sender.senderDefinition,
    senderId: sender.senderId,
  };

  return consignment;
}

export function consignmentFormToRegisterContentDto(consignment: ReceivedPaperConsignmentRegisterContentForm, receivedDocumentUsage: ReceivedDocumentUsage):
  ReceivedPaperConsignmentRegisterContentDto {

  const result: ReceivedPaperConsignmentRegisterContentDto = {...consignment};

  let baseDocumentsWithComponents: SadReceivedDocumentCreateDto[] = [];
  if (receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER) {
    baseDocumentsWithComponents = consignment.receivedDocumentCreateDtoListNoHandover!.map(d => {
      return {
        ownerFunctionalPositionId: null,
        ownerOrganizationalUnitId: null,
        receivedDocumentWithComponentsCreateBaseDto: d
      };
    });
  } else if (receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE) {
    baseDocumentsWithComponents = consignment.receivedDocumentCreateDtoListWithHandover!;
  }

  baseDocumentsWithComponents.forEach(document => transformDocumentCreateDtoWithComponents(document.receivedDocumentWithComponentsCreateBaseDto!, false));

  result.receivedDocumentCreateDtoList = [...baseDocumentsWithComponents as SadReceivedDocumentCreateDto[]];

  const sender = getSender(consignment.senderDefinition);
  result.senderDefinition = sender.senderDefinition;
  result.senderId = sender.senderId;

  delete (result as ReceivedPaperConsignmentRegisterContentForm).receivedDocumentCreateDtoListWithHandover;
  delete (result as ReceivedPaperConsignmentRegisterContentForm).receivedDocumentCreateDtoListNoHandover;
  delete (result as ReceivedPaperConsignmentRegisterContentForm).originalSender;
  delete (result as ReceivedPaperConsignmentRegisterContentForm).senderAddressValid;

  return result;
}
