@if (svgIcon) {
  <icz-icon size="small" class="mr-4" [svgIcon]="svgIcon" withCustomIconColor></icz-icon>
}
@if (labelContext) {
  <span>
    {{ (label ?? '') | translate:labelContext }}
  </span>
}
@else {
  <span>
    {{ (label ?? '') | translate }}
  </span>
}
<ng-content></ng-content>
