import {TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {TableReservedColumns} from './selected-rows.service';
import {
  IAutocompleteListItemContext,
  IImplicitTemplateContext
} from '../form-elements/form-autocomplete/form-autocomplete.model';
import {TreeItemSelectionStrategy} from '../form-elements/tree-view/tree-view.component';
import {Option} from '../../model';
import {NamedDTO} from '../../core/services/data-mapping.utils';
import {FilterOperator, isNoValueOperator} from '../../services/search-api.service';


export enum FilterType {
  TEXT = 'text',
  DATE = 'date',
  DATE_STATISTICS = 'dateStatistics',
  DATETIME = 'datetime',
  NUMBER = 'number',
  ENUM = 'enum',
  CODEBOOK = 'codebook',
  BOOLEAN = 'boolean',
  FILE_SIZE = 'fileSize',
  EMPOWERMENT = 'empowerment',
  SUBJECT_RECORD = 'subjectRecord',
  ADDRESS = 'consigneeAddress',
  NONE = 'none',
}

export const BASIC_FILTER_TYPES = [
  FilterType.TEXT,
  FilterType.NUMBER,
  FilterType.DATE,
  FilterType.DATE_STATISTICS,
  FilterType.DATETIME,
  FilterType.BOOLEAN,
  FilterType.FILE_SIZE,
  FilterType.EMPOWERMENT,
  FilterType.SUBJECT_RECORD,
  FilterType.ADDRESS,
] as const;

type BasicFilterType = typeof BASIC_FILTER_TYPES[number];

interface CommonFilterDefinitionFields<T extends string = never> {
  label?: Nullable<string>;
  id: T | TableReservedColumns.SELECTION;
  nullValueText?: string;
  // If allowTranslation is true, this prefix is used
  // as a prefix for translation key of given table item cell.
  translateWithPrefix?: string;
  allowTranslation?: boolean;
  filterLabel?: string;
}

export type FilterListOption = Option<any, any>;

export interface FilterWithList {
  list?: FilterListOption[];
  list$?: Observable<FilterListOption[]>;
}

export interface BasicFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T> {
  filterType: BasicFilterType;
  filterConfig?: CommonFilterConfig;
}

export interface EnumFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T>, FilterWithList {
  filterType: FilterType.ENUM;
  filterConfig?: EnumFilterConfig;
}

export interface CodebookFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T>, FilterWithList {
  filterType: FilterType.CODEBOOK;
  filterConfig?: CodebookFilterConfig;
}

export interface NoneFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T> {
  filterType: FilterType.NONE;
  filterConfig?: FilterConfigBase;
}

export type CombinedFilterDefinition<T extends string = never> = (
  Omit<BasicFilterDefinition<T>, 'filterType'> &
  Omit<EnumFilterDefinition<T>, 'filterType'> &
  Omit<CodebookFilterDefinition<T>, 'filterType'> &
  Omit<NoneFilterDefinition<T>, 'filterType'> &
  { filterType: FilterType }
);
export type NonemptyFilterDefinition<T extends string = never> = (
  BasicFilterDefinition<T> |
  EnumFilterDefinition<T> |
  CodebookFilterDefinition<T>
);

export type BaseFilterDefinition<T extends string = never> = (
  NonemptyFilterDefinition<T> |
  NoneFilterDefinition<T>
);

export function isBasicFilterDefinition(fd: BaseFilterDefinition<string>): fd is BasicFilterDefinition<string> {
  return BASIC_FILTER_TYPES.includes(fd.filterType as BasicFilterType);
}

export function isEnumFilterDefinition(fd: BaseFilterDefinition<string>): fd is EnumFilterDefinition<string> {
  return fd.filterType === FilterType.ENUM;
}

export function isCodebookFilterDefinition(fd: BaseFilterDefinition<string>): fd is CodebookFilterDefinition<string> {
  return fd.filterType === FilterType.CODEBOOK;
}

export function isListFilterDefinition(fd: BaseFilterDefinition<string>): fd is EnumFilterDefinition<string> | CodebookFilterDefinition<string> {
  return isEnumFilterDefinition(fd) || isCodebookFilterDefinition(fd);
}

export function isNonemptyFilterDefinition(fd: BaseFilterDefinition<string>): fd is NoneFilterDefinition<string> {
  return isBasicFilterDefinition(fd) || isEnumFilterDefinition(fd) || isCodebookFilterDefinition(fd);
}

export interface FilterConfigBase {
  customFilterId?: string;
}

export interface CommonFilterConfig extends FilterConfigBase {
  isDistinctive?: boolean; // distinctive filters are permanently visible filters left to full-text search field
}

export interface EnumFilterConfig extends CommonFilterConfig {
  findListCb?: (searchTerm: string) => Observable<Array<NamedDTO>>;  // Search api using method to dynamically fill autocomplete list
  minSearchTermLength?: number;
  originId?: string; // a source of IDs in FilterItem#value, if need to be distinguished
  useCustomChips?: boolean; // if filterType is "list" and the filter is multiselect, this option set to true will allow the user to define custom chips and sort them using usage rate
  chipNamespace?: string; // namespace for custom chip data, effective if useCustomChips is true
  listSearchIndexer?: (o: Option<any, any>) => string; // used for enhancing the set of Option properties which will be searched thru using full-text search
  customListItemTemplate?: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>; // used for custom option templates
}

export type SubfilterInitialValues = Record<string, FilterItemValue>;

export interface CodebookFilterConfig extends CommonFilterConfig {
  isTree?: boolean;
  treeSelectionStrategy?: TreeItemSelectionStrategy;
  minSearchTermLength?: number;
  originId?: string; // a source of IDs in FilterItem#value, if need to be distinguished
  customListItemTemplate?: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>; // used for custom option templates and will also show up in selection chips
  customSelectedChipTemplate?: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>; // used for custom option templates and will also show up in selection chips
  subfilters?: Array<Partial<BasicFilterItem | EnumFilterItem>>; // CodebookFilterItems are disallowed on purpose
  subfilterInitialValues?: SubfilterInitialValues; // Record from subfilter ID to its intrinsic value
  subfilterInitialValues$?: Record<string, Observable<FilterItemValue>>; // Record from subfilter ID to its intrinsic value
  searchFieldPlaceholder?: string;
}

export type FilterConfig = FilterConfigBase | CommonFilterConfig | EnumFilterConfig;

export interface FilterSubValue {
  operator: FilterOperator;
  value: Nullable<string>;
  and?: boolean;
  subValueId?: string;
}

interface CommonFilterItemFields extends FilterWithList {
  id: string;
  label: Nullable<string>;
  value: Nullable<string | string[]>;
  viewValue?: Nullable<string>;
  subValues?: Nullable<FilterSubValue[]>;
  columnLabel: string;
  filterOption?: Option<FilterOperator>;
}

export interface BasicFilterItem extends CommonFilterItemFields, CommonFilterConfig {
  filterType: BasicFilterType;
}

export interface EnumFilterItem extends CommonFilterItemFields, EnumFilterConfig {
  filterType: FilterType.ENUM;
}

export interface CodebookFilterItem extends CommonFilterItemFields, CodebookFilterConfig {
  filterType: FilterType.CODEBOOK;
}

export interface NoneFilterItem extends CommonFilterItemFields, FilterConfigBase {
  filterType: FilterType.NONE;
}

export type NonemptyFilterItem = BasicFilterItem | EnumFilterItem | CodebookFilterItem;
export type FilterItem = NonemptyFilterItem | NoneFilterItem;

export function isBasicFilterItem(fi: Nullable<FilterItem>): fi is BasicFilterItem {
  return BASIC_FILTER_TYPES.includes(fi?.filterType as BasicFilterType);
}

export function isEnumFilterItem(fi: Nullable<FilterItem>): fi is EnumFilterItem {
  return fi?.filterType === FilterType.ENUM;
}

export function isCodebookFilterItem(fi: Nullable<FilterItem>): fi is CodebookFilterItem {
  return fi?.filterType === FilterType.CODEBOOK;
}

export function isListFilterItem(fi: Nullable<FilterItem>): fi is EnumFilterItem | CodebookFilterItem {
  return isEnumFilterItem(fi) || isCodebookFilterItem(fi);
}

export function isNonemptyFilterItem(fi: Nullable<FilterItem>): fi is NonemptyFilterItem {
  return isBasicFilterItem(fi) || isEnumFilterItem(fi) || isCodebookFilterItem(fi);
}

export interface DehydratedFilterItemValue<T = string | string[]> {
  id: string;
  operator: Nullable<FilterOperator>;
  value: Nullable<T>;
  subValues?: Nullable<FilterSubValue[]>;
  originId?: string; // when matching with corresponding options after deserialization,
                     // will look up only Options with corresponding originId
  list?: Nullable<FilterListOption[]>;
}

export class FilterItemValue<T = string | string[]> implements DehydratedFilterItemValue<T> {
  static fromFilterItem(item: FilterItem): FilterItemValue {
    return new this(
      item.id,
      item!.filterOption?.value,
      item!.value,
      item!.subValues,
      isListFilterItem(item) ? item.originId : undefined,
      isListFilterItem(item) ? item!.list : undefined,
    );
  }

  constructor(
    public id: string,
    public operator: Nullable<FilterOperator>,
    public value: Nullable<T>,
    public subValues?: Nullable<FilterSubValue[]>,
    public originId?: string,
    public list?: Nullable<FilterListOption[]>,
  ) {
  }

  hasValidValue(): boolean {
    return (!isNoValueOperator(this.operator) && !!this.value) || isNoValueOperator(this.operator);
  }
}

export function isFilterWithOptions(filterType: Nullable<FilterType>) {
  return filterType === FilterType.ENUM || filterType === FilterType.CODEBOOK;
}
