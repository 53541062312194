import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'icz-dashboard-gauge-counter',
  templateUrl: './dashboard-gauge-counter.component.html',
  styleUrls: ['./dashboard-gauge-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardGaugeCounterComponent {
  @Input({required: true})
  name = '';
  @Input({required: true})
  count: Nullable<number> = 0;
  @Input()
  showWarnColor = false;

  get gaugeColorClass() {
    if (this.count && this.count > 0) {
      if (this.showWarnColor) {
        return 'gauge-color-warn';
      } else {
        return 'gauge-color-primary';
      }
    } else {
      return 'gauge-color-disabled';
    }
  }
}
