/* eslint-disable no-console */
import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlSerializer} from '@angular/router';
import {AuthService} from '../authentication/auth.service';
import {createAbsoluteRoute} from '../routing/routing.helpers';
import {ApplicationRoute} from '../../enums/shared-routes.enum';

export function guardUserAuthenticated(checkUserAuthentiactedWithoutFunctionalPosition = false) {
  return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService = inject(AuthService);
    const urlSerializer = inject(UrlSerializer);

    let isAuthenticated: boolean;

    if (checkUserAuthentiactedWithoutFunctionalPosition) {
      isAuthenticated = authService.isAuthenticatedWithoutFunctionalPosition;
    }
    else {
      isAuthenticated = authService.isAuthenticatedWithFunctionalPosition;
    }

    if (isAuthenticated) {
      return true;
    }
    else {
      console.info('User not authenticated according to AuthGuard, redirecting to login page');
      return urlSerializer.parse(createAbsoluteRoute(ApplicationRoute.LOGIN));
    }
  };
}
