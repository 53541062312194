import {Observable, Subject} from 'rxjs';
import {debounceTime, tap} from 'rxjs/operators';
import {OwnConsignmentStatus} from '|api/commons';
import {
  ApiOwnConsignmentService,
  OwnDigitalConsignmentCreateDto,
  OwnDigitalConsignmentUpdateDto,
  OwnInternalDigitalConsignmentCreateDto,
  OwnInternalDigitalConsignmentUpdateDto,
  OwnInternalPaperConsignmentCreateDto,
  OwnInternalPaperConsignmentUpdateDto,
  OwnOfficeDeskConsignmentCreateDto,
  OwnOfficeDeskConsignmentUpdateDto,
  OwnPaperConsignmentCreateDto,
  OwnPaperConsignmentUpdateDto,
  ProofOfDeliveryCreateDto
} from '|api/sad';
import {AbstractConsignmentDialogConsignee, ConsigneeResult} from './abstract-consignment-dialog-consignee';
import {ConsignmentValidationDialogResult} from './consignment-validation/consignment-validation.component';
import {WizardComponent} from '../../../dialogs/wizard/wizard.component';
import {
  OwnDataboxConsignmentForm,
  OwnEmailConsignmentForm,
  OwnInternalConsignmentForm,
  OwnOfficeDeskConsignmentForm,
  OwnPaperOrPersonalConsignmentForm
} from './consignment-dialog.form';
import {
  filterEmptyCustomTextValues
} from '../../envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';

export enum ConsignmentWizardStep {
  CONSIGNEES_OVERVIEW = 'CONSIGNEES_OVERVIEW',
  CONSIGNEE_SELECTION = 'CONSIGNEE_SELECTION',
  CONSIGNMENT_SPECIFICATION = 'CONSIGNMENT_SPECIFICATION',
  COMPONENTS_SELECTION = 'COMPONENTS_SELECTION',
  CONSIGNEE_CREATION = 'CONSIGNEE_CREATION',
  CONSIGNMENT_VALIDATION = 'CONSIGNMENT_VALIDATION',
  ORGANIZATIONAL_UNIT_SELECTION = 'ORGANIZATIONAL_UNIT_SELECTION',
}

@Directive()
export abstract class AbstractConsignmentDialogValidation extends AbstractConsignmentDialogConsignee implements OnInit {

  protected apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  protected destroyReference = inject(DestroyRef);

  consignmentValidationResult: Nullable<ConsignmentValidationDialogResult> = {alreadyRan: false, valid: false, loading: false};
  currentWizardStep!: ConsignmentWizardStep;
  wizard: Nullable<WizardComponent>;
  runBackendValidation$ = new Subject<void>();
  consignmentValidationTabHeading = '';

  ngOnInit() {
    this.runBackendValidation$.pipe(takeUntilDestroyed(this.destroyReference),
      debounceTime(100),
      tap(_ => this.setValidationTab({alreadyRan: false, loading: true})),
      debounceTime(500)).subscribe(_ => {
      this.runBackendValidation();
    });
  }

  setTabStatus(opts: {showValidityState: boolean}) {
    if (this.wizard?.tabs) {
      this.wizard.tabs = this.wizard.tabs.map(t => {
        if (t.id === ConsignmentWizardStep.CONSIGNMENT_VALIDATION) {
          return {
            ...t,
            valid: this.consignmentValidationResult!.valid,
            icon: this.consignmentValidationResult!.valid && this.consignmentValidationResult!.errors!.length ? 'warning_color' : '',
            loading: this.consignmentValidationResult!.loading,
            showTabValidity: opts.showValidityState};
        } else return t;
      });
    }
  }

  setTabNotRunYet() {
    this.setTabStatus({showValidityState: false});
    this.consignmentValidationTabHeading = 'Zásilka nebyla zvalidována';
  }

  setTabLoading() {
    this.setTabStatus({showValidityState: true});
    this.consignmentValidationTabHeading = 'Probíhá validace';
  }

  setTabValid() {
    this.setTabStatus({showValidityState: true});
    this.consignmentValidationTabHeading = 'Validní zásilka';
  }

  setTabInvalid() {
    this.setTabStatus({showValidityState: true});
    this.consignmentValidationTabHeading = 'Nevalidní zásilka';
  }

  setValidationTab(consignmentValidationResult: ConsignmentValidationDialogResult) {
    this.consignmentValidationResult = consignmentValidationResult;

    if (this.consignmentValidationResult) {
      if (this.consignmentValidationResult.loading) {
        this.setTabLoading();
      } else if (!this.consignmentValidationResult.alreadyRan) {
        this.setTabNotRunYet();
      } else {
        if (this.consignmentValidationResult.valid) {
          this.setTabValid();
        } else {
          this.setTabInvalid();
        }
      }
    } else {
      this.setTabNotRunYet();
    }
  }

  private runBackendValidation() {
    let dto: OwnPaperConsignmentCreateDto |
      OwnDigitalConsignmentCreateDto |
      OwnOfficeDeskConsignmentCreateDto |
      OwnInternalPaperConsignmentCreateDto |
      OwnInternalDigitalConsignmentCreateDto;
    let validationReq$: Nullable<Observable<any>>;

    // officeDeskConsignment doesn't need genericFieldsForm to be valid, because deliveryTypeId is specified in _specificFieldsForm
    if ((!this.isOfficeDeskConsignment && this.genericFieldsForm.invalid) || this.specificFieldsForm.invalid) {
      this.setValidationTab({alreadyRan: false, loading: false, valid: false, errors: []});
    }
    else if (this.isCreateAnyConsignmentDialogType || (this.isEditConsignmentDialogType &&
      this.modalData.consignment!.status !== OwnConsignmentStatus.IN_DISTRIBUTION &&
      this.modalData.consignment!.status !== OwnConsignmentStatus.DISPATCHED &&
      this.modalData.consignment!.status !== OwnConsignmentStatus.DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION
    )) {
      if (this.isPaperConsignmentByDistClass) {
        dto = this.getPaperConsignmentCreateDto();
        validationReq$ = this.apiOwnConsignmentService.ownConsignmentValidateOwnPaperConsignment({body: dto as OwnPaperConsignmentCreateDto}, SKIP_ERROR_DIALOG);
      }
      else if (this.isEmailConsignmentByDistClass) {
        dto = this.getEmailConsignmentCreateDto();
        validationReq$ = this.apiOwnConsignmentService.ownConsignmentValidateOwnDigitalConsignment({body: dto as OwnDigitalConsignmentCreateDto}, SKIP_ERROR_DIALOG);
      }
      else if (this.isDataboxConsignmentByDistClass) {
        dto = this.getDataboxConsignmentCreateDto();
        validationReq$ = this.apiOwnConsignmentService.ownConsignmentValidateOwnDigitalConsignment({body: dto as OwnDigitalConsignmentCreateDto}, SKIP_ERROR_DIALOG);
      }
      else if (this.isOfficeDeskConsignment) {
        dto = this.getOwnOfficeDeskConsignmentCreateDto();
        validationReq$ = this.apiOwnConsignmentService.ownConsignmentValidateOwnOfficeDeskConsignment({body: dto as OwnOfficeDeskConsignmentCreateDto}, SKIP_ERROR_DIALOG);
      }
      else if (this.isInternalConsignmentByDistClass) {
        if (this.isInternalPaperDispatch) {
          dto = this.getInternalPaperConsignmentCreateDto();
          validationReq$ = this.apiOwnConsignmentService.ownConsignmentValidateOwnInternalPaperConsignment({body: dto as OwnInternalPaperConsignmentCreateDto}, SKIP_ERROR_DIALOG);
        }
        else {
          dto = this.getInternalDigitalConsignmentCreateDto();
          validationReq$ = this.apiOwnConsignmentService.ownConsignmentValidateOwnInternalDigitalConsignment({body: dto as OwnInternalDigitalConsignmentCreateDto}, SKIP_ERROR_DIALOG);
        }
      }

      if (!validationReq$) {
        this.setValidationTab({alreadyRan: false, loading: false});
        return;
      }

      validationReq$.subscribe({
        next: status => {
          if (!status) return;
          this.setValidationTab({alreadyRan: true, loading: false, ...status});
        },
        error: error => {
          if (error?.error) {
            this.setValidationTab({alreadyRan: true, loading: false, valid: false, errors: [{code: error.error.message}]});
          }
        }
      });
    }
      // If the consignment is IN_DISTRIBUTION or DISPATCHED or DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION or FOR_CONFIRMATION_OF_DELIVERY, no change
    // should be able to make it invalid.
    else {
      this.setValidationTab({alreadyRan: true, loading: false, valid: true, errors: []});
    }
  }

  getPaperConsignmentCreateDto(proofOfDelivery?: Nullable<ProofOfDeliveryCreateDto>): OwnPaperConsignmentCreateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnPaperOrPersonalConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      codAmount: formValue.codAmount,
      componentIds: this.selectedComponentIds,
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      consigneeAdditionalName: formValue.consigneeAdditionalName,
      consigneeAddress: formValue.consigneeAddress!,
      consignorFileRefNumber: formValue.consignorFileRefNumber,
      consignorRefNumber: formValue.consignorRefNumber,
      note: formValue.note,
      contactPerson: formValue.contactPerson,
      declaredValue: formValue.declaredValue,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId,
      nonPaperComponentsType: formValue.nonPaperComponentsType,
      ownDocumentId: this.modalData.documentData.id,
      salutation: formValue.salutation,
      envelopeTemplateId: formValue.envelopeTemplateId,
      customText: filterEmptyCustomTextValues(formValue.customText),
      weight: formValue.weight,
      payoutAmount: formValue.payoutAmount,
      proofOfDelivery,
    };
  }

  getOwnOfficeDeskConsignmentCreateDto(): OwnOfficeDeskConsignmentCreateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnOfficeDeskConsignmentForm).getRawValue(),
    };

    let consignee: ConsigneeResult = {consignee: null, consigneeId: null};
    if (!formValue.publicPosting) {
      consignee = this.getConsignee();
    }

    return {
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      componentIds: this.selectedComponentIds,
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      publicPosting: formValue.publicPosting!,
      label: formValue.label,
      toPostFrom: formValue.toPostFrom!,
      postingDuration: formValue.postingDuration,
      toPostTo: formValue.toPostTo!,
      documentId: this.modalData.documentData.id,
      officeDeskCategoryId: formValue.officeDeskCategoryId!,
      officeDeskRegionId: formValue.officeDeskRegionId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      note: formValue.note,
    };
  }

  getInternalPaperConsignmentCreateDto(): OwnInternalPaperConsignmentCreateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnInternalConsignmentForm).getRawValue(),
    };

    return {
      componentIds: this.selectedComponentIds,
      consigneeAdditionalName: formValue.consigneeAdditionalName,
      consigneeOrganizationalUnitId: formValue.consigneeOrganizationalUnitId!,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      consignorFileRefNumber: formValue.consignorFileRefNumber,
      consignorRefNumber: formValue.consignorRefNumber,
      note: formValue.note,
      contactPerson: formValue.contactPerson,
      salutation: formValue.salutation,
      ownDocumentId: this.modalData.documentData.id,
      deliveryTypeId: formValue.deliveryTypeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      filingOfficeSheetNodeId: formValue.filingOfficeSheetNodeId!,
      envelopeTemplateId: formValue.envelopeTemplateId,
      customText: filterEmptyCustomTextValues(formValue.customText),
    };
  }

  getInternalDigitalConsignmentCreateDto(): OwnInternalDigitalConsignmentCreateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnInternalConsignmentForm).getRawValue(),
    };

    return {
      componentIds: this.selectedComponentIds,
      consigneeOrganizationalUnitId: formValue.consigneeOrganizationalUnitId!,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      consignorFileRefNumber: formValue.consignorFileRefNumber,
      consignorRefNumber: formValue.consignorRefNumber,
      empowerment: this.modalData.documentData.empowerment,
      ownDocumentId: this.modalData.documentData.id,
      deliveryTypeId: formValue.deliveryTypeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      filingOfficeSheetNodeId: formValue.filingOfficeSheetNodeId!,
      mainComponentId: this.mainComponentId,
      note: formValue.note,
    };
  }

  getEmailConsignmentCreateDto(proofOfDelivery?: Nullable<ProofOfDeliveryCreateDto>): OwnDigitalConsignmentCreateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnEmailConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      ownDocumentId: this.modalData.documentData.id,
      componentIds: this.selectedComponentIds,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId,
      body: formValue.body,
      consigneeEmail: formValue.consigneeEmail,
      subject: formValue.subject!,
      mainComponentId: this.mainComponentId,
      note: formValue.note,
      proofOfDelivery,
    };
  }

  getDataboxConsignmentCreateDto(): OwnDigitalConsignmentCreateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnDataboxConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId,
      deliveryTypeId: formValue.deliveryTypeId!,
      ownDocumentId: this.modalData.documentData.id,
      componentIds: this.selectedComponentIds,
      subject: formValue.subject!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId,
      consigneeOrganizationalUnit: formValue.consigneeOrganizationalUnit,
      consigneeOrganizationalUnitCode: formValue.consigneeOrganizationalUnitCode ? Number(formValue.consigneeOrganizationalUnitCode) : undefined,
      consignorRefNumber: formValue.consignorRefNumber,
      consignorFileRefNumber: formValue.consignorFileRefNumber,
      empowerment: formValue.empowerment,
      consigneeDataBox: formValue.consigneeDataBox,
      mainComponentId: this.mainComponentId,
      toBeDeliveredTo: formValue.toBeDeliveredTo,
      note: formValue.note,
    };
  }

  getOwnPaperConsignmentUpdateDto(): OwnPaperConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnPaperOrPersonalConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      codAmount: formValue.codAmount,
      componentIds: this.selectedComponentIds,
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      consigneeAddress: formValue.consigneeAddress!,
      consignorFileRefNumber: formValue.consignorFileRefNumber,
      consignorRefNumber: formValue.consignorRefNumber,
      declaredValue: formValue.declaredValue,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId,
      consignmentPostingNumber: {
        code: formValue.consignmentPostingNumber.code!,
        prefix: formValue.consignmentPostingNumber.prefix!,
        suffix: formValue.consignmentPostingNumber.suffix!,
      },
      payoutAmount: formValue.payoutAmount,
      weight: formValue.weight,
      envelopeTemplateId: formValue.envelopeTemplateId,
      salutation: formValue.salutation,
      consigneeAdditionalName: formValue.consigneeAdditionalName,
      contactPerson: formValue.contactPerson,
      customText: filterEmptyCustomTextValues(formValue.customText),
      note: formValue.note,
    };
  }

  getOwnDataboxConsignmentUpdateDto(): OwnDigitalConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnDataboxConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      componentIds: this.selectedComponentIds,
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      consignorRefNumber: formValue.consignorRefNumber!,
      consignorFileRefNumber: formValue.consignorFileRefNumber!,
      consigneeDataBox: formValue.consigneeDataBox!,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      subject: formValue.subject!,
      toBeDeliveredTo: formValue.toBeDeliveredTo!,
      empowerment: formValue.empowerment,
      note: formValue.note,
    };
  }

  getOwnEmailConsignmentUpdateDto(): OwnDigitalConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnEmailConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      body: formValue.body!,
      componentIds: this.selectedComponentIds,
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      subject: formValue.subject!,
      consigneeEmail: formValue.consigneeEmail!,
      deliveryTypeId: formValue.deliveryTypeId!,
      note: formValue.note,
    };
  }

  getOwnDigitalConsignmentUpdateDto(): OwnDigitalConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnDataboxConsignmentForm).getRawValue(),
    };
    const consignee = this.getConsignee();

    return {
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      subject: formValue.subject!,
      componentIds: this.selectedComponentIds,
      deliveryTypeId: formValue.deliveryTypeId!,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      note: formValue.note,
    };
  }

  getOwnOfficeDeskConsignmentUpdateDto(): OwnOfficeDeskConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnOfficeDeskConsignmentForm).getRawValue(),
    };

    let consignee: ConsigneeResult = {consignee: null, consigneeId: null};
    if (!formValue.publicPosting) {
      consignee = this.getConsignee();
    }

    return {
      componentIds: this.selectedComponentIds,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      consigneeDefinition: consignee.consignee!,
      consigneeId: consignee.consigneeId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      label: formValue.label!,
      officeDeskCategoryId: formValue.officeDeskCategoryId!,
      officeDeskRegionId: formValue.officeDeskRegionId!,
      toPostFrom: formValue.toPostFrom!,
      toPostTo: formValue.toPostTo!,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId!,
      note: formValue.note,
    };
  }

  getOwnInternalPaperConsignmentUpdateDto(): OwnInternalPaperConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnInternalConsignmentForm).getRawValue(),
    };

    return {
      componentIds: this.selectedComponentIds,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      consigneeOrganizationalUnitId: formValue.consigneeOrganizationalUnitId!,
      consignorFileRefNumber: formValue.consignorFileRefNumber!,
      consignorRefNumber: formValue.consignorRefNumber!,
      envelopeTemplateId: formValue.envelopeTemplateId,
      consigneeAdditionalName: formValue.consigneeAdditionalName,
      contactPerson: formValue.contactPerson,
      salutation: formValue.salutation,
      customText: filterEmptyCustomTextValues(formValue.customText),
      note: formValue.note,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId,
      documentId: this.modalData.documentData.id,
    };
  }

  getOwnInternalDigitalConsignmentUpdateDto(): OwnInternalDigitalConsignmentUpdateDto {
    const formValue = {
      ...this.genericFieldsForm.getRawValue(),
      ...(this.specificFieldsForm as OwnInternalConsignmentForm).getRawValue(),
    };

    return {
      componentIds: this.selectedComponentIds,
      deliveryServiceCombinationId: formValue.deliveryServiceCombinationId!,
      deliveryTypeId: formValue.deliveryTypeId!,
      consigneeOrganizationalUnitId: formValue.consigneeOrganizationalUnitId!,
      consignorFileRefNumber: formValue.consignorFileRefNumber!,
      consignorRefNumber: formValue.consignorRefNumber!,
      mainComponentId: this.mainComponentId,
      note: formValue.note,
      dispatchOfficeDistributionNodeId: formValue.dispatchOfficeDistributionNodeId,
      documentId: this.modalData.documentData.id,
    };
  }

}
