import {ChangeDetectorRef, DestroyRef, inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export type DetachEvents = 'columnResize' | 'menuClosed';

/**
 * Purpose of this service is to detach and reattach views from DOM - turn off change detection.
 * Use when you want better performance on certain actions and with CAUTION!
 */

@Injectable({
  providedIn: 'root',
})
export class DetachingService {

  private destroyRef = inject(DestroyRef);

  private _detachEvents: { [key: string]: ChangeDetectorRef[] } = {};
  private _detachApplicants$ = new BehaviorSubject<Nullable<DetachEvents>>(null);

  constructor() {
    this._detachApplicants$.pipe(
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(applicantForDetach => {
      Object.entries(this._detachEvents).forEach(([applicant, cds]) => {
        if (applicant === applicantForDetach) {
          cds.forEach(cd => cd.detach());
        } else {
          cds.forEach(cd => cd.reattach());
        }
      });
    });
  }

  /**
   * Register view to detach when eventName is emit with detachApplicantsByEvent(eventName)
   * @param eventName Event name by which detaches all registered views
   * @param cd Change detection reference
   */
  registerDetach(eventName: DetachEvents, cd: ChangeDetectorRef) {
    if (this._detachEvents[eventName]) {
      this._detachEvents[eventName].push(cd);
    } else {
      this._detachEvents[eventName] = [cd];
    }
  }

  /**
   * Detaches all registered views from DOM change detections by provided eventName and reattaches all other.
   * Dont forget to reattach!
   */
  detachApplicantsByEvent(eventName: DetachEvents) {
    this._detachApplicants$.next(eventName);
  }

  reattach() {
    this._detachApplicants$.next(null);
  }

}
