import {EpdzErrorDto} from '|api/sad';
import {OperationValidator, ValidatorGuard} from '../../../services/bulk-operation-validation.service';
import {EpdzMessageType} from '|api/commons';

export class EpdzErrorsOperationValidators {

  static isMessageWithoutSender(): OperationValidator<EpdzErrorDto> {
    return (epdzError: EpdzErrorDto) => {
      return !epdzError.senderAddress ? {validationMessage: 'U zprávy nebyl zjištěn odesílatel'} : null;
    };
  }

  @ValidatorGuard()
  static isMessageNotEmailType(): OperationValidator<EpdzErrorDto> {
    return (epdzError: EpdzErrorDto) => {
      return epdzError.messageType !== EpdzMessageType.EMAIL ? {validationMessage: 'Tuto operaci lze provést pouze pro e-mailové zprávy'} : null;
    };
  }

}
