import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {ClassificationSchemeDto} from '|api/codebook';
import {DocumentForm, SettlementType} from '|api/commons';
import {
  ApiDocumentService,
  DisposalScheduleComputationDto,
  DisposalScheduleComputationInnerRequestDto,
  DocumentDto,
  DocumentPrepareSettlementDto
} from '|api/document';
import {
  DocumentSettleErrorDialogComponent
} from '../document-settle-error-dialog/document-settle-error-dialog.component';
import {IczFormControl} from '../../../../../form-elements/icz-form-controls';
import {getStartOfTheDay, Option} from '../../../../../../model';
import {enumToOptions} from '../../../../../../core/services/data-mapping.utils';
import {LoadingIndicatorService} from '../../../../../essentials/loading-indicator.service';
import {IczModalService} from '../../../../../../services/icz-modal.service';
import {formatDisposalSchedule, isOwnDocumentObject} from '../../../../shared-document.utils';
import {EsslComponentDto} from '../../../../../../services/essl-component-search.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {VisibleClassifiersMode} from '../../../../abstract-classifier-selector.component';
import {debounceTime, finalize} from 'rxjs/operators';
import {SKIP_ERROR_DIALOG} from '../../../../../../core/error-handling/http-errors';
import {DisposalTipDetailLevel} from '../../../../shared-business-components.model';
import {adjustSettlementDate, SettlementTabState} from '../document-settle-model';
import {AbstractDocumentSettleFormComponent} from '../abstract-document-settle-form.component';
import {IczOnChanges, IczSimpleChanges} from '../../../../../../utils/icz-on-changes';
import {AbstractObjectSettleDialogComponent} from '../abstract-object-settle-dialog.component';

@Component({
  selector: 'icz-document-settle-form',
  templateUrl: './document-settle-form.component.html',
  styleUrls: ['./document-settle-form.component.scss'],
})
export class DocumentSettleFormComponent extends AbstractDocumentSettleFormComponent implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private apiDocumentService = inject(ApiDocumentService);
  private modalService = inject(IczModalService);

  readonly userCommentNotice = 'Dokument obsahuje {{userCommentCount}} uživatelské komentáře';

  @Input() esslComponents: Nullable<EsslComponentDto[]>;
  @Input() showDocumentInfo = false;
  @Input({required: true}) settlementTabState!: SettlementTabState;
  @Input() disposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  @Input() set document(document: DocumentDto) {
    this._document = document;
    if (document?.auditInfo) {
      this.minSettlementDate = getStartOfTheDay(document.auditInfo.createdAt);
      this.documentCreateDate = document.auditInfo.createdAt;
    }
  }
  get document() {
    return this._document;
  }

  @Output() onErrorClose = new EventEmitter<void>();
  @Output() settlementTabPrepareChange = new EventEmitter<SettlementTabState>();
  private _document!: DocumentDto;

  documentSettlementTypeOptions: Option[] = [];
  documentFormOptions: Option[] = [];
  disposalScheduleOptions: Option[] = [];

  minSettlementDate!: Date;
  maxSettlementDate = new Date();
  settlementDate!: Date;

  userCommentNoticeContext = {userCommentCount: 4};

  validClassificationScheme!: ClassificationSchemeDto;

  settlementDateValidator = {
    validationFn: (date: Date) => this.isDateValid(date),
    invalidDateMessage : { errorMessageTemplate: 'Zadejte datum mezi dneškem a datem založení dokumentu'}
  };

  get documentSettlementCtrl() {
    return this.form.get('documentSettlementType') as IczFormControl;
  }

  get isSettlementByRecord() {
    return this.documentSettlementCtrl?.value === SettlementType.BY_RECORD;
  }

  get selectedEsslComponentsCount(): string {
    return String(this.form.get('esslComponents')!.value?.length ?? 0);
  }

  get isDocumentInFile() {
    return !isNil(this.document?.fileId);
  }

  readonly VisibleClassifiersMode = VisibleClassifiersMode;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  private prepareDtoToView(prepareDto: DocumentPrepareSettlementDto) {
    if (prepareDto.classificationScheme) {
      this.validClassificationScheme = prepareDto.classificationScheme;
      this.form.get('classificationSchemeName')!.setValue(prepareDto.classificationScheme.name);
      this.form.get('classificationSchemeValidFrom')!.setValue(prepareDto.classificationScheme.validFrom);
      this.form.get('classificationSchemeValidTo')!.setValue(prepareDto.classificationScheme.validTo);
    }

    if (!isNil(prepareDto.entityClassId)) {
      this.form.get('entityClassId')!.setValue(prepareDto.entityClassId);
      this.form.get('entityClassId')!.updateValueAndValidity();
    }

    if (!isNil(prepareDto.documentTypeId)) {
      this.form.get('documentTypeId')!.setValue(prepareDto.documentTypeId);
      this.form.get('documentTypeId')!.updateValueAndValidity();
    }
    this.settlementTabPrepareChange.emit({prepared: true, classificationScheme: this.validClassificationScheme});
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      this.fillDisposalScheduleSection();
    }
  }

  fillDisposalScheduleSection() {
    if (this.disposalSchedulePrepare) {
      if (this.disposalSchedulePrepare.availableDisposalSchedules) {
        this.availableDisposalSchedules = this.disposalSchedulePrepare.availableDisposalSchedules;
        this.disposalScheduleOptions = this.disposalSchedulePrepare.availableDisposalSchedules.map(ds => ({value: ds.disposalScheduleId, label: formatDisposalSchedule(ds)}));
        this.form.get('disposalScheduleId')!.setValue(this.disposalSchedulePrepare.recommendedDisposalScheduleId);
        this.form.get('yearOfRetentionPeriodStart')!.setValue(this.disposalSchedulePrepare.defaultYearOfRetentionPeriodStart);
        this.form.get('externalRetentionTriggerIds')!.setValue(this.disposalSchedulePrepare.externalRetentionTriggerIds);
      }
    }
  }

  override codebooksLoaded() {
    if (this.isDocumentInFile) {
      this.form.get('entityClassId')!.disable();
    }

    this.loadingService.startLoading(this);
    if (this.settlementTabState?.prepared) {
      this.prepareDisposalSchedules();
      this.validClassificationScheme = this.settlementTabState.classificationScheme!;
    } else {
      this.prepareSettlement(this.form.get('settlementDate')!.value);
    }
  }

  override ngOnInit() {
    super.ngOnInit();
    if (isOwnDocumentObject(this.document)) {
      this.documentSettlementTypeOptions = enumToOptions('settlementType', SettlementType).filter(op => op.value !== SettlementType.BY_ASSIGNMENT);
    } else {
      // settlement by document is allowed only for own document
      this.documentSettlementTypeOptions = enumToOptions('settlementType', SettlementType).filter(op => op.value !== SettlementType.BY_ASSIGNMENT && op.value !== SettlementType.BY_DOCUMENT);
    }

    this.documentFormOptions = enumToOptions('documentForm', DocumentForm);

    this.form.get('settlementDate')!.valueChanges.pipe(
      debounceTime(500),
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(settlementDate => {
      if (settlementDate) {
        this.prepareSettlement(adjustSettlementDate(settlementDate));
      }
    });

    this.form.get('entityClassId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(classId => {
      if (classId) {
        this.form.get('classificationSchemeName')!.setValue(this.validClassificationScheme.name);
        this.form.get('classificationSchemeValidFrom')!.setValue(this.validClassificationScheme.validFrom);
        this.form.get('classificationSchemeValidTo')!.setValue(this.validClassificationScheme.validTo);
      }
      this.disposalScheduleSourceChange.emit();
    });

    this.form.get('documentTypeId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.disposalScheduleSourceChange.emit();
    });

    this.form.get('disposalScheduleId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const selectedDs = this.availableDisposalSchedules.find(ds => ds.disposalScheduleId === value)!;
      this.form.get('retentionTriggerTypeCode')!.setValue(selectedDs?.retentionTriggerTypeCode);
      this.form.get('disposalOperationCode')!.setValue(selectedDs.disposalOperationCode);
      this.form.get('retentionPeriod')!.setValue(selectedDs.retentionPeriod);
      this.form.get('triggerEventCheckYear')!.setValue((new Date()).getFullYear() + (selectedDs.defaultTriggerEventCheckYear ?? 0));
    });

    this.form.get('documentSettlementType')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(documentSettlementType => {
      AbstractObjectSettleDialogComponent.processReasonAndContentFields(
        documentSettlementType,
        this.form.get('reason') as IczFormControl,
        this.form.get('content') as IczFormControl
      );
    });
  }

  private prepareSettlement(date: string) {
    if (this.document) {
      this.settlementDate = new Date(date);

      this.apiDocumentService.documentPrepareSettlement(
        {
          id: this.document.id,
          dateTime: date,
        },
        SKIP_ERROR_DIALOG
      ).pipe(finalize(() => {
        this.loadingService.endLoading(this);
      })).subscribe({
        next: prepareDto => {
          this.prepareDtoToView(prepareDto);
          this.prepareDisposalSchedules();
          this.loadingService.endLoading(this);
        },
        error: err => {
          this.openErrorDialog(err);
        }
      });
    }
  }

  private prepareDisposalSchedules() {
    const formValue = this.form.getRawValue();
    const computationInnerRequest: DisposalScheduleComputationInnerRequestDto[] = [{
      entityId: this.document.id,
      documentTypeId: formValue.documentTypeId,
      entityClassId: formValue.entityClassId,
    }];

    if (!isNil(computationInnerRequest[0].entityClassId) || !isNil(computationInnerRequest[0].documentTypeId)) {
      this.apiDocumentService.documentGetValidDisposalSchedules(
        {
          body: {
            inner: computationInnerRequest,
            referenceDate: this.form.get('settlementDate'!)!.value!
          }
        }
      ).subscribe(result => {
        this.loadingService.endLoading(this);

        if (result.resultsPerEntity) {
          this.disposalSchedulePrepare = result.resultsPerEntity[0];
          // if during prepare disposal schedule prepare the document has its own disposalScheduleId
          // and this disposal schedule is in the available disposal schedules than use it as recommended
          if (this.disposalSchedulePrepare.availableDisposalSchedules) {
            const currentDocumentDS = this.disposalSchedulePrepare.availableDisposalSchedules.find(d => d.disposalScheduleId === this.document.disposalScheduleId);
            if (currentDocumentDS) {
              this.disposalSchedulePrepare.recommendedDisposalScheduleId = currentDocumentDS.disposalScheduleId;
            }
          }
          this.fillDisposalScheduleSection();
        }
      });
    }
  }

  private openErrorDialog(error: any) {
    this.modalService
      .openComponentInModal({
        component: DocumentSettleErrorDialogComponent,
        modalOptions: {
          width: 500,
          height: 580,
          titleTemplate: 'Dokument není možné vyřídit',
        },
        data: {
          error,
          documentId: this.document.id
        },
      })
      .subscribe(result => {
        if (!result) {
          this.onErrorClose.next();
        }
      });
  }

  private isDateValid(date: Date) {
    if (!(date && this.minSettlementDate)) return false;
    return !(date < this.minSettlementDate || date > this.maxSettlementDate);
  }

}
