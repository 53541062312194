/**
 * Workaround for undefined enums at runtime - duplicated enums from @icz/idt/shared/messages.ts.
 * Try importing those enums from @icz/idt/shared/messages.ts after upgrading to TypeScript 4.7.
 */

export enum ErrorType {
  nativeAppUnreachable = 100,
  methodNotImplemented = 110,
  methodNotFound,
  methodInternalError,
  certStoreNotConfigured = 120,
  certNotFound,
  openFileError = 130,
  mimeTypeInvalid,
  mimeTypeNotFound,
  fileExtensionInvalid,
  fileExtensionNotFound,
  unknownFileType,
  customOpenCommandsDBInvalid,
}
