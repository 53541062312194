/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FileTypeCreateDto } from '../../models/file-type-create-dto';
import { FileTypeDto } from '../../models/file-type-dto';

export interface FileTypeCreate$Params {
      body: FileTypeCreateDto
}

export function fileTypeCreate(http: HttpClient, rootUrl: string, params: FileTypeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileTypeDto>> {
  const rb = new RequestBuilder(rootUrl, fileTypeCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FileTypeDto>;
    })
  );
}

fileTypeCreate.PATH = '/codebook/classifier/file-type';