@if (messages.length > messagesToShow) {
  <div class="row counter py-8 justify-between">
    <div class="row">
      <icz-button svgIcon="error" background></icz-button>
      <icz-label [label]="messagesQueue"></icz-label>
    </div>
    <icz-button label="Zavřít vše" (onAction)="closeAll()"></icz-button>
  </div>
}

<div class="flex flex-col-reverse">
  @for (message of messages; track message) {
    <!--
    appearWithDelay animation prevents annoying blinking while
    repositioning the toast center in its popover overlay
    -->
    @if ($index < messagesToShow) {
      <icz-toast-message
        @appearWithDelay
        [canHide]="$index === 0"
        [message]="message"
        (dismiss)="dismiss($index)">
      </icz-toast-message>
    }
  }
</div>
