import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValidationResult} from '|api/commons';
import {SignatureValidationItemDto} from '|api/component';

@Component({
  selector: 'icz-digital-component-signature-info',
  templateUrl: './digital-component-signature-info.component.html',
  styleUrls: ['./digital-component-signature-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalComponentSignatureInfoComponent {
  @Input() signatureOrSeal: Nullable<SignatureValidationItemDto>;

  @Input() signatureIndex!: number;

  @Input() sealIndex!: number;

  @Input() totalNumberOfSignatures!: number;

  @Input() totalNumberOfSeals!: number;

  private get numberOfSignatureText() {
    return `(${this.signatureIndex}/${this.totalNumberOfSignatures})`;
  }

  private get numberOfSealText() {
    return `(${this.sealIndex}/${this.totalNumberOfSeals})`;
  }

  private get qualifiedSignatureLabel() {
    return this.isQualified ? 'Kvalifikovaný podpis' : 'Není kvalifikovaný podpis';
  }

  private get qualifiedSealLabel() {
    return this.isQualified ? 'Kvalifikovaná pečeť' : 'Není kvalifikovaná pečeť';
  }

  get headerIcon() {
    return this.isSeal ? 'seal' : 'signature';
  }

  get signatureOrSealLabel() {
    return this.isSeal ? 'Pečeť' : 'Podpis';
  }

  get invalidOrIndeterminateLabelLong() {
    return `fe.ui.signatureDetail.error.subindication.${this.signatureOrSeal?.subindication}`;
  }

  /* todo(dr) Překlad a kontext */
  get signatoryLabel() {
    return this.isSeal ? 'Opečetil' : 'Podepsal';
  }

  get signedOrSealedDateLabel() {
    return this.isSeal ? 'Opečetěno' : 'Podepsáno';
  }

  get sectionLabel() {
    return this.isSeal ? 'Detail pečetě' : 'Detail podpisu';
  }

  get formAndLevelLabel() {
    return this.isSeal ? 'Formát a úroveň pečetě' : 'Formát a úroveň podpisu';
  }

  get isSeal() {
    return this.signatureOrSeal?.qcType === 'SEAL';
  }

  get indexText() {
    return this.isSeal ? this.numberOfSealText : this.numberOfSignatureText;
  }

  get isSignatoryDefined() {
    return Boolean(this.signatureOrSeal?.certificateInfoDto?.signatory);
  }

  get signatory() {
    const {commonName = null, organizationName = null, organizationalUnitName = null} = this.signatureOrSeal?.certificateInfoDto?.signatory!;
    const signatory = `${commonName ? commonName : ''} ${organizationalUnitName ? organizationalUnitName : ''} ${
      organizationName ? organizationName : ''
    }`;
    return {signatory};
  }

  get isQualified() {
    return this.signatureOrSeal?.certificateInfoDto!.qualified;
  }

  get qualifiedTagColor() {
    return this.isQualified ? 'green' : 'red';
  }

  get qcType() {
    return {qcType: this.signatureOrSeal?.qcType};
  }

  get notValidSignatureOrSealHeadingLabel() {
    if (this.isSeal) {
      if (this.isQualified) {
        return 'Neplatná kvalifikovaná pečeť';
      }
      else {
        return 'Neplatná pečeť';
      }
    }
    else {
      if (this.isQualified) {
        return 'Neplatný kvalifikovaný podpis';
      }
      else {
        return 'Neplatný podpis';
      }
    }
  }

  get indeterminateSignatureOrSealHeadingLabel() {
    if (this.isSeal) {
      if (this.isQualified) {
        return 'Neznámá kvalifikovaná pečeť';
      }
      else {
        return 'Neznámá pečeť';
      }
    }
    else {
      if (this.isQualified) {
        return 'Neznámý kvalifikovaný podpis';
      }
      else {
        return 'Neznámý podpis';
      }
    }
  }

  get validSignatureOrSealLabel() {
    return this.isSeal ? 'Platná pečeť' : 'Platný podpis';
  }

  get qualifiedLabel() {
    return this.isSeal ? this.qualifiedSealLabel : this.qualifiedSignatureLabel;
  }

  get isValidSignatureOrSeal() {
    return this.signatureOrSeal?.result === ValidationResult.VALID;
  }

  get isInvalidSignatureOrSeal() {
    return this.signatureOrSeal?.result === ValidationResult.INVALID;
  }

  get isIndeterminateSignatureOrSeal() {
    return this.signatureOrSeal?.result === ValidationResult.INDETERMINATE;
  }

  get revokedLabel() {
    return this.signatureOrSeal?.certificateInfoDto?.revoked ? 'Ano' : 'Ne';
  }

  get hasTimestamps() {
    return this.signatureOrSeal?.timestamps?.length;
  }

  getTimestampResultLabel(timestamp: SignatureValidationItemDto) {
    switch (timestamp.result) {
      case ValidationResult.VALID:
        return 'Platné';
      case ValidationResult.INVALID:
        return 'Neplatné';
      case ValidationResult.INDETERMINATE:
        return 'Nedostatek informací';
      default:
        return '?';
    }
  }
}
