import {ColumnDefinition, isListColumnDefinition} from './table.models';
import {Option} from '../../model';
import {Subject} from 'rxjs';

export class TableColumnsData<T extends string = never> {

  initialDefinitions: ColumnDefinition<T>[] = [];
  columnDefinitions: ColumnDefinition<T>[] = [];
  displayedColumns!: string[];
  listLoadingStates: Record<string, boolean> = {};
  listLoadingStatesChange$ = new Subject<Record<string, boolean>>();
  columnDefinitionsChange$ = new Subject<void>();

  constructor(definitions: ColumnDefinition<T>[]) {
    this.initialDefinitions = definitions.map(columnDef => ({
      ...columnDef,
      displayed: columnDef.displayed ?? true,
    }));
    this.reset();
    this.setDisplayedColumns();
  }

  setColumnList(columnId: T, list: Option[]) {
    const columnDefinition = this.getColumnById(columnId);

    if (isListColumnDefinition(columnDefinition)) {
      columnDefinition.list = list;
      this.columnDefinitionsChange$.next();
    }
  }

  setColumnLoading(columnId: T, loading: boolean) {
    this.listLoadingStates[columnId] = loading;
    this.listLoadingStatesChange$.next({...this.listLoadingStates});
  }

  getColumnById(columnId: T): Nullable<ColumnDefinition<T>> {
    return this.columnDefinitions.find(
      entry => entry.id === columnId || entry.filterConfig?.customFilterId === columnId
    );
  }

  setDisplayedColumns() {
    this.displayedColumns = this.columnDefinitions
      .filter(definition => definition.displayed)
      .map(definition => definition.id);
  }

  reset() {
    this.columnDefinitions = [...(this.initialDefinitions ?? [])]
      .map(definition => ({
        ...definition,
        label: definition.label || definition.id,
        displayed: isNil(definition.displayed) || definition.displayed === true,
      }));
  }

}
