<div [waiting]="loadingService.isLoading$(this) | async">
  <form [formGroup]="form">
    @if (idtStatus === IdtConnectionState.RUNNING) {
      <icz-section label="Jak se má anonymizace uložit?">
        <icz-radio-group class="grow">
          <div class="col grow">
            <icz-radio-button class="grow" formControlName="saveAsNewComponent"
                              label="Anonymizaci uložit jako novou verzi původní komponenty"
                              [value]="false"
                              [fieldDisabled]="forceNewComponentCreation"
            ></icz-radio-button>
            <icz-radio-button class="grow" formControlName="saveAsNewComponent"
                              label="Anonymizaci uložit jako novou komponentu"
                              [value]="true"
            ></icz-radio-button>
          </div>
        </icz-radio-group>
        @if(form.get('saveAsNewComponent')!.value) {
          <icz-form-field label="Název komponenty" formControlName="fileName"></icz-form-field>
          <icz-form-field label="Popis komponenty" formControlName="description"></icz-form-field>
        }
      </icz-section>
    }
    @else {
      <icz-idt-malfunction-alert
        [idtStatus]="idtStatus"
        functionUnavailableText="Operace anonymizace tudíž není k dispozici."
      ></icz-idt-malfunction-alert>
      <div class="mt-8">
        @if (idtStatus !== IdtConnectionState.INITIATING) {
          <a iczLinkWithoutHref (click)="reconnect()">
            Zkusit znovu
          </a>
        }
      </div>
    }
  </form>
</div>

<icz-form-buttons>
  <icz-button leftButtons  [disabled]="loadingService.isLoading(this)" (onAction)="submit()" primary label="Anonymizovat"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
