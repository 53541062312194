/* tslint:disable */
/* eslint-disable */

/**
 * Barcode orientation
 */
export enum BarcodeOrientation {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL'
}
