import {DOCUMENT} from '@angular/common';
import {DestroyRef, inject, Injectable, Injector, Type} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {ComponentModalComponent} from '../components/dialogs/component-modal/component-modal.component';
import {AnyComponent} from '../model';
import {IczModalRef} from './icz-modal-ref.injectable';
import {HealthcheckService} from '../core/guards/healthcheck.service';
import {arrayRemove} from '../lib/utils';
import {castStream} from '../lib/rxjs';
import {extendDefaultModalConfig} from '../lib/modals';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {InterpolationContext} from '../lib/model';

export interface IczModalDefinition<D> {
  component: Type<AnyComponent>;
  modalOptions: IczModalOptions;
  data?: D;
}

export interface IczModalOptions {
  titleTemplate?: string; // a string with mustache-like expressions. might not need be defined if useCustomHeader is true
  titleTemplateContext?: InterpolationContext; // context for title template if it contains mustache expressions
  width: number | string;
  /** Height: if number, in pixels. If null, then height will be dynamically adjusted according to content height.
   * If string, only 'vh' unit with numeric dimension is supported.  */
  // eslint-disable-next-line @typescript-eslint/ban-types -- null is reserved value
  height: number | string | null;
  useCustomHeader?: boolean; // will remove header from the modal
  isClosable?: boolean; // if true, there will be "X" button, backdrop click listener and esc keypress listener which will close the modal
  disableAutoMargin?: boolean; // will disable auto-positioning of inner content. handy for special modal content components
  disableButtonBar?: boolean; // will stretch modal component to full height, used when icz-form-buttons are not present inside modal component
  injector?: Injector;
}

/**
 * A simple wrapper around MatDialog, auto-places a component of choice into ModalDialogComponent,
 * thus saving devs from boilerplate HTML+CSS and always subscribing to DialogRef#afterClosed()
 *
 * If you need any more advanced use-case, don't hesitate to use your trusty old MatDialog.
 */

@Injectable({
  providedIn: 'root',
})
export class IczModalService {

  private router = inject(Router);
  private dialogService = inject(MatDialog);
  private healthcheckService = inject(HealthcheckService);
  private destroyRef = inject(DestroyRef);
  private document = inject(DOCUMENT);

  private openModals: IczModalRef<any>[] = [];

  /**
   *  R = Modal Dialog Result
   *  D = Data passed to Modal Dialog
   */
  openComponentInModalWithInstance<R, D>(modalDef: IczModalDefinition<D>): IczModalRef<R> {
    this.checkBackend();
    modalDef = this.applyDefaultModalOptionsValues(modalDef);
    const config = this.getModalConfig<D>(modalDef);

    const modalRef: IczModalRef<R> = this.dialogService.open<ComponentModalComponent<D>, IczModalDefinition<D>, R>(
      ComponentModalComponent,
      config
    ) as unknown as IczModalRef<R>;

    modalRef.forceClose = modalRef.close;

    modalRef.afterClosed().pipe(
      take(1), // technical code: ensures that we really don't need to unsubscribe
    ).subscribe(_ => {
      arrayRemove(this.openModals, modalRef);
    });
    this.openModals.push(modalRef);

    return modalRef;
  }

  /**
   *  R = Modal Dialog Result
   *  D = Data passed to Modal Dialog
   */
  openComponentInModal<R, D>(modalDef: IczModalDefinition<D>): Observable<R> {
    // When designing this service, I took current MatDialog#open usages in our project into account.
    // Those calls were always accompanied by subscribing to afterClosed thus this method will
    // return Observable<R> instead of DialogRef...
    const modalRef = this.openComponentInModalWithInstance(modalDef) as IczModalRef<R>;

    return modalRef.afterClosed().pipe(
      take(1), // technical code: ensures that we really don't need to unsubscribe in components
      castStream<R>(),
      tap(_ => {
        // After closing a nested modal, focus should be returned
        // to another modal container otherwise Esc keypress will not work.
        const underlyingModalEls: NodeListOf<HTMLElement> = this.document.querySelectorAll('.mat-dialog-container');

        if (underlyingModalEls.length) {
          // CDK places child modal elements as next descendants of the
          // previous one (important in case of modal nesting).
          const lastModalEl = underlyingModalEls[underlyingModalEls.length - 1];
          lastModalEl.focus();
        }
      })
    );
  }

  closeAllModals() {
    for (const modalRef of this.openModals) {
      modalRef.forceClose(null);
    }
  }

  hasOpenModalOfType(modalComponentClass: Type<AnyComponent>) {
    return this.openModals.findIndex(
      om => (om.componentInstance as ComponentModalComponent<unknown>).component instanceof modalComponentClass
    ) !== -1;
  }

  isModalComponentCurrentlyActive(modalComponent: ComponentModalComponent<unknown>) {
    if (this.openModals.length) {
      // eslint-disable-next-line
      return (this.openModals.at(-1) as unknown as MatDialogRef<unknown>) === modalComponent.dialogRef;
    }
    else {
      return false;
    }
  }

  private getModalConfig<D>(modalDef: IczModalDefinition<D>) {
    const maxPadding = 50; // px

    const heightLimit = this.document.body.offsetHeight;
    const widthLimit = this.document.body.offsetWidth;

    const finalHeight = this.getEffectiveModalDimensions(modalDef.modalOptions.height, heightLimit, maxPadding);
    const finalWidth = this.getEffectiveModalDimensions(modalDef.modalOptions.width, widthLimit, maxPadding);

    return extendDefaultModalConfig({
      width: modalDef.modalOptions.width ? finalWidth : undefined,
      height: modalDef.modalOptions.height ? finalHeight : undefined,
      injector: modalDef.modalOptions.injector,
      data: modalDef,
    });
  }

  private getEffectiveModalDimensions(dimension: Nullable<string|number>, limit: number, maxPadding: number) {
    if (typeof dimension === 'number' || dimension === null) {
      return dimension && dimension > limit ? `${limit - maxPadding}px` : `${dimension}px`;
    }
    else if (typeof dimension === 'string' && (dimension.endsWith('vh') || dimension.endsWith('vw'))) {
      const isVh = dimension.endsWith('vh');
      let viewportDimension = parseInt(dimension.replaceAll('vh', '').replaceAll('vw', ''), 10);
      viewportDimension = viewportDimension < 0 ? 0 : viewportDimension > 90 ? 90 : viewportDimension;
      return `${String(viewportDimension)}${isVh ? 'vh' : 'vw'}`;
    }
    else {
      throw new Error('Unsupported unit for modal width/height provided.');
    }
  }

// fire-and-forget service healthcheck before each modal open
  private checkBackend() {
    this.healthcheckService.getBackendHealthcheckResult().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(result => {
      if (!result.isUp) {
        this.router.navigateByUrl(createAbsoluteRoute(result.redirectTo!));
        this.closeAllModals();
      }
    });
  }

  private applyDefaultModalOptionsValues<D>(modalDef: IczModalDefinition<D>) {
    modalDef = {...modalDef};

    if (modalDef.modalOptions.isClosable === undefined) {
      modalDef.modalOptions.isClosable = true;
    }

    return modalDef;
  }
}
