import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import {shouldCall as ShouldCall} from '@tinkoff/ng-event-plugins';

export const SIDEBAR_SCROLLBAR_RESERVE = 20;

// predicates supplied to decorators must be exported non-arrow functions
export function resizeInProgress(this: ResizableSidebarComponent) {
  return this.sideX !== 0;
}

@Component({
  selector: 'icz-resizable-sidebar',
  templateUrl: './resizable-sidebar.component.html',
  styleUrls: ['./resizable-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResizableSidebarComponent {
  private readonly DEFAULT_INITIAL_WIDTH = 300;

  // all widths are in px
  @Input({required: true})
  set initialWidth(width: number) {
    if (width !== this.DEFAULT_INITIAL_WIDTH) {
      this.sideWidth = width;
    }
  }
  @Input()
  minWidth = 300;
  @Input()
  maxWidth = 640;
  @Output()
  widthChanged = new EventEmitter<number>();

  @HostBinding('style.width.px')
  sideWidth = this.DEFAULT_INITIAL_WIDTH;

  sideX = 0;
  SIDEBAR_SCROLLBAR_RESERVE = SIDEBAR_SCROLLBAR_RESERVE;

  get totalWidth(): number {
    return this.sideWidth - this.SIDEBAR_SCROLLBAR_RESERVE;
  }

  onSideResizeStart(event: MouseEvent) {
    this.sideX = event.clientX;
  }

  /**
   * Performance optimization:
   * - eventName.silent will run event handler outside Angular Zone
   * - init.eventName will hand current ChangeDetector over to @ShouldCall
   * - @ShouldCall will re-enter the Zone if its predicate (resizeInProgress)
   *    holds thus it will not trigger unnecessary dirty check cycles.
   */
  @HostListener('document:mousemove.silent', ['$event'])
  @HostListener('document:mousemove.init', ['$event'])
  @ShouldCall(resizeInProgress)
  onSideResize(event: MouseEvent) {
    if (this.sideX) {
      this.sideWidth += event.clientX - this.sideX;
      if (this.sideWidth < this.minWidth) {
        this.sideWidth = this.minWidth;
      } else if (this.sideWidth > this.maxWidth) {
        this.sideWidth = this.maxWidth;
      }
      this.sideX = event.clientX;
      this.widthChanged.emit(this.totalWidth);
    }
  }

  @HostListener('document:mouseup.silent', ['$event'])
  @HostListener('document:mouseup.init', ['$event'])
  @ShouldCall(resizeInProgress)
  onSideResizeStop() {
    this.sideX = 0;
  }

}
