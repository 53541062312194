import {inject, Injectable} from '@angular/core';
import {
  CirculationToastType,
  SCirculationBulkTaskAssignedInitiatorData,
  SCirculationBulkTaskAssignedInitiatorErrorData,
  SCirculationBulkTaskErrorByTargetData,
  SCirculationBulkTaskSuccessByTargetData,
  SCirculationHandoverTaskAssignedTargetParticipantData,
  SCirculationHandoverTaskPassedInitiatorData,
  SCirculationHandoverTaskRejectedInitiatorData,
  SCirculationHandoverTaskRevokedTargetParticipantData,
  SCirculationHandoverTaskSuccessInitiatorData,
  SCirculationHandoverToOwnHandsTaskReturnedInitiatorData,
  SCirculationTaskAssignedTargetParticipantData,
  SCirculationTaskPassedInitiatorData,
  SCirculationTaskRejectedInitiatorData,
  SCirculationTaskRevokedTargetParticipantData,
  SCirculationTaskSuccessInitiatorData
} from './circulation-toast.service';
import {
  EsslComponentCirculationToastType,
  getEsslComponentToastTemplateType,
  SBulkCirculatedEsslComponentData,
  SCirculatedEsslComponentData,
  SCirculatedEsslComponentErrorData,
  SEsslComponentApprovalApprovedInitiatorData,
  SEsslComponentApprovalDisapprovedInitiatorData,
  SEsslComponentApprovalPassInitiatorData,
  SEsslComponentApprovalRejectInitiatorData,
  SEsslComponentApprovalRevokeTargetParticipantData,
  SEsslComponentSignRejectInitiatorData,
  SEsslComponentSignRevokeTargetParticipantData,
  SEsslComponentSignSuccessInitiatorData
} from './essl-component-circulation-toast.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  FileCirculationToastType,
  SFileCirculationBulkTaskSuccessByTargetData,
  SFileCirculationTaskErrorData,
} from './file-circulation-toast.service';
import {EntityType} from '|api/commons';
import {
  getEsslComponentTemplate
} from '../../../components/notifications/notification-message-body/abstract-notification-message-content';


@Injectable({
  providedIn: 'root'
})
export class CirculationNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_TASK_ASSIGNED_TARGET_PARTICIPANT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_TASK_ASSIGNED_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationTaskAssignedTargetParticipantData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_TASK_SUCCESS_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_TASK_SUCCESS_INITIATOR,
          parsedBody.parameters,
          SCirculationTaskSuccessInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_TASK_REJECTED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_TASK_REJECTED_INITIATOR,
          parsedBody.parameters,
          SCirculationTaskRejectedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_TASK_REVOKED_TARGET_PARTICIPANT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationTaskRevokedTargetParticipantData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_TASK_PASSED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_TASK_PASSED_INITIATOR,
          parsedBody.parameters,
          SCirculationTaskPassedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TASK_ASSIGNED_TARGET_PARTICIPANT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationHandoverTaskAssignedTargetParticipantData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TASK_SUCCESS_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverTaskSuccessInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TASK_REJECTED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_HANDOVER_TASK_REJECTED_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverTaskRejectedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationHandoverTaskRevokedTargetParticipantData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TASK_PASSED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.WARNING,
          CirculationToastType.FLOW_HANDOVER_TASK_PASSED_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverTaskPassedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverToOwnHandsTaskReturnedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverToOwnHandsTaskReturnedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverTaskPassedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverTaskSuccessInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR,
          parsedBody.parameters,
          SCirculationHandoverTaskPassedInitiatorData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_APPROVED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_INITIATOR)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentApprovalApprovedInitiatorData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_REVOKED_TARGET_PARTICIPANT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_REVOKE_TARGET_PARTICIPANT)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentApprovalRevokeTargetParticipantData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_DISAPPROVED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_INITIATOR)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentApprovalDisapprovedInitiatorData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_REJECTED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_REJECTED_INITIATOR)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentApprovalRejectInitiatorData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_SIGNING_TASK_SIGNED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentSignSuccessInitiatorData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_SIGNING_TASK_REVOKED_TARGET_PARTICIPANT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REVOKE_TARGET_PARTICIPANT)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentSignRevokeTargetParticipantData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_SIGNING_TASK_REJECTED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REJECT_INITIATOR)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentSignRejectInitiatorData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.FLOW_COMPONENT_APPROVAL_TASK_PASSED_INITIATOR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_PASSED_INITIATOR)(parsedBody.parameters[0]),
          parsedBody.parameters,
          SEsslComponentApprovalPassInitiatorData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_HANDOVER_FOR_SIGN_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR,
          parsedBody.parameters,
          SBulkCirculatedEsslComponentData,
          true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_HAND_FOR_APPROVAL_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR, parsedBody.parameters, SBulkCirculatedEsslComponentData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_ANALOG_SIGN_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          getEsslComponentToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT, EntityType.ANALOG_COMPONENT),
          parsedBody.parameters,
          SBulkCirculatedEsslComponentData
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_BULK_HANDOVER_TASK_ASSIGNED_INITIATOR,
          parsedBody.parameters,
          SCirculationBulkTaskAssignedInitiatorData,
          true
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_HANDOVER_TAKEOVER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationBulkTaskAssignedInitiatorData,
          true
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_HANDOVER_FILE_TAKEOVER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_FILE_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationBulkTaskAssignedInitiatorData,
          true
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_STATEMENT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_BULK_HAND_FOR_STATEMENT_INITIATOR,
          parsedBody.parameters,
          SCirculationBulkTaskAssignedInitiatorData,
          true
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_APPROVAL_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_BULK_HAND_FOR_APPROVAL_DOCUMENT_INITIATOR,
          parsedBody.parameters,
          SCirculationBulkTaskAssignedInitiatorData,
          true
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
          parsedBody.parameters,
          SCirculationBulkTaskSuccessByTargetData,
          true
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_FILE_HANDOVER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR, parsedBody.parameters, SFileCirculationBulkTaskSuccessByTargetData);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_DIGITAL_SIGN_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, getEsslComponentToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT, EntityType.DIGITAL_COMPONENT), parsedBody.parameters, SBulkCirculatedEsslComponentData);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_BULK_HAND_FOR_ACKNOWLEDGMENT_INITIATOR, parsedBody.parameters, SCirculationBulkTaskAssignedInitiatorData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_APPROVE_BY_TARGET_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_APPROVE_BY_TARGET_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_TARGET_PARTICIPANT, parsedBody.parameters, SCirculatedEsslComponentData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_TARGET_PARTICIPANT, parsedBody.parameters, SCirculatedEsslComponentData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_FILE_TAKEOVER_AND_PASS_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR, parsedBody.parameters, SFileCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_TASK_REJECT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_TASK_REVOKE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_TASK_PASS_TO_NEXT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.INFO, CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskSuccessByTargetData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_HANDOVER_FOR_SIGN_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SCirculatedEsslComponentErrorData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_HAND_FOR_APPROVAL_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SCirculatedEsslComponentErrorData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_ANALOG_SIGN_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR, parsedBody.parameters, SCirculatedEsslComponentErrorData, true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_DIGITAL_SIGN_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR, parsedBody.parameters, SCirculatedEsslComponentErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SCirculationBulkTaskAssignedInitiatorErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_FILE_HANDOVER_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SFileCirculationTaskErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HANDOVER_TAKEOVER_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_HANDOVER_TASK_TARGET_PARTICIPANT_ERROR, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_STATEMENET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SCirculationBulkTaskAssignedInitiatorErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_APPROVAL_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SCirculationBulkTaskAssignedInitiatorErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR, parsedBody.parameters, SCirculationBulkTaskAssignedInitiatorErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_APPROVE_BY_TARGET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_APPROVE_BY_TARGET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_BY_TARGET_ERROR, parsedBody.parameters, SCirculatedEsslComponentErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_DISAPPROVAL_BY_TARGET_ERROR, parsedBody.parameters, SCirculatedEsslComponentErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR, CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_FILE_TAKEOVER_AND_PASS_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, FileCirculationToastType.FILE_TASK_CIRCULATION_BY_TARGET_ERROR, parsedBody.parameters, SFileCirculationTaskErrorData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_TASK_REJECT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT_ERROR, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_TASK_REVOKE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT_ERROR, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_TASK_PASS_TO_NEXT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT_ERROR, parsedBody.parameters, SCirculationBulkTaskErrorByTargetData,true);
      }
    );
  }
}
