import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {ApiElasticsearchService} from '|api/elastic';
import {SearchApiWithElasticService, UnitViewSearchConfig} from './search-api-with-elastic.service';
import {SearchParams, TypedSearchRecordDto, unwrapSearchContent} from './search-api.service';
import {
  ExtendedCirculationActivityDto,
  ExtendedCirculationTaskDto
} from '../components/shared-business-components/model/elastic-extended-entities.interface';
import {extendEsslObjectsWithRepresenting} from './search-api-data-utils';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

@Injectable({
  providedIn: 'root'
})
export class CirculationSearchService extends SearchApiWithElasticService {

  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);

  countTasksGlobally(searchParams: SearchParams, unitView?: Nullable<UnitViewSearchConfig>): Observable<number> {
    return this.searchApi<number>(
      searchParams,
      unitView?.isUnitView ?
        this.getElasticViewByOrgUnitUri('flow-tasks-by-org-unit', unitView.orgUnitId, searchParams, true) :
        this.getElasticViewUri('flow-tasks', searchParams, true)
    );
  }

  findTasksGlobally(searchParams: SearchParams, unitView?: Nullable<UnitViewSearchConfig>): Observable<Page<ExtendedCirculationTaskDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<ExtendedCirculationTaskDto>>>(
      searchParams,
      unitView?.isUnitView ?
        this.getElasticViewByOrgUnitUri('flow-tasks-by-org-unit', unitView.orgUnitId, searchParams) :
        this.getElasticViewUri('flow-tasks', searchParams)
    ).pipe(
      extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent()
    );
  }

  findActivitiesGlobally(searchParams: SearchParams, unitView?: Nullable<UnitViewSearchConfig>): Observable<Page<ExtendedCirculationActivityDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<ExtendedCirculationActivityDto>>>(
      searchParams,
      unitView?.isUnitView ?
        this.getElasticViewByOrgUnitUri('flow-activities-by-org-unit', unitView.orgUnitId, searchParams) :
        this.getElasticViewUri('flow-activities', searchParams)
    ).pipe(
      extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent()
    );
  }

  findActivitiesForDocument(searchParams: Partial<SearchParams>, documentId: number): Observable<Page<ExtendedCirculationActivityDto>> {
    return this.findActivities(searchParams, applyPathParams(ApiElasticsearchService.ElasticsearchFindDocumentActivitiesPath, {documentId}));
  }

  findActivitiesForFile(searchParams: Partial<SearchParams>, fileId: number): Observable<Page<ExtendedCirculationActivityDto>> {
    return this.findActivities(searchParams, applyPathParams(ApiElasticsearchService.ElasticsearchFindFileActivitiesPath, {fileId}));
  }

  private findActivities(searchParams: Partial<SearchParams>, searchUri: string): Observable<Page<ExtendedCirculationActivityDto>> {
    if (searchParams?.fulltextSearchTerm?.trim()) {
      searchUri += `?text=${encodeURIComponent(searchParams?.fulltextSearchTerm ?? '')}`;
    }

    return this.searchApi<Page<TypedSearchRecordDto<ExtendedCirculationActivityDto>>>(
      searchParams,
      CORE_MICROSERVICE_ROOT + searchUri
    ).pipe(
      unwrapSearchContent(),
    ) as Observable<Page<ExtendedCirculationActivityDto>>;
  }

}
