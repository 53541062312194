<div [waiting]="loadingService.isLoading(this)">
  <icz-file-selector
    [form]="form"
    controlName="id"
    (objectSelected)="onFileSelected($event)"
  ></icz-file-selector>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()" primary label="Vložit do spisu"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
