import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'icz-static-progress-indicator',
  templateUrl: './static-progress-indicator.component.html',
  styleUrls: ['./static-progress-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StaticProgressIndicatorComponent {
  @Input()
  progressLabel: string = '';
}
