@if (activity) {
  <span
    cdkOverlayOrigin
    #digitalComponentsPopupOrigin="cdkOverlayOrigin">
    @if (isNotesFieldVisible(activity)) {
      <a
        class="visible-clickable-area p-4"
        iczLinkWithoutHref
      (click)="fetchActivityNotes($event, activity, notesPopup)">{{ getNotesFieldText(activity) }}</a>
    }
    @if (loadingService.isLoading$(apiCirculationTaskService, activity.id) | async) {
      <icz-spinner
        class="ml-4" [diameter]="13"
      ></icz-spinner>
    }
    <icz-table-toolbar-popup
      #notesPopup
      [popoverOrigin]="digitalComponentsPopupOrigin"
      (onClose)="activityNotesClosed()">
      @for (activityNote of currentActivityNotes; track activityNote) {
        @if (activityNote.userId | findUserById | async; as userFullName) {
          <icz-notification-card
            class="cursor-default"
            [originatorInitials]="getUserAcronym(userFullName)">
            <div class="icz-body-strong icz-text-muted" originatorName>{{ userFullName }} - {{ activityNote.functionalPositionId | findFunctionalPositionById:true | async }}</div>
            <div class="icz-body-strong mt-8" header>{{ (activityNote.taskState ? 'en.circulationTaskState.' + activityNote.taskState : '') | translate }}</div>
            <div class="icz-body-1" body>{{ activityNote.message }}</div>
          </icz-notification-card>
        }
      }
    </icz-table-toolbar-popup>
  </span>
}
