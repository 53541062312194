<div class="col value-selector">
  @if (timeSelectorUsage === TimepickerUsage.FILTER) {
    <icz-button tabIndex="-1" background svgIcon="collapse" (onAction)="increment()"></icz-button>
  }
  <icz-form-field [(value)]="viewValue"
    (keydown.arrowUp)="increment()"
    (keydown.arrowDown)="decrement()"
    [fieldDisabled]="fieldDisabled"
    [showValidationStatus]="false"
  ></icz-form-field>
  @if (timeSelectorUsage === TimepickerUsage.FILTER) {
    <icz-button tabIndex="-1" background svgIcon="expand_more" (onAction)="decrement()"></icz-button>
  }
</div>
