<icz-section [label]="sectionLabel" boxed class="block mb-32">
  <icz-form-field
    label="Odesílatel"
    [value]="senderName"
    [fieldDisabled]="true"
  ></icz-form-field>
  <icz-form-field
    label="Adresa odesílatele"
    [value]="senderAddress"
    [fieldDisabled]="true"
  ></icz-form-field>
  <div class="row">
    <icz-form-field
      class="grow"
      label="ID schránky odesílatele"
      [value]="message?.dataBoxId ?? consignment?.dataBoxDto?.id"
      [fieldDisabled]="true"
    ></icz-form-field>
    <icz-form-field
      class="grow"
      label="Typ schránky"
      [value]="message?.dataBoxType ?? consignment?.dataBoxDto?.type"
      [fieldDisabled]="true"
    ></icz-form-field>
  </div>
  <icz-form-field
    label="Organizační jednotka odesílatele"
    [value]="message?.senderOrganisationUnit ?? consignment?.senderOrganisationUnit"
    [fieldDisabled]="true"
  ></icz-form-field>
</icz-section>
