import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HistoryBit} from '../../../services/history.model';
import {getObjectIcon} from '../../shared-business-components/shared-document.utils';
import {HistoryService} from '../../../services/history.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'icz-history-bits',
  templateUrl: './history-bits.component.html',
  styleUrls: ['./history-bits.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoryBitsComponent implements OnInit {

  protected historyService = inject(HistoryService);
  private destroyRef = inject(DestroyRef);
  protected router = inject(Router);
  private translateService = inject(TranslateService);

  historyBits: HistoryBit[] = [];
  isOpen = false;

  readonly getObjectIcon = getObjectIcon;

  ngOnInit() {
    this.historyService.historyBits$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(historyBits => this.historyBits = [...historyBits].reverse());
  }

  historyBitClick(historyBit: HistoryBit) {
    this.isOpen = false;
    this.historyService.routeToHistoryBit(historyBit);
  }

  openInNewTab($event: Event, completeUrl: string) {
    $event.stopPropagation();
    window.open(completeUrl);
  }

  getHistoryBitName(historyBit: HistoryBit) {
    if (historyBit.labelParts && historyBit.labelParts.length > 1) {
      const translatedParts: string[] = historyBit.labelParts.filter(Boolean).map(part => this.translateService.instant(part));
      return translatedParts.join(' ');
    } else {
      return this.translateService.instant(historyBit.label);
    }
  }

}
