<div class="gap-16 row file-link" [routerLink]="entityLink">
  <icz-icon [svgIcon]="entityIcon"></icz-icon>
  <div class="gap-0 col">
    <div>
      {{ entityIdentifier | formatIdentifier }}
      <span class="pl-8 ref-number">{{ entity?.refNumber ?? ('bez čísla jednacího' | translate) }}</span>
    </div>
    <div class="font-bold">{{ entity?.subject ?? '' }}</div>
    @if (showFileHandlingType && isFile(entity)) {
      <icz-label label="{{ 'document.fileHandlingType.' + entity!.fileHandlingType }}"></icz-label>
    }
  </div>
</div>
