/* tslint:disable */
/* eslint-disable */

/**
 * Status received EPDZ message can be in from processing point of view.
 */
export enum ReceivedEpdzMessageProcessingStatus {
  UNREAD = 'UNREAD',
  UNREAD_WITH_DEFECTS = 'UNREAD_WITH_DEFECTS',
  PROCESSED = 'PROCESSED',
  FORWARDED = 'FORWARDED',
  REJECTED = 'REJECTED',
  DISCARDED = 'DISCARDED'
}
