/* tslint:disable */
/* eslint-disable */

/**
 * EPDZ channel type enum.
 */
export enum EpdzChannelType {
  DIRECTORY = 'directory',
  MVD = 'mvd',
  EML = 'eml',
  OCR = 'ocr',
  ISDS = 'isds',
  IMAP = 'imap',
  POP_3 = 'pop3',
  SMTP = 'smtp',
  GRAPH = 'graph',
  UPLOAD = 'upload'
}
