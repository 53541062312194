/* tslint:disable */
/* eslint-disable */

/**
 * Cross reference type
 */
export enum CrossReferenceType {
  WEAK_BY_SYSTEM = 'WEAK_BY_SYSTEM',
  WEAK_BY_USER = 'WEAK_BY_USER',
  STRONG = 'STRONG'
}
