@if (label) {
  <div>
    <label class="input-label">{{(label | translate)}}</label>
  </div>
}
<div class="row-group w-full mb-16">
  @for (option of options; track option.value) {
    @if (!option.isHidden) {
      <icz-button
        (onAction)="_valueChanged(option.value)"
        class="grow"
        [label]="option.label"
        [disabled]="fieldDisabled"
        [ngClass]="{'button-toggled': isButtonToggled(option.value), 'button-not-toggled': !isButtonToggled(option.value)}">
      </icz-button>
    }
  }
</div>
