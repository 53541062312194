/* tslint:disable */
/* eslint-disable */

/**
 * Email security protocol type
 */
export enum EmailTransportProtocol {
  SMTP = 'SMTP',
  IMAP = 'IMAP',
  POP_3 = 'POP3',
  GRAPH = 'GRAPH'
}
