import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, ViewChild} from '@angular/core';
import {
  AnalogComponentCreateFormComponent,
  CreateAnalogFormConfig,
  EsslAnalogComponentType,
  EsslAnalogComponentTypeAsFields
} from '../analog-component-create-form/analog-component-create-form.component';
import {PrimitiveControlValueType} from '../../form-elements/form-field';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  selector: 'icz-add-analog-component-to-document',
  templateUrl: './add-analog-component-to-document.component.html',
  styleUrls: ['./add-analog-component-to-document.component.scss'],
})
export class AddAnalogComponentToDocumentComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  readonly EsslAnalogComponentType = EsslAnalogComponentType;
  @ViewChild('paperComponentsCreateFormComponent') paperComponentsCreateFormComponent!: AnalogComponentCreateFormComponent;
  @ViewChild('mediumComponentsCreateFormComponent') mediumComponentsCreateFormComponent!: AnalogComponentCreateFormComponent;
  @ViewChild('physicalItemsComponentsCreateFormComponent') physicalItemsComponentsCreateFormComponent!: AnalogComponentCreateFormComponent;

  @Input({required: true}) form!: IczFormGroup;

  componentDetailedInputChanged = new EventEmitter<Nullable<string>>();

  emitComponentDetailedInputChange(event: Nullable<PrimitiveControlValueType>) {
    this.componentDetailedInputChanged.next(String(event));
  }

  @Input() showComponentDetailedInputToggle = false;
  @Input({required: true}) analogComponentsConfig!: CreateAnalogFormConfig;
  @Input() resetForm!: Nullable<EventEmitter<void>>;

  // adding boolean to Option<V> requires slightly bigger refactor
  componentDetailedInputOptions = [
    {
      value: 'false',
      label: 'Stručný popis komponent'
    },
    {
      value: 'true',
      label: 'Podrobný popis komponent'
    }
  ];

  get analogComponentsControlForPaper(): IczFormGroup {
    return this.form?.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormGroup;
  }

  get analogComponentsControlForMedium(): IczFormGroup {
    return this.form?.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormGroup;
  }

  get analogComponentsControlForPhysicalItem(): IczFormGroup {
    return this.form?.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormGroup;
  }

  componentsExpanded = {paperComponents: true, mediumComponents: true, physicalItemComponents: true};

  addPaperComponent() {
    this.paperComponentsCreateFormComponent?.addAnotherComponent();
  }

  addMediumComponent() {
    this.mediumComponentsCreateFormComponent?.addAnotherComponent();
    this.componentsExpanded.mediumComponents = true;
  }

  addPhysicalItemComponent() {
    this.physicalItemsComponentsCreateFormComponent?.addAnotherComponent();
    this.componentsExpanded.physicalItemComponents = true;
  }

  ngOnInit() {
    this.componentDetailedInputChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((value: Nullable<string>) => {
      this.analogComponentsConfig = {...this.analogComponentsConfig, isDetailedInput: value as 'true' | 'false'};
    });
  }

}
