/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EnvelopeTemplateDto } from '../models/envelope-template-dto';
import { envelopeTemplatesCheckUpdatePermisssion } from '../fn/envelope-templates/envelope-templates-check-update-permisssion';
import { EnvelopeTemplatesCheckUpdatePermisssion$Params } from '../fn/envelope-templates/envelope-templates-check-update-permisssion';
import { envelopeTemplatesCreate } from '../fn/envelope-templates/envelope-templates-create';
import { EnvelopeTemplatesCreate$Params } from '../fn/envelope-templates/envelope-templates-create';
import { envelopeTemplatesDelete } from '../fn/envelope-templates/envelope-templates-delete';
import { EnvelopeTemplatesDelete$Params } from '../fn/envelope-templates/envelope-templates-delete';
import { envelopeTemplatesFindAll } from '../fn/envelope-templates/envelope-templates-find-all';
import { EnvelopeTemplatesFindAll$Params } from '../fn/envelope-templates/envelope-templates-find-all';
import { envelopeTemplatesFindById } from '../fn/envelope-templates/envelope-templates-find-by-id';
import { EnvelopeTemplatesFindById$Params } from '../fn/envelope-templates/envelope-templates-find-by-id';
import { envelopeTemplatesFindEnvelopeTemplates } from '../fn/envelope-templates/envelope-templates-find-envelope-templates';
import { EnvelopeTemplatesFindEnvelopeTemplates$Params } from '../fn/envelope-templates/envelope-templates-find-envelope-templates';
import { envelopeTemplatesGetTemplatePreview } from '../fn/envelope-templates/envelope-templates-get-template-preview';
import { EnvelopeTemplatesGetTemplatePreview$Params } from '../fn/envelope-templates/envelope-templates-get-template-preview';
import { envelopeTemplatesUpdate } from '../fn/envelope-templates/envelope-templates-update';
import { EnvelopeTemplatesUpdate$Params } from '../fn/envelope-templates/envelope-templates-update';
import { EnvelopeValidateResponseDto } from '../models/envelope-validate-response-dto';
import { PageEnvelopeTemplateDto } from '../models/page-envelope-template-dto';


/**
 * Contains endpoints related to Envelope Templates
 */
@Injectable({ providedIn: 'root' })
export class ApiEnvelopeTemplatesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `envelopeTemplatesFindById()` */
  static readonly EnvelopeTemplatesFindByIdPath = '/sad/envelope-templates/{id}';

  /**
   * Envelope Template Get.
   *
   * Gets envelope template by id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesFindById$Response(params: EnvelopeTemplatesFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<EnvelopeTemplateDto>> {
    return envelopeTemplatesFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Envelope Template Get.
   *
   * Gets envelope template by id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesFindById(params: EnvelopeTemplatesFindById$Params, context?: HttpContext): Observable<EnvelopeTemplateDto> {
    return this.envelopeTemplatesFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnvelopeTemplateDto>): EnvelopeTemplateDto => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesUpdate()` */
  static readonly EnvelopeTemplatesUpdatePath = '/sad/envelope-templates/{id}';

  /**
   * Envelope Template Update.
   *
   * Updates Envelope Template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesUpdate$Response(params: EnvelopeTemplatesUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EnvelopeTemplateDto>> {
    return envelopeTemplatesUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Envelope Template Update.
   *
   * Updates Envelope Template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesUpdate(params: EnvelopeTemplatesUpdate$Params, context?: HttpContext): Observable<EnvelopeTemplateDto> {
    return this.envelopeTemplatesUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnvelopeTemplateDto>): EnvelopeTemplateDto => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesDelete()` */
  static readonly EnvelopeTemplatesDeletePath = '/sad/envelope-templates/{id}';

  /**
   * Envelope Template Deletion.
   *
   * Deletes of envelope template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesDelete$Response(params: EnvelopeTemplatesDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return envelopeTemplatesDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Envelope Template Deletion.
   *
   * Deletes of envelope template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesDelete(params: EnvelopeTemplatesDelete$Params, context?: HttpContext): Observable<void> {
    return this.envelopeTemplatesDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesGetTemplatePreview()` */
  static readonly EnvelopeTemplatesGetTemplatePreviewPath = '/sad/envelope-templates/template-preview/{format}';

  /**
   * Template Preview.
   *
   * Gets envelope preview PDF based on template definition.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesGetTemplatePreview()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesGetTemplatePreview$Response(params: EnvelopeTemplatesGetTemplatePreview$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return envelopeTemplatesGetTemplatePreview(this.http, this.rootUrl, params, context);
  }

  /**
   * Template Preview.
   *
   * Gets envelope preview PDF based on template definition.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesGetTemplatePreview$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesGetTemplatePreview(params: EnvelopeTemplatesGetTemplatePreview$Params, context?: HttpContext): Observable<Array<string>> {
    return this.envelopeTemplatesGetTemplatePreview$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesCheckUpdatePermisssion()` */
  static readonly EnvelopeTemplatesCheckUpdatePermisssionPath = '/sad/envelope-templates/check-update-permisssion';

  /**
   * Envelope Template Validate Update Permission.
   *
   * Checks if logged fp i permitted to update action.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesCheckUpdatePermisssion()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesCheckUpdatePermisssion$Response(params: EnvelopeTemplatesCheckUpdatePermisssion$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EnvelopeValidateResponseDto>>> {
    return envelopeTemplatesCheckUpdatePermisssion(this.http, this.rootUrl, params, context);
  }

  /**
   * Envelope Template Validate Update Permission.
   *
   * Checks if logged fp i permitted to update action.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesCheckUpdatePermisssion$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesCheckUpdatePermisssion(params: EnvelopeTemplatesCheckUpdatePermisssion$Params, context?: HttpContext): Observable<Array<EnvelopeValidateResponseDto>> {
    return this.envelopeTemplatesCheckUpdatePermisssion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnvelopeValidateResponseDto>>): Array<EnvelopeValidateResponseDto> => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesFindAll()` */
  static readonly EnvelopeTemplatesFindAllPath = '/sad/envelope-templates/';

  /**
   * Finds all envelope templates.
   *
   * Returns all envelope templates.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesFindAll$Response(params?: EnvelopeTemplatesFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EnvelopeTemplateDto>>> {
    return envelopeTemplatesFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all envelope templates.
   *
   * Returns all envelope templates.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesFindAll(params?: EnvelopeTemplatesFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<EnvelopeTemplateDto>> {
    return this.envelopeTemplatesFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EnvelopeTemplateDto>>): Array<EnvelopeTemplateDto> => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesCreate()` */
  static readonly EnvelopeTemplatesCreatePath = '/sad/envelope-templates/';

  /**
   * Envelope Template Create.
   *
   * Creates a new envelope template.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesCreate$Response(params: EnvelopeTemplatesCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EnvelopeTemplateDto>> {
    return envelopeTemplatesCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Envelope Template Create.
   *
   * Creates a new envelope template.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  envelopeTemplatesCreate(params: EnvelopeTemplatesCreate$Params, context?: HttpContext): Observable<EnvelopeTemplateDto> {
    return this.envelopeTemplatesCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EnvelopeTemplateDto>): EnvelopeTemplateDto => r.body)
    );
  }

  /** Path part for operation `envelopeTemplatesFindEnvelopeTemplates()` */
  static readonly EnvelopeTemplatesFindEnvelopeTemplatesPath = '/sad/envelope-templates/search-api';

  /**
   * Finds envelope templates.
   *
   * Returns envelope templates for the passed search predicate.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `envelopeTemplatesFindEnvelopeTemplates()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesFindEnvelopeTemplates$Response(params?: EnvelopeTemplatesFindEnvelopeTemplates$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEnvelopeTemplateDto>> {
    return envelopeTemplatesFindEnvelopeTemplates(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds envelope templates.
   *
   * Returns envelope templates for the passed search predicate.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `envelopeTemplatesFindEnvelopeTemplates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  envelopeTemplatesFindEnvelopeTemplates(params?: EnvelopeTemplatesFindEnvelopeTemplates$Params|null|undefined, context?: HttpContext): Observable<PageEnvelopeTemplateDto> {
    return this.envelopeTemplatesFindEnvelopeTemplates$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEnvelopeTemplateDto>): PageEnvelopeTemplateDto => r.body)
    );
  }

}
