/* tslint:disable */
/* eslint-disable */

/**
 * Type of permission a member of filing office can have.
 */
export enum FilingOfficeMemberPermissionType {
  DELIVERY = 'DELIVERY',
  PERSONAL_DELIVERY = 'PERSONAL_DELIVERY'
}
