import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {SubjectTableColumnSet} from '../subjects-table/subjects-table/subjects-table.component';
import {InMemorySearchDatasource} from '../../../form-elements/form-popup-table-selector/in-memory-search.datasource';
import {SubjectsTableAction, SubjectsTableActionCommand} from '../subjects-table/subjects-table-actions';
import {SubjectRecordSource, SubjectRecordWithSourceDto} from '../../model/subjects.model';
import {SubjectsService} from '../../../../services/subjects.service';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';
import {SubjectRecordDto} from '|api/commons';

export enum SelectSubjectDialogResultAction {
  SELECTED_EXISTING = 'SELECTED_EXISTING',
  CREATE_NEW = 'CREATE_NEW',
}

export interface SelectSubjectDialogResult {
  result: Nullable<SelectSubjectDialogResultAction>,
  subject?: SubjectRecordWithSourceDto,
}

@Component({
  selector: 'icz-select-subject-dialog',
  templateUrl: './select-subject-dialog.component.html',
  styleUrls: ['./select-subject-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectSubjectDialogComponent implements OnInit {

  protected modalRef = injectModalRef<Nullable<SelectSubjectDialogResult>>();
  private destroyRef = inject(DestroyRef);
  private subjectsService = inject(SubjectsService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected searchedSubjects = injectModalData<SubjectRecordDto[]>();

  dataSource = new InMemorySearchDatasource(() => []);

  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly loaderIdIsdsFind = 'ISDS_FIND';

  ngOnInit(): void {
    if (this.searchedSubjects?.length > 0) {
      this.dataSource.setDataFactory(() => this.searchedSubjects);
    }
  }

  closeAsCreateNew() {
    this.closeModal(SelectSubjectDialogResultAction.CREATE_NEW);
  }

  private executeModalAction(action: SubjectsTableAction, subject: SubjectRecordWithSourceDto) {
    switch (action) {
      case SubjectsTableAction.LINK_SUBJECT:
        this.closeModal(SelectSubjectDialogResultAction.SELECTED_EXISTING, subject);
        break;
    }
  }

  actionExecuted(command: SubjectsTableActionCommand) {
    if (command.subject.subjectSource === SubjectRecordSource.ISDS_SEARCH) {
      this.loadingIndicatorService.doLoading(
        this.subjectsService.findUsingIsdsFind(command.subject).pipe(takeUntilDestroyed(this.destroyRef)),
        this,
        this.loaderIdIsdsFind,
      ).subscribe(subjectFromIsdsFind => {
        if (subjectFromIsdsFind) {
          this.executeModalAction(command.action, subjectFromIsdsFind);
        }
      });
    } else {
      this.executeModalAction(command.action, command.subject);
    }
  }

  closeModal(action: SelectSubjectDialogResultAction, row?: SubjectRecordWithSourceDto) {
    this.modalRef.close({result: action, subject: row});
  }

}
