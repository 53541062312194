<div [formGroup]="searchForm" class="sticky-header">
  @if (showBackToApp) {
    <icz-button label="Zpět do aplikace" svgIcon="backtoapp" background noBorder noCentered (onAction)="backToApp()" class="back-to-app-styling"></icz-button>
  }
  @if (showSearch) {
    <icz-form-field formControlName="searchItem" placeholder="Hledej v navigačním stromu" class="find-field-style" [clearable]="true" [showValidationStatus]="false" ></icz-form-field>
  }
</div>
<mat-accordion [multi]="isSearchActive" class="scroll-vertically">
  @for (menuButton of _menuData; track menuButton.id) {
    @if (menuButton.children) {
      <mat-expansion-panel hideToggle [testingFeature]="menuButton.isTestingFeature!"
        [expanded]="expandedMenuItemsDictionary[menuButton.id] || isSearchActive"
        [blockingOverlayOpts]="{noOpacity: true, cursorNotAllowed: true}">
        <mat-expansion-panel-header collapsedHeight="3rem" expandedHeight="3rem">
          <icz-button class="top-level-navigation-button grow" [attr.data-cy]="menuButton.id"
            [class.no-icon]="!menuButton.icon" [svgIcon]="menuButton.icon!"
          noCentered noBorder [label]="getMenuItemLabel(menuButton)"></icz-button>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <div class="col">
            @for (secondLvlButton of menuButton.children; track secondLvlButton.id) {
              <ng-container [ngTemplateOutlet]="secondLvlButton.children ? expansionPanel : routingButton"
              [ngTemplateOutletContext]="{button : secondLvlButton}"></ng-container>
            }
          </div>
        </ng-template>
      </mat-expansion-panel>
    }
    @if (!menuButton.children) {
      <div class="items-center row" [testingFeature]="menuButton.isTestingFeature!" style="height: 3rem;"
        [blockingOverlayOpts]="{noOpacity: true, cursorNotAllowed: true}">
        <icz-button class="top-level-navigation-button grow"
          [class.no-icon]="!menuButton.icon"
          [routerLink]="getMenuItemUrl(menuButton)"
          [queryParams]="getMenuItemQueryParams(menuButton)"
          [ngClass]="{'active-link': isLinkActive(menuButton.id)}"
          [svgIcon]="menuButton.icon!" noCentered noBorder
          [attr.data-cy]="menuButton.id"
          [label]="getMenuItemLabel(menuButton)"
        ></icz-button>
      </div>
    }
  }
</mat-accordion>

<ng-template #expansionPanel let-button='button'>

  <mat-expansion-panel #expansionButton hideToggle [testingFeature]="button.isTestingFeature"
    [expanded]="expandedMenuItemsDictionary[button.id] || isSearchActive"
    [blockingOverlayOpts]="{noOpacity: true, cursorNotAllowed: true}">
    <mat-expansion-panel-header collapsedHeight="2.25rem" expandedHeight="2.25rem">
      <a mat-button [attr.data-cy]="'routerLink_' + button.id" class="expansion-mat-button grow">
        <div class="row grow no-highlight-row">
          @if (!expansionButton.expanded) {
            <icz-icon
              svgIcon="after" size="small"
            class="expansion-icon"></icz-icon>
          }
          @if (expansionButton.expanded) {
            <icz-icon
              svgIcon="expand_more" size="small"
            class="expansion-icon"></icz-icon>
          }
          <div>
            {{ getMenuItemLabel(button) }}
          </div>
        </div>
      </a>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent>
      <div class="col">
        @for (childButton of button.children; track childButton.id) {
          <ng-container [ngTemplateOutlet]="childButton.children ? expansionPanel : routingButton"
          [ngTemplateOutletContext]="{button : childButton}"></ng-container>
        }
      </div>
    </ng-template>
  </mat-expansion-panel>

</ng-template>

<ng-template #routingButton let-button='button'>

  <a mat-button
    [routerLink]="getMenuItemUrl(button)"
    [queryParams]="getMenuItemQueryParams(button)"
    [ngClass]="{'active-link': isLinkActive(button.id)}"
    [attr.data-cy]="'routerLink_' + button.id"
    [testingFeature]="button.isTestingFeature"
    [blockingOverlayOpts]="{noOpacity: true, cursorNotAllowed: true}">
    <div class="row link-button-content">
      @if (getMenuItemLabel(button); as itemLabel) {
        <div [iczTooltip]="itemLabel"
          class="link-button-text grow" [iczTooltipShowDelay]="1000">
          <span [iczHighlightText]="itemLabel" [iczHighlightText.if]="isSearchActive" [iczHighlightText.offsets]="getHighlightOffset(button.name)"></span>
        </div>
      }
      @if (button.actionButtons?.length) {
        @for (actionButton of button.actionButtons; track actionButton.name) {
          <icz-button
            class="menu-item-action-button"
            background size="tiny"
            [iczTooltip]="actionButton.name"
            [svgIcon]="actionButton.icon"
            (onAction)="handleActionButtonClick($event, actionButton)"
            [testingFeature]="actionButton.isTestingFeature"
            testingFeatureLabel="NYI"
            [blockingOverlayOpts]="{noOpacity: true, cursorNotAllowed: true}"
          ></icz-button>
        }
      }
    </div>
  </a>

</ng-template>
