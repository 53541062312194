import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {
  ApiDocumentService,
  DisposalScheduleComputationDto,
  DisposalScheduleComputationInnerRequestDto,
  DisposalScheduleComputationRequestDto,
  DisposalScheduleComputationResponseDto,
  DocumentBulkSettlementDto,
  DocumentDto,
  DocumentPrepareSettlementDto,
  DocumentSettlementByAcknowledgementCreateDto,
  DocumentSettlementByDocumentCreateDto,
  DocumentSettlementByRecordCreateDto
} from '|api/document';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {
  adjustSettlementDate,
  DefaultDocumentSettlement,
  getSettlementFormGroup,
  getSettlementGeneralTabForm
} from './document-settle-model';
import {SettlementType} from '|api/commons';
import {isOwnDocumentObject} from '../../../shared-document.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {Option} from '../../../../../model';
import {debounceTime, switchMap} from 'rxjs/operators';
import {TabsComponent} from '../../../../essentials/tabs/tabs.component';
import {combineLatest, Observable, of} from 'rxjs';
import {DocumentToastService, DocumentToastType} from '../../../../../core/services/notifications/document-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {AbstractObjectSettleDialogComponent} from './abstract-object-settle-dialog.component';
import {injectModalData, injectModalRef} from '../../../../../lib/modals';


@Component({
  selector: 'icz-document-settle-dialog',
  templateUrl: './document-settle-dialog.component.html',
  styleUrls: ['./document-settle-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class DocumentSettleDialogComponent extends AbstractObjectSettleDialogComponent<DocumentDto> implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private apiDocumentService = inject(ApiDocumentService);
  private documentToastService = inject(DocumentToastService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected override objects = injectModalData<DocumentDto[]>();

  @ViewChild('tabsComponent') tabsComponent!: TabsComponent;

  formGroupsToCheck!: string[];

  protected override generalTabForm = getSettlementGeneralTabForm();
  minSettlementDate!: Date;
  globalSettlementTypeOptions!: Option[];
  globalDisposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;
  disposalSchedulePreparePerDocument: Nullable<DisposalScheduleComputationDto[]>;

  get isSingleResolution() {
    return this.objects.length === 1;
  }

  get isBulkResolution() {
    return this.objects.length > 1;
  }

  private initForms() {
    this.objects.forEach(d => {
      let preselectedSettlementType = SettlementType.BY_ACKNOWLEDGEMENT;

      if (isOwnDocumentObject(d)) {
        preselectedSettlementType = SettlementType.BY_DOCUMENT;
      }

      this.forms[d.id.toString()] = getSettlementFormGroup(d, preselectedSettlementType);
      this.tabsCache[d.id.toString()] = {prepared: false, classificationScheme: null};
    });
    Object.keys(this.forms).forEach(docId => {
      const formProp = `forms.${docId}` as keyof DocumentSettleDialogComponent;
      this.checkUnsavedService.addUnsavedFormCheck(this, [formProp]);
    });
  }

  private toggleShowValidity(show: boolean) {
    this.tabs.forEach(tab => {
      if (tab.id !== this.GENERAL_TAB) {
        tab.showTabValidity = show;
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  getDisposalSchedulePrepareForDocument(documentId: number): Nullable<DisposalScheduleComputationDto> {
    if (this.disposalSchedulePreparePerDocument) {
      return this.disposalSchedulePreparePerDocument.find(dsp => dsp.entityId === documentId)!;
    } else {
      return null;
    }
  }

  onDisposalScheduleSourceChange() {
    const computationInnerRequest: DisposalScheduleComputationInnerRequestDto[] = [];

    this.objects.forEach(d => {
      const formValue = this.forms[d.id.toString()].getRawValue();
      computationInnerRequest.push({
        documentTypeId: formValue.documentTypeId,
        entityClassId: formValue.entityClassId,
        entityId: d.id,
      });
    });

    this.apiDocumentService.documentGetValidDisposalSchedules(
      {
        body: {
          inner: computationInnerRequest,
          referenceDate: this.generalTabForm.get('settlementDate'!)!.value!
        }
      }
    ).subscribe(result => {
      this.globalDisposalSchedulePrepare = result.globalResult;
      this.disposalSchedulePreparePerDocument = result.resultsPerEntity;
      this.patchFormsDisposalSchedulePrepare(result);
    });
  }

  private patchFormsDisposalSchedulePrepare(prepare: DisposalScheduleComputationResponseDto) {
    if (prepare.resultsPerEntity) {
      prepare.resultsPerEntity.forEach(result => {
        const form = this.forms[result.entityId!.toString()];
        form.get('disposalScheduleId')!.setValue(result.recommendedDisposalScheduleId);
        form.get('yearOfRetentionPeriodStart')!.setValue(result.defaultYearOfRetentionPeriodStart);
        form.get('externalRetentionTriggerIds')!.setValue(result.externalRetentionTriggerIds);
      });
    }
  }

  private patchFormsFromPrepare(prepares: DocumentPrepareSettlementDto[]) {
    prepares.forEach(prepare => {
      const form = this.forms[prepare.documentId!.toString()];
      if (form) {
        form.patchValue(prepare);
        if (prepare.preselectedRelatedDocument) {
          form.get('relatedDocumentId')!.setValue(prepare.preselectedRelatedDocument.id);
          form.get('relatedDocumentTypeId')!.setValue(prepare.preselectedRelatedDocument.documentTypeId);
        }
      }
    });
    Object.values(this.forms).forEach(form => {
      form.recursivelyUpdateValueAndValidity();
    });
    this.syncFormsValidityWithTabValidity();
  }

  private setDefaultTabFieldsRestrictions() {
    if (this.objects.every(d => !isNil(d.fileId))) {
      this.generalTabForm.get('entityClassId')!.disable();
    }
    if (this.objects.every(d => d.entityClassId === this.objects[0].entityClassId)) {
      this.generalTabForm.get('entityClassId')!.setValue(this.objects[0].entityClassId);
    }
    if (this.objects.every(d => d.documentTypeId === this.objects[0].documentTypeId)) {
      this.generalTabForm.get('documentTypeId')!.setValue(this.objects[0].documentTypeId);
    }
    // settlement by document is only allowed if every document is ownDocument
    if (this.objects.every(d => isOwnDocumentObject(d))) {
      this.globalSettlementTypeOptions = enumToOptions('settlementType', SettlementType).filter(op => op.value !== SettlementType.BY_ASSIGNMENT);
      this.generalTabForm.get('documentSettlementType')!.setValue(SettlementType.BY_DOCUMENT);
    } else {
      this.globalSettlementTypeOptions = enumToOptions('settlementType', SettlementType).filter(op => op.value !== SettlementType.BY_ASSIGNMENT && op.value !== SettlementType.BY_DOCUMENT);
      this.generalTabForm.get('documentSettlementType')!.setValue(SettlementType.BY_ACKNOWLEDGEMENT);
    }
  }

  protected overrideValueInTabs(field: string, value: any) {
    Object.entries(this.forms).forEach(([docId, form]) => {
      if (field === 'entityClassId') {
        const isDocumentInFile = this.objects.find(d => d.id.toString() === docId)!.fileId;
        if (!isDocumentInFile) {
          form.get(field)?.setValue(value, {emitEvent: false});
        }
        form.recursivelyUpdateValueAndValidity();
        this.syncFormsValidityWithTabValidity();
      }
      else {
        form.get(field)?.setValue(value, {emitEvent: false});
        form.recursivelyUpdateValueAndValidity();
      }
      this.syncFormsValidityWithTabValidity();
    });
  }

  private propagateDefaultTabValueChangeToAllTabs() {
    const overrideFields: (keyof DefaultDocumentSettlement)[] = [
      'documentSettlementType',
      'settlementDate',
      'entityClassId',
      'documentTypeId',
      'content',
      'reason',
      'yearOfRetentionPeriodStart',
      'triggerEventCheckYear',
    ];
    this.setupGeneralTabValueOverride(overrideFields);
    this.generalTabForm.get('documentSettlementType')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(documentSettlementType => {
      this.processReasonAndContentFields(documentSettlementType, this.generalTabForm);
      Object.values(this.forms).forEach(formGroup => {
        this.processReasonAndContentFields(documentSettlementType, formGroup);
      });
      this.syncFormsValidityWithTabValidity();
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    if (this.isBulkResolution) {
      this.initTabs();
    }

    this.initForms();

    if (this.isBulkResolution) {
      this.propagateDefaultTabValueChangeToAllTabs();
      this.setDefaultTabFieldsRestrictions();

      const createdDates: number[] = this.objects.map(d => (new Date(d.auditInfo!.createdAt!)).getTime());
      this.minSettlementDate = new Date(Math.max(...createdDates));

      this.loadingIndicatorService.doLoading(
        combineLatest([
          this.codebookService.entityClasses(),
          this.codebookService.disposalSchedules(),
          this.apiDocumentService.documentPrepareBulkSettlement({
            dateTime: this.generalTabForm.get('settlementDate'!)!.value!,
            body: this.objects.map(d => d.id)
          }).pipe(
            switchMap(prepares => {
              const disposalSchedulesReq: DisposalScheduleComputationRequestDto = {
                inner: prepares.map(prepare => ({
                  documentTypeId: prepare.documentTypeId,
                  entityClassId: prepare.entityClassId,
                  entityId: prepare.documentId!,
                })),
                referenceDate: this.generalTabForm.get('settlementDate'!)!.value!
              };
              return this.apiDocumentService.documentGetValidDisposalSchedules({body: disposalSchedulesReq}).pipe(
                switchMap(validDisposalSchedules => {
                  return of({
                    prepares,
                    validDisposalSchedules
                  });
                })
              );
            })
          )
        ]),
        this
      ).subscribe(([ec,ds, prepareWithDisposalSchedules]) => {
        this.entityClasses = ec;
        this.disposalSchedules = ds;
        this.patchFormsFromPrepare(prepareWithDisposalSchedules.prepares);
        this.globalDisposalSchedulePrepare = prepareWithDisposalSchedules.validDisposalSchedules.globalResult;
        this.disposalSchedulePreparePerDocument = prepareWithDisposalSchedules.validDisposalSchedules.resultsPerEntity;
      });

      this.generalTabForm.get('settlementDate')!.valueChanges.pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(settlementDate => {
        if (settlementDate) {
          this.loadingIndicatorService.doLoading(
            this.apiDocumentService.documentPrepareBulkSettlement({
              dateTime: settlementDate,
              body: this.objects.map(d => d.id)
            }),
            this
          ).pipe(
            switchMap(prepares => {
              const disposalSchedulesReq: DisposalScheduleComputationRequestDto = {
                inner: prepares.map(prepare => ({
                  documentTypeId: prepare.documentTypeId,
                  entityClassId: prepare.entityClassId,
                  entityId: prepare.documentId!,
                })),
                referenceDate: this.generalTabForm.get('settlementDate'!)!.value!
              };
              return this.apiDocumentService.documentGetValidDisposalSchedules({body: disposalSchedulesReq}).pipe(
                switchMap(validDisposalSchedules => {
                  return of({
                    prepares,
                    validDisposalSchedules
                  });
                })
              );
            })
          ).subscribe(prepareWithDisposalSchedules => {
            this.patchFormsFromPrepare(prepareWithDisposalSchedules.prepares);
            this.globalDisposalSchedulePrepare = prepareWithDisposalSchedules.validDisposalSchedules.globalResult;
            this.disposalSchedulePreparePerDocument = prepareWithDisposalSchedules.validDisposalSchedules.resultsPerEntity;
            this.patchFormsDisposalSchedulePrepare(prepareWithDisposalSchedules.validDisposalSchedules);
          });
        }
      });
    }
  }

  private getSingleSettlementDto(settlementType: SettlementType, formValue: any, documentId: number, isBulk?: boolean): DocumentSettlementByRecordCreateDto |
    DocumentSettlementByAcknowledgementCreateDto | DocumentSettlementByDocumentCreateDto | DocumentBulkSettlementDto {
    let result;
    const settlementDate = adjustSettlementDate(formValue.settlementDate!);

    switch (settlementType) {
      case SettlementType.BY_ACKNOWLEDGEMENT:
        result = {
          documents: [{
            excludedComponentIds: formValue.excludedComponentIds,
            documentId,
            documentTypeId: formValue.documentTypeId
          }],
          settlementDate,
          entityClassId: formValue.entityClassId,
          disposalScheduleId: formValue.disposalScheduleId,
          yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
          triggerEventCheckYear: formValue.triggerEventCheckYear
        } as DocumentSettlementByAcknowledgementCreateDto;
        break;
      case SettlementType.BY_RECORD:
        result = {
          documents: [{
            excludedComponentIds: formValue.excludedComponentIds,
            documentId,
            documentTypeId: formValue.documentTypeId
          }],
          settlementDate,
          entityClassId: formValue.entityClassId,
          reason: formValue.reason,
          content: formValue.content,
          disposalScheduleId: formValue.disposalScheduleId,
          yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
          triggerEventCheckYear: formValue.triggerEventCheckYear
        } as DocumentSettlementByRecordCreateDto;
        break;
      case SettlementType.BY_DOCUMENT:
        result = {
          settlementDate,
          documents: [{
            excludedComponentIds: formValue.excludedComponentIds,
            documentId,
            documentTypeId: formValue.documentTypeId
          }],
          entityClassId: formValue.entityClassId,
          relatedDocumentId: formValue.relatedDocumentId,
          disposalScheduleId: formValue.disposalScheduleId,
          yearOfRetentionPeriodStart: formValue.yearOfRetentionPeriodStart,
          triggerEventCheckYear: formValue.triggerEventCheckYear
        } as DocumentSettlementByDocumentCreateDto;

        if (isNil(formValue.relatedDocumentId)) {
          result.relatedDocumentId = documentId;
        }
        else {
          result.documents?.push({
            excludedComponentIds: formValue.excludedRelatedDocumentComponentIds,
            documentId: formValue.relatedDocumentId,
            documentTypeId: formValue.relatedDocumentTypeId
          });
        }
        break;
    }
    if (isBulk) {
      (result as DocumentBulkSettlementDto).settlementType = settlementType;
      (result as DocumentBulkSettlementDto).documentId = documentId;
    }

    return result as DocumentSettlementByRecordCreateDto |
      DocumentSettlementByAcknowledgementCreateDto | DocumentSettlementByDocumentCreateDto | DocumentBulkSettlementDto;
  }

  submit() {
    if (this.isSingleResolution) {
      const formValue = this.forms[this.objects[0].id].getRawValue();

      const settlementType = this.forms[this.objects[0].id].get('documentSettlementType')?.value as SettlementType;

      let request$ = new Observable<unknown>();

      const body = this.getSingleSettlementDto(settlementType, formValue, this.objects[0].id);
      if (settlementType === SettlementType.BY_RECORD) {
        request$ = this.apiDocumentService.documentSettleDocumentByRecord({id: this.objects[0].id, body: body as DocumentSettlementByRecordCreateDto});
      } else if (settlementType === SettlementType.BY_DOCUMENT) {
        request$ = this.apiDocumentService.documentSettleDocumentByDocument({id: this.objects[0].id, body: body as DocumentSettlementByDocumentCreateDto});
      }  else if (settlementType === SettlementType.BY_ACKNOWLEDGEMENT) {
        request$ = this.apiDocumentService.documentSettleDocumentByAcknowledgement({id: this.objects[0].id, body: body as DocumentSettlementByAcknowledgementCreateDto});
      }

      this.loadingIndicatorService.doLoading(request$, this).subscribe(_ => {
        this.documentToastService.dispatchDocumentWarningToast(DocumentToastType.SETTLE_DOCUMENT_STARTED, {
          [InternalNotificationKey.DOCUMENT_ID]: this.objects[0].id,
          [InternalNotificationKey.DOCUMENT_SUBJECT]: this.objects[0].subject,
        });
        this.close(true);
      });
    }
    else {
      this.toggleShowValidity(true);

      const allFormsValid = Object.values(this.forms).every(f => f.valid);
      if (allFormsValid) {
        const body: Array<DocumentBulkSettlementDto> = [];
        Object.entries(this.forms).forEach(([docId, f]) => {
          body.push(this.getSingleSettlementDto(f.get('documentSettlementType')!.value, f.getRawValue(), parseInt(docId), true) as DocumentBulkSettlementDto);
        });

        this.loadingIndicatorService.doLoading(this.apiDocumentService.documentBulkSettle({body}), this).subscribe(_ => {
          this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_SETTLEMENT_STARTED, {
            [InternalNotificationKey.COUNT]: this.objects.length,
          });
          this.close(true);
        });
      }
      else {
        this.tabsComponent.selectAndScrollToFirstInvalid();
      }
    }
  }

  close(isDone?: boolean) {
    this.modalRef.close(isDone);
  }

}
