import {Component, inject, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {merge, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../icz-form-controls';
import {isImportant, isRequired} from '../icz-validators/icz-validators';
import {
  IczFieldValidationResults,
  IczValidationErrors,
  IczValidatorResult,
} from '../icz-validators/validator-decorators';
import {IczFormDirective} from '../../icz-form.directive';

function isInvalid(control: Nullable<AbstractControl>): boolean {
  return (control?.touched && control?.invalid) ?? false;
}


@Component({
  selector: 'icz-validation-errors-list',
  templateUrl: './validation-errors-list.component.html',
  styleUrls: ['./validation-errors-list.component.scss'],
})
export class ValidationErrorsListComponent implements OnInit {

  private iczForm = inject(IczFormDirective, {optional: true, skipSelf: true});

  @Input()
  set control(newControl: Nullable<IczFormControl>) {
    if (newControl && this._control !== newControl) {
      this._control = newControl;

      if (this._control.statusChanges && this._control.touchChanged && this._control.dirtinessChanged) {
        const controlChange$ = merge(
          this._control.statusChanges,
          this._control.touchChanged,
          this._control.dirtinessChanged,
        ).pipe(
          // required in order to emit "isRequired$" after component render
          startWith(null),
          map(_ => undefined),
        );
        this.isInvalid$ = controlChange$.pipe(
          map(_ => isInvalid(this._control)),
        );
        this.isRequired$ = controlChange$.pipe(
          map(_ => !isInvalid(this._control) && isRequired(this._control)),
        );
        this.isImportant$ = controlChange$.pipe(
          map(_ => !isInvalid(this._control) && !isRequired(this._control) && isImportant(this._control)),
        );
      }
    }
  }
  get control(): IczFormControl {
    return this._control;
  }

  @Input()
  set form(newForm: Nullable<IczFormGroup>) {
    if (newForm && this._form !== newForm) {
      this._form = newForm;

      if (this._form.statusChanges && this._form.touchChanged && this._form.dirtinessChanged) {
        this.isFormInvalid$ = merge(
          this._form.statusChanges,
          this._form.touchChanged,
          this._form.dirtinessChanged,
        ).pipe(
          map(_ => this.form?.touched && (this.form?.errors !== null || this.form?.invalid)),
        );
      }
    }
  }
  get form(): IczFormGroup {
    return this._form as IczFormGroup;
  }

  @Input()
  set array(newArray: Nullable<IczFormArray>) {
    if (newArray && this._array !== newArray) {
      this._array = newArray;

      if (this._array.statusChanges && this._array.touchChanged && this._array.dirtinessChanged) {
        this.isArrayInvalid$ = merge(
          this._array.statusChanges,
          this._array.touchChanged,
          this._array.dirtinessChanged,
        ).pipe(
          map(_ => this.array?.touched === true && this.array?.errors !== null),
        );
      }
    }
  }
  get array(): IczFormArray {
    return this._array;
  }

  @Input()
  showValidationStatus: boolean = true;

  @Input()
  fieldsValidationErrors: IczFieldValidationResults[] = [];

  @Input()
  showFieldErrors: boolean = false;

  @Input()
  errorMessageIfFormInvalid: Nullable<string>;

  @Input()
  wrapInAlert = false;
  @Input()
  compactAlert = false;
  @Input()
  customAlertHeading: Nullable<string>;
  @Input()
  useFocusableArea = false;

  private _control!: IczFormControl;
  private _array!: IczFormArray;
  private _form!: IczFormGroup;
  private errorCountLimit = Infinity;

  isInvalid$!: Observable<boolean>;
  isFormInvalid$!: Observable<boolean>;
  isArrayInvalid$!: Observable<boolean>;
  isRequired$!: Observable<boolean>;
  isImportant$!: Observable<boolean>;

  ngOnInit() {
    if (this.iczForm) {
      this.errorCountLimit = 1;
    }
  }

  getErrors(type: 'control' | 'form' | 'array'): IczValidatorResult[] {
    const out: IczValidatorResult[] = [];

    if (this[type] && this[type]!.errors) {
      const errors: Nullable<IczValidationErrors> = this[type]!.errors!;
      const keys = Object.keys(errors);

      for (let i = 0; i < Math.min(keys.length, this.errorCountLimit); ++i) {
        out.push(errors[keys[i]]);
      }
    }

    return out;
  }

  getFieldErrors(fieldErrors: IczFieldValidationResults): IczValidatorResult[] {
    const out: IczValidatorResult[] = [];

    if(fieldErrors.errors !== null){
      Object.entries(fieldErrors.errors!).forEach(([_, value]) => {
        out.push(value);
      });
    }

    return out;
  }

  getAlertMode() {
    return this.compactAlert ? 'compact' : 'full';
  }

}
