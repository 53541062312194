import {inject, Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {FilterOperator, isNoValueOperator} from './search-api.service';
import {DialogService} from '../core/services/dialog.service';
import {FilterItemValue, FilterSubValue} from '../components/table/filter.types';
import {FULLTEXT_SEARCH_TERM_FILTER_ID} from '../components/table/table-toolbar/saved-filters.service';
import {enumToArray, enumValuesToArray} from '../core/services/data-mapping.utils';
import {Sort} from '@angular/material/sort';
import {FilterItemValueTree, FilterTreeOperator} from '../components/table/filter-trees.utils';

interface UnparsedFilterValue {
  operator: FilterOperator;
  unparsedContent: string;
}

type FilterValuePlaceholder = string;

interface ExpressionTree {
  operator: FilterTreeOperator;
  values: Array<FilterValuePlaceholder|ExpressionTree>;
}

function isExpressionTree(val: any): val is ExpressionTree {
  return !isNil(val) && typeof val === 'object' && val.operator && val.values;
}

interface BuildExpressionTreeResult {
  tree: ExpressionTree;
  processedString: string;
}

interface FilterValuesExtractionResult {
  unparsedFilterValuesMap: Map<number, UnparsedFilterValue>;
  queryStringResiduum: string;
}

export const FILTER_PARAM_NAME = 'filter';
export const FULLTEXT_SEARCH_TERM_PARAM_NAME = 'text';
export const SORT_TERM_PARAM_NAME = 'sort';

@Injectable({
  providedIn: 'root'
})
export class SearchParamsSerializationService {

  private dialogService = inject(DialogService);

  serializeFilterValuesToString(filterValues: FilterItemValueTree): string {
    if (filterValues.values.length === 0) {
      return '';
    }
    else {
      const outParts: string[] = [];

      for (const value of filterValues.values) {
        if (value instanceof FilterItemValue) {
          if (value.id !== FULLTEXT_SEARCH_TERM_FILTER_ID && value.hasValidValue()) {
            outParts.push(this.serializeFilterItemValue(value));
          }
        }
        else {
          outParts.push(this.serializeFilterValuesToString(value as FilterItemValueTree));
        }
      }

      const serializedOutParts = outParts.filter(Boolean).join(';');

      if (filterValues.operator === FilterTreeOperator.NONE) {
        return serializedOutParts;
      }
      else if (serializedOutParts) {
        return `${filterValues.operator}(${serializedOutParts})`;
      }
      else {
        return '';
      }
    }
  }

  deserializeFilterValuesFromString(queryString: string): Nullable<FilterItemValueTree> {
    const extractionResult = this.extractFilterValuesFromQueryString(queryString);

    if (extractionResult) {
      const unparsedFilterValuesMap = extractionResult.unparsedFilterValuesMap;
      queryString = extractionResult.queryStringResiduum;

      const openingBracketCount = Array.from(queryString).filter(char => char === '(').length;
      const closingBracketCount = Array.from(queryString).filter(char => char === ')').length;

      if (openingBracketCount !== closingBracketCount) {
        throw new Error('Syntax error in search query occurred.');
      }
      else {
        const expressionTreeBuilderResult = this.buildExpressionTree(extractionResult.queryStringResiduum);

        let expressionTree = expressionTreeBuilderResult.tree;

        if (
          expressionTree.operator === FilterTreeOperator.NONE &&
          expressionTree.values.length === 1 && isExpressionTree(expressionTree.values[0])
        ) {
          expressionTree = expressionTree.values[0] as ExpressionTree;
        }

        return this.buildFilterItemValueTree(expressionTree, unparsedFilterValuesMap);
      }
    }
    else {
      throw new Error('Unable to match filter items in query string.');
    }
  }

  createParamsFromSort(sort: Nullable<Sort>): Params {
    if (!sort || sort.direction === '') {
      return {};
    }
    else {
      return {
        [SORT_TERM_PARAM_NAME]: `${sort.active},${sort.direction}`,
      };
    }
  }

  private extractFilterValuesFromQueryString(queryString: string): Nullable<FilterValuesExtractionResult> {
    const supportedOperatorsRe = enumValuesToArray(FilterOperator)
      .map(operator => `(?:${operator})`) // ?:-groups are non-capturing
      .join('|');
    const re = new RegExp(`(${supportedOperatorsRe})\\(('.+?')\\)`); // "+?" is non-greedy variant of "+"
    const unparsedFilterValuesMap = new Map<number, UnparsedFilterValue>();

    let reMatches = re.exec(queryString);
    let i = 0;

    if (reMatches) {
      do {
        unparsedFilterValuesMap.set(
          i,
          {
            operator: reMatches[1] as FilterOperator,
            unparsedContent: reMatches[2],
          }
        );

        queryString = queryString.replace(re, `__${i}__`);

        reMatches = re.exec(queryString);
        ++i;
      } while (reMatches);
    }
    else {
      return null;
    }

    return {
      unparsedFilterValuesMap,
      queryStringResiduum: queryString
    };
  }

  private buildExpressionTree(str: string, acc?: Nullable<ExpressionTree>): BuildExpressionTreeResult {
    let processedString = '';

    if (!acc) {
      acc = {
        operator: FilterTreeOperator.NONE,
        values: [],
      };
    }

    const andStart = `${FilterTreeOperator.AND}(`;
    const orStart = `${FilterTreeOperator.OR}(`;
    const bracketStart = `(`;

    while (true) {
      if (str.startsWith('__')) {
        const filterValuePlaceholderRe = /__\d+__/;
        const searchResult = filterValuePlaceholderRe.exec(str);

        if (searchResult) {
          const filterValuePlaceholder = searchResult[0];

          acc.values.push(filterValuePlaceholder);
          str = str.replace(filterValuePlaceholder, '');
          processedString += filterValuePlaceholder;
        }
      }
      else if (str.startsWith(';')) {
        str = str.replace(';', '');
        processedString += ';';
      }
      else if (str.startsWith(andStart)) {
        str = str.replace(andStart, '');
        processedString += andStart;

        const result = this.buildExpressionTree(
          str,
          {
            operator: FilterTreeOperator.AND,
            values: [],
          }
        );

        acc.values.push(result.tree);
        str = str.replace(result.processedString, '');
        processedString += result.processedString;
      }
      else if (str.startsWith(orStart)) {
        str = str.replace(orStart, '');
        processedString += orStart;

        const result = this.buildExpressionTree(
          str,
          {
            operator: FilterTreeOperator.OR,
            values: [],
          }
        );

        acc.values.push(result.tree);
        str = str.replace(result.processedString, '');
        processedString += result.processedString;
      }
      else if (str.startsWith(bracketStart)) {
        str = str.replace(bracketStart, '');
        processedString += bracketStart;

        const result = this.buildExpressionTree(str, acc);

        acc.values.push(result.tree);
        str = str.replace(result.processedString, '');
        processedString += result.processedString;
      }
      else {
        if (str.startsWith(')')) {
          str = str.replace(')', '');
          processedString += ')';
        }

        return {
          tree: acc,
          processedString,
        };
      }
    }
  }

  private serializeFilterItemValue(paramValue: FilterItemValue<string | string[]>) {
    let modelValue = paramValue.value;
    let serializedValue;

    if (!modelValue || isNoValueOperator(paramValue.operator)) {
      serializedValue = '';
    }
    else {
      if (Array.isArray(modelValue) && paramValue.originId) {
        // v is either a code or numeric ID - assuming that : is safe metadata delimiter
        modelValue = modelValue.map(v => `${paramValue.originId}:${v}`);
      }

      if (paramValue.subValues) {
        const serializedSubValues: string[] = [];

        for (const subValue of paramValue.subValues) {
          if (subValue.value) {
            let serializedSubValue;

            if (subValue.subValueId) {
              serializedSubValue = `^${subValue.subValueId}^${subValue.operator}("${subValue.value}")`; // SubValue#value can be only string, no need for JSON.stringify
            }
            else {
              serializedSubValue = `${subValue.operator}("${subValue.value}")`; // SubValue#value can be only string, no need for JSON.stringify
            }

            if (subValue.and) {
              serializedSubValue = `and(${serializedSubValue})`;
            }

            serializedSubValues.push(serializedSubValue);
          }
        }

        if (serializedSubValues.length) {
          serializedValue = `'${this.escapeJsonValue(JSON.stringify(modelValue))}','${this.escapeJsonValue(JSON.stringify(serializedSubValues))}'`;
        }
        else {
          serializedValue = `'${this.escapeJsonValue(JSON.stringify(modelValue))}'`;
        }
      }
      else {
        serializedValue = `'${this.escapeJsonValue(JSON.stringify(modelValue))}'`;
      }
    }

    if (serializedValue) {
      return `${paramValue.operator}('"${paramValue.id}"',${serializedValue})`;
    }
    else {
      return `${paramValue.operator}('"${paramValue.id}"')`;
    }
  }

  private parseSubValue(unparsedFilterSpecifier: string, paramKey: string): Nullable<UnparsedFilterValue> {
    // capture group #1 = operator, #2 = right operand
    const re = /(.+?)\((.*)\)/g; // "+?" is non-greedy variant of "+"
    const reMatches = re.exec(unparsedFilterSpecifier);

    if (reMatches) {
      const validOperators = enumToArray(FilterOperator).map(op => op[1]); // gets enum item value
      const searchOperator: FilterOperator = reMatches[1] as FilterOperator;

      if (!validOperators.find(vo => vo === searchOperator)) {
        // Neznámý filtrovací operátor "{{operatorName}}" pro kritérium "{{criterionName}}". Vyhledávání podle tohoto kritéria nebude provedeno.
        this.dialogService.showError(
          'fe.ui.unknownFilteringOperator',
            undefined,
          {
            operatorName: searchOperator,
            criterionName: paramKey,
          }
        );

        return null;
      }
      else {
        return {
          operator: searchOperator,
          unparsedContent: reMatches[2],
        };
      }
    }

    return null;
  }

  private buildFilterItemValueTree(expressionTree: ExpressionTree, unparsedFilterValuesMap: Map<number, UnparsedFilterValue>) {
    expressionTree.values = [...expressionTree.values];

    for (let i = 0; i < expressionTree.values.length; ++i) {
      if (typeof expressionTree.values[i] === 'string') {
        const valuesMapKey = Number((expressionTree.values[i] as string).replaceAll('_', ''));
        const unparsedFilterValue = unparsedFilterValuesMap.get(valuesMapKey);

        if (unparsedFilterValue) {
          (expressionTree as FilterItemValueTree).values[i] = this.parseFilterItemValue(unparsedFilterValue);
        }
        else {
          throw new Error(`Filter item key ${valuesMapKey} not found in unparsed filter values map.`);
        }
      }
      else {
        (expressionTree as FilterItemValueTree).values[i] = this.buildFilterItemValueTree(expressionTree.values[i] as ExpressionTree, unparsedFilterValuesMap);
      }
    }

    return expressionTree as FilterItemValueTree;
  }

  private parseFilterItemValue(unparsedFilterValue: UnparsedFilterValue): FilterItemValue {
    const contentParts = unparsedFilterValue.unparsedContent
      .replace(/^'/, '')
      .replace(/'$/, '')
      .split(`','`);

    const parsedFilterId = JSON.parse(this.unescapeJsonValue(contentParts[0]));
    let parsedFilterValue: Nullable<any>;
    let originId: Nullable<string>;

    if (contentParts[1]) {
      parsedFilterValue = JSON.parse(this.unescapeJsonValue(contentParts[1]));

      if (Array.isArray(parsedFilterValue!) && parsedFilterValue.length > 0) {
        parsedFilterValue = parsedFilterValue.map(v => String(v));

        if (parsedFilterValue[0].includes(':')) {
          originId = parsedFilterValue[0].split(':')[0];

          if (originId) {
            parsedFilterValue = (parsedFilterValue as string[]).map(sv => sv.split(':')[1]);
          }
        }
      }
    }

    let parsedSubValues: Nullable<FilterSubValue[]>;

    if (contentParts[2]) {
      parsedSubValues = [];

      const unparsedSearchSubValues = JSON.parse(this.unescapeJsonValue(contentParts[2]));

      for (let unparsedSubValue of unparsedSearchSubValues) {
        const parsedSubValue: Partial<FilterSubValue> = {};

        if (unparsedSubValue.startsWith('and(')) {
          parsedSubValue.and = true;
          unparsedSubValue = unparsedSubValue.replace('and(', '');
          unparsedSubValue = unparsedSubValue.replace(/\)$/g, '');
        }

        if (unparsedSubValue.startsWith('^')) {
          parsedSubValue.subValueId = unparsedSubValue.replace('^', '');
          parsedSubValue.subValueId = parsedSubValue.subValueId!.replace(/\^.*!/, '');

          unparsedSubValue = unparsedSubValue.replace(/\^.*\^/g, '');
        }

        const unparsedFilterSubValue = this.parseSubValue(unparsedSubValue, parsedFilterId);

        if (unparsedFilterSubValue) {
          parsedSubValue.operator = unparsedFilterSubValue.operator;
          parsedSubValue.value = JSON.parse(unparsedFilterSubValue.unparsedContent);

          parsedSubValues.push(parsedSubValue as FilterSubValue);
        }
      }
    }

    return new FilterItemValue(
      parsedFilterId,
      unparsedFilterValue.operator,
      parsedFilterValue,
      parsedSubValues,
      originId!,
    );
  }

  private escapeJsonValue(jsonString: string) {
    return jsonString.replaceAll(`'`, `\\'`);
  }

  private unescapeJsonValue(jsonString: string) {
    return jsonString.replaceAll(`\\'`, `'`);
  }
}
