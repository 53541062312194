import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {formatDisposalSchedule} from '../../shared-document.utils';
import {IczFormGroup} from '../../../form-elements/icz-form-controls';
import {DisposalScheduleDto, DocumentTypeDto} from '|api/codebook';
import {isNil} from 'lodash';
import {CodebookService} from '../../../../core/services/codebook.service';
import {Option} from '../../../../model';

@Component({
  selector: 'icz-document-type-selector-popup',
  templateUrl: './document-type-selector-popup.component.html',
  styleUrls: ['./document-type-selector-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTypeSelectorPopupComponent implements OnInit {
  private codebookService = inject(CodebookService);

  @Input()
  selectedSelectedDocumentId: Nullable<number>;
  @Input()
  classifierOptions: Option[] = [];

  selectedDocumentType: Nullable<DocumentTypeDto>;
  documentTypePopupForm = new IczFormGroup({});
  disposalSchedules: DisposalScheduleDto[] = [];

  getSelectedDocumentType(property: keyof DocumentTypeDto) {
    if (this.selectedSelectedDocumentId && this.classifierOptions.length > 0) {
      if (isNil(this.selectedDocumentType) || (this.selectedDocumentType && this.selectedDocumentType.id !== this.selectedSelectedDocumentId)) {
        const selectedDocumentTypeOp = this.classifierOptions.find(co => co.value === this.selectedSelectedDocumentId);
        if (selectedDocumentTypeOp) {
          this.selectedDocumentType = selectedDocumentTypeOp.data as DocumentTypeDto;
          if (this.selectedDocumentType) {
            return (this.selectedDocumentType[property]) ? String(this.selectedDocumentType[property]) : '';
          } else {
            return '';
          }
        } else {
          return '';
        }
      } else {
        return (this.selectedDocumentType[property]) ? String(this.selectedDocumentType[property]) : '';
      }
    } else {
      return '';
    }
  }

  get disposalScheduleForPopup() {
    const selectedDisposalScheduleId = this.getSelectedDocumentType('disposalScheduleId');
    if (selectedDisposalScheduleId && this.disposalSchedules.length > 0) {
      const disposalSchedule = this.disposalSchedules.find(ds => ds.id === Number(selectedDisposalScheduleId));
      return formatDisposalSchedule(disposalSchedule);
    } else {
      return '';
    }
  }

  ngOnInit(): void {
    this.codebookService.disposalSchedules().subscribe(disposalSchedules => {
      this.disposalSchedules = disposalSchedules;
    });
  }
}
