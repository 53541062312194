import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CirculationActivityState} from '|api/commons';
import {IczTagColor} from '../../essentials/tag/tag.component';


export const ACTIVITY_STATE_COLORS: Record<CirculationActivityState, IczTagColor> = {
  [CirculationActivityState.IN_PROGRESS]: 'orange',
  [CirculationActivityState.DONE]: 'green',
  [CirculationActivityState.REVOKED]: 'grey',
};

@Component({
  selector: 'icz-activity-state-tag',
  templateUrl: './activity-state-tag.component.html',
  styleUrls: ['./activity-state-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityStateTagComponent {
  @Input()
  activityState: Nullable<CirculationActivityState>;

  get tagText() {
    return isNil(this.activityState) ? 'Čeká v pořadí' : `en.circulationActivityState.${this.activityState}`;
  }

  readonly activityStateColors = ACTIVITY_STATE_COLORS;

  get activityStateColor(): IczTagColor {
    return isNil(this.activityState) ? 'grey' : this.activityStateColors[this.activityState];
  }
}
