@if (isBulkResolution && tabs.length) {
  <icz-tabs [tabs]="tabs"
            class="h-full"
    [waiting]="loadingIndicatorService.isLoading(this)"
    (tabClick)="tabChange($any($event))"
    #tabsComponent
    >
    <ng-template [iczTab]="GENERAL_TAB">
      <icz-document-settle-general-setting
        [minSettlementDate]="minSettlementDate"
        [globalSettlementTypeOptions]="globalSettlementTypeOptions"
        [disposalSchedulePrepare]="globalDisposalSchedulePrepare"
        (disposalScheduleSourceChange)="onDisposalScheduleSourceChange()"
        [form]="generalTabForm"
        [documents]="objects"
        >
      </icz-document-settle-general-setting>
    </ng-template>
    @for (document of objects; track document.id) {
      <ng-template [iczTab]="document?.id!.toString()">
        @if (document) {
          <icz-document-settle
            class="settle-dialog-height"
            [document]="document"
            [form]="forms[document.id.toString()]"
            [showWizardSubmitBar]="false"
            [showDocumentInfo]="true"
            [disposalSchedulePrepare]="getDisposalSchedulePrepareForDocument(document.id)"
            [settlementTabState]="tabsCache[document.id.toString()]"
            (settlementTabStateChange)="settlementTabStateChange(document.id.toString(), $event)"
            (settlementValid)="tabValidityChanged(document.id.toString(), $event)"
          ></icz-document-settle>
        }
      </ng-template>
    }
  </icz-tabs>
}
@else {
  <icz-document-settle [document]="objects[0]"
                       (submit)="submit()"
                       (closed)="close($event)"
                       [settlementTabState]="tabsCache[objects[0].id.toString()]"
                       (settlementTabStateChange)="settlementTabStateChange(objects[0].id.toString(), $event)"
                       [form]="forms[objects[0].id.toString()]"
  ></icz-document-settle>
}
@if (isBulkResolution) {
  <icz-form-buttons>
    <icz-button leftButtons label="Vyřídit" primary
    (onAction)="submit()"></icz-button>
    <icz-button rightButtons (onAction)="close()" label="Storno"></icz-button>
  </icz-form-buttons>
}
