<div class="layout col">
  <div class="header row" [formGroup]="form">
    <div class="grow">
      <div class="items-center justify-between row">
        <div class="items-center row">
          <icz-heading label="Upozornění" level="3"></icz-heading>
          <icz-checkbox [fieldDisabled]="isLoading$ | async" label="Ukázat jen nepřečtené" formControlName="showOnlyUnread" class="ml-12"></icz-checkbox>
        </div>
        <icz-button class="flex-grow-0" svgIcon="close" background disableRipple (onAction)="closeSidebar()" size="small"></icz-button>
      </div>
    </div>
  </div>
  @if (notificationsTotalCount$ | async) {
    <div class="justify-between px-24 py-12 paginator row">
      <div class="row">
        <icz-label [labelContext]="notificationPageRange" label="Zobrazeno x upozornění z x"></icz-label>
      </div>
      <icz-paginator
        [pageSize]="notificationPageSize"
        [pageIndex]="notificationPageIndex"
        [length]="notificationsTotalCount$ | async"
        (pageChanged)="handleNotificationPageChange($event)"
        (rangeChanged)="handleNotificationPageRangeChange($event)">
      </icz-paginator>
    </div>
  }
  <div class="scroll-area" [ngClass]="{'scroll-area--no-notifications': (notificationsTotalCount$ | async) === 0, 'scroll-area--no-scroll': isLoading$ | async}" [waiting]="isLoading$ | async">
    @if ((notificationsTotalCount$ | async) === 0) {
      <div class="no-notifications">
        {{ 'Žádná upozornění' | translate }}
      </div>
    }
    @for (notification of notifications$ | async; track notification.id) {
      <div>
        <icz-notification-card
          [isSystemMessage]="!notification.auditInfo!.createdBy || notification.auditInfo!.createdBy === 'system'"
          [originatorInitials]="getUserAcronym(notification.auditInfo!.createdBy!)"
          class="cursor-pointer"
          (click)="onRowClick(notification)">
          <span originatorName [class.acknowledged-notification-text]="isRead(notification)">
            @if (notification.auditInfo!.createdBy! && notification.auditInfo!.createdBy! !== 'system') {
              <span>{{notification.auditInfo!.createdBy!}}</span> -
              <span>{{notification.auditInfo!.createdByFunctionalPositionId! | findFunctionalPositionById:true | async}}</span>
            }
            @if (!notification.auditInfo!.createdBy! || notification.auditInfo!.createdBy! === 'system') {
              {{ 'Zpráva zaslaná systémem' | translate }}
            }
          </span>
          <icz-notification-type-icon
            discriminator size="default"
            [notification]="notification"
            [isRead]="isRead(notification)">
          </icz-notification-type-icon>
          <icz-notification-message-header
            header
            [isRead]="isRead(notification)"
            [isBulkNotification]="notification.bulkNotification!"
            [code]="$any(notification.subjectCode)"
            [params]="getHeaderParams(notification)">
          </icz-notification-message-header>
          @if (!notification.bulkNotification) {
            <icz-notification-message-body
              body
              [code]="$any(notification.messageCode)"
              [params]="notification.parameters[0]"
              [isRead]="isRead(notification)">
            </icz-notification-message-body>
          }
          <ng-container appendix>
            <div class="date-and-status">
              <div>
                <span class="date">
                  <span class="status-class">
                    {{'Vytvořeno' | translate}}
                  </span>
                  {{ notification.auditInfo!.createdAt! | localizedDatetime }}
                </span>
              </div>
            </div>
            <div class="date-and-status">
              <div>
                <span class="status-class"
                  [class.unread]="notification.acknowledgementStatus === AcknowledgementStatus.UNREAD">
                  {{'en.acknowledgementStatus.' + notification.acknowledgementStatus | translate}}
                </span>
                <span class="date">{{notification.acknowledgedAt | localizedDatetime}}</span>
              </div>
              <div class="row place-content-center action-buttons">
                @if (notification.acknowledgementStatus === AcknowledgementStatus.UNREAD) {
                  <icz-button
                    iczTooltip="Označit jako přečtené"
                    svgIcon="opened_mail"
                    size="small"
                    (onAction)="markAsRead($event, notification)"
                  background></icz-button>
                }
                <icz-button
                  iczTooltip="Smazat"
                  svgIcon="delete"
                  size="small"
                  class="ml-16"
                  background
                (onAction)="deleteNotification($event, notification)"></icz-button>
              </div>
            </div>
          </ng-container>
        </icz-notification-card>
      </div>
    }
  </div>
  <div class="footer row" [formGroup]="form">
    <div class="gap-16 row grow">
      <icz-button class="grow"
        (onAction)="showNotificationsOverview()"
        label="Zobrazit všechna upozornění">
      </icz-button>
      <icz-button class="grow"
        [disabled]="(notificationsTotalCount$ | async)! <= 0"
        (onAction)="markAllAsRead()"
        label="Označit vše jako přečtené">
      </icz-button>
    </div>
  </div>
</div>
