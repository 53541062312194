/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PropertyDefinitionCreateDto } from '../../models/property-definition-create-dto';
import { PropertyDefinitionDto } from '../../models/property-definition-dto';

export interface PropertyDefinitionCreate$Params {
      body: PropertyDefinitionCreateDto
}

export function propertyDefinitionCreate(http: HttpClient, rootUrl: string, params: PropertyDefinitionCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyDefinitionDto>> {
  const rb = new RequestBuilder(rootUrl, propertyDefinitionCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PropertyDefinitionDto>;
    })
  );
}

propertyDefinitionCreate.PATH = '/management/definition';