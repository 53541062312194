import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  DoCheck,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {sub} from 'date-fns';
import {clone} from 'lodash';
import {forkJoin, Observable, of, Subject} from 'rxjs';
import {debounceTime, map} from 'rxjs/operators';
import {EmpowermentDto, SubjectRecordDto} from '|api/commons';
import {getOptionsByValuesList, locateOptionByValue, Option} from '../../../../model';
import {FilterOperator, getOperatorTranslationKey, isNoValueOperator} from '../../../../services/search-api.service';
import {UserSettingsService} from '../../../../services/user-settings.service';
import {IczOnChanges, IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {LocalizedDatePipe} from '../../../essentials/date.pipe';
import {LocalizedDatetimePipe} from '../../../essentials/datetime.pipe';
import {EmpowermentPipe} from '../../../essentials/empowerment.pipe';
import {AddressPipe} from '../../../form-elements/address.pipe';
import {FormChipInputComponent} from '../../../form-elements/form-chip-input/form-chip-input.component';
import {AddressCompleteDto, AddressFormat, Countries} from '../../../shared-business-components/model/addresses.model';
import {ADDRESS_SHAPE_INDICATOR} from '../../custom-filters/address-custom-filter/address-custom-filter.component';
import {IczTableFilter} from '../../custom-filters/table-filter.component';
import {FILTER_WIDGETS} from '../../filter-widgets.definition';
import {
  FilterItem,
  FilterSubValue,
  FilterType,
  isEnumFilterItem,
  isFilterWithOptions,
  isListFilterItem,
  NonemptyFilterItem
} from '../../filter.types';
import {
  addressToolbarOperators,
  booleanOperator,
  dateStatisticsToolbarOperators,
  dateToolbarOperators,
  DeferredColumnList,
  empowermentToolbarOperators,
  fileSizeToolbarOperators,
  FilterValue,
  listToolbarOperators,
  numberToolbarOperators,
  subjectRecordToolbarOperators,
  textToolbarOperators
} from '../../table.models';
import {TableToolbarService} from '../table-toolbar.service';
import {SubjectNamePipe} from '../../../shared-business-components/subjects/subject-name.pipe';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

export function getFilterItemValue(
  item: FilterItem,
  translateService: TranslateService,
  localizedDate: LocalizedDatePipe,
  localizedDatetime: LocalizedDatetimePipe,
  empowermentPipe: EmpowermentPipe,
  addressPipe: AddressPipe,
  apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,
  subjectNamePipe: SubjectNamePipe,
): Observable<string> {
  if (!item?.value) {
    return of(translateService.instant('není nastaveno'));
  }

  switch (item?.filterType) {
    case FilterType.DATE:
    case FilterType.DATE_STATISTICS:
    case FilterType.DATETIME:
      if (item.subValues?.length === 2) {
        const intervalBounds = [...item.subValues];
        intervalBounds.sort(
          // ISO 8601 strings are lexicographically comparable thus no need to convert them to unix timestamps
          (b1, b2) => b1.value! > b2.value! ? 1 : -1
        );

        const lowerBoundValue = intervalBounds[0].value;
        const upperBoundValue = sub(new Date(intervalBounds[1].value!), {days: 1}); // needed for correctly formatting upper bound

        return of(`${localizedDate.transform(lowerBoundValue)} - ${localizedDate.transform(upperBoundValue)}`);
      }
      else {
        return of(localizedDate.transform(item.value as Nullable<string>));
      }

    case FilterType.NUMBER:
      if (item.subValues?.length === 2) {
        const intervalBounds = [...item.subValues];
        intervalBounds.sort();

        const lowerBoundValue = intervalBounds[0].value;
        const upperBoundValue = intervalBounds[1].value;

        return of(`${lowerBoundValue} - ${upperBoundValue}`);
      }
      else {
        return of(String(item.value));
      }

    case FilterType.BOOLEAN:
      if (item.value) {
        return of('Ano');
      }
      else {
        return of('Ne');
      }

    case FilterType.EMPOWERMENT:
      const empowermentDto: Partial<Record<keyof EmpowermentDto, number|string>> = {};
      item.subValues?.forEach(subValue => {
        const attribute = subValue.subValueId!.replace('empowerment.', '');
        empowermentDto[attribute as keyof EmpowermentDto] = subValue.value!;
      });

      return of(empowermentPipe.transform(empowermentDto as EmpowermentDto));

    case FilterType.ADDRESS:
      const address: Partial<AddressCompleteDto> = {};
      const subValueIds: string[] = [];
      item.subValues?.forEach(subValue => {
        const attribute = subValue.subValueId!.replace(`${ADDRESS_SHAPE_INDICATOR}.`, '');
        subValueIds.push(attribute);
        address[attribute] = subValue.value!;
      });
      if (subValueIds.includes('addressLines')) {
        address._Class = AddressFormat.GenericLineAddressDto;
      }
      else {
        if (address.country === Countries.CZE) {
          address._Class = AddressFormat.CzechAddressDto;
        }
        else if (address.country === Countries.SVK) {
          address._Class = AddressFormat.SlovakAddressDto;
        }
      }

      return addressPipe.transform(address as AddressCompleteDto);

    case FilterType.SUBJECT_RECORD:
      const observables: Observable<SubjectRecordDto>[] = (item.value! as string[])!.map(
        subjectId => apiSubjectRecordNgElasticService.subjectRecordElasticGet({subjectId: parseInt(subjectId!)})
      );

      return forkJoin(observables).pipe(
        map(subjects => {
          return subjects.map(s => subjectNamePipe.transform(s)).join(', ');
        }),
      );

    default:
      if (item.filterType === FilterType.CODEBOOK || item.filterType === FilterType.ENUM) {
        if (!item?.list) {
          console.warn(`FilterItem with filterType IN {list, tree} requires to have FilterItem#list ` +
            `specified in order to render human-friendly labels. Displaying list of raw values as fallback.`);

          return of((item.value as string[])!.join(', '));
        }
        else {
          return of(
            getHumanFriendlyListLabels(item, item.originId)
              .map(l => translateService.instant(l))
              .join(', ')
          );
        }
      }
      else {
        return of(item.value as string);
      }
  }
}

function getHumanFriendlyListLabels(item: FilterItem, originId: Nullable<string>): string[] {
  item.value ??= [];

  const value: Array<string|number> = Array.isArray(item.value) ? item.value as string[] : [item.value as string];

  return value.map(value => {
    const option = locateOptionByValue(item.list ?? [], value, originId);

    return option?.label ?? '???';
  });
}


@Component({
  selector: 'icz-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    SubjectNamePipe,
    LocalizedDatePipe,
    LocalizedDatetimePipe,
    EmpowermentPipe,
    AddressPipe,
  ]
})
export class FilterItemComponent implements OnInit, AfterViewInit, IczOnChanges, DoCheck {

  private translateService = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);
  private userSettingsService = inject(UserSettingsService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private subjectNamePipe = inject(SubjectNamePipe);
  private localizedDatePipe = inject(LocalizedDatePipe);
  private localizedDatetimePipe = inject(LocalizedDatetimePipe);
  private empowermentPipe = inject(EmpowermentPipe);
  private addressPipe = inject(AddressPipe);
  private destroyRef = inject(DestroyRef);
  private tableToolbarService = inject(TableToolbarService, {optional: true});

  _item: Nullable<NonemptyFilterItem>;
  get item(): Nullable<NonemptyFilterItem> {
    return this._item;
  }

  @Input({required: true})
  set item(item: Nullable<NonemptyFilterItem>) {
    this._item = item;
  }

  @Input({required: true}) autoOpen = true;
  @Input({required: true}) isStatic = false;
  @Input() isDisabled = false;
  @Input() useAsFormElement = false;
  @Input() isLoading = false;

  @Output() valueChanged = new EventEmitter<NonemptyFilterItem>();
  @Output() cancelClicked = new EventEmitter<boolean>();

  notChosenLabel: string = '';

  @Input() set deferredFilterList(listInfo: DeferredColumnList) {
    if (this.item) {
      this.item.list = listInfo.list;

      if (this.component) {
        this.component.instance.isLoading = listInfo.isListLoading;
        this.component.changeDetectorRef.detectChanges();
      }
    }
  }

  get filterLabel() {
    return this.item ? this.getFilterItemLabel() : '';
  }

  filterValue = of('');

  get hasNoValueOperator() {
    return isNoValueOperator(this.item?.filterOption?.value);
  }

  get isFilterWithOptions() {
    return isFilterWithOptions(this.item?.filterType);
  }

  get shouldRenderChips() {
    return this.isFilterWithOptions && (
        this.item?.filterOption?.value === FilterOperator.inSet ||
        this.item?.filterOption?.value === FilterOperator.equals ||
        this.item?.filterOption?.value === FilterOperator.notEquals
      );
  }

  get isModalFilterItem() {
    return this.item?.filterType === FilterType.SUBJECT_RECORD;
  }

  get filterItemList(): Option[] {
    if (isListFilterItem(this.item)) {
      if (isEnumFilterItem(this.item) && this.item?.useCustomChips && this.item?.chipNamespace) {
        return FormChipInputComponent.getOptionsWithCustomChips(
          this.item?.list ?? [],
          null,
          this.item?.chipNamespace,
          this.userSettingsService,
        ) as Option<any, any>[];
      }
      else {
        return this.item?.list ?? [];
      }
    }
    else {
      return [];
    }
  }

  get selectedOptions() {
    return getOptionsByValuesList(
      this.filterItemList,
      (this.item?.value ?? []) as string[],
      isListFilterItem(this.item!) ? this.item?.originId : undefined
    );
  }

  get isBooleanFilter() {
    return this.item?.filterType === FilterType.BOOLEAN;
  }

  get shouldDisplayExpansionHint() {
    return this.useAsFormElement && !this.isDisabled && this.isFilterWithOptions;
  }

  @ViewChild('customFilter', {static: false, read: ViewContainerRef}) customFilter!: ViewContainerRef;

  isOpen = false;
  component: Nullable<ComponentRef<IczTableFilter>>;
  filterTypes: Option<FilterOperator>[] = [];
  reload$ = new Subject<boolean>();
  chosenFilterOperator!: FilterOperator;
  viewValue: Nullable<string | number | string[]>;

  _oldItem: Nullable<NonemptyFilterItem>;
  _oldValue: Nullable<string | string[]>;
  _oldSubValues: Nullable<FilterSubValue[]>;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.item && changes.item.currentValue) {
      const item: FilterItem = changes.item.currentValue;

      this.filterTypes = this.getFilterOperatorsByType(item.filterType);

      if (item && !item.filterOption) {
        item.filterOption = this.getDefaultFilterOperatorOption(item);

        if (item.filterOption) {
          this.chosenFilterOperator = item.filterOption.value;
        }
      }

      this.item = item;
    }
  }

  ngDoCheck() {
    if (this.item !== this._oldItem || this.item?.value !== this._oldValue || this.item?.subValues !== this._oldSubValues) {
      this.filterValue = this.getFilterValue();

      this._oldItem = this.item;
      this._oldValue = this.item?.value;
      this._oldSubValues = this.item?.subValues;
    }
  }

  ngOnInit() {
    this.tableToolbarService?.reloadFilters$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(_ => this.cd.detectChanges());

    if (!this.useAsFormElement) {
      this.notChosenLabel = 'Vše';

      this.reload$.pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(closeAfter => {
        this.tableToolbarService?.reloadFilters();
        if (closeAfter) this.closePopup();
        this.cd.detectChanges();
      });
    }
  }

  cancel($event?: Event) {
    $event?.stopPropagation();
    let isCancelWithEmptyValue = false;
    const emptyFilterValue: FilterValue = {
      value: null,
      label: null,
      filterOperator: null,
      list: this.item?.list,
    };

    if (this.item) {
      if (!isNil(this.item.value) && this.item.value !== '') {
        this.setFilterValue(emptyFilterValue);
        this.resetFilterOperator();
      }
      else if (this.item.filterOption!.value === FilterOperator.empty || this.item.filterOption!.value === FilterOperator.notEmpty) {
        this.resetFilterOperator();
        this.setFilterValue(emptyFilterValue);
      }
      else {
        if (!this.useAsFormElement) {
          this.tableToolbarService?.removeFilterItem(this.item);
        }
        else {
          this.resetFilterOperator();
        }
        isCancelWithEmptyValue = true;
      }
    }

    this.cancelClicked.next(isCancelWithEmptyValue);
  }

  openPopup($event?: Event) {
    $event?.stopPropagation();

    if (!this.isDisabled) {
      if (this.isBooleanFilter) return;

      this.isOpen = true;
      this.component = undefined;
      setTimeout(() => this.createFilterContent(), 0);
    }
  }

  ngAfterViewInit(): void {
    if (this.autoOpen) this.openPopup();
    else this.isOpen = false;
    this.cd.detectChanges();
  }

  createFilterContent() {
    if (this.item) {
      const componentConstructor = FILTER_WIDGETS[this.item.filterType];

      if (componentConstructor) {
        this.component = this.customFilter.createComponent(componentConstructor) as ComponentRef<IczTableFilter>;
        this.component.instance.item = this.item;
        this.component.instance.filterOperators = this.filterTypes;
        this.component.instance.isLoading = this.isLoading;

        this.component.instance.setFilterValue.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe((res: FilterValue) => {
          this.setFilterValue(res);
        });

        this.component.instance.closePopup.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => this.closePopup());

        this.component.changeDetectorRef.detectChanges();
      }
    }
  }

  setFilterValue(filterValue: FilterValue) {
    if (this.item) {
      const filterOperator = filterValue.filterOperator as FilterOperator;
      this.viewValue = filterValue.viewValue!;
      this.item.value = filterValue.value;
      this.item.subValues = filterValue.subValues;
      this.item.label = filterValue.label ? filterValue.label : filterValue.value as string;
      this.item.filterOption = {value: filterOperator, label: getOperatorTranslationKey(filterOperator)};
      this.item.list = filterValue.list;
      this.chosenFilterOperator = filterValue.filterOperator!;
      this.valueChanged.next(clone(this.item));

      if (!this.useAsFormElement) {
        this.reload$.next(Boolean(filterValue.closeAfter));
      }
    }
  }

  closePopup() {
    this.isOpen = false;
    this.component = null;
  }

  booleanFilterChanged($event: any) {
    this.setFilterValue({filterOperator: FilterOperator.equals, label: $event.toString(), value: $event.toString()});
  }

  private getFilterItemLabel() {
    let out = this.item?.columnLabel ? this.translateService.instant(this.item.columnLabel) : '';

    if (!this.useAsFormElement && !this.item?.filterOption) throw new Error('Filter operators for this filter type not defined!');

    if (this.item && !this.useAsFormElement && !isNoValueOperator(this.item.filterOption!.value) && !this.item.value) {
      out += ': ' + this.translateService.instant(
        this.item.filterOption!.placeholder ?
          this.item.filterOption!.placeholder :
          this.notChosenLabel
      );
    }

    return out;
  }

  private getDefaultFilterOperatorOption(c: FilterItem): Option<FilterOperator> {
    const availableOperators = this.getFilterOperatorsByType(c.filterType);

    if (c.filterType === FilterType.TEXT) {
      return availableOperators.find(o => o.value === FilterOperator.contains)!;
    }
    else if (c.filterType === FilterType.ENUM || c.filterType === FilterType.CODEBOOK) {
      return availableOperators.find(o => o.value === FilterOperator.equals)!;
    }
    else if (availableOperators) {
      return availableOperators[0];
    }
    else {
      // failsafe defaults
      return {label: getOperatorTranslationKey(FilterOperator.equals), value: FilterOperator.equals, icon: 'equal'};
    }
  }

  private getFilterOperatorsByType(toolbarType: FilterType): Array<Option<FilterOperator>> {
    switch (toolbarType) {
      case FilterType.ENUM:
      case FilterType.CODEBOOK:
        return listToolbarOperators;
      case FilterType.DATE:
      case FilterType.DATETIME:
        return dateToolbarOperators;
      case FilterType.DATE_STATISTICS:
        return dateStatisticsToolbarOperators;
      case FilterType.NUMBER:
        return numberToolbarOperators;
      case FilterType.TEXT:
        return textToolbarOperators;
      case FilterType.BOOLEAN:
        return booleanOperator;
      case FilterType.FILE_SIZE:
        return fileSizeToolbarOperators;
      case FilterType.EMPOWERMENT:
        return empowermentToolbarOperators;
      case FilterType.SUBJECT_RECORD:
        return subjectRecordToolbarOperators;
      case FilterType.ADDRESS:
        return addressToolbarOperators;
      default:
        return [];
    }
  }

  private getFilterValue() {
    return this.item?.value ?
      getFilterItemValue(
        this.item,
        this.translateService,
        this.localizedDatePipe,
        this.localizedDatetimePipe,
        this.empowermentPipe,
        this.addressPipe,
        this.apiSubjectRecordNgElasticService,
        this.subjectNamePipe,
      ) :
      of('');
  }

  private resetFilterOperator() {
    this.item!.filterOption = this.getDefaultFilterOperatorOption(this.item!);
  }

  shouldDisplayCalendarHint(item: Nullable<FilterItem>) {
    return this.useAsFormElement && !this.isDisabled && item?.filterType === FilterType.DATE;
  }

  shouldDisplayCancelHint(item: Nullable<FilterItem>) {
    return !this.isDisabled && (!this.isStatic || item?.value || this.hasNoValueOperator);
  }
}
