import {Directive} from '@angular/core';

// This esoteric voodoo code is explained here:
// https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
function escapeRegExp(string: string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

@Directive()
export abstract class AbstractHighlightDirective {
  protected getRegexForTerms(terms: string[]): RegExp {
    return new RegExp(`(${terms.map(escapeRegExp).join('|')})`, 'gi');
  }
}
