import {inject, Injectable} from '@angular/core';
import {merge, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {
  ApiAnalogComponentTypeService,
  ApiBusinessRulesService,
  ApiClassificationSchemeService,
  ApiCsuCmfService,
  ApiCsuCzemService,
  ApiCsuLegalformService,
  ApiDeliveryResultService,
  ApiDeliveryServiceAdditionalService,
  ApiDeliveryServiceBasicService,
  ApiDeliveryServiceCombinationService,
  ApiDeliveryServiceGroupService,
  ApiDeliveryTypeService,
  ApiDispatchOfficeService,
  ApiDisposalScheduleService,
  ApiDistributorService,
  ApiDocumentTypeService,
  ApiEntityClassService,
  ApiExternalRetentionTriggerService,
  ApiFileTypeService,
  ApiFilingOfficeService,
  ApiFormatRegisterService,
  ApiIszrIntegrationAdministrationService,
  ApiIszrService,
  ApiKeywordService,
  ApiOfficeDeskCategoryService,
  ApiOfficeDeskRegionService,
  ApiRegistryOfficeService,
  ApiSecurityCategoryService,
  DeliveryTypeGroup,
} from '|api/codebook';
import {
  ApiDataboxNodesOauthService,
  ApiDistributionNodeOauthService,
  ApiEmailNodesOauthService,
  ApiEmailUploadNodesOauthService,
  ApiIssdOauthService,
  ApiOfficeDeskNodesOauthService,
  ApiPortalNodesOauthService,
  ApiSheetNodesOauthService
} from '|api/config-server';
import {ApiOrganizationUnitService, ApiOrgStructCacheService, ApiPermissionService} from '|api/core';
import {
  ApiEnvelopeTemplatesService,
  ApiOwnConsignmentConfigurationService,
  ApiOwnConsignmentService,
  ApiReceivedDigitalConsignmentService,
  ApiSheetLabelTemplatesService
} from '|api/sad';
import {ICodebookService} from '../../services/services-utils';
import {BusinessRuleName, SubjectRecordClassification} from '|api/commons';
import {ApiErmsIssdService} from '|api/nsess-api';
import {ApiFilingRegisterAdministrationService} from '|api/records';
import {SKIP_ERROR_DIALOG} from '../error-handling/http-errors';
import {handleNonexistentFilingRegister} from '../../utils/basic-registers.util';


const clearCodebookCache$ = new Subject<void>();
const clearTransientCodebookCache$ = new Subject<void>();

const CODEBOOK_CACHING_POLICY = {
  maxAge: 60 * 60 * 1000, /* ms = 60min */
  cacheBusterObserver: clearCodebookCache$,
};

const TRANSIENT_CODEBOOK_CACHING_POLICY = {
  ...CODEBOOK_CACHING_POLICY,
  cacheBusterObserver: merge(CODEBOOK_CACHING_POLICY.cacheBusterObserver, clearTransientCodebookCache$),
};

@Injectable({
  providedIn: 'root',
})
export class CodebookService implements ICodebookService {

  private apiIszrService = inject(ApiIszrService);
  private apiClassificationSchemeService = inject(ApiClassificationSchemeService);
  private apiDocumentTypeService = inject(ApiDocumentTypeService);
  private apiDispatchOfficeService = inject(ApiDispatchOfficeService);
  private apiFilingOfficeService = inject(ApiFilingOfficeService);
  private apiSheetNodesOauthService = inject(ApiSheetNodesOauthService);
  private apiDeliveryTypeService = inject(ApiDeliveryTypeService);
  private apiDistributorService = inject(ApiDistributorService);
  private apiFileTypeService = inject(ApiFileTypeService);
  private apiSecurityCategoryService = inject(ApiSecurityCategoryService);
  private apiDisposalScheduleService = inject(ApiDisposalScheduleService);
  private apiBusinessRulesService = inject(ApiBusinessRulesService);
  private apiEntityClassService = inject(ApiEntityClassService);
  private apiDeliveryServiceBasicService = inject(ApiDeliveryServiceBasicService);
  private apiDeliveryServiceAdditionalService = inject(ApiDeliveryServiceAdditionalService);
  private apiDeliveryServiceCombinationService = inject(ApiDeliveryServiceCombinationService);
  private apiOrgStructCacheService = inject(ApiOrgStructCacheService);
  private apiCsuLegalformService = inject(ApiCsuLegalformService);
  private apiCsuCzemService = inject(ApiCsuCzemService);
  private apiCsuCmfService = inject(ApiCsuCmfService);
  private apiAnalogComponentTypeService = inject(ApiAnalogComponentTypeService);
  private apiExternalRetentionTriggerService = inject(ApiExternalRetentionTriggerService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  private apiDeliveryResultService = inject(ApiDeliveryResultService);
  private apiDeliveryServiceGroupService = inject(ApiDeliveryServiceGroupService);
  private apiKeywordService = inject(ApiKeywordService);
  private apiFormatRegisterService = inject(ApiFormatRegisterService);
  private apiOfficeDeskCategoryService = inject(ApiOfficeDeskCategoryService);
  private apiOfficeDeskRegionService = inject(ApiOfficeDeskRegionService);
  private apiOfficeDeskNodesOauthService = inject(ApiOfficeDeskNodesOauthService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private apiDataboxNodesOauthService = inject(ApiDataboxNodesOauthService);
  private apiEmailNodesOauthService = inject(ApiEmailNodesOauthService);
  private apiEmailUploadNodesOauthService = inject(ApiEmailUploadNodesOauthService);
  private apiEnvelopeTemplatesService = inject(ApiEnvelopeTemplatesService);
  private apiSheetLabelTemplatesService = inject(ApiSheetLabelTemplatesService);
  private apiDistributionNodeOauthService = inject(ApiDistributionNodeOauthService);
  private apiIssdService = inject(ApiIssdOauthService);
  private apiErmsIssdService = inject(ApiErmsIssdService);
  private apiOrganizationalUnitService = inject(ApiOrganizationUnitService);
  private apiOwnConsignmentConfigurationService = inject(ApiOwnConsignmentConfigurationService);
  private apiFilingRegisterAdministrationService = inject(ApiFilingRegisterAdministrationService);
  private apiPermissionService = inject(ApiPermissionService);
  private apiRegistryOfficeService = inject(ApiRegistryOfficeService);
  private apiPortalNodesOauthService = inject(ApiPortalNodesOauthService);
  private apiIszrIntegrationAdministrationService = inject(ApiIszrIntegrationAdministrationService);

  // Every potentially large codebook with async origins should be marked with
  // @Cacheable(CODEBOOK_CACHING_POLICY), provided it is always static regardless of who is logged in.

  // Below if caching is always desirable

  @Cacheable(CODEBOOK_CACHING_POLICY)
  users() {
    return this.apiOrgStructCacheService.orgStructCacheFindAllUsers().pipe(map(page => page.content ?? []));
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  functionalPositions() {
    return this.apiOrgStructCacheService.orgStructCacheFindAllFunctionalPositions();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  organizationalUnits() {
    return this.apiOrgStructCacheService.orgStructCacheFindAllOrganizationalUnits();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  deliveryServiceGroups() {
    return this.apiDeliveryServiceGroupService.deliveryServiceGroupFindAll();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  allLegalForms() {
    return this.apiCsuLegalformService.csuLegalformGetAll();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  legalFormsPO() {
    return this.apiCsuLegalformService.csuLegalformGetLegalFormsForSubjectClassification({
      subjectClassification: SubjectRecordClassification.PO,
    });
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  legalFormsPFO() {
    return this.apiCsuLegalformService.csuLegalformGetLegalFormsForSubjectClassification({
      subjectClassification: SubjectRecordClassification.PFO,
    });
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  countries() {
    return this.apiCsuCzemService.csuCzemGetAll();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  currencies() {
    return this.apiCsuCmfService.csuCmfGetAll();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  formatGroups() {
    return this.apiFormatRegisterService.formatRegisterFindAllGroups();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  formats() {
    return this.apiFormatRegisterService.formatRegisterFindAllDataFileFormats();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  iszrAgendasWithActivityRoles() {
    return this.apiIszrService.iszrFindAll();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  getAllDocumentPermissionsWithDependencies() {
    return this.apiPermissionService.permissionGetAllDocumentPermissionsWithDependencies();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  getAllFilePermissionsWithDependencies() {
    return this.apiPermissionService.permissionGetAllFilePermissionsWithDependencies();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  getAllStorageUnitPermissionsWithDependencies() {
    return this.apiPermissionService.permissionGetAllStorageUnitPermissionsWithDependencies();
  }

  @Cacheable(CODEBOOK_CACHING_POLICY)
  orgUnitsToLoggedInFp() {
    return this.apiOrganizationalUnitService.organizationUnitGetOrgUnitsCurrentlyAccessibleToLoggedInFp();
  }

  // @Cacheable(CODEBOOK_CACHING_POLICY)
  // businessRulesGetAllowedFormatsPersonal() {
  //   return this.apiBusinessRulesService.businessRulesGetAllowedFormats({name: BusinessRuleName.DISTRIBUTION_PERSONAL_ALLOWED_ATTACHMENT_FORMAT});
  // }

  // Codebooks with imperatively clearable cache

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  classificationSchemes() {
    return this.apiClassificationSchemeService.classificationSchemeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  documentTypes() {
    return this.apiDocumentTypeService.documentTypeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  dispatchOffices() {
    return this.apiDispatchOfficeService.dispatchOfficeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  filingOffices() {
    return this.apiFilingOfficeService.filingOfficeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  fileTypes() {
    return this.apiFileTypeService.fileTypeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  securityCategories() {
    return this.apiSecurityCategoryService.securityCategoryFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  deliveryTypes() {
    return this.apiDeliveryTypeService.deliveryTypeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  externalRetentionTriggers() {
    return this.apiExternalRetentionTriggerService.externalRetentionTriggerFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  deliveryResults() {
    return this.apiDeliveryResultService.deliveryResultFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  keywords() {
    return this.apiKeywordService.keywordFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  keywordsAllValid() {
    return this.apiKeywordService.keywordFindAllCurrentlyValid();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  analogComponentTypes() {
    return this.apiAnalogComponentTypeService.analogComponentTypeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  officeDeskCategories() {
    return this.apiOfficeDeskCategoryService.officeDeskCategoryFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  officeDeskRegions() {
    return this.apiOfficeDeskRegionService.officeDeskRegionFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  disposalSchedules() {
    return this.apiDisposalScheduleService.disposalScheduleFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  entityClasses() {
    return this.apiEntityClassService.entityClassFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  deliveryServiceBasics() {
    return this.apiDeliveryServiceBasicService.deliveryServiceBasicFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  deliveryServiceAdditionals() {
    return this.apiDeliveryServiceAdditionalService.deliveryServiceAdditionalFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  deliveryServiceCombinations() {
    return this.apiDeliveryServiceCombinationService.deliveryServiceCombinationFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  deliveryServiceCombinationsForCzechPost() {
    return this.apiDeliveryServiceCombinationService.deliveryServiceCombinationFindByDeliveryTypeGroup(
      {deliveryTypeGroup: DeliveryTypeGroup.POSTA, page: 0, size: 1000}
    );
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  distributors() {
    return this.apiDistributorService.distributorFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  issdApplications() {
    return this.apiIssdService.issdOauthFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  distributionNodesAll() {
    return this.apiDistributionNodeOauthService.distributionNodeOauthFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  sheetNodes() {
    return this.apiSheetNodesOauthService.sheetNodesOauthGetAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  officeDeskNodes() {
    return this.apiOfficeDeskNodesOauthService.officeDeskNodesOauthGetAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  emailNodes() {
    return this.apiEmailNodesOauthService.emailNodesOauthGetAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  databoxNodes() {
    return this.apiDataboxNodesOauthService.databoxNodesOauthGetAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  portalNodes() {
    return this.apiPortalNodesOauthService.portalNodesOauthGetAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  emailUploadNodes() {
    return this.apiEmailUploadNodesOauthService.emailUploadNodesOauthGetAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  ermsIssd() {
    return this.apiErmsIssdService.ermsIssdGetByPermissions();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  ownPaperConsignmentConfiguration() {
    return this.apiOwnConsignmentConfigurationService.ownConsignmentConfigurationGetPaperConsignmentConfiguration();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  getFilingRegister() {
    return this.apiFilingRegisterAdministrationService.filingRegisterAdministrationGetFilingRegister({}, SKIP_ERROR_DIALOG).pipe(
      handleNonexistentFilingRegister(),
    );
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  getAllSeparateFilingRegisters() {
    return this.apiFilingRegisterAdministrationService.filingRegisterAdministrationGetAllSeparateFilingRegisters();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  businessRulesGetAllowedFormatsIsds() {
    return this.apiBusinessRulesService.businessRulesGetAllowedFormats({name: BusinessRuleName.DISTRIBUTION_ISDS_ALLOWED_ATTACHMENT_FORMAT});
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  businessRulesGetAllowedFormatsEmail() {
    return this.apiBusinessRulesService.businessRulesGetAllowedFormats({name: BusinessRuleName.DISTRIBUTION_EMAIL_TECHNICAL_ATTACHMENT_FORMAT});
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  businessRulesGetAllowedFormatsPersonal() {
    return this.apiBusinessRulesService.businessRulesGetAllowedFormats({name: BusinessRuleName.DISTRIBUTION_PERSONAL_TECHNICAL_ATTACHMENT_FORMAT});
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  businessRulesGetAllowedFormatsAnonymiztion() {
    return this.apiBusinessRulesService.businessRulesGetAllowedFormats({name: BusinessRuleName.ANONYMIZATION});
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  emailNodesForCurrentFunctionalPosition() {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindEmailNodesForFunctionalPosition();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  emailUploadNodesForCurrentFunctionalPosition() {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  databoxNodesForCurrentFunctionalPosition() {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  sheetNodesForCurrentFunctionalPosition() {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindSheetNodesForFunctionalPosition();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  officeDeskNodesForCurrentFunctionalPosition() {
    return this.apiOwnConsignmentService.ownConsignmentFindOfficeDeskNodesForFunctionalPosition();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  iszrAgendasWithActivityRolesForCurrentFunctionalPosition() {
    return this.apiIszrIntegrationAdministrationService.iszrIntegrationAdministrationGetAllFiltered();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  envelopeTemplates() {
    return this.apiEnvelopeTemplatesService.envelopeTemplatesFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  sheetLabelTemplates() {
    return this.apiSheetLabelTemplatesService.sheetLabelTemplatesFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  registryOffices() {
    return this.apiRegistryOfficeService.registryOfficeFindAll();
  }

  @Cacheable(TRANSIENT_CODEBOOK_CACHING_POLICY)
  registryOfficesForCurrentFunctionalPosition() {
    return this.apiRegistryOfficeService.registryOfficeFindRegistryOfficesForFunctionalPosition();
  }

  // Utility methods

  resetTransientCodebookCaches() {
    clearTransientCodebookCache$.next();
  }

  resetCodebookCaches() {
    clearCodebookCache$.next();
  }

}
