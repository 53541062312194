/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageReceivedInternalPaperConsignmentDto } from '../models/page-received-internal-paper-consignment-dto';
import { PageReceivedPaperConsignmentDto } from '../models/page-received-paper-consignment-dto';
import { PageReceivedTelefaxConsignmentDto } from '../models/page-received-telefax-consignment-dto';
import { ReceivedInternalPaperConsignmentDto } from '../models/received-internal-paper-consignment-dto';
import { receivedPaperConsignmentCreateProofOfDelivery } from '../fn/received-paper-consignment/received-paper-consignment-create-proof-of-delivery';
import { ReceivedPaperConsignmentCreateProofOfDelivery$Params } from '../fn/received-paper-consignment/received-paper-consignment-create-proof-of-delivery';
import { ReceivedPaperConsignmentDto } from '../models/received-paper-consignment-dto';
import { receivedPaperConsignmentFindById } from '../fn/received-paper-consignment/received-paper-consignment-find-by-id';
import { ReceivedPaperConsignmentFindById$Params } from '../fn/received-paper-consignment/received-paper-consignment-find-by-id';
import { receivedPaperConsignmentFindByIdInternal } from '../fn/received-paper-consignment/received-paper-consignment-find-by-id-internal';
import { ReceivedPaperConsignmentFindByIdInternal$Params } from '../fn/received-paper-consignment/received-paper-consignment-find-by-id-internal';
import { receivedPaperConsignmentFindConsignmentsForDocument } from '../fn/received-paper-consignment/received-paper-consignment-find-consignments-for-document';
import { ReceivedPaperConsignmentFindConsignmentsForDocument$Params } from '../fn/received-paper-consignment/received-paper-consignment-find-consignments-for-document';
import { receivedPaperConsignmentFindInternalConsignmentsForDocument } from '../fn/received-paper-consignment/received-paper-consignment-find-internal-consignments-for-document';
import { ReceivedPaperConsignmentFindInternalConsignmentsForDocument$Params } from '../fn/received-paper-consignment/received-paper-consignment-find-internal-consignments-for-document';
import { receivedPaperConsignmentFindTelefaxConsignmentsForDocument } from '../fn/received-paper-consignment/received-paper-consignment-find-telefax-consignments-for-document';
import { ReceivedPaperConsignmentFindTelefaxConsignmentsForDocument$Params } from '../fn/received-paper-consignment/received-paper-consignment-find-telefax-consignments-for-document';
import { receivedPaperConsignmentGetPdfProofOfDelivery } from '../fn/received-paper-consignment/received-paper-consignment-get-pdf-proof-of-delivery';
import { ReceivedPaperConsignmentGetPdfProofOfDelivery$Params } from '../fn/received-paper-consignment/received-paper-consignment-get-pdf-proof-of-delivery';
import { receivedPaperConsignmentPrintLabelByDocumentId } from '../fn/received-paper-consignment/received-paper-consignment-print-label-by-document-id';
import { ReceivedPaperConsignmentPrintLabelByDocumentId$Params } from '../fn/received-paper-consignment/received-paper-consignment-print-label-by-document-id';
import { receivedPaperConsignmentProcessInternal } from '../fn/received-paper-consignment/received-paper-consignment-process-internal';
import { ReceivedPaperConsignmentProcessInternal$Params } from '../fn/received-paper-consignment/received-paper-consignment-process-internal';
import { receivedPaperConsignmentProcessReceivedFilingOffice } from '../fn/received-paper-consignment/received-paper-consignment-process-received-filing-office';
import { ReceivedPaperConsignmentProcessReceivedFilingOffice$Params } from '../fn/received-paper-consignment/received-paper-consignment-process-received-filing-office';
import { receivedPaperConsignmentProcessReceivedOfficer } from '../fn/received-paper-consignment/received-paper-consignment-process-received-officer';
import { ReceivedPaperConsignmentProcessReceivedOfficer$Params } from '../fn/received-paper-consignment/received-paper-consignment-process-received-officer';
import { receivedPaperConsignmentProcessTelefaxReceivedOfficer } from '../fn/received-paper-consignment/received-paper-consignment-process-telefax-received-officer';
import { ReceivedPaperConsignmentProcessTelefaxReceivedOfficer$Params } from '../fn/received-paper-consignment/received-paper-consignment-process-telefax-received-officer';
import { receivedPaperConsignmentRegisterContentByFilingOffice } from '../fn/received-paper-consignment/received-paper-consignment-register-content-by-filing-office';
import { ReceivedPaperConsignmentRegisterContentByFilingOffice$Params } from '../fn/received-paper-consignment/received-paper-consignment-register-content-by-filing-office';
import { receivedPaperConsignmentRegisterContentByOfficer } from '../fn/received-paper-consignment/received-paper-consignment-register-content-by-officer';
import { ReceivedPaperConsignmentRegisterContentByOfficer$Params } from '../fn/received-paper-consignment/received-paper-consignment-register-content-by-officer';
import { receivedPaperConsignmentUpdate } from '../fn/received-paper-consignment/received-paper-consignment-update';
import { ReceivedPaperConsignmentUpdate$Params } from '../fn/received-paper-consignment/received-paper-consignment-update';
import { receivedPaperConsignmentUploadConsignmentBody } from '../fn/received-paper-consignment/received-paper-consignment-upload-consignment-body';
import { ReceivedPaperConsignmentUploadConsignmentBody$Params } from '../fn/received-paper-consignment/received-paper-consignment-upload-consignment-body';
import { ReceivedTelefaxConsignmentDto } from '../models/received-telefax-consignment-dto';


/**
 * API for management of received paper consignment
 */
@Injectable({ providedIn: 'root' })
export class ApiReceivedPaperConsignmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `receivedPaperConsignmentFindById()` */
  static readonly ReceivedPaperConsignmentFindByIdPath = '/sad/received-paper-consignment/{id}';

  /**
   * Find received paper consignment by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindById$Response(params: ReceivedPaperConsignmentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find received paper consignment by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindById(params: ReceivedPaperConsignmentFindById$Params, context?: HttpContext): Observable<ReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedPaperConsignmentDto>): ReceivedPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentUpdate()` */
  static readonly ReceivedPaperConsignmentUpdatePath = '/sad/received-paper-consignment/{id}';

  /**
   * Update paper consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentUpdate$Response(params: ReceivedPaperConsignmentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update paper consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentUpdate(params: ReceivedPaperConsignmentUpdate$Params, context?: HttpContext): Observable<ReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedPaperConsignmentDto>): ReceivedPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentRegisterContentByOfficer()` */
  static readonly ReceivedPaperConsignmentRegisterContentByOfficerPath = '/sad/received-paper-consignment/{id}/register-content-officer';

  /**
   * Register content of an 'unopened' consignment just opened ;-)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentRegisterContentByOfficer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentRegisterContentByOfficer$Response(params: ReceivedPaperConsignmentRegisterContentByOfficer$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentRegisterContentByOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Register content of an 'unopened' consignment just opened ;-)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentRegisterContentByOfficer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentRegisterContentByOfficer(params: ReceivedPaperConsignmentRegisterContentByOfficer$Params, context?: HttpContext): Observable<ReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentRegisterContentByOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedPaperConsignmentDto>): ReceivedPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentRegisterContentByFilingOffice()` */
  static readonly ReceivedPaperConsignmentRegisterContentByFilingOfficePath = '/sad/received-paper-consignment/{id}/register-content-filing-office';

  /**
   * Register content of an 'unopened' consignment just opened ;-)
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentRegisterContentByFilingOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentRegisterContentByFilingOffice$Response(params: ReceivedPaperConsignmentRegisterContentByFilingOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentRegisterContentByFilingOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Register content of an 'unopened' consignment just opened ;-)
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentRegisterContentByFilingOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentRegisterContentByFilingOffice(params: ReceivedPaperConsignmentRegisterContentByFilingOffice$Params, context?: HttpContext): Observable<ReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentRegisterContentByFilingOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedPaperConsignmentDto>): ReceivedPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentUploadConsignmentBody()` */
  static readonly ReceivedPaperConsignmentUploadConsignmentBodyPath = '/sad/received-paper-consignment/upload-consignment-body';

  /**
   * Uploads received paper consignment body.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentUploadConsignmentBody()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  receivedPaperConsignmentUploadConsignmentBody$Response(params?: ReceivedPaperConsignmentUploadConsignmentBody$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedPaperConsignmentUploadConsignmentBody(this.http, this.rootUrl, params, context);
  }

  /**
   * Uploads received paper consignment body.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentUploadConsignmentBody$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  receivedPaperConsignmentUploadConsignmentBody(params?: ReceivedPaperConsignmentUploadConsignmentBody$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.receivedPaperConsignmentUploadConsignmentBody$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentProcessTelefaxReceivedOfficer()` */
  static readonly ReceivedPaperConsignmentProcessTelefaxReceivedOfficerPath = '/sad/received-paper-consignment/telefax/receive-officer';

  /**
   * Process the received telefax consignment - only test purposes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentProcessTelefaxReceivedOfficer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessTelefaxReceivedOfficer$Response(params: ReceivedPaperConsignmentProcessTelefaxReceivedOfficer$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedTelefaxConsignmentDto>> {
    return receivedPaperConsignmentProcessTelefaxReceivedOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received telefax consignment - only test purposes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentProcessTelefaxReceivedOfficer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessTelefaxReceivedOfficer(params: ReceivedPaperConsignmentProcessTelefaxReceivedOfficer$Params, context?: HttpContext): Observable<ReceivedTelefaxConsignmentDto> {
    return this.receivedPaperConsignmentProcessTelefaxReceivedOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedTelefaxConsignmentDto>): ReceivedTelefaxConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentProcessReceivedOfficer()` */
  static readonly ReceivedPaperConsignmentProcessReceivedOfficerPath = '/sad/received-paper-consignment/receive-officer';

  /**
   * Process the received paper consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentProcessReceivedOfficer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessReceivedOfficer$Response(params: ReceivedPaperConsignmentProcessReceivedOfficer$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentProcessReceivedOfficer(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received paper consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentProcessReceivedOfficer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessReceivedOfficer(params: ReceivedPaperConsignmentProcessReceivedOfficer$Params, context?: HttpContext): Observable<ReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentProcessReceivedOfficer$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedPaperConsignmentDto>): ReceivedPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentProcessReceivedFilingOffice()` */
  static readonly ReceivedPaperConsignmentProcessReceivedFilingOfficePath = '/sad/received-paper-consignment/receive-filing-office';

  /**
   * Process the received paper consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentProcessReceivedFilingOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessReceivedFilingOffice$Response(params: ReceivedPaperConsignmentProcessReceivedFilingOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentProcessReceivedFilingOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received paper consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentProcessReceivedFilingOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessReceivedFilingOffice(params: ReceivedPaperConsignmentProcessReceivedFilingOffice$Params, context?: HttpContext): Observable<ReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentProcessReceivedFilingOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedPaperConsignmentDto>): ReceivedPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentPrintLabelByDocumentId()` */
  static readonly ReceivedPaperConsignmentPrintLabelByDocumentIdPath = '/sad/received-paper-consignment/print-label-by-document-id/{id}';

  /**
   * Prints label for consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentPrintLabelByDocumentId()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentPrintLabelByDocumentId$Response(params: ReceivedPaperConsignmentPrintLabelByDocumentId$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return receivedPaperConsignmentPrintLabelByDocumentId(this.http, this.rootUrl, params, context);
  }

  /**
   * Prints label for consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentPrintLabelByDocumentId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentPrintLabelByDocumentId(params: ReceivedPaperConsignmentPrintLabelByDocumentId$Params, context?: HttpContext): Observable<Array<string>> {
    return this.receivedPaperConsignmentPrintLabelByDocumentId$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentProcessInternal()` */
  static readonly ReceivedPaperConsignmentProcessInternalPath = '/sad/received-paper-consignment/internal';

  /**
   * Process the received internal paper consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentProcessInternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessInternal$Response(params: ReceivedPaperConsignmentProcessInternal$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedInternalPaperConsignmentDto>> {
    return receivedPaperConsignmentProcessInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received internal paper consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentProcessInternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedPaperConsignmentProcessInternal(params: ReceivedPaperConsignmentProcessInternal$Params, context?: HttpContext): Observable<ReceivedInternalPaperConsignmentDto> {
    return this.receivedPaperConsignmentProcessInternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedInternalPaperConsignmentDto>): ReceivedInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentCreateProofOfDelivery()` */
  static readonly ReceivedPaperConsignmentCreateProofOfDeliveryPath = '/sad/received-paper-consignment/internal/proof-of-delivery/{receivedInternalMessageId}/{receivedDocumentId}';

  /**
   * Creates proof of delivery for received internal message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentCreateProofOfDelivery()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentCreateProofOfDelivery$Response(params: ReceivedPaperConsignmentCreateProofOfDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedPaperConsignmentCreateProofOfDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates proof of delivery for received internal message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentCreateProofOfDelivery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentCreateProofOfDelivery(params: ReceivedPaperConsignmentCreateProofOfDelivery$Params, context?: HttpContext): Observable<void> {
    return this.receivedPaperConsignmentCreateProofOfDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentFindTelefaxConsignmentsForDocument()` */
  static readonly ReceivedPaperConsignmentFindTelefaxConsignmentsForDocumentPath = '/sad/received-paper-consignment/telefax/by-document/{id}';

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentFindTelefaxConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindTelefaxConsignmentsForDocument$Response(params: ReceivedPaperConsignmentFindTelefaxConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedTelefaxConsignmentDto>> {
    return receivedPaperConsignmentFindTelefaxConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentFindTelefaxConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindTelefaxConsignmentsForDocument(params: ReceivedPaperConsignmentFindTelefaxConsignmentsForDocument$Params, context?: HttpContext): Observable<PageReceivedTelefaxConsignmentDto> {
    return this.receivedPaperConsignmentFindTelefaxConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedTelefaxConsignmentDto>): PageReceivedTelefaxConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentGetPdfProofOfDelivery()` */
  static readonly ReceivedPaperConsignmentGetPdfProofOfDeliveryPath = '/sad/received-paper-consignment/proof-of-delivery/{id}';

  /**
   * Generates pdf proof of delivery for given consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentGetPdfProofOfDelivery()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentGetPdfProofOfDelivery$Response(params: ReceivedPaperConsignmentGetPdfProofOfDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return receivedPaperConsignmentGetPdfProofOfDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates pdf proof of delivery for given consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentGetPdfProofOfDelivery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentGetPdfProofOfDelivery(params: ReceivedPaperConsignmentGetPdfProofOfDelivery$Params, context?: HttpContext): Observable<Array<string>> {
    return this.receivedPaperConsignmentGetPdfProofOfDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentFindByIdInternal()` */
  static readonly ReceivedPaperConsignmentFindByIdInternalPath = '/sad/received-paper-consignment/internal/{id}';

  /**
   * Find received internal paper consignment by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentFindByIdInternal()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindByIdInternal$Response(params: ReceivedPaperConsignmentFindByIdInternal$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedInternalPaperConsignmentDto>> {
    return receivedPaperConsignmentFindByIdInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * Find received internal paper consignment by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentFindByIdInternal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindByIdInternal(params: ReceivedPaperConsignmentFindByIdInternal$Params, context?: HttpContext): Observable<ReceivedInternalPaperConsignmentDto> {
    return this.receivedPaperConsignmentFindByIdInternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedInternalPaperConsignmentDto>): ReceivedInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentFindInternalConsignmentsForDocument()` */
  static readonly ReceivedPaperConsignmentFindInternalConsignmentsForDocumentPath = '/sad/received-paper-consignment/internal/by-document/{id}';

  /**
   * Search Internal Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentFindInternalConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindInternalConsignmentsForDocument$Response(params: ReceivedPaperConsignmentFindInternalConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedInternalPaperConsignmentDto>> {
    return receivedPaperConsignmentFindInternalConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Internal Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentFindInternalConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindInternalConsignmentsForDocument(params: ReceivedPaperConsignmentFindInternalConsignmentsForDocument$Params, context?: HttpContext): Observable<PageReceivedInternalPaperConsignmentDto> {
    return this.receivedPaperConsignmentFindInternalConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedInternalPaperConsignmentDto>): PageReceivedInternalPaperConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedPaperConsignmentFindConsignmentsForDocument()` */
  static readonly ReceivedPaperConsignmentFindConsignmentsForDocumentPath = '/sad/received-paper-consignment/by-document/{id}';

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedPaperConsignmentFindConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindConsignmentsForDocument$Response(params: ReceivedPaperConsignmentFindConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedPaperConsignmentDto>> {
    return receivedPaperConsignmentFindConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedPaperConsignmentFindConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedPaperConsignmentFindConsignmentsForDocument(params: ReceivedPaperConsignmentFindConsignmentsForDocument$Params, context?: HttpContext): Observable<PageReceivedPaperConsignmentDto> {
    return this.receivedPaperConsignmentFindConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedPaperConsignmentDto>): PageReceivedPaperConsignmentDto => r.body)
    );
  }

}
