import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlSerializer} from '@angular/router';
import {filter} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from '../authentication/auth.service';
import {CodebookService} from '../services/codebook.service';
import {DocumentsRoute, FilingOfficeRoute} from '../../enums/documents-routes.enum';
import {ApplicationRoute} from '../../enums/shared-routes.enum';

export function guardPaperOrPersonalOrInternalConsignment() {
  return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const codebookService = inject(CodebookService);
    const authService = inject(AuthService);
    const urlSerializer = inject(UrlSerializer);
    const pathParts = [
      FilingOfficeRoute.RECEIVED_ALL_CONSIGNMENTS,
      FilingOfficeRoute.RECEIVE_PAPER_CONSIGNMENT,
      FilingOfficeRoute.RECEIVE_PERSONAL_CONSIGNMENT,
      FilingOfficeRoute.RECEIVED_INTERNAL_CONSIGNMENTS,
      FilingOfficeRoute.RECEIVE_INTERNAL_CONSIGNMENT,
      FilingOfficeRoute.HANDED_OVER,
      FilingOfficeRoute.REJECTED,
      DocumentsRoute.REGISTER_CONTENT_FILING_OFFICE,
    ];

    const isReceivedPaperOrInternalConsignments = pathParts.some(p => state.url.includes(p));

    return codebookService.sheetNodesForCurrentFunctionalPosition().pipe(
      filter(_ => authService.isAuthenticatedWithFunctionalPosition),
      take(1),
      map(sheetNodes => {
        if (isReceivedPaperOrInternalConsignments) {
          if (sheetNodes.length > 0) {
            return true;
          } else {
            return urlSerializer.parse(ApplicationRoute.ROOT);
          }
        } else {
          throw new Error('PaperConsignmentGuard guard could not match any routes for determining access');
        }
      })
    );
  };
}
