import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IczFormArray, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {SubjectAttributeWithMultipleValues} from '../../model/subjects.model';
import {getNumberedLabel} from '../../shared-document.utils';
import {isAddressPartiallyFilled} from '../../../../services/subjects.service';
import {SubjectAttributeSource} from '|api/commons';
import {SubjectAttributeType} from '../../model/subject-attribute-type';


@Component({
  selector: 'icz-subject-multiple-values-attribute',
  templateUrl: './subject-multiple-values-attribute.component.html',
  styleUrls: ['./subject-multiple-values-attribute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectMultipleValuesAttributeComponent implements OnInit {

  private translateService = inject(TranslateService);

  @Input({required: true}) subjectAttributeType!: SubjectAttributeWithMultipleValues;
  @Input({required: true}) form!: IczFormGroup;
  @Input() multiplicity: [number, number] = [0, Infinity];
  @Input() boxed = false;
  @Input() isReadonly = false;
  @Output() blockingAction = new EventEmitter<boolean>();

  readonly SubjectAttributeType = SubjectAttributeType;

  initExpanded = true;

  ngOnInit() {
    if (this.multipleValuesControl?.controls?.length) {
      this.initExpanded = this.isAdditionalAddress && isAddressPartiallyFilled(this.multipleValuesControl.controls?.[0] as IczFormGroup);
    }
  }

  isVerifiedAttribute(form: IczFormGroup) {
    return form.get('source')?.value !== SubjectAttributeSource.INTERNAL;
  }

  loaderIds: number[] = [];

  get multipleValuesControl(): IczFormArray {
    return this.form?.get(this.subjectAttributeType) as IczFormArray;
  }

  get isEmail(): boolean {
    return this.subjectAttributeType === SubjectAttributeType.EMAIL;
  }

  get isPhoneFax(): boolean {
    return this.subjectAttributeType === SubjectAttributeType.PHONE_FAX;
  }

  get isDataBox(): boolean {
    return this.subjectAttributeType === SubjectAttributeType.DATA_BOX;
  }

  get isAdditionalAddress(): boolean {
    return this.subjectAttributeType === SubjectAttributeType.ADDITIONAL_ADDRESS;
  }

  get isPostalBox(): boolean {
    return this.subjectAttributeType === SubjectAttributeType.POSTAL_BOX;
  }

  get addNewAttrValueLabel(): string {
    return this.isEmail ? 'Přidat e-mail' :
      this.isDataBox ? 'Přidat datovou schránku' :
        this.isAdditionalAddress ? 'Přidat kontaktní adresu' :
          this.isPostalBox ? 'Přidat P.O. BOX' :
            this.isPhoneFax ? 'Přidat telefon/fax' :
              'Přidat atribut';
  }

  get canAddNew(): boolean {
    return this.multipleValuesControl?.controls.length < this.multiplicity[1];
  }

  get stringAttributeLabel(): string {
    return this.isEmail ? 'E-Mail' : this.isPhoneFax ? 'Telefon/fax' : 'Atribut';
  }

  get canRemoveLast(): boolean {
    return this.multipleValuesControl?.controls.length > this.multiplicity[0];
  }

  get disabledAddButtonTooltip(): Nullable<string> {
    return !this.canAddNew ? 'Tento typ subjektu nemůže mít více datových schránek.' : null;
  }

  setBlockingAction(i: number, isBlocking: boolean) {
    if (isBlocking) {
      if (this.loaderIds.indexOf(i) === -1) {
        this.loaderIds.push(i);
      }
    } else {
      const loaderIndex = this.loaderIds.findIndex(l => l === i);
      this.loaderIds.splice(loaderIndex, 1);
    }
    if (this.loaderIds.length === 0) {
      this.blockingAction.emit(false);
    } else {
      this.blockingAction.emit(true);
    }
  }

  addNewAttrValue() {
    if (this.canAddNew) this.multipleValuesControl?.incrementSize();
  }

  deleteAttrValue(i: number) {
    if (this.canRemoveLast) this.multipleValuesControl?.removeAt(i);
  }

  numberedSectionLabel(i: number): string {
    const labelToTranslate = this.isEmail ? 'E-mail' :
      this.isDataBox ? 'Datová schránka' :
        this.isAdditionalAddress ? 'Kontaktní adresa' :
          this.isPostalBox ? 'P.O. BOX' :
            this.isPhoneFax ? 'Telefon/fax' :
              'Hodnota atributu';
    return getNumberedLabel(i, labelToTranslate, this.translateService);
  }

}
