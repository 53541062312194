/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiSubstitutionService } from './services/api-substitution.service';
import { ApiLiquibaseService } from './services/api-liquibase.service';
import { ApiOrganizationalStructureService } from './services/api-organizational-structure.service';
import { ApiUserService } from './services/api-user.service';
import { ApiOrganizationalUnitService } from './services/api-organizational-unit.service';
import { ApiFunctionalPositionService } from './services/api-functional-position.service';
import { ApiApplicationRoleService } from './services/api-application-role.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiSubstitutionService,
    ApiLiquibaseService,
    ApiOrganizationalStructureService,
    ApiUserService,
    ApiOrganizationalUnitService,
    ApiFunctionalPositionService,
    ApiApplicationRoleService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
