import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';
import {MessageType, SImplicitlyStringifiable, ToastService} from '../../../components/notifications/toast.service';

export const SSubjectTemplateData = z.object({
  [InternalNotificationKey.SUBJECT_NAME]: SImplicitlyStringifiable,
});
export type SubjectTemplateData = z.infer<typeof SSubjectTemplateData>;

export const SSubjectTemplateErrorData = z.intersection(
  SSubjectTemplateData,
  z.object({
    [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  })
);
export type SubjectTemplateErrorData = z.infer<typeof SSubjectTemplateErrorData>;

export enum SubjectToastType {
  SUBJECT_MARK_AS_REPRESENTING_SUCCESS = 'SUBJECT_MARK_AS_REPRESENTING_SUCCESS',
  SUBJECT_IDENTIFY_SUCCESS = 'SUBJECT_IDENTIFY_SUCCESS',
  SUBJECT_DISPOSE_SUCCESS = 'SUBJECT_DISPOSE_SUCCESS',
  SUBJECT_DISPOSE_ALL_SUCCESS = 'SUBJECT_DISPOSE_ALL_SUCCESS',
  SUBJECT_LINK_SUCCESS = 'SUBJECT_LINK_SUCCESS',
  SUBJECT_LINK_ERROR = 'SUBJECT_LINK_ERROR',
  SUBJECT_UNLINK_SUCCESS = 'SUBJECT_UNLINK_SUCCESS',
  SUBJECT_UNLINK_ERROR = 'SUBJECT_UNLINK_ERROR',
  SUBJECT_UPDATE_SUCCESS = 'SUBJECT_UPDATE_SUCCESS',
  SUBJECT_UPDATE_ERROR = 'SUBJECT_UPDATE_ERROR',
  SUBJECT_ADDRESS_IDENTIFICATION_SUCCESS = 'SUBJECT_ADDRESS_IDENTIFICATION_SUCCESS',
}

@Injectable({
  providedIn: 'root',
})
export class SubjectToastService {

  private toastService = inject(ToastService);

  dispatchSubjectInfoToast(subjectToastType: SubjectToastType, templateData: SubjectTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, subjectToastType, templateData, SSubjectTemplateData);
  }

  dispatchSubjectErrorToast(subjectToastType: SubjectToastType, templateData: SubjectTemplateErrorData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, subjectToastType, templateData, SSubjectTemplateErrorData);
  }

  dispatchSimpleSubjectInfoToast(subjectToastType: SubjectToastType) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, subjectToastType);
  }

  dispatchSimpleSubjectErrorToast(subjectToastType: SubjectToastType) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, subjectToastType);
  }

}
