import {Component, inject, Input} from '@angular/core';
import {FavouritePageService} from '../../../services/favourite-page.service';
import {HistoryBit} from '../../../services/history.model';
import {IconSize} from '../../essentials/icon/icon.component';

@Component({
  selector: 'icz-favourite-page-selector',
  templateUrl: './favourite-page-selector.component.html',
  styleUrls: ['./favourite-page-selector.component.scss'],
})
export class FavouritePageSelectorComponent {

  private favouritePageService = inject(FavouritePageService);

  @Input({required: true}) page!: Nullable<HistoryBit>;

  @Input() smallIcon = false;

  get isPageInFavouriteList() {
    return this.favouritePageService.isPageInFavouriteList(this.page!.url);
  }

  get canPageBeInFavouriteList() {
    return this.page?.canBeFavourite;
  }

  get icon() {
    return this.isPageInFavouriteList ? 'favorite_on' : 'favorite_off';
  }

  get iconSize(): IconSize {
    return this.smallIcon ? 'small' : 'default';
  }

  get tooltip() {
    return this.isPageInFavouriteList ? 'Odebrat ze sekce oblíbené' : 'Přidat do sekce oblíbené';
  }

  onClick($event: Event) {
    $event.stopPropagation();
    if (this.isPageInFavouriteList) {
      this.favouritePageService.removePageFromFavouriteList(this.page!.url);
    } else {
      this.favouritePageService.savePageToFavouriteList(this.page!);
    }
  }

}
