/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EsslErrorCodeExceptionDto } from '|api/commons';
import { PageSecurityCategoryDto } from '../models/page-security-category-dto';
import { securityCategoryCheckBeforeUpdate } from '../fn/security-category/security-category-check-before-update';
import { SecurityCategoryCheckBeforeUpdate$Params } from '../fn/security-category/security-category-check-before-update';
import { securityCategoryCreate } from '../fn/security-category/security-category-create';
import { SecurityCategoryCreate$Params } from '../fn/security-category/security-category-create';
import { securityCategoryDeleteById } from '../fn/security-category/security-category-delete-by-id';
import { SecurityCategoryDeleteById$Params } from '../fn/security-category/security-category-delete-by-id';
import { SecurityCategoryDto } from '../models/security-category-dto';
import { securityCategoryFind } from '../fn/security-category/security-category-find';
import { SecurityCategoryFind$Params } from '../fn/security-category/security-category-find';
import { securityCategoryFindAll } from '../fn/security-category/security-category-find-all';
import { SecurityCategoryFindAll$Params } from '../fn/security-category/security-category-find-all';
import { securityCategoryFindAllValid } from '../fn/security-category/security-category-find-all-valid';
import { SecurityCategoryFindAllValid$Params } from '../fn/security-category/security-category-find-all-valid';
import { securityCategoryFindAllValidOrToBeValid } from '../fn/security-category/security-category-find-all-valid-or-to-be-valid';
import { SecurityCategoryFindAllValidOrToBeValid$Params } from '../fn/security-category/security-category-find-all-valid-or-to-be-valid';
import { securityCategoryFindById } from '../fn/security-category/security-category-find-by-id';
import { SecurityCategoryFindById$Params } from '../fn/security-category/security-category-find-by-id';
import { securityCategoryTestCreate } from '../fn/security-category/security-category-test-create';
import { SecurityCategoryTestCreate$Params } from '../fn/security-category/security-category-test-create';
import { securityCategoryUpdate } from '../fn/security-category/security-category-update';
import { SecurityCategoryUpdate$Params } from '../fn/security-category/security-category-update';


/**
 * Controller for Security Categories
 */
@Injectable({ providedIn: 'root' })
export class ApiSecurityCategoryService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `securityCategoryFindById()` */
  static readonly SecurityCategoryFindByIdPath = '/codebook/classifier/security-category/{id}';

  /**
   * Find security category by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindById$Response(params: SecurityCategoryFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityCategoryDto>> {
    return securityCategoryFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find security category by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindById(params: SecurityCategoryFindById$Params, context?: HttpContext): Observable<SecurityCategoryDto> {
    return this.securityCategoryFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecurityCategoryDto>): SecurityCategoryDto => r.body)
    );
  }

  /** Path part for operation `securityCategoryUpdate()` */
  static readonly SecurityCategoryUpdatePath = '/codebook/classifier/security-category/{id}';

  /**
   * Update existing security category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  securityCategoryUpdate$Response(params: SecurityCategoryUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SecurityCategoryDto>>> {
    return securityCategoryUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing security category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  securityCategoryUpdate(params: SecurityCategoryUpdate$Params, context?: HttpContext): Observable<Array<SecurityCategoryDto>> {
    return this.securityCategoryUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecurityCategoryDto>>): Array<SecurityCategoryDto> => r.body)
    );
  }

  /** Path part for operation `securityCategoryDeleteById()` */
  static readonly SecurityCategoryDeleteByIdPath = '/codebook/classifier/security-category/{id}';

  /**
   * Delete document type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryDeleteById$Response(params: SecurityCategoryDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return securityCategoryDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete document type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryDeleteById(params: SecurityCategoryDeleteById$Params, context?: HttpContext): Observable<void> {
    return this.securityCategoryDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `securityCategoryFindAll()` */
  static readonly SecurityCategoryFindAllPath = '/codebook/classifier/security-category';

  /**
   * Finds all security categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindAll$Response(params?: SecurityCategoryFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SecurityCategoryDto>>> {
    return securityCategoryFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all security categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindAll(params?: SecurityCategoryFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<SecurityCategoryDto>> {
    return this.securityCategoryFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecurityCategoryDto>>): Array<SecurityCategoryDto> => r.body)
    );
  }

  /** Path part for operation `securityCategoryCreate()` */
  static readonly SecurityCategoryCreatePath = '/codebook/classifier/security-category';

  /**
   * Create new security category
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  securityCategoryCreate$Response(params: SecurityCategoryCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityCategoryDto>> {
    return securityCategoryCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new security category
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  securityCategoryCreate(params: SecurityCategoryCreate$Params, context?: HttpContext): Observable<SecurityCategoryDto> {
    return this.securityCategoryCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecurityCategoryDto>): SecurityCategoryDto => r.body)
    );
  }

  /** Path part for operation `securityCategoryTestCreate()` */
  static readonly SecurityCategoryTestCreatePath = '/codebook/classifier/security-category/testCreate';

  /**
   * Create new security category, allow any validFrom value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryTestCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  securityCategoryTestCreate$Response(params: SecurityCategoryTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SecurityCategoryDto>> {
    return securityCategoryTestCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new security category, allow any validFrom value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryTestCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  securityCategoryTestCreate(params: SecurityCategoryTestCreate$Params, context?: HttpContext): Observable<SecurityCategoryDto> {
    return this.securityCategoryTestCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SecurityCategoryDto>): SecurityCategoryDto => r.body)
    );
  }

  /** Path part for operation `securityCategoryFindAllValid()` */
  static readonly SecurityCategoryFindAllValidPath = '/codebook/classifier/security-category/valid';

  /**
   * Finds all VALID security categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryFindAllValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindAllValid$Response(params?: SecurityCategoryFindAllValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SecurityCategoryDto>>> {
    return securityCategoryFindAllValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all VALID security categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryFindAllValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindAllValid(params?: SecurityCategoryFindAllValid$Params|null|undefined, context?: HttpContext): Observable<Array<SecurityCategoryDto>> {
    return this.securityCategoryFindAllValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecurityCategoryDto>>): Array<SecurityCategoryDto> => r.body)
    );
  }

  /** Path part for operation `securityCategoryFindAllValidOrToBeValid()` */
  static readonly SecurityCategoryFindAllValidOrToBeValidPath = '/codebook/classifier/security-category/valid-or-to-be-valid';

  /**
   * Finds all valid or to-be-valid security categories
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryFindAllValidOrToBeValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindAllValidOrToBeValid$Response(params?: SecurityCategoryFindAllValidOrToBeValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SecurityCategoryDto>>> {
    return securityCategoryFindAllValidOrToBeValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all valid or to-be-valid security categories
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryFindAllValidOrToBeValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFindAllValidOrToBeValid(params?: SecurityCategoryFindAllValidOrToBeValid$Params|null|undefined, context?: HttpContext): Observable<Array<SecurityCategoryDto>> {
    return this.securityCategoryFindAllValidOrToBeValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SecurityCategoryDto>>): Array<SecurityCategoryDto> => r.body)
    );
  }

  /** Path part for operation `securityCategoryFind()` */
  static readonly SecurityCategoryFindPath = '/codebook/classifier/security-category/search-api';

  /**
   * Finds security categorys based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFind$Response(params?: SecurityCategoryFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSecurityCategoryDto>> {
    return securityCategoryFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds security categorys based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryFind(params?: SecurityCategoryFind$Params|null|undefined, context?: HttpContext): Observable<PageSecurityCategoryDto> {
    return this.securityCategoryFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSecurityCategoryDto>): PageSecurityCategoryDto => r.body)
    );
  }

  /** Path part for operation `securityCategoryCheckBeforeUpdate()` */
  static readonly SecurityCategoryCheckBeforeUpdatePath = '/codebook/classifier/security-category/check-before-update/{id}';

  /**
   * Check security category before update if any of the descandens if out of range for new validTo date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `securityCategoryCheckBeforeUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryCheckBeforeUpdate$Response(params: SecurityCategoryCheckBeforeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EsslErrorCodeExceptionDto>> {
    return securityCategoryCheckBeforeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Check security category before update if any of the descandens if out of range for new validTo date.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `securityCategoryCheckBeforeUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  securityCategoryCheckBeforeUpdate(params: SecurityCategoryCheckBeforeUpdate$Params, context?: HttpContext): Observable<EsslErrorCodeExceptionDto> {
    return this.securityCategoryCheckBeforeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EsslErrorCodeExceptionDto>): EsslErrorCodeExceptionDto => r.body)
    );
  }

}
