<icz-address class="mb-24"
             [form]="form"
             [addressAttributeType]="SubjectAttributeType.RESIDENTIAL_ADDRESS"
             [subjectAttributeType]="SubjectAttributeType.RESIDENTIAL_ADDRESS"
             [boxed]="isUpdateMode"
             [showSectionExpansionHint]="!isUpdateMode"
             [isReadonly]="isReadonly"
             [withAddressValidationButton]="true"
             [initExpanded]="initExpandedResidentialAddress"
             [label]="isNaturalPersonOrBI ? 'Trvalý pobyt' : 'Adresa sídla'">
</icz-address>
<icz-address class="mb-24"
             [form]="form"
             [addressAttributeType]="SubjectAttributeType.MAILING_ADDRESS"
             [subjectAttributeType]="SubjectAttributeType.MAILING_ADDRESS"
             [boxed]="isUpdateMode"
             [isReadonly]="isReadonly"
             [showSectionExpansionHint]="!isUpdateMode"
             [withAddressValidationButton]="true"
             [initExpanded]="initExpandedMailingAddress"
             label="Doručovací adresa">
</icz-address>

<!--       Additional addresses  -->
<icz-subject-multiple-values-attribute class="my-24" [boxed]="isUpdateMode" [isReadonly]="isReadonly"
                                       [subjectAttributeType]="SubjectAttributeType.ADDITIONAL_ADDRESS"
                                       [form]="form">
</icz-subject-multiple-values-attribute>
<!--       Postal boxes  -->
<icz-subject-multiple-values-attribute class="my-24" [boxed]="isUpdateMode" [isReadonly]="isReadonly"
                                       [subjectAttributeType]="SubjectAttributeType.POSTAL_BOX"
                                       [form]="form">
</icz-subject-multiple-values-attribute>
