import {FunctionalPositionDto} from '|api/auth-server';
import {EsslApplicationRole} from '|api/commons';

export function isOfficer() {
  return true; // Referent is implicit role
}

export function isDispatchOfficer(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.VYPRAVNA));
}

export function isFilingOfficer(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.PODATELNA));
}

export function isRegistryOfficer(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.SPISOVNA));
}

export function isLeader(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.VEDOUCI));
}

export function isSecretariat(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.SEKRETARIAT));
}

export function isSubjectRegisterManager(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.SPRAVCE_JM_REJSTRIKU));
}

export function isSecretariatOrLeader(currentUserInfo: FunctionalPositionDto) {
  return isLeader(currentUserInfo) || isSecretariat(currentUserInfo);
}

export function isAdmin(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.ADMINISTRATOR));
}

export function isClassificationSchemesAdmin(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.SPRAVCE_SPISOVEHO_PLANU));
}

export function isSubjectsAdmin(currentUserInfo: FunctionalPositionDto) {
  return Boolean(currentUserInfo?.applicationRoles!.includes(EsslApplicationRole.SPRAVCE_JM_REJSTRIKU));
}
