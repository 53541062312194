import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, UrlSerializer} from '@angular/router';
import {filter} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {AuthService} from '../authentication/auth.service';
import {CodebookService} from '../services/codebook.service';
import {FilingOfficeRoute} from '../../enums/documents-routes.enum';
import {ApplicationRoute} from '../../enums/shared-routes.enum';

export function guardEmailConsignment() {
  return (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const codebookService = inject(CodebookService);
    const authService = inject(AuthService);
    const urlSerializer = inject(UrlSerializer);
    const isReceivedEmailConsignments = state.url.includes(FilingOfficeRoute.RECEIVED_EMAIL_MESSAGES) || state.url.includes(FilingOfficeRoute.RECEIVE_EMAIL_MESSAGE);

    return codebookService.emailNodesForCurrentFunctionalPosition().pipe(
      filter(_ => authService.isAuthenticatedWithFunctionalPosition),
      take(1),
      map(emailNodes => {
        if (isReceivedEmailConsignments) {
          if (emailNodes.length > 0) {
            return true;
          } else {
            return urlSerializer.parse(ApplicationRoute.ROOT);
          }
        } else {
          throw new Error('EmailConsignmentGuard guard could not match any routes for determining access');
        }
      })
    );
  };
}
