import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'translateEnumValuesList',
  pure: false, // output might change based on language change
})
export class TranslateEnumValuesListPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(value: string[], enumName: string): string {
    return value
            .map(v => `en.${enumName}.${v}`)
            .map(v => this.translateService.instant(v))
            .join(', ');
  }

}
