/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { circulationActivityBulkActionsAcknowledgement } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-acknowledgement';
import { CirculationActivityBulkActionsAcknowledgement$Params } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-acknowledgement';
import { circulationActivityBulkActionsApproval } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-approval';
import { CirculationActivityBulkActionsApproval$Params } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-approval';
import { circulationActivityBulkActionsHandover } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-handover';
import { CirculationActivityBulkActionsHandover$Params } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-handover';
import { circulationActivityBulkActionsRevoke } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-revoke';
import { CirculationActivityBulkActionsRevoke$Params } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-revoke';
import { circulationActivityBulkActionsSign } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-sign';
import { CirculationActivityBulkActionsSign$Params } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-sign';
import { circulationActivityBulkActionsStatement } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-statement';
import { CirculationActivityBulkActionsStatement$Params } from '../fn/circulation-activity-bulk-actions/circulation-activity-bulk-actions-statement';


/**
 * Bulk action endpoints for activities
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationActivityBulkActionsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationActivityBulkActionsStatement()` */
  static readonly CirculationActivityBulkActionsStatementPath = '/flow/activity/bulk/statement';

  /**
   * Gives statements for documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityBulkActionsStatement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsStatement$Response(params: CirculationActivityBulkActionsStatement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityBulkActionsStatement(this.http, this.rootUrl, params, context);
  }

  /**
   * Gives statements for documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityBulkActionsStatement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsStatement(params: CirculationActivityBulkActionsStatement$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityBulkActionsStatement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityBulkActionsSign()` */
  static readonly CirculationActivityBulkActionsSignPath = '/flow/activity/bulk/sign';

  /**
   * Signs components
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityBulkActionsSign()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsSign$Response(params: CirculationActivityBulkActionsSign$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityBulkActionsSign(this.http, this.rootUrl, params, context);
  }

  /**
   * Signs components
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityBulkActionsSign$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsSign(params: CirculationActivityBulkActionsSign$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityBulkActionsSign$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityBulkActionsRevoke()` */
  static readonly CirculationActivityBulkActionsRevokePath = '/flow/activity/bulk/revoke';

  /**
   * Revokes activities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityBulkActionsRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsRevoke$Response(params: CirculationActivityBulkActionsRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityBulkActionsRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revokes activities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityBulkActionsRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsRevoke(params: CirculationActivityBulkActionsRevoke$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityBulkActionsRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityBulkActionsHandover()` */
  static readonly CirculationActivityBulkActionsHandoverPath = '/flow/activity/bulk/handover';

  /**
   * Hands over entities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityBulkActionsHandover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsHandover$Response(params: CirculationActivityBulkActionsHandover$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityBulkActionsHandover(this.http, this.rootUrl, params, context);
  }

  /**
   * Hands over entities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityBulkActionsHandover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsHandover(params: CirculationActivityBulkActionsHandover$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityBulkActionsHandover$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityBulkActionsApproval()` */
  static readonly CirculationActivityBulkActionsApprovalPath = '/flow/activity/bulk/approval';

  /**
   * Approves entities
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityBulkActionsApproval()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsApproval$Response(params: CirculationActivityBulkActionsApproval$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityBulkActionsApproval(this.http, this.rootUrl, params, context);
  }

  /**
   * Approves entities
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityBulkActionsApproval$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsApproval(params: CirculationActivityBulkActionsApproval$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityBulkActionsApproval$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `circulationActivityBulkActionsAcknowledgement()` */
  static readonly CirculationActivityBulkActionsAcknowledgementPath = '/flow/activity/bulk/acknowledgement';

  /**
   * Acknowledges documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationActivityBulkActionsAcknowledgement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsAcknowledgement$Response(params: CirculationActivityBulkActionsAcknowledgement$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return circulationActivityBulkActionsAcknowledgement(this.http, this.rootUrl, params, context);
  }

  /**
   * Acknowledges documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationActivityBulkActionsAcknowledgement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationActivityBulkActionsAcknowledgement(params: CirculationActivityBulkActionsAcknowledgement$Params, context?: HttpContext): Observable<void> {
    return this.circulationActivityBulkActionsAcknowledgement$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
