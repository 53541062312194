<form [formGroup]="formGroup" [waiting]="loadingService.isLoading$(this) | async">
  <div class="row gap-32">
    <div class="col grow">
      <icz-section label="Doložka">
        @if (showLegalPrescription) {
          <icz-form-field formControlName="legalPrescription" label="Právní předpis"></icz-form-field>
        }
        <div class="row grow">
          <icz-date-picker class="grow-1" formControlName="executionDate" label="Datum právní moci"></icz-date-picker>
          <icz-date-picker class="grow-1" formControlName="enforceableDate" label="Datum vykonatelnosti"></icz-date-picker>
        </div>
        <icz-form-field formControlName="decisionEnforceableInPart" label="Rozhodnutí vykonatelné v části" ></icz-form-field>
        <icz-checkbox label="Založit vypravení" formControlName="createConsignments" secondaryLabel="Do nově zakládaného dokumentu budou zduplikována všechna vypravení původního dokumentu" class="mt-8"></icz-checkbox>
        <icz-checkbox label="Opečetit doložku" formControlName="addTimestamp" class="mt-8"></icz-checkbox>
      </icz-section>
    </div>
    <div class="col grow">
      <icz-section label="Informace, které budou uvedené na doložce">
        <icz-form-field formControlName="documentRefNumber" label="Číslo jednací vstupního dokumentu, ve kterém je vstupní komponenta pro doložku"></icz-form-field>
        <icz-form-field formControlName="fileRefNumber" label="Spisová značka spisu, ve kterém je vstupní dokument vložen"></icz-form-field>
      </icz-section>
    </div>
  </div>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
    [disabled]="loadingService.isLoading(this)"
    (onAction)="submit()"
    primary label="Založit dokument s doložkou"
  ></icz-button>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
