import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentDto} from '|api/document';

@Component({
  selector: 'icz-document-registration-timeline-link',
  templateUrl: './document-registration-timeline-link.component.html',
  styleUrls: ['./document-registration-timeline-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentRegistrationTimelineLinkComponent {

  @Input({required: true})
  document: Nullable<DocumentDto>;

  isRegistrationTimelineOpen = false;

  toggleRegistrationTimeline($event: MouseEvent) {
    $event.stopPropagation();
    this.isRegistrationTimelineOpen = !this.isRegistrationTimelineOpen;
  }

}
