import {inject, Injectable} from '@angular/core';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from './internal-notification.enum';
import {
  EpdzErrorToastType,
  SEpdzErrorErrorTemplateData,
  SEpdzErrorTemplateData,
  SEpdzGeneralErrorErrorTemplateData
} from './epdz-error-toast.service';

@Injectable({
  providedIn: 'root',
})
export class EpdzErrorNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_EPDZ_ERROR_PROCESSING_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EpdzErrorToastType.EPDZ_ERROR_PROCESSING_SUCCESS,
          parsedBody.parameters,
          SEpdzErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.BULK_EPDZ_ERROR_PROCESSING_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EpdzErrorToastType.EPDZ_ERROR_PROCESSING_ERROR,
          parsedBody.parameters,
          SEpdzErrorErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.EPDZ_ERROR_CONTAINER_CORRUPT,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EpdzErrorToastType.EPDZ_ERROR_CONTAINER_CORRUPT,
          parsedBody.parameters,
          SEpdzGeneralErrorErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.EPDZ_ERROR_NO_CONTAINER,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EpdzErrorToastType.EPDZ_ERROR_NO_CONTAINER,
          parsedBody.parameters,
          SEpdzGeneralErrorErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.EPDZ_ERROR_GENERAL,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EpdzErrorToastType.EPDZ_ERROR_GENERAL,
          parsedBody.parameters,
          SEpdzGeneralErrorErrorTemplateData,
        );
      }
    );

  }

}
