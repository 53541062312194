import {ChangeDetectorRef, inject, NgZone, Pipe, PipeTransform} from '@angular/core';
import Timeout = NodeJS.Timeout;

@Pipe({name: 'debounce', pure: false})
export class DebouncePipe implements PipeTransform {

  private changeDetector = inject(ChangeDetectorRef);
  private zone = inject(NgZone);

  private values: any[] = [];
  private timeoutHandle: Nullable<Timeout> = null;

  transform(value: any, debounceTime = 0, startingValue = value) {
    // first value
    if (!this.hasCurrentValue) this.resetValues(startingValue);

    // there is no value that needs debouncing at this point
    if (this.currentValue === value) {
      clearTimeout(this.timeoutHandle!);
      this.resetValues();
      return this.currentValue;
    }

    // there is a new value that needs to be debounced
    if (!this.hasComingValue || this.comingValue !== value) {
      clearTimeout(this.timeoutHandle!);
      this.resetValues();
      this.setComingValue(value);

      this.timeoutHandle = setTimeout(() => {
        this.zone.run(() => {
          if (this.hasComingValue) {
            this.shiftValues();
            this.changeDetector.markForCheck();
          }
        });
      }, debounceTime);
    }

    return this.currentValue;
  }

  get hasCurrentValue() { return this.values.length > 0; }
  get hasComingValue() { return this.values.length > 1; }

  get currentValue() { return this.values[0]; }
  get comingValue() { return this.values[1]; }

  private resetValues(currentValue = this.currentValue) { this.values = [currentValue]; }
  private setComingValue(comingValue: any) { this.values.push(comingValue); }
  private shiftValues() { this.values.shift(); }

}
