import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CORE_MICROSERVICE_ROOT, Page} from '../api';
import {
  ApiClassificationSchemeService,
  ApiEntityClassService,
  ApiKeywordService,
  ClassificationSchemeDto,
  EntityClassDto,
  KeywordDto
} from '|api/codebook';
import {SearchApiService, SearchParams} from './search-api.service';
import {
  ApiEnvelopeTemplatesService,
  ApiSheetLabelTemplatesService,
  EnvelopeTemplateDto,
  SheetLabelTemplateDto
} from '|api/sad';
import {ApiDigitalComponentTemplateService, DigitalComponentTemplateDto} from '|api/component';

@Injectable({
  providedIn: 'root',
})
export class CodebookSearchService extends SearchApiService {

  findClassificationSchemes(searchParams: Partial<SearchParams>): Observable<Page<ClassificationSchemeDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiClassificationSchemeService.ClassificationSchemeSearchPath);
  }

  findEntityClasses(searchParams: Partial<SearchParams>): Observable<Page<EntityClassDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiEntityClassService.EntityClassSearchPath);
  }

  findKeywords(searchParams: Partial<SearchParams>): Observable<Page<KeywordDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiKeywordService.KeywordSearchPath);
  }

  findEnvelopeTemplates(searchParams: Partial<SearchParams>): Observable<Page<EnvelopeTemplateDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiEnvelopeTemplatesService.EnvelopeTemplatesFindEnvelopeTemplatesPath);
  }

  findSheetLabelTemplates(searchParams: Partial<SearchParams>): Observable<Page<SheetLabelTemplateDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiSheetLabelTemplatesService.SheetLabelTemplatesFindSheetLabelTemplatesPath);
  }

  findDigitalComponentTemplates(searchParams: Partial<SearchParams>): Observable<Page<DigitalComponentTemplateDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + ApiDigitalComponentTemplateService.DigitalComponentTemplateFindDigitalComponentTemplatesPath);
  }
}
