<icz-menu-and-bar>
  <icz-main-application-bar toolbar [(notificationsSidebarOpen)]="showNotificationsSidebar"></icz-main-application-bar>
  <div sideMenuItems
       [style.minHeight.px]="150">
    <icz-side-menu-items [menuData]="mainMenuWithCount.menuItemsWithCounts$ | async"></icz-side-menu-items>
  </div>
  <icz-notifications-sidebar [opened]="showNotificationsSidebar" (closed)="showNotificationsSidebar = false">
  </icz-notifications-sidebar>
  <router-outlet class="router-outlet-flex-container-col"></router-outlet>
</icz-menu-and-bar>
