/* tslint:disable */
/* eslint-disable */

/**
 * Document state
 */
export enum DocumentState {
  ON_TABLE = 'ON_TABLE',
  UNOPENED = 'UNOPENED',
  SETTLED = 'SETTLED',
  DEACTIVATED = 'DEACTIVATED',
  SUSPENDED = 'SUSPENDED'
}
