import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateParser} from '@ngx-translate/core';
import {InterpolationContext} from '../../lib/model';

@Pipe({
  name: 'interpolate',
  pure: true,
})
export class InterpolatePipe implements PipeTransform {

  private translateParser = inject(TranslateParser);

  transform(interpolationTemplate: string, interpolationContext: Nullable<InterpolationContext>): string {
    return this.translateParser.interpolate(
      interpolationTemplate,
      interpolationContext ?? {},
    ) ?? '';
  }

}
