/* tslint:disable */
/* eslint-disable */

/**
 * Represents type of organizational structure entity
 */
export enum OrganizationalStructureEntityType {
  APPLICATION_ROLE = 'APPLICATION_ROLE',
  FUNCTIONAL_POSITION = 'FUNCTIONAL_POSITION',
  ORGANIZATIONAL_UNIT = 'ORGANIZATIONAL_UNIT',
  USER = 'USER',
  RELATION_OU_FM = 'RELATION_OU_FM'
}
