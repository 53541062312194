import {DestroyRef, inject, Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {AllSavedFilters, LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {AuthService} from '../../../core/authentication/auth.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FilterItemValueTree} from '../filter-trees.utils';
import {FilterItemValue} from '../filter.types';
import {FilterOperator} from '../../../services/search-api.service';

export const FULLTEXT_SEARCH_TERM_FILTER_ID = '__FullTextSearchTerm';


@Injectable({
  providedIn: 'root'
})
export class SavedFiltersService {

  private authService = inject(AuthService);
  private userSettingsService = inject(UserSettingsService);
  private destroyRef = inject(DestroyRef);

  private _savedFilters$ = new BehaviorSubject<AllSavedFilters>({});
  savedFilters$ = this._savedFilters$.asObservable();

  get savedFilters(): AllSavedFilters {
    return this._savedFilters$.value;
  }

  constructor() {
    this.initialize();

    this.authService.login$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.initialize();
    });
  }

  saveFilter(serializationScope: string, filterName: string, filterItems: Nullable<FilterItemValueTree>, fulltextSearchTerm: Nullable<string>) {
    if (this.savedFilters.hasOwnProperty(filterName)) {
      throw new Error(`Saved filters already contain a filter named "${filterName}" in scope "${serializationScope}".`);
    }

    const savedFiltersClone = cloneDeep(this.savedFilters);

    if (!savedFiltersClone[serializationScope]) {
      savedFiltersClone[serializationScope] = {};
    }

    savedFiltersClone[serializationScope][filterName] = {isDefault: false, ...filterItems!};

    if (fulltextSearchTerm) {
      savedFiltersClone[serializationScope][filterName].values.push(new FilterItemValue(
        FULLTEXT_SEARCH_TERM_FILTER_ID,
        FilterOperator.contains,
        fulltextSearchTerm,
      ));
    }

    this._savedFilters$.next(savedFiltersClone);
    this.serializeAvailableSavedFilters();
  }

  updateFilterDefaultState(serializationScope: string, value: boolean, filterName: string) {
    if (!this.savedFilters.hasOwnProperty(serializationScope)) {
      throw new Error(`Saved filters does not contain a filters in scope "${serializationScope}".`);
    }

    const savedFiltersClone = cloneDeep(this.savedFilters);
    const savedScopedFiltersClone = savedFiltersClone[serializationScope];

    Object.keys(savedScopedFiltersClone).forEach(k => {
      const filterValue = savedScopedFiltersClone[k];
      if (k === filterName) {
        filterValue.isDefault = value;
      } else {
        filterValue.isDefault = false;
      }
    });

    this._savedFilters$.next(savedFiltersClone);
    this.serializeAvailableSavedFilters();
  }

  deleteFilter(serializationScope: string, filterName: string) {
    const savedFiltersClone = cloneDeep(this.savedFilters);
    delete savedFiltersClone[serializationScope][filterName];

    this._savedFilters$.next(savedFiltersClone);
    this.serializeAvailableSavedFilters();
  }

  private initialize() {
    this._savedFilters$.next(this.userSettingsService.getSavedFilters() ?? {});
  }

  private serializeAvailableSavedFilters() {
    this.userSettingsService.setParsedValue(
      LocalStorageKey.SAVED_FILTERS,
      this.savedFilters
    );
  }

}
