<icz-table-preview [opened]="opened" (closed)="closed.emit()" [isTableDocPreview]="true">
  <div class="preview-header" previewHeader>
    <div class="row">
      <icz-icon svgIcon="eye" size="small"></icz-icon>
      <div class="grow">
        {{ 'Omezený náhled na dokument' | translate }}
      </div>
      <icz-button size="small" svgIcon="close" background (onAction)="closed.emit()"></icz-button>
    </div>
  </div>

  @if (hasDocumentForConstrainedPreview) {
    <div class="preview-body">
      <form [formGroup]="form">
        <icz-form-field class="grow"
          formControlName="subject"
          label="Věc"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms"
          [autoSizeMax]="3">
        </icz-form-field>
        <icz-form-field class="grow"
          formControlName="refNumber"
          label="Číslo jednací"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms">
        </icz-form-field>
        <icz-form-field class="grow"
          formControlName="identifier"
          label="UID - Unikatni identifikator"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms">
        </icz-form-field>
        <icz-form-autocomplete class="grow"
          label="Držitelé"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms"
          formControlName="holderFunctionalPositionIds"
          [isMultiselect]="true"
          [autoSizeMax]="6"
          [options]="functionalPositionsOptions$ | async">
        </icz-form-autocomplete>
        <icz-form-autocomplete class="grow"
          label="Zpracovatel"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms"
          formControlName="ownerFunctionalPositionId"
          [autoSizeMax]="6"
          [options]="functionalPositionsOptions$ | async">
        </icz-form-autocomplete>
        <icz-date-picker label="Datum založení"
          formControlName="createdAt"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms">
        </icz-date-picker>
        <icz-form-field label="Reprezentující subjekt"
          formControlName="representingSubject"
          iczHighlightFormField
          [iczHighlightFormField.terms]="searchTerms">
        </icz-form-field>
      </form>
    </div>
  }
</icz-table-preview>
