/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { FileTypeDto } from '../../models/file-type-dto';
import { FileTypeTestCreateDto } from '../../models/file-type-test-create-dto';

export interface FileTypeTestCreate$Params {
      body: FileTypeTestCreateDto
}

export function fileTypeTestCreate(http: HttpClient, rootUrl: string, params: FileTypeTestCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<FileTypeDto>> {
  const rb = new RequestBuilder(rootUrl, fileTypeTestCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<FileTypeDto>;
    })
  );
}

fileTypeTestCreate.PATH = '/codebook/classifier/file-type/testCreate';