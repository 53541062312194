/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeliveryTypeDto } from '../models/delivery-type-dto';
import { deliveryTypeFindAll } from '../fn/delivery-type/delivery-type-find-all';
import { DeliveryTypeFindAll$Params } from '../fn/delivery-type/delivery-type-find-all';
import { deliveryTypeFindAllValidInTimeByType } from '../fn/delivery-type/delivery-type-find-all-valid-in-time-by-type';
import { DeliveryTypeFindAllValidInTimeByType$Params } from '../fn/delivery-type/delivery-type-find-all-valid-in-time-by-type';
import { deliveryTypeFindByDeliveryTypeGroup } from '../fn/delivery-type/delivery-type-find-by-delivery-type-group';
import { DeliveryTypeFindByDeliveryTypeGroup$Params } from '../fn/delivery-type/delivery-type-find-by-delivery-type-group';
import { deliveryTypeFindById } from '../fn/delivery-type/delivery-type-find-by-id';
import { DeliveryTypeFindById$Params } from '../fn/delivery-type/delivery-type-find-by-id';
import { deliveryTypeSearch } from '../fn/delivery-type/delivery-type-search';
import { DeliveryTypeSearch$Params } from '../fn/delivery-type/delivery-type-search';
import { deliveryTypeUpdate } from '../fn/delivery-type/delivery-type-update';
import { DeliveryTypeUpdate$Params } from '../fn/delivery-type/delivery-type-update';
import { PageDeliveryTypeDto } from '../models/page-delivery-type-dto';


/**
 * Delivery types controller
 */
@Injectable({ providedIn: 'root' })
export class ApiDeliveryTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliveryTypeFindById()` */
  static readonly DeliveryTypeFindByIdPath = '/codebook/classifier/delivery-type/{id}';

  /**
   * Finds the delivery type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryTypeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindById$Response(params: DeliveryTypeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryTypeDto>> {
    return deliveryTypeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the delivery type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryTypeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindById(params: DeliveryTypeFindById$Params, context?: HttpContext): Observable<DeliveryTypeDto> {
    return this.deliveryTypeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryTypeDto>): DeliveryTypeDto => r.body)
    );
  }

  /** Path part for operation `deliveryTypeUpdate()` */
  static readonly DeliveryTypeUpdatePath = '/codebook/classifier/delivery-type/{id}';

  /**
   * Updates an existing delivery type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryTypeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryTypeUpdate$Response(params: DeliveryTypeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryTypeDto>>> {
    return deliveryTypeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing delivery type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryTypeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryTypeUpdate(params: DeliveryTypeUpdate$Params, context?: HttpContext): Observable<Array<DeliveryTypeDto>> {
    return this.deliveryTypeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryTypeDto>>): Array<DeliveryTypeDto> => r.body)
    );
  }

  /** Path part for operation `deliveryTypeFindAll()` */
  static readonly DeliveryTypeFindAllPath = '/codebook/classifier/delivery-type';

  /**
   * Returns all the delivery types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryTypeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindAll$Response(params?: DeliveryTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryTypeDto>>> {
    return deliveryTypeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the delivery types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryTypeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindAll(params?: DeliveryTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DeliveryTypeDto>> {
    return this.deliveryTypeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryTypeDto>>): Array<DeliveryTypeDto> => r.body)
    );
  }

  /** Path part for operation `deliveryTypeFindAllValidInTimeByType()` */
  static readonly DeliveryTypeFindAllValidInTimeByTypePath = '/codebook/classifier/delivery-type/{nodeType}/valid-in-time/{dateTime}';

  /**
   * Returns all delivery types of a specific type valid at a specific time
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryTypeFindAllValidInTimeByType()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindAllValidInTimeByType$Response(params: DeliveryTypeFindAllValidInTimeByType$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryTypeDto>>> {
    return deliveryTypeFindAllValidInTimeByType(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all delivery types of a specific type valid at a specific time
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryTypeFindAllValidInTimeByType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindAllValidInTimeByType(params: DeliveryTypeFindAllValidInTimeByType$Params, context?: HttpContext): Observable<Array<DeliveryTypeDto>> {
    return this.deliveryTypeFindAllValidInTimeByType$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryTypeDto>>): Array<DeliveryTypeDto> => r.body)
    );
  }

  /** Path part for operation `deliveryTypeSearch()` */
  static readonly DeliveryTypeSearchPath = '/codebook/classifier/delivery-type/search-api';

  /**
   * Returns delivery types based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryTypeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeSearch$Response(params?: DeliveryTypeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryTypeDto>> {
    return deliveryTypeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns delivery types based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryTypeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeSearch(params?: DeliveryTypeSearch$Params|null|undefined, context?: HttpContext): Observable<PageDeliveryTypeDto> {
    return this.deliveryTypeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryTypeDto>): PageDeliveryTypeDto => r.body)
    );
  }

  /** Path part for operation `deliveryTypeFindByDeliveryTypeGroup()` */
  static readonly DeliveryTypeFindByDeliveryTypeGroupPath = '/codebook/classifier/delivery-type/findByDeliveryTypeGroup/{deliveryTypeGroup}';

  /**
   * Returns delivery types based on delivery type parent
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryTypeFindByDeliveryTypeGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindByDeliveryTypeGroup$Response(params: DeliveryTypeFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryTypeDto>>> {
    return deliveryTypeFindByDeliveryTypeGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns delivery types based on delivery type parent
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryTypeFindByDeliveryTypeGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryTypeFindByDeliveryTypeGroup(params: DeliveryTypeFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<Array<DeliveryTypeDto>> {
    return this.deliveryTypeFindByDeliveryTypeGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryTypeDto>>): Array<DeliveryTypeDto> => r.body)
    );
  }

}
