<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-section label="Komu předat">
    <icz-org-structure-selector
      [form]="form"
      [isMultiselect]="isSelectorMultiselect"
      iczAutoFocus
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      controlName="orgStructureElementIds"
      [label]="isSelectorMultiselect ? 'Funkční místa nebo organizační jednotky' : 'Funkční místo nebo organizační jednotka'">
    </icz-org-structure-selector>
  </icz-section>
  <icz-section label="Instrukce a termín">
    <div class="row">
      <icz-form-field class="grow-5"
                      formControlName="targetTaskInstruction"
                      label="Instrukce"
                      [autoSizeMax]="3"
      ></icz-form-field>
      <icz-date-picker class="grow-3"
                       formControlName="deadline"
                       label="Termín úkolu"
                       [selectableDates]="deadlineValidator"
      ></icz-date-picker>
    </div>
  </icz-section>
  <!-- todo not in API -->
  <!-- <icz-section *ngIf="initEntity?.documentForm === DocumentForm.ANALOG" label="Tisk">
    <icz-checkbox testingFeature
                  formControlName="printHandoverProtocol"
                  label="Tisknout předávací protokol">
    </icz-checkbox>
  </icz-section> -->
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="passToNextHandler()" primary label="Předat"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
