import {HttpContext} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {SKIP_ERROR_DIALOG_TOKEN} from '../interceptors/interceptor-config';
import {EsslErrorResponseDto} from '../services/communication-error-dialog.service';


// Preconstructed http context used for hiding error dialog on HTTP error
export const SKIP_ERROR_DIALOG = new HttpContext();
SKIP_ERROR_DIALOG.set(SKIP_ERROR_DIALOG_TOKEN, true);

export function getErrorMessageFromErrorDto(translateService: TranslateService, errorDto: EsslErrorResponseDto) {
  const replaceRegex = new RegExp(/{{([A-Za-z0-9]+)}}/, 'i');

  let errorMessage: string;

  if (errorDto.errorCode) {
    errorMessage = translateService.instant(errorDto.errorCode);

    if (errorDto.parameters) {
      for (const parameter of errorDto.parameters) {
        errorMessage = errorMessage.replace(replaceRegex, parameter);
      }
    }
  }
  else if (errorDto.message) {
    errorMessage = errorDto.message;
  }
  else {
    errorMessage = translateService.instant('Došlo k neznámé chybě');
  }

  return errorMessage;
}
