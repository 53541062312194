import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {map} from 'rxjs/operators';
import {FunctionalPositionDto} from '|api/auth-server';
import {DistributionClass, OwnConsignmentStatus, SubjectRecordDto} from '|api/commons';
import {OwnDigitalConsignmentDto, OwnOfficeDeskConsignmentDto, OwnTelefaxConsignmentDto, SheetNodeDto} from '|api/sad';
import {OfficeDeskSpecifics} from '../abstract-consignment-dialog-data';
import {GenericOwnConsignment} from '../../../own-consignment-table/model/own-consignment-model';
import {
  OwnConsignmentSpecificFieldsForm,
  OwnInternalConsignmentForm,
  OwnPaperOrPersonalConsignmentForm
} from '../consignment-dialog.form';
import {CurrentSessionService} from '../../../../../services/current-session.service';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {OrganizationalStructureService} from '../../../../../core/services/organizational-structure.service';
import {
  AddressCompleteDto,
  addressToAddressWithType,
  AddressWithTypeAndValidityDto,
  AddressWithTypeDto
} from '../../../model/addresses.model';
import {isOfficer} from '../../../../../services/user-roles.model';
import {ConsignmentWizardStep} from '../abstract-consignment-dialog-validation';
import {AddressEditInfo} from '../../../../form-elements/address-details/address-details.component';
import {hashed} from '../../../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SubjectReplacementConfig} from '../../../model/subjects.model';


export interface ConsigneeWithDestination {
  consignee: SubjectRecordDto,
  destinationAddress?: AddressCompleteDto,
}

@Component({
  selector: 'icz-consignment-consignees-overview',
  templateUrl: './consignment-consignees-overview.component.html',
  styleUrls: ['./consignment-consignees-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentConsigneesOverviewComponent implements OnInit {

  private currentSessionService = inject(CurrentSessionService);
  private codebookService = inject(CodebookService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  selectedConsigneesWithDestination: Nullable<ConsigneeWithDestination[]>;
  @Input({required: true})
  officeDeskSpecifics: Nullable<OfficeDeskSpecifics>;
  @Input({required: true})
  isNew = false;
  @Input()
  readonly = false;
  @Input({required: true})
  consignment: Nullable<GenericOwnConsignment>;
  @Input({required: true})
  specificFieldsForm: Nullable<OwnConsignmentSpecificFieldsForm>;
  @Input({required: true})
  distributionClassForDispatch: Nullable<DistributionClass>;
  @Input({required: true})
  additionalAddresses: AddressWithTypeAndValidityDto[] = [];
  @Output()
  consigneeEdited = new EventEmitter<SubjectRecordDto>();
  @Output()
  consigneeRemoved = new EventEmitter<SubjectRecordDto>();
  @Output()
  wizardStepChangeRequested = new EventEmitter<ConsignmentWizardStep>();
  @Output()
  addressEdited = new EventEmitter<AddressEditInfo>();

  currentUserFP: Nullable<FunctionalPositionDto> = null;
  sheetNodes: SheetNodeDto[] = [];

  readonly isNil = isNil;
  readonly ConsignmentWizardStep = ConsignmentWizardStep;

  ngOnInit() {
    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      takeUntilDestroyed(this.destroyRef)).subscribe(fp => this.currentUserFP = fp!);
    this.codebookService.sheetNodes().subscribe(sheetNodesResult => {
      this.sheetNodes = sheetNodesResult;
    });
  }

  organizationalUnits$ = this.organizationalStructureService.organizationalUnits().pipe(
    map(units => units.map(unit => ({value: unit.id, label: unit.name})))
  );

  get isStatusToHandover() {
    if (!this.consignment) return false;
    return this.consignment.status === OwnConsignmentStatus.TO_HANDOVER;
  }

  get isPaperConsignment(): boolean {
    return (
      this.distributionClassForDispatch === DistributionClass.ADDRESS_PLACE ||
      this.distributionClassForDispatch === DistributionClass.ON_PLACE
    );
  }

  get isOfficeDeskConsignment(): boolean {
    return this.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD;
  }

  get isEmailConsignment(): boolean {
    return this.distributionClassForDispatch === DistributionClass.EMAIL_BOX;
  }

  get isDataboxConsignment(): boolean {
    return this.distributionClassForDispatch === DistributionClass.ISDS_BOX;
  }

  get isInternalConsignment(): boolean {
    return this.distributionClassForDispatch === DistributionClass.INTERNAL;
  }

  get isTelefaxConsignment(): boolean {
    return this.distributionClassForDispatch === DistributionClass.PHONE_CONTACT;
  }

  get digitalConsignment(): Nullable<OwnDigitalConsignmentDto> {
    if (this.isEmailConsignment || this.isDataboxConsignment) {
      return this.specificFieldsForm?.getRawValue() as OwnDigitalConsignmentDto;
    }
    else return null;
  }

  get telefaxConsignment(): Nullable<OwnTelefaxConsignmentDto> {
    if (this.isTelefaxConsignment) {
      return this.consignment as OwnTelefaxConsignmentDto;
    }
    else return null;
  }

  get isPublicPosting() {
    return (this.consignment as OwnOfficeDeskConsignmentDto)?.publicPosting ?? this.officeDeskSpecifics?.isPublicPosting;
  }

  get areSubjectAndAddressEditable() {
    return this.isNew || (isOfficer() && this.isStatusToHandover && !this.isInternalConsignment);
  }

  get subjectReplacementConfig(): SubjectReplacementConfig {
    return {allowSubjectReplacement: false, disallowedReplacementReason: 'U již založené zásilky nelze nahradit adresáta.'};
  }

  addressToAddressWithType(consigneeAddress: AddressCompleteDto, subject: SubjectRecordDto, additionalAddresses: AddressWithTypeAndValidityDto[]): Nullable<AddressWithTypeDto> {
    if (!consigneeAddress) {
      return null;
    }
    else {
      const validAdditionalAddresses = additionalAddresses.filter(aa => aa.isCurrentlyValid);

      if (validAdditionalAddresses.length) {
        const consigneeAddressHash = hashed(consigneeAddress);
        const additionalAddressHashes = validAdditionalAddresses.map(aa => hashed(aa.address));

        const additionalAddressIndex = additionalAddressHashes.indexOf(consigneeAddressHash);

        if (additionalAddressIndex !== -1) {
          return validAdditionalAddresses[additionalAddressIndex];
        }
      }

      return addressToAddressWithType(consigneeAddress, subject);
    }
  }

  filingOfficeAddress(): Nullable<AddressWithTypeDto> {
    const filingNode = this.sheetNodes.find(sn => sn.id === (this.specificFieldsForm as OwnInternalConsignmentForm).get('filingOfficeSheetNodeId')!.value);
    if (filingNode && filingNode.address) {
      return addressToAddressWithType(filingNode.address as AddressCompleteDto);
    } else {
      return null;
    }
  }

  getDestinationAddress(consignee: ConsigneeWithDestination): Nullable<AddressCompleteDto> {
    return (this.specificFieldsForm as Nullable<OwnPaperOrPersonalConsignmentForm>)?.value?.consigneeAddress ?? consignee.destinationAddress;
  }

}
