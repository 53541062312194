/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DataBoxNodeDto } from '../models/data-box-node-dto';
import { databoxNodesOauthCreate } from '../fn/databox-nodes-oauth/databox-nodes-oauth-create';
import { DataboxNodesOauthCreate$Params } from '../fn/databox-nodes-oauth/databox-nodes-oauth-create';
import { databoxNodesOauthGetAll } from '../fn/databox-nodes-oauth/databox-nodes-oauth-get-all';
import { DataboxNodesOauthGetAll$Params } from '../fn/databox-nodes-oauth/databox-nodes-oauth-get-all';
import { databoxNodesOauthGetById } from '../fn/databox-nodes-oauth/databox-nodes-oauth-get-by-id';
import { DataboxNodesOauthGetById$Params } from '../fn/databox-nodes-oauth/databox-nodes-oauth-get-by-id';
import { databoxNodesOauthGetDefaultDataboxNode } from '../fn/databox-nodes-oauth/databox-nodes-oauth-get-default-databox-node';
import { DataboxNodesOauthGetDefaultDataboxNode$Params } from '../fn/databox-nodes-oauth/databox-nodes-oauth-get-default-databox-node';
import { databoxNodesOauthSearch } from '../fn/databox-nodes-oauth/databox-nodes-oauth-search';
import { DataboxNodesOauthSearch$Params } from '../fn/databox-nodes-oauth/databox-nodes-oauth-search';
import { databoxNodesOauthUpdate } from '../fn/databox-nodes-oauth/databox-nodes-oauth-update';
import { DataboxNodesOauthUpdate$Params } from '../fn/databox-nodes-oauth/databox-nodes-oauth-update';
import { PageDataBoxNodeDto } from '../models/page-data-box-node-dto';


/**
 * API for management of data box nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiDataboxNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `databoxNodesOauthUpdate()` */
  static readonly DataboxNodesOauthUpdatePath = '/oauth/databox-nodes/update-node/{id}';

  /**
   * Updates data box node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `databoxNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  databoxNodesOauthUpdate$Response(params: DataboxNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DataBoxNodeDto>> {
    return databoxNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates data box node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `databoxNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  databoxNodesOauthUpdate(params: DataboxNodesOauthUpdate$Params, context?: HttpContext): Observable<DataBoxNodeDto> {
    return this.databoxNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataBoxNodeDto>): DataBoxNodeDto => r.body)
    );
  }

  /** Path part for operation `databoxNodesOauthCreate()` */
  static readonly DataboxNodesOauthCreatePath = '/oauth/databox-nodes/create-node';

  /**
   * Creates new data box node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `databoxNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  databoxNodesOauthCreate$Response(params: DataboxNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DataBoxNodeDto>> {
    return databoxNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new data box node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `databoxNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  databoxNodesOauthCreate(params: DataboxNodesOauthCreate$Params, context?: HttpContext): Observable<DataBoxNodeDto> {
    return this.databoxNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataBoxNodeDto>): DataBoxNodeDto => r.body)
    );
  }

  /** Path part for operation `databoxNodesOauthGetById()` */
  static readonly DataboxNodesOauthGetByIdPath = '/oauth/databox-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `databoxNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthGetById$Response(params: DataboxNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<DataBoxNodeDto>> {
    return databoxNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `databoxNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthGetById(params: DataboxNodesOauthGetById$Params, context?: HttpContext): Observable<DataBoxNodeDto> {
    return this.databoxNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataBoxNodeDto>): DataBoxNodeDto => r.body)
    );
  }

  /** Path part for operation `databoxNodesOauthSearch()` */
  static readonly DataboxNodesOauthSearchPath = '/oauth/databox-nodes/search-api';

  /**
   * Find all values in data box nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `databoxNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthSearch$Response(params?: DataboxNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDataBoxNodeDto>> {
    return databoxNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in data box nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `databoxNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthSearch(params?: DataboxNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageDataBoxNodeDto> {
    return this.databoxNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDataBoxNodeDto>): PageDataBoxNodeDto => r.body)
    );
  }

  /** Path part for operation `databoxNodesOauthGetAll()` */
  static readonly DataboxNodesOauthGetAllPath = '/oauth/databox-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `databoxNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthGetAll$Response(params?: DataboxNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DataBoxNodeDto>>> {
    return databoxNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `databoxNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthGetAll(params?: DataboxNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<DataBoxNodeDto>> {
    return this.databoxNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DataBoxNodeDto>>): Array<DataBoxNodeDto> => r.body)
    );
  }

  /** Path part for operation `databoxNodesOauthGetDefaultDataboxNode()` */
  static readonly DataboxNodesOauthGetDefaultDataboxNodePath = '/oauth/databox-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `databoxNodesOauthGetDefaultDataboxNode()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthGetDefaultDataboxNode$Response(params?: DataboxNodesOauthGetDefaultDataboxNode$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<DataBoxNodeDto>> {
    return databoxNodesOauthGetDefaultDataboxNode(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `databoxNodesOauthGetDefaultDataboxNode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  databoxNodesOauthGetDefaultDataboxNode(params?: DataboxNodesOauthGetDefaultDataboxNode$Params|null|undefined, context?: HttpContext): Observable<DataBoxNodeDto> {
    return this.databoxNodesOauthGetDefaultDataboxNode$Response(params, context).pipe(
      map((r: StrictHttpResponse<DataBoxNodeDto>): DataBoxNodeDto => r.body)
    );
  }

}
