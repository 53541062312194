import {SearchApiService, SearchParams} from './search-api.service';
import {Injectable} from '@angular/core';
import {CORE_MICROSERVICE_ROOT, Page} from '../api';
import {ApiRegistryOfficeTransferService, RegistryOfficeTransferProblemDto} from '|api/document';

@Injectable({
  providedIn: 'root'
})
export class RegistryOfficeSearchService extends SearchApiService {

  findRegistryOfficeProblems(searchParams: Partial<SearchParams>) {
    return this.searchApi<Page<RegistryOfficeTransferProblemDto>>(searchParams, CORE_MICROSERVICE_ROOT + ApiRegistryOfficeTransferService.RegistryOfficeTransferFindRegistryOfficeTransferProblemsPath);
  }

}
