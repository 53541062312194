/* tslint:disable */
/* eslint-disable */
export enum ComponentAuthorizedOperation {
  COMPONENT_SHOW_PROFILE = 'COMPONENT_SHOW_PROFILE',
  COMPONENT_MODIFY_PROFILE = 'COMPONENT_MODIFY_PROFILE',
  COMPONENT_SHOW_CONTENT = 'COMPONENT_SHOW_CONTENT',
  COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION = 'COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION',
  COMPONENT_DIGITIZE_ANALOG_COMPONENT = 'COMPONENT_DIGITIZE_ANALOG_COMPONENT',
  COMPONENT_CHECKOUT = 'COMPONENT_CHECKOUT',
  COMPONENT_CHECKIN = 'COMPONENT_CHECKIN',
  COMPONENT_LOCK = 'COMPONENT_LOCK',
  COMPONENT_UNLOCK = 'COMPONENT_UNLOCK',
  COMPONENT_DOWNLOAD = 'COMPONENT_DOWNLOAD',
  COMPONENT_CONVERT = 'COMPONENT_CONVERT',
  COMPONENT_EXTRACT = 'COMPONENT_EXTRACT',
  COMPONENT_CONVERT_WITH_CLAUSE = 'COMPONENT_CONVERT_WITH_CLAUSE',
  COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE = 'COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE',
  COMPONENT_PERFORM_AUTHORIZED_CONVERSION_EL = 'COMPONENT_PERFORM_AUTHORIZED_CONVERSION_EL',
  COMPONENT_SHOW_ACTIVITIES = 'COMPONENT_SHOW_ACTIVITIES',
  COMPONENT_CREATE_SIGNING_ACTIVITY = 'COMPONENT_CREATE_SIGNING_ACTIVITY',
  COMPONENT_CREATE_APPROVAL_ACTIVITY = 'COMPONENT_CREATE_APPROVAL_ACTIVITY',
  COMPONENT_REVOKE_TASK = 'COMPONENT_REVOKE_TASK',
  COMPONENT_BEGIN_TASK = 'COMPONENT_BEGIN_TASK',
  COMPONENT_REJECT_TASK = 'COMPONENT_REJECT_TASK',
  COMPONENT_DELEGATE_TASK = 'COMPONENT_DELEGATE_TASK',
  COMPONENT_APPROVE_OR_DISAPPROVE = 'COMPONENT_APPROVE_OR_DISAPPROVE',
  COMPONENT_SETTLE_SIGN_TASK = 'COMPONENT_SETTLE_SIGN_TASK',
  COMPONENT_SIGN = 'COMPONENT_SIGN',
  COMPONENT_SEAL = 'COMPONENT_SEAL',
  COMPONENT_ADD_TIMESTAMP = 'COMPONENT_ADD_TIMESTAMP',
  COMPONENT_VERIFY_SIGNATURE = 'COMPONENT_VERIFY_SIGNATURE',
  COMPONENT_DELETE = 'COMPONENT_DELETE',
  COMPONENT_ANONYMIZATION = 'COMPONENT_ANONYMIZATION',
  COMPONENT_UPLOAD_DATA = 'COMPONENT_UPLOAD_DATA'
}
