<div class="row-group">
  <icz-button-collection
    #buttonCollectionComponent
    size="large"
    [groupButtons]="true"
    [hideBorders]="true"
    [buttons]="subjectToolbarButtons"
    (buttonClicked)="buttonClicked($event)"
    [areCollapsed]="areButtonsCollapsed$ | async"
  ></icz-button-collection>
</div>
