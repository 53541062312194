/* tslint:disable */
/* eslint-disable */

/**
 * Rejection Reason appendix type (used for discriminating between footer and information appendix)
 */
export enum ExternalNotificationAppendixType {
  FOOTER = 'FOOTER',
  INFORMATION = 'INFORMATION'
}
