<div
  class="h-full"
  [class.row-group]="groupButtonsIfMoreThanOne && !isVertical"
  [class.hide-borders]="hideBorders"
  [class.row]="!groupButtonsIfMoreThanOne && !isVertical"
  [class.col]="!groupButtonsIfMoreThanOne && isVertical">
  @for (button of (_buttons$ | async); track button) {
    @if (button.show ?? true) {
      <!-- icz-button declarations must be kept duplicated bcs
      [matMenuTriggerFor] does not accept null. keep those declarations in sync pls. -->
      @if (!button.submenuItems?.length || (button.submenuItems?.length && (disableAll || button.disable))) {
        <icz-button [svgIcon]="button.icon" [size]="size"
          [testingFeature]="button.isTestingFeature"
          [testingFeatureLabel]="button.isTestingFeature ? 'NYI' : null"
          [disabled]="disableAll || button.disable"
          [primary]="button.type === ButtonType.PRIMARY"
          [iczTooltip]="getTooltip(button) ?? null"
          noCentered
          [isCollapsed]="areCollapsed || button.alwaysCollapse"
          (onAction)="onButtonClick($event, button)"
          [label]="button.label"
          [originalIconColor]="button.useOriginalIconColor ?? false">
          @if (button.iconAfter) {
            <icz-icon class="ml-16 mr-8" [svgIcon]="button.iconAfter" size="default"></icz-icon>
          }
        </icz-button>
      }
    @else {
        <icz-button [svgIcon]="button.icon" [size]="size"
          [testingFeature]="button.isTestingFeature"
          [testingFeatureLabel]="button.isTestingFeature ? 'NYI' : null"
          [disabled]="disableAll || button.disable"
          [primary]="button.type === ButtonType.PRIMARY"
          [iczTooltip]="getTooltip(button) ?? null"
          noCentered
          [matMenuTriggerFor]="submenu"
          [isCollapsed]="areCollapsed || button.alwaysCollapse"
          (onAction)="onButtonClick($event, button)"
          [label]="button.label"
          [originalIconColor]="button.useOriginalIconColor ?? false">
          @if (button.iconAfter) {
            <icz-icon class="ml-16 mr-8" [svgIcon]="button.iconAfter" size="default"></icz-icon>
          }
        </icz-button>
        <mat-menu #submenu="matMenu">
          <icz-button-collection
            [groupButtons]="false"
            [isVertical]="true"
            [hideBorders]="true"
            [size]="size"
            [buttons]="button.submenuItems || []"
            (buttonClicked)="onButtonClick(null, $event)"
          ></icz-button-collection>
        </mat-menu>
      }
    }
  }
</div>
