import {RetentionTriggerTypeCode, SettlementType} from '|api/commons';
import {ClassificationSchemeDto} from '|api/codebook';
import {DocumentDto} from '|api/document';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';

export interface SearchedDocumentOptionData {
  document: DocumentDto;
}

export interface DefaultDocumentSettlement {
  documentSettlementType: SettlementType;
  documentTypeId: Nullable<number>;
  settlementDate: Nullable<string>;
  entityClassId: Nullable<number>;
  deleteComments: Nullable<boolean>;
  content: Nullable<string>;
  reason: Nullable<string>;
  yearOfRetentionPeriodStart: Nullable<number>;
  triggerEventCheckYear: Nullable<number>;
}

export interface SettlementTabState {
  prepared: boolean;
  classificationScheme: Nullable<ClassificationSchemeDto>;
}

export interface DocumentSettlementInnerDto {
  excludedComponentIds?: Array<number>;
  documentId: number;
  documentTypeId?: number;
}

export interface DisposalScheduleSource {
  entityClassId: Nullable<number>;
  documentTypeId: Nullable<number>;
}

export function adjustSettlementDate(settlementDate: string): string {
  const today = new Date();

  const isToday = today.toDateString() === new Date(settlementDate).toDateString();
  if (isToday) {
    settlementDate = new Date().toISOString();
  }

  return settlementDate;
}

export function getSettlementFormGroup(document: DocumentDto, settlementType: SettlementType) {
  const todayDateString = new Date().toISOString();

  return new IczFormGroup({
    documentForm: new IczFormControl<Nullable<string>>({value: document.documentForm, disabled: true}, [IczValidators.required()]),
    documentSettlementType: new IczFormControl<Nullable<SettlementType>>(settlementType, [IczValidators.required()]),
    documentTypeId: new IczFormControl<Nullable<number>>(null, []),
    relatedDocumentTypeId: new IczFormControl<Nullable<number>>(document.documentTypeId, []),
    settlementDate: new IczFormControl<Nullable<string>>(todayDateString, [IczValidators.required()]),
    // read-only fields
    fileId: new IczFormControl<Nullable<number>>(document.fileId, []),
    entityClassId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    componentCountForSettlement: new IczFormControl<Nullable<number>>({value: 0, disabled: true}, []),
    classificationSchemeName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidFrom: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidTo: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    disposalScheduleId: new IczFormControl<Nullable<string>>(null, []),
    disposalScheduleSource: new IczFormControl<Nullable<string>>(null, []),
    // non document DTO fields needed to be part of FormGroup
    deleteComments: new IczFormControl<Nullable<boolean>>(true, []),
    esslComponents: new IczFormControl<Nullable<number[]>>([]),
    excludedComponentIds: new IczFormControl<Nullable<number[]>>([]),
    excludedRelatedDocumentComponentIds: new IczFormControl<Nullable<number[]>>([]),
    relatedDocumentComponents: new IczFormControl<Nullable<number[]>>([]),
    relatedDocumentId: new IczFormControl<Nullable<string>>(null),
    content: new IczFormControl<Nullable<string>>(null),
    reason: new IczFormControl<Nullable<string>>(null),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionTriggerTypeCode: new IczFormControl<Nullable<RetentionTriggerTypeCode>>({value: null, disabled: true}),
    triggerEventCheckYear: new IczFormControl<Nullable<string>>({value: null, disabled: true}, [IczValidators.min((new Date()).getFullYear()), IczValidators.isInteger()]),
    externalRetentionTriggerIds: new IczFormControl<Nullable<string>>(null),
    disposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
  });
}

export function getSettlementGeneralTabForm() {
  return new IczFormGroup({
    documentSettlementType: new IczFormControl<Nullable<SettlementType>>(null, [IczValidators.required()]),
    documentTypeId: new IczFormControl<Nullable<number>>(null, []),
    settlementDate: new IczFormControl<Nullable<string>>(new Date().toISOString(), [IczValidators.required()]),
    // read-only fields
    entityClassId: new IczFormControl<Nullable<number>>(null, []),
    classificationSchemeName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidFrom: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    classificationSchemeValidTo: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    disposalScheduleId: new IczFormControl<Nullable<string>>(null, []),
    disposalScheduleSource: new IczFormControl<Nullable<string>>(null, []),
    // non document DTO fields needed to be part of FormGroup
    deleteComments: new IczFormControl<Nullable<boolean>>(null),
    content: new IczFormControl<Nullable<string>>(null),
    reason: new IczFormControl<Nullable<string>>(null),
    yearOfRetentionPeriodStart: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionTriggerTypeCode: new IczFormControl<Nullable<RetentionTriggerTypeCode>>({value: null, disabled: true}),
    triggerEventCheckYear: new IczFormControl<Nullable<string>>({value: null, disabled: true}, [IczValidators.min((new Date()).getFullYear()), IczValidators.isInteger()]),
    externalRetentionTriggerIds: new IczFormControl<Nullable<string>>(null),
    disposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    retentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
  });
}
