import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ReceivedDigitalConsignmentDto, ReceivedEpdzMessageDetailDto} from '|api/sad';

@Component({
  selector: 'icz-delivery-info-databox',
  templateUrl: './delivery-info-databox.component.html',
  styleUrls: ['./delivery-info-databox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryInfoDataboxComponent {
  @Input()
  sectionLabel = '';
  @Input()
  showDeliveryType = false;
  @Input()
  showSignatureStatus = false;

  /**
   * consignment and message are mutually exclusive inputs
   */
  @Input()
  message!: Nullable<ReceivedEpdzMessageDetailDto>;
  @Input()
  consignment!: Nullable<ReceivedDigitalConsignmentDto>;
}
