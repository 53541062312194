import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {TableConfig} from '../../table/table.models';
import {TableColumnsData} from '../../table/table-columns-data';
import {RowComparatorFn} from '../../table/selected-rows.service';
import {OrganizationalStructureOption} from '../../../core/services/organizational-structure.service';
import {CirculationTableDataType} from './circulation-table.models';
import {IczTableDataSource} from '../../table/table.datasource';
import {
  CirculationView,
  isActivityView,
  isComponentTaskOrActivityView,
  isTaskView,
} from '../document-toolbar/services/toolbar-common.utils';
import {extendDefaultTableConfig, TableComponent} from '../../table/table.component';
import {CirculationTableColumn, getColumnsData, getColumnsForView} from './circulation-table.columnsets';
import {Option} from '../../../model';
import {COMPONENT_ENTITY_TYPES, getLatestDigitalComponentVersion} from '../shared-document.utils';
import {DocumentFiltersDataService} from '../document-table/components/document-filters/document-filters-data.service';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {SearchParams, SortParam} from '../../../services/search-api.service';
import {EsslComponentModalService} from '../../../services/essl-component-modal.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CirculationTaskState} from '|api/commons';


export function formatPrimaryTargetParticipant(targetParticipants: number[], orgStructureOptions:  OrganizationalStructureOption[], originId: 'fp'|'ou') {
  let out = '';
  const primaryTargetParticipantId = targetParticipants[0];

  if (primaryTargetParticipantId) {
    const targetFunctionalPositionName = orgStructureOptions.find(o => o.originId === originId && o.id === primaryTargetParticipantId)?.label;

    if (targetFunctionalPositionName) {
      out += targetFunctionalPositionName;
    }
    else {
      out += '???';
    }
  }

  if (targetParticipants.length > 1) {
    out += ` +${targetParticipants.length - 1}`;
  }

  return out;
}


@Component({
  selector: 'icz-circulation-table',
  templateUrl: './circulation-table.component.html',
  styleUrls: ['./circulation-table.component.scss'],
})
export class CirculationTableComponent<T extends CirculationTableDataType = CirculationTableDataType> implements OnInit {

  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private esslComponentModalService = inject(EsslComponentModalService);
  protected loadingService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) tableId!: string;

  @Input({required: true}) dataSource!: IczTableDataSource<T>;

  @Input()
  openQuickPreview = true;

  @Input()
  isUnitView = false; // todo(rb) temporary parameter until task solving in unit views is implemented

  @Input({required: true})
  set viewType(viewType: CirculationView) {
    if (viewType && this._viewType !== viewType) {
      const previousViewType = this._viewType;
      this._viewType = viewType;

      /**
       * Should not run on first change because it will init TableToolbarService
       * twice which results in glitches in global search when using search link.
       */
      if (previousViewType) {
        this.initializeTableConfig();
        this.initializeColumnMetadata();
      }
    }
  }
  get viewType(): CirculationView {
    return this._viewType;
  }

  @Output()
  pageLoad = new EventEmitter<SearchParams>();
  @Output()
  reload = new EventEmitter<void>();

  @ViewChild('iczTable') iczTable!: TableComponent<CirculationTableColumn>;

  config!: TableConfig<CirculationTableColumn>;

  columnsData!: TableColumnsData<CirculationTableColumn>;
  selectedRows: Array<T> = [];
  activeRow: Nullable<T> = null;
  isQuickPreviewOpen = false;

  gracefulElasticRowComparator: RowComparatorFn<T> = (a, b) => {
    if (a.elasticId && b.elasticId) {
      return a.elasticId === b.elasticId;
    }
    else {
      // When not working with Elastic data sources, IDs are guaranteed to be
      // unique because they originate from database auto-incremented sequence.
      return a.id === b.id;
    }
  };

  _viewType!: CirculationView;

  get isActivityView() {
    return isActivityView(this._viewType!);
  }

  get isTaskView() {
    return isTaskView(this._viewType!);
  }

  get isIssdTaskView() {
    return this._viewType! === CirculationView.ISSD_TASKS || this._viewType! === CirculationView.ISSD_TASKS_REJECTED;
  }

  readonly CirculationTableColumn = CirculationTableColumn;
  readonly CirculationTaskState = CirculationTaskState;

  getLatestDigitalComponentVersion = getLatestDigitalComponentVersion;

  functionalPositionTreeOptions: Option[] = [];
  organizationalUnitTreeOptions: Option[] = [];

  private issdAppsOptions: Option[] = [];

  ngOnInit() {
    this.initializeTableConfig();

    const req$: Observable<any>[] = [
      this.documentFiltersDataService.functionalPositionTreeOptions$,
      this.documentFiltersDataService.organizationalUnitTreeOptions$
    ];

    if (this.isIssdTaskView) {
      req$.push(this.documentFiltersDataService.issdAppsOptions$);
    } else {
      req$.push(of([]));
    }

    forkJoin(req$).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([functionalPositionTreeOptions, organizationalUnitTreeOptions, issdApps]) => {
      this.functionalPositionTreeOptions = functionalPositionTreeOptions as Option[];
      this.organizationalUnitTreeOptions = organizationalUnitTreeOptions as Option[];
      this.issdAppsOptions = issdApps as Option[];

      this.initializeColumnMetadata();
    });
  }

  private initializeTableConfig() {
    let defaultSort: Nullable<SortParam<CirculationTableColumn>>;

    if (this.isActivityView) {
      defaultSort = {
        fieldName: CirculationTableColumn.INIT_DATE,
        descending: true,
      };
    }
    else if (this.isTaskView) {
      defaultSort = {
        fieldName: CirculationTableColumn.ASSIGNMENT_DATE,
        descending: true,
      };
    }

    this.config = extendDefaultTableConfig({
      rowHeight: isComponentTaskOrActivityView(this._viewType!) ? 70 : 36,
      hasActiveRow: this.openQuickPreview,
      defaultFilterColumns: [
        CirculationTableColumn.OBJECT_CLASS,
      ],
      toolbarConfig: {
        showFilter: true,
        showSavedFilters: true,
        autoOpenFilter: false,
        showFulltextSearch: true,
      },
      defaultSort,
    });
  }

  private initializeColumnMetadata() {
    this.columnsData = getColumnsData(
      this.documentFiltersDataService,
      this.functionalPositionTreeOptions,
      this.organizationalUnitTreeOptions,
      this.issdAppsOptions,
      getColumnsForView(this._viewType!),
      this._viewType!,
    );
  }

  loadPage(searchParams: SearchParams) {
    this.dataSource.loadPage(searchParams);
    this.isQuickPreviewOpen = false;
    this.pageLoad.emit(searchParams);
  }

  setActiveRow(row: T) {
    this.activeRow = row;
    this.isQuickPreviewOpen = Boolean(row);
  }

  unselectAndReloadRow() {
    this.dataSource.reload(true);
    this.reload.emit();
  }

  getPrimaryTargetParticipantText(targetParticipants: number[]) {
    return formatPrimaryTargetParticipant(targetParticipants, this.functionalPositionTreeOptions as OrganizationalStructureOption[], 'fp');
  }

  getPrimaryTargetParticipantOrgUnitText(targetParticipants: number[]) {
    return formatPrimaryTargetParticipant(targetParticipants, this.organizationalUnitTreeOptions as OrganizationalStructureOption[], 'ou');
  }

  setSelectedRows(rows: Array<T>) {
    this.selectedRows = rows;
  }

  openCirculationComponent(row: T) {
    const castedRow = row as CirculationTaskDto | CirculationActivityDto;

    this.esslComponentModalService.openEsslComponentDetailWithFullFetch(
      castedRow.componentId!,
      castedRow.digitalComponentVersionId,
      castedRow.documentId!,
      true,
      true,
    ).subscribe(componentChanged => {
      if (componentChanged) {
        this.dataSource.reload(true);
      }
    });
  }

  isComponentCirculation(row: T) {
    return COMPONENT_ENTITY_TYPES.includes(row.circulationEntityType);
  }

}
