import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {DigitalComponentVersionTypeFlag, EntityType} from '|api/commons';
import {LoadingIndicatorService} from '../loading-indicator.service';
import {
  BinaryWithCharset,
  RemoteBinaryFileDownloadService
} from '../../../services/remote-binary-file-download.service';
import {getComponentIcon} from '../../../model';
import {LocalBinaryFileDownloadService} from '../../../services/local-binary-file-download.service';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {injectModalData, injectModalRef} from '../../../lib/modals';

export interface PlainComponentViewerData {
  digitalComponentVersionId: Nullable<number>;
  digitalComponentVersionFileName: Nullable<string>;
  digitalComponentVersionMimeType: Nullable<string>;
  digitalComponentVersionTypeFlag: Nullable<DigitalComponentVersionTypeFlag>;
  modalTitleSuffix?: Nullable<string>;
}

@Component({
  selector: 'icz-plain-component-version-viewer-dialog',
  templateUrl: './plain-component-version-viewer-dialog.component.html',
  styleUrls: ['./plain-component-version-viewer-dialog.component.scss'],
})
export class PlainComponentVersionViewerDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<PlainComponentVersionViewerDialogComponent>();
  protected remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  protected versionData = injectModalData<PlainComponentViewerData>();

  @ViewChild(CdkDrag, {static: false})
  cdkDrag!: CdkDrag;

  get isMaximized() {
    return this.modalRef.isMaximized;
  }

  get digitalComponentVersionFileIcon() {
    return getComponentIcon(EntityType.DIGITAL_COMPONENT!, this.versionData?.digitalComponentVersionTypeFlag!);
  }

  fileContents: Nullable<BinaryWithCharset>;

  ngOnInit() {
    this.loadingService.doLoading(this.remoteBinaryFileDownloadService.fetchDigitalComponentVersion(this.versionData?.digitalComponentVersionId!),
      this)
      .subscribe(binary => {
        this.fileContents = binary;
      });
  }

  download() {
    this.localBinaryFileDownloadService.downloadBlob(
      this.fileContents!.buffer,
      this.versionData.digitalComponentVersionFileName ?? 'file',
      this.versionData.digitalComponentVersionMimeType,
    );
  }

  print() {
    // todo(rb) implement after BE ready
  }

  toggleMaximize() {
    this.cdkDrag.reset();
    this.modalRef.toggleMaximize();
  }

  cancel() {
    this.modalRef.close();
  }

}
