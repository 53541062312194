/* tslint:disable */
/* eslint-disable */

/**
 * Object class for Document
 */
export enum DocumentAllowableObjectClass {
  RECEIVED_DOCUMENT = 'RECEIVED_DOCUMENT',
  RECEIVED_DOCUMENT_DIGITAL = 'RECEIVED_DOCUMENT_DIGITAL',
  OWN_DOCUMENT = 'OWN_DOCUMENT',
  OWN_DOCUMENT_DIGITAL = 'OWN_DOCUMENT_DIGITAL'
}
