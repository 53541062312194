import {Component, inject, Input} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Page} from '../../../api';
import {KeywordDto} from '|api/codebook';
import {CodebookSearchService} from '../../../services/codebook-search.service';
import {Option} from '../../../model';
import {findKeywordsByIDs} from '../../../services/search-methods.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {AbstractClassifierSelectorComponent} from '../abstract-classifier-selector.component';

export function findKeywordsByIDsToOptions(ids: number[], searchService: CodebookSearchService): Observable<Option[]> {
  return findKeywordsByIDs(ids as number[], searchService).pipe(
    map((res: Page<KeywordDto>) => res.content),
    map((content: Array<KeywordDto>) => {
      return content.map((k: KeywordDto) => {
        return {value: k.id, label: k.code};
      });
    }));
}


@Component({
  selector: 'icz-keywords-selector',
  templateUrl: './keywords-selector.component.html',
  styleUrls: ['./keywords-selector.component.scss'],
})
export class KeywordsSelectorComponent extends AbstractClassifierSelectorComponent<KeywordDto> {

  private codebookService = inject(CodebookService);

  @Input()
  label = 'Klíčová slova';
  @Input()
  isMultiselect = true;

  classifierSource$ = this.codebookService.keywords();
  optionMapper = (k: KeywordDto) => ({value: k.id!, label: k.code});

}
