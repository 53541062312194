/* tslint:disable */
/* eslint-disable */

/**
 * Applied dimensions
 */
export enum StatisticsDimension {
  SECURITY_CATEGORY = 'SECURITY_CATEGORY',
  ENTITY_CLASS = 'ENTITY_CLASS',
  ENTITY_STATE = 'ENTITY_STATE'
}
