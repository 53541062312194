/* tslint:disable */
/* eslint-disable */

/**
 * Composition type
 */
export enum AnalogCompositionType {
  SHEETS = 'SHEETS',
  VOLUMES = 'VOLUMES'
}
