/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CirculationTaskDto } from '../models/circulation-task-dto';
import { circulationTaskStatementPass } from '../fn/circulation-task-statement/circulation-task-statement-pass';
import { CirculationTaskStatementPass$Params } from '../fn/circulation-task-statement/circulation-task-statement-pass';
import { circulationTaskStatementReject } from '../fn/circulation-task-statement/circulation-task-statement-reject';
import { CirculationTaskStatementReject$Params } from '../fn/circulation-task-statement/circulation-task-statement-reject';
import { circulationTaskStatementRevoke } from '../fn/circulation-task-statement/circulation-task-statement-revoke';
import { CirculationTaskStatementRevoke$Params } from '../fn/circulation-task-statement/circulation-task-statement-revoke';
import { circulationTaskStatementStatement } from '../fn/circulation-task-statement/circulation-task-statement-statement';
import { CirculationTaskStatementStatement$Params } from '../fn/circulation-task-statement/circulation-task-statement-statement';


/**
 * Controller for executing actions on statement document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskStatementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskStatementStatement()` */
  static readonly CirculationTaskStatementStatementPath = '/flow/statement/{id}/statement';

  /**
   * Finish task by statement by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskStatementStatement()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementStatement$Response(params: CirculationTaskStatementStatement$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskStatementStatement(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by statement by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskStatementStatement$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementStatement(params: CirculationTaskStatementStatement$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskStatementStatement$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskStatementRevoke()` */
  static readonly CirculationTaskStatementRevokePath = '/flow/statement/{id}/revoke';

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskStatementRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementRevoke$Response(params: CirculationTaskStatementRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskStatementRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskStatementRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementRevoke(params: CirculationTaskStatementRevoke$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskStatementRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskStatementReject()` */
  static readonly CirculationTaskStatementRejectPath = '/flow/statement/{id}/reject';

  /**
   * Finish task by reject task by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskStatementReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementReject$Response(params: CirculationTaskStatementReject$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskStatementReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by reject task by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskStatementReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementReject(params: CirculationTaskStatementReject$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskStatementReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskStatementPass()` */
  static readonly CirculationTaskStatementPassPath = '/flow/statement/{id}/pass';

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskStatementPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementPass$Response(params: CirculationTaskStatementPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskStatementPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskStatementPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskStatementPass(params: CirculationTaskStatementPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskStatementPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

}
