/* tslint:disable */
/* eslint-disable */

/**
 * Type of public authority.
 */
export enum OvmType {
  OVM = 'OVM',
  PO_OVM = 'PO_OVM',
  PFO_OVM = 'PFO_OVM',
  FO_OVM = 'FO_OVM'
}
