<div class="wrapper" cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin">

  @if (asPopover) {
    <icz-form-field
      #selectedValueField
      class="selected-value-field"
      [class.focused]="optionsOpen"
      (inputClick)="inputFieldClicked($event)"
      [hasClickableInput]="true"
      (keydown.arrowDown.stop)="openPopover()"
      (keydown.enter.stop)="openPopover()"
      (keydown.space.stop)="openPopover()"
      [showValidationStatus]="false"
      [fieldDisabled]="disabled"
      [label]="label"
      [hideInputElement]="true"
      [rightLabel]="rightLabel"
      [showRightLabelPopupOnClick]="showRightLabelPopupOnClick"
      [autoSizeMax]="!(selectedOptions$ | async)!.length ? 1 : (isMultiselect ? MULTISELECT_VALUES_LIST_MAX_LINES : autoSizeMax)">
      <ng-content prefix select="[prefix]"></ng-content>
      <ng-content rightLabelPopup select="[rightLabelPopup]"></ng-content>
      <div class="internal-content" [class.internal-content-disabled]="disabled"
        [ngStyle]="(isMultiselect || autoSizeMax > 1) ? getInternalContentStyle(isMultiselect ? MULTISELECT_VALUES_LIST_MAX_LINES : autoSizeMax, !(selectedOptions$ | async)!.length) : null"
        >
        @if (placeholder && !(selectedOptions$ | async)!.length) {
          <span class="icz-select-placeholder">{{ placeholder | translate }}</span>
        }
        @if ((selectedOptions$ | async)!.length) {
          <ng-container [ngTemplateOutlet]="selectedOptionTemplate"
            [ngTemplateOutletContext]="selectedOptionContext$ | async">
          </ng-container>
        }
      </div>
      <ng-container suffix>
        <ng-content select="[suffix]"></ng-content>
        @if (!disabled) {
          @if (showClearButton) {
            <icz-icon
              class="clear-selection"
              size="small"
              svgIcon="delete"
              (click)="clearClicked($event)">
            </icz-icon>
          }
          <icz-icon class="open-dropdown"
            size="small"
            svgIcon="expand_more"
            (click)="openClicked($event)">
          </icz-icon>
        }
      </ng-container>
    </icz-form-field>
  }

  @if (asPopover) {
    <icz-popover
      [isOpen]="optionsOpen"
      [overlayOrigin]="overlayOrigin"
      (onClose)="optionsClosed(false)">
      @if (optionsOpen) {
        <div
          class="content-wrapper"
          [style.width.px]="selectedValueFieldWidthPx"
          (keydown)="keyPressed($event)">
          <ng-container [ngTemplateOutlet]="popoverContentTemplate"
            [ngTemplateOutletContext]="popoverContentContext$ | async">
          </ng-container>
        </div>
      }
    </icz-popover>
  }

  @if (!asPopover) {
    <div class="content-wrapper">
      <ng-container [ngTemplateOutlet]="popoverContentTemplate"
        [ngTemplateOutletContext]="popoverContentContext$ | async">
      </ng-container>
    </div>
  }

</div>
