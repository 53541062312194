/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentMarkRequest } from '../../models/document-mark-request';
import { DocumentMarkResponse } from '../../models/document-mark-response';

export interface SignatureMarkDocument$Params {

/**
 * ID of document to mark
 */
  documentId: number;
      body: DocumentMarkRequest
}

export function signatureMarkDocument(http: HttpClient, rootUrl: string, params: SignatureMarkDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentMarkResponse>> {
  const rb = new RequestBuilder(rootUrl, signatureMarkDocument.PATH, 'post');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentMarkResponse>;
    })
  );
}

signatureMarkDocument.PATH = '/document/signature/{documentId}/mark';