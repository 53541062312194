/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DigitalComponentVersionCompleteDto } from '../../models/digital-component-version-complete-dto';

export interface DigitalComponentVersionUploadNewFile$Params {

/**
 * Validate authentication elements on file.
 */
  validate?: boolean|null|undefined;
      body?: {
'file': Blob;
}|null|undefined
}

export function digitalComponentVersionUploadNewFile(http: HttpClient, rootUrl: string, params?: DigitalComponentVersionUploadNewFile$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<DigitalComponentVersionCompleteDto>> {
  const rb = new RequestBuilder(rootUrl, digitalComponentVersionUploadNewFile.PATH, 'post');
  if (params) {
    rb.query('validate', params.validate, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DigitalComponentVersionCompleteDto>;
    })
  );
}

digitalComponentVersionUploadNewFile.PATH = '/component/digital-component-version';