import {FileOrProfileDtoWithAuthorization} from '../../model/dto-with-permissions.interface';
import {isFileObject} from '../../shared-document.utils';
import {FileState} from '|api/commons';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {OperationValidator, ValidatorGuard} from '../../../../services/bulk-operation-validation.service';
import {FileDto} from '|api/document';

export class FileOperationValidators {
  @ValidatorGuard()
  static selectedItemIsNotFile(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return !isFileObject(file) ? {validationMessage: 'Akci lze provést pouze se spisy' } : null;
    };
  }

  static isFileNotSettled(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return (file.fileState !== FileState.SETTLED) ? {validationMessage: 'Spis není vyřízen' } : null;
    };
  }

  static isFileDeactivated(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return (file.fileState === FileState.DEACTIVATED) ? {validationMessage: 'Spis je zrušený' } : null;
    };
  }

  static isFileClosed(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return (file.fileState === FileState.CLOSED) ? {validationMessage: 'Spis již byl uzavřen' } : null;
    };
  }

  static isFileNotClosed(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return (file.fileState !== FileState.CLOSED) ? {validationMessage: 'Spis ještě nebyl uzavřen' } : null;
    };
  }

  static isFileNotOpen(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return (file.fileState !== FileState.OPEN) ? {validationMessage: 'Spis není ve stavu Otevřený' } : null;
    };
  }

  static isFileInStorageUnit(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      return (file as FileDto).storageUnitId ? {validationMessage: 'Spis je v ukládací jednotce' } : null;
    };
  }

  static isFileDeactivetePreconditionNotMet(
    currentSessionService: CurrentSessionService,
    organizationalStructureService: OrganizationalStructureService,
  ): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      const predicate = (file: FileOrProfileDtoWithAuthorization) => !(file.ownerFunctionalPositionId === currentUserFpId || organizationalStructureService.getParentsFPs(file.ownerFunctionalPositionId!).includes(currentUserFpId));
      const currentUserFpId = currentSessionService.currentUserFunctionalPosition!.id;

      return (predicate(file) ?
          {validationMessage: 'Pro spis nejste zpracovatelem ani jeho nadřízeným' } : null
      );
    };
  }

  static fileHandoverToExternalApplicationPreconditionNotMet(): OperationValidator<FileOrProfileDtoWithAuthorization> {
    return (file: FileOrProfileDtoWithAuthorization) => {
      const predicate = (file: FileOrProfileDtoWithAuthorization) => (
        file.fileState && (file.fileState !== FileState.SETTLED && file.fileState !== FileState.OPEN && file.fileState !== FileState.CLOSED));

      return (predicate(file) ? {validationMessage: 'Spis není ve stavu Otevřen, Vyřízen nebo Uzavřen' } : null);
    };
  }
}
