<div class="shortcut-list shortcut-list--grid">
  @for (shortcut of shortcutListOptions; track shortcut.value) {
    <div
      (click)="onShortcutClick(shortcut.value)"
      class="shortcut"
      [class.shortcut--active]="selectionMode === DateFilterSelectionMode.INTERVAL && shortcut.value === activeIntervalShortcut"
      [class.shortcut--custom-range]="shortcut.value === DatePickerShortcut.OWN_DATE_RANGE"
      >
      {{ shortcut.label | translate }}
    </div>
  }
</div>
<div class="shortcuts-subform">
  @if (activeIntervalShortcut === DatePickerShortcut.OWN_DATE_RANGE) {
    <form class="row-group" [formGroup]="rangeForm">
      <icz-form-field iczAutoFocus class="grow" formControlName="start" (keydown)="handleKeyboardShortcuts($event)">
        <span prefix>{{ 'Od' | translate }}:&nbsp;</span>
      </icz-form-field>
      <icz-form-field class="grow" formControlName="end" (keydown)="handleKeyboardShortcuts($event)">
        <span prefix>{{ 'Do' | translate }}:&nbsp;</span>
      </icz-form-field>
      <icz-button
        primary label="Použít" class="mb-16"
        (onAction)="applyOwnDateRange()"
      ></icz-button>
    </form>
  }
  @if (activeIntervalShortcut === DatePickerShortcut.X_DAYS_AGO_FROM_TODAY) {
    <form [formGroup]="daysAgoFromTodayForm">
      <icz-form-field iczAutoFocus type="integer" formControlName="days">
        <span prefix>{{ 'Počet dnů do minulosti' | translate }}:&nbsp;</span>
      </icz-form-field>
    </form>
  }
</div>
