/* tslint:disable */
/* eslint-disable */

/**
 * Visible signature page
 */
export enum VisibleSignaturePage {
  FIRST = 'FIRST',
  LAST = 'LAST',
  NUMBER = 'NUMBER'
}
