import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Observable} from 'rxjs';
import {DocumentState, EntityType} from '|api/commons';
import {DigitalComponentCompleteDto, PaperComponentDto} from '|api/component';
import {DocumentDto} from '|api/document';
import {OwnOfficeDeskConsignmentDto} from '|api/sad';
import {DocumentsTableDataType, getDetailUrlByEntity, TableEntityView} from '../../documents-table.models';
import {DocumentView, REGISTRY_OFFICE_TRANSFER_VIEWS} from '../../../document-toolbar/services/toolbar-common.utils';
import {
  getLatestDigitalComponentVersion,
  isActivityEntity,
  isDocumentObject,
  isEsslObjectEntity,
  isTaskEntity
} from '../../../shared-document.utils';
import {createAbsoluteRoute} from '../../../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../../../enums/shared-routes.enum';
import {DocumentDetailRoute, DocumentsRoute} from '../../../../../enums/documents-routes.enum';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {ComponentSearchService} from '../../../../../services/component-search.service';
import {FilterOperator} from '../../../../../services/search-api.service';
import {EsslEntity} from '../../../model/entity.model';
import {EsslComponentDto} from '../../../../../services/essl-component-search.service';
import {EsslComponentModalService} from '../../../../../services/essl-component-modal.service';
import {HttpErrorResponse} from '@angular/common/http';
import {EntityWithRefNumber} from '../../../ref-number-link/ref-number-link.component';
import {Router} from '@angular/router';


@Component({
  selector: 'icz-table-entity-icon',
  templateUrl: './table-entity-icon.component.html',
  styleUrls: ['./table-entity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableEntityIconComponent<T extends DocumentsTableDataType = DocumentsTableDataType> implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private cd = inject(ChangeDetectorRef);
  private router = inject(Router);
  private searchService = inject(ComponentSearchService);
  private esslComponentModalService = inject(EsslComponentModalService);

  @Input()
  documentsTableEntity: Nullable<T & EsslEntity>;
  @Input()
  consignment: Nullable<OwnOfficeDeskConsignmentDto>;

  @Input({required: true})
  view!: TableEntityView;

  private _documentEsslComponents$ = new BehaviorSubject<Nullable<EsslComponentDto[]>>(null);
  documentEsslComponents$ = this._documentEsslComponents$.asObservable();
  maxDocumentEsslComponentsInPreview = 5;
  notAllComponentsVisible = false;
  totalComponentCount = 0;

  isDigitalComponentsPopupOpen = false;
  isDocumentDeactivated = false;

  get entity(): Nullable<(T & EsslEntity) | OwnOfficeDeskConsignmentDto> {
    return this.documentsTableEntity ?? this.consignment;
  }

  get isOriginalObjectIconVisible() {
    return (
      isEsslObjectEntity(this.documentsTableEntity) ||
      this.view === DocumentView.DOCUMENT_SELECTOR || REGISTRY_OFFICE_TRANSFER_VIEWS.includes(this.view!) ||
      (this.view !== DocumentView.GLOBAL_SEARCH && (isTaskEntity(this.documentsTableEntity) || isActivityEntity(this.documentsTableEntity))) ||
      this.consignment
    );
  }

  get showComponentsWithDigitalContentIndicator() {
    return isDocumentObject(this.documentsTableEntity) && (
      this.documentsTableEntity.currentQuantification?.digitalComponentCount ||
      this.documentsTableEntity.currentQuantification?.analogComponentWithDigitalContentCount);
  }

  readonly isDocumentObject = isDocumentObject;
  readonly isEsslObjectEntity = isEsslObjectEntity;
  readonly createAbsoluteRoute = createAbsoluteRoute;

  readonly EntityType = EntityType;
  readonly DocumentView = DocumentView;
  readonly ApplicationRoute = ApplicationRoute;
  readonly DocumentsRoute = DocumentsRoute;
  readonly DocumentDetailRoute = DocumentDetailRoute;

  canUserDisplayComponents = true;

  ngOnInit() {
    if (this.documentsTableEntity && this.consignment) {
      throw new Error('Either documentsTableEntity or consignment must be provided, not both.');
    }
  }

  entityTypeClick($event: MouseEvent, row: T | OwnOfficeDeskConsignmentDto) {
    if (isDocumentObject(row as any) && this.showComponentsWithDigitalContentIndicator) {
      this._documentEsslComponents$.next(null);

      this.isDigitalComponentsPopupOpen = !this.isDigitalComponentsPopupOpen;

      let reqs$: [Observable<DigitalComponentCompleteDto[]>, Observable<PaperComponentDto[]>];
      if (this.documentsTableEntity) {
        let documentId;

        if (isTaskEntity(row) || isActivityEntity(row)) {
          documentId = row.documentId!;
        } else {
          documentId = row.id;
          this.isDocumentDeactivated = (row as DocumentDto).documentState === DocumentState.DEACTIVATED;
        }

        reqs$ = [
          this.searchService.findDocumentDigitalComponents(documentId, {
            size: this.maxDocumentEsslComponentsInPreview,
          }).pipe(map(c => c.content)),
          this.searchService.findDocumentPaperComponents(documentId, {
            size: this.maxDocumentEsslComponentsInPreview,
            filter: [
              {
                fieldName: 'digitalRenditionId',
                operator: FilterOperator.greater,
                value: String(0),
              }
            ]
          }).pipe(map(c => c.content)),
        ];
      }

      if (!reqs$!) throw new Error('No path for components provided');

      this.loadingService.doLoading(
        combineLatest(reqs$),
        this
      ).subscribe({
        next: ([digitals, papersWithDigitalRenditionPage]) => {
          const content = [...digitals, ...papersWithDigitalRenditionPage];
          if (this.documentsTableEntity) {
            this.totalComponentCount = ((row as DocumentDto).currentQuantification?.digitalComponentCount ?? 0) + ((row as DocumentDto).currentQuantification?.analogComponentCount ?? 0);
          } else {
            this.totalComponentCount = this.consignment!.componentIds!.length;
          }

          this.notAllComponentsVisible = this.totalComponentCount > this.maxDocumentEsslComponentsInPreview;

          if (content.length === 1) {
            const firstViewableComponent = content[0] as DigitalComponentCompleteDto;
            const isFirstViewableComponentDigital = firstViewableComponent.entityType === EntityType.DIGITAL_COMPONENT;

            this.esslComponentModalService.openEsslComponentDetailWithPermissionFetch(
              firstViewableComponent,
              isFirstViewableComponentDigital ? getLatestDigitalComponentVersion(firstViewableComponent)!.id! : null,
              firstViewableComponent.documentId!,
              this.isDocumentDeactivated
            ).subscribe();

            this.isDigitalComponentsPopupOpen = false;

            this.cd.detectChanges();
          }
          else {
            this._documentEsslComponents$.next(content.slice(0, this.maxDocumentEsslComponentsInPreview));
          }
        },
        error: e => {
          if (e instanceof HttpErrorResponse && e.status === 401) {
            this.canUserDisplayComponents = false;
          }
        }
      });
    }

    $event.stopPropagation();
  }

  allComponentsViewClick() {
    const detailUri = getDetailUrlByEntity(this.view, this.entity as EntityWithRefNumber, DocumentDetailRoute.COMPONENTS);

    if (detailUri) {
      this.router.navigateByUrl(detailUri);
    }
  }

}
