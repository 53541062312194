<a
  [hidden]="!defects?.length"
  class="icz-body-1 visible-clickable-area p-4"
  href="javascript:void;"
  cdkOverlayOrigin
  #defectsPopupOrigin="cdkOverlayOrigin"
  (click.stop)="openIssuesPopup()"
  >
  {{ issuesLinkText }}
</a>

<icz-table-toolbar-popup
  [isOpen]="isDefectsPopupOpen"
  [popoverOrigin]="defectsPopupOrigin"
  (onClose)="closeIssuesPopup()"
  >
  @for (defect of defects; track defect.defectType) {
    <div class="icz-body-1 issue-item">
      <span class="icz-body-strong">{{ getDigitalMessageDefectSummaryTranslationKey(defect.defectType) | translate }}</span>
      <br>
        {{ defect.rejectionReasonText }}
        @if (defect.rejectionReasonAlternativeText) {
          <br>
            ---
            <br>
              {{ defect.rejectionReasonAlternativeText }}
            }
          </div>
        }
      </icz-table-toolbar-popup>
