/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageTransactionLogIncidentDto } from '../models/page-transaction-log-incident-dto';
import { transactionLogIncidentFind } from '../fn/transaction-log-incident/transaction-log-incident-find';
import { TransactionLogIncidentFind$Params } from '../fn/transaction-log-incident/transaction-log-incident-find';


/**
 * Controller for Transaction Log Rendition Incidents
 */
@Injectable({ providedIn: 'root' })
export class ApiTransactionLogIncidentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transactionLogIncidentFind()` */
  static readonly TransactionLogIncidentFindPath = '/transaction-log/incident/search';

  /**
   * Find all Transaction Log Rendition incidents based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionLogIncidentFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionLogIncidentFind$Response(params?: TransactionLogIncidentFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageTransactionLogIncidentDto>> {
    return transactionLogIncidentFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Transaction Log Rendition incidents based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionLogIncidentFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionLogIncidentFind(params?: TransactionLogIncidentFind$Params|null|undefined, context?: HttpContext): Observable<PageTransactionLogIncidentDto> {
    return this.transactionLogIncidentFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageTransactionLogIncidentDto>): PageTransactionLogIncidentDto => r.body)
    );
  }

}
