import {ChangeDetectionStrategy, Component, EventEmitter, Output} from '@angular/core';
import {SubjectObjectRelationType} from '|api/commons';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';

// Note: as of 2024-02-12 this is unused, but it is possible user defined subject relations will be possible again
@Component({
  selector: 'icz-add-subject-step-relation',
  templateUrl: './add-subject-step-relation.component.html',
  styleUrls: ['./add-subject-step-relation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddSubjectStepRelationComponent {

  @Output() advanceToSearch = new EventEmitter<SubjectObjectRelationType>();

  SubjectObjectRelationOptions = enumToOptions('subjectObjectRelationType', SubjectObjectRelationType);

  form = new IczFormGroup({
    relationType: new IczFormControl<Nullable<string>>(SubjectObjectRelationType.RELATED, [IczValidators.required()]),
  });

  advanceToSearchStep() {
    this.advanceToSearch.emit(this.form?.get('relationType')?.value as SubjectObjectRelationType);
  }

}
