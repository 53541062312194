/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EffectiveConfigurationValueDto } from '../models/effective-configuration-value-dto';
import { PagePropertyValueDto } from '../models/page-property-value-dto';
import { propertyValueCreate } from '../fn/property-value/property-value-create';
import { PropertyValueCreate$Params } from '../fn/property-value/property-value-create';
import { propertyValueDelete } from '../fn/property-value/property-value-delete';
import { PropertyValueDelete$Params } from '../fn/property-value/property-value-delete';
import { PropertyValueDto } from '../models/property-value-dto';
import { propertyValueGetEffectiveConfiguration } from '../fn/property-value/property-value-get-effective-configuration';
import { PropertyValueGetEffectiveConfiguration$Params } from '../fn/property-value/property-value-get-effective-configuration';
import { propertyValueSearch } from '../fn/property-value/property-value-search';
import { PropertyValueSearch$Params } from '../fn/property-value/property-value-search';
import { propertyValueSearchGlobalScoped } from '../fn/property-value/property-value-search-global-scoped';
import { PropertyValueSearchGlobalScoped$Params } from '../fn/property-value/property-value-search-global-scoped';
import { propertyValueSearchOrganizationScoped } from '../fn/property-value/property-value-search-organization-scoped';
import { PropertyValueSearchOrganizationScoped$Params } from '../fn/property-value/property-value-search-organization-scoped';
import { propertyValueUpdate } from '../fn/property-value/property-value-update';
import { PropertyValueUpdate$Params } from '../fn/property-value/property-value-update';


/**
 * API for management of property values
 */
@Injectable({ providedIn: 'root' })
export class ApiPropertyValueService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `propertyValueUpdate()` */
  static readonly PropertyValueUpdatePath = '/property-value/{id}';

  /**
   * Updates existing property value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueUpdate$Response(params: PropertyValueUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyValueDto>> {
    return propertyValueUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates existing property value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueUpdate(params: PropertyValueUpdate$Params, context?: HttpContext): Observable<PropertyValueDto> {
    return this.propertyValueUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PropertyValueDto>): PropertyValueDto => r.body)
    );
  }

  /** Path part for operation `propertyValueDelete()` */
  static readonly PropertyValueDeletePath = '/property-value/{id}';

  /**
   * Deletes existing property value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueDelete$Response(params: PropertyValueDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return propertyValueDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes existing property value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueDelete(params: PropertyValueDelete$Params, context?: HttpContext): Observable<void> {
    return this.propertyValueDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `propertyValueCreate()` */
  static readonly PropertyValueCreatePath = '/property-value/';

  /**
   * Creates new property value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueCreate$Response(params: PropertyValueCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyValueDto>> {
    return propertyValueCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new property value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  propertyValueCreate(params: PropertyValueCreate$Params, context?: HttpContext): Observable<PropertyValueDto> {
    return this.propertyValueCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PropertyValueDto>): PropertyValueDto => r.body)
    );
  }

  /** Path part for operation `propertyValueGetEffectiveConfiguration()` */
  static readonly PropertyValueGetEffectiveConfigurationPath = '/property-value/{applicationName}/{organizationId}';

  /**
   * Find effective configuration for an application and an organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueGetEffectiveConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueGetEffectiveConfiguration$Response(params: PropertyValueGetEffectiveConfiguration$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<EffectiveConfigurationValueDto>>> {
    return propertyValueGetEffectiveConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Find effective configuration for an application and an organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueGetEffectiveConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueGetEffectiveConfiguration(params: PropertyValueGetEffectiveConfiguration$Params, context?: HttpContext): Observable<Array<EffectiveConfigurationValueDto>> {
    return this.propertyValueGetEffectiveConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EffectiveConfigurationValueDto>>): Array<EffectiveConfigurationValueDto> => r.body)
    );
  }

  /** Path part for operation `propertyValueSearch()` */
  static readonly PropertyValueSearchPath = '/property-value/search-api';

  /**
   * Finds property values based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueSearch$Response(params?: PropertyValueSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePropertyValueDto>> {
    return propertyValueSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds property values based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueSearch(params?: PropertyValueSearch$Params|null|undefined, context?: HttpContext): Observable<PagePropertyValueDto> {
    return this.propertyValueSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePropertyValueDto>): PagePropertyValueDto => r.body)
    );
  }

  /** Path part for operation `propertyValueSearchOrganizationScoped()` */
  static readonly PropertyValueSearchOrganizationScopedPath = '/property-value/organization/search-api';

  /**
   * Finds organization property values based on search request and organization of user
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueSearchOrganizationScoped()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueSearchOrganizationScoped$Response(params?: PropertyValueSearchOrganizationScoped$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePropertyValueDto>> {
    return propertyValueSearchOrganizationScoped(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds organization property values based on search request and organization of user
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueSearchOrganizationScoped$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueSearchOrganizationScoped(params?: PropertyValueSearchOrganizationScoped$Params|null|undefined, context?: HttpContext): Observable<PagePropertyValueDto> {
    return this.propertyValueSearchOrganizationScoped$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePropertyValueDto>): PagePropertyValueDto => r.body)
    );
  }

  /** Path part for operation `propertyValueSearchGlobalScoped()` */
  static readonly PropertyValueSearchGlobalScopedPath = '/property-value/global/search-api';

  /**
   * Finds global property values based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `propertyValueSearchGlobalScoped()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueSearchGlobalScoped$Response(params?: PropertyValueSearchGlobalScoped$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PagePropertyValueDto>> {
    return propertyValueSearchGlobalScoped(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds global property values based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `propertyValueSearchGlobalScoped$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  propertyValueSearchGlobalScoped(params?: PropertyValueSearchGlobalScoped$Params|null|undefined, context?: HttpContext): Observable<PagePropertyValueDto> {
    return this.propertyValueSearchGlobalScoped$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePropertyValueDto>): PagePropertyValueDto => r.body)
    );
  }

}
