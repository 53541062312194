/* tslint:disable */
/* eslint-disable */

/**
 * File form
 */
export enum FileForm {
  DIGITAL = 'DIGITAL',
  ANALOG = 'ANALOG',
  HYBRID = 'HYBRID'
}
