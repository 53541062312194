<div [formGroup]="specificFieldsForm" [waiting]="(loadingService.isLoading$(this) | async)">

  <div class="row grow gap-48 px-8">
    <div class="col grow">
      <icz-section label="Kde se zobrazí na úřední desce">
        <div class="row">
          <icz-form-autocomplete
            class="grow"
            formControlName="dispatchOfficeDistributionNodeId"
            label="Úřední deska"
            (valueChange)="nodeChanged($event)"
            [options]="officeDeskDistributionNodeOptions"
          ></icz-form-autocomplete>
        </div>
        <div class="row">
          <icz-form-autocomplete
            class="grow"
            formControlName="officeDeskCategoryId"
            label="Kategorie úřední desky"
            (valueChange)="categoryChanged($event)"
            [options]="officeDeskCategoryOptions"
          ></icz-form-autocomplete>
        </div>
        <div class="row">
          <icz-form-autocomplete
            class="grow"
            formControlName="officeDeskRegionId"
            label="Oblast úřední desky"
            [options]="officeDeskRegionOptions"
          ></icz-form-autocomplete>
        </div>
      </icz-section>
      <icz-section label="Popis vyvěšení">
        <div class="row">
          <icz-form-field
            class="grow"
            formControlName="label"
            label="Název"
          ></icz-form-field>
        </div>
        <div class="row">
          <icz-form-field
            class="grow"
            formControlName="refNumber"
            label="Vztahuje se k číslu jednacímu"
          ></icz-form-field>
        </div>
        <div [waiting]="loadingService.isLoading(this, 'originator')">
          <div class="row">
            <icz-form-field
              class="grow"
              formControlName="originatorName"
              label="Původce jméno"
            ></icz-form-field>
          </div>
          <div class="row">
            <icz-form-field
              class="grow"
              formControlName="originatorAddress"
              label="Původce adresa"
            ></icz-form-field>
          </div>
        </div>
      </icz-section>
    </div>

    <div class="col grow">
      <icz-section label="Termíny vyvěšení">
        <div class="row">
          <icz-date-picker
            class="grow"
            formControlName="toPostFrom"
            [selectableDates]="presentOrFutureDateValidator"
            label="Vyvěsit od"
          ></icz-date-picker>
          <icz-form-field
            class="grow"
            type="integer"
            formControlName="postingDuration"
            label="Vyvěsit dní"
          ></icz-form-field>
          <icz-date-picker
            class="grow"
            formControlName="toPostTo"
            [selectableDates]="presentOrFutureDateValidator"
            label="Vyvěsit do"
          ></icz-date-picker>
        </div>
      </icz-section>
      <icz-section label="Způsob doručení a doručenka">
        <div class="row">
          <icz-readonly-field
            [value]="deliveryTypeId"
            class="grow"
            label="Způsob doručení"
            [options]="deliveryTypeOptions"
          ></icz-readonly-field>
        </div>
        <div class="row">
          <icz-delivery-service-combination-selector
            class="mb-2 grow"
            [form]="specificFieldsForm"
            controlName="deliveryServiceCombinationId"
            [deliveryTypeId]="deliveryTypeId"
          ></icz-delivery-service-combination-selector>
        </div>
      </icz-section>
      <icz-section label="Poznámka">
        <icz-form-field
          formControlName="note"
          label="Poznámka k vyvěšení"
          [autoSizeMax]="5"
        ></icz-form-field>
      </icz-section>
    </div>

  </div>
</div>
