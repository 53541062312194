/* tslint:disable */
/* eslint-disable */

/**
 * Envelope permission type
 */
export enum EnvelopePermissionType {
  READ = 'READ',
  EDIT = 'EDIT',
  NO_PERMISSION = 'NO_PERMISSION'
}
