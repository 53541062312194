import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {EntityType} from '|api/commons';
import {
  TableToolbarPopupComponent
} from '../../../../table/table-toolbar/table-toolbar-popup/table-toolbar-popup.component';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {Option} from '../../../../../model';
import {CirculationSearchService} from '../../../../../services/circulation-search.service';
import {FilterOperator, FilterParam} from '../../../../../services/search-api.service';
import {isDocumentEntity} from '../../../shared-document.utils';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';

@Component({
  selector: 'icz-issd-activity-state-icon',
  templateUrl: './issd-entity-activity-icon.component.html',
  styleUrls: ['./issd-entity-activity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssdEntityActivityIconComponent {

  protected loadingService = inject(LoadingIndicatorService);
  private circulationSearchService = inject(CirculationSearchService);
  private cd = inject(ChangeDetectorRef);

  @Input({required: true})
  entityId: Nullable<number>;
  @Input({required: true})
  entityType: Nullable<EntityType>;
  @Input()
  issdAppsOptions: Option[] = [];

  circulationTask: Nullable<ExtendedCirculationTaskDto>;
  activityHeading = '';
  taskState = '';

  taskIconClicked($event: MouseEvent, tasksPopup: TableToolbarPopupComponent) {
    tasksPopup.toggle();
    $event.stopPropagation();
    const filterParams: FilterParam[] = [];

    if (isDocumentEntity(this.entityType)) {
      filterParams.push({
        fieldName: 'documentId',
        operator: FilterOperator.equals,
        value: String(this.entityId),
      });
    } else {
      filterParams.push({
        fieldName: 'fileId',
        operator: FilterOperator.equals,
        value: String(this.entityId),
      });
    }

    this.loadingService.doLoading(this.circulationSearchService.findTasksGlobally({
      page: 0,
      size: 1,
      sort: [],
      filter: filterParams,
    }), this).subscribe(page => {
      if (page.content.length === 1) {
        this.circulationTask = page.content[0];
        this.activityHeading = 'en.circulationActivityType.' + this.circulationTask!.activityType;
        this.taskState = 'en.circulationTaskState.' + this.circulationTask!.taskState;
        this.cd.detectChanges();
      }
    });
  }

}
