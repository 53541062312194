<icz-validation-errors-list
  [control]="control"
>
  <icz-form-field
    type="decimal"
    [decimalPlaceCount]="2"
    [label]="label"
    [value]="moneyValue"
    [fieldDisabled]="!availableCurrencies || fieldDisabled"
    [showValidationStatus]="false"
    (valueChange)="_valueChanged($event)"
    (blur)="blur.emit()"
  >
    <span suffix>{{ currency?.symbol }}</span>
  </icz-form-field>
</icz-validation-errors-list>
