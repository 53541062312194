/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DeliveryServiceGroupDto } from '../models/delivery-service-group-dto';
import { deliveryServiceGroupFindAll } from '../fn/delivery-service-group/delivery-service-group-find-all';
import { DeliveryServiceGroupFindAll$Params } from '../fn/delivery-service-group/delivery-service-group-find-all';
import { deliveryServiceGroupFindById } from '../fn/delivery-service-group/delivery-service-group-find-by-id';
import { DeliveryServiceGroupFindById$Params } from '../fn/delivery-service-group/delivery-service-group-find-by-id';
import { deliveryServiceGroupSearch } from '../fn/delivery-service-group/delivery-service-group-search';
import { DeliveryServiceGroupSearch$Params } from '../fn/delivery-service-group/delivery-service-group-search';
import { PageDeliveryServiceGroupDto } from '../models/page-delivery-service-group-dto';


/**
 * Groups controller
 */
@Injectable({ providedIn: 'root' })
export class ApiDeliveryServiceGroupService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliveryServiceGroupFindAll()` */
  static readonly DeliveryServiceGroupFindAllPath = '/codebook/delivery-service-group';

  /**
   * Returns all the groups
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceGroupFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceGroupFindAll$Response(params?: DeliveryServiceGroupFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryServiceGroupDto>>> {
    return deliveryServiceGroupFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the groups
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceGroupFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceGroupFindAll(params?: DeliveryServiceGroupFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DeliveryServiceGroupDto>> {
    return this.deliveryServiceGroupFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryServiceGroupDto>>): Array<DeliveryServiceGroupDto> => r.body)
    );
  }

  /** Path part for operation `deliveryServiceGroupFindById()` */
  static readonly DeliveryServiceGroupFindByIdPath = '/codebook/delivery-service-group/{id}';

  /**
   * Finds the group by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceGroupFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceGroupFindById$Response(params: DeliveryServiceGroupFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceGroupDto>> {
    return deliveryServiceGroupFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the group by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceGroupFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceGroupFindById(params: DeliveryServiceGroupFindById$Params, context?: HttpContext): Observable<DeliveryServiceGroupDto> {
    return this.deliveryServiceGroupFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceGroupDto>): DeliveryServiceGroupDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceGroupSearch()` */
  static readonly DeliveryServiceGroupSearchPath = '/codebook/delivery-service-group/search-api';

  /**
   * Returns groups based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceGroupSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceGroupSearch$Response(params?: DeliveryServiceGroupSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceGroupDto>> {
    return deliveryServiceGroupSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns groups based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceGroupSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceGroupSearch(params?: DeliveryServiceGroupSearch$Params|null|undefined, context?: HttpContext): Observable<PageDeliveryServiceGroupDto> {
    return this.deliveryServiceGroupSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceGroupDto>): PageDeliveryServiceGroupDto => r.body)
    );
  }

}
