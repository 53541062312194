<div class="grow" [waiting]="loadingIndicatorService.isLoading$(this, SubjectLoaderIds.SEARCHING) | async">
  <ng-content></ng-content>

  @if (!isAnonymousSender) {
    <div>
      @if (oneSubjectFound || manySubjectsFound) {
        <div class="row mb-16">
          <icz-label label="Nenašli jste co jste hledali?" ></icz-label>
          <span class="underline cursor-pointer" (click)="overrideToCreateSubject()">{{'Založit subjekt' | translate}}</span>
        </div>
      }
      @if (showCreateForm) {
        <div>
          @if (lastFindMode === SubjectRecordFindMode.INTERNAL) {
            <icz-alert label="Subjekt nebyl v rejstříku nalezen. Doporučujeme ho dohledat také v ISDS."
                       severity="info">
              <div extraContent>
                <icz-button (onAction)="searchSubjects(SubjectRecordFindMode.ISDS)" primary label="Vyhledat v ISDS"></icz-button>
              </div>
            </icz-alert>
          } @else {
            <icz-alert label="Subjekt nebyl nalezen. Odesílatel bude nově založen."
                       severity="info">
            </icz-alert>
          }
          @if (showCreateForm) {
            <icz-add-subject-step-create
              class="mt-16"
              [useCustomFormButtons]="false"
              [form]="createForm"
              [componentMode]="SubjectCreateComponentMode.CREATE_ESSENTIAL"
              [enforceAddressValidator]="enforceAddressValidator"
              [allowEmitCreateResult]="true"
              (blockingAction)="blockingAction.emit($event)"
              (result)="createFormResult($event)"
              [dataForCreate]="subjectStepCreateData">
            </icz-add-subject-step-create>
          }
        </div>
      }
      @if (showSearchForm) {
        <icz-section label="Vyhledat odesílatele">
          <icz-subject-search-form [form]="searchForm" [singleColumnDisplay]="true" (searchByKeys)="searchSubjects(SubjectRecordFindMode.INTERNAL_ISDS)"
            [wrapInSections]="false"
            [showAddressInvalidAlert]="true"
            (blockingAction)="blockingAction.emit($event)">
          </icz-subject-search-form>
          <div class="row">
            <icz-button svgIcon="search" size="large" label="Vyhledat subjekt" class="grow" primary
                        (onAction)="searchSubjects(SubjectRecordFindMode.INTERNAL_ISDS)"
                        [disabled]="!searchForm.valid || isSearchFormEmpty"
            ></icz-button>
            <icz-button svgIcon="search" size="large" label="Vyhledat v rejstříku" class="grow"
                        (onAction)="searchSubjects(SubjectRecordFindMode.INTERNAL)"
                        [disabled]="!searchForm.valid || isSearchFormEmpty"
            ></icz-button>
          </div>

          @if (iszrAgendasForCurrentFunctionalPosition.length) {
            <icz-button
              size="large" label="Ztotožnit" class="mt-8"
              (onAction)="identifySubjectFromSearchForm()"
              [disabled]="!searchForm.valid || isSearchFormEmpty || isUserNotPermittedForIszrOperations"
            ></icz-button>
          }
        </icz-section>
      }
      @if (oneExternalSubjectFound) {
        <icz-alert severity="success"
          class="mb-16"
          label="Odesílatel byl nalezen v externím systému a bude nově založen v interním rejstříku.">
        </icz-alert>
      }
      @if (oneSubjectFound) {
        <div>
          <icz-subject-detail-card
            [areAddressesReadonly]="!withAddressSelection"
            [subject]="searchedSubjects[0]"
            (subjectAddressSelected)="subjectAddressSelected($event)"
            (subjectRemoved)="removeAsSender()">
          </icz-subject-detail-card>
        </div>
      }
    </div>
  }

  <icz-section [formGroup]="anonymousSenderFormGroup" label="Anonymní odesílatel">
    <icz-checkbox
      class="mb-16"
      formControlName="anonymousSender"
      label="Anonymní odesílatel"
    ></icz-checkbox>
  </icz-section>
</div>
