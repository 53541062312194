/* tslint:disable */
/* eslint-disable */

/**
 * Subject find mode
 */
export enum SubjectRecordFindMode {
  INTERNAL = 'INTERNAL',
  ISDS = 'ISDS',
  INTERNAL_ISDS = 'INTERNAL_ISDS',
  SKIP_ISDS_IF_FOUND_INTERNAL = 'SKIP_ISDS_IF_FOUND_INTERNAL'
}
