<mat-expansion-panel
  [expanded]="expanded" [disabled]="alwaysOpened" [hideToggle]="!boxed || alwaysOpened"
  [class.hide-box]="!boxed" (opened)="onOpened()" (closed)="onClosed()">

  @if (label) {
    <mat-expansion-panel-header
      tabindex="-1"
      [collapsedHeight]="headerHeight + 'px'"
      [expandedHeight]="headerHeight + 'px'"
      (click)="click()"
      >
      <ng-content select="[sectionHeaderBeforeLabel]"></ng-content>
      @if (svgIcon) {
        <icz-icon [svgIcon]="svgIcon" class="mr-8" [size]="svgIconSize!"></icz-icon>
      }
      <div class="mr-16 label whitespace-pre" [class.label-doc]="boxed">{{label | translate}}</div>
      <ng-content select="[sectionHeader]"></ng-content>
      <div class="rest">
        <div class="grow"></div>
        <ng-content select="[sectionHeaderAppendix]"></ng-content>
        @if (showExpansionHint) {
          <span>
            {{ (expanded ? 'sbalit sekci' : 'rozbalit sekci') | translate }}
          </span>
        }
      </div>
    </mat-expansion-panel-header>
  }

  <div [class.pt-16]="!label"
    [class.expansion-content]="boxed"
    [style.max-height]="maxContentHeight"
    [style.overflow-y]="maxContentHeight === 'auto' ? 'none' : 'auto'">
    <ng-content></ng-content>
  </div>

</mat-expansion-panel>
