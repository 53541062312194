@if (paginator && isCollectionPaginable) {
  <div class="button-panel">
    <icz-button svgIcon="first_page" class="mr-4" [disabled]="!paginator.hasPreviousPage()" (onAction)="paginator.firstPage()"></icz-button>
    <icz-button svgIcon="chevron_left" class="mr-4" [disabled]="!paginator.hasPreviousPage()" (onAction)="paginator.previousPage()"></icz-button>
    @for (availablePage of availablePages; track availablePage) {
      <icz-button class="mr-4" [class.active-page]="currentPageNumber === availablePage" [label]="'' + availablePage" (onAction)="setPage(availablePage)"></icz-button>
    }
    <icz-button svgIcon="chevron_right" [disabled]="!paginator.hasNextPage()" (onAction)="paginator.nextPage()"></icz-button>
  </div>
}
<mat-paginator class="paginator" [pageIndex]="pageIndex" [length]="length" [pageSize]="pageSize" [showFirstLastButtons]="false" [hidePageSize]="true"></mat-paginator>
