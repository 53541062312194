@if (digitalComponent) {
  <div class="gap-32 row grow drag-boundary" [waiting]="loadingService.isLoading(this)">
    <div class="col manual-signature-config-col">
      <icz-label
        [hasNormalWhitespace]="true"
        class="card-drag-challenge"
        label="Umístěte podpis přetažením na vhodné místo v dokumentu"
      ></icz-label>
      <!-- TODO: (RB) Nothing is displayed in the card for IDT file cert keystore provider -->
      <icz-card
        #card
        class="signature-card"
        [class.invisible]="(translate.x || translate.y) && loadingService.isLoading(this)"
        (cdkDragEnded)="handleDragDrop()"
        [cdkDragFreeDragPosition]="{x: translate.x!, y: translate.y!}"
        cdkDrag
        cdkDragBoundary=".drag-boundary"
        >
        <!--        Note that Backend will not translate strings inside the signature, stays in czech-->
        <div class="p-4 signature-content">
          <div>{{ 'Dokument je podepsán elektronickým podpisem' }}</div>
          <div><div class="signature-card-left">{{ 'Podepisující' }}:</div> <span class="font-bolder">{{ certSubject }}</span> </div>
          <div><div class="signature-card-left">{{ 'Organizace, Oj' }}:</div> <span class="font-bolder">{{ certOrg }}</span> </div>
          <div><div class="signature-card-left">{{ 'Sériové č. cert.' }}:</div> {{ certificate?.serialNumber }} </div>
          <div><div class="signature-card-left">{{ 'Vydavatel cert.' }}:</div> {{ certIssuer }} </div>
          <div><div class="signature-card-left">{{ 'Datum a čas' }}:</div> Zde bude doplněn čas podpisu </div>
          <div [class.invisible]="!formReason"> <div class="signature-card-left">{{ 'Důvod' }}:</div> {{ formReason }} </div>
          <div [class.invisible]="!formPlace">  <div class="signature-card-left">{{ 'Místo' }}:</div> {{ formPlace }} </div>
        </div>
      </icz-card>
      @if (pdfLoadedAndRendered && hasCardBeenDropped && hasCardValidPosition) {
        <icz-status-card
          class="mb-16"
          status="success"
          label="Podpis je umístěn do dokumentu"
        ></icz-status-card>
      }
      @if (pdfLoadedAndRendered && hasCardBeenDropped && !hasCardValidPosition) {
        <icz-status-card
          class="mb-16"
          status="error"
          label="Podpis byl umístěn mimo dokument nebo příliš blízko okraji stránky. Prosím opravte umístění podpisu."
        ></icz-status-card>
      }
      @if (!pdfLoadedAndRendered || !hasCardBeenDropped) {
        <div
          class="card-placeholder"
        ></div>
      }
      <icz-section label="Důvod a místo podpisu">
        <form [formGroup]="signatureReasonPlaceForm">
          <icz-form-field formControlName="reason" label="Důvod podpisu"></icz-form-field>
          <icz-form-field formControlName="place" label="Místo podpisu"></icz-form-field>
          <icz-form-field formControlName="pageNumber" label="Strana podpisu" [fieldDisabled]="true"></icz-form-field>
        </form>
      </icz-section>
    </div>
    <div class="row grow">
      @if (pdfSource$ | async; as pdfSource) {
        <pdf-viewer
          class="pdf-card w-full"
          [(page)]="currentPageNumber"
          [zoom]="zoom"
          (after-load-complete)="afterPdfLoad($event)"
          [src]="pdfSource"
        ></pdf-viewer>
      }
    </div>
  </div>
}
