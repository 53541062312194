<icz-wizard
  [waiting]="loadingService.isLoading$(this) | async"
  [hideNextButton]="true"
  >
  <ng-template
    [iczWizardStep]="ManualDeliveryResultDialogStep.DELIVERY_RESULT_SPECIFICATION"
    label="Výsledek doručení"
    [isValid]="isDeliveryResultSpecificationStepValid()"
    >
      <div class="row gap-32">
        <div  class="grow">
          <icz-manual-delivery-result-form

            [form]="deliveryResultSpecificationFormGroup"
            (selectedDeliveryResultChanged)="onSelectedDeliveryResultChange($event)"
            [deliveryResultOptions]="deliveryResultOptions"
            [deliveryResults]="deliveryResults"
            [isValidAcknowledgementDate]="isValidAcknowledgementDate"
          ></icz-manual-delivery-result-form>
        </div>
        @if (isConfirmationFormArtifactOrDocument) {
          <div class="grow">
            <form class="p-16" [formGroup]="deliveryResultSpecificationFormGroup">
              <icz-section label="Připojení doručenky" >
                <icz-radio-group>
                  <icz-radio-button
                    class="mb-8"
                    formControlName="deliveryConfirmationForm"
                    [value]="null"
                    label="Bez připojení doručenky"
                  ></icz-radio-button>
                  <icz-radio-button
                    class="mb-8"
                    formControlName="deliveryConfirmationForm"
                    [value]="DeliveryResultConfirmationForm.DOCUMENT"
                    label="Připojit existující dokument"
                  ></icz-radio-button>
                  @if (effectiveDeliveryConfirmationForm === DeliveryResultConfirmationForm.DOCUMENT) {
                    <div class="icz-body-1 py-4 pl-8">
                      {{ 'Dokument můžete připojit v dalším kroku formuláře' | translate }}
                    </div>
                  }
                  <icz-radio-button
                    class="mb-8"
                    formControlName="deliveryConfirmationForm"
                    [value]="DeliveryResultConfirmationForm.ARTIFACT"
                    label="Připojit komponentu"
                  ></icz-radio-button>
                  @if (effectiveDeliveryConfirmationForm === DeliveryResultConfirmationForm.ARTIFACT) {
                    <div class="icz-body-1 pt-4 pl-8">
                      {{ 'Komponentu můžete připojit v dalším kroku formuláře' | translate }}
                    </div>
                  }
                </icz-radio-group>
              </icz-section>
            </form>
          </div>
        }
        @else {
          <div class="grow"></div>
        }
      </div>
  </ng-template>
  <ng-template
    [iczWizardStep]="ManualDeliveryResultDialogStep.DELIVERY_CONFIRMATION_SELECTION"
    [stepHidden]="!shouldAppendDeliveryConfirmation"
    label="Potvrzení o doručení"
    submitLabel="Zapsat a připojit doručenku"
    >
    @if (effectiveDeliveryConfirmationForm === DeliveryResultConfirmationForm.ARTIFACT) {
      <div class="p-16">
        <icz-section label="Potvrzení o doručení">
          <icz-validation-errors-list [array]="deliveryConfirmationFileControl">
            <form [formGroup]="confirmationFileFormGroup">
              <div class="row grow">
                <icz-form-autocomplete label="Typ komponenty" [options]="artefactTypeOptions" formControlName="artefactType" class="grow"></icz-form-autocomplete>
                <div class="grow"></div>
              </div>
              @if (isDigitalArtefactTypeSelected) {
                <div>
                  @if (hasDeliveryConfirmationFileSelected) {
                    <div class="icz-body-1 mb-8">
                      {{ 'Potvrzení o doručení k nahrání' | translate }}:
                    </div>
                  }
                  <icz-file-upload-list
                    #uploader
                    class="grow"
                    [form]="confirmationFileFormGroup"
                    [showFileSize]="false"
                    [isMultiUpload]="false"
                    fileFormArrayName="deliveryConfirmationFile"
                    [fileDeletionHandler]="fileDeletionHandler"
                  ></icz-file-upload-list>
                  @if (!hasDeliveryConfirmationFileSelected) {
                    <div class="icz-body-1 mb-8">
                      {{ 'Nahrát potvrzení o doručení' | translate }}:
                    </div>
                    <icz-button
                      class="inline-block"
                      (onAction)="uploader.openUploadDialog()"
                      label="Vybrat z počítače"
                    ></icz-button>
                  }
                </div>
              }
              @else {
                <icz-form-field label="Název" formControlName="physicalArtefactName"></icz-form-field>
              }
            </form>
          </icz-validation-errors-list>
        </icz-section>
      </div>
    }
    @else {
      <icz-documents-table
        class="grow"
        tableId="manual-delivery-result-document-selector"
        [dataSource]="dataSource"
        [hideTableToolbarButtons]="true"
        [viewType]="DocumentView.DOCUMENT_SELECTOR"
        [openQuickPreview]="false"
        (selectedRowsChanged)="confirmationDocumentChanged($any($event))"
        >
        <icz-label tableTitle label="Vyberte dokument s potvrzením o doručení"></icz-label>
        <icz-label noDataLabel label="Žádné dokumenty"></icz-label>
      </icz-documents-table>
    }
  </ng-template>

</icz-wizard>

<icz-form-buttons>
  <ng-container leftButtons>
    @if (currentWizardStep === ManualDeliveryResultDialogStep.DELIVERY_RESULT_SPECIFICATION) {
      @if (!isAddProofOfDeliveryAction) {
        <icz-button
          iczFormSubmit [disabled]="loadingService.isLoading(this)"
          (onAction)="submit(false)" primary label="Zapsat doručení"
        ></icz-button>
      }
      @if (shouldAppendDeliveryConfirmation) {
        <icz-button iczFormSubmit
          [disabled]="loadingService.isLoading(this)"
          (onAction)="goToDeliveryConfirmationStep()" label="Zapsat a připojit doručenku"
          [primary]="isAddProofOfDeliveryAction"
        ></icz-button>
      }
    }
    @if (currentWizardStep === ManualDeliveryResultDialogStep.DELIVERY_CONFIRMATION_SELECTION) {
      <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)"
        (onAction)="submit(true)" primary
        [label]="isAddProofOfDeliveryAction ? 'Připojit doručenku' : 'Zapsat a připojit doručenku'"
      ></icz-button>
    }
  </ng-container>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
