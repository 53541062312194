<div class="row gap-0 grow header-row mat-header-cell">
  <span class="grow-2 header-row-cell pl-16">{{ 'ID zásilky' | translate }}</span>
  <span class="grow-4 header-row-cell">{{ 'Komu se posílá' | translate }}</span>
  <span class="grow-2 header-row-cell">{{ 'Kam se posílá' | translate }}</span>
  <span class="grow-2 header-row-cell">{{ 'Doručovací služba' | translate }}</span>
  <span class="grow-2 header-row-cell"></span>
</div>
@if (!consignmentCommands?.length) {
  <div class="no-items-text">
    {{ 'Žádné zásilky' | translate }}
  </div>
}
@for (consignmentCommand of consignmentCommands; track consignmentCommand.consignment.id) {
  <div class="faux-table-row row grow px-16 py-8 icz-body-1">
    @if (consignmentCommand.consignment; as consignment) {
      <div class="row items-center grow-2">
        {{ consignment.uid!.uid }}
      </div>
      <div class="grow-4">
        <icz-consignment-table-consignee
          class="block mb-4"
          [consignment]="consignment"
        ></icz-consignment-table-consignee>
        @if (consignmentCommand.code2DMetadata; as code2DMetadata) {
          <div>
            {{ '2D Známka' | translate | uppercase }}:
            {{ 'Hmotnost' | translate }}: {{ code2DMetadata.weight }} g,
            {{ 'Výplatné' | translate }}: {{ code2DMetadata.payoutAmount }} Kč,
            {{ 'Služba' | translate }}: {{ code2DMetadata.postalServiceCode }}
          </div>
        }
      </div>
      <div class="grow-2">
        <icz-consignment-table-address
          [consignment]="consignment"
        ></icz-consignment-table-address>
      </div>
      <div class="grow-2">
        {{ consignment.deliveryServiceCombinationId | findInList:deliveryServiceCombinationOptions }}
      </div>
      <div class="row grow-2">
        <div class="row ml-auto items-center">
          @if (showDeliveryServiceEdit) {
            <icz-button
              label="Upravit službu" class="h-24"
              (onAction)="consignmentDeliveryServiceEditRequested.emit(consignmentCommand)"></icz-button>
          }
          <icz-button
            svgIcon="delete" class="h-24"
            (onAction)="consignmentRemovalRequested.emit(consignmentCommand)"
          ></icz-button>
        </div>
      </div>
    }
  </div>
}
