import {inject, Injectable} from '@angular/core';
import {
  DeliveryServiceAdditionalDto,
  DeliveryServiceAdditionalInfoDto,
  DeliveryServiceBasicDto,
  DeliveryServiceBasicInfoDto,
  DeliveryServiceCombinationDto
} from '|api/codebook';
import {CodebookService} from '../../core/services/codebook.service';

export interface EpaPaCodes {
  basicEpa: string;
  basicPa: string;
  additionalEpas: string[];
  additionalPas: string[];
}

interface EpaPaCacheItem {
  id: number;
  epa: string;
  pa: string;
}

export function getEpaPaCodesFromServices(basicService: Nullable<DeliveryServiceBasicDto | DeliveryServiceBasicInfoDto>,
                                          additionalServices: Nullable<DeliveryServiceAdditionalDto[] | DeliveryServiceAdditionalInfoDto[]>): EpaPaCodes {
  return {
    basicEpa: basicService?.epa ?? '',
    basicPa: basicService?.pa ?? '',
    additionalEpas: additionalServices?.filter(ad => (ad.epa)).map(ad => ad.epa!) ?? [],
    additionalPas: additionalServices?.filter(ad => (ad.pa)).map(ad => ad.pa!) ?? []
  };
}

export function epaStringFromCodes(epaPpaCodes: EpaPaCodes) {
  return `${epaPpaCodes.basicEpa} + ${epaPpaCodes.additionalEpas.join(' + ')}`.replace(/\+ $/, '');
}

export function paStringFromCodes(epaPpaCodes: EpaPaCodes) {
  return `${epaPpaCodes.basicPa} + ${epaPpaCodes.additionalPas.join(' + ')}`.replace(/\+ $/, '');
}

/**
 * Encapsulates algorithms for computing PA/ePA codes
 * for a given delivery service combination.
 */
@Injectable({providedIn: 'root'})
export class EpaPaService {

  private codebookService = inject(CodebookService);

  cachedEpaPpaCodes: EpaPaCacheItem[] = [];

  additionalServices: Nullable<DeliveryServiceAdditionalDto[]>;
  basicServices: Nullable<DeliveryServiceBasicDto[]>;

  constructor() {
    this.codebookService.deliveryServiceAdditionals().subscribe(additionals => {
      this.additionalServices = additionals;
    });
    this.codebookService.deliveryServiceBasics().subscribe(basics => {
      this.basicServices = basics;
    });
  }

  /**
   * Calculates the derived EPA and PA codes from combination of Basic and Additional services.
   * @param row: DeliveryServiceCombinationDto
   * @param toCalc: whether to return EPA calculation or PA calculation
   */
  calculateEpaPa(row: DeliveryServiceCombinationDto, toCalc: 'epa' | 'pa') {
    const cached = this.cachedEpaPpaCodes.find(record => record.id === row.id);

    if (cached) {
      return toCalc === 'epa' ? cached.epa : cached.pa;
    }

    else {
      const basicServiceId = row.basicService?.id ?? undefined;
      const additionalServiceIds = row.additionalServices?.map(as => as.id) ?? undefined;
      if (!this.basicServices || !this.additionalServices || !basicServiceId || !additionalServiceIds) return '';

      const epaPaCodes = getEpaPaCodesFromServices(row.basicService, row.additionalServices);
      let epa = epaStringFromCodes(epaPaCodes);
      let pa = paStringFromCodes(epaPaCodes);
      if (epa.includes('false')) epa = 'Nedostupný'; // Api returns 'false' as value for some codes, it means the EPA/PA are not available.
      if (pa.includes('false')) pa = 'Nedostupný'; // Api returns 'false' as value for some codes, it means the EPA/PA are not available.

      this.cachedEpaPpaCodes.push({id: row.id!, epa, pa});

      return toCalc === 'epa' ? epa : pa;
    }
  }

}
