import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

// TODO(rb) This is substitute for translateParser.interpolate function.
//  The reason is that BE does not return names of placeholders to interpolate. Therefore RegExp is used.
//  If BE would send error parameters like this model: [{[key: string]: string}] we could use translateParser.interpolate.
export function interpolateBackendErrorMessage(translateService: TranslateService, error: Nullable<{errorCode?: string, parameters?: string[]}>): string {
  if (!error?.errorCode) {
    return translateService.instant('Neznámá chyba');
  }
  else {
    const replaceRegex = /{{1,2}([a-z0-9]+)}{1,2}/i;
    const replaceNumberRegex = /{{1,2}([0-9]+)}{1,2}/i;
    const translatedErrorCode = translateService.instant(error.errorCode);

    if (error.parameters?.length) {
      let interpolated = translatedErrorCode;
      // parsing based on existence of {{[0-9]+}} string in error message, if this message contains such a parameter than it number from this parameter is used to index error code parameters
      let relplaceIndexableParam = true;
      while (relplaceIndexableParam) {
        const matchedParam = String(interpolated).match(replaceNumberRegex);
        if (matchedParam && error.parameters[Number(matchedParam[1])]) {
          interpolated = interpolated.replace(matchedParam[0], translateService.instant(error.parameters[Number(matchedParam[1])]));
        } else {
          relplaceIndexableParam = false;
        }
      }

      error.parameters.forEach(param => interpolated = interpolated.replace(replaceRegex, param));

      return interpolated;
    }
    else {
      return translatedErrorCode;
    }
  }
}


@Pipe({
  name: 'interpolateBackendError',
  pure: false, // impure because it should rerender on language changes
})
export class InterpolateBackendErrorPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(code: Nullable<string>, parameters?: Nullable<string[]>): string {
    return interpolateBackendErrorMessage(this.translateService, {errorCode: code!, parameters: parameters!});
  }

}
