import {UntypedFormGroup} from '@angular/forms';
import {Observable, ObservableInput} from 'rxjs';
import {DialogService} from '../core/services/dialog.service';

export interface IFormGroupCheckable {
  formGroupsToCheck: string[];
}

export interface IFormGroupCheckableWithInitialValue extends IFormGroupCheckable {
  initialFormValue: Record<string, any>;
}

type ChangeTypeCallbacks<R> = {
  changesDetected: (afterClosed$: Observable<boolean>) => R;
  changesNotDetected: () => Exclude<R, ObservableInput<boolean>>;
};

export function isIFormGroupCheckableWithInitialValue(object: any): object is IFormGroupCheckableWithInitialValue {
  return 'initialFormValue' in object;
}

export function hasUnsavedChanges(component: IFormGroupCheckable): boolean {
  return component.formGroupsToCheck.some(formGroupKey => {
    // @ts-ignore -- purely runtime characteristic, can't be typed ahead of time
    return (component[formGroupKey] && component[formGroupKey] instanceof UntypedFormGroup && component[formGroupKey].dirty);
  });
}

export function openModalOnChanges<R>(
  component: IFormGroupCheckable,
  dialogService: DialogService,
  callbacks: ChangeTypeCallbacks<R>,
): R {
  const hasChanges = hasUnsavedChanges(component);

  if (hasChanges) {
    return callbacks.changesDetected(dialogService.openUnsavedChangesDialog());
  }
  else {
    return callbacks.changesNotDetected();
  }
}
