import {inject, Injectable} from '@angular/core';
import {combineLatest, forkJoin, isObservable, map, Observable} from 'rxjs';
import {Button} from '../../essentials/button-collection/button-collection.component';
import {ResponsivityService} from '../../essentials/responsivity.service';

enum BooleanPropertyMergeStrategy {
  AND = 'AND',
  OR = 'OR',
}

@Injectable({
  providedIn: 'root'
})
export class ToolbarDataService {

  private responsivityService = inject(ResponsivityService);

  mergeToolbars(...toolbars: Button[][]): Button[];
  mergeToolbars(...toolbars: Observable<Button[]>[]): Observable<Button[]>;

  // Bulk merge of n toolbars into a single toolbar
  // T1 x T2 x ... x Tn - associative from left
  mergeToolbars(...toolbars: (Button[]|Observable<Button[]>)[]): (Button[]|Observable<Button[]>) {
    if (toolbars.length > 0) {
      if (Array.isArray(toolbars[0])) {
        return this.mergeNToolbarsSync(...toolbars as Button[][]);
      }
      else if (isObservable(toolbars[0])) {
        return forkJoin(toolbars).pipe(
          map(resolvedToolbars => this.mergeNToolbarsSync(...resolvedToolbars as Button[][])),
        );
      }
    }

    return [];
  }

  processResponsiveToolbarButtons(toolbar$: Observable<Button[]>): Observable<Button[]> {
    return combineLatest([
      this.responsivityService.invisibleBreakpointsList$,
      toolbar$,
    ]).pipe(
      map(([invisibleBreakpointsList, toolbar]) => {
        let buttonsToHide = this.pickToolbarButtons(toolbar, b => invisibleBreakpointsList.includes(b.visibleTilBreakpoint!));
        const filteredToolbarButtons = this.filterToolbarButtons(toolbar, b => !invisibleBreakpointsList.includes(b.visibleTilBreakpoint!));

        if (buttonsToHide.length) {
          buttonsToHide = buttonsToHide
            .map(b => ({
              ...b,
              visibleTilBreakpoint: undefined,
              icon: undefined,
            }));

          const moreItemsToolbar: Button[] = [
            {
              label: 'Více',
              icon: 'more',
              submenuItems: buttonsToHide,
            }
          ];

          return this.mergeNToolbarsSync(
            filteredToolbarButtons,
            moreItemsToolbar,
          );
        }
        else {
          return toolbar as Button[];
        }
      }),
    );
  }

  // Filters buttons from the toolbar such that remaining buttons will comply to predicate
  filterToolbarButtons<T extends Button>(toolbar: T[], predicate: (b: T) => boolean): T[] {
    const out: T[] = [];
    const filteredButtons = toolbar.filter(predicate);

    for (const button of filteredButtons) {
      out.push({
        ...button,
        submenuItems: button.submenuItems ? this.filterToolbarButtons(button.submenuItems as T[], predicate) : undefined,
      });
    }

    return out;
  }

  // Returns a flatified list of toolbar buttons without submenus that comply to predicate
  pickToolbarButtons<T extends Button>(toolbar: T[], predicate: (b: T) => boolean): T[] {
    const out: T[] = [];
    const filteredButtons = toolbar.filter(predicate);

    for (const button of filteredButtons) {
      out.push({
        ...button,
      });

      if (button.submenuItems) {
        out.push(...this.pickToolbarButtons((button.submenuItems as T[]), predicate));
      }
    }

    return out.filter(b => !b.submenuItems);
  }

  private mergeNToolbarsSync(...toolbars: Button[][]): Button[] {
    if (toolbars.length === 0) {
      return [];
    }
    else if (toolbars.length === 1) {
      return toolbars[0];
    }
    else {
      let out = toolbars[0];

      for (let i = 1; i < toolbars.length; ++i) {
        out = this.mergeTwoToolbars(out, toolbars[i]);
      }

      return out;
    }
  }

  /**
   * Merges two toolbars, such that:
   * - buttons of t1 will be before buttons of t2,
   * - buttons with submenus with the same label and same icon will get merged and in their
   *    submenus, buttons of submenu of t1 will be before buttons of submenu of t2.
   */
  private mergeTwoToolbars(t1: Button[] = [], t2: Button[] = []): Button[] {
    const out: Button[] = [];

    for (const t1Button of t1) {
      let submenusMerged = false;
      let sameButtons = false;

      for (const t2Button of t2) {
        if (this.areSameButtons(t1Button, t2Button)) {
          if (this.haveMergeableSubmenus(t1Button, t2Button)) {
            out.push({
              ...t1Button,
              buttonDisablers: [...t1Button.buttonDisablers ?? [], ...t2Button.buttonDisablers ?? []],
              show: this.mergeNullableBooleanProperties(t1Button.show, t2Button.show, BooleanPropertyMergeStrategy.OR),
              disable: this.mergeNullableBooleanProperties(t1Button.disable, t2Button.disable, BooleanPropertyMergeStrategy.AND),
              isTestingFeature: t1Button.isTestingFeature && t2Button.isTestingFeature,
              submenuItems: this.mergeTwoToolbars(t1Button.submenuItems, t2Button.submenuItems),
            });

            submenusMerged = true;

            break;
          } else if (isNil(t1Button.submenuItems) && isNil(t2Button.submenuItems)) {
            sameButtons = true;
          }
          else {
            throw new Error(`Unable to merge toolbars - there are buttons with duplicate name "${t1Button.label}".`);
          }
        }
      }

      if (!submenusMerged || sameButtons) {
        out.push({...t1Button});
      }
    }

    outerLoop: for (const t2Button of t2) {
      for (const t1Button of t1) {
        // t1Button and t2Button have already been merged - skip them
        if (this.areSameButtons(t1Button, t2Button) && (this.haveMergeableSubmenus(t1Button, t2Button) || (isNil(t1Button.submenuItems) && isNil(t2Button.submenuItems)))) {
          continue outerLoop;
        }
      }

      out.push({...t2Button});
    }

    return out;
  }

  private areSameButtons(b1: Button, b2: Button) {
    return b1.label === b2.label && b1.icon === b2.icon;
  }

  private haveMergeableSubmenus(b1: Button, b2: Button) {
    return b1.submenuItems?.length && b2.submenuItems?.length;
  }

  private mergeNullableBooleanProperties(dp1: Nullable<boolean>, dp2: Nullable<boolean>, mode: BooleanPropertyMergeStrategy) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const strategies: Record<BooleanPropertyMergeStrategy, Function> = {
      [BooleanPropertyMergeStrategy.AND]: (x: boolean, y: boolean) => x && y,
      [BooleanPropertyMergeStrategy.OR]: (x: boolean, y: boolean) => x || y,
    };

    // Optimistic predicate merge strategy:
    // - for disjunction, undefined dynamic property is truthy
    // - for conjunction, undefined dynamic property is falsy
    const propertyValueForMerge1 = dp1 ?? mode === BooleanPropertyMergeStrategy.OR;
    const propertyValueForMerge2 = dp2 ?? mode === BooleanPropertyMergeStrategy.OR;

    return strategies[mode](propertyValueForMerge1, propertyValueForMerge2);
  }

}
