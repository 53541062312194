<div class="popup-size">
  <div class="row header-separator">
    <icz-label label="Informace o věcné skupině" class="grow ml-16"></icz-label>
  </div>
  <icz-tabs [tabs]="tabs">
    <ng-template [iczTab]="EntityClassPopupTab.DESCRIPTION">
      <div class="m-16">
        <icz-readonly-field label="Název věcné skupiny" [value]="getEntityClassProperty('name')"></icz-readonly-field>
        <icz-readonly-field label="Popis" [value]="getEntityClassProperty('description')"></icz-readonly-field>
        <icz-readonly-field label="Skartační režim" [value]="disposalScheduleForPopup"></icz-readonly-field>
        @if (selectedEntityClass) {
          <icz-retention-trigger-type-widget [form]="entityClassPopupForm" [disposalScheduleId]="selectedEntityClass.disposalScheduleId!" [externalRetentionTriggerIds]="[selectedEntityClass.externalRetentionTriggerId!]"></icz-retention-trigger-type-widget>
        }
        <icz-readonly-field label="Bezpečnostní kategorie" [value]="securityCategoryLabel"></icz-readonly-field>
        <div class="row">
          <icz-readonly-field class="grow" label="Spisový plán" [value]="getClassificationSchemeProperty('name')"></icz-readonly-field>
          <icz-readonly-field class="grow" label="Platnost plánu do" [value]="getClassificationSchemeProperty('validTo') | localizedDatetime"></icz-readonly-field>
        </div>
      </div>
    </ng-template>
    <ng-template [iczTab]="EntityClassPopupTab.TREE_VIEW">
      <icz-entity-class-tree-detail [entityClassId]="selectedEntityClassId"></icz-entity-class-tree-detail>
    </ng-template>
  </icz-tabs>
</div>
