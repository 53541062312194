<div iczForm [formGroup]="form">
  <div class="relation-column my-16">
    <icz-form-autocomplete
      label="Typ vazby"
      formControlName="relationType"
      [options]="SubjectObjectRelationOptions"
    ></icz-form-autocomplete>
    <icz-button primary size="large" (onAction)="advanceToSearchStep()" label="Pokračovat"></icz-button>
  </div>
</div>
