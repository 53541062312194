<form [formGroup]="singleAttributeForm">
  @if (wrapInSection) {
    <icz-section
      [label]="label" [boxed]="boxed"
      [showExpansionHint]="showSectionExpansionHint"
      [expanded]="initExpanded" [storeClosedState]="false"
      >
      <div class="pt-8">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
      @if (boxed && isCzechAddress && !isFormDisabled && addressIdentificationStatus !== AddressIdentificationStatus.IDENTIFIED) {
        <icz-button
          sectionHeaderAppendix class="mr-16"
          label="Ověřit adresu" svgIcon="done"
          [stopClickPropagation]="true"
          [disabled]="!isAddressValidForIdentification"
          [iczTooltip]="!isAddressValidForIdentification ? ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP : null"
          background (onAction)="identifyAddress()"
        ></icz-button>
      }
    </icz-section>
  }
  @else {
    <div [waiting]="loadingService.isLoading$(addressIdentificationService) | async">
      @if (isCzechAddress) {
        @if (addressIdentificationStatus === AddressIdentificationStatus.IDENTIFIED || isIszrVerified) {
          <icz-alert
            class="mb-16"
            severity="success"
            [heading]="isIszrVerified ? 'Adresa byla ověřena v registru obyvatel' : 'Adresa je ověřena vůči databázi adres'"
            [label]="addressIdentifiedText"
            [textContext]="addressIdentifiedTextContext"
          ></icz-alert>
        }
        @if (addressIdentificationStatus === AddressIdentificationStatus.NOT_IDENTIFIED) {
          <icz-alert
            class="mb-16"
            severity="warning"
            heading="Tato adresa není v databázi adres"
          ></icz-alert>
        }
      }
      @if (showAddressTypeOptions) {
        <icz-button-toggle-group class="grow"
          [value]="addressFormatType"
          (valueChange)="onAddressComponentFormatType($event, true)"
          [options]="addressFormatTypeOptions">
        </icz-button-toggle-group>
      }
      @if (showSearchBox && isCzechAddress && !isFormDisabled) {
        <div class="row">
          <icz-address-fulltext-selector class="grow" (addressSelected)="addressSelected($event)"></icz-address-fulltext-selector>
        </div>
      }
      <ng-container [formGroup]="valueForm">
        @if (isGenericLineAddress || isPostalBoxAddress) {
          <icz-form-autocomplete
            class="grow"
            label="Země"
            formControlName="country"
            [options]="countriesOptions$ | async"
          >
            @if (isVerified) {
              <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
            }
          </icz-form-autocomplete>
        }
      </ng-container>
      @if (isCzechAddress) {
        <icz-czech-or-slovak-address [form]="form" [isReadonly]="isReadonly" [groupName]="subjectAttributeType ? subjectAttributeType : groupName" [addressFormat]="AddressFormat.CzechAddressDto">
        </icz-czech-or-slovak-address>
      }
      @if (isSlovakAddress) {
        <icz-czech-or-slovak-address [form]="form" [isReadonly]="isReadonly" [groupName]="subjectAttributeType ? subjectAttributeType : groupName" [addressFormat]="AddressFormat.SlovakAddressDto">
        </icz-czech-or-slovak-address>
      }
      @if (isPostalBoxAddress) {
        <div [formGroup]="postalBoxAddressControl">
          <div class="row">
            <icz-form-field formControlName="box" label="ID P.O. Boxu" class="grow" ></icz-form-field>
            <icz-form-field formControlName="postalCode" label="PSČ" class="grow" ></icz-form-field>
          </div>
          <icz-form-field formControlName="postOffice" label="Pošta"></icz-form-field>
        </div>
      }
      @if (isGenericLineAddress) {
        <div [formGroup]="genericLineAddressControl">
          <ng-container formGroupName="addressLines">
            @for (control of addressLinesControl?.controls; track control) {
              <div [formGroupName]="$index">
                <div class="row items-center">
                  <div class="col grow">
                    <icz-form-field formControlName="line" [label]="$index + 1 + '. řádek adresy'">
                      @if (isVerified) {
                        <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
                      }
                    </icz-form-field>
                  </div>
                  <div>
                    @if (!isSearchMode) {
                      <icz-button
                        class="grow"
                        svgIcon="delete"
                        [disabled]="isReadonlyOrVerified"
                        (onAction)="removeAddressLine($event, $index)"
                        iczTooltip="Odebrat řádek adresy"
                      ></icz-button>
                    }
                  </div>
                </div>
              </div>
            }
            @if (!isSearchMode) {
              <icz-button
                class="grow"
                (onAction)="addAddressLine()"
                [disabled]="isReadonlyOrVerified"
                label="Přidat řádek adresy"
              ></icz-button>
            }
          </ng-container>
        </div>
      }
      @if (!isSearchMode) {
        <div class="row">
          <icz-readonly-field class="grow" [value]="null" label="Poznámka k adrese" testingFeature></icz-readonly-field> <!-- note -->
        </div>
      }
      @if (withAddressValidationButton && !boxed && isCzechAddress && addressIdentificationStatus !== AddressIdentificationStatus.IDENTIFIED) {
        <div>
          <icz-button
            [disabled]="!isAddressValidForIdentification"
            [iczTooltip]="!isAddressValidForIdentification ? ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP : null"
            label="Ověřit adresu" (onAction)="identifyAddress()"
          ></icz-button>
        </div>
      }
    </div>
  }

  <ng-template #content>
    <div [waiting]="loadingService.isLoading$(addressIdentificationService) | async">
      @if (isCzechAddress) {
        @if (addressIdentificationStatus === AddressIdentificationStatus.IDENTIFIED || isIszrVerified) {
          <icz-alert
            class="mb-16"
            severity="success"
            [heading]="isIszrVerified ? 'Adresa byla ověřena v registru obyvatel' : 'Adresa je ověřena vůči databázi adres'"
            [label]="addressIdentifiedText"
            [textContext]="addressIdentifiedTextContext"
          ></icz-alert>
        }
        @if (addressIdentificationStatus === AddressIdentificationStatus.NOT_IDENTIFIED) {
          <icz-alert
            class="mb-16"
            severity="warning"
            heading="Tato adresa není v databázi adres"
          ></icz-alert>
        }
      }

      @if (withAddressTypeSelection && showAddressTypeOptions) {
        <icz-button-toggle-group class="grow"
          [value]="addressFormatType"
          (valueChange)="onAddressComponentFormatType($event, true)"
          [options]="addressFormatTypeOptions">
        </icz-button-toggle-group>
      }
      @if (showSearchBox && isCzechAddress && !isFormDisabled) {
        <div class="row">
          <icz-address-fulltext-selector class="grow" (addressSelected)="addressSelected($event)"></icz-address-fulltext-selector>
        </div>
      }

      <ng-container [formGroup]="valueForm">
        @if (isGenericLineAddress || isPostalBoxAddress) {
          <icz-form-autocomplete
            class="grow"
            label="Země"
            formControlName="country"
            [options]="countriesOptions$ | async"
          >
            @if (isVerified) {
              <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
            }
          </icz-form-autocomplete>
        }
      </ng-container>

      @if (isCzechAddress) {
        <icz-czech-or-slovak-address [form]="form" [isReadonly]="isReadonly" [groupName]="subjectAttributeType ? subjectAttributeType : groupName" [addressFormat]="AddressFormat.CzechAddressDto">
        </icz-czech-or-slovak-address>
      }
      @if (isSlovakAddress) {
        <icz-czech-or-slovak-address [form]="form" [isReadonly]="isReadonly" [groupName]="subjectAttributeType ? subjectAttributeType : groupName" [addressFormat]="AddressFormat.SlovakAddressDto">
        </icz-czech-or-slovak-address>
      }

      @if (isPostalBoxAddress) {
        <div [formGroup]="postalBoxAddressControl">
          <div class="row">
            <icz-form-field formControlName="box" label="ID P.O. Boxu" class="grow" ></icz-form-field>
            <icz-form-field formControlName="postalCode" label="PSČ" class="grow" ></icz-form-field>
          </div>
          <icz-form-field formControlName="postOffice" label="Pošta"></icz-form-field>
        </div>
      }

      @if (isGenericLineAddress) {
        <div [formGroup]="genericLineAddressControl">
          <ng-container formGroupName="addressLines">
            @for (control of addressLinesControl?.controls; track control) {
              <div [formGroupName]="$index">
                <div class="row items-center">
                  <div class="col grow">
                    <icz-form-field formControlName="line" [label]="$index + 1 + '. řádek adresy'">
                      @if (isVerified) {
                        <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
                      }
                    </icz-form-field>
                  </div>
                  <div>
                    @if (!isSearchMode) {
                      @if (!$first) {
                        <icz-button
                          class="grow"
                          svgIcon="delete"
                          [disabled]="isReadonlyOrVerified"
                          (onAction)="removeAddressLine($event, $index)"
                          iczTooltip="Odebrat řádek adresy"
                        ></icz-button>
                      }
                    }
                  </div>
                </div>
              </div>
            }
            @if (!isSearchMode) {
              <icz-button
                class="grow"
                (onAction)="addAddressLine()"
                [disabled]="isReadonlyOrVerified"
                label="Přidat řádek adresy"
              ></icz-button>
            }
          </ng-container>
        </div>
      }
      @if (!isSearchMode) {
        <div class="row">
          <icz-readonly-field class="grow" [value]="null" label="Poznámka k adrese" testingFeature></icz-readonly-field> <!-- note -->
        </div>
      }
      @if (withAddressValidationButton && !boxed && isCzechAddress && addressIdentificationStatus !== AddressIdentificationStatus.IDENTIFIED) {
        <div>
          <icz-button
            [disabled]="!isAddressValidForIdentification"
            [iczTooltip]="!isAddressValidForIdentification ? ADDRESS_IDENTIFICATION_UNAVAILABLE_TOOLTIP : null"
            label="Ověřit adresu" (onAction)="identifyAddress()"
          ></icz-button>
        </div>
      }
    </div>
  </ng-template>


</form>
