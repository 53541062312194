import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {DeliveryTypeDto} from '|api/codebook';
import {CodebookService} from '../../../core/services/codebook.service';

@Component({
  selector: 'icz-delivery-type-widget',
  templateUrl: './delivery-type-widget.component.html',
  styleUrls: ['./delivery-type-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeliveryTypeWidgetComponent implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private codebookService = inject(CodebookService);

  @Input({required: true}) deliveryTypeId!: number;

  deliveryType: Nullable<DeliveryTypeDto>;

  get isDeliveryTypeNotNull() {
    return !isNil(this.deliveryType);
  }

  ngOnInit(): void {
    if (!isNil(this.deliveryTypeId)) {
      this.codebookService.deliveryTypes().subscribe(deliveryTypes => {
        this.deliveryType = deliveryTypes.find(dt => dt.id === this.deliveryTypeId);
        this.cd.detectChanges();
      });
    }
  }

}
