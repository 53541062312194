/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { internalNotificationAccept } from '../fn/internal-notification/internal-notification-accept';
import { InternalNotificationAccept$Params } from '../fn/internal-notification/internal-notification-accept';
import { internalNotificationAcceptAll } from '../fn/internal-notification/internal-notification-accept-all';
import { InternalNotificationAcceptAll$Params } from '../fn/internal-notification/internal-notification-accept-all';
import { internalNotificationCount } from '../fn/internal-notification/internal-notification-count';
import { InternalNotificationCount$Params } from '../fn/internal-notification/internal-notification-count';
import { InternalNotificationCountDto } from '../models/internal-notification-count-dto';
import { internalNotificationNotify } from '../fn/internal-notification/internal-notification-notify';
import { InternalNotificationNotify$Params } from '../fn/internal-notification/internal-notification-notify';
import { internalNotificationSearch } from '../fn/internal-notification/internal-notification-search';
import { InternalNotificationSearch$Params } from '../fn/internal-notification/internal-notification-search';
import { internalNotificationSoftDelete } from '../fn/internal-notification/internal-notification-soft-delete';
import { InternalNotificationSoftDelete$Params } from '../fn/internal-notification/internal-notification-soft-delete';
import { internalNotificationUndoAccept } from '../fn/internal-notification/internal-notification-undo-accept';
import { InternalNotificationUndoAccept$Params } from '../fn/internal-notification/internal-notification-undo-accept';
import { PageInternalNotificationDto } from '../models/page-internal-notification-dto';


/**
 * Controller for sending notification
 */
@Injectable({ providedIn: 'root' })
export class ApiInternalNotificationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `internalNotificationUndoAccept()` */
  static readonly InternalNotificationUndoAcceptPath = '/notification/internal-notification/undo-accept';

  /**
   * Undo accept of internal notification.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationUndoAccept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationUndoAccept$Response(params: InternalNotificationUndoAccept$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return internalNotificationUndoAccept(this.http, this.rootUrl, params, context);
  }

  /**
   * Undo accept of internal notification.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationUndoAccept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationUndoAccept(params: InternalNotificationUndoAccept$Params, context?: HttpContext): Observable<void> {
    return this.internalNotificationUndoAccept$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `internalNotificationSoftDelete()` */
  static readonly InternalNotificationSoftDeletePath = '/notification/internal-notification/soft-delete';

  /**
   * Set internal notification to deleted.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationSoftDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationSoftDelete$Response(params: InternalNotificationSoftDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return internalNotificationSoftDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Set internal notification to deleted.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationSoftDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationSoftDelete(params: InternalNotificationSoftDelete$Params, context?: HttpContext): Observable<void> {
    return this.internalNotificationSoftDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `internalNotificationNotify()` */
  static readonly InternalNotificationNotifyPath = '/notification/internal-notification/notify';

  /**
   * Notification create operation
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationNotify()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationNotify$Response(params: InternalNotificationNotify$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return internalNotificationNotify(this.http, this.rootUrl, params, context);
  }

  /**
   * Notification create operation
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationNotify$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationNotify(params: InternalNotificationNotify$Params, context?: HttpContext): Observable<void> {
    return this.internalNotificationNotify$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `internalNotificationAccept()` */
  static readonly InternalNotificationAcceptPath = '/notification/internal-notification/accept';

  /**
   * Accept internal notification.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationAccept()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationAccept$Response(params: InternalNotificationAccept$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return internalNotificationAccept(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept internal notification.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationAccept$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  internalNotificationAccept(params: InternalNotificationAccept$Params, context?: HttpContext): Observable<void> {
    return this.internalNotificationAccept$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `internalNotificationAcceptAll()` */
  static readonly InternalNotificationAcceptAllPath = '/notification/internal-notification/acceptAll';

  /**
   * Accept all internal notification for logged user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationAcceptAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalNotificationAcceptAll$Response(params?: InternalNotificationAcceptAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return internalNotificationAcceptAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Accept all internal notification for logged user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationAcceptAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalNotificationAcceptAll(params?: InternalNotificationAcceptAll$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.internalNotificationAcceptAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `internalNotificationSearch()` */
  static readonly InternalNotificationSearchPath = '/notification/internal-notification/search-api';

  /**
   * Find all internal notification instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalNotificationSearch$Response(params?: InternalNotificationSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageInternalNotificationDto>> {
    return internalNotificationSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all internal notification instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalNotificationSearch(params?: InternalNotificationSearch$Params|null|undefined, context?: HttpContext): Observable<PageInternalNotificationDto> {
    return this.internalNotificationSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageInternalNotificationDto>): PageInternalNotificationDto => r.body)
    );
  }

  /** Path part for operation `internalNotificationCount()` */
  static readonly InternalNotificationCountPath = '/notification/internal-notification/count';

  /**
   * Find all sent internal notifications which are not deleted for current user and functional position.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `internalNotificationCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalNotificationCount$Response(params?: InternalNotificationCount$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<InternalNotificationCountDto>> {
    return internalNotificationCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all sent internal notifications which are not deleted for current user and functional position.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `internalNotificationCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  internalNotificationCount(params?: InternalNotificationCount$Params|null|undefined, context?: HttpContext): Observable<InternalNotificationCountDto> {
    return this.internalNotificationCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<InternalNotificationCountDto>): InternalNotificationCountDto => r.body)
    );
  }

}
