/* tslint:disable */
/* eslint-disable */

/**
 * Cross reference specialization type
 */
export enum CrossReferenceSpecializationType {
  USER_REFERENCE = 'USER_REFERENCE',
  DOCUMENT_SETTLEMENT_BY_RESPONSE = 'DOCUMENT_SETTLEMENT_BY_RESPONSE',
  DOCUMENT_DUPLICATE = 'DOCUMENT_DUPLICATE',
  DOCUMENT_TRANSFER = 'DOCUMENT_TRANSFER',
  INTERNAL_DELIVERY = 'INTERNAL_DELIVERY'
}
