import {DocumentDto, FileDto, ReceivedDocumentDto} from '|api/document';
import {isDocumentObject, isOwnDocumentObject, isReceivedDocumentObject} from '../../shared-document.utils';
import {OperationValidator, ValidatorGuard} from '../../../../services/bulk-operation-validation.service';
import {DocumentForm, DocumentState, FileState} from '|api/commons';
import {CirculationView, DocumentView} from './toolbar-common.utils';
import {SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {FileDetailService} from '../../../../services/file-detail.service';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {CirculationActivityDto} from '|api/flow';

export class DocumentOperationValidators {
  @ValidatorGuard()
  static selectedItemIsNotDocument(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return !isDocumentObject(document) ? {validationMessage: 'Akci lze provést pouze s dokumenty' } : null;
    };
  }

  @ValidatorGuard()
  static selectedItemIsNotReceivedDocument(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return !isReceivedDocumentObject(document) ? {validationMessage: 'Akci lze provést pouze s doručenými dokumenty' } : null;
    };
  }

  @ValidatorGuard()
  static selectedItemIsNotOwnDocument(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return !isOwnDocumentObject(document) ? {validationMessage: 'Akci lze provést pouze s vlastním dokumentem' } : null;
    };
  }

  static isDocumentDeactivated(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.documentState === DocumentState.DEACTIVATED ? {validationMessage: 'Dokument je zrušený'} : null;
    };
  }

  static isDocumentUnopened(): OperationValidator<ReceivedDocumentDto> {
    return (document: ReceivedDocumentDto) => {
      return document.documentState === DocumentState.UNOPENED ? {validationMessage: 'Dokument je neotevřený'} : null;
    };
  }

  static isDocumentNotUnopened(): OperationValidator<ReceivedDocumentDto> {
    return (document: ReceivedDocumentDto) => {
      return document.documentState !== DocumentState.UNOPENED ? {validationMessage: 'Dokument je otevřený'} : null;
    };
  }

  static isDocumentToOwnHands(): OperationValidator<ReceivedDocumentDto> {
    return (document: ReceivedDocumentDto) => {
      return Boolean(document.toOwnHands) ? {validationMessage: 'Dokument je do vlastních rukou'} : null;
    };
  }

  static isDocumentNotToOwnHands(): OperationValidator<ReceivedDocumentDto> {
    return (document: ReceivedDocumentDto) => {
      return !Boolean(document.toOwnHands) ? {validationMessage: 'Dokument není do vlastních rukou'} : null;
    };
  }

  static isDocumentInFile(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return !isNil(document.fileId) ? {validationMessage: 'Dokument již je vložen do spisu'} : null;
    };
  }

  static isDocumentNotInFile(viewType: DocumentView | CirculationView): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      let predicate;
      if (viewType === DocumentView.FILE_CONTENTS) {
        predicate = (document: SearchRecordSourceDocumentFileDto) => !isNil(document.documentRemovedFromGivenFileAt);
      } else {
        predicate = (document: DocumentDto) => !document.fileId;
      }

      return predicate(document) ? {validationMessage: 'Dokument není vložen do žádného spisu'} : null;
    };
  }

  static isParentFileDeactivated(fileDetailService: Nullable<FileDetailService>, fileDto: Nullable<FileDto>): OperationValidator<DocumentDto> {
    return (selection: DocumentDto) => {
      let disable = false;
      if (fileDetailService?.object) {
        disable = fileDetailService.object.fileState === FileState.DEACTIVATED;
      } else if (fileDto) {
        disable = fileDto.fileState === FileState.DEACTIVATED;
      }
      return disable ? { validationMessage: 'Spis, ve kterém je dokument vložen, je zrušený' } : null;
    };
  }

  static isParentFileClosed(fileDetailService: Nullable<FileDetailService>, fileDto: Nullable<FileDto>): OperationValidator<DocumentDto> {
    return (selection: DocumentDto) => {
      let disable = false;
      if (fileDetailService?.object) {
        disable = fileDetailService.object.fileState === FileState.CLOSED;
      } else if (fileDto) {
        disable = fileDto.fileState === FileState.CLOSED;
      }
      return disable ? { validationMessage: 'Spis, ve kterém je dokument vložen, je uzavřen' } : null;
    };
  }

  static isDocumentSettled(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.documentState === DocumentState.SETTLED ?
        {validationMessage: 'Dokument již byl vyřízen'} :
        null;
    };
  }

  static isDocumentNotSettled(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.documentState !== DocumentState.SETTLED ?
        {validationMessage: 'Dokument zatím nebyl vyřízen'} :
        null;
    };
  }

  static isDocumentNotInProgress(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.documentState !== DocumentState.ON_TABLE ?
        {validationMessage: 'Dokument není ve stavu Vyřizovaný'} :
        null;
    };
  }

  static isDocumentInStorageUnit(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.storageUnitId ? {validationMessage: 'Dokument je v ukládací jednotce' } : null;
    };
  }

  static documentIsRegistered(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return !isNil(document.refNumber) ?
        {validationMessage: 'Dokument je zaevidován'} :
        null;
    };
  }

  static documentIsNotRegistered(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return isNil(document.refNumber) ?
        {validationMessage: 'Dokument není v žádné evidenci'} :
        null;
    };
  }

  static documentIsNotAnalog(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.documentForm !== DocumentForm.ANALOG ?
        {validationMessage: 'Dokument není analogový'} :
        null;
    };
  }

  static documentIsNotAnalogOrHybrid(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.documentForm !== DocumentForm.ANALOG && document.documentForm !== DocumentForm.HYBRID ?
        {validationMessage: 'Dokument není analogový ani hybridní'} :
        null;
    };
  }

  static documentHasExecutionDate(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return !isNil(document.executionDate) ?
        {validationMessage: 'Dokument již má právní moc'} :
        null;
    };
  }

  static documentIsInDifferentFilingRegister(filingRegisterId: number): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return document.filingRegisterId !== filingRegisterId ?
        {validationMessage: 'Dokument je zaevidován v jiném registru než první vybraný dokument'} :
        null;
    };
  }

  static isParentFileRunningActivity(documentDetailService: DocumentDetailService, isOnDocumentDetail: boolean): OperationValidator<DocumentDto> {
    return () => {
      let isParentFileRunningActivity = false;

      if (isOnDocumentDetail) {
        isParentFileRunningActivity = !isNil(documentDetailService.parentRunningActivity);
      }

      return isParentFileRunningActivity ?
        {validationMessage: 'Nad spisem, ve kterém je tento dokument vložen, běží oběh' } :
        null;
    };
  }

  static isNotDocumentOwnerOrSupervisor(currentSessionService: CurrentSessionService, organizationalStructureService: OrganizationalStructureService): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      const currentUserFpId = currentSessionService.currentUserFunctionalPosition!.id;

      const predicate = (document: DocumentDto) => !(document.ownerFunctionalPositionId === currentUserFpId || organizationalStructureService.getParentsFPs(document.ownerFunctionalPositionId).includes(currentUserFpId!));

      return predicate(document) ? {validationMessage: 'Nejste zpracovatelem dokumentu ani jeho nadřízeným'} : null;
    };
  }

  static documentHandoverToExternalApplicationPreconditionNotMet(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      const predicate = (document: DocumentDto) => (
        document.documentState && (document.documentState !== DocumentState.SETTLED && document.documentState !== DocumentState.ON_TABLE));

      return predicate(document) ? {validationMessage: 'Dokument není ve stavu Vyřízováné nebo Vyřizen'} : null;
    };
  }

  static documentIsFileInitialDocument(initialDocumentId: Nullable<number>): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return (initialDocumentId && initialDocumentId === document.id) ?
        {validationMessage: 'Dokument je zakládající dokument spisu.'} :
        null;
    };
  }

  static documentIsInExternalApplication(): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return (!isNil(document.issdId)) ?
        {validationMessage: 'Dokument je v správě externí aplikace.'} :
        null;
    };
  }

  static documentInCirculation(runningActivity: Nullable<CirculationActivityDto>): OperationValidator<DocumentDto> {
    return (document: DocumentDto) => {
      return (runningActivity && runningActivity.documentId === document.id) ?  {validationMessage: 'Dokument je v oběhu.'}  : null;
    };
  }
}
