@if (message) {
  <div class="row">
    <div class="grow-2 subject-and-body">
      <icz-form-field
        label="Předmět"
        [fieldDisabled]="true"
        [value]="message.subject"
      ></icz-form-field>
      <icz-readonly-field value="" [withWhiteBackground]="true">
        @if (emailBodyHtml) {
          <icz-html-outlet
            [html]="emailBodyHtml" class="email-body-preview"
          ></icz-html-outlet>
        }
        @if (emailBodyRtf) {
          <icz-rtf-outlet
            [rtfContents]="emailBodyRtf" class="email-body-preview"
          ></icz-rtf-outlet>
        }
      </icz-readonly-field>
      <icz-digital-message-signature-status
        [message]="message"
      ></icz-digital-message-signature-status>
    </div>
    <icz-email-metadata-preview
      class="grow-1"
      [message]="message"
    ></icz-email-metadata-preview>
  </div>
}
