<ng-container [formGroup]="form">
  <icz-form-autocomplete
    [formControlName]="controlName"
    label="Doručovací služba"
    [options]="deliveryServiceCombinationOptions"
    [customOptionTemplate]="deliveryServiceCombinationTemplate"
    [customSelectedOptionTemplate]="deliveryServiceCombinationTemplate"
    >
    <ng-template #deliveryServiceCombinationTemplate let-context>
      <icz-form-autocomplete-list-text-item
        class="w-full"
        [option]="context.option"
        >
        <icz-icon
          afterItemLabel svgIcon="info" size="small"
          (mouseenter.stop)="showDeliveryServiceCombinationInfo(context.option.data.deliveryServiceCombination)"
          (mouseleave.stop)="hideDeliveryServiceCombinationInfo()"
          cdkOverlayOrigin
          #deliveryServiceInfoOverlayOrigin="cdkOverlayOrigin"
        ></icz-icon>
      </icz-form-autocomplete-list-text-item>

      <icz-popover
        [isOpen]="hoveredDeliveryServiceCombination === context.option.data.deliveryServiceCombination"
        [withBackdrop]="false"
        [overlayOrigin]="deliveryServiceInfoOverlayOrigin"
        (onClose)="hideDeliveryServiceCombinationInfo()"
        >
        @if (hoveredDeliveryServiceCombination === context.option.data.deliveryServiceCombination) {
          <icz-delivery-service-combination-info
            [deliveryServiceCombination]="hoveredDeliveryServiceCombination"
          ></icz-delivery-service-combination-info>
        }
      </icz-popover>
    </ng-template>
  </icz-form-autocomplete>
</ng-container>
