/* tslint:disable */
/* eslint-disable */

/**
 * Represents possible flow types of circulation activities
 */
export enum CirculationActivityFlowType {
  SERIAL = 'SERIAL',
  PARALLEL = 'PARALLEL',
  SELECTIVE = 'SELECTIVE'
}
