import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {SubjectRecordClassification, SubjectRecordFindDto} from '|api/commons';
import {createAddressFormGroup} from '../address/address-utils';
import {SearchOnlyAttribute, SubjectIdentifierType, SubjectsService,} from '../../../../services/subjects.service';
import {IczFormControl, IczFormGroup} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {IczDateValidators} from '../../../form-elements/validators/icz-validators/icz-date-validators';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {SubjectRecordClassificationWithAll} from '../../model/subjects.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SubjectAttributeType} from '../../model/subject-attribute-type';

export function getSearchSubjectFormGroup(initialValue?: Nullable<SubjectRecordFindDto>, withImportantFieldFlags = true) {
  const form = new IczFormGroup({
    classification: new IczFormControl<Nullable<SubjectRecordClassificationWithAll>>({value: 'ALL', disabled: true}, []),
    identityType: new IczFormControl<Nullable<SubjectIdentifierType>>(SubjectIdentifierType.DATA_BOX, []),
    [SearchOnlyAttribute.DATA_BOX]: new IczFormControl<Nullable<string>>(null, [IczValidators.isValidDataboxIdentifier()]),
    [SearchOnlyAttribute.ADDRESS]: createAddressFormGroup(),
    [SearchOnlyAttribute.EMAIL]: new IczFormControl<Nullable<string>>({value: null, important: withImportantFieldFlags, disabled: false}, []),
    [SubjectAttributeType.IDENTITY_CARD_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.DRIVING_LICENCE_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.PASSPORT_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.CLIENT_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.FIRST_NAME]: new IczFormControl<Nullable<string>>({value: null, important: withImportantFieldFlags, disabled: false}, [IczValidators.minLength(2)]),
    [SubjectAttributeType.SURNAME]: new IczFormControl<Nullable<string>>({value: null, important: withImportantFieldFlags, disabled: false}, [IczValidators.minLength(2)]),
    [SubjectAttributeType.BIRTH_DATE]: new IczFormControl<Nullable<string>>({value: null, important: withImportantFieldFlags, disabled: false}, []),
    [SubjectAttributeType.BUSINESS_NAME]: new IczFormControl<Nullable<string>>({value: null, important: withImportantFieldFlags, disabled: false}, [IczValidators.minLength(3)]),
    [SubjectAttributeType.CID]: new IczFormControl<Nullable<string>>({value: null, important: withImportantFieldFlags, disabled: false}, [IczValidators.isCID()]),
  });
  if (initialValue) {
    form.patchValue(initialValue!);
  }
  return form;
}

export type SubjectSearchFormData = ReturnType<typeof getSearchSubjectFormGroup>['value'];

@Component({
  selector: 'icz-subject-search-form',
  templateUrl: './subject-search-form.component.html',
  styleUrls: ['./subject-search-form.component.scss'],
  providers: [SubjectsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectSearchFormComponent implements OnInit {

  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private subjectsService = inject(SubjectsService);

  @Input({required: true}) form = getSearchSubjectFormGroup();
  @Input() singleColumnDisplay = false;
  @Input() showSearchAppendix = false;
  @Input() showAddressInvalidAlert = false;
  @Input() wrapInSections = true;
  @Output() searchByKeys =  new EventEmitter<void>();
  @Output() blockingAction = new EventEmitter<boolean>();
  @Input() set expanded(expanded) {
    this._expanded = expanded;
    this.changeDetectorRef.detectChanges();
  }

  get expanded() {
    return this._expanded;
  }

  searchCriteriaValue: SubjectSearchFormData = {};

  private _expanded = true;

  readonly birthDateValidator = IczDateValidators.birthDateValidator;
  readonly SubjectAttributeType = SubjectAttributeType;
  readonly SearchOnlyAttribute = SearchOnlyAttribute;

  get classification(): SubjectRecordClassification {
    return SubjectTemplateUtils.classification(this.form);
  }

  get addressForm(): IczFormGroup {
    return this.form.get(SearchOnlyAttribute.ADDRESS) as IczFormGroup;
  }

  emitSearch() {
    this.searchByKeys.emit();
  }

  get isFormEmpty() {
    return this.subjectsService.isSearchFormEmpty(this.form);
  }

  onInputFieldKeydown($event: KeyboardEvent): void {
    const keyPressed = $event.key;

    if (keyPressed === 'Enter') {
      $event.stopPropagation();
      this.emitSearchIfFormNonEmpty();
    }
  }

  ngOnInit() {
    this.form.get('identityType')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedQuickKey => {
      Object.values(SubjectIdentifierType).forEach(quickKey => {
        if (selectedQuickKey !== quickKey) {
          this.form.get(quickKey as unknown as SubjectAttributeType)!.setValue(null, {emitEvent: false});
        }
      });
    });
    this.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.searchCriteriaValue = this.form.getRawValue();
    });
  }

  emitSearchIfFormNonEmpty() {
    if (!this.isFormEmpty) this.emitSearch();
  }

}
