/* tslint:disable */
/* eslint-disable */

/**
 * Digital component template parameter group
 */
export enum DigitalComponentTemplateParameterGroup {
  DOCUMENT = 'DOCUMENT',
  OWNER = 'OWNER',
  USER = 'USER',
  ORG_STRUCTURE = 'ORG_STRUCTURE',
  CONSIGNMENT_SUBJECT = 'CONSIGNMENT_SUBJECT',
  ATTACHMENTS = 'ATTACHMENTS',
  ALL_CONSIGNMENTS = 'ALL_CONSIGNMENTS',
  RESOLUTION_BY_DOCUMENT = 'RESOLUTION_BY_DOCUMENT'
}
