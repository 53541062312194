import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {DeferredColumnList, isListColumnDefinition, MIN_SEARCH_TERM_LENGTH, TableToolbarConfig} from '../table.models';
import {TableToolbarPopupComponent} from './table-toolbar-popup/table-toolbar-popup.component';
import {FormFieldComponent} from '../../form-elements/form-field/form-field.component';
import {ToggleButtonComponent} from '../../essentials/toggle-button/toggle-button.component';
import {FilterItem, FilterType} from '../filter.types';
import {TableToolbarService} from './table-toolbar.service';
import {DetachingService} from '../../essentials/detaching.service';
import {PrimitiveControlValueType} from '../../form-elements/form-field';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TableColumnsData} from '../table-columns-data';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {FILTER_PARAM_NAME} from '../../../services/search-params-serialization.service';
import {SubjectNamePipe} from '../../shared-business-components/subjects/subject-name.pipe';
import {LocalizedDatePipe} from '../../essentials/date.pipe';
import {LocalizedDatetimePipe} from '../../essentials/datetime.pipe';
import {EmpowermentPipe} from '../../essentials/empowerment.pipe';
import {AddressPipe} from '../../form-elements/address.pipe';
import {FilterNameService} from '../filter-name.service';
import {FilterTreeOperator, isFilterTreeEmpty, isSimpleQueryFilterTree} from '../filter-trees.utils';
import {StatisticsDimension} from '|api/elastic';

export function getDefaultTableToolbarConfig(): TableToolbarConfig {
  return {
    showFilter: true,
    showReload: true,
    showToolbar: true,
    showTools: true,
    showFulltextSearch: false,
    showDimensions: false,
    showColumnSelector: true,
    filterIsStatic: false,
    autoOpenFilter: false,
    showSavedFilters: true,
    disableFilter: false,
  };
}

function isNonEmptyElement(el: Element): boolean {
  return el.innerHTML.trim().length > 0;
}

@Component({
  selector: 'icz-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    SubjectNamePipe,
    LocalizedDatePipe,
    LocalizedDatetimePipe,
    EmpowermentPipe,
    AddressPipe,
    FilterNameService,
  ]
})
export class TableToolbarComponent implements OnInit, IczOnChanges {

  protected tableToolbarService = inject(TableToolbarService);
  protected translate = inject(TranslateService);
  protected router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private detachingService = inject(DetachingService);
  private destroyRef = inject(DestroyRef);
  private filterNameService = inject(FilterNameService);

  @ViewChild('moreFiltersPopup', {static: false})
  private moreFiltersPopup!: TableToolbarPopupComponent;
  @ViewChild('searchTermInput', {static: false})
  private searchTermInput!: FormFieldComponent;
  @ViewChild('filterChild', {static: false})
  private filterButton!: ToggleButtonComponent;
  @ViewChild('titleContent', {static: true})
  private titleContentEl!: ElementRef;
  @ViewChild('toolsContent', {static: true})
  private toolsContentEl!: ElementRef;
  @ViewChild('tabsContent', {static: true})
  private tabsContentEl!: ElementRef;

  @Input({required: true})
  config: TableToolbarConfig = getDefaultTableToolbarConfig();
  @Input({required: true})
  set enabledFilters(newValue: Nullable<string[]>) {
    if (this.tableToolbarService.enabledFilters$.value !== newValue) {
      this.tableToolbarService.enabledFilters$.next(newValue);
    }
  }

  @Input({required: true}) tableIdForLocalStorage!: string;
  @Input({required: true}) columnsData: Nullable<TableColumnsData<string>>;
  @Input() allowedDimensions: Nullable<Array<StatisticsDimension>>;
  @Input() initialDimensions: Nullable<Array<StatisticsDimension>>;
  @Input({required: true}) listLoadingStates!: Record<string, boolean>;
  @Input()
  set searchTerm(newValue: string) {
    if (newValue.trim() !== this._searchTerm.trim()) {
      this._searchTerm = newValue;

      if (this._searchTerm) {
        const realSearchTerm = this._searchTerm.trim();

        this.toolbarForm.get('searchTerm')!.setValue(realSearchTerm);

        if (this.searchTermInput) {
          this.searchTermInput.focusField();
        }
      }
    }
  }
  @Input() allowSavingFiltersWithEmptyValues = true;
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() dimensionsChange = new EventEmitter<Array<StatisticsDimension>>();

  @Output() reloadClicked = new EventEmitter<void>();
  @Output() columnSettingsClicked = new EventEmitter<void>();

  toolbarForm = this.tableToolbarService.toolbarForm;

  trianglePosition = 0;
  @Input()
  subToolbarOpened  = false;

  collator = new Intl.Collator(this.translate.currentLang);

  availableColumnFilterOptions$ = combineLatest([
    this.tableToolbarService.allColumnFilterOptions$,
    this.tableToolbarService.enabledFilters$,
  ]).pipe(
    map(([allColumnFilterOptions, enabledFilters]) => {
      if (enabledFilters) {
        return allColumnFilterOptions.filter(f => enabledFilters.includes(String(f.value)));
      }
      else {
        return allColumnFilterOptions;
      }
    }),
    map(columnOptions => columnOptions.sort((o1, o2) => this.collator.compare(o1.label, o2.label))),
  );

  distinctiveFilters$ = this.tableToolbarService.distinctiveFilters$;

  visibleFilters$ = this.tableToolbarService.visibleFilters$;

  isSimpleQueryMode$ = this.tableToolbarService.activeFilters$.pipe(
    map(activeFilters => isSimpleQueryFilterTree(activeFilters)),
  );

  searchTermValue$ = this.searchTermControl.valueChanges.pipe(
    startWith(this.searchTermControl.value),
  );

  filterName$ = this.filterNameService.getFilterNameObs(
    this.tableToolbarService.activeFilters$.pipe(debounceTime(1000)),
    this.searchTermValue$,
    true,
  );

  defaultFilterName$!: Observable<Nullable<string>>;

  get hasTitleContent(): boolean {
    return this.titleContentEl ? isNonEmptyElement(this.titleContentEl.nativeElement) : false;
  }

  get hasTabsContent(): boolean {
    return this.tabsContentEl ? isNonEmptyElement(this.tabsContentEl.nativeElement) : false;
  }

  get searchTermControl() {
    return this.tableToolbarService.toolbarForm.get('searchTerm')!;
  }

  getDeferredFilterListPerColumn(item: FilterItem): DeferredColumnList {
    const columnDefinition = this.columnsData?.getColumnById(item.id);

    if (isListColumnDefinition(columnDefinition)) {
      return {list: (columnDefinition.list ?? []), isListLoading: this.listLoadingStates[columnDefinition.id] ?? false};
    }
    else {
      return {list: [], isListLoading: false};
    }
  }

  protected _searchTerm: string = '';

  private listLoadingStatesSubscription: Nullable<Subscription>;

  ngOnInit() {
    this.detachingService.registerDetach('columnResize', this.cd);

    this.tableToolbarService.reloadFilters$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(_ => this.cd.detectChanges());

    this.router.events.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(navigationEvent => {
      if (navigationEvent instanceof NavigationStart) {
        this.tableToolbarService.autoOpen = false;
      }
    });

    if (this.config?.autoOpenFilter) {
      this.subToolbarOpened = true;
    }

    this.toolbarForm.get('searchTerm')!.valueChanges.pipe(
      filter(searchTerm => {
        const coalescedSearchTermLength = searchTerm?.length ?? 0;
        return coalescedSearchTermLength === 0 || coalescedSearchTermLength >= MIN_SEARCH_TERM_LENGTH;
      }),
      map(searchTerm => searchTerm ?? ''),
      debounceTime(500),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(newTerm => this.searchTermChange.emit(newTerm!));

    if (this.allowSavingFiltersWithEmptyValues) {
      this.defaultFilterName$ = this.filterNameService.getFilterNameObs(
        this.tableToolbarService.visibleFilters$.pipe(
          map(visibleFilters => ({
            operator: FilterTreeOperator.NONE,
            values: visibleFilters,
          })),
        ),
        this.searchTermValue$,
        false,
      );
    }
    else {
      this.defaultFilterName$ = this.filterNameService.getFilterNameObs(
        this.tableToolbarService.activeFilters$,
        this.searchTermValue$,
        false,
      );
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.columnsData && this.columnsData) {
      this.listLoadingStatesSubscription?.unsubscribe();

      this.listLoadingStatesSubscription = this.columnsData!.listLoadingStatesChange$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.cd.detectChanges());
    }
  }

  addFilter(filterId: string) {
    const item = this.tableToolbarService.allColumnFilters$.value.find(u => u.id === filterId || u.customFilterId === filterId)!;
    this.tableToolbarService.autoOpen = item.filterType !== FilterType.BOOLEAN;
    this.tableToolbarService.addFilterItem({...item, id: filterId, value: null, label: null});
  }

  toggleFilter() {
    this.tableToolbarService.autoOpen = false;
    const elem = this.filterButton.eleRef.nativeElement;
    this.trianglePosition = elem.offsetParent.clientWidth - elem.offsetLeft - elem.clientWidth / 2 - 8;
    this.subToolbarOpened = !this.subToolbarOpened;
  }

  activeFilterIndication(): boolean {
    return !isFilterTreeEmpty(this.tableToolbarService.activeFilterValues$.value) && !this.subToolbarOpened;
  }

  moreFiltersChanged(filterId: PrimitiveControlValueType) {
    this.moreFiltersPopup.isOpen = false;
    this.addFilter(filterId as string);
  }

  isSearchTermRightLength(searchTerm: Nullable<string>) {
    return (searchTerm?.length ?? 0) >= MIN_SEARCH_TERM_LENGTH;
  }

  editComplexQuery() {
    const queryParamParts = location.search.replace('?', '').split('&');
    const filterQueryParam = queryParamParts.find(qp => qp.startsWith(FILTER_PARAM_NAME));

    if (filterQueryParam) {
      this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.ADVANCED_SEARCH) + '?' + filterQueryParam);
    }
  }

}
