/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { IssdDto } from '../models/issd-dto';
import { issdFindAll } from '../fn/issd/issd-find-all';
import { IssdFindAll$Params } from '../fn/issd/issd-find-all';
import { issdFindIssdBySourceAndOrganization } from '../fn/issd/issd-find-issd-by-source-and-organization';
import { IssdFindIssdBySourceAndOrganization$Params } from '../fn/issd/issd-find-issd-by-source-and-organization';
import { issdFindIssdByUsernameAndOrganization } from '../fn/issd/issd-find-issd-by-username-and-organization';
import { IssdFindIssdByUsernameAndOrganization$Params } from '../fn/issd/issd-find-issd-by-username-and-organization';
import { issdGetById } from '../fn/issd/issd-get-by-id';
import { IssdGetById$Params } from '../fn/issd/issd-get-by-id';


/**
 * API for management of issds (external applications).
 */
@Injectable({ providedIn: 'root' })
export class ApiIssdService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `issdGetById()` */
  static readonly IssdGetByIdPath = '/issd/{id}';

  /**
   * Returns issd by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdGetById$Response(params: IssdGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdDto>> {
    return issdGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns issd by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdGetById(params: IssdGetById$Params, context?: HttpContext): Observable<IssdDto> {
    return this.issdGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdDto>): IssdDto => r.body)
    );
  }

  /** Path part for operation `issdFindAll()` */
  static readonly IssdFindAllPath = '/issd/findAll';

  /**
   * Finds all issd instances
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdFindAll$Response(params?: IssdFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<IssdDto>>> {
    return issdFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all issd instances
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdFindAll(params?: IssdFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<IssdDto>> {
    return this.issdFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<IssdDto>>): Array<IssdDto> => r.body)
    );
  }

  /** Path part for operation `issdFindIssdByUsernameAndOrganization()` */
  static readonly IssdFindIssdByUsernameAndOrganizationPath = '/issd/by-username/{username}/{orgid}';

  /**
   * Returns issd by username
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdFindIssdByUsernameAndOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdFindIssdByUsernameAndOrganization$Response(params: IssdFindIssdByUsernameAndOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdDto>> {
    return issdFindIssdByUsernameAndOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns issd by username
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdFindIssdByUsernameAndOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdFindIssdByUsernameAndOrganization(params: IssdFindIssdByUsernameAndOrganization$Params, context?: HttpContext): Observable<IssdDto> {
    return this.issdFindIssdByUsernameAndOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdDto>): IssdDto => r.body)
    );
  }

  /** Path part for operation `issdFindIssdBySourceAndOrganization()` */
  static readonly IssdFindIssdBySourceAndOrganizationPath = '/issd/by-source/{source}/{orgid}';

  /**
   * Returns issd by source
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `issdFindIssdBySourceAndOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdFindIssdBySourceAndOrganization$Response(params: IssdFindIssdBySourceAndOrganization$Params, context?: HttpContext): Observable<StrictHttpResponse<IssdDto>> {
    return issdFindIssdBySourceAndOrganization(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns issd by source
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `issdFindIssdBySourceAndOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  issdFindIssdBySourceAndOrganization(params: IssdFindIssdBySourceAndOrganization$Params, context?: HttpContext): Observable<IssdDto> {
    return this.issdFindIssdBySourceAndOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<IssdDto>): IssdDto => r.body)
    );
  }

}
