/* tslint:disable */
/* eslint-disable */

/**
 * Property scope
 */
export enum PropertyScope {
  GLOBAL = 'GLOBAL',
  ORGANIZATION = 'ORGANIZATION',
  APPLICATION = 'APPLICATION',
  APPLICATION_ORGANIZATION = 'APPLICATION_ORGANIZATION'
}
