import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalBinaryFileDownloadService {

  downloadBinaryFileFromUrl(url: string, fileName: string) {
    const linkEl = document.createElement('a');
    linkEl.href = url;
    linkEl.download = fileName;

    linkEl.click();
  }

  constructDownloadUrl(blob: BlobPart,
                       fileName: string,
                       mimeType: Nullable<string>): string {
    const file = new File([blob], fileName, {
      type: mimeType ?? 'application/octet-stream',
    });
    return window.URL.createObjectURL(file);
  }

  downloadBlob(blob: BlobPart,
               fileName: string,
               mimeType: Nullable<string>): void {
    const downloadUrl = this.constructDownloadUrl(blob, fileName, mimeType);
    this.downloadBinaryFileFromUrl(downloadUrl, fileName);
  }

  downloadFile(file: File): void {
    this.downloadBinaryFileFromUrl(window.URL.createObjectURL(file), file.name);
  }

}
