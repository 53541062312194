<form [formGroup]="form" [waiting]="loadingService.isLoading(this)">
  <icz-org-structure-selection-loader
    label="Zvolit skupinu pro předání"
    [parentForm]="form"
    (groupSelected)="applySavedGroup($event)"
  ></icz-org-structure-selection-loader>
  <icz-section label="Komu dát k vyjádření">
    <icz-org-structure-selector
      [form]="form"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      controlName="orgStructureElementIds"
      label="Funkční místa nebo organizační jednotky"
      [isMultiselect]="true"
    ></icz-org-structure-selector>
    <icz-org-structure-selection-saver
      [parentForm]="form"
      [group]="groupForSaving"
      [isGroupOrdered]="selectedFlowType !== CirculationActivityFlowType.PARALLEL"
    ></icz-org-structure-selection-saver>
  </icz-section>
  <div class="row gap-48">
    <div class="col grow">
      <icz-section label="Způsob předání">
        <icz-radio-group class="grow">
          <div class="col grow">
            <icz-radio-button class="grow" formControlName="circulationFlowType"
              label="Dát k vyjádření všem najednou"
              [value]="CirculationActivityFlowType.PARALLEL"
            ></icz-radio-button>
            <icz-radio-button class="grow" formControlName="circulationFlowType"
              label="Dát k vyjádření postupně"
              [value]="CirculationActivityFlowType.SERIAL"
            ></icz-radio-button>
          </div>
        </icz-radio-group>
        @if (selectedFlowType === CirculationActivityFlowType.SERIAL && selectedParticipants?.length) {
          <icz-section label="Pořadí předání">
            <icz-form-item-sorter formControlName="taskParticipantsOrder" [options]="selectedParticipants" class="grow"></icz-form-item-sorter>
          </icz-section>
        }
      </icz-section>
    </div>
    <div class="col grow">
      <icz-section label="Instrukce a termín">
        <icz-form-field formControlName="targetTaskInstruction"
          label="Instrukce"
          [autoSizeMax]="3">
        </icz-form-field>
        <icz-date-picker formControlName="deadline"
          label="Termín, dokdy potřebujete vyjádření"
          [selectableDates]="deadlineValidator"
        ></icz-date-picker>
      </icz-section>
      <!--<icz-section *ngIf="initEntity?.documentForm === DocumentForm.ANALOG" testingFeature label="Tisk">
      <icz-checkbox formControlName="printHandoverProtocol"
        label="Tisknout předávací protokol">
      </icz-checkbox>
    </icz-section>-->
  </div>
</div>
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="handForStatement()" primary label="Dát k vyjádření"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
