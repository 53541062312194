/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { OwnDocumentDto } from '../models/own-document-dto';
import { receivedDocumentCreateResponseDocument } from '../fn/received-document/received-document-create-response-document';
import { ReceivedDocumentCreateResponseDocument$Params } from '../fn/received-document/received-document-create-response-document';
import { ReceivedDocumentDto } from '../models/received-document-dto';
import { receivedDocumentFindById } from '../fn/received-document/received-document-find-by-id';
import { ReceivedDocumentFindById$Params } from '../fn/received-document/received-document-find-by-id';
import { receivedDocumentUpdate } from '../fn/received-document/received-document-update';
import { ReceivedDocumentUpdate$Params } from '../fn/received-document/received-document-update';


/**
 * API for management of received documents
 */
@Injectable({ providedIn: 'root' })
export class ApiReceivedDocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `receivedDocumentFindById()` */
  static readonly ReceivedDocumentFindByIdPath = '/document/received-document/{id}';

  /**
   * Find received document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDocumentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDocumentFindById$Response(params: ReceivedDocumentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedDocumentDto>> {
    return receivedDocumentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find received document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDocumentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDocumentFindById(params: ReceivedDocumentFindById$Params, context?: HttpContext): Observable<ReceivedDocumentDto> {
    return this.receivedDocumentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedDocumentDto>): ReceivedDocumentDto => r.body)
    );
  }

  /** Path part for operation `receivedDocumentUpdate()` */
  static readonly ReceivedDocumentUpdatePath = '/document/received-document/{id}';

  /**
   * Update received document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDocumentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDocumentUpdate$Response(params: ReceivedDocumentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedDocumentDto>> {
    return receivedDocumentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update received document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDocumentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDocumentUpdate(params: ReceivedDocumentUpdate$Params, context?: HttpContext): Observable<ReceivedDocumentDto> {
    return this.receivedDocumentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedDocumentDto>): ReceivedDocumentDto => r.body)
    );
  }

  /** Path part for operation `receivedDocumentCreateResponseDocument()` */
  static readonly ReceivedDocumentCreateResponseDocumentPath = '/document/received-document/{id}/response-document';

  /**
   * Creates a response document for a received document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDocumentCreateResponseDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDocumentCreateResponseDocument$Response(params: ReceivedDocumentCreateResponseDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDocumentDto>> {
    return receivedDocumentCreateResponseDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a response document for a received document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDocumentCreateResponseDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDocumentCreateResponseDocument(params: ReceivedDocumentCreateResponseDocument$Params, context?: HttpContext): Observable<OwnDocumentDto> {
    return this.receivedDocumentCreateResponseDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDocumentDto>): OwnDocumentDto => r.body)
    );
  }

}
