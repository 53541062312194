import {ChangeDetectionStrategy, Component, ElementRef, inject, ViewChild} from '@angular/core';
import {
  AnalogComponentToDocumentReferenceCreateDto,
  MediumComponentCreateDto,
  PaperComponentCreateDto,
  PhysicalItemComponentCreateDto
} from '|api/component';
import {
  AnalogComponentCreateFormComponent,
  createAnalogComponentsFormGroup,
  CreateAnalogFormConfig,
  CreateAnalogsUsage,
  EsslAnalogComponentType,
  EsslAnalogComponentTypeAsFields
} from '../../../analog-component-create-form/analog-component-create-form.component';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczFormArray} from '../../../../form-elements/icz-form-controls';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';

export interface EsslAnalogComponentModalData {
  analogComponentType: EsslAnalogComponentType;
}


@Component({
  selector: 'icz-analog-component-create-dialog',
  templateUrl: './analog-component-create-dialog.component.html',
  styleUrls: ['./analog-component-create-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AnalogComponentCreateDialogComponent {

  protected modalRef = injectModalRef<Nullable<AnalogComponentToDocumentReferenceCreateDto[]>>();
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected data = injectModalData<EsslAnalogComponentModalData>();

  @ViewChild('fileUpload', {static: false}) fileUpload!: ElementRef;
  @ViewChild('AnalogComponentCreateFormComponent') analogComponentCreateFormComponent!: AnalogComponentCreateFormComponent;

  formGroupsToCheck!: string[];
  form = createAnalogComponentsFormGroup({type: this.data.analogComponentType});
  componentsConfig: CreateAnalogFormConfig = {
    analogCreateUsage: CreateAnalogsUsage.PART_OF_DOCUMENT_CREATE,
    showFieldIsFinal: true,
    showFieldOrigin: true,
    isDetailedInput: 'true',
    allowDeleteFirst: false,
    setFieldIsFinalAsTrue: false,
  };

  isUploading = false;

  get paperComponents(): PaperComponentCreateDto[] {
    return this.paperComponentsControl.value;
  }

  get paperComponentsControl(): IczFormArray {
    return this.form.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray;
  }

  get mediumComponents(): MediumComponentCreateDto[] {
    return this.mediumComponentsControl.value;
  }

  get mediumComponentsControl(): IczFormArray {
    return this.form.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray;
  }

  get physicalItemComponents(): PhysicalItemComponentCreateDto[] {
    return this.physicalItemComponentsControl.value;
  }

  get physicalItemComponentsControl(): IczFormArray {
    return this.form.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray;
  }

  get isPaperComponentsDialog(): boolean {
    return this.data.analogComponentType === EsslAnalogComponentType.PAPER_COMPONENT;
  }

  get isMediumComponentDialog(): boolean {
    return this.data.analogComponentType === EsslAnalogComponentType.MEDIUM_COMPONENT;
  }

  get isPhysicalItemComponentDialog(): boolean {
    return this.data.analogComponentType === EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT;
  }

  get formControlsBasedOnDialogType(): IczFormArray {
    if (this.isPaperComponentsDialog) return this.paperComponentsControl;
    if (this.isMediumComponentDialog) return this.mediumComponentsControl;
    if (this.isPhysicalItemComponentDialog) return this.physicalItemComponentsControl;
    else return this.paperComponentsControl;
  }

  get isFormInvalid(): boolean {
    return this.formControlsBasedOnDialogType.invalid;
  }

  get disabled(): boolean {
    return this.isUploading || this.isFormInvalid;
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  addAnotherComponent() {
    this.analogComponentCreateFormComponent.addAnotherComponent();
  }

  submit() {
    this.analogComponentCreateFormComponent.submit();
  }

  close(analogComponentsToLink: AnalogComponentToDocumentReferenceCreateDto[]) {
    this.modalRef.close(analogComponentsToLink);
  }

  cancel() {
    this.modalRef.close(null);
  }

}
