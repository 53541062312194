/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageTransactionEventDto } from '../models/page-transaction-event-dto';
import { TransactionEventDto } from '../models/transaction-event-dto';
import { transactionEventFind } from '../fn/transaction-event/transaction-event-find';
import { TransactionEventFind$Params } from '../fn/transaction-event/transaction-event-find';
import { transactionEventFindById } from '../fn/transaction-event/transaction-event-find-by-id';
import { TransactionEventFindById$Params } from '../fn/transaction-event/transaction-event-find-by-id';


/**
 * Controller for Transaction Event
 */
@Injectable({ providedIn: 'root' })
export class ApiTransactionEventService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `transactionEventFindById()` */
  static readonly TransactionEventFindByIdPath = '/transaction-log/transaction-event/{id}';

  /**
   * Find transaction event by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionEventFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventFindById$Response(params: TransactionEventFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<TransactionEventDto>> {
    return transactionEventFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find transaction event by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionEventFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventFindById(params: TransactionEventFindById$Params, context?: HttpContext): Observable<TransactionEventDto> {
    return this.transactionEventFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TransactionEventDto>): TransactionEventDto => r.body)
    );
  }

  /** Path part for operation `transactionEventFind()` */
  static readonly TransactionEventFindPath = '/transaction-log/transaction-event/search';

  /**
   * Find all Transaction Event instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `transactionEventFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventFind$Response(params?: TransactionEventFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageTransactionEventDto>> {
    return transactionEventFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all Transaction Event instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `transactionEventFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  transactionEventFind(params?: TransactionEventFind$Params|null|undefined, context?: HttpContext): Observable<PageTransactionEventDto> {
    return this.transactionEventFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageTransactionEventDto>): PageTransactionEventDto => r.body)
    );
  }

}
