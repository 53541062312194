import {inject, Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {OwnPaperConsignmentDto} from '|api/sad';
import {ConsignmentsToastService, ConsignmentToastData} from '../../../consignment-dialog/consignments-toast.service';
import {WithdrawForCorrectionDialogComponent} from './withdraw-for-correction-dialog.component';
import {IczModalService} from '../../../../../services/icz-modal.service';


export enum WithdrawForCorrectionDialogResultType {
  WithdrawnForCorrection = 'WithdrawnForCorrection',
}

export interface WithdrawForCorrectionDialogResult {
  resultType: WithdrawForCorrectionDialogResultType;
  consignment?: OwnPaperConsignmentDto;
}

export interface WithdrawForCorrectionDialogData {
  documentId: number;
  consignments: OwnPaperConsignmentDto[];
}

@Injectable({
  providedIn: 'root'
})
export class WithdrawForCorrectionDialogService {

  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);

  openWithdrawForCorrectionDialog(documentId: number, consignments: OwnPaperConsignmentDto[]) {
    return this.modalService.openComponentInModal<Nullable<WithdrawForCorrectionDialogResult>, WithdrawForCorrectionDialogData>({
      component: WithdrawForCorrectionDialogComponent,
      modalOptions: {
        titleTemplate: 'Stažení zásilky k nápravě',
        width: 800,
        height: 400,
      },
      data: {
        documentId,
        consignments: consignments as OwnPaperConsignmentDto[],
      },
    }).pipe(
      tap(result => {
        if (result) {
          const isBulk = consignments.length > 1;
          if (isBulk) {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.COUNT]: consignments.length,
            };

            this.consignmentsToastService.dispatchConsignmentBulkActionAccepted(toastData);
          } else {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.DOCUMENT_ID]: documentId,
              [InternalNotificationKey.CONSIGNMENT_ID]: result.consignment!.id,
              [InternalNotificationKey.CONSIGNMENT_UID]: result.consignment!.uid!.uid!,
            };

            this.consignmentsToastService.dispatchConsignmentWithdrawnForCorrection(toastData);
          }
        }
      }),
    );
  }

}
