/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ChangePasswordDto } from '../models/change-password-dto';
import { passwordGetUserInfo } from '../fn/password/password-get-user-info';
import { PasswordGetUserInfo$Params } from '../fn/password/password-get-user-info';


/**
 * API for password encrypt
 */
@Injectable({ providedIn: 'root' })
export class ApiPasswordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `passwordGetUserInfo()` */
  static readonly PasswordGetUserInfoPath = '/password/encrypt';

  /**
   * Retrieves encrypted password
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `passwordGetUserInfo()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordGetUserInfo$Response(params: PasswordGetUserInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<ChangePasswordDto>> {
    return passwordGetUserInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves encrypted password
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `passwordGetUserInfo$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  passwordGetUserInfo(params: PasswordGetUserInfo$Params, context?: HttpContext): Observable<ChangePasswordDto> {
    return this.passwordGetUserInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ChangePasswordDto>): ChangePasswordDto => r.body)
    );
  }

}
