/* tslint:disable */
/* eslint-disable */

/**
 * Acknowledgement status of internal notification
 */
export enum AcknowledgementStatus {
  UNREAD = 'UNREAD',
  READ = 'READ'
}
