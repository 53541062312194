import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {InternalNotificationKey} from '|api/notification';
import {
  InternalNotificationBulkDocumentActionMessageCode,
  InternalNotificationMessageCode
} from './internal-notification.enum';
import {
  EsslComponentToastType,
  SConversionErrorTemplateData,
  SDigitalComponentTemplateData
} from './essl-component-toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {createAbsoluteRoute} from '../../routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentDetailRoute, DocumentsRoute} from '../../../enums/documents-routes.enum';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  DocumentToastType,
  SDocumentTemplateData,
  SDocumentTemplateDataError,
  SInsertToFileTemplateData,
  SInsertToFileTemplateDataError
} from './document-toast.service';

type ToastTemplateData = Partial<Record<InternalNotificationKey, string>>;

@Injectable({
  providedIn: 'root'
})
export class DocumentNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);
  private router = inject(Router);

  initialize() {
    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.CONVERSION_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.CONVERSION_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.CONVERSION_ERROR,
      (parsedBody: WebSocketNotification) => {
        const parameters = parsedBody.parameters.map(parameterSet => this.prepareGenericConversionErrorTemplateData(parameterSet));

        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.CONVERSION_ERROR,
          parameters,
          SConversionErrorTemplateData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.CONVERSION_WITH_CLAUSE_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_ERROR,
      (parsedBody: WebSocketNotification) => {
        const parameters = parsedBody.parameters.map(parameterSet => this.prepareGenericConversionErrorTemplateData(parameterSet));

        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.CONVERSION_WITH_CLAUSE_ERROR,
          parameters,
          SConversionErrorTemplateData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_ERROR,
      (parsedBody: WebSocketNotification) => {
        const parameters = parsedBody.parameters.map(parameterSet => this.prepareGenericConversionErrorTemplateData(parameterSet));

        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.AUTHORIZED_CONVERSION_ERROR,
          parameters,
          SConversionErrorTemplateData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_ERROR,
      (parsedBody: WebSocketNotification) => {
        const parameters = parsedBody.parameters.map(parameterSet => this.prepareGenericConversionErrorTemplateData(parameterSet));

        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.AUTHORIZED_CONVERSION_ERROR,
          parameters,
          SConversionErrorTemplateData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR,
      (parsedBody: WebSocketNotification) => {
        const parameters = parsedBody.parameters.map(parameterSet => this.prepareGenericConversionErrorTemplateData(parameterSet));

        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR,
          parameters,
          SConversionErrorTemplateData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR,
      (parsedBody: WebSocketNotification) => {
        const parameters = parsedBody.parameters.map(parameterSet => this.prepareGenericConversionErrorTemplateData(parameterSet));

        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR,
          parameters,
          SConversionErrorTemplateData,
          true,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          DocumentToastType.DOCUMENT_INSERTED_TO_FILE,
          parsedBody.parameters,
          SInsertToFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_INSERT_DOCUMENTS_INTO_FILE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          DocumentToastType.DOCUMENT_INSERTED_TO_FILE_ERROR,
          parsedBody.parameters,
          SInsertToFileTemplateDataError,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          DocumentToastType.DOCUMENT_REMOVED_FROM_FILE,
          parsedBody.parameters,
          SInsertToFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          DocumentToastType.DOCUMENT_REMOVED_FROM_FILE_ERROR,
          parsedBody.parameters,
          SInsertToFileTemplateDataError,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE,
          parsedBody.parameters,
          SInsertToFileTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE_ERROR,
          parsedBody.parameters,
          SInsertToFileTemplateDataError,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_REGISTER_DOCUMENT_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          DocumentToastType.DOCUMENT_REGISTERED,
          parsedBody.parameters,
          SDocumentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationBulkDocumentActionMessageCode.BULK_REGISTER_DOCUMENT_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          DocumentToastType.DOCUMENT_REGISTERED_ERROR,
          parsedBody.parameters,
          SDocumentTemplateDataError,
        );
      }
    );
  }

  private prepareGenericConversionErrorTemplateData(parameters: ToastTemplateData) {
    return {
      ...parameters,
      onPrimaryButtonClick: () => this.router.navigateByUrl(
        createAbsoluteRoute(
          ApplicationRoute.DOCUMENTS,
          DocumentsRoute.DOCUMENT,
          parameters[InternalNotificationKey.DOCUMENT_ID]!,
          DocumentDetailRoute.COMPONENTS,
          {
            digitalComponentId: parameters[InternalNotificationKey.DIGITAL_COMPONENT_ID],
            digitalComponentVersionId: parameters[InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID],
          }
        )
      ),
    };
  }

}
