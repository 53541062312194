/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { classificationSchemeCreate } from '../fn/classification-scheme/classification-scheme-create';
import { ClassificationSchemeCreate$Params } from '../fn/classification-scheme/classification-scheme-create';
import { classificationSchemeDelete } from '../fn/classification-scheme/classification-scheme-delete';
import { ClassificationSchemeDelete$Params } from '../fn/classification-scheme/classification-scheme-delete';
import { ClassificationSchemeDto } from '../models/classification-scheme-dto';
import { classificationSchemeExportXml } from '../fn/classification-scheme/classification-scheme-export-xml';
import { ClassificationSchemeExportXml$Params } from '../fn/classification-scheme/classification-scheme-export-xml';
import { classificationSchemeFindAll } from '../fn/classification-scheme/classification-scheme-find-all';
import { ClassificationSchemeFindAll$Params } from '../fn/classification-scheme/classification-scheme-find-all';
import { classificationSchemeFindById } from '../fn/classification-scheme/classification-scheme-find-by-id';
import { ClassificationSchemeFindById$Params } from '../fn/classification-scheme/classification-scheme-find-by-id';
import { classificationSchemeGetValidOnDate } from '../fn/classification-scheme/classification-scheme-get-valid-on-date';
import { ClassificationSchemeGetValidOnDate$Params } from '../fn/classification-scheme/classification-scheme-get-valid-on-date';
import { classificationSchemeImportXml } from '../fn/classification-scheme/classification-scheme-import-xml';
import { classificationSchemeImportXml_1 } from '../fn/classification-scheme/classification-scheme-import-xml-1';
import { ClassificationSchemeImportXml_1$Params } from '../fn/classification-scheme/classification-scheme-import-xml-1';
import { ClassificationSchemeImportXml$Params } from '../fn/classification-scheme/classification-scheme-import-xml';
import { classificationSchemeMakeEditable } from '../fn/classification-scheme/classification-scheme-make-editable';
import { ClassificationSchemeMakeEditable$Params } from '../fn/classification-scheme/classification-scheme-make-editable';
import { classificationSchemeRecalculateEntityClasses } from '../fn/classification-scheme/classification-scheme-recalculate-entity-classes';
import { ClassificationSchemeRecalculateEntityClasses$Params } from '../fn/classification-scheme/classification-scheme-recalculate-entity-classes';
import { classificationSchemeRecalculateEntityClassesRoot } from '../fn/classification-scheme/classification-scheme-recalculate-entity-classes-root';
import { ClassificationSchemeRecalculateEntityClassesRoot$Params } from '../fn/classification-scheme/classification-scheme-recalculate-entity-classes-root';
import { classificationSchemeRelease } from '../fn/classification-scheme/classification-scheme-release';
import { ClassificationSchemeRelease$Params } from '../fn/classification-scheme/classification-scheme-release';
import { classificationSchemeSearch } from '../fn/classification-scheme/classification-scheme-search';
import { ClassificationSchemeSearch$Params } from '../fn/classification-scheme/classification-scheme-search';
import { classificationSchemeSetExpirationDateBeforeReleased } from '../fn/classification-scheme/classification-scheme-set-expiration-date-before-released';
import { ClassificationSchemeSetExpirationDateBeforeReleased$Params } from '../fn/classification-scheme/classification-scheme-set-expiration-date-before-released';
import { classificationSchemeUpdate } from '../fn/classification-scheme/classification-scheme-update';
import { ClassificationSchemeUpdate$Params } from '../fn/classification-scheme/classification-scheme-update';
import { classificationSchemeValidate } from '../fn/classification-scheme/classification-scheme-validate';
import { ClassificationSchemeValidate$Params } from '../fn/classification-scheme/classification-scheme-validate';
import { ClassificationSchemeValidateResponseDto } from '../models/classification-scheme-validate-response-dto';
import { PageClassificationSchemeDto } from '../models/page-classification-scheme-dto';


/**
 * Controller for Classification Scheme
 */
@Injectable({ providedIn: 'root' })
export class ApiClassificationSchemeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `classificationSchemeFindById()` */
  static readonly ClassificationSchemeFindByIdPath = '/codebook/classifier/classification-scheme/{id}';

  /**
   * Find classification scheme by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeFindById$Response(params: ClassificationSchemeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find classification scheme by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeFindById(params: ClassificationSchemeFindById$Params, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeUpdate()` */
  static readonly ClassificationSchemeUpdatePath = '/codebook/classifier/classification-scheme/{id}';

  /**
   * Update existing classification scheme
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationSchemeUpdate$Response(params: ClassificationSchemeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing classification scheme
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationSchemeUpdate(params: ClassificationSchemeUpdate$Params, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeDelete()` */
  static readonly ClassificationSchemeDeletePath = '/codebook/classifier/classification-scheme/{id}';

  /**
   * Deletes classification scheme by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeDelete$Response(params: ClassificationSchemeDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return classificationSchemeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes classification scheme by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeDelete(params: ClassificationSchemeDelete$Params, context?: HttpContext): Observable<void> {
    return this.classificationSchemeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `classificationSchemeSetExpirationDateBeforeReleased()` */
  static readonly ClassificationSchemeSetExpirationDateBeforeReleasedPath = '/codebook/classifier/classification-scheme/setExpirationDateBeforeReleased';

  /**
   * Finds all classification schemes that should have valid to set as a reaction to release of a different classification scheme.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeSetExpirationDateBeforeReleased()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeSetExpirationDateBeforeReleased$Response(params?: ClassificationSchemeSetExpirationDateBeforeReleased$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return classificationSchemeSetExpirationDateBeforeReleased(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all classification schemes that should have valid to set as a reaction to release of a different classification scheme.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeSetExpirationDateBeforeReleased$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeSetExpirationDateBeforeReleased(params?: ClassificationSchemeSetExpirationDateBeforeReleased$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.classificationSchemeSetExpirationDateBeforeReleased$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `classificationSchemeFindAll()` */
  static readonly ClassificationSchemeFindAllPath = '/codebook/classifier/classification-scheme';

  /**
   * Finds all classification schemes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeFindAll$Response(params?: ClassificationSchemeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<ClassificationSchemeDto>>> {
    return classificationSchemeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all classification schemes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeFindAll(params?: ClassificationSchemeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<ClassificationSchemeDto>> {
    return this.classificationSchemeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ClassificationSchemeDto>>): Array<ClassificationSchemeDto> => r.body)
    );
  }

  /** Path part for operation `classificationSchemeCreate()` */
  static readonly ClassificationSchemeCreatePath = '/codebook/classifier/classification-scheme';

  /**
   * Create new classification scheme
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationSchemeCreate$Response(params: ClassificationSchemeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new classification scheme
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  classificationSchemeCreate(params: ClassificationSchemeCreate$Params, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeValidate()` */
  static readonly ClassificationSchemeValidatePath = '/codebook/classifier/classification-scheme/validate/{id}';

  /**
   * Validates classification scheme
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeValidate()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeValidate$Response(params: ClassificationSchemeValidate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeValidateResponseDto>> {
    return classificationSchemeValidate(this.http, this.rootUrl, params, context);
  }

  /**
   * Validates classification scheme
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeValidate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeValidate(params: ClassificationSchemeValidate$Params, context?: HttpContext): Observable<ClassificationSchemeValidateResponseDto> {
    return this.classificationSchemeValidate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeValidateResponseDto>): ClassificationSchemeValidateResponseDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeRelease()` */
  static readonly ClassificationSchemeReleasePath = '/codebook/classifier/classification-scheme/release/{id}/{date}';

  /**
   * Releases classification scheme with given date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeRelease()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeRelease$Response(params: ClassificationSchemeRelease$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeRelease(this.http, this.rootUrl, params, context);
  }

  /**
   * Releases classification scheme with given date.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeRelease$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeRelease(params: ClassificationSchemeRelease$Params, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeRelease$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeRecalculateEntityClasses()` */
  static readonly ClassificationSchemeRecalculateEntityClassesPath = '/codebook/classifier/classification-scheme/recalculate/{id}';

  /**
   * Automatically recalculates all entity classes codes for given classification schema.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeRecalculateEntityClasses()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeRecalculateEntityClasses$Response(params: ClassificationSchemeRecalculateEntityClasses$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return classificationSchemeRecalculateEntityClasses(this.http, this.rootUrl, params, context);
  }

  /**
   * Automatically recalculates all entity classes codes for given classification schema.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeRecalculateEntityClasses$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeRecalculateEntityClasses(params: ClassificationSchemeRecalculateEntityClasses$Params, context?: HttpContext): Observable<void> {
    return this.classificationSchemeRecalculateEntityClasses$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `classificationSchemeRecalculateEntityClassesRoot()` */
  static readonly ClassificationSchemeRecalculateEntityClassesRootPath = '/codebook/classifier/classification-scheme/recalculate/{id}/{entityClassId}';

  /**
   * Automatically recalculates entity classes codes for given classification schema. Starting from given entityClassId and to it's children.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeRecalculateEntityClassesRoot()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeRecalculateEntityClassesRoot$Response(params: ClassificationSchemeRecalculateEntityClassesRoot$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return classificationSchemeRecalculateEntityClassesRoot(this.http, this.rootUrl, params, context);
  }

  /**
   * Automatically recalculates entity classes codes for given classification schema. Starting from given entityClassId and to it's children.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeRecalculateEntityClassesRoot$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeRecalculateEntityClassesRoot(params: ClassificationSchemeRecalculateEntityClassesRoot$Params, context?: HttpContext): Observable<void> {
    return this.classificationSchemeRecalculateEntityClassesRoot$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `classificationSchemeMakeEditable()` */
  static readonly ClassificationSchemeMakeEditablePath = '/codebook/classifier/classification-scheme/make-editable/{id}';

  /**
   * Changes state of CS to draft.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeMakeEditable()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeMakeEditable$Response(params: ClassificationSchemeMakeEditable$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeMakeEditable(this.http, this.rootUrl, params, context);
  }

  /**
   * Changes state of CS to draft.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeMakeEditable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeMakeEditable(params: ClassificationSchemeMakeEditable$Params, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeMakeEditable$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeImportXml()` */
  static readonly ClassificationSchemeImportXmlPath = '/codebook/classifier/classification-scheme/import';

  /**
   * Create new classification scheme from xml
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeImportXml()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  classificationSchemeImportXml$Response(params?: ClassificationSchemeImportXml$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeImportXml(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new classification scheme from xml
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeImportXml$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  classificationSchemeImportXml(params?: ClassificationSchemeImportXml$Params|null|undefined, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeImportXml$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeImportXml_1()` */
  static readonly ClassificationSchemeImportXml_1Path = '/codebook/classifier/classification-scheme/import/meta';

  /**
   * Create new classification scheme from xml
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeImportXml_1()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  classificationSchemeImportXml_1$Response(params?: ClassificationSchemeImportXml_1$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeImportXml_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new classification scheme from xml
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeImportXml_1$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  classificationSchemeImportXml_1(params?: ClassificationSchemeImportXml_1$Params|null|undefined, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeImportXml_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeGetValidOnDate()` */
  static readonly ClassificationSchemeGetValidOnDatePath = '/codebook/classifier/classification-scheme/valid/{date}';

  /**
   * Find classification valid for a specific date.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeGetValidOnDate()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeGetValidOnDate$Response(params: ClassificationSchemeGetValidOnDate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationSchemeDto>> {
    return classificationSchemeGetValidOnDate(this.http, this.rootUrl, params, context);
  }

  /**
   * Find classification valid for a specific date.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeGetValidOnDate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeGetValidOnDate(params: ClassificationSchemeGetValidOnDate$Params, context?: HttpContext): Observable<ClassificationSchemeDto> {
    return this.classificationSchemeGetValidOnDate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ClassificationSchemeDto>): ClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeSearch()` */
  static readonly ClassificationSchemeSearchPath = '/codebook/classifier/classification-scheme/search-api';

  /**
   * Find all values in in classification scheme based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeSearch$Response(params?: ClassificationSchemeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageClassificationSchemeDto>> {
    return classificationSchemeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in in classification scheme based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeSearch(params?: ClassificationSchemeSearch$Params|null|undefined, context?: HttpContext): Observable<PageClassificationSchemeDto> {
    return this.classificationSchemeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageClassificationSchemeDto>): PageClassificationSchemeDto => r.body)
    );
  }

  /** Path part for operation `classificationSchemeExportXml()` */
  static readonly ClassificationSchemeExportXmlPath = '/codebook/classifier/classification-scheme/export/{type}/{id}';

  /**
   * Export classification scheme by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `classificationSchemeExportXml()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeExportXml$Response(params: ClassificationSchemeExportXml$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return classificationSchemeExportXml(this.http, this.rootUrl, params, context);
  }

  /**
   * Export classification scheme by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `classificationSchemeExportXml$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  classificationSchemeExportXml(params: ClassificationSchemeExportXml$Params, context?: HttpContext): Observable<Array<string>> {
    return this.classificationSchemeExportXml$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
