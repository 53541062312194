import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {Time} from '../../../../model';

export type TimeSelectorValue = Partial<Time>;

export enum TimeSelectorUsage {
  FILTER = 'FILTER',
  FORM = 'FORM'
}

@Component({
  selector: 'icz-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSelectorComponent {
  readonly TimeSelectorUsage = TimeSelectorUsage;
  @Input()
  set initialHours(hours: number) {
    this.hours ??= hours;
  }
  @Input()
  set initialMinutes(minutes: number) {
    this.minutes ??= minutes;
  }
  @Input() timeSelectorUsage = TimeSelectorUsage.FILTER;
  @Input() label = '';
  @Input() fieldDisabled = false;

  @Output()
  selectionChange = new EventEmitter<TimeSelectorValue>();

  hours: Nullable<number> = null;
  minutes: Nullable<number> = null;

  minutesChanged($event: number) {
    this.minutes = $event;
    this.emitCurrentValue();
  }

  hoursChanged($event: number) {
    this.hours = $event;
    this.emitCurrentValue();
  }

  protected emitCurrentValue() {
    this.selectionChange.emit({
      hours: this.hours!,
      minutes: this.minutes!,
    });
  }
}
