<icz-resizable-sidebar
  [initialWidth]="340"
  (widthChanged)="sideWidth = $event"
>
  <div class="title">{{treeviewTitle | translate}}</div>

  <icz-table-tree-menu
    [itemMenu]="treeMenu"
    [showItemMenu]="showItemMenu"
    [treeControl]="treeControl"
    [dataSource]="dataSource"
    [selectedMenuItem]="selected"
    [maxWidth]="sideWidth"
    (treeItemSelected)="onTreeNodeClick($event)">
  </icz-table-tree-menu>
</icz-resizable-sidebar>

<mat-menu #treeMenu="matMenu">
  <ng-template matMenuContent>
    <icz-button background (onAction)="onAction.emit({action: 'edit', node: selected})" label="Nastavit seskupení typů"></icz-button>
    <icz-button background (onAction)="onAction.emit({action: 'group', node: selected})" label="Založit nové seskupení typů"></icz-button>
    <icz-button background (onAction)="onAction.emit({action: 'new', node: selected})" label="Založit typ"></icz-button>
  </ng-template>
</mat-menu>
