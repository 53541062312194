/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentPermission } from '../models/document-permission';
import { FilePermission } from '../models/file-permission';
import { permissionGetAllDocumentPermissionsWithDependencies } from '../fn/permission/permission-get-all-document-permissions-with-dependencies';
import { PermissionGetAllDocumentPermissionsWithDependencies$Params } from '../fn/permission/permission-get-all-document-permissions-with-dependencies';
import { permissionGetAllFilePermissionsWithDependencies } from '../fn/permission/permission-get-all-file-permissions-with-dependencies';
import { PermissionGetAllFilePermissionsWithDependencies$Params } from '../fn/permission/permission-get-all-file-permissions-with-dependencies';
import { permissionGetAllStorageUnitPermissionsWithDependencies } from '../fn/permission/permission-get-all-storage-unit-permissions-with-dependencies';
import { PermissionGetAllStorageUnitPermissionsWithDependencies$Params } from '../fn/permission/permission-get-all-storage-unit-permissions-with-dependencies';
import { permissionGetDocumentPermissionDependencies } from '../fn/permission/permission-get-document-permission-dependencies';
import { PermissionGetDocumentPermissionDependencies$Params } from '../fn/permission/permission-get-document-permission-dependencies';
import { permissionGetDocumentPermissionsByLevel } from '../fn/permission/permission-get-document-permissions-by-level';
import { PermissionGetDocumentPermissionsByLevel$Params } from '../fn/permission/permission-get-document-permissions-by-level';
import { permissionGetFilePermissionDependencies } from '../fn/permission/permission-get-file-permission-dependencies';
import { PermissionGetFilePermissionDependencies$Params } from '../fn/permission/permission-get-file-permission-dependencies';
import { permissionGetFilePermissionsByLevel } from '../fn/permission/permission-get-file-permissions-by-level';
import { PermissionGetFilePermissionsByLevel$Params } from '../fn/permission/permission-get-file-permissions-by-level';
import { permissionGetStorageUnitPermissionDependencies } from '../fn/permission/permission-get-storage-unit-permission-dependencies';
import { PermissionGetStorageUnitPermissionDependencies$Params } from '../fn/permission/permission-get-storage-unit-permission-dependencies';
import { permissionGetStorageUnitPermissionsByLevel } from '../fn/permission/permission-get-storage-unit-permissions-by-level';
import { PermissionGetStorageUnitPermissionsByLevel$Params } from '../fn/permission/permission-get-storage-unit-permissions-by-level';
import { StorageUnitPermission } from '../models/storage-unit-permission';

@Injectable({ providedIn: 'root' })
export class ApiPermissionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `permissionGetStorageUnitPermissionDependencies()` */
  static readonly PermissionGetStorageUnitPermissionDependenciesPath = '/permission/storage-unit/by-name/{name}/depends-on';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetStorageUnitPermissionDependencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetStorageUnitPermissionDependencies$Response(params: PermissionGetStorageUnitPermissionDependencies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StorageUnitPermission>>> {
    return permissionGetStorageUnitPermissionDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetStorageUnitPermissionDependencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetStorageUnitPermissionDependencies(params: PermissionGetStorageUnitPermissionDependencies$Params, context?: HttpContext): Observable<Array<StorageUnitPermission>> {
    return this.permissionGetStorageUnitPermissionDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StorageUnitPermission>>): Array<StorageUnitPermission> => r.body)
    );
  }

  /** Path part for operation `permissionGetStorageUnitPermissionsByLevel()` */
  static readonly PermissionGetStorageUnitPermissionsByLevelPath = '/permission/storage-unit/by-level/{level}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetStorageUnitPermissionsByLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetStorageUnitPermissionsByLevel$Response(params: PermissionGetStorageUnitPermissionsByLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<StorageUnitPermission>>> {
    return permissionGetStorageUnitPermissionsByLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetStorageUnitPermissionsByLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetStorageUnitPermissionsByLevel(params: PermissionGetStorageUnitPermissionsByLevel$Params, context?: HttpContext): Observable<Array<StorageUnitPermission>> {
    return this.permissionGetStorageUnitPermissionsByLevel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<StorageUnitPermission>>): Array<StorageUnitPermission> => r.body)
    );
  }

  /** Path part for operation `permissionGetAllStorageUnitPermissionsWithDependencies()` */
  static readonly PermissionGetAllStorageUnitPermissionsWithDependenciesPath = '/permission/storage-unit/all-with-dependencies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetAllStorageUnitPermissionsWithDependencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetAllStorageUnitPermissionsWithDependencies$Response(params?: PermissionGetAllStorageUnitPermissionsWithDependencies$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: Array<StorageUnitPermission>;
}>> {
    return permissionGetAllStorageUnitPermissionsWithDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetAllStorageUnitPermissionsWithDependencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetAllStorageUnitPermissionsWithDependencies(params?: PermissionGetAllStorageUnitPermissionsWithDependencies$Params|null|undefined, context?: HttpContext): Observable<{
[key: string]: Array<StorageUnitPermission>;
}> {
    return this.permissionGetAllStorageUnitPermissionsWithDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<StorageUnitPermission>;
}>): {
[key: string]: Array<StorageUnitPermission>;
} => r.body)
    );
  }

  /** Path part for operation `permissionGetFilePermissionDependencies()` */
  static readonly PermissionGetFilePermissionDependenciesPath = '/permission/file/by-name/{name}/depends-on';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetFilePermissionDependencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetFilePermissionDependencies$Response(params: PermissionGetFilePermissionDependencies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FilePermission>>> {
    return permissionGetFilePermissionDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetFilePermissionDependencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetFilePermissionDependencies(params: PermissionGetFilePermissionDependencies$Params, context?: HttpContext): Observable<Array<FilePermission>> {
    return this.permissionGetFilePermissionDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FilePermission>>): Array<FilePermission> => r.body)
    );
  }

  /** Path part for operation `permissionGetFilePermissionsByLevel()` */
  static readonly PermissionGetFilePermissionsByLevelPath = '/permission/file/by-level/{level}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetFilePermissionsByLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetFilePermissionsByLevel$Response(params: PermissionGetFilePermissionsByLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FilePermission>>> {
    return permissionGetFilePermissionsByLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetFilePermissionsByLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetFilePermissionsByLevel(params: PermissionGetFilePermissionsByLevel$Params, context?: HttpContext): Observable<Array<FilePermission>> {
    return this.permissionGetFilePermissionsByLevel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FilePermission>>): Array<FilePermission> => r.body)
    );
  }

  /** Path part for operation `permissionGetAllFilePermissionsWithDependencies()` */
  static readonly PermissionGetAllFilePermissionsWithDependenciesPath = '/permission/file/all-with-dependencies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetAllFilePermissionsWithDependencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetAllFilePermissionsWithDependencies$Response(params?: PermissionGetAllFilePermissionsWithDependencies$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: Array<FilePermission>;
}>> {
    return permissionGetAllFilePermissionsWithDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetAllFilePermissionsWithDependencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetAllFilePermissionsWithDependencies(params?: PermissionGetAllFilePermissionsWithDependencies$Params|null|undefined, context?: HttpContext): Observable<{
[key: string]: Array<FilePermission>;
}> {
    return this.permissionGetAllFilePermissionsWithDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<FilePermission>;
}>): {
[key: string]: Array<FilePermission>;
} => r.body)
    );
  }

  /** Path part for operation `permissionGetDocumentPermissionDependencies()` */
  static readonly PermissionGetDocumentPermissionDependenciesPath = '/permission/document/by-name/{name}/depends-on';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetDocumentPermissionDependencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetDocumentPermissionDependencies$Response(params: PermissionGetDocumentPermissionDependencies$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentPermission>>> {
    return permissionGetDocumentPermissionDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetDocumentPermissionDependencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetDocumentPermissionDependencies(params: PermissionGetDocumentPermissionDependencies$Params, context?: HttpContext): Observable<Array<DocumentPermission>> {
    return this.permissionGetDocumentPermissionDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentPermission>>): Array<DocumentPermission> => r.body)
    );
  }

  /** Path part for operation `permissionGetDocumentPermissionsByLevel()` */
  static readonly PermissionGetDocumentPermissionsByLevelPath = '/permission/document/by-level/{level}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetDocumentPermissionsByLevel()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetDocumentPermissionsByLevel$Response(params: PermissionGetDocumentPermissionsByLevel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DocumentPermission>>> {
    return permissionGetDocumentPermissionsByLevel(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetDocumentPermissionsByLevel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetDocumentPermissionsByLevel(params: PermissionGetDocumentPermissionsByLevel$Params, context?: HttpContext): Observable<Array<DocumentPermission>> {
    return this.permissionGetDocumentPermissionsByLevel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DocumentPermission>>): Array<DocumentPermission> => r.body)
    );
  }

  /** Path part for operation `permissionGetAllDocumentPermissionsWithDependencies()` */
  static readonly PermissionGetAllDocumentPermissionsWithDependenciesPath = '/permission/document/all-with-dependencies';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `permissionGetAllDocumentPermissionsWithDependencies()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetAllDocumentPermissionsWithDependencies$Response(params?: PermissionGetAllDocumentPermissionsWithDependencies$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: Array<DocumentPermission>;
}>> {
    return permissionGetAllDocumentPermissionsWithDependencies(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `permissionGetAllDocumentPermissionsWithDependencies$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  permissionGetAllDocumentPermissionsWithDependencies(params?: PermissionGetAllDocumentPermissionsWithDependencies$Params|null|undefined, context?: HttpContext): Observable<{
[key: string]: Array<DocumentPermission>;
}> {
    return this.permissionGetAllDocumentPermissionsWithDependencies$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: Array<DocumentPermission>;
}>): {
[key: string]: Array<DocumentPermission>;
} => r.body)
    );
  }

}
