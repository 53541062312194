import {EpdzErrorsOperationValidators} from './epdz-errors.validators';
import {EpdzErrorDto} from '|api/sad';
import {ButtonDisablerFn, Guard} from '../../essentials/button-collection/button-collection.component';
import {CommonToolbarDisablers} from '../document-toolbar/services/toolbar-common.disablers';

export class EpdzErrorsToolbarDisablers {

  static isMessageWithoutSender(selection: Nullable<EpdzErrorDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EpdzErrorsOperationValidators.isMessageWithoutSender());
    };
  }

  @Guard()
  static isMessageNotEmailType(selection: Nullable<EpdzErrorDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EpdzErrorsOperationValidators.isMessageNotEmailType());
    };
  }

}
