/* tslint:disable */
/* eslint-disable */

/**
 * Settlement type
 */
export enum SettlementType {
  BY_ACKNOWLEDGEMENT = 'BY_ACKNOWLEDGEMENT',
  BY_RECORD = 'BY_RECORD',
  BY_DOCUMENT = 'BY_DOCUMENT',
  BY_ASSIGNMENT = 'BY_ASSIGNMENT'
}
