/* tslint:disable */
/* eslint-disable */

/**
 * Type of ISSD access.
 */
export enum IssdAccessType {
  NONE = 'NONE',
  BASIC = 'BASIC',
  DIGEST = 'DIGEST',
  CERTIFICATE = 'CERTIFICATE'
}
