/* eslint-disable @angular-eslint/directive-class-suffix */
import {DestroyRef, Directive, EventEmitter, inject, Input, Output} from '@angular/core';
import {IczFormControl} from './icz-form-controls';
import {DebugLoggingService} from '../../core/services/debug-logging.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Directive()
export abstract class FormField<T> {
  // these common properties should be implemented
  // by every subclass of FormField...
  @Input() label!: string;
  @Input() placeholder!: string;
  @Input() rightLabel?: Nullable<string>;
  @Input() rightLabelTooltip?: Nullable<string>;
  @Input() showRightLabelPopupOnClick = false;

  // these @Inputs and @Outputs are required by
  // FormFieldValueAccessorDirective to function properly
  @Input()
  set value(newValue: Nullable<T>) {
    this._value = newValue;
  }
  get value(): Nullable<T> {
    return this._value;
  }
  @Input()
  set fieldDisabled(newValue: Nullable<boolean>) { // not just [disabled] cause it clashes with another directive from ReactiveFormsModule
    this._fieldDisabledFromTemplate = newValue;
  }
  get fieldDisabled(): Nullable<boolean> {
    return this._fieldDisabledFromTemplate ?? this._fieldDisabledFromReactiveModel;
  }
  @Output() valueChange = new EventEmitter<Nullable<T>>();
  @Output() blur = new EventEmitter<void>();

  // used mainly for debug logging
  @Input()
  formControlName!: string;

  _value: Nullable<T> = null;

  _fieldDisabledFromTemplate: Nullable<boolean>;
  _fieldDisabledFromReactiveModel: Nullable<boolean>;
  _isPasswordField: Nullable<boolean>;

  control!: IczFormControl;
  onControlAssigned: Nullable<() => void>;

  private _debugLogger = inject(DebugLoggingService);
  private _destroyRef = inject(DestroyRef);

  constructor() {
    this.blur.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(
      _ => {
        if (!this._isPasswordField) {
          this._debugLogger.logUserInteraction({description: `Input '${this.label}', hodnota: ${String(this._value)}`});
        }
      }
    );
  }

  // @ts-ignore
  abstract _valueChanged($event);
}

export type PrimitiveControlValueType = Nullable<string|number|boolean|Array<string|number|boolean>>;

@Directive()
export abstract class PrimitiveValueFormField<T extends PrimitiveControlValueType = PrimitiveControlValueType> extends FormField<T> {}
