<div class="ellipsis-element" [style.maxHeight.px]="heightLimit">
  <div class="content" #contentEl>
    {{ contents }}
    @if (isExpanded) {
      <a iczLinkWithoutHref (click)="expanderClicked()">
        {{ 'Méně' | translate }}
      </a>
    }
  </div>
  <div class="expander">
    @if (!isExpanded && isContentExpandable(contentEl)) {
      <a iczLinkWithoutHref (click)="expanderClicked()">
        {{ 'Více' | translate }}
      </a>
    }
  </div>
</div>
