/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExternalNotificationAppendixConfigurationDto } from '../../models/external-notification-appendix-configuration-dto';
import { ExternalNotificationAppendixConfigurationUpdateDto } from '../../models/external-notification-appendix-configuration-update-dto';

export interface ExternalNotificationAppendixConfigurationUpdate$Params {

/**
 * Id of to be updated external notification appendix configuration
 */
  id: number;
      body: ExternalNotificationAppendixConfigurationUpdateDto
}

export function externalNotificationAppendixConfigurationUpdate(http: HttpClient, rootUrl: string, params: ExternalNotificationAppendixConfigurationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>> {
  const rb = new RequestBuilder(rootUrl, externalNotificationAppendixConfigurationUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExternalNotificationAppendixConfigurationDto>;
    })
  );
}

externalNotificationAppendixConfigurationUpdate.PATH = '/notification/external-notification-appendix-configuration/{id}';