import {inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CheckUnsavedFormsCollection, FormGroupPropertiesOf} from './check-unsaved-forms-collection.interface';
import {
  hasUnsavedChanges,
  IFormGroupCheckable,
  IFormGroupCheckableWithInitialValue,
  isIFormGroupCheckableWithInitialValue
} from '../../lib/form-group-checks';
import {AnyComponent} from '../../model';
import {guardUnsavedForm} from '../../core/guards/unsaved-form.guard';
import {DialogService} from '../../core/services/dialog.service';

/**
 * This service should be component-provided
 */
@Injectable()
export class CheckUnsavedFormPageService implements CheckUnsavedFormsCollection {

  private activatedRoute = inject(ActivatedRoute);
  private dialogService = inject(DialogService);

  component!: IFormGroupCheckable | IFormGroupCheckableWithInitialValue;

  addUnsavedFormCheck<T extends IFormGroupCheckable>(component: T, formGroupsToCheck: Array<keyof FormGroupPropertiesOf<T>>) {
    this.component = component;
    this.component.formGroupsToCheck = formGroupsToCheck as string[];

    if (this.activatedRoute?.routeConfig?.canDeactivate) {
      const deactivationGuards = this.activatedRoute.routeConfig.canDeactivate;

      if (!deactivationGuards.includes(guardUnsavedForm())) {
        deactivationGuards.push(guardUnsavedForm());
      }
    }
    else {
      this.activatedRoute.routeConfig!.canDeactivate = [guardUnsavedForm()];
    }
  }

  removeUnsavedFormCheck<T extends AnyComponent>(formGroupsToRemoveCheck: Array<keyof FormGroupPropertiesOf<T>>) {
    this.component.formGroupsToCheck = this.component.formGroupsToCheck.filter(item => !(formGroupsToRemoveCheck as string[]).includes(item));
  }

  resetFormValueIfConfirmed() {
    if (hasUnsavedChanges(this.component)) {
      this.dialogService.openQuestionDialogWithAnswer({
        title: 'Zrušení úprav',
        question: 'Zrušení úprav',
        description: 'Chcete vzít zpět právě vepsané úpravy?',
        leftButtonTitle: 'Ano',
        rightButtonTitle: 'Ne',
      }).subscribe(
        result => {
          if (isIFormGroupCheckableWithInitialValue(this.component)) {
            if (result) {
              this.component.formGroupsToCheck.forEach(formName => {
                // @ts-ignore -- purely runtime characteristic, can't be typed ahead of time
                this.component[formName].reset((this.component as IFormGroupCheckableWithInitialValue).initialFormValue[formName]);
              });
            }
          } else {
            throw new Error('Using resetFormValueIfConfirmed method without IFormGroupCheckableWithInitialValue interface');
          }
        }
      );
    }
  }
}
