import {HttpErrorResponse} from '@angular/common/http';
import {CirculationActivityType} from '|api/commons';
import {DocumentDto, FileDto} from '|api/document';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {InternalNotificationKey} from '|api/notification';
import {EsslComponentDto} from '../services/essl-component-search.service';
import {
  getLatestDigitalComponentVersion,
  isDigitalComponent,
  isEsslComponent,
  isFileObject
} from '../components/shared-business-components/shared-document.utils';
import {getToastHeaderTemplateTranslateKey, MessageType, ToastService} from '../components/notifications/toast.service';
import {getErrorMessageFromErrorDto} from '../core/error-handling/http-errors';
import {
  OrganizationalStructureOption,
  OrganizationalUnitOptionData,
} from '../core/services/organizational-structure.service';
import {
  ExtendedCirculationActivityDto,
  ExtendedCirculationTaskDto,
  isExtendedDocumentActivityDto,
  isExtendedDocumentTaskDto
} from '../components/shared-business-components/model/elastic-extended-entities.interface';
import {TranslateService} from '@ngx-translate/core';
import {IczModalRef} from '../services';

/**
 * Should be used as curried function with a bind like this:
 *
 * anObservable.subscribe({
 *   next: ...,
 *   error: handleCirculationErrorAsToast(DocumentCirculationActivityType.STATEMENT, this.initEntity).bind(this), <-- no call operator with exception passed
 * });
 */
export function handleCirculationErrorAsToast(
  entityContext: DocumentDto | FileDto | EsslComponentDto | ExtendedCirculationActivityDto | ExtendedCirculationTaskDto,
  httpErrorResponse: HttpErrorResponse,
  toastService: ToastService,
  translateService: TranslateService,
  modalRef?: IczModalRef<any>,
  activityType?: CirculationActivityType,
) {
  let template: string;
  let templateContext: Partial<Record<InternalNotificationKey, any>>;

  if (isFileObject(entityContext)) {
    template = getToastHeaderTemplateTranslateKey('FLOW_ERROR_FILE');
    templateContext = {
      [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityType}`,
      [InternalNotificationKey.REF_NUMBER]: entityContext.refNumber,
      [InternalNotificationKey.FILE_SUBJECT]: entityContext.subject,
      [InternalNotificationKey.FILE_ID]: entityContext.id,
    };
  }
  else if (isEsslComponent(entityContext)) {
    if (isDigitalComponent(entityContext)) {
      const lastComponentVersion = getLatestDigitalComponentVersion(entityContext)!;

      template = getToastHeaderTemplateTranslateKey('FLOW_ERROR_COMPONENT_DIGITAL');
      templateContext = {
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityType}`,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: lastComponentVersion?.fileName ?? entityContext.label,
        [InternalNotificationKey.DOCUMENT_ID]: entityContext.documentId,
        [InternalNotificationKey.ESSL_COMPONENT_ID]: entityContext.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: lastComponentVersion?.id!,
      };
    }
    else {
      template = getToastHeaderTemplateTranslateKey('FLOW_ERROR_COMPONENT_NONDIGITAL');
      templateContext = {
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityType}`,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: entityContext.label,
        [InternalNotificationKey.DOCUMENT_ID]: entityContext.documentId,
        [InternalNotificationKey.ESSL_COMPONENT_ID]: entityContext.id!,
      };
    }
  }
  else {
    templateContext = {
      [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityType}`,
      [InternalNotificationKey.REF_NUMBER]: entityContext.refNumber,
      [InternalNotificationKey.DOCUMENT_SUBJECT]: entityContext.subject,
    };

    if (isExtendedDocumentTaskDto(entityContext)) {
      template = getToastHeaderTemplateTranslateKey('FLOW_ERROR_TASK');
      templateContext[InternalNotificationKey.DOCUMENT_ID] = entityContext.documentId;
      templateContext[InternalNotificationKey.TASK_ID] = entityContext.id;
    }
    else if (isExtendedDocumentActivityDto(entityContext)) {
      template = getToastHeaderTemplateTranslateKey('FLOW_ERROR_ACTIVITY');
      templateContext[InternalNotificationKey.DOCUMENT_ID] = entityContext.documentId;
      templateContext[InternalNotificationKey.ACTIVITY_ID] = entityContext.id;
    }
    else { // DocumentDto
      template = getToastHeaderTemplateTranslateKey('FLOW_ERROR_DOCUMENT');
      templateContext[InternalNotificationKey.DOCUMENT_ID] = entityContext.id;
    }
  }

  const errorMessage = getErrorMessageFromErrorDto(translateService, httpErrorResponse.error);

  toastService.dispatchToast({
    type: MessageType.ERROR,
    data: {
      header: {
        template,
        templateData: {
          ...templateContext,
          [InternalNotificationKey.REASON]: errorMessage,
        },
      },
    }
  });

  if (modalRef) {
    modalRef.forceClose(null);
  }
}

export function isTaskEntity(entity: DocumentDto | FileDto | CirculationTaskDto | CirculationActivityDto | ExtendedCirculationTaskDto | ExtendedCirculationActivityDto): entity is CirculationTaskDto {
  return entity.hasOwnProperty('activityId') &&
    entity.hasOwnProperty('assignmentDate') &&
    entity.hasOwnProperty('deadline');
}

export function getRelatedOrgStructureOptionIds(
  this: {orgStructureOptions: OrganizationalStructureOption[]},
  values: Array<string | number>,
): Array<string | number> {
  const selectedOptions = this.orgStructureOptions
    .filter(o => o.value && values.includes(o.value));

  for (const selectedOption of selectedOptions) {
    const selectedOptionType = selectedOption.originId as 'fp' | 'ou';

    if (selectedOptionType === 'fp') {
      const parentRepresentedOu = this.orgStructureOptions.find(o => (
        o.originId === 'ou' &&
        (o.data as OrganizationalUnitOptionData)?.defaultFunctionalPositionId === selectedOption.id
      ));

      if (parentRepresentedOu) {
        values.push(parentRepresentedOu.value!);
      }
    }
    else if (selectedOptionType === 'ou') {
      const representingFp = this.orgStructureOptions.find(o => (
        o.originId === 'fp' &&
        o.id === (selectedOption.data as OrganizationalUnitOptionData)?.defaultFunctionalPositionId
      ));

      if (representingFp) {
        values.push(representingFp.value!);
      }
    }
  }

  return values;
}
