import {ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef} from '@angular/core';
import {TRUNCATED_TEXT_TOOLTIP_DELAY} from '../../../../essentials/tooltip.directive';
import {IAutocompleteListItemContext} from '../../form-autocomplete.model';
import {Option} from '../../../../../model';

@Component({
  selector: 'icz-option-item',
  templateUrl: './option-item.component.html',
  styleUrls: ['./option-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OptionItemComponent<T> {
  @HostBinding('class.disabled')
  get isOptionDisabled() {
    return this.option?.disabled;
  }
  @HostBinding('class.separator')
  get isOptionSeparator() {
    return this.option?.isSeparator;
  }

  @Input()
  option!: Option<T>;
  @Input()
  @HostBinding('class.selected')
  isSelected = false;
  @Input()
  isMultiselect: Nullable<boolean> = false;
  @Input()
  listItemTemplate: Nullable<TemplateRef<IAutocompleteListItemContext>>;
  @Input()
  hideDefaultTooltip = false;

  get optionTooltip() {
    if (this.hideDefaultTooltip || this.option?.isSeparator) {
      return null;
    }
    else {
      return this.option?.disabled && this.option?.disableReason ?
        this.option?.disableReason :
        this.option?.label;
    }
  }

  readonly TRUNCATED_TEXT_TOOLTIP_DELAY = TRUNCATED_TEXT_TOOLTIP_DELAY;
}
