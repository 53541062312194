<div class="row items-center"
  [iczTooltip]="optionTooltip"
  [iczTooltipShowDelay]="option.disableReason ? 500 : TRUNCATED_TEXT_TOOLTIP_DELAY">

  @if (isMultiselect && !option.isSeparator) {
    <icz-checkbox class="pointer-events-none" [checked]="isSelected"></icz-checkbox>
  }

  <ng-container [ngTemplateOutlet]="listItemTemplate ?? defaultListItemTemplate"
    [ngTemplateOutletContext]="{$implicit: {option: option}}">
  </ng-container>

</div>

<ng-template #defaultListItemTemplate let-context>
  <icz-form-autocomplete-list-text-item class="w-full" [option]="context.option">
  </icz-form-autocomplete-list-text-item>
</ng-template>
