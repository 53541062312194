import {Pipe, PipeTransform} from '@angular/core';
import {
  Highlight,
  HighlightFragment
} from '../document-table/components/document-relevance-details/document-relevance-details.component';

@Pipe({
  name: 'highlightFragmentForField',
  pure: true,
})
export class HighlightFragmentForFieldPipe implements PipeTransform {

  transform(rowHighlights: Nullable<Highlight[]>, columnName: Nullable<string>): Nullable<HighlightFragment> {
    return rowHighlights?.find(h => h.field === columnName)?.fragments?.[0];
  }

}
