<div>
  @if (correctObjectsList.length === 0) {
    <icz-alert
      heading="Žádný z vybraných objektů není validní pro pokračování ve vybrané hromadné operaci."
    severity="error" class="mb-16"></icz-alert>
  }
  @else {
    @if (validationResultToDisplay.length > 0) {
      <icz-alert
        [heading]="modalData.dialogWarningLabel"
        [textContext]="modalData.dialogWarningLabelContext"
      severity="warning" class="mb-16"></icz-alert>
    }
  }
  <div [waiting]="loadingService.isLoading$(this) | async">
    @for (result of validationResultToDisplay; track result) {
      <div class="validation-result mb-16">
        <div class="row mb-8 items-center">
          @if (result.esslObjectIcon) {
            <icz-icon size="small" [svgIcon]="result.esslObjectIcon"></icz-icon>
          }
          <div class="font-bold">{{ result.esslObjectLabel }}</div>
        </div>
        @for (error of result.validationErrors; track error) {
          <div class="row mb-4 pl-24 items-center">
            <icz-alert severity="info" alertMode="compact" [label]="error.validationMessage!" [textContext]="error.validationMessageContext"></icz-alert>
          </div>
        }
      </div>
    }
  </div>
</div>
<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this) || (correctObjectsList.length === 0)"
  (onAction)="submit()" primary label="Pokračovat"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
  (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
