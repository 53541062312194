import {Component} from '@angular/core';
import {ApplicationRoute} from '../../../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../../../enums/documents-routes.enum';
import {createAbsoluteRoute} from '../../../../../core/routing/routing.helpers';
import {InterpolationContext} from '../../../../../lib/model';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';

export interface BulkOperationErrorsDialogData {
  errors: BulkOperationError[];
  showConfirmButton: boolean;
  warningMessage?: Nullable<string>;
  warningMessageContext?: Nullable<InterpolationContext>;
}

export interface BulkOperationError {
  errorMessage: string;
  entity: {
    name: string;
  };
}

@Component({
  selector: 'icz-bulk-operation-errors-dialog',
  templateUrl: './bulk-operation-errors-dialog.component.html',
  styleUrls: ['./bulk-operation-errors-dialog.component.scss']
})
export class BulkOperationErrorsDialogComponent {

  protected modalRef = injectModalRef<boolean>();
  protected modalData = injectModalData<BulkOperationErrorsDialogData>();

  readonly ApplicationRoute = ApplicationRoute;
  readonly DocumentsRoute = DocumentsRoute;
  readonly createAbsoluteRoute = createAbsoluteRoute;

  confirm() {
    this.modalRef.close(true);
  }

  cancel() {
    this.modalRef.close(false);
  }

}
