import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LoginPageComponent} from './login/login.page';
import {PlaceSelectionPageComponent} from './place-selection/place-selection.page';
import {LoginLanguageSelectorComponent} from './login-language-selector/login-language-selector.component';
import {EnvironmentInfoComponent} from './environment-info/environment-info.component';
import {VersionInfoComponent} from './version-info/version-info.component';
import {FormElementsModule} from '../form-elements/form-elements.module';
import {EssentialsModule} from '../essentials/essentials.module';


@NgModule({
  declarations: [
    LoginPageComponent,
    PlaceSelectionPageComponent,
    LoginLanguageSelectorComponent,
    EnvironmentInfoComponent,
    VersionInfoComponent,
  ],
  exports: [
    LoginPageComponent,
    PlaceSelectionPageComponent,
    LoginLanguageSelectorComponent,
    EnvironmentInfoComponent,
    VersionInfoComponent,
  ],
  imports: [
    CommonModule,
    FormElementsModule,
    EssentialsModule,
  ]
})
export class LoginScreensModule {}
