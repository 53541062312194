/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { BusinessRuleDto } from '../../models/business-rule-dto';
import { BusinessRuleKey } from '|api/commons';
import { BusinessRuleName } from '|api/commons';

export interface BusinessRulesFindRuleByNameAndKey$Params {

/**
 * Name of business rule
 */
  name: BusinessRuleName;

/**
 * Business rule key
 */
  key: BusinessRuleKey;

/**
 * Value of business rule key
 */
  keyValue: string;
}

export function businessRulesFindRuleByNameAndKey(http: HttpClient, rootUrl: string, params: BusinessRulesFindRuleByNameAndKey$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRuleDto>> {
  const rb = new RequestBuilder(rootUrl, businessRulesFindRuleByNameAndKey.PATH, 'get');
  if (params) {
    rb.path('name', params.name, {});
    rb.path('key', params.key, {});
    rb.query('keyValue', params.keyValue, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<BusinessRuleDto>;
    })
  );
}

businessRulesFindRuleByNameAndKey.PATH = '/codebook/business-rules/{name}/{key}/';