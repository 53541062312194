import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {convertValueToDateObject} from './date.pipe';
import {stripSecondsFromCzechDateTime, stripSecondsFromEnglishDateTime} from './time.pipe';
import {ApplicationLanguage} from '../../core/services/environment.models';


export function iczFormatDatetime(
  currentLocale: ApplicationLanguage,
  value: Nullable<string | number | Date>,
  withoutYear = false,
  withSeconds = false,
) {
  const convertedValue = convertValueToDateObject(value);

  let formattedDateTime: string;

  if (currentLocale === ApplicationLanguage.CZECH || currentLocale === ApplicationLanguage.SLOVAK) {
    formattedDateTime = convertedValue?.toLocaleString('cs-CZ') ?? '';
  }
  else {
    formattedDateTime = convertedValue?.toLocaleString('en-US') ?? '';
  }

  if (!withSeconds) {
    if (currentLocale === ApplicationLanguage.CZECH || currentLocale === ApplicationLanguage.SLOVAK) {
      formattedDateTime = stripSecondsFromCzechDateTime(formattedDateTime);
    }
    else {
      formattedDateTime = stripSecondsFromEnglishDateTime(formattedDateTime);
    }
  }

  if (withoutYear) {
    return formattedDateTime.replace(/ \d{4}/g, '');
  }
  else {
    return formattedDateTime;
  }
}

@Pipe({
  name: 'localizedDatetime',
  pure: false,
})
export class LocalizedDatetimePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * @param value - ISO datetime OR unix timestamp in milliseconds since Epoch
   * @param withoutYear - don't show year
   * @param withSeconds - show seconds
   */
  transform(value: Nullable<string | number | Date>, withoutYear = false, withSeconds = false): string {
    if (!value) { // Branch used merely for performance optimization
      return '';
    }

    return iczFormatDatetime(this.translateService.currentLang as ApplicationLanguage, value, withoutYear, withSeconds);
  }

}
