/* tslint:disable */
/* eslint-disable */

/**
 * Type of email node.
 */
export enum DistributionNodeType {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE',
  SEND_RECEIVE = 'SEND_RECEIVE'
}
