import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CodebookService} from '../../core/services/codebook.service';
import {OrganizationalStructureService} from '../../core/services/organizational-structure.service';

@Pipe({
  name: 'findFunctionalPositionById',
  pure: true,
})
export class FindFunctionalPositionByIdPipe implements PipeTransform {

  private codebookService = inject(CodebookService);
  private orgStructureService = inject(OrganizationalStructureService);

  transform(functionalPositionIds: Nullable<number[] | number>, hideMemberUserNames: boolean = false): Observable<string> {
    if (functionalPositionIds && !Array.isArray(functionalPositionIds)) {
      functionalPositionIds = [functionalPositionIds];
    }

    if (isNil(functionalPositionIds)) {
      return of('');
    }
    else {
      if (hideMemberUserNames) {
        return this.codebookService.functionalPositions().pipe(
          map(functionalPositions => {
            return functionalPositions
              .filter(fp => fp && (functionalPositionIds as number[]).includes(fp.id))
              .map(fp => fp.name)
              .join(', ');
            }
          ),
        );
      }
      else {
        return this.orgStructureService.functionalPositionsOptions().pipe(
          map(functionalPositions => {
            return functionalPositions
              .filter(fpOption => fpOption && (functionalPositionIds as number[]).includes(fpOption.value))
              .map(fp => fp.label)
              .join(', ');
            }
          ),
        );
      }
    }
  }

}
