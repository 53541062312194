import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TransactionSidebarComponent} from '../transaction-sidebar/transaction-sidebar.component';
import {TableModule} from '../../table/table.module';
import {EssentialsModule} from '../../essentials/essentials.module';
import {TransactionsComponent} from './transactions.component';

@NgModule({
  declarations: [
    TransactionSidebarComponent,
    TransactionsComponent,
  ],
  imports: [CommonModule, EssentialsModule, TableModule],
  exports: [
    TransactionSidebarComponent,
    TransactionsComponent,
  ],
  providers: [],
})
export class TransactionsModule {
}
