import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BarController, BarElement, CategoryScale, Chart, ChartData, LinearScale, Title, Tooltip} from 'chart.js';
import {GenericChartConfig} from '../statistics-model';
import {CHART_COLOR_PALETTE, ChartElementColor} from '../chart-utils';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';


const barChartTooltipFooter = (tooltipItems: any) => {
  let sum = 0;

  tooltipItems.forEach(function(tooltipItem: any) {
    sum += tooltipItem.parsed.y;
  });
  return 'Suma: ' + sum;
};


@Component({
  selector: 'icz-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BarChartComponent implements AfterViewInit, IczOnChanges {

  @Input({required: true}) config!: GenericChartConfig;

  @ViewChild('chart') private chartRef!: ElementRef;
  private chart!: Chart<any>;

  constructor() {
    Chart.register(BarController, BarElement, LinearScale, Title, CategoryScale, Tooltip);
  }

  // Setting defaults if optional dataSet config items are not present
  configToBarConfig(config: GenericChartConfig): ChartData<any> {
    const result: ChartData<any> = {...config};
    result.datasets.forEach(d => {
      if (!d.barPercentage) d.barPercentage = 0.3;
      if (!d.borderWidth) d.borderWidth = 1;
      if (!d.backgroundColor) {
        d.backgroundColor = CHART_COLOR_PALETTE;
      }
      if (!d.borderColor) {
        d.borderColor = d.data.map((v: number) => ChartElementColor.ORANGE);
      }
    });

    return result;
  }

  setNewChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'bar',
      data: this.configToBarConfig(this.config),
      options: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              footer: barChartTooltipFooter,
            }
          }
        }
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.config && !this.chart) {
      this.setNewChart();
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.config.currentValue) {
      if (this.chart) {
        this.chart.data = this.configToBarConfig(changes.config.currentValue);
        this.chart.update();
      }
      else if (this.chartRef?.nativeElement) {
        this.setNewChart();
      }
    }
  }

}
