<form class="filter-form" [hidden]="!config.showToolbar" [formGroup]="toolbarForm">
  <div class="items-center row grow main-toolbar" [hidden]="!(config.showFilter || config.showReload || config.showTools || hasTitleContent)">
    <div class="flex grow no-margin" #titleContent>
      <ng-content select="[tableTag]"></ng-content>
      <ng-content select="[tableTitle]"></ng-content>
    </div>

    <div class="grow"></div>

    <div class="flex no-margin">
      @if (config.showTools) {
        <ng-content select="[beforeToolbar]"></ng-content>
        <div #toolsContent class="no-button-border">
          <ng-content select="[tools]"></ng-content>
        </div>
      }
      @if ((config.showFilter && !config.autoOpenFilter) || config.showReload || config.showColumnSelector) {
        <div class="div-splitter"></div>
      }
      <div class="flex">
        <icz-toggle-button #filterChild svgIcon="filter" size="large"
          class="table-filter-toggler"
          [hidden]="!config.showFilter || (config.autoOpenFilter && config.filterIsStatic)"
          iczTooltip="Filtr"
          [class.active-filter-indication]="activeFilterIndication()"
        [disabled]="config.disableFilter" (onAction)="toggleFilter()"></icz-toggle-button>
        @if (config.showReload) {
          <icz-button svgIcon="refresh"
            (onAction)="reloadClicked.emit()" size="large"
            background iczTooltip="Znovu načíst"
          ></icz-button>
        }
        @if (config.showColumnSelector) {
          <icz-button svgIcon="table_settings"
                      (onAction)="columnSettingsClicked.emit()" size="large"
                      background="transparent" iczTooltip="Nastavení sloupců"
          ></icz-button>
        }
      </div>
    </div>
  </div>

  <div class="-mx-8" #tabsContent>
    <ng-content select="[tableTabs]"></ng-content>
  </div>

  @if (subToolbarOpened) {
    <div class="row items-center">
      @if (isSimpleQueryMode$ | async) {
        <div class="filter-area row flex-wrap">
          @if (config.showDimensions) {
            <icz-dimensions-toolbar [initialDimensions]="initialDimensions"
              [allowedDimensions]="allowedDimensions"
            (dimensionsChange)="dimensionsChange.emit($event)"></icz-dimensions-toolbar>
          }
          @for (item of distinctiveFilters$ | async; track item.id) {
            <icz-filter-item
              [item]="item"
              [autoOpen]="false"
              [isStatic]="true"
              [isLoading]="listLoadingStates[item.id]"
            ></icz-filter-item>
          }
          @if (config.showFulltextSearch) {
            <icz-form-field iczAutoFocus
              #searchTermInput class="search search-input"
              [class.active-filter-indication]="_searchTerm.trim().length || isSearchTermRightLength(toolbarForm.get('searchTerm')?.value)"
              formControlName="searchTerm" placeholder="Hledej">
              <icz-icon prefix svgIcon="search" size="small"></icz-icon>
            </icz-form-field>
          }
          @for (item of visibleFilters$ | async; track item.id) {
            <icz-filter-item
              [item]="item"
              [deferredFilterList]="getDeferredFilterListPerColumn(item)"
              [autoOpen]="tableToolbarService.autoOpen"
              [isStatic]="config.filterIsStatic"
              [isLoading]="listLoadingStates[item.id]"
            ></icz-filter-item>
          }
          <div [hidden]="config.filterIsStatic">
            <icz-filter-tag class="more-filters-item" cdkOverlayOrigin #moreFiltersOrigin="cdkOverlayOrigin"
              filterName="Více" (clicked)="moreFiltersPopup.toggle()">
              <icz-button suffix svgIcon="expand_more" background disableRipple disableFocus
              size="default"></icz-button>
            </icz-filter-tag>
          </div>
          <icz-table-toolbar-popup #moreFiltersPopup [popoverOrigin]="moreFiltersOrigin">
            <!--
            availableColumnFilterOptions$ | async is not inlined to [options] to prevent
            redundant subscribes because of @if (moreFiltersPopup.isOpen")
            -->
            @if (availableColumnFilterOptions$ | async; as availableColumnFilterOptions) {
              @if (moreFiltersPopup.isOpen) {
                <icz-form-autocomplete
                  class="more-filters-selector"
                  iczAutoFocus
                  [asPopover]="false"
                  [options]="availableColumnFilterOptions"
                  [value]="null"
                  (valueChange)="moreFiltersChanged($event!)"
                ></icz-form-autocomplete>
              }
            }
          </icz-table-toolbar-popup>
        </div>
      }
      @else {
        <div class="filter-area">
          <span class="icz-body-strong">
            {{ 'Vyhledávací dotaz' | translate | uppercase }}:
          </span>&nbsp;@if ((filterName$ | async); as filterName) {
          <span class="icz-body-1" [innerHTML]="filterName"></span>
        }
        @else {
          <span class="list-loading-dark inline-block w-64 u-align-sub"></span>
          }&nbsp;<a
          class="p-4 icz-body-1 visible-clickable-area"
          iczLinkWithoutHref (click)="editComplexQuery()"
        >{{ 'Upravit dotaz' | translate }}</a>
      </div>
    }
    <div class="row">
      @if (config.showSavedFilters) {
        <icz-table-saved-filters
          [tableIdForLocalStorage]="tableIdForLocalStorage"
          [allowSavingFiltersWithEmptyValues]="allowSavingFiltersWithEmptyValues"
          [defaultFilterName$]="defaultFilterName$"
        ></icz-table-saved-filters>
      }
    </div>
  </div>
}

</form>
