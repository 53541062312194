/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DistributionNodeDto } from '../models/distribution-node-dto';
import { distributionNodeOauthFindAll } from '../fn/distribution-node-oauth/distribution-node-oauth-find-all';
import { DistributionNodeOauthFindAll$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-find-all';
import { distributionNodeOauthFindById } from '../fn/distribution-node-oauth/distribution-node-oauth-find-by-id';
import { DistributionNodeOauthFindById$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-find-by-id';
import { distributionNodeOauthLinkDispatchOffice } from '../fn/distribution-node-oauth/distribution-node-oauth-link-dispatch-office';
import { DistributionNodeOauthLinkDispatchOffice$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-link-dispatch-office';
import { distributionNodeOauthLinkFilingOffice } from '../fn/distribution-node-oauth/distribution-node-oauth-link-filing-office';
import { DistributionNodeOauthLinkFilingOffice$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-link-filing-office';
import { distributionNodeOauthReloadEpdzConfiguration } from '../fn/distribution-node-oauth/distribution-node-oauth-reload-epdz-configuration';
import { DistributionNodeOauthReloadEpdzConfiguration$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-reload-epdz-configuration';
import { distributionNodeOauthSearch } from '../fn/distribution-node-oauth/distribution-node-oauth-search';
import { DistributionNodeOauthSearch$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-search';
import { distributionNodeOauthUnlinkDispatchOffice } from '../fn/distribution-node-oauth/distribution-node-oauth-unlink-dispatch-office';
import { DistributionNodeOauthUnlinkDispatchOffice$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-unlink-dispatch-office';
import { distributionNodeOauthUnlinkFilingOffice } from '../fn/distribution-node-oauth/distribution-node-oauth-unlink-filing-office';
import { DistributionNodeOauthUnlinkFilingOffice$Params } from '../fn/distribution-node-oauth/distribution-node-oauth-unlink-filing-office';
import { PageDistributionNodeDto } from '../models/page-distribution-node-dto';


/**
 * API for management of distributionnode nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiDistributionNodeOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `distributionNodeOauthUnlinkFilingOffice()` */
  static readonly DistributionNodeOauthUnlinkFilingOfficePath = '/oauth/distribution-node/{distributionNodeId}/unlink-filing-office/{filingOfficeId}';

  /**
   * Unlink distribution node from dispatch office with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthUnlinkFilingOffice()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthUnlinkFilingOffice$Response(params: DistributionNodeOauthUnlinkFilingOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return distributionNodeOauthUnlinkFilingOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlink distribution node from dispatch office with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthUnlinkFilingOffice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthUnlinkFilingOffice(params: DistributionNodeOauthUnlinkFilingOffice$Params, context?: HttpContext): Observable<void> {
    return this.distributionNodeOauthUnlinkFilingOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthUnlinkDispatchOffice()` */
  static readonly DistributionNodeOauthUnlinkDispatchOfficePath = '/oauth/distribution-node/{distributionNodeId}/unlink-dispatch-office/{dispatchOfficeId}';

  /**
   * Unlink distribution node from dispatch office with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthUnlinkDispatchOffice()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthUnlinkDispatchOffice$Response(params: DistributionNodeOauthUnlinkDispatchOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return distributionNodeOauthUnlinkDispatchOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlink distribution node from dispatch office with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthUnlinkDispatchOffice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthUnlinkDispatchOffice(params: DistributionNodeOauthUnlinkDispatchOffice$Params, context?: HttpContext): Observable<void> {
    return this.distributionNodeOauthUnlinkDispatchOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthLinkFilingOffice()` */
  static readonly DistributionNodeOauthLinkFilingOfficePath = '/oauth/distribution-node/{distributionNodeId}/link-filing-office/{filingOfficeId}';

  /**
   * Link distribution node to filing office with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthLinkFilingOffice()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthLinkFilingOffice$Response(params: DistributionNodeOauthLinkFilingOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return distributionNodeOauthLinkFilingOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Link distribution node to filing office with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthLinkFilingOffice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthLinkFilingOffice(params: DistributionNodeOauthLinkFilingOffice$Params, context?: HttpContext): Observable<void> {
    return this.distributionNodeOauthLinkFilingOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthLinkDispatchOffice()` */
  static readonly DistributionNodeOauthLinkDispatchOfficePath = '/oauth/distribution-node/{distributionNodeId}/link-dispatch-office/{dispatchOfficeId}';

  /**
   * Link distribution node to dispatch office with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthLinkDispatchOffice()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthLinkDispatchOffice$Response(params: DistributionNodeOauthLinkDispatchOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return distributionNodeOauthLinkDispatchOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Link distribution node to dispatch office with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthLinkDispatchOffice$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthLinkDispatchOffice(params: DistributionNodeOauthLinkDispatchOffice$Params, context?: HttpContext): Observable<void> {
    return this.distributionNodeOauthLinkDispatchOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthFindAll()` */
  static readonly DistributionNodeOauthFindAllPath = '/oauth/distribution-node';

  /**
   * Returns all distribution nodes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthFindAll$Response(params?: DistributionNodeOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DistributionNodeDto>>> {
    return distributionNodeOauthFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all distribution nodes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthFindAll(params?: DistributionNodeOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DistributionNodeDto>> {
    return this.distributionNodeOauthFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DistributionNodeDto>>): Array<DistributionNodeDto> => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthFindById()` */
  static readonly DistributionNodeOauthFindByIdPath = '/oauth/distribution-node/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthFindById$Response(params: DistributionNodeOauthFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DistributionNodeDto>> {
    return distributionNodeOauthFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthFindById(params: DistributionNodeOauthFindById$Params, context?: HttpContext): Observable<DistributionNodeDto> {
    return this.distributionNodeOauthFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DistributionNodeDto>): DistributionNodeDto => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthSearch()` */
  static readonly DistributionNodeOauthSearchPath = '/oauth/distribution-node/search-api';

  /**
   * Find all values in distribution nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthSearch$Response(params?: DistributionNodeOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDistributionNodeDto>> {
    return distributionNodeOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in distribution nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthSearch(params?: DistributionNodeOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageDistributionNodeDto> {
    return this.distributionNodeOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDistributionNodeDto>): PageDistributionNodeDto => r.body)
    );
  }

  /** Path part for operation `distributionNodeOauthReloadEpdzConfiguration()` */
  static readonly DistributionNodeOauthReloadEpdzConfigurationPath = '/oauth/distribution-node/reload-epdz-configuration';

  /**
   * Loads distribution nodes of type EMAIL and ISDS and updates config property "epdz.organization.channels"
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `distributionNodeOauthReloadEpdzConfiguration()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthReloadEpdzConfiguration$Response(params?: DistributionNodeOauthReloadEpdzConfiguration$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return distributionNodeOauthReloadEpdzConfiguration(this.http, this.rootUrl, params, context);
  }

  /**
   * Loads distribution nodes of type EMAIL and ISDS and updates config property "epdz.organization.channels"
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `distributionNodeOauthReloadEpdzConfiguration$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  distributionNodeOauthReloadEpdzConfiguration(params?: DistributionNodeOauthReloadEpdzConfiguration$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.distributionNodeOauthReloadEpdzConfiguration$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
