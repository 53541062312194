import {LocalStorageKey, UserSettingsService} from '../../services/user-settings.service';
import {Directive, inject, OnInit} from '@angular/core';

@Directive()
export class AbstractDashboardConnectedToSettingsComponent implements OnInit {

  private userSettingsService = inject(UserSettingsService);

  showNotificationsSection = false;
  showTasksSection = false;
  showActivitiesSection = false;
  showUserSubstitutions = false;
  showFPSubstitutions = false;

  ngOnInit() {
    this.initDashboardSettings();
  }

  initDashboardSettings() {
    const sectionsSettings = this.userSettingsService.getParsedValue(LocalStorageKey.USER_DASHBOARD_SECTIONS);

    if (sectionsSettings) {
      this.showNotificationsSection = sectionsSettings.notifications;
      this.showTasksSection = sectionsSettings.myTasks;
      this.showActivitiesSection = sectionsSettings.activities;
      this.showUserSubstitutions = sectionsSettings.userSubstitutions;
      this.showFPSubstitutions = sectionsSettings.fpSubstitutions;
    }
    else {
      this.showNotificationsSection = true;
      this.showTasksSection = true;
      this.showActivitiesSection = true;
      this.showUserSubstitutions = true;
      this.showFPSubstitutions = true;
    }
  }

}
