import {Directive, inject} from '@angular/core';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {
  AbstractTableWithSelectorComponent,
  AbstractTableWithTabsAndSelectorComponent,
  TableViewMetadata,
  TableWithTabsViewMetadata
} from '../abstract-table-with-tabs-and-selector.component';
import {combineLatest} from 'rxjs';
import {CodebookService} from '../../../core/services/codebook.service';
import {map} from 'rxjs/operators';
import {namedDtoToOption} from '../../../core/services/data-mapping.utils';

export const UNIT_VIEW_QUERY_PARAM_NAME = 'orgUnitId';

function getUnitViewSelectorOptions(codebookService: CodebookService) {
  return combineLatest([
    codebookService.organizationalUnits(),
    codebookService.orgUnitsToLoggedInFp(),
  ]).pipe(
    map(([units, accessibleOrgUnitIds]) => {
      const accessibleUnits = units.filter(u => accessibleOrgUnitIds.includes(u.id));

      return [
        {value: null, label: 'Všechny útvary'},
        ...accessibleUnits.map(namedDtoToOption()),
      ];
    }),
  );
}

function isUnitViewByUrl(url: string) {
  return url.replace(/\?.+$/, '').startsWith(`/${ApplicationRoute.UNIT}`);
}

@Directive()
export abstract class AbstractUnitViewComponent<TViewMetadata extends TableViewMetadata = TableViewMetadata> extends AbstractTableWithSelectorComponent<TViewMetadata> {

  protected codebookService = inject(CodebookService);

  isUnitView = isUnitViewByUrl(this.router.url);

  protected selectorQueryParamName = UNIT_VIEW_QUERY_PARAM_NAME;
  protected selectorOptions$ = getUnitViewSelectorOptions(this.codebookService);

}

@Directive()
export abstract class AbstractUnitViewWithTabsComponent<TView extends string, TViewMetadata extends TableWithTabsViewMetadata<TView>> extends AbstractTableWithTabsAndSelectorComponent<TView, TViewMetadata> {

  protected codebookService = inject(CodebookService);

  isUnitView = isUnitViewByUrl(this.router.url);

  protected selectorQueryParamName = UNIT_VIEW_QUERY_PARAM_NAME;
  protected selectorOptions$ = getUnitViewSelectorOptions(this.codebookService);

}
