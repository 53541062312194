/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentDigestRequest } from '../../models/document-digest-request';
import { DocumentDigestResponse } from '../../models/document-digest-response';

export interface SignatureDigestDocument$Params {

/**
 * ID of document to digest
 */
  documentId: number;
      body: DocumentDigestRequest
}

export function signatureDigestDocument(http: HttpClient, rootUrl: string, params: SignatureDigestDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentDigestResponse>> {
  const rb = new RequestBuilder(rootUrl, signatureDigestDocument.PATH, 'post');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentDigestResponse>;
    })
  );
}

signatureDigestDocument.PATH = '/document/signature/{documentId}/digest';