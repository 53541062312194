// case-insensitive and insensitive to data type differences
import {collateStrings} from './string-collation';
import {FilterItem, FilterType} from './filter.types';
import {FilterOperator, FilterParam, isNoValueOperator, SortParam} from '../../services/search-api.service';
import {get} from 'lodash';

const filterFunctors = {
  eq: (arg: string, str: string) => arg.toLowerCase() === String(str).toLowerCase(),
  ne: (arg: string, str: string) => arg.toLowerCase() !== String(str).toLowerCase(),
  contains: (arg: string, str: string) => {
    const escapedRegex = getEscapedRegex(arg.toLowerCase());
    return (new RegExp(`.*${escapedRegex}.*`)).test(String(str).toLowerCase());
  },
  doesNotContains: (arg: string, str: string) => {
    return !filterFunctors.contains(arg, str);
  },
  startsWith: (arg: string, str: string) => {
    const escapedRegex = getEscapedRegex(arg.toLowerCase());
    return (new RegExp(`^${escapedRegex}.*`)).test(String(str).toLowerCase());
  },
  endsWith: (arg: string, str: string) => {
    const escapedRegex = getEscapedRegex(arg.toLowerCase());
    return (new RegExp(`.*${escapedRegex}$`)).test(String(str).toLowerCase());
  },
  lt: (arg: string, str: string) => Number(str) < Number(arg),
  lte: (arg: string, str: string) => Number(str) <= Number(arg),
  gt: (arg: string, str: string) => Number(str) > Number(arg),
  gte: (arg: string, str: string) => Number(str) >= Number(arg),
  in: (arg: string, str: string) => arg.split(',').includes(str),
  empty: (_: unknown, str: string) => {
    return !str;
  },
  notEmpty: (_: unknown, str: string) => {
    return str;
  },
};
const sortFunctors = {
  asc: (fieldName: string, arr: any[]) => {
    arr.sort(fieldNameComparator(fieldName, false));
    return arr;
  },
  desc: (fieldName: string, arr: any[]) => {
    arr.sort(fieldNameComparator(fieldName, true));
    return arr;
  },
};

function fieldNameComparator(fieldName: string, invert: boolean) {
  return (x: Record<string, any>, y: Record<string, any>) => {
    const normalizedX = get(x, fieldName).toString().toLowerCase();
    const normalizedY = get(y, fieldName).toString().toLowerCase();

    if (normalizedX === normalizedY) {
      return 0;
    }
    else {
      const fieldRelation = collateStrings(normalizedX, normalizedY);

      if (!fieldRelation) {
        return invert ? 1 : -1;
      }
      else {
        return invert ? -1 : 1;
      }
    }
  };
}

function getEscapedRegex(reString: string) {
  return reString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function filterAndSortArray(
  array: Record<string, unknown>[],
  filter: Nullable<FilterParam[]>,
  fulltextSearchTerm?: Nullable<string>,
  sort?: Nullable<SortParam<string>[]>,
) {
  if (fulltextSearchTerm?.trim()) {
    array = array.filter(item => {
      return Object.values(item)
      .map(item => String(item))
      .some(item => item.toLowerCase().includes(fulltextSearchTerm.toLowerCase()));
    });
  }

  if (filter) {
    for (const filterParam of filter) {
      array = array.filter(
        item => {
          const propertyValue = item[filterParam.fieldName];

          const criterionResult = filterFunctors[filterParam.operator!](
            filterParam.value!,
            propertyValue ? String(propertyValue) : ''
          );
          return filterParam.not ? !criterionResult : criterionResult;
        }
      );
    }
  }

  let sortedTableData = array;

  if (sort) {
    for (const sortParam of sort) {
      const functorName = (sortParam.descending ? 'desc' : 'asc');
      sortedTableData = sortFunctors[functorName](sortParam.fieldName, sortedTableData);
    }
  }

  return sortedTableData;
}

export function convertViewFiltersToDataFilters(filterItems: FilterItem[]): FilterParam[] {
  return filterItems.flatMap(f => {
    const filterOperator = f.filterOption!.value;

    if (Array.isArray(f.value) && f.value.length) {
      const ids = f.value;
      const filterParam: FilterParam = {
        fieldName: f.id,
        operator: FilterOperator.inSet,
        value: ids.toString(),
      };

      if (filterOperator === FilterOperator.notEquals) {
        filterParam.not = true;
      }

      return filterParam;
    }
    else {
      const filterParam: FilterParam = {
        fieldName: f.id,
        operator: filterOperator as FilterOperator,
        value: isNil(f.value) ? '' : String(f.value),
      };

      if (f.filterType === FilterType.TEXT && !isNoValueOperator(filterOperator)) {
        filterParam.isCaseInsensitive = true;
      }

      if (filterOperator === FilterOperator.doesNotContains) {
        filterParam.not = true;
        filterParam.operator = FilterOperator.contains;
      }

      if (f.subValues?.length) {
        const expandedSubValues: FilterParam[] = [];

        for (const subValue of f.subValues) {
          expandedSubValues.push({
            ...filterParam,
            fieldName: subValue.subValueId ? subValue.subValueId : f.id,
            and: subValue.and,
            operator: subValue.operator,
            value: subValue.value,
          });
        }

        return expandedSubValues;
      }
      else {
        return filterParam;
      }
    }
  });
}
