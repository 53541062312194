/* tslint:disable */
/* eslint-disable */

/**
 * Incoming EPDZ message type
 */
export enum EpdzMessageType {
  EMAIL = 'EMAIL',
  DATA_BOX = 'DATA_BOX',
  DATA_BOX_PERSONAL_DELIVERY = 'DATA_BOX_PERSONAL_DELIVERY'
}
