<icz-validation-errors-list [control]="control" [showValidationStatus]="showValidationStatus && asPopover">
  <icz-composable-form-autocomplete
    #composableFormAutocomplete
    [value]="__value"
    (valueChange)="_valueChanged($event)"
    (blur)="blur.emit()"
    [label]="label"
    [placeholder]="placeholder"
    [options]="_visibleOptions"
    [disabled]="fieldDisabled"
    [clearable]="clearable"
    [required]="required"
    [autoSizeMax]="autoSizeMax"
    [isMultiselect]="isMultiselect"
    [asPopover]="asPopover"
    [searchIndexer]="searchIndexer"
    [minSearchTermLength]="minSearchTermLength"
    [optionsDefinitionFactory]="optionsDefinitionFactory"
    [popoverContentTemplate]="popoverContentTemplate"
    [selectedOptionTemplate]="selectedOptionTemplate"
    [rightLabel]="rightLabel"
    [showRightLabelPopupOnClick]="showRightLabelPopupOnClick"
    [allowLoadingIndicator]="allowLoadingIndicator">

    <ng-template #selectedOptionTemplate let-context>
      @if (customSelectedOptionTemplate) {
        <!--
        In Angular 16, context.selectedOptions has wrong inferred type thus using $any.
        todo(rb) try to remove $any after upgrading to another version of Angular.
        -->
        @for (so of context.selectedOptions; track so.value) {
          <ng-container
            [ngTemplateOutlet]="$any(customSelectedOptionTemplate)"
            [ngTemplateOutletContext]="{$implicit: {option: so}}">
          </ng-container>
        }
      }
      @if (!customSelectedOptionTemplate) {
        @if (!isMultiselect) {
          @for (so of context.selectedOptions; track so.value) {
            <icz-form-autocomplete-list-text-item
              [option]="so" [iczTooltip]="so.label"
              [isMultiline]="autoSizeMax > 1"
            ></icz-form-autocomplete-list-text-item>
          }
        }
        @if (isMultiselect) {
          <div class="chip-list">
            @for (so of context.selectedOptions; track so.value) {
              <icz-form-autocomplete-chip
                [removable]="!fieldDisabled"
                [isMultiline]="autoSizeMax > 1"
                [iczTooltip]="so.label"
                (removeClicked)="deselectOption(so)"
              >
                {{ so.label | translate }}
              </icz-form-autocomplete-chip>
            }
          </div>
        }
      }
    </ng-template>

    <ng-template #popoverContentTemplate let-context>
      <icz-form-autocomplete-list
        [isLoading]="loadingService.isLoading$(composableFormAutocomplete) | async"
        [searchTermInputPrefix]="searchTermInputPrefix"
        [searchTermInputPlaceholder]="asPopover ? '' : placeholder"
        [noResultsTemplate]="noResultsTemplate"
        [isMultiselect]="isMultiselect"
        [options]="context?.options ?? []"
        [selectedOptions]="context?.selectedOptions ?? []"
        [listItemTemplate]="customOptionTemplate || listItemTemplate"
        [hideDefaultTooltips]="hideDefaultTooltips"
        [hideSearchField]="hideSearchField"
        (selectionChanged)="context?.selectionChanged($event)"
        (searchTermChanged)="context?.searchTermChanged($event)"
        >

        <ng-content additionalContentBeforeOptions select="[additionalContentBeforeOptions]"></ng-content>

        <ng-template #listItemTemplate let-context>
          <icz-form-autocomplete-list-text-item
            [option]="context.option">
          </icz-form-autocomplete-list-text-item>
        </ng-template>

      </icz-form-autocomplete-list>
    </ng-template>

    <ng-content prefix select="[prefix]"></ng-content>
    <ng-content suffix select="[suffix]"></ng-content>
    <ng-content rightLabelPopup select="[rightLabelPopup]"></ng-content>

  </icz-composable-form-autocomplete>
</icz-validation-errors-list>
