import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {IconSize} from '../icon/icon.component';
import {ValidationResultWithTooltip} from '../../../services/bulk-operation-validation.service';
import {ResponsivityBreakpoint} from '../responsivity.service';
import {BehaviorSubject} from 'rxjs';
import {ToolbarDataService} from '../../table/table-toolbar/toolbar-data.service';

export enum ButtonType {
  PRIMARY = 'PRIMARY', // blue
  SECONDARY = 'SECONDARY', // white
}

// closure used for disabled status of buttons in button collection
export interface ButtonDisablerFn {
  (): Nullable<ValidationResultWithTooltip>;

  // function object metadata used in process of evaluating the disablers
  isGuard?: boolean;
}

// a decorator used for marking button disablers which guard execution of disablers declared after them
export function Guard() {
  return (target: any, propertyKey: string | symbol, propertyDescriptor: TypedPropertyDescriptor<(...args: any) => ButtonDisablerFn>) => {
    const untransformedDisablerFnFactory = propertyDescriptor.value!;

    propertyDescriptor.value = (...args: any) => {
      const disablerFn = untransformedDisablerFnFactory(...args);
      disablerFn.isGuard = true;
      return disablerFn;
    };
  };
}

// A common abstraction for building data-driven interfaces with buttons
export interface Button {
  type?: ButtonType;
  label: string;
  action?: (thisButton: Button) => any; // it is expected that externalized data passing will be done mainly via a closure
  icon?: string; // svgIcon name (key of the object in icons.ts)
  iconAfter?: string; // svgIcon name (key of the object in icons.ts)
  disable?: boolean;
  show?: boolean;
  alwaysCollapse?: boolean; // collapsed buttons display only as clickable icons with button label in their tooltip
  tooltip?: Nullable<string>;
  visibleTilBreakpoint?: Nullable<ResponsivityBreakpoint>; // if Nil, then the button will always be visible
  disableTooltip?: Nullable<string>;
  isTestingFeature?: boolean;
  useOriginalIconColor?: boolean;
  submenuItems?: Button[];
  buttonDisablers?: ButtonDisablerFn[];
}

@Component({
  selector: 'icz-button-collection',
  templateUrl: './button-collection.component.html',
  styleUrls: ['./button-collection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonCollectionComponent {

  private translateService = inject(TranslateService);
  private toolbarDataService = inject(ToolbarDataService);

  @Input({required: true})
  set buttons(buttons: Nullable<Button[]>) {
    if (buttons !== this.__buttons$.value) {
      this.__buttons$.next(buttons ?? []);
    }
  }
  @Input()
  size: IconSize = 'default';
  @Input()
  groupButtons = false;
  @Input()
  areCollapsed: Nullable<boolean> = false;
  @Input()
  disableAll = false;
  @Input()
  isVertical = false;
  @Input()
  hideBorders = false;
  @Output()
  buttonClicked = new EventEmitter<Button>();

  private __buttons$ = new BehaviorSubject<Button[]>([]);
  _buttons$ = this.toolbarDataService.processResponsiveToolbarButtons(this.__buttons$);

  readonly ButtonType = ButtonType;

  getTooltip(button: Button) {
    const tooltip = (button.tooltip ?
      this.translateService.instant(button.tooltip ?? '') :
      ''
    );

    if (tooltip && button.disableTooltip) {
      return `${tooltip}<br><br>${button.disableTooltip}`;
    } else if (button.disableTooltip) {
      return button.disableTooltip;
    } else {
      return tooltip;
    }
  }

  // To avoid missing right border, only apply grouping if more than 1 button exists
  get groupButtonsIfMoreThanOne() {
    return this.groupButtons && this.__buttons$.value?.length > 1;
  }

  onButtonClick($event: Nullable<Event>, button: Button) {
    if ((button.submenuItems?.length ?? 0) > 0 || button.disable) {
      return;
    }

    this.buttonClicked.emit(button);
  }

}
