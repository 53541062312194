/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { analogComponentTypeCreate } from '../fn/analog-component-type/analog-component-type-create';
import { AnalogComponentTypeCreate$Params } from '../fn/analog-component-type/analog-component-type-create';
import { analogComponentTypeDelete } from '../fn/analog-component-type/analog-component-type-delete';
import { AnalogComponentTypeDelete$Params } from '../fn/analog-component-type/analog-component-type-delete';
import { AnalogComponentTypeDto } from '../models/analog-component-type-dto';
import { analogComponentTypeFindAll } from '../fn/analog-component-type/analog-component-type-find-all';
import { AnalogComponentTypeFindAll$Params } from '../fn/analog-component-type/analog-component-type-find-all';
import { analogComponentTypeFindById } from '../fn/analog-component-type/analog-component-type-find-by-id';
import { AnalogComponentTypeFindById$Params } from '../fn/analog-component-type/analog-component-type-find-by-id';
import { analogComponentTypeSearch } from '../fn/analog-component-type/analog-component-type-search';
import { AnalogComponentTypeSearch$Params } from '../fn/analog-component-type/analog-component-type-search';
import { analogComponentTypeUpdate } from '../fn/analog-component-type/analog-component-type-update';
import { AnalogComponentTypeUpdate$Params } from '../fn/analog-component-type/analog-component-type-update';
import { PageAnalogComponentTypeDto } from '../models/page-analog-component-type-dto';


/**
 * Controller for Analog Component types
 */
@Injectable({ providedIn: 'root' })
export class ApiAnalogComponentTypeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `analogComponentTypeFindById()` */
  static readonly AnalogComponentTypeFindByIdPath = '/codebook/analog-component-type/{id}';

  /**
   * Find analog component type by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analogComponentTypeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeFindById$Response(params: AnalogComponentTypeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AnalogComponentTypeDto>> {
    return analogComponentTypeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find analog component type by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analogComponentTypeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeFindById(params: AnalogComponentTypeFindById$Params, context?: HttpContext): Observable<AnalogComponentTypeDto> {
    return this.analogComponentTypeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnalogComponentTypeDto>): AnalogComponentTypeDto => r.body)
    );
  }

  /** Path part for operation `analogComponentTypeUpdate()` */
  static readonly AnalogComponentTypeUpdatePath = '/codebook/analog-component-type/{id}';

  /**
   * Update existing analog component type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analogComponentTypeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  analogComponentTypeUpdate$Response(params: AnalogComponentTypeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<AnalogComponentTypeDto>> {
    return analogComponentTypeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing analog component type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analogComponentTypeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  analogComponentTypeUpdate(params: AnalogComponentTypeUpdate$Params, context?: HttpContext): Observable<AnalogComponentTypeDto> {
    return this.analogComponentTypeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnalogComponentTypeDto>): AnalogComponentTypeDto => r.body)
    );
  }

  /** Path part for operation `analogComponentTypeDelete()` */
  static readonly AnalogComponentTypeDeletePath = '/codebook/analog-component-type/{id}';

  /**
   * Delete existing analog component type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analogComponentTypeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeDelete$Response(params: AnalogComponentTypeDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return analogComponentTypeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing analog component type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analogComponentTypeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeDelete(params: AnalogComponentTypeDelete$Params, context?: HttpContext): Observable<void> {
    return this.analogComponentTypeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `analogComponentTypeFindAll()` */
  static readonly AnalogComponentTypeFindAllPath = '/codebook/analog-component-type';

  /**
   * Finds all analog component types
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analogComponentTypeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeFindAll$Response(params?: AnalogComponentTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<AnalogComponentTypeDto>>> {
    return analogComponentTypeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all analog component types
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analogComponentTypeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeFindAll(params?: AnalogComponentTypeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<AnalogComponentTypeDto>> {
    return this.analogComponentTypeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AnalogComponentTypeDto>>): Array<AnalogComponentTypeDto> => r.body)
    );
  }

  /** Path part for operation `analogComponentTypeCreate()` */
  static readonly AnalogComponentTypeCreatePath = '/codebook/analog-component-type';

  /**
   * Create new analog component type
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analogComponentTypeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  analogComponentTypeCreate$Response(params: AnalogComponentTypeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<AnalogComponentTypeDto>> {
    return analogComponentTypeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new analog component type
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analogComponentTypeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  analogComponentTypeCreate(params: AnalogComponentTypeCreate$Params, context?: HttpContext): Observable<AnalogComponentTypeDto> {
    return this.analogComponentTypeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<AnalogComponentTypeDto>): AnalogComponentTypeDto => r.body)
    );
  }

  /** Path part for operation `analogComponentTypeSearch()` */
  static readonly AnalogComponentTypeSearchPath = '/codebook/analog-component-type/search-api';

  /**
   * Finds analog component types based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `analogComponentTypeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeSearch$Response(params?: AnalogComponentTypeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageAnalogComponentTypeDto>> {
    return analogComponentTypeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds analog component types based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `analogComponentTypeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  analogComponentTypeSearch(params?: AnalogComponentTypeSearch$Params|null|undefined, context?: HttpContext): Observable<PageAnalogComponentTypeDto> {
    return this.analogComponentTypeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageAnalogComponentTypeDto>): PageAnalogComponentTypeDto => r.body)
    );
  }

}
