/* eslint-disable @angular-eslint/no-input-rename */
import {Directive, ElementRef, inject, Input} from '@angular/core';
import {AbstractHighlightDirective} from './abstract-highlight.directive';
import {IczOnChanges, IczSimpleChanges} from '../../utils/icz-on-changes';

@Directive({
  selector: '[iczHighlightBackground]',
})
export class HighlightBackgroundDirective extends AbstractHighlightDirective implements IczOnChanges {

  private el = inject(ElementRef);

  @Input({alias: 'iczHighlightBackground', required: true})
  matchTags: string[] = []; // tags to match against

  @Input({alias: 'iczHighlightBackground.terms', required: true})
  terms: string[] = []; // terms to highlight in text. typically based on user input.

  @Input('iczHighlightBackground.if')
  activateHighlighting: boolean = true; // could be disabled based on some external condition

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (this.activateHighlighting) {
      if (changes.terms  || changes.matchTags) {
        const nonemptyTerms = this.terms.filter(t => t);

        if (nonemptyTerms.length && this.tagsMatchAnyTerm(nonemptyTerms)) {
          this.highlightSelf();
        }
        else {
          this.unhighlightSelf();
        }
      }
    }
    else {
      this.unhighlightSelf();
    }
  }

  private highlightSelf() {
    this.el.nativeElement.classList.add('highlight');
  }

  private unhighlightSelf() {
    this.el.nativeElement.classList.remove('highlight');
  }

  private tagsMatchAnyTerm(terms: string[]): boolean {
    const re = this.getRegexForTerms(terms);

    return this.matchTags.filter(t => re.test(String(t))).length > 0;
  }
}
