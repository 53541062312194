import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentState} from '|api/commons';
import {FilterOperator, FilterParam, SearchParams} from '../../../services/search-api.service';
import {AbstractObjectSelectorComponent} from '../file-selector/abstract-object-selector.component';
import {WITHOUT_REF_NUMBER} from '../document-toolbar/services/toolbar-common.utils';
import {DocumentAllowableObjectClass, DocumentDto} from '|api/document';
import {OWN_DOCUMENT_OBJECT_CLASSES, RECEIVED_DOCUMENT_OBJECT_CLASSES} from '../shared-document.utils';

@Component({
  selector: 'icz-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSelectorComponent extends AbstractObjectSelectorComponent<DocumentDto> {

  @Input()
  objectClasses: DocumentAllowableObjectClass[] = [...OWN_DOCUMENT_OBJECT_CLASSES, ...RECEIVED_DOCUMENT_OBJECT_CLASSES];

  readonly recentlyVisitedObjectsHeading = 'Naposledy navštívené dokumenty:';
  readonly additionalFilteringCriteria: FilterParam[] = [
    {
      fieldName: 'documentState',
      operator: FilterOperator.equals,
      value: String([DocumentState.ON_TABLE]),
    },
  ];
  readonly withoutRefNumberText = WITHOUT_REF_NUMBER;

  protected findObjects(searchParams: SearchParams) {
    return this.searchService.findDocumentsAll(searchParams);
  }

}
