<icz-table
  [id]="tableId"
  [config]="tableConfig"
  [columnsData]="columnsData"
  [dataSource]="dataSource"
  (clicked)="rowClickCallback($event)"
  (pageLoad)="loadPage($event)"
  >
  @if (tableTitleTag) {
    <icz-tag tableTag [label]="tableTitleTag" class="mr-8"></icz-tag>
  }
  <icz-label tableTitle [label]="tableTitle"></icz-label>
  <icz-label notFoundLabel noDataLabel label="Žádné možnosti nenalezeny"></icz-label>
</icz-table>
