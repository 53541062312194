import {BarcodeFormat, FontSize} from '|api/sad';

export enum EnvelopeFormat {
  DL = 'DL',
  C_7_C_6 = 'C7C6',
  C_6 = 'C6',
  C_6_C_5 = 'C6C5',
  C_5 = 'C5',
  C_4 = 'C4',
  C_3 = 'C3',
  B_6 = 'B6',
  B_5 = 'B5',
  B_4 = 'B4',
  E_4 = 'E4'
}

export enum PaperFormat {
  A3 = 'A3',
  A4 = 'A4',
  A5 = 'A5',
  A6 = 'A6',
  A7 = 'A7',
  A8 = 'A8',
}

export const ENVELOPE_FORMAT_SIZES: Record<EnvelopeFormat, [number, number]> = {
  [EnvelopeFormat.DL]: [220, 110],
  [EnvelopeFormat.C_7_C_6]: [162, 81],
  [EnvelopeFormat.C_6]: [162, 114],
  [EnvelopeFormat.C_6_C_5]: [229, 114],
  [EnvelopeFormat.C_5]: [229, 162],
  [EnvelopeFormat.C_4]: [324, 229],
  [EnvelopeFormat.C_3]: [458, 324],
  [EnvelopeFormat.B_6]: [176, 125],
  [EnvelopeFormat.B_5]: [250, 176],
  [EnvelopeFormat.B_4]: [353, 250],
  [EnvelopeFormat.E_4]: [400, 280],
};

export const PAPER_FORMAT_SIZES = {
  [PaperFormat.A3]: [297, 420],
  [PaperFormat.A4]: [210, 297],
  [PaperFormat.A5]: [148, 210],
  [PaperFormat.A6]: [105, 148],
  [PaperFormat.A7]: [74, 105],
  [PaperFormat.A8]: [52, 74],
};

export const BARCODE_FORMAT_SIZES: Record<BarcodeFormat, [number, number]> = {
  [BarcodeFormat.HEIGHT_15_MM]: [45, 15],
  [BarcodeFormat.HEIGHT_12_MM]: [40, 12],
  [BarcodeFormat.HEIGHT_10_MM]: [35, 10],
  [BarcodeFormat.HEIGHT_8_MM]: [35, 8],
  [BarcodeFormat.HEIGHT_6_MM]: [35, 6],
};

export const FONT_SIZES: Record<FontSize, number> = {
  [FontSize.PT_9]: 3.2,
  [FontSize.PT_11]: 3.9,
  [FontSize.PT_13]: 4.6,
  [FontSize.PT_15]: 5.3,
  [FontSize.PT_17]: 6,
};

export const BARCODE_FONT_SIZE = FONT_SIZES[FontSize.PT_13];
