import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SubjectAttributeDataBoxDto, SubjectRecordDto} from '|api/commons';
import {EsslObject} from '../../model/entity.model';
import {isDocumentObject, isFileObject} from '../../shared-document.utils';
import {AddressWithTypeDto} from '../../model/addresses.model';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {SubjectAttributeType} from '../../model/subject-attribute-type';


@Component({
  selector: 'icz-portrait',
  templateUrl: './portrait.component.html',
  styleUrls: ['./portrait.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortraitComponent {

  @Input({required: true}) subject: Nullable<SubjectRecordDto>;
  @Input() object!: Nullable<EsslObject>;
  @Input() showDatabox = false;

  get isDocumentObject() {
    return isDocumentObject(this.object);
  }
  get isFileObject() {
    return isFileObject(this.object);
  }

  get dataBoxes(): SubjectAttributeDataBoxDto[] {
    return (this.subject?.attributes[SubjectAttributeType.DATA_BOX]) ?? [];
  }

  get getBusinessSortedAddress(): Nullable<AddressWithTypeDto> {
    return this.subject ? SubjectTemplateUtils.getBusinessSortedAddresses(this.subject)?.[0] : null;
  }

}
