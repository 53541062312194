import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit, signal} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {DisposalScheduleDto, DocumentTypeDto, EntityClassDto} from '|api/codebook';
import {forkJoin} from 'rxjs';
import {DisposalScheduleComparison, DisposalScheduleSourceType} from '../disposal-tip/disposal-tip.component';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {formatDisposalSchedule} from '../shared-document.utils';

@Component({
  selector: 'icz-disposal-schedule-comparator',
  templateUrl: './disposal-schedule-comparator.component.html',
  styleUrls: ['./disposal-schedule-comparator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisposalScheduleComparatorComponent implements IczOnChanges, OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  protected cd = inject(ChangeDetectorRef);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);

  @Input({required: true})
  entityClassId: Nullable<number>;
  @Input()
  documentTypeId: Nullable<number>;
  @Input({required: true})
  disposalScheduleSource: Nullable<DisposalScheduleSourceType>;
  @Input()
  disposalScheduleComparison: Nullable<DisposalScheduleComparison> = null;

  form = new IczFormGroup({
    disposalScheduleByEntityClass: new IczFormGroup({
      disposalSchedule: new IczFormControl<Nullable<string>>({disabled: true, value: null}),
    }),
    disposalScheduleByDocumentType: new IczFormGroup({
      disposalSchedule: new IczFormControl<Nullable<string>>({disabled: true, value: null}),
    }),
  });

  disposalSchedules: DisposalScheduleDto[] = [];
  documentTypes: DocumentTypeDto[] = [];
  entityClasses: EntityClassDto[] = [];
  selectedEntityClass: Nullable<EntityClassDto>;
  selectedDocumentType: Nullable<DocumentTypeDto>;
  comparatorOpen = false;
  disposalScheduleSelectedSig = signal(false);

  get disposalScheduleByEntityClassForm() {
    return this.form.get('disposalScheduleByEntityClass')! as IczFormGroup;
  }

  get disposalScheduleByDocumentTypeForm() {
    return this.form.get('disposalScheduleByDocumentType')! as IczFormGroup;
  }

  get disposalScheduleSelectedDual() {
    return this.disposalScheduleComparison === DisposalScheduleComparison.DUAL;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.entityClassId && changes.entityClassId.currentValue) {
      this.fillDisposalSchedule();
    }
    if (changes.documentTypeId && changes.documentTypeId.currentValue) {
      this.fillDisposalSchedule();
    }
    if (changes.disposalScheduleComparison && changes.disposalScheduleComparison) {
      if (isNil(changes.disposalScheduleComparison)) {
        this.disposalScheduleSelectedSig.set(false);
      } else {
        this.disposalScheduleSelectedSig.set(changes.disposalScheduleComparison.currentValue === DisposalScheduleComparison.DUAL || changes.disposalScheduleComparison.currentValue === DisposalScheduleComparison.SINGLE);
      }
    }
  }

  ngOnInit() {
    this.loadingService.doLoading(
      forkJoin([
        this.codebookService.documentTypes(),
        this.codebookService.entityClasses(),
        this.codebookService.disposalSchedules(),
      ]),
      this
    ).subscribe(([documentTypes, entityClasses, disposalSchedules ]) => {
      this.documentTypes = documentTypes;
      this.entityClasses = entityClasses;
      this.disposalSchedules = disposalSchedules;
      this.fillDisposalSchedule();
    });
  }

  fillDisposalSchedule() {
    if (this.entityClasses.length > 0 && this.documentTypes.length > 0 && this.disposalSchedules.length > 0) {
      if (this.entityClassId) {
        this.selectedEntityClass = this.entityClasses.find(ec => ec.id === this.entityClassId)!;
        this.disposalScheduleByEntityClassForm.get('disposalSchedule')!.setValue(
          this.formatSelectedDisposalSchedule(this.selectedEntityClass.disposalScheduleId));
      }
      if (this.documentTypeId) {
        this.selectedDocumentType = this.documentTypes.find(ec => ec.id === this.documentTypeId)!;
        this.disposalScheduleByDocumentTypeForm.get('disposalSchedule')!.setValue(
          this.formatSelectedDisposalSchedule(this.selectedDocumentType.disposalScheduleId));
      }
    }

  }

  formatSelectedDisposalSchedule(scheduleId: Nullable<number>) {
    const disposalSchedule = this.disposalSchedules.find(ds => ds.id === scheduleId);
    return formatDisposalSchedule(disposalSchedule);
  }

  retentionTriggerAndDisposalScheduleInfoText() {
    const infoText: string[] = [];
    const disposalScheduleText = this.translateService.instant('Skartační režim je přebrán od {{disposalScheduleSource}}.');
    const entityClassText = this.translateService.instant('věcné skupiny');
    const documentTypeText = this.translateService.instant('typu dokumentu');

    const context: Record<string, string> = {
      disposalScheduleSource: '',
      retentionTriggerEventSource: ''
    };

    if (this.disposalScheduleSource) {
      infoText.push(disposalScheduleText);
      if (this.disposalScheduleSource === DisposalScheduleSourceType.DOCUMENT_TYPE) {
        context.disposalScheduleSource = documentTypeText;
      } else if (this.disposalScheduleSource === DisposalScheduleSourceType.ENTITY_CLASS) {
        context.disposalScheduleSource = entityClassText;
      }
    }

    return (infoText.length > 0) ? this.translateParser.interpolate(infoText.join(' '), context)! : '';
  }
}
