/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageSettlementDto } from '../models/page-settlement-dto';
import { SettlementDto } from '../models/settlement-dto';
import { settlementFind } from '../fn/settlement/settlement-find';
import { SettlementFind$Params } from '../fn/settlement/settlement-find';
import { settlementFindById } from '../fn/settlement/settlement-find-by-id';
import { SettlementFindById$Params } from '../fn/settlement/settlement-find-by-id';
import { settlementFindSettlementsByIds } from '../fn/settlement/settlement-find-settlements-by-ids';
import { SettlementFindSettlementsByIds$Params } from '../fn/settlement/settlement-find-settlements-by-ids';
import { settlementResumeSettlements } from '../fn/settlement/settlement-resume-settlements';
import { SettlementResumeSettlements$Params } from '../fn/settlement/settlement-resume-settlements';
import { settlementSuspendSettlements } from '../fn/settlement/settlement-suspend-settlements';
import { SettlementSuspendSettlements$Params } from '../fn/settlement/settlement-suspend-settlements';
import { settlementUpdateTriggerYears } from '../fn/settlement/settlement-update-trigger-years';
import { SettlementUpdateTriggerYears$Params } from '../fn/settlement/settlement-update-trigger-years';


/**
 * API for management of settlements
 */
@Injectable({ providedIn: 'root' })
export class ApiSettlementService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `settlementUpdateTriggerYears()` */
  static readonly SettlementUpdateTriggerYearsPath = '/document/settlement/trigger-event-years';

  /**
   * Bulk set trigger event check years or years of retention period start.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settlementUpdateTriggerYears()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementUpdateTriggerYears$Response(params: SettlementUpdateTriggerYears$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return settlementUpdateTriggerYears(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk set trigger event check years or years of retention period start.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settlementUpdateTriggerYears$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementUpdateTriggerYears(params: SettlementUpdateTriggerYears$Params, context?: HttpContext): Observable<void> {
    return this.settlementUpdateTriggerYears$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `settlementSuspendSettlements()` */
  static readonly SettlementSuspendSettlementsPath = '/document/settlement/suspend-settlement';

  /**
   * Suspend files or documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settlementSuspendSettlements()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementSuspendSettlements$Response(params: SettlementSuspendSettlements$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return settlementSuspendSettlements(this.http, this.rootUrl, params, context);
  }

  /**
   * Suspend files or documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settlementSuspendSettlements$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementSuspendSettlements(params: SettlementSuspendSettlements$Params, context?: HttpContext): Observable<void> {
    return this.settlementSuspendSettlements$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `settlementResumeSettlements()` */
  static readonly SettlementResumeSettlementsPath = '/document/settlement/resume-settlement';

  /**
   * Resume files or documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settlementResumeSettlements()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementResumeSettlements$Response(params: SettlementResumeSettlements$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return settlementResumeSettlements(this.http, this.rootUrl, params, context);
  }

  /**
   * Resume files or documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settlementResumeSettlements$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementResumeSettlements(params: SettlementResumeSettlements$Params, context?: HttpContext): Observable<void> {
    return this.settlementResumeSettlements$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `settlementFindSettlementsByIds()` */
  static readonly SettlementFindSettlementsByIdsPath = '/document/settlement/settlements-by-ids';

  /**
   * Find all settlements for given ids. If some id does not correspond any settlement, error is returned with information, which ids do not have corresponding settlement.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settlementFindSettlementsByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementFindSettlementsByIds$Response(params: SettlementFindSettlementsByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SettlementDto>>> {
    return settlementFindSettlementsByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all settlements for given ids. If some id does not correspond any settlement, error is returned with information, which ids do not have corresponding settlement.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settlementFindSettlementsByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  settlementFindSettlementsByIds(params: SettlementFindSettlementsByIds$Params, context?: HttpContext): Observable<Array<SettlementDto>> {
    return this.settlementFindSettlementsByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SettlementDto>>): Array<SettlementDto> => r.body)
    );
  }

  /** Path part for operation `settlementFindById()` */
  static readonly SettlementFindByIdPath = '/document/settlement/{id}';

  /**
   * Find settlement with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settlementFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  settlementFindById$Response(params: SettlementFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SettlementDto>> {
    return settlementFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find settlement with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settlementFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settlementFindById(params: SettlementFindById$Params, context?: HttpContext): Observable<SettlementDto> {
    return this.settlementFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SettlementDto>): SettlementDto => r.body)
    );
  }

  /** Path part for operation `settlementFind()` */
  static readonly SettlementFindPath = '/document/settlement/search-api';

  /**
   * Find all instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `settlementFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  settlementFind$Response(params?: SettlementFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSettlementDto>> {
    return settlementFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `settlementFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  settlementFind(params?: SettlementFind$Params|null|undefined, context?: HttpContext): Observable<PageSettlementDto> {
    return this.settlementFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSettlementDto>): PageSettlementDto => r.body)
    );
  }

}
