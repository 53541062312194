/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deliveryResultCreate } from '../fn/delivery-result/delivery-result-create';
import { DeliveryResultCreate$Params } from '../fn/delivery-result/delivery-result-create';
import { deliveryResultDelete } from '../fn/delivery-result/delivery-result-delete';
import { DeliveryResultDelete$Params } from '../fn/delivery-result/delivery-result-delete';
import { DeliveryResultDto } from '../models/delivery-result-dto';
import { deliveryResultFindAll } from '../fn/delivery-result/delivery-result-find-all';
import { DeliveryResultFindAll$Params } from '../fn/delivery-result/delivery-result-find-all';
import { deliveryResultSearch } from '../fn/delivery-result/delivery-result-search';
import { DeliveryResultSearch$Params } from '../fn/delivery-result/delivery-result-search';
import { deliveryResultUpdate } from '../fn/delivery-result/delivery-result-update';
import { DeliveryResultUpdate$Params } from '../fn/delivery-result/delivery-result-update';
import { PageDeliveryResultDto } from '../models/page-delivery-result-dto';


/**
 * Controller for delivery result
 */
@Injectable({ providedIn: 'root' })
export class ApiDeliveryResultService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliveryResultUpdate()` */
  static readonly DeliveryResultUpdatePath = '/codebook/delivery-result/{id}';

  /**
   * Update existing delivery result
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryResultUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryResultUpdate$Response(params: DeliveryResultUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryResultDto>> {
    return deliveryResultUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing delivery result
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryResultUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryResultUpdate(params: DeliveryResultUpdate$Params, context?: HttpContext): Observable<DeliveryResultDto> {
    return this.deliveryResultUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryResultDto>): DeliveryResultDto => r.body)
    );
  }

  /** Path part for operation `deliveryResultDelete()` */
  static readonly DeliveryResultDeletePath = '/codebook/delivery-result/{id}';

  /**
   * Delete existing delivery result
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryResultDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryResultDelete$Response(params: DeliveryResultDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deliveryResultDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing delivery result
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryResultDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryResultDelete(params: DeliveryResultDelete$Params, context?: HttpContext): Observable<void> {
    return this.deliveryResultDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `deliveryResultFindAll()` */
  static readonly DeliveryResultFindAllPath = '/codebook/delivery-result';

  /**
   * Finds all delivery results
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryResultFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryResultFindAll$Response(params?: DeliveryResultFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryResultDto>>> {
    return deliveryResultFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all delivery results
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryResultFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryResultFindAll(params?: DeliveryResultFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DeliveryResultDto>> {
    return this.deliveryResultFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryResultDto>>): Array<DeliveryResultDto> => r.body)
    );
  }

  /** Path part for operation `deliveryResultCreate()` */
  static readonly DeliveryResultCreatePath = '/codebook/delivery-result';

  /**
   * Create new delivery result
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryResultCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryResultCreate$Response(params: DeliveryResultCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryResultDto>> {
    return deliveryResultCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new delivery result
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryResultCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryResultCreate(params: DeliveryResultCreate$Params, context?: HttpContext): Observable<DeliveryResultDto> {
    return this.deliveryResultCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryResultDto>): DeliveryResultDto => r.body)
    );
  }

  /** Path part for operation `deliveryResultSearch()` */
  static readonly DeliveryResultSearchPath = '/codebook/delivery-result/search-api';

  /**
   * Finds delivery results based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryResultSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryResultSearch$Response(params?: DeliveryResultSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryResultDto>> {
    return deliveryResultSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds delivery results based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryResultSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryResultSearch(params?: DeliveryResultSearch$Params|null|undefined, context?: HttpContext): Observable<PageDeliveryResultDto> {
    return this.deliveryResultSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryResultDto>): PageDeliveryResultDto => r.body)
    );
  }

}
