import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Highlight} from '../document-table/components/document-relevance-details/document-relevance-details.component';

@Component({
  selector: 'icz-highlightable-text-content',
  templateUrl: './highlightable-text-content.component.html',
  styleUrls: ['./highlightable-text-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HighlightableTextContentComponent {
  @Input({required: true})
  fieldId: Nullable<string>;
  @Input({required: true})
  originalValue: Nullable<string>;
  @Input({required: true})
  searchTermWords: string[] = [];
  @Input({required: true})
  highlights: Highlight[] = [];
}
