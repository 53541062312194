@if (customFields?.length) {
  @if (wrapInSection) {
    <icz-section label="Uživatelem zadávaný text">
      <ng-container *ngTemplateOutlet="customFieldsContent"></ng-container>
    </icz-section>
  }
  @else {
    @if (form) {
      <ng-container [formGroup]="form">
        @for (customField of customFields; track customField.key) {
          <icz-form-field
            [formControlName]="customField.key"
            [label]="customField.label"
            [fieldDisabled]="disabled"
          ></icz-form-field>
        }
      </ng-container>
    }
  }
  <ng-template #customFieldsContent>
    @if (form) {
      <ng-container [formGroup]="form">
        @for (customField of customFields; track customField.key) {
          <icz-form-field
            [formControlName]="customField.key"
            [label]="customField.label"
            [fieldDisabled]="disabled"
          ></icz-form-field>
        }
      </ng-container>
    }
  </ng-template>
}
