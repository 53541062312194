/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ExternalRetentionTriggerCreateDto } from '../../models/external-retention-trigger-create-dto';
import { ExternalRetentionTriggerDto } from '../../models/external-retention-trigger-dto';

export interface ExternalRetentionTriggerCreate$Params {
      body: ExternalRetentionTriggerCreateDto
}

export function externalRetentionTriggerCreate(http: HttpClient, rootUrl: string, params: ExternalRetentionTriggerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<ExternalRetentionTriggerDto>> {
  const rb = new RequestBuilder(rootUrl, externalRetentionTriggerCreate.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ExternalRetentionTriggerDto>;
    })
  );
}

externalRetentionTriggerCreate.PATH = '/codebook/external-retention-trigger';