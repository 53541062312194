import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {TranslateService} from '@ngx-translate/core';
import {DateFilterSelectionMode} from './date-filter.models';
import {DatePickerShortcut, ShortcutClickedEvent} from '../date-picker-shortcuts/date-picker-shortcuts.component';
import {FilterOperator} from '../../../../../../services/search-api.service';
import {IczOnChanges, IczSimpleChanges} from '../../../../../../utils/icz-on-changes';
import {IczFormControl, IczFormGroup} from '../../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../../form-elements/validators/icz-validators/icz-validators';
import {areDateRangesEqual, isDateRange, parseDateFromLocalDateString} from '../../../../../../model';
import {iczFormatDate} from '../../../../../essentials/date.pipe';
import {ApplicationLanguage} from '../../../../../../core/services/environment.models';

export function isDateOperatorIntervalable(filterOperator: FilterOperator) {
  return (
    filterOperator === FilterOperator.equals ||
    filterOperator === FilterOperator.notEquals
  );
}

@Component({
  selector: 'icz-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateFilterComponent implements IczOnChanges {

  protected translateService = inject(TranslateService);

  @Input({required: true})
  selected: Nullable<Date | DateRange<Date>>;
  @Input({required: true})
  currentOperator!: FilterOperator;
  @Output()
  selectedDateChanged = new EventEmitter<Nullable<Date | DateRange<Date>>>();

  @Output()
  daySelected = new EventEmitter();

  calendarDateForm = new IczFormGroup({
    date: new IczFormControl<Nullable<string>>(null, [IczValidators.localDate()]),
  });

  activeShortcutType: Nullable<DatePickerShortcut>;

  isIntervalSwitchDisabled = false;

  selectionMode = DateFilterSelectionMode.DATE;

  get isUsingIntervalableOperator(): boolean {
    return this.currentOperator === FilterOperator.equals || this.currentOperator === FilterOperator.notEquals;
  }

  readonly DateFilterSelectionMode = DateFilterSelectionMode;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.currentOperator) {
      if (this.isUsingIntervalableOperator) {
        this.isIntervalSwitchDisabled = false;

        if (changes.selected) {
          if (isDateRange(this.selected)) {
            this.selectionMode = DateFilterSelectionMode.INTERVAL;
          }
          else {
            this.selectionMode = DateFilterSelectionMode.DATE;
          }
        }
      }
      else {
        this.isIntervalSwitchDisabled = true;

        // clear filter state only when change INTERVAL->DATE happens
        if (this.selectionMode === DateFilterSelectionMode.INTERVAL) {
          this.selectionMode = DateFilterSelectionMode.DATE;
          this.activeShortcutType = null;
          this.selected = null;
          this.selectedDateChanged.emit(null);
        }
      }
    }
    if (changes.selected) {
      if (this.selected && this.selectionMode === DateFilterSelectionMode.DATE) {
        this.calendarDateForm.get('date')!.setValue(
          iczFormatDate(
            this.translateService.currentLang as ApplicationLanguage,
            this.selected as Date,
          ).replaceAll(' ', '')
        );
      }
    }
  }

  applyDateInputValue() {
    if (this.calendarDateForm.valid) {
      const dateText = this.calendarDateForm.value.date;

      if (dateText) {
        const parsedDate = parseDateFromLocalDateString(dateText);

        if (parsedDate) {
          this.selected = parsedDate;
          this.onDateChange(parsedDate);
        }
      }
      else {
        this.selected = null;
        this.onDateChange(null);
      }
    }
  }

  onDateChange(date: Nullable<Date | DateRange<Date>>) {
    if (isDateRange(date)) {
      if (date.start && date.end) {
        const lastDateDiffersFromCurrentDate = !this.selected || this.selected instanceof Date || (
          isDateRange(this.selected) &&
          !areDateRangesEqual(this.selected!, date)
        );

        if (lastDateDiffersFromCurrentDate) {
          this.activeShortcutType = null;
          this.selected = date;
        }

        this.selectedDateChanged.emit(date);
      }
    }
    else {
      this.selected = date;
      this.selectedDateChanged.emit(date);
    }
  }

  onDayClick() {
    this.daySelected.emit();
  }

  handleDateShortcutChange(event: ShortcutClickedEvent) {
    this.activeShortcutType = event.shortcutType;

    if (event.shortcutValue) {
      this.selected = event.shortcutValue;
      this.onDateChange(event.shortcutValue);
    }
  }

  switchToDateMode() {
    this.changeSelectionMode(DateFilterSelectionMode.DATE);
  }

  switchToIntervalMode() {
    this.changeSelectionMode(DateFilterSelectionMode.INTERVAL);
  }

  private changeSelectionMode(newSelectionMode: DateFilterSelectionMode) {
    const oldSelectionMode = this.selectionMode;
    this.selectionMode = newSelectionMode;
    this.activeShortcutType = null;

    if (
      oldSelectionMode === DateFilterSelectionMode.INTERVAL &&
      newSelectionMode === DateFilterSelectionMode.DATE
    ) {
      this.selected = null;
      this.selectedDateChanged.emit(null);
    }
  }

}
