export enum SubjectAttributeType {
  ADDITIONAL_ADDRESS = 'additionalAddresses',
  ART_1_P_3_ID = 'art1p3Id',
  BIRTH_DATE = 'birthDate',
  BIRTH_NAME = 'birthName',
  BIRTH_PLACE = 'birthPlace',
  BIRTH_SURNAME = 'birthSurname',
  BUSINESS_NAME = 'businessName',
  CID = 'cid',
  CLIENT_ID = 'clientId',
  COMPANY_ID = 'companyId',
  DATA_BOX = 'dataBoxes',
  DEGREE_AFTER = 'degreeAfter',
  DEGREE_BEFORE = 'degreeBefore',
  DRIVING_LICENCE_ID = 'drivingLicenceId',
  EMAIL = 'emails',
  EORI_CODE = 'eoriCode',
  EXCISE_TAX_ID = 'exciseTaxId',
  FIRST_NAME = 'firstName',
  GENDER = 'gender',
  IDENTITY_CARD_ID = 'identityCardId',
  LEGAL_FORM = 'legalForm',
  LE_ID = 'leId',
  MAILING_ADDRESS = 'mailingAddress',
  NOTE = 'note',
  PASSPORT_ID = 'passportId',
  PHONE_FAX = 'phoneFaxes',
  POSTAL_BOX = 'postalBoxes',
  RESIDENTIAL_ADDRESS = 'residentialAddress',
  SURNAME = 'surname',
  TAX_ID = 'taxId',
  VAT_ID = 'vatId',
}
