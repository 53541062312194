<div class="col grow">
  <icz-button background class="row-button grow" noCentered svgIcon="settings"
  (onAction)="routeToAdmin()" label="Nastavení aplikace"></icz-button>
  <hr>
    <icz-button background class="row-button grow" noCentered svgIcon="system_config"
    (onAction)="routeToConfig()" label="Systémová konfigurace"></icz-button>
    <hr>
      <div class="px-16 py-8">
        <div>
          <icz-label class="icz-body-1 mt-4 mb-2" label="Prostředí"></icz-label>
          <icz-chip class="pr-0" cdkOverlayOrigin #environmentsOrigin="cdkOverlayOrigin"
            (clicked)="openEnvironmentsPopup()">
            <div class="gap-4 row" content>
              <icz-icon size="small" [svgIcon]="currentEnvironmentOption.icon"></icz-icon>
              <div class="grow">{{ currentEnvironmentOption.label | translate }}</div>
            </div>
            <icz-button suffix svgIcon="expand_more" background disableRipple disableFocus
            size="default" (onAction)="openEnvironmentsPopup()"></icz-button>
          </icz-chip>
          <icz-popover [overlayOrigin]="environmentsOrigin"
            [isOpen]="environmentPopupOpen" (onClose)="environmentsPopupClosed()">
            <icz-options-list
              [options]="environmentOptions"
              [selectedOptions]="[currentEnvironmentOption]"
              (selectionChanged)="environmentChanged($event)">
            </icz-options-list>
          </icz-popover>
        </div>
        <!-- todo(rb) move the settings below to somewhere else -->
        <div class="grow-1">
          <icz-label class="icz-body-1 mt-4 mb-2" label="Jazyk"></icz-label>
          <icz-chip class="pr-0" cdkOverlayOrigin #languagesOrigin="cdkOverlayOrigin"
            (clicked)="openLanguagesPopup()">
            <div class="gap-4 row" content>
              <icz-icon size="small" [svgIcon]="currentLanguageOption.icon"></icz-icon>
              <div class="grow">{{ currentLanguageOption.label | translate }}</div>
            </div>
            <icz-button suffix svgIcon="expand_more" background disableRipple disableFocus
            size="default" (onAction)="openLanguagesPopup()"></icz-button>
          </icz-chip>
          <icz-popover [overlayOrigin]="languagesOrigin"
            [isOpen]="languagesPopupOpen" (onClose)="languagesPopupClosed()">
            <icz-options-list
              [options]="languageOptions"
              [selectedOptions]="[currentLanguageOption]"
              (selectionChanged)="languageChanged($event)">
            </icz-options-list>
          </icz-popover>
        </div>
        @if (currentColorSchemeOption) {
          <div class="grow-1">
            <icz-label class="icz-body-1 mt-4 mb-2" label="Podoba prostředí"></icz-label>
            <icz-chip class="pr-0" cdkOverlayOrigin #colorSchemeOrigin="cdkOverlayOrigin"
              (clicked)="openColorSchemePopup()">
              <div class="gap-4 row" content>
                <div class="grow">{{ currentColorSchemeOption.label | translate }}</div>
              </div>
              <icz-button suffix svgIcon="expand_more" background disableRipple disableFocus
              size="default" (onAction)="openColorSchemePopup()"></icz-button>
            </icz-chip>
            <icz-popover [overlayOrigin]="colorSchemeOrigin"
              [isOpen]="colorSchemePopupOpen" (onClose)="colorSchemePopupClosed()">
              <icz-options-list
                [options]="environamentColorOptions"
                [selectedOptions]="[currentColorSchemeOption]"
                (selectionChanged)="onIconColorSchemeChange($event)">
              </icz-options-list>
            </icz-popover>
          </div>
        }
        <div class="mt-12 col gap-12">
          <icz-checkbox
            label="Zobrazovat popisky akcí tabulek"
            class="icz-body-1"
            [checked]="(userSettingsService.showTableLabels$ | async)!"
            (changed)="setShowTableLabels($event)"
          ></icz-checkbox>
          <icz-checkbox
            label="Vypnout vyskakovací zelená okna potvrzující provedení aktivity"
            class="icz-body-1"
            [checked]="(userSettingsService.disableInfoToasts$ | async)!"
            (changed)="setDisableInfoToasts($event)"
          ></icz-checkbox>
        </div>
      </div>
    </div>
