<icz-wizard
  [showSubmitBar]="false"
  [waiting]="loadingService.isLoading$(subjectsService, SubjectLoaderIds.LINKING) | async">

  <ng-template
    iczWizardStep="search"
    label="Vyhledání"
    submitLabel="Vyhledání"
    [isValid]="false">
    <icz-subject-search-with-results class="mb-24"
                                     [showFooter]="true"
                                     [showSearchAppendix]="true"
                                     [allowShowLinkToStepCreate]="true"
                                     [searchOnlyIdentified]="true"
                                     [subjectToolbarContext]="SubjectToolbarContext.OVERVIEW"
                                     tableViewId="subjects-step-search"
                                     (advanceToCreate)="advanceToCreate($event)"
                                     [documentId]="documentId"
                                     [modalRef]="modalRef"
                                     [resultTableColumnSet]="SubjectTableColumnSet.RESULT_SET_FOR_LINKING"
                                     [relationType]="wizardSharedData!.relationType">
    </icz-subject-search-with-results>
  </ng-template>

  <ng-template
    iczWizardStep="create"
    label="Založení"
    submitLabel="Založení"
    [isValid]="false">
    <icz-add-subject-step-create
      class="mb-64"
      [form]="form"
      [dataForCreate]="wizardSharedData!"
      [documentId]="documentId"
      [modalRef]="modalRef"
    ></icz-add-subject-step-create>
  </ng-template>
</icz-wizard>
