/* tslint:disable */
/* eslint-disable */

/**
 * Type of digital component version flag
 */
export enum DigitalComponentVersionTypeFlag {
  AUDIO = 'AUDIO',
  DOC = 'DOC',
  DOCX = 'DOCX',
  FO = 'FO',
  IMAGE = 'IMAGE',
  ODS = 'ODS',
  ODT = 'ODT',
  ODP = 'ODP',
  PDF = 'PDF',
  PDFA = 'PDFA',
  PPT = 'PPT',
  PPTX = 'PPTX',
  XLS = 'XLS',
  XLSX = 'XLSX',
  XML = 'XML',
  ZFO = 'ZFO',
  HTML = 'HTML',
  OTHER = 'OTHER'
}
