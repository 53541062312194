import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SubjectRecordCreateOrUpdateDto} from '|api/commons';
import {AddSubjectWizardData, StepCreateFormResult} from '../../../model/subjects.model';
import {
  AddSubjectStepCreateComponent
} from '../../../subjects/add-subject/add-subject-step-create/add-subject-step-create.component';
import {ConsignmentDocumentData} from '../abstract-consignment-dialog-data';
import {SubjectCreateFormComponent} from '../../../subjects/subject-create-form/subject-create-form.component';

@Component({
  selector: 'icz-consignment-consignee-creation',
  templateUrl: './consignment-consignee-creation.component.html',
  styleUrls: ['./consignment-consignee-creation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentConsigneeCreationComponent implements OnInit {
  @ViewChild('subjectCreateComponent')
  subjectCreateComponent!: AddSubjectStepCreateComponent;

  @Input({required: true})
  documentData!: ConsignmentDocumentData;
  @Input({required: true})
  consigneeCreationData!: AddSubjectWizardData;
  @Output()
  initialized = new EventEmitter<this>();
  @Output()
  subjectCreationRequested = new EventEmitter<SubjectRecordCreateOrUpdateDto>();
  @Output()
  blockingAction = new EventEmitter<boolean>();

  form = SubjectCreateFormComponent.getCreateSubjectFormGroup();

  createRequested = false;

  ngOnInit(): void {
    setTimeout(() => {
      this.initialized.emit(this);
    }, 0);
  }

  createResult(result: StepCreateFormResult) {
    this.subjectCreationRequested.emit(result.subject!);
  }

  createSubject() {
    this.createRequested = true;
  }
}
