/// <reference types="resize-observer-browser" />
import {
  CdkConnectedOverlay,
  CdkOverlayOrigin,
  ConnectedOverlayPositionChange,
  ConnectedPosition,
  OverlayRef
} from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {distinctUntilChanged, map} from 'rxjs/operators';
import * as uuid from 'uuid';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';


export type PopoverPosition = 'DownLeft' | 'UpLeft' | 'DownRight' | 'UpRight' | 'Left' | 'Right';

const ALLOWABLE_POPOVER_POSITIONS: Record<PopoverPosition, ConnectedPosition> = {
  UpLeft: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
  },
  DownLeft: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
  },
  UpRight: {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
  },
  DownRight: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
  },
  Left: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
  },
  Right: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
  },
};

function arePopoverPositionsEqual(pp1: ConnectedPosition, pp2: ConnectedPosition) {
  return (
    pp1.originX === pp2.originX &&
    pp1.originY === pp2.originY &&
    pp1.overlayX === pp2.overlayX &&
    pp1.overlayY === pp2.overlayY
  );
}


@Component({
  selector: 'icz-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopoverComponent implements AfterViewInit, IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  private el = inject(ElementRef);
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) overlayOrigin!: CdkOverlayOrigin;
  @Input({required: true}) isOpen!: boolean;
  @Input() allowHorizontalPlacement = false;
  @Input() withBackdrop = true;
  @Input() showContentWindow = true;
  @Input() zIndex = 1000;
  @Output() onOpen = new EventEmitter<Nullable<OverlayRef>>();
  @Output() onClose = new EventEmitter();
  @Output() onPopoverPositionChange = new EventEmitter<PopoverPosition>();
  @ViewChild(CdkConnectedOverlay) cdkConnectedOverlay!: CdkConnectedOverlay;

  popoverId = uuid.v4(); // required for successful resolution of this instance by plain querySelector over document
  isInnerContentOpen = false;

  allowedPositions: ConnectedPosition[] = [];

  private resizeObserver = new ResizeObserver(() => this.adjustPopoverSizeAndPosition());

  ngAfterViewInit() {
    if (this.isOpen) {
      this.initAutoPopoverAdjustments();
      this.adjustPopoverZIndex();
      this.adjustPopoverSizeAndPosition();
    }

    this.cdkConnectedOverlay.positionChange.pipe(
      distinctUntilChanged((prev, curr) => prev.connectionPair.overlayY === curr.connectionPair.overlayY),
      map((positionChange: ConnectedOverlayPositionChange) => {
        const currentPosition = positionChange.connectionPair;
        const positionNames = Object.keys(ALLOWABLE_POPOVER_POSITIONS) as PopoverPosition[];

        for (const positionName of positionNames) {
          if (arePopoverPositionsEqual(currentPosition, ALLOWABLE_POPOVER_POSITIONS[positionName])) {
            return positionName ;
          }
        }

        return 'DownLeft'; // sensible default
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(p => {
      this.onPopoverPositionChange.emit(p);
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.allowHorizontalPlacement) {
      this.allowedPositions = [
        ALLOWABLE_POPOVER_POSITIONS.DownLeft,
        ALLOWABLE_POPOVER_POSITIONS.DownRight,
        ALLOWABLE_POPOVER_POSITIONS.UpLeft,
        ALLOWABLE_POPOVER_POSITIONS.UpRight,
      ];

      if (this.allowHorizontalPlacement) {
        this.allowedPositions.push(
          ALLOWABLE_POPOVER_POSITIONS.Right,
          ALLOWABLE_POPOVER_POSITIONS.Left,
        );
      }
    }
  }

  connectedOverlayAttach() {
    this.adjustPopoverZIndex();
    this.adjustPopoverSizeAndPosition();
    this.onOpen.emit(this.cdkConnectedOverlay?.overlayRef);

    setTimeout(() => {
      this.initAutoPopoverAdjustments();
    }, 50); // avoids fast blinking between original and adjusted position
  }

  handleClose() {
    this.onClose.emit();
    this.isInnerContentOpen = false;
    this.resizeObserver.disconnect();

    // After closing a popover inside modal context, focus should be returned
    // to closest modal container element otherwise Esc keypress will not work.
    const underlyingModalEl: HTMLElement = this.el.nativeElement.closest('.mat-dialog-container');

    if (underlyingModalEl) {
      setTimeout(() => {
        underlyingModalEl.focus();
      }, 100);
    }
  }

  private initAutoPopoverAdjustments() {
    this.isInnerContentOpen = true;
    this.cd.detectChanges();

    const contentEl = document.querySelector(
      `.icz-popover-content-window[data-popover-id="${this.popoverId}"] > *`
    );

    if (contentEl) {
      this.resizeObserver.observe(contentEl);
    }
  }

  private adjustPopoverSizeAndPosition() {
    window.dispatchEvent(new Event('resize'));
  }

  private adjustPopoverZIndex() {
    const overlayRef = this.cdkConnectedOverlay?.overlayRef;

    if (overlayRef) {
      overlayRef.hostElement.style.zIndex = String(this.zIndex);
    }
  }

}
