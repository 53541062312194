export function applyPathParams(path: string, params?: Record<string, string | number>): string {
  let out = path;

  if (params) {
    for (const paramKey in params) {
      if (params.hasOwnProperty(paramKey)) {
        // @ts-ignore -- our node supports replaceAll
        out = out.replaceAll(`{${paramKey}}`, encodeURIComponent(String(params[paramKey])));
      }
    }
  }

  return out;
}
