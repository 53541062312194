import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ReceivedDigitalConsignmentDto, ReceivedEpdzMessageDetailDto} from '|api/sad';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {SubjectRecordDto} from '|api/commons';

import {SubjectAttributeType} from '../model/subject-attribute-type';


@Component({
  selector: 'icz-sender-info-databox',
  templateUrl: './sender-info-databox.component.html',
  styleUrls: ['./sender-info-databox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SenderInfoDataboxComponent {
  @Input()
  sectionLabel = '';

  /**
   * consignment and message are mutually exclusive inputs
   */
  @Input()
  message!: Nullable<ReceivedEpdzMessageDetailDto>;
  @Input()
  consignment!: Nullable<ReceivedDigitalConsignmentDto>;

  @Input()
  senderSubject!: Nullable<SubjectRecordDto>;

  get senderName() {
    if (this.senderSubject) {
      return this.senderSubject ? SubjectTemplateUtils.getSubjectName(this.senderSubject) : '';
    }
    else if (this.message) {
      return this.message.senderLabel ?? '';
    }
    else {
      return '';
    }
  }

  get senderAddress() {
    if (this.senderSubject) {
      const defaultSubjectAddress = SubjectTemplateUtils.getBusinessSortedAddresses(this.senderSubject)[0];
      return defaultSubjectAddress ? SubjectTemplateUtils.getAddressString(defaultSubjectAddress, this.senderSubject.classification, true) : '';
    }
    else if (this.message) {
      return this.message.senderAddress ?? '';
    }
    else {
      return '';
    }
  }

  readonly SubjectAttributeType = SubjectAttributeType;
}
