/* tslint:disable */
/* eslint-disable */
export enum DocumentAuthorizedOperation {
  DOCUMENT_CREATE_RECEIVED_DOCUMENT_RESPONSE = 'DOCUMENT_CREATE_RECEIVED_DOCUMENT_RESPONSE',
  DOCUMENT_CREATE_FILE = 'DOCUMENT_CREATE_FILE',
  DOCUMENT_REMOVE_FROM_FILE = 'DOCUMENT_REMOVE_FROM_FILE',
  DOCUMENT_DUPLICATE = 'DOCUMENT_DUPLICATE',
  DOCUMENT_SHOW_PROFILE = 'DOCUMENT_SHOW_PROFILE',
  DOCUMENT_MODIFY_PROFILE = 'DOCUMENT_MODIFY_PROFILE',
  DOCUMENT_SHOW_COMPONENTS = 'DOCUMENT_SHOW_COMPONENTS',
  DOCUMENT_ADD_DIGITAL_COMPONENT = 'DOCUMENT_ADD_DIGITAL_COMPONENT',
  DOCUMENT_ADD_PAPER_COMPONENT = 'DOCUMENT_ADD_PAPER_COMPONENT',
  DOCUMENT_ADD_MEDIUM_COMPONENT = 'DOCUMENT_ADD_MEDIUM_COMPONENT',
  DOCUMENT_ADD_PHYSICAL_ITEM_COMPONENT = 'DOCUMENT_ADD_PHYSICAL_ITEM_COMPONENT',
  DOCUMENT_SHOW_OWN_CONSIGNMENTS = 'DOCUMENT_SHOW_OWN_CONSIGNMENTS',
  DOCUMENT_ADD_OWN_CONSIGNMENT = 'DOCUMENT_ADD_OWN_CONSIGNMENT',
  DOCUMENT_PRINT_OWN_CONSIGNMENT_LIST = 'DOCUMENT_PRINT_OWN_CONSIGNMENT_LIST',
  DOCUMENT_SHOW_RECEIVED_CONSIGNMENTS = 'DOCUMENT_SHOW_RECEIVED_CONSIGNMENTS',
  DOCUMENT_SHOW_RELATED_OBJECTS = 'DOCUMENT_SHOW_RELATED_OBJECTS',
  DOCUMENT_ADD_RELATED_OBJECT = 'DOCUMENT_ADD_RELATED_OBJECT',
  DOCUMENT_REMOVE_RELATED_OBJECT = 'DOCUMENT_REMOVE_RELATED_OBJECT',
  DOCUMENT_SHOW_SUBJECTS = 'DOCUMENT_SHOW_SUBJECTS',
  DOCUMENT_ADD_SUBJECT = 'DOCUMENT_ADD_SUBJECT',
  DOCUMENT_SHOW_SUBJECT = 'DOCUMENT_SHOW_SUBJECT',
  DOCUMENT_MODIFY_SUBJECT = 'DOCUMENT_MODIFY_SUBJECT',
  DOCUMENT_REMOVE_SUBJECT = 'DOCUMENT_REMOVE_SUBJECT',
  DOCUMENT_IDENTIFY_SUBJECT = 'DOCUMENT_IDENTIFY_SUBJECT',
  DOCUMENT_SHOW_SHARING = 'DOCUMENT_SHOW_SHARING',
  DOCUMENT_MANAGE_SHARING = 'DOCUMENT_MANAGE_SHARING',
  DOCUMENT_SHOW_HISTORY = 'DOCUMENT_SHOW_HISTORY',
  DOCUMENT_SHOW_ACTIVITIES = 'DOCUMENT_SHOW_ACTIVITIES',
  DOCUMENT_CREATE_HANDOVER_ACTIVITY = 'DOCUMENT_CREATE_HANDOVER_ACTIVITY',
  DOCUMENT_CREATE_APPROVAL_ACTIVITY = 'DOCUMENT_CREATE_APPROVAL_ACTIVITY',
  DOCUMENT_CREATE_STATEMENT_ACTIVITY = 'DOCUMENT_CREATE_STATEMENT_ACTIVITY',
  DOCUMENT_CREATE_ACKNOWLEDGEMENT_ACTIVITY = 'DOCUMENT_CREATE_ACKNOWLEDGEMENT_ACTIVITY',
  DOCUMENT_REVOKE_TASK = 'DOCUMENT_REVOKE_TASK',
  DOCUMENT_BEGIN_TASK = 'DOCUMENT_BEGIN_TASK',
  DOCUMENT_REJECT_TASK = 'DOCUMENT_REJECT_TASK',
  DOCUMENT_DELEGATE_TASK = 'DOCUMENT_DELEGATE_TASK',
  DOCUMENT_TAKEOVER = 'DOCUMENT_TAKEOVER',
  DOCUMENT_TAKEOVER_AND_DELEGATE = 'DOCUMENT_TAKEOVER_AND_DELEGATE',
  DOCUMENT_APPROVE_OR_DISAPPROVE = 'DOCUMENT_APPROVE_OR_DISAPPROVE',
  DOCUMENT_MAKE_STATEMENT = 'DOCUMENT_MAKE_STATEMENT',
  DOCUMENT_ACKNOWLEDGE = 'DOCUMENT_ACKNOWLEDGE',
  DOCUMENT_REGISTER = 'DOCUMENT_REGISTER',
  DOCUMENT_SETTLE = 'DOCUMENT_SETTLE',
  DOCUMENT_REVOKE_SETTLEMENT = 'DOCUMENT_REVOKE_SETTLEMENT',
  DOCUMENT_PASS_ON_TO_EXTERNAL_ORGANIZATION = 'DOCUMENT_PASS_ON_TO_EXTERNAL_ORGANIZATION',
  DOCUMENT_DEACTIVATE = 'DOCUMENT_DEACTIVATE',
  DOCUMENT_REACTIVATE = 'DOCUMENT_REACTIVATE'
}
