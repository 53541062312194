/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ownDocumentCreate } from '../fn/own-document/own-document-create';
import { ownDocumentCreate_1 } from '../fn/own-document/own-document-create-1';
import { OwnDocumentCreate_1$Params } from '../fn/own-document/own-document-create-1';
import { OwnDocumentCreate$Params } from '../fn/own-document/own-document-create';
import { OwnDocumentDto } from '../models/own-document-dto';
import { ownDocumentFindById } from '../fn/own-document/own-document-find-by-id';
import { OwnDocumentFindById$Params } from '../fn/own-document/own-document-find-by-id';
import { ownDocumentUpdate } from '../fn/own-document/own-document-update';
import { OwnDocumentUpdate$Params } from '../fn/own-document/own-document-update';


/**
 * API for management of own documents
 */
@Injectable({ providedIn: 'root' })
export class ApiOwnDocumentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `ownDocumentFindById()` */
  static readonly OwnDocumentFindByIdPath = '/document/own-document/{id}';

  /**
   * Find own document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownDocumentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownDocumentFindById$Response(params: OwnDocumentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDocumentDto>> {
    return ownDocumentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find own document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownDocumentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  ownDocumentFindById(params: OwnDocumentFindById$Params, context?: HttpContext): Observable<OwnDocumentDto> {
    return this.ownDocumentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDocumentDto>): OwnDocumentDto => r.body)
    );
  }

  /** Path part for operation `ownDocumentUpdate()` */
  static readonly OwnDocumentUpdatePath = '/document/own-document/{id}';

  /**
   * Update own document.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownDocumentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownDocumentUpdate$Response(params: OwnDocumentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDocumentDto>> {
    return ownDocumentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update own document.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownDocumentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownDocumentUpdate(params: OwnDocumentUpdate$Params, context?: HttpContext): Observable<OwnDocumentDto> {
    return this.ownDocumentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDocumentDto>): OwnDocumentDto => r.body)
    );
  }

  /** Path part for operation `ownDocumentCreate()` */
  static readonly OwnDocumentCreatePath = '/document/own-document/batch';

  /**
   * Creates batch of own documents
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownDocumentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownDocumentCreate$Response(params: OwnDocumentCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<OwnDocumentDto>>> {
    return ownDocumentCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates batch of own documents
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownDocumentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownDocumentCreate(params: OwnDocumentCreate$Params, context?: HttpContext): Observable<Array<OwnDocumentDto>> {
    return this.ownDocumentCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OwnDocumentDto>>): Array<OwnDocumentDto> => r.body)
    );
  }

  /** Path part for operation `ownDocumentCreate_1()` */
  static readonly OwnDocumentCreate_1Path = '/document/own-document/';

  /**
   * Creates new own document
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `ownDocumentCreate_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownDocumentCreate_1$Response(params: OwnDocumentCreate_1$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnDocumentDto>> {
    return ownDocumentCreate_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new own document
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `ownDocumentCreate_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  ownDocumentCreate_1(params: OwnDocumentCreate_1$Params, context?: HttpContext): Observable<OwnDocumentDto> {
    return this.ownDocumentCreate_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnDocumentDto>): OwnDocumentDto => r.body)
    );
  }

}
