import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {Cacheable} from 'ts-cacheable';
import {BackendStatus} from '../../api';
import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {ApiService} from '../services/api.service';
import {ENVIRONMENT} from '../services/environment.service';

export interface HealthcheckResult {
  isUp: boolean;
  redirectTo?: ApplicationRoute; // used when isUp is false to redirect to appropriate screen
}

const HEALTHCHECK_DATA_VALIDITY = 1000; // ms

@Injectable({
  providedIn: 'root'
})
export class HealthcheckService {

  private backendStatusService = inject(ApiService);
  private environment = inject(ENVIRONMENT);

  lastUsedRoute: Nullable<string>;

  getBackendHealthcheckResult(): Observable<HealthcheckResult> {
    if (this.environment.disableBackendHealthchecks) {
      return of({
        isUp: true,
      });
    }
    else {
      return this.backendHealthcheckStatus();
    }
  }

  // Having this cached debounces frequent requests to healthcheck endpoint
  //  which prevents creating ineffective requests.
  //  Our app is not so critical to have so fine healthcheck periods.
  @Cacheable({maxAge: HEALTHCHECK_DATA_VALIDITY})
  private backendHealthcheckStatus() {
    return this.backendStatusService.backendIsReady().pipe(
      map((backendStatus: BackendStatus) => {
        if (backendStatus === BackendStatus.UP) {
          return {
            isUp: true,
          };
        }
        else if (
          backendStatus === BackendStatus.DOWN ||
          backendStatus === BackendStatus.NETWORK_UNAVAILABLE
        ) {
          this.storeLastUsedRoute();

          if (backendStatus === BackendStatus.DOWN) {
            return {
              isUp: false,
              redirectTo: ApplicationRoute.WAITING_TO_BE,
            };
          }
          else {
            return {
              isUp: false,
              redirectTo: ApplicationRoute.NETWORK_UNAVAILABLE,
            };
          }
        }
        else {
          return {
            isUp: false,
          };
        }
      }),
    );
  }

  private storeLastUsedRoute() {
    if (
      !location.pathname.includes(ApplicationRoute.WAITING_TO_BE) &&
      !location.pathname.includes(ApplicationRoute.APPLICATION_UNAVAILABLE)
    ) {
      // Application router might not have been initialized yet thus using
      //  the value from browser address bar (invalid routes will get
      //  corrected by the router after the application becomes healthy).
      this.lastUsedRoute = location.pathname;
    }
  }
}
