import {TemplateRef} from '@angular/core';
import {
  BaseFilterDefinition,
  BASIC_FILTER_TYPES,
  BasicFilterDefinition,
  CodebookFilterDefinition,
  CombinedFilterDefinition,
  EnumFilterDefinition,
  FilterSubValue,
  FilterType
} from './filter.types';
import {FilterOperator, getOperatorTranslationKey, SortParam} from '../../services/search-api.service';
import {Option} from '../../model';
import {IconSize} from '../essentials/icon/icon.component';

export const MIN_SEARCH_TERM_LENGTH = 2;

export type AutoPageSizeByOffsets = {
  screenTopOffset: number,
  screenBottomOffset: number
};

export type AutoPageSizeByManualHeight = {
  tableHeight: number,
};

export type AutoPageSizeConfig = true | AutoPageSizeByOffsets | AutoPageSizeByManualHeight;

export type TableTemplates = {
  [columnId: string]: {
    template: TemplateRef<any>,
    withEllipsis: boolean,
  }
};

export interface ResizeLineBounds {
  x: number;
  y: number;
  height: number;
}

export interface TableConfig<TColumnKey extends string, TC = TableToolbarConfig> {
  hasActiveRow: boolean;
  hidePageSize: boolean;
  defaultSort: Nullable<SortParam<TColumnKey>>;
  defaultFilterColumns: TColumnKey[];
  loadDataOnInitOrDatasourceChange: boolean;
  hoverableRows: boolean;
  rowHeight: number;
  autoPageSizeConfig: AutoPageSizeConfig;
  allowMultiPageSelection: boolean;
  disableLocalStorageSortPersistence: boolean;
  toolbarConfig: TC;
}

export type TableConfigExtensions<TColumnKey extends string> = Partial<TableConfig<TColumnKey, Partial<TableToolbarConfig>>>;

export interface TableToolbarConfig {
  showToolbar: boolean;
  showFilter: boolean; // show filter toggle button
  showReload: boolean;
  showTools: boolean;
  showFulltextSearch: boolean;
  showSavedFilters: boolean;
  showDimensions: boolean;
  showColumnSelector: boolean;
  filterIsStatic: boolean;
  autoOpenFilter: boolean;
  disableFilter: boolean;
}

export interface ColumnProperties {
  displayed?: boolean;
  toggleable?: boolean;
  icon?: string;
  iconSize?: IconSize;
  fixedWidth?: number; // If fixedWidth is defined, fixedWidth takes precedence both over minWidth and maxWidth and column cannot be resized.
  minWidth?: number;
  maxWidth?: number;
  defaultWidth?: number;
  disableSort?: boolean;
  hideLabelInHeader?: boolean;
  allowSettingInContextMenu?: boolean; // Should only ever be set to FALSE together with displayed: false as well
}

export type ColumnDefinition<T extends string = never> = ColumnProperties & BaseFilterDefinition<T>;
export type CombinedColumnDefinition<T extends string = never> = ColumnProperties & CombinedFilterDefinition<T>;

export function isBasicColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & BasicFilterDefinition<string> {
  return (BASIC_FILTER_TYPES as unknown as FilterType[]).includes(cd?.filterType!);
}

export function isEnumColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & EnumFilterDefinition<string> {
  return cd?.filterType === FilterType.ENUM;
}

export function isCodebookColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & CodebookFilterDefinition<string> {
  return cd?.filterType === FilterType.CODEBOOK;
}

export function isListColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & (EnumFilterDefinition<string> | CodebookFilterDefinition<string>) {
  return isEnumColumnDefinition(cd) || isCodebookColumnDefinition(cd);
}

export interface DeferredColumnList {
  list: Option[];
  isListLoading: boolean;
}

export interface FilterValue {
  value: Nullable<string | string[]>;
  list?: Option<Nullable<string | number>, any>[];
  subValues?: Nullable<FilterSubValue[]>;
  viewValue?: string | number | string[];
  label: Nullable<string>;
  filterOperator: Nullable<FilterOperator>;
  closeAfter?: boolean;
}

const EQUALS = {label: getOperatorTranslationKey(FilterOperator.equals), value: FilterOperator.equals, icon: 'equal'};
const NOT_EQUALS = {label: getOperatorTranslationKey(FilterOperator.notEquals), value: FilterOperator.notEquals, icon: 'unequal'};
const EMPTY = {label: getOperatorTranslationKey(FilterOperator.empty), value: FilterOperator.empty, icon: 'empty'};
const NOT_EMPTY = {label: getOperatorTranslationKey(FilterOperator.notEmpty), value: FilterOperator.notEmpty, icon: 'not_empty'};
const CONTAINS = {label: getOperatorTranslationKey(FilterOperator.contains), value: FilterOperator.contains, icon: 'contains'};
const DOES_NOT_CONTAINS = {label: getOperatorTranslationKey(FilterOperator.doesNotContains), value: FilterOperator.doesNotContains, icon: 'does_not_contain'};
const STARTS_WITH = {label: getOperatorTranslationKey(FilterOperator.startsWith), value: FilterOperator.startsWith, icon: 'starting_with'};
const ENDS_WITH = {label: getOperatorTranslationKey(FilterOperator.endsWith), value: FilterOperator.endsWith, icon: 'ending'};
const GREATER = {label: getOperatorTranslationKey(FilterOperator.greater), value: FilterOperator.greater, icon: 'bigger'};
const GREATER_OR_EQUAL = {label: getOperatorTranslationKey(FilterOperator.greaterOrEqual), value: FilterOperator.greaterOrEqual, icon: 'bigger_or_equal'};
const LESS = {label: getOperatorTranslationKey(FilterOperator.less), value: FilterOperator.less, icon: 'smaller'};
const LESS_OR_EQUAL = {label: getOperatorTranslationKey(FilterOperator.lessOrEqual), value: FilterOperator.lessOrEqual, icon: 'smaller_or_equal'};

export const allToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  EMPTY,
  NOT_EMPTY,
  CONTAINS,
  DOES_NOT_CONTAINS,
  STARTS_WITH,
  ENDS_WITH,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL
];

export const textToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  CONTAINS,
  DOES_NOT_CONTAINS,
  STARTS_WITH,
  ENDS_WITH,
  EMPTY,
  NOT_EMPTY,
];

export const listToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  EMPTY,
  NOT_EMPTY,
];

export const numberToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
  EMPTY,
  NOT_EMPTY,
];

export const fileSizeToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
];

export const empowermentToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
];

export const subjectRecordToolbarOperators = [EQUALS];

export const addressToolbarOperators = [
  CONTAINS,
  DOES_NOT_CONTAINS,
  EMPTY,
  NOT_EMPTY
];

export const dateToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
  EMPTY,
  NOT_EMPTY,
];

export const dateStatisticsToolbarOperators: Array<Option<FilterOperator>> = [
  EQUALS,
];

export const booleanOperator: Array<Option<FilterOperator>> = [
  EQUALS
];
