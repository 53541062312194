/* tslint:disable */
/* eslint-disable */

/**
 * Registry office transfer state
 */
export enum RegistryOfficeTransferState {
  HANDED_OVER = 'HANDED_OVER',
  TO_RESOLVE_ISSUES = 'TO_RESOLVE_ISSUES',
  TAKEN_OVER = 'TAKEN_OVER',
  TAKEN_OVER_FOR_SAVING = 'TAKEN_OVER_FOR_SAVING',
  TO_RESOLVE_ISSUES_BY_REGISTRY_OFFICE = 'TO_RESOLVE_ISSUES_BY_REGISTRY_OFFICE',
  TO_RESOLVE_ISSUES_BY_OWNER = 'TO_RESOLVE_ISSUES_BY_OWNER',
  SAVED_TO_DESA = 'SAVED_TO_DESA'
}
