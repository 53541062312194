/* tslint:disable */
/* eslint-disable */

/**
 * Email notification subtype. Specifies subtype of parent type EmailNotificationType.
 */
export enum EmailNotificationSubtype {
  OPERATION_DOCUMENT = 'OPERATION_DOCUMENT',
  OPERATION_FILE = 'OPERATION_FILE',
  DEADLINE_DOCUMENT_SETTLEMENT_DATE = 'DEADLINE_DOCUMENT_SETTLEMENT_DATE',
  DEADLINE_FILE_SETTLEMENT_DATE = 'DEADLINE_FILE_SETTLEMENT_DATE',
  DEADLINE_FILE_SETTLEMENT_RESUME_DATE = 'DEADLINE_FILE_SETTLEMENT_RESUME_DATE',
  DEADLINE_CIRCULATION_TASK_DEADLINE = 'DEADLINE_CIRCULATION_TASK_DEADLINE',
  DEADLINE_RETENTION_TRIGGER = 'DEADLINE_RETENTION_TRIGGER',
  CIRCULATION_OWNER_CHANGE = 'CIRCULATION_OWNER_CHANGE',
  CIRCULATION_COMPONENT_SIGN = 'CIRCULATION_COMPONENT_SIGN',
  CIRCULATION_APPROVAL = 'CIRCULATION_APPROVAL',
  CIRCULATION_STATEMENT = 'CIRCULATION_STATEMENT',
  CIRCULATION_ACKNOWLEDGMENT = 'CIRCULATION_ACKNOWLEDGMENT',
  DISPATCH = 'DISPATCH'
}
