import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {InterpolationContext} from '../../../lib/model';

@Component({
  selector: 'icz-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelComponent {
  @Input({required: true}) label: Nullable<string>;
  @Input() labelContext: Nullable<InterpolationContext>;
  @Input() hasNormalWhitespace = false;

  @Input() set justifyContent(value: string | '') { this._justifyContent = value === '' ? 'center' : value; }
  get justifyContent() { return this._justifyContent; }
  _justifyContent = 'center';

  @Input() set alignItems(value: string | '') { this._alignItems = value === '' ? 'center' : value; }
  get alignItems() { return this._alignItems; }
  _alignItems = 'center';

  @Input() set dontTranslate(value: boolean | '') { this._dontTranslate = value === '' ? true : value; }
  get dontTranslate() { return this._dontTranslate; }
  _dontTranslate = false;

  @HostBinding('class.primary')
  @Input()
  set primary(value: boolean | '') { this._primary = value === '' ? true : value; }
  get primary() { return this._primary; }
  _primary = false;

  @HostBinding('style.justify-content')
  get justify() {
    return this.justifyContent;
  }

  @HostBinding('class.secondary')
  @Input()
  set secondary(value: string | boolean) { this._secondary = value !== false; }
  get secondary() { return this._secondary; }
  _secondary = false;

  @HostBinding('class.light')
  @Input()
  set light(value: string | boolean) { this._light = value !== false; }
  get light() { return this._light; }
  _light = false;

  @HostBinding('class.bold')
  @Input()
  set bold(value: string | boolean) { this._bold = value !== false; }
  get bold() { return this._bold; }
  _bold = false;

  @HostBinding('style.align-items')
  get align() {
    return this.alignItems;
  }
}
