import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ApiDocumentService} from '|api/document';
import {ApiOwnConsignmentService, OwnConsignmentBulkHandleOperationsDto, OwnConsignmentWithdrawalDto} from '|api/sad';
import {
  WithdrawForCorrectionDialogData,
  WithdrawForCorrectionDialogResult,
  WithdrawForCorrectionDialogResultType
} from './withdraw-for-correction-dialog.service';
import {GenericOwnConsignment} from '../../../own-consignment-table/model/own-consignment-model';
import {
  isOwnDigitalConsignment,
  isOwnInternalDigitalConsignment,
  isOwnInternalPaperConsignment,
  isOwnOfficeDeskConsignment,
  isOwnPaperConsignment
} from '../../../own-consignment-table/own-consignment-workflow.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {constructBulkModalTitle} from '../../../../../lib/utils';
import {injectModalData, injectModalRef} from '../../../../../lib/modals';


@Component({
  selector: 'icz-withdraw-for-correction',
  templateUrl: './withdraw-for-correction-dialog.component.html',
  styleUrls: ['./withdraw-for-correction-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WithdrawForCorrectionDialogComponent implements OnInit {

  private modalRef = injectModalRef<Nullable<WithdrawForCorrectionDialogResult>>();
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private apiDocumentService = inject(ApiDocumentService);
  private translateService = inject(TranslateService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected modalData = injectModalData<WithdrawForCorrectionDialogData>();

  form = new IczFormGroup({
    withdrawReason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  ngOnInit() {
    if (this.modalData.consignments) {
      const isBulk = this.modalData.consignments.length > 1;
      if (isBulk) {
        this.modalRef.setModalTitle({
          title: constructBulkModalTitle('Stažení zásilky k nápravě'),
          titleContext: {count: this.modalData.consignments.length.toString()},
        });
      } else {
        this.apiDocumentService.documentFindById({id: this.modalData.documentId}).subscribe(doc => {
          this.modalRef.setModalTitle({
            title: 'Stažení zásilky k nápravě {{refNumber}}: {{subject}}',
            titleContext: {
              refNumber: doc.refNumber ?? this.translateService.instant('bez čísla jednacího'),
              subject: doc.subject
            },
          });
        });
      }
    }
  }

  withdrawForCorrection() {
    const formValue = this.form.getRawValue() as OwnConsignmentWithdrawalDto;

    const isBulk = this.modalData.consignments.length > 1;
    let req$: Observable<GenericOwnConsignment | void>;

    if (isBulk) {
      const reqData: OwnConsignmentBulkHandleOperationsDto = {
        reason: formValue.withdrawReason,
        bulkDataDto: this.modalData.consignments.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        }),
      };

      req$ = this.apiOwnConsignmentService.ownConsignmentBulkWithdraw({body: reqData});
    } else {
      const reqData = {id: this.modalData.consignments[0].id, body: formValue};
      const c = this.modalData.consignments[0];

      if (isOwnPaperConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawPaper(reqData);
      } else if (isOwnOfficeDeskConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawOfficeDesk(reqData);
      } else if (isOwnInternalDigitalConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawInternalDigital(reqData);
      } else if (isOwnInternalPaperConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawInternalPaper(reqData);
      } else if (isOwnDigitalConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawDigital(reqData);
      } else {
        throw new Error('Wrong type of own consignment for withdraw!');
      }
    }

    this.loadingIndicatorService.doLoading(
      req$,
      this,
    ).subscribe(ownConsignment => {
      this.modalRef.close({
        resultType: WithdrawForCorrectionDialogResultType.WithdrawnForCorrection,
        consignment: ownConsignment,
      } as WithdrawForCorrectionDialogResult);
    });
  }

  close() {
    this.modalRef.close(null);
  }

}
