import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  GenericChartConfig,
  IczStatistic,
  StatisticTrendCounterMode,
  TrendCounterDefinition,
  TrendCounterStoredSettings,
  TrendPeriod
} from '../statistics-model';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {StatisticDashboardToggleComponent} from './statistic-dashboard-toggle/statistic-dashboard-toggle.component';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczModalService} from '../../../services/icz-modal.service';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {
  ApiStatisticsService,
  Statistic,
  StatisticsDimension,
  StatisticTileDto,
  StatisticTileRequestDto
} from '|api/elastic';
import {StatisticTrendConfig} from './statistic-trend-counter/statistic-trend-counter.component';
import {BehaviorSubject} from 'rxjs';
import {WebSocketNotificationsService} from '../../notifications/web-socket-notifications.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DashboardLayoutService} from '../../../services/dashboard-layout.service';

enum StatisticDashboardMode {
  DASHBOARD = 'DASHBOARD',
  DETAIL = 'DETAIL',
}

const STATISTICS_TOPIC = '/user/topic/statistic';


@Component({
  selector: 'icz-statistics-dashboard',
  templateUrl: './statistics-dashboard.component.html',
  styleUrls: ['./statistics-dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsDashboardComponent implements OnInit, OnDestroy {

  loadingIndicatorService = inject(LoadingIndicatorService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private iczModalService = inject(IczModalService);
  private userSettingsService = inject(UserSettingsService);
  private apiStatisticsService = inject(ApiStatisticsService);
  private webSocketNotificationsService = inject(WebSocketNotificationsService);
  protected destroyRef = inject(DestroyRef);
  protected dashboardLayoutService = inject(DashboardLayoutService);

  mode = StatisticDashboardMode.DASHBOARD;
  selectedStatistics: Nullable<IczStatistic>;

  statisticTrendConfigsColumnGrid: StatisticTrendConfig[][] = [[], [], []];
  statisticsCountMessage$ = new BehaviorSubject<Nullable<StatisticTileDto>>(null);
  statisticsCountCache: Partial<Record<Statistic, StatisticTileDto>> = {};

  readonly StatisticDashboardMode = StatisticDashboardMode;

  trendCounters: TrendCounterDefinition[] = [
    {
      id: IczStatistic.NEW_DOCUMENT_COUNT,
      label: 'Počet nových dokumentů',
      isTimeSpecific: true,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.NEW_DOCUMENT_COUNT}`,
      statisticKey: Statistic.DOCUMENTS_RANGE_COUNT,
      mode: StatisticTrendCounterMode.COUNT,
      order: 0,
      columnIndex: 0,
    },
    {
      id: IczStatistic.NEW_FILE_COUNT,
      label: 'Počet nových spisů',
      isTimeSpecific: true,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.NEW_FILE_COUNT}`,
      statisticKey: Statistic.FILES_RANGE_COUNT,
      mode: StatisticTrendCounterMode.COUNT,
      order: 0,
      columnIndex: 1,
    },
    {
      id: IczStatistic.DOCUMENT_COUNT,
      label: 'Počet dokumentů',
      isTimeSpecific: false,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.DOCUMENT_COUNT}`,
      statisticKey: Statistic.DOCUMENTS_TOTAL_COUNT,
      mode: StatisticTrendCounterMode.COUNT,
      isDetailTestingFeature: true,
      order: 0,
      columnIndex: 2,
    },
    {
      id: IczStatistic.FILE_COUNT,
      label: 'Počet spisů',
      isTimeSpecific: false,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.FILE_COUNT}`,
      statisticKey: Statistic.FILES_TOTAL_COUNT,
      mode: StatisticTrendCounterMode.COUNT,
      isDetailTestingFeature: true,
      order: 1,
      columnIndex: 0,
    },
    {
      id: IczStatistic.DOCUMENT_SIZE,
      label: 'Velikost dokumentů',
      isTimeSpecific: false,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.DOCUMENT_SIZE}`,
      statisticKey: Statistic.DOCUMENT_SIZE_COUNT,
      mode: StatisticTrendCounterMode.TABLE,
      isDetailTestingFeature: true,
      order: 1,
      columnIndex: 1,
    },
    {
      id: IczStatistic.FILE_SIZE,
      label: 'Velikost spisů',
      isTimeSpecific: false,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.FILE_SIZE}`,
      statisticKey: Statistic.FILE_SIZE_COUNT,
      mode: StatisticTrendCounterMode.TABLE,
      isDetailTestingFeature: true,
      order: 1,
      columnIndex: 2,
    },
    {
      id: IczStatistic.FILE_BY_PERMISSION_COUNT,
      label: 'Počet spisú podle přístupových oprávnění',
      isTimeSpecific: false,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.FILE_BY_PERMISSION_COUNT}`,
      statisticKey: Statistic.FILES_BY_ACCESS_ENTITLEMENT,
      mode: StatisticTrendCounterMode.TABLE,
      isDetailTestingFeature: true,
      order: 2,
      columnIndex: 0,
    },
    {
      id: IczStatistic.NEW_COMPONENT_COUNT,
      label: 'Počet nových komponent',
      isTimeSpecific: true,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.NEW_COMPONENT_COUNT}`,
      statisticKey: Statistic.DIGITAL_COMPONENT_RANGE_COUNT,
      mode: StatisticTrendCounterMode.COUNT,
      isDetailTestingFeature: true,
      order: 2,
      columnIndex: 1,
    },
    {
      id: IczStatistic.FILE_IN_STORING_UNIT_COUNT,
      label: 'Počet spisú v ukládacích jednotkách',
      isTimeSpecific: true,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.FILE_IN_STORING_UNIT_COUNT}`,
      statisticKey: Statistic.FILES_IN_STORAGE_UNIT_RANGE_COUNT,
      mode: StatisticTrendCounterMode.COUNT,
      isDetailTestingFeature: true,
      order: 2,
      columnIndex: 2,
    },

    /*{
      id: IczStatistic.NEW_ENTITY_CLASSES_CURRENT_CS,
      label: 'Nové věcné skupiny aktuálního Spisového plánu',
      isTimeSpecific: true,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.NEW_ENTITY_CLASSES_CURRENT_CS}`
    },
    {
      id: IczStatistic.SIP_PACKAGE_COUNT,
      label: 'Počet SIP balíčků',
      isTimeSpecific: true,
      tooltip: `fe.ui.tooltip.statistics.${IczStatistic.SIP_PACKAGE_COUNT}`
    },*/
    /*
  {
     id: IczStatistic.COMPONENTS,
     label: 'Počet nových komponent',
   },
   {
     id: IczStatistic.FILES_BY_COMPONENT_FILESIZE,
     label: 'Velikost spisů podle digitálního obsahu',
   },*/
  ];

  ngOnInit() {
    this.webSocketNotificationsService.stompClientCreated.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(created => {
      if (created) {
        this.webSocketNotificationsService.registerStompWatcher(STATISTICS_TOPIC, message => {
          const statisticsCount = message as StatisticTileDto;
          this.statisticsCountCache[statisticsCount.statistic as Statistic] = statisticsCount;
          this.statisticsCountMessage$.next(statisticsCount);
        });
        this.initTrendCards();
      }
    });
  }

  ngOnDestroy() {
    this.webSocketNotificationsService.unregisterStompWatcher(STATISTICS_TOPIC);
  }

  initTrendCards() {
    this.statisticTrendConfigsColumnGrid = [[], [], []];
    const initRequestBody: StatisticTileRequestDto[] = [];
    const sectionSettings = this.userSettingsService.getParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS);
    const userSettingsToStore: Partial<Record<IczStatistic, TrendCounterStoredSettings>> = {};
    let statisticTileConfig: StatisticTrendConfig;
    const fromDate = new Date();
    const fromDateLast7 = new Date();
    const fromDateLast30 = new Date();
    fromDateLast7.setDate(fromDate.getDate() - 7);
    fromDateLast30.setDate(fromDate.getDate() - 30);
    this.trendCounters.forEach((trendCounterDef, index) => {
      let settings: TrendCounterStoredSettings;
      if (sectionSettings && sectionSettings[trendCounterDef.id]) {
        const userSettings = sectionSettings[trendCounterDef.id] as TrendCounterStoredSettings;
        statisticTileConfig = {
          ...trendCounterDef,
          dimensions: [StatisticsDimension.ENTITY_CLASS, StatisticsDimension.ENTITY_STATE, StatisticsDimension.SECURITY_CATEGORY],
          allowLineChart: true,
          allowBarChart: false,
          allowDonutChart: false,
          barConfig: this.sampleBarConfig,
          donutConfig: this.sampleDonutConfig,
          order: userSettings.order,
          displayed: userSettings.displayed,
          period: userSettings.period,
        };
        settings = {...userSettings};
      } else {
        statisticTileConfig = {
          ...trendCounterDef,
          dimensions: [StatisticsDimension.ENTITY_CLASS, StatisticsDimension.ENTITY_STATE, StatisticsDimension.SECURITY_CATEGORY],
          allowLineChart: true,
          allowBarChart: false,
          allowDonutChart: false,
          barConfig: this.sampleBarConfig,
          donutConfig: this.sampleDonutConfig,
          order: index,
          displayed: true,
          period: TrendPeriod.LAST_7_DAYS,
        };
        settings = {
          id: trendCounterDef.id,
          order: trendCounterDef.order,
          displayed: true,
          period: TrendPeriod.LAST_7_DAYS,
          columnIndex: trendCounterDef.columnIndex,
        };
      }
      userSettingsToStore[trendCounterDef.id] = settings;

      this.statisticTrendConfigsColumnGrid[settings.columnIndex][settings.order] = statisticTileConfig;

      const tileRequest: StatisticTileRequestDto = {
        statistic: trendCounterDef.statisticKey,
      };

      if (statisticTileConfig.isTimeSpecific) {
        tileRequest.watchedPeriodToDate = new Date().toISOString();
        if (statisticTileConfig.period === TrendPeriod.LAST_7_DAYS) {
          tileRequest.watchedPeriodFromDate = fromDateLast7.toISOString();
        } else {
          tileRequest.watchedPeriodFromDate = fromDateLast30.toISOString();
        }
      }

      initRequestBody.push(tileRequest);
    });

    this.apiStatisticsService.statisticsGetStatisticTilesAsync({body: initRequestBody}).subscribe();
    this.userSettingsService.setParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS, userSettingsToStore);
    this.changeDetectorRef.detectChanges();
  }

  itemDropped(event: CdkDragDrop<StatisticTrendConfig[], any>) {
    const itemData = event.item.data as StatisticTrendConfig;
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const columnIndex = Number(String(event.container.id).split('_')[1]);
      this.storeReorderedConfigs([columnIndex]);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
      const previousColumnIndex = Number(String(event.previousContainer.id).split('_')[1]);
      const currentColumnIndex = Number(String(event.container.id).split('_')[1]);
      this.storeReorderedConfigs([previousColumnIndex, currentColumnIndex]);

      this.statisticsCountMessage$.next(this.statisticsCountCache[itemData.statisticKey]);
    }
  }

  private storeReorderedConfigs(columnIndexes: number[]) {
    const statisticsSettings = this.userSettingsService.getParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS);
    if (statisticsSettings) {
      columnIndexes.forEach(columnIndex => {
        this.statisticTrendConfigsColumnGrid[columnIndex].forEach((counterConfig, index) => {
          const userTileSettings = statisticsSettings[counterConfig.id] as TrendCounterStoredSettings;
          userTileSettings.order = index;
          userTileSettings.columnIndex = columnIndex;
        });
      });
      this.userSettingsService.setParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS, statisticsSettings);
    }
  }

  getStatisticConfig(id: IczStatistic): Nullable<StatisticTrendConfig> {
    for (const column of this.statisticTrendConfigsColumnGrid) {
      for (const tileConfig of column) {
        if (tileConfig.id === id) {
          return tileConfig!;
        }
      }
    }
    return null;
  }

  getTableTitle(id: IczStatistic): string {
    return this.trendCounters.find(s => s.id === id)!.label;
  }

  isStatisticTrendNormal(id: IczStatistic) {
    return this.getStatisticConfig(id)?.mode === StatisticTrendCounterMode.COUNT;
  }

  isStatisticTrendExtended(id: IczStatistic) {
    return this.getStatisticConfig(id)?.mode === StatisticTrendCounterMode.TABLE;
  }

  goToDashboard() {
    this.mode = StatisticDashboardMode.DASHBOARD;
    this.initTrendCards();
    this.dashboardLayoutService.showDashboardHeader$.next(true);
  }

  goToDetail(id: IczStatistic) {
    if (id) {
      this.mode = StatisticDashboardMode.DETAIL;
      this.selectedStatistics = id;
      this.dashboardLayoutService.showDashboardHeader$.next(false);
    }
  }

  openSettings() {
    this.iczModalService.openComponentInModal<boolean, void>({
      component: StatisticDashboardToggleComponent,
      modalOptions: {
        width: 1000,
        height: 600,
        titleTemplate: 'Nastavení statistické nástěnky',
      }
    }).subscribe(_ => {
      this.initTrendCards();
    });
  }

  mockComponentRangesLabels = ['< 10 MB', '10 - 20 MB', '20 - 30 MB', '30 - 40 MB', '40 - 50 MB', '50 - 60 MB'];
  mockComponentCounts = [65, 59, 80, 81, 56, 55, 40];

  sampleBarConfig: GenericChartConfig = {
    labels: this.mockComponentRangesLabels,
    datasets: [{
      label: 'Počet',
      data: this.mockComponentCounts,
      barPercentage: 0.3,
      borderWidth: 1
    }]
  };

  sampleDonutConfig: GenericChartConfig = {
    labels: this.mockComponentRangesLabels,
    datasets: [{
      label: 'Počet',
      data: this.mockComponentCounts,
      hoverOffset: 5
    }],
  };
}
