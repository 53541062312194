/* tslint:disable */
/* eslint-disable */

/**
 * Relation type
 */
export enum EsslComponentRelation {
  MAIN = 'MAIN',
  ENCLOSURE = 'ENCLOSURE',
  META = 'META',
  SIGNATURE = 'SIGNATURE',
  DELIVERY_CONFIRMATION = 'DELIVERY_CONFIRMATION',
  PROTOCOL = 'PROTOCOL'
}
