import {inject, Injectable} from '@angular/core';
import {InternalNotificationConsignmentMessageCode} from './internal-notification.enum';
import {
  WebSocketNotification,
  WebSocketNotificationsService,
} from '../../../components/notifications/web-socket-notifications.service';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  ConsignmentToastType,
  SSimpleConsignmentToastData,
} from '../../../components/shared-business-components/consignment-dialog/consignments-toast.service';


@Injectable({
  providedIn: 'root',
})
export class ConsignmentNotificationService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    Object.keys(InternalNotificationConsignmentMessageCode).forEach(key => {
      let messageType = MessageType.INFO;

      if (key.endsWith('SUCCESS')) {
        messageType = MessageType.INFO;
      }
      else if (key.endsWith('ERROR')) {
        messageType = MessageType.ERROR;
      }

      this.wsNotificationsService.registerMessageHandler(
        InternalNotificationConsignmentMessageCode[key as keyof typeof InternalNotificationConsignmentMessageCode],
        (parsedBody: WebSocketNotification) => {
          const templateKey = key.replace('_BULK', '');
          this.toastService.dispatchBulkToast(
            messageType,
            templateKey as keyof typeof ConsignmentToastType,
            parsedBody.parameters,
            SSimpleConsignmentToastData,
            false
          );
        }
      );
    });
  }
}
