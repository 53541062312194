import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {ComponentWithContext, IAutocompleteListItemContext,} from '../form-autocomplete.model';
import {Option} from '../../../../model';

/**
 * Comprehensive docs explaining this component are in ComposableFormAutocomplete JSDoc
 */
@Component({
  selector: 'icz-form-autocomplete-list-text-item',
  templateUrl: './form-autocomplete-list-text-item.component.html',
  styleUrls: ['./form-autocomplete-list-text-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAutocompleteListTextItemComponent implements ComponentWithContext<IAutocompleteListItemContext> {
  @Input({required: true})
  option!: Option;
  @HostBinding('class.multiline')
  @Input()
  isMultiline = false;
}
