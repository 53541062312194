import {Component, EventEmitter, inject, Input, Output, ViewChild} from '@angular/core';
import {OwnPaperConsignmentDto} from '|api/sad';
import {Observable} from 'rxjs';
import {ConsignmentCommand} from '../barcode-scanned-consignments-table/barcode-scanned-consignments-table.component';
import {ConsignmentBarcodeScanAndFindService} from './consignment-barcode-scan-and-find.service';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {FormFieldComponent} from '../../form-elements/form-field/form-field.component';
import {IczValidators} from '../../form-elements/validators/icz-validators/icz-validators';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';

export function getBarcodeScannedConsignmentsForm() {
  return  new IczFormGroup({
    codeBcr: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    code2D: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(60), IczValidators.maxLength(60)]),
    codeCdz: new IczFormControl<Nullable<string>>(null),
  });
}

@Component({
  selector: 'icz-barcode-scanned-consignments-form',
  templateUrl: './barcode-scanned-consignments-form.component.html',
  styleUrls: ['./barcode-scanned-consignments-form.component.scss'],
})
export class BarcodeScannedConsignmentsFormComponent implements IczOnChanges {
  protected loadingService = inject(LoadingIndicatorService);
  private consignmentBarcodeScanAndFindService = inject(ConsignmentBarcodeScanAndFindService);

  @Input({required: true})
  form!: IczFormGroup;
  @Input()
  useCode2D = false;
  @Input({required: true})
  notFoundMessage = '';
  @Input()
  preselectedConsignments: ConsignmentCommand[] = [];
  @Input()
  showDeliveryServiceEdit = true;
  @Output()
  consignmentDeliveryServiceEditRequested = new EventEmitter<ConsignmentCommand>();
  @Output()
  consignmentRemovalRequested = new EventEmitter<ConsignmentCommand>();
  @Output()
  consignmentCommandsChanged = new EventEmitter<ConsignmentCommand[]>();

  @ViewChild('codeBcrField')
  codeBcrField!: FormFieldComponent;
  @ViewChild('code2DField')
  code2DField!: FormFieldComponent;

  consignmentsToProcess: ConsignmentCommand[] = [];
  consignmentAlreadyInList = false;
  consignmentNotFound = false;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.preselectedConsignments && changes.preselectedConsignments.currentValue) {
      this.consignmentsToProcess = changes.preselectedConsignments.currentValue;
    }
  }

  resetErrorFlags() {
    this.consignmentNotFound = false;
    this.consignmentAlreadyInList = false;
  }

  bcrCodeFieldEnterPressed() {
    if (this.useCode2D) {
      this.code2DField.focusField();
    } else {
      this.tryAddScannedConsignmentToList();
    }
  }

  removeConsignment(consignment: ConsignmentCommand) {
    this.consignmentsToProcess = this.consignmentsToProcess.filter(
      consignmentCommand => consignmentCommand !== consignment
    );
    this.consignmentCommandsChanged.emit(this.consignmentsToProcess);
  }

  private prepareFormForNextScanning() {
    setTimeout(() => {
      this.form.enable();
      this.form.reset();
      this.codeBcrField.focusField();
    }, 500);
  }

  tryAddScannedConsignmentToList() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.resetErrorFlags();

      const codeBcr = this.form.value.codeBcr;
      const code2D = this.form.value.code2D;

      this.form.disable();

      const consignmentAlreadyAdded = this.consignmentsToProcess.find(
        ctp => ctp.consignment.uid!.uid === codeBcr || (ctp.consignment as OwnPaperConsignmentDto).outboundConsignmentIdentifier === codeBcr
      );

      if (consignmentAlreadyAdded) {
        this.consignmentAlreadyInList = true;
        this.prepareFormForNextScanning();

        return;
      }

      let getConsignments$: Observable<OwnPaperConsignmentDto[]>;

      if (codeBcr === 'ROCKETMAN') {
        getConsignments$ = this.consignmentBarcodeScanAndFindService.getFiveConsignmentsUsingCheatCode();
      }
      else {
        getConsignments$ = this.consignmentBarcodeScanAndFindService.tryGetConsignmentByBcr(
          this.form.value.codeBcr ?? ''
        );
      }

      this.loadingService.doLoading(
        getConsignments$,
        this
      ).subscribe(consignments => {
        if (consignments.length) {
          for (const consignment of consignments) {
            const consignmentCommand: ConsignmentCommand = {
              consignment,
              code2DMetadata: this.consignmentBarcodeScanAndFindService.parseCodeString2D(code2D),
              cdz: null, // not implemented for now
            };

            this.consignmentsToProcess.unshift(consignmentCommand);
          }
          this.consignmentCommandsChanged.emit(this.consignmentsToProcess);
          this.prepareFormForNextScanning();
        }
        else {
          this.consignmentNotFound = true;
          this.form.enable();
        }
      });
    }
  }
}
