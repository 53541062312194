<icz-validation-errors-list
  [control]="control"
  [wrapInAlert]="true"
  [useFocusableArea]="true"
></icz-validation-errors-list>

@if (componentOptions?.length) {
  <icz-card class="block mt-8 mb-24 mx-4" boxed>
    <icz-options-list
      class="block"
      [options]="componentOptions"
      [listItemTemplate]="componentItemTemplate"
      [isMultiselect]="isSelectionActive"
      [readonlyMode]="!isSelectionActive"
      [hideDefaultTooltips]="true"
      [selectedOptions]="__value"
      (selectionChanged)="selectionChanged($event)"
    ></icz-options-list>
  </icz-card>
}

<ng-template #componentItemTemplate let-context>
  @if (context.option?.data?.epdzComponent; as epdzAttachment) {
    <div
      class="row grow w-full items-center component-item py-4"
      [class.items-start]="hasNonEmptySignatureFlag(epdzAttachment)"
    >
      <icz-icon
        [svgIcon]="getAttachmentIcon(epdzAttachment)"
        class="cursor-pointer"
        (click.stop)="openAttachmentPreview(epdzAttachment)"
      ></icz-icon>
      @if (attachmentHasIssue(epdzAttachment)) {
        <icz-icon
          svgIcon="error"
          [iczTooltip]="getAttachmentIssuesTooltip(epdzAttachment)"
        ></icz-icon>
      }
      <div class="col grow justify-center">
        <div
          class="icz-body-strong cursor-pointer truncate"
          (click.stop)="openAttachmentPreview(epdzAttachment)"
          [iczTooltip]="epdzAttachment.name"
          >
          {{ epdzAttachment.name }}
        </div>
        @if (hasNonEmptySignatureFlag(epdzAttachment)) {
          <icz-digital-component-flags
            (click.stop)="0"
            [signatureValidationData]="epdzAttachment.signatureValidationDataDto"
            [flags]="epdzToEsslSignatureFlags(epdzAttachment.signatureFlags)"
          ></icz-digital-component-flags>
        }
      </div>
      @if (isContainer(epdzAttachment)) {
        <icz-button
          class="h-32"
          svgIcon="eye"
          iczTooltip="Zobrazit obsah kontejneru"
          (onAction)="openEpdzContainerContentsModal(epdzAttachment)"
        ></icz-button>
      }
    </div>
  }
</ng-template>
