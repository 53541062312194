/* tslint:disable */
/* eslint-disable */

/**
 * Signature required flag for emails.
 */
export enum EmailSignatureType {
  REQUIRED = 'REQUIRED',
  NOT_REQUIRED = 'NOT_REQUIRED',
  ONLY_BODY = 'ONLY_BODY'
}
