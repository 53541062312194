<div [formGroup]="countForm">
  <icz-form-field formControlName="count" label="Pocet zasilek" type="integer"></icz-form-field>
</div>

<ng-container iczForm>
  <div [formGroup]="form" [waiting]="loadingIndicatorService.isLoading(this)">
    <div class="row">
      @if (form.value.consignee) {
        <span>Odesílatel: {{form.value.consignee | subjectName}}</span>
      }
      @if (!form.value.consignee) {
        <span class="text-invalid">Nenalezen vhodný odesílatel</span>
      }
    </div>

    <div class="row">
      @if (form.value.consigneeAddress) {
        <div>
          Adresa:
          <icz-address-details
            [addressWithType]="getAddress(form.value.consigneeAddress)"
          ></icz-address-details>
        </div>
      }
      @if (!form.value.consigneeAddress) {
        <span class="text-invalid">Nenalezena vhodná adresa na subjektu</span>
      }
    </div>

    <div class="row">
      <icz-form-autocomplete [options]="deliveryTypeOps"
        class="grow"
        formControlName="deliveryTypeId"
        label="Způsob doručení">
      </icz-form-autocomplete>
    </div>

    <div class="row">
      <icz-form-autocomplete [options]="combinationOpts"
        class="grow"
        formControlName="deliveryServiceCombinationId"
        label="Kombinace služeb">
      </icz-form-autocomplete>
      <!--      <icz-button (onAction)="randomize('combination')" label="randomize!"></icz-button>-->
    </div>

    <div class="row">
      <icz-form-autocomplete [clearable]="false"
        [options]="sheetNodeOptions"
        class="dispatch-office-selector grow-1"
        formControlName="dispatchOfficeDistributionNodeId"
      ></icz-form-autocomplete>
    </div>

    <div class="row">
      <div class="col">Komponenta: {{componentLabel}}</div>
    </div>
  </div>

  <icz-form-buttons>
    <icz-button (onAction)="submit()" [disabled]="loadingIndicatorService.isLoading(this) || !form.valid || !countForm.valid" iczFormSubmit
    label="Založit {{countForm.value.count}} zásilek" leftButtons primary></icz-button>
    <icz-button (onAction)="closeModal()" [disabled]="loadingIndicatorService.isLoading(this)" label="Storno"
    rightButtons></icz-button>
  </icz-form-buttons>
</ng-container>
