import {inject, Injectable} from '@angular/core';
import {forkJoin, map, Observable} from 'rxjs';
import {FilingRegisterDto, SeparateFilingRegisterDto} from '|api/records';
import {CodebookService} from '../../core/services/codebook.service';

export type BasicRegisterDto = FilingRegisterDto | SeparateFilingRegisterDto;

@Injectable({
  providedIn: 'root'
})
export class BasicRegistersService {

  private codebookService = inject(CodebookService);

  getBasicRegisters(): Observable<BasicRegisterDto[]> {
    return forkJoin([
      this.codebookService.getFilingRegister(),
      this.codebookService.getAllSeparateFilingRegisters(),
    ]).pipe(
      map(([filingRegister, separateFilingRegisters]) => [
        (filingRegister ?? [] as unknown as FilingRegisterDto),
        ...separateFilingRegisters
      ])
    );
  }

}
