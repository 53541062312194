<div [formGroup]="form" [waiting]="loadingIndicatorService.isLoading$(this) | async" class="h-full">
  @if (isDeleteDialogType) {
    <icz-alert severity="error" class="mb-32"
      heading="Opravdu chcete smazat zásilku? Smazání nelze vzít zpět.">
    </icz-alert>
  }
  <div class="row grow h-full">
    <icz-form-field class="grow" formControlName="reason" iczAutoFocus
    [label]="rejectDialogConfig?.[modalData.type]!.reasonLabel"></icz-form-field>
  </div>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingIndicatorService.isLoading(this)"
  (onAction)="submit()" primary [label]="rejectDialogConfig?.[modalData.type]!.confirmLabel"></icz-button>
  <icz-button rightButtons [disabled]="loadingIndicatorService.isLoading(this)"
  (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
