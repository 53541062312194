import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RegistryOfficeTransferIncidentDto} from '|api/document';
import {AbstractToolbarButtonsComponent} from '../../../essentials/abstract-toolbar-buttons.component';
import {ToolbarDataService} from '../../../table/table-toolbar/toolbar-data.service';
import {
  RegistryOfficeTransferIncidentToolbarButtonsService
} from './registry-office-transfer-incident-toolbar-buttons.service';
import {ELASTIC_RELOAD_DELAY} from '../../document-toolbar/services/toolbar-common.utils';
import {delay} from 'rxjs';


@Component({
  selector: 'icz-registry-office-transfer-incident-toolbar-buttons',
  templateUrl: './registry-office-transfer-incident-toolbar-buttons.component.html',
  styleUrls: ['./registry-office-transfer-incident-toolbar-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    RegistryOfficeTransferIncidentToolbarButtonsService,
  ]
})
export class RegistryOfficeTransferIncidentToolbarButtonsComponent extends AbstractToolbarButtonsComponent<RegistryOfficeTransferIncidentDto, void> implements OnInit {

  private registryOfficeTransferIncidentToolbarButtonsService = inject(RegistryOfficeTransferIncidentToolbarButtonsService);
  private toolbarDataService = inject(ToolbarDataService);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.registryOfficeTransferIncidentToolbarButtonsService.actionCompleted$.pipe(
      delay(ELASTIC_RELOAD_DELAY),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.operationCompleted.emit();
    });
  }

  protected generateToolbarContents(rows: Nullable<RegistryOfficeTransferIncidentDto[]>) {
    this.toolbarButtons = this.registryOfficeTransferIncidentToolbarButtonsService.getToolbarButtons(rows);
  }

}
