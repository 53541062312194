/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PagePaperComponentDto } from '../models/page-paper-component-dto';
import { paperComponentCreate } from '../fn/paper-component/paper-component-create';
import { PaperComponentCreate$Params } from '../fn/paper-component/paper-component-create';
import { paperComponentDelete } from '../fn/paper-component/paper-component-delete';
import { PaperComponentDelete$Params } from '../fn/paper-component/paper-component-delete';
import { paperComponentDownloadDigitalRendition } from '../fn/paper-component/paper-component-download-digital-rendition';
import { PaperComponentDownloadDigitalRendition$Params } from '../fn/paper-component/paper-component-download-digital-rendition';
import { PaperComponentDto } from '../models/paper-component-dto';
import { paperComponentFindById } from '../fn/paper-component/paper-component-find-by-id';
import { PaperComponentFindById$Params } from '../fn/paper-component/paper-component-find-by-id';
import { paperComponentFindPaperComponents } from '../fn/paper-component/paper-component-find-paper-components';
import { PaperComponentFindPaperComponents$Params } from '../fn/paper-component/paper-component-find-paper-components';
import { paperComponentGetDocumentPaperComponentIds } from '../fn/paper-component/paper-component-get-document-paper-component-ids';
import { PaperComponentGetDocumentPaperComponentIds$Params } from '../fn/paper-component/paper-component-get-document-paper-component-ids';
import { paperComponentGetDocumentPaperComponents } from '../fn/paper-component/paper-component-get-document-paper-components';
import { PaperComponentGetDocumentPaperComponents$Params } from '../fn/paper-component/paper-component-get-document-paper-components';
import { paperComponentLinkPaperComponentToDigitalRendition } from '../fn/paper-component/paper-component-link-paper-component-to-digital-rendition';
import { PaperComponentLinkPaperComponentToDigitalRendition$Params } from '../fn/paper-component/paper-component-link-paper-component-to-digital-rendition';
import { paperComponentLinkPaperComponentToDocument } from '../fn/paper-component/paper-component-link-paper-component-to-document';
import { PaperComponentLinkPaperComponentToDocument$Params } from '../fn/paper-component/paper-component-link-paper-component-to-document';
import { paperComponentPrintLabel } from '../fn/paper-component/paper-component-print-label';
import { PaperComponentPrintLabel$Params } from '../fn/paper-component/paper-component-print-label';
import { paperComponentUpdate } from '../fn/paper-component/paper-component-update';
import { PaperComponentUpdate$Params } from '../fn/paper-component/paper-component-update';


/**
 * Controller for paper component
 */
@Injectable({ providedIn: 'root' })
export class ApiPaperComponentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `paperComponentFindById()` */
  static readonly PaperComponentFindByIdPath = '/component/paper-component/{id}';

  /**
   * Finds existing paper component by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentFindById$Response(params: PaperComponentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<PaperComponentDto>> {
    return paperComponentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds existing paper component by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentFindById(params: PaperComponentFindById$Params, context?: HttpContext): Observable<PaperComponentDto> {
    return this.paperComponentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaperComponentDto>): PaperComponentDto => r.body)
    );
  }

  /** Path part for operation `paperComponentUpdate()` */
  static readonly PaperComponentUpdatePath = '/component/paper-component/{id}';

  /**
   * Update metadata for existing paper component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentUpdate$Response(params: PaperComponentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PaperComponentDto>> {
    return paperComponentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update metadata for existing paper component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentUpdate(params: PaperComponentUpdate$Params, context?: HttpContext): Observable<PaperComponentDto> {
    return this.paperComponentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaperComponentDto>): PaperComponentDto => r.body)
    );
  }

  /** Path part for operation `paperComponentCreate()` */
  static readonly PaperComponentCreatePath = '/component/paper-component';

  /**
   * Create new paper component
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentCreate$Response(params: PaperComponentCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<PaperComponentDto>> {
    return paperComponentCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new paper component
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentCreate(params: PaperComponentCreate$Params, context?: HttpContext): Observable<PaperComponentDto> {
    return this.paperComponentCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<PaperComponentDto>): PaperComponentDto => r.body)
    );
  }

  /** Path part for operation `paperComponentPrintLabel()` */
  static readonly PaperComponentPrintLabelPath = '/component/paper-component/{id}/print-label';

  /**
   * Prints label for component.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentPrintLabel()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentPrintLabel$Response(params: PaperComponentPrintLabel$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return paperComponentPrintLabel(this.http, this.rootUrl, params, context);
  }

  /**
   * Prints label for component.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentPrintLabel$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentPrintLabel(params: PaperComponentPrintLabel$Params, context?: HttpContext): Observable<Array<string>> {
    return this.paperComponentPrintLabel$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `paperComponentLinkPaperComponentToDocument()` */
  static readonly PaperComponentLinkPaperComponentToDocumentPath = '/component/paper-component/{documentId}/link';

  /**
   * Link paper component for document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentLinkPaperComponentToDocument()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentLinkPaperComponentToDocument$Response(params: PaperComponentLinkPaperComponentToDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paperComponentLinkPaperComponentToDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Link paper component for document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentLinkPaperComponentToDocument$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentLinkPaperComponentToDocument(params: PaperComponentLinkPaperComponentToDocument$Params, context?: HttpContext): Observable<void> {
    return this.paperComponentLinkPaperComponentToDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paperComponentLinkPaperComponentToDigitalRendition()` */
  static readonly PaperComponentLinkPaperComponentToDigitalRenditionPath = '/component/paper-component/link-digital-rendition';

  /**
   * Link paper component for document with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentLinkPaperComponentToDigitalRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentLinkPaperComponentToDigitalRendition$Response(params: PaperComponentLinkPaperComponentToDigitalRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paperComponentLinkPaperComponentToDigitalRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Link paper component for document with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentLinkPaperComponentToDigitalRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  paperComponentLinkPaperComponentToDigitalRendition(params: PaperComponentLinkPaperComponentToDigitalRendition$Params, context?: HttpContext): Observable<void> {
    return this.paperComponentLinkPaperComponentToDigitalRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paperComponentDownloadDigitalRendition()` */
  static readonly PaperComponentDownloadDigitalRenditionPath = '/component/paper-component/{paperComponentId}/download-digital-rendition';

  /**
   * Download digital rendition of paper component by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentDownloadDigitalRendition()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentDownloadDigitalRendition$Response(params: PaperComponentDownloadDigitalRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paperComponentDownloadDigitalRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Download digital rendition of paper component by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentDownloadDigitalRendition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentDownloadDigitalRendition(params: PaperComponentDownloadDigitalRendition$Params, context?: HttpContext): Observable<void> {
    return this.paperComponentDownloadDigitalRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `paperComponentGetDocumentPaperComponents()` */
  static readonly PaperComponentGetDocumentPaperComponentsPath = '/component/paper-component/{documentId}/paper-components';

  /**
   * Returns paper components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentGetDocumentPaperComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentGetDocumentPaperComponents$Response(params: PaperComponentGetDocumentPaperComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PaperComponentDto>>> {
    return paperComponentGetDocumentPaperComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns paper components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentGetDocumentPaperComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentGetDocumentPaperComponents(params: PaperComponentGetDocumentPaperComponents$Params, context?: HttpContext): Observable<Array<PaperComponentDto>> {
    return this.paperComponentGetDocumentPaperComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PaperComponentDto>>): Array<PaperComponentDto> => r.body)
    );
  }

  /** Path part for operation `paperComponentFindPaperComponents()` */
  static readonly PaperComponentFindPaperComponentsPath = '/component/paper-component/{documentId}/paper-components/search-api';

  /**
   * Finds paper components based on documentId and search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentFindPaperComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentFindPaperComponents$Response(params: PaperComponentFindPaperComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<PagePaperComponentDto>> {
    return paperComponentFindPaperComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds paper components based on documentId and search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentFindPaperComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentFindPaperComponents(params: PaperComponentFindPaperComponents$Params, context?: HttpContext): Observable<PagePaperComponentDto> {
    return this.paperComponentFindPaperComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PagePaperComponentDto>): PagePaperComponentDto => r.body)
    );
  }

  /** Path part for operation `paperComponentGetDocumentPaperComponentIds()` */
  static readonly PaperComponentGetDocumentPaperComponentIdsPath = '/component/paper-component/{documentId}/paper-component-ids';

  /**
   * Returns paper components of a document with the given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentGetDocumentPaperComponentIds()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentGetDocumentPaperComponentIds$Response(params: PaperComponentGetDocumentPaperComponentIds$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<number>>> {
    return paperComponentGetDocumentPaperComponentIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns paper components of a document with the given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentGetDocumentPaperComponentIds$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentGetDocumentPaperComponentIds(params: PaperComponentGetDocumentPaperComponentIds$Params, context?: HttpContext): Observable<Array<number>> {
    return this.paperComponentGetDocumentPaperComponentIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<number>>): Array<number> => r.body)
    );
  }

  /** Path part for operation `paperComponentDelete()` */
  static readonly PaperComponentDeletePath = '/component/paper-component/per-entity/document/{documentId}/component/{componentId}';

  /**
   * Deletes paper component in a given document according to theirs ids
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `paperComponentDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentDelete$Response(params: PaperComponentDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return paperComponentDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes paper component in a given document according to theirs ids
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `paperComponentDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  paperComponentDelete(params: PaperComponentDelete$Params, context?: HttpContext): Observable<void> {
    return this.paperComponentDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
