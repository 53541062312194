<icz-subjects-table
  tableId="subjects-selection"
  [tableTitle]="modalData.tableTitle"
  [dataSource]="subjectsDataSource"
  [columnSet]="SubjectTableColumnSet.RESULT_SET_FOR_LISTING"
  (selectedRowsChanged)="selectedRowsChanged($event)"
></icz-subjects-table>

<icz-form-buttons>
  <icz-button leftButtons
              (onAction)="submit()"
              primary [label]="modalData.primaryButtonTitle"
  ></icz-button>
  <icz-button rightButtons
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
