import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {
  AbstractNotificationMessageContent,
  NotificationGenericTemplateWithSchema,
} from './abstract-notification-message-content';
import {
  InternalNotificationConsignmentMessageCode,
  InternalNotificationMessageCode,
} from '../../../core/services/notifications/internal-notification.enum';
import {getToastBodyTemplateTranslateKey, getToastHeaderTemplateTranslateKey} from '../toast.service';
import {MESSAGE_BODY_MAPPERS} from './notification-message-body-mappers.model';
import {
  ConsignmentToastType,
  SConsignmentErrorToastData,
  SConsignmentToastData
} from '../../shared-business-components/consignment-dialog/consignments-toast.service';
import {ZodSchema} from 'zod';

function registerConsignmentBodyMappers() {
  for (const [key, value] of Object.entries(InternalNotificationConsignmentMessageCode)) {
    const templateKey = key.replace('_BULK', '');

    let schema: ZodSchema = SConsignmentToastData;
    if (key.endsWith('SUCCESS')) {
      schema = SConsignmentToastData;
    } else if (key.endsWith('ERROR')) {
      schema = SConsignmentErrorToastData;
    }
    MESSAGE_BODY_MAPPERS[value as InternalNotificationConsignmentMessageCode] = {
      template: templateKey as ConsignmentToastType,
      schema,
      preserveBodyMode: false,
    };
  }
}

@Component({
  selector: 'icz-notification-message-body',
  templateUrl: './notification-message-body.component.html',
  styleUrls: ['./notification-message-body.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationMessageBodyComponent extends AbstractNotificationMessageContent<InternalNotificationMessageCode> implements OnInit {

  protected mappers = MESSAGE_BODY_MAPPERS;

  ngOnInit() {
    registerConsignmentBodyMappers();
  }

  isKnownMessageCode(messageCode: InternalNotificationMessageCode) {
    return this.mappers.hasOwnProperty(messageCode);
  }

  hasBody(messageCode: InternalNotificationMessageCode) {
    return this.mappers[messageCode] !== null;
  }

  getMessageBodyTemplate(messageCode: InternalNotificationMessageCode): Nullable<NotificationGenericTemplateWithSchema<string>> {
    const bodyTemplate = this.getTemplate(messageCode);

    if (bodyTemplate) {
      let bodyTemplateExtended = '';
      if (typeof bodyTemplate.template === 'function') {
        bodyTemplateExtended = bodyTemplate.template(this.paramsMap);
      } else {
        bodyTemplateExtended = bodyTemplate.template;
      }

      return {
        ...bodyTemplate,
        template: (bodyTemplate.preserveBodyMode === false) ? getToastHeaderTemplateTranslateKey(bodyTemplateExtended) : getToastBodyTemplateTranslateKey(bodyTemplateExtended),
      };
    }
    else {
      return null;
    }
  }

}
