import {DateRange} from '@angular/material/datepicker';
import {isEqual, parse} from 'date-fns';
import {UserDto} from '|api/auth-server';
import {DigitalComponentVersionTypeFlag, EntityType} from '|api/commons';
import {isValidDate} from './lib/utils';

export interface ElasticIdentifiable {
  elasticId: string; // concatenation {entityType} + {numeric ID}
}

// A component type with no specific shape
export interface AnyComponent {}

/**
 * ListItem contains common properties of Option, TreeNode and TreeFlatNode
 * which are likely to be used in HTML templates of option/tree lists.
 *
 * It also allows to make spreading like TreeNode = {...Option, someKey: someValue} or
 * Option = {...TreeNode, someKey: someValue} in type-safe way.
 */
export interface ListItem<D = unknown> {
  label: string;
  icon?: string;
  disabled?: boolean;
  disableReason?: string;
  data?: D;
  placeholder?: string;
  default?: boolean;
  originId?: string;
  isReserved?: boolean; // a special flag signifying a reserved value amongst options, will make it look distinct in options list
  isSeparator?: boolean; // makes the option unselectable and distinct from other items in option lists
  isGroup?: boolean; // makes the option unselectable and does not render checkbox, used primarily in trees
  autoExpand?: boolean; // makes the option expanded in initial state of tree view
}

/**
 * @property originId is used in case, when @property(value) cannot reflect original ID bcs of duplicity.
 * Especially seen in tree-component where multiple different arrays are merged together to be display in tree structure.
 * @property parent determines parent Option - also used in tree-component.
 */
export interface Option<V = Nullable<string|number>, D = unknown> extends ListItem<D> {
  value: V; // primary value
  isHidden?: boolean; // hidden options are not visible in available options list but can be selected in control models
  parent?: V;
  id?: number; // system-wide identifier of the option, in case it differs from Option#value for some reason
}

export interface Time {
  hours: number;
  minutes: number;
}

export const BOOLEAN_OPTIONS: Option<boolean>[] = [
  {
    value: true,
    label: 'Ano',
  },
  {
    value: false,
    label: 'Ne',
  }
];

export const FORM_SUBMIT_DEBOUNCE_TIME = 500; // ms

export function locateOptionByValue<V, D>(list: Nullable<Array<Option<V, D>>>, value: Nullable<V>, originId?: Nullable<string>): Nullable<Option<V, D>> {
  if (!list) return null;

  return list.find(o => {
    // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
    if (originId && o.originId) return originId === o.originId && value !== undefined && (o.id as unknown as V) == value;
    // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
    else return value !== undefined && o.value == value;
  }) ?? null;
}

export function getOptionsByValuesList<V, D>(options: Array<Option<V, D>>, values: V[], originId?: Nullable<string>) {
  if (!values || !options) return [];
  return values.map(v => {
    if (v === undefined) {
      return undefined;
    }
    else {
      if (originId) {
        // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
        return options.find(o => originId === o.originId && v == (o.id as unknown as V))!;
      }
      else {
        // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
        return options.find(o => v == o.value)!;
      }
    }
  }).filter(o => !isNil(o)) as Array<Option<V, D>>;
}

export function getOptionsSubtree<V>(options: Array<Option<V>>, subtreeRootVal: V): Array<Option<V>> {
  if (!subtreeRootVal) {
    return options;
  }

  const rootItem = options.find(item => item?.value === subtreeRootVal);

  if (!rootItem) {
    return options;
  }

  const validParents = [rootItem.value];

  for (const option of options) {
    if (validParents.includes(option.parent!)) {
      validParents.push(option.value);
    }
  }

  return options.filter(o => validParents.includes(o.parent!) || o === rootItem);
}

export function getOptionsPathToRoot<V>(options: Array<Option<V>>, nonRootPathEndVal: V): Array<Option<V>> {
  if (nonRootPathEndVal === undefined) {
    // having an option with value === undefined is considered invalid, return empty set
    return [];
  }
  else if (nonRootPathEndVal === null) {
    // if there is a path with option of value === null, then it always is isolated set of one element
    return [locateOptionByValue(options, nonRootPathEndVal)!];
  }
  else {
    const path: Option<V>[] = [];
    let currentPathElement: Option<V> = locateOptionByValue(options, nonRootPathEndVal)!;

    if (currentPathElement) {
      path.push(currentPathElement);

      while (currentPathElement?.parent) {
        currentPathElement = locateOptionByValue(options, currentPathElement.parent)!;
        path.push(currentPathElement);
      }
    }

    return path;
  }
}

export function getComponentIconByFormatFlag(formatFlag: Nullable<DigitalComponentVersionTypeFlag>, isContainer?: boolean) {
  if (isContainer && formatFlag !== DigitalComponentVersionTypeFlag.PDFA) {
    return 'kontejner';
  }
  else {
    switch (formatFlag) {
      case DigitalComponentVersionTypeFlag.AUDIO:
        return 'attachment_audio';
      case DigitalComponentVersionTypeFlag.DOC:
        return 'attachment_doc';
      case DigitalComponentVersionTypeFlag.DOCX:
        return 'attachment_docx';
      case DigitalComponentVersionTypeFlag.FO:
        return 'attachment_fo';
      case DigitalComponentVersionTypeFlag.IMAGE:
        return 'attachment_img';
      case DigitalComponentVersionTypeFlag.ODP:
        return 'attachment_odp';
      case DigitalComponentVersionTypeFlag.ODS:
        return 'attachment_ods';
      case DigitalComponentVersionTypeFlag.ODT:
        return 'attachment_odt';
      case DigitalComponentVersionTypeFlag.PDF:
        return 'attachment_pdf';
      case DigitalComponentVersionTypeFlag.PDFA:
        return isContainer ? 'pdfa_kontejner' : 'attachment_pdfa';
      case DigitalComponentVersionTypeFlag.PPT:
        return 'attachment_ppt';
      case DigitalComponentVersionTypeFlag.PPTX:
        return 'attachment_pptx';
      case DigitalComponentVersionTypeFlag.XLS:
        return 'attachment_xls';
      case DigitalComponentVersionTypeFlag.XLSX:
        return 'attachment_xlsx';
      case DigitalComponentVersionTypeFlag.XML:
        return 'attachment_xml';
      case DigitalComponentVersionTypeFlag.ZFO:
        return 'attachment_zfo';
      case DigitalComponentVersionTypeFlag.OTHER:
      default:
        return 'attachment_rest';
    }
  }
}

export const getComponentIcon = (entityType: EntityType, formatFlag?: Nullable<DigitalComponentVersionTypeFlag>, isContainer?: boolean): string => {
  switch (entityType) {
    case EntityType.PAPER_COMPONENT:
      return 'attachment_paper';
    case EntityType.MEDIUM_COMPONENT:
      return 'nosic';
    case EntityType.PHYSICAL_ITEM_COMPONENT:
      return 'spanner';
    case EntityType.DIGITAL_COMPONENT:
      return getComponentIconByFormatFlag(formatFlag, isContainer);
    default:
      return 'attachment_rest';
  }
};

export const getComponentIconByFilenameSuffix = (fileName: string) => {
  const filenameParts = fileName.split('.');
  const fileSuffix = filenameParts[filenameParts.length - 1].toLowerCase();

  switch (fileSuffix) {
    case 'pdf':
      return 'attachment_pdf';
    case 'doc':
      return 'attachment_doc';
    case 'docx':
      return 'attachment_docx';
    case 'fo':
      return 'attachment_fo';
    case 'odp':
      return 'attachment_odp';
    case 'ods':
      return 'attachment_ods';
    case 'odt':
      return 'attachment_odt';
    case 'pdfa':
      return 'attachment_pdfa';
    case 'ppt':
      return 'attachment_ppt';
    case 'pptx':
      return 'attachment_pptx';
    case 'xsl':
      return 'attachment_xsl';
    case 'xsls':
      return 'attachment_xsls';
    case 'zfo':
      return 'attachment_zfo';
    case 'eml':
    case 'msg':
      return 'email';
    case 'bmp':
    case 'gif':
    case 'jfif':
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'ppeg':
    case 'tiff':
      return 'attachment_img';
    case 'wav':
    case 'mp3':
    case 'ogg':
    case 'flac':
      return 'attachment_audio';
    default:
      return 'attachment_rest';
  }
};

export function getStartOfTheDay(value?: Nullable<string | Date>): Date {
  const date = value ? new Date(value) : new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

export function getUserFullName(u: UserDto, withTitles = false) {
  if (u.username === 'system') {
    return 'Systém';
  }
  else if (isNil(u.firstName) && isNil(u.surname)) {
    return 'Uživatel beze jména';
  }
  else {
    const nameWithoutTitles = `${u.firstName ?? ''}${u.firstName ? ' ' : ''}${u.surname ?? ''}`;

    if (withTitles) {
      return `${u.titleBeforeName ? `${u.titleBeforeName} ` : ''}${nameWithoutTitles}${u.titleAfterName ? `, ${u.titleAfterName}` : ''}`;
    }
    else {
      return nameWithoutTitles;
    }
  }
}

export function getUserInitials(namesOrUser: string[] | UserDto): string {
  let names: string[] = [];

  if (Array.isArray(namesOrUser)) {
    names = namesOrUser;
  }
  else {
    names = [namesOrUser!.firstName ?? '', namesOrUser!.surname ?? ''];
  }

  if (!names?.length) return '';

  if (names.length === 1) {
    return names.at(0)!.charAt(0);
  }
  else {
    return `${names.at(0)!.charAt(0)}${names.at(-1)!.charAt(0)}`;
  }
}

export function parseDateFromLocalDateString(dateSource: string): Nullable<Date> {
  const allowedInputFormats = [
    'd.M.yyyy',
    'dd.MM.yyyy',
    'd/M/yyyy',
    'dd/MM/yyyy',
    'ddMMyyyy',
  ];

  let date: Nullable<Date>;

  for (const dateFormat of allowedInputFormats) {
    date = parse(dateSource, dateFormat, new Date());

    if (isValidDate(date)) {
      break;
    }
  }

  return date;
}

export function parseTimeFromSimpleTimeString(timeSource: string): Nullable<Time> {
  const timeRe = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/g;

  if (timeRe.test(timeSource)) {
    const timeParts = timeSource.split(':');

    return {
      hours: parseInt(timeParts[0], 10),
      minutes: parseInt(timeParts[1], 10),
    };
  }
  else {
    return null;
  }
}

export function isDateRange(value: any): value is DateRange<Date> {
  return value && 'start' in value && 'end' in value;
}

export function areDateRangesEqual(dr1: DateRange<Date>, dr2: DateRange<Date>) {
  return isEqual(dr1.start!, dr2.start!) && isEqual(dr1.end!, dr2.end!);
}

export function isOptionSelectable(o: Option) {
  return !o.disabled && !o.isSeparator && !o.isGroup;
}

export interface ArrayDiff<T> {
  additions: T[];
  removals: T[];
}

export function getArrayDiff<T>(originalItems: T[], newItems: T[]): ArrayDiff<T> {
  return {
    additions: newItems.filter(i => !originalItems.includes(i)),
    removals: originalItems.filter(i => !newItems.includes(i)),
  };
}
