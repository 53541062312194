import {createComponent, EnvironmentInjector, inject, Injectable, Type} from '@angular/core';
import {AbstractTemplateCollection, TemplatePool} from './abstract-template-collection';

/**
 * Template Pools are an implementation of OOP Flyweight pattern for Angular Templates,
 * allowing you to easily share <ng-template>s across a variety of components.
 * Without performance overhead. Without code duplication. Without race conditions. Super Cool!
 *
 * P.S. Should be provided AND initialized with a pool component on per-injector basis in
 *      NgModule#providers. That means: Once for Root Module or its non-lazy children OR
 *      Once for any lazy module and its non-lazy children.
 *      A pool component is a component that extends AbstractTemplateCollection and contains ONLY:
 *      - <ng-template>s with template reference variables in HTML
 *      - @ViewChild('templateVar') xxxTemplate: TemplateRef<whatever>. The "[T|t]emplate"
 *         prefix/suffix is important as it is used when constructing the public pool accessible
 *         from TemplatePoolService#template$.
 */
@Injectable()
export class TemplatePoolService<P extends AbstractTemplateCollection> {

  private environmentInjector = inject(EnvironmentInjector);

  get templates(): TemplatePool<P> {
    return this.templatePool.pool;
  }

  private templatePool!: P;

  initialize(templatePoolComponent: Type<P>) {
    if (!this.templatePool) {
      const componentRef = createComponent(
        templatePoolComponent,
        {
          environmentInjector: this.environmentInjector,
        }
      );
      componentRef.changeDetectorRef.detectChanges();
      this.templatePool = componentRef.instance;
    }
  }

}
