/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiNomenclatureSynchronizationService } from './services/api-nomenclature-synchronization.service';
import { ApiFpOwnershipTransferService } from './services/api-fp-ownership-transfer.service';
import { ApiEntitySharingManagementService } from './services/api-entity-sharing-management.service';
import { ApiLiquibaseService } from './services/api-liquibase.service';
import { ApiAuthorizationService } from './services/api-authorization.service';
import { ApiPermissionService } from './services/api-permission.service';
import { ApiOrganizationUnitService } from './services/api-organization-unit.service';
import { ApiOrgStructCacheService } from './services/api-org-struct-cache.service';
import { ApiEntitySharingService } from './services/api-entity-sharing.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiNomenclatureSynchronizationService,
    ApiFpOwnershipTransferService,
    ApiEntitySharingManagementService,
    ApiLiquibaseService,
    ApiAuthorizationService,
    ApiPermissionService,
    ApiOrganizationUnitService,
    ApiOrgStructCacheService,
    ApiEntitySharingService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
