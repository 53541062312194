@if (isDataBoxDelivery) {
  <icz-sender-info-databox
    [consignment]="receivedDigitalConsignment"
    [senderSubject]="senderSubject"
  ></icz-sender-info-databox>
}
@if (isSenderLoaded && !wasSenderRemoved) {
  <div>
    @if (isSenderKnown) {
      <icz-subject-detail-card
        class="mb-32"
        [allowSubjectEdit]="isSubjectEditAllowed"
        [allowSubjectUnlink]="isSubjectEditAllowed"
        [subjectOU]="senderOU"
        label="Odesílatel"
        [areAddressesReadonly]="true"
        [subject]="senderSubject"
        (subjectRemoved)="subjectRemoved()">
      </icz-subject-detail-card>
      @if (isPostDelivery) {
        <icz-address
          [boxed]="true"
          [isSearchMode]="false"
          [isReadonly]="true"
          label="Adresa, odkud byla zásilka odeslána"
          class="mb-32"
          [form]="senderAddress"
          groupName="."
          [addressAttributeType]="SubjectAttributeType.MAILING_ADDRESS"
          [showSectionExpansionHint]="false"
        ></icz-address>
      }
      @if (isTelefaxConsignment) {
        <icz-card>
          <div class="p-16">
            <icz-label label="Telefonní číslo, z kterého byla zásilka odeslána"></icz-label>
            <span> {{ receivedTelefaxConsignment!.phoneNumber }}</span>
          </div>
        </icz-card>
      }
    }
    @else {
      <icz-section label="Odesílatel" boxed class="block mb-32">
        <div>{{'Anonymní uživatel' | translate}}</div>
        @if (!isDataBoxDelivery) {
          <div class="row mt-16">
            <icz-button (onAction)="subjectRemoved()" label="Změnit odesílatele" class="grow"
            [disabled]="isDocumentDeactivated"></icz-button>
            <div class="grow"></div>
          </div>
        }
      </icz-section>
    }
  </div>
}
