import {inject, Injectable} from '@angular/core';
import {Observable, of, OperatorFunction, switchMap} from 'rxjs';
import {map} from 'rxjs/operators';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {
  ObjectClass,
  RelatedObjectType,
  SubjectObjectRelationFindDto,
  SubjectObjectRelationType,
  SubjectRecordWithRelationsDto
} from '|api/commons';
import {DocumentDto, FileDto} from '|api/document';
import {ApiElasticsearchService, SearchRecordDto, SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {CirculationActivityDto} from '|api/flow';
import {ReceivedDocumentDto} from '|api/sad';
import {SearchApiWithElasticService, UnitViewSearchConfig} from './search-api-with-elastic.service';
import {FilterOperator, SearchParams, TypedSearchRecordDto, unwrapSearchContent} from './search-api.service';
import {formatAsLocalIsoDate, getTodayMidnight} from '../lib/utils';
import {getDefaultSearchParams} from '../components/table/table.datasource';
import {ReceivedConsignmentsTableView} from './received-consignments.model';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {entityTypeToRelatedObjectType} from '../components/shared-business-components/shared-document.utils';
import {extendEsslObjectsWithRepresenting} from './search-api-data-utils';

export interface ReceivedDocumentDtoWithInitialHandoverActivity extends ReceivedDocumentDto {
  handoverActivityId?: Nullable<number>;
  handoverActivities?: Nullable<CirculationActivityDto[]>;
}

export interface EntityWithRepresentingSubject {
  representingSubject?: string;
}

function extendEsslObjectsWithSender(apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,):
  OperatorFunction<Page<TypedSearchRecordDto<any & EntityWithRepresentingSubject>>, Page<TypedSearchRecordDto<any & EntityWithRepresentingSubject>>> {
  return switchMap(res => {
    if (!res.content) return of(res);

    const relatedObjectIds = res.content.map(c => c.source.id);
    if (!relatedObjectIds.length) return of(res);

    const related: SubjectObjectRelationFindDto = {
      relatedObjectIds: res.content.map(c => c.source.id),
      relatedObjectType: RelatedObjectType.DOCUMENT,
      relationType: SubjectObjectRelationType.SENDER,
    };
    return apiSubjectRecordNgElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: related}).pipe(map((subjectsWithRelations: Array<SubjectRecordWithRelationsDto>) => {
      res.content.forEach(c => {
        const sender: Nullable<SubjectRecordWithRelationsDto> = subjectsWithRelations.find(s => s.objectRelations!.find(r => {
          return r.relatedObjectId === c.source.id && r.relatedObjectType === entityTypeToRelatedObjectType(c.source.entityType!)!; }
        ));

        if (sender) {
          c.source.sender = sender;
        }
      });
      return res;
    }));
  });
}


@Injectable({
  providedIn: 'root',
})
export class DocumentSearchService extends SearchApiWithElasticService {

  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);

  findDocumentsAll(searchParams: Partial<SearchParams>): Observable<Page<DocumentDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(searchParams, this.getElasticViewUri('all-documents', searchParams))
      .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  findFilesAll(searchParams: Partial<SearchParams>): Observable<Page<FileDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<FileDto>>>(searchParams, this.getElasticViewUri('all-files', searchParams))
      .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  countDocumentsInProgress(searchParams: Partial<SearchParams>, unitView: UnitViewSearchConfig): Observable<number> {
    if (unitView.isUnitView) {
      return this.searchApi<number>(searchParams, this.getElasticViewByOrgUnitUri('on-table-by-org-unit', unitView.orgUnitId, searchParams, true));
    }
    else {
      // todo(lp) rename to in-progress when ESSL-7491
      return this.searchApi<number>(searchParams, this.getElasticViewUri('on-table', searchParams, true));
    }
  }

  findDocumentsInProgress(searchParams: Partial<SearchParams>, unitView: UnitViewSearchConfig): Observable<Page<DocumentDto>> {
    if (unitView.isUnitView) {
      return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(searchParams, this.getElasticViewByOrgUnitUri('on-table-by-org-unit', unitView.orgUnitId, searchParams))
        .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
    }
    else {
      // todo(lp) rename to in-progress when ESSL-7491
      return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(searchParams, this.getElasticViewUri('on-table', searchParams))
        .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
    }
  }

  findReceivedDocumentsFilingOffice(searchParams: Partial<SearchParams>, tableView: ReceivedConsignmentsTableView): Observable<Page<ReceivedDocumentDto>> {
    if (tableView === ReceivedConsignmentsTableView.TODAY) {
      searchParams.filter!.push(
        {
          fieldName: 'consignments.deliveryDate',
          value: formatAsLocalIsoDate(new Date()),
          operator: FilterOperator.equals
        },
      );
    }

    return this.findReceivedDocumentsGloballyFilingOffice(searchParams);
  }

  documentsFilingOfficeForTodayCount(): Observable<number> {
    const searchParams: SearchParams = {...getDefaultSearchParams(), size: 0, filter: []};

    searchParams.filter!.push(
      {
        fieldName: 'consignments.deliveryDate',
        value: formatAsLocalIsoDate(new Date()),
        operator: FilterOperator.equals
      },
    );

    return this.findReceivedDocumentsGloballyFilingOffice(searchParams).pipe(
      map(result => result.totalElements)
    );
  }

  findHandedOverFilingOffice(searchParams: Partial<SearchParams>): Observable<Page<ReceivedDocumentDtoWithInitialHandoverActivity>> {
    return this.searchApi<Page<TypedSearchRecordDto<ReceivedDocumentDtoWithInitialHandoverActivity>>>(searchParams, this.getElasticViewUri('handed-over-by-filing-office', searchParams))
      .pipe(extendEsslObjectsWithSender(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  findRejectedFilingOffice(searchParams: Partial<SearchParams>): Observable<Page<ReceivedDocumentDtoWithInitialHandoverActivity>> {
    return this.searchApi<Page<TypedSearchRecordDto<ReceivedDocumentDtoWithInitialHandoverActivity>>>(searchParams, this.getElasticViewUri('returned-to-filing-office', searchParams))
      .pipe(extendEsslObjectsWithSender(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  findReceivedDocumentsGloballyFilingOffice(searchParams: Partial<SearchParams>): Observable<Page<ReceivedDocumentDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<ReceivedDocumentDto>>>(searchParams, this.getElasticViewUri('received-by-filing-office', searchParams))
      .pipe(extendEsslObjectsWithSender(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  findExpiringDocuments(searchParams: Partial<SearchParams>, triggerDate: Date, unitView: UnitViewSearchConfig): Observable<Page<DocumentDto>> {
    this.addExpiringDocumentsCriteriaToSearchParams(searchParams, triggerDate);
    return this.findDocumentsInProgress(searchParams, unitView);
  }

  countExpiringDocuments(searchParams: Partial<SearchParams>, triggerDate: Date, unitView: UnitViewSearchConfig): Observable<number> {
    this.addExpiringDocumentsCriteriaToSearchParams(searchParams, triggerDate);
    return this.countDocumentsInProgress(searchParams, unitView);
  }

  countExpiredDocuments(searchParams: Partial<SearchParams>, unitView: UnitViewSearchConfig): Observable<number> {
    if (!searchParams.filter!.find(f => f.fieldName === 'resolutionDate')) {
      searchParams.filter!.push({
        fieldName: 'resolutionDate',
        value: getTodayMidnight().toISOString(),
        operator: FilterOperator.less,
      });
    }

    return this.countDocumentsInProgress(searchParams, unitView);
  }

  findSettledDocuments(searchParams: Partial<SearchParams>, unitView: UnitViewSearchConfig, findStored: boolean, findWithRetentionCheckYear: boolean): Observable<Page<DocumentDto>> {
    searchParams.filter ??= [];

    if (findStored) {
      searchParams.filter.push({
        fieldName: 'storageUnitId',
        operator: FilterOperator.notEmpty,
        value: '',
      });
    } else if (findWithRetentionCheckYear) {
      searchParams.filter.push({
        fieldName: 'triggerEventCheckYear',
        operator: FilterOperator.notEmpty,
        value: '',
      });
    }
    else {
      searchParams.filter.push({
        fieldName: 'storageUnitId',
        operator: FilterOperator.empty,
        value: '',
      });
    }

    if (unitView.isUnitView) {
      return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(searchParams, this.getElasticViewByOrgUnitUri('settled-by-org-unit', unitView.orgUnitId, searchParams))
        .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
    }
    else {
      return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(searchParams, this.getElasticViewUri('settled', searchParams))
        .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
    }
  }

  findIssdDocuments(searchParams: Partial<SearchParams>): Observable<Page<DocumentDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(searchParams, this.getElasticViewUri('issd', searchParams))
      .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  findManualSharedDocuments(searchParams: Partial<SearchParams>): Observable<Page<DocumentDto | FileDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<DocumentDto | FileDto>>>(searchParams, this.getElasticViewUri('shared-manually', searchParams))
      .pipe(extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService), unwrapSearchContent());
  }

  findDocumentsGlobally(searchParams: Partial<SearchParams>): Observable<Page<DocumentDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<DocumentDto>>>(
      searchParams,
      CORE_MICROSERVICE_ROOT +
      applyPathParams(
        ApiElasticsearchService.ElasticsearchFindInEntitiesPath,
        {searchText: ''}
      ).replace(/\/$/, '') +
      (searchParams.fulltextSearchTerm ? `?text=${encodeURIComponent(searchParams.fulltextSearchTerm)}` : '?text= ')
    ).pipe(
      map((entities: Page<TypedSearchRecordDto<DocumentDto>>) => ({
        content: entities.content.map((entity: TypedSearchRecordDto<DocumentDto>) => ({
          ...entity,
          source: {
            ...entity.source,
            relevance: Number(entity.score),
            highlights: entity.highlights,
          },
        })),
        totalElements: entities.totalElements,
      })),
      extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService),
      unwrapSearchContent()
    );
  }

  findObjectsGloballyByCurrentUser(searchParams: Partial<SearchParams>): Observable<Page<DocumentDto | FileDto>> {
    return this.searchApi<Page<SearchRecordDto>>(
      searchParams,
      CORE_MICROSERVICE_ROOT +
      applyPathParams(
        ApiElasticsearchService.ElasticsearchFindPath,
        {searchText: ''}
      ).replace(/\/$/, '') +
      (searchParams.fulltextSearchTerm ? `?text=${encodeURIComponent(searchParams.fulltextSearchTerm)}` : '')
    ).pipe(
      map((entities: any) => ({
        content: entities.content.map((entity: SearchRecordDto) => ({
          ...entity,
          source: {
            ...entity.source,
            relevance: Number(entity.score),
            highlights: entity.highlights,
          },
        })),
        totalElements: entities.totalElements,
      })),
      extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService),
      unwrapSearchContent()
    );
  }

  findRegisteredDocuments(searchParams: Partial<SearchParams>): Observable<Page<DocumentDto>> {
    if (!searchParams.filter!.find(f => f.fieldName === 'refNumber')) {
      searchParams.filter!.push({
        fieldName: 'refNumber',
        operator: FilterOperator.notEmpty,
        value: null,
      });
    }
    if (!searchParams.filter!.find(f => f.fieldName === 'objectClass')) {
      searchParams.filter!.push({
        fieldName: 'objectClass',
        operator: FilterOperator.inSet,
        value: String([ObjectClass.OWN_DOCUMENT, ObjectClass.RECEIVED_DOCUMENT]),
      });
    }

    return this.findDocumentsAll(searchParams);
  }

  findFileDocuments(searchParams: Partial<SearchParams>, fileId: number): Observable<Page<DocumentDto>> {
    searchParams.filter!.push({
      fieldName: 'fileId',
      value: '' + fileId,
    });
    return this.findDocumentsAll(searchParams);
  }

  findAllDocumentsInFile(searchParams: Partial<SearchParams>, fileId: number): Observable<Page<SearchRecordSourceDocumentFileDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<SearchRecordSourceDocumentFileDto>>>(searchParams, applyPathParams(CORE_MICROSERVICE_ROOT + ApiElasticsearchService.ElasticsearchFindDocumentsInFilePath, {fileId})).pipe(
      extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService),
      unwrapSearchContent<SearchRecordSourceDocumentFileDto>()
    );
  }

  findAllDocumentsInStorageUnit(searchParams: Partial<SearchParams>, storageUnitId: number): Observable<Page<SearchRecordSourceDocumentFileDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<SearchRecordSourceDocumentFileDto>>>(searchParams, applyPathParams(CORE_MICROSERVICE_ROOT + ApiElasticsearchService.ElasticsearchFindDocumentsAndFilesInStorageUnitPath, {storageUnitId})).pipe(
      extendEsslObjectsWithRepresenting(this.apiSubjectRecordNgElasticService),
      unwrapSearchContent<SearchRecordSourceDocumentFileDto>()
    );
  }

  findAllEntitiesForStorageUnitInsert(searchParams: Partial<SearchParams>, storageUnitId: number): Observable<Page<SearchRecordSourceDocumentFileDto>> {
    return this.searchApi<Page<TypedSearchRecordDto<SearchRecordSourceDocumentFileDto>>>(
      searchParams,
      applyPathParams(
        CORE_MICROSERVICE_ROOT + ApiElasticsearchService.ElasticsearchLoadEntitiesForStorageUnitInsertViewPath,
        {
          storageUnitId,
          viewName: 'entities-for-storage-unit-insert',
        }
      )
    ).pipe(
      unwrapSearchContent<SearchRecordSourceDocumentFileDto>()
    );
  }

  findFiles(searchParams: Partial<SearchParams>): Observable<Page<FileDto>> {
    return this.findFilesAll(searchParams);
  }

  private addExpiringDocumentsCriteriaToSearchParams(searchParams: Partial<SearchParams>, triggerDate: Date) {
    if (!searchParams.filter!.find(f => f.fieldName === 'resolutionDate')) {
      searchParams.filter!.push({
        fieldName: 'resolutionDate',
        value: triggerDate.toISOString(),
        operator: FilterOperator.lessOrEqual,
      });
    }
  }

}
