<div class="row gap-0 icz-body-1">
  <div>
    <div class="selection-grid-header">Hodiny</div>
    <div class="hours-grid">
      @for (hour of availableHours; track hour) {
        <div
          class="grid-item"
          [class.active]="hours === hour"
          (click)="hoursChanged(hour)"
          >
          {{ hour }}
        </div>
      }
    </div>
  </div>
  <div>
    <div class="selection-grid-header">Minuty</div>
    <div class="minutes-grid">
      @for (minute of availableMinutes; track minute) {
        <div
          class="grid-item"
          [class.active]="minutes === minute"
          (click)="minutesChanged(minute)"
          >
          {{ zeroPadMinutes(minute) }}
        </div>
      }
    </div>
  </div>
</div>
