/* tslint:disable */
/* eslint-disable */

/**
 * Type of circulation activity that is subject to notification of subtype DEADLINE_CIRCULATION_TASK_DEADLINE.
 */
export enum CirculationActivityForNotification {
  OWNER_CHANGE = 'OWNER_CHANGE',
  COMPONENT_SIGN = 'COMPONENT_SIGN',
  APPROVAL = 'APPROVAL',
  STATEMENT = 'STATEMENT',
  ACKNOWLEDGMENT = 'ACKNOWLEDGMENT'
}
