import {inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {isIgnoredRequestUrl, isRequestToConfigServerBasicResource} from '../../services/services-utils';
import {ENVIRONMENT} from '../services/environment.service';
import {AuthService} from '../authentication/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authService = inject(AuthService);
  private environment = inject(ENVIRONMENT);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isIgnoredRequestUrl(req, this.environment)) {
      return next.handle(req);
    }
    else if (isRequestToConfigServerBasicResource(req)) {
      return next.handle(req);
    }
    else {
      if (this.authService.isAuthenticatedWithFunctionalPosition || this.authService.isAuthenticatedWithoutFunctionalPosition) {
        req = req.clone({
          headers: req.headers.set('Authorization', `Bearer ${this.authService.authToken!.access_token}`),
        });

        return next.handle(req);
      }
      else {
        throw new Error(`Unauthorized request to authorized endpoint ${req.url}.`);
      }
    }
  }

}
