import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {getComponentIconByFilenameSuffix} from '../../../model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ComponentUploadStatus} from '../essl-components/essl-components-utils';
import {AbstractControl} from '@angular/forms';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {EsslErrorCodeExceptionDto} from '|api/commons';
import {interpolateBackendErrorMessage} from '../../essentials/interpolate-backend-error.pipe';
import {IczValidatorFn} from '../../form-elements/validators/icz-validators/validator-decorators';

interface UploadListItem {
  name: string;
  size: number; // in Bytes
}

export function fileUploadFormArray(arrayValidators: IczValidatorFn[]) {
  return new IczFormArray(
    () => new IczFormGroup({
      file: new IczFormControl<Nullable<string>>(null),
      digitalComponentVersionId: new IczFormControl<Nullable<string>>(null),
      status: new IczFormControl<Nullable<ComponentUploadStatus>>(null),
      uploadExceptions: new IczFormControl<Nullable<EsslErrorCodeExceptionDto[]>>(null),
    }),
    [],
    arrayValidators
  );
}

@Component({
  selector: 'icz-file-upload-list',
  templateUrl: './file-upload-list.component.html',
  styleUrls: ['./file-upload-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadListComponent implements OnInit {

  protected cd = inject(ChangeDetectorRef);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);
  private translateParser = inject(TranslateParser);
  private translateService = inject(TranslateService);

  @ViewChild('fileUpload', {static: false})
  fileUpload!: ElementRef;

  @Input({required: true})
  form!: IczFormGroup;
  @Input()
  fileFormArrayName = 'digitalComponents';
  @Input()
  isMultiUpload = false;
  @Input()
  showFileSize = true;
  @Input()
  allowedFileExtensions: string[] = [];
  @Input()
  showFileDragAndDrop = false;
  @Input()
  showFileUploadButton = false;
  @Input()
  fileToDtoTransformer: (file: File) => any = file => ({
    file,
    digitalComponentVersionId: null,
    status: ComponentUploadStatus.IDLE
  });
  @Input()
  fileDeletionHandler: (index: number) => Observable<any> = _ => of(null);
  @Input()
  withFileIcon = true;

  @Output()
  filesChanged = new EventEmitter<void>();

  isFileDrag = false;

  get files() {
    return this.form!.get(this.fileFormArrayName) as IczFormArray;
  }

  getFileUploadExceptions(index: number) {
    const uploadExceptionsCtrl = this.files.at(index).get('uploadExceptions') as IczFormControl;
    if (uploadExceptionsCtrl) {
      return (uploadExceptionsCtrl.value ?? []) as EsslErrorCodeExceptionDto[];
    } else {
      return [];
    }
  }

  formatUploadException(exception: EsslErrorCodeExceptionDto) {
    return interpolateBackendErrorMessage(this.translateService, {errorCode: exception.code!, parameters: exception.parameters ?? []});
  }

  get fileInputAccept() {
    if (!this.allowedFileExtensions.length) {
      return null;
    }
    else {
      return this.allowedFileExtensions.join(',');
    }
  }

  get dndHelpText() {
    if (this.allowedFileExtensions.length > 0) {
      return this.translateParser.interpolate( this.translateService.instant('Můžete sem přetáhnout soubory s příponou {{allowedExtensions}} nebo je nahrát z počítače.'),
        {allowedExtensions: this.allowedFileExtensions.join(', ')}
      );
    } else {
      return this.translateService.instant('Můžete sem přetáhnout soubory nebo je nahrát z počítače.');
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.cd.detectChanges();
    });
  }

  openUploadDialog() {
    this.fileUpload.nativeElement.click();
  }

  showSpinner(fileItem: AbstractControl) {
    const fileStatusControl = fileItem.get('status');
    if (fileStatusControl) {
      return fileStatusControl.value === ComponentUploadStatus.UPLOADING || fileStatusControl.value === ComponentUploadStatus.IDLE;
    } else {
      return false;
    }
  }

  showFileIcon(fileItem: AbstractControl) {
    const fileStatusControl = fileItem.get('status');
    if (fileStatusControl) {
      return fileStatusControl.value === ComponentUploadStatus.SUCCESS;
    } else {
      return false;
    }
  }

  showErrorIcon(fileItem: AbstractControl) {
    const fileStatusControl = fileItem.get('status');
    if (fileStatusControl) {
      return fileStatusControl.value === ComponentUploadStatus.ERROR || fileStatusControl.value === ComponentUploadStatus.INVALID_EXTENSION;
    } else {
      return false;
    }
  }

  deleteDigitalComponent(index: number) {
    this.loadingIndicatorService.doLoading(
      this.fileDeletionHandler(index),
      this,
      index
    ).subscribe(() => {
      this.files.removeAt(index);
      this.filesChanged.emit();
    });
  }

  processFileUpload(fileUpload: any) {
    for (let i = 0; i < fileUpload.files.length; i++) {
      const newFileForm = this.files.incrementSize() as IczFormGroup;
      const fileExtension = fileUpload.files.item(i)!.name.split('.').at(-1);
      if (this.allowedFileExtensions.length > 0 && fileExtension && !this.allowedFileExtensions.includes(`.${fileExtension}`)) {
        newFileForm.patchValue({...this.fileToDtoTransformer(fileUpload.files.item(i)!), status: ComponentUploadStatus.INVALID_EXTENSION, uploadExceptions: [{code: 'fe.ui.fileUploadException.wrongExtension', parameters: [`.${fileExtension}`]}]});
      } else {
        newFileForm.patchValue(this.fileToDtoTransformer(fileUpload.files.item(i)!));
      }
    }
  }

  setFiles() {
    const fileUpload = this.fileUpload.nativeElement;
    this.processFileUpload(fileUpload);

    fileUpload.value = null;

    this.filesChanged.emit();
  }

  getFileName(file: UploadListItem) {
    return file.name;
  }

  getFileSize(file: UploadListItem) {
    return file.size;
  }

  getFileIcon(file: UploadListItem) {
    return getComponentIconByFilenameSuffix(this.getFileName(file));
  }

  onFileDragover(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDrag = true;
  }

  onFileDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDrag = false;
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDrag = false;

    const fileUpload = event.dataTransfer;
    if (fileUpload) {
      this.processFileUpload(fileUpload);
      fileUpload.clearData();
      this.filesChanged.emit();
    }
  }

}
