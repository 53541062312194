import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'icz-table-toolbar-popup',
  templateUrl: './table-toolbar-popup.component.html',
  styleUrls: ['./table-toolbar-popup.component.scss'],
})
export class TableToolbarPopupComponent {
  @Input({required: true}) popoverOrigin!: CdkOverlayOrigin;
  @Input() isOpen = false;

  @Output() onClose = new EventEmitter<void>();

  toggle() { this.isOpen = !this.isOpen; }
}
