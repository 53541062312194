/* eslint-disable no-console */
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Language} from '|api/commons';
import {ApiDictionaryOauthService} from '|api/config-server';
import {AuthService} from '../authentication/auth.service';
import {GlobalLoadingIndicatorService} from '../../components/essentials/global-loading-indicator.service';
import {Option} from '../../model';
import {ApplicationLanguage} from '../services/environment.models';
import {HealthcheckResult, HealthcheckService} from '../guards/healthcheck.service';
import {ActiveModuleService, getApplicationModuleByUrl} from '../services/active-module.service';
import {getPreferredBrowserLanguage, isEnumValue} from '../../lib/utils';
import {LocalStorageKey, UserSettingsService} from '../../services/user-settings.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DestroyRef, inject} from '@angular/core';


export const AVAILABLE_LANGUAGES: Array<Option<ApplicationLanguage>> = [
  {
    value: ApplicationLanguage.CZECH,
    label: 'Česky',
    icon: 'flag_cz',
  },
  {
    value: ApplicationLanguage.SLOVAK,
    label: 'Slovensky',
    icon: 'flag_sk',
  },
  {
    value: ApplicationLanguage.ENGLISH,
    label: 'Anglicky',
    icon: 'flag_en',
  }
];

function checkDictionaryEmpty(dictionary: Record<string, string>, dictionaryName: string) {
  if (!Object.keys(dictionary).length) {
    console.error(`Dictionary "${dictionaryName}" is empty.`);
    console.error(dictionary);
  }
}


export class IczTranslateLoader implements TranslateLoader {

  private httpClient = inject(HttpClient);
  private apiDictionaryOauthService = inject(ApiDictionaryOauthService);
  private authService = inject(AuthService);
  private healthcheckService = inject(HealthcheckService);
  private activeModuleService = inject(ActiveModuleService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private destroyRef = inject(DestroyRef);

  getTranslation(lang: ApplicationLanguage): Observable<any> {
    const pageRootElement = document.querySelector('html');
    const dictionaryObservables: Array<Observable<Nullable<any>>> = [];

    dictionaryObservables.push(this.httpClient.get(`/assets/i18n-local/${lang}.json`));

    // Setting activeModule here is important because application healthcheck
    //  checks different sets of microservices for each ApplicationModule.
    //  Location.pathname is used because Router#url is not yet fully
    //  known in some cases, namely at application init time.
    this.activeModuleService.activeModule = getApplicationModuleByUrl(location.pathname);

    if (pageRootElement) {
      pageRootElement.lang = lang;
    }

    const loadDisctionaryObs$ = this.healthcheckService.getBackendHealthcheckResult().pipe(
      takeUntilDestroyed(this.destroyRef),
      switchMap((res: HealthcheckResult) => {
        // if backend is unavailable on frontend start, regardless of user having valid token, don't try to get translations
        if (res.isUp === false) {
          dictionaryObservables.push(of(null), of({emptyDictionary: 'and that is fine'}));
        }
        // get translations for the main app
        else if (this.authService.isAuthenticatedWithFunctionalPosition) {
          const frontEndDictionary$ = this.httpClient.get(`/assets/i18n/${lang}.json`);

          const langUppercase = lang.toUpperCase();
          if (!isEnumValue(Language)(langUppercase)) {
            throw new Error(`Value ${langUppercase} is not in LangFindByLanguageUsingGETParamsEnum`);
          }

          const generalDictionary$ = this.apiDictionaryOauthService
            .dictionaryOauthFindByLanguage({lang: langUppercase}).pipe(
              map(array => {
                const dictionary: Record<string, string> = {};
                array.forEach(phrase => dictionary[phrase.key] = phrase.value);
                return dictionary;
              }),
            );

          dictionaryObservables.push(frontEndDictionary$, generalDictionary$);
        }

        if (dictionaryObservables.length === 1) {
          console.info('Fetching only login screen dictionary');
        }
        else if (dictionaryObservables.length === 3) {
          console.info('Fetching login screen, frontend and backend dictionaries');
        }
        else if (dictionaryObservables.length === 0) {
          console.info('No dictionaries to fetch');
        }

        return combineLatest(dictionaryObservables).pipe(
          map(dictionaries => {
            const loginScreenDictionary = dictionaries[0] ?? {};
            const frontendDictionary = dictionaries[1] ?? {};
            const backendDictionary = dictionaries[2] ?? {};

            checkDictionaryEmpty(loginScreenDictionary, 'Login Screen');
            if (dictionaries.length === 3) checkDictionaryEmpty(backendDictionary, 'BE');

            return {
              ...loginScreenDictionary,
              ...backendDictionary,
              ...frontendDictionary, // FE dictionary has higher priority for cases of debugging, thus must be lower
            };
          }),
        );
      })
    );

    if (this.globalLoading.isInitialized) {
      return this.globalLoading.doLoading(loadDisctionaryObs$);
    } else {
      return loadDisctionaryObs$;
    }
  }

}

export function loadTranslations(
  translateService: TranslateService,
  userSettingsService: UserSettingsService,
  authService: AuthService,
  loadCondition?: Nullable<boolean>,
): Observable<void> {
  if (userSettingsService.getRawValue(LocalStorageKey.USER_LANGUAGE) === null) {
    const userLocale = getPreferredBrowserLanguage();
    let languageFromLocale;

    if (userLocale.includes('cs')) languageFromLocale = ApplicationLanguage.CZECH;
    else if (userLocale.includes('sk')) languageFromLocale = ApplicationLanguage.SLOVAK;
    else languageFromLocale = ApplicationLanguage.ENGLISH;

    userSettingsService.setRawValue(LocalStorageKey.USER_LANGUAGE, languageFromLocale);
  }

  translateService.addLangs([
    ApplicationLanguage.CZECH,
    ApplicationLanguage.SLOVAK,
    ApplicationLanguage.ENGLISH
  ]);

  if (isNil(loadCondition) || loadCondition) {
    const userLanguage = userSettingsService.getRawValue(LocalStorageKey.USER_LANGUAGE)!;

    translateService.setDefaultLang(userLanguage);
    return translateService.reloadLang(userLanguage);
  }
  else {
    return of();
  }
}
