import {Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {CirculationActivityFlowType, EntityType} from '|api/commons';
import {FileDto} from '|api/document';
import {
  ApiCirculationActivityBulkActionsService,
  ApiCirculationActivityService,
  ApiCirculationTaskBulkActionsService,
  ApiCirculationTaskHandoverService,
  CirculationActivityDto,
  CirculationActivityPreconditionFailureType,
  CirculationHandoverActivityCreateDto,
  CirculationHandoverTaskTakeoverPassDto,
  CirculationTaskDto
} from '|api/flow';
import {
  AbstractHandoverFormModalComponent,
  getSelectedSingleselectOrgStructureElement
} from '../../document-toolbar/components/abstract-handover-form-modal.component';
import {FunctionalPositionGroup, FunctionalPositionGroupItems} from '../../saved-functional-positions.service';
import {DerivedOrgStructureElementOption} from '../../document-toolbar/components/abstract-handover-form.component';
import {CheckUnsavedFormDialogService} from '../../../dialogs/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup, TextLength} from '../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../form-elements/validators/icz-validators/icz-validators';
import {isFileObject} from '../../shared-document.utils';
import {IczModalService} from '../../../../services/icz-modal.service';
import {DialogService} from '../../../../core/services/dialog.service';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';
import {handleCirculationErrorAsToast, isTaskEntity} from '../../../../utils/document-file-circulation.utils';
import {ExtendedCirculationTaskDto, isExtendedDocumentTaskDto} from '../../model/elastic-extended-entities.interface';
import {
  PrimaryFunctionalPositionPickerComponent,
  PrimaryFunctionalPositionPickerDialogData,
  PrimaryFunctionalPositionPickerResult
} from '../../primary-functional-position-picker/primary-functional-position-picker.component';
import {injectModalData} from 'libs/shared/src/lib/lib/modals';


@Component({
  selector: 'icz-file-handover-dialog',
  templateUrl: './file-handover-dialog.component.html',
  styleUrls: ['./file-handover-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class FileHandoverDialogComponent extends AbstractHandoverFormModalComponent {

  private modalService = inject(IczModalService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);
  private apiCirculationTaskHandoverService = inject(ApiCirculationTaskHandoverService);
  private apiCirculationTaskBulkActionsService = inject(ApiCirculationTaskBulkActionsService);
  private dialogService = inject(DialogService);
  protected override circulationEntities = injectModalData<Array<FileDto | ExtendedCirculationTaskDto>>();

  override form = new IczFormGroup({
    orgStructureElementId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    deadline: new IczFormControl<Nullable<string>>(null),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });

  override get groupForSaving(): FunctionalPositionGroupItems {
    const primaryFp = this.originalOrgStructureElementId;
    return primaryFp ? [primaryFp] : [];
  }

  // sensible default
  protected override selectedFlowType = CirculationActivityFlowType.PARALLEL;

  protected override orgStructureElementIdsControlName = 'orgStructureElementId';

  private get originalOrgStructureElementId(): Nullable<number> {
    return this.form.get('orgStructureElementId')!.value;
  }

  protected override get selectedOrgStructureOptions() {
    return getSelectedSingleselectOrgStructureElement(this.orgStructureElementsControl, this.orgStructureOptions);
  }

  private getOptionValue = (o: DerivedOrgStructureElementOption, index: number) => o.value;

  protected override functionalPositionMapper = this.getOptionValue;
  protected override organizationalUnitMapper = this.getOptionValue;

  readonly isTaskEntity = isTaskEntity;
  readonly isFileObject = isFileObject;

  isTakeoverAndPassMode = false;

  override ngOnInit() {
    super.ngOnInit();

    this.isTakeoverAndPassMode = this.circulationEntities.every(entity => isTaskEntity(entity));

    if (this.isSingleEntityFlow) {
      if (!isExtendedDocumentTaskDto(this.circulationEntities[0])) {
        this.loadingService.doLoading(
          this.apiCirculationActivityService.circulationActivityExecuteFileHandoverPreconditionsCheck({body: [this.circulationEntities[0].id]}), this).subscribe(results => {
          if (results && results.length === 1 && !results[0].passed) {
            let text = '';
            const result = results[0];
            const context: Record<string, string> = {};
            const failedFilePrecondtion = result.failures.find(f => f.entityType === EntityType.FILE);
            const failedDocumentPrecondtions = result.failures.filter(f => f.entityType === EntityType.DOCUMENT);

            if (failedFilePrecondtion) {
              if (
                failedFilePrecondtion.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NOT_HOLDER ||
                failedFilePrecondtion.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NOT_OWNER ||
                failedFilePrecondtion.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NEITHER_OWNER_NOR_HOLDER
              ) {
                text = 'Spis může být předán do oběhu pouze uživatelem, který je zpracovatelem a zároveň také držitelem spisu.';
              } else if (failedFilePrecondtion.failedPrecondition === CirculationActivityPreconditionFailureType.INCOMPATIBLE_FLOW_CURRENTLY_RUNNING) {
                text = 'Nad spisem běží oběh, který brání jeho předání.';
              }
            } else if (failedDocumentPrecondtions.length) {
              const errorDocuments: Record<string, any> = {};
              errorDocuments.notHolderOrOwner = failedDocumentPrecondtions.map(
                doc => (
                  doc.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NOT_HOLDER ||
                  doc.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NOT_OWNER ||
                  doc.failedPrecondition === CirculationActivityPreconditionFailureType.CURRENT_FP_IS_NEITHER_OWNER_NOR_HOLDER
                )
              );
              errorDocuments.inpompatibleFlow = failedDocumentPrecondtions.map(
                doc => doc.failedPrecondition === CirculationActivityPreconditionFailureType.INCOMPATIBLE_FLOW_CURRENTLY_RUNNING
              );

              if (errorDocuments.notHolderOrOwner) {
                text = 'Pro některé dokumenty ({{count}}) ve spisu, nejste zpracovatelem a zároveň také držitelem.';
                context.count = String(errorDocuments.notHolderOrOwner.length);
              } else if (errorDocuments.inpompatibleFlow) {
                text = 'Nad některými dokumenty ({{count}}) ve spisu běží oběhy, které brání předání celého spisu.';
                context.count = String(errorDocuments.inpompatibleFlow.length);
              }
            }

            setTimeout(() => {
              this.dialogService.showError(text, 'Spis nelze předat oběhem', context).subscribe(_ => {
                this.modalRef.close();
              });
            });
          }
        });
      }
    }

    /**
     * if initEntity is a task, this modal serves for "takeover and pass" workflow action.
     * else it is used for initiating "file handover" activity
     */
    if (this.isTakeoverAndPassMode) {
      const deadlineControl = this.form.get('deadline')!;

      deadlineControl.setValue((this.circulationEntities[0] as ExtendedCirculationTaskDto).deadline);
      deadlineControl.disable();
    }
  }

  submit() {
    const formValue = this.form.value;
    let submit$: Observable<unknown>;

    if (this.isTakeoverAndPassMode) {
      const takeoverActionDtos: CirculationHandoverTaskTakeoverPassDto[] = this.circulationEntities.map(entity => ({
        id: entity.id,
        primaryFunctionalPositionId: this.selectedFunctionalPositions[0],
        primaryOrganizationalUnitId: this.selectedOrganizationalUnits[0],
        targetTaskInstruction: formValue.targetTaskInstruction!,
      }));

      if (this.isSingleEntityFlow) {
        submit$ = this.apiCirculationTaskHandoverService.circulationTaskHandoverTakeoverAndPass(
          {
            id: this.circulationEntities[0].id,
            body: takeoverActionDtos[0],
          },
          SKIP_ERROR_DIALOG
        );
      } else {
        submit$ = this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsTakeoverAndPass(
          {
            body: takeoverActionDtos,
          },
          SKIP_ERROR_DIALOG
        );
      }
    }
    else {
      const activityCreateDtos: CirculationHandoverActivityCreateDto[] = this.circulationEntities.map(entity => ({
        circulationEntityType: (entity as FileDto).entityType!,
        deadline: formValue.deadline!,
        flowType: this.selectedFlowType,
        targetTaskInstruction: formValue.targetTaskInstruction!,
        fileId: entity.id,
        primaryFunctionalPositionId: this.selectedFunctionalPositions[0],
        primaryOrganizationalUnitId: this.selectedOrganizationalUnits[0],
      }));

      if (this.isSingleEntityFlow) {
        submit$ = this.apiCirculationActivityService.circulationActivityCreateHandover(
          {
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        );
      } else {
        submit$ = this.apiCirculationActivityBulkActionsService.circulationActivityBulkActionsHandover(
          {
            body: activityCreateDtos,
          },
          SKIP_ERROR_DIALOG
        );
      }
    }

    this.loadingService.doLoading(
      submit$,
      this
    ).subscribe({
      next: resultEntity => {
        this.form.reset();
        if (this.isTaskEntity(this.circulationEntities[0])) { // takeover and pass = result is a task
          if (this.isSingleEntityFlow) {
            this.modalRef.close((resultEntity as CirculationTaskDto).activityId);
          } else {
            this.modalRef.close(this.circulationEntities.length);
          }
        }
        else { // handover create = result is an activity
          if (this.isSingleEntityFlow) {
            this.modalRef.close((resultEntity as CirculationActivityDto).id);
          } else {
            this.modalRef.close(this.circulationEntities.length);
          }
        }
      },
      error: errorResponse => handleCirculationErrorAsToast(
        this.circulationEntities[0],
        errorResponse,
        this.toastService,
        this.translateService,
        this.modalRef
      ),
    });
  }

  override applySavedGroup(functionalPositionGroup: Nullable<FunctionalPositionGroup>) {
    const orgStructureElementControl = this.form.get('orgStructureElementId')!;
    const functionalPositionGroupItems = functionalPositionGroup?.items;

    if (functionalPositionGroupItems) {
      if (functionalPositionGroupItems.length === 0) {
        return;
      }
      else if (functionalPositionGroupItems.length === 1) {
        orgStructureElementControl.setValue(functionalPositionGroupItems[0] as number);
      }
      else {
        this.modalService.openComponentInModal<PrimaryFunctionalPositionPickerResult, PrimaryFunctionalPositionPickerDialogData>({
          component: PrimaryFunctionalPositionPickerComponent,
          modalOptions: {
            titleTemplate: 'Kterému funkčnímu místu má být předán spis?',
            width: 600,
            height: 500,
            isClosable: false,
          },
          data: {
            selectedGroup: functionalPositionGroupItems,
            showHandCopyToAll: false,
          },
        }).subscribe(result => {
          if (result) {
            orgStructureElementControl.setValue(result.originalReceiver as any);
          }
        });
      }
    }
    else {
      orgStructureElementControl.setValue(null);
    }
  }

}
