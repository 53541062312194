/* tslint:disable */
/* eslint-disable */

/**
 * Event state
 */
export enum ErmsOutgoingEventAuditState {
  TO_SEND = 'TO_SEND',
  OBSOLETED = 'OBSOLETED',
  SENDING_FAILED = 'SENDING_FAILED',
  SENT_SUCCESSFULLY = 'SENT_SUCCESSFULLY',
  WITHDRAWN = 'WITHDRAWN',
  FIXED = 'FIXED',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
  PROCESSING_ISSD_MESSAGE_FAILED = 'PROCESSING_ISSD_MESSAGE_FAILED'
}
