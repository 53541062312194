<div class="icz-body-1">
  {{ dialogText }}
</div>

<icz-form-buttons>
  <icz-button (onAction)="markDigitalComponent(true)" leftButtons primary label="Opečetit a orazítkovat"></icz-button>
  @if (applicationConfigService.signatureTimestampMandatorness === SignaturePlaceTimestamp.ALLOWED) {
    <icz-button
      (onAction)="markDigitalComponent(false)"
      leftButtons label="Opečetit"
    ></icz-button>
  }
  <icz-button rightButtons (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
