<icz-essl-component
  [documentEsslComponent]="documentEsslComponent"
  [versionId]="versionId"
  [locked]="locked"
  [hideDescription]="hideDescription"
  [hideIcon]="hideIcon"
  [showFileName]="showFileName"
  (click)="fileClick()">
  <ng-container fileStatus>
    <ng-content select="[fileStatus]"></ng-content>
  </ng-container>
</icz-essl-component>
