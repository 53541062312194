/* tslint:disable */
/* eslint-disable */

/**
 * Status of an own consignment that is being set explicitly.
 */
export enum ExplicitOwnConsignmentStatus {
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
  NOT_DELIVERED = 'NOT_DELIVERED'
}
