import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {DocumentDto, FileAllowableObjectClass, FileDto, StorageUnitDto} from '|api/document';
import {isDocumentEntity, isFileEntity, isStorageUnitEntity} from '../shared-document.utils';
import {EntityWithRefNumber} from '../ref-number-link/ref-number-link.component';
import {Router} from '@angular/router';
import {DocumentView} from '../document-toolbar/services/toolbar-common.utils';
import {getDetailUrlByEntity} from '../document-table/documents-table.models';

@Component({
  selector: 'icz-entity-info',
  templateUrl: './entity-info.component.html',
  styleUrls: ['./entity-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntityInfoComponent {

  private router = inject(Router);

  @Input()
  entity!: FileDto | DocumentDto | StorageUnitDto;
  @Input()
  isLink = false;

  get isDocumentEntity() {
    return isDocumentEntity(this.entity?.entityType);
  }

  get isFileEntity() {
    return isFileEntity(this.entity?.entityType);
  }

  get isStorageUnitEntity() {
    return isStorageUnitEntity(this.entity?.entityType);
  }

  get objectClass() {
    return this.entity?.objectClass as FileAllowableObjectClass;
  }

  navigateToEntityDetail() {
    if (this.isLink) {
      this.router.navigateByUrl(getDetailUrlByEntity(DocumentView.DOCUMENT_LISTING, this.entity as EntityWithRefNumber)!);
    }
  }

}
