import {inject, Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {DebugLoggingService} from '../services/debug-logging.service';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {

  private debugLoggingService = inject(DebugLoggingService);

  readonly ignoredUrls: string[] = [
    '/eureka/apps',
    '/assets',
  ];

  isNotInIgnoredUrls(url: string) {
    return isNil(this.ignoredUrls.find(iUrl => url.includes(iUrl)));
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap({
        next: response => {
          if (response.type === HttpEventType.Response && this.isNotInIgnoredUrls(req.url)) {
            this.debugLoggingService.logHttpRequest({
              httpMethod: req.method,
              requestUrl: req.urlWithParams,
              statusCode: response.status,
              requestHeaders: req.headers,
              requestPayload: req.body,
              responseHeaders: response.headers,
              responsePayload: response.body,
            });
          }
        },
        error: error => {
          if (error instanceof HttpErrorResponse) {
            this.debugLoggingService.logHttpRequest({
              httpMethod: req.method,
              requestUrl: req.urlWithParams,
              statusCode: error.status,
              requestHeaders: req.headers,
              requestPayload: req.body,
              responseHeaders: error.headers,
              responsePayload: error.error,
            });
          }
        },
      }),
    );
  }

}
