/* tslint:disable */
/* eslint-disable */

/**
 * Business rule name
 */
export enum BusinessRuleName {
  EXTRACTION = 'EXTRACTION',
  CONVERSION = 'CONVERSION',
  SIGNABLE = 'SIGNABLE',
  ANONYMIZATION = 'ANONYMIZATION',
  VERIFIABLE_SIGNATURE = 'VERIFIABLE_SIGNATURE',
  VISIBLE_SIGNATURE = 'VISIBLE_SIGNATURE',
  CONVERSION_TO_PAPER_FORM = 'CONVERSION_TO_PAPER_FORM',
  DISTRIBUTION_EMAIL_ALLOWED_ATTACHMENT_FORMAT = 'DISTRIBUTION_EMAIL_ALLOWED_ATTACHMENT_FORMAT',
  DISTRIBUTION_EMAIL_TECHNICAL_ATTACHMENT_FORMAT = 'DISTRIBUTION_EMAIL_TECHNICAL_ATTACHMENT_FORMAT',
  DISTRIBUTION_ISDS_ALLOWED_ATTACHMENT_FORMAT = 'DISTRIBUTION_ISDS_ALLOWED_ATTACHMENT_FORMAT',
  DISTRIBUTION_PERSONAL_TECHNICAL_ATTACHMENT_FORMAT = 'DISTRIBUTION_PERSONAL_TECHNICAL_ATTACHMENT_FORMAT',
  OFFICE_DESK = 'OFFICE_DESK'
}
