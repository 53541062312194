import {MatTableDataSource} from '@angular/material/table';
import {cloneDeep, isEqual, sortBy} from 'lodash';
import {BehaviorSubject, Observable, OperatorFunction, Subject} from 'rxjs';
import {debounceTime, filter, map, retry, share, switchMap, tap} from 'rxjs/operators';
import {Page} from '../../api';
import {FilterOperator, GenericSearchService, SearchParams} from '../../services/search-api.service';
import {HttpErrorResponse} from '@angular/common/http';

export const defaultPageSize = 20;

interface LoadPage {
  searchParams: SearchParams;
  deselectAll: boolean;
}

export const getDefaultSearchParams = (): SearchParams => ({
  page: 0,
  size: defaultPageSize,
  sort: [],
  filter: [],
});

export interface IczDataSourceDef<T> {
  url: string;
  microServiceRoot?: string;
  staticPathParams?: Record<string, string>;
  customMapper?: OperatorFunction<Page<any>, Page<T>>;
}

// Properties of params2 will override the properties of params1.
// params2 might be partial, because params1 is consistent, it will produce consistent result.
export function mergeSearchParams(params1: SearchParams, params2: Partial<SearchParams>): SearchParams {
  return {
    ...params1,
    ...params2,
    sort: [
      ...(params1.sort || []),
      ...(params2.sort || []),
    ],
    filter: [
      ...(params1.filter || []),
      ...(params2.filter || []),
    ],
  };
}

export function makeDatasourceFromSearchObs<T>(search$: (s: SearchParams) => Observable<Page<T>>): IczTableDataSource<T> {
  // constructs an instance of anonymous class declared at runtime
  return new (class extends IczTableDataSource<T> {
    constructor() {
      super(search$);
    }
  })();
}

export function dataSourceContainsValue(
  dataSource: IczTableDataSource<any>,
  dataFieldName: string,
  value: any,
): Observable<boolean> {
  dataSource.loadPage({
    page: 0,
    size: defaultPageSize,
    filter: [{
      fieldName: dataFieldName,
      value: String(value),
      operator: FilterOperator.equals,
    }],
    sort: [],
  });

  return dataSource.items$.pipe(
    map(valueItems => valueItems.length > 0),
  );
}

export class IczTableDataSource<T> extends MatTableDataSource<T> {
  // Used for passing in additional hidden search parameters which are not originated from table toolbar filters.
  // It is partial because these parameters might not need to set page size and page number, for example.
  additionalSearchParams: Nullable<Partial<SearchParams>>;
  disablePagination = false;

  loading$!: Observable<boolean>;
  error$!: Observable<Nullable<HttpErrorResponse>>;
  unselectAll$!: Observable<void>;
  loadPage$!: Observable<Nullable<SearchParams>>;
  loadPageResult$!: Observable<Page<T>>;
  items$!: Observable<T[]>;

  protected _unselectAll$!: Subject<void>;
  protected _items$!: BehaviorSubject<T[]>;
  protected _error$!: BehaviorSubject<Nullable<HttpErrorResponse>>;
  protected _loading$!: BehaviorSubject<boolean>;
  protected _loadPage$!: Subject<Nullable<LoadPage>>;
  protected lastSearchParams!: SearchParams;

  constructor(
    protected search$: (searchParams: SearchParams) => Observable<Page<T>>,
  ) {
    super();
    this.initialize();
  }

  /**
   * Overrides _filterData() of MatTableDataSource to not modify paginator.length
   */
  override _filterData(data: T[]) {
    this.filteredData =
      !this.filter ? data : data.filter((obj => this.filterPredicate(obj, this.filter)));
    return this.filteredData;
  }

  reload(deselectAll = false) {
    if (!this.lastSearchParams) this.lastSearchParams = getDefaultSearchParams();

    this.loadPage(this.lastSearchParams, deselectAll);
  }

  getAllItems() {
    return this.search$({
      ...this.lastSearchParams,
      filter: [...(this.lastSearchParams?.filter ?? [])],
      sort: [...(this.lastSearchParams?.sort ?? [])],
      page: 0,
      size: this.paginator?.length!
    });
  }

  loadPage(searchParams: SearchParams, deselectAll = false) {
    let finalSearchParams: SearchParams;

    if (this.additionalSearchParams) {
      finalSearchParams = mergeSearchParams(
        searchParams,
        this.additionalSearchParams
      );
    }
    else {
      finalSearchParams = cloneDeep(searchParams);
    }

    this._loadPage$.next({
      searchParams: finalSearchParams,
      deselectAll
    });

    // Only search params originating from table toolbar are persisted.
    this.lastSearchParams = searchParams;
  }

  sortOrFilterChanged(searchParams: SearchParams) {
    if (searchParams && this.lastSearchParams) {
      // searchParams come in already merged but this.lastSearchParams are unmerged;
      //  this construct constructs lastSearchParams such that they are mutually
      //  comparable without false positives.
      const finalLastSearchParams = mergeSearchParams(
        this.lastSearchParams,
        this.additionalSearchParams ?? {}
      );

      return (
        !isEqual(sortBy(searchParams.sort), sortBy(finalLastSearchParams.sort)) ||
        !isEqual(sortBy(searchParams.filter), sortBy(finalLastSearchParams.filter))
      );
    }
    else {
      return false;
    }
  }

  override connect(): BehaviorSubject<T[]> {
    return this._items$;
  }

  override disconnect(): void {
    this._items$.complete();
    this._loading$.complete();
    this._loadPage$.complete();

    // after disconnecting, the datasource should
    // be reusable in the same context
    this.initialize();
  }

  setData(data: T[]) {
    this.data = data;
    this._items$.next(data);
  }

  protected initialize() {
    this._unselectAll$ = new Subject<void>();
    this._items$ = new BehaviorSubject<T[]>([]);
    this._loading$ = new BehaviorSubject<boolean>(false); // false because we might not need to load data right away after page load
    this._error$ = new BehaviorSubject<Nullable<HttpErrorResponse>>(null);
    this._loadPage$ = new Subject<Nullable<LoadPage>>();
    this.unselectAll$ = this._unselectAll$.asObservable();
    this.items$ = this._items$.asObservable();
    this.loading$ = this._loading$.asObservable();
    this.error$ = this._error$.asObservable();
    this.loadPage$ = this._loadPage$.pipe(
      map(loadPage => loadPage?.searchParams),
    );
    this.loadPageResult$ = this._loadPage$.pipe(
      filter(loadPage => Boolean(loadPage && loadPage.searchParams)),
      tap(_ => {
        this._loading$.next(true);
      }),
      debounceTime(100),
      tap(loadPage => {
        if (loadPage!.deselectAll) {
          this._unselectAll$.next();
        }
      }),
      map(loadPage => {
        if (this.sortOrFilterChanged(loadPage!.searchParams)) {
          loadPage!.searchParams.page = 0;
          this.paginator?.firstPage();
        }
        return loadPage!.searchParams;
      }),
      switchMap(searchParams => this.search$(searchParams)),
      tap({
        error: e => {
          if (e instanceof HttpErrorResponse) {
            this.setData([]);
            this._error$.next(e);
            this._loading$.next(false);
          } else {
            // eslint-disable-next-line no-console -- important for catching hard-to-debug issues
            console.error(e);
          }
        },
      }),
      retry(),
      filter(page => Boolean(page)),
      share(),
    ) as Observable<Page<T>>;

    this.loadPageResult$.subscribe({
      next: page => {
        this.setData(page.content ?? []);
        this._error$.next(null);
        this._loading$.next(false);
        if (this.paginator) this.paginator.length = page.totalElements ?? 0;
      },
      error: error => {
        this.setData([]);
        this._error$.next(error);
        this._loading$.next(false);
        if (this.paginator) this.paginator.length = 0;
        throw error;
      }
    });
  }
}

export class IczSimpleTableDataSource<T> extends IczTableDataSource<T> {
  constructor(searchService: GenericSearchService, dataSource: IczDataSourceDef<T>) {
    super(searchParams => searchService.genericSearchApi<T>(searchParams, dataSource));
  }
}
