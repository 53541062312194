/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deliveryServiceAdditionalCreate } from '../fn/delivery-service-additional/delivery-service-additional-create';
import { DeliveryServiceAdditionalCreate$Params } from '../fn/delivery-service-additional/delivery-service-additional-create';
import { DeliveryServiceAdditionalDto } from '../models/delivery-service-additional-dto';
import { deliveryServiceAdditionalFindAll } from '../fn/delivery-service-additional/delivery-service-additional-find-all';
import { DeliveryServiceAdditionalFindAll$Params } from '../fn/delivery-service-additional/delivery-service-additional-find-all';
import { deliveryServiceAdditionalFindByDeliveryTypeGroup } from '../fn/delivery-service-additional/delivery-service-additional-find-by-delivery-type-group';
import { deliveryServiceAdditionalFindByDeliveryTypeGroup_1 } from '../fn/delivery-service-additional/delivery-service-additional-find-by-delivery-type-group-1';
import { DeliveryServiceAdditionalFindByDeliveryTypeGroup_1$Params } from '../fn/delivery-service-additional/delivery-service-additional-find-by-delivery-type-group-1';
import { DeliveryServiceAdditionalFindByDeliveryTypeGroup$Params } from '../fn/delivery-service-additional/delivery-service-additional-find-by-delivery-type-group';
import { deliveryServiceAdditionalFindById } from '../fn/delivery-service-additional/delivery-service-additional-find-by-id';
import { DeliveryServiceAdditionalFindById$Params } from '../fn/delivery-service-additional/delivery-service-additional-find-by-id';
import { deliveryServiceAdditionalSearch } from '../fn/delivery-service-additional/delivery-service-additional-search';
import { DeliveryServiceAdditionalSearch$Params } from '../fn/delivery-service-additional/delivery-service-additional-search';
import { deliveryServiceAdditionalUpdate } from '../fn/delivery-service-additional/delivery-service-additional-update';
import { DeliveryServiceAdditionalUpdate$Params } from '../fn/delivery-service-additional/delivery-service-additional-update';
import { PageDeliveryServiceAdditionalDto } from '../models/page-delivery-service-additional-dto';


/**
 * Additional delivery services controller
 */
@Injectable({ providedIn: 'root' })
export class ApiDeliveryServiceAdditionalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `deliveryServiceAdditionalFindById()` */
  static readonly DeliveryServiceAdditionalFindByIdPath = '/codebook/delivery-service-additional/{id}';

  /**
   * Finds the service by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindById$Response(params: DeliveryServiceAdditionalFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceAdditionalDto>> {
    return deliveryServiceAdditionalFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds the service by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindById(params: DeliveryServiceAdditionalFindById$Params, context?: HttpContext): Observable<DeliveryServiceAdditionalDto> {
    return this.deliveryServiceAdditionalFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceAdditionalDto>): DeliveryServiceAdditionalDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceAdditionalUpdate()` */
  static readonly DeliveryServiceAdditionalUpdatePath = '/codebook/delivery-service-additional/{id}';

  /**
   * Updates an existing service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceAdditionalUpdate$Response(params: DeliveryServiceAdditionalUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceAdditionalDto>> {
    return deliveryServiceAdditionalUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates an existing service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceAdditionalUpdate(params: DeliveryServiceAdditionalUpdate$Params, context?: HttpContext): Observable<DeliveryServiceAdditionalDto> {
    return this.deliveryServiceAdditionalUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceAdditionalDto>): DeliveryServiceAdditionalDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceAdditionalFindAll()` */
  static readonly DeliveryServiceAdditionalFindAllPath = '/codebook/delivery-service-additional';

  /**
   * Returns all the services
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindAll$Response(params?: DeliveryServiceAdditionalFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryServiceAdditionalDto>>> {
    return deliveryServiceAdditionalFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all the services
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindAll(params?: DeliveryServiceAdditionalFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DeliveryServiceAdditionalDto>> {
    return this.deliveryServiceAdditionalFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryServiceAdditionalDto>>): Array<DeliveryServiceAdditionalDto> => r.body)
    );
  }

  /** Path part for operation `deliveryServiceAdditionalCreate()` */
  static readonly DeliveryServiceAdditionalCreatePath = '/codebook/delivery-service-additional';

  /**
   * Creates a new service
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceAdditionalCreate$Response(params: DeliveryServiceAdditionalCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DeliveryServiceAdditionalDto>> {
    return deliveryServiceAdditionalCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new service
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  deliveryServiceAdditionalCreate(params: DeliveryServiceAdditionalCreate$Params, context?: HttpContext): Observable<DeliveryServiceAdditionalDto> {
    return this.deliveryServiceAdditionalCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DeliveryServiceAdditionalDto>): DeliveryServiceAdditionalDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceAdditionalFindByDeliveryTypeGroup()` */
  static readonly DeliveryServiceAdditionalFindByDeliveryTypeGroupPath = '/codebook/delivery-service-additional/{deliveryTypeGroup}/findByDeliveryTypeGroup';

  /**
   * Returns all services for given delivery type group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalFindByDeliveryTypeGroup()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindByDeliveryTypeGroup$Response(params: DeliveryServiceAdditionalFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DeliveryServiceAdditionalDto>>> {
    return deliveryServiceAdditionalFindByDeliveryTypeGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all services for given delivery type group
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalFindByDeliveryTypeGroup$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindByDeliveryTypeGroup(params: DeliveryServiceAdditionalFindByDeliveryTypeGroup$Params, context?: HttpContext): Observable<Array<DeliveryServiceAdditionalDto>> {
    return this.deliveryServiceAdditionalFindByDeliveryTypeGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DeliveryServiceAdditionalDto>>): Array<DeliveryServiceAdditionalDto> => r.body)
    );
  }

  /** Path part for operation `deliveryServiceAdditionalFindByDeliveryTypeGroup_1()` */
  static readonly DeliveryServiceAdditionalFindByDeliveryTypeGroup_1Path = '/codebook/delivery-service-additional/{deliveryTypeGroup}/findByDeliveryTypeGroup/search-api';

  /**
   * Returns services for given delivery type group based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalFindByDeliveryTypeGroup_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindByDeliveryTypeGroup_1$Response(params: DeliveryServiceAdditionalFindByDeliveryTypeGroup_1$Params, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceAdditionalDto>> {
    return deliveryServiceAdditionalFindByDeliveryTypeGroup_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns services for given delivery type group based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalFindByDeliveryTypeGroup_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalFindByDeliveryTypeGroup_1(params: DeliveryServiceAdditionalFindByDeliveryTypeGroup_1$Params, context?: HttpContext): Observable<PageDeliveryServiceAdditionalDto> {
    return this.deliveryServiceAdditionalFindByDeliveryTypeGroup_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceAdditionalDto>): PageDeliveryServiceAdditionalDto => r.body)
    );
  }

  /** Path part for operation `deliveryServiceAdditionalSearch()` */
  static readonly DeliveryServiceAdditionalSearchPath = '/codebook/delivery-service-additional/search-api';

  /**
   * Returns services based on a search query
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deliveryServiceAdditionalSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalSearch$Response(params?: DeliveryServiceAdditionalSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDeliveryServiceAdditionalDto>> {
    return deliveryServiceAdditionalSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns services based on a search query
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deliveryServiceAdditionalSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deliveryServiceAdditionalSearch(params?: DeliveryServiceAdditionalSearch$Params|null|undefined, context?: HttpContext): Observable<PageDeliveryServiceAdditionalDto> {
    return this.deliveryServiceAdditionalSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDeliveryServiceAdditionalDto>): PageDeliveryServiceAdditionalDto => r.body)
    );
  }

}
