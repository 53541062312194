import {Directive, inject, Input, TemplateRef} from '@angular/core';
import {TableColumnsData} from './table-columns-data';
import {CombinedColumnDefinition} from './table.models';
import {IczTableDataSource} from './table.datasource';


// If using withEllipsis: true for *iczColumnTemplate, container
// contents must be wrapped inside a <span> element.
@Directive({
  selector: '[iczColumnTemplate]',
})
export class ColumnTemplateDirective<TData, TColId extends string> {

  content = inject(TemplateRef);

  @Input({alias: 'iczColumnTemplateFrom', required: true})
  set from(stuff: [IczTableDataSource<TData>, TableColumnsData<TColId>]) {}

  @Input({alias: 'iczColumnTemplateId', required: true}) id!: TColId;

  @Input('iczColumnTemplateWithEllipsis')
  set withEllipsis(newValue: boolean) {
    this._withEllipsis = newValue;
  }
  get withEllipsis(): boolean {
    return this._withEllipsis;
  }

  private _withEllipsis = false;

  // This makes it possible to know the type of row and column variables - it will be:
  //  - TData from IczTableDataSource<TData>,
  //  - ColumnDefinition<TColId> from TableColumnsData<TColId>.
  static ngTemplateContextGuard<TData, TColId extends string>(
    dir: ColumnTemplateDirective<TData, TColId>,
    ctx: unknown
  ): ctx is {$implicit: TData; column: CombinedColumnDefinition<TColId>} {
    return true;
  }

}
