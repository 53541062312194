import {ChangeDetectionStrategy, Component, DestroyRef, inject} from '@angular/core';
import {FunctionalPositionGroupItem, FunctionalPositionGroupItems} from '../saved-functional-positions.service';
import {
  OrganizationalStructureOption,
  OrganizationalStructureService
} from '../../../core/services/organizational-structure.service';
import {Option} from '../../../model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {injectModalData, injectModalRef} from '../../../lib/modals';

export interface PrimaryFunctionalPositionPickerDialogData {
  showHandCopyToAll: boolean;
  selectedGroup: FunctionalPositionGroupItems;
}

export interface PrimaryFunctionalPositionPickerResult {
  originalReceiver: Nullable<FunctionalPositionGroupItem>;
  copyReceivers: FunctionalPositionGroupItem[];
}


@Component({
  selector: 'icz-primary-functional-position-picker',
  templateUrl: './primary-functional-position-picker.component.html',
  styleUrls: ['./primary-functional-position-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrimaryFunctionalPositionPickerComponent {

  private orgStructureService = inject(OrganizationalStructureService);
  private modalRef = injectModalRef<PrimaryFunctionalPositionPickerResult>();
  private destroyRef = inject(DestroyRef);
  protected dialogData = injectModalData<PrimaryFunctionalPositionPickerDialogData>();

  orgStructureElements: OrganizationalStructureOption[] = [];

  ngOnInit() {
    this.orgStructureService.orgStructureOptions().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(orgStructureOptions => {
      this.orgStructureElements = orgStructureOptions.filter(o => this.dialogData.selectedGroup.includes(o.value!));
    });
  }

  handCopyToAll() {
    this.modalRef.close({
      originalReceiver: null,
      copyReceivers: this.orgStructureElements.map(e => e.value!),
    });
  }

  orgStructureElementSelected(selectedElement: Option) {
    this.modalRef.close({
      originalReceiver: selectedElement.value,
      copyReceivers: this.orgStructureElements.filter(e => e !== selectedElement).map(e => e.value!),
    });
  }

}
