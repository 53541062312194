import {GenericOwnConsignment} from '../model/own-consignment-model';
import {IczTableDataSource} from '../../../table/table.datasource';
import {OwnConsignmentsSearchService} from '../../../../services/own-consignments-search.service';


export class DocumentDetailOwnConsignmentsDatasource extends IczTableDataSource<GenericOwnConsignment> {
  constructor(
    searchService: OwnConsignmentsSearchService,
    documentId: number,
  ) {
    super(searchParams => searchService.findOwnDocumentOwnConsignments(documentId, searchParams));
  }
}
