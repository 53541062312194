import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {LineChartConfig, periodOptions, StatisticConfig, TrendPeriod} from '../statistics-model';
import {ColumnDefinition} from '../../table/table.models';
import {TableColumnsData} from '../../table/table-columns-data';
import {CodebookFilterDefinition, FilterItemValue, FilterType} from '../../table/filter.types';
import {
  DocumentFiltersDataService
} from '../../shared-business-components/document-table/components/document-filters/document-filters-data.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {extendDefaultTableConfig, TableComponent} from '../../table/table.component';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {FilterOperator, FilterParam, SearchParams} from '../../../services/search-api.service';
import {formatAsLocalIsoDate, removeDuplicates} from '../../../lib/utils';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {ApiStatisticsService, StatisticsDimension} from '|api/elastic';
import {InMemorySearchDatasource} from '../../form-elements/form-popup-table-selector/in-memory-search.datasource';
import {DocumentState, FileState} from '|api/commons';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {Option} from '../../../model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  getEntityClassFilterConfig,
  setupEntityClassFilterSubfilters
} from '../../shared-business-components/document-table/components/document-filters/document-filter-selectors';
import {shareReplay} from 'rxjs';
import {TemplatePoolService} from '../../essentials/template-pool/template-pool.service';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {isEqual} from 'lodash';
import {DateRange} from '@angular/material/datepicker';
import {FormFilterItem} from '../../table/table-toolbar/form-filter/form-filter.component';
import {ChartElementColor} from '../chart-utils';
import {DashboardLayoutService} from '../../../services/dashboard-layout.service';

type StatisticsTableColumn =  StatisticsDimension | 'count' | 'previousCount' | 'dateRange' | 'dateRangeToCompare';

@Component({
  selector: 'icz-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrls: ['./statistics-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsTableComponent implements IczOnChanges, OnInit, OnDestroy {

  loadingIndicatorService = inject(LoadingIndicatorService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private apiStatisticsService = inject(ApiStatisticsService);
  private templatePool = inject(TemplatePoolService);
  protected dashboardLayoutService = inject(DashboardLayoutService);


  @Input({required: true}) tableTitle!: string;
  @Input({required: true}) allowedDimensions!: StatisticsDimension[];
  @Input({required: true}) statisticConfig!: StatisticConfig;
  @Input({required: true}) period!: TrendPeriod;

  selectedDimensions: StatisticsDimension[] = [];
  initialDataLoaded = false;
  private showPreviousCountColumn = false;
  private tableComponent!: TableComponent<StatisticsTableColumn>;

  watchedPeriodFromDate!: Date;
  watchedPeriodToDate!: Date;

  firstPeriodFilter = {
    label: this.translateService.instant('Období'),
    filterType: FilterType.DATE,
  } as Partial<FormFilterItem>;

  secondPeriodFilter = {
    label: this.translateService.instant('Porovnat s obdobím'),
    filterType: FilterType.DATE,
  } as Partial<FormFilterItem>;

  tableConfig = extendDefaultTableConfig({
    hoverableRows: false,
    rowHeight: 40,
    toolbarConfig: {
      showFilter: false,
      showTools: false,
      showDimensions: true,
      autoOpenFilter: true,
      showReload: false,
      showColumnSelector: false,
      showSavedFilters: false
    },
    defaultFilterColumns: [StatisticsDimension.ENTITY_CLASS],
  });

  readonly StatisticsDimension = StatisticsDimension;

  lineChartConfig: LineChartConfig = {
    xAxisRange: [new Date().toISOString(), new Date().toISOString()],
    datasets: []
  };
  appliedFilters: FilterParam[] = [];

  trendPeriodOptions: Option[] = periodOptions;
  securityCategories$ = this.documentFiltersDataService.securityCategoryOptions$;
  entityStateOptions: Option[] = removeDuplicates([
    ...enumToOptions('documentState', DocumentState),
    ...enumToOptions('fileState', FileState),
  ], o => o.value);

  selectedDate: Nullable<Date | DateRange<Date>> = null;

  showLineChart = true;

  get showBarChart(): boolean {
    return this.statisticConfig.allowBarChart && this.form.get('showBarChart')!.value!;
  };
  get showDonutChart(): boolean {
    return this.statisticConfig.allowDonutChart && this.form.get('showDonutChart')!.value!;
  };

  form = new IczFormGroup({
    showBarChart: new IczFormControl<Nullable<boolean>>(false),
    showDonutChart: new IczFormControl<Nullable<boolean>>(false),
    period: new IczFormControl<Nullable<TrendPeriod>>(null),
    firstPeriod: new IczFormControl<Nullable<FilterItemValue<any>>>(null),
    secondPeriod: new IczFormControl<Nullable<FilterItemValue<string>>>(null),
  });

  readonly allColumnIds: StatisticsDimension[] = [
    StatisticsDimension.ENTITY_CLASS, StatisticsDimension.SECURITY_CATEGORY, StatisticsDimension.ENTITY_STATE
  ];

  columnsData!: TableColumnsData<StatisticsTableColumn>;

  dataSource = new InMemorySearchDatasource(() => []);

  showTableAndGraph = true;

  afterTableInitialized(tableInstance: TableComponent<StatisticsTableColumn>) {
    this.tableComponent = tableInstance;
  }

  loadPage(searchParams: SearchParams) {
    if (isEqual(this.appliedFilters, searchParams.filter)) {
      this.dataSource?.loadPage({...searchParams, filter: []});
    } else {
      this.appliedFilters = searchParams.filter;
      this.loadTableData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString());
    }
  }

  getNullDimensionValue(columnId: string) {
    switch (columnId) {
      case StatisticsDimension.ENTITY_CLASS:
        return this.translateService.instant('Bez věcné skupiny');
      case StatisticsDimension.SECURITY_CATEGORY:
        return this.translateService.instant('Bez bezpečnostní kategorie');
      // todo(mh) more dimensions will be added in the future
      default:
        return '';
    }
  }

  loadLineChartData(dateMin: string, dateMax: string) {
    this.loadingIndicatorService.doLoading(
      this.apiStatisticsService.statisticsGetDateSeriesCreatedAggregation({
        body: {
          statistic: this.statisticConfig.statisticKey,
          watchedPeriodFromDate: dateMin,
          watchedPeriodToDate: dateMax,
          includePrecedingPeriod: false
        }
      }),
      this, 'statistic-table'
    ).subscribe(lineChartData => {
      if (this.lineChartConfig.datasets.length > 0) {
        this.lineChartConfig.xAxisRange = [dateMin, dateMax];
        this.lineChartConfig.datasets[0].data = lineChartData.watchedPeriodPoints.map(point => Number(point.y));
        this.lineChartConfig.datasets[0].fullDataSet = lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y}));
        this.lineChartConfig = {...this.lineChartConfig};
      } else {
        this.lineChartConfig = {
          xAxisRange: [dateMin, dateMax],
          datasets: [
            {
              label: this.translateService.instant('Období'),
              borderColor: ChartElementColor.BLUE,
              fullDataSet: lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y})),
              fill: true,
              data: lineChartData.watchedPeriodPoints.map(point => Number(point.y))}
          ]
        };
      }
    });
  }

  loadSecondLineChartData(dateMin: string, dateMax: string) {
    this.loadingIndicatorService.doLoading(
      this.apiStatisticsService.statisticsGetDateSeriesCreatedAggregation({
        body: {
          statistic: this.statisticConfig.statisticKey,
          watchedPeriodFromDate: dateMin,
          watchedPeriodToDate: dateMax,
          includePrecedingPeriod: false
        }
      }),
      this, 'statistic-table'
    ).subscribe(lineChartData => {
      if (this.lineChartConfig.datasets[1]) {
        this.lineChartConfig.datasets[1].data = lineChartData.watchedPeriodPoints.map(point => Number(point.y));
        this.lineChartConfig.datasets[1].fullDataSet = lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y}));
      } else {
        this.lineChartConfig.datasets.push({
          label: this.translateService.instant('Porovnat s obdobím'),
          borderColor: ChartElementColor.GREY,
          fullDataSet: lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y})),
          fill: false,
          data: lineChartData.watchedPeriodPoints.map(point => Number(point.y))
        });
      }
      if (this.lineChartConfig.datasets[0].data.length < this.lineChartConfig.datasets[1].data.length) {
        const offset = this.lineChartConfig.datasets[1].data.length - this.lineChartConfig.datasets[0].data.length;
        const adjustedDateMax = new Date();
        adjustedDateMax.setDate(this.watchedPeriodToDate.getDate() + offset);
        this.lineChartConfig.xAxisRange = [this.watchedPeriodFromDate.toISOString(), adjustedDateMax.toISOString()];
      }

      this.lineChartConfig = {...this.lineChartConfig};
    });
  }

  loadTableData(dateMin: string, dateMax: string) {
    const entityClassIdsForFilter: number[] = [];
    if (this.appliedFilters) {
      this.appliedFilters.forEach(filter => {
        if (filter.fieldName === StatisticsDimension.ENTITY_CLASS) {
          entityClassIdsForFilter.push(Number(filter.value));
        }
        // todo(mh) more filters will be added in the future
      });
    }

    this.loadingIndicatorService.doLoading(
      this.apiStatisticsService.statisticsGetCreatedForDimensionsAggregation({
        size: 1000,
        entityClassIds: entityClassIdsForFilter,
        body: {
          dimensions: this.selectedDimensions,
          statistic: this.statisticConfig.statisticKey,
          watchedPeriodFromDate: dateMin,
          watchedPeriodToDate: dateMax,
        }
      }),
      this, 'lineChart'
    ).subscribe(statisticsTableData => {
      this.dataSource.setDataFactory(() => statisticsTableData.rows);
    });
  }

  exportToCSV() {
    // connect to export to CSV
  }

  private columnDefForFilterlessDimension(id: StatisticsDimension): ColumnDefinition {
    return {
      id: id as any,
      label: this.translateService.instant(`en.dimension.${id}`),
      filterType: FilterType.NONE,
      disableSort: true,
      allowTranslation: true,
      displayed: this.selectedDimensions.includes(id)
    };
  };

  private setColumnsDataAndDatasource() {
    const entityClassOptionsTree$ = this.documentFiltersDataService.entityClassOptionsTree$.pipe(shareReplay(1));
    const classificationSchemeOptions$ = this.documentFiltersDataService.classificationSchemeOptions$.pipe(shareReplay(1));
    const entityClassColumnDef = {
      id: StatisticsDimension.ENTITY_CLASS,
      label: this.translateService.instant(`en.dimension.${StatisticsDimension.ENTITY_CLASS}`),
      filterType: FilterType.CODEBOOK,
      list$: entityClassOptionsTree$,
      filterConfig: getEntityClassFilterConfig(this.templatePool),
      disableSort: true,
      displayed: this.selectedDimensions.includes(StatisticsDimension.ENTITY_CLASS)
    };
    setupEntityClassFilterSubfilters(
      (entityClassColumnDef as unknown as CodebookFilterDefinition).filterConfig!,
      classificationSchemeOptions$,
    );

    this.columnsData = new TableColumnsData<StatisticsTableColumn>([
      entityClassColumnDef,
      this.columnDefForFilterlessDimension(StatisticsDimension.SECURITY_CATEGORY),
      this.columnDefForFilterlessDimension(StatisticsDimension.ENTITY_STATE),
      {id: 'count', label: 'Počet objektů', filterType: FilterType.NONE, disableSort: true},
      // todo(mh) will be added in the future
      /*{
        id: StatisticsDimension.SECURITY_CATEGORY,
        label: this.translateService.instant(`en.dimension.${StatisticsDimension.SECURITY_CATEGORY}`),
        filterType: FilterType.CODEBOOK,
        list$: this.codebookService.securityCategories().pipe(namedDtosToOptionsWithCode),
        disableSort: true,
        displayed: this.selectedDimensions.includes(StatisticsDimension.SECURITY_CATEGORY)
      },
      {
        id: StatisticsDimension.ENTITY_STATE,
        label: this.translateService.instant(`en.dimension.${StatisticsDimension.ENTITY_STATE}`),
        list: this.documentFiltersDataService.entityStateOptions,
        filterType: FilterType.ENUM,
        disableSort: true,
        displayed: this.selectedDimensions.includes(StatisticsDimension.SECURITY_CATEGORY)
      },
      this.columnDefForFilterlessDimension(StatisticsDimension.DOCUMENT_STATE),
      this.columnDefForFilterlessDimension(StatisticsDimension.FILE_STATE),
      this.columnDefForFilterlessDimension(StatisticsDimension.TOTAL_COMPONENT_FILESIZE),
      this.columnDefForFilterlessDimension(StatisticsDimension.FILESIZE),
      this.columnDefForFilterlessDimension(StatisticsDimension.COMPONENT_PUID),
      {id: 'previousCount', label: 'Počet objektů v předešlém období', filterType: FilterType.NONE, disableSort: true, displayed: this.showPreviousCountColumn},
      {id: 'dateRange', label: 'Od - do', filterType: FilterType.DATE_STATISTICS, disableSort: true, displayed: false,},
      {id: 'dateRangeToCompare', label: 'Porovnat s od - do', filterType: FilterType.DATE_STATISTICS, disableSort: true, displayed: false,},*/
    ]);

    this.changeDetectorRef.detectChanges();
  }

  dimensionsChanged(dimensions: StatisticsDimension[], resetDatasource: boolean) {
    this.selectedDimensions = dimensions;

    const dimensionCols = this.columnsData!.columnDefinitions.filter(c => !['count', 'previousCount','dateRange', 'dateRangeToCompare'].includes(c.id));
    dimensionCols.forEach(c => {
      c.displayed = dimensions.includes(c.id as StatisticsDimension);
    });
    this.tableComponent.setDisplayedColumns();

    this.loadTableData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString());
  }

  initialDataLoad() {
    if (this.statisticConfig && this.period && !this.initialDataLoaded) {
      this.selectedDate = new Date();
      this.initialDataLoaded = true;
      this.selectedDimensions = [this.statisticConfig.dimensions[0]];

      this.watchedPeriodFromDate = new Date();
      this.watchedPeriodToDate = new Date();
      this.watchedPeriodToDate.setDate(this.watchedPeriodToDate.getDate() - 1);
      const fromDate = new Date();
      if (this.period === TrendPeriod.LAST_7_DAYS) {
        this.watchedPeriodFromDate.setDate(fromDate.getDate() - 7);
      } else {
        this.watchedPeriodFromDate.setDate(fromDate.getDate() - 30);
      }

      this.form.get('firstPeriod')!.setValue(FilterItemValue.fromFilterItem({
        id: 'firstPeriod',
        value: 'interval',
        subValues: [
          {
            operator: FilterOperator.greaterOrEqual,
            value: formatAsLocalIsoDate(this.watchedPeriodFromDate),
            and: true
          },
          {
            operator: FilterOperator.less,
            value: formatAsLocalIsoDate(new Date()),
            and: true
          }
        ]
      } as FormFilterItem));

      this.setColumnsDataAndDatasource();
      this.loadChartAndTable();
    }
  }

  loadChartAndTable() {
    this.loadLineChartData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString());
    this.loadTableData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString());
  }

  ngOnInit() {
    this.form.get('firstPeriod')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(period => {
      if (period && period.value) {
        if (period.value === 'interval') {
          this.watchedPeriodFromDate = new Date(String(period.subValues![0].value));
          const intervalToDate = new Date(String(period.subValues![1].value));
          intervalToDate.setDate(intervalToDate.getDate() - 1);
          this.watchedPeriodToDate = intervalToDate;
        } else {
          this.watchedPeriodFromDate = new Date(String(period.value));
          this.watchedPeriodToDate = new Date(String(period.value));
        }
        this.loadChartAndTable();
        this.showTableAndGraph = true;
      } else {
        this.showTableAndGraph = false;
      }
    });

    this.form.get('secondPeriod')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(period => {
      let secondWatchedPeriodFromDate;
      let secondWatchedPeriodToDate;
      if (period && period.value) {
        if (period.value === 'interval') {
          secondWatchedPeriodFromDate = new Date(String(period.subValues![0].value)).toISOString();
          const intervalToDate = new Date(String(period.subValues![1].value));
          intervalToDate.setDate(intervalToDate.getDate() - 1);
          secondWatchedPeriodToDate = intervalToDate.toISOString();
        } else {
          secondWatchedPeriodFromDate = new Date(String(period.value)).toISOString();
          secondWatchedPeriodToDate = new Date(String(period.value)).toISOString();
        }
        this.loadSecondLineChartData(secondWatchedPeriodFromDate, secondWatchedPeriodToDate);
      } else {
        if (this.lineChartConfig.datasets.length === 2) {
          this.lineChartConfig.datasets.splice(1, 1);
        }
        this.lineChartConfig = {...this.lineChartConfig};
      }
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    this.initialDataLoad();
  }

  ngOnDestroy() {
    setTimeout(() => {
      this.dashboardLayoutService.showDashboardHeader$.next(true);
    });
  }
}
