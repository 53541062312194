<div [waiting]="loadingService.isLoading(this)">
  <icz-shared-folder-selector
    label="Cílová složka"
    [form]="form"
    controlName="id"
    [disabledFolderIds]="dialogData.disabledFolderIds"
    [view]="SharedFolderSelectorView.FOLDERS_SUITABLE_FOR_INSERTION"
  ></icz-shared-folder-selector>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()" primary [label]="dialogData.submitButtonText"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
