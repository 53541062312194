/* tslint:disable */
/* eslint-disable */

/**
 * Email notification frequency.
 */
export enum EmailNotificationFrequency {
  IMMEDIATELY = 'IMMEDIATELY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY'
}
