/* tslint:disable */
/* eslint-disable */

/**
 * State of subject attribute
 */
export enum SubjectAttributeState {
  CORRECT = 'CORRECT',
  INCORRECT = 'INCORRECT',
  UNDEFINED = 'UNDEFINED',
  BAD_FORMAT = 'BAD_FORMAT',
  JOINED = 'JOINED'
}
