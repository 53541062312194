/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ComponentAuthorizationRequest } from '../../models/component-authorization-request';
import { ComponentAuthorizationResult } from '../../models/component-authorization-result';
import { DocumentAuthorizationRequest } from '../../models/document-authorization-request';
import { DocumentAuthorizationResult } from '../../models/document-authorization-result';
import { FileAuthorizationRequest } from '../../models/file-authorization-request';
import { FileAuthorizationResult } from '../../models/file-authorization-result';
import { OwnConsignmentAuthorizationRequest } from '../../models/own-consignment-authorization-request';
import { OwnConsignmentAuthorizationResult } from '../../models/own-consignment-authorization-result';
import { ReceivedConsignmentAuthorizationRequest } from '../../models/received-consignment-authorization-request';
import { ReceivedConsignmentAuthorizationResult } from '../../models/received-consignment-authorization-result';
import { StorageUnitAuthorizationRequest } from '../../models/storage-unit-authorization-request';
import { StorageUnitAuthorizationResult } from '../../models/storage-unit-authorization-result';

export interface AuthorizationAuthorizeOperations$Params {
      body: Array<(DocumentAuthorizationRequest | FileAuthorizationRequest | OwnConsignmentAuthorizationRequest | ReceivedConsignmentAuthorizationRequest | ComponentAuthorizationRequest | StorageUnitAuthorizationRequest)>
}

export function authorizationAuthorizeOperations(http: HttpClient, rootUrl: string, params: AuthorizationAuthorizeOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(DocumentAuthorizationResult | FileAuthorizationResult | OwnConsignmentAuthorizationResult | ReceivedConsignmentAuthorizationResult | ComponentAuthorizationResult | StorageUnitAuthorizationResult)>>> {
  const rb = new RequestBuilder(rootUrl, authorizationAuthorizeOperations.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(DocumentAuthorizationResult | FileAuthorizationResult | OwnConsignmentAuthorizationResult | ReceivedConsignmentAuthorizationResult | ComponentAuthorizationResult | StorageUnitAuthorizationResult)>>;
    })
  );
}

authorizationAuthorizeOperations.PATH = '/authorization/check';