<div class="row">
  <div class="col">
    @if (isIszrVerified) {
      @if (showIdentifiedAsTag) {
        <icz-tag svgIcon="verified_subject" color="green" label="Ztotožněný">
        </icz-tag>
      } @else {
        <icz-icon
          svgIcon="verified_subject"
          [size]="size"
          [iczTooltip]="iszrVerifiedTooltip"
          [iczTooltipShowDelay]="500"
        ></icz-icon>
      }
    }
    @else if (isIsdsVerified) {
      <icz-icon
        svgIcon="overeny_subjekt"
        [size]="size"
        [iczTooltip]="isdsVerifiedTooltip"
        [iczTooltipShowDelay]="500"
      ></icz-icon>
    } @else if (isIdentifiable) {
      <icz-icon
        svgIcon="complete_subject"
        [size]="size"
        iczTooltip="Subjekt má uveden dostatek údajů, aby ho bylo možné jednoznačně identifikovat. Nebyl ale ověřen nebo ztotožněn."
        [iczTooltipShowDelay]="500"
      ></icz-icon>
    } @else {
      <icz-icon
        svgIcon="incomplete_subject"
        [size]="size"
        iczTooltip="Není uvedeno dostatek údajů pro jednoznačnou identifikaci subjektu."
        [iczTooltipShowDelay]="500"
      ></icz-icon>
    }
  </div>

  @if (showExtraVerificationInfo) {
    <div class="col justify-center">
      @if (hasIsdsVerifiedDate) {
        <span>{{ 'Ověřeno v ISDS' | translate }}: {{ subject?.isdsVerified! | localizedDatetime }}</span>
      }
      <div>
        <span>{{ 'Poslední úprava' | translate }}:
          @if (subject?.auditInfo) {
            <span>{{ subject?.auditInfo!.modifiedAt | localizedDatetime }}</span>
          }
          @if (!subject?.auditInfo) {
            <span>{{ 'Neznámé' | translate }}</span>
          }
        </span>
      </div>
    </div>
  }
</div>
