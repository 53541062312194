/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { KeywordDto } from '../../models/keyword-dto';
import { KeywordUpdateDto } from '../../models/keyword-update-dto';

export interface KeywordUpdate$Params {

/**
 * The keyword id
 */
  id: number;
      body: KeywordUpdateDto
}

export function keywordUpdate(http: HttpClient, rootUrl: string, params: KeywordUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<KeywordDto>> {
  const rb = new RequestBuilder(rootUrl, keywordUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<KeywordDto>;
    })
  );
}

keywordUpdate.PATH = '/codebook/classifier/keyword/{id}';