/* tslint:disable */
/* eslint-disable */

/**
 * State of container extraction
 */
export enum ContainerExtractionState {
  NOT_EXTRACTED = 'NOT_EXTRACTED',
  EXTRACTED = 'EXTRACTED',
  EXTRACTED_WITHOUT_CONTENT = 'EXTRACTED_WITHOUT_CONTENT',
  EXTRACTION_FAILED = 'EXTRACTION_FAILED'
}
