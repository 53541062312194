import {Component, DestroyRef, inject} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {CirculationActivityType, EntityType} from '|api/commons';
import {
  ApiCirculationActivityBulkActionsService,
  ApiCirculationActivityService,
  ApiCirculationTaskAcknowledgementService,
  ApiCirculationTaskApprovalService,
  ApiCirculationTaskComponentSigningService,
  ApiCirculationTaskHandoverService,
  ApiCirculationTaskHandoverToOwnHandsService,
  ApiCirculationTaskService,
  ApiCirculationTaskStatementService,
  CirculationActivityDto,
  CirculationActivityRemoveTargetParticipantDto,
  CirculationActivityRevokeDto,
  CirculationTaskDto,
  CirculationTaskRevokeDto,
} from '|api/flow';
import {AssignedTaskInfo, getTasksInfo, TaskInfo, TaskInfoType} from '../../../task-info-bit/task-info.model';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {DialogService} from '../../../../../core/services/dialog.service';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {ExtendedCirculationActivityDto} from '../../../model/elastic-extended-entities.interface';
import {handleCirculationErrorAsToast} from '../../../../../utils/document-file-circulation.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DialogSeverity} from '../../../../../core/services/dialog.models';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';
import {ToastService} from '../../../../notifications/toast.service';
import {TranslateService} from '@ngx-translate/core';

enum TaskRemovalMode {
  ALL = 'ALL',
  SOME = 'SOME',
}


@Component({
  selector: 'icz-revoke-task-dialog',
  templateUrl: './revoke-task-dialog.component.html',
  styleUrls: ['./revoke-task-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class RevokeTaskDialogComponent {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<string | boolean>>();
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);
  private apiCirculationTaskService = inject(ApiCirculationTaskService);
  private apiCirculationTaskStatementService = inject(ApiCirculationTaskStatementService);
  private apiCirculationTaskComponentSigningService = inject(ApiCirculationTaskComponentSigningService);
  private apiCirculationTaskHandoverService = inject(ApiCirculationTaskHandoverService);
  private apiCirculationTaskHandoverToOwnHandsService = inject(ApiCirculationTaskHandoverToOwnHandsService);
  private apiCirculationTaskApprovalService = inject(ApiCirculationTaskApprovalService);
  private apiCirculationTaskAcknowledgementService = inject(ApiCirculationTaskAcknowledgementService);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected circulationEntities = injectModalData<Array<ExtendedCirculationActivityDto>>();

  formGroupsToCheck!: string[];

  readonly TaskRemovalMode = TaskRemovalMode;

  form = new IczFormGroup({
    taskRemovalMode: new IczFormControl<Nullable<TaskRemovalMode>>(TaskRemovalMode.ALL),
    reason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  activity!: CirculationActivityDto;
  tasksInfo: TaskInfo[] = [];

  tasksToRemove: TaskInfo[] = [];

  managementTask!: CirculationTaskDto;

  get isFormValid() {
    if (this.taskRemovalMode === TaskRemovalMode.ALL) {
      return this.form.valid;
    }
    else if (this.taskRemovalMode === TaskRemovalMode.SOME) {
      return this.form.valid && this.tasksToRemove.length > 0;
    }
    else {
      return false;
    }
  }

  get taskRemovalMode(): TaskRemovalMode {
    return this.form.get('taskRemovalMode')!.value!;
  }

  readonly EntityType = EntityType;

  isBulkRevoke = false;
  bulkRevokableActivies: ExtendedCirculationActivityDto[] = [];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.isBulkRevoke = this.circulationEntities.length > 1;

    if (this.isBulkRevoke) {
      this.circulationEntities.forEach(entity => {
        this.bulkRevokableActivies.push(entity);
      });

    } else {
      this.activity = this.circulationEntities[0];

      this.loadingService.doLoading(
        combineLatest([
          this.apiCirculationTaskService.circulationTaskFindByActivityId({activityId: this.activity.id}),
          this.apiCirculationActivityService.circulationActivityGetTargetParticipants({id: this.activity.id}),
        ]),
        this
      ).pipe(
        switchMap(([tasks, activityParticipants]) => this.apiCirculationTaskService.circulationTaskFilterByRevocableTasks({
            body: tasks.map(t => t.id),
          }).pipe(
            map(revokableTaskIds => ({tasks, activityParticipants, revokableTaskIds}))
          )
        ),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(({tasks, activityParticipants, revokableTaskIds}) => {
        this.managementTask = tasks.find(t => t.isManagementTask)!;

        this.tasksInfo = getTasksInfo(this.activity, tasks, activityParticipants).filter(
          taskInfo => (
            taskInfo.taskInfoType === TaskInfoType.UNASSIGNED || (
              taskInfo.taskInfoType === TaskInfoType.ASSIGNED &&
              revokableTaskIds.includes((taskInfo as AssignedTaskInfo).taskId)
            )
          )
        );

        if (!this.tasksInfo.length) {
          this.showUnrevokableTaskMessage();
        }
      });
    }

    this.form.get('taskRemovalMode')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => this.tasksToRemove = []);
  }

  toggleTaskRemoval(task: TaskInfo, checkboxValue: boolean) {
    if (checkboxValue) {
      this.tasksToRemove.push(task);
    }
    else {
      this.tasksToRemove.splice(
        this.tasksToRemove.indexOf(task),
        1
      );
    }
  }

  revokeTask() {
    const reason = this.form.get('reason')!.value!;

    if (this.isBulkRevoke) {
      const bulkActivityRevokeDtos: CirculationActivityRevokeDto[] = this.bulkRevokableActivies.map(activity => ({
        activityId: activity.id,
        reason,
        assignedOnly: this.isRevokeAssignedOnlyMode(activity),
      }));

      this.apiCirculationActivityBulkActionsService.circulationActivityBulkActionsRevoke({body: bulkActivityRevokeDtos}).subscribe({
        next: _ => this.modalRef.close(true),
        error: _ => this.modalRef.close(false),
      });
    } else {
      if (this.taskRemovalMode === TaskRemovalMode.ALL && this.managementTask) {
        const managementTaskId = this.managementTask.id;

        const taskRevokeDto: CirculationTaskRevokeDto = {
          reason,
          assignedOnly: this.isRevokeAssignedOnlyMode(this.activity),
        };

        let request$: Observable<unknown>;

        if (
          this.activity.activityType === CirculationActivityType.DOCUMENT_APPROVAL ||
          this.activity.activityType === CirculationActivityType.COMPONENT_APPROVAL
        ) {
          request$ = this.apiCirculationTaskApprovalService.circulationTaskApprovalRevoke(
            {
              id: managementTaskId,
              body: taskRevokeDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else if (this.activity.activityType === CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT) {
          request$ = this.apiCirculationTaskAcknowledgementService.circulationTaskAcknowledgementRevoke(
            {
              id: managementTaskId,
              body: taskRevokeDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else if (
          this.activity.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
          this.activity.activityType === CirculationActivityType.FILE_HANDOVER
        ) {
          request$ = this.apiCirculationTaskHandoverService.circulationTaskHandoverRevoke(
            {
              id: managementTaskId,
              body: taskRevokeDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else if (
          this.activity.activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
          request$ = this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsRevoke(
            {
              id: managementTaskId,
              body: taskRevokeDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else if (this.activity.activityType === CirculationActivityType.DOCUMENT_STATEMENT) {
          request$ = this.apiCirculationTaskStatementService.circulationTaskStatementRevoke(
            {
              id: managementTaskId,
              body: taskRevokeDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else if (this.activity.activityType === CirculationActivityType.COMPONENT_SIGNING) {
          request$ = this.apiCirculationTaskComponentSigningService.circulationTaskComponentSigningRevoke(
            {
              id: managementTaskId,
              body: taskRevokeDto,
            },
            SKIP_ERROR_DIALOG
          );
        }
        else {
          throw new Error(`Management task revokal not implemented for activityType ${this.activity.activityType}.`);
        }

        if (request$) {
          this.loadingService.doLoading(request$, this).subscribe({
            next: _ => this.modalRef.close(reason),
            error: errorResponse => handleCirculationErrorAsToast(
              this.circulationEntities[0],
              errorResponse,
              this.toastService,
              this.translateService,
              this.modalRef,
              this.activity.activityType
            ),
          });
        }
      }
      else if (this.taskRemovalMode === TaskRemovalMode.SOME) {
        const participantRemovalDto: CirculationActivityRemoveTargetParticipantDto = {
          reason,
          functionalPositionIds: this.tasksToRemove.map(t => t.ownerFunctionalPositionId),
        };

        this.loadingService.doLoading(
          this.apiCirculationActivityService.circulationActivityRemoveTargetParticipants({
            id: this.activity.id,
            body: participantRemovalDto,
          }),
          this
        ).subscribe(_ => this.modalRef.close(reason));
      }
    }
  }

  closeModal() {
    this.modalRef.close(null);
  }

  private isRevokeAssignedOnlyMode(activity: CirculationActivityDto) {
    return !(
      activity.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
      activity.activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS
    );
  }

  private showUnrevokableTaskMessage() {
    this.dialogService.openSimpleDialog({
      severity: DialogSeverity.ERROR,
      title: this.isBulkRevoke ? 'Úkoly nelze odvolat' : 'Úkol nelze odvolat',
      content: [
        {
          text: this.isBulkRevoke ? 'Na všech úkolech v rámci oběhů již někdo pracuje.' : 'Na všech úkolech v rámci vybraných oběhů již někdo pracuje.',
        }
      ]
    }).subscribe(result => {
      if (result) {
        this.modalRef.close(null);
      }
    });
  }

}
