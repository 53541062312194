import {SelectionModel} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {TranslateService} from '@ngx-translate/core';
import {IAutocompleteListItemContext} from '../form-autocomplete/form-autocomplete.model';
import {getArrayDiff, ListItem, Option} from '../../../model';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {WINDOW} from '../../essentials/responsivity.service';

export type ChildrenPath = { [optionId: string]: string[] };

/**
 * Node item
 */
export interface TreeItemNode extends ListItem {
  id: Nullable<string | number>;
  parent?: TreeItemNode;
  children?: TreeItemNode[];
}

/** Flat item node with expandable and level information */
export interface TreeItemFlatNode extends ListItem {
  value: Nullable<string | number>;
  level: number;
  expandable: boolean;
  parent: boolean;
}

export interface TreeViewSelection {
  childrenPath: ChildrenPath;
  selectedNodes: TreeItemFlatNode[];
}

export enum TreeItemSelectionStrategy {
  SIMPLE = 'SIMPLE',
  BULK = 'BULK',
  HYBRID = 'HYBRID',
}

export function buildTreeFromOptions(flatOptionsList: Option[], translateService: TranslateService): TreeItemNode[] {
  const lookupMap = new Map<Nullable<string | number>, TreeItemNode>();

  for (const option of flatOptionsList) {
    lookupMap.set(option.value, optionToTreeNode(option, translateService));
  }

  const out: TreeItemNode[] = [];

  for (const option of flatOptionsList) {
    if (!isNil(option.parent)) {
      const parentOption = lookupMap.get(option.parent);

      if (parentOption) {
        if (!parentOption.children) {
          parentOption.children = [];
        }

        parentOption.children!.push(lookupMap.get(option.value)!);
      }
      else {
        out.push(lookupMap.get(option.value)!);
      }
    }
    else {
      out.push(lookupMap.get(option.value)!);
    }
  }

  return out;
}

function optionToTreeNode(option: Option, translateService: TranslateService): TreeItemNode {
  return {
    ...option,
    parent: undefined,
    id: option.value,
    children: undefined,
    label: translateService.instant(option.label),
    disabled: option.disabled,
  };
}

// all in px
const treeViewContainerHeights = {
  minBuffer: 125,
  fallback: 300,
  max: 500,
};


@Component({
  selector: 'icz-tree-view',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class TreeViewComponent implements IczOnChanges, AfterViewInit {

  private translate = inject(TranslateService);
  private window = inject(WINDOW);

  readonly TreeItemSelectionStrategy = TreeItemSelectionStrategy;
  @ViewChild('virtualScrollViewport', {read: ElementRef, static: false})
  virtualScrollViewport!: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.setContainerHeight();
  }

  @Input({required: true})
  isMultiChoice = false;
  @Input()
  containerTreeHeight: Nullable<number> = 500;
  @Input({required: true})
  listItemTemplate!: TemplateRef<IAutocompleteListItemContext>;
  @Input({required: true})
  selectionStrategy = TreeItemSelectionStrategy.SIMPLE;
  /** Used when opening tree with already checked nodes */
  @Input({required: true})
  checkedItems: Option[] = [];

  containerHeight = treeViewContainerHeights.minBuffer; // in px

  @Input({required: true})
  set data(data: Option[]) {
    if (data?.length) {
      data = data.sort((a, b) => {
        if (a.parent === b.parent) return 0;
        if (!a.parent || !b.parent) return !a.parent ? -1 : 1;
        return a.parent > b.parent ? 1 : -1;
      });
      this.originalData = data;

      const treeNodes = buildTreeFromOptions(data, this.translate);
      this.originalTree = [...treeNodes];

      this.dataSource.data = [...treeNodes];
      this.originalData.forEach(od => {
        this.constructChildrenPaths(od);
      });

      this.resetViewHelperNodeMaps();
      this.selectedNodes = [];
      this.checklistSelection.clear();

      this.dataSource.data = [...treeNodes];
      this.autoExpandNodes();
      this.applyCheckedItems();
      this.reselect();
    }
  }

  /**
   * Used to rebuild tree by searched term.
   * And because of rebuilding the tree and its dataSource several variables has to be reset
   */
  @Input({required: true})
  set searchTerm(term: string) {
    this._searchTerm = term;
    if (!this.originalData) return;

    this.resetViewHelperNodeMaps();

    if (!term) {
      this.dataSource.data = this.originalTree;
    }
    else {
      const helperLeaveMeDic: { [optionId: string]: boolean } = {};

      const filteredData = this.originalData.filter(
        od => !od.isGroup
      ).filter(od => {
        if (helperLeaveMeDic[od.value!]) {
          return true;
        }

        if (!od.label.toLowerCase().includes(term.toLowerCase())) {
          return false;
        }
        else {
          if (this.hasChildHelperDic[od.value!]) {
            this.markChildrenToNotDelete(this.hasChildHelperDic[od.value!], helperLeaveMeDic);
          }
          return true;
        }
      });

      this.dataSource.data = buildTreeFromOptions(filteredData, this.translate);
    }

    this.reselect();
  }

  @Output()
  selectionChanged = new EventEmitter<TreeViewSelection>();

  _searchTerm = '';

  /** Map from nested node to flattened node. This helps us to keep the same object for selection */
  nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();

  /** Helper array containing */
  selectedNodes: TreeItemFlatNode[] = [];

  /** Dictionary used to determine whether a node has children */
  hasChildHelperDic: { [optionId: string]: TreeItemNode[] } = {};

  /** The selection for checklist */
  checklistSelection = new SelectionModel<TreeItemFlatNode>(
    true,
    undefined,
    undefined,
    (o1, o2) => o1.value === o2.value,
  );

  /** Original data used when search is made */
  originalData!: Option[];

  originalTree!: TreeItemNode[];
  /** Dictionary of children path, used to show breadcrumbs above nodes */
  childrenPath: ChildrenPath = {};

  /** Map from flat node to nested node. This helps us finding the nested node to be modified */
  flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();

  getChildren = (node: TreeItemNode): TreeItemNode[] => node.children!;

  isExpandable = (node: TreeItemFlatNode) => node.expandable;
  hasChild = (_nodeData: TreeItemFlatNode) => _nodeData.expandable;
  getLevel = (node: TreeItemFlatNode) => node.level;

  /**
   * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
   */
  private transformer = (node: TreeItemNode, level: number) => {
    const existingNode = this.nestedNodeMap.get(node);
    let flatNode: TreeItemFlatNode;

    if (existingNode && existingNode.label === node.label) {
      flatNode = existingNode;
    }
    else {
      // here we need to remove cyclic object refs manually
      // due to possible debugging serialization issues
      const originalNode: TreeItemNode = {...node};
      delete originalNode.children;
      delete originalNode.parent;

      flatNode = {
        ...originalNode,
        label: node.label,
        value: node.id,
        level,
        expandable: !!node.children,
        parent: Boolean(node.parent),
        disabled: node.disabled,
      };
    }

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  };

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    this.getLevel,
    this.isExpandable,
    this.getChildren
  );
  treeControl = new FlatTreeControl<TreeItemFlatNode>(this.getLevel, this.isExpandable);
  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.checkedItems) {
      const oldSelection = (changes.checkedItems.previousValue ?? []).map(o => o.value).sort();
      const newSelection = (this.checkedItems ?? []).map(o => o.value).sort();

      let changesOccurred = false;

      if (oldSelection.length !== newSelection.length) {
        changesOccurred = true;
      }
      else {
        for (let i = 0; i < oldSelection.length; ++i) {
          if (oldSelection[i] !== newSelection[i]) {
            changesOccurred = true;
            break;
          }
        }
      }

      if (changesOccurred) {
        const diff = getArrayDiff(oldSelection, newSelection);
        this.applyCheckedItems(diff.removals);
      }
    }

    if (changes.containerTreeHeight && changes.containerTreeHeight.currentValue) {
      this.setContainerHeight();
    }
  }

  setContainerHeight() {
    setTimeout(() => {
      const maxPerScreen = Math.min((this.containerTreeHeight ? (this.containerTreeHeight - 180) : (0.5 * this.window.innerHeight - 52)), treeViewContainerHeights.max);

      const thisEl = this.virtualScrollViewport?.nativeElement;
      if (!thisEl) {
        this.containerHeight = treeViewContainerHeights.fallback;
        return;
      }
      const parentFound = thisEl.parentElement.parentElement?.classList?.value.includes('tree-view-wrapper');
      const containerToSet = parentFound ? thisEl.parentElement.parentElement : null;
      if (!containerToSet) {
        this.containerHeight = treeViewContainerHeights.fallback;
        return;
      }
      let sumHeight = 0;

      const visibleRows = this.virtualScrollViewport.nativeElement.querySelectorAll('div.node-main-row');
      visibleRows.forEach((row: HTMLElement) => {
        sumHeight += row.offsetHeight;
      });
      if (!sumHeight) {
        this.containerHeight = treeViewContainerHeights.fallback;
      }
      if (sumHeight < treeViewContainerHeights.minBuffer) {
        this.containerHeight = treeViewContainerHeights.minBuffer;
      }
      else if (sumHeight < maxPerScreen) {
        this.containerHeight = sumHeight;
      }
      else {
        this.containerHeight = maxPerScreen;
      }
      containerToSet.style.height = `${this.containerHeight}px`;
    }, 0);
  }

  ngAfterViewInit() {
    this.setContainerHeight();
  }

  /** Whether all the descendants of the node are selected. */
  isInnerNodeSelected(node: TreeItemFlatNode): boolean {
    if (
      this.selectionStrategy === TreeItemSelectionStrategy.SIMPLE ||
      this.selectionStrategy === TreeItemSelectionStrategy.HYBRID
    ) {
      return this.checklistSelection.isSelected(node);
    }
    else {
      const descendants = this.treeControl.getDescendants(node);
      return descendants.every(child =>
        this.checklistSelection.isSelected(child)
      );
    }
  }

  /** Whether part of the descendants are selected */
  isInnerNodeIndeterminate(node: TreeItemFlatNode): boolean {
    if (
      this.selectionStrategy === TreeItemSelectionStrategy.SIMPLE ||
      this.selectionStrategy === TreeItemSelectionStrategy.HYBRID
    ) {
      return false;
    }

    const descendants = this.treeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child));
    return result && !this.isInnerNodeSelected(node);
  }

  formatChildPath(childPath: string[]) {
    let out = '';

    for (let i = 0; i < childPath.length; ++i) {
      if (i > 0) {
        out += ' &rarr; ';
      }
      out += childPath[i];
    }

    return out;
  }

  /** Toggle item selection. Select/deselect all the descendants node */
  toggleItemSelection(node: TreeItemFlatNode) {
    this.checklistSelection.toggle(node);

    if (this.selectionStrategy === TreeItemSelectionStrategy.SIMPLE) {
      this.toggleItemSelectionSimple();
    }
    else if (this.selectionStrategy === TreeItemSelectionStrategy.BULK) {
      this.toggleItemSelectionBulk(node);
    }
    else if (this.selectionStrategy === TreeItemSelectionStrategy.HYBRID) {
      this.toggleItemSelectionHybrid();
    }
  }

  selectSubtreeByNode(event: MouseEvent, node: TreeItemFlatNode) {
    event.stopImmediatePropagation();

    if (!this.isInnerNodeSelected(node) && !node.disabled) {
      this.checklistSelection.toggle(node);
    }

    this.selectDescendantsByNode(node);

    this.writeSelectedNodes();
    this.emitChanges();
  }

  /** Toggle a leaf item selection. Check all the parents to see if they changed */
  toggleLeafItemSelection(node: TreeItemFlatNode): void {
    this.checklistSelection.toggle(node);
    if (this.selectionStrategy === TreeItemSelectionStrategy.BULK) this.checkAllParentsSelection(node);
    this.cleverRemoval();
  }

  nodeClicked(node: TreeItemFlatNode) {
    if (node.isGroup) {
      this.toggleExpansionState(node);
    }
    else {
      this.chooseSingleValue(node);
    }
  }

  chooseSingleValue(node: TreeItemFlatNode) {
    if (node.disabled) {
      return;
    }

    this.selectedNodes = [node];
    this.emitChanges();
  }

  toggleExpansionState(node: TreeItemFlatNode) {
    if (this.hasChild(node)) {
      if (this.treeControl.isExpanded(node)) {
        this.treeControl.collapse(node);
      }
      else {
        this.treeControl.expand(node);
      }
    }
    this.setContainerHeight();
  }

  isSelectAllDescendantsVisible(node: TreeItemFlatNode) {
    return this.isMultiChoice && this.selectionStrategy === TreeItemSelectionStrategy.HYBRID && this.hasChild(node);
  }

  private applyCheckedItems(removals?: Array<Nullable<string | number>>) {
    const checkedItemsIds = this.checkedItems.map(ci => ci.value);
    for (const [mapKey, mapValue] of this.nestedNodeMap.entries()) {
      if (mapKey.id) {
        this.hasChildHelperDic[mapKey.id] = mapKey.children!;
        if (checkedItemsIds.includes(mapKey.id)) {
          if (this.selectionStrategy === TreeItemSelectionStrategy.BULK) {
            this.expandUp(mapKey, mapValue);
          }

          if (!this.checklistSelection.selected.find(cs => cs.value === mapValue.value)) {
            this.checklistSelection.select(mapValue);
          }
          if (!this.selectedNodes.find(sn => sn.value === mapValue.value)) {
            this.selectedNodes.push(mapValue);
          }
        }

        if (removals?.includes(mapValue.value)) {
          this.checklistSelection.deselect(mapValue);

          const selectedNodeIndex = this.selectedNodes.findIndex(sn => sn.value === mapValue.value);

          if (selectedNodeIndex !== -1) {
            this.selectedNodes.splice(selectedNodeIndex, 1);
          }
        }
      }
    }
  }

  private toggleItemSelectionSimple() {
    this.cleverRemoval();
  }

  private toggleItemSelectionBulk(node: TreeItemFlatNode) {
    this.selectDescendantsByNode(node);
    this.selectParentsByNode(node);
  }

  private selectParentsByNode(node: TreeItemFlatNode) {
    this.checkAllParentsSelection(node);
    this.cleverRemoval();
  }

  private selectDescendantsByNode(node: TreeItemFlatNode) {
    const descendants = this.treeControl.getDescendants(node).filter(node => !node.disabled);
    const isRootNodeSelected = this.checklistSelection.isSelected(node);

    if (isRootNodeSelected || (!isRootNodeSelected && node.disabled) || (!isRootNodeSelected && node.isReserved)) {
      this.checklistSelection.select(...descendants);
    }
    else {
      this.checklistSelection.deselect(...descendants);
    }
  }

  private toggleItemSelectionHybrid() {
    this.cleverRemoval();
  }

  private emitChanges() {
    this.selectionChanged.emit({
      childrenPath: this.childrenPath,
      selectedNodes: this.selectedNodes,
    });
  }

  /** Checks all the parents when a leaf node is selected/unselected */
  private checkAllParentsSelection(node: TreeItemFlatNode): void {
    let parent: Nullable<TreeItemFlatNode> = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  private expandUp(node: TreeItemNode, expandableNode: TreeItemFlatNode) {
    if (node.parent) {
      for (const [key, value] of this.nestedNodeMap.entries()) {
        if (key.id === node.parent.id) {
          this.expandUp(node.parent, value);
          break;
        }
      }
    }

    if (expandableNode.expandable) this.treeControl.expand(expandableNode);
  }

  /** Check root node checked state and change it accordingly */
  private checkRootNodeSelection(node: TreeItemFlatNode): void {
    const nodeSelected = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const descAllSelected = descendants.every(child =>
      this.checklistSelection.isSelected(child)
    );
    if (nodeSelected && !descAllSelected) {
      this.checklistSelection.deselect(node);
    } else if (!nodeSelected && descAllSelected) {
      this.checklistSelection.select(node);
    }
  }

  /** Get the parent node of a node */
  private getParentNode(node: TreeItemFlatNode): Nullable<TreeItemFlatNode> {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }
    return null;
  }

  /**
   * Marks children of node to exclude from removing from input array.
   * When search is made, desired result is to show all descendants of a node
   * even when they dont fulfil searched condition
   */
  private markChildrenToNotDelete(children: TreeItemNode[], helperLeaveMeDic: { [optionId: string]: boolean }) {
    children.forEach(child => {
      if (child.children) {
        this.markChildrenToNotDelete(child.children, helperLeaveMeDic);
      }

      helperLeaveMeDic[child.id!] = true;
    });
  }

  /**
   * Adds/Removes nodes from selectedNodes based on flatNodeMap and checklistSelection.
   * Reason for this is changing number of input nodes by search
   */
  private cleverRemoval() {
    [...this.selectedNodes].forEach(sn => {
      if (Array.from(this.flatNodeMap.values()).map(s => s.id).some(ex => ex === sn.value)
        && !this.checklistSelection.selected.some(cs => cs.value === sn.value)) {
        this.selectedNodes.splice(this.selectedNodes.indexOf(sn), 1);
      }
    });

    this.writeSelectedNodes();
    this.emitChanges();
  }

  private writeSelectedNodes() {
    this.checklistSelection.selected.forEach(cs => {
      if (!this.selectedNodes.some(sn => sn.value === cs.value)) this.selectedNodes.push(cs);
    });
  }

  private constructChildrenPaths(node: Option, nested: Nullable<string | number> = null) {
    if (node?.parent) {
      const parent = this.originalData.find(od => od.value === node.parent);
      let key;
      if (nested) {
        key = nested!;
      } else {
        key = node.value;
        nested = node.value;
      }

      if (parent) {
        // going up
        this.constructChildrenPaths(parent, nested);

        // going down
        if (key) {
          if (!this.childrenPath[key]) this.childrenPath[key] = [parent.label];
          else this.childrenPath[key].push(parent.label);
        }
      }
    }
  }

  private resetViewHelperNodeMaps() {
    this.flatNodeMap = new Map<TreeItemFlatNode, TreeItemNode>();
    this.nestedNodeMap = new Map<TreeItemNode, TreeItemFlatNode>();
  }

  private autoExpandNodes() {
    const autoExpandableItems = this.treeControl.dataNodes.filter(node => node.autoExpand);

    for (const node of autoExpandableItems) {
      this.treeControl.expand(node);
    }
  }

  reselect() {
    if (this.isMultiChoice) {
      for (const [key, value] of this.nestedNodeMap.entries()) {
        this.hasChildHelperDic[key.id!] = key.children!;
        if (this.selectedNodes.some(sn => sn.value === key.id)) {
          this.checklistSelection.select(value);
        }
      }
    }
  }

}
