/* eslint-disable no-restricted-imports */
import {AbstractControl, UntypedFormControl, ValidationErrors, Validators} from '@angular/forms';
import {isNil, isNumber} from 'lodash';
import {AnalogCompositionType, SubjectRecordClassification, SubjectRecordCreateOrUpdateDto} from '|api/commons';
import {SubjectAsSender} from '../../../shared-business-components/model/subjects.model';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../icz-form-controls';
import {IczValidatorFn, ValidationErrorMessage} from './validator-decorators';
import {OrganizationalStructureOption, OrganizationalUnitOptionData} from '../../../../core/services/organizational-structure.service';

// Source: https://en.wikipedia.org/wiki/Luhn_mod_N_algorithm , adapted to our needs
function generalizedLuhnCheck(nums: number[], modulus: number) {
  if (modulus <= 0 || modulus % 2 !== 0) {
    throw new Error(`Luhn check modulus must be even natural number. Currently it is not - mod=${modulus}.`);
  }
  if (nums.some(n => n < 0 || n >= modulus)) {
    throw new Error(`Luhn check mod ${modulus} input array contains number(s) outside the base: ${nums}.`);
  }

  let factor = 1;
  let sum = 0;

  for (let i = nums.length - 1; i >= 0; i--) {
    let addend = factor * nums[i];

    factor = (factor === 2) ? 1 : 2;

    addend = (Math.floor(addend / modulus)) + (addend % modulus);
    sum += addend;
  }

  const remainder = sum % modulus;
  return (remainder === 0);
}

// eslint-disable-next-line -- definition coherent with Angular docs
export type ValidatorResult = ValidationErrors | null;

/**
 * A collection of validators to be used in our forms.
 * Conventions:
 * - Every validator here should either reuse a built-in Angular validator by returning it
 *     or define and return a custom ValidatorFn for Angular Reactive forms
 *     (see https://dzone.com/articles/how-to-create-custom-validators-in-angular)
 * - Every validator should define a template of its validation error message
 *     using the @ValidationErrorMessage decorator. Further reference
 *     for the decorator to be found in validator-decorators.ts.
 */
export class IczValidators {
  @ValidationErrorMessage('Nesmí být prázdné.')
  static required(): IczValidatorFn {
    return Validators.required;
  }

  @ValidationErrorMessage('Musí obsahovat alespoň {{requiredLength}} znaků.')
  static minLength(length: number): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (typeof control.value === 'string') {
        return Validators.minLength(length)(control);
      }
      else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Nesmí přesáhnout {{requiredLength}} znaků.')
  static maxLength(length: number): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (typeof control.value === 'string') {
        return Validators.maxLength(length)(control);
      }
      else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Musí být e-mail ve tvaru "xxx@yyy.zz".')
  static email(): IczValidatorFn {
    return Validators.email;
  }

  @ValidationErrorMessage('Musí být telefonní číslo ve tvaru "+123 456 789 012".')
  static phoneNumber(): IczValidatorFn {
    return Validators.pattern(/\+\d{1,3} \d{3} \d{3} \d{3}/);
  }

  @ValidationErrorMessage('Musí být URL ve tvaru "https://base.application.url/" nebo "http://base.application.url/".')
  static simpleUrl(): IczValidatorFn {
    return Validators.pattern(/^(https?:\/\/)[^\/]+\/$/);
  }

  @ValidationErrorMessage('Musí mít minimální číselnou hodnotu {{min}}.')
  static min(value: number): IczValidatorFn {
    return Validators.min(value);
  }

  @ValidationErrorMessage('Musí mít maximální číselnou hodnotu {{max}}.')
  static max(value: number): IczValidatorFn {
    return Validators.max(value);
  }

  @ValidationErrorMessage('Musí být poštovní směrovací číslo.')
  static postalCode(includeSpace: boolean): IczValidatorFn {
    return includeSpace ? Validators.pattern(/^(\d{3}) *(\d{2})$/) : Validators.pattern(/^(\d{5})$/);
  }

  @ValidationErrorMessage('Zadejte platné orientační číslo ve formátu "číslo/vchod budovy"')
  static orientationNumber(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const buildingNumericValue = parseInt((control.value?.match(/\d+/))?.[0]);
      if (buildingNumericValue < 1) {
        return {orientationNumber: false};
      }
      return Validators.pattern(/^(\d)+(\/)?([a-z]|[A-Z]?)$/)(control);
    };
  }

  @ValidationErrorMessage('Zadejte platné datum ve formátu "D.M.RRRR", "D/M/RRRR", nebo "DDMMRRRR".')
  static localDate() {
    return Validators.pattern(/(^\d{1,2}\. ?\d{1,2}\. ?\d{4}$)|(^\d{1,2}\/ ?\d{1,2}\/ ?\d{4}$)|(^\d{2}\d{2}\d{4}$)/);
  }

  @ValidationErrorMessage('Zadejte platný čas ve formátu "HH:MM:SS"')
  static isTime() {
    return Validators.pattern(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/);
  }

  @ValidationErrorMessage('Zadejte platný čas ve formátu "HH:MM"')
  static isSimpleTime() {
    return Validators.pattern(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/);
  }

  @ValidationErrorMessage('Zadejte platný datum s časem ve formátu "DD.MM.RRRR HH:MM:SS"')
  static isLocalDateTime() {
    return Validators.pattern(/^([1-9]|([012][0-9])|(3[01]))\.(0{0,1}[1-9]|1[012])\.\d\d\d\d (2[0-3]|[0-1]?\d):[0-5]\d:[0-5]\d$/);
  }

  @ValidationErrorMessage('Musí být platné desetinné číslo, Např.: "2,5", "2,25", "2,0434223"')
  static isDecimal() {
    return (control: AbstractControl): ValidatorResult => {
      if (!isNil(control.value) && !isNumber(control.value)) {
        return {isDecimal: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Musí být celé číslo, Např.: "2", "15", "0"')
  static isInteger(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (!isNil(control.value) && !Number.isInteger(control.value)) {
        return {isInteger: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Musí být celé číslo, Např.: "2", "15", "0"')
  static isStringifiedInteger(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      // eslint-disable-next-line eqeqeq -- coercive behavior is on point here - `string|number == string` where string|number might contain nonconversible values
      if (control.value && control.value != `${parseInt(control.value, 10)}`) {
        return {isInteger: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Nelze vybrat funkční místo, které nikdo nevykonává ani organizační jednotku, která nemá určeného reprezentanta.')
  static isNonEmptyOrgEntity(options: OrganizationalStructureOption[]): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (control.value && options.length) {
        const option = options.find(o => o.value === control.value);
        if ((option?.data as OrganizationalUnitOptionData)?.isEmptyOrgEntity) {
          return {isNonEmptyOrgEntity: false};
        } return null;
      }
      return null;
    };
  }

  @ValidationErrorMessage('Není platné IČO')
  static isCID(): IczValidatorFn {
    const isIco = (v: Nullable<string>) => {
      let a = 0;

      if (!v || v.length !== 8) return false;

      const b = v.split('');

      let c = 0;

      for (let i = 0; i < 7; i++) {
        a += (parseInt(b[i]) * (8 - i));
      }

      a = a % 11;
      c = 11 - a;
      if (a === 1) c = 0;
      if (a === 0) c = 1;
      if (a === 10) c = 1;
      return parseInt(b[7]) === c;
    };

    return (control: AbstractControl): ValidatorResult => {
      if (control.value && (typeof(control.value) === 'string' && !isIco(control.value))) {
        return {isCID: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Nebyl vybrán platný Odesílatel. Je nutno buďto vyhledat existující subjekt, nebo zadat minimální údaje pro jeho založení včetně adresy.')
  static isValidSenderSubject(): IczValidatorFn {
    const looksLikeSenderDto = (value: SubjectAsSender) => {
      return typeof(value) === 'number' ||
        Object.values(SubjectRecordClassification).includes((value as SubjectRecordCreateOrUpdateDto)!.classification!);
    };

    return (control: AbstractControl): ValidatorResult => {
      if (!control.value || !looksLikeSenderDto(control.value)) {
        return {isValidSenderSubject: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Pro založení subjektu je nutné vybrat konkrétní typ subjektu.')
  static isValidSubjectClassification(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (control.value === 'ALL') {
        return {isValidSubjectClassification: false};
      }
      return null;
    };
  }

  // lp: deprecated, but still possible this validator will be needed (with slight modification)
  static analogVolumesFormValidator: IczValidatorFn = (fg: AbstractControl) => {
    if (fg.get('analogCompositionType')!.value === AnalogCompositionType.VOLUMES) return null;
    const paperComponentSheetEnclosureCount = fg.get('paperComponentSheetEnclosureCount')!.value;
    const paperComponentSheetCount = fg.get('paperComponentSheetCount')!.value;
    return paperComponentSheetEnclosureCount !== null && paperComponentSheetCount !== null && paperComponentSheetEnclosureCount <= paperComponentSheetCount
      ? null
      : {analogVolumesFormValidator: {errorMessageTemplate: 'Počet listů všech komponent nesmí být menší než Počet komponent.'}};
  };

  @ValidationErrorMessage('Pole musí obsahovat pouze číslice.')
  static number(): IczValidatorFn {
    return Validators.pattern(/^\d+$/);
  }

  @ValidationErrorMessage('Hesla se musí shodovat')
  static matchPasswords(): IczValidatorFn {
    return (control: AbstractControl) => {
      const password = control.get('password');
      const confirmPassword = control.get('confirmPassword');

      return (password?.touched || confirmPassword?.touched) && password!.value !== confirmPassword!.value ? {notMatchingPassword: true} : null;
    };
  }

  // used under very specific circumstances - when we want to
  // use an autocomplete option with value equal to null.
  // note that when using this validator, the initial value of
  // given form control must be equal to undefined or some other
  // falsy value that is not null.
  // ---
  // why is it like this? because historically the code relied on
  // "null" meaning "unfilled" but in fact it is sometimes a
  // special value for backend services.
  @ValidationErrorMessage('Nesmí být prázdné.')
  static requiredButAllowNull(): IczValidatorFn {
    return (control: AbstractControl) => {
      return (control.value === null || Validators.required(control) === null) ? null : {requiredButAllowNull: true};
    };
  }

  @ValidationErrorMessage('Musí končit názvem jednotky {kB, MB}, který není oddělen mezerou. Příklad: „100MB“.')
  static isValidFileSize(): IczValidatorFn {
    return Validators.pattern(/^(\d*\.?\d+)[kM]B$/);
  }

  @ValidationErrorMessage('Musí být platný formát pro paragraf v zákone. Příklad: „40a“.')
  static isValidEmpowermentSection(): IczValidatorFn {
    return Validators.pattern(/^(\d+)[a-z]?$/);
  }

  @ValidationErrorMessage('Musí být platný formát pro písmeno v odstavci zákona.')
  static isValidEmpowermentPoint(): IczValidatorFn {
    return Validators.pattern(/^(?!.*ch)[a-z]{1,2}$/);
  }

  @ValidationErrorMessage('Musí být platný identifikátor datové schránky.')
  static isValidDataboxIdentifier(): IczValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      else {
        // base space symbols are sensitive to reordering. keep them like this pls
        const baseSpaceSymbols = 'abcdefghijkmnpqrstuvwxyz23456789';
        const formatValidatorResult = Validators.pattern(new RegExp(`^[${baseSpaceSymbols}]{7}$`))(control);

        if (formatValidatorResult === null) {
          const characterCodesInBaseSpace = (control.value as string).split('').map(letter => baseSpaceSymbols.indexOf(letter));
          const isLuhnCheckValid = generalizedLuhnCheck(characterCodesInBaseSpace, baseSpaceSymbols.length);

          if (isLuhnCheckValid) {
            return null;
          }
          else {
            return {isValidDataboxIdentifier: false};
          }
        }
        else {
          return {isValidDataboxIdentifier: false};
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být nahrána šablona štítku.')
  static isLabelTemplateUploaded(): IczValidatorFn {
    return Validators.required;
  }

  @ValidationErrorMessage('Musí být nahrána výchozí šablona štítku.')
  static isDefaultLabelTemplateSet(): IczValidatorFn {
    return Validators.required;
  }

  @ValidationErrorMessage('Musí být validní pole formátu JSON.')
  static isJsonArray(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const value = control.value;

      if (!value) {
        return null;
      }
      else {
        const jsonValueValidationResult = IczValidators.isJsonValue()(control);

        if (jsonValueValidationResult === null) {
          const parsedValue = JSON.parse(value);

          if (!parsedValue || Array.isArray(parsedValue)) {
            return null;
          }
          else {
            return {isJsonArray: true};
          }
        }
        else {
          return {isJsonArray: true};
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být validní objekt formátu JSON.')
  static isJsonObject(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const value = control.value;

      if (!value) {
        return null;
      }
      else {
        const jsonValueValidationResult = IczValidators.isJsonValue()(control);

        if (jsonValueValidationResult === null) {
          const parsedValue = JSON.parse(value);

          // typeof(parsedValue) === 'object' => null literal included on purpose
          if (typeof(parsedValue) === 'object' && !Array.isArray(parsedValue)) {
            return null;
          }
          else {
            return {isJsonArray: true};
          }
        }
        else {
          return {isJsonObject: true};
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být validní hodnota formátu JSON.')
  static isJsonValue(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const value = control.value;

      if (!value) {
        return null;
      }
      else {
        try {
          JSON.parse(value);
          return null;
        }
        catch (ex) {
          return {isJsonValue: true};
        }
      }
    };
  }

  @ValidationErrorMessage('Datum odeslání nesmí být pozdější než datum doručení')
  static isDispatchDateValid(): IczValidatorFn {
    return (control: AbstractControl) => {
      if (!isNil(control.parent) && !isNil(control.value) ){
        const deliveryDateValue = control.parent!.get('deliveryDate')!.value;
        if (new Date(deliveryDateValue) < new Date(control.value)) {
          return {isDispatchDateValid: false};
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Je nutné vybrat právě jeden soubor.')
  static oneFileSelected(): IczValidatorFn {
    return (control: AbstractControl) => {
      if ((control as IczFormArray).length === 1) {
        return null;
      } else {
        return {
          fileSelected: false,
        };
      }
    };
  }

  @ValidationErrorMessage('Nebylo přidáno žádné oprávnění.')
  static isPermissionListFilled(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (control.value.length < 1) {
        return {isPerrmisionAdded: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Není nahrán soubor.')
  static isDigitalComponentUploadFormValid(): IczValidatorFn {
    return (control: AbstractControl) => {
      if (isNil((control as IczFormGroup).value.digitalComponentVersionId) && isNil((control as IczFormGroup).value.digitalComponentTemplateId)) {
        return {
          uploadInvalid: false,
        };
      } else {
        return null;
      }
    };
  }

  static _dummyValidator = () => null;
}

export function isRequired(control: Nullable<AbstractControl>) {
  const validationErrors = control?.validator?.(new UntypedFormControl('')) ?? {};

  return 'required' in validationErrors || 'requiredButAllowNull' in validationErrors;
}

export function isImportant(control: Nullable<IczFormControl>) {
  return control?.important!;
}
