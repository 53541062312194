<div class="flex justify-between">
  @if (level === '1' || !level) {
    <h1 class="grow"><ng-container *ngTemplateOutlet="innerContent"></ng-container></h1>
  }
  @if (level === '2') {
    <h2 class="grow"><ng-container *ngTemplateOutlet="innerContent"></ng-container></h2>
  }
  @if (level === '3') {
    <h3 class="grow"><ng-container *ngTemplateOutlet="innerContent"></ng-container></h3>
  }
  <ng-content select="[extraContent]"></ng-content>
</div>

<ng-template #innerContent>
  {{ label | translate | interpolate:(labelContext ?? {}) }}
</ng-template>
