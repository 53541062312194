/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { disposalScheduleCreate } from '../fn/disposal-schedule/disposal-schedule-create';
import { DisposalScheduleCreate$Params } from '../fn/disposal-schedule/disposal-schedule-create';
import { DisposalScheduleDto } from '../models/disposal-schedule-dto';
import { disposalScheduleFind } from '../fn/disposal-schedule/disposal-schedule-find';
import { DisposalScheduleFind$Params } from '../fn/disposal-schedule/disposal-schedule-find';
import { disposalScheduleFindAll } from '../fn/disposal-schedule/disposal-schedule-find-all';
import { DisposalScheduleFindAll$Params } from '../fn/disposal-schedule/disposal-schedule-find-all';
import { disposalScheduleFindById } from '../fn/disposal-schedule/disposal-schedule-find-by-id';
import { DisposalScheduleFindById$Params } from '../fn/disposal-schedule/disposal-schedule-find-by-id';
import { disposalScheduleFindDisposalScheduleUsages } from '../fn/disposal-schedule/disposal-schedule-find-disposal-schedule-usages';
import { DisposalScheduleFindDisposalScheduleUsages$Params } from '../fn/disposal-schedule/disposal-schedule-find-disposal-schedule-usages';
import { disposalScheduleGetEffectiveDisposalSchedule } from '../fn/disposal-schedule/disposal-schedule-get-effective-disposal-schedule';
import { DisposalScheduleGetEffectiveDisposalSchedule$Params } from '../fn/disposal-schedule/disposal-schedule-get-effective-disposal-schedule';
import { disposalScheduleUpdate } from '../fn/disposal-schedule/disposal-schedule-update';
import { DisposalScheduleUpdate$Params } from '../fn/disposal-schedule/disposal-schedule-update';
import { DisposalScheduleUsageDto } from '../models/disposal-schedule-usage-dto';
import { EffectiveDisposalScheduleResponse } from '../models/effective-disposal-schedule-response';
import { PageDisposalScheduleDto } from '../models/page-disposal-schedule-dto';


/**
 * Controller for Disposal Schedules
 */
@Injectable({ providedIn: 'root' })
export class ApiDisposalScheduleService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `disposalScheduleFindById()` */
  static readonly DisposalScheduleFindByIdPath = '/codebook/disposal-schedule/{id}';

  /**
   * Find disposal schedule by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFindById$Response(params: DisposalScheduleFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<DisposalScheduleDto>> {
    return disposalScheduleFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find disposal schedule by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFindById(params: DisposalScheduleFindById$Params, context?: HttpContext): Observable<DisposalScheduleDto> {
    return this.disposalScheduleFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<DisposalScheduleDto>): DisposalScheduleDto => r.body)
    );
  }

  /** Path part for operation `disposalScheduleUpdate()` */
  static readonly DisposalScheduleUpdatePath = '/codebook/disposal-schedule/{id}';

  /**
   * Update existing disposal schedule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disposalScheduleUpdate$Response(params: DisposalScheduleUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<DisposalScheduleDto>> {
    return disposalScheduleUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing disposal schedule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disposalScheduleUpdate(params: DisposalScheduleUpdate$Params, context?: HttpContext): Observable<DisposalScheduleDto> {
    return this.disposalScheduleUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DisposalScheduleDto>): DisposalScheduleDto => r.body)
    );
  }

  /** Path part for operation `disposalScheduleFindAll()` */
  static readonly DisposalScheduleFindAllPath = '/codebook/disposal-schedule';

  /**
   * Finds all disposal schedules
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFindAll$Response(params?: DisposalScheduleFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DisposalScheduleDto>>> {
    return disposalScheduleFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all disposal schedules
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFindAll(params?: DisposalScheduleFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<DisposalScheduleDto>> {
    return this.disposalScheduleFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DisposalScheduleDto>>): Array<DisposalScheduleDto> => r.body)
    );
  }

  /** Path part for operation `disposalScheduleCreate()` */
  static readonly DisposalScheduleCreatePath = '/codebook/disposal-schedule';

  /**
   * Create new disposal schedule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disposalScheduleCreate$Response(params: DisposalScheduleCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<DisposalScheduleDto>> {
    return disposalScheduleCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new disposal schedule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disposalScheduleCreate(params: DisposalScheduleCreate$Params, context?: HttpContext): Observable<DisposalScheduleDto> {
    return this.disposalScheduleCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<DisposalScheduleDto>): DisposalScheduleDto => r.body)
    );
  }

  /** Path part for operation `disposalScheduleGetEffectiveDisposalSchedule()` */
  static readonly DisposalScheduleGetEffectiveDisposalSchedulePath = '/codebook/disposal-schedule/effective';

  /**
   * Calculation of effective disposal schedule.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleGetEffectiveDisposalSchedule()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disposalScheduleGetEffectiveDisposalSchedule$Response(params: DisposalScheduleGetEffectiveDisposalSchedule$Params, context?: HttpContext): Observable<StrictHttpResponse<EffectiveDisposalScheduleResponse>> {
    return disposalScheduleGetEffectiveDisposalSchedule(this.http, this.rootUrl, params, context);
  }

  /**
   * Calculation of effective disposal schedule.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleGetEffectiveDisposalSchedule$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  disposalScheduleGetEffectiveDisposalSchedule(params: DisposalScheduleGetEffectiveDisposalSchedule$Params, context?: HttpContext): Observable<EffectiveDisposalScheduleResponse> {
    return this.disposalScheduleGetEffectiveDisposalSchedule$Response(params, context).pipe(
      map((r: StrictHttpResponse<EffectiveDisposalScheduleResponse>): EffectiveDisposalScheduleResponse => r.body)
    );
  }

  /** Path part for operation `disposalScheduleFindDisposalScheduleUsages()` */
  static readonly DisposalScheduleFindDisposalScheduleUsagesPath = '/codebook/disposal-schedule/{id}/find-usages';

  /**
   * Find all usages of given disposal schedule
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleFindDisposalScheduleUsages()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFindDisposalScheduleUsages$Response(params: DisposalScheduleFindDisposalScheduleUsages$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<DisposalScheduleUsageDto>>> {
    return disposalScheduleFindDisposalScheduleUsages(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all usages of given disposal schedule
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleFindDisposalScheduleUsages$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFindDisposalScheduleUsages(params: DisposalScheduleFindDisposalScheduleUsages$Params, context?: HttpContext): Observable<Array<DisposalScheduleUsageDto>> {
    return this.disposalScheduleFindDisposalScheduleUsages$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DisposalScheduleUsageDto>>): Array<DisposalScheduleUsageDto> => r.body)
    );
  }

  /** Path part for operation `disposalScheduleFind()` */
  static readonly DisposalScheduleFindPath = '/codebook/disposal-schedule/search-api';

  /**
   * Find all disposal schedule instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `disposalScheduleFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFind$Response(params?: DisposalScheduleFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageDisposalScheduleDto>> {
    return disposalScheduleFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all disposal schedule instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `disposalScheduleFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  disposalScheduleFind(params?: DisposalScheduleFind$Params|null|undefined, context?: HttpContext): Observable<PageDisposalScheduleDto> {
    return this.disposalScheduleFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageDisposalScheduleDto>): PageDisposalScheduleDto => r.body)
    );
  }

}
