import {HttpErrorResponse} from '@angular/common/http';
import {of, OperatorFunction, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {FilingRegisterDto} from '|api/records';

// must be used with SKIP_ERROR_DIALOG_CONTEXT HttpContext for request method
export function handleNonexistentFilingRegister(): OperatorFunction<FilingRegisterDto, Nullable<FilingRegisterDto>> {
  return catchError((e: HttpErrorResponse) => {
    const errorCode = e.error.errorCode;

    if (errorCode === 'be.error.filingRegister.doesNotExist') {
      return of(null);
    }
    else {
      return throwError(() => e);
    }
  });
}
