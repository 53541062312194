@if (form && subject) {
  <icz-table-preview [opened]="opened" (closed)="closed.emit()">
    <ng-container previewHeader>
      <div class="row items-center">
        <icz-subject-header-info [subject]="subject"></icz-subject-header-info>
      </div>
    </ng-container>
    <div class="p-16 col">
      @for (attribute of orderedAttributes;track attribute) {
        @if ($any(subject.attributes[attribute])?.value) {

          @if (attribute === SubjectAttributeType.LEGAL_FORM) {
            <icz-subject-single-attribute [form]="form"
                                          [subjectAttributeType]="SubjectAttributeType.LEGAL_FORM"
                                          [componentType]="SubjectAttributeComponentType.AUTOCOMPLETE"
                                          [options]="legalFormOptions$ | async"
                                          class="grow">
            </icz-subject-single-attribute>
          } @else if (attribute === SubjectAttributeType.GENDER) {
            <icz-subject-single-attribute [form]="form"
                                          [subjectAttributeType]="SubjectAttributeType.GENDER"
                                          [options]="genderOptions" [componentType]="SubjectAttributeComponentType.AUTOCOMPLETE"
                                          class="grow">
            </icz-subject-single-attribute>
          } @else if (attribute === SubjectAttributeType.BIRTH_DATE) {
            <icz-subject-single-attribute [form]="form"
                                          [subjectAttributeType]="SubjectAttributeType.BIRTH_DATE"
                                          [componentType]="SubjectAttributeComponentType.DATEPICKER"
                                          class="col grow">
            </icz-subject-single-attribute>
          } @else {
            <icz-subject-single-attribute [form]="form" [subjectAttributeType]="attribute"
                                          class="grow">
            </icz-subject-single-attribute>
          }
        }
      }
      @for (attribute of orderedAttributesWithMultiplicityMany;track attribute) {
        @for (singleAttributeFormGroup of getMultipleValuesControl(attribute).controls;track singleAttributeFormGroup) {
          <ng-container [formGroup]="$any(singleAttributeFormGroup)">
            <icz-subject-single-attribute [form]="$any(singleAttributeFormGroup)" groupName="." [subjectAttributeType]="attribute"
                                          class="grow">
            </icz-subject-single-attribute>
          </ng-container>
        }
      }
    </div>
  </icz-table-preview>
}
