/* tslint:disable */
/* eslint-disable */

/**
 * Issd Conversion Mapping Attribute
 */
export enum IssdConversionMappingAttribute {
  USER = 'USER',
  FUNCTIONAL_POSITION = 'FUNCTIONAL_POSITION',
  CLASSIFICATION_SCHEME = 'CLASSIFICATION_SCHEME',
  ENTITY_CLASS_CODE = 'ENTITY_CLASS_CODE',
  DISPOSAL_SCHEDULE_CODE = 'DISPOSAL_SCHEDULE_CODE',
  RETENTION_TRIGGER = 'RETENTION_TRIGGER',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  FILE_TYPE = 'FILE_TYPE',
  FILING_REGISTER = 'FILING_REGISTER',
  MANIPULATION_STYLE = 'MANIPULATION_STYLE',
  POSTAL_SERVICES = 'POSTAL_SERVICES',
  OFFICE_DESK_CATEGORY = 'OFFICE_DESK_CATEGORY',
  DOCUMENT_FORM = 'DOCUMENT_FORM'
}
