import {CommonModule} from '@angular/common';
import {ErrorHandler, inject, NgModule} from '@angular/core';
import {TitleStrategy} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EsslErrorHandler} from './error-handling/essl-error-handler';
import {CirculationNotificationsService} from './services/notifications/circulation-notifications.service';
import {DocumentNotificationsService} from './services/notifications/document-notifications.service';
import {NotificationCountHandlerService} from './services/notifications/notification-count-handler.service';
import {OfficeDeskNotificationsService} from './services/notifications/office-desk-notifications.service';
import {RenditionNotificationsService} from './services/notifications/rendition-notification.service';
import {SettlementNotificationsService} from './services/notifications/settlement-notifications.service';
import {IczPageTitleStrategy} from './routing/icz-page-title-strategy';
import {OrgStructureNotificationsService} from './services/notifications/org-structure-notifications.service';
import {ConsignmentNotificationService} from './services/notifications/consignment-notification.service';
import {EsslComponentNotificationsService} from './services/notifications/essl-component-notifications.service';
import {StorageUnitNotificationsService} from './services/notifications/storage-unit-notifications.service';
import {EpdzErrorNotificationsService} from './services/notifications/epdz-error-notifications.service';
import {SharedFolderNotificationsService} from './services/notifications/shared-folder-notification.service';
import {RegistryOfficeNotificationsService} from './services/notifications/registry-office-notifications.service';
import {AdminNotificationsService} from './services/notifications/admin-notifications.service';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useClass: EsslErrorHandler,
    },
    {
      provide: TitleStrategy,
      useClass: IczPageTitleStrategy,
    },
  ],
  imports: [
    CommonModule,
  ],
})
export class CoreModule {

  constructor() {
    if (inject(CoreModule, {optional: true, skipSelf: true})) {
      throw new Error('You should import core module only in the root module');
    }

    // Effectively constructs notification services with websocket registration for counts
    inject(NotificationCountHandlerService).initialize();
    inject(DocumentNotificationsService).initialize();
    inject(CirculationNotificationsService).initialize();
    inject(SettlementNotificationsService).initialize();
    inject(RenditionNotificationsService).initialize();
    inject(OfficeDeskNotificationsService).initialize();
    inject(OrgStructureNotificationsService).initialize();
    inject(ConsignmentNotificationService).initialize();
    inject(EsslComponentNotificationsService).initialize();
    inject(StorageUnitNotificationsService).initialize();
    inject(EpdzErrorNotificationsService).initialize();
    inject(SharedFolderNotificationsService).initialize();
    inject(RegistryOfficeNotificationsService).initialize();
    inject(AdminNotificationsService).initialize();

    // TranslateService injection into IczPageTitleStrategy must be done manually
    //  because otherwise it throws circular DI dependency error.
    (inject(TitleStrategy) as IczPageTitleStrategy).setTranslateService(inject(TranslateService));
  }

}
