import {startWith} from 'rxjs/operators';
import {DestroyRef, EventEmitter, Injectable} from '@angular/core';
import {IczFormGroup} from '../../../form-elements/icz-form-controls';
import {daysBetween, formatAsLocalIsoDate} from '../../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Injectable({
  providedIn: 'root',
})
export class ConsignmentUtilsService {
  registerCalcPostingDays(form: IczFormGroup, context: DestroyRef) {
    const initialToPostFrom = form.get('toPostFrom')!.value;
    const initialPostingDuration = form.get('postingDuration')!.value;
    const initialToPostTo = form.get('toPostTo')!.value;

    form.get('toPostFrom')!.valueChanges.pipe(
      startWith(initialToPostFrom), takeUntilDestroyed(context))
      .subscribe(toPostFrom => {
        const toPostTo = form.get('toPostTo')!.value;
        if (toPostFrom && toPostTo) {
          const countDaysBetween = Math.max(0, daysBetween(toPostFrom, toPostTo) - 1); // Boundary days are excluded
          form.get('postingDuration')!.setValue(countDaysBetween, {emitEvent: false});
        }
      });

    form.get('postingDuration')!.valueChanges.pipe(
      startWith(initialPostingDuration), takeUntilDestroyed(context))
      .subscribe(postingDuration => {
        const toPostFrom = form.get('toPostFrom')!.value;
        if (toPostFrom && postingDuration && !isNaN(postingDuration as number)) {
          const date = new Date(toPostFrom);
          date.setDate(date.getDate() + parseInt(postingDuration as unknown as string) + 1);
          const toPostTo = formatAsLocalIsoDate(date);
          form.get('toPostTo')!.setValue(toPostTo, {emitEvent: false});
          (form.get('toPostTo')!.statusChanges as EventEmitter<any>).emit(form.get('toPostTo')!.status);
        }
      });

    form.get('toPostTo')!.valueChanges.pipe(
      startWith(initialToPostTo), takeUntilDestroyed(context))
      .subscribe(toPostTo => {
        const toPostFrom = form.get('toPostFrom')!.value;
        if (toPostFrom && toPostTo) {
          const countDaysBetween = Math.max(0, daysBetween(toPostFrom, toPostTo) - 1); // Boundary days are excluded
          form.get('postingDuration')!.setValue(countDaysBetween, {emitEvent: false});
        }
      });
  }
}
