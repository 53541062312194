import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CodebookService} from '../../core/services/codebook.service';
import {getUserFullName} from '../../model';

@Pipe({
  name: 'findUserByUsername',
  pure: true,
})
export class FindUserByUsernamePipe implements PipeTransform {

  private codebookService = inject(CodebookService);

  transform(username: Nullable<string>): Observable<string> {
    if (isNil(username)) {
      return of('');
    }
    else {
      return this.codebookService.users().pipe(
        map(users => users.find(u => u.username === username)!),
        map(user => getUserFullName(user)),
      );
    }
  }

}
