import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {DocumentTypeDto} from '|api/codebook';
import {AbstractClassifierSelectorComponent} from '../abstract-classifier-selector.component';
import {CodebookService} from '../../../core/services/codebook.service';
import {NodeType} from '|api/commons';
import {namedDtoToOption} from '../../../core/services/data-mapping.utils';

@Component({
  selector: 'icz-document-type-selector',
  templateUrl: './document-type-selector.component.html',
  styleUrls: ['./document-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentTypeSelectorComponent extends AbstractClassifierSelectorComponent<DocumentTypeDto> {

  private codebookService = inject(CodebookService);

  @Input()
  label = 'Typ dokumentu';
  @Input()
  isMultiselect = false;
  @Input()
  fieldDisabled: Nullable<boolean>;
  @Input()
  showPopupDetails = false;

  classifierSource$ = this.codebookService.documentTypes();

  optionMapper = (classifier: DocumentTypeDto) => {
    return {data: classifier, ...namedDtoToOption(true)(classifier)};
  };
  override prefilteringPredicate = (e: DocumentTypeDto) => e.nodeType === NodeType.LEAF;


  get selectedSelectedDocumentId() {
    if (this.form && this.controlName) {
      return this.form.get(this.controlName)!.value;
    } else {
      return null;
    }
  }

  override prepareClassifierOptions() {
    super.prepareClassifierOptions();
  }
}
