<div [formGroup]="form" [waiting]="loadingIndicatorService.isLoading$(this) | async" class="h-full">
  <icz-form-field class="grow" formControlName="withdrawReason" label="Důvod stažení" iczAutoFocus></icz-form-field>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="withdrawForCorrection()" primary label="Stáhnout k nápravě"></icz-button>
  <icz-button rightButtons [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
