/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ClassificationDto } from '../../models/classification-dto';
import { ClassificationUpdateDto } from '../../models/classification-update-dto';

export interface ClassificationUpdate$Params {

/**
 * Id of classification to be updated
 */
  id: number;
      body: ClassificationUpdateDto
}

export function classificationUpdate(http: HttpClient, rootUrl: string, params: ClassificationUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ClassificationDto>> {
  const rb = new RequestBuilder(rootUrl, classificationUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ClassificationDto>;
    })
  );
}

classificationUpdate.PATH = '/codebook/classifier/classification/{id}';