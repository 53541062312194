/* tslint:disable */
/* eslint-disable */

/**
 * Status of the BulkPostingForm. Worth mentioning this can differ from the status of all OwnPaperConsignments in this BulkPostingForm.
 */
export enum BulkPostingFormStatus {
  DISTRIBUTED = 'DISTRIBUTED',
  CANCELLED = 'CANCELLED',
  SETTLED = 'SETTLED',
  OPEN = 'OPEN'
}
