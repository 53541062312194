import {DestroyRef, inject, Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {ObjectClass} from '|api/commons';
import {HistoryService} from '../../../services/history.service';
import {areHistoryBitsSame, HistoryBit} from '../../../services/history.model';
import {arrayFindLast} from '../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface Breadcrumb {
  uri: Nullable<string>; // for uri === null it renders a non-clickable link
  name: string;
  isRerouteFromHistory: boolean;
  isAwaitingAlias: boolean;
  objectClass?: ObjectClass;
  nameParts?: string[];
}


@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  private historyService = inject(HistoryService);
  private destroyRef = inject(DestroyRef);

  private _breadcrumbs$ = new ReplaySubject<Breadcrumb[]>(1);
  breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor() {
    this.historyService.historyBits$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(historyBits => {
      const relevantHistory = this.getRelevantHistorySlice(historyBits);

      this.removeNavigationCycles(relevantHistory);
      this.removeChildrenOfCurrentBreadcrumb(relevantHistory);

      this._breadcrumbs$.next(
        relevantHistory.map(historyBit => ({
          uri: historyBit.url,
          name: historyBit.label,
          isRerouteFromHistory: historyBit.isRerouteFromHistory,
          isAwaitingAlias: historyBit.isAwaitingAlias,
          objectClass: historyBit.visitedObject?.objectClass,
          nameParts: historyBit.labelParts,
        })),
      );
    });
  }

  // mutates relevantHistory array
  private removeNavigationCycles(relevantHistory: HistoryBit[]) {
    for (let i = 0; i < relevantHistory.length; ++i) {
      const cycleEnd = arrayFindLast(relevantHistory, hb => hb !== relevantHistory[i] && areHistoryBitsSame(hb, relevantHistory[i]));

      if (cycleEnd) {
        const cycleEndIndex = relevantHistory.indexOf(cycleEnd);
        relevantHistory.splice(i, cycleEndIndex - i);
      }
    }
  }

  private getRelevantHistorySlice(historyBits: HistoryBit[]) {
    const lastRootHistoryItem = arrayFindLast(
      historyBits,
      hb => hb.isRootLevelRoute || hb.isRerouteFromHistory,
    );
    let lastRootHistoryItemIndex = 0;

    if (lastRootHistoryItem) {
      lastRootHistoryItemIndex = historyBits.indexOf(lastRootHistoryItem);
    }

    return historyBits.slice(lastRootHistoryItemIndex);
  }

  // Happens in admin sections - when navigating through the navigation tree,
  // the user can edit e.g. a security category nested deep in classification tree
  // and then return to a category he was not at before.
  private removeChildrenOfCurrentBreadcrumb(relevantHistory: HistoryBit[]) {
    if (relevantHistory.length < 2) {
      return;
    }

    for (let i = 1; i < relevantHistory.length; ++i) {
      const previousUri = relevantHistory[i - 1].url;
      const currentUri = relevantHistory[i].url;

      if (previousUri.includes(currentUri)) {
        relevantHistory.splice(i - 1, 1);
        --i;
      }
    }
  }
}
