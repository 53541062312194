import {Params} from '@angular/router';
import {add, addMinutes} from 'date-fns';
import {isObjectLike} from 'lodash';
import {Observable} from 'rxjs';
import {Time} from '../model';

export function addDays(date: Date, days: number): Date {
  date.setDate(date.getDate() + days);
  return date;
}

export function daysBetween(start: string, end: string): number {
  const d1 = new Date(start);
  const d2 = new Date(end);
  // @ts-ignore
  return Math.round((d2 - d1) / (1000 * 3600 * 24)); // convert ms to days
}

// Uses Object#is for comparison. Comparator could also access properties
// of compound types in case we need to check for duplicate objects.
export function hasDuplicates<T>(arr: T[], comparator: (arg0: T) => any = (x: T) => x): boolean {
  if (!arr || arr.length === 0) return false;

  return (new Set(arr.map(comparator))).size !== arr.length;
}

export function removeDuplicates<T>(arr: T[], comparator: (arg0: T) => any = (x: T) => x): T[] {
  return arr.filter((item, i) => {
    const comparedValue = comparator(item);
    const itemIndex = arr.findIndex(val => Object.is(comparator(val), comparedValue));

    return itemIndex === i; // evaluates to true on second and higher occurrence
  });
}

export function constructBulkModalTitle(title: string): string {
  return `${title} ({{count}})`;
}

export function firstLetterUppercase(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function isWindowsOs() {
  return (navigator.platform.indexOf('Win') !== -1);
}

export function isChrome() {
  return (navigator as any).userAgentData?.brands?.some((b: {brand: string}) => b.brand === 'Google Chrome');
}

export function isMsEdge() {
  return (navigator as any).userAgentData?.brands?.some((b: {brand: string}) => b.brand === 'Microsoft Edge');
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function getPreferredBrowserLanguage() {
  if (navigator.languages !== undefined) {
    // 0th item of navigator.languages is the most preferred of supplied locales...
    return navigator.languages[0];
  }
  else { // IE Fallback
    return navigator.language;
  }
}

export const isEnumValue = <T>(e: T) => (token: any): token is T[keyof T] =>
  Object.values(e as Record<string, unknown>).includes(token as T[keyof T]);

// eslint-disable-next-line @typescript-eslint/ban-types -- accepts generic object
export function hashed(obj: Nullable<object | string | number | boolean>): string {
  const str = JSON.stringify(obj === undefined ? '___undEfinEd__' : obj);
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash ? hash.toString(10) : '';
}

// eslint-disable-next-line @typescript-eslint/ban-types -- accepts generic object
export function stripUnwantedProps<T extends object, U>(object: T,
                                                        unwanted: Array<keyof T>,
                                                        config: { removeNull: boolean,
                                                          removeEmptyString: boolean } = {
                                                          removeNull: false,
                                                          removeEmptyString: false
                                                        }): U {
  unwanted.forEach(prop => {
    if (object.hasOwnProperty(prop)) delete object[prop];
  });
  for (const [key, value] of Object.entries(object)) {
    if (config.removeEmptyString && value === '') delete object[key as keyof T];
    if (config.removeNull && value === null) delete object[key as keyof T];
  }
  return object as unknown as U;
}

export function arrayMove<T>(arr: Array<Nullable<T>>, fromIndex: number, toIndex: number): T[] {
  const arrCopy = [...arr];

  if (toIndex >= arrCopy.length) {
    let k = toIndex - arrCopy.length + 1;
    while (k) {
      arrCopy.push(null);
      --k;
    }
  }

  arrCopy.splice(toIndex, 0, arrCopy.splice(fromIndex, 1)[0]);

  return arrCopy as T[];
}

export function replaceLast(haystack: string, needle: string, replacement: string): string {
  if (!haystack.includes(needle)) {
    return haystack;
  }
  else {
    const pcs = haystack.split(needle);
    const lastPc = pcs.pop();
    return pcs.join(needle) + replacement + lastPc;
  }
}

export function arrayRemove<T>(arr: T[], itemToRemove: T) {
  const index = arr.indexOf(itemToRemove);

  if (index > -1) {
    arr.splice(index, 1);
  }
}

export function isNilOrEmptyString(value: any): value is Nil | '' {
  return isNil(value) || value === '';
}

export function parseBoolean(value: Nullable<string>): boolean {
  return value === 'true';
}

export function isIsoDateString(s: any) {
  return typeof s === 'string' && (/\d{4}-\d{2}-\d{2}/).test(s);
}

export function isValidDate(d: any): d is Date {
  return d && d instanceof Date && !isNaN(Number(d));
}

export function isValidTime(t: any): t is Time {
  return (
    t &&
    'hours' in t &&
    'minutes' in t &&
    t.hours >= 0 && t.hours <= 23 &&
    t.minutes >= 0 && t.minutes <= 59
  );
}

export function serializeParamsToQueryString(params: Params, omitQuery?: boolean): string {
  if (Object.keys(params).length === 0) return '';

  let out = omitQuery ? '' : '?';

  const paramEntries = Object.entries(params);

  for (let i = 0; i < paramEntries.length; ++i) {
    const [k, v] = paramEntries[i];

    if (params.hasOwnProperty(k)) {
      out += `${k}=${v}`;

      if (i < paramEntries.length - 1) out += '&';
    }
  }

  return out;
}

export function validateJsonPassesReferenceObject<T>(selectedFile: T, referenceObj: T) {
  let valid = true;

  function checkValueIsDefined(val: any) {
    if (isObjectLike(val)) {
      for (const nestedKey in val) {
        if (val[nestedKey as any] === undefined) valid = false;
      }
    }
    else {
      if (val === undefined) valid = false;
    }
  }

  for (const key in referenceObj) {
    if ((referenceObj as Record<any, any>).hasOwnProperty(key)) {
      const val: any = selectedFile[key as keyof T];
      if (Array.isArray(val) && (referenceObj[key] as Array<any>).length > 0) {
        if ((val as Array<any>).length === 0) {
          valid = false;
        }
        val.forEach(arrayVal => {
          checkValueIsDefined(arrayVal);
        });
      }
      else {
        checkValueIsDefined(val);
      }
    }
  }
  return valid;
}

export function stripTimezoneFromDate(value: Nullable<Date>): Nullable<Date> {
  return isNil(value) ? value : addMinutes(value, value.getTimezoneOffset());
}

export function arrayFindLast<T>(arr: T[], findPredicate: (arrItem: T) => boolean): Nullable<T> {
  return [...arr].reverse().find(findPredicate);
}

export function pushOrCreateArray<T>(arr: Nullable<T[]>, value: any) {
  if (arr) {
    arr.push(value);
  } else {
    arr = [value];
  }
  return arr;
}

export function formatAsLocalIsoDate(date: Date | string) {
  // Performance hack - swedish has ISO-like official date formats,
  // no need to do any prior meddling or postprocessing.
  // Angular DatePipe has execution time 80 ms, this has execution time 3 ms.
  return new Date(date).toLocaleDateString('sv-SE');
}

export function formatAsLocalIsoDateTime(date: Date | string) {
  return new Date(date).toLocaleString('sv-SE');
}

export function getMidnightOfTheDay(date?: Date): Date {
  const out = date ? new Date(date) : new Date();
  out.setHours(0);
  out.setMinutes(0);
  out.setSeconds(0);
  out.setMilliseconds(0);

  return out;
}

/**
 * Creates time in hh:mm format, that is suitable for time picker component.
 * @param date
 */
export function formatAsTimePickerTime(date: Date): string {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

/**
 * Creates date in yyyy-mm-dd format, that is suitable for date picker component.
 * @param date
 */
export function formatAsDatePickerDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Combines date in yyyy-mm-dd format and time in hh:mm format to Date object
 * @param dateString
 * @param timeString
 */
export function combineDateTime (dateString: string, timeString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);
  const [hours, minutes] = timeString.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes);
}

export function getTodayMidnight(): Date {
  return getMidnightOfTheDay();
}

export function getTomorrowMidnight(): Date {
  let out = new Date();
  out = add(out, {days: 1});
  out.setHours(0);
  out.setMinutes(0);
  out.setSeconds(0);
  out.setMilliseconds(0);

  return out;
}

export function fileToBase64(file: File): Observable<string> {
  return new Observable(subscriber => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      subscriber.next(reader.result as string);
      subscriber.complete();
    };
    reader.onerror = function (error) {
      subscriber.error(error);
    };
  });
}

export function deepFreeze(object: Record<string, any>) {
  const propNames = Object.getOwnPropertyNames(object);

  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

export function stripQueryStringFromUrl(url: string) {
  return url.replace(/\?.+$/, '');
}
