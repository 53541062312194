/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DataBoxNodeDto } from '../models/data-box-node-dto';
import { EmailNodeDto } from '../models/email-node-dto';
import { EmailUploadNodeDto } from '../models/email-upload-node-dto';
import { PageEpdzErrorDto } from '../models/page-epdz-error-dto';
import { PageReceivedDigitalConsignmentDto } from '../models/page-received-digital-consignment-dto';
import { PageReceivedEpdzMessageDto } from '../models/page-received-epdz-message-dto';
import { PageReceivedInternalDigitalConsignmentDto } from '../models/page-received-internal-digital-consignment-dto';
import { PageReceivedPortalConsignmentDto } from '../models/page-received-portal-consignment-dto';
import { ReceivedDigitalConsignmentBaseDto } from '../models/received-digital-consignment-base-dto';
import { receivedDigitalConsignmentBulkPerformActionOverEpdzError } from '../fn/received-digital-consignment/received-digital-consignment-bulk-perform-action-over-epdz-error';
import { ReceivedDigitalConsignmentBulkPerformActionOverEpdzError$Params } from '../fn/received-digital-consignment/received-digital-consignment-bulk-perform-action-over-epdz-error';
import { receivedDigitalConsignmentCountEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-count-epdz-message';
import { receivedDigitalConsignmentCountEpdzMessage_1 } from '../fn/received-digital-consignment/received-digital-consignment-count-epdz-message-1';
import { ReceivedDigitalConsignmentCountEpdzMessage_1$Params } from '../fn/received-digital-consignment/received-digital-consignment-count-epdz-message-1';
import { ReceivedDigitalConsignmentCountEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-count-epdz-message';
import { receivedDigitalConsignmentCountErrors } from '../fn/received-digital-consignment/received-digital-consignment-count-errors';
import { ReceivedDigitalConsignmentCountErrors$Params } from '../fn/received-digital-consignment/received-digital-consignment-count-errors';
import { receivedDigitalConsignmentCountMessageInStates } from '../fn/received-digital-consignment/received-digital-consignment-count-message-in-states';
import { receivedDigitalConsignmentCountMessageInStates_1 } from '../fn/received-digital-consignment/received-digital-consignment-count-message-in-states-1';
import { ReceivedDigitalConsignmentCountMessageInStates_1$Params } from '../fn/received-digital-consignment/received-digital-consignment-count-message-in-states-1';
import { ReceivedDigitalConsignmentCountMessageInStates$Params } from '../fn/received-digital-consignment/received-digital-consignment-count-message-in-states';
import { receivedDigitalConsignmentCreateEmailConsignment } from '../fn/received-digital-consignment/received-digital-consignment-create-email-consignment';
import { ReceivedDigitalConsignmentCreateEmailConsignment$Params } from '../fn/received-digital-consignment/received-digital-consignment-create-email-consignment';
import { receivedDigitalConsignmentCreateProofOfDelivery } from '../fn/received-digital-consignment/received-digital-consignment-create-proof-of-delivery';
import { ReceivedDigitalConsignmentCreateProofOfDelivery$Params } from '../fn/received-digital-consignment/received-digital-consignment-create-proof-of-delivery';
import { receivedDigitalConsignmentDiscardDefectiveEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-discard-defective-epdz-message';
import { ReceivedDigitalConsignmentDiscardDefectiveEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-discard-defective-epdz-message';
import { receivedDigitalConsignmentDownloadEpdzAttachmentContent } from '../fn/received-digital-consignment/received-digital-consignment-download-epdz-attachment-content';
import { ReceivedDigitalConsignmentDownloadEpdzAttachmentContent$Params } from '../fn/received-digital-consignment/received-digital-consignment-download-epdz-attachment-content';
import { ReceivedDigitalConsignmentDto } from '../models/received-digital-consignment-dto';
import { receivedDigitalConsignmentFindById } from '../fn/received-digital-consignment/received-digital-consignment-find-by-id';
import { ReceivedDigitalConsignmentFindById$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-by-id';
import { receivedDigitalConsignmentFindByIdInternal } from '../fn/received-digital-consignment/received-digital-consignment-find-by-id-internal';
import { ReceivedDigitalConsignmentFindByIdInternal$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-by-id-internal';
import { receivedDigitalConsignmentFindConsignmentsForDocument } from '../fn/received-digital-consignment/received-digital-consignment-find-consignments-for-document';
import { ReceivedDigitalConsignmentFindConsignmentsForDocument$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-consignments-for-document';
import { receivedDigitalConsignmentFindContainerContents } from '../fn/received-digital-consignment/received-digital-consignment-find-container-contents';
import { ReceivedDigitalConsignmentFindContainerContents$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-container-contents';
import { receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition } from '../fn/received-digital-consignment/received-digital-consignment-find-data-box-nodes-for-functional-position';
import { ReceivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-data-box-nodes-for-functional-position';
import { receivedDigitalConsignmentFindEmailNodesForFunctionalPosition } from '../fn/received-digital-consignment/received-digital-consignment-find-email-nodes-for-functional-position';
import { ReceivedDigitalConsignmentFindEmailNodesForFunctionalPosition$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-email-nodes-for-functional-position';
import { receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition } from '../fn/received-digital-consignment/received-digital-consignment-find-email-upload-nodes-for-functional-position';
import { ReceivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-email-upload-nodes-for-functional-position';
import { receivedDigitalConsignmentFindEpdzError } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-error';
import { ReceivedDigitalConsignmentFindEpdzError$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-error';
import { receivedDigitalConsignmentFindEpdzErrorDistributionNode } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-error-distribution-node';
import { ReceivedDigitalConsignmentFindEpdzErrorDistributionNode$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-error-distribution-node';
import { receivedDigitalConsignmentFindEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-message';
import { receivedDigitalConsignmentFindEpdzMessage_1 } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-message-1';
import { ReceivedDigitalConsignmentFindEpdzMessage_1$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-message-1';
import { ReceivedDigitalConsignmentFindEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-message';
import { receivedDigitalConsignmentFindEpdzMessageById } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-message-by-id';
import { ReceivedDigitalConsignmentFindEpdzMessageById$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-epdz-message-by-id';
import { receivedDigitalConsignmentFindInternalConsignmentsForDocument } from '../fn/received-digital-consignment/received-digital-consignment-find-internal-consignments-for-document';
import { ReceivedDigitalConsignmentFindInternalConsignmentsForDocument$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-internal-consignments-for-document';
import { receivedDigitalConsignmentFindPortalConsignmentsForDocument } from '../fn/received-digital-consignment/received-digital-consignment-find-portal-consignments-for-document';
import { ReceivedDigitalConsignmentFindPortalConsignmentsForDocument$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-portal-consignments-for-document';
import { receivedDigitalConsignmentFindSheetNodesForFunctionalPosition } from '../fn/received-digital-consignment/received-digital-consignment-find-sheet-nodes-for-functional-position';
import { ReceivedDigitalConsignmentFindSheetNodesForFunctionalPosition$Params } from '../fn/received-digital-consignment/received-digital-consignment-find-sheet-nodes-for-functional-position';
import { receivedDigitalConsignmentForwardDefectiveEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-forward-defective-epdz-message';
import { ReceivedDigitalConsignmentForwardDefectiveEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-forward-defective-epdz-message';
import { receivedDigitalConsignmentLockEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-lock-epdz-message';
import { ReceivedDigitalConsignmentLockEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-lock-epdz-message';
import { receivedDigitalConsignmentPerformActionOverEpdzError } from '../fn/received-digital-consignment/received-digital-consignment-perform-action-over-epdz-error';
import { ReceivedDigitalConsignmentPerformActionOverEpdzError$Params } from '../fn/received-digital-consignment/received-digital-consignment-perform-action-over-epdz-error';
import { receivedDigitalConsignmentProcessInternal } from '../fn/received-digital-consignment/received-digital-consignment-process-internal';
import { ReceivedDigitalConsignmentProcessInternal$Params } from '../fn/received-digital-consignment/received-digital-consignment-process-internal';
import { receivedDigitalConsignmentProcessReceivedFilingOffice } from '../fn/received-digital-consignment/received-digital-consignment-process-received-filing-office';
import { ReceivedDigitalConsignmentProcessReceivedFilingOffice$Params } from '../fn/received-digital-consignment/received-digital-consignment-process-received-filing-office';
import { receivedDigitalConsignmentProcessReceivedSingle } from '../fn/received-digital-consignment/received-digital-consignment-process-received-single';
import { ReceivedDigitalConsignmentProcessReceivedSingle$Params } from '../fn/received-digital-consignment/received-digital-consignment-process-received-single';
import { receivedDigitalConsignmentRegister } from '../fn/received-digital-consignment/received-digital-consignment-register';
import { ReceivedDigitalConsignmentRegister$Params } from '../fn/received-digital-consignment/received-digital-consignment-register';
import { receivedDigitalConsignmentRejectDefectiveEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-reject-defective-epdz-message';
import { ReceivedDigitalConsignmentRejectDefectiveEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-reject-defective-epdz-message';
import { receivedDigitalConsignmentUnlockEpdzMessage } from '../fn/received-digital-consignment/received-digital-consignment-unlock-epdz-message';
import { ReceivedDigitalConsignmentUnlockEpdzMessage$Params } from '../fn/received-digital-consignment/received-digital-consignment-unlock-epdz-message';
import { receivedDigitalConsignmentUpdate } from '../fn/received-digital-consignment/received-digital-consignment-update';
import { ReceivedDigitalConsignmentUpdate$Params } from '../fn/received-digital-consignment/received-digital-consignment-update';
import { ReceivedEpdzAttachmentDto } from '../models/received-epdz-attachment-dto';
import { ReceivedEpdzMessageCountDto } from '../models/received-epdz-message-count-dto';
import { ReceivedEpdzMessageDetailDto } from '../models/received-epdz-message-detail-dto';
import { ReceivedInternalDigitalConsignmentDto } from '../models/received-internal-digital-consignment-dto';
import { SheetNodeDto } from '../models/sheet-node-dto';


/**
 * API for management of received digital consignment
 */
@Injectable({ providedIn: 'root' })
export class ApiReceivedDigitalConsignmentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `receivedDigitalConsignmentFindById()` */
  static readonly ReceivedDigitalConsignmentFindByIdPath = '/sad/received-digital-consignment/{id}';

  /**
   * Find received digital consignment by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindById$Response(params: ReceivedDigitalConsignmentFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedDigitalConsignmentBaseDto>> {
    return receivedDigitalConsignmentFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find received digital consignment by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindById(params: ReceivedDigitalConsignmentFindById$Params, context?: HttpContext): Observable<ReceivedDigitalConsignmentBaseDto> {
    return this.receivedDigitalConsignmentFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedDigitalConsignmentBaseDto>): ReceivedDigitalConsignmentBaseDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentUpdate()` */
  static readonly ReceivedDigitalConsignmentUpdatePath = '/sad/received-digital-consignment/{id}';

  /**
   * Update received digital consignment.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentUpdate$Response(params: ReceivedDigitalConsignmentUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedDigitalConsignmentDto>> {
    return receivedDigitalConsignmentUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update received digital consignment.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentUpdate(params: ReceivedDigitalConsignmentUpdate$Params, context?: HttpContext): Observable<ReceivedDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedDigitalConsignmentDto>): ReceivedDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentProcessReceivedSingle()` */
  static readonly ReceivedDigitalConsignmentProcessReceivedSinglePath = '/sad/received-digital-consignment/receive-officer';

  /**
   * Process the received digital consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentProcessReceivedSingle()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentProcessReceivedSingle$Response(params: ReceivedDigitalConsignmentProcessReceivedSingle$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedDigitalConsignmentDto>> {
    return receivedDigitalConsignmentProcessReceivedSingle(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received digital consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentProcessReceivedSingle$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentProcessReceivedSingle(params: ReceivedDigitalConsignmentProcessReceivedSingle$Params, context?: HttpContext): Observable<ReceivedDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentProcessReceivedSingle$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedDigitalConsignmentDto>): ReceivedDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentProcessReceivedFilingOffice()` */
  static readonly ReceivedDigitalConsignmentProcessReceivedFilingOfficePath = '/sad/received-digital-consignment/receive-filing-office';

  /**
   * Process the received digital consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentProcessReceivedFilingOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentProcessReceivedFilingOffice$Response(params: ReceivedDigitalConsignmentProcessReceivedFilingOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedDigitalConsignmentDto>> {
    return receivedDigitalConsignmentProcessReceivedFilingOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received digital consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentProcessReceivedFilingOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentProcessReceivedFilingOffice(params: ReceivedDigitalConsignmentProcessReceivedFilingOffice$Params, context?: HttpContext): Observable<ReceivedDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentProcessReceivedFilingOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedDigitalConsignmentDto>): ReceivedDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentProcessInternal()` */
  static readonly ReceivedDigitalConsignmentProcessInternalPath = '/sad/received-digital-consignment/internal';

  /**
   * Process the received internal digital consignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentProcessInternal()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentProcessInternal$Response(params: ReceivedDigitalConsignmentProcessInternal$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedInternalDigitalConsignmentDto>> {
    return receivedDigitalConsignmentProcessInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * Process the received internal digital consignment
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentProcessInternal$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentProcessInternal(params: ReceivedDigitalConsignmentProcessInternal$Params, context?: HttpContext): Observable<ReceivedInternalDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentProcessInternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedInternalDigitalConsignmentDto>): ReceivedInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCreateProofOfDelivery()` */
  static readonly ReceivedDigitalConsignmentCreateProofOfDeliveryPath = '/sad/received-digital-consignment/internal/proof-of-delivery/{receivedInternalMessageId}/{receivedDocumentId}';

  /**
   * Creates proof of delivery for received internal message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCreateProofOfDelivery()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCreateProofOfDelivery$Response(params: ReceivedDigitalConsignmentCreateProofOfDelivery$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentCreateProofOfDelivery(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates proof of delivery for received internal message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCreateProofOfDelivery$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCreateProofOfDelivery(params: ReceivedDigitalConsignmentCreateProofOfDelivery$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentCreateProofOfDelivery$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCreateEmailConsignment()` */
  static readonly ReceivedDigitalConsignmentCreateEmailConsignmentPath = '/sad/received-digital-consignment/create-email-consignment';

  /**
   * Creates received email consignments from uploaded eml a msg files.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCreateEmailConsignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentCreateEmailConsignment$Response(params: ReceivedDigitalConsignmentCreateEmailConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentCreateEmailConsignment(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates received email consignments from uploaded eml a msg files.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCreateEmailConsignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentCreateEmailConsignment(params: ReceivedDigitalConsignmentCreateEmailConsignment$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentCreateEmailConsignment$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentRejectDefectiveEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentRejectDefectiveEpdzMessagePath = '/sad/epdz/{id}/reject';

  /**
   * EPDZ Message Rejection.
   *
   * Rejects defective EPDZ message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentRejectDefectiveEpdzMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentRejectDefectiveEpdzMessage$Response(params: ReceivedDigitalConsignmentRejectDefectiveEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentRejectDefectiveEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * EPDZ Message Rejection.
   *
   * Rejects defective EPDZ message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentRejectDefectiveEpdzMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentRejectDefectiveEpdzMessage(params: ReceivedDigitalConsignmentRejectDefectiveEpdzMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentRejectDefectiveEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentForwardDefectiveEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentForwardDefectiveEpdzMessagePath = '/sad/epdz/{id}/forward';

  /**
   * EPDZ Message Forwarding.
   *
   * forwards defective EPDZ message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentForwardDefectiveEpdzMessage()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentForwardDefectiveEpdzMessage$Response(params: ReceivedDigitalConsignmentForwardDefectiveEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentForwardDefectiveEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * EPDZ Message Forwarding.
   *
   * forwards defective EPDZ message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentForwardDefectiveEpdzMessage$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentForwardDefectiveEpdzMessage(params: ReceivedDigitalConsignmentForwardDefectiveEpdzMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentForwardDefectiveEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentDiscardDefectiveEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentDiscardDefectiveEpdzMessagePath = '/sad/epdz/{id}/discard';

  /**
   * EPDZ Message Discarding.
   *
   * Discards defective EPDZ message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentDiscardDefectiveEpdzMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentDiscardDefectiveEpdzMessage$Response(params: ReceivedDigitalConsignmentDiscardDefectiveEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentDiscardDefectiveEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * EPDZ Message Discarding.
   *
   * Discards defective EPDZ message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentDiscardDefectiveEpdzMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentDiscardDefectiveEpdzMessage(params: ReceivedDigitalConsignmentDiscardDefectiveEpdzMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentDiscardDefectiveEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentRegister()` */
  static readonly ReceivedDigitalConsignmentRegisterPath = '/sad/epdz/register';

  /**
   * Register EPDZ event
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentRegister()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentRegister$Response(params: ReceivedDigitalConsignmentRegister$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentRegister(this.http, this.rootUrl, params, context);
  }

  /**
   * Register EPDZ event
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentRegister$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentRegister(params: ReceivedDigitalConsignmentRegister$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentRegister$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentPerformActionOverEpdzError()` */
  static readonly ReceivedDigitalConsignmentPerformActionOverEpdzErrorPath = '/sad/epdz/error/{epdzErrorId}/{action}';

  /**
   * Perform specific action over Epdz error message
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentPerformActionOverEpdzError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentPerformActionOverEpdzError$Response(params: ReceivedDigitalConsignmentPerformActionOverEpdzError$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentPerformActionOverEpdzError(this.http, this.rootUrl, params, context);
  }

  /**
   * Perform specific action over Epdz error message
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentPerformActionOverEpdzError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentPerformActionOverEpdzError(params: ReceivedDigitalConsignmentPerformActionOverEpdzError$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentPerformActionOverEpdzError$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentBulkPerformActionOverEpdzError()` */
  static readonly ReceivedDigitalConsignmentBulkPerformActionOverEpdzErrorPath = '/sad/epdz/bulk-error-action';

  /**
   * Bulk perform specific action over Epdz error messages.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentBulkPerformActionOverEpdzError()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentBulkPerformActionOverEpdzError$Response(params: ReceivedDigitalConsignmentBulkPerformActionOverEpdzError$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentBulkPerformActionOverEpdzError(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk perform specific action over Epdz error messages.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentBulkPerformActionOverEpdzError$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  receivedDigitalConsignmentBulkPerformActionOverEpdzError(params: ReceivedDigitalConsignmentBulkPerformActionOverEpdzError$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentBulkPerformActionOverEpdzError$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindSheetNodesForFunctionalPosition()` */
  static readonly ReceivedDigitalConsignmentFindSheetNodesForFunctionalPositionPath = '/sad/received-digital-consignment/sheet-nodes';

  /**
   * Finds sheet nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindSheetNodesForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindSheetNodesForFunctionalPosition$Response(params?: ReceivedDigitalConsignmentFindSheetNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SheetNodeDto>>> {
    return receivedDigitalConsignmentFindSheetNodesForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds sheet nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindSheetNodesForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindSheetNodesForFunctionalPosition(params?: ReceivedDigitalConsignmentFindSheetNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<Array<SheetNodeDto>> {
    return this.receivedDigitalConsignmentFindSheetNodesForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SheetNodeDto>>): Array<SheetNodeDto> => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindPortalConsignmentsForDocument()` */
  static readonly ReceivedDigitalConsignmentFindPortalConsignmentsForDocumentPath = '/sad/received-digital-consignment/portal/by-document/{id}';

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindPortalConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindPortalConsignmentsForDocument$Response(params: ReceivedDigitalConsignmentFindPortalConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedPortalConsignmentDto>> {
    return receivedDigitalConsignmentFindPortalConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindPortalConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindPortalConsignmentsForDocument(params: ReceivedDigitalConsignmentFindPortalConsignmentsForDocument$Params, context?: HttpContext): Observable<PageReceivedPortalConsignmentDto> {
    return this.receivedDigitalConsignmentFindPortalConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedPortalConsignmentDto>): PageReceivedPortalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindByIdInternal()` */
  static readonly ReceivedDigitalConsignmentFindByIdInternalPath = '/sad/received-digital-consignment/internal/{id}';

  /**
   * Find received internal digital consignment by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindByIdInternal()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindByIdInternal$Response(params: ReceivedDigitalConsignmentFindByIdInternal$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedInternalDigitalConsignmentDto>> {
    return receivedDigitalConsignmentFindByIdInternal(this.http, this.rootUrl, params, context);
  }

  /**
   * Find received internal digital consignment by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindByIdInternal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindByIdInternal(params: ReceivedDigitalConsignmentFindByIdInternal$Params, context?: HttpContext): Observable<ReceivedInternalDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentFindByIdInternal$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedInternalDigitalConsignmentDto>): ReceivedInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindInternalConsignmentsForDocument()` */
  static readonly ReceivedDigitalConsignmentFindInternalConsignmentsForDocumentPath = '/sad/received-digital-consignment/internal/by-document/{id}';

  /**
   * Search Internal Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindInternalConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindInternalConsignmentsForDocument$Response(params: ReceivedDigitalConsignmentFindInternalConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedInternalDigitalConsignmentDto>> {
    return receivedDigitalConsignmentFindInternalConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Internal Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindInternalConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindInternalConsignmentsForDocument(params: ReceivedDigitalConsignmentFindInternalConsignmentsForDocument$Params, context?: HttpContext): Observable<PageReceivedInternalDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentFindInternalConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedInternalDigitalConsignmentDto>): PageReceivedInternalDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition()` */
  static readonly ReceivedDigitalConsignmentFindEmailUploadNodesForFunctionalPositionPath = '/sad/received-digital-consignment/email-upload-nodes';

  /**
   * Finds email upload nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition$Response(params?: ReceivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EmailUploadNodeDto>>> {
    return receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds email upload nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition(params?: ReceivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<Array<EmailUploadNodeDto>> {
    return this.receivedDigitalConsignmentFindEmailUploadNodesForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailUploadNodeDto>>): Array<EmailUploadNodeDto> => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEmailNodesForFunctionalPosition()` */
  static readonly ReceivedDigitalConsignmentFindEmailNodesForFunctionalPositionPath = '/sad/received-digital-consignment/email-nodes';

  /**
   * Finds email nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEmailNodesForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEmailNodesForFunctionalPosition$Response(params?: ReceivedDigitalConsignmentFindEmailNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EmailNodeDto>>> {
    return receivedDigitalConsignmentFindEmailNodesForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds email nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEmailNodesForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEmailNodesForFunctionalPosition(params?: ReceivedDigitalConsignmentFindEmailNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<Array<EmailNodeDto>> {
    return this.receivedDigitalConsignmentFindEmailNodesForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailNodeDto>>): Array<EmailNodeDto> => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition()` */
  static readonly ReceivedDigitalConsignmentFindDataBoxNodesForFunctionalPositionPath = '/sad/received-digital-consignment/data-box-nodes';

  /**
   * Finds data box nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition$Response(params?: ReceivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<DataBoxNodeDto>>> {
    return receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds data box nodes available for an authorised functional position (the position is taken from the OAuth token).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition(params?: ReceivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition$Params|null|undefined, context?: HttpContext): Observable<Array<DataBoxNodeDto>> {
    return this.receivedDigitalConsignmentFindDataBoxNodesForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<DataBoxNodeDto>>): Array<DataBoxNodeDto> => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindConsignmentsForDocument()` */
  static readonly ReceivedDigitalConsignmentFindConsignmentsForDocumentPath = '/sad/received-digital-consignment/by-document/{id}';

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindConsignmentsForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindConsignmentsForDocument$Response(params: ReceivedDigitalConsignmentFindConsignmentsForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedDigitalConsignmentDto>> {
    return receivedDigitalConsignmentFindConsignmentsForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Consignments For Document.
   *
   * Returns all Consignments, be they paper or digital, for a given document. Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindConsignmentsForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindConsignmentsForDocument(params: ReceivedDigitalConsignmentFindConsignmentsForDocument$Params, context?: HttpContext): Observable<PageReceivedDigitalConsignmentDto> {
    return this.receivedDigitalConsignmentFindConsignmentsForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedDigitalConsignmentDto>): PageReceivedDigitalConsignmentDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEpdzMessageById()` */
  static readonly ReceivedDigitalConsignmentFindEpdzMessageByIdPath = '/sad/epdz/{messageId}';

  /**
   * Find received EPDZ (data) messages by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEpdzMessageById()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzMessageById$Response(params: ReceivedDigitalConsignmentFindEpdzMessageById$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedEpdzMessageDetailDto>> {
    return receivedDigitalConsignmentFindEpdzMessageById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find received EPDZ (data) messages by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEpdzMessageById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzMessageById(params: ReceivedDigitalConsignmentFindEpdzMessageById$Params, context?: HttpContext): Observable<ReceivedEpdzMessageDetailDto> {
    return this.receivedDigitalConsignmentFindEpdzMessageById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedEpdzMessageDetailDto>): ReceivedEpdzMessageDetailDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentFindEpdzMessagePath = '/sad/epdz/{distributionNodeId}/search-api';

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEpdzMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzMessage$Response(params: ReceivedDigitalConsignmentFindEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedEpdzMessageDto>> {
    return receivedDigitalConsignmentFindEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEpdzMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzMessage(params: ReceivedDigitalConsignmentFindEpdzMessage$Params, context?: HttpContext): Observable<PageReceivedEpdzMessageDto> {
    return this.receivedDigitalConsignmentFindEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedEpdzMessageDto>): PageReceivedEpdzMessageDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCountEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentCountEpdzMessagePath = '/sad/epdz/{distributionNodeId}/count';

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCountEpdzMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountEpdzMessage$Response(params: ReceivedDigitalConsignmentCountEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return receivedDigitalConsignmentCountEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCountEpdzMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountEpdzMessage(params: ReceivedDigitalConsignmentCountEpdzMessage$Params, context?: HttpContext): Observable<number> {
    return this.receivedDigitalConsignmentCountEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCountMessageInStates()` */
  static readonly ReceivedDigitalConsignmentCountMessageInStatesPath = '/sad/epdz/{distributionNodeId}/count/states';

  /**
   * Find all received EPDZ (data) messages in different states
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCountMessageInStates()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountMessageInStates$Response(params: ReceivedDigitalConsignmentCountMessageInStates$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedEpdzMessageCountDto>> {
    return receivedDigitalConsignmentCountMessageInStates(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received EPDZ (data) messages in different states
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCountMessageInStates$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountMessageInStates(params: ReceivedDigitalConsignmentCountMessageInStates$Params, context?: HttpContext): Observable<ReceivedEpdzMessageCountDto> {
    return this.receivedDigitalConsignmentCountMessageInStates$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedEpdzMessageCountDto>): ReceivedEpdzMessageCountDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentDownloadEpdzAttachmentContent()` */
  static readonly ReceivedDigitalConsignmentDownloadEpdzAttachmentContentPath = '/sad/epdz/{attachmentId}/download';

  /**
   * Download specific EPDZ attachment by attachment id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentDownloadEpdzAttachmentContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentDownloadEpdzAttachmentContent$Response(params: ReceivedDigitalConsignmentDownloadEpdzAttachmentContent$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentDownloadEpdzAttachmentContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Download specific EPDZ attachment by attachment id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentDownloadEpdzAttachmentContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentDownloadEpdzAttachmentContent(params: ReceivedDigitalConsignmentDownloadEpdzAttachmentContent$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentDownloadEpdzAttachmentContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindContainerContents()` */
  static readonly ReceivedDigitalConsignmentFindContainerContentsPath = '/sad/epdz/{attachmentId}/container-contents';

  /**
   * Find all attachments in given received EPDZ container
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindContainerContents()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindContainerContents$Response(params: ReceivedDigitalConsignmentFindContainerContents$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ReceivedEpdzAttachmentDto>>> {
    return receivedDigitalConsignmentFindContainerContents(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all attachments in given received EPDZ container
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindContainerContents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindContainerContents(params: ReceivedDigitalConsignmentFindContainerContents$Params, context?: HttpContext): Observable<Array<ReceivedEpdzAttachmentDto>> {
    return this.receivedDigitalConsignmentFindContainerContents$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ReceivedEpdzAttachmentDto>>): Array<ReceivedEpdzAttachmentDto> => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentUnlockEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentUnlockEpdzMessagePath = '/sad/epdz/unlock-epdz-message/{messageId}';

  /**
   * Unlock EPDZ Message.
   *
   * Unlocks EPDZ message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentUnlockEpdzMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentUnlockEpdzMessage$Response(params: ReceivedDigitalConsignmentUnlockEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentUnlockEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlock EPDZ Message.
   *
   * Unlocks EPDZ message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentUnlockEpdzMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentUnlockEpdzMessage(params: ReceivedDigitalConsignmentUnlockEpdzMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentUnlockEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEpdzMessage_1()` */
  static readonly ReceivedDigitalConsignmentFindEpdzMessage_1Path = '/sad/epdz/search-api';

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEpdzMessage_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzMessage_1$Response(params?: ReceivedDigitalConsignmentFindEpdzMessage_1$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageReceivedEpdzMessageDto>> {
    return receivedDigitalConsignmentFindEpdzMessage_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEpdzMessage_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzMessage_1(params?: ReceivedDigitalConsignmentFindEpdzMessage_1$Params|null|undefined, context?: HttpContext): Observable<PageReceivedEpdzMessageDto> {
    return this.receivedDigitalConsignmentFindEpdzMessage_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageReceivedEpdzMessageDto>): PageReceivedEpdzMessageDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentLockEpdzMessage()` */
  static readonly ReceivedDigitalConsignmentLockEpdzMessagePath = '/sad/epdz/lock-epdz-message/{messageId}';

  /**
   * Lock EPDZ Message.
   *
   * Locks EPDZ message.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentLockEpdzMessage()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentLockEpdzMessage$Response(params: ReceivedDigitalConsignmentLockEpdzMessage$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return receivedDigitalConsignmentLockEpdzMessage(this.http, this.rootUrl, params, context);
  }

  /**
   * Lock EPDZ Message.
   *
   * Locks EPDZ message.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentLockEpdzMessage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentLockEpdzMessage(params: ReceivedDigitalConsignmentLockEpdzMessage$Params, context?: HttpContext): Observable<void> {
    return this.receivedDigitalConsignmentLockEpdzMessage$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEpdzErrorDistributionNode()` */
  static readonly ReceivedDigitalConsignmentFindEpdzErrorDistributionNodePath = '/sad/epdz/errors/{distributionNodeId}/search-api';

  /**
   * Find all EPDZ errors based on criteria in request for particular distribution node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEpdzErrorDistributionNode()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzErrorDistributionNode$Response(params: ReceivedDigitalConsignmentFindEpdzErrorDistributionNode$Params, context?: HttpContext): Observable<StrictHttpResponse<PageEpdzErrorDto>> {
    return receivedDigitalConsignmentFindEpdzErrorDistributionNode(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all EPDZ errors based on criteria in request for particular distribution node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEpdzErrorDistributionNode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzErrorDistributionNode(params: ReceivedDigitalConsignmentFindEpdzErrorDistributionNode$Params, context?: HttpContext): Observable<PageEpdzErrorDto> {
    return this.receivedDigitalConsignmentFindEpdzErrorDistributionNode$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEpdzErrorDto>): PageEpdzErrorDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentFindEpdzError()` */
  static readonly ReceivedDigitalConsignmentFindEpdzErrorPath = '/sad/epdz/errors/search-api';

  /**
   * Find all EPDZ errors based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentFindEpdzError()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzError$Response(params?: ReceivedDigitalConsignmentFindEpdzError$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEpdzErrorDto>> {
    return receivedDigitalConsignmentFindEpdzError(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all EPDZ errors based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentFindEpdzError$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentFindEpdzError(params?: ReceivedDigitalConsignmentFindEpdzError$Params|null|undefined, context?: HttpContext): Observable<PageEpdzErrorDto> {
    return this.receivedDigitalConsignmentFindEpdzError$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEpdzErrorDto>): PageEpdzErrorDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCountErrors()` */
  static readonly ReceivedDigitalConsignmentCountErrorsPath = '/sad/epdz/errors/count/{messageType}';

  /**
   * Counts EPDZ errors based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCountErrors()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountErrors$Response(params: ReceivedDigitalConsignmentCountErrors$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return receivedDigitalConsignmentCountErrors(this.http, this.rootUrl, params, context);
  }

  /**
   * Counts EPDZ errors based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCountErrors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountErrors(params: ReceivedDigitalConsignmentCountErrors$Params, context?: HttpContext): Observable<number> {
    return this.receivedDigitalConsignmentCountErrors$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCountMessageInStates_1()` */
  static readonly ReceivedDigitalConsignmentCountMessageInStates_1Path = '/sad/epdz/count/states';

  /**
   * Find all received EPDZ (data) messages in different states
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCountMessageInStates_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountMessageInStates_1$Response(params?: ReceivedDigitalConsignmentCountMessageInStates_1$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<ReceivedEpdzMessageCountDto>> {
    return receivedDigitalConsignmentCountMessageInStates_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received EPDZ (data) messages in different states
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCountMessageInStates_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountMessageInStates_1(params?: ReceivedDigitalConsignmentCountMessageInStates_1$Params|null|undefined, context?: HttpContext): Observable<ReceivedEpdzMessageCountDto> {
    return this.receivedDigitalConsignmentCountMessageInStates_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedEpdzMessageCountDto>): ReceivedEpdzMessageCountDto => r.body)
    );
  }

  /** Path part for operation `receivedDigitalConsignmentCountEpdzMessage_1()` */
  static readonly ReceivedDigitalConsignmentCountEpdzMessage_1Path = '/sad/epdz/count/';

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `receivedDigitalConsignmentCountEpdzMessage_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountEpdzMessage_1$Response(params?: ReceivedDigitalConsignmentCountEpdzMessage_1$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return receivedDigitalConsignmentCountEpdzMessage_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all received EPDZ (data) messages based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `receivedDigitalConsignmentCountEpdzMessage_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  receivedDigitalConsignmentCountEpdzMessage_1(params?: ReceivedDigitalConsignmentCountEpdzMessage_1$Params|null|undefined, context?: HttpContext): Observable<number> {
    return this.receivedDigitalConsignmentCountEpdzMessage_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
