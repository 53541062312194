// not available in Swagger specs, AccessTokenData and RefreshTokenData typings are based on API responses...
export interface AccessTokenData {
  user_name: string;
  functional_position_id: Nullable<number>;
  organization_code: string;
  client_id: string;
  functional_position_essl_roles: string[];
  user_id: number;
  refresh_expires_in: number;
  surname: string;
  scope: string[];
  organization_id: number;
  exp: number;
  functional_position: string;
  first_name: string;
  jti: string;
  email: string;
}

export interface RefreshTokenData {
  functional_position_id: number;
  user_name: string;
  organization_code: string;
  client_id: string;
  functional_position_essl_roles: string[];
  user_id: number;
  refresh_expires_in: number;
  surname: string;
  scope: string[];
  organization_id: string;
  ati: string;
  exp: number;
  functional_position: string;
  first_name: string;
  jti: string;
  email: string;
}

export interface TokenBase {
  access_token: string;
  refresh_token: string;
}

export interface TokenApiResponse extends TokenBase {}

export interface FrontendToken extends TokenBase {
  access_token_data: AccessTokenData;
  refresh_token_data: RefreshTokenData;
}

export class AuthToken implements FrontendToken {
  access_token_data!: AccessTokenData;
  access_token!: string;
  refresh_token_data!: RefreshTokenData;
  refresh_token!: string;

  get expires(): Date {
    return new Date(this.access_token_data.exp * 1000);
  }

  get refresh_expires(): Date {
    return new Date(this.refresh_token_data.exp * 1000);
  }

  get functional_position(): string {
    return this.access_token_data.functional_position;
  }

  get isValid(): boolean {
    return this.isAccessTokenValid && this.isRefreshTokenValid;
  }

  get isAccessTokenValid(): boolean {
    return this.expires >= new Date();
  }

  get isRefreshTokenValid(): boolean {
    return this.refresh_expires >= new Date();
  }

  // prevents security vulnerabilities by omitting
  // decoded token content in its serialization
  toReplacedStringified(): string {
    function replacer(key: string, value: unknown) {
      if (key === 'access_token_data') {
        return undefined;
      }
      else if (key === 'refresh_token_data') {
        return undefined;
      }
      else {
        return value;
      }
    }

    return JSON.stringify(this, replacer);
  }

  private constructor(fromPlainObject: FrontendToken) {
    Object.assign(this, fromPlainObject);
  }

  static fromJson(token: FrontendToken): Nullable<AuthToken> {
    if (token) {

      return new AuthToken({
        access_token: token.access_token,
        refresh_token: token.refresh_token,
        access_token_data: parseJwt(token.access_token),
        refresh_token_data: parseJwt(token.refresh_token),
      });
    } else {
      return null;
    }
  }

  static fromApi(responseData: TokenApiResponse): AuthToken {
    return new AuthToken({
      access_token: responseData.access_token,
      access_token_data: parseJwt(responseData.access_token),
      refresh_token: responseData.refresh_token,
      refresh_token_data: parseJwt(responseData.refresh_token),
    });
  }
}

/**
 * Cypress has issues with imports like `import * as jwtDecode from 'jwt-decode';`.
 * Therefore parse is done library-less
 */
export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
