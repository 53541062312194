import {inject, Pipe, PipeTransform} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {CodebookService} from '../../core/services/codebook.service';
import {getUserFullName} from '../../model';

export function getUserFullNameById$(codebookService: CodebookService, userId: Nullable<number>): Observable<string> {
  if (isNil(userId)) {
    return of('');
  }
  else {
    return codebookService.users().pipe(
      map(users => users.find(u => u.id === userId)!),
      map(user => getUserFullName(user)),
    );
  }
}

@Pipe({
  name: 'findUserById',
  pure: true,
})
export class FindUserByIdPipe implements PipeTransform {

  private codebookService = inject(CodebookService);

  transform(userId: Nullable<number>): Observable<string> {
    return getUserFullNameById$(
      this.codebookService,
      userId,
    );
  }

}
