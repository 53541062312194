import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {InterpolationContext} from '../../../lib/model';

export type AlertStatus = 'error' | 'warning' | 'info' | 'neutral' | 'success';

export type AlertMode = 'full' | 'compact';

const SEVERITY_ICONS: Record<AlertStatus, string> = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  neutral: 'error',
  success: 'success',
};

const SEVERITY_COMPACT_ICONS: Record<AlertStatus, string> = {
  info: 'info_blue',
  warning: 'warning',
  error: 'error',
  neutral: 'error',
  success: 'config_success',
};

@Component({
  selector: 'icz-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertComponent {
  @Input() heading = '';
  @Input() label = '';
  @Input() severity: AlertStatus = 'info';
  @Input() textContext: Nullable<InterpolationContext>;
  @Input() showIcon = true;
  @Input() customSvgIcon: Nullable<string>;
  @Input() isDismissable = false;
  @Input() isLabelExpandable = false;
  @Input() alertMode: AlertMode = 'full';
  @HostBinding('class.pinned-top') @Input() isPinnedToTop = false;

  @Output() dismissed = new EventEmitter<void>();

  isExpanded = false;

  get icon() {
    if (this.customSvgIcon) {
      return this.customSvgIcon;
    }
    else {
      if (this.isFullAlert) {
        return SEVERITY_ICONS[this.severity];
      }
      else {
        return SEVERITY_COMPACT_ICONS[this.severity];
      }
    }
  }

  get isFullAlert() {
    return this.alertMode === 'full';
  }

  @HostBinding('class.mode-full')
  get hasFullModeStyle() {
    return this.isFullAlert;
  }

  @HostBinding('class.info')
  get isInfo() {
    return this.severity === 'info';
  }

  @HostBinding('class.error')
  get isError() {
    return this.severity === 'error';
  }

  @HostBinding('class.warning')
  get isWarning() {
    return this.severity === 'warning';
  }

  @HostBinding('class.neutral')
  get isNeutral() {
    return this.severity === 'neutral';
  }

  @HostBinding('class.success')
  get isSuccess() {
    return this.severity === 'success';
  }

  toggleExpansionState() {
    this.isExpanded = !this.isExpanded;
  }
}
