import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {ReceivedEpdzMessageDefectDto} from '|api/sad';
import {getDigitalMessageDefectSummaryTranslationKey} from '../../../enums/digital-message-issue.enum';

@Component({
  selector: 'icz-digital-message-issues',
  templateUrl: './digital-message-issues.component.html',
  styleUrls: ['./digital-message-issues.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DigitalMessageIssuesComponent {

  private translateService = inject(TranslateService);

  @Input({required: true})
  defects: Nullable<ReceivedEpdzMessageDefectDto[]>;

  isDefectsPopupOpen = false;

  get issuesLinkText(): string {
    if (this.defects?.length) {
      let out = '';

      out += this.translateService.instant(
        getDigitalMessageDefectSummaryTranslationKey(this.defects[0].defectType)
      );
      const remainingIssuesCount = this.defects.length - 1;

      if (remainingIssuesCount > 0) {
        out += ` (+${remainingIssuesCount})`;
      }

      return out;
    }
    else {
      return '';
    }
  }

  readonly getDigitalMessageDefectSummaryTranslationKey = getDigitalMessageDefectSummaryTranslationKey;

  openIssuesPopup() {
    this.isDefectsPopupOpen = true;
  }

  closeIssuesPopup() {
    this.isDefectsPopupOpen = false;
  }

}
