@if (canPageBeInFavouriteList) {
  <icz-button
    (onAction)="onClick($event)"
    class="favorite-icon"
    originalIconColor
    [svgIcon]="icon"
    background
    [iczTooltip]="tooltip"
    [size]="iconSize"
  ></icz-button>
}
