/* tslint:disable */
/* eslint-disable */

/**
 * Classification code separator
 */
export enum ClassificationCodeSeparator {
  SLASH = 'SLASH',
  DASH = 'DASH',
  SPACE = 'SPACE',
  DOT = 'DOT'
}
