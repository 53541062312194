import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {InterpolationContext} from '../../../lib/model';

type HeadingLevel = '1'|'2'|'3';

@Component({
  selector: 'icz-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeadingComponent {
  @HostBinding('class.h1')
  get isLevel1() { return this.level === '1'; }
  @HostBinding('class.h2')
  get isLevel2() { return this.level === '2'; }
  @HostBinding('class.h3')
  get isLevel3() { return this.level === '3'; }

  @Input()
  level: HeadingLevel = '1';
  @Input({required: true})
  label: string = '';
  @Input()
  labelContext: Nullable<InterpolationContext>;
}
