/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ConsignmentValidationResult } from '../../models/consignment-validation-result';
import { OwnInternalPaperConsignmentCreateDto } from '../../models/own-internal-paper-consignment-create-dto';

export interface OwnConsignmentValidateOwnInternalPaperConsignment$Params {
      body: OwnInternalPaperConsignmentCreateDto
}

export function ownConsignmentValidateOwnInternalPaperConsignment(http: HttpClient, rootUrl: string, params: OwnConsignmentValidateOwnInternalPaperConsignment$Params, context?: HttpContext): Observable<StrictHttpResponse<ConsignmentValidationResult>> {
  const rb = new RequestBuilder(rootUrl, ownConsignmentValidateOwnInternalPaperConsignment.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<ConsignmentValidationResult>;
    })
  );
}

ownConsignmentValidateOwnInternalPaperConsignment.PATH = '/sad/own-consignment/paper/internal/validate';