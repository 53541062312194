import {Component, inject, OnInit} from '@angular/core';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {Option} from '../../../../../model';
import {LocalizedDatePipe} from '../../../../essentials/date.pipe';
import {AddressCompleteDto, addressToAddressWithType, AddressWithTypeDto} from '../../../model/addresses.model';
import {CzechAddressDto, RelatedObjectType, SubjectRecordDto} from '|api/commons';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {ApiComponentService, PaperComponentDto} from '|api/component';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {DistributionNodeType} from '|api/config-server';
import {TranslateService} from '@ngx-translate/core';
import {OwnPaperConsignmentCreateDto} from '|api/sad';
import {EsslComponentDto} from '../../../../../services/essl-component-search.service';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

import {sheetNodeToOption} from '../../../consignment-dialog/consignment-dialog/consignment.model';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';

export interface DevCreateConsignmentDialogComponentResult {
  count: number,
  paperConsig: OwnPaperConsignmentCreateDto,
}

@Component({
  selector: 'icz-dev-create-consignment-dialog',
  templateUrl: './dev-create-consignment-dialog.component.html',
  styleUrls: ['./dev-create-consignment-dialog.component.scss'],
  providers: [
    LocalizedDatePipe,
  ],
})
export class DevCreateConsignmentDialogComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private apiComponentService = inject(ApiComponentService);
  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private modalRef = injectModalRef<Nullable<DevCreateConsignmentDialogComponentResult>>();
  protected modalData = injectModalData<{ documentId: number }>();

  combinationOpts: Option[] = [];
  deliveryTypeOps: Option[] = [];
  sheetNodeOptions: Option[] = [];
  components: EsslComponentDto[] = [];

  countForm = new IczFormGroup({
    count: new IczFormControl<Nullable<number>>(1, [IczValidators.required(), IczValidators.isInteger(), IczValidators.min(1), IczValidators.max(20)]),
  });

  form = new IczFormGroup({
    deliveryServiceCombinationId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    deliveryTypeId: new IczFormControl<Nullable<number>>(10, [IczValidators.required()]),
    consignee: new IczFormControl<Nullable<SubjectRecordDto>>(null, [IczValidators.required()]),
    consigneeAddress: new IczFormControl<Nullable<AddressCompleteDto>>(null, [IczValidators.required()]),
    componentIds: new IczFormControl<Nullable<number[]>>([], [IczValidators.required()]),
    dispatchOfficeDistributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    ownDocumentId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  getAddress(consigneeAddress: AddressCompleteDto): AddressWithTypeDto {
    return addressToAddressWithType(consigneeAddress);
  }

  get componentLabel() {
    if (!this.form.value.componentIds!.length) return '';
    else return this.components.find(c => c.id === this.form.value!.componentIds![0])!.label;
  }

  private initializeSheetNodeOptions() {
    this.codebookService.sheetNodes().pipe(map(sheetNodes => sheetNodes
      .filter(sheetNode => sheetNode.isActive && sheetNode.dispatchOfficeId)
      .filter(sheetNode => sheetNode.purpose === DistributionNodeType.SEND || sheetNode.purpose === DistributionNodeType.SEND_RECEIVE)
      .map(sheetNodeToOption(this.translateService))),
    ).subscribe(sheetNodeOptions => {
      this.sheetNodeOptions = sheetNodeOptions;
      this.form.get('dispatchOfficeDistributionNodeId')!.setValue(sheetNodeOptions[0].value);
    });
  }

  ngOnInit() {
    this.form.get('ownDocumentId')!.setValue(this.modalData.documentId);
    this.loadingIndicatorService.doLoading(combineLatest([
      this.apiSubjectRecordNgElasticService.subjectRecordElasticElasticFindSubjectsByRelations(
        {body: {
          relatedObjectIds: [this.modalData.documentId],
            relatedObjectType: RelatedObjectType.DOCUMENT}}),

      this.apiComponentService.componentSearch({page: 0, size: 10, urlPredicates: {documentId: this.modalData.documentId}}),
    ]), this).subscribe(([subjects, componentsPage]) => {
      if (subjects?.length) {
        this.form.get('consignee')!.setValue(subjects[0]);

        let address: Nullable<AddressCompleteDto>;
        address = subjects[0].attributes.residentialAddress?.value as CzechAddressDto;
        if (!address) {
          address = subjects[0].attributes.mailingAddress?.value as CzechAddressDto;
        }
        if (!address) {
          address = subjects[0].attributes.additionalAddresses?.[0]!.value as CzechAddressDto;
        }

        if (address) {
          this.form.get('consigneeAddress')!.setValue(address as CzechAddressDto);
        }
      }

      if (componentsPage.content?.length) {
        this.components = componentsPage.content as unknown as EsslComponentDto[];
        this.form.get('componentIds')!.setValue([(componentsPage.content[0] as PaperComponentDto)!.id!]);
      }

    });

    this.codebookService.deliveryServiceCombinations().subscribe(combinations => {
      this.combinationOpts = combinations.map(namedDtoToOption());
      this.form.get('deliveryServiceCombinationId')!.setValue(combinations[0].id);
    });

    this.codebookService.deliveryTypes().subscribe(types => {
      this.deliveryTypeOps = types.map(namedDtoToOption());
    });
    this.initializeSheetNodeOptions();

    // todo consider randomizator of component, and combination id

  }

  submit() {
    this.modalRef.close({count: this.countForm.value.count!, paperConsig: this.form.value as OwnPaperConsignmentCreateDto});
  }

  closeModal() {
    this.modalRef.close(null);
  }

}
