import {inject, Injectable} from '@angular/core';
import {cloneDeep, isEmpty, isEqual} from 'lodash';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AmbivalentSubjectDto,
  CzechAddressDto,
  DataBoxType,
  GenericLineAddressDto,
  HouseNumberType,
  OvmType,
  PostalBoxAddressDto,
  SubjectAttributeAddressDto,
  SubjectAttributeDataBoxDto,
  SubjectAttributesDto,
  SubjectAttributeSource,
  SubjectAttributeState,
  SubjectAttributeStringDto,
  SubjectRecordClassification,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto,
  SubjectRecordFindDto,
  SubjectRecordFindMode,
} from '|api/commons';
import {IczFormGroup} from '../components/form-elements/icz-form-controls';
import {
  AddressCompleteDto,
  AddressForm,
  AddressFormat,
  CzechAddressDtoExtended,
  GenericLineAddressFormDto,
} from '../components/shared-business-components/model/addresses.model';
import {
  DataboxTypeFromIsdsSearchMethod, isDataBoxDto,
  IszrEnrichResult,
  SubjectPhysicalAddresses,
  SubjectRecordSource,
  SubjectRecordWithSourceDto,
} from '../components/shared-business-components/model/subjects.model';
import {hashed, pushOrCreateArray, stripUnwantedProps} from '../lib/utils';
import {ApiSubjectRecordService} from '|api/subject-register';
import {enumValuesToArray} from '../core';
import {SubjectTemplateUtils} from '../utils/subject-template-utils';
import {SubjectAttributeType} from '../components/shared-business-components/model/subject-attribute-type';

export enum SubjectLoaderIds {
  SEARCHING = 'searching',
  LINKING = 'linking',
}

export enum SearchOnlyAttribute {
  ADDRESS = 'address',
  DATA_BOX = 'dataBoxId',
  EMAIL = 'email',
}

export enum SubjectIdentifierType {
  CLIENT_ID = SubjectAttributeType.CLIENT_ID,
  DATA_BOX = SearchOnlyAttribute.DATA_BOX,
  DRIVING_LICENCE_ID = SubjectAttributeType.DRIVING_LICENCE_ID,
  IDENTITY_CARD_ID = SubjectAttributeType.IDENTITY_CARD_ID,
  PASSPORT_ID = SubjectAttributeType.PASSPORT_ID,
}

export type AddressAttributeType = Partial<SubjectAttributeType>;

export const addressAttributeTypeToCreateFromSearchedAddress = SubjectAttributeType.RESIDENTIAL_ADDRESS;

export const unifiedSearchFormKeys = [
  SearchOnlyAttribute.ADDRESS,
  SearchOnlyAttribute.EMAIL,
  SubjectAttributeType.BIRTH_DATE,
  SubjectAttributeType.BUSINESS_NAME,
  SubjectAttributeType.CID,
  SubjectAttributeType.FIRST_NAME,
  SubjectAttributeType.SURNAME,
];

export const unifiedSearchFormKeysWithIdentifiers = [
  ...unifiedSearchFormKeys,
  ...enumValuesToArray(SubjectIdentifierType),
];

export const stringAttributesWithMultiplicityOne: SubjectAttributeType[] = [
  SubjectAttributeType.ART_1_P_3_ID,
  SubjectAttributeType.BIRTH_DATE,
  SubjectAttributeType.BIRTH_NAME,
  SubjectAttributeType.BIRTH_SURNAME,
  SubjectAttributeType.BIRTH_PLACE,
  SubjectAttributeType.BUSINESS_NAME,
  SubjectAttributeType.CID,
  SubjectAttributeType.CLIENT_ID,
  SubjectAttributeType.COMPANY_ID,
  SubjectAttributeType.DEGREE_AFTER,
  SubjectAttributeType.DEGREE_BEFORE,
  SubjectAttributeType.DRIVING_LICENCE_ID,
  SubjectAttributeType.EORI_CODE,
  SubjectAttributeType.EXCISE_TAX_ID,
  SubjectAttributeType.FIRST_NAME,
  SubjectAttributeType.GENDER,
  SubjectAttributeType.IDENTITY_CARD_ID,
  SubjectAttributeType.LEGAL_FORM,
  SubjectAttributeType.LE_ID,
  SubjectAttributeType.NOTE,
  SubjectAttributeType.PASSPORT_ID,
  SubjectAttributeType.SURNAME,
  SubjectAttributeType.TAX_ID,
  SubjectAttributeType.VAT_ID,
];

export const attributesWithMultiplicityMany: SubjectAttributeType[] = [
  SubjectAttributeType.ADDITIONAL_ADDRESS,
  SubjectAttributeType.POSTAL_BOX,
  SubjectAttributeType.DATA_BOX,
  SubjectAttributeType.EMAIL,
  SubjectAttributeType.PHONE_FAX,
];

// Not all keys will ever be used for searching, but keeping the map full just in case
export function getUiKeyForSubjectSearchAttribute(key: string): string {
  const map: Record<keyof SubjectRecordFindDto, string> = {
    identifiable: 'Identifikovatelný',
    address: 'Adresa',
    birthDate: 'Datum narození',
    birthPlace: 'Datum narození',
    businessName: 'Obchodní název',
    cid: 'IČO',
    clientId: 'Klientské číslo',
    dataBoxId: 'Datová schránka',
    deathDate: 'Datum úmrtí',
    drivingLicenceId: 'Řidičský průkaz',
    identityCardId: 'Občanský průkaz',
    firstName: 'Jméno',
    surname: 'Příjmení',
    passportId: 'Cestovní pas',
    email: 'Email',
    mode: '',
    classification: '',
  };

  return map[key as keyof SubjectRecordFindDto];
}

export function getUiKeyForSubjectCreateAttribute(key: Nullable<SubjectAttributeType>): string {
  if (!key) return '';

  const map = {
    [SubjectAttributeType.ADDITIONAL_ADDRESS]: 'Kontaktní adresa',
    [SubjectAttributeType.RESIDENTIAL_ADDRESS]: 'Trvalé bydliště',
    [SubjectAttributeType.MAILING_ADDRESS]: 'Doručovací adresa',
    [SubjectAttributeType.CLIENT_ID]: 'Klientské číslo',
    [SubjectAttributeType.DATA_BOX]: getUiKeyForSubjectSearchAttribute('dataBoxId'),
    [SubjectAttributeType.EMAIL]: 'Email',
    [SubjectAttributeType.PHONE_FAX]: 'Telefon/fax',
    [SubjectAttributeType.FIRST_NAME]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.FIRST_NAME as keyof SubjectRecordFindDto),
    [SubjectAttributeType.SURNAME]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.SURNAME as keyof SubjectRecordFindDto),
    [SubjectAttributeType.DEGREE_BEFORE]: 'Titul před',
    [SubjectAttributeType.DEGREE_AFTER]: 'Titul za',
    [SubjectAttributeType.BIRTH_DATE]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.BIRTH_DATE as keyof SubjectRecordFindDto),
    [SubjectAttributeType.LEGAL_FORM]: 'Právní forma',
    [SubjectAttributeType.BUSINESS_NAME]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.BUSINESS_NAME as keyof SubjectRecordFindDto),
    [SubjectAttributeType.EORI_CODE]: 'EORI kód',
    [SubjectAttributeType.CID]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.CID as keyof SubjectRecordFindDto),
    [SubjectAttributeType.COMPANY_ID]: 'ID Společnosti', // not displayed anywhere, except subject pipe
    [SubjectAttributeType.TAX_ID]: 'DIČ',
    [SubjectAttributeType.VAT_ID]: 'Identifikační číslo pro účely DPH',
    [SubjectAttributeType.ART_1_P_3_ID]: 'Identifikační údaj uvedený v čl.3 odst. 1',
    [SubjectAttributeType.EXCISE_TAX_ID]: 'ID Spotřební daně',
    [SubjectAttributeType.LE_ID]: 'Identifikační údaj právnické osoby',
    [SubjectAttributeType.IDENTITY_CARD_ID]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.IDENTITY_CARD_ID as keyof SubjectRecordFindDto),
    [SubjectAttributeType.PASSPORT_ID]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.PASSPORT_ID as keyof SubjectRecordFindDto),
    [SubjectAttributeType.DRIVING_LICENCE_ID]: getUiKeyForSubjectSearchAttribute(SubjectAttributeType.DRIVING_LICENCE_ID as keyof SubjectRecordFindDto),
    [SubjectAttributeType.BIRTH_NAME]: 'Rodné jméno',
    [SubjectAttributeType.BIRTH_SURNAME]: 'Rodné příjmení',
    [SubjectAttributeType.BIRTH_PLACE]: 'Místo narození',
    [SubjectAttributeType.GENDER]: 'Pohlaví',
    [SubjectAttributeType.NOTE]: 'Poznámka',
    [SubjectAttributeType.POSTAL_BOX]: 'P.O. BOX',
  };

  return map[key] ?? '-';
}

export function isCzSkAddressPartiallyFilled(value: IczFormGroup | AddressCompleteDto): boolean {
  if (!value) return false;
  const formValue = value?.getRawValue ? value?.getRawValue() : value;
  let isFilled = false;
  const propsIrrelevantForDecidingIfFilled = ['_Class', 'id', 'search', 'houseNumberType', 'country'];

  const keys = Object.keys(formValue).filter(k => propsIrrelevantForDecidingIfFilled.indexOf(k) === -1);
  if (keys.some(k => !isNil(formValue[k]) && formValue[k] !== '')) isFilled = true;
  return isFilled;
}

export function isGenericLineAddressMinimumRequired(value: IczFormGroup | AddressCompleteDto): boolean {
  if (!value) return false;

  const formValue = value?.getRawValue ? value?.getRawValue() : value;

  return (formValue as GenericLineAddressFormDto).addressLines?.[0].line !== null;
}

export function isPostalBoxAddressMinimumRequired(value: IczFormGroup | AddressCompleteDto): boolean {
  if (!value) return false;

  const formValue = value?.getRawValue ? value?.getRawValue() : value;

  return (formValue as PostalBoxAddressDto).box !== null;
}

export function isAddressPartiallyFilled(attributeForm: IczFormGroup): boolean {
  const valueForm = attributeForm.get('value')!;
  return isCzSkAddressPartiallyFilled(valueForm.get([AddressFormat.CzechAddressDto]) as IczFormGroup) ||
    isCzSkAddressPartiallyFilled(valueForm!.get([AddressFormat.SlovakAddressDto]) as IczFormGroup) ||
    isGenericLineAddressMinimumRequired(valueForm!.get([AddressFormat.GenericLineAddressDto]) as IczFormGroup) ||
    isPostalBoxAddressMinimumRequired(valueForm!.get([AddressFormat.PostalBoxAddressDto]) as IczFormGroup);
}

export function isAddressForIdentificationFilled(value: IczFormGroup | AddressCompleteDto): boolean {
  const formValue = (value as IczFormGroup).getRawValue ? (value as IczFormGroup).getRawValue() : value;
  let isFilled = false;
  const relevantProps = ['street', 'city', 'postalCode'];

  const keys = Object.keys(formValue).filter(k => relevantProps.includes(k));
  if (keys.some(k => !isNil(formValue[k]) && formValue[k] !== '')) isFilled = true;
  return isFilled;
}

function pluckAddressDtoFormByFormat(value: IczFormGroup | AddressForm): AddressCompleteDto {
  const formValue = (value as IczFormGroup).getRawValue ? (value as IczFormGroup).getRawValue() : value;

  const type: Nullable<AddressFormat> = formValue.value._Class;

  if (type === AddressFormat.CzechAddressDto) {
    return formValue.value[AddressFormat.CzechAddressDto] as AddressCompleteDto;
  }
  else if (type === AddressFormat.SlovakAddressDto) {
    return formValue.value[AddressFormat.SlovakAddressDto] as AddressCompleteDto;
  }
  else if (type === AddressFormat.GenericLineAddressDto) {
    return formValue.value[AddressFormat.GenericLineAddressDto] as AddressCompleteDto;
  }
  else {
    return formValue.value[AddressFormat.PostalBoxAddressDto] as AddressCompleteDto;
  }
}

/**
 * Handles houseNumber vs registrationNumber.
 * This seems like it should be handled in AddressComponent, however it would require keeping separate displayed values from internal
 * model value which is bug prone
 * @param addressToBuild CzechAddressDtoExtended
 */
function handleBuildingNumbers(addressToBuild: CzechAddressDtoExtended) {
  const address = cloneDeep(addressToBuild);

  if (address.houseNumber || !address.registrationNumber) {
    address.houseNumberType = HouseNumberType.STREET_NUMBER;
  }
  else if (address.registrationNumber) {
    address.houseNumberType = HouseNumberType.REGISTRATION_NUMBER;
    address.houseNumber = address.registrationNumber;
    delete address.registrationNumber;
  }

  const split = address.orientationNumber?.split('/');

  if (split?.length === 1) {
    delete address.orientationNumberLastCharacter;
  }
  else if (split && split?.length >= 2) {
    address.orientationNumber = split[0];
    address.orientationNumberLastCharacter = split[1];
  }

  return address;
}

export function formatAddressForDto(value: IczFormGroup | AddressForm): AddressCompleteDto {
  const formValue = (value as IczFormGroup)?.getRawValue ? (value as IczFormGroup)?.getRawValue() : value;

  let addressByFormat: AddressCompleteDto = pluckAddressDtoFormByFormat(formValue);
  const addressClass = formValue.value._Class;
  addressByFormat.country = formValue.value.country;
  addressByFormat._Class = addressClass;

  addressByFormat = stripUnwantedProps<AddressCompleteDto, Partial<AddressCompleteDto>>(addressByFormat, [], {
    removeNull: true,
    removeEmptyString: true
  }) as AddressCompleteDto;

  if (addressClass === AddressFormat.GenericLineAddressDto) {
    const addressLinesForm: any = (addressByFormat as GenericLineAddressDto).addressLines;
    (addressByFormat as GenericLineAddressDto).addressLines = addressLinesForm.filter((a: { line: string }) => Boolean(a.line)).map((a: { line: string }) => a.line);
  }
  else if (addressClass === AddressFormat.CzechAddressDto || addressClass === AddressFormat.SlovakAddressDto) {
    addressByFormat = handleBuildingNumbers(addressByFormat as CzechAddressDto);
  }

  return addressByFormat;
}


@Injectable({
  providedIn: 'root',
})
export class SubjectsService {

  private isdsFindResults: Record<string, SubjectRecordWithSourceDto> = {};

  private apiSubjectRecordNgService = inject(ApiSubjectRecordService);

  enrichExistingSubjectWithIdentified(internalSubject: SubjectRecordDto, iszrIdentifiedSubject: SubjectRecordDto, formValue?: Record<string, any>):
    IszrEnrichResult {
    const diff: Record<string, string> = {};
    const identifiedResult: SubjectRecordDto = {
      ...internalSubject,
      iszrMetadata: iszrIdentifiedSubject.iszrMetadata,
      iszrIdentifier: iszrIdentifiedSubject.iszrIdentifier,
      attributes: {...internalSubject.attributes},
      classification: iszrIdentifiedSubject.classification,
      ovmType: iszrIdentifiedSubject.ovmType,
    };

    // use case for providing formValue is when editing existing subject, and adding new attributes and then identifying, we need to merge
    // existing attributes with new user attributes and with iszr attributes
    if (formValue) {
      identifiedResult.attributes = this.formValueToAttributesDto(formValue, identifiedResult.attributes);
    }

    Object.entries(iszrIdentifiedSubject.attributes).forEach(([key, iszrValue]) => {
      if (iszrValue !== null) {
        if (Array.isArray(iszrValue)) {
          if (iszrValue.length > 0) {
            iszrValue.forEach((iszr: SubjectAttributeStringDto) => {
              const existingAttrs = (identifiedResult.attributes[key as keyof SubjectAttributesDto] as Nullable<SubjectAttributeStringDto[]>);
              if (existingAttrs) {
                const existingAttrIndex: number = existingAttrs?.findIndex(a => {
                  let hashedAndStrippedIszrValue = hashed(iszr.value);
                  if (typeof iszr.value !== 'string') {
                    hashedAndStrippedIszrValue = hashed(stripUnwantedProps(iszr!.value as AddressCompleteDto, [], {removeNull: true, removeEmptyString: true}));
                  }

                  let hashedAndStrippedExistingValue = hashed(a.value);
                  if (typeof a.value !== 'string') {
                    hashedAndStrippedExistingValue = hashed(stripUnwantedProps(a.value as AddressCompleteDto, [], {removeNull: true, removeEmptyString: true}));
                  }

                  return hashedAndStrippedExistingValue === hashedAndStrippedIszrValue;
                });
                if (existingAttrIndex > -1) {
                  existingAttrs[existingAttrIndex] = {...iszr, id: existingAttrs[existingAttrIndex].id};
                } else {
                  const resultAttr = identifiedResult.attributes[key as keyof SubjectAttributesDto] as Nullable<SubjectAttributeStringDto[]>;
                  pushOrCreateArray(resultAttr, iszr);
                }
              } else {
                const resultAttr = identifiedResult.attributes[key as keyof SubjectAttributesDto] as Nullable<SubjectAttributeStringDto[]>;
                pushOrCreateArray(resultAttr, iszr);
              }
            });
          }
        } else {
          if (formValue) {
            const attrFormValue = formValue[key];
            if (!(attrFormValue as SubjectAttributeStringDto)?.value || hashed((attrFormValue as SubjectAttributeStringDto).value) !== hashed(iszrValue.value)) {
              diff[key] = (attrFormValue as SubjectAttributeStringDto)?.value ?? 'Prázdné';
            }
          }
          identifiedResult.attributes[key as keyof SubjectAttributesDto] = iszrValue;
        }
      }
    });
    return {enrichedSubject: identifiedResult, diff};
  }

  setFindModeBySearchKeys(searchForm: IczFormGroup): SubjectRecordFindMode {
    let searchInIsds = false;
    const keysThatTriggerIsdsSearch = [
      SearchOnlyAttribute.ADDRESS,
      SearchOnlyAttribute.DATA_BOX,
      SubjectAttributeType.FIRST_NAME,
      SubjectAttributeType.SURNAME,
      SubjectAttributeType.CID,
      SubjectAttributeType.BUSINESS_NAME,
    ];
    keysThatTriggerIsdsSearch.forEach(key => {
      if (key === SearchOnlyAttribute.ADDRESS) {
        if (isCzSkAddressPartiallyFilled(searchForm.get(SearchOnlyAttribute.ADDRESS)) && searchForm.get(SearchOnlyAttribute.ADDRESS)!.valid) {
          searchInIsds = true;
        }
      } else if (!isNil(searchForm.get(key)!.value) && searchForm.get(key)!.valid) {
        searchInIsds = true;
      }
    });
    return searchInIsds ? SubjectRecordFindMode.INTERNAL_ISDS : SubjectRecordFindMode.INTERNAL;
  }

  getFormValueFromSearchForm(form: IczFormGroup): Record<string, string | AddressCompleteDto> {
    const allAttributes = form.getRawValue();
    delete allAttributes.classification;
    delete allAttributes.identityType;

    return allAttributes;
  }

  handleBuildingNumbers(address: CzechAddressDtoExtended): CzechAddressDtoExtended {
    return handleBuildingNumbers(address);
  }

  setAddressForSearchDto(identificationDto: SubjectRecordFindDto, addressForm: IczFormGroup): SubjectRecordFindDto {
    const address: AddressCompleteDto = formatAddressForDto(addressForm);
    const addressFormByFormat: AddressCompleteDto = this.pluckAddressFormByFormat(addressForm);

    if (isCzSkAddressPartiallyFilled(addressFormByFormat)) {
      identificationDto.address = address;
    } else {
      delete identificationDto.address;
    }
    return identificationDto;
  }

  correctForDeletedAttributesWithMultipleValues(newAttributes: SubjectAttributesDto, oldAttributes: SubjectAttributesDto): SubjectAttributesDto {
    const oldAttributesCopy = cloneDeep(oldAttributes);

    attributesWithMultiplicityMany.forEach(attributeType => {
      if (!oldAttributesCopy[attributeType]) return;

      const modifiedAttributesIds = (newAttributes[attributeType] as SubjectAttributeStringDto[])?.map(a => a.id).filter(id => Boolean(id)) ?? [];
      const oldAttributesIds = (oldAttributesCopy[attributeType] as SubjectAttributeStringDto[]).map(a => a.id);
      const idsOnlyInOldAttributes = oldAttributesIds.filter(old => !modifiedAttributesIds.includes(old));
      if (idsOnlyInOldAttributes.length) {
        let oldAttributesToKeep = (oldAttributesCopy[attributeType] as SubjectAttributeStringDto[]).filter(oa => idsOnlyInOldAttributes.includes(oa.id));
        oldAttributesToKeep = oldAttributesToKeep.map(old => {return {...old, value: null};});
        if (!newAttributes[attributeType]) {
          // @ts-ignore
          newAttributes[attributeType] = oldAttributesToKeep as SubjectAttributeStringDto[];
        } else {
          (newAttributes[attributeType] as SubjectAttributeStringDto[]).push(...oldAttributesToKeep);
        }
      }
    });
    return newAttributes;
  }

  /**
   * Creates SubjectRecordCreateOrUpdateDto from subject SEARCH FORM - may sound illogical, but is needed in Filing Office
   * @param form
   */
  createNewSubjectDtoFromSearchForm(form: IczFormGroup): SubjectRecordCreateOrUpdateDto {
    const notInDto: string[] = ['classification', 'dataBoxId'];

    const formValue = stripUnwantedProps<any, any>(this.getFormValueFromSearchForm(form), notInDto, {removeNull: true, removeEmptyString: true});
    const attributes = this.formValueToAttributesDto(formValue);

    const classification = form.get('classification')!.value as SubjectRecordClassification;
    const ovmType = form.get('ovmType')?.value as OvmType;

    return {
      classification,
      ovmType,
      attributes,
      forceMode: false,
      enrichMode: true,
      objectRelations: null
    };
  }

  pluckAddressFormByFormat(form: IczFormGroup): AddressCompleteDto {
    return pluckAddressDtoFormByFormat(form);
  }

  getSubjectPhysicalAddresses(attributes: Nullable<SubjectAttributesDto>): Nullable<SubjectPhysicalAddresses> {
    if (!attributes) return null;
    return {
      mailing: attributes.mailingAddress?.value,
      residential: attributes.residentialAddress?.value,
      additional: attributes.additionalAddresses?.map(a => a.value),
      postalBoxes: attributes.postalBoxes?.map(p => p.value!)
    };
  }

  getSubjectSearchValue(form: IczFormGroup,
  ): SubjectRecordFindDto {
    const formValue = stripUnwantedProps<SubjectRecordFindDto, SubjectRecordFindDto>
    (form.getRawValue() as SubjectRecordFindDto, [], {removeNull: true, removeEmptyString: true});
    delete (formValue as any).classification;
    delete (formValue as any).identityType;

    return this.setAddressForSearchDto(formValue, form.get('address') as IczFormGroup);
  }

  isSearchFormEmpty(form: IczFormGroup): boolean {
    return isEmpty(this.getSubjectSearchValue(form));
  }

  dbTypeFromIsdsSearchToClassification(dbType: DataboxTypeFromIsdsSearchMethod): SubjectRecordClassification {
    switch (dbType) {
      case DataboxTypeFromIsdsSearchMethod.OVM_FO:
      case DataboxTypeFromIsdsSearchMethod.FO:
        return SubjectRecordClassification.FO;
      case DataboxTypeFromIsdsSearchMethod.OVM_PFO:
      case DataboxTypeFromIsdsSearchMethod.PFO:
      case DataboxTypeFromIsdsSearchMethod.PFO_ADVOK:
      case DataboxTypeFromIsdsSearchMethod.PFO_DANPOR:
      case DataboxTypeFromIsdsSearchMethod.PFO_INSSPR:
      case DataboxTypeFromIsdsSearchMethod.PFO_AUDITOR:
      case DataboxTypeFromIsdsSearchMethod.PFO_ZNALEC:
      case DataboxTypeFromIsdsSearchMethod.PFO_TLUMOCNIK:
      case DataboxTypeFromIsdsSearchMethod.PFO_REQ:
      case DataboxTypeFromIsdsSearchMethod.PFO_ARCH:
      case DataboxTypeFromIsdsSearchMethod.PFO_AIAT:
      case DataboxTypeFromIsdsSearchMethod.PFO_AZI:
        return SubjectRecordClassification.PFO;
      case DataboxTypeFromIsdsSearchMethod.OVM:
      case DataboxTypeFromIsdsSearchMethod.OVM_NOTAR:
      case DataboxTypeFromIsdsSearchMethod.OVM_EXEKUT:
      case DataboxTypeFromIsdsSearchMethod.OVM_REQ:
      case DataboxTypeFromIsdsSearchMethod.OVM_PO:
      case DataboxTypeFromIsdsSearchMethod.PO:
      case DataboxTypeFromIsdsSearchMethod.PO_ZAK:
      case DataboxTypeFromIsdsSearchMethod.PO_REQ:
        return SubjectRecordClassification.PO;
    }
  }

  mapAmbivalentSubjectDtoToSubjects(ambivalent: AmbivalentSubjectDto): SubjectRecordWithSourceDto[] {
    const baseAttrIsdsSearch = {
      source: SubjectAttributeSource.ISDS,
      state: SubjectAttributeState.CORRECT,
      validFrom: new Date().toISOString(),
    };

    const isdsSearchResultsAsPseudoSubjectRecords: SubjectRecordWithSourceDto[] = [];
    ambivalent.isdsSearchResults?.forEach(s => {
      const result: SubjectRecordWithSourceDto = {
        classification: this.dbTypeFromIsdsSearchToClassification(s.dbType as DataboxTypeFromIsdsSearchMethod),
        identifiable: false,
        ovmType: s.dbIdOVM as OvmType,
        isdsVerified: new Date().toISOString(), // isds search results don't have isdsVerified date yet but they will, once they are created as internal subject, in the meantime FE has to set it
        attributes: {
          dataBoxes: [
            {
              ...baseAttrIsdsSearch,
              value: {id: s.dbID!, type: s.dbType as DataBoxType,},
            }
          ],
          businessName: {
            ...baseAttrIsdsSearch,
            value: s.dbName,
          },
          surname: {
            ...baseAttrIsdsSearch,
            value: s.dbName,
          },
          birthDate: {
            ...baseAttrIsdsSearch,
            value: s.dbBiDate,
          },
          additionalAddresses: [
            {value: s.dbAddress ? {_Class: AddressFormat.CzechAddressDto, street: s.dbAddress} : null,
              ...baseAttrIsdsSearch,
            }
          ],
          cid: {
            ...baseAttrIsdsSearch,
            value: s.dbCID,
          }
        },
        subjectSource: SubjectRecordSource.ISDS_SEARCH
      };
      isdsSearchResultsAsPseudoSubjectRecords.push(result);
    });

    let result: SubjectRecordWithSourceDto[] = [];
    if (ambivalent.internalResults?.length) {
      result = result.concat(ambivalent.internalResults.map(s => {
        return {...s, subjectSource: SubjectRecordSource.INTERNAL};
      }));
    }
    if (ambivalent.isdsFindResults?.length) {
      result = result.concat(ambivalent.isdsFindResults.map(s => {
        return {
          ...s,
          subjectSource: SubjectRecordSource.ISDS_FIND,
          isdsVerified: new Date().toISOString(), // isds search results don't have isdsVerified date yet but they will, once they are created as internal subject, in the meantime FE has to set it
        };
      }));
    }
    if (isdsSearchResultsAsPseudoSubjectRecords?.length) result = result.concat(isdsSearchResultsAsPseudoSubjectRecords);


    result = result.sort((s1, s2) => {
      return SubjectTemplateUtils.getSubjectWeight(s2) - SubjectTemplateUtils.getSubjectWeight(s1);
    });

    return result;
  }

  searchSubjects(form: IczFormGroup, searchOnlyIdentified: Nullable<boolean> = true, findModeOverride?: Nullable<SubjectRecordFindMode>): Observable<SubjectRecordWithSourceDto[]> {
    const findMode = findModeOverride ?? this.setFindModeBySearchKeys(form);

    const identificationDto = this.getSubjectSearchValue(form);
    identificationDto.mode = findMode;
    identificationDto.identifiable = searchOnlyIdentified; // identifiable is 3 state, null means ALL, true ONLY identified, false ONLY unidentified

    return this.apiSubjectRecordNgService.subjectRecordSearch({body: identificationDto})
      .pipe(map(res => this.mapAmbivalentSubjectDtoToSubjects(res)));
  }

  findUsingIsdsFind(subject: SubjectRecordDto): Observable<Nullable<SubjectRecordWithSourceDto>> {
    const dataBoxId = subject.attributes.dataBoxes?.[0].value!.id;
    if (dataBoxId) {
      if (this.isdsFindResults[dataBoxId]) {
        return of(this.isdsFindResults[dataBoxId]);
      }

      return this.apiSubjectRecordNgService.subjectRecordSearch({body: {dataBoxId, mode: SubjectRecordFindMode.ISDS}})
        .pipe(map(res => this.mapAmbivalentSubjectDtoToSubjects(res)),
          map(res => {
            const subjectFromIsdsFind = res.find(r => r.subjectSource === SubjectRecordSource.ISDS_FIND);
            if (subjectFromIsdsFind) {
              this.isdsFindResults[dataBoxId] = subjectFromIsdsFind;
            }
            return subjectFromIsdsFind ?? null;
          }
        ));
    } else return of(null);
  }

  // API explicitly requires that to remove attribute value, old attribute with ID must be sent, with value null. Omitting attribute in dto does not
  // remove it
  createAttributeWithMultiplicityOne(newAttribute: Nullable<SubjectAttributeStringDto>,
                                     oldAttribute: Nullable<SubjectAttributeStringDto>): Nullable<SubjectAttributeStringDto> {
    if (!oldAttribute) {
      if (newAttribute?.value) {
        return {
          source: newAttribute.source,
          validFrom: newAttribute.validFrom,
          state: newAttribute.state,
          value: newAttribute.value,
        };
      } else return undefined;
    } else {
      if (newAttribute) {
        return {
          source: newAttribute.source,
          validFrom: newAttribute.validFrom,
          state: newAttribute.state,
          value: newAttribute.value,
          id: oldAttribute.id,
        };
      } else {
        return {...oldAttribute, value: null};
      }
    }
  }

  createAttributeWithMultipleValues(newAttributes: Array<Nullable<(SubjectAttributeStringDto | SubjectAttributeDataBoxDto)>>,
                                    oldAttributes: Nullable<Array<SubjectAttributeStringDto | SubjectAttributeDataBoxDto>>):
    (SubjectAttributeStringDto | SubjectAttributeDataBoxDto)[] {

    const result: (SubjectAttributeStringDto | SubjectAttributeAddressDto[]) = [];
    // In create mode, API requires value origins to be specified
    if (!oldAttributes?.length) {
      newAttributes?.forEach(newAttribute => {
        if (newAttribute?.value) {
          const addNewAttr = () => {
            result.push({
              source: newAttribute.source,
              validFrom: newAttribute.validFrom,
              state: newAttribute.state,
              value: newAttribute.value,
            });
          };
          if (isDataBoxDto(newAttribute.value)) {
            if ((newAttribute as SubjectAttributeDataBoxDto)?.value?.type) {
              addNewAttr();
            }
          }
          else {
            addNewAttr();
          }
        }
      });
    }
    else {
      newAttributes?.forEach(newAttribute => {
        if (newAttribute?.id) {
          const updatedAttribute = oldAttributes.find(o => o.id === newAttribute.id)!;
          updatedAttribute.value = newAttribute.value ;
          result.push(updatedAttribute);
        } else if (newAttribute) {
          const oldAttributeWithSameValue = oldAttributes.find(o => {return isEqual(o.value, newAttribute.value);});
          if (oldAttributeWithSameValue) {
            result.push(oldAttributeWithSameValue);
          } else {
            result.push({
              source: newAttribute.source,
              validFrom: newAttribute.validFrom,
              state: newAttribute.state,
              value: newAttribute.value,
            });
          }
        }
      });
    }
    return result;
  }

  createAddressAttribute(newAttributes: Array<Nullable<AddressForm>>,
                         oldAttribute: Nullable<Array<SubjectAttributeAddressDto>>): Array<SubjectAttributeAddressDto> {
    const result: SubjectAttributeAddressDto[] = [];

    newAttributes?.forEach(newAttribute => {
      if (newAttribute) {
        const address: AddressCompleteDto = formatAddressForDto(newAttribute);
        if (isCzSkAddressPartiallyFilled(address)) {

          // In create mode, API requires value origins to be specified
          if (!oldAttribute?.length) {
            if (newAttribute?.value) {
              result.push({
                source: newAttribute.source!,
                validFrom: newAttribute.validFrom!,
                state: newAttribute.state!,
                value: address,
              });
            }
          }
          else {
            if (newAttribute?.id) {
              const updatedAttribute = oldAttribute.find(o => o.id === newAttribute.id)!;
              updatedAttribute.value = address;
              result.push(updatedAttribute);
            }
            else if (newAttribute) {
              result.push({
                source: newAttribute.source!,
                validFrom: newAttribute.validFrom!,
                state: newAttribute.state!,
                value: address,
              });
            }
          }
        }
      }
    });

    return result;
  }

  /**
   * Returns the attributes object of a subject to be create or updated. To delete an existing subject attribute,
   *  attribute needs to be sent with it's assigned ID and value: null. Omitting the attribute from request does not delete the attribute
   */
  formValueToAttributesDto(formValue: Record<SubjectAttributeType, (SubjectAttributeStringDto | SubjectAttributeDataBoxDto | AddressForm) | Array<SubjectAttributeStringDto | SubjectAttributeDataBoxDto>>,
                           oldAttributes?: Nullable<SubjectAttributesDto>):
    SubjectAttributesDto {

    const result: SubjectAttributesDto = {};
    Object.entries(formValue).forEach(([k, v]) => {
      switch (k) {
        case SubjectAttributeType.ART_1_P_3_ID:
        case SubjectAttributeType.BIRTH_DATE:
        case SubjectAttributeType.BIRTH_NAME:
        case SubjectAttributeType.BIRTH_PLACE:
        case SubjectAttributeType.BIRTH_SURNAME:
        case SubjectAttributeType.BUSINESS_NAME:
        case SubjectAttributeType.CID:
        case SubjectAttributeType.CLIENT_ID:
        case SubjectAttributeType.COMPANY_ID:
        case SubjectAttributeType.DEGREE_AFTER:
        case SubjectAttributeType.DEGREE_BEFORE:
        case SubjectAttributeType.DRIVING_LICENCE_ID:
        case SubjectAttributeType.EORI_CODE:
        case SubjectAttributeType.EXCISE_TAX_ID:
        case SubjectAttributeType.FIRST_NAME:
        case SubjectAttributeType.GENDER:
        case SubjectAttributeType.IDENTITY_CARD_ID:
        case SubjectAttributeType.LE_ID:
        case SubjectAttributeType.LEGAL_FORM:
        case SubjectAttributeType.NOTE:
        case SubjectAttributeType.PASSPORT_ID:
        case SubjectAttributeType.SURNAME:
        case SubjectAttributeType.TAX_ID:
        case SubjectAttributeType.VAT_ID:
          // @ts-ignore
          result[k] = this.createAttributeWithMultiplicityOne(v!, oldAttributes?.[k]);
          break;
        case SubjectAttributeType.MAILING_ADDRESS:
          result.mailingAddress = this.createAddressAttribute([v! as AddressForm], [oldAttributes?.mailingAddress!])[0];
          break;
        case SubjectAttributeType.RESIDENTIAL_ADDRESS:
          result.residentialAddress = this.createAddressAttribute([v! as AddressForm], [oldAttributes?.residentialAddress!])[0];
          break;
        case SubjectAttributeType.ADDITIONAL_ADDRESS:
          result.additionalAddresses = this.createAddressAttribute(v! as AddressForm[], oldAttributes?.additionalAddresses!);
          break;
        case SubjectAttributeType.POSTAL_BOX:
          result.postalBoxes = this.createAddressAttribute(v! as AddressForm[], oldAttributes?.postalBoxes!);
          break;
        case SubjectAttributeType.EMAIL:
        case SubjectAttributeType.PHONE_FAX:
        case SubjectAttributeType.DATA_BOX:
          // @ts-ignore
          result[k] = this.createAttributeWithMultipleValues(v! as Array<SubjectAttributeStringDto | SubjectAttributeDataBoxDto>, oldAttributes?.[k]);
          break;
        default:
          break;
      }
    });
    return result;
  }

}
