/* tslint:disable */
/* eslint-disable */

/**
 * Sheet label template type
 */
export enum SheetLabelTemplateType {
  ADDRESS = 'ADDRESS',
  OBJECT = 'OBJECT'
}
