<icz-tabs [tabs]="tabs" [activeTab]="activeTab" [class.page-background]="!isInModalContext" [withStickyTabs]="!isInModalContext"
  (tabClick)="setStepByTab($event)" class="grow" [hideTabset]="hideTabset">
  @for (step of steps; track step.id) {
    <ng-template [iczTab]="step.id">
      <ng-template [ngTemplateOutlet]="step.content"></ng-template>
    </ng-template>
  }
  <ng-content select="[extraContent]" extraContent></ng-content>
</icz-tabs>

@if (showSubmitBar) {
  <icz-form-buttons>
    <ng-container leftButtons>
      @if (!readonlyWizard && !hideNextButton) {
        <icz-button
          primary iczFormSubmit
          [iczFormSubmit.disableChecks]="disableNextStepFormChecks"
          [disabled]="submitDisabled"
          (onAction)="nextStep()"
          [label]="submitLabel"
        ></icz-button>
      }
      <ng-content select="[extraLeftButtons]"></ng-content>
    </ng-container>
    <icz-button iczFormCancel rightButtons (onAction)="close()" label="Storno"></icz-button>
  </icz-form-buttons>
}
