import {DestroyRef, inject, Pipe, PipeTransform} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CodebookService} from '../../core/services/codebook.service';
import {DisposalScheduleDto} from '|api/codebook';

@Pipe({
  name: 'disposalSchedule',
  pure: false,
})
export class DisposalSchedulePipe implements PipeTransform {

  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  private disposalSchedules: DisposalScheduleDto[] = [];

  constructor() {
    this.codebookService.disposalSchedules().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(disposalSchedules => {
      this.disposalSchedules = disposalSchedules;
    });
  }

  transform(value: Nullable<number>): string {
    let result = '';
    if (value) {
      const disposalSchedule = this.disposalSchedules.find(ds => ds.id === value);
      if (disposalSchedule) {
        result = `${disposalSchedule.disposalOperationCode}-${disposalSchedule.retentionPeriod} - ${disposalSchedule.name}`;
      }
    }

    return result;
  }

}
