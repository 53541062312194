import {ChangeDetectionStrategy, Component, inject, OnInit, ViewChild} from '@angular/core';
import {OwnConsignmentStatus} from '|api/commons';
import {
  ConsignmentBarcodeScanAndFindService
} from '../../../barcode-scanned-consignments-form/consignment-barcode-scan-and-find.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {forkJoin} from 'rxjs';
import {DeliveryResultDto} from '|api/codebook';
import {DELIVERY_TYPE_POST_CODE} from '../../../shared-document.utils';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {
  ConsignmentCommand
} from '../../../barcode-scanned-consignments-table/barcode-scanned-consignments-table.component';
import {
  getDeliveryResultSpecificationFormGroup
} from '../manual-delivery-result-form/manual-delivery-result-form.component';
import {
  getBarcodeScannedConsignmentsForm
} from '../../../barcode-scanned-consignments-form/barcode-scanned-consignments-form.component';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {injectModalData, injectModalRef} from '../../../../../lib/modals';
import {WizardComponent} from '../../../../dialogs/wizard/wizard.component';
import {Option} from '../../../../../model';
import {IczDateValidators} from '../../../../form-elements/validators/icz-validators/icz-date-validators';
import {ApiOwnConsignmentService, BulkProofOfDeliveryCreateDto, ProofOfDeliveryCreateDto} from '|api/sad';

enum ManualDeliveryResultByBarcodeDialogStep {
  SCAN_CONSIGNMENTS = 'SCAN_CONSIGNMENTS',
  DELIVERY_RESULT_SPECIFICATION = 'DELIVERY_RESULT_SPECIFICATION',
}

export interface ManualDeliveryResultByBarcodeDialogData {
  dispatchOfficeDistributionNodeIds: number[];
}

@Component({
  selector: 'icz-manual-delivery-result-by-barcode',
  templateUrl: './manual-delivery-result-by-barcode.component.html',
  styleUrls: ['./manual-delivery-result-by-barcode.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService,
    ConsignmentBarcodeScanAndFindService,
  ]
})
export class ManualDeliveryResultByBarcodeDialogComponent implements OnInit, IFormGroupCheckable {
  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<number>>();
  private consignmentBarcodeScanAndFindService = inject(ConsignmentBarcodeScanAndFindService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private modalData = injectModalData<ManualDeliveryResultByBarcodeDialogData>();
  private codebookService = inject(CodebookService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);

  @ViewChild(WizardComponent)
  wizard!: WizardComponent;

  formGroupsToCheck!: string[];
  form = getBarcodeScannedConsignmentsForm();
  deliveryResultSpecificationFormGroup = getDeliveryResultSpecificationFormGroup(false);

  readonly ManualDeliveryResultByBarcodeDialogStep = ManualDeliveryResultByBarcodeDialogStep;
  deliveryResults: DeliveryResultDto[] = [];
  deliveryResultOptions: Option[] = [];
  consignmentsToProcess: ConsignmentCommand[] = [];

  isValidAcknowledgementDate = IczDateValidators.pastOrPresentDateValidator;

  get currentDeliveryResultWizardStep() {
    return (this.wizard?.currentStep?.id as Nullable<ManualDeliveryResultByBarcodeDialogStep>) ?? ManualDeliveryResultByBarcodeDialogStep.DELIVERY_RESULT_SPECIFICATION;
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['deliveryResultSpecificationFormGroup']);

    this.consignmentBarcodeScanAndFindService.initialize(
      this.modalData.dispatchOfficeDistributionNodeIds,
      [
        OwnConsignmentStatus.DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION,
        OwnConsignmentStatus.DISPATCHED
      ]
    );

    this.loadingService.doLoading(
      forkJoin([
        this.codebookService.deliveryTypes(),
        this.codebookService.deliveryResults(),
      ]), this).subscribe(([deliveryTypes, deliveryResults]) => {
      this.deliveryResults = deliveryResults;
      const deliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_POST_CODE)!.id;
      this.deliveryResultOptions = deliveryResults.filter(d => d.deliveryTypeId === deliveryTypeId).map(namedDtoToOption());
    });
  }

  onConsignmentCommandsChanged(consignments: ConsignmentCommand[]) {
    this.consignmentsToProcess = consignments;
  }

  isDeliveryResultSpecificationStepValid() {
    return true;
  }

  goToStep(step: ManualDeliveryResultByBarcodeDialogStep) {
    this.wizard?.setStepById(step);
  }

  cancel() {
    this.modalRef.close(null);
  }

  submit() {
    const formValue = this.deliveryResultSpecificationFormGroup.getRawValue() as ProofOfDeliveryCreateDto;
    const body: BulkProofOfDeliveryCreateDto = {
      proofOfDeliveryCreateDto: formValue,
      consignmentIdList: this.consignmentsToProcess.map(consignmentToProcess => consignmentToProcess.consignment.id),
    };

    this.form.reset();

    this.loadingService.doLoading(
      this.apiOwnConsignmentService.ownConsignmentBulkCreateProofOfDelivery(
        {
          body
        }
      ),
      this,
    ).subscribe(_ => {
      this.modalRef.close(this.consignmentsToProcess.length);
    });
  }
}
