import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {PrimitiveValueFormField} from '../form-field';
import {MatCheckboxChange} from '@angular/material/checkbox';

export type LabelPosition = 'before' | 'after';

@Component({
  selector: 'icz-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxComponent extends PrimitiveValueFormField {
  @Input()
  labelPosition: LabelPosition = 'after';
  @Input()
  secondaryLabel: string = '';
  @Input()
  indeterminate!: boolean;
  @HostBinding('class.with-generous-click-area')
  @Input()
  generousClickArea = false;
  @Input()
  verticalAlignmentWithFormField = false;

  @Input()
  set checked(value: boolean) {
    this.value = value;
  }
  get checked(): boolean {
    return this.value as boolean;
  }

  @Output()
  changed = new EventEmitter<boolean>();

  _valueChanged($event: MatCheckboxChange) {
    const newValue = $event.checked;

    this.value = newValue;
    this.changed.emit(newValue);
    this.valueChange.emit(newValue);
    this.blur.emit();
  }
}
