/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CollectionFileRefNumberIdentifierGeneratorCreateDto } from '../../models/collection-file-ref-number-identifier-generator-create-dto';
import { CollectionFileRefNumberIdentifierGeneratorDto } from '../../models/collection-file-ref-number-identifier-generator-dto';

export interface IdentifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Params {
      body: CollectionFileRefNumberIdentifierGeneratorCreateDto
}

export function identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator(http: HttpClient, rootUrl: string, params: IdentifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator$Params, context?: HttpContext): Observable<StrictHttpResponse<CollectionFileRefNumberIdentifierGeneratorDto>> {
  const rb = new RequestBuilder(rootUrl, identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<CollectionFileRefNumberIdentifierGeneratorDto>;
    })
  );
}

identifierGeneratorAdministrationCreateCollectionFileRefNumberGenerator.PATH = '/records/identifier-generator-administration/collection-file-ref-number-generator';