<mat-radio-button
  class="flex grow normal-whitespace"
  (focusout)="blur.emit($event)"
  (change)="radioValueChanged($event)"
  [checked]="checked"
  [value]="value"
  [disabled]="fieldDisabled">
  {{label | translate}}
  <ng-content></ng-content>
</mat-radio-button>
@if (secondaryLabel) {
  <div class="icz-body-1 secondary-label">{{secondaryLabel | translate}}</div>
}
