@if (!documentDetailService || !loadingService.isLoading(documentDetailService)) {
  <div class="row-group" tools>
    @if (isToolbarLoading) {
      <icz-spinner
        class="mt-4 mr-8"
        [diameter]="30"
      ></icz-spinner>
    }
    <icz-button-collection
      size="large"
      [groupButtons]="true"
      [hideBorders]="true"
      [buttons]="toolbarButtons"
      [areCollapsed]="isToolbarCollapsed$ | async"
      [disableAll]="isToolbarLoading"
      (buttonClicked)="buttonClicked($event)"
    ></icz-button-collection>
  </div>
}
