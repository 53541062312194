import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {DeliveryTypeDto} from '|api/codebook';
import {ConsignmentType, DistributionClass, SubjectRecordClassification} from '|api/commons';
import {DistributionNodeType} from '|api/config-server';
import {
  CustomTextEnvelopeSectionDto,
  EnvelopeSectionType,
  EnvelopeTemplateDto,
  OwnInternalPaperConsignmentCreateDto,
  OwnPaperConsignmentCreateDto,
} from '|api/sad';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {DialogService} from '../../../../../core/services/dialog.service';
import {Option} from '../../../../../model';
import {IczModalService} from '../../../../../services/icz-modal.service';
import {IczOnChanges, IczSimpleChanges} from '../../../../../utils/icz-on-changes';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {
  TemplateCustomFieldDefinition
} from '../../../envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {AddressCompleteDto, AddressWithTypeAndValidityDto} from '../../../model/addresses.model';
import {SubjectAndAddress} from '../../../model/subjects.model';
import {AbstractConsignmentSpecificationComponent} from '../abstract-consignment-specification.component';
import {OwnPaperOrPersonalConsignmentForm} from '../consignment-dialog.form';
import {
  ConsignmentAddressDialogComponent,
  ConsignmentAddressDialogData,
  ConsignmentAddressDialogResult
} from './consignment-address-dialog/consignment-address-dialog.component';
import {SubjectsService} from '../../../../../services/subjects.service';

import {sheetNodeToOption} from '../consignment.model';
import {SubjectAttributeType} from '../../../model/subject-attribute-type';

@Component({
  selector: 'icz-paper-or-personal-or-internal-consignment-specification',
  templateUrl: './paper-or-personal-or-internal-consignment-specification.component.html',
  styleUrls: ['./paper-or-personal-or-internal-consignment-specification.component.scss'],
})
export class PaperOrPersonalOrInternalConsignmentSpecificationComponent extends AbstractConsignmentSpecificationComponent implements OnInit, IczOnChanges {

  private modalService = inject(IczModalService);
  private codebookService = inject(CodebookService);
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private subjectsService = inject(SubjectsService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  override specificFieldsForm!: OwnPaperOrPersonalConsignmentForm;
  @Input({required: true})
  consigneeAddressOverrides: SubjectAndAddress[] = [];
  @Input()
  additionalAddresses: AddressWithTypeAndValidityDto[] = [];
  @Input({required: true})
  distributionClassForDispatch!: DistributionClass;
  @Output()
  addressAdded = new EventEmitter<AddressWithTypeAndValidityDto[]>();
  @Input()
  disabled = false;
  @Input()
  canAddNewAddress = true;
  @Input()
  selectedDeliveryType: Nullable<DeliveryTypeDto>;
  @Input({required: true})
  consignmentForEnvelopePreview!: Nullable<OwnPaperConsignmentCreateDto | OwnInternalPaperConsignmentCreateDto>;

  showAdditionalDeliveryInfo = false;
  envelopeTemplateOptions: Option<number, EnvelopeTemplateDto>[] = [];
  envelopeCustomFieldDefs: TemplateCustomFieldDefinition[] = [];
  sheetNodeOptions: Option<number>[] = [];

  areDeliveryServiceCombinationsLoading = false;

  get isPaperConsignment() {
    return this.distributionClassForDispatch === DistributionClass.ADDRESS_PLACE;
  }

  get isPersonalConsignment() {
    return this.distributionClassForDispatch === DistributionClass.ON_PLACE;
  }

  get isInternalConsignment() {
    return this.distributionClassForDispatch === DistributionClass.INTERNAL;
  }

  get showAdditionalInfoSection() {
    return this.isPaperConsignment || (this.isInternalConsignment && !this.isDigitalDeliveryType);
  }

  get selectedEnvelopeTemplate(): Nullable<EnvelopeTemplateDto> {
    const envelopeTemplateId = Number(this.specificFieldsForm.value.envelopeTemplateId);
    return this.envelopeTemplateOptions.find(o => o.value === envelopeTemplateId)?.data;
  }

  get consignmentUid(): Nullable<string> {
    return this.specificFieldsForm.getRawValue().uid?.uid;
  }

  readonly ConsignmentType = ConsignmentType;
  readonly DistributionClass = DistributionClass;
  readonly SubjectRecordClassification = SubjectRecordClassification;
  isDigitalDeliveryType = false;

  ngOnInit(): void {
    this.preselectConsigneeAddress();
    this.initializeSheetNodeOptions();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.distributionClassForDispatch && this.sheetNodeOptions) {
      this.preselectSheetNode();
    }

    if (changes.selectedDeliveryType && changes.selectedDeliveryType.currentValue) {
      this.isDigitalDeliveryType = changes.selectedDeliveryType.currentValue.forDigital;
    }

    if (changes.specificFieldsForm && changes.specificFieldsForm.currentValue) {
      this.initializeForm();
    }
  }

  addAddress() {
    this.modalService.openComponentInModal<ConsignmentAddressDialogResult, ConsignmentAddressDialogData>({
      component: ConsignmentAddressDialogComponent,
      modalOptions: {
        width: 500,
        height: 720,
        titleTemplate: 'Přidání adresy',
      },
      data: {
        createModeSubjectClassification: this.consignee.classification!,
      },
    }).subscribe(result => {
      if (result) {
        const resultAddress = result.address;

        if ((resultAddress.type! === SubjectAttributeType.MAILING_ADDRESS || resultAddress.type! === SubjectAttributeType.RESIDENTIAL_ADDRESS) &&
          this.consignee.attributes[resultAddress.type] ){
          this.dialogService.openSimpleDialog({
            title: 'Přidání adresy',
            content: [
              {
                text: 'Nelze přidat novou adresu. Subjekt již má adresu daného typu.'
              }
            ],
          });
        } else {
          this.additionalAddresses = [
            ...this.additionalAddresses,
            {
              ...resultAddress,
              isCurrentlyValid: true,
            },
          ];

          this.specificFieldsForm.get('consigneeAddress')!.setValue(resultAddress.address);
          this.addressAdded.emit(this.additionalAddresses);
        }
      }
    });
  }

  private preselectConsigneeAddress() {
    const consigneeAddressControl = this.specificFieldsForm.get('consigneeAddress')!;

    if (!consigneeAddressControl.value) {
      if (this.consigneeAddressOverrides.length) {
        const overrideForCurrentConsignee = this.consigneeAddressOverrides.find(o => o.subject === this.consignee);

        if (overrideForCurrentConsignee) {
          consigneeAddressControl.setValue(overrideForCurrentConsignee.address);
        }
      }
      else {
        const consigneeAddresses = this.subjectsService.getSubjectPhysicalAddresses(this.consignee?.attributes);

        if (consigneeAddresses) {
          if (Object.keys(consigneeAddresses!).length === 1) {
            consigneeAddressControl.setValue(consigneeAddresses?.mailing ?? consigneeAddresses?.residential ?? consigneeAddresses.additional![0]);
          }
          else {
            const mailingAddress = consigneeAddresses.mailing;

            if (mailingAddress) {
              consigneeAddressControl.setValue(mailingAddress.value as AddressCompleteDto);
            }
          }
        }
      }
    }
  }

  private initializeForm() {
    if (this.disabled) {
      this.specificFieldsForm.disable();
    }
    else {
      const deliveryServiceCombinationControl = this.specificFieldsForm.get('deliveryServiceCombinationId')!;

      deliveryServiceCombinationControl.valueChanges.pipe(
        startWith(deliveryServiceCombinationControl.value),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(deliveryServiceCombinationId => {
        this.initializeEnvelopeTemplateOptions(deliveryServiceCombinationId!);
      });
    }

    if (this.isInternalConsignment) {
      this.specificFieldsForm.get('deliveryServiceCombinationId')!.setValidators([IczValidators.required()]);
      this.specificFieldsForm.get('dispatchOfficeDistributionNodeId')!.setValidators([IczValidators.required()]);
      this.specificFieldsForm.get('deliveryServiceCombinationId')!.updateValueAndValidity();
      this.specificFieldsForm.get('dispatchOfficeDistributionNodeId')!.updateValueAndValidity();
    }
  }

  private initializeSheetNodeOptions() {
    this.codebookService.sheetNodes().pipe(map(sheetNodes => sheetNodes
      .filter(sheetNode => sheetNode.isActive && sheetNode.dispatchOfficeId)
      .filter(sheetNode => sheetNode.purpose === DistributionNodeType.SEND || sheetNode.purpose === DistributionNodeType.SEND_RECEIVE)
      .map(sheetNodeToOption(this.translateService)))
    ).subscribe(sheetNodeOptions => {
      this.sheetNodeOptions = sheetNodeOptions;
      this.preselectSheetNode();
    });
  }

  private initializeEnvelopeTemplateOptions(limitingDeliveryServiceCombinationId?: number) {
    this.codebookService.envelopeTemplates().subscribe(envelopeTemplates => {
      if (limitingDeliveryServiceCombinationId) {
        envelopeTemplates = envelopeTemplates.filter(et => (
          !et.limitToDeliveryServiceCombinationId ||
          et.limitToDeliveryServiceCombinationId.length === 0 ||
          et.limitToDeliveryServiceCombinationId.includes(limitingDeliveryServiceCombinationId)
        ));

        const envelopeTemplateControl = this.specificFieldsForm.get('envelopeTemplateId')!;
        const envelopeTemplateId = envelopeTemplateControl.value;

        if (!envelopeTemplates.find(et => et.id === envelopeTemplateId)) {
          envelopeTemplateControl.setValue(null);
        }
      }

      this.envelopeTemplateOptions = envelopeTemplates.map(et => ({
        ...namedDtoToOption()(et),
        data: et,
      }));

      this.setUpEnvelopeCustomFieldDefs();

      this.specificFieldsForm.get('envelopeTemplateId')!.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        // this timeout is needed because the value of this.selectedEnvelopeTemplate
        //  is consistent with form model after one change detection tick
        setTimeout(() => {
          this.setUpEnvelopeCustomFieldDefs();
        }, 0);
      });
    });
  }

  private preselectSheetNode() {
    const sheetNodeControl = this.specificFieldsForm.get('dispatchOfficeDistributionNodeId')!;

    if (this.sheetNodeOptions.length === 1) {
      sheetNodeControl.setValue(this.sheetNodeOptions[0].value);
    }
  }

  private setUpEnvelopeCustomFieldDefs() {
    if (this.selectedEnvelopeTemplate) {
      const textSections = this.selectedEnvelopeTemplate.sections.filter(
        s => s.sectionType === EnvelopeSectionType.TEXT
      ) as CustomTextEnvelopeSectionDto[];

      this.envelopeCustomFieldDefs = textSections.map(s => ({
        key: s.sectionKey,
        label: s.name,
      }));
    }
    else {
      this.envelopeCustomFieldDefs = [];
    }
  }

}
