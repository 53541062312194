import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {delay, filter} from 'rxjs/operators';
import {OwnConsignmentWorkflowService} from '../../own-consignment-table/own-consignment-workflow.service';
import {ConsignmentToolbarAction, ConsignmentToolbarButtonsService} from './consignment-toolbar-buttons.service';
import {
  GenericOwnConsignment,
  OwnConsignmentOfficeDeskTableView,
  OwnConsignmentTableView
} from '../../own-consignment-table/model/own-consignment-model';
import {ToolbarDataService} from '../../../table/table-toolbar/toolbar-data.service';
import {IczSimpleChanges} from '../../../../utils/icz-on-changes';
import {DocumentDetailCountType, DocumentDetailService} from '../../../../services/document-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {increment, load} from '../../../../lib/object-counts';
import {ConsignmentDialogService} from '../../consignment-dialog/consignment-dialog.service';
import {CodebookService} from '../../../../core/services/codebook.service';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {ELASTIC_RELOAD_DELAY} from '../../document-toolbar/services/toolbar-common.utils';
import {AbstractToolbarButtonsComponent} from '../../../essentials/abstract-toolbar-buttons.component';


@Component({
  selector: 'icz-consignment-toolbar-buttons',
  templateUrl: './consignment-toolbar-buttons.component.html',
  styleUrls: ['./consignment-toolbar-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ConsignmentDialogService,
    ConsignmentToolbarButtonsService,
    OwnConsignmentWorkflowService,
  ]
})
export class ConsignmentToolbarButtonsComponent extends AbstractToolbarButtonsComponent<GenericOwnConsignment, OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView> implements OnInit {

  private consignmentToolbarButtonsService = inject(ConsignmentToolbarButtonsService);
  private toolbarDataService = inject(ToolbarDataService);
  private codebookService = inject(CodebookService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  protected loadingService = inject(LoadingIndicatorService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});

  @Input({required: true})
  isUnitView!: boolean;
  @Input({required: true})
  dispatchOfficeId!: Nullable<number>;
  @Input({required: true})
  canCreateBulkPostingForm!: boolean;
  @Input()
  excludedConsignmentIdsForBpf?: Array<number>;
  @Input()
  disableAllButtons = false;
  @Input()
  collapseButtons = false;
  @Input()
  dispatchOfficeDistributionNodeIds: number[] = [];

  guidedBPFGenerationEnabled = false; // important to start with false

  ngOnInit() {
    this.consignmentToolbarButtonsService.actionCompleted$.pipe(
      delay(ELASTIC_RELOAD_DELAY),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(action => {
      this.operationCompleted.emit();

      if (this.documentDetailService) {
        switch (action) {
          case ConsignmentToolbarAction.CONSIGNMENT_CREATED:
            this.documentDetailService.reloadObject({
              [DocumentDetailCountType.CONSIGNMENTS]: increment(),
              [DocumentDetailCountType.SUBJECTS]: load(),
            });
            break;
        }
      }
    });

    this.codebookService.ownPaperConsignmentConfiguration().subscribe(config => {
      this.guidedBPFGenerationEnabled = config.guidedBPFGenerationEnabled;
      this.generateToolbarContents(this.selectedRows);
    });

    if (this.documentDetailService) {
      this.documentDetailService.object$.pipe(
        filter(Boolean),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        this.generateToolbarContents(this.selectedRows);
      });
    }
  }

  override ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.selectedRows || changes.dispatchOfficeId || changes.canCreateBulkPostingForm || changes.excludedConsignmentIdsForBpf) {
      this.generateToolbarContents(this.selectedRows);
    }
  }

  protected generateToolbarContents(rows: Nullable<(GenericOwnConsignment)[]>) {
    this.loadingService.doLoading(this.consignmentToolbarButtonsService.getToolbarButtons(rows, {
      tableView: this.view,
      dispatchOfficeId: this.dispatchOfficeId,
      isUnitView: this.isUnitView,
      canCreateBulkPostingForm: this.canCreateBulkPostingForm,
      excludedConsignmentIdsForBpf: this.excludedConsignmentIdsForBpf,
      guidedBPFGenerationEnabled: this.guidedBPFGenerationEnabled,
      dispatchOfficeDistributionNodeIds: this.dispatchOfficeDistributionNodeIds,
    }), this).subscribe(buttons => {
      this.toolbarButtons = buttons;
      this.cd.detectChanges();
    });
  }

}
