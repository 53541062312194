import {Component, DestroyRef, HostBinding, inject, Input, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {EnvironmentIconColorScheme, UserSettingsService} from '../../../services/user-settings.service';
import {ICONS} from './icons';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';

export type IconSize = 'tiny' | 'small' | 'compact' | 'default' | 'large';

@Component({
  selector: 'icz-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IconComponent implements IczOnChanges {

  private userSettings = inject(UserSettingsService);
  private applicationConfigService = inject(ApplicationConfigService);
  private destroyRef = inject(DestroyRef);

  @Input() svgIcon: Nullable<string>;

  @Input() showCircle = true;

  @Input() size: IconSize = 'default';
  @HostBinding('class.tiny') get isTiny() { return this.size === 'tiny'; }
  @HostBinding('class.small') get isSmall() { return this.size === 'small'; }
  @HostBinding('class.large') get isLarge() { return this.size === 'large'; }
  @HostBinding('class.compact') get isCompact() { return this.size === 'compact'; }

  @Input()
  set withCustomIconColor(value: Nullable<boolean | ''>) { this._withCustomIconColor = value === '' ? true : Boolean(value); }
  get withCustomIconColor() { return this._withCustomIconColor; }
  _withCustomIconColor = false;

  private _svgIcon$ = new BehaviorSubject<Nullable<string>>(null);

  svgIconByScheme$ = combineLatest([
    this.userSettings.environmentIconColorScheme$.pipe(distinctUntilChanged()),
    this._svgIcon$.pipe(distinctUntilChanged()),
  ]).pipe(
    takeUntilDestroyed(this.destroyRef),
    map(([localStorageColorScheme, svgIcon]) => {
      const colorScheme = localStorageColorScheme ?? this.applicationConfigService.defaultIconColorScheme;

      if ((svgIcon + this.colorSchemeMapping[colorScheme]) in ICONS) {
        return (svgIcon + this.colorSchemeMapping[colorScheme]);
      }
      else {
        return svgIcon;
      }
    })
  );

  private colorSchemeMapping = {
    [EnvironmentIconColorScheme.GRAY_SCHEME]: '',
    [EnvironmentIconColorScheme.COLOR_SCHEME]: '__color'
  };

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.svgIcon) {
      this._svgIcon$.next(this.svgIcon);
    }
  }

}
