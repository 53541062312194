/* tslint:disable */
/* eslint-disable */

/**
 * Node type
 */
export enum NodeType {
  NODE = 'NODE',
  LEAF = 'LEAF'
}
