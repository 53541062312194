/* tslint:disable */
/* eslint-disable */

/**
 * Action executed on tasks
 */
export enum CirculationTaskAction {
  REVOKE_BY_INITIATOR = 'REVOKE_BY_INITIATOR',
  TAKEOVER_BY_INITIATOR = 'TAKEOVER_BY_INITIATOR',
  TAKEOVER_AND_PASS_BY_INITIATOR = 'TAKEOVER_AND_PASS_BY_INITIATOR',
  REJECT_BY_TARGET = 'REJECT_BY_TARGET',
  RETURN_BY_TARGET = 'RETURN_BY_TARGET',
  TAKEOVER_BY_TARGET = 'TAKEOVER_BY_TARGET',
  TAKEOVER_AND_PASS_BY_TARGET = 'TAKEOVER_AND_PASS_BY_TARGET',
  PASS_BY_TARGET = 'PASS_BY_TARGET',
  APPROVE_DOCUMENT_BY_TARGET = 'APPROVE_DOCUMENT_BY_TARGET',
  DISAPPROVE_DOCUMENT_BY_TARGET = 'DISAPPROVE_DOCUMENT_BY_TARGET',
  APPROVE_COMPONENT_BY_TARGET = 'APPROVE_COMPONENT_BY_TARGET',
  DISAPPROVE_COMPONENT_BY_TARGET = 'DISAPPROVE_COMPONENT_BY_TARGET',
  STATEMENT_DONE_BY_TARGET = 'STATEMENT_DONE_BY_TARGET',
  ACKNOWLEDGE_BY_TARGET = 'ACKNOWLEDGE_BY_TARGET',
  SIGN_BY_TARGET = 'SIGN_BY_TARGET',
  ASSIGN_TO_INITIATOR = 'ASSIGN_TO_INITIATOR',
  ASSIGN_TO_TARGET = 'ASSIGN_TO_TARGET',
  START_PROGRESS_BY_SYSTEM = 'START_PROGRESS_BY_SYSTEM',
  DONE_BY_SYSTEM = 'DONE_BY_SYSTEM'
}
