import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {ApiOwnConsignmentService} from '|api/sad';
import {IczOnChanges, IczSimpleChanges} from '../../../../../utils/icz-on-changes';
import {IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {Option} from '../../../../../model';

@Component({
  selector: 'icz-applicable-outcome-distribution-node-selector',
  templateUrl: './applicable-outcome-distribution-node-selector.component.html',
  styleUrls: ['./applicable-outcome-distribution-node-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicableOutcomeDistributionNodeSelectorComponent implements IczOnChanges {

  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;
  @Input({required: true})
  deliveryTypeId!: Nullable<number>;
  @Output()
  loadingStatusChanged = new EventEmitter<boolean>(true);

  distributionNodeOptions: Option[] = [];

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.deliveryTypeId) {
      if (this.deliveryTypeId) {
        this.fetchDistributionNodeOptions();
      }
      else {
        this.distributionNodeOptions = [];
      }
    }
  }

  private fetchDistributionNodeOptions() {
    this.loadingStatusChanged.emit(true);

    this.apiOwnConsignmentService.ownConsignmentGetOrganizationOutcomeDistributionNodes({
      deliveryTypeId: this.deliveryTypeId!,
    }).subscribe(
      distributionNodes => {
      this.distributionNodeOptions = distributionNodes
        .filter(node => node.isActive && node.dispatchOfficeId)
        .map(dn => ({
        value: dn.id,
        label: dn.nodeName,
      }));

      if (this.distributionNodeOptions.length === 1) {
        this.form.get(this.controlName)!.setValue(this.distributionNodeOptions[0]!.value);
      }

      this.loadingStatusChanged.emit(false);
    });
  }

}
