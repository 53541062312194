import {Component, inject} from '@angular/core';
import {LoadingIndicatorService} from '../../../../../essentials/loading-indicator.service';
import {injectModalData, injectModalRef} from 'libs/shared/src/lib/lib/modals';

export interface ResolutionErrorDialogData {
  error: any;
  documentId: number;
}

@Component({
  selector: 'icz-document-settle-error-dialog',
  templateUrl: './document-settle-error-dialog.component.html',
  styleUrls: ['./document-settle-error-dialog.component.scss'],
})
export class DocumentSettleErrorDialogComponent {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<boolean>();
  protected modalData = injectModalData<ResolutionErrorDialogData>();

  get hasUnresolvedConsignments() {
    //todo(mh) when BE error codes are known implement
    return false;
  }

  get hasRunningCirculation() {
    //todo(mh) when BE error codes are known implement
    return false;
  }

  get isNotStateForResolution() {
    return this.modalData.error?.error?.errorCode ?? false;
  }

  cancel() {
    this.modalRef.close(false);
  }

}
