/* tslint:disable */
/* eslint-disable */

/**
 * Current status of a background job
 */
export enum EsslJobStatus {
  PLANNED = 'PLANNED',
  RUNNING = 'RUNNING',
  FINISHED_SUCCESSFULLY = 'FINISHED_SUCCESSFULLY',
  FINISHED_WITH_MINOR_COMPLAINTS = 'FINISHED_WITH_MINOR_COMPLAINTS',
  FINISHED_WITH_WARNINGS = 'FINISHED_WITH_WARNINGS',
  FINISHED_WITH_ERRORS = 'FINISHED_WITH_ERRORS',
  FAILED_TO_FINISH = 'FAILED_TO_FINISH'
}
