/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { circulationTaskApprovalApprove } from '../fn/circulation-task-approval/circulation-task-approval-approve';
import { CirculationTaskApprovalApprove$Params } from '../fn/circulation-task-approval/circulation-task-approval-approve';
import { circulationTaskApprovalDisapprove } from '../fn/circulation-task-approval/circulation-task-approval-disapprove';
import { CirculationTaskApprovalDisapprove$Params } from '../fn/circulation-task-approval/circulation-task-approval-disapprove';
import { circulationTaskApprovalPass } from '../fn/circulation-task-approval/circulation-task-approval-pass';
import { CirculationTaskApprovalPass$Params } from '../fn/circulation-task-approval/circulation-task-approval-pass';
import { circulationTaskApprovalReject } from '../fn/circulation-task-approval/circulation-task-approval-reject';
import { CirculationTaskApprovalReject$Params } from '../fn/circulation-task-approval/circulation-task-approval-reject';
import { circulationTaskApprovalRevoke } from '../fn/circulation-task-approval/circulation-task-approval-revoke';
import { CirculationTaskApprovalRevoke$Params } from '../fn/circulation-task-approval/circulation-task-approval-revoke';
import { CirculationTaskDto } from '../models/circulation-task-dto';


/**
 * Controller for executing actions on approval document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskApprovalService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskApprovalRevoke()` */
  static readonly CirculationTaskApprovalRevokePath = '/flow/approval/{id}/revoke';

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskApprovalRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalRevoke$Response(params: CirculationTaskApprovalRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskApprovalRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskApprovalRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalRevoke(params: CirculationTaskApprovalRevoke$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskApprovalRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskApprovalReject()` */
  static readonly CirculationTaskApprovalRejectPath = '/flow/approval/{id}/reject';

  /**
   * Finish task by reject by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskApprovalReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalReject$Response(params: CirculationTaskApprovalReject$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskApprovalReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by reject by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskApprovalReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalReject(params: CirculationTaskApprovalReject$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskApprovalReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskApprovalPass()` */
  static readonly CirculationTaskApprovalPassPath = '/flow/approval/{id}/pass';

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskApprovalPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalPass$Response(params: CirculationTaskApprovalPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskApprovalPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskApprovalPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalPass(params: CirculationTaskApprovalPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskApprovalPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskApprovalDisapprove()` */
  static readonly CirculationTaskApprovalDisapprovePath = '/flow/approval/{id}/disapprove';

  /**
   * Finish task by disapproval by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskApprovalDisapprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalDisapprove$Response(params: CirculationTaskApprovalDisapprove$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskApprovalDisapprove(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by disapproval by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskApprovalDisapprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalDisapprove(params: CirculationTaskApprovalDisapprove$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskApprovalDisapprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskApprovalApprove()` */
  static readonly CirculationTaskApprovalApprovePath = '/flow/approval/{id}/approve';

  /**
   * Finish task by approval by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskApprovalApprove()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalApprove$Response(params: CirculationTaskApprovalApprove$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskApprovalApprove(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by approval by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskApprovalApprove$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskApprovalApprove(params: CirculationTaskApprovalApprove$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskApprovalApprove$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

}
