<div class="row icz-body-1">
  @if (!isSigned) {
    <icz-icon size="small" svgIcon="crossed"></icz-icon>
    <div>{{ 'Zpráva není podepsána elektronickým podpisem' | translate }}</div>
  }
  @else {
    <icz-digital-component-flags
      [flags]="epdzToEsslSignatureFlags(originalAttachment?.signatureFlags)"
      [signatureValidationData]="originalAttachment?.signatureValidationDataDto"
    ></icz-digital-component-flags>
  }
</div>
