/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { IszrAccessCertificateDto } from '../models/iszr-access-certificate-dto';
import { iszrCertificateDeleteIszrCertificate } from '../fn/iszr-certificate/iszr-certificate-delete-iszr-certificate';
import { IszrCertificateDeleteIszrCertificate$Params } from '../fn/iszr-certificate/iszr-certificate-delete-iszr-certificate';
import { iszrCertificateGetIszrCertificate } from '../fn/iszr-certificate/iszr-certificate-get-iszr-certificate';
import { IszrCertificateGetIszrCertificate$Params } from '../fn/iszr-certificate/iszr-certificate-get-iszr-certificate';
import { iszrCertificateGetIszrUrlList } from '../fn/iszr-certificate/iszr-certificate-get-iszr-url-list';
import { IszrCertificateGetIszrUrlList$Params } from '../fn/iszr-certificate/iszr-certificate-get-iszr-url-list';
import { iszrCertificateStoreIszrCertificate } from '../fn/iszr-certificate/iszr-certificate-store-iszr-certificate';
import { IszrCertificateStoreIszrCertificate$Params } from '../fn/iszr-certificate/iszr-certificate-store-iszr-certificate';
import { IszrUrlListDto } from '../models/iszr-url-list-dto';


/**
 * API for managing ISZR certificates
 */
@Injectable({ providedIn: 'root' })
export class ApiIszrCertificateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `iszrCertificateGetIszrCertificate()` */
  static readonly IszrCertificateGetIszrCertificatePath = '/oauth/iszr-access-certificate/';

  /**
   * Retrieve ISZR certificate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrCertificateGetIszrCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrCertificateGetIszrCertificate$Response(params?: IszrCertificateGetIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IszrAccessCertificateDto>> {
    return iszrCertificateGetIszrCertificate(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve ISZR certificate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrCertificateGetIszrCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrCertificateGetIszrCertificate(params?: IszrCertificateGetIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<IszrAccessCertificateDto> {
    return this.iszrCertificateGetIszrCertificate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrAccessCertificateDto>): IszrAccessCertificateDto => r.body)
    );
  }

  /** Path part for operation `iszrCertificateStoreIszrCertificate()` */
  static readonly IszrCertificateStoreIszrCertificatePath = '/oauth/iszr-access-certificate/';

  /**
   * Create Or Update ISZR certificate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrCertificateStoreIszrCertificate()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  iszrCertificateStoreIszrCertificate$Response(params?: IszrCertificateStoreIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IszrAccessCertificateDto>> {
    return iszrCertificateStoreIszrCertificate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create Or Update ISZR certificate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrCertificateStoreIszrCertificate$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  iszrCertificateStoreIszrCertificate(params?: IszrCertificateStoreIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<IszrAccessCertificateDto> {
    return this.iszrCertificateStoreIszrCertificate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrAccessCertificateDto>): IszrAccessCertificateDto => r.body)
    );
  }

  /** Path part for operation `iszrCertificateDeleteIszrCertificate()` */
  static readonly IszrCertificateDeleteIszrCertificatePath = '/oauth/iszr-access-certificate/';

  /**
   * Delete ISZR certificate
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrCertificateDeleteIszrCertificate()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrCertificateDeleteIszrCertificate$Response(params?: IszrCertificateDeleteIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IszrAccessCertificateDto>> {
    return iszrCertificateDeleteIszrCertificate(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete ISZR certificate
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrCertificateDeleteIszrCertificate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrCertificateDeleteIszrCertificate(params?: IszrCertificateDeleteIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<IszrAccessCertificateDto> {
    return this.iszrCertificateDeleteIszrCertificate$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrAccessCertificateDto>): IszrAccessCertificateDto => r.body)
    );
  }

  /** Path part for operation `iszrCertificateGetIszrUrlList()` */
  static readonly IszrCertificateGetIszrUrlListPath = '/oauth/iszr-access-certificate/url-list';

  /**
   * Retrieve ISZR URLs from CredMgr
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `iszrCertificateGetIszrUrlList()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrCertificateGetIszrUrlList$Response(params?: IszrCertificateGetIszrUrlList$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IszrUrlListDto>> {
    return iszrCertificateGetIszrUrlList(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve ISZR URLs from CredMgr
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `iszrCertificateGetIszrUrlList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  iszrCertificateGetIszrUrlList(params?: IszrCertificateGetIszrUrlList$Params|null|undefined, context?: HttpContext): Observable<IszrUrlListDto> {
    return this.iszrCertificateGetIszrUrlList$Response(params, context).pipe(
      map((r: StrictHttpResponse<IszrUrlListDto>): IszrUrlListDto => r.body)
    );
  }

}
