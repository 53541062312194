/* tslint:disable */
/* eslint-disable */

/**
 * Statistic type
 */
export enum Statistic {
  DOCUMENT_SIZE_COUNT = 'DOCUMENT_SIZE_COUNT',
  FILE_SIZE_COUNT = 'FILE_SIZE_COUNT',
  DOCUMENTS_TOTAL_COUNT = 'DOCUMENTS_TOTAL_COUNT',
  FILES_TOTAL_COUNT = 'FILES_TOTAL_COUNT',
  DOCUMENTS_RANGE_COUNT = 'DOCUMENTS_RANGE_COUNT',
  FILES_RANGE_COUNT = 'FILES_RANGE_COUNT',
  FILES_IN_STORAGE_UNIT_RANGE_COUNT = 'FILES_IN_STORAGE_UNIT_RANGE_COUNT',
  DIGITAL_COMPONENT_RANGE_COUNT = 'DIGITAL_COMPONENT_RANGE_COUNT',
  FILES_BY_ACCESS_ENTITLEMENT = 'FILES_BY_ACCESS_ENTITLEMENT'
}
