import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CirculationActivityType} from '|api/commons';

@Component({
  selector: 'icz-activity-type-designator',
  templateUrl: './activity-type-designator.component.html',
  styleUrls: ['./activity-type-designator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityTypeDesignatorComponent {
  @Input({required: true})
  activityType!: CirculationActivityType;

  readonly CirculationActivityType = CirculationActivityType;
}
