/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { BasicSettingsDto } from '../models/basic-settings-dto';
import { basicSettingsOauthFind } from '../fn/basic-settings-oauth/basic-settings-oauth-find';
import { basicSettingsOauthFind_1 } from '../fn/basic-settings-oauth/basic-settings-oauth-find-1';
import { BasicSettingsOauthFind_1$Params } from '../fn/basic-settings-oauth/basic-settings-oauth-find-1';
import { BasicSettingsOauthFind$Params } from '../fn/basic-settings-oauth/basic-settings-oauth-find';
import { basicSettingsOauthUpdate } from '../fn/basic-settings-oauth/basic-settings-oauth-update';
import { BasicSettingsOauthUpdate$Params } from '../fn/basic-settings-oauth/basic-settings-oauth-update';


/**
 * API for OAuth2 protected REST endpoints for management of application settings
 */
@Injectable({ providedIn: 'root' })
export class ApiBasicSettingsOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `basicSettingsOauthFind()` */
  static readonly BasicSettingsOauthFindPath = '/oauth/basic-settings';

  /**
   * Finds basic settings of the current organization
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSettingsOauthFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSettingsOauthFind$Response(params?: BasicSettingsOauthFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<BasicSettingsDto>> {
    return basicSettingsOauthFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds basic settings of the current organization
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSettingsOauthFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSettingsOauthFind(params?: BasicSettingsOauthFind$Params|null|undefined, context?: HttpContext): Observable<BasicSettingsDto> {
    return this.basicSettingsOauthFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSettingsDto>): BasicSettingsDto => r.body)
    );
  }

  /** Path part for operation `basicSettingsOauthUpdate()` */
  static readonly BasicSettingsOauthUpdatePath = '/oauth/basic-settings';

  /**
   * Updates basic settings or creates new if it does not exist
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSettingsOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSettingsOauthUpdate$Response(params: BasicSettingsOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicSettingsDto>> {
    return basicSettingsOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates basic settings or creates new if it does not exist
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSettingsOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  basicSettingsOauthUpdate(params: BasicSettingsOauthUpdate$Params, context?: HttpContext): Observable<BasicSettingsDto> {
    return this.basicSettingsOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSettingsDto>): BasicSettingsDto => r.body)
    );
  }

  /** Path part for operation `basicSettingsOauthFind_1()` */
  static readonly BasicSettingsOauthFind_1Path = '/oauth/basic-settings/{organizationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `basicSettingsOauthFind_1()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSettingsOauthFind_1$Response(params: BasicSettingsOauthFind_1$Params, context?: HttpContext): Observable<StrictHttpResponse<BasicSettingsDto>> {
    return basicSettingsOauthFind_1(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `basicSettingsOauthFind_1$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  basicSettingsOauthFind_1(params: BasicSettingsOauthFind_1$Params, context?: HttpContext): Observable<BasicSettingsDto> {
    return this.basicSettingsOauthFind_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<BasicSettingsDto>): BasicSettingsDto => r.body)
    );
  }

}
