import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {InterpolationContext} from '../../../lib/model';

export type IczTagColor = 'orange' | 'green' | 'grey' | 'red' | 'blue' | 'purple';

@Component({
  selector: 'icz-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() svgIcon!: string;
  @Input({required: true}) label: Nullable<string>;
  @Input() labelContext: Nullable<InterpolationContext>;

  @Input() color: IczTagColor = 'orange';

  @Input()
  inline = false;

  @HostBinding('class.inline-flex')
  get isInlineFlex() {
    return this.inline;
  }

  @HostBinding('class.flex')
  get isFlex() {
    return !this.inline;
  }

  @HostBinding('class.red')
  get isRed() {
    return this.color === 'red';
  }

  @HostBinding('class.green')
  get isGreen() {
    return this.color === 'green';
  }

  @HostBinding('class.grey')
  get isGrey() {
    return this.color === 'grey';
  }

  @HostBinding('class.orange')
  get isOrange() {
    return this.color === 'orange';
  }

  @HostBinding('class.blue')
  get isBlue() {
    return this.color === 'blue';
  }

  @HostBinding('class.purple')
  get isPurple() {
    return this.color === 'purple';
  }
}
