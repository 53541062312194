/* tslint:disable */
/* eslint-disable */

/**
 * Import type
 */
export enum ImportType {
  ORGADMIN = 'ORGADMIN',
  EOS = 'EOS',
  INITIAL_ADMIN_IMPORT = 'INITIAL_ADMIN_IMPORT'
}
