/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { crossReferenceTemplateCreate } from '../fn/cross-reference-template/cross-reference-template-create';
import { CrossReferenceTemplateCreate$Params } from '../fn/cross-reference-template/cross-reference-template-create';
import { crossReferenceTemplateDelete } from '../fn/cross-reference-template/cross-reference-template-delete';
import { CrossReferenceTemplateDelete$Params } from '../fn/cross-reference-template/cross-reference-template-delete';
import { CrossReferenceTemplateDto } from '../models/cross-reference-template-dto';
import { crossReferenceTemplateFindById } from '../fn/cross-reference-template/cross-reference-template-find-by-id';
import { CrossReferenceTemplateFindById$Params } from '../fn/cross-reference-template/cross-reference-template-find-by-id';
import { crossReferenceTemplateGetByEntityReferringFrom } from '../fn/cross-reference-template/cross-reference-template-get-by-entity-referring-from';
import { CrossReferenceTemplateGetByEntityReferringFrom$Params } from '../fn/cross-reference-template/cross-reference-template-get-by-entity-referring-from';
import { crossReferenceTemplateSearch } from '../fn/cross-reference-template/cross-reference-template-search';
import { CrossReferenceTemplateSearch$Params } from '../fn/cross-reference-template/cross-reference-template-search';
import { crossReferenceTemplateUpdate } from '../fn/cross-reference-template/cross-reference-template-update';
import { CrossReferenceTemplateUpdate$Params } from '../fn/cross-reference-template/cross-reference-template-update';
import { PageCrossReferenceTemplateDto } from '../models/page-cross-reference-template-dto';


/**
 * Controller for Cross reference template
 */
@Injectable({ providedIn: 'root' })
export class ApiCrossReferenceTemplateService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `crossReferenceTemplateFindById()` */
  static readonly CrossReferenceTemplateFindByIdPath = '/codebook/cross-reference-template/{id}';

  /**
   * Find cross reference template by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceTemplateFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceTemplateFindById$Response(params: CrossReferenceTemplateFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceTemplateDto>> {
    return crossReferenceTemplateFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find cross reference template by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceTemplateFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceTemplateFindById(params: CrossReferenceTemplateFindById$Params, context?: HttpContext): Observable<CrossReferenceTemplateDto> {
    return this.crossReferenceTemplateFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceTemplateDto>): CrossReferenceTemplateDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceTemplateUpdate()` */
  static readonly CrossReferenceTemplateUpdatePath = '/codebook/cross-reference-template/{id}';

  /**
   * Update existing cross reference template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceTemplateUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceTemplateUpdate$Response(params: CrossReferenceTemplateUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceTemplateDto>> {
    return crossReferenceTemplateUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing cross reference template
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceTemplateUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceTemplateUpdate(params: CrossReferenceTemplateUpdate$Params, context?: HttpContext): Observable<CrossReferenceTemplateDto> {
    return this.crossReferenceTemplateUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceTemplateDto>): CrossReferenceTemplateDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceTemplateCreate()` */
  static readonly CrossReferenceTemplateCreatePath = '/codebook/cross-reference-template';

  /**
   * Creates new cross reference template
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceTemplateCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceTemplateCreate$Response(params: CrossReferenceTemplateCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<CrossReferenceTemplateDto>> {
    return crossReferenceTemplateCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new cross reference template
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceTemplateCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceTemplateCreate(params: CrossReferenceTemplateCreate$Params, context?: HttpContext): Observable<CrossReferenceTemplateDto> {
    return this.crossReferenceTemplateCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CrossReferenceTemplateDto>): CrossReferenceTemplateDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceTemplateDelete()` */
  static readonly CrossReferenceTemplateDeletePath = '/codebook/cross-reference-template';

  /**
   * Delete cross reference templates by list of ids.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceTemplateDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceTemplateDelete$Response(params: CrossReferenceTemplateDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return crossReferenceTemplateDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete cross reference templates by list of ids.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceTemplateDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceTemplateDelete(params: CrossReferenceTemplateDelete$Params, context?: HttpContext): Observable<void> {
    return this.crossReferenceTemplateDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `crossReferenceTemplateSearch()` */
  static readonly CrossReferenceTemplateSearchPath = '/codebook/cross-reference-template/search-api';

  /**
   * Finds cross reference templates based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceTemplateSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceTemplateSearch$Response(params?: CrossReferenceTemplateSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageCrossReferenceTemplateDto>> {
    return crossReferenceTemplateSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds cross reference templates based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceTemplateSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceTemplateSearch(params?: CrossReferenceTemplateSearch$Params|null|undefined, context?: HttpContext): Observable<PageCrossReferenceTemplateDto> {
    return this.crossReferenceTemplateSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageCrossReferenceTemplateDto>): PageCrossReferenceTemplateDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceTemplateGetByEntityReferringFrom()` */
  static readonly CrossReferenceTemplateGetByEntityReferringFromPath = '/codebook/cross-reference-template/by-entity-from/{entityType}';

  /**
   * Finds cross reference templates based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceTemplateGetByEntityReferringFrom()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceTemplateGetByEntityReferringFrom$Response(params: CrossReferenceTemplateGetByEntityReferringFrom$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CrossReferenceTemplateDto>>> {
    return crossReferenceTemplateGetByEntityReferringFrom(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds cross reference templates based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceTemplateGetByEntityReferringFrom$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceTemplateGetByEntityReferringFrom(params: CrossReferenceTemplateGetByEntityReferringFrom$Params, context?: HttpContext): Observable<Array<CrossReferenceTemplateDto>> {
    return this.crossReferenceTemplateGetByEntityReferringFrom$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CrossReferenceTemplateDto>>): Array<CrossReferenceTemplateDto> => r.body)
    );
  }

}
