import {ChangeDetectionStrategy, Component, HostBinding, Input, OnInit} from '@angular/core';
import {EntityType} from '|api/commons';
import {DigitalComponentCompleteDto, DigitalComponentVersionCompleteDto, PaperComponentDto} from '|api/component';
import {EsslComponentDto} from '../../../services/essl-component-search.service';
import {PrimitiveValueFormField} from '../form-field';
import {getComponentIcon, getComponentIconByFormatFlag} from '../../../model';

@Component({
  selector: 'icz-essl-component',
  templateUrl: './essl-component.component.html',
  styleUrls: ['./essl-component.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EsslComponentComponent extends PrimitiveValueFormField implements OnInit {

  @Input({required: true}) documentEsslComponent!: EsslComponentDto;
  @Input() versionId!: number;
  @Input() locked = false;
  @Input() showFileName = false;

  @HostBinding('class.no-description')
  @Input()
  set hideDescription(value: '' | boolean) { this._hideDescription = value === '' ? true : value; }
  get hideDescription() { return this._hideDescription; }

  private _hideDescription = false;

  @Input() set hideIcon(value: '' | boolean) { this._hideIcon = value === '' ? true : value; }
  get hideIcon() { return this._hideIcon; }
  private _hideIcon = false;

  digitalComponentLabel!: string;
  description!: string;
  fileName!: string;
  svgIcon = 'attachment';

  get isAnalogComponent() {
    return this.isPaperComponent || this.isMediumComponentOrPhysicalItemComponent;
  }
  get isPaperComponent() {
    return this.documentEsslComponent?.entityType === EntityType.PAPER_COMPONENT;
  }
  get isMediumComponentOrPhysicalItemComponent() {
    return this.documentEsslComponent?.entityType === EntityType.MEDIUM_COMPONENT || this.documentEsslComponent?.entityType === EntityType.PHYSICAL_ITEM_COMPONENT;
  }

  get digitalComponent() {
    return this.documentEsslComponent as DigitalComponentCompleteDto;
  }

  get hasExecutionDateClause() {
    return this.digitalComponent?.executionDateClause;
  }

  ngOnInit() {
    if (this.documentEsslComponent) {
      this.digitalComponentLabel = this.documentEsslComponent.label!;
      this.description = this.digitalComponent.description!;
    }

    if (this.isAnalogComponent) {
      if ((this.documentEsslComponent as PaperComponentDto).digitalComponentLastTypeFlag) {
        this.svgIcon = getComponentIconByFormatFlag((this.documentEsslComponent as PaperComponentDto).digitalComponentLastTypeFlag);
      } else {
        this.svgIcon = getComponentIcon(this.documentEsslComponent.entityType!, null);
      }
    }
    if (!this.isAnalogComponent && this.digitalComponent) {
      let digitalComponentVersion: DigitalComponentVersionCompleteDto;

      if (!this.versionId) {
        digitalComponentVersion = this.digitalComponent.digitalComponentVersions![0];
        this.versionId = digitalComponentVersion?.id!;
      } else {
        digitalComponentVersion = this.digitalComponent.digitalComponentVersions!.find(v => v.id === this.versionId)!;
      }

      if (digitalComponentVersion) {
        this.fileName = digitalComponentVersion.fileName!;
        this.svgIcon = getComponentIcon(EntityType.DIGITAL_COMPONENT, digitalComponentVersion.digitalComponentVersionTypeFlag!, !isNil(digitalComponentVersion.extractionState));
      }
    }
  }

  _valueChanged($event: unknown) {
    // no-op
  }

}
