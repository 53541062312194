import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Option} from '../../../model';

@Component({
  selector: 'icz-readonly-field',
  templateUrl: './readonly-field.component.html',
  styleUrls: ['./readonly-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'icz-form-field'
  },
})
export class ReadonlyFieldComponent {
  @Input()
  placeholder = '';
  @Input()
  label = '';
  @Input({required: true})
  value: Nullable<string | number | string[] | number[]> = null;
  @Input()
  options: Nullable<Option[]> = [];
  @Input()
  withWhiteBackground = false;

  get isValueArray() {
    return Array.isArray(this.value);
  }
}
