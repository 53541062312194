/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { consignmentComponentDownloadOwnConsignmentComponentDigitalVersion } from '../fn/consignment-component/consignment-component-download-own-consignment-component-digital-version';
import { ConsignmentComponentDownloadOwnConsignmentComponentDigitalVersion$Params } from '../fn/consignment-component/consignment-component-download-own-consignment-component-digital-version';
import { consignmentComponentFindOwnConsignmentComponents } from '../fn/consignment-component/consignment-component-find-own-consignment-components';
import { ConsignmentComponentFindOwnConsignmentComponents$Params } from '../fn/consignment-component/consignment-component-find-own-consignment-components';
import { PageEsslComponentBaseDto } from '../models/page-essl-component-base-dto';


/**
 * Contains endpoints related to consignment components.
 */
@Injectable({ providedIn: 'root' })
export class ApiConsignmentComponentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `consignmentComponentDownloadOwnConsignmentComponentDigitalVersion()` */
  static readonly ConsignmentComponentDownloadOwnConsignmentComponentDigitalVersionPath = '/sad/own-consignment/{id}/component/{componentId}/digital-component-version/{digitalComponentVersionId}/download';

  /**
   * Download Specific Version of Digital Component Of Own Consignment.
   *
   * Downloads specific version of a digital component a given consignment is eligible to reference or already references.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consignmentComponentDownloadOwnConsignmentComponentDigitalVersion()` instead.
   *
   * This method doesn't expect any request body.
   */
  consignmentComponentDownloadOwnConsignmentComponentDigitalVersion$Response(params: ConsignmentComponentDownloadOwnConsignmentComponentDigitalVersion$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return consignmentComponentDownloadOwnConsignmentComponentDigitalVersion(this.http, this.rootUrl, params, context);
  }

  /**
   * Download Specific Version of Digital Component Of Own Consignment.
   *
   * Downloads specific version of a digital component a given consignment is eligible to reference or already references.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consignmentComponentDownloadOwnConsignmentComponentDigitalVersion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consignmentComponentDownloadOwnConsignmentComponentDigitalVersion(params: ConsignmentComponentDownloadOwnConsignmentComponentDigitalVersion$Params, context?: HttpContext): Observable<void> {
    return this.consignmentComponentDownloadOwnConsignmentComponentDigitalVersion$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `consignmentComponentFindOwnConsignmentComponents()` */
  static readonly ConsignmentComponentFindOwnConsignmentComponentsPath = '/sad/own-consignment/{id}/component/search-api';

  /**
   * Search Components For Own Consignment.
   *
   * Returns all components that a given own consignment can include, or already includes (depending on permissions). Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `consignmentComponentFindOwnConsignmentComponents()` instead.
   *
   * This method doesn't expect any request body.
   */
  consignmentComponentFindOwnConsignmentComponents$Response(params: ConsignmentComponentFindOwnConsignmentComponents$Params, context?: HttpContext): Observable<StrictHttpResponse<PageEsslComponentBaseDto>> {
    return consignmentComponentFindOwnConsignmentComponents(this.http, this.rootUrl, params, context);
  }

  /**
   * Search Components For Own Consignment.
   *
   * Returns all components that a given own consignment can include, or already includes (depending on permissions). Optionally additionally filtered by accompanying criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `consignmentComponentFindOwnConsignmentComponents$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  consignmentComponentFindOwnConsignmentComponents(params: ConsignmentComponentFindOwnConsignmentComponents$Params, context?: HttpContext): Observable<PageEsslComponentBaseDto> {
    return this.consignmentComponentFindOwnConsignmentComponents$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEsslComponentBaseDto>): PageEsslComponentBaseDto => r.body)
    );
  }

}
