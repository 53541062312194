/* tslint:disable */
/* eslint-disable */

/**
 * Transaction log event type
 */
export enum TransactionLogEventType {
  CREATION = 'CREATION',
  DUPLICATION = 'DUPLICATION',
  UPDATE = 'UPDATE',
  STATE_CHANGE = 'STATE_CHANGE',
  CANCEL = 'CANCEL',
  DISPLAY = 'DISPLAY',
  EXPORT = 'EXPORT',
  STORNO = 'STORNO',
  DESTRUCTION = 'DESTRUCTION',
  INSERT_INTO_FILE = 'INSERT_INTO_FILE',
  REMOVE_FROM_FILE = 'REMOVE_FROM_FILE',
  MOVE_BETWEEN_FILES = 'MOVE_BETWEEN_FILES',
  INSERT_INTO_STORAGE_UNIT = 'INSERT_INTO_STORAGE_UNIT',
  REMOVE_FROM_STORAGE_UNIT = 'REMOVE_FROM_STORAGE_UNIT',
  MOVE_BETWEEN_STORAGE_UNITS = 'MOVE_BETWEEN_STORAGE_UNITS',
  DEACTIVATION = 'DEACTIVATION',
  REF_NUMBER_ATTACHED = 'REF_NUMBER_ATTACHED',
  NEW_VERSION = 'NEW_VERSION',
  INSERT_TO_DISPATCH = 'INSERT_TO_DISPATCH',
  REMOVE_FROM_DISPATCH = 'REMOVE_FROM_DISPATCH',
  SIGNATURE_ATTACHED = 'SIGNATURE_ATTACHED',
  TIMESTAMP_ATTACHED = 'TIMESTAMP_ATTACHED',
  FINAL_VERSION = 'FINAL_VERSION',
  HANDED_OVER = 'HANDED_OVER',
  REJECTED = 'REJECTED',
  WITHDRAWN = 'WITHDRAWN',
  TAKEN_OVER = 'TAKEN_OVER',
  DISTRIBUTED = 'DISTRIBUTED',
  DISPATCHED = 'DISPATCHED',
  DELIVERED = 'DELIVERED',
  SETTLEMENT = 'SETTLEMENT',
  DOCUMENT_SETTLEMENT = 'DOCUMENT_SETTLEMENT',
  DOCUMENT_SETTLEMENT_WITHDRAW = 'DOCUMENT_SETTLEMENT_WITHDRAW',
  DOCUMENT_ISSD_HANDOVER = 'DOCUMENT_ISSD_HANDOVER',
  DOCUMENT_ISSD_RETURN = 'DOCUMENT_ISSD_RETURN',
  FILE_SETTLEMENT = 'FILE_SETTLEMENT',
  FILE_SETTLEMENT_WITHDRAW = 'FILE_SETTLEMENT_WITHDRAW',
  FILE_ISSD_HANDOVER = 'FILE_ISSD_HANDOVER',
  FILE_ISSD_RETURN = 'FILE_ISSD_RETURN',
  OWNER_CHANGE = 'OWNER_CHANGE',
  OWNER_CHANGE_AUTOMATIC = 'OWNER_CHANGE_AUTOMATIC',
  APPROVAL = 'APPROVAL',
  STATEMENT = 'STATEMENT',
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  COMPONENT_SIGNING = 'COMPONENT_SIGNING',
  COMPONENT_APPROVAL = 'COMPONENT_APPROVAL',
  CLOSE = 'CLOSE',
  OPEN = 'OPEN',
  REOPEN = 'REOPEN',
  HANDOVER_TO_AGENDA = 'HANDOVER_TO_AGENDA',
  RETURN_FROM_AGENDA = 'RETURN_FROM_AGENDA',
  KEYWORD_ATTACHED = 'KEYWORD_ATTACHED',
  KEYWORD_REMOVED = 'KEYWORD_REMOVED',
  HANDOVER_TO_REPOSITORY = 'HANDOVER_TO_REPOSITORY',
  TAKE_TO_REPOSITORY = 'TAKE_TO_REPOSITORY',
  RETURN_FROM_REPOSITORY = 'RETURN_FROM_REPOSITORY',
  PAUSE_DISPOSAL_SCHEDULE = 'PAUSE_DISPOSAL_SCHEDULE',
  CANCEL_PAUSE_DISPOSAL_SCHEDULE = 'CANCEL_PAUSE_DISPOSAL_SCHEDULE',
  INSERT_INTO_SHREDDING_SCHEME = 'INSERT_INTO_SHREDDING_SCHEME',
  REMOVE_FROM_SHREDDING_SCHEME = 'REMOVE_FROM_SHREDDING_SCHEME',
  INSERT_INTO_CASE_FILE_SEPARATION = 'INSERT_INTO_CASE_FILE_SEPARATION',
  REMOVE_FROM_CASE_FILE_SEPARATION = 'REMOVE_FROM_CASE_FILE_SEPARATION',
  FORMAT_CONVERSION_EX_OFFICIO = 'FORMAT_CONVERSION_EX_OFFICIO',
  FORMAT_CONVERSION = 'FORMAT_CONVERSION',
  MARK_ATTACHED = 'MARK_ATTACHED',
  ASSIGNMENT = 'ASSIGNMENT',
  PASS = 'PASS',
  REJECTION = 'REJECTION',
  REVOKE = 'REVOKE',
  ISSD_HANDOVER = 'ISSD_HANDOVER',
  ISSD_TAKEOVER = 'ISSD_TAKEOVER',
  ISSD_RETURN = 'ISSD_RETURN',
  ISSD_REJECT = 'ISSD_REJECT',
  ISSD_FINISH = 'ISSD_FINISH',
  USAGE_REPORT_GENERATED = 'USAGE_REPORT_GENERATED',
  REFERENCED_BY_GENERATED_USAGE_REPORT = 'REFERENCED_BY_GENERATED_USAGE_REPORT',
  INSERT_INTO_CROSS_REFERENCE = 'INSERT_INTO_CROSS_REFERENCE',
  REMOVE_FROM_CROSS_REFERENCE = 'REMOVE_FROM_CROSS_REFERENCE',
  CLASSIFICATION_UPDATE = 'CLASSIFICATION_UPDATE',
  END = 'END',
  RELEASE = 'RELEASE',
  RETURN = 'RETURN',
  UPDATE_META = 'UPDATE_META',
  IMPORT = 'IMPORT',
  RENDITION = 'RENDITION',
  SETTLEMENT_SUSPENDED = 'SETTLEMENT_SUSPENDED',
  SETTLEMENT_RESUMED = 'SETTLEMENT_RESUMED',
  OUTPUT_FORMAT_CONVERSION = 'OUTPUT_FORMAT_CONVERSION',
  ELECTRONIC_TO_PAPER_FORM_CONVERSION = 'ELECTRONIC_TO_PAPER_FORM_CONVERSION',
  CONVERSION_TO_DIGITAL_FORM = 'CONVERSION_TO_DIGITAL_FORM',
  SIGNATURE_VALIDATION = 'SIGNATURE_VALIDATION',
  ANONYMIZATION = 'ANONYMIZATION',
  NATIVE_EDIT = 'NATIVE_EDIT',
  ORG_UNIT_ENTITY_SHARING_SET_UP = 'ORG_UNIT_ENTITY_SHARING_SET_UP',
  ORG_UNIT_ENTITY_SHARING_REDEFINED = 'ORG_UNIT_ENTITY_SHARING_REDEFINED',
  ORG_UNIT_ENTITY_SHARING_REVOKED = 'ORG_UNIT_ENTITY_SHARING_REVOKED',
  ENTITY_SHARING_SET_UP = 'ENTITY_SHARING_SET_UP',
  ENTITY_SHARING_REDEFINED = 'ENTITY_SHARING_REDEFINED',
  ENTITY_SHARING_REVOKED = 'ENTITY_SHARING_REVOKED',
  DELETION = 'DELETION',
  IDENTIFICATION = 'IDENTIFICATION',
  LINK = 'LINK',
  UNLINK = 'UNLINK',
  ORGANIZATION_CREATION = 'ORGANIZATION_CREATION',
  ORGANIZATION_ACTIVATION = 'ORGANIZATION_ACTIVATION',
  ORGANIZATION_DEACTIVATION = 'ORGANIZATION_DEACTIVATION',
  ORGANIZATION_SOFT_DELETE = 'ORGANIZATION_SOFT_DELETE',
  ORGANIZATION_DELETE = 'ORGANIZATION_DELETE',
  DESA_NOMENCLATURE_UPLOAD = 'DESA_NOMENCLATURE_UPLOAD',
  DESA_NOMENCLATURE_UPDATE = 'DESA_NOMENCLATURE_UPDATE',
  REGISTRY_OFFICE_HANDOVER = 'REGISTRY_OFFICE_HANDOVER',
  REGISTRY_OFFICE_HANDOVER_STORNO = 'REGISTRY_OFFICE_HANDOVER_STORNO',
  REGISTRY_OFFICE_HANDOVER_PROBLEMS_FOUND = 'REGISTRY_OFFICE_HANDOVER_PROBLEMS_FOUND',
  REGISTRY_OFFICE_HANDOVER_PROBLEMS_RESOLVED = 'REGISTRY_OFFICE_HANDOVER_PROBLEMS_RESOLVED',
  REGISTRY_OFFICE_HANDOVER_RETURNED_TO_RESOLVE = 'REGISTRY_OFFICE_HANDOVER_RETURNED_TO_RESOLVE',
  REGISTRY_OFFICE_HANDOVER_ACCEPTED = 'REGISTRY_OFFICE_HANDOVER_ACCEPTED',
  REGISTRY_OFFICE_HANDOVER_REJECTED = 'REGISTRY_OFFICE_HANDOVER_REJECTED',
  AUTHORIZED_CONVERSION_TO_DIGITAL_FORM = 'AUTHORIZED_CONVERSION_TO_DIGITAL_FORM',
  AUTHORIZED_CONVERSION_TO_PAPER_FORM = 'AUTHORIZED_CONVERSION_TO_PAPER_FORM'
}
