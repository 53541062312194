<div class="gap-24 row grow" [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="grow">
    <icz-section label="Vyřizovaný dokument">
      <div class="document-selector-max-width">
        <icz-form-autocomplete
          [noResultsTemplate]="noResultsTemplate"
          [options]="previouslySearchedOptions"
          [optionsDefinitionFactory]="makeDocumentsSearchOptionsDefinition"
          [isMultiselect]="false"
          formControlName="relatedDocumentId"
          label="Vyhledejte vyřizovaný dokument"
          placeholder="Hledejte v názvu objektu"
          [minSearchTermLength]="minSearchTermLength"
          (valueChange)="onResolutionDocumentSelected($event)"
        ></icz-form-autocomplete>
      </div>
      @for (message of documentErrors; track message) {
        <div>
          <icz-icon svgIcon="info_blue"></icz-icon>
          <div>
            <span>{{message | translate}}</span>
          </div>
        </div>
      }
    </icz-section>
  </div>
  <div class="grow">
    <icz-section label="Typ a forma dokumentu">
      <icz-document-type-selector
        [form]="form"
        controlName="relatedDocumentTypeId"
        [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
        [date]="settlementDate"
      ></icz-document-type-selector>
      <icz-form-autocomplete label="Forma dokumentu" [value]="byDocumentForm" class="grow" [fieldDisabled]="true" [options]="documentFormOptions"></icz-form-autocomplete>
    </icz-section>
    <icz-section label="Počet ukládaných komponent">
      <icz-form-field label="Počet ukládaných komponent" class="grow" [fieldDisabled]="true" [value]="byDocumentComponentsCount"></icz-form-field>
    </icz-section>
  </div>
</div>

<ng-template #noResultsTemplate let-context>
  <div class="no-options-found">
    {{ ((context.searchTerm?.length ?? 0) >= 3 ? 'Žádné dokumenty nenalezeny' : 'Pro vyhledání zadejte minimálně tři znaky') | translate }}
  </div>
</ng-template>
