import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DigitalComponentVersionSignatureFlagType} from '|api/commons';
import {ReceivedEpdzMessageDetailDto} from '|api/sad';

@Component({
  selector: 'icz-email-preview',
  templateUrl: './email-preview.component.html',
  styleUrls: ['./email-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailPreviewComponent {
  @Input({required: true})
  message: Nullable<ReceivedEpdzMessageDetailDto>;
  @Input({required: true})
  emailBodyHtml: Nullable<string>;
  @Input({required: true})
  emailBodyRtf: Nullable<ArrayBuffer>;

  readonly DigitalComponentVersionSignatureFlagType = DigitalComponentVersionSignatureFlagType;
}
