import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {ComponentWithContext, IAutocompleteListItemContext, IPopoverContext,} from '../form-autocomplete.model';
import {Option} from '../../../../model';

export interface IAutocompleteNoResultsContext {
  searchTerm: string;
  searchTermChanged: EventEmitter<string>;
}

/**
 * Comprehensive docs explaining this component are in ComposableFormAutocomplete JSDoc
 */
@Component({
  selector: 'icz-form-autocomplete-list',
  templateUrl: './form-autocomplete-list.component.html',
  styleUrls: ['./form-autocomplete-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormAutocompleteListComponent implements ComponentWithContext<IPopoverContext> {
  // inputs and outputs passed to options list
  @Input({required: true})
  options: Option[] = [];
  @Input({required: true})
  selectedOptions: Option[] = [];
  @Input({required: true})
  isLoading: Nullable<boolean> = false;
  @Input({required: true})
  isMultiselect: Nullable<boolean> = false;
  @Input({required: true})
  listItemTemplate!: TemplateRef<IAutocompleteListItemContext>;
  @Input({required: true})
  noResultsTemplate: Nullable<TemplateRef<IAutocompleteNoResultsContext>>;
  @Input({required: true})
  hideDefaultTooltips = false;
  @Output()
  selectionChanged = new EventEmitter<Option[]>();

  // own inputs and outputs
  @Input()
  searchTermInputPrefix!: string;
  @Input()
  searchTermInputPlaceholder!: string;
  @Input()
  hideSearchField = false;
  @Output()
  searchTermChanged = new EventEmitter<string>();

  searchTerm: string = '';

  searchItems($event: string | number) {
    this.searchTerm = $event as string;
    this.searchTermChanged.emit(this.searchTerm);
  }
}
