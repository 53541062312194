import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT, Page} from '../api';
import {
  ApiComponentService,
  DigitalComponentCompleteDto,
  MediumComponentCreateDto,
  MediumComponentDto,
  PaperComponentCreateDto,
  PaperComponentDto,
  PhysicalItemComponentCreateDto,
  PhysicalItemComponentDto
} from '|api/component';
import {ApiConsignmentComponentService} from '|api/sad';
import {FilterOperator, FilterParam, SearchApiService, SearchParams} from './search-api.service';

export type EsslAnalogComponentDto = PaperComponentDto | MediumComponentDto | PhysicalItemComponentDto;
export type EsslAnalogComponentCreateDto = PaperComponentCreateDto | MediumComponentCreateDto | PhysicalItemComponentCreateDto;
export type EsslComponentDto = DigitalComponentCompleteDto | EsslAnalogComponentDto;

@Injectable({
  providedIn: 'root',
})
export class EsslComponentSearchService extends SearchApiService {

  prepareSearchParamsforFind(
    searchParams: SearchParams,
    documentId?: number,
    additionalFilters?: FilterParam[],
  ): SearchParams {
    const hasFulltextFilterDefined = Boolean(searchParams.fulltextSearchTerm?.trim());

    if (additionalFilters?.length) {
      for (const additionalFilter of additionalFilters) {
        if (!searchParams.filter.find(f => f.fieldName === additionalFilter.fieldName)) {
          searchParams.filter.push(additionalFilter);
        }
      }
    }
    if (documentId) {
      searchParams.filter.push({operator: FilterOperator.equals, fieldName: 'documentId', value: String(documentId)});
    }
    if (hasFulltextFilterDefined) {
      if (!searchParams.filter.find(f => f.fieldName === 'label')) {
        searchParams.filter.push({
          operator: FilterOperator.contains,
          fieldName: 'label',
          value: searchParams.fulltextSearchTerm,
          isCaseInsensitive: true,
          or: true,
        });
      }
      if (!searchParams.filter.find(f => f.fieldName === 'description')) {
        searchParams.filter.push({
          operator: FilterOperator.contains,
          fieldName: 'description',
          value: searchParams.fulltextSearchTerm,
          isCaseInsensitive: true,
          or: true,
        });
      }
    }

    return searchParams;
  }

  findEsslComponents(
    searchParams: SearchParams,
    documentId?: number,
    additionalFilters?: FilterParam[],
  ): Observable<Page<EsslComponentDto>> {
    return this.searchApi<Page<EsslComponentDto>>(this.prepareSearchParamsforFind(searchParams, documentId, additionalFilters), CORE_MICROSERVICE_ROOT + ApiComponentService.ComponentSearchPath);
  }

  findEsslComponentsForDocuments(searchParams: SearchParams, documentIds: number[]) {
    if (documentIds?.length) {
      searchParams.filter.push({operator: FilterOperator.inSet, fieldName: 'documentId', value: String(documentIds)});
    }

    return this.searchApi<Page<EsslComponentDto>>(searchParams, CORE_MICROSERVICE_ROOT + ApiComponentService.ComponentSearchPath);
  }

  findEsslComponentsForConsignment(
    searchParams: SearchParams,
    consignmetId: number,
    documentId?: number,
    additionalFilters?: FilterParam[],
  ) {
    return this.searchApi<Page<EsslComponentDto>>(this.prepareSearchParamsforFind(searchParams, documentId, additionalFilters), CORE_MICROSERVICE_ROOT + applyPathParams(ApiConsignmentComponentService.ConsignmentComponentFindOwnConsignmentComponentsPath, {id: consignmetId}));
  }

}
