import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'icz-annotated-label',
  templateUrl: './annotated-label.component.html',
  styleUrls: ['./annotated-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnotatedLabelComponent {

  @Input({required: true}) annotation!: string;
  @Input({required: true}) label!: Nullable<string | number>;

  @Input() set inline(inline: boolean|'') { this._isInline = (inline === true || inline === ''); }
  @Input() set highlight(highlight: boolean|'') { this._isHighlighted = (highlight === true || highlight === ''); }

  _isInline = false;
  _isHighlighted = false;

  readonly isNil = isNil;

}
