import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable} from 'rxjs';
import {
  EntityType,
  OvmType,
  RelatedObjectType,
  SubjectAttributeAddressDto,
  SubjectObjectRelationType,
  SubjectRecordClassification,
  SubjectRecordDto,
  SubjectRecordWithRelationsDto
} from '|api/commons';
import {ApiAuthorizationService, AuthorizedEntityType, DocumentAuthorizedOperation, FileAuthorizedOperation} from '|api/core';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {IczModalService} from '../../../../../services/icz-modal.service';
import {SubjectTemplateUtils} from '../../../../../utils/subject-template-utils';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {FilterType} from '../../../../table/filter.types';
import {TableReservedColumns} from '../../../../table/selected-rows.service';
import {TableColumnsData} from '../../../../table/table-columns-data';
import {extendDefaultTableConfig, TableComponent} from '../../../../table/table.component';
import {ColumnDefinition} from '../../../../table/table.models';
import {AddressCompleteDto, AddressWithTypeDto} from '../../../model/addresses.model';
import {
  mapLegalForms,
  SubjectAndAddress,
  SubjectRecordSource,
  SubjectRecordWithSourceDto,
  SubjectWithSource
} from '../../../model/subjects.model';
import {CreateNewConsignmentDialogType} from '../../../own-consignment-table/model/own-consignment-model';
import {GeneralAuthorizationResult, GeneralAuthorizedOperation, isAuthorizedOperationGranted} from '../../../permissions/permissions.utils';
import {SubjectsTableAction, SubjectsTableActionCommand} from '../subjects-table-actions';
import {SearchParams} from '../../../../../services/search-api.service';
import {DocumentFileDetailService} from '../../../../../services/abstract-object-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DocumentDto, FileDto} from '|api/document';
import {EditSubjectDialogMode} from '../../edit-subject/edit-subject-dialog.component';
import {delay} from 'rxjs/operators';
import {ELASTIC_RELOAD_DELAY} from '../../../document-toolbar/services/toolbar-common.utils';
import {SubjectsService} from '../../../../../services/subjects.service';
import {ScrollingService} from '../../../../../services/scrolling.service';
import {IczTableDataSource} from '../../../../table/table.datasource';
import {AdministrationSubjectRecordDto} from '|api/subject-register';
import {SubjectAttributeType} from '../../../model/subject-attribute-type';

export enum SubjectTableColumnSet {
  RESULT_SET_FOR_LINKING = 'RESULT_SET_FOR_LINKING',
  RESULT_SET_FOR_LISTING = 'RESULT_SET_FOR_LISTING',
  RESULT_SET_FOR_ADMIN_OVERVIEW = 'RESULT_SET_FOR_ADMIN_OVERVIEW',
  RESULT_SET_FOR_DETAIL = 'RESULT_SET_FOR_DETAIL',
  RESULT_SET_FOR_CONSIGNMENTS_SEARCH = 'RESULT_SET_FOR_CONSIGNMENTS_SEARCH',
  RESULT_SET_FOR_CONSIGNMENTS_DOCUMENT_RELATED = 'RESULT_SET_FOR_CONSIGNMENTS_DOCUMENT_RELATED',
  RESULT_SET_FOR_DUPLICATE_ADMINISTRATION = 'RESULT_SET_FOR_DUPLICATE_ADMINISTRATION',
}

enum SubjectColumnKeys {
  SELECTION = 'selection',
  CLASSIFICATION = 'classification',
  SOURCE = 'source',
  IDENTIFICATION_STATUS = 'identificationStatus',
  ATTRIBUTES_BIRTH_DATE = 'attributes.birthDate',
  ATTRIBUTES_BIRTH_PLACE = 'attributes.birthPlace',
  ATTRIBUTES_CLIENT_ID = 'attributes.clientId',
  ATTRIBUTES_VAT_ID = 'attributes.vatId',
  ATTRIBUTES_DATA_BOX = 'attributes.dataBoxes',
  ATTRIBUTES_DRIVING_LICENCE_ID = 'attributes.drivingLicenceId',
  ATTRIBUTES_EMAIL = 'attributes.emails',
  ATTRIBUTES_EORI_CODE = 'attributes.eoriCode',
  ATTRIBUTES_IDENTITY_CARD_ID = 'attributes.identityCardId',
  ATTRIBUTES_LEGAL_FORM = 'attributes.legalForm',
  ATTRIBUTES_LE_ID = 'attributes.leId',
  ATTRIBUTES_NAME = 'attributes.name',
  ATTRIBUTES_NOTE = 'attributes.note',
  ATTRIBUTES_PASSPORT_ID = 'attributes.passportId',
  ATTRIBUTES_PHONE_FAX = 'attributes.phoneFaxes',
  ATTRIBUTES_TAX_ID = 'attributes.taxId',
  ATTRIBUTES_CID = 'attributes.cid',
  DATA_BOX_OR_EMAIL = 'dataBoxOrEmail',
  RELATION_TYPE = 'relationType',
  ADDRESS = 'address',
  ADDRESSES_WITH_SELECTOR = 'addressesWithSelector',
  ACTIONS = 'actions',
  CONSIGNMENT_ACTIONS = 'consignmentActions',
  ADMINISTRATION_ACTIONS = 'administrationActions',
  IS_REPRESENTING_SUBJECT = 'isRepresentingSubject',
  DUPLICATE_COUNT = 'duplicateCount',
  CREATED_AT = 'auditInfo.createdAt',
}

const tableColumnDefinitions = new Map<SubjectColumnKeys, ColumnDefinition<SubjectColumnKeys>>([
  // Usual default columns
  [SubjectColumnKeys.CLASSIFICATION, {
    id: SubjectColumnKeys.CLASSIFICATION,
    label: 'Typ',
    filterType: FilterType.NONE,
    disableSort: true,
    fixedWidth: 55},],
  [SubjectColumnKeys.SELECTION, {id: TableReservedColumns.SELECTION, label: 'Výběr', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.SOURCE, {id: SubjectColumnKeys.SOURCE, label: 'Zdroj', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.IDENTIFICATION_STATUS, {id: SubjectColumnKeys.IDENTIFICATION_STATUS, label: 'Stav ztotožnění', icon: 'verified_subject', iconSize: 'default', filterType: FilterType.NONE, fixedWidth: 40, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_DATA_BOX, {id: SubjectColumnKeys.ATTRIBUTES_DATA_BOX, label: 'Datová schránka', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_NAME, {id: SubjectColumnKeys.ATTRIBUTES_NAME, label: 'Název subjektu', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_PHONE_FAX, {id: SubjectColumnKeys.ATTRIBUTES_PHONE_FAX, label: 'Telefon/fax', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_NOTE, {id: SubjectColumnKeys.ATTRIBUTES_NOTE, label: 'Poznámka', icon: 'comments', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_CID, {id: SubjectColumnKeys.ATTRIBUTES_CID, label: 'IČO', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.RELATION_TYPE, {id: SubjectColumnKeys.RELATION_TYPE, label: 'Vazba', filterType: FilterType.NONE, disableSort: true},],
  [SubjectColumnKeys.ADDRESS, {id: SubjectColumnKeys.ADDRESS, label: 'Adresa', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ADDRESSES_WITH_SELECTOR, {id: SubjectColumnKeys.ADDRESSES_WITH_SELECTOR, label: 'Adresa', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.DATA_BOX_OR_EMAIL, {id: SubjectColumnKeys.DATA_BOX_OR_EMAIL, label: 'Datovka/E-mail', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ACTIONS, {id: SubjectColumnKeys.ACTIONS, label: ' ', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.CONSIGNMENT_ACTIONS, {id: SubjectColumnKeys.CONSIGNMENT_ACTIONS, label: ' ', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.ADMINISTRATION_ACTIONS, {id: SubjectColumnKeys.ADMINISTRATION_ACTIONS, label: ' ', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.IS_REPRESENTING_SUBJECT, {id: SubjectColumnKeys.IS_REPRESENTING_SUBJECT, label: 'Reprezentující subjekt', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.DUPLICATE_COUNT, {id: SubjectColumnKeys.DUPLICATE_COUNT, label: 'Počet duplicit', filterType: FilterType.NONE, disableSort: true,}],
  [SubjectColumnKeys.CREATED_AT, {id: SubjectColumnKeys.CREATED_AT, label: 'Založeno', filterType: FilterType.DATE, disableSort: true,}],
  // Optional columns
  [SubjectColumnKeys.ATTRIBUTES_EMAIL, {id: SubjectColumnKeys.ATTRIBUTES_EMAIL, label: 'E-mail', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_CLIENT_ID, {id: SubjectColumnKeys.ATTRIBUTES_CLIENT_ID, label: 'Klientské číslo', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_PASSPORT_ID, {id: SubjectColumnKeys.ATTRIBUTES_PASSPORT_ID, label: 'Cestovní pas', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_DRIVING_LICENCE_ID, {id: SubjectColumnKeys.ATTRIBUTES_DRIVING_LICENCE_ID, label: 'Řidičský průkaz', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_IDENTITY_CARD_ID, {id: SubjectColumnKeys.ATTRIBUTES_IDENTITY_CARD_ID, label: 'Občanský průkaz', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_BIRTH_DATE, {id: SubjectColumnKeys.ATTRIBUTES_BIRTH_DATE, label: 'Datum narození', filterType: FilterType.DATE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_BIRTH_PLACE, {id: SubjectColumnKeys.ATTRIBUTES_BIRTH_PLACE, label: 'Místo narození', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_LEGAL_FORM, {id: SubjectColumnKeys.ATTRIBUTES_LEGAL_FORM, label: 'Právní forma', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_LE_ID, {id: SubjectColumnKeys.ATTRIBUTES_LE_ID, label: 'Id. právnické osoby', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_TAX_ID, {id: SubjectColumnKeys.ATTRIBUTES_TAX_ID, label: 'DIČ', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
  [SubjectColumnKeys.ATTRIBUTES_EORI_CODE, {id: SubjectColumnKeys.ATTRIBUTES_EORI_CODE, label: 'EORI kód', filterType: FilterType.NONE, displayed: false, disableSort: true,}],
]);

type SubjectsTableDataType = (SubjectRecordDto | AdministrationSubjectRecordDto) & SubjectWithSource;

enum ShowSubjectInfoAction {
  OPEN_MODAL = 'OPEN_MODAL',
  SHOW_SIDEBAR = 'SHOW_SIDEBAR',
}
const allowedColumnSetsForSidebar = [
  SubjectTableColumnSet.RESULT_SET_FOR_LINKING,
  SubjectTableColumnSet.RESULT_SET_FOR_CONSIGNMENTS_SEARCH,
];

@Component({
  selector: 'icz-subjects-table',
  templateUrl: './subjects-table.component.html',
  styleUrls: ['./subjects-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectsTableComponent implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private injector = inject(Injector);
  private destroyRef = inject(DestroyRef);
  private codebookService = inject(CodebookService);
  private iczModalService = inject(IczModalService);
  private subjectsService = inject(SubjectsService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private scrollingService = inject(ScrollingService);
  private readonly elementRef = inject(ElementRef);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private abstractObjectDetailService = inject<DocumentFileDetailService<DocumentDto | FileDto, any>>(DocumentFileDetailService, {optional: true});

  @Input({required: true}) tableId!: string;
  @Input({required: true}) dataSource!: IczTableDataSource<SubjectsTableDataType>;
  @Input({required: true}) columnSet: SubjectTableColumnSet = SubjectTableColumnSet.RESULT_SET_FOR_LISTING;
  @Input() tableTitle: Nullable<string>;
  @Input() createNewConsignmentDialogType!: CreateNewConsignmentDialogType;
  @Input() parentEntityId: Nullable<number>;
  @Input() parentEntityType: Nullable<EntityType>;
  @Input() linkButtonLabel: Nullable<string> = 'Přidat';
  @Input() linkButtonDisabled = false;
  @Input() linkButtonDisabledReason: Nullable<string>;
  @Output() reloadDataSource = new EventEmitter<void>();
  @Output() selectedRowsChanged = new EventEmitter<SubjectsTableDataType[]>();
  @Output() actionExecuted = new EventEmitter<SubjectsTableActionCommand>();
  @Output() subjectAddressChanged = new EventEmitter<SubjectAndAddress>();
  @Output() rowClicked = new EventEmitter<SubjectsTableDataType>();

  @ViewChild('iczTable') iczTable!: TableComponent<SubjectColumnKeys>;

  canUserShowSubjectDetail = true;
  canUserEditSubject = true;

  getRelationType(row: SubjectRecordWithRelationsDto): Nullable<SubjectObjectRelationType> {
    return row.objectRelations?.find(r => r.relatedObjectId === this.parentEntityId &&
      r.relatedObjectType === (this.parentEntityType as unknown as RelatedObjectType))?.relationType;
  }

  readonly SubjectsTableAction = SubjectsTableAction;
  readonly SubjectRecordClassification = SubjectRecordClassification;
  readonly SubjectColumnKeys = SubjectColumnKeys;
  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly SubjectRecordSource = SubjectRecordSource;
  readonly OvmType = OvmType;
  readonly subjectObjectRelationType = enumToOptions('subjectObjectRelationType', SubjectObjectRelationType);
  readonly loaderIdIsdsFind = 'ISDS_FIND';
  readonly ShowSubjectInfoAction = ShowSubjectInfoAction;

  tableConfig = extendDefaultTableConfig({
    rowHeight: 70,
    toolbarConfig: {
      showToolbar: true,
      autoOpenFilter: false,
      filterIsStatic: false,
      showReload: false,
      showFilter: false,
    },
  });

  columnsData: TableColumnsData<SubjectColumnKeys> = new TableColumnsData<never>([]);
  selectedRows!: SubjectRecordWithSourceDto[];
  activeRow: Nullable<SubjectRecordWithSourceDto>;
  sidebarOpened = false;
  legalFormOptions$ = this.codebookService.allLegalForms().pipe(mapLegalForms());

  currentObjectRepresentingSubject: Nullable<SubjectRecordDto>;

  getSuitableAddressForDisplay(subject: SubjectRecordDto): Nullable<AddressWithTypeDto> {
    return SubjectTemplateUtils.getBusinessSortedAddresses(subject)?.[0];
  }

  private scrollToTop() {
    setTimeout(() => {
      this.scrollingService.scrollTo(this.elementRef.nativeElement);
    }, 0);
  }

  private openEditModal (subject: SubjectRecordWithSourceDto, relationType?: SubjectObjectRelationType, representing?: boolean) {
    let subjectDialogMode = EditSubjectDialogMode.READ_ONLY;

    let allowSubjectReplacement = true;
    let disallowedReplacementReason = '';

    if (!subject.id) {
      subjectDialogMode = EditSubjectDialogMode.ISDS_VIEW_ONLY;
    }
    if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_DETAIL || this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_ADMIN_OVERVIEW) {
      subjectDialogMode = EditSubjectDialogMode.FULL;
    }
    if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_ADMIN_OVERVIEW) {
      allowSubjectReplacement = false;
      disallowedReplacementReason = 'Neexistuje vazba, pro kterou by mohlo dojít k nahrazení subjektů.';
    }

    SubjectTemplateUtils.openEditSubjectModal(
      this.iczModalService,
      subject,
      subjectDialogMode,
      relationType,
      representing, {
        allowSubjectReplacement,
        disallowedReplacementReason
      },
      this.injector)
      .pipe(delay(ELASTIC_RELOAD_DELAY))
      .subscribe((result: any) => {
        if (result) this.reloadDataSource.emit();
      });
  };

  showSubjectInfo(row: Nullable<SubjectsTableDataType>, action: ShowSubjectInfoAction) {
    if (!row) {
      if (action === ShowSubjectInfoAction.OPEN_MODAL) {
       return;
      } else if (action === ShowSubjectInfoAction.SHOW_SIDEBAR) {
        this.activeRow = null;
        this.sidebarOpened = Boolean(this.activeRow);
      }
    }
    else {
      const relationType = this.getRelationType(row)!;
      const representing = row.id === this.currentObjectRepresentingSubject?.id;

      if (row.subjectSource === SubjectRecordSource.ISDS_SEARCH) {
        // todo(lp) for now, hiding sidebar for isdsSearch results
        if (action === ShowSubjectInfoAction.SHOW_SIDEBAR) {
          return;
        }

        const dataBoxId = row.attributes.dataBoxes?.[0].value!.id;
        if (dataBoxId) {
          this.scrollToTop();
          this.loadingIndicatorService.doLoading(
            this.subjectsService.findUsingIsdsFind(row).pipe(takeUntilDestroyed(this.destroyRef)),
            this,
            this.loaderIdIsdsFind,
          ).subscribe(subjectFromIsdsFind => {
            if (subjectFromIsdsFind) {
              if (action === ShowSubjectInfoAction.OPEN_MODAL) {
                this.openEditModal(subjectFromIsdsFind, relationType, representing);
              }
              else if (action === ShowSubjectInfoAction.SHOW_SIDEBAR) {
                this.activeRow = subjectFromIsdsFind;
                this.sidebarOpened = Boolean(this.activeRow);
              }
            }
          });
        }
      }
      else {
        if (action === ShowSubjectInfoAction.OPEN_MODAL) {
          this.openEditModal(row, relationType, representing);
        }
        else if (action === ShowSubjectInfoAction.SHOW_SIDEBAR) {
          this.activeRow = row;
          this.sidebarOpened = Boolean(this.activeRow);
        }
      }
    }
  }

  loadPage(searchParams: SearchParams) {
    this.dataSource.loadPage(searchParams);
  }

  setActiveRow(row: Nullable<SubjectRecordWithSourceDto>) {
    if (!allowedColumnSetsForSidebar.includes(this.columnSet)) return;
    this.showSubjectInfo(row, ShowSubjectInfoAction.SHOW_SIDEBAR);
  }

  setColumnSet() {
    const optionalCols = [
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_CLIENT_ID)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_EMAIL)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_PASSPORT_ID)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_DRIVING_LICENCE_ID)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_IDENTITY_CARD_ID)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_BIRTH_DATE)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_BIRTH_PLACE)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_LEGAL_FORM)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_LE_ID)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_TAX_ID)!,
      tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_EORI_CODE)!,
    ];

    if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_LISTING || this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_ADMIN_OVERVIEW) {
      this.columnsData = new TableColumnsData<SubjectColumnKeys>([
        tableColumnDefinitions.get(SubjectColumnKeys.SELECTION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CLASSIFICATION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_DATA_BOX)!,
        tableColumnDefinitions.get(SubjectColumnKeys.IDENTIFICATION_STATUS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NAME)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADDRESS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.RELATION_TYPE)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_PHONE_FAX)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NOTE)!,
        ...optionalCols
      ]);
    } else if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_DETAIL) {
      this.columnsData = new TableColumnsData<SubjectColumnKeys>([
        tableColumnDefinitions.get(SubjectColumnKeys.SELECTION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CLASSIFICATION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_DATA_BOX)!,
        tableColumnDefinitions.get(SubjectColumnKeys.IDENTIFICATION_STATUS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NAME)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADDRESS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.RELATION_TYPE)!,
        tableColumnDefinitions.get(SubjectColumnKeys.IS_REPRESENTING_SUBJECT)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_PHONE_FAX)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NOTE)!,
        ...optionalCols
      ]);
    } else if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_LINKING) {
      this.columnsData = new TableColumnsData<SubjectColumnKeys>([
        tableColumnDefinitions.get(SubjectColumnKeys.SOURCE)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CLASSIFICATION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_DATA_BOX)!,
        tableColumnDefinitions.get(SubjectColumnKeys.IDENTIFICATION_STATUS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NAME)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADDRESS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_PHONE_FAX)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NOTE)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ACTIONS)!,
        ...optionalCols
      ]);
    }
    else if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_CONSIGNMENTS_SEARCH) {
      this.columnsData = new TableColumnsData<SubjectColumnKeys>([
        tableColumnDefinitions.get(SubjectColumnKeys.SOURCE)!,
        tableColumnDefinitions.get(SubjectColumnKeys.IDENTIFICATION_STATUS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CLASSIFICATION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.DATA_BOX_OR_EMAIL)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NAME)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADDRESSES_WITH_SELECTOR)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CONSIGNMENT_ACTIONS)!,
        ...optionalCols
      ]);
    }
    else if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_CONSIGNMENTS_DOCUMENT_RELATED) {
      this.columnsData = new TableColumnsData<SubjectColumnKeys>([
        tableColumnDefinitions.get(SubjectColumnKeys.IDENTIFICATION_STATUS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CLASSIFICATION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.DATA_BOX_OR_EMAIL)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NAME)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADDRESSES_WITH_SELECTOR)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CONSIGNMENT_ACTIONS)!,
        ...optionalCols
      ]);
    }
    else if (this.columnSet === SubjectTableColumnSet.RESULT_SET_FOR_DUPLICATE_ADMINISTRATION) {
      this.columnsData = new TableColumnsData<SubjectColumnKeys>([
        tableColumnDefinitions.get(SubjectColumnKeys.IDENTIFICATION_STATUS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CLASSIFICATION)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_NAME)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADDRESS)!,
        tableColumnDefinitions.get(SubjectColumnKeys.DATA_BOX_OR_EMAIL)!,
        tableColumnDefinitions.get(SubjectColumnKeys.DUPLICATE_COUNT)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ATTRIBUTES_CID)!,
        tableColumnDefinitions.get(SubjectColumnKeys.CREATED_AT)!,
        tableColumnDefinitions.get(SubjectColumnKeys.ADMINISTRATION_ACTIONS)!,
      ]);
    }
  }

  ngOnInit() {
    if (this.abstractObjectDetailService) {
      this.abstractObjectDetailService.objectRepresentingSubject$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(objectRepresentingSubject => {
        this.currentObjectRepresentingSubject = objectRepresentingSubject;
        this.cd.detectChanges();
      });
    }

    this.setColumnSet();

    if (this.parentEntityId || this.parentEntityType) {
      if (!this.parentEntityId || !this.parentEntityType) {
        throw new Error('Either set both @Input parentEntityId and @Input parentEntityType or none of them.');
      }

      let showSubjectAuthorizedOperation: GeneralAuthorizedOperation;
      let modifySubjectAuthorizedOperation: GeneralAuthorizedOperation;
      let authorizationResult$: Observable<GeneralAuthorizationResult>;

      if (this.parentEntityType === EntityType.DOCUMENT) {
        showSubjectAuthorizedOperation = DocumentAuthorizedOperation.DOCUMENT_SHOW_SUBJECT;
        modifySubjectAuthorizedOperation = DocumentAuthorizedOperation.DOCUMENT_MODIFY_SUBJECT;
        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeDocumentOperations({
          body: {
            authorizedEntityId: this.parentEntityId,
            authorizedEntityType: AuthorizedEntityType.DOCUMENT,
            operationsToAuthorize: [showSubjectAuthorizedOperation, modifySubjectAuthorizedOperation],
          }
        });
      }
      else if (this.parentEntityType === EntityType.FILE) {
        showSubjectAuthorizedOperation = FileAuthorizedOperation.FILE_SHOW_SUBJECT;
        modifySubjectAuthorizedOperation = FileAuthorizedOperation.FILE_MODIFY_SUBJECT;
        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeFileOperations({
          body: {
            authorizedEntityId: this.parentEntityId,
            authorizedEntityType: AuthorizedEntityType.FILE,
            operationsToAuthorize: [showSubjectAuthorizedOperation!, modifySubjectAuthorizedOperation!],
          }
        });
      }

      this.loadingIndicatorService.doLoading(
        authorizationResult$!,
        this,
      ).subscribe({
        next: authorizationResult => {
          this.canUserShowSubjectDetail = isAuthorizedOperationGranted(authorizationResult, showSubjectAuthorizedOperation);
          this.canUserEditSubject = isAuthorizedOperationGranted(authorizationResult, modifySubjectAuthorizedOperation);
        },
        error: _ => {
          this.canUserShowSubjectDetail = false;
          this.canUserEditSubject = false;
        }
      });
    }
  }

  getDefaultSubjectAddress(subject: SubjectRecordDto): Nullable<AddressCompleteDto> {
    const addressPriorities = [
      // highest prio
      SubjectAttributeType.MAILING_ADDRESS,
      SubjectAttributeType.ADDITIONAL_ADDRESS,
      SubjectAttributeType.RESIDENTIAL_ADDRESS,
      // lowest prio
    ];

    for (const addressType of addressPriorities) {
      const addressTypeAttribute = subject.attributes[addressType];

      if (addressTypeAttribute) {
        if (addressType === SubjectAttributeType.ADDITIONAL_ADDRESS) {
          return (addressTypeAttribute as SubjectAttributeAddressDto[]).map(a => a.value) as AddressCompleteDto[];
        } else {
          return (addressTypeAttribute as SubjectAttributeAddressDto).value as AddressCompleteDto;
        }
      }
    }

    return null;
  }

  addressSelectorValueChanged(subject: SubjectRecordDto, address: Nullable<AddressCompleteDto>) {
    this.subjectAddressChanged.emit({subject, address});
  }

  getSubjectAddressCount(subject: SubjectRecordDto): number {
    const mailingCount = Boolean(subject.attributes.mailingAddress) ? 1 : 0;
    const residentialCount = Boolean(subject.attributes.residentialAddress) ? 1 : 0;
    const additionalsCount = subject.attributes.additionalAddresses?.length ?? 0;
    return mailingCount + residentialCount + additionalsCount;
  }

}
