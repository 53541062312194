import {ChangeDetectorRef, Directive, forwardRef, HostListener, inject, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {RadioButtonComponent} from './radio-button.component';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'icz-radio-button[formControlName]',
  providers: [
    {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioButtonValueAccessorDirective), multi: true},
  ],
})
export class RadioButtonValueAccessorDirective implements ControlValueAccessor {

  private cd = inject(ChangeDetectorRef);
  private radioButtonComponent = inject(RadioButtonComponent);

  @Input() value: any;
  @Input() formControlName!: string;

  @HostListener('blur') rbTouched() {
    this.onTouched();
  }
  @HostListener('change') rbChanged() { this.onChange(this.value); }

  setDisabledState(isDisabled: boolean) {
    this.radioButtonComponent._fieldDisabledFromReactiveModel = isDisabled;
    this.cd.detectChanges();
  }

  writeValue(val: any): void {
    this.radioButtonComponent.checked = (val === this.value);
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouched = fn; }

  private onChange = (_: any) => {};
  private onTouched = () => {};

}
