import {
  DehydratedFilterItemValue,
  FilterItem,
  FilterItemValue,
  isListFilterItem,
  NonemptyFilterItem
} from './filter.types';
import {ComplexOperator, FilterParam, getOperatorTranslationKey} from '../../services/search-api.service';
import {convertViewFiltersToDataFilters} from './filter.utils';

import {deepFreeze} from '../../lib/utils';

export enum FilterTreeOperator {
  AND = 'and',
  OR = 'or',
  /*
     as of now, NONE can be only at the root of a tree to signify that
     it is a simple expression with implicit AND between filtering terms
     (i.e. we do not support arbitrary logical expression bracketing)
  */
  NONE = 'none',
}

interface FilterTree<TItem> {
  operator: FilterTreeOperator;
  values: Array<FilterTree<TItem> | TItem>;
}

export const EMPTY_FILTER_TREE = deepFreeze({
  operator: FilterTreeOperator.NONE,
  values: [],
}) as Readonly<FilterTree<any>>;

export type StringifiedTree = FilterTree<string>;
export type FilterItemValueTree = FilterTree<FilterItemValue>;
export type DehydratedFilterItemValueTree = FilterTree<DehydratedFilterItemValue>;
export type FilterItemTree = FilterTree<NonemptyFilterItem>;
export type FilterParamTree = FilterTree<FilterParam>;

export function isFilterTree<T extends FilterTree<unknown>>(val: any): val is T {
  return !isNil(val) && (typeof val === 'object') && val.operator && Array.isArray(val.values);
}

export function isSimpleQueryFilterTree(tree: FilterTree<unknown>) {
  return tree.operator === FilterTreeOperator.NONE;
}

export function isFilterTreeEmpty(filterItemValueTree: Nullable<FilterTree<unknown>>) {
  return !(filterItemValueTree?.values?.length ?? 0);
}

export function filterOperatorToComplexOperator(filterOp: FilterTreeOperator): ComplexOperator {
  switch (filterOp) {
    case FilterTreeOperator.AND:
      return ComplexOperator.AND;
    case FilterTreeOperator.OR:
      return ComplexOperator.OR;
    case FilterTreeOperator.NONE:
      return ComplexOperator.NONE;
  }
}

export function findInFilterTree<T>(tree: FilterTree<T>, predicate: (fi: T) => boolean): Nullable<T> {
  let out: Nullable<T>;

  for (const treeNode of tree.values) {
    if (isFilterTree(treeNode)) {
      out = findInFilterTree(treeNode, predicate);
    }
    else {
      if (predicate(treeNode)) {
        out = treeNode;
      }
    }

    if (!isNil(out)) {
      return out;
    }
  }

  return null;
}

export function addValueToFilterItem(filterItem: FilterItem, itemValue: FilterItemValue<string | string[]>) {
  return {
    ...filterItem,
    id: filterItem.customFilterId ?? filterItem.id,
    value: itemValue.value,
    subValues: itemValue.subValues,
    label: filterItem.columnLabel ?? filterItem.label,
    list: filterItem.list,
    filterOption: {value: itemValue.operator!, label: getOperatorTranslationKey(itemValue.operator)},
    originId: isListFilterItem(filterItem) ? itemValue.originId : undefined,
  } as NonemptyFilterItem;
}

export function filterItemValueTreeToFilterItemTree(filterItemTree: FilterItemValueTree, filterItems: FilterItem[]): FilterItemTree {
  if (isSimpleQueryFilterTree(filterItemTree)) {
    return {
      operator: FilterTreeOperator.NONE,
      values: filterItemTree.values.map(filterItemValue => {
        const filterItem = filterItems.find(fi => fi.customFilterId === (filterItemValue as FilterItemValue).id || fi.id === (filterItemValue as FilterItemValue).id)!;
        return addValueToFilterItem(filterItem, filterItemValue as FilterItemValue);
      }),
    };
  }
  else {
    return {
      operator: filterItemTree.operator,
      values: filterItemTree.values.map(filterItemValue => {
        if (isFilterTree(filterItemValue)) {
          return filterItemValueTreeToFilterItemTree(filterItemValue, filterItems);
        }
        else {
          const filterItem = filterItems.find(fi => fi.id === filterItemValue.id || fi.customFilterId === filterItemValue.id)!;
          return addValueToFilterItem(filterItem, filterItemValue);
        }
      }),
    };
  }
}

export function filterItemTreeToFilterItemValueTree(filterItemTree: FilterItemTree): FilterItemValueTree {
  if (isSimpleQueryFilterTree(filterItemTree)) {
    return {
      operator: FilterTreeOperator.NONE,
      values: filterItemTree.values.map(filterItem => FilterItemValue.fromFilterItem(filterItem as FilterItem)),
    };
  }
  else {
    return {
      operator: filterItemTree.operator,
      values: filterItemTree.values.map(filterItem => {
        if (isFilterTree(filterItem)) {
          return filterItemTreeToFilterItemValueTree(filterItem);
        }
        else {
          return FilterItemValue.fromFilterItem(filterItem);
        }
      }),
    };
  }
}

export function hydrateFilterItemValue(filterItemValue: DehydratedFilterItemValue): FilterItemValue {
  return new FilterItemValue(
    filterItemValue.id,
    filterItemValue.operator,
    filterItemValue.value,
    filterItemValue.subValues,
    filterItemValue.originId,
  );
}

export function hydrateFilterItemValueTree(filterItemTree: DehydratedFilterItemValueTree): FilterItemValueTree {
  if (isSimpleQueryFilterTree(filterItemTree)) {
    return {
      operator: FilterTreeOperator.NONE,
      values: filterItemTree.values.map(filterItem => hydrateFilterItemValue(filterItem as DehydratedFilterItemValue)),
    };
  }
  else {
    return {
      operator: filterItemTree.operator,
      values: filterItemTree.values.map(filterItem => {
        if (isFilterTree(filterItem)) {
          return hydrateFilterItemValueTree(filterItem);
        }
        else {
          return hydrateFilterItemValue(filterItem);
        }
      })
    };
  }
}

export function viewFilterTreeToDataFilterTree(filterItemTree: FilterItemTree): FilterParamTree {
  if (isSimpleQueryFilterTree(filterItemTree)) {
    return {
      operator: FilterTreeOperator.NONE,
      values: convertViewFiltersToDataFilters(filterItemTree.values as FilterItem[]),
    };
  }
  else {
    return {
      operator: filterItemTree.operator,
      values: filterItemTree.values.flatMap(
        // @ts-ignore
        v => {
          if (isFilterTree(v)) {
            return viewFilterTreeToDataFilterTree(v);
          }
          else {
            return convertViewFiltersToDataFilters([v]);
          }
        }
      ),
    };
  }
}
