/* tslint:disable */
/* eslint-disable */

/**
 * Label template type
 */
export enum LabelTemplateType {
  RECEIVED_DOCUMENT = 'RECEIVED_DOCUMENT',
  OWN_DOCUMENT = 'OWN_DOCUMENT',
  RECEIVED_CONSIGNMENT = 'RECEIVED_CONSIGNMENT',
  OWN_CONSIGNMENT = 'OWN_CONSIGNMENT'
}
