/* tslint:disable */
/* eslint-disable */

/**
 * Email notification main type.
 */
export enum EmailNotificationType {
  OPERATION = 'OPERATION',
  DEADLINE = 'DEADLINE',
  CIRCULATION = 'CIRCULATION',
  CONSIGNMENT = 'CONSIGNMENT'
}
