import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'icz-submit-bar',
  templateUrl: './submit-bar.component.html',
  styleUrls: ['./submit-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmitBarComponent {
  @Input() isNewOrLabel!: boolean | string;
  @Input() savingProgress: Nullable<boolean> = false;
  @Input() customCaption: Nullable<string>;
  @Input() submitDisabled = false;
  @Output() cancel = new EventEmitter<void>();
  @Output() submit = new EventEmitter<void>();

  get caption() {
    return this.isNewOrLabel === true ? 'Vytvořit' : this.isNewOrLabel || 'Uložit';
  }
}
