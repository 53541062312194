import {inject, Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {OwnPaperConsignmentDto} from '|api/sad';
import {GenericOwnConsignment} from '../../../own-consignment-table/model/own-consignment-model';
import {ConsignmentsToastService, ConsignmentToastData} from '../../../consignment-dialog/consignments-toast.service';
import {RejectDialogComponent} from './reject-dialog.component';
import {IczModalService} from '../../../../../services/icz-modal.service';
import {constructBulkModalTitle} from '../../../../../lib/utils';
import {dispatchDialogTitles} from '../../dispatch-dialogs.model';
import {OwnConsignmentWorkflowAction} from '../../../consignment-dialog/consignment-dialog/consignment.model';


export enum RejectDialogType {
  REJECT = 'REJECT',
  CANCEL = 'CANCEL',
  WITHDRAW_FROM_DISTRIBUTION = 'WITHDRAW_FROM_DISTRIBUTION',
  DELETE = 'DELETE',
  UNPOST = 'UNPOST',
}

export interface RejectDialogResult {
  resultType: RejectDialogType;
  consignment: GenericOwnConsignment;
  reason: string;
}

export interface RejectDialogData {
  type: RejectDialogType,
  documentId: number;
  consignments: GenericOwnConsignment[];
}

@Injectable({
  providedIn: 'root'
})
export class RejectDialogService {

  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);

  openRejectDialog(documentId: number, consignments: GenericOwnConsignment[], type: RejectDialogType) {
    const isBulk = consignments.length > 1;
    let titleTemplate =
      type === RejectDialogType.REJECT ? dispatchDialogTitles[OwnConsignmentWorkflowAction.REJECT] :
        type === RejectDialogType.CANCEL ? dispatchDialogTitles[OwnConsignmentWorkflowAction.CANCEL] :
          type === RejectDialogType.WITHDRAW_FROM_DISTRIBUTION ? dispatchDialogTitles[OwnConsignmentWorkflowAction.WITHDRAW_FROM_DISTRIBUTION] :
            type === RejectDialogType.DELETE ? dispatchDialogTitles[OwnConsignmentWorkflowAction.DELETE] :
              type === RejectDialogType.UNPOST ? dispatchDialogTitles[OwnConsignmentWorkflowAction.UNPOST] :
                '';

    if (isBulk) titleTemplate = constructBulkModalTitle(titleTemplate);

    return this.modalService.openComponentInModal<Nullable<RejectDialogResult>, RejectDialogData>({
      component: RejectDialogComponent,
      modalOptions: {
        titleTemplate,
        titleTemplateContext: {count: consignments.length.toString()},
        width: 800,
        height: 400,
      },
      data: {
        type,
        documentId,
        consignments: consignments as OwnPaperConsignmentDto[],
      },
    }).pipe(
      tap(result => {
        if (result) {
          if (isBulk) {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.COUNT]: consignments.length,
            };

            this.consignmentsToastService.dispatchConsignmentBulkActionAccepted(toastData);
          } else {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.DOCUMENT_ID]: documentId,
              [InternalNotificationKey.CONSIGNMENT_ID]: consignments[0].id,
              [InternalNotificationKey.CONSIGNMENT_NAME]: consignments[0].documentSubject!,
              [InternalNotificationKey.CONSIGNMENT_UID]: consignments[0].uid!.uid!,
            };

            if (result.resultType === RejectDialogType.REJECT) {
              this.consignmentsToastService.dispatchConsignmentRejected(toastData);
            } else if (result.resultType === RejectDialogType.CANCEL) {
              toastData[InternalNotificationKey.REASON] = result.reason;
              this.consignmentsToastService.dispatchConsignmentCancelled(toastData);
            } else if (result.resultType === RejectDialogType.WITHDRAW_FROM_DISTRIBUTION) {
              this.consignmentsToastService.dispatchConsignmentWithdrawnFromDistribution(toastData);
            } else if (result.resultType === RejectDialogType.DELETE) {
              this.consignmentsToastService.dispatchConsignmentDeleted(toastData);
            } else if (result.resultType === RejectDialogType.UNPOST) {
              this.consignmentsToastService.dispatchConsignmentManuallyUnposted(toastData);
            }
          }
        }
      }),
    );
  }

}
