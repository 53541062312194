/* tslint:disable */
/* eslint-disable */

/**
 * Crud operation
 */
export enum CrudOperation {
  CREATE = 'CREATE',
  READ = 'READ',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}
