import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {CORE_MICROSERVICE_ROOT, Page} from '../api';
import {
  ApiTransactionEventService as TransactionLogApiTransactionEventService,
  TransactionEventDto
} from '|api/transactionlog';
import {SearchApiService, SearchParams} from './search-api.service';

@Injectable({
  providedIn: 'root',
})
export class TransactionLogSearchService extends SearchApiService {

  findTransactions(searchParams: Partial<SearchParams>): Observable<Page<TransactionEventDto>> {
    return this.searchApi(searchParams, CORE_MICROSERVICE_ROOT + TransactionLogApiTransactionEventService.TransactionEventFindPath);
  }

}
