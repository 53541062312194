/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { sharedFolderAddObjectsToSharedFolder } from '../fn/shared-folder/shared-folder-add-objects-to-shared-folder';
import { SharedFolderAddObjectsToSharedFolder$Params } from '../fn/shared-folder/shared-folder-add-objects-to-shared-folder';
import { sharedFolderCreate } from '../fn/shared-folder/shared-folder-create';
import { SharedFolderCreate$Params } from '../fn/shared-folder/shared-folder-create';
import { sharedFolderDeleteFolder } from '../fn/shared-folder/shared-folder-delete-folder';
import { SharedFolderDeleteFolder$Params } from '../fn/shared-folder/shared-folder-delete-folder';
import { SharedFolderDto } from '../models/shared-folder-dto';
import { sharedFolderFindById } from '../fn/shared-folder/shared-folder-find-by-id';
import { SharedFolderFindById$Params } from '../fn/shared-folder/shared-folder-find-by-id';
import { sharedFolderGetAllAccessibleFolders } from '../fn/shared-folder/shared-folder-get-all-accessible-folders';
import { SharedFolderGetAllAccessibleFolders$Params } from '../fn/shared-folder/shared-folder-get-all-accessible-folders';
import { sharedFolderGetAllFoldersSuitableForInsertion } from '../fn/shared-folder/shared-folder-get-all-folders-suitable-for-insertion';
import { SharedFolderGetAllFoldersSuitableForInsertion$Params } from '../fn/shared-folder/shared-folder-get-all-folders-suitable-for-insertion';
import { sharedFolderGetAllManageableFolders } from '../fn/shared-folder/shared-folder-get-all-manageable-folders';
import { SharedFolderGetAllManageableFolders$Params } from '../fn/shared-folder/shared-folder-get-all-manageable-folders';
import { sharedFolderMoveObjectToAnotherFolder } from '../fn/shared-folder/shared-folder-move-object-to-another-folder';
import { SharedFolderMoveObjectToAnotherFolder$Params } from '../fn/shared-folder/shared-folder-move-object-to-another-folder';
import { sharedFolderRemoveObjectsFromSharedFolder } from '../fn/shared-folder/shared-folder-remove-objects-from-shared-folder';
import { SharedFolderRemoveObjectsFromSharedFolder$Params } from '../fn/shared-folder/shared-folder-remove-objects-from-shared-folder';
import { sharedFolderUpdate } from '../fn/shared-folder/shared-folder-update';
import { SharedFolderUpdate$Params } from '../fn/shared-folder/shared-folder-update';


/**
 * Controller for shared folder objects
 */
@Injectable({ providedIn: 'root' })
export class ApiSharedFolderService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sharedFolderFindById()` */
  static readonly SharedFolderFindByIdPath = '/document/shared-folder/{id}';

  /**
   * Find shared folder object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderFindById$Response(params: SharedFolderFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderDto>> {
    return sharedFolderFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find shared folder object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderFindById(params: SharedFolderFindById$Params, context?: HttpContext): Observable<SharedFolderDto> {
    return this.sharedFolderFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderDto>): SharedFolderDto => r.body)
    );
  }

  /** Path part for operation `sharedFolderUpdate()` */
  static readonly SharedFolderUpdatePath = '/document/shared-folder/{id}';

  /**
   * Shared folder Update.
   *
   * Updates shared folder's metadata.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderUpdate$Response(params: SharedFolderUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderDto>> {
    return sharedFolderUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Shared folder Update.
   *
   * Updates shared folder's metadata.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderUpdate(params: SharedFolderUpdate$Params, context?: HttpContext): Observable<SharedFolderDto> {
    return this.sharedFolderUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderDto>): SharedFolderDto => r.body)
    );
  }

  /** Path part for operation `sharedFolderDeleteFolder()` */
  static readonly SharedFolderDeleteFolderPath = '/document/shared-folder/{id}';

  /**
   * Delete shared folder.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderDeleteFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderDeleteFolder$Response(params: SharedFolderDeleteFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sharedFolderDeleteFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete shared folder.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderDeleteFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderDeleteFolder(params: SharedFolderDeleteFolder$Params, context?: HttpContext): Observable<void> {
    return this.sharedFolderDeleteFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sharedFolderCreate()` */
  static readonly SharedFolderCreatePath = '/document/shared-folder';

  /**
   * Creates new shared folder
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderCreate$Response(params: SharedFolderCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderDto>> {
    return sharedFolderCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new shared folder
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderCreate(params: SharedFolderCreate$Params, context?: HttpContext): Observable<SharedFolderDto> {
    return this.sharedFolderCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderDto>): SharedFolderDto => r.body)
    );
  }

  /** Path part for operation `sharedFolderRemoveObjectsFromSharedFolder()` */
  static readonly SharedFolderRemoveObjectsFromSharedFolderPath = '/document/shared-folder/{id}/remove-objects-from-folder';

  /**
   * Remove objects to folder.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderRemoveObjectsFromSharedFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderRemoveObjectsFromSharedFolder$Response(params: SharedFolderRemoveObjectsFromSharedFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sharedFolderRemoveObjectsFromSharedFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * Remove objects to folder.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderRemoveObjectsFromSharedFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderRemoveObjectsFromSharedFolder(params: SharedFolderRemoveObjectsFromSharedFolder$Params, context?: HttpContext): Observable<void> {
    return this.sharedFolderRemoveObjectsFromSharedFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sharedFolderMoveObjectToAnotherFolder()` */
  static readonly SharedFolderMoveObjectToAnotherFolderPath = '/document/shared-folder/{id}/move-to-another-folder';

  /**
   * Move object to another folder.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderMoveObjectToAnotherFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderMoveObjectToAnotherFolder$Response(params: SharedFolderMoveObjectToAnotherFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sharedFolderMoveObjectToAnotherFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * Move object to another folder.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderMoveObjectToAnotherFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderMoveObjectToAnotherFolder(params: SharedFolderMoveObjectToAnotherFolder$Params, context?: HttpContext): Observable<void> {
    return this.sharedFolderMoveObjectToAnotherFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sharedFolderAddObjectsToSharedFolder()` */
  static readonly SharedFolderAddObjectsToSharedFolderPath = '/document/shared-folder/{id}/add-objects-to-folder';

  /**
   * Add object to folder.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderAddObjectsToSharedFolder()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderAddObjectsToSharedFolder$Response(params: SharedFolderAddObjectsToSharedFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return sharedFolderAddObjectsToSharedFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * Add object to folder.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderAddObjectsToSharedFolder$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sharedFolderAddObjectsToSharedFolder(params: SharedFolderAddObjectsToSharedFolder$Params, context?: HttpContext): Observable<void> {
    return this.sharedFolderAddObjectsToSharedFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `sharedFolderGetAllManageableFolders()` */
  static readonly SharedFolderGetAllManageableFoldersPath = '/document/shared-folder/get-all-manageable-folders';

  /**
   * Get all manageable folders.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderGetAllManageableFolders()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderGetAllManageableFolders$Response(params?: SharedFolderGetAllManageableFolders$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SharedFolderDto>>> {
    return sharedFolderGetAllManageableFolders(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all manageable folders.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderGetAllManageableFolders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderGetAllManageableFolders(params?: SharedFolderGetAllManageableFolders$Params|null|undefined, context?: HttpContext): Observable<Array<SharedFolderDto>> {
    return this.sharedFolderGetAllManageableFolders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SharedFolderDto>>): Array<SharedFolderDto> => r.body)
    );
  }

  /** Path part for operation `sharedFolderGetAllFoldersSuitableForInsertion()` */
  static readonly SharedFolderGetAllFoldersSuitableForInsertionPath = '/document/shared-folder/get-all-folders-suitable-for-insertion';

  /**
   * Get all folders suitable for insertion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderGetAllFoldersSuitableForInsertion()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderGetAllFoldersSuitableForInsertion$Response(params?: SharedFolderGetAllFoldersSuitableForInsertion$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SharedFolderDto>>> {
    return sharedFolderGetAllFoldersSuitableForInsertion(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all folders suitable for insertion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderGetAllFoldersSuitableForInsertion$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderGetAllFoldersSuitableForInsertion(params?: SharedFolderGetAllFoldersSuitableForInsertion$Params|null|undefined, context?: HttpContext): Observable<Array<SharedFolderDto>> {
    return this.sharedFolderGetAllFoldersSuitableForInsertion$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SharedFolderDto>>): Array<SharedFolderDto> => r.body)
    );
  }

  /** Path part for operation `sharedFolderGetAllAccessibleFolders()` */
  static readonly SharedFolderGetAllAccessibleFoldersPath = '/document/shared-folder/get-all-accessible-folders';

  /**
   * Get all accessible folders.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sharedFolderGetAllAccessibleFolders()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderGetAllAccessibleFolders$Response(params?: SharedFolderGetAllAccessibleFolders$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<SharedFolderDto>>> {
    return sharedFolderGetAllAccessibleFolders(this.http, this.rootUrl, params, context);
  }

  /**
   * Get all accessible folders.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sharedFolderGetAllAccessibleFolders$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sharedFolderGetAllAccessibleFolders(params?: SharedFolderGetAllAccessibleFolders$Params|null|undefined, context?: HttpContext): Observable<Array<SharedFolderDto>> {
    return this.sharedFolderGetAllAccessibleFolders$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SharedFolderDto>>): Array<SharedFolderDto> => r.body)
    );
  }

}
