import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {TooltipDirective} from '../tooltip.directive';
import {DebugLoggingService} from '../../../core/services/debug-logging.service';

export type ButtonSize = 'tiny' | 'small' | 'compact' | 'default' | 'large' | 'xlarge';

@Component({
  selector: 'icz-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent implements OnInit, IczOnChanges {

  private debugLogging = inject(DebugLoggingService);
  private attachedTooltip = inject(TooltipDirective, {optional: true, self: true});

  @Input() svgIcon: Nullable<string>;
  @Input() tabIndex = '0';
  @Input()
  set disableRipple(value: Nullable<boolean | ''>) { this._disableRipple = value === '' ? true : Boolean(value); }
  get disableRipple() { return this._disableRipple; }
  _disableRipple: boolean = false;

  @Input() disableFocus: boolean|'' = false;
  @HostBinding('class.square') @Input() square = false;
  @Input() isCollapsed: Nullable<boolean> = false;
  @Input() stopClickPropagation = false;
  // an arrow function containing a predicate which when evaluated
  // to true will disable emission of onAction event
  @Input() cancelActionIf!: () => boolean;

  @Input()
  set originalIconColor(value: Nullable<boolean | ''>) { this._originalIconColor = value === '' ? true : Boolean(value); }
  get originalIconColor() { return this._originalIconColor; }
  _originalIconColor = false;

  @Input() iconPosition: 'left' | 'right' = 'left';
  @HostBinding('class.icon-right') get isIconRight() { return this.iconPosition === 'right'; }

  @Input()
  size: ButtonSize = 'default';
  @HostBinding('class.tiny') get isTiny() { return this.size === 'tiny'; }
  @HostBinding('class.small') get isSmall() { return this.size === 'small'; }
  @HostBinding('class.large') get isLarge() { return this.size === 'large'; }
  @HostBinding('class.xlarge') get isXLarge() { return this.size === 'xlarge'; }
  @HostBinding('class.compact') get isCompact() { return this.size === 'compact'; }

  @Input()
  set disabled(value: Nullable<boolean | ''>) { this._disabled = value === '' ? true : Boolean(value); }
  get disabled() { return this._disabled; }
  _disabled = false;

  @HostBinding('class.rounded')
  @Input()
  set rounded(value: boolean | '') { this._rounded = value === '' ? true : value; }
  get rounded() { return this._rounded; }
  _rounded = false;

  @HostBinding('class.no-centered')
  @Input()
  set noCentered(value: boolean | '') { this._noCentered = value === '' ? true : value; }
  get noCentered() { return this._noCentered; }
  _noCentered = false;

  @Input()
  set noBorder(value: boolean | '') { this._noBorder = value === '' ? true : value; }
  get noBorder() { return this._noBorder; }
  _noBorder = false;

  @Input() set primary(value: boolean | '') { this._primary = value === '' ? true : value; }
  get primary() { return this._primary; }
  _primary = false;

  get color() { return this._primary ? 'primary' : undefined; }

  @Input()
  set background(value: Nullable<string>) { this._background = value === '' ? 'transparent' : value; }
  get background() { return this._background || this.color; }

  _background: Nullable<string>;

  @HostBinding('class.no-border')
  get hasNoBorder() {
    return this._background === 'transparent' || this._noBorder || this._primary;
  }

  @Output()
  onAction = new EventEmitter<Event>();

  @ViewChild('button') button!: MatButton;

  @Input() label!: string;

  get collapsedTooltip() {
    return (this.isCollapsed && !this.attachedTooltip) ? this.label : null;
  }

  get hasDisabledFocus(): boolean { return this.disableFocus === '' || this.disableFocus; }
  get innerButtonTabIndex(): string { return this.hasDisabledFocus ? '-1' : this.tabIndex; }

  hasNoContent!: boolean;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.label || changes.isCollapsed) {
      if (this.attachedTooltip) {
        this.attachedTooltip.tooltipButtonLabel = this.isCollapsed ? this.label : null;
        this.attachedTooltip.ngOnChanges();
      }
    }
  }

  focus() {
    if (!this.hasDisabledFocus) {
      this.button.focus();
    }
  }

  @HostListener('click', ['$event'])
  buttonClicked($event: Event) {
    if (this.stopClickPropagation) $event.stopPropagation();
    if (this.disabled) return;
    if (typeof(this.cancelActionIf) !== 'function' || !this.cancelActionIf()) {
      this.debugLogging.logUserInteraction({description: `Použito tlačítko '${this.label ?? this.svgIcon}'`});
      this.onAction.emit($event);
    }
  }

  ngOnInit(): void {
    this.hasNoContent = !Boolean(this.label);
  }

}
