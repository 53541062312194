<div [formGroup]="specificFieldsForm" [waiting]="(loadingService.isLoading$(this) | async) || distributionNodesLoading">
  <div class="row gap-48">
    <div class="grow">
      <icz-section label="Adresát">
        @if (consignee) {
          <span class="icz-body-1">{{ consignee | subject }}</span>
        }
      </icz-section>
      <icz-section label="Způsob vypravení">
        <icz-delivery-service-combination-selector
          [form]="specificFieldsForm"
          controlName="deliveryServiceCombinationId"
          [deliveryTypeId]="deliveryTypeId"
        ></icz-delivery-service-combination-selector>

        <icz-applicable-outcome-distribution-node-selector
          [form]="specificFieldsForm"
          controlName="dispatchOfficeDistributionNodeId"
          [deliveryTypeId]="deliveryTypeId"
          (loadingStatusChanged)="distributionNodesLoading = $event"
        ></icz-applicable-outcome-distribution-node-selector>

        <div class="row">
          <!-- todo(rb) implement invalidated/suspended databox warning after BE ready -->
          <icz-form-autocomplete formControlName="consigneeDataBox" class="grow" label="Zvolte datovou schránku" [options]="databoxOptions"></icz-form-autocomplete>
          <icz-form-field formControlName="toBeDeliveredTo" class="grow" label="K rukám"></icz-form-field>
        </div>
        <icz-form-field formControlName="subject" label="Předmět zprávy"></icz-form-field>
        <icz-form-field formControlName="consigneeOrganizationalUnit" label="Organizační jednotka příjemce"></icz-form-field>
        <icz-form-field type="integer" formControlName="consigneeOrganizationalUnitCode" label="Kód organizační jednotky příjemce"></icz-form-field>
      </icz-section>
    </div>
    <div class="grow">
      <icz-section label="Evidenční čísla a zmocnění">
        <icz-form-field [fieldDisabled]="true" [value]="documentRefNumber" label="Naše číslo jednací"></icz-form-field>
        <icz-form-field [fieldDisabled]="true" [value]="fileRefNumber" label="Naše spisová značka"></icz-form-field>
        <icz-form-field formControlName="consignorRefNumber" label="Vaše číslo jednací"></icz-form-field>
        <icz-form-field formControlName="consignorFileRefNumber" label="Vaše spisová značka"></icz-form-field>
        <icz-empowerment formControlName="empowerment"></icz-empowerment>
      </icz-section>

      <icz-section label="Poznámka">
        <icz-form-field
          formControlName="note"
          label="Poznámka k zásilce"
          [autoSizeMax]="5"
        ></icz-form-field>
      </icz-section>
    </div>
  </div>
</div>
