import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {HighlightFormFieldDirective} from './highlight-form-field.directive';
import {HighlightBackgroundDirective} from './highlight-background.directive';
import {HighlightTextDirective} from './highlight-text.directive';
import {EssentialsModule} from '../essentials/essentials.module';


@NgModule({
  declarations: [
    HighlightBackgroundDirective,
    HighlightFormFieldDirective,
    HighlightTextDirective,
  ],
  exports: [
    HighlightBackgroundDirective,
    HighlightFormFieldDirective,
    HighlightTextDirective,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
  ]
})
export class HighlightsModule { }
