import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map, tap} from 'rxjs/operators';
import {OrganizationalUnitDto} from '|api/auth-server';
import {ApiBasicSettingsOauthService, DistributionNodeType} from '|api/config-server';
import {SheetNodeDto} from '|api/sad';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {OrganizationalStructureService} from '../../../../../core/services/organizational-structure.service';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {OwnInternalConsignmentForm} from '../consignment-dialog.form';
import {
  SubjectAttributeSource,
  SubjectAttributeState,
  SubjectRecordClassification,
  SubjectRecordDto
} from '|api/commons';

import {sheetNodeToOption} from '../consignment.model';

export interface OrganizationConsignee {
  subject: SubjectRecordDto,
  sheetNode: Nullable<SheetNodeDto>,
}

@Component({
  selector: 'icz-consignment-org-unit-selection',
  templateUrl: './consignment-org-unit-selection.component.html',
  styleUrls: ['./consignment-org-unit-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentOrgUnitSelectionComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  private orgStructureService = inject(OrganizationalStructureService);
  private apiBasicSettingsOauthService = inject(ApiBasicSettingsOauthService);
  private translateService = inject(TranslateService);

  @Input({required: true}) form!: OwnInternalConsignmentForm;

  @Output() organizationConsigneeChanged: EventEmitter<OrganizationConsignee> = new EventEmitter<OrganizationConsignee>();

  organizationalUnits$ = this.orgStructureService.organizationalUnits().pipe(
    tap(units => this.organizationUnits = units),
    map(units => units.map(namedDtoToOption(true)))
  );

  sheetNodesOptions$ = this.codebookService.sheetNodes().pipe(
    map(sheetNodes => {
      if (sheetNodes) {
        return sheetNodes.filter(sheetNode => sheetNode.isActive).filter(node => node.purpose === DistributionNodeType.SEND_RECEIVE || node.purpose === DistributionNodeType.RECEIVE);
      } else {
        return [];
      }
    }),
    tap(sheetNodes => this.sheetNodes = sheetNodes),
    map(sheetNodes => sheetNodes.map(sheetNodeToOption(this.translateService))),
  );

  sheetNodes: SheetNodeDto[] = [];
  organizationUnits: OrganizationalUnitDto[] = [];

  organizationDesc = '';

  organizationDummyConsignee!: OrganizationConsignee;

  ngOnInit(): void {
    this.loadingService.doLoading(
      this.apiBasicSettingsOauthService.basicSettingsOauthFind(),
      this
    ).subscribe(settings => {
      this.organizationDesc = `${settings.organizationSubjectType} - ${settings.organizationName}, ${settings.organizationCid}`;
      this.organizationDummyConsignee = {
        sheetNode: null,
        subject: {
          identifiable: false,
          classification: settings.organizationSubjectType as unknown as SubjectRecordClassification,
          attributes: {}
        }
      };
      this.organizationConsigneeChanged.emit(this.organizationDummyConsignee);

    });

    this.form.get('consigneeOrganizationalUnitId')!.valueChanges.subscribe(ouId=> {
      if (ouId) {
        const orgUnit = this.organizationUnits.find(ou => ou.id === ouId);
        this.form.get('consigneeAdditionalName')!.setValue(orgUnit ? orgUnit.name : null);
      }
    });

    this.form.get('filingOfficeSheetNodeId')!.valueChanges.subscribe(nodeId => {
      if (nodeId) {
        const baseAttr = {
          source: SubjectAttributeSource.INTERNAL,
          state: SubjectAttributeState.CORRECT,
          validFrom: new Date().toISOString(),
        };
        this.organizationDummyConsignee.sheetNode = this.sheetNodes.find(sn => sn.id === nodeId);
        this.organizationDummyConsignee.subject.attributes =
          { mailingAddress: {
            ...baseAttr,
              value: this.organizationDummyConsignee.sheetNode!.address
            }
          };
        this.form.get('consigneeAddress')!.setValue(this.organizationDummyConsignee.sheetNode!.address);
        this.organizationConsigneeChanged.emit(this.organizationDummyConsignee);
      }
    });
  }

}
