import {Directive, ElementRef, inject, OnInit} from '@angular/core';

@Directive({
  selector: '[iczLinkWithoutHref]'
})
export class LinkWithoutHrefDirective implements OnInit {

  elementRef = inject(ElementRef);

  ngOnInit() {
    (this.elementRef.nativeElement as HTMLLinkElement).tabIndex = 0;
  }

}
