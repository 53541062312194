import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

const LINE_HEIGHT = 20; // px

@Component({
  selector: 'icz-expandable-paragraph',
  templateUrl: './expandable-paragraph.component.html',
  styleUrls: ['./expandable-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableParagraphComponent {

  @Input({required: true})
  contents = '';
  @Input()
  lineCountLimit = 2;

  isExpanded = false;

  @HostBinding('style.max-height.px')
  get heightLimit() {
    return this.isExpanded ? null : this.lineCountLimit * LINE_HEIGHT;
  }

  expanderClicked() {
    this.isExpanded = !this.isExpanded;
  }

  isContentExpandable(contentElement: Element) {
    return contentElement.clientHeight > (this.heightLimit ?? -Infinity);
  }

}
