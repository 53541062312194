export enum DocumentsDashboardRoute {
  DASHBOARD = 'dashboard',
}

export enum DocumentDetailRoute {
  PREVIEW = 'preview',
  HISTORY = 'history',
  DELIVERY = 'delivery',
  SUBJECTS = 'subjects',
  OWN_CONSIGNMENTS = 'own-consignments',
  OFFICE_DESK_CONSIGNMENTS = 'office-desk-consignments',
  COMPONENTS = 'components',
  ACTIVITIES = 'activities',
  RELATED_OBJECTS = 'related-objects',
  SHARE = 'share',
  INCIDENTS = 'incidents',
}

export enum DocumentsRoute {
  DOCUMENT = 'document',
  FILE = 'file',
  STORAGE_UNIT = 'storage-unit',
  TASKS = 'tasks',
  IN_PROGRESS = 'in-progress',
  SETTLED = 'settled',
  EXPIRING = 'expiring',
  SIGNATURE_BOOK = 'signatures-book',
  NOTIFICATIONS = 'notifications',
  ADVANCED_SEARCH = 'advanced-search',
  SEARCH = 'search',
  MANUALLY_SHARED = 'manually-shared',
  DUPLICATE = 'duplicate',
  REGISTER_CONTENT_OFFICER = 'register-content-officer',
  REGISTER_CONTENT_FILING_OFFICE = 'register-content',
  RECEIVED = 'received',
  NEW_RESPONSE_DOCUMENT = 'new-response-document',
  EXTERNAL_APPLICATION_DOCUMENTS = 'external-application-documents',
  REGISTRY_OFFICE_TRANSFERS = 'registry-office-transfers',
}

export enum RegistryOfficeRoute {
  TO_TAKE_OVER = 'to-take-over',
  TO_RESOLVE_ISSUES = 'to-resolve-issues',
  REJECTED = 'rejected',
  PROBLEMS = 'problems',
  ALL = 'all',
}

export enum FilingOfficeRoute {
  RECEIVE_EMAIL_MESSAGE = 'receive-email-message',
  RECEIVE_DATABOX_MESSAGE = 'receive-databox-message',
  RECEIVE_PAPER_CONSIGNMENT = 'receive-paper-consignment',
  RECEIVE_PERSONAL_CONSIGNMENT = 'receive-personal-consignment',
  RECEIVED_EMAIL_MESSAGES = 'received-email-messages',
  RECEIVED_DATABOX_MESSAGES = 'received-databox-messages',
  RECEIVED_ALL_CONSIGNMENTS = 'received-all-consignments',
  HANDED_OVER = 'handed-over',
  RECEIVED_INTERNAL_CONSIGNMENTS = 'received-internal-consignments',
  RECEIVE_INTERNAL_CONSIGNMENT = 'receive-internal-consignment',
  REJECTED = 'rejected',
}

export enum DispatchRoute {
  TO_TAKE_OVER = 'to-take-over',
  TO_DISPATCH = 'to-dispatch',
  IN_DISTRIBUTION = 'in-distribution',
  DISPATCHED = 'dispatched',
  BULK_POSTING_FORMS = 'bulk-posting-forms',
  OFFICER_VIEW_ALL = 'officer-view-all',
  OFFICER_VIEW_IN_PROGRESS = 'officer-view-in-progress',
  OFFICER_VIEW_REJECTED = 'officer-view-rejected',
  OFFICER_VIEW_RECORD_DELIVERY_RESULT = 'officer-view-record-delivery-result',
  DISPATCH_OFFICE_RECORD_DELIVERY_RESULT = 'dispatch-office-record-delivery-result',
}

export enum OfficeDeskRoute {
  TO_TAKE_OVER = 'to-take-over',
  TO_POST = 'to-post',
  POSTED = 'posted',
  UNPOSTED = 'unposted',
  OFFICER_VIEW_ALL = 'officer-view-all',
  OFFICER_VIEW_ALL_POSTED = 'officer-view-posted-all',
  OFFICER_VIEW_IN_PROGRESS = 'officer-view-in-progress',
  OFFICER_VIEW_REJECTED = 'officer-view-rejected',
}

export enum StorageUnitDetailRoute {
  PREVIEW = 'preview',
  CONTENT = 'content',
  HISTORY = 'history',
  SHARE = 'share',
  INCIDENTS = 'incidents',
}
