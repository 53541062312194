/* eslint-disable no-console */
import {DestroyRef, inject, Injectable} from '@angular/core';
import {CurrentSessionService} from '../../services/current-session.service';
import {BehaviorSubject, fromEvent, Observable, of, Subject, switchMap, throwError} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ENVIRONMENT} from '../services/environment.service';
import {LocalStorageKey} from '../../services/user-settings.service';
import {AccessTokenData, AuthToken, FrontendToken, TokenApiResponse} from './auth.helpers';
import {distinctUntilChanged, map, startWith, tap} from 'rxjs/operators';
import {IAuthService} from '../../services/services-utils';
import {SKIP_ERROR_DIALOG} from '../error-handling/http-errors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RouteRestorationService} from '../../services/route-restoration.service';
import {Router} from '@angular/router';

// Percentage of time between issueTime and expiryTime of an access token,
// used for triggering periodic background token refresh.
const TOKEN_REFRESH_PERIOD = 0.75;

interface AuthHeaderMetadata {
  headers: {Authorization: string};
  withCredentials: boolean;
}

export enum LogoutType {
  USER_INITIATED = 'USER_INITIATED',
  LOGIN_ABORTED = 'LOGIN_ABORTED',
  PROGRAMMATIC = 'PROGRAMMATIC',
}

@Injectable({providedIn: 'root'})
export class AuthService implements IAuthService {

  private httpClient = inject(HttpClient);
  private destroyRef = inject(DestroyRef);
  private currentSessionService = inject(CurrentSessionService);
  private routeRestorationService = inject(RouteRestorationService);
  private environment = inject(ENVIRONMENT);
  private router = inject(Router);

  authToken: Nullable<AuthToken>;

  private handleGlobalSessionAccessTokenDataChange = true;
  private _globalSessionAccessTokenData$ = new BehaviorSubject<Nullable<AccessTokenData>>(null);
  globalSessionAccessTokenData$ = this._globalSessionAccessTokenData$.asObservable();

  private _login$ = new Subject<void>();
  login$ = this._login$.asObservable();

  private _logout$ = new Subject<LogoutType>();
  logout$ = this._logout$.asObservable();

  private _refreshToken$ = new Subject<void>();
  refreshToken$ = this._refreshToken$.asObservable();

  private tokenRefreshTimeout: Nullable<number>;

  get timeoutPeriodMs(): number {
    return this.getTimeoutPeriodMs(this.authToken);
  }

  get isAuthenticatedWithoutFunctionalPosition() {
    return this.getIsAuthenticatedWithoutFunctionalPosition(this.authToken);
  }

  get isAuthenticatedWithFunctionalPosition() {
    return this.getIsAuthenticatedWithFunctionalPosition(this.authToken);
  }

  get hasExpiredAccessWithFunctionalPosition() {
    return this.getHasExpiredAccessWithFunctionalPosition(this.authToken);
  }

  get isTokenValidityWithinExpiryToleranceRange() {
    return this.getIsTokenValidityWithinExpiryToleranceRange(this.authToken);
  }

  get isSessionRestorable() {
    return this.getIsSessionRestorable(this.authToken);
  }

  get globalSessionAccessTokenData() {
    return this._globalSessionAccessTokenData$.value;
  }

  private get clientCredentials(): AuthHeaderMetadata {
    return {
      headers: {Authorization: this.environment.clientCredentials},
      withCredentials: true,
    };
  }

  constructor() {
    this.initCrossTabAuthSynchronization();
  }

  tryRestoreSession() {
    this.restoreAccessTokenFromStorage();

    if (this.isSessionRestorable) {
      return this.setUpUserSessionInfo(this.authToken!).pipe(
        tap(_ => this.setUpRefreshTimeout()),
        tap(_ => this._login$.next()),
      );
    }
    else if (this.hasExpiredAccessWithFunctionalPosition) {
      return this.requestTokenRefresh(this.authToken!).pipe(
        switchMap(token => this.setUpUserSessionInfo(token)),
        tap(_ => this.setUpRefreshTimeout()),
        tap(_ => this._login$.next()),
      );
    }
    else {
      this.deleteAccessTokenFromStorage();
      return of(null) as unknown as Observable<void>;
    }
  }

  logIn(orgCode: string, username: string, password: string) {
    console.info('logIn');

    return this.genericRequestAndSaveToken({
      grant_type: 'password',
      organization_code: orgCode,
      username,
      password,
    }).pipe(
      switchMap(token => this.setUpUserSessionInfo(token)),
    );
  }

  selectFunctionalPosition(functionalPositionCode: string) {
    console.info('selectFunctionalPosition', functionalPositionCode);

    if (this.isAuthenticatedWithoutFunctionalPosition) {
      return this.requestTokenForUserAndFunctionalPosition(
        this.authToken!,
        functionalPositionCode
      ).pipe(
        switchMap(token => this.setUpUserSessionInfo(token)),
        tap(_ => this.persistAccessTokenToStorage()),
        tap(_ => this.setUpRefreshTimeout()),
        tap(_ => this._login$.next()),
      );
    }
    else {
      return throwError(() => new Error(`User has invalid access token while trying to select functional position.`));
    }
  }

  logOut(logoutType = LogoutType.USER_INITIATED) {
    console.info('logOut', logoutType, new Error().stack);

    this.clearRefreshTimeout();
    this.authToken = null;

    this.routeRestorationService.storeUrlBeforeLogin(this.router.url);
    this.currentSessionService.unsetSession();

    this.deleteAccessTokenFromStorage();

    this.handleGlobalSessionAccessTokenDataChange = false;
    this._globalSessionAccessTokenData$.next(null);
    this.handleGlobalSessionAccessTokenDataChange = true;

    this._logout$.next(logoutType);
  }

  logOutSessionsInOtherTabs() {
    console.info('logOutSessionsInOtherTabs');

    this.deleteAccessTokenFromStorage();

    this.handleGlobalSessionAccessTokenDataChange = false;
    this._globalSessionAccessTokenData$.next(null);
    this.handleGlobalSessionAccessTokenDataChange = true;
  }

  cancelLoginProcess() {
    console.info('cancelLoginProcess');

    this.clearRefreshTimeout();
    this.authToken = null;
    this.currentSessionService.unsetSession();
  }

  private setUpRefreshTimeout() {
    if (isNil(this.tokenRefreshTimeout) && this.isAuthenticatedWithFunctionalPosition) {
      // const timeoutPeriod = 11000; // for debugging
      const timeoutPeriod = this.timeoutPeriodMs;

      console.info(`Token refresh timeout set with period=${timeoutPeriod}`);

      this.tokenRefreshTimeout = setTimeout(() => {
        console.info('Periodic token refresh fired', this.isAuthenticatedWithFunctionalPosition);

        if (this.isAuthenticatedWithFunctionalPosition) {
          this.requestTokenRefresh(this.authToken!).subscribe(() => {
            this.persistAccessTokenToStorage();

            this.clearRefreshTimeout();
            this.setUpRefreshTimeout();
            this._refreshToken$.next();
          });
        }
      }, timeoutPeriod) as unknown as number;
    }
  }

  private clearRefreshTimeout() {
    if (!isNil(this.tokenRefreshTimeout)) {
      console.info('Periodic token refresh cleared');
      clearTimeout(this.tokenRefreshTimeout);
      this.tokenRefreshTimeout = null;
    }
  }

  private requestTokenForUserAndFunctionalPosition(authToken: AuthToken, functionalPositionCode: string): Observable<AuthToken> {
    console.info('requestTokenForUserAndFunctionalPosition', functionalPositionCode);

    return this.genericRequestAndSaveToken({
      grant_type: 'functional_position',
      access_token: authToken.access_token,
      functional_position: functionalPositionCode,
      client_id: 'frontend',
    });
  }

  private requestTokenRefresh(authToken: AuthToken): Observable<AuthToken> {
    console.info('requestTokenRefresh');

    return this.genericRequestAndSaveToken({
      grant_type: 'refresh_token',
      refresh_token: authToken.refresh_token,
      client_id: 'frontend',
    });
  }

  private genericRequestAndSaveToken(tokenRequestObject: Record<string, string>): Observable<AuthToken> {
    return this.httpClient.post<TokenApiResponse>(
      this.environment.getTokenUrl,
      new HttpParams({
        fromObject: tokenRequestObject,
      }),
      {
        ...this.clientCredentials,
        context: SKIP_ERROR_DIALOG,
      },
    ).pipe(
      tap({
        next: tokenApiResponse => {
          this.authToken = AuthToken.fromApi(tokenApiResponse);
        },
        error: _ => {
          this.logOut(LogoutType.PROGRAMMATIC);
        }
      }),
      map(_ => this.authToken!),
    );
  }

  private restoreAccessTokenFromStorage() {
    console.info('restoreAccessTokenFromStorage');

    let token: Nullable<FrontendToken>;

    try {
      token = JSON.parse(localStorage.getItem(LocalStorageKey.ACCESS_TOKEN)!) ?? null;

      if (token) {
        if (typeof token.access_token !== 'string' || typeof token.refresh_token !== 'string') {
          token = null;
        }
      }
    }
    catch {
      token = null;
    }

    if (token) {
      console.info('Token found in local storage');
      this.authToken = AuthToken.fromJson(token);
    }
    else {
      console.info('Token not found in local storage');
    }
  }

  private persistAccessTokenToStorage() {
    console.info('persistAccessTokenToStorage', !isNil(this.authToken));
    localStorage.setItem(LocalStorageKey.ACCESS_TOKEN, this.authToken!.toReplacedStringified());
  }

  private deleteAccessTokenFromStorage() {
    console.info('deleteAccessTokenFromStorage');
    localStorage.removeItem(LocalStorageKey.ACCESS_TOKEN);
  }

  private setUpUserSessionInfo(token: AuthToken) {
    return this.currentSessionService.setSession(
      token.access_token_data.organization_id,
      token.access_token_data.user_id,
      token.access_token_data.functional_position_id,
    );
  }

  private getTimeoutPeriodMs(token: Nullable<AuthToken>): number {
    if (!token) {
      return 0;
    }
    else {
      return Math.floor((Number(token.expires) - Number(new Date())) * TOKEN_REFRESH_PERIOD);
    }
  }

  private getIsAuthenticatedWithoutFunctionalPosition(token: Nullable<AuthToken>): boolean {
    return Boolean(token?.isValid);
  }

  private getIsAuthenticatedWithFunctionalPosition(token: Nullable<AuthToken>): boolean {
    return this.getIsAuthenticatedWithoutFunctionalPosition(token) && Boolean(token?.functional_position);
  }

  private getHasExpiredAccessWithFunctionalPosition(token: Nullable<AuthToken>): boolean {
    return Boolean(
      token?.functional_position &&
      !token?.isAccessTokenValid &&
      token?.isRefreshTokenValid
    );
  }

  private getIsTokenValidityWithinExpiryToleranceRange(token: Nullable<AuthToken>): boolean {
    return Boolean(token && this.getTimeoutPeriodMs(token) > 10_000);
  }

  private getIsSessionRestorable(token: Nullable<AuthToken>): boolean {
    return this.getIsAuthenticatedWithFunctionalPosition(token) && this.getIsTokenValidityWithinExpiryToleranceRange(token);
  }

  private initCrossTabAuthSynchronization() {
    fromEvent(window, 'storage').pipe(
      startWith(null),
      map(_ => window.localStorage),
      map(localStorage => localStorage[LocalStorageKey.ACCESS_TOKEN] as string),
      distinctUntilChanged(),
      map(authTokenFromStorage => {
        if (authTokenFromStorage) {
          const authToken = AuthToken.fromJson(JSON.parse(authTokenFromStorage))!;

          if (this.getIsSessionRestorable(authToken)) {
            return authToken.access_token_data;
          }
        }

        return null;
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(this._globalSessionAccessTokenData$);

    this.globalSessionAccessTokenData$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(accessTokenData => {
      if (!accessTokenData && this.handleGlobalSessionAccessTokenDataChange) {
        this.logOut(LogoutType.PROGRAMMATIC);
      }
    });
  }

}
