<div [formGroup]="form" class="row grow">
  <icz-form-field class="grow" formControlName="name"
                  label="Název"></icz-form-field>
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              (onAction)="ok()" primary label="Uložit"></icz-button>
  <icz-button rightButtons
              (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
