import {inject} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IFormGroupCheckable, openModalOnChanges} from '../../lib/form-group-checks';
import {ActivatedRouteSnapshot, CanDeactivateFn, RouterStateSnapshot} from '@angular/router';
import {createAbsoluteRoute} from '../routing/routing.helpers';
import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {DialogService} from '../services/dialog.service';

const guardUnsaved = (component: IFormGroupCheckable, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot) => {

  if (
    nextState.url === createAbsoluteRoute(ApplicationRoute.NETWORK_UNAVAILABLE) ||
    nextState.url === createAbsoluteRoute(ApplicationRoute.WAITING_TO_BE) ||
    nextState.url === createAbsoluteRoute(ApplicationRoute.LOGIN)
  ) {
    return true;
  }
  else {
    const dialogService = inject(DialogService);

    const isDialogAlreadyOpen = dialogService.isAnyDialogOpened();

    if (isDialogAlreadyOpen) {
      return false;
    } else {
      return openModalOnChanges<Observable<boolean>|boolean>(
        component,
        dialogService,
        {
          changesDetected: afterClosed$ => {
            return afterClosed$.pipe(
              map(result => {
                return !result;
              })
            );
          },
          changesNotDetected: () => true
        }
      );
    }
  }
};

export function guardUnsavedForm(): CanDeactivateFn<IFormGroupCheckable> {
  return guardUnsaved;
}
