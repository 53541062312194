/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { DocumentSharingStatusDto } from '../models/document-sharing-status-dto';
import { entitySharingCountSharingStatusForDocument } from '../fn/entity-sharing/entity-sharing-count-sharing-status-for-document';
import { EntitySharingCountSharingStatusForDocument$Params } from '../fn/entity-sharing/entity-sharing-count-sharing-status-for-document';
import { entitySharingCountSharingStatusForFile } from '../fn/entity-sharing/entity-sharing-count-sharing-status-for-file';
import { EntitySharingCountSharingStatusForFile$Params } from '../fn/entity-sharing/entity-sharing-count-sharing-status-for-file';
import { entitySharingCountSharingStatusForStorageUnit } from '../fn/entity-sharing/entity-sharing-count-sharing-status-for-storage-unit';
import { EntitySharingCountSharingStatusForStorageUnit$Params } from '../fn/entity-sharing/entity-sharing-count-sharing-status-for-storage-unit';
import { entitySharingGetSharingStatusForDocument } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-document';
import { EntitySharingGetSharingStatusForDocument$Params } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-document';
import { entitySharingGetSharingStatusForFile } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-file';
import { EntitySharingGetSharingStatusForFile$Params } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-file';
import { entitySharingGetSharingStatusForSharedFolder } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-shared-folder';
import { EntitySharingGetSharingStatusForSharedFolder$Params } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-shared-folder';
import { entitySharingGetSharingStatusForStorageUnit } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-storage-unit';
import { EntitySharingGetSharingStatusForStorageUnit$Params } from '../fn/entity-sharing/entity-sharing-get-sharing-status-for-storage-unit';
import { FileSharingStatusDto } from '../models/file-sharing-status-dto';
import { SharedFolderSharingStatusDto } from '../models/shared-folder-sharing-status-dto';
import { StorageUnitSharingStatusDto } from '../models/storage-unit-sharing-status-dto';

@Injectable({ providedIn: 'root' })
export class ApiEntitySharingService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `entitySharingGetSharingStatusForStorageUnit()` */
  static readonly EntitySharingGetSharingStatusForStorageUnitPath = '/entity-sharing/storage-unit/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingGetSharingStatusForStorageUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForStorageUnit$Response(params: EntitySharingGetSharingStatusForStorageUnit$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitSharingStatusDto>> {
    return entitySharingGetSharingStatusForStorageUnit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingGetSharingStatusForStorageUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForStorageUnit(params: EntitySharingGetSharingStatusForStorageUnit$Params, context?: HttpContext): Observable<StorageUnitSharingStatusDto> {
    return this.entitySharingGetSharingStatusForStorageUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitSharingStatusDto>): StorageUnitSharingStatusDto => r.body)
    );
  }

  /** Path part for operation `entitySharingCountSharingStatusForStorageUnit()` */
  static readonly EntitySharingCountSharingStatusForStorageUnitPath = '/entity-sharing/storage-unit/{id}/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingCountSharingStatusForStorageUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingCountSharingStatusForStorageUnit$Response(params: EntitySharingCountSharingStatusForStorageUnit$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return entitySharingCountSharingStatusForStorageUnit(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingCountSharingStatusForStorageUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingCountSharingStatusForStorageUnit(params: EntitySharingCountSharingStatusForStorageUnit$Params, context?: HttpContext): Observable<number> {
    return this.entitySharingCountSharingStatusForStorageUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `entitySharingGetSharingStatusForSharedFolder()` */
  static readonly EntitySharingGetSharingStatusForSharedFolderPath = '/entity-sharing/shared-folder/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingGetSharingStatusForSharedFolder()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForSharedFolder$Response(params: EntitySharingGetSharingStatusForSharedFolder$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderSharingStatusDto>> {
    return entitySharingGetSharingStatusForSharedFolder(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingGetSharingStatusForSharedFolder$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForSharedFolder(params: EntitySharingGetSharingStatusForSharedFolder$Params, context?: HttpContext): Observable<SharedFolderSharingStatusDto> {
    return this.entitySharingGetSharingStatusForSharedFolder$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderSharingStatusDto>): SharedFolderSharingStatusDto => r.body)
    );
  }

  /** Path part for operation `entitySharingGetSharingStatusForFile()` */
  static readonly EntitySharingGetSharingStatusForFilePath = '/entity-sharing/file/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingGetSharingStatusForFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForFile$Response(params: EntitySharingGetSharingStatusForFile$Params, context?: HttpContext): Observable<StrictHttpResponse<FileSharingStatusDto>> {
    return entitySharingGetSharingStatusForFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingGetSharingStatusForFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForFile(params: EntitySharingGetSharingStatusForFile$Params, context?: HttpContext): Observable<FileSharingStatusDto> {
    return this.entitySharingGetSharingStatusForFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileSharingStatusDto>): FileSharingStatusDto => r.body)
    );
  }

  /** Path part for operation `entitySharingCountSharingStatusForFile()` */
  static readonly EntitySharingCountSharingStatusForFilePath = '/entity-sharing/file/{id}/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingCountSharingStatusForFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingCountSharingStatusForFile$Response(params: EntitySharingCountSharingStatusForFile$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return entitySharingCountSharingStatusForFile(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingCountSharingStatusForFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingCountSharingStatusForFile(params: EntitySharingCountSharingStatusForFile$Params, context?: HttpContext): Observable<number> {
    return this.entitySharingCountSharingStatusForFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `entitySharingGetSharingStatusForDocument()` */
  static readonly EntitySharingGetSharingStatusForDocumentPath = '/entity-sharing/document/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingGetSharingStatusForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForDocument$Response(params: EntitySharingGetSharingStatusForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentSharingStatusDto>> {
    return entitySharingGetSharingStatusForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingGetSharingStatusForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingGetSharingStatusForDocument(params: EntitySharingGetSharingStatusForDocument$Params, context?: HttpContext): Observable<DocumentSharingStatusDto> {
    return this.entitySharingGetSharingStatusForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentSharingStatusDto>): DocumentSharingStatusDto => r.body)
    );
  }

  /** Path part for operation `entitySharingCountSharingStatusForDocument()` */
  static readonly EntitySharingCountSharingStatusForDocumentPath = '/entity-sharing/document/{id}/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `entitySharingCountSharingStatusForDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingCountSharingStatusForDocument$Response(params: EntitySharingCountSharingStatusForDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return entitySharingCountSharingStatusForDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `entitySharingCountSharingStatusForDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  entitySharingCountSharingStatusForDocument(params: EntitySharingCountSharingStatusForDocument$Params, context?: HttpContext): Observable<number> {
    return this.entitySharingCountSharingStatusForDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
