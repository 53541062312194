import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnDestroy, OnInit} from '@angular/core';
import {from, of} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import axios, {AxiosResponse} from 'axios';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import Timeout = NodeJS.Timeout;

const AVAILABILITY_CHECK_INTERVAL = 5_000; // ms


@Component({
  selector: 'icz-application-unavailable-page',
  templateUrl: './application-unavailable-page.component.html',
  styleUrls: ['./application-unavailable-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationUnavailablePageComponent implements OnInit, OnDestroy {

  private destroyRef = inject(DestroyRef);

  private checkIntervalHandle: Nullable<Timeout> = null;

  ngOnInit() {
    this.doAvailabilityChecks();
  }

  ngOnDestroy(): void {
    this.destroyCheckInterval();
  }

  doAvailabilityChecks() {
    // Using rxjs interval(...) proved behaving weird after stopping DevServer.
    this.checkIntervalHandle = setInterval(() => {
      from(axios.get('/')).pipe(
        map((response: AxiosResponse) => response.status === 200),
        catchError(_ => of(false)),
        filter(value => value === true),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe({
        next: _ => this.refreshApplication(),
      });
    }, AVAILABILITY_CHECK_INTERVAL);
  }

  refreshApplication() {
    if (this.checkIntervalHandle !== null) {
      this.destroyCheckInterval();
    }

    location.href = '/';
  }

  private destroyCheckInterval() {
    clearInterval(this.checkIntervalHandle as Timeout);
    this.checkIntervalHandle = null;
  }

}
