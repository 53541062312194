@if (isSelectedClassifierExpired) {
  <icz-alert
    severity="warning"
    class="mb-12" heading="Neplatný typ dokumentu"
  ></icz-alert>
}
<ng-container [formGroup]="form">
  <icz-form-autocomplete
    [label]="label"
    [formControlName]="controlName"
    [isMultiselect]="isMultiselect"
    [fieldDisabled]="fieldDisabled"
    [options]="classifierOptions"
    [showRightLabelPopupOnClick]="true"
    [rightLabel]="(selectedSelectedDocumentId && showPopupDetails) ? 'Náhled typu dokumentu' : undefined"
  >
    <ng-container rightLabelPopup>
      @if (!isMultiselect) {
        <icz-document-type-selector-popup [classifierOptions]="classifierOptions" [selectedSelectedDocumentId]="selectedSelectedDocumentId"></icz-document-type-selector-popup>
      }
    </ng-container>
  </icz-form-autocomplete>
</ng-container>
