import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {FileSizeUnit, transformFileSize} from '../../../../../essentials/file-size.pipe';
import {IczFormControl, IczFormGroup} from '../../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../../form-elements/validators/icz-validators/icz-validators';
import {Option} from '../../../../../../model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface FileSizeChangedEvent {
  fileSize: number;
  unit: FileSizeUnit;
}


@Component({
  selector: 'icz-file-size-filter',
  templateUrl: './file-size-filter.component.html',
  styleUrls: ['./file-size-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSizeFilterComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Output() fileSizeChanged = new EventEmitter<FileSizeChangedEvent>();

  form = new IczFormGroup({
    fileSize: new IczFormControl<Nullable<number>>(null, [IczValidators.min(0), IczValidators.isDecimal()]),
    unit: new IczFormControl<Nullable<FileSizeUnit>>(FileSizeUnit.KB),
  });

  sizeOptions: Option[] = [
    {label: 'B', value: FileSizeUnit.B},
    {label: 'kB', value: FileSizeUnit.KB},
    {label: 'MB', value: FileSizeUnit.MB},
    {label: 'GB', value: FileSizeUnit.GB},
  ];

  formValueChanged(value: {fileSize?: Nullable<number>, unit?: Nullable<FileSizeUnit>}) {
    const {fileSize, unit} = value;
    const fileSizeTransformed = transformFileSize(fileSize!, unit!, true);
    if (this.form.valid) {
      this.fileSizeChanged.emit({
        fileSize: fileSizeTransformed,
        unit: unit!,
      });
    }
  }

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => this.formValueChanged(value));
  }
}
