import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of, Subject, switchMap} from 'rxjs';
import {
  ApiFunctionalPositionService,
  ApiUserService,
  FunctionalPositionWithSubstitutionDto,
  UserDto
} from '|api/auth-server';
import {castStream} from '../lib/rxjs';
import {map, tap} from 'rxjs/operators';
import {OrganizationDto} from '|api/commons';
import {OrganizationsService} from './organizations.service';
import {Cacheable} from 'ts-cacheable';

const cacheReset$ = new Subject<void>();

const cacheConfig = {
  cacheBusterObserver: cacheReset$,
};

@Injectable({
  providedIn: 'root'
})
export class CurrentSessionService {

  private apiUserService = inject(ApiUserService);
  private organizationsService = inject(OrganizationsService);
  private apiFunctionalPositionService = inject(ApiFunctionalPositionService);

  private _currentOrganization$ = new BehaviorSubject<Nullable<OrganizationDto>>(null);
  currentOrganization$ = this._currentOrganization$.asObservable();

  private _currentUser$ = new BehaviorSubject<Nullable<UserDto>>(null);
  currentUser$ = this._currentUser$.asObservable();

  private _currentUserFunctionalPosition$ = new BehaviorSubject<Nullable<FunctionalPositionWithSubstitutionDto>>(null);
  currentUserFunctionalPosition$ = this._currentUserFunctionalPosition$.asObservable();

  get currentUser(): Nullable<UserDto> {
    return this._currentUser$.value;
  }

  get currentUserFunctionalPosition(): Nullable<FunctionalPositionWithSubstitutionDto> {
    return this._currentUserFunctionalPosition$.value;
  }

  get currentOrganization(): Nullable<OrganizationDto> {
    return this._currentOrganization$.value;
  }

  @Cacheable(cacheConfig)
  getAvailableFunctionalPlacesWithSubstitutions(): Observable<FunctionalPositionWithSubstitutionDto[]> {
    return this.apiFunctionalPositionService.functionalPositionGetFunctionalPositionsWithSubstitutions();
  }

  setSession(organizationId: number, userId: number, functionalPositionId: Nullable<number>): Observable<void> {
    return this.apiUserService.userFindById({
      id: userId,
    }).pipe(
      switchMap(user => {
        return forkJoin([
          this.organizationsService.getOrganizations().pipe(
            map(organizations => {
              const organization = organizations.find(o => o.id === organizationId);

              if (!organization) {
                throw new Error(`Organization with ID=${organizationId} not found.`);
              }

              if (user.organizationId !== organization!.id) {
                throw new Error(`Current user does not belong to an organization with ID=${organizationId}.`);
              }

              return organization;
            }),
          ),
          of(user),
        ]);
      }),
      switchMap(([organization, user]) => {
        if (functionalPositionId) {
          return forkJoin([
            of(organization),
            of(user),
            this.getAvailableFunctionalPlacesWithSubstitutions().pipe(
              map(functionalPositionsWithSubstitutions => {
                const functionalPosition = functionalPositionsWithSubstitutions.find(fp => fp.id === functionalPositionId);

                if (!functionalPosition) {
                  throw new Error(`Functional position with ID=${functionalPositionId} not found among available functional positions for the current user.`);
                }

                return functionalPosition;
              }),
            ),
          ]);
        }
        else {
          return of([
            organization,
            user,
            null
          ] as [OrganizationDto, UserDto, Nullable<FunctionalPositionWithSubstitutionDto>]);
        }
      }),
      tap(([organization, user, functionalPosition]) => {
        cacheReset$.next();
        this._currentOrganization$.next(organization);
        this._currentUser$.next(user);
        this._currentUserFunctionalPosition$.next(functionalPosition);
      }),
      castStream<void>(),
    );
  }

  unsetSession() {
    this._currentUser$.next(null);
    this._currentUserFunctionalPosition$.next(null);
    this.resetOrgStructureCache();
  }

  resetOrgStructureCache() {
    cacheReset$.next();
  }

}
