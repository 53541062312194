<icz-validation-errors-list [form]="_form" [showFieldErrors]="true" [fieldsValidationErrors]="empowermentErrors">
  <icz-label label="Zmocnění"></icz-label>
  <form [formGroup]="_form">
    <div class="row-group field-prefix">
      <icz-form-field
        class="grow"
        formControlName="law"
        [switchPrefixAndPlaceholder]="true"
        [showValidationStatus]="false"
        [placeholder]="empowermentFieldNames.law"
        [fieldDisabled]="fieldDisabled"
        type="integer"
        (blur)="blur.emit()">
        <span prefix>{{'zák. č.' | translate}}</span>
      </icz-form-field>
      <icz-form-field
        class="grow"
        formControlName="year"
        [switchPrefixAndPlaceholder]="true"
        [showValidationStatus]="false"
        [placeholder]="empowermentFieldNames.year"
        [fieldDisabled]="fieldDisabled"
        type="integer"
        (blur)="blur.emit()">
        <span prefix>{{'rok' | translate}}</span>
      </icz-form-field>
      <icz-form-field
        class="grow"
        formControlName="section"
        [switchPrefixAndPlaceholder]="true"
        [showValidationStatus]="false"
        [placeholder]="empowermentFieldNames.section"
        [fieldDisabled]="fieldDisabled"
        (blur)="blur.emit()">
        <span prefix>§</span>
      </icz-form-field>
      <icz-form-field
        class="grow"
        formControlName="paragraph"
        [switchPrefixAndPlaceholder]="true"
        [showValidationStatus]="false"
        [placeholder]="empowermentFieldNames.paragraph"
        [fieldDisabled]="fieldDisabled"
        (blur)="blur.emit()">
        <span prefix>{{'ods.' | translate}}</span>
      </icz-form-field>
      <icz-form-field
        class="grow"
        formControlName="point"
        [switchPrefixAndPlaceholder]="true"
        [showValidationStatus]="false"
        [placeholder]="empowermentFieldNames.point"
        [fieldDisabled]="fieldDisabled"
        (blur)="blur.emit()">
        <span prefix>{{'pís.' | translate}}</span>
      </icz-form-field>
    </div>
  </form>
</icz-validation-errors-list>
