/* eslint-disable @angular-eslint/directive-class-suffix */
import {DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Router} from '@angular/router';
import {interval, merge, of} from 'rxjs';
import {catchError, filter, map, share, switchMap, take, tap} from 'rxjs/operators';
import {BackendStatus, checkApiIsReady, EurekaAppStatus} from '../../api';
import {HealthcheckService} from '../../core/guards/healthcheck.service';
import {ApiService} from '../../core/services/api.service';
import {ApplicationRoute} from '../../enums/shared-routes.enum';


@Directive()
export abstract class AbstractBackendStatusComponent implements OnInit {

  private healthcheckService = inject(HealthcheckService);
  private apiService = inject(ApiService);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  get apiStatus() {
    return this.status
      .filter(app => !app.excludeFromApi)
      .sort((a, b) => {
        const an = a.name.toLowerCase();
        const bn = b.name.toLowerCase();
        return (an < bn) ? -1 : (an > bn) ? 1 : 0;
      });
  }

  private status: EurekaAppStatus[] = [];

  ngOnInit() {
    const beCheckTimer$ = merge(
      of(null),
      interval(5_000).pipe(take(10)),
      interval(60_000),
    );
    const backendStatus$ = beCheckTimer$.pipe(
      switchMap(() => this.apiService.getBackendAppsStatus()),
      catchError(_ => of([])),
      tap(status => this.status = status),
      map(checkApiIsReady),
      share(),
    );

    backendStatus$.pipe(
      filter(backendStatus => backendStatus === BackendStatus.DOWN),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: () => this.router.navigate([ApplicationRoute.WAITING_TO_BE])
    });

    backendStatus$.pipe(
      filter(backendStatus => backendStatus === BackendStatus.NETWORK_UNAVAILABLE),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: () => this.router.navigate([ApplicationRoute.NETWORK_UNAVAILABLE])
    });

    backendStatus$.pipe(
      filter(backendStatus => backendStatus === BackendStatus.UP),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: () => {
        this.router.navigateByUrl(this.healthcheckService.lastUsedRoute ?? ApplicationRoute.ROOT);
        this.healthcheckService.lastUsedRoute = null;
      },
    });
  }

}
