/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AllowedFormatsDto } from '../models/allowed-formats-dto';
import { BusinessRuleDto } from '../models/business-rule-dto';
import { businessRulesCreateRulesForEmailAttachmentFormat } from '../fn/business-rules/business-rules-create-rules-for-email-attachment-format';
import { BusinessRulesCreateRulesForEmailAttachmentFormat$Params } from '../fn/business-rules/business-rules-create-rules-for-email-attachment-format';
import { businessRulesDeleteByIds } from '../fn/business-rules/business-rules-delete-by-ids';
import { BusinessRulesDeleteByIds$Params } from '../fn/business-rules/business-rules-delete-by-ids';
import { businessRulesFindRuleByNameAndKey } from '../fn/business-rules/business-rules-find-rule-by-name-and-key';
import { BusinessRulesFindRuleByNameAndKey$Params } from '../fn/business-rules/business-rules-find-rule-by-name-and-key';
import { businessRulesFindRulesByKey } from '../fn/business-rules/business-rules-find-rules-by-key';
import { BusinessRulesFindRulesByKey$Params } from '../fn/business-rules/business-rules-find-rules-by-key';
import { businessRulesGetAllowedFormats } from '../fn/business-rules/business-rules-get-allowed-formats';
import { BusinessRulesGetAllowedFormats$Params } from '../fn/business-rules/business-rules-get-allowed-formats';
import { businessRulesSearch } from '../fn/business-rules/business-rules-search';
import { BusinessRulesSearch$Params } from '../fn/business-rules/business-rules-search';
import { businessRulesSearchEmailrules } from '../fn/business-rules/business-rules-search-emailrules';
import { BusinessRulesSearchEmailrules$Params } from '../fn/business-rules/business-rules-search-emailrules';
import { ElectronicChannelAttachmentBusinessRuleDto } from '../models/electronic-channel-attachment-business-rule-dto';
import { PageBusinessRuleDto } from '../models/page-business-rule-dto';
import { PageElectronicChannelAttachmentBusinessRuleDto } from '../models/page-electronic-channel-attachment-business-rule-dto';


/**
 * Controller for Business Rules
 */
@Injectable({ providedIn: 'root' })
export class ApiBusinessRulesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `businessRulesCreateRulesForEmailAttachmentFormat()` */
  static readonly BusinessRulesCreateRulesForEmailAttachmentFormatPath = '/codebook/business-rules/electronic-channel';

  /**
   * Creates business rule for email attachments.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesCreateRulesForEmailAttachmentFormat()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessRulesCreateRulesForEmailAttachmentFormat$Response(params: BusinessRulesCreateRulesForEmailAttachmentFormat$Params, context?: HttpContext): Observable<StrictHttpResponse<ElectronicChannelAttachmentBusinessRuleDto>> {
    return businessRulesCreateRulesForEmailAttachmentFormat(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates business rule for email attachments.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesCreateRulesForEmailAttachmentFormat$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessRulesCreateRulesForEmailAttachmentFormat(params: BusinessRulesCreateRulesForEmailAttachmentFormat$Params, context?: HttpContext): Observable<ElectronicChannelAttachmentBusinessRuleDto> {
    return this.businessRulesCreateRulesForEmailAttachmentFormat$Response(params, context).pipe(
      map((r: StrictHttpResponse<ElectronicChannelAttachmentBusinessRuleDto>): ElectronicChannelAttachmentBusinessRuleDto => r.body)
    );
  }

  /** Path part for operation `businessRulesFindRuleByNameAndKey()` */
  static readonly BusinessRulesFindRuleByNameAndKeyPath = '/codebook/business-rules/{name}/{key}/';

  /**
   * Finds business rule with given name, key and key value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesFindRuleByNameAndKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesFindRuleByNameAndKey$Response(params: BusinessRulesFindRuleByNameAndKey$Params, context?: HttpContext): Observable<StrictHttpResponse<BusinessRuleDto>> {
    return businessRulesFindRuleByNameAndKey(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds business rule with given name, key and key value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesFindRuleByNameAndKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesFindRuleByNameAndKey(params: BusinessRulesFindRuleByNameAndKey$Params, context?: HttpContext): Observable<BusinessRuleDto> {
    return this.businessRulesFindRuleByNameAndKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<BusinessRuleDto>): BusinessRuleDto => r.body)
    );
  }

  /** Path part for operation `businessRulesGetAllowedFormats()` */
  static readonly BusinessRulesGetAllowedFormatsPath = '/codebook/business-rules/{name}/allowed-formats';

  /**
   * Retrieves format informations for given business rule name.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesGetAllowedFormats()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesGetAllowedFormats$Response(params: BusinessRulesGetAllowedFormats$Params, context?: HttpContext): Observable<StrictHttpResponse<AllowedFormatsDto>> {
    return businessRulesGetAllowedFormats(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieves format informations for given business rule name.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesGetAllowedFormats$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesGetAllowedFormats(params: BusinessRulesGetAllowedFormats$Params, context?: HttpContext): Observable<AllowedFormatsDto> {
    return this.businessRulesGetAllowedFormats$Response(params, context).pipe(
      map((r: StrictHttpResponse<AllowedFormatsDto>): AllowedFormatsDto => r.body)
    );
  }

  /** Path part for operation `businessRulesFindRulesByKey()` */
  static readonly BusinessRulesFindRulesByKeyPath = '/codebook/business-rules/{key}/';

  /**
   * Finds business rules with given key and key value
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesFindRulesByKey()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesFindRulesByKey$Response(params: BusinessRulesFindRulesByKey$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<BusinessRuleDto>>> {
    return businessRulesFindRulesByKey(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds business rules with given key and key value
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesFindRulesByKey$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesFindRulesByKey(params: BusinessRulesFindRulesByKey$Params, context?: HttpContext): Observable<Array<BusinessRuleDto>> {
    return this.businessRulesFindRulesByKey$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<BusinessRuleDto>>): Array<BusinessRuleDto> => r.body)
    );
  }

  /** Path part for operation `businessRulesSearch()` */
  static readonly BusinessRulesSearchPath = '/codebook/business-rules/search-api';

  /**
   * Search api for business rule.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesSearch$Response(params?: BusinessRulesSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageBusinessRuleDto>> {
    return businessRulesSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Search api for business rule.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesSearch(params?: BusinessRulesSearch$Params|null|undefined, context?: HttpContext): Observable<PageBusinessRuleDto> {
    return this.businessRulesSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageBusinessRuleDto>): PageBusinessRuleDto => r.body)
    );
  }

  /** Path part for operation `businessRulesSearchEmailrules()` */
  static readonly BusinessRulesSearchEmailrulesPath = '/codebook/business-rules/electronic-channel/search-api';

  /**
   * Search api for email related business rules.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesSearchEmailrules()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesSearchEmailrules$Response(params?: BusinessRulesSearchEmailrules$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageElectronicChannelAttachmentBusinessRuleDto>> {
    return businessRulesSearchEmailrules(this.http, this.rootUrl, params, context);
  }

  /**
   * Search api for email related business rules.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesSearchEmailrules$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  businessRulesSearchEmailrules(params?: BusinessRulesSearchEmailrules$Params|null|undefined, context?: HttpContext): Observable<PageElectronicChannelAttachmentBusinessRuleDto> {
    return this.businessRulesSearchEmailrules$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageElectronicChannelAttachmentBusinessRuleDto>): PageElectronicChannelAttachmentBusinessRuleDto => r.body)
    );
  }

  /** Path part for operation `businessRulesDeleteByIds()` */
  static readonly BusinessRulesDeleteByIdsPath = '/codebook/business-rules/';

  /**
   * Deletes rules by id list
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `businessRulesDeleteByIds()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessRulesDeleteByIds$Response(params: BusinessRulesDeleteByIds$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return businessRulesDeleteByIds(this.http, this.rootUrl, params, context);
  }

  /**
   * Deletes rules by id list
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `businessRulesDeleteByIds$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  businessRulesDeleteByIds(params: BusinessRulesDeleteByIds$Params, context?: HttpContext): Observable<void> {
    return this.businessRulesDeleteByIds$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
