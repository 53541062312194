@if (initials) {
  <icz-button noBorder disableRipple
    rounded size="large" [background]="backgroundColor"
    class="no-label-margin"
    [class.blue]="color === UserInitialsColor.BLUE"
    [class.green]="color === UserInitialsColor.GREEN"
    [label]="initials | uppercase">
  </icz-button>
}
@if (!initials) {
  <icz-button noBorder rounded size="large" disableRipple
    [svgIcon]="initialsIcon"
    [background]="backgroundColor"
    originalIconColor
    class="no-label-margin"
    [class.user-icon]="!initials"
    [label]="(initials ?? '') | uppercase"
    [class.blue]="color === UserInitialsColor.BLUE"
    [class.green]="color === UserInitialsColor.GREEN">
  </icz-button>
}
