@if (task) {
  <span
    cdkOverlayOrigin
    #digitalComponentsPopupOrigin="cdkOverlayOrigin">
    <a class="visible-clickable-area p-4"
      iczLinkWithoutHref
    (click)="openTaskNotes($event, notesPopup)">{{ getNotesFieldText() }}</a>
    <icz-table-toolbar-popup
      #notesPopup
      [popoverOrigin]="digitalComponentsPopupOrigin">
      <icz-notification-card class="cursor-default">
        <div class="icz-body-strong mt-8" header>{{ 'en.circulationTaskState.' + task.taskState | translate }}</div>
        <div class="icz-body-1" body>{{ task.settlementNote }}</div>
      </icz-notification-card>
    </icz-table-toolbar-popup>
  </span>
}
