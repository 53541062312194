import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CirculationTaskType} from '|api/commons';

@Component({
  selector: 'icz-task-type-designator',
  templateUrl: './task-type-designator.component.html',
  styleUrls: ['./task-type-designator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskTypeDesignatorComponent {
  @Input({required: true})
  taskType!: CirculationTaskType;

  get isTakeoverTask() {
    const takeoverTaskTypes = [
      CirculationTaskType.TAKEOVER_BY_TARGET,
      CirculationTaskType.TAKEOVER_DUPLICATE_BY_TARGET,
      CirculationTaskType.TAKEOVER_REJECTED_BY_INITIATOR,
      CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_BY_TARGET,
      CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_REJECTED_BY_INITIATOR,
      // should also exist CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_DUPLICATE_BY_TARGET ???
    ];

    return takeoverTaskTypes.includes(this.taskType);
  }
}
