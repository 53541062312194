import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';
import {
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SImplicitlyStringifiable,
  ToastService
} from '../../notifications/toast.service';

export enum ConsignmentToastBulkType {
  CONSIGNMENT_BULK_HANDOVER_SUCCESS = 'CONSIGNMENT_BULK_HANDOVER_SUCCESS',
  CONSIGNMENT_BULK_HANDOVER_ERROR = 'CONSIGNMENT_BULK_HANDOVER_ERROR',
  CONSIGNMENT_BULK_TAKEOVER_SUCCESS = 'CONSIGNMENT_BULK_TAKEOVER_SUCCESS',
  CONSIGNMENT_BULK_TAKEOVER_ERROR = 'CONSIGNMENT_BULK_TAKEOVER_ERROR',
  CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_SUCCESS',
  CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_ERROR = 'CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_ERROR',
  CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS',
  CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_ERROR = 'CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_ERROR',
  CONSIGNMENT_BULK_MARK_AS_DISPATCHED_SUCCESS = 'CONSIGNMENT_BULK_MARK_AS_DISPATCHED_SUCCESS',
  CONSIGNMENT_BULK_MARK_AS_DISPATCHED_ERROR = 'CONSIGNMENT_BULK_MARK_AS_DISPATCHED_ERROR',
  CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_SUCCESS = 'CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_SUCCESS',
  CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_ERROR = 'CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_ERROR',
  CONSIGNMENT_BULK_REJECT_SUCCESS = 'CONSIGNMENT_BULK_REJECT_SUCCESS',
  CONSIGNMENT_BULK_REJECT_ERROR = 'CONSIGNMENT_BULK_REJECT_ERROR',
  CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_SUCCESS = 'CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_SUCCESS',
  CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_ERROR = 'CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_ERROR',
  CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_SUCCESS = 'CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_SUCCESS',
  CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_ERROR = 'CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_ERROR',
  CONSIGNMENT_BULK_CANCEL_SUCCESS = 'CONSIGNMENT_BULK_CANCEL_SUCCESS',
  CONSIGNMENT_BULK_CANCEL_ERROR = 'CONSIGNMENT_BULK_CANCEL_ERROR',
  CONSIGNMENT_BULK_DELETE_SUCCESS = 'CONSIGNMENT_BULK_DELETE_SUCCESS',
  CONSIGNMENT_BULK_DELETE_ERROR = 'CONSIGNMENT_BULK_DELETE_ERROR',
}

export enum ConsignmentToastType {
  CONSIGNMENT_SAVED = 'CONSIGNMENT_SAVED',
  CONSIGNMENT_UPDATED = 'CONSIGNMENT_UPDATED',
  CONSIGNMENT_BULK_ACTION_ACCEPT_SUCCESS = 'CONSIGNMENT_BULK_ACTION_ACCEPT_SUCCESS',
  CONSIGNMENT_BULK_ACTION_ACCEPT_ERROR = 'CONSIGNMENT_BULK_ACTION_ACCEPT_ERROR',

  CONSIGNMENT_HANDOVER_SUCCESS = 'CONSIGNMENT_HANDOVER_SUCCESS',
  CONSIGNMENT_HANDOVER_ERROR = 'CONSIGNMENT_HANDOVER_ERROR',
  CONSIGNMENT_TAKEOVER_SUCCESS = 'CONSIGNMENT_TAKEOVER_SUCCESS',
  CONSIGNMENT_TAKEOVER_ERROR = 'CONSIGNMENT_TAKEOVER_ERROR',
  CONSIGNMENT_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'CONSIGNMENT_PREPARE_FOR_DISTRIBUTION_SUCCESS',
  CONSIGNMENT_PREPARE_FOR_DISTRIBUTION_ERROR = 'CONSIGNMENT_PREPARE_FOR_DISTRIBUTION_ERROR',
  CONSIGNMENT_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'CONSIGNMENT_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS',
  CONSIGNMENT_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_ERROR = 'CONSIGNMENT_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_ERROR',
  CONSIGNMENT_MARK_AS_DISPATCHED_SUCCESS = 'CONSIGNMENT_MARK_AS_DISPATCHED_SUCCESS',
  CONSIGNMENT_MARK_AS_DISPATCHED_ERROR = 'CONSIGNMENT_MARK_AS_DISPATCHED_ERROR',
  CONSIGNMENT_MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE_SUCCESS = 'CONSIGNMENT_MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE_SUCCESS',
  CONSIGNMENT_MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE_ERROR = 'CONSIGNMENT_MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE_ERROR',
  CONSIGNMENT_REJECT_SUCCESS = 'CONSIGNMENT_REJECT_SUCCESS',
  CONSIGNMENT_REJECT_ERROR = 'CONSIGNMENT_REJECT_ERROR',
  CONSIGNMENT_CANCEL_SUCCESS = 'CONSIGNMENT_CANCEL_SUCCESS',
  CONSIGNMENT_CANCEL_ERROR = 'CONSIGNMENT_CANCEL_ERROR',
  CONSIGNMENT_WITHDRAW_FOR_CORRECTION_SUCCESS = 'CONSIGNMENT_WITHDRAW_FOR_CORRECTION_SUCCESS',
  CONSIGNMENT_WITHDRAW_FOR_CORRECTION_ERROR = 'CONSIGNMENT_WITHDRAW_FOR_CORRECTION_ERROR',
  CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION_SUCCESS = 'CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION_SUCCESS',
  CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION_ERROR = 'CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION_ERROR',
  CONSIGNMENT_DELETE_SUCCESS = 'CONSIGNMENT_DELETE_SUCCESS',
  CONSIGNMENT_DELETE_ERROR = 'CONSIGNMENT_DELETE_ERROR',

  CONSIGNMENT_MANUALLY_POSTED = 'CONSIGNMENT_MANUALLY_POSTED',
  CONSIGNMENT_MANUALLY_UNPOSTED = 'CONSIGNMENT_MANUALLY_UNPOSTED',
  BULK_POSTING_FORM_CREATED = 'BULK_POSTING_FORM_CREATED',
  BULK_POSTING_FORM_UPDATED = 'BULK_POSTING_FORM_UPDATED',
  BULK_POSTING_FORM_CANCELLED = 'BULK_POSTING_FORM_CANCELLED',
  BULK_POSTING_FORM_EXPORTED = 'BULK_POSTING_FORM_EXPORTED',
  BULK_POSTING_FORM_TEMPLATE_CREATED = 'BULK_POSTING_FORM_TEMPLATE_CREATED',
  PROOF_OF_DELIVERY_RECORDED = 'PROOF_OF_DELIVERY_RECORDED',
  OFFICE_DESK_POST_SUCCESS = 'OFFICE_DESK_POST_SUCCESS',
  OFFICE_DESK_POST_ERROR = 'OFFICE_DESK_POST_ERROR',
  OFFICE_DESK_UNPOST_SUCCESS = 'OFFICE_DESK_UNPOST_SUCCESS',
  OFFICE_DESK_UNPOST_ERROR = 'OFFICE_DESK_UNPOST_ERROR',
  CONSIGNMENT_DELIVERY_SERVICE_UPDATE_SUCCESS = 'CONSIGNMENT_DELIVERY_SERVICE_UPDATE_SUCCESS',
  CONSIGNMENTS_BULK_TAKEN_OVER_SUCCESS = 'CONSIGNMENTS_BULK_TAKEN_OVER_SUCCESS',
  CONSIGNMENTS_BULK_DISTRIBUTED_SUCCESS = 'CONSIGNMENTS_BULK_DISTRIBUTED_SUCCESS',
  PROOF_OF_DELIVERY_ADDED = 'PROOF_OF_DELIVERY_ADDED',
}

export const SSimpleConsignmentToastData = z.object({
  [InternalNotificationKey.CONSIGNMENT_UID]: z.optional(SImplicitlyStringifiable),
  [InternalNotificationKey.COUNT]: z.optional(SImplicitlyStringifiable),
});
export type SimpleConsignmentToastData = z.infer<typeof SSimpleConsignmentToastData>;

export const SConsignmentToastData = z.intersection(
  SSimpleConsignmentToastData,
  z.object({
    [InternalNotificationKey.DOCUMENT_ID]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.CONSIGNMENT_ID]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.CONSIGNMENT_NAME]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.DOCUMENT_SUBJECT]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.REASON]: z.optional(SImplicitlyStringifiable),
  })
);
export type ConsignmentToastData = z.infer<typeof SConsignmentToastData>;

export const SConsignmentErrorToastData = z.intersection(
  SSimpleConsignmentToastData,
  z.object({
    [InternalNotificationKey.ERROR_DESCRIPTION]: z.optional(SImplicitlyStringifiable),
  })
);
export type ConsignmentErrorToastData = z.infer<typeof SConsignmentErrorToastData>;

export const SBulkPostingFormToastData = z.object({
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
});
export type BulkPostingFormToastData = z.infer<typeof SBulkPostingFormToastData>;

export const SBulkConsignmentActionToastData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type BulkConsignmentActionToastData = z.infer<typeof SBulkConsignmentActionToastData>;

@Injectable({
  providedIn: 'root'
})
export class ConsignmentsToastService {

  private toastService = inject(ToastService);

  dispatchConsignmentBulkActionAccepted(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_BULK_ACTION_ACCEPT_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentBulkActionAcceptError(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.ERROR,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_BULK_ACTION_ACCEPT_ERROR),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentSimpleInfoToast(toastType: ConsignmentToastType, toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(toastType),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentSimpleErrorToast(toastType: ConsignmentToastType, toastData: ConsignmentErrorToastData) {
    this.toastService.dispatchToast({
      type: MessageType.ERROR,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(toastType),
          templateData: toastData,
          templateDataSchema: SConsignmentErrorToastData,
        }
      }
    });
  }

  dispatchConsignmentSaved(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_SAVED),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentUpdated(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_UPDATED),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentHandedOver(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_HANDOVER_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentTakenOver(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_TAKEOVER_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentManuallyPosted(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_MANUALLY_POSTED),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentManuallyUnposted(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_MANUALLY_UNPOSTED),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentTakenOverAndPreparedForDistribution(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentPreparedForDistribution(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_PREPARE_FOR_DISTRIBUTION_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentMarkedAsDispatched(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_MARK_AS_DISPATCHED_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentRejected(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_REJECT_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentWithdrawnFromDistribution(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentCancelled(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_CANCEL_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentWithdrawnForCorrection(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_WITHDRAW_FOR_CORRECTION_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentDeleted(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_DELETE_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentOutsideFilingOfficeDispatched(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_MARK_AS_DISPATCHED_OUTSIDE_FILING_OFFICE_SUCCESS),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchProofOfDeliveryRecorded(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.PROOF_OF_DELIVERY_RECORDED),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchProofOfDeliveryAdded(toastData: ConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.PROOF_OF_DELIVERY_ADDED),
          templateData: toastData,
          templateDataSchema: SConsignmentToastData,
        }
      }
    });
  }

  dispatchBulkPostingFormCreated(toastData: BulkPostingFormToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.BULK_POSTING_FORM_CREATED),
          templateData: toastData,
          templateDataSchema: SBulkPostingFormToastData,
        }
      }
    });
  }

  dispatchBulkPostingFormUpdated(toastData: BulkPostingFormToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.BULK_POSTING_FORM_UPDATED),
          templateData: toastData,
          templateDataSchema: SBulkPostingFormToastData,
        }
      }
    });
  }

  dispatchBulkPostingFormCancelled(toastData: BulkPostingFormToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.BULK_POSTING_FORM_CANCELLED),
          templateData: toastData,
        }
      }
    });
  }

  dispatchBulkPostingFormExported(toastData: BulkPostingFormToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.BULK_POSTING_FORM_EXPORTED),
          templateData: toastData,
          templateDataSchema: SBulkPostingFormToastData,
        }
      }
    });
  }

  dispatchBulkPostingFormTemplateCreated(toastData: BulkPostingFormToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.BULK_POSTING_FORM_TEMPLATE_CREATED),
          templateData: toastData,
          templateDataSchema: SBulkPostingFormToastData,
        }
      }
    });
  }

  dispatchConsignmentDeliveryServiceUpdated(toastData: SimpleConsignmentToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENT_DELIVERY_SERVICE_UPDATE_SUCCESS),
          templateData: toastData,
          templateDataSchema: SSimpleConsignmentToastData,
        }
      }
    });
  }

  dispatchConsignmentsBulkTakenOver(toastData: BulkConsignmentActionToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENTS_BULK_TAKEN_OVER_SUCCESS),
          templateData: toastData,
          templateDataSchema: SBulkConsignmentActionToastData,
        }
      }
    });
  }

  dispatchConsignmentsBulkDistributed(toastData: BulkConsignmentActionToastData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(ConsignmentToastType.CONSIGNMENTS_BULK_DISTRIBUTED_SUCCESS),
          templateData: toastData,
          templateDataSchema: SBulkConsignmentActionToastData,
        }
      }
    });
  }

}
