/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EmailNodeDto } from '../models/email-node-dto';
import { emailNodesOauthCreate } from '../fn/email-nodes-oauth/email-nodes-oauth-create';
import { EmailNodesOauthCreate$Params } from '../fn/email-nodes-oauth/email-nodes-oauth-create';
import { emailNodesOauthGetAll } from '../fn/email-nodes-oauth/email-nodes-oauth-get-all';
import { EmailNodesOauthGetAll$Params } from '../fn/email-nodes-oauth/email-nodes-oauth-get-all';
import { emailNodesOauthGetById } from '../fn/email-nodes-oauth/email-nodes-oauth-get-by-id';
import { EmailNodesOauthGetById$Params } from '../fn/email-nodes-oauth/email-nodes-oauth-get-by-id';
import { emailNodesOauthGetDefault } from '../fn/email-nodes-oauth/email-nodes-oauth-get-default';
import { EmailNodesOauthGetDefault$Params } from '../fn/email-nodes-oauth/email-nodes-oauth-get-default';
import { emailNodesOauthSearch } from '../fn/email-nodes-oauth/email-nodes-oauth-search';
import { EmailNodesOauthSearch$Params } from '../fn/email-nodes-oauth/email-nodes-oauth-search';
import { emailNodesOauthUpdate } from '../fn/email-nodes-oauth/email-nodes-oauth-update';
import { EmailNodesOauthUpdate$Params } from '../fn/email-nodes-oauth/email-nodes-oauth-update';
import { PageEmailNodeDto } from '../models/page-email-node-dto';


/**
 * API for management of email nodes.
 */
@Injectable({ providedIn: 'root' })
export class ApiEmailNodesOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `emailNodesOauthUpdate()` */
  static readonly EmailNodesOauthUpdatePath = '/oauth/email-nodes/update-node/{id}';

  /**
   * Updates email node by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNodesOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNodesOauthUpdate$Response(params: EmailNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNodeDto>> {
    return emailNodesOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates email node by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNodesOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNodesOauthUpdate(params: EmailNodesOauthUpdate$Params, context?: HttpContext): Observable<EmailNodeDto> {
    return this.emailNodesOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNodeDto>): EmailNodeDto => r.body)
    );
  }

  /** Path part for operation `emailNodesOauthCreate()` */
  static readonly EmailNodesOauthCreatePath = '/oauth/email-nodes/create-node';

  /**
   * Creates new email node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNodesOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNodesOauthCreate$Response(params: EmailNodesOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNodeDto>> {
    return emailNodesOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates new email node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNodesOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  emailNodesOauthCreate(params: EmailNodesOauthCreate$Params, context?: HttpContext): Observable<EmailNodeDto> {
    return this.emailNodesOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNodeDto>): EmailNodeDto => r.body)
    );
  }

  /** Path part for operation `emailNodesOauthGetById()` */
  static readonly EmailNodesOauthGetByIdPath = '/oauth/email-nodes/{id}';

  /**
   * Returns node by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNodesOauthGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthGetById$Response(params: EmailNodesOauthGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNodeDto>> {
    return emailNodesOauthGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns node by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNodesOauthGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthGetById(params: EmailNodesOauthGetById$Params, context?: HttpContext): Observable<EmailNodeDto> {
    return this.emailNodesOauthGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNodeDto>): EmailNodeDto => r.body)
    );
  }

  /** Path part for operation `emailNodesOauthSearch()` */
  static readonly EmailNodesOauthSearchPath = '/oauth/email-nodes/search-api';

  /**
   * Find all values in email nodes based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNodesOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthSearch$Response(params?: EmailNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageEmailNodeDto>> {
    return emailNodesOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all values in email nodes based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNodesOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthSearch(params?: EmailNodesOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageEmailNodeDto> {
    return this.emailNodesOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageEmailNodeDto>): PageEmailNodeDto => r.body)
    );
  }

  /** Path part for operation `emailNodesOauthGetAll()` */
  static readonly EmailNodesOauthGetAllPath = '/oauth/email-nodes/get-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNodesOauthGetAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthGetAll$Response(params?: EmailNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EmailNodeDto>>> {
    return emailNodesOauthGetAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNodesOauthGetAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthGetAll(params?: EmailNodesOauthGetAll$Params|null|undefined, context?: HttpContext): Observable<Array<EmailNodeDto>> {
    return this.emailNodesOauthGetAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EmailNodeDto>>): Array<EmailNodeDto> => r.body)
    );
  }

  /** Path part for operation `emailNodesOauthGetDefault()` */
  static readonly EmailNodesOauthGetDefaultPath = '/oauth/email-nodes/default-node';

  /**
   * Returns default node
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `emailNodesOauthGetDefault()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthGetDefault$Response(params?: EmailNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<EmailNodeDto>> {
    return emailNodesOauthGetDefault(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns default node
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `emailNodesOauthGetDefault$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  emailNodesOauthGetDefault(params?: EmailNodesOauthGetDefault$Params|null|undefined, context?: HttpContext): Observable<EmailNodeDto> {
    return this.emailNodesOauthGetDefault$Response(params, context).pipe(
      map((r: StrictHttpResponse<EmailNodeDto>): EmailNodeDto => r.body)
    );
  }

}
