import {HttpClient} from '@angular/common/http';
import {inject, Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VersionInfoService {

  private httpClient = inject(HttpClient);

  getVersionInfo() {
    const requestTimestamp = Number(new Date());
    return this.httpClient.get(
      `/assets/version.txt?ts=${requestTimestamp}`,
      {
        responseType: 'text',
        observe: 'body',
      }
    );
  }

}
