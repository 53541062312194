import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {arrayToOptions, enumValuesToArray} from '../../../core/services/data-mapping.utils';
import {DataBoxType} from '|api/commons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {finalize} from 'rxjs/operators';
import {ApiSadDataboxService} from '|api/sad';
import {
  AbstractSubjectVerifiableAttributeComponent
} from '../../shared-business-components/subjects/subject-single-attribute/abstract-subject-verifiable-attribute.component';

@Component({
	selector: 'icz-databox-with-type',
	templateUrl: './databox-with-type.component.html',
	styleUrls: ['./databox-with-type.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataboxWithTypeComponent extends AbstractSubjectVerifiableAttributeComponent implements OnInit {

  private apiSadDataboxService = inject(ApiSadDataboxService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  @Input() showDataboxType = false;
	@Output() blockingAction = new EventEmitter<boolean>();

	isLoading = false;

	dataBoxTypeOptions = arrayToOptions(enumValuesToArray(DataBoxType));

	dataBoxTypeControl(dataBoxControl: IczFormGroup): IczFormControl {
		return dataBoxControl!.get('type') as IczFormControl;
  }

  override ngOnInit() {
    super.ngOnInit();

    this.valueForm.get('id')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((dataBoxId: Nullable<string>) => {
      if (dataBoxId && this.valueForm!.get('id')!.valid) {
        this.isLoading = true;
        this.blockingAction.emit(this.isLoading);
        this.changeDetectorRef.detectChanges();

				this.apiSadDataboxService.sadDataboxGetDataBoxType({id: dataBoxId}).pipe(finalize(() => {
					this.isLoading = false;
          this.blockingAction.emit(this.isLoading);
          this.changeDetectorRef.detectChanges();
        })).subscribe(
          {
            next: type => {
              this.valueForm.get('type')!.setValue(type);
            },
            error: _ => {
              this.valueForm.get('type')!.setValue(null);
            }
          });
			}
		});
	}

}
