import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {OptionItemComponent} from './form-autocomplete/options-list/option-item/option-item.component';
import {OptionsListComponent} from './form-autocomplete/options-list/options-list.component';
import {FormChipInputComponent} from './form-chip-input/form-chip-input.component';
import {FormMoneyInputComponent} from './form-money-input/form-money-input.component';
import {IczFormCancelDirective} from './icz-form-cancel.directive';
import {IczFormSubmitDirective} from './icz-form-submit.directive';
import {IczFormContainerDirective, IczFormDirective,} from './icz-form.directive';
import {ItemSorterItemComponent} from './item-sorter/item-sorter-item/item-sorter-item.component';
import {ItemSorterComponent} from './item-sorter/item-sorter.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {ReadonlyFieldComponent} from './readonly-field/readonly-field.component';
import {ValidityWidgetComponent} from './validity-widget/validity-widget.component';
import {FormPopupTableSelectorValueAccessorDirective} from './form-popup-table-selector.value-accessor';
import {FormFieldValueAccessorDirective} from './form-field.value-accessor';
import {ValidationErrorsListComponent} from './validators/validation-errors-list/validation-errors-list.component';
import {RequiredFieldDirective} from './validators/icz-validators/required-field.directive';
import {MaxLengthDirective} from './validators/icz-validators/max-length.directive';
import {MinLengthDirective} from './validators/icz-validators/min-length.directive';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {RadioButtonValueAccessorDirective} from './radio-button/radio-button.value-accessor';
import {FormTreeSelectorComponent} from './form-tree-selector/form-tree-selector.component';
import {FormAutocompleteTreeListComponent} from './form-tree-selector/form-autocomplete-tree-list/form-autocomplete-tree-list.component';
import {FormPopupTableSelectorComponent} from './form-popup-table-selector/form-popup-table-selector.component';
import {FormAutocompleteComponent} from './form-autocomplete/form-autocomplete.component';
import {ComposableFormAutocompleteComponent} from './form-autocomplete/composable-form-autocomplete/composable-form-autocomplete.component';
import {FormAutocompleteChipComponent} from './form-autocomplete/form-autocomplete-chip/form-autocomplete-chip.component';
import {FormAutocompleteListComponent} from './form-autocomplete/form-autocomplete-list/form-autocomplete-list.component';
import {
  FormAutocompleteListTextItemComponent
} from './form-autocomplete/form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {InputMaskDirective} from './input-mask.directive';
import {ButtonToggleGroupComponent} from './button-toggle-group/button-toggle-group.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {EsslComponentComponent} from './digital-component/essl-component.component';
import {TreeViewComponent} from './tree-view/tree-view.component';
import {SubmitBarComponent} from './submit-bar/submit-bar.component';
import {FormHelpFieldComponent} from './form-help-field/form-help-field.component';
import {FormInlineSelectComponent} from './form-inline-select/form-inline-select.component';
import {
  CzechOrSlovakAddressComponent
} from '../shared-business-components/subjects/address/czech-or-slovak-address/czech-or-slovak-address.component';
import {AddressDetailsComponent} from './address-details/address-details.component';
import {DataBoxDetailsComponent} from './databox-details/data-box-details.component';
import {EmpowermentComponent} from './empowerment/empowerment.component';
import {ConsignmentPostingNumberComponent} from './consignment-posting-number/consignment-posting-number.component';
import {AddressPipe} from './address.pipe';
import {EssentialsModule} from '../essentials/essentials.module';
import {TimePickerComponent} from './time-picker/time-picker.component';
import {NumberCounterFieldComponent} from './time-picker/time-selector/number-counter-field/number-counter-field.component';
import {TimeSelectorComponent} from './time-picker/time-selector/time-selector.component';
import {TimeSelectorGridComponent} from './time-picker/time-selector-grid/time-selector-grid.component';
import {DataboxWithTypeComponent} from './databox-with-type/databox-with-type.component';
import {FormFieldComponent} from './form-field/form-field.component';
import {IczRadioGroupDirective} from './radio-button/radio-group.directive';

@NgModule({
	declarations: [
		FormFieldValueAccessorDirective,
		FormPopupTableSelectorValueAccessorDirective,
		ValidationErrorsListComponent,
		RequiredFieldDirective,
		MaxLengthDirective,
		MinLengthDirective,
		RadioButtonComponent,
		RadioButtonValueAccessorDirective,
		FormTreeSelectorComponent,
		FormAutocompleteTreeListComponent,
		FormPopupTableSelectorComponent,
		FormFieldComponent,
		FormAutocompleteComponent,
		ComposableFormAutocompleteComponent,
		FormAutocompleteChipComponent,
		FormAutocompleteListComponent,
		FormAutocompleteListTextItemComponent,
		InputMaskDirective,
		ButtonToggleGroupComponent,
		CheckboxComponent,
		DatePickerComponent,
		EsslComponentComponent,
		TreeViewComponent,
		SubmitBarComponent,
		FormHelpFieldComponent,
		OptionsListComponent,
		FormInlineSelectComponent,
		CzechOrSlovakAddressComponent,
		IczFormDirective,
		IczFormSubmitDirective,
		IczFormContainerDirective,
		PaginatorComponent,
		ReadonlyFieldComponent,
		ItemSorterComponent,
		ItemSorterItemComponent,
		FormChipInputComponent,
		OptionItemComponent,
		AddressDetailsComponent,
		DataBoxDetailsComponent,
		ValidityWidgetComponent,
		IczFormCancelDirective,
		EmpowermentComponent,
		FormMoneyInputComponent,
		ConsignmentPostingNumberComponent,
		AddressPipe,
		TimePickerComponent,
		NumberCounterFieldComponent,
		TimeSelectorComponent,
		TimeSelectorGridComponent,
		DataboxWithTypeComponent,
    IczRadioGroupDirective,
	],
	exports: [
		FormFieldValueAccessorDirective,
		FormPopupTableSelectorValueAccessorDirective,
		ValidationErrorsListComponent,
		RequiredFieldDirective,
		MaxLengthDirective,
		MinLengthDirective,
		RadioButtonComponent,
		RadioButtonValueAccessorDirective,
		FormTreeSelectorComponent,
		FormAutocompleteTreeListComponent,
		FormPopupTableSelectorComponent,
		FormFieldComponent,
		FormAutocompleteComponent,
		ComposableFormAutocompleteComponent,
		FormAutocompleteChipComponent,
		FormAutocompleteListComponent,
		FormAutocompleteListTextItemComponent,
		InputMaskDirective,
		ButtonToggleGroupComponent,
		CheckboxComponent,
		DatePickerComponent,
		EsslComponentComponent,
		TreeViewComponent,
		SubmitBarComponent,
		OptionItemComponent,
		FormHelpFieldComponent,
		OptionsListComponent,
		FormInlineSelectComponent,
		CzechOrSlovakAddressComponent,
		IczFormDirective,
		IczFormSubmitDirective,
		PaginatorComponent,
		IczFormContainerDirective,
		ReadonlyFieldComponent,
		ItemSorterComponent,
		FormChipInputComponent,
		AddressDetailsComponent,
		DataBoxDetailsComponent,
		ValidityWidgetComponent,
		IczFormCancelDirective,
		EmpowermentComponent,
		FormMoneyInputComponent,
		ConsignmentPostingNumberComponent,
		AddressPipe,
		TimePickerComponent,
		NumberCounterFieldComponent,
		TimeSelectorComponent,
		DataboxWithTypeComponent,
    IczRadioGroupDirective,
	],
	imports: [CommonModule, EssentialsModule],
})
export class FormElementsModule {
}
