/* eslint-disable @angular-eslint/directive-class-suffix */
import {AfterViewInit, Directive, TemplateRef} from '@angular/core';

export type TemplatePool<P> = {[K in keyof P]: P[K] extends TemplateRef<any> ? P[K] : never};

@Directive() // template-less abstract Components are Directives
export abstract class AbstractTemplateCollection implements AfterViewInit {
  // @ts-ignore
  pool: TemplatePool<this> = {};

  // Initialization occurs here and not in the constructor
  // because `this` must also contain properties of eventual subclass.
  ngAfterViewInit(): void {
    for (const propertyName of Object.keys(this)) {
      if (propertyName.toLowerCase().includes('template')) {
         // @ts-ignore
        this.pool[propertyName] = this[propertyName as keyof this];
      }
    }
  }
}
