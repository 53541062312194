import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {TableColumnsData} from '../table-columns-data';
import {TableConfig} from '../table.models';
import {IczTableDataSource} from '../table.datasource';
import {extendDefaultTableConfig} from '../table.component';
import {SearchParams} from '../../../services/search-api.service';
import {injectModalData, injectModalRef} from '../../../lib/modals';

export interface PopupSelectorTableDialogData<TColumnId extends string> {
  tableId: string;
  tableTitle: string;
  tableTitleTag: string;
  schema: TableColumnsData<TColumnId>;
  dataSource: IczTableDataSource<any>;
  tableConfig: Partial<TableConfig<TColumnId>>;
}

@Component({
  selector: 'icz-popup-selector-table-dialog',
  templateUrl: './popup-selector-table-dialog.component.html',
  styleUrls: ['./popup-selector-table-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupSelectorTableDialogComponent {

  protected router = inject(Router);
  protected modalRef = injectModalRef<unknown>();
  protected dialogData = injectModalData<PopupSelectorTableDialogData<string>>();

  tableConfig = extendDefaultTableConfig<string>({
    ...this.dialogData.tableConfig,
    autoPageSizeConfig: {tableHeight: 600},
    toolbarConfig: {
      ...(this.dialogData?.tableConfig?.toolbarConfig ?? {}),
      showFilter: false,
      showReload: false,
      autoOpenFilter: true,
      showToolbar: this.dialogData.tableTitle !== '',
    },
  });

  tableId: string = this.dialogData.tableId;
  tableTitle: string = this.dialogData.tableTitle;
  tableTitleTag: string = this.dialogData.tableTitleTag;
  columnsData: TableColumnsData<string> = this.dialogData.schema;

  dataSource: IczTableDataSource<unknown> = this.dialogData.dataSource;

  rowClickCallback(row: unknown) {
    this.modalRef.close(row);
  }

  loadPage(searchParams: SearchParams) {
    this.dataSource.loadPage(searchParams);
  }

}
