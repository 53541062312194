<div class="col h-full">

  <div
    class="row items-center toolbar"
    cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    >
    <icz-icon [svgIcon]="currentFileIcon$ | async"></icz-icon>
    <div class="icz-body-1">{{ currentFileName$ | async }}</div>

    <div class="grow"></div>

    <div class="row" cdkDragHandle cdkDragHandleDisabled>
      <div class="row-group">
        <icz-button background svgIcon="download" label="Stáhnout" (onAction)="downloadCurrentFile()"></icz-button>
        <icz-button testingFeature background svgIcon="printer" label="Tisk" (onAction)="printCurrentFile()"></icz-button>
      </div>

      @if (hasMoreAttachments) {
        <div class="row-group mx-8">
          <icz-button svgIcon="arrow_left" label="Předchozí" [disabled]="isPrevButtonDisabled" (onAction)="goToPrevFile()"></icz-button>
          <icz-button svgIcon="arrow_right" iconPosition="right" label="Další" [disabled]="isNextButtonDisabled" (onAction)="goToNextFile()"></icz-button>
        </div>
      }

      <icz-button [svgIcon]="isMaximized ? 'minim' : 'copy'" (onAction)="toggleMaximize()" size="small" background disableRipple></icz-button>
      <icz-button svgIcon="close" (onAction)="close()" size="small" background disableRipple></icz-button>
    </div>
  </div>

  <div class="content-container grow" [waiting]="loadingService.isLoading$(this) | async">
    @for (defect of currentAttachmentDefects; track defect) {
      <icz-alert
        class="m-16"
        [heading]="getCurrentAttachmentIssueSummary(defect)"
        severity="error"
        >
        <ng-container content>
          {{ defect.rejectionReasonText }}
          @if (defect.rejectionReasonAlternativeText) {
            <br>
            ---
            <br>
            {{ defect.rejectionReasonAlternativeText }}
          }
        </ng-container>
      </icz-alert>
    }

    @if (currentFile$ | async; as currentFile) {
      <icz-file-contents-viewer
        class="block w-full h-full overflow-scroll"
        [fileName]="currentFile.fileName"
        [fileContents]="currentFile.fileContents"
        [mimeType]="currentFile.mimeType"
        (downloadRequested)="downloadCurrentFile()"
      ></icz-file-contents-viewer>
    }
  </div>

</div>
