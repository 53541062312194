/* tslint:disable */
/* eslint-disable */

/**
 * Gender
 */
export enum GenderType {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}
