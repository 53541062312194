/* tslint:disable */
/* eslint-disable */

/**
 * Organizational unit type
 */
export enum OrganizationalUnitType {
  DIVISION = 'DIVISION',
  DEPARTMENT = 'DEPARTMENT',
  BRANCH = 'BRANCH',
  REFERAT = 'REFERAT',
  SECTION = 'SECTION',
  ELECTED_BODY = 'ELECTED_BODY'
}
