/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { keywordCreate } from '../fn/keyword/keyword-create';
import { KeywordCreate$Params } from '../fn/keyword/keyword-create';
import { KeywordDto } from '../models/keyword-dto';
import { keywordFindAll } from '../fn/keyword/keyword-find-all';
import { KeywordFindAll$Params } from '../fn/keyword/keyword-find-all';
import { keywordFindAllBySearchedTerm } from '../fn/keyword/keyword-find-all-by-searched-term';
import { KeywordFindAllBySearchedTerm$Params } from '../fn/keyword/keyword-find-all-by-searched-term';
import { keywordFindAllCurrentlyValid } from '../fn/keyword/keyword-find-all-currently-valid';
import { KeywordFindAllCurrentlyValid$Params } from '../fn/keyword/keyword-find-all-currently-valid';
import { keywordFindAllCurrentlyValidOrToBeValid } from '../fn/keyword/keyword-find-all-currently-valid-or-to-be-valid';
import { KeywordFindAllCurrentlyValidOrToBeValid$Params } from '../fn/keyword/keyword-find-all-currently-valid-or-to-be-valid';
import { keywordGetById } from '../fn/keyword/keyword-get-by-id';
import { KeywordGetById$Params } from '../fn/keyword/keyword-get-by-id';
import { keywordSearch } from '../fn/keyword/keyword-search';
import { KeywordSearch$Params } from '../fn/keyword/keyword-search';
import { keywordUpdate } from '../fn/keyword/keyword-update';
import { KeywordUpdate$Params } from '../fn/keyword/keyword-update';
import { PageKeywordDto } from '../models/page-keyword-dto';


/**
 * The keyword classifiers controller
 */
@Injectable({ providedIn: 'root' })
export class ApiKeywordService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `keywordGetById()` */
  static readonly KeywordGetByIdPath = '/codebook/classifier/keyword/{id}';

  /**
   * Returns keyword by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordGetById$Response(params: KeywordGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<KeywordDto>> {
    return keywordGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns keyword by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordGetById(params: KeywordGetById$Params, context?: HttpContext): Observable<KeywordDto> {
    return this.keywordGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<KeywordDto>): KeywordDto => r.body)
    );
  }

  /** Path part for operation `keywordUpdate()` */
  static readonly KeywordUpdatePath = '/codebook/classifier/keyword/{id}';

  /**
   * Updates a keyword
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordUpdate$Response(params: KeywordUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<KeywordDto>> {
    return keywordUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a keyword
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordUpdate(params: KeywordUpdate$Params, context?: HttpContext): Observable<KeywordDto> {
    return this.keywordUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<KeywordDto>): KeywordDto => r.body)
    );
  }

  /** Path part for operation `keywordCreate()` */
  static readonly KeywordCreatePath = '/codebook/classifier/keyword';

  /**
   * Creates a new keyword
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordCreate$Response(params: KeywordCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<KeywordDto>> {
    return keywordCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new keyword
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordCreate(params: KeywordCreate$Params, context?: HttpContext): Observable<KeywordDto> {
    return this.keywordCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<KeywordDto>): KeywordDto => r.body)
    );
  }

  /** Path part for operation `keywordFindAllBySearchedTerm()` */
  static readonly KeywordFindAllBySearchedTermPath = '/codebook/classifier/keyword/find-all-by-searched-term';

  /**
   * Finds all keywords by searched term
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordFindAllBySearchedTerm()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordFindAllBySearchedTerm$Response(params: KeywordFindAllBySearchedTerm$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<KeywordDto>>> {
    return keywordFindAllBySearchedTerm(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all keywords by searched term
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordFindAllBySearchedTerm$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  keywordFindAllBySearchedTerm(params: KeywordFindAllBySearchedTerm$Params, context?: HttpContext): Observable<Array<KeywordDto>> {
    return this.keywordFindAllBySearchedTerm$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KeywordDto>>): Array<KeywordDto> => r.body)
    );
  }

  /** Path part for operation `keywordFindAllCurrentlyValid()` */
  static readonly KeywordFindAllCurrentlyValidPath = '/codebook/classifier/keyword/valid';

  /**
   * Finds all currently valid keywords
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordFindAllCurrentlyValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordFindAllCurrentlyValid$Response(params?: KeywordFindAllCurrentlyValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<KeywordDto>>> {
    return keywordFindAllCurrentlyValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all currently valid keywords
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordFindAllCurrentlyValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordFindAllCurrentlyValid(params?: KeywordFindAllCurrentlyValid$Params|null|undefined, context?: HttpContext): Observable<Array<KeywordDto>> {
    return this.keywordFindAllCurrentlyValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KeywordDto>>): Array<KeywordDto> => r.body)
    );
  }

  /** Path part for operation `keywordFindAllCurrentlyValidOrToBeValid()` */
  static readonly KeywordFindAllCurrentlyValidOrToBeValidPath = '/codebook/classifier/keyword/valid-or-to-be-valid';

  /**
   * Finds all valid or to-be-valid keywords
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordFindAllCurrentlyValidOrToBeValid()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordFindAllCurrentlyValidOrToBeValid$Response(params?: KeywordFindAllCurrentlyValidOrToBeValid$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<KeywordDto>>> {
    return keywordFindAllCurrentlyValidOrToBeValid(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all valid or to-be-valid keywords
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordFindAllCurrentlyValidOrToBeValid$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordFindAllCurrentlyValidOrToBeValid(params?: KeywordFindAllCurrentlyValidOrToBeValid$Params|null|undefined, context?: HttpContext): Observable<Array<KeywordDto>> {
    return this.keywordFindAllCurrentlyValidOrToBeValid$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KeywordDto>>): Array<KeywordDto> => r.body)
    );
  }

  /** Path part for operation `keywordSearch()` */
  static readonly KeywordSearchPath = '/codebook/classifier/keyword/search-api';

  /**
   * Finds keywords by provided criteria
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordSearch$Response(params?: KeywordSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageKeywordDto>> {
    return keywordSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds keywords by provided criteria
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordSearch(params?: KeywordSearch$Params|null|undefined, context?: HttpContext): Observable<PageKeywordDto> {
    return this.keywordSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageKeywordDto>): PageKeywordDto => r.body)
    );
  }

  /** Path part for operation `keywordFindAll()` */
  static readonly KeywordFindAllPath = '/codebook/classifier/keyword/find-all';

  /**
   * Returns all keywords
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `keywordFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordFindAll$Response(params?: KeywordFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<KeywordDto>>> {
    return keywordFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns all keywords
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `keywordFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  keywordFindAll(params?: KeywordFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<KeywordDto>> {
    return this.keywordFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<KeywordDto>>): Array<KeywordDto> => r.body)
    );
  }

}
