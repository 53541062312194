/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { EmailNodeDto } from '../../models/email-node-dto';
import { EmailNodeUpdateDto } from '../../models/email-node-update-dto';

export interface EmailNodesOauthUpdate$Params {

/**
 * ID of entity to update
 */
  id: number;
      body: EmailNodeUpdateDto
}

export function emailNodesOauthUpdate(http: HttpClient, rootUrl: string, params: EmailNodesOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<EmailNodeDto>> {
  const rb = new RequestBuilder(rootUrl, emailNodesOauthUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<EmailNodeDto>;
    })
  );
}

emailNodesOauthUpdate.PATH = '/oauth/email-nodes/update-node/{id}';