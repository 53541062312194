import {Observable} from 'rxjs';
import {StorageUnitDto} from '|api/document';

export interface NewStorageUnitDialogResult {
  storageUnit: StorageUnitDto;
  redirectToPermissionSettings: boolean;
}

export abstract class AbstractStorageUnitDialogsManagerService {
  abstract openStorageUnitCreateDialog(): Observable<Nullable<NewStorageUnitDialogResult>>;
  abstract openStorageUnitInsertDialog(storageUnit: StorageUnitDto): Observable<Nullable<boolean>>;
}
