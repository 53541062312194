<icz-wizard
  [waiting]="loadingService.isLoading$(this) | async"
  [hideNextButton]="true"
  [showSubmitBar]="false"
>
  <ng-template
    [iczWizardStep]="ManualDeliveryResultByBarcodeDialogStep.SCAN_CONSIGNMENTS"
    label="Výběr zásilek"
    [isValid]="isDeliveryResultSpecificationStepValid()"
  >
    <div class="p-16">
      <icz-barcode-scanned-consignments-form
        [form]="form"
        [useCode2D]="false"
        [preselectedConsignments]="consignmentsToProcess"
        [showDeliveryServiceEdit]="false"
        notFoundMessage="Listinná zásilka s tímto kódem se nenachází v seznamu zásilek pro zapsání výsledku doručení"
        (consignmentCommandsChanged)="onConsignmentCommandsChanged($event)"
      ></icz-barcode-scanned-consignments-form>
    </div>


  </ng-template>
  <ng-template
    [iczWizardStep]="ManualDeliveryResultByBarcodeDialogStep.DELIVERY_RESULT_SPECIFICATION"
    label="Výsledek doručení"
    [isValid]="isDeliveryResultSpecificationStepValid()"
  >
    <div class="row gap-32">
      <div  class="grow">
        <icz-manual-delivery-result-form

          [form]="deliveryResultSpecificationFormGroup"
          [deliveryResultOptions]="deliveryResultOptions"
          [deliveryResults]="deliveryResults"
          [isValidAcknowledgementDate]="isValidAcknowledgementDate"
        ></icz-manual-delivery-result-form>
      </div>
      <div class="grow">
        <form [formGroup]="deliveryResultSpecificationFormGroup">
          <icz-section label="Připojení doručenky">
            <icz-radio-group>
              <icz-radio-button
                class="mb-8"
                formControlName="deliveryConfirmationForm"
                [value]="null"
                label="Bez připojení doručenky"
              ></icz-radio-button>
            </icz-radio-group>
          </icz-section>
        </form>
      </div>
    </div>
  </ng-template>
</icz-wizard>

<icz-form-buttons>
  <ng-container leftButtons>
    @if (currentDeliveryResultWizardStep === ManualDeliveryResultByBarcodeDialogStep.SCAN_CONSIGNMENTS) {
      <icz-button [disabled]="loadingService.isLoading(this)"
                  (onAction)="goToStep(ManualDeliveryResultByBarcodeDialogStep.DELIVERY_RESULT_SPECIFICATION)" primary
                  label="Přejít k zápisu doručení"
      ></icz-button>
    }
    @if (currentDeliveryResultWizardStep === ManualDeliveryResultByBarcodeDialogStep.DELIVERY_RESULT_SPECIFICATION) {
      <icz-button
        iczFormSubmit [disabled]="loadingService.isLoading(this)"
        (onAction)="submit()" primary label="Zapsat doručení"
      ></icz-button>
    }
  </ng-container>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
