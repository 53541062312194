<div [formGroup]="form">
  <div class="row grow">
    <icz-form-autocomplete
      [fieldDisabled]="true"
      [options]="retentionTriggerTypesOptions"
      class="grow"
      [value]="retentionTriggerTypeCode"
      label="Spouštěcí událost"
    ></icz-form-autocomplete>
    @if (showExternalRetentionYear) {
      @if (isRetentionTriggerTypeWithPeriod()) {
        <icz-form-field class="grow" label="Rok spouštecí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
        <icz-form-field class="grow" label="Rok kontroly spouštecí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
      } @else {
        <div class="grow-2"></div>
      }
    }
  </div>
  @if (isExternalRetentionTriggerTypeWithPeriod()) {
    <icz-form-autocomplete
      class="grow"
      [fieldDisabled]="true"
      [value]="externalRetentionTriggerIdsValue"
      [isMultiselect]="externalRetentionTriggerIds? externalRetentionTriggerIds.length > 1 : false"
      label="Externí spouštěcí událost"
      [options]="externalRetentionTriggerOptions$ | async"
    ></icz-form-autocomplete>
  }
</div>
