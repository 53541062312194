import {Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {Page} from '../../../../api';
import {EntityType} from '|api/commons';
import {
  ApiAuthorizationService,
  AuthorizedEntityType,
  DocumentAuthorizedOperation,
  FileAuthorizedOperation
} from '|api/core';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {
  GeneralAuthorizationRequest,
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation
} from '../permissions.utils';
import {IczTableDataSource} from '../../../table/table.datasource';
import {EntityWithAuthorization} from './entity-with-permissions.model';
import {COMPONENT_ENTITY_TYPES, DOCUMENT_ENTITY_TYPES} from '../../shared-document.utils';
import {SearchParams} from '../../../../services/search-api.service';
import {removeDuplicates} from '../../../../lib/utils';

const RELATED_ENTITY_ID_FIELD_NAMES: Partial<Record<AuthorizedEntityType, EntityIdReferencePropertyKey>> = {
  [AuthorizedEntityType.DOCUMENT]: 'documentId',
  [AuthorizedEntityType.FILE]: 'fileId',
};

const RELATED_AUTHORIZED_ENTITY_TYPES: Partial<Record<EntityType, AuthorizedEntityType>> = {
  [EntityType.DIGITAL_COMPONENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.PAPER_COMPONENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.MEDIUM_COMPONENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.PHYSICAL_ITEM_COMPONENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.DOCUMENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.OWN_DOCUMENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.RECEIVED_DOCUMENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.FILE]: AuthorizedEntityType.FILE,
};

const AUTHORIZED_ENTITY_TYPES: EntityType[] = [
  EntityType.DOCUMENT,
  EntityType.OWN_DOCUMENT,
  EntityType.RECEIVED_DOCUMENT,
  EntityType.FILE,
  // EsslComponents currently do not require explicit permission fetch in table views thus are not included
];

const ENTITY_TYPE_AUTHORIZED_ENTITY_TYPES: Partial<Record<EntityType, AuthorizedEntityType>> = {
  [EntityType.DOCUMENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.OWN_DOCUMENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.RECEIVED_DOCUMENT]: AuthorizedEntityType.DOCUMENT,
  [EntityType.FILE]: AuthorizedEntityType.FILE,
};

function getAuthorizationEntityIdKey(authorizedEntityType: Nullable<AuthorizedEntityType>): 'id'|EntityIdReferencePropertyKey {
  return authorizedEntityType ? (RELATED_ENTITY_ID_FIELD_NAMES[authorizedEntityType] ?? 'id') : 'id';
}

function mapAuthorizationResultsToEntities<T extends ExtendedEntity>(
  response: Page<T>,
  authorizationResults: GeneralAuthorizationResult[],
): Page<T & EntityWithAuthorization> {
  return {
    ...response,
    content: response.content.map(item => {
      let authorzedEntityType: Nullable<AuthorizedEntityType>;

      if (item.entityType === EntityType.CIRCULATION_ACTIVITY || item.entityType === EntityType.CIRCULATION_TASK) {
        const circulationEntityType = (item as unknown as CirculationActivityDto | CirculationTaskDto).circulationEntityType;

        if (
          DOCUMENT_ENTITY_TYPES.includes(circulationEntityType) ||
          COMPONENT_ENTITY_TYPES.includes(circulationEntityType)
        ) {
          authorzedEntityType = AuthorizedEntityType.DOCUMENT;
        }
        else if (circulationEntityType === EntityType.FILE) {
          authorzedEntityType = AuthorizedEntityType.FILE;
        }
      }
      else if (item.entityType && !AUTHORIZED_ENTITY_TYPES.includes(item.entityType)) {
        authorzedEntityType = RELATED_AUTHORIZED_ENTITY_TYPES[item.entityType];
      }

      const out = {
        ...item,
        authorization: authorizationResults.find(authResult => {
          return (
            authResult.authorizedEntityId === item[getAuthorizationEntityIdKey(authorzedEntityType)] &&
            authResult.authorizedEntityType === (authorzedEntityType ?? ENTITY_TYPE_AUTHORIZED_ENTITY_TYPES[item.entityType!])
          );
        }),
      };

      return out;
    })
  };
}

type EntityIdReferencePropertyKey = 'documentId' | 'fileId' | 'dispatchOfficeId' | 'filingOfficeId';

type EntityRelatedToPermissionedEntity = Partial<Record<EntityIdReferencePropertyKey, Nullable<number>>>;

// Interface for generic entity, which is needed to fetch permissions for that entity
type ExtendedEntity = {
  entityType?: Nullable<EntityType>;
  id: number;
} & EntityRelatedToPermissionedEntity;

type OperationsToAuthorizeByEntity = Partial<Record<AuthorizedEntityType, GeneralAuthorizedOperation[]>>;

export const DOCUMENT_FILE_OPERATIONS_FOR_TABLE_LISTS: OperationsToAuthorizeByEntity = {
  [AuthorizedEntityType.DOCUMENT]: [DocumentAuthorizedOperation.DOCUMENT_SHOW_PROFILE, DocumentAuthorizedOperation.DOCUMENT_SHOW_COMPONENTS],
  [AuthorizedEntityType.FILE]: [FileAuthorizedOperation.FILE_SHOW_PROFILE],
};

/*
  AbstractPermissionedEntityDatasource
  --------------------------------------------------
  1. Is used to extend the IczTableDataSource and add permissions to entities
  2. It is generic datasource, so it can be used with any entity, which has ID and entityType
  3. Datasource accepts searchObsFactory as argument, which is used to fetch entities
  4. After fetching entities, endpoint for permissions is called and permission for each entity is fetched
  5. Permissions are then mapped to entities with mapAuthorizationResultsToEntities function
  6. Added permissions are then used in tables to check, if user has permissions for toolbar actions or for certain use-cases
  in tables as of view detailed sidebar or click on link to detail
*/
export abstract class AbstractPermissionedEntityDatasource<T extends ExtendedEntity> extends IczTableDataSource<T & EntityWithAuthorization> {
  /**
   * @param searchObsFactory - an original factory accepted by SearchApiService to be decorated with permission fetch
   * @param apiAuthorizationService - merely a technical dependency
   *                          - specifies which permissioned entity type the foreign entities refer
   */
  constructor(
    searchObsFactory: (searchParams: SearchParams) => Observable<Page<T>>,
    apiAuthorizationService: ApiAuthorizationService,
    operationsToAuthorizeByEntity: OperationsToAuthorizeByEntity,
  ) {
    super(searchParams =>
      searchObsFactory(searchParams).pipe(
        switchMap(response => {
          // If table is empty, no fetching permissions is done and response is returned as observable and typecasted to EntityWithPermissions
          if (response.content.length === 0) {
            return of(response) as Observable<Page<T & EntityWithAuthorization & ExtendedEntity>>;
          }

          const authorizationRequests: GeneralAuthorizationRequest[] = response.content.map(e => {
            let authorizedEntityType: Nullable<AuthorizedEntityType>;
            let relatedAuthorizedEntityIdKey: 'id'|EntityIdReferencePropertyKey;

            if (e.entityType === EntityType.CIRCULATION_TASK || e.entityType === EntityType.CIRCULATION_ACTIVITY) {
              const circulationEntityType = (e as unknown as CirculationActivityDto | CirculationTaskDto).circulationEntityType;

              if (
                DOCUMENT_ENTITY_TYPES.includes(circulationEntityType) ||
                COMPONENT_ENTITY_TYPES.includes(circulationEntityType)
              ) {
                authorizedEntityType = AuthorizedEntityType.DOCUMENT;
              }
              else if (circulationEntityType === EntityType.FILE) {
                authorizedEntityType = AuthorizedEntityType.FILE;
              }

              relatedAuthorizedEntityIdKey = getAuthorizationEntityIdKey(authorizedEntityType);
            }
            else if (e.entityType && !AUTHORIZED_ENTITY_TYPES.includes(e.entityType)) {
              authorizedEntityType = RELATED_AUTHORIZED_ENTITY_TYPES[e.entityType];
              relatedAuthorizedEntityIdKey = getAuthorizationEntityIdKey(authorizedEntityType);
            }
            else {
              authorizedEntityType = ENTITY_TYPE_AUTHORIZED_ENTITY_TYPES[e.entityType!];
              relatedAuthorizedEntityIdKey = 'id';
            }

            return ({
              authorizedEntityType: authorizedEntityType!,
              authorizedEntityId: e[relatedAuthorizedEntityIdKey ?? 'id']!,
              operationsToAuthorize: operationsToAuthorizeByEntity[authorizedEntityType!] ?? [],
            });
          }).filter(
            authorizationRequest => authorizationRequest.operationsToAuthorize.length > 0
          ).filter(
            authorizationRequest => AUTHORIZED_ENTITY_TYPES.includes((authorizationRequest.authorizedEntityType!) as unknown as EntityType)
          );

          if (authorizationRequests.length) {
            return apiAuthorizationService.authorizationAuthorizeOperations({
              body: removeDuplicates(
                authorizationRequests,
                authRequest => `${authRequest.authorizedEntityType}${authRequest.authorizedEntityId}`
              ) as any // fine as GeneralAuthorizationRequest is assignable to authorization endpoint body
            }).pipe(
              map(authorizationResults => mapAuthorizationResultsToEntities(response, authorizationResults as GeneralAuthorizationResult[])),
            );
          }
          else {
            return of(response) as Observable<Page<T & EntityWithAuthorization & ExtendedEntity>>;
          }
        })
      )
    );
  }
}
