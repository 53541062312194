import {inject, Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {SKIP_ERROR_DIALOG_TOKEN} from './interceptor-config';
import {LoadingIndicatorService} from '../../components/essentials/loading-indicator.service';
import {CommunicationErrorDialogService, EsslErrorResponseDto} from '../services/communication-error-dialog.service';
import {Router} from '@angular/router';
import {createAbsoluteRoute} from '../routing/routing.helpers';
import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {IczModalService} from '../../services/icz-modal.service';

@Injectable()
export class ErrorHandlingInterceptor implements HttpInterceptor {

  private router = inject(Router);
  private modalService = inject(IczModalService);
  private loadingIndicatorService = inject(LoadingIndicatorService);
  private communicationErrorDialogService = inject(CommunicationErrorDialogService);

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((e: HttpErrorResponse | Error) => {
        this.loadingIndicatorService.endLoading(this);

        if (e instanceof HttpErrorResponse) {
          if (e.status === 0) {
            this.modalService.closeAllModals();
            this.router.navigateByUrl(createAbsoluteRoute(
              ApplicationRoute.NETWORK_UNAVAILABLE,
            ));
          }
          else {
            let errorBody$: Observable<EsslErrorResponseDto>;

            if (e.error instanceof Blob) {
              errorBody$ = from(e.error.text()).pipe(
                map(stringifiedError => JSON.parse(stringifiedError)),
              );
            }
            else {
              errorBody$ = of(e.error);
            }

            errorBody$.pipe(
              catchError(_ => of(e.error as EsslErrorResponseDto)),
              take(1),
            ).subscribe(parsedErrorBody => {
              const suppressErrorDialogFromFrontend = req.context.get(SKIP_ERROR_DIALOG_TOKEN);
              const suppressErrorDialogFromBackend = (
                parsedErrorBody && typeof parsedErrorBody === 'object' &&
                parsedErrorBody.suppressFrontendErrorReporting
              );
              if (!suppressErrorDialogFromFrontend && !suppressErrorDialogFromBackend) {
                this.communicationErrorDialogService.showCommunicationError({
                  ...e,
                  error: parsedErrorBody,
                });
              }
            });
          }
        }

        return throwError(() => e);
      }),
    );
  }

}
