<div cdkDrag class="header-cell-label" [cdkDragData]="{name: column?.id, columnIndex: columnIndex}" [iczTooltip]="column?.label">
  @if (column?.icon) {
    <icz-icon class="header-icon mr-4" [size]="column?.iconSize ?? 'small'" [svgIcon]="column?.icon!"></icz-icon>
  }
  @if (!column?.icon) {
    <div class="header-cell-text" [style.width]="textContentWidth">
      {{ column?.hideLabelInHeader ? ' ' : (column?.label! | translate) }}
    </div>
  }
</div>
