/* tslint:disable */
/* eslint-disable */

/**
 * Task resolution
 */
export enum CirculationTaskResolution {
  ASSIGNED = 'ASSIGNED',
  IN_PROGRESS = 'IN_PROGRESS',
  DONE_SUCCESS = 'DONE_SUCCESS',
  DONE_FAILURE = 'DONE_FAILURE',
  DONE_NEUTRAL = 'DONE_NEUTRAL'
}
