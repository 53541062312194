/* tslint:disable */
/* eslint-disable */

/**
 * Registry office transfer incident state
 */
export enum RegistryOfficeTransferIncidentState {
  IN_PROGRESS = 'IN_PROGRESS',
  SOLVED = 'SOLVED'
}
