/* tslint:disable */
/* eslint-disable */

/**
 * Type of ISSD authorization.
 */
export enum IssdAuthorizationType {
  L = 'L',
  PN = 'PN',
  FP = 'FP',
  L_FP = 'L_FP',
  FP_PN = 'FP_PN'
}
