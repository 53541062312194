import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {sub} from 'date-fns';
import {debounceTime, filter} from 'rxjs/operators';
import {DateFilterSelectionMode} from '../date-filter/date-filter.models';
import {IczOnChanges, IczSimpleChanges} from '../../../../../../utils/icz-on-changes';
import {IczFormControl, IczFormGroup} from '../../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../../form-elements/validators/icz-validators/icz-validators';
import {Option, parseDateFromLocalDateString} from '../../../../../../model';
import {getTodayMidnight} from '../../../../../../lib/utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum DatePickerShortcut {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_MONTH = 'LAST_MONTH',
  LAST_7_DAYS = 'LAST_7_DAYS',
  X_DAYS_AGO_FROM_TODAY = 'X_DAYS_AGO_FROM_TODAY',
  OWN_DATE_RANGE = 'OWN_DATE_RANGE',
}

export type DatePickerShortcutValue = Date | DateRange<Date>;

export type ShortcutClickedEvent = {
  shortcutType: Nullable<DatePickerShortcut>;
  shortcutValue: Nullable<DatePickerShortcutValue>;
};

export type OwnDateRangeParameters = {
  start: Nullable<string>;
  end: Nullable<string>;
};

export type XDaysAgoParameters = {
  days: string;
};

export type DatePickerShortcutParameters = OwnDateRangeParameters | XDaysAgoParameters;

function isDatepickerShortcutParametrized(shortcut: DatePickerShortcut) {
  return shortcut === DatePickerShortcut.X_DAYS_AGO_FROM_TODAY || shortcut === DatePickerShortcut.OWN_DATE_RANGE;
}


@Component({
  selector: 'icz-date-picker-shortcuts',
  templateUrl: './date-picker-shortcuts.component.html',
  styleUrls: ['./date-picker-shortcuts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerShortcutsComponent implements OnInit, IczOnChanges {

  private destroyRef = inject(DestroyRef);

  @Input({required: true}) selectionMode = DateFilterSelectionMode.DATE;

  @Input({required: true}) activeIntervalShortcut: Nullable<DatePickerShortcut>;

  @Output() shortcutClicked = new EventEmitter<ShortcutClickedEvent>();

  rangeForm = new IczFormGroup({
    start: new IczFormControl<Nullable<string>>(null, [IczValidators.localDate()]),
    end: new IczFormControl<Nullable<string>>(null, [IczValidators.localDate()]),
  });

  daysAgoFromTodayForm = new IczFormGroup({
    days: new IczFormControl<Nullable<string>>(null, [IczValidators.isInteger(), IczValidators.min(0), IczValidators.max(365 * 20 /* 20yrs */)]),
  });

  readonly DatePickerShortcut = DatePickerShortcut;
  readonly DateFilterSelectionMode = DateFilterSelectionMode;

  get shortcutListOptions(): Option<DatePickerShortcut>[] {
    if (this.selectionMode === DateFilterSelectionMode.DATE) {
      return this.dateShortcutsOptions;
    }
    else if (this.selectionMode === DateFilterSelectionMode.INTERVAL) {
      return this.intervalShortcutsOptions;
    }
    else {
      return [];
    }
  }

  private dateShortcutsOptions: Option<DatePickerShortcut>[] = [
    {
      label: 'Dnes',
      value: DatePickerShortcut.TODAY,
    },
    {
      label: 'Včera',
      value: DatePickerShortcut.YESTERDAY,
    },
  ];

  private intervalShortcutsOptions: Option<DatePickerShortcut>[] = [
    {
      label: 'Poslední měsíc',
      value: DatePickerShortcut.LAST_MONTH,
    },
    {
      label: 'Posledních 7 dní',
      value: DatePickerShortcut.LAST_7_DAYS,
    },
    {
      label: 'X dní zpět ode dneška',
      value: DatePickerShortcut.X_DAYS_AGO_FROM_TODAY,
    },
    {
      label: 'Zadat vlastní období',
      value: DatePickerShortcut.OWN_DATE_RANGE,
    },
  ];

  ngOnInit() {
    this.daysAgoFromTodayForm.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(_ => this.activeIntervalShortcut === DatePickerShortcut.X_DAYS_AGO_FROM_TODAY),
      filter(_ => this.daysAgoFromTodayForm.valid),
      debounceTime(300),
    ).subscribe(shortcutParams => {
      this.shortcutClicked.emit({
        shortcutType: this.activeIntervalShortcut!,
        shortcutValue: this.getShortcutTransformedDate(
          this.activeIntervalShortcut!,
          {
            days: shortcutParams.days!,
          }
        ),
      });
      this.rangeForm.reset();
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.selectionMode) {
      this.daysAgoFromTodayForm.reset();
      this.rangeForm.reset();
    }
  }

  getShortcutTransformedDate(shortcut: DatePickerShortcut, shortcutParameters?: DatePickerShortcutParameters): Nullable<DatePickerShortcutValue> {
    const todayMidnight = getTodayMidnight();

    switch (shortcut) {
      case DatePickerShortcut.TODAY:
        return new Date();
      case DatePickerShortcut.YESTERDAY:
        return sub(new Date(), {days: 1});
      case DatePickerShortcut.LAST_MONTH:
        return new DateRange(sub(todayMidnight, {months: 1}), todayMidnight);
      case DatePickerShortcut.LAST_7_DAYS:
        return new DateRange(sub(todayMidnight, {days: 7}), todayMidnight);
      case DatePickerShortcut.X_DAYS_AGO_FROM_TODAY:
        const numberOfDays = Number((shortcutParameters as XDaysAgoParameters).days);

        if (numberOfDays) {
          return new DateRange(
            sub(todayMidnight, {days: numberOfDays}),
            todayMidnight
          );
        }
        else {
          return null;
        }
      case DatePickerShortcut.OWN_DATE_RANGE:
        const start: Nullable<Date> = parseDateFromLocalDateString((shortcutParameters as OwnDateRangeParameters).start ?? '');
        const end: Nullable<Date> = parseDateFromLocalDateString((shortcutParameters as OwnDateRangeParameters).end ?? '');

        return new DateRange(start ?? todayMidnight, end ?? todayMidnight);
      default:
        return null;
    }
  }

  onShortcutClick(shortcut: DatePickerShortcut) {
    if (isDatepickerShortcutParametrized(shortcut)) {
      this.activeIntervalShortcut = shortcut;
    }
    else {
      const date = this.getShortcutTransformedDate(shortcut);

      this.activeIntervalShortcut = null;
      this.shortcutClicked.emit({
        shortcutType: shortcut,
        shortcutValue: date,
      });
    }
  }

  applyOwnDateRange() {
    const shortcutParams = this.rangeForm.value;

    if (this.rangeForm.valid && shortcutParams.start && shortcutParams.end) {
      this.shortcutClicked.emit({
        shortcutType: this.activeIntervalShortcut!,
        shortcutValue: this.getShortcutTransformedDate(
          this.activeIntervalShortcut!,
          {
            start: shortcutParams.start!,
            end: shortcutParams.end!,
          }
        ),
      });
      this.daysAgoFromTodayForm.reset();
    }
  }

  handleKeyboardShortcuts($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.applyOwnDateRange();
      $event.preventDefault();
      $event.stopPropagation();
    }
  }

}
