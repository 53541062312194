import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {distinctUntilChanged} from 'rxjs/operators';
import {ActiveModuleService} from '../../../core/services/active-module.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


@Component({
  template: `
    <router-outlet class="router-outlet-flex-container"></router-outlet>
  `,
})
export class ThemeOutletComponent implements OnInit {

  private activeModuleService = inject(ActiveModuleService);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.activeModuleService.activeModule$.pipe(
      distinctUntilChanged(), // optimization - HTML class switches should be minimized
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(
      activeModule => {
        document.body.className = `module-${activeModule}`;
      },
    );
  }

}
