/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { IszrAccessCertificateCreateOrUpdateDto } from '../../models/iszr-access-certificate-create-or-update-dto';
import { IszrAccessCertificateDto } from '../../models/iszr-access-certificate-dto';

export interface IszrCertificateStoreIszrCertificate$Params {
      body?: {
'certificate': Blob;
'iszrAccessCertificateUpdateDto': IszrAccessCertificateCreateOrUpdateDto;
}|null|undefined
}

export function iszrCertificateStoreIszrCertificate(http: HttpClient, rootUrl: string, params?: IszrCertificateStoreIszrCertificate$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<IszrAccessCertificateDto>> {
  const rb = new RequestBuilder(rootUrl, iszrCertificateStoreIszrCertificate.PATH, 'put');
  if (params) {
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<IszrAccessCertificateDto>;
    })
  );
}

iszrCertificateStoreIszrCertificate.PATH = '/oauth/iszr-access-certificate/';