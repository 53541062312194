/* tslint:disable */
/* eslint-disable */

/**
 * Batch state
 */
export enum ErmsReceivedBatchAuditState {
  RECEIVED = 'RECEIVED',
  REJECTED = 'REJECTED',
  REJECTED_FOR_SERVER_ERROR = 'REJECTED_FOR_SERVER_ERROR',
  SKIPPED = 'SKIPPED',
  TO_PROCESS = 'TO_PROCESS',
  BEING_PROCESSED = 'BEING_PROCESSED',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED'
}
