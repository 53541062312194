@if (loadingIndicatorService.isLoading(this, loaderIdIsdsFind)) {
  <icz-alert class="mb-16"
    heading="Probíhá ověření všech dat o subjektu ze systému Datových schránek" severity="info">
  </icz-alert>
}
<div class="row mb-16 none-selected-link">
  <icz-label label="Nenašli jste co jste hledali?" ></icz-label>
  <span class="underline cursor-pointer" (click)="closeAsCreateNew()">{{'Založit subjekt' | translate}}</span>
</div>

<icz-subjects-table
  [dataSource]="dataSource"
  [columnSet]="SubjectTableColumnSet.RESULT_SET_FOR_LINKING"
  (actionExecuted)="actionExecuted($event)"
  [waiting]="loadingIndicatorService.isLoading(this, loaderIdIsdsFind)"
  tableTitle="Nalezené subjekty"
  class="w-full"
  tableId="select-subject-as-sender"
  >

</icz-subjects-table>
