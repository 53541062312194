import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {InterpolationContext} from '../../../../../lib/model';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup, TextLength} from '../../../../form-elements/icz-form-controls';
import {IczValidators} from '../../../../form-elements/validators/icz-validators/icz-validators';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {injectModalData, injectModalRef} from '../../../../../lib/modals';


type WorkflowTextResponseDialogResponseHandler = (response: string) => Observable<any>;

export interface WorkflowTextResponseDialogParams {
  infoAlertTemplate?: string;
  infoAlertTemplateContext?: InterpolationContext;
  responseFieldLabel: string;
  responseFieldDefaultValue?: string;
  primaryButtonLabel: string;
  responseHandlerFn: WorkflowTextResponseDialogResponseHandler;
}

export interface WorkflowTextResponseDialogResult {
  response: string;
}


@Component({
  selector: 'icz-workflow-text-response-dialog',
  templateUrl: './workflow-text-response-dialog.component.html',
  styleUrls: ['./workflow-text-response-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class WorkflowTextResponseDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<WorkflowTextResponseDialogResult | boolean>();
  private destroyRef = inject(DestroyRef);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected dialogParams = injectModalData<WorkflowTextResponseDialogParams>();

  formGroupsToCheck!: string[];

  form = new IczFormGroup({
    response: new IczFormControl<Nullable<string>>(null, [IczValidators.required()], [], TextLength.LONG),
  });

  // defaults
  infoAlertTemplate: Nullable<string> = '';
  infoAlertTemplateContext: Nullable<InterpolationContext> = {};
  responseFieldLabel = 'Odpověď';
  primaryButtonLabel = 'Odpovědět';
  responseHandlerFn: WorkflowTextResponseDialogResponseHandler = _ => of(null);

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.infoAlertTemplate = this.dialogParams.infoAlertTemplate;
    this.infoAlertTemplateContext = this.dialogParams.infoAlertTemplateContext;
    this.responseFieldLabel = this.dialogParams.responseFieldLabel;
    this.primaryButtonLabel = this.dialogParams.primaryButtonLabel;
    this.responseHandlerFn = this.dialogParams.responseHandlerFn;

    if (this.dialogParams.responseFieldDefaultValue) {
      this.form.patchValue({
        response: this.dialogParams.responseFieldDefaultValue,
      });
      this.form.markAsPristine();
    }
  }

  respond() {
    const response = this.form.get('response')!.value!;

    this.loadingService.doLoading(
      this.responseHandlerFn(response),
      this
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(
      () => {
        this.modalRef.close({
          response
        });
      },
    );
  }

  closeModal() {
    this.modalRef.close(false);
  }

}
