import {inject, Injectable} from '@angular/core';
import {WebSocketNotificationsService} from '../../../components/notifications/web-socket-notifications.service';
import {ToastService} from '../../../components/notifications/toast.service';

@Injectable({
  providedIn: 'root'
})
export class SharedFolderNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  initialize() {
    // todo(rb) fill later
  }

}
