<div class="row">
  @if (loadingService.isLoading(this)) {
    <div class="items-center justify-center h-full col">
      <icz-spinner [diameter]="50"></icz-spinner>
      <div class="row">
        <icz-label class="font-bold" label="Kontroluje se správnost komponent"></icz-label>
      </div>
    </div>
  }
  @if (parentDocumentError) {
    <icz-alert
      severity="error" class="mb-32"
      [heading]="parentDocumentError.code | interpolateBackendError:parentDocumentError.parameters"
    ></icz-alert>
  }

  @if (numberOfErrors > 0) {
    <div class="col grow gap-32">
      <icz-alert
        severity="error"
        [heading]="data.isMark? 'fe.ui.alert.heading.DIGITAL_COMPONENTS_NOT_VALID_FOR_MARK' : 'fe.ui.alert.heading.DIGITAL_COMPONENTS_NOT_VALID_FOR_EXTEND'"
        [textContext]="headingContext"
      ></icz-alert>
      <div class="row">
        <icz-label class="font-bold" [label]="data.isMark? 'Komponenty, které nelze opečetit' : 'Komponenty, na které nelze přidat časové razítko'"></icz-label>
      </div>
      <div class="overflow-y-auto digital-component-list">
        @for (errorComponent of validationErrorComponents; track errorComponent.id) {
          <div class="items-start mb-16 row">
            <icz-alert severity="error" [heading]="errorComponent.fileName!" [label]="errorComponent.errorCode!" [textContext]="errorComponent.errorParameters" alertMode="compact"></icz-alert>
          </div>
        }
      </div>
    </div>
  }
</div>

<icz-form-buttons>
  @if (canContinue) {
    <icz-button leftButtons iczFormSubmit
      (onAction)="continue()"
      primary label="Pokračovat s vyhovujícími komponentami"
    ></icz-button>
  }
  <icz-button rightButtons (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
