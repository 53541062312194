<a
  [hidden]="!document?.refNumberHistory?.length"
  class="icz-body-1 mr-24"
  iczLinkWithoutHref
  cdkOverlayOrigin
  #registrationTimelineLink="cdkOverlayOrigin"
  (click)="toggleRegistrationTimeline($event)"
  >
  {{ 'Přeevidováno - původní číslo jednací' | translate }}
</a>

<icz-popover
  [isOpen]="isRegistrationTimelineOpen"
  [overlayOrigin]="registrationTimelineLink"
  (onClose)="isRegistrationTimelineOpen = false"
  >
  @if (isRegistrationTimelineOpen) {
    <icz-document-registration-timeline
      [timelineItems]="document?.refNumberHistory ?? []"
    ></icz-document-registration-timeline>
  }
</icz-popover>
