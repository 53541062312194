@if(form) {
  <div
    class="col"
    [waiting]="(loadingService.isLoading$(this)! | async) || (loadingService.isLoading$(consignmentsTable)! | async)"
  >
    <form [formGroup]="form">
      <div class="row">
        <div class="row grow">
          <icz-form-field
            #codeBcrField
            iczAutoFocus
            class="grow" formControlName="codeBcr"
            label="Načtěte nebo vepište kód"
            [showValidationStatus]="false"
            (keydown.enter.prevent)="bcrCodeFieldEnterPressed()"
          ></icz-form-field>
          @if (useCode2D) {
            <icz-form-field
              #code2DField
              class="grow" formControlName="code2D"
              label="Načtěte nebo vepište 2D známku"
              [showValidationStatus]="false"
              (keydown.enter.prevent)="tryAddScannedConsignmentToList()"
            ></icz-form-field>
          }
          <!-- todo(rb) uncomment that after CDZ scanning implemented -->
          <!--<icz-form-field
          class="grow" formControlName="codeCdz"
          label="Načtěte nebo vepište ČDZ"
        ></icz-form-field>-->
        </div>
        <icz-button class="add-scanned-consignment-button"
                    (onAction)="tryAddScannedConsignmentToList()"
                    primary label="Přidat do seznamu"
        ></icz-button>
      </div>
      <div
        [class.opacity-100]="consignmentAlreadyInList || consignmentNotFound"
        class="row validation-error icz-body-1 mt-12 opacity-0"
      >
        <icz-icon svgIcon="inline_error" size="small"></icz-icon>
        @if (consignmentAlreadyInList) {
          {{ 'Tato zásilka již byla přidána do seznamu' | translate }}
        }
        @if (consignmentNotFound) {
          {{notFoundMessage | translate }}
        }
      </div>
    </form>
    <icz-barcode-scanned-consignments-table
      #consignmentsTable
      class="block mt-16"
      [consignmentCommands]="consignmentsToProcess"
      [showDeliveryServiceEdit]="showDeliveryServiceEdit"
      (consignmentRemovalRequested)="removeConsignment($event)"
      (consignmentDeliveryServiceEditRequested)="consignmentDeliveryServiceEditRequested.emit($event)"
    ></icz-barcode-scanned-consignments-table>
  </div>
}
