@if (showFileDragAndDrop) {
  <div class="file-drag-area"
    (dragover)="onFileDragover($event)"
    (dragleave)="onFileDragLeave($event)"
    (drop)="onFileDrop($event)"
    [ngClass]="{'on-file-drag': isFileDrag}">
    {{ dndHelpText }}
    <div class="px-8 pt-16">
      <ng-container *ngTemplateOutlet="fileList"></ng-container>
    </div>
  </div>
}
@if (!showFileDragAndDrop) {
  <div>
    <ng-container *ngTemplateOutlet="fileList"></ng-container>
  </div>
}
<ng-template #fileList>
  @if (showFileUploadButton) {
    <icz-button label="Nahrát soubor z počítače" (onAction)="openUploadDialog()" class="inline-block"></icz-button>
  }
  @for (item of files.controls; track item) {
    <div class="col gap-16 digital-component"
      [waiting]="loadingIndicatorService.isLoading(this, $index)"
      >
      @if (item.get('file')!.value; as uploadListItem) {
        <div class="gap-16">
          <div class="row">
            @if (showSpinner(item) || loadingIndicatorService.isLoading(this, $index)) {
              <icz-spinner [diameter]="24"></icz-spinner>
            }
            <ng-content select="[beforeFileName]"></ng-content>
            @if (withFileIcon && showFileIcon(item)) {
              <icz-icon [svgIcon]="getFileIcon(uploadListItem)"></icz-icon>
            }
            @if (showErrorIcon(item)) {
              <icz-icon svgIcon="error"></icz-icon>
            }
            <icz-label class="font-bold overflow-hidden" dontTranslate [label]="getFileName(uploadListItem)" [iczTooltip]="getFileName(uploadListItem)"></icz-label>
            @if (showFileSize) {
              <icz-label class="filesize" dontTranslate [label]="getFileSize(uploadListItem) | fileSize"></icz-label>
            }
            <ng-content select="[afterFileName]"></ng-content>
            <icz-button background size="small" svgIcon="delete" (onAction)="deleteDigitalComponent($index)" [disabled]="item.get('file')!.disabled"></icz-button>
          </div>
          @for (uploadException of getFileUploadExceptions($index); track uploadException) {
            <div>
              <div class="icz-error file-upload-error mt-4">{{ formatUploadException(uploadException) }}</div>
            </div>
          }
        </div>
      }
      <!-- additional content for file card customization -->
      <ng-content select="[additionalContent]"></ng-content>
    </div>
  }
</ng-template>

<input type="file"
  #fileUpload
  (change)="setFiles()"
  [multiple]="isMultiUpload"
  [accept]="fileInputAccept"
  data-cy="doc-digital-component-file-input"
  class="hidden">
