/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { PropertyValueDto } from '../../models/property-value-dto';
import { PropertyValueUpdateDto } from '../../models/property-value-update-dto';

export interface PropertyValueUpdate$Params {

/**
 * Id of to be updated property value
 */
  id: number;
      body: PropertyValueUpdateDto
}

export function propertyValueUpdate(http: HttpClient, rootUrl: string, params: PropertyValueUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<PropertyValueDto>> {
  const rb = new RequestBuilder(rootUrl, propertyValueUpdate.PATH, 'put');
  if (params) {
    rb.path('id', params.id, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<PropertyValueDto>;
    })
  );
}

propertyValueUpdate.PATH = '/property-value/{id}';