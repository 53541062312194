/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { EpdzOrganizationConfig } from '../models/epdz-organization-config';
import { epdzPropertiesGetEpdzProperties } from '../fn/epdz-properties/epdz-properties-get-epdz-properties';
import { EpdzPropertiesGetEpdzProperties$Params } from '../fn/epdz-properties/epdz-properties-get-epdz-properties';


/**
 * API to retrieve EPDZ configuration.
 */
@Injectable({ providedIn: 'root' })
export class ApiEpdzPropertiesService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `epdzPropertiesGetEpdzProperties()` */
  static readonly EpdzPropertiesGetEpdzPropertiesPath = '/epdz-properties/';

  /**
   * Return configuration for EPDZ.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `epdzPropertiesGetEpdzProperties()` instead.
   *
   * This method doesn't expect any request body.
   */
  epdzPropertiesGetEpdzProperties$Response(params?: EpdzPropertiesGetEpdzProperties$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<EpdzOrganizationConfig>>> {
    return epdzPropertiesGetEpdzProperties(this.http, this.rootUrl, params, context);
  }

  /**
   * Return configuration for EPDZ.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `epdzPropertiesGetEpdzProperties$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  epdzPropertiesGetEpdzProperties(params?: EpdzPropertiesGetEpdzProperties$Params|null|undefined, context?: HttpContext): Observable<Array<EpdzOrganizationConfig>> {
    return this.epdzPropertiesGetEpdzProperties$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<EpdzOrganizationConfig>>): Array<EpdzOrganizationConfig> => r.body)
    );
  }

}
