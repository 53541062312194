<ng-container [formGroup]="form">
  <icz-form-tree-selector
    [formControlName]="controlName"
    [label]="label"
    [options]="orgStructureOptions$ | async"
    [isMultiselect]="isMultiselect"
    [selectionStrategy]="TreeSelectorSelectionStrategy.HYBRID"
    [customSelectedOptionTemplate]="orgStructureChipContent"
    [placeholder]="placeholder"
    [autoSizeMax]="autoSizeMax"
    [clearable]="clearable"
    [fieldDisabled]="fieldDisabled"
    (valueChangeWithOriginIds)="emitValueChangeWithOriginIds($event)"
  ></icz-form-tree-selector>

  <ng-template #orgStructureChipContent let-context>
    <div class="row">
      @if (context.option.originId === 'ou') {
        <icz-icon size="small" svgIcon="hierarchy" class="h-16"></icz-icon>
      }
      <div class="grow">
        {{ context.option.label | translate }}
      </div>
    </div>
  </ng-template>
</ng-container>
