<form [waiting]="loadingService.isLoading(this)" iczAutoFocus class="grow" [formGroup]="form">
  @if (infoAlertTemplate) {
    <icz-alert class="icz-body-1 mb-16" [heading]="infoAlertTemplate" [textContext]="infoAlertTemplateContext"></icz-alert>
  }
  <icz-form-field iczAutoFocus formControlName="response" [label]="responseFieldLabel" [autoSizeMax]="10"></icz-form-field>
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="respond()" primary [label]="primaryButtonLabel"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
