@if (idtStatus === IdtConnectionState.INITIATING) {
  <div class="row">
    <icz-icon svgIcon="info"></icz-icon>
    <icz-label label="Načítání IDT2"></icz-label>
  </div>
}

@if (idtStatus === IdtConnectionState.INCOMPATIBLE_BROWSER) {
  <icz-alert
    class="mt-16"
    heading="Váš prohlížeč není kompatibilní s IDT2"
    severity="error"
    >
    <ng-container content>
      <icz-label label="IDT2 je momentálně podporováno pouze v prohlížečích Chrome, Firefox a Microsoft Edge."></icz-label>
      @if (functionUnavailableText) {
        <icz-label [label]="functionUnavailableText"></icz-label>
      }
    </ng-container>
  </icz-alert>
}

@if (idtStatus === IdtConnectionState.INCOMPATIBLE_VERSION) {
  <icz-alert
    class="mt-16"
    heading="Máte zastaralou verzi IDT2"
    severity="warning"
    >
    @if (functionUnavailableText) {
      <icz-label content [label]="functionUnavailableText" [hasNormalWhitespace]="true"></icz-label>
    }
  </icz-alert>
}

@if (idtStatus === IdtConnectionState.NOT_INSTALLED) {
  <icz-alert class="mt-16" heading="fe.ui.alert.heading.IDT_NOT_INSTALLED" severity="error"></icz-alert>
}
