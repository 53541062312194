import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationCountService {
  private _notificationCount$ = new BehaviorSubject<Nullable<number>>(null);
  notificationCount$ = this._notificationCount$.asObservable();

  updateNotificationCount(newCount: number) {
    this._notificationCount$.next(newCount);
  }
}
