import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Page} from '../api';
import {StorageUnitDto} from '|api/document';
import {SearchParams, TypedSearchRecordDto, unwrapSearchContent} from './search-api.service';
import {SearchApiWithElasticService, UnitViewSearchConfig} from './search-api-with-elastic.service';

@Injectable({
  providedIn: 'root'
})
export class StorageUnitSearchService extends SearchApiWithElasticService {

  findStorageUnits(searchParams: Partial<SearchParams>, unitView: UnitViewSearchConfig): Observable<Page<StorageUnitDto>> {
    if (unitView.isUnitView) {
      return this.searchApi<Page<TypedSearchRecordDto<StorageUnitDto>>>(searchParams, this.getElasticViewByOrgUnitUri('storage-unit', unitView.orgUnitId, searchParams))
        .pipe(unwrapSearchContent());
    }
    else {
      return this.searchApi<Page<TypedSearchRecordDto<StorageUnitDto>>>(searchParams, this.getElasticViewUri('storage-unit', searchParams))
        .pipe(unwrapSearchContent());
    }
  }

}
