import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AttributeComponentType} from '../../model/subjects.model';
import {getUiKeyForSubjectCreateAttribute} from '../../../../services/subjects.service';
import {Option} from '../../../../model';
import {IczDateValidators} from '../../../form-elements/validators/icz-validators/icz-date-validators';
import {AbstractSubjectVerifiableAttributeComponent} from './abstract-subject-verifiable-attribute.component';
import {SubjectAttributeType} from '../../model/subject-attribute-type';


@Component({
  selector: 'icz-subject-single-attribute',
  templateUrl: './subject-single-attribute.component.html',
  styleUrls: ['./subject-single-attribute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectSingleAttributeComponent extends AbstractSubjectVerifiableAttributeComponent implements OnInit {

  @Input() componentType: AttributeComponentType = AttributeComponentType.FORM_FIELD;
  @Input() options!: Nullable<Option[]>;

  readonly birthDateValidator = IczDateValidators.birthDateValidator;
  readonly SubjectAttributeType = SubjectAttributeType;

  get label(): string {
    return this.subjectAttributeType ? getUiKeyForSubjectCreateAttribute(this.subjectAttributeType) : '';
  }

  get previousValue(): string {
    return this.singleAttributeForm.get('previousValue')!.value!;
  }

  get rightLabelTooltip(): string {
    return `${this.label}: ${this.previousValue}`;
  }

  get isFormField(): boolean {
    return this.componentType === AttributeComponentType.FORM_FIELD;
  }

  get isAutocomplete(): boolean {
    return this.componentType === AttributeComponentType.AUTOCOMPLETE;
  }

  get isDatepicker(): boolean {
    return this.componentType === AttributeComponentType.DATEPICKER;
  }

}
