import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MAT_RADIO_DEFAULT_OPTIONS, MAT_RADIO_GROUP, MatRadioChange} from '@angular/material/radio';
import {IczRadioGroupDirective} from './radio-group.directive';
import {DebugLoggingService} from '../../../core/services/debug-logging.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'icz-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {
      provide: MAT_RADIO_GROUP,
      useExisting: IczRadioGroupDirective,
    }
  ],
})
export class RadioButtonComponent implements OnInit {

  private debugLogger = inject(DebugLoggingService);
  private destroyRef = inject(DestroyRef);

  @Input() label!: string;
  @Input() secondaryLabel: Nullable<string>;
  @Input() value: any;
  @Input() checked = false;
  @HostBinding('class.disabled')
  @Input()
  set fieldDisabled(newValue: Nullable<boolean>) {
    this._fieldDisabledFromTemplate = newValue;
  }
  get fieldDisabled(): Nullable<boolean> {
    return this._fieldDisabledFromTemplate ?? this._fieldDisabledFromReactiveModel;
  }

  @Output() blur = new EventEmitter<FocusEvent>();
  @Output() change = new EventEmitter<MatRadioChange>();

  _fieldDisabledFromTemplate: Nullable<boolean>;
  _fieldDisabledFromReactiveModel: Nullable<boolean>;

  ngOnInit() {
    this.blur.pipe(takeUntilDestroyed(this.destroyRef)).subscribe( _ =>
      this.debugLogger.logUserInteraction({description: `Input '${this.label}', hodnota: ${String(this.value)}`})
    );
  }

  radioValueChanged(radioChangeEvent: MatRadioChange) {
    this.checked = radioChangeEvent.value === this.value;
    this.change.emit(radioChangeEvent);
  }

}
