import {Component, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../dialogs/check-unsaved-form-dialog.service';
import {ICZ_MODAL_DATA} from '../../../../dialogs/component-modal/component-modal.component';
import {IczModalRef} from '../../../../../services/icz-modal-ref.injectable';
import {LoadingIndicatorService} from '../../../../essentials/loading-indicator.service';
import {IczFormControl, IczFormGroup} from '../../../../form-elements/icz-form-controls';
import {
  ApiDigitalComponentService,
  ComponentContentControlMetadataDto,
  DigitalComponentCompleteDto
} from '|api/component';
import {TranslateService} from '@ngx-translate/core';
import {
  EsslComponentToastService,
  EsslComponentToastType
} from '../../../../../core/services/notifications/essl-component-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {getLatestDigitalComponentVersion} from '../../../shared-document.utils';

@Component({
  selector: 'icz-update-component-template-dialog',
  templateUrl: './update-component-template-dialog.component.html',
  styleUrls: ['./update-component-template-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class UpdateComponentTemplateDialogComponent implements OnInit {
  formGroupsToCheck!: string[];
  form = new IczFormGroup({
    onlyEmpty: new IczFormControl<boolean>(false)
  });

  componentMetadata: ComponentContentControlMetadataDto[] = [];
  allParametersFilled = true;

  protected modalRef = inject(IczModalRef<void>);
  protected loadingService = inject(LoadingIndicatorService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private digitalComponent = inject(ICZ_MODAL_DATA) as DigitalComponentCompleteDto;
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private translateService = inject(TranslateService);
  private componentToastService = inject(EsslComponentToastService);

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.loadingService.doLoading(
      this.apiDigitalComponentService.digitalComponentGetComponentContentControlMetadata({id: this.digitalComponent.digitalComponentVersions!.at(0)!.id!}),
      this
    ).subscribe(componentMetadata => {
      this.componentMetadata = componentMetadata;
      for (const param of this.componentMetadata) {
        if (param.empty) {
          this.allParametersFilled = false;
          return;
        }
      }
    });
  }

  submit() {
    const onlyEmpty = this.form.get('onlyEmpty')!.value;
    this.loadingService.doLoading(
      this.apiDigitalComponentService.digitalComponentUpdateComponentContentControlMetadata({id: getLatestDigitalComponentVersion(this.digitalComponent)!.id!, onlyEmpty }),
      this
    ).subscribe({
      next: result => {
        const toastTemplateData = {
          [InternalNotificationKey.DOCUMENT_ID]: String(this.digitalComponent.documentId),
          [InternalNotificationKey.DIGITAL_COMPONENT_ID]: this.digitalComponent.id!,
          [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: result.id!,
          [InternalNotificationKey.ESSL_COMPONENT_LABEL]: this.digitalComponent.label!,
        };
        this.componentToastService.dispatchComponentInfoToast(EsslComponentToastType.COMPONENT_PARAMETERS_UPDATED, toastTemplateData);
        this.modalRef.close(true);
      },
      error: _ => {
        this.componentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.COMPONENT_PARAMETERS_UPDATE_ERROR);
      }
    });
  }

  getParameterValueAsText(parameter: ComponentContentControlMetadataDto) {
    let text = '';
    if (parameter.empty) {
      text = `${this.translateService.instant('není vyplněno')}, `;
      if (parameter.valueAvailable) {
        text += this.translateService.instant('hodnota existuje');
      } else {
        text += this.translateService.instant('hodnota neexistuje');
      }
    } else {
      text = String(parameter.value);
    }
    return text;
  }

  cancel() {
    this.modalRef.close(false);
  }
}
