export enum ReceivedDocumentUsage {
  CREATE_OFFICER_FULL_DOCUMENT = 'CREATE_OFFICER_FULL_DOCUMENT',
  CREATE_FILING_OFFICE_PAPER_CONSIGNMENT = 'CREATE_FILING_OFFICE_PAPER_CONSIGNMENT',
  CREATE_FILING_OFFICE_PERSONAL_CONSIGNMENT = 'CREATE_FILING_OFFICE_PERSONAL_CONSIGNMENT',
  REGISTER_CONTENT_OFFICER = 'REGISTER_CONTENT_OFFICER',
  REGISTER_CONTENT_FILING_OFFICE = 'REGISTER_CONTENT_FILING_OFFICE',
  CREATE_FILING_OFFICE_INTERNAL_CONSIGNMENT = 'CREATE_FILING_OFFICE_INTERNAL_CONSIGNMENT',
}

export enum ReceivedConsignmentsTableView {
  ALL = 'ALL',
  TODAY = 'TODAY',
  PAPER = 'PAPER',
  DATABOX = 'DATABOX',
  EMAIL = 'EMAIL',
  INTERNAL = 'INTERNAL',
}

export enum ReceivedConsignmentDeliveryType {
  PAPER = 'PAPER',
  DATABOX = 'DATABOX',
  EMAIL = 'EMAIL',
  INTERNAL = 'INTERNAL',
}
