/* tslint:disable */
/* eslint-disable */
import {Essl9972ObjectClass} from './essl-9972-object-class';
import {Essl9972RegistryOfficeTransferActivityState} from './essl-9972-registry-office-transfer-activity-state';
import {Essl9972RegistryOfficeTransferState} from './essl-9972-registry-office-transfer-state';
import {Essl9972SearchRecordSourceDto} from './essl-9972-search-record-source-dto';
import {Essl9972UniqueIdentifierDto} from './essl-9972-unique-identifier-dto';

export enum RegistryOfficeTransferIncidentOrigin {
  VALIDATOR = 'VALIDATOR',
  DESA = 'DESA',
  MANUAL = 'MANUAL',
}

/**
 * DTO representing elastic search hit source for registry office transfer view
 */
export type Essl9972SearchRecordSourceRegistryOfficeTransferActivityDto = Essl9972SearchRecordSourceDto & {

/**
 * Transfer main entity document ID
 */
'documentId'?: number;

/**
 * Transfer main entity file ID
 */
'fileId'?: number;

/**
 * Transfer main entity storage unit ID
 */
'storageUnitId'?: number;

/**
 * Target transfer registry office ID
 */
'registryOfficeId'?: number;

/**
 * Object class of main transfer entity
 */
'objectClass'?: Essl9972ObjectClass;
'registryOfficeTransferState'?: Essl9972RegistryOfficeTransferState;
'registryOfficeTransferActivityState'?: Essl9972RegistryOfficeTransferActivityState;

/**
 * Datum předání (DATETIME)
 */
'handedOverAt'?: string;

/**
 * Datum převzetí (DATETIME)
 */
'takenOverAt'?: string;

/**
 * Předal
 */
'handedOverByFunctionalPositionId'?: number;
'uid'?: Essl9972UniqueIdentifierDto;

/**
 * ČJ/SZ/Č.UJ
 */
'refNumber'?: string;

/**
 * Věc dokumentu / Název spisu / Název UJ jakožto hlavní entity uložení
 */
'entityName'?: string;

/**
 * VS ukládané entity
 */
'entityClassId'?: number;

/**
 * Zpracovatel ukládané entity
 */
'ownerFunctionalPositionId'?: number;

/**
 * Počet nevyřešených incidentů uložení (Not Implemented Yet)
 */
'unresolvedIncidentsCount'?: number;

/**
 * Počet ukládaných entit
 */
'transferedEntitiesCount'?: number;

'incidentsOrigin'?: RegistryOfficeTransferIncidentOrigin;
} & {
};
