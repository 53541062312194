/* tslint:disable */
/* eslint-disable */

/**
 * Classification of a subject identification
 */
export enum SubjectRecordClassification {
  FO = 'FO',
  PO = 'PO',
  PFO = 'PFO'
}
