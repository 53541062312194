/* tslint:disable */
/* eslint-disable */

/**
 * Nomenclature synchronization status
 */
export enum NomenclatureSynchronizationStatus {
  SYNCHRONIZED = 'SYNCHRONIZED',
  OUT_OF_SYNC = 'OUT_OF_SYNC',
  SYNC_RUNNING = 'SYNC_RUNNING'
}
