import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input, OnInit,
  Output
} from '@angular/core';
import {ClassificationSchemeDto, DisposalScheduleDto, DocumentTypeDto, EntityClassDto} from '|api/codebook';
import {DisposalOperationCode, RetentionTriggerTypeCode} from '|api/commons';
import {IczOnChanges, IczSimpleChanges} from '../../../utils/icz-on-changes';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {isNilOrEmptyString} from '../../../lib/utils';
import {IczFormControl, IczFormGroup} from '../../form-elements/icz-form-controls';
import {LoadingIndicatorService} from '../../essentials/loading-indicator.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {DisposalTipDetailLevel} from '../shared-business-components.model';
import {forkJoin} from 'rxjs';

export enum DisposalScheduleSourceType {
  DISPOSAL_SCHEDULE = 'DISPOSAL_SCHEDULE',
  ENTITY_CLASS = 'ENTITY_CLASS',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
}

export enum DisposalScheduleComparison {
  NONE = 'NONE',
  DUAL = 'DUAL',
  SINGLE = 'SINGLE'
}

/**
 * Used for rendering effective disposal schedule given disposalScheduleId, entityClassId and documentTypeId.
 * There are certain rules in place when determining which disposal schedule will be effective.
 * see getEffectiveDisposalScheduleSourceType.
 */
@Component({
  selector: 'icz-disposal-tip',
  templateUrl: './disposal-tip.component.html',
  styleUrls: ['./disposal-tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisposalTipComponent implements IczOnChanges, OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  protected cd = inject(ChangeDetectorRef);

  @Input()
  disposalScheduleId: Nullable<number>;
  @Input({required: true})
  entityClassId: Nullable<number>;
  @Input()
  documentTypeId: Nullable<number>;
  @Input({required: true})
  detailLevel!: DisposalTipDetailLevel;
  @Input()
  showHelpField = true;

  @Output()
  disposalScheduleSelected = new EventEmitter<Nullable<DisposalScheduleDto>>(true);
  @Output()
  disposalScheduleSourceEntity = new EventEmitter<Nullable<EntityClassDto|DocumentTypeDto>>(true);
  @Output()
  disposalScheduleSource = new EventEmitter<Nullable<DisposalScheduleSourceType>>(true);
  @Output()
  disposalScheduleComparison = new EventEmitter<Nullable<DisposalScheduleComparison>>(true);

  retentionTriggerTypesOptions = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);

  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;
  disposalOperationCodeOptions = enumToOptions('disposalOperationCode', DisposalOperationCode);

  effectiveDisposalScheduleSourceType: Nullable<DisposalScheduleSourceType>;

  get disposalSchedule() {
    if (isNilOrEmptyString(this.form.get('disposalOperationCode')!.value)) {
      return '';
    }
    else {
      return this.form.get('disposalOperationCode')!.value! +
        (this.form.get('retentionPeriod')!.value ?? this.form.get('disposalYear')!.value ?? '') +
        ' - ' +
        this.form.get('disposalName')!.value;
    }
  }

  effectiveDisposalSchedule: Nullable<DisposalScheduleDto>;
  currentClassificationScheme: Nullable<ClassificationSchemeDto>;
  documentTypes: DocumentTypeDto[] = [];
  entityClasses: EntityClassDto[] = [];

  form = new IczFormGroup({
    disposalOperationCode: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    retentionPeriod: new IczFormControl<Nullable<number>>({value: null, disabled: true}, []),
    disposalYear: new IczFormControl<Nullable<number>>({value: null, disabled: true}, []),
    disposalName: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
  });

  ngOnInit() {
    forkJoin([
      this.codebookService.documentTypes(),
      this.codebookService.entityClasses(),
    ]).subscribe(([documentTypes, entityClasses]) => {
      this.documentTypes = documentTypes;
      this.entityClasses = entityClasses;
      this.resolveDisaposalSchedule();
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.disposalScheduleId || changes.entityClassId || changes.documentTypeId) {
      this.resolveDisaposalSchedule();
    }
  }

  resolveDisaposalSchedule() {
    const effectiveDisposalScheduleSourceType = this.getEffectiveDisposalScheduleSourceType();
    this.resolveDisaposalScheduleComparison();

    if (effectiveDisposalScheduleSourceType === DisposalScheduleSourceType.DISPOSAL_SCHEDULE) {
      this.effectiveDisposalScheduleSourceType = effectiveDisposalScheduleSourceType;
      this.setFromDisposalScheduleId(this.disposalScheduleId);
    } else if (effectiveDisposalScheduleSourceType === DisposalScheduleSourceType.ENTITY_CLASS) {
      this.effectiveDisposalScheduleSourceType = effectiveDisposalScheduleSourceType;
      this.fetchEntityClassAndSetDisposalSchedule();
    } else if (effectiveDisposalScheduleSourceType === DisposalScheduleSourceType.DOCUMENT_TYPE) {
      this.fetchDocumentTypeAndSetDisposalSchedule();
    } else {
      this.form.reset();
      this.disposalScheduleSourceEntity.emit(null);
      this.effectiveDisposalScheduleSourceType = null;
    }
    this.disposalScheduleSource.emit(this.effectiveDisposalScheduleSourceType);
  }

  resolveDisaposalScheduleComparison() {
    let selectedDocumentType;
    let selectedEntityClass;

    if (this.documentTypes) {
      selectedDocumentType = this.documentTypes.find(dt => dt.id === this.documentTypeId);
    }

    if (this.entityClasses) {
      selectedEntityClass = this.entityClasses.find(ec => ec.id === this.entityClassId);
    }

    if (selectedDocumentType && selectedEntityClass) {
      if (selectedDocumentType.disposalScheduleId && selectedEntityClass.disposalScheduleId) {
        this.disposalScheduleComparison.emit(DisposalScheduleComparison.DUAL);
      } else if (
        (selectedDocumentType.disposalScheduleId && isNil(selectedEntityClass.disposalScheduleId)) ||
        (selectedEntityClass.disposalScheduleId && isNil(selectedDocumentType.disposalScheduleId))
      ) {
        this.disposalScheduleComparison.emit(DisposalScheduleComparison.SINGLE);
      }
    } else if (
      (selectedDocumentType && selectedDocumentType.disposalScheduleId) ||
      (selectedEntityClass && selectedEntityClass.disposalScheduleId)
    ) {
      this.disposalScheduleComparison.emit(DisposalScheduleComparison.SINGLE);
    } else {
      this.disposalScheduleComparison.emit(null);
    }
  }

  private fetchDocumentTypeAndSetDisposalSchedule() {
    if (this.documentTypes) {
      const documentType = this.documentTypes.find(dt => dt.id === this.documentTypeId);

      if (documentType) {
        if (documentType.disposalScheduleId) {
          this.setFromDisposalScheduleId(documentType.disposalScheduleId);
          this.effectiveDisposalScheduleSourceType = DisposalScheduleSourceType.DOCUMENT_TYPE;
          this.disposalScheduleSourceEntity.emit(documentType);
        } else {
          if (this.entityClassId) {
            this.effectiveDisposalScheduleSourceType = DisposalScheduleSourceType.ENTITY_CLASS;
            this.fetchEntityClassAndSetDisposalSchedule();
          } else {
            this.setFromDisposalScheduleId(null);
          }
        }
      } else if (!this.entityClassId){
        this.setFromDisposalScheduleId(null);
        this.effectiveDisposalScheduleSourceType = null;
      }
    }
  }

  private fetchEntityClassAndSetDisposalSchedule() {
    if (this.entityClasses) {
      const entityClass = this.entityClasses.find(ec => ec.id === this.entityClassId);

      if (entityClass) {
        if (this.effectiveDisposalScheduleSourceType !== DisposalScheduleSourceType.DOCUMENT_TYPE) {
          this.setFromDisposalScheduleId(entityClass.disposalScheduleId!);
          this.effectiveDisposalScheduleSourceType = DisposalScheduleSourceType.ENTITY_CLASS;
          this.disposalScheduleSourceEntity.emit(entityClass);
        }

        if (entityClass.classificationSchemeId) {
          this.codebookService.classificationSchemes().subscribe(schemes => {
            this.setClassificationScheme(schemes.find(s => s.id === entityClass.classificationSchemeId));
          });
        }
      } else if (!this.entityClassId && this.documentTypeId) {
        this.setClassificationScheme(null);
      } else if (!this.documentTypeId && !this.documentTypeId) {
        this.setFromDisposalScheduleId(null);
        this.effectiveDisposalScheduleSourceType = null;
      }
    }
  }

  private setFromDisposalScheduleId(id: Nullable<number>) {
    if (id) {
      this.codebookService.disposalSchedules().subscribe(disposalSchedules => {
        this.effectiveDisposalSchedule = disposalSchedules.find(ds => ds.id === id);

        if (this.effectiveDisposalSchedule) {
          this.disposalScheduleSelected.emit(this.effectiveDisposalSchedule);
          this.form.get('retentionPeriod')!.setValue(this.effectiveDisposalSchedule.retentionPeriod);
          this.form.get('disposalYear')!.setValue(this.effectiveDisposalSchedule.disposalYear);
          this.form.get('disposalOperationCode')!.setValue(this.effectiveDisposalSchedule.disposalOperationCode);
          this.form.get('disposalName')!.setValue(this.effectiveDisposalSchedule.name);
        }
      });
    }
    else {
      this.effectiveDisposalSchedule = null;
      this.disposalScheduleSelected.emit(null);
      this.disposalScheduleSourceEntity.emit(null);
      this.form.reset();
    }
  }

  private setClassificationScheme(classificationScheme: Nullable<ClassificationSchemeDto>) {
    this.currentClassificationScheme = classificationScheme;
    this.cd.detectChanges();
  }

  private getEffectiveDisposalScheduleSourceType(): Nullable<DisposalScheduleSourceType> {
    if (this.disposalScheduleId) {
      return DisposalScheduleSourceType.DISPOSAL_SCHEDULE;
    }
    else if (this.entityClassId && this.documentTypeId) {
      return DisposalScheduleSourceType.DOCUMENT_TYPE;
    }
    else if (this.entityClassId && !this.documentTypeId) {
      return DisposalScheduleSourceType.ENTITY_CLASS;
    }
    else if (!this.entityClassId && this.documentTypeId) {
      return DisposalScheduleSourceType.DOCUMENT_TYPE;
    }
    else {
      return null;
    }
  }
}
