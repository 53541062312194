<div class="row gap-16 items-center">
  @if (showIcon) {
    <icz-icon class="alert-icon" [svgIcon]="icon" withCustomIconColor></icz-icon>
  }
  <div class="col gap-0 grow">
    <div class="col grow">
      <div class="row">
        <div class="row grow">
          @if (heading) {
            <icz-label [labelContext]="textContext" class="font-bold" [hasNormalWhitespace]="true" [label]="heading"></icz-label>
          }
          @if (label && !heading) {
            <icz-label [labelContext]="textContext" [hasNormalWhitespace]="true" [label]="label"></icz-label>
          }

          @if (isFullAlert) {
            @if (isLabelExpandable && !isExpanded) {
              <icz-icon svgIcon="expand_more" class="cursor-pointer" (click)="toggleExpansionState()"></icz-icon>
            }
            @if (isLabelExpandable && isExpanded) {
              <icz-icon svgIcon="collapse" class="cursor-pointer" (click)="toggleExpansionState()"></icz-icon>
            }
          }
        </div>
        <ng-container>
          <ng-content select="[extraContent]"></ng-content>
        </ng-container>
      </div>
    </div>
    @if ((!isLabelExpandable || isExpanded)) {
      <div>
        @if (!label) {
          <ng-content select="[content]"></ng-content>
        }
        @if (label && heading) {
          <icz-label [labelContext]="textContext" [hasNormalWhitespace]="true" [label]="label"></icz-label>
        }
      </div>
    }
  </div>
  @if (isDismissable) {
    <icz-button svgIcon="close" (onAction)="dismissed.emit()" size="small" disableRipple background></icz-button>
  }
</div>
@if (!isFullAlert) {
  <ng-content select="[content]"></ng-content>
}
