import {DocumentState, SubjectRecordDto} from '|api/commons';
import {DocumentDto} from '|api/document';
import {ButtonDisablerFn} from '../../../essentials/button-collection/button-collection.component';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {IszrRppUserRelationFilteredDto} from '|api/codebook';

export class SubjectsToolbarDisablers {

  static isDocumentDeactivated(currentDocument: Nullable<DocumentDto>): ButtonDisablerFn {
    return () => {
      return currentDocument?.documentState ===  DocumentState.DEACTIVATED ? {tooltip: 'Dokument je zrušený'} : null;
    };
  }

  static isUserNotPermittedForIszrOperations(rppUserRelationsForCurrentFp: IszrRppUserRelationFilteredDto[]): ButtonDisablerFn {
    return () => {
      return !rppUserRelationsForCurrentFp.length ? {tooltip: 'Nemáte oprávnění pracovat s agendami ISZR'} : null;
    };
  }

  static subjectIsAlreadyRepresenting(selectedRows: SubjectRecordDto[], documentDetailService: Nullable<DocumentDetailService>): ButtonDisablerFn {
    return () => {
      return (selectedRows[0] && documentDetailService?.objectRepresentingSubject?.id === selectedRows[0]?.id) ?
        {tooltip: 'Subjekt již je pro právě otevřený dokument reprezentující'} :
        null;
    };
  }

}
