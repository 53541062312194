import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {SecurityCategoryDto} from '|api/codebook';
import {AbstractClassifierSelectorComponent} from '../abstract-classifier-selector.component';
import {CodebookService} from '../../../core/services/codebook.service';
import {NodeType} from '|api/commons';
import {namedDtoToOption} from '../../../core/services/data-mapping.utils';

@Component({
  selector: 'icz-security-category-selector',
  templateUrl: './security-category-selector.component.html',
  styleUrls: ['./security-category-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityCategorySelectorComponent extends AbstractClassifierSelectorComponent<SecurityCategoryDto> {

  private codebookService = inject(CodebookService);

  @Input()
  label = 'Bezpečnostní kategorie';
  @Input()
  isMultiselect = false;
  @Input()
  fieldDisabled: Nullable<boolean>;

  classifierSource$ = this.codebookService.securityCategories();
  optionMapper = namedDtoToOption(true);
  override prefilteringPredicate = (e: SecurityCategoryDto) => e.nodeType === NodeType.LEAF;

}
