import {Component, ElementRef, EventEmitter, Input, ViewChild} from '@angular/core';
import {BusinessRuleName} from '|api/commons';
import {IczFormGroup} from '../../form-elements/icz-form-controls';
import {CreateDigitalFormConfig} from '../essl-components/essl-components-utils';
import {DigitalComponentUploadDialogType} from '../essl-components/models/digital-components.model';
import {ReceivedDocumentUsage} from '../../../services/received-consignments.model';


@Component({
  selector: 'icz-receive-document-digital-components',
  templateUrl: './receive-document-digital-components.component.html',
  styleUrls: ['./receive-document-digital-components.component.scss'],
})
export class ReceiveDocumentDigitalComponentsComponent {
  @ViewChild('fileUpload', {static: false})
  fileUpload!: ElementRef;

  @Input() receivedDocumentUsage = ReceivedDocumentUsage.CREATE_OFFICER_FULL_DOCUMENT;
  @Input({required: true}) form!: IczFormGroup;

  selectFiles = new EventEmitter();

  digitalComponentsConfig: CreateDigitalFormConfig = {
    showFieldIsFinal: false,
    showFieldOrigin: false,
    setFieldIsFinalAsTrue: true,
  };

  get businessRule(): Nullable<BusinessRuleName> {
    return this.isFilingOfficePersonal || this.isFullDocument ? BusinessRuleName.DISTRIBUTION_PERSONAL_TECHNICAL_ATTACHMENT_FORMAT : null;
  }

  get isFullDocument() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_OFFICER_FULL_DOCUMENT;
  }

  get isFilingOfficePersonal() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PERSONAL_CONSIGNMENT;
  }

  get isOnPlaceDelivery() {
    return this.isFilingOfficePersonal || this.isFullDocument;
  }

  get digitalComponentsUploadFormType(): DigitalComponentUploadDialogType {
    return (this.isFullDocument || this.isFilingOfficePersonal) ? DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT_WITH_SIGNATURES_CHECK :
      DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT;
  }

  get digitalComponentsLabel() {
    return this.isOnPlaceDelivery ? 'Obsah nosiče' : 'Digitální komponenty';
  }

  get openFileSelectLabel() {
    return this.isOnPlaceDelivery ? 'Nahrát obsah nosiče' : 'Vybrat soubor z počítače';
  }

}
