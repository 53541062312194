<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="row gap-16">
    <div class="col grow">
      <icz-section label="Základní informace o chybě">
        <div class="row">
          <icz-readonly-field
            class="grow"
            label="Zdroj chyby"
            [value]="incident.source"
            [options]="incidentSourceOptions"
          ></icz-readonly-field>
          <icz-readonly-field
            class="grow"
            label="ID chyby"
            [value]="incident.errorId"
          ></icz-readonly-field>
        </div>
        <icz-readonly-field
          label="Založeno"
          [value]="incident.auditInfo?.createdAt | localizedDatetime"
        ></icz-readonly-field>
      </icz-section>
      <icz-section label="Popis chyby">
        <icz-form-autocomplete
          formControlName="state"
          label="Stav chyby"
          [options]="incidentStateOptions"
        ></icz-form-autocomplete>
        <icz-form-field
          formControlName="name"
          label="Popis chyby"
          [autoSizeMax]="5"
        ></icz-form-field>
        <icz-form-field
          formControlName="resolutionDescription"
          label="Provedená náprava"
          [autoSizeMax]="5"
        ></icz-form-field>
      </icz-section>
    </div>
    <div class="col grow">
      @if (objectWithError) {
        <icz-section label="Objekt s chybou">
          <icz-entity-info
            [entity]="objectWithError"
          ></icz-entity-info>
        </icz-section>
      }
      @if (componentWithError) {
        <icz-section label="Komponenta s chybou">
          <!-- todo(rb) fix exception for digital component after BE provides component type property -->
          <icz-essl-component-link
            [documentEsslComponent]="$any(componentWithError)"
          ></icz-essl-component-link>
        </icz-section>
      }
      @if (associatedProblem) {
        <icz-section label="Navázaný problém">
          <div class="icz-body-1 mb-8">
            <div>{{ associatedProblem.errorId }} - {{ associatedProblem.name }}</div>
            <div>{{ associatedProblem.description }}</div>
          </div>
          <icz-readonly-field
            label="Workaround"
            [value]="associatedProblem.workaround"
          ></icz-readonly-field>
          <icz-readonly-field
            label="Popis řešení"
            [value]="associatedProblem.solutionDescription"
          ></icz-readonly-field>
          <icz-checkbox label="Automaticky napravovat" [fieldDisabled]="true" [value]="associatedProblem.solvedAutomatically"></icz-checkbox>
        </icz-section>
      }
    </div>
  </div>
</form>

<icz-form-buttons>
  <ng-container leftButtons>
    <icz-button iczFormSubmit
      [disabled]="loadingService.isLoading(this) || isIncidentSolved"
      (onAction)="submit()"
      primary label="Uložit změny"
    ></icz-button>
    <icz-button iczFormSubmit
      [disabled]="loadingService.isLoading(this) || isIncidentSolved"
      (onAction)="submitAndMarkAsResolved()"
      label="Uložit změny a označit jako vyřešené"
    ></icz-button>
  </ng-container>
  <icz-button rightButtons
    [disabled]="loadingService.isLoading(this)"
    (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
