<div class="popup-size">
  <div class="row header-separator">
    <icz-label label="Informace o typu dokumentu" class="grow ml-16"></icz-label>
  </div>
  <div class="m-16">
    <icz-readonly-field label="Název typu dokumentu" [value]="getSelectedDocumentType('name')"></icz-readonly-field>
    <icz-readonly-field label="Popis" [value]="getSelectedDocumentType('description')"></icz-readonly-field>
    <icz-readonly-field label="Skartační režim" [value]="disposalScheduleForPopup"></icz-readonly-field>
    @if (selectedDocumentType) {
      <icz-retention-trigger-type-widget [form]="documentTypePopupForm" [disposalScheduleId]="selectedDocumentType.disposalScheduleId!" [externalRetentionTriggerIds]="[selectedDocumentType.externalRetentionTriggerId!]"></icz-retention-trigger-type-widget>
    }
  </div>
</div>
