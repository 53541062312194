/* tslint:disable */
/* eslint-disable */

/**
 * Organization subject type
 */
export enum OrganizationSubjectType {
  PO = 'PO',
  PO_OVM = 'PO_OVM',
  OVM = 'OVM'
}
