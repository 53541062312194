import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {
  DocumentState,
  FileState,
  OrganizationalStructureEntityDto,
  OrganizationalStructureEntityType,
  SharedFolderPermissionLevel
} from '|api/commons';
import {
  ApiAuthorizationService,
  ApiEntitySharingService,
  AuthorizationBusinessReason,
  AuthorizedEntityType,
  DocumentAuthorizedOperation,
  DocumentPermission,
  DocumentSharingDto,
  DocumentSharingUpdateDto,
  FileAuthorizedOperation,
  FilePermission,
  FileSharingDto,
  FileSharingUpdateDto,
  OrgUnitEntitySharingDto,
  PermissionSetLevel,
  SharedFolderAuthorizedOperation,
  SharedFolderSharingDto,
  SharedFolderSharingUpdateDto,
  StorageUnitAuthorizedOperation,
  StorageUnitPermission,
  StorageUnitSharingDto,
  StorageUnitSharingUpdateDto,
} from '|api/core';
/*
  Permission settings table
  --------------------------------
  1. Is used to display, add, edit a delete permission for certain entity
  2. Now its used only for entity DOCUMENT, but in future it should be used also for FILE and maybe other entities.
  3. If user has permission to change permissions for certain entity, all fields are editable and user can also add or delete permissions
*/
import {FindFunctionalPositionByIdPipe} from '../../find-functional-position-by-id.pipe';
import {FindOrganizationalUnitByIdPipe} from '../../find-organizational-unit-by-id.pipe';
import {
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation,
  isAuthorizedOperationGranted
} from '../permissions.utils';
import {DocumentDto} from '|api/document';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {
  ManualSharingDto,
  ManualSharingUpdateDto,
  ManualSharingWithFineGrainedPermissionsDto,
  PermissionService
} from '../services/permission.service';
import {InMemorySearchDatasource} from '../../../form-elements/form-popup-table-selector/in-memory-search.datasource';
import {TableColumnsData} from '../../../table/table-columns-data';
import {FilterType} from '../../../table/filter.types';
import {PermissionSelectorValue} from '../permission-selector-popup/permission-selector-popup.component';
import {PermissionSelectorPopupClosedEvent} from '../permission-selector/permission-selector.component';
import {LoadingIndicatorService} from '../../../essentials/loading-indicator.service';
import {extendDefaultTableConfig} from '../../../table/table.component';
import {SearchParams} from '../../../../services/search-api.service';
import {DocumentDetailCountType, DocumentDetailService} from '../../../../services/document-detail.service';
import {FileDetailCountType, FileDetailService} from '../../../../services/file-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ObjectDetailPart} from '../../../../services/abstract-object-detail.service';
import {decrement, increment, load} from '../../../../lib/object-counts';
import {StorageUnitDetailCountType, StorageUnitDetailService} from '../../../../services/storage-unit-detail.service';

type GeneralSharingDto = DocumentSharingDto | FileSharingDto | StorageUnitSharingDto | SharedFolderSharingDto;

@Component({
  selector: 'icz-permission-settings-table',
  templateUrl: './permission-settings-table.component.html',
  styleUrls: ['./permission-settings-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FindFunctionalPositionByIdPipe,
    FindOrganizationalUnitByIdPipe,
  ],
})
export class PermissionSettingsTableComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private permissionService = inject(PermissionService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private apiEntitySharingService = inject(ApiEntitySharingService);
  private cd = inject(ChangeDetectorRef);
  private functionalPositionPipe = inject(FindFunctionalPositionByIdPipe);
  private findOrganizationalUnitByIdPipe = inject(FindOrganizationalUnitByIdPipe);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private destroyRef = inject(DestroyRef);
  private documentDetailService = inject(DocumentDetailService, {optional: true});
  private fileDetailService = inject(FileDetailService, {optional: true});
  private storageUnitDetailService = inject(StorageUnitDetailService, {optional: true});

  @Input({required: true}) entityId!: number;
  @Input({required: true}) entityType!: AuthorizedEntityType;
  @Input({required: true}) tableTitle!: string;
  @Input() disableOrgUnitPermissions = false;

  readonly OrganizationalStructureEntityType = OrganizationalStructureEntityType;
  readonly AuthorizationBusinessReason = AuthorizationBusinessReason;

  isPermissionToolbarDisabled = false;

  manageSharingAuthorizedOperation!: GeneralAuthorizedOperation;
  authorizationResult!: GeneralAuthorizationResult;

  canUserManageSharing = true;
  isSharedWithCurrentUser = false;

  permissions$!: Observable<{perEntity: GeneralSharingDto[], perOrgUnit: OrgUnitEntitySharingDto[]}>;

  dataSource!: InMemorySearchDatasource;

  columnsData = new TableColumnsData<keyof (ManualSharingDto & OrgUnitEntitySharingDto) | 'origin' | 'rowActions'>([
    {
      id: 'sharedWith',
      label: 'Funkční místa/Organizační jednotky',
      filterType: FilterType.NONE,
      disableSort: true,
    },
    {id: 'permissions', label: 'Oprávnění', filterType: FilterType.NONE, disableSort: true},
    {id: 'origin', label: 'Důvod', filterType: FilterType.NONE, disableSort: true},
    {id: 'revoking', label: 'Typ oprávnění', filterType: FilterType.NONE, disableSort: true},
    {id: 'rowActions', label: 'Akce', fixedWidth: 60, filterType: FilterType.NONE, hideLabelInHeader: true},
  ]);

  config = extendDefaultTableConfig({
    rowHeight: 70,
    toolbarConfig: {
      showFilter: false,
    }
  });

  permissionRowToPermissionOptions(row: ManualSharingDto): PermissionSelectorValue {
    const {revoking} = row;
    const detailedPermissions = Boolean((row as ManualSharingWithFineGrainedPermissionsDto).permissions) && !Boolean(row.permissionSetLevel);
    const options: PermissionSelectorValue = {
      revoking,
      detailedPermissions,
    };
    return detailedPermissions
      ? {...options, permissions: (row as ManualSharingWithFineGrainedPermissionsDto).permissions}
      : {...options, permissionSetLevel: row.permissionSetLevel};
  }

  onPermissionSelectorPopupClosed(row: ManualSharingDto, $event: PermissionSelectorPopupClosedEvent) {
    if ($event) {
      const {permissionsUpdated, permissionOptions} = $event;
      if (permissionsUpdated) {
        this.updatePermission(row, permissionOptions);
      }
    }
  }

  loadPermissions() {
    if (!this.permissions$) {
      if (this.entityType === AuthorizedEntityType.DOCUMENT) {
        this.permissions$ =  this.apiEntitySharingService.entitySharingGetSharingStatusForDocument({id: this.entityId});
      }
      else if (this.entityType === AuthorizedEntityType.FILE) {
        this.permissions$ =  this.apiEntitySharingService.entitySharingGetSharingStatusForFile({id: this.entityId});
      }
      else if (this.entityType === AuthorizedEntityType.STORAGE_UNIT) {
        this.permissions$ =  this.apiEntitySharingService.entitySharingGetSharingStatusForStorageUnit({id: this.entityId});
      }
      else if (this.entityType === AuthorizedEntityType.SHARED_FOLDER) {
        this.permissions$ =  this.apiEntitySharingService.entitySharingGetSharingStatusForSharedFolder({id: this.entityId}).pipe(
          map(response => ({
            ...response,
            perOrgUnit: [],
          })),
        );
      }
    }
    this.permissions$.subscribe(permissions => {
      if (!this.dataSource) {
        this.dataSource = new InMemorySearchDatasource(() => [...permissions.perEntity, ...permissions.perOrgUnit]);
        // Keep it here pls, otherwise dont work
        this.cd.detectChanges();
      } else {
        this.dataSource.setDataFactory(() => [...permissions.perEntity, ...permissions.perOrgUnit]);
      }
    });
  }

  getDocumentSharingUpdateDto(row: DocumentSharingDto, permissionOptions: PermissionSelectorValue): DocumentSharingUpdateDto {
    const sharingUpdateDto: DocumentSharingUpdateDto = {
      ...row,
      ...permissionOptions,
      permissionSetLevel: permissionOptions.permissionSetLevel as PermissionSetLevel,
      permissions: permissionOptions.permissions as DocumentPermission[]
    };

    const {detailedPermissions} = permissionOptions;

    if (detailedPermissions) {
      delete sharingUpdateDto.permissionSetLevel;
    } else {
      delete sharingUpdateDto.permissions;
    }
    return sharingUpdateDto;
  }

  getFileSharingUpdateDto(row: FileSharingDto, permissionOptions: PermissionSelectorValue): FileSharingUpdateDto {
    const permissionCreateDto: FileSharingUpdateDto = {
      ...row,
      ...permissionOptions,
      permissionSetLevel: permissionOptions.permissionSetLevel as PermissionSetLevel,
      permissions: permissionOptions.permissions as FilePermission[]
    };

    const {detailedPermissions} = permissionOptions;

    if (detailedPermissions) {
      delete permissionCreateDto.permissionSetLevel;
    } else {
      permissionCreateDto.permissions = [];
    }
    return permissionCreateDto;
  }

  getStorageUnitSharingUpdateDto(row: StorageUnitSharingDto, permissionOptions: PermissionSelectorValue): StorageUnitSharingUpdateDto {
    const sharingUpdateDto: StorageUnitSharingUpdateDto = {
      ...row,
      ...permissionOptions,
      permissionSetLevel: permissionOptions.permissionSetLevel as PermissionSetLevel,
      permissions: permissionOptions.permissions as StorageUnitPermission[]
    };

    const {detailedPermissions} = permissionOptions;

    if (detailedPermissions) {
      delete sharingUpdateDto.permissionSetLevel;
    } else {
      delete sharingUpdateDto.permissions;
    }
    return sharingUpdateDto;
  }

  getSharedFolderSharingUpdateDto(row: StorageUnitSharingDto, permissionOptions: PermissionSelectorValue): SharedFolderSharingUpdateDto {
    const sharingUpdateDto: SharedFolderSharingUpdateDto = {
      ...row,
      ...permissionOptions,
      permissionSetLevel: permissionOptions.permissionSetLevel as SharedFolderPermissionLevel
    };

    return sharingUpdateDto;
  }

  onPermissionAdded() {
    this.documentDetailService?.reloadObject({
      [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
      [DocumentDetailCountType.SHARE]: increment(),
    });
    this.fileDetailService?.reloadObject({
      [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
      [FileDetailCountType.SHARE]: increment(),
    });
    this.storageUnitDetailService?.reloadObject({
      [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
      [StorageUnitDetailCountType.SHARE]: increment(),
    });
    this.loadPermissions();
  }

  getSharedWithTooltip(row: ManualSharingDto): Observable<string> {
    const fms = this.functionalPositionPipe.transform(row.sharedWith.filter(
      s => s.organizationalStructureEntityType === OrganizationalStructureEntityType.FUNCTIONAL_POSITION).map(s => s.organizationalStructureEntityId));
    const ous = this.findOrganizationalUnitByIdPipe.transform(row.sharedWith.filter(
      s => s.organizationalStructureEntityType === OrganizationalStructureEntityType.ORGANIZATIONAL_UNIT).map(s => s.organizationalStructureEntityId));
    return combineLatest([fms, ous]).pipe(map(([fms, ous]) => {
      if (fms && ous) {
        return `${fms}, ${ous}`;
      } else if (fms && !ous) {
        return `${fms}`;
      } else {
        return `${ous}`;
      }
    }));
  }

  getFirstTwoShared(row: ManualSharingDto): OrganizationalStructureEntityDto[] {
    return row.sharedWith.slice(0, 2);
  }

  getMoreFormattedText(count: number) {
    return this.translateParser.interpolate(this.translateService.instant('a {{count}} další'), {count});
  }

  updatePermission(row: ManualSharingDto, permissionOptions: PermissionSelectorValue) {
    const {id} = row;

    let sharingDto: ManualSharingUpdateDto;

    if (this.entityType === AuthorizedEntityType.DOCUMENT) {
      sharingDto = this.getDocumentSharingUpdateDto(row as DocumentSharingDto, permissionOptions);
    }
    else if (this.entityType === AuthorizedEntityType.FILE) {
      sharingDto = this.getFileSharingUpdateDto(row as FileSharingDto, permissionOptions);
    }
    else if (this.entityType === AuthorizedEntityType.STORAGE_UNIT) {
      sharingDto = this.getStorageUnitSharingUpdateDto(row as StorageUnitSharingDto, permissionOptions);
    }
    else if (this.entityType === AuthorizedEntityType.SHARED_FOLDER) {
      sharingDto = this.getSharedFolderSharingUpdateDto(row as StorageUnitSharingDto, permissionOptions);
    }

    this.permissionService
      .updatePermission(this.entityType, id, sharingDto!)
      .subscribe(() => this.loadPermissions());
  }

  deletePermission(permissionId: number) {
    this.permissionService.deletePermission(this.entityType, permissionId).subscribe(() => {
      this.documentDetailService?.reloadObject({
        [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
        [DocumentDetailCountType.SHARE]: decrement(),
      });
      this.fileDetailService?.reloadObject({
        [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
        [FileDetailCountType.SHARE]: decrement(),
      });
      this.storageUnitDetailService?.reloadObject({
        [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
        [StorageUnitDetailCountType.SHARE]: decrement(),
      });
      this.loadPermissions();
    });
  }

  onPageLoad(searchParams: SearchParams) {
    this.dataSource.loadPage(searchParams);
  }

  ngOnInit() {
    let authorizationResult$: Nullable<Observable<GeneralAuthorizationResult>>;

    const loadPermissions = (authorizationResult: GeneralAuthorizationResult) => {
      this.authorizationResult = authorizationResult;
      this.canUserManageSharing = isAuthorizedOperationGranted(authorizationResult, this.manageSharingAuthorizedOperation) && !this.isSharedWithCurrentUser;
      this.loadPermissions();
    };

    if (this.entityType === AuthorizedEntityType.DOCUMENT) {
      if (!this.documentDetailService) {
        throw new Error('DocumentDetailService not provided.');
      }

      this.documentDetailService.object$.pipe(
        filter(Boolean),
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(document => {
        this.isPermissionToolbarDisabled = document.documentState === DocumentState.DEACTIVATED;
        this.isSharedWithCurrentUser = (document as DocumentDto).isSharedWithCurrentUser;

        this.manageSharingAuthorizedOperation = DocumentAuthorizedOperation.DOCUMENT_MANAGE_SHARING;
        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeDocumentOperations({
          body: {
            authorizedEntityId: document.id,
            authorizedEntityType: AuthorizedEntityType.DOCUMENT,
            operationsToAuthorize: [this.manageSharingAuthorizedOperation],
          }
        });
        this.loadingIndicatorService.doLoading(
          authorizationResult$,
          this,
        ).subscribe(authorizationResult => loadPermissions(authorizationResult));
      });
    }
    else if (this.entityType === AuthorizedEntityType.FILE) {
      if (!this.fileDetailService) {
        throw new Error('FileDetailService not provided.');
      }

      this.fileDetailService.object$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(file => {
        this.isPermissionToolbarDisabled = file ? file.fileState === FileState.DEACTIVATED : false;

        this.manageSharingAuthorizedOperation = FileAuthorizedOperation.FILE_MANAGE_SHARING;
        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeFileOperations({
          body: {
            authorizedEntityId: file!.id,
            authorizedEntityType: AuthorizedEntityType.FILE,
            operationsToAuthorize: [this.manageSharingAuthorizedOperation!],
          }
        });
        this.loadingIndicatorService.doLoading(
          authorizationResult$,
          this,
        ).subscribe(authorizationResult => loadPermissions(authorizationResult));
      });
    }
    else if (this.entityType === AuthorizedEntityType.STORAGE_UNIT) {
      if (!this.storageUnitDetailService) {
        throw new Error('StorageUnitDetailService not provided.');
      }

      this.storageUnitDetailService.object$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(storageUnit => {
        this.manageSharingAuthorizedOperation = StorageUnitAuthorizedOperation.STORAGE_UNIT_MANAGE_SHARING;
        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeStorageUnitOperations({
          body: {
            authorizedEntityId: storageUnit!.id,
            authorizedEntityType: AuthorizedEntityType.STORAGE_UNIT,
            operationsToAuthorize: [this.manageSharingAuthorizedOperation!],
          }
        });
        this.loadingIndicatorService.doLoading(
          authorizationResult$,
          this,
        ).subscribe(authorizationResult => loadPermissions(authorizationResult));
      });
    }
    else if (this.entityType === AuthorizedEntityType.SHARED_FOLDER) {
      this.manageSharingAuthorizedOperation = SharedFolderAuthorizedOperation.SHARED_FOLDER_MANAGE_SHARING;
      authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeSharedFolderOperations({
        body: {
          authorizedEntityId: this.entityId,
          authorizedEntityType: AuthorizedEntityType.SHARED_FOLDER,
          operationsToAuthorize: [this.manageSharingAuthorizedOperation!],
        }
      });
      this.loadingIndicatorService.doLoading(
        authorizationResult$,
        this,
      ).subscribe(authorizationResult => loadPermissions(authorizationResult));
    }
  }

}
