@if (environment.environmentType; as appEnvironment) {
  <div class="environment-container">
    @if (appEnvironment === EnvironmentType.TESTING) {
      <div class="environment environment-testing">
        {{ 'Testovací prostředí' | translate }}
      </div>
    }
    @if (appEnvironment === EnvironmentType.TRAINING) {
      <div class="environment environment-training">
        {{ 'Školící prostředí' | translate }}
      </div>
    }
  </div>
}
