@if (form) {
  <div class="col grow w-full" [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
    <div class="gap-24 row">
      <div class="gap-8 col grow">
        <icz-section label="Jak chcete vyřídit">
          <icz-form-autocomplete label="Způsob vyřízení"
                                 formControlName="documentSettlementType"
                                 iczRequiredField
                                 [options]="globalSettlementTypeOptions">
          </icz-form-autocomplete>
          <div class="row grow">
            <icz-date-picker
              label="Datum vyřízení"
              iczRequiredField
              formControlName="settlementDate"
              [selectableDates]="settlementDateValidator"
              outputFormat="dateTime"
              class="grow">
            </icz-date-picker>
          </div>
          @if (isResolutionByRecord) {
            <icz-form-field label="Obsah vyřízení (jak bylo vyřízeno)" formControlName="content"></icz-form-field>
            <icz-form-field label="Důvod" formControlName="reason"></icz-form-field>
          }
        </icz-section>
        <icz-section label="Komentáře" testingFeature>
          <icz-checkbox formControlName="deleteComments"
                        label="Smazat uživatelské komentáře"
                        secondaryLabel="{{ userCommentNotice | translate | interpolate:userCommentNoticeContext }}"></icz-checkbox>
        </icz-section>
      </div>
      <div class="gap-8 col grow">
        <icz-section label="Zatřídění">
          <icz-document-type-selector
            [form]="form"
            controlName="documentTypeId"
            [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
            [date]="settlementDate"
          ></icz-document-type-selector>
          <icz-entity-class-selector
            [form]="form"
            controlName="entityClassId"
            [showEntityClassesValidAt]="settlementDate"
          ></icz-entity-class-selector>
          <div class="row grow">
            <icz-form-field label="Spisový plán" formControlName="classificationSchemeName" class="grow"></icz-form-field>
          </div>
          <div class="row grow">
            <icz-date-picker label="Platnost spisového plánu od"
                             formControlName="classificationSchemeValidFrom"
                             placeholder="Neomezeno" class="grow">
            </icz-date-picker>
            <icz-date-picker label="Platnost spisového plánu do"
                             formControlName="classificationSchemeValidTo"
                             placeholder="Neomezeno" class="grow">
            </icz-date-picker>
          </div>
        </icz-section>
      </div>
    </div>
    @if (form.get('entityClassId')!.value || form.get('documentTypeId')!.value) {
      <icz-section label="Skartační režim">
        <div class="gap-24 row">
          <div class="grow">
            <icz-form-autocomplete
              [clearable]="false"
              [options]="disposalScheduleOptions"
              class="grow"
              formControlName="disposalScheduleId"
              label="Skartační režim"
            ></icz-form-autocomplete>
            <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                                   formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
            <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
            <icz-form-autocomplete
              [clearable]="false"
              [options]="retentionTriggerTypesOptions"
              class="grow"
              formControlName="retentionTriggerTypeCode"
              label="Spouštěcí událost"
            ></icz-form-autocomplete>
          </div>
          <div class="col grow">
            @if (disposalSchedulePrepare && isExternalRetentionTriggerTypeWithPeriod()) {
              <icz-ext-retention-trigger-selector
                [form]="form"
                [availableExternalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds ?? []"
              ></icz-ext-retention-trigger-selector>
            }
            <div class="row">
              <icz-form-field class="grow" label="Rok spouštecí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
              @if (isRetentionTriggerTypeWithPeriod()) {
                <icz-form-field class="grow" label="Rok kontroly spouštecí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
              } @else {
                <div class="grow"></div>
              }
            </div>
          </div>
        </div>
      </icz-section>
    }

  </div>
}
