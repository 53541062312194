/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { configIdtDownload } from '../fn/config-idt/config-idt-download';
import { ConfigIdtDownload$Params } from '../fn/config-idt/config-idt-download';
import { configIdtDownloadJar } from '../fn/config-idt/config-idt-download-jar';
import { ConfigIdtDownloadJar$Params } from '../fn/config-idt/config-idt-download-jar';


/**
 * API for idt configuration
 */
@Injectable({ providedIn: 'root' })
export class ApiConfigIdtService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `configIdtDownloadJar()` */
  static readonly ConfigIdtDownloadJarPath = '/idt/idt-jar-with-dependencies.jar';

  /**
   * Download IDT jar
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configIdtDownloadJar()` instead.
   *
   * This method doesn't expect any request body.
   */
  configIdtDownloadJar$Response(params?: ConfigIdtDownloadJar$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return configIdtDownloadJar(this.http, this.rootUrl, params, context);
  }

  /**
   * Download IDT jar
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `configIdtDownloadJar$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  configIdtDownloadJar(params?: ConfigIdtDownloadJar$Params|null|undefined, context?: HttpContext): Observable<Array<string>> {
    return this.configIdtDownloadJar$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

  /** Path part for operation `configIdtDownload()` */
  static readonly ConfigIdtDownloadPath = '/idt/download';

  /**
   * Download IDT jnlp file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configIdtDownload()` instead.
   *
   * This method doesn't expect any request body.
   */
  configIdtDownload$Response(params?: ConfigIdtDownload$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return configIdtDownload(this.http, this.rootUrl, params, context);
  }

  /**
   * Download IDT jnlp file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `configIdtDownload$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  configIdtDownload(params?: ConfigIdtDownload$Params|null|undefined, context?: HttpContext): Observable<Array<string>> {
    return this.configIdtDownload$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
