import {Pipe, PipeTransform} from '@angular/core';
import {AddressAttributeType} from '../../../services/subjects.service';
import {SubjectAttributeType} from '../model/subject-attribute-type';

export function getAddressTypeLabelForNaturalPerson(type: Nullable<AddressAttributeType>): string {
  if (!type) return '';
  if (type === SubjectAttributeType.RESIDENTIAL_ADDRESS) return 'Trvalé bydliště';
  if (type === SubjectAttributeType.MAILING_ADDRESS) return 'Doručovací adresa';
  if (type === SubjectAttributeType.ADDITIONAL_ADDRESS) return 'Kontaktní adresa';
  if (type === SubjectAttributeType.POSTAL_BOX) return 'P.O. BOX';
  else return 'Neznámý druh adresy';
}

export function getAddressTypeLabelForLegalLike(type: Nullable<AddressAttributeType>): string {
  if (!type) return '';
  if (type === SubjectAttributeType.RESIDENTIAL_ADDRESS) return 'Sídlo';
  if (type === SubjectAttributeType.MAILING_ADDRESS) return 'Doručovací adresa';
  if (type === SubjectAttributeType.ADDITIONAL_ADDRESS) return 'Kontaktní adresa';
  if (type === SubjectAttributeType.POSTAL_BOX) return 'P.O. BOX';
  else return 'Neznámý druh adresy';
}

@Pipe({
  name: 'addressTypeLabel',
  pure: true
})
export class AddressTypeLabelPipe implements PipeTransform {

  transform(type: Nullable<AddressAttributeType>): string {
    return getAddressTypeLabelForNaturalPerson(type);
  }

}
