import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {IconSize} from '../icon/icon.component';

@Component({
  selector: 'icz-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss', '../button/button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleButtonComponent implements OnInit {

  eleRef = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);

  @Input() svgIcon = '';
  @Input() size: IconSize = 'default';
  @Input() label!: string;
  @Input() background!: string;
  @Input() disabled = false;
  @Input() toggled = false;

  @Output() onAction = new EventEmitter<Event>();

  toggle() {
    this.toggled = !this.toggled;
    this.cd.detectChanges();
  }

  ngOnInit() {
  }

}
