/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { DocumentCheckBeforeMarkRequest } from '../../models/document-check-before-mark-request';
import { DocumentCheckBeforeMarkResponse } from '../../models/document-check-before-mark-response';

export interface SignatureCheckBeforeMark$Params {

/**
 * ID of document
 */
  documentId: number;
      body: DocumentCheckBeforeMarkRequest
}

export function signatureCheckBeforeMark(http: HttpClient, rootUrl: string, params: SignatureCheckBeforeMark$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentCheckBeforeMarkResponse>> {
  const rb = new RequestBuilder(rootUrl, signatureCheckBeforeMark.PATH, 'post');
  if (params) {
    rb.path('documentId', params.documentId, {});
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<DocumentCheckBeforeMarkResponse>;
    })
  );
}

signatureCheckBeforeMark.PATH = '/document/signature/{documentId}/check-before-mark';