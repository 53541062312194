import {Directive, forwardRef, inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  PermissionSelectorComponent
} from '../shared-business-components/permissions/permission-selector/permission-selector.component';
import {GenericValueAccessor} from './generic.value-accessor';
import {ButtonToggleGroupComponent} from './button-toggle-group/button-toggle-group.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {FormAutocompleteComponent} from './form-autocomplete/form-autocomplete.component';
import {FormChipInputComponent} from './form-chip-input/form-chip-input.component';
import {FormFieldComponent} from './form-field/form-field.component';
import {FormTreeSelectorComponent} from './form-tree-selector/form-tree-selector.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {ItemSorterComponent} from './item-sorter/item-sorter.component';
import {FormInlineSelectComponent} from './form-inline-select/form-inline-select.component';
import {
  SubjectAddressSelectorComponent
} from '../shared-business-components/subjects/subject-address-selector/subject-address-selector.component';
import {FormMoneyInputComponent} from './form-money-input/form-money-input.component';
import {
  EpdzAttachmentSubsetSelectorComponent
} from '../shared-business-components/epdz-attachment-subset-selector/epdz-attachment-subset-selector.component';
import {EmpowermentComponent} from './empowerment/empowerment.component';
import {TimePickerComponent} from './time-picker/time-picker.component';


@Directive({
  // the + ',' + are intentional because the commas are hard
  // to read and thus the selector is prone to hard-to-spot mistakes

  selector: 'icz-button-toggle-group[formControlName]' + ',' +
            'icz-checkbox[formControlName]' + ',' +
            'icz-form-autocomplete[formControlName]' + ',' +
            'icz-form-chip-input[formControlName]' + ',' +
            'icz-form-field[formControlName]' + ',' +
            'icz-form-tree-selector[formControlName]' + ',' +
            'icz-date-picker[formControlName]' + ',' +
            'icz-time-picker[formControlName]' + ',' +
            'icz-form-item-sorter[formControlName]' + ',' +
            'icz-form-inline-select[formControlName]' + ',' +
            'icz-subject-address-selector[formControlName]' + ',' +
            'icz-form-money-input[formControlName]' + ',' +
            'icz-epdz-attachment-subset-selector[formControlName]' + ',' +
            'icz-empowerment[formControlName]' + ',' +
            'icz-permission-selector[formControlName]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => FormFieldValueAccessorDirective),
    multi: true
  }],
})
export class FormFieldValueAccessorDirective extends GenericValueAccessor {

  private buttonToggleGroupComponent = inject(ButtonToggleGroupComponent, {optional: true});
  private checkboxComponent = inject(CheckboxComponent, {optional: true});
  private formAutocompleteComponent = inject(FormAutocompleteComponent, {optional: true});
  private formChipInputComponent = inject(FormChipInputComponent, {optional: true});
  private formFieldComponent = inject(FormFieldComponent, {optional: true});
  private formTreeSelectorComponent = inject(FormTreeSelectorComponent, {optional: true});
  private datepickerComponent = inject(DatePickerComponent, {optional: true});
  private timePickerComponent = inject(TimePickerComponent, {optional: true});
  private itemSorterComponent = inject(ItemSorterComponent, {optional: true});
  private inlineSelectComponent = inject(FormInlineSelectComponent, {optional: true});
  private subjectAddressSelectorComponent = inject(SubjectAddressSelectorComponent, {optional: true});
  private formMoneyInputComponent = inject(FormMoneyInputComponent, {optional: true});
  private componentSubsetSelectorComponent = inject(EpdzAttachmentSubsetSelectorComponent, {optional: true});
  private empowermentComponent = inject(EmpowermentComponent, {optional: true});
  private permissionSelectorComponent = inject(PermissionSelectorComponent, {optional: true});

  @Input()
  override formControlName!: string;

  protected override component = (this.buttonToggleGroupComponent || this.checkboxComponent || this.formAutocompleteComponent ||
    this.formChipInputComponent || this.formFieldComponent || this.formTreeSelectorComponent ||
    this.datepickerComponent || this.timePickerComponent || this.itemSorterComponent || this.inlineSelectComponent || this.subjectAddressSelectorComponent ||
    this.formMoneyInputComponent || this.componentSubsetSelectorComponent || this.permissionSelectorComponent || this.empowermentComponent)!;

}
