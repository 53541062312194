/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { filingRegisterRenditionMakeRendition } from '../fn/filing-register-rendition/filing-register-rendition-make-rendition';
import { FilingRegisterRenditionMakeRendition$Params } from '../fn/filing-register-rendition/filing-register-rendition-make-rendition';
import { filingRegisterRenditionSearch } from '../fn/filing-register-rendition/filing-register-rendition-search';
import { FilingRegisterRenditionSearch$Params } from '../fn/filing-register-rendition/filing-register-rendition-search';
import { PageFilingRegisterRenditionDto } from '../models/page-filing-register-rendition-dto';


/**
 * The filing register rendition controller
 */
@Injectable({ providedIn: 'root' })
export class ApiFilingRegisterRenditionService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `filingRegisterRenditionMakeRendition()` */
  static readonly FilingRegisterRenditionMakeRenditionPath = '/records/filing-register/rendition/make';

  /**
   * Makes a (Separate) Filing Register rendition.
   *
   * Starts a background process, whose output will be a single (Separate) Filing Register rendition.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterRenditionMakeRendition()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterRenditionMakeRendition$Response(params: FilingRegisterRenditionMakeRendition$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return filingRegisterRenditionMakeRendition(this.http, this.rootUrl, params, context);
  }

  /**
   * Makes a (Separate) Filing Register rendition.
   *
   * Starts a background process, whose output will be a single (Separate) Filing Register rendition.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterRenditionMakeRendition$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  filingRegisterRenditionMakeRendition(params: FilingRegisterRenditionMakeRendition$Params, context?: HttpContext): Observable<void> {
    return this.filingRegisterRenditionMakeRendition$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `filingRegisterRenditionSearch()` */
  static readonly FilingRegisterRenditionSearchPath = '/records/filing-register/rendition/search-api';

  /**
   * Returns filing register renditions based on the provided search criteria.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `filingRegisterRenditionSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterRenditionSearch$Response(params?: FilingRegisterRenditionSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageFilingRegisterRenditionDto>> {
    return filingRegisterRenditionSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns filing register renditions based on the provided search criteria.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `filingRegisterRenditionSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  filingRegisterRenditionSearch(params?: FilingRegisterRenditionSearch$Params|null|undefined, context?: HttpContext): Observable<PageFilingRegisterRenditionDto> {
    return this.filingRegisterRenditionSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageFilingRegisterRenditionDto>): PageFilingRegisterRenditionDto => r.body)
    );
  }

}
