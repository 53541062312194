<div [ngClass]="{'acknowledged-notification-icon': isRead}">
  @if (notification?.notificationType === NotificationType.INFO) {
    <icz-icon
      svgIcon="info_blue"
      [size]="size">
    </icz-icon>
  }
  @if (notification?.notificationType === NotificationType.WARNING) {
    <icz-icon
      svgIcon="warning_color"
      [size]="size">
    </icz-icon>
  }
  @if (notification?.notificationType === NotificationType.ERROR) {
    <icz-icon
      svgIcon="error"
      [size]="size">
    </icz-icon>
  }
</div>
