<mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
  <div class="row tree-node" *matTreeNodeDef="let node"
    [class.selected]="node === selectedMenuItem"
    [iczTooltip]="node.label"
    [iczTooltipShowDelay]="TRUNCATED_TEXT_TOOLTIP_DELAY"
    [style.width.px]="maxWidth - HORIZONTAL_PADDINGS_COMPENSATION">
    <mat-tree-node matTreeNodePadding [matTreeNodePaddingIndent]="INDENT_SIZE"
      class="grow" [style.max-width]="getNodeElementWidth(node)">
      <!--      TOGGLE BUTTON WITH ARROW OR DOT -->
      @if (node.testingFeature) {
        <icz-button
          class="expander" size="small"
          disabled
          disableRipple
          [svgIcon]="getTreeViewItemIcon(node)"
          background
        ></icz-button>
      }
      @else {
        <icz-button class="expander" size="small"
          [svgIcon]="getTreeViewItemIcon(node)"
          background
          disableRipple
          [attr.aria-label]="'toggle ' + node.label"
          [disabled]="!node.expandable"
          matTreeNodeToggle>
        </icz-button>
      }
      <ng-template #matTreeNodeToggle>
        <icz-button class="expander" size="small"
          [svgIcon]="getTreeViewItemIcon(node)"
          background
          disableRipple
          [attr.aria-label]="'toggle ' + node.label"
          [disabled]="!node.expandable"
          matTreeNodeToggle>
        </icz-button>
      </ng-template>

      <!--      NODE LABEL-->
      <icz-button
        [attr.aria-label]="'toggle ' + node.label" size="compact"
        [testingFeature]="node.testingFeature"
        [label]="node.label"
        background class="node-label-button grow"
        disableRipple noCentered
        (onAction)="node.testingFeature ? null : treeItemSelected.emit(node)">
      </icz-button>

    </mat-tree-node>
    <!--    3 DOT MENU BUTTON-->
    @if (showItemMenu && itemMenu) {
      <icz-button svgIcon="more" class="more"
      [matMenuTriggerFor]="itemMenu" size="small" background></icz-button>
    }
  </div>
</mat-tree>
