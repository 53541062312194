<div class="gap-16 col" [waiting]="loadingService.isLoading$(this) | async">
  <icz-section label="Zásilka" boxed class="block">
    <icz-readonly-field label="Identifikátor zásilky" [value]="receivedConsignment?.uid?.uid"></icz-readonly-field>
    <icz-readonly-field label="Název" [value]="receivedConsignment?.label"></icz-readonly-field>
    <icz-readonly-field label="Založil" [value]="receivedConsignment?.auditInfo?.createdBy!"></icz-readonly-field>
    <div class="row grow gap-16">
      <icz-readonly-field label="Založeno" [value]="receivedConsignment?.auditInfo?.createdAt! | localizedDate" class="grow"></icz-readonly-field>
      <icz-readonly-field label="Poslední úprava" [value]="receivedConsignment?.auditInfo?.modifiedAt! | localizedDate" class="grow"></icz-readonly-field>
    </div>
  </icz-section>

  <icz-section label="Doručení" boxed class="block">
    <icz-readonly-field label="Způsob doručení" [value]="deliveryTypeName"></icz-readonly-field>
    @if (isPostDelivery) {
      <div>
        <div class="row grow gap-32 mb-8">
          <icz-checkbox label="Doporučeně" [value]="$any(receivedConsignment).recommended" [fieldDisabled]="true" ></icz-checkbox>
          <icz-checkbox label="Do vlastních rukou" [value]="$any(receivedConsignment).personalDelivery" [fieldDisabled]="true"></icz-checkbox>
        </div>
        <icz-readonly-field label="Podací pošta" class="grow" [value]="$any(receivedConsignment).senderPostOffice"></icz-readonly-field>
        <icz-readonly-field label="Podací číslo" class="grow" [value]="$any(receivedConsignment).postingNumber"></icz-readonly-field>
      </div>
    }
    @if (isDataBoxDelivery) {
      <div>
        <icz-readonly-field label="Označení příchozí zásilky" [value]="$any(receivedConsignment).externalId">
          <icz-icon suffix size="small" svgIcon="info" iczTooltip="Např. ID zprávy"></icz-icon>
        </icz-readonly-field>
        <icz-readonly-field label="Předmět" [value]="$any(receivedConsignment).subject"></icz-readonly-field>
      </div>
    }
    <div class="row">
      <icz-readonly-field class="grow" label="Datum odeslání" [value]="receivedConsignment?.dispatchDate | localizedDate"></icz-readonly-field>
      <icz-readonly-field label="Datum doručení" class="grow mr-8" [value]="receivedConsignment?.deliveryDate | localizedDate"></icz-readonly-field>
      <icz-form-field label="Čas doručení" [value]="receivedConsignment?.deliveryTime" class="grow" [fieldDisabled]="true"></icz-form-field>
    </div>
    @if (isPostDelivery) {
      <icz-readonly-field label="Barcode" class="grow" testingFeature value=""></icz-readonly-field>
    }
    @if (isDataBoxDelivery) {
      <div>
        <icz-readonly-field label="Zmocnění" [value]="$any(receivedConsignment).empowerment | empowerment"></icz-readonly-field>
        <div class="row">
          <icz-readonly-field class="grow" label="Číslo jednací odesílatele" [value]="$any(receivedConsignment).senderRefNumber"></icz-readonly-field>
          <icz-readonly-field class="grow" label="Do vlastních rukou" [value]="$any(receivedConsignment).personalDelivery | localizedBoolean"></icz-readonly-field>
        </div>
        <div class="row">
          <icz-readonly-field class="grow" label="K rukám" [value]="$any(receivedConsignment).toHands"></icz-readonly-field>
        </div>
        <icz-readonly-field label="Organizační jednotka příjemce" [value]="$any(receivedConsignment).consigneeOrganisationUnit"></icz-readonly-field>
      </div>
    }
  </icz-section>

  @if (hasPaperReceivedComponents || hasDigitalReceivedComponents) {
    <icz-section label="Doručené množství" boxed class="block">
      @if (hasDigitalReceivedComponents) {
        <icz-form-field label="Počet digitálních komponent" class="grow" [value]="digitalReceivedComponents" [fieldDisabled]="true"></icz-form-field>
      }
      @if (hasPaperReceivedComponents) {
        <icz-form-field label="Počet listinných komponent" class="grow" [value]="formattedPaperComponentsCount" [fieldDisabled]="true">
          <icz-icon suffix size="small" iczTooltip="fe.ui.paperComponentsCount.tooltip" svgIcon="info"></icz-icon>
        </icz-form-field>
        <icz-form-field label="Počet a druh nelistinných komponent" class="grow" [value]="nonPaperReceivedComponents" [fieldDisabled]="true"></icz-form-field>
      }
    </icz-section>
  }

  @if (hasSender) {
    @if (isDataBoxDelivery) {
      <icz-sender-info-databox
        [consignment]="$any(receivedConsignment)"
        [senderSubject]="senderSubject"
      ></icz-sender-info-databox>
    }
    <icz-subject-detail-card
      class="mb-16"
      [areAddressesReadonly]="true"
      [allowSubjectEdit]="false"
      [allowSubjectUnlink]="false"
      [subject]="senderSubject"
      label="Odesílatel">
    </icz-subject-detail-card>
    @if (isPostDelivery) {
      <icz-address
        [boxed]="true"
        [isSearchMode]="false"
        [isReadonly]="true"
        label="Adresa, odkud byla zásilka odeslána"
        [form]="senderAddressDtoForm"
        groupName="."
        [addressAttributeType]="SubjectAttributeType.MAILING_ADDRESS"
        [showSectionExpansionHint]="false"
      ></icz-address>
    }
  }
  @else {
    <icz-section label="Odesílatel" boxed class="block mb-32">
      <div>{{'Anonymní uživatel' | translate}}</div>
    </icz-section>
  }

</div>
