/* eslint-disable @angular-eslint/directive-selector */
import {Directive, HostBinding, HostListener, inject, Input} from '@angular/core';
import {Router} from '@angular/router';

// Works similarly to [routerLink] but works for non-<a> elements plus
// adds "hand finger" cursor on top of its host
@Directive({
  selector: '[linksTo]',
})
export class LinksToDirective {

  private router = inject(Router);

  @Input({required: true})
  linksTo = '';

  @HostBinding('class.cursor-pointer')
  get isClickable(): boolean {
    return this.linksTo !== '';
  }

  @HostListener('click')
  routeToLink() {
    this.router.navigateByUrl(this.linksTo);
  }

}
