
@switch (background) {
  @case (undefined) {
    <button
      #button mat-stroked-button class="grow" type="button"
      [class.disabled]="disabled"
      [ngClass]="{'disableRipple': disableRipple}"
      [tabIndex]="innerButtonTabIndex"
      [color]="color"
      [iczTooltip]="collapsedTooltip">
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  }
  @default {
    <button
      #button mat-flat-button class="grow" type="button" [ngClass]="{'disable-ripple': disableRipple}"
      [tabIndex]="innerButtonTabIndex" [disabled]="disabled"
      [color]="color"
      [ngStyle]="{background: background}"
      [iczTooltip]="collapsedTooltip">
      <ng-container *ngTemplateOutlet="buttonContent"></ng-container>
    </button>
  }
}

<ng-template #buttonContent>
  <div class="content" [ngClass]="{'icon-only': hasNoContent, 'label-only': !svgIcon, 'disabled': disabled, 'label-collapsed': isCollapsed}">
    @if (iconPosition === 'left') {
      <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
    }

    @if (label && !isCollapsed) {
      <icz-label class="label grow" [label]="label"></icz-label>
    }
    <ng-content></ng-content>

    @if (iconPosition === 'right') {
      <ng-container *ngTemplateOutlet="buttonIcon"></ng-container>
    }
  </div>
</ng-template>

<ng-template #buttonIcon>
  @if (svgIcon) {
    <icz-icon class="icon" [svgIcon]="svgIcon" [withCustomIconColor]="!_originalIconColor"></icz-icon>
  }
</ng-template>
